import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const FinalSettlementStyle = makeStyles((theme) => ({
    iconButton: {
        borderRadius: "4px",
        height: "40px",
        width: "40px",
        border: `1px solid ${theme.palette.border.primary}`
    },
}))

export const FinalSettlementViewStyle = makeStyles((theme) => ({
    gridBox: {
        background: "#fff",
        borderRadius: 4,
        boxShadow: "0px 0px 16px #00000014",
        height: "100%",
    },
    title: {
        fontSize:"1rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    titlehead: {
        fontSize:"1rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
        marginInlineStart: "16px"
    },
    detailSubtitle: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().bold
    },
    detailTitle: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    breakupTitle: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().regular,
    },
    breakupContent: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
        float: "right",
    },
    root: {
        height: "calc(100vh - 130px)",
        overflow: "auto"
    },
    create:{
        backgroundColor:"#5078E1",
        borderRadius:"4px",
        padding:"10px 12px",
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color:"#fff",
        cursor:"pointer",
        '&:hover':{
            backgroundColor:"#5078E1",
        borderRadius:"4px",
        padding:"10px 12px",
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        }
    },
    drawer:{
        backgroundColor:"#fff",
        position:"relative"
    },
    header: {
        // position: "fixed",
        top: 0,
        width: "100%"
    },
     drawerCloseBtn: {
        fontSize: 16,
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
        background: theme.palette.background.tertiary,
        borderRadius: 4,
        height: 32,
        width: 32,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    drawerHeading: {
        fontSize: 16,
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    absoluteBtn:{
        position:"absolute",
        width:"100%",
        padding:"16px",
        bottom:0,
        left:0,
        right:0
    },
    reqblock: {
        backgroundColor: "#5078E1",
        backgroundImage: `url(${"/images/requestView.svg"})`,
        height: "250px"
      },
    closeicon: {
        color: "white",
        cursor: "pointer"
      },
    yesbtn: {
        backgroundColor: "white",
        color: "#5078E1",
        "&:hover": {
          backgroundColor: "white",
          color: "#5078E1",
        }
      },
      nobtn: {
        color: "white",
        border: "1px solid #E4E8EE",
        "&:hover": {
          color: "white",
          border: "1px solid white",
    
        },
        marginInlineStart:"8px"
      },
      q1hdr: {
        color: "white",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: "14px",
        marginLeft: "15px"
      },
      approvalbox:{
        position:"absolute",
        left:0,
        right:0,
        bottom:0,
        padding:"16px"
      }
      
}))