import { Box } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { MenuList } from "../../../components";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods } from "../../../utils";

const AccountList = ({ setSelected = () => false, selected = {}, t, company, setNoData }) => {
    const [accountList, setAccountList] = React.useState(null);
    const [search, setSearch] = React.useState("");
    const [offset, setOffset] = React.useState(0);
    const [totalCount, setTotalCount] = React.useState(0);
    const limit = 20;

    //get all account
    const getAccountList = (offset, search, reload) => {
        const payload = {
            offset: offset,
            limit: limit,
            search: search,
            company_id: company?.value
        };

        NetworkCall(
            `${config.api_url}/queries/opportunity/contact_account`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            const filteredData = response?.data?.data?.filter(
                (account) => account.account_payment_classification !== "cash_and_bank"
            );

            if (filteredData.length === 0 && offset === 0) {
                setNoData(true);
            } else {
                setNoData(false);
            }

            if (reload) {
                setAccountList(filteredData);
                setSelected(filteredData?.[0] || {});
            } else {
                setAccountList([...accountList, ...filteredData]);
            }

            setTotalCount(response?.data?.count || 0);
        }).catch((err) => {
            console.log(err);
            setNoData(true);
        });
    };

    React.useEffect(() => {
        getAccountList(0, "", true);
        // eslint-disable-next-line
    }, []);

    // infinity scroll
    const fetchMoreData = () => {
        if (accountList.length < totalCount) {
            const newOffset = offset + limit;
            setOffset(newOffset);
            getAccountList(newOffset, search, false);
        }
    };
    //handle search
    const handleSearch = (value) => {
        setSearch(value);
        setOffset(0);
        getAccountList(0, value, true);
    };

    return (
        <Box>
            <MenuList
                list={accountList}
                selected={selected?.id}
                setSelected={setSelected}
                searchLabel={t("search")}
                fetchMoreData={fetchMoreData}
                handleSearch={handleSearch}
                searchValue={search}
            />
        </Box>
    )
}
export default withTranslation("receipt")(AccountList); 