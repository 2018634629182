import SearchIcon from '@mui/icons-material/Search';
import { Box, Drawer, Grid, IconButton, Typography } from "@mui/material";
import moment from "moment";
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomAsyncWithIcons, Location, PopoverDatePicker, SinglePopoverDatePicker } from '../../../components';
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { Routes } from "../../../router/routes";
import { AlertProps } from "../../../utils";
import { propertyPurposeListresidential } from "../../../utils/propertySearchpoints";
import { ClearFilter } from "../component/clearFilter";
import { Filter } from "../component/filter";
import { finderStyles } from "../style";
export const Residential = (props) => {
    var date = moment().toDate();

    const initialState = {
        city: props?.data?.city ?? "",
        purpose: props?.data?.purpose ?? propertyPurposeListresidential[0],
        type: props?.data?.type ?? {
            value: null,
            label: "All"
        },
        category: props?.data?.category ?? {
            label: "All",
            value: null,
        },
        duration: props?.data?.duration ?? 12,
        durationday: props?.data?.durationday ?? new Date(date),
        durationtype: props?.data?.durationtype ?? {
            type: "Month",
            date: new Date(date.setMonth(date.getMonth() + 12))
        },
        bed: props?.data?.bed ?? {
            label: "1",
            value: 1
        },
        bath: props?.data?.bath ?? {
            label: "1",
            value: 1
        },
        endDate: props?.data?.endDate ?? "",
        handover: props?.data?.handover ?? "",
        range1: props?.filterdata?.range1 ?? "",
        range2: props?.filterdata?.range2 ?? "",
        rangetype: props?.filterdata?.rangetype ?? "",
    }
    const customStyles = {
        control: {
            borderRight: '1px solid #ececec',
            borderRadius: 0,
            padding: "4px 10px",
            backgroundColor: 'none',
            border: 0
        }
    }
    const initialStatefilter = {
        propertyType: props?.filterdata?.propertyType ?? "",
        range1: props?.filterdata?.range1 ?? "",
        range2: props?.filterdata?.range2 ?? "",
        rangetype: props?.filterdata?.rangetype ?? "",
        budget1: props?.filterdata?.budget1 ?? "",
        budget2: props?.filterdata?.budget2 ?? "",
        budgettype: props?.filterdata?.budgettype ?? "",
        aminities: props?.filterdata?.aminities ?? "",
        facilitesbool: props?.filterdata?.facilitesbool ?? "",
        facilites: props?.filterdata?.facilites ?? "",
        facilitestype: props?.filterdata?.facilitestype ?? "",
        pet: props?.filterdata?.pet ?? "",
        furnish: props?.filterdata?.furnish,
        unittype: props?.filterdata?.unittype ?? ""
    }
    const classes = finderStyles()
    const navigate = useNavigate()
    const alert = React.useContext(AlertContext);
    const [location, setLocation] = React.useState(props?.location ?? {})
    const [category, setCategory] = React.useState(props?.category ?? propertyPurposeListresidential[0]?.unitCategory)
    const [drawer, setDrawer] = React.useState(false)
    const [state, setState] = useState({ ...initialState })
    const [filter, setFilter] = React.useState(props?.open ?? false)
    const [statefilter, setStateFilter] = React.useState({ ...initialStatefilter })
    const [filters, setFilters] = React.useState(false)
    const didMount = useRef(false);
    const detectLocation = () => {
        if (navigator.geolocation && (!props?.value?.longitude)) {
            navigator.geolocation.getCurrentPosition((position) => {
                const lat = position.coords.latitude;
                const long = position.coords.longitude;
                fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${config.googleMapApiKey}`)
                    .then(data => data.json())
                    .then(({ results }) => {
                        if (Array.isArray(results)) {
                            const [info] = results;
                            let city = info?.address_components?.find(comp => comp?.types?.includes("locality"));
                            let location = info?.address_components?.find(comp => comp?.types?.includes("administrative_area_level_2"));
                            let _obj = {};
                            _obj["address"] = `${city?.long_name}, ${location?.long_name}`;
                            _obj["latitude"] = lat;
                            _obj["longitude"] = long;
                            _obj["city"] = city?.long_name;
                            _obj["location"] = location?.long_name;

                            setLocation(_obj)

                        }
                    }).catch(console.error);
            });
        }
    };
    React.useEffect(() => {
        if (window.location.pathname === "/result") {

        } else {
            detectLocation()
        }
        // eslint-disable-next-line
    }, [])
    const updateState = (key, value) => {
        if (key === "purpose") {
            setState({ ...state, purpose: value, category: "" });
        } else {
            setState({ ...state, [key]: value });
        }
    };
    const updateStateFilter = (key, value) => {

        setStateFilter({ ...statefilter, [key]: value });
    };
    const filterType = {
        area: "area range",
        facilites: "facilities",
        amenities: "amenities",
        grace: "grace",
        pet: "pet",
        furnish: "furnish",
        unittype: "unittype",
        budget: "budget"
    }
    const goResult = () => {

        if (
            location?.length === 0 || state?.purpose?.length === 0 ||
            state?.type?.length === 0 || state?.category?.length === 0 ||
            state?.duration?.length === 0 || state?.durationday?.length === 0 ||
            state?.durationtype?.date?.length === 0 || state?.bed?.length === 0 || state?.bath?.length === 0
        ) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please Fill All Fields",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            })
        }
        else {
            if (window.location.pathname === "/result") {
                let tempEndDate = new Date(JSON.parse(JSON.stringify(state?.durationtype?.date)))
                const payload = {
                    tenantId: `${config.tenantId}`,
                    "search_location": {
                        "lat": location?.latitude,
                        "long": location?.longitude,
                        "distance": 10
                    },
                    "property_purpose": "Residential",
                    "revenue_type": state?.purpose?.label?.length > 0 ? state?.purpose?.value : null,
                    "property_type": state?.type?.value ?? null,
                    "unit_category": state?.category?.value ?? null,
                    "unit_type": statefilter?.unittype?.length > 0 ? statefilter?.unittype?.map((val) => val?.value) : null,
                    "requiredBeds": state?.bed?.value ?? null,
                    "requiredBaths": state?.bath.value ?? null,
                    "duration": {
                        "fromDate": state?.durationday ?? null,
                        "toDate": statefilter?.facilitesbool ? new Date(tempEndDate.setDate(tempEndDate.getDate() + parseInt(statefilter?.facilitesbool))) : state?.durationtype?.date ?? null,
                    },
                    "areaRequirements": {
                        "minArea": statefilter?.range1 ? parseInt(statefilter?.range1) : null,
                        "maxArea": statefilter?.range2 ? parseInt(statefilter?.range2) : null,
                        "areaUnit": statefilter?.rangetype?.value ? statefilter?.rangetype?.value : null
                    },
                    "budget": {
                        "minCost": statefilter?.budget1 ? parseInt(statefilter?.budget1) : null,
                        "maxCost": statefilter?.budget2 ? parseInt(statefilter?.budget2) : null,
                        "currency": statefilter?.budgettype?.value ? statefilter?.budgettype?.value : null
                    },
                    "hand_over_date": state?.handover.length > 0 ? state?.handover : null,
                    "furnishing": statefilter?.furnish ? [statefilter?.furnish] : null,
                    "isPetAllowed": statefilter?.pet ? statefilter?.pet === "Allowed" ? true : false : null,
                    "facilities": statefilter?.facilites?.length > 0 ? statefilter?.facilites?.map((val) => val?.value) : null,
                    "amenities": statefilter?.aminities?.length > 0 ? statefilter?.aminities?.map((val) => val?.value) : null,
                }
                props?.reload(payload)
                setFilters(false)
            } else {

                navigate(Routes.propertyResult, {
                    state: {
                        purpose: "Residential",
                        type: "residential",
                        value: state,
                        filter: statefilter,
                        open: filter,
                        category: category,
                        location: location
                    }
                })
            }
        }
    }
    const bedlist = [
        {
            label: "1",
            value: 1
        },
        {
            label: "2",
            value: 2
        },
        {
            label: "3",
            value: 3
        },
        {
            label: "4",
            value: 4
        },
        {
            label: "5+",
            value: 5
        }
    ]
    const bathlist = [
        {
            label: "1",
            value: 1
        },
        {
            label: "2",
            value: 2
        },
        {
            label: "3",
            value: 3
        },
        {
            label: "4",
            value: 4
        },
        {
            label: "5+",
            value: 5
        }
    ]
    
    const onClear = () => {
        setStateFilter(initialStatefilter)
        setDrawer(false)
        setFilter(false)
    }
    const clear = (value) => {
        if (value === "range") {
            setStateFilter({ ...statefilter, range1: "", range2: "", rangetype: "" });
            setFilters(true)

        } else if (value === "budget") {
            setStateFilter({ ...statefilter, budget1: "", budget2: "", budgettype: "" });
            setFilters(true)

        } else {
            setStateFilter({ ...statefilter, [value]: "" });
            setFilters(true)

        }

    }

    React.useEffect(() => {
        // Return early, if this is the first render:
        if (!didMount.current) {
            return didMount.current = true;
        }
        // Paste code to be executed on subsequent renders:
        if (window.location.pathname === "/result") {
            if (filters) {
                goResult()
            }

        } else {
            return false
        }
        // eslint-disable-next-line
    }, [filters]);
    return (
        <div>
            {
                window.location.pathname === '/propertyfinder' &&
                <>
                    {
                        filter &&
                        <>
                            {
                                statefilter?.pet || statefilter?.facilitesbool || statefilter?.unittype || statefilter?.propertyType || statefilter?.range1 || statefilter?.range2 || statefilter?.rangetype || statefilter?.budget1 || statefilter?.budget2 || statefilter?.budgettype || statefilter?.aminities || statefilter?.facilites || statefilter?.facilitestype ?
                                    <Typography className={classes.clearAll} onClick={onClear}>Clear All</Typography>
                                    : ""
                            }
                        </>
                    }
                </>
            }

            <Grid container>
                <Grid item xs={11.7}>
                    <Grid container alignItems="center" className={classes.selectorRoot}>
                        <Grid item xs={1.7} className={classes.selector}>
                            <Location
                                value={location}
                                handleChange={(value) => setLocation(value)}
                                placeholder="Enter City"
                            />
                        </Grid>
                        <Grid item xs={1.9} className={classes.selector}>
                            <CustomAsyncWithIcons
                                value={state?.purpose}
                                placeholder="Property Purpose"
                                onChange={(value) => {
                                    updateState('purpose', value)
                                    setCategory(value?.unitCategory)
                                }}
                                options={propertyPurposeListresidential}
                                styles={{ ...customStyles }}
                                icon="/images/propertypurpose.svg"
                            />
                        </Grid>
                        <Grid item xs={1.8} className={classes.selector}>
                            <CustomAsyncWithIcons
                                value={state?.category}
                                icon="/images/unitcatergry.svg"
                                placeholder="Unit Category"
                                onChange={(value) => updateState('category', value)}
                                options={category}
                                styles={{ ...customStyles }}
                            />

                        </Grid>
                        <Grid item xs={1.8} className={classes.selector}>
                            {
                                state?.purpose?.label === "Buy" ?
                                    <SinglePopoverDatePicker
                                        startIcon="/images/date.svg"
                                        placeholder="Enter Handover Date"
                                        updateState={updateState}
                                        handover={state?.handover}
                                    /> :
                                    <PopoverDatePicker
                                        startIcon="/images/date.svg"
                                        placeholder="Enter Duration"
                                        updateState={updateState}
                                        gracePeriod={statefilter?.facilitesbool}
                                        duration={state?.duration}
                                        day={state?.durationday}
                                        type={state?.durationtype}
                                    />
                            }


                        </Grid>
                        <Grid item xs={1.2} className={classes.selector}>
                            <CustomAsyncWithIcons
                                value={state?.bed}
                                icon="/images/bed.svg"
                                placeholder="Beds"
                                onChange={(value) => updateState('bed', value)}
                                options={bedlist}
                                styles={{ ...customStyles }}
                            />
                        </Grid>
                        <Grid item xs={1.3}>
                            <CustomAsyncWithIcons
                                value={state?.bath}
                                icon="/images/bath.svg"
                                placeholder="Bath"
                                onChange={(value) => updateState('bath', value)}
                                options={bathlist}
                                styles={{ ...customStyles }}
                            />
                        </Grid>
                        <Grid item xs={0.5} className={classes.search} onClick={goResult}>
                            <SearchIcon style={{ color: "white", fontSize: "24px" }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={0.3} >
                    <Box onClick={() => setDrawer(true)} display="flex" alignItems="center" marginTop="16px" marginLeft="8px">
                        <Box>
                            <IconButton size="small">
                                <img src="/images/filterWhite.svg" alt="" />
                            </IconButton>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <ClearFilter filter={filter} state={statefilter} clear={clear} />
            <Drawer anchor='right' open={drawer} onClose={() => setDrawer(false)} className={classes.filterDialog}>

                <Filter
                    filter="Residential"
                    type={filterType}
                    applyfilter={() => {
                        setDrawer(false)
                        setFilter(true)
                        if (window.location.pathname === "/result") {
                            goResult()
                        }

                    }}
                    onClear={onClear} state={statefilter} updateState={updateStateFilter} onClose={() => {
                        if (window.location.pathname === "/result") {

                        } else {
                            setFilter(false)
                        }
                        setDrawer(false)

                    }} />

            </Drawer>

        </div>
    )
}