import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const DeliveryMilestoneTemplateStyles = makeStyles((theme) => ({
    img: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "10px",
        height: "40px",
        width: '40px'
    },
    btn: {
        padding: "12px",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 6px 10px #00000014"

    },
    btn2: {
        padding: "12px",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 6px 10px #00000014",
        "&:hover": {
            backgroundColor: "#fff"
        }

    },
    card: {
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#EEF2FC",
        padding: "12px",
    },
    recBox: {
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#85A1EA",
        padding: "12px",
        textAlign: "center"

    },
    textSmall: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: "white"
    },
    textRight: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: "#091B29",
        padding: "8px 36px"
    },
    textLeft: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: "#98A0AC",
        padding: "8px 16px"
    },
    text2: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: "#4E5A6B",
        marginLeft: "12px",
        marginBottom: "4px"

    },
    text1: {
        fontSize:"1rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: "#091B29",
        marginLeft: "12px",
        marginBottom: "4px"

    },
    text3: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: "#98A0AC",
        marginLeft: "12px"

    },
    span: {
        backgroundColor: "#4E5A6B",
        borderRadius: "5px",
        color: "white",
        padding: "2px",
        fontSize:"0.75rem",
        marginLeft: "6px"
    },
    periodStyle: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        backgroundColor: "#CED3DD",
        margin: "6px",
    },
    hour: {
        backgroundColor: "#F5F7FA",
        padding: "12px 8px 8px 8px",
        borderRadius: theme.palette.borderRadius,
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        minHeight: 45,
        overflow: "auto"
    },
    TextField: {
        padding: "0px 14px",
    },
    selected: {
        padding: "10px",
        borderRadius: theme.palette.borderRadius,
        color: "white",
        fontSize:"0.875rem",
        backgroundColor: "#F1F7FF",
        cursor: "pointer",
        border: "1px solid #5078E1",
        justifyContent: "space-between"
    },
    unSelected: {
        padding: "10px",
        borderRadius: theme.palette.borderRadius,
        fontSize:"0.875rem",
        cursor: "pointer",
        border: "1px solid #E4E8EE",
        justifyContent: "space-between"
    },
    label: {
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().semiBold,
        fontSize:"0.75rem",
        marginBottom: "5px",
    },
    heading: {
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().semiBold,
        fontSize:"0.875rem",
    },
    subHeading: {
        color: theme.typography.color.secondary,
        fontSize:"0.75rem",
    },
    box: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px",
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
    },
    info: {
        padding: "8px",
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        backgroundColor: theme.typography.color.secondary,
        left: "25px",
        bottom: "-10px",
    },
    subHeadingInfo: {
        color: "#E4E8EE",
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
    },
    box2: {
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px",
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
    },
    box3: {
        alignItems: "center",
        padding: "16px",
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        display: "flex"
    },
    box4: {
        alignItems: "center",
        marginLeft: "80px",
        display: "flex"
    },
    delete: {
        padding: "8px",
        backgroundColor: "#fff",
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #E4E8EE",
    },
    bg: {
        backgroundImage: `url(/images/bgfordelivery.svg)`,
    },
    color: {
        color: theme.palette.primary.main,
        textAlign: "center",
        marginRight: "40px"
    }
}));