import React from "react";
import { withTranslation } from "react-i18next";
import {
  Subheader,
  QuotationSummery,
  QuotationContactCreate,
} from "../../components";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Grid, Typography, Button, Drawer } from "@mui/material";
import { quotationsStyles } from "./style";
import { DetailsSection, UnitDetails } from "./sections";
import {
  accessCheckRender,
  getRoutePermissionNew,
  NetWorkCallMethods,
  quotationStatus,
  AlertProps,
} from "../../utils";
import { AuthContext, BackdropContext, AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";

const ReservationDetails = ({ t }) => {
  const navigate = useNavigate();
  const classes = quotationsStyles();
  // useRef
  const permission = React.useRef([]);
  // context
  const auth = React.useContext(AuthContext);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  // search param
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  // state
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [selectedAcc, setSelectedAcc] = React.useState("");
  const [details, setDetails] = React.useState({
    quott: {},
    units: [],
    reservation: {},
  });

  //get quoation details
  const getQuotDetail = () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    const payload = {
      quotation_id: id,
    };
    NetworkCall(
      `${config.api_url}/quotation/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setDetails({
          quott: response.data.quotationDetails[0],
          units: response.data.units,
          reservation: response.data.reservation,
        });
        setSelectedAcc(response?.data?.quotationDetails[0]?.account_id ? {
          id: response?.data?.quotationDetails[0]?.account_id,
          name: response?.data?.quotationDetails[0]?.account_name,
          value: response?.data?.quotationDetails[0]?.account_id,
          label: response?.data?.quotationDetails[0]?.account_name,
          account_no: response?.data?.quotationDetails[0]?.account_mobile_no_country_code,
          value1: response?.data?.quotationDetails[0]?.account_mobile_no_country_code,
        } : null)


        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "Loading",
        });
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "Loading",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
      });
  };

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      permission.current = perm;
      if (perm?.read) {
        getQuotDetail();
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  const render = () => {
    return (
      <div>
        {/* sub Navbar */}
        <Subheader
          title={`${details?.quott?.first_name ?? ""} ${details?.quott?.last_name ?? ""
            } ${details?.quott?.lead_no ? `(${details?.quott?.lead_no})` : ""}`}
          goBack={() => {
            navigate();
          }}
          placeholder={t("Search")}
        />
        <Box className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={3.5}>
              <DetailsSection t={t} details={details} id={id} permission={permission?.current} />
            </Grid>
            <Grid item xs={4.5}>
              <Box>
                <UnitDetails t={t} list={details?.units} />
              </Box>
            </Grid>
            <Grid item xs={4} padding="16px 16px 0px 16px">
              <Box height={"16px"} />
              <Box className={classes.root}>
                {/*Quotation Summary*/}
                <Typography className={classes.unitTitle}>
                  {t("Quotation_Summary")}
                </Typography>
                <Box height={"16px"} />
                <div className={classes.summeryRoot}>
                  <QuotationSummery
                    t={t}
                    symbol={details?.quott?.symbol}
                    datas={{
                      totalAmount: details?.quott?.total_amount,
                      totalrefundableTax: details?.quott?.total_refundable,
                      totalTax: details?.quott?.total_tax,
                      total: details?.quott?.total_amount,
                      totalDiscount: details?.quott?.total_discount,
                    }}
                    total={
                      details?.total_quote_amount ??
                      details?.quott?.total_amount +
                      details?.quott?.total_tax -
                      details?.quott?.total_discount
                    }
                    height="310px"
                  />
                </div>

                {/*button container shown if status equal to draft*/}
                {(details?.quott?.status !== quotationStatus?.won ||
                  details?.quott?.status !== quotationStatus?.notIntrseted) &&
                  permission?.current?.create && (
                    <Box className={classes.btnParent}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          {" "}
                        </Grid>
                        <Grid item xs={12}>
                          {/*Convert to Agreement  btn*/}
                          <Button
                            onClick={() => setOpenDrawer(true)}
                            variant="contained"
                            className={classes.btn}
                            fullWidth
                          >
                            {t("Convert_Agreement")}
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/*create Aggreement dialog*/}


        <React.Fragment key={'right'}>
          <Drawer
            anchor={'right'}
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
          >


            <QuotationContactCreate
              onClose={() => setOpenDrawer(false)}
              quotation_id={id}
              data={details}
              t={t}
              type={details?.quott?.revenue_type}
              email_id={details?.quott?.email_id}
              contactId={details?.quott?.contact_id}
              company_id={details?.quott?.company_id}
              setSelectedAcc={setSelectedAcc}
              selectedAcc={selectedAcc}
              reload={getQuotDetail}
              total={
                details?.quott?.total_amount +
                details?.quott?.total_tax -
                details?.quott?.total_discount
              }
            />
          </Drawer>
        </React.Fragment>

      </div >
    );
  };

  return <div> {accessCheckRender(render, permission?.current)}</div>;
};
export default withTranslation("reservation")(ReservationDetails);
