import { Box, Button } from "@mui/material";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import RestoreErrorImage from "../../assets/restoreErrorhandling";
import { withTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
export const useStyles = makeStyles((theme) => ({
    imgBlock: {
        width: "100%",
        // margin: "50px auto",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
}))
const RestoreErrorHandling = ({ t, state = {} }) => {
    const classes = useStyles()
    const navigate = useNavigate()
    const goBack = () => {
        navigate(-1);
    }
    return (
        <center>
            <Box className={classes.imgBlock}>
                <RestoreErrorImage message={state?.msg ? t(state?.msg) : null} />
                <Box mt={1}>
                    <Button variant="contained" onClick={goBack}>
                        {t("tryAgain")}
                    </Button>
                </Box>
            </Box>
        </center>
    )
}
export default withTranslation("backupRestore")(RestoreErrorHandling)