import { Avatar, Box, Divider, Grid, Stack, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import React from "react";
import { withTranslation } from "react-i18next";
import { stringAvatar } from "../../../utils";
import { AggrementComponentStyles } from "./styles";

const CustomerInfoCard = ({ data = {}, t = () => false }) => {
  // classes
  const classes = AggrementComponentStyles();
  return (
    <Card className={`${classes.cardHeader}`}>
      <Stack direction={"row"}
        paddingInlineStart={"17px"}
        alignItems={"center"}
        divider={<Divider orientation="vertical" flexItem sx={{ height: "90px", marginInline: "16px" }} />}>
          <Avatar
            src={data?.image}
            alt="Profile Pic"
            className={`${classes.image}`}
            {...stringAvatar(data?.name)}
          />
        <Box sx={{width:"100%"}}>

          <Grid container>
            <Grid item xs={12}>
              <Typography className={`${classes.title}`}>
                {data?.title ?? ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {/* name */}
            <Grid item xs={3}>
              <Typography className={`${classes.label}`}>{t("name")}</Typography>
              <Typography className={`${classes.text}`} noWrap>
                {data?.name ?? "-"}
              </Typography>
            </Grid>
            {/* account */}
            {data?.account_number &&
              <Grid item xs={3}>
                <Typography className={`${classes.label}`}>
                  {t("accountNumber")}
                </Typography>
                <Typography className={`${classes.text}`} noWrap>
                  {data?.account_number ?? "-"}
                </Typography>
              </Grid>
            }
            {/* Email Id */}
            <Grid item xs={3}>
              <Typography className={`${classes.label}`}>  {t("email_id")}</Typography>
              <Typography className={`${classes.text}`} noWrap>
                {data?.email ?? "-"}
              </Typography>
            </Grid>
            {/* Mobile Number */}
            <Grid item xs={3}>
              <Typography className={`${classes.label}`}>
                {t("mobile_no")}
              </Typography>
              <Typography className={`${classes.text}`} noWrap>
                {data?.phone ?? "-"}
              </Typography>
            </Grid>
          </Grid>
        </Box >
      </Stack >

    </Card >
  );
};
export default withTranslation("agreement")(CustomerInfoCard);
