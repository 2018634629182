import { Avatar, Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { stringAvatar } from '../../../utils'
import { VenueCardStyle } from './style'

const VenueCard = ({ list = [], showAvailableSlot = () => false, selectedVenue = "", t }) => {
    const classes = VenueCardStyle()
    return (
        <Stack className={classes.root} spacing={1}>
            {
                list?.map(li => {
                    return (
                        <Box className={(selectedVenue?.facility_id === li.facility_id) ? classes.selectedbox : classes.box}
                            onClick={() => showAvailableSlot(li)}>
                            <Stack direction={"row"} alignItems="center" spacing={2} p={2}>

                                <Avatar src={li.assets[0]} {...stringAvatar(li?.description)}></Avatar>
                                <Stack sx={{ marginInlineStart: '16px' }}>
                                    <Typography className={classes.name}> {li?.description} </Typography>
                                    <Typography className={classes.slot}> {li?.free_slots} {t("slotavailable")}</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    )
                })
            }
        </Stack >
    )
}

export default withTranslation("amenityBooking")(VenueCard)
