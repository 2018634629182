import { Grid } from "@mui/material";
import React from "react";
import { useNavigate } from 'react-router-dom';
import { SearchFilter, Subheader, TableWithPagination } from "../../components";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { Routes } from "../../router/routes";
import { getCompanyOption, Managementheading, Managementpath, getRoutePermissionNew, accessCheckRender, NetWorkCallMethods } from "../../utils";
import { useStylesManagement } from "./style";
import { withTranslation } from "react-i18next";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";

const ManagementCommittee = ({
      t
}) => {
      const classes = useStylesManagement();
      const navigate = useNavigate();
      const auth = React.useContext(AuthContext);
      const backdrop = React.useContext(BackdropContext);
      const alert = React.useContext(AlertContext)
      const [list, setList] = React.useState({})
      const [searchText, setSearchText] = React.useState("");
      const [page, setPage] = React.useState(1);
      const [limit, setLimit] = React.useState(10);
      const [companyList, setCompanyList] = React.useState([]);
      const [selectedCompany, setSelectedCompany] = React.useState({});
      const [propertyId, setPropertyId] = React.useState([])
      const [permissions, setPermission] = React.useState({})

      const rowClick = (data) => {
            navigate(Routes.managementcommitteeView, {
                  state: {
                        main: {
                              propertyID: data?.id,
                              company_id: selectedCompany?.value,
                              PropertyName: data?.PropertyName
                        }
                  }
            })
      }
      const propertylist = (v, searchText, offSet, limit) => {
            backdrop.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: "Loading",
            });
            setSearchText(searchText)
            const payload = {
                  id: v,
                  search: searchText,
                  offset: offSet,
                  limit: limit,
            }

            NetworkCall(
                  `${config.api_url}/queries/get_property_management_committe/get`,
                  NetWorkCallMethods.post,
                  payload,
                  null,
                  true,
                  false
            ).then((res) => {
                  const list = res?.data?.data?.property?.map((val) => {
                        let _d;
                        try {
                              _d = {
                                    Image: val?.logo ?? "",
                                    id: val?.id ?? "-",
                                    property_no: val?.property_no ?? "-",
                                    PropertyName: val?.name ?? "-",
                                    revenueType: val?.revenue_type ?? "-",
                                    Roles: val?.management_committee?.length ?? "-",
                                    location: (val?.address?.city !== "" || val?.address?.state !== "") ? `${val?.address?.city ? val?.address?.city + "," : ""} ${val?.address?.state ?? ""}` : "-"

                              };
                        } catch (err) {

                        }
                        return _d;
                  })
                  setList({
                        count: res?.data?.count,
                        list: list
                  })
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            }).catch((err) => {

            })
      }
      const handlePagination = (value) => {
            setPage(value);
            let offSet = (value - 1) * limit;
            propertylist(propertyId, searchText, offSet, limit)
      }
      const handleChangeLimit = (value) => {
            setLimit(value);
            setPage(1);
            propertylist(propertyId, searchText, 0, value)
      }
      const handleCompanyChange = (e) => {
            let id = []
            e?.property?.map((list) => { return id.push(list.id) })
            setPropertyId(id)
            setSelectedCompany(e)
            propertylist(e?.property?.map((x) => x?.id), searchText, 0, limit)
      }
      React.useEffect(() => {
            let company = getCompanyOption(backdrop, auth, alert)
            const perm = getRoutePermissionNew(auth)
            if (perm) {
                  setPermission(perm)
                  if (perm?.read) {
                        if (company) {
                              setCompanyList(company?.list)
                              setSelectedCompany(company?.selected)
                              let id = []
                              company?.selected?.property?.map((list) => { return id.push(list.id) })
                              setPropertyId(id)
                              propertylist(company?.selected?.property?.map((x) => x?.id), searchText, 0, limit)
                        }
                  }
            }
            // eslint-disable-next-line
      }, [auth])
      const render = () => {
            return (
                  <>
                        <Subheader hideBackButton={true} title={`${t("Management Committee")}   (${list?.count ?? 0})`} select options={companyList} value={selectedCompany}
                              goBack={() => {
                                    navigate(Routes.home)
                              }}
                              onchange={(e) => {
                                    handleCompanyChange(e)
                                    // getProperty(e)
                              }

                              } />
                        <div className={classes.root}>
                              <Grid container className={classes.content} >
                                    <Grid item xs={4}>
                                          <SearchFilter
                                                value={searchText}
                                                handleChange={(value) => propertylist(propertyId, value, 0, 10, value)}
                                                placeholder={t("Search by Property Name")}
                                                customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
                                          />
                                    </Grid>



                                    {/* Main tableData */}
                                    <Grid item xs={12} className={classes.tableMain}>
                                          <TableWithPagination
                                                heading={Managementheading(t)}
                                                rows={list?.list ?? ""}
                                                path={Managementpath}
                                                showpagination={true}
                                                onClick={rowClick}
                                                onUnitClick={() => (false)}
                                                showpdfbtn={false}
                                                showexcelbtn={false}
                                                showSearch={false}
                                                handlePagination={handlePagination}
                                                handleChangeLimit={handleChangeLimit}
                                                totalRowsCount={list?.count}
                                                tableType="no-side"
                                                page={page}
                                                limit={limit}
                                                dataType={[
                                                      { type: ["avatarmanagement"], name: "Image" },
                                                      { type: ["text"], name: "PropertyName" },
                                                      // { type: ["text"], name: "revenueType" },
                                                      { type: ["text"], name: "location" },
                                                      { type: ["text"], name: "Roles" },
                                                ]}
                                                height={'calc(100vh - 280px)'}
                                                view={permissions?.read}
                                                edit={permissions?.update}
                                                delete={permissions.delete} />
                                    </Grid>
                                    {/* end Main tableData */}


                              </Grid>
                        </div>
                  </>
            );
      }
      return (
            <>
                  {accessCheckRender(render, permissions)}
            </>

      )
};
export default withTranslation("managementcommittee")(ManagementCommittee);
