/* eslint-disable no-unused-vars */
import {
  Box, Grid, IconButton
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchFilter, Subheader, TableWithPagination } from "../../components";
import { GenerateForm } from ".."
import { AlertContext, BackdropContext } from "../../contexts";
import { Routes } from '../../router/routes';
import {  LocalStorageKeys, Quotationheading, Quotationpath } from '../../utils';
import { UseDebounce } from '../customHooks';
import FilterComponent from './filter';
import { FontFamilySwitch } from '../../utils';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px",
  },
  content: {
    border: "2px solid white",
    borderRadius: "12px",
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
    // height: `calc(100vh - 88px)`
  },

  btn: {
    borderRadius: theme.palette.borderRadius,
  },
  btn2: {
    borderRadius: theme.palette.borderRadius,
    color: theme.palette.secondary.main,
  },
  text: {
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary
  },
  subText: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.secondary
  },
  img: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "10px"
  },
  searchdrop: {
    borderRadius: "10px",
    border: "1px solid #E4E8EE",
    cursor: "pointer",
  },
  chip: {
    backgroundColor: "#F2F4F7",
    marginLeft: "6px",
    marginTop: "10px",
    borderRadius: theme.palette.borderRadius,
    fontSize:"0.875rem",
    cursor: "pointer",
    border: "none",
    boxShadow: "none",
    color: theme.typography.color.secondary,
    "&:hover": {
      border: "none",
      boxShadow: "none",
    },
    display: "flex",
    alignItems: "center",
  },
  Label: {
    color: theme.typography.color.secondary,
    fontSize:"0.75rem",
    marginTop: "6px",
  },
  UnitStatusactive: {
    padding: "10px 16px",
    textAlign: "center",
    borderRadius: theme.palette.borderRadius,
    color: "white",
    fontSize:"0.875rem",
    backgroundColor: "#5078E1",
    cursor: "pointer",
  },
  UnitStatus: {
    padding: "10px 16px",
    textAlign: "center",
    borderRadius: theme.palette.borderRadius,
    fontSize:"0.875rem",
    cursor: "pointer",
    backgroundColor: "#F2F4F7",
  },
  previousbtn: {
    backgroundColor: "white",
    color: "#091B29",
    border: "1px solid #CED3DD",
    borderRadius: "10px",
    padding: "12px 8px",
    boxShadow: "none",
  },
}))

const FilterDependency = {
  companyName: ['propertyName', 'unitName', 'createdBy'],
  propertyName: ['unitName']
}

const Quotation = props => {
  const { t } = props
  const companyIDs = JSON.parse(localStorage.getItem(LocalStorageKeys.role))?.filter(_ => _?.name === 'Manager')?.map(_ => _?.company_id);
  const { setBackDrop, open: loading } = React.useContext(BackdropContext);
  const classes = useStyles();
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const navigate = useNavigate();
  const [quotationData, setQuotationData] = useState({});
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [searchValue, setSearchValue] = React.useState('');
  const [openFilter, setOpen] = React.useState(false);
  const [filterData, setFilterData] = useState({});
  const [filterApplied, setFilterApplied] = useState(false);

  //debounce hook
  const debounce = UseDebounce();
  //handle pagination
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
  }
  //change limit
  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
  }
  //handle search
  const handleSearch = (e) => {
    setSearchValue(e)
    debounce(() => searchTableFunction(e), 800)
  }
  //table function
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
  }
  //handle more icon 
  const handleIcon = (type, data) => {
    navigate({
      pathname: `${Routes.quotation_view.split('/:')[0]}/${data?.id}`
    })
  }
  //fillter drawer
  const handleFilterDrawer = (bool = false) => {
    if (bool && !filterApplied) {
      setFilterData({});
    }
    setOpen(bool)
  }

  const onChangeFilterState = (key, value) => {

    setFilterData(() => {
      if (FilterDependency.hasOwnProperty(key)) {
        FilterDependency[key].map(_ => filterData[_] = []);
        return {
          ...filterData, [key]: value
        }
      }
      else {
        return {
          ...filterData, [key]: value
        }
      }
    })
  }

  const applyFilter = () => {

    const retrunIdfromMap = (array) => array?.map(({ value }) => value) ?? [];

    let payload = {
      company_id: retrunIdfromMap(filterData?.companyName),
      property_id: retrunIdfromMap(filterData?.propertyName),
      unit_id: retrunIdfromMap(filterData?.unitName),
      created_by: retrunIdfromMap(filterData?.createdBy),
      status: retrunIdfromMap(filterData?.status),
      final_status: filterData?.finalStatus ?? '',
    }

    if (page > 1) {
      setPage(1);
    }

    setBackDrop({
      open: true,
      message: t("Fetching data"),
    });

    handleFilterDrawer();
    setFilterApplied(true)


  }

  const clearAllFilter = () => {
    setFilterData({});
  }

  const goBack = () => {
    navigate(-1);
  }

  return (
    <>
      <Subheader title={t("Quotation")} goBack={goBack} />
      <div className={classes.root}>

        <Grid container className={classes.content} >
          <Grid item xs={4}>
            <SearchFilter
              value={searchValue}
              handleChange={handleSearch}
              placeholder={t("Search Quotation")}

            />
          </Grid>
          <Grid
            item xs={8}
            textAlign={"right"}
          >
            <Box>
              <IconButton size='small' className={classes.img} onClick={() => handleFilterDrawer(true)}>
                <img src='/images/filter.svg' alt='filter' />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {Quotationheading && <TableWithPagination
              heading={Quotationheading}
              // rows={Quotationrow}
              rows={quotationData?.data}
              path={Quotationpath}
              showpagination={true}
              count="2"
              tableType="no-side"
              onClick={() => false}
              handleIcon={handleIcon}
              showpdfbtn={false}
              showexcelbtn={false}
              showSearch={false}
              dataType={[
                { type: ["text"], name: "quotationId" },
                { type: ["text"], name: "leadName" },
                { type: ["text"], name: "agent" },
                { type: ["text"], name: "units" },
                { type: ["text"], name: "value" },
                { type: ["text"], name: "period" },
                { type: ["text"], name: "expireOn" },
                { type: ["status"], name: "status" },
                { type: ["icon"], icon: "icon" },
              ]}
              handlePagination={handlePagination}
              handleChangeLimit={handleChangeLimit}
              totalRowsCount={quotationData?.totalRowsCount}
              page={page}
              limit={limit}
              height={370}
              view={true}
              edit={true}
              delete={true} />}
          </Grid>
          {openFilter &&
            <GenerateForm
              parent_id={"quotation_listing_drawer"}
              nocancel
              readOnly
              loading={false}
              open={openFilter}
              handlecancel={handleFilterDrawer}
              header={'Filter'}
            >
              <FilterComponent
                data={filterData}
                onChange={onChangeFilterState}
                applyFilter={applyFilter}
                clearAllFilter={clearAllFilter}
              />
            </GenerateForm>}
        </Grid>
      </div>
    </>
  )
}
export default withTranslation("quotationsList")(Quotation)









