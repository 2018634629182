
import { Close } from "@mui/icons-material";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import {
  Avatar, Box,
  Button,
  FormControlLabel,
  Grid,
  Stack, Switch, Tooltip,
  tooltipClasses,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { t } from "i18next";
import jsPDF from "jspdf";
import { QRCodeCanvas } from "qrcode.react";
import React, { useRef } from "react";
import InspectionTemplateImg from "../../assets/inspectionTemplate";
import { AlertDialog } from "../dialog";
import { generalCardStyles } from "./style";

export const GeneralCard = ({
  list = "",
  buttonText = "",
  handleChange = () => false,
  decreaseQty = () => false,
  increaseQty = () => false,
  view = false,
  totalItems = "",
  isDot,
  handleUpdate = () => false,
  productId,
  imageAvatar,
  component = "",
  handleView = () => false,
  hideBtn = false,
  handleCheck = () => false,
  type = "",
  isViewProduct = false
}) => {
  const classes = generalCardStyles();
  const [value, setValue] = React.useState({
    bool: false
  })
  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 35,
    height: 20,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: "1px 0px",
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 18,
      height: 18,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const switchComponent = (val) => {
    switch (val) {
      case "toggleButton":
        return (
          <div>
            <FormControlLabel
              className={classes.toggle}
              checked={list?.is_active}
              onChange={(e) => handleCheck(e.target.checked)}
              control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
            />
          </div>
        );
      case "increment":
        return (
          <>
            {list.open ? (
              <div className={classes.cart}>
                <Button onClick={decreaseQty}> - </Button>
                <Typography className={classes.cartText}>{list.count}</Typography>
                <Button onClick={increaseQty}> + </Button>
              </div>

            ) : (
              type === "template" ?
                list?.is_active &&

                <div className={classes.addBtn} onClick={handleChange}>
                  {buttonText}
                </div>

                :

                <div className={classes.addBtn} onClick={handleChange}>
                  {buttonText}
                </div>
            )}
          </>
        )

      default:
        return null;
    }
  };
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#DADDE9",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#FFFFFF",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 190,
      fontSize: "0.75rem",
      border: "1px solid #DADDE9",
    },
  }));
  const qrContainerRef = useRef(null);
  const doc = new jsPDF();
  const downloadQRCode = async () => {
    const qrCodeURL = document.getElementById('qrCodeEl')
    const pngUrl = qrCodeURL.toDataURL("image/png").replace("image/png", "image/octet-stream");
    let aEl = document.createElement("a");
    aEl.href = pngUrl;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);

  }
  const handleDownloadPdf = () => {
    // html2canvas(qrContainerRef.current).then((canvas) => {
    //   const imgData = canvas.toDataURL("image/png");
    //   doc.addImage(imgData, "PNG", 15, 25, 150, 150);
    // });
    const qrCodeURL = document.getElementById('qrCodeEl')
    const pngUrl = qrCodeURL.toDataURL("image/png").replace("image/png", "image/octet-stream");
    doc.addImage(pngUrl, "PNG", 55, 15, 90, 90);
    // <---Item Name--->
    doc.setTextColor("#4E5A6B");
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text(`Item Name: `, 60, 115);

    doc.setTextColor("#091B29");
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text(`${list?.name}`, 135, 115, null, null, "right");
    // <---Serial Number--->
    doc.setTextColor("#4E5A6B");
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text(`Serial Number: `, 60, 122);

    doc.setTextColor("#091B29");
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text(`${list?.serial_number ?? "-"}`, 135, 122, null, null, "right");
    // <---Manufacturer--->
    doc.setTextColor("#4E5A6B");
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text(`Manufacturer Name: `, 60, 130);

    doc.setTextColor("#091B29");
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text(`${list?.manufacturer ?? "-"}`, 135, 130, null, null, "right");
    doc.save("qr_code.pdf");
  };
  const handleQRdownload = async (type) => {
    if (type === "image") {
      await downloadQRCode()
    }
  }
  return (
    <>
      {!isViewProduct ?
        <Grid container>
          <Grid item xs={12}>
            <Box>
              <div>
                <div className={classes.generalCard}>

                  <div className={classes.imageText}>
                    <Avatar className={classes.avatar}>
                      {
                        list?.image ?
                          <img src={list?.image} style={{ objectFit: "cover" }} alt="" /> :
                          <InspectionTemplateImg />
                      }


                    </Avatar>
                    <div>
                      <div className={classes.title}>
                        <Typography className={classes.text}>
                          {list?.Name}
                        </Typography>
                        {list?.mandatory && (
                          <div className={classes.clipIcon}>
                            <BootstrapTooltip
                              placement="right-start"
                              title={
                                <React.Fragment>
                                  <Typography className={classes.tooltipTitle}>
                                    Mandatory Inventory Item
                                  </Typography>
                                  <Typography className={classes.tooltipSubTitle}>
                                    This item need to be verified and need to be
                                    intact.
                                  </Typography>
                                </React.Fragment>
                              }
                            >
                              <AssignmentOutlinedIcon />
                            </BootstrapTooltip>
                          </div>
                        )}
                      </div>
                      {view === true && (
                        <div className={classes.subText}>
                          <div className={classes.items}>
                            <Typography>{totalItems} Items</Typography>
                          </div>
                          {isDot && <span className={classes.dot}></span>}
                          <div className={classes.viewText} onClick={() => handleView(list)}
                          >
                            <Typography sx={{ fontSize: "0.75rem" }}>View</Typography>
                          </div>
                        </div>
                      )}
                      {productId === true && (

                        <div className={classes.subText}>
                          <div className={classes.items}>
                            <Typography>Product Id:</Typography>
                          </div>
                          <Stack marginLeft="8px" alignItems="center" direction={"row"} spacing={1} >
                            <Typography className={classes.tooltipTitle}>{list?.product_id ?? "-"}</Typography>
                            {/* {list?.item_type === "Inventory" &&
                              <Typography className={classes.viewText} onClick={handleUpdate}>Update</Typography>
                            } */}
                          </Stack>


                        </div>
                      )}
                    </div>
                  </div>
                  {switchComponent(component)}
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
        :
        <Box className={classes.productCard}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={9} md={9} lg={9}>
              <Stack direction="row" alignItems="center">
                <Avatar className={classes.avatar}>
                  {
                    list?.image ?
                      <img src={list?.image} style={{ objectFit: "cover" }} alt="" /> :
                      <img src="/images/Group 98362.svg" alt="" />
                  }
                </Avatar>
                <Box>
                  <Typography className={classes.itemTitle}>{list?.reference_no}</Typography>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography className={classes.itemDataTitle}>{list?.item_reference_no}</Typography>
                    <Box className={classes.itemspacing}></Box>
                    <Typography className={classes.itemDataTitle}>{list?.item}</Typography>
                  </Stack>
                </Box>
              </Stack>
              <Box display="flex" mt={1} alignItems={"center"}>
                <Typography className={classes.itemDatasubTitle}>{t("Item Name")} :</Typography>
                <Typography className={classes.itemDatasubvalue}>{list?.item_name ?? "-"}</Typography>
              </Box>
              <Box display="flex" mt={0.5} alignItems={"center"}>
                <Typography className={classes.itemDatasubTitle}>{t("Manufacturer Name")} :</Typography>
                <Typography className={classes.itemDatasubvalue}>{list?.manufacturer ?? "-"}</Typography>
              </Box>
            </Grid>
            {list?.id?.length > 0 &&
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <QRCodeCanvas value={list?.id} size={96} />
                <Box mt={1} display="flex" justifyContent={"center"}>
                  <Button className={classes.downloadbtn} onClick={() => setValue({ bool: true })} id='basic-button'>{t("View QR")}</Button>
                </Box>
              </Grid>
            }
          </Grid>
          <AlertDialog
            isnotTitle
            open={value?.bool}
            onClose={() => setValue({ bool: false })}
            isNormal
            component={
              <Box p={2}>
                <Box display="flex" justifyContent={"end"} onClick={() => setValue({ value: false })} sx={{ cursor: "pointer" }}>
                  <Close />
                </Box>
                <Box mt={2}>
                  <center>
                    <div ref={qrContainerRef}>
                      <QRCodeCanvas value={list?.id} id={'qrCodeEl'} size={350} />
                    </div>
                    <Box sx={{padding:"16px 28px"}}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Button className={classes.downloadbtntwo} variant="contained" fullWidth onClick={() => handleQRdownload("image")}>{t("Download as Image")}</Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button className={classes.downloadbtntwo} variant="contained" fullWidth onClick={handleDownloadPdf}>{t("Download as Pdf")}</Button>
                      </Grid>
                    </Grid>
                    </Box>
                  </center>
                </Box>
              </Box >
            }
          />
        </Box>

      }
    </>
  );
};