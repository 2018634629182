import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Button, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindowDimensions } from "../../utils";
import { useStylesCreation } from "./style";


const TabTitle = (props) => {
  return (
    <div className={props?.selectBack}>
      <div>{props?.datavalue?.imgSelect}</div>
      <div style={{ marginInlineStart: "10px", textAlign: "left" }}>
        <Typography className={props?.class}>
          {props?.datavalue?.label}
        </Typography>
        <Typography className={props?.subtabtitle} flexWrap>
          {props?.datavalue?.sub}
        </Typography>
      </div>
      {/* {props?.isEdit && (
                        <div
                              style={{ marginLeft: "14px", textAlign: "left" }}
                              onClick={() =>
                                    props?.handleedit
                                          ? props?.handleedit(props?.datavalue?.value)
                                          : null
                              }
                        >
                              <img src="/images/Outlineselect.svg" alt="" />
                        </div>
                  )} */}
      {/* {!props?.isAfter && <div className={classes.after}>|</div>} */}
    </div>
  );
}



export function PropertyTabs(props) {
  const size = useWindowDimensions();
  const classes = useStylesCreation(props);
  const navigate = useNavigate()
  const {
    style = {},
    t,
    backgroundColor,
    tabPanelStyle = {},
  } = props;
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={props?.value} variant="scrollable">
        <Box className={classes.tabBox}>
          <Box sx={{width: props?.customTabWidth ?? "auto"}}>
            <TabList
              sx={{ padding: "0px", backgroundColor: backgroundColor }}
              onChange={props?.handleChange ?? ""}
              aria-label="lab API tabs example"
              className={classes.tabs}
            >
              {props?.tabTittle?.map((x, index) => {
                return (
                  (x?.is_active === false ? false : true) && <Tab
                    label={
                      <TabTitle

                        isAfter={props?.isAfter}
                        handleedit={props?.handleedit ?? ""}
                        datavalue={x}
                        index={index}
                        isEdit={props?.isEdit}
                        class={x?.className}
                        subtabtitle={x?.subtabtitle}
                        selectBack={x?.selectBack}

                      />
                    }
                    value={x?.value}
                    tabvalue={props?.value}
                    className={classes.tabs}
                  />
                );
              })}
            </TabList>
          </Box>
          {props?.showBtns && (
            <Box padding={props?.buttonGroupPadding ?? props?.padding ?? "10px 8px 8px 8px"} marginRight="12px" display="flex">

              {props?.isEdit ? (
                <Button
                  variant="contained"
                  className={classes.next}
                  sx={{ ...props?.buttonStyle }}
                  onClick={() =>
                    props?.handleedit
                      ? props?.handleedit(props?.datavalue?.value)
                      : null
                  }
                >
                  {t("Edit")}
                </Button>
              ) : (
                <>
                  {props?.delete ? (
                    <Button
                      className={classes.Cancel}
                      onClick={props?.onDelete}
                    >
                      {t("Delete")}
                    </Button>
                  ) : (
                    !props?.is_cancel && (
                      <Button
                        className={classes.Cancel}
                        onClick={() => navigate(-1)}
                      >
                        {t("Cancel")}
                      </Button>
                    )
                  )}
                  {props?.saveBtnText && (
                    <Button
                      variant="contained"
                      className={classes.next}
                      onClick={() => props?.save()}
                      disabled={props?.isSaveDisable}
                    >
                      {props?.saveBtnText ?? t("save")}
                    </Button>
                  )}
                </>
              )}
            </Box>
          )}
        </Box>
        <div
          style={{
            overflow: "scroll",
            height: props?.isHeigth ? size.height - (65 + 54 + 57) : "",
            ...style,
          }}
        >
          <TabPanel
            className={
              props?.padding ? classes.tabPanel : classes.tabPanel1
            }
            sx={{...tabPanelStyle}}
            value={1}
          >
            {props?.tab1}
          </TabPanel>
          <TabPanel
            className={
              props?.padding ? classes.tabPanel : classes.tabPanel1
            }
            sx={{...tabPanelStyle}}
            value={2}
          >
            {props?.tab2}
          </TabPanel>
          <TabPanel
            className={
              props?.padding ? classes.tabPanel : classes.tabPanel1
            }
            sx={{...tabPanelStyle}}
            value={3}
          >
            {props?.tab3}
          </TabPanel>
          <TabPanel
            className={
              props?.padding ? classes.tabPanel : classes.tabPanel1
            }
            sx={{...tabPanelStyle}}
            value={4}
          >
            {props?.tab4}
          </TabPanel>
          <TabPanel
            className={
              props?.padding ? classes.tabPanel : classes.tabPanel1
            }
            sx={{...tabPanelStyle}}
            value={5}
          >
            {props?.tab5}
          </TabPanel>
          <TabPanel
            className={
              props?.padding ? classes.tabPanel : classes.tabPanel1
            }
            sx={{...tabPanelStyle}}
            value={6}
          >
            {props?.tab6}
          </TabPanel>
          <TabPanel
            className={
              props?.padding ? classes.tabPanel : classes.tabPanel1
            }
            sx={{...tabPanelStyle}}
            value={7}
          >
            {props?.tab7}
          </TabPanel>
          <TabPanel
            className={
              props?.padding ? classes.tabPanel : classes.tabPanel1
            }
            sx={{...tabPanelStyle}}
            value={8}
          >
            {props?.tab8}
          </TabPanel>
        </div>
      </TabContext>
    </Box>
  );
}
