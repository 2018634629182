/* eslint-disable no-unused-vars */
import {
  Backdrop,
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  List,
  Tooltip,
  Popover,
  ListItem,
  Link,
  Grid,
  Paper,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { DownloadImage } from "../../assets";
import {
  SearchFilter,
  Subheader,
  TableWithPagination,
  FilterGenerator,
  UseDebounce,
  DrawerComponent,
  FilterComponent,
} from "../../components";
import { CreateLead } from "../../components/createLead";
import { LeadComments } from "../../components/viewComments/leadComments";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import {
  accessCheckRender,
  AlertProps,
  enum_types,
  enumSelect,
  getCompanyOption,
  getRoutePermissionNew,
  LocalStorageKeys,
  NetWorkCallMethods,
  wrapLabelsInT,
} from "../../utils";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { Leadheading, Leadpath } from "../../utils/tableData";
import { CreateLeadContext } from "./leadcontext";
import { useStyles } from "./styles";
import { loadOptions } from "../../utils/asyncPaginateLoadOptions";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfiniteScroll from "react-infinite-scroll-component";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { set } from "date-fns";

export const Leads = (props) => {
  const {
    leadList,
    commentCount,
    getListData,
    openFilter,
    OpenDrawer,
    searchdata,
    total,
    clearAll,
    companySelectFunction,
    selectedCompany,
    setSelectedCompany,
    setSearchdata,
    t = () => false,
  } = React.useContext(CreateLeadContext);

  const auth = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [listTab, setTab] = React.useState("");
  const [isReportLoading, setIsReportLoading] = React.useState(true);
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const { state } = useLocation();
  const [companyList, setCompanyList] = React.useState([]);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const [permission, setPermission] = React.useState({});
  const [selectedTeams, setSelectedTeams] = React.useState("");
  const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId);
  const clientId = localStorage.getItem(LocalStorageKeys.clinetID);
  const [commentsDrawer, setCommentsDrawer] = React.useState(false);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const [create, setCreate] = React.useState({
    open: false,
    lead_id: null,
  });
  const [state1, setState1] = React.useState(true);
  const [status, setStatus] = React.useState({
    value: [],
    enum: [],
  });
  const [filterData, setFilterData] = React.useState({
    periorty: "",
    status: [2],
    name: "",
    lead: "",
  });
  const debounce = UseDebounce();
  const [downloadedFiles, setDownloadedFiles] = useState([]);
  const [pendingFiles, setPendingFiles] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [scrollOffset, setScrollOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const is_workspace = ["49", "35"].includes(moduleId);
  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");
  const limitParam = searchParams.get("limit");

  // Default values if not set in URL
  const pageFromParams = pageParam ? parseInt(pageParam, 10) : 1;
  const limitFromParams = limitParam ? parseInt(limitParam, 10) : 10;

  const [page, setPage] = React.useState(pageFromParams);
  const [limit, setLimit] = React.useState(limitFromParams);
  const open = Boolean(anchorEl);

  const updatePage = (newPage, newLimit = limit) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", newPage.toString());
    searchParams.set("limit", newLimit.toString());

    // Update URL without reloading
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });

    // Calculate offset
    const newOffset = (newPage - 1) * newLimit;

    // Update state
    setPage(newPage);
    setLimit(newLimit);

    getListData(
      newOffset,
      newLimit,
      listTab,
      "filter",
      selectedCompany,
      selectedTeams,
      filterData
    );
  };

  const performNetworkCall = async (url, method, payload, onSuccess) => {
    try {
      const response = await NetworkCall(
        url,
        method,
        payload,
        null,
        true,
        false
      );
      if (response.status === 200) {
        onSuccess(response);
      } else {
        console.error("Failed to store report details");
      }
    } catch (error) {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Some Thing Went Wrong"),
      });
    }
  };

  const getEnum = async (value) => {
    const result = await enumSelect([enum_types.urgent_type]);
    setStatus({
      value: result?.urgent_type ?? [],
      enum: value ?? [],
    });
  };

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        if (localStorage.getItem("authToken")) {
          let company = getCompanyOption(backdrop, auth, alert);
          if (company) {
            setCompanyList(company?.list);
            getStatusMaster();
            if (state?.main?.leadNew) {
              setSelectedCompany(
                state?.main?.selectedCompany ?? company?.selected
              );
            } else {
              setSelectedCompany(company?.selected);
            }
          }
        } else {
          navigate(Routes.login);
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  // check if the reports in the queue are completed every five seconds
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (pendingFiles.length > 0 && open) {
        // get ids of pending files
        const pendingFileIds = Array.isArray(pendingFiles)
          ? pendingFiles
              .map((file) => file?.id)
              .filter((id) => id !== undefined)
          : [];

        performNetworkCall(
          `${config.api_url}/360_reports/get_report_status`,
          NetWorkCallMethods.post,
          { report_ids: pendingFileIds },
          (response) => {
            // filter undefined data in the array and remove them
            const filteredPendingFiles = pendingFiles.filter(
              (file) => file !== undefined
            );

            // get response files with is_completed = true or is_failed = true
            const updatedFiles = response.data.data;
            const completedOrFailedFiles = updatedFiles.filter(
              (file) => file.is_completed || file.is_failed
            );

            // remove completed files from pending files
            const newPendingFiles = filteredPendingFiles.filter(
              (file) =>
                !completedOrFailedFiles.some(
                  (completedOrFailedFile) =>
                    completedOrFailedFile.id === file.id
                )
            );

            setPendingFiles(newPendingFiles);

            // update downloaded files with the new data
            const newDownloadedFiles = downloadedFiles.map((file) => {
              const updatedFile = updatedFiles.find(
                (updatedFile) => updatedFile.id === file.id
              );
              if (updatedFile) {
                return updatedFile;
              }
              return file;
            });

            setDownloadedFiles(newDownloadedFiles);
          }
        );
      }
    }, 5000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingFiles, open]);

  const handlePagination = (newPage) => {
    updatePage(newPage, limit);
  };

  const handleChangeLimit = (newLimit) => {
    updatePage(1, newLimit); // Reset page to 1 when limit changes
  };
  const OpenCommentsDrawer = () => {
    setCommentsDrawer(!commentsDrawer);
  };

  const handleIcon = (type, data) => {
    if (type === "edit") {
      navigate(Routes.createOpportunity, {
        state: {
          id: data?.id,
          company: {
            value: data?.company_id,
            label: data?.company_name,
          },
        },
      });

      setCreate({
        open: true,
        lead_id: data?.id,
      });
    } else if (type === "double_click") {
      navigate(Routes.leadsDetails, {
        state: {
          opportunity: data,
          company: selectedCompany,
          id: data?.id,
          team: selectedTeams?.value === "noteams" ? null : selectedTeams,
        },
      });
    }
  };

  const onNext = (val) => {
    navigate(Routes.createOpportunity, {
      state: {
        opportunity: val,
        company: selectedCompany,
        is_opportunity: true,
        primary: state1,
      },
    });
  };
  const goCreateOppertunity = () => {
    handleCreate("open", true);
  };
  // Function to change property
  const handleTeamsChange = (team) => {
    const currentOffset = (page - 1) * limit;
    setSelectedTeams(team);
    getListData(
      currentOffset,
      limit,
      listTab,
      "key",
      selectedCompany,
      team,
      filterData
    );
  };

  const fetchMoreReport = () => {
    setIsLoadingMore(true);
    const newScrollOffset = scrollOffset + 5;
    setScrollOffset(newScrollOffset);
    performNetworkCall(
      `${config.api_url}/360_reports/get_reports`,
      NetWorkCallMethods.post,
      { report_type: "opportunity", offset: newScrollOffset, limit: 5 },
      (response) => {
        const getData = response.data.data;
        setDownloadedFiles([...downloadedFiles, ...getData]);

        // get files with is_completed = false
        // eslint-disable-next-line array-callback-return
        const queuedReports = [...downloadedFiles, ...getData].map((file) => {
          if (!file.is_completed) {
            return file;
          }
        });
        setPendingFiles(queuedReports);

        console.log("Report details stored successfully");
        setIsReportLoading(false);
        setIsLoading(false);
        setIsDownloadLoading(false);
        setIsLoadingMore(false);
      }
    );
  };

  const handleDownload = async (event) => {
    setAnchorEl(event.currentTarget);
    setIsReportLoading(true);
    const newScrollOffset = 0;
    setScrollOffset(newScrollOffset);
    performNetworkCall(
      `${config.api_url}/360_reports/get_reports`,
      NetWorkCallMethods.post,
      { report_type: "opportunity", offset: newScrollOffset, limit: 5 },
      (response) => {
        const getData = response.data.data;
        setDownloadedFiles(getData);

        // eslint-disable-next-line array-callback-return
        const queuedReports = getData.map((file) => {
          if (!file.is_completed) {
            return file;
          }
        });
        setPendingFiles(queuedReports);
        console.log("Report details stored successfully");
        setIsReportLoading(false);
        setIsLoading(false);
        setIsDownloadLoading(false);
      }
    );
  };

  const generateRandomAlphanumeric = (length) => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };

  const handleDownloadReport = async () => {
    setIsLoading(true);
    setIsDownloadLoading(true);
    let fileName = "";
    const date = new Date();
    const yy = String(date.getFullYear());
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    const hh = String(date.getHours()).padStart(2, "0");
    const min = String(date.getMinutes()).padStart(2, "0");
    const randomAlphanumeric = generateRandomAlphanumeric(6);
    fileName = `opportunity_report_${yy}_${mm}_${dd}_${hh}_${min}_${randomAlphanumeric}.xlsx`;
    performNetworkCall(
      `${config.api_url}/360_reports/report_downloads`,
      NetWorkCallMethods.post,
      {
        file_name: fileName,
        report_type: "opportunity",
        is_completed: false,
        is_failed: false,
        file_url: null,
        company_id: selectedCompany?.value,
        client_id: clientId,
        status: filterData?.status,
        priority: filterData?.periorty,
        contact:
          filterData?.name === ""
            ? undefined
            : filterData?.name?.map((name) => name?.value),
        leadOwner:
          filterData?.lead === ""
            ? undefined
            : filterData?.lead?.map((lead) => lead?.value),
      },
      async () => {
        console.log("Report details stored successfully");
        const newScrollOffset = 0;
        setScrollOffset(newScrollOffset);
        performNetworkCall(
          `${config.api_url}/360_reports/get_reports`,
          NetWorkCallMethods.post,
          { report_type: "opportunity", offset: newScrollOffset, limit: 5 },
          (response) => {
            const getData = response.data.data;
            setDownloadedFiles(getData);
            // eslint-disable-next-line array-callback-return
            const queuedReports = getData.map((file) => {
              if (!file.is_completed) {
                return file;
              }
            });
            setPendingFiles(queuedReports);
            console.log("Report details stored successfully");
            setIsLoading(false);
            setIsDownloadLoading(false);
          }
        );
      }
    );
  };

  const handleOpenFile = (fileName) => {
    const fileUrl = fileName;
    window.open(fileUrl, "_blank");
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDownloadedFiles([]);
  };

  const id = open ? "simple-popover" : undefined;

  // get status master
  const getStatusMaster = () => {
    NetworkCall(
      `${config.api_url}/queries/opportunity/lead_status_master`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        getEnum(response?.data?.data?.lead_status_master);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
      });
  };

  const onApplyFilter = (value) => {
    setFilterData(value);
    getListData(
      0,
      10,
      listTab,
      "filter",
      selectedCompany,
      selectedTeams,
      value
    );
  };

  const handleCreate = (key, value) => {
    setCreate({
      ...create,
      [key]: value,
      lead_id: null,
    });
  };

  const is_instigator = Object.values(filterData)
    ?.flat()
    ?.filter((v) => !!v)?.length;
  const searchLead = (value) => {
    debounce(
      () =>
        getListData(
          0,
          10,
          value,
          "key",
          selectedCompany,
          selectedTeams,
          filterData
        ),
      800
    );
  };

  const manualResponse = (array) => {
    const details = array?.contact?.map((i) => {
      return {
        label: i?.label,
        value: i?.id,
      };
    });
    return details;
  };
  const manualResponseLead = (array) => {
    return array.data;
  };
  function truncateFileName(fileName) {
    const maxLength = 15;
    if (fileName.length <= maxLength * 2) {
      return fileName;
    }
    const start = fileName.slice(0, maxLength);
    const end = fileName.slice(-maxLength);
    return `${start}...${end}`;
  }
  const render = () => {
    return (
      <>
        <Subheader
          hideBackButton={true}
          title={`${t("Opportunity")}(${total ?? 0})`}
          select
          options={companyList}
          value={selectedCompany}
          onchange={(e) => {
            companySelectFunction(e);
          }}
          selectTeamsOptions
          handleTeamsChange={handleTeamsChange}
          companyId={selectedCompany?.value}
          moduleId={moduleId}
          selectedTeams={selectedTeams}
        />
        <div className={classes.root}>
          {
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={loading}
            >
              <CircularProgress color="primary" />
            </Backdrop>
          }
          <Grid container className={classes.content} spacing={2}>
            <Grid item xs={12} sm={4}>
              <SearchFilter
                value={searchdata}
                handleChange={(value) => {
                  setSearchdata(value);
                  debounce(() => searchLead(value), 800);
                }}
                placeholder={t("Search by Opportunity ID")}
                customfieldSx={{ height: "40px" }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={8}
              textAlign={"right"}
              display={"flex"}
              justifyContent={"end"}
            >
              <Box display={"flex"} sx={{ float: "right" }}>
                <Box>
                  <IconButton
                    size="small"
                    className={classes.img}
                    onClick={handleDownload}
                  >
                    <DownloadImage />
                  </IconButton>

                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <div className={classes.tray}>
                      <div className={classes.buttonWrapper}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleDownloadReport}
                          disabled={isLoading || isDownloadLoading} // Disable button when loading or download is in progress
                          className={classes.button}
                        >
                          {isDownloadLoading ? (
                            <CircularProgress size={24} />
                          ) : (
                            "Download Report"
                          )}
                        </Button>
                      </div>
                      <Divider />

                      <List className={classes.list}>
                        {isReportLoading ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress size={34} />
                          </div>
                        ) : downloadedFiles.length === 0 ? (
                          <Typography style={{ textAlign: "center" }}>
                            No export history
                          </Typography>
                        ) : (
                          <InfiniteScroll
                            dataLength={downloadedFiles.length}
                            next={() => fetchMoreReport()}
                            hasMore={hasMore}
                            height={150}
                          >
                            <Grid>
                              {downloadedFiles.map((file, index) => (
                                <ListItem
                                  key={index}
                                  className={classes.listItem}
                                >
                                  <Tooltip title={file.file_name} arrow>
                                    {file.is_completed ? (
                                      <Link
                                        className={classes.fileName}
                                        onClick={() =>
                                          handleOpenFile(file.file_url)
                                        }
                                        component="button"
                                        variant="body2"
                                      >
                                        {truncateFileName(file.file_name)}
                                      </Link>
                                    ) : (
                                      <Typography
                                        variant="body2"
                                        className={classes.disabledFileName}
                                        style={{ color: "gray" }}
                                      >
                                        {truncateFileName(file.file_name)}
                                      </Typography>
                                    )}
                                  </Tooltip>
                                  {file.is_completed ? null : file.is_failed ? (
                                    <WarningAmberRoundedIcon
                                      className={classes.errorIcon}
                                      style={{
                                        color: "red",
                                        fontSize: "18px",
                                      }}
                                    />
                                  ) : (
                                    <CircularProgress size={24} />
                                  )}
                                </ListItem>
                              ))}
                              {isLoadingMore && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <CircularProgress size={24} />
                                </div>
                              )}
                            </Grid>
                          </InfiniteScroll>
                        )}
                      </List>
                    </div>
                  </Popover>
                </Box>
                <Divider
                  orientation="vertical"
                  p={0.5}
                  height={2}
                  sx={{ marginInline: "16px" }}
                />
                <Box mr={1}>
                  <Tooltip
                    title={t("View Latest Comment")}
                    placement="top"
                    arrow
                  >
                    {commentCount > 0 ? (
                      <IconButton
                        size="small"
                        className={classes.img}
                        onClick={OpenCommentsDrawer}
                      >
                        <Badge
                          variant="dot"
                          color="error"
                          classes={{ badge: classes.customBadge }}
                        >
                          <img src="/images/chat.svg" alt="chat" />
                        </Badge>
                      </IconButton>
                    ) : (
                      <IconButton
                        size="small"
                        className={classes.img}
                        onClick={OpenCommentsDrawer}
                      >
                        <img src="/images/chat.svg" alt="chat" />
                      </IconButton>
                    )}
                  </Tooltip>
                </Box>
                <Divider
                  orientation="vertical"
                  p={0.5}
                  height={2}
                  sx={{ marginInline: "16px" }}
                />
                <Box>
                  {is_instigator ? (
                    <IconButton
                      size="small"
                      className={classes.img}
                      onClick={OpenDrawer}
                    >
                      <Badge
                        variant="dot"
                        color="primary"
                        classes={{ badge: classes.customBadge }}
                      >
                        <img src="/images/filter.svg" alt="filter" />
                      </Badge>
                    </IconButton>
                  ) : (
                    <IconButton
                      size="small"
                      className={classes.img}
                      onClick={OpenDrawer}
                    >
                      <img src="/images/filter.svg" alt="filter" />
                    </IconButton>
                  )}
                </Box>
                <Divider
                  orientation="vertical"
                  p={0.5}
                  height={2}
                  sx={{ marginInline: "16px" }}
                />
                {/* &nbsp; &nbsp; &nbsp; */}
                {permission?.create && (
                  <Button
                    variant="contained"
                    onClick={goCreateOppertunity}
                    sx={{ height: "40px" }}
                  >
                    {t("Add opportunity")}
                  </Button>
                )}
              </Box>
            </Grid>
            {/* Main tableData */}
            <Grid item xs={12} className={classes.tableMain}>
              <TableWithPagination
                heading={Leadheading(t)}
                rows={leadList}
                path={Leadpath}
                showpagination={true}
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                handleIcon={handleIcon}
                handlePagination={handlePagination}
                handleChangeLimit={handleChangeLimit}
                totalRowsCount={total}
                page={page}
                limit={limit}
                tableType="no-side"
                dataType={[
                  { type: ["more_5"], icon: "icon" },
                  { type: ["text"], name: "leadNo" },
                  { type: ["date"], name: "date" },
                  { type: ["long_text"], name: "description" },
                  { type: ["text"], name: "purpose" },
                  { type: ["text"], name: "opportunity_type" },
                  { type: ["text"], name: "activeQuote" },
                  { type: ["text"], name: "value" },
                  { type: ["text"], name: "owner" },
                  { type: ["text"], name: "contact_name" },
                  { type: ["text"], name: "contact_email" },
                  { type: ["text"], name: "company_name" },
                  { type: ["text"], name: "country" },
                  { type: ["text"], name: "contact_mobile_no" },
                  { type: ["text"], name: "source" },
                  { type: ["oppertunity_status"], name: "status" },
                  { type: ["date"], name: "dateOfOpen" },
                  { type: ["date"], name: "dateOfClosed" },
                  // { type: ["text"], name: "leadId" },
                ]}
                height={isXs ? "calc(100vh - 440px)" : "calc(100vh - 315px)"}
                view={permission?.read}
                edit={permission?.update}
                delete={permission?.delete}
                enable_double_click
              />
            </Grid>

            {/* create section */}
            <React.Fragment key={"right"}>
              <Drawer
                anchor={"right"}
                open={create?.open}
                onClose={() => handleCreate("open", false)}
              >
                <CreateLead
                  t={t}
                  title={`${
                    create?.lead_id
                      ? is_workspace
                        ? t("Update Workspace Opportunity")
                        : t("Update Opportunity")
                      : is_workspace
                      ? t("Create Workspace Opportunity")
                      : t("Create Opportunity")
                  }`}
                  onClose={() => handleCreate("open", false)}
                  state={{
                    SelectedCompany: selectedCompany,
                    company: companyList,
                  }}
                  moduleId={moduleId}
                  btnName="Opportunity"
                  oppertunity_only={true}
                  reload={() => {
                    getListData(
                      0,
                      10,
                      "",
                      "key",
                      selectedCompany,
                      selectedTeams,
                      filterData
                    );
                    handleCreate("open", false);
                  }}
                  company_id={selectedCompany?.value}
                  // quote_id={state?.selected_id}
                  lead_id={create?.lead_id}
                  onNext={onNext}
                  setState1={setState1}
                  state1={state1}
                  is_opportunity={true}
                  // revenue_type={"Lease"}
                />
              </Drawer>
            </React.Fragment>
            <React.Fragment key={"right"}>
              <Drawer
                anchor={"right"}
                open={commentsDrawer}
                onClose={() => setCommentsDrawer(false)}
              >
                <Box>
                  <LeadComments
                    t={t}
                    closeDrawer={() => setCommentsDrawer(false)}
                    company_id={selectedCompany?.value}
                    clientID={clientId}
                    filterData={filterData}
                    selectedCompany={selectedCompany}
                    type={"opportunity"}
                    selectedTeams={selectedTeams}
                  />
                </Box>
              </Drawer>
            </React.Fragment>
            {openFilter && (
              <FilterGenerator
                open={openFilter}
                onClose={OpenDrawer}
                components={[
                  {
                    component: "toggleButton",
                    value: filterData?.periorty,
                    options: wrapLabelsInT(status?.value, t),
                    isMulti: true,
                    state_name: "periorty",
                    label: t("Priority Type"),
                    // required:true
                  },
                  {
                    component: "toggleButton",
                    value: filterData?.status,
                    options: wrapLabelsInT(status?.enum, t) ?? [],
                    isMulti: true,
                    state_name: "status",
                    label: t("Status"),
                    // required:true
                  },
                  {
                    component: "select",
                    value: filterData?.name,
                    isMulti: true,
                    label: t("Contact Name"),
                    state_name: "name",
                    placeholder: t("Select Contact Name"),
                    loadOptions: (search, array, handleLoading) =>
                      loadOptionsApis(
                        "queries/opportunity/contact/dropdown",
                        {
                          company_id: selectedCompany?.value,
                        },
                        search,
                        array,
                        handleLoading,
                        "data",
                        { custom_data: true },
                        manualResponse
                      ),
                    debounceTimeout: 800,
                    isPaginate: true,
                  },
                  {
                    component: "select",
                    value: filterData?.lead,
                    isMulti: true,
                    label: t("Lead Owner"),
                    state_name: "lead",
                    placeholder: t("Select Lead Owner"),
                    loadOptions: (search, array, handleLoading) =>
                      loadOptionsApis(
                        "queries/opportunity/lead_owner/dropdown",
                        {
                          company_id: selectedCompany?.value,
                        },
                        search,
                        array,
                        handleLoading,
                        "data",
                        {},
                        manualResponseLead
                      ),
                    debounceTimeout: 800,
                    isPaginate: true,
                  },
                ]}
                onApply={(value) => onApplyFilter(value)}
                defaultState={{
                  periorty: "",
                  status: [2],
                  name: "",
                  lead: "",
                }}
              />
            )}
          </Grid>
        </div>
      </>
    );
  };
  return <>{accessCheckRender(render, permission)}</>;
};
