import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
  tabRoot: {
    padding: "0px",
    background: "white",
    display: "flex",
    alignItems: "center"
  },
  bottom: {
    position: 'absolute',
    width: "100%",
    height: "2px",
    bottom: "0",
    left: "0",
    right: "0",
    backgroundColor: theme?.typography?.status?.primary
  },
  root: {
    padding: "0px",
    background: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  closeRequest: {
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
      color: theme?.palette?.background?.tertiary1,
    },
    marginInlineStart: "16px"
  },
  outline: {
    fontFamily: FontFamilySwitch().semiBold,
    backgroundColor: theme?.palette?.background?.tertiary1,
    color: theme?.palette?.primary?.main,
    border: `1px solid ${theme?.palette?.primary?.main}`,
    "&:hover": {
      backgroundColor: theme?.palette?.background?.tertiary1,
      color: theme?.palette?.primary?.main,
      border: `1px solid ${theme?.palette?.primary?.main}`,
    },
  },
  menu: {
    display:"flex",
    justifyContent:"center",
    "& .MuiPopover-paper": {
      boxShadow: "0 0px 8px -4px #f2f3f5",
      border: "1px solid #f2f3f5",
      borderRadius: "5px",
      marginTop:"5px"
    },
  },
  menuItem: {
    borderBottom: "1px solid #E4E8EE",
    margin: "0px 4px",
    fontSize: "0.875rem",
    color: "#071741",
    fontFamily: FontFamilySwitch().regular,
    padding: "10px",
    cursor: "pointer",
    "&:last-child": {
      border: 0,
    },
  },
  image: {
    width: "188px",
    height: "188px",
    borderRadius: "4px",
    position: "relative",
    "&:hover": {
      opacity: "16",
      backgroundColor: 'red'
    },
  },
  caroselRoot: {
    borderRadius: "4px",
    backgroundColor: (props) => props?.backgroundColor ?? "",
    padding: (props) => props?.padding ?? "",
    cursor: "pointer",
  },
  arrowBtn: {
    backgroundColor: "#fff !important",
    border: "1px solid #CED3DD",
  },
  arrow: {
    backgroundColor: "#fff",
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "0.75rem",
    borderRadius: "50%",
    border: "1px solid #CED3DD",
  },
  prev: {
    position: "absolute",
    zIndex: 1,
    top: "41%",
    left: "12px",
    cursor: "pointer",
  },
  next: {
    position: "absolute",
    zIndex: 1,
    top: "41%",
    right: "12px !important",
    cursor: "pointer",
  },
  tabtitle: {
    fontSize: "0.75rem",
    color: theme?.typography?.color?.secondary,
    fontFamily: FontFamilySwitch().bold,
  },
  tabtitle1: {
    fontSize: "0.75rem",
    color: theme?.typography?.status?.primary,
    fontFamily: FontFamilySwitch().bold,
  },
  selectBack1: {
    backgroundColor: theme?.palette?.info?.light,
    display: 'flex',
    alignItems: 'center',
    padding: '19px 16px',
    position: 'relative'
  },
  selectBack: {
    display: 'flex',
    alignItems: 'center',
    padding: '19px 16px',
    position: 'relative'
  },
  icon: {
    fontSize: "0.875rem",
    marginLeft: "-2px",
  },
  iconArabic: {
    fontSize: "0.875rem",
    marginLeft: "-3px",
  },
  iconbtn: {
    border: `1px solid ${theme.palette.border.secondary}`,
    backgroundColor: theme.palette.border.secondary,
    padding: "8px",
    borderRadius: "50%",
    height: "24px",
    width: "24px",
    cursor: "pointer",
    direction: "ltr"

  },
  title: {
    color: theme.typography.color.secondary.main,
    fontFamily: FontFamilySwitch().bold,
    fontSize: "1rem"
  },
  subheaderRoot: {
    backgroundColor: theme.palette.background.paper,
    padding: "8px",
    marginTop: "-4px",
    borderBottom: (props) => props?.borderBottom ?? "1px solid #00000014",
    minHeight: "54px",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center"
  },
  jobTitle: {
    fontSize: "0.875rem",
    color: "#071741",
    fontFamily: FontFamilySwitch().semiBold,
  }
}));
export const AggrementComponentStyles = makeStyles((theme) => ({
  dot: {
    height: 6,
    width: 6,
    backgroundColor: theme.palette.border.primary,
    borderRadius: "50%",
    margin: "0 6px"
  },
  downBtn: {
    '&:hover': {
      backgroundColor: "#ffff"
    }
  },
  kycCard: {
    background: theme.palette.background.tertiary1,
    '&:hover': {
      background: theme.palette.info.light
    },
    cursor: "pointer"
  },
  kycCardSelected: {
    background: theme.palette.info.light,
    cursor: "pointer",
  },
  content: {
    fontSize:"0.75rem",
    color: "#4E5A6B"
  },
  cardHeader: {
    top: "246px",
    left: "367px",
    padding: "17px 0px",
    margin: "8px 0px",
    boxShadow: 'none',
    border: '1px solid #E4E8EE',
    borderRadius: '4px'

  },
  image: {
    borderRadius: "50% !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "70px",
    height: "70px",

  },
  BorderRight: {
    borderRight: "1px solid #E4E8EE",
    display: "flex",
    justifyContent: "center"
  },
  title: {
    fontSize:"0.75rem",
    color: "#4E5A6B",
    textTransform: "upperCase",
    fontFamily: FontFamilySwitch().bold,
    marginBottom: "16px"
  },
  label: {
    fontSize:"0.75rem",
    color: "#98A0AC",
    textTransform: "Capitalize",
    fontFamily: FontFamilySwitch().semiBold,
    marginBottom: "5px",
    whiteSpace: "nowrap"
  },
  text: {
    fontSize:"0.875rem",
    color: "#091B29",
    fontFamily: FontFamilySwitch().semiBold,
    direction: "ltr",
    textAlign: "-webkit-match-parent"
  },
  customerDetails: {
    padding: '5px 16px !important'
  },
  postCard: {
    backgroundColor: "white",
    marginBottom: "8px",
    borderRadius: "8px"
  },
  postCardTitle: {
    color: "#4E5A6B",
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().bold,
  },
  postCardDescription: {
    color: '#98A0AC',
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  postCardStatus: {
    color: "#5078E1",
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    marginTop: "8px"
  },
  postCardContent: {
    marginInlineStart: "20px",
  },
  PaymentMethodCard: {
    border: "1px solid #E4E8EE",
    borderRadius: "4px",
    padding: "4px 12px",
    marginTop: "4px",
    color: "#091B29",
    boxShadow: "none",
    backgroundColor: "white",
    cursor: "pointer",
    "&:hover": {
      border: "1px solid #5078E1",
      color: "#5078E1",
    }
  },
  PaymentMethodCardSelect: {
    padding: "4px 12px",
    borderRadius: "4px",
    boxShadow: "none",
    backgroundColor: "white",
    cursor: "pointer",
    border: "1px solid #5078E1",
    color: "#5078E1",
    marginTop: "4px",
  },
  PaymentMethodtitle: {
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    textAlign: "left",
    padding: "10px 10px",
  },
  paymentScheduletitle: {
    fontSize:"0.75rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().bold,
  },
  PaymentScheduleAccordion: {
    border: "1px solid #E4E8EE",
    borderRadius: "4px",
    boxShadow: "none"
  },

  caroselRoot: {
    borderRadius: '4px',
    backgroundColor: (props) => props?.backgroundColor ?? "",
    padding: (props) => props?.padding ?? "",
    cursor: 'pointer'
  },
  arrowBtn: {
    backgroundColor: "#fff !important",
    border: "1px solid #CED3DD",
  },
  arrow: {
    backgroundColor: "#fff",
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize:"0.75rem",
    borderRadius: "50%",
    border: "1px solid #CED3DD",
  },
  prev: {
    position: "absolute",
    zIndex: 1,
    top: "41%",
    left: '12px',
    cursor: 'pointer'
  },
  next: {
    position: "absolute",
    zIndex: 1,
    top: "41%",
    right: '12px !important',
    cursor: 'pointer'
  },
  quoDtlCard: {
    border: "1px solid #E4E8EE",
    borderRadius: 5,
    backgroundColor: "white"
  },
  quoDtlCardTitle: {
    fontSize: "0.75rem",
    color: "#98A0AC",
    fontFamily: FontFamilySwitch().bold,

  },
  quoDtlCardNumber: {
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().bold,
    margin: "4px 0px"
  },
  quoDtlCardStack: {
    fontSize:"0.75rem",
    color: theme.typography.color.secondary,
  },
  quoDtlCardTableSec: {
    fontSize:"0.75rem",
    color: theme.typography.color.secondary,
    margin: 0,
    padding: 8
  },
  quoDtlCardTableBold: {
    fontSize:"0.75rem",
    color: "#000",
    fontWeight: "bold"
  },
  QuoCardButton: {
    margin: "0px -8px 0px 8px"
  },
  quoDtlCardDate: {
    fontSize:"0.75rem",
    color: theme.typography.color.secondary
  },
  leadDetDot: {
    height: 6,
    width: 6,
    backgroundColor: theme.palette.border.primary,
    borderRadius: "50%",
    margin: "0 6px"
  },

  titleCard: {
    color: "#000",
    fontSize:"1rem",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center"
  },
  status: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().bold,
    padding: "2px 10px",
    borderRadius: "2px",
    display: "inline-block",
    marginBottom: "4px",
    textTransform: "capitalize"
  },
  quoDtlCardTableRow: {
    padding: "0px 12px",
    "&:last-child": {
      backgroundColor: "#F5F7FA",
      padding: "12px"
    }
  },
  invoiceTable: {
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0px 0px 16px #00000014"
  },
  invoiceDetail: {
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0px 0px 16px #00000014",
  },
  invoiceDetail1: {
    height: "calc(100vh - 280px)",
    overflow: "auto",
  },
  invoiceDetails: {
    height: "calc(100vh - 260px)",
    overflow: "auto",
    backgroundColor: "white"
  },
  inspectionDetails: {
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0px 0px 16px #00000014",
  },
  inspectionDetail1: {
    height: "calc(100vh - 290px)",
    overflow: "auto",
  },
  detailCardRoot: {
    border: "1px solid #E4E8EE",
    borderRadius: "4px"
  },
  section1: {
    padding: "12px",
    borderBottom: "1px solid #E4E8EE",
  },

  totalSection: {
    padding: "8px",
    backgroundColor: "#F5F7FA",
    borderRadius: "4px",
    fontSize:"0.75rem",
    color: "#091B29",
    fontFamily: FontFamilySwitch().semiBold,
  },
  totaltaxSection: {
    padding: "8px",
    color: "#78B1FE",
    backgroundColor: "#F1F7FF",
    borderRadius: "4px",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize:"0.75rem",
  },
  label1: {
    fontSize:"0.75rem",
    color: "#98A0AC",
    textTransform: "Capitalize",
    fontFamily: FontFamilySwitch().semiBold,

  },
  btn: {
    padding: "8px 12px",
    boxShadow: "none"
  },
  img: {
    height: "40px",
    width: "100%",
    objectFit: "cover"
  },
  invoiceDetail2: {
    height: "calc(100vh - 180px)",
    backgroundColor: "#F5F7FA"
  },
  decline: {
    padding: "8px 12px",
    boxShadow: "none"
  },
  approved: {
    padding: "8px 12px",
    boxShadow: "none",
    color: "white"
  },
  kycStatus: {
    fontSize:"0.75rem",
    padding: "4px 6px 4px 6px",
    fontWeight: "bold",
    borderRadius: "4px"
  },
  right: {
    borderRight: `1px solid ${theme.palette.border.secondary}`,
    height: "calc(100vh - 290px)",
    overflow: "auto",
  },
  unselect: {
    padding: "10px",
    textAlign: "center",
    borderRadius: theme.palette.borderRadius,
    color: "white",
    fontSize:"0.875rem",
    backgroundColor: "#5078E1",
    cursor: "pointer",
    fontFamily: FontFamilySwitch().semiBold,
  },
  select: {
    padding: "10px",
    textAlign: "center",
    borderRadius: theme.palette.borderRadius,
    fontSize:"0.875rem",
    cursor: "pointer",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    fontFamily: FontFamilySwitch().semiBold,

  },
  doc: {
    height: "calc(100vh - 230px)",
    boxShadow: "0px 3px 16px #00000029",
    margin: "0px 13%",
    overflow: "auto",
  },
  tabledoc: {
    width: "100%", overflow: "hidden",
    border: `1px solid ${theme.palette.border.secondary}`,
    background: "#fff",
    height: "calc(100vh - 180px)"
  },
  next1: {
    padding: "10px",
    textAlign: "center",
    borderRadius: theme.palette.borderRadius,
    color: "white",
    fontSize:"0.875rem",
    backgroundColor: "#5078E1",
    cursor: "pointer",
    position: "absolute",
    right: "10px",
    top: "36px"
  },
  accordionHead: {
    // "& .MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded": {
    //   minHeight: 34
    // }
    '&.Mui-expanded': {
      minHeight: 34,
      maxHeight: 34,
    }
  }
}));