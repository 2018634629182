import makeStyles from "@mui/styles/makeStyles";
import { config } from "../../config";
import { FontFamilySwitch, login_background_color } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
        backgroundColor: "white",
    },
    grid: {
      alignItems: "center",
      background: `transparent linear-gradient(119deg, ${login_background_color?.[config?.app_env]?.light} 0%, ${login_background_color?.[config?.app_env]?.dark} 100%) 0% 0% no-repeat padding-box`,
    },
    signupform: {
        overflow: "overlay",
        padding: "80px 20px 80px 0px",
        alignContent: "center",
        justifyContent: "center",
        height: (size) => size?.height,
    },
    form_grid: {
        position: "relative",
        maxWidth: "400px",
        maxHeight: "600px",
        backgroundColor: "white",
        padding: "40px",
        borderRadius: theme.palette.borderRadius,
    },
    logo: {
      [theme.breakpoints.down("sm")]: {
        display: "none"
      },
    },
    image: {
      alignContent: "center",
      justifyContent: "center",
      height: (size) => size?.height
    },
    text: {
        fontSize:"1.5rem",
        fontFamily: FontFamilySwitch().extraBold,
        color: theme.typography.color.primary,
        marginTop: "12px",
    },
    btn: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 6px 10px #00000014",
        opacity: 1,
        padding: "16px",
        height: "48px",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
        color: "white",
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold
    },
    verificationText: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().regular,
        textAlign: "center",
        color: theme.typography.color.primary,
        backgroundColor: theme.palette.background.secondary,
        borderRadius: theme.palette.borderRadius,
        padding: "20px",
    },
    poweredby: {
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
    },
    pa: {
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
        fontSize:"0.75rem",
    },
    title: {
        fontFamily: FontFamilySwitch().extraBold,
        fontSize:"1.5rem",
        color: theme.typography.color.primary,
    },
    content: {
        width: "inherit",
    },
    backgroundImage: {
        backgroundColor: "#7EA1FF",
        height: "213px",
        backgroundImage: `url("/images/login_background_1-min.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "100%"
    },
    img: {
        height: "8%",
        width: "28%",
    },
    background: {
        backgroundImage: `url(/images/login_background_1-min.png)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        [theme.breakpoints.down("sm")]: {
            display: "none"
        },
    },
    backgroundGrid: {
        background: `transparent linear-gradient(119deg, ${login_background_color?.[config?.app_env]?.light} 0%, ${login_background_color?.[config?.app_env]?.dark} 100%) 0% 0% no-repeat padding-box`,
        height: "213px",
    },
}));