import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { AlertDialog, SearchFilter } from "../../../components";
import { stringAvatar, useWindowDimensions } from "../../../utils";
import { useStyles } from "../style";

export const EmployeeCard = ({ t, data = {}, setData = () => false, handleSearch = () => false, fetchMoreData = () => false, searchText = "", setSearchText = () => false, open = false, setOpen = () => false, closeModal = () => false, selectEmp = () => false, changeEmployee = () => false,setOffset=()=>false }) => {
    const classes = useStyles()

    const size = useWindowDimensions();
    const EmployeeList = () => {
        return (
            <Box m={2}>
                <Box mb={2}>
                    <SearchFilter t={t} handleChange={handleSearch} value={searchText} />
                </Box>
                <InfiniteScroll
                    dataLength={data?.AllEmployees?.length ?? ""}
                    next={fetchMoreData}
                    hasMore={true}
                    height={size?.height - 420}
                >
                    {data?.AllEmployees?.map((x) => {
                        return (
                            <Box className={classes.empCardList} onClick={() => selectEmp(x)}>
                                <Stack direction={"row"}>
                                    <Avatar src={x.image_url} className={classes.empImg} {...stringAvatar(x?.first_name)} />
                                    <Box sx={{ marginInlineStart: "8px" }} >
                                        <Typography className={classes.empName}>{x.first_name}</Typography>
                                        <Typography className={classes.empRole}>{x.job_title?.length > 0 ? x?.job_title : "-"}</Typography>
                                    </Box>
                                </Stack>
                                {x?.id === data?.selectedResourceId &&
                                    <Box mt={1.5}>
                                        <Typography className={classes.changeEmp}><CheckCircleIcon /></Typography>
                                    </Box>
                                }
                            </Box>
                        )
                    })}
                    {data?.AllEmployees?.length === 0 &&
                        <Box className={classes.leaveName} textAlign="center" display="flex" justifyContent={"center"}>{t("noDataFound")}</Box>
                    }
                </InfiniteScroll>
                {data?.AllEmployees?.length > 0 &&
                    <Box mt={2}>
                        <Button variant="contained" fullWidth onClick={() => changeEmployee()}>{t("changeEmployee")}</Button>
                    </Box>
                }
            </Box>
        )
    }
    // const closeModal = () =>{
    //     setOpen(false)
    //     setSearchText("")
    // }
    return (
        <>
            <Box className={classes.empCard}>
                <Stack direction={"row"} spacing={1}>
                    <Avatar variant="square" className={classes.empImg} src={data?.list?.image_url} {...stringAvatar(data?.list?.first_name)} />
                    <Box sx={{ marginInlineStart: "8px ! important" }}>
                        <Typography className={classes.empName}>{data?.list?.first_name}{data?.list?.last_name}</Typography>
                        <Typography className={classes.empRole}>{data?.list?.job_title?.length > 0 ? data?.list?.job_title : "-"}</Typography>
                    </Box>
                </Stack>
                {data?.list?.first_name &&
                    <Box mt={1.5} onClick={() => { return (setOpen(true), setSearchText(""),setOffset(0)) }}>
                        <Typography className={classes.changeEmp}>{t("change")}</Typography>
                    </Box>
                }
            </Box>
            {/*Employee dialog*/}
            <AlertDialog
                open={open}
                onClose={closeModal}
                header={t("Change Employee")}
                component={EmployeeList()}
                isNormal
            />
        </>
    )
}