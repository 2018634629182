import { Button, Divider, Grid, Stack } from "@mui/material";
import { withTranslation } from "react-i18next";
import { SearchFilter } from "../../../components";
import { useStyles } from "../style";

const SearchFilters = ({
    onClick = () => false,
    searchText = "",
    handleSearch = () => false,
    openfilter = () => false,
    filterData = {},
    permissions = {},
    t

}) => {
    const classes = useStyles()
    return (
        <Grid container justifyContent="space-between">
            <Grid item xs={4}>
                {/*search */}
                <SearchFilter
                    handleChange={handleSearch}
                    value={searchText}
                    placeholder={t("Search Name")}
                    customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
                />
            </Grid>
            <Grid item xs={8} alignContent="flex-end">
                <Stack
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                    justifyContent="flex-end"
                    alignItems="center"
                    direction="row">
                    {/*add btn */}
                    {permissions?.create &&
                        <Button onClick={onClick} className={classes.addIcon} variant="contained">{t("Add")}</Button>

                    }
                </Stack>
            </Grid>
        </Grid>
    )
}
export default withTranslation("inspectionItemmaster")(SearchFilters); 