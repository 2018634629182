import makeStyles from '@mui/styles/makeStyles';
import { FontFamilySwitch } from '../../utils';
export const useStyles = makeStyles((theme) => ({
    block: {
        backgroundColor: "#FFFFFF",
        borderRadius: theme.palette.borderRadius,
        padding: "12px"
    },
    empCard: {
        display: "flex",
        justifyContent: "space-between",
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #E4E8EE",
        padding: "8px",
        cursor: "pointer"
    },
    empCardList: {
        display: "flex",
        justifyContent: "space-between",
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #E4E8EE",
        padding: "8px",
        marginBottom: "8px",
        cursor: "pointer"

    },
    empName: {
        fontSize:"1rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
    },
    empRole: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().regular,
    },
    empImg: {
        borderRadius: theme.palette.borderRadius
    },
    changeEmp: {
        fontSize:"0.75rem",
        color: "#5078E1",
        fontFamily: FontFamilySwitch().bold,
        cursor: "pointer"
    },
    statsTitle: {
        fontSize:"0.875rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().bold,
        textTransform: "capitalize"
    },
    list: {
        display: "flex",
        justifyContent: "space-between"
    },
    leaveName: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.secondary,
        margin: "10px 0px 0px 0px"
    },
    icon: {
        border: "1px solid #E4E8EE",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        textAlign: "center"
    },
    content: {
        overflow: "auto",
        height: `calc(100vh - 500px)`,
        // height: "170px"
    },
    availableLeave: {
        padding: "4px",
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#F2F4F7",
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
        height: "25px",
        marginTop: "10px",
        marginRight: "5px"
    },
    chart: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    chartSection: {
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #E4E8EE",
        padding: "5px",
        height: "230px"
    },
    type: {
        backgroundColor: "#F5F7FA",
        borderRadius: theme.palette.borderRadius,
        padding: "6px",
        width: "270px"
    },
    textcont: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().bold,
        marginInlineStart: "8px"
    },
    textcontd: {
        fontSize:"0.75rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
    },
    typebtn: {
        backgroundColor: "white",
        borderRadius: theme.palette.borderRadius,
        padding: "4px",
        marginLeft: "7px"
    },
    nobtn: {
        margin: "4px 0px 0px 0px !important",
        cursor: "pointer"
    },
    nobtn2: {
        marginTop: "4px !important",
        cursor: "pointer"

    },
    total: {
        color: theme.typography.color.primary,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
    },
    des: {
        color: theme.typography.color.secondary,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
    },
    center: {
        textAlign: "center",
        position: "absolute",
        left: "0px",
        right: "65%",
        top: "53%",
        [theme.breakpoints.down("sm")]: {
            left: "0px",
            right: "0px",
            top: "36%",
        },
    },
    tooltip: {
        backgroundColor: "#FFFFFF",
        border: "1px solid #E4E8EE",
        display: "flex",
        alignItems: "center",
        padding: "4px 7px",
        zIndex: 999,
        borderRadius: "4px",
        position: "relative",
    },
    name: {
        color: "#7C8594",
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
    },
    value: {
        color: "#4E5A6B",
        fontSize:"1rem",
        fontFamily: FontFamilySwitch().semiBold,
        marginRight: "8px",
    },
    dot: {
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        marginRight: "8px",
    },
    mainCard: {
        height: `calc(100vh - 175px)`
    }
}))
export const MonthlyCalendarStyles = makeStyles((theme) => ({
    // calendar dates styles
    calDates: {
        textAlign: "center",
        overflow: "auto",
        height: `calc(100vh - 255px)`,
    },
    calDatesCustom: {
        textAlign: "center",
    },
    calheader: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        marginBottom: "20px",
        direction: "ltr"
    },
    calheaderCustom: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        marginBottom: "20px"

    },
    applyBorder: {
        borderLeft: " 3px solid #E4E8EE"
    },
    date: {
        color: theme.typography.color.secondary,
        fontSize:"1.125rem",
        fontWeight: "bold"
    },
    dateCustom: {
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
    },
    seldateCustom: {
        color: "#FFFFFF",
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
    },
    date_outline: {
        color: "black",
        border: `1px solid ${theme.palette.border.secondary}`,
        // height: 90,
    },
    date_outlinedcustom: {
        color: "black",
        border: `1px solid ${theme.palette.border.secondary}`,
        height: 50,
        paddingTop: "5px",
        backgroundColor: "white"
    },
    seldate_outlinedcustom: {
        backgroundColor: "#5078E1",
        borderRadius: theme.palette.borderRadius,
        paddingTop: "5px",
        height: 50,
        border: `1px solid ${theme.palette.border.secondary}`,

    },
    seldate_range: {
        backgroundColor: "#F1F7FF",
        borderRadius: theme.palette.borderRadius,
        paddingTop: "5px",
        height: 50,
        border: `1px solid ${theme.palette.border.secondary}`,
    },
    hidden_other_month_dates: {
        visibility: "hidden"
    },
    eventlabel: {
        overflow: "hidden",
        width: "98%",
    },
    eventtxt: {
        whiteSpace: "nowrap",
        width: "99%",
        fontSize:"0.75rem",
    },
    moreLabel: {
        float: "left",
        fontSize:"0.75rem",
        fontWeight: "bold",
        color: theme.palette.info.main,
    },
    daysText: {
        textAlign: "center",
        marginBottom: "8px",
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().regular,
    },
    daysTextCustom: {
        textAlign: "center",
        height: "50px",
        paddingTop: "15px",
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().regular,
        // color: "black",
        border: `1px solid ${theme.palette.border.secondary}`,
    },
    applyTimeOff: {
        backgroundColor: "#F1F7FF",
        borderRadius: theme.palette.borderRadius,
        padding: "4px",
        margin: "4px",
        fontSize:"0.75rem",
        color: "#5078E1",
        fontFamily: FontFamilySwitch().regular,
        cursor: "pointer"
    },
    applyTimeOffCurrentDate: {
        backgroundColor: "#F1F7FF",
        borderRadius: theme.palette.borderRadius,
        padding: "4px",
        margin: "-8px 4px 4px 4px !important",
        fontSize:"0.75rem",
        color: "#5078E1",
        fontFamily: FontFamilySwitch().regular,
    },
    TimeOfflist: {
        border: "1px solid #E4E8EE",
        textAlign: "center",
        padding: "12px",
        borderRadius: theme.palette.borderRadius,
        cursor: "pointer"
    },
    leaveName: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.secondary,
        margin: "10px 0px 0px 0px",
        marginInlineStart: "8px"
    },
    statsTitle: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().bold,
        textTransform: "upperCase",
        marginBottom: "10px"
    },
    icon: {
        border: "1px solid #E4E8EE",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        textAlign: "center"
    },
    changeEmp: {
        fontSize:"0.75rem",
        color: "#5078E1",
        fontFamily: FontFamilySwitch().bold,
    },
    durationBox: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        padding: "15px"
    },
    durationText: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().semiBold,
        textTransform: "uppercase"
    },
    descriptext: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().semiBold,
        marginTop: "3px"
    },
    dateText: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().bold,
        marginTop: "3px"
    },
    timeBox: {
        display: "flex",
        backgroundColor: "#F1F7FF",
        padding: "5px",
        borderRadius: theme.palette.borderRadius,
        width: "fit-content"
    },
    hourText: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
    },
    closebtn: {
        boxShadow: "none",
        textTransform: "capitalize",
        border: "1px solid #E4E8EE",
        backgroundColor: "#FFFFFF",
        color: theme.typography.color.primary,
        borderRadius: theme.palette.borderRadius,
        fontSize: "0.8125rem",
        fontFamily: FontFamilySwitch().semiBold,
        "&:hover": {
            backgroundColor: '#FFFFFF',
            border: "1px solid #E4E8EE",

        },
    },
    submitbtn: {
        boxShadow: "none",
        textTransform: "capitalize",
        backgroundColor: "#5078E1",
        color: "#FFFFFF",
        borderRadius: theme.palette.borderRadius,
        fontSize: "0.8125rem",
        fontFamily: FontFamilySwitch().semiBold,
        "&:hover": {
            backgroundColor: '#5078E1',
        },
    },
    leavesub: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.tertiary,
        cursor: "pointer",
        marginInlineStart: "8px"
    },
    popovertext: {
        backgroundColor: "#F5F7FA",
        padding: "8px",
        borderRadius: theme.palette.borderRadius
    },
    popoversubtext: {
        color: theme.typography.color.secondary,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
    },
    menuCustomColor: {
        backgroundColor: "#FFFFFF !important",
        padding:"12px"
    },
    plussvg: {
        cursor: "pointer"
    },
    currentDate: {
        backgroundColor: "#1967d2",
        color: "#FFFFFF",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        paddingTop: "8px"
    },
    approveBtn: {
        borderRadius: theme.palette.borderRadius,
        height: "40px",
        boxShadow: "none",
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: "white",
        backgroundColor: "#5AC782",
        "&:hover": {
            backgroundColor: "#5AC782 ",
        },
    },
    rejectBtn: {
        borderRadius: theme.palette.borderRadius,
        height: "40px",
        boxShadow: "none",
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: "white",
        backgroundColor: "#FF4B4B",
        "&:hover": {
            backgroundColor: "#FF4B4B ",
        },
    },
}))