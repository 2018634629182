import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CityProperty from "../../assets/cityproperty";
import ImagesProperty from "../../assets/imagesproperty";
import { PropertyTabs, Subheader } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import {
  accessCheckRender,
  AlertProps,
  allowed_file_size,
  deleteS3File,
  NetWorkCallMethods,
} from "../../utils";
import { Assests } from "./tabs/assests";
import { FloorDetails } from "./tabs/floorDetails";
import { useStylesCreation } from "./tabs/style";
import {
  mapResult,
  singleFileUpload,
  assestType,
  getRoutePermissionNew,
  getCustomBlockName,
  getCustomFloorName,
} from "../../utils/common";
import moment from "moment";
import { withTranslation } from "react-i18next";

const CreateFloor = ({ t = () => false }) => {
  const initial = {
    img: "",
    Company: "",
    property: "",
    status: { value: true, label: "Active" },
    des: "",
    type: "",
    purpose: "",
    revenue: "",
    unit: "",
    doorNo: "",
    addressLineOne: "",
    addressLineTwo: "",
    landmark: "",
    area: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    latitude: "",
    longitude: "",
    initials: true,
    email: "",
    website: "",
    bussinessmobile: "",
    mobile: "",
    step: 0,
    built: "",
    handover: "",
    error: {
      img: "",
      Company: "",
      property: "",
      status: "",
      des: "",
      type: "",
      purpose: "",
      revenue: "",
      unit: "",
      initials: true,
      email: "",
      website: "",
      bussinessmobile: "",
      mobile: "",
      step: 0,
      built: "",
      handover: "",
    },
  };
  const backdrop = React.useContext(BackdropContext);
  const [data, setData] = React.useState({ ...initial });
  const [value, setValue] = React.useState(1);
  const [disable, setDisable] = React.useState(true);
  const [images, setImages] = React.useState([]);
  const [deletedAssetURL, setDeletedAssetURL] = React.useState([]);
  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext);
  const navigate = useNavigate();
  const classes = useStylesCreation();
  const { state } = useLocation();
  const [mapLoad, setMapLoad] = React.useState(false);
  const [Load, setLoad] = React.useState(false);
  const [permission, setPermission] = React.useState({});
  const [deleted_s3_urls, set_deleted_s3_urls] = React.useState([]);

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        if (state?.main) {
          if (state?.main?.blockID) {
            getPropertyById1(state?.main?.blockID);
          } else {
            getPropertyByCompanyID(state?.main?.propertyID);
          }
        }
        if (state?.edit?.isEdit) {
          getPropertyByIdEdit();
        }
      }
    }
    // eslint-disable-next-line
  }, [auth, state?.main, state?.edit?.isEdit]);

  const updateState = (k, v) => {
    let error = data?.error;
    error[k] = "";
    if (k === "revenue") {
      setData({ ...data, [k]: v, handover: "", error });
    } else {
      setData({ ...data, [k]: v, error });
    }
  };

  const validate = () => {
    let isValid = true;
    let error = data.error;

    if (data?.property_hierarchy?.is_block) {
      if (
        !Boolean(state?.edit?.isEdit) &&
        !Boolean(state?.main?.blockID) &&
        data?.block.length === 0
      ) {
        isValid = false;
        error.block =
          getCustomBlockName(data?.property_hierarchy) +
          " " +
          t(" is Required");
      }
    }
    if (data?.Company?.length === 0) {
      isValid = false;
      error.Company = t("Company Name is Required");
    }
    // if (data?.img?.length === 0) {
    //       isValid = false;
    //       error.img = getCustomFloorName(data?.property_hierarchy) + " Image is Required";
    // }
    if (data?.property?.length === 0) {
      isValid = false;
      error.property =
        getCustomFloorName(data?.property_hierarchy) +
        " " +
        t(" Name is Required");
    }

    if (data?.status?.length === 0) {
      isValid = false;
      error.status = t("Status is Required");
    }
    if (data?.type?.length === 0) {
      isValid = false;
      error.type = t("Property Type is Required");
    }
    if (data?.purpose?.length === 0) {
      isValid = false;
      error.purpose =
        getCustomFloorName(data?.property_hierarchy) +
        t(" Purpose is Required");
    }
    if (data?.revenue?.length === 0) {
      isValid = false;
      error.revenue = t("Revenue Type is Required");
    }
    if (data?.unit?.length === 0) {
      isValid = false;
      error.unit = t("Measurement Unit is Required");
    }
    if (data?.built?.length === 0) {
      isValid = false;
      error.built = t("Year Built is Required");
    }
    if (data?.revenue === "Sale" && data?.handover?.length === 0) {
      isValid = false;
      error.handover = t("Handover Date is Required");
    }
    if (data?.website?.length === 0) {
      isValid = false;
      error.website = t("Website is Required");
    }
    if (data?.email?.length === 0) {
      isValid = false;
      error.email = t("Email Address is Required");
    }
    if (state?.edit?.isEdit) {
      if (data?.bussinessmobile?.mobile?.length === 0) {
        isValid = false;
        error.bussinessmobile = t("Business Phone is Required");
      }
      if (data?.mobile?.mobile.length === 0) {
        isValid = false;
        error.mobile = t("Mobile Phone is Required");
      }
    } else {
      if (data?.bussinessmobile?.length === 0) {
        isValid = false;
        error.bussinessmobile = t("Business Phone is Required");
      }
      if (data?.mobile?.length === 0) {
        isValid = false;
        error.mobile = t("Mobile Phone is Required");
      }
    }
    if (!isValid) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Please fill all mandatory field"),
      });
    }
    setData({ ...data, error });

    return isValid;
  };

  const updateimg = async (image) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: t("Loading"),
    });
    try {
      const uploaded_file = await singleFileUpload(
        image,
        { companyId: data?.Company?.value, type: assestType?.Thumbnails },
        alert,
        allowed_file_size
      );
      if (uploaded_file?.[0]?.url) {
        updateState("img", uploaded_file?.[0]?.url);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      } else {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      }
    } catch (err) {
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: t("Some Thing Went Wrong"),
      });
    }
  };
  const mapResultData = (map) => {
    let result = mapResult(map);
    setData({ ...data, ...result });
  };
  const next = () => {
    if (value === 1) {
      if (validate()) {
        setDisable(false);
        setValue(2);
      } else {
        return false;
      }
    }
  };
  const previous = () => {
    setValue((prevValue) => prevValue - 1);
    if (!state?.edit?.isEdit) {
      setMapLoad(false);
    }
  };
  const removeimg = () => {
    set_deleted_s3_urls([...deleted_s3_urls, data?.img]);
    setData({ ...data, img: "" });
  };
  const tabTittle = [
    {
      label: getCustomFloorName(data?.property_hierarchy) + " " + t(" Details"),
      sub:
        t("Enter ") +
        " " +
        getCustomFloorName(data?.property_hierarchy) +
        " " +
        t(", Address & Contact Details"),
      imgSelect:
        value === 2 ? (
          <img src="/images/correct_icon.svg" alt="icon" />
        ) : (
          <CityProperty color={value === 1 ? "#5078E1" : "#98A0AC"} />
        ),
      value: 1,
      className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle,
    },
    {
      label: t("Assets"),
      sub: t("Upload assets like Image, 360 Image, Vid.."),
      imgSelect: <ImagesProperty color={value === 2 ? "#5078E1" : "#98A0AC"} />,
      value: 2,
      className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle,
    },
  ];
  const handleAssetFile = (data) => {
    if (data.asset_type === 3) {
      setDeletedAssetURL([...deletedAssetURL, data.id].filter(Boolean));
      setImages(
        images?.filter((val) => val?.file_meta?.id !== data?.file_meta?.id)
      );
    } else {
      set_deleted_s3_urls([...deleted_s3_urls, data?.url]);
      setDeletedAssetURL([...deletedAssetURL, data.id].filter(Boolean));
      setImages(images?.filter((val) => val?.url !== data?.url));
    }
  };
  const save = () => {
    deleteS3File({ urls: deleted_s3_urls });

    if (validate()) {
      const datas = {
        tenantId: `${config.tenantId}`,
        name: data?.property,
        description: data?.des,
        logo: data?.img ?? null,
        company_id: parseInt(data?.Company?.value),
        door_no: data?.doorNo,
        street_1: data?.addressLineOne,
        street_2: data?.addressLineTwo,
        landmark: data?.landmark,
        area: data?.area,
        locality: data?.area,
        district: data?.city,
        city: data?.city,
        state: data?.state,
        uom_id: data?.unit?.value,
        revenue_type: data?.revenue?.value,
        zipcode: data?.zipcode?.toString(),
        business_phone: data?.bussinessmobile?.mobile,
        mobile_phone: data?.mobile?.mobile,
        business_phone_country_code:
          data?.bussinessmobile?.mobile_code ?? "+91",
        mobile_phone_country_code: data?.mobile?.mobile_code,
        country: data?.country,
        website: data?.website,
        email: data?.email,
        mobile_country_code: data?.mobile?.mobile_code,
        latitude: parseFloat(data?.latitude),
        longitude: parseFloat(data?.longitude),
        property_id: state?.edit?.isEdit
          ? state?.edit?.propertyID
          : state?.main?.propertyID,
        floor_purpose: data?.purpose?.value,
        is_active: data?.status?.value ? true : false,
        assetsData: state?.edit?.isEdit
          ? images?.filter((val) => !val?.id)
          : images,
        year_built: data?.built
          ? moment(data?.built).format("yyyy-MM-DD")
          : null,
        planned_hand_over_date:
          data?.revenue?.value === "Sale" && data?.handover
            ? moment(data?.handover).format("yyyy-MM-DD")
            : null,
        block_id: state?.edit?.isEdit
          ? state?.edit?.blockID
          : state?.main?.blockID
          ? state?.main?.blockID
          : data?.block?.value,
        created_by: localStorage.getItem("profileID"),
      };
      if (state?.edit?.isEdit) {
        datas["floor_id"] = state?.edit?.floorID;
        datas["deleteAssets"] = deletedAssetURL;
      }

      NetworkCall(
        `${config.api_url}/floor/${state?.edit?.isEdit ? `update` : `create`}`,
        NetWorkCallMethods.post,
        datas,
        null,
        true,
        false
      )
        .then((response) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: state?.edit?.isEdit
              ? getCustomFloorName(data?.property_hierarchy) +
                " " +
                t(" Updated successfully.")
              : getCustomFloorName(data?.property_hierarchy) +
                " " +
                t(" Created successfully."),
          });
          navigate(-1);
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Internal error. Please try again later."),
          });
          setDisable(false);
        });
    }
  };

  const checkSave = (type) => {
    setDisable(true);
    if (state?.edit?.isEdit) {
      if (permission?.update) {
        type === "previous" && previous();
        type === "next" && next();
        type === "save" && save();
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          msg: t("Access denied. Contact your administrator."),
          severity: AlertProps.severity.error,
        });
      }
    } else if (permission?.create) {
      type === "previous" && previous();
      type === "next" && next();
      type === "save" && save();
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        msg: t("Access denied. Contact your administrator."),
        severity: AlertProps.severity.error,
      });
    }
  };

  const getPropertyById1 = (blockID) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: t("Create ") + getCustomFloorName(data?.property_hierarchy),
    });
    let blockValue = typeof blockID === "string" ? blockID : blockID?.value;

    const payload = { blockId: blockValue };

    NetworkCall(
      `${config?.api_url}/queries/block/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        let temData = res?.data?.data?.block?.[0];

        if (temData?.property?.property_hierarchyByID?.is_floor) {
          setData({
            ...data,
            block: typeof blockID === "object" ? blockID : null,
            img: temData?.logo,
            Company: {
              value: temData?.property?.company?.id,
              label: temData?.property?.company?.name,
            },
            property: "",
            property_hierarchy: temData?.property?.property_hierarchyByID,
            propertyHierarchy: temData?.property?.property_hierarchy,
            des: "",
            type: {
              value: temData?.property?.property_groupByID?.id,
              label: temData?.property?.property_groupByID?.group_name,
            },
            purpose: {
              value: temData?.block_purpose,
              label: temData?.block_purpose,
            },
            revenue: {
              value: temData?.revenue_type,
              label: temData?.revenue_type,
            },
            unit: temData?.uomByID ?? "",
            capture: "",
            total: "",
            doorNo: temData?.addressByID?.door_no,
            addressLineOne: temData?.addressByID?.addressLineOne,
            addressLineTwo: temData?.addressByID?.addressLineTwo,
            landmark: temData?.addressByID?.landmark,
            area: temData?.addressByID?.area,
            city: temData?.addressByID?.city,
            state: temData?.addressByID?.state,
            zipcode: temData?.addressByID?.zipcode,
            country: temData?.addressByID?.country,
            latitude: temData?.addressByID?.latitude,
            longitude: temData?.addressByID?.longitude,
            initials: true,
            email: temData?.addressByID?.emailAddress ?? "",
            website: temData?.property?.website,
            bussinessmobile: {
              mobile: temData?.addressByID?.mobile_no ?? "",
              mobile_code: temData?.addressByID?.mobile_no_country_code ?? "",
            },
            mobile: {
              mobile: temData?.addressByID?.alternative_mobile_no ?? "",
              mobile_code:
                temData?.addressByID?.alternative_mobile_no_country_code ?? "",
            },
            step: 0,
            built: new Date(temData?.year_built) ?? "",
            handover: new Date(temData?.planned_hand_over_date) ?? "",

            error: {
              block: "",
              img: "",
              Company: "",
              property: "",
              des: "",
              type: "",
              purpose: "",
              revenue: "",
              unit: "",
              capture: "",
              total: "",
              initials: true,
              email: "",
              website: "",
              bussinessmobile: "",
              mobile: "",
              step: 0,
            },
          });
          setLoad(true);
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.info,
            msg:
              getCustomFloorName(temData?.property?.property_hierarchyByID) +
              t(" creation is restricted."),
          });
          navigate(-1);
        }
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went wrong please try again"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  const getPropertyByIdEdit = () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    const payload = { floorId: state?.edit?.floorID };

    NetworkCall(
      `${config?.api_url}/queries/floor/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        let temData = res?.data?.data?.floor?.[0];
        setImages(
          res?.data?.data?.assets.filter((x) => x?.assets_master_type !== 1)
        );
        setData({
          ...data,
          img: temData?.logo ?? "",
          Company: {
            value: temData?.property?.company?.id,
            label: temData?.property?.company?.name,
          },
          property: temData?.name,
          status: {
            value: temData?.is_active,
            label: temData?.is_active === true ? t("Active") : t("In-Active"),
          },
          des: temData?.description,
          type: {
            value: temData?.property?.property_groupByID?.id,
            label: temData?.property?.property_groupByID?.group_name,
          },
          purpose: {
            value: temData?.floor_purpose,
            label: temData?.floor_purpose,
          },
          revenue: {
            value: temData?.revenue_type,
            label: temData?.revenue_type,
          },
          unit: temData?.uomByID ?? "",
          email: temData?.address?.email_id,
          website: temData?.property?.website,
          doorNo: temData?.address?.door_no,
          addressLineOne: temData?.address?.addressLineOne,
          addressLineTwo: temData?.address?.addressLineTwo,
          landmark: temData?.address?.landmark,
          area: temData?.address?.area,
          city: temData?.address?.city,
          state: temData?.address?.state,
          zipcode: temData?.address?.zipcode,
          country: temData?.address?.country,
          latitude: temData?.address?.latitude,
          longitude: temData?.address?.longitude,
          initials: true,
          // email: temData?.emailAddress,
          // website: temData?.emailAddress,
          bussinessmobile: {
            mobile: temData?.address?.mobile_no,
            mobile_code: temData?.address?.mobile_no_country_code,
          },
          mobile: {
            mobile: temData?.address?.alternative_mobile_no,
            mobile_code: temData?.address?.alternative_mobile_no_country_code,
          },
          step: 0,
          built: new Date(temData?.year_built) ?? "",
          handover: new Date(temData?.planned_hand_over_date) ?? "",
          error: {
            img: "",
            Company: "",
            property: "",
            status: "",
            des: "",
            type: "",
            purpose: "",
            revenue: "",
            unit: "",
            capture: "",
            total: "",
            initials: true,
            email: "",
            website: "",
            bussinessmobile: "",
            mobile: "",
            step: 0,
          },
        });
        setLoad(true);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went wrong please try again"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  const getPropertyByCompanyID = (propertyID) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: t("Loading"),
    });

    const payload = { propertyID };

    NetworkCall(
      `${config?.api_url}/queries/property/get`,
      NetWorkCallMethods?.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        let temData = res?.data?.data?.property?.[0];
        if (temData?.property_hierarchyByID?.is_floor) {
          setData({
            ...data,
            block: "",
            img: temData?.logo,
            Company: {
              value: temData?.company?.value,
              label: temData?.company?.label,
            },
            property: "",
            property_hierarchy: temData?.property_hierarchyByID,
            propertyHierarchy: temData?.property_hierarchy,
            des: "",
            type: {
              value: temData?.propertyType?.value,
              label: temData?.propertyType?.label,
            },
            purpose: {
              value: temData?.property_purpose,
              label: temData?.property_purpose,
            },
            revenue: {
              value: temData?.revenue_type,
              label: temData?.revenue_type,
            },
            unit: temData?.uomByID ?? "",
            capture: "",
            total: "",
            doorNo: temData?.addressByID?.door_no,
            addressLineOne: temData?.addressByID?.addressLineOne,
            addressLineTwo: temData?.addressByID?.addressLineTwo,
            landmark: temData?.addressByID?.landmark,
            area: temData?.addressByID?.area,
            city: temData?.addressByID?.city,
            state: temData?.addressByID?.state,
            zipcode: temData?.addressByID?.zipcode,
            country: temData?.addressByID?.country,
            latitude: temData?.addressByID?.latitude,
            longitude: temData?.addressByID?.longitude,
            initials: true,
            email: temData?.emailAddress ?? "",
            website: temData?.website,
            bussinessmobile: {
              mobile: temData?.mobile_phone ?? "",
              mobile_code: temData?.mobile_country_code ?? "",
            },
            mobile: {
              mobile: temData?.business_phone ?? "",
              mobile_code: temData?.business_phone_country_code ?? "",
            },
            step: 0,
            built: new Date(temData?.year_built) ?? "",
            handover: new Date(temData?.planned_hand_over_date) ?? "",

            error: {
              block: "",
              img: "",
              Company: "",
              property: "",
              des: "",
              type: "",
              purpose: "",
              revenue: "",
              unit: "",
              capture: "",
              total: "",
              initials: true,
              email: "",
              website: "",
              bussinessmobile: "",
              mobile: "",
              step: 0,
            },
          });
          setLoad(true);
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.info,
            msg:
              getCustomFloorName(data?.property_hierarchy) +
              t(" creation is restricted."),
          });
          navigate(-1);
        }
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((error) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
      });
  };

  const onChangeBlock = (value) => {
    getPropertyById1(value);
  };

  const render = () => {
    return (
      <>
        <Subheader
          title={getCustomFloorName(data?.property_hierarchy) + t(" Add/Edit")}
          goBack={() => {
            navigate(-1);
          }}
          previousAndNextBtn={true}
          previousBtn={() => checkSave("previous")}
          nextBtn={() => checkSave("next")}
          previousBtnDisabled={value <= 1 && true}
          nextBtnDisabled={value === 2 ? true : false}
        />
        <PropertyTabs
          value={value}
          isHeigth
          tab1={
            <FloorDetails
              id="pushTitle"
              Load={Load}
              showBlock={
                !Boolean(state?.edit?.isEdit) &&
                !Boolean(state?.main?.blockID) &&
                data?.property_hierarchy?.is_block
              }
              propertyID={state?.main?.propertyID}
              onChangeBlock={onChangeBlock}
              data={data}
              updateState={updateState}
              updateimg={updateimg}
              mapResult={mapResultData}
              removeimg={removeimg}
              mapLoad={mapLoad}
              t={t}
            />
          }
          tab2={
            <Assests
              images={images}
              t={t}
              setImages={setImages}
              handleAssetFile={handleAssetFile}
              companyId={data?.Company?.value}
            />
          }
          tabTittle={tabTittle}
          showBtns={true}
          isSaveDisable={disable}
          saveBtnText={state?.edit?.isEdit ? t("Update") : t("Submit")}
          save={() => checkSave("save")}
          t={t}
        />
      </>
    );
  };

  return (
    <div style={{ position: "relative" }}>
      {accessCheckRender(render, permission)}
    </div>
  );
};
export default withTranslation("floorList")(CreateFloor);
