import WarningIcon from "@mui/icons-material/Warning";
import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { withTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { Subheader } from "../../components";
import { NewLoader } from "../../components/newLoader";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import {
  AlertProps,
  NetWorkCallMethods,
  getNextFrequency,
  useWindowDimensions,
} from "../../utils";
import { ProposedBOM } from "./components/BOM";
import { AddItem } from "./components/addItem";
import { BasicPlanDetails } from "./components/basicPlanDetails";
import { ChooseProperties } from "./components/chooseProperties";
import { ContractDetails } from "./components/contractDetails";
import { ExecutionDetails } from "./components/executionDetails";
import { PlanPreview } from "./components/planPreview";
import { ScheduleWorkOrders } from "./components/scheduleWorkOrder";
import { PlanStepper } from "./components/stepper";
import { useStyles } from "./style";
import { constructViewData, initialState } from "./utils";
const CreateFixedMaintenancePlanner = ({ t = () => false }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const alert = React.useContext(AlertContext);
  const size = useWindowDimensions();
  const { state } = useLocation();
  const matches = useMediaQuery("(min-width:900px)");
  const [activeStep, setActiveStep] = React.useState(0);
  const [data, setData] = React.useState({ ...initialState });
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (state?.is_draft) {
      getPlannerDetails();
    }
    if (state?.pmp_type) {
      setData({ ...data, PMPType: state?.pmp_type });
    }
    //eslint-disable-next-line
  }, []);

  const getPlannerDetails = () => {
    setLoading(true);
    const payload = {
      id: state?.tableData?.id,
    };

    NetworkCall(
      `${config.api_url}/${
        state?.planner_type === "Fixed"
          ? "preventive_plan/get"
          : "preventive_dynamic_plan/get"
      }`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then(async (response) => {
        const result = constructViewData(
          response?.data?.data,
          state?.planner_type
        );
        await setData({ ...data, ...result });
        setLoading(false);
        // setDetails({ ...response?.data?.data, id: details?.id })
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const updateState = (k, v, k1, v1, k2, v2) => {
    if (k === "frequency") {
      let occurence = data?.occurence > 0 ? data?.occurence : 1;
      let end_date = getNextFrequency(
        v?.value,
        occurence - 1,
        data?.execution_start_date
      );
      setData({ ...data, [k]: v, execution_end_date: end_date });
    } else if (k === "execution_start_date") {
      let occurence = data?.occurence > 0 ? data?.occurence : 1;
      let end_date = getNextFrequency(data?.frequency?.value, occurence - 1, v);
      setData({ ...data, [k]: v, execution_end_date: end_date });
    } else if (k === "occurence") {
      let occurence = v > 0 ? v : 1;
      let end_date = getNextFrequency(
        data?.frequency?.value,
        occurence - 1,
        data?.execution_start_date
      );
      setData({ ...data, [k]: v, execution_end_date: end_date });
    } else if (k === "execution_hours") {
      if (v > 0) {
        setData({ ...data, [k]: v, [k1]: v1, [k2]: v2 });
      } else {
        setData({ ...data, execution_hours: "" });
      }
      // setData({ ...data, "execution_hours": ""})
    } else {
      setData({ ...data, [k]: v, [k1]: v1, [k2]: v2 });
    }
  };

  const checkValidation = () => {
    let isValid = true;
    if (activeStep === 0) {
      if (state?.planner_type === "Fixed") {
        isValid =
          data?.plan_name?.length > 0 &&
          data?.plan_description?.length > 0 &&
          (data?.PMPType === "Unit"
            ? data?.unit_type?.label?.length > 0
            : data?.category?.label?.length > 0 &&
              data?.sub_category?.label?.length > 0) &&
          data?.execution_start_date !== null &&
          data?.frequency?.label?.length > 0 &&
          data?.occurence !== "" &&
          data?.resource_group?.length > 0 &&
          data?.execution_hours !== null &&
          (data?.contract_details?.data?.length > 0
            ? data?.selected_properties?.filter((x) => {
                return x?.is_selected;
              })?.length > 0
            : true)
            ? true
            : false;
      } else {
        isValid =
          data?.plan_name?.length > 0 &&
          data?.plan_description?.length > 0 &&
          data?.resource_group?.length > 0 &&
          (data?.resource_type === "vendor"
            ? data?.vendor_name?.label?.length > 0
            : true) &&
          (data?.PMPType === "Unit"
            ? data?.unit_type?.label?.length > 0
            : data?.category?.label?.length > 0 &&
              data?.sub_category?.label?.length > 0) &&
          data?.dynamic_schedule
            ?.map((values) => {
              return values?.execution_start_date;
            })
            ?.every((x) => {
              return x !== null;
            }) &&
          data?.dynamic_schedule
            ?.map((values) => {
              return values?.frequency;
            })
            ?.every((x) => {
              return x !== "";
            }) &&
          data?.dynamic_schedule
            ?.map((values) => {
              return values?.occurence;
            })
            ?.every((x) => {
              return x !== "";
            }) &&
          (data?.contract_details?.data?.length > 0
            ? data?.selected_properties?.filter((x) => {
                return x?.is_selected;
              })?.length > 0
            : true)
            ? true
            : false;
      }
    } else if (activeStep === 1) {
      if (state?.planner_type === "Fixed") {
        isValid = data?.selected_items?.length > 0 ? true : false;
      } else {
        isValid = data?.selected_properties?.length > 0 ? true : false;
      }
    } else if (activeStep === 2) {
      if (state?.planner_type === "Fixed") {
        isValid = data?.selected_resources?.length > 0 ? true : false;
      } else {
        isValid = data?.selected_dynamic_checklist?.length > 0 ? true : false;
      }
    } else if (activeStep === 3) {
      if (state?.planner_type === "Fixed") {
        isValid = data?.selected_checklist?.length > 0 ? true : false;
      } else {
        // isValid = true
        isValid =
          data?.BOM?.length > 0
            ? data?.BOM?.map((x) => {
                return x?.itemName?.label;
              })?.every((i) => {
                return i?.length > 0;
              })
            : true;
      }
    } else if (activeStep === 4) {
      // isValid = true
      isValid =
        data?.BOM?.length > 0
          ? data?.BOM?.map((x) => {
              return x?.itemName?.label;
            })?.every((i) => {
              return i?.length > 0;
            })
          : true;
    }
    return isValid;
  };
  const next = () => {
    const result = checkValidation();
    const start_date = moment(data?.execution_start_date);
    const current_date = moment(moment(new Date()).format("MM-DD-YYYY"));
    const dynamic_start_date = data?.dynamic_schedule
      ?.map((values) => moment(values?.execution_start_date))
      ?.every((start_date) => start_date.isAfter(current_date));
    if (
      result &&
      ((state?.planner_type === "Fixed" && start_date > current_date) ||
        (state?.planner_type !== "Fixed" && dynamic_start_date))
    ) {
      setActiveStep(activeStep + 1);
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg:
          activeStep === 0
            ? (state?.planner_type === "Fixed" && start_date <= current_date) ||
              (state.planner_type !== "Fixed" && !dynamic_start_date)
              ? t("Execution start date should be greater than current date")
              : t("Please fill all mandatory fields")
            : activeStep === 1
            ? state?.planner_type === "Fixed"
              ? t("Please add Unit /Item ")
              : t("Please Choose Property")
            : activeStep === 2
            ? state?.planner_type === "Fixed"
              ? t("Please add Resource")
              : t("Please add CheckList")
            : activeStep === 3
            ? state?.planner_type === "Fixed"
              ? t("Please add CheckList")
              : data?.BOM?.length === 0
              ? t("Please add BOM")
              : t("Please Choose Item")
            : data?.BOM?.length === 0
            ? t("Please add BOM")
            : t("Please Choose Item"),
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };

  const submitDraft = () => {
    const result = checkValidation();
    if (result) {
      onSubmit(true);
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg:
          activeStep === 0
            ? t("Please fill all mandatory fields")
            : activeStep === 1
            ? state?.planner_type === "Fixed"
              ? t("Please add Unit /Item ")
              : t("Please Choose Property")
            : activeStep === 2
            ? state?.planner_type === "Fixed"
              ? t("Please add Resource")
              : t("Please add CheckList")
            : activeStep === 3
            ? state?.planner_type === "Fixed"
              ? t("Please add CheckList")
              : data?.BOM?.length === 0
              ? t("Please add BOM")
              : t("Please Choose Item")
            : data?.BOM?.length === 0
            ? t("Please add BOM")
            : t("Please Choose Item"),
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };
  const onSubmit = (is_draft = false) => {
    const unitAndItem = data?.selected_items?.map((x) => {
      return {
        id: state?.is_edit ? x?.edit_id : undefined,
        is_active: true,
        item_id: x?.item_id,
        plan_config_type: x?.config_type,
        property_id: x?.property_id,
        unit_id: x?.unit_id,
        unit_mapping_id: x?.item_mapping_id,
      };
    });
    const CheckList =
      data?.selected_checklist?.length > 0
        ? data?.selected_checklist?.map((x) => {
            return {
              id: state?.is_edit ? x?.edit_id : undefined,
              checklist_group_id: x?.checklist_group_id,
              is_active: true,
              plan_config_type: "Checklist",
            };
          })
        : [];
    const Resource =
      data?.selected_resources?.length > 0
        ? data?.selected_resources?.map((x) => {
            return {
              id: state?.is_edit ? x?.edit_id : undefined,
              resource_id: x?.value,
              is_active: true,
              plan_config_type: "Resource",
            };
          })
        : [];
    const dynamic_schedule =
      data?.dynamic_schedule?.length > 0
        ? data?.dynamic_schedule?.map((x) => {
            return {
              id: state?.is_edit ? x?.edit_id : undefined,
              occurrences: x?.occurence,
              frequency: x?.frequency?.value,
              start_date: moment(x?.execution_start_date).format("YYYY-MM-DD"),
              end_date: moment(x?.execution_end_date).format("YYYY-MM-DD"),
              dynamic_config_type: "Schedule",
              is_active: true,
            };
          })
        : [];
    const dynamic_checkList =
      data?.selected_dynamic_checklist?.length > 0
        ? data?.selected_dynamic_checklist?.map((x) => {
            return {
              id: state?.is_edit ? x?.edit_id : undefined,
              checklist_group_id: x?.checklist_group_id,
              is_active: true,
              dynamic_config_type: "Checklist",
              frequency: x?.selected_period,
            };
          })
        : [];
    const dynamic_properties =
      data?.selected_properties?.length > 0
        ? data?.selected_properties?.map((x) => {
            return {
              is_active: true,
              property_id: x?.value ?? x?.property_id,
              dynamic_config_type: "Property",
            };
          })
        : [];
    const payload = {
      upsert_params: {
        id: data?.id,
        resource_type: data?.resource_type,
        company_id: state?.company?.value,
        pmp_type: data?.PMPType,
        name: data?.plan_name,
        description: data?.plan_description,
        unit_type: data?.unit_type?.value,
        pmp_criteria: data?.pmp_criteria,
        start_date:
          state?.planner_type === "Fixed"
            ? moment(data?.execution_start_date).format("YYYY-MM-DD")
            : null,
        end_date:
          state?.planner_type === "Fixed"
            ? moment(data?.execution_end_date).format("YYYY-MM-DD")
            : null,
        roles: data?.job,
        skills: data?.profession,
        resource_group_ids: data?.resource_group?.map((x) => {
          return x.value;
        }),
        execution_hour: data?.execution_hours ?? null,
        properties: data?.selected_properties?.map((x) => {
          return {
            id:
              state?.planner_type === "Fixed"
                ? x?.property?.id
                : x?.value ?? x?.property_id,
            name:
              state?.planner_type === "Fixed"
                ? x?.property?.name
                : x?.label ?? x?.property_name,
            logo:
              state?.planner_type === "Fixed"
                ? x?.property?.logo
                : x?.image_url ?? x?.logo,
          };
        }),
        vendor_id:
          data?.resource_type === "internal" ? null : data?.vendor_name?.value,
        contract_id: data?.contract_details?.data?.[0]?.id,
        plan_type: state?.planner_type,
        frequency: data?.frequency?.value,
        occurrences:
          state?.planner_type === "Fixed"
            ? data?.occurence > 1
              ? data?.occurence
              : 0
            : null,
        category_id: data?.category?.value,
        subcategory_id: data?.sub_category?.value,
        is_active: true,
        is_draft: is_draft,
        config:
          state?.planner_type === "Fixed"
            ? [...unitAndItem, ...CheckList, ...Resource]
            : undefined,
        bom: data?.BOM?.map((val) => {
          return {
            id: state?.is_edit ? val?.edit_id : undefined,
            inspection_item: val?.itemName?.value,
            amount_per_item: val?.price,
            quatity: val?.qty,
            line_amount: val?.price * val?.qty,
            currency_id: state?.company?.currency_id,
            is_active: true,
          };
        }),
        schedule:
          state?.planner_type === "Fixed"
            ? data?.schedule_work_orders?.map((val) => {
                return {
                  // "id": state?.is_edit ? val?.id : undefined,
                  unit_id: val?.unit_id,
                  property_id: val?.property_id,
                  item_mapping_id: val?.item_mapping_id,
                  execution: val?.execution,
                  resource_id: val?.resource?.value,
                };
              })
            : undefined,
        dynamic_config:
          state?.planner_type === "Dynamic"
            ? [...dynamic_schedule, ...dynamic_checkList, ...dynamic_properties]
            : undefined,
        dynamic_order:
          state?.planner_type === "Dynamic"
            ? data?.dynamic_plan_preview?.map((x) => {
                return {
                  id: state?.is_edit ? x?.edit_id : undefined,
                  property_id: x?.property_id,
                  execution_date: moment(x?.start_date).format("YYYY-MM-DD"),
                  period: x?.frequency,
                  occurrences: x?.occurences > 1 ? x?.occurences : 0,
                  start_date: moment(x?.start_date).format("YYYY-MM-DD"),
                  end_date: moment(x?.end_date).format("YYYY-MM-DD"),
                };
              })
            : undefined,
      },
    };
    NetworkCall(
      `${config.api_url}/preventive_plan/upsert`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        const msg = is_draft
          ? "Saved as Draft Successfully"
          : "Plan has been Published Successfully";
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: msg,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        navigate(
          state?.planner_type === "Fixed"
            ? {
                pathname: Routes?.fixedMaintenancePlanner,
                state: { tabValue: state?.tabValue },
              }
            : {
                pathname: Routes?.dynamicMaintenancePlanner,
                state: { tabValue: state?.tabValue },
              }
        );
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  const goBack = () => {
    if (state?.planner_type === "Fixed") {
      navigate(Routes?.fixedMaintenancePlanner, {
        state: { tabValue: state?.tabValue },
      });
    } else {
      navigate(Routes?.dynamicMaintenancePlanner, {
        state: { tabValue: state?.tabValue },
      });
    }
  };
  return (
    <Box>
      <Subheader
        goBack={() => goBack()}
        // padding={"12px 4px"}
        title={
          state?.planner_type === "Fixed"
            ? t("Create New Fixed Preventive Maintenance Planner")
            : t("Create New Dynamic Preventive Maintenance Planner")
        }
      />
      {loading ? (
        <NewLoader />
      ) : (
        <>
          <Box className={classes.root} position={"relative"}>
            <Box p={2} display={"flex"} justifyContent={"center"}>
              <PlanStepper t={t} activeStep={activeStep} state={state} />
            </Box>
            <Divider />
            <Box
              height={matches ? "auto" : size?.height - 300}
              overflow="scroll"
            >
              {activeStep === 0 ? (
                <Box>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <BasicPlanDetails
                        state={state}
                        t={t}
                        data={data}
                        setData={setData}
                        updateState={updateState}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <ExecutionDetails
                        state={state}
                        t={t}
                        data={data}
                        setData={setData}
                        updateState={updateState}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <ContractDetails
                        state={state}
                        t={t}
                        data={data}
                        setData={setData}
                        updateState={updateState}
                        loading={loading}
                        setLoading={setLoading}
                      />
                    </Grid>
                  </Grid>
                </Box>
              ) : activeStep === 1 ? (
                <Box>
                  {state?.planner_type === "Fixed" ? (
                    <AddItem
                      state={state}
                      t={t}
                      data={data}
                      updateState={updateState}
                      form_type="unit"
                    />
                  ) : (
                    <ChooseProperties
                      state={state}
                      t={t}
                      data={data}
                      updateState={updateState}
                    />
                  )}
                </Box>
              ) : activeStep === 2 ? (
                <Box>
                  {state?.planner_type === "Fixed" ? (
                    <AddItem
                      state={state}
                      t={t}
                      data={data}
                      updateState={updateState}
                      form_type="resource"
                    />
                  ) : (
                    <AddItem
                      state={state}
                      t={t}
                      data={data}
                      updateState={updateState}
                      form_type="checkList"
                    />
                  )}
                </Box>
              ) : activeStep === 3 ? (
                <Box>
                  {state?.planner_type === "Fixed" ? (
                    <AddItem
                      state={state}
                      t={t}
                      data={data}
                      updateState={updateState}
                      form_type="checkList"
                    />
                  ) : (
                    <ProposedBOM t={t} data={data} updateState={updateState} />
                  )}
                </Box>
              ) : activeStep === 4 ? (
                <Box>
                  {state?.planner_type === "Fixed" ? (
                    <ProposedBOM t={t} data={data} updateState={updateState} />
                  ) : (
                    <PlanPreview t={t} data={data} updateState={updateState} />
                  )}
                </Box>
              ) : (
                <Box>
                  <ScheduleWorkOrders
                    state={state}
                    t={t}
                    data={data}
                    updateState={updateState}
                  />
                </Box>
              )}
            </Box>
          </Box>
          <Box
            className={classes.fixedBottom}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Button
              variant="outlined"
              className={classes.draftBtn}
              onClick={() => submitDraft()}
            >
              {t("Save As Draft")}
            </Button>
            <Stack direction="row" spacing={1} alignItems="center">
              {activeStep === 0 && (
                <Box className={classes.warningCard}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <WarningIcon sx={{ color: "#FF9340" }} />
                    <Typography className={classes.warningText}>
                      {t(
                        "Please recheck this information, Once it freezed it can't be edited"
                      )}
                    </Typography>
                  </Stack>
                </Box>
              )}
              {(state?.planner_type === "Dynamic" ||
                state?.planner_type === "Fixed") &&
                activeStep !== 0 && (
                  <Button
                    variant="outlined"
                    className={classes.draftBtn}
                    onClick={() => setActiveStep(activeStep - 1)}
                  >
                    {t("Previous")}
                  </Button>
                )}
              <Button
                variant="contained"
                className={classes.createBtn}
                onClick={() =>
                  (state?.planner_type === "Fixed" && activeStep === 5) ||
                  (state?.planner_type === "Dynamic" && activeStep === 4)
                    ? onSubmit()
                    : next()
                }
              >
                {activeStep === 0
                  ? t("Freeze & Proceed")
                  : (state?.planner_type === "Fixed" && activeStep === 5) ||
                    (state?.planner_type === "Dynamic" && activeStep === 4)
                  ? t("Create & Execute Plan")
                  : t("Next")}
              </Button>
            </Stack>
          </Box>
        </>
      )}
    </Box>
  );
};
export default withTranslation("maintenancePlanner")(
  CreateFixedMaintenancePlanner
);
