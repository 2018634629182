import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex"
    },
    content: {
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid #E4E8EE",
        borderRadius: "4px"
    },
    divider: {
        height: "24px",
        width: "1px",

        backgroundColor: "#CED3DD",
        marginInline: "8px"
    },
    Label: {
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: (props) => props?.labelSize ?? "0.75rem",
        marginBottom: (props) => props?.labelMarginBottom ?? "5px",
    },
    text: {},
    required: {
        color: "red",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: (props) => props?.labelSize ?? "0.75rem",
        marginBottom: (props) => props?.labelMarginBottom ?? "5px",
    },
    textbox: {
        backgroundColor: (props) => props?.color ?? "auto",
        borderRadius: theme.palette.borderRadius,
        border: "none",
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px white inset",
            backgroundColor: '#fff'
        },
        [`& fieldset`]: {
            borderRadius: theme.palette.borderRadius,
            height: (props) => (props.multiline ? "unset" : props?.height ?? 50),
            border: "none",
            "&:hover": {
                border: "none",
            },
        },
        "& .MuiOutlinedInput-input": {
            padding: (props) => props?.padding ?? "11px 14px",
        },
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                border: "none",
            },
        },
    },
    customSelect: {
        width: "60%",
        fontSize: (props) => props?.fontSize ?? "0.875rem",
        border: "none",
        control: (base) => ({
            ...base,
            borderRadius: 4,
            backgroundColor: "none",
            zIndex: 1,
            height: 45,
            fontSize: (props) => props?.fontSize ?? "0.75rem",
            padding: "auto",
            border: "none",
            boxShadow: "none",
            outline: "none",
            "&:hover": {
                border: "none",
            },
        }),
        placeholder: defaultStyles => {
            return {
                ...defaultStyles,
                fontFamily: FontFamilySwitch().regular,
                fontSize: (props) => props?.fontSize ?? "0.75rem",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "100%",
                overflow: "hidden"

            };
        },
        menu: defaultStyles => {
            return {
                ...defaultStyles,
                zIndex: 3,
                borderRadius: "4px",
            };
        },
        menuList: defaultStyles => {
            return {
                ...defaultStyles,
            };
        },
        option: defaultStyles => {
            return {
                ...defaultStyles,
                padding: "8px 12px"
                // margin: "0px"
            };
        },
    },
    ampmContainer: {
        cursor: "pointer",
    },
    ampm: {
        fontSize:"0.75rem"
    },
    selectedAmPm: {
        cursor: "pointer",
        backgroundColor: "#5078E1",
        color: "white",
        fontSize:"0.75rem",
        padding: "8px 20px 8px 8px"
    },
    unSelectedAmPm: {
        cursor: "pointer",
        fontSize:"0.75rem",
        padding: "8px 20px 8px 8px"
    },
    mobBtn: {

        }

    }
));
