import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "12px",
    borderRadius: "4px",
    boxShadow: "0px 1px 16px #00000014",
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontSize: "0.875rem",
    color: "#091B29",
    fontFamily: FontFamilySwitch().semiBold,
    marginBottom: "4px",
  },
  dropBox: {
    width: "100%",
    marginTop: "4px",
    marginBottom: "4px",
    backgroundColor: "#F8F9FA",
    borderRadius: "4px",
    padding: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  subTitle: {
    fontSize: "0.875rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().semiBold,
    margin: "4px",
  },
  icon: {
    color: "#98A0AC",
    fontSize: "1.125rem",
  },
  upfrontBox: {
    width: "150px",
    [theme.breakpoints.only("sm")]: {
      width: "100px",
    },
  },
}));
