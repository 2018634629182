import { Avatar, Box, Stack, Tooltip, Typography, tooltipClasses } from '@mui/material'
import React from 'react'
import { EditIcon } from '../../../components'
import { UnitAccountCardStyle } from './styles'
import { styled } from '@mui/material/styles';
import { remCalc, FontFamilySwitch } from '../../../utils';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#4E5A6B',
        color: '#fff',
        maxWidth: 220,
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().semiBold,
    },
}));

export const UnitAccountCard = ({
    type = "normal",
    title = "",
    subTitle = "",
    edit = false,
    image_url = "",
    openEdit = () => false,
    left = false,
    t = () => false
}) => {
    const classes = UnitAccountCardStyle()
    const avatarClasses = {
        "portal": classes.portalAvatar,
        "onboarding": classes.onboardingAvatar,
        "normal": classes.boxAvatar,
        "rounded": classes.roundAvatar
    }
    const avatarUrl = {
        "portal": "images/portalUserIcon.svg",
        "onboarding": "images/onboardingIcon.svg",
        "normal": image_url ? image_url : "images/noUnitAvatar.svg",
        "rounded": image_url ? image_url : "/images/noImageAvatar.svg"
    }
    return (
        <>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                    <Avatar
                        className={avatarClasses[type]}
                        src={avatarUrl[type]}>
                    </Avatar>
                    <Stack>
                        <Typography className={classes.title}>{title}</Typography>
                        {subTitle &&
                            <HtmlTooltip
                                arrow
                                title={
                                    <React.Fragment>
                                        <Typography className={classes.tooltipText}>{subTitle}</Typography>
                                    </React.Fragment>
                                }><Typography className={left ? classes.leftSubtitle : classes.subTitle}>{subTitle}</Typography>
                            </HtmlTooltip>
                        }
                    </Stack>
                </Stack>
                {edit &&
                    <HtmlTooltip
                        arrow
                        title={
                            <React.Fragment>
                                <Typography className={classes.tooltipText}>{t("Edit Access Person")}</Typography>
                            </React.Fragment>
                        }>
                        <Box className={classes.editBtn} onClick={openEdit}>
                            <EditIcon />
                        </Box>
                    </HtmlTooltip>
                }
            </Stack>
        </>
    )
}