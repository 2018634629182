import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    weatherTilte: {
        color: theme.typography.color.white,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        marginBottom: 0
    },
    weatherReport: {
        color: theme.typography.color.white,
        fontSize:"1.125rem",
        fontFamily: FontFamilySwitch().bold,
        marginBottom: '3px'
    },
    weatherBox: {
        maxWidth: '233px',
        width: '100%',
        borderRadius: '4px',
        backgroundColor: theme.palette.weather.main,
        padding: '16px',
        position: 'fixed',
        bottom: 16,
        left: 16
    },
    weatherTimeDate: {
        color: theme.typography.color.white,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().regular,
        marginBottom: '5px'
    },
    weatherInnerBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    flagImage: {
        height: 50,
        width: 50
    }
}));