import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import styled from "@mui/material/styles/styled";
import useTheme from "@mui/material/styles/useTheme";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { EditIcon, LoadingSection, Subheader, TextBox } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import {
  AlertProps,
  LocalStorageKeys,
  NetWorkCallMethods,
  FontFamilySwitch
} from "../../utils";
import { UploadImageURL } from "./imageUploadURL";
import { UploadImage } from "./upload";
import { conditions } from "./utils";

const CustomBox = styled("div")(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 0px 16px #00000014",
  borderRadius: 4,
  width: "100%",
  // height: '100%',
  padding: theme.spacing(1),
  height: "calc(100vh - 130px)",
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
}));

const CustomTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "fontSize",
})(({ theme, fontSize }) => ({
  fontFamily: FontFamilySwitch().semiBold,
  fontSize: fontSize ? fontSize : "0.75rem",
  color: theme.typography.color.tertiary,
  marginBottom: theme.spacing(1),
}));

const CustomGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

export const ConfigurationMasterViewEdit = (props) => {
  const theme = useTheme();
  const alert = React.useContext(AlertContext);
  const navigate = useNavigate();
  const location = useLocation();

  const { condition, company, edit, type } = location?.state;

  const splitID = location.pathname.split("/");
  const findRoute = splitID[splitID?.length - 1];

  const [state, setState] = useState({
    bannerStatus: false,
    uploadedImages: [],
    isEdit: false,
    deleteAssests: [],
    existingAssests: [],
    updateAssests: [],
  });

  const [loading, setLoading] = useState(null);

  const onChangeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
    setLoading(null);
  };

  const submit = () => {
    const validationCheck = validation();
    if (validationCheck) {
      setLoading("submit");
      networkCallback();
    }
  };

  useEffect(() => {
    if (findRoute === "add") {
      onChangeState("isEdit", true);
    } else {
      getConfigurationDetails(findRoute);
    }
    // eslint-disable-next-line
  }, []);

  const getConfigurationDetails = async (id) => {
    setLoading("screen");
    const payload = {
      id,
      tenantId: config.tenantId,
    };
    await NetworkCall(
      `${config.api_url}/company-banner/get-banner`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let result = response.data.data?.[0];
        setState({
          ...state,
          company: {
            label: result?.company_name,
            value: result?.company_id,
          },
          // bannerStatus: result?.is_active,
          uploadedImages: returnAssests(result?.assets),
          isEdit: Boolean(edit),
          existingAssests: result?.assets?.map(({ id }) => id),
        });
        setLoading(null);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
        });
      });
  };

  const returnAssests = (data) => {
    return data?.map((_) => ({
      src: _?.url,
      url: _?.action_url ?? "",
      priority: _?.priority,
      id: _?.id,
      name: _?.file_meta?.name,
      type: _?.file_meta?.type,
      size: _?.file_meta?.size,
    }));
  };

  const networkCallback = () => {
    let extraPayload = {};

    if (findRoute !== "add") {
      extraPayload = {
        id: findRoute,
        deleteAssets: state?.deleteAssests?.length ? state?.deleteAssests : [],
        updateAssets: state?.updateAssests?.length ? state?.updateAssests : [],
      };
    }

    let payload = {
      tenantId: config.tenantId,
      company_id: company?.id,
      type: type,
      // "is_active": state?.bannerStatus,
      user_profile_id: localStorage.getItem(LocalStorageKeys.profileID),
      assets: state?.uploadedImages
        ?.filter(({ id }) => !state?.existingAssests?.includes(id))
        ?.map(({ src, url, name, size, type }) => ({
          url: src,
          action_url: url ?? "",
          file_meta: {
            name,
            size,
            type,
          },
        })),
      ...extraPayload,
    };
    NetworkCall(
      `${config.api_url}/company-banner/upsert`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data.data;
        alert.setSnack({
          open: true,
          horizontal: AlertProps.horizontal.center,
          vertical: AlertProps.vertical.top,
          msg: JSON.stringify(main),
          severity: AlertProps.severity.success,
        });
        navigate({
          pathname: Routes.configuration,
        });
        setLoading(null);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
        });
        setLoading(null);
      });
  };

  const validation = () => {
    if (company.value && state?.uploadedImages?.length) {
      return true;
    } else {
      alert.setSnack({
        open: true,
        horizontal: AlertProps.horizontal.center,
        vertical: AlertProps.vertical.top,
        msg: returnErrorMsg(),
        severity: AlertProps.severity.error,
      });
      return false;
    }
  };

  const returnErrorMsg = () => {
    if (state?.uploadedImages?.length === 0 && !state?.company?.value) {
      return `Please select company and upload atleast one image`;
    } else if (state?.uploadedImages?.length === 0) {
      return `Please upload atleast one image`;
    } else {
      return `Please select company`;
    }
  };

  const handleUploadImages = (value) => {
    setLoading("uploadedImages");
    onChangeState("uploadedImages", [...state?.uploadedImages, value]);
  };

  const onChangeUrl = (value, index) => {
    let copyImage = [...state?.uploadedImages];
    copyImage[index] = value;
    setState(() => {
      if (value?.id) {
        if (state.updateAssests?.find((_) => _?.id === value?.id)) {
          state.updateAssests.find((_) => _?.id === value?.id)["action_url"] =
            value?.url;
        } else {
          state.updateAssests.push({ id: value?.id, action_url: value?.url });
        }

        return {
          ...state,
          uploadedImages: copyImage,
          updateAssests: [...state.updateAssests],
        };
      } else {
        return {
          ...state,
          uploadedImages: copyImage,
        };
      }
    });
  };

  const handledelete = (index, { id }) => {
    setLoading("uploadedImages");

    const filteredImage = state?.uploadedImages?.filter((_) => _?.id !== id);
    setState(() => {
      if (id) {
        return {
          ...state,
          uploadedImages: filteredImage,
          deleteAssests: [...state?.deleteAssests, id],
        };
      } else {
        return {
          ...state,
          uploadedImages: filteredImage,
        };
      }
    });

    setLoading(null);
  };

  const goBack = () => {
    navigate({
      pathname: Routes.configuration,
    });
  };

  return (
    <div>
      <Subheader
        title="Configuration"
        goBack={() => navigate(Routes.configuration)}
      />
      {loading === "screen" ? (
        <LoadingSection
          bottom={"30vh"}
          message="Loading Configuration Details..."
        />
      ) : (
        <Box p={1}>
          <CustomBox>
            <Grid
              container
              alignContent={"baseline"}
              sx={{
                flex: 1,
              }}
            >
              <CustomGrid item xs={12} md={6} lg={4}>
                <TextBox
                  label="Company"
                  value={company?.name}
                  isReadonly={true}
                />
              </CustomGrid>
              <CustomGrid
                item
                xs={12}
                md={6}
                lg={8}
                sx={{
                  order: { xs: -1, sm: 0 },
                }}
              >
                <Stack direction={"row"} justifyContent={"flex-end"}>
                  {/* <div>
                                    <CustomTypography>
                                        Banner Status
                                    </CustomTypography>
                                    <SwitchComponent
                                        id={`__switch`}
                                        checked={state?.bannerStatus ? state?.bannerStatus : false}
                                        handleChange={(value) => onChangeState('bannerStatus', value)}
                                        readOnly={!state?.isEdit}
                                    />
                                </div> */}
                  {!state?.isEdit && (
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{
                        gap: "10px",
                        borderRadius: 1,
                        height: 40,
                      }}
                      onClick={() => onChangeState("isEdit", true)}
                    >
                      <EditIcon />
                      Edit
                    </Button>
                  )}
                </Stack>
              </CustomGrid>
              {state?.isEdit && (
                <CustomGrid item xs={12}>
                  <CustomTypography fontSize={14}>
                    Upload Images
                  </CustomTypography>
                  <UploadImage
                    handleChange={handleUploadImages}
                    conditions={conditions[condition]}
                  />
                </CustomGrid>
              )}
              {state?.uploadedImages?.length > 0 && (
                <CustomGrid item xs={12}>
                  <CustomTypography fontSize={14}>
                    Uploaded Images
                  </CustomTypography>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    gap={3}
                    flexWrap={"wrap"}
                  >
                    {state?.uploadedImages?.map((_, index) => (
                      <UploadImageURL
                        data={{
                          // src: 'https://protomate.objectstore.e2enetworks.net/156-337x175.png',
                          src: _?.src,
                          url: _?.url ?? "",
                          name: _?.name,
                          type: _?.type,
                          size: _?.size,
                          id: _?.id,
                        }}
                        loading={loading === "uploadedImages"}
                        handleChange={(value) => onChangeUrl(value, index)}
                        handledelete={() => handledelete(index, _)}
                        isReadOnly={!state?.isEdit}
                        size={conditions[condition]}
                      />
                    ))}
                  </Stack>
                </CustomGrid>
              )}
            </Grid>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"flex-end"}
              gap={1}
            >
              {state?.isEdit && (
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: theme.palette.border.secondary,
                    color: theme.typography.color.primary,
                    borderRadius: theme.palette.borderRadius,
                  }}
                  onClick={goBack}
                >
                  Cancel
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: theme.palette.borderRadius,
                }}
                disabled={loading === "submit"}
                onClick={() => {
                  state?.isEdit ? submit() : goBack();
                }}
              >
                {state?.isEdit ? "Save" : "Back"}
              </Button>
            </Stack>
          </CustomBox>
        </Box>
      )}
    </div>
  );
};
