import { Box } from "@mui/material";
import React from "react";
import { CompanySettingsList } from "../../../components";
import { reservation_feature, validateCustom } from "../../../utils/companySettings";
export const AdvancedSettings = ({
    data = {},
    updateState = () => false,
    t
}) => {
    return (
        <Box>
            {/*"Enable Reservation Feature"*/}
            <Box padding="8px 12px">
                <CompanySettingsList
                    title={t("Enable Reservation Feature")}
                    onChange={(value) => updateState("reservation_feature", value)}
                    value={data?.reservation_feature}
                    toggle={true}
                    custom={true}
                    customValue={0}
                    openCustom={validateCustom([null], data?.reservation_feature?.value)}
                    isError={data?.error?.reservation_feature?.length > 0}
                    errorMessage={data?.error?.reservation_feature}
                    options={data?.reservation_feature?.is_active ? reservation_feature : []}
                />
            </Box>
            {/*Enable Auto Deposite Amount Calculation Feature*/}
            <Box padding="8px 12px">
                <CompanySettingsList
                    title={t("Enable Auto Deposite Amount Calculation Feature")}
                    onChange={(value) => updateState("auto_deposite_cal", value)}
                    value={data?.auto_deposite_cal}
                    toggle={true}
                />
            </Box>
            {/*Enable Auto Reservation Amount Calculation Feature*/}
            <Box padding="8px 12px">
                <CompanySettingsList
                    title={t("Enable Auto Reservation Amount Calculation Feature")}
                    onChange={(value) => updateState("auto_reservation_cal", value)}
                    value={data?.auto_reservation_cal}
                    toggle={true}
                />
            </Box>
            {/*"Enable Grace Period"*/}
            <Box padding="8px 12px">
                <CompanySettingsList
                    title={t("Enable Grace Period")}
                    onChange={(value) => updateState("grace_peroid", value)}
                    value={data?.grace_peroid}
                    toggle={true}
                    custom={true}
                    isError={data?.error?.grace_peroid?.length > 0}
                    errorMessage={data?.error?.grace_peroid}
                    customValue={0}
                    openCustom={validateCustom([null], data?.grace_peroid?.value)}
                    options={data?.grace_peroid?.is_active ? reservation_feature : []}
                />
            </Box>
            {/*"Finance Email"*/}
            <Box padding="8px 12px"> 
                <CompanySettingsList
                    type="email"
                    title={t("Finance Alert Email")}
                    onChange={(value) => updateState("finance_email", value)}
                    value={data?.finance_email}
                />
            </Box>
            {/*Enable Pricing Override*/}
            {/* <Box padding="8px 12px">
                <CompanySettingsList
                    title="Enable Pricing Override"
                    onChange={(value) => updateState("pricing_override", value)}
                    value={data?.pricing_override}
                    toggle={true}
                />
            </Box> */}
            {/*Enable Short Term Retals*/}
            {/* <Box padding="8px 12px">
                <CompanySettingsList
                    title="Enable Short Term Rentals"
                    onChange={(value) => updateState("short_term_retals", value)}
                    value={data?.short_term_retals}
                    toggle={true}
                />
            </Box> */}
            {/*Enable Storage Offering*/}
            {/* <Box padding="8px 12px">
                <CompanySettingsList
                    title="Enable Storage Offering"
                    onChange={(value) => updateState("storage_offering", value)}
                    value={data?.storage_offering}
                    toggle={true}
                />
            </Box> */}
            {/*"Enable Rental Pricing Index Calculation"*/}
            {/* <Box padding="8px 12px">
                <CompanySettingsList
                    title="Enable Rental Pricing Index Calculation"
                    onChange={(value) => updateState("index_calculation", value)}
                    value={data?.index_calculation}
                    toggle={true}
                    options={data?.index_calculation?.is_active ? enumValue?.rera_type : []}
                />
            </Box> */}
            {/*Enable Refer and Win*/}
            {/* <Box padding="8px 12px">
                <CompanySettingsList
                    title="Enable Refer and Win"
                    onChange={(value) => updateState("refer_win", value)}
                    value={data?.refer_win}
                    toggle={true}
                />
            </Box> */}
            {/*Enable Professional Banners*/}
            {/* <Box padding="8px 12px">
                <CompanySettingsList
                    title="Enable Professional Banners"
                    onChange={(value) => updateState("professoinal_banners", value)}
                    value={data?.professoinal_banners}
                    toggle={true}
                />
            </Box> */}
        </Box>
    )
}