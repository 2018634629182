import React, { useContext } from 'react';
import ImgUpload from "../../../components/imgUpload";
import { CustomPaper, CustomTypography, CarouselSlider } from "../components";
import useTheme from '@mui/material/styles/useTheme';
import { DialogContext } from '../../../contexts';
import { FontFamilySwitch } from '../../../utils';

export const AssetsView = (props) => {

    const {
        data,
        t
    } = props;

    const dialog = useContext(DialogContext);


    const onClickImage = (val, index) => {
        dialog.setDialog({
            ...dialog,
            open: true,
            sx: {
                '& .MuiPaper-root': {
                    width: "500px",
                    '& .MuiDialogContent-root': {
                        padding: '20px 10px'
                    },
                    [theme.breakpoints.down('500')]: {
                        Width: "100%"
                    }
                }
            },
            body: (
                <div>
                    <CustomTypography marginBottom={"8px"} marginLeft={"34px"} fontFamily={FontFamilySwitch().bold} fontSize={18}>{t("Images")}</CustomTypography>
                    <CarouselSlider
                        data={data?.uploadedImages}
                        activeIndex={index}
                    />
                </div>
            )
        })
    }

    const theme = useTheme();

    return (
        <CustomPaper>
            {
                data?.uploadedImages?.length > 0 ? (<ImgUpload
                    accept={'image/*'}
                    selectedImageURL={data?.uploadedImages ?? []}
                    id={2}
                    readOnly={true}
                    onClickImage={onClickImage}
                />) : (
                    <CustomTypography
                        fontFamily={FontFamilySwitch().semiBold}
                        marginBottom={theme?.spacing(2)}
                        marginTop={theme?.spacing(2)}
                        color={theme?.typography?.color?.primary}
                        fontSize={16}
                    >
                        {t("No Images found")}
                    </CustomTypography>
                )
            }
        </CustomPaper>

    )
}