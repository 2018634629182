import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Divider from "@mui/material/Divider";
import Popover from '@mui/material/Popover';
import Stack from "@mui/material/Stack";
import styled from "@mui/material/styles/styled";
import React, { useState } from 'react';
import { BathIcon, BedBath, BedIcon, CustomTypography } from '.';
import { ToggleButtonComponent } from '../../../components';
import { FontFamilySwitch } from '../../../utils';

const Wrapper = styled(Stack)({
    border: '1px solid #E4E8EE',
    borderRadius: '4px',
    backgroundColor: '#fff',
    position: 'relative',
    // overflow: 'hidden',
    cursor: 'pointer',
    padding: '9px',
})

const Container = styled('div')({
    padding: 16,
    boxShadow: '0px 8px 24px #0717411F',
    backgroundColor: "#FFFFFF",
    borderRadius: 10
})

const Div = styled('div')({
    display: 'flex'
})

const CustomDivider = styled(Divider)({
    height: 15,
    alignSelf: 'center'
})

const IconWithValue = ({ icon, value }) => (
    <Stack
        direction={"row"}
        alignItems={"center"}
        gap={1}
    >
        {icon && icon}
        {value &&
            <CustomTypography
                fontSize={14}
                color={"#091B29"}
                fontFamily={FontFamilySwitch().semiBold}
            >
                {value}
            </CustomTypography>
        }
    </Stack>
)

const toggleButtonStyle = {
    height: '32px',
    borderColor: '#E4E8EE',
    borderRadius: '4px !important',
    paddingBlock: 0,
    flex: 1,
    backgroundColor: '#F2F4F7'
}

export const BedBathSelect = (props) => {

    const {
        label,
        bedOptions = [],
        bathOptions = [],
        value = null,
        onChange = null,
    } = props;

    const [anchorEl, setAnchorE1] = useState(null)

    const onChangeValue = (key, val) => {
        let updateValue = {
            ...value,
            [key]: val
        }
        onChange && onChange(updateValue)
    }


    return (
        <div>
            <Wrapper onClick={e => setAnchorE1(e.target)}>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                >
                    <div style={{ flex: 1, width: '80%' }}>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                        >
                            <BedBath width={15} height={15} />
                            {label &&
                                <CustomTypography
                                    fontFamily={FontFamilySwitch().semiBold}                                    
                                    fontSize={12}
                                    color={"#98A0AC"}
                                >
                                    {label}
                                </CustomTypography>
                            }
                        </Stack>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={2}
                            divider={<CustomDivider orientation="vertical" flexItem />}
                            marginTop={1 / 2}
                        >
                            <IconWithValue
                                icon={<BedIcon />}
                                value={value?.bed}
                            />
                            <IconWithValue
                                icon={<BathIcon />}
                                value={value?.bath}
                            />
                        </Stack>
                    </div>
                    <Div>
                        <ArrowDropDownIcon
                            style={{
                                fontSize: "26px",
                                color: "#98A0AC",
                                transform: anchorEl ? 'rotate(180deg)' : 'rotate(0)',
                                transition: '0.25s',
                            }} />
                    </Div>
                </Stack>
            </Wrapper>
            {
                anchorEl &&
                <Popover
                    open={true}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorE1(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Container>
                        <CustomTypography
                            fontSize={12}
                            color={"#98A0AC"}
                            gutterBottom
                        >
                            Bed
                        </CustomTypography>
                        <ToggleButtonComponent
                            options={bedOptions}
                            value={value?.bed}
                            onChange={(value) => onChangeValue('bed', value)}
                            buttonStyle={toggleButtonStyle}
                        />
                        <Divider sx={{ marginBlock: '10px' }} />
                        <CustomTypography
                            fontSize={12}
                            color={"#98A0AC"}
                            gutterBottom
                        >
                            Bath
                        </CustomTypography>
                        <ToggleButtonComponent
                            options={bathOptions}
                            value={value?.bath}
                            onChange={(value) => onChangeValue('bath', value)}
                            buttonStyle={toggleButtonStyle}
                        />
                    </Container>
                </Popover>
            }
        </div>
    )
}

