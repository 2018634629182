import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { Box, Divider, Stack, Typography, useMediaQuery } from "@mui/material";
import moment from "moment";
import React from "react";
import { CalendarAsset } from "../../../assets/calendarAsset";
import { InternalResourceIcon } from "../../../assets/internalresourceIcon";
import { SelectedResourceIcon } from "../../../assets/selectedResource";
import { SelectedVendorAsset } from "../../../assets/selectedVendor";
import { VendorAssetIcon } from "../../../assets/vendorAsset";
import { FormGenerator, SelectBox } from "../../../components";
import { getNextFrequency, useWindowDimensions } from "../../../utils";
// import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions"
import { addId } from "../../requestView/steps/utils";
import { useStyles } from "../style";
import { CriteriaList, frequencyOptions, loadOptionsApis } from "../utils";
import { addDays } from "date-fns";
import { CustomLoadOptionsApis } from "../../../components/assignModal/utils";

export const ExecutionDetails = ({
  state = {},
  t = () => false,
  data = {},
  updateState = () => false,
  isView = false,
}) => {
  const classes = useStyles();
  const size = useWindowDimensions();
  const matches = useMediaQuery("(min-width:900px)");
  const [resourceType, setResourceType] = React.useState("internal");
  const [isVendor, setIsVendor] = React.useState(true);
  React.useEffect(() => {
    if (data?.resource_type !== null && data?.resource_type?.length > 0) {
      setResourceType(data?.resource_type);
    }
    //eslint-disable-next-line
  }, []);
  const manualResponse = (array) => {
    const details = {
      data: array?.data?.map((i) => {
        return {
          ...i,
          label: i?.label,
          value: i?.value,
        };
      }),
      count: array?.count,
    };
    return details;
  };
  const handleUpdate = (k, v, detail) => {
    const result = data?.dynamic_schedule?.map((x) => {
      if (x?.auto_inc_id === detail?.auto_inc_id && k === "frequency") {
        let occurence = x?.occurence > 0 ? x?.occurence : 1;
        let end_date = getNextFrequency(
          v?.value,
          occurence - 1,
          x?.execution_start_date
        );
        return {
          ...x,
          [k]: v,
          execution_end_date: end_date,
        };
      } else if (x?.auto_inc_id === detail?.auto_inc_id && k === "occurence") {
        let occurence = v > 0 ? v : 1;
        let end_date = getNextFrequency(
          x?.frequency?.value,
          occurence - 1,
          x?.execution_start_date
        );
        return {
          ...x,
          [k]: v,
          execution_end_date: end_date,
        };
      } else if (
        x?.auto_inc_id === detail?.auto_inc_id &&
        k === "execution_start_date"
      ) {
        let occurence = x?.occurence > 0 ? x?.occurence : 1;
        let end_date = getNextFrequency(x?.frequency?.value, occurence - 1, v);
        return {
          ...x,
          [k]: v,
          execution_end_date: end_date,
        };
      } else {
        return x;
      }
    });
    updateState("dynamic_schedule", result);
  };
  const handleAddSchedule = () => {
    let schedule = addId([
      ...data?.dynamic_schedule,
      {
        execution_start_date: null,
        frequency: "",
        occurence: "",
        execution_end_date: "",
      },
    ]);
    updateState("dynamic_schedule", schedule);
  };
  const changeInput = (detail) => [
    {
      isActive: true,
      component: "date",
      label: t("Execution Start Date"),
      value:
        state?.planner_type === "Fixed"
          ? data?.execution_start_date
          : detail?.execution_start_date,
      placeholder: t("Enter Start Date"),
      onChange: (value) =>
        state?.planner_type === "Fixed"
          ? updateState("execution_start_date", value)
          : handleUpdate("execution_start_date", value, detail),
      error: data?.error?.execution_start_date,
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      minDate: addDays(new Date(), 1),
      isReadonly: isView,
      height: "40px",
      isRequired: true,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      isActive: true,
      component: "select",
      label: t("Frequency"),
      placeholder: t("Select Frequency"),
      value:
        state?.planner_type === "Fixed" ? data?.frequency : detail?.frequency,
      onChange: (val) =>
        state?.planner_type === "Fixed"
          ? updateState("frequency", val)
          : handleUpdate("frequency", val, detail),
      error: data?.error?.frequency,
      isRequired: true,
      options:
        state?.planner_type === "Fixed"
          ? frequencyOptions
          : frequencyOptions?.filter((x) => {
              return !data?.dynamic_schedule
                ?.map((x) => {
                  return x?.frequency?.value;
                })
                .includes(x?.value);
            }),
      debounceTimeout: 800,
      // isPaginate: true,
      menuPlacement: "bottom",
      selectHeight: "40px",
      isReadonly: isView,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      isActive: true,
      component: "text",
      label: t("Count(Occurences)"),
      placeholder: t("Enter Count"),
      value:
        state?.planner_type === "Fixed" ? data?.occurence : detail?.occurence,
      type: "number",
      onChange: (value) =>
        state?.planner_type === "Fixed"
          ? updateState("occurence", value.target.value)
          : handleUpdate("occurence", value?.target?.value, detail),
      error: data?.error?.occurence,
      isRequired: true,
      isReadonly: isView,
      height: "45px",
      padding: "10px 14px",
    },
    {
      isActive: true,
      isReadonly: true,
      component: "date",
      label: t("Execution End Date"),
      value:
        state?.planner_type === "Fixed"
          ? data?.execution_end_date
          : detail?.execution_end_date,
      placeholder: t("Enter End Date"),
      onChange: (value) =>
        state?.planner_type === "Fixed"
          ? updateState("execution_end_date", value)
          : handleUpdate("execution_end_date", value, detail),
      error: data?.error?.execution_end_date,
      height: "40px",
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
    },
  ];
  const form2 = [
    {
      size: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      isActive: true,
      component: "select",
      label: t("Resource Group"),
      placeholder: t("Select Resource Group"),
      value: data?.resource_group,
      onChange: (val) =>
        updateState("resource_group", val, "resource_type", resourceType),
      error: data?.error?.resource_group,
      isRequired: true,
      loadOptions: (search, array, handleLoading) =>
        CustomLoadOptionsApis(
          "resource_group_master/drop_down",
          {
            company_id: state?.company?.value,
          },
          search,
          array,
          handleLoading,
          "data",
          {},
          false
          // manualResponse,
          // { all: true }
        ),
      debounceTimeout: 800,
      isPaginate: true,
      selectHeight: "auto",
      isMulti: true,
      color: "white",
      menuPlacement: "top",
      isReadonly: isView,
    },
    // {
    //     size: {
    //         xs: 12,
    //         sm: 12,
    //         md: 12,
    //         lg: 12
    //     },
    //     isActive: true,
    //     component: "select",
    //     label: t("Profession and Skills"),
    //     placeholder: t("Select Profession and Skills"),
    //     value: data?.profession,
    //     onChange: (val) => updateState("profession", val),
    //     error: data?.error?.profession,
    //     isRequired: true,
    //     // options: [],
    //     debounceTimeout: 800,
    //     loadOptions: (search, array, handleLoading) =>
    //         loadOptionsApis(
    //             "queries/profession_master",
    //             {
    //                 "company_id": state?.company?.value,
    //             },
    //             search,
    //             array,
    //             handleLoading,
    //             "data",
    //             {},
    //             manualResponse
    //         ),
    //     isPaginate: true,
    //     selectHeight: "auto",
    //     color: "white",
    //     isMulti: true,
    //     menuPlacement: "top",
    //     isReadonly: isView
    // },
  ];
  const handleDelete = (val) => {
    const result = data?.dynamic_schedule?.filter((x) => {
      return x?.auto_inc_id !== val?.auto_inc_id;
    });
    updateState("dynamic_schedule", result);
  };
  const handleResourceType = (val) => {
    if (val === "internal") {
      // updateState("vendor_name","")
      setIsVendor(false);
    }
    setResourceType(val);
  };
  return (
    <Box>
      <Box
        sx={
          matches
            ? {
                borderRight: "1px solid #E4E8EE",
                padding: "16px 16px 0px 16px",
              }
            : { borderTop: "1px solid #E4E8EE", padding: "16px" }
        }
      >
        <Typography className={classes.planTitle}>
          {state?.planner_type === "Fixed"
            ? t("Execution Details")
            : t("Execution Criteria & Detail")}
        </Typography>
      </Box>
      <Box
        sx={{
          height: !matches
            ? "auto"
            : isView
            ? size?.height - 260
            : size?.height - 350,
          overflow: "scroll",
          borderRight: matches ? "1px solid #E4E8EE" : "none",
          padding: matches ? "0px 16px 0px 16px" : "16px",
          borderTop: !matches ? "1px solid #E4E8EE" : "none",
        }}
      >
        {state?.planner_type === "Dynamic" && (
          <>
            {!isView && (
              <Box mt={2}>
                {/* <Typography className={classes.planTitle}>{t("Criteria")}</Typography> */}
                <Box mt={1} mb={1} className={classes.dynamicriteriacard}>
                  {CriteriaList(t, "dynamic")?.map((val, index) => {
                    return (
                      <Box
                        className={
                          val?.is_selected ? classes.dynamicSelectedCard : ""
                        }
                      >
                        <Stack
                          direction={"row"}
                          spacing={1}
                          alignItems="center"
                          p={val?.is_selected ? 0 : 1.5}
                        >
                          {val?.is_selected ? (
                            <RadioButtonCheckedIcon sx={{ color: "#5078E1" }} />
                          ) : (
                            <RadioButtonCheckedIcon
                              sx={{ color: "#E4E8EE", cursor: "not-allowed" }}
                            />
                          )}
                          <Box>
                            <Typography className={classes.criteriaTitle}>
                              {val?.title}
                            </Typography>
                            <Typography className={classes.criteriaSubTitle}>
                              {val?.sub_title}
                            </Typography>
                          </Box>
                        </Stack>
                        {CriteriaList(t)?.length - 1 !== index &&
                          !val?.is_selected && (
                            <Box mt={1} mb={1}>
                              <Divider />
                            </Box>
                          )}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            )}
            <Box mt={1}>
              {data?.dynamic_schedule?.map((x, index) => {
                return (
                  <>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      mt={1}
                    >
                      <Typography className={classes.title}>
                        {t("REGULAR SCHEDULE")} {index + 1}
                      </Typography>
                      {!isView && (
                        <Box
                          onClick={() => handleDelete(x)}
                          sx={{ cursor: "pointer" }}
                        >
                          <Typography className={classes.deleteText}>
                            {t("Delete")}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Box className={classes.card} mt={1.5}>
                      <Box mt={1} className={classes.executionCard}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <CalendarAsset />
                          {x?.execution_start_date !== null &&
                          x?.frequency?.label?.length > 0 &&
                          x?.occurence !== "" ? (
                            <Typography className={classes.title}>{`Every ${
                              x?.frequency?.day_count ?? 0
                            } days for ${
                              x?.occurence !== "" ? x?.occurence : 1
                            } occurences starting from ${moment(
                              x?.execution_start_date ?? new Date()
                            ).format("DD MMM YYYY")} , Until ${moment(
                              x?.execution_end_date ?? new Date()
                            ).format("DD MMM YYYY")}`}</Typography>
                          ) : (
                            <Typography className={classes.title}>
                              {t(
                                "Fill the below details to view the number of occurrences"
                              )}
                            </Typography>
                          )}
                          {/* <Typography className={classes.title}>{`Every ${x?.frequency?.day_count ?? 0} days for ${x?.occurence !== "" ? x?.occurence : 1} occurences starting from ${moment(x?.execution_start_date ?? new Date()).format("DD MMM YYYY")} , Until ${moment(x?.execution_end_date ?? new Date()).format("DD MMM YYYY")}`}</Typography> */}
                        </Stack>
                      </Box>
                      <Box mt={2}>
                        <FormGenerator t={t} components={changeInput(x)} />
                      </Box>
                    </Box>
                  </>
                );
              })}
            </Box>
            {!isView && (
              <Box onClick={() => handleAddSchedule()} mt={1.5}>
                <Typography className={classes.addBillLine}>
                  {t("Add Another Schedule")}
                </Typography>
              </Box>
            )}
          </>
        )}
        {state?.planner_type === "Fixed" && (
          <>
            <Box mt={1} className={classes.executionCard}>
              <Stack direction="row" spacing={1} alignItems="center">
                <CalendarAsset />
                {data?.execution_start_date !== null &&
                data?.frequency?.label?.length > 0 &&
                data?.occurence !== "" ? (
                  <Typography className={classes.title}>{`Every ${
                    data?.frequency?.day_count ?? 0
                  } days for ${
                    data?.occurence !== "" ? data?.occurence : 1
                  } occurences starting from ${moment(
                    data?.execution_start_date ?? new Date()
                  ).format("DD MMM YYYY")} , Until ${moment(
                    data?.execution_end_date ?? new Date()
                  ).format("DD MMM YYYY")}`}</Typography>
                ) : (
                  <Typography className={classes.title}>
                    {t(
                      "Fill the below details to view the number of occurrences"
                    )}
                  </Typography>
                )}
              </Stack>
            </Box>
            <Box mt={2}>
              <FormGenerator t={t} components={changeInput()} />
            </Box>
            <Box mt={2}>
              <Typography className={classes.planTitle}>
                {t("Resource Details")}
              </Typography>
              <Box mt={1}>
                <Typography className={classes.title}>
                  {t("Resource Type")}
                </Typography>
              </Box>
              {isView ? (
                <Box mt={1} mb={2}>
                  {resourceType === "internal" && (
                    <Box
                      className={classes.viewResourceCard}
                      onClick={(e) => handleResourceType("internal")}
                    >
                      <Stack direction={"row"} spacing={1} alignItems="center">
                        {resourceType === "internal" ? (
                          <SelectedResourceIcon />
                        ) : (
                          <InternalResourceIcon />
                        )}
                        <Box>
                          <Typography className={classes.resourceTitle}>
                            {t("Internal Resource")}
                          </Typography>
                          <Typography className={classes.criteriaSubTitle}>
                            {t("Select below criteria for resource pooling")}
                          </Typography>
                        </Box>
                      </Stack>
                      {resourceType === "internal" && (
                        <Box>
                          <FormGenerator t={t} components={form2} />
                        </Box>
                      )}
                    </Box>
                  )}
                  {resourceType === "vendor" && (
                    <Box
                      className={classes.viewResourceCard}
                      onClick={() => handleResourceType("vendor")}
                    >
                      <Stack direction={"row"} spacing={1} alignItems="center">
                        {resourceType === "vendor" ? (
                          <SelectedVendorAsset />
                        ) : (
                          <VendorAssetIcon />
                        )}
                        <Box>
                          <Typography className={classes.resourceTitle}>
                            {t("Vendor")}
                          </Typography>
                          <Typography className={classes.criteriaSubTitle}>
                            {t("Work jobs will be to external vendor")}
                          </Typography>
                        </Box>
                      </Stack>
                      {resourceType === "vendor" && (
                        <>
                          <Box mt={1}>
                            <SelectBox
                              label={t("Vendor Name")}
                              placeholder={t("Choose Vendor")}
                              onChange={(val) => {
                                return (
                                  updateState("vendor_name", val),
                                  setIsVendor(true)
                                );
                              }}
                              loadOptions={(search, array, handleLoading) =>
                                loadOptionsApis(
                                  "queries/vendor_master",
                                  {
                                    company_id: state?.company?.value,
                                  },
                                  search,
                                  array,
                                  handleLoading,
                                  "data",
                                  {},
                                  manualResponse
                                )
                              }
                              value={data?.vendor_name ?? ""}
                              isPaginate={true}
                              isError={data?.error?.vendor_name?.length > 0}
                              errorMessage={data?.error?.vendor_name}
                              menuPlacement={"bottom"}
                              isRequired
                              color="white"
                              selectHeight={"35px"}
                              isReadonly={true}
                              isDisabled
                            />
                          </Box>
                          <Box mt={1}>
                            <FormGenerator t={t} components={form2} />
                          </Box>
                        </>
                      )}
                    </Box>
                  )}
                </Box>
              ) : (
                <Box mt={1} className={classes.resourceCard}>
                  <Box
                    className={
                      resourceType === "internal"
                        ? classes.selectedCard
                        : classes.vendorCard
                    }
                    onClick={() => handleResourceType("internal")}
                  >
                    <Stack direction={"row"} spacing={2} alignItems="center">
                      {resourceType === "internal" ? (
                        <SelectedResourceIcon />
                      ) : (
                        <InternalResourceIcon />
                      )}
                      <Box>
                        <Typography className={classes.resourceTitle}>
                          {t("Internal Resource")}
                        </Typography>
                        <Typography className={classes.criteriaSubTitle}>
                          {t("Select below criteria for resource pooling")}
                        </Typography>
                      </Box>
                    </Stack>
                    {resourceType === "internal" && (
                      <>
                        <FormGenerator t={t} components={form2} />
                      </>
                    )}
                  </Box>
                  <Box
                    className={
                      resourceType === "vendor"
                        ? classes.selectedCard
                        : classes.vendorCard
                    }
                    onClick={() => handleResourceType("vendor")}
                  >
                    <Stack direction={"row"} spacing={2} alignItems="center">
                      {resourceType === "vendor" ? (
                        <SelectedVendorAsset />
                      ) : (
                        <VendorAssetIcon />
                      )}
                      <Box>
                        <Typography className={classes.resourceTitle}>
                          {t("Vendor")}
                        </Typography>
                        <Typography className={classes.criteriaSubTitle}>
                          {t("Work jobs will be to external vendor")}
                        </Typography>
                      </Box>
                    </Stack>
                    {resourceType === "vendor" && (
                      <>
                        <Box mt={1}>
                          <SelectBox
                            label={t("Vendor Name")}
                            placeholder={t("Choose Vendor")}
                            onChange={(val) => {
                              return (
                                updateState(
                                  "vendor_name",
                                  val,
                                  "resource_type",
                                  resourceType
                                ),
                                setIsVendor(true)
                              );
                            }}
                            loadOptions={(search, array, handleLoading) =>
                              loadOptionsApis(
                                "queries/vendor_master",
                                {
                                  company_id: state?.company?.value,
                                },
                                search,
                                array,
                                handleLoading,
                                "data",
                                {},
                                manualResponse
                              )
                            }
                            value={
                              (isVendor === true ? data?.vendor_name : "") ?? ""
                            }
                            isPaginate={true}
                            isError={data?.error?.vendor_name?.length > 0}
                            errorMessage={data?.error?.vendor_name}
                            menuPlacement={"bottom"}
                            isRequired
                            color="white"
                            selectHeight={"35px"}
                          />
                        </Box>
                        <Box mt={1}>
                          <FormGenerator t={t} components={form2} />
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
