import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { StepCardStyle } from './style'

const StepCard = (
    {
        t = () => false,
        step = "",
        content = "",
        btntext = "",
        click = () => false,
        svgIcon = {},
        invoiceData = {},
        stepNumber = "",
        step3 = ""
    }) => {
    const classes = StepCardStyle()
    return (
        <Box className={!invoiceData?.step2 && stepNumber === "3" ? classes.disabledBox : classes.box} onClick={click}>
            <Stack spacing={2} alignItems={"center"} p={4}>
                <Box className={!invoiceData?.step2 && stepNumber === "3" ? classes.disabledStep : classes.step}>
                    <Typography className={classes.stepText}>{step}</Typography>
                </Box>
                {svgIcon}
                <Typography className={!invoiceData?.step2 && stepNumber === "3" ? classes.disabledContent : classes.content}> {content} </Typography>
                <Box className={!invoiceData?.step2 && stepNumber === "3" ? classes.disabledCustomBtn : classes.customBtn}>
                    <Typography className={!invoiceData?.step2 && stepNumber === "3" ? classes.disabledCustomBtnText : classes.customBtnText}> {btntext} </Typography>
                </Box>
                {stepNumber === "3" && <Typography className={classes.step3Text}>{step3}</Typography>}
            </Stack>
        </Box>
    )
}

export default withTranslation("freeTextInvoice")(StepCard)