import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  Button,
  Tooltip,
} from "@mui/material";
import { CustomTypography } from "../../companyCreation/components";
import CloseIcon from "../../../assets/closeIcon";
import { FontFamilySwitch } from "../../../utils";
import { useStylesDrawerView } from "./style";
import { LoadingSection } from "../../../components";
import React, { useState } from "react";
import { CreateExternalUnitContext } from "../externalUnitContext";
import MyGoogleMap from "../../../components/interactivemap/myGoogleMaps";
import { AssetView } from "./assetViewDrawer";

export const UnitDetailsDrawer = ({
  t,
  unitDetails,
  closeDrawer,
  currency,
}) => {
  const { dataLoading, formatPrice } = React.useContext(
    CreateExternalUnitContext
  );
  const classes = useStylesDrawerView();
  const [expanded, setExpanded] = useState(false);
  const maxLength = 50;
  const notes = unitDetails?.[0]?.notes || "";
  const isLongText = notes.length > maxLength;

  const unitDetailscard = [
    {
      assets: "/images/propertyType.png",
      header: "Property Type",
      subHeader: unitDetails?.[0]?.propertyType?.group_name,
    },
    {
      assets: "/images/unitCategory.png",
      header: "Unit Category",
      subHeader: unitDetails?.[0]?.purpose,
    },
    {
      assets: "/images/unitType.png",
      header: "Unit Type",
      subHeader: unitDetails?.[0]?.unitType?.name,
    },
    {
      assets: "/images/squareFeet.png",
      header: unitDetails?.[0]?.uom?.name,
      subHeader: unitDetails?.[0]?.total_area,
    },
    {
      assets: "/images/bed.png",
      header: "Bed",
      subHeader: unitDetails?.[0]?.bed,
    },
    {
      assets: "/images/bath.svg",
      header: "Bath",
      subHeader: unitDetails?.[0]?.bath,
    },
  ];

  const getAvatarDetails = (price) => {
    switch (true) {
      case price.is_primary:
        return { img: "/images/primaryCharges.svg", text: "Primary" };
      case price.is_secondary:
        return { img: "/images/secondaryCharges.svg", text: "Secondary" };
      case price.is_onetime:
        return { img: "/images/onetimeCharges.svg", text: "One-time" };
      case price.is_refundable:
        return { img: "/images/refundableCharges.svg", text: "Refundable" };
      default:
        return { img: "/images/otherCharges.svg", text: "Other" };
    }
  };

  const latitude = Number(unitDetails?.[0]?.latitude) || 0;
  const longitude = Number(unitDetails?.[0]?.longitude) || 0;

  const isValidLatitude = latitude >= -90 && latitude <= 90;
  const isValidLongitude = longitude >= -180 && longitude <= 180;

  return (
    <Box
      className={classes.formRoot}
      sx={{ display: "flex", flexDirection: "column", height: "100vh" }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "white",
          zIndex: 10,
        }}
      >
        <Stack px={2} pt={2} direction="row" alignItems="center" gap={2}>
          <IconButton onClick={closeDrawer} sx={{ padding: 0 }}>
            <CloseIcon htmlColor="#7C8594" />
          </IconButton>
          <CustomTypography
            fontSize={"1.125rem"}
            fontFamily={FontFamilySwitch().bold}
          >
            {t("View Unit")}
          </CustomTypography>
        </Stack>
        <Divider className={classes.divider} />
        <Typography className={classes.header}>{t("Unit Details")}</Typography>
        <Divider className={classes.divider2} />
      </Box>
      <Box sx={{ flexGrow: 1, overflowY: "auto", padding: "16px" }}>
        {dataLoading ? (
          <LoadingSection />
        ) : (
          <>
            <Box className={classes.whiteBox}>
              <Grid spacing={2} container>
                <Grid item lg={8} xs={8}>
                  {unitDetails?.[0]?.assets?.length > 0 ? (
                    <AssetView
                      title={t("Unit Image")}
                      isNormal={true}
                      asset={unitDetails?.[0]?.assets}
                      assetAll={unitDetails?.[0]?.assets}
                    />
                  ) : (
                    <Box
                      sx={{
                        backgroundColor: "#e3e3e3",
                        margin: 0,
                        padding: 0,
                        textAlign: "center",
                        borderRadius: 1,
                      }}
                    >
                      <img
                        src="/images/propertytumb.png"
                        style={{
                          height: "180px",
                          borderRadius: "4px",
                          margin: "0 auto",
                        }}
                      />
                    </Box>
                  )}
                  <Stack item xs={12} direction={"row"} spacing={2}>
                    <Grid item xs={12}>
                      <Stack direction={"row"} spacing={1} mt={2} mb={"4px"}>
                        <Typography className={classes.unitName}>
                          {unitDetails?.[0]?.name}
                        </Typography>
                        <Box className={classes.availability}>
                          {t("Available")}
                        </Box>
                      </Stack>
                      <Typography className={classes.propertyName}>
                        {unitDetails?.[0]?.property_name}
                      </Typography>
                      <Typography mt={"4px"} mb={1} className={classes.notes}>
                        {expanded || !isLongText
                          ? notes
                          : `${notes.substring(0, maxLength)}...`}
                      </Typography>
                      {isLongText && (
                        <Button
                          className={classes.button}
                          size="small"
                          onClick={() => setExpanded(!expanded)}
                        >
                          {expanded ? t("View Less") : t("View More")}
                        </Button>
                      )}
                    </Grid>
                    <Box>
                      {/* Company card */}
                      <Box className={classes.companyCard} my={2}>
                        <Stack
                          direction={"row"}
                          spacing={"4px"}
                          alignItems={"center"}
                        >
                          <img
                            src={unitDetails?.[0]?.company?.logo}
                            className={classes.assets1}
                          />
                          <Typography className={classes.designation}>
                            {t(unitDetails?.[0]?.company?.name)}
                          </Typography>
                        </Stack>
                      </Box>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item lg={4} xs={4}>
                  <Box
                    sx={{
                      backgroundColor: "#F2F4F7",
                      padding: "8px",
                      borderRadius: "6px",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "6px",
                        padding: "12px",
                      }}
                    >
                      <Typography className={classes.salePerson}>
                        {t("AGENT / SALES PERSON")}
                      </Typography>
                      <Stack
                        mt={1}
                        direction="row"
                        alignItems="center"
                        spacing={2}
                      >
                        <Avatar
                          className={classes.assets}
                          src={unitDetails?.[0]?.vendor?.[0]?.image_url}
                        />
                        <Stack direction={"column"}>
                          <Typography className={classes.name}>
                            {unitDetails?.[0]?.vendor?.[0]?.name}
                          </Typography>
                          <Typography className={classes.designation}>
                            {t("Vendor")}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Box>
                    <Box
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "6px",
                        padding: "12px",
                        marginTop: "8px",
                      }}
                    >
                      <Typography className={classes.salePerson}>
                        {t("MLS / LISTING DETAIL")}
                      </Typography>
                      <Tooltip
                        title={
                          unitDetails?.[0]?.listings?.portal_provider
                            ? unitDetails?.[0]?.listings?.portal_provider
                            : "-"
                        }
                        arrow
                        placement="top"
                      >
                        <Typography mb={1} className={classes.salesText}>
                          {unitDetails?.[0]?.listings?.portal_provider
                            ? unitDetails?.[0]?.listings?.portal_provider
                            : "-"}
                        </Typography>
                      </Tooltip>
                      <Typography className={classes.salePerson}>
                        {t("MLS / LISTING DETAIL")}
                      </Typography>
                      <Tooltip
                        title={
                          unitDetails?.[0]?.listings?.reference_id
                            ? unitDetails?.[0]?.listings?.reference_id
                            : "-"
                        }
                        arrow
                        placement="top"
                      >
                        <Typography className={classes.salesText}>
                          {unitDetails?.[0]?.listings?.reference_id
                            ? unitDetails?.[0]?.listings?.reference_id
                            : "-"}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <Box className={classes.detailsCardContainer}>
                {unitDetailscard.map((card, index) => (
                  <Box mb={"4px"} className={classes.detailsCard}>
                    <Stack direction={"row"} spacing={1}>
                      <Avatar src={card?.assets} className={classes.assets} />
                      <Stack direction={"column"}>
                        <Typography className={classes.designation}>
                          {t(card?.header)}
                        </Typography>
                        <Typography className={classes.name}>
                          {card.subHeader}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                ))}
              </Box>
              <Divider sx={{ marginTop: "8px", backgroundColor: "#d4dbe4" }} />
              <Typography mt={"12px"} mb={"12px"} className={classes.name}>
                {t("Pricing")}
              </Typography>
              <Box className={classes.detailsCardContainer}>
                {unitDetails?.[0]?.pricing?.map((price, index) => {
                  const { img, text } = getAvatarDetails(price);
                  return (
                    <Box key={index} mr={2} className={classes.pricingBox}>
                      <img src={img} alt={text} />
                      <Typography mt={2} mb={1} className={classes.name}>
                        {formatPrice(price.value)} {currency}
                      </Typography>
                      <Typography className={classes.designation}>
                        {t(text)}
                      </Typography>
                      <Typography className={classes.designation}>
                        {t("Charges")}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
              <Divider className={classes.divider} />
              <Typography my={2} className={classes.name}>
                {t("Address")}
              </Typography>

              <Grid container spacing={2} height={"180px"}>
                <Grid item xs={4}>
                  {isValidLatitude && isValidLongitude ? (
                    <MyGoogleMap
                      lat={latitude}
                      lng={longitude}
                      center={{ lat: latitude, lng: longitude }}
                      zoom={13}
                      readonly
                    />
                  ) : (
                    <Typography
                      sx={{
                        fontFamily: FontFamilySwitch().bold,
                        textAlign: "center",
                      }}
                    >
                      Invalid latitude or longitude values.
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={8} mt={3}>
                  <Stack direction={"column"} spacing={2}>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <img src="/images/Location.svg" alt="location" />
                      <Typography className={classes.addressDetails}>
                        {unitDetails?.[0]?.address
                          ? unitDetails?.[0]?.address
                          : "-"}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <img src="/images/Email.svg" alt="Email" />
                      <Typography className={classes.addressDetails}>
                        {unitDetails?.[0]?.vendor?.[0]?.address?.email_id
                          ? unitDetails?.[0]?.vendor?.[0]?.address?.email_id
                          : "-"}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <img src="/images/Web.svg" alt="Web" />
                      <Typography className={classes.addressDetails}>
                        {unitDetails?.[0]?.listings?.portal_url
                          ? unitDetails?.[0]?.listings?.portal_url
                          : "-"}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
