import { Badge, Box, Grid, IconButton, Stack } from "@mui/material"
import React from "react"
import { withTranslation } from "react-i18next"
import { FilterGenerator, LoadingSection, SearchFilter, Subheader, TableWithPagination } from "../../components"
import { ExecutedWorkOrdersViewDataType, ExecutedWorkOrdersViewHeading, ExecutedWorkOrdersViewPath, NetWorkCallMethods, accessCheckRender, getCompanyOption, getRoutePermissionNew } from "../../utils"
import { useStyles } from "./style"
import { NetworkCall } from "../../networkcall"
import moment from "moment"
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions"
import { config } from "../../config"
import { AlertContext, AuthContext, BackdropContext } from "../../contexts"

const ExecutedJobsView = ({ t = () => false }) => {
    const auth = React.useContext(AuthContext)
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)
    const classes = useStyles()
    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [searchText, setSearchText] = React.useState("")
    const [tableData, setTableData] = React.useState({
        list: [],
        count: 0
    })
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [permission, setPermission] = React.useState({})
    const [drawer, setDrawer] = React.useState(false)
    const [filterData, setFilterData] = React.useState({
        unit: [],
        resource: [],
        status: []
    })
    const getList = (company_id, offset = 0, limit = 10, search = "", filterData = {}) => {
        setLoading(true)
        const payload = {
            company_id: company_id,
            offset: offset,
            limit: limit,
            search: search,
            unit: filterData?.unit?.map((x) => { return x?.value }) ?? [],
            is_runned: filterData?.status?.map((x) => { return x?.value }) ?? [],
            "resource": filterData?.resource?.map((x) => { return x?.value }) ?? [],
        }

        NetworkCall(
            `${config.api_url}/preventive_plan/work_job_list`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            const result = response?.data?.data?.map((x) => {
                return {
                    ...x,
                    execution: moment(x?.execution)?.format("DD MMM YYYY"),
                    status: x?.is_runned ? "Assigned" : "Pending"
                }
            })
            setTableData({ list: result, count: response?.data?.count })
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
        })
    }
    React.useEffect(() => {
        let company = getCompanyOption(backdrop, auth, alert)
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (company && perm?.read) {
                setCompanyList(company?.list)
                setSelectedCompany(company?.selected)
                getList(company?.selected?.value, 0, 10, "", filterData)
            }
        }
        //eslint-disable-next-line
    }, [auth])
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        getList(value?.value, 0, 10, "", filterData)
    }
    const handleSearch = (value) => {
        setSearchText(value)
        getList(selectedCompany?.value, 0, 10, value, filterData)
    }
    const onApplyFilter = (value) => {
        setFilterData(value)
        getList(selectedCompany?.value, 0, 10, "", value)
    }
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getList(selectedCompany?.value, offset, 10, "", filterData)
    }
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getList(selectedCompany?.value, 0, value, "", filterData)
    }
    const openDrawer = () => {
        setDrawer(true)
    }
    const manualResponse = (array) => {
        const details = array?.map((i) => {
            return {
                ...i,
                label: i?.name,
                value: i?.id
            }
        })
        return details
    };
    const render = () => {
        return (
            <Box>
                <Subheader
                    title={t("Executed Work Orders")}
                    hideBackButton={true}
                    select
                    options={companyList}
                    value={selectedCompany}
                    onchange={(e) => {
                        handleCompanyChange(e)
                    }}
                />
                {loading ?
                    <LoadingSection top="20vh" message={t("Fetching Details")} />
                    :
                    <Box className={classes.root}>
                        <Grid container justifyContent="space-between">
                            <Grid item xs={4}>
                                <Box>
                                    {/*search */}
                                    <SearchFilter label={false} placeholder={t("Search")} handleChange={(value) => handleSearch(value)} value={searchText} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
                                </Box>
                            </Grid>

                            <Grid item xs={8} display={"flex"} justifyContent={"end"}>
                                <Box display={"flex"} alignItems="center" sx={{ float: "right" }}>
                                    <Stack
                                        direction="row"
                                        spacing={0.6}
                                        display={"flex"}
                                        alignItems="center"
                                    >
                                        <Box>
                                            <IconButton
                                                size="small"
                                                className={classes.img}
                                                onClick={openDrawer}
                                            >
                                                <Badge variant="dot" color="primary"
                                                    invisible={!Boolean(filterData.resource?.length > 0) && !Boolean(filterData.status?.length > 0) && !Boolean(filterData.unit?.length > 0)}>
                                                    <img src="/images/filter.svg" alt="filter" />
                                                </Badge>
                                            </IconButton>
                                        </Box>
                                    </Stack>
                                    <Box width={"15px"} />
                                </Box>
                            </Grid>
                        </Grid>

                        <TableWithPagination
                            heading={ExecutedWorkOrdersViewHeading(t)}
                            rows={tableData?.list}
                            dataType={ExecutedWorkOrdersViewDataType}
                            path={ExecutedWorkOrdersViewPath}
                            showpagination
                            tableType="no-side"
                            // handleIcon={handleIcon}
                            handlePagination={handlePagination}
                            handleChangeLimit={handleChangeLimit}
                            totalRowsCount={tableData?.count}
                            page={page}
                            limit={limit}
                            view={permission?.read && true}
                            height={`calc(100vh - 300px)`}
                            edit={true}
                            delete={true} />
                    </Box>
                }

                {/* filter */}
                {drawer && (
                    <FilterGenerator
                        open={drawer}
                        onClose={() => setDrawer(false)}
                        components={[
                            {
                                component: "select",
                                value: filterData?.resource,
                                label: t("Resource"),
                                placeholder: t("Resource"),
                                state_name: "resource",
                                loadOptions: (search, array, handleLoading) =>
                                    loadOptionsApis(
                                        "/resources/getAll",
                                        {
                                            "company_id": selectedCompany?.value,
                                            "active": [true]
                                        },
                                        search,
                                        array,
                                        handleLoading,
                                        "result",
                                        {},
                                        manualResponse
                                    ),
                                isPaginate: true,
                                isMulti: true
                            },
                            {
                                component: "select",
                                value: filterData?.unit,
                                isMulti: true,
                                label: t("Unit"),
                                placeholder: t("Unit"),
                                state_name: "unit",
                                options: [],
                                isPaginate: true,
                            },
                            // {
                            //     component: "select",
                            //     value: filterData?.status,
                            //     isMulti: true,
                            //     label: t("Status"),
                            //     placeholder: t("Status"),
                            //     state_name: "status",
                            //     options: [],
                            // },
                        ]}
                        onApply={(value) => onApplyFilter(value)}
                    />
                )}
            </Box>
        )
    }
    return (
        <>
            {accessCheckRender(render, permission)}
        </>
    );
}
export default withTranslation("maintenancePlanner")(ExecutedJobsView)