import { Box, Grid } from "@mui/material";
import moment from "moment";
import React from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  FilterGenerator,
  SearchFilter,
  Subheader,
  TableWithPagination,
  UseDebounce,
} from "../../components";
import { NewLoader } from "../../components/newLoader";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import {
  accessCheckRender,
  AlertProps,
  getCompanyOption,
  getRoutePermissionNew,
  NetWorkCallMethods,
} from "../../utils";
import {
  KycDetailsTableHeading,
  KycDetailsTablePath,
  KycDetailsTableDataType,
  Mandatory,
} from "../../utils/kycDetails";
import { KycDetailsStyle } from "./style";

const KycDetails = (props) => {
  const { loading, handleLoading, t } = props;
  const Heading = KycDetailsTableHeading(t);
  const debounce = UseDebounce();
  const classes = KycDetailsStyle();
  const navigate = useNavigate();
  const auth = React.useContext(AuthContext);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);

  //company
  const [isloading, setLoading] = React.useState(false);
  const [companyList, setCompanyList] = React.useState([]);
  const [permission, setPermission] = React.useState({});
  const [selectedCompany, setSelectedCompany] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const [filterData, setFilterData] = React.useState({ status: [true] });
  const [fiterDrawer, setFilterDrawer] = React.useState(false);
  const [data, setData] = React.useState({
    list: [],
    count: "",
  });

  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");
  const limitParam = searchParams.get("limit");

  // Default values if not set in URL
  const pageFromParams = pageParam ? parseInt(pageParam, 10) : 1;
  const limitFromParams = limitParam ? parseInt(limitParam, 10) : 10;

  const [page, setPage] = React.useState(pageFromParams);
  const [limit, setLimit] = React.useState(limitFromParams);

  const updatePage = (newPage, newLimit = limit) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", newPage.toString());
    searchParams.set("limit", newLimit.toString());

    // Update URL without reloading
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });

    // Update state
    setPage(newPage);
    setLimit(newLimit);
  };

  ///handle pagination
  const handlePagination = (newPage) => {
    updatePage(newPage, limit);
  };

  const handleChangeLimit = (newLimit) => {
    updatePage(1, newLimit); // Reset page to 1 when limit changes
  };
  const currentOffset = (page - 1) * limit;
  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          setCompanyList(company?.list);
          setSelectedCompany(company?.selected);
          if (auth) {
            getKydDetail(currentOffset, limit, "", company?.selected?.value);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  // Go Back
  const goBack = () => {
    navigate(-1);
  };

  // Function to change the company
  const handleCompanyChange = (value) => {
    setLoading(true);
    setSelectedCompany(value);
    getKydDetail(0, 10, "", value?.value);
  };

  // Function to change search text
  const handleSearchTextChange = (e) => {
    setSearchText(e);
    debounce(() => searchTableFunction(e), 800);
  };

  const getKydDetail = (offset = 0, limit = 10, search = "", companyId) => {
    setLoading(true);
    const payload = {
      offset: offset,
      limit: limit,
      search: search,
      company_id: companyId,
    };

    NetworkCall(
      `${config.api_url}/kyc_approval_requests`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        const result = response?.data?.data?.map((e) => {
          return {
            booking_id: e?.booking_id,
            agreement_no: e?.agreement_no,
            requested_by: e?.created_by_first_name,
            requested_on: moment(e?.created_at).format("YYYY-MM-DD"),
            contact_id: e?.contact_account_name,
            name: "-",
            mobile: `${e?.mobile_no_country_code} ${e?.mobile_no}`,
            email: e?.email_id,
            no_of_proof: e?.no_of_proof,
            no_of_approved_count: e?.no_of_approved_count,
            status: e?.status,
            date_closed:
              (e?.status !== "pending" || e?.status !== "Pending") &&
              moment(e?.updated_at).format("YYYY-MM-DD"),
            ...e,
          };
        });

        setData({ ...data, list: result, count: response?.data?.count });
        setLoading(false);
        handleLoading(false);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("somethingWentWrong"),
        });
        setLoading(false);
      });
  };

  // Function to search data in amenity booking
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getKydDetail(0, limit, e, selectedCompany?.value);
  };

  const handleTableIcon = (type, data) => {
    if (type === "view") {
      navigate(Routes.kycDetailView, {
        state: { data: data, company: selectedCompany },
      });
    }
  };

  const handleFilter = (value) => {
    setFilterData(value);
  };

  const render = () => {
    return (
      <Box>
        <Subheader
          hideBackButton={false}
          title={t("KYC Screening Requests")}
          select
          options={companyList}
          value={selectedCompany}
          goBack={goBack}
          onchange={(e) => {
            handleCompanyChange(e);
          }}
        />

        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <SearchFilter
                placeholder={t("Search")}
                value={searchText}
                handleChange={(value) => handleSearchTextChange(value)}
                customfieldSx={{ height: "40px" }}
              />
            </Grid>
            <Grid item xs={8}>
              <Box display={"flex"} justifyContent={"end"}>
                {/* <Stack direction="row" spacing={2}
                                    divider={<Divider orientation="vertical" flexItem />}>
                                    <IconButton onClick={() => setFilterDrawer(!fiterDrawer)}
                                        className={classes.filterButton}>
                                        <Badge variant="dot" color="primary"
                                            invisible={!(filterData.is_mandatory)}>
                                            <FilterIMG color="#091b29" />
                                        </Badge>
                                    </IconButton>
                                </Stack> */}
              </Box>
            </Grid>
          </Grid>
          {isloading ? (
            <NewLoader minusHeight="500px" />
          ) : (
            <TableWithPagination
              heading={Heading}
              rows={data?.list}
              path={KycDetailsTablePath}
              showpagination={true}
              showpdfbtn={false}
              showexcelbtn={false}
              showSearch={false}
              handleIcon={handleTableIcon}
              onClick={() => false}
              tableType="no-side"
              dataType={KycDetailsTableDataType}
              handlePagination={handlePagination}
              handleChangeLimit={handleChangeLimit}
              totalRowsCount={data?.count}
              page={page}
              limit={limit}
              height={"calc(100vh - 285px)"}
              view={permission?.view}
              edit={permission?.edit}
              delete={permission?.delete}
            />
          )}
        </div>

        <FilterGenerator
          open={fiterDrawer}
          onClose={() => setFilterDrawer(false)}
          onApply={(value) => handleFilter(value)}
          components={[
            {
              component: "toggleButton",
              value: filterData?.is_mandatory,
              options: Mandatory(t),
              isMulti: false,
              state_name: "is_mandatory",
              label: t("Mandatory"),
            },
          ]}
        />
      </Box>
    );
  };

  return <div>{accessCheckRender(render, permission, "", loading)}</div>;
};

export default withTranslation("kycDetails")(KycDetails);
