import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, Grid, IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import React from "react";
import { CompanySettingsList, SelectBox, TextBox } from "../../../components";
import { config } from '../../../config';
import { AlertContext } from '../../../contexts';
import { NetworkCall } from '../../../networkcall';
import { AlertProps, billGenENUM, enum_types, enumSelect, NetWorkCallMethods } from "../../../utils";
import { loadOptionsApis } from '../../../utils/asyncPaginateLoadOptions';
import { quotation_validity, validateCustom } from "../../../utils/companySettings";
import { useStyles } from './styles';

export const BasicSettings = ({
    data = {},
    updateState = () => false,
    loadOptionData = () => false,
    loading = false,
    t,
    updateState1 = () => false,
    COMPANY_ID = "",
    setSettings = () => false
}) => {
    const [toggleView, setToggleView] = React.useState([]);
    const alert = React.useContext(AlertContext)
    const classes = useStyles()
    const [UOMValue, setUOMValue] = React.useState()
    const [enumValue, setEnumValue] = React.useState({
        uom: [],
        company_payment_period: [],
        payment_terms: [],
        billing_cycle_date: [],
        bill_gen: [],
        payment_gateway: [],
        company_lease_period: [],
        inspection_item_type: []
    })
    //get enum
    const getEnum = async () => {
        const result = await enumSelect([enum_types.area_metric_type, enum_types.company_payment_period, enum_types.payment_terms, enum_types.billing_cycle_date, enum_types.billing_cycle, enum_types.company_payment_gateway, enum_types.company_lease_period, enum_types.inspection_item_type])
        const a = result?.payment_terms.sort((a, b) => b.label.localeCompare(a.label))?.filter((x) => x?.value !== "User Defined");

        setEnumValue({
            // uom: result?.area_metric_type,
            company_payment_period: result?.company_payment_period,
            payment_terms: a,
            billing_cycle_date: result?.billing_cycle_date?.filter(i => i?.value !== billGenENUM.last_day).filter(i => i?.value !== billGenENUM.mid_date).filter(i => i?.value !== billGenENUM.first).reverse(),
            bill_gen: result?.billing_cycle,
            payment_gateway: result?.company_payment_gateway,
            company_lease_period: result?.company_lease_period,
            inspection_item_type: result?.inspection_item_type?.filter((e) => e?.value !== "Product" && e?.value !== "Inspection")
        })
    }
    const onChangeToggleView = (index) => {
        if (toggleView.includes(index)) {
            setToggleView(toggleView?.filter(_ => _ !== index));
            return
        }
        setToggleView([...toggleView, index])
    }

    //uom api call
    const getUOMMaster = async () => {
        const payload = {};
        NetworkCall(
            `${config.api_url}/queries/uom_master/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((response) => {
                setUOMValue(response?.data?.data?.unit_type_master)

            }).catch((error) => {

                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),
                });
            });
    }

    const itemMasterManualResponse = (array) => {
        const details = array?.map((i) => {
            return {
                label: i?.name,
                value: i?.id,
                ...i
            }
        })
        return details
    };

    //initial load
    React.useEffect(() => {
        getEnum()
        getUOMMaster()
        // eslint-disable-next-line
    }, [])
    const renderGatway = () => {
        return (

            data?.default_payment_gateway?.value === "Solution Provider" &&
            <Grid container spacing={1} marginTop="8px">

                <Grid item xs={12} sm={6} md={3}>
                    <TextBox
                        isrequired
                        autocomplete={"new-name"}
                        label={t("Login name")}
                        placeholder={t("Enter name")}
                        value={data?.loginName ?? ''}
                        onChange={(e) => updateState('loginName', e.target.value)}
                        isError={data?.error?.error?.loginName?.length > 0}
                        errorMessage={data?.error?.loginName}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextBox
                        // {...textBoxStyle}
                        isrequired
                        autocomplete={"new-password"}
                        label={t("Login password")}
                        type={toggleView?.includes('1') ? "text" : "password"}
                        placeholder={t("Enter password")}
                        value={data?.loginPassword ?? ''}
                        endAdornment={(
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => onChangeToggleView('1')}
                                    edge="end"
                                >
                                    {toggleView?.includes('1') ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )}
                        isError={data?.error?.loginPassword?.length > 0}
                        errorMessage={data?.error?.loginPassword}
                        onChange={(e) => updateState('loginPassword', e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextBox
                        // {...textBoxStyle}
                        isrequired
                        label={t("Login token")}
                        type={toggleView?.includes('2') ? "text" : "password"}
                        placeholder={t("Enter login token")}
                        value={data?.loginToken ?? ''}
                        endAdornment={(
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => onChangeToggleView('2')}
                                    edge="end"
                                >
                                    {toggleView?.includes('2') ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )}
                        isError={data?.error?.loginToken?.length > 0}
                        errorMessage={data?.loginToken}
                        onChange={(e) => updateState('loginToken', e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextBox
                        // {...textBoxStyle}
                        isrequired
                        label={t("Payment Provider Name")}
                        placeholder={t("Enter Payment Provider Name")}
                        value={data?.paymentProviderName ?? ''}
                        onChange={(e) => updateState('paymentProviderName', e.target.value)}
                        isError={data?.error?.error?.paymentProviderName?.length > 0}
                        errorMessage={data?.error?.paymentProviderName}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextBox
                        // {...textBoxStyle}
                        isrequired
                        label={t("Url")}
                        placeholder={t("Enter Url")}
                        value={data?.provider_url ?? ''}
                        isError={data?.error?.error?.provider_url?.length > 0}
                        errorMessage={data?.error?.provider_url}
                        onChange={(e) => updateState('provider_url', e.target.value)}
                    />
                </Grid>
            </Grid>
        )
    }
    const manualResponse = (array) => {
        return array?.currency_master?.map((i) => {
            return {
                ...i,
                label: i?.label,
                value: i?.value
            }
        })
    };
    return (
        <Box>
            {/*"Default Base Currency" */}
            <Box padding="8px 12px">
                <CompanySettingsList
                    title={t("Default Base Currency")}
                    onChange={(value) => updateState("default_base_currency", value)}
                    value={data?.default_base_currency}
                    bgColor="#F2F4F7"
                    type="select"
                    border={false}
                    aysnc={true}
                    loadOptionData={(search, array, handleLoading) => loadOptionsApis(
                        "queries/currency_master/list",
                        {},
                        search,
                        array,
                        handleLoading,
                        "data",
                        {},
                        manualResponse
                    )}
                    isError={data?.error?.default_base_currency?.length > 0}
                    errorMessage={data?.error?.default_base_currency}
                />

            </Box>
            {/* default time zone */}
            <Box padding="8px 12px">
                <CompanySettingsList
                    title={t("Default Time Zone")}
                    onChange={(value) => updateState("default_time_zone", value)}
                    value={data?.default_time_zone}
                    bgColor="#F2F4F7"
                    type="currency_select"
                    border={false}
                    aysnc={true}
                    // loadOptionData={loadOptionData}
                    isError={data?.error?.default_time_zone?.length > 0}
                    errorMessage={data?.error?.default_time_zone}
                    loadOptionData={(search, array, handleLoading) =>
                        loadOptionsApis(
                            "/timezone",
                            {},
                            search,
                            array,
                            handleLoading,
                            "data",
                            { timezone: true, label: "label", value: "value" }
                        )
                    }
                />

            </Box>
            <Box className={classes.root}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography className={classes.upfrontTitle}>{t("Upfront Charges")}</Typography>
                    <Stack direction={"row"} spacing={2}>
                        <Box className={classes.upfrontBox}>
                            <SelectBox
                                value={data?.itemType}
                                placeholder={t("Select Item Type")}
                                onChange={(value) => {
                                    setSettings({
                                        ...data,
                                        itemType: value,
                                        itemCategory: "",
                                        itemMaster: ""
                                    })
                                }}
                                options={enumValue?.inspection_item_type}
                                color={"#fff"}
                            />
                        </Box>
                        <Box className={classes.upfrontBox}>
                            <SelectBox
                                value={data?.itemCategory}
                                placeholder={t("Select Item Category")}
                                onChange={(value) => {
                                    setSettings({
                                        ...data,
                                        itemCategory: value,
                                        itemMaster: ""
                                    })
                                }}
                                loadOptions={(search, array, handleLoading) =>
                                    loadOptionsApis(
                                        "inspection_item_category",
                                        {
                                            item_type: [data?.itemType?.value]
                                        },
                                        search,
                                        array,
                                        handleLoading,
                                        "list"
                                    )}
                                debounceTimeout={800}
                                isPaginate={true}
                                key={JSON.stringify(data?.itemType)}
                                color={"#fff"}
                            />
                        </Box>
                        <Box className={classes.upfrontBox}>
                            <SelectBox
                                isRequired
                                value={data?.itemMaster}
                                placeholder={t("Select Item Master")}
                                onChange={(value) => {
                                    updateState("itemMaster", value);
                                }}
                                loadOptions={(search, array, handleLoading) => loadOptionsApis(
                                    "free_text_invoice/get_free_text_item_types",
                                    {
                                        company_id: COMPANY_ID,
                                        item_type: "Item Master",
                                        item_master_type: data?.itemType?.value,
                                        item_master_category: data?.itemCategory?.value,
                                        component_type: "item_based"
                                    },
                                    search,
                                    array,
                                    handleLoading,
                                    "items",
                                    {},
                                    itemMasterManualResponse
                                )}
                                debounceTimeout={800}
                                isPaginate={true}
                                isError={data?.error?.itemMaster?.length > 0}
                                errorMessage={data?.error?.itemMaster}
                                color={"#fff"}
                                // isReadOnly={amenitiesMasterData?.view}
                                key={JSON.stringify(data?.itemCategory, data?.itemCategory)}
                            />
                        </Box>
                    </Stack>
                </Stack>
            </Box>
            <Box padding="8px 12px">
                <CompanySettingsList
                    title={t("Invite Mail trigger")}
                    onChange={(v) => {
                        updateState("is_mail_trigger", v?.value)
                    }}
                    value={data?.is_mail_trigger ? { value: true, label: "Yes" } : { value: false, label: "No" }}
                    options={[
                        { value: true, label: t("Yes") },
                        { value: false, label: t("No") },
                    ]}
                    isError={data?.error?.is_mail_trigger?.length > 0}
                    errorMessage={data?.error?.is_mail_trigger}
                />
            </Box>
            <Box padding="8px 12px">
                <CompanySettingsList
                    title={t("Agreement Renewal")}
                    onChange={(v) => {
                        updateState("is_renewal", v?.value)
                    }}
                    value={data?.is_renewal ? { value: true, label: "Yes" } : { value: false, label: "No" }}
                    options={[
                        { value: true, label: t("Yes") },
                        { value: false, label: t("No") },
                    ]}
                    isError={data?.error?.is_renewal?.length > 0}
                    errorMessage={data?.error?.is_renewal}
                />
            </Box>
            <Box padding="8px 12px">
                <CompanySettingsList
                    title={t("Allow Extension")}
                    onChange={(v) => {
                        updateState("allow_extension", v?.value)
                    }}
                    value={data?.allow_extension ? { value: true, label: "Yes" } : { value: false, label: "No" }}
                    options={[
                        { value: true, label: t("Yes") },
                        { value: false, label: t("No") },
                    ]}
                    isError={data?.error?.allow_extension?.length > 0}
                    errorMessage={data?.error?.allow_extension}
                />
            </Box>
            <Box padding="8px 12px">
                <CompanySettingsList
                    title={t("Renewal Day")}
                    onChange={(v) => {
                        updateState("renewal_period", v)
                    }}
                    value={data?.renewal_period}
                    isError={data?.error?.renewal_period?.length > 0}
                    errorMessage={data?.error?.renewal_period}
                    type="text"
                />
            </Box>
            <Box padding="8px 12px">
                <CompanySettingsList
                    title={t("Approval Period")}
                    onChange={(v) => {
                        updateState("approval_period", v)
                    }}
                    value={data?.approval_period}
                    isError={data?.error?.approval_period?.length > 0}
                    errorMessage={data?.error?.approval_period}
                    type="text"
                />
            </Box>
            {/*Default Unit Measurement*/}
            <Box padding="8px 12px">
                <CompanySettingsList
                    title={t("Default Unit Measurement")}
                    onChange={(value) => updateState("default_uom", value)}
                    value={data?.default_uom}
                    options={UOMValue}
                    isError={data?.error?.default_uom?.length > 0}
                    errorMessage={data?.error?.default_uom}
                />
            </Box>
            {/*Default Quotation Validity*/}
            <Box padding="8px 12px">
                <CompanySettingsList
                    title={t("Default Quotation Validity")}
                    onChange={(value) => updateState("quotation_validity", value)}
                    value={data?.quotation_validity}
                    options={quotation_validity}
                    custom={true}
                    customValue={0}
                    openCustom={validateCustom([null], data?.quotation_validity?.value)}
                    isError={data?.error?.quotation_validity?.length > 0}
                    errorMessage={data?.error?.quotation_validity}
                />
            </Box>
            {/*Default Billing Payment Period*/}
            <Box padding="8px 12px">
                <CompanySettingsList
                    title={t("Default Billing Payment Period")}
                    onChange={(value) => updateState("billing_payment_peroid", value)}
                    value={data?.billing_payment_peroid}
                    options={enumValue?.company_payment_period}
                    isError={data?.error?.billing_payment_peroid?.length > 0}
                    errorMessage={data?.error?.billing_payment_peroid}
                />
            </Box>
            {/*Default Billing lease Period*/}
            <Box padding="8px 12px">
                <CompanySettingsList
                    title={t("Default Lease Period")}
                    onChange={(value) => updateState("lease_period", value)}
                    value={data?.lease_period}
                    options={enumValue?.company_lease_period}
                    isError={data?.error?.lease_period?.length > 0}
                    errorMessage={data?.error?.lease_period}
                />
            </Box>
            {/*Default Billing Payment Terms*/}
            <Box padding="8px 12px">

                <CompanySettingsList
                    title={t("Default Billing Payment Terms")}
                    onChange={(value) => {
                        updateState("billing_payment_terms_value", value)
                    }}
                    value={data?.billing_payment_terms_value}
                    options={enumValue?.payment_terms}
                    onCustomChange={(value, custom) => {
                        // updateState("billing_payment_terms_value", value)
                        // updateState("billing_payment_terms", custom)
                        updateState1(custom, value)

                    }}
                    // textValue={data?.billing_payment_terms_value}
                    // type={data?.billing_payment_terms?.value === enumName.userDefined ? "textbox" : null}
                    isError={data?.error?.billing_payment_terms_value?.length > 0}
                    errorMessage={data?.error?.billing_payment_terms_value}
                    custom={true}
                    customValue={0}
                    openCustom={false}
                />
            </Box>

            {/*Default Billing Cycle Methods*/}
            <Box padding="8px 12px">
                <CompanySettingsList
                    title={t("Default Billing Cycle Methods")}
                    onChange={(value) => updateState("billing_cycle_method", value)}
                    value={data?.billing_cycle_method}
                    options={enumValue?.bill_gen}
                    isError={data?.error?.billing_cycle_method?.length > 0}
                    errorMessage={data?.error?.billing_cycle_method}
                />
            </Box>
            {/*Default Billing Generate Date*/}
            <Box padding="8px 12px">

                {data?.billing_cycle_date?.value === "Custom Date" ? "textbox" : null}
                <CompanySettingsList
                    title={t("Default Billing Generate Date")}
                    onChange={(value) => updateState("billing_generate_date", value)}
                    value={data?.billing_generate_date}
                    options={data?.billing_generate_date?.value === "Custom Date" ? enumValue?.billing_cycle_date?.filter((x) => x?.value !== "Custom Date") : enumValue?.billing_cycle_date}
                    isError={data?.error?.billing_generate_date?.length > 0}
                    errorMessage={data?.error?.billing_generate_date}
                    type={data?.billing_generate_date?.value === "Custom Date" ? "textbox" : null}
                    onChangeText={(state, value) => updateState("bill_custom_date", value)}
                    textValue={data?.bill_custom_date}
                />
            </Box>
            {/*"Default Payment Gateway"*/}
            <Box padding="8px 12px">
                <CompanySettingsList
                    title={t("Default Payment Gateway")}
                    onChange={(value) => updateState("default_payment_gateway", value)}
                    value={data?.default_payment_gateway}
                    options={enumValue.payment_gateway}
                    render={renderGatway}
                    isError={data?.error?.default_payment_gateway?.length > 0}
                    errorMessage={data?.error?.default_payment_gateway}
                />
            </Box>
        </Box>
    )
}