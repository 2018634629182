import { Avatar, Box, IconButton, Typography } from "@mui/material"
import {useStyles} from "./styles";
import EditIMG from "../../../assets/editimage";
import { FontFamilySwitch, stringAvatar } from "../../../utils";

export const BrokerAccCard=({acc,onEdit,title="Broker & Account",bgColor,textColor, edit= false , hideValue1=true})=>{

    const classes=useStyles({bgColor,textColor});

    return(
        <Box className={classes.brokerRoot}>
            <Typography className={classes.accTitle}>{title}</Typography>
          <Box marginTop="4px"  display="flex" alignItems="center">
            <Box>
            <Avatar src={acc?.logo ?? acc?.image_url} {...stringAvatar(acc?.label, FontFamilySwitch().semiBold, "50%")}/>
            </Box>
            <Box flexGrow={1} marginLeft="8px">
                <Typography className={classes.accLabel}>{acc?.label}</Typography>
                <Typography className={classes.accNo}>{acc?.account_no ?? acc?.contact_no}</Typography>
                {
                        (acc?.value1 && hideValue1) &&  <Typography className={classes.accNo}>{acc?.value1 ?? acc?.value1}</Typography>

                }

            </Box>
            <Box>
                {edit && <IconButton onClick={onEdit}>
                    <EditIMG color="#5078E1"/>
                </IconButton>}
            </Box>
          
          </Box>


        </Box>
    )

}