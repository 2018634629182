/* eslint-disable react-hooks/exhaustive-deps */
import {
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import React from "react";
import FilterIMG from "../../assets/filter";
import {
  FilterGenerator,
  SearchFilter,
  Subheader,
  TableWithPagination,
  UseDebounce,
} from "../../components";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import {
  accessCheckRender,
  activeOptions,
  AlertProps,
  convertDateFromTime,
  getCompanyOption,
  getRoutePermissionNew,
  NetWorkCallMethods,
} from "../../utils";
import {
  Heading,
  initialDialogState,
  Path,
  Type,
} from "../../utils/workingHoursMaster/tableFormUtils";
import { useStyles } from "./style";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { BackendRoutes } from "../../router/routes";
import { DialogForm } from "./component/dialogForm";
import { withTranslation } from "react-i18next";
import { NewLoader } from "../../components/newLoader";
import moment from "moment";

const WorkingHoursMaster = ({ t, loading, handleLoading }) => {
  const classes = useStyles();
  const debounce = UseDebounce();

  // useContext
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext);

  // useState
  const [permission, setPermission] = React.useState({});
  const [loader, setLoader] = React.useState(true);
  const [disable, setDisable] = React.useState(true);
  const [companyList, setCompanyList] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState({});
  const [list, setList] = React.useState({
    data: [],
    totalRowsCount: 0,
  });
  const [search, setSearch] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [filterData, setFilterData] = React.useState({ is_active: [true] });
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogState, setDialogState] = React.useState({
    ...initialDialogState,
  });
  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        getCompany();
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  const getCompany = () => {
    let company = getCompanyOption(backdrop, auth, alert);
    if (company) {
      setCompanyList(company?.list);
      setSelectedCompany(company?.selected);
      getList(company?.selected, filterData, 0, limit, search);
    }
  };

  // Function to get working hours master list based on the input data
  const getList = (company, filter, offset, limit, search) => {
    let payload = {
      offset: offset,
      limit: limit,
      company_id: company?.value,
      search: search,
      active: filter?.is_active ?? undefined,
    };
    NetworkCall(
      `${config.api_url}${BackendRoutes.working_hours_master_list}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const list = res?.data?.data?.map((_) => {
          return {
            id: _?.id,
            company_name: _?.company_name ?? "-",
            description: _?.description ?? "-",
            shift_name: _?.shift_name ?? "-",
            starting_time: _?.start_time ?? "-",
            ending_time: _?.end_time ?? "-",
            break_period: _?.break_period ?? "-",
            total_working_hours:
              _?.working_hours !== null
                ? _?.working_hours?.toFixed(2) +
                  (_?.working_hours === 1 ? " Hr" : " Hrs")
                : 0,
            status: _?.is_active ? "Active" : "Inactive",
            week_off_days: _?.week_off_days,
            data: _,
          };
        });
        setList({
          data: list,
          totalRowsCount: res?.data?.count,
        });
        setLoader(false);
        setDisable(false);
        handleLoading(false);
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps?.severity?.error,
          msg: t(AlertProps?.message?.some_thing_went_wrong),
        });
      });
  };

  // Function to change the company
  const handleCompanyChange = (value) => {
    setLoader(true);
    setSelectedCompany(value);
    if (page > 1) {
      setPage(1);
    }
    getList(value, filterData, 0, limit, search);
  };

  // Function for search in search component
  const handleSearch = (e) => {
    setSearch(e);
    debounce(() => searchTableFunction(e), 800);
  };

  // Function to search data in working hours master list
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getList(selectedCompany, filterData, 0, limit, e);
  };

  // Function to open filter drawer
  const handleFilter = (value) => {
    setLoader(true);
    setFilterData(value);
    if (page > 1) {
      setPage(1);
    }
    getList(selectedCompany, value, 0, limit, search);
  };

  // Function to handle pagination in table
  const handleTablePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getList(selectedCompany, filterData, offset, limit, search);
  };

  // Function to handle page limit in table
  const handleTablePageLimit = (value) => {
    setLimit(value);
    if (page > 1) {
      setPage(1);
    }
    getList(selectedCompany, filterData, 0, value, search);
  };

  // Function to open add dialog
  const handleAdd = () => {
    setDisable(false);
    setDialogState({ ...initialDialogState });
    setDialogOpen(true);
  };

  // Function to handle icon in table row
  const handleTableIcon = (type, data) => {
    const tempData = data?.data ?? data;
    const tempAddJobsState = {
      formType: type,
      id: tempData?.id,
      shift_name: tempData?.shift_name,
      shift_starting_time: tempData?.shift_start_time
        ? convertDateFromTime(tempData?.shift_start_time)
        : tempData?.shift_starting_time,
      starting_time: tempData?.start_time,
      shift_ending_time: tempData?.shift_end_time
        ? convertDateFromTime(tempData?.shift_end_time)
        : tempData?.shift_ending_time,
      ending_time: tempData?.end_time,
      break_starting_time: tempData?.break_start_time
        ? convertDateFromTime(tempData?.break_start_time)
        : tempData?.break_starting_time,
      break_ending_time: tempData?.break_end_time
        ? convertDateFromTime(tempData?.break_end_time)
        : tempData?.break_ending_time,
      break_period: tempData?.break_period,
      total_working_hours:
        (tempData?.working_hours
          ? tempData?.working_hours.toFixed(2)
          : tempData?.total_working_hours) +
        (tempData?.working_hours === 1 || tempData?.total_working_hours === 1
          ? " Hr"
          : " Hrs"),
      description: tempData?.description,
      is_active: type === "active" ? !tempData?.is_active : tempData?.is_active,
      is_delete: type === "delete" ?? tempData?.is_delete,
      week_off_days: tempData?.week_off_days ?? [],
      error: initialDialogState?.error,
    };
    setDialogState({ ...tempAddJobsState });
    if (type === "edit" || type === "view" || type === "delete") {
      setDisable(false);
      setDialogOpen(true);
    } else if (type === "active") {
      handleCreateEdit(tempAddJobsState);
    }
  };

  // Function to update addJobsState
  const updateDialogState = (k, v) => {
    let error = dialogState?.error;
    error[k] = "";
    setDialogState({ ...dialogState, [k]: v, error });
  };

  // Function for updating addNewState
  const validate = () => {
    let isValid = true;
    let error = dialogState.error;

    if (dialogState?.shift_name?.length === 0) {
      isValid = false;
      error.shift_name = t("Shift Name is Required");
    }
    if (dialogState?.shift_starting_time?.length === 0) {
      isValid = false;
      error.shift_starting_time = t("Starting Time is Required");
    }
    if (dialogState?.shift_ending_time?.length === 0) {
      isValid = false;
      error.shift_ending_time = t("Ending Time is Required");
    }
    if (
      dialogState?.break_starting_time?.length === 0 ||
      dialogState?.break_starting_time === null ||
      dialogState?.break_starting_time === undefined
    ) {
      isValid = false;
      error.break_starting_time = t("Break Period Starting Time is Required");
    }
    if (
      dialogState?.break_ending_time?.length === 0 ||
      dialogState?.break_ending_time === null ||
      dialogState?.break_ending_time === undefined
    ) {
      isValid = false;
      error.break_ending_time = t("Break Period Ending Time is Required");
    }

    const shiftStartingTime = moment(dialogState?.shift_starting_time);
    const shiftEndingTime = moment(dialogState?.shift_ending_time);
    const breakStartingTime = moment(dialogState?.break_starting_time);
    const breakEndingTime = moment(dialogState?.break_ending_time);

    if (
      breakStartingTime.isBefore(shiftStartingTime) ||
      breakStartingTime.isAfter(shiftEndingTime)
    ) {
      isValid = false;
      error.break_starting_time = t("Break Time must be within Shift Time");
    }
    if (
      breakEndingTime.isBefore(shiftStartingTime) ||
      breakEndingTime.isAfter(shiftEndingTime)
    ) {
      isValid = false;
      error.break_ending_time = t("Break Time must be within Shift Time");
    }

    if (!isValid) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps?.severity?.error,
        msg: t(AlertProps?.message?.please_fill_all_mandatory_field),
      });
    }

    setDialogState({ ...dialogState, error });
    return isValid;
  };

  // Function to create a Job
  const handleCreateEdit = async (data) => {
    if (
      data?.formType === "active" || data?.formType === "delete"
        ? true
        : validate()
    ) {
      setDisable(true);

      const payload = {
        from: data?.shift_starting_time?.format("HH:mm:00"),
        to: data?.shift_ending_time?.format("HH:mm:00"),
        is_active: data?.is_active,
        company_id: selectedCompany?.value,
        shift_name: data?.shift_name,
        break_from: data?.break_starting_time?.format("HH:mm") ?? null,
        break_to: data?.break_ending_time?.format("HH:mm") ?? null,
        description: data?.description,
        is_delete: data?.is_delete,
        week_off_days: data?.week_off_days ?? [],
        id: data?.id?.length > 0 ? data?.id : undefined,
      };

      NetworkCall(
        `${config.api_url}/working_hours_master/upsert`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((res) => {
          setLoader(true);
          setDialogState({ ...initialDialogState });
          if (page > 1) {
            setPage(1);
          }
          getList(selectedCompany, filterData, 0, limit, search);
          setDialogOpen(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: data?.id
              ? data?.formType === "delete"
                ? t("Work Schedule Deleted Successfully.!!!")
                : data?.formType === "active"
                ? data?.is_active
                  ? `${data?.shift_name} ${t("Is Active Now!!!")}`
                  : `${data?.shift_name} ${t("Is In-Active Now!!!")}`
                : t("Work Schedule Updated Successfully.!!!")
              : t("Work Schedule Created Successfully.!!!"),
          });
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps?.severity?.error,
            msg: t(AlertProps?.message?.some_thing_went_wrong),
          });
          setDisable(false);
        });
    } else {
      return false;
    }
  };

  const render = () => {
    return (
      <>
        <Subheader
          hideBackButton={true}
          title={t("calendar_master")}
          isReadOnly={loader || disable}
          select
          options={companyList}
          value={selectedCompany}
          onchange={(e) => handleCompanyChange(e)}
        />
        {loader ? (
          <NewLoader minusHeight="100px" />
        ) : (
          <div className={classes.root}>
            <Grid container className={classes.content} spacing={1}>
              <Grid item xs={3}>
                <SearchFilter
                  value={search}
                  placeholder={t("search")}
                  handleChange={(value) => handleSearch(value)}
                  customfieldSx={{
                    "& .MuiOutlinedInput-root": { height: "40px" },
                  }}
                />
              </Grid>
              <Grid item xs={9}>
                <Box display={"flex"} justifyContent={"end"}>
                  <Stack
                    direction="row"
                    spacing={2}
                    divider={<Divider orientation="vertical" flexItem />}
                  >
                    <IconButton
                      disabled={disable}
                      onClick={() => setFilterOpen(!filterOpen)}
                      className={classes.filterButton}
                    >
                      <Badge
                        variant="dot"
                        color="primary"
                        invisible={!(filterData.is_active?.length > 0)}
                      >
                        <FilterIMG color="#091b29" />
                      </Badge>
                    </IconButton>
                    {permission.create && (
                      <Button
                        variant="contained"
                        disabled={disable}
                        className={classes.button}
                        onClick={handleAdd}
                      >
                        {t("add")}
                      </Button>
                    )}
                  </Stack>
                </Box>
              </Grid>
            </Grid>
            <TableWithPagination
              heading={Heading(t)}
              rows={list?.data}
              path={Path}
              showpagination={true}
              showpdfbtn={false}
              showexcelbtn={false}
              showSearch={false}
              handleIcon={handleTableIcon}
              onClick={() => console.log("")}
              tableType="no-side"
              dataType={Type}
              handlePagination={handleTablePagination}
              handleChangeLimit={handleTablePageLimit}
              totalRowsCount={list?.totalRowsCount}
              page={page}
              limit={limit}
              height={"calc(100vh - 290px)"}
              view={permission.read}
              edit={permission.update}
              delete={permission.delete}
            />
            <FilterGenerator
              open={filterOpen}
              onClose={() => setFilterOpen(false)}
              onApply={(value) => handleFilter(value)}
              components={[
                {
                  component: "toggleButton",
                  value: filterData?.is_active,
                  options: activeOptions(t),
                  isMulti: true,
                  state_name: "is_active",
                  label: t("status"),
                },
              ]}
            />
            <DialogForm
              t={t}
              handleTableIcon={handleTableIcon}
              handleCreateEdit={handleCreateEdit}
              dialogState={dialogState}
              dialogOpen={dialogOpen}
              setDialogOpen={setDialogOpen}
              disable={disable}
              updateDialogState={updateDialogState}
            />
          </div>
        )}
      </>
    );
  };

  return <div>{accessCheckRender(render, permission, "", loading)}</div>;
};

export default withTranslation("workingHoursMaster")(WorkingHoursMaster);
