import { Badge, Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import { CalendarDateChange } from '../../../components/calendarDateChange';
import { DropdownCard } from './dropdownCard';
import FilterIMG from '../../../assets/filter';
import { useStyles } from './style';
import { FilterGenerator } from '../../../components';
import { enumSelect, enum_types } from '../../../utils';
import { loadOptionsApis } from '../../../utils/asyncPaginateLoadOptions';


export const ScheduleCalendarHeader = ({ t, state = {}, prev = () => false, next = () => false, resource = [], setSelectedType = () => false, selectedType = {}, type = "", setType = "", filterData = {}, onApplyFilter = () => false, companyId = "" }) => {
    const classes = useStyles()
    const [drawer, setDrawer] = useState(false)
    const [value, setValue] = React.useState({ type: [] })
    const getEnum = async () => {
        const result = await enumSelect([enum_types.inspection_request_type_enum]);
        setValue({
            type: result?.inspection_request_type_enum,
        });
    };
    const manualResponse = (array) => {
        const details = array?.map((i) => {
                return {
                    ...i,
                    label: i?.name,
                    value: i?.id
                }
            })
        return details
    };
    React.useEffect(() => {
        getEnum()
        //eslint-disable-next-line
    }, [])
    return (
        <Box>
            <Grid container alignItems={"center"} >
                <Grid item xs={2}>
                    <DropdownCard t={t} setSelectedType={setSelectedType} selectedType={selectedType} options={resource} />
                </Grid>
                <Grid item xs={3} />
                <Grid xs={4}>
                    <CalendarDateChange state={state} prev={prev} next={next} comp={type === "Monthly" ? "month" : type === "Daily" ? "daily" : "weekly"} />
                </Grid>
                <Grid xs={3} display={"flex"} justifyContent={"end"}>
                    <Box className={classes.filterbox} display={'flex'} justifyContent={"center"} onClick={() => setDrawer(true)}>
                        {(filterData?.resource?.length > 0 || filterData?.job_type?.length > 0) ? <Badge variant="dot" color="primary"><FilterIMG /></Badge> : <FilterIMG />}
                    </Box>
                </Grid>
                {/* <Grid item xs={2}>
                <CustomDropDown type={type} setType={setType} />
            </Grid> */}
            </Grid>
            {drawer && (
                <FilterGenerator
                    open={drawer}
                    onClose={() => setDrawer(false)}
                    components={[
                        {
                            component: "select",
                            value: filterData?.resource,
                            state_name: "resource",
                            label: t("Resource"),
                            placeholder: t("Select Resource"),
                            // options: [],
                            loadOptions: (search, array, handleLoading) =>
                                loadOptionsApis(
                                    "/resources/getAll",
                                    {
                                        "company_id": companyId,
                                        "active": [true]
                                    },
                                    search,
                                    array,
                                    handleLoading,
                                    "result",
                                    {},
                                    manualResponse
                                ),
                            isMulti: true,
                            isPaginate: true,
                        },

                        {
                            component: "select",
                            value: filterData?.job_type,
                            state_name: "job_type",
                            label: t("Job Type"),
                            placeholder: t("Select Job Type"),
                            options: value?.type,
                            isMulti: true,
                            // isPaginate: true,
                        },
                    ]}
                    onApply={(value) => onApplyFilter(value)}
                />
            )}
        </Box>
    )
}