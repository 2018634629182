import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const pricingAppraisalStyle = makeStyles((theme) => ({
  root: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: `0px 0px 16px ${theme?.palette?.boxShadow}`,
    backgroundColor: theme?.palette?.background?.tertiary1,
    padding: "16px",
    height: `calc(100vh - 147px)`,
    overflow: "hidden",
    margin: "14px",
  },
  img: {
    border: `1px solid ${theme?.palette?.border?.secondary}`,
    borderRadius: theme?.palette?.borderRadius,
    padding: "10px",
  },
  menu: {
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.palette?.secondary?.main,
  },
  button: {
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    height:"41px"
  },
  popUpParent: {
    padding: "16px",
    overflow: "auto",
    background: "white"

  },
  tableMain: {
    paddingTop: "0 !important"
  }
}));

export const againstCompanyStyle = makeStyles((theme) => ({
  popUpParent: {
    padding: "16px",
    overflow: "auto",
    backgroundColor: "#fff"
  },
  btnParent: {
    padding: "16px",
    backgroundColor: theme?.palette?.background?.tertiary1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  next: {
    marginLeft: "10px",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
  Cancel: {
    backgroundColor: theme?.palette?.background?.tertiary1,
    color: "#091B29",
    fontSize:"0.875rem",
    fontWeight: 600,
    fontFamily: FontFamilySwitch().semiBold,
    border: `1px solid ${theme?.palette?.border?.secondary}`,
    "&:hover": {
      backgroundColor: theme?.palette?.background?.tertiary1,
    },
  },
  valueTitle: {
    color: theme?.palette?.background?.primary,
    fontFamily: FontFamilySwitch().bold,
    fontSize:"0.75rem",
    textTransform: "capitalize"

  },
  endorement: {
    color: theme?.palette?.background?.primary,
    fontFamily: FontFamilySwitch().semiBold,
    fontSize:"0.75rem",
    whiteSpace: "nowrap",

    width: "50%"
  },
  divider: {
    margin: "20px 0px",
  },
}));

export const againstPropertyStyle = makeStyles((theme) => ({
  popUpParent: {
    padding: "16px",
    overflow: "auto",
    background: "white"

  },
  btnParent: {
    padding: "16px",
    backgroundColor: theme?.palette?.background?.tertiary1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  next: {
    marginLeft: "10px",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
  Cancel: {
    backgroundColor: theme?.palette?.background?.tertiary1,
    color: "#091B29",
    fontSize:"0.875rem",
    fontWeight: 600,
    fontFamily: FontFamilySwitch().semiBold,
    border: `1px solid ${theme?.palette?.border?.secondary}`,
    "&:hover": {
      backgroundColor: theme?.palette?.background?.tertiary1,
    },
  },
  valueTitle: {
    color: theme?.palette?.background?.primary,
    fontFamily: FontFamilySwitch().bold,
    fontSize:"0.75rem",
    textTransform: "capitalize"
  },
  endorement: {
    color: theme?.palette?.background?.primary,
    fontFamily: FontFamilySwitch().semiBold,
    fontSize:"0.75rem",
  },
  divider: {
    margin: "20px 0px",
  },
}));
