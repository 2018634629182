import Skeleton from '@mui/lab/Skeleton';
import Avatar from '@mui/material/Avatar';
import styled from '@mui/material/styles/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { config } from '../../config';
import { AlertContext } from '../../contexts';
import { AlertProps, allowed_file_size, findImageSize, singleFileUpload } from '../../utils';
import { Camera, Image } from '../svg';
import { withTranslation } from 'react-i18next';

const CustomUpload = styled('div', {
    shouldForwardProp: (prop) => prop !== 'width' && prop !== 'height' && prop !== 'backgroundColor' && prop !== 'border'
})(({ width, height, backgroundColor, border }) => ({
    width,
    height,
    backgroundColor,
    border,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    position: 'relative',
}));

const CustomDiv = styled('div')({
    position: 'absolute',
    right: 4,
    bottom: 4
})

const CustomInput = styled('input')({
    position: 'absolute',
    opacity: 0,
    inset: 0,
    zIndex: 1,
    cursor: 'pointer'
})

const CustomAvatar = styled(Avatar)({
    width: 100,
    height: 100,
    borderRadius: '50%',
});

const RelativeDiv = styled('div')({
    cursor: "pointer",
    position: 'relative',
    width: 'fit-content'
});

const UploadLogo = (props) => {


    const alert = React.useContext(AlertContext);
    const [loading, setLoading] = React.useState(false);

    const {
        accept = "image/*",
        disabled = false,
        conditions = {},
        fileUploadUrl = true,
        handleChange = null,
        value = "",
        t
    } = props;

    const handleUpload = async (data) => {
        //only allow if file selected
        if (data?.length) {
            //condition check
            if (conditions?.width && conditions?.height) {
                let { width = null, height = null } = await findImageSize(data[0]);
                if (width === conditions?.width && height === conditions?.height) {
                    if (fileUploadUrl) {
                        executeImageData(data)
                    }
                    else {
                        handleChange && handleChange(data)
                    }
                }
                else {
                    alert.setSnack({
                        open: true,
                        horizontal: AlertProps.horizontal.center,
                        vertical: AlertProps.vertical.top,

                        msg: `${t("Please select the image size in")} ("${conditions?.width}x${conditions?.height}")`,


                        severity: AlertProps.severity.error,
                    });
                }
            }
            else {
                if (fileUploadUrl) {
                    executeImageData(data)
                }
                else {
                    handleChange && handleChange(data)
                }
            }
        }
    }

    const executeImageData = async (data) => {

        setLoading(true);

        let uploaded_file = await singleFileUpload(data?.[0], { tenantId: `${config.tenantId}` }, alert, allowed_file_size)

        if (uploaded_file?.[0]?.url) {
            handleChange && handleChange(uploaded_file?.[0]?.url);
            setLoading(false);
        } else {
            handleChange(null);
            setLoading(false);
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Some Thing Went Wrong"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            })
        }
    }

    return (
        <div>
            {
                loading ? (
                    <Skeleton variant='circular' width={100} height={100} />
                ) : (
                    <>
                        {
                            value ? (
                                <RelativeDiv>
                                    <CustomAvatar src={value} />
                                    <CustomInput
                                        type="file"
                                        onChange={(e) => handleUpload(e.target.files)}
                                        accept={accept}
                                        disabled={disabled}
                                    />
                                    <CustomDiv>
                                        <CustomUpload
                                            width={24}
                                            height={24}
                                            backgroundColor={'#E4E8EE'}
                                            border={'2px solid #FFFFFF'}
                                        >
                                            <Camera height={11} />
                                        </CustomUpload>
                                    </CustomDiv>
                                </RelativeDiv>
                            ) : (
                                <CustomUpload
                                    width={100}
                                    height={100}
                                    backgroundColor={'#F2F4F7'}
                                >
                                    <CustomInput
                                        type="file"
                                        onChange={(e) => handleUpload(e.target.files)}
                                        accept={accept}
                                        disabled={disabled}
                                    />
                                    <Image />
                                    <CustomDiv>
                                        <CustomUpload
                                            width={24}
                                            height={24}
                                            backgroundColor={'#E4E8EE'}
                                            border={'2px solid #FFFFFF'}
                                        >
                                            <Camera height={11} />
                                        </CustomUpload>
                                    </CustomDiv>
                                </CustomUpload>
                            )
                        }
                    </>
                )
            }
        </div >
    )
}

UploadLogo.propTypes = {
    accept: PropTypes.string,
    disabled: PropTypes.bool,
    conditions: PropTypes.object,
    fileUploadUrl: PropTypes.bool,
    handleChange: PropTypes.func,
    value: PropTypes.string,

}
export default withTranslation("uploadLogo")(UploadLogo)

