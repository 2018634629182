import gql from "graphql-tag";

export const GET_MASTERS_FOR_CREATING_BLOCKs = `
query GET_MASTERS_FOR_CREATING_BLOCK {
  block_type_master(where: { is_active: { eq: true } }) {
    value:id
    label:name
  }
}`;
export let GET_MASTERS_FOR_CREATING_BLOCK = (
  offset,
  limit,
  searchText,
  company_id = []
) => gql`
query GET_MASTERS_FOR_CREATING_BLOCK{
  count: block_type_master(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
    }
  ) {
    count:count_id
  }
  block_type_master(
      where:{
        name:{iregex:"${searchText}"}
        is_active:{eq:true}
      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
      	value:id
      }
}
`;
export let GET_MAINTENANCE_CATEGORY = (
  offset,
  limit,
  searchText,
  company_id = []
) => gql`
query GET_MASTERS_FOR_CREATING_BLOCK{
  count: maintenance_category_master(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
    }
  ) {
    count:count_id
  }
  maintenance_category_master(
      where:{
        name:{iregex:"${searchText}"}
        is_active:{eq:true}
      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
      	value:id
      }
}
`;
export let GET_MAINTENANCE_SUB_CATEGORY = (
  offset,
  limit,
  searchText,
  id = ""
) => gql`
query GET_MASTERS_FOR_CREATING_BLOCK{
  count: maintenance_sub_category_master(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
      maintenance_category_id:{eq:"${id}"}
    }
  ) {
    count:count_id
  }
  maintenance_sub_category_master(
      where:{
        name:{iregex:"${searchText}"}
        maintenance_category_id:{eq:"${id}"}
        is_active:{eq:true}
      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
      	value:id
      }
}
`;

export let GET_GENERAL_CATEGORY_MASTERS = (
  offset,
  limit,
  searchText,
  company_id = []
) => gql`
query GET_MASTERS_FOR_CREATING_BLOCK{
  count: general_category_master(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
    }
  ) {
    count:count_id
  }
  general_category_master(
      where:{
        name:{iregex:"${searchText}"}
        is_active:{eq:true}
      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
      	value:id
      }
}
`;
export let GET_GENERAL_SUB_CATEGORY = (
  offset,
  limit,
  searchText,
  id = ""
) => gql`
query GET_MASTERS_FOR_CREATING_BLOCK{
  count: general_sub_category_master(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
     general_category_id:{eq:"${id}"}
    }
  ) {
    count:count_id
  }
 general_sub_category_master(
      where:{
        name:{iregex:"${searchText}"}
        general_category_id:{eq:"${id}"}
        is_active:{eq:true}
      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
      	value:id
      }
}
`;

export const SELECT_UNITS = `
 query($id:String){
  unit(where:{property_id:{eq:$id}}){
    name
    id
  }
}`;
export let SELECTUNITS = (offset, limit, searchText, id = "") => gql`
query GET_MASTERS_FOR_CREATING_BLOCK{
  count: unit(
    where:{
      property_id:{eq:"${id}"}
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
    }
  ) {
    count:count_id
  }
  unit(
      where:{
        property_id:{eq:"${id}"}
        name:{iregex:"${searchText}"}
        is_active:{eq:true}
      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
      	value:id
      }
}
`;
export const GET_PROPERTY_DETAILS_BY_ID = `
query GET_PROPERTY_DETAILS_BY_ID($propertyID: ID) {
  property(where: { id: { eq: $propertyID } }) {
    id
    property_no
    name
    property_group {
      id
      group_name
    }
    name
    description
    area_metric
    carpet_area
    total_area
    property_purpose
    total_units
    address {
      street_1
      street_2
      street_3
      city
      state
      country
      zipcode

    }
    company {
      value: id
      label: name
    }
    unit(where: { is_active: { eq: true } }) {
      id
      name
      unit_no
      total_bed_rooms
      total_area
      block(where: { is_active: { eq: true } }) {
        id
        name
        block_no
      }
      floor(where: { is_active: { eq: true } }) {
        id
        name
        floor_no
      }
      property(where: { is_active: { eq: true } }) {
        id
        name
        property_no
        area_metric
        company(where: { is_active: { eq: true } }) {
          currency(where: { is_active: { eq: true } }) {
            symbol
          }
        }
      }
    }
    business_phone_country_code
    business_phone
    mobile_country_code
    mobile_phone
    website
    email
    city_area_master {
      id
      area_name
    }
    cities_master {
      city_name
      state_master {
        state_name
      }
      country_master {
        country_name
      }
    }
    block(
      where: { property_id: { eq: $propertyID } }
      order_by: { updated_at: desc }
    ) {
      id
      is_active
      property_id
      block_no
      name
      area_metric
      carpet_area
      use_floor_hierarchy
      total_area
    }
  }
  assets(
    where: { reference_id: { eq: $propertyID }, is_active: { eq: true } }
  ) {
    id
    reference_id
    url
    priority
    assets_master_type
  }
}`;
export const GET_SHORTLISTED_UNITS_BY_IDS = `
query GET_SHORTLISTED_UNITS_BY_IDS($unitIDS: [ID]) {
  unit(
    where: { id: { in: $unitIDS }, is_active: { eq: true } }
    order_by: { property_id: desc }
  ) {
    id
    name
    unit_no
    total_bed_rooms
    total_area
    block(where: { is_active: { eq: true } }) {
      id
      name
      block_no
    }
    floor(where: { is_active: { eq: true } }) {
      id
      name
      floor_no
    }
    property(where: { is_active: { eq: true } }) {
      id
      name
      property_no
      area_metric
      company(where: { is_active: { eq: true } }) {
        currency(where: { is_active: { eq: true } }) {
          symbol
        }
      }
    }
  }
}`;

export const GET_LEAD_LIST = `
query GET_LEAD_LIST {
  lead(where: { is_active: { eq: true } }) {
    id
    name
    company_id
    lead_owner(where: { is_active: { eq: true } }) {
      first_name
    }
    contact(where: { is_active: { eq: true } }) {
      mobile_no_country_code
      mobile_no
    }
  }
}`;

export const GET_BLOCK_DETAILS_BY_ID = `
query GET_BLOCK_DETAILS_BY_ID($blockId: ID) {
  block(where: { id: { eq: $blockId }, is_active: { eq: true } }) {
  area_metric
company_id
block_type
is_external
id
carpet_area
total_area
use_floor_hierarchy
property_id
    block_purpose
address_id
owner_id
is_active
created_by
updated_by
created_at
updated_at
name
description
block_no
address_id
    property{
       name
      description
    address_id
     contact{
      street_1
      street_2
      street_3
    }
      cities_master{
        city:city_name
         state_master{
          state_name

      }
        country_master{
          country_name
        }

      }

       business_phone_country_code
    business_phone
    mobile_country_code
    mobile_phone
    website
    email

    }
  }
    floor(where: { block_id: { eq: $blockId } }
      order_by: { updated_at: desc }){
    name
    description
    id
    floor_no
  }
  unit{
    id
    count_id
  }
  assets(
    where: { reference_id: { eq: $blockId }, is_active: { eq: true } }
  ) {
    url
    assets_master_type
    priority
  }
}`;

export const GET_BLOCK_BY_PROPERTY_ID = `
query GET_BLOCK_BY_PROPERTY_ID($propertyID: String, $searchText: String) {
  block(
    where: {
      property_id: { eq: $propertyID }
      or: { block_no: { iregex: $searchText }, name: { iregex: $searchText } }
    }
    offset: 0
    limit: 1000
    order_by: { updated_at: desc }
  ) {
    id
    is_active
    property_id
     unit(where:{ is_active: { eq: true }}){
      id
    }
    floor(where:{ is_active: { eq: true }}){
      id
    }
    block_no
    name
    area_metric
    carpet_area
    use_floor_hierarchy
    total_area
  }
}`;

export const GET_PROPERTY_BY_COMPANY_ID = (
  offset = 0,
  limit = 10,
  searchText = "",
  companyID = [],
  propertyGroudID = [],
  status = [],
  clientID,
  propertyHierarchyID = [],
  propertyPurpose = []
) => gql`
query GET_PROPERTY_BY_COMPANY_ID{
  count:property(
    where: {
      ${clientID?.length ? `client:{eq:"${clientID}"}` : ""}
      ${companyID?.length ? `company_id: { in: [${companyID}] }` : ""}
      ${
        propertyGroudID?.length
          ? `property_group_id: { in: [${propertyGroudID}] }`
          : ""
      }
      ${status?.length ? `is_active:{in:[${status}]}` : ""}
      ${
        propertyHierarchyID?.length
          ? `property_hierarchy_id: { in: [${propertyHierarchyID}] }`
          : ""
      }
      ${
        propertyPurpose?.length
          ? `property_purpose: { in: $propertyPurpose }`
          : ""
      }
      or: {
        property_no: { iregex: "${searchText}" }
        name: { iregex: "${searchText}" }
      }
    }
  ){
    count:count_id
  }
  property(
    where: {
      ${clientID?.length ? `client:{eq:"${clientID}"}` : ""}
      ${companyID?.length ? `company_id: { in: [${companyID}] }` : ""}
      ${
        propertyGroudID?.length
          ? `property_group_id: { in: [${propertyGroudID}] }`
          : ""
      }
      ${status?.length ? `is_active:{in:[${status}]}` : ""}
      ${
        propertyHierarchyID?.length
          ? `property_hierarchy_id: { in: [${propertyHierarchyID}] }`
          : ""
      }
      ${
        propertyPurpose?.length
          ? `property_purpose: { in: $propertyPurpose }`
          : ""
      }
      or: {
        property_no: { iregex: "${searchText}" }
        name: { iregex: "${searchText}" }
      }
    }
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc }
  ) {
    id
    is_active
    property_no
    name
property_purpose
property_hierarchyByID {
  value: id
  label: name
  is_block
  is_floor
  is_unit
  is_multi_unit
}
    company:company_master(where: { is_active: { eq: true } }) {
      id
      name
    }
    block(where: { is_active: { eq: true } }) {
      id
    }
    floor(where: { is_active: { eq: true } }) {
      id
    }
    unit(where: { is_active: { eq: true } }) {
      id
    }
    address_id
    address:addressByID{
      area
      street_1
      street_2
      street_3
      city
      district
      state
      country
    }
    property_group_id
   property_group_master:property_groupByID {
      id
      group_name
    }

  }
}`;

export const GET_PROPERTY_BY_COMPANY_ID_NEW = (
  offset = 0,
  limit = 10,
  searchText = "",
  companyID = [],
  propertyGroudID = [],
  status,
  clientID,
  propertyHierarchyID = [],
  propertyPurpose = [],
  teamsId = ""
) => gql`
query GET_PROPERTY_BY_COMPANY_ID{
  count:property(
    where: {
      ${clientID?.length ? `client:{eq:"${clientID}"}` : ""}
      ${companyID?.length ? `company_id: { in: [${companyID}] }` : ""}
      ${
        propertyGroudID?.length
          ? `property_group_id: { in: [${propertyGroudID}] }`
          : ""
      }
      ${status !== null ? `is_active:{eq:${status}}` : ""}
      ${
        propertyHierarchyID?.length
          ? `property_hierarchy_id: { in: [${propertyHierarchyID}] }`
          : ""
      }
      ${
        propertyPurpose?.length
          ? `property_purpose: { in: $propertyPurpose }`
          : ""
      }
      or: {
        property_no: { iregex: "${searchText}" }
        name: { iregex: "${searchText}" }
      }
    }
  ){
    count:count_id
  }
  property(
    where: {
      ${clientID?.length ? `client:{eq:"${clientID}"}` : ""}
      ${companyID?.length ? `company_id: { in: [${companyID}] }` : ""}
      ${
        propertyGroudID?.length
          ? `property_group_id: { in: [${propertyGroudID}] }`
          : ""
      }
      ${status !== null ? `is_active:{eq:${status}}` : ""}
      ${
        propertyHierarchyID?.length
          ? `property_hierarchy_id: { in: [${propertyHierarchyID}] }`
          : ""
      }
      ${
        propertyPurpose?.length
          ? `property_purpose: { in: $propertyPurpose }`
          : ""
      }
      or: {
        property_no: { iregex: "${searchText}" }
        name: { iregex: "${searchText}" }
      }
    }
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc }
  ) {
    id
    is_active
    property_no
    name
property_purpose
property_hierarchyByID {
  value: id
  label: name
  is_block
  is_floor
  is_unit
  is_multi_unit
}
    company:company_master(where: { is_active: { eq: true } }) {
      id
      name
    }
    block(where: { is_active: { eq: true } }) {
      id
    }
    floor(where: { is_active: { eq: true } }) {
      id
    }
    unit(where: { is_active: { eq: true } }) {
      id
    }
    address_id
    address:addressByID{
      area
      street_1
      street_2
      street_3
      city
      district
      state
      country
    }
    property_group_id
   property_group_master:property_groupByID {
      id
      group_name
    }

  }
}`;

// unitTable

export const GET_UNITTABLEDETAILS = `
query($propertyID:String){
  unit(where:{property_id:{eq:$propertyID},is_active: { eq: true }}){
     id
    name
    unit_no
    parent_unit_id
    parent_unit: unit(find: "parents") {
      id
      name
    }
    area_metric
    total_area
    furnishing
    unit_purpose
    status

    is_active
    unit_type_master {
      type:name
    }
  }
}

`;
export const GET_FLOOR_DETAILS_BY_ID = `
query GET_FLOOR_DETAILS_BY_ID($floorId: ID) {
  floor(where: { id: { eq: $floorId }, is_active: { eq: true } }) {
    id
    floor_purpose
    company_id
    block {
      id
      name
      description
    }
    property {
      id
      area_metric
      company{
        id
        name
      }
      name
      description
      business_phone_country_code
      business_phone
      mobile_country_code
      mobile_phone
      website
         address_id
     contact{
      street_1
      street_2
      street_3
    }
      email
      cities_master {
        city:city_name
        state_master {
          state_name
        }
        country_master {
          country_name
        }
      }
      business_phone_country_code
      business_phone
      mobile_country_code
      mobile_phone
      website
      email
    }
    is_external
    id
    property_id
    block_id
    address_id
    owner_id
    name
    description
    floor_no
    unit(order_by: { updated_at: desc }) {
      id
      name
      unit_no
      property {
        id
        area_metric
      }
      unit(find: "parents") {
        id
        name
      }
      unit_type {
        id
        type:name
      }
      carpet_area
      area_metric
      furnishing
      unit_purpose
      status
      is_active
    }
  }

  assets(where: { reference_id: { eq: $floorId }, is_active: { eq: true } }) {
    url
    assets_master_type
    priority
  }
}
`;
export const GET_UNIT_BY_FLOOR_ID = `
query GET_UNIT_BY_FLOOR_ID($floorID: String, $searchText: String) {
  unit(
    where: {
      floor_id: { eq: $floorID }
      or: { unit_no: { iregex: $searchText }, name: { iregex: $searchText } }
    }
    offset: 0
    limit: 1000
    order_by: { updated_at: desc }
  ) {
    id
    name
    unit_no
    unit(find: "parents") {
      id
      name
    }
    unit_type {
      id
      type:name
    }
    carpet_area
    area_metric
    furnishing_master {
      id
      type
    }
    unit_purpose

    is_active
  }
}
`;

export const GET_FLOOR_BY_BLOCK_ID = `
query GET_FLOOR_BY_BLOCK_ID($blockId: String, $searchText: String) {
  floor(
    where: {
      block_id: { eq: $blockId }
      or: { floor_no: { iregex: $searchText }, name: { iregex: $searchText } }
    }
    offset: 0
    limit: 1000
    order_by: { updated_at: desc }
  ) {
    name
    description
    id
    floor_no
  }
}`;

export const GET_BLOCK = gql`
  query GET_BLOCK {
    assets: block_assets(
      where: { block_id: { eq: $blockID }, is_active: { eq: true } }
    ) {
      id
      url
      priority
      asset_type
    }
    block(where: { id: { eq: $blockID } }) {
      id
      logo
      name
      year_built
      planned_hand_over_date
      uom_masterByID {
        value: id
        label: name
      }
      addressByID {
        door_no
        street_1
        street_2
        landmark
        area
        city
        state
        country
        zipcode
        latitude
        longitude
        alternative_mobile_no_country_code
        alternative_mobile_no
        mobile_no_country_code
        mobile_no
        email_id
      }
      property_id
      propertyByID {
        id
        companyByID {
          value: id
          label: name
        }
        property_groupByID {
          value: id
          label: group_name
        }
        area_metric
        uom_masterByID {
          name
        }
        addressByID {
          door_no
          street_1
          street_2
          landmark
          area
          city
          state
          country
          zipcode
          latitude
          longitude
        }
        business_phone_country_code
        business_phone
        mobile_country_code
        mobile_phone
        website
        email
        property_hierarchyByID {
          value: id
          label: name
          is_block
          is_floor
          is_unit
          is_multi_unit
          is_customisable
          property_hierarchy_labelByID {
            id
            floor_label
            block_label
          }
        }
      }
      is_active
      description
      property_id
      use_floor_hierarchy
      carpet_area
      total_area
      block_purpose
      revenue_type
      area_metric
      block_type_masterByID {
        value: id
        label: name
      }
    }
  }
`;

export const GET_FLOOR_DATA_EDIT = `
query($floorId:String){
  floor(where:{id:{eq:$floorId}}){
   name
   is_active
     property_id
     block_id
    id
    description
    contact{
      latitude
      longitude
    }
     floor_purpose
  }
  assets( where: {reference_id: { eq: $floorId } is_active:{eq:true}}){
    url
    id
    assets_master_type
    priority
  }
}`;

export const GET_PROPERTY_FORM_MASTERS = `
query GET_PROPERTY_FORM_MASTER($userProfileID:String) {
  client_user_roles(
    where: {
      is_active: { eq: true }
      user_profile_id: { eq: $userProfileID }
      roles: { name: { eq: "Manager" } }
    }
  ) {
    id
    roles {
      id
      name
    }
    company {
      id
      name
    }
  }
  property_group_master(where: { is_active: { eq: true } }) {
    value: id
    label: group_name
  }
  country_master(where: { is_active: { eq: true } }) {
    value: id
    label: country_name
    state_master(where: { is_active: { eq: true } }) {
      value: id
      label: state_name
      cities_master(where: { is_active: { eq: true } }) {
        value: id
        label: city_name
        city_area_master(where: { is_active: { eq: true } }) {
          value: id
          label: area_name
          zipcode
        }
      }
    }
  }
}`;

export const GET_FILTER_DATA_FOR_PROPERTY_FILTER = `
query GET_FILTER_DATA_FOR_PROPERTY_FILTER($userProfileID: String , $clientID:String) {
  client_user_roles(
    where: {
      is_active: { eq: true }
      user_profile_id: { eq: $userProfileID }
      roles: { name: { eq: "Manager" } }
      client:{eq:$clientID}
    }
    offset: 0
    limit: 1000
  ) {
    id
    roles {
      id
      name
    }
    company:company_master {
      id
      name
      company_no
      uom
      payment_period
      currency_id
      currency{
        id
        name
      }
      company_addressByID {
        id
        mobile_no_country_code
        mobile_no
        alternative_mobile_no_country_code
        alternative_mobile_no
        email_id
        website
      }
      propertyByID(offset:0 limit:10000){
          id
          name
      }
    }
  }
}`;

export const GET_PROPERTY_TYPE = (
  offset,
  limit,
  searchText,
  { company_id, client_id }
) => gql`
query GET_PROPERTY_TYPE{
  count:property_group_master(
    where:{
      group_name:{iregex:"${searchText}"}
      ${company_id ? `company_id: {eq: ${company_id}}` : ``}
      ${client_id ? `client: {eq: "${client_id}"}` : ``}
      is_active: { eq: true }
    }
  ){
    count:count_id
  }
  
  property_group_master(
    where:{
      group_name:{iregex:"${searchText}"}
      ${company_id ? `company_id: {eq: ${company_id}}` : ``}
      ${client_id ? `client: {eq: "${client_id}"}` : ``}
      is_active: { eq: true }
      is_delete: { eq: false }
    }
    offset:${offset}
    limit:${limit}
  ) {
    value: id
    label: group_name
  }
}`;

export const GET_PROPERTY_DETAILS_BY_ID_FOR_EDIT = `
query GET_PROPERTY_DETAILS_BY_ID_FOR_EDIT($propertyID: ID) {
  property(where: { id: { eq: $propertyID } }) {
    id
    propertyName: name
    propertyDescription: description
    totalUnit: total_units
    measurementUnit: area_metric
    carpetArea: carpet_area
    totalArea: total_area
    company(where: { is_active: { eq: true } }) {
      label: name
      value: id
    }
    property_purpose
    revenue_type
    propertyType: property_group(where: { is_active: { eq: true } }) {
      label: group_name
      value: id
    }
    is_active
    isPropertyContainsBlock: is_block
    isPropertyContainsFloor: is_floor
    address {
      addressLineOne: street_1
      addressLineTwo: street_2
      addressLineThree: street_3
      city
      district
      state
      zipcode
      postOfficeBox: po_box
      country
      latitude
      longitude
      emailAddress: email_id
    }
    cities_master(where: { is_active: { eq: true } }) {
      label: city_name
      value: id
      city_area_master(where: { is_active: { eq: true } }) {
        label: area_name
        value: id
        zipcode
      }
      country_master(where: { is_active: { eq: true } }) {
        label: country_name
        value: id
        state_master(where: { is_active: { eq: true } }) {
          label: state_name
          value: id
          cities_master(where: { is_active: { eq: true } }) {
            label: city_name
            value: id
            city_area_master(where: { is_active: { eq: true } }) {
              label: area_name
              value: id
              zipcode
            }
          }
        }
      }
      state_master(where: { is_active: { eq: true } }) {
        label: state_name
        value: id
        cities_master(where: { is_active: { eq: true } }) {
          label: city_name
          value: id
          city_area_master(where: { is_active: { eq: true } }) {
            label: area_name
            value: id
            zipcode
          }
        }
      }
    }
    city_area_master(where: { is_active: { eq: true } }) {
      label: area_name
      value: id
      zipcode
    }
    businessPhoneCode: business_phone_country_code
    businessPhone: business_phone
    mobilePhoneCode: mobile_country_code
    mobilePhone: mobile_phone
    website
    emailAddress: email
    Municipality: municipality_authority
    Electricity: electrical_water_authority
    Utilties: util_electricity_connection
    Natural: util_gas_connection
    Water: util_water_connection
    Geographic: smart_geographic_addressing
    Unit: unit_creation
  }
  assets(
    where: { reference_id: { eq: $propertyID }, is_active: { eq: true } }
  ) {
    id
    reference_id
    url
    priority
    assets_master_type
  }
}`;

export const GET_UNIT_FORM_MASTER = `
query GET_UNIT_FORM_MASTER {
  unit_type_master(where: { is_active: { eq: true } }) {
    value: id
    label: name
  }
}`;
export let GET_UNIT_FORM_MASTERS = (
  offset,
  limit,
  searchText,
  company_id = []
) => gql`
query GET_UNIT_FORM_MASTERS{
  count: unit_type_master(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
    }
  ) {
    count:count_id
  }
  unit_type_master(
      where:{
        name:{iregex:"${searchText}"}
        is_active:{eq:true}
      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
      	value:id
      }
}
`;

export const GET_PARENT_UNITS_BY_FLOOR_ID = `
query GET_PARENT_UNITS_BY_FLOOR_ID($floorId: ID) {
  floor(where: { id: { eq: $floorId }, is_active: { eq: true } }) {
    id
    property {
      label: area_metric
      value: area_metric
    }
    unit(where: { is_room: { eq: false } }) {
      value: id
      label: name
      revenue_type
      unit_type(where: { is_active: { eq: true } }) {
        value: id
        label: name
      }
      address(where: { is_active: { eq: true } }) {
        latitude
        longitude
      }
      unit_purpose
    }
  }
}`;

export const GET_UNIT_DETAILS_BY_ID_FOR_EDIT = `
query GET_UNIT_DETAILS_BY_ID_FOR_EDIT($unitId: ID) {
  unit(where: { id: { eq: $unitId } }) {
    id
    is_room
    revenue_type
    parentUnit: unit(find: "parents", where: { is_active: { eq: true } }) {
      value: id
      label: name
      unit_purpose
      revenue_type
      unit_type
      unit_typeByID {
        value: id
        label: name
      }
      address_id
      addressByID {
        latitude
        longitude
      }
    }
    roomUnitName: name
    roomUnitDescription: description
    property{
      measurementUnit:  area_metric
    }
    measurementUnit: area_metric
    carpetArea: carpet_area
    totalArea: total_area
    furnishingType: furnishing
    status
    unit_purpose
    unit_type
    unit_typeByID {
      value: id
      label: name
    }
    address_id
    addressByID {
      latitude
      longitude
    }
    is_active
  }
  assets(where: { reference_id: { eq: $unitId }, is_active: { eq: true } }) {
    id
    reference_id
    url
    priority
    assets_master_type
  }
}
`;
// unit details
export const GET_UNIT_DETAILS = `
query($unitId:String){
  unit(where:{id:{eq:$unitId}, }){
    name
    unit_no
   id
    description
    floor{
      id
      floor_no
      name
      description
    }
     block{
       id
    block_no
      name
      description
    }
    contact{
      latitude
      longitude
    }
    property{
      id
      company:company_master{
        id
        name
      }
      website
      mobile_phone
      business_phone
      email
      name
      description
      address{
        street_1
        street_2
        street_3
        district
        state
        country
        zipcode
      }
    }

     furnishing
  }}`;

//get assests for anything
export const GET_ASSESTS = `
  query($id:String){
  assets(where:{reference_id:$id}){
    url
    priority
    id
  }
}
  `;
//resident
export const GET_RESIDENT_BY_UNIT = `
query($unitId: String) {
    count: owner_customer(
    where: {
      unit_id: { eq: $unitId }
      is_active: { eq: true }
      tenant_profile_id: { is_null: false }
      tenant_profile: { first_name: { iregex: $searchText } }
     }

     offset:$offset
      limit:$limit
  ) {
    count: count_id
  }
  resident:owner_customer(
    where: {
      unit_id: { eq: $unitId }
      is_active: { eq: true }
      tenant_profile_id: { is_null: false }
      tenant_profile: {
        first_name: { iregex: $searchText }
       }
    }

     offset:$offset
      limit:$limit
  ) {
    id
    owner_profile_id
    user_profiles:tenant_profile {
      id
      first_name
      last_name
      mobile_no
    }
    from
    to
  }
}


`;
//owner
export const GET_OWNERS_BY_UNIT = `
query($unitId: String) {
  count: owner_customer(
    where: {
      unit_id: { eq: $unitId }
      is_active: { eq: true }
      owner_profile_id: { is_null: false }
      owner_profile: { first_name: { iregex: $searchText } }
    }
    offset: $offset
    limit: $limit
  ) {
    count: count_id
  }
  owner: owner_customer(
    where: {
      unit_id: { eq: $unitId }
      is_active: { eq: true }
      owner_profile: { first_name: { iregex: $searchText } }
      owner_profile_id: { is_null: false }
    }

    offset: $offset
    limit: $limit
  ) {
    id
    owner_profile_id
   user_profiles: owner_profile {
      id
      first_name
      last_name
      mobile_no
    }
    from
    to
  }
}

`;
//unit rent breakup

export const GET_UNIT_RENT_BREAKUP = `
query($unitId: String , $clientID:String) {
  count: pricing_table(
    where: { unit_id: { eq: $unitId }, is_active: { eq: true }
    pricing_component: { name: { iregex: $searchText } },client:$clientID
  }

    offset: $offset
    limit: $limit
  ) {
    count: count_id
  }
  unit_rental_breakup: pricing_table(
    where: { unit_id: { eq: $unitId }, is_active: { eq: true }
    pricing_component: { name: { iregex: $searchText } },client:$clientID
  }

    offset: $offset
    limit: $limit
  ) {
    value
    revenue_type
    is_chargeable
    currency {
      name
      code
      symbol
    }
    value_basis_type
    vat_group_master {
      group_name
    }
    rental_breakup_master:pricing_componentByID{
      id
      description: name
    }
    display_percentage:taxable
  }
}

`;
// unit vacancy period
export const GET_UNIT_VACANCY_PERIOD = `
query($unitId:String , $clientID:String){
    count: unit_vacancy_period(
    where: { unit_id: { eq: $unitId }, is_active: { eq: true }
    reference_no: { iregex: $searchText },client:$clientID
  }
    offset: $offset
    limit: $limit
  ) {
    count: count_id
  }
  unit_vacancy_period(
    where:{unit_id:{eq:$unitId},is_active: { eq: true }
    reference_no: { iregex: $searchText },client:$clientID

  }

      offset:$offset
      limit:$limit
    ){
     id
    unit_id
    company_name
    logo
    available_from
    available_to
    unit_no
    name
    agreement_no
    first_name
    last_name
    status
    mobile_no
    mobile_no_country_code
  }}
`;
//get unit requset

export const GET_UNIT_REQUSET = `
query {
  count: request(
    where: {
      unit_id: { eq: $unitId }
      or: {
        request_no: { iregex: $searchText }
        subject: { iregex: $searchText }
      }
    }
    offset: $offset
    limit: $limit
  ) {
    count: count_id
  }
  request(
    where: {
      unit_id: $unitId
      or: {
        request_no: { iregex: $searchText }
        subject: { iregex: $searchText }
      }
    }

    offset: $offset
    limit: $limit
  ) {
    unit_id
    request_no
    request_type
    request_status
    raised_on
    description: subject
  }
}

`;
//unit amenities breakup

export const GET_UNIT_AMENITIES_BREAKUP = `
query($unitId:String , $clientID:String){
   count: unit_amenities_breakup(
    where: {
      unit_id: { eq: $unitId }, is_active: { eq: true }
     name: { iregex: $searchText },client:$clientID
    }

    offset: $offset
    limit: $limit
  ) {
    count: count_id
  }
  unit_amenities_breakup(
    where:{unit_id:{eq:$unitId},is_active: { eq: true }
   name: { iregex: $searchText },client:$clientID
  }
     name: { iregex: $searchText }
     offset:$offset
      limit:$limit
    ){
    amenities_type
    is_chargeable
    name
    notes
  }}
`;
// unit utilities
export const GET_UNIT_UTILITIES = `
query($unitId:String){
  unit_utilties(where:{client:$clientID ,unit_id:{eq:$unitId},is_active: { eq: true }}){
  utilites
    meter_serial_no
  meter_point_reference
   updated_at
    description
  }}
`;
// rooms
export const GET_ROOMS = `
query($unitId:String , $clientID:String){
  count: unit_room(
    where: { unit_id: { eq: $unitId }, is_active: { eq: true }
    name: { iregex: $searchText },client:$clientID
  }
    offset: $offset
    limit: $limit
  ) {
    count: count_id
  }
   unit_room(
     where:{unit_id:{eq:$unitId}
     name: { iregex: $searchText }
     client:$clientID
    }

      offset:$offset
      limit:$limit
     ){
   id
    name
    description
    unit_room_no
    unit{
      status
    }
  }
  }

`;
// security request
export const GET_SECURITY_REQUEST = `
query($unitId:String , $clientID:String){
    security_request(where:{client:$clientID,unit_id:{eq:$unitId},is_active: { eq: true }}){
    name
    description
    request_no
    status
    raised_no:created_at
    request_type
  }
  }
`;

export const GET_ALL_SECURITY_REQUEST = `
query($unitId: [String!],$offset:Int,$limit:Int,$searchText: String) {
  count: security_request(
    where: {
        request_no: { iregex: $searchText },
        unit_id: { in: $unitId }, is_active: { eq: true } }
  ) {
    count: count_id
  }

  security_request(
    where: {  request_no: { iregex: $searchText },unit_id: { in: $unitId }, is_active: { eq: true } }
    offset:$offset
    limit:$limit

  ) {
    name
    description
    request_no
    status
    raised_no: created_at
    request_type
  }
}
`;
// UNIT OWNER

export const GET_UNIT_OWNER = `
query($unitId:String){
   owner_customer(
     where:{client:{eq:$clientID} ,unit_id:{eq:$unitId}, is_active: { eq: true }}
     offset:$offset
      limit:$limit
     ){
     from
    to
    user_profile{
      first_name
      last_name
      email_id
      mobile_no

    }
  }
    }
`;
//UNIT AGREEMENTS

export const GET_UNIT_AGREEMENT = `
query($unitId: String , $clientID:String) {
  count: agreement_units(
    where: {
      unit_id: { eq: $unitId }
      is_active: { eq: true }
      client:{eq:$clientID}
      agreement: { agreement_no: { iregex: $searchText } }
    }
    offset: $offset
    limit: $limit
  ) {
    count: count_id
  }
  agreement_units(
    where: {
      unit_id: { eq: $unitId }
      is_active: { eq: true }
      client:{eq:$clientID}
      agreement: { agreement_no: { iregex: $searchText } }
    }
    offset: $offset
    limit: $limit
  ) {
    agreement {
      id
      lease_start_date
      lease_end_date
      agreement_no
      contact {
        email_id
        mobile_no
        mobile_no_country_code
        first_name
        last_name
      }
    }
  }
}

`;

export const GET_ALL_UNIT_AGREEMENT = `
query($unitId: [String!],$offset:Int,$limit:Int,$searchText: String){
    count: agreement_units(
    where: { agreement: {agreement_no: { iregex: $searchText } },unit_id: { in: $unitId }, is_active: { eq: true } }
  ) {
    count: count_id
  }
     agreement_units(where:{agreement: {agreement_no: { iregex: $searchText } },unit_id:{in:$unitId},is_active: { eq: true }}
        offset:$offset
    limit:$limit
      ){
      agreement{
        agreement_no
        id
         lease_start_date
      lease_end_date

         contact{
          email_id
          mobile_no
          mobile_no_country_code
           first_name
          last_name
        }

      }
  }
  }
`;

//UNIT INVOICE

export const GET_UNIT_INVOICE = `
query($unitId: String , $clientID:String) {
    count: invoice_units(
    where: {
      unit_id: { eq: $unitId }
      is_active: { eq: true }
      client:{eq:$clientID}
      invoice: { invoice_no: { iregex: $searchText } }
    }
    offset: $offset
    limit: $limit
  ) {
    count: count_id
  }
  invoice_units(
    where: { client:{eq:$clientID}, unit_id: { eq: $unitId }, is_active: { eq: true }
   invoice: { invoice_no: { iregex: $searchText } }
  }
    offset: $offset
    limit: $limit
  ) {
    invoice {
      invoice_no
      id
      lease_start_date
      payment_due_date
      invoice_due_amount
      payment_status
      invoice_date
      invoice_master {
        type
      }
      invoice_total_amount
      is_active
    }
  }
}


`;
export const GET_ALL_UNIT_INVOICE_OLD = `
query($unitId: [String!],$offset:Int,$limit:Int,$searchText: String){
  count: agreement_units(
    where: { invoice: {invoice_no: { iregex: $searchText }},unit_id: { in: $unitId }, is_active: { eq: true } }
  ) {
    count: count_id
  }
     invoice_units(where:{ invoice: {invoice_no: { iregex: $searchText }},unit_id:{in:$unitId},is_active: { eq: true }}
       offset:$offset
    limit:$limit
      ){
      invoice{
        invoice_no
        invoice_master{
          type
        }
        invoice_total_amount
        is_active
      }
  }
  }
`;
export const SEARCH_BLOCK = `
query($search: String) {
  block(
    where: {
      is_active:{eq:true}
      or: {
        name: { ilike: $search }
        block_no: { ilike: $search }
      }
    }
  ) {
    id
      is_active
      property_id
      block_no
      name
      area_metric
      carpet_area
      use_floor_hierarchy
       total_area
  }
}`;

export const GET_USER_PROFILE_ID_BY_USER_ID = `
query GET_USER_PROFILE_ID_BY_USER_ID($userID: String) {
  user_profiles(where: { id: { eq: $userID }, is_active: { eq: true } }) {
    id
    first_name
    user_id
    last_name
    get_assets_url_id:image_url
    email_id
  }
}`;

//unit search
export const GET_UNIT_SEARCH = `
query($search: String) {
  unit(where: { name: { ilike: $search } }) {
    id
    name
    unit_no
    parent_unit_id
    parent_unit: unit(find: "parents") {
      id
      name
    }
    area_metric
    total_area
    furnishing
    unit_purpose
    status

    is_active
    unit_type_master {
      type:name
    }
  }
}

`;

export const leadDetails = `query($id:String){
  roleList: roles(where:{is_view:true}){
      name
      id
  },
  leadDetails:lead(where: { id: { eq: $id } } client: { eq: $client }) {
      id
      is_active
      name
      unit_usage
      grace_period_value
      lead_no
      lead_units{
      count_id
    }
    shortlisted:lead_units{
      id
      unit_id
    }
       company{
        value: id
         label:name
      currency_id
      currency{
        symbol
        value:id
        label:symbol
      }
      uom
    }
      lead_owner {
        id
         first_name
        last_name
      }
      budget_range
      required_area
      lease_start_date
      currency {
        symbol
      }
       requested_at: created_at
      requested_by: created_byByID {
        first_name
        last_name
      }
      lease_end_date
      lead_type_master {
        id
        name
      }
      value
      notes
      value_type
      revenue_type
      status
      closed_on
    lead_source
    subject
    urgent
    grace_period_value
    grace_period_type
    lease_period
    unit_usage
      contact:prospective {
        id
        email_id
        mobile_no
        first_name
        last_name
        mobile_no_country_code
        fax_no
        phone
        alternative_mobile
        facebook
        linkedin
        twitter
        street_1
        street_2
        street_3
        landmark
        district
        city
        state
        country
        zipcode
        longitude
        latitude
        image_url
      }
  },
  listTodo: lead_checklist(where:{lead_id:$id ,is_active:true},client: { eq: $client }  order_by: { created_at: desc }){
      id
      name
      description
      is_done
      created_by
  },
  listAactivity: lead_activities(where:{lead_id:{eq:$id} is_active:{eq:true}}, client: { eq: $client } order_by: { created_at: desc }){
      id
      lead{
        lead_no
      }
      activity_sub_category{
        id
        sub_category:name
      }
      activity_category{
        id
          activity_category_master{
            id
            name
          }
        activity_category: name
      }
      created_at
      created_by
      created_by_details: created_by {
        last_name
        first_name
        id
      }
      notes
      remarks
      email_id
      priority
      type
      responsible
      lead_activitiy_master{
        id
        type:name
      }
      status
      date_time
      phone_number
      location
  }
    quotationlist: quotation(
    where: { lead_id: { eq: $id }, is_active: { eq: true } }
    order_by: { created_at: desc }
  ) {
    id
   quotationNo: quotation_no
    units: quotation_units {
      count: count_id
    }
    value: total_amount
    periods: lease_period
    duration: lease_duration
    quoteExpireOn: quotation_expiry_date,
    status:quote_status
  }
}`;

export const getleadProfilepic = `query($id:String){
  assets(where:{reference_id:$id priority:1 assets_master_type:2}){
    url
    id
  }
}`;
export const GET_COMPANY_ID_BY_USER_PROFILE_ID_AND_ROLE_NAME = `
query GET_COMPANY_ID_BY_USER_PROFILE_ID_AND_ROLE_NAME(
  $userProfileID: String
  $roleName: String
) {
  client_user_roles(
    where: {
      is_active: { eq: true }
      user_profile_id: { eq: $userProfileID }
      roles: { name: { eq: $roleName } }
    }
  ) {
    id
    roles_id
    roles {
      id
      name
    }
    company_id
    company {
      id
      name
    }
  }
}`;

export const GET_AGREEMENT_UNIT_ACCESS_BY_COMPANY_ID = `
query GET_AGREEMENT_UNIT_ACCESS_BY_COMPANY_ID(
  $companyID: [Int]
  $status: [String]
) {
  totalCountData: agreement_access(
    where: {
      company_id: { in: $companyID }
      is_active: { eq: true }
      request_status: { in: $status }
    }
  ) {
    totalCount: count_id
  }
  agreement_access(
    where: {
      company_id: { in: $companyID }
      is_active: { eq: true }
      request_status: { in: $status }
    }
    order_by: { updated_at: desc }
    offset: 0
    limit: 1000
  ) {
    id
    company_id
    property_id
    unit_id
    account {
      id
      agreement {
        agreement_no
      }
    }
    request_type
    request_status
    remarks
    name
    mobile_country_code
    mobile_no
    email_id
    created_at
    created_by {
      id
      first_name
      last_name
    }
    approved_on
    approved_by: approved_byByID {
      id
      first_name
      last_name
    }
    property {
      id
      name
    }
    unit(where: { is_active: { eq: true } }) {
      id
      name
      unit_no
      property(where: { is_active: { eq: true } }) {
        id
        name
        property_no
      }
      block(where: { is_active: { eq: true } }) {
        id
        name
        block_no
        property(where: { is_active: { eq: true } }) {
          id
          name
          property_no
        }
      }
    }
  }
}`;

export const GET_AGREEMENT_UNIT_ACCESS_BY_ID = `
query GET_AGREEMENT_UNIT_ACCESS_BY_ID($agreementAccessID: ID) {
  agreement_access(
    where: { id: { eq: $agreementAccessID }, is_active: { eq: true } }
  ) {
    id
    request_type
    created_at
    created_by(where: { is_active: { eq: true } }) {
      id
      first_name
      last_name
    }
    approved_on
    approved_by(where: { is_active: { eq: true } }) {
      id
      first_name
      last_name
    }
    request_status
    remarks
    unit(where: { is_active: { eq: true } }) {
      id
      name
      unit_no
      property_id
      property(where: { is_active: { eq: true } }) {
        id
        name
        property_no
      }
      block(where: { is_active: { eq: true } }) {
        id
        name
        block_no
        property(where: { is_active: { eq: true } }) {
          id
          name
          property_no
        }
      }
    }
    name
    mobile_country_code
    mobile_no
    email_id
    agreement_id
    account {
      id
      agreement {
        agreement_no
      }
    }
    account_id
  }
}`;
export const GETCATEGORY = `
 query($activity_id:String){
  activity_category_master(where:{lead_activity_master_id:$activity_id}){
    value:id
    label:name
  }
}`;
export const GETSUBCATEGORY = `
query($master_id:String){
  activity_sub_category_master(where:{activity_category_master_id:$master_id}){
    value:id
    label:name
  }
}`;
export const SEARCHLEADBYOWNER = `
query($search: String,$ownerId:String) {
  lead(
    where: {
      lead_owner: $ownerId
      contact:{
        or: {
        first_name: { ilike: $search }
        email_id: { ilike: $search }
        mobile_no: { ilike: $search }
      }
      }
    }
  ) {
    lead_no
    created_by
    id
    created_at
    contact {
      id
      first_name
      last_name
      email_id
     mobile_no
      get_assets_url_id

    }
  }
}

`;
export const getLeadActivityDetails = `
query($id:ID){
  lead_activities(where:{id:{eq:$id}}){
    id
    activity_category{
      value:id
      label:name
    }
    activity_sub_category{
      value:id
      label:name
    }
    email_id
    location
    date_time
    priority
    notes
    lead_activity_master{
      id
      name
    }
    phone_number
    type
  }
}`;

export const UPDATE_TRANSFER_LEAD = `mutation UpdateLeadByLeadID($leadID: String, $updatePayload: leadInput) {
  lead(
    where: { id: { eq: $leadID }}
    update: $updatePayload
  ) {
    id
    transfer_remarks
  }
}`;

export const LEAD_NAME_OPTIONS = `query($id:String){
  client_user_roles(where:{roles_id:$id}){
     user_profile{
      id
      first_name
      last_name
    }
  }
}`;

export const CLOSE_LEAD = `mutation UpdateLeadByLeadID($leadID: ID, $updatePayload: leadInput) {
  lead(
    where: { id: { eq: $leadID }, is_active: { eq: true } }
    update: $updatePayload
  ) {
    id

  }
}`;

export const CREATE_TODO = `mutation($insert: [lead_checklistOutput!]) {
  lead_checklist(insert: $insert ) {
   name
    description
    is_active
    created_by
  }
}`;

export const UPDATE_TODO = `mutation($leadChecklistID: ID, $leadChecklistUpdateData: lead_checklistInput) {
  lead_checklist(
    where: { id: { eq: $leadChecklistID } }
    update: $leadChecklistUpdateData
  ) {
    id
  }
}`;

export const LIST_COMPANY = `
query($ID:Int) {
  lead(where:{company_id:{eq:$ID}}) {
    id
    name
    contact{
      id
      first_name
    }
    lead_owner{
      id
     first_name
    }
  }
}`;
export let LIST_COMPANY_CONTACT = (offset, limit, searchText, obj = "") => gql`
query GET_MASTERS_FOR_CREATING_BLOCK{
  count: contact(
    where:{
      ${searchText?.length > 0 ? `first_name:{iregex:"${searchText}"}` : ""}
      is_active:{eq:true}
      company_id:{eq:"${obj?.company_id}"}
      client:{eq:"${obj?.clientId}"}
    }
  ) {
    count:count_id
  }
 contact(
        where:{
          ${searchText?.length > 0 ? `first_name:{iregex:"${searchText}"}` : ""}
       company_id:{eq:"${obj?.company_id}"}
        is_active:{eq:true}
        client:{eq:"${obj?.clientId}"}
      }
       offset:${offset}
      limit:${limit}
     ){
      value:email_id
      label:first_name
    }
}
`;
export let LIST_COMPANY_OWNER = (offset, limit, searchText, obj) => gql`
query GET_MASTERS_FOR_CREATING_BLOCK{
 count:lead_owners(
        where:{
        label:{iregex:"${searchText}"}
        company_id:{eq:"${obj?.company_id}"}
         client:{eq:"${obj?.clientId}"}
      }
     ) {
    count:count_id
  }
      lead_owners(
        where:{
        label:{iregex:"${searchText}"}
        company_id:{eq:"${obj?.company_id}"}
        client:{eq:"${obj?.clientId}"}
      }
      offset:${offset}
      limit:${limit}
     ){
      value
      label
     }
}
`;

export const EMAIL_VADLIDATE = `
query($search: String) {
  contact(where:{email_id:{eq:$search}})
   {
    id
   }
 }`;

export const PHONE_VADLIDATE = `
 query($search: String) {
  contact(where:{mobile_no:{eq:$search}})
   {
    id
   }
 }`;

export const GET_MAINTENANCE_CATEGORY_MASTER = `
query {
  maintenance_category_master {
    id
    type:name
  }
}`;

export const GET_MAINTENANCE_SUB_CATEGORY_MASTER_BY_MAINTENANCE_CATEGORY_ID = `
query($maintenanceCategoryId: Int) {
  maintenance_sub_category_master(
    where: { maintenance_category_id: { eq: $maintenanceCategoryId } }
  ) {
    id
     type:name
  }
}
`;

export const SELECT_PROPERTY = `
query($id:Int){
  property(where:{company_id:{eq:$id}}){
    name
    id
  }
}`;

export const SELECT_UNIT = `
 query($id:String){
  unit(where:{property_id:{eq:$id}}){
    name
    id
  }
}`;

export const GETPROFILEDETAILS = `
query Profile($userID: String) {
  user_profiles(where: { user_id: { eq: $userID } }) {
    id
    first_name
    mobile_no_country_code
    last_name
    mobile_no
    alternative_mobile_no
    contact_id
  }
}
`;
export const GET_GENERAL_REQUEST = `
query($generalRequestNo: ID) {
  delivery_inspection:agreement_inspection(where:{
    agreement_inspection_request:{request_type:"delivery-order"}
    general_request_id:{ eq: $referenceId }}
  ){
    id
  }
  table: general_request(where: { id: { eq: $generalRequestNo } }) {
    id
    is_active
    mobile_country_code
    request_no:general_request_no
    catagory: general_category_master {
      id
      type: name
    }
    subcatagory: general_sub_category_master {
      id
      type: name
    }
    subject: subject
    description: description
    problemsince: problem_since
    raisedon: raised_on
    profile:created_by{
      first_name
      last_name
    }
    unit: general_request_units {
      id
      company_master {
        id
        name
      }
      property {
        id
        name
      }
      unit {
        name
        id
      }
    }
    projectByID{
      id
      project_no
      title
    }
    contractByID{
      id
      contract_no
    }
    prefferedtime: preferred_time
    name: contact_name
    mobile: contact_mobile
    alternative: contact_alternative_mobile
    status: general_status(
      order_by: { updated_at: desc }
    ) {
      id
      created_at
      created_by
      remarks
      type
      notes
      master: general_status_master {
        id
        type: name
      }
    }
  }
}`;

export const GET_MAINTANCE_REQUEST = `

query($maintenanceRequestNo: ID, $referenceId: String) {
  table:maintenance_request(
    where: { id: { eq: $maintenanceRequestNo } }
  ) {
    registered_worker: domestic_helper_master {
      value: id
      label: name
      mobile_no
      mobile_country_code
      profession: profession_master {
      value: id
      label: name
      }
    }
    worker_contact_name
    projectByID{
      id
      project_no
      title
    }
    contractByID{
      id
      contract_no
    }
    profession: profession_master {
    value: id
    label: name
    }
    worker_contact_no
    worker_contact_country_code
    vendor_email_id
    alternative_company
    location
    service_charges
    material_charges
    total_paid
    paid_to
    payment_mode
    payment_date
    invoice_no
    invoice_date
    assigned_date
    work_completion
    id
    is_active
    mobile_country_code
    priority
    request_no:maintenance_request_no
   catagory: maintenance_category_master {
      id
      type:name
    }
    subcatagory:maintenance_sub_category_master {
      id
     type:name
    }
    subject:subject
    description:description
   problemsince:problem_since
    raisedon:raised_on
    contact_name
    slot_id
    work_order_ref_no
    work_order_date
    work_order_status
    vendor_ref:vendor_masterByID {
      label:name
      value:id
    }
    location{
      id
      name
    }
    inspection_item_mapping{
      id
      name
      manufacturer{
        id
        name
      }
      model_number
      serial_number
      item_condition
      assets
    }
    unit:maintenance_request_units {
      id
      company_master{
        id
        name
      }
      property{
        id
        name
      }
      unit {
        name
        id
        unit_no
      }
    }
    prefferedtime:preferred_time
    name:contact_name
    mobile:contact_mobile
    alternative:contact_alternative_mobile
    status:maintenance_status(
      order_by: { updated_at: desc }
    ){
      id
      created_at
      updated_by
      remarks
      type
      closed_on
      master:maintenance_status_master {
        id
        type:name
      }
      notes
    }
  }
  images:maintenance_request_assets(where: { 
    maintenance_request_id: { eq: $referenceId }
    ,is_active:true}
  ) {
    id
    priority
    url
    file_meta
  }
  service_inspection:agreement_inspection(where:{
    agreement_inspection_request:{request_type:"service"}
    maintenance_id:{ eq: $referenceId }}
  ){
    id
  }
  
   delivery_inspection:agreement_inspection(where:{
    agreement_inspection_request:{request_type:"delivery-order"}
    maintenance_id:{ eq: $referenceId }}
  ){
    id
  }
  general_inspection:agreement_inspection(where:{
    agreement_inspection_request:{request_type:"general"}
    maintenance_id:{ eq: $referenceId }}
  ){
    id
  }
}`;

export const GET_SEARCH_DATA_PROPERTY_FINDER = `
query GET_FILTER_DATA_PROPERTY_FINDER {
  amenities_type_master(where: { is_active: { eq: true } }) {
    id
    label: amenities_name
  }

}
`;
export const GETACTIVITYBYID = `
query ($id:ID){
  lead_activities(
    where: { id: { eq: $id } }
  ) {
      id
      lead{
        lead_no
      }
      activity_sub_category{
        id
        sub_category:name
      }
      activity_category{
        id
          activity_category_master{
            id
            name
          }
        activity_category: name
      }
      created_at
      created_by
      created_by_details: created_by {
        last_name
        first_name
        id
      }
      notes
      remarks
      email_id
      priority
      type
      responsible
      lead_activitiy_master{
        id
        type:name
      }
      status
      date_time
      phone_number
      location
  }
}
`;

export const ROLES_GET = `
query{
  roles(where:{is_active:true}){
    value:id
    label:name
  }
}`;

export const GET_LIST_ROLES = `
query ($ID: [String]) {
  roles(where: { id: { in: $ID } }) {
   name
  }
}`;
// residnet accounts

export const GET_RESIDNET_ACCOUNTS = `
query($agreementId: [String],$searchText: String) {
  count: agreement(
    where: {id: { in: $agreementId }, is_active: { eq: true } }
  ) {
    count: count_id
  }
  agreement(where:{ id: { in: $agreementId }, is_active: true }) {
    id
    account_id
    account{
      name
      account_no
    }
    contact {
      first_name
      last_name
    }
    company:company_master{
      id
      name
    }
  }
}
`;
//OTHER RESIDNETS

export const GET_OTHER_RESIDENTS = `
query($unitId:[String],$searchText: String){
  count: owner_customer(
    where: { tenant_profileByID: {first_name: { iregex: $searchText }},unit_id: { not_in: $unitId }, is_active: { eq: true } }
  ) {
    count: count_id
  }
  owner_customer(where:{tenant_profileByID: {first_name: { iregex: $searchText }},unit_id:{not_in:$unitId},is_active:true}){
     tenant_profileByID{
      first_name
      last_name
      email_id
      mobile_no
      mobile_no_country_code
    }
  }
}
`;

export const GET_ALL_UNIT_INVOICE = `query ($unitId:[String],$offset:Int,$limit:Int){
  invoice_units: owner_customer(where:{unit_id:{in:$unitId},is_active:true}){
    id
    unit{
        id
        unit_no
    }
    tenant_profileByID{
        first_name
        last_name
        mobile_no_country_code
        mobile_no
        email_id
    }
  }
}`;
export let GET_PROPERTY_FORM_MASTER = (
  offset,
  limit,
  searchText,
  userProfileID,
  type
) => gql`
query GET_PROPERTY_BASED_ON_COMPANY{
  count: ${type}(
    where:{
      ${
        type === "client_user_roles"
          ? `
      roles: { name: { eq: "Manager" } }
         user_profile_id: { eq: "${userProfileID}"}
      `
          : ""
      }
      ${
        type === "property_group_master"
          ? `

      group_name:{iregex:"${searchText}"}
      `
          : ""
      }

      is_active:{eq:true}

    }
    offset:${offset}
    limit:${limit}
  ) {
    count:count_id
  }
  ${
    type === "client_user_roles"
      ? `
  client_user_roles(
      where:{
      roles: { name: { eq: "Manager" } }
      is_active:{eq:true}
      user_profile_id: { eq: "${userProfileID}"}
      }

    ){
       id
    roles {
      id
      name
    }
    company
      where: {
      is_active: { eq: true }
      name:{iregex:"${searchText}"}
    }
       offset:${offset}
       limit:${limit}
      ) {
        value:id
        label:name
      }
      }
  `
      : ""
  }
  ${
    type === "property_group_master"
      ? `
    property_group_master(
      where: { is_active: { eq: true }
      group_name:{iregex:"${searchText}"}
    }
       offset:${offset}
       limit:${limit}
      ) {
    value: id
    label: group_name

  }
  `
      : ""
  }
  ${
    type === "property_type"
      ? `
  property_group_master(
    where: { is_active: { eq: true }
    group_name:{iregex:"${searchText}"}
  }
     offset:${offset}
     limit:${limit}
    ) {
  value: id
  label: group_name

}
`
      : ""
  }
    ${
      type === "country_master"
        ? `
      country_master(
        where: { is_active: { eq: true }
       country_name:{iregex:"${searchText}"}
       }
         offset:${offset}
       limit:${limit}
        ) {
    value: id
    label: country_name
    state_master(
      where: { is_active: { eq: true } }
      offset:0
       limit:400
      ) {
      value: id
      label: state_name
      cities_master(
        where: { is_active: { eq: true } }
        offset:0
       limit:700
        ) {
        value: id
        label: city_name
        city_area_master(
          offset:0
          limit:700
          where: { is_active: { eq: true } }
          offset:0
           limit:700
          ) {
          value: id
          label: area_name
          zipcode
        }
      }
    }
  }`
        : ""
    }

}
`;

export let GET_BLOCK_FORM_MASTER = (
  offset,
  limit,
  searchText,
  type,
  company
) => gql`
query GET_BLOCK_FORM_MASTER{
  count: ${type}(
    where:{
      is_active:{eq:true}
      ${
        type === "property_group_master"
          ? `
      group_name:{iregex:"${searchText}"}`
          : ""
      }
      ${
        type === "block_type_master"
          ? `
      name:{iregex:"${searchText}"}
      company_id:{eq: ${company}}
      `
          : ""
      }
    }
  ) {
    count:count_id
  }
  ${
    type === "property_group_master"
      ? `
    property_group_master(
      where: { is_active: { eq: true }
      group_name:{iregex:"${searchText}"}
    }
       offset:${offset}
       limit:${limit}
      ) {
    value: id
    label: group_name

  }
  `
      : ""
  }
  ${
    type === "block_type_master"
      ? `
  block_type_master(
      where: { is_active: { eq: true }
      name:{iregex:"${searchText}"}
      company_id:{eq: ${company}}
    }
       offset:${offset}
       limit:${limit}
      ) {
    value: id
    label: name

  }
  `
      : ""
  }

}`;

export let GET_UNIT_FORM_MASTER_NEW = (
  offset,
  limit,
  searchText,
  floorID,
  type
) => gql`
query GET_UNIT_FORM_MASTER{
  ${
    type !== "floor"
      ? `
  count: ${type}(
    where:{
      is_active:{eq:true}
      ${
        type === "property_group_master"
          ? `
      group_name:{iregex:"${searchText}"}`
          : ""
      }
      ${
        type === "unit_type_master"
          ? `
      name:{iregex:"${searchText}"}`
          : ""
      }
    }
  ) {
    count:count_id
  }`
      : ""
  }


  ${
    type === "floor"
      ? `
  count: floor(
    where: {
      id: { eq: "${floorID}" }
      is_active: { eq: true }
    }
  ) {
    count: unit(
      where: {
        or: { name: { iregex: "${searchText}" }, unit_no: { iregex: "${searchText}" } }
        is_room: { eq: false }
        unit_category_id: { in: $unitCategoryIDs }
        unit_purpose: { eq: $unitPurpose }
      }
    ) {
      count: count_id
    }
  }`
      : ""
  }

  ${
    type === "property_group_master"
      ? `
    property_group_master(
      where: { is_active: { eq: true }
      group_name:{iregex:"${searchText}"}
    }
       offset:${offset}
       limit:${limit}
      ) {
    value: id
    label: group_name

  }
  `
      : ""
  }
  ${
    type === "unit_type_master"
      ? `
  unit_type_master(
      where: { is_active: { eq: true }
      name:{iregex:"${searchText}"}
    }
       offset:${offset}
       limit:${limit}
      ) {
    value: id
    label: name

  }
  `
      : ""
  }

  ${
    type === "floor"
      ? `
  floor(
    where: {
      id: { eq: "${floorID}" }
      is_active: { eq: true }
    }
  ) {
    unit(
      where: {
        or: { name: { iregex: "${searchText}" }, unit_no: { iregex: "${searchText}" } }
        is_room: { eq: false }
        unit_category_id: { in: $unitCategoryIDs }
        unit_purpose: { eq: $unitPurpose }
      }
      offset: 0
      limit: 1000
    ) {
      value: id
      label: name
      id
      name
      unit_no
      revenue_type
      area_metric
      unit_type(where: { is_active: { eq: true } }) {
        value: id
        label: name
      }
      address(where: { is_active: { eq: true } }) {
        latitude
        longitude
      }
      unit_purpose
    }
  }
  `
      : ""
  }

}`;
export let GET_PROPERTY_BASED_ON_COMPANY = (
  offset,
  limit,
  searchText,
  company_id = []
) => gql`
query GET_PROPERTY_BASED_ON_COMPANY{
  count: property(
    where:{
      company_id:{in:[${company_id}]}
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
    }
  ) {
    count:count_id
  }
  property(
      where:{
        company_id:{in:[${company_id}]}
        name:{iregex:"${searchText}"}
        is_active:{eq:true}
      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
      	value:id
      }
}
`;
export let GET_AMINITIES_LIST = (offset, limit, searchText, company) => gql`
query GET_MASTERS_FOR_CREATING_BLOCK{
  count: amenities_type_master(
    where:{
      amenities_name:{iregex:"${searchText}"}
      is_active:{eq:true}
      company_id:{eq:${company}}
    }
  ) {
    count:count_id
  }
  amenities_type_master(
      where:{
        amenities_name:{iregex:"${searchText}"}
        is_active:{eq:true}
         company_id:{eq:${company}}
      }
      offset:${offset}
      limit:${limit}
    ){
        label:amenities_name
      	value:id
      }
}
`;
export let UNIT_TYPE = (offset, limit, searchText, type, company) => gql`
query UNIT_TYPE{
  count: unit_type_master(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
      company_id:{eq:${company}}
    }
  ) {
    count:count_id
  }
  unit_type_master(
      where:{
        name:{iregex:"${searchText}"}
        is_active:{eq:true}
          company_id:{eq:${company}}
      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
      	value:id
      }
}
`;
//in proprty page
export const GET_BLOCK_LIST_BY_PROPERTY = gql`
  query ($id: String, $searchText: String, $offset: INT, $limit: INT) {
    count: block(
      where: {
        property_id: { eq: $id }
        or: { name: { iregex: $searchText }, block_no: { iregex: $searchText } }
        is_active: { in: $active }
      }
      limit: $limit
      offset: $offset
    ) {
      count: count_id
    }
    property(where: { id: { eq: $id } }) {
      id
      name
      property_hierarchy
      property_hierarchyByID {
        value: id
        label: name
        is_block
        is_floor
        is_unit
        is_multi_unit
        is_customisable
        property_hierarchy_labelByID {
          id
          floor_label
          block_label
        }
      }
    }
    block(
      where: {
        property_id: { eq: $id }
        or: { name: { iregex: $searchText }, block_no: { iregex: $searchText } }
        is_active: { in: $active }
      }
      limit: $limit
      offset: $offset
      order_by: { updated_at: desc }
    ) {
      name
      id
      is_active
      block_no
      description
      name
      area_metric
      carpet_area
      use_floor_hierarchy
      total_area
      assets(where: { reference_id: $id }, is_active: { eq: true }) {
        url
      }
      unit(where: { is_active: { eq: true } }) {
        id
      }
      floor(where: { is_active: { eq: true } }) {
        id
      }
      property_id
      property(where: { id: { eq: $id } }) {
        name
        revenue_type
        company {
          id
          name
        }
        property_group_master {
          id
          group_name
        }
        address_id
        address {
          street_1
          street_2
          street_3
          city
          district
          state
          country
        }
      }
    }
  }
`;
export const GET_BLOCK_LIST_FILTER_BY_PROPERTY = (
  id,
  searchText,
  block_type,
  active,
  offset,
  limit,
  client
) => gql`
query GET_BLOCK_LIST_FILTER_BY_PROPERTY{
  count: block(
    where: {
      property_id: { eq: "${id}" }
      or: {
        name: { iregex: "${searchText}" }
        block_no: { iregex: "${searchText}" }
      }
      ${block_type?.length ? `block_type:{id:{in:[${block_type}]}}` : ""}
      ${active?.length ? `is_active:{in:[${active}]}` : ""}
      client:{eq:"${client}"}
    }
  ) {
    count: count_id
  }
  property(where:{id:{eq:"${id}"}}){
    id
    name
    company_id
    property_hierarchyByID {
      value: id
      label: name
      is_block
      is_floor
      is_unit
      is_multi_unit
      is_customisable
      property_hierarchy_labelByID{
        id
        floor_label
        block_label
      }
    }
  }
  block(
    where: {
      property_id: { eq: "${id}" }
      or: {
        name: { iregex: "${searchText}" }
        block_no: { iregex: "${searchText}" }
      }
      ${block_type?.length ? `block_type:{id:{in:[${block_type}]}}` : ""}
      ${active?.length ? `is_active:{in:[${active}]}` : ""}
     }
     limit: ${limit}
     offset: ${offset}
    order_by: { updated_at: desc }
  ) {
    name
    id
    is_active
    block_no
    block_type_master{
        id
        name
    }
    description
    name
    area_metric
    carpet_area
    use_floor_hierarchy
    total_area
      assets(where: { reference_id: "${id}" },is_active: { eq: true }) {
    url
  }
    unit(where: { is_active: { eq: true } }) {
      id
    }
    floor(where: { is_active: { eq: true } }) {
      id
    }
    property_id
    property {
      name
      revenue_type
       company:company_master{
        id
        name
      }
      property_group_master {
        id
        group_name
      }
     address_id
    address{
      street_1
      street_2
      street_3
      city
      district
      state
      country
    }
    }
  }
}`;
export const GET_BLOCK_TYPE = (
  offset,
  limit,
  searchText,
  { company_id, client_id }
) => gql`
query {
  count:block_type_master(
    where:{
      name:{iregex:"${searchText}"}
      ${company_id ? `company_id: { eq: ${company_id} }` : ``}
      ${client_id ? `client: { eq: "${client_id}" }` : ``}
      is_active: { eq: true }
      is_delete: { eq: false }
    }
  ){
    count:count_id
  }
  block_type_master(
    where:{
      name:{iregex:"${searchText}"}
      ${company_id ? `company_id: { eq: ${company_id} }` : ``}
      ${client_id ? `client: { eq: "${client_id}" }` : ``}
      is_active: { eq: true }
      is_delete: { eq: false }
    }
    offset:${offset}
    limit:${limit}
  ){
    value:id
    label:name
  }
}
`;

export const GET_FLOOR_LIST_BY_PROPERTY = gql`
  query GET_FLOOR(
    $id: String
    $searchText: String
    $active: [INT]
    $client: String
  ) {
    count: floor(
      where: {
        property_id: { eq: $id }
        or: { name: { iregex: $searchText }, floor_no: { iregex: $searchText } }
        is_active: { in: $active }
        client: { eq: $client }
      }
    ) {
      count: count_id
    }
    floor(
      where: {
        client: { eq: $client }
        property_id: { eq: $id }
        or: { name: { iregex: $searchText }, floor_no: { iregex: $searchText } }
        is_active: { in: $active }
      }
      limit: $limit
      offset: $offset
      order_by: { updated_at: desc }
    ) {
      name
      description
      id
      is_active
      floor_no
      description
      block_id
      block {
        id
        name
      }
      unit(where: { is_active: { eq: true } }) {
        id
        name
      }
      property_hierarchyByID {
        value: id
        label: name
        is_block
        is_floor
        is_unit
        is_multi_unit
        is_customisable
        property_hierarchy_labelByID {
          id
          floor_label
          block_label
        }
      }
      property {
        id
        name
        revenue_type
        company: company_master {
          id
          name
        }
        property_group_master {
          id
          group_name
        }
        address_id
        address {
          street_1
          street_2
          street_3
          city
          district
          state
          country
        }
      }
    }
    property(where: { id: $id }) {
      id
      name
      property_hierarchy
      property_hierarchyByID {
        value: id
        label: name
        is_block
        is_floor
        is_unit
        is_multi_unit
        is_customisable
        property_hierarchy_labelByID {
          id
          floor_label
          block_label
        }
      }
    }
  }
`;
export const GET_UNIT_LIST_BY_PROPERTY = gql`
  query GET_UNIT(
    $ID: ID
    $offset: String
    $limit: String
    $searchText: String
    $active: [INT]
  ) {
    count: unit(
      where: {
        property_id: { eq: $ID }
        or: { name: { iregex: $searchText }, unit_no: { iregex: $searchText } }
        is_active: { in: $active }
      }
      offset: $offset
      limit: $limit
    ) {
      count: count_id
    }
    unit(
      where: {
        property_id: { eq: $ID }
        or: { name: { iregex: $searchText }, unit_no: { iregex: $searchText } }
        is_active: { in: $active }
      }
      offset: $offset
      limit: $limit
      order_by: { updated_at: desc }
    ) {
      id
      name
      unit_no
      revenue_type
      description
      total_bed_rooms
      total_baths
      property_hierarchyByID {
        value: id
        label: name
        is_block
        is_floor
        is_unit
        is_multi_unit
        is_customisable
        property_hierarchy_labelByID {
          id
          floor_label
          block_label
        }
      }
      property {
        id
        name
        address_id
        address {
          street_1
          street_2
          street_3
          city
          district
          state
          country
        }
        company {
          id
          name
        }
        area_metric
      }
      block {
        id
        name
      }
      floor {
        id
        name
      }
      unit_type {
        id
        type: name
      }
      unit_category
      unit_categoryByID {
        id
        name
      }
      carpet_area
      total_area
      uomByID {
        id
        name
      }
      area_metric
      unit_purpose
      furnishing
      unit_purpose
      status
      is_active
    }
    property(where: { id: $ID }) {
      id
      name
      property_hierarchy
      property_hierarchyByID {
        value: id
        label: name
        is_block
        is_floor
        is_unit
        is_multi_unit
        is_customisable
        property_hierarchy_labelByID {
          id
          floor_label
          block_label
        }
      }
    }
  }
`;

export const GET_UNIT_LIST_BY_PROPERTY_OR_BLOCK_FLOOR_UNIT = (
  type,
  unit_category,
  unit_type,
  active,
  ID,
  offset,
  limit,
  searchText
) => gql`
query GET_UNIT_LIST_BY_PROPERTY_OR_BLOCK_FLOOR_UNIT{
  count: unit(
   where: {
    ${type}: { eq: "${ID}" }
    name: { iregex: "${searchText}" }
    ${unit_category?.length ? `unit_category:{eq:"${unit_category}"}` : ""}
    ${unit_type?.length ? `unit_type:{id:{in:[${unit_type}]}}` : ""}
    ${active?.length ? `is_active:{in:[${active}]}` : ""}
  }) {
    count: count_id
  }
  unit(
    where: {
      ${type}: { eq: "${ID}" }
      name: { iregex: "${searchText}" }
      ${unit_category?.length ? `unit_category:{eq:"${unit_category}"}` : ""}
      ${unit_type?.length ? `unit_type:{id:{in:[${unit_type}]}}` : ""}
      ${active?.length ? `is_active:{in:[${active}]}` : ""}
    }
    offset: ${offset}
    limit: ${limit}
    order_by: { updated_at: desc }
  ) {
    id
    name
    unit_no
    description
    total_bed_rooms
    total_baths
    property {
      id
      name
      address_id
    address{
      street_1
      street_2
      street_3
      city
      district
      state
      country
    }
      company:company_master {
        id
        name
      }
      area_metric
    }
    block {
      id
      name
    }
    floor {
      id
      name
    }
    unit_type {
      id
      type: name
    }
    unit_category
    carpet_area
    total_area
    area_metric
    unit_purpose
    furnishing
    unit_purpose
    status
    is_active
  }
  ${
    type === "property_id"
      ? `
  property(where:{id:"${ID}"}){
    id
    name
  }
  `
      : ""
  }
  ${
    type === "block_id"
      ? `
  block(where: { id: "${ID}" }) {
    id
    property_id
    property {
      id
      name
    }
  }
  `
      : ""
  }
  ${
    type === "floor_id"
      ? `
  floor(where:{id:"${ID}"}){
    id
    property_id
    property{
      id
      name
    }
  }
  `
      : ""
  }
}
`;
export const GET_UNIT_CATEGORY_LIST = gql`
  query GET_UNIT_CATEGORY_LIST {
    unit {
      label: unit_category
      value: unit_category
    }
  }
`;

export const GET_UNIT_TYPE_LIST = (offset, limit, search) => gql`
query{
  count: unit(
     where:{
       unit_type:{name:{iregex:"${search}"}
    }
  }
 ){
count:count_id
}
 unit(
  where:{unit_type:{name:{iregex:"${search}"}}
}
  offset:${offset}
  limit:${limit}
 ){
 unit_type{
     value:id
     label:name
 }
 }
}
`;

//get list in proprty -floor,unit

//in block page
export const GET_FLOOR_LIST = gql`
  query GET_FLOOR(
    $blockId: String
    $searchText: String
    $active: [INT]
    $client: String
  ) {
    count: floor(
      where: {
        client: { eq: $client }
        block_id: { eq: $id }
        name: { iregex: $searchText }
        is_active: { in: $active }
      }
      limit: $limit
      offset: $offset
    ) {
      count: count_id
    }
    floor(
      where: {
        client: { eq: $client }
        block_id: { eq: $id }
        name: { iregex: $searchText }
        is_active: { in: $active }
      }
      limit: $limit
      offset: $offset
      order_by: { updated_at: desc }
    ) {
      name
      description
      id
      is_active
      floor_no
      block_id
      block {
        id
        name
      }
      unit(where: { is_active: { eq: true } }) {
        id
        name
      }
      property_hierarchyByID {
        value: id
        label: name
        is_block
        is_floor
        is_unit
        is_multi_unit
        is_customisable
        property_hierarchy_labelByID {
          id
          floor_label
          block_label
        }
      }
      property {
        id
        name
        property_hierarchyByID {
          value: id
          label: name
          is_block
          is_floor
          is_unit
          is_multi_unit
          is_customisable
          property_hierarchy_labelByID {
            id
            floor_label
            block_label
          }
        }
        revenue_type
        company: company_master {
          id
          name
        }
        property_group_master {
          id
          group_name
        }
        address_id
        address {
          street_1
          street_2
          street_3
          city
          district
          state
          country
        }
      }
    }
    block(where: { id: $id }) {
      id
      name
      property_id
      property {
        id
        name
        property_hierarchy
        property_hierarchyByID {
          value: id
          label: name
          is_block
          is_floor
          is_unit
          is_multi_unit
          is_customisable
          property_hierarchy_labelByID {
            id
            floor_label
            block_label
          }
        }
      }
    }
  }
`;
export const GET_UNIT_LIST = gql`
  query GET_UNIT(
    $ID: ID
    $offset: String
    $limit: String
    $searchText: String
    $active: [INT]
  ) {
    count: unit(
      where: {
        floor_id: { eq: $ID }
        or: { name: { iregex: $searchText }, unit_no: { iregex: $searchText } }
        is_active: { in: $active }
      }
      offset: $offset
      limit: $limit
    ) {
      count: count_id
    }
    unit(
      where: {
        floor_id: { eq: $ID }
        or: { name: { iregex: $searchText }, unit_no: { iregex: $searchText } }
        is_active: { in: $active }
      }
      offset: $offset
      limit: $limit
      order_by: { updated_at: desc }
    ) {
      id
      name
      unit_no
      description
      revenue_type
      total_bed_rooms
      total_baths
      property_hierarchyByID {
        value: id
        label: name
        is_block
        is_floor
        is_unit
        is_multi_unit
        is_customisable
        property_hierarchy_labelByID {
          id
          floor_label
          block_label
        }
      }
      property {
        id
        name
        address_id
        address {
          street_1
          street_2
          street_3
          city
          district
          state
          country
        }
        company {
          id
          name
        }
        area_metric
      }
      block {
        id
        name
      }
      floor {
        id
        name
      }
      unit_type {
        id
        type: name
      }
      unit_category
      unit_categoryByID {
        id
        name
      }
      carpet_area
      total_area
      uomByID {
        id
        name
      }
      area_metric
      unit_purpose
      furnishing
      unit_purpose
      status
      is_active
    }
    floor(where: { id: $ID }) {
      id
      property_id
      property {
        id
        name
        property_hierarchy
        property_hierarchyByID {
          value: id
          label: name
          is_block
          is_floor
          is_unit
          is_multi_unit
          is_customisable
          property_hierarchy_labelByID {
            id
            floor_label
            block_label
          }
        }
      }
    }
  }
`;
export const PROPERTY_DETAILS_BY_POPERTY_ID = gql`
  query GET_PROPERTY_DETAILS_BY_ID($propertyID: ID) {
    property(where: { id: { eq: $propertyID } }) {
      unit_count: unit(where: { is_active: { eq: true } }) {
        count_id
        __typename
      }
      floor_count: floor(where: { is_active: { eq: true } }) {
        count_id
        __typename
      }
      block_count: block(where: { is_active: { eq: true } }) {
        count_id
        __typename
      }
      project_id
      projectByID {
        id
        project_no
        title
        planned_start_date
        planned_end_date
      }
      title_deed
      deed_date
      hijri_date
      land_area
      market_price
      vendor_id
      vendorByID {
        id
        vendor_no
        vendor_code
        image_url
        name
        vendor_address {
          id
          mobile_code
          mobile_no
          email_id
          website
        }
      }
      registration_officeByID {
        id
        vendor_no
        vendor_code
        image_url
        name
        vendor_address {
          id
          mobile_code
          mobile_no
          email_id
          website
          door_no
          street_1
          street_2
          landmark
          area
          city
          state
          country
          pincode
          latitude
          longitude
        }
      }
      communication_email
      id
      logo
      is_block
      is_floor
      property_no
      name
      pet_policy
      is_asset_property
      is_community
      property_hierarchyByID {
        value: id
        label: name
        is_block
        is_floor
        is_unit
        is_multi_unit
        is_customisable
        property_hierarchy_labelByID {
          id
          floor_label
          block_label
        }
      }
      set_back_area
      is_asset_functional_location
      uomByID {
        value: id
        label: name
      }
      swimming_pools
      discussion_forum
      elevators
      amenities_booking
      area_metric
      property_purpose
      property_hierarchy
      description
      revenue_type
      municipality_authority
      electrical_water_authority
      util_gas_connection
      smart_geographic_addressing
      util_water_connection
      util_electricity_connection
      company {
        id
        name
        value: id
        label: name
        currency {
          id
          symbol
        }
        __typename
      }
      property_group_id
      property_groupByID {
        id
        group_name
        __typename
      }
      payment_period
      is_active
      description
      revenue_type
      carpet_area
      total_area
      year_built
      planned_hand_over_date
      is_listing
      is_pet_allowed
      address {
        id
        door_no
        street_1
        street_2
        street_3
        landmark
        area
        city
        district
        state
        country
        zipcode
        latitude
        longitude
        smart_geo_address
        __typename
      }
      business_phone_country_code
      business_phone
      mobile_country_code
      mobile_phone
      website

      email
      owner_id
      ownerByID: contact_accountByID {
        id
        name
        account_no
        company_id
        logo
        email_id
        mobile_no
        mobile_no_country_code
        contact {
          id
          first_name
          last_name
          image_url
          mobile_no
          mobile_no_country_code
          email_id
        }
      }
      __typename
    }
    share_holder(
      where: {
        property_id: { eq: $propertyID }
        is_active: { eq: true }
        is_delete: { eq: false }
      }
    ) {
      id
      name
      email_id
      mobile_no
      mobile_no_country_code
      value
      contact_id
      contactByID {
        id
        first_name
        mobile_no_country_code
        mobile_no
        email_id
        image_url
      }
    }
    property_assets(
      where: { property_id: { eq: $propertyID }, is_active: { eq: true } }
    ) {
      id
      url
      priority
      asset_type
    }
    team_property_access(
      where: { property_id: { eq: $propertyID }, is_active: { eq: true } }
    ) {
      id
      teams {
        id
        name
      }
    }
  }
`;

export const GET_UNIT_LIST_BY_FLOOR = gql`
  query GET_UNIT(
    $ID: ID
    $offset: String
    $limit: String
    $searchText: String
    $active: [INT]
  ) {
    count: floor(
      where: {
        floor_id: { eq: $ID }
        name: { iregex: $searchText }
        is_active: { in: $active }
      }
      offset: $offset
      limit: $limit
    ) {
      count: count_id
    }
    floor(
      where: {
        floor_id: { eq: $ID }
        name: { iregex: $searchText }
        is_active: { in: $active }
      }
      offset: $offset
      limit: $limit
      order_by: { updated_at: desc }
    ) {
      id
      name
      unit_no
      total_bed_rooms
      total_baths
      property {
        id
        name
        city_area_master {
          id
          area_name
        }
        cities_master {
          city_name
          state_master {
            state_name
          }
          country_master {
            country_name
          }
        }
        company {
          id
          name
        }
        area_metric
      }
      block {
        id
        name
      }
      floor {
        id
        name
      }
      unit_type {
        id
        type: name
      }
      unit_category
      carpet_area
      total_area
      area_metric
      unit_purpose
      furnishing
      unit_purpose
      status
      is_active
    }
  }
`;
export const GET_UNIT_LIST_BY_BLOCK = gql`
  query GET_UNIT(
    $ID: ID
    $offset: String
    $limit: String
    $searchText: String
    $active: [INT]
  ) {
    count: unit(
      where: {
        block_id: { eq: $ID }
        name: { iregex: $searchText }
        is_active: { in: $active }
      }
      offset: $offset
      limit: $limit
    ) {
      count: count_id
    }
    unit(
      where: {
        block_id: { eq: $ID }
        name: { iregex: $searchText }
        is_active: { in: $active }
      }
      offset: $offset
      limit: $limit
      order_by: { updated_at: desc }
    ) {
      id
      name
      unit_no
      revenue_type
      description
      total_bed_rooms
      total_baths
      property_hierarchyByID {
        value: id
        label: name
        is_block
        is_floor
        is_unit
        is_multi_unit
        is_customisable
        property_hierarchy_labelByID {
          id
          floor_label
          block_label
        }
      }
      property {
        id
        name
        address_id
        address {
          street_1
          street_2
          street_3
          city
          district
          state
          country
        }
        company {
          id
          name
        }
        area_metric
      }
      block {
        id
        name
      }
      floor {
        id
        name
      }
      unit_type {
        id
        type: name
      }
      unit_category
      unit_categoryByID {
        id
        name
      }
      carpet_area
      total_area
      uomByID {
        id
        name
      }
      area_metric
      unit_purpose
      furnishing
      unit_purpose
      status
      is_active
    }
    block(where: { id: $ID }) {
      id
      property_id
      property {
        id
        name
        property_hierarchy
        property_hierarchyByID {
          value: id
          label: name
          is_block
          is_floor
          is_unit
          is_multi_unit
          is_customisable
          property_hierarchy_labelByID {
            id
            floor_label
            block_label
          }
        }
      }
    }
  }
`;
// unit details
export const GET_UNIT_DETAILS_BY_UNIT_ID = gql`
  query GET_UNIT_DETAILS_BY_UNIT_ID($unitId: String) {
    unit(where: { id: { eq: $unitId } }) {
      project_id
      projectByID {
        id
        project_no
        title
        planned_start_date
        planned_end_date
      }
      registration_officeByID {
        id
        vendor_no
        vendor_code
        image_url
        name
        vendor_address {
          id
          mobile_code
          mobile_no
          email_id
          website
          door_no
          street_1
          street_2
          landmark
          area
          city
          state
          country
          pincode
          latitude
          longitude
        }
      }
      whole_land_area
      master_bedroom_size
      living_room_size
      kitchen_size
      guest_room_size
      market_price
      title_deed
      deed_date
      hijri_date
      children_room_size
      servant_room_size
      id
      execution_status
      name
      pet_policy
      logo
      unit_no
      total_rooms
      balcony_area
      build_up_area
      terrace_area
      orientation
      payment_period
      balconies
      total_baths
      total_area
      total_bed_rooms
      carpet_area
      area_metric
      revenue_type
      address_id
      is_seat
      seat_count
      ownerByID: contact_accountByID {
        id
        name
        account_no
        company_id
        logo
        email_id
        mobile_no
        mobile_no_country_code
        contact {
          id
          first_name
          last_name
          image_url
          mobile_no
          mobile_no_country_code
          email_id
        }
      }
      addressByID {
        smart_geo_address
        mobile_no
        mobile_no_country_code
        alternative_mobile_no
        alternative_mobile_no_country_code
        email_id
        id
        door_no
        street_1
        street_2
        street_3: landmark
        latitude
        longitude
        city
        state
        country
        zipcode
        landmark
        district: area
        cities_master {
          city_name
          state_master {
            state_name
          }
          country_master {
            country_name
          }
        }
      }
      unit_category
      unit_purpose
      unit_type {
        id
        type: name
        label: name
        value: id
      }
      is_asset_functional_location
      is_asset_property
      is_short_term_rental
      is_under_promotion
      uomByID {
        value: id
        label: name
      }
      unit_categoryByID {
        value: id
        label: name
        id
        name
      }
      id
      is_active
      govt_utility_no

      description
      is_pet_allowed
      planned_hand_over_date
      listing_status
      parent_unit_id
      parent_unit: unit(find: "parents") {
        id
        name
      }
      year_built
      lease_or_sale_value
      status
      orientation

      contact {
        latitude
        longitude
      }
      block {
        id
        name
      }
      floor {
        id
        name
      }
      property {
        id
        revenue_type
        property_hierarchy
        property_group_master {
          id
          group_name
        }
        property_groupByID {
          id
          group_name
        }
        company: company_master {
          id
          name
          value: id
          label: name
          uom
          country_id
          currency_id
          currencyByID {
            id
            name
            symbol
            code
          }
          vat_group_id
          vat_groupByID {
            id
            group_name
            value: id
            label: group_name
          }
        }
        is_active
        website
        mobile_phone
        mobile_country_code
        business_phone
        business_phone_country_code
        email
        name
        description
        address_id
        addressByID {
          id
          street_1
          street_2
          street_3
          district
          state
          country
          zipcode
          latitude
          longitude
        }
      }
      furnishing
    }
    share_holder(
      where: {
        unit_id: { eq: $unitId }
        is_active: { eq: true }
        is_delete: { eq: false }
      }
    ) {
      id
      name
      email_id
      mobile_no
      mobile_no_country_code
      value
      contact_id
      contactByID {
        id
        first_name
        mobile_no_country_code
        mobile_no
        email_id
        image_url
      }
    }
    assets: unit_assets(
      where: { unit_id: { eq: $unitId }, is_active: { eq: true } }
    ) {
      url
      asset_type
    }
  }
`;

export const GET_PROPERTY_DETAILS_BY_ID_FOR_EDIT_ = () => {
  return gql`
    query GET_PROPERTY_DETAILS_BY_ID_FOR_EDIT($propertyID: ID) {
      property(where: { id: { eq: $propertyID } }) {
        project_id
        projectByID {
          id
          project_no
          title
          planned_start_date
          planned_end_date
        }
        title_deed
        deed_date
        hijri_date
        land_area
        market_price
        vendor_id
        vendorByID {
          id
          vendor_no
          vendor_code
          image_url
          name
          vendor_address {
            id
            mobile_code
            mobile_no
            email_id
            website
          }
        }
        registration_officeByID {
          id
          vendor_no
          vendor_code
          image_url
          name
          vendor_address {
            id
            mobile_code
            mobile_no
            email_id
            website
            door_no
            street_1
            street_2
            landmark
            area
            city
            state
            country
            pincode
            latitude
            longitude
          }
        }
        communication_email
        id
        logo
        propertyName: name
        propertyDescription: description
        totalUnit: total_units
        measurementUnit: area_metric
        carpetArea: carpet_area
        totalArea: total_area
        set_back_area
        company(where: { is_active: { eq: true } }) {
          label: name
          value: id
        }
        property_purpose
        area_metric
        uomByID {
          value: id
          label: name
        }
        revenue_type
        propertyType: property_group(where: { is_active: { eq: true } }) {
          label: group_name
          value: id
        }
        pet_policy
        swimming_pools
        is_community
        elevators
        discussion_forum
        amenities_booking
        is_asset_property
        property_hierarchyByID {
          value: id
          label: name
          is_block
          is_floor
          is_unit
          is_multi_unit
          is_customisable
          property_hierarchy_labelByID {
            id
            floor_label
            block_label
          }
        }
        set_back_area
        is_asset_functional_location
        uomByID {
          value: id
          label: name
        }
        is_active
        isPropertyContainsBlock: is_block
        isPropertyContainsFloor: is_floor
        address_id
        addressByID {
          id
          addressLineOne: street_1
          addressLineTwo: street_2
          addressLineThree: street_3
          door_no
          landmark
          area
          city
          district
          state
          zipcode
          postOfficeBox: po_box
          country
          latitude
          longitude
          emailAddress: email_id
          smart_geo_address
        }
        business_phone_country_code
        business_phone
        mobile_country_code
        mobile_phone
        website
        emailAddress: email
        Municipality: municipality_authority
        Electricity: electrical_water_authority
        Utilties: util_electricity_connection
        Natural: util_gas_connection
        Water: util_water_connection
        Geographic: smart_geographic_addressing
        is_pet_allowed
        listing_status
        year_built
        planned_hand_over_date
        payment_period
        util_natural_gas_connection
        land_number
        owner_id
        ownerByID: contact_accountByID {
          id
          name
          account_no
          company_id
          logo
          email_id
          mobile_no
          mobile_no_country_code
          contact {
            id
            first_name
            last_name
            image_url
            mobile_no
            mobile_no_country_code
            email_id
          }
        }
      }
      share_holder(
        where: {
          property_id: { eq: $propertyID }
          is_active: { eq: true }
          is_delete: { eq: false }
        }
      ) {
        id
        name
        email_id
        mobile_no
        mobile_no_country_code
        value
        is_active
        is_delete
        contact_id
        contactByID {
          id
          first_name
          mobile_no_country_code
          mobile_no
          email_id
          image_url
        }
      }
      property_assets(
        where: { property_id: { eq: $propertyID }, is_active: { eq: true } }
      ) {
        id
        url
        priority
        asset_type
      }
      team_property_access(
        where: { property_id: { eq: $propertyID }, is_active: { eq: true } }
      ) {
        id
        teams {
          value: id
          label: name
        }
      }
    }
  `;
};

export const ASSEST_MASTER_TABLE = () => {
  return gql`
    query ASSEST_MASTER_TABLE {
      assets_master(order_by: { order: asc }) {
        id
        type
        accept
        order
      }
    }
  `;
};

export const GET_OTHER_INFO_UNIT_COUNT = gql`
  query GET_UNIT_DETAILS_BY_ID($unitID: String) {
    totalResident: owner_customer(
      where: {
        unit_id: $unitID
        is_active: true
        tenant_profile_id: { is_null: false }
      }
    ) {
      count_tenant_profile_id
    }

    totalOwners: owner_customer(
      where: {
        unit_id: $unitID
        is_active: true
        owner_profile_id: { is_null: false }
      }
    ) {
      count_owner_profile_id
    }

    pricing_table(
      where: { unit_id: $unitID, is_active: true, client: $client }
    ) {
      count_id
    }
    unit_vacancy_period(
      where: { unit_id: $unitID, is_active: true, client: $client }
    ) {
      count_id
    }
    unit_amenities_breakup(
      where: { unit_id: $unitID, is_active: true, client: $client }
    ) {
      count_id
    }
    unit_utilties(
      where: { unit_id: $unitID, is_active: true, client: $client }
    ) {
      count_id
    }
    unit(where: { parent_unit_id: $unitID, is_active: true, client: $client }) {
      count_id
    }

    agreement_units(
      where: { unit_id: $unitID, is_active: true, client: $client }
    ) {
      count_id
    }
    invoice_units(
      where: { unit_id: $unitID, is_active: true, client: $client }
    ) {
      count_id
    }

    general_request_units(
      where: { unit_id: $unitID, is_active: true, client: $client }
    ) {
      count_id
    }

    maintenance_request_units(
      where: { unit_id: $unitID, is_active: true, client: $client }
    ) {
      count_id
    }
  }
`;
export const GET_ASSET_MASTER = gql`
  query {
    assets_master(
      where: { is_active: { eq: true } }
      order_by: { order: asc }
    ) {
      id
      type
      order
    }
  }
`;
export const BlOCK_DETAILS_BY_BLOCK_ID = gql`
  query GET_BLOCK_DETAILS_BY_ID($blockId: ID) {
    block(where: { id: { eq: $blockId }, is_active: { eq: true } }) {
      unit_count: unit(where: { is_active: { eq: true } }) {
        count_id
      }
      floor_count: floor(where: { is_active: { eq: true } }) {
        count_id
      }
      company {
        label: name
        value: id
      }
      area_metric
      uom_masterByID {
        name
      }
      block_type
      block_type_masterByID {
        name
      }
      is_external
      id
      logo
      company: company_master {
        id
        name
      }
      year_built
      planned_hand_over_date
      carpet_area
      total_area
      use_floor_hierarchy
      property_id
      block_purpose
      address_id
      owner_id
      is_active
      created_by
      updated_by
      created_at
      updated_at
      name
      description
      block_no

      address_id
      address {
        mobile_no
        mobile_no_country_code
        alternative_mobile_no
        alternative_mobile_no_country_code
        email_id
        id
        door_no
        street_1
        street_2
        street_3: landmark
        latitude
        longitude
        city
        state
        country
        zipcode
        landmark
        district: area
        area
        cities_master {
          city_name
          state_master {
            state_name
          }
          country_master {
            country_name
          }
        }
      }
      property {
        name
        revenue_type
        property_groupByID {
          group_name
        }
        company_masterByID {
          name
          id
        }

        description

        address {
          street_1
          street_2
          street_3
          latitude
          longitude
          city
          state
          country
          zipcode
          landmark
          district: area
        }
        cities_master {
          city_name
          state_master {
            state_name
          }
          country_master {
            country_name
          }
        }
        business_phone_country_code
        business_phone
        mobile_country_code
        mobile_phone
        website
        email
        property_hierarchyByID {
          value: id
          label: name
          is_block
          is_floor
          is_unit
          is_multi_unit
          is_customisable
          property_hierarchy_labelByID {
            id
            floor_label
            block_label
          }
        }
      }
    }
    assets: block_assets(
      where: { block_id: { eq: $blockId }, is_active: { eq: true } }
    ) {
      id
      url
      priority
      asset_type
    }
  }
`;
export const FLOOR_DETAILS_BY_BLOCK_ID_old = gql`
  query ($floorId: String) {
    floor(where: { id: { eq: $floorId } }) {
      unit_count: unit {
        count_id
      }
      name
      floor_no
      floor_purpose
      id
      is_active
      description
      contact {
        latitude
        longitude
      }
      property {
        id
        revenue_type
        property_groupByID {
          group_name
        }
        company_masterByID {
          name
          id
        }
        is_active
        website
        mobile_phone
        business_phone
        email
        name
        description
        address {
          street_1
          street_2
          street_3
          district
          state
          country
          zipcode
          latitude
          longitude
        }
      }
    }
    assets(where: { reference_id: { eq: $floorId } }) {
      url
      assets_master_type
    }
    asset: assets(
      where: { reference_id: { eq: $floorId }, assets_master_type: 2 }
    ) {
      url
      assets_master_type
    }
  }
`;
export const GET_ALL_COUNTS = gql`
  query ($unitId: [String!], $agreementId: [String]) {
    security_count: security_request(
      where: { unit_id: { in: $unitId }, is_active: { eq: true } }
    ) {
      count: count_id
    }

    agreement_units_count: agreement_units(
      where: { unit_id: { in: $unitId }, is_active: { eq: true } }
    ) {
      count: count_id
    }
    invoice_units_count: invoice_units(
      where: { unit_id: { in: $unitId }, is_active: { eq: true } }
    ) {
      count: count_id
    }
    owner_customer_count: owner_customer(
      where: { unit_id: { in: $unitId }, is_active: { eq: true } }
    ) {
      count: count_id
    }
    agreement_count: agreement(
      where: { id: { in: $agreementId }, is_active: { eq: true } }
    ) {
      count: count_id
    }
  }
`;
export const GET_ALL_OWNERS_COUNTS = gql`
  query ($unitId: [String!]) {
    security_count: security_request(
      where: { unit_id: { in: $unitId }, is_active: { eq: true } }
    ) {
      count: count_id
    }

    agreement_units_count: agreement_units(
      where: { unit_id: { in: $unitId }, is_active: { eq: true } }
    ) {
      count: count_id
    }
    invoice_units_count: invoice_units(
      where: { unit_id: { in: $unitId }, is_active: { eq: true } }
    ) {
      count: count_id
    }
    owner_customer_count: owner_customer(
      where: { unit_id: { in: $unitId }, is_active: { eq: true } }
    ) {
      count: count_id
    }
  }
`;

export const GET_PROPERTY_BY_PROPERTY_ID = gql`
  query GET_PROPERTY_BY_PROPERTY_ID {
    property(where: { id: { eq: $propertyID } }) {
      id
      logo
      year_built
      property_hierarchyByID {
        value: id
        label: name
        is_block
        is_floor
        is_unit
        is_multi_unit
        is_customisable
        property_hierarchy_labelByID {
          id
          floor_label
          block_label
        }
      }
      planned_hand_over_date
      companyByID {
        value: id
        label: name
      }
      revenue_type
      property_purpose
      property_groupByID {
        value: id
        label: group_name
      }
      area_metric
      uom_masterByID {
        value: id
        label: name
      }
      addressByID {
        door_no
        street_1
        street_2
        landmark
        area
        city
        state
        country
        zipcode
        latitude
        longitude
      }
      business_phone_country_code
      business_phone
      mobile_country_code
      mobile_phone
      website
      email
      uom_masterByID {
        name
      }
    }
  }
`;

export const GET_PROPERTY_BY_FLOOR_ID = gql`
  query GET_PROPERTY_BY_FLOOR_ID {
    floor(where: { id: { eq: $floorID } }) {
      id
      logo
      name
      year_built
      planned_hand_over_date
      floor_purpose
      revenue_type
      property_id
      block_id
      block {
        value: id
        label: name
        logo
      }
      addressByID {
        email_id
        door_no
        street_1
        street_2
        landmark
        area
        city
        state
        country
        zipcode
        latitude
        longitude
        mobile_no
        mobile_no_country_code
        alternative_mobile_no
        alternative_mobile_no_country_code
      }
      propertyByID {
        id
        uomByID {
          value: id
          label: name
        }
        logo
        website
        property_hierarchy
        property_hierarchyByID {
          value: id
          label: name
          is_block
          is_floor
          is_unit
          is_multi_unit
          is_customisable
          property_hierarchy_labelByID {
            id
            floor_label
            block_label
          }
        }
        is_block
        is_floor
        companyByID {
          value: id
          label: name
        }
        property_groupByID {
          value: id
          label: group_name
        }
        area_metric
        payment_period
        addressByID {
          door_no
          street_1
          street_2
          landmark
          area
          city
          state
          country
          zipcode
          latitude
          longitude
        }
        year_built
        planned_hand_over_date
        business_phone_country_code
        business_phone
        mobile_country_code
        mobile_phone
        website
        email
      }
      unit {
        count: count_id
      }
    }
  }
`;

export const GET_UNIT = gql`
  query GET_UNIT {
    share_holder(
      where: {
        unit_id: { eq: $unitID }
        is_active: { eq: true }
        is_delete: { eq: false }
      }
    ) {
      id
      name
      email_id
      mobile_no
      mobile_no_country_code
      value
      contact_id
      contactByID {
        id
        first_name
        mobile_no_country_code
        mobile_no
        email_id
        image_url
      }
    }
    assets: unit_assets(
      where: { unit_id: { eq: $unitID }, is_active: { eq: true } }
    ) {
      id
      url
      priority
      asset_type
    }
    unit(where: { id: { eq: $unitID } }) {
      project_id
      projectByID {
        id
        project_no
        title
        planned_start_date
        planned_end_date
      }
      registration_officeByID {
        id
        vendor_no
        vendor_code
        image_url
        name
        vendor_address {
          id
          mobile_code
          mobile_no
          email_id
          website
          door_no
          street_1
          street_2
          landmark
          area
          city
          state
          country
          pincode
          latitude
          longitude
        }
      }
      whole_land_area
      master_bedroom_size
      living_room_size
      kitchen_size
      guest_room_size
      market_price
      title_deed
      deed_date
      hijri_date
      children_room_size
      servant_room_size
      is_seat
      seat_count
      execution_status
      ownerByID: contact_accountByID {
        id
        name
        account_no
        company_id
        logo
        email_id
        mobile_no
        mobile_no_country_code
        contact {
          id
          first_name
          last_name
          image_url
          mobile_no
          mobile_no_country_code
          email_id
        }
      }
      addressByID {
        smart_geo_address
        door_no
        street_1
        street_2
        landmark
        area
        city
        state
        country
        zipcode
        latitude
        longitude
        alternative_mobile_no
        alternative_mobile_no_country_code
        mobile_no
        mobile_no_country_code
        email: email_id
      }
      id
      uomByID {
        value: id
        label: name
      }
      unit_categoryByID {
        value: id
        label: name
        id
        name
      }
      is_asset_functional_location
      is_asset_property
      is_short_term_rental
      is_under_promotion
      logo
      build_up_area
      terrace_area
      pet_policy
      is_active
      name
      balconies
      balcony_area
      property_id
      block {
        value: id
        label: name
        logo
      }
      block_id
      floor {
        value: id
        label: name
        logo
      }
      floor_id
      description
      unit_purpose
      revenue_type
      unit_category
      payment_period
      unit_type {
        value: id
        label: name
      }
      furnishing
      status
      parentUnit: unit(find: "parents", where: { is_active: { eq: true } }) {
        id
        name
        unit_no
      }
      carpet_area
      total_area
      govt_utility_no
      year_built
      planned_hand_over_date
      orientation
      lease_or_sale_value
      total_rooms
      total_bed_rooms
      total_baths
      listing_status
      is_pet_allowed
      propertyByID {
        id
        logo
        companyByID {
          value: id
          label: name
        }
        property_groupByID {
          value: id
          label: group_name
        }
        property_hierarchyByID {
          value: id
          label: name
          is_block
          is_floor
          is_unit
          is_multi_unit
          is_customisable
          property_hierarchy_labelByID {
            id
            floor_label
            block_label
          }
        }
        ownerByID: contact_accountByID {
          id
          name
          logo
          account_no
          mobile_no
          mobile_no_country_code
          email_id
          contact {
            id
            first_name
            last_name
            image_url
            mobile_no
            mobile_no_country_code
            email_id
          }
        }
        area_metric
        addressByID {
          door_no
          street_1
          street_2
          landmark
          area
          city
          state
          country
          zipcode
          latitude
          longitude
        }
        business_phone_country_code
        business_phone
        mobile_country_code
        mobile_phone
        website
        email
      }
    }
  }
`;
export const FLOOR_DETAILS_BY_BLOCK_ID = gql`
  query ($floorId: String) {
    floor(where: { id: { eq: $floorId } }) {
      unit_count: unit(where: { is_active: { eq: true } }) {
        count_id
      }
      company {
        value: id
        label: name
      }
      name
      floor_no
      floor_purpose
      id
      logo
      is_active
      description
      address_id
      address {
        mobile_no
        mobile_no_country_code
        alternative_mobile_no
        alternative_mobile_no_country_code
        email_id
        id
        door_no
        street_1
        street_2
        street_3: landmark
        latitude
        longitude
        city
        state
        country
        zipcode
        landmark
        district: area
        cities_master {
          city_name
          state_master {
            state_name
          }
          country_master {
            country_name
          }
        }
      }
      contact {
        latitude
        longitude
      }
      block {
        id
        name
      }
      property {
        id
        revenue_type
        property_groupByID {
          group_name
        }
        company_masterByID {
          name
          id
        }
        is_active
        website
        mobile_phone
        business_phone
        email
        name
        description
        address {
          street_1
          street_2
          street_3
          district
          state
          country
          zipcode
          latitude
          longitude
        }
      }
    }
    assets: floor_assets(
      where: { floor_id: { eq: $floorId }, is_active: { eq: true } }
    ) {
      id
      url
      priority
      asset_type
    }
  }
`;

export const GET_FLOOR_LIST_BY_PROPERTY_EDIT = gql`
  query GET_FLOOR($id: String) {
    floor(where: { id: { eq: $id } }, order_by: { updated_at: desc }) {
      name
      logo
      year_built
      planned_hand_over_date
      description
      id
      is_active
      floor_no
      floor_purpose
      uomByID {
        value: id
        label: name
      }
      carpet_area
      total_area
      block_id
      block {
        id
        name
      }
      revenue_type
      address_id
      address: addressByID {
        email_id
        addressLineOne: street_1
        addressLineTwo: street_2
        addressLineThree: street_3
        door_no
        landmark
        city
        area
        district
        state
        zipcode
        postOfficeBox: po_box
        country
        latitude
        longitude
        mobile_no
        mobile_no_country_code
        alternative_mobile_no
        alternative_mobile_no_country_code
      }
      unit {
        id
        name
      }
      property {
        id
        name
        property_hierarchyByID {
          value: id
          label: name
          is_block
          is_floor
          is_unit
          is_multi_unit
          is_customisable
          property_hierarchy_labelByID {
            id
            floor_label
            block_label
          }
        }
        website
        revenue_type
        area_metric
        company: company_master {
          id
          name
        }
        property_groupByID: property_groupByID {
          id
          group_name
        }
        city_area_master {
          id
          area_name
        }
        cities_master {
          city_name
          state_master {
            state_name
          }
          country_master {
            country_name
          }
        }
      }
    }
    assets: floor_assets(
      where: { floor_id: { eq: $id }, is_active: { eq: true } }
    ) {
      id
      url
      priority
      asset_type
    }
  }
`;
export const PROPERTY_DETAILS_BY_POPERTY_ID_FLOOR_BLOCK = gql`
  query PROPERTY_DETAILS_BY_POPERTY_ID_FLOOR_BLOCK($propertyID: ID) {
    propertycount: property(where: { id: { eq: $propertyID } }) {
      count: count_id
    }
    blockcount: block(
      where: { property_id: { eq: $propertyID }, is_active: true }
    ) {
      count: count_id
    }
    floorcount: block(
      where: { property_id: { eq: $propertyID }, is_active: true }
    ) {
      count: count_id
    }
    unitcount: block(
      where: { property_id: { eq: $propertyID }, is_active: true }
    ) {
      count: count_id
    }
    property(where: { id: { eq: $propertyID } }) {
      id
      property_no
      name
      area_metric
      property_purpose
      description
      revenue_type
      municipality_authority
      electrical_water_authority
      util_gas_connection
      smart_geographic_addressing
      util_water_connection
      util_electricity_connection
      company: company_master {
        id
        name
      }
      property_group_id
      property_groupByID: property_groupByID {
        id
        group_name
      }
      address_id
      address: addressByID {
        addressLineOne: street_1
        addressLineTwo: street_2
        addressLineThree: street_3
        door_no
        landmark
        city
        district
        state
        zipcode
        postOfficeBox: po_box
        country
        latitude
        longitude
        emailAddress: email_id
      }
      payment_period
      is_active
      description
      revenue_type
      carpet_area
      total_area
      year_built
      planned_hand_over_date
      is_listing
      is_pet_allowed
      business_phone_country_code
      business_phone
      mobile_country_code
      mobile_phone
      website
      email
    }
    assets(where: { reference_id: $propertyID }) {
      url
    }
  }
`;

export const GET_PROPRTY_AND_UNIT = gql`
  query {
    property(where: { id: { eq: $id } }) {
      id
      year_built
      name
      description
      address_id
      address {
        street_1
        street_2
        street_3
        city
        state
        country
        zipcode
        __typename
      }
      business_phone_country_code
      business_phone
      mobile_country_code
      mobile_phone
      website
      email
      unitCount: unit(
        where: {
          is_active: true
          name: { iregex: $search }
          id: { in: $unit_id }
        }
      ) {
        count_id
        __typename
      }
      unit(
        where: {
          is_active: true
          id: { in: $unit_id }
          name: { iregex: $search }
        }
        offset: $offset
        limit: $limit
      ) {
        id
        name
        total_baths
        total_rooms
        total_area
        year_built
        url: logo
        total_bed_rooms
        carpet_area
        unit_no
        unit_purpose
        room_rent: lease_or_sale_value
        currency_id
        unit_type {
          id
          name
        }
        area_metric
        unit_category
        unit_categoryByID {
          id
          name
        }
        furnishing
        currencyByID {
          id
          symbol
        }
        description
        payment_period
        __typename
        uom_master {
          name
        }
      }
      __typename
    }
    assets: property_assets(
      where: { property_id: { eq: $id } }
      is_active: { eq: true }
    ) {
      id
      url
      priority
      asset_type
      __typename
    }
    asset: property_assets(
      where: {
        property_id: { eq: $id }
        is_active: true
        asset_type: $masterType
      }
      orderby: { priority: asc }
    ) {
      id
      url
      asset_type
    }
  }
`;

export const GET_UNIT_FOR_PROPERTY = gql`
  query {
    unit(where: { id: { eq: $id } }) {
      id
      name
      total_baths
      total_rooms
      year_built
      total_bed_rooms
      carpet_area
      unit_no
      room_rent
      currency
      unit_type
      area_metric
      furnishing
      description
      block {
        id
        name
      }
      floor {
        id
        name
      }
      property {
        id
        name
        address_id
        address {
          street_1
          street_2
          street_3
          city
          state
          country
          zipcode
        }
      }
    }
    assets(where: { reference_id: { eq: $id }, is_active: true }) {
      id
      url
      assets_master_type
    }
    asset: assets(
      where: {
        reference_id: { eq: $id }
        is_active: true
        assets_master_type: 2
      }
      orderby: { priority: asc }
    ) {
      id
      url
      assets_master_type
    }
  }
`;

export const GET_PROPERTY_DETAILS_FOR_MANAGEMENT = () => {
  return gql`
    query GET_PROPERTY_DETAILS_FOR_MANAGEMENT($propertyID: ID) {
      property(where: { id: { eq: $propertyID } }, is_active: true) {
        id
        propertyName: name
        propertyDescription: description
        company(where: { is_active: { eq: true } }) {
          name
          id
        }
        logo
        description
        addressByID {
          id
          door_no
          street_1
          street_2
          street_3
          landmark
          area
          city
          district
          state
          country
          zipcode
          latitude
          longitude
        }
        management_committee(
          where: { property_id: { eq: $propertyID }, is_active: true }
          order_by: { created_at: desc }
        ) {
          id
          property_id
          resident_profileByID {
            first_name
            image_url
            email_id
            last_name
            mobile_no
            mobile_no_country_code
            is_active
            id
          }
          committee_role {
            id
            role_name
          }
        }
        property_no
        property_purpose
        area_metric
        revenue_type
        propertyType: property_group(where: { is_active: { eq: true } }) {
          group_name
          id
        }
        is_active
        payment_period
      }
      assets(
        where: { reference_id: { eq: $propertyID }, is_active: { eq: true } }
      ) {
        id
        reference_id
        url
        priority
        assets_master_type
      }
    }
  `;
};
export const GET_MANAGEMENT_MEMBERLIST = (is_active = []) => {
  return gql`
  query GET_MANAGEMENT_COMMITTEE(
    $offset: Int
    $limit: Int
    $searchText: String
    $propertyId: ID
  ) {
    count: management_committee(
      where: {
        or:{
          committee_role:{role_name: { iregex: $searchText }}
           user_profiles: { first_name:{iregex: $searchText} }
        }
        property_id: { eq: $propertyId }
        ${is_active?.length > 0 ? `is_active: { in: $is_active }` : ``}
      }
    ) {
      count: count_id
    }
    management_committee(
      where: {
        or:{
          committee_role:{role_name: { iregex: $searchText }}
           user_profiles: { first_name:{iregex: $searchText} }
        }
        property_id: { eq: $propertyId }
        ${is_active?.length > 0 ? `is_active: { in: $is_active }` : ``}
      }
      offset: $offset
      limit: $limit
      order_by: { created_at: desc }
    ) {
      id
      committee_role{
       value: id
       label: role_name
       responsibiltes:description
      }
      from
      unit{
  id
  name
  unit_no
}
is_owner
      created_at
      updated_at
      user_profiles{
       value: id
     label :first_name
        image_url
      }
      company {
        id
        name
      }
      is_active
    }
  

}`;
};
export const GET_MANAGEMENT_HISTORY = gql`
  query MANAGEMENT_HISTORY($commiteeID: STRING, $offset: Int, $limit: Int) {
    management_commitee_history(
      where: { committee_id: $commiteeID }
      order_by: { created_at: desc }
      offset: $offset
      limit: $limit
    ) {
      id
      management_committee {
        id
        unit {
          id
          name
          unit_no
        }
      }
      created_at
      user_profileByID {
        first_name
        mobile_no
        mobile_no_country_code
        email_id
        image_url
      }
    }
  }
`;

export const GET_PROPERTY_DETAILS_MANAGEMENT_LIST = () => {
  return gql`
    query GET_PROPERTY_BY_COMPANY_ID($propertyID: String) {
      count: property(
        where: {
          id: { in: $propertyID }
          name: { iregex: $searchText }
          is_active: true
        }
      ) {
        count: count_id
      }
      property(
        where: {
          id: { in: $propertyID }
          name: { iregex: $searchText }
          is_active: true
        }
        offset: $offset
        limit: $limit
        order_by: { created_at: desc }
      ) {
        id
        logo
        is_active
        property_no
        revenue_type
        name
        address: addressByID {
          street_1
          street_2
          street_3
          district
          city
          state
        }
        management_committee(where: { property_id: { in: $propertyID } }) {
          id
          committee_role
        }
      }
    }
  `;
};

export const InsertData_Management = `
mutation($id: String, $role_id: String) {
  management_committee(
    where: { id: { eq: $id }, committee_role: $role_id }
    update: $data
  ) {
    id
  }
}
`;
export const GET_PROPERTY_GROUP = (
  offset,
  limit,
  searchText,
  type,
  company
) => gql`
query GET_PROPERTY_BASED_ON_COMPANY{
  count: property_group_master(
    where:{
      group_name:{iregex:"${searchText}"}
      is_active:{eq:true}
      company_id:${company}

    }
  ) {
    count:count_id
  }

     property_group_master(
      where: { is_active: { eq: true }
       group_name:{iregex:"${searchText}"}
           company_id:${company}
    }
       offset:${offset}
       limit:${limit}
      ) {
    value: id
    label: group_name

  }
}
`;
export let GET_FESILITIES_LIST = (offset, limit, searchText) => gql`
query GET_MASTERS_FOR_CREATING_BLOCK{
  count: amenities_type_master(
    where:{
      amenities_name:{iregex:"${searchText}"}
      is_active:{eq:true}
        amenities_type: "Property"
    }
  ) {
    count:count_id
  }
  amenities_type_master(
      where:{
        amenities_name:{iregex:"${searchText}"}
        is_active:{eq:true}
          amenities_type: "Property"
      }
      offset:${offset}
      limit:${limit}
    ){
        label:amenities_name
      	value:id
      }
}
`;

export const GET_ROLES = () => gql`
  query {
    committee_roles {
      value: id
      label: role_name
    }
  }
`;

export const ADD_ROLE = `
mutation {
  management_committee(insert: $data) {
    id
  }
}`;

export const GET_MEASURMWENT_UNIT = `
query{
  company_master(where: { id: { eq: $id } }){
      uom
  }
}`;

export const GET_UNIT_TYPE = (offset, limit, searchText, name, id) => gql`
query GET_UNIT_TYPE {
  count: unit_type_master(
    where: {
      is_active: { eq: true }
      name: { iregex: "${searchText}" }
      company_id:{eq:"${id}"}
    }
  ) {
    count: count_id
  }
  unit_type_master(
    where: {
      is_active: { eq: true }
      name: { iregex: "${searchText}" }
      company_id:{eq:"${id}"}
    }
    offset:${offset}
    limit:${limit}
  ) {
    value:id
    label:name
  }
}

`;

export const GET_PROPERTY_GROUP_PURPOSE = (
  offset,
  limit,
  searchText,
  purpose,
  id
) =>
  gql`
query GET_PROPERTY_BASED_ON_COMPANY{
  count: property_group_master(
    where:{
      group_name:{iregex:"${searchText}"}
      is_active:{eq:true}
      ${
        purpose
          ? `
        purpose: { eq: "${purpose}" }
        `
          : ""
      }
        company_id:{eq:${id}}
    }
  ) {
    count:count_id
  }

     property_group_master(
      where: {
       group_name:{iregex:"${searchText}"}
       is_active: { eq: true }
     ${
       purpose
         ? `
        purpose: { eq: "${purpose}" }
        `
         : ""
     }
       company_id:{eq:${id}}
    }
       offset:${offset}
       limit:${limit}
      ) {
    value: id
    label: group_name

  }
}
`;
export let GET_CURRENCY = (offset, limit, searchText) => gql`
query GET_CURRENCY{
  count: currency_master(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
    }
  ) {
    count:count_id
  }
  currency_master(
      where:{
        name:{iregex:"${searchText}"}
        is_active:{eq:true}
      }
      offset:${offset}
      limit:${limit}
    ){
        label:symbol
      	value:id
      }
}
`;

export let GET_OPTIONS_FOR_PRICING_TABLE_FORM = (
  offset,
  limit,
  type,
  searchText,
  companyID,
  countryID,
  revenueType,
  categoryQuestionSet
) => gql`
  query GET_OPTIONS_FOR_PRICING_TABLE_FORM {
    count: ${type} (
      where: {
        is_active: { eq: true }
        ${
          type === "pricing_component"
            ? `
        name: { iregex: "${searchText}" }
        revenue_type: { eq: "${revenueType}" }
        company_id: { eq: ${companyID} }
        primary: { eq: ${categoryQuestionSet?.isPrimary} }
        is_onetime: { eq: ${categoryQuestionSet?.isOneTime} }
        refundable: { eq: ${categoryQuestionSet?.isRefundable} }
        is_quantity: { eq: ${categoryQuestionSet?.isQuantity} }
        is_parking: { eq: ${categoryQuestionSet?.isParking} }
        is_wallet: { eq: ${categoryQuestionSet?.is_wallet} }
        is_late_fee: { eq: ${categoryQuestionSet?.is_late_fee} }
        is_commission_payment: { eq: ${categoryQuestionSet?.is_commission_payment} }
        is_delete: { eq: false }

        `
            : ""
        }
        ${
          type === "vat_group_master"
            ? `
        group_name: { iregex: "${searchText}" }
        ${!!countryID ? `country_id: { eq: ${countryID} }` : ""}
        is_delete: { eq: false }

        `
            : ""
        }
        ${
          type === "inspection_items"
            ? `
        is_active: { eq: true }
        pantry_billing: { eq: true }
        name: { iregex: "${searchText}" }
        company_id: { eq: ${companyID} }
        `
            : ""
        }
      }
    ) {
      count: count_id
    }
    ${
      type === "pricing_component"
        ? `
    pricing_component(
      where: {
        is_active: { eq: true }
        is_delete: { eq: false }
        name: { iregex: "${searchText}" }
        company_id: { eq: ${companyID} }
        revenue_type: { eq: "${revenueType}" }
        primary: { eq: ${categoryQuestionSet?.isPrimary} }
        is_onetime: { eq: ${categoryQuestionSet?.isOneTime} }
        refundable: { eq: ${categoryQuestionSet?.isRefundable} }
        is_quantity: { eq: ${categoryQuestionSet?.isQuantity} }
        is_parking: { eq: ${categoryQuestionSet?.isParking} }
        is_wallet: { eq: ${categoryQuestionSet?.is_wallet} }
        is_late_fee: { eq: ${categoryQuestionSet?.is_late_fee} }
        is_commission_payment: { eq: ${categoryQuestionSet?.is_commission_payment} }
      }
      offset:${offset}
      limit:${limit}
    ) {
      value: id
      label: name
      id
      primary
      refundable
      taxtable
      is_onetime
      is_reserve_quality
      name
      revenue_type
      is_parking
      is_override
      is_short_term
      is_security
      is_quantity
      company_id
    }
        `
        : ""
    }
    ${
      type === "vat_group_master"
        ? `
    vat_group_master(
      where: {
        is_active: { eq: true }
        is_delete: { eq: false }
        group_name: { iregex: "${searchText}" }
        ${!!countryID ? `country_id: { eq: ${countryID} }` : ""}
      }
      offset:${offset}
      limit:${limit}
    ) {
      value: id
      label: group_name
      id
      group_name
      company_id
    }
        `
        : ""
    }
    ${
      type === "inspection_items"
        ? `
        inspection_items(
          where: {
            is_active: { eq: true }
            pantry_billing: { eq: true }
            name: { iregex: "${searchText}" }
            company_id: { eq: ${companyID} }
          }
          offset:${offset}
          limit:${limit}
        ) {
          value: id
          label: name
          period_type
          id
          vat_group_id
          sale_value
          taxtable:vat_group_id
          vat_group:vat_groupByID {
            value:id
            label:group_name
      }
        }
            `
        : ""
    }

  }`;

export const GET_COMPONENT_BASED_PRICING = gql`
  query GET_COMPONENT_BASED_PRICING {
    count: pricing_table(
      where: {
        is_active: { eq: true }
        unit_id: { eq: $unitID }
        or: {
          rental_breakup: { name: { iregex: $searchText } }
          inspection_item: { name: { iregex: $searchText } }
        }
      }
    ) {
      count: count_id
    }
    pricing_table(
      order_by: { created_at: asc }
      where: {
        is_active: { eq: true }
        unit_id: { eq: $unitID }
        or: {
          rental_breakup: { name: { iregex: $searchText } }
          inspection_item: { name: { iregex: $searchText } }
        }
      }
      offset: $offset
      limit: $limit
    ) {
      id
      revenue_type
      quantity
      is_wallet_item
      rental_breakup_id
      rental_breakupByID {
        id
        name
        value: id
        label: name
        taxtable
        is_wallet
        is_late_fee
        is_commission_payment
      }
      inspection_itemByID {
        id
        name
        value: id
        label: name
        taxtable: vat_group_id
      }
      payment_period
      value
      value_basis_type
      currencyByID {
        id
        code
      }
      taxable
      is_chargeable
      annual_rental
      is_security
      uom
      vat_group_id
      vat_groupByID {
        id
        group_name
        value: id
        label: group_name
      }
      vat_group_master {
        id
        group_name
        value: id
        label: group_name
      }
      primary
      refundable
      is_onetime
      is_reserve_quality
      is_parking
      is_quantity
      is_price_overide
      payment_period
      company_id
      companyByID {
        id
        country_id
      }
      property_id
      unit_id
      unitByID {
        id
        uomByID {
          id
          value: id
          label: name
        }
      }
      rera_pricing {
        id
        min
        max
        recommended_value
      }
    }
  }
`;

export const GET_BLOCK_DETAILS = gql`
  query ($id: String) {
    block(where: { id: { eq: $id } }) {
      logo
      year_built
      planned_hand_over_date
      revenue_type
      block_purpose
      uomByID {
        value: id
        label: name
      }
      property {
        website
        property_hierarchy
        property_hierarchyByID {
          value: id
          label: name
          is_block
          is_floor
          is_unit
          is_multi_unit
          is_customisable
          property_hierarchy_labelByID {
            id
            floor_label
            block_label
          }
        }
        property_groupByID: property_groupByID {
          id
          group_name
        }
        area_metric
        company: company_master {
          id
          name
        }
      }
      addressByID {
        mobile_no
        mobile_no_country_code
        alternative_mobile_no
        alternative_mobile_no_country_code
        addressLineOne: street_1
        addressLineTwo: street_2
        addressLineThree: street_3
        door_no
        landmark
        area
        city
        district
        state
        zipcode
        postOfficeBox: po_box
        country
        latitude
        longitude
        emailAddress: email_id
      }
    }
  }
`;

export const GET_CONFIGURE_DATA = gql`
  query GET_CONFIGURE_DATA(
    $offset: Int
    $limit: Int
    $client: String
    $searchText: String
  ) {
    count: sequence_config(
      where: { is_active: { eq: true }, table_name: { iregex: $searchText } }
    ) {
      count: count_id
    }

    sequence_config(
      where: { is_active: { eq: true }, table_name: { iregex: $searchText } }
      offset: $offset
      limit: $limit
      order_by: { table_name: asc }
    ) {
      id
      slug
      table_name
      date_format
      starts_with
      prefix
      preference
      seed_value
    }
  }
`;

export const GET_PROPERTY_BY_COMPANY = (offset, limit, searchText, id) => gql`
query GET_PROPERTY_BASED_ON_COMPANY{
  count: property(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
      company_id:{eq:"${id}"}

    }
  ) {
    count:count_id
  }

     property(
      where: { is_active: { eq: true }
       name:{iregex:"${searchText}"}
       company_id:{eq:"${id}"}
    }
       offset:${offset}
       limit:${limit}
      ) {
        value: id
        label: name

  }
}
`;
export const GET_PROPERTY_BY_COMPANY_Filter = (
  offset,
  limit,
  searchText,
  { id }
) => {
  return gql`
query GET_PROPERTY_BASED_ON_COMPANY{
  count: property(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
      company_id:{eq:"${id}"}

    }
  ) {
    count:count_id
  }

     property(
      where: { is_active: { eq: true }
       name:{iregex:"${searchText}"}
       company_id:{eq:"${id}"}
    }
       offset:${offset}
       limit:${limit}
      ) {
        value: id
        label: name

  }
}
`;
};

export const GET_ROLES_SALESAGENT = (offset, limit, searchText) => gql`
query GET_PROPERTY_BASED_ON_COMPANY{
  count: roles(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}

    }
  ) {
    count:count_id
  }

     roles(
      where: { is_active: { eq: true }
       name:{iregex:"${searchText}"}

    }
       offset:${offset}
       limit:${limit}
      ) {
        value: id
        label: name

  }
}
`;

export const GET_USERS_BY_ROLE_ID = gql`
  query GET_USERS_BY_ROLE_ID($clientID: String) {
    client_user_roles(
      where: {
        roles_id: { eq: $id }
        company_id: { eq: $company_id }
        user_profileByID: { first_name: { iregex: $search } }
        client: { eq: $clientID }
      }
      is_active: { eq: true }

      offset: $offset
      limit: $limit
    ) {
      roles_id
      user_profileByID {
        id
        first_name
        last_name
        email_id
        mobile_no
        mobile_no_country_code
        image_url
      }
    }
  }
`;
export const GET_UTILITIES_MASTER_TABLE_LIST = (
  client = "",
  offset = 0,
  limit = 10,
  searchText = "",
  companyID = [],
  status = []
) => {
  return gql`
query GET_UTILITIES_MASTER_TABLE_LIST($id:ID){
  count:utilities_master(
     where: {
        ${companyID?.length > 0 ? `company_id: { in: [${companyID}] }` : ""}
      ${status?.length > 0 ? `is_active:{in:[${status}]}` : ""}
        utility_name: { iregex: "${searchText}" }
        is_delete: {eq : false},
                client:{eq:"${client}"}

    }){
      count:count_id
    }

  utilities_master(
     where: {
    ${companyID?.length > 0 ? `company_id: { in: [${companyID}] }` : ""}
      ${status?.length > 0 ? `is_active:{in:[${status}]}` : ""}
       utility_name: { iregex: "${searchText}" }
               is_delete: {eq : false}
                       client:{eq:"${client}"}


    }
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc }
  ) {
    id
   utility_name
   name: utility_name
   price: per_unit_price
   status: is_active
    is_active
    description
    is_delete
    item_master:inspection_items{
      label:name
      value:id
      inspection_item_category{
        label:name
        value:id
        item_type
      }
    }
  }
}

`;
};

export const GET_UTILITIES_MASTER_BY_COMPANY = (
  offset,
  limit,
  searchText,
  id
) => gql`
query GET_UTILITIES_MASTER_BY_COMPANY{
  count: utilities_master(
    where:{
      utility_name:{iregex:"${searchText}"}
      is_active:{eq:true}
      company_id:{eq:"${id}"}

    }
  ) {
    count:count_id
  }

     utilities_master(
      where: { is_active: { eq: true }
       utility_name:{iregex:"${searchText}"}
       company_id:{eq:"${id}"}
    }
       offset:${offset}
       limit:${limit}
      ) {
        value: id
        label: utility_name

  }
}
`;
export const GET_UNIT_UTILITIES_TABLE_LIST = (
  client = "",
  offSet = 0,
  limit = 10,
  search = "",
  unitId = "",
  status = []
) => {
  return gql`
query GET_UNIT_UTILITIES_TABLE_LIST($id:ID){
  count: unit_utilties(
    where: {
       ${unitId?.length > 0 ? `unit_id: { eq: "${unitId}" }` : ""}
      ${status?.length > 0 ? `is_active:{in:[${status}]}` : ""}

      utilities_master:
      {
        utility_name: { iregex: "${search}"
       }
      }
      is_delete: {eq:false}
              client:{eq:"${client}"}

    })
 {
    count: count_id
  }
  unit_utilties(
    where: {
      ${unitId?.length > 0 ? `unit_id: { eq: "${unitId}" }` : ""}
      ${status?.length > 0 ? `is_active:{in:[${status}]}` : ""}

      utilities_master: { utility_name: { iregex: "${search}" } }
      is_delete: {eq:false}
              client:{eq:"${client}"}


    }
  offset: ${offSet}
    limit: ${limit}
    order_by: { created_at: desc }

  ) {
    unit_utilties_reference_no: reference_no
    provider: utilties_provider
    serial_number: meter_serial_no
    last_reading: last_reading
    current_reading: last_reading
    status: is_active
    id
    meter_serial_no
    price
    period
    is_active
    description
    utilties_provider
    prev_reading
    last_reading
    utilities_master {
      value:id
      label:utility_name
       currency{
        id
        symbol
      }
    }
  }
}

`;
};

export const GET_PROXY_LEAD_DETAILS = (
  companyID,
  offSet,
  limit,
  searchText,
  leadSource,
  status,
  clientID
) => {
  return gql`
query GET_PROXY_LEAD_DETAILS(
  $leadSource: [String!]
) {
  count: proxy_lead(
    where: {
      is_active:{eq:true}
      client:{eq:"${clientID}"}
      company_id: { eq: ${companyID} }
      or:{
        lead_name: { iregex: "${searchText}" },
reference_no: { iregex: "${searchText}" }
      }
    ${leadSource?.length > 0 ? ` lead_source:{in:$leadSource} ` : ""}
    ${status?.length > 0 ? `status:["${status}"]` : ""}
    }
  ){
    count:count_id
  }
  proxy_lead(
    where: {
      is_active:{eq:true}
      company_id: { eq: ${companyID} }
       client:{eq:"${clientID}"}
       or:{
        lead_name: { iregex: "${searchText}" },
reference_no: { iregex: "${searchText}" }
      }
      ${leadSource?.length > 0 ? ` lead_source:{in:$leadSource} ` : ""}
      ${status?.length > 0 ? `status:["${status}"]` : ""}
    }
    offset: ${offSet}
    limit: ${limit}
    order_by: { created_at: desc }
  ) {
    id
    lead_name
    reference_no
    lead_owner{
      id
      first_name
      last_name
    }
    lead_source
    purpose
    description
    email
    mobile_no
    mobile_country_code
    created_at
    updated_at
    status
  }
}`;
};

export const GET_PROXY_LEAD_VIEW = gql`
  query GET_PROXY_LEAD_VIEW($id: String) {
    proxy_lead(where: { is_active: { eq: true }, id: { eq: $id } }) {
      id
      lead_name
      job_title
      company
      business_phone_country_code
      business_phone
      mobile_country_code
      mobile_no
      email
      address_1
      address_2
      city
      country_master {
        id
        country_name
      }
      archieved_description
      remarks
      website
      preferred_contact
      referrer_name
      referrer_company
      is_showcase
      last_showcase_date
      purpose
      revenue_type
      lease_start_date
      value
      lease_period
      domain_referrer
      campaign_source
      description
      is_existing_customer
      lead_source
      internal_lead_source {
        id
        name
      }
      property {
        id
        name
      }
      unit_type_master {
        id
        name
      }
      preferred_mode_contact
      budget
      budget_amount
      is_confirm_interest
      expected_close_period
      reference_no
      name: lead_name
      member: lead_owner {
        id
        first_name
        last_name
        email_id
        mobile_no
        mobile_no_country_code
        image_url
      }
      source: lead_source
      comments
      status
      unit_usage
      urgent
      classify_lead_id
      classify_lead {
        value: id
        label: name
      }
      proxy_lead_units(where: { is_active: true }) {
        unitByID {
          id
          logo
          unit_name: name
          unit_no
          propertyByID {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_USERPROFILE_BY_ID = gql`
  query GET_USER_NAME($id: String) {
    user_profiles(where: { is_active: { eq: true }, id: { eq: $id } }) {
      first_name
      last_name
      image_url
    }
  }
`;

export const GET_ROLES_CONTACT = (offset, limit, searchText) => gql`
  query GET_ROLES_CONTACT{
    count: roles(
      where:{
        name:{iregex:"${searchText}"}
        is_active:{eq:true}

      }
    ) {
      count:count_id
    }
       roles(
        where: { is_active: { eq: true }
         name:{iregex:"${searchText}"}

      }
         offset:${offset}
         limit:${limit}
        ) {
          value: id
          label: name

    }
  }`;

// export const GET_VENDOR_LIST_BY_PROPERTY_ID = (offset, limit, searchText, propertyIDS) => gql`
// query GET_VENDOR_LIST_BY_PROPERTY_ID {
//   count: vendor_master(
//     where: {
//       is_active: { eq: true }
//       property_id: { in: [${propertyIDS}] }
//       name: { iregex: "${searchText}" }
//     }
//   ) {
//     count: count_id
//   }
//   vendor_master(
//     where: {
//       is_active: { eq: true }
//       property_id: { in: [${propertyIDS}] }
//       name: { iregex: "${searchText}" }
//     }
//     order_by: { name: asc }
//     offset: ${offset}
//     limit: ${limit}
//   ) {
//     value: id
//     label: name
//   }
// }`;

export const GET_VENDOR_LIST_BY_PROPERTY_ID = (
  offset,
  limit,
  searchText,
  extraParams = {}
) => gql`
  query GET_VENDOR_LIST_BY_PROPERTY_ID {
    count: vendor_master(
      where: {
        is_active: { eq: true }
        name: { iregex: "${searchText}" }
        ${
          extraParams?.companyID
            ? `company_id: { eq: ${extraParams?.companyID} }`
            : ""
        }
      }
    ) {
      count: count_id
    }
    vendor_master(
      where: {
        is_active: { eq: true }
        name: { iregex: "${searchText}" }
        ${
          extraParams?.companyID
            ? `company_id: { eq: ${extraParams?.companyID} }`
            : ""
        }
      }
      order_by: { name: asc }
      offset: ${offset}
      limit: ${limit}
    ) {
      value: id
      label: name
    }
  }`;
export const GET_AMENITIES_MASTER_TABLE_LIST = (
  client = "",
  offset = 0,
  limit = 10,
  searchText = "",
  companyID = [],
  status = []
) => {
  return gql`
query GET_AMENITIES_MASTER_TABLE_LIST($id:ID){
  count:amenities_type_master(
     where: {
        ${companyID?.length > 0 ? `company_id: { in: [${companyID}] }` : ""}
      ${status?.length > 0 ? `is_active:{in:[${status}]}` : ""}
        amenities_name: { iregex: "${searchText}" }
        is_delete: {eq : false}
        client:{eq:"${client}"}
    }){
      count:count_id
    }

  amenities_type_master(
     where: {
    ${companyID?.length > 0 ? `company_id: { in: [${companyID}] }` : ""}
      ${status?.length > 0 ? `is_active:{in:[${status}]}` : ""}
       amenities_name: { iregex: "${searchText}" }
               is_delete: {eq : false}
                       client:{eq:"${client}"}


    }
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc }
  ) {
    id
   amenities_name
   amenities_type
   is_booking
    is_active
    is_delete
  }
}

`;
};

export const GET_PROPERTY_AMENITIES_TABLE_LIST = (
  client = "",
  offset = 0,
  limit = 10,
  searchText = "",
  propertyId = "",
  status = [],
  chargeable = []
) => {
  return gql`
query GET_PROPERTY_AMENITIES_TABLE_LIST($id:ID){
  count:property_facilities(
     where: {
        ${propertyId?.length > 0 ? `property_id: { eq: "${propertyId}" }` : ""}
      ${status?.length > 0 ? `is_active:{in:[${status}]}` : ""}
      ${chargeable?.length > 0 ? `is_chargeable:{in:[${chargeable}]}` : ""}
                    client:{eq:"${client}"}

      amenities_type_master:{
         amenities_name: { iregex:"${searchText}" }
      }
        is_delete: {eq : false}
    }){
      count:count_id
    }

  property_facilities(
     where: {
     ${propertyId?.length > 0 ? `property_id: { eq: "${propertyId}" }` : ""}
      ${status?.length > 0 ? `is_active:{in:[${status}]}` : ""}
      ${chargeable?.length > 0 ? `is_chargeable:{in:[${chargeable}]}` : ""}
                    client:{eq:"${client}"}

 amenities_type_master:{
         amenities_name: { iregex:"${searchText}" }
      }
              is_delete: {eq : false}

    }
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc }
  ) {
     id
   amenities_type
    is_active
    is_delete
    is_chargeable
    description
    rate
    period
    amenities_type
    currencyByID{
      id
      symbol
    }
    amenities_type_master{
      amenities_name
      id
      is_booking
    }
  }
}
`;
};
export const GET_AMENITIES_TYPE_OPTIONS = (
  offset,
  limit,
  searchText,
  type = "",
  companyId = ""
) => gql`
query GET_AMENITIES_TYPE_OPTIONS{
   count:amenities_type_master(
     where: {
     amenities_type:"${type}"
     amenities_name:{iregex:"${searchText}"}
      company_id: ${companyId}
    }){
      count:count_id
    }

  amenities_type_master(
     where: {
     amenities_type:"${type}"
     amenities_name:{iregex:"${searchText}"}
      company_id: ${companyId}
    }
     offset:${offset}
       limit:${limit}

  ) {
   label:amenities_name
   value:id
}
}`;

export const GET_UNIT_AMENITIES_TABLE_LIST = (
  client = "",
  offset = 0,
  limit = 10,
  searchText = "",
  unitId = "",
  status = [],
  chargeable = []
) => {
  return gql`
query GET_UNIT_AMENITIES_TABLE_LIST($id:ID){
  count:unit_amenities_breakup(
     where: {
        ${unitId?.length > 0 ? `unit_id: { eq: "${unitId}" }` : ""}
      ${status?.length > 0 ? `is_active:{in:[${status}]}` : ""}
            ${
              chargeable?.length > 0 ? `is_chargeable:{in:[${chargeable}]}` : ""
            }
                          client:{eq:"${client}"}


      amenities_type_master:{
         amenities_name: { iregex:"${searchText}" }
      }
        is_delete: {eq : false}
    }){
      count:count_id
    }

  unit_amenities_breakup(
     where: {
     ${unitId?.length > 0 ? `unit_id: { eq: "${unitId}" }` : ""}
      ${status?.length > 0 ? `is_active:{in:[${status}]}` : ""}
            ${
              chargeable?.length > 0 ? `is_chargeable:{in:[${chargeable}]}` : ""
            }
                          client:{eq:"${client}"}

 amenities_type_master:{
         amenities_name: { iregex:"${searchText}" }
      }
              is_delete: {eq : false}

    }
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc }
  ) {
     id
   amenities_type
    is_active
    is_delete
    is_chargeable
    notes
    rate
    period
    amenities_type
    currencyByID{
      id
      symbol
    }
    amenities_type_master{
      amenities_name
      id
      is_booking
    }
  }
}
`;
};

export const GET_PRICING_COMPONENT = (
  offset,
  limit,
  searchText,
  extraParams = {},
  clientID
) => gql`
query GET_PRICING_COMPONENT {
  count: pricing_component(
    where: {
      is_delete: { eq: false }
      name: { iregex: "${searchText}" }
      ${
        extraParams?.status ? `is_active: { in: [${extraParams?.status}] }` : ""
      }
      ${
        extraParams?.companyID
          ? `company_id: { eq: ${extraParams?.companyID} }`
          : ""
      }
      client:{eq:"${clientID}"}
      ${
        extraParams?.component_type_filters &&
        extraParams?.component_type_filters?.length > 0
          ? extraParams?.component_type_filters
          : ``
      }
      ${
        extraParams?.revenue_type && extraParams?.revenue_type?.length > 0
          ? `revenue_type: { in: $revenue_type }`
          : ""
      }
    }
  ) {
    count: count_id
  }
  pricing_component(
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc, name: asc }
    where: {
      is_delete: { eq: false }
      name: { iregex: "${searchText}" }
      ${
        extraParams?.status ? `is_active: { in: [${extraParams?.status}] }` : ""
      }
      ${
        extraParams?.companyID
          ? `company_id: { eq: ${extraParams?.companyID} }`
          : ""
      }
      client:{eq:"${clientID}"}
      ${
        extraParams?.component_type_filters &&
        extraParams?.component_type_filters?.length > 0
          ? extraParams?.component_type_filters
          : ``
      }
      ${
        extraParams?.revenue_type && extraParams?.revenue_type?.length > 0
          ? `revenue_type: { in: $revenue_type }`
          : ""
      }
    }
  ) {
    id
    company_id
    companyByID(where: { is_active: { eq: true } }) {
      id
      name
      auto_deposit_calc
      auto_reservation_calc
    }
    name
    inspection_items_id
    inspection_itemsByID{
      value: id
      label: name
    }
    revenue_type
    description
    is_active
    primary
    refundable
    is_onetime
    is_reserve_quality
    taxtable
    is_parking
    is_quantity
    is_override
    is_short_term
    is_external
    is_security
    hsn_sac_code
    tax_group
    vat_group_master{
      value:id
      label:group_name
  }
  is_wallet
  is_late_fee
  is_commission_payment
  }
}`;

export const PROPERTY_DETAILS_BY_BLOCK_ID = gql`
  query PROPERTY_DETAILS_BY_BLOCK_ID {
    block(where: { id: { eq: $blockID } }) {
      id
      logo
      name
      year_built
      planned_hand_over_date
      block_purpose
      revenue_type
      property_id
      addressByID {
        email_id
        door_no
        street_1
        street_2
        landmark
        area
        city
        state
        country
        zipcode
        latitude
        longitude
        mobile_no
        mobile_no_country_code
        alternative_mobile_no
        alternative_mobile_no_country_code
      }
      propertyByID {
        id
        uomByID {
          value: id
          label: name
        }
        logo
        website
        property_hierarchy
        property_hierarchyByID {
          value: id
          label: name
          is_block
          is_floor
          is_unit
          is_multi_unit
          is_customisable
          property_hierarchy_labelByID {
            id
            floor_label
            block_label
          }
        }
        is_block
        is_floor
        companyByID {
          value: id
          label: name
        }
        property_groupByID {
          value: id
          label: group_name
        }
        area_metric
        payment_period
        addressByID {
          door_no
          street_1
          street_2
          landmark
          area
          city
          state
          country
          zipcode
          latitude
          longitude
        }
        year_built
        planned_hand_over_date
        business_phone_country_code
        business_phone
        mobile_country_code
        mobile_phone
        website
        email
      }
      unit {
        count: count_id
      }
    }
  }
`;

export const GET_COMPANY_LIST_FROM_CLIENT_USER_ROLES = gql`
  query GET_COMPANY_LIST_FROM_CLIENT_USER_ROLES($userProfileID: String) {
    client_user_roles(
      where: {
        is_active: { eq: true }
        rolesByID: { is_active: { eq: true } }
        companyByID: { is_active: { eq: true } }
        user_profile_id: { eq: $userProfileID }
        roles: { name: { eq: "Manager" } }
        client: { eq: $clientID }
      }
      order_by: { companyByID: { name: asc } }
      offset: 0
      limit: 10000
    ) {
      id
      rolesByID {
        id
        name
      }
      companyByID {
        id
        name
        company_no
      }
    }
  }
`;

export const GET_PROFESSION = gql`
  query GET_PROFESSION(
    $status: [Boolean]
    $companyID: Int
    $search: String
    $offset: Int
    $limit: Int
  ) {
    count: profession_master(
      where: {
        is_active: { in: $status }
        is_delete: { eq: false }
        client: { eq: $clientID }
        company_id: { eq: $companyID }
        name: { iregex: $search }
      }
    ) {
      count: count_id
    }
    profession_master(
      where: {
        is_active: { in: $status }
        is_delete: { eq: false }
        client: { eq: $clientID }
        company_id: { eq: $companyID }
        name: { iregex: $search }
      }
      order_by: { created_at: desc }
      offset: $offset
      limit: $limit
    ) {
      id
      name
      description
      is_active
      is_delete
      company_id
      client
    }
  }
`;

export const GET_DEPARTMENT = gql`
  query GET_DEPARTMENT(
    $status: [Boolean]
    $companyID: Int
    $search: String
    $offset: Int
    $limit: Int
  ) {
    count: department_master(
      where: {
        is_active: { in: $status }
        is_delete: { eq: false }
        client: { eq: $clientID }
        company_id: { eq: $companyID }
        name: { iregex: $search }
      }
    ) {
      count: count_id
    }
    department_master(
      where: {
        is_active: { in: $status }
        is_delete: { eq: false }
        client: { eq: $clientID }
        company_id: { eq: $companyID }
        name: { iregex: $search }
      }
      order_by: { created_at: desc }
      offset: $offset
      limit: $limit
    ) {
      id
      name
      description
      is_active
      is_delete
      company_id
      client
    }
  }
`;

export const GET_PARKING_AREA = gql`
  query GET_PARKING_AREA(
    $clientID: String
    $status: [Boolean]
    $companyID: Int
    $search: String
    $offset: Int
    $limit: Int
  ) {
    count: parking_area(
      where: {
        is_active: { in: $status }
        is_delete: { eq: false }
        client: { eq: $clientID }
        company_id: { eq: $companyID }
        vehicle_parking_area: { iregex: $search }
      }
    ) {
      count: count_id
    }
    parking_area(
      where: {
        is_active: { in: $status }
        is_delete: { eq: false }
        client: { eq: $clientID }
        company_id: { eq: $companyID }
        vehicle_parking_area: { iregex: $search }
      }
      order_by: { created_at: desc }
      offset: $offset
      limit: $limit
    ) {
      id
      propertyByID {
        label: name
        value: id
        id
        name
      }
      vehicle_parking_area
      parking_area_type
      description
      is_active
      is_delete
      company_id
      client
    }
  }
`;

export const GET_PROPERTIES_BY_COMPANY_ID = gql`
  query GET_PROPERTIES_BY_COMPANY_ID(
    $clientID: String
    $companyID: Int
    $search: String
    $offset: Int
    $limit: Int
  ) {
    count: property(
      where: {
        is_active: { eq: true }
        client: { eq: $clientID }
        company_id: { eq: $companyID }
        name: { iregex: $search }
      }
    ) {
      count: count_id
    }
    property(
      where: {
        is_active: { eq: true }
        client: { eq: $clientID }
        company_id: { eq: $companyID }
        name: { iregex: $search }
      }
      order_by: { name: asc }
      offset: $offset
      limit: $limit
    ) {
      label: name
      value: id
      id
      name
    }
  }
`;

export const GET_PROPERTIES_FOR_ACCESS_GATES_BY_COMPANY_ID = (
  propertyGroudID = []
) => {
  return gql`
query GET_PROPERTIES_FOR_ACCESS_GATES_BY_COMPANY_ID(
  $clientID: String
  $companyID: Int
  $status: [Boolean]
  $search: String
  $offset: Int
  $limit: Int
) {
  count: property(
    where: {
      is_active: { in: $status }
      client: { eq: $clientID }
      company_id: { eq: $companyID }
      ${
        propertyGroudID?.length
          ? `property_group_id: { in: [${propertyGroudID}] }`
          : ""
      }
      name: { iregex: $search }
    }
  ) {
    count: count_id
  }
  property(
    where: {
      is_active: { in: $status }
      client: { eq: $clientID }
      company_id: { eq: $companyID }
      ${
        propertyGroudID?.length
          ? `property_group_id: { in: [${propertyGroudID}] }`
          : ""
      }
      name: { iregex: $search }
    }
    order_by: { name: asc }
    offset: $offset
    limit: $limit
  ) {
    id
    is_active
    name
    address_id
    addressByID {
      city
      district
    }
    access_gates_masterByID(
       where: {
      is_active: { eq: true }
      is_delete: { eq: false }
        }
    ) {
      count: count_id
    }
    property_group_id
    property_groupByID {
      id
      group_name
    }
  }
}`;
};

export const GET_SECURITY_GUARD_BY_PROPERTY_ID = gql`
  query GET_SECURITY_GUARD_BY_PROPERTY_ID(
    $clientID: String
    $propertyID: String
    $search: String
    $offset: Int
    $limit: Int
  ) {
    count: access_control(
      where: {
        is_active: { eq: true }
        client: { eq: $clientID }
        property_id: { eq: $propertyID }
        rolesByID: { name: { eq: "Security Guard" } }
        user_profilesByID: { first_name: { iregex: $search } }
      }
    ) {
      count: count_id
    }
    access_control(
      where: {
        is_active: { eq: true }
        client: { eq: $clientID }
        property_id: { eq: $propertyID }
        rolesByID: { name: { eq: "Security Guard" } }
        user_profilesByID: { first_name: { iregex: $search } }
      }
      order_by: { user_profiles: { first_name: asc } }
      offset: $offset
      limit: $limit
    ) {
      rolesByID {
        id
        name
      }
      user_profilesByID {
        value: id
        label: first_name
      }
    }
  }
`;

export const GET_ACCESS_GATE = gql`
  query GET_ACCESS_GATE(
    $clientID: String
    $status: [Boolean]
    $propertyID: String
    $search: String
    $offset: Int
    $limit: Int
  ) {
    count: access_gates_master(
      where: {
        is_active: { in: $status }
        is_delete: { eq: false }
        client: { eq: $clientID }
        property_id: { eq: $propertyID }
        or: { name: { iregex: $search }, gate_no: { iregex: $search } }
      }
    ) {
      count: count_id
    }
    access_gates_master(
      where: {
        is_active: { in: $status }
        is_delete: { eq: false }
        client: { eq: $clientID }
        property_id: { eq: $propertyID }
        or: { name: { iregex: $search }, gate_no: { iregex: $search } }
      }
      order_by: { created_at: desc }
      offset: $offset
      limit: $limit
    ) {
      id
      name
      gate_no
      gate_type
      user_profileByID {
        label: first_name
        value: id
        id
        first_name
      }
      description
      is_active
      is_delete
      company_id
      property_id
      client
      value: id
      label: name
    }
  }
`;
export const DASHBOARD_COUNTS = gql`
  query {
    location: company_address(
      where: {
        is_active: true
        client: { eq: $clientID }
        company_id: { in: $companyID }
      }
    ) {
      latitude
      longitude
    }
    propertyCount: property(
      where: {
        is_active: true
        client: { eq: $clientID }
        company_id: { in: $companyID }
      }
    ) {
      count: count_id

      __typename
    }
    propertyMap: property(
      where: {
        is_active: true
        client: { eq: $clientID }
        company_id: { in: $companyID }
      }
    ) {
      id
      name
      logo
      year_built
      type: property_groupByID {
        id
        group_name
      }
      units: unit {
        id
      }
      address {
        latitude
        longitude
        country
        district
      }

      __typename
    }
    vacantunitCount: unit(
      where: {
        is_active: true
        status: "Vacant"
        client: { eq: $clientID }
        company_id: { in: $companyID }
      }
    ) {
      count: count_id
      __typename
    }
    ReservedunitCount: unit(
      where: {
        is_active: true
        status: "Reserved"
        client: { eq: $clientID }
        company_id: { in: $companyID }
      }
    ) {
      count: count_id
      __typename
    }
    OccupiedunitCount: unit(
      where: {
        is_active: true
        status: "Occupied"
        client: { eq: $clientID }
        company_id: { in: $companyID }
      }
    ) {
      count: count_id
      __typename
    }
    unitCount: unit(
      where: {
        is_active: true
        status: "Vacant"
        client: { eq: $clientID }
        company_id: { in: $companyID }
      }
    ) {
      count: count_id
      __typename
    }
    unitlistCount: unit(
      where: {
        is_active: true
        is_listing: true
        client: { eq: $clientID }
        company_id: { in: $companyID }
      }
    ) {
      count: count_id
      __typename
    }
    residentCount: resident(
      where: { client: { eq: $clientID }, company_id: { in: $companyID } }
    ) {
      count: count_id
      __typename
    }
    ownerCount: owner(
      where: { client: { eq: $clientID }, company_id: { in: $companyID } }
    ) {
      count: count_id
      __typename
    }

    generalCount: request(
      where: {
        client: { eq: $clientID }
        request_type: { eq: "General" }
        company_id: { in: $companyID }
      }
    ) {
      count: count_id
    }

    maintenanceCount: request(
      where: {
        client: { eq: $clientID }
        request_type: { eq: "Maintenance" }
        company_id: { in: $companyID }
      }
    ) {
      count: count_id
    }
  }
`;
export const UNIT_VACANCY_DETAILS = () => gql`
  query ($unitId: String, $clientID: String) {
    count: unit_vacancy(
      where: {
        unit_id: { eq: $unitId }
        client: { eq: $clientID }
        is_active: true
      }
    ) {
      count: count_id
    }
    unit_vacancy(
      where: {
        unit_id: { eq: $unitId }
        client: { eq: $clientID }
        is_active: true
      }
      order_by: { available_from: desc }
    ) {
      id
      unit_id
      company_name
      logo
      available_from
      available_to
      unit_no
      name
      agreement_no
      first_name
      last_name
      status
      mobile_no
      mobile_no_country_code
      current_status
      logo
    }
  }
`;

export const GET_SERVICE_PROVIDER_LIST = gql`
  query GET_SERVICE_PROVIDER_LIST(
    $status: [Boolean]
    $clientID: String
    $companyID: Int
    $search: String
    $offset: Int
    $limit: Int
  ) {
    count: service_providers_master(
      where: {
        company_id: { eq: $companyID }
        is_active: { in: $status }
        is_delete: { eq: false }
        client: { eq: $clientID }
        or: {
          name: { iregex: $search }
          service_provider_code: { iregex: $search }
        }
      }
    ) {
      count: count_id
    }
    service_providers_master(
      where: {
        is_active: { in: $status }
        is_delete: { eq: false }
        client: { eq: $clientID }
        company_id: { eq: $companyID }
        or: {
          name: { iregex: $search }
          service_provider_code: { iregex: $search }
        }
      }
      order_by: { created_at: desc }
      offset: $offset
      limit: $limit
    ) {
      id
      image_url
      name
      service_provider_code
      service_providers_addressByID {
        id
        city
        mobile_code
        mobile_no
        email_id
      }
      is_active
    }
  }
`;

export const GET_SERVICE_PROVIDER = `
query GET_SERVICE_PROVIDER($serviceProviderID:ID){
  service_providers_master(where:{id:$serviceProviderID}) {
    id
    image_url
    companyByID {
      id
      name
      value: id
      label: name
    }
    profession_masterByID {
      id
      name
      value: id
      label: name
    }
    name
    service_provider_code
    description
    service_providers_addressByID {
      id
      provider_id
      door_no
      street_1
      street_2
      landmark
      area
      city
      state
      country
      pincode
      latitude
      longitude
      mobile_code
      mobile_no
      telephone_code
      telephone_no
      email_id
    }
    bank_name
    branch_name
    currency_masterByID {
      id
      name
      code
      symbol
      value: id
      label: name
    }
    account_type
    account_no
    routing_type
    routing_code
    cash_collection_office
    cheque_name
    address_1
    address_2
    bank_city
    bank_country
    company_registration
    company_registration_no
    facebook
    twitter
    linkedin
    whatsapp
    swift_in_out_logByID(where: { is_active: { eq: true } }) {
      count: count_id
    }
  }
}`;

export const GET_PROPERTY_TYPES = (
  offset,
  limit,
  searchText,
  purpose,
  id
) => gql`
query GET_PROPERTY_BASED_ON_COMPANY{
  count: property_group_master(
    where:{
      group_name:{iregex:"${searchText}"}
      is_active:{eq:true}

    }
    offset:${offset}
    limit:${limit}
  ) {
    count:count_id
  }

     property_group_master(
      where: { is_active: { eq: true }
       group_name:{iregex:"${searchText}"}
    }
       offset:${offset}
       limit:${limit}
      ) {
    value: id
    label: group_name

  }
}
`;
export const GET_PROFESSION_LIST = gql`
  query GET_PROFESSION_LIST(
    $clientID: String
    $companyID: Int
    $search: String
    $offset: Int
    $limit: Int
  ) {
    count: profession_master(
      where: {
        is_active: { eq: true }
        is_delete: { eq: false }
        client: { eq: $clientID }
        company_id: { eq: $companyID }
        name: { iregex: $search }
      }
    ) {
      count: count_id
    }
    profession_master(
      where: {
        is_active: { eq: true }
        is_delete: { eq: false }
        client: { eq: $clientID }
        company_id: { eq: $companyID }
        name: { iregex: $search }
      }
      order_by: { name: asc }
      offset: $offset
      limit: $limit
    ) {
      value: id
      label: name
    }
  }
`;

export const GET_CURRENCY_LIST = gql`
  query GET_CURRENCY_LIST(
    # $clientID: String
    # $companyID: Int
    $search: String
    $offset: Int
    $limit: Int
  ) {
    count: currency_master(
      where: {
        is_active: { eq: true }
        is_delete: { eq: false }
        # client: { eq: $clientID }
        # company_id: { eq: $companyID }
        name: { iregex: $search }
      }
    ) {
      count: count_id
    }
    currency_master(
      where: {
        is_active: { eq: true }
        is_delete: { eq: false }
        # client: { eq: $clientID }
        # company_id: { eq: $companyID }
        name: { iregex: $search }
      }
      order_by: { name: asc }
      offset: $offset
      limit: $limit
    ) {
      value: id
      label: name
    }
  }
`;

export const GET_PARENT_UNIT_BY_PROPERTY_ID1 = gql`
  query GET_PARENT_UNIT_BY_PROPERTY_ID(
    $propertyID: ID
    $search: String
    $offset: Int
    $limit: Int
  ) {
    count: property(where: { id: { eq: $propertyID } }) {
      count: unitByID(
        where: {
          or: { name: { iregex: $search }, unit_no: { iregex: $search } }
          is_room: { eq: false }
        }
      ) {
        count: count_id
      }
    }
    property(where: { id: { eq: $propertyID } }) {
      unitByID(
        where: {
          or: { name: { iregex: $search }, unit_no: { iregex: $search } }
          is_room: { eq: false }
        }
        order_by: { name: asc }
        offset: $offset
        limit: $limit
      ) {
        value: id
        label: name
        id
        name
        unit_no
        revenue_type
        area_metric
        unit_type(where: { is_active: { eq: true } }) {
          value: id
          label: name
        }
        address(where: { is_active: { eq: true } }) {
          latitude
          longitude
        }
        unit_purpose
      }
    }
  }
`;

export const GET_PARENT_UNIT_BY_PROPERTY_ID = (
  propertyID,
  search,
  offset,
  limit,
  unitCategoryIDs,
  unitPurpose
) => gql`
query GET_PARENT_UNIT_BY_PROPERTY_ID{
  count: property(where: { id: { eq: "${propertyID}" } }) {
    count: unitByID(
      where: {
        or: { name: { iregex: "${search}" }, unit_no: { iregex: "${search}" } }
        unit_category_id: { in: [${unitCategoryIDs}] }
        unit_purpose: { eq: "${unitPurpose}" }
      }
    ) {
      count: count_id
    }
  }
  property(where: { id: { eq: "${propertyID}" } }) {
    unitByID(
      where: {
        or: { name: { iregex: "${search}" }, unit_no: { iregex: "${search}" } }
        unit_category_id: { in: [${unitCategoryIDs}] }
        unit_purpose: { eq: "${unitPurpose}" }
      }
      order_by: { name: asc }
      offset: ${offset}
      limit: ${limit}
    ) {
      value: id
      label: name
      id
      name
      unit_no
      revenue_type
      area_metric
      unit_type(where: { is_active: { eq: true } }) {
        value: id
        label: name
      }
      address(where: { is_active: { eq: true } }) {
        latitude
        longitude
      }
      unit_purpose
    }
  }
}`;

export const GET_JOB = gql`
  query GET_JOB(
    $status: [Boolean]
    $companyID: Int
    $search: String
    $offset: Int
    $limit: Int
  ) {
    count: job_master(
      where: {
        is_active: { in: $status }
        is_delete: { eq: false }
        client: { eq: $clientID }
        company_id: { eq: $companyID }
        name: { iregex: $search }
      }
    ) {
      count: count_id
    }
    job_master(
      where: {
        is_active: { in: $status }
        is_delete: { eq: false }
        client: { eq: $clientID }
        company_id: { eq: $companyID }
        name: { iregex: $search }
      }
      order_by: { created_at: desc }
      offset: $offset
      limit: $limit
    ) {
      id
      name
      description
      is_active
      is_delete
      company_id
      client
    }
  }
`;

export const GET_EMERGENCY_MASTER = gql`
  query GET_EMERGENCY_MASTER(
    $clientID: String
    $status: [Boolean]
    $companyID: Int
    $search: String
    $offset: Int
    $limit: Int
  ) {
    count: emergency_master(
      where: {
        is_active: { in: $status }
        is_delete: { eq: false }
        client: { eq: $clientID }
        company_id: { eq: $companyID }
        property_id: { in: $property_id }
        or: { name: { iregex: $search }, contact_number: { iregex: $search } }
      }
    ) {
      count: count_id
    }
    emergency_master(
      where: {
        is_active: { in: $status }
        is_delete: { eq: false }
        client: { eq: $clientID }
        company_id: { eq: $companyID }
        property_id: { in: $property_id }
        or: { name: { iregex: $search }, contact_number: { iregex: $search } }
      }
      order_by: { created_at: desc }
      offset: $offset
      limit: $limit
    ) {
      id
      propertyByID {
        label: name
        value: id
        id
        name
      }
      name
      department
      country_code
      contact_number
      is_active
      is_delete
      company_id
      client
    }
  }
`;

export const GET_DOMESTIC_HELPER_LIST = gql`
  query GET_DOMESTIC_HELPER_LIST(
    $status: [Boolean]
    $clientID: String
    $companyID: Int
    $search: String
    $offset: Int
    $limit: Int
  ) {
    count: domestic_helper_master(
      where: {
        is_active: { in: $status }
        is_delete: { eq: false }
        client: { eq: $clientID }
        company_id: { eq: $companyID }
        property_id: { in: $property_id }
        name: { iregex: $search }
      }
    ) {
      count: count_id
    }
    domestic_helper_master(
      where: {
        is_active: { in: $status }
        is_delete: { eq: false }
        client: { eq: $clientID }
        company_id: { eq: $companyID }
        property_id: { in: $property_id }
        name: { iregex: $search }
      }
      order_by: { created_at: desc }
      offset: $offset
      limit: $limit
    ) {
      id
      helper_image
      name
      gender
      domestic_helper_addressByID {
        id
        mobile_code
        mobile_no
      }
      identification_masterByID {
        id
        type
      }
      identification_no
      is_active
    }
  }
`;

export const GET_DOMESTIC_HELPER = gql`
  query GET_DOMESTIC_HELPER($domesticHelperID: ID) {
    domestic_helper_master(where: { id: { eq: $domesticHelperID } }) {
      id
      helper_image
      companyByID {
        id
        name
        value: id
        label: name
      }
      propertyByID {
        id
        name
        property_no
        value: id
        label: name
      }
      name
      gender
      nationality
      profession_masterByID {
        id
        name
        value: id
        label: name
      }
      identification_masterByID {
        id
        type
        value: id
        label: type
      }
      identification_no
      description
      domestic_helper_addressByID {
        id
        helper_id
        door_no
        street_1
        street_2
        landmark
        area
        city
        state
        country
        pincode
        latitude
        longitude
        mobile_code
        mobile_no
        telephone_code
        telephone_no
        email_id
      }
      bank_name
      branch_name
      currency_masterByID {
        id
        name
        code
        symbol
        value: id
        label: name
      }
      account_type
      account_no
      routing_type
      routing_code
      cash_collection_office
      cheque_name
      address_1
      address_2
      bank_city
      bank_country
      facebook
      twitter
      linkedin
      whatsapp
      is_barred
      proof: know_your_client {
        id
        domestic_helper_id
        proof_id
        valid_to
        valid_from
        name
        url
        is_active
        name
        is_mandatory
        id_no: number
        identification_master {
          id
          type
          proof_type
        }
        country_master {
          label: country_name
          value: id
        }
      }
    }
  }
`;

export const GET_IDENTIFICAITON_MASTER = gql`
  query GET_IDENTIFICAITON_MASTER(
    $clientID: String
    $companyID: Int
    $search: String
    $offset: Int
    $limit: Int
  ) {
    count: Identification_master(
      where: {
        is_active: { eq: true }
        client: { eq: $clientID }
        company_id: { eq: $companyID }
        type: { iregex: $search }
      }
    ) {
      count: count_id
    }
    Identification_master(
      where: {
        is_active: { eq: true }
        client: { eq: $clientID }
        company_id: { eq: $companyID }
        type: { iregex: $search }
      }
      order_by: { type: asc }
      offset: $offset
      limit: $limit
    ) {
      label: type
      value: id
      id
      type
    }
  }
`;

export const GET_INVOICE_TABLE_LIST = (
  client = "",
  offset = 0,
  limit = 10,
  searchText = "",
  companyID = [],
  status = []
) => {
  return gql`
query GET_INVOICE_TABLE_LIST($id:ID){
  count:invoice(
     where: {
        ${companyID?.length > 0 ? `company_id: { in: [${companyID}] }` : ""}
      ${status?.length > 0 ? `is_active:{in:[${status}]}` : ""}
        invoice_no: { iregex: "${searchText}" }
        client:{eq:"${client}"}
    }){
      count:count_id
    }

  invoice(
     where: {
    ${companyID?.length > 0 ? `company_id: { in: [${companyID}] }` : ""}
      ${status?.length > 0 ? `is_active:{in:[${status}]}` : ""}
       invoice_no: { iregex: "${searchText}" }
                       client:{eq:"${client}"}


    }
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc }
  ) {
    id
   invoice_no
   invoice_type
    agreement{
   id
      agreement_no
    }
    is_active
    account_no
    invoice_total_amount
    payment_due_date
    created_at
    payment_status
    invoice_due_amount
      property{
        id
        name
      }
  }
}

`;
};

export const companyDetailsInProprty = (id) => gql`
query Company_Details{
  company_master(where:{id:${id}}){
    payment_period
    uom
    company_addressByID{
      email_id
      website
      mobile_no_country_code
      mobile_no
      alternative_mobile_no_country_code:telephone_code
      alternative_mobile_no:telephone
    }
  }
}
`;

export const GET_AGREEMENT_REQUEST = gql`
  query GET_AGREEMENT_REQUEST(
    $status: [Boolean]
    $companyID: Int
    $requestPurpose: String
    $startDate: String
    $endDate: String
    $search: String
    $offset: Int
    $limit: Int
  ) {
    count: agreement_request(
      where: {
        is_active: { in: $status }
        agreementByID: { company_id: { eq: $companyID } }
        request_purpose: { eq: $requestPurpose }
        and: { created_at: { gte: $startDate }, created_at: { lt: $endDate } }
        or: {
          reference_id: { iregex: $search }
          agreementByID: { agreement_no: { iregex: $search } }
          created_by: { first_name: { iregex: $search } }
        }
      }
    ) {
      count: count_id
    }
    agreement_request(
      where: {
        is_active: { in: $status }
        agreementByID: { company_id: { eq: $companyID } }
        request_purpose: { eq: $requestPurpose }
        and: { created_at: { gte: $startDate }, created_at: { lt: $endDate } }
        or: {
          reference_id: { iregex: $search }
          agreementByID: { agreement_no: { iregex: $search } }
          created_by: { first_name: { iregex: $search } }
        }
      }
      order_by: { created_at: desc }
      offset: $offset
      limit: $limit
    ) {
      id
      reference_id
      agreement_id
      agreementByID {
        id
        agreement_no
        company_id
        no_of_units: agreement_unitsByID {
          count: count_id
        }
      }
      created_by {
        first_name
      }
      created_at
      tentative_termination_date
      status
    }
  }
`;
export const GET_DEPARTMENT_BY_COMPANY_ID = (
  offset,
  limit,
  searchText,
  id,
  clientId
) => gql`
query{
  count: department_master(where: {
    name: { iregex: "${searchText}" }
      is_active: { eq: true }
      company_id: { eq: "${id}" }
    client: { eq: "${clientId}" }

  }){
    count: count_id

  }

  department_master(where: {
    name: { iregex: "${searchText}" }
      is_active: { eq: true }
      company_id: { eq: "${id}" }
    client: { eq: "${clientId}" }
  }
   offset: ${offset}
       limit: ${limit}
  ){
    value: id
    label: name
  }
}
`;
export const GET_USERDETAILS_BY_DEPARTMENT_ID = gql`
  query {
    contact(
      where: {
        department_id: { eq: $id }
        company_id: { eq: $company_id }
        client: { eq: $clientID }
        is_active: { eq: true }
        first_name: { iregex: $search }
      }
      offset: $offset
      limit: $limit
    ) {
      id
      first_name
      department_id
      company_id
      image_url
      email_id
      mobile_no
      mobile_no_country_code
    }
  }
`;

export const GET_SHORT_TERM_PRICING = gql`
  query GET_SHORT_TERM_PRICING {
    count: short_term_pricing(
      where: {
        is_active: { eq: true }
        unit_id: { eq: $unitID }
        rental_breakup: { name: { iregex: $searchText } }
      }
    ) {
      count: count_id
    }
    short_term_pricing(
      order_by: { created_at: asc }
      where: {
        is_active: { eq: true }
        unit_id: { eq: $unitID }
        rental_breakup: { name: { iregex: $searchText } }
      }
      offset: $offset
      limit: $limit
    ) {
      id
      revenue_type
      quantity
      rental_breakup_id
      rental_breakupByID {
        id
        name
        value: id
        label: name
      }
      payment_period
      value
      value_basis_type
      currencyByID {
        id
        code
      }
      taxable
      is_chargeable
      annual_rental
      is_security
      uom
      vat_groupByID {
        id
        group_name
        value: id
        label: group_name
      }
      primary
      refundable
      is_onetime
      is_reserve_quality
      is_parking
      is_quantity
      is_price_overide
      payment_period
      company_id
      companyByID {
        id
        country_id
      }
      property_id
      unit_id
    }
  }
`;
export const GET_DELIVERY_MILESTONE_MASTER_TABLE_LIST = (
  client = "",
  offset = 0,
  limit = 10,
  searchText = "",
  companyID = [],
  status = []
) => {
  return gql`
query GET_DELIVERY_MILESTONE_MASTER_TABLE_LIST($id:ID){
  count:delivery_milestone_masters(
     where: {
        ${companyID?.length > 0 ? `company_id: { in: [${companyID}] }` : ""}
      ${status?.length > 0 ? `is_active:{in:[${status}]}` : ""}
        name: { iregex: "${searchText}" }
        is_delete: {eq : false}
        client:{eq:"${client}"}
    }){
      count:count_id
    }

  delivery_milestone_masters(
     where: {
    ${companyID?.length > 0 ? `company_id: { in: [${companyID}] }` : ""}
      ${status?.length > 0 ? `is_active:{in:[${status}]}` : ""}
       name: { iregex: "${searchText}" }
               is_delete: {eq : false}
                       client:{eq:"${client}"}


    }
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc }
  ) {
    id
   name
   description
    is_active
    is_delete
  }
}

`;
};
export const GET_DELIVERY_MILESTONE_TEMPLATE_TABLE_LIST = (
  client = "",
  offset = 0,
  limit = 10,
  searchText = "",
  companyID = [],
  status = []
) => {
  return gql`
query GET_DELIVERY_MILESTONE_TEMPLATE_TABLE_LIST($id:ID){
  count:milestone_template_master(
     where: {
        ${companyID?.length > 0 ? `company_id: { in: [${companyID}] }` : ""}
      ${status?.length > 0 ? `is_active:{in:[${status}]}` : ""}
        name: { iregex: "${searchText}" }
                       is_delete: {eq : false}
        client:{eq:"${client}"}
    }){
      count:count_id
    }

  milestone_template_master(
     where: {
    ${companyID?.length > 0 ? `company_id: { in: [${companyID}] }` : ""}
      ${status?.length > 0 ? `is_active:{in:[${status}]}` : ""}
       name: { iregex: "${searchText}" }
                      is_delete: {eq : false}
                       client:{eq:"${client}"}


    }
    offset: ${offset}
    limit: ${limit}
    order_by: { created_at: desc }
  ) {
    milestone_template_id:id
   name
   description
   template_type
    is_active
    is_delete
created_byByID{
        id
      last_name
      first_name
    }
    updated_byByID{
        id
      last_name
      first_name
    }
    created_at
    updated_at
    milestone_template_items( where: {
      is_active: {eq : true}
    }){
     milestone_template_item_id: id
      id:delivery_milestone_id
      name
      description
      is_active
      value
      value_type
      order_by
    }

  }
}

`;
};
export const TEMPLATE_LIST = (
  client = "",
  offset = 0,
  limit = 10,
  searchText = "",
  companyID = []
) => {
  return gql`
query {
  count: delivery_milestone_masters(where: {
    name: { iregex: "${searchText}" }
          is_active:{eq : true}
          is_delete: {eq : false}
           client:{eq:"${client}"}
                   ${
                     companyID?.length > 0
                       ? `company_id: { in: [${companyID}] }`
                       : ""
                   }

}) {
    count: count_id
  }
  delivery_milestone_masters(
    where: { name: { iregex: "${searchText}" }
        is_active:{eq : true}
        is_delete: {eq : false}
        client:{eq:"${client}"}
                ${
                  companyID?.length > 0
                    ? `company_id: { in: [${companyID}] }`
                    : ""
                }

  }
    offset: ${offset}
    limit: ${limit}
  ) {
     id
     name
     description
     is_active
     is_delete
  }
}

`;
};

export const GET_ROOMS_BEDSPACE = `
query GET_ROOMS_BEDSPACE(
  $clientID: String
  $unitId: String
  $searchText: String
  $offset: Int
  $limit: Int
) {
  count: unit(
    where: {
      is_active: { eq: true }
      client: { eq: $clientID }
      parent_unit_id: { eq: $unitId }
      name: { iregex: $searchText }
    }
  ) {
    count: count_id
  }
  unit(
    where: {
      is_active: { eq: true }
      client: { eq: $clientID }
      parent_unit_id: { eq: $unitId }
      name: { iregex: $searchText }
    }
    order_by: { name: asc }
    offset: $offset
    limit: $limit
  ) {
    id
    name
    description
    unit_no
    status
  }
}`;

export const GET_PROPERTIES_BY_COMPANY = (
  offset,
  limit,
  searchText,
  companyID
) => gql`
query GET_PROPERTY_BASED_ON_COMPANY{
  count: property(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
      company_id:{eq:${companyID}}

    }
  ) {
    count:count_id
  }

  property(
      where: { is_active: { eq: true }
       name:{iregex:"${searchText}"}
       company_id:{eq:${companyID}}
    }
       offset:${offset}
       limit:${limit}
      ) {
        value: id
        label: name

  }
}
`;

export const GET_PROXY_LEAD_UNIT = gql`
  query GET_PROXY_LEAD_UNIT($offset: Int, $limit: Int, $id: String) {
    count: proxy_lead_units(
      where: { proxy_lead_id: { eq: $id }, is_active: true }
    ) {
      count: count_id
    }
    proxy_lead_units(
      where: { proxy_lead_id: { eq: $id }, is_active: true }
      offset: $offset
      limit: $limit
      order_by: { created_at: desc }
    ) {
      id
      unit {
        id
        logo
        name
        unit_no
        unit_type
        unit_type_masterByID {
          name
        }
        property {
          name
        }
      }
    }
  }
`;

export const GET_PROXY_CHECKLIST = gql`
  query GET_PROXY_CHECKLIST($offset: Int, $limit: Int, $id: String) {
    count: proxy_lead_checklist(where: { proxy_lead_id: { eq: $id } }) {
      count: count_id
    }
    proxy_lead_checklist(
      where: { proxy_lead_id: { eq: $id } }
      offset: $offset
      limit: $limit
      order_by: { created_at: desc }
    ) {
      id
      name
      description
      is_done
    }
  }
`;

export const GET_ACTIVITY_CATAGORY_MASTER = (
  offset,
  limit,
  searchText,
  object,
  id
) => gql`
query GET_ACTIVITY_CATAGORY_MASTER{
  count: activity_category_master(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
      is_delete:{eq:false}
      ${id?.length > 0 ? `lead_activity_master_id:{eq:"${id}"}` : ``}
      ${object?.client?.length > 0 ? `client:{eq:"${object?.client}"}` : ``}
      company_id: {eq: ${object?.company}}
    }
  ) {
    count:count_id
  }

  activity_category_master(
      where: { is_active: { eq: true }
      is_delete:{eq:false}
       name:{iregex:"${searchText}"}
       ${id?.length > 0 ? `lead_activity_master_id:{eq:"${id}"}` : ``}
      ${object?.client?.length > 0 ? `client:{eq:"${object?.client}"}` : ``}
      company_id: {eq: ${object?.company}}
    }
       offset:${offset}
       limit:${limit}
      ) {
        value: id
        label: name

  }
}
`;

export const ACTIVITY_TYPES = gql`
  query {
    proxy_lead_activity_master: lead_activity_master {
      id
      name
      image_url
    }
  }
`;

export const GET_PROXY_LEAD_ACTIVITIES = gql`
  query GET_PROXY_LEAD_ACTIVITIES($offset: Int, $limit: Int, $id: String) {
    count: proxy_lead_activities(where: { proxy_lead_id: { eq: $id } }) {
      count: count_id
    }
    proxy_lead_activities(
      where: { proxy_lead_id: { eq: $id } }
      offset: $offset
      limit: $limit
      order_by: { created_at: desc }
    ) {
      priority
      id
      date_time
      phone_number
      phone_code
      email_id
      location
      type
      purpose
      status
      activity_category {
        label: name
        value: id
      }
      activity_sub_category {
        label: name
        value: id
      }
      notes
      closed_on
      remarks
      user_profiles: created_byByID {
        first_name
        last_name
      }
      proxy_lead_activity_master: lead_activity_master {
        name
        image_url
        id
        inactive_logo
      }
    }
  }
`;
export const GET_ACTIVITY_SUB_CATAGORY_MASTER = (
  offset,
  limit,
  searchText,
  object
  // id
) => gql`
query GET_ACTIVITY_SUB_CATAGORY_MASTER{
  count: activity_sub_category_master(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}

    }
  ) {
    count:count_id
  }

  activity_sub_category_master(
      where: {
      is_active: { eq: true }
      name:{iregex:"${searchText}"}
    }
       offset:${offset}
       limit:${limit}
      ) {
        value: id
        label: name

  }
}
`;

export const GET_ROLES_NEW = (offset, limit, searchText, clientID) => gql`
query GET_ROLES_NEW{
  count: client_roles(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
      client:"${clientID}"

    }
  ) {
    count:count_id
  }

  client_roles(
      where: {
        is_active: { eq: true }
       name:{iregex:"${searchText}"}
       client:"${clientID}"
    }
       offset:${offset}
       limit:${limit}
      ) {
        value: id
        label: name

  }
}
`;

export const GET_USERS_BY_ROLE_ID_NEW = gql`
  query GET_USERS_BY_ROLE_ID($clientID: String) {
    client_user_role_access(
      where: {
        client_role_id: { eq: $id }
        user_profileByID: { first_name: { iregex: $search } }
      }
      is_active: { eq: true }

      offset: $offset
      limit: $limit
    ) {
      roles_id: client_role_id
      company_ids
      user_profileByID {
        id
        first_name
        last_name
        email_id
        mobile_no
        mobile_no_country_code
        image_url
      }
    }
  }
`;

export const GET_ACTIVITY_CATEGORY = (funcVar = {}) => gql`
query GET_ACTIVITY_CATEGORY(
  $status: [Boolean]
  $companyID: Int
  ${funcVar?.leadActivityID ? `$leadActivityID: String` : ``}
  $search: String
  $offset: Int
  $limit: Int
) {
  count: activity_category_master(
    where: {
      is_active: { in: $status }
      is_delete: { eq: false }
      client: { eq: $clientID }
      company_id: { eq: $companyID }
      ${
        funcVar?.leadActivityID
          ? `lead_activity_master_id: { eq: $leadActivityID }`
          : ``
      }
      name: { iregex: $search }
    }
  ) {
    count: count_id
  }
  activity_category_master(
    where: {
      is_active: { in: $status }
      is_delete: { eq: false }
      client: { eq: $clientID }
      company_id: { eq: $companyID }
      ${
        funcVar?.leadActivityID
          ? `lead_activity_master_id: { eq: $leadActivityID }`
          : ``
      }
      name: { iregex: $search }
    }
    order_by: { created_at: desc }
    offset: $offset
    limit: $limit
  ) {
    value: id
    label: name
    id
    lead_activity_master_id
    lead_activity_masterByID {
      value: id
      label: name
      id
      name
    }
    name
    is_active
    is_delete
    company_id
    client
  }
}`;

export const GET_LEAD_ACTIVITY = gql`
  query GET_LEAD_ACTIVITY($search: String, $offset: Int, $limit: Int) {
    count: lead_activity_master(
      where: {
        is_active: { eq: true }
        is_delete: { eq: false }
        name: { iregex: $search }
      }
    ) {
      count: count_id
    }
    lead_activity_master(
      where: {
        is_active: { eq: true }
        is_delete: { eq: false }
        name: { iregex: $search }
      }
      order_by: { name: asc }
      offset: $offset
      limit: $limit
    ) {
      label: name
      value: id
      id
      name
    }
  }
`;

export const GET_ACTIVITY_SUB_CATEGORY = gql`
  query GET_ACTIVITY_SUB_CATEGORY(
    $status: [Boolean]
    $companyID: Int
    $search: String
    $offset: Int
    $limit: Int
  ) {
    count: activity_sub_category_master(
      where: {
        is_active: { in: $status }
        is_delete: { eq: false }
        client: { eq: $clientID }
        company_id: { eq: $companyID }
        name: { iregex: $search }
      }
    ) {
      count: count_id
    }
    activity_sub_category_master(
      where: {
        is_active: { in: $status }
        is_delete: { eq: false }
        client: { eq: $clientID }
        company_id: { eq: $companyID }
        name: { iregex: $search }
      }
      order_by: { created_at: desc }
      offset: $offset
      limit: $limit
    ) {
      id
      activity_category_master_id
      activity_category_masterByID {
        value: id
        label: name
        id
        name
        lead_activity_master_id
        lead_activity_masterByID {
          value: id
          label: name
          id
          name
        }
      }
      name
      is_active
      is_delete
      company_id
      client
    }
  }
`;

export const GET_PROSPECTIVE = gql`
  query GET_PROSPECTIVE(
    $clientID: String
    $companyID: Int
    $status: [Boolean]
    $search: String
    $offset: Int
    $limit: Int
  ) {
    count: prospective(
      where: {
        client: { eq: $clientID }
        company_id: { eq: $companyID }
        is_active: { in: $status }
        or: {
          first_name: { iregex: $search }
          phone: { iregex: $search }
          email_id: { iregex: $search }
        }
      }
    ) {
      count: count_id
    }
    prospective(
      where: {
        client: { eq: $clientID }
        company_id: { eq: $companyID }
        is_active: { in: $status }
        or: {
          first_name: { iregex: $search }
          phone: { iregex: $search }
          email_id: { iregex: $search }
        }
      }
      order_by: { created_at: desc }
      offset: $offset
      limit: $limit
    ) {
      id
      image_url
      first_name
      created_at
    }
  }
`;

export const GET_PROSPECTIVE_BY_ID = gql`
  query GET_PROSPECTIVE_BY_ID($prospectiveID: ID) {
    prospective(where: { id: { eq: $prospectiveID } }) {
      id
      image_url
      first_name
      noOfOpportunities: leadByID(where: { is_active: { eq: true } }) {
        count: count_id
      }
      created_at
      door_no
      street_1
      street_2
      landmark
      area
      city
      state
      country
      zipcode
      latitude
      longitude
      mobile_no_country_code
      mobile_no
      alternative_mobile_country_code
      alternative_mobile
      email_id
      facebook
      twitter
      linkedin
      leadByID(where: { is_active: { eq: true } }) {
        id
        lead_no
        created_at
        subject
        unit_usage
        lead_source
        urgent
        lead_owner {
          first_name
        }
        status
        statusByID: lead_status_master {
          id
          status
        }
      }
    }
  }
`;

export let GET_MAINTENANCE_CATEGORY_NEW = (
  offset,
  limit,
  searchText,
  obj,
  company_id = []
) => gql`
query GET_MASTERS_FOR_CREATING_BLOCK{
  count: maintenance_category_master(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
    }
  ) {
    count:count_id
  }
  maintenance_category_master(
      where:{
        name:{iregex:"${searchText}"}
        is_active:{eq:true}
      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
      	value:id
      }
}
`;
export let GET_MAINTENANCE_SUB_CATEGORY_NEW = (
  offset,
  limit,
  searchText,
  obj,
  id = ""
) => gql`
query GET_MASTERS_FOR_CREATING_BLOCK{
  count: maintenance_sub_category_master(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
      maintenance_category_id:{eq:"${id}"}
    }
  ) {
    count:count_id
  }
  maintenance_sub_category_master(
      where:{
        name:{iregex:"${searchText}"}
        maintenance_category_id:{eq:"${id}"}
        is_active:{eq:true}
      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
      	value:id
      }
}
`;

export let GET_PROPERTY_BASED_ON_COMPANY_NEW = (
  offset,
  limit,
  searchText,
  obj,
  company_id = []
) => gql`
query GET_PROPERTY_BASED_ON_COMPANY{
  count: property(
    where:{
      company_id:{in:[${company_id}]}
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
    }
  ) {
    count:count_id
  }
  property(
      where:{
        company_id:{in:[${company_id}]}
        name:{iregex:"${searchText}"}
        is_active:{eq:true}
      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
      	value:id
      }
}
`;

export let SELECTUNITS_NEW = (offset, limit, searchText, obj, id = "") => gql`
query GET_MASTERS_FOR_CREATING_BLOCK{
  count: unit(
    where:{
      property_id:{eq:"${id}"}
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
    }
  ) {
    count:count_id
  }
  unit(
      where:{
        property_id:{eq:"${id}"}
        name:{iregex:"${searchText}"}
        is_active:{eq:true}
      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
      	value:id
        unit_no
      }
}
`;

export let SELECTUNITS_NEW_FILTER = (
  offset,
  limit,
  searchText,
  obj,
  id = ""
) => gql`
query GET_MASTERS_FOR_CREATING_BLOCK{
  count: unit(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
      company_id:"${obj?.company_id}"
    }
  ) {
    count:count_id
  }
  unit(
      where:{

        name:{iregex:"${searchText}"}
        is_active:{eq:true}
        company_id:"${obj?.company_id}"
      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
      	value:id
      }
}
`;

export let GET_GENERAL_CATEGORY_MASTERS_NEW = (
  offset,
  limit,
  searchText,
  obj,
  company_id = []
) => gql`
query GET_MASTERS_FOR_CREATING_BLOCK{
  count: general_category_master(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
    }
  ) {
    count:count_id
  }
  general_category_master(
      where:{
        name:{iregex:"${searchText}"}
        is_active:{eq:true}
      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
      	value:id
      }
}
`;
export let GET_GENERAL_SUB_CATEGORY_NEW = (
  offset,
  limit,
  searchText,
  obj,
  id = ""
) => gql`
query GET_MASTERS_FOR_CREATING_BLOCK{
  count: general_sub_category_master(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
     general_category_id:{eq:"${id}"}
    }
  ) {
    count:count_id
  }
 general_sub_category_master(
      where:{
        name:{iregex:"${searchText}"}
        general_category_id:{eq:"${id}"}
        is_active:{eq:true}
      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
      	value:id
      }
}
`;

export const updateDeviceToken = `
mutation($insert:[user_device_tokenOutput!]) {
 user_device_token(
    insert: $insert

  ) {
   id
  }
}`;

export const KYC_LIST = (
  companyID,
  proof_type,
  clientID,
  offSet,
  limit,
  status,
  valid_from,
  valid_to
) => {
  return gql`
  query {
    count: know_your_client(
      where: {
        proof_type: { eq:"${proof_type}" },
        company_id: { eq: ${companyID} } ,
        client:{eq:"${clientID}"},
        ${valid_from?.length > 0 ? `valid_from: ${valid_from}` : ``}
        ${valid_to?.length > 0 ? `valid_to: ${valid_to}` : ``}
        ${status?.length > 0 ? `is_mandatory: ${status}` : ``}
      }
    ) {
      count_id
    }
    know_your_client(
      where: {
        proof_type: { eq:"${proof_type}" },
        company_id: { eq: ${companyID}  },
        client:{eq:"${clientID}"}
        ${valid_from?.length > 0 ? `valid_from: ${valid_from}` : ``}
        ${valid_to?.length > 0 ? `valid_to: ${valid_to}` : ``}
        ${status?.length > 0 ? `is_mandatory: ${status}` : ``}
      }
      offset: ${offSet}
      limit: ${limit}
      order_by: { created_at: desc }
    ) {
      id
      name_as_per_id: name
      valid_to
      valid_from
      is_mandatory
      contact_id
      id_number: number
      contactByID {
        first_name
        last_name
      }
      proof_type
      number
      identification_masterByID {
        type
      }
      countryByID {
        country_name
      }
      accountByID {
        name
        account_no
      }
    }
  }`;
};

export const GET_DETAILS_KYC_DETAILS = gql`
  query ($id: ID) {
    know_your_client(where: { id: { eq: $id } }) {
      id
      url
      identification_masterByID {
        type
      }
      name
      number
      valid_to
      valid_from
      countryByID {
        country_name
      }
    }
  }
`;
export const GET_PROPERTIES_FOR_UNIT_VACANCY = (propertyGroupID = []) => gql`
query GET_PROPERTIES_FOR_UNIT_VACANCY(
  $clientID: String
  $companyID: Int
  ${propertyGroupID?.length ? `$propertyGroupID: [String]` : ""}
  $status: [Boolean]
  $search: String
  $offset: Int
  $limit: Int
) {
  count: property(
    where: {
      is_active: { in: $status }
      client: { eq: $clientID }
      company_id: { eq: $companyID }
      ${
        propertyGroupID?.length
          ? `property_group_id: { in: $propertyGroupID }`
          : ""
      }
      name: { iregex: $search }
    }
  ) {
    count: count_id
  }
  property(
    where: {
      is_active: { in: $status }
      client: { eq: $clientID }
      company_id: { eq: $companyID }
      ${
        propertyGroupID?.length
          ? `property_group_id: { in: $propertyGroupID }`
          : ""
      }
      name: { iregex: $search }
    }
    order_by: { name: asc }
    offset: $offset
    limit: $limit
  ) {
    id
    is_active
    name
    address_id
    addressByID {
      id
      area
      city
      district
    }
    unitCount: unitByID {
      count: count_id
    }
    property_group_id
    property_groupByID {
      id
      group_name
    }
  }
}`;

export const GET_UNIT_VACANCY = (unitCategory = []) => gql`
query GET_UNIT_VACANCY(
  $clientID: String
  $companyID: Int
  $activeStatus: [Boolean]
  ${unitCategory?.length ? `$unitCategory: [String]` : ""}
  $from: String
  $to: String
  $search: String
  $offset: Int
  $limit: Int
) {
  count: unit(
    where: {
      is_active: { in: $activeStatus }
      client: { eq: $clientID }
      company_id: { eq: $companyID }
      ${unitCategory?.length ? `unit_category: { in: $unitCategory }` : ""}
      or: {
        property: { name: { iregex: $search } }
        unit_no: { iregex: $search }
        name: { iregex: $search }
      }
    }
  ) {
    count: count_id
  }
  unit(
    where: {
      is_active: { in: $activeStatus }
      client: { eq: $clientID }
      company_id: { eq: $companyID }
      ${unitCategory?.length ? `unit_category: { in: $unitCategory }` : ""}
      or: {
        property: { name: { iregex: $search } }
        unit_no: { iregex: $search }
        name: { iregex: $search }
      }
    }
    order_by: { created_at: desc }
    offset: $offset
    limit: $limit
  ) {
    id
    property_id
    propertyByID {
      id
      name
    }
    unit_no
    name
    unit_category
    unit_categoryByID{
      id
      name
    }
    unit_type_masterByID {
      id
      name
    }
    unit_vacancy_periodByID(
      where: {
        is_active: { eq: true }
        available_from: { gte: $from }
        available_to: { lt: $to }
      }
    ) {
      id
      status
      available_from
      available_to
    }
    status
    is_active
  }
}`;

export const GET_UNIT_VACANCY_PERIOD_LIST = gql`
  query GET_UNIT_VACANCY_PERIOD($unitID: String, $offset: Int, $limit: Int) {
    count: unit_vacancy(
      where: { is_active: { eq: true }, unit_id: { eq: $unitID } }
    ) {
      count: count_id
    }
    unit_vacancy(
      where: { is_active: { eq: true }, unit_id: { eq: $unitID } }
      order_by: { available_from: asc }
      offset: $offset
      limit: $limit
    ) {
      id
      is_active
      client
      unit_id
      available_from
      available_to
      status
      unit_no
      name
      agreement_no
      first_name
      last_name
      status
      mobile_no
      mobile_no_country_code
      current_status
      logo
    }
  }
`;

export const UNIT_CATEGORY_MASTER = () => gql`
  query unit_category_master {
    count: unit_category_master {
      count: count_id
    }
    unit_category_master {
      value: id
      label: name
    }
  }
`;

export const GET_UNIT_VACANCY_BY_PROPERTY_ID = (unitCategory = []) => gql`
query GET_UNIT_VACANCY_BY_PROPERTY_ID(
  $clientID: String
  $propertyID: String
  $activeStatus: [Boolean]
  ${unitCategory !== "" ? `$unitCategory: Int` : ""}
  $from: String
  $to: String
  $search: String
  $offset: Int
  $limit: Int
) {
  property(
    where: { id: { eq: $propertyID } }
  ) {
    id
    logo
    property_no
    name
    company_id
    companyByID {
      id
      name
    }
    property_group_id
    property_group_masterByID {
      id
      group_name
    }
    property_purpose
    address {
      id
      door_no
      street_1
      street_2
      street_3
      landmark
      area
      city
      district
      state
      country
      zipcode
      latitude
      longitude
      __typename
    }
    totalUnits: unit {
      count: count_id
    }
    is_active
    description
  }
  count: unit(
    where: {
      is_active: { in: $activeStatus }
      client: { eq: $clientID }
      property_id: { eq: $propertyID }
      ${unitCategory !== "" ? `unit_category:{id:{ eq: $unitCategory }} ` : ""}
      name: { iregex: $search }
    }
  ) {
    count: count_id
  }
  unit(
    where: {
      is_active: { in: $activeStatus }
      client: { eq: $clientID }
      property_id: { eq: $propertyID }
      ${unitCategory !== "" ? `unit_category:{id:{ eq: $unitCategory }} ` : ""}
      name: { iregex: $search }
    }
    order_by: { created_at: desc }
    offset: $offset
    limit: $limit
  ) {
    id
    property_id
    name
    unit_category
    unit_categoryByID{
      id
      name
    }
    floor_id
    floorByID {
      id
      name
    }
    block_id
    blockByID {
      id
      name
    }
    status
    is_active
    unit_vacancy_periodByID(
      where: {
        is_active: { eq: true }
        available_from: { gte: $from }
        available_to: { lt: $to }
      }
    ) {
      id
      status
      available_from
      available_to
    }
  }
}`;
export const GET_CONTACT_ACCOUNT_USER = gql`
  query {
    contact_account(
      where: {
        company_id: { eq: $company_id }
        client: { eq: $clientID }
        is_active: { eq: true }
        name: { iregex: $search }
        relationship: { eq: $relationship }
      }
      offset: $offset
      limit: $limit
      orderby: { created_at: desc }
    ) {
      id
      name
      account_no
      company_id
      logo
      email_id
      mobile_no
      mobile_no_country_code
      contact {
        id
        first_name
        last_name
        image_url
        mobile_no
        mobile_no_country_code
        email_id
      }
    }
  }
`;

export let UOM_MASTER = (search = "", offset = 0, limit = 10) => gql`
query UOM_MASTER {
  count: uom_master(
    where: {
      is_active: { eq: true }
      is_delete: { eq: false }
      name: { iregex: "${search}" }
    }
  ) {
    count: count_id
  }
  uom_master(
    where: {
      is_active: { eq: true }
      is_delete: { eq: false }
      name: { iregex: "${search}" }
    }
    offset: ${offset}
    limit: ${limit}
  ) {
    value: id
    label: name
  }
}`;

export let PROPERTY_HIERARCHY_MASTER = (
  search = "",
  offset = 0,
  limit = 10
) => gql`
query PROPERTY_HIERARCHY_MASTER {
  count: property_hierarchy_master(
    where: {
      is_active: { eq: true }
      is_delete: { eq: false }
      name: { iregex: "${search}" }
    }
  ) {
    count: count_id
  }
  property_hierarchy_master(
    where: {
      is_active: { eq: true }
      is_delete: { eq: false }
      name: { iregex: "${search}" }
    }
    offset: ${offset}
    limit: ${limit}
  ) {
    value: id
    label: name
    is_block
    is_floor
    is_unit
    is_multi_unit
  }
}`;

export const PROPERTY_HIERARCHY_MASTER_OPTION = (
  offset,
  limit,
  searchText
) => gql`
query PROPERTY_HIERARCHY_MASTER {
  count: property_hierarchy_master(
    where: {
      is_active: { eq: true }
      is_delete: { eq: false }
      name: { iregex: "${searchText}" }
    }
  ) {
    count: count_id
  }
  property_hierarchy_master(
    where: {
      is_active: { eq: true }
      is_delete: { eq: false }
      name: { iregex: "${searchText}" }
    }
    offset: ${offset}
    limit: ${limit}
  ) {
    value: id
    label: name
    is_block
    is_floor
    is_unit
    is_multi_unit
  }
}`;

export const COMPANY_TYPE_MASTER = (
  offset = 0,
  limit = 10,
  search = "",
  extraParams = {}
) => {
  return gql(`
query COMPANY_TYPE_MASTER {
  count: company_type_master(
    where: {
        ${extraParams?.isActive ? `is_active:{eq:true}` : ""}
      is_delete: { eq: false }
      name: { iregex: "${search}" }
    }
  ) {
    count: count_id
  }
  company_type_master(
    where: {
        ${extraParams?.isActive ? `is_active:{eq:true}` : ""}
      is_delete: { eq: false }
      name: { iregex: "${search}" }
    }
    offset: ${offset}
    limit: ${limit}
  ) {
    value: id
    label: name
  }
}`);
};

export const DUPLICATE_PROPERTY_NAME_VALIDATOR = gql`
  query DUPLICATE_PROPERTY_NAME_VALIDATOR(
    $clientID: String
    $companyID: Int
    $propertyName: String
  ) {
    property(
      where: {
        is_active: { eq: true }
        client: { eq: $clientID }
        company_id: { eq: $companyID }
        name: { ilike: $propertyName }
      }
    ) {
      duplicate: count_id
    }
  }
`;

export const GET_PRICING_DETAILS = gql`
  query GET_COMPONENT_BASED_PRICING($rera_id: ID) {
    pricing_table(where: { id: { eq: $rera_id } }) {
      id
      revenue_type
      quantity
      rental_breakup_id
      rental_breakupByID {
        id
        name
        value: id
        label: name
      }
      payment_period
      value
      value_basis_type
      currencyByID {
        id
        code
      }
      taxable
      is_chargeable
      annual_rental
      is_security
      uom
      vat_groupByID {
        id
        group_name
        value: id
        label: group_name
      }
      primary
      refundable
      is_onetime
      is_reserve_quality
      is_parking
      is_quantity
      is_price_overide
      payment_period
      company_id
      companyByID {
        id
        country_id
      }
      property_id
      unit_id
      unit {
        total_area
      }
      rera_pricing(where: { is_active: true }) {
        id
        min
        max
        recommended_value
      }
    }
  }
`;

export const GET_PRICING_TABLE = gql`
  query GET_PRICING_TABLE(
    $clientID: String
    $companyID: Int
    $search: String
    $offset: Int
    $limit: Int
  ) {
    count: pricing_table(
      where: {
        is_active: { eq: true }
        client: { eq: $clientID }
        company_id: { eq: $companyID }
        rental_breakup: { name: { iregex: $search } }
      }
    ) {
      count: count_id
    }
    pricing_table(
      where: {
        is_active: { eq: true }
        client: { eq: $clientID }
        company_id: { eq: $companyID }
        rental_breakup: { name: { iregex: $search } }
      }
      order_by: { created_at: asc }
      offset: $offset
      limit: $limit
    ) {
      id
      unit_id
      unitByID {
        value: id
        label: name
        name
        unit_no
        property_id
        propertyByID {
          value: id
          label: name
          name
          property_no
        }
      }
      revenue_type
      rental_breakup_id
      rental_breakupByID {
        value: id
        label: name
        name
      }
      primary
      is_onetime
      refundable
      is_quantity
      is_parking
      value
      value_basis_type
      currency_id
      currencyByID {
        value: id
        label: name
        name
        code
      }
      uom
      payment_period
      taxable
      quantity
      is_chargeable
    }
  }
`;

export let RESERVEATION_LIST = (
  companyID = "",
  offset = 0,
  limit = 10,
  search = "",
  clientID = "",
  reservation_status = "",
  start = "",
  end = "",
  status = ""
) => gql`
query RESERVEATION_LIST {
    count: reservation(
      where: {
        company_id:{eq:${companyID}}
        is_active: { eq: true }
        client:{eq:"${clientID}"}
        or:{
          reservation_no: { iregex: "${search}" }
          quotation:{quotation_no:{iregex: "${search}"}}
         }
         ${
           reservation_status?.length > 0
             ? `reservation_status: {eq:${reservation_status}}`
             : ""
         }
         ${
           start?.length > 0
             ? ` reservation_expiry_date: { and: { gte: "${start}", lte: "${end}" } }`
             : ""
         }
         ${status?.length > 0 ? `status: "${status}"` : ""}
      }
    ) {
      count: count_id
    }

  reservation(
    where: {
      company_id:{eq:${companyID}}
      is_active: { eq: true }
      client:{eq:"${clientID}"}
     or:{
      reservation_no: { iregex: "${search}" }
      quotation:{quotation_no:{iregex: "${search}"}}
     }
     ${
       reservation_status?.length > 0
         ? ` reservation_status: {eq:${reservation_status}}`
         : ""
     }
    ${
      start?.length > 0
        ? ` reservation_expiry_date: { and: { gte: "${start}", lte: "${end}" } }`
        : ""
    }
    ${status?.length > 0 ? `status: "${status}"` : ""}
    }
    offset: ${offset}
    limit: ${limit}
  ) {
    id
    reservation_no
    reservation_status
    reservation_expiry_date
    status
    quotation {
      id
      quotation_no
      unit_usage
      total_amount
      quotation_units {
        count_id
      }
    }
  }
}`;

export let MANAGE_INVOICE_LIST = (
  companyID = "",
  offset = 0,
  limit = 10,
  search = "",
  clientID = "",
  start = "",
  end = "",
  typeSelet = ""
) => gql`
query MANAGE_INVOICE_LIST {
    count: quotation_payment_schedule(
      where: {
        agreement_id: { is_null: false },
        agreement:{ company_id:{eq:${companyID}}}
        is_active: { eq: true }
        client:{eq:"${clientID}"}
        status:"Unposted",
        ${typeSelet?.length > 0 ? `invoice_type:{eq:"${typeSelet}"}` : ""},
        ${
          search?.length > 0 ? `agreement:{ agreement_no:{eq:"${search}"}}` : ""
        }
        ${
          start?.length > 0
            ? ` payment_due_date: { and: { gte: "${start}", lte: "${end}" } }`
            : ""
        }
      }
    ) {
      count: count_id
    }
    quotation_payment_schedule(
      where: {
      agreement_id: { is_null: false },
      agreement:{ company_id:{eq:${companyID}}}
      is_active: { eq: true }
      client:{eq:"${clientID}"}
      status:"Unposted",
      ${typeSelet?.length > 0 ? `invoice_type:{eq:"${typeSelet}"}` : ""},
      ${search?.length > 0 ? `agreement:{ agreement_no:{eq:"${search}"}}` : ""}
      ${
        start?.length > 0
          ? ` payment_due_date: { and: { gte: "${start}", lte: "${end}" } }`
          : ""
      }
    }
    offset: ${offset}
    limit: ${limit}
    ) {
      id
      schedule_no
      description
      payment_due_date
      total_amount
      invoice_type
      agreement {
        agreement_no
        payment_mode
        account {
          account_no
          name
        }
      }
    }
}`;

export const GET_PROPERTY_BY_USER = (
  offset = 0,
  limit = 10,
  search = "",
  clientID,
  companyID
) => {
  return `
query property {
  property(
    where: {
      is_active: { eq: true }
      name: { iregex: "${search}" }
      client:{eq:"${clientID}"}
      id:{in:$property_ids}
      company_id:{eq:${companyID}}
    }
    offset: ${offset}
    limit: ${limit}
  ) {
    id
    name
    logo
    address {
      state
      city
      country
    }
  }
}`;
};

export const GET_NOTIFICATION_DETAILS = (id) => gql`
query($id:ID){
  notifications(where: { id:"${id}" }) {
    id
    description
    category
    property_ids
    is_all_property
    to_builds
  }
}
`;

export const GET_NOTIFICATION_VIEW = (id) => gql`
query($id:ID){
  notifications(where: { id:"${id}" }) {
    id
    description
    category
    propertyByID {
      id
      name
      logo
    }
    created_at
    created_by {
      first_name
      last_name
    }
  }
}
`;
//need to check
export const GET_UNIT_CATEGORY_MASTER = gql`
  query GET_UNIT_CATEGORY_MASTER {
    unit_category_master(
      where: { is_active: { eq: true }, is_delete: { eq: false } }
      order_by: { priority: asc }
    ) {
      value: id
      label: name
      id
      name
    }
  }
`;

export const GET_PARENT_UNIT_BY_FLOOR_ID = (
  floorID,
  searchText,
  unitCategoryIDs,
  unitPurpose,
  offset = 0,
  limit = 10
) => gql`
query GET_PARENT_UNIT_FROM_FLOOR_ID {
  count: floor(
    where: {
      id: { eq: "${floorID}" }
      is_active: { eq: true }
    }
  ) {
    count: unit(
      where: {
        or: {
          name: { iregex: "${searchText}" }
          unit_no: { iregex: "${searchText}" }
        }
        unit_category_id: { in: [${unitCategoryIDs}] }
        unit_purpose: { eq: "${unitPurpose}" }
      }
    ) {
      count: count_id
    }
  }
  floor(
    where: {
      id: { eq: "${floorID}" }
      is_active: { eq: true }
    }
  ) {
    unit(
      where: {
        or: {
          name: { iregex: "${searchText}" }
          unit_no: { iregex: "${searchText}" }
        }
        unit_category_id: { in: [${unitCategoryIDs}] }
        unit_purpose: { eq: "${unitPurpose}" }
      }
      order_by: { name: asc }
      offset: ${offset}
      limit: ${limit}
    ) {
      value: id
      label: name
      id
      name
      unit_no
      revenue_type
      area_metric
      unit_type(where: { is_active: { eq: true } }) {
        value: id
        label: name
      }
      address(where: { is_active: { eq: true } }) {
        latitude
        longitude
      }
      unit_purpose
    }
  }
}`;

export let GET_UOM = (offset, limit, searchText) => gql`
query GET_UOM{
  count: uom_master(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
    }
  ) {
    count:count_id
  }
  uom_master(
      where:{
        name:{iregex:"${searchText}"}
        is_active:{eq:true}
      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
      	value:id
      }
}
`;

export const GET_REPORTS = (offset = 0, limit = 10, search = "") => {
  return `
  query report_category_master {
    report_category_master(
      where: {
        is_active: { eq: true }
        name: { iregex: "${search}" }
      }
      offset: ${offset}
      limit: ${limit}
    ) {
      id
      name
    }
  }`;
};

export let GET_REPORTS_MASTER = (
  offset,
  limit,
  searchText,
  obj,
  company_id = []
) => gql`
query GET_REPORTS_MASTER{
  count: report_master(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
      category_id:"${obj?.category_id}"
    }
  ) {
    count:count_id
  }
  report_master(
      where:{
        name:{iregex:"${searchText}"}
        is_active:{eq:true}
        category_id:"${obj?.category_id}"
      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
      	value:id
      }
}
`;

export let GET_BLOCK_ID = (
  offset,
  limit,
  searchText,
  obj,
  company_id = []
) => gql`
query GET_BLOCK_ID($ids:[ID]){
  count: block(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
      property_id:{in:$ids}

    }
  ) {
    count:count_id
  }
  block(
      where:{
        name:{iregex:"${searchText}"}
        is_active:{eq:true}
        property_id:{in:$ids}
      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
      	value:id
      }
}
`;

export let GET_FLOOR_ID = (
  offset,
  limit,
  searchText,
  obj,
  company_id = []
) => gql`
query GET_FLOOR_ID($ids:[ID]){
  count: floor(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
      block_id:{in:$ids}

    }
  ) {
    count:count_id
  }
  floor(
      where:{
        name:{iregex:"${searchText}"}
        is_active:{eq:true}
        block_id:{in:$ids}
      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
      	value:id
      }
}
`;

export const GET_DIRECTORY_ASSETS = gql`
  query GET_DIRECTORY_ASSETS($directory_asset_ID: ID) {
    directory_assets(where: { id: { eq: $directory_asset_ID } }) {
      file_meta
    }
  }
`;
export const GET_TEAM_BY_COMPANY = (company_id, module_id) => gql`
query GET_TEAM_BY_COMPANY{
  count: teams(where: {
     is_active:{eq:true}
     company_id:"${company_id}"
     module:"${module_id}"
    } ) {
    count:count_id
  }
  teams(
    where: {
    is_active:{eq:true}
     company_id:"${company_id}"
     module:"${module_id}"
        }  ){
    value:id
    label:name
  }      or: { block_no: { iregex: $searchText }, name: { iregex: $searchText } }

}`;

export const GET_DOMESTIC_HELPER_LIST_WITH_FUNCTION = (profession) => gql`
query GET_DOMESTIC_HELPER_LIST(
  $status: [Boolean]
  $clientID: String
  $companyID: Int
  $search: String
  $offset: Int
  $limit: Int
  ${profession ? `$profession: Int` : ``} 
) {
  count: domestic_helper_master(
    where: {
      is_active: { in: $status }
      is_delete: { eq: false }
      client: { eq: $clientID }
      company_id: { eq: $companyID }
            property_id: { in: $property_id }

      name: { iregex: $search }
      ${profession ? `profession_id:{ eq: $profession}` : ``} 
    }
  ) {
    count: count_id
  }
  domestic_helper_master(
    where: {
      is_active: { in: $status }
      is_delete: { eq: false }
      client: { eq: $clientID }
      company_id: { eq: $companyID }
            property_id: { in: $property_id }

      name: { iregex: $search }
      ${profession ? `profession_id:{ eq: $profession}` : ``} 
    }
    order_by: { created_at: desc }
    offset: $offset
    limit: $limit
  ) {
    id
    helper_image
    name
    gender
    is_barred
    domestic_helper_addressByID {
      id
      mobile_code
      mobile_no
    }
    identification_masterByID {
      id
      type
    }
    identification_no
    is_active
    profession_master{
      name
      id
    }
  }
}`;

export const GET_PROFESSION_MASTER_LIST = (offset, limit, searchText) => gql`
query GET_PROFESSION_MASTER_LIST{
  count:profession_master(
    where:{
      name:{iregex:"${searchText}"}
      is_active:[true]
    }
    offset:${offset}
    limit:${limit}
  ){
    count:count_id
  }
  profession_master(
    where:{
      name:{iregex:"${searchText}"}
      is_active:[true]
    }
    offset:${offset}
    limit:${limit}
  ) {
    value: id
    label: name
  }
}
`;

export const GET_INSPECTION_ITEMS = gql`
  query GET_INSPECTION_ITEMS(
    $companyID: Int
    $clientID: String
    $search: String
    $offset: Int
    $limit: Int
  ) {
    count: inspection_items(
      where: {
        is_active: { eq: true }
        company_id: { eq: $companyID }
        client: { eq: $clientID }
        name: { iregex: $search }
        item: { neq: Inspection }
      }
    ) {
      count: count_id
    }
    inspection_items(
      where: {
        is_active: { eq: true }
        company_id: { eq: $companyID }
        client: { eq: $clientID }
        name: { iregex: $search }
        item: { neq: Inspection }
      }
      offset: $offset
      limit: $limit
    ) {
      id
      value: id
      label: name
      item
    }
  }
`;

export let SELECT_VENDOR = (offset, limit, searchText, obj) => gql`
query GET_VENDOR_MASTER{
  count: vendor_master(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
      client:{eq:"${obj?.client}"}
      ${obj?.company_id ? `company_id:{eq:${obj?.company_id}}` : ``}
    }
  ) {
    count:count_id
  }
  vendor_master(
      where:{

        name:{iregex:"${searchText}"}
        is_active:{eq:true}
        client:{eq:"${obj?.client}"}
        ${obj?.company_id ? `company_id:{eq:${obj?.company_id}}` : ``}
      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
      	value:id
      }
}
`;

export const CHECK_PRICING_COMPONENT = (
  searchText,
  extraParams = {},
  clientID
) => gql`
query GET_PRICING_COMPONENT {
  pricing_component(
    where: {
      is_delete: { eq: false }
      name: { eq: "${searchText}" }
      is_active:true
      ${
        extraParams?.companyID
          ? `company_id: { eq: ${extraParams?.companyID} }`
          : ""
      }
      client:{eq:"${clientID}"}
      ${extraParams?.type ? `revenue_type: { eq: ${extraParams?.type} }` : ""}
      ${
        extraParams?.component_type
          ? `
        primary: { eq: ${extraParams?.component_type?.primary} }
        is_onetime: { eq: ${extraParams?.component_type?.is_onetime} }
        refundable: { eq: ${extraParams?.component_type?.refundable} }
        is_quantity: { eq: ${extraParams?.component_type?.is_quantity} }
        is_parking: { eq: ${extraParams?.component_type?.is_parking} }
        is_wallet: { eq: ${extraParams?.component_type?.is_wallet} }
        is_late_fee: { eq: ${extraParams?.component_type?.is_late_fee} }
        `
          : ""
      }
    }
  ) {
    id
  }
}`;

export let GET_LEAD_STATUS_MASTER = `
query {
  lead_status_master(where:{is_active:true}){
    label:status
    value:id
  }
}
`;

export const CHECK_PRIMARY_COMPONENT = gql`
  query CHECK_PRIMARY_COMPONENT($unit_id: String) {
    count: pricing_table(
      where: {
        is_active: { eq: true }
        unit_id: { eq: $unit_id }
        primary: { eq: true }
        is_onetime: { eq: false }
        refundable: { eq: false }
        is_quantity: { eq: false }
        is_parking: { eq: false }
        rental_breakup: { is_wallet: { eq: false } }
        is_wallet_item: { eq: false }
        rental_breakup: { is_late_fee: { eq: false } }
      }
    ) {
      count: count_id
    }
  }
`;

export const LEAD_ACTIVITY_MASTER = () => gql`
  query lead_activity_master {
    lead_activity_master {
      id
      title: name
      image: image_url
    }
  }
`;

export const Contact_List = gql`
  query {
    contact(Where: { company_id: $company_id, is_active: true }) {
      value: id
      label: first_name
    }
  }
`;
export let SELECTUNIT = (offset, limit, searchText, obj) => gql`
query GET_ACCOUNTS{
  agreement(
      where:{
        company_id:{eq:"${obj?.company_id}"}
        account: { name: { iregex: "${searchText}" } }
      }
      distinct_on:[account_id]
    ){
      account_id
      account{
        name
        account_no
      }
      id
      }
}
`;
export const UOM_LIST = gql`
  query {
    uom_master(
      where: { is_delete: { eq: false }, is_active: { eq: true } }
      order_by: { priority: asc }
    ) {
      value: id
      label: name
      priority
    }
  }
`;

export let SELECT_CLASSIFIED_LEADS = (
  offset,
  limit,
  searchText,
  id = ""
) => gql`
query GET_MASTERS_FOR_CREATING_BLOCK{
  count: classify_leads(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
    }
  ) {
    count:count_id
  }
  classify_leads(
      where:{
        name:{iregex:"${searchText}"}
        is_active:{eq:true}
      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
      	value:id
      }
}
`;

export const GET_UNIT_TYPE_OPTION = (
  offset,
  limit,
  search,
  { company_id, client_id }
) => gql`
query GET_UNIT_TYPE_OPTION {
  count: unit_type_master(
    where: {
      name: { iregex: "${search}" }
      client: { eq: "${client_id}" }
      company_id: { eq: ${company_id} }
      is_active: { eq: true }
      is_delete: { eq: false }
    }
  ) {
    count: count_id
  }
  unit_type_master(
    where: {
      name: { iregex: "${search}" }
      client: { eq: "${client_id}" }
      company_id: { eq: ${company_id} }
      is_active: { eq: true }
      is_delete: { eq: false }
    }
    offset: ${offset}
    limit: ${limit}
    order_by: { name: asc }
  ) {
    value: id
    label: name
  }
}`;

export const GET_UNIT_CATEGORY_MASTER_OPTION = (offset, limit, search) => gql`
query GET_UNIT_CATEGORY_MASTER_OPTION {
  count: unit_category_master(
    where: {
      name: { iregex: "${search}" }
      is_active: { eq: true }
      is_delete: { eq: false }
    }
  ) {
    count: count_id
  }
  unit_category_master(
    where: {
      name: { iregex: "${search}" }
      is_active: { eq: true }
      is_delete: { eq: false }
    }
    offset: ${offset}
    limit: ${limit}
    order_by: { priority: asc }
  ) {
    value: id
    label: name
  }
}`;

export const GET_FEEDBACK_TYPE = (
  offset,
  limit,
  searchText,
  { client_id }
) => gql`
query GET_PROPERTY_TYPE{
  count:customer_feedback_type_master(
    where:{
      name:{iregex:"${searchText}"}
      ${client_id ? `client: {eq: "${client_id}"}` : ``}
      is_active: { eq: true }
    }
  ){
    count:count_id
  }
  customer_feedback_type_master(
    where:{
      name:{iregex:"${searchText}"}
      ${client_id ? `client: {eq: "${client_id}"}` : ``}
      is_active: { eq: true }
    }
    offset:${offset}
    limit:${limit}
  ) {
    value: id
    label: name
  }
}`;

export let GET_CONTACT_AGAINST_COMPANY = (
  offset,
  limit,
  searchText,
  obj,
  company_id = []
) => gql`
  query GET_CONTACT_AGAINST_COMPANY{
    count: contact(
      where:{
        or: { first_name: { iregex: "${searchText}" }, last_name: { iregex: "${searchText}" } }
        is_active:{eq:true}
        ${obj?.client_id ? `client: {eq: "${obj?.client_id}"}` : ``}
        company_id: { eq: ${obj?.company_id} }
  
  
      }
    ) {
      count:count_id
    }
    contact(
        where:{
          or: { first_name: { iregex: "${searchText}" }, last_name: { iregex: "${searchText}" } }
          is_active:{eq:true}
          ${obj?.client_id ? `client: {eq: "${obj?.client_id}"}` : ``}
          company_id: { eq: ${obj?.company_id} }
  
  
        }
        offset:${offset}
        limit:${limit}
      ){
          label:first_name
          last_name
          value:id
        }
  }
  `;

export let SELECCONTACT = (offset, limit, searchText, obj) => gql`
query SELECCONTACT{
  count: contact_account(
    where:{
      company_id:{eq:"${obj?.company_id}"}
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
    }
  ) {
    count:count_id
  }
  contact_account(
      where:{
        company_id:{eq:"${obj?.company_id}"}
        name:{iregex:"${searchText}"}
        is_active:{eq:true}
      }
      offset:${offset}
      limit:${limit}
    ){
        label:name
      	value:account_no
      }
}
`;

export let SELECTAGREEMENT = (offset, limit, searchText, obj) => gql`
  query SELECCONTACT{
    count: agreement(
      where:{
        account_id:{eq:"${obj?.account_no}"}
        agreement_no:{iregex:"${searchText}"}
        is_active:{eq:true}
      }
    ) {
      count:count_id
    }
    agreement(
        where:{
          account_id:{eq:"${obj?.account_no}"}
          agreement_no:{iregex:"${searchText}"}
          is_active:{eq:true}
        }
        offset:${offset}
        limit:${limit}
      ){
          label:agreement_no
          value:id
        }
  }
  `;
export const GET_PROPERTIES_BASED_ON_COMPANY = (
  offset,
  limit,
  searchText,
  params
) => gql`
query {
  count:property(
    where:{
      name:{iregex:"${searchText}"}
      company_id:{eq:"${params?.company_id}"}
      is_active:{eq:true}
    }
  ){
    count:count_id
  }
  property(
    where:{
      name:{iregex:"${searchText}"}
            company_id:{eq:"${params?.company_id}"}
            is_active:{eq:true}

    }
    offset:${offset}
    limit:${limit}
  ) {
    value: id
    label: name
    logo
    mobile_country_code
    mobile_phone
    email
    property_no
    address{
      city
    }
  }
}
`;

export const GET_PAYMENT_TERMS = (company_id) => gql`
  query paymentTerm{
    company_master(where: { id: { eq: ${company_id} } }){
      payment_terms
    }
  }
  `;

export const GET_PROPERTY_LIST = (company_id, searchText, offset, limit) => gql`
  query GET_PROPERTY{
    count: property(
      where:{
          name:{iregex:"${searchText}"}
          is_active:{eq:true}
          company_id:"${company_id}"
  
      }
    ) {
      count:count_id
    }
   property(
        where:{
             name:{iregex:"${searchText}"}
             company_id:"${company_id}"
             is_active:{eq:true}
           }
        offset:${offset}
        limit:${limit}
      ){
           name
           property_no
           id
           logo
          address{
            city
            state
          }
        }
  }
  `;
export const GET_COUNTRY_MASTER = (offset, limit, searchText) => gql`
  query GET_COUNTRY_MASTER{
    count:country_master(
      where:{
        is_active:{eq:true}
        country_name:{iregex:"${searchText}"}
      }){
      count:count_id
    }
    country_master(
      where:{
        is_active:{eq:true}
        country_name:{iregex:"${searchText}"}
      }
      offset:${offset}
      limit:${limit}
      order_by:{created_at:desc,country_name:asc }
    ){
      value:id
      label:country_name
    }
  }`;

export const GET_INTERNAL_LEAD_SOURCE = (
  offset,
  limit,
  searchText,
  extraParams = {}
) => gql`
  query GET_INTERNAL_LEAD_SOURCE{
    count:internal_lead_source(
      where:{
        is_active:{eq:true}
        is_delete:{eq:false}
        ${
          extraParams?.client_id
            ? `client: {eq: "${extraParams?.client_id}"}`
            : ``
        }
        name:{iregex:"${searchText}"}
      }){
      count:count_id
    }
    internal_lead_source(
      where:{
        is_active:{eq:true}
        is_delete:{eq:false}
        ${
          extraParams?.client_id
            ? `client: {eq: "${extraParams?.client_id}"}`
            : ``
        }
        name:{iregex:"${searchText}"}
      }
      offset:${offset}
      limit:${limit}
      order_by:{created_at:desc,name:asc }
    ){
      value:id
      label:name
    }
  }`;

export const GET_COMPANY_ACCOUNT = gql`
  query {
    count: contact(
      where: {
        company_id: $id
        client: $client
        or: {
          email_id: { iregex: $searchText }
          mobile_no: { iregex: $searchText }
          first_name: { iregex: $searchText }
        }
      }
    ) {
      count: count_id
    }
    contact(
      where: {
        company_id: $id
        client: $client
        or: {
          email_id: { iregex: $searchText }
          mobile_no: { iregex: $searchText }
          first_name: { iregex: $searchText }
        }
      }
      offset: $offset
      limit: $limit
    ) {
      value: id
      label: first_name
      id
      name: first_name
      account_no
      mobile_no_country_code
      mobile_no
      email_id
      logo: image_url
    }
  }
`;

export let GET_UOM_LIST_QUERY = (offset, limit, searchText) => gql`
query GET_UOM_LIST {
  count: uom_master(
    where: {
      is_active: { eq: true }
      is_delete: { eq: false }
      name: { iregex: "${searchText}" }
    }
  ) {
    count: count_id
  }
  uom_master(
    where: {
      is_active: { eq: true }
      is_delete: { eq: false }
      name: { iregex: "${searchText}" }
    }
    offset: ${offset}
    limit: ${limit}
  ) {
    value: id
    label: name
  }
}`;

export const GET_JOBS_AND_ROLE = (offset, limit, searchText, id) => gql`
query GET_JOBS_AND_ROLE{
  count: job_master(
    where:{
      name:{iregex:"${searchText}"}
      is_active:{eq:true}
      is_delete:{eq:false}
      company_id:{eq:"${id}"}

    }
  ) {
    count:count_id
  }

     job_master(
      where: {
        name:{iregex:"${searchText}"}
       is_active:{eq:true}
      is_delete:{eq:false}
      company_id:{eq:"${id}"}
    }
       offset:${offset}
       limit:${limit}
      ) {
        value: id
        label: name

  }
}
`;

export const GET_PROPERTY_PROXY_LEAD = (offset, limit, searchText) => gql`
  query GET_PROPERTY_PROXY_LEAD{
    count:property(
      where:{
        is_active:{eq:true}
        name:{iregex:"${searchText}"}
      }){
      count:count_id
    }
    property(
      where:{
        is_active:{eq:true}
        name:{iregex:"${searchText}"}
      }
      offset:${offset}
      limit:${limit}
      order_by:{created_at:desc }
    ){
      value:id
      label:name
    }
  }`;
