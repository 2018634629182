import { useContext, useEffect, useState } from 'react';
import { Badge, Box, Button, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import FilterIMG from '../../assets/filter';
import { DrawerComponent, FilterGenerator, Label, SearchFilter, SelectBox, Subheader, TableWithPagination, TextBox, UseDebounce } from '../../components';
import { AlertContext, AuthContext, BackdropContext } from '../../contexts';
import { accessCheckRender, activeOptions, AlertProps, concat_string, enum_types, enumSelect, getCompanyOption, getRoutePermissionNew, LocalStorageKeys, NetWorkCallMethods, useWindowDimensions } from '../../utils';
import { useStyles } from "./style";
import CloseIcon from '@mui/icons-material/Close';
import { TABLE_HEADING, TABLE_PATH, TABLE_TYPE, INITIAL_ADD_FORM_STATE, INITIAL_FILTER_STATE, INITIAL_TABLE_PAGE, INITIAL_TABLE_LIMIT, INITIAL_TABLE_OFFSET } from './utils/tableUtils';
import { NetworkCall } from '../../networkcall';
import { BackendRoutes } from '../../router/routes';
import { withTranslation } from 'react-i18next';
import { loadOptionsApis } from '../../utils/asyncPaginateLoadOptions';
import { config } from '../../config';

const UtilityMaster = (props) => {
    const { loading, handleLoading, t = () => false } = props;
    const table_offset = INITIAL_TABLE_OFFSET;
    const size = useWindowDimensions();

    const classes = useStyles({ size });
    const debounce = UseDebounce();

    const alert = useContext(AlertContext);
    const backdrop = useContext(BackdropContext);
    const auth = useContext(AuthContext);

    const [permission_state, set_permission_state] = useState({});
    const [selected_company, set_selected_company] = useState("");
    const [company_list, set_company_list] = useState([]);
    const [is_button_disable, set_is_button_disable] = useState(false);
    const [is_table_loading, set_is_table_loading] = useState(true);
    const [table_state, set_table_state] = useState({ rows: [], total_rows_count: 0, });
    const [table_search_text, set_table_search_text] = useState("");
    const [table_page, set_table_page] = useState(INITIAL_TABLE_PAGE);
    const [table_limit, set_table_limit] = useState(INITIAL_TABLE_LIMIT);
    const [filter_state, set_filter_state] = useState(INITIAL_FILTER_STATE);
    const [is_filter_open, set_is_filter_open] = useState(false);
    const [options, set_options] = useState({
        active_status: [],
        utilties_provider: [],
        inspection_item_type: [],
        utility_period_type: [],
    })
    const [is_add_form_open, set_is_add_form_open] = useState(false);
    const [add_form_state, set_add_form_state] = useState(INITIAL_ADD_FORM_STATE);

    useEffect(() => {
        const permission = getRoutePermissionNew(auth);
        if (permission) {
            set_permission_state(permission);
            if (permission?.read) {
                let company = getCompanyOption(backdrop, auth, alert);
                if (company) {
                    getOptions();
                    set_company_list(company?.list);
                    set_selected_company(company?.selected);
                    getInitialData({ company: company?.selected });
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const getInitialData = async ({ company = selected_company }) => {
        return new Promise(async (resolve, reject) => {
            try {
                await Promise.all([
                    getTableData({ company }),
                ]);
                handleLoading(false);
            } catch (error) {
                reject(error);
            }
        })
    }

    const getTableData = ({
        company = selected_company,
        offset = table_offset,
        limit = table_limit,
        search = table_search_text,
        filter = filter_state,
    }) => {
        set_is_button_disable(true);
        set_is_table_loading(true);
        let payload = {
            offset, limit, search,
            company_id: company?.value,
            is_active: filter?.is_active?.length === 0
                ? [true, false]
                : filter?.is_active,
        }
        NetworkCall(
            `${config.api_url}${BackendRoutes?.utilities_utility_master_list}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((response) => {
            let temp_response = response?.data?.data ?? []
            let temp_row = temp_response?.map?.((_) => {
                return {
                    id: _?.id,
                    utility_category_name: _?.utility_category_name?.length > 0 ? _?.utility_category_name : "-",
                    utility_name: _?.utility_name?.length > 0 ? _?.utility_name : "-",
                    description: _?.description?.length > 0 ? _?.description : "-",
                    provider: _?.provider?.length > 0 ? _?.provider : "-",
                    service_provider_name: _?.service_provider_name?.length > 0 ? _?.service_provider_name : "-",
                    price_per_uom: _?.price
                        ? concat_string(
                            {
                                price: `${_?.currency_symbol ? (_?.currency_symbol + ` `) : ``}${_?.price}`,
                                uom: _?.period,
                            },
                            ["price", "uom"],
                            " / "
                        )
                        : "-",
                    is_billing: _?.is_billing,
                    is_tax_enabled: _?.is_taxable,
                    hsn_sac_code: _?.hsn_number?.length > 0 ? _?.hsn_number : "-",
                    tax_group_name: _?.vat_group_name?.length > 0 ? _?.vat_group_name : "-",
                    item_type_name: _?.item_type_name?.length > 0 ? _?.item_type_name : "-",
                    item_category_name: _?.item_category_name?.length > 0 ? _?.item_category_name : "-",
                    item_master_name: _?.item_master_name?.length > 0 ? _?.item_master_name : "-",
                    is_active: _?.is_active ? "Active" : "Inactive",
                    data: _,
                }
            })
            set_table_state({
                rows: temp_row ?? [],
                total_rows_count: response?.data?.count ?? 0
            })
            set_is_button_disable(false);
            set_is_table_loading(false);
        }).catch((e) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
        });
    }

    const getOptions = async () => {
        const result = await enumSelect([
            enum_types?.utilties_provider,
            enum_types?.inspection_item_type,
            enum_types?.utility_period_type,
        ])
        set_options({
            active_status: activeOptions(t),
            utilties_provider: result?.utilties_provider,
            inspection_item_type: result?.inspection_item_type,
            utility_period_type: result?.utility_period_type,
        })
    }

    const handleCompanyChange = ({ company }) => {
        set_selected_company(company);
        getInitialData({ company });
    }

    const handleTableSearch = ({ search }) => {
        set_table_search_text(search)
        debounce(() => searchTableFunction({ search }), 800)
    }

    const searchTableFunction = ({ search }) => {
        if (table_page > 1) { set_table_page(1) }
        getTableData({ search });
    }

    const handleAdd = () => {
        set_add_form_state({
            ...INITIAL_ADD_FORM_STATE,
            currency: selected_company?.currency_symbol ?? "",
            error: {
                utility_category: "",
                utility_name: "",
                description: "",
                service_provider: "",
                uom: "",
                price: "",
                hsn_sac_code: "",
                tax_group: "",
                item_type: "",
                item_category: "",
                item_master: "",
            }
        });
        set_is_add_form_open(true);
    }

    const handleTableIcon = ({ type, data }) => {
        const full_data = data?.data
        const temp_add_state = {
            form_type: type,
            id: full_data?.id,
            utility_category: full_data?.utility_category_id
                ? {
                    value: full_data?.utility_category_id,
                    label: full_data?.utility_category_name,
                }
                : "",
            utility_name: full_data?.utility_name ?? "",
            description: full_data?.description ?? "",
            provider: full_data?.provider ?? "",
            service_provider: full_data?.service_provider_id
                ? {
                    value: full_data?.service_provider_id,
                    label: full_data?.service_provider_name,
                }
                : "",
            uom: full_data?.period
                ? {
                    value: full_data?.period,
                    label: full_data?.period,
                }
                : "",
            price: full_data?.price?.toString().length > 0 ? full_data?.price?.toString() : "",
            currency: full_data?.currency_id
                ? {
                    value: full_data?.currency_id,
                    label: full_data?.currency_symbol,
                }
                : "",
            is_billing: full_data?.is_billing,
            is_tax_enabled: full_data?.is_taxable,
            hsn_sac_code: full_data?.hsn_number ?? "",
            tax_group: full_data?.vat_group_id
                ? {
                    value: full_data?.vat_group_id,
                    label: full_data?.vat_group_name,
                }
                : "",
            item_type: full_data?.item_type_name
                ? {
                    value: full_data?.item_type_name,
                    label: full_data?.item_type_name,
                }
                : "",
            item_category: full_data?.item_category_id
                ? {
                    value: full_data?.item_category_id,
                    label: full_data?.item_category_name,
                }
                : "",
            item_master: full_data?.item_master_id
                ? {
                    value: full_data?.item_master_id,
                    label: full_data?.item_master_name,
                }
                : "",
            is_active: type === "active" ? !full_data?.is_active : full_data?.is_active,
            is_delete: type === "delete",
            error: {
                utility_category: "",
                utility_name: "",
                description: "",
                service_provider: "",
                uom: "",
                price: "",
                hsn_sac_code: "",
                tax_group: "",
                item_type: "",
                item_category: "",
                item_master: "",
            },
        }
        set_add_form_state({ ...temp_add_state })
        if (type === "edit" || type === "view") {
            set_is_button_disable(false);
            set_is_add_form_open(true);
        }
        else if (type === "active" || type === "delete") {
            handleCreateEdit({ final_add_state: temp_add_state });
        }
    }

    const handleTablePagination = ({ page = table_page }) => {
        set_table_page(page);
        let offset = (page - 1) * table_limit;
        getTableData({ offset });
    }

    const handleTablePageLimit = ({ limit = table_limit }) => {
        set_table_limit(limit);
        set_table_page(1);
        getTableData({ limit });
    }

    const updateAddFormState = ({ key, value, error_message }) => {
        let updated_errors = add_form_state?.error;
        updated_errors[key] = error_message ?? "";
        switch (key) {
            case "is_tax_enabled":
                set_add_form_state({
                    ...add_form_state,
                    [key]: value,
                    hsn_sac_code: "",
                    tax_group: "",
                    error: {
                        ...updated_errors,
                        hsn_sac_code: "",
                        tax_group: "",
                    }
                });
                break;
            case "item_type":
                set_add_form_state({
                    ...add_form_state,
                    [key]: value,
                    item_category: "",
                    item_master: "",
                    error: {
                        ...updated_errors,
                        item_category: "",
                        item_master: "",
                    }
                });
                break;
            case "item_category":
                set_add_form_state({
                    ...add_form_state,
                    [key]: value,
                    item_master: "",
                    error: {
                        ...updated_errors,
                        item_master: "",
                    }
                });
                break;

            default:
                set_add_form_state({ ...add_form_state, [key]: value, error: updated_errors });
                break;
        }
    }

    const validate = () => {
        let is_valid = true;
        let error = add_form_state?.error;
        if (add_form_state?.utility_category?.length === 0) { is_valid = false; error.utility_category = t("Utility Category is Required") }
        if (add_form_state?.utility_name?.length === 0) { is_valid = false; error.utility_name = t("Utility Name is Required") }
        if (add_form_state?.service_provider?.length === 0) { is_valid = false; error.service_provider = t("Service Provider is Required") }
        if (add_form_state?.uom?.length === 0) { is_valid = false; error.uom = t("UOM is Required") }
        if (add_form_state?.price?.length === 0) { is_valid = false; error.price = t("Price is Required") }
        if (add_form_state?.is_tax_enabled) {
            if (add_form_state?.hsn_sac_code?.length === 0) { is_valid = false; error.hsn_sac_code = t("HSN/SAC Code is Required") }
            if (add_form_state?.tax_group?.length === 0) { is_valid = false; error.tax_group = t("Tax Group is Required") }
        }
        if (add_form_state?.item_master?.length === 0) { is_valid = false; error.item_master = t("Item Master is Required") }
        if (!is_valid) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill all mandatory field"),
            });
        }
        set_add_form_state({ ...add_form_state, error });
        return is_valid;
    }

    const handleCreateEdit = async ({ final_add_state }) => {
        if ((final_add_state?.form_type === "active" || final_add_state?.form_type === "delete") ? true : validate()) {
            set_is_button_disable(true);
            const current_date = new Date().toISOString();
            const profile_id = localStorage?.getItem?.(LocalStorageKeys?.profileID);

            const payload = {
                utility_name: final_add_state?.utility_name?.length > 0 ? final_add_state?.utility_name : null,
                description: final_add_state?.description?.length > 0 ? final_add_state?.description : null,
                company_id: selected_company?.value ?? null,
                category_id: final_add_state?.utility_category?.value ?? null,
                provider: final_add_state?.provider?.length > 0 ? final_add_state?.provider : null,
                service_provider: final_add_state?.service_provider?.value ?? null,
                period: final_add_state?.uom?.value ?? null,
                price: final_add_state?.price?.length > 0 ? final_add_state?.price : 0,
                currency_id: final_add_state?.currency?.value ?? null,
                is_billing: final_add_state?.is_billing ?? false,
                is_taxable: final_add_state?.is_tax_enabled ?? false,
                hsn_number: final_add_state?.hsn_sac_code?.length > 0 ? final_add_state?.hsn_sac_code : null,
                vat_group_id: final_add_state?.tax_group?.value ?? null,
                inspection_item: final_add_state?.item_master?.value ?? null,
                is_active: final_add_state?.is_active ?? null,
                is_delete: final_add_state?.is_delete ?? null,
                updated_by: profile_id ?? null,
                updated_at: current_date ?? null,
            }

            switch (final_add_state?.form_type) {
                case "add":
                    payload.created_by = profile_id ?? null;
                    payload.created_at = current_date ?? null;
                    break;
                case "edit":
                    payload.id = final_add_state?.id ?? undefined;
                    break;
                case "active":
                    payload.id = final_add_state?.id ?? undefined;
                    break;
                case "delete":
                    payload.id = final_add_state?.id ?? undefined;
                    break;

                default:
                    break;
            }

            NetworkCall(
                `${config.api_url}${BackendRoutes?.utilities_upsert}`,
                NetWorkCallMethods.post, payload, null, true, false
            ).then(() => {
                set_table_page(1);
                set_table_search_text("");
                getInitialData({});
                set_is_add_form_open(false);
                set_add_form_state({ ...INITIAL_ADD_FORM_STATE });
                set_is_button_disable(false);
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.success,
                    msg: final_add_state?.id
                        ? (final_add_state?.form_type === "delete"
                            ? t("Utility Master Deleted Successfully.!!!")
                            : t("Utility Master Updated Successfully.!!!")
                        )
                        : t("Utility Master Created Successfully.!!!"),
                })
            }).catch((error) => {
                set_is_button_disable(false);
                alert.setSnack({
                    ...alert, open: true, msg: t("Some Thing Went Wrong"),
                    severity: AlertProps.severity.error,
                });
            });
        } else { return false }
    }

    const handleFilter = ({ filter }) => {
        set_filter_state(filter);
        set_table_page(1);
        getTableData({ filter });
    }

    const constructUtilityCategoryDropdownResponse = (array) => {
        const temp_utility_category_list = array?.map((i) => {
            return {
                ...i,
                label: i?.name,
                value: i?.id,
            }
        })
        return temp_utility_category_list
    };

    const constructItemMasterDropdownResponse = (array) => {
        const temp_item_master_list = array?.map((i) => {
            return {
                ...i,
                label: i?.name,
                value: i?.id,
            }
        })
        return temp_item_master_list
    };
    let country_id = selected_company?.data?.country?.id;
    const render = () => {
        return (
            <Box className={classes.root}>
                <Subheader
                    hideBackButton
                    title={t("Utility Master")}
                    select
                    options={company_list}
                    value={selected_company}
                    onchange={(company) => handleCompanyChange({ company })} />
                <Box className={classes.body}>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <SearchFilter
                                value={table_search_text}
                                placeholder={t("Search Utility Master")}
                                customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
                                handleChange={(search) => handleTableSearch({ search })} />
                        </Grid>
                        <Grid item xs={8}>
                            <Box display={"flex"} justifyContent={"end"}>
                                <Stack direction="row" spacing={2}
                                    divider={<Divider orientation="vertical" flexItem sx={{ marginInline: "16px" }} />}>
                                    <IconButton
                                        className={classes.filter_button}
                                        onClick={() => set_is_filter_open(!is_filter_open)}>
                                        <Badge
                                            variant="dot"
                                            color="primary"
                                            invisible={!(filter_state.is_active?.length > 0)}>
                                            <FilterIMG color="#091b29" />
                                        </Badge>
                                    </IconButton>
                                    <Button
                                        variant="contained"
                                        className={classes.button}
                                        onClick={handleAdd}>
                                        {t("Add A Utility Master")}
                                    </Button>
                                </Stack>
                            </Box>
                        </Grid >
                        <Grid item xs={12}>
                            <TableWithPagination
                                is_loading={is_table_loading}
                                tableType="no-side"
                                heading={TABLE_HEADING(t)}
                                path={TABLE_PATH}
                                dataType={TABLE_TYPE}
                                rows={table_state?.rows}
                                onClick={() => { }}
                                handleIcon={(type, data) => handleTableIcon({ type, data })}
                                showpagination={true}
                                page={table_page}
                                handlePagination={(page) => handleTablePagination({ page })}
                                limit={table_limit}
                                handleChangeLimit={(limit) => handleTablePageLimit({ limit })}
                                totalRowsCount={table_state?.total_rows_count}
                                height={'calc(100vh - 290px)'}
                                view={true}
                                edit={true}
                                delete={true} />
                        </Grid>
                    </Grid >
                    <FilterGenerator
                        open={is_filter_open}
                        onClose={() => set_is_filter_open(false)}
                        onApply={(filter) => handleFilter({ filter })}
                        components={[
                            {
                                component: "toggleButton",
                                value: filter_state?.is_active,
                                options: activeOptions(t),
                                isMulti: true,
                                state_name: "is_active",
                                label: t("Status")
                            },
                        ]} />
                    <DrawerComponent
                        anchor={size?.width >= 600 ? "right" : "bottom"}
                        DrawerClass={classes.add_drawer}
                        open={is_add_form_open}
                        onClose={() => set_is_add_form_open(false)}
                        toggleDrawer={() => set_is_add_form_open(false)}
                        component={
                            <>
                                <Box className={classes.add_drawer_header}>
                                    <Typography className={classes.add_drawer_header_title}>
                                        {add_form_state?.form_type === "add"
                                            ? t("Add Utility Master")
                                            : add_form_state?.form_type === "edit"
                                                ? t("Edit Utility Master")
                                                : add_form_state?.form_type === "view"
                                                    ? t("View Utility Master")
                                                    : t("Add Utility Master")
                                        }
                                    </Typography>
                                    <IconButton onClick={() => set_is_add_form_open(false)}
                                        className={classes.add_drawer_close_button}>
                                        <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
                                    </IconButton>
                                </Box>
                                <Box className={add_form_state?.form_type === "view"
                                    ? classes.add_drawer_body_view
                                    : classes.add_drawer_body}>
                                    <SelectBox
                                        isRequired
                                        isReadOnly={add_form_state?.form_type === "view"}
                                        label={t("Utility Category")}
                                        placeholder={t("Select Utility Category")}
                                        value={add_form_state?.utility_category ?? ""}
                                        onChange={(value) => updateAddFormState({ key: "utility_category", value })}
                                        isPaginate
                                        debounceTimeout={800}
                                        loadOptions={(search, array, handleLoading) =>
                                            loadOptionsApis(
                                                BackendRoutes?.utilities_category_list?.slice(1),
                                                { is_active: [true] },
                                                search, array, handleLoading, "data", {},
                                                constructUtilityCategoryDropdownResponse,
                                            )}
                                        isError={add_form_state?.error?.utility_category?.length > 0}
                                        errorMessage={add_form_state?.error?.utility_category} />
                                    <Box height={16} />
                                    <TextBox
                                        isrequired
                                        isReadonly={add_form_state?.form_type === "view"}
                                        label={t("Utility Name")}
                                        placeholder={t("Enter Utility Name")}
                                        value={add_form_state?.utility_name ?? ""}
                                        onChange={(e) => updateAddFormState({ key: "utility_name", value: e?.target?.value })}
                                        isError={add_form_state?.error?.utility_name?.length > 0}
                                        errorMessage={add_form_state?.error?.utility_name} />
                                    <Box height={16} />
                                    <TextBox
                                        multiline
                                        isReadonly={add_form_state?.form_type === "view"}
                                        label={t("Description")}
                                        placeholder={t("Enter Description")}
                                        value={add_form_state?.description ?? ""}
                                        onChange={(e) => updateAddFormState({ key: "description", value: e?.target?.value })}
                                        isError={add_form_state?.error?.description?.length > 0}
                                        errorMessage={add_form_state?.error?.description} />
                                    <Box height={16} />
                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={"16px"}>
                                        <Box width={{ xs: "100%", sm: "50%" }}>
                                            <Label isRequired label={t("Provider")} />
                                            <Box className={classes.add_drawer_button_container}>
                                                {options?.utilties_provider?.map((_) => {
                                                    return <Button
                                                        className={_?.value === add_form_state?.provider
                                                            ? classes.add_drawer_button_selected
                                                            : classes.add_drawer_button_unselected
                                                        }
                                                        onClick={() => (add_form_state?.form_type === "add" ||
                                                            add_form_state?.form_type === "edit")
                                                            ? updateAddFormState({ key: "provider", value: _?.value })
                                                            : false
                                                        }>
                                                        {t(_?.label)}
                                                    </Button>
                                                })}
                                            </Box>
                                        </Box>
                                        <Box width={{ xs: "100%", sm: "50%" }}>
                                            <SelectBox
                                                isRequired
                                                isReadOnly={add_form_state?.form_type === "view"}
                                                label={t("Service Provider")}
                                                placeholder={t("Select Service Provider")}
                                                value={add_form_state?.service_provider ?? ""}
                                                onChange={(value) => updateAddFormState({ key: "service_provider", value })}
                                                isPaginate
                                                debounceTimeout={800}
                                                loadOptions={(search, array, handleLoading) =>
                                                    loadOptionsApis(
                                                        BackendRoutes?.service_provider_list?.slice(1),
                                                        { company_id: selected_company?.value, is_active: [true] },
                                                        search, array, handleLoading, "data",
                                                    )}
                                                isError={add_form_state?.error?.service_provider?.length > 0}
                                                errorMessage={add_form_state?.error?.service_provider} />
                                        </Box>
                                    </Stack>
                                    <Box height={16} />
                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={"16px"}>
                                        <Box width={{ xs: "100%", sm: "50%" }}>
                                            <SelectBox
                                                isRequired
                                                isReadOnly={add_form_state?.form_type === "view"}
                                                label={t("UOM")}
                                                placeholder={t("Select UOM")}
                                                value={add_form_state?.uom ?? ""}
                                                onChange={(value) => updateAddFormState({ key: "uom", value })}
                                                options={options?.utility_period_type ?? []}
                                                isError={add_form_state?.error?.uom?.length > 0}
                                                errorMessage={add_form_state?.error?.uom} />
                                        </Box>
                                        <Box width={{ xs: "100%", sm: "50%" }}>
                                            <TextBox
                                                isrequired
                                                isReadonly={add_form_state?.form_type === "view"}
                                                label={t("Price Per UOM")}
                                                placeholder={t("Enter Price")}
                                                type={"number"}
                                                value={add_form_state?.price ?? ""}
                                                onChange={(e) => updateAddFormState({ key: "price", value: e?.target?.value })}
                                                endAdornment={add_form_state?.currency?.label ?? selected_company?.currency_symbol ?? ""}
                                                isError={add_form_state?.error?.price?.length > 0}
                                                errorMessage={add_form_state?.error?.price} />
                                        </Box>
                                    </Stack>
                                    <Box height={16} />
                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={"16px"}>
                                        <Box width={{ xs: "100%", sm: "50%" }}>
                                            <SelectBox
                                                isReadOnly={add_form_state?.form_type === "view"}
                                                label={t("Item Type")}
                                                placeholder={t("Select Item Type")}
                                                value={add_form_state?.item_type ?? ""}
                                                onChange={(value) => updateAddFormState({ key: "item_type", value })}
                                                options={options?.inspection_item_type ?? []}
                                                isError={add_form_state?.error?.item_type?.length > 0}
                                                errorMessage={add_form_state?.error?.item_type} />
                                        </Box>
                                        <Box width={{ xs: "100%", sm: "50%" }}>
                                            <SelectBox
                                                key={JSON.stringify(add_form_state?.item_type)}
                                                isReadOnly={add_form_state?.form_type === "view" || !add_form_state?.item_type?.value > 0}
                                                label={t("Item Category")}
                                                placeholder={t("Select Item Category")}
                                                value={add_form_state?.item_category ?? ""}
                                                onChange={(value) => updateAddFormState({ key: "item_category", value })}
                                                isPaginate
                                                debounceTimeout={800}
                                                loadOptions={(search, array, handleLoading) =>
                                                    loadOptionsApis(
                                                        BackendRoutes?.inspection_item_category?.slice(1),
                                                        { item_type: [add_form_state?.item_type?.value], },
                                                        search, array, handleLoading, "list",
                                                    )}
                                                isError={add_form_state?.error?.item_category?.length > 0}
                                                errorMessage={add_form_state?.error?.item_category} />
                                        </Box>
                                    </Stack>
                                    <Box height={16} />
                                    <SelectBox
                                        isRequired
                                        key={JSON.stringify([add_form_state?.item_type, add_form_state?.item_category])}
                                        isReadOnly={add_form_state?.form_type === "view"}
                                        label={t("Item Master")}
                                        placeholder={t("Select Item Master")}
                                        menuPlacement="top"
                                        value={add_form_state?.item_master ?? ""}
                                        onChange={(value) => updateAddFormState({ key: "item_master", value })}
                                        isPaginate
                                        debounceTimeout={800}
                                        loadOptions={(search, array, handleLoading) =>
                                            loadOptionsApis(
                                                BackendRoutes?.free_text_invoice_get_free_text_item_types?.slice(1),
                                                {
                                                    company_id: selected_company?.value,
                                                    item_type: "Item Master",
                                                    item_master_type: add_form_state?.item_type?.value,
                                                    item_master_category: add_form_state?.item_category?.value,
                                                    component_type: "item_based"
                                                },
                                                search, array, handleLoading, "items", {},
                                                constructItemMasterDropdownResponse,
                                            )}
                                        isError={add_form_state?.error?.item_master?.length > 0}
                                        errorMessage={add_form_state?.error?.item_master} />
                                    <Box height={16} />
                                    <Stack direction={"row"} spacing={"16px"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Label label={t("Billing")} />
                                        <Stack direction={"row"} columnGap={"8px"} className={classes.switch_container}
                                            sx={{ cursor: Boolean(add_form_state?.form_type === "view") ? "auto" : "pointer" }}
                                            onClick={(e) => add_form_state?.form_type === "view"
                                                ? false
                                                : updateAddFormState({ key: "is_billing", value: !add_form_state?.is_billing })
                                            }>
                                            <Typography className={classes.switch_container_value}>
                                                {add_form_state?.is_billing ? t("Yes") : t("No")}
                                            </Typography>
                                            {add_form_state?.is_billing ?
                                                <Box className={classes.switch_container_on}>
                                                    <Box className={classes.switch_toggle_on} />
                                                </Box> :
                                                <Box className={classes.switch_container_off}>
                                                    <Box className={classes.switch_toggle_off} />
                                                </Box>
                                            }
                                        </Stack>
                                    </Stack>
                                    <Box height={16} />
                                    <Stack direction={"row"} spacing={"16px"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Label label={t("Tax Enabled")} />
                                        <Stack direction={"row"} columnGap={"8px"} className={classes.switch_container}
                                            sx={{ cursor: Boolean(add_form_state?.form_type === "view") ? "auto" : "pointer" }}
                                            onClick={(e) => add_form_state?.form_type === "view"
                                                ? false
                                                : updateAddFormState({ key: "is_tax_enabled", value: !add_form_state?.is_tax_enabled })
                                            }>
                                            <Typography className={classes.switch_container_value}>
                                                {add_form_state?.is_tax_enabled ? t("Yes") : t("No")}
                                            </Typography>
                                            {add_form_state?.is_tax_enabled ?
                                                <Box className={classes.switch_container_on}>
                                                    <Box className={classes.switch_toggle_on} />
                                                </Box> :
                                                <Box className={classes.switch_container_off}>
                                                    <Box className={classes.switch_toggle_off} />
                                                </Box>
                                            }
                                        </Stack>
                                    </Stack>
                                    {add_form_state?.is_tax_enabled &&
                                        <>
                                            <Box height={16} />
                                            <Stack direction={{ xs: "column", sm: "row" }} spacing={"16px"}>
                                                <Box width={{ xs: "100%", sm: "50%" }}>
                                                    <TextBox
                                                        isrequired
                                                        isReadonly={add_form_state?.form_type === "view"}
                                                        label={t("HSN/SAC Code")}
                                                        placeholder={t("Enter Code")}
                                                        value={add_form_state?.hsn_sac_code ?? ""}
                                                        onChange={(e) => updateAddFormState({ key: "hsn_sac_code", value: e?.target?.value })}
                                                        isError={add_form_state?.error?.hsn_sac_code?.length > 0}
                                                        errorMessage={add_form_state?.error?.hsn_sac_code} />
                                                </Box>
                                                <Box width={{ xs: "100%", sm: "50%" }}>
                                                    <SelectBox
                                                        isRequired
                                                        isReadOnly={add_form_state?.form_type === "view"}
                                                        label={t("Tax Group")}
                                                        placeholder={t("Select Tax Group")}
                                                        menuPlacement="top"
                                                        value={add_form_state?.tax_group ?? ""}
                                                        onChange={(value) => updateAddFormState({ key: "tax_group", value })}
                                                        isPaginate
                                                        debounceTimeout={800}
                                                        loadOptions={(search, array, handleLoading) =>
                                                            loadOptionsApis(
                                                                BackendRoutes?.vat_group_list?.slice(1),
                                                                { is_active: [true],
                                                                  country_id: country_id,
                                                                },
                                                                search, array, handleLoading, "data",
                                                            )}
                                                        isError={add_form_state?.error?.tax_group?.length > 0}
                                                        errorMessage={add_form_state?.error?.tax_group} />
                                                </Box>
                                            </Stack>
                                        </>
                                    }
                                    <Box height={16} />
                                    <Label isRequired label={t("Status")} />
                                    <Box className={classes.add_drawer_button_container}>
                                        {options?.active_status?.map((_) => {
                                            return <Button
                                                className={_?.value === add_form_state?.is_active
                                                    ? classes.add_drawer_button_selected
                                                    : classes.add_drawer_button_unselected
                                                }
                                                onClick={() => (add_form_state?.form_type === "add" ||
                                                    add_form_state?.form_type === "edit")
                                                    ? updateAddFormState({ key: "is_active", value: _?.value })
                                                    : false
                                                }>
                                                {_?.label}
                                            </Button>
                                        })}
                                    </Box>
                                    <Box height={16} />
                                </Box>
                                {(add_form_state?.form_type === "add" || add_form_state?.form_type === "edit") &&
                                    <Box className={classes.add_drawer_footer}>
                                        {add_form_state?.form_type === "edit" &&
                                            <Button
                                                fullWidth
                                                className={classes.add_drawer_footer_close_button}
                                                onClick={() => set_is_add_form_open(false)}>
                                                {t("Cancel")}
                                            </Button>
                                        }
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            className={classes.add_drawer_footer_button}
                                            disabled={is_button_disable}
                                            onClick={() => handleCreateEdit({ final_add_state: add_form_state })}>
                                            {add_form_state?.form_type === "add"
                                                ? t("Create")
                                                : t("Save")
                                            }
                                        </Button>
                                    </Box>}
                            </>}
                    />
                </Box>
            </Box>
        );
    }

    return accessCheckRender(render, permission_state, "", loading);
}
export default withTranslation("utilitiesMaster")(UtilityMaster);
