import { Box, Grid, IconButton } from "@mui/material";
import { withTranslation } from "react-i18next";
import FilterIcon from "../../assets/filterIcon";
import { SearchFilter } from "../../components";
import { useTableStyles } from "./style";

const SearchFilterSec = ({ t, handleSearch = () => false, searchText = "", openfilter = () => false, dot = false }) => {
    // style
    const classes = useTableStyles()

    return (
        <Box>
            <Grid container className={classes.girdItem} alignItems="center" >
                <Grid item md={4}>
                    <SearchFilter label={false} placeholder={t("searchNewRequest")} handleChange={(value) => handleSearch(value)} value={searchText} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
                </Grid>
                <Grid item xs={8} display="flex" justifyContent={"end"} alignItems="flex-end" onClick={() => openfilter()}>
                    {dot ?
                        <FilterIcon />
                        : <IconButton
                            size="small"
                            className={classes.filterIconbtn}
                        >
                            <img src="/images/filter.svg" alt="filter" />
                        </IconButton>}
                </Grid>
            </Grid>
        </Box>
    )
}
export default withTranslation("inspection")(SearchFilterSec)

