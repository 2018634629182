import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const contactsStyles = makeStyles((theme) => ({
    root: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        // backgroundColor: "white",
        padding: "16px",
        height: `calc(100vh - 147px)`,
        overflow: "hidden",
        margin: "14px"
    },
    content: {
        textAlign: "center",
        backgroundColor: "white",
        padding: "0px 18px 8px 16px",
        marginTop: "1px"
    },
    btn: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "none",
        fontSize:"0.875rem",
        height: '40px'
    },
    btn3: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "none",
        fontSize:"0.875rem",
        height: '40px',
        whiteSpace: "nowrap", 
        overflow: "hidden",  
        "&:hover": {
            backgroundColor: "#fff"
        },
        marginInlineStart: "16px"
    },
    btn2: {
        borderRadius: theme.palette.borderRadius,
        color: theme.palette.secondary.main,
    },
    text: {
        fontSize:"1.5rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
    },
    drawer: {
        "& .MuiDrawer-paper": {

            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
        },
        "& .MuiContainer-root": {
            padding: "0px"
        },

    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: "16px",
            padding: "0px",
        },
    },
    addContactDialogStyle: {
        "& .MuiDialog-paper": {
            borderRadius: theme.palette.borderRadius,
            padding: "0px",
            width: "320px",
        },
    },
    drawerHeaderStyle: {
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
    },
    drawerHeaderTextStyle: {
        fontSize:"1rem",
        fontWeight: "bolder",
        color: theme.typography.color.primary,
    },
    subText: {
        fontSize:"1rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.secondary
    },
    img: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        //marginTop: "4px",
        padding: "12px",
        marginLeft: "16px"
    },
    tableHead: {
        backgroundColor: "#F2F4F7",
        color: theme.typography.color.secondary,
        borderRadius: theme.palette.borderRadius,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
    },
    thead: {
        backgroundColor: '#F2F4F7',
        '& th:first-child': {
            borderRadius: '1em 0 0 1em'
        },
        '& th:last-child': {
            borderRadius: '0 1em 1em 0'
        }
    },
    tbody: {
        backgroundColor: '#F2F4F7',
        '& th:first-child': {
            borderRadius: '1em 0 0 1em'
        },
        '& th:last-child': {
            borderRadius: '0 1em 1em 0'
        }
    },
    businessType: {
        backgroundColor: "#78B1FE",
        color: "white",
        padding: "1px",
        borderRadius: theme.palette.borderRadius,
        textAlign: "center"
    },
    bottomTitle: {
        fontSize:"1.125rem",
        fontFamily: FontFamilySwitch().extraBold,

    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
        marginBottom: "12px"
    },
    contentBottom: {
        // padding: "12px",

    },

    grid: {
        backgroundColor: theme.palette.background.secondary,
        borderRadius: theme.palette.borderRadius,
        padding: "4px"
    },
    title: {
        fontSize:"1rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary

    },
    subTitle: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.palette.primary.main
    },
    divider: {
        height: "12px",
        width: "2px",
        backgroundColor: "black"
    },
    dialogHeaderStyle: {
        padding: "16px 24px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #E4E8EE"
    },
    dialogHeaderTitleStyle: {
        fontFamily: FontFamilySwitch().extraBold,
        fontSize:"1rem",
        color: "#091B29"
    },
    dialogCloseIconButtonStyle: {
        padding: "0px"
    },
    dialogBodyStyle: {
        padding: "24px",
        height: "350px",
        overflowY: "overlay"
    },
    dialogFooterStyle: {
        padding: "24px",
    },
    dialogFooterButtonStyle: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.08)",
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: "white"
    },
    createContactRoot: {
        position: 'relative'
    },
    box: {
        boxShadow: "0px 0px 16px #00000014",
        borderRadius: "4px",
        height: 'calc(100vh - 200px)',
        overflow: "auto",
        backgroundColor: "white"
    },
    box2: {
        boxShadow: "0px 0px 16px #00000014",
        borderRadius: "4px",
        // height: 'calc(100vh - 200px)',
        overflow: "auto",
        backgroundColor: "white"
    },
    boxTab: {
        height: 'calc(100vh - 300px)',
        overflow: "auto",
    },
    noData: {
        fontFamily: FontFamilySwitch().bold,
        fontSize:"1rem",
        color: theme.typography.color.primary,
        padding: "12px",
        textAlign: "center",
        marginTop: "20%"
    },
    tabtitle: {
        fontSize:"0.75rem",
        color: '#4E5A6B',
        fontFamily: FontFamilySwitch().bold,
    },
    tabtitle1: {
        fontSize:"0.75rem",
        color: '#5078E1',
        fontFamily: FontFamilySwitch().bold,
    },
    subtabtitle: {
        fontSize:"0.75rem",
        color: '#4E5A6B',
        fontFamily: FontFamilySwitch().regular,
    },
    subtabtitle1: {
        fontSize:"0.75rem",
        color: '#5078E1',
        fontFamily: FontFamilySwitch().regular,
    },
    selectBack1: {
        backgroundColor: '#F1F7FF',
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px"
    },
    selectBack: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
    },
}));