import { config } from "../../config";
import { jwtDecode } from 'jwt-decode';
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, getMobileLimitBasedOnCC, LocalStorageKeys, NetWorkCallMethods, RetainLocalStorage, ValidateEmail } from "../../utils";

export const silentOTP = (login, alert, navigate, isIamValideToLogin, setDisable) => {
  let data = {
    password: login?.password
  };
  if (isIamValideToLogin()) {
    // eslint-disable-next-line

    let limit = getMobileLimitBasedOnCC(login?.mobile_no?.mobile_code)
    var isphone = login?.mobile_no?.mobile?.length === limit;

    var email = ValidateEmail(
      login.email
    );
    if (isphone) {
      data["mobile_no"] = login.mobile_no.mobile;
      data["mobile_code"] = login.mobile_no.mobile_code;
    } else if (email) {
      data["email_id"] = login.email?.toLowerCase();;
    } else {
      data["ucid"] = login.email;
    }
    NetworkCall(
      `${config.authapi}/auth/login`,
      NetWorkCallMethods.post, data, null, false, false
    ).then((res) => {
      localStorage.setItem(
        LocalStorageKeys.authToken,
        res?.data?.token
      )
      const token = jwtDecode(res?.data?.token)

      const payload = {
        otp: "0000",
      };
      // eslint-disable-next-line
      var isphone_1 = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(token?.mobile_no);
      // eslint-disable-next-line
      var email_1 = /^\w+([\.-]?\ w+)*@\w+([\.-]?\ w+)*(\.\w{2,3})+$/.test(token?.email_id);
      if (isphone_1) {
        payload["mobile_no"] = token?.mobile_no;
      } else if (email_1) {
        payload["email_id"] = token?.email_id;
      } else {
        payload["ucid"] = token?.email_id;
      }
      NetworkCall(
        `${config.authapi}/auth/verifyOTP?token=${res?.data?.token}`,
        NetWorkCallMethods.post, payload, null, false, false
      ).then((response) => {
        localStorage.setItem(
          LocalStorageKeys.authToken,
          response?.data?.token
        )
        navigate(Routes.companyselect, {
          state: {
            main: {
              clientData: response?.data ?? "",
            },
          },
        });
        return false
      }).catch((err) => {

        if (err?.response?.data?.status === 403) {

          alert.setSnack({
            ...alert, open: true, msg: "Sorry you do not have access",
            severity: AlertProps.severity.error,
          });
          setDisable(false)
        } else {
          RetainLocalStorage();
          navigate(Routes.login)
          alert.setSnack({
            ...alert, open: true, msg: " Invalid OTP",
            severity: AlertProps.severity.error,
          });
          setDisable(false)
        }

      });
    }).catch((err) => {
      if (err?.status === 400) {
        alert.setSnack({
          ...alert, open: true, msg: err?.response?.data?.message,
          severity: AlertProps.severity.warning,
        });
        setDisable(false)
        navigate(Routes.setpassword + "?welcomeAuthToken=" + err?.response?.data?.token, {
          state: {
            main: {
              type: "firstLogin",
            },
          },
        })
      } else {
        alert.setSnack({
          ...alert, open: true, msg: "Invalid Credientials",
          severity: AlertProps.severity.error,
        });
        setDisable(false)
      }
    });
  } else {
    setDisable(false)
    return false;
  }
};