import React from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Subheader } from "../../../components";
import { config } from "../../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { accessCheckRender, AlertProps, getRoutePermissionNew, NetWorkCallMethods } from "../../../utils";
import { PropertyDetails } from './propertyView/propertydetails';
import { withTranslation } from "react-i18next";

const AccessGatesPropertyView = ({
      t
}) => {

      const navigate = useNavigate()
      const search = useLocation().search
      const propertyID = new URLSearchParams(search).get("propertyID")

      // useContext
      const alert = React.useContext(AlertContext)
      const backdrop = React.useContext(BackdropContext)
      const auth = React.useContext(AuthContext)

      // useState
      const [data, setData] = React.useState([])
      const [permission, setPermission] = React.useState({})

      // use effect to get permission
      React.useEffect(() => {
            const perm = getRoutePermissionNew(auth)
            if (perm) {
                  setPermission(perm)
                  if (perm?.read) {
                        if (propertyID) { getPropertyByID() }
                  }
            }
            // eslint-disable-next-line
      }, [auth, propertyID]);

      // Function to get property details
      const getPropertyByID = () => {
            backdrop.setBackDrop({ ...backdrop, open: true, message: t("Loading...") })
            NetworkCall(
                  `${config.api_url}/queries/property/get`,
                  NetWorkCallMethods.post,
                  {
                        propertyID: propertyID
                  },
                  null,
                  true,
                  false
            ).then((r) => {
                  setData(r?.data?.data)
                  backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            }).catch((err) => {
                  backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
                  alert.setSnack({
                        ...alert, open: true,
                        severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
                  })
            })
      }

      const render = () => {
            return <>
                  <Subheader title={data?.property?.[0]?.name} goBack={() => navigate(-1)}
                        select isReadOnly={true} value={data?.property?.[0]?.company} />
                  <PropertyDetails t={t} permission={permission} data={data?.property?.[0]} assets={data?.asset} />
            </>
      }

      return (
            <div >
                  {accessCheckRender(render, permission)}
            </div >
      )

}
export default withTranslation("accessGates")(AccessGatesPropertyView);
