/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from '@mui/icons-material/Close';
import { Badge, Box, Button, Dialog, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import FilterIMG from '../../assets/filter';
import { FilterGenerator, SearchFilter, Subheader, TableWithPagination, TextBox, UseDebounce } from '../../components';
import { NewLoader } from '../../components/newLoader';
import { config } from '../../config';
import { AlertContext, AuthContext, BackdropContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { accessCheckRender, AlertProps, getCompanyOption, getRoutePermissionNew, LocalStorageKeys, NetWorkCallMethods } from '../../utils';
import { JobsHeading, JobsPath, JobsType, StatusOptionListMap } from '../../utils/jobs/jobsListUtils';
import { jobsStyles } from "./style";
import { withTranslation } from 'react-i18next';

const Jobs = (props) => {
    const defaultFilterState = { status: [true] };
    const { loading, handleLoading, t } = props;

    const defaultAddJobsState = {
        formType: "add",
        id: "",
        name: "",
        description: "",
        status: true,
        delete: false,
        error: {
            name: ""
        }
    }

    const classes = jobsStyles()
    const debounce = UseDebounce()

    // useContext
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)


    // useState
    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [jobsList, setJobsList] = React.useState({})
    const [searchText, setSearchText] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [filterData, setFilterData] = React.useState(defaultFilterState)
    const [filterDrawer, setFilterDrawer] = React.useState(false)
    const [addJobDialogOpen, setAddJobDialogOpen] = React.useState(false)
    const [addJobsState, setAddJobsState] = React.useState({ ...defaultAddJobsState })
    const [permission, setPermission] = React.useState({})
    const [loader, setLoader] = React.useState(true)
    const [buttonDisable, setButtonDisable] = React.useState(false)

    const StatusOptionList = (t) => [
        { label: t('Active'), value: true },
        { label: t('Inactive'), value: false },
    ]

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getCompany()
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const getCompany = () => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
        }
    }

    // useEffect to get jobs list using selected company and filter data when loading the screen
    React.useEffect(() => {
        setPage(1)
        if (selectedCompany?.value) { getJobs() }
    }, [selectedCompany, filterData])

    // Function to get jobs list based on the input data
    const getJobs = (offset = 0, limit = 10, search = "") => {
        const payload = {
            status: (!filterData?.status || filterData?.status?.length === 0) ?
                [true, false] : filterData?.status,
            company_id: selectedCompany?.value,
            search,
            offset,
            limit,
        }
        NetworkCall(
            `${config.api_url}/queries/job_master/list`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((r) => {
            setJobsList({
                data: r?.data?.data?.job_master,
                totalRowsCount: r?.data?.count
            })
            setLoader(false)
            handleLoading(false)
        }).catch((e) => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
            })
        })
    }

    // Set row data for table
    const JobsRows = React.useCallback(jobsList?.data?.map((_) => {
        let j
        try {
            j = {
                id: _?.id,
                name: _?.name ?? "",
                description: _?.description?.length === 0 ? "-" : _?.description ?? "-",
                status: _?.is_active ? "Active" : "Inactive",
                data: _
            }
        } catch (err) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
            })
        }
        return j
    }), [jobsList])

    // Function to change the company
    const handleCompanyChange = (value) => {
        setLoader(true)
        setSelectedCompany(value)
    }

    // Function for search in search component
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    // Function to search data in jobs list
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }
        getJobs(0, limit, e)
    }

    // Function to open add job form
    const handleAddJob = () => {
        setAddJobsState({ ...defaultAddJobsState })
        setAddJobDialogOpen(true)
    }

    // Function to handle icon in table row
    const handleTableIcon = (type, data) => {
        const tempData = data?.data
        const tempAddJobsState = {
            formType: type,
            id: tempData?.id,
            name: tempData?.name,
            description: tempData?.description,
            status: type === "active" ? !tempData?.is_active : tempData?.is_active,
            delete: type === "delete" ?? tempData?.is_delete,
            error: defaultAddJobsState?.error
        }
        setAddJobsState({ ...tempAddJobsState })
        if (type === "edit" || type === "view") { setAddJobDialogOpen(true) }
        else if (type === "active" || type === "delete") { handleCreateEdit(tempAddJobsState) }
    }

    // Function to handle pagination in table
    const handleTablePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getJobs(offset, limit, searchText)
    }

    // Function to handle page limit in table
    const handleTablePageLimit = (value) => {
        setLimit(value)
        setPage(1)
        getJobs(0, value, searchText)
    }

    // Function to update addJobsState
    const updateAddJobsDialogState = (k, v) => {
        let error = addJobsState?.error
        error[k] = ""
        setAddJobsState({ ...addJobsState, [k]: v, error })
    }

    // Function for updating addNewState
    const validate = () => {
        let isValid = true
        let error = addJobsState.error
        if (addJobsState?.name?.length === 0) { isValid = false; error.name = t("Name is Required") }
        if (!isValid) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill all mandatory field"),
            })
        }
        setAddJobsState({ ...addJobsState, error })
        return isValid
    }

    // Function to create a Job
    const handleCreateEdit = async (data) => {
        if ((data?.formType === "active" || data?.formType === "delete") ? true : validate()) {
            setButtonDisable(true)
            const currentDate = new Date().toISOString()
            const profileID = localStorage.getItem(LocalStorageKeys.profileID)

            const payload = {
                id:data?.id??undefined,
                company_id: selectedCompany?.value ?? undefined,
                description: data?.description ?? undefined,
                is_active: data?.status ?? undefined,
                is_delete: data?.delete ?? undefined,
                name: data?.name ?? undefined,
                updated_at: currentDate ?? undefined,
                updated_by: profileID ?? undefined
            }

            if (data?.formType === "add") {
                payload.client = localStorage.getItem(LocalStorageKeys.clinetID) ?? undefined
                payload.created_at = currentDate ?? undefined
                payload.created_by = profileID ?? undefined
                delete payload.id
            }
            NetworkCall(
                `${config.api_url}/queries/job_master/upsert`,
                NetWorkCallMethods.post,
                {"payload":[payload]},
                null,
                true,
                false
            ).then((r) => {
                setButtonDisable(false)
                setLoader(true)
                setAddJobsState({ ...defaultAddJobsState })
                getJobs()
                setAddJobDialogOpen(false)
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.success,
                    msg: `${data?.id
                        ? (data?.formType === "delete"
                            ? t("Job Deleted Successfully.!!!")
                            : t("Job Updated Successfully.!!!"))
                        : t("Job Created Successfully.!!!")}`,
                })
            }).catch((err) => {
                setButtonDisable(false)
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
                })
            })

        } else { return false }
    }


    const render = () => {
        return <>
            <Subheader hideBackButton={true} title={t("Jobs")}
                select options={companyList} value={selectedCompany} onchange={(e) => handleCompanyChange(e)} />
            {
                loader ? (
                    <NewLoader minusHeight="100px" />
                ) : (
                    <div className={classes.root}>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <SearchFilter value={searchText} placeholder={t("Search Jobs")}
                                    handleChange={(value) => handleSearch(value)} />
                            </Grid>
                            <Grid item xs={8}>
                                <Box display={"flex"} justifyContent={"end"}>

                                    <Stack direction="row"
                                        divider={<Divider orientation="vertical" flexItem sx={{ marginInline: "16px" }} />}>
                                        <IconButton onClick={() => setFilterDrawer(!filterDrawer)}
                                            className={classes.filterButton}>
                                            <Badge variant="dot" color="primary"
                                                invisible={!(filterData.status?.length > 0)}>
                                                <FilterIMG color="#091b29" />
                                            </Badge>
                                        </IconButton>
                                        {permission.create && <Button variant="contained" className={classes.button}
                                            onClick={handleAddJob}>
                                            {t("Add Job")}
                                        </Button>}
                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                        <TableWithPagination
                                    heading={JobsHeading(t)}
                                    rows={JobsRows}
                                    path={JobsPath}
                                    showpagination={true}
                                    showpdfbtn={false}
                                    showexcelbtn={false}
                                    showSearch={false}
                                    handleIcon={handleTableIcon}
                                    onClick={() => console.log("")}
                                    tableType="no-side"
                                    dataType={JobsType}
                                    handlePagination={handleTablePagination}
                                    handleChangeLimit={handleTablePageLimit}
                                    totalRowsCount={jobsList?.totalRowsCount}
                                    page={page}
                                    limit={limit}
                                    height={'calc(100vh - 290px)'}
                                    view={permission.read}
                                    edit={permission.update}
                                    delete={permission.delete}
                                    moreIconRight />

                        <FilterGenerator open={filterDrawer} onClose={() => setFilterDrawer(false)}
                            onApply={(value) => setFilterData(value)}
                            defaultState={defaultFilterState}
                            components={[
                                {
                                    component: "toggleButton",
                                    value: filterData?.status,
                                    options: StatusOptionList(t),
                                    isMulti: true,
                                    state_name: "status",
                                    label: t("Status")
                                },
                            ]} />
                        <Dialog
                            className={classes.addJobsDialog}
                            open={addJobDialogOpen}
                            onClose={() => setAddJobDialogOpen(false)}>
                            <div className={classes.addJobsDialogHeader}>
                                <Typography className={classes.addJobsDialogHeaderTitle}>
                                    {addJobsState?.formType === "add" ? t("Add Job") :
                                        addJobsState?.formType === "edit" ? t("Edit Job") :
                                            addJobsState?.formType === "view" ? t("View Job") : t("Add Job")}
                                </Typography>
                                <IconButton onClick={() => setAddJobDialogOpen(false)}
                                    className={classes.addJobsDialogCloseButton}>
                                    <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
                                </IconButton>
                            </div>
                            <div className={classes.addJobsDialogBody}>
                                <TextBox
                                    isrequired
                                    isReadonly={addJobsState?.formType === "view"}
                                    label={t("Job Name")}
                                    placeholder={t("Enter Name")}
                                    value={addJobsState?.name ?? ""}
                                    onChange={(e) => updateAddJobsDialogState("name", e.target.value)}
                                    isError={addJobsState?.error?.name?.length > 0}
                                    errorMessage={addJobsState?.error?.name} />
                                <Box height={16} />
                                <TextBox
                                    isReadonly={addJobsState?.formType === "view"}
                                    label={t("Responsibilities")}
                                    placeholder={t("Enter Responsibilities")}
                                    multiline
                                    value={addJobsState?.description ?? ""}
                                    onChange={(e) => updateAddJobsDialogState("description", e.target.value)} />
                                <Box height={16} />
                                <Typography className={classes.addJobsDialogFieldLabel} noWrap>{t("Status")}</Typography>
                                <div className={classes.addJobsDialogButtonContainer}>
                                    {StatusOptionListMap.map((_) => {
                                        return <Button className={_?.value === addJobsState?.status ?
                                            classes.addJobxDialogButtonSelected : classes.addJobxDialogButtonUnSelected}
                                            onClick={() => (addJobsState?.formType === "add" || addJobsState?.formType === "edit") ?
                                                updateAddJobsDialogState("status", _?.value) : false}>
                                            {t(_?.label)}
                                        </Button>
                                    })}
                                </div>
                            </div>
                            {(addJobsState?.formType === "add" || addJobsState?.formType === "edit") &&
                                <div className={classes.addJobsDialogFooter}>
                                    {addJobsState?.formType === "edit" && <><Button fullWidth
                                        className={classes.addJobsDialogFooterCloseButton}
                                        onClick={() => setAddJobDialogOpen(false)}>
                                        {t("Cancel")}
                                    </Button></>}
                                    <Button variant="contained" fullWidth
                                        disabled={buttonDisable}
                                        className={classes.addJobsDialogFooterButton}
                                        onClick={() => handleCreateEdit(addJobsState)}>
                                        {addJobsState?.formType === "add" ? t("Create") : t("Save")}
                                    </Button>
                                </div>}
                        </Dialog>
                    </div>)}
        </>
    }

    return <div>
        {accessCheckRender(render, permission, "", loading)}
    </div>
}
export default withTranslation("jobs")(Jobs)
