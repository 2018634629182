import { Box, Button, Divider, Grid, Stack } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import FilterIcon from "../../assets/filterIcon";
import { AlertDialog, SearchFilter, TableWithPagination } from "../../components";
import { committeeTableDatatype, committeeTableHeading, committeeTablePath } from '../../utils';
import { AddForm } from "./addForm";
import { HistoryViewCard } from "./historyCard";
import { useStylesManagement } from "./style";

const ManagementCommitteeTable = ({ t, rowData = [], handleIcon = () => false, handlePagination = () => false, handleChangeLimit = () => false, page = 1, limit = 10, type = "add", formdata = "", memberOptions = [], setFormData = () => false, showForm = false, setShowForm = () => false, roleOptions = "", updateState = () => false, selectedMember = "", setSelectedMember = () => false, setFilterDrawer = () => false, searchText = "", handleSearch = () => false, onFormSubmit = () => false, onCloseModal = () => false, PropertyName = "", state = "", permissions = "", isDisableBtn = false }) => {
    const classes = useStylesManagement()
    const showAddForm = () => {
        setShowForm(true)
        setFormData({ ...formdata, "type": "add" })
    }
    return (
        <Box className={classes.tableSection}>
            <Grid container>
                {/* searchbar */}
                <Grid item xs={4}>
                    <SearchFilter
                        handleChange={handleSearch}
                        value={searchText}
                        placeholder={t("Search")}
                    />
                </Grid>
                {/* filterIcon */}
                <Grid item md={8} >
                    <Stack
                        direction="row"
                        divider={<Divider className={classes.dividerStyle} orientation="vertical" flexItem />}
                        spacing={2}
                        justifyContent={"end"}
                    >

                        <FilterIcon onClick={() => setFilterDrawer(true)} />
                        {permissions.create &&
                            <div className={classes.addbtn}>
                                <Button variant="contained"
                                    onClick={() => showAddForm()}
                                >{t("Add Member")}</Button>
                            </div>
                        }
                    </Stack>
                </Grid>
            </Grid>
            <TableWithPagination
                heading={committeeTableHeading(t)}
                rows={rowData}
                path={committeeTablePath}
                dataType={committeeTableDatatype}
                showpagination={true}
                count="2"
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                tableType="no-side"
                handleIcon={handleIcon}
                delete={false}
                history={true}
                edit={permissions?.update}
                handlePagination={handlePagination}
                handleChangeLimit={handleChangeLimit}
                totalRowsCount={rowData?.length}
                page={page}
                limit={limit}
                height={'calc(100vh - 400px)'}


            />
            <AlertDialog
                isNormal
                header={formdata.type === 'add' ? t('Add Member') : formdata.type === 'history' ? t("History") : t("Edit Member")}
                onClose={() => onCloseModal()}
                open={showForm}
                component={
                    formdata?.type === "history" ?
                        <HistoryViewCard formdata={formdata} t={t} />
                        :
                        <AddForm
                            formdata={formdata}
                            setFormData={setFormData}
                            memberOptions={memberOptions}
                            roleOptions={roleOptions}
                            updateState={updateState}
                            selectedMember={selectedMember}
                            PropertyName={PropertyName}
                            setSelectedMember={setSelectedMember}
                            onFormSubmit={onFormSubmit}
                            onCloseModal={onCloseModal}
                            state={state}
                            t={t}
                            isDisableBtn={isDisableBtn}
                        />

                }

            />
        </Box>
    )
}
export default withTranslation("managementcommittee")(ManagementCommitteeTable)