import { Box, Popover, Typography } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { useStyles } from "../styles";
import { Person } from "./personDetails";
import { AlertDialog, IframeViwer, TemplateGenerator } from "../../../components";
import {AlertProps, NetWorkCallMethods, timeZoneConverter} from '../../../utils'
import { NetworkCall } from "../../../networkcall";
import { AlertContext } from "../../../contexts";
import { NewLoader } from "../../newLoader";
import { config } from "../../../config";

const CardDetails = ({ data, t }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialog, setDialog] = React.useState({
    bool: false,
    details:{},
  });
  const [open1 , setOpen1] = React.useState(false)
  const [template, setTemplate] = React.useState(false)

  const alert = React.useContext(AlertContext);

  const [pdf , setPdf]= React.useState({
    bool:false,
    data:""
})
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;


  const generate=async(value)=>{
    setTemplate(false)
    setPdf({
      bool:false,
      data:""
      });  
      setOpen1(true);
      const payload = {
        id:dialog?.details?.quotation_id,
        type_master_id:value?.value

    };
    await NetworkCall(
        `${config.api_url}/quotation/get_quotation_pdf`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
    )
        .then((res) => {
            setPdf({
                bool:true,
                data:res?.data
            })
        })
        .catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("some thing went wrong"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        });
}


  return (
    <Box paddingTop="7px" marginLeft="12px">
      <Box className={classes.timeLineMain}>
        <Typography className={classes.title}>{data?.title ?? ""}</Typography>

        {data?.is_qutation && (
          <Typography className={classes.subTitleTimeLine}>
            <span
              className={classes.link}
              onClick={() => {
                setTemplate(true)
                setDialog({
                  ...dialog,
                  details:data?.qutation
                })
              }

              }
            >
              {data?.qutation?.quotation_no ?? ""}
            </span>
            ,
            {" "}
            <span>
              {t("Won on")}
            </span>
            {" "}
            <span>
              &#x202a;{timeZoneConverter(data?.qutation?.qutation_created_at, "DD MMM YY HH:mm a")}&#x202c;
            </span>
          </Typography>
        )}
        {data?.is_owner && (
          <Typography className={classes.assigned}>
            {t("Assigned To")} {" "}
            <span onMouseEnter={handleClick} className={classes.link}>
              {data?.assignee?.user_first_name ?? ''}&nbsp;
              {data?.assignee?.user_last_name ?? ''}
            </span>
          </Typography>
        )}
        {data?.sub?.length > 0 && (
          <Typography className={classes.subTitleTimeLine}>
            {data?.sub ?? ''}
          </Typography>
        )}
            {data?.sub1?.length > 0 && (
          <Typography className={classes.subTitleTimeLine}>
            {data?.sub1 ?? ''}
          </Typography>
        )}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className={classes.popover}
      >
        <Person data={{
          first_name:data?.assignee?.user_first_name ?? '-',
          last_name:data?.assignee?.user_last_name ?? '',
          email_id:data?.assignee?.email_id ?? '-',
          mobile_no_country_code:data?.assignee?.mobile_no_country_code ?? '-',
          mobile_no:data?.assignee?.mobile_no ?? "-",
          image_url:data?.assignee?.image_url ?? ""
        }} />
      </Popover>

      {/* quotation pdf */}
      <AlertDialog
        header={`${t("Quotation")} - ${data?.qutation?.quotation_no}`}
        onClose={() => setOpen1(false)}
        open={open1}
        component={
          <Box >
            {
              pdf?.bool ?
                <IframeViwer pdf={pdf} height="125px" />

                :
                <NewLoader minusHeight="125px" />
            }
          </Box>
        }
      />

<TemplateGenerator t={t} name={t("Quotation Template")} open={template} onClose={()=>setTemplate(false)} type={1} generate={generate}/>
    </Box>
  );
};
export default withTranslation("agreement")(CardDetails);
