import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const departmentStyles = makeStyles((theme) => ({
    root: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        height: `calc(100vh - 147px)`,
        overflow: "hidden",
        margin: "14px"
    },
    content: {
        textAlign: "center"
    },
    button: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "none",
        fontSize:"0.875rem"
    },
    filterButton: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "12px"
    },
    addDepartmentDialog: {
        "& .MuiDialog-paper": {
            borderRadius: theme.palette.borderRadius,
            padding: "0px",
            width: "320px",
        },
    },
    addDepartmentDialogHeader: {
        padding: "16px 24px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #E4E8EE"
    },
    addDepartmentDialogHeaderTitle: {
        fontFamily: FontFamilySwitch().extraBold,
        fontSize:"1rem",
        color: "#091B29"
    },
    addDepartmentDialogCloseButton: {
        padding: "0px"
    },
    addDepartmentDialogBody: {
        padding: "24px",
        overflowY: "overlay"
    },
    addDepartmentDialogFooter: {
        display: "flex",
        columnGap: "12px",
        padding: "24px",
    },
    addDepartmentDialogFooterButton: {
        borderRadius: theme.palette.borderRadius,
        height: "45px",
        border: "1px solid #5078E1",
        boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.08)",
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: "white"
    },
    addDepartmentDialogFooterCloseButton: {
        borderRadius: theme.palette.borderRadius,
        height: "45px",
        border: "1px solid #5078E1",
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: "#5078E1",
        backgroundColor: "white",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#E4E8EE ",
        },
    },
    addDepartmentDialogFieldLabel: {
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().semiBold,
        fontSize:"0.75rem",
        marginBottom: "5px",
    },
    addDepartmentDialogButtonContainer: {
        display: "flex",
        rowGap: "8px",
        columnGap: "8px",
    },
    addDepartmentDialogButtonSelected: {
        borderRadius: theme.palette.borderRadius,
        height: "45px",
        border: "1px solid #5078E1",
        backgroundColor: "#5078E1",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#5078E1 ",
        },
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: "white",
    },
    addDepartmentDialogButtonUnSelected: {
        borderRadius: theme.palette.borderRadius,
        height: "45px",
        border: "1px solid #E4E8EE",
        backgroundColor: "white",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#E4E8EE ",
        },
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: "#98A0AC",
    },
}));