import { Box, Typography, Stack } from "@mui/material";
import React from "react";
import { CompanyToggle, CustomCheckBox, TextBox } from "../index";
import { useStyles } from "./styles";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { SelectBox } from "../../components/select";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const CompanySettingsList = ({
  title = "",
  options = [],
  checked = true,
  onChange = () => false,
  value = "",
  bgColor = "white",
  border = true,
  custom = false,
  toggle = false,
  type = "",
  loading = {},
  loadOptionData = () => false,
  customValue = 30,
  onChangeText = () => false,
  textValue = "",
  render = () => false,
  isError = false,
  errorMessage = "",
  openCustom = false,
  selectBoxWidth = "",
  onCustomChange = () => false,
  onboardSelectOptions = [],
  is_onchange = false,
  setSettings = () => false,
  data = {},
  t,
}) => {
  const classes = useStyles();

  const onChangeValue = (key, val, custom) => {
    if (is_onchange) {
      return onChange(val);
    }
    let updateValue = {
      ...value,
      [key]: val,
    };
    onChange && onChange(updateValue);
    if (custom) {
      onCustomChange && onCustomChange(updateValue, "custom");
    }
  };

  const ledgerKeys = [
    "acc_receiveLedger",
    "tax_payLedger",
    "depositsLedger",
    "acc_payLedger",
    "tax_receiveLedger",
    "accrued_ledger",
    "customerLedger",
    "inventoryLedger",
    "goodsLedger",
    "creditsLedger",
    "advanceLedger",
    "investLedger",
    "saleLedger",
  ];

  const updateState = (key, key1, key2, value, val) => {
    if (ledgerKeys.includes(key1)) {
      setSettings({
        ...data,
        [key]: {
          ...value,
          [key1]: val,
          [key2]: "",
        },
        error: {
          ...data.error,
          [key]: "",
        },
      });
    } else if (ledgerKeys.includes(key2)) {
      setSettings({
        ...data,
        [key]: {
          ...value,
          [key1]: val,
          [key2]: val?.ledger,
        },
        error: {
          ...data.error,
          [key]: "",
        },
      });
    } else
      setSettings({
        ...data,
        [key]: {
          ...value,
          [key1]: val,
        },
      });
  };

  const itemManualResponse = (array) => {
    const details = array?.map((i) => {
      return {
        label: i?.name,
        value: i?.id,
        ...i,
      };
    });
    return details;
  };

  const manualResponse = (array) => {
    const details = array?.map((i) => {
      return {
        ...i,
        label: i?.name,
        value: i?.id,
      };
    });
    return details;
  };

  const manualLedgerResponse = (data) => {
    let result = data?.map((val) => {
      return {
        value: val?.id,
        label: val?.name,
      };
    });
    return result;
  };

  return (
    <Box>
      <Box
        display="flex"
        className={classes.root}
        alignItems={type === "select" ? "center" : "center"}
        sx={{
          backgroundColor: bgColor,
          border: border ? "1px solid #E4E8EE" : "none",
        }}
      >
        <Box flexGrow={1}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className={classes.title}>{title}</Typography>
            {toggle && (
              <CustomCheckBox
                checked={value?.is_active}
                onChange={(e) => onChangeValue("is_active", e.target.checked)}
              />
            )}
          </Box>

          {options?.length > 0 && (
            <Box
              display="flex"
              alignItems={type === "textbox" ? "center" : "auto"}
            >
              <CompanyToggle
                options={options}
                value={value?.value}
                onChange={(value) => onChangeValue("value", value)}
                isMulti={true}
                fullWidth={false}
                custom={custom}
                height="46px"
                customValue={customValue}
                openCustom={openCustom}
                onCustomChange={(value) =>
                  onChangeValue("value", value, "custom")
                }
                type={type}
                textValue={textValue}
                onChangeText={onChangeText}
              />
              {type === "textbox" && (
                <Box width="100px" marginLeft="12px" marginTop="-4px">
                  <TextBox
                    label={null}
                    onChange={(e) => onChangeText("value", e.target.value)}
                    value={textValue}
                    placeholder="Custom"
                  />
                </Box>
              )}
            </Box>
          )}
          {type === "text" && (
            <Box width="250px">
              <TextBox
                label={null}
                onChange={(e) => onChange(e.target.value)}
                value={value}
                placeholder="Custom"
                type="number"
              />
            </Box>
          )}
          {value?.is_active && type === "AR" && (
            <Stack direction="column" spacing={0.5}>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("Account Receivable - Assets")}
                </Typography>
                <Stack direction={"row"} spacing={2}>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      value={value?.acc_receiveType ?? ""}
                      color={"#fff"}
                      isReadOnly
                    />
                  </Box>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      value={value?.acc_receiveLedger ?? ""}
                      placeholder={t("Select Main Ledger")}
                      onChange={(val) =>
                        updateState(
                          "account_receive",
                          "acc_receiveLedger",
                          "acc_receive",
                          value,
                          val
                        )
                      }
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "/com_ledger/listAll",
                          {
                            type: value?.acc_receiveType
                              ? [value?.acc_receiveType?.value]
                              : [],
                            is_active: [true],
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualLedgerResponse
                        )
                      }
                      debounceTimeout={800}
                      isPaginate={true}
                      color={"#fff"}
                      valueTooltip={true}
                    />
                  </Box>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      placeholder={t("Select Account")}
                      onChange={(val) =>
                        updateState(
                          "account_receive",
                          "acc_receive",
                          "acc_receiveLedger",
                          value,
                          val
                        )
                      }
                      value={value?.acc_receive ?? ""}
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "/com_master/listAll",
                          {
                            type: value?.acc_receiveType
                              ? [value?.acc_receiveType?.value]
                              : [],
                            ledger_id: value?.acc_receiveLedger
                              ? [value?.acc_receiveLedger?.value]
                              : [],
                            is_active: [true],
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualResponse
                        )
                      }
                      debounceTimeout={800}
                      isPaginate={true}
                      valueTooltip={true}
                      color={"#fff"}
                      key={JSON.stringify(value?.acc_receiveLedger)}
                    />
                  </Box>
                </Stack>
              </Box>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("Output Tax Payable - Liability")}
                </Typography>
                <Stack direction={"row"} spacing={2}>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      value={value?.tax_payType ?? ""}
                      color={"#fff"}
                      isReadOnly
                    />
                  </Box>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      value={value?.tax_payLedger ?? ""}
                      placeholder={t("Select Main Ledger")}
                      onChange={(val) =>
                        updateState(
                          "account_receive",
                          "tax_payLedger",
                          "tax_pay",
                          value,
                          val
                        )
                      }
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "/com_ledger/listAll",
                          {
                            type: value?.tax_payType
                              ? [value?.tax_payType?.value]
                              : [],
                            is_active: [true],
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualLedgerResponse
                        )
                      }
                      debounceTimeout={800}
                      isPaginate={true}
                      color={"#fff"}
                      valueTooltip={true}
                    />
                  </Box>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      placeholder={t("Select Account")}
                      onChange={(val) =>
                        updateState(
                          "account_receive",
                          "tax_pay",
                          "tax_payLedger",
                          value,
                          val
                        )
                      }
                      value={value?.tax_pay ?? ""}
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "/com_master/listAll",
                          {
                            type: value?.tax_payType
                              ? [value?.tax_payType?.value]
                              : [],
                            ledger_id: value?.tax_payLedger
                              ? [value?.tax_payLedger?.value]
                              : [],
                            is_active: [true],
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualResponse
                        )
                      }
                      debounceTimeout={800}
                      isPaginate={true}
                      valueTooltip={true}
                      color={"#fff"}
                      key={JSON.stringify(value?.tax_payLedger)}
                    />
                  </Box>
                </Stack>
              </Box>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("Security Deposits Payable - Liability")}
                </Typography>
                <Stack direction={"row"} spacing={2}>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      value={value?.depositsType ?? ""}
                      color={"#fff"}
                      isReadOnly
                    />
                  </Box>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      value={value?.depositsLedger ?? ""}
                      placeholder={t("Select Main Ledger")}
                      onChange={(val) =>
                        updateState(
                          "account_receive",
                          "depositsLedger",
                          "deposits",
                          value,
                          val
                        )
                      }
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "/com_ledger/listAll",
                          {
                            type: value?.depositsType
                              ? [value?.depositsType?.value]
                              : [],
                            is_active: [true],
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualLedgerResponse
                        )
                      }
                      debounceTimeout={800}
                      isPaginate={true}
                      color={"#fff"}
                      valueTooltip={true}
                    />
                  </Box>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      placeholder={t("Select Account")}
                      onChange={(val) =>
                        updateState(
                          "account_receive",
                          "deposits",
                          "depositsLedger",
                          value,
                          val
                        )
                      }
                      value={value?.deposits ?? ""}
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "/com_master/listAll",
                          {
                            type: value?.depositsType
                              ? [value?.depositsType?.value]
                              : [],
                            ledger_id: value?.depositsLedger
                              ? [value?.depositsLedger?.value]
                              : [],
                            is_active: [true],
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualResponse
                        )
                      }
                      debounceTimeout={800}
                      isPaginate={true}
                      valueTooltip={true}
                      color={"#fff"}
                      key={JSON.stringify(value?.depositsLedger)}
                    />
                  </Box>
                </Stack>
              </Box>
              {/* ------------------ */}
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("Customer Security Deposits - Liability")}
                </Typography>
                <Stack direction={"row"} spacing={2}>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      value={value?.customerType ?? ""}
                      color={"#fff"}
                      isReadOnly
                    />
                  </Box>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      value={value?.customerLedger ?? ""}
                      placeholder={t("Select Main Ledger")}
                      onChange={(val) =>
                        updateState(
                          "account_receive",
                          "customerLedger",
                          "customer_deposit",
                          value,
                          val
                        )
                      }
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "/com_ledger/listAll",
                          {
                            type: value?.customerType
                              ? [value?.customerType?.value]
                              : [],
                            is_active: [true],
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualLedgerResponse
                        )
                      }
                      debounceTimeout={800}
                      isPaginate={true}
                      color={"#fff"}
                      valueTooltip={true}
                    />
                  </Box>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      placeholder={t("Select Account")}
                      onChange={(val) =>
                        updateState(
                          "account_receive",
                          "customer_deposit",
                          "customerLedger",
                          value,
                          val
                        )
                      }
                      value={value?.customer_deposit ?? ""}
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "/com_master/listAll",
                          {
                            type: value?.customerType
                              ? [value?.customerType?.value]
                              : [],
                            ledger_id: value?.customerLedger
                              ? [value?.customerLedger?.value]
                              : [],
                            is_active: [true],
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualResponse
                        )
                      }
                      debounceTimeout={800}
                      isPaginate={true}
                      valueTooltip={true}
                      color={"#fff"}
                      key={JSON.stringify(value?.customerLedger)}
                    />
                  </Box>
                </Stack>
              </Box>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("Property Inventory - Assets")}
                </Typography>
                <Stack direction={"row"} spacing={2}>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      value={value?.inventoryType ?? ""}
                      color={"#fff"}
                      isReadOnly
                    />
                  </Box>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      value={value?.inventoryLedger ?? ""}
                      placeholder={t("Select Main Ledger")}
                      onChange={(val) =>
                        updateState(
                          "account_receive",
                          "inventoryLedger",
                          "inventory",
                          value,
                          val
                        )
                      }
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "/com_ledger/listAll",
                          {
                            type: value?.inventoryType
                              ? [value?.inventoryType?.value]
                              : [],
                            is_active: [true],
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualLedgerResponse
                        )
                      }
                      debounceTimeout={800}
                      isPaginate={true}
                      color={"#fff"}
                      valueTooltip={true}
                    />
                  </Box>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      placeholder={t("Select Account")}
                      onChange={(val) =>
                        updateState(
                          "account_receive",
                          "inventory",
                          "inventoryLedger",
                          value,
                          val
                        )
                      }
                      value={value?.inventory ?? ""}
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "/com_master/listAll",
                          {
                            type: value?.inventoryType
                              ? [value?.inventoryType?.value]
                              : [],
                            ledger_id: value?.inventoryLedger
                              ? [value?.inventoryLedger?.value]
                              : [],
                            is_active: [true],
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualResponse
                        )
                      }
                      debounceTimeout={800}
                      isPaginate={true}
                      valueTooltip={true}
                      color={"#fff"}
                      key={JSON.stringify(value?.inventoryLedger)}
                    />
                  </Box>
                </Stack>
              </Box>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("Customer Advance Payment - Liability")}
                </Typography>
                <Stack direction={"row"} spacing={2}>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      value={value?.advanceType ?? ""}
                      color={"#fff"}
                      isReadOnly
                    />
                  </Box>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      value={value?.advanceLedger ?? ""}
                      placeholder={t("Select Main Ledger")}
                      onChange={(val) =>
                        updateState(
                          "account_receive",
                          "advanceLedger",
                          "advance",
                          value,
                          val
                        )
                      }
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "/com_ledger/listAll",
                          {
                            type: value?.advanceType
                              ? [value?.advanceType?.value]
                              : [],
                            is_active: [true],
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualLedgerResponse
                        )
                      }
                      debounceTimeout={800}
                      isPaginate={true}
                      color={"#fff"}
                      valueTooltip={true}
                    />
                  </Box>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      placeholder={t("Select Account")}
                      onChange={(val) =>
                        updateState(
                          "account_receive",
                          "advance",
                          "advanceLedger",
                          value,
                          val
                        )
                      }
                      value={value?.advance ?? ""}
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "/com_master/listAll",
                          {
                            type: value?.advanceType
                              ? [value?.advanceType?.value]
                              : [],
                            ledger_id: value?.advanceLedger
                              ? [value?.advanceLedger?.value]
                              : [],
                            is_active: [true],
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualResponse
                        )
                      }
                      debounceTimeout={800}
                      isPaginate={true}
                      valueTooltip={true}
                      color={"#fff"}
                      key={JSON.stringify(value?.advanceLedger)}
                    />
                  </Box>
                </Stack>
              </Box>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("Customer Investment Payments - Liability")}
                </Typography>
                <Stack direction={"row"} spacing={2}>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      value={value?.investType ?? ""}
                      color={"#fff"}
                      isReadOnly
                    />
                  </Box>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      value={value?.investLedger ?? ""}
                      placeholder={t("Select Main Ledger")}
                      onChange={(val) =>
                        updateState(
                          "account_receive",
                          "investLedger",
                          "invest",
                          value,
                          val
                        )
                      }
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "/com_ledger/listAll",
                          {
                            type: value?.investType
                              ? [value?.investType?.value]
                              : [],
                            is_active: [true],
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualLedgerResponse
                        )
                      }
                      debounceTimeout={800}
                      isPaginate={true}
                      color={"#fff"}
                      valueTooltip={true}
                    />
                  </Box>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      placeholder={t("Select Account")}
                      onChange={(val) =>
                        updateState(
                          "account_receive",
                          "invest",
                          "investLedger",
                          value,
                          val
                        )
                      }
                      value={value?.invest ?? ""}
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "/com_master/listAll",
                          {
                            type: value?.investType
                              ? [value?.investType?.value]
                              : [],
                            ledger_id: value?.investLedger
                              ? [value?.investLedger?.value]
                              : [],
                            is_active: [true],
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualResponse
                        )
                      }
                      debounceTimeout={800}
                      isPaginate={true}
                      valueTooltip={true}
                      color={"#fff"}
                      key={JSON.stringify(value?.investLedger)}
                    />
                  </Box>
                </Stack>
              </Box>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("Cost Of Sale - Expense")}
                </Typography>
                <Stack direction={"row"} spacing={2}>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      value={value?.saleType ?? ""}
                      color={"#fff"}
                      isReadOnly
                    />
                  </Box>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      value={value?.saleLedger ?? ""}
                      placeholder={t("Select Main Ledger")}
                      onChange={(val) =>
                        updateState(
                          "account_receive",
                          "saleLedger",
                          "sale",
                          value,
                          val
                        )
                      }
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "/com_ledger/listAll",
                          {
                            type: value?.saleType
                              ? [value?.saleType?.value]
                              : [],
                            is_active: [true],
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualLedgerResponse
                        )
                      }
                      debounceTimeout={800}
                      isPaginate={true}
                      color={"#fff"}
                      valueTooltip={true}
                    />
                  </Box>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      placeholder={t("Select Account")}
                      onChange={(val) =>
                        updateState(
                          "account_receive",
                          "sale",
                          "saleLedger",
                          value,
                          val
                        )
                      }
                      value={value?.sale ?? ""}
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "/com_master/listAll",
                          {
                            type: value?.saleType
                              ? [value?.saleType?.value]
                              : [],
                            ledger_id: value?.saleLedger
                              ? [value?.saleLedger?.value]
                              : [],
                            is_active: [true],
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualResponse
                        )
                      }
                      debounceTimeout={800}
                      isPaginate={true}
                      valueTooltip={true}
                      color={"#fff"}
                      key={JSON.stringify(value?.saleLedger)}
                    />
                  </Box>
                </Stack>
              </Box>
            </Stack>
          )}
          {value?.is_active && type === "AP" && (
            <Stack direction="column" spacing={0.5}>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("Account Payable - Liability")}
                </Typography>
                <Stack direction={"row"} spacing={2}>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      value={value?.acc_payType ?? ""}
                      color={"#fff"}
                      isReadOnly
                    />
                  </Box>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      value={value?.acc_payLedger ?? ""}
                      placeholder={t("Select Main Ledger")}
                      onChange={(val) =>
                        updateState(
                          "account_pay",
                          "acc_payLedger",
                          "acc_pay",
                          value,
                          val
                        )
                      }
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "/com_ledger/listAll",
                          {
                            type: value?.acc_payType
                              ? [value?.acc_payType?.value]
                              : [],
                            is_active: [true],
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualLedgerResponse
                        )
                      }
                      debounceTimeout={800}
                      isPaginate={true}
                      color={"#fff"}
                      valueTooltip={true}
                    />
                  </Box>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      placeholder={t("Select Account")}
                      onChange={(val) =>
                        updateState(
                          "account_pay",
                          "acc_pay",
                          "acc_payLedger",
                          value,
                          val
                        )
                      }
                      value={value?.acc_pay ?? ""}
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "/com_master/listAll",
                          {
                            type: value?.acc_payType
                              ? [value?.acc_payType?.value]
                              : [],
                            ledger_id: value?.acc_payLedger
                              ? [value?.acc_payLedger?.value]
                              : [],
                            is_active: [true],
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualResponse
                        )
                      }
                      debounceTimeout={800}
                      isPaginate={true}
                      valueTooltip={true}
                      color={"#fff"}
                      key={JSON.stringify(value?.acc_payLedger)}
                    />
                  </Box>
                </Stack>
              </Box>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("Input Tax Receivable - Assets")}
                </Typography>
                <Stack direction={"row"} spacing={2}>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      value={value?.tax_receiveType ?? ""}
                      color={"#fff"}
                      isReadOnly
                    />
                  </Box>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      value={value?.tax_receiveLedger ?? ""}
                      placeholder={t("Select Main Ledger")}
                      onChange={(val) =>
                        updateState(
                          "account_pay",
                          "tax_receiveLedger",
                          "tax_receive",
                          value,
                          val
                        )
                      }
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "/com_ledger/listAll",
                          {
                            type: value?.tax_receiveType
                              ? [value?.tax_receiveType?.value]
                              : [],
                            is_active: [true],
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualLedgerResponse
                        )
                      }
                      debounceTimeout={800}
                      isPaginate={true}
                      color={"#fff"}
                      valueTooltip={true}
                    />
                  </Box>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      placeholder={t("Select Account")}
                      onChange={(val) =>
                        updateState(
                          "account_pay",
                          "tax_receive",
                          "tax_receiveLedger",
                          value,
                          val
                        )
                      }
                      value={value?.tax_receive ?? ""}
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "/com_master/listAll",
                          {
                            type: value?.tax_receiveType
                              ? [value?.tax_receiveType?.value]
                              : [],
                            ledger_id: value?.tax_receiveLedger
                              ? [value?.tax_receiveLedger?.value]
                              : [],
                            is_active: [true],
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualResponse
                        )
                      }
                      debounceTimeout={800}
                      isPaginate={true}
                      valueTooltip={true}
                      color={"#fff"}
                      key={JSON.stringify(value?.tax_receiveLedger)}
                    />
                  </Box>
                </Stack>
              </Box>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("Accrued Liabilities - Liability")}
                </Typography>
                <Stack direction={"row"} spacing={2}>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      value={value?.accrued_type ?? ""}
                      color={"#fff"}
                      isReadOnly
                    />
                  </Box>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      value={value?.accrued_ledger ?? ""}
                      placeholder={t("Select Main Ledger")}
                      onChange={(val) =>
                        updateState(
                          "account_pay",
                          "accrued_ledger",
                          "accrued_liability",
                          value,
                          val
                        )
                      }
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "/com_ledger/listAll",
                          {
                            type: value?.accrued_type
                              ? [value?.accrued_type?.value]
                              : [],
                            is_active: [true],
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualLedgerResponse
                        )
                      }
                      debounceTimeout={800}
                      isPaginate={true}
                      color={"#fff"}
                      valueTooltip={true}
                    />
                  </Box>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      placeholder={t("Select Account")}
                      onChange={(val) =>
                        updateState(
                          "account_pay",
                          "accrued_liability",
                          "accrued_ledger",
                          value,
                          val
                        )
                      }
                      value={value?.accrued_liability ?? ""}
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "/com_master/listAll",
                          {
                            type: value?.accrued_type
                              ? [value?.accrued_type?.value]
                              : [],
                            ledger_id: value?.accrued_ledger
                              ? [value?.accrued_ledger?.value]
                              : [],
                            is_active: [true],
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualResponse
                        )
                      }
                      debounceTimeout={800}
                      isPaginate={true}
                      valueTooltip={true}
                      color={"#fff"}
                      key={JSON.stringify(value?.accrued_ledger)}
                    />
                  </Box>
                </Stack>
              </Box>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("Cost of Goods - Expense")}
                </Typography>
                <Stack direction={"row"} spacing={2}>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      value={value?.goodsType ?? ""}
                      color={"#fff"}
                      isReadOnly
                    />
                  </Box>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      value={value?.goodsLedger ?? ""}
                      placeholder={t("Select Main Ledger")}
                      onChange={(val) =>
                        updateState(
                          "account_pay",
                          "goodsLedger",
                          "goods",
                          value,
                          val
                        )
                      }
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "/com_ledger/listAll",
                          {
                            type: value?.goodsType
                              ? [value?.goodsType?.value]
                              : [],
                            is_active: [true],
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualLedgerResponse
                        )
                      }
                      debounceTimeout={800}
                      isPaginate={true}
                      color={"#fff"}
                      valueTooltip={true}
                    />
                  </Box>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      placeholder={t("Select Account")}
                      onChange={(val) => {
                        updateState(
                          "account_pay",
                          "goods",
                          "goodsLedger",
                          value,
                          val
                        );
                      }}
                      value={value?.goods ?? ""}
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "/com_master/listAll",
                          {
                            type: value?.goodsType
                              ? [value?.goodsType?.value]
                              : [],
                            ledger_id: value?.goodsLedger
                              ? [value?.goodsLedger?.value]
                              : [],
                            is_active: [true],
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualResponse
                        )
                      }
                      debounceTimeout={800}
                      isPaginate={true}
                      valueTooltip={true}
                      color={"#fff"}
                      key={JSON.stringify(value?.goodsLedger)}
                    />
                  </Box>
                </Stack>
              </Box>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("Input Tax Credits - Assets")}
                </Typography>
                <Stack direction={"row"} spacing={2}>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      value={value?.creditsType ?? ""}
                      color={"#fff"}
                      isReadOnly
                    />
                  </Box>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      value={value?.creditsLedger ?? ""}
                      placeholder={t("Select Main Ledger")}
                      onChange={(val) =>
                        updateState(
                          "account_pay",
                          "creditsLedger",
                          "credits",
                          value,
                          val
                        )
                      }
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "/com_ledger/listAll",
                          {
                            type: value?.creditsType
                              ? [value?.creditsType?.value]
                              : [],
                            is_active: [true],
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualLedgerResponse
                        )
                      }
                      debounceTimeout={800}
                      isPaginate={true}
                      color={"#fff"}
                      valueTooltip={true}
                    />
                  </Box>
                  <Box className={classes.upfrontBox}>
                    <SelectBox
                      placeholder={t("Select Account")}
                      onChange={(val) => {
                        updateState(
                          "account_pay",
                          "credits",
                          "creditsLedger",
                          value,
                          val
                        );
                      }}
                      value={value?.credits ?? ""}
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "/com_master/listAll",
                          {
                            type: value?.creditsType
                              ? [value?.creditsType?.value]
                              : [],
                            ledger_id: value?.creditsLedger
                              ? [value?.creditsLedger?.value]
                              : [],
                            is_active: [true],
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualResponse
                        )
                      }
                      debounceTimeout={800}
                      isPaginate={true}
                      valueTooltip={true}
                      color={"#fff"}
                      key={JSON.stringify(value?.creditsLedger)}
                    />
                  </Box>
                </Stack>
              </Box>
            </Stack>
          )}
          {value?.is_active && type === "Reservation" && (
            <Stack direction="column" spacing={0.5}>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("Select Item Type")}
                </Typography>
                <Box style={{ width: "186px" }}>
                  <SelectBox
                    // onChange={(val) => onChangeValue('itemType', val)}
                    value={{
                      label: value?.itemType?.label,
                      value: value?.itemType?.value,
                    }}
                    color={"#fff"}
                    isReadOnly
                  />
                </Box>
              </Box>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("Select Category")}
                </Typography>
                <Box style={{ width: "186px" }}>
                  <SelectBox
                    placeholder={t("Select Category")}
                    onChange={(val) => {
                      setSettings({
                        ...data,
                        reservation_item: {
                          ...value,
                          itemCategory: val,
                          itemSubCategory: "",
                          itemMaster: "",
                          reservation_COA: "",
                        },
                      });
                    }}
                    value={value?.itemCategory ?? ""}
                    color={"#fff"}
                    loadOptions={(search, array, handleLoading) =>
                      loadOptionsApis(
                        "inspection_item_category",
                        {
                          item_type: ["Services"],
                        },
                        search,
                        array,
                        handleLoading,
                        "list"
                      )
                    }
                    debounceTimeout={800}
                    isPaginate={true}
                    menuPlacement="auto"
                  />
                </Box>
              </Box>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("Select Subcategory")}
                </Typography>
                <Box style={{ width: "186px" }}>
                  <SelectBox
                    placeholder={t("Select Subcategory")}
                    onChange={(val) => {
                      setSettings({
                        ...data,
                        reservation_item: {
                          ...value,
                          itemSubCategory: val,
                          itemMaster: "",
                          reservation_COA: "",
                        },
                      });
                    }}
                    value={value?.itemSubCategory ?? ""}
                    color={"#fff"}
                    loadOptions={(search, array, handleLoading) =>
                      loadOptionsApis(
                        "inspection_item_subcategory",
                        {
                          category_id: value?.itemCategory?.value,
                          is_active: [true],
                        },
                        search,
                        array,
                        handleLoading,
                        "list"
                      )
                    }
                    debounceTimeout={800}
                    isPaginate={true}
                    key={JSON.stringify(value?.itemSubCategory)}
                    menuPlacement="auto"
                  />
                </Box>
              </Box>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("Select Item")}
                </Typography>
                <Box style={{ width: "186px" }}>
                  <SelectBox
                    placeholder={t("Select Item")}
                    onChange={(val) => {
                      setSettings({
                        ...data,
                        reservation_item: {
                          ...value,
                          itemMaster: val,
                          reservation_COA: val?.revenue?.name ?? "",
                        },
                      });
                    }}
                    value={value?.itemMaster ?? ""}
                    color={"#fff"}
                    loadOptions={(search, array, handleLoading) =>
                      loadOptionsApis(
                        "free_text_invoice/get_free_text_item_types",
                        {
                          item_type: "Item Master",
                          item_master_type: value?.itemType?.value,
                          item_master_category: value?.itemCategory?.value,
                          item_master_sub_category:
                            value?.itemSubCategory?.value,
                          component_type: "item_based",
                        },
                        search,
                        array,
                        handleLoading,
                        "items",
                        {},
                        itemManualResponse
                      )
                    }
                    debounceTimeout={800}
                    isPaginate={true}
                    key={JSON.stringify(value?.itemMaster)}
                    menuPlacement="auto"
                  />
                </Box>
              </Box>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("COA Main Account - Revenue")}
                </Typography>
                <TextBox
                  color={"#fff"}
                  label={null}
                  value={textValue}
                  placeholder={t("COA Revenue Account")}
                  isReadonly={true}
                  valueTooltip={true}
                />
              </Box>
            </Stack>
          )}
          {value?.is_active && type === "Penalty" && (
            <Stack direction="column" spacing={0.5}>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("Select Item Type")}
                </Typography>
                <Box style={{ width: "186px" }}>
                  <SelectBox
                    // onChange={(val) => onChangeValue('itemType', val)}
                    value={{
                      label: value?.itemType?.label,
                      value: value?.itemType?.value,
                    }}
                    color={"#fff"}
                    isReadOnly
                  />
                </Box>
              </Box>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("Select Category")}
                </Typography>
                <Box style={{ width: "186px" }}>
                  <SelectBox
                    placeholder={t("Select Category")}
                    onChange={(val) => {
                      setSettings({
                        ...data,
                        penalty_item: {
                          ...value,
                          itemCategory: val,
                          itemSubCategory: "",
                          itemMaster: "",
                          penalty_COA: "",
                        },
                      });
                    }}
                    value={value?.itemCategory ?? ""}
                    color={"#fff"}
                    loadOptions={(search, array, handleLoading) =>
                      loadOptionsApis(
                        "inspection_item_category",
                        {
                          item_type: ["Services"],
                        },
                        search,
                        array,
                        handleLoading,
                        "list"
                      )
                    }
                    debounceTimeout={800}
                    isPaginate={true}
                    menuPlacement="auto"
                  />
                </Box>
              </Box>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("Select Subcategory")}
                </Typography>
                <Box style={{ width: "186px" }}>
                  <SelectBox
                    placeholder={t("Select Subcategory")}
                    onChange={(val) => {
                      setSettings({
                        ...data,
                        penalty_item: {
                          ...value,
                          itemSubCategory: val,
                          itemMaster: "",
                          penalty_COA: "",
                        },
                      });
                    }}
                    value={value?.itemSubCategory ?? ""}
                    color={"#fff"}
                    loadOptions={(search, array, handleLoading) =>
                      loadOptionsApis(
                        "inspection_item_subcategory",
                        {
                          category_id: value?.itemCategory?.value,
                          is_active: [true],
                        },
                        search,
                        array,
                        handleLoading,
                        "list"
                      )
                    }
                    debounceTimeout={800}
                    isPaginate={true}
                    key={JSON.stringify(value?.itemSubCategory)}
                    menuPlacement="auto"
                  />
                </Box>
              </Box>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("Select Item")}
                </Typography>
                <Box style={{ width: "186px" }}>
                  <SelectBox
                    placeholder={t("Select Item")}
                    onChange={(val) => {
                      setSettings({
                        ...data,
                        penalty_item: {
                          ...value,
                          itemMaster: val,
                          penalty_COA: val?.revenue?.name ?? "",
                        },
                      });
                    }}
                    value={value?.itemMaster ?? ""}
                    color={"#fff"}
                    loadOptions={(search, array, handleLoading) =>
                      loadOptionsApis(
                        "free_text_invoice/get_free_text_item_types",
                        {
                          item_type: "Item Master",
                          item_master_type: value?.itemType?.value,
                          item_master_category: value?.itemCategory?.value,
                          item_master_sub_category:
                            value?.itemSubCategory?.value,
                          component_type: "item_based",
                        },
                        search,
                        array,
                        handleLoading,
                        "items",
                        {},
                        itemManualResponse
                      )
                    }
                    debounceTimeout={800}
                    isPaginate={true}
                    key={JSON.stringify(value?.itemMaster)}
                    menuPlacement="auto"
                  />
                </Box>
              </Box>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("COA Main Account - Revenue")}
                </Typography>
                <TextBox
                  color={"#fff"}
                  label={null}
                  value={textValue}
                  placeholder={t("COA Revenue Account")}
                  isReadonly={true}
                  valueTooltip={true}
                />
              </Box>
            </Stack>
          )}
          {value?.is_active && type === "Upfront" && (
            <Stack direction="column" spacing={0.5}>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("Select Item Type")}
                </Typography>
                <Box style={{ width: "186px" }}>
                  <SelectBox
                    // onChange={(val) => onChangeValue('itemType', val)}
                    value={{
                      label: value?.itemType?.label,
                      value: value?.itemType?.value,
                    }}
                    color={"#fff"}
                    isReadOnly
                  />
                </Box>
              </Box>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("Select Category")}
                </Typography>
                <Box style={{ width: "186px" }}>
                  <SelectBox
                    placeholder={t("Select Category")}
                    onChange={(val) => {
                      setSettings({
                        ...data,
                        upfront_charge: {
                          ...value,
                          itemCategory: val,
                          itemSubCategory: "",
                          itemMaster: "",
                          upfront_COA: "",
                        },
                      });
                    }}
                    value={value?.itemCategory ?? ""}
                    color={"#fff"}
                    loadOptions={(search, array, handleLoading) =>
                      loadOptionsApis(
                        "inspection_item_category",
                        {
                          item_type: ["Services"],
                        },
                        search,
                        array,
                        handleLoading,
                        "list"
                      )
                    }
                    debounceTimeout={800}
                    isPaginate={true}
                    menuPlacement="auto"
                  />
                </Box>
              </Box>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("Select Subcategory")}
                </Typography>
                <Box style={{ width: "186px" }}>
                  <SelectBox
                    placeholder={t("Select Subcategory")}
                    onChange={(val) => {
                      setSettings({
                        ...data,
                        upfront_charge: {
                          ...value,
                          itemSubCategory: val,
                          itemMaster: "",
                          upfront_COA: "",
                        },
                      });
                    }}
                    value={value?.itemSubCategory ?? ""}
                    color={"#fff"}
                    loadOptions={(search, array, handleLoading) =>
                      loadOptionsApis(
                        "inspection_item_subcategory",
                        {
                          category_id: value?.itemCategory?.value,
                          is_active: [true],
                        },
                        search,
                        array,
                        handleLoading,
                        "list"
                      )
                    }
                    debounceTimeout={800}
                    isPaginate={true}
                    key={JSON.stringify(value?.itemSubCategory)}
                    menuPlacement="auto"
                  />
                </Box>
              </Box>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("Select Item")}
                </Typography>
                <Box style={{ width: "186px" }}>
                  <SelectBox
                    placeholder={t("Select Item")}
                    onChange={(val) => {
                      setSettings({
                        ...data,
                        upfront_charge: {
                          ...value,
                          itemMaster: val,
                          upfront_COA: val?.revenue?.name ?? "",
                        },
                      });
                    }}
                    value={value?.itemMaster ?? ""}
                    color={"#fff"}
                    loadOptions={(search, array, handleLoading) =>
                      loadOptionsApis(
                        "free_text_invoice/get_free_text_item_types",
                        {
                          item_type: "Item Master",
                          item_master_type: value?.itemType?.value,
                          item_master_category: value?.itemCategory?.value,
                          item_master_sub_category:
                            value?.itemSubCategory?.value,
                          component_type: "item_based",
                        },
                        search,
                        array,
                        handleLoading,
                        "items",
                        {},
                        itemManualResponse
                      )
                    }
                    debounceTimeout={800}
                    isPaginate={true}
                    key={JSON.stringify(value?.itemMaster)}
                    menuPlacement="auto"
                  />
                </Box>
              </Box>
              <Box display="flex" className={classes.dropBox}>
                <Typography className={classes.subTitle}>
                  {t("COA Main Account - Revenue")}
                </Typography>
                <TextBox
                  color={"#fff"}
                  label={null}
                  value={textValue}
                  placeholder={t("COA Revenue Account")}
                  isReadonly={true}
                  valueTooltip={true}
                />
              </Box>
            </Stack>
          )}
          {render()}
        </Box>
        <Box>
          {type === "select" && (
            <Box width={selectBoxWidth ? selectBoxWidth : "250px"}>
              <SelectBox
                onChange={(value) => onChangeValue("value", value)}
                value={value?.value}
                color="white"
                loading={loading === "currency"}
                loadOptions={(search, array, handleLoading) =>
                  loadOptionData(search, array, handleLoading)
                }
                isPaginate
                debounceTimeout={800}
              />
            </Box>
          )}
          {type === "onboardselect" && (
            <Box width={selectBoxWidth ? selectBoxWidth : "250px"}>
              <SelectBox
                onChange={(value) => onChangeValue("value", value)}
                value={value?.value}
                color="white"
                options={onboardSelectOptions}
              />
            </Box>
          )}
          {type === "currency_select" && (
            <Box width="250px">
              <SelectBox
                onChange={(value) => onChangeValue("value", value)}
                value={value?.value}
                color="white"
                loading={loading}
                loadOptions={loadOptionData}
                isPaginate
                debounceTimeout={800}
              />
            </Box>
          )}
          {type === "email" && (
            <Box width="350px">
              <TextBox
                label={null}
                onChange={(e) => onChangeValue("value", e.target.value)}
                value={value?.value ?? value ?? ""}
                placeholder="Enter Finance Email"
                type="email"
              />
            </Box>
          )}
        </Box>
      </Box>
      {isError && (
        <Typography variant={"caption"} color={"error"}>
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};
