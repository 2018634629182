import { Box, Grid, Typography } from '@mui/material';
import styled from '@mui/material/styles/styled';
import React, { useContext, useEffect, useState } from 'react';
import { SelectBox, TextBox, ToggleButtonComponent } from '../../../components';
import { AlertContext } from '../../../contexts';
import { AlertProps,  enum_types, FontFamilySwitch, wrapLabelsInT } from '../../../utils';
import { loadOptionsApis } from '../../../utils/asyncPaginateLoadOptions';
import { useStylesCreation } from '../../propertcreation/createproperty/style';
import { networkCallback } from '../utils';
const CustomTypography = styled(Typography)(({ theme }) => ({
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.secondary,
    marginBottom: theme.spacing(1),
    textAlign: "start !important"
}));

const buttonStyle = {
    fontSize:"0.875rem",
    height: "45px",
    minWidth: "45px",
    borderColor: "#E4E8EE",
    borderRadius: 1,
    backgroundColor: "#fff",
}

const textBoxStyle = {
    // padding: "8px 14px",
    // borderRadius: 8,
    // height: 44
}

export const BankingDetails = (props) => {

    const {
        data = {},
        updateState = null,
        error = {},
        t
    } = props;

    const classes = useStylesCreation();
    const alert = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const [bankAccountTypeOptions, setBankAccountTypeOptions] = useState([])

    useEffect(() => {
        getBankAccountType()
        // eslint-disable-next-line
    }, [])

    const getBankAccountType = async () => {
        let result = await networkCallback({ enumName: ["bank_account_type"] }, "enum");
        if (!result) {
            return []
        }
        setBankAccountTypeOptions(result?.bank_account_type ?? []);
    }
    const manualResponse = (array) => {
        return array?.currency_master?.map((i) => {
          return {
              ...i,
              label: i?.label,
              value: i?.value
          }
      })
      };
    const loadOptionData = async (search, array,handleLoading, type) => {
        setLoading(type);
        let result;
        let enumTypes = [enum_types.bank_routing_type]

        if (type === "currency") {
            return loadOptionsApis(
                "queries/currency_master/list",
                {},
                search,
                array,
                handleLoading,
                "data",
                {},
                manualResponse
              )
        }
        if (type === "country") {
            return loadOptionsApis(
                "queries/opportunity/country_master",
                {
                  "region_id": [],
                },
                search,
                array,
                handleLoading,
                "data",
                {},
              )
        }
        else if (enumTypes?.includes(type)) {
            result = await networkCallback({ enumName: [type] }, "enum", setLoading);
            if (!result) {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something went wrong"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                return {
                    options: [],
                    hasMore: false
                }
            }
            return {
                options: result?.[type] ?? [],
                hasMore: false
            }
        }
        else {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const {
        bankingBasicDetails = null,
    } = data;
    return (
        <div>
            <Box className={classes.imagebox1}>
                <CustomTypography>{t("Banking Basic Details")}</CustomTypography>
                <Grid container rowSpacing={1} columnSpacing={3}>
                    <Grid item xs={12} sm={4} md={3} lg={2.7}>
                        <ToggleButtonComponent
                            label={t("Account Type")}
                            options={wrapLabelsInT(bankAccountTypeOptions, t)}
                            value={bankingBasicDetails?.bankAccountType ?? []}
                            onChange={(value) => updateState('bankingBasicDetails', 'bankAccountType', value)}
                            isMulti={false}
                            fullWidth={false}
                            buttonStyle={buttonStyle}
                            isError={error?.bankingBasicDetails?.bankAccountType?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.bankAccountType?.errorMsg ?? ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <TextBox
                            {...textBoxStyle}
                            label={t("Account Number")}
                            placeholder={t("Enter Account Number")}
                            value={bankingBasicDetails?.accountNumber ?? ""}
                            onChange={(e) => updateState('bankingBasicDetails', "accountNumber", e.target.value)}
                            isError={error?.bankingBasicDetails?.accountNumber?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.accountNumber?.errorMsg}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <TextBox
                            {...textBoxStyle}
                            label={t("Bank Name")}
                            placeholder={t("Enter Bank Name")}
                            value={bankingBasicDetails?.bankName ?? ""}
                            onChange={(e) => updateState('bankingBasicDetails', "bankName", e.target.value)}
                            isError={error?.bankingBasicDetails?.bankName?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.bankName?.errorMsg}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <TextBox
                            {...textBoxStyle}
                            label={t("Branch Name")}
                            placeholder={t("Enter Branch Name")}
                            value={bankingBasicDetails?.branchName ?? ""}
                            onChange={(e) => updateState('bankingBasicDetails', "branchName", e.target.value)}
                            isError={error?.bankingBasicDetails?.branchName?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.branchName?.errorMsg}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <TextBox
                            {...textBoxStyle}
                            label={t("Address 1")}
                            placeholder={t("Enter Address 1")}
                            value={bankingBasicDetails?.addressLineOne ?? ""}
                            onChange={(e) => updateState('bankingBasicDetails', "addressLineOne", e.target.value)}
                            isError={error?.bankingBasicDetails?.addressLineOne?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.addressLineOne?.errorMsg}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2.7}>
                        <TextBox
                            {...textBoxStyle}
                            // isrequired
                            label={t("Address 2")}
                            placeholder={t("Enter Address 2")}
                            value={bankingBasicDetails?.addressLineTwo ?? ""}
                            onChange={(e) => updateState('bankingBasicDetails', "addressLineTwo", e.target.value)}
                            isError={error?.bankingBasicDetails?.addressLineTwo?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.addressLineTwo?.errorMsg}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <TextBox
                            {...textBoxStyle}
                            label={t("City")}
                            placeholder={t("Enter City")}
                            options={[]}
                            value={bankingBasicDetails?.city ?? null}
                            onChange={(e) => updateState('bankingBasicDetails', 'city', e.target.value)}
                            isError={error?.bankingBasicDetails?.city?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.city?.errorMsg ?? ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <SelectBox
                            label={t("Country")}
                            placeholder={t("Select Country")}
                            loadOptions={(search, array,handleLoading) => loadOptionData(search, array,handleLoading, "country")}
                            isPaginate
                            debounceTimeout={800}
                            value={bankingBasicDetails?.country ?? null}
                            onChange={(value) => updateState('bankingBasicDetails', 'country', value)}
                            isError={error?.bankingBasicDetails?.country?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.country?.errorMsg ?? ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <SelectBox
                            label={t("Bank Routing Type")}
                            placeholder={t("Select Bank Routing Type")}
                            // options={[]}
                            loadOptions={(search, array,handleLoading) => loadOptionData(search, array,handleLoading, enum_types.bank_routing_type)}
                            isPaginate
                            debounceTimeout={800}
                            loading={loading === enum_types.bank_routing_type}
                            isSearchable={false}
                            value={bankingBasicDetails?.bankRoutingType ?? null}
                            onChange={(value) => updateState('bankingBasicDetails', 'bankRoutingType', value)}
                            isError={error?.bankingBasicDetails?.bankRoutingType?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.bankRoutingType?.errorMsg ?? ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <TextBox
                            {...textBoxStyle}
                            label={t("Bank Routing Code")}
                            placeholder={t("Enter Bank Routing Code")}
                            value={bankingBasicDetails?.bankRoutingCode ?? ""}
                            onChange={(e) => updateState('bankingBasicDetails', "bankRoutingCode", e.target.value)}
                            isError={error?.bankingBasicDetails?.bankRoutingCode?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.bankRoutingCode?.errorMsg}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4} md={3} lg={2.7}>
                        <SelectBox
                            label={t("Account Currency")}
                            placeholder={t("Select Currency")}
                            loadOptions={(search, array,handleLoading) => loadOptionData(search, array,handleLoading, "currency")}
                            isPaginate
                            debounceTimeout={800}
                            value={bankingBasicDetails?.currency ?? null}
                            onChange={(value) => updateState('bankingBasicDetails', 'currency', value)}
                            isError={error?.bankingBasicDetails?.currency?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.currency?.errorMsg ?? ''}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <TextBox
                            {...textBoxStyle}
                            label={t("Preferred Cash Collection Office")}
                            placeholder={t("Enter Preferred Cash Collection Office")}
                            value={bankingBasicDetails?.preferredCashCollectionOffice ?? ""}
                            onChange={(e) => updateState('bankingBasicDetails', "preferredCashCollectionOffice", e.target.value)}
                            isError={error?.bankingBasicDetails?.preferredCashCollectionOffice?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.preferredCashCollectionOffice?.errorMsg}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <TextBox
                            {...textBoxStyle}
                            label={t("Cheque Payment beneficiary Name")}
                            placeholder={t("Enter Cheque Name")}
                            value={bankingBasicDetails?.chequeName ?? ""}
                            onChange={(e) => updateState('bankingBasicDetails', "chequeName", e.target.value)}
                            isError={error?.bankingBasicDetails?.chequeName?.error ?? false}
                            errorMessage={error?.bankingBasicDetails?.chequeName?.errorMsg}
                        />
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}