import makeStyles from "@mui/styles/makeStyles";
export const quotationsStyles = makeStyles((theme) => ({
    table: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        //height: `calc(100vh - 147px)`,
        overflow: "hidden",

    },
    img: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "12px"
    },
    filterButton: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "12px",
        height: '40px'
    },

    tray: {
        zIndex: 1000,
        borderTop: "1px solid #ddd",
        width: "330px",
        minHeight: "100px",
        maxHeight: "350px",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(2),
      },
      list: {
        maxHeight: "200px",
        overflowY: "auto",
        width: "100%",
      },
      listItem: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      },
      fileName: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "300px",
      },
      disabledFileName: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "300px",
      },
      buttonWrapper: {
        position: "relative",
        width: "60%",
      },
      buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
      },
      fileProgress: {
        marginLeft: theme.spacing(1),
      },
      button: {
        width: "100%",
      },
    
    
}));