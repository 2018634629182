import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import { LoadingSection, Subheader, UseDebounce } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods, getCompanyOption } from "../../utils";
import { SettingList } from "../companyCreation/components";
import DomesticContactsTable from "./components/table";
import { DomesticContactListStyles } from "./styles";
import { withTranslation } from "react-i18next";
const DomesticContacts = ({ t }) => {
  const classes = DomesticContactListStyles();
  const [selectedCompany, setSelectedCompany] = React.useState({});
  const [companyList, setCompanyList] = React.useState([]);
  const backdrop = React.useContext(BackdropContext);
  const auth = React.useContext(AuthContext);
  const [selected, setSelected] = React.useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [propertySearchText, setPropertySearchText] = useState("");
  const [propertyList, setPropertyList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [loading, setLoading] = useState(false);
  const alert = React.useContext(AlertContext);
  const debounce = UseDebounce();
  const [totalRowsCount, setTotalRowsCount] = useState("");
  const offSet = 0;
  //get Property List
  const getPropertyList = (
    company_id,
    search,
    searchBool,
    offset = offSet,
    bool = false
  ) => {
    const payload = {
      company_id: company_id,
      search: search,
      limit: 20,
      offset: offset,
    };

    NetworkCall(
      `${config.api_url}/queries/property/list`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setLoading(false)
        if (!bool) {
          setPropertyList(response?.data?.data?.property);
        } else {
          setPropertyList([...propertyList, ...response?.data?.data?.property]);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  //get Contact List
  const getContactList = (
    company_id,
    search,
    PropertyId,
    offset = 0,
    limit = 10
  ) => {
    const payload = {
      company_id: company_id,
      search: search,
      offset: offset,
      limit: limit,
      property_id: PropertyId,
    };

    NetworkCall(
      `${config.api_url}/queries/contact/community_contacts/list`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        // setLoading(false)
        setContactList(
          response?.data?.data?.community_contacts?.map((val) => {
            return {
              ...val,
              postedBy: val?.posted_by?.first_name,
              profession: val?.profession?.name,
              contact_number: val?.country_code?.concat(
                " " + val?.contact_number
              ),
              unit_name: val?.unit?.name,
              private: val?.private ? "No" : "Yes",
              unitNo: val?.unit?.unit_no,
            };
          })
        );
        setTotalRowsCount(response?.data?.community_contacts.length);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  //initial load
  React.useEffect(() => {
    let company = getCompanyOption(backdrop, auth, alert);
    if (company) {
      setCompanyList(company?.list);
      setSelectedCompany(company?.selected);
      getPropertyList(company?.selected?.value, "", true);
    }
    // eslint-disable-next-line
  }, [auth]);
  //contacts list Load
  React.useEffect(() => {
    if (selected) {
      getContactList(selectedCompany?.value, "", selected?.id, 0, limit);
    }
    // eslint-disable-next-line
  }, [selected]);
  //company handle change
  const handleCompanyChange = (value) => {
    setSelectedCompany(value);
    getPropertyList(value?.value, "", true);
    // getContactList(value?.value, "", selected?.id, 0, 10)
  };

  //handle Pagination
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getContactList(selectedCompany?.value, "", selected?.id, offset, limit);
  };
  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getContactList(selectedCompany?.value, "", selected?.id, 0, limit);
  };
  //on search
  const handleSearch = (e) => {
    setSearchText(e);
    debounce(() => searchTableFunction(e), 800);
  };
  //search function
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getContactList(selectedCompany?.value, e, selected?.id, 0, 10);
  };
  //property Search
  const handlePropertySearch = (value) => {
    setPropertySearchText(value);
    debounce(() => getPropertyList(selectedCompany?.value, value), 800);
  };
  return (
    <Box>
      <Subheader
        title={t("Public Contacts Directory")}
        hideBackButton={true}
        select
        value={selectedCompany}
        options={companyList}
        onchange={(e) => {
          handleCompanyChange(e);
        }}
      />
      {loading ? (
        <LoadingSection top="20vh" message={t("Fetching Details")} />
      ) : (
        <Grid container spacing={2} p={2} marginTop={"-20px"}>
          <Grid item xs={3}>
            <Box className={classes.propertyList}>
              <SettingList
                isSearch={true}
                title={t("Properties")}
                list={propertyList}
                selected={selected?.id}
                setSelected={setSelected}
                name={t("searchProperties")}
                handleSearch={handlePropertySearch}
                searchText={propertySearchText}
                styles={true}
                contactSetting
                t={t}
              />
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Box className={classes.contactList}>
              <DomesticContactsTable
                handlePagination={handlePagination}
                handleChangeLimit={handleChangeLimit}
                handleSearch={handleSearch}
                page={page}
                limit={limit}
                searchText={searchText}
                list={contactList}
                totalRowsCount={totalRowsCount}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
export default withTranslation("domesticContacts")(DomesticContacts);
