import { Dialog, Grid, IconButton, Stack, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from "../style"
import { Area } from "./area"
import { Bed } from "./bed"
import { Bath } from "./bath"
import { UnitType } from "./unitType"

export const UnitView = (props) => {

    const { t = () => false } = (props)

    const classes = useStyles(props)
    const unitView = props?.requestState?.unitView
    // const total = (price) => {
    //     let _total = 0
    //     for (let i = 0; i < price?.length; i++) {
    //         if (price[i]?.applied_value) {
    //             _total = _total + price[i]?.applied_value
    //         }
    //         if (price[i]?.appliedTaxAmount) {
    //             _total = _total + price[i]?.appliedTaxAmount
    //         }
    //     }
    //     return _total
    // }

    return <Dialog
        className={classes.unitViewDialog}
        open={props?.unitViewDialogOpen}
        onClose={() => props?.setUnitViewDialogOpen(false)}>
        <div className={classes.viewDialogHeader}>
            <Typography className={classes.viewDialogHeaderTitle}>
                {t("Unit View")}
            </Typography>
            <IconButton onClick={() => props?.setUnitViewDialogOpen(false)}
                className={classes.viewDialogCloseButton}>
                <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
            </IconButton>
        </div>
        <Grid container>
            <Grid item xs={6} padding={"16px"}>
                <Grid container paddingBottom={"12px"}>
                    <Typography className={classes.viewDialogText6}>
                        {unitView?.name}
                    </Typography>
                </Grid>
                <Stack direction={"row"} justifyContent={"start"}
                    alignItems={"center"} padding={"4px 0px"}
                    divider={<div style={{
                        backgroundColor: "#CED3DD", margin: "0px 8px",
                        borderRadius: "50%", height: "6px", width: "6px"
                    }} />}>
                    <Typography className={classes.viewDialogText7}>
                        {unitView?.property?.property_name ?? ""}
                    </Typography>
                    <Stack direction={"row"} justifyContent={"center"}
                        alignItems={"center"}>
                        <Area />
                        <Typography className={classes.viewDialogText7}>
                            {unitView?.totalarea ?
                                (unitView?.totalarea +
                                    (unitView?.areaMetric ?? "")) : ""}
                        </Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"center"}
                        alignItems={"center"}>
                        <Bed />
                        <Typography className={classes.viewDialogText7}>
                            {unitView?.totalbedroom}
                        </Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"center"}
                        alignItems={"center"}>
                        <Bath />
                        <Typography className={classes.viewDialogText7}>
                            {unitView?.baths}
                        </Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"center"}
                        alignItems={"center"}>
                        <UnitType />
                        <Typography className={classes.viewDialogText7}>
                            {unitView?.unitType}
                        </Typography>
                    </Stack>
                </Stack>
                <Grid container className={classes.viewDialogContactDivider} />
            </Grid>
            <Grid item xs={6} padding={"16px"}>
                <div className={classes.pricingDetailsDiv}>
                    <Grid container paddingBottom={"12px"}>
                        <Typography className={classes.viewDialogText6}>
                            {t("PRICING DETAILS")}
                        </Typography >
                    </Grid >
                    <Grid container className={classes.agreementSummary}>
                        <Grid container alignContent={"start"} className={classes.priceDetailsBody}>
                            {unitView?.pricing
                                ?.filter((val) => {
                                    if (props?.requestState?.revised_auto_renewal_escalation?.value === "Agreement Price") {
                                        return !val?.is_wallet && !val?.is_late_fee && !val?.is_wallet_item && !val?.is_commission_payment && !val?.isQuantity;
                                    } else if (props?.requestState?.revised_auto_renewal_escalation?.value === "Latest Price") {
                                        return !val?.pricing_component?.[0]?.is_wallet && !val?.pricing_component?.[0]?.is_late_fee && !val?.is_wallet_item && !val?.pricing_component?.[0]?.is_commission_payment && !val?.isQuantity;
                                    } else {
                                        return !val?.is_wallet && !val?.is_late_fee && !val?.is_wallet_item && !val?.is_commission_payment && !val?.isQuantity;
                                    }
                                })?.map((_) => {
                                    return <>
                                        <Grid item xs={6}>
                                            <Typography className={classes.priceDescriptionText}>
                                                {_?.PCname}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography textAlign={"right"} className={classes.priceTotalText}>
                                                {(props?.requestState?.currency ? (props?.requestState?.currency + " ") : "") +
                                                    (_?.componentvalue?.toFixed(2) ?? "0.00")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography className={classes.viewDialogText7}>
                                                {t("Tax Amount")}
                                            </Typography >
                                        </Grid >
                                        <Grid item xs={6}>
                                            <Typography textAlign={"right"} className={classes.viewDialogText7}>
                                                {(props?.requestState?.currency ? (props?.requestState?.currency + " ") : "") +
                                                    (_?.tax?.toFixed(2) ?? "0.00")}
                                            </Typography>
                                        </Grid>
                                    </>
                                })}
                        </Grid >
                        <Grid container className={classes.priceTotal}>
                            <Grid item xs={6}>
                                <Typography className={classes.priceTotalText}>
                                    {t("Total")}
                                </Typography >
                            </Grid >
                            <Grid item xs={6}>
                                {(props?.requestState?.revised_auto_renewal_escalation?.value === "Agreement Price") ? (
                                    <Typography textAlign={"right"} className={classes.priceTotalText}>
                                        {(props?.requestState?.currency ? (props?.requestState?.currency + " ") : "") +
                                            (((props?.requestState?.unitPrice?.totalAmount ?? 0.00) -
                                                (props?.requestState?.unitPrice?.totalrefundableTax ?? 0.00) +
                                                (props?.requestState?.unitPrice?.totalTax ?? 0.00))?.toFixed(2) ?? "0.00")}
                                    </Typography>
                                ) : (props?.requestState?.revised_auto_renewal_escalation?.value === "Latest Price") ? (
                                    <Typography textAlign={"right"} className={classes.priceTotalText}>
                                        {(props?.requestState?.currency ? (props?.requestState?.currency + " ") : "") +
                                            (((props?.requestState?.unitPrice?.totalAmount ?? 0.00) -
                                                (props?.requestState?.unitPrice?.totalrefundableTax ?? 0.00) +
                                                (props?.requestState?.unitPrice?.totalTax ?? 0.00))?.toFixed(2) ?? "0.00")}
                                    </Typography>
                                ) : (
                                    <Typography textAlign={"right"} className={classes.priceTotalText}>
                                        {(props?.requestState?.currency ? (props?.requestState?.currency + " ") : "") +
                                            (((props?.requestState?.unitPrice?.totalAmount ?? 0.00) -
                                                (props?.requestState?.unitPrice?.totalrefundableTax ?? 0.00) +
                                                (props?.requestState?.unitPrice?.totalTax ?? 0.00))?.toFixed(2) ?? "0.00")}
                                    </Typography>
                                )
                                }
                            </Grid>
                        </Grid >
                    </Grid >
                </div >
            </Grid >
        </Grid >
    </Dialog >
}