import makeStyles from "@mui/styles/makeStyles";
import { remCalc, FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    imageViewer: {
        '& .styles-module_close__2I1sI': {
            color: 'white',
            position: 'absolute',
            top: '67px',
            right: '100px',
            fontSize: '40px',
            fontWeight: 'bold',
            opacity: 0.2,
            cursor: 'pointer',
        }
    },
    link: {
        fontSize: remCalc(16),
        color: "#091B29",
        fontFamily: FontFamilySwitch().extraBold,
    },
    add_icon: {
        border: "1px solid #091B29",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
    },
    link_account_profile_image: {
        height: "40px",
        width: "40px",
    },
    link_account_name: {
        fontSize: remCalc(14),
        color: "#091B29",
        fontFamily: FontFamilySwitch().extraBold,
    },
    link_account_email_id: {
        fontSize: remCalc(12),
        color: "#98A0AC",
        fontFamily: FontFamilySwitch().semiBold,
    },
    unlink_box: {
        cursor: "pointer",
        height: "40px",
        padding: "4px 8px",
        border: "1px solid #98A0AC",
        borderRadius: theme?.palette.borderRadius,
        display: "flex",
        alignItems: "center",
    },
    unlink_text: {
        fontSize: remCalc(12),
        color: "#98A0AC",
        fontFamily: FontFamilySwitch().extraBold,
    },
}));