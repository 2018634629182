import { Box } from "@mui/material"
import React from 'react'
import { useStyle } from "./style"
import { withTranslation } from "react-i18next";
import { Account, Contact, CreateOrder } from "./components";
import { timeZoneConverter } from "../../utils";



const details =({
    quote_details,
    data,
    t=()=>false
})=> {
    return [
        {
            name: t("Total Units"),
            details: `${data?.unit} ${data?.unit > 1 ? "Units" : "Unit"}`
        },
        {
            name: quote_details?.revenue_type === "Sale" ? t("Sale Period") : (quote_details?.revenue_type === "Manage" ? t("Manage Period") : t("Lease Period")),
            details:`${timeZoneConverter(new Date(quote_details?.lease_start_date))} - ${timeZoneConverter(new Date(quote_details?.lease_end_date))}`
        },
        {
            name: t("Property Source"),
            details: quote_details?.lead_source ?? "",
            is_tag: true
        },
    ]
}

const details1 = ({
    quote_details,
    t=()=>false

})=>{
    return [
        {
            name: t("Quotation Start & End"),
            details:`${timeZoneConverter(quote_details?.lease_start_date)} - ${timeZoneConverter(quote_details?.lease_end_date)} `
        },
        {
            name: t("Opportunity Number"),
            details: quote_details?.lead_no
        },
    ]
}

const QuotationConformComponent = ({
    openDrawer = ()=>false,
    t = () => false,
    data={},
    conform=false,
    disable=false,
    onChangeStep=()=>false,
    toggleDrawer=()=>false,
    agreementCreation=()=>false,
    disableAcc=false,
    contactID="",
    images=[],
    setImages=() =>false,
    validateAgreement=()=>false,
    disableBTN,
    quotation_id = quotation_id
}) => {
    //    style
    const classes = useStyle();

    const [state, setState] = React.useState({
        contactType:true,
        step:0,
        accountType:true,
        is_accept:false,
        reference:"",
        order_date:"",
        order_value:"",
        error:{
            reference:"",
            order_date:"",
            order_value:"",
            contactType:"",
            step:"",
            accountType:"",
            is_accept:"",
        }
    })
    
    const updateState = (k, v) => {
        let error = state?.error;
        error[k] = "";
        setState({ ...state, [k]: v })
    }

    const components={
        0:<CreateOrder disableBTN={disableBTN} t={t} state={state} updateState={updateState} quotation_id={quotation_id}  details1={details1({quote_details:data?.quote_details , t})} details={details({quote_details:data?.quote_details , data , t})} data={{...data , quote_details:data?.quote_details}} disable={disable} onChangeStep={onChangeStep} toggleDrawer={toggleDrawer} openDrawer={openDrawer} agreementCreation={agreementCreation} disableAcc={disableAcc} contactID={contactID} images={images} setImages={setImages} validateAgreement={validateAgreement}/>,
        1: <Contact  t={t} data={state} updateState={updateState}/>,
        2: <Account  t={t} data={state} updateState={updateState}/>

    }


    return (
        <Box className={classes?.drawerBox}>
        {components[state?.step]}
    </Box>
    )
}
export default withTranslation("quotationsList")(QuotationConformComponent)
