import {
    Box, Divider, Grid,
    Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CarouselSliders } from '../../components';
import { Routes } from '../../router/routes';
import { FontFamilySwitch } from '../../utils';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: "12px",
        backgroundColor: theme.palette.background.secondary,
        height: `calc(100vh - 88px)`,
        overflow: "auto"
    },

    btn: {
        borderRadius: theme.palette.borderRadius,
    },
    btn2: {
        borderRadius: theme.palette.borderRadius,
        color: theme.palette.secondary.main,
    },
    text: {
        fontSize:"1rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
    },
    subText: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary
    },
    bottomTitle: {
        fontSize:"1.125rem",
        fontFamily: FontFamilySwitch().extraBold,

    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
        marginBottom: "12px"
    },
    contentBottom: {

    },
    drawer: {
        "& .MuiDrawer-paper": {

            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
        },
        "& .MuiContainer-root": {
            padding: "0px"
        },
    },
    grid: {
        backgroundColor: theme.palette.background.secondary,
        borderRadius: theme.palette.borderRadius,
        padding: "4px"
    },
    title: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary

    },
    subTitle: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.secondary
    },
    box: {
        borderRadius: theme.palette.borderRadius,
        padding: "4px",
        backgroundColor: "white",
        border: "1px solid #E4E8EE"
    },
    area: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.tertiary
    },
    sqft: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
    },
    color: {
        backgroundColor: "#FFEACC",
        borderRadius: theme.palette.borderRadius,
        color: "#E29336",
        padding: "4px",
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,

    },
    color2: {
        backgroundColor: "#CFCFFF",
        borderRadius: theme.palette.borderRadius,
        color: "#6A69D2",
        padding: "4px",
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,

    },
    color3: {
        backgroundColor: "#E4E8EE",
        borderRadius: theme.palette.borderRadius,
        color: theme.typography.color.secondary,
        padding: "4px",
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        marginLeft: 6

    }
}))

export const Details = (props) => {

    const classes = useStyles();
    const navigate = useNavigate();

    const handleUnitEdit = () => {
        navigate(
            Routes.addUnit +
            "?companyId=" + props?.data?.property?.company?.id +
            "&propertyId=" + props?.data?.property?.id +
            "&blockId=" + props?.data?.block?.id +
            "&floorId=" + props?.data?.floor?.id +
            "&unitId=" + props?.data?.id +
            "&topNavBarTitle=" + props?.data?.name);
    };

    return <div className={classes.root}>
        <Grid container className={classes.content} spacing={1} textAlign={"left"}>
            <Grid item xs={12}>
                {props?.assets?.length > 0 ? (
                    <>
                        <CarouselSliders data={props.assets} assets={true} />
                    </>
                ) : (
                    <>
                        <img
                            src="/images/propertytumb.png"
                            alt="img"
                            style={{
                                height: "100%",
                                width: "100%",
                            }}
                        />
                    </>
                )}            </Grid>
            {props.color &&
                <Grid item xs={12}>
                    <Box display={"flex"}>
                        <Box className={classes.color}>
                            {props?.data?.block?.block_no}
                        </Box>&nbsp;&nbsp;
                        {
                            props.data.floor &&
                            <Box className={classes.color2}>
                                {props?.data?.floor?.floor_no}
                            </Box>
                        }
                        {
                            props.data.unit_no &&
                            <Box className={classes.color3}>
                                {props?.data?.unit_no}
                            </Box>
                        }
                    </Box>

                </Grid>

            }
            <Grid item xs={8}>
                <Typography className={classes.text}>{props?.data?.name}</Typography>
            </Grid>
            <Grid item xs={4} textAlign={"right"} onClick={handleUnitEdit}>
                <img src='/images/Outline.svg' alt='outline' />
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.subText}>
                    {props?.data?.description}
                </Typography>
            </Grid>
            {props?.data?.contact?.longitude &&
                <Grid item xs={12}>
                    <Box display={"flex"}>
                        <Box >
                            <Typography className={classes.subText}>
                                Latitude :<span className={classes.sqft}>{props?.data?.contact?.latitude}</span>
                            </Typography>
                        </Box>&nbsp;&nbsp;
                        <Box >
                            <Typography className={classes.subText}>
                                Longitude :<span className={classes.sqft}>{props?.data?.contact?.longitude}</span>
                            </Typography>
                        </Box>
                    </Box>
                </Grid>

            }
            {
                props?.unitTable &&
                <>
                    <Grid item xs={12}>
                        <Box
                            display={"flex"}
                        >
                            <Box className={classes.box} display="flex" alignItems={"center"}>
                                <div><img src='images/Furnished.svg' alt="furished" /> &nbsp;</div>
                                <Typography className={classes.sqft}>
                                    {props?.data?.furnishing}
                                </Typography>
                            </Box>&nbsp;&nbsp;&nbsp;
                            <Box className={classes.box} display="flex" alignItems={"center"}>
                                <div><img src='images/Occupied.svg' alt="furished" /> &nbsp;</div>
                                <Typography className={classes.sqft}>
                                    {props?.data?.unit_status_master?.type}
                                </Typography>
                            </Box>&nbsp;&nbsp;&nbsp;
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.text}>{props?.data?.floor?.name}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.subText}>
                            {props?.data?.floor?.description}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.text}>{props?.data?.block?.name}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.subText}>
                            {props?.data?.block?.description}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.text}>{props?.data?.property?.name}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.subText}>
                            {props?.data?.property?.description}
                        </Typography>
                    </Grid>

                </>
            }
            {
                props.data.leaseableArea &&


                <Grid item xs={12}>
                    <Box
                        display={"flex"}
                    >
                        <Box className={classes.box}>
                            <Typography className={classes.area}>
                                LEASABLE AREA
                            </Typography>
                            <Typography className={classes.sqft}>
                                {props.data.leaseableArea}
                            </Typography>
                        </Box>&nbsp;&nbsp;&nbsp;
                        <Box className={classes.box}>
                            <Typography className={classes.area}>
                                TOTAL AREA
                            </Typography>
                            <Typography className={classes.sqft}>
                                {props.data.totalArea}
                            </Typography>
                        </Box>&nbsp;&nbsp;&nbsp;
                        <Box className={classes.box}>
                            <Typography className={classes.area}>
                                UNITS
                            </Typography>
                            <Typography className={classes.sqft}>
                                {props.data.units}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            }
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Box display={"flex"}>
                    <Box>
                        <img src='/images/loc.svg' alt='loc' />
                    </Box>
                    <Box>
                        <Typography className={classes.subTitle}>
                            Address
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                {props?.data?.property?.address === null ? " - " :
                    <Typography className={classes.subText}>
                        {props?.data?.property?.address?.street_1}{", "}
                        {props?.data?.property?.address?.street_2}{", "}
                        {props?.data?.property?.address?.street_3}{", "}
                        {props?.data?.property?.address?.district}{", "}
                        {props?.data?.property?.address?.state}{", "}
                        {props?.data?.property?.address?.country}{", "}
                        {props?.data?.property?.address?.zipcode}{", "}

                    </Typography>
                }
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Box display={"flex"}>
                    <Box>
                        <img src='/images/call.svg' alt='loc' />
                    </Box>
                    <Box>
                        <Typography className={classes.subTitle}>
                            CONTACT
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Typography className={classes.area}>
                    BUSINESS PHONE
                </Typography>
                <Typography className={classes.sqft}>
                    {props?.data?.property?.business_phone === null ? "-" : props?.data?.property?.business_phone}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography className={classes.area}>
                    MOBILE PHONE
                </Typography>
                <Typography className={classes.sqft}>
                    {props?.data?.property?.mobile_phone === null ? "-" : props?.data?.property?.mobile_phone}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.area}>
                    WEBSITE
                </Typography>
                <Typography className={classes.sqft}>
                    {props?.data?.property?.website === null ? "-" : props?.data?.property?.website}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.area}>
                    EMAIL Address
                </Typography>
                <Typography className={classes.sqft}>
                    {props?.data?.property?.email === null ? "-" : props?.data?.property?.email}
                </Typography>
            </Grid>

        </Grid>
    </div>
}