import React from "react";
import { withNavBars } from "../../HOCs";
import AccountDetails from "./accountDetails";

class AccountDetailsParent extends React.Component {
    render() {
        const { reltype } = this.props;
        return <AccountDetails reltype={reltype} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(AccountDetailsParent, props);
