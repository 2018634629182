import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../../utils";

export const useStyle = makeStyles((theme) => ({
    drawerBox: {
        width: '456px',
        position: "relative"
    },
    header: {
        width: "100%"
    },
    body: {
        height: "calc(100vh - 191px)",
        overflow: "auto"
    },
    drawerCloseBtn: {
        fontSize:"1rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
        background: theme.palette.background.tertiary,
        borderRadius: 4,
        height: 32,
        width: 32,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    drawerHeading: {
        fontSize:"1rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    previous: {
        border: `1px solid ${theme.palette.border.primary}`,
        background: "transparent",
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        padding: '11px 16px',
        lineHeight: '19px !important',
        "&:hover": {
            background: "transparent",
        },
        width:"100%"

    },
    next: {
        background: theme.palette.border.primary_2,
        color: "#fff",
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        padding: '11px 16px',
        lineHeight: '19px !important',
        "&:hover": {
            background: theme.palette.border.primary_2,
        },
        width:"100%"
  
    },
    card:{
        borderRadius:'8px',
        border:'1px solid #E4E8EE',
        boxShadow:'0px 1px 12px #0000001A',
        backgroundColor:'#FFFFFF',
        padding:16
    },
    cardTitle:{
        color:"#091B29",
        fontSize:"1rem",
        fontFamily: FontFamilySwitch().semiBold,
    },
    cardSubTitle:{
        color:"#4E5A6B",
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
    },
    dot:{
        backgroundColor:"#4E5A6B",
        padding:"3px",
        borderRadius:'50%'
    },
    cardParent:{
        backgroundColor:'#F2F4F7',
        padding:'16px'
    },
    name:{
        color:"#4E5A6B",
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().regular,
    },
    details:{
        color:"#091B29",
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
    },
    orderTitle:{
        color:"#4E5A6B",
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        margin:"16px 0px"
    },
    acceptText:{
        color:"#4E5A6B",
        fontSize:"0.672rem",
        fontFamily: FontFamilySwitch().semiBold,
    },
    footerRoot:{
        borderTop:"1px solid #F2F4F7",
        padding:"16px"
    },
    contactTitle:{
        color:"#091B29",
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
    },
    createNew:{
        color:"#5078E1",
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
    },
    addContactParent:{
        height:'calc(100vh - 191px)',
        overflow:"auto"
    }
}))