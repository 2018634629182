import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        border: "2px solid white",
        backgroundColor: "white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        margin: "16px"
    },
    formTitle: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().extraBold,
    },
    formBlock: {
        backgroundColor: "#FFFFFF",
        border: "1px solid #E4E8EE",
        position: "relative",
        // height: "100vh"
    },
    componentBox: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        cursor: "pointer",
        "&:hover": {
            border: "1px solid #5078E1",
            borderRadius: theme.palette.borderRadius,
            backgroundColor: "#F1F7FF"
        }
    },
    formComponentTitle: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
        marginTop: "12px",
        textAlign: "center"


    },
    fixedBottom: {
        border: "1px solid #E4E8EE",
        display: "flex",
        justifyContent: "space-between",
        padding: "12px",
        bottom: 0,
        left: 0,
        right: 0,
        position: "absolute",
        [theme.breakpoints.down('md')]: {
            display: "block"
        }


        // marginTop: "80px",
    },
    nocomponentText: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.secondary
    },
    choosecomponentText: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().regular,
        color: theme.typography.color.secondary
    },
    nocomponentBox: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        // padding: "70px 0px",
        textAlign: "center",
        // [theme.breakpoints.down('md')]: {
        //     padding: "40px 0px",
        // }


    },
    chooseQuestions: {
        margin: "20px 40px",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "#FFFFFF"
    },
    dragIcon: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        padding: '1px',
        display: "flex",
        justifyContent: "center",
        cursor:"move"
    },
    customSelectBox: {
        padding: "2px",
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        width: "180px"
    },
    optionsText: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.primary,
        marginLeft: "5px"
    },
    questiontxt: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.tertiary,
    },
    rightBlock: {
        height: `calc(100vh - 150px)`,
        overflow: "scroll",
        position: "relative",
        [theme.breakpoints.down('md')]: {
            height: `calc(100vh - 150px)`,
        }


    },
    optiontitle: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.secondary,
        textTransform: "uppercase"
    },
    addAnother: {
        fontFamily: FontFamilySwitch().bold,
        fontSize:"0.75rem",
        color: "#5078E1",
        cursor: "pointer"
    },
    draftbtn: {
        "&:hover": { backgroundColor: "white" }
    },
    goBacktext: {
        fontSize:"1.125rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    goBackSubtxt: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().regular,
        color: theme.typography.color.secondary
    },
    viewQuestionhdr: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().semiBold,
        textTransform: "capitalize"
    },
    rating: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "3px",
        width: "32px",
        height: "32px"
    },
    selectedRating: {
        backgroundColor: "#F1F7FF",
        border: "1px solid #5078E1",
        borderRadius: theme.palette.borderRadius,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "3px",
        width: "32px",
        height: "32px"
    },
    bottomDiv: {
        padding: "16px",
        border: "1px solid #E4E8EE",
        // position:"absolute",
        // bottom:0,
        left: 0,
        right: 0
    },
    formCategory: {
        padding: "16px 16px 0px 16px"
    },
    question_bank: {
        padding: "8px 16px 0px 16px",
        position: "relative"
    },
    iconButton: {
        borderRadius: "4px",
        border: `1px solid ${theme.palette.border.primary}`,
        height: '40px',
        width: '40px'
    },
    responsiveBlock: {
        [theme.breakpoints.down('md')]: {
            height: `calc(100vh - 275px)`,
            overflow: "scroll"
        }
    },


}))