import React from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Stack,
  Pagination,
} from "@mui/material";
// import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useStyles } from "./styles";
import DoneIcon from "@mui/icons-material/Done";
import {
  AlertDialog,
  LoadingSection,
  SearchFilter,
  UseDebounce,
} from "../../../components";
// import InfiniteScroll from "react-infinite-scroll-component";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
import { AlertProps, NetWorkCallMethods } from "../../../utils";
// import FileViewer from "react-file-viewer";
import { DocumentViewer } from "../../../components/fileViewer";
import { Custom } from "../../../assets/custom";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../router/routes";
import { BackdropContext } from "../../../contexts";
import NoDataFound from "../../../assets/noData";
// import { NewLoader } from '../../../components/newLoader';
import { AlertContext } from "../../../contexts";

export const TemplateChoose = ({
  onSubmitSettings = () => false,
  selected = {},
  settings = {},
  t = () => false,
  isDisableBtn = false,
}) => {
  const classes = useStyles();
  const debounce = UseDebounce();
  const navigate = useNavigate();

  const [value, setValue] = React.useState({
    step: 1,
    bool: false,
    dialog: false,
    data: [],
    offset: 0,
    selected: {},
    count: 0,
  });
  const [list, setLise] = React.useState([]);
  const [index, setIndex] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [searchText, setSearchText] = React.useState("");
  const alert = React.useContext(AlertContext);

  const [page, setPage] = React.useState(1);

  const totalCount = value?.count > -1 ? value?.count : "";
  const backdrop = React.useContext(BackdropContext);

  const updateState = (k, v) => {
    setValue({ ...value, [k]: v });
  };

  const getData = (
    offset = 0,
    search = "",
    type = 1,
    filter = false,
    is_create
  ) => {
    let datas = {
      offset,
      limit: 10,
      search,
      type,
    };
    NetworkCall(
      `${config.api_url}/pdf-type-master/get_shortlist_templates`,
      NetWorkCallMethods.post,
      datas,
      null,
      true,
      false
    )
      .then((rs) => {
        setValue({
          ...value,
          data: rs?.data?.data,
          offset: offset,
          step: type,
          count: rs?.data?.count,
          dialog: false,
        });

        setLoading(false);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((er) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        console.log(er);
        // alert.setSnack({
        //     ...alert,
        //     open: true,
        //     severity: AlertProps.severity.success,
        //     msg: "onBoarding Deleted Successfully",
        //     vertical: AlertProps.vertical.top,
        //     horizontal: AlertProps.horizontal.center,
        // });
        setLoading(false);
      });
  };

  const getTypes = () => {
    let datas = {};
    NetworkCall(
      `${config.api_url}/pdf-type-master/get_types`,
      NetWorkCallMethods.post,
      datas,
      null,
      true,
      false
    )
      .then((rs) => {
        setLise(rs?.data);
        getData(0, "", rs?.data?.data?.[0]?.id);
      })
      .catch((er) => {
        console.log(er);
      });
  };

  const chooseTemplate = (v) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });

    let datas = {
      id: v?.id,
      is_default: !v?.is_default,
      is_type: true,
      pdf_type_id: v?.pdf_type_id,
    };

    NetworkCall(
      `${config.api_url}/pdf-type-master/shortlist_create`,
      NetWorkCallMethods.post,
      datas,
      null,
      true,
      false
    )
      .then((rs) => {
        setLoading(false);
        getData(0, "", value?.step);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Default Selected Sucessfully"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      })
      .catch((er) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Some Thing Went Wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        setLoading(false);
      });
  };

  const handleSearch = (v) => {
    setSearchText(v);
    debounce(() => getData(0, v, value?.step), 1000);
  };

  React.useEffect(() => {
    getTypes();
    // eslint-disable-next-line
  }, []);

  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * 10;
    getData(offset, "", value?.step, true);
  };

  return (
    <Box>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <Box
          style={{ padding: "15px 15px 0px" }}
          display="flex"
          alignItems="start"
          justifyContent="space-between"
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Box>
            <Typography className={classes.title_template}>
              {t("Templates")}
            </Typography>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  overflowX: "auto",
                  overflowY: "hidden",
                  marginTop: "16px",
                }}
              >
                {list?.map((x) => {
                  return (
                    <Box
                      onClick={() => {
                        setLoading(true);
                        getData(0, "", x?.id, true);
                      }}
                      style={{
                        textAlign: "center",
                        position: "relative",
                        paddingBottom: "4px",
                      }}
                    >
                      <Typography
                        noWrap
                        className={
                          x?.id === value?.step
                            ? classes.tabNameseleted
                            : classes.tabName
                        }
                        variant="span"
                      >
                        {x?.name}
                        {x?.id === value?.step && (
                          <Box className={classes.absolute} />
                        )}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
          <Button
            className={classes.explore}
            onClick={() => navigate(Routes.ExploreTemplate)}
            disabled={isDisableBtn}
          >
            {t("Explore Template")}
          </Button>
        </Box>

        {!value?.data?.length > 0 && !loading ? (
          <Box
            style={{
              height: "calc(100vh - 245px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NoDataFound />
          </Box>
        ) : (
          <div>
            <Box style={{ padding: "16px 16px 0px 16px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <SearchFilter
                    value={searchText}
                    placeholder={t("Search Template")}
                    handleChange={(value) => handleSearch(value)}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              style={{
                padding: "16px",
                height: `calc(100vh - 361px)`,
                overflow: "auto",
              }}
            >
              {loading ? (
                <LoadingSection bottom={"40vh"} message="Fetching ..." />
              ) : (
                <Grid container spacing={2}>
                  {value?.data?.map((x, i) => {
                    return (
                      <Grid item xs={12} sm={6} md={3} lg={2.4}>
                        <Box
                          className={classes.container}
                          onMouseEnter={() => {
                            setIndex(i);
                            updateState("bool", true);
                          }}
                          onMouseLeave={() => {
                            setIndex(null);
                            updateState("bool", false);
                          }}
                        >
                          <Stack
                            direction="row"
                            spacing={2}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            className={classes.selectBoxParent}
                          >
                            {x?.is_default ? (
                              <Stack
                                direction="row"
                                className={classes.selectBox}
                              >
                                <Box className={classes.DoneParent}>
                                  <DoneIcon
                                    style={{
                                      color: "#5078E1",
                                      fontSize: "0.75rem",
                                    }}
                                  />
                                </Box>
                                <Typography className={classes.doneText}>
                                  {t("Default")}
                                </Typography>
                              </Stack>
                            ) : i === index && value?.bool ? (
                              <Stack
                                direction="row"
                                className={classes.selectBoxDefault}
                                onClick={() => chooseTemplate(x)}
                              >
                                <Box className={classes.DoneParentDefault}>
                                  <DoneIcon
                                    style={{
                                      color: "#fff",
                                      fontSize: "0.75rem",
                                    }}
                                  />
                                </Box>
                                <Typography className={classes.doneTextDefault}>
                                  {t("Use as default")}
                                </Typography>
                              </Stack>
                            ) : (
                              ""
                            )}
                          </Stack>
                          <Box className={classes.pdfImg}>
                            <DocumentViewer url={x?.image_url} />
                            {x?.default_pdf_type === "Custom" && (
                              <Box className={classes.custom}>
                                <Custom />{" "}
                                {i === index && value?.bool && "Custom"}
                              </Box>
                            )}

                            {/* <img src={x?.image_url} alt='img' width={'100%'} height={'100%'}/> */}
                          </Box>
                          <Box className={classes.container1}>
                            <Stack
                              direction="row"
                              spacing={2}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Typography className={classes.language} noWrap>
                                {x?.language}
                              </Typography>
                              {i === index && value?.bool && (
                                <Typography
                                  className={classes.view}
                                  onClick={() =>
                                    setValue({
                                      ...value,
                                      dialog: true,
                                      selected: x,
                                    })
                                  }
                                >
                                  {t("View")}
                                </Typography>
                              )}
                            </Stack>
                            <Typography
                              className={classes.templateTitle}
                              noWrap
                            >
                              {x?.label}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </Box>

            <Box
              display="flex"
              alignItems={"center"}
              justifyContent={"space-between"}
              style={{ padding: "12px" }}
            >
              <Box className={classes.pageCount}>
                Page {page ?? 0}/<span>{Math.ceil(totalCount / 10)}</span>
              </Box>
              <Pagination
                className={classes.paginate}
                shape="rounded"
                count={Math.ceil(totalCount / 10)}
                page={page}
                onChange={(e, value) => handlePagination(value)}
                color="primary"
              />
            </Box>
          </div>
        )}

        {/* view */}
        <AlertDialog
          header={t("Preview Template")}
          onClose={() => updateState("dialog", false)}
          md
          component={
            <>
              <Stack
                direction="row"
                spacing={2}
                justifyContent={"space-between"}
                alignItems={"center"}
                style={{ padding: "16px" }}
              >
                <Box>
                  {value?.selected?.default_pdf_type === "Custom" && (
                    <Box className={classes.custom1}>
                      <Custom /> Custom
                    </Box>
                  )}
                  <Typography className={classes.dialogTitle} noWrap>
                    {value?.selected?.label}
                  </Typography>
                </Box>
                {!value?.selected?.is_default ? (
                  <Stack
                    direction="row"
                    className={classes.selectBoxDefault}
                    style={{ border: "1px solid #5078E1", borderRadius: "8px" }}
                    onClick={() => {
                      updateState("dialog", false);
                      chooseTemplate(value?.selected);
                    }}
                  >
                    <Box className={classes.DoneParentDefault}>
                      <DoneIcon
                        style={{ color: "#fff", fontSize: "0.75rem" }}
                      />
                    </Box>
                    <Typography className={classes.doneTextDefault}>
                      {t("Use as default")}
                    </Typography>
                  </Stack>
                ) : (
                  <Stack
                    direction="row"
                    className={classes.selectBox}
                    style={{ border: "1px solid #5078E1", borderRadius: "8px" }}
                  >
                    <Box className={classes.DoneParent}>
                      <DoneIcon
                        style={{ color: "#5078E1", fontSize: "0.75rem" }}
                      />
                    </Box>
                    <Typography className={classes.doneText}>
                      {t("Default")}
                    </Typography>
                  </Stack>
                )}
              </Stack>
              <Box style={{ padding: "16px" }}>
                <Box
                  className={classes.pdfImgs}
                  width={"100%"}
                  style={{ height: "calc(100vh - 279px)" }}
                >
                  <DocumentViewer url={value?.selected?.image_url} />
                </Box>
              </Box>
            </>
          }
          open={value?.dialog}
        />
      </Box>
    </Box>
  );
};
