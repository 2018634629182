import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import {
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { Typography } from "@mui/material";
import { FontFamilySwitch } from "../../../utils";
const useStyles = makeStyles((theme, props) => ({
  root: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "-20px",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "start",
    },

    "& svg": {
      // borderRadius: "50%",
      width: "100%",
      minWidth: "165px !important",
      height: "165px !important",
      position: "relative",
      [theme.breakpoints.up("sm")]: {
        width: "100% !important",
        margin: "0 auto",
      },
      "& .recharts-wrapper": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
  center: {
    textAlign: "center",
    position: "absolute",
    left: "0px",
    right: "0px",
    top: "36%",
    zIndex: 1,
    padding: "12px",
    [theme.breakpoints.down("sm")]: {
      left: "0px",
      right: "0px",
      top: "36%",
    },
  },
  chart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize:"0.75rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().semiBold,
  },
  count: {
    fontSize:"1.125rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
  },
}));

export const PieCharts = (props) => {
  const classes = useStyles(props);
  return (
    <div style={{ position: "relative" }} className={classes.root}>
      <ResponsiveContainer style={{ margin: "0 auto" }}>
        <PieChart className={classes.chart}>
          <Pie
            data={props?.data}
            cx="50%"
            cy="50%"
            outerRadius={100}
            innerRadius={props?.radius}
            dataKey="count"
          />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
      {props?.isTotal && (
        <div className={classes.center}>
          <Typography className={classes.count}>{props?.total ?? 0}</Typography>
          <Typography className={classes.title}>Total</Typography>
        </div>
      )}
    </div>
  );
};
