import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../../utils";

export const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderRadius: theme.palette.borderRadius,
    // border: "3px solid white",
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    padding: (props) => props?.padding ?? "19px",
    // height: (props) => props?.height ?? "380px",
    width: "100%",
    // overflow: "auto",
    display: 'flex',
    flexFlow: (props) => props?.flexFlow ?? "column",
  },
  detailsCard1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  countCard: {
    fontSize:"1.5rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().extraBold,
  },
  detailsCard2: {
    color: "#091B29",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize:"0.875rem",
    maxWidth: (props) => props?.maxWidth ?? "107px",
    width: "100%",
  },
  titleFont: {
    color: "#091B29",
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().extraBold,
  },
  flexBoxWithSpace: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  graphDataCount: {
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize:"1rem",
    display: 'flex',
    alignItems: 'center'
  },
  dot: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
  },
  dotParent: {
    display: "flex",
    paddingTop: 0,
    alignItems: "center",
  },
  graphDataTitle: {
    color: "#98A0AC",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize:"0.75rem",
    marginLeft: "8px",
  },
  graphmapData: {
    maxWidth: (props) => props?.maxWidthGraph ?? "221px",
    width: "100%",
    margin: "0 auto",
  },
  areaMeter: {
    color: "#091B29",
    fontSize:"1.125rem",
    fontFamily: FontFamilySwitch().bold,
  },
  map: {
    borderRadius: theme.palette.borderRadius,
    overflow: "hidden",
    height: "100%",
    minHeight: '500px',
    position: "relative",
  },
  searchBox: {
    position: "absolute",
    top: 12,
    right: 0,
    left: 0,
    zIndex: 1,
    padding: "0px 10px",
    width: "85%",
  },
  listText: {
    backgroundColor: "#fff",
    width: "120px !important",
    color: "#1C1C1C",
    boxShadow: "#5C86CB2E",
    borderRadius: "4px",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  listText1: {
    width: "120px !important",
    color: "#98A0AC",
    borderRadius: "4px",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  tabname: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
  },
  list: {
    backgroundColor: "#F5F7FA",
    display: "flex",
    padding: "5px",
    borderRadius: "4px",
    "&.MuiListItem-root": {
      width: "100px !important",
      padding: "0px!important",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      cursor: "pointer",
      borderRadius: " 4px",
    },
  },
  cardNew: {
    borderRadius: theme.palette.borderRadius,
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    boxShadow: "0px 3px 30px #5C86CB2E",
  },
  Expensestitle: {
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().extraBold,
  },
  grid: {
    display: "flex",
    // padding: "0px 12px",
    overflow: "auto",
    // alignItems: "center",
  },
  num: {
    fontSize:"0.75rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().regular,
  },
  noData: {
    textAlign: "center",
    marginTop: "10%",
    [theme.breakpoints.down("md")]: {
      marginTop: "10%",
    },
  },
  weatherBox: {
    maxWidth: "140px",
    width: "100%",
    borderRadius: "4px",
    backgroundColor: theme.palette.weather.main,
    padding: "16px",
    position: "absolute",
    bottom: 16,
    right: 16,
  },
  totalUnit: {
    color: theme.typography.color.white,
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().regular,
  },
  finder: {
    backgroundColor: "#5078E1",
    padding: "10px",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#5078E1",
    },
  },
  noDataGraph: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10%",
  },
  graphMargin: {
    marginTop: (props) => props?.margin ?? "10px",
  },
  requestTab: {
    padding: "12px 24px 24px",
  },
  scrollLgend: {
    maxHeight: (props) => props?.maxHeightGraph ?? "102px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  graphParentFlex: {
    display: "flex",
    alignItems: "center",
  },
  dividerBox: {
    padding: "12px",
  },
  dividerBoxText: {
    color: "#FFFFFF",
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().bold,
  },
  cardHandover: {
    display: 'flex',
    alignItems: 'center'
  },
  activityCard: {
    padding: "8px 12px",
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "#DAE2F6",
    display: "flex",
    alignItems: "center"
  },
  details: {
    width: "90%",
    display: "table-row",
  },
  cardTitle: {
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#091B29",
    marginBottom: "4px",
    wordWrap: "break-word"
  },
  cardSubTitle: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#4E5A6B",
    wordWrap: "break-word"
  },
  comment: {
    height: "20px",
    width: "20px",
    padding: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  table: {
    width: "100%",
    height: "100%",
},
}));
