import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HC_funnel from "highcharts/modules/funnel";
import { FontFamilySwitch } from "../../../utils";

HC_funnel(Highcharts);

export const HighChartsFunnel = ({ data = [], isShowLegend, height = 300, graphTitle }) => {
  const chartRef = useRef(null);
  const highchartRef = useRef(null);

  useEffect(() => {
    if (!highchartRef.current) {
      highchartRef.current = Highcharts.chart(chartRef.current, {
        colors: [
          "#278ECF",
          "#48D762",
          "#FFCA1F",
          "#FF9416",
          "#D42AE8",
          "#535AD7",
          "#FF402C",
          "#83BFFF",
          "#6ED88F",
          "#FFE366",
        ],
        chart: {
          type: "funnel",
        },
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: true,
        },
        title: {
          text: graphTitle,
          align: "left",
          style: {
            color: "#091B29",
            fontSize: "1rem",
            fontFamily: FontFamilySwitch().extraBold,
            marginInlineStart: "8px",
          },
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b> ({point.y:,.0f})",
              softConnector: true,
            },
            neckWidth: "30%",
            neckHeight: "25%",
            width: "80%",
          },
        },
        legend: {
          enabled: isShowLegend,
          align: "center",
          verticalAlign: "bottom",
          layout: "horizontal",
          margin: 15,
        },
        tooltip: {
          useHTML: true, // Enable HTML in the tooltip
          headerFormat: "<table>", // Add opening <table> tag
          pointFormat:
            '<tr><td><span style="color:{point.color};">&#9679;</span> {point.name}: </td>' +
            '<td style="padding:0"><b>{point.y}</b></td></tr>',
          footerFormat: "</table>", // Add closing </table> tag
        },
        series: [
          {
            data: data.map((item) => [item.label, item.value]),
          },
        ],
      });
    } else {
      highchartRef.current.update({
        series: [
          {
            name: "Funnel",
            data: data.map((item) => [item.label, item.value]),
          },
        ],
      });
    }

    const handleFullscreenChange = () => {
      if (highchartRef.current) {
        highchartRef.current.reflow();
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      if (highchartRef.current) {
        highchartRef.current = null;
      }
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, [data, isShowLegend, graphTitle]);

  return <div ref={chartRef} style={{ width: "100%", height: height }}></div>;
};
