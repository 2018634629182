import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../utils";
export const propertyStyles = makeStyles((theme) => ({
  card: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
  },
  cards: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",

  },
  unitImg: {
    height: "141px",
    width: "141px",
    borderRadius: "50%",
    margin: "4px",
    objectFit: "fill"
  },
  imgTag: {
    padding: "2px 6px",
    borderRadius: "5px",
    backgroundColor: "#071741",
    color: "white",
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    position: "absolute",
    bottom: "5px",
    display: "inline",

  },
  imgDiv: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    borderRight: "1px solid #00000014",
    padding: "4px",
  },
  title: {
    color: theme.typography.color.secondary,
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().bold,
    marginBottom: "4px"
  },
  imgDiv2: {
    padding: "0px 12px"
  },
  heading: {
    color: theme.typography.color.tertiary,
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    textTransform: "capitalize",
    marginBottom: "4px"
  },
  sub: {
    color: theme.typography.color.primary,
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    direction: "ltr",
    textAlign: "-webkit-match-parent",
    '& .ql-editor': {
      padding: '0px 0px 16px 0px',
      color: theme.typography.color.primary,
      fontSize:"0.875rem",
      fontFamily: FontFamilySwitch().semiBold,
    }

  },
  sub1: {
    color: theme.typography.color.primary,
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  matric: {
    color: theme.typography.color.tertiary,
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().regular,
    textTransform: "capitalize"
  },
  map: {
    borderRadius: theme.palette.borderRadius,
    overflow: "hidden",
    height: "200px"
  },
  addressRoot: {
    padding: "12px",
    minHeight: "200px",
    border: "1px solid #E4E8EE",
    borderRadius: theme.palette.borderRadius
  },
  shareHolderCard: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    borderRadius: theme.palette.borderRadius,
    display: "flex",
    alignItems: "center",
  },
  list: {
    '&.MuiList-root': {
      paddingBottom: "0px !important"
    },
    '&.MuiListItem-root': {
      width: '135px !important',
      padding: '0px!important',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      // border: '1px solid #E4E8EE',
      cursor: 'pointer',
      marginTop: "-4px"
    }
  },
  tabname: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().bold,
    cursor: 'pointer'
  },
  imagebox1: {
    padding: '16px',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: theme.palette.borderRadius,
  },
  imagebox: {
    textAlign: 'center',
    padding: '23px 16px',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: theme.palette.borderRadius,
  },

  popupimg: {
    '& .styles-module_close__2I1sI': {
      color: 'white',
      position: 'absolute',
      top: '67px',
      right: '100px',
      fontSize: '40px',
      fontWeight: 'bold',
      opacity: 0.2,
      cursor: 'pointer',
    }
  },
  imagebox3: {
    backgroundColor: '#fff',
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: theme.palette.borderRadius,
    margin: "-24px"
  },
  listText: {
    backgroundColor: 'white',
    width: '135px !important',
    color: ' #5078E1',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    borderBottom: '1.5px solid #5078E1',

  },
  descriptionDiv: {
    maxWidth: "100%",
    overflow: "overlay",
    maxHeight: (props) => props?.isSeeMore ? "200px" : "100%",
  },
  seeMore: {
    textDecoration: "underline",
    color: "blue",
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    cursor: "pointer",
    textAlign: "right"
  },
  noImageBox: {
    height: 300,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  noImageText: {
      position: "absolute",
  },
  shareHolderImage: {
    height: "56px",
    width: "56px",
    borderRadius: "50%",
    objectFit: "fill",
    marginInlineEnd: "16px",
  },
}));