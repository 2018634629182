import { Box, Grid } from "@mui/material"
import React, { useState, useContext } from "react"
import { dashboardStyles } from "./style"
import { CardDetails } from "../propertymanagerdashboard/components"
import { DialogDrawer } from "../../components"
import { useEffect } from "react"
import { NetworkCall } from "../../networkcall"
import { config } from "../../config"
import { AlertProps, NetWorkCallMethods } from "../../utils"
import { AlertContext } from "../../contexts"
import { BackendRoutes } from "../../router/routes"
import { NewLoader } from "../../components/newLoader"
import { constructBasicStatsData } from "./utils"
import GraphComponent from "../propertymanagerdashboard/components/graphcomponent";

export const DashBoard = ({
    selected_company = {},
    selected_teams = {},
    t = () => false
}) => {
    const classes = dashboardStyles();

    // useContext
    const alert = useContext(AlertContext);

    // useState
    const [loader, setLoader] = useState(true);
    const [stats_state, set_stats_state] = useState(null);
    const [zoom, setZoom] = useState(false);
    const [zoomGraph, setZoomGraph] = useState("");
    const scaleLine = {
        x: {
            grid: {
                display: false
            }
        },
        y: {
            grid: {
                display: false
            }
        }
    }
    // eslint-disable-next-line
    const zoomGraphFunction = (e) => {
        setZoomGraph(e);
        setZoom(true);
    };
    // close popup
    const handleCloseModule = () => {
        setZoom(false);
    };

    useEffect(() => {
        if (selected_company?.value
            && selected_teams?.value) {
            getInitialData()
        }
        // eslint-disable-next-line
    }, [selected_teams]);

    useEffect(() => {
        if (stats_state) {
            setLoader(false)
        }
        // eslint-disable-next-line
    }, [stats_state]);

    const getInitialData = () => {
        return new Promise(async (resolve, reject) => {
            try {
                await Promise.all([
                    getBasicStats(),
                ])
            } catch (error) {
                reject(error);
            }
        })
    }

    // get basic stats
    const getBasicStats = () => {
        let teamsId = selected_teams?.value;
        const payload = {
            company_id: selected_company?.value ?? "",
        };
        if (teamsId !== "noteams") { payload["team_id"] = teamsId }
        NetworkCall(
            `${config.api_url}${BackendRoutes.contract_dashboard}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((res) => {
            const result = constructBasicStatsData(t, res?.data?.data);
            set_stats_state(result);
        }).catch((error) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error,
            });
        });
    };

    return (loader ?
        (<NewLoader minusHeight="100px" />) :
        (<Box className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={3} sx={{ display: "flex" }}>
                    <Grid container spacing={2}>
                        {stats_state?.agreement_stats?.map((x) => {
                            return (
                                <Grid item xs={6} sm={4} md={6} lg={6}>
                                    <CardDetails data={x} padding={16} />
                                </Grid>
                            )
                        })}

                    </Grid>
                </Grid>
                {/* active agreements */}
                <Grid item xs={12} sm={12} md={6} lg={3}>
                    <GraphComponent
                        t={t}
                        graphTitle={t("Active Agreements")}
                        chartHeader={t("Active Agreements")}
                        graphData={stats_state?.active_agreements_by_agreement_type?.data ?? []}
                        is_pie_new
                        height={"350px"}
                        isShowLegend={true}
                        minWidth={true}
                        onZoomClick={zoomGraphFunction}
                        textMargin={'0px 0px 16px'}
                        divider1={{ xs: 12, sm: 7.4, md: 12, lg: 12 }}
                        divider={{ xs: 12, sm: 4.6, md: 12, lg: 12 }}
                        gradiantStyle={{
                            margin: '0px 0px 0px',
                            display: "auto",
                            alignItems: "center",
                            top: { lg: "auto", md: '16px', sm: "3px", },
                            height: { md: "400px" }
                        }}
                    />
                </Grid>
                {/* agreement revenue by month */}
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <GraphComponent
                        padding={"0px 16px"}
                        t={t}
                        graphTitle={t("Agreement Revenue For Next 12 Months")}
                        chartHeader={t("Agreement Revenue For Next 12 Months")}
                        graphData={stats_state?.agreement_revenue?.data ?? []}
                        line_new
                        height={"320px"}
                        chartHeight={"320px"}
                        isNoLegend
                        scale={scaleLine}
                        onZoomClick={zoomGraphFunction}
                        justifyContent={'start'}
                        gradiantStyle={{
                            margin: '15px 0px 0px',
                            top: {
                                lg: "auto",
                                md: '7px',
                                sm: "3px",
                            },
                            height: {
                                sm: "63px"
                            }
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={0.5}>
                <Grid item xs={12} md={6} lg={6}>
                    <GraphComponent
                        t={t}
                        padding={"0px 16px"}
                        graphTitle={t("Forcasted Revenue for next 12 Months")}
                        chartHeader={t("Forcasted Revenue for next 12 Months")}
                        graphData={stats_state?.forcast_revenue ?? []}
                        is_line_bar
                        scale={scaleLine}
                        gheight={250}
                        isNoLegend
                        onZoomClick={zoomGraphFunction}
                        justifyContent={'start'}
                        gradiantStyle={{
                            margin: '15px 0px 0px',
                            top: {
                                lg: "auto",
                                md: '7px',
                                sm: "3px",
                            },
                            height: {
                                sm: "63px"
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} sx={{ display: "flex" }}>
                    <Grid container spacing={2}>
                        {stats_state?.contact_stats?.map((x) => {
                            return (
                                <Grid item xs={6} sm={4} md={6} lg={6}>
                                    <CardDetails data={x} padding={16} />
                                </Grid>
                            )
                        })}

                    </Grid>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <Box height={400}>
                        <GraphComponent
                            t={t}
                            race_bar
                            padding={"0px 16px"}
                            graphTitle={t("Contracts Types")}
                            chartHeader={t("Contracts Types")}
                            height={420}
                            chartHeight={400}
                            gheight={350}
                            scale={scaleLine}
                            onZoomClick={zoomGraphFunction}
                            justifyContent={'start'}
                            indexAxis={"y"}
                            isNoLegend
                            graphData={stats_state?.contract_types ?? []}
                            textMargin={'0px 0px 16px'}
                            gradiantStyle={{
                                margin: '15px 0px 0px',
                                maxHeight: "70px",
                                top: { lg: "auto", md: '7px', sm: "3px", },
                                height: { sm: "63px", }
                            }} />
                    </Box>
                </Grid>
            </Grid>
            <DialogDrawer
                header={t(zoomGraph)}
                maxWidth={"md"}
                handleClose={handleCloseModule}
                open={zoom}
                height={"auto"}
                borderRadius={"12px"}
                padding={() => {
                    let padding = "0px";
                    const zoom_graph_title_list_1 = ["Agreement Revenue By Month For Selected Year"]
                    const zoom_graph_title_list_2 = ["Forcasted Revenue for next 12 Months", "Contracts Types"]
                    if (zoom_graph_title_list_1.includes(zoomGraph)) {
                        padding = "50px 20px";
                    } else if (zoom_graph_title_list_2.includes(zoomGraph)) {
                        padding = "50px 0px";
                    }
                    return padding;
                }}
                onClose={handleCloseModule}
                component={
                    <>
                        {zoomGraph === "Active Agreements By Agreement Type" && (
                            <GraphComponent
                                graphData={stats_state?.active_agreements_by_agreement_type?.data ?? []}
                                isPie
                                t={t}
                                margin={"0px"}
                                padding={"16px"}
                                maxHeightGraph={"250px"}
                                minWidth={false}
                                height={"auto"}
                                is_popUp
                                centerTop="44%"
                                justifyContent={'start'}
                                divider1={{ xs: 12, sm: 5, md: 5, lg: 5 }}
                                divider={{ xs: 12, sm: 7, md: 7, lg: 7 }}
                                gradiantStyle={{
                                    margin: '0px 0px 0px',
                                    display: "flex",
                                    alignItems: "center",
                                    grid_template: "100%",
                                    maxHeight: "auto",
                                    top: { lg: "auto", md: '-8px', sm: "auto", },
                                    height: { md: "auto", sm: "auto", lg: "100%" }
                                }} />
                        )}
                        {zoomGraph === "Agreement Revenue By Month For Selected Year" && (
                            <GraphComponent
                                graphData={stats_state?.agreement_revenue?.data ?? []}
                                line
                                margin={"0px"}
                                scale={scaleLine}
                                minWidth={false}
                                is_popUp
                                justifyContent={'start'}
                                padding={"0px 16px 16px"}
                                gheight={350}
                                divider1={
                                    {
                                        xs: 12,
                                        sm: 4,
                                        md: 4,
                                        lg: 4
                                    }
                                }
                                divider={
                                    {
                                        xs: 12,
                                        sm: 8,
                                        md: 8,
                                        lg: 8
                                    }
                                }

                                gradiantStyle={{
                                    margin: '15px 0px 0px',
                                    grid_template: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    maxHeight: "auto",
                                    top: {
                                        lg: "auto",
                                        md: '16px',
                                        sm: "auto",
                                    },
                                    height: {
                                        md: "auto",
                                        sm: "auto",
                                        lg: "100%"
                                    }
                                }}

                            />
                        )}
                        {zoomGraph === "Forcasted Revenue for next 12 Months" && (
                            <GraphComponent
                                graphData={stats_state?.forcast_revenue ?? []}
                                line
                                margin={"0px"}
                                scale={scaleLine}
                                minWidth={false}
                                is_popUp
                                isNoLegend
                                justifyContent={'start'}
                                padding={"0px 16px 16px"}
                                gheight={350}
                                divider1={{ xs: 12, sm: 4, md: 4, lg: 4 }}
                                divider={{ xs: 12, sm: 8, md: 8, lg: 8 }}
                                gradiantStyle={{
                                    margin: '15px 0px 0px',
                                    grid_template: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    maxHeight: "auto",
                                    top: { lg: "auto", md: '16px', sm: "auto", },
                                    height: { md: "auto", sm: "auto", lg: "100%" }
                                }} />
                        )}
                        {zoomGraph === "Contracts Types" && (
                            <GraphComponent
                                indexAxis={"y"}
                                isDatalabels={false}
                                isBar
                                graphData={stats_state?.contract_types ?? []}
                                margin={"0px"}
                                scale={scaleLine}
                                minWidth={false}
                                gheight={350}
                                is_popUp
                                isNoLegend
                                justifyContent={'start'}
                                padding={"0px 16px 16px"}
                                divider1={{ xs: 12, sm: 4, md: 4, lg: 4, }}
                                divider={{ xs: 12, sm: 8, md: 8, lg: 8, }}
                                gradiantStyle={{
                                    margin: '15px 0px 0px',
                                    grid_template: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    maxHeight: "auto",
                                    top: { lg: "auto", md: '16px', sm: "auto", },
                                    height: { md: "auto", sm: "auto", lg: "100%", }
                                }} />
                        )}
                    </>
                }
            />
        </Box >)
    )
}