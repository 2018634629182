import React from "react";

export const PdfImage = ({style}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 343.3 489.81" style={style}>
      <g data-name="Capa 2">
        <g data-name="Capa 2">
          <path
            fill="#eaeaea"
            d="M343.3 489.81H1.68V0h256.81l84.81 83.72z"
          ></path>
          <path
            fill="#d9251c"
            d="M91.31 312.81c-4.72 0-9.82-1.76-14.4-7.11-7.4-8.63-7.7-18.49-.81-27.07 6.28-7.81 18.61-14.88 36.64-21 6.92-2.35 14.51-4.52 22.61-6.45 5-9.07 9.83-19 14.34-29.45 4.18-9.68 7.8-19.17 10.78-28.18C148.7 173.8 140 153.65 140.22 139c.22-12.29 7-20.19 18.61-21.67 10.3-1.31 18.14 3.69 21.48 13.72 4.61 13.88.59 37.22-6.75 60.85a323.25 323.25 0 0019.34 27.66c6.1 7.85 12.32 15.17 18.45 21.77 29-.58 48.26 3.5 57.83 8.64 10.07 5.39 14.12 13.88 11.14 23.3-3.62 11.41-12.19 16.89-23.52 15-14.64-2.4-33.27-17.07-50.37-34.77a358.38 358.38 0 00-36.43 3.3c-9.37 1.31-18.37 2.94-26.79 4.81-12 21.09-26 39.92-39.37 47.47a26.22 26.22 0 01-12.53 3.73zm35.4-47c-20.35 5.85-35.45 13.08-41.25 20.3-3.31 4.12-3.16 7.41.55 11.74 2.27 2.64 5.28 4.57 11.92.81 8.27-4.66 18.5-16.54 28.78-32.81zm96.64-12.39c14 13.14 26.69 21.61 35.39 23 4.55.75 8-.13 10.14-6.83.59-1.86 1.69-5.32-5.36-9.1-7.94-4.17-22.3-6.58-40.17-7.03zm-54.7-47c-2.5 6.88-5.19 13.63-7.94 20-3 7-6.4 14.21-10.08 21.43a375.69 375.69 0 0117.7-2.91q14.78-2.07 27.53-2.93c-4.38-5-8.56-10.11-12.44-15.1a338.11 338.11 0 01-14.77-20.45zm-8.3-77.19c-6.61.84-8 4.59-8.13 10-.16 8.93 4.63 22.6 13 38.31 5-18.9 6.47-34.32 3.69-42.68-1.53-4.62-3.93-6.2-8.57-5.6z"
          ></path>
          <path fill="#c9c9c9" d="M258.49 84.81h84.81L258.49 0z"></path>
          <path fill="#d9251c" d="M0 384.49h343.3v105.32H0z"></path>
          <g fill="#fff">
            <path d="M98.12 407.37h22.75c11.68 0 19.62 6.58 19.62 17 0 12-9.05 17.43-19.9 17.43H110v20.54H98.12zm11.9 25h9.07c5.51 0 9.4-2.11 9.4-7.73s-4.11-7.79-9.15-7.79H110zM151 407.37h21.8c16 0 26.49 10.55 26.49 26.81 0 16-10.16 28.19-27 28.19H151zm11.89 45.39h8.92c10.54 0 15.2-7.84 15.2-18.55 0-9-4.36-17.23-15.13-17.23h-9zM211.32 407.37h38.39v9.75h-26.78v13.47H248v9.75h-25.07v22h-11.61z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}