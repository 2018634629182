import {
    Avatar, Box,
    Grid,
    Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
// import { useNavigate } from 'react-router-dom';
import {  FontFamilySwitch, stringAvatar } from '../../utils';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: "12px",
        backgroundColor: theme.palette.background.secondary,
        height: `calc(100vh - 88px)`,
        overflow: "auto"
    },

    btn: {
        borderRadius: theme.palette.borderRadius,
    },
    btn2: {
        borderRadius: theme.palette.borderRadius,
        color: theme.palette.secondary.main,
    },
    text: {
        fontSize:"1rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
    },
    subText: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary
    },
    bottomTitle: {
        fontSize:"1.125rem",
        fontFamily: FontFamilySwitch().extraBold,

    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
        marginBottom: "12px"
    },
    contentBottom: {

    },
    drawer: {
        "& .MuiDrawer-paper": {

            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
        },
        "& .MuiContainer-root": {
            padding: "0px"
        },
    },
    grid: {
        backgroundColor: theme.palette.background.secondary,
        borderRadius: theme.palette.borderRadius,
        padding: "4px"
    },
    title: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary

    },
    subTitle: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.secondary
    },
    box: {
        borderRadius: theme.palette.borderRadius,
        padding: "4px",
        backgroundColor: "white",
        border: "1px solid #E4E8EE"
    },
    area: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.tertiary
    },
    sqft: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
    },
    color: {
        backgroundColor: "#FFEACC",
        borderRadius: theme.palette.borderRadius,
        color: "#E29336",
        padding: "4px",
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,

    },
    color2: {
        backgroundColor: "#CFCFFF",
        borderRadius: theme.palette.borderRadius,
        color: "#6A69D2",
        padding: "4px",
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,

    },
    color3: {
        backgroundColor: "#E4E8EE",
        borderRadius: theme.palette.borderRadius,
        color: theme.typography.color.secondary,
        padding: "4px",
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        marginLeft: 6

    },
    img: {
        width: "59px",
        height: "79px",
        objectFit: "fill",
        borderRadius: theme.palette.borderRadius
    },
    span: {
        backgroundColor: "#E1E8F8",
        borderRadius: theme.palette.borderRadius,
        padding: "4px",
        fontSize:"0.75rem"
    }
}))

export const ResidentDetails = (props) => {
    const { t } = (props)

    const classes = useStyles();
    // const navigate = useNavigate();

    // const handleUnitEdit = () => {
    //     navigate(
    //         Routes.addUnit +
    //         "?companyId=" + props?.data?.property?.company?.id +
    //         "&propertyId=" + props?.data?.property?.id +
    //         "&blockId=" + props?.data?.block?.id +
    //         "&floorId=" + props?.data?.floor?.id +
    //         "&unitId=" + props?.data?.id +
    //         "&topNavBarTitle=" + props?.data?.name);
    // };

    return <div className={classes.root}>
        <Grid container className={classes.content} textAlign="left" spacing={2}>
            <Grid item xs={4} sm={2} md={4} lg={3}>
                <Avatar className={classes.img} {...stringAvatar(props?.data?.first_name)}>
                </Avatar>
                {/* <img src={props?.data?.img}
                    alt="dataimg"
                    className={classes.img}
                /> */}
            </Grid>
            <Grid item xs={8} sm={10} md={8} lg={9}>
                <Typography className={classes.text}>
                    {`${props?.data?.first_name} ${" "} ${props?.data?.last_name ? props?.data?.last_name : ""}`}
                    &nbsp;
                    <span className={classes.span}>{props?.data?.user_no}</span>
                </Typography>
                <Typography className={classes.subText} marginTop="5px">{`${props?.data?.mobile_no_country_code} - ${props?.data?.mobile_no}`}</Typography>
                <Typography className={classes.subText} marginTop="5px">{props?.data?.email_id}</Typography>
                <Typography className={classes.subText} marginTop="5px">{props?.data?.company_name}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Box display={"flex"}>
                    <Box>
                        <Typography className={classes.subTitle}>
                            {t("Address")} : <span className={classes.subText}>
                                {props?.data?.street1 ? (props?.data?.street1 + ", ") : " "}
                                {props?.data?.street2 ? (props?.data?.street2 + ", ") : " "}
                                {props?.data?.street3 ? (props?.data?.street3 + ", ") : " "}
                                {props?.data?.district ? (props?.data?.district + ", ") : " "}
                                {props?.data?.city ? (props?.data?.city + ", ") : " "}
                                {props?.data?.state ? (props?.data?.state + ", ") : " "}
                                {props?.data?.zipcode ? (props?.data?.zipcode + ", ") : " "}
                            </span>
                        </Typography>
                    </Box>
                    <Box>

                    </Box>
                </Box>
            </Grid>
        </Grid>
    </div>
}