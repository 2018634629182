import React from 'react'
import { FormGenerator, Label } from '../../../components'
import { Box, Grid, Stack } from '@mui/material'
import { loadOptionsApis } from '../../../utils/asyncPaginateLoadOptions'
import { ProjectContext } from '../projectContext'
import { makeStyles } from '@mui/styles'
import ReactQuill from 'react-quill'
import { FontFamilySwitch } from '../../../utils'


const useStyle = makeStyles((theme) => ({
    reactQuil: {
        "& .ql-toolbar.ql-snow": {
            borderRadius: "8px 8px 0px 0px"
        },
        "& .ql-container.ql-snow": {
            borderRadius: "0px 0px 8px 8px"
        },
        "& .ql-editor": {
            textAlign: "start",
            fontSize: "0.875rem",
            fontFamily: FontFamilySwitch().bold,
            color: "#091B29",
            height: "calc(100vh - 500px)",
            padding: "12px"
        },
    },
}))

export const ProgressForm = ({
    projectData = "",
    t = () => false
}) => {

    const {
        updateProgressForm,
        progressForm,
        setProgressForm,
        viewData,
        progressDesc, setProgressDesc
    } = React.useContext(ProjectContext)
    const classes = useStyle()
    const modules = {
        toolbar: {
            container: [
                [{ header: [1, 2, false] }],
                [{ color: [] }, { background: [] }],
                ["bold", "italic", "underline", "strike"],
                [{ align: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                // ["emoji"],
                // ["code-block"],
            ],
        },
        "emoji-toolbar": false,
        "emoji-textarea": false,
        "emoji-shortname": false,
    }
    const manualPropertyResponse = (array) => {
        const result = array?.map((e) => {
            return {
                label: e?.name,
                value: e?.property_id,
                subTitle1: e?.property_no,
                subTitle2: e?.city ?? "",
                url: e?.logo,
                area: e?.area,
                city: e?.city,
                country: e?.country,
                district: e?.district,
                landmark: e?.landmark,
                property_no: e?.property_no,
                state: e?.state,
                is_block: e?.is_block,
                id: e?.id
            }
        })
        return result
    }

    const manualBlockResponse = (array) => {
        const result = array?.data?.map((e) => {
            return {
                label: e?.name,
                value: e?.block_id,
                area: e?.area,
                block_no: e?.block_no,
                city: e?.city,
                country: e?.country,
                district: e?.district,
                landmark: e?.landmark,
                logo: e?.logo,
                name: e?.name,
                property_id: e?.property_id,
                property_logo: e?.property_logo,
                property_name: e?.property_name,
                property_no: e?.property_no,
                state: e?.state,
            }
        })
        return result
    }

    const handleDeleteAssets = (value) => {
        const normal = progressForm?.images?.filter((i) => i?.rowId !== value?.rowId)
        const deleted = progressForm?.images?.filter((i) => i?.rowId === value?.rowId)

        setProgressForm({
            ...progressForm,
            images: normal,
            delectedAssets: [...progressForm?.delectedAssets, ...deleted]
        })
    }

    return (
        <Box p={2}>
            <FormGenerator
                spacing={2}
                components={[
                    {
                        size: {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                        },
                        isActive: true,
                        component: "text",
                        label: t("Progress Title"),
                        value: progressForm?.progressTitle,
                        placeholder: t("Enter Progress Title"),
                        onChange: (value) =>
                            updateProgressForm("progressTitle", value.target.value),
                        error: t(progressForm?.error?.progressTitle),
                        isRequired: true,
                        isReadonly: progressForm?.isView,
                    },
                    {
                        size: {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                        },
                        isActive: true,
                        component: "date",
                        label: t("Progress Date"),
                        value: progressForm?.progressDate,
                        placeholder: t("Select Progress Date"),
                        onChange: (value) => updateProgressForm("progressDate", value),
                        error: t(progressForm?.error?.progressDate),
                        isRequired: true,
                        isReadonly: progressForm?.isView,
                    },
                    {
                        isActive: true,
                        component: "fileUpload",
                        label: t("Upload Image"),
                        selected: progressForm?.images,
                        placeholder: "Category",
                        handleUpload: (value) => updateProgressForm("images", [...progressForm?.images, ...value]),
                        handleDelete: (value) => handleDeleteAssets(value),
                        error: t(progressForm?.error?.images),
                        isRequired: true,
                        size: {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                        },
                        isReadonly: progressForm?.isView,
                    },
                    {
                        isActive: true,
                        component: "select",
                        label: t("Property"),
                        value: progressForm?.property,
                        placeholder: t("Select Property"),
                        onChange: (value) => updateProgressForm("property", value),
                        loadOptions: (search, array, handleLoading) =>
                            loadOptionsApis(
                                "project_properties/list",
                                {
                                    is_block: viewData?.type === "Property" ? false : true,
                                    project_id: projectData?.id
                                },
                                search,
                                array,
                                handleLoading,
                                "data",
                                {},
                                manualPropertyResponse
                            ),
                        isPaginate: true,
                        error: progressForm?.error?.property,
                        isRequired: viewData?.type === "Task Based" ? false : true,
                        size: {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                        },
                        isReadonly: progressForm?.isView,
                    },
                    {
                        isActive: viewData?.type !== "Property",
                        component: "select",
                        label: t("Block"),
                        value: progressForm?.block,
                        placeholder: t("Select Block"),
                        onChange: (value) => updateProgressForm("block", value),
                        loadOptions: (search, array, handleLoading) =>
                            loadOptionsApis(
                                "project_block/list",
                                {
                                    project_id: projectData?.id
                                },
                                search,
                                array,
                                handleLoading,
                                "data",
                                {},
                                manualBlockResponse
                            ),
                        isPaginate: true,
                        error: progressForm?.error?.block,
                        isRequired: viewData?.type === "Task Based" ? false : true,
                        size: {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                        },
                        isReadonly: progressForm?.isView,
                    }
                ]}
            />
            <Stack spacing={1} mt={2}>
                <Label label="Description" />
                <Grid container>
                    <Grid item xs={12}>
                        <ReactQuill
                            readOnly={progressForm?.isView}
                            onChange={(value) => setProgressDesc({
                                data: value
                            })}
                            value={progressDesc?.data}
                            modules={progressForm?.isView ? { toolbar: false } : modules}
                            className={classes.reactQuil}
                            theme="snow"
                        />
                    </Grid>
                </Grid>
            </Stack>
        </Box>
    )
}