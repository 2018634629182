import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { FontFamilySwitch } from "../../../../../utils";
export const useStyles = makeStyles((theme) => ({
  root: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
    overflow: "hidden",
    marginTop: "14px",
    height: "calc(100vh - 268px)",
  },
  content: {
    // textAlign: "center",
  },
  addNewButton: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: "none",
    fontSize: "0.875rem",
  },
  filterButton: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "12px",
  },
  addNewDialog: {
    "& .MuiDialog-paper": {
      width: "551px",
      borderRadius: theme.palette.borderRadius,
      padding: "0px",
      backgroundColor: "white",
    },
  },
  addNewDialogHeader: {
    padding: "16px 24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #E4E8EE",
  },
  addNewDialogHeaderTitle: {
    fontSize: "1rem",
    color: "#091B29",
    fontFamily: FontFamilySwitch().extraBold,
  },
  addNewDialogHeaderCloseButton: {
    padding: "0px",
  },
  addNewDialogHeaderCloseIcon: {
    height: "14px",
    width: "14px",
    padding: "0px",
  },
  addNewDialogQuestionBody: {
    padding: "24px",
  },
  addNewDialogFormBody: {
    maxHeight: "504px",
    overflow: "overlay",
  },
  addQuestionCard1: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    borderRadius: theme.palette.borderRadius,
  },
  addQuestionCard2: {
    cursor: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    borderRadius: theme.palette.borderRadius,
  },
  addQuestionContent1: {
    marginRight: "12px",
    display: "flex",
    alignItems: "center",
  },
  addQuestionContent2: {
    display: "flex",
    alignItems: "center",
  },
  addQuestionSerialAvatar: {
    marginInlineEnd: "12px",
    height: "22px",
    width: "22px",
  },
  addQuestionSerialText: {
    fontSize: "0.75rem",
    color: "white",
    fontFamily: FontFamilySwitch().bold,
  },
  addNewDialogQuestionText: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  addNewDialogInfoIcon1: {
    marginInlineEnd: "24px",
    display: "flex",
    alignItems: "center",
  },
  addNewDialogInfoIcon2: {
    display: "flex",
    alignItems: "center",
  },
  addNewDialogFormContent: {
    padding: "24px",
  },
  addNewDialogFormFieldLabel: {
    color: theme.typography.color.tertiary,
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.875rem",
    marginBottom: "5px",
  },
  addNewDialogFormButtonUnSelected: {
    borderRadius: theme.palette.borderRadius,
    height: "45px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "white",
    },
    "&.Mui-disabled": {
      backgroundColor: "#E4E8EE",
    },
  },
  addNewDialogFormButtonTextUnSelected: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#4E5A6B",
  },
  addNewDialogFormButtonSelected: {
    borderRadius: theme.palette.borderRadius,
    height: "45px",
    border: "1px solid #5078E1",
    backgroundColor: "#5078E1",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#5078E1 ",
    },
  },
  addNewDialogFormButtonTextSelected: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "white",
  },
  switchContainerOn: {
    position: "relative",
    height: "20px",
    width: "36px",
    borderRadius: "20px",
    backgroundColor: "#EEF9EE",
  },
  switchToggleOn: {
    position: "absolute",
    right: "0px",
    height: "20px",
    width: "20px",
    borderRadius: "20px",
    border: "2px solid white",
    backgroundColor: "#5AC782",
  },
  switchContainerOff: {
    position: "relative",
    height: "20px",
    width: "36px",
    borderRadius: "20px",
    backgroundColor: "#E4E8EE",
  },
  switchToggleOff: {
    position: "absolute",
    right: "20px",
    height: "20px",
    width: "20px",
    borderRadius: "20px",
    border: "2px solid #E4E8EE",
    backgroundColor: "#98A0AC",
  },
  addNewDialogCustomSwitchOn: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    borderRadius: theme.palette.borderRadius,
    minHeight: "50px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
  },
  addNewDialogCustomSwitchOn1: {
    cursor: "pointer",
    padding: "16px",
    borderRadius: theme.palette.borderRadius,
    minHeight: "50px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
  },
  addNewDialogCustomSwitchContent1: {
    marginRight: "12px",
    display: "flex",
    alignItems: "center",
  },
  addNewDialogCustomSwitchText: {
    color: "#091B29",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  addNewDialogCustomSwitchContent2: {
    display: "flex",
    alignItems: "center",
  },
  addNewDialogCustomSwitchOff: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    borderRadius: theme.palette.borderRadius,
    minHeight: "50px",
    border: "1px solid #F2F4F7",
    backgroundColor: "#F2F4F7",
  },
  addNewDialogCustomSwitchOff1: {
    cursor: "pointer",
    padding: "16px",
    borderRadius: theme.palette.borderRadius,
    minHeight: "50px",
    border: "1px solid #F2F4F7",
    backgroundColor: "#F2F4F7",
  },
  addNewDialogButtonContentAdd: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "24px",
  },
  addNewDialogButtonContentViewEdit: {
    justifyContent: "center",
    alignItems: "center",
    padding: "24px",
  },
  addNewDialogBackButton: {
    borderRadius: theme.palette.borderRadius,
    height: "45px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#E4E8EE ",
    },
  },
  addNewDialogBackButtonText: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#4E5A6B",
  },
  addNewDialogAddButton: {
    borderRadius: theme.palette.borderRadius,
    height: "45px",
    border: "1px solid #5078E1",
    backgroundColor: "#5078E1",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#5078E1 ",
    },
  },
  addNewDialogAddButtonText: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "white",
  },
  addNewDialogViewEditButton: {
    borderRadius: theme.palette.borderRadius,
    height: "45px",
    border: "1px solid #5078E1",
    backgroundColor: "#5078E1",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#5078E1 ",
    },
  },
  addNewDialogViewEditButtonText: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "white",
  },
}));

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px",
    padding: "12px",
    backgroundColor: "#FFFFFF",
    color: "#4E5A6B",
    border: "1px solid #E4E8EE",
    boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.04)",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
}));
