import { Box, Grid } from "@mui/material"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { LeadIcon } from "../../assets/lead"
import { DialogDrawer, Subheader } from "../../components"
import { config } from "../../config"
import { AlertContext, AuthContext, BackdropContext } from "../../contexts"
import { NetworkCall } from "../../networkcall"
import { NetWorkCallMethods, accessCheckRender, getCalendarOptions, getCompanyOption, getRoutePermissionNew, pageName } from "../../utils"
import { UtilityGraphComponent } from "../inspectionNewDashboard/components/graphcomponent"
import { CardDetails } from "../propertymanagerdashboard/components"
import Graphcomponent from "../propertymanagerdashboard/components/graphcomponent"
import { useStylesCreation } from "./style"

const UtilityManagerDashboard = (props) => {
    const { loading, handleLoading, t } = props;
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext);
    const alert = React.useContext(AlertContext);
    const classes = useStylesCreation()
    const [zoom, setZoom] = useState(false);
    const [zoomGraph, setZoomGraph] = useState("");
    const [data, setData] = useState({})
    const [companyList, setCompanyList] = useState([])
    const [selectedCompany, setSelectedCompany] = useState("")
    const [calendarOptions, setCalendarOptions] = useState("")
    const [selectedCalendar, setSelectedCalendar] = useState("")
    const [permission, setPermission] = useState({})
    const [utilityGraphData, setUtilityGraphData] = React.useState({
        "graphData": {
            "labels": [],
            "datasets": [
                {
                    "label": "Utility Graph",
                    "data": [],
                    "fill": true,
                    "backgroundColor": "#a450fb",
                    "borderColor": "#13d882"
                }
            ]
        },
        "readings": {}
    })
    const [utilityData, setUtilityData] = React.useState({
        selected_property: "",
        selected_unit: "",
        selected_utility: "",
        propertyData: [],
        unitData: [],
        utilityItems: []
    })
    const [selectedMeter, setSelectedMeter] = React.useState("")
    const [defaultUtilityUnit, setDefaultUtilityUnit] = React.useState({})
    const [defaultUtilityItem, setDefaultUtilityItem] = React.useState({})
    const utilityCards = [
        {
            title: t("Current Reading"),
            reading: utilityGraphData?.readings?.present_meter_readings ?? 0,
            image: "/images/propertdashboard/occupied.png"
        },
        {
            title: t("Past Reading"),
            reading: utilityGraphData?.readings?.previous_meter_readings ?? 0,
            image: "/images/propertdashboard/activeunits.png"

        },
    ]
    const utilityDetails = [
        {
            icon: <LeadIcon />,
            count: data?.total_unit_utils ?? 0,
            sub: t("Total Unit Utilities"),
            icon_type: true
        },
        {
            image: "/images/propertdashboard/block.svg",
            count: data?.external_utils ?? 0,
            sub: t("Utilities-External"),
            image_type: true
        },
        {
            image: "/images/propertdashboard/pausedIcon.png",
            count: data?.unit_with_util ?? 0,
            sub: t("Units with utilities"),
            image_type: true
        },
        {
            image: "/images/propertdashboard/managerIcon.png",
            count: data?.internal_utils ?? 0,
            sub: t("Utilities-Internal"),
            image_type: true
        },
        {
            image: "/images/propertdashboard/assigned.svg",
            count: data?.unit_without_util ?? 0,
            sub: t("Units with zero utilities"),
            image_type: true
        },
        {
            image: "/images/propertdashboard/activeunits.png",
            count: data?.total_reading ?? 0,
            sub: t("Readings Taken"),
            image_type: true
        },
    ]
    // use effect to get permission
    useEffect(() => {
        const perm = getRoutePermissionNew(auth);
        if (perm) {
            setPermission(perm);
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert);
                if (company) {
                    setCompanyList(company?.list);
                    setSelectedCompany(company?.selected);
                    let tempCalendarOptions = getCalendarOptions()
                    setCalendarOptions(tempCalendarOptions)
                    setSelectedCalendar(tempCalendarOptions[6])
                    getPropertyDropdown(company?.selected?.value)
                    getDashboardData(company?.selected?.value, tempCalendarOptions[6].value.from_date, tempCalendarOptions[6].value.to_date)
                    handleLoading(false)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const getPropertyDropdown = (company = []) => {
        let payload = {
            company_ids: [company],
            offset: 0,
            limit: null
        };
        NetworkCall(
            `${config.api_url}/queries/property/list`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            getUnits(company, res?.data?.data?.property?.[0]?.value);
            setUtilityData({
                ...utilityData,
                propertyData: res?.data?.data?.property,
                selected_property: res?.data?.data?.property?.[0],
                selected_unit: "",
            });
        });
    };
    const getUnits = (companyId, propertyId) => {
        const payload = {
            "company_id": companyId,
            "property_id": propertyId,
        };
        NetworkCall(
            `${config.api_url}/unit/property_units`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                setDefaultUtilityUnit(res?.data?.data?.units?.map((val) => {
                    return {
                        value: val?.id,
                        label: val?.name
                    }
                })?.[0])
                getUtilityItems(res?.data?.data?.units?.[0]?.id)
            })

            .catch((error) => {
                console.log(error)
            })
    }
    const getUtilityMeters = (unit_id, utility_id) => {
        const payload = {
            // "search": "",
            "unit_id": unit_id,
            "utility_id": utility_id
        }
        NetworkCall(
            `${config.api_url}/queries/unit_utilities`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                setSelectedMeter(res?.data?.data?.data?.length > 0 ? { label: "All Meters", value: null } : "")
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const getUtilityItems = (unit_id) => {
        const payload = {
            // "search": "",
            "unit_id": unit_id,
        }
        NetworkCall(
            `${config.api_url}/unit_meter_reading/get_utility`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                setDefaultUtilityItem(res?.data?.data?.map((val) => {
                    return {
                        value: val?.value,
                        label: val?.label
                    }
                })?.[0])
                if (res?.data?.data?.[0]?.value) {
                    getUtilityMeters(unit_id, res?.data?.data?.[0]?.value)
                }
                getUtilityGraphData(unit_id, res?.data?.data?.[0]?.value, selectedCalendar?.value?.from_date, selectedCalendar?.value?.to_date)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const getDashboardData = (company_id, from_date, to_date) => {
        const payload = {
            "company_id": company_id,
            "start_date": from_date,
            "end_date": to_date
        };
        NetworkCall(
            `${config.api_url}/utility_manager`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                setData({ ...data, ...res?.data?.data })
            })
            .catch((error) => {
                console.log(error)
            });
    }
    const getUtilityGraphData = (unit_id, utility_id, from_date, to_date, meter_id) => {
        // let tempCalendarOptions = getCalendarOptions()
        const payload = {
            "unit_id": unit_id,
            "utility_id": utility_id,
            start_date: from_date,
            end_date: to_date,
            "unit_util_id": meter_id ?? null
        };
        NetworkCall(
            `${config.api_url}/inspection_manager/reading_graph`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                const result = {
                    "labels": res?.data?.data?.graph_data?.labels,
                    "datasets": [
                        {
                            "label": "",
                            data: res?.data?.data?.graph_data?.datasets?.[0]?.data,
                            fill: true,
                            backgroundColor: "#DDFFEA",
                            borderColor: "#82E0A5"
                        }
                    ]
                }
                setUtilityGraphData({ graphData: result, readings: res?.data?.data?.reading_data })
            })
            .catch((error) => {
                console.log(error)
            })
    }
    //handleCompanyChange
    const handleCompanyChange = (val) => {
        setSelectedCompany(val)
        getDashboardData(val?.value, selectedCalendar.value.from_date, selectedCalendar.value.to_date)
    }
    // Function to change the Calendar
    const handleCalendarChange = (date) => {
        if (date?.load) {
            setSelectedCalendar(date)
            getDashboardData(selectedCompany?.value, date.value.from_date, date.value.to_date)
            getUtilityGraphData(utilityData?.selected_unit?.value?.length > 0 ? utilityData?.selected_unit?.value : defaultUtilityUnit?.value, utilityData?.selected_utility?.value?.length > 0 ? utilityData?.selected_utility?.value : defaultUtilityItem?.value, date?.value?.from_date, date?.value?.to_date, utilityData?.selected_meter?.value)

        }
    }
    const updateUtilityData = (k, v) => {
        if (k === "selected_property") {
            getUnits(selectedCompany?.value, v?.value)
            setUtilityData({ ...utilityData, selected_unit: "", [k]: v })
        }
        else if (k === "selected_unit") {
            getUtilityItems(v?.value)
            setUtilityData({ ...utilityData, [k]: v, selected_utility: "", selected_meter: "" })
        }
        else {
            getUtilityGraphData(utilityData?.selected_unit?.value?.length > 0 ? utilityData?.selected_unit?.value : defaultUtilityUnit?.value, v?.value, selectedCalendar?.value?.from_date, selectedCalendar?.value?.to_date, utilityData?.selected_meter?.value)
            setUtilityData({ ...utilityData, [k]: v })
        }
    }
    // zoom function
    const zoomGraphFunction = (e) => {
        setZoomGraph(e);
        setZoom(true);
    };
    // close popup
    const handleCloseModule = () => {
        setZoom(false);
    };
    const handleMeterChange = (val) => {
        setSelectedMeter(val)
        getUtilityGraphData(utilityData?.selected_unit?.value?.length > 0 ? utilityData?.selected_unit?.value : defaultUtilityUnit?.value, utilityData?.selected_utility?.value?.length > 0 ? utilityData?.selected_utility?.value : defaultUtilityItem?.value, selectedCalendar?.value?.from_date, selectedCalendar?.value?.to_date, val?.value)
    }
    const render = () => {
        return (
            <Box>
                <Subheader
                    hideBackButton={true}
                    title={t("Utility Manager Dashboard")}
                    select options={companyList}
                    value={selectedCompany} onchange={(e) => handleCompanyChange(e)}
                    calanderselect
                    calendarOptions={calendarOptions} calanderValue={selectedCalendar}
                    onChangeCalendar={(e) => handleCalendarChange(e)}
                />
                <Box className={classes.dashboardRoot}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <Box>
                                <Box height="60vh">
                                    <Graphcomponent
                                        t={t}
                                        title={"Utility Category"}
                                        header={t("Utility Category")}
                                        graphData={data?.utilityCategory ?? []}
                                        isPie
                                        margin={"20px"}
                                        minWidth={true}
                                        isZoom
                                        onZoomClick={zoomGraphFunction}
                                        textMargin={'0px 0px 16px'}
                                        divider={
                                            {
                                                xs: 12,
                                                sm: 6,
                                                md: 12,
                                                lg: 12
                                            }
                                        }
                                        divider1={
                                            {
                                                xs: 12,
                                                sm: 6,
                                                md: 12,
                                                lg: 12
                                            }
                                        }
                                        gradiantStyle={{
                                            margin: '15px 0px 0px',
                                            top: {
                                                lg: "auto",
                                                md: '16px',
                                                sm: "0px important",
                                            },
                                            bottom: {
                                                lg: "auto",
                                                md: '16px',
                                                sm: "0px",
                                            },
                                            height: {
                                                md: "400px"
                                            }
                                        }}
                                    />
                                </Box>
                                <Box mt={2}>
                                    <Grid container spacing={2}>
                                        {utilityDetails?.map((x) => {
                                            return (
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <CardDetails data={x} maxHeight={"125px"} t={t} />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <UtilityGraphComponent
                                t={t}
                                title={"Utility Readings"}
                                header={t("Utility Readings")}
                                graphData={utilityGraphData?.graphData}
                                margin={"20px"}
                                isUtility
                                utilityCards={(selectedMeter !== "" && selectedMeter?.label !== "All Meters") ? utilityCards : []}
                                selectedCompany={selectedCompany}
                                utilityData={utilityData}
                                setUtilityData={setUtilityData}
                                width={"auto"}
                                isCustomSpacing={true}
                                alignItems={"start"}
                                height={"100vh"}
                                updateState={updateUtilityData}
                                defaultUtilityUnit={defaultUtilityUnit}
                                defaultUtilityItem={defaultUtilityItem}
                                selectedMeter={selectedMeter}
                                isMeterDropdown={true}
                                handleMeter={handleMeterChange}

                            />
                        </Grid>
                    </Grid>
                </Box>
                {/* zoom dialog for each graph */}
                <DialogDrawer
                    header={t(zoomGraph)}
                    maxWidth={"md"}
                    handleClose={handleCloseModule}
                    open={zoom}
                    height={"auto"}
                    borderRadius={"12px"}
                    padding={'0px'}
                    onClose={handleCloseModule}
                    component={
                        <div>
                            {zoomGraph === "Utility Category" && (
                                <Graphcomponent
                                    graphData={data?.utilityCategory}
                                    isPie
                                    t={t}
                                    margin={"20px"}
                                    padding={"16px"}
                                    maxHeightGraph={"250px"}
                                    minWidth={false}
                                    height={"auto"}
                                    is_popUp
                                    justifyContent={'start'}
                                    divider1={
                                        {
                                            xs: 12,
                                            sm: 5,
                                            md: 5,
                                            lg: 5
                                        }
                                    }
                                    divider={
                                        {
                                            xs: 12,
                                            sm: 7,
                                            md: 7,
                                            lg: 7
                                        }
                                    }

                                    gradiantStyle={{
                                        margin: '0px 0px 0px',
                                        display: "flex",
                                        alignItems: "center",
                                        grid_template: "100%",
                                        maxHeight: "auto",
                                        top: {
                                            lg: "auto",
                                            md: '-8px',
                                            sm: "auto",
                                        },
                                        height: {
                                            md: "auto",
                                            sm: "auto",
                                            lg: "100%"
                                        }
                                    }}
                                />
                            )}
                        </div>
                    }
                />
            </Box>
        )
    }
    return (
        <div>
            {accessCheckRender(render, permission, pageName?.dashboard, loading)}
        </div>
    );
}

export default withTranslation("utilityDashboard")(UtilityManagerDashboard)