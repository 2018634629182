import { makeStyles } from "@mui/styles"
import { FontFamilySwitch, remCalc } from "../../utils"

export const ProjectStyle = makeStyles((theme) => ({
    createBtn: {
        height: "40px",
        fontSize: remCalc(14),
        fontFamily: FontFamilySwitch().semiBold,
    },
    filterButton: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "12px"
    },
    menuBox: {
        "& .MuiPaper-root": {
            marginTop: "12px",
        }
    },
    menuTitle: {
        fontSize: remCalc(14),
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.primary,
        cursor: "pointer"
    },
    menuSubTitle: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().regular,
        color: theme.typography.color.secondary,
        cursor: "pointer"
    },
    stepDivider: {
        height: "2px",
        borderRadius: "12px",
        background: theme.palette.border.main,
        width: "10px"
    },
    selectedStep: {
        height: "20px",
        width: "20px",
        borderRadius: "50%",
        background: theme.palette.primary.main,
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: remCalc(12),
        color: "#fff",
        textAlign: "center"
    },
    unselectedStep: {
        height: "20px",
        width: "20px",
        borderRadius: "50%",
        background: theme.palette.border.secondary,
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: remCalc(12),
        color: theme.typography.color.secondary,
        textAlign: "center"
    },
    typeBox: {
        border: `1px solid ${theme.palette.border.main}`,
        borderRadius: "4px"
    },
    selectedBg: {
        background: theme.palette.info.light
    },
    typeTitle: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
    },
    optionHeader: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.primary
    },
    optionSubTitle: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().regular,
        color: theme.typography.color.secondary,
    },
    drawer: {
        "& .MuiPaper-root": {
            width: (props) => props?.value === 4 ? "calc(100vw - 400px)" : props?.value === 5 ? "calc(100vw - 100px)" : 500
        }
    },
    editdrawer: {
        "& .MuiPaper-root": {
            width: `500px !important`
        }
    },
    hrsLabel: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.tertiary,
    },
    createHead: {
        padding: "16px",
        borderBottom: `1px solid ${theme.palette.border.main}`
    },
    createBody: {
        height: "calc(100vh - 130px)",
    },
    createFooter: {
        padding: "8px 16px"
    },
    nextBtn: {
        height: "40px",
        fontSize: remCalc(14),
        fontFamily: FontFamilySwitch().semiBold,
    },
    prevBtn: {
        height: "40px",
        fontSize: remCalc(14),
        fontFamily: FontFamilySwitch().semiBold,
        "&:hover": {
            background: "transparent"
        }
    },
    createFormBody: {
        height: "calc(100vh - 175px)",
        overflow: "auto"
    },
    overViewTitle: {
        fontSize: remCalc(16),
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
    },
    seeMoreLessTextStyle: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.palette.primary.main,
        cursor: "pointer"
    },
    viewLabel: {
        fontSize: remCalc(14),
        fontFamily: FontFamilySwitch().regular,
        color: theme.typography.color.secondary,
        whiteSpace: "nowrap"
    },
    viewValue: {
        fontSize: remCalc(14),
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.primary,
    },
    viewAvatar: {
        height: "20px",
        width: "20px",
        borderRadius: "50%"
    },
    resourceValue: {
        fontSize: remCalc(12),
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
        background: theme.palette.border.secondary,
        borderRadius: 4,
        padding: "4px 8px",
        width: "fit-content"
    },
    createdByText: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().italic,
        color: theme.typography.color.secondary,
    },
    basedText: {
        background: theme.palette.border.primary,
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.secondary,
        borderRadius: "4px",
        padding: "4px 8px"
    },
    rightBorder: {
        borderRight: `1px solid ${theme.palette.border.secondary}`
    },
    leftBorder: {
        borderLeft: `1px solid ${theme.palette.border.secondary}`
    },
    dot: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        background: theme.palette.background.primary
    },
    viewText: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.palette.primary.main,
        cursor: "pointer"
    },
    secText: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.secondary,
    },
    progressBox: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: "4px",
        padding: "16px",
        marginTop: "8px"
    },
    viewTextBig: {
        fontSize: remCalc(14),
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.palette.primary.main,
        cursor: "pointer"
    },
    tabList: {
        "& .MuiButtonBase-root": {
            padding: "0px 12px !important"
        }
    },
    header: {
        padding: "12px",
        borderBottom: `1px solid ${theme.palette.border.secondary}`
    },
    bottom: {
        padding: '16px',
    },
    body: {
        height: "calc(100vh - 130px)",
        overflow: "auto",
        width: "100%",
    },
    customOptionAvatar: {
        height: "32px",
        width: "32px",
        borderRadius: "4px"
    },
    smCardBox: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: "6px",
        padding: "10px 8px 6px 8px"
    },
    sub: {
        color: theme.typography.color.primary,
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        textTransform: "capitalize",
        "& .ql-editor": {
            padding: "6px 0px",
            textAlign: "start",
            height: "350px"
        }
    }
}))


export const ActualBOMPreviewStyle = makeStyles((theme) => ({
    requestDetailsBox: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        padding: "8px"
    },
    requestTitle: {
        fontSize: "1rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    statusBox: {
        borderRadius: theme.palette.borderRadius,
        fontSize: '0.75rem',
        fontFamily: FontFamilySwitch().semiBold,
        color: "#FFFFFF",
        padding: "5px"
    },
    requestNo: {
        fontSize: "0.875rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().regular,
    },
    requestdot: {
        width: "6px",
        height: "6px",
        backgroundColor: theme.typography.color.secondary,
        borderRadius: "50%"
    },
    BillingAmountTxt: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.tertiary
    },
    itemName: {
        fontSize: "0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().regular,
    },
    viewDetailsTxt: {
        color: "#5078E1",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: "0.875rem",
        cursor: "pointer"
    },
    finalamttext: {
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: "0.875rem",
    }
}))