import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from 'react-router-dom';
import { LoadingSection, TableWithPagination } from "../../../components";
import { Routes } from "../../../router/routes";
import { quotationdataType, quotationheading, quotationpath } from "../../../utils/oppertunityUtils";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
import { NetWorkCallMethods } from "../../../utils";

export const Quotation = ({ t, oppertunity_id = "", permission = {}, setQuotationCount = () => false }) => {
    const [list, setList] = React.useState({
        list: [],
        count: 0
    })
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate()

    //get Quotation list
    const getQuotation = (offset = 0) => {
        NetworkCall(
            `${config.api_url}/queries/opportunity/quotation`,
            NetWorkCallMethods.post,
            {
                id: oppertunity_id,
                offset: offset,
                limit: limit
            },
            null,
            true,
            false
          )
        .then((response) => {
            setList({
                list: response?.data?.data?.quotation?.map((val) => {
                    return {
                        ...val, units: val?.units[0]?.count,
                        period: `${val?.duration} ${val?.periods}`
                    }
                }),
                count: response?.data?.data?.count[0]?.count
            })
            setQuotationCount(response?.data?.data?.count[0]?.count)
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })

    }
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getQuotation(offset, limit)
    }
    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getQuotation(0, value,)
    }
    //initialload
    React.useEffect(() => {
        if (permission?.read) {
            getQuotation(0)
        }
        // eslint-disable-next-line 
    }, [permission])

    const handleIcon = (type, data) => {
        if (type === "view") {
            navigate(Routes.quotation_view + "?id=" + data?.id)
        }
    }


    return (
        <Box >
            {
                loading ? <LoadingSection top={"5vh"} message={t("Loading Quotation...")} /> :
                    <TableWithPagination
                        heading={quotationheading(t)}
                        rows={list?.list}
                        path={quotationpath}
                        showpagination={true}
                        showpdfbtn={false}
                        showexcelbtn={false}
                        showSearch={false}
                        tableType="no-side"
                        handleIcon={handleIcon}
                        handlePagination={handlePagination}
                        handleChangeLimit={handleChangeLimit}
                        totalRowsCount={list?.count}
                        page={page}
                        limit={limit}
                        count="2"
                        dataType={quotationdataType}
                        height={`calc(100vh - 360px)`}
                        view={permission?.read}
                        edit={permission?.update}
                        delete={permission?.delete} />
            }

        </Box>
    )
}