import { KeyboardArrowRight } from "@mui/icons-material"
import { Box, Container, Divider, Grid, Stack, Typography } from "@mui/material"
import {jwtDecode} from 'jwt-decode'
import moment from "moment"
import React, { useCallback, useState } from "react"
import Carousel from "react-elastic-carousel"
import { useLocation } from "react-router-dom"
import ImageViewer from "react-simple-image-viewer"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import { ApartmentIcon } from "../../../assets/propertyBoard/apartmentIcon"
import { AreaIcon } from "../../../assets/propertyBoard/areaIcon"
import { BlockIcon } from "../../../assets/propertyBoard/blockIcon"
import { CalendarIcon } from "../../../assets/propertyBoard/calendarIcon"
import { CommunityIcon } from "../../../assets/propertyBoard/communityIcon"
import { FloorIcon } from "../../../assets/propertyBoard/floorIcon"
import { FurnishIcon } from "../../../assets/propertyBoard/furnishIcon"
import { HospitalIcon } from "../../../assets/propertyBoard/hospitalIcon"
import ParkingIcon from "../../../assets/propertyBoard/parkingIcon"
import { ParkIcon } from "../../../assets/propertyBoard/parksIcon"
import { PurposeIcon } from "../../../assets/propertyBoard/purposeIcon"
import { RestaurantIcon } from "../../../assets/propertyBoard/restaurantIcon"
import { RevenueIcon } from "../../../assets/propertyBoard/revenueIcon"
import { SchoolIcons } from "../../../assets/propertyBoard/schoolIcon"
import { TransportationIcon } from "../../../assets/propertyBoard/transportationIcon"
import { WIFIIcon } from "../../../assets/propertyBoard/wifiIcon"
import { config } from "../../../config"
import { NetworkCall } from "../../../networkcall"
import { assestType, NetWorkCallMethods } from "../../../utils"
import { BathIcon, BedIcon } from "../../propertyFinder4/components"
import { AssetView } from "../../requestView/components"
import { getColor } from "./card"
import { useStyles } from "./style"
import AgreementIcon from "../../../assets/agreementIcon"
import { CircleIcon } from "../../../assets"
import NoDataFound from "../../../assets/noData"
import { HistoryIcon } from "../../../assets/propertyBoard/historyIcon"
import { Stepper } from "./stepper"

const ShareUnit = ({ t = () => false }) => {
    const classes = useStyles()
    const TabData = [{ value: 1, label: "Unit Details" }, { value: 2, label: "Property Details" }]
    const [selectedTab, setSelectedTab] = React.useState(1)
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
    const [data, setData] = useState({})
    const breakPoints = [{ width: 1, itemsToShow: 2 }];
    const search = useLocation().search;
    const token = new URLSearchParams(search).get("token");

    const getIconColor = (val) => {
        switch (val) {
            case "Restaurants":
                return { color: "#FFECEC", icon: <RestaurantIcon /> }
            case "Transportation":
                return { color: "#0000000F", icon: <TransportationIcon /> }
            case "Schools":
                return { color: "#F1F7FF", icon: <SchoolIcons /> }
            case "Hospitals":
                return { color: "#FFF4EB", icon: <HospitalIcon /> }
            default:
                return { color: "#EEF9EE", icon: <ParkIcon /> }
        }
    }
    React.useEffect(() => {
        getDetails()
        //eslint-disable-next-line
    }, [])
    const getDetails = () => {

        const decoded = jwtDecode(token)


        let payload = {
            "unit_id": decoded?.unit_id,
            "client": decoded?.client,
            end_date: decoded?.end_date,
            start_date: decoded?.start_date
        }

        NetworkCall(
            `${config.api_url}/common/get_unit_details`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setData({
                propertyDetails: response?.data?.property_details, unitDetails: response?.data?.unit_details,
                start_date: moment(decoded?.start_date).format("DD MMM YYYY"),
                end_date: moment(decoded?.end_date).format("DD MMM YYYY")
            })
        }).catch((err) => {
            console.log(err)
        });
    }
    // open image viwer
    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);
    // close image viwer
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };


    return (
        <Container maxWidth="sm">
            <Box p={2} className={classes.shareBlockCard}>
                {data?.unitDetails?.unit_block_request?.unit_id &&
                    <Box className={classes.blockBox} mb={2}>
                        <Typography className={classes.unitBlock}>Unit Block Details</Typography>
                        <Box mt={2} display="flex" justifyContent={"space-between"} alignItems="center">
                            <Box>
                                <Typography className={classes.amounttxt}>{data?.unitDetails?.unit_block_request?.prospective_name}</Typography>
                                <Stack mt={0.5} direction={"row"} spacing={1} alignItems="center">
                                    <Typography className={classes.unitDetails}>{data?.unitDetails?.unit_block_request?.status}</Typography>
                                    <Box className={classes.dotunit}></Box>
                                    <Typography className={classes.unitDetails}>{data?.unitDetails?.unit_block_request?.from} to {data?.unitDetails?.unit_block_request?.to}</Typography>
                                </Stack>
                            </Box>
                            <BlockIcon />
                        </Box>
                    </Box>
                }
                <Stack direction="row" spacing={2} className={classes.viewMoreTabs} alignItems={"center"}>
                    <Grid container alignItems={"center"}>
                        {TabData?.map((x) => {
                            return (
                                <Grid item xs={`${12 / Number(TabData?.length)}`} className={selectedTab === x.value ? classes.selectedTab : classes.tabText}
                                    onClick={() => {
                                        setSelectedTab(x.value)
                                    }}>
                                    {x.label}
                                </Grid>
                            )
                        })}
                    </Grid>
                </Stack>

                {selectedTab === 3 ?
                    <Box>


                        <Box mt={2} className={classes.agreementBox}>
                            <Stack direction="row" spacing={0.5} alignItems={"center"}>
                                <AgreementIcon />
                                <Typography className={classes.amenitiesText}>Agreement Details</Typography>
                            </Stack>
                            {
                                data?.unitDetails?.over_lapping_agreement?.length > 0 ?
                                    data?.unitDetails?.over_lapping_agreement?.map((val, index) => {
                                        return (
                                            <Box mt={1}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={4}>
                                                        <Typography className={classes.agreementTxt}>Agreement ID</Typography>
                                                        <Typography className={classes.amounttxt}>{val?.agreement_no}</Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography className={classes.agreementTxt}>From</Typography>
                                                        <Typography className={classes.amounttxt}>{moment(val?.available_from).format("DD MMM YYYY")}</Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography className={classes.agreementTxt}>To</Typography>
                                                        <Typography className={classes.amounttxt}>{moment(val?.available_to).format("DD MMM YYYY")}</Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography className={classes.agreementTxt}>Revenue</Typography>
                                                        <Typography className={classes.amounttxt}>{val?.total_amount ?? 0}</Typography>

                                                    </Grid >
                                                    <Grid item xs={4}>
                                                        <Typography className={classes.agreementTxt}>Contact Name</Typography>
                                                        <Typography className={classes.amounttxt}>{val?.contact_name}</Typography>
                                                    </Grid>
                                                </Grid >
                                                {
                                                    (data?.unitDetails?.over_lapping_agreement?.length - 1) !== index &&
                                                    <Box mt={1} mb={1}>
                                                        <Divider />
                                                    </Box>
                                                }

                                            </Box >
                                        )

                                    }) :
                                    <center>
                                        <NoDataFound />
                                    </center>

                            }


                            {/* <Box mt={1}>
                                    <Stack direction="row" spacing={0.5} alignItems={"center"}>
                                        <ReservationIcon />
                                        <Typography className={classes.amenitiesText}>Reservation Details</Typography>
                                    </Stack>
                                    <Stack mt={1} direction="row" spacing={1} alignItems="center">
                                        <Avatar src={data?.reservationDetails} />
                                        <Box>
                                            <Typography className={classes.amounttxt}>{data?.reservationDetails?.occupant_name}</Typography>
                                            <Typography className={classes.floorTxt}>{` Reserved By ${data?.reservationDetails?.reserved_by}`}</Typography>
                                        </Box>
                                    </Stack>
                                </Box> */}
                        </Box >

                        {data?.unitDetails?.unit_vaccancy?.length > 0 &&
                            <Box mt={1}>
                                <Stack direction="row" spacing={0.5} alignItems={"center"}>
                                    <HistoryIcon />
                                    <Typography className={classes.amenitiesText}>Occupant History</Typography>
                                </Stack>
                                <Box mt={1} p={1}>
                                    <Stepper t={t} data={data?.unitDetails?.unit_vaccancy?.map((x) => {
                                        return {
                                            ...x,
                                            dateRange: `${moment(x?.available_from).format("DD MMM YYYY")} - ${moment(x?.available_to).format("DD MMM YYYY")}`,
                                            icon: <CircleIcon />
                                        }
                                    })} />
                                </Box>
                            </Box>
                        }
                    </Box >
                    :

                    <Box >
                        <Box mt={1}>
                            <AssetView
                                asset={selectedTab === 2 ? data?.propertyDetails?.assets : data?.unitDetails?.data?.unit_assets
                                    ?.filter((x) => x?.asset_type !== assestType?.Videos)
                                    ?.filter((x) => x?.asset_type !== assestType?.Documents)
                                    ?.map((x) => x)}
                                assetAll={selectedTab === 2 ? data?.propertyDetails?.assets : data?.unitDetails?.data?.unit_assets}
                                openImageViewer={openImageViewer}
                                isNormal
                                maxWidth={"sm"}
                            />
                        </Box>
                        <Box mt={1} display="flex" justifyContent={"space-between"} alignItems="center">
                            <Typography className={classes.amounttxt}>{selectedTab === 1 ? data?.unitDetails?.data?.name : data?.propertyDetails?.name}</Typography>
                            {selectedTab === 1 ?
                                <Typography className={classes.amounttxt}>{data?.unitDetails?.unit_pricing?.[0]?.total}</Typography>
                                :
                                <Box className={classes.breakupBox}><Typography className={classes.brkuptxt}>{`${data?.propertyDetails?.unit_count} units available`}</Typography></Box>
                            }
                        </Box>
                        <Box mt={1} display="flex" justifyContent={"space-between"} alignItems="center">
                            <Typography className={classes.unitDetails}>{selectedTab === 1 ? data?.unitDetails?.data?.unit_no : data?.propertyDetails?.property_no}</Typography>
                            {selectedTab === 1 &&
                                <Typography className={classes.subtext} style={{ color: data?.unitDetails?.data?.execution_status === "Occupied" ? "#98A0AC" : getColor(data?.unitDetails?.execution_status)?.color, backgroundColor: data?.execution_status === "Occupied" ? "#E4E8EE" : getColor(data?.unitDetails?.data?.execution_status)?.background }}>{data?.unitDetails?.data?.execution_status}</Typography>
                            }
                        </Box>
                        {selectedTab === 1 &&
                            <>
                                <Stack mt={1} direction="row" spacing={1} alignItems="center">

                                    {data?.unitDetails?.data?.total_bed_rooms &&
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Box><BedIcon /></Box>
                                            <Typography className={classes.floorTxt} noWrap>{`${data?.unitDetails?.data?.total_bed_rooms} Bedrooms`}</Typography>


                                        </Stack>
                                    }
                                    {data?.unitDetails?.data?.total_bed_rooms &&
                                        <Box className={classes.dotunit}></Box>
                                    }
                                    {data?.unitDetails?.unit_pricing?.units?.[0]?.baths &&
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Box><BathIcon /></Box>
                                            <Typography className={classes.floorTxt} noWrap>{`${data?.unitDetails?.unit_pricing?.units?.[0]?.baths} Baths`}</Typography>


                                        </Stack>
                                    }
                                    {data?.unitDetails?.unit_pricing?.units?.[0]?.baths &&
                                        <Box className={classes.dotunit}></Box>
                                    }
                                    {data?.unitDetails?.data?.furnishing &&
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Box><FurnishIcon /></Box>
                                            <Typography className={classes.floorTxt} noWrap>{data?.unitDetails?.data?.furnishing}</Typography>
                                        </Stack>
                                    }
                                    {data?.unitDetails?.data?.carpet_area &&
                                        <Box className={classes.dotunit}></Box>
                                    }
                                    {data?.unitDetails?.data?.carpet_area &&
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Box><AreaIcon /></Box>
                                            <Typography className={classes.floorTxt} noWrap>{`${data?.unitDetails?.data?.carpet_area} ${data?.unitDetails?.data?.uom?.name}`}</Typography>
                                        </Stack>
                                    }
                                </Stack>
                                <Box mt={2} className={classes.amenitiesBox}>
                                    <Grid container spacing={2}>
                                        {data?.unitDetails?.data?.type?.name &&
                                            <Grid item xs={4}>
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <Box><ApartmentIcon /></Box>
                                                    <Typography className={classes.amenitiesText}>{data?.unitDetails?.data?.type?.name}</Typography>
                                                </Stack>
                                            </Grid>
                                        }
                                        {data?.unitDetails?.data?.community &&
                                            <Grid item xs={4}>
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <Box><CommunityIcon /></Box>
                                                    <Typography className={classes.amenitiesText}>{data?.unitDetails?.data?.community}</Typography>
                                                </Stack>
                                            </Grid>
                                        }
                                        {data?.unitDetails?.data?.unit_purpose &&
                                            <Grid item xs={4}>
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <Box><RevenueIcon /></Box>
                                                    <Typography className={classes.amenitiesText}>{data?.unitDetails?.data?.unit_purpose}</Typography>
                                                </Stack>
                                            </Grid>
                                        }
                                        {data?.unitDetails?.data?.orient &&
                                            <Grid item xs={4}>
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <Box><FloorIcon /></Box>
                                                    <Typography className={classes.amenitiesText}>{data?.unitDetails?.data?.orient}</Typography>
                                                </Stack>
                                            </Grid>
                                        }
                                        {data?.unitDetails?.data?.revenue_type &&
                                            <Grid item xs={4}>
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <PurposeIcon />
                                                    <Typography className={classes.amenitiesText}>{data?.unitDetails?.data?.revenue_type}</Typography>
                                                </Stack>
                                            </Grid>
                                        }
                                        {data?.unitDetails?.data?.built_year &&
                                            <Grid item xs={4}>
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <CalendarIcon />
                                                    <Typography className={classes.amenitiesText}>{`${data?.unitDetails?.data?.built_year} build`}</Typography>
                                                </Stack>
                                            </Grid>
                                        }
                                    </Grid>
                                </Box>

                            </>
                        }
                        <Box mt={2} mb={2}>
                            <Divider />
                        </Box>
                        <Box mt={1}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Box><img src="/images/mapIcon.svg" width="24px" alt="mapIcon" /></Box>
                                <Typography className={classes.floorTxt}>{selectedTab === 2 ? `${data?.propertyDetails?.address?.door_no},${data?.propertyDetails?.address?.name},${data?.propertyDetails?.address?.name},${data?.propertyDetails?.address?.city},${data?.propertyDetails?.address?.state},${data?.propertyDetails?.address?.country}` :
                                    `${data?.unitDetails?.data?.address?.door_no},${data?.unitDetails?.data?.address?.name},${data?.unitDetails?.data?.address?.name},${data?.unitDetails?.data?.address?.city},${data?.unitDetails?.data?.address?.state},${data?.unitDetails?.data?.address?.country}`}</Typography>
                            </Stack>
                        </Box>
                        <Box mt={2} mb={2}>
                            <Divider />
                        </Box>
                        {data?.unitDetails?.data?.amenities?.length > 0 &&
                            <>
                                <Box mt={1}>
                                    <Typography className={classes.amenitiesText}>AMENITIES</Typography>
                                    <Grid container spacing={1}>
                                        {data?.amenities?.map((x) => {
                                            return (
                                                <Grid item xs={3} alignItems="center">
                                                    <Stack mt={1} className={classes.amenitiesDataBox} direction={"row"} spacing={1} alignItems="center">
                                                        <Box>{x === "Free WiFi" ? <WIFIIcon /> : x === "Car Parking" ? <ParkingIcon /> : <ParkingIcon />}</Box>
                                                        <Typography className={classes.floorTxt} noWrap>{x}</Typography>
                                                    </Stack>
                                                </Grid>

                                            )
                                        })}
                                    </Grid>
                                </Box>
                                <Box mt={2} mb={2}>
                                    <Divider />
                                </Box>
                            </>
                        }
                        {selectedTab === 1 &&
                            <>
                                <Box mt={1}>
                                    <Typography className={classes.amenitiesText}>BREAKUP</Typography>
                                    <Box mt={1} className={classes.breakupBox}>
                                        <Typography className={classes.brkuptxt}>This Break Up is {data?.start_date} to {data?.end_date}</Typography>
                                    </Box>
                                </Box>
                                <Box mt={2}>
                                    {
                                        data?.unitDetails?.unit_pricing?.units?.[0]?.pricing?.map((val) => {
                                            return (
                                                <Box mt={1} display="flex" justifyContent={"space-between"}>
                                                    <Typography className={classes.brkupdata}>{val?.rentalBreakup}</Typography>
                                                    <Typography className={classes.brkupdata}>{val?.amount}</Typography>
                                                </Box>

                                            )
                                        })
                                    }

                                </Box>
                            </>
                        }
                        {selectedTab === 2 &&
                            <>
                                <Box mt={1}>
                                    <Typography className={classes.amenitiesText}>LOCATION & NEARBY</Typography>
                                </Box>
                                <Box mt={1}>
                                    <Carousel showArrows={false} breakPoints={breakPoints}>

                                        {data?.nearby?.map((x) => {
                                            return (

                                                <Box className={classes.nearbyBox}>
                                                    <Box className={classes.iconBox} display="flex" justifyContent={"center"} style={{ backgroundColor: getIconColor(x)?.color }} alignItems="center">
                                                        {getIconColor(x)?.icon}
                                                    </Box>
                                                    <Box className={classes.nearybyTitleBox} display="flex" justifyContent={"space-between"} alignItems="center">
                                                        <Box>
                                                            <Typography className={classes.amounttxt}>{x}</Typography>
                                                            <Typography className={classes.floorTxt}>{"Near this Property"}</Typography>
                                                        </Box>
                                                        <KeyboardArrowRight className={classes.arrowIcon} />
                                                    </Box>
                                                </Box>


                                            )
                                        })}

                                    </Carousel>
                                </Box>
                            </>
                        }
                    </Box>
                }

            </Box >


            {
                isViewerOpen && (
                    <ImageViewer
                        src={selectedTab === 2 ? data?.propertyDetails?.assets : data?.unitDetails?.data?.unit_assets}
                        currentIndex={currentImage}
                        onClose={closeImageViewer}
                        disableScroll={false}
                        backgroundStyle={{
                            backgroundColor: "rgba(0,0,0,0.9)",
                        }}
                        closeOnClickOutside={true}
                    />
                )
            }
        </Container >
    )
}
export default ShareUnit