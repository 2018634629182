import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";
export const AddressCardStyles = makeStyles((theme) => ({
    addresscardBody: {
        border: "1px solid #071741",
        backgroundColor: "white",
        padding: "12px 12px 12px 0px",
        borderRadius: "4px",
        width: '330px'
    },
    firstSection: {
        display: "flex",
        justifyContent: "space-between"
    },
    subsection1: {
        display: "flex",
        justifyContent: "space-between"
    },
    button: {
        backgroundColor: "#071741",
        color: "white",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        height: "19px",
    },
    buttonText: {
        fontSize:"0.75rem",
        textTransform: "uppercase",
        textAlign: "left",
        fontFamily: FontFamilySwitch().bold,
        // letterSpacing: "0.4px",
        padding: "5px 10px",
    },
    edit: {
        color: "#5078E1",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize:"0.75rem",
        cursor: 'pointer'
    },
    userInfo: {
        fontFamily: FontFamilySwitch().bold,
        color: "#091B29",
        fontSize:"0.875rem",
        marginLeft: "10px",
    },
    userAddressText: {
        fontFamily: FontFamilySwitch().semiBold,
        color: "#98A0AC",
        fontSize:"0.75rem"
    },
    addressList: {
        fontSize:"0.75rem",
        color: "#98A0AC",
        fontFamily: FontFamilySwitch().semiBold,
        margin: "10px 10px 0px 10px",
    }


}))