import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const DetailsCardStyle = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.tertiary1,
        border: `1px solid ${theme.palette.border.secondary}`,
        boxShadow: "0px 0px 16px #00000014",
        borderRadius: theme.palette.borderRadius,
        padding: "8px 12px 8px 12px"
    },
    cardHeader: {
        // display: "flex",
        // justifyContent: "space-between"
    },
    title: {
        color: theme.typography.color.tertiary,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        // letterSpacing: "0.24px",
        textTransform: "capitalize"
    },
    status: {
        backgroundColor: theme.palette.warning.light,
        borderRadius: theme.palette.borderRadius,
        border: `1px solid ${theme.palette.border.tertiary}`,
        color: theme.typography.status.secondary,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        padding: "2px 8px",
        textAlign: "center",
        marginTop: "-6px",
        textTransform: "capitalize"
    },
    Reassign: {
        textTransform: "upperCase",
        color: theme.typography.status.primary,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        cursor: "pointer"
    },
    viewMore: {
        color: theme.typography.status.primary,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        // textTransform: "uppercase",
        // letterSpacing: "0.24px",
        cursor: "pointer"
    },
    subHeader: {
        display: "flex",
        marginTop: "10px",
        justifyContent: "space-between"
    },
    info: {
        display: "flex",
    },
    Name: {
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
    },
    description: {
        color: theme.typography.color.tertiary,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().regular,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        // overflow: "hidden",
        width: "90%",
    },


    descriptionNo: {
        color: theme.typography.color.tertiary,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().regular,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        // overflow: "hidden",
        width: "90%",
        direction: 'ltr',
        marginInlineStart: "12px"
    },

    count: {
        borderRadius: "50%",
        backgroundColor: theme.palette.background.light,
        fontSize:"0.75rem",
        color: theme.typography.status.tertiary,
        padding: "5px",
        textAlign: "center",
        width: "28px",
        height: "28px",
        fontFamily: FontFamilySwitch().extraBold,
    },
    infodata: {
        marginInlineStart: "15px"
    },
    alt_divider: {
        display: "none"
    },
    divider: {
        border: `1px solid ${theme.palette.border.secondary}`
    },
    btnsend: {
        backgroundColor: "#F1F7FF",
        borderRadius: theme.palette.borderRadius,
        color: theme.palette.primary,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        "&:hover": {
            backgroundColor: "#F1F7FF",
        },
        marginTop: "-10px"
    },
    stepperdiv: {
        marginTop: "20px"
    },
    gatePass: {
        backgroundColor: theme.palette.background.light,
        borderRadius: theme.palette.borderRadius,
        padding: "10px 12px 2px"
    },
    tenant: {
        backgroundColor: "#FFF9DD",
        borderRadius: theme.palette.borderRadius,
        width: "40px",
        height: "40px",
        textAlign: "center",
        paddingTop: "10px"
    },
    track: {
        marginTop: "10px"
    },
    image: {
        width: "45px",
        height: "45px",
        borderRadius: theme.palette.borderRadius
    },
    newdescription: {
        color: theme.typography.color.tertiary,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().regular,
    },
    detailsDrop: {
        left: 0,
        right: 0,
        top: 30,
        padding: "12px",
        borderRight: "1px solid #E4E8EE",
        borderLeft: "1px solid #E4E8EE",
        borderBottom: "1px solid #E4E8EE",
        borderRadius: "4px",
        zIndex: 999
    },
    activeText: {
        marginTop: "-10px",
        color: "#96DBAE",
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        "&:hover": {
            backgroundColor: "#FFFFFF",
        },
    }
}))