import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch, remCalc } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundColor: "white",
  },
  grid: {
    height: ({ size }) => size?.height,
    alignItems: "center",
  },
  left_section: {
    height: ({ size }) => size?.height,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
    padding: "16px",
    paddingInlineEnd: "0px",
  },
  web_background: {
    height: ({ size }) => size?.height - 32,
    width: "100%",
    borderRadius: theme.palette.borderRadius_2,
  },
  right_section: {
    height: ({ size }) => size?.height,
    alignItems: "center",
    display: "flex",
    justifyContent: "end",
  },
  web_right_section_card: {
    display: "contents",
    height: ({ size }) => size?.height,
    padding: "0px",
  },
  web_back_button: {
    cursor: "pointer",
    height: "32px",
    width: "32px",
  },
  web_content: {
    height: "100vh",
    overflow: "overlay",
    display: "flex",
    position: "relative",
    padding: "16px",
    backgroundColor: "white",
  },
  web_logo: {
    height: "32px",
    objectFit: "contain",
  },
  web_title: {
    fontSize: remCalc(16),
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },
  web_description: {
    fontSize: remCalc(16),
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary,
  },
  web_forget_password: {
    fontSize: remCalc(12),
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.primary_2,
    textAlign: "end",
    cursor: "pointer",
  },
  web_login_button: {
    height: "48px",
    width: "100%",
    borderRadius: theme.palette.borderRadius_2,
    boxShadow: "0px 6px 10px #00000014",
    backgroundColor: theme.palette.background.button_background_1,
    border: "1px solid #5078E1",
  },
  web_login_button_text: {
    fontSize: remCalc(14),
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.white,
  },
  web_log_in_button: {
    height: "48px",
    width: "100%",
    borderRadius: theme.palette.borderRadius_2,
    boxShadow: "0px 6px 10px #00000014",
    border: "1px solid #5078E1",
    "&:hover": {
      backgroundColor: theme.palette.background.tertiary1,
    },
  },
  web_log_in_button_text: {
    fontSize: remCalc(14),
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.primary_2,
  },
  mob_right_section_card: {
    padding: "0px",
  },
  // mob_content: {
  //     height: "calc(100vh - 54px)",
  //     overflow: "overlay",
  // },
  mob_body_items: {
    padding: "16px",
  },
  mob_background: {
    height: "235px",
    borderRadius: theme.palette.borderRadius,
  },
  mob_background_image: {
    height: "235px",
    width: "100%",
    borderRadius: theme.palette.borderRadius,
  },
  mob_bottom_items: {
    padding: "16px",
  },
  mob_logo: {
    height: "32px",
    objectFit: "contain",
  },
  mob_description: {
    fontSize: remCalc(20),
    fontFamily: FontFamilySwitch().semiBold,
    color: "#FEF4F4",
    textShadow: "0px 6px 10px #00000033",
  },
  mob_set_up_new_button: {
    height: "48px",
    width: "100%",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 6px 10px #00000014",
    backgroundColor: theme.palette.background.button_background_1,
    border: "1px solid #5078E1",
  },
  mob_set_up_new_button_text: {
    fontSize: remCalc(14),
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.white,
  },
  mob_log_in_button: {
    height: "48px",
    width: "100%",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 6px 10px #00000014",
    backgroundColor: "#ffffff75",
    "&:hover": {
      backgroundColor: "#ffffff75",
    },
  },
  mob_log_in_button_text: {
    fontSize: remCalc(14),
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.white,
  },
  mob_terms_of_use_description: {
    width: "100%",
    fontSize: remCalc(12),
    fontFamily: FontFamilySwitch().regular,
    color: theme.typography.color.white,
  },
  mob_terms_of_use_word: {
    fontSize: remCalc(12),
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.white,
    textDecoration: "underline",
    display: "inline",
    cursor: "pointer",
  },
  version_no: {
    fontSize: remCalc(12),
    fontFamily: FontFamilySwitch().regular,
    color: theme.typography.color.tertiary,
  },
  terms_of_use_word: {
    fontSize: remCalc(12),
    fontFamily: FontFamilySwitch().regular,
    color: theme.typography.color.tertiary,
    cursor: "pointer",
  },
  powered_by_logo: {
    height: "11px",
    objectFit: "contain",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      minWidth: ({ size }) => size?.width > 599 && "500px",
      maxWidth: ({ size }) => size?.width > 599 && "500px",
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  drawer_header_grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: ({ size }) => size?.width > 599 ? "start" : "end",
    padding: "16px",
    backgroundColor: "#F2F4F7"
  },
  drawer_header_text: {
    marginLeft: "12px",
    fontSize: remCalc(16),
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary,
  },
  drawer_content_box: {
    height: ({ size }) => size?.width > 599 ? "calc(100vh - 64px)" : "450px",
    overflow: "overlay",
    padding: "16px",
  },
  drawer_button_box: {
    padding: "16px",
    borderTop: `1px solid ${theme.palette.border.secondary}`
  },
  region_box: {
    cursor: "pointer",
    height: "40px",
    padding: "7px 4px 7px 8px",
    display: "flex",
    alignItems: "center",
    border: "1px solid #E4E8EE",
    borderRadius: "20px",
    columnGap: "2px",
  },
  region_flag: {
    borderRadius: "50%",
    objectFit: "contain",
    height: "18px",
    width: "18px",
  },
  region_menu_list: {
    "& .MuiPopover-paper": {
      maxHeight: "250px",
      minWidth: "200px",
      marginTop: "10px",
      padding: "8px 16px",
      boxShadow: "0 0px 8px -4px #f2f3f5",
      border: "1px solid #f2f3f5",
      borderRadius: "5px",
    },
  },
  selected_region_menu_item: {
    border: "1px solid #5078E1",
    borderRadius: theme.palette.borderRadius,
    padding: "12px 5px",
    backgroundColor: "#F1F7FF",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  unselected_region_menu_item: {
    borderRadius: theme.palette.borderRadius,
    padding: "12px 5px",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  selected_region_name: {
    fontSize: remCalc(14),
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.selected_1
  },
  unselected_region_name: {
    fontSize: remCalc(14),
    fontFamily: FontFamilySwitch().regular,
    color: theme.typography.color.primary
  },
}));