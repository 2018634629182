import { Box, Fade, Stack, Typography } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { InvoiceDropdown } from "../../../components";
import { AggrementComponentStyles } from "./styles";
import {convertTimeUtcToZoneCalander} from '../../../utils';

const InvoiceDetailCard = ({ data = {},tabledetails={}, t }) => {
    
    const classes = AggrementComponentStyles()
    const [open, setOpen] = React.useState(false)
    return (
        <Box className={classes.detailCardRoot}>
            <Box className={classes.section1}>
                <Typography className={classes.label}>{t("invoice_details")}</Typography>
                <Stack direction="row" justifyContent="space-between">
                    <Typography className={classes.postCardTitle}>{data?.invoice_no}</Typography>
                    <Typography
                        sx={{ color: data?.payment_status === "no-due" ? "#5AC782" : "#FF4B4B", backgroundColor: data?.payment_status === "no-due" ? "#EEF9EE" : "#FFECEC" }}
                        className={classes.status}>{data?.payment_status}</Typography>
                </Stack>
                <Typography className={classes.paymentScheduletitle}>{data?.invoice_type}</Typography>
                <Stack marginTop="6px" direction="row" spacing={1} alignItems="center">
                    <Typography className={classes.label1}>{t("generated_on")} {convertTimeUtcToZoneCalander(data?.invoice_date)}</Typography>
                    <Box className={classes.dot} />
                    <Typography className={classes.label1}>{t("dueDate")} {convertTimeUtcToZoneCalander(data?.payment_due_date)}</Typography>
                </Stack>
            </Box>
            <Box padding="12px 12px 8px 12px">
                {
                    open &&
                    <Fade in={open}>
                        <Box>
                            <Stack direction="row" justifyContent="space-between" >
                                <Typography className={classes.quoDtlCardDate}>{t("invoice_amount")}</Typography>
                                <Typography className={classes.quoDtlCardDate}> {data?.currency_master?.symbol} {data?.invoice_total_amount - data?.invoice_total_tax_amount}</Typography>
                            </Stack>
                            <Stack marginTop="8px" direction="row" justifyContent="space-between" >
                                <Typography className={classes.quoDtlCardDate}>{t("tax")}</Typography>
                                <Typography className={classes.quoDtlCardDate}> {data?.currency_master?.symbol} {data?.invoice_total_tax_amount ?? 0}</Typography>
                            </Stack>
                        </Box>
                    </Fade>
                }



                <Stack marginTop="8px" direction="row" marginBottom="12px" justifyContent="space-between" className={classes.totalSection}>
                    <Typography sx={{ fontSize:"0.75rem" }}>{t("total_invoice_amount")}</Typography>
                    <Typography sx={{ fontSize:"0.75rem" }}>{data?.currency_master?.symbol} {data?.invoice_total_amount}</Typography>
                </Stack>
                {
                    // data?.payment_status === "due" &&
                    <div>
                        {/* <Stack marginTop="8px" marginBottom="12px" direction="row" justifyContent="space-between" className={classes.totaltaxSection}>
                            <Typography sx={{ fontSize:"0.75rem" }}>{t("parcial_amount")}</Typography>
                            <Typography sx={{ fontSize:"0.75rem" }}>{data?.currency_master?.symbol} {data?.invoice_total_amount - data?.invoice_due_amount ?? 0}</Typography>
                        </Stack>
                         */}
                        <InvoiceDropdown partialAmount={data?.invoice_total_amount - data?.invoice_due_amount ?? 0} 
                        invoiceData={tabledetails} symbol={data?.currency_master?.symbol} />
                    </div>
                }

                <center>
                    <Typography className={classes.postCardStatus} onClick={() => setOpen(!open)}>{t("view")} {open ? `${t("less")}` : `${t("more")}`}</Typography>
                </center>
            </Box>
        </Box>
    )
}
export default withTranslation("agreement")(InvoiceDetailCard); 