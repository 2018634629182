import React from "react";
import { Dialog, DialogTitle, DialogContent, Typography, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ChequeImageViewer = ({ open, onClose, image, chequeNo, issuedBy, onCloseButtonClick }) => {
  return (
      <Dialog open={open} onClose={onClose} 
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '8px',
        }
      }}
      >
        <DialogTitle fontWeight={700} fontSize={14}>
          CHEQUE VIEW
          <IconButton
            aria-label="close"
            onClick={onCloseButtonClick}
            sx={{ 
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 5 }}>
            <Typography fontSize={14}>Cheque Number: { chequeNo? chequeNo : "-"}</Typography>
            <Typography fontSize={14}>Issued By: {issuedBy ? issuedBy : "-"}</Typography>
          </Box>
          <Box>
            { image && image.length > 0 &&
            <img
              src={image}
              alt="Cheque Image"
              style={{
                width: "100%",
                marginTop: "16px",
                border: "1px solid rgb(179, 179, 179)",
              }}
            />
            }
          </Box>
        </DialogContent>
      </Dialog>
  );
};

export default ChequeImageViewer;
