import { Avatar, Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { stringAvatar, timeZoneConverter } from "../../utils"
import { FontFamilySwitch } from "../../utils";
import ImageViewer from "react-simple-image-viewer";
const useStyles = makeStyles((theme) => ({
  card: {

    backgroundColor: theme.palette.background.paper,
    margin: "auto 8px",
  },
  Cardcontent: {
    padding: "8px 12px",
    borderBottom: `1px dashed ${theme.palette.border.secondary}`,
  },
  title: {
    fontSize:"1rem",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().bold,
  },
  progress: {
    fontSize:"0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#78B1FE",
    padding: "1px 8px",
    borderRadius: theme.palette.borderRadius,
  },
  approved: {
    fontSize:"0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: theme.palette.borderRadius,
  },
  delete: {
    fontSize:"0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "red",
    padding: "1px 8px",
    borderRadius: theme.palette.borderRadius,
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  sub: {
    fontSize:"0.75rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().regular,
    textTransform: "capitalize",
  },
  view: {
    cursor: "pointer",
    fontSize:"0.75rem",
    color: "#5078E1",
    fontFamily: FontFamilySwitch().regular,
    textTransform: "capitalize",
  },
  popupimg: {
    '& .styles-module_close__2I1sI': {
      color: 'white',
      position: 'absolute',
      top: '67px',
      right: '100px',
      fontSize: '40px',
      fontWeight: 'bold',
      opacity: 0.2,
      cursor: 'pointer',
    }
  },
}));

export const VisiterCard = (props) => {
  const classes = useStyles(props);
  const [isViewerOpen, setIsViewerOpen] = React.useState(false)

  return (
    <>
      <div className={classes.card}>
        <div className={classes.Cardcontent}>
          <Box alignItems="center" display="flex">
            <Box>
              <Avatar src={props?.data?.is_registered_worker ? props?.data?.domestic_helpers?.visitor_image : props?.data?.visitor_image}
                onClick={() => (props?.data?.domestic_helpers?.visitor_image || props?.data?.visitor_image) ? setIsViewerOpen(true) : false} style={{ cursor: "pointer" }}
                {...stringAvatar(props?.data?.is_registered_worker ? props?.data?.domestic_helpers?.name : props?.data?.name)} />
              {isViewerOpen && (
                <ImageViewer
                  src={[props?.data?.is_registered_worker ? props?.data?.domestic_helpers?.visitor_image : props?.data?.visitor_image]}
                  currentIndex={0}
                  onClose={() => setIsViewerOpen(false)}
                  disableScroll={false}
                  backgroundStyle={{
                    backgroundColor: "rgba(0,0,0,0.9)",
                  }}
                  className={classes.popupimg}
                  closeOnClickOutside={true}
                />
              )}
            </Box>

            <Box flexGrow={1} marginInlineStart="10px">
              <Typography variant="subtitle2" className={classes.title}>
                {props?.data?.is_registered_worker ? props?.data?.domestic_helpers?.name : props?.data?.name}
              </Typography>
              <Box alignItems="center" display="flex" marginTop="4px">
                {props?.data?.is_registered_worker ?
                  (props?.data?.domestic_helpers?.id_type && <>
                    <Box>
                      <Typography variant="subtitle2" className={classes.proof}>
                        {props?.data?.domestic_helpers?.id_type}&nbsp;({props?.data?.domestic_helpers?.identification_no})
                      </Typography>
                    </Box>
                    <Box className={classes.dot} />
                  </>) :
                  (props?.data?.idtype && <>
                    <Box>
                      <Typography variant="subtitle2" className={classes.proof}>
                        {props?.data?.idtype}&nbsp;({props?.data?.identification_no})
                      </Typography>
                    </Box>
                    <Box className={classes.dot} />
                  </>)}
                <Box onClick={() => props.onClick(props.data)}>
                  <Typography
                    variant="subtitle2"
                    className={classes.view}
                    noWrap
                  >
                    View Details
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          {props?.isEntries && (<Box alignItems="center" display="flex">
            <Grid container xs={6} marginTop="10px">
              <Box flexGrow={1} marginInlineStart="10px">
                <Typography style={{ fontSize:"0.75rem", fontFamily: FontFamilySwitch().semiBold, color: "#98A0AC" }}>
                  {"Checked In"}
                </Typography>
                <Box alignItems="center" display="flex" marginTop="4px">
                  <Box>
                    <Typography style={{ fontSize:"0.75rem", fontFamily: FontFamilySwitch().semiBold, color: "#091B29" }}>
                      {(props.data.check_in_by ? (props.data.check_in_by + ", ") : "") + (props.data.check_in_time ? timeZoneConverter(props.data.check_in_time, "DD MMM, hh:mm") : "")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid container xs={6}>
              <Box flexGrow={1} marginInlineStart="10px">
                <Typography style={{ fontSize:"0.75rem", fontFamily: FontFamilySwitch().semiBold, color: "#98A0AC" }}>
                  {"Checked Out"}
                </Typography>
                <Box alignItems="center" display="flex" marginTop="4px">
                  <Box>
                    <Typography style={{ fontSize:"0.75rem", fontFamily: FontFamilySwitch().semiBold, color: "#091B29" }}>
                      {(props.data.check_out_by ? (props.data.check_out_by + ", ") : "") + (props.data.check_out_time ? timeZoneConverter(props.data.check_out_time, "DD MMM, hh:mm") : "")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Box >)}
        </div >
      </div >
    </>
  );
};
