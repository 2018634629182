import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const unitStyles = makeStyles((theme) => ({
  root: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    margin: "16px",
    height: `calc(100vh - 150px)`,
  },
  table: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
    //height: `calc(100vh - 147px)`,
    overflow: "hidden",
  },
  img: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "12px",
    marginLeft: "16px"
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: "none",
    fontSize:"0.875rem",
  },
  drawerHeaderStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerHeaderTextStyle: {
    fontSize:"1rem",
    fontWeight: "bolder",
    color: theme.typography.color.primary,
  },
  grid: {
    justifyContent: "center",
  },
  child: {
    margin: "5% 0% 0% 50%",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      padding: "12px 0px 0px 12px",
    },
  },
  signupform: {
    padding: "0px 12px 0px 12px",
    backgroundColor: "white",
    boxShadow: "0px 8px 69px #0000001A",
    borderRadius: theme.palette.borderRadius,
    marginTop: "20%",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      marginTop: "6%",
    },
  },
  text: {
    fontSize:"1.5rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary,
    marginTop: "0",
  },
  verificationText: {
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().regular,
    textAlign: "center",
    color: theme.typography.color.primary,
    backgroundColor: theme.palette.background.secondary,
    borderRadius: theme.palette.borderRadius,
    padding: "20px",
  },
  poweredby: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    fontSize:"0.75rem",
    color: theme.typography.color.tertiary,
  },
  pa: {
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().semiBold,
    fontSize:"0.75rem",
  },
  companyName: {
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().bold,
    fontSize:"0.875rem",
    marginLeft: "12px",
  },
  companySelect: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  comapnyImage: {
    width: 50,
    height: 50,
    borderRadius: 0,
  },
  selectCompany: {
    padding: "14px",
    boxShadow: "0px 3px 30px #5C86CB2E",
    margin: "8px 0px",
    borderRadius: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    width: "100%",
  },
  selectCompanyBorder: {
    padding: "14px",
    boxShadow: "0px 3px 30px #5C86CB2E",
    margin: "8px 0px",
    borderRadius: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: `1px solid ${theme.palette.primary.main}`,
    cursor: "pointer",
    width: "100%",
  },
  list: {
    overflow: "auto",
    padding: "0px 20px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  content: {
    padding: "22px 20px",
  },
  logoIcon: {
    position: "fixed",
    top: "36px",
    left: "36px",
  },
  filterimg: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    marginLeft: "16px",
    padding: "12px",
  },
  propertyDeatils: {
    color: "#091B29",
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().bold,
  },
  catagory: {
    color: "#98A0AC",
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  catagoryName: {
    color: "#091B29",
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
  },
  marginLeft: {
    marginLeft: "10px",
    display: "flex",
    alignItems: "center",
  },
  divider: {
    height: "20px",
  },
  chip: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    padding: "5px",
    borderRadius: 4,
  },
  addressDeatils: {
    color: "#4E5A6B",
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().regular,
    marginInlineStart: "7px",
    direction: "ltr",
    textAlign: "-webkit-match-parent"
  },
  addressDeatils2: {
    color: "#4E5A6B",
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().regular,
    marginInlineStart: "7px",
    width: "auto", 
    whiteSpace: "nowrap", 
    textOverflow: "ellipsis", 
    overflow: "hidden"
  },
  addressDetailsBox: {
    padding: "12px",
    borderBottom: "1px solid #E4E8EE",
  },
  addressBoxFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  detailsContainer: {
    borderInlineStart: "1px solid #E4E8EE",
  },
  detailsText: {
    color: "#4E5A6B",
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().regular,
    marginLeft: "7px",
  },
  count: {
    color: "#000",
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().bold,
  },
  detailsFlexBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px",
    borderBottom: "1px solid #E4E8EE",
  },
  parentDetailsFlexBox: {
    backgroundColor: "#f5f7fa",
    padding: "16px",
    marginTop: "15px",
  },
  graftParent: {
    border: "1px solid #E4E8EE",
    borderRadius: 4,
    padding: "10px",
    margin: "16px 0px 2px",
    borderBottom: "1px solid #E4E8EE",
  },
  dot: {
    borderRadius: "50%",
    width: "6px",
    height: "6px",
    background: "#CED3DD",
    marginRight: "7px",
  },
  amenitieslist: {
    border: "4px",
    backgroundColor: "#F5F7FA",
    color: "#000",
    fontFamily: FontFamilySwitch().bold,
    fontSize:"0.875rem",
    padding: "10px",
  },
  aminitiesParent: {
    borderTop: "1px solid #E4E8EE",
  },
  aminitiesDeatils: {
    color: "#091B29",
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().bold,
    margin: "10px 0px",
  },
  revenueParent: {
    borderRadius: "4px",
    backgroundColor: "#F5F7FA",
    padding: "10px 15px",
    marginTop: "15px",
  },
  revenueBorder: {
    borderTop: "1px solid #E4E8EE",
    marginTop: "16px",
  },
  textCenter: {
    textAlign: "center",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
  },
  totalCount: {
    color: "#091B29",
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().bold,
    marginLeft: "10px",
  },
  graphPadding: {
    padding: "20px 0px",
  },
  paddingTop: {
    paddingTop: "16px",
  },
  marginTop: {
    // marginTop: "16px",
    borderTop: "1px solid #E4E8EE",
  },
  description: {
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().regular,
    fontSize:"0.75rem",
  },
  borderBottom: {
    borderBottom: "1px solid #E4E8EE",
    paddingBottom: "10px",
  },
  bathDetails: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px",
  },
  bedText: {
    color: "#091B29 ",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize:"0.75rem",
    marginInlineStart: "7px",
  },
  amountCard: {
    borderRadius: "4px",
    padding: "10px",
    backgroundColor: "#F5F7FA",
  },
  amountTitle: {
    color: "#86a6d3",
    fontFamily: FontFamilySwitch().regular,
    fontSize:"0.75rem",
  },
  amountContant: {
    color: "#000",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize:"0.875rem",
  },
  amountParnt: {
    padding: "16px 0px",
    borderTop: "1px solid #E4E8EE",
  },
  totalMountBox: {
    padding: "10px 0px",
    borderTop: "1px dashed #E4E8EE",
    marginTop: '10px'
  },
  pricingTabelParent: {
    borderTop: "1px solid #E4E8EE",
  },
  shortlistbtn: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: "none",
    fontSize:"0.875rem",
    width: '100%'
  },
  addressflexBox: {
    display: "flex",
    paddingBottom: '3px',
    alignItems: "center"
  },
  detailsub: {
    fontFamily: FontFamilySwitch().regular,
    color: "#4E5A6B",
    fontSize:"0.875rem",
    padding: '10px 0px 0px'

  },
  seeMoreLessTextStyle: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.palette.primary.main,
  },
  pricingBoxFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: '9px'
  },
  noFound: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: `calc(100vh - 250px)`
  },
  noData: {
    color: '#4E5A6B',
    margin: 0,
    overflow: 'hidden',
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().bold,
    fontWeight: 'bold',
  },
  unitVacancyParent: {
    marginTop: "16px",
    borderTop: "1px solid #E4E8EE",
  },
  pricing_table_label: {
    color: "#4E5A6B",
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().regular,
  },
}));
