import { Dialog, Grid } from "@mui/material";
import React, { useState } from "react";
import { Slider } from "../../../components";
import { Assets } from "./assets";
import { filterStyles } from "./styles";

export const AssetView = ({
  asset,
  assetAll,
  title,
  isNormal = false,
  maxWidth = "md",
}) => {
  const classes = filterStyles();
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0); // Store selected index

  const openPopup = (index) => {
    setSelectedIndex(index); // Set the selected asset index
    setOpen(true);
  };

  return (
    <>
      {asset?.length === 1 && (
        <Grid container spacing={1}>
          <Grid item xs={12} onClick={() => openPopup(0)}>
            {" "}
            {/* Ensure index 0 */}
            <img src={asset[0]?.url} alt="" className={classes.detailImg} />
          </Grid>
        </Grid>
      )}
      {asset?.length > 1 && (
        <Grid container spacing={1}>
          <Grid item lg={7} xs={7} onClick={() => openPopup(0)}>
            {" "}
            {/* First image */}
            <img src={asset[0]?.url} alt="" className={classes.detailImg} />
          </Grid>
          <Grid item lg={5} xs={7}>
            <Grid container spacing={1}>
              {asset.slice(1, 5).map((item, index) => (
                <Grid
                  item
                  xs={6}
                  key={index}
                  onClick={() => openPopup(index + 1)}
                >
                  {" "}
                  {/* Correct index */}
                  <img
                    src={item?.url ?? "/images/imagesproperty.svg"}
                    alt=""
                    className={classes.detailImg2}
                  />
                </Grid>
              ))}
              {/* Ensure placeholders are still shown for less than 5 images */}
              {Array.from({ length: Math.max(0, 5 - asset.length) }).map(
                (_, index) => (
                  <Grid item xs={6} key={`placeholder-${index}`}>
                    <img
                      src="/images/imagesproperty.svg"
                      alt=""
                      className={classes.detailImg2}
                    />
                  </Grid>
                )
              )}
              {asset?.length > 5 && (
                <Grid item xs={6} onClick={() => openPopup(4)}>
                  <div className={classes.addImg}>
                    <span className={classes.plus}>
                      +&nbsp;{asset?.length - 5}
                    </span>
                    <img
                      src={asset[4]?.url ?? "/images/imagesproperty.svg"}
                      alt=""
                      className={classes.detailImg2}
                    />
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}

      <Dialog
        open={open}
        fullWidth
        maxWidth={maxWidth ?? "md"}
        onClose={() => setOpen(false)}
        className={classes.dialog}
      >
        {isNormal ? (
          <Slider assets={assetAll ?? []} selectedAssetIndex={selectedIndex} />
        ) : (
          <Assets
            assets={assetAll}
            title={title}
            onClose={() => setOpen(false)}
          />
        )}
      </Dialog>
    </>
  );
};
