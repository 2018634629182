import { Box, Grid, Stack } from '@mui/material'
import React from 'react'
import { AlertDialog } from '../../components'
import { AmenityDetails, AmenityPaymentDetails, CancelBooking, DeclineQRcode } from './components'
import { RequestMessage } from '../alreadyPaid/components'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
const DeclinePopup = (
    {
        amenityBookingDetail = {},
        type = "",
        t,
        closeDecline = () => false,
        updateAmenityBookingList = () => false
    }
) => {
    const [cancelPopup, setCancelPopup] = React.useState(false)

    return (
        <Box>
            <Grid container spacing={1} pb={2} pt={1}>
                <Grid item md={6} sm={6}>
                    <Stack>
                        <AmenityPaymentDetails amenityBookingDetail={amenityBookingDetail} />
                        <AmenityDetails amenityBookingDetail={amenityBookingDetail} />
                    </Stack>
                </Grid>
                <Grid item md={6} sm={6}>
                    <Stack p={1}>
                        {
                            amenityBookingDetail?.is_expired ?
                                <RequestMessage
                                    type="Expired"
                                    title={t("Pass Expired")}
                                    message={t("Expired On") + " " + moment(amenityBookingDetail?.booked_date).format("DD MMM")} /> :

                                amenityBookingDetail?.check_logs?.length > 0 ?
                                    <RequestMessage
                                        type=""
                                        title={t("Pass Used")}
                                        message={t("In") + ": " + moment(`${amenityBookingDetail?.check_logs?.[0]?.checkin_date} ${amenityBookingDetail?.check_logs?.[0]?.checkin_time}`).format("YYYY-MM-DD hh:mm") + " by " + amenityBookingDetail?.check_logs?.[0]?.checkin_person?.first_name}
                                        subMessage={t("Out") + ": " + moment(`${amenityBookingDetail?.check_logs?.[0]?.checkout_date} ${amenityBookingDetail?.check_logs?.[0]?.checkout_time}`).format("YYYY-MM-DD hh:mm") + " by " + amenityBookingDetail?.check_logs?.[0]?.checkout_person?.first_name} />
                                    :
                                    amenityBookingDetail?.booking_status === "Booked" ?
                                        <RequestMessage
                                            type=""
                                            title={t("Booking Confirmed")}
                                            message={`Booked by ${amenityBookingDetail?.user_first_name} on ${moment(amenityBookingDetail?.booked_date).format("DD MMM")}`}
                                            subMessage="" />
                                        :

                                        amenityBookingDetail?.booking_status === "Cancelled" &&
                                        <RequestMessage
                                            type="Cancelled"
                                            title={t("RequestCancelled")}
                                            message={t("Declinedby") + amenityBookingDetail?.user_first_name + " " + moment(amenityBookingDetail?.booked_date).format("DD MMM")}
                                            remarks={amenityBookingDetail?.remarks} />
                        }
                        {

                        }
                        <DeclineQRcode
                            status={amenityBookingDetail?.booking_status === "Cancelled"}
                            amenityBookingDetail={amenityBookingDetail}
                            type={type}
                            cancelClick={() => setCancelPopup(!cancelPopup)} />
                    </Stack>
                </Grid>
            </Grid>
            <AlertDialog isNormal open={cancelPopup}
                onClose={() => setCancelPopup(!cancelPopup)}
                header={t("CancelBooking")}
                component={
                    <CancelBooking
                    t={t}
                        amenityBookingDetail={amenityBookingDetail}
                        closeCancel={() => setCancelPopup(!cancelPopup)}
                        closeDecline={closeDecline}
                        updateAmenityBookingList={updateAmenityBookingList} />
                } />
        </Box>
    )
}

    export default withTranslation("amenityBooking")(DeclinePopup)
