import { Box } from "@mui/material";
import { withTranslation } from "react-i18next";
import { FormGenerator } from "../../../components";
import React from "react";
import { AggrementTabStyles } from "./styles";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";
import { LocalStorageKeys } from "../../../utils";
import moment from "moment";

const FinanceInformation = ({
  details = {},
  t,
  data,
  updateState = () => false,
}) => {
  const classes = AggrementTabStyles();

  const onChangeInstallment = (e) => {
    let RegExp = /^\d*\.?\d*$/;
    console.log(RegExp.test(e), "slslsl");

    const floatRegex = /^-?\d*(\.\d*)?$/;
    if (floatRegex.test(e) || e === "") {
      updateState("installments", e);
    }
  };

  React.useMemo(() => {
    if (data?.starting_from && data?.installments) {
      let currentDate = moment(data?.starting_from);
      let newDate = currentDate.add(Number(data?.installments), "months");
      console.log(new Date(newDate), "new Date(newDate)");
      updateState("period", newDate);
    }
  }, [data?.starting_from, data?.installments]);
  return (
    <Box p={2}>
      <Box className={classes.root}>
        <FormGenerator
          components={[
            {
              isActive: true,
              component: "text",
              label: t("Application Number"),
              value: data?.application_number,
              placeholder: t("Application Number"),
              onChange: (e) =>
                updateState("application_number", e?.target?.value),

              size: {
                xs: 12,
                sm: 12,
                md: 4,
                lg: 3,
              },
            },
            {
              isActive: true,
              component: "date",
              label: t("Application Date"),
              value: data?.application_date,
              placeholder: t("Application Date"),
              onChange: (e) => updateState("application_date", e),

              size: {
                xs: 12,
                sm: 12,
                md: 4,
                lg: 3,
              },
            },
            {
              isActive: true,
              component: "select",
              label: t("Financing Bank"),
              value: data?.financing_bank,
              placeholder: t("Financing Bank"),
              onChange: (e) => updateState("financing_bank", e),

              size: {
                xs: 12,
                sm: 12,
                md: 4,
                lg: 3,
              },
              fontSize: "14px !important",
              isPaginate: true,
              loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                  "queries/get_vendor_master",
                  {
                    company_id: JSON.parse(
                      localStorage.getItem(LocalStorageKeys?.selectedCompany)
                    )?.value,
                  },
                  search,
                  array,
                  handleLoading,
                  "data",
                  {}
                ),
              debounceTimeout: 800,
            },
            {
              isActive: true,
              component: "text",
              type: "number",
              label: t("Upfront Amount"),
              value: data?.upfront_amount,
              placeholder: t("Upfront Amount"),
              onChange: (e) => updateState("upfront_amount", e?.target?.value),

              size: {
                xs: 12,
                sm: 12,
                md: 4,
                lg: 3,
              },
            },
            {
              isActive: true,
              component: "text",
              label: t("Approval Reference"),
              value: data?.approval_reference,
              placeholder: t("Approval Reference"),
              onChange: (e) =>
                updateState("approval_reference", e?.target?.value),

              size: {
                xs: 12,
                sm: 12,
                md: 4,
                lg: 3,
              },
            },
            {
              isActive: true,
              component: "text",
              type: "number",
              label: t("Approval Amount"),
              value: data?.approved_amount,
              placeholder: t("Approval Amount"),
              onChange: (e) => updateState("approved_amount", e?.target?.value),

              size: {
                xs: 12,
                sm: 12,
                md: 4,
                lg: 3,
              },
            },
            {
              isActive: true,
              component: "date",
              label: t("Approval Date"),
              value: data?.approval_date,
              placeholder: t("Approval Date"),
              onChange: (e) => updateState("approval_date", e),

              size: {
                xs: 12,
                sm: 12,
                md: 4,
                lg: 3,
              },
            },
            {
              isActive: true,
              component: "text",
              type: "number",
              label: t("EMI Amount"),
              value: data?.emi_amount,
              placeholder: t("EMI Amount"),
              onChange: (e) => updateState("emi_amount", e?.target?.value),

              size: {
                xs: 12,
                sm: 12,
                md: 4,
                lg: 3,
              },
            },

            {
              isActive: true,
              component: "date",
              label: t("Signed Date"),
              value: data?.signed_date,
              placeholder: t("Signed Date"),
              onChange: (e) => updateState("signed_date", e),

              size: {
                xs: 12,
                sm: 12,
                md: 4,
                lg: 3,
              },
            },
            {
              isActive: true,
              component: "date",
              label: t("Starting From"),
              value: data?.starting_from,
              placeholder: t("Starting From"),
              onChange: (e) => updateState("starting_from", e),

              size: {
                xs: 12,
                sm: 12,
                md: 4,
                lg: 3,
              },
            },
            {
              isActive: true,
              component: "text",
              label: t("Installments"),
              value: data?.installments,
              placeholder: t("Installments"),
              onChange: (e) => onChangeInstallment(e?.target?.value),
              type: "number",
              size: {
                xs: 12,
                sm: 12,
                md: 4,
                lg: 3,
              },
            },
            {
              isActive: true,
              component: "date",
              label: t("Period"),
              value: data?.period,
              placeholder: t("Period"),
              size: {
                xs: 12,
                sm: 12,
                md: 4,
                lg: 3,
              },
              isReadonly: true,
              pattern: "[0-9]*",
            },
          ]}
        />
      </Box>
    </Box>
  );
};
export default withTranslation("agreement")(FinanceInformation);
