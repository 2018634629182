import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { Box, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import React from 'react';
import { eventListStyle, weeklyCalendarStyle } from './style';
export const SwitchColors = (val) => {
    switch (val) {
        case "Security":
            return { color: "#B3776D", backgroundColor: "#FEEAEA80" }
        case "Maintenance":
            return { color: "#6DAFB3", backgroundColor: "#DBF0F180" }
        case "Events":
            return { color: "#B3A16D", backgroundColor: "#FFFAD880" }
        case "General":
            return { color: "#A5499A", backgroundColor: "#FEEFFC" }
        case "Finance":
            return { color: "#7683B7", backgroundColor: "#E4E9FE80" }
        case "Home services":
            return { color: "#CE6F6F", backgroundColor: "#FFD8D880" }
        default:
            return { color: "#6DAFB3", backgroundColor: "#DBF0F180" }
    }
}

export const WeeklyCalendar = ({ t, weekdates = [], data = [], openDeclinePopup = () => false, handleSelected = () => false, showDailyCalendar = () => false }) => {
    const classes = weeklyCalendarStyle()
    const hours = [
        { label: "00:00-04:00", start: "00", end: "04" },
        { label: "04:00-08:00", start: "04", end: "08" },
        { label: "08:00-12:00", start: "08", end: "12" },
        { label: "12:00-16:00", start: "12", end: "16" },
        { label: "16:00-20:00", start: "16", end: "20" },
        { label: "20:00-24:00", start: "20", end: "24" },
    ]

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#fff',
            maxWidth: "100%",
            border: '1px solid #dadde9',
        },
    }));
    return (
        <Box>
            <TableContainer className={classes.table_container}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.cells}>
                                <Stack spacing={1} alignItems={"center"}>
                                    <AccessTimeFilledIcon className={classes.listTitle} />
                                </Stack>
                            </TableCell>
                            {weekdates?.map((e) => {
                                return (
                                    <TableCell className={classes.cells}>
                                        <Stack alignItems={"center"} justifyContent={"center"} spacing={1}>
                                            <Stack>
                                                <Typography className={classes.dayLabel}>
                                                    {moment(e).format("ddd")}
                                                </Typography>
                                                <Typography className={classes.dateLabel}>
                                                    {moment(e).format("DD")}
                                                </Typography>
                                            </Stack>

                                        </Stack>
                                    </TableCell>
                                );
                            })}
                        </TableRow>


                    </TableHead>

                    <TableBody>
                        {
                            hours?.map((val) => {
                                return (
                                    <TableRow>
                                        <TableCell className={classes.cells} sx={{ textAlign: "center" }} alignItems={"center"}>
                                            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                                <Typography className={classes.dayLabel}>{val?.label}</Typography>
                                            </Stack>
                                        </TableCell>
                                        {

                                            weekdates?.map(date => {
                                                return (
                                                    <TableCell className={classes.content_cells}>
                                                        {
                                                            data?.map(wd => {
                                                                return (
                                                                    <div>
                                                                        {
                                                                            // wd?.date === moment(date).format("YYYY-MM-DD") &&
                                                                            // wd?.calendarData?.filter((item) => /// old code
                                                                            // wd?.calendarData?.filter(number => number?.start?.slice(11, 13) >= val?.start && number?.end?.slice(11, 13) <= val?.end /// recent one
                                                                            // console.log("item?.start?.slice(11, 13)==>",item?.start?.slice(11, 13),"val?.start",val?.start)
                                                                            // (item?.start?.slice(11, 13) === val?.start && item?.end?.slice(11, 13) === val?.end)  // old code
                                                                            // (item?.start?.slice(11, 13) >= val?.start && item?.end?.slice(11, 13) <= val?.end)  /// new one

                                                                            wd?.calendarData?.filter((item) => (item.start.slice(11, 13) >= val.start && item.end.slice(11, 13) <= val.end)
                                                                            )?.map((x) => {

                                                                                return (
                                                                                    <Box>
                                                                                        {moment(x?.start).format("DD-MM-YYYY") === moment(date).format("DD-MM-YYYY") &&
                                                                                            <Stack spacing={1}>
                                                                                                {

                                                                                                    x?.activity?.length > 1 &&
                                                                                                    <>
                                                                                                        <HtmlTooltip
                                                                                                            title={
                                                                                                                // x?.parking_master_parking_type === "Reserved" ?
                                                                                                                <React.Fragment>
                                                                                                                    <CustomToolTip t={t} data={x?.activity?.[0]} openDeclinePopup={openDeclinePopup} handleSelected={handleSelected} date={date} />
                                                                                                                </React.Fragment >
                                                                                                                // : ""
                                                                                                            }
                                                                                                            arrow className={classes.customTooltip}
                                                                                                        >
                                                                                                            <Stack direction={"row"} spacing={1} sx={{ backgroundColor: SwitchColors(x?.activity?.[0]?.category)?.backgroundColor }} p={0.4}
                                                                                                                alignItems={"center"}
                                                                                                                // onClick={() => handleSelected(date)}
                                                                                                            >
                                                                                                                <Typography className={classes.event_label} style={{ color: SwitchColors(x?.activity?.[0]?.category)?.color }}>{`${x?.activity?.[0]?.title}`}</Typography>
                                                                                                            </Stack>
                                                                                                        </HtmlTooltip >
                                                                                                        <HtmlTooltip
                                                                                                            title={
                                                                                                                // x?.parking_master_parking_type === "Reserved" ?
                                                                                                                <React.Fragment>
                                                                                                                    <CustomToolTip t={t} data={x?.activity?.[1]} openDeclinePopup={openDeclinePopup} handleSelected={handleSelected} date={date} />
                                                                                                                </React.Fragment >
                                                                                                                // : ""
                                                                                                            }
                                                                                                            arrow className={classes.customTooltip}
                                                                                                        >
                                                                                                            <Stack direction={"row"} spacing={1} sx={{ backgroundColor: SwitchColors(x?.activity?.[1]?.category)?.backgroundColor }} p={0.4}
                                                                                                                alignItems={"center"}
                                                                                                                // onClick={() => handleSelected(date)}
                                                                                                            >
                                                                                                                <Typography className={classes.event_label} style={{ color: SwitchColors(x?.activity?.[1]?.category)?.color }}>{`${x?.activity?.[1]?.title}`}</Typography>
                                                                                                            </Stack>
                                                                                                        </HtmlTooltip >
                                                                                                        {x?.activity?.length > 2 &&
                                                                                                            <Box onClick={() => showDailyCalendar(date)}>
                                                                                                                <Typography className={classes.event_label} sx={{ color: "#78B1FE", cursor: "pointer" }}>{`+ ${x?.activity?.length - 2} more`}</Typography>
                                                                                                            </Box>
                                                                                                        }
                                                                                                    </>
                                                                                                }
                                                                                                {x?.activity?.length === 1 &&
                                                                                                    <HtmlTooltip
                                                                                                        title={
                                                                                                            // x?.parking_master_parking_type === "Reserved" ?
                                                                                                            <React.Fragment>
                                                                                                                <CustomToolTip t={t} data={x?.activity?.[0]} openDeclinePopup={openDeclinePopup} handleSelected={handleSelected} date={date} />
                                                                                                            </React.Fragment >
                                                                                                            // : ""
                                                                                                        }
                                                                                                        arrow className={classes.customTooltip}
                                                                                                    >
                                                                                                        <Stack direction={"row"} spacing={1} sx={{ backgroundColor: SwitchColors(x?.activity?.[0]?.category)?.backgroundColor }} p={0.4}
                                                                                                            alignItems={"center"}
                                                                                                            // onClick={() => handleSelected(date)}
                                                                                                        >
                                                                                                            <Typography className={classes.event_label} style={{ color: SwitchColors(x?.activity?.[0]?.category)?.color }}>{`${x?.activity?.[0]?.title}`}</Typography>
                                                                                                        </Stack>
                                                                                                    </HtmlTooltip >
                                                                                                }


                                                                                            </Stack >
                                                                                        }
                                                                                    </Box >
                                                                                )
                                                                            })
                                                                        }
                                                                    </div >
                                                                )
                                                            })
                                                        }
                                                    </TableCell >
                                                )
                                            })
                                        }
                                    </TableRow >
                                )
                            })
                        }
                    </TableBody >

                </Table >
            </TableContainer >
        </Box >
    )
}

const CustomToolTip = ({ data = {}, handleSelected = () => false, date = "", t }) => {
    const classes = eventListStyle()
    return (
        <Box p={1}>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <img src={"/images/announcement.svg"} width="40px" height="40px" alt="icon" className={classes.announceImg} />
                </Grid>
                <Grid item xs={10}>
                    <Typography className={classes.ttTitle}> {data?.title} </Typography>
                    <Box ml={1} mt={1}>
                        <Typography className={classes.ttsecTitle}>{data?.category}</Typography>
                        <Typography className={classes.ttassigned_by}>{`${t("Created on")} ${moment(data.created_at).format("DD MMM YYYY")} ${t("by")} ${data?.triggered_by_first_name}`}</Typography>
                        <Typography className={classes.moreLabel} onClick={() => {
                            handleSelected(data)
                        }}> {t("View More")}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    )
}