/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useNavigate } from 'react-router-dom';
import {
  FilterGenerator,
  Subheader,
  UseDebounce,
} from "../../components";
import { NewLoader } from "../../components/newLoader";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { accessCheckRender, AlertProps, enumSelect, enum_types, getCompanyOption, getRoutePermissionNew, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { AccountTable, DetailsContainer } from "./component";
import { FontFamilySwitch } from "../../utils";
import { withTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    margin: "16px",
  },
  content: {
    textAlign: "center",
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: "none",
    fontSize:"0.875rem",
  },
  btn2: {
    borderRadius: theme.palette.borderRadius,
    color: theme.palette.secondary.main,
  },
  text: {
    fontSize:"1.5rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  drawerHeaderStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerHeaderTextStyle: {
    fontSize:"1rem",
    fontWeight: "bolder",
    color: theme.typography.color.primary,
  },
  subText: {
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary,
  },
  img: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    //marginTop: "4px",
    padding: "12px",
  },
  tableHead: {
    backgroundColor: "#F2F4F7",
    color: theme.typography.color.secondary,
    borderRadius: theme.palette.borderRadius,
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  thead: {
    backgroundColor: "#F2F4F7",
    "& th:first-child": {
      borderRadius: "1em 0 0 1em",
    },
    "& th:last-child": {
      borderRadius: "0 1em 1em 0",
    },
  },
  tbody: {
    backgroundColor: "#F2F4F7",
    "& th:first-child": {
      borderRadius: "1em 0 0 1em",
    },
    "& th:last-child": {
      borderRadius: "0 1em 1em 0",
    },
  },
  businessType: {
    backgroundColor: "#78B1FE",
    color: "white",
    padding: "1px",
    borderRadius: theme.palette.borderRadius,
    textAlign: "center",
  },
  bottomTitle: {
    fontSize:"1.125rem",
    fontFamily: FontFamilySwitch().extraBold,
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    marginBottom: "12px",
  },
  contentBottom: {
    // padding: "12px",
  },

  grid: {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: theme.palette.borderRadius,
    padding: "4px",
  },
  title: {
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },
  subTitle: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.palette.primary.main,
  },
  divider: {
    height: "12px",
    width: "2px",
    backgroundColor: "black",
  },
  detailsContainer: {
    borderInlineStart: "1px solid #E4E8EE",

  },
  noFound: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: `calc(100vh - 250px)`
  },
  noData: {
    color: '#4E5A6B',
    margin: 0,
    overflow: 'hidden',
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().bold,
    fontWeight: 'bold',
  },
  noDataTeams: {
    textAlign: "center",
    marginTop: "25%",

  },
  root2: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    margin: "16px",
    height: `calc(100vh - 148px)`
  },
}));

const Properties = (props) => {
  const defaultFilterState = { status: true };
  const { loading, handleLoading, t } = props;
  const navigate = useNavigate();
  const classes = useStyles();
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const auth = React.useContext(AuthContext);
  const [drawer, setDrawer] = React.useState(null);
  // const [showDrawer, setShowDrawer] = React.useState(false);
  const [propertyList, setPropertyList] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId)
  const [companyList, setCompanyList] = React.useState([]);
  const [selectedTeams, setSelectedTeams] = React.useState('')
  const [selectedCompany, setSelectedCompany] = React.useState({});

  const [filterData, setFilterData] = React.useState(defaultFilterState);
  const [stats, setStats] = React.useState(null);
  const [detailLoader, setDetailLoader] = React.useState(false);
  const [companyDetails, setCompanyDetails] = React.useState({})
  // state
  const [enumValue, setEnumValue] = React.useState({ purpose_global_type: [], });
  // get enum
  const getEnum = async () => {
    const result = await enumSelect([enum_types?.purpose_global_type]);
    setEnumValue({ purpose_global_type: result?.purpose_global_type });
  };
  const [permission, setPermission] = React.useState({})
  const [loader, setLoader] = React.useState(true)

  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");
  const limitParam = searchParams.get("limit");

  // Default values if not set in URL
  const pageFromParams = pageParam ? parseInt(pageParam, 10) : 1;
  const limitFromParams = limitParam ? parseInt(limitParam, 10) : 10;

  const [page, setPage] = React.useState(pageFromParams);
  const [limit, setLimit] = React.useState(limitFromParams);

  const updatePage = (newPage, newLimit = limit) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", newPage.toString());
    searchParams.set("limit", newLimit.toString());

    // Update URL without reloading
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });

    // Calculate offset
    const newOffset = (newPage - 1) * newLimit;

    // Update state
    setPage(newPage);
    setLimit(newLimit);

    // Fetch the updated data
    getPropertyByCompanyID(newOffset, newLimit, searchText);
    getPropertyByCompanyDetails(selectedCompany?.value)
  };

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        getEnum()
        getCompany()
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  //debounce hook
  const debounce = UseDebounce();
  //more options
  //handle pagination
  const handlePagination = (newPage) => {
    updatePage(newPage, limit);
  };

  const handleChangeLimit = (newLimit) => {
    updatePage(1, newLimit); // Reset page to 1 when limit changes
  };
  const handleSearch = (e) => {
    setSearchText(e);
    debounce(() => searchTableFunction(e), 800);
  };
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getPropertyByCompanyID(0, limit, e);
  };

  const handleOnClickAddPropertyButton = () => {
    navigate(Routes.propertycreation, {
      state: {
        main: {
          data: companyDetails ?? {},
          company: selectedCompany,
          teams: selectedTeams,
        },
      },
    });
  };
  const handleIcon = (type, data) => {
    if (type === "edit") {
      navigate(Routes.propertycreation, {
        state: {
          main: {
            company: selectedCompany,
            teams: selectedTeams,
            companyID: data?.companyID,
            propertyID: data?.id,
            isEdit: true,
          },
        },
      });
      // navigate(Routes.propertycreation + "?companyID=" + data?.companyID + "&propertyID=" + data?.id + "&topNavBarTitle=" + data?.propertyName)
    } else if (type === "info"||type==="double_click") {
      navigate(
        Routes.propertyview +
        "?companyID=" +
        data?.companyID +
        "&propertyID=" +
        data?.id +
        "&name=" +
        data?.propertyName
      );
    } else if (type === "view") {
      const tempList = propertyList?.data?.map(_ => {
        return { ..._, selectedRow: _?.id === data?.id ?? false }
      })
      setPropertyList({ ...propertyList, data: tempList })
      getPropertyStats(data?.id);
    }
  };
  const onUnitClick = (data, val) => {
    if (val === "block") {
      navigate(Routes.block + "?propertyID=" + data?.id);
    } else if (val === "floor") {
      navigate(Routes.floor + "?propertyID=" + data?.id);
    } else if (val === "unit") {
      navigate(Routes.unit + "?propertyID=" + data?.id);
    }
    // if (val === "unit") {
    //     navigate(Routes.unitTable + "?companyID=" + data?.companyID + "&propertyID=" + data?.id + "&topNavBarTitle=" + data?.propertyName)
    // } else
  };
  const draweropen = () => {
    setDrawer(true);
  };
  const drawerclose = () => {
    setDrawer(false);
  };
  const propertyRow = React.useCallback(
    propertyList?.data?.map((val, index) => {
      let _d;
      try {
        _d = {
          index: (page - 1) * limit + index + 1,
          selectedRow: val?.selectedRow,
          propertyNo: val?.property_no,
          propertyName: val?.name,
          companyName: val?.company_master_name,
          area: val?.area ?? "-",
          city: val?.city ?? "-",
          location: (val?.area || val?.city) ?
            ((val?.area ? (val?.area + ", ") : "") + (val?.city ?? "")) : "-",
          blocks: val?.block.length,
          floors: val?.floor.length,
          units: val?.unit.length,
          propertyType: val?.property_group_master_group_name,
          propertyPurpose: val?.property_purpose ?? "-",
          propertyHierarchy: val?.property_hierarchy_name,
          icon: "editview",
          companyID: val?.company_master_id,
          id: val?.id,
        };
      } catch (err) { }
      return _d;
    }),
    [propertyList]
  );

  const currentOffset = (page - 1) * limit;
  const getPropertyByCompanyID = (offset = currentOffset, limit = limitFromParams, search = "") => {
    let companyID = selectedCompany?.value;
    let propertyGroudID = filterData.property_type?.length
      ? filterData?.property_type?.map(({ value }) => value)
      : [];
    let propertyPurpose = filterData.property_purpose?.length
      ? filterData?.property_purpose?.map(({ value }) => value)
      : [];
    let propertyHierarchyID = filterData.property_hierarchy?.length
      ? filterData?.property_hierarchy?.map(({ value }) => value)
      : [];
    let is_active = [true, false].includes(filterData?.status) ? [filterData?.status] : [];
    let teamsId = selectedTeams?.value;

    const payload = {
      company_id: companyID,
      status: is_active,
      property_groud_id: propertyGroudID,
      property_hierarchy_id: propertyHierarchyID,
      property_purpose: propertyPurpose,
      offset: offset,
      limit: limit,
      search: search
    }
    if (teamsId !== "noteams") {
      payload["team"] = teamsId
      payload["team_all_property"] = true
    }
    NetworkCall(
      `${config.api_url}/property/getAllProperties`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setPropertyList({
          data: response?.data?.data?.property,
          totalRowsCount: response?.data?.data?.count,
        });
        if (response?.data?.data?.property?.length > 0) {
          getPropertyStats(response?.data?.data?.property?.[0]?.id);
        } else {
          setDetailLoader(false);
        }
        setLoader(false)
        handleLoading(false)
      })
      .catch(() => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
      });
  };
  const getPropertyByCompanyDetails = (companyID) => {
    const payload = {
      id: companyID,
    };
    NetworkCall(
      `${config.api_url}/queries/company_master/details`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setCompanyDetails(response?.data?.data)
        setLoader(false)
        handleLoading(false)
      })
      .catch(() => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
      });
  };

  React.useEffect(() => {
    if (selectedTeams?.value) {
      getPropertyByCompanyID(currentOffset, limitFromParams, searchText);
    }
  }, [selectedTeams, filterData]);

  const getCompany = () => {
    let company = getCompanyOption(backdrop, auth, alert)
    if (company) {
      setCompanyList(company?.list)
      setSelectedCompany(company?.selected)
      if (company?.list?.length > 0) {
        getPropertyByCompanyDetails(company?.selected?.value)
      }
    }
  }

  const handleCompanyChange = (value) => {
    setLoader(true)
    setDetailLoader(true);
    setSelectedCompany(value);
    getPropertyByCompanyDetails(value?.value)
  };

  const onApplyFilter = (value) => {
    setLoader(true)
    setDetailLoader(true);
    setFilterData(value);
  };

  const getPropertyStats = (id) => {
    setDetailLoader(true);
    const payload = {
      property_id: id,
    };
    NetworkCall(
      `${config.api_url}/property/stats`,
      NetWorkCallMethods.post,
      payload,
      true,
      true,
      false
    )
      .then((res) => {
        const unitCatagory = res?.data?.data?.unit_category?.map((x) => {
          return {
            name: x?.name,
            count: parseInt(x?.count),
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
          };
        });
        const unit_type = res?.data?.data?.unit_type?.map((x) => {
          return {
            name: x?.unit_type,
            count: parseInt(x?.count),
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
          };
        });
        let tempAssets = res?.data?.data?.assets?.map((_) => _)
        res?.data?.data?.property_details?.logo &&
          (tempAssets = [{ url: res?.data?.data?.property_details?.logo }, ...tempAssets])
        setStats({
          data: res?.data?.data,
          unit_catagory: unitCatagory ?? [],
          unit_type: unit_type ?? [],
          assets: tempAssets
        });
        setDetailLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setDetailLoader(false);
      });
  };

  // Function to change property
  const handleTeamsChange = (team) => {
    setSelectedTeams(team)
  }

  const manualPropertyTypeResponse = array => array?.property_group_master;
  const manualPropertyHierarchyMasterResponse = array => array?.property_hierarchy_master;

  const render = () => {
    return <>
      <Subheader
        hideBackButton={true}
        title={t("Properties")}
        select
        options={companyList}
        value={selectedCompany}
        goBack={() => {
          navigate(-1);
        }}
        onchange={(e) => {
          handleCompanyChange(e);
        }}
        selectTeamsOptions
        handleTeamsChange={handleTeamsChange}
        companyId={selectedCompany?.value}
        moduleId={moduleId}
        selectedTeams={selectedTeams}
      />
      {
        loader ? (
          <NewLoader minusHeight="100px" />
        ) : (
          <Box className={selectedTeams ? classes.root : classes.root2}>
            <Grid container sx={{height:{sm:"calc(100vh - 153px)"}, overflow:"auto"}}>
              {selectedTeams ?
                <>
                  <Grid item xs={12} md={7} lg={8} sm={12}>
                    {/*table */}
                    <Box p={2}>
                      <AccountTable
                        permission={permission}
                        draweropen={draweropen}
                        data={propertyRow}
                        selectedCompany={selectedCompany}
                        handleChangeLimit={handleChangeLimit}
                        handlePagination={handlePagination}
                        page={page}
                        handleSearch={handleSearch}
                        searchText={searchText}
                        limit={limit}
                        handleIcon={handleIcon}
                        onUnitClick={onUnitClick}
                        totalRowsCount={propertyList?.totalRowsCount}
                        handleOnClickAddPropertyButton={handleOnClickAddPropertyButton}
                        handleChange={handleSearch}
                        t={t}
                        enable_double_click={true}
                        enable_single_click={true}
                        filterData={filterData}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={5} lg={4} sm={12}>
                    {/*details */}
                    {
                      detailLoader ? (
                        <NewLoader minusHeight="100px" />
                      ) : (
                        <Box p={"16px 0px 16px 16px"} className={classes.detailsContainer}>
                          {propertyRow?.length > 0 ? (
                            <DetailsContainer t={t} stats={stats} loading={detailLoader} permission={permission} />
                          ) : (
                            <div className={classes.noFound}>
                              <Typography className={classes.noData}>{t("No Data")}</Typography>
                            </div>
                          )}
                        </Box>)}
                  </Grid>
                </>
                :
                <Grid item xs={12} className={classes.noDataTeams}>
                  <Typography className={classes.text}>
                    {t("No data found")}
                  </Typography>
                </Grid>

              }
            </Grid>
            {drawer && (
              <FilterGenerator
                open={drawer}
                onClose={drawerclose}
                defaultState={defaultFilterState}
                components={[
                  {
                    component: "select",
                    value: filterData?.property_type,
                    options: [],
                    isMulti: true,
                    label: t("Property Type"),
                    state_name: "property_type",
                    placeholder: t("Select Property Type"),
                    loadOptions: (search, array, handleLoading) =>
                      loadOptionsApis(
                        "queries/property_type/get",
                        { company_id: selectedCompany?.value },
                        search,
                        array,
                        handleLoading,
                        "property_group_master",
                        {},
                        manualPropertyTypeResponse,
                        { manualResponseMethod: "without_extract_label" },
                      ),
                    debounceTimeout: 800,
                    isPaginate: true,
                  },
                  {
                    component: "select",
                    value: filterData?.property_hierarchy,
                    options: [],
                    isMulti: true,
                    label: t("Property Hierarchy"),
                    state_name: "property_hierarchy",
                    placeholder: t("Select Property Hierarchy"),
                    loadOptions: (search, array, handleLoading) =>
                      loadOptionsApis(
                        "queries/property_hierarchy_master/get",
                        {},
                        search,
                        array,
                        handleLoading,
                        "data",
                        {},
                        manualPropertyHierarchyMasterResponse,
                      ),
                    debounceTimeout: 800,
                    isPaginate: true,
                  },
                  {
                    component: "select",
                    value: filterData?.property_purpose,
                    options: enumValue?.purpose_global_type,
                    isMulti: true,
                    state_name: "property_purpose",
                    placeholder: t("Select Property Purpose"),
                    label: t("Property Purpose"),
                  },
                  {
                    component: "toggleButton",
                    value: filterData?.status,
                    options: [
                      { label: t("Active"), value: true },
                      { label: t("Inactive"), value: false },
                    ],
                    state_name: "status",
                    label: t("Status"),
                    // required:true
                  },
                ]}
                onApply={(value) => onApplyFilter(value)}
              />
            )}
          </Box>)}
    </>
  }

  return (
    <div>
      {accessCheckRender(render, permission, "", loading)}
    </div>
  );
};
export default withTranslation("properties")(Properties)
