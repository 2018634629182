import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../utils";
export const TreeStylesParent = makeStyles((theme) => ({
      Icons: {
            fontSize: '20px !important',
            background: '#E4E8EE',
            borderRadius: '2px',
            fontWeight: 600,
            color: '#000'
      },
      rightIcons: {
            fontSize: '20px !important',
            borderRadius: '2px',
            fontWeight: 600,
            color: '#000'
      },
      displayFlex: {
            display: 'flex',
            alignItems: 'center',
            "&::-webkit-scrollbar": {
                  width: "0em",
            },
      },

      marginLeft: {
            marginInlineStart: '15px',
            alignItems: 'center'

      },
      formLabel: {
            '&.MuiFormControlLabel-root': {
                  marginLeft: "-16px",
                  marginRight: "0px"
            }
      },
      treeTitle: {
            color: '#091B29',
            fontFamily: FontFamilySwitch().regular,
            fontSize:"0.875rem",
            fontWeight: 600
      },
      searchTitle: {
            fontSize:"1rem",
            fontFamily: FontFamilySwitch().semiBold,
            color: '#101010'
      },
      addBtn: {
            background: '#5078E1',
            color: '#fff',
            borderRadius: '4px',
            padding: '4px 10px',
            '&:hover': {
                  background: '#5078E1',
                  color: '#fff'
            }
      },
      searchBox: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginInlineStart: "6px"
      },
      searchField: {
            marginTop: '16px',
            [`& fieldset`]: {
                  border: '1px solid #1010100D',
                  borderRadius: "4px",
                  backgroundColor: '#00000008',
            },
            '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                        border: '1px solid #5078E1',
                  },

            },
      },
      permissionTitle: {
            color: '#101010',
            fontSize:"0.875rem",
            fontFamily: FontFamilySwitch().semiBold,
      },
      permissionSubTitle: {
            color: theme.typography.color.secondary,
            fontSize:"0.75rem",
            fontFamily: FontFamilySwitch().semiBold,
      },
      permissionBox: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px solid #E0E0E0',
            borderRadius: '4px',
            padding: "10px 20px",
            margin: '10px 0px',
            cursor: 'pointer'
      },
      selectedpermissionBox: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px solid #5078E1',
            borderRadius: '4px',
            padding: "16px",
            margin: '10px 0px',
            cursor: 'pointer'
      },
      badge: {
            backgroundColor: '#F2F4F7',
            borderRadius: '4px',
            padding: '5px 12px',
            color: '#4E5A6B',
            fontSize:"0.75rem",
            fontFamily: FontFamilySwitch().regular,
            fontWeight: 600
      },
      Label: {
            color: '#98A0AC',
            fontFamily: FontFamilySwitch().semiBold,
            fontSize:"0.875rem",
            marginBottom: "5px",
      },


      textbox: {
            backgroundColor: (props) => props?.color ?? "auto",
            borderRadius: "4px",
            border: "none",
            "&:-webkit-autofill": {
                  WebkitBoxShadow: "0 0 0 1000px white inset",
                  backgroundColor: '#fff'
            },
            [`& fieldset`]: {
                  borderRadius: "4px",
                  height: (props) => (props.multiline ? "unset" : props?.height ?? 50),
                  border: (props) => props?.border ?? "1px solid #CED3DD",
                  "&:hover": {
                        border: "1px solid red",
                  },
            },
            "& .MuiOutlinedInput-input": {
                  padding: (props) => props?.padding ?? "11px 14px",
            },
            '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                        borderColor: '#5078E1',
                  },

            },

      },
      checkBoxDesign: {
            minWidth: 60,
            maxWidth: 62,
            display: "flex",
            justifyContent: "center",
      },
      iconrelative: {
            position: 'relative',
      },
      iconabsolute: {
            width: '18px',
            height: '0.5px',
            background: '#E0E0E0',
            position: 'absolute',
            left: '-24px',
            top: '36%'
      },
      permissionEditSection: {
            display: 'flex',
            alignItems: 'center'
      },
      deleteIcon: {
            color: 'red',
            fontSize: '24px'
      },
      convert: {
            color: "white",
            fontSize:"1rem",
            fontFamily: FontFamilySwitch().bold,
      },
      convertProcess: {
            color: "white",
            fontSize:"1rem",
            fontFamily: FontFamilySwitch().regular,
            marginTop: '7px'
      },
      yes: {
            color: theme?.palette?.primary?.main,
            fontSize:"0.875rem",
            fontFamily: FontFamilySwitch().bold,
            border: `1px solid white`,
            backgroundColor: "white",
            padding: "7px 29px",
            width: "100%",
            "&:hover": {
                  border: `1px solid white`,
                  backgroundColor: "white",
            },
      },
      No: {
            color: "white",
            fontSize:"0.875rem",
            fontFamily: FontFamilySwitch().semiBold,
            border: `1px solid white`,
            padding: "7px 29px",
            backgroundColor: theme?.palette?.primary?.main,
            marginInlineStart: "10px",
            width: "100%",
            "&:hover": {
                  border: `1px solid white`,
                  backgroundColor: theme?.palette?.primary?.main,
            },
      },
      proceedDialog: {
            padding: "54px 50px 51px 228px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            backgroundImage: `url(${"images/announsment.svg"})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: theme?.palette?.primary?.main
      },
      new: {
            fontSize:"0.75rem",
            fontFamily: FontFamilySwitch().semiBold,
            backgroundColor: theme.palette.error.main,
            color: "white",
            padding: "4px",
            borderRadius: theme.palette.borderRadius,
            height: "20px"
            // marginLeft: "8px"
      }
}))