import React from "react";
import { withNavBars } from "../../HOCs";
import ApprovalWorkflow from "./approvalWorkflow";


class ApprovalWorkflowParent extends React.Component {
    render() {
        return <ApprovalWorkflow {...this.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ApprovalWorkflowParent, props);