import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const correspondencesStyles = makeStyles((theme) => ({
    root: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        height: `calc(100vh - 147px)`,
        overflow: "hidden",
        margin: "14px"
    },
    button: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "none",
        fontSize:"0.875rem",
        height: '40px'
    },
    filterButton: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "12px",
        height:'40px',
        width:'40px'
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: theme.palette.borderRadius,
            padding: "0px",
        },
    },
    addDialogHeader: {
        padding: "20px 24px 16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #E4E8EE"
    },
    dialogHeaderTitle: {
        fontFamily: FontFamilySwitch().extraBold,
        fontSize:"1rem",
        color: theme.typography.color.primary,
    },
    dialogCloseButton: {
        padding: "0px"
    },
    addDialogBody: {
        padding: "24px",
        overflowY: "overlay",
    },
    divider: {
        backgroundColor: "#E4E8EE"
    },
    level_card_div: {
        cursor: "pointer"
    },
    level_card_text: {
        fontSize:"1rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
        marginInlineStart: "14px"
    },
    viewDialogHeader: {
        padding: "16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #E4E8EE"
    },
    viewDialogBody: {
        padding: "16px",
        overflowX: "hidden",
        overflowY: "overlay",
        width: "600px",
    },
    pdf_viewer_div: {
        height: "100%",
        width: "inherit",
        overflowX: "hidden",
        overflowY: "overlay",
    },
    view_dialog_reference_no: {
        fontSize:"1rem",
        fontFamily: FontFamilySwitch().extraBold,
        color: theme.typography.color.primary,
    },
    view_dialog_created: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().regular,
        fontStyle: "italic",
        color: theme.typography.color.tertiary,
    },
    edit_button: {
        cursor: "pointer",
        height: "40px",
        padding: "10px 16px",
        borderRadius: theme.palette.borderRadius,
        border: `1px solid ${theme.palette.primary.main}`,
    },
    edit_button_text: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.palette.primary.main,
    },
    send_button: {
        cursor: "pointer",
        height: "40px",
        padding: "10px 16px",
        borderRadius: theme.palette.borderRadius,
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.primary.main,
        marginInlineStart: "8px"
    },
    send_button_text: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.white,
    },
    replied_on_text: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.tertiary,
        marginInlineStart: "8px"
    },
    reply_text: {
        fontFamily: FontFamilySwitch().semiBold,
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
    },
    confirmSuccessDialog: {
        "& .MuiDialog-paper": {
            borderRadius: theme.palette.borderRadius,
            padding: "0px",
            width: "600px",
        },
    },
    confirmDialogComponent: {
        backgroundColor: theme.palette.primary.main,
        backgroundImage: `url("/images/confirmBGImage.svg")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "250px",
    },
    successDialogComponent: {
        backgroundColor: theme.palette.primary.main,
        backgroundImage: `url("/images/successBGImage.svg")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "250px",
    },
    confirmSuccessDialogHeader: {
        padding: "20px",
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
    },
    confirmDialogBody: {
        padding: "0px 54px",
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
    },
    successDialogBody: {
        padding: "0px 54px",
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
    },
    successDialogBodyRtl: {
        padding: "0px 54px",
        display: "flex",
        alignItems: "center",
    },
    yes_button: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        cursor: "pointer",
        height: "40px",
        padding: "10px 16px",
        borderRadius: theme.palette.borderRadius,
        border: `1px solid ${theme.palette.primary.contrastText}`,
        backgroundColor: theme.palette.primary.contrastText,
    },
    yes_okay_button_text: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.palette.primary.main,
    },
    no_button: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        cursor: "pointer",
        height: "40px",
        padding: "10px 16px",
        borderRadius: theme.palette.borderRadius,
        border: `1px solid ${theme.palette.primary.contrastText}`,
        backgroundColor: theme.palette.primary.main,
        marginInlineStart: "8px"
    },
    no_button_text: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.white,
    },
    confirm_question_success_message_text: {
        textAlign: "center",
        fontFamily: FontFamilySwitch().bold,
        fontSize:"1rem",
        color: theme.typography.color.white
    },
    okay_button: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        height: "40px",
        width: "150px",
        padding: "10px 16px",
        borderRadius: theme.palette.borderRadius,
        border: `1px solid ${theme.palette.primary.contrastText}`,
        backgroundColor: theme.palette.primary.contrastText,
    },
    see_more_less_text: {
        cursor: "pointer",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize:"0.75rem",
        color: "Blue",
    },
}));