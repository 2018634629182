import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import Exporting from "highcharts/modules/exporting";
import { FontFamilySwitch } from "../../../utils";

Exporting(Highcharts);

window.showTooltip = function (event, text) {
  let tooltip = document.getElementById("tooltip");
  if (!tooltip) {
    tooltip = document.createElement("div");
    tooltip.id = "tooltip";
    tooltip.style.position = "absolute";
    tooltip.style.backgroundColor = "#333";
    tooltip.style.color = "#fff";
    tooltip.style.padding = "5px";
    tooltip.style.borderRadius = "5px";
    document.body.appendChild(tooltip);
  }
  tooltip.textContent = text;
  tooltip.style.left = `${event.pageX}px`;
  tooltip.style.top = `${event.pageY}px`;
  tooltip.style.display = "block";
};

window.hideTooltip = function () {
  const tooltip = document.getElementById("tooltip");
  if (tooltip) {
    tooltip.style.display = "none";
  }
};

export const RaceBarChart = ({
  data = {},
  options = {},
  isShowLegend = false,
  chartHeight = "",
  graphTitle,
}) => {
  const chartRef = useRef(null);
  const highchartRef = useRef(null);

  useEffect(() => {
    if (!highchartRef.current) {
      highchartRef.current = Highcharts.chart(chartRef.current, {
        colors: ["#FF8064", "#FBBD62", "#7CB10D", "#83C19F"],
        chart: {
          type: "bar",
          ...options.chart,
        },
        title: {
          text: graphTitle,
          align: "left",
          style: {
            color: "#091B29",
            fontSize: "1rem",
            fontFamily: FontFamilySwitch().extraBold,
            marginInlineStart: "8px",
          },
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: data.labels,
          labels: {
            useHTML: true,
            formatter: function () {
              let label = this.value;
              let tooltipText = label;
              if (label.length > 10) {
                label = label.substring(0, 10) + "...";
              }
              return `<span class="label" onmouseover="window.showTooltip(event, '${tooltipText}')" onmouseout="window.hideTooltip()" style="display: inline-block; transition: transform 0.1s ease; cursor: pointer;">${label}</span>`;
            },
          },
          ...options.xAxis,
        },
        yAxis: {
          min: 0,
          ...options.yAxis,
        },
        exporting: {
          enabled: true, // Enable the exporting module
        },
        plotOptions: {
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: true,
              ...options.plotOptions?.column?.dataLabels,
            },
            ...options.plotOptions?.column,
          },
          ...options.plotOptions,
        },
        legend: {
          enabled: isShowLegend,
          align: "center",
          verticalAlign: "bottom",
          layout: "horizontal",
          margin: 15,
          ...options.legend,
        },
        tooltip: {
          useHTML: true,
          formatter: function () {
            let number = this.y;
            let metric = "";
            if (number >= 1.0e12) {
              number = number / 1.0e12;
              metric = "Tn";
            } else if (number >= 1.0e9) {
              number = number / 1.0e9;
              metric = "Bn";
            } else if (number >= 1.0e6) {
              number = number / 1.0e6;
              metric = "Mn";
            } else if (number >= 1.0e3) {
              number = number / 1.0e3;
              metric = "k";
            }
            return (
              "<b>" +
              this.x +
              "</b>: " +
              Highcharts.numberFormat(number, 0) +
              metric
            );
          },
        },
        series: data.datasets.map((dataset, datasetIndex) => {
          return {
            name: dataset.label,
            data: dataset.data.map((item, itemIndex) => ({
              y: parseInt(item),
              color: ["#FF8064", "#F29000", "#7CB10D", "#83C19F"][
                itemIndex % 4
              ],
            })),
          };
        }),
        ...options,
      });
    } else {
      highchartRef.current.update({
        xAxis: {
          categories: data.labels,
          ...options.xAxis,
        },
        series: data.datasets.map((dataset) => ({
          name: dataset.label,
          data: dataset.data.map((item) => parseInt(item)),
        })),
      });
    }
    if (!highchartRef.current) {
      highchartRef.current = Highcharts.chart(chartRef.current, options);
    } else {
      highchartRef.current.update(options);
    }

    const handleFullscreenChange = () => {
      if (highchartRef.current) {
        highchartRef.current.reflow();
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      if (highchartRef.current) {
        highchartRef.current = null;
      }
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, [data, options, isShowLegend, graphTitle]);

  return (
    <div ref={chartRef} style={{ width: "100%", height: chartHeight }}></div>
  );
};

export default RaceBarChart;
