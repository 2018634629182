import { makeStyles } from "@mui/styles";
import { remCalc, FontFamilySwitch } from "../../utils";

export const NewLineChartStyle = makeStyles((theme) => ({
    tooltipBox: {
        background: "#fff",
        borderRadius: 8,
    },
    ttTitle: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.secondary
    },
    ttDot: {
        height: 8,
        width: 8,
        borderRadius: "50%"
    },
    ttValue: {
        fontSize: remCalc(14),
        fontFamily: FontFamilySwitch().extraBold,
        color: theme.typography.color.primary
    }
}))