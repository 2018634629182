import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { config } from '../../../config';
import { AlertContext } from '../../../contexts';
import { AlertProps, allowed_file_size, singleFileUpload } from '../../../utils';
import { proofComponentStyles } from "../styles";
import { withTranslation } from 'react-i18next';


const UploadFile = (props) => {

    const {
        label = "",
        handleChange = null,
        id = "",
        t
    } = props;



    const alert = React.useContext(AlertContext);

    const [loading, setLoading] = React.useState(false);
    const classes = proofComponentStyles()
    const types = ["application/pdf", "image/png", "image/jpg", "image/jpeg"]
    const handleUpload = async (data, e) => {
        if (data?.length) {
            if (types.includes(data?.[0]?.type)) {
                await executeImageData(data)
                e.target.value = null;
            } else {
                alert.setSnack({
                    open: true,
                    horizontal: AlertProps.horizontal.center,
                    vertical: AlertProps.vertical.top,
                    msg: t("Invalid file"),
                    severity: AlertProps.severity.error,
                });
            }
        }

    }

    const executeImageData = async (data) => {

        setLoading(true);

        let uploaded_file = await singleFileUpload(data?.[0], { tenantId: `${config.tenantId}` }, alert, allowed_file_size)

        if (uploaded_file?.[0]?.url) {
            handleChange && handleChange(
                {
                    src: uploaded_file?.[0]?.url,
                    name: data[0]?.name,
                    type: data[0]?.type
                },
                id
            );
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    return (
        <Box>

            <label htmlFor={`uplodebtn${id}`}>
                <Box className={classes.fileroot}>
                    <FileUploadOutlinedIcon className={classes.uploadIcon} />
                    <Typography className={classes.uploadlabel}>{loading ? t("Uploading...") : label}</Typography>

                </Box>
            </label>
            <input
                type="file"
                name="img"
                style={{ display: "none" }}
                id={`uplodebtn${id}`}
                onChange={(value) => handleUpload(value.target.files, value)}

            />
        </Box>
    )
}
export default withTranslation("uploadFile")(UploadFile)

