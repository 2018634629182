//import ImageViewer from "react-simple-image-viewer";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React from "react";
import Carousel, { consts } from "react-elastic-carousel";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { DocumentViewer } from "../../../components/fileViewer/index";
import { config } from "../../../config";
import { BackdropContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods } from "../../../utils";
import { assestType } from "../../../utils/common";
import { filterStyles } from "./styles";
export const Assets = (props) => {
  const classes = filterStyles();
  const [value, setValue] = React.useState(1);
  const [listing, setlisting] = React.useState([]);
  const breakPoints = [{ width: 1, itemsToShow: 1 }];
  const backdrop = React.useContext(BackdropContext);
  const [asset, setAsset] = React.useState({
    open: false,
    url: "",
    title: "",
    id: "",
    initialActiveIndex: 0,
  });
  React.useEffect(() => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    const payload = {
      offset: 0,
      limit: null,
    };
    NetworkCall(
      `${config.api_url}/queries/assets_master/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "Some Thing Went Wrong",
        });
        setlisting(res.data?.data?.assets_master);
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "Some Thing Went Wrong",
        });
      });
    // eslint-disable-next-line
  }, []);

  const handleList = (val) => {
    setValue(val);
  };
  const onClose = () => {
    setAsset({
      open: false,
      title: "",
      url: "",
      id: "",
    });
  };
  const onOpen = (title, url, id, assetType) => {
    const filteredAssets = props?.assets?.filter(
      (i) => i?.asset_type === assetType
    );

    const assetIndex = filteredAssets.findIndex((val) => val.id === id);

    setAsset({
      open: true,
      title: title,
      url: url,
      id: id,
      imageIndex: assetIndex !== -1 ? assetIndex : 0, // If assetIndex is not found, default to 0
    });
  };
  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />;
    return (
      <IconButton
        size="small"
        style={{ fontSize: "20px" }}
        onClick={onClick}
        disabled={isEdge}
      >
        {pointer}
      </IconButton>
    );
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        p={1}
        className={classes.titleBar}
      >
        <Box flexGrow={1}>
          <Typography className={classes.detailTitle}>
            {props?.title}
          </Typography>
        </Box>
        <Box>
          <IconButton size="small" onClick={props?.onClose}>
            <CloseIcon style={{ fontSize: "20px" }} />
          </IconButton>
        </Box>
      </Box>
      <Box className={classes.imagebox1}>
        <>
          <List style={{ display: "flex" }} className={classes.list}>
            {listing
              .filter((x) => x?.id !== assestType?.Thumbnails)
              .map((val) => {
                return (
                  <ListItem
                    className={
                      val.id === value ? classes.listText : classes.list
                    }
                    onClick={() => handleList(val.id)}
                  >
                    <Typography className={classes.tabname} noWrap>
                      {val.type}
                    </Typography>
                  </ListItem>
                );
              })}
          </List>

          <div style={{ marginTop: "8px" }}>
            <Grid container>
              {props?.assets
                ?.filter((x) => x?.id !== assestType?.Thumbnails)
                ?.map((val) => {
                  return (
                    <>
                      {val?.asset_type === value && (
                        <Grid
                          item
                          xs={2}
                          style={{
                            margin: "4px",
                            borderRadius: "12px",
                            overflow: "hidden",
                          }}
                        >
                          {val?.asset_type === assestType?.Documents ? (
                            <div
                              style={{
                                height: "150px",
                                border: "2px solid #E4E8EE",
                                overflow: "hidden",
                              }}
                              onClick={() =>
                                onOpen(
                                  "PDF Documents",
                                  val?.url,
                                  val?.id,
                                  val?.asset_type
                                )
                              }
                            >
                              <DocumentViewer url={val?.url} />
                            </div>
                          ) : (
                            <>
                              {val?.asset_type === assestType?.Videos ? (
                                val.url.includes("youtu.be") ? (
                                  <iframe
                                    title="Videos"
                                    style={{
                                      objectFit: "cover",
                                      height: "150px",
                                      width: "100%",
                                      borderRadius: "12px",
                                    }}
                                    onClick={() =>
                                      onOpen(
                                        "Videos",
                                        val?.url,
                                        val?.id,
                                        val?.asset_type
                                      )
                                    }
                                    src={`https://www.youtube.com/embed/${
                                      val.url.split("youtu.be/")[1]
                                    }`}
                                    allow="autoplay; encrypted-media"
                                    allowFullScreen
                                  />
                                ) : (
                                  <video
                                    style={{
                                      objectFit: "cover",
                                      height: "150px",
                                      width: "100%",
                                      borderRadius: "12px",
                                    }}
                                    onClick={() =>
                                      onOpen(
                                        "Videos",
                                        val?.url,
                                        val?.id,
                                        val?.asset_type
                                      )
                                    }
                                    controls
                                  >
                                    <source src={val.url} type="video/mp4" />
                                  </video>
                                )
                              ) : val?.asset_type ===
                                assestType?.Three_sixty_Images ? (
                                <img
                                  src={val.url}
                                  alt=""
                                  onClick={() =>
                                    onOpen(
                                      "360 VR View",
                                      val?.url,
                                      val?.id,
                                      val?.asset_type
                                    )
                                  }
                                  style={{
                                    objectFit: "cover",
                                    height: "150px",
                                    width: "100%",
                                    borderRadius: "12px",
                                    cursor: "pointer",
                                    border: "2px solid #E4E8EE",
                                  }}
                                />
                              ) : val?.asset_type ===
                                assestType?.Facility_Images ? (
                                <img
                                  key={val?.id}
                                  src={val.url}
                                  alt=""
                                  onClick={() =>
                                    onOpen(
                                      "General Images",
                                      val?.url,
                                      val?.id,
                                      val?.asset_type
                                    )
                                  }
                                  style={{
                                    objectFit: "cover",
                                    height: "150px",
                                    width: "100%",
                                    borderRadius: "12px",
                                    cursor: "pointer",
                                    border: "2px solid #E4E8EE",
                                  }}
                                />
                              ) : val?.asset_type ===
                                assestType?.General_Images ? (
                                <img
                                  key={val?.id}
                                  src={val.url}
                                  alt=""
                                  onClick={() =>
                                    onOpen(
                                      "Amenities",
                                      val?.url,
                                      val?.id,
                                      val?.asset_type
                                    )
                                  }
                                  style={{
                                    objectFit: "cover",
                                    height: "150px",
                                    width: "100%",
                                    borderRadius: "12px",
                                    cursor: "pointer",
                                    border: "2px solid #E4E8EE",
                                  }}
                                />
                              ) : (
                                <img
                                  key={val?.id}
                                  src={val.url}
                                  alt=""
                                  onClick={() => {
                                    onOpen(
                                      "Floor Plan",
                                      val?.url,
                                      val?.id,
                                      val?.asset_type
                                    );
                                  }}
                                  style={{
                                    objectFit: "cover",
                                    height: "150px",
                                    width: "100%",
                                    borderRadius: "12px",
                                    cursor: "pointer",
                                    border: "2px solid #E4E8EE",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </Grid>
                      )}
                    </>
                  );
                })}
            </Grid>
          </div>
        </>
      </Box>
      <Dialog
        open={asset?.open}
        maxWidth="sm"
        onClose={onClose}
        fullWidth
        className={classes.dialog}
      >
        <Box
          display="flex"
          alignItems="center"
          p={1}
          className={classes.titleBar}
        >
          <Box flexGrow={1}>
            <Typography className={classes.detailTitle}>
              {asset?.title}
            </Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon style={{ fontSize: "20px" }} />
            </IconButton>
          </Box>
        </Box>
        <div>
          {asset?.title === "Floor Plan" && (
            <Carousel
              renderArrow={myArrow}
              showArrows={true}
              breakPoints={breakPoints}
              initialActiveIndex={asset?.imageIndex}
            >
              {props?.assets
                ?.filter((i) => i?.asset_type === 7)
                .map((val) => {
                  return (
                    <img
                      key={val?.id}
                      src={val?.url}
                      alt=""
                      height="500px"
                      width="100%"
                    />
                  );
                })}
            </Carousel>
          )}
          {
            asset?.title === "PDF Documents" && (
              <>
                {props?.assets
                  ?.filter((i) => i?.asset_type === 4)
                  .map((val) => {
                    return (
                      <div style={{ height: "500px", marginLeft: "40px" }}>
                        <DocumentViewer url={val?.url} />
                      </div>
                    );
                  })}
              </>
            )
            // <Carousel renderArrow={myArrow} showArrows={true} breakPoints={breakPoints}  >

            //     {
            //         props?.assets?.filter((i) => i?.asset_type === 4).map((val) => {
            //             return (
            //                 <div style={{ height: "500px" }}>
            //                     <DocumentViewer url={val?.url} />
            //                 </div>
            //             )
            //         })
            //     }

            // </Carousel>
          }
          {asset?.title === "Videos" && (
            <Carousel
              renderArrow={myArrow}
              showArrows={true}
              breakPoints={breakPoints}
              initialActiveIndex={asset?.imageIndex}
            >
              {props?.assets
                ?.filter((i) => i?.asset_type === 3)
                .map((val) => {
                  return (
                    <video
                      style={{
                        objectFit: "cover",
                        height: "500px",
                        width: "100%",
                      }}
                      controls
                    >
                      <source key={val?.id} src={val.url} type="video/mp4" />
                    </video>
                  );
                })}
            </Carousel>
          )}
          {asset?.title === "360 VR View" && (
            <Carousel
              renderArrow={myArrow}
              showArrows={true}
              breakPoints={breakPoints}
              initialActiveIndex={asset?.imageIndex}
            >
              {props?.assets
                ?.filter((i) => i?.asset_type === 5)
                .map((val) => {
                  return (
                    <img
                      key={val?.id}
                      src={val?.url}
                      alt=""
                      height="500px"
                      width="100%"
                    />
                  );
                })}
            </Carousel>
          )}

          {asset?.title === "Amenities" && (
            <Carousel
              renderArrow={myArrow}
              showArrows={true}
              breakPoints={breakPoints}
              initialActiveIndex={asset?.imageIndex}
            >
              {props?.assets
                ?.filter((i) => i?.asset_type === assestType.General_Images)
                .map((val) => (
                  <img
                    key={val?.id}
                    src={val?.url}
                    alt=""
                    height="500px"
                    width="100%"
                  />
                ))}
            </Carousel>
          )}

          {asset?.title === "General Images" && (
            <Carousel
              renderArrow={myArrow}
              showArrows={true}
              breakPoints={breakPoints}
              initialActiveIndex={asset?.imageIndex}
            >
              {props?.assets
                ?.filter((i) => i?.asset_type === assestType.Facility_Images)
                .map((val) => (
                  <img
                    key={val?.id}
                    src={val?.url}
                    alt=""
                    height="500px"
                    width="100%"
                  />
                ))}
            </Carousel>
          )}
        </div>
      </Dialog>
    </>
  );
};
