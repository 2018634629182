import React from "react"
import { CustomFileUploadStyle } from "./style";
import { Stack, Grid, Avatar, Box, Typography } from '@mui/material'
import UploadIcon from "../../screens/marketingPortalConfigurartion/assets/uploadIcon";
import { allowed_file_size, deleteS3File, multiFileUpload } from "../../utils";
import { AlertContext, BackdropContext } from "../../contexts";
import FileViewer from "react-file-viewer";
import { DialogDrawer } from "../dialogDrawer";
import { DeleteIcon } from "../../assets";
import { Label } from "../label";
import { v4 as uuidv4 } from 'uuid';

export const CustomFileUpload = ({
    accept = "image/*, application/pdf",
    selected = [],
    handleUpload = () => false,
    handleDelete = () => false,
    label = "",
    isRequired = false,
    labelStyle = "",
    error = "",
    isReadonly = false
}) => {
    const classes = CustomFileUploadStyle()
    const alert = React.useContext(AlertContext)
    const backdrop = React.useContext(BackdropContext)
    const [openFile, setOpenFile] = React.useState({
        bool: false,
        selected: ""
    })

    const handleUploadAssets = async (value) => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "Loading",
        });
        let uploaded_file = await multiFileUpload(value, {}, alert, allowed_file_size)
        const constructedAttachment = uploaded_file?.map((e) => {
            return {
                url: e?.url,
                file_meta: e?.file_meta,
                is_active: e?.is_active,
                rowId: uuidv4()
            }
        })
        await backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "Loading",
        });
        await handleUpload(constructedAttachment)
    }

    const handleDeleteAssets = (value) => {
        deleteS3File({ urls: [value?.url] });
        handleDelete(value)
    }

    return (
        <>
            {label?.length > 0 && <Label label={label} style={labelStyle} isRequired={isRequired} />}
            <Grid container spacing={2}>
                {
                    !isReadonly &&
                    <Grid item xs={4}>
                        <div>
                            <label>
                                <Stack className={classes.button} variant='contained' spacing={1}>
                                    <UploadIcon />
                                    Upload image
                                </Stack>
                                <input type='file'
                                    style={{ display: 'none' }}
                                    accept={accept}
                                    onClick={(event) => {
                                        event.target.value = null;
                                    }}
                                    onChange={(e) => handleUploadAssets(e?.target?.files)}
                                    multiple
                                />
                            </label>
                        </div>
                    </Grid>
                }
                {
                    selected?.map((x) => {
                        return (
                            <Grid item xs={4} sx={{ position: "relative" }}>
                                {!isReadonly && <Box className={classes.deleteBox} onClick={() => handleDeleteAssets(x)}><DeleteIcon /></Box>}
                                {
                                    x?.file_meta?.type === "pdf" ?
                                        <Box className={classes.fileBox} onClick={() => setOpenFile({
                                            bool: true,
                                            selected: x
                                        })}>
                                            <FileViewer fileType={x?.file_meta?.type} filePath={x?.url} />
                                        </Box>
                                        :
                                        <Avatar src={x?.url} variant="square" className={classes.fileBox} onClick={() => setOpenFile({
                                            bool: true,
                                            selected: x
                                        })} />
                                }
                            </Grid>
                        )
                    })
                }
            </Grid>
            {error?.length > 0 && (
                <Typography variant={"caption"} color={"error"} mt={1}>
                    {error}
                </Typography>
            )}
            <DialogDrawer
                header={"View"}
                maxWidth={"sm"}
                handleClose={() => setOpenFile({
                    bool: false,
                    selected: ""
                })}
                open={openFile?.bool}
                height={"auto"}
                borderRadius={"12px"}
                padding={'0px'}
                onClose={() => setOpenFile({
                    bool: false,
                    selected: ""
                })}
                component={
                    <>
                        {
                            openFile?.selected?.file_meta?.type === "pdf" ?
                                <Box className={classes.viewBox}>
                                    <center>
                                        <FileViewer fileType={"pdf"} filePath={openFile?.selected?.url} />
                                    </center>
                                </Box> :
                                <Avatar src={openFile?.selected?.url} className={classes.viewBox}></Avatar>
                        }
                    </>
                }
            />
        </>
    )
}