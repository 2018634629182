import React from "react";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Divider,
  Drawer,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import styled from "@mui/material/styles/styled";

import FilterIcon from "../../assets/filterIcon";
import {
  AlertDialog,
  FilterGenerator,
  SearchFilter,
  Subheader,
  TableWithPagination,
  UseDebounce,
} from "../../components";
import { config } from "../../config";
import { AuthContext, BackdropContext, AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import {
  enum_types,
  getCompanyOption,
  getRoutePermissionNew,
  NetWorkCallMethods,
  AlertProps,
} from "../../utils";
import { StatusOptionListLang } from "../../utils/accessGates/accessGatesListUtils";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import {
  FreeTextInvoiceTableHeading,
  FreeTextInvoiceTablePath,
} from "../../utils/freeTextInvoice";
import StepsContent from "../createInvoiceFreeText/stepsContent";
import { CreateEditInvoiceDrawer } from "./createEditInvoiceDrawer";
import { FreeTextInvoiceContext } from "./freeTextInvoiceContext";
import { FreeTextInvoiceStyle } from "./style";
import { accessCheckRender } from "../../utils";
import { CreateEditInvoice } from "./createEditInvoice";

const CustomPaper = styled("div")(({ theme }) => ({
  border: "2px solid white",
  borderRadius: theme.palette.borderRadius,
  boxShadow: "0px 0px 16px #00000014",
  backgroundColor: "white",
  padding: "16px",
  height: `calc(100vh - 152px)`,
  overflow: "hidden",
  margin: "16px",
}));

const FreeTextInvoice = ({ t = () => false }) => {
  const defaultFilterState = { status: [true] };

  const {
    freeTextData,
    setFreeTextData,
    stateData,
    getFreeTextInvoice,
    setStateData,
    updateState,
    handleOnClose,
    getCustomerList,
    setInitialType,
  } = React.useContext(FreeTextInvoiceContext);

  const classes = FreeTextInvoiceStyle();
  const auth = React.useContext(AuthContext);
  const backdrop = React.useContext(BackdropContext);
  const debounce = UseDebounce();
  const navigate = useNavigate();
  const alert = React.useContext(AlertContext);

  const type =
    window.location.pathname === Routes.freeTextInvoice
      ? null
      : window.location.pathname === Routes.creditNote
      ? "Credit"
      : "Debit";
  const Heading = FreeTextInvoiceTableHeading(t);

  const [companyList, setCompanyList] = React.useState([]);
  const [permission, setPermission] = React.useState({});
  const [selectedCompany, setSelectedCompany] = React.useState({});
  const [isDisableBtn, setIsDisableBtn] = React.useState(false);

  const [publish, setPublish] = React.useState(false);
  const [tableData, setTableData] = React.useState({});

  const [filterData, setFilterData] = React.useState(defaultFilterState);
  const [filterDrawer, setFilterDrawer] = React.useState(false);
  const [confirmation, setComfirmation] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});

  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");
  const limitParam = searchParams.get("limit");

  // Default values if not set in URL
  const pageFromParams = pageParam ? parseInt(pageParam, 10) : 1;
  const limitFromParams = limitParam ? parseInt(limitParam, 10) : 10;

  const [page, setPage] = React.useState(pageFromParams);
  const [limit, setLimit] = React.useState(limitFromParams);

  const updatePage = (newPage, newLimit = limit) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", newPage.toString());
    searchParams.set("limit", newLimit.toString());

    // Update URL without reloading
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
    // Update state
    setPage(newPage);
    setLimit(newLimit);
  };

  const currentOffset = (page - 1) * limit;
  React.useEffect(() => {
    const _ = getRoutePermissionNew(auth);
    if (_) {
      setPermission(_);
      if (_?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          setCompanyList(company?.list);
          setSelectedCompany(company?.selected);
          getCustomerList(
            currentOffset,
            limitFromParams,
            "",
            [],
            [],
            company?.selected?.value
          );
        }
      }
    }
  }, [auth]);

  const handleCompanyChange = (e) => {
    setSelectedCompany(e);
    getCustomerList(0, 10, "", [], [], e?.value);
  };

  //handle pagination
  const handlePagination = (newPage) => {
    updatePage(newPage, limit);
  };

  const handleChangeLimit = (newLimit) => {
    updatePage(1, newLimit); // Reset page to 1 when limit changes
  };

  const onApplyFilter = (value) => {
    const constructedInvoiceType = value?.invoiceType?.map(
      (data) => data?.value
    );
    setFilterData(value);
    getCustomerList(
      0,
      10,
      "",
      constructedInvoiceType,
      value?.status,
      null,
      value?.accountNo?.label
    );
  };

  const handleTableIcon = (type, data) => {
    if (type === "view") {
      setInitialType(type);
      getFreeTextInvoice(data, type);
      return setTableData(data);
    } else if (type === "edit") {
      setInitialType(type);
      getFreeTextInvoice(data, type);
    } else if (type === "double_click") {
      setInitialType("view");
      getFreeTextInvoice(data, "view");
      setTableData(data);
    } else if (type === "publish_invoice") {
      setComfirmation(true);
      setSelectedRow(data);
    }
  };

  const publishInvoice = (selectedRow) => {
    setIsDisableBtn(true);
    if (
      selectedRow?.organization_id &&
      selectedRow?.integrated_account_id === null
    ) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Your Account is not Synced to ERP"),
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
      setIsDisableBtn(false);
      return false;
    }
    let id = selectedRow?.id;
    const payload = {
      id: id,
    };
    NetworkCall(
      `${config.api_url}/free_text_invoice/published_to`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        updateProspect(id);
        setComfirmation(false);
      })
      .catch((error) => {
        setIsDisableBtn(false);
        console.log(error);
      });
  };

  const updateProspect = (id) => {
    setIsDisableBtn(true);
    const payload = { status: "Published" };
    const variables = {
      id: id,
      payload: payload,
    };
    NetworkCall(
      `${config.api_url}/queries/free_text_invoice/update`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((rs) => {
        setPublish(!publish);
        setSelectedRow({});
        setIsDisableBtn(false);
        getCustomerList(0, 10, "", [], [], selectedCompany?.value);
      })
      .catch((er) => {
        setIsDisableBtn(false);
        console.log(er);
      });
  };

  const editDetailFromView = () => {
    navigate(Routes.createInvoiceFreeText, {
      state: { type: "edit", invoiceDataList: freeTextData?.invoiceDataList },
    });
  };

  const closePublish = () => {
    setPublish(!publish);
    setFreeTextData({ ...freeTextData, view: false });
  };

  const handleSearch = (data) => {
    setFreeTextData({ ...freeTextData, searchText: data });
    debounce(() => getCustomerList(0, 10, data, [], [], null), 800);
  };

  const manualResponse = (array) =>
    array?.list?.map((_) => ({
      ..._,
      label: _?.account_no,
      value: _?.id,
    }));

  const render = () => (
    <Box>
      <Subheader
        title={type === "Credit" ? t("Credit Invoice") : t("Customer Invoice")}
        hideBackButton={stateData?.step === 3 ? false : true}
        select={true}
        options={companyList}
        value={selectedCompany}
        onchange={(e) => {
          handleCompanyChange(e);
        }}
        useCustomNavigation={true}
        goBack={() => {
          setStateData({ ...stateData, step: 2, createEditList: false });
          updateState("createPopup", true);
        }}
        isReadOnly={stateData?.step === 3}
      />
      {stateData?.step === 3 ? (
        <CreateEditInvoice />
      ) : (
        <CustomPaper>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <SearchFilter
                placeholder={t("Search")}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Grid>
            <Grid item xs={8}>
              <Box display="flex" justifyContent="flex-end">
                <Stack
                  direction="row"
                  divider={
                    <Divider
                      orientation="vertical"
                      flexItem
                      variant="middle"
                      sx={{
                        height: 40,
                        marginInline: "16px",
                        marginTop: "0px",
                      }}
                    />
                  }
                >
                  <Box onClick={() => setFilterDrawer(!filterDrawer)}>
                    <FilterIcon />
                  </Box>
                  {permission?.create && (
                    <Button
                      variant="contained"
                      className={classes.createBtn}
                      onClick={() => {
                        setInitialType("create");
                        setFreeTextData({
                          ...freeTextData,
                          createPopup: !freeTextData?.createPopup,
                          is_proxy: false,
                        });
                      }}
                    >
                      {t("Create")}
                    </Button>
                  )}
                </Stack>
              </Box>
            </Grid>
          </Grid>

          <TableWithPagination
            heading={Heading}
            rows={freeTextData?.tableRow}
            path={FreeTextInvoiceTablePath}
            showpagination={true}
            showpdfbtn={false}
            showexcelbtn={false}
            showSearch={false}
            handleIcon={handleTableIcon}
            onClick={() => false}
            tableType="no-side"
            dataType={[
              { type: ["more_free_text_invoice"], name: "icon" },
              { type: ["text"], name: "invoice_no" },
              { type: ["text"], name: "date" },
              { type: ["text"], name: "type" },
              { type: ["text"], name: "account_no" },
              { type: ["text"], name: "account_name" },
              { type: ["text"], name: "invoice_type" },
              { type: ["text"], name: "total_amount_due" },
              { type: ["description"], name: "description" },
              { type: ["text"], name: "created_by" },
              { type: ["kyc_status"], name: "status" },
            ]}
            handlePagination={handlePagination}
            handleChangeLimit={handleChangeLimit}
            totalRowsCount={freeTextData?.count}
            page={page}
            limit={limit}
            height="calc(100vh - 300px)"
            view={permission?.read}
            edit={permission?.update}
            delete={false}
            publish_invoice={true}
            enable_double_click={true}
          />
        </CustomPaper>
      )}

      <AlertDialog
        open={freeTextData?.view}
        onClose={() =>
          setFreeTextData({ ...freeTextData, view: !freeTextData?.view })
        }
        header={t("View")}
        component={
          <Box p={2}>
            <StepsContent
              type="view"
              stateData={freeTextData?.invoiceDataList}
              editDetailFromView={editDetailFromView}
              publishInvoice={publishInvoice}
              viewData={tableData}
              selectedCompany={selectedCompany}
              isDisableBtn={isDisableBtn}
            />
          </Box>
        }
        lg
      />

      <React.Fragment key="right">
        <Drawer
          anchor="right"
          open={freeTextData?.createPopup}
          onClose={() => handleOnClose()}
        >
          <CreateEditInvoiceDrawer />
        </Drawer>
      </React.Fragment>

      <FilterGenerator
        open={filterDrawer}
        onClose={() => setFilterDrawer(!filterDrawer)}
        onApply={(value) => onApplyFilter(value)}
        defaultState={defaultFilterState}
        components={[
          type === null && {
            component: "select",
            value: filterData?.invoiceType,
            options: [],
            isMulti: true,
            label: t("InvoiceType"),
            placeholder: t("Select Invoice Type"),
            state_name: "invoiceType",
            loadOptions: (search, array, handleLoading) =>
              loadOptionsApis(
                "/enum",
                { enumName: [enum_types.free_text_invoice_type] },
                search,
                array,
                handleLoading,
                "free_text_invoice_type"
              ),
            debounceTimeout: 800,
            isPaginate: true,
          },
          {
            component: "select",
            value: filterData?.accountNo,
            options: [],
            isMulti: false,
            label: t("Account Number"),
            state_name: "accountNo",
            placeholder: t("Select Account Number"),
            loadOptions: (search, array, handleLoading) =>
              loadOptionsApis(
                "account/get_all",
                {
                  company_id: selectedCompany?.value,
                  type: [],
                },
                search,
                array,
                handleLoading,
                "data",
                {},
                manualResponse
              ),
            debounceTimeout: 800,
            isPaginate: true,
          },
          {
            component: "toggleButton",
            value: filterData?.status,
            options: StatusOptionListLang(t),
            isMulti: true,
            state_name: "status",
            label: t("Status"),
          },
        ].filter(Boolean)}
      />

      <Dialog
        open={confirmation}
        onClose={() => setComfirmation(!confirmation)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.publishDialog}
      >
        <DialogContent sx={{ padding: 0 }}>
          <DialogContentText sx={{ background: "transparent" }}>
            <div
              style={{
                paddingRight: "60px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                backgroundImage: `url(${"images/success.svg"})`,
                backgroundRepeat: "no-repeat",
                height: 250,
                width: 600,
              }}
            >
              <div className={classes.proceedDialog}>
                <Stack spacing={3}>
                  <Typography className={classes.convert}>
                    {tableData?.type === "Credit"
                      ? t("Are you sure you want to publish credit Note")
                      : t("Are you sure you want to publish invoice")}
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.marginTop}
                  >
                    <Button
                      className={classes.yes}
                      onClick={() => publishInvoice(selectedRow)}
                      disabled={isDisableBtn}
                    >
                      {t("Yes")}
                    </Button>
                    <Button
                      className={classes.No}
                      onClick={() => setComfirmation(!confirmation)}
                    >
                      {t("No")}
                    </Button>
                  </Box>
                </Stack>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={publish}
        onClose={closePublish}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.publishDialog}
      >
        <DialogContent sx={{ padding: 0 }}>
          <DialogContentText sx={{ background: "transparent" }}>
            <div
              style={{
                paddingInlineEnd:
                  auth?.auth?.auth?.language === "ar" ? "290px" : "60px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                backgroundImage: `url(${"images/success.svg"})`,
                backgroundRepeat: "no-repeat",
                height: 250,
                width: 600,
              }}
            >
              <Stack spacing={2} alignItems={"center"}>
                <Typography className={classes.publishPopupTitle}>
                  {tableData?.type === "Credit"
                    ? t("Credit Note published successfully")
                    : t("InvoicePublishedSuccessfully")}
                </Typography>

                <Box className={classes.publishPopupBtn} onClick={closePublish}>
                  <Typography className={classes.publishbtnText}>
                    {t("Okay")}
                  </Typography>
                </Box>
              </Stack>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );

  return <Box>{accessCheckRender(render, permission)}</Box>;
};

export default withTranslation("freeTextInvoice")(FreeTextInvoice);
