import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { CloseIconWithBG } from "../../../assets";
import { FormGenerator, UploadComponent } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import {
  AlertProps,
  LocalStorageKeys,
  NetWorkCallMethods,
  useWindowDimensions,
} from "../../../utils";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";
import {
  ItemOptionList,
  StatusOptionList,
} from "../../../utils/insepectionMaster";
import { InspectEditStyles } from "./style";

const Form = ({
  company_id = "",
  data = {},
  setData = () => false,
  reload = () => false,
  onClose = () => false,
  t,
  itemTypeOptions = [],
  uomOptions = [],
  company = {},
}) => {
  const alert = React.useContext(AlertContext);
  const [assets, setAssets] = React.useState([]);
  const [isDisableBtn, setIsDisableBtn] = React.useState(false);
  const classes = InspectEditStyles();
  const size = useWindowDimensions();
  const [isSmartDevice, setIsSmartDevice] = React.useState(false);

  const revenueTooltip = t("This field is mandatory when ERP is enabled");

  //update state of master
  const updateState = (key, value) => {
    let error = data.error;
    error[key] = "";
    if (key === "item_type") {
      setData({
        ...data,
        [key]: value,
        error,
        item_category: "",
        item_subcategory: "",
      });
    } else if (key === "item_category") {
      setData({ ...data, [key]: value, error, item_subcategory: "" });
    } else if (key === "tax_group") {
      setData({ ...data, [key]: value, error, hsn_sac_code: value.hsn_code });
    } else {
      setData({ ...data, [key]: value, error });
    }
  };
  //get Inspection Items Based on Id
  const getInspectionItemsById = (item) => {
    const payload = {
      id: item?.id,
    };
    NetworkCall(
      `${config.api_url}/inspection_items/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        const val = response?.data?.data;
        setData({
          ...data,
          isEdit: data?.isEdit,
          id: val?.id,
          item: val?.name,
          item_id: item?.reference_no,
          status: val?.is_active,
          item_type: { label: val?.item, value: val?.item },
          name: val?.name,
          assets: val?.assets ?? [],
          item_category: { value: val?.category_id, label: val?.category },
          item_subcategory: {
            value: val?.subcategory_id,
            label: val?.subcategory,
          },
          manufacturer_name: {
            value: val?.manufacturer_id,
            label: val?.manufacturer,
          },
          tax_group: { value: val?.vat_group_id, label: val?.vat_group_name },
          hsn_sac_code: val?.hsn_sac_code,
          is_billing: val?.is_billing,
          is_consumable: val?.is_consumable,
          is_FixedAsset: val?.is_fixed_asset,
          is_Taxable: val?.is_taxable,
          pantry_billing: val?.pantry_billing,
          cost_price: val?.costing_value ?? null,
          sale_price: val?.sale_value ?? null,
          model_no: val?.model_no,
          parentItem:
            val?.parent_id !== null
              ? { label: val?.parent_name, value: val?.parent_id }
              : "",
          uom:
            val?.period_type !== null
              ? { value: val?.period_type, label: val?.period_type }
              : "",
          revenue_account: val?.revenue ?? null,
          expense_number: val?.expense ?? null,
          assets_number: val?.assetsNumber ?? null,
        });
        setAssets(val?.assets ?? []);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  React.useEffect(() => {
    if (data?.isEdit || data?.isView) {
      getInspectionItemsById(data);
    }
    //eslint-disable-next-line
  }, []);
  //validate form
  const validateForm = () => {
    let isValid = true;
    let error = data.error;

    if (data?.name?.length === 0) {
      isValid = false;
      error.name = `${t("itemName")} ${t("isRequired")}`;
    }
    if (data?.item_type?.length === 0) {
      isValid = false;
      error.item_type = `${t("itemType")} ${t("isRequired")}`;
    }
    if (data?.item_category?.length === 0) {
      isValid = false;
      error.item_category = `${t("Item Category")} ${t("isRequired")}`;
    }
    if (data?.item_subcategory?.length === 0) {
      isValid = false;
      error.item_subcategory = `${t("Item subcategory")} ${t("isRequired")}`;
    }
    // if (data?.parentItem?.length === 0) {
    //     isValid = false;
    //     error.parentItem = `${t("Parent Item is required")}`;
    // }
    if (data?.manufacturer_name?.length === 0) {
      isValid = false;
      error.manufacturer_name = `${t("Manufacturer Name")} ${t("isRequired")}`;
    }
    if (data?.model_no?.length === 0 && data?.item_type?.value !== "Services") {
      isValid = false;
      error.model_no = `${t("Model Number")} ${t("isRequired")}`;
    }
    if (data?.is_billing && data?.cost_price === null) {
      isValid = false;
      error.cost_price = `${t("Cost price is Required")}`;
    }
    if (data?.is_billing && data?.sale_price === null) {
      isValid = false;
      error.sale_price = `${t("Sale price is Required")}`;
    }
    if (data?.status?.length === 0) {
      isValid = false;
      error.status = `${t("status")} ${t("isRequired")}`;
    }
    if (data?.uom?.length === 0) {
      isValid = false;
      error.uom = `${t("UOM is Required")}`;
    }
    setData({ ...data, error });
    return isValid;
  };
  const handleSubmit = () => {
    if (data?.isView) {
      setData({ ...data, isView: false, isEdit: true });
    } else {
      onSubmit();
    }
  };
  const handleSmartDeviceSubmission = async (inspectionItemId) => {
    if (!isSmartDevice) return true;

    const smartDevicePayload = {
      uuid: inspectionItemId,
      device_type: data?.deviceType?.value,
      device_category: data?.deviceCategory?.value,
      label: data?.label,
      company_id: company_id,
      room_name: data?.roomName,
    };

    try {
      await NetworkCall(
        `${config.api_url}/smart_iot/add_smart_device`,
        NetWorkCallMethods.post,
        smartDevicePayload,
        null,
        true,
        false
      );
      return true;
    } catch (err) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg:
          err?.response?.data?.error?.message ??
          t("Failed to register smart device"),
      });
      return false;
    }
  };

  const validateSmartDeviceForm = () => {
    if (!isSmartDevice) return true;

    let isValid = true;
    let error = data.error;

    if (!data?.deviceType?.value) {
      isValid = false;
      error.deviceType = t("Device Type is required");
    }
    if (!data?.deviceCategory?.value) {
      isValid = false;
      error.deviceCategory = t("Device Category is required");
    }
    if (!data?.label) {
      isValid = false;
      error.label = t("Label is required");
    }
    if (!data?.roomName) {
      isValid = false;
      error.roomName = t("Room Name is required");
    }

    setData({ ...data, error });
    return isValid;
  };

  //on submit
  const onSubmit = async () => {
    if (validateForm() && validateSmartDeviceForm()) {
      setIsDisableBtn(true);
      const payload = {
        name: data?.name,
        inventory_id: data?.item ? data?.name?.value : undefined,
        is_inventory: false,
        is_active: data?.status,
        item: data?.item_type?.value,
        company_id: data?.isEdit ? undefined : company_id,
        id: data?.id ?? undefined,
        size_type: "instruction_item_mapping_size",
        assets: assets,
        category_id: data?.item_category?.value,
        subcategory_id: data?.item_subcategory?.value,
        manufacturer_id: data?.manufacturer_name?.value ?? null,
        is_billing: data?.is_billing,
        is_taxable: data?.is_Taxable,
        hsn_sac_code: data?.hsn_sac_code,
        vat_group_id: data?.tax_group?.value ?? null,
        is_consumable:
          data?.item_type?.value === "Inventory" ? data?.is_consumable : null,
        is_fixed_asset: data?.is_FixedAsset ?? false,
        parent_id: data?.parentItem?.value ?? null,
        sale_value: data?.sale_price,
        costing_value: data?.cost_price,
        model_no: data?.model_no,
        pantry_billing:
          data?.item_type?.value === "Inventory" ||
          data?.item_type?.value === "Services"
            ? data?.pantry_billing
            : null,
        period_type: data?.uom?.value ?? null,
        revenue_account: data?.revenue_account?.value ?? null,
        expense_number: data?.expense_number?.value ?? null,
        assets_number: data?.assets_number?.value ?? null,
      };

      try {
        const response = await NetworkCall(
          `${config.api_url}/inspection_items/upsert`,
          NetWorkCallMethods.post,
          payload,
          null,
          true,
          false
        );

        // Handle smart device registration after successful item creation
        const smartDeviceSuccess = await handleSmartDeviceSubmission(
          response?.data?.data?.id
        );

        if (smartDeviceSuccess) {
          reload();
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `${t("Item Successfully")} ${
              data?.isEdit ? t("Edited") : t("Created")
            }`,
          });
          onClose();
        }
      } catch (err) {
        setIsDisableBtn(false);
        if (err?.response?.status === 406) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t(
              "Your Inspection limit has been hit! Please upgrade your plan!"
            ),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg:
              err?.response?.data?.error?.message ?? t("Something Went Wrong"),
          });
        }
      } finally {
        setIsDisableBtn(false);
      }
    }
  };
  const InspectionItemManualResponse = (array) => {
    const details = array?.map((i) => {
      return {
        label: i?.name,
        value: i?.id,
        ...i,
      };
    });
    return details;
  };

  const manualResponse = (array) => {
    const details = array?.map((i) => {
      return {
        label: i?.name,
        value: i?.id,
      };
    });
    return details;
  };
  //form data
  const changeInput = [
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      isActive: data?.isEdit || data?.isView,
      component: "text",
      label: t("Item Id"),
      placeholder: t("Enter Item Id"),
      value: data?.item_id,
      color: "#F6F6F6",
      onChange: (value) => updateState("item_id", value.target.value),
      error: data?.error?.item_id,
      isReadonly: true,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      isActive: true,
      component: "select",
      label: t("itemType"),
      placeholder: t("selectItemType"),
      value: data?.item_type,
      onChange: (value) => updateState("item_type", value),
      error: data?.error?.item_type,
      isRequired: true,
      options: itemTypeOptions,
      debounceTimeout: 800,
      isPaginate: true,
      isReadonly: data?.isView,
      menuOptionHeight: "150px",
      menuPlacement: "bottom",
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      isActive: true,
      component: "select",
      label: t("Item Category"),
      placeholder: t("Select Item Category"),
      value: data?.item_category,
      onChange: (value) => updateState("item_category", value),
      error: data?.error?.item_category,
      isRequired: true,
      loadOptions: (search, array, handleLoading) =>
        loadOptionsApis(
          "inspection_item_category",
          {
            item_type: [data?.item_type?.value],
          },
          search,
          array,
          handleLoading,
          "list"
        ),
      key: JSON.stringify(data?.item_type),
      debounceTimeout: 800,
      isPaginate: true,
      isReadonly: data?.isView,
      menuOptionHeight: "150px",
      menuPlacement: "bottom",
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      isActive: data?.item_category !== "" ? true : false,
      component: "select",
      label: t("Item Subcategory"),
      placeholder: t("Select Item Subcategory"),
      value: data?.item_subcategory,
      onChange: (value) => updateState("item_subcategory", value),
      error: data?.error?.item_subcategory,
      isRequired: true,
      loadOptions: (search, array, handleLoading) =>
        loadOptionsApis(
          "inspection_item_subcategory",
          {
            category_id: data?.item_category?.value,
          },
          search,
          array,
          handleLoading,
          "list"
        ),
      key: JSON.stringify(data?.item_category),
      debounceTimeout: 800,
      isPaginate: true,
      isReadonly: data?.isView,
      menuOptionHeight: "150px",
      menuPlacement: "bottom",
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: data?.isEdit || data?.item_category === "" ? 12 : 6,
        lg: data?.isEdit || data?.item_category === "" ? 12 : 6,
      },
      isActive: true,
      component: "text",
      label: t("itemName"),
      placeholder: t("enterItemName"),
      value: data?.name,
      onChange: (value) => updateState("name", value.target.value),
      error: data?.error?.name,
      isReadonly: data?.isView,
      isRequired: true,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      isActive: true,
      component: "select",
      label: t("Manufacturer Name"),
      placeholder: t("Select Manufacturer Name"),
      value: data?.manufacturer_name,
      onChange: (value) => updateState("manufacturer_name", value),
      error: data?.error?.manufacturer_name,
      isRequired: true,
      loadOptions: (search, array, handleLoading) =>
        loadOptionsApis(
          "manufacturer_master",
          {
            item_type: [data?.item_type?.value],
          },
          search,
          array,
          handleLoading,
          "list"
        ),
      debounceTimeout: 800,
      isPaginate: true,
      menuOptionHeight: "150px",
      menuPlacement: "bottom",
      isReadonly: data?.isView,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      isActive: data?.item_type?.value !== "Services",
      component: "text",
      label: t("Model Number"),
      placeholder: t("Enter Model Number"),
      value: data?.model_no,
      onChange: (value) => updateState("model_no", value.target.value),
      error: data?.error?.model_no,
      isRequired: true,
      isReadonly: data?.isView,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      isActive: true,
      component: "select",
      label: t("Parent Item"),
      placeholder: t("Select Parent Item"),
      value: data?.parentItem,
      onChange: (value) => updateState("parentItem", value),
      error: data?.error?.parentItem,
      // isRequired: true,
      loadOptions: (search, array, handleLoading) =>
        loadOptionsApis(
          "/inspection_items/getAll",
          {
            company_id: company_id,
          },
          search,
          array,
          handleLoading,
          "data",
          {},
          InspectionItemManualResponse
        ),
      debounceTimeout: 800,
      isPaginate: true,
      menuOptionHeight: "150px",
      menuPlacement: "bottom",
      isReadonly: data?.isView,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      isActive: true,
      component: "select",
      label: t("UOM"),
      placeholder: t(`Select UOM`),
      value: data?.uom,
      onChange: (value) => updateState("uom", value),
      error: data?.error?.uom,
      isRequired: true,
      options: uomOptions,
      debounceTimeout: 800,
      isPaginate: true,
      isReadonly: data?.isView,
      menuOptionHeight: "150px",
      menuPlacement: "bottom",
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      isActive: true,
      component: "text",
      label: t("Cost Price"),
      placeholder: t("Enter Cost Price"),
      type: "number",
      value: data?.cost_price,
      onChange: (value) => updateState("cost_price", value.target.value),
      error: data?.is_billing ? data?.error?.cost_price : "",
      endAdornment: company?.currency_symbol,
      isReadonly: data?.isView,
      isRequired: data?.is_billing ? true : false,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      isActive: true,
      component: "text",
      type: "number",
      label: t("Sale Price"),
      placeholder: t("Enter Sale Price"),
      value: data?.sale_price,
      onChange: (value) => updateState("sale_price", value.target.value),
      error: data?.is_billing ? data?.error?.sale_price : "",
      endAdornment: company?.currency_symbol,
      isReadonly: data?.isView,
      isRequired: data?.is_billing ? true : false,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      isActive: true,
      component: "select",
      type: "number",
      label: t("COA Main Account - Revenue"),
      placeholder: t("Enter Revenue Account"),
      isDisabled: data?.isView,
      value: data?.revenue_account,
      onChange: (value) => updateState("revenue_account", value),
      error: data?.error?.revenue_account,
      loadOptions: (search, array, handleLoading) =>
        loadOptionsApis(
          "/com_master/listAll",
          {
            company_id: company_id,
            is_active: [true],
          },
          search,
          array,
          handleLoading,
          "data",
          {},
          manualResponse
        ),
      debounceTimeout: 800,
      isPaginate: true,
      isTooltip: true,
      tooltip: revenueTooltip,
      tooltipPlacement: "top",
      menuOptionHeight: "150px",
      menuPlacement: "auto",
      valueTooltip: true,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      isActive: true,
      component: "select",
      type: "number",
      label: t("COA Main Number - Expense"),
      placeholder: t("Enter Expense Number"),
      isDisabled: data?.isView,
      error: data?.error?.expense_number,
      value: data?.expense_number,
      onChange: (value) => updateState("expense_number", value),
      loadOptions: (search, array, handleLoading) =>
        loadOptionsApis(
          "/com_master/listAll",
          {
            company_id: company_id,
            is_active: [true],
          },
          search,
          array,
          handleLoading,
          "data",
          {},
          manualResponse
        ),
      debounceTimeout: 800,
      isPaginate: true,
      isTooltip: true,
      tooltip: revenueTooltip,
      tooltipPlacement: "top",
      valueTooltip: true,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      isActive: true,
      component: "select",
      type: "number",
      label: t("COA Main Number - Assets"),
      placeholder: t("Enter Assets Number"),
      isDisabled: data?.isView,
      error: data?.error?.assets_number,
      value: data?.assets_number,
      onChange: (value) => updateState("assets_number", value),
      loadOptions: (search, array, handleLoading) =>
        loadOptionsApis(
          "/com_master/listAll",
          {
            company_id: company_id,
            is_active: [true],
          },
          search,
          array,
          handleLoading,
          "data",
          {},
          manualResponse
        ),
      debounceTimeout: 800,
      isPaginate: true,
      isTooltip: true,
      tooltip: revenueTooltip,
      tooltipPlacement: "top",
      valueTooltip: true,
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 6,
        lg: 6,
      },
      isActive:
        data?.item_type?.value === "Inventory" ||
        data?.item_type?.value === "Services",
      component: "toggle",
      label: t("Pantry Billing"),
      placeholder: t("Billing"),
      value: data?.pantry_billing,
      onChange: (value) => updateState("pantry_billing", value),
      error: data?.error?.pantry_billing,
      isRequired: true,
      isReadonly: data?.isView,
      options: ItemOptionList(t),
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 6,
        lg: 6,
      },
      isActive: data?.item_type?.value === "Inventory",
      component: "toggle",
      label: t("Consumable"),
      placeholder: t("Consumable"),
      value: data?.is_consumable,
      onChange: (value) => updateState("is_consumable", value),
      error: data?.error?.is_consumable,
      isRequired: true,
      isReadonly: data?.isView,
      options: ItemOptionList(t),
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 6,
        lg: 6,
      },
      isActive:
        data?.item_type?.value === "Inventory" ||
        data?.item_type?.value === "Tools" ||
        data?.item_type?.value === "Vehicles" ||
        data?.item_type?.value === "Assets",
      component: "toggle",
      label: t("Fixed Asset"),
      placeholder: t("Fixed Asset"),
      value: data?.is_FixedAsset,
      onChange: (value) => updateState("is_FixedAsset", value),
      error: data?.error?.is_FixedAsset,
      isRequired: true,
      isReadonly: data?.isView,
      options: ItemOptionList(t),
    },
  ];
  const form2 = [
    {
      size: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      isActive: true,
      component: "toggleButton",
      label: t("Billing"),
      value: data?.is_billing,
      isReadonly: data?.isView,
      onChange: (value) =>
        setData({
          ...data,
          is_billing: value,
          is_Taxable: false,
          is_consumable: false,
          hsn_sac_code: "",
          tax_group: "",
        }),
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      isActive: data?.is_billing,
      component: "toggleButton",
      label: t("Tax Enabled"),
      value: data?.is_Taxable,
      isReadonly: data?.isView,
      onChange: (value) =>
        setData({
          ...data,
          is_Taxable: value,
          is_consumable: false,
          hsn_sac_code: "",
          tax_group: "",
        }),
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      isActive: data?.is_Taxable,
      component: "select",
      label: t("Tax Group"),
      placeholder: t("Select Tax Group"),
      value: data?.tax_group,
      onChange: (value) => updateState("tax_group", value),
      error: data?.error?.tax_group,
      loadOptions: (search, array, handleLoading) =>
        loadOptionsApis(
          "vat-group/getAll",
          {
            country_id: JSON.parse(
              localStorage.getItem(LocalStorageKeys.selectedCompany)
            )?.data?.country?.id,
          },
          search,
          array,
          handleLoading,
          "vat_group_master"
        ),
      debounceTimeout: 800,
      isPaginate: true,
      menuOptionHeight: "150px",
      menuPlacement: "bottom",
      isReadonly: data?.isView,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      isActive: data?.is_Taxable,
      component: "text",
      label: t("HSN/SAC Code"),
      placeholder: t("Enter HSN/SAC Code"),
      value: data?.hsn_sac_code,
      onChange: (value) => updateState("hsn_sac_code", value.target.value),
      error: data?.error?.hsn_sac_code,
      isReadonly: true,
    },
    {
      size: {
        xs: 12,
        sm: 6,
        md: 6,
        lg: 6,
      },
      isActive: true,
      component: "toggle",
      label: t("status"),
      placeholder: t("status"),
      value: data?.status,
      onChange: (value) => updateState("status", value),
      error: data?.error?.status,
      isRequired: true,
      options: StatusOptionList(t),
      isReadonly: data?.isView,
    },
    // {
    //     size: {
    //         xs: 12,
    //         sm: 12,
    //         md: 12,
    //         lg: 12
    //     },
    //     isActive: true,
    //     component: "toggleButton",
    //     label: t("Smart Device?"),
    //     value: isSmartDevice,
    //     isReadonly: data?.isView,
    //     onChange: (value) => {
    //         setIsSmartDevice(value);
    //         if (!value) {
    //             setData({
    //                 ...data,
    //                 controllerId: "",
    //                 controllerPath: "",
    //                 deviceType: "",
    //                 deviceCategory: "",
    //                 label: ""
    //             });
    //         }
    //     },
    // }
  ];
  const form3 = [
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      isActive: isSmartDevice,
      component: "select",
      label: t("Device Type"),
      placeholder: t("Select Device Type"),
      value: data?.deviceType,
      onChange: (value) => updateState("deviceType", value),
      error: data?.error?.deviceType,
      loadOptions: (search, array, handleLoading) => {
        return Promise.resolve({
          options: [
            { label: "Controller", value: "CONTROLLER" },
            { label: "Monitor", value: "MONITOR" },
          ],
        });
      },
      isReadonly: data?.isView,
      debounceTimeout: 800,
      isPaginate: true,
      menuOptionHeight: "150px",
      menuPlacement: "bottom",
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      isActive: isSmartDevice,
      component: "select",
      label: t("Device Controller"),
      placeholder: t("Select Device Controller"),
      value: data?.deviceCategory,
      onChange: (value) => updateState("deviceCategory", value),
      error: data?.error?.deviceCategory,
      loadOptions: (search, array, handleLoading) => {
        return Promise.resolve({
          options: [
            { label: "AC", value: "ac" },
            { label: "Light", value: "light" },
            { label: "Fan", value: "fan" },
          ],
        });
      },
      isReadonly: data?.isView,
      debounceTimeout: 800,
      isPaginate: true,
      menuOptionHeight: "150px",
      menuPlacement: "bottom",
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      isActive: isSmartDevice,
      component: "text",
      label: t("Label"),
      placeholder: t("Enter Label"),
      value: data?.label,
      onChange: (value) => updateState("label", value.target.value),
      error: data?.error?.label,
      isReadonly: data?.isView,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      isActive: isSmartDevice,
      component: "text",
      label: t("Room Name"),
      placeholder: t("Enter Room Name"),
      value: data?.roomName,
      onChange: (value) => updateState("roomName", value.target.value),
      error: data?.error?.roomName,
      isReadonly: data?.isView,
    },
  ];
  return (
    <Box>
      {/*form generator */}
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        p={1.5}
        alignItems="center"
        sx={{ backgroundColor: "#F2F4F7" }}
      >
        <Typography className={classes.drawerHeader}>
          {data?.isView
            ? t("View Item")
            : data?.isEdit
            ? t("Edit Item")
            : t("Create Item")}
        </Typography>
        <Box onClick={onClose} style={{ cursor: "pointer" }}>
          <CloseIconWithBG />
        </Box>
      </Box>
      <Divider />
      <Box
        p={2}
        width="500px"
        height={size?.height - 120}
        overflow="scroll"
        position={"relative"}
      >
        <FormGenerator t={t} components={changeInput} />
        <Box mt={1}>
          <UploadComponent
            // readOnly={type?.label === resourceTypes?.users && data?.is_exisit}
            handleChange={(val) => updateState("assets", val)}
            logo_title={t("Upload Images")}
            errorMessage={data?.error?.assets}
            isError={data?.error?.assets?.length > 0}
            assets={assets}
            setAssets={setAssets}
            xs={3}
            height={"100px"}
            imageHeight={"98px"}
            readOnly={data?.isView}
          />
        </Box>
        <Box mt={1}>
          <FormGenerator t={t} components={form2} />
        </Box>
        {isSmartDevice && (
          <Box mt={1}>
            <FormGenerator t={t} components={form3} />
          </Box>
        )}
      </Box>
      <Box className={classes.fixedBottom}>
        <Button
          sx={{ height: "40px" }}
          variant="contained"
          fullWidth
          onClick={handleSubmit}
          disabled={isDisableBtn}
        >
          {data?.isEdit ? t("Save") : data?.isView ? t("edit") : t("Create")}
        </Button>
      </Box>
    </Box>
  );
};
export default withTranslation("inspectionItemmaster")(Form);
