import React from "react"
import { Steps } from "../../../screens/residentOnboarding/steps"
import BillingContactIcon from "../../../screens/residentOnboarding/utils/billingContactIcon"
import ContactIcon from "../../../screens/residentOnboarding/utils/contactIcon"
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions"
import { returnExisitContact, returnremoveExisitContact } from "../utils"


export const ContactAccount = ({ t = () => false, dataNew, updateStateNew, company_id, setDataNew, is_read_only , is_quotation , setState1=()=>false , state1 , is_opportunity=false , is_lead=false}) => {


    const chooseContact = (val, type) => {
        let data;
        let error = dataNew?.error;

        if (type === "delete") {
            data = returnremoveExisitContact(val);
        } else {
            data = returnExisitContact(val);
        }
        error["contact"] = ""
        setDataNew({
            ...dataNew,
            ...data,
            error
        })

    }
    return (

        <>
            <Steps
                t={t}
                component={[
                    {
                        type: "contactSelect",
                        header: "Choose the contact",
                        title: t('Choose contact'),
                        noSelectedTitle: t("No contacted selected to display"),
                        icon: <ContactIcon />,
                        options: [],
                        topHeader: "Choose The Contact",
                        handleChange: (key, value) => updateStateNew(key, value),
                        chooseContact: (value) => chooseContact(value),
                        value: dataNew?.contact,
                        onDelete: () => chooseContact(null, "delete"),
                        loadOptions: (search, array, handleLoading) =>
                            loadOptionsApis(
                              "queries/opportunity/contact/list",
                              {
                                is_drop_down:true
                              },
                              search,
                              array,
                              handleLoading,
                              "data",
                              {},
                            ),
                        error: dataNew?.error?.contact?.length > 0,
                        errorMsg: dataNew?.error?.contact,
                        isActive: true,
                        border: "none",
                        isReadOnly: is_read_only,
                        menuPlacement: "auto",
                        state: dataNew,
                        is_exist: dataNew?.is_exist
                    },
                    {
                        type: "chooseComponent",
                        header: t("Choose the Billing Account"),
                        title: t('Choose Billing Account'),
                        noSelectedTitle: t("No account selected to display"),
                        icon: <BillingContactIcon />,
                        options: [],
                        handleRemove: () => updateStateNew("billing", null),
                        onchange: (value) => updateStateNew("billing", value),
                        value: dataNew?.billing,
                        onDelete: () => updateStateNew("billing", null),
                        key: JSON.stringify({ contact: dataNew?.contact, email_id: dataNew?.email_id }),
                        loadOptions: (search, array, handleLoading, primary) => {

                            if (primary && (is_quotation || is_opportunity || is_lead)) {
                                return loadOptionsApis(
                                    "contact/primary_account",
                                    {
                                        contact_id: dataNew?.contact?.value ?? null,
                                        email_id: dataNew?.contact?.value ? undefined : dataNew?.email_id ?? null,
                                        company_id
                                    },
                                    search,
                                    array,
                                    handleLoading,
                                    "data"
                                )
                            }
                            return loadOptionsApis(
                                "queries/opportunity/contact_account",
                                {
                                    relationship: "Customer",
                                    company_id
                                },
                                search,
                                array,
                                handleLoading,
                                "data",
                                {},
                              )
                        },
                        isActive: true,
                        isRequired: false,
                        menuPlacement: dataNew?.is_exist ? 'bottom' : 'top',

                        border: "none",
                        boldHeader: true,
                        isBorder: true,
                        showRemove: true,
                        is_toggle: (is_quotation || is_opportunity || is_lead) ? true : false,
                        state1: state1,
                        setState1: (e) => setState1(e ?? false),
                        t

                    },
                ]}
            />
        </>
    )
}