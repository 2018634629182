/* eslint-disable react-hooks/exhaustive-deps */
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import { Avatar, Badge, Box, Button, Dialog, Divider, Drawer, Grid, Hidden, IconButton, Stack, Tab, Tabs, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { lightFormat } from "date-fns";
import React from 'react';
import { useNavigate } from 'react-router-dom';
import FilterIMG from "../../../assets/filter";
import { LoadingSection, SearchFilter, Subheader, TableWithPagination, TextBox } from '../../../components';
import VisitorSecurityFilter from "../../../components/visitorSecurityFilter";
import { config } from '../../../config';
import { AlertContext, AuthContext, BackdropContext } from "../../../contexts";
import { NetworkCall } from '../../../networkcall';
import { BackendRoutes, Routes } from "../../../router/routes";
import { AlertProps, constructPropertyList, convertTimeUtcToZoneCalander, getCompanyOption, LocalStorageKeys, NetWorkCallMethods, timeZoneConverter, FontFamilySwitch } from '../../../utils';
import {
    ApprovedHeading,
    ApprovedPath,
    ApprovedType,
    DeclinedHeading,
    DeclinedPath,
    DeclinedType,
    EntriesHeading,
    EntriesPath, EntriesType, WaitingForApprovalHeading,
    WaitingForApprovalPath,
    WaitingForApprovalType
} from '../../../utils/securityManager/domesticHelpRequestDataPoints';
import useWindowDimensions from '../../../utils/useWindowDimensions';
import { withTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
    },
    innerWindow: {
        borderRadius: theme.palette.borderRadius,
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        // padding: "16px",
        height: `calc(100vh - 147px)`,
        overflow: "hidden",
        margin: "14px"
    },
    searchFilter: {
        padding: "16px 0px 0px 0px",
        width: "30%"
    },
    table: {
        padding: "0px 16px 0px 16px"
    },
    rootCard: {
        backgroundColor: "white",
        borderRadius: "16px",
        boxShadow: "0px 3px 16px #00000014",
    },
    img: {
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #E4E8EE",
        padding: "2px 8px",
    },
    bottomBarTitle: {
        fontSize: "1.125rem",
        fontFamily: FontFamilySwitch().extraBold,
        color: theme.typography.color.primary,
    },
    title: {
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.secondary,
    },
    sub: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.Tertiary,
    },
    approved: {
        fontSize: "0.75rem",
        color: "#FFFFFF",
        fontFamily: FontFamilySwitch().bold,
        background: "#5AC782",
        padding: "1px 8px",
        borderRadius: theme.palette.borderRadius,
        display: "inline",
    },
    progress: {
        fontSize: "0.75rem",
        color: "#FFFFFF",
        fontFamily: FontFamilySwitch().bold,
        background: "#78B1FE",
        padding: "1px 8px",
        borderRadius: theme.palette.borderRadius,
        display: "inline",
    },
    rejected: {
        fontSize: "0.75rem",
        color: "#FFFFFF",
        fontFamily: FontFamilySwitch().bold,
        background: "#EC903F",
        padding: "1px 8px",
        borderRadius: theme.palette.borderRadius,
        display: "inline",
    },
    delete: {
        fontSize: "0.75rem",
        color: "#FFFFFF",
        fontFamily: FontFamilySwitch().bold,
        background: "red",
        padding: "1px 8px",
        borderRadius: theme.palette.borderRadius,
        display: "inline",
    },
    round: {
        color: "#00000014",
        fontSize: "40px",
    },
    border: {
        border: "1px solid #E4E8EE",
        marginTop: "-4px",
    },
    heading: {
        fontSize: "0.875rem",
        color: theme.typography.color.Tertiary,
        fontFamily: FontFamilySwitch().semiBold,
        marginTop: "10px",
    },
    name: {
        fontSize: "1rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    drawer: {
        "& .MuiDrawer-paper": {
            borderTopRightRadius: "16px",
            borderTopLeftRadius: "16px",
        },
        "& .MuiContainer-root": {
            padding: "0px 8px",
        },
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: theme.palette.borderRadius,
            padding: "0px",
        },
    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
        padding: "4px 8px 8px 8px",
    },
    qr: {
        fontSize: "1rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    expire: {
        fontSize: "0.75rem",
        color: theme.typography.color.Tertiary,
        fontFamily: FontFamilySwitch().regular,
    },
    cancelbtn: {
        padding: "12px",
        border: "1px solid #5078E1",
        backgroundColor: "white",
        borderRadius: "10px",
    },
    submitbtn: {
        borderRadius: "10px",
        padding: "12px 8px",
    },
    drawerHeaderStyle: {
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
        backgroundColor:"#F2F4F7"
    },
    drawerHeaderTextStyle: {
        fontSize: "1rem",
        fontWeight: "bolder",
        color: theme.typography.color.primary,
    },
    drawerFooterStyle: {
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
    },
    drawerButtonStyle: {
        "&:hover": "none",
        borderRadius: theme.palette.borderRadius,
        padding: "14px",
        background: theme.palette.primary.main,
        fontSize: "0.875rem",
        fontWeight: "normal",
        color: "#FFF",
        "&:disabled": {
            backgroundColor: "#E5E5E5 !important",
            color: "#737373",
        }
    },
    filterBox: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "12px"
    },
    reject_description_div: {
        display: "flex",
        backgroundColor: "#ffecec",
        alignItems: "center",
        justifyContent: "center",
        padding: "6px",
        marginBottom: "24px"
    },
    reject_text_style: {
        fontSize: "0.75rem",
        color: "#ff4b4b",
        fontFamily: FontFamilySwitch().semiBold,

    }
}))


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (children)}
        </div>
    );
}

const DomesticHelpRequest = props => {
    const { t } = props

    const classes = useStyles();


    const backdrop = React.useContext(BackdropContext);
    const size = useWindowDimensions();
    const [initialLoad, setInitialLoad] = React.useState(false)
    const [selectedProperty, setSelectedProperty] = React.useState({})
    const [propertyData, setPropertyData] = React.useState([])
    const [tabTitle, setTabTitle] = React.useState("Waiting For Approval")
    const navigate = useNavigate();
    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [value, setValue] = React.useState(0);
    const exportRef = React.useRef();
    const [disable, setDisable] = React.useState(false)
    // const [propertyIDS, setPropertyIDS] = React.useState([]);
    // eslint-disable-next-line
    const [unitList, setUnitList] = React.useState([]);
    const alert = React.useContext(AlertContext);
    const auth = React.useContext(AuthContext)

    const [isUnitListDialogOpen, setIsUnitListDialogOpen] = React.useState(false);

    const initialDeclineFormData = {
        remarkForDecline: "",
        error: {
            remarkForDecline: "",
        }
    }

    const [tab, setTab] = React.useState({
        offset: 0,
        page: 1,
        limit: 10
    })

    const [declineFormData, setDeclineFormData] = React.useState({ ...initialDeclineFormData });

    const [searchText, setSearchText] = React.useState("");

    const [isDomesticHelperRequestDialogOpen, setIsDomesticHelperRequestDialogOpen] = React.useState(false);

    const filterKeys1 = []
    const filterKeys2 = ["accessGate"]

    const [isDialogActionButton, setIsDialogActionButton] = React.useState(true);

    const [isAcceptActionButton, setIsAcceptActionButton] = React.useState(true);

    const [dialogTitle, setDialogTitle] = React.useState("");

    const [domesticHelperRequestDetails, setDomesticHelperRequestDetails] = React.useState([]);

    const [waitingForApprovalData, setWaitingForApprovalData] = React.useState({
        heading: WaitingForApprovalHeading(t),
        row: [],
        path: WaitingForApprovalPath,
        type: WaitingForApprovalType,
    });
    const [approvedData, setApprovedData] = React.useState({
        heading: ApprovedHeading(t),
        row: [],
        path: ApprovedPath,
        type: ApprovedType,
    });
    const [declinedData, setDeclinedData] = React.useState({
        heading: DeclinedHeading(t),
        row: [],
        path: DeclinedPath,
        type: DeclinedType,
    });
    const [entriesData, setEntriesData] = React.useState({
        heading: EntriesHeading(t),
        row: [],
        path: EntriesPath,
        type: EntriesType,
    });

    const updateDeclineFormData = (key, value) => {
        let error = declineFormData?.error;
        error[key] = "";
        setDeclineFormData({ ...declineFormData, [key]: value, error });
    };

    const handlePagination = (value) => {
        const offset = (value - 1) * tab.limit;
        setTab({ ...tab, page: value, offset: offset })
    }

    const handleChangeLimit = (value) => {
        setTab({ ...tab, limit: value, page: 1 })
    }

    const isFormValid = () => {
        let isValid = true;
        let error = declineFormData.error;
        if (declineFormData?.remarkForDecline?.length === 0) {
            isValid = false;
            error.remarkForDecline = t("Please Enter Remarks");
        } else {
            error.remarkForDecline = "";
        }
        setDeclineFormData({ ...declineFormData, error });
        return isValid;
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0) {
            setIsDialogActionButton(true);
            setTab({ ...tab, page: 1, offset: 0 })
            setTabTitle("Waiting For Approval")
        }
        if (newValue === 1) {
            setIsDialogActionButton(false);
            setTab({ ...tab, page: 1, offset: 0 })
            setTabTitle("Approved")
        }
        if (newValue === 2) {
            setIsDialogActionButton(false);
            setTab({ ...tab, page: 1, offset: 0 })
            setTabTitle("Declined")
        }
        if (newValue === 3) {
            setIsDialogActionButton(false);
            setTab({ ...tab, page: 1, offset: 0 })
            setTabTitle("Entries")
        }
    };
    const [filterDrawer, setFilterDrawer] = React.useState(false)
    const [filterData, setFilterData] = React.useState({}) //{ status: [true] }
    const clientId = localStorage.getItem(LocalStorageKeys.clinetID)

    const getWaitingForApprovalApprovedDeclinedList = (filter_data = filterData) => {
        setInitialLoad(true)
        const payload = {
            tenantId: `${config.tenantId}`,
            status: [tabTitle ?
                (tabTitle === "Waiting For Approval" ?
                    "Pending" :
                    tabTitle === "Approved" ?
                        "Approved" :
                        tabTitle === "Declined" ?
                            "Rejected" :
                            tabTitle === "Entries" ?
                                "Approved" : "Pending") :
                "Pending"],
            offset: tab.offset,
            limit: tab.limit,
            property_id: selectedProperty?.label === "All Properties" ? selectedProperty?.value : [selectedProperty?.value],
            query: searchText.length > 0 ? searchText.toUpperCase() : undefined,
            created_start_date: filter_data?.createdDate?.startDate ? lightFormat(filter_data?.createdDate?.startDate, 'yyyy-MM-dd') : undefined,
            created_end_date: filter_data?.createdDate?.endDate ? lightFormat(filter_data?.createdDate?.endDate, 'yyyy-MM-dd') : undefined,
            request_from_start_date: filter_data?.requestedFromDate?.startDate ? lightFormat(filter_data?.requestedFromDate?.startDate, "yyyy-MM-dd") : undefined,
            request_from_end_date: filter_data?.requestedFromDate?.endDate ? lightFormat(filter_data?.requestedFromDate?.endDate, "yyyy-MM-dd") : undefined,
            request_to_start_date: filter_data?.requestedToDate?.startDate ? lightFormat(filter_data?.requestedToDate?.startDate, "yyyy-MM-dd") : undefined,
            request_to_end_date: filter_data?.requestedToDate?.endDate ? lightFormat(filter_data?.requestedToDate?.endDate, "yyyy-MM-dd") : undefined,
        };
        NetworkCall(
            `${config.api_url}/services/domestic/list`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((response) => {
                if (!tabTitle || tabTitle === "Waiting For Approval") {
                    let tempPending = response?.data?.data?.response?.map((item, index) => {
                        let _item = {
                            id: item?.id ? item?.id : "",
                            helper_id: item?.helper_reference ? item?.helper_reference : "-",
                            Profession: item?.profession ? item?.profession : "-",
                            helper_name: item?.name ? item?.name : "-",
                            category: item?.profession ? item?.profession : "-",
                            reference_id: item?.reference_id ? item?.reference_id : "-",
                            requested_by: item?.requested_by ? item?.requested_by : "-",
                            unit_no: (item?.unit_name ? (item?.unit_name + ", ") : "") + (item?.unit_no ? item?.unit_no : "-"),
                            domestic_help_number: ((item?.mobile_country_code ? (item?.mobile_country_code) : "") + " " + (item?.mobile_no ? item?.mobile_no : "-")),
                            raised_on: item?.raised_on ? convertTimeUtcToZoneCalander(item?.raised_on, 'yyyy-MM-DD hh:mm a') : "-",
                            status: "Waiting for Approval",
                            icon: "",
                            index: tab.offset + index + 1
                        };
                        return _item;
                    });
                    setWaitingForApprovalData({ ...waitingForApprovalData, row: tempPending, totalRowsCount: response?.data?.data?.totalRecords });
                }
                if (tabTitle && tabTitle === "Approved") {
                    let tempData = response?.data?.data?.response?.map((item, index) => {
                        let _item = {
                            id: item?.id ? item?.id : "",
                            helper_id: item?.helper_reference ? item?.helper_reference : "-",
                            helper_name: item?.name ? item?.name : "-",
                            Profession: item?.profession ? item?.profession : "-",
                            category: item?.profession ? item?.profession : "-",
                            reference_id: item?.reference_id ? item?.reference_id : "-",
                            requested_by: item?.requested_by ? item?.requested_by : "-",
                            unit_no: (item?.unit_name ? (item?.unit_name + ", ") : "") + (item?.unit_no ? item?.unit_no : "-"),
                            domestic_help_number: ((item?.mobile_country_code ? (item?.mobile_country_code) : "") + " " + (item?.mobile_no ? item?.mobile_no : "-")),
                            raised_on: item?.raised_on ? convertTimeUtcToZoneCalander(item?.raised_on, 'yyyy-MMM-dd hh:mm a') : "-",
                            status: "Approved",
                            icon: "",
                            index: tab.offset + index + 1
                        };
                        return _item;
                    });
                    setApprovedData({ ...approvedData, row: tempData, totalRowsCount: response?.data?.data?.totalRecords });
                }
                if (tabTitle && tabTitle === "Declined") {
                    let tempRejected = response?.data?.data?.response?.map((item, index) => {
                        let _item = {
                            id: item?.id ? item?.id : "",
                            helper_id: item?.helper_reference ? item?.helper_reference : "-",
                            helper_name: item?.name ? item?.name : "-",
                            Profession: item?.profession ? item?.profession : "-",
                            category: item?.profession ? item?.profession : "-",
                            reference_id: item?.reference_id ? item?.reference_id : "-",
                            requested_by: item?.requested_by ? item?.requested_by : "-",
                            unit_no: (item?.unit_name ? (item?.unit_name + ", ") : "") + (item?.unit_no ? item?.unit_no : "-"),
                            domestic_help_number: ((item?.mobile_country_code ? (item?.mobile_country_code) : "") + " " + (item?.mobile_no ? item?.mobile_no : "-")),
                            raised_on: item?.raised_on ? convertTimeUtcToZoneCalander(item?.raised_on, 'yyyy-MMM-dd hh:mm a') : "-",
                            status: "Declined",
                            icon: "",
                            index: tab.offset + index + 1
                        };
                        return _item;
                    });
                    setDeclinedData({ ...declinedData, row: tempRejected, totalRowsCount: response?.data?.data?.totalRecords });
                }
                setInitialLoad(false)

            }).catch((error) => {

                setInitialLoad(false)

                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                })
            });
    };

    const getEntriesList = (filter_data = filterData) => {
        setInitialLoad(true)

        let payload = { search: searchText, offset: tab.offset, limit: tab.limit, property_id: [selectedProperty?.value] }

        NetworkCall(
            `${config.api_url}${BackendRoutes.domestic_helper_domestic_helper_entries}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((r) => {
                let tempEntries = r?.data?.data?.entries?.map((_, index) => {
                    let _item = {
                        id: _?.id ? _?.id : "",
                        helper_id: _?.domestic_helper_master?.reference_id ? _?.domestic_helper_master?.reference_id : "-",
                        helper_name: _?.domestic_helper_master?.name ? _?.domestic_helper_master?.name : "-",
                        Profession: _?.domestic_helper_master?.profession ? _?.domestic_helper_master?.profession : "-",
                        category: _?.domestic_helper_master?.profession?.name ? _?.domestic_helper_master?.profession?.name : "-",
                        reference_id: _?.reference_id ? _?.reference_id : "-",
                        requested_by: _?.created?.first_name ? _?.created?.first_name : "-",
                        unit_no: _?.domestic_helper_request?.length ? _?.domestic_helper_request?.length : 0,
                        unitList: _?.domestic_helper_request ? _?.domestic_helper_request?.map(i => i?.domestic_helper_units?.[0]?.unit) : [],
                        domestic_help_number: ((_?.domestic_helper_master?.mobile_country_code ? (_?.domestic_helper_master?.mobile_country_code) : "") + " " + (_?.domestic_helper_master?.mobile_no ? _?.domestic_helper_master?.mobile_no : "-")),
                        checked_in_gate: _?.access_gates_master?.name ?? '-',
                        checked_in_by: _?.checked_in_by?.first_name ?? '-',
                        checked_in: ((_?.check_in_time && _?.check_in_time !== null) ? timeZoneConverter(_?.check_in_time, "DD MMM YY , hh:mm") : "-"),
                        checked_out_gate: _?.access_gates_master?.name ?? '-',
                        checked_out_by: _?.checked_out_by?.first_name ?? "-",
                        checked_out: ((_?.check_out_time && _?.check_out_time !== null) ? timeZoneConverter(_?.check_out_time, "DD MMM YY , hh:mm") : "-"),
                        index: tab.offset + index + 1
                    };
                    return _item;
                });
                setEntriesData({ ...entriesData, row: tempEntries, totalRowsCount: r?.data?.data?.count });
                setInitialLoad(false)
            }).catch((e) => {
                alert.setSnack({
                    ...alert, open: true, msg: t("Some Thing Went Wrong"),
                    severity: AlertProps.severity.error,
                })
            })
    }

    React.useEffect(() => {
        if (propertyData !== null && propertyData?.length > 0 && selectedProperty?.value) {
            if (!tabTitle || tabTitle === "Waiting For Approval") {
                setValue(0)
                setIsDialogActionButton(true);
                getWaitingForApprovalApprovedDeclinedList();
            }
            if (tabTitle && tabTitle === "Approved") {
                setValue(1)
                setIsDialogActionButton(false);
                getWaitingForApprovalApprovedDeclinedList();
            }
            if (tabTitle && tabTitle === "Declined") {
                setValue(2)
                setIsDialogActionButton(false);
                getWaitingForApprovalApprovedDeclinedList();
            }
            if (tabTitle && tabTitle === "Entries") {
                setValue(3)
                setIsDialogActionButton(false);
                getEntriesList();
            }
        } else if (selectedCompany?.property?.length === 0) {
            if (!tabTitle || tabTitle === "Waiting For Approval") {
                setValue(0)
                setIsDialogActionButton(true);
                setWaitingForApprovalData({ ...waitingForApprovalData, row: [], totalRowsCount: 0 });
            }
            if (tabTitle && tabTitle === "Approved") {
                setValue(1)
                setIsDialogActionButton(false);
                setApprovedData({ ...approvedData, row: [], totalRowsCount: 0 });
            }
            if (tabTitle && tabTitle === "Declined") {
                setValue(2)
                setIsDialogActionButton(false);
                setDeclinedData({ ...declinedData, row: [], totalRowsCount: 0 });
            }
            if (tabTitle && tabTitle === "Entries") {
                setValue(3)
                setIsDialogActionButton(false);
                setEntriesData({ ...entriesData, row: [], totalRowsCount: 0 });
            }
        }
        // eslint-disable-next-line
    }, [searchText, tab, tabTitle, selectedCompany, selectedProperty]);

    const getDomesticHelperRequestDetails = (data, type) => {
        const payload={
            id: data?.id
        }
        NetworkCall(
            `${config.api_url}/queries/domestic_helper_request/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((r) => {
            const temp_data = r?.data?.data?.domestic_helper_request?.[0];
            setDomesticHelperRequestDetails(temp_data)
            switch (type) {
                case "waiting_for_approval":
                    if (temp_data?.domestic_helper_master?.is_active === false ||
                        temp_data?.domestic_helper_master?.is_delete === true) {
                        setDisable(true);
                    } else {
                        setDisable(false);
                    }
                    setIsDialogActionButton(true);
                    break;
                case "approved":
                    setIsDialogActionButton(true);
                    break;
                case "declined":
                    setIsDialogActionButton(false);
                    break;
                case "accept":
                    setIsDialogActionButton(false);
                    break;

                default:
                    break;
            }
            setIsDomesticHelperRequestDialogOpen(true);
        }).catch((e) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Internal error. Please try again later."),
            });
        })
    };

    const handleWaitingForApprovalTableRowView = (type, data) => {
        setDialogTitle(t("Request View"));
        getDomesticHelperRequestDetails(data, "waiting_for_approval");
    };

    const handleApprovedTableRowView = (type, data) => {
        setDialogTitle(t("Approved"));
        getDomesticHelperRequestDetails(data, "approved");
    };

    const handleDeclinedTableRowView = (type, data) => {
        setDialogTitle(t("Declined"));
        getDomesticHelperRequestDetails(data, "declined");
    };

    const handleEntriesTableRowView = (type, data) => {
        // setIsDialogActionButton(false);
        // setDialogTitle("View");
        // getDomesticHelperRequestDetails(data);
    };

    const closeDomesticHelperRequestDialog = () => {
        setIsDomesticHelperRequestDialogOpen(false);
        setIsAcceptActionButton(true);
        setDeclineFormData({ ...initialDeclineFormData });
    }

    const domesticHelperRequestView = () => {
        return (<Grid
            container
            className={classes.screenScrollEffect}
            style={{
                height: size?.height,
                overflowX: "hidden",
                overflowY: "scroll",
                scrollbarWidth: "none",
                "&::-webkit-scrollbar": {
                    display: "none",
                },
            }}
        >
            <Grid item xs={12} sx={{ padding: "12px" }} ref={exportRef} id="ticket">
                <div className={classes.rootCard} style={{ border: "1px solid #E4E8EE", boxShadow: "none" }}>
                    <Box display="flex" alignItems="center" p={3}>
                        <Box className={classes.img}>
                            <img
                                src="/images/domesticHelper.svg"
                                alt=""
                                style={{ objectFit: "contain", marginTop: "4px" }}
                            />
                        </Box>

                        <Box flexGrow={1} marginInlineStart="14px">
                            <Typography variant="subtitle2" className={classes.title}>
                                {domesticHelperRequestDetails?.domestic_helper_master?.name ? domesticHelperRequestDetails?.domestic_helper_master?.name : "-"}
                            </Typography>
                            {domesticHelperRequestDetails?.created_at &&
                                <Stack direction={"row"}>
                                    <Typography sx={{ marginInlineEnd: "4px" }} className={classes.sub}>{t("Requested on")}</Typography>
                                    <Typography className={classes.sub}>&#x202a;{convertTimeUtcToZoneCalander(domesticHelperRequestDetails?.created_at)}&#x202c;</Typography>
                                </Stack>
                            }
                            {domesticHelperRequestDetails?.approved_on &&
                                <Stack direction={"row"}>
                                    <Typography sx={{ marginInlineEnd: "4px" }} className={classes.sub}>{t("Approved on")}</Typography>
                                    <Typography className={classes.sub}>&#x202a;{convertTimeUtcToZoneCalander(domesticHelperRequestDetails?.created_at)}&#x202c;</Typography>
                                </Stack>
                            }
                            {/* <Typography variant="subtitle2" className={classes.sub}>
                                {domesticHelperRequestDetails?.created_at ? (t("Requested on ") + convertTimeUtcToZoneCalander(domesticHelperRequestDetails?.created_at) + ", ") : "-"}
                                {domesticHelperRequestDetails?.approved_on ? (t("Approved on ") + convertTimeUtcToZoneCalander(domesticHelperRequestDetails?.approved_on)) : "-"}
                            </Typography> */}
                            {domesticHelperRequestDetails?.request_status === "Pending" && (
                                <Typography
                                    variant="subtitle2"
                                    noWrap
                                    className={classes.progress}
                                >
                                    {t("In Progress")}
                                </Typography>
                            )}
                            {domesticHelperRequestDetails?.request_status === "Approved" && (
                                <Typography variant="subtitle2" className={classes.approved}>
                                    {t("Approved")}
                                </Typography>
                            )}
                            {domesticHelperRequestDetails?.request_status === "Rejected" && (
                                <Typography variant="subtitle2" className={classes.rejected}>
                                    {t("Rejected")}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    {
                        domesticHelperRequestDetails?.remarks && <div className={classes.reject_description_div}>
                            <Typography className={classes.reject_text_style}>
                                {domesticHelperRequestDetails?.remarks ?? ""}
                            </Typography>
                        </div>
                    }
                    <Box display="flex" alignItems="center" marginTop="-20px">
                        <Box>
                            <CircleIcon
                                className={classes.round}
                                style={{ marginLeft: "-20px", color: "white", borderRight: "2px solid #E4E8EE", borderRadius: "100%" }}
                            />
                        </Box>
                        <Box flexGrow={1}>
                            <Box className={classes.border} />
                        </Box>
                        <Box>
                            <CircleIcon
                                className={classes.round}
                                style={{ marginRight: "-20px", color: "white", borderLeft: "2px solid #E4E8EE", borderRadius: "100%" }}
                            />
                        </Box>
                    </Box>
                    <Box p={3} marginTop="-24px">
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2" className={classes.heading}>
                                    {t("UNIT")}
                                </Typography>
                                <Typography variant="subtitle2" className={classes.title}>
                                    {(domesticHelperRequestDetails?.unit?.name ? (domesticHelperRequestDetails?.unit?.name + (domesticHelperRequestDetails?.unit?.unit_no ? ", " : "")) : "") + (domesticHelperRequestDetails?.unit?.unit_no ? domesticHelperRequestDetails?.unit?.unit_no : "-")}
                                </Typography>
                                <Typography variant="subtitle2" className={classes.heading}>
                                    {t("CATEGORY")}
                                </Typography>
                                <Typography variant="subtitle2" className={classes.title}>
                                    {domesticHelperRequestDetails?.profession?.[0]?.name ? domesticHelperRequestDetails?.profession?.[0]?.name : "-"}
                                </Typography>
                                <Typography variant="subtitle2" className={classes.heading}>
                                    {t("DOMESTIC HELP NUMBER")}
                                </Typography>
                                <Typography variant="subtitle2" className={classes.title}>
                                    {((domesticHelperRequestDetails?.domestic_helper_master?.mobile_country_code ? (domesticHelperRequestDetails?.domestic_helper_master?.mobile_country_code + " ") : "") + (domesticHelperRequestDetails?.domestic_helper_master?.mobile_no ? domesticHelperRequestDetails?.domestic_helper_master?.mobile_no : "-"))}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2" className={classes.heading}>
                                    {t("REQUESTED BY")}
                                </Typography>
                                <Typography variant="subtitle2" className={classes.title}>
                                    {domesticHelperRequestDetails?.requested_by?.first_name ? domesticHelperRequestDetails?.requested_by?.first_name : "-"}
                                </Typography>
                                <Typography variant="subtitle2" className={classes.heading}>
                                    {t("REFERENCE ID")}
                                </Typography>
                                <Typography variant="subtitle2" className={classes.title}>
                                    {domesticHelperRequestDetails?.reference_id ? domesticHelperRequestDetails?.reference_id : "-"}
                                </Typography>
                                {domesticHelperRequestDetails?.request_status === "Rejected" && <><Typography variant="subtitle2" className={classes.heading}>
                                    {t("REMARKS")}
                                </Typography>
                                    <Typography variant="subtitle2" className={classes.title}>
                                        {domesticHelperRequestDetails?.remarks ? domesticHelperRequestDetails?.remarks : "-"}
                                    </Typography></>}
                            </Grid>
                        </Grid>
                        <br />
                    </Box>
                </div >
            </Grid >
        </Grid >);
    }

    const declineDomesticHelperRequestForm = () => {
        return (<Grid p={2}>
            <TextBox
                multiline
                label={t("Remark For Decline")}
                placeholder={t("Enter Remarks")}
                value={declineFormData?.remarkForDecline}
                onChange={(e) => {
                    updateDeclineFormData("remarkForDecline", e.target.value);
                }}
                isError={declineFormData?.error?.remarkForDecline?.length > 0}
                errorMessage={declineFormData?.error?.remarkForDecline} />
        </Grid>);
    }

    const handleAcceptDeclineDomesticHelperRequest = async (queryType) => {

        if (queryType === "accept") {
            await updateDomesticHelperRequest(queryType)
        }

        if (queryType === "decline") {
            if (isFormValid()) {
                await updateDomesticHelperRequest(queryType)
            } else {
                return false;
            }
        }
    }

    const updateDomesticHelperRequest = async (queryType) => {
        setDisable(true);

        const payload = {
            request_id: domesticHelperRequestDetails?.id,
            request_status: queryType === "accept" ? "Approved" : "Rejected",
            remarks: declineFormData?.remarkForDecline,
            approved_on: new Date().toISOString(),
            approved_by: localStorage.getItem(LocalStorageKeys.userProfileID),
            updated_at: new Date().toISOString(),
            updated_by: localStorage.getItem(LocalStorageKeys.userProfileID),
        }

        NetworkCall(
            `${config.api_url}${BackendRoutes?.domestic_helper_update_domestic_helper_status}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then(() => {
            switch (queryType) {
                case "accept":
                    getDomesticHelperRequestDetails(domesticHelperRequestDetails, "accept");
                    break;

                case "decline":
                    setIsDialogActionButton(false);
                    setIsAcceptActionButton(true);
                    setDeclineFormData({ ...initialDeclineFormData });
                    setIsDomesticHelperRequestDialogOpen(false);
                    break;

                default:
                    break;
            }
            getWaitingForApprovalApprovedDeclinedList();
            setDisable(false);
        }
        ).catch((error) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error,
            });
            setDisable(false);
        });
    }
    const onUnitClick = (data) => {

        // setUnitList(data?.unitList);
        // setIsUnitListDialogOpen(true);
    }

    const unitDetails = () => {
        return (<Grid container>
            {unitList?.map((item, index, array) => {
                return <>
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center" p={2}>
                            <Box>
                                <Avatar>
                                    <Typography style={{ fontSize: "1.25rem", fontFamily: FontFamilySwitch().extraBold, }}>
                                        {item?.unit?.name?.[0] ? item?.unit?.name?.[0] : ""}
                                    </Typography>
                                </Avatar>
                            </Box>
                            <Box marginLeft="10px">
                                <Typography style={{ fontSize: "1rem", fontFamily: FontFamilySwitch().semiBold, }}>
                                    {(item?.unit?.name ? (item?.unit?.name + (item?.unit?.unit_no ? ", " : "")) : "") + (item?.unit?.unit_no ? item?.unit?.unit_no : "-")}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </>
            })}
        </Grid>);
    }
    const closeUnitListDialog = () => {
        setIsUnitListDialogOpen(false);
    }

    // useEffect to get company list for company switcher when loading the screen
    React.useEffect(() => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
            getPropertyList(company?.selected)
        }
        // eslint-disable-next-line
    }, [auth])

    // Function to change the company
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        getPropertyList(value)
    }

    // Function to change property list
    const getPropertyList = (company) => {
        let property = constructPropertyList(company)
        setPropertyData(property?.list);
        setSelectedProperty(property?.list[0])
    }

    //handlePropertyChange
    const onPropertyChange = (val) => {
        setSelectedProperty(val)
    }

    // Filter Component Functions
    const apply = (values) => {
        setFilterData(values)
        let api = [0, 1, 2]
        if (api.includes(value)) {
            getWaitingForApprovalApprovedDeclinedList(values)
        }
        else {
            getEntriesList(values)
        }
    }

    return <div className={classes.root}>
        <Subheader
            hideBackButton={true}
            title={t("Domestic Help Request")}
            select options={companyList}
            value={selectedCompany}
            goBack={() => navigate(Routes.home)}
            onchange={(e) => handleCompanyChange(e)}
            onPropertyChange={(e) => {
                onPropertyChange(e)
            }}
            propertyValue={selectedProperty}
            selectProperty
            propertyOptions={propertyData}
        />
        <Grid direction={"column"} className={classes.innerWindow}>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} sx={{ padding: "0px 8px 0px 16px" }}>
                        <Tab label={t("Waiting for Approval")} />
                        <Tab label={t("Approved")} />
                        <Tab label={t("Declined")} />
                        <Tab label={t("Entries")} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    {waitingForApprovalData.heading.length > 0 && (
                        <Grid item xs={12} className={classes.table}>
                            <Grid container>
                                <Grid item xs={8}>
                                    <div className={classes.searchFilter}>
                                        <SearchFilter placeholder={t("Search By Helper Name")} value={searchText} handleChange={(value) => setSearchText(value)} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box display={"flex"} justifyContent={"end"} p={2}>
                                        {/* <IconButton onClick={() => setFilterDrawer(!filterDrawer)} className={classes.filterBox}>
                                            <Badge variant="dot" color="primary"
                                                invisible={!(Object.keys(filterData).length > 0)}>
                                                <FilterIMG color="#091b29" />
                                            </Badge>
                                        </IconButton> */}
                                    </Box>
                                </Grid>
                            </Grid>
                            {initialLoad ? <LoadingSection message={"feteching data..."} /> :

                                <TableWithPagination
                                    heading={waitingForApprovalData.heading}
                                    rows={waitingForApprovalData.row}
                                    path={waitingForApprovalData.path}
                                    showpagination={waitingForApprovalData.row.length > 0 ? true : false}
                                    onClick={() => console.log("")}
                                    onUnitClick={onUnitClick}
                                    handleIcon={handleWaitingForApprovalTableRowView}
                                    showpdfbtn={false}
                                    showexcelbtn={false}
                                    showSearch={false}
                                    dataType={waitingForApprovalData.type}
                                    handlePagination={handlePagination}
                                    handleChangeLimit={handleChangeLimit}
                                    totalRowsCount={waitingForApprovalData?.totalRowsCount}
                                    page={tab.page}
                                    limit={tab.limit}
                                    height={'calc(100vh - 355px)'}
                                    tableType="no-side"
                                    view={true}
                                    edit={true}
                                    delete={true} />
                            }
                        </Grid>
                    )}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {approvedData.heading.length > 0 && (
                        <Grid item xs={12} className={classes.table}>
                            <Grid container>
                                <Grid item xs={8}>
                                    <div className={classes.searchFilter}>
                                        <SearchFilter placeholder={t("Search By Helper Name")} value={searchText} handleChange={(value) => setSearchText(value)} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box display={"flex"} justifyContent={"end"} p={2}>
                                        {/* <IconButton onClick={() => setFilterDrawer(!filterDrawer)} className={classes.filterBox}>
                                            <Badge variant="dot" color="primary"
                                                invisible={!(Object.keys(filterData).length > 0)}>
                                                <FilterIMG color="#091b29" />
                                            </Badge>
                                        </IconButton> */}
                                    </Box>
                                </Grid>
                            </Grid>
                            {initialLoad ? <LoadingSection message={"feteching data..."} /> :

                                <TableWithPagination
                                    heading={approvedData.heading}
                                    rows={approvedData.row}
                                    path={approvedData.path}
                                    showpagination={approvedData.row.length > 0 ? true : false}
                                    onClick={() => false}
                                    handleIcon={handleApprovedTableRowView}
                                    showpdfbtn={false}
                                    showexcelbtn={false}
                                    showSearch={false}
                                    dataType={approvedData.type}
                                    handlePagination={handlePagination}
                                    handleChangeLimit={handleChangeLimit}
                                    totalRowsCount={approvedData?.totalRowsCount}
                                    page={tab.page}
                                    limit={tab.limit}
                                    height={'calc(100vh - 355px)'}
                                    tableType="no-side"
                                    view={true}
                                    edit={true}
                                    delete={true} />
                            }
                        </Grid>
                    )}
                </TabPanel>
                <TabPanel value={value} index={2}>
                    {declinedData.heading.length > 0 && (
                        <Grid item xs={12} className={classes.table}>
                            <Grid container>
                                <Grid item xs={8}>
                                    <div className={classes.searchFilter}>
                                        <SearchFilter placeholder={t("Search By Helper Name")} value={searchText} handleChange={(value) => setSearchText(value)} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box display={"flex"} justifyContent={"end"} p={2}>
                                        {/* <IconButton onClick={() => setFilterDrawer(!filterDrawer)} className={classes.filterBox}>
                                            <Badge variant="dot" color="primary"
                                                invisible={!(Object.keys(filterData).length > 0)}>
                                                <FilterIMG color="#091b29" />
                                            </Badge>
                                        </IconButton> */}
                                    </Box>
                                </Grid>
                            </Grid>
                            {initialLoad ? <LoadingSection message={"feteching data..."} /> :

                                <TableWithPagination
                                    heading={declinedData.heading}
                                    rows={declinedData.row}
                                    path={declinedData.path}
                                    showpagination={declinedData.row.length > 0 ? true : false}
                                    onClick={() => false}
                                    handleIcon={handleDeclinedTableRowView}
                                    showpdfbtn={false}
                                    showexcelbtn={false}
                                    showSearch={false}
                                    dataType={declinedData.type}
                                    handlePagination={handlePagination}
                                    handleChangeLimit={handleChangeLimit}
                                    totalRowsCount={declinedData?.totalRowsCount}
                                    page={tab.page}
                                    limit={tab.limit}
                                    height={'calc(100vh - 355px)'}
                                    tableType="no-side"
                                    view={true}
                                    edit={true}
                                    delete={true} />
                            }
                        </Grid>
                    )}
                </TabPanel>
                <TabPanel value={value} index={3}>
                    {entriesData.heading.length > 0 && (
                        <Grid item xs={12} className={classes.table}>
                            <Grid container>
                                <Grid item xs={8}>
                                    <div className={classes.searchFilter}>
                                        <SearchFilter placeholder={t("Search")} value={searchText} handleChange={(value) => setSearchText(value)} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box display={"flex"} justifyContent={"end"} p={2}>
                                        <IconButton onClick={() => setFilterDrawer(!filterDrawer)} className={classes.filterBox}>
                                            <Badge variant="dot" color="primary"
                                                invisible={!(Object.keys(filterData).length > 0)}>
                                                <FilterIMG color="#091b29" />
                                            </Badge>
                                        </IconButton>
                                    </Box>
                                </Grid>
                            </Grid>
                            {initialLoad ? <LoadingSection message={"feteching data..."} /> :

                                <TableWithPagination
                                    heading={entriesData.heading}
                                    rows={entriesData.row}
                                    path={entriesData.path}
                                    showpagination={entriesData.row.length > 0 ? true : false}
                                    onClick={() => false}
                                    handleIcon={handleEntriesTableRowView}
                                    onUnitClick={onUnitClick}
                                    showpdfbtn={false}
                                    showexcelbtn={false}
                                    showSearch={false}
                                    dataType={entriesData.type}
                                    handlePagination={handlePagination}
                                    handleChangeLimit={handleChangeLimit}
                                    totalRowsCount={entriesData?.totalRowsCount}
                                    page={tab.page}
                                    limit={tab.limit}
                                    height={'calc(100vh - 355px)'}
                                    tableType="no-side"
                                    view={true}
                                    edit={true}
                                    delete={true} />
                            }
                        </Grid>
                    )}
                </TabPanel>
            </Box>
        </Grid>
        <Hidden smUp>
            <Drawer
                className={classes.drawer}
                anchor="bottom"
                open={isDomesticHelperRequestDialogOpen}
                onClose={closeDomesticHelperRequestDialog}>
                <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}>
                    <Grid>
                        <Typography className={classes.drawerHeaderTextStyle}>
                            {dialogTitle}
                        </Typography>
                    </Grid>
                    <Grid>
                        <IconButton
                            style={{
                                padding: "0px",
                            }}
                            onClick={closeDomesticHelperRequestDialog}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider />
                {isAcceptActionButton ? domesticHelperRequestView() : declineDomesticHelperRequestForm()}
                {isDialogActionButton && (<><Divider />
                    {isAcceptActionButton ? (<Grid className={classes.drawerFooterStyle}>
                        <Box height={"6px"} />
                        <Grid container direction={"row"} >
                            <Grid container xs={domesticHelperRequestDetails?.request_status === "Pending" ? 6 : 12}>
                                <Button
                                    fullWidth
                                    className={classes.drawerButtonStyle}
                                    style={{ marginRight: "8px", backgroundColor: "#FF4B4B" }}
                                    variant="contained"
                                    disabled={disable}
                                    onClick={() => setIsAcceptActionButton(false)}>
                                    {t("Decline")}
                                </Button>
                            </Grid>
                            {domesticHelperRequestDetails?.request_status === "Pending" && <Grid container xs={6}>
                                <Button
                                    fullWidth
                                    className={classes.drawerButtonStyle}
                                    style={{ marginLeft: "8px", backgroundColor: "#5AC782" }}
                                    variant="contained"
                                    disabled={disable}
                                    onClick={() => { handleAcceptDeclineDomesticHelperRequest("accept") }}>
                                    {t("Accept")}
                                </Button>
                            </Grid>}
                        </Grid>
                    </Grid>) : (<Grid className={classes.drawerFooterStyle}>
                        <Box height={"6px"} />
                        <Grid container direction={"row"} >
                            <Grid container xs={6}>
                                <Button
                                    fullWidth
                                    className={classes.drawerButtonStyle}
                                    style={{ marginRight: "8px", backgroundColor: "#FFFFFF", color: "#091B29" }}
                                    variant="contained"
                                    disabled={disable}
                                    onClick={closeDomesticHelperRequestDialog}>
                                    {t("Back")}
                                </Button>
                            </Grid>
                            <Grid container xs={6}>
                                <Button
                                    fullWidth
                                    className={classes.drawerButtonStyle}
                                    style={{ marginLeft: "8px", backgroundColor: "#FF4B4B" }}
                                    variant="contained"
                                    disabled={disable}
                                    onClick={() => { handleAcceptDeclineDomesticHelperRequest("decline") }}>
                                    {t("Decline")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>)}</>)}
            </Drawer>
        </Hidden>
        <Hidden smDown>
            <Dialog
                className={classes.dialog}
                fullWidth
                open={isDomesticHelperRequestDialogOpen}
                onClose={closeDomesticHelperRequestDialog}>
                <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}>
                    <Grid>
                        <Typography className={classes.drawerHeaderTextStyle}>
                            {dialogTitle}
                        </Typography>
                    </Grid>
                    <Grid>
                        <IconButton
                            style={{
                                padding: "0px",
                            }}
                            onClick={closeDomesticHelperRequestDialog}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider />
                {isAcceptActionButton ? domesticHelperRequestView() : declineDomesticHelperRequestForm()}
                {isDialogActionButton && (<><Divider />
                    {isAcceptActionButton ? (<Grid className={classes.drawerFooterStyle}>
                        <Box height={"6px"} />
                        <Grid container direction={"row"} >
                            <Grid container xs={domesticHelperRequestDetails?.request_status === "Pending" ? 6 : 12}>
                                <Button
                                    fullWidth
                                    className={classes.drawerButtonStyle}
                                    style={{ marginRight: "8px", backgroundColor: "#FF4B4B" }}
                                    variant="contained"
                                    disabled={disable}
                                    onClick={() => setIsAcceptActionButton(false)}>
                                    {t("Decline")}
                                </Button>
                            </Grid>
                            {domesticHelperRequestDetails?.request_status === "Pending" && <Grid container xs={6}>
                                <Button
                                    fullWidth
                                    className={classes.drawerButtonStyle}
                                    style={{ marginLeft: "8px", backgroundColor: "#5AC782" }}
                                    variant="contained"
                                    disabled={disable}
                                    onClick={() => { handleAcceptDeclineDomesticHelperRequest("accept") }}>
                                    {t("Accept")}
                                </Button>
                            </Grid>}
                        </Grid>
                    </Grid>) : (<Grid className={classes.drawerFooterStyle}>
                        <Box height={"6px"} />
                        <Grid container direction={"row"} >
                            <Grid container xs={6}>
                                <Button
                                    fullWidth
                                    className={classes.drawerButtonStyle}
                                    style={{ marginRight: "8px", backgroundColor: "#FFFFFF", color: "#091B29" }}
                                    variant="contained"
                                    disabled={disable}
                                    onClick={closeDomesticHelperRequestDialog}>
                                    {t("Back")}
                                </Button>
                            </Grid>
                            <Grid container xs={6}>
                                <Button
                                    fullWidth
                                    className={classes.drawerButtonStyle}
                                    style={{ marginLeft: "8px", backgroundColor: "#FF4B4B" }}
                                    variant="contained"
                                    disabled={disable}
                                    onClick={() => { handleAcceptDeclineDomesticHelperRequest("decline") }}>
                                    {t("Decline")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>)}</>)}
            </Dialog>
        </Hidden>
        <Hidden smUp>
            <Drawer
                className={classes.drawer}
                anchor="bottom"
                open={isUnitListDialogOpen}
                onClose={closeUnitListDialog}>
                <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}>
                    <Grid>
                        <Typography className={classes.drawerHeaderTextStyle}>
                            {t("Unit Details")}
                        </Typography>
                    </Grid>
                    <Grid>
                        <IconButton
                            style={{
                                padding: "0px",
                            }}
                            onClick={closeUnitListDialog}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider />
                {unitDetails()}
            </Drawer>
        </Hidden>
        <Hidden smDown>
            <Dialog
                className={classes.dialog}
                fullWidth
                open={isUnitListDialogOpen}
                onClose={closeUnitListDialog}>
                <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}>
                    <Grid>
                        <Typography className={classes.drawerHeaderTextStyle}>
                            {t("Unit Details")}
                        </Typography>
                    </Grid>
                    <Grid>
                        <IconButton
                            style={{
                                padding: "0px",
                            }}
                            onClick={closeUnitListDialog}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider />
                {unitDetails()}
            </Dialog>
        </Hidden>
        <VisitorSecurityFilter
            open={filterDrawer}
            close={() => setFilterDrawer(!filterDrawer)}
            apply={apply}
            filterData={filterData}
            filterKeys={value === 3 ? filterKeys2 : filterKeys1}
            clientId={clientId}
            property={selectedProperty}
        />
    </div>
}
export default withTranslation("domesticHelper")(DomesticHelpRequest)
