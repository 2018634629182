import { Badge, Box, Grid, IconButton, Stack } from "@mui/material";
import moment from "moment";
import React from "react";
import { withTranslation } from "react-i18next";
import { FilterGenerator, LoadingSection, SearchFilter, Subheader, TableWithPagination, UseDebounce } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { enum_types, enumSelect, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods, TimeOffResourceBookingDataType, TimeOffResourceBookingHeading, TimeOffResourceBookingPath, wrapLabelsInT } from "../../utils";
import { useStyles } from "./style";

const TimeOffResources = ({ t }) => {
    const [tableData, setTableData] = React.useState([{
        inspection_id: "IN342",
        unit_id: "UNT-123",
        assigned_date: "05-12-2022",
        hours: 4,
        actual_hours: 0,
        assigned_to: "sonu",
        status: "Assigned"
    }])
    const auth = React.useContext(AuthContext)
    const [searchText, setSearchText] = React.useState("")
    const [permission, setPermission] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [loading, setLoading] = React.useState(true)
    const backdrop = React.useContext(BackdropContext)
    const [drawer, setDrawer] = React.useState(false)
    const [selectedCompany, setSelectedCompany] = React.useState("")
    const alert = React.useContext(AlertContext)
    const [companyList, setCompanyList] = React.useState([])
    const [leaveCategories, setLeaveCategories] = React.useState([])
    const [leaveType, setLeaveType] = React.useState([])
    const [filterData, setFilterData] = React.useState({
        leave_category: [],
        leave_type: []
    })
    const classes = useStyles()
    const debounce = UseDebounce()

    const getTimeOffResourcesList = (companyId, offset = 0, limit = 10, searchKey = "", filterData = {}) => {
        const requestPayload = {
            start: offset ?? 0,
            length: limit ?? 10,
            company_id: companyId,
            search: searchKey?.length > 0 ? searchKey : undefined,
            "contact_ids": [],
            "leave_category": filterData?.leave_category?.length > 0 ? filterData?.leave_category?.map((x) => x?.value) : undefined, //leave master use graphql
            "leave_type": filterData?.leave_type?.length > 0 ? filterData?.leave_type?.map((x) => x?.value) : undefined, //enum resource_leave_type
        };
        NetworkCall(
            `${config.api_url}/leave_request`,
            NetWorkCallMethods.post,
            requestPayload,
            null,
            true,
            false
        )
            .then((response) => {
                setLoading(false)
                const list = response?.data?.data?.data?.map((val, index) => {
                    let dt1 = new Date(val?.start_date_time);
                    let dt2 = new Date(val?.end_date_time);
                    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
                    diff /= (60 * 60);
                    const NoOfDays = Math.abs(Math.round(diff))
                    return {
                        ...val,
                        duration_type: val?.type === "full_day" ? "Full Day" : val?.type === "half_day" ? `Half Day (${val?.half_day_type==="first_half"?"First Half":"Second Half"})` : val?.type === "hourly" ? "Hourly" : "-",
                        start_date_time: `${moment(val?.start_date_time).format("DD-MM-YYYY")}`,
                        end_date_time:`${moment(val?.start_date_time).format("DD-MM-YYYY")}`,
                        no_of_days:val?.type === "half_day"?"0.5 Day" :val?.type === "hourly" ? `${NoOfDays} hours` : val?.no_of_days > 1 ? `${val?.no_of_days} Days` : `${val?.no_of_days} Day`
                    }
                })
                setTableData({ list: list, count: response?.data?.data?.count })
            }).catch((err) => {

                setLoading(false)

            })
    }
    const getAllLeaveList = (search="") => {
        const payload={
            offset:0,
            limit:null,
            search:search
        }
        NetworkCall(
            `${config.api_url}/queries/get_leave_masters`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setLeaveCategories(res.data?.data?.leave_master)
        }).catch((err) => {
            console.log(err)
        })
    }
    // enum get
    const getEnum = async () => {
        const result = await enumSelect([enum_types.resource_leave_type]);
        setLeaveType(result?.resource_leave_type)
    };

    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        let company = getCompanyOption(backdrop, auth, alert)
        if (perm) {
            setPermission(perm)
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
            getTimeOffResourcesList(company?.selected?.value, 0, 10, "")
            getEnum()
            getAllLeaveList()
        }
        //eslint-disable-next-line
    }, [auth])
    //handle company change
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        getTimeOffResourcesList(value?.value, 0, limit, "")
        setFilterData({ job: [], type: [] })
    }
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getTimeOffResourcesList(selectedCompany?.value, offset, limit, "")
    }

    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getTimeOffResourcesList(selectedCompany?.value, 0, value, "")

    }
    const handleSearch = (value) => {
        setSearchText(value)
        debounce(() => getTimeOffResourcesList(selectedCompany?.value, 0, 10, value), 800)        
    }
    const openDrawer = () => {
        setDrawer(!drawer)
    }
    const onApplyFilter = (value) => {
        getTimeOffResourcesList(selectedCompany?.value, 0, 10, "", value)
        setFilterData(value)
    }
    return (

        <Box>
            <Subheader title={t("timeOff_request")} hideBackButton={true} select
                options={companyList}
                value={selectedCompany}
                onchange={(e) => {
                    handleCompanyChange(e)
                }} />
            {loading ?
                <LoadingSection top="20vh" message={t("Fetching Details")} />
                :
                <Box className={classes.root}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={4}>
                            <Box>
                                {/*search */}
                                <SearchFilter label={false} placeholder={t("Search By Resource Name")} handleChange={(value) => handleSearch(value)} value={searchText} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" }}}/>
                            </Box>
                        </Grid>

                        <Grid item xs={8} display={"flex"} justifyContent={"end"}>
                            <Box display={"flex"} alignItems="center" sx={{ float: "right" }}>
                                <Stack
                                    direction="row"
                                    spacing={0.6}
                                    display={"flex"}
                                    alignItems="center"
                                >
                                    <Box>
                                        <IconButton
                                            size="small"
                                            className={classes.img}
                                            onClick={openDrawer}
                                        >
                                            <Badge variant="dot" color="primary"
                                                invisible={!Boolean(filterData.leave_category?.length > 0) && !Boolean(filterData.leave_type?.length > 0)}>
                                                <img src="/images/filter.svg" alt="filter" />
                                            </Badge>
                                        </IconButton>
                                    </Box>
                                </Stack>
                                <Box width={"15px"} />
                            </Box>
                        </Grid>
                    </Grid>

                    <TableWithPagination
                        heading={TimeOffResourceBookingHeading(t)}
                        rows={tableData?.list}
                        dataType={TimeOffResourceBookingDataType}
                        path={TimeOffResourceBookingPath}
                        showpagination
                        tableType="no-side"
                        // handleIcon={handleIcon}
                        handlePagination={handlePagination}
                        handleChangeLimit={handleChangeLimit}
                        totalRowsCount={tableData?.count}
                        page={page}
                        limit={limit}
                        view={permission?.read && true}
                        height={`calc(100vh - 300px)`}
                        edit={true}
                        delete={true} />
                </Box>
            }

            {/* filter */}
            {drawer && (
                <FilterGenerator
                    open={drawer}
                    onClose={() => setDrawer(false)}
                    components={[
                        {
                            component: "select",
                            value: filterData?.leave_category,
                            isMulti: true,
                            label: t("Leave Type"),
                            placeholder:t("Leave Type"),
                            state_name: "leave_category",
                            options: leaveCategories,
                        },
                        {
                            component: "select",
                            value: filterData?.leave_type,
                            isMulti: true,
                            label: t("Time Off Type"),
                            placeholder:t("Time Off Type"),
                            state_name: "leave_type",
                            options: wrapLabelsInT(leaveType, t),
                        },
                    ]}
                    onApply={(value) => onApplyFilter(value)}
                />
            )}
        </Box>
    )
}
export default withTranslation("inspection")(TimeOffResources)