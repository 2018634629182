import { Badge, IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import styled from "@mui/material/styles/styled";
import React from "react";
import { withTranslation } from "react-i18next";
import FilterIMG from "../../assets/filter";
import {
  AlertDialog,
  DetailsCardCoponent,
  Editor,
  FilterGenerator,
  LoadingSection,
  SearchFilter,
  SelectBox,
  Subheader,
  TableWithPagination,
  TextBox,
  ToggleButtonComponent,
  UploadComponent,
  UseDebounce,
} from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import {
  accessCheckRender,
  AlertProps,
  AmenitiesMasterHeading,
  AmenitiesMasterPath,
  enum_types,
  enumSelect,
  getCompanyOption,
  getRoutePermissionNew,
  NetWorkCallMethods,
  FontFamilySwitch
} from "../../utils";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { AssetView } from "../resourceMaster/components/assetView";
import { AmenitiesMasterStyles } from "./style";
import { useNavigate } from "react-router-dom";
const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.palette.borderRadius,
  height: "40px",
}));

const CustomPaper = styled("div")(({ theme }) => ({
  boxShadow: "0px 0px 16px #00000014",
  borderRadius: theme.palette.borderRadius,
  backgroundColor: "#ffffff",
  padding: theme.spacing(2),
  margin: theme.spacing(3),
}));

const initialState = {
  id: null,
  amenityName: "",
  amenityCategory: "",
  amenityType: "",
  booking: true,
  status: true,
  description: "",
  edit: false,
  view: false,
  itemMaster: "",
  itemType: "",
  itemCategory: "",
  error: {
    amenityCategory: "",
    amenityType: "",
    amenityName: "",
    booking: "",
    status: "",
    itemMaster: "",
  },
};

const STATUS_OPTIONS = (t = () => false) => {
  return [
    { label: t("Active"), value: true },
    { label: t("In Active"), value: false },
  ];
};

const BOOKING_TYPE = (t = () => false) => {
  return [
    { label: t("Yes"), value: true },
    { label: t("No"), value: false },
  ];
};

const AmenitiesMaster = ({ t = () => false }) => {
  const classes = AmenitiesMasterStyles();
  const [openDialog, setOpenDialog] = React.useState(false);
  // const size = useWindowDimensions();
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const auth = React.useContext(AuthContext);
  const [amenitiesMasterData, setAmenitiesMasterData] = React.useState({
    ...initialState,
  });
  const [assets, setAssets] = React.useState([]);
  const [companyList, setCompanyList] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState({});
  const [amenitiesMasterList, setAmenitiesMasterList] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  // const userProfileId = localStorage.getItem(LocalStorageKeys.userProfileID)
  const [drawer, setDrawer] = React.useState(null);
  const [filterData, setFilterData] = React.useState({
    is_active: [],
    amenities_type: [],
    is_booking: [],
    amenity_category: [],
  });
  const [loading, setLoading] = React.useState(true);
  // const clientId = localStorage.getItem(LocalStorageKeys.clinetID)
  const [enumValue, setEnumValue] = React.useState({
    aminityType: [],
    amenityCategory: [],
  });
  const navigate = useNavigate();
  const [permission, setPermission] = React.useState({});
  const [viewModal, setViewModal] = React.useState(false);
  const [viewAmenityDetails, setViewAmenityDetails] = React.useState([]);
  const [isDisableBtn, setIsDisableBtn] = React.useState(false);

  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");
  const limitParam = searchParams.get("limit");

  // Default values if not set in URL
  const pageFromParams = pageParam ? parseInt(pageParam, 10) : 1;
  const limitFromParams = limitParam ? parseInt(limitParam, 10) : 10;

  const [page, setPage] = React.useState(pageFromParams);
  const [limit, setLimit] = React.useState(limitFromParams);

  const updatePage = (newPage, newLimit = limit) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", newPage.toString());
    searchParams.set("limit", newLimit.toString());

    // Update URL without reloading
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
    // Update state
    setPage(newPage);
    setLimit(newLimit);
  };
  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        getEnum();
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          setCompanyList(company?.list);
          setSelectedCompany(company?.selected);
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  const getEnum = async () => {
    const result = await enumSelect([
      enum_types.amenities_type,
      enum_types.inspection_item_type,
    ]);
    setEnumValue({
      aminityType: result?.amenities_type,
      amenityCategory: result?.amenity_category,
      inspection_item_type: result?.inspection_item_type?.filter(
        (e) => e?.value !== "Product" && e?.value !== "Inspection"
      ),
    });
  };

  const draweropen = () => {
    setDrawer(true);
  };
  const drawerclose = () => {
    setDrawer(false);
  };
  const debounce = UseDebounce();

  //handle pagination
  const handlePagination = (newPage) => {
    updatePage(newPage, limit);
  };

  const handleChangeLimit = (newLimit) => {
    updatePage(1, newLimit); // Reset page to 1 when limit changes
  };

  const handleSearch = (e) => {
    setSearchText(e);
    debounce(() => searchTableFunction(e), 800);
  };
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    AmenitiesMasterTableData(selectedCompany?.value, 0, limit, e, filterData);
  };

  //update state
  const updateState = (key, value) => {
    let error = amenitiesMasterData?.error;
    error[key] = "";
    setAmenitiesMasterData({ ...amenitiesMasterData, [key]: value });
  };
  //validation
  const validate = () => {
    let isValid = true;
    let error = amenitiesMasterData.error;
    if (amenitiesMasterData?.amenityName?.length === 0) {
      isValid = false;
      error.amenityName = t("Amenity Name  is Required");
    }

    if (amenitiesMasterData?.amenityCategory?.length === 0) {
      isValid = false;
      error.amenityCategory = t("AmenityCategory is Required");
    }
    if (amenitiesMasterData?.booking?.length === 0) {
      isValid = false;
      error.booking = t("Booking is Required");
    }
    if (amenitiesMasterData?.status === null) {
      isValid = false;
      error.status = t("Status is Required");
    }
    if (amenitiesMasterData?.booking === null) {
      isValid = false;
      error.booking = t("Booking Required is Required");
    }
    if (amenitiesMasterData?.itemMaster === "") {
      isValid = false;
      error.itemMaster = t("Item Master is Required");
    }

    if (
      amenitiesMasterData?.amenityName?.length === 0 ||
      amenitiesMasterData?.amenityCategory?.length === 0 ||
      amenitiesMasterData?.booking?.length === 0
    ) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Please fill all mandatory field"),
      });
    }
    setAmenitiesMasterData({ ...amenitiesMasterData, error });

    return isValid;
  };
  //table listing function
  const AmenitiesMasterTableData = async (
    company_id,
    offset = 0,
    limit = 10,
    search = "",
    filterData
  ) => {
    const payload = {
      company_id: company_id,
      search: search,
      offset: offset,
      limit: limit,
      active: filterData.is_active,
      amenities_type: filterData.amenities_type.map((x) => x.value),
      amenity_category: filterData.amenity_category.map((x) => x.value),
      is_booking: filterData.is_booking,
    };

    NetworkCall(
      `${config.api_url}/amenities/get_all`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setAmenitiesMasterList({
          data: response?.data?.list,
          totalRowsCount: response?.data?.count,
        });
        setLoading(false);
        backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
      })
      .catch((error) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });

        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
        setLoading(false);
      });
  };

  //Upsert API FUNCTION
  const UpsertAmenitiesMaster = () => {
    if (validate()) {
      setIsDisableBtn(true);
      const constructedAssets = assets?.map((e) => {
        return {
          id: e?.id,
          file_meta: e?.file_meta,
          asset_type: e?.asset_type,
          is_active: e?.is_active,
          url: e?.url,
        };
      });
      let payload = {
        id: amenitiesMasterData?.id ?? undefined,
        amenities_name: amenitiesMasterData?.amenityName,
        amenities_type: amenitiesMasterData?.amenityType?.value,
        amenity_category: amenitiesMasterData?.amenityCategory?.value,
        company_id: selectedCompany?.value,
        description: amenitiesMasterData?.description,
        assets: constructedAssets,
        is_booking: amenitiesMasterData?.booking,
        is_active: amenitiesMasterData?.status,
        inspection_item: amenitiesMasterData?.itemMaster?.value,
      };
      NetworkCall(
        `${config.api_url}/amenities/upsert`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: amenitiesMasterData?.id
              ? t("Amenities Updated successfully")
              : t("Amenities Added successfully"),
          });
          setAmenitiesMasterData({ ...initialState });
          setOpenDialog(false);
          AmenitiesMasterTableData(
            selectedCompany?.value,
            currentOffset,
            limitFromParams,
            "",
            filterData
          );
          setAssets([]);
          setIsDisableBtn(false);
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Internal error. Please try again later."),
          });
          setIsDisableBtn(false);
        });
    }
  };
  //view Data
  const ViewAmenityData = (id) => {
    const payload = {
      id: id,
    };
    NetworkCall(
      `${config.api_url}/amenities/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let data = response?.data?.data;
        setViewAmenityDetails(response?.data?.data);
        if (amenitiesMasterData.edit) {
          setAssets(data?.assets);
          setAmenitiesMasterData({
            ...amenitiesMasterData,
            amenityName: data?.amenities_name,
            amenityCategory: {
              label: data?.category?.name,
              value: data?.category?.id,
            },
            amenityType: {
              label: data?.amenities_type,
              value: data?.amenities_type,
            },
            is_active: data?.is_active,
            status: data?.is_active,
            booking: data?.is_booking,
            description: data?.description,
            edit: true,
            view: false,
            id: data?.id,
            is_booking: data?.is_booking,
            itemType: {
              label: data?.item_master?.category?.item_type,
              value: data?.item_master?.category?.item_type,
            },
            itemCategory: data?.item_master?.category,
            itemMaster: {
              label: data?.item_master?.label,
              value: data?.item_master?.value,
            },
          });
        }

        setLoading(false);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
        setLoading(false);
      });
  };
  // is active changing function
  const changeactive = async (data, value) => {
    const payload = {
      params: [
        {
          id: data?.id,
          is_active: !data?.is_active,
        },
      ],
    };

    NetworkCall(
      `${config.api_url}/queries/amenity_master/upsert`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Status Updated"),
        });
        AmenitiesMasterTableData(
          selectedCompany?.value,
          currentOffset,
          limitFromParams,
          "",
          filterData
        );
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
      });
  };
  //delete function
  const deleteTableData = async (data) => {
    const payload = {
      id: data,
      update: {
        is_delete: true,
      },
    };
    NetworkCall(
      `${config.api_url}/queries/amenities_type_master/update`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((rs) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Deleted"),
        });
        AmenitiesMasterTableData(
          selectedCompany?.value,
          0,
          limit,
          "",
          filterData
        );
      })
      .catch((er) => {
        console.log(er);
      });
  };
  //table row constructions
  const AmenitiesMasterRow = amenitiesMasterList?.data?.map((val, index) => {
    let _d;
    try {
      _d = {
        index: (page - 1) * limit + index + 1,
        amenityName: val?.amenities_name ?? " - ",
        amenityType: val?.amenities_type ?? " - ",
        amenityCategory: val?.amenity_category ?? " - ",
        is_active: val?.is_active,
        booking: val?.is_booking ? "Yes" : "No",
        images: val.images,
        IsDelete: val?.is_delete,
        icon: "more",
        id: val?.id,
      };
    } catch (err) {}
    return _d;
  });
  const currentOffset = (page - 1) * limit;
  //initial load
  React.useEffect(() => {
    if (selectedCompany?.value) {
      AmenitiesMasterTableData(
        selectedCompany?.value,
        currentOffset,
        limitFromParams,
        "",
        filterData
      );
    }
    //eslint-disable-next-line
  }, [selectedCompany, filterData]);
  //handle Icon
  const handleIcon = (type, data, status) => {
    if (type === "view") {
      ViewAmenityData(data.id);
      setAmenitiesMasterData({ ...amenitiesMasterData, view: true });
      setViewModal(true);
    } else if (type === "edit") {
      setOpenDialog(true);
      amenitiesMasterData.edit = true;
      setAmenitiesMasterData(amenitiesMasterData);
      ViewAmenityData(data.id);
    } else if (type === "active") {
      changeactive(data, status);
    } else if (type === "delete") {
      deleteTableData(data.id, data.IsDelete);
    }
  };

  //apply filter
  const onApplyFilter = (value) => {
    setFilterData(value);
    AmenitiesMasterTableData(selectedCompany?.value, 0, limit, "", value);
  };

  const itemMasterManualResponse = (array) => {
    const details = array?.map((i) => {
      return {
        label: i?.name,
        value: i?.id,
        ...i,
      };
    });
    return details;
  };

  // add and edit modal
  const addAndEdit = () => {
    return (
      <>
        <Grid
          container
          spacing={2}
          className={classes.amenityModal}
          width={"100%"}
        >
          <Grid item xs={6} className={classes.amenityImageblock}>
            <Box className={classes.amenityImageSection}>
              <Box mb={2}>
                <Typography className={classes.amenitiesheading}>
                  {t("Upload Amenity Images")}
                </Typography>
              </Box>
              <Box>
                <UploadComponent
                  assets={assets}
                  setAssets={setAssets}
                  xs={assets.length > 0 ? 4 : 12}
                  amenities={true}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.amenitiesDetails}>
              <Box mb={2}>
                <Typography className={classes.amenitiesheading}>
                  {t("Amenities Details")}
                </Typography>
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SelectBox
                    isRequired
                    value={amenitiesMasterData?.amenityType}
                    label={t("Amenity Type")}
                    placeholder={t("Select Amenity Type")}
                    options={enumValue?.aminityType}
                    onChange={(value) => {
                      updateState("amenityType", value);
                    }}
                    isError={
                      amenitiesMasterData?.error?.amenityType?.length > 0
                    }
                    errorMessage={amenitiesMasterData?.error?.amenityType}
                    isReadOnly={amenitiesMasterData?.view}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectBox
                    isRequired
                    value={amenitiesMasterData?.amenityCategory}
                    label={t("Amenity Category")}
                    placeholder={t("Select Amenity Category")}
                    onChange={(value) => {
                      updateState("amenityCategory", value);
                    }}
                    loadOptions={(search, array, handleLoading) =>
                      loadOptionsApis(
                        "queries/amenity_category",
                        {},
                        search,
                        array,
                        handleLoading,
                        "data",
                        {},
                        manualCategoryResponse
                      )
                    }
                    debounceTimeout={800}
                    isPaginate={true}
                    isError={
                      amenitiesMasterData?.error?.amenityCategory?.length > 0
                    }
                    errorMessage={amenitiesMasterData?.error?.amenityCategory}
                    isReadOnly={amenitiesMasterData?.view}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextBox
                    value={amenitiesMasterData?.amenityName}
                    isrequired
                    label={t("Amenity Master Name")}
                    placeholder={t("Enter Amenity Master Name")}
                    onChange={(e) => {
                      updateState("amenityName", e.target.value);
                    }}
                    isError={
                      amenitiesMasterData?.error?.amenityName?.length > 0
                    }
                    errorMessage={amenitiesMasterData?.error?.amenityName}
                    isReadonly={amenitiesMasterData?.view}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectBox
                    value={amenitiesMasterData?.itemType}
                    label={t("Item Type")}
                    placeholder={t("Select Item Type")}
                    onChange={(value) => {
                      setAmenitiesMasterData({
                        ...amenitiesMasterData,
                        itemType: value,
                        itemCategory: "",
                        itemMaster: "",
                      });
                    }}
                    options={enumValue?.inspection_item_type ?? []}
                    isPaginate={true}
                    isReadOnly={amenitiesMasterData?.view}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectBox
                    value={amenitiesMasterData?.itemCategory}
                    label={t("Item Category")}
                    placeholder={t("Select Item Category")}
                    onChange={(value) => {
                      setAmenitiesMasterData({
                        ...amenitiesMasterData,
                        itemCategory: value,
                        itemMaster: "",
                      });
                    }}
                    loadOptions={(search, array, handleLoading) =>
                      loadOptionsApis(
                        "inspection_item_category",
                        {
                          item_type: [amenitiesMasterData?.itemType?.value],
                        },
                        search,
                        array,
                        handleLoading,
                        "list"
                      )
                    }
                    debounceTimeout={800}
                    isPaginate={true}
                    key={JSON.stringify(amenitiesMasterData?.itemType)}
                    isReadOnly={!amenitiesMasterData?.itemType}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectBox
                    isRequired
                    value={amenitiesMasterData?.itemMaster}
                    label={t("Item Master")}
                    placeholder={t("Select Item Master")}
                    onChange={(value) => {
                      updateState("itemMaster", value);
                    }}
                    loadOptions={(search, array, handleLoading) =>
                      loadOptionsApis(
                        "free_text_invoice/get_free_text_item_types",
                        {
                          company_id: selectedCompany?.value,
                          item_type: "Item Master",
                          item_master_type:
                            amenitiesMasterData?.itemType?.value,
                          item_master_category:
                            amenitiesMasterData?.itemCategory?.value,
                          component_type: "item_based",
                        },
                        search,
                        array,
                        handleLoading,
                        "items",
                        {},
                        itemMasterManualResponse
                      )
                    }
                    debounceTimeout={800}
                    isPaginate={true}
                    isError={amenitiesMasterData?.error?.itemMaster?.length > 0}
                    errorMessage={amenitiesMasterData?.error?.itemMaster}
                    isReadOnly={amenitiesMasterData?.view}
                    key={JSON.stringify(
                      amenitiesMasterData?.itemCategory,
                      amenitiesMasterData?.itemCategory
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    gutterBottom
                    style={{
                      color: "#98A0AC",
                      fontSize: "0.75rem",
                      marginBottom: "4px",
                      fontFamily: FontFamilySwitch().semiBold,
                    }}
                  >
                    {t("Status")} <span className={classes.errorStar}>*</span>
                  </Typography>
                  <ToggleButtonComponent
                    options={STATUS_OPTIONS(t)}
                    value={amenitiesMasterData?.status}
                    onChange={(value) => updateState("status", value)}
                    isMulti={false}
                    fullWidth={false}
                    isError={amenitiesMasterData?.error?.status?.length > 0}
                    errorMessage={amenitiesMasterData?.error?.status}
                    isReadOnly={amenitiesMasterData?.view}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    gutterBottom
                    style={{
                      color: "#98A0AC",
                      fontSize: "0.75rem",
                      marginBottom: "4px",
                      fontFamily: FontFamilySwitch().semiBold,
                    }}
                  >
                    {t("Booking Required")}{" "}
                    <span className={classes.errorStar}>*</span>
                  </Typography>
                  <ToggleButtonComponent
                    options={BOOKING_TYPE(t)}
                    value={amenitiesMasterData?.booking}
                    onChange={(value) => {
                      updateState("booking", value);
                    }}
                    isMulti={false}
                    fullWidth={false}
                    isError={amenitiesMasterData?.error?.booking?.length > 0}
                    errorMessage={amenitiesMasterData?.error?.booking}
                    isReadOnly={amenitiesMasterData?.view}
                    isrequired
                  />
                </Grid>
                <Grid item xs={12}>
                  <Editor
                    label={t("Description")}
                    handleChange={(e) => {
                      updateState("description", e);
                    }}
                    value={amenitiesMasterData?.description}
                    height={"80px"}
                    toolbar={{
                      toolbar: {
                        container: [
                          [{ header: [1, 2, false] }],
                          [{ color: [] }, { background: [] }],
                          ["bold", "italic", "underline", "strike"],
                          [{ align: [] }],
                          [{ list: "ordered" }, { list: "bullet" }],
                          [],
                          [],
                          [],
                        ],
                      },
                      "emoji-toolbar": true,
                      // "emoji-textarea": true,
                      "emoji-shortname": true,
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.fixedbtn}>
          {(((amenitiesMasterData?.edit || amenitiesMasterData?.view) &&
            permission?.update) ||
            (!amenitiesMasterData?.view &&
              !amenitiesMasterData?.edit &&
              permission?.create)) && (
            <Box p={1} justifyContent={"end"} display={"flex"}>
              <Button
                variant="contained"
                className={classes.btn}
                onClick={
                  amenitiesMasterData?.view
                    ? () =>
                        setAmenitiesMasterData({
                          ...amenitiesMasterData,
                          view: false,
                          edit: true,
                        })
                    : UpsertAmenitiesMaster
                }
                disabled={isDisableBtn}
              >
                {amenitiesMasterData?.view
                  ? t("Edit")
                  : amenitiesMasterData?.edit
                  ? t("Update")
                  : t("Create New Amenity")}
              </Button>
            </Box>
          )}
        </Box>
      </>
    );
  };
  //view modal
  const card = [
    {
      size: {
        xs: 4,
        sm: 4,
        md: 4,
        lg: 4,
      },
      isActive: viewAmenityDetails?.amenityType,
      component: "text",
      heading: t("Amenity Type"),
      subtitle: `${viewAmenityDetails?.amenities_type}`,
    },
    {
      size: {
        xs: 4,
        sm: 4,
        md: 4,
        lg: 4,
      },
      isActive: viewAmenityDetails?.category?.name,
      component: "text",
      heading: t("Amenity Category"),
      subtitle: `${viewAmenityDetails?.category?.name} `,
    },
    {
      size: {
        xs: 4,
        sm: 4,
        md: 4,
        lg: 4,
      },
      isActive: true,
      component: "text",
      heading: t("Amenity Name"),
      subtitle: `${viewAmenityDetails?.amenities_name} `,
    },
    {
      size: {
        xs: 4,
        sm: 4,
        md: 4,
        lg: 4,
      },
      isActive: true,
      component: "text",
      heading: t("Status"),
      subtitle: viewAmenityDetails?.is_active ? "Active" : "In-active",
    },
    {
      size: {
        xs: 4,
        sm: 4,
        md: 4,
        lg: 4,
      },
      isActive: true,
      component: "text",
      heading: t("Booking Required"),
      subtitle: viewAmenityDetails?.is_booking ? "Yes" : "No",
    },
    {
      size: {
        xs: 4,
        sm: 4,
        md: 4,
        lg: 4,
      },
      isActive: viewAmenityDetails?.description,
      component: "description",
      heading: t("Description"),
      subtitle: `${viewAmenityDetails?.description} `,
    },
    {
      size: {
        xs: 4,
        sm: 4,
        md: 4,
        lg: 4,
      },
      isActive: viewAmenityDetails?.item_master,
      component: "text",
      heading: t("Item Master"),
      subtitle: `${viewAmenityDetails?.item_master?.label} `,
    },
  ];
  const viewAmenity = () => {
    return (
      <Box p={2}>
        <AssetView asset={viewAmenityDetails?.assets ?? []} />
        <DetailsCardCoponent components={card} t={t} />
        <Box mt={2}>
          <Button
            fullWidth
            variant="contained"
            onClick={() => showEdit(viewAmenityDetails)}
          >
            {t("Edit")}
          </Button>
        </Box>
      </Box>
    );
  };
  const showEdit = (data) => {
    setViewModal(false);
    setOpenDialog(true);
    setAssets(data?.assets);
    setAmenitiesMasterData({
      ...amenitiesMasterData,
      amenityName: data?.amenities_name,
      amenityCategory: {
        label: data?.category?.name,
        value: data?.category?.id,
      },
      amenityType: { label: data?.amenities_type, value: data?.amenities_type },
      is_active: data?.is_active,
      status: data?.is_active,
      booking: data?.is_booking,
      description: data?.description,
      edit: true,
      view: false,
      id: data?.id,
      itemType: {
        label: data?.item_master?.category?.item_type,
        value: data?.item_master?.category?.item_type,
      },
      itemCategory: data?.item_master?.category,
      itemMaster: {
        label: data?.item_master?.label,
        value: data?.item_master?.value,
      },
    });
  };
  const manualCategoryResponse = (array) => {
    return array?.amenity_category;
  };
  const render = () => {
    return (
      <>
        {loading ? (
          <LoadingSection
            bottom={"45vh"}
            message={t("Loading Amenities Listing...")}
          />
        ) : (
          <>
            <div>
              <Subheader
                title={t("Amenities Master")}
                count={false}
                hideBackButton
                select
                options={companyList}
                value={selectedCompany}
                onchange={
                  (e) => setSelectedCompany(e)
                  // getProperty(e)
                }
              />
              <CustomPaper>
                <Grid container className={classes.content} spacing={2}>
                  <Grid item xs={4}>
                    <SearchFilter
                      placeholder={t("Search By Amenity Name")}
                      value={searchText}
                      handleChange={(value) => handleSearch(value)}
                      customfieldSx={{
                        "& .MuiOutlinedInput-root": { height: "40px" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={8} textAlign={"right"}>
                    <Box display={"flex"} sx={{ justifyContent: "end" }}>
                      <Box marginRight={"12px"} display={"flex"}>
                        <Box>
                          {filterData?.amenities_type?.length > 0 ||
                          filterData?.is_active?.length > 0 ||
                          filterData?.amenity_category?.length > 0 ||
                          filterData?.is_booking?.length > 0 ? (
                            <IconButton
                              onClick={draweropen}
                              className={classes.img}
                            >
                              <Badge variant="dot" color="primary">
                                <FilterIMG color="#091b29" />
                              </Badge>
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={draweropen}
                              className={classes.img}
                            >
                              <FilterIMG color="#091b29" />
                            </IconButton>
                          )}
                        </Box>

                        {permission?.create && (
                          <Box>
                            <CustomButton
                              color="primary"
                              variant="contained"
                              onClick={() => setOpenDialog(true)}
                            >
                              {t("Add Amenity")}
                            </CustomButton>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <TableWithPagination
                      heading={AmenitiesMasterHeading(t)}
                      rows={
                        AmenitiesMasterRow?.length ? AmenitiesMasterRow : []
                      }
                      path={AmenitiesMasterPath}
                      showpagination={true}
                      tableType="no-side"
                      handleIcon={handleIcon}
                      onClick={() => null}
                      showpdfbtn={false}
                      showexcelbtn={false}
                      showSearch={false}
                      dataType={[
                        { type: ["text"], name: "amenityName" },
                        { type: ["text"], name: "amenityType" },
                        { type: ["text"], name: "amenityCategory" },
                        { type: ["text"], name: "booking" },
                        { type: ["status"], name: "is_active" },
                        { type: ["text"], name: "images" },
                        { type: ["more"], icon: "icon" },
                      ]}
                      handlePagination={handlePagination}
                      handleChangeLimit={handleChangeLimit}
                      totalRowsCount={amenitiesMasterList?.totalRowsCount}
                      page={page}
                      limit={limit}
                      height={"calc(100vh - 290px)"}
                      view={permission?.read}
                      edit={permission?.update}
                      delete={permission?.delete}
                    />
                  </Grid>
                </Grid>
              </CustomPaper>
            </div>
            {/* add and edit  and view */}
            <AlertDialog
              md
              header={
                amenitiesMasterData?.view
                  ? t("View Amenity")
                  : amenitiesMasterData?.edit
                  ? t("Edit Amenity")
                  : t("Add Amenity")
              }
              onClose={() => {
                setOpenDialog(false);
                setAmenitiesMasterData({ ...initialState });
                setAssets([]);
              }}
              open={openDialog}
              component={
                <>
                  <div>{addAndEdit()}</div>
                </>
              }
            />
            {/*view Modal*/}
            <AlertDialog
              medium
              header={
                amenitiesMasterData?.view
                  ? t("View Amenity")
                  : amenitiesMasterData?.edit
                  ? t("Edit Amenity")
                  : t("Add Amenity")
              }
              onClose={() => {
                setViewModal(false);
                setAmenitiesMasterData({ ...initialState });
                setAssets([]);
              }}
              open={viewModal}
              component={
                <>
                  <div>{viewAmenity()}</div>
                </>
              }
            />

            {/* filter drawer */}
            {drawer && (
              <FilterGenerator
                open={drawer}
                onClose={drawerclose}
                components={[
                  {
                    component: "select",
                    value: filterData?.amenities_type,
                    options: enumValue?.aminityType,
                    isMulti: true,
                    state_name: "amenities_type",
                    label: t("Amenity Type"),
                    placeholder: t("Amenity Type"),
                  },
                  {
                    component: "select",
                    value: filterData?.amenity_category,
                    loadOptions: (search, array, handleLoading) =>
                      loadOptionsApis(
                        "queries/amenity_category",
                        {},
                        search,
                        array,
                        handleLoading,
                        "data",
                        {},
                        manualCategoryResponse
                      ),
                    debounceTimeout: 800,
                    isPaginate: true,
                    isMulti: true,
                    state_name: "amenity_category",
                    label: t("Amenity Category"),
                    placeholder: t("Amenity Category"),
                  },
                  {
                    component: "toggleButton",
                    value: filterData?.is_booking,
                    options: [
                      { label: t("Yes"), value: true },
                      { label: t("No"), value: false },
                    ],
                    isMulti: true,
                    state_name: "is_booking",
                    // required:true
                    buttonStyle: { width: "65px" },
                    label: t("Booking Required"),
                  },
                  {
                    component: "toggleButton",
                    value: filterData?.is_active,
                    options: [
                      { label: t("Active"), value: true },
                      { label: t("Inactive"), value: false },
                    ],
                    isMulti: true,
                    state_name: "is_active",
                    label: t("Status"),
                    // required:true
                  },
                ]}
                onApply={(value) => onApplyFilter(value)}
              />
            )}
          </>
        )}
      </>
    );
  };

  return <>{accessCheckRender(render, permission)}</>;
};
export default withTranslation("amenities")(AmenitiesMaster);
