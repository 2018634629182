export const ReceiptHistoryHeading = (t) => [
    { title: t("Tagged Date"), field: "created_at" },
    { title: t("Receipt Number"), field: "reciepts_no" },
    { title: t("Settlement No"), field: "settlement_no"},
    { title: t("Source"), field: "source"},
    { title: t("Tagged By"), field: "tagged_by" },
    { title: t("Amount"), field: "amount_tagged"},
    
]


