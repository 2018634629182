import { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, accessCheckRender, getCompanyOption, getRoutePermissionNew, pageName, FontFamilySwitch } from "../../utils";
import { useStyles } from "./style";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Subheader from "../../components/subheader/subheader";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { BackendRoutes, Routes } from "../../router/routes";
import {
  constructResponseGetAreaStatisticsListData,
  constructResponseGetUnitCategoryStats,
  constructResponseGetOccupancyPropertyListData,
  constructResponseGetStatData,
} from "./utils/helperFunctions";
import { Map } from "../propertymanagerdashboard/map";
import { SelectBox, TableWithPagination, DialogDrawer } from "../../components";
import Graphcomponent from "../propertymanagerdashboard/components/graphcomponent";
import { AreaStatisticsListDataType, AreaStatisticsListHeading, AreaStatisticsListPath, INITIAL_UOM, OccupancyPropertyListDataType, OccupancyPropertyListHeading, OccupancyPropertyListPath, scale } from "./utils/constant";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { CardDetails } from "../propertymanagerdashboard/components";
import { useNavigate } from "react-router-dom";

const PropertyManagerDashboard = ({
  loading = true,
  handleLoading = () => false,
  t = () => false,
}) => {
  const graph_offset = 0;
  const graph_page = 1;
  const graph_limit = 4;
  const module_id = localStorage?.getItem?.(
    LocalStorageKeys?.activeRoleModuleId
  );

  const classes = useStyles();
  const auth = useContext(AuthContext);
  const backdrop = useContext(BackdropContext);
  const alert = useContext(AlertContext);
  const navigate = useNavigate();

  const [permission_state, set_permission_state] = useState("");
  const [selected_company, set_selected_company] = useState("");
  const [company_list, set_company_list] = useState([]);
  const [selected_teams, set_selected_teams] = useState("");
  const [stat_data, set_stat_data] = useState("");
  const [area_statistics_list_data, set_area_statistics_list_data] = useState({
    rows: [],
    count: 0,
  });
  const [area_statistics_list_page, set_area_statistics_list_page] =
    useState(1);
  const [area_statistics_list_limit, set_area_statistics_list_limit] =
    useState(10);
  const [is_area_statistics_list_loading, set_is_area_statistics_list_loading] =
    useState(true);
  const [area_statistics_list_uom, set_area_statistics_list_uom] =
    useState(INITIAL_UOM);
  const [occupancy_property_list_data, set_occupancy_property_list_data] =
    useState({ rows: [], count: 0 });
  const [occupancy_property_list_page, set_occupancy_property_list_page] =
    useState(1);
  const [occupancy_property_list_limit, set_occupancy_property_list_limit] =
    useState(10);
  const [
    is_occupancy_property_list_loading,
    set_is_occupancy_property_list_loading,
  ] = useState(true);
  const [is_zoom, set_is_zoom] = useState(false);
  const [zoom_graph_title, set_zoom_graph_title] = useState("");
  const [graph_option_state, set_graph_option_state] = useState({
    unit_category: {
      offset: graph_offset,
      page: graph_page,
      limit: graph_limit,
    },
  });
  const [unit_category_state, set_unit_category_state] = useState({
    total: 0,
    data: [],
  });

  useEffect(() => {
    const permission = getRoutePermissionNew(auth);
    if (permission) {
      set_permission_state(permission);
      if (permission?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          set_company_list(company?.list);
          set_selected_company(company?.selected);
          getInitialData({ company: company?.selected });
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  const handleCompanyChange = (_) => {
    set_selected_company(_);
    getInitialData({ company: _ });
  };

  const handleTeamsChange = (team) => {
    set_selected_teams(team);
  };

  const getInitialData = ({ company = selected_company }) => {
    return new Promise(async (resolve, reject) => {
      try {
        await Promise.all([
          getStatData({ company }),
          getAreaStatisticsListData({ company }),
          getOccupancyPropertyListData({ company }),
          getUnitCategoryStats({ company }),
        ]);
        handleLoading(false);
      } catch (error) {
        reject(error);
      }
    });
  };

  const getStatData = ({ company = selected_company }) => {
    let payload = { company_id: company?.value };

    NetworkCall(
      `${config?.api_url}${BackendRoutes?.property_manager}`,
      NetWorkCallMethods?.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const temp_stat_data = constructResponseGetStatData({
          data: res?.data?.data,
          t,
        });
        set_stat_data(temp_stat_data);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          msg: t("Some Thing Went Wrong"),
          severity: AlertProps?.severity?.error,
        });
      });
  };

  const getAreaStatisticsListData = ({
    offset = 0,
    limit = area_statistics_list_limit,
    company = selected_company,
    uom = area_statistics_list_uom,
  }) => {
    set_is_area_statistics_list_loading(true);
    let payload = {
      company_id: company?.value,
      start: offset,
      length: limit,
      uom_id: uom?.value ?? undefined,
    };

    NetworkCall(
      `${config?.api_url}${BackendRoutes?.property_manager_purpose_area_statistics}`,
      NetWorkCallMethods?.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const temp_area_statistics_list =
          constructResponseGetAreaStatisticsListData({
            data: res?.data?.data,
            t,
          });
        set_area_statistics_list_data(temp_area_statistics_list);
        set_is_area_statistics_list_loading(false);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          msg: t("Some Thing Went Wrong"),
          severity: AlertProps?.severity?.error,
        });
      });
  };

  const getOccupancyPropertyListData = ({
    offset = 0,
    limit = occupancy_property_list_limit,
    company = selected_company,
  }) => {
    set_is_occupancy_property_list_loading(true);
    let payload = {
      company_id: company?.value,
      start: offset,
      length: limit,
    };

    NetworkCall(
      `${config?.api_url}${BackendRoutes?.property_manager_occpancy_vs_property}`,
      NetWorkCallMethods?.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const temp_occupancy_property_list =
          constructResponseGetOccupancyPropertyListData({
            data: res?.data?.data,
            t,
          });
        set_occupancy_property_list_data(temp_occupancy_property_list);
        set_is_occupancy_property_list_loading(false);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          msg: t("Some Thing Went Wrong"),
          severity: AlertProps?.severity?.error,
        });
      });
  };

  const areaStatisticsListHandlePagination = (_) => {
    set_area_statistics_list_page(_);
    let offset = (_ - 1) * area_statistics_list_limit;
    getAreaStatisticsListData({ offset });
  };

  const areaStatisticsListHandleChangeLimit = (_) => {
    set_area_statistics_list_limit(_);
    set_area_statistics_list_page(1);
    getAreaStatisticsListData({ limit: _ });
  };

  const handleAreaStatisticsListUOM = (_) => {
    set_area_statistics_list_uom(_);
    getAreaStatisticsListData({ uom: _ });
  };

  const occupancyPropertyListHandleIcon = (type, data) => {
    switch (type) {
      case "view":
        navigate(Routes?.unit + "?propertyID=" + data?.id);
        break;

      default:
        break;
    }
  };

  const occupancyPropertyListHandlePagination = (_) => {
    set_occupancy_property_list_page(_);
    let offset = (_ - 1) * occupancy_property_list_limit;
    getOccupancyPropertyListData({ offset });
  };

  const occupancyPropertyListHandleChangeLimit = (_) => {
    set_occupancy_property_list_limit(_);
    set_occupancy_property_list_page(1);
    getOccupancyPropertyListData({ limit: _ });
  };

  const getUnitCategoryStats = ({
    company = selected_company,
    offset = graph_option_state?.unit_category?.offset,
    limit = graph_option_state?.unit_category?.limit,
  }) => {
    const payload = {
      company_id: company?.value ?? "",
      offset,
      limit,
    };

    NetworkCall(
      `${config.api_url}${BackendRoutes?.property_manager_unit_category_dashboard}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const result = constructResponseGetUnitCategoryStats({
          data: res?.data?.data,
          t,
        });
        set_unit_category_state(result);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          msg: t("Some Thing Went Wrong"),
          severity: AlertProps.severity.error,
        });
      });
  };

  const handleUnitCategoryPagination = (value) => {
    set_graph_option_state({
      ...graph_option_state,
      unit_category: {
        ...graph_option_state?.unit_category,
        page: value,
      },
    });
    let offset = (value - 1) * graph_option_state?.unit_category?.limit;
    getUnitCategoryStats({ offset });
  };

  const handleOnZoomClick = (e) => {
    set_zoom_graph_title(e);
    set_is_zoom(true);
  };

  const handleCloseModule = () => {
    set_is_zoom(false);
  };

  const uomMasterManualResponse = (array) => {
    const details = array?.uom_master?.map((i) => {
      return {
        ...i,
        label: i?.label,
        value: i?.value,
      }
    })
    return details
  }

  const render = () => {
    return (
      <>
        {/* sub header */}
        <Subheader
          title={t("Property Manager Dashboard")}
          hideBackButton
          select
          options={company_list}
          value={selected_company}
          onchange={(e) => handleCompanyChange(e)}
          selectTeamsOptions
          handleTeamsChange={handleTeamsChange}
          companyId={selected_company?.value}
          moduleId={module_id}
          selectedTeams={selected_teams}
        />
        <Box className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} lg={1.5} sx={{ display: "flex" }}>
              <Grid container spacing={2}>
                {stat_data?.count_1?.map((_) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      onClick={() => (_?.route ? navigate(_?.route) : false)}
                    >
                      <CardDetails
                        data={_}
                        padding={16}
                        sub_style={{ fontFamily: FontFamilySwitch().bold }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={8} lg={3}>
              <Graphcomponent
                padding={"8px 16px"}
                t={t}
                isShowLegend={true}
                graphTitle={t("Property Type")}
                chartHeader={t("Property Type")}
                graphData={stat_data?.property_type ?? []}
                is_pie_new
                onZoomClick={handleOnZoomClick}
                minWidth={true}
                textMargin={"0px 0px 16px"}
                divider1={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                divider={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                gradiantStyle={{
                  margin: "15px 0px 0px",
                  top: { lg: "auto", md: "16px", sm: "3px" },
                  height: { md: "400px" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={1.5} sx={{ display: "flex" }}>
              <Grid container spacing={2}>
                {stat_data?.count_2?.map((_) => {
                  return (
                    <Grid item xs={12}>
                      <CardDetails
                        data={_}
                        padding={16}
                        sub_style={{ fontFamily: FontFamilySwitch().bold }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={8} lg={3}>
              <Graphcomponent
                t={t}
                padding={"8px 16px"}
                indexAxis={"y"}
                isNoLegend
                graphTitle={t("Unit Status")}
                chartHeader={t("Unit Status")}
                graphData={stat_data?.unit_status ?? []}
                is_bar_new
                scale={scale}
                margin={"0px"}
                onZoomClick={(e) => handleOnZoomClick(e)}
                height={300}
                chartHeight={"300px"}
                justifyContent={"start"}
                gradiantStyle={{
                  margin: "15px 0px 0px",
                  top: { lg: "auto", md: "7px", sm: "3px" },
                  height: { sm: "63px" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={3} sx={{ display: "flex" }}>
              <Grid container spacing={2}>
                {stat_data?.count_3?.map((_) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={6}>
                      <CardDetails
                        data={_}
                        padding={16}
                        sub_style={{ fontFamily: FontFamilySwitch().bold }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} lg={1.5} sx={{ display: "flex" }}>
              <Grid container spacing={2}>
                {stat_data?.count_4?.map((_) => {
                  return (
                    <Grid item xs={12}>
                      <CardDetails
                        data={_}
                        padding={16}
                        sub_style={{ fontFamily: FontFamilySwitch().bold }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={8} lg={3.5}>
              <Graphcomponent
                t={t}
                padding={"8px 16px"}
                indexAxis={"y"}
                graphTitle={t("Unit Category")}
                chartHeader={t("Unit Category")}
                height={200}
                chartHeight={"265px"}
                is_pagination={
                  unit_category_state?.total >
                  graph_option_state?.unit_category?.limit
                }
                graph_data_total={unit_category_state?.total}
                graph_data_limit={graph_option_state?.unit_category?.limit}
                graph_data_page={graph_option_state?.unit_category?.page}
                handle_graph_pagination={handleUnitCategoryPagination}
                graphData={unit_category_state?.data ?? []}
                is_bar_new
                isNoLegend
                disableNoData
                scale={scale}
                margin={"0px"}
                onZoomClick={(e) => handleOnZoomClick(e)}
                gheight={250}
                justifyContent={"start"}
                gradiantStyle={{
                  margin: "15px 0px 0px",
                  top: { lg: "auto", md: "7px", sm: "3px" },
                  height: { sm: "63px" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3.5}>
              <Graphcomponent
                margin={"0px"}
                padding={"8px 16px"}
                t={t}
                graphTitle={t("Unit Type")}
                chartHeader={t("Unit Type")}
                graphData={stat_data?.unit_type ?? []}
                is_pie_new
                isShowLegend={true}
                onZoomClick={handleOnZoomClick}
                minWidth={true}
                textMargin={"0px 0px 16px"}
                divider1={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                divider={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                gradiantStyle={{
                  margin: "15px 0px 0px",
                  top: { lg: "auto", md: "16px", sm: "3px" },
                  height: { md: "400px" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3.5}>
              <Graphcomponent
                t={t}
                padding={"8px 16px"}
                graphTitle={t("Unit Purpose")}
                chartHeader={t("Unit Purpose")}
                graphData={stat_data?.unit_purpose?.data ?? []}
                is_semi_circle_new
                innerRadius={70}
                isTotal
                isShowLegend={true}
                total={stat_data?.unit_purpose?.total ?? 0}
                onZoomClick={handleOnZoomClick}
                minWidth={true}
                textMargin={"0px 0px 16px"}
                divider1={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                divider={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                gradiantStyle={{
                  margin: "15px 0px 0px",
                  top: { lg: "auto", md: "16px", sm: "3px" },
                  height: { md: "400px" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={7}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack
                    className={classes?.table_section}
                    direction={"column"}
                    style={window.innerWidth <= 600 ? { height: "437px" } : {}}
                  >
                    <Typography className={classes.table_header}>
                      {t("Occupancy By Property")}
                    </Typography>
                    <TableWithPagination
                      t={t}
                      is_loading={is_occupancy_property_list_loading}
                      heading={OccupancyPropertyListHeading({ t })}
                      rows={occupancy_property_list_data?.rows}
                      path={OccupancyPropertyListPath}
                      dataType={OccupancyPropertyListDataType}
                      showpagination
                      handleIcon={occupancyPropertyListHandleIcon}
                      handlePagination={occupancyPropertyListHandlePagination}
                      handleChangeLimit={occupancyPropertyListHandleChangeLimit}
                      totalRowsCount={occupancy_property_list_data?.count}
                      page={occupancy_property_list_page}
                      limit={occupancy_property_list_limit}
                      height={280}
                      tableType="no-side"
                      view={permission_state?.read}
                      edit={permission_state?.update}
                      delete={permission_state?.delete}
                    />
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={6}
                  style={{ flexGrow: 1, maxWidth: "100%" }}
                >
                  <Stack
                    className={classes?.table_section}
                    direction={"column"}
                    style={window.innerWidth <= 600 ? { height: "437px" } : {}}
                  >
                    <Stack
                      direction={"row"}
                      columnGap={"16px"}
                      justifyContent={"space-between"}
                    >
                      <Typography className={classes.table_header}>
                        {t("Area Statistics")}
                      </Typography>
                      <Box minWidth={"200px"}>
                        <SelectBox
                          placeholder={t("Select UOM")}
                          selectHeight={"35px"}
                          value={area_statistics_list_uom}
                          onChange={(_) => handleAreaStatisticsListUOM(_)}
                          isPaginate
                          debounceTimeout={800}
                          loadOptions={(search, array, handleLoading) =>
                            loadOptionsApis(
                              "queries/property_search/uom_master",
                              {},
                              search,
                              array,
                              handleLoading,
                              "data",
                              {},
                              uomMasterManualResponse,
                            )
                          }
                        />
                      </Box>
                    </Stack>
                    <TableWithPagination
                      t={t}
                      is_loading={is_area_statistics_list_loading}
                      heading={AreaStatisticsListHeading({ t })}
                      rows={area_statistics_list_data?.rows}
                      path={AreaStatisticsListPath}
                      dataType={AreaStatisticsListDataType}
                      showpagination
                      handleIcon={() => { }}
                      handlePagination={areaStatisticsListHandlePagination}
                      handleChangeLimit={areaStatisticsListHandleChangeLimit}
                      totalRowsCount={area_statistics_list_data?.count}
                      page={area_statistics_list_page}
                      limit={area_statistics_list_limit}
                      height={267}
                      tableType="no-side"
                      view={permission_state?.read}
                      edit={permission_state?.update}
                      delete={permission_state?.delete}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} lg={5}>
              <Map
                t={t}
                selectedcompany={selected_company}
                selectedTeams={selected_teams}
              />
            </Grid>
          </Grid>
        </Box>
        <DialogDrawer
          header={t(zoom_graph_title)}
          maxWidth={"md"}
          handleClose={handleCloseModule}
          open={is_zoom}
          height={"auto"}
          borderRadius={"12px"}
          padding={"0px"}
          onClose={handleCloseModule}
          component={
            <div>
              {zoom_graph_title === "Property Type" && (
                <Graphcomponent
                  t={t}
                  title={t("Property Type")}
                  graphData={stat_data?.property_type ?? []}
                  isPie
                  margin={"20px"}
                  padding={"16px"}
                  maxHeightGraph={"250px"}
                  minWidth={false}
                  height={"auto"}
                  is_popUp
                  justifyContent={"start"}
                  divider1={{ xs: 12, sm: 5, md: 5, lg: 5 }}
                  divider={{ xs: 12, sm: 7, md: 7, lg: 7 }}
                  gradiantStyle={{
                    margin: "0px 0px 0px",
                    display: "flex",
                    alignItems: "center",
                    grid_template: "100%",
                    maxHeight: "auto",
                    top: { lg: "auto", md: "-8px", sm: "auto" },
                    height: { md: "auto", sm: "auto", lg: "100%" },
                  }}
                />
              )}
              {zoom_graph_title === "Unit Status" && (
                <Graphcomponent
                  t={t}
                  indexAxis={"y"}
                  title={t("Unit Status")}
                  graphData={stat_data?.unit_status ?? []}
                  isBar
                  scale={scale}
                  margin={"20px"}
                  minWidth={false}
                  gheight={"500px"}
                  gwidth={"400px"}
                  is_popUp
                  justifyContent={"start"}
                  padding={"0px 16px 16px"}
                  divider1={{ xs: 12, sm: 4, md: 4, lg: 4 }}
                  divider={{ xs: 12, sm: 8, md: 8, lg: 8 }}
                  gradiantStyle={{
                    margin: "15px 0px 0px",
                    grid_template: "100%",
                    display: "flex",
                    alignItems: "center",
                    maxHeight: "auto",
                    top: { lg: "auto", md: "16px", sm: "auto" },
                    height: { md: "auto", sm: "auto", lg: "100%" },
                  }}
                />
              )}
              {zoom_graph_title === "Unit Category" && (
                <Graphcomponent
                  t={t}
                  indexAxis={"y"}
                  title={t("Unit Category")}
                  is_popup_pagination={
                    unit_category_state?.total >
                    graph_option_state?.unit_category?.limit
                  }
                  graph_data_total={unit_category_state?.total}
                  graph_data_limit={graph_option_state?.unit_category?.limit}
                  graph_data_page={graph_option_state?.unit_category?.page}
                  handle_graph_pagination={handleUnitCategoryPagination}
                  graphData={unit_category_state?.data ?? []}
                  isBar
                  disableNoData
                  scale={scale}
                  margin={"20px"}
                  minWidth={false}
                  gheight={"500px"}
                  gwidth={"400px"}
                  is_popUp
                  justifyContent={"start"}
                  padding={"0px 16px 16px"}
                  divider1={{ xs: 12, sm: 4, md: 4, lg: 4 }}
                  divider={{ xs: 12, sm: 8, md: 8, lg: 8 }}
                  gradiantStyle={{
                    margin: "15px 0px 0px",
                    grid_template: "100%",
                    display: "flex",
                    alignItems: "center",
                    maxHeight: "auto",
                    top: { lg: "auto", md: "16px", sm: "auto" },
                    height: { md: "auto", sm: "auto", lg: "100%" },
                  }}
                />
              )}
              {zoom_graph_title === "Unit Type" && (
                <Graphcomponent
                  t={t}
                  title={t("Unit Type")}
                  graphData={stat_data?.unit_type ?? []}
                  isPie
                  margin={"20px"}
                  padding={"16px"}
                  maxHeightGraph={"250px"}
                  minWidth={false}
                  height={"auto"}
                  is_popUp
                  justifyContent={"start"}
                  divider1={{ xs: 12, sm: 5, md: 5, lg: 5 }}
                  divider={{ xs: 12, sm: 7, md: 7, lg: 7 }}
                  gradiantStyle={{
                    margin: "0px 0px 0px",
                    display: "flex",
                    alignItems: "center",
                    grid_template: "100%",
                    maxHeight: "auto",
                    top: { lg: "auto", md: "-8px", sm: "auto" },
                    height: { md: "auto", sm: "auto", lg: "100%" },
                  }}
                />
              )}
              {zoom_graph_title === "Unit Purpose" && (
                <Graphcomponent
                  t={t}
                  title={t("Unit Purpose")}
                  graphData={stat_data?.unit_purpose?.data ?? []}
                  isPie
                  margin={"40px"}
                  innerRadius={100}
                  centerTop="44%"
                  isTotal
                  total={stat_data?.unit_purpose?.total ?? 0}
                  padding={"16px"}
                  maxHeightGraph={"250px"}
                  minWidth={false}
                  height={"auto"}
                  is_popUp
                  justifyContent={"start"}
                  divider1={{ xs: 12, sm: 5, md: 5, lg: 5 }}
                  divider={{ xs: 12, sm: 7, md: 7, lg: 7 }}
                  gradiantStyle={{
                    margin: "0px 0px 0px",
                    display: "flex",
                    alignItems: "center",
                    grid_template: "100%",
                    maxHeight: "auto",
                    top: { lg: "auto", md: "-8px", sm: "auto" },
                    height: { md: "auto", sm: "auto", lg: "100%" },
                  }}
                />
              )}
            </div>
          }
        />
      </>
    );
  };

  return (
    <div>
      {accessCheckRender(render, permission_state, pageName.dashboard, loading)}
    </div>
  );
};
export default withTranslation("propertyManagerDashBoard")(
  PropertyManagerDashboard
);
