import {
  Box,
  Button,
  Divider,
  Grid, Menu,
  MenuItem, Stack
} from "@mui/material";
import React, { useContext, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  DialogBox,
  SearchFilter,
  Subheader,
  TableWithPagination
} from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import {
  accessCheckRender,
  AlertProps, getCompanyOption,
  getRoutePermissionNew,
  NetWorkCallMethods, pricingApprialsdataType,
  pricingApprialsHeader,
  pricingApprialsPath,
  useWindowDimensions,
} from "../../utils";
import { CreateAgainstCompany, CreateAgainstProperty } from "./creationtypes";
import { PricingView } from "./stepComponents";
import { pricingAppraisalStyle } from "./style";

const PricingAppraisal = (props) => {
  // language
  const { t } = props;
  // size
  const size = useWindowDimensions();
  // classes
  const classes = pricingAppraisalStyle();
  // context
  const auth = useContext(AuthContext);
  const alert = useContext(AlertContext);
  const backdrop = useContext(BackdropContext);
  // apolo clint
  // state start
  const [company, setCompany] = useState({
    companyList: [],
    selectedCompany: {},
    searchText: "",
    openDrawer: false,
    addPopup: false,
    type: "",
    viewPopup: false,
  });
  const [appraisalList, setAppraisalList] = useState({
    data: [],
    count: "",
  });
  const [details, setDetails] = useState({
    viewData: {},
    property_details: [],
    unit_no: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const openOption = Boolean(anchorEl);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [permission, setPermission] = React.useState({})

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        let companyId = getCompanyOption(backdrop, auth, alert);
        setCompany({
          ...company,
          companyList: companyId?.list,
          selectedCompany: companyId?.selected,
        });
        if (companyId) getList(0, 10, "", companyId?.selected);
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  //   end

  /*---------------------------------function start--------------------------------------*/
  //   company select
  const companySelect = (value, key) => {
    setCompany({ ...company, [key]: value });
  };
  //   open creation type
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // Create Appraisal Pricing
  const handleClose = (type) => {
    if (type === "type") {
      setAnchorEl(null);
    } else {
      setAnchorEl(null);
      setCompany({ ...company, addPopup: true, type: type });
    }
  };
  // handleIcon
  const handleIcon = (type, data) => {
    if (type === "view") {
      getDetails(data?.data);
    }
    if (type === "Activate") {
      if (data?.Status === "Yet To Activate") {
        activateAppraisal(data?.data);
      }
    }
    if (type === "Delete") {
      updateAppraisal(data?.id, "is_delete", true, false);
    }
  };
  // view Details
  const getDetails = (value) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading...",
    });
    const data = {
      company_id: value?.company_id,
      component_type_applicablity: value?.component_type_applicablity,
      based_on: value?.component_based,
      component_id: value?.pricing_component_id,
      properties:
        value?.property_ids?.length > 0 ? value?.property_ids : undefined,
      existing_price:
        value?.appraisal_type === "Specific"
          ? value?.present_specific_value
          : undefined,
      start:
        value?.appraisal_type === "Range"
          ? Number(value?.present_range_value?.start)
          : undefined,
      end:
        value?.appraisal_type === "Range"
          ? Number(value?.present_range_value?.end)
          : undefined,
      pricing_appraisal_id: value?.id,
      is_active: value?.status === "Active" ? true : false,
    };
    NetworkCall(
      `${config.api_url}/pricing_appraisal/get_by_filter`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setDetails({
          viewData: value,
          property_details:
            value?.property_ids?.length > 0 ? response?.data?.data : [],
          unit_no:
            value?.property_ids?.length > 0
              ? ""
              : value?.status === "Active"
                ? response?.data?.refelected_units ?? 0
                : response?.data?.data?.[0]?.no_of_units ?? 0,
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading..."),
        });
        companySelect(true, "viewPopup");
      })
      .catch((error) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading..."),
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
      });
  };
  // onReload
  const onReload = () => {
    companySelect(false, "addPopup");
    getList(0, limit, "", company?.selectedCompany);
  };
  // get appraisal list
  const getList = (offset, limit, search, company) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading...",
    });
    NetworkCall(
      `${config.api_url
      }/pricing_appraisal?page=${offset}&pageSize=${limit}&search=${search?.length > 0 ? search : ""
      }&company_id=${company?.value}`,
      NetWorkCallMethods.get,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        let list = response?.data?.results?.map((item) => {
          return {
            Appraisal_Reference_ID: item?.reference_no ?? "-",
            Component_Type: item?.component_name ?? "-",
            Pricing_Component_Name: item?.pricing_component_name ?? "-",
            Revenue_Type: item?.component_revenue_type ?? "-",
            Component_Based_On: item?.component_based ?? "-",
            Previous_Value:
              item?.appraisal_type === "Specific"
                ? `${company?.selectedCompany?.currency_symbol ??
                company?.currency_symbol
                } ${item?.present_specific_value ?? 0}`
                : `${company?.selectedCompany?.currency_symbol ??
                company?.currency_symbol
                } ${item?.present_range_value?.start} - ${company?.selectedCompany?.currency_symbol ??
                company?.currency_symbol
                } ${item?.present_range_value?.end}`,
            Present_Value: `${company?.selectedCompany?.currency_symbol ??
              company?.currency_symbol
              } ${item?.new_appraisal_amount ?? 0}`,
            Status: item?.status ?? "-",
            id: item?.id,
            property_ids: item?.property_ids,
            data: item,
          };
        });
        setAppraisalList({
          data: list,
          count: response?.data?.total,
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading..."),
        });
      })
      .catch((error) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading..."),
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
      });
  };
  // update apprisal
  const updateAppraisal = (id, key, value, activate) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: t("Loading..."),
    });
    const payload={
      ID: id,
      updatePayload: {
        [key]: value,
      },
    }
   NetworkCall(
        `${config.api_url}/queries/pricing_appraisals/update`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
      .then((rs) => {
        if (activate) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: t("Appraisal Activated successfully."),
          });
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `${key === "status" ? "Status Updated" : "Deleted"
              } Successfully`,
          });
        }
        getList(0, 10, "", company?.selectedCompany);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading..."),
        });
      })
      .catch((er) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading..."),
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
      });
  };
  // activate appraisal
  const activateAppraisal = (value) => {
    const data = {
      company_id: value?.company_id,
      component_type_applicablity: value?.component_type_applicablity,
      based_on: value?.component_based,
      component_id: value?.pricing_component_id,
      properties:
        value?.property_ids?.length > 0 ? value?.property_ids : undefined,

      value: value?.new_appraisal_amount,
      existing_price:
        value?.appraisal_type === "Specific"
          ? value?.present_specific_value
          : undefined,
      start:
        value?.appraisal_type === "Range"
          ? Number(value?.present_range_value?.start)
          : undefined,
      end:
        value?.appraisal_type === "Range"
          ? Number(value?.present_range_value?.end)
          : undefined,
      pricing_appraisal_id: value?.id,
    };
    NetworkCall(
      `${config.api_url}/pricing_appraisal/activate`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((res) => {
        if (res?.data !== "no components found for respective combinations !") {
          updateAppraisal(value?.id, "status", "Active", true);
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.info,
            msg: t("no components found for respective combinations."),
          });
        }
      })
      .catch((error) => {
        getList(0, limit, "", company?.companyList?.[0]);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
      });
  };
  // pagination
  const handlePagination = (value) => {
    setPage(value);
    let offset = value - 1;
    getList(offset, limit, "", company?.companyList?.[0]);
  };
  // pagination limit change function
  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getList(0, value, "", company?.companyList?.[0]);
  };
  /** -----------------------------------------end-------------------------------------------------- */


  const render = () => {
    return <>
      {/* subheader */}
      <Subheader
        title={`${t("Pricing Appraisal")}(${appraisalList?.count ?? 0})`}
        hideBackButton={true}
        options={company?.companyList ?? []}
        value={company?.selectedCompany}
        onchange={(e) => {
          companySelect(e, "selectedCompany");
          getList(0, 10, "", e);
        }}
        select
      />
      {/* table UI */}
      <div className={classes.root}>
        <Grid container className={classes.content} spacing={2}>
          <Grid item xs={4}>
            <Box>
              {/* search box */}
              <SearchFilter
                value={company?.searchText ?? ""}
                placeholder={t("Search Appraisal Pricing")}
                handleChange={(value) => {
                  companySelect(value, "searchText");
                  getList(0, 10, value, company?.companyList?.[0]);
                }}
                customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" }}}
              />
            </Box>
          </Grid>

          <Grid item xs={8}>
            <Box display={"flex"} sx={{ justifyContent: "end" }}>
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
              >
                {permission?.create && <Box>
                  {/* add agent button */}
                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={handleClick}
                    sx={{height:"40px"}}
                  >
                    {t("Create Appraisal Pricing")}
                  </Button>
                  {/* menu select  */}
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openOption}
                    onClose={() => handleClose("type")}
                    className={classes.menuParent}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => handleClose("Create Against Company")}
                      className={classes.menu}
                    >
                      {t("Create Against Company")}
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => handleClose("Create Against Property")}
                      className={classes.menu}
                    >
                      {t("Create Against Property")}
                    </MenuItem>
                  </Menu>
                </Box>}
              </Stack>
            </Box>
          </Grid>

          {/* Main tableData */}
          <Grid item xs={12} className={classes.tableMain}>
            <TableWithPagination
              heading={pricingApprialsHeader(t)}
              rows={appraisalList?.data ?? []}
              path={pricingApprialsPath}
              showpagination={appraisalList?.count > 10 ? true : false}
              showpdfbtn={false}
              showexcelbtn={false}
              showSearch={false}
              dataType={pricingApprialsdataType}
              height={"calc(100vh - 300px)"}
              handleIcon={handleIcon}
              handlePagination={handlePagination}
              handleChangeLimit={handleChangeLimit}
              totalRowsCount={appraisalList?.count}
              page={page}
              limit={limit}
              view={permission?.read}
              edit={permission?.update}
              delete={permission?.delete}
            />
          </Grid>
          {/* end Main tableData */}
        </Grid>
      </div>
      {/* add pop up */}
      <DialogBox
        open={company?.addPopup}
        onClose={() => companySelect(false, "addPopup")}
        header={
          company?.type === "Create Against Property"
            ? t("Creating Property level Pricing Appraisal")
            : t("Creating Company level Pricing Appraisal")
        }
        maxWidth={"md"}
        component={
          <div>
            {company?.type === "Create Against Property" ? (
              <CreateAgainstProperty
                company={company?.selectedCompany}
                onReload={onReload}
                t={t}
              />
            ) : (
              <CreateAgainstCompany
                company={company?.selectedCompany}
                onReload={onReload}
                t={t}
              />
            )}
          </div>
        }
      />
      {/* view */}{" "}
      <DialogBox
        open={company?.viewPopup}
        handleClose={() => companySelect(false, "viewPopup")}
        onClose={() => companySelect(false, "viewPopup")}
        header={details?.viewData?.reference_no ?? ""}
        maxWidth={"sm"}
        component={
          <div
            className={classes.popUpParent}
            style={{ height: size?.height - 300 }}
          >
            <PricingView
              state={details?.viewData}
              property={details?.property_details}
              unit_no={details?.unit_no}
              t={t}
            />
          </div>
        }
      />
    </>
  }


  return (
    <div>
      {accessCheckRender(render, permission)}
    </div>
  );
};
export default withTranslation("profileswitcher")(PricingAppraisal);
