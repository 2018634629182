import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useNavigate } from "react-router-dom";
import { config } from '../../config';
import { AlertContext, BackdropContext } from "../../contexts";
import { NetworkCall } from '../../networkcall';
import { LocalStorageKeys, NetWorkCallMethods, billGenENUM, enumSelect, enum_types, getEnumValueOppertunity, FontFamilySwitch } from '../../utils';
import { loadOptionsApis } from '../../utils/asyncPaginateLoadOptions';
import { Billing_cycle_date, Billing_cycle_method, quotation_validity, validateCustom, yesorno } from '../../utils/companySettings';
import { getDateValue } from '../propertyFinder4/utils';
import { editConstractData, initialCreateStateResident, next } from './function';
import { Steps } from './steps';
import { residentOnboardingStyle } from './style';
import BillingContactIcon from './utils/billingContactIcon';
import ContactIcon from './utils/contactIcon';
import PropertyOwnerIcon from './utils/propertyOwnerIcon';
import { activeOccupents, boardingPropertyType } from './utils/residentOnboard';
import UnitIcon from './utils/unitIcon';



export const Create = ({
    onClose = () => false,
    state,
    company_id,
    quote_id,
    lead_id,
    reload = () => false,
    oppertunity_only = false,
    title = null,
    btnName = null,
    t, type,input_type
}) => {
    const classes = residentOnboardingStyle()
    const navigate = useNavigate();
    const alert = React.useContext(AlertContext);
    const backdrop = React.useContext(BackdropContext);
    const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId)
    const is_workspace = ['49', '35'].includes(moduleId)

    // -------------- STATE ---------------------
    const [createState, setCreateState] = React.useState({ ...initialCreateStateResident })
    const [step, setStep] = React.useState(1)
    const [enumValue, setEnum] = React.useState({
        unit_usage: [],
        priority: [],
        source: [],
        lease_type: [],
        billGenCycle: [],
        billing_cycle_date: [],
        payment_period: [],
        apply_grace_period_type: [],
        payment_mode: [],
        turnover_type: [],
        auto_renewal_escalation: [],
    });
    const [disable, setDisable] = React.useState(false)
    const [state1, setState1] = React.useState(true)


    // update state
    const updateState = (key, value) => {
        let error = createState?.error;
        error[key] = "";
        if(key === "propertyType"){
           return setCreateState({ ...createState, [key]: value,unit_category:null, error })
        }
        setCreateState({ ...createState, [key]: value, error })
    }

    const getEnum = async () => {
        const result = await enumSelect([
            enum_types.urgent_type,
            enum_types.l_period,
            enum_types.lead_source,
            enum_types.billing_cycle,
            enum_types.billing_cycle_date,
            enum_types.unit_payment_period,
            enum_types.apply_grace_period_type,
            enum_types.payment_mode,
            enum_types?.turnover_type,
            enum_types?.auto_renewal_escalation,
        ]);
        setEnum({
            priority: result?.urgent_type,
            lease_type: result?.l_period
                ?.filter((val) => val?.value !== "Quarterly")
                .filter((val) => val?.value !== "Daily"),
            billGenCycle: result?.billing_cycle,
            billing_cycle_date: result?.billing_cycle_date
                ?.filter((i) => i?.value !== billGenENUM.last_day)
                .filter((i) => i?.value !== billGenENUM.mid_date)
                .filter((i) => i?.value !== billGenENUM.first),
            payment_period: result?.payment_value_type
                .filter((val) => val?.value !== "On Completion")
                .filter((val) => val?.value !== "Milestone Based")
                .filter((val) => val?.value !== "Hourly")
                .filter((val) => val?.value !== "Weekly"),
            source: result?.lead_source,
            apply_grace_period_type: result?.apply_grace_period_type,
            payment_mode: result?.payment_mode,
            turnover_type: result?.turnover_type,
            auto_renewal_escalation: result?.auto_renewal_escalation,
        });
    };

    const sumbit = () => {
        setDisable(true);
        onClose()
    }

    const getEditData = () => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "...fetching",
        });
        setStep(2)
         NetworkCall(
            `${config?.api_url}/queries/get_lead_details`,
            NetWorkCallMethods.post,
            {
              id: lead_id,
            },
            null,
            true,
            false
          )
        .then((res) => {
            const response = editConstractData(res?.data?.data, quote_id)
            setCreateState(response)
            backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "...fetching",
            });
        }).catch((err) => {
            console.log(err)
            backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "...fetching",
            });
        })
    }
    //get company details
    const getCompanyDetails = (value) => {
            NetworkCall(
                `${config?.api_url}/queries/opportunity/get_company_details`,
                NetWorkCallMethods.post,
                {
                  company_id: company_id,
                },
                null,
                true,
                false
              )
            .then((res) => {
                setCreateState({
                    ...createState,
                    billingCycleMethod: res?.data?.company_master[0]?.billing_cycle,
                    billingCycleDate:
                        res?.data?.company_master[0]?.billing_cycle_date ===
                            "As per the Agreement Date"
                            ? res?.data?.company_master[0]?.billing_cycle_date
                            : res?.data?.company_master[0]?.billing_day,
                    paymentPeriod: {
                        value: res?.data?.company_master[0]?.payment_period,
                        label: res?.data?.company_master[0]?.payment_period,
                    },
                    propertyType:is_workspace?{
                        title: "Commercial",
                        label: "Commercial",
                        value: "Commercial"
                    }:"",
                    activeOccupents:is_workspace?{title:"Tenant"}:""
                })
            })
            .catch((err) => console.log(err));
    };

    React.useMemo(() => {
        if (createState?.startDate !== null && createState?.duration?.value) {
            let end_date = getDateValue(
                {
                    date: createState?.startDate ?? "",
                    input: createState?.duration?.value ?? "",
                    period: createState?.duration?.select ?? "",
                },
                true,
                0,
                true
            );
            setCreateState({
                ...createState,
                endDate: end_date ?? null,
                billStartDate: createState?.startDate,
                billingCycleDate: createState?.startDate ?
         (new Date(createState?.startDate)?.getDate() > 28 ? 1 : ((createState?.initial_bill_cycle_date) ?? 1) )
         : (createState?.initial_bill_cycle_date ?? 1)



            });
        }
        else {
            if (createState?.startDate) {
                setCreateState({
                    ...createState,
                    // custom_date: new Date(createState?.startDate)?.getDate() > 28 ? 1 : createState?.initial_bill_cycle_date,
                    // billingCycleDate: new Date(createState?.startDate)?.getDate() > 28 ? null : createState?.billingCycleDate
                    billingCycleDate: createState?.startDate ? new Date(createState?.startDate)?.getDate() > 28 ? 1 : createState?.initial_bill_cycle_date : createState?.initial_bill_cycle_date

                })

            }
            return false
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        createState?.startDate,
        createState?.duration,
        createState?.paymentPeriod
    ]);

    React.useEffect(() => {
        getEnum()
        if (lead_id?.length > 0) {
            getEditData()
        }
        else {
            getCompanyDetails()
        }
        // eslint-disable-next-line
    }, [lead_id])

    //on change grace peroid value
    const onChangeGracePeroidValue = (value) => {
        let error = createState.error;
        error.grace_peroid = "";
        setCreateState({
            ...createState,
            grace_peroid: value,
            error,
        });
    };

    const onChangeGracePeroid = (value) => {
        let apply_grace_period = value === true ? "In the End" : "In the Beginning";
        setCreateState({
            ...createState,
            apply_grace_peroid: apply_grace_period,
        });
    };

    const onCategoryChange=(v)=>{
        setCreateState({
            ...createState,
            unit_category:v,
            properties:[],
            error:{
                ...createState?.error,
                unit_category:""

            }
        })
    }
    return (
        <Box className={classes.drawerBox}>
            <Box className={classes.header}>
                <Stack direction={"row"} spacing={2} alignItems={"center"} p={2}>
                    <Typography className={classes.drawerCloseBtn} onClick={onClose}>X</Typography>
                    <Typography className={classes.drawerHeading}>{title ? title : lead_id ? t("Update Resident On-Boarding") : t("Create Resident On-Boarding")}</Typography>
                </Stack>
                <Divider></Divider>
            </Box>
            <Box className={classes.body}>
                <Box p={2}>
                    {
                        step === 1 &&
                        <Steps t={t}
                            component={[
                                {
                                    type: "propertyType",
                                    data: is_workspace ? boardingPropertyType?.filter((x) => { return x?.value !== "Residential" }) : boardingPropertyType,
                                    selected: createState?.propertyType,
                                    handleClick: (value) => updateState("propertyType", value),
                                    header: t("Boarding Property Type"),
                                    error: createState?.error?.propertyType?.length > 0,
                                    errorMsg: t(createState?.error?.propertyType),
                                    isActive: true,
                                    isRequired: true
                                },
                                {
                                    type: "chooseComponent",
                                    header: t("Choose the contact"),
                                    title: t("Choose contact"),
                                    subTitle: t("Pick contact from registered contact"),
                                    noSelectedTitle: t("No contacted selected to display"),
                                    icon: <ContactIcon />,
                                    options: [],
                                    topHeader: t("Choose The Contact"),
                                    onchange: (value) => updateState("contact", value),
                                    value: createState?.contact,
                                    onDelete: () => updateState("contact", null),
                                    loadOptions: (search, array, handleLoading) =>
                                        loadOptionsApis(
                                            "queries/opportunity/contact/list",
                                            {
                                              is_drop_down:true
                                            },
                                            search,
                                            array,
                                            handleLoading,
                                            "data",
                                            {},
                                          ),
                                    error: createState?.error?.contact?.length > 0,
                                    errorMsg: t(createState?.error?.contact),
                                    isActive: true,
                                    border: "none"

                                },
                                {
                                    type: "addButton",
                                    text: t("Add Billing Account"),
                                    hidden: createState?.billingAccount,
                                    click: () => updateState("billingAccount", true),
                                    isActive: true,
                                },
                                {
                                    type: "chooseComponent",
                                    header: t("Choose the Billing Account"),
                                    title: t("Choose Billing Account"),
                                    // subTitle: t("Choose a billing account for existing resident"),
                                    noSelectedTitle: t("No account selected to display"),
                                    icon: <BillingContactIcon />,
                                    options: [],
                                    visible: createState?.billingAccount,
                                    handleRemove: () => updateState("billingAccount", false),
                                    onchange: (value) => updateState("billing", value),
                                    value: createState?.billing,
                                    key: JSON.stringify({ contact: createState?.contact }),
                                    onDelete: () => updateState("billing", null),
                                    loadOptions: (search, array, handleLoading, primary) => {

                                        if (primary) {
                                            return loadOptionsApis(
                                                "contact/primary_account",
                                                {
                                                    contact_id: createState?.contact?.value ?? null,
                                                    email_id: createState?.contact?.email_id,
                                                    company_id
                                                },
                                                search,
                                                array,
                                                handleLoading,
                                                "data"
                                            )
                                        }
                                        return loadOptionsApis(
                                            "queries/opportunity/contact_account",
                                            { company_id },
                                            search,
                                            array,
                                            handleLoading,
                                            "data",
                                            {},
                                          )
                                    },
                                    isActive: true,
                                    isRequired: false,
                                    menuPlacement: 'top',
                                    border: "none",
                                    boldHeader: false,
                                    isBorder: false,
                                    showRemove: true,
                                    is_toggle: true,
                                    state1: state1,
                                    setState1: (e) => setState1(e ?? false),
                                    t
                                },
                            ]}
                        />
                    }
                    {
                        step === 2 &&
                        <Steps t={t}
                            component={[
                                {
                                    type: "propertyType",
                                    data: is_workspace?activeOccupents?.filter((x)=>{return x?.title!=="Owner"}):activeOccupents,
                                    selected: createState?.activeOccupents,
                                    handleClick: (value) => updateState("activeOccupents", value),
                                    header: t("Active Occupants"),
                                    error: createState?.error?.activeOccupents?.length > 0,
                                    errorMsg: createState?.error?.activeOccupents,
                                    isActive: true,
                                    isRequired: true
                                },

                                {
                                    type: "multiLineText",
                                    header: t("Subject"),
                                    value: createState?.subject,
                                    handleChange: (value) => updateState("subject", value),
                                    error: createState?.error?.subject?.length > 0,
                                    errorMsg: createState?.error?.subject,
                                    isActive: true,
                                    isRequired: true

                                },
                                {
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    isActive: true,
                                    type: "duration",
                                    label: type === "leaseType" ? t("Lease Duration") : t("Manage Duration"),
                                    value: createState?.duration,
                                    onChange: (value) => updateState("duration", value),
                                    placeholder: type === "leaseType" ? t("Lease Duration") : t("Manage Duration"),
                                    isRequired: true,
                                    options: enumValue?.lease_type?.filter((x) => x?.value !== "Weekly"),
                                    isError: createState?.error?.duration?.length > 0,
                                    errorMessage: createState?.error?.duration,
                                },

                                {
                                    type: "datePicker",
                                    header: t("Start Date"),
                                    value: createState?.startDate,
                                    handleChange: (value) => updateState("startDate", value),
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    isActive: true,
                                    isrequired: true,
                                    isError: createState?.error?.startDate?.length > 0,
                                    errorMessage: createState?.error?.startDate,

                                },
                                {
                                    type: "datePicker",
                                    header: t("End Date"),
                                    value: createState?.endDate,
                                    handleChange: (value) => updateState("endDate", value),
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    isActive: true,
                                    isrequired: true,
                                    isReadonly: true,

                                },
                                {
                                    type: "datePicker",
                                    header: t("Bill Start Date"),
                                    value: createState?.billStartDate,
                                    handleChange: (value) => updateState("billStartDate", value),
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    isActive: true,
                                    isrequired: true,
                                    isReadonly: true

                                },
                                {
                                    type: "selectBox",
                                    header: t("Payment Options"),
                                    value: createState?.paymentOption,
                                    handleChange: (value) => updateState("paymentOption", value),
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    options: enumValue?.payment_mode,
                                    isActive: true,
                                    isrequired: true,
                                    isError: createState?.error?.paymentOption?.length > 0,
                                    errorMessage: createState?.error?.paymentOption,
                                    menuPlacement: "top",
                                    fontFamily: FontFamilySwitch().semiBold,

                                },
                                {
                                    type: "selectBox",
                                    header: t("Payment Period"),
                                    value: createState?.paymentPeriod,
                                    handleChange: (value) => updateState("paymentPeriod", value),
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    options: getEnumValueOppertunity(createState?.duration?.select, createState?.duration?.value, enumValue?.payment_period),
                                    isActive: true,
                                    isrequired: true,
                                    isError: createState?.error?.paymentPeriod?.length > 0,
                                    errorMessage: createState?.error?.paymentPeriod,
                                    menuPlacement: "top",
                                    fontFamily: FontFamilySwitch().semiBold,

                                },
                                {
                                    type: "toggleButton",
                                    header: t("Billing Cycle Method"),
                                    value: createState?.billingCycleMethod,
                                    handleChange: (value) => updateState("billingCycleMethod", value),
                                    option: Billing_cycle_method,
                                    sm: 5,
                                    md: 5,
                                    lg: 5,
                                    isActive: createState?.paymentPeriod?.value === "Prepaid" ? false : true,
                                    isrequired: true,
                                    isError: createState?.error?.billingCycleMethod?.length > 0,
                                    errorMessage: createState?.error?.billingCycleMethod,


                                },
                                {
                                    sm: 7,
                                    md: 7,
                                    lg: 7,
                                    isActive: createState?.paymentPeriod?.value === "Prepaid" ? false : true,
                                    type: "toggle1",
                                    label: t("Billing Cycle Date"),
                                    value: createState?.billingCycleDate,
                                    placeholder: t("Billing Cycle Date"),
                                    onChange: (value) => updateState("billingCycleDate", value),
                                    error: createState?.error?.billingCycleDate,
                                    isRequired: true,
                                    options: Billing_cycle_date ?? [],
                                    isMulti: true,
                                    textBoxIsReadOnly: createState?.startDate ? new Date(createState?.lease_start_date).getDate() > 28 ? true : false : false,
                                    openCustom: false,
                                    customValue: createState?.billingCycleDate === "As per the Agreement Date" ? "" : createState?.billingCycleDate,
                                    custom: createState?.billingCycleDate === "As per the Agreement Date" ? true : false,
                                    is_Oppertunity: true,
                                    customDay: createState?.billingCycleDate === "As per the Agreement Date" ? false : true,
                                    onCustom: (value) => updateState("billingCycleDate", value),
                                    customerror: createState?.error?.billingCycleDate,
                                    selectBillingCycle: true,
                                    t:t


                                },
                                {
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    type: "toggleButton",
                                    header: "Auto Renewal",
                                    value: createState?.auto_renewal,
                                    handleChange: (value) => updateState("auto_renewal", value),
                                    option: yesorno,
                                    isActive: oppertunity_only,
                                    isrequired: true,
                                    isError: createState?.error?.auto_renewal?.length > 0,
                                    errorMessage: createState?.error?.auto_renewal,
                                },
                                {
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    type: "priority",
                                    label: "Priority",
                                    value: createState?.priority,
                                    placeholder: "Priority",
                                    onChange: (value) => updateState("priority", value),
                                    error: createState?.error?.priority,
                                    isRequired: true,
                                    options: enumValue?.priority,
                                    isActive: oppertunity_only,

                                },


                                {


                                    sm: 12,
                                    md: 12,
                                    lg: 12,

                                    type: "grace_period",
                                    label: "Grace Period",
                                    value: createState?.grace_peroid,
                                    onChange: (value) => onChangeGracePeroidValue(value),
                                    error: createState?.error?.grace_peroid,
                                    isRequired: true,
                                    options: quotation_validity,
                                    isMulti: true,
                                    openCustom: validateCustom([null], createState?.grace_peroid),
                                    customValue: 0,
                                    custom: true,
                                    applyGracePeriod: createState?.grace_peroid ? true : false,
                                    applyGracePeriodValue:
                                        createState?.apply_grace_peroid === "In the Beginning" ? false : true,
                                    OnApplyGracePeriod: (value) => onChangeGracePeroid(value),
                                    isActive: oppertunity_only,
                                    // is_Oppertunity: true


                                },

                            ]}
                        />
                    }
                    {
                        step === 3 &&
                        <Steps t={t}
                            component={[
                                {
                                    type: "toggleButton",
                                    header: "",
                                    value: createState?.unit_type,
                                    handleChange: (value) => {
                                        setCreateState({
                                            ...createState,
                                            unit_type: value,
                                            // owner:null,
                                            // properties:[],
                                            // property:[],
                                            // property_against:null
                                        })
                                    },
                                    option: [{ label: t("Property"), value: 1 }, { label: "Owner", value: 2 }],
                                    sm: 12,
                                    md: 12,
                                    lg: 12,
                                    isrequired: true,
                                    isActive: true,
                                    isError: createState?.error?.billingCycleMethod?.length > 0,
                                    errorMessage: createState?.error?.billingCycleMethod,

                                },
                                {
                                    type: "chooseComponent",
                                    header: "",
                                    title: (
                                        <>
                                          {t("Choose Property Owner")} <span style={{ color: 'red' }}>*</span>
                                        </>
                                      ),
                                    placeholder: t("Choose Property Owner"),
                                    subTitle: "",
                                    noSelectedTitle: t("No Property Owner selected to display"),
                                    icon: <PropertyOwnerIcon />,
                                    topHeader: "Choose The Contact",
                                    onchange: (value) => {
                                        setCreateState({
                                            ...createState,
                                            property: [],
                                            owner: value
                                        })
                                    },
                                    value: createState?.owner,
                                    onDelete: () => {
                                        setCreateState({
                                            ...createState,
                                            property: [],
                                            owner: null
                                        })
                                    },
                                    border: "none",
                                    isActive: createState?.unit_type === 2 ? true : false,
                                    loadOptions: (search, array, handleLoading) =>
                                        loadOptionsApis(
                                            "lead_proxy/get_owner",
                                            { company_id: state?.SelectedCompany?.value ?? 1 },
                                            search,
                                            array,
                                            handleLoading,
                                            "data"
                                        ),
                                    error: createState?.error?.owner?.length > 0,
                                    errorMsg: createState?.error?.owner,
                                    isRequired: false,

                                },
                                {
                                    type: "chooseComponent",
                                    header: "",
                                    title: (
                                        <>
                                          {t("Choose Units")} <span style={{ color: 'red' }}>*</span>
                                        </>
                                      ),
                                    placeholder: t("Choose Units"),
                                    subTitle: "",
                                    noSelectedTitle: t("No Units selected to display"),
                                    icon: <UnitIcon />,
                                    topHeader: "Choose The Contact",
                                    onchange: (value) => updateState("property", [...createState?.property, value]),
                                    value: createState?.property,
                                    border: "none",
                                    onDeleteMuti: (index) => {
                                        setCreateState({
                                            ...createState,
                                            property: createState?.property?.filter((x, i) => i !== index),
                                            deleted_property: [...createState?.deleted_property, createState?.property?.filter((x, i) => i === index)?.[0]]
                                        })
                                    },
                                    isActive: ((createState?.owner?.value?.length > 0 || createState?.show_property) && createState?.unit_type === 2) ? true : false,
                                    isMulti: true,
                                    onDeleteMutiCheck: (value) => updateState("property", value),
                                    loadOptions: (search, array, handleLoading) =>
                                        loadOptionsApis(
                                            "opportunity/units",
                                            { lease_start_date: `${moment(createState?.startDate).format("YYYY-MM-DD")} 00:00:00`, lease_end_date: `${moment(createState?.endDate).format("YYYY-MM-DD")} 23:59:59`, purpose: createState?.propertyType?.title ?? "Residential", revenue_type: type === "leaseType" ? "Lease" : "Manage", vacant: ["Vacant"], owners: [createState?.owner?.value] },
                                            search,
                                            array,
                                            handleLoading,
                                            "data",
                                            { unit: "true", label: "label", value: "value" }
                                        ),
                                    key: JSON.stringify(createState?.owner),
                                    error: createState?.error?.property?.length > 0,
                                    errorMsg: createState?.error?.property,
                                    isRequired: false,
                                },
                                {
                                    type: "chooseComponent",
                                    header: "",
                                    title: (
                                        <>
                                          {t("Choose Property")} <span style={{ color: 'red' }}>*</span>
                                        </>
                                      ),
                                    placeholder: t("Choose Property"),
                                    subTitle: "",
                                    noSelectedTitle: t("No Property selected to display"),
                                    icon: <PropertyOwnerIcon />,
                                    topHeader: t("Choose The Property"),
                                    onchange: (value) => {
                                        setCreateState({
                                            ...createState,
                                            properties: [],
                                            property_against: value,
                                            unit_category:null
                                        })
                                    },
                                    value: createState?.property_against,
                                    onDelete: () => {
                                        setCreateState({
                                            ...createState,
                                            properties: [],
                                            property_against: null,
                                            unit_category:null
                                        })
                                    },
                                    isActive: createState?.unit_type === 1 ? true : false,
                                    loadOptions: (search, array, handleLoading) =>
                                        loadOptionsApis(
                                            "opportunity/get_property",
                                            { company_id: state?.SelectedCompany?.value ?? 1 },
                                            search,
                                            array,
                                            handleLoading,
                                            "data"
                                        ),
                                    error: createState?.error?.property_against?.length > 0,
                                    errorMsg: createState?.error?.property_against,
                                    isRequired: false,
                                },
                                {
                                    sm: 12,
                                    md: 12,
                                    lg: 12,
                                    type: "selectBox",
                                    header: t("Unit Category"),
                                    placeholder: t("Choose Unit Category"),
                                    handleChange: (value) => onCategoryChange(value),
                                    value: createState?.unit_category,
                                    isActive:  ((createState?.property_against?.value?.length > 0 || createState?.show_property) && createState?.unit_type === 1) ? true : false,
                                    loadOptions: (search, array, handleLoading) =>
                                        loadOptionsApis(
                                            "queries/unit_category/get",
                                            { type:input_type , purpose:createState?.propertyType?.value },
                                            search,
                                            array,
                                            handleLoading,
                                            "unit_category_master"
                                        ),
                                    error: createState?.error?.unit_category?.length > 0,
                                    errorMsg: createState?.error?.unit_category,
                                    isrequired: true,
                                    isPaginate:true

                                },
                                {
                                    type: "chooseComponent",
                                    header: "",
                                    title: (
                                        <>
                                          {t("Choose Units")} <span style={{ color: 'red' }}>*</span>
                                        </>
                                      ),
                                    placeholder: t("Choose Units"),
                                    subTitle: "",
                                    noSelectedTitle: t("No Units selected to display"),
                                    icon: <UnitIcon />,
                                    topHeader: t("Choose The Contact"),
                                    onchange: (value) => updateState("properties", [...createState?.properties, value]),
                                    value: createState?.properties,
                                    onDeleteMuti: (index) => {
                                        setCreateState({
                                            ...createState,
                                            properties: createState?.properties?.filter((x, i) => i !== index),
                                            deleted_property: [...createState?.deleted_property, createState?.properties?.filter((x, i) => i === index)?.[0]]
                                        })
                                    },
                                    isActive: (createState?.unit_category?.value && createState?.unit_type === 1) ? true : false,
                                    isMulti: true,
                                    onDeleteMutiCheck: (value) => updateState("properties", value),
                                    loadOptions: (search, array, handleLoading) =>
                                        loadOptionsApis(
                                            "opportunity/units",
                                            { unit_category_id:createState?.unit_category?.value ,lease_start_date: `${moment(createState?.startDate).format("YYYY-MM-DD")} 00:00:00`, lease_end_date: `${moment(createState?.endDate).format("YYYY-MM-DD")} 23:59:59`, purpose: createState?.propertyType?.title ?? "Residential", revenue_type: type === "leaseType" ? "Lease" : oppertunity_only ? [] : "Manage", vacant: ["Vacant"], properties: [createState?.property_against?.value] },
                                            search,
                                            array,
                                            handleLoading,
                                            "data",
                                            { unit: "true", label: "label", value: "value" }
                                        ),
                                    key: JSON.stringify(createState?.unit_category),
                                    error: createState?.error?.properties?.length > 0,
                                    errorMsg: createState?.error?.properties,
                                    isRequired: false,
                                }
                            ]} />
                    }
                </Box>
            </Box>
            <Box className={classes.bottom} sx={{ width: "100%" }}>
                <Stack direction={"row"} justifyContent={"space-between"}
                    alignItems={"center"} p={1}>

                    <Button
                        disabled={lead_id?.length > 0 ? step <= 2 : step <= 1}
                        onClick={() => setStep(step - 1)}
                        variant='contained'
                        className={classes.previous}
                        disableElevation
                    >
                        {t("Previous")}
                    </Button>

                    <Button
                        onClick={() => {
                            next({ step, setStep, state, setCreateState, createState, navigate, alert, backdrop, sumbit, company_id, reload, oppertunity_only ,type, t})
                        }}
                        className={classes.next}
                        disabled={disable}
                        variant='contained'>
                        {step === 3 ? `${lead_id ? t("Update") : t("Complete")} ${btnName ?? t("Onboarding")}` : t("Next")}
                    </Button>
                </Stack>
            </Box>
        </Box>
    )
}