import { Box } from "@mui/material";
import React from "react";
import {
  AlertDialog,
  FilterGenerator,
  LoadingSection,
  Subheader,
  UseDebounce,
} from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import {
  accessCheckRender,
  AlertProps,
  getCompanyOption,
  getRoutePermissionNew,
  NetWorkCallMethods,
} from "../../utils";
import { StatusOptionList } from "../../utils/insepectionMaster";
import { initialState } from "../../utils/resourceMaster";
import Resources from "./components/resources";
import ResourceMasterTable from "./components/table";
import { ViewResources } from "./components/viewResources";
import { ResourceMasterStyles } from "./styles";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ResourceMaster = (props) => {
  const defaultFilterState = {
    type: [],
    is_active: [true],
  };
  const { t } = props;
  const classes = ResourceMasterStyles();
  const debounce = UseDebounce();
  const auth = React.useContext(AuthContext);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const [searchText, setSearchText] = React.useState("");
  const [compenyList, setCompanyList] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState({});
  const [drawer, setDrawer] = React.useState(false);
  const [type, setTypes] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [seletedtype, setSelectedType] = React.useState("");
  const [itemList, setItemList] = React.useState({
    list: [],
    count: 0,
  });
  const [form, setForm] = React.useState(false);
  const [filterData, setFilterData] = React.useState(defaultFilterState);
  const [data, setData] = React.useState({ ...initialState });
  const [permissions, setPermissions] = React.useState({});
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState({
    bool: false,
    id: null,
  });

  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");
  const limitParam = searchParams.get("limit");

  // Default values if not set in URL
  const pageFromParams = pageParam ? parseInt(pageParam, 10) : 1;
  const limitFromParams = limitParam ? parseInt(limitParam, 10) : 10;

  const [page, setPage] = React.useState(pageFromParams);
  const [limit, setLimit] = React.useState(limitFromParams);

  const updatePage = (newPage, newLimit = limit) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", newPage.toString());
    searchParams.set("limit", newLimit.toString());

    // Update URL without reloading
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
    // Update state
    setPage(newPage);
    setLimit(newLimit);
  };

  //get list
  const getResourceList = (
    company_id,
    searchText,
    offset,
    limits,
    filterDatas
  ) => {
    setLoading(true);
    const payload = {
      company_id: company_id,
      search: searchText,
      offset: offset,
      limit: limits,
      active: filterDatas?.is_active ?? undefined,
      type:
        filterDatas?.type?.length > 0
          ? filterDatas?.type?.map((val) => val?.value)
          : undefined,
    };
    NetworkCall(
      `${config.api_url}/resources/getAll`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setItemList({
          list: response?.data?.result,
          count: response.data.count,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  //get type
  const getType = () => {
    const payload = {
      offfset: 0,
      limit: 10,
      search: "",
      status: [true],
    };
    NetworkCall(
      `${config.api_url}/queries/resource_type_master/list`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((res) => {
      const result = res?.data?.data?.resource_type_master?.filter((x) => {
        return x?.label !== "Account" && x?.label !== "Generic";
      });
      setTypes(result);
    });
  };

  //initial load
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);

    if (perm) {
      setPermissions(perm);
      if (perm?.read) {
        getCompanyList();
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  const currentOffset = (page - 1) * limit;
  //get company list
  const getCompanyList = () => {
    let company = getCompanyOption(backdrop, auth, alert);
    if (company) {
      setCompanyList(company?.list);
      setSelectedCompany(company?.selected);
      getResourceList(
        company?.selected?.value,
        "",
        currentOffset,
        limitFromParams,
        filterData
      );
      getType();
    }
  };

  //handle change
  const handleCompanyChange = (value) => {
    setSelectedCompany(value);
    getResourceList(value?.value, "", 0, 10, filterData);
  };

  //apply filter
  const onApplyFilter = (data) => {
    getResourceList(selectedCompany?.value, "", 0, 10, data);
    setFilterData(data);
  };
  //handle pagination
  const handlePagination = (newPage) => {
    updatePage(newPage, limit);
  };

  const handleChangeLimit = (newLimit) => {
    updatePage(1, newLimit); // Reset page to 1 when limit changes
  };
  //on search
  const handleSearch = (e) => {
    setSearchText(e);
    debounce(() => searchTableFunction(e), 800);
  };
  //search function
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getResourceList(selectedCompany?.value, e, 0, limit, filterData);
  };
  //onadd
  const onAdd = (data) => {
    setForm(!form);
    setSelectedType(data);
  };
  //on close
  const onClose = () => {
    setForm(!form);
    setData({
      period: "",
      tool: "",
      module: "",
      vehicles: "",
      working_hour: "",
      user: "",
      reporting_to: "",
      rate_hour: "",
      daily_rate: "",
      currency: "",
      name: "",
      account: "",
      isEdit: false,
      id: "",
      assets: [],
      type: "",
      department: "",
      job: "",
      vehicles_no: "",
      profession: "",
      error: {
        period: "",
        tool: "",
        module: "",
        vehicles: "",
        working_hour: "",
        user: "",
        reporting_to: "",
        rate_hour: "",
        daily_rate: "",
        currency: "",
        name: "",
        account: "",
        department: "",
        job: "",
        vehicles_no: "",
        profession: "",
      },
    });
  };
  //onview
  const onView = (val) => {
    setSelected({
      bool: !selected?.bool,
      id: val,
    });
  };
  //onEdit
  const onEdit = (id) => {
    setForm(true);
    setData({ ...data, id: id, isEdit: true });
    onView();
  };
  //on isActive
  const onIsActive = (id, status) => {
    const payload = {
      id: id,
      update: {
        is_active: status,
      },
    };
    NetworkCall(
      `${config.api_url}/queries/resources/update`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((rs) => {
        reload();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Updated"),
        });
      })
      .catch((er) => {
        console.log(er);
      });
  };
  //handleIcon
  const handleIcon = (type, data) => {
    if (type === "view") {
      onView(data);
    }
    if (type === "edit" && permissions?.view) {
      onAdd(data?.id);
    } else if (type === "active") {
      onIsActive(data?.id, !data?.is_active);
    }
  };
  //reload
  const reload = () => {
    getResourceList(
      selectedCompany?.value,
      "",
      currentOffset,
      limitFromParams,
      filterData
    );
  };

  const render = () => {
    return (
      <Box>
        {/*sub header */}
        <Subheader
          hideBackButton={true}
          title={t("Resource Master")}
          select
          options={compenyList}
          value={selectedCompany}
          onchange={(e) => {
            handleCompanyChange(e);
          }}
        />
        {loading ? (
          <LoadingSection top="20vh" message="Fetching ..." />
        ) : (
          <Box className={`${classes.root}`}>
            <ResourceMasterTable
              onFilter={() => setDrawer(true)}
              placeholder={t("Search Resource")}
              options={type}
              searchText={searchText}
              handleSearch={handleSearch}
              handleChangeLimit={handleChangeLimit}
              handlePagination={handlePagination}
              page={page}
              permissions={permissions}
              list={itemList}
              limit={limit}
              onAdd={onAdd}
              handleIcon={handleIcon}
              height={`calc(100vh - 320px)`}
            />

            {drawer && (
              <FilterGenerator
                open={drawer}
                onClose={() => setDrawer(false)}
                defaultState={defaultFilterState}
                components={[
                  {
                    component: "toggleButton",
                    value: filterData?.is_active,
                    state_name: "is_active",
                    label: t("Active"),
                    options: StatusOptionList(t),
                    isMulti: true,
                  },
                  {
                    component: "select",
                    value: filterData?.type,
                    state_name: "type",
                    label: t("Type"),
                    placeholder: t("Type"),
                    options: type,
                    isMulti: true,
                  },
                ]}
                onApply={(value) => onApplyFilter(value)}
              />
            )}

            {/*add form */}
            <AlertDialog
              open={form}
              md={true}
              component={
                <Resources
                  t={t}
                  data={data}
                  setData={setData}
                  value={seletedtype?.label}
                  type={seletedtype}
                  company={selectedCompany}
                  reload={reload}
                  onClose={onClose}
                  setSelectedType={setSelectedType}
                />
              }
              header={`${data?.isEdit ? t("Edit") : t("Add")} ${
                t(seletedtype?.label) ?? ""
              }`}
              onClose={onClose}
            />

            {/*view form */}
            <AlertDialog
              open={selected?.bool}
              medium={true}
              component={
                <ViewResources
                  permissions={permissions}
                  selected={selected?.id}
                  name={selected?.id?.name}
                  onEdit={onEdit}
                  t={t}
                />
              }
              header={selected?.id?.name}
              onClose={onView}
            />
          </Box>
        )}
      </Box>
    );
  };

  return <Box>{accessCheckRender(render, permissions)}</Box>;
};
export default withTranslation("resourceMaster")(ResourceMaster);
