import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const IncrementDecrementStyle = makeStyles((theme)=>({
    countBox:{
        height:32,
        width:40,
        background:"#fff",
        borderRadius:4,
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    },
    btn:{
        fontSize:"1rem",
        fontFamily: FontFamilySwitch().bold,
        '&:hover':{
            background:"transparent"
        }
    },
    count:{
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold
    }
}))