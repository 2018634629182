import { Box, Paper } from "@mui/material";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  FilterGenerator,
  LoadingSection,
  Subheader,
  TableWithPagination,
  UseDebounce,
} from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import {
  accessCheckRender,
  AlertProps,
  enum_types,
  enumSelect,
  getCompanyOption,
  getRoutePermissionNew,
  NetWorkCallMethods,
} from "../../utils";
import SearchFilterSec from "./searchSec";
import { useTableStyles } from "./style";
import { reqHeading, reqType, reqTypePath } from "./table";
import moment from "moment";

const ReqTable = ({ t }) => {
  // style
  const classes = useTableStyles();
  const navigate = useNavigate(); // navigation
  const [tableData, setTableData] = useState([]);
  const [permissions, setPermission] = React.useState({});
  const [totalRows, setTotalRows] = useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [selectedCompany, setSelectedCompany] = React.useState({});
  const [filterData, setFilterData] = React.useState({
    status: null,
    dateRange: {
      startDate: null,
      endDate: null,
    },
    completion_date: null,
  });
  const [companyList, setCompanyList] = React.useState([]);
  const debounce = UseDebounce();
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const auth = React.useContext(AuthContext);
  const [drawer, setDrawer] = useState(false);
  const [loading, setLoading] = React.useState(true);
  const [statusOptionList, setStatusOptionsList] = React.useState([]);

  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");
  const limitParam = searchParams.get("limit");

  // Default values if not set in URL
  const pageFromParams = pageParam ? parseInt(pageParam, 10) : 1;
  const limitFromParams = limitParam ? parseInt(limitParam, 10) : 10;

  const [page, setPage] = React.useState(pageFromParams);
  const [limit, setLimit] = React.useState(limitFromParams);

  const updatePage = (newPage, newLimit = limit) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", newPage.toString());
    searchParams.set("limit", newLimit.toString());

    // Update URL without reloading
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });

    // Update state
    setPage(newPage);
    setLimit(newLimit);
  };

  //handle pagination
  const handlePagination = (newPage) => {
    updatePage(newPage, limit);
  };

  const handleChangeLimit = (newLimit) => {
    updatePage(1, newLimit); // Reset page to 1 when limit changes
  };

  //get tableData
  const getAllAgreementRequestList = (
    company_id,
    offset = 0,
    limit = 10,
    searchText,
    filterData
  ) => {
    const payload = {
      company_id: company_id,
      search: searchText,
      status: filterData?.status?.map((x) => {
        return x?.value;
      }),
      completed_on: filterData?.completion_date
        ? moment(filterData?.completion_date).format("YYYY-MM-DD")
        : null,
      start_date: filterData?.dateRange?.startDate
        ? moment(filterData?.dateRange?.startDate).format("YYYY-MM-DD")
        : null,
      end_date: filterData?.dateRange?.endDate
        ? moment(filterData?.dateRange?.endDate).format("YYYY-MM-DD")
        : null,
      offset: offset,
      limit: limit,
      type: "move-in",
    };
    NetworkCall(
      `${config.api_url}/agreement_inspection/list_request`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        // const result = response?.data?.data?.map((val) => {
        //     return {
        //         ...val,
        //         property_name: `${val?.property?.[0]?.name} ${(val?.property?.length - 1) > 0 ? `(+${val?.property?.length - 1})` : ""} `
        //     }
        // })
        setTableData(response?.data?.data);
        setTotalRows(response?.data?.count);
        setLoading(false);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("data not found"),
        });
        setLoading(false);
      });
  };
  const getEnum = async () => {
    const result = await enumSelect([
      enum_types?.agreement_inspection_status_type,
      enum_types?.agreement_inspection_status_type_new,
    ]);
    const agreementJobStatus =
      result?.agreement_inspection_status_type_new?.filter((x) => {
        return x?.value !== "Completed" && x?.value !== "Yet to assign";
      });
    setStatusOptionsList([
      ...result?.agreement_inspection_status_type,
      ...agreementJobStatus,
    ]);
  };
  const currentOffset = (page - 1) * limit;
  //initial load
  React.useEffect(() => {
    
    let company = getCompanyOption(backdrop, auth, alert);
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (company && perm?.read) {
        setCompanyList(company?.list);
        setSelectedCompany(company?.selected);
        getEnum();
        getAllAgreementRequestList(
          company?.selected?.value,
          currentOffset,
          limit,
          "",
          filterData
        );
      }
    }
    // eslint-disable-next-line
  }, [auth]);
  //on search
  const handleSearch = (e) => {
    setSearchText(e);
    debounce(() => searchTableFunction(e), 800);
  };
  //search function
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getAllAgreementRequestList(selectedCompany?.value, 0, 10, e, filterData);
  };

  //on click row
  const handleIcon = (type, data) => {
    navigate(`${Routes?.moveInInspectionDetails}`, {
      state: {
        company: selectedCompany,
        id: data?.id,
        title: data?.reference_id,
        type: data?.request_type,
        agreement_id: data?.agreement_id,
      },
    });
  };
  //company handle change
  const handleCompanyChange = (value) => {
    setSelectedCompany(value);
    getAllAgreementRequestList(value?.value, 0, 10, "", filterData);
  };
  //To filter data based on status
  const handleFilter = (data) => {
    getAllAgreementRequestList(selectedCompany?.value, 0, limit, "", data);
    setFilterData(data);
  };
  const handleCloseFilter = () => {
    setDrawer(false);
  };
  const render = () => {
    return (
      <Box className={classes.content}>
        <Subheader
          title={t("Onboard and Move-in")}
          hideBackButton={true}
          select
          value={selectedCompany}
          options={companyList}
          onchange={(e) => {
            handleCompanyChange(e);
          }}
        />
        {loading ? (
          <LoadingSection top="20vh" message={"Fetching Details"} />
        ) : (
          <Paper className={classes.root}>
            <SearchFilterSec
              handleSearch={handleSearch}
              searchText={searchText}
              openfilter={() => setDrawer(true)}
              dot={
                filterData?.status?.length > 0 ||
                filterData?.dateRange?.startDate !== null ||
                filterData?.completion_date !== null
              }
            />
            <TableWithPagination
              heading={reqHeading(t)}
              rows={tableData}
              dataType={reqType}
              path={reqTypePath}
              showpagination
              tableType="no-side"
              handleIcon={handleIcon}
              handlePagination={handlePagination}
              handleChangeLimit={handleChangeLimit}
              totalRowsCount={totalRows}
              page={page}
              limit={limit}
              className={classes.reqTable}
              view={true}
              height={`calc(100vh - 300px)`}
              edit={true}
              delete={true}
            />
          </Paper>
        )}
        {/*filter component */}
        {drawer && (
          <FilterGenerator
            open={drawer}
            onClose={() => handleCloseFilter()}
            components={[
              {
                component: "date_range",
                value: filterData?.dateRange,
                state_name: "dateRange",
                label: t("Requested On"),
                toLabel: t("To"),
                startPlaceholder: t("Select Start Date"),
                endPlaceholder: t("Select End Date"),
              },
              {
                component: "select",
                value: filterData?.status,
                options: statusOptionList,
                isMulti: true,
                state_name: "status",
                label: t("Status"),
                placeholder: t("Status"),
              },
              {
                component: "date",
                value: filterData?.completion_date,
                state_name: "completion_date",
                label: t("Completion Date"),
                placeholder: t("Select Completion Date"),
              },
            ]}
            onApply={(value) => handleFilter(value)}
          />
        )}
      </Box>
    );
  };
  return <Box>{accessCheckRender(render, permissions)}</Box>;
};
export default withTranslation("inspection")(ReqTable);
