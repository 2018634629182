import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { DynamicSlider } from "../../components";
import { Routes } from "../../router/routes";
import { FontFamilySwitch } from "../../utils";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "12px",
    backgroundColor: theme.palette.background.secondary,
    height: `calc(100vh - 88px)`,
    overflow: "auto",
  },

  btn: {
    borderRadius: theme.palette.borderRadius,
  },
  btn2: {
    borderRadius: theme.palette.borderRadius,
    color: theme.palette.secondary.main,
  },
  text: {
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },
  subText: {
    fontSize:"0.75rem",
    color: theme.typography.color.secondary,
  },
  bottomTitle: {
    fontSize:"1.125rem",
    fontFamily: FontFamilySwitch().extraBold,
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    marginBottom: "12px",
  },
  contentBottom: {},
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px",
    },
  },
  grid: {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: theme.palette.borderRadius,
    padding: "4px",
  },
  title: {
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },
  subTitle: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary,
  },
  box: {
    borderRadius: theme.palette.borderRadius,
    padding: "8px 12px",
    backgroundColor: "white",
  },
  area: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.tertiary,
  },
  sqft: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },
  color: {
    backgroundColor: "#FFEACC",
    borderRadius: theme.palette.borderRadius,
    color: "#E29336",
    padding: "4px",
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  color2: {
    backgroundColor: "#CFCFFF",
    borderRadius: theme.palette.borderRadius,
    color: "#6A69D2",
    padding: "4px",
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },

  badge1: {
    backgroundColor: "#FFFFFF",
    borderRadius: "0px 4px 4px 0px",
    color: "#4E5A6B",
    padding: "4px",
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  badge2: {
    backgroundColor: "#78B1FE",
    borderRadius: "4px 0px 0px 4px",
    color: "#FFFFFF",
    padding: "4px",
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
}));

export const BlockDetailCard = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const search = useLocation().search;
  const companyID = new URLSearchParams(search).get("companyID");
  var settings = {
    dots: true,
    infinite: true,
    // slidesToShow: 3,
    // slidesToScroll: 1,
    //autoplay: true,
    speed: 2000,
    // autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
  };
  return (
    <div className={classes.root}>
      <Grid
        container
        className={classes.content}
        spacing={1}
        textAlign={"left"}
      >
        <Grid item xs={12}>
          <div style={{ position: "relative" }}>
            <Slider {...settings}>
              {props?.assets.length > 0 ? (
                <>
                  <DynamicSlider data={props.assets} assets={true} />
                </>
              ) : (
                <>
                  <img
                    src="/images/propertytumb.png"
                    alt=""
                    style={{
                      height: "250px",
                      width: "100%",
                    }}
                  />
                </>
              )}
            </Slider>
            <div style={{ position: "absolute", left: "0px", bottom: "10px" }}>
              {props?.data?.block_no && (
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.badge1}
                >
                  {props?.data?.block_no}
                </Box>
              )}
            </div>
            <div style={{ position: "absolute", bottom: "10px", right: "0px" }}>
              {props?.data?.block_purpose && (
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.badge2}
                >
                  {props?.data?.block_purpose}
                </Box>
              )}
            </div>
          </div>
        </Grid>
        {props?.data?.block_no && (
          <Grid item xs={12}>
            <Box display={"flex"}>
              <Box className={classes.color}>{props.data.block_no}</Box>
            </Box>
          </Grid>
        )}
        <Grid item xs={8}>
          <Typography className={classes.text}>{props?.data?.name}</Typography>
        </Grid>
        <Grid item xs={4} textAlign={"right"}>
          <IconButton
            onClick={() =>
              navigate(
                Routes.addBlock +
                "?companyID=" + companyID + "&propertyId=" +
                props?.propertyId +
                "&blockId=" +
                props?.data?.id +
                "&Edit=" +
                true +
                "&topNavBarTitle=" +
                props?.data?.name
              )
            }
          >
            <img src="/images/Outline.svg" alt="outline" />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.subText}>
            {props?.data?.description}
          </Typography>
        </Grid>
        {props?.data?.Latitude && (
          <Grid item xs={12}>
            <Box display={"flex"}>
              <Box>
                <Typography className={classes.subText}>
                  Latitude :
                  <span className={classes.sqft}>{props.data.Latitude}</span>
                </Typography>
              </Box>
              &nbsp;&nbsp;
              <Box>
                <Typography className={classes.subText}>
                  Longitude :
                  <span className={classes.sqft}>{props.data.Longitude}</span>
                </Typography>
              </Box>
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              {" "}
              <Box className={classes.box}>
                <Typography className={classes.area}>LEASABLE AREA</Typography>
                <Typography className={classes.sqft}>
                  {props?.data?.carpet_area}&nbsp;
                  {props?.data?.area_metric}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              {" "}
              <Box className={classes.box}>
                <Typography className={classes.area}>TOTAL AREA</Typography>
                <Typography className={classes.sqft}>
                  {props?.data?.total_area}&nbsp;
                  {props?.data?.area_metric}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              {" "}
              <Box className={classes.box}>
                <Typography className={classes.area}>FLR HIERARCHY</Typography>
                <Typography className={classes.sqft}>
                  {props?.data?.use_floor_hierarchy === true ? "True" : "False"}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.text}>
            {props?.data?.property?.name}
          </Typography>
          <Typography className={classes.subText} marginTop="12px">
            {props?.data?.property?.description}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box display={"flex"}>
            <Box>
              <img src="/images/loc.svg" alt="loc" />
            </Box>
            <Box>
              <Typography className={classes.subTitle}>Address</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>

          <Typography className={classes.subText}>
            {props?.data?.property?.contact?.street_1 && (
              <>{props?.data?.property?.contact?.street_1} ,&nbsp;</>
            )}
            {props?.data?.property?.contact?.street_2 && (
              <>{props?.data?.property?.contact?.street_2} ,&nbsp;</>
            )}
            {props?.data?.property?.contact?.street_3}
            <br />
            {props?.data?.property?.cities_master?.city},&nbsp;
            {props?.data?.property?.cities_master?.state_master?.state_name}
            ,&nbsp;
            {props?.data?.property?.cities_master?.country_master?.country_name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box display={"flex"}>
            <Box>
              <img src="/images/call.svg" alt="loc" />
            </Box>
            <Box>
              <Typography className={classes.subTitle}>CONTACT</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.area}>BUSINESS PHONE</Typography>
          <Typography className={classes.sqft}>
            {props?.data?.property?.business_phone_country_code}&nbsp;
            {props?.data?.property?.business_phone}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.area}>MOBILE PHONE</Typography>
          <Typography className={classes.sqft}>
            {props?.data?.property?.mobile_country_code}&nbsp;
            {props?.data?.property?.mobile_phone}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.area}>WEBSITE</Typography>
          <Typography className={classes.sqft}>
            {props?.data?.property?.website}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.area}>EMAIL Address</Typography>
          <Typography className={classes.sqft}>
            {props?.data?.property?.email}
          </Typography>
        </Grid>
      </Grid>
      <br />
      <br />
    </div>
  );
};
