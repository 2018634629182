import { Box, Button, Divider, Grid, Menu, MenuItem, Stack, Typography } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import FilterIcon from "../../../assets/filterIcon";
import { SearchFilter, TableWithPagination } from "../../../components";
import { ResourceMasterDataType, ResourceMasterHeading, ResourceMasterListPath } from "../../../utils/resourceMaster";
import { ResourceMasterComponentStyles } from "./styles";
const ResourceMasterTable = ({
    handleIcon = () => false,
    list = [],
    handlePagination = () => false,
    handleChangeLimit = () => false,
    page = "",
    limit = "",
    path = [],
    heading = [],
    dataType = [],
    placeholder = "",
    searchText = "",
    handleSearch = () => false,
    onClick = () => false,
    height = "",
    add = false,
    handleAddInspection = () => false,
    onFilter = () => false,
    dot = false,
    options = [],
    onAdd = () => false,
    t,
    permissions = {}
}) => {

    const classes = ResourceMasterComponentStyles()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    //To show Add menu 
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    //toclose dialog
    const handleClose = (value) => {
        setAnchorEl(null);
    };

    return (
        <>
            <Grid container spacing={3} justifyContent="space-between">
                {/* searchbar */}
                <Grid item xs={4}>
                    <SearchFilter
                        handleChange={handleSearch}
                        value={searchText}
                        placeholder={t("searchResources")} 
                        customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" }}}/>
                </Grid>
                {/* filterIcon */}

                <Grid item md={8} className={classes.filterIconBox}>

                 <Box>
                 <Stack
                        direction="row"
                        alignItems={"center"}
                        divider={<Divider className={classes.dividerStyle} orientation="vertical" flexItem sx={{ marginInlineStart: "16px", }} />}
                        justifyContent={"end"}
                    >
                        <span className={classes.filterIcondivFil} onClick={onFilter}>
                            <FilterIcon />
                        </span>
                        <div className={classes.filterIconBoxBtn}>
                            {
                                permissions?.create &&
                                <Button variant="contained"
                                    sx={{ height: "40px" }}
                                    onClick={handleClick}>
                                    {t("addNew")}
                                </Button>
                            }


                        </div>

                    </Stack >
                 </Box>
                </Grid >
            </Grid >
            <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        className={`${classes.menuparent}`}
                    >
                        {options.map((x, index) => {
                            return (
                                <Box className={`${classes.menuparent}`}>
                                    <MenuItem onClick={() => onAdd(x)}>
                                        <Typography className={`${classes.menutext}`}>{t(x?.label)}</Typography>
                                    </MenuItem>
                                    <hr className={index === options?.length - 1 ? `${classes.alt_divider}` : `${classes.divider_menu}`
                                    }
                                    />
                                </Box>
                            )
                        })}
                    </Menu>

            {/* Resourcetable */}
            < TableWithPagination
                heading={ResourceMasterHeading(t)}
                rows={list?.list}
                path={ResourceMasterListPath}
                dataType={ResourceMasterDataType}
                showpagination={true}
                count="2"
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                tableType="no-side"
                onClick={onClick}
                handleIcon={handleIcon}
                view={true}
                handlePagination={handlePagination}
                handleChangeLimit={handleChangeLimit}
                totalRowsCount={list?.count}
                page={page}
                limit={limit}
                height={height}
            />
            {/* Dialog */}
            {/* <Box>
                <DialogBox
                    open={dialog}
                    onClose={closeDialog}
                    header={isEdit === true ? "Edit" : value === "Tools" ? "Create New Tool" : value === "User" ? "Create New User" : value === "Account" ? "Create New Account" : value === "Vehicles" ? "Create New Vehicle" : value === "Generic" ? "Create New Generic" : "Create"}
                    isNormal
                    width={"710px !important"}
                    component={<Resources value={value} isEdit={true} handleSubmit={handleSubmit} editdata={editData} />}
                />
            </Box> */}
            {/* <DialogBox
                open={isOpen}
                onClose={() => setisOpen(false)}
                header={(editData?.resource_type === "User" || editData?.resource_type === "Account") ? editData.resource_id : editData.resource_type === "Tools" ? editData.tool_Name : editData.tool_id}
                isNormal
                width={(editData?.resource_type === "User" || editData?.resource_type === "Account") ? "400px !important" : "515px !important"}
                component={<EditResources data={editData} handleEdit={handleEdit} />}
            /> */}

        </>

    )
}
export default withTranslation("inspection")(ResourceMasterTable)