import React from "react";
import { withNavBars } from "../../HOCs";
import LeaseAgreementsList from "./leaseAgreement";

class LeaseAgreementsListParent extends React.Component {
    render() {
        return <LeaseAgreementsList />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(LeaseAgreementsListParent, props);
