import { BOM } from '../assets/BOM';
import { InvoiceTabIcon } from '../assets/InvoiceTabIcon';
import { SLA } from '../assets/SLA';
import CityProperty from '../assets/cityproperty';
import { DeliveryJobTabIcon } from '../assets/deliveryJobTabIcon';
import { GeneralJobTabIcon } from '../assets/generalJobTabIcon';
import ImagesProperty from '../assets/imagesproperty';
import { ServiceJobTabIcon } from '../assets/serviceJobTabIcon';


export const Requestpath = ["icon","status","request_no", "requestName", "property_name", "unit", "asset","category","sub_category",'contact_name', 'contact_number',"priority","location","build_source","job_count","raisedDate","closed_on"]

export const Requestheading = (t) => [
  { title: "", field: 'icon' },
  { title: t("Status"), field: 'status' },
  { title: t("Request ID"), field: "request_no" },
  { title: t("Description"), field: "requestName" },
  { title: t("Property Name"), field: "property_name" },
  { title: t("Unit Name"), field: "unit" },
  { title: t("Asset Name"), field: "asset" },
  { title: t("Category"), field: 'category' },
  { title: t("Subcategory"), field: 'sub_category' },
  { title: t("Contact Name"), field: 'contact_name' },
  { title: t("Contact Number"), field: 'contact_number' },
  { title: t("Priority"), field: 'priority' },
  { title: t("Location"), field: 'location' },
  { title: t("Source"), field: 'build_source' },
  { title: t("Jobs"), field: 'job_count' },
  { title: t("Raised On"), field: "raisedDate" },
  { title: t("Date Closed"), field: 'closed_on' },
]

export const GenralRequestheading = (t) => [
  { title: "", field: 'icon' },
  { title: t("Status"), field: 'status' },
  { title: t("Request ID"), field: "request_no" },
  { title: t("Description"), field: "requestName" },
  { title: t("Property Name"), field: "property_name" },
  { title: t("Unit Name"), field: "unit" },
  { title: t("Category"), field: 'category' },
  { title: t("Source"), field: 'build_source' },
  { title: t("Raised On"), field: "raisedDate" },
  // { title: "Date Closed", field: 'closed_date'},
  // { title: "Contact Name", field: 'contact_name'},
  // { title: "Contact Number", field: 'contact_number'},
  // { title: t("Status"), field: 'status' },
  // { title: "", field: 'icon' },
]
export const maintanceRequestTabData = (t, value, classes, type) => [
  {
    title: type ? t("Maintenance_Requests") : t("General_Requests"),
    value: 0,
    image: <CityProperty color={value === 0 ? '#5078E1' : '#98A0AC'} />,
    className: value === 0 ? classes.tabtitle1 : classes.tabtitle,
    selectBack: value === 0 ? classes.selectBack1 : classes.selectBack,
  },

]
export const maintanceDynamicTabs = (t, value, classes, type) => {
  return(
  [
  {
    title: type ? t("Requests") : t("Requests"),
    value: 0,
    image: <CityProperty color={value === 0 ? '#5078E1' : '#98A0AC'} />,
    is_active: true
    // className: value === 0 ? classes.tabtitle1 : classes.tabtitle,
    // selectBack: value === 0 ? classes.selectBack1 : classes.selectBack,
  },
  {
    title: t("KPI"),
    value: 6,
    is_active: true,
    image: value === 6?<SLA color='#5078E1' />:<SLA/>,
  },
  {
    title: t("Service Job"),
    value: 1,
    is_active: false,
    image: <ServiceJobTabIcon color={value === 1 ? '#5078E1' : '#98A0AC'} />,
  },
  {
    title: t("General Job"),
    value: 2,
    is_active: false,
    image: <GeneralJobTabIcon color={value === 2 ? '#5078E1' : '#98A0AC'} />,
  },
  {
    title: t("Delivery Job"),
    value: 3,
    is_active: false,
    image: <DeliveryJobTabIcon color={value === 3 ? '#5078E1' : '#98A0AC'} />,
  },
  {
    title: t("Quotation"),
    value: 8,
    is_active: false,
    image: <BOM color={value === 8 ? '#5078E1' : '#98A0AC'} />,
  },
  {
    title: t("Actual BOM"),
    value: 5,
    is_active: false,
    image: <BOM color={value === 5 ? '#5078E1' : '#98A0AC'} />,
  },
  {
    title: t("Invoices"),
    value: 7,
    is_active: false,
    image: <InvoiceTabIcon color={value === 7 ? '#5078E1' : '#98A0AC'} />,
  },
  {
    title: t("Summary"),
    value: 4,
    is_active: false,
    image: <ImagesProperty color={value === 4 ? '#5078E1' : '#98A0AC'} />,
  },

]
)}
