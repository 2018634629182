import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { LocalStorageKeys, NetWorkCallMethods } from "../../utils";

export const listErrorState = [
  {
    tax: [
      { key: "taxGroup" },
      { key: "taxCode" },
      { key: "taxRegistrationNumber" },
    ],
    logo: [{ key: "src" }],
    profileDetails: [
      { key: "companyCode", component: "company_code" },
      { key: "companyName" },
      { key: "companyType" },
      { key: "companyCountry" },
      { key: "status" },
    ],
    address: [
      // { key: 'addressLineOne' },
      // { key: 'addressLineTwo' },
      // { key: 'landmark' },
      // { key: 'area' },
      // { key: 'city' },
      // { key: 'state' },
      // { key: 'country' },
      // { key: 'pincode' },
      { key: "latitude" },
      { key: "longitude" },
    ],
    contactInformation: [
      // { key: 'primaryContact', component: 'mobile_number', },
      // { key: 'title' },
      { key: "primaryMobile", component: "mobile_number" },
      { key: "primaryTelephone", component: "mobile_number" },
      {
        key: "primaryEmailAddress",
        component: "email",
        errorMsg: "Please enter valid emailID",
      },
      { key: "website" },
    ],

    // bankingBasicDetails: [
    //     { key: "bankName" },
    //     { key: "branchName" },
    //     { key: "currency" },
    //     { key: "bankAccountType" },
    //     { key: "accountNumber" },
    //     { key: "bankRoutingType" },
    //     { key: "bankRoutingCode" },
    //     { key: "preferredCashCollectionOffice" },
    //     { key: "chequeName" },
    //     { key: "addressLineOne" },
    //     { key: "city" },
    //     { key: "country" },
    // ],
  },
  {
    initialConfig: [
      { key: "currency" },
      { key: "uom" },
      { key: "leasePeriod" },
      { key: "referAndWin", component: "boolean" },
      { key: "gracePeriod", component: "integer" },
    ],
    pricing: [
      { key: "paymentPeriod" },
      { key: "priceOverride", component: "boolean" },
      { key: "shortTermPricing", component: "boolean" },
      { key: "paymentTerm" },
    ],
    paymentGatewayConfiguration: [
      { key: "paymentGateway" },
      {
        key: "loginName",
        dependency: {
          key: "paymentGateway",
          component: "select",
          value: "Solution Provder",
        },
      },
      {
        key: "loginPassword",
        dependency: {
          key: "paymentGateway",
          component: "select",
          value: "Solution Provder",
        },
      },
      {
        key: "loginToken",
        dependency: {
          key: "paymentGateway",
          component: "select",
          value: "Solution Provder",
        },
      },
      {
        key: "paymentProviderName",
        dependency: {
          key: "paymentGateway",
          component: "select",
          value: "Solution Provder",
        },
      },
      {
        key: "url",
        component: "website",
        dependency: {
          key: "paymentGateway",
          component: "select",
          value: "Solution Provder",
        },
      },
    ],
  },
  {
    companyEstateRegistration: [
      { key: "companyRegistrationType" },
      { key: "companyRegistrationNumber" },
      { key: "realEstateRegulatoryAuthority" },
      { key: "RERARegistrationNumber" },
      { key: "RERABrokerRegistrationNumber" },
      { key: "realEstateAdvertisingPermit", component: "boolean" },
      {
        key: "advertisingPermitNumber",
        dependency: {
          key: "realEstateAdvertisingPermit",
          component: "boolean",
        },
      },
    ],
    tax: [
      { key: "taxGroup" },
      { key: "taxCode" },
      { key: "taxRegistrationNumber" },
    ],
  },
  {
    bankingBasicDetails: [
      { key: "bankName" },
      { key: "branchName" },
      { key: "currency" },
      { key: "bankAccountType" },
      { key: "accountNumber" },
      { key: "bankRoutingType" },
      { key: "bankRoutingCode" },
      { key: "preferredCashCollectionOffice" },
      { key: "chequeName" },
      { key: "addressLineOne" },
      { key: "city" },
      { key: "country" },
    ],
  },
];

export const networkCallback = async (
  givenPayload,
  url,
  handleLoading,
  returnFullResponse = false
) => {
  const payload = {
    ...givenPayload,
  };
  const options = await NetworkCall(
    `${config.api_url}/${url}`,
    NetWorkCallMethods.post,
    payload,
    null,
    true,
    false
  )
    .then((response) => {
      let main = response?.data?.data;
      handleLoading && handleLoading(null);
      return returnFullResponse ? response : main;
    })
    .catch(() => {
      handleLoading && handleLoading(null);
      return null;
    });
  return options;
};
const validate = (value) => {
  if (!!value) return value;
  else return undefined;
};

export const constructPayload = (
  companyProfile,
  companyConfiguration,
  governmentalDetails,
  bankingDetails,
  assets,
  settings = null,
  isEdit = false
) => {
  const client_controls = settings?.client_controls?.map((x) => {
    return x?.details?.map((val) => {
      if (isEdit) {
        return {
          id: val?.id,
          is_active: val?.is_active,
        };
      } else {
        return {
          ...val,
          id: isEdit ? val?.id : undefined,
          app_build_name: undefined,
        };
      }
    });
  });
  const {
    profileDetails = null,
    logo = null,
    address = null,
    contactInformation = null,
    companyEstateRegistration = null,
    bankingBasicDetails = null,
    TermsAndConditionDetails = null,
    tax = null,
  } = companyProfile;

  let payload = {
    tenantId: config.tenantId,
    user_profile_id: localStorage.getItem(LocalStorageKeys?.profileID) ?? null,

    logo: validate(logo?.src),
    company_no: validate(profileDetails?.companyCode),
    name: validate(profileDetails?.companyName),
    is_active: validate(profileDetails?.status?.value),
    country_id: validate(profileDetails?.companyCountry?.value),
    company_type_id: validate(profileDetails?.companyType?.value),
    description: validate(profileDetails?.businessDescription),

    latitude: validate(address?.latitude),
    longitude: validate(address?.longitude),
    door_no: validate(
      address?.doorNumber?.length > 0 ? address?.doorNumber : null
    ),
    street_1: validate(
      address?.addressLineOne?.length > 0 ? address?.addressLineOne : null
    ),
    street_2: validate(
      address?.addressLineTwo?.length > 0 ? address?.addressLineTwo : null
    ),
    landmark: validate(
      address?.landmark?.length > 0 ? address?.landmark : null
    ),
    area: validate(address?.area),
    city: validate(address?.city),
    state: validate(address?.state),
    zipcode: validate(address?.pincode),
    country: validate(address?.country),

    telephone: validate(contactInformation?.primaryTelephone?.mobile),
    telephone_code: validate(contactInformation?.primaryTelephone?.mobile_code),
    mobile_no_country_code: validate(
      contactInformation?.primaryMobile?.mobile_code
    ),
    mobile_no: validate(contactInformation?.primaryMobile?.mobile),
    email_id: validate(contactInformation?.primaryEmailAddress),
    website: validate(contactInformation?.website),
    title: validate(contactInformation?.title),
    alternative_mobile_no_country_code: validate(
      contactInformation?.primaryContact?.mobile_code
    ),
    alternative_mobile_no: validate(contactInformation?.primaryContact?.mobile),
    finance_email: validate(settings?.finance_email?.value),

    currency_id: validate(settings?.default_base_currency?.value?.value),
    uom_id: validate(settings?.default_uom?.value),
    quote_validity_value: validate(settings?.quotation_validity?.value),
    quote_validity_type: "Days",
    lease_period: settings?.lease_period?.value ?? null,
    is_refer_win_share: settings?.refer_win?.is_active ?? false,
    is_promotional_banners: settings?.professoinal_banners?.is_active ?? false,
    grace_period: settings?.grace_peroid?.is_active
      ? settings?.grace_peroid?.value
      : null,

    payment_period: validate(settings?.billing_payment_peroid?.value),
    is_price_overide: settings?.pricing_override?.is_active ?? false,
    is_short_term_pricing: settings?.short_term_retals?.is_active ?? false,
    //"pricing_method": pricing?.pricingMethod?.value,
    payment_terms:
      settings?.billing_payment_terms === "custom"
        ? "User Defined"
        : validate(settings?.billing_payment_terms_value?.value),
    is_storage_offering: settings?.storage_offering?.is_active ?? false,
    billing_cycle: validate(settings?.billing_cycle_method?.value),
    billing_cycle_date: validate(settings?.billing_generate_date?.value),

    reservation_validity_value: settings?.reservation_feature?.is_active
      ? settings?.reservation_feature?.value
      : null,
    reservation_validity_type: "Days",
    auto_reservation_calc: settings?.auto_reservation_cal?.is_active ?? false,
    auto_deposit_calc: settings?.auto_deposite_cal?.is_active ?? false,
    payment_terms_value:
      settings?.billing_payment_terms === "custom"
        ? settings?.billing_payment_terms_value?.value
        : null,

    payment_gateway: settings?.default_payment_gateway?.value,
    login_name:
      settings?.default_payment_gateway?.value === "Solution Provider"
        ? settings?.loginName
        : null,
    login_password:
      settings?.default_payment_gateway?.value === "Solution Provider"
        ? settings?.loginPassword
        : null,
    login_token:
      settings?.default_payment_gateway?.value === "Solution Provider"
        ? settings?.loginToken
        : null,
    payment_provider_name:
      settings?.default_payment_gateway?.value === "Solution Provider"
        ? settings?.paymentProviderName
        : null,
    payment_provider_url:
      settings?.default_payment_gateway?.value === "Solution Provider"
        ? settings?.provider_url
        : null,

    company_registration: validate(
      companyEstateRegistration?.companyRegistrationType?.value
    ),
    company_registration_number: validate(
      companyEstateRegistration?.companyRegistrationNumber
    ),
    rera_type: validate(
      companyEstateRegistration?.realEstateRegulatoryAuthority?.value
    ), //realestatereularAu
    rera_registration_number: validate(
      companyEstateRegistration?.RERARegistrationNumber
    ),
    rera_broker_registration_number: validate(
      companyEstateRegistration?.RERABrokerRegistrationNumber
    ),
    rera_advertising_permit: validate(
      companyEstateRegistration?.realEstateAdvertisingPermit
    ),
    rera_advertising_permit_no: validate(
      companyEstateRegistration?.advertisingPermitNumber
    ),

    tax_code_type: validate(tax?.taxCode?.value),
    tax_registration_number: validate(tax?.taxRegistrationNumber),
    vat_group_id: validate(tax?.taxGroup?.value),
    cr_no: validate(tax?.cr_no),
    // "total_tax_rate": validate(tax?.totalRateTax),

    primary_bank_name: validate(bankingBasicDetails?.bankName),
    branch_name: validate(bankingBasicDetails?.branchName),
    address_1: validate(bankingBasicDetails?.addressLineOne),
    address_2: validate(bankingBasicDetails?.addressLineTwo),
    bank_city_name: validate(bankingBasicDetails?.city),
    bank_country_name: validate(bankingBasicDetails?.country?.value),
    routing_type: validate(bankingBasicDetails?.bankRoutingType?.value),
    bank_routing_code: validate(bankingBasicDetails?.bankRoutingCode),
    account_type: validate(bankingBasicDetails?.bankAccountType),
    primary_account_no: validate(bankingBasicDetails?.accountNumber),
    banking_currency_id: validate(bankingBasicDetails?.currency?.value),
    cash_collection_office: validate(
      bankingBasicDetails?.preferredCashCollectionOffice
    ),
    cheque_name: validate(bankingBasicDetails?.chequeName),
    billing_day:
      settings?.billing_generate_date?.value === "Custom Date"
        ? validate(settings?.bill_custom_date)
        : null,
    assetsData: assets?.uploadedImages
      ?.filter(({ id }) => !id)
      ?.map(({ priority, url }) => ({ priority, url })),
    deleteAssets: assets?.deletedImages?.length ? assets?.deletedImages : [],
    terms_condition:
      TermsAndConditionDetails?.termsAndConditionDescription ?? null,
    timezone: settings?.default_time_zone?.value?.value1 ?? undefined,
    is_discussion_forum: settings?.discussion?.is_active ?? null,
    is_my_neighbours: settings?.neigbours?.is_active ?? null,
    is_my_contact: settings?.contact?.is_active ?? null,
    is_survey: settings?.survey?.is_active ?? null,
    is_walk_in_track: settings?.walkin?.is_active ?? null,
    is_parking_alarm_notify: settings?.parking?.is_active ?? null,
    is_amenity_booking: settings?.booking?.is_active ?? null,
    is_delivery_collection: settings?.deleivery?.is_active ?? null,
    is_polls: settings?.polls?.is_active ?? null,
    is_visitor_worker_pass_approval: Boolean(
      settings?.is_visitor_worker_pass_approval?.is_active
    ),
    is_unit_request_access_approval: Boolean(
      settings?.is_unit_request_access_approval?.is_active
    ),
    proof:
      settings?.proof?.length > 0
        ? settings?.proof
            ?.filter((val) => val?.is_active)
            ?.map((val) => {
              return {
                id: val?.id ?? undefined,
                proof_id: val?.type?.value,
                proof_type: val?.type?.proof_type,
                kyc_type: "Company",
                name: val?.name,
                number: val?.id_no,
                valid_from: val?.valid_from,
                valid_to: val?.valid_to,
                url: val?.file ?? [],
                is_mandatory: val?.mandatry_verify,
                country_id: val?.issuing_country?.value,
                is_active: val?.is_active,
              };
            })
        : [],
    size_type: "company_size",
    long_name: validate(profileDetails?.companyLongName),
    upfront_charges: settings?.upfront_charge?.is_active
      ? settings?.upfront_charge?.itemMaster?.value
      : null,
    company_controls: client_controls.flat() ?? [],
    is_mail_trigger: settings?.is_mail_trigger ?? false,
    renewal: settings?.is_renewal ?? false,
    renewal_period: settings?.renewal_period
      ? Number(settings?.renewal_period)
      : 30,
    allow_extension: settings?.allow_extension ?? false,
    "approval_period":settings?.approval_period ?? 30,
    reservation_item: settings?.reservation_item?.is_active
      ? settings?.reservation_item?.itemMaster?.value
      : null,
    penalty_item: settings?.penalty_item?.is_active
      ? settings?.penalty_item?.itemMaster?.value
      : null,
    acc_pay: settings?.account_pay?.is_active
      ? settings?.account_pay?.acc_pay?.value
      : null,
    tax_receive: settings?.account_pay?.is_active
      ? settings?.account_pay?.tax_receive?.value
      : null,
    accrued_liability: settings?.account_pay?.is_active
      ? settings?.account_pay?.accrued_liability?.value
      : null,
    goods: settings?.account_pay?.is_active
      ? settings?.account_pay?.goods?.value
      : null,
    acc_receive: settings?.account_receive?.is_active
      ? settings?.account_receive?.acc_receive?.value
      : null,
    deposits: settings?.account_receive?.is_active
      ? settings?.account_receive?.deposits?.value
      : null,
    tax_pay: settings?.account_receive?.is_active
      ? settings?.account_receive?.tax_pay?.value
      : null,
    customer_deposit: settings?.account_receive?.is_active
      ? settings?.account_receive?.customer_deposit?.value
      : null,
    inventory: settings?.account_receive?.is_active
      ? settings?.account_receive?.inventory?.value
      : null,
    advance: settings?.account_receive?.is_active
      ? settings?.account_receive?.advance?.value
      : null,
    invest: settings?.account_receive?.is_active
      ? settings?.account_receive?.invest?.value
      : null,
    sale: settings?.account_receive?.is_active
      ? settings?.account_receive?.sale?.value
      : null,
    credits: settings?.account_pay?.is_active
      ? settings?.account_pay?.credits?.value
      : null,
  };

  return payload;
};

export const returnEditSettings = (data) => {
  const client_controls = data?.client_controls;
  let result = {
    finance_email: data?.finance_email ?? "",
    default_base_currency: data?.currency?.id
      ? {
          value: {
            label: data?.currency?.name,
            value: data?.currency?.id,
          },
        }
      : "",
    bill_custom_date: data?.billing_day ?? "",
    default_uom: data?.uom_master
      ? {
          value: data?.uom_master?.id,
          label: data?.uom_master?.name,
          id: data?.uom_master?.id,
        }
      : "",
    quotation_validity: data?.quote_validity_value
      ? {
          value: data?.quote_validity_value,
        }
      : { value: null, label: "None" },
    billing_payment_peroid: data?.payment_period
      ? {
          value: data?.payment_period,
        }
      : "",
    billing_cycle_method: data?.billing_cycle
      ? {
          value: data?.billing_cycle,
        }
      : "",
    billing_generate_date: data?.billing_cycle_date
      ? {
          value: data?.billing_cycle_date,
        }
      : "",
    default_payment_gateway: data?.payment_gateway
      ? {
          value: data?.payment_gateway,
        }
      : { value: null, label: "None" },
    loginName: data?.login_name ?? "",
    loginPassword: data?.login_password ?? "",
    loginToken: data?.login_token ?? "",
    paymentProviderName: data?.payment_provider_name ?? "",
    provider_url: data?.payment_provider_url ?? "",
    reservation_feature: {
      value: data?.reservation_validity_value,
      is_active:
        data?.reservation_validity_value ||
        data?.reservation_validity_value === null
          ? true
          : false,
    },
    auto_deposite_cal: {
      is_active: data?.auto_deposit_calc ? true : false,
    },
    auto_reservation_cal: {
      is_active: data?.auto_reservation_calc ? true : false,
    },
    grace_peroid: {
      is_active:
        data?.grace_period || data?.grace_period === null ? true : false,
      value: data?.grace_period,
    },
    pricing_override: {
      is_active: data?.is_price_overide ? true : false,
    },
    short_term_retals: {
      is_active: data?.is_short_term_pricing ? true : false,
    },
    storage_offering: {
      is_active: data?.is_storage_offering ? true : false,
    },
    index_calculation: {
      is_active: data?.short_term_retals ? true : false,
    },
    refer_win: {
      is_active: data?.is_refer_win_share ? true : false,
    },
    professoinal_banners: {
      is_active: data?.is_promotional_banners ? true : false,
    },
    discussion: {
      is_active: data?.is_discussion_forum,
    },
    neigbours: {
      is_active: data?.is_my_neighbours,
    },
    contact: {
      is_active: data?.is_my_contact,
    },
    survey: {
      is_active: data?.is_survey,
    },
    walkin: {
      is_active: data?.is_walk_in_track,
    },
    parking: {
      is_active: data?.is_parking_alarm_notify,
    },
    booking: {
      is_active: data?.is_amenity_booking,
    },
    deleivery: {
      is_active: data?.is_delivery_collection,
    },
    polls: {
      is_active: data?.is_polls,
    },
    is_visitor_worker_pass_approval: {
      is_active: data?.is_visitor_worker_pass_approval,
    },
    is_unit_request_access_approval: {
      is_active: data?.is_unit_request_access_approval,
    },
    logo: "",
    billing_payment_terms_value:
      data?.payment_terms !== "User Defined"
        ? { value: data?.payment_terms }
        : { value: data?.payment_terms_value },
    billing_payment_terms:
      data?.payment_terms === "User Defined" ? "custom" : "",
    logo_status: "",
    lease_period: {
      value: data?.lease_period,
    },
    default_time_zone: {
      value: { value: data?.timezone ?? "", label: data?.timezone ?? "" },
    },
    proof: data?.know_your_client_has_many?.map((val) => {
      return {
        id: val?.id,
        type: val?.identification_masterByID,
        kyc_type: "Both",
        name: val?.name,
        id_no: val?.number,
        valid_from: new Date(val?.valid_from),
        valid_to: new Date(val?.valid_to),
        file: val?.url,
        mandatry_verify: val?.is_mandatory,
        issuing_country: val?.country_master,
        is_active: val?.is_active,
      };
    }),
    itemType: data?.upfront_charge?.item
      ? {
          label: data?.upfront_charge?.item,
          value: data?.upfront_charge?.item,
        }
      : "",
    itemCategory:
      data?.upfront_charge?.inspection_item_category?.name &&
      data?.upfront_charge?.inspection_item_category?.id
        ? {
            label: data?.upfront_charge?.inspection_item_category?.name,
            value: data?.upfront_charge?.inspection_item_category?.id,
          }
        : "",
    itemMaster:
      data?.upfront_charge?.name && data?.upfront_charge?.id
        ? {
            label: data?.upfront_charge?.name,
            value: data?.upfront_charge?.id,
          }
        : "",
    upfront_charge: {
      itemType: data?.upfront_charge?.item
        ? {
            label: data?.upfront_charge?.item,
            value: data?.upfront_charge?.item,
          }
        : "",
      itemCategory:
        data?.upfront_charge?.inspection_item_category?.name &&
        data?.upfront_charge?.inspection_item_category?.id
          ? {
              label: data?.upfront_charge?.inspection_item_category?.name,
              value: data?.upfront_charge?.inspection_item_category?.id,
            }
          : "",
      itemMaster:
        data?.upfront_charge?.name && data?.upfront_charge?.id
          ? {
              label: data?.upfront_charge?.name,
              value: data?.upfront_charge?.id,
            }
          : "",
      itemSubCategory:
        data?.upfront_charge?.inspection_item_subCategory?.name &&
        data?.upfront_charge?.inspection_item_subCategory?.id
          ? {
              label: data?.upfront_charge?.inspection_item_subCategory?.name,
              value: data?.upfront_charge?.inspection_item_subCategory?.id,
            }
          : "",
      upfront_COA: data?.upfront_charge?.revenue
        ? data?.upfront_charge?.revenue?.name
        : "",
      is_active: data?.upfront_charge ? true : false,
    },
    penalty_item: {
      itemType: data?.penalty?.item
        ? { label: data?.penalty?.item, value: data?.penalty?.item }
        : "",
      itemCategory:
        data?.penalty?.inspection_item_category?.name &&
        data?.penalty?.inspection_item_category?.id
          ? {
              label: data?.penalty?.inspection_item_category?.name,
              value: data?.penalty?.inspection_item_category?.id,
            }
          : "",
      itemMaster:
        data?.penalty?.name && data?.penalty?.id
          ? { label: data?.penalty?.name, value: data?.penalty?.id }
          : "",
      itemSubCategory:
        data?.penalty?.inspection_item_subCategory?.name &&
        data?.penalty?.inspection_item_subCategory?.id
          ? {
              label: data?.penalty?.inspection_item_subCategory?.name,
              value: data?.penalty?.inspection_item_subCategory?.id,
            }
          : "",
      penalty_COA: data?.penalty?.revenue ? data?.penalty?.revenue?.name : "",
      is_active: data?.penalty ? true : false,
    },
    reservation_item: {
      itemType: data?.reserve_item?.item
        ? { label: data?.reserve_item?.item, value: data?.reserve_item?.item }
        : "",
      itemCategory:
        data?.reserve_item?.inspection_item_category?.name &&
        data?.reserve_item?.inspection_item_category?.id
          ? {
              label: data?.reserve_item?.inspection_item_category?.name,
              value: data?.reserve_item?.inspection_item_category?.id,
            }
          : "",
      itemMaster:
        data?.reserve_item?.name && data?.reserve_item?.id
          ? { label: data?.reserve_item?.name, value: data?.reserve_item?.id }
          : "",
      itemSubCategory:
        data?.reserve_item?.inspection_item_subCategory?.name &&
        data?.reserve_item?.inspection_item_subCategory?.id
          ? {
              label: data?.reserve_item?.inspection_item_subCategory?.name,
              value: data?.reserve_item?.inspection_item_subCategory?.id,
            }
          : "",
      reservation_COA: data?.reserve_item?.revenue
        ? data?.reserve_item?.revenue?.name
        : "",
      is_active: data?.reserve_item ? true : false,
    },
    account_pay: {
      is_active:
        data?.company_coa?.pay_acc?.value ||
        data?.company_coa?.receive_tax?.value ||
        data?.company_coa?.liability_accrued?.value
          ? true
          : false,
      acc_pay: data?.company_coa?.pay_acc
        ? {
            label: data?.company_coa?.pay_acc?.label,
            value: data?.company_coa?.pay_acc?.value,
          }
        : "",
      acc_payLedger: data?.company_coa?.pay_acc
        ? data?.company_coa?.pay_acc?.ledger
        : "",
      tax_receive: data?.company_coa?.receive_tax
        ? {
            label: data?.company_coa?.receive_tax?.label,
            value: data?.company_coa?.receive_tax?.value,
          }
        : "",
      tax_receiveLedger: data?.company_coa?.receive_tax
        ? data?.company_coa?.receive_tax?.ledger
        : "",
      accrued_liability: data?.company_coa?.liability_accrued
        ? {
            label: data?.company_coa?.liability_accrued?.label,
            value: data?.company_coa?.liability_accrued?.value,
          }
        : "",
      accrued_ledger: data?.company_coa?.liability_accrued
        ? data?.company_coa?.liability_accrued?.ledger
        : "",
      goods: data?.company_coa?.good
        ? {
            label: data?.company_coa?.good?.label,
            value: data?.company_coa?.good?.value,
          }
        : "",
      goodsLedger: data?.company_coa?.good
        ? data?.company_coa?.good?.ledger
        : "",
      credits: data?.company_coa?.credit
        ? {
            label: data?.company_coa?.credit?.label,
            value: data?.company_coa?.credit?.value,
          }
        : "",
      creditsLedger: data?.company_coa?.credit
        ? data?.company_coa?.credit?.ledger
        : "",
    },
    account_receive: {
      is_active:
        data?.company_coa?.receive_acc?.value ||
        data?.company_coa?.pay_tax?.value ||
        data?.company_coa?.deposit?.value
          ? true
          : false,
      acc_receive: data?.company_coa?.receive_acc
        ? {
            label: data?.company_coa?.receive_acc?.label,
            value: data?.company_coa?.receive_acc?.value,
          }
        : "",
      acc_receiveLedger: data?.company_coa?.receive_acc
        ? data?.company_coa?.receive_acc?.ledger
        : "",
      tax_pay: data?.company_coa?.pay_tax
        ? {
            label: data?.company_coa?.pay_tax?.label,
            value: data?.company_coa?.pay_tax?.value,
          }
        : "",
      tax_payLedger: data?.company_coa?.pay_tax
        ? data?.company_coa?.pay_tax?.ledger
        : "",
      deposits: data?.company_coa?.deposit
        ? {
            label: data?.company_coa?.deposit?.label,
            value: data?.company_coa?.deposit?.value,
          }
        : "",
      depositsLedger: data?.company_coa?.deposit
        ? data?.company_coa?.deposit?.ledger
        : "",
      customer_deposit: data?.company_coa?.customer
        ? {
            label: data?.company_coa?.customer?.label,
            value: data?.company_coa?.customer?.value,
          }
        : "",
      customerLedger: data?.company_coa?.customer
        ? data?.company_coa?.customer?.ledger
        : "",

      inventory: data?.company_coa?.inventor
        ? {
            label: data?.company_coa?.inventor?.label,
            value: data?.company_coa?.inventor?.value,
          }
        : "",
      inventoryLedger: data?.company_coa?.inventor
        ? data?.company_coa?.inventor?.ledger
        : "",

      advance: data?.company_coa?.advances
        ? {
            label: data?.company_coa?.advances?.label,
            value: data?.company_coa?.advances?.value,
          }
        : "",
      advanceLedger: data?.company_coa?.advances
        ? data?.company_coa?.advances?.ledger
        : "",
      invest: data?.company_coa?.investment
        ? {
            label: data?.company_coa?.investment?.label,
            value: data?.company_coa?.investment?.value,
          }
        : "",
      investLedger: data?.company_coa?.investment
        ? data?.company_coa?.investment?.ledger
        : "",
      sale: data?.company_coa?.sales
        ? {
            label: data?.company_coa?.sales?.label,
            value: data?.company_coa?.sales?.value,
          }
        : "",
      saleLedger: data?.company_coa?.sales
        ? data?.company_coa?.sales?.ledger
        : "",
    },
    client_controls: client_controls.flat() ?? [],
    is_mail_trigger: data?.is_mail_trigger ?? false,
    is_renewal: data?.renewal ?? false,
    renewal_period: data?.renewal_period,
    allow_extension: data?.allow_extension ?? false,
    approval_period:data?.approval_period ?? 30,
    error: {
      default_base_currency: "",
      default_uom: "",
      quotation_validity: "",
      billing_payment_peroid: "",
      billing_payment_terms: "",
      billing_cycle_method: "",
      billing_generate_date: "",
      default_payment_gateway: "",
      reservation_feature: "",
      auto_deposite_cal: "",
      auto_reservation_cal: "",
      grace_peroid: "",
      pricing_override: "",
      short_term_retals: "",
      storage_offering: "",
      index_calculation: "",
      refer_win: "",
      professoinal_banners: "",
      logo: "",
      logo_status: "",
      loginName: "",
      loginPassword: "",
      loginToken: "",
      paymentProviderName: "",
      provider_url: "",
      upfront_charge: "",
      penalty_item: "",
      reservation_item: "",
      account_pay: "",
      account_receive: "",
    },
  };
  return result;
};

export const returnValue = (value = "", delimiter = "") =>
  value ? value : delimiter;

export const constructCompanyDetails = (val, totalTax) => {
  const returnAssests = (array) =>
    array?.map(({ id = "", url = "", priority = null }) => ({
      id,
      url,
      priority,
      asset_type: 2,
    }));

  let constructState = {};
  let information = val?.company_address?.[0] ?? null;
  let vat_group = val?.vat_group?.id
    ? {
        label: returnValue(val?.vat_group?.group_name),
        value: returnValue(val?.vat_group?.id),
      }
    : val?.vat_group_master?.id
    ? {
        label: returnValue(val?.vat_group_master?.group_name),
        value: returnValue(val?.vat_group_master?.id),
      }
    : null;
  constructState["companyProfile"] = {
    logo: val?.logo
      ? {
          src: returnValue(val?.logo, null),
        }
      : null,
    profileDetails: {
      companyCode: returnValue(val?.company_no),
      companyName: returnValue(val?.name),
      companyLongName: returnValue(val?.long_name),
      status: {
        label: returnValue(val?.is_active ? "Active" : "Inactive"),
        value: val?.is_active ? true : false,
      },
      companyCountry: val?.company_country
        ? {
            label: returnValue(val?.company_country?.country_name),
            value: returnValue(val?.company_country?.id),
          }
        : null,
      companyType: val?.company_type_master
        ? {
            label: returnValue(val?.company_type_master?.label),
            value: returnValue(val?.company_type_master?.value),
          }
        : null,
      businessDescription: returnValue(val?.description),
    },
    address: {
      latitude: returnValue(information?.latitude, null),
      longitude: returnValue(information?.longitude, null),
      doorNumber: returnValue(information?.door_no),
      addressLineOne: returnValue(information?.street_1),
      addressLineTwo: returnValue(information?.street_2),
      landmark: returnValue(information?.landmark),
      area: returnValue(information?.area),
      city: returnValue(information?.city),
      state: returnValue(information?.state),
      country: returnValue(information?.country),
      pincode: returnValue(information?.zipcode),
    },
    contactInformation: {
      title: returnValue(information?.name),
      primaryContact: {
        mobile: returnValue(information?.alternative_mobile_no, null),
        mobile_code: returnValue(
          information?.alternative_mobile_no_country_code,
          null
        ),
      },
      primaryTelephone: {
        mobile: returnValue(information?.telephone, null),
        mobile_code: returnValue(information?.telephone_code, null),
      },
      primaryMobile: {
        mobile: returnValue(information?.mobile_no, null),
        mobile_code: returnValue(information?.mobile_no_country_code, null),
      },
      primaryEmailAddress: returnValue(information?.email_id),
      website: returnValue(information?.website),
    },
    bankingBasicDetails: {
      bankName: returnValue(val?.primary_bank_name),
      branchName: returnValue(val?.branch_name),
      currency: val?.banking_currency?.id
        ? {
            label: returnValue(val?.banking_currency?.name),
            value: returnValue(val?.banking_currency?.id),
          }
        : null,
      bankAccountType: returnValue(val?.account_type),
      accountNumber: returnValue(val?.primary_account_no),
      bankRoutingType: val?.routing_type
        ? {
            label: returnValue(val?.routing_type),
            value: returnValue(val?.routing_type),
          }
        : null,
      bankRoutingCode: returnValue(val?.bank_routing_code),
      preferredCashCollectionOffice: returnValue(val?.cash_collection_office),
      chequeName: returnValue(val?.cheque_name),
      addressLineOne: returnValue(val?.address_1),
      addressLineTwo: returnValue(val?.address_2),
      city: returnValue(val?.bank_city_name),
      country: val?.country,
    },
    companyEstateRegistration: {
      companyRegistrationType: val?.company_registration
        ? {
            label: returnValue(val?.company_registration),
            value: returnValue(val?.company_registration),
          }
        : null,
      companyRegistrationNumber: returnValue(val?.company_registration_number),
      realEstateRegulatoryAuthority: val?.rera_type
        ? {
            label: returnValue(val?.rera_type),
            value: returnValue(val?.rera_type),
          }
        : null,
      RERARegistrationNumber: returnValue(val?.rera_registration_number),
      RERABrokerRegistrationNumber: returnValue(
        val?.rera_broker_registration_number
      ),
      realEstateAdvertisingPermit: val?.rera_advertising_permit,
      advertisingPermitNumber: returnValue(val?.rera_advertising_permit_no),
    },
    tax: {
      taxGroup: vat_group,

      taxCode: val?.tax_code_type
        ? {
            label: returnValue(val?.tax_code_type),
            value: returnValue(val?.tax_code_type),
          }
        : null,
      taxRegistrationNumber: returnValue(val?.tax_registration_number),
      totalRateTax: returnValue(totalTax),
      cr_no: val?.cr_no,
    },
    TermsAndConditionDetails: {
      termsAndConditionDescription: returnValue(val?.terms_condition),
    },
    proof:
      val?.know_your_client_has_many?.map((item) => ({
        id: item?.id,
        type: item?.identification_masterByID,
        kyc_type: "Both",
        name: item?.name,
        id_no: item?.number,
        valid_from: new Date(item?.valid_from),
        valid_to: new Date(item?.valid_to),
        file: item?.url,
        mandatry_verify: item?.is_mandatory,
        issuing_country: item?.country_master,
        is_active: item?.is_active,
      })) || [],
  };
  constructState["companyConfiguration"] = {
    initialConfig: {
      currency: val?.currency
        ? {
            label: returnValue(val?.currency?.name),
            value: returnValue(val?.currency?.id),
          }
        : null,
      timezone: val?.timezone ? val?.timezone : null,
      uom: val?.uom_master
        ? {
            label: returnValue(val?.uom_master?.label),
            value: returnValue(val?.uom_master?.value),
          }
        : null,
      leasePeriod: val?.lease_period
        ? {
            label: returnValue(val?.lease_period),
            value: returnValue(val?.lease_period),
          }
        : null,
      referAndWin: val?.is_refer_win_share,
      promotionalBanners: val?.is_promotional_banners,
      gracePeriod: val?.grace_period ?? "-",
    },
    pricing: {
      pricingMethod: val?.pricing_method
        ? {
            label: returnValue(val?.pricing_method),
            value: returnValue(val?.pricing_method),
          }
        : null,
      paymentPeriod: val?.payment_period
        ? {
            label: returnValue(val?.payment_period),
            value: returnValue(val?.payment_period),
          }
        : null,
      priceOverride: val?.is_price_overide,
      shortTermPricing: val?.is_short_term_pricing,
      paymentTerm: val?.payment_terms
        ? {
            label: returnValue(val?.payment_terms),
            value: returnValue(val?.payment_terms),
          }
        : null,
      paymentTermValue: val?.payment_terms_value,
      ...val,
    },
    paymentGatewayConfiguration: {
      paymentGateway: val?.payment_gateway
        ? {
            label: returnValue(val?.payment_gateway),
            value: returnValue(val?.payment_gateway),
          }
        : null,
      loginName: returnValue(val?.login_name),
      loginPassword: returnValue(val?.login_password),
      loginToken: returnValue(val?.login_token),
      paymentProviderName: returnValue(val?.payment_provider_name),
      url: returnValue(val?.payment_provider_url),
    },
  };
  constructState["governmentalDetails"] = {
    companyEstateRegistration: {
      companyRegistrationType: val?.company_registration
        ? {
            label: returnValue(val?.company_registration),
            value: returnValue(val?.company_registration),
          }
        : null,
      companyRegistrationNumber: returnValue(val?.company_registration_number),
      realEstateRegulatoryAuthority: val?.rera_type
        ? {
            label: returnValue(val?.rera_type),
            value: returnValue(val?.rera_type),
          }
        : null,
      RERARegistrationNumber: returnValue(val?.rera_registration_number),
      RERABrokerRegistrationNumber: returnValue(
        val?.rera_broker_registration_number
      ),
      realEstateAdvertisingPermit: val?.rera_advertising_permit,
      advertisingPermitNumber: returnValue(val?.rera_advertising_permit_no),
    },
    tax: vat_group,

    taxCode: val?.tax_code_type
      ? {
          label: returnValue(val?.tax_code_type),
          value: returnValue(val?.tax_code_type),
        }
      : null,
    taxRegistrationNumber: returnValue(val?.tax_registration_number),
    totalRateTax: returnValue(totalTax),
    cr_no: val?.cr_no,
  };

  constructState["bankingDetails"] = {
    bankingBasicDetails: {
      bankName: returnValue(val?.primary_bank_name),
      branchName: returnValue(val?.branch_name),
      currency: {
        label: returnValue(val?.banking_currency?.name),
        value: returnValue(val?.banking_currency?.id),
      },
      bankAccountType: returnValue(val?.account_type),
      accountNumber: returnValue(val?.primary_account_no),
      bankRoutingType: {
        label: returnValue(val?.routing_type),
        value: returnValue(val?.routing_type),
      },
      bankRoutingCode: returnValue(val?.bank_routing_code),
      preferredCashCollectionOffice: returnValue(val?.cash_collection_office),
      chequeName: returnValue(val?.cheque_name),
      addressLineOne: returnValue(val?.address_1),
      addressLineTwo: returnValue(val?.address_2),
      city: returnValue(val?.bank_city_name),
      country: val?.country_master,
    },
  };
  constructState["assets"] = {
    // existingImage: val?.company_assets?.length ? returnAssestsID(val?.company_assets) : [],
    uploadedImages:
      val?.company_assets?.length > 0 ? returnAssests(val?.company_assets) : [],
    deletedImages: [],
  };
  constructState["upfront_charges"] = val?.upfront_charges;

  return { ...constructState };
};

export const getDefaultSetting = (data) => {
  let myObject = data?.client_controls;
  let controls = [];
  for (var key in myObject) {
    if (myObject.hasOwnProperty(key)) {
      controls.push({
        header: key,
        details: myObject[key],
      });
    }
  }
  let result = {
    bill_custom_date: data?.billing_day ?? "",
    default_base_currency: data?.currency
      ? { value: data?.currency ?? "" }
      : "",
    default_uom: data?.uom_master
      ? { value: data?.uom_master?.id, label: data?.uom_master?.name ?? "" }
      : "",
    quotation_validity:
      data?.quotation_validity || data?.quotation_validity === null
        ? { value: data?.quotation_validity }
        : "",
    billing_payment_peroid: data?.billing_payment_period
      ? { value: data?.billing_payment_period ?? "" }
      : "",
    billing_payment_terms: data?.billing_payment_terms
      ? { value: data?.billing_payment_terms ?? "" }
      : "",
    billing_cycle_method: data?.billing_cycle_method
      ? { value: data?.billing_cycle_method ?? "" }
      : "",
    billing_generate_date: data?.billing_generation_date
      ? { value: data?.billing_generation_date ?? "" }
      : "",
    default_payment_gateway: { value: null, label: "None" },
    loginName: "",
    loginPassword: "",
    loginToken: "",
    paymentProviderName: "",
    provider_url: "",
    billing_payment_terms_value: data?.billing_payment_terms_value ?? "",
    lease_period: data?.lease_period ? { value: data?.lease_period ?? "" } : "",
    reservation_feature:
      data?.reservation_validity || data?.reservation_validity === null
        ? {
            value: data?.reservation_validity,
            is_active:
              data?.reservation_validity || data?.reservation_validity === null
                ? true
                : false,
          }
        : "",
    auto_deposite_cal: { is_active: data?.auto_deposit_calc },
    auto_reservation_cal: { is_active: data?.auto_reservation_calc },
    grace_peroid:
      data?.grace_period_validity || data?.grace_period_validity === null
        ? {
            value: data?.grace_period_validity,
            is_active:
              data?.grace_period_validity ||
              data?.grace_period_validity === null
                ? true
                : false,
          }
        : "",
    pricing_override: { is_active: data?.is_pricing_override },
    short_term_retals: { is_active: data?.is_short_term },
    storage_offering: { is_active: data?.is_storage_offering },
    refer_win: { is_active: data?.is_refer_win },
    professoinal_banners: { is_active: data?.is_promotional_banners },
    proof: "",
    default_time_zone: { value: data?.timezone ?? "" },
    discussion: {
      is_active: data?.is_discussion_forum,
    },
    neigbours: {
      is_active: data?.is_my_neighbours,
    },
    contact: {
      is_active: data?.is_my_contact,
    },
    survey: {
      is_active: data?.is_survey,
    },
    walkin: {
      is_active: data?.is_walk_in_track,
    },
    parking: {
      is_active: data?.is_parking_alarm_notify,
    },
    booking: {
      is_active: data?.is_amenity_booking,
    },
    deleivery: {
      is_active: data?.is_delivery_collection,
    },
    polls: {
      is_active: data?.is_polls,
    },
    is_visitor_worker_pass_approval: {
      is_active: data?.is_visitor_worker_pass_approval,
    },
    is_unit_request_access_approval: {
      is_active: data?.is_unit_request_access_approval,
    },
    client_controls: controls,
    is_mail_trigger: data?.is_mail_trigger ?? false,
    is_renewal: data?.is_mail_trigger ?? false,
    renewal_period: data?.renewal_period ?? 30,
    allow_extension: data?.allow_extension ?? false,
    approval_period:data?.approval_period ?? 30,
    error: {
      default_base_currency: "",
      default_uom: "",
      quotation_validity: "",
      billing_payment_peroid: "",
      billing_payment_terms: "",
      billing_cycle_method: "",
      billing_generate_date: "",
      default_payment_gateway: "",
      reservation_feature: "",
      auto_deposite_cal: "",
      auto_reservation_cal: "",
      grace_peroid: "",
      pricing_override: "",
      short_term_retals: "",
      storage_offering: "",
      index_calculation: "",
      refer_win: "",
      professoinal_banners: "",
      logo: "",
      logo_status: "",
      loginName: "",
      loginPassword: "",
      loginToken: "",
      paymentProviderName: "",
      provider_url: "",
      lease_period: "",
    },
  };
  return result;
};
