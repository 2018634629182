import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const quotationsStyles = makeStyles((theme) => ({
    table: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        //padding: "16px",
        //height: `calc(100vh - 147px)`,
        overflow: "hidden",

    },
    chartContainer: {
        borderLeft: "1px solid #E4E8EE",
        height: `calc(100vh - 150px)`,
        overflow: "auto"
    },
    title: {
        fontSize:"1rem",
        color: "#4E5A6B",
        fontFamily: FontFamilySwitch().bold,
        marginBottom: "8px"

    },
    img: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "10px",
        height: "40px",
        width: '40px'
    },
    tray: {
        zIndex: 1000,
        borderTop: "1px solid #ddd",
        width: "330px",
        minHeight: "100px",
        maxHeight: "350px",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(2),
    },
    buttonWrapper: {
        position: "relative",
        width: "60%",
    },
    button: {
        width: "100%",
    },
    list: {
        maxHeight: "200px",
        overflowY: "auto",
        width: "100%",
    },
    listItem: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
    },
    fileName: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        maxWidth: "300px"
    },
    disabledFileName: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        maxWidth: "300px",
    },
    loader: {
        marginLeft: theme.spacing(1),
    }
}));