import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import Exporting from "highcharts/modules/exporting";
import { FontFamilySwitch } from "../../../utils";

Exporting(Highcharts);

export const HighChartsSemiCircle = ({
  height = 300,
  data = [],
  graphTitle = "",
  subtitle = "",
  isShowLegend = false,
  isTotal = false,
}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const chart = Highcharts.chart(chartRef.current, {
        colors: [
          "#278ECF",
          "#48D762",
          "#FFCA1F",
          "#FF9416",
          "#D42AE8",
          "#535AD7",
          "#FF402C",
          "#83BFFF",
          "#6ED88F",
          "#FFE366",
        ],
        exporting: {
          enabled: true,
        },
        credits: {
          enabled: false
        },
        chart: {
          type: "pie",
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false,
          events: {
            render: function () {
              if (isTotal) {
                const total = this.series[0].data.reduce(
                  (total, point) => total + point.y,
                  0
                );
                if (this.totalLabel) {
                  this.totalLabel.destroy();
                }

                const label = this.renderer
                  .text(total, 0, 0)
                  .css({
                    fontSize: "30px",
                    fontFamily: FontFamilySwitch().bold,
                    textAlign: "center",
                  })
                  .add();

                const box = label.getBBox();

                label.attr({
                  x: this.plotWidth / 1.9 - box.width / 2,
                  y: this.plotHeight / 1.2 + box.height / 4, // Adjust this line
                });

                this.totalLabel = label;
              }
            },
          },
        },
        title: {
          text: graphTitle,
          align: "left",
          style: {
            color: "#091B29",
            fontSize: "1rem",
            fontFamily: FontFamilySwitch().extraBold,
            marginInlineStart: "8px",
          },
        },
        subtitle: {
          text: subtitle,
        },
        plotOptions: {
          pie: {
            innerSize: isTotal ? "80%" : "0%",
            startAngle: -90,
            endAngle: 90,
            center: ["50%", "75%"],
            size: "100%",
          },
          series: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: isShowLegend,
            },
          },
        },
        tooltip: {
          useHTML: true,
          headerFormat: "<table>",
          pointFormat:
            '<tr><td><span style="color:{point.color};">&#9679;</span> {point.name}: </td>' +
            '<td style="padding:0"><b>{point.y}</b></td></tr>',
          footerFormat: "</table>",
        },
        series: [
          {
            type: "pie",
            name: "",
            innerSize: "50%",
            data: data.map((item) => ({ name: item.name, y: item.count })),
          },
        ],
        pane: {
          size: "100%",
          center: ["50%", "75%"],
          startAngle: -90,
          endAngle: 90,
        },
      });

      const handleFullscreenChange = () => {
        chart.reflow();
      };
      document.addEventListener("fullscreenchange", handleFullscreenChange);

      return () => {
        document.removeEventListener(
          "fullscreenchange",
          handleFullscreenChange
        );
      };
    }
  }, [data, graphTitle, subtitle, isShowLegend, isTotal]);

  return <div ref={chartRef} style={{ width: "100%", height: height }} />;
};
