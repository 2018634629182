import React from "react";

const FinancialCardSVG = ({ fillColor, strokeColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.625rem"
      height="8.875rem"
      viewBox="0 0 202 142"
      fill="none"
      style={{ position: "absolute", zIndex: 0 }}
    >
      <path
        d="M201 11V107.445C201 112.968 196.523 117.445 191 117.445H125.349C121.746 117.445 118.421 119.383 116.646 122.519L109.057 135.926C107.282 139.062 103.958 141 100.355 141H11C5.47715 141 1 136.523 1 131V11C1 5.47716 5.47715 1 11 1H191C196.523 1 201 5.47716 201 11Z"
        fill={fillColor}
        stroke={strokeColor}
      />
    </svg>
  );
};

export default FinancialCardSVG;