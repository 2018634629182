/* eslint-disable react-hooks/exhaustive-deps */
import { Badge, Box, Button, Divider, Grid, IconButton, Stack } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import FilterIMG from '../../assets/filter';
import { FilterGenerator, SearchFilter, Subheader, TableWithPagination, UseDebounce } from '../../components';
import { config } from '../../config';
import { AlertContext, AuthContext, BackdropContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { Routes } from '../../router/routes';
import { accessCheckRender, AlertProps, getCompanyOption, getRoutePermissionNew, LocalStorageKeys, NetWorkCallMethods } from '../../utils';
import { loadOptionsApis } from '../../utils/asyncPaginateLoadOptions';
import { Heading, Path, StatusOptionList, Type } from '../../utils/domesticHelper/domesticHelperUtils';
import { useStyles } from "./style";
import { withTranslation } from 'react-i18next';

const DomesticHelperList = ({ t }) => {
    const defaultFilterState = { status: [true] };

    const classes = useStyles()
    const debounce = UseDebounce()
    const navigate = useNavigate()

    // useContext
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)


    // useState
    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [list, setList] = React.useState({})
    const [searchText, setSearchText] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [filterData, setFilterData] = React.useState(defaultFilterState)
    const [filterDrawer, setFilterDrawer] = React.useState(false)
    const [propertyData, setPropertyData] = React.useState([])
    const [selectedProperty, setSelectedProperty] = React.useState({})
    const [permissions, setPermission] = React.useState({})

    // useEffect to get company list for company switcher when loading the screen
    React.useEffect(() => {
        let company = getCompanyOption(backdrop, auth, alert)
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (company && perm?.read) {
                setCompanyList(company?.list)
                setSelectedCompany(company?.selected)
                let properties =
                    company?.selected?.property?.map((x) => {
                        return {
                            value: x.id,
                            label: x.name
                        }


                    })
                setPropertyData([
                    // allProperties,
                    ...properties
                ]);
                const selectProerty = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedProperty));

                if (selectProerty && selectProerty !== "undefined") {
                    setSelectedProperty(JSON.parse(localStorage.getItem(LocalStorageKeys.selectedProperty)))
                }
                else {
                    localStorage.setItem(LocalStorageKeys.selectedProperty, JSON.stringify(properties?.[0]))
                    setSelectedProperty(properties?.[0])
                }


            }
        }
        // eslint-disable-next-line
    }, [auth])

    // useEffect to get list using selected company and filter data when loading the screen
    React.useEffect(() => {
        setPage(1)
        if (selectedProperty?.value) { getList() }
    }, [selectedProperty, filterData])

    // Function to get list based on the input data
    const getList = (offset = 0, limit = 10, search = "") => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." })
        const payload = {
            profession: filterData?.profession?.value,
            client: localStorage.getItem(LocalStorageKeys.clinetID) ?? "",
            status: (!filterData?.status || filterData?.status?.length === 0) ?
                [true, false] : filterData?.status,
            company_id: selectedCompany?.value,
            property_id: selectedProperty?.label === "All Properties" ? selectedProperty?.value : [selectedProperty?.value],
            search,
            offset,
            limit,
        }
        NetworkCall(
            `${config.api_url}/queries/domestic_helper_master/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((r) => {
            setList({
                data: r?.data?.data?.domestic_helper_master,
                totalRowsCount: r?.data?.count
            })
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
        }).catch((e) => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("SomeThingWentWrong")
            })
        })
    }

    // Function to show logo or initial
    const returnValue = (value = "", delimiter = "") => value ? value : delimiter

    // Set row data for table
    const Rows = React.useCallback(list?.data?.map((_) => {
        let j
        try {
            j = {
                id: _?.id,
                imageUrl: {
                    logo: returnValue(_?.helper_image, "-"),
                    name: _?.name ? returnValue(_?.name?.charAt(0)) : ""
                },
                name: _?.name ?? "-",
                gender: _?.gender ?? "-",
                mobileNo: _?.domestic_helper_addressByID?.[0]?.mobile_no ?
                    ((_?.domestic_helper_addressByID?.[0]?.mobile_code &&
                        (_?.domestic_helper_addressByID?.[0]?.mobile_code + " ")) +
                        _?.domestic_helper_addressByID?.[0]?.mobile_no) : "-",
                profession: _?.profession_master?.name,
                idType: _?.identification_masterByID?.type ?? "-",
                idNumber: _?.identification_no ?? "-",
                status: _?.is_active ? "Active" : "Inactive",
                barred: _?.is_barred ? "Yes" : "No",
                data: _
            }
        } catch (err) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("SomeThingWentWrong")
            })
        }
        return j
    }), [list])

    // Function to change the company
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        let properties =
            value?.property?.map((x) => {
                return {
                    value: x.id,
                    label: x.name
                }


            })
        setPropertyData([
            // allProperties,
            ...properties
        ]);
        setSelectedProperty(properties?.[0])
    }

    // Function for search in search component
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    // Function to search data in list
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }
        getList(0, limit, e)
    }

    // Function to open add form
    const handleAdd = () => {
        navigate(Routes.domesticHelperCreateEdit, {
            state: {
                main: {
                    company: selectedCompany,
                    companyID: selectedCompany?.value,
                    selectedProperty: selectedProperty

                }
            }
        })
    }

    // Function to handle icon in table row
    const handleTableIcon = (type, data) => {
        if (type === "view") { navigate(Routes.domesticHelperView + "?domesticHelperID=" + data?.id) }
        else if (type === "edit") {
            navigate(Routes.domesticHelperCreateEdit, {
                state: {
                    main: {
                        company: selectedCompany,
                        companyID: selectedCompany?.value,
                        domesticHelperID: data?.id,
                        selectedProperty: selectedProperty
                    }
                }
            })
        }
        else if (type === "active" || type === "delete") { handleStatusDelete(type, data) }
    }

    // Function to handle pagination in table
    const handleTablePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getList(offset, limit, searchText)
    }

    // Function to handle page limit in table
    const handleTablePageLimit = (value) => {
        setLimit(value)
        setPage(1)
        getList(0, value, searchText)
    }

    // Function to toggle status and delete domestic helper
    const handleStatusDelete = async (type, data) => {
        const currentDate = new Date().toISOString()
        const profileID = localStorage.getItem(LocalStorageKeys.profileID)

        const payload = {
            is_active: type === "active" ? !data?.data?.is_active : undefined,
            is_delete: type === "delete" ? true : undefined,
            updated_at: currentDate ?? undefined,
            updated_by: profileID ?? undefined
        }
        const variables = {
            id: data?.id,
            update: payload
        }
        NetworkCall(
            `${config.api_url}/queries/domestic_helper_master/update`,
            NetWorkCallMethods.post,
            variables,
            null,
            true,
            false
        ).then((r) => {
            getList()
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.success,
                msg: data?.id ?
                    (type === "delete"
                        ? t("Registered Workers Deleted Successfully.!!!")
                        : (type === "active"
                            ? (!data?.data?.is_active
                                ? `${data?.name}${t(" Is Active Now!!!")}`
                                : `${data?.name}${t(" Is In-Active Now!!!")}`)
                            : t("Registered Workers Updated Successfully.!!!")))
                    : t("Registered Workers Created Successfully.!!!"),
            })
        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("SomeThingWentWrong")
            })
        })
    }
    //property change function
    const onPropertyChange = (val) => {
        setSelectedProperty(val)
        localStorage.setItem(LocalStorageKeys.selectedProperty, JSON.stringify(val))

    }
    const manualProfessionResponse = (array) => {
        return array?.profession_master
    }

    const render = () => {
        return (
            <div >
                <Subheader hideBackButton={true} title={t("RegisteredWorkers")}
                    select options={companyList} value={selectedCompany} onchange={(e) => handleCompanyChange(e)}
                    onPropertyChange={(e) => {
                        onPropertyChange(e)
                    }}
                    propertyValue={selectedProperty}
                    selectProperty
                    propertyOptions={propertyData}
                />
                <div className={classes.root}>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <SearchFilter value={searchText} placeholder={t("SearchDomesticHelper")}
                                handleChange={(value) => handleSearch(value)}
                                customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <Box display={"flex"} justifyContent={"end"}>
                                <Stack direction="row" spacing={2}
                                    divider={<Divider orientation="vertical" flexItem />}>
                                    <IconButton onClick={() => setFilterDrawer(!filterDrawer)}
                                        className={classes.filterButton}>
                                        <Badge variant="dot" color="primary"
                                            invisible={!(filterData.status?.length > 0)}>
                                            <FilterIMG color="#091b29" />
                                        </Badge>
                                    </IconButton>
                                    <Button variant="contained" className={classes.button}
                                        onClick={handleAdd}>
                                        {t("AddRegisteredWorkers")}
                                    </Button>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <TableWithPagination
                                heading={Heading(t)}
                                rows={Rows}
                                path={Path}
                                showpagination={true}
                                showpdfbtn={false}
                                showexcelbtn={false}
                                showSearch={false}
                                handleIcon={handleTableIcon}
                                onClick={() => console.log("")}
                                tableType="no-side"
                                dataType={Type}
                                handlePagination={handleTablePagination}
                                handleChangeLimit={handleTablePageLimit}
                                totalRowsCount={list?.totalRowsCount}
                                page={page}
                                limit={limit}
                                height={'calc(100vh - 290px)'}
                                view={permissions?.read}
                                edit={permissions?.update}
                                delete={permissions?.delete} />
                        </Grid>
                    </Grid>
                    <FilterGenerator open={filterDrawer} onClose={() => setFilterDrawer(false)}
                        onApply={(value) => setFilterData(value)}
                        defaultState={defaultFilterState}
                        components={[
                            {
                                component: "toggleButton",
                                value: filterData?.status,
                                options: StatusOptionList(t),
                                isMulti: true,
                                state_name: "status",
                                label: t("Status")
                            },
                            {
                                component: "select",
                                value: filterData?.profession,
                                options: [],
                                isMulti: false,
                                label: t("Profession"),
                                state_name: "profession",
                                placeholder: t("Select Profession"),
                                loadOptions: (search, array, handleLoading) =>
                                    loadOptionsApis(
                                        "queries/profession_master/list",
                                        {
                                            company_id: selectedCompany?.value
                                        },
                                        search,
                                        array,
                                        handleLoading,
                                        "data",
                                        {},
                                        manualProfessionResponse
                                    ),
                                debounceTimeout: 800,
                                isPaginate: true,
                            }
                        ]} />
                </div>
            </div>
        )
    }
    return (
        <Box>
            {accessCheckRender(render, permissions)}
        </Box>
    )
}

export default withTranslation("domesticHelper")(DomesticHelperList)
