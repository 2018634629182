import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box, Menu, MenuItem, Stack, Typography } from "@mui/material";
import React from "react";
import CalendarSmallIcon from "../../../assets/propertyBoardCalendar";
import { FontFamilySwitch } from "../../../utils";
import { StepperStyles } from "./style";

export const DateRangePickerComponent = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t = () => false } = props;
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const classes = StepperStyles()
    const onCalendarPickerChange = (value) => {
        props?.onChangeCalendar(value)
        setAnchorEl(null)
    }
    return (
        <Box>
            <Box
                style={{
                    backgroundColor: "#FFFFFF",
                    padding: "8px",
                    borderRadius: "4px",
                    alignItems: "center",
                    display: "flex",
                    zIndex: 999,
                    cursor: "pointer",
                    border: "1px solid #E4E8EE",
                    width: "150px",
                    justifyContent: "space-between"

                }}
                onClick={handleClick}>
                <Stack direction="row" alignItems={"center"}>
                    <CalendarSmallIcon />
                    <Typography style={{
                        marginTop: "2px",
                        fontFamily: FontFamilySwitch().semiBold,
                        fontSize:"0.75rem",
                        color: "#333333",
                        marginInlineStart: "8px"

                    }}>
                        {t(props?.calanderValue?.label)}
                        {/* {(props?.calanderValue?.value?.from_date ? moment(props?.calanderValue?.value?.from_date).format("DD MMM YYYY"): "YYYY-MM-DD") + " to " +
                        (props?.calanderValue?.value?.to_date ? moment(props?.calanderValue?.value?.to_date).format("DD MMM YYYY"): "YYYY-MM-DD")} */}
                    </Typography>
                </Stack>
                {
                    open ? <KeyboardArrowUp /> :
                        <KeyboardArrowDown />
                }


            </Box >
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                className={classes.menuList}
            >
                {props?.options?.map((val, i) => {
                    return (
                        <MenuItem onClick={() => onCalendarPickerChange(val)} className={classes.menuItemlist}>

                            <Box>
                                <Typography className={classes.dateOptionLabel}>{t(val?.label)}</Typography>
                                {props?.options?.length - 1 !== i &&
                                    <Box className={classes.applyblockborder} />
                                }
                            </Box>
                        </MenuItem>
                    )

                })}
            </Menu>
        </Box>
    )
}