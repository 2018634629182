import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  CircularProgress,
  List,
  Tooltip,
  Popover,
  ListItem,
  Link,
  Drawer,
  Typography,
  Badge,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DialogBox,
  DrawerComponent,
  FilterGenerator,
  FormGenerator,
  SearchFilter,
  Subheader,
  TableWithPagination,
  UseDebounce,
} from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import {
  accessCheckRender,
  enumSelect,
  enum_types,
  getCompanyOption,
  getRoutePermissionNew,
  LeadNewheading,
  LeadNewpath,
  LocalStorageKeys,
  NetWorkCallMethods,
  AlertProps,
  concat_string,
  getCalendarOptions,
  wrapLabelsInT,
} from "../../utils";
import { AddLead } from "./createlead";
import { LeadStylesParent } from "./style";
import { NewLoader } from "../../components/newLoader";
import { withTranslation } from "react-i18next";
import moment from "moment";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { QuickViewDrawer } from "./components/quickViewDrawer";
import { INITIAL_QUICK_VIEW_STATE } from "./utils/quickViewUtils";
import { CloseIconSvg } from "./assets/closeIconSvg";
import { DownloadImage } from "../../assets";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfiniteScroll from "react-infinite-scroll-component";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { LeadComments } from "../../components/viewComments/leadComments";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { subDays } from "date-fns";

const LeadNew = (props) => {
  const { t } = props;
  const size = useWindowDimensions();

  const classes = LeadStylesParent({ size });
  const navigate = useNavigate();
  const auth = React.useContext(AuthContext);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const [list, setList] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const [companyList, setCompanyList] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState({});
  const [selectedTeams, setSelectedTeams] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [is_table_loading, set_is_table_loading] = React.useState(false);
  const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId);
  const [open, setOpen] = React.useState({
    open: false,
    data: null,
  });
  const [data1, setData1] = React.useState("");

  const debounce = UseDebounce();

  const [isEdit, setEdit] = React.useState({
    id: null,
    bool: false,
  });
  const [filterData, setFilterData] = React.useState({
    status: "In Progress",
    lead_source: [],
    lead_owner: [],
    revenue_type: [],
    purpose: [],
    urgent_type: [],
    date: "",
    active: true,
    custom_date_value: "",
    campaign_source: [],
  });
  const clientId = localStorage.getItem(LocalStorageKeys.clinetID);
  const ProfileID = localStorage.getItem(LocalStorageKeys.profileID);
  const [downloadedFiles, setDownloadedFiles] = useState([]);
  const [pendingFiles, setPendingFiles] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [scrollOffset, setScrollOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isReportLoading, setIsReportLoading] = React.useState(true);
  const [drawer, setDrawer] = React.useState(false);
  const [commentsDrawer, setCommentsDrawer] = React.useState(false);
  const [dialog, setDialog] = React.useState(false);
  const [enumValue, setEnumValue] = React.useState({
    source: [],
    revenue_type: [],
    purpose_global_type: [],
    urgent_type: [],
  });
  const [permission, setPermission] = React.useState({});
  const [is_add_form_open, set_is_add_form_open] = useState(false);
  const [quick_view_state, set_quick_view_state] = useState(
    INITIAL_QUICK_VIEW_STATE
  );

  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");
  const limitParam = searchParams.get("limit");

  // Default values if not set in URL
  const pageFromParams = pageParam ? parseInt(pageParam, 10) : 1;
  const limitFromParams = limitParam ? parseInt(limitParam, 10) : 10;

  const [page, setPage] = React.useState(pageFromParams);
  const [limit, setLimit] = React.useState(limitFromParams);
  const openAnchor = Boolean(anchorEl);
  const accessData = auth?.auth?.auth?.access;
  const moduleName = Object.keys(accessData || {})[0];
  const structureArray = accessData?.[moduleName]?.role?.permissions?.[0]?.structure || [];
  const updatePage = (newPage, newLimit = limit) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", newPage.toString());
    searchParams.set("limit", newLimit.toString());

    // Update URL without reloading
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });

    // Calculate offset
    const newOffset = (newPage - 1) * newLimit;

    // Update state
    setPage(newPage);
    setLimit(newLimit);

    // Fetch the updated data
    GetList(
      selectedCompany,
      selectedTeams,
      searchText,
      newOffset,
      newLimit,
      filterData?.lead_source?.map((x) => x?.value) ?? [],
      filterData?.status ?? [],
      filterData?.lead_owner?.map((x) => x?.value) ?? [],
      filterData?.purpose?.map((x) => x?.value) ?? [],
      filterData?.type?.map((x) => x?.value) ?? [],
      filterData?.date?.value?.from_date,
      filterData?.date?.value?.to_date,
      filterData?.active,
      filterData?.urgent_type,
      filterData?.external_lead_source?.map((x) => x?.value) ?? [],
      filterData?.campaign_source?.map((x) => x?.value) ?? []
    );
  };

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          setCompanyList(company?.list);
          setSelectedCompany(company?.selected);
          if (company?.list?.length > 0) {
            // getDetails(company?.selected);
            getEnum();
          }
        }
      }
    }

    const searchParams = new URLSearchParams(window.location.search);
    const pageParam = searchParams.get("page");
    const limitParam = searchParams.get("limit");

    const currentPage = pageParam ? parseInt(pageParam, 10) : 1;
    const currentLimit = limitParam ? parseInt(limitParam, 10) : 10;
    const currentOffset = (currentPage - 1) * currentLimit;

    setPage(currentPage);
    setLimit(currentLimit);
    // eslint-disable-next-line
  }, [auth, location.search]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (pendingFiles.length > 0 && openAnchor) {
        // get ids of pending files
        const pendingFileIds = Array.isArray(pendingFiles)
          ? pendingFiles
              .map((file) => file?.id)
              .filter((id) => id !== undefined)
          : [];

        performNetworkCall(
          `${config.api_url}/360_reports/get_report_status`,
          NetWorkCallMethods.post,
          { report_ids: pendingFileIds },
          (response) => {
            // filter undefined data in the array and remove them
            const filteredPendingFiles = pendingFiles.filter(
              (file) => file !== undefined
            );

            // get response files with is_completed = true or is_failed = true
            const updatedFiles = response.data.data;
            const completedOrFailedFiles = updatedFiles.filter(
              (file) => file.is_completed || file.is_failed
            );

            // remove completed files from pending files
            const newPendingFiles = filteredPendingFiles.filter(
              (file) =>
                !completedOrFailedFiles.some(
                  (completedOrFailedFile) =>
                    completedOrFailedFile.id === file.id
                )
            );

            setPendingFiles(newPendingFiles);

            // update downloaded files with the new data
            const newDownloadedFiles = downloadedFiles.map((file) => {
              const updatedFile = updatedFiles.find(
                (updatedFile) => updatedFile.id === file.id
              );
              if (updatedFile) {
                return updatedFile;
              }
              return file;
            });

            setDownloadedFiles(newDownloadedFiles);
          }
        );
      }
    }, 5000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingFiles, openAnchor]);

  const performNetworkCall = async (url, method, payload, onSuccess) => {
    try {
      const response = await NetworkCall(
        url,
        method,
        payload,
        null,
        true,
        false
      );
      if (response.status === 200) {
        onSuccess(response);
      } else {
        console.error("Failed to store report details");
      }
    } catch (error) {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Some Thing Went Wrong"),
      });
    }
  };

  const fetchMoreReport = () => {
    setIsLoadingMore(true);
    const newScrollOffset = scrollOffset + 5;
    setScrollOffset(newScrollOffset);
    performNetworkCall(
      `${config.api_url}/360_reports/get_reports`,
      NetWorkCallMethods.post,
      { report_type: "lead", offset: newScrollOffset, limit: 5 },
      (response) => {
        const getData = response.data.data;
        setDownloadedFiles([...downloadedFiles, ...getData]);
        // get files with is_completed = false
        const queuedReports = [...downloadedFiles, ...getData].map((file) => {
          if (!file.is_completed) {
            return file;
          }
        });
        setPendingFiles(queuedReports);
        console.log("Report details stored successfully");
        setIsReportLoading(false);
        setIsLoading(false);
        setIsDownloadLoading(false);
        setIsLoadingMore(false);
      }
    );
  };
  const handleDownload = async (event) => {
    setAnchorEl(event.currentTarget);
    setIsReportLoading(true);
    const newScrollOffset = 0;
    setScrollOffset(newScrollOffset);
    performNetworkCall(
      `${config.api_url}/360_reports/get_reports`,
      NetWorkCallMethods.post,
      { report_type: "lead", offset: newScrollOffset, limit: 5 },
      (response) => {
        const getData = response.data.data;
        setDownloadedFiles(getData);
        const queuedReports = getData.map((file) => {
          if (!file.is_completed) {
            return file;
          }
        });
        setPendingFiles(queuedReports);
        console.log("Report details stored successfully");
        setIsReportLoading(false);
        setIsLoading(false);
        setIsDownloadLoading(false);
      }
    );
  };

  const generateRandomAlphanumeric = (length) => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };
  function truncateFileName(fileName) {
    const maxLength = 15;
    if (fileName.length <= maxLength * 2) {
      return fileName;
    }
    const start = fileName.slice(0, maxLength);
    const end = fileName.slice(-maxLength);
    return `${start}...${end}`;
  }

  const handleDownloadReport = async () => {
    setIsLoading(true);
    setIsDownloadLoading(true);
    let fileName = "";
    const date = new Date();
    const yy = String(date.getFullYear());
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    const hh = String(date.getHours()).padStart(2, "0");
    const min = String(date.getMinutes()).padStart(2, "0");
    const randomAlphanumeric = generateRandomAlphanumeric(6);
    fileName = `lead_report_${yy}_${mm}_${dd}_${hh}_${min}_${randomAlphanumeric}.xlsx`;
    performNetworkCall(
      `${config.api_url}/360_reports/report_downloads`,
      NetWorkCallMethods.post,
      {
        file_name: fileName,
        report_type: "lead",
        is_completed: false,
        file_url: null,
        company_id: selectedCompany?.value,
        client_id: clientId,
        status: filterData?.status,
        urgent: filterData?.urgent_type,
        is_active: filterData?.active,
        leadSource: filterData?.lead_source?.map((x) => x?.value),
        leadOwner: filterData?.lead_owner?.map((x) => x?.value) ?? [],
        purpose: filterData?.purpose?.map((x) => x?.value) ?? [],
        campaign_source:
          filterData?.campaign_source?.map((x) => x?.value) ?? [],
        type: filterData?.type?.map((x) => x?.value) ?? [],
        startDate: filterData?.date?.value?.from_date,
        endDate: filterData?.date?.value?.to_date,
        team: selectedTeams?.value === "noteams" ? null : selectedTeams?.value,
      },
      async () => {
        console.log("Report details stored successfully");
        const newScrollOffset = 0;
        setScrollOffset(newScrollOffset);
        performNetworkCall(
          `${config.api_url}/360_reports/get_reports`,
          NetWorkCallMethods.post,
          { report_type: "lead", offset: newScrollOffset, limit: 5 },
          (response) => {
            const getData = response.data.data;
            setDownloadedFiles(getData);
            const queuedReports = getData.map((file) => {
              if (!file.is_completed) {
                return file;
              }
            });
            setPendingFiles(queuedReports);
            console.log("Report details stored successfully");
            setIsLoading(false);
            setIsDownloadLoading(false);
          }
        );
      }
    );
  };
  const handleOpenFile = (fileName) => {
    const fileUrl = fileName;
    window.open(fileUrl, "_blank");
  };
  const handleClose = () => {
    setAnchorEl(null);
    setDownloadedFiles([]);
  };
  const id = openAnchor ? "simple-popover" : undefined;

  const Status = {
    "In Progress": "Open",
    Converted: "Qualify",
    "Lost Lead": "Disqualify",
    Archieved: "Archieved",
    Unqualified: "Unqualified",
  };
  const GetList = (
    company,
    team,
    searchText,
    offSet,
    limit,
    source,
    status,
    lead_owner,
    purpose,
    type,
    startDate,
    endDate,
    active,
    urgent_type,
    external_lead_source,
    campaign_source
  ) => {
    set_is_table_loading(true);
    let clientID = localStorage.getItem(LocalStorageKeys.clinetID);
    const payload = {
      offset: offSet,
      company_id: company?.value,
      limit: limit,
      client: clientID,
      is_active: true,
      search: searchText ?? undefined,
      internal_lead_source_id: source?.map((x) => x) ?? undefined,
      status: status?.length > 0 ? status : undefined,
      lead_owner,
      purpose,
      type,
      startDate: !!startDate
        ? `${moment(startDate).format("YYYY-MM-DD")} 00:00:00`
        : null,
      endDate: !!endDate
        ? `${moment(endDate).format("YYYY-MM-DD")} 23:59:59`
        : null,
      urgent: urgent_type,
      comment_from: moment(subDays(new Date(), 14)).format("YYYY-MM-DD"),
      comment_to: moment(new Date()).format("YYYY-MM-DD"),
      domain_referrer: external_lead_source,
      campaign_source,
    };

    if (team?.value !== "noteams") {
      if (team?.value) {
        payload["team"] = team?.value;
      } else if (selectedTeams && selectedTeams?.value !== "noteams") {
        payload["team"] = selectedTeams?.value;
      } else if (team && team !== "noteams") {
        payload["team"] = team;
      }
    }
    NetworkCall(
      `${config.api_url}/lead_proxy/get_proxy_lead_details`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        let list = res?.data?.data?.proxy_lead?.map((val) => {
          return {
            id: val?.id ?? "",
            LeadNumber: val?.reference_no ?? "",
            LeadName: val?.lead_name ?? "",
            MobileNumber: `${val?.mobile_country_code ?? ""}  ${
              val?.mobile_no ?? ""
            }`,
            Email: val?.email ?? "-",
            Source: val?.lead_source ?? "-",
            LeadOwner:
              val?.first_name || val?.last_name
                ? `${val?.first_name ?? ""} ${val?.last_name ?? ""}`
                : "-",
            Status: Status[val?.status] ?? "",
            description: val?.description ?? "-",
            date: val?.created_at ?? "-",
            purpose: val?.purpose ?? "-",
            statusDate: val?.updated_at ?? "-",
            type: val?.revenue_type ?? "-",
            country_name: val?.country_name ?? "-",
            company: val?.company ?? "-",
            icon: "more_2",
            urgent: val?.urgent,
            data: val,
            internal_lead_source_name: val?.internal_lead_source_name,
            is_active: val?.is_active,
            campaign_source: val?.campaign_source ?? "-",
          };
        });
        setList({
          count: res?.data?.data?.count ?? 0,
          list: list ?? [],
          comment_count: res?.data?.data?.comment?.[0]?.count,
        });
        setLoader(false);
        set_is_table_loading(false);
      })
      .catch((err) => {
        setLoader(false);
        set_is_table_loading(false);
      });
  };

  const handlePagination = (newPage) => {
    updatePage(newPage, limit);
  };

  const handleChangeLimit = (newLimit) => {
    updatePage(1, newLimit); // Reset page to 1 when limit changes
  };

  // dialog open
  const OpenDrawer = () => {
    setDrawer(!drawer);
  };
  const OpenCommentsDrawer = () => {
    setCommentsDrawer(!commentsDrawer);
  };
  // icon click function
  const handleIcon = (type, data, status) => {
    const full_data = data?.data;
    switch (type) {
      case "edit":
        setEdit({
          bool: true,
          id: data?.id,
        });
        setDialog(true);
        break;

      case "view":
        navigate(Routes.leadview, {
          state: {
            main: {
              company: selectedCompany,
              id: data?.id,
              selectedCompany: selectedCompany,
              team: selectedTeams?.value === "noteams" ? null : selectedTeams,
            },
          },
        });
        break;

      case "Achieved":
        save({
          status: "In Progress",
          id: data?.id,
        });
        setOpen({
          data: data,
          open: false,
        });
        break;

      case "delete":
        save({
          id: data?.id,
          is_active: !data?.is_active,
        });
        break;

      case "quick_view":
        set_quick_view_state({
          id: full_data?.id,
          lead_name: full_data?.lead_name ?? undefined,
          reference_no: full_data?.reference_no ?? undefined,
          purpose: full_data?.purpose ?? undefined,
          revenue_type: full_data?.revenue_type ?? undefined,
          mobile_no: full_data?.mobile_no
            ? concat_string(
                {
                  mobile_code: full_data?.mobile_country_code,
                  mobile_no: full_data?.mobile_no,
                },
                ["mobile_code", "mobile_no"],
                " "
              )
            : undefined,
          email_id: full_data?.email ?? undefined,
          description: full_data?.description ?? undefined,
          is_add_comment_enabled:
            full_data?.status === "In Progress" ? true : false,
          priority: full_data?.urgent ?? undefined,
        });
        set_is_add_form_open(!is_add_form_open);
        break;

      default:
        navigate(Routes.leadview, {
          state: {
            main: {
              company: selectedCompany,
              id: data?.id,
              selectedCompany: selectedCompany,
            },
          },
        });
        break;
    }
  };
  // close dialog and clear all states
  const closeDrawer = () => {
    setDialog(!dialog);
    setEdit({
      id: null,
      bool: false,
    });
  };
  // add laed function
  const addlead = () => {
    setDialog(!dialog);
  };
  // filter apply function
  const onApplyFilter = (value) => {
    setFilterData(value);
    GetList(
      selectedCompany,
      selectedTeams,
      searchText,
      0,
      limit,
      value?.lead_source?.map((x) => x?.value) ?? [],
      value?.status ?? [],
      value?.lead_owner?.map((x) => x?.value) ?? [],
      value?.purpose?.map((x) => x?.value) ?? [],
      value?.type?.map((x) => x?.value) ?? [],
      value?.date?.value?.from_date,
      value?.date?.value?.to_date,
      value?.active,
      value?.urgent_type,
      value?.external_lead_source?.map((x) => x?.value),
      value?.campaign_source?.map((x) => x?.value) ?? []
    );
  };
  // get details of tabel data
  const getDetails = (team) => {
    const currentOffset = (page - 1) * limit; // Dynamic offset calculation

    GetList(
      selectedCompany,
      team,
      searchText,
      currentOffset,
      limit, // Use limit from state
      filterData?.lead_source?.map((x) => x?.value),
      filterData?.status,
      filterData?.lead_owner?.map((x) => x?.value) ?? [],
      filterData?.purpose?.map((x) => x?.value) ?? [],
      filterData?.type?.map((x) => x?.value) ?? [],
      filterData?.date?.value?.from_date,
      filterData?.date?.value?.to_date,
      filterData?.active,
      filterData?.urgent_type,
      filterData?.external_lead_source?.map((x) => x?.value),
      filterData?.campaign_source?.map((x) => x?.value) ?? []
    );
  };

  // get enum
  const getEnum = async () => {
    const result = await enumSelect([
      enum_types.lead_source,
      enum_types.revenue_type,
      enum_types.purpose_global_type,
      enum_types.urgent_type,
    ]);
    setEnumValue({
      source: result?.lead_source,
      revenue_type: result?.revenue_type,
      purpose_global_type: result?.purpose_global_type,
      urgent_type: result?.urgent_type,
    });
  };
  // Function to change property
  const handleTeamsChange = (team) => {
    setSelectedTeams(team);
    getDetails(team);
  };

  const handleSearch = (value) => {
    setSearchText(value);
    debounce(
      () =>
        GetList(
          selectedCompany,
          selectedTeams,
          value,
          0,
          10,
          filterData?.lead_source?.map((x) => x?.value),
          filterData?.status,
          filterData?.lead_owner?.map((x) => x?.value) ?? [],
          filterData?.purpose?.map((x) => x?.value) ?? [],
          filterData?.type?.map((x) => x?.value) ?? [],
          filterData?.date?.value?.from_date,
          filterData?.date?.value?.to_date,
          filterData?.active,
          filterData?.urgent_type,
          filterData?.campaign_source?.map((x) => x?.value) ?? []
        ),
      800
    );
  };

  const save = (data) => {
    let datas = {
      id: data?.id,
      is_active: data?.is_active,
      status: data?.status,
      archieved_description: data?.archieved_description,
    };

    NetworkCall(
      `${config.api_url}/lead_proxy/upsert`,
      NetWorkCallMethods.post,
      datas,
      null,
      true,
      false
    )
      .then((response) => {
        getDetails(selectedCompany?.value);
        setOpen({
          data: data,
          open: false,
        });
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
      });
  };

  let moduleType = ""; 
  structureArray.forEach((item) => {
    if (!moduleType && item?.children?.length) {
      item.children.forEach((child) => {
        if (child?.module_type_meta_data?.includes("Lease")) {
          moduleType = "Lease";
        } else if (child?.module_type_meta_data?.includes("Sale")) {
          moduleType = "Sale";
        }
      });
    }
  });

  const render = () => {
    return (
      <>
        {/* sub nav bar */}
        <Subheader
          hideBackButton={true}
          title={`${t("Lead")}(${list?.count ?? 0})`}
          select
          options={companyList}
          value={selectedCompany}
          placeholder={t("Search By Lead Name")}
          goBack={() => {
            navigate(Routes.home);
          }}
          onchange={(e) => {
            setSelectedCompany(e);
          }}
          selectTeamsOptions
          handleTeamsChange={handleTeamsChange}
          companyId={selectedCompany?.value}
          moduleId={moduleId}
          selectedTeams={selectedTeams}
        />

        {loader ? (
          <NewLoader />
        ) : (
          <>
            {/* body contant */}
            <div className={classes.root}>
              <Grid container className={classes.content} spacing={2}>
                <Grid item xs={4}>
                  <Box>
                    {/* search box */}
                    <SearchFilter
                      value={searchText}
                      placeholder={t("Search")}
                      handleChange={(value) => handleSearch(value)}
                      customfieldSx={{
                        "& .MuiOutlinedInput-root": { height: "40px" },
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={8} display={"flex"} justifyContent={"end"}>
                  <Box display={"flex"} sx={{ float: "right" }}>
                    <Stack
                      direction="row"
                      divider={
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ marginInline: "16px" }}
                        />
                      }
                      spacing={2}
                    >
                      <Box>
                        <IconButton
                          size="small"
                          className={classes.img}
                          onClick={handleDownload}
                        >
                          <DownloadImage />
                        </IconButton>
                        <Popover
                          id={id}
                          open={openAnchor}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <div className={classes.tray}>
                            <div className={classes.buttonWrapper}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleDownloadReport}
                                disabled={isLoading || isDownloadLoading}
                                className={classes.button}
                              >
                                {isDownloadLoading ? (
                                  <CircularProgress size={24} />
                                ) : (
                                  t("Download Report")
                                )}
                              </Button>
                            </div>
                            <Divider />
                            <List className={classes.list}>
                              {isReportLoading ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <CircularProgress size={34} />
                                </div>
                              ) : downloadedFiles.length === 0 ? (
                                <Typography style={{ textAlign: "center" }}>
                                  {t("No export history")}
                                </Typography>
                              ) : (
                                <InfiniteScroll
                                  dataLength={downloadedFiles.length}
                                  next={fetchMoreReport}
                                  hasMore={hasMore}
                                  height={150}
                                >
                                  <Grid>
                                    {downloadedFiles.map((file, index) => (
                                      <ListItem
                                        key={index}
                                        className={classes.listItem}
                                      >
                                        <Tooltip title={file.file_name} arrow>
                                          {file.is_completed ? (
                                            <Link
                                              className={classes.fileName}
                                              onClick={() =>
                                                handleOpenFile(file.file_url)
                                              }
                                              component="button"
                                              variant="body2"
                                            >
                                              {truncateFileName(file.file_name)}
                                            </Link>
                                          ) : (
                                            <Typography
                                              variant="body2"
                                              className={
                                                classes.disabledFileName
                                              }
                                              style={{ color: "gray" }}
                                            >
                                              {truncateFileName(file.file_name)}
                                            </Typography>
                                          )}
                                        </Tooltip>
                                        {file.is_completed ? null : file.is_failed ? (
                                          <WarningAmberRoundedIcon
                                            className={classes.errorIcon}
                                            style={{
                                              color: "red",
                                              fontSize: "18px",
                                            }}
                                          />
                                        ) : (
                                          <CircularProgress size={24} />
                                        )}
                                      </ListItem>
                                    ))}
                                    {isLoadingMore && (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <CircularProgress size={24} />
                                      </div>
                                    )}
                                  </Grid>
                                </InfiniteScroll>
                              )}
                            </List>
                          </div>
                        </Popover>
                      </Box>
                      <Box display={"flex"}>
                        <Box mr={1}>
                          <Tooltip
                            title={t("View Latest Comment")}
                            placement="top"
                            arrow
                          >
                            {list.comment_count > 0 ? (
                              <IconButton
                                size="small"
                                className={classes.img}
                                onClick={OpenCommentsDrawer}
                              >
                                <Badge
                                  variant="dot"
                                  color="error"
                                  classes={{ badge: classes.customBadge }}
                                >
                                  <img src="/images/chat.svg" alt="chat" />
                                </Badge>
                              </IconButton>
                            ) : (
                              <IconButton
                                size="small"
                                className={classes.img}
                                onClick={OpenCommentsDrawer}
                              >
                                <img src="/images/chat.svg" alt="chat" />
                              </IconButton>
                            )}
                          </Tooltip>
                        </Box>
                        <Divider
                          orientation="vertical"
                          p={0.5}
                          height={2}
                          sx={{ marginInline: "16px" }}
                        />
                        <IconButton
                          size="small"
                          className={classes.img}
                          onClick={OpenDrawer}
                        >
                          <img src="/images/filter.svg" alt="filter" />
                        </IconButton>
                      </Box>
                      {permission?.create && (
                        <Box>
                          {/* add agent button */}
                          <Button
                            variant="contained"
                            className={classes.button}
                            onClick={addlead}
                          >
                            {t("Create Lead")}
                          </Button>
                        </Box>
                      )}
                    </Stack>
                  </Box>
                </Grid>

                {/* Main tableData */}
                <Grid item xs={12} className={classes.tableMain}>
                  <TableWithPagination
                    is_loading={is_table_loading}
                    heading={LeadNewheading(t)}
                    rows={list?.list ?? ""}
                    path={LeadNewpath}
                    showpagination={true}
                    showpdfbtn={false}
                    showexcelbtn={false}
                    showSearch={false}
                    archived={true}
                    handleIcon={handleIcon}
                    handlePagination={handlePagination}
                    handleChangeLimit={handleChangeLimit}
                    totalRowsCount={list?.count}
                    page={page}
                    limit={limit}
                    tableType="no-side"
                    noDataSvg
                    dataType={[
                      { type: ["lead_more"], icon: "icon" },
                      { type: ["text"], name: "LeadNumber" },
                      { type: ["date"], name: "date" },
                      { type: ["long_text"], name: "description" },
                      { type: ["text"], name: "LeadName" },
                      { type: ["text"], name: "Email" },
                      { type: ["text"], name: "company" },
                      { type: ["text"], name: "country_name" },
                      { type: ["text"], name: "MobileNumber" },
                      { type: ["text"], name: "purpose" },
                      { type: ["text"], name: "type" },
                      { type: ["text"], name: "internal_lead_source_name" },
                      { type: ["long_text"], name: "campaign_source" },
                      { type: ["priority"], name: "urgent" },
                      { type: ["text"], name: "LeadOwner" },
                      { type: ["status"], name: "Status" },
                    ]}
                    height={"calc(100vh - 315px)"}
                    view={permission?.read}
                    edit={permission?.update}
                    delete={permission?.delete}
                    hideDelete
                  />
                </Grid>
                {/* end Main tableData */}
              </Grid>

              {/* filter drawer */}
              {drawer && (
                <FilterGenerator
                  open={drawer}
                  onClose={() => setDrawer(false)}
                  components={[
                    {
                      component: "select",
                      value: filterData?.lead_source,
                      options: [],
                      isMulti: true,
                      label: t("Internal Lead Source"),
                      placeholder: t("Select Internal Lead Source"),
                      state_name: "lead_source",
                      loadOptions: (search, array, handleLoading) =>
                        loadOptionsApis(
                          "queries/opportunity/internal_lead_source",
                          {},
                          search,
                          array,
                          handleLoading,
                          "data",
                          {}
                        ),
                      debounceTimeout: 800,
                      isPaginate: true,
                    },
                    {
                      component: "select",
                      value: filterData?.external_lead_source,
                      options: [],
                      isMulti: true,
                      label: t("External Lead Source"),
                      placeholder: t("Select External Lead Source"),
                      state_name: "external_lead_source",
                      loadOptions: (search, array, handleLoading) =>
                        loadOptionsApis(
                          "lead_proxy/get-domain",
                          {
                            company_id: selectedCompany?.value,
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {}
                        ),
                      debounceTimeout: 800,
                      isPaginate: true,
                    },
                    {
                      component: "select",
                      value: filterData?.lead_owner,
                      options: [],
                      isMulti: true,
                      label: t("Lead Owner"),
                      placeholder: t("Select Lead Owner"),
                      state_name: "lead_owner",
                      loadOptions: (search, array, handleLoading) =>
                        loadOptionsApis(
                          "lead/lead_owner",
                          {
                            company_id: selectedCompany?.value,
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {}
                          // manualResponse
                        ),
                      debounceTimeout: 800,
                      isPaginate: true,
                    },
                    {
                      component: "select",
                      value: filterData?.type,
                      options: enumValue?.revenue_type ?? [],
                      isMulti: true,
                      label: t("Type"),
                      placeholder: t("Select Type"),
                      state_name: "type",
                    },
                    {
                      component: "select",
                      value: filterData?.purpose,
                      options: enumValue?.purpose_global_type ?? [],
                      isMulti: true,
                      label: t("Purpose"),
                      placeholder: t("Select Purpose"),
                      state_name: "purpose",
                    },
                    {
                      component: "select",
                      value: filterData?.date,
                      state_name: "date",
                      label: t("Created On"),
                      placeholder: t("Select Date"),
                      options: getCalendarOptions(),
                    },
                    {
                      component: "select",
                      value: filterData?.campaign_source,
                      options: [],
                      isMulti: true,
                      label: t("Campaign Source"),
                      placeholder: t("Select campaign source"),
                      state_name: "campaign_source",
                      loadOptions: (search, array, handleLoading) =>
                        loadOptionsApis(
                          "lead_proxy/get-campaign-source",
                          {
                            company_id: selectedCompany?.value,
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {}
                        ),
                      debounceTimeout: 800,
                      isPaginate: true,
                    },
                    {
                      component: "toggleButton",
                      value: filterData?.status,
                      options: [
                        { label: t("Open"), value: "In Progress" },
                        { label: t("Qualify"), value: "Converted" },
                        { label: t("Disqualify"), value: "Lost Lead" },
                        { label: t("Archived"), value: "Archieved" },
                        { label: t("Unqualified"), value: "Unqualified" },
                      ],
                      isMulti: false,
                      state_name: "status",
                      label: t("Status"),
                    },
                    {
                      component: "toggleButton",
                      value: filterData?.urgent_type,
                      options: wrapLabelsInT(enumValue?.urgent_type, t),
                      isMulti: true,
                      state_name: "urgent_type",
                      label: t("Priority"),
                    },
                  ]}
                  onApply={(value) => onApplyFilter(value)}
                />
              )}
            </div>
          </>
        )}

        {/* create lead */}

        <React.Fragment key={"right"}>
          <Drawer anchor={"right"} open={dialog} onClose={closeDrawer}>
            <AddLead
              t={t}
              permission={permission}
              isEdit={isEdit}
              parentID={"id_123"}
              closeDrawer={closeDrawer}
              selectedCompany={selectedCompany?.value}
              getDetails={getDetails}
              company={selectedCompany}
              team={selectedTeams}
              moduleType={moduleType}
            />
          </Drawer>
        </React.Fragment>

        <React.Fragment key={"right"}>
          <Drawer
            anchor={"right"}
            open={commentsDrawer}
            onClose={() => setCommentsDrawer(false)}
          >
            <Box>
              <LeadComments
                t={t}
                closeDrawer={() => setCommentsDrawer(false)}
                company_id={selectedCompany?.value}
                clientID={localStorage.getItem(LocalStorageKeys.clinetID)}
                filterData={filterData}
                selectedCompany={selectedCompany}
                selectedTeams={selectedTeams}
              />
            </Box>
          </Drawer>
        </React.Fragment>
        <DialogBox
          open={open?.open}
          background
          maxWidth={"xs"}
          isnotTitle
          handleClose={() =>
            setOpen({
              open: false,
              ...open,
            })
          }
          component={
            <Box p={2}>
              <FormGenerator
                t={t}
                spacing={1.5}
                components={[
                  {
                    isActive: true,
                    component: "text",
                    label: "Description/Requirement",
                    value: data1,
                    placeholder: "Description/Requirement",
                    onChange: (e) => setData1(e?.target?.value),
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 12,
                      lg: 12,
                    },
                    isPaginate: true,
                    multiline: true,
                    height: "60px",
                  },
                ]}
              />
              <Button
                className={classes.commandbtn}
                disabled={setData1?.length > 0 ? false : true}
                style={{ width: "100%", marginTop: "12px" }}
                onClick={() =>
                  save({
                    status: "In Progress",
                    id: open?.data?.id,
                    archieved_description: data1,
                  })
                }
              >
                Submit
              </Button>
            </Box>
          }
        />
        <DrawerComponent
          anchor={size?.width >= 600 ? "right" : "bottom"}
          DrawerClass={classes.quick_view_drawer}
          open={is_add_form_open}
          onClose={() => set_is_add_form_open(!is_add_form_open)}
          component={
            <>
              <Stack
                className={classes.quick_view_drawer_header}
                direction={"row"}
                columnGap={"12px"}
                alignItems={"center"}
              >
                <IconButton
                  onClick={() => set_is_add_form_open(!is_add_form_open)}
                  className={classes.quick_view_drawer_close_button}
                >
                  <CloseIconSvg />
                </IconButton>
                <Typography className={classes.quick_view_drawer_header_title}>
                  {t("Quick View")}
                </Typography>
              </Stack>
              <Box className={classes.quick_view_drawer_body_view}>
                <QuickViewDrawer
                  t={t}
                  spacing={1.5}
                  components={[
                    {
                      isActive: true,
                      component: "text",
                      label: "Description/Requirement",
                      value: data1,
                      placeholder: "Description/Requirement",
                      onChange: (e) => setData1(e?.target?.value),
                      size: {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                      },
                      isPaginate: true,
                      multiline: true,
                      height: "60px",
                    },
                  ]}
                />
                <Button
                  className={classes.commandbtn}
                  disabled={setData1?.length > 0 ? false : true}
                  style={{ width: "100%", marginTop: "12px" }}
                  onClick={() =>
                    save({
                      status: "In Progress",
                      id: open?.data?.id,
                      archieved_description: data1,
                    })
                  }
                >
                  Submit
                </Button>
              </Box>
            </>
          }
        />
        <DrawerComponent
          anchor={size?.width >= 600 ? "right" : "bottom"}
          DrawerClass={classes.quick_view_drawer}
          open={is_add_form_open}
          onClose={() => set_is_add_form_open(!is_add_form_open)}
          component={
            <>
              <Stack
                className={classes.quick_view_drawer_header}
                direction={"row"}
                columnGap={"12px"}
                alignItems={"center"}
              >
                <IconButton
                  onClick={() => set_is_add_form_open(!is_add_form_open)}
                  className={classes.quick_view_drawer_close_button}
                >
                  <CloseIconSvg />
                </IconButton>
                <Typography className={classes.quick_view_drawer_header_title}>
                  {t("Quick View")}
                </Typography>
              </Stack>
              <Box className={classes.quick_view_drawer_body_view}>
                <QuickViewDrawer
                  t={t}
                  selected_company={selectedCompany}
                  data={quick_view_state}
                />
              </Box>
            </>
          }
        />
      </>
    );
  };

  return <>{accessCheckRender(render, permission)}</>;
};
export default withTranslation("leads")(LeadNew);
