import { Button, Divider, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Profile } from "../../assets";
import AccountIcon from "../../assets/accountIcon";
import AgreementIcon from "../../assets/agreementIcon";
import { InvoiceDropdown, LoadingSection, ReceiptHistoryTable, Subheader, TemplateGenerator, UseDebounce } from "../../components";
import { DocumentViewer } from "../../components/fileViewer";
import { NewLoader } from "../../components/newLoader";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { AlertProps, NetWorkCallMethods, accessCheckRender, convertTimeUtcToZoneCalander, getRoutePermissionNew } from "../../utils";
import { InvoiceViewStyles } from "./style";
import { withTranslation } from "react-i18next";
import moment from "moment";
import NoDataFound from "../../assets/noData";




const CustomPaper = styled('div')(({ theme }) => ({
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "#F2F4F7",
    // padding: theme.spacing(2),
    margin: theme.spacing(3),
    padding: "0px",
    height: `calc(100vh - 147px)`,
}))





const InvoiceView = ({ id = null, agreement = false, goBackFun = () => false, t }) => {

    const classes = InvoiceViewStyles()
    const alert = React.useContext(AlertContext);
    const backdrop = React.useContext(BackdropContext);
    const auth = React.useContext(AuthContext)
    const { state } = useLocation()
    const [status, setStatus] = React.useState("invoice")
    const [loading, setLoading] = React.useState(true)
    const [invoiceData, setInvoiceData] = React.useState();
    const navigate = useNavigate()
    const [permission, setPermission] = React.useState({})

    const [details, setDetails] = React.useState()
    const [pdf, setPdf] = React.useState({
        bool: false,
        data: ""
    })
    const [template, setTemplate] = React.useState(false);


    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                InvoiceTableData()
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const InvoiceTableData = () => {

        const payload = {
            id: state || id
        };
        NetworkCall(
            `${config.api_url}/invoice/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((response) => {
                setInvoiceData(response?.data?.data)
                setLoading(false)
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
            }).catch((error) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });

                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,

                    msg: t("Some Thing Went Wrong"),
                });
                setLoading(false)

            });
    }

    const goBack = () => {
        if (agreement) {
            goBackFun()
        } else {
            navigate(-1);
        }
    }

    React.useEffect(() => {
        if (permission?.read) {
            InvoiceTableData()
        }
        //eslint-disable-next-line
    }, [permission])

    React.useEffect(() => {
        // if(state?.id){
        getInvoiceDropdown()
        // }
        // eslint-disable-next-line
    }, [])
    const getInvoiceDropdown = (e) => {
        const payload = {
            invoice_id: state || id,
            search: e ?? ""
        }
        NetworkCall(
            `${config.api_url}/settlement/get_invoice_receipt_settlement`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            // setDetails(response.data.invoice_receipt_settlement[0]);
            let invoiceDataArray = []
            response?.data?.data?.invoice_receipt_settlement?.map((i) => {

                invoiceDataArray.push(
                    {
                        amount_tagged: i?.amount_tagged,
                        created_at: convertTimeUtcToZoneCalander(i?.created_at),
                        tagged_by: i?.tagged_by?.first_name,
                        reciepts_no: i?.reciepts?.reciepts_no,
                        source: i?.reciepts?.payment_mode ?? "-",
                        url: i?.reciepts.url,
                        settlement_no: i?.settlement_no,
                        symbol: i?.invoice.company.currency.symbol
                    }
                )
                return 0;
            }
            )
            setDetails(invoiceDataArray)
        })

            .catch((err) => {
                console.log(err);
            });
    }

    // Search function

    const [searchText, setSearchText] = React.useState("");
    const debounce = UseDebounce();

    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    const searchTableFunction = (e) => {
        getInvoiceDropdown(e)
    }


    //post to invoice
    const getManageInvoice = (v) => {
        setPdf({
            bool: false,
            data: ""
        })
        setTemplate(false)
        const variables = {
            "invoice_id": state || id,
            type_master_id: v?.value ?? undefined
        }
        // console.log(variables) 
        NetworkCall(
            `${config.api_url}/invoice/template`,
            NetWorkCallMethods.post,
            variables,
            null,
            true,
            false
        ).then((res) => {
            setPdf({
                data: res?.data,
                bool: true,

            })

        }).catch((err) => {
            setPdf({
                pdf: "",
                bool: false,

            })
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,

                msg: t("Some thing went wrong"),

            });
        })
    }


    React.useEffect(() => {
        getManageInvoice()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state || id])

    const handleSubmit = () => {
        let datas = {
            "id": state || id
        }
        NetworkCall(
            `${config.api_url}/invoice/covert_invoice`,
            NetWorkCallMethods.post,
            datas, null, true, false
        ).then((res) => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.success,
                msg: t("Converted Successfully"),
            });
            InvoiceTableData()

        }).catch((error) => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Internal error. Please try again later."),
            });
        });
    }
    const render = () => {
        return <>

            {loading ? <LoadingSection bottom={"45vh"} message={t("Loading Invoice Details")} />

                :
                <>
                    <div>
                        <Subheader title={t("Invoice")} count={false}
                            select={false}
                            isReadOnly
                            goBack={goBack}
                        />
                        <CustomPaper >
                            <Grid container>
                                <Grid item xs={12} sm={12} md={3} lg={3} bgcolor="white">
                                    <Grid container spacing={1} padding="12px 0px 12px 12px" display="block">
                                        <Box className={classes.left_content}>
                                            <Grid item xs={12} >
                                                <div className={classes.div} >
                                                    <Box>
                                                        <Box>

                                                            <Typography className={classes.textSmall}>{t("INVOICE DETAILS")}</Typography>
                                                        </Box >
                                                        <Box height={"8px"} />
                                                        <Box display={"flex"} justifyContent={"space-between"}>
                                                            <Box>
                                                                <Typography className={classes.text1}>{invoiceData?.invoice_no ?? " -"}</Typography>
                                                            </Box>
                                                            <Box>
                                                                <Typography className={classes.text2}>{invoiceData?.payment_status === "due" ? "unpaid" : "paid"}</Typography>

                                                            </Box>
                                                        </Box>
                                                        <Box height={"4px"} />

                                                        <Box>
                                                            <Typography className={classes.text3}>{invoiceData?.invoice_type ?? "-"}</Typography>
                                                        </Box>
                                                        <Box height={"4px"} />

                                                        <Stack direction={"row"} alignItems="center">
                                                            <Box >
                                                                <Typography className={classes.text4}>{`${"Generated on"} ${moment(invoiceData?.created_at).format("DD MMM YY")}`}</Typography>
                                                            </Box >
                                                            <Box className={classes.periodStyle} />
                                                            <Box >
                                                                <Typography className={classes.text4}>{`${"Due on"} ${moment(invoiceData?.payment_due_date).format("DD MMM YY")}`}</Typography>

                                                            </Box>
                                                        </Stack >
                                                    </Box >
                                                    <Box height={"12px"} />

                                                    <Divider />
                                                    <Box height={"12px"} />

                                                    <Box className={classes.box}>
                                                        <Box>

                                                            <Typography className={classes.text3}>{t("Invoice Amount")}</Typography>

                                                        </Box >
                                                        <Box>
                                                            <Typography className={classes.text3}>{`${invoiceData?.total_amount_without_tax ?? ""} ${invoiceData?.symbol ?? ""}`}</Typography>

                                                        </Box>
                                                    </Box >
                                                    <Box height={"12px"} />

                                                    <Box className={classes.box}>
                                                        <Box>
                                                            <Typography className={classes.text3}>{`${t("Tax")}`}</Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography className={classes.text3}>{`${invoiceData?.invoice_total_tax_amount ?? 0} ${invoiceData?.symbol ?? ""}`}</Typography>

                                                        </Box>
                                                    </Box>
                                                    <Box height={"12px"} />

                                                    <Box className={classes.box1}>
                                                        <Box>

                                                            <Typography className={classes.text333}>{t("Total Invoice Amount")}</Typography>

                                                        </Box >
                                                        <Box>
                                                            <Typography className={classes.text333}>{`${invoiceData?.total_amount ?? "-"} ${invoiceData?.symbol ?? ""}`}</Typography>

                                                        </Box>
                                                    </Box >
                                                    <Box height={"12px"} />
                                                    <Box className={classes.dropdownBox}>
                                                        {/* <Box>
                                                        <Typography className={classes.text33} >Partial Amount Received</Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography className={classes.text33}>{`${invoiceData?.partial_amount ?? ""} ${invoiceData?.symbol ?? ""}`}</Typography>
                                                    </Box> */}
                                                        <InvoiceDropdown partialAmount={invoiceData?.partial_amount} invoiceData={details} symbol={invoiceData?.symbol} />
                                                    </Box>

                                                </div >
                                            </Grid >
                                            {invoiceData?.is_configured &&
                                                <Grid item xs={12} >
                                                    <div className={classes.div} >
                                                        <Box>

                                                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                <Box>
                                                                    <Typography className={classes.text1}>{t("E INVOICE")}</Typography>
                                                                </Box>
                                                                <Box>
                                                                    <Typography className={invoiceData?.is_configured ? classes.text5 : classes.text2}>{invoiceData?.is_configured ? "Configured" : "Not Configured"}</Typography>

                                                                </Box>
                                                            </Box>

                                                            <Box height={"4px"} />
                                                            <Box display={"flex"} justifyContent={"space-between"}>
                                                                <Box>
                                                                    <Typography className={classes.text4}>{`${"Submitted on"}`}</Typography>
                                                                </Box>
                                                                <Box>
                                                                    {invoiceData?.submitted_on && <Typography className={classes.text4}>{`${moment(invoiceData?.submitted_on).format("DD MMM YY HH:mm a")}`}</Typography>
                                                                    }
                                                                </Box>
                                                            </Box>
                                                            <Box height={"4px"} />

                                                            <Box display={"flex"} justifyContent={"space-between"}>
                                                                <Box>
                                                                    <Typography className={classes.text4}>{`${"ACK on"} `}</Typography>
                                                                </Box>
                                                                <Box>
                                                                    {invoiceData?.ack_on && <Typography className={classes.text4}>{`${moment(invoiceData?.ack_on).format("DD MMM YY HH:mm a")}`}</Typography>
                                                                    }
                                                                </Box>
                                                            </Box>


                                                        </Box >
                                                        <Box height={"12px"} />

                                                        <Divider />
                                                        <Box height={"12px"} />

                                                        <Button variant="contained" fullWidth disabled={invoiceData?.is_reported ? true : !invoiceData?.is_configured}
                                                            onClick={handleSubmit}
                                                        >
                                                            {t("Submit")}
                                                        </Button >



                                                    </div >
                                                </Grid >
                                            }
                                            <Grid item xs={12}>
                                                <div className={classes.div}>
                                                    <Box>

                                                        <Typography className={classes.textSmall}>{t("CUSTOMER DETAILS")}</Typography>
                                                    </Box >
                                                    <Box height={"4px"} />
                                                    <Box display={"flex"}>
                                                        <Box>
                                                            {invoiceData?.image_url ?
                                                                <img src={invoiceData?.image_url} alt="userimg" className={classes.img} />
                                                                : <Profile />
                                                            }
                                                        </Box>

                                                        <Box marginInlineStart={"12px"} alignItems="center">
                                                            <Typography className={classes.textRight}>{invoiceData?.user_name ?? "-"}</Typography>
                                                            <Typography className={classes.textBottom}>{invoiceData?.ucid ?? "-"}</Typography>
                                                        </Box>

                                                    </Box >
                                                    <Box height={"12px"} />
                                                    <Divider />
                                                    <Box height={"12px"} />
                                                    <Box>

                                                        <Typography className={classes.textSmall}>{t("ACCOUNT DETAILS")}</Typography>
                                                    </Box >
                                                    <Box height={"4px"} />

                                                    <Box display={"flex"}>
                                                        <Box>
                                                            <AccountIcon />
                                                        </Box>

                                                        <Box marginInlineStart={"12px"} alignItems="center">
                                                            <Typography className={classes.textRight}>{invoiceData?.account_name ?? "-"}</Typography>
                                                            <Typography className={classes.textBottom}>{invoiceData?.account_no ?? "-"}</Typography>
                                                        </Box>
                                                    </Box >
                                                </div >
                                            </Grid >
                                            <Grid item xs={12}>
                                                <div className={classes.div}>

                                                    <Box display={"flex"}>
                                                        <Box>
                                                            <AgreementIcon />
                                                        </Box>

                                                        <Box marginInlineStart={"12px"} alignItems="center">
                                                            <Typography className={classes.textRight}>{invoiceData?.agreement_no ?? "-"}</Typography>
                                                            <Typography className={classes.textBottom}>{invoiceData?.company_name ?? "-"}</Typography>
                                                        </Box>
                                                    </Box >
                                                </div >
                                            </Grid >
                                        </Box >

                                        <Grid item xs={12}>
                                            <br />
                                            <Button variant="contained" fullWidth disabled={true}>

                                                {t("Send Payment Link")}
                                            </Button >
                                        </Grid >
                                    </Grid >
                                </Grid >
                                <Grid item xs={12} sm={12} md={9} lg={9} >

                                    <Grid container rowSpacing={3} justifyContent={"center"} pt={0.8} style={{ position: 'relative' }} >
                                        <Grid item xs={2} >
                                            <Typography

                                                onClick={() => {
                                                    setStatus("invoice");
                                                }}
                                                className={
                                                    status === "invoice"
                                                        ? classes.unselect
                                                        : classes.select
                                                }
                                            >

                                                {t("invoice")}
                                            </Typography >
                                        </Grid >
                                        <Grid item xs={2} >
                                            <Typography

                                                onClick={() => {
                                                    setStatus("receipt");
                                                }}
                                                className={
                                                    status === "receipt"
                                                        ? classes.unselect
                                                        : classes.select
                                                }
                                            >

                                                {t("Receipt")}
                                            </Typography >
                                        </Grid >
                                        <Grid item xs={2} >
                                            <Typography

                                                onClick={() => {
                                                    setStatus("receipthistory");
                                                }}
                                                className={
                                                    status === "receipthistory"
                                                        ? classes.unselect
                                                        : classes.select
                                                }
                                            >

                                                {t("Receipt History")}
                                            </Typography >
                                        </Grid >
                                        {status === "invoice" &&
                                            <Box className={classes.next} onClick={() => setTemplate(true)}>
                                                {t("Generate")}
                                            </Box>
                                        }
                                        {
                                            status === "receipthistory" ?
                                                <Grid item xs={12} m={2} p={2} className={classes.tabledoc}>
                                                    <ReceiptHistoryTable ReceiptTableData={details} searchText={searchText} handleSearch={handleSearch} t={t} />
                                                </Grid>
                                                :
                                                <Grid item xs={12} >

                                                    <Box p={1}>
                                                        <center className={classes.doc}>
                                                            {
                                                                status === "receipt" ?
                                                                    <div>
                                                                        {/* {details?.url ?
                                                                        <DocumentViewer url={details?.url} /> :
                                                                        "No Invoice found"
                                                                    } */}
                                                                        {
                                                                            details?.length > 0 ? details.map(dtl => {
                                                                                return (
                                                                                    <div>
                                                                                        {
                                                                                            dtl?.url !== null ?
                                                                                                <DocumentViewer url={dtl?.url} />
                                                                                                :
                                                                                                <Box justifyContent={'center'} display={'flex'} alignItems={'center'} height={'calc(100vh - 253px)'}><NoDataFound /></Box>

                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            })
                                                                                :
                                                                                <Box justifyContent={'center'} display={'flex'} alignItems={'center'} height={'calc(100vh - 253px)'}><NoDataFound /></Box>

                                                                        }
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <Box >
                                                                            {
                                                                                pdf?.bool ?
                                                                                    <iframe
                                                                                        src={`data:application/pdf;base64,${pdf?.data
                                                                                            }`}
                                                                                        alt="pdf"
                                                                                        width="100%"
                                                                                        style={{ height: `calc(100vh - 192px)` }}
                                                                                        title="quotation"

                                                                                    />

                                                                                    :
                                                                                    <NewLoader minusHeight={'158px'} />
                                                                            }
                                                                        </Box>
                                                                    </div>
                                                            }
                                                        </center>
                                                    </Box>
                                                </Grid >
                                        }
                                    </Grid >
                                </Grid >
                            </Grid >
                        </CustomPaper >
                    </div >

                </>
            }

            <TemplateGenerator t={t} name={t("Invoice Template")} btnText={t("Generate")} open={template} onClose={() => setTemplate(false)} type={2} generate={getManageInvoice} />
        </>
    }

    return (
        <>
            {accessCheckRender(render, permission)}
        </>
    );
};


export default withTranslation("invoice")(InvoiceView);
