import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Link,
  Stack,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Tooltip,
} from "@mui/material";
import { useStylesListView } from "./style";
import { UnitDetailsDrawer } from "./unitDetailsDrawer";
import AddUnit from "../createUnit";
import { createState } from "../utils";
import { FontFamilySwitch } from "../../../utils";
import { CreateExternalUnitContext } from "../externalUnitContext";

export const ListView = ({
  t,
  unit,
  deleteUnit = () => {},
  viewUnit = () => {},
  unitDetails,
  unitData,
  setUnitData,
  existingPricing,
  getExternalUnits,
  companyId,
  currency,
}) => {
  const { formatPrice } = React.useContext(CreateExternalUnitContext);
  const classes = useStylesListView();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dialog, setDialog] = React.useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
    viewUnit();
  };

  const closeDrawer = () => {
    setUnitData({ ...createState });
    setDialog(!dialog);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setUnitData({ ...createState });
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <Box mb={1} className={classes.card} sx={{ width: "100%" }}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ width: "100%" }}
      >
        <Stack direction={"row"} sx={{ width: "100%" }}>
          <Box sx={{ position: "relative", height: "120px" }}>
            {unit?.assets?.length > 0 ? (
              <img
                src={unit?.assets?.[0]?.url}
                alt={unit?.name}
                className={classes.asset}
              />
            ) : (
              <img
                src="/images/propertyImagePlaceholder.svg"
                alt={unit?.name}
                className={classes.asset}
              />
            )}
            <Stack
              className={classes.assetText}
              direction="row"
              spacing={1}
              alignItems="center"
            >
              <Typography className={classes.text}>
                {t(unit?.purpose)}
              </Typography>
              <Divider className={classes.round} />
              <Typography className={classes.text}>
                {unit?.unitType?.name}
              </Typography>
            </Stack>
          </Box>

          <Stack marginInlineStart={2} direction={"column"} spacing={1}>
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Typography className={classes.header}>{unit?.name}</Typography>
              <Box className={classes.availability}>{t("Available")}</Box>
              <Typography className={classes.header}>
                {unit?.vendor?.[0]?.name}
              </Typography>
            </Stack>
            <Typography className={classes.propertyName}>
              {unit?.property_name}
            </Typography>
            <Typography className={classes.units}>{`${t(
              "Total Units in this Property:"
            )} ${unit.numberOfUnits} ${t("Units")}`}</Typography>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <img src="/images/link.svg" />
              {unit?.listings?.portal_url ? (
                <Link
                  target="_blank"
                  href={unit?.listings?.portal_url}
                  className={classes.url}
                >
                  {unit?.listings?.portal_url}
                </Link>
              ) : (
                <div>-</div>
              )}
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction="column"
          justifyContent={"space-between"}
          textAlign={"right"}
        >
          <Stack direction="row" justifyContent="flex-end">
            <Typography className={classes.header}>
              {currency} {formatPrice(unit?.pricing)}
            </Typography>
            <Tooltip
              title={`${t("Primary Charges")} ${currency} ${unit?.pricing} ${t(
                unit?.period
              )}`}
              placement="right"
              arrow
            >
              <Box
                sx={{
                  position: "relative",
                  top: "-2px",
                  ml: 1,
                  "&:hover img": {
                    content: 'url("/images/infoblue.svg")',
                  },
                  marginInlineStart: 1,
                }}
              >
                <img src="/images/info.svg" />
              </Box>
            </Tooltip>
          </Stack>

          <Stack mt={2} direction="row" alignItems="center">
            <Button
              onClick={() => {
                viewUnit();
                handleDrawerOpen();
              }}
              className={classes.view}
              variant="outlined"
              width={"100%"}
            >
              {t("View Details")}
            </Button>
            <Box
              onClick={() => {
                viewUnit();
                setDialog(true);
              }}
              className={classes.editBox}
            >
              <img src="/images/editblue.svg" alt={unit?.edit} />
            </Box>
            <Box onClick={handleDialogOpen} className={classes.deleteBox}>
              <img src="/images/delete.svg" alt={unit?.delete} />
            </Box>
          </Stack>
        </Stack>
      </Stack>
      {/* Dialog for delete confirmation */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.publishDialog}
      >
        <DialogContent sx={{ padding: 0 }}>
          <DialogContentText sx={{ background: "transparent" }}>
            <div
              style={{
                paddingRight: "60px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                backgroundImage: `url(${"images/announsment.svg"})`,
                backgroundRepeat: "no-repeat",
                height: 250,
                width: 600,
              }}
            >
              <div className={classes.proceedDialog}>
                <Stack spacing={3}>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      fontFamily: FontFamilySwitch().semiBold,
                    }}
                  >
                    {t("Are you sure you want to delete this unit?")}
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.marginTop}
                  >
                    <Button
                      className={classes.Yes}
                      onClick={() => {
                        deleteUnit();
                        handleDialogClose();
                      }}
                    >
                      {t("Confirm")}
                    </Button>
                    <Button className={classes.No} onClick={handleDialogClose}>
                      {t("Cancel")}
                    </Button>
                  </Box>
                </Stack>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {/* Drawer for unit details */}
      <React.Fragment key={"right"}>
        <Drawer anchor={"right"} open={drawerOpen} onClose={handleDrawerClose}>
          <UnitDetailsDrawer
            t={t}
            currency={currency}
            unitDetails={unitDetails}
            closeDrawer={handleDrawerClose}
          />
        </Drawer>
      </React.Fragment>
      <React.Fragment key={"right"}>
        <Drawer anchor={"right"} open={dialog} onClose={closeDrawer}>
          <AddUnit
            t={t}
            closeDrawer={closeDrawer}
            unitData={unitData}
            setUnitData={setUnitData}
            isEdit={true}
            pricing={existingPricing}
            getExternalUnits={getExternalUnits}
            companyId={companyId}
            currency={currency}
          />
        </Drawer>
      </React.Fragment>
    </Box>
  );
};
