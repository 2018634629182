import { Avatar, Box, Stack, Typography, Grid, DialogContent, Dialog } from '@mui/material'
import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { CourtDropdownStyle } from './style'
import { withTranslation } from 'react-i18next';
const CourtDropdown = (
    {
        openCourt = () => false,
        amenityList = [],
        selectedAmenity = {},
        handleAmenityChange = () => false,
        handleAmenityViewChange = () => false,
        t
    }
) => {
    const classes = CourtDropdownStyle({ listLength: amenityList?.length })
    const [open, setOpen] = React.useState(false)
    const showAll = () => setOpen(!open)
    const select = (e) => {
        handleAmenityChange(e)
        showAll()
    }
    const handleView = (e) => {
        handleAmenityViewChange(e)
        openCourt()
    }
    return (
        <Box>
            <Box p={1} ml={1} className={classes.dropdown}>
                <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent="space-between"
                    onClick={() => selectedAmenity?.label ? showAll() : false}>
                    <Avatar className={classes.avatar} src={selectedAmenity?.amenity_assets?.[0]}>
                        {/* {selectedAmenity?.label ? selectedAmenity?.label?.charAt(0) : "!"} */}
                    </Avatar>
                    <Typography className={classes.value} noWrap>{selectedAmenity?.label ?? "Not Available"}</Typography>
                    <span>
                        {
                            open ? <ExpandLessIcon /> : <ExpandMoreIcon />
                        }
                    </span>
                </Stack>
            </Box>
            <Dialog
                open={open}
                onClose={() => setOpen(!open)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.customDialog}
                fullWidth={true}
                maxWidth={500}
            >
                <DialogContent>
                    <Grid container>
                        {amenityList?.map((i) => {
                            return (
                                <Grid item md={4}>
                                    <Stack spacing={1}
                                        onClick={() => select(i)}
                                        direction={"row"} alignItems={"center"}
                                        className={selectedAmenity?.value === i?.value ? classes.selected_card : classes.card}>
                                        <Avatar
                                            src={i?.assets?.[0]?.url}
                                            onClick={() => select(i)}>
                                            {i?.label ? i?.label?.charAt(0) : ""}
                                        </Avatar>
                                        <Stack spacing={1}>
                                            <Typography className={classes.name}
                                                onClick={() => select(i)} noWrap sx={{ width: 100 }}>
                                                {i?.label}
                                            </Typography>
                                            <Typography className={classes.view}
                                                onClick={() => handleView(i)}>
                                                {t("View")}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            )
                        })}
                    </Grid>
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default withTranslation("amenityBooking")(CourtDropdown)
