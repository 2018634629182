import makeStyles from '@mui/styles/makeStyles';
import { FontFamilySwitch } from '../../utils';
export const useStyles = makeStyles((theme) => ({
    root: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        margin: "16px"
    },
    content1: {
        height: `calc(100vh - 220px)`,
        padding: "16px",
        overflow: "auto",
    },
    content2: {
        padding: "12px 16px",
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: theme.palette.borderRadius,
            padding: "0px",
        },
    },
    dialog1: {
        "& .MuiDialog-paper": {
            borderRadius: theme.palette.borderRadius,
            padding: "0px",
            backgroundColor: "transparent",
            boxShadow: "none"
        },

    },
    shortTitle: {
        fontSize:"1rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
    },
    btn1: {
        padding: "8px 18px",
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #CED3DD",
        boxShadow: "none",
        backgroundColor: "white",
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        "&:hover": {
            backgroundColor: "white",
        },
    },
    btn2: {
        padding: "8px 18px",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "none",
        fontSize:"0.875rem",

    },
    type: {
        backgroundColor: "white",
        //margin: "12px",
        padding: "18px",
        borderRadius: theme.palette.borderRadius,
        cursor: "pointer",
        height: "300px"
    },
    typename: {
        fontSize:"1rem",
        fontFamily: FontFamilySwitch().bold,
        marginBottom: "10px",
        marginTop: "10px",
    }
}))