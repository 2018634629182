/* eslint-disable react/jsx-no-target-blank */
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  Fab,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Skeleton,
  SpeedDialIcon,
  Stack,
  TableRow,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Pagination from "@mui/material/Pagination";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { withTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { CloseIconWithBG, DeleteIcon } from "../../assets";
import EditIMG from "../../assets/edit";
import InfoIcon from "../../assets/infoIcon";
import NoDataFound from "../../assets/noData";
import { CustomSelectOptions, DatePickerNew, TextBox } from "../../components";
import {
  LocalStorageKeys,
  convertTimeUtcToZoneCalander,
  currencyFormater,
  enumName,
  remCalc,
  stringAvatar,
  timeZoneConverter,
  FontFamilySwitch,
} from "../../utils";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { CustomCheckBox } from "../customeCheckBox";

const StyledTableCell = styled(TableCell)(({ theme, headingColor }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.typography.color.secondary,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    border: 0,
    backgroundColor: headingColor ?? "#F2F4F7",
    padding: "8px",
  },

  [`&.${tableCellClasses.body}`]: {
    color: theme.typography.color.primary,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().regular,
    // border: 0,
    whiteSpace: "normal",
    wordWrap: "break-word",
    backgroundColor: headingColor ?? "auto",
    padding: "0px 8px",
  },
}));

const useStyles = makeStyles((theme) => ({
  fab_icon_box: {
    position: "absolute",
    bottom: 8,
    display: "flex",
    width: "100%",
    justifyContent: "end",
  },
  fab: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#5078E1",
    boxShadow: "none",
    "&:hover": {
      background: "#5078E1",
    },
  },
  arrow: {
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    fontSize: "1rem",
    color: "black",
  },
  button: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    margin: 10,
  },
  paginate: {
    direction: "ltr",
    "& .MuiButtonBase-root.MuiPaginationItem-root": {
      borderRadius: theme.palette.borderRadius,
      border: "none",
      color: "#606060",
    },
    "& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected": {
      color: "white",
      borderRadius: theme.palette.borderRadius,
      border: "none",
      backgroundColor: "#5078E1",
    },
  },
  pagination: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  typo: {
    fontSize: "0.875rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().bold,
    fontWeight: "bold",
    margin: 0,
    overflow: "hidden",
  },
  tabletopbtn: {
    fontWeight: "lighter",
    boxShadow: "none",
    marginLeft: "6px",
    backgroundColor: "#F8F8F8",
    border: "1px solid #E9E9E9",
    borderRadius: "6px",
    color: "black",

    "&:nth-child(1)": {
      textTransform: "capitalize",
    },
    "&:hover": {
      backgroundColor: "#F8F8F8",
      border: "1px solid #E9E9E9",
      borderRadius: "6px",
      boxShadow: "none",
    },
  },
  root: {
    whiteSpace: "noWrap",
    "& .MuiTableContainer-root": {
      marginTop: (props) => props?.marginTop,
      "&::-webkit-scrollbar": {
        height: 6,
      },
      "& table": {
        borderCollapse: "separate",
      },
    },
  },
  flexRow: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  change: {
    textDecoration: "underline",
    color: "#4D7EFF",
    cursor: "pointer",
    marginLeft: 70,
  },
  thead: {
    zIndex: 1,
    backgroundColor: "#F2F4F7",
    "& th": {
      zIndex: 1,
      textAlign: "-webkit-match-parent",
    },
    "& th:first-child": {
      borderRadius: (props) =>
        props.tableType === "normal" ? "4px 0px 0px 4px" : "4px 0 0 0",
    },
    "& th:last-child": {
      borderRadius: (props) =>
        props.tableType === "normal" ? "0 4px 4px 0" : "0 4px 0 0",
    },
  },
  tbody: (props) => ({
    height: "48px",
    cursor: "pointer",
    textAlign: "-webkit-match-parent",
    "&.Mui-selected": {
      backgroundColor: props?.selectedRowColor ?? "#F1F7FF",
    },
    "&:hover": {
      backgroundColor: "#F1F7FF",
    }, // border: (props.tableType === "normal" ? 0 : "1px solid #E4E8EE"),
    backgroundColor: props?.cellBackground
      ? props?.cellBackground
      : props.tableType === "normal"
      ? "#F2F4F7"
      : "white",
    "& th": {
      borderBlock: props.tableType === "normal" ? 0 : "0.5px solid #E4E8EE",
      textAlign: "-webkit-match-parent",
    },
    "& th:first-child": {
      borderRadius: props.tableType === "normal" ? "4px 0px 0px 4px" : 0,
      borderInlineStart:
        props.tableType === "normal"
          ? 0
          : props?.tableType === "no-side"
          ? 0
          : "1px solid #E4E8EE",
    },
    "& th:last-child": {
      borderRadius: props.tableType === "normal" ? "0 4px 4px 0" : 0,
      borderInlineEnd:
        props.tableType === "normal"
          ? 0
          : props?.tableType === "no-side"
          ? 0
          : "0.5px solid #E4E8EE",
    },
  }),
  select: {
    color: "red",
    "& .MuiSelect-select": {
      paddingBlock: 0,
      fontSize: "0.875rem",
      color: "#4E5A6B",
      fontFamily: FontFamilySwitch().bold,
      fontWeight: "bold",
      height: 20,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
  },
  noRecordDiv: {
    textAlign: "center",
    marginTop: 30,
    [theme.breakpoints.down("md")]: {
      height: (props) => props?.mdHeight ?? props?.height,
    },
  },
  mdHeight: {
    [theme.breakpoints.down("md")]: {
      minHeight: (props) => props?.mdHeight ?? props?.height,
      maxHeight: (props) => props?.mdHeight ?? props?.height,
    },
  },

  menu: {
    "& .MuiPopover-paper": {
      boxShadow: "0 0px 8px -4px #f2f3f5",
      border: "1px solid #f2f3f5",
      borderRadius: "5px",
    },
  },
  active: {
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.875rem",
    color: "#5AC782",
  },
  inprogress: {
    padding: "4px 6px",
    backgroundColor: "#78B1FE",
    borderRadius: theme.palette.borderRadius,
    display: "inline",
    color: "white",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular,
  },

  activego: {
    padding: "4px 6px",
    backgroundColor: "#5AC782",
    borderRadius: theme.palette.borderRadius,
    display: "inline",
    color: "white",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular,
  },
  inactivego: {
    padding: "4px 6px",
    backgroundColor: "#CED3DD",
    borderRadius: theme.palette.borderRadius,
    display: "inline",
    color: "white",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular,
  },
  inactive: {
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.875rem",
    color: "red",
    whiteSpace: "nowrap",
  },
  more: {
    fontSize: "1.25rem",
    color: "gray",
    cursor: "pointer",
  },
  menuList: {
    "& .MuiPopover-paper": {
      boxShadow: "0px 0px 6px #0717411F",
      borderRadius: theme.palette.borderRadius,
      backgroundColor: "white",
    },
  },
  menuItem: {
    borderBottom: "1px solid #E4E8EE",
    margin: "0px 4px",
    fontSize: "0.875rem",
    color: "#071741",
    fontFamily: FontFamilySwitch().semiBold,
    "&:last-child": {
      border: 0,
    },
  },
  delete_menu_item: {
    borderBottom: "1px solid #E4E8EE",
    margin: "0px 4px",
    fontSize: "0.875rem",
    color: "#FF4B4B",
    fontFamily: FontFamilySwitch().regular,
    "&:last-child": {
      border: 0,
    },
  },
  text: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.875rem",
    color: "#091B29",
    textTransform: "capitalize",
    direction: "ltr",
    textAlign: "-webkit-match-parent",
    "& .ql-editor": {
      padding: "0px",
      color: theme.typography.color.primary,
      fontSize: "0.875rem",
      fontFamily: FontFamilySwitch().semiBold,
      textTransform: "capitalize",
    },
  },
  tooltip: {
    "&:hover": {
      backgroundColor: theme.typography.color.white,
    },
  },
  qstatus: {
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.875rem",
    textTransform: "capitalize",
    textAlign: "center",
    borderRadius: "4px",
    padding: "4px",
    color: "white",
  },
  infoIcon: {
    color: "#4E5A6B",
    fontSize: "1.25rem",
  },
  yet: {
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.875rem",
    color: "#FF9340",
    textTransform: "capitalize",
  },
  link: {
    textDecoration: "underline",
    color: "#5078E1",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().regular,
  },
  moreBtn: {
    // float: "right"
  },
  red: {
    color: "white",
    backgroundColor: "red",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().regular,
    textAlign: "center",
    borderRadius: "4px",
    padding: "4px",
  },
  customRed: {
    color: "#E83232",
    fontSize: "0.875rem",
    backgroundColor: "#FDE8E8",
    fontFamily: FontFamilySwitch().regular,
    textAlign: "center",
    borderRadius: "3px",
    padding: "5px 10px",
    border: "1px solid #E83232",
  },
  lightBlue: {
    color: "white",
    backgroundColor: "#78B1FE",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().regular,
    textAlign: "center",
    borderRadius: "4px",
    padding: "4px",
  },
  green: {
    color: "white",
    backgroundColor: "#5AC782",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().regular,
    textAlign: "center",
    borderRadius: "4px",
    padding: "4px",
  },
  yellow: {
    color: "white",
    backgroundColor: "#FAD500",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().regular,
    textAlign: "center",
    borderRadius: "4px",
    padding: "4px",
  },
  subTotal: {
    color: theme.typography.color.primary,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    // border: 0,
    whiteSpace: "normal",
    wordWrap: "break-word",
    padding: "0px 8px",
  },
  assignstatus: {
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.875rem",
    color: "#78B1FE",
    textTransform: "capitalize",
  },
  rejectedstatus: {
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.875rem",
    color: "#FF4B4B",
    textTransform: "capitalize",
  },
  rejectedStatusBtn: {
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.875rem",
    color: "#FF4B4B",
    textTransform: "capitalize",
    border: "1px solid #E4E8EE",

    "&:hover": {
      backgroundColor: "white",
      border: "1px solid #E4E8EE",
    },
  },
  completeStatus: {
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.875rem",
    color: "#5AC782",
    textTransform: "capitalize",
  },
  rejectedStatus: {
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.875rem",
    color: "#FF4B4B",
    textTransform: "capitalize",
  },
  date: {
    color: "#98A0AC",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  role: {
    fontSize: "0.875rem",
    marginBottom: "10px",
    // backgroundColor: "#EBFBFF",
    color: "#2CA2BF",
    borderRadius: theme.palette.borderRadius,
    padding: "5px",
    width: "fit-content",
  },
  revoke_button: {
    padding: "8px 12px",
    borderRadius: theme.palette.borderRadius,
    border: "1px solid #E4E8EE",
    "&:hover": {
      backgroundColor: theme.palette.background.tertiary1,
    },
  },
  revoke_text: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },
  file_ext_text: {
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.875rem",
    color: "#091B29",
    "& .ql-editor": {
      color: theme.typography.color.primary,
      fontSize: "0.875rem",
      fontFamily: FontFamilySwitch().regular,
    },
  },
  backup_and_restore_status: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.875rem",
    textTransform: "capitalize",
  },
  warningRedCard: {
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "#FFECEC",
    padding: "4px",
  },
  warningRedText: {
    fontSize: remCalc(12),
    fontFamily: FontFamilySwitch().semiBold,
    color: "#FF4B4B",
  },
  dot: {
    height: "6px",
    width: "6px",
    background: theme.palette.background.primary,
    borderRadius: "50%",
  },
  cardTitle: {
    fontSize: remCalc(12),
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.primary,
  },
  cardSubTitle: {
    fontSize: remCalc(12),
    fontFamily: FontFamilySwitch().regular,
    color: theme.typography.color.secondary,
  },
  cardAvatar: {
    height: 32,
    width: 32,
    borderRadius: "8px",
  },
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px",
    padding: "12px",
    backgroundColor: "#FFFFFF",
    color: "#4E5A6B",
    border: "1px solid #E4E8EE",
    boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.04)",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
}));

const LongTextTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "400px",
    borderRadius: "4px",
    padding: "8px 12px",
    backgroundColor: "#FFFFFF",
    color: "#1C1C1C",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#FFFFFF",
  },
}));

const TableComponent = (props) => {
  const classes = useStyles(props);
  const { t } = props;
  const totalCount = props?.totalRowsCount > -1 ? props?.totalRowsCount : "";
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [index, setIndex] = React.useState(null);
  const open = Boolean(anchorEl);
  const disable_click_cases = [
    "more",
    "more_2",
    "unit",
    "block",
    "textLink",
    "floor",
    "more_3",
    "info",
    "checkBox",
    "more_4",
    "more_data",
    "inspection_status",
    "inputBox",
    "view_more",
    "more_appraisal",
    "more_5",
    "file_manager_option",
    "correspondence_option",
    "revoke",
    "import_table_option",
  ];

  const handleClose = () => {
    setAnchorEl(null);
  };
  const modules = {
    toolbar: false,
  };
  const getComponentType = (val, data) => {
    const main = data.main;

    switch (data.type) {
      case "status": {
        if (
          data.value === "Approved" ||
          data?.value === "no-due" ||
          data?.value === "Settled" ||
          data?.value === "No-Due" ||
          data?.value === "Assigned" ||
          data?.value === "Completed"
        ) {
          return (
            <p style={{ color: "#267C24", margin: "0", whiteSpace: "nowrap" }}>
              {data.value}
            </p>
          );
        } else if (
          data.value === "Rejected" ||
          data?.value === "Terminated" ||
          data?.value === "due" ||
          data?.value === "Unsettled" ||
          data?.value === "Due"
        ) {
          return (
            <p style={{ color: "red", margin: "0", whiteSpace: "nowrap" }}>
              {data.value}
            </p>
          );
        } else if (
          data.value === "Pending" ||
          data?.value === "Yet to assign" ||
          data?.value === "Yet To Verify"
        ) {
          return (
            <p style={{ color: "#78B1FE", margin: "0", whiteSpace: "nowrap" }}>
              {data.value}
            </p>
          );
        } else if (data.value === "Expired" || data?.value === "Achieved") {
          return (
            <p style={{ color: "#4E5A6B", margin: "0", whiteSpace: "nowrap" }}>
              {data.value}
            </p>
          );
        } else if (data.value === "Lost Lead" || data.value === "Disqualify") {
          return (
            <p
              className={classes.red}
              style={{ margin: "0", whiteSpace: "nowrap" }}
            >
              {data.value}
            </p>
          );
        } else if (data.value === "In Progress") {
          return (
            <Typography
              className={classes.green}
              style={{ margin: "0" }}
              width="max-content"
              noWrap
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "Converted" || data.value === "Qualify") {
          return (
            <p className={classes.lightBlue} style={{ margin: "0" }}>
              {data.value}
            </p>
          );
        } else if (data.value === "Cancelled") {
          return (
            <p
              style={{
                backgroundColor: "#98A0AC",
                margin: "0",
                color: "#fff",
                borderRadius: "4px",
                padding: "4px",
              }}
            >
              {data.value}
            </p>
          );
        } else if (data.value === "Yet To Activate") {
          return <p style={{ color: "#FF9340", margin: "0" }}>{data.value}</p>;
        } else if (data.value === "Active") {
          return (
            <span className={classes.green} style={{ margin: "0" }}>
              {data.value}
            </span>
          );
        } else if (
          data.value === "InActive" ||
          data.value === "In-Active" ||
          data.value === "Cancelled"
        ) {
          return <p style={{ color: "#FF4B4B", margin: "0" }}>{data.value}</p>;
        } else if (data.value === "Inactive") {
          return <p style={{ color: "#FF4B4B", margin: "0" }}>{data.value}</p>;
        } else if (data.value === "Quote Accepted") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#5AC782",
                fontSize: "0.75rem",
                fontFamily: FontFamilySwitch().regular,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "open") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#5AC782",
                fontSize: "0.75rem",
                fontFamily: FontFamilySwitch().regular,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "close" || data?.value === "closed") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#CED3DD",
                fontSize: "0.75rem",
                fontFamily: FontFamilySwitch().regular,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Closed") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "red",
                fontSize: "0.75rem",
                fontFamily: FontFamilySwitch().regular,
                textAlign: "center",
                borderRadius: "4px",
                padding: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Open") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#5AC782",
                fontSize: "0.75rem",
                fontFamily: FontFamilySwitch().regular,
                textAlign: "center",
                borderRadius: "4px",
                padding: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "requested for visit") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#FF9340",
                fontSize: "0.75rem",
                fontFamily: FontFamilySwitch().regular,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "requested for re-quote accepted") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#FF9340",
                fontSize: "0.75rem",
                fontFamily: FontFamilySwitch().regular,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "inProgress") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#78B1FE",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().regular,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "In Progress") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#78B1FE",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().regular,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "Active") {
          return (
            <Typography className={classes.green} sx={{ margin: "0" }}>
              {data.value}
            </Typography>
          );
        } else if (data.value === "Paid") {
          return (
            <Typography className={classes.green} sx={{ margin: "0" }}>
              {data.value}
            </Typography>
          );
        } else if (data.value === "Vacant" || data.value === "Booked") {
          return (
            <span className={classes.green} sx={{ margin: "0" }}>
              {data.value}
            </span>
          );
        } else if (data.value === "Reserved") {
          return (
            <span className={classes.yellow} sx={{ margin: "0" }}>
              {data.value}
            </span>
          );
        } else if (data.value === "Occupied") {
          return (
            <span className={classes.red} sx={{ margin: "0" }}>
              {data.value}
            </span>
          );
        } else if (data.value === "Inactive") {
          return (
            <Typography className={classes.red} sx={{ margin: "0" }}>
              {data.value}
            </Typography>
          );
        } else if (data.value === "Unpaid") {
          return (
            <Typography className={classes.customRed} sx={{ margin: "0" }}>
              {data.value}
            </Typography>
          );
        } else if (data.value === "delete") {
          return (
            <div>
              {props?.delete && (
                <IconButton onClick={() => props.handleIcon("delete", main)}>
                  <DeleteForeverIcon color="primary" />
                </IconButton>
              )}
            </div>
          );
        } else if (data.value === true) {
          return (
            <Typography className={classes.active} sx={{ margin: "0" }}>
              {t("Active")}
            </Typography>
          );
        } else if (data.value === false) {
          return (
            <Typography className={classes.inactive} sx={{ margin: "0" }}>
              {t("Inactive")}
            </Typography>
          );
        } else if (data?.value === "Agreement generated") {
          return (
            <Typography
              sx={{
                color: "#5AC782",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().semiBold,
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Resident Onboarded") {
          return (
            <Typography
              sx={{
                color: "#78B1FE",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().semiBold,
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Cancelled") {
          return (
            <Typography
              sx={{
                color: "#FF4B4B",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().semiBold,
              }}
            >
              {data.value}
            </Typography>
          );
        } else {
          return data.value ?? "-";
        }
      }
      case "status2": {
        if (data?.value === "Closed") {
          return (
            <Typography
              sx={{
                color: "#000",
                backgroundColor: "#F2F4F7",
                fontSize: "0.75rem",
                fontFamily: FontFamilySwitch().regular,
                textAlign: "center",
                borderRadius: "4px",
                padding: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Open") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#5AC782",
                fontSize: "0.75rem",
                fontFamily: FontFamilySwitch().regular,
                textAlign: "center",
                borderRadius: "4px",
                padding: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else {
          return data.value;
        }
      }
      case "maintenanceRequestStatus": {
        if (data?.value === "Closed") {
          return (
            <Typography className={classes.green} sx={{ margin: "0" }}>
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Open") {
          return (
            <Typography className={classes.red} sx={{ margin: "0" }}>
              {data.value}
            </Typography>
          );
        } else {
          return data.value;
        }
      }
      case "maintenance_status": {
        if (data?.value === "Open") {
          return (
            <Typography
              sx={{
                color: "#78B1FE",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().semiBold,
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Resolved") {
          return (
            <Typography
              sx={{
                color: "#5AC782",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().semiBold,
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Cancelled") {
          return (
            <Typography
              sx={{
                color: "#FF4B4B",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().semiBold,
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Reopened") {
          return (
            <Typography
              sx={{
                color: "#FF9340",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().semiBold,
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else {
          return (
            <Typography
              sx={{
                color: "#ffd700",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().semiBold,
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        }
      }
      case "object_status": {
        if (data?.value.value === enumName.approved) {
          return (
            <Typography
              sx={{
                color: "#5AC782",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().semiBold,
                margin: "0",
              }}
              noWrap
            >
              {data.value.label}
            </Typography>
          );
        } else if (data?.value.value === enumName.cancelled) {
          return (
            <Typography
              sx={{
                color: "#4E5A6B",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().semiBold,
                margin: "0",
              }}
              noWrap
            >
              {data.value.label}
            </Typography>
          );
        } else if (data?.value.value === enumName.pending) {
          return (
            <Typography
              sx={{
                color: "#FF9340",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().semiBold,
                margin: "0",
              }}
              noWrap
            >
              {data.value.label}
            </Typography>
          );
        } else if (data?.value.value === enumName.rejected) {
          return (
            <Typography
              sx={{
                color: "#FF4B4B",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().semiBold,
                margin: "0",
              }}
              noWrap
            >
              {data.value.label}
            </Typography>
          );
        } else {
          return data.value;
        }
      }

      case "kyc_status": {
        if (
          data.value === "Approved" ||
          data.value === "Verified" ||
          data.value === "approved" ||
          data.value === "Reconcile" ||
          data?.value === "Published"
        ) {
          return (
            <p
              style={{
                color: "#5AC782",
                margin: "0",
                background: "#EEF9EE",
                width: 90,
                padding: 4,
                textAlign: "center",
                borderRadius: 4,
                fontWeight: "bold",
              }}
            >
              {data.value.charAt(0).toUpperCase() + data?.value?.slice(1)}
            </p>
          );
        } else if (
          data.value === "Declined" ||
          data.value === "declined" ||
          data.value === "Rejected"
        ) {
          return (
            <p
              style={{
                color: "#FF4B4B",
                margin: "0",
                background: "#FFECEC",
                width: 90,
                padding: 4,
                textAlign: "center",
                borderRadius: 4,
                fontWeight: "bold",
              }}
            >
              {data.value.charAt(0).toUpperCase() + data?.value?.slice(1)}
            </p>
          );
        } else if (
          data.value === "Pending" ||
          data.value === "Yet to be Verified" ||
          data.value === "pending" ||
          data.value === "Draft" ||
          data?.value === "In-Draft"
        ) {
          return (
            <p
              style={{
                color: "#FF9340",
                margin: "0",
                background: "#FF93401A",
                width: data.value === "Yet to be Verified" ? 150 : 90,
                padding: 4,
                textAlign: "center",
                borderRadius: 4,
                fontWeight: "bold",
              }}
            >
              {data.value.charAt(0).toUpperCase() + data?.value?.slice(1)}
            </p>
          );
          // } else if (data.value === "Lost Lead") {
          //   return <p style={{ color: "red", margin: '0' }}>{data.value}</p>;
        } else if (data.value === "Lost Lead") {
          return (
            <p className={classes.red} style={{ margin: "0" }}>
              {data.value}
            </p>
          );
        } else if (data.value === "In Progress") {
          return (
            <Typography
              className={classes.green}
              style={{ margin: "0" }}
              width="max-content"
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "Converted") {
          return (
            <p className={classes.lightBlue} style={{ margin: "0" }}>
              {data.value}
            </p>
          );
        } else if (data.value === "Cancelled") {
          return <p style={{ color: "#98A0AC", margin: "0" }}>{data.value}</p>;
        } else if (data.value === "Yet To Activate") {
          return <p style={{ color: "#FF9340", margin: "0" }}>{data.value}</p>;
        } else if (data.value === "Active") {
          return (
            <span className={classes.green} style={{ margin: "0" }}>
              {data.value}
            </span>
          );
        } else if (data.value === "InActive") {
          return <p style={{ color: "#FF4B4B", margin: "0" }}>{data.value}</p>;
        } else if (data.value === "Quote Accepted") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#5AC782",
                fontSize: "0.75rem",
                fontFamily: FontFamilySwitch().regular,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "open") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#5AC782",
                fontSize: "0.75rem",
                fontFamily: FontFamilySwitch().regular,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "close" || data?.value === "closed") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#CED3DD",
                fontSize: "0.75rem",
                fontFamily: FontFamilySwitch().regular,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Closed") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "red",
                fontSize: "0.75rem",
                fontFamily: FontFamilySwitch().regular,
                textAlign: "center",
                borderRadius: "4px",
                padding: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Open") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#5AC782",
                fontSize: "0.75rem",
                fontFamily: FontFamilySwitch().regular,
                textAlign: "center",
                borderRadius: "4px",
                padding: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "requested for visit") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#FF9340",
                fontSize: "0.75rem",
                fontFamily: FontFamilySwitch().regular,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "requested for re-quote accepted") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#FF9340",
                fontSize: "0.75rem",
                fontFamily: FontFamilySwitch().regular,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "inProgress") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#78B1FE",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().regular,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "In Progress") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#78B1FE",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().regular,
                textAlign: "center",
                borderRadius: "4px",
                margin: "0",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "Active") {
          return (
            <Typography className={classes.green} sx={{ margin: "0" }}>
              {data.value}
            </Typography>
          );
        } else if (data.value === "Paid") {
          return (
            <Typography className={classes.active} sx={{ margin: "0" }}>
              {data.value}
            </Typography>
          );
        } else if (data.value === "Vacant") {
          return (
            <span className={classes.green} sx={{ margin: "0" }}>
              {data.value}
            </span>
          );
        } else if (data.value === "Occupied") {
          return (
            <span className={classes.red} sx={{ margin: "0" }}>
              {data.value}
            </span>
          );
        } else if (data.value === "Inactive") {
          return (
            <Typography className={classes.red} sx={{ margin: "0" }}>
              {data.value}
            </Typography>
          );
        } else if (data.value === "Unpaid") {
          return (
            <Typography className={classes.red} sx={{ margin: "0" }}>
              {data.value}
            </Typography>
          );
        } else if (data.value === "delete") {
          return (
            <div>
              {props?.delete && (
                <IconButton onClick={() => props.handleIcon("delete", main)}>
                  <DeleteForeverIcon color="primary" />
                </IconButton>
              )}
            </div>
          );
        } else if (data.value === true) {
          return (
            <Typography className={classes.active} sx={{ margin: "0" }}>
              {t("Active")}
            </Typography>
          );
        } else if (data.value === false) {
          return (
            <Typography className={classes.inactive} sx={{ margin: "0" }}>
              {t("Inactive")}
            </Typography>
          );
        } else {
          return data.value;
        }
      }

      case "publish_button": {
        return (
          data?.main?.status !== "Published" && (
            <Button
              sx={{ boxShadow: "none", height: 30 }}
              onClick={(e) => {
                e?.stopPropagation();
                props.handlePublish(data?.main);
              }}
              disabled={props?.isDisableBtn}
              variant="contained"
            >
              {t("Publish")}
            </Button>
          )
        );
      }
      case "submit_button": {
        return (
          <Button
            sx={{ boxShadow: "none", height: 30 }}
            onClick={(e) => {
              e?.stopPropagation();
              props.handleSubmit(data?.main);
            }}
            disabled={
              data?.main?.is_reported ? true : !data?.main?.is_configured
            }
            variant="contained"
          >
            {t("Submit")}
          </Button>
        );
      }
      case "configure_button": {
        return (
          <Button
            sx={{ boxShadow: "none", height: 30 }}
            onClick={(e) => {
              e?.stopPropagation();
              if (data?.main?.is_configured) {
                props.handleIcon("edit", data?.main);
              } else {
                props.handleConfiguration(data?.main);
              }
            }}
            variant="contained"
            // disabled={data?.main?.is_configured}
          >
            {data?.main?.is_configured
              ? t("Edit (Configured)")
              : t("Configure")}
          </Button>
        );
      }

      case "icon": {
        let editviewtoggle = (
          <div style={{ display: "flex" }}>
            {props?.edit && (
              <IconButton onClick={() => props.handleIcon("edit", main)}>
                <EditIMG color="#98a0ac" />
              </IconButton>
            )}
            {props?.view && (
              <IconButton onClick={() => props.handleIcon("view", main)}>
                <RemoveRedEyeOutlinedIcon
                  sx={{ fontSize: "1rem", color: "#98A0AC" }}
                />
              </IconButton>
            )}

            {props?.edit && (
              <Switch
                checked={data.is_active}
                onChange={(e) =>
                  props.handleIcon("toggle", main, e.target.checked)
                }
              />
            )}
          </div>
        );
        let editview = (
          <div style={{ display: "flex" }}>
            {props?.edit && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  props.handleIcon("edit", main);
                }}
              >
                <EditIMG color="#98a0ac" />
              </IconButton>
            )}
            {props?.view && (
              <IconButton onClick={() => props.handleIcon("view", main)}>
                <RemoveRedEyeOutlinedIcon
                  sx={{ fontSize: "1rem", color: "#98A0AC" }}
                />
              </IconButton>
            )}
          </div>
        );
        let deleteIcon = (
          <div style={{ display: "flex" }}>
            {props?.delete && (
              <IconButton onClick={() => props.handleIcon("delete", main)}>
                <DeleteOutlineIcon
                  sx={{ fontSize: "1rem", color: "#98A0AC" }}
                />
              </IconButton>
            )}
          </div>
        );
        let viewdelete = (
          <div style={{ display: "flex" }}>
            {props?.edit && (
              <IconButton onClick={() => props.handleIcon("edit", main)}>
                <EditIMG color="#98a0ac" />
              </IconButton>
            )}
            {props?.delete && (
              <IconButton onClick={() => props.handleIcon("delete", main)}>
                <DeleteOutlineIcon
                  sx={{ fontSize: "1rem", color: "#98A0AC" }}
                />
              </IconButton>
            )}
          </div>
        );
        let edittoggle = (
          <div style={{ display: "flex" }}>
            {props?.edit && (
              <IconButton
                onClick={() => props.handleIcon("edit", main, props?.edit)}
              >
                <EditIMG color="#98a0ac" />
              </IconButton>
            )}
            {props?.edit && (
              <Switch
                checked={main.is_active}
                onChange={(e) => props.handleIcon("toggle", main, props?.edit)}
              />
            )}
          </div>
        );
        let viewedittoggle = (
          <div style={{ display: "flex" }}>
            {props?.edit && (
              <IconButton
                onClick={() => props.handleIcon("edit", main, props?.edit)}
              >
                <EditIMG color="#98a0ac" />
              </IconButton>
            )}
            {props?.view && (
              <IconButton
                onClick={() => props.handleIcon("view", main, props?.view)}
              >
                <RemoveRedEyeOutlinedIcon
                  sx={{ fontSize: "1rem", color: "#98A0AC" }}
                />
              </IconButton>
            )}
            {props?.edit && (
              <Switch
                checked={main.is_active}
                onChange={(e) => props.handleIcon("toggle", main, props?.edit)}
              />
            )}
          </div>
        );
        let vieweditresident = (
          <div style={{ display: "flex" }}>
            {props?.edit && (
              <IconButton
                onClick={() => props.handleIcon("edit", main, props?.edit)}
              >
                <EditIMG color="#98a0ac" />
              </IconButton>
            )}
            {props?.view && (
              <IconButton
                onClick={() => props.handleIcon("view", main, props?.view)}
              >
                <RemoveRedEyeOutlinedIcon
                  sx={{ fontSize: "1rem", color: "#98A0AC" }}
                />
              </IconButton>
            )}
          </div>
        );
        let viewPdf = (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              props.handleIcon("view_pdf", main);
            }}
          >
            <RemoveRedEyeOutlinedIcon
              sx={{ fontSize: "1rem", color: "#98A0AC" }}
            />
          </IconButton>
        );
        if (data?.icon === "view_pdf") {
          return viewPdf;
        }
        if (data.icon === "editviewtoggle") {
          return editviewtoggle;
        }
        if (data.icon === "deleteIcon") {
          return deleteIcon;
        }
        if (data.icon === "viewedittoggle") {
          return viewedittoggle;
        }
        if (data.icon === "edittoggle") {
          return edittoggle;
        }
        if (data.icon === "viewdelete") {
          return viewdelete;
        }
        if (data.icon === "editresident") {
          return vieweditresident;
        }
        if (data.icon === "editview") {
          return editview;
        } else if (data.icon === "edit") {
          return (
            <div>
              {props?.edit && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    props.handleIcon("edit", main, props?.edit);
                  }}
                >
                  <EditIMG color="#98a0ac" />
                </IconButton>
              )}
            </div>
          );
        } else if (data.icon === "view") {
          return (
            <div>
              {props?.view && (
                <IconButton onClick={() => props.handleIcon("view", main)}>
                  <RemoveRedEyeOutlinedIcon
                    sx={{ fontSize: "1rem", color: "#98A0AC" }}
                  />
                </IconButton>
              )}
            </div>
          );
        } else if (data.icon === "arrow") {
          return (
            <div style={{ display: "flex" }}>
              <IconButton size="small" className={classes.arrow}>
                <ArrowForwardIosIcon
                  size="small"
                  style={{ fontSize: "1rem" }}
                />
              </IconButton>
            </div>
          );
        }

        break;
      }
      case "leadEdit": {
        return (
          <>
            {props?.view && (
              <IconButton onClick={() => props.handleIcon("view", main)}>
                <RemoveRedEyeOutlinedIcon
                  sx={{ fontSize: "1rem", color: "#98A0AC" }}
                />
              </IconButton>
            )}
          </>
        );
      }
      case "propertyType": {
        return (
          <>
            {data.value && (
              <Typography
                sx={{
                  color: "white",
                  backgroundColor: "#78B1FE",
                  fontSize: "0.875rem",
                  fontFamily: FontFamilySwitch().regular,
                  textAlign: "center",
                  borderRadius: "4px",
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;&nbsp;{data.value}&nbsp;&nbsp;&nbsp;
              </Typography>
            )}
          </>
        );
      }
      case "blockType": {
        return (
          <>
            {data?.value ? (
              <Typography
                sx={{
                  color: "white",
                  backgroundColor: "#78B1FE",
                  fontSize: "0.875rem",
                  fontFamily: FontFamilySwitch().regular,
                  textAlign: "center",
                  borderRadius: "4px",
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;&nbsp;{data?.value}&nbsp;&nbsp;&nbsp;
              </Typography>
            ) : (
              <Typography
                style={{
                  whiteSpace: "nowrap",
                }}
                className={classes.text}
              >
                {"-"}
              </Typography>
            )}
          </>
        );
      }
      case "checkBox": {
        return (
          <div>
            {props?.edit && (
              <Checkbox
                readOnly={data?._?.disabled}
                sx={{ padding: "0px" }}
                onChange={(e) =>
                  props.handleIcon("checkBox", main, e?.target?.checked)
                }
                color="success"
                checked={data.value}
                checkedIcon={<CheckCircleIcon />}
                icon={<CircleOutlinedIcon style={{ color: "#c1c1c1" }} />}
              />
            )}
          </div>
        );
      }
      case "customCheckBox": {
        return (
          <div style={{ paddingLeft: data?._?.paddingLeft ?? "auto" }}>
            {props?.edit && (
              <Checkbox
                readOnly={data?._?.disabled}
                sx={{ padding: "0px" }}
                onChange={(e) =>
                  props.handleIcon(
                    "customCheckBox",
                    main,
                    e?.target?.checked,
                    data?._?.name
                  )
                }
                color="primary"
                checked={data.value}
                // checkedIcon={<CheckCircleIcon />}
                // icon={<CircleOutlinedIcon style={{ color: "#c1c1c1" }} />}
              />
            )}
          </div>
        );
      }
      case "customCard": {
        return (
          <Stack direction={"row"} alignItems={"center"} columnGap={1}>
            <Avatar
              className={classes.cardAvatar}
              {...stringAvatar(data?.main?.card?.title)}
            ></Avatar>
            <Stack>
              <Typography className={classes.cardTitle}>
                {data?.main?.card?.title}
              </Typography>
              <Stack direction={"row"} alignItems="center" columnGap={1}>
                <Typography className={classes.cardSubTitle}>
                  {data?.main?.card?.subTitle1}
                </Typography>
                <Box className={classes.dot}></Box>
                <Typography className={classes.cardSubTitle}>
                  {data?.main?.card?.subTitle2}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        );
      }
      case "tooltip": {
        return (
          <>
            <Button
              aria-describedby={props?.id}
              className={classes.tooltip}
              onClick={(e) => {
                e.stopPropagation();
                props?.handleHover(e, main);
              }}
              // onMouseLeave={props?.handleClose}
            >
              {data.value}
            </Button>
            <Popover
              className={classes.menu}
              style={{ boxShadow: "none !important" }}
              id={props?.id}
              open={props?.open}
              anchorEl={props?.anchorEl}
              onClose={props?.handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {props?.roles?.map((val) => {
                return <Typography sx={{ p: 1 }}>{val?.name}</Typography>;
              })}
            </Popover>
          </>
        );
      }
      case "avatar": {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={data.image} sx={{ width: 34, height: 34 }} />
            <Typography style={{ marginLeft: 10 }}>{data.value}</Typography>
          </div>
        );
      }
      case "avatarText": {
        return (
          <Stack direction={"row"} alignItems={"center"} columnGap={"12px"}>
            <Avatar
              src={data.value.image ?? data.main.image}
              sx={{
                width: 34,
                height: 34,
                lineHeight: 0,
                fontSize: "0.875rem",
              }}
            >
              {data?.value?.name ? data?.value?.name?.charAt(0) : ""}
            </Avatar>
            <Typography className={classes.text}>
              {data.value.name ?? data.main.name}
            </Typography>
          </Stack>
        );
      }
      case "avatarTextDashboard": {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src={data?.value?.image ?? ""}
              sx={{
                width: 34,
                height: 34,
                lineHeight: 0,
                fontSize: "0.875rem",
              }}
              {...stringAvatar(main?.name)}
            />
          </div>
        );
      }
      case "avatarmanagement": {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={data.value} sx={{ width: 34, height: 34 }} />
          </div>
        );
      }
      case "text": {
        return (
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
            className={classes.text}
          >
            {data.value ?? "-"}
          </Typography>
        );
      }
      case "mile_text": {
        return (
          <Tooltip title={data.value ?? "-"} arrow>
            <Typography
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "150px",
                display: "inline-block",
              }}
              className={classes.text}
            >
              {data.value ?? "-"}
            </Typography>
          </Tooltip>
        );
      }
      case "community_text": {
        return (
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
            className={classes.text}
          >
            {data.value === true ? "True" : "False"}
          </Typography>
        );
      }

      case "long_text": {
        const placement = data?._?.tooltip_placement ?? "right";
        const maxWidth = data?._?.max_width ?? "200px";

        return (
          <div style={{ maxWidth, display: "flex" }}>
            <LongTextTooltip
              title={data?.value ?? ""}
              placement={placement}
              arrow
            >
              <Typography
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
                className={classes.text}
              >
                {data?.value ?? "-"}
              </Typography>
            </LongTextTooltip>
          </div>
        );
      }

      case "textObject": {
        return (
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
            className={classes.text}
          >
            {data?.value?.label ?? "-"}
          </Typography>
        );
      }
      case "chip": {
        return (
          <Chip
            label={data?.value ?? "-"}
            style={{
              fontWeight: !props?.postingTypeProps?.textWeight
                ? "bold"
                : "normal",
              backgroundColor:
                data?.value === "Proxy"
                  ? "#9987c2"
                  : data?.value === "ERP"
                  ? "#e69a2d"
                  : data?.value === "PG2"
                  ? "#33ace4"
                  : props?.postingTypeProps?.bgColor,
              color: props?.postingTypeProps?.textColor ?? "#FFFFFF",
              border: props?.postingTypeProps?.color ?? "none",
              padding: props?.postingTypeProps?.padding ?? "8px",
            }}
          />
        );
      }
      case "textWithChip": {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography className={classes.text} style={{ marginRight: "8px" }}>
              {data?.value ?? "-"}
            </Typography>
            {data.main?.Payment_Method &&
              props.paymentMethodProps?.for?.includes(
                data.main.Payment_Method
              ) && (
                <Chip
                  variant="outlined"
                  label={props?.paymentMethodProps?.text ?? "Chip"}
                  style={{
                    border: props?.paymentMethodProps?.color ?? "none",
                    color: props?.paymentMethodProps?.textColor ?? "#000",
                    backgroundColor:
                      props?.paymentMethodProps?.bgColor ?? "none",
                  }}
                  onClick={() =>
                    props.paymentMethodProps.handleClick(data.main)
                  }
                />
              )}
          </div>
        );
      }
      case "colorText": {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              className={classes.text}
              style={{
                color: data?.value === "Rejected" ? "#800000" : "#008000",
                marginRight: 6
              }}
            >
              {data?.value}
            </Typography>
            {data?.value === "Rejected" && (
                <InfoIcon info={t("This invoice has been deleted")} />
              )}
          </div>
        );
      }
      case "textLink": {
        return (
          <div
            onClick={() => props?.onUnitClick(main, data)}
            style={{ textDecorationLine: "underline", color: "blue" }}
          >
            {data.value}
          </div>
        );
      }
      case "increment": {
        return data.index + 1;
      }
      case "image": {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <img src={data.image} alt={data.name}></img>
            <Typography style={{ marginLeft: 10 }}>{data.value}</Typography>
            {/* <Typography className={classes.change}>Change</Typography> */}
          </div>
        );
      }
      case "PFImage": {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src={data.value !== "" ? data.value : "/images/pf.svg"}
              sx={{ width: 34, height: 34 }}
            />
          </div>
        );
      }
      case "logo": {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src={data?.value?.logo}
              sx={{
                width: 34,
                height: 34,
                lineHeight: 0,
                fontSize: "0.875rem",
              }}
            >
              {data?.value?.name ? data?.value?.name?.charAt(0) : ""}
            </Avatar>
          </div>
        );
      }
      case "img": {
        return (
          // <div style={{ display: "flex", alignItems: "center" }}>
          // <Avatar
          //   src={data?.main?.img}
          //   sx={{ width: 32, height: 32, borderRadius:4 }}
          // >
          // </Avatar>
          <Box sx={{ height: 32, width: 32 }}>{data?.main?.img}</Box>
          // </div>
        );
      }
      case "link": {
        if (props?.state?.type === "account") return;
        return (
          <Typography
            className={classes.link}
            onClick={() => props?.handleLink(data)}
          >
            {main.url}
          </Typography>
        );
      }
      case "date": {
        return (
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
            className={classes.text}
          >
            {data?.value ? convertTimeUtcToZoneCalander(data?.value) : "-"}
          </Typography>
        );
      }
      case "date_time": {
        const format = data?._?.format ?? "yyyy-MMM-DD hh:mm a";
        return (
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
            className={classes.text}
          >
            {data.value
              ? convertTimeUtcToZoneCalander(data.value, format)
              : "-"}
          </Typography>
        );
      }
      case "hour": {
        return (
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
            className={classes.text}
          >
            {data.value ? timeZoneConverter(data.value, "hh:mm") : "-"}
          </Typography>
        );
      }
      case "description": {
        return (
          <div style={{ maxWidth: "400px", display: "flex" }}>
            <LongTextTooltip title={data.value ?? ""} placement="top" arrow>
              <Typography
                noWrap
                readOnly
                className={classes.text}
                style={{
                  wordBreak: "break-all",
                  overflowWrap: "anywhere",
                }}
              >
                {data?.value?.length > 0
                  ? `${data?.value?.substring(0, 70)}${
                      data?.value?.length > 70 ? "..." : ""
                    } `
                  : "-"}
              </Typography>
            </LongTextTooltip>
          </div>
        );
      }
      case "assetName": {
        return (
          <div style={{ maxWidth: "300px", display: "flex" }}>
            <LongTextTooltip title={data.value ?? ""} placement="top" arrow>
              <Typography
                noWrap
                readOnly
                className={classes.text}
                style={{
                  wordBreak: "break-all",
                  overflowWrap: "anywhere",
                }}
              >
                {data?.value?.length > 0
                  ? `${data?.value?.substring(0, 50)}${
                      data?.value?.length > 50 ? "..." : ""
                    } `
                  : "-"}
              </Typography>
            </LongTextTooltip>
          </div>
        );
      }
      case "quill": {
        return (
          <div>
            <ReactQuill
              noWrap
              readOnly
              theme="bubble"
              // value={data?.value ?? ""}
              value={
                data?.value?.length > 0
                  ? `${data?.value?.substring(0, 100)}${
                      data?.value?.length > 10 ? "..." : ""
                    } `
                  : "-"
              }
              modules={modules}
              className={classes.text}
              style={{
                // wordBreak: "break-all",
                overflowWrap: "anywhere",
                width: "max-content",
              }}
            />
          </div>
        );
      }
      case "redirect": {
        return (
          <a target="_blank" href={data.value}>
            {data.value}
          </a>
        );
      }
      case "object": {
        return data.value[data?.objectOption] ?? "-";
      }
      case "phone": {
        return (
          <>
            {main.code} {main.mobile}
          </>
        );
      }
      case "more": {
        return (
          <div
            style={{
              display: props?.edit || props?.delete ? "flex" : "none",
              float: props?.moreIconRight ? "right" : "auto",
            }}
          >
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.edit && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("edit", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Edit")}
                  </MenuItem>
                )}
                {props?.edit && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("active", main, main.status);
                    }}
                    className={classes.menuItem}
                  >
                    {main.status === "Active" ||
                    main.is_active === true ||
                    main.is_active === "Active"
                      ? t("Inactive")
                      : t("Active")}
                  </MenuItem>
                )}
                {props?.history && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("history", main, main.delete);
                    }}
                    className={classes.menuItem}
                  >
                    {t("History")}
                  </MenuItem>
                )}
                {props?.delete && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("delete", main, main.delete);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Delete")}
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "more_2": {
        return (
          <div
            style={{ display: props?.edit || props?.delete ? "flex" : "none" }}
          >
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.edit && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("edit", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Edit")}
                  </MenuItem>
                )}
                {props?.delete && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("delete", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Delete")}
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "more_all": {
        return (
          <div
            style={{ display: props?.edit || props?.delete ? "flex" : "none" }}
          >
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.view && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("view_more", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("View")}
                  </MenuItem>
                )}
                {props?.edit && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("edit", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Edit")}
                  </MenuItem>
                )}
                {props?.delete && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("active", main, main.status);
                    }}
                    className={classes.menuItem}
                  >
                    {main.status === "Active" ||
                    main.is_active === true ||
                    main.is_active === "Active"
                      ? t("In-Active")
                      : t("Active")}
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "feedback": {
        return (
          <div
            style={{ display: props?.edit || props?.delete ? "flex" : "none" }}
          >
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.view && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("view_feedBack", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("View")}
                  </MenuItem>
                )}
                {props?.delete && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("active/inactive", main);
                    }}
                    className={classes.menuItem}
                  >
                    <>
                      {main.status === "Active" || main.is_active === true ? (
                        <Typography sx={{ color: "#FF4B4B" }}>
                          {t("In-active this form")}
                        </Typography>
                      ) : (
                        <Typography sx={{ color: "#5AC782" }}>
                          {t("Active")}
                        </Typography>
                      )}
                    </>
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "draft_feedback": {
        return (
          <div
            style={{ display: props?.edit || props?.delete ? "flex" : "none" }}
          >
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    props.handleIcon("publish", main);
                  }}
                  className={classes.menuItem}
                >
                  {t("Publish Form")}
                </MenuItem>

                {props?.view && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("view_feedBack", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("View")}
                  </MenuItem>
                )}
                {props?.edit && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("edit", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Edit")}
                  </MenuItem>
                )}
                {props?.delete && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("delete", main);
                    }}
                    className={classes.menuItem}
                  >
                    <Typography sx={{ color: "#FF4B4B" }}>
                      {t("Delete")}
                    </Typography>
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "function_location_more": {
        return (
          <div
            style={{ display: props?.edit || props?.delete ? "flex" : "none" }}
          >
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.view && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("view", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("View Functional Location")}
                  </MenuItem>
                )}
                {props?.view && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();

                      handleClose();
                      props.handleIcon("view_heirarchy", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("View Hierarchy")}
                  </MenuItem>
                )}
                {props?.edit && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();

                      handleClose();
                      props.handleIcon("edit", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Edit")}
                  </MenuItem>
                )}
                {props?.edit && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();

                      handleClose();
                      props.handleIcon("active", main, main.status);
                    }}
                    className={classes.menuItem}
                  >
                    {main.status === "Active" ? t("Inactive") : "Active"}
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "more_3": {
        return (
          <div
            style={{ display: props?.edit || props?.delete ? "flex" : "none" }}
          >
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.edit && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("active", main, main.status);
                    }}
                    className={classes.menuItem}
                  >
                    {main.status === "Active" ? t("Inactive") : "Active"}
                  </MenuItem>
                )}

                {props?.delete && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("delete", main, main.delete);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Delete")}
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "more_delete": {
        return (
          <div
            style={{ display: props?.edit || props?.delete ? "flex" : "none" }}
          >
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.edit && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("active", main, main.status);
                    }}
                    className={classes.menuItem}
                  >
                    {main.status === "Active" ? t("Inactive") : t("Active")}
                  </MenuItem>
                )}

                {props?.delete && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("delete", main, main.status);
                    }}
                    className={classes.menuItem}
                  >
                    {main.status === "Active" ? t("Delete") : t("Active")}
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "more_4": {
        return (
          <div style={{ display: props?.edit ? "flex" : "none" }}>
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>
            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.edit && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("edit", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Edit")}
                  </MenuItem>
                )}
                {props?.edit && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("active", main, main.status);
                    }}
                    className={classes.menuItem}
                  >
                    {main.status === "Active" ||
                    main.is_active === true ||
                    main.is_active === "Active"
                      ? t("Inactive")
                      : "Active"}
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "more_data": {
        return (
          <div style={{ display: props?.edit ? "flex" : "none" }}>
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>
            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.edit && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("edit", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Edit")}
                  </MenuItem>
                )}
                {props?.edit && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("active", main, main.status);
                    }}
                    className={classes.menuItem}
                  >
                    {main.is_active ? t("Inactive") : "Active"}
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "more_5": {
        return (
          <div style={{ display: props?.edit ? "flex" : "none" }}>
            {main.status !== "Won" && (
              <IconButton
                className={classes.moreBtn}
                onClick={(e) => {
                  e.stopPropagation();
                  setAnchorEl(e.currentTarget);
                  setIndex(data.index);
                }}
              >
                <MoreVertIcon className={classes.more} />
              </IconButton>
            )}

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {" "}
                {props?.edit && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("edit", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Edit")}
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "file_manager_option": {
        return (
          <div style={{ display: "flex" }}>
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.edit && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("edit", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Edit")}
                  </MenuItem>
                )}
                {props?.detail && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("detail", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Detail")}
                  </MenuItem>
                )}
                {props?.download && !main?.is_dir && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("download", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Download")}
                  </MenuItem>
                )}
                {props?.delete && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("delete", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Delete")}
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "view_more": {
        return (
          <div>
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    props.handleIcon("active", main, main.status);
                  }}
                  className={classes.menuItem}
                >
                  {main?.is_active ? t("In-Active") : t("Active")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    props.handleIcon("view", main, main.delete);
                  }}
                  className={classes.menuItem}
                >
                  {t("View")}
                </MenuItem>
              </Menu>
            )}
          </div>
        );
      }
      case "error_icon": {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              transform: "rotate(180deg)",
            }}
          >
            {main.iconToolTip && (
              <InfoIcon
                fill="#FF4B4B"
                hoverFill="#FF4B4B"
                info={main.iconToolTip}
              />
            )}
          </div>
          // {main?.iconToolTip && <LightTooltip title={main.iconToolTip} placement="top">
          //    {/* <ErrorOutlineIcon /> */}
          //    <InfoIcon fill="#FF4B4B" />
          //  </LightTooltip>}
        );
      }
      case "block": {
        return (
          <div
            onClick={() => props?.onUnitClick(main, "block")}
            style={{ textDecorationLine: "underline", color: "blue" }}
          >
            {data.value}
          </div>
        );
      }
      case "unit": {
        return (
          <div
            onClick={() => props?.onUnitClick(main, "unit")}
            style={{ textDecorationLine: "underline", color: "blue" }}
          >
            {data.value}
          </div>
        );
      }
      case "floor": {
        return (
          <div
            onClick={() => props?.onUnitClick(main, "floor")}
            style={{ textDecorationLine: "underline", color: "blue" }}
          >
            {data.value}
          </div>
        );
      }
      case "active": {
        return (
          <>
            {data.value ? (
              <Typography className={classes.activego}>
                {t("Active")}
              </Typography>
            ) : (
              <Typography className={classes.inactivego}>
                {t("Active")}
              </Typography>
            )}
          </>
        );
      }
      case "info": {
        return (
          <div style={{ display: props?.view ? "flex" : "none" }}>
            {props?.view && (
              <IconButton onClick={() => props.handleIcon("info", main)}>
                <InfoOutlinedIcon className={classes.infoIcon} />
              </IconButton>
            )}
          </div>
        );
      }
      case "payment_status": {
        return (
          <>
            {data.value === "Close" ? (
              <Typography className={classes.inactivego} sx={{ margin: "0" }}>
                {t("Close")}
              </Typography>
            ) : (
              <Typography className={classes.inprogress} sx={{ margin: "0" }}>
                {t("In Progress")}
              </Typography>
            )}
          </>
        );
      }
      case "q_status": {
        return (
          <>
            {
              <Typography
                className={
                  (data.value === "Won" && classes.green) ||
                  (data.value === "Active" && classes.yellow) ||
                  (data.value === "InActive" && classes.red) ||
                  (data.value === "Inactive" && classes.red)
                }
                sx={{ margin: "0" }}
              >
                {data.value}
              </Typography>
            }
          </>
        );
      }

      // INSPECTION START

      case "inspection_status": {
        return (
          <Box>
            {data.value === "Yet To Verify" ? (
              <Box flexGrow={1} display="flex" alignItems="center">
                <Box>
                  <Typography className={classes.yet}>{data.value}</Typography>
                </Box>
                <Box marginLeft="30px">
                  {/* <Button
                    sx={{ boxShadow: "none" }}
                    onClick={() => props.handleIcon("assign", data)}
                    variant="contained"
                  >
                    Assign
                  </Button> */}
                </Box>
              </Box>
            ) : data.value === "Yet to assign" ? (
              <Box flexGrow={1} display="flex" alignItems="center">
                <Box>
                  <Typography className={classes.yet}>
                    {t(data.value)}
                  </Typography>
                </Box>
                <Box style={{ marginInlineStart: "30px" }}>
                  <Button
                    sx={{ boxShadow: "none" }}
                    onClick={() => props.handleIcon("assign", data.main)}
                    variant="contained"
                  >
                    {t("Assign")}
                  </Button>
                </Box>
              </Box>
            ) : (
              <Typography
                className={
                  data.value === "Assigned"
                    ? classes.assignstatus
                    : data.value === "Rejected"
                    ? classes.rejectedStatus
                    : classes.completeStatus
                }
              >
                {t(data.value)}
              </Typography>
            )}
          </Box>
        );
      }
      case "more_6": {
        return (
          <div style={{ display: props?.view ? "flex" : "none" }}>
            {props?.view && (
              <IconButton onClick={() => props.handleIcon("info", main)}>
                <MoreVertIcon className={classes.more} />
              </IconButton>
            )}
          </div>
        );
      }
      case "more_6_ins_item": {
        return (
          <div style={{ float: "right" }}>
            <IconButton>
              <MoreVertIcon
                className={classes.more}
                handleIcon={props?.handleIcon}
              />
            </IconButton>
          </div>
        );
      }
      case "utility_reading": {
        return (
          <div
            style={{ display: props?.view || props?.edit ? "flex" : "none" }}
          >
            {!main.is_converted_to_managed && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setAnchorEl(e.currentTarget);
                  setIndex(data.index);
                }}
              >
                <MoreVertIcon className={classes.more} />
              </IconButton>
            )}
            {index === data?.index && !main.is_converted_to_managed && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClose();
                    props.handleIcon("post", main);
                  }}
                  className={classes.menuItem}
                >
                  {t("Post to Manage Invoice")}
                </MenuItem>
                {props?.delete && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("active", main, main.status);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Inactive")}
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "more_7": {
        return (
          <div
            style={{ display: props?.view || props?.edit ? "flex" : "none" }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>
            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.view && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("view", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("View")}
                  </MenuItem>
                )}
                {props?.edit && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("active", main, main.status);
                    }}
                    className={classes.menuItem}
                  >
                    {main.status === "Active" ? t("Inactive") : "Active"}
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "more_free_text_invoice": {
        return (
          <>
            <div
              style={{
                display: props?.edit || props?.delete ? "flex" : "none",
              }}
            >
              <IconButton
                className={classes.moreBtn}
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                  setIndex(data.index);
                  // e.stopPropagation()
                }}
              >
                <MoreVertIcon className={classes.more} />
              </IconButton>

              {index === data?.index && (
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  className={classes.menuList}
                >
                  {props?.publish_invoice && main?.status !== "Published" && (
                    <MenuItem
                      onClick={(e) => {
                        props.handleIcon("publish_invoice", main);
                        handleClose();
                      }}
                      className={classes.menuItem}
                    >
                      {t("Publish Invoice")}
                    </MenuItem>
                  )}
                  {props?.edit && main?.status !== "Published" && (
                    <MenuItem
                      onClick={(e) => {
                        props.handleIcon("edit", main);
                        handleClose();
                      }}
                      className={classes.menuItem}
                    >
                      {t("Edit")}
                    </MenuItem>
                  )}
                  {props?.view && (
                    <MenuItem
                      onClick={(e) => {
                        props.handleIcon("view", main);
                        handleClose();
                      }}
                      className={classes.menuItem}
                    >
                      {t("View")}
                    </MenuItem>
                  )}
                </Menu>
              )}
            </div>
          </>
        );
      }
      case "more_contract": {
        return (
          <div
            style={{ display: props?.edit || props?.delete ? "flex" : "none" }}
          >
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                e?.stopPropagation();
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.edit && data?.main?.status !== "Published" && (
                  <MenuItem
                    onClick={(e) => {
                      handleClose();
                      props.handleIcon("edit", main);
                      e?.stopPropagation();
                    }}
                    className={classes.menuItem}
                  >
                    {t("Edit")}
                  </MenuItem>
                )}
                {props?.delete && (
                  <MenuItem
                    onClick={(e) => {
                      handleClose();
                      props.handleIcon("delete", main);
                      e?.stopPropagation();
                    }}
                    className={classes.menuItem}
                  >
                    {t("Delete")}
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "more_approvalWorkflow": {
        return (
          <div style={{ display: props?.edit ? "flex" : "none" }}>
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                e?.stopPropagation();
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>
          </div>
        );
      }
      case "object_status_inspection": {
        if (
          data?.value.value === "Yet to be Assign" ||
          data?.value.value === "Pending"
        ) {
          return (
            <Typography
              sx={{
                color: "#FF9340",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().semiBold,
              }}
            >
              {data.value.label}
            </Typography>
          );
        } else if (data?.value.value === "Assigned") {
          return (
            <Typography
              sx={{
                color: "#78B1FE",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().semiBold,
              }}
            >
              {data.value.label}
            </Typography>
          );
        } else if (data?.value.value === "Completed") {
          return (
            <Typography
              sx={{
                color: "#5AC782",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().semiBold,
              }}
            >
              {data.value.label}
            </Typography>
          );
        } else {
          return data.value;
        }
      }
      case "status_inspection": {
        if (data?.value === "Yet to assign" || data?.value === "Pending") {
          return (
            <Typography
              sx={{
                color: "#FF9340",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().semiBold,
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Assigned") {
          return (
            <Typography
              sx={{
                color: "#78B1FE",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().semiBold,
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Completed") {
          return (
            <Typography
              sx={{
                color: "#5AC782",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().semiBold,
              }}
            >
              {data.value}
            </Typography>
          );
        } else {
          return data.value;
        }
      }
      case "inputBox": {
        if (props?.state?.type === "account") return;
        return (
          <div style={{ display: props?.edit ? "flex" : "none" }}>
            {props?.edit && (
              <Checkbox
                checked={val?.is_active}
                onChange={(e) =>
                  props.handleIcon("checkBox", data, e.target.checked)
                }
              />
            )}
          </div>
        );
      }
      case "checkBox_custom": {
        return (
          <div style={{ display: props?.edit ? "flex" : "none" }}>
            <Checkbox
              checked={main?.is_checked ? true : false}
              onClick={(e) => {
                e.stopPropagation();
                props.handleIcon("customCheckBox", main, e?.target?.checked);
              }}
            />
          </div>
        );
      }
      case "blocking_status": {
        if (data?.value === "Pending") {
          return (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography
                sx={{
                  color: "#FF9340",
                  fontSize: "0.875rem",
                  fontFamily: FontFamilySwitch().semiBold,
                }}
              >
                {data.value}
              </Typography>
              <Stack direction="row" spacing={2} alignItems="center">
                <Button
                  variant="contained"
                  onClick={() => props.handleIcon("approve", main)}
                >
                  {t("Approve")}
                </Button>
                <Button
                  variant="outlined"
                  className={classes.rejectedStatusBtn}
                  onClick={() => props.handleIcon("reject", main)}
                >
                  {t("Reject")}
                </Button>
              </Stack>
            </Box>
          );
        } else if (data?.value === "Approved") {
          return (
            <Typography
              sx={{
                color: "#5AC782",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().semiBold,
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "Rejected") {
          return (
            <Typography
              sx={{
                color: "red",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().semiBold,
              }}
            >
              {data.value}
            </Typography>
          );
        } else {
          return (
            <Typography
              sx={{
                // color: "red",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().semiBold,
              }}
            >
              {data.value}
            </Typography>
          );
        }
      }
      case "switcher": {
        return (
          <div style={{ display: props?.edit ? "flex" : "none" }}>
            {props?.edit && (
              <CustomCheckBox
                checked={val?.is_team_lead}
                onChange={(e) =>
                  props.handleIcon(
                    "customCheckBox",
                    data?.main,
                    data?.main?.is_team_lead
                  )
                }
              />
            )}
          </div>
        );
      }
      case "unused_balance": {
        return (
          <Typography
            sx={{
              color: data.value > 0 ? "#5AC782" : "auto",
              fontSize: "0.875rem",
              fontFamily: FontFamilySwitch().semiBold,
            }}
          >
            {data.value > 0
              ? `${main.symbol} ${data.value}`
              : `${main.symbol} 0`}
          </Typography>
        );
      }
      /* falls through */
      case "more_appraisal": {
        return (
          <div
            style={{ display: props?.edit || props?.delete ? "flex" : "none" }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.edit &&
                  new Date(main?.data?.effective_start_date) <= new Date() &&
                  main?.Status !== "Active" && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClose();
                        props.handleIcon("Activate", main);
                      }}
                      className={classes.menuItem}
                    >
                      {t("Activate")}
                    </MenuItem>
                  )}

                {props?.delete && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("Delete", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Delete")}
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "inventory": {
        return (
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
            className={classes.text}
          >
            {data.value ? "Yes" : "No"}
          </Typography>
        );
      }
      /* falls through */
      case "aminities_booking_status": {
        if (data.value === "Pending") {
          return (
            <p style={{ color: "#78B1FE", margin: "0", whiteSpace: "nowrap" }}>
              {data.value}
            </p>
          );
        } else if (data.value === "Unpaid" || data.value === "Cancelled") {
          return (
            <p style={{ color: "#FF9340", margin: "0", whiteSpace: "nowrap" }}>
              {data.value}
            </p>
          );
        } else if (data.value === "Approved" || data.value === "Booked") {
          return (
            <p style={{ color: "#5AC782", margin: "0", whiteSpace: "nowrap" }}>
              {data.value}
            </p>
          );
        } else if (data.value === "Paid") {
          return (
            <p style={{ color: "#5AC782", margin: "0", whiteSpace: "nowrap" }}>
              {data.value}
            </p>
          );
        } else if (data.value === "Decline") {
          return (
            <p style={{ color: "#FF4B4B", margin: "0", whiteSpace: "nowrap" }}>
              {data.value}
            </p>
          );
        } else if (data.value === "Invoice conversion pending") {
          return (
            <p style={{ color: "#FF9340", margin: "0", whiteSpace: "nowrap" }}>
              {data.value}
            </p>
          );
        } else {
          return (
            <p style={{ margin: "0", whiteSpace: "nowrap" }}>{data.value}</p>
          );
        }
      }
      /* falls through */

      case "inputCheck": {
        return (
          !props?.proxyprops && (
            <Checkbox
              checked={val?.is_active}
              onClick={(e) => {
                e.stopPropagation();
                props.handleCheck(
                  main,
                  e.target.checked,
                  data?.index,
                  "is_active"
                );
              }}
            />
          )
        );
      }
      /* falls through */

      case "agreement_inspection_status": {
        return (
          <Box>
            {data.value === "Yet To Verify" ? (
              <Box flexGrow={1} display="flex" alignItems="center">
                <Box>
                  <Typography className={classes.yet}>{data.value}</Typography>
                </Box>
              </Box>
            ) : data.value === "Pending" ? (
              <p style={{ color: "#78B1FE", margin: "0" }}>{data.value}</p>
            ) : data.value === "Yet to assign" ||
              data.value === "Yet to Assign Inspector" ? (
              <Box flexGrow={1} display="flex" alignItems="center">
                <Box>
                  <Typography className={classes.yet}>{data.value}</Typography>
                </Box>
              </Box>
            ) : (
              <Typography
                className={
                  data.value === "Assigned"
                    ? classes.assignstatus
                    : data.value === "Rejected"
                    ? classes.rejectedstatus
                    : classes.completeStatus
                }
              >
                {data.value}
              </Typography>
            )}
          </Box>
        );
      }
      case "text_with_date": {
        return (
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
            className={classes.text}
          >
            {data?.value?.value ?? "-"} ,{" "}
            <span className={classes.date}>
              {data?.value?.date
                ? convertTimeUtcToZoneCalander(data?.value?.date)
                : "-"}
            </span>
          </Typography>
        );
      }
      case "role": {
        return data?.value?.label ? (
          <Tooltip title={data?.value?.responsibiltes} placement="bottom" arrow>
            <Typography
              style={{
                whiteSpace: "nowrap",
              }}
              className={classes.text}
            >
              <span
                className={classes.role}
                style={{
                  backgroundColor: `${data?.value?.bgColor}`,
                  color: `${
                    data?.value?.bgColor === "#EBFBFF"
                      ? "#2CA2BF"
                      : data?.value?.bgColor === "#EBF0FF"
                      ? "#5071CE"
                      : data?.value?.bgColor === "#FFFAEB"
                      ? "#ECC049"
                      : "black"
                  }`,
                }}
              >
                {data?.value?.label}
              </span>
            </Typography>
          </Tooltip>
        ) : (
          <Typography className={classes.text}>{"-"}</Typography>
        );
      }
      case "tool_tip": {
        return (
          <>
            <LightTooltip
              title={main?.build?.map((x, i) => {
                return (
                  <span>
                    {x?.build_name ?? ""}
                    {i === main?.build?.length - 1 ? "." : ","}
                  </span>
                );
              })}
              placement="right"
            >
              <span style={{ color: "#5078E1" }}>&nbsp;{data.value}&nbsp;</span>
            </LightTooltip>
          </>
        );
      }
      case "parking": {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography sx={{ whiteSpace: "nowrap" }} className={classes.text}>
              {data.value ?? "-"}
            </Typography>
            {data.main?.parking && (
              <Typography className={classes.lightBlue}>
                {data.main?.parking ? t("Parking") : ""}
              </Typography>
            )}
          </Stack>
        );
      }
      case "image_icon": {
        return data?.value;
      }
      case "milestone_text": {
        return (
          <Box p={1}>
            {data?.main?.value_type !== "variable" && data?.index === 0 ? (
              <TextBox
                value={data?.value}
                onChange={props?.milstoneOnchange}
                type="number"
                isError={val?.customerror?.length > 0}
                errorMessage={val?.customerror}
                label={null}
                placeholder={t("Enter Amount")}
                endAdornment={
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                    }}
                    className={classes.text}
                  >
                    {props?.currency ?? "-"}
                  </Typography>
                }
                // isReadonly={true}
              />
            ) : data?.main?.value_type === "variable" ? (
              <TextBox
                value={data?.value}
                onChange={(e) => props?.milstoneOnchange(e, data?.index)}
                type="number"
                isError={val?.customerror?.length > 0}
                errorMessage={val?.customerror}
                label={null}
                placeholder="Enter Amount"
                endAdornment={
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                    }}
                    className={classes.text}
                  >
                    {props?.currency ?? "-"}
                  </Typography>
                }
                // isReadonly={true}
              />
            ) : (
              <Typography
                style={{
                  whiteSpace: "nowrap",
                }}
                className={classes.text}
              >
                {`${data?.value} (${data?.main?.percentage} %)` ?? "-"}
              </Typography>
            )}
          </Box>
        );
      }
      case "oppertunity_status": {
        return (
          <>
            {
              <Typography
                className={
                  (data.value === "Won" && classes.green) ||
                  (data.value === "Open" && classes.yellow) ||
                  (data.value === "Closed" && classes.red)
                }
                sx={{ margin: "0" }}
              >
                {data.value}
              </Typography>
            }
          </>
        );
      }
      case "object_status_color": {
        return (
          <Typography
            sx={{
              color: data?.value?.text_color,
              fontSize: "0.875rem",
              fontFamily: FontFamilySwitch().semiBold,
            }}
          >
            {data?.value?.label ?? "-"}
          </Typography>
        );
      }
      case "correspondence_option": {
        return (
          <div
            style={{
              display:
                props?.view || props?.edit || props?.delete ? "flex" : "none",
            }}
          >
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {/* {" "} */}
                {/* {props?.view && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("dialog_view", main);
                    }}
                    className={classes.menuItem}
                  >
                    View
                  </MenuItem>
                )} */}
                {props?.edit && main?.status?.value === 1 && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("send", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Send")}
                  </MenuItem>
                )}
                {props?.delete && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("delete", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Delete")}
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }

      case "revoke": {
        return (
          <Button
            className={classes.revoke_button}
            disabled={props?.isButtonDisable}
            onClick={() => props.handleIcon("unit_revoke", main)}
          >
            <Typography className={classes.revoke_text}>
              {data?.value}
            </Typography>
          </Button>
        );
      }

      case "number_format": {
        return (
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
            className={classes.text}
          >
            {data?.value ? parseFloat(data?.value)?.toLocaleString() : "-"}
          </Typography>
        );
      }
      case "more_resident_onboard": {
        return (
          <>
            {data?.main?.status !== "Won" && (
              <div
                style={{
                  display: props?.edit || props?.delete ? "flex" : "none",
                }}
              >
                <IconButton
                  className={classes.moreBtn}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAnchorEl(e.currentTarget);
                    setIndex(data.index);
                  }}
                >
                  <MoreVertIcon className={classes.more} />
                </IconButton>

                {index === data?.index && (
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    className={classes.menuList}
                  >
                    {props?.edit && (
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClose();
                          props.handleIcon("edit", main);
                        }}
                        className={classes.menuItem}
                      >
                        {t("Edit")}
                      </MenuItem>
                    )}
                    {props?.delete && (
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClose();
                          props.handleIcon("delete", main);
                        }}
                        className={classes.menuItem}
                      >
                        {t("Delete")}
                      </MenuItem>
                    )}
                  </Menu>
                )}
              </div>
            )}
          </>
        );
      }
      case "import_table_option": {
        return (
          <div
            style={{ display: props?.view || props?.delete ? "flex" : "none" }}
          >
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {main?.error_file_url && props?.error_report && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("error_report", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Error Report")}
                  </MenuItem>
                )}
                {props?.view && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("download", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Download")}
                  </MenuItem>
                )}
                {props?.delete && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("delete", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Delete")}
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "file_ext_text": {
        return (
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
            className={classes.file_ext_text}
          >
            {data.value ?? "-"}
          </Typography>
        );
      }
      case "manual_backup_option": {
        return (
          <div
            style={{ display: props?.edit || props?.delete ? "flex" : "none" }}
          >
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.edit && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("log", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Restore Log")}
                  </MenuItem>
                )}
                {props?.edit && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("restore", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Restore")}
                  </MenuItem>
                )}
                {props?.delete && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("delete", main);
                    }}
                    className={classes.delete_menu_item}
                  >
                    {t("Delete")}
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "system_backup_option": {
        return (
          <div style={{ display: props?.edit ? "flex" : "none" }}>
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                {props?.edit && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.handleIcon("restore", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Restore")}
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "lead_more": {
        return (
          <div
            style={{ display: props?.edit || props?.delete ? "flex" : "none" }}
          >
            {
              <Stack direction={"row"} alignItems={"center"}>
                <IconButton
                  className={classes.moreBtn}
                  onClick={(e) => {
                    e?.stopPropagation();
                    props?.handleIcon("quick_view", main);
                  }}
                >
                  <RemoveRedEyeOutlinedIcon />
                </IconButton>
                {(data?.main?.Status === "Open" ||
                  data?.main?.Status === "Archieved") && (
                  <IconButton
                    className={classes.moreBtn}
                    onClick={(e) => {
                      e.stopPropagation();
                      setAnchorEl(e.currentTarget);
                      setIndex(data.index);
                    }}
                  >
                    <MoreVertIcon className={classes.more} />
                  </IconButton>
                )}
              </Stack>
            }

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={(e) => {
                  e.stopPropagation();
                  handleClose();
                }}
                className={classes.menuList}
              >
                {props?.edit && data?.main?.Status === "Open" && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("edit", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Edit")}
                  </MenuItem>
                )}
                {props?.delete &&
                  data?.main?.Status === "Open" &&
                  !props?.hideDelete && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClose();
                        props.handleIcon("delete", main);
                      }}
                      className={classes.menuItem}
                    >
                      {t("Delete")}
                    </MenuItem>
                  )}
                {props?.archived && data?.main?.Status === "Archieved" && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClose();
                      props.handleIcon("Achieved", main);
                    }}
                    className={classes.menuItem}
                  >
                    {t("Re-open")}
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        );
      }
      case "backup_and_restore_status": {
        return (
          <Typography
            className={classes.backup_and_restore_status}
            style={{
              whiteSpace: "nowrap",
              color:
                data?.value === "Yet to initiate"
                  ? "#FF9340"
                  : data?.value === "Completed"
                  ? "#5AC782"
                  : data?.value === "Failed"
                  ? "#FF4B4B"
                  : "#091B29",
            }}
          >
            {data?.value ?? "-"}
          </Typography>
        );
      }
      case "milestone_date": {
        return (
          <Box p={1} width="150px">
            <DatePickerNew
              value={data?.value}
              handleChange={(e) => {
                props?.dateOnchange(e, data?.index);
              }}
              minDate={new Date()}
            />
          </Box>
        );
      }
      case "yes_no": {
        return (
          <Typography className={classes.text} style={{ whiteSpace: "nowrap" }}>
            {data.value ? "Yes" : "No"}
          </Typography>
        );
      }
      case "price": {
        const currency_code = JSON?.parse(
          localStorage?.getItem(LocalStorageKeys?.selectedCompany)
        )?.code;

        return (
          <Typography className={classes.text} style={{ whiteSpace: "nowrap" }}>
            {data?.value ? currencyFormater(currency_code, data?.value) : "-"}
          </Typography>
        );
      }
      case "avatar_1": {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src={data?.value?.image_src ?? ""}
              sx={{
                width: 34,
                height: 34,
                lineHeight: 0,
                fontSize: "0.875rem",
              }}
              {...stringAvatar(data?.value?.name)}
            />
          </div>
        );
      }
      case "credit": {
        return (
          <>
            <Typography
              sx={{
                color: "#11a442bd",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().semiBold,
              }}
            >
              {data?.value ?? "-"}
            </Typography>
          </>
        );
      }
      case "debit": {
        return (
          <>
            <Typography
              sx={{
                color: "#FF8F6B",
                fontSize: "0.875rem",
                fontFamily: FontFamilySwitch().semiBold,
              }}
            >
              {data?.value ?? "-"}
            </Typography>
          </>
        );
      }
      case "priority": {
        return (
          <Typography
            className={classes.backup_and_restore_status}
            style={{
              whiteSpace: "nowrap",
              color:
                data?.value === "low"
                  ? "#06D6A0"
                  : data?.value === "medium"
                  ? "#FFD166"
                  : data?.value === "high"
                  ? "#EFB93C"
                  : "#EF476F",
            }}
          >
            {data?.value ?? "-"}
          </Typography>
        );
      }
      case "arrow": {
        return (
          <div
            style={{ display: "flex" }}
            onClick={() => props?.handleIcon("view", main)}
          >
            <IconButton size="small" className={classes.arrow}>
              <ArrowForwardIosIcon
                size="small"
                style={{ fontSize: "0.875rem", color: "#4E5A6B" }}
              />
            </IconButton>
          </div>
        );
      }
      case "deleteIcon": {
        return (
          props?.edit && (
            <Box display={"flex"} justifyContent={"end"}>
              <IconButton onClick={() => props.handleIcon("delete", main)}>
                <DeleteIcon color="error" />
              </IconButton>
            </Box>
          )
        );
      }
      case "select_box": {
        const InspectionItemManualResponse = (array) => {
          const details = array?.data?.map((i) => {
            return {
              label: i?.label,
              value: i?.value,
              ...i,
            };
          });
          return details;
        };
        return (
          <Box width="250px">
            <CustomSelectOptions
              label={""}
              placeholder={""}
              onChange={(val) => props.handleIcon("resource_update", main, val)}
              // options={[]}
              loadOptions={(search, array, handleLoading) =>
                loadOptionsApis(
                  `/queries/resources_by_group`,
                  {
                    company_id: data?.value?.company_id,
                    group_id: data?.value?.resource_group?.map((x) => {
                      return x?.value;
                    }),
                  },
                  search,
                  array,
                  handleLoading,
                  "data",
                  {},
                  InspectionItemManualResponse
                )
              }
              isPaginate={true}
              noSearch={true}
              closeIcon={false}
              // key={JSON.stringify(data?.category)}
              value={data.value}
              selectHeight={"35px"}
              // isError={data?.error?.sub_category?.length > 0}
              // errorMessage={data?.error?.sub_category}
              menuPlacement={"bottom"}
              maxMenuHeight={"150px"}
              // isRequired
              // isReadOnly={true}
            />
          </Box>
        );
      }
      case "job_validation": {
        return (
          <Box>
            {!data?.value?.is_overlap ? (
              <CheckCircleIcon color="success" />
            ) : (
              <Box
                className={classes.warningRedCard}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                onClick={() => props.handleIcon("overlapped_jobs", main)}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <WarningIcon sx={{ color: "#FF4B4B" }} />
                  <Typography
                    className={classes.warningRedText}
                  >{`${data.value.over_lapping_count} jobs Overlaps`}</Typography>
                </Stack>
                <ArrowForwardIosIcon
                  sx={{ color: "#FF4B4B", fontSize: "14px" }}
                />
              </Box>
            )}
          </Box>
        );
      }
      case "closeIcon": {
        return (
          <Box onClick={() => props.handleIcon("customClose", main)}>
            <CloseIconWithBG />
          </Box>
        );
      }
      case "quillView": {
        return (
          <Typography
            sx={{
              color: "#5078E1",
              fontSize: "0.875rem",
              fontFamily: FontFamilySwitch().semiBold,
              cursor: "pointer",
            }}
            onClick={() => props?.handleViewQuill(data?.value)}
          >
            {"View"}
          </Typography>
        );
      }
      case "approvalLevels": {
        return (
          <Typography
            sx={{
              color: "#ffffff",
              fontSize: "0.875rem",
              fontFamily: FontFamilySwitch().regular,
              cursor: "pointer",
              padding: "4px 6px",
              backgroundColor: val?.level === "None" ? "#B3B3B3" : "#5AC782",
              display: "inline-block",
              borderRadius: "4px",
            }}
          >{`${val?.level === "None" ? '' : 'Level'} ${data?.value}`}</Typography>
        );
      }
      default: {
        return data.value;
      }
    }
  };

  const returnPageNumber = () => {
    //check if props.rows have a index key
    const checkIndexKeyInType = props?.rows?.some((_) => _?.index);
    if (props?.rows?.length && checkIndexKeyInType) {
      if (props?.rows?.length === 1) {
        return `${props?.rows?.[0]?.index} of ${totalCount}`;
      } else {
        let lastCountIndex = props?.rows?.length - 1;
        return `${props?.rows?.[0]?.index} - ${props?.rows?.[lastCountIndex]?.index} of ${totalCount}`;
      }
    }
    return "";
  };
  // const ccyFormat = (num) => {
  //   return `${num.toFixed(2)}`;
  // }
  return (
    <div className={classes.root}>
      {props?.is_loading ? (
        <>
          <Box sx={{ position: "relative" }}>
            <TableContainer
              className={classes.mdHeight}
              sx={{
                minHeight: props?.height && props?.height,
                maxHeight: props?.height && props?.height,
                mt: 2,
              }}
            >
              <Table stickyHeader aria-label="customized table">
                <TableHead className={classes.thead}>
                  {props?.heading?.length > 0 && (
                    <TableRow>
                      {props?.heading?.map((data, index) => (
                        <StyledTableCell
                          headingColor={props?.headingColor}
                          size="small"
                          key={index}
                        >
                          {data.title}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  )}
                </TableHead>
                {props?.tableType === "normal" && <Box height={"8px"} />}
                <TableBody>
                  {[...Array(props?.no_of_loading_rows ?? 4).keys()]?.map(
                    (_) => (
                      <TableRow
                        className={classes.tbody}
                        style={{
                          backgroundColor: "#fff",
                        }}
                      >
                        {props?.dataType?.map((data_type) => (
                          <StyledTableCell
                            stickyHeader={true}
                            size="small"
                            headingColor={props?.cellColor}
                            component="th"
                            scope="row"
                          >
                            {data_type?.type?.map((type) => (
                              <Skeleton
                                animation="wave"
                                variant="rounded"
                                height={"20px"}
                                style={{ borderRadius: "2px" }}
                              />
                            ))}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {props?.is_fab_icon && (
              <Box className={classes.fab_icon_box}>
                <Skeleton
                  variant="circular"
                  animation="wave"
                  height={56}
                  width={56}
                />
              </Box>
            )}
          </Box>
          {props?.showpagination && (
            <div className={classes.pagination}>
              <div className={classes.flexRow}>
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  height={"32px"}
                  width={"150px"}
                  style={{ borderRadius: "2px" }}
                />
              </div>
              <div className={classes.flexRow}>
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  height={"32px"}
                  width={"150px"}
                  style={{ borderRadius: "2px" }}
                />
              </div>
            </div>
          )}
        </>
      ) : props?.rows?.length === 0 ? (
        <div
          className={classes.noRecordDiv}
          style={{
            position: "relative",
            minHeight: props?.height && props?.height,
            maxHeight: props?.height && props?.height,
          }}
        >
          {props?.noDataSvg ? (
            <Box
              sx={{
                //zIndex: -1,
                minHeight: props?.height && props?.height,
                maxHeight: props?.height && props?.height,
                mt: 2,
                pr: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <NoDataFound />
            </Box>
          ) : (
            <p className={classes.typo}>
              {t(props?.noDataText ?? "No Data Found")}
            </p>
          )}
          {props?.is_fab_icon && (
            <Box className={classes.fab_icon_box}>
              <Fab
                className={classes.fab}
                size="large"
                color="primary"
                onClick={props?.handleFabIcon}
              >
                <SpeedDialIcon />
              </Fab>
            </Box>
          )}
        </div>
      ) : (
        <>
          <Box sx={{ position: "relative" }}>
            <TableContainer
              className={classes.mdHeight}
              sx={{
                //zIndex: -1,
                minHeight: props?.height && props?.height,
                maxHeight: props?.height && props?.height,
                mt: 2,
              }}
            >
              {/* <br /> */}
              {/* Table Component */}
              <Table stickyHeader aria-label="customized table">
                {/* heading */}
                <TableHead className={classes.thead}>
                  {props?.heading?.length > 0 && (
                    <TableRow>
                      {props?.heading?.map((data, index) => {
                        return (
                          <StyledTableCell
                            headingColor={props?.headingColor}
                            size="small"
                            key={index}
                          >
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              spacing={1}
                            >
                              {data?.isBackCheckbox && (
                                <Checkbox
                                  checked={props?.allSelect}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    props.allhandleCheck(
                                      e.target.checked,
                                      data
                                    );
                                  }}
                                />
                              )}
                              {data.title}
                              {data?.Checkbox && !props?.proxyprops && (
                                <Checkbox
                                  checked={props?.allSelect}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    props.allhandleCheck(e.target.checked);
                                  }}
                                />
                              )}
                            </Stack>
                          </StyledTableCell>
                        );
                      })}
                    </TableRow>
                  )}
                </TableHead>
                {/* rows */}
                {props?.tableType === "normal" && <Box height={"8px"} />}
                <TableBody>
                  {props?.rows?.length > 0 && (
                    <>
                      {props?.rows?.map((row, index) => {
                        return (
                          <>
                            <TableRow
                              selected={row?.selectedRow}
                              key={index + 1}
                              className={classes.tbody}
                              style={{
                                backgroundColor: props?.selectedBackgroundColor
                                  ? row?.id === props?.data
                                    ? props?.selectedBackgroundColor
                                    : "#fff"
                                  : row?.status && props?.isColor === true
                                  ? "#F5F7FA"
                                  : "auto",
                              }}
                              onClick={() =>
                                props?.onClick && props?.onClick(row)
                              }
                            >
                              {props?.dataType?.map((val) => {
                                return (
                                  <StyledTableCell
                                    stickyHeader={true}
                                    size="small"
                                    headingColor={props?.cellColor}
                                    component="th"
                                    scope="row"
                                    onDoubleClick={() => {
                                      if (props?.enable_double_click) {
                                        if (
                                          disable_click_cases?.includes(
                                            val?.type[0]
                                          )
                                        ) {
                                        } else {
                                          props?.view &&
                                            props.handleIcon &&
                                            props.handleIcon(
                                              "double_click",
                                              row
                                            );
                                        }
                                      }
                                    }}
                                    onClick={() => {
                                      if (
                                        props?.enable_single_click &&
                                        props?.enable_double_click
                                      ) {
                                        props?.view &&
                                          props.handleIcon &&
                                          props.handleIcon("view", row);
                                      } else if (
                                        !props?.enable_single_click &&
                                        props?.enable_double_click
                                      ) {
                                      } else if (
                                        disable_click_cases?.includes(
                                          val?.type[0]
                                        )
                                      ) {
                                      } else {
                                        props?.view &&
                                          props.handleIcon &&
                                          props.handleIcon("view", row);
                                      }
                                    }}
                                  >
                                    {val?.type?.map((type) => {
                                      return getComponentType(row, {
                                        index: index,
                                        type: type,
                                        main: row,
                                        value: row[val.name],
                                        image: row[val.imagekey],
                                        icon: row[val.icon],
                                        is_active: row[val.is_active],
                                        objectOption: val?.label ?? "label",
                                        _: val,
                                      });
                                    })}
                                  </StyledTableCell>
                                );
                              })}
                            </TableRow>

                            {props.tableType === "normal" && (
                              <Box height={"8px"} />
                            )}
                          </>
                        );
                      })}
                    </>
                  )}
                  {props?.is_fab_icon && <Box height={"66px"} />}
                  {props?.subTotal && (
                    <TableRow>
                      <TableCell colSpan={5}></TableCell>
                      <TableCell className={classes.subTotal}>
                        {props?.subTotal}
                      </TableCell>
                      <TableCell className={classes.subTotal}>
                        {props.subTotalValue}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {props?.is_fab_icon && (
              <Box className={classes.fab_icon_box}>
                <Fab
                  className={classes.fab}
                  size="large"
                  color="primary"
                  onClick={props?.handleFabIcon}
                >
                  <SpeedDialIcon />
                </Fab>
              </Box>
            )}
          </Box>
          {/* Pagination Section */}
          {props?.showpagination && (
            <>
              <div className={classes.pagination}>
                {/* <p className={classes.typo}>
              pages {1}/{Math.ceil(props.rows.length / pageSize)}
            </p> */}
                <div className={classes.flexRow}>
                  <p className={classes.typo}>{t("Rows per page")}</p>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={props?.limit}
                    onChange={(e) =>
                      props?.handleChangeLimit(
                        e.target.value,
                        props?.extraData ?? ""
                      )
                    }
                    // classes={{
                    //   root:classes.select
                    // }}
                    className={classes.select}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                  >
                    {/* <MenuItem value={2}>2</MenuItem> */}
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </div>
                <div className={classes.flexRow}>
                  {props?.rows?.length > 0 && (
                    <p className={classes.typo}>{returnPageNumber()}</p>
                  )}
                  <Pagination
                    className={classes.paginate}
                    shape="rounded"
                    count={Math.ceil(totalCount / props?.limit)}
                    page={props?.page}
                    // onChange={handleChange}
                    onChange={(e, value) =>
                      props?.handlePagination(value, props?.extraData ?? "")
                    }
                    color="primary"
                    // showFirstButton
                    // showLastButton
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
export default withTranslation("accounts")(TableComponent);

TableComponent.defaultProps = {
  handleIcon: () => {},
  view: true,
  edit: true,
  delete: true,
  detail: true,
  download: true,
  enable_double_click: false,
  enable_single_click: false,
  is_loading: false,
  is_fab_icon: false,
  handleFabIcon: () => false,
};
