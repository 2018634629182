import { Box, Button } from "@mui/material";
import React, { useContext, useState } from "react";
import { LoadingSection, Stepper } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../../utils";
import { ChooseProperty } from "./chooseProperty";
import { ChooseUnit } from "./chooseUnit";
import { Preview } from "./preview";
import ScopeOfWork from "./scopeOfWork";
import { useStyles } from "./style";

export const CreateInspection = ({ t, companyId = "", onClose = () => false, reload = () => false }) => {
    const classes = useStyles()
    const alert = useContext(AlertContext)
    const [step, setStep] = useState(1)
    const [offset, setOffset] = useState(0)
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({
        selectedProperty: {},
        selectedUnit: {},
        selectedInspectionItem: [],
        propertyData: [],
        unitData: [],
        inspectionItemData: [],
        maintenanceRequest: [],
        disable: true,
        scopeOfWork: "",
    })
    const [searchText, setSearchText] = useState("")
    const [isDisableBtn,setIsDisableBtn]=useState(false)
    const stepsNew = [
        {
            label: t("Choose Property"),
            step: 1,
            value: step,
        },
        {
            label: t("Choose Unit"),
            step: 2,
            value: step,
        },
        {
            label: t("Scope Of Work"),
            step: 3,
            value: step,
        },
        {
            label: t("Preview"),
            step: 4,
            value: step,
        },
    ];
    const getPropertyList = (offset = 0, search = "", searchBool = true) => {
        const payload = {
            company_id: companyId,
            status: [true],
            offset: offset,
            limit: 10,
            search: search
        }
        NetworkCall(
            `${config.api_url}/property/getAllProperties`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setLoading(false)
                if (searchBool) {
                    setData({ ...data, propertyData: response?.data?.data?.property })
                }
                else {
                    setData({ ...data, propertyData: data?.propertyData.concat(response?.data?.data?.property) })
                }
            })
            .catch((error) => {
                setLoading(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),
                });
            });
    }
    const getUnitList = (offset = 0, search = "", searchBool = true) => {
        const payload ={
            type: [],
            property_id: data?.selectedProperty?.id,
            offset: offset,
            search: search,
            limit: 10
        }
        NetworkCall(
            `${config.api_url}/queries/get_unit_by_category`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setLoading(false)
            if (searchBool) {
                setData({ ...data, unitData: response?.data?.data?.unit, disable: data?.selectedUnit?.name ? false : true })
            }
            else {
                setData({ ...data, unitData: data?.unitData.concat(response?.data?.data?.unit), disable: data?.selectedUnit?.name ? false : true })
            }

        }).catch((err) => {
            setLoading(false)
            console.log(err)
        })
    }
    const prev = () => {
        setStep(step - 1)
        setData({ ...data, disable: false })
    }
    const next = () => {
        setStep(step + 1)
        switchComponents(step + 1)
    }
    const switchComponents = (val) => {
        switch (val) {
            case 1:
                return getPropertyList(0, "")
            case 2:
                return getUnitList(0, "")
            default:
                return null
        }
    }
    React.useEffect(() => {
        getPropertyList(0, "")
        if (data?.selectedProperty.length > 0) {
            getUnitList(0, "")
        }
        //eslint-disable-next-line
    }, [])
    const updateState = (k, v, k1, v1) => {
        setData({ ...data, [k]: v, [k1]: v1, disable: false })
    }
    const handleSearch = (v) => {
        // setData({ ...data, [k]: v })
        if (step === 1) {
            getPropertyList(0, v)
        }
        else if (step === 2) {
            getUnitList(0, v)
        }
        setSearchText(v)
    }
    const fetchMoreData = () => {
        setOffset(offset + 10);
        if (step === 1) {
            getPropertyList(offset + 10, "", false);
        }
        else if (step === 2) {
            getUnitList(offset + 10, "", false)
        }

    };
    const onCreate = () => {
        setIsDisableBtn(true)
        const payload = {
            "unit_id": data?.selectedUnit?.id,
            "request_type": "site_visit",
            "scope_of_work": data?.scopeOfWork

        }

        NetworkCall(
            `${config.api_url}/general_inspection/create`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setIsDisableBtn(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Site Visit Request Created Successfully"),
            });
            reload()
            onClose()
        }).catch((error) => {
            setIsDisableBtn(false)
            onClose()
            if (error.response) {
                // Request made and server responded
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: Object.keys(error.response.data.error.message).length !== 0 ? error.response.data.error.message : "Something went wrong",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });

            }
            else {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something went wrong please try again"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            }

        })
    }
    const StepperContent = {
        1: <ChooseProperty t={t} updateState={updateState} propertyData={data.propertyData} selectedProperty={data?.selectedProperty} handleSearch={handleSearch} searchText={searchText} fetchMoreData={fetchMoreData} />,
        2: <ChooseUnit t={t} updateState={updateState} unitData={data.unitData} selectedUnit={data?.selectedUnit} handleSearch={handleSearch} searchText={searchText} fetchMoreData={fetchMoreData} />,
        3: <ScopeOfWork t={t} updateState={updateState} value={data.scopeOfWork} />,
        4: <Preview t={t} selectedUnit={data?.selectedUnit} selectedInspectionItem={data?.selectedInspectionItem} setStep={setStep} maintenanceRequest={data?.maintenanceRequest} updateState={updateState} data={data} />
    };
    return (
        <Box>
            <Stepper t={t} step={step} steps={stepsNew} padding={"16px 22px "} />
            <Box>{loading ? <LoadingSection top="20vh" message={t("Fetching Details")} /> : StepperContent[step]}</Box>
            <Box display="flex" justifyContent={step > 1 ? "space-between" : "end"} mt={2} p={2} className={classes.anchorBottom}>
                {step > 1 &&
                    <Button variant="outlined" onClick={prev} className={classes.previousbtn}>{t("Previous")}</Button>
                }
                <Button variant="contained" onClick={step === 4 ? onCreate : next} disabled={isDisableBtn?true:data?.disable}>{step === 4 ? t("Create") : t("Next")}</Button>

            </Box>
        </Box>
    )
}