
import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch, remCalc } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    tcText: {
        fontSize: "1rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    imgContainer: {
        width: "100%",
        borderRadius: "12px",
        overflow: "hidden",
        boxShadow: "0px 0px 4px #00000029",
        height: "125px",
        margin: "4px"
    },
    img: {
        objectFit: "cover",
        height: "125px",
        width: "100%",
        borderRadius: 12,
    },

    detailsBox: {
        display: "flex",
        alignItems: "center",
        padding: "8px 16px",
        backgroundColor: theme?.palette?.background?.background5
    },
    pdfText: {
        padding: 4,
        color: "#fff",
        fontFamily: FontFamilySwitch().bold,
        fontSize: remCalc(12),
        background: theme.palette.primary.main,
        borderRadius: 8
    },
    pdfName: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().bold,
        wordBreak: "break-all",
        whiteSpace: "nowrap",
        width: '95%',
        textOverflow: "ellipsis",
        overflow: "hidden"
    }
}));