import React from "react";
import { Typography, Box, ClickAwayListener } from '@mui/material';
import { useStyles } from './styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { TickIcon } from '../svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { NetWorkCallMethods } from "../../utils";

export const TemplateDrawer = ({
    mistoneUpdate = () => false,
    milestoneTemplate = {},
    company_id = null,
    // is_selected = false,
}) => {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [mileStoneOptions, setMileStoneOptions] = React.useState([]);
    const [offset, setOffset] = React.useState(0);
    const [hasMore, setHasMore] = React.useState(true);

    // functions
    const getMailStoneOptions = (offset, filter) => {
            NetworkCall(
                `${config.api_url}/queries/opportunity/get_milestone_master`,
                NetWorkCallMethods.post,
                {
                    company_id: company_id,
                    offset: offset ?? 0,
                    limit: 10,
                },
                null,
                true,
                false
              )
            .then((response) => {
                const newMileStones = response?.data?.data;
                if (newMileStones.length < 10) {
                    setHasMore(false);
                } else {
                    setHasMore(true);
                }

                if (filter) {
                    setMileStoneOptions((prevOptions) => prevOptions.concat(newMileStones));
                } else {
                    setMileStoneOptions(newMileStones);
                }
            })
            .catch((err) => {
                console.log(err);
                setHasMore(false); 
            });
    };

    // Infinite scroll
    const fetchMoreData = () => {
        const newOffset = offset + 10;
        setOffset(newOffset);
        getMailStoneOptions(newOffset, true);
    };

    const openDrawer = (isOpen) => {
        if (isOpen) {
            getMailStoneOptions(0, false);
        }
        setOpen(!open);
    };

    const updateFunction = (value) => {
        setOpen(!open);
        mistoneUpdate(value);
    };

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div style={{ position: "relative" }}>
                {!open ? (
                    <Box className={classes.root} onClick={() => openDrawer(true)}>
                        <Typography className={classes.templateTitle}>
                            {milestoneTemplate?.label} <span className={classes.templateVariable}>( {milestoneTemplate?.template_type ?? "-"} )</span>
                        </Typography>
                        <KeyboardArrowDownIcon />
                    </Box>
                ) : (
                    <Box className={open ? classes?.selectRoot : classes?.selectRoot1}>
                        <Box className={classes.select}>
                            <Typography className={classes.templateTitle}>
                                {milestoneTemplate?.label} <span className={classes.templateVariable}>( {milestoneTemplate?.template_type ?? '-'} )</span>
                            </Typography>
                            <KeyboardArrowUpIcon />
                        </Box>
                        <InfiniteScroll
                            dataLength={mileStoneOptions.length}
                            next={fetchMoreData}
                            hasMore={hasMore}
                            loader={<Box display="flex" justifyContent="center" alignItems="center" height="100px"><h4>Loading...</h4></Box>}
                            height={`calc(100vh - 540px)`}
                        >
                            <Box p={1} style={{ overflow: "auto" }}>
                                {mileStoneOptions.map((x, index) => (
                                    <Box key={index} className={index === mileStoneOptions.length ? classes.select1 : classes.select} onClick={() => updateFunction(x)}>
                                        <Typography className={classes.templateTitle}>
                                            {x?.label ?? "-"} <span className={classes.templateVariable}>( {x?.template_type ?? "-"} )</span>
                                        </Typography>
                                        {milestoneTemplate?.value === x?.value && <TickIcon />}
                                    </Box>
                                ))}
                            </Box>
                        </InfiniteScroll>
                    </Box>
                )}
                {open && <Box height="18px" />}
            </div>
        </ClickAwayListener>
    );
};
