import {
  Badge,
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import FilterIMG from "../../assets/filter";
import {
  FilterGenerator,
  SearchFilter,
  Subheader,
  TableWithPagination,
  TextBox,
  UseDebounce,
} from "../../components";
import { AlertContext, AuthContext } from "../../contexts";
import {
  accessCheckRender,
  activeOptions,
  AlertProps,
  getRoutePermissionNew,
  LocalStorageKeys,
  master_secret_key,
  NetWorkCallMethods,
} from "../../utils";
import { useStyles } from "./style";
import CloseIcon from "@mui/icons-material/Close";
import { NewLoader } from "../../components/newLoader";
import {
  Heading,
  Path,
  Type,
  addAmenityActiveOptions,
  defaultAddState,
  defaultFilterData,
} from "./utils/tableUtils";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { BackendRoutes } from "../../router/routes";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const AmenityCategory = (props) => {
  const { loading, handleLoading, t = () => false } = props;

  const classes = useStyles();
  const debounce = UseDebounce();

  // useContext
  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext);

  // useState
  const [data, setData] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const [filterData, setFilterData] = React.useState(defaultFilterData);
  const navigate = useNavigate();
  const [fiterDrawer, setFilterDrawer] = React.useState(false);
  const [addDialogOpen, setAddDialogOpen] = React.useState(false);
  const [addState, setAddState] = React.useState(defaultAddState);
  const [permission, setPermission] = React.useState({});
  const [loader, setLoader] = React.useState(true);
  const [buttonDisable, setButtonDisable] = React.useState(false);

  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");
  const limitParam = searchParams.get("limit");

  // Default values if not set in URL
  const pageFromParams = pageParam ? parseInt(pageParam, 10) : 1;
  const limitFromParams = limitParam ? parseInt(limitParam, 10) : 10;

  const [page, setPage] = React.useState(pageFromParams);
  const [limit, setLimit] = React.useState(limitFromParams);

  const updatePage = (newPage, newLimit = limit) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", newPage.toString());
    searchParams.set("limit", newLimit.toString());

    // Update URL without reloading
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
    // Update state
    setPage(newPage);
    setLimit(newLimit);
  };

  const currentOffset = (page - 1) * limit;
  // useEffect to get permission and data when loading the screen
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        getData(currentOffset, limitFromParams, searchText, filterData);
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  // Function to get the list based on the input data
  const getData = (
    offset = 0,
    limit = 10,
    search = "",
    filter = defaultFilterData
  ) => {
    let payload = {
      master_name: master_secret_key?.amenity_categories,
      offset,
      limit,
      search,
      is_active:
        !filter?.is_active || filter?.is_active?.length === 0
          ? [true, false]
          : filter?.is_active,
    };
    NetworkCall(
      `${config.api_url}${BackendRoutes?.masters_listing_get}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((r) => {
        let temp_response = r?.data?.data?.data ?? [];
        let temp_row = temp_response?.map?.((_) => {
          return {
            id: _?.id,
            name: _?.name ?? "-",
            description: _?.description ?? "-",
            is_active: _?.is_active ? "Active" : "Inactive",
            data: _,
          };
        });
        setData({
          row: temp_row ?? [],
          totalRowsCount: r?.data?.data?.count ?? 0,
        });
        setLoader(false);
        handleLoading(false);
      })
      .catch((e) => {
        alert.setSnack({
          ...alert,
          open: true,
          msg: t("Some Thing Went Wrong"),
          severity: AlertProps.severity.error,
        });
      });
  };

  // Function for search in search component
  const handleSearch = (e) => {
    setSearchText(e);
    debounce(() => searchTableFunction(e), 800);
  };

  // Function to search data in the list
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getData(0, limit, e, filterData);
  };

  // Function to open add form
  const handleAdd = () => {
    setButtonDisable(false);
    setAddState({ ...defaultAddState });
    setAddDialogOpen(true);
  };

  // Function to handle icon in table row
  const handleTableIcon = (type, data) => {
    const tempData = data?.data;
    const tempAddState = {
      formType: type,
      id: tempData?.id,
      name: tempData?.name,
      description: tempData?.description,
      is_active: type === "active" ? !tempData?.is_active : tempData?.is_active,
      is_delete: type === "delete",
      error: defaultAddState?.error,
    };
    setAddState({ ...tempAddState });
    if (type === "edit" || type === "view") {
      setButtonDisable(false);
      setAddDialogOpen(true);
    } else if (type === "active" || type === "delete") {
      handleCreateEdit(tempAddState);
    }
  };

  //handle pagination
  const handleTablePagination = (newPage) => {
    updatePage(newPage, limit);
  };

  const handleTablePageLimit = (newLimit) => {
    updatePage(1, newLimit); // Reset page to 1 when limit changes
  };

  // Function to update addState
  const updateAddDialogState = (k, v) => {
    let error = addState?.error;
    error[k] = "";
    setAddState({ ...addState, [k]: v, error });
  };

  // Function for updating addNewState
  const validate = () => {
    let isValid = true;
    let error = addState.error;
    if (addState?.name?.length === 0) {
      isValid = false;
      error.name = t("Name is Required");
    }
    if (!isValid) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Please fill all mandatory field"),
      });
    }
    setAddState({ ...addState, error });
    return isValid;
  };

  // Function to create a amenity category
  const handleCreateEdit = async (data) => {
    if (
      data?.formType === "active" || data?.formType === "delete"
        ? true
        : validate()
    ) {
      setButtonDisable(true);
      const currentDate = new Date().toISOString();
      const profileID = localStorage.getItem(LocalStorageKeys.profileID);

      const temp_payload = {
        name: data?.name ?? undefined,
        description:
          data?.description?.length > 0 ? data?.description : undefined,
        is_active: data?.is_active ?? undefined,
        is_delete: data?.is_delete ?? undefined,
        updated_at: currentDate ?? undefined,
        updated_by: profileID ?? undefined,
      };

      switch (data?.formType) {
        case "add":
          temp_payload.client =
            localStorage.getItem(LocalStorageKeys.clinetID) ?? undefined;
          temp_payload.created_at = currentDate ?? undefined;
          temp_payload.created_by = profileID ?? undefined;
          break;
        case "edit":
          temp_payload.id = data?.id ?? undefined;
          break;
        case "active":
          temp_payload.id = data?.id ?? undefined;
          break;
        case "delete":
          temp_payload.id = data?.id ?? undefined;
          break;

        default:
          break;
      }

      let payload = { amenity_category: [temp_payload] };

      NetworkCall(
        `${config.api_url}${BackendRoutes?.masters_upsert_amenity_category}`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((r) => {
          setLoader(true);
          setAddState({ ...defaultAddState });
          getData(currentOffset, limitFromParams, searchText, filterData);
          setAddDialogOpen(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `${t("Amenity Category")} ${
              data?.id
                ? `${data?.formType === "delete" ? t("Deleted") : t("Updated")}`
                : `${t("Created")}`
            } ${t("Successfully.!!!")}`,
          });
        })
        .catch((e) => {
          setButtonDisable(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Some Thing Went Wrong"),
          });
        });
    } else {
      return false;
    }
  };

  // Function to filter
  const handleFilter = (value) => {
    setLoader(true);
    setFilterData(value);
    setPage(1);
    getData(0, limit, searchText, value);
  };

  const render = () => {
    return (
      <div>
        <Subheader hideBackButton={true} title={t("Amenity Category")} />
        {loader ? (
          <NewLoader minusHeight="100px" />
        ) : (
          <div className={classes.root}>
            <Grid container className={classes.content} spacing={1}>
              <Grid item xs={4}>
                <SearchFilter
                  value={searchText}
                  placeholder={t("Search Amenity Category")}
                  handleChange={(value) => handleSearch(value)}
                  customfieldSx={{
                    "& .MuiOutlinedInput-root": { height: "40px" },
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <Box display={"flex"} justifyContent={"end"}>
                  <Stack
                    direction="row"
                    spacing={2}
                    divider={<Divider orientation="vertical" flexItem />}
                  >
                    <IconButton
                      onClick={() => setFilterDrawer(!fiterDrawer)}
                      className={classes.filterButton}
                    >
                      <Badge
                        variant="dot"
                        color="primary"
                        invisible={!(filterData.is_active?.length > 0)}
                      >
                        <FilterIMG color="#091b29" />
                      </Badge>
                    </IconButton>
                    <Button
                      variant="contained"
                      className={classes.button}
                      onClick={handleAdd}
                    >
                      {t("Add A Amenity Category")}
                    </Button>
                  </Stack>
                </Box>
              </Grid>
            </Grid>

            <TableWithPagination
              heading={Heading(t)}
              rows={data?.row}
              path={Path}
              showpagination={true}
              showpdfbtn={false}
              showexcelbtn={false}
              showSearch={false}
              handleIcon={handleTableIcon}
              onClick={() => console.log("")}
              tableType="no-side"
              dataType={Type}
              handlePagination={handleTablePagination}
              handleChangeLimit={handleTablePageLimit}
              totalRowsCount={data?.totalRowsCount}
              page={page}
              limit={limit}
              height={"calc(100vh - 290px)"}
              view={true}
              edit={true}
              delete={true}
            />

            <FilterGenerator
              open={fiterDrawer}
              onClose={() => setFilterDrawer(false)}
              onApply={(value) => handleFilter(value)}
              defaultState={defaultFilterData}
              components={[
                {
                  component: "toggleButton",
                  value: filterData?.is_active,
                  options: activeOptions(t),
                  isMulti: true,
                  state_name: "is_active",
                  label: t("Status"),
                },
              ]}
            />
            <Dialog
              className={classes.addDialog}
              open={addDialogOpen}
              onClose={() => setAddDialogOpen(false)}
            >
              <div className={classes.addDialogHeader}>
                <Typography className={classes.addDialogHeaderTitle}>
                  {addState?.formType === "add"
                    ? t("Add Amenity Category")
                    : addState?.formType === "edit"
                    ? t("Edit Amenity Category")
                    : addState?.formType === "view"
                    ? t("View Amenity Category")
                    : t("Add Amenity Category")}
                </Typography>
                <IconButton
                  onClick={() => setAddDialogOpen(false)}
                  className={classes.addDialogCloseButton}
                >
                  <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
                </IconButton>
              </div>
              <div className={classes.addDialogBody}>
                <TextBox
                  isrequired
                  isReadonly={addState?.formType === "view"}
                  label={t("Name")}
                  placeholder={t("Enter Name")}
                  value={addState?.name ?? ""}
                  onChange={(e) => updateAddDialogState("name", e.target.value)}
                  isError={addState?.error?.name?.length > 0}
                  errorMessage={addState?.error?.name}
                />
                <Box height={16} />
                <TextBox
                  isReadonly={addState?.formType === "view"}
                  label={t("Description")}
                  placeholder={t("Enter Description")}
                  multiline
                  value={addState?.description ?? ""}
                  onChange={(e) =>
                    updateAddDialogState("description", e.target.value)
                  }
                />
                <Box height={16} />
                <Typography className={classes.addDialogFieldLabel} noWrap>
                  {t("Status")}
                </Typography>
                <div className={classes.addDialogButtonContainer}>
                  {addAmenityActiveOptions?.map((_) => {
                    return (
                      <Button
                        className={
                          _?.value === addState?.is_active
                            ? classes.addDialogButtonSelected
                            : classes.addDialogButtonUnSelected
                        }
                        onClick={() =>
                          addState?.formType === "add" ||
                          addState?.formType === "edit"
                            ? updateAddDialogState("is_active", _?.value)
                            : false
                        }
                      >
                        {t(_?.label)}
                      </Button>
                    );
                  })}
                </div>
              </div>
              {(addState?.formType === "add" ||
                addState?.formType === "edit") && (
                <div className={classes.addDialogFooter}>
                  {addState?.formType === "edit" && (
                    <>
                      <Button
                        fullWidth
                        className={classes.addDialogFooterCloseButton}
                        onClick={() => setAddDialogOpen(false)}
                      >
                        {t("Cancel")}
                      </Button>
                    </>
                  )}
                  <Button
                    variant="contained"
                    fullWidth
                    className={classes.addDialogFooterButton}
                    disabled={buttonDisable}
                    onClick={() => handleCreateEdit(addState)}
                  >
                    {addState?.formType === "add" ? t("Create") : t("Save")}
                  </Button>
                </div>
              )}
            </Dialog>
          </div>
        )}
      </div>
    );
  };

  return <div>{accessCheckRender(render, permission, "", loading)}</div>;
};
export default withTranslation("amenityCategory")(AmenityCategory);
