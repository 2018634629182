import { Badge, Box, Grid, Stack } from "@mui/material";
import React from "react";
import FilterIMG from "../../assets/filter";
import {
  FilterGenerator,
  SearchFilter,
  Subheader,
  TableWithPagination,
  UseDebounce,
} from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import {
  accessCheckRender,
  AlertProps,
  getCompanyOption,
  getRoutePermissionNew,
  NetWorkCallMethods,
} from "../../utils";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import {
  ActiveStatus,
  PropertyHeading,
  PropertyPath,
  PropertyType,
} from "../../utils/unitVacancy/tableUtils";
import {
  BodyDiv,
  FilterButton,
  ViewAllButton,
} from "./components/styledComponents";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const UnitVacancyPropertyList = ({ t }) => {
  // constants
  const defaultFilterState = { status: [true] };
  const navigate = useNavigate();
  const debounce = UseDebounce();

  // useContext
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext);

  // useState
  const [companyList, setCompanyList] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState({});
  const [list, setList] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const [filterData, setFilterData] = React.useState(defaultFilterState);
  const [fiterDrawer, setFilterDrawer] = React.useState(false);
  const [permission, setPermission] = React.useState({});

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          setCompanyList(company?.list);
          setSelectedCompany(company?.selected);
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");
  const limitParam = searchParams.get("limit");

  // Default values if not set in URL
  const pageFromParams = pageParam ? parseInt(pageParam, 10) : 1;
  const limitFromParams = limitParam ? parseInt(limitParam, 10) : 10;

  const [page, setPage] = React.useState(pageFromParams);
  const [limit, setLimit] = React.useState(limitFromParams);

  const updatePage = (newPage, newLimit = limit) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", newPage.toString());
    searchParams.set("limit", newLimit.toString());

    // Update URL without reloading
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
    // Update state
    setPage(newPage);
    setLimit(newLimit);
  };

  //handle pagination
  const handleTablePagination = (newPage) => {
    updatePage(newPage, limit);
  };

  const handleTablePageLimit = (newLimit) => {
    updatePage(1, newLimit); // Reset page to 1 when limit changes
  };

  const currentOffset = (page - 1) * limit;

  // useEffect to get property list using selected company and filter data when loading the screen
  React.useEffect(() => {
    if (selectedCompany?.value) {
      getList(currentOffset, limitFromParams, searchText);
    }
    // eslint-disable-next-line
  }, [selectedCompany, filterData]);

  // Function to get property list based on the input data
  const getList = (offset = 0, limit = 10, search = "") => {
    backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." });
    let propertyGroupID = filterData.property_type?.length
      ? filterData?.property_type?.map(({ value }) => value)
      : [];
    const payload = {
      status:
        !filterData?.status || filterData?.status?.length === 0
          ? [true, false]
          : filterData?.status,
      companyID: selectedCompany?.value,
      propertyGroupID: propertyGroupID,
      search: search,
      offset: offset,
      limit: limit,
    };
    NetworkCall(
      `${config.api_url}/queries/property/for_unit_vacancy`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((r) => {
        setList({
          data: r?.data?.data?.property,
          totalRowsCount: r?.data?.data?.count?.[0]?.count,
        });
        backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
      })
      .catch((e) => {
        backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
      });
  };

  // eslint-disable-next-line
  const Row = React.useCallback(
    list?.data?.map((_) => {
      let p;
      try {
        p = {
          id: _?.id,
          name: _?.name,
          location:
            (_?.addressByID?.area ? _?.addressByID?.area + ", " : "") +
            (_?.addressByID?.city
              ? _?.addressByID?.city
              : _?.addressByID?.district ?? ""),
          unitCounts: _?.unitCount?.[0]?.count,
          propertyType: _?.property_groupByID?.group_name,
          status: _?.is_active ? "Active" : "Inactive",
          data: _,
        };
      } catch (err) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
      }
      return p;
      // eslint-disable-next-line
    }),
    [list]
  );

  // Function to change the company
  const handleCompanyChange = (value) => {
    setSelectedCompany(value);
  };

  // Function for search in search component
  const handleSearch = (e) => {
    setSearchText(e);
    debounce(() => searchTableFunction(e), 800);
  };

  // Function to search data in property list
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getList(0, limit, e);
  };

  // Function to handle icon in table row
  const handleTableIcon = (type, data) => {
    if (type === "view") {
      navigate(Routes.unitVacancyPropertyView + "?propertyID=" + data?.id);
    }
  };

  const render = () => {
    return (
      <>
        <Subheader
          hideBackButton={true}
          title={t("Unit Vacancy Period")}
          select
          options={companyList}
          value={selectedCompany}
          onchange={(e) => {
            handleCompanyChange(e);
          }}
        />
        <BodyDiv>
          <Grid container spacing={"8px"}>
            <Grid item xs={4}>
              <SearchFilter
                value={searchText}
                placeholder={t("Search Properties")}
                handleChange={(value) => handleSearch(value)}
                customfieldSx={{
                  "& .MuiOutlinedInput-root": { height: "40px" },
                }}
              />
            </Grid>
            <Grid item xs={8} alignSelf={"center"}>
              <Stack direction="row" justifyContent={"end"}>
                <ViewAllButton
                  onClick={() => {
                    navigate(Routes.unitVacancyAll);
                  }}
                >
                  {t("View All")}
                </ViewAllButton>
                <Box sx={{ marginInlineStart: "16px" }}>
                  <FilterButton onClick={() => setFilterDrawer(!fiterDrawer)}>
                    <Badge
                      variant="dot"
                      color="primary"
                      invisible={
                        !(
                          filterData.status?.length > 0 ||
                          filterData.property_type?.length > 0
                        )
                      }
                    >
                      <FilterIMG color="#091b29" />
                    </Badge>
                  </FilterButton>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <TableWithPagination
                heading={PropertyHeading(t)}
                rows={Row}
                path={PropertyPath}
                showpagination={true}
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                handleIcon={handleTableIcon}
                onClick={() => console.log("")}
                tableType="no-side"
                dataType={PropertyType}
                handlePagination={handleTablePagination}
                handleChangeLimit={handleTablePageLimit}
                totalRowsCount={list?.totalRowsCount}
                page={page}
                limit={limit}
                height={"calc(100vh - 290px)"}
                view={true}
                edit={true}
                delete={true}
              />
            </Grid>
          </Grid>
          <FilterGenerator
            open={fiterDrawer}
            onClose={() => setFilterDrawer(false)}
            onApply={(value) => setFilterData(value)}
            defaultState={defaultFilterState}
            components={[
              {
                component: "select",
                value: filterData?.property_type,
                options: [],
                isMulti: true,
                label: t("Property Type"),
                placeholder: t("Property Type"),
                state_name: "property_type",
                loadOptions: (search, array, handleLoading) =>
                  loadOptionsApis(
                    "queries/property_type/get",
                    {
                      company_id: selectedCompany?.value,
                      is_active: [true],
                    },
                    search,
                    array,
                    handleLoading,
                    "property_group_master",
                    {}
                  ),
                debounceTimeout: 800,
                isPaginate: true,
              },
              {
                component: "toggleButton",
                value: filterData?.status,
                options: ActiveStatus(t),
                isMulti: true,
                state_name: "status",
                label: t("Status"),
              },
            ]}
          />
        </BodyDiv>
      </>
    );
  };

  return <div>{accessCheckRender(render, permission)}</div>;
};
export default withTranslation("unitVacancyPropertyList")(
  UnitVacancyPropertyList
);
