import { Badge, Box, Drawer, Grid, IconButton, Stack } from "@mui/material"
import moment from "moment"
import React from "react"
import { withTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { useLocation } from "react-router-dom"
import FilterIMG from "../../assets/filter"
import { FilterGenerator, LoadingSection, SearchFilter, Subheader, TableWithPagination } from "../../components"
import { config } from "../../config"
import { AlertContext, AuthContext, BackdropContext } from "../../contexts"
import { NetworkCall } from "../../networkcall"
import { Routes } from "../../router/routes"
import { AlertProps, CostingApprovalDataType, CostingApprovalHeading, CostingApprovalPath, LocalStorageKeys, NetWorkCallMethods, accessCheckRender, getCompanyOption, getRoutePermissionNew } from "../../utils"
import { filterOptions } from "./components/common"
import { PreviewScreen } from "./components/preview"
import { CostingApprovalStyles } from "./style"
import { getComponentValue } from "../freeTextInvoice/utlis/freeTextInvoice"


const CostingApproval = ({ t, handleLoading }) => {
    const classes = CostingApprovalStyles()
    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [permission, setPermission] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [loader, setLoader] = React.useState(false)
    const [searchText, setSearchText] = React.useState("")
    const [viewData, setViewData] = React.useState([])
    const [agreementData, setAgreementData] = React.useState([])
    const [approvalTimeline, setApprovalTimeline] = React.useState([]) 
    const [openDrawer, setOpenDrawer] = React.useState(false)
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext);
    const alert = React.useContext(AlertContext);
    const [filterDrawer, setFilterDrawer] = React.useState(false)
    const [filterData, setFilterData] = React.useState({
        requestType: [filterOptions(t)?.requestType?.[0]]
    })
    const [approvalStatus, setApprovalStatus] = React.useState("")
    const navigate = useNavigate()
    const [list, setList] = React.useState({
        data: [],
        count: 0

    })
     const [details, setDetails] = React.useState({
        quott: {},
        units: [],
        reservation: {},
        reservationPrice: "",
        is_primary: false,
        unitDetails: [],
      });
     const search = useLocation().search;
      const id = new URLSearchParams(search).get("id");
    const [itemDetails, setItemDetails] = React.useState([])
    const [timelineData, setTimelineData] = React.useState([])
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth);
        if (perm) {
            setPermission(perm);
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert);
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                    getCostingRequestList(0, 10, company?.selected?.value, "", filterData)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);
    //get costing list
    const getCostingRequestList = (offset = 0, limit = 10, company_id, search = "", filterData) => {
        const payload = {
            "type": filterData?.requestType?.map?.(i => i?.value)?.includes("All") ? undefined : filterData?.requestType?.map?.(i => i?.value),
            "company_id": company_id,
            "offset": offset,
            "limit": limit,
            "search": search,
            bom_type:"Actual",
            profile_id: localStorage.getItem(LocalStorageKeys.profileID),
            assigned_to: localStorage.getItem(LocalStorageKeys.profileID)
        };
        NetworkCall(
            `${config.api_url}/request_costing/get_costing_status_request`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                setLoading(false)
                const dataSource = res?.data?.data?.data ?? res?.data?.data?.approval_workflow;
                const result = dataSource?.map((val, i) => {
                    return {
                        ...val,
                        unit_name: val?.source === "Maintenance" ? val?.maintenance_unit_name : val?.source === "General" ? val?.general_unit_name : val?.agreement_unit_name,
                        requested_on: moment(val?.requested_on).format("DD MMM YYYY ,hh:mm a"),
                        updated_on: moment(val?.updated_at).format("DD MMM YYYY , hh:mm a"),
                        comments: val?.comments !== "" ? val?.comments ?? "-" : "-",
                        request_id: val?.maintenance_request_no ?? val?.general_request_no ?? val?.agreement_no ?? val?.request_id ?? "-",
                        requested_by: val?.requestedBy?.first_name ?? val?.createdBy?.first_name ?? "-",
                    }
                })
                setList({ data: result, count: res?.data?.data?.count })
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            });
    }

    //get timeline details
    const getTimelineFlow = (data) => {
        const payload = {
            id: data?.costing_request_id
        }

        NetworkCall(
            `${config.api_url}/request_costing/time_line`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setTimelineData(response?.data?.data)
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something Went Wrong",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        })
    }
    //get Costing Item details
    const getCostingDetails = (data) => {
        const payload = {
            "request_id": data?.maintenance_request_id ?? data?.general_request_id,
            "type": data?.source,
            "bom_type":"Actual"
        }

        NetworkCall(
            `${config.api_url}/request_costing/get_costing`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setItemDetails(response?.data?.data?.[0]?.costing?.[0])

        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something Went Wrong",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        })
    }
    //get Final Settlement Details
    const getFinalSettlementDetails = (data) => {
        const payload = {
            "agreement_id": data?.agreement_id,
            "offset": 0,
            "limit": 10,
            "type": "AgreementInfo"
        }

        NetworkCall(
            `${config.api_url}/final_settlement/agreement_info`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setViewData({ ...response?.data?.data, source: data?.source, id: data?.agreement_id, ...data })
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something Went Wrong",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        })
    }

    const getQuotationDetails = (data) => {
        setLoader(true);
        const payload = {
          tenantId: `${config.tenantId}`,
          quotation_id: data?.quotation_id,
          is_primary: true,
          is_quote: true,
        };
        NetworkCall(
          `${config.api_url}/quotation/get`,
          NetWorkCallMethods.post,
          payload,
          null,
          true,
          false
        )
          .then((response) => {
            let result = response?.data?.quotation?.map((val) => {
                return {
                  ...val,
                  id: val?.id,
                  is_open: false,
                  name: val?.name,
                  unit_no: val?.unitNo,
                  total_area: val?.totalarea,
                  area_metric: val?.areaMetric,
                  total_bed_rooms: val?.totalbedroom,
                  total_baths: val?.baths,
                  unit_type: val?.unitType,
                  discount: 0,
                  discountValue: 0,
                  room_rent: val?.room_rent ?? 0,
                  url: val?.logo,
                  pricing: val?.pricing?.map((c) => {
                    return {
                      ...c,
                      unitid: val?.id,
                      primary: c?.primary ?? false,
                      refundable: c?.refundable ?? false,
                      isActive: true,
                      taxable: c?.taxable ?? false,
                      isChargeable: true,
                      propertyId: val?.property?.property_id,
                      companyId: c?.company_id,
                      isOnetime: c?.isOnetime ?? false,
                      beforeTax: c?.rent_amount,
                      pricing_components: !!c?.inspection
                        ? c?.inspection
                        : c?.component,
                      discountType: { label: "%", value: "%" },
                      inspection: !!c?.inspection
                        ? {
                            category: c?.inspection?.category,
                            type: {
                              value: c?.inspection?.item,
                              label: c?.inspection?.item,
                            },
                          }
                        : null,
                      discountedPrice: c?.displayPercentage ?? 0,
                      discountValue: c?.displayPercentage ?? 0,
                      discountPercentage: c?.displayPercentage ?? 0,
                      fixTax: c?.before_tax,
                      refundableAmount: 0,
                      vat_id: c?.vatGroup?.VGMid,
                      componentType: getComponentValue(
                        c?.component,
                        c?.is_wallet_item
                      ),
                      subtotal: Number(c?.rent_amount ?? 0) + Number(c?.tax ?? 0),
                      componentvalues: c?.value,
                      componentvalue: c?.value,
                      quantity: !!c?.quantity ? c?.quantity : 1,
                      tax: c?.tax ?? 0,
                      taxAmountValue: Number(c?.before_tax) ?? 0,
                      amountWithoutDiscount: c?.amount_without_discount,
                      is_active: true,
                      isQuantity: !!c?.quantity ? true : false,
                      uom_type: c?.uom_type,
                    };
                  }),
                  total: val?.total,
                  totalDiscount: 0,
                  totalAmount: val?.totalPricing,
                  fixTotal: val?.totalPricing,
                  totalrefundableTax: val?.totalrefundableTax,
                  totalDiscountValue: null,
                  discountPrice: 0,
                  discountedPrice: 0,
                  tax: 0,
                  blockid: val?.blockid,
                  propertyid: val?.propertyid,
                  floorid: val?.floorid,
                };
              });
            setViewData({ ...response?.data?.data, source: data?.source, id: data?.agreement_id, ...data })
    
            setDetails({
              quott: response.data.quotationDetails[0],
              units: response.data.units,
              reservationPrice: response.data.reservationPrice,
              reservation: response.data.reservation,
              is_primary: response?.data?.is_primary,
              unitDetails: result,
            });
            setLoader(false);
          })
          .catch((err) => {
            console.log(err);
            setLoader(false);
          });
      };

  const fetchAgreementDetails = (data) => {
    setLoader(true);
    const payload = {
      id: data?.agreement_id,
      to: `${moment(new Date()).format("YYYY-MM-DD")} 00:00:00`,
    };

    NetworkCall(
      `${config.api_url}/agreement/get_details`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((response) => {
        setViewData({ ...response?.data?.data, source: data?.source, id: data?.agreement_id, ...data })
      const result = response?.data?.agreement?.map((x) => {
        return {
            ...x,
            id: x?.id,
            agreement_no: x?.agreement_no,
            agreement_date: x?.agreement_date,
            agreement_type: x?.agreement_type,
        };
      });
      setAgreementData(result?.[0]); 
           setLoader(false);
    }).catch((err) => {
      console.log(err);
      setLoader(false);
    });
  };
    const getWorkflowTimeline = (data) => {
      setLoader(true);
      const payload = {
        request_no: data?.request_no,
      };

      NetworkCall(
        `${config.api_url}/approval_workflow/get_workflow_timeline`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          setViewData({
            ...response?.data?.data,
            source: data?.source,
            id: data?.workflow_id,
            ...data,
          });

          const result = response?.data?.data?.data.map((x) => {
            if (x?.status === "Pending") {
              return {
                createdBy: x?.createdBy,
                updatedBy: x?.updatedBy,
                created_by: x?.createdBy?.first_name || x?.created_by,
                created_at: x?.created_at,
                status: x?.status,
                level_name: x?.level_name,
              };
            } else {
              return {
                createdBy: x?.createdBy,
                updatedBy: x?.updatedBy,
                created_by: x?.createdBy?.first_name || x?.created_by,
                updated_by: x?.updatedBy?.first_name || x?.updated_by,
                created_at: x?.created_at,
                status: x?.status,
                level_name: x?.level_name,
              };
            }
          });
          setApprovalTimeline(result);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    };
  


    // Function to change the company
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        getCostingRequestList(0, limit, value?.value, "", filterData)
    }
    //handle search
    const handleSearch = (value) => {
        setSearchText(value)
        getCostingRequestList(0, limit, selectedCompany?.value, value, filterData)
    }
    //handle Pagination
    const handlePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getCostingRequestList(offset, limit, selectedCompany?.value, "", searchText,filterData)
    }

    const handleChangeLimit = (value) => {
        setLimit(value)
        setPage(1)
        getCostingRequestList(0, value, selectedCompany?.value, "", searchText,filterData)
    }
    const handleIcon = (type, data) => {
        if (type === "view") {
            setOpenDrawer(true)
            if (data?.source === "Quotation Approval") {
                getQuotationDetails(data);
                } else if (data?.source === "Agreement Order Confirmation Approval"){
                getQuotationDetails(data);
                } else if (data?.source === "Agreement Confirmation Approval"){
                fetchAgreementDetails(data);
                }
                else if (data?.source === "Final Settlement") {
                    getFinalSettlementDetails(data);
                }else if (data?.source === "Agreement Emergency Cancellation Approval"){
                    fetchAgreementDetails(data);
                }else if (data?.source === "Reservation Request Approval"){
                    getQuotationDetails(data);
                }else {
                    getCostingDetails(data);
                    setViewData(data);
                }
            setApprovalStatus(data?.status)
        if (
            ["Quotation Approval", "Agreement Order Confirmation Approval", "Reservation Request Approval", "Agreement Confirmation Approval", "Agreement Emergency Cancellation Approval"].includes(data?.source)
        ) {
            getWorkflowTimeline(data);
        } else {
            getTimelineFlow(data); 
        }
    } else {
        if (data?.source === "Final Settlement") {
            navigate(Routes.finalSettlementView, {
              state: {
                data: data,
                selectedCompany: selectedCompany,
              },
            });
          } else if (
            data?.source === "Quotation Approval" ||
            data?.source === "Reservation Request Approval" ||
            data?.source === "Agreement Order Confirmation Approval"
          ) {
            navigate(`${Routes.quotation_view}?id=${data?.quotation_id}`, {
              state: {
                data: data,
                selectedCompany: selectedCompany,
              },
            });
          } else if (
            data?.source === "Agreement Confirmation Approval" ||
            data?.source === "Agreement Emergency Cancellation Approval"
          ) {
            navigate(`${Routes.agreementView}?id=${data?.agreement_id}`, {
              state: {
                data: data,
                selectedCompany: selectedCompany,
              },
            });
          } else {
            navigate(Routes.maintanceRequestView, {
              state: {
                main: {
                  value:
                    data?.source === "Maintenance"
                      ? data?.maintenance_request_id
                      : data?.general_request_id,
                  maintenance_id:
                    data?.source === "Maintenance"
                      ? data?.maintenance_request_id
                      : data?.general_request_id,
                  type: data?.source,
                  request: data?.source,
                  company: selectedCompany?.value,
                  companyData: selectedCompany,
                },
                },
            });
            }
        }
    }
    const closeDrawer = () => {
        setOpenDrawer(false)
    }

    //Funtion to apply filter
    const applyFilter = (filter_data) => {
        setPage(1)
        setFilterData(filter_data)
        getCostingRequestList(0, limit, selectedCompany?.value, "", filter_data)

    }
    const reload = () => {
        getCostingRequestList(0, limit, selectedCompany?.value, "", filterData)
    }
    const render = () => {
        return (
            <Box>
                <Subheader hideBackButton={true} title={t("Requests For Approval")}
                    select options={companyList} value={selectedCompany} onchange={(e) => handleCompanyChange(e)}
                />
                <Box p={2}>
                    {
                        loading ?
                            <LoadingSection top="20vh" message={t("loading")} /> :
                            <Box className={classes.root}>
                                <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                        <SearchFilter value={searchText} placeholder={t("Search Request")}
                                            handleChange={(value) => handleSearch(value)} />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Box display={"flex"} justifyContent={"end"} onClick={() => setFilterDrawer(true)}>
                                            <Stack direction="row">

                                                <IconButton
                                                    className={classes.filterButton}>
                                                    <Badge variant="dot" color="primary">

                                                        <FilterIMG color="#091b29" />
                                                    </Badge>
                                                </IconButton>
                                            </Stack>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TableWithPagination
                                            heading={CostingApprovalHeading(t)}
                                            rows={list?.data}
                                            path={CostingApprovalPath}
                                            showpagination={true}
                                            showpdfbtn={false}
                                            showexcelbtn={false}
                                            showSearch={false}
                                            handleIcon={handleIcon}
                                            tableType="no-side"
                                            dataType={CostingApprovalDataType}
                                            handlePagination={handlePagination}
                                            handleChangeLimit={handleChangeLimit}
                                            totalRowsCount={list?.count}
                                            page={page}
                                            limit={limit}
                                            height={'calc(100vh - 300px)'}
                                            view={permission.read}
                                            edit={permission.update}
                                            delete={permission.delete} />
                                    </Grid>
                                </Grid>
                            </Box>
                    }
                </Box>
                
                <Drawer
                    anchor={"right"}
                    open={openDrawer}
                    onClose={() => closeDrawer()}
                >
                    <PreviewScreen t={t} setCloseDrawer={setOpenDrawer} data={viewData} reload={reload} approvalStatus={approvalStatus} timelineData={timelineData} itemDetails={itemDetails} handleIcon={handleIcon} quotationDetails={details} agreementDetails={agreementData} loader={loader} setLoader={setLoader} approvalTimeline={approvalTimeline}/>
                </Drawer>
                <FilterGenerator open={filterDrawer} onClose={() => setFilterDrawer(false)}
                    onApply={(value) => applyFilter(value)}
                    components={[
                        {
                            component: "select",
                            value: filterData?.requestType,
                            options: filterOptions(t)?.requestType,
                            isMulti: true,
                            state_name: "requestType",
                            label: t("Type"),
                        },
                    ]} />
            </Box>
        )
    }
    return <div>
        {accessCheckRender(render, permission)}
    </div>
}
export default withTranslation("costing")(CostingApproval) 