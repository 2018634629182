import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

export const useStyles = makeStyles((theme) => ({
  label: {
    color: (props) => props?.labelColor ?? theme.typography.color.tertiary,
    fontFamily: (props) =>
      props?.fontFamily === "bold"
        ? FontFamilySwitch().bold
        : FontFamilySwitch().semiBold,
    fontSize: (props) => props?.labelSize ?? theme.palette.labelFontSize,
    marginBottom: (props) => props?.labelMarginBottom ?? "8px",
    textAlign: (props) => props?.textAlign ?? "-webkit-match-parent",
  },
  required: {
    color: "red",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: (props) => props?.labelSize ?? theme.palette.labelFontSize,
    marginBottom: (props) => props?.labelMarginBottom ?? "5px",
  },
}));

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px",
    padding: "10px",
    backgroundColor: "#FFFFFF",
    color: "#4E5A6B",
    border: "1px solid #E4E8EE",
    boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.04)",
    fontSize: "0.8rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
}));
