/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { AlertProps, NetWorkCallMethods, accessCheckRender, getRoutePermissionNew, FontFamilySwitch } from '../../utils';

import { FunctionalStyle } from "./style";
import { withTranslation } from 'react-i18next';
import { FunctionTree } from './components/treeComponent';
import { Subheader } from '../../components';
import { MyBookingCard } from './components/card';
import { AlertContext, AuthContext, BackdropContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { config } from '../../config';
import { withNavBars } from '../../HOCs';
import { NewLoader } from '../../components/newLoader';
import { useNavigate, useLocation } from 'react-router';
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const FunctionalHeirarchy = ({ reltype = "", t = () => false }) => {
    const LongTextTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: "400px",
            borderRadius: "4px",
            padding: "8px 12px",
            backgroundColor: "#FFFFFF",
            color: "#1C1C1C",
            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
            fontSize: "0.875rem",
            fontFamily: FontFamilySwitch().semiBold,
        },
        [`& .${tooltipClasses.arrow}`]: {
            backgroundColor: "transparent",
            color: "#FFFFFF"
        },
    }));

    const classes = FunctionalStyle();
    const { state } = useLocation()
    const navigate = useNavigate()

    const alert = React.useContext(AlertContext);
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext);
    const [permission, setPermission] = React.useState({})
    const [heirarchyData, setHeirarchyData] = React.useState([])
    const [detailsData, setDetailsData] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                if (state?.id?.length > 0)
                    getHeirarchyByID(state?.id)
            }
        }
        // eslint-disable-next-line
    }, [auth]);
    const getHeirarchyByID = (id) => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading", });
        let datas = { id: id }
        NetworkCall(
            `${config.api_url}/function_location/hierarchy`,
            NetWorkCallMethods.post,
            datas, null, true, false
        ).then((res) => {
            setHeirarchyData(res?.data?.data)
            getDetailsByID(res?.data?.data?.[0]?.id, res?.data?.data?.[0]?.location_type)
            backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
        }).catch((error) => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Internal error. Please try again later."),
            });
        });
    }
    const getDetailsByID = (id, type) => {
        setLoading(true)
        let datas = { id: id, type: type }
        NetworkCall(
            `${config.api_url}/function_location/view`,
            NetWorkCallMethods.post,
            datas, null, true, false
        ).then((res) => {
            setDetailsData(res?.data?.data)
            setLoading(false)

        }).catch((error) => {
            setLoading(false)

            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Internal error. Please try again later."),
            });
        });
    }










    const handleOnFolderClick = (data) => {
        getDetailsByID(data?.id, data?.location_type)
    }
    const title = detailsData?.name ?? state?.name ?? ""
    const render = () => {
        return <>
            <Subheader
                title={state?.name ?? ""}
                select
                isReadOnly
                goBack={() => navigate(-1)}
                value={state?.company ?? {}} onchange={() => false}
            />
            <Box>
                <Grid container spacing={2} p={2}>

                    <Grid item sm={5} md={5} lg={3}>
                        <Box className={heirarchyData?.length > 0 ? classes.box2 : classes.box} overflow={"auto"}>
                            <Box p={"12px"}>
                                <Typography className={classes.addNewDialogHeaderTitle}>{t("Heirarchy")}</Typography>
                            </Box>
                            <Divider />
                            <FunctionTree
                                data={heirarchyData}
                                handleOnFolderClick={handleOnFolderClick}

                            />
                        </Box>
                    </Grid>

                    <Grid item sm={7} md={7} lg={9}>

                        <Box className={heirarchyData?.length > 0 ? classes.box2 : classes.box}>
                            {loading ? <NewLoader /> : heirarchyData?.length > 0 ?
                                <>
                                    <Box p={"12px"}>
                                        <LongTextTooltip title={title ?? ""} placement="top" arrow>
                                            <Typography
                                                noWrap
                                                readOnly
                                                className={classes.addNewDialogHeaderTitle}
                                                style={{
                                                    wordBreak: "break-all",
                                                    overflowWrap: "anywhere",
                                                }}
                                            >
                                                {title?.length > 0
                                                    ? `${title?.substring(0, 70)}${title?.length > 70 ? "..." : ""
                                                    } `
                                                    : "-"}

                                            </Typography>
                                        </LongTextTooltip>
                                    </Box>
                                    <Divider />
                                    <Box p={"12px"}>
                                        <Typography className={classes.AssociateText}>
                                            {t("FUNCTIONAL LOCATION PICTURES & DETAILS")}
                                        </Typography>
                                        <Box m={"10px 0px"}>

                                            <MyBookingCard
                                                label1={t("Functional Location Name")}
                                                label2={t("Latitude & Longitute")}
                                                label3={t("Description")}
                                                label4={t("Parent Location")}
                                                value1={detailsData?.name}
                                                value2={`${detailsData?.latitude ?? ""} & ${detailsData?.longitude ?? ""}`}
                                                value3={detailsData?.description}
                                                value4={detailsData?.parent_name}
                                                image_url={detailsData?.image_url}

                                            />


                                        </Box>
                                        {detailsData?.location_type === "Unit" &&
                                            <>
                                                <Typography className={classes.AssociateText}>
                                                    {t("ASSET TAGGED")}
                                                </Typography>
                                                <Box m={"10px 0px"} height={`calc(100vh - 357px)`} overflow={"auto"}>
                                                    {detailsData?.unit && detailsData?.unit?.item_mapping?.map((i) => {
                                                        return <>
                                                            <MyBookingCard
                                                                label1={t("Asset Name")}
                                                                label2={t("Serial Number")}
                                                                label3={t("Manufacturer")}
                                                                label4={t("Condition")}
                                                                value1={i?.name}
                                                                value2={i?.serial_number}
                                                                value3={i?.manufaturer_name}
                                                                value4={i?.item_condition}
                                                                image_url={i?.image_url}

                                                            />
                                                        </>
                                                    })}



                                                </Box>
                                            </>
                                        }
                                    </Box>

                                </>
                                :
                                <center>

                                    <Typography className={classes.noData} >{t("No data found")}</Typography>
                                </center>
                            }

                        </Box >
                    </Grid >
                </Grid >
            </Box >



        </>
    }
    return <div >
        {accessCheckRender(render, permission)}
    </div >
}
const props = {
    boxShadow: false
}
export default withNavBars(withTranslation("functionalLocation")(FunctionalHeirarchy), props)
