import React, { createContext } from "react";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import {
  AlertProps,
  enum_types,
  enumSelect,
  getCompanyOption,
  getRoutePermissionNew,
  LocalStorageKeys,
  NetWorkCallMethods,
} from "../../utils";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { withTranslation } from "react-i18next";
import { UseDebounce } from "../../components";
import { returnEditData } from "./utils";

export const CreateExternalUnitContext = createContext();

const CreateExternalUnitProvider = (props) => {
  const { t = () => false } = props;
  const auth = React.useContext(AuthContext);
  const [permission, setPermission] = React.useState({});
  const backdrop = React.useContext(BackdropContext);
  const [companyList, setCompanyList] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState({});
  const [units, setUnits] = React.useState([]);
  const [unitDetails, setUnitDetails] = React.useState({});
  const [activeView, setActiveView] = React.useState("card");
  const [loading, setLoading] = React.useState(false);
  const [dataLoading, setDataLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(12);
  const [count, setCount] = React.useState(0);
  const alert = React.useContext(AlertContext);
  const [selectedVendor, setSelectedVendor] = React.useState(null);
  const [currency, setCurrency] = React.useState("");
  const [filterData, setFilterData] = React.useState({
    revenue_type: [],
    unit_type: [],
    unit_category: [],
    total_area: [],
  });
  const [existingPricing, setExistingPricing] = React.useState([]);
  const [unitData, setUnitData] = React.useState({
    id: "",
    name: "",
    property_name: "",
    purpose: "",
    developer: "",
    property_type: "",
    category: "",
    address: "",
    revenue_type: "",
    unit_type: "",
    total_area: "",
    uom_id: "",
    bed: "",
    bath: "",
    latitude: "",
    longitude: "",
    notes: "",
    created_by: "",
    updated_by: "",
    client: "",
    payment_period: "",
    pricingData: {
      primary: "",
      secondary: [],
      refundable: [],
      onetime: [],
      other: [],
    },
    deletePricing: [],
    assestData: [],
    deleteAssets: [],
    ListingData: {
      portal_url: "",
      portal_provider: "",
      reference_id: "",
      agent: "",
      phone: "",
    },
    error: {
      name: "",
      property_name: "",
      purpose: "",
      developer: "",
      property_type: "",
      category: "",
      address: "",
      revenue_type: "",
      unit_type: "",
      total_area: "",
      uom_id: "",
      bed: "",
      bath: "",
      latitude: "",
      longitude: "",
      notes: "",
      created_by: "",
      updated_by: "",
      client: "",
      payment_period: "",
      pricingData: {
        primary: "",
        secondary: [],
        refundable: [],
        onetime: [],
        other: [],
      },
      deletePricing: "",
      assestData: "",
      deleteAssets: "",
      ListingData: {
        phone: "",
      },
    },
  });
  const [searchValue, setSearchValue] = React.useState("");
  const [enumValue, setEnumValue] = React.useState({
    revenue_type: [],
  });
  const debounce = UseDebounce();

  const handleSearch = (e) => {
    setSearchValue(e);
    debounce(
      () =>
        getExternalUnits(
          e,
          0,
          12,
          filterData,
          selectedVendor,
          selectedCompany?.value
        ),
      800
    );
  };

  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getExternalUnits(
      "",
      offset,
      12,
      filterData,
      selectedVendor,
      selectedCompany?.value
    );
  };

  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getExternalUnits(
      "",
      0,
      value,
      filterData,
      selectedVendor,
      selectedCompany?.value
    );
  };

  const getExternalUnits = (
    search,
    offset = 0,
    limit = 10,
    filterData,
    selectedVendorId,
    companyId = ""
  ) => {
    setLoading(true);
    const payload = {
      offset: offset,
      limit: limit,
      search,
      filterData,
      vendor_id: selectedVendorId?.id,
      company_id: companyId,
    };

    NetworkCall(
      `${config.api_url}/external_units/getAllUnits`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((r) => {
        const units = r?.data?.data?.data;
        // Calculate the number of units for each property_name
        const propertyUnitCount = units.reduce((acc, unit) => {
          acc[unit.property_name] = (acc[unit.property_name] || 0) + 1;
          return acc;
        }, {});

        // Add pricing (only is_primary=true) and numberOfUnits to each unit
        const unitsWithPricingAndCount = units.map((unit) => ({
          ...unit,
          pricing: unit.pricing
            .filter((price) => price.is_primary) // Filter only primary prices
            .reduce((acc, price) => acc + price.value, 0), // Sum up the primary values
          numberOfUnits: propertyUnitCount[unit.property_name],
          period: unit.pricing?.[0]?.payment_period,
        }));

        setUnits(unitsWithPricingAndCount);
        setCount(r?.data?.data?.count);
        setLoading(false);
      })
      .catch((e) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong",
        });
        setLoading(false);
      });
  };

  const handleDeleteUnit = (id) => {
    const payload = {
      id: id?.id,
    };
    NetworkCall(
      `${config.api_url}/external_units/delete`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((r) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("External Unit Deleted Successfully"),
        });
        getExternalUnits(
          "",
          0,
          12,
          filterData,
          selectedVendor,
          selectedCompany?.value
        );
      })
      .catch((e) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
        });
      });
  };

  const viewUnitById = (id) => {
    setDataLoading(true);
    const payload = {
      id: id?.id,
    };
    NetworkCall(
      `${config.api_url}/external_units/getUnitById`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((r) => {
        const { unitData, pricingData } = returnEditData(r?.data?.data?.[0]);
        setUnitData(unitData);
        setExistingPricing(pricingData);
        setUnitDetails(r?.data?.data);
        setDataLoading(false);
      })
      .catch((e) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
        });
      });
  };

  const getEnum = async () => {
    const result = await enumSelect([enum_types.revenue_type]);
    setEnumValue({
      revenue_type: result?.revenue_type,
    });
  };

  const handleViewChange = (view) => {
    setActiveView(view);
  };

  const handleFilter = ({ filter }) => {
    getExternalUnits("", 0, 12, filter, selectedVendor, selectedCompany?.value);
    setFilterData(filter);
  };

  // Function to change the company
  const handleCompanyChange = (value) => {
    setSelectedCompany(value);
    getExternalUnits("", 0, 12, filterData, selectedVendor, value?.value);
    setSelectedVendor("");
    setCurrency(value?.code);
  };

  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        getEnum();
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          setCompanyList(company?.list);
          setSelectedCompany(company?.selected);
          setCurrency(company?.selected?.code);
          getExternalUnits(
            "",
            0,
            12,
            filterData,
            selectedVendor,
            company?.selected?.value
          );
        } else {
          const companyId = localStorage.getItem(
            LocalStorageKeys?.selectedCompany
          );
          getExternalUnits(
            "",
            0,
            12,
            filterData,
            selectedVendor,
            companyId?.value
          );
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  const handleVendorChange = (value) => {
    setSelectedVendor(value);
    getExternalUnits("", 0, 12, filterData, value, selectedCompany?.value);
  };

  const formatPrice = (value) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
    }).format(value);
  };

  const manualVendorResponse = (array) => {
    return array?.vendor_master;
  };

  return (
    <CreateExternalUnitContext.Provider
      value={{
        t,
        units,
        handleViewChange,
        activeView,
        handleFilter,
        handleDeleteUnit,
        viewUnitById,
        unitData,
        setUnitData,
        unitDetails,
        loading,
        dataLoading,
        handleSearch,
        searchValue,
        page,
        handlePagination,
        count,
        enumValue,
        limit,
        handleChangeLimit,
        filterData,
        unitData,
        setUnitData,
        permission,
        selectedVendor,
        handleVendorChange,
        existingPricing,
        setExistingPricing,
        getExternalUnits,
        handleCompanyChange,
        companyList,
        selectedCompany,
        currency,
        formatPrice,
        manualVendorResponse,
      }}
    >
      {props.children}
    </CreateExternalUnitContext.Provider>
  );
};

export default withTranslation("externalUnit")(CreateExternalUnitProvider);
