import makeStyles from "@mui/styles/makeStyles";
import {  FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((size) => ({
    root:{
        border:`1px solid #E4E8EE`,
        borderRadius:'8px',
        padding:'10px',
        textAlign:"center"
    },
    label:{
        color:"#091B29",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize:"0.75rem",
        marginTop:"4px"
    }
}));