import React from "react"
import { withTranslation } from "react-i18next";
import { TableWithPagination, SearchFilter } from "../../../components";
import { domesticContactListDataType, domesticContactListHeading, domesticContactListPath } from "../../../utils";
import { Box, Grid, Typography } from "@mui/material";
import { DomesticContactListStyles } from "../styles";
const DomesticContactsTable = ({
    handleIcon = () => false,
    list = [],
    handlePagination = () => false,
    handleChangeLimit = () => false,
    page = "",
    limit = "",
    handleSearch = () => false,
    searchText,
    totalRowsCount = "",
    t
}) => {

    const heading = domesticContactListHeading(t)
    const classes = DomesticContactListStyles()
    return (
        <Box>
            <Grid container>
                <Grid item xs={4}>
                    <SearchFilter placeholder={t("searchContacts")} handleChange={(value) => handleSearch(value)} value={searchText} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
                </Grid>
            </Grid>
            {
                list?.length > 0 ?
                    <TableWithPagination
                        heading={heading}
                        rows={list}
                        path={domesticContactListPath}
                        dataType={domesticContactListDataType}
                        showpagination={true}
                        tableType="no-side"
                        handleIcon={handleIcon}
                        handlePagination={handlePagination}
                        handleChangeLimit={handleChangeLimit}
                        totalRowsCount={totalRowsCount}
                        page={page}
                        limit={limit}
                        height={`calc(100vh - 300px)`}
                    /> :
                    <Box className={classes.noDataBox}>
                        <Typography className={classes.noDataFound}>{t("No public directories to show")}</Typography>
                    </Box>
            }
        </Box>
    )
}
export default withTranslation("domesticContacts")(DomesticContactsTable); 