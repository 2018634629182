import { makeStyles } from "@mui/styles"
import { FontFamilySwitch, remCalc } from "../../utils"

export const projectCategoryStyle = makeStyles((theme) => ({
    tabtitle: {
        fontSize: "0.75rem",
        color: '#4E5A6B',
        fontFamily: FontFamilySwitch().bold,
    },
    tabtitle1: {
        fontSize: "0.75rem",
        color: '#5078E1',
        fontFamily: FontFamilySwitch().bold,
    },
    subtabtitle: {
        fontSize: "0.75rem",
        color: '#4E5A6B',
        fontFamily: FontFamilySwitch().regular,
    },
    subtabtitle1: {
        fontSize: "0.75rem",
        color: '#5078E1',
        fontFamily: FontFamilySwitch().regular,
    },
    selectBack1: {
        backgroundColor: '#F1F7FF',
        display: 'flex',
        alignItems: 'center',
        padding: '10px 22px 10px 16px',
        borderRadius: theme.palette.borderRadius
    },
    selectBack: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
    },
    filterButton: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "12px"
    },
    createBtn: {
        height: "40px",
        fontSize: remCalc(14),
        fontFamily: FontFamilySwitch().semiBold,
    },
    drawerTitle: {
        fontSize: remCalc(16),
        fontFamily: FontFamilySwitch().bold,
    },
    drawerCreateBtn:{
        height: "48px",
        fontSize: remCalc(14),
        fontFamily: FontFamilySwitch().bold
    },
    drawer:{
        "& .MuiPaper-root":{
            width: 300
        }
    }
}))