import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const KycDetailsStyle = makeStyles((theme) => ({
  root: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
    height: `calc(100vh - 147px)`,
    overflow: "hidden",
    margin: "16px"
  },
  filterButton: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "12px"
  }
}))

export const KycDetailViewStyle = makeStyles((theme) => ({
  root: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
    height: `calc(100vh - 280px)`,
    overflow: "hidden",
    margin: "16px 22px 16px 22px"
  },
  filterButton: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "12px"
  },
  declineBtn: {
    background: theme.palette.error.main,
    color: "#fff",
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().bold,
    height: 40,
    width: 112,
    '&:hover': {
      background: theme.palette.error.main,
      color: "#fff"
    }
  },
  approvalBtn: {
    background: theme.palette.success.main,
    color: "#fff",
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().bold,
    height: 40,
    width: 112,
    '&:hover': {
      background: theme.palette.success.main,
      color: "#fff"
    },
    marginInlineStart: "16px"
  }
}))


export const ApprovalPopupStyle = makeStyles((theme) => ({

  decline: {
    width: "100%",
    borderRadius: "4px",
    backgroundColor: "#FF4B4B",
    color: "#fff",
    fontFamily: FontFamilySwitch().bold,
    "&:hover": {
      backgroundColor: "#FF4B4B",
      color: "#fff",
    },
    height: 40
  },
  approve: {
    width: "100%",
    borderRadius: "4px",
    backgroundColor: "#5AC782",
    color: "#fff",
    marginLeft: '10px',
    fontFamily: FontFamilySwitch().bold,
    "&:hover": {
      backgroundColor: "#5AC782",
      color: "#fff",
    },
    height: 40
  },
  kycForm: {
    position: "absolute",
    backgroundColor: "white",
    zIndex: "1",
    bottom: "0px",
    left: "0px",
    right: "0px",
    width: "100%",
    padding: "12px",
    boxShadow: "0px -10px 10px #00000014"
  },
  title: {
    color: theme.typography.color.tertiary,
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    textTransform: "capitalize"
  },
  content: {
    color: "#4E5A6B",
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().bold,
    textTransform: "capitalize"
  },
  bottomContent: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: 4
  },
  Verified: {
    color: theme.palette.success.main,
    margin: "0",
    background: "#EEF9EE",
    width: 90,
    padding: 2,
    textAlign: "center",
    borderRadius: 4,
    fontWeight: "bold",
    fontSize:"0.875rem"
  },
  remarkTitle: {
    color: theme.typography.color.primary,
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().bold,
    textTransform: "capitalize"
  },
  remarkContent: {
    color: theme.typography.color.secondary,
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().bold,
    textTransform: "capitalize"
  },
  innerContent: {
    height: 'calc(100vh - 300px)',
    overflow: "auto"
  },
  Declined: {
    color: theme.palette.error.main,
    margin: "0",
    background: "#FFECEC",
    width: 90,
    padding: 2,
    textAlign: "center",
    borderRadius: 4,
    fontWeight: "bold",
    fontSize:"0.875rem"
  },
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginTop: "8px"
  },
  sideColor: {
    backgroundColor: '#E4E8EE',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height:"340px",
    xs:2
  }
}))


export const RemarksPopupStyle = makeStyles((theme) => ({
  closebtn: {
    height: 40,
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
    background: "transparent",
    border: `1px solid ${theme.palette.border.primary}`,
    '&:hover': {
      background: "transparent",
      color: theme.typography.color.primary,
      border: `1px solid ${theme.palette.border.primary}`
    }
  },
  updatebtn: {
    height: 40,
    color: "#fff",
    fontFamily: FontFamilySwitch().bold,
    '&:hover': {
      color: "#fff",
    },
    marginInlineStart: "16px"
  },
  title: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.tertiary
  }
}))