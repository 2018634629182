import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Divider,
  Drawer,
  Link,
  Stack,
  Typography,
  Tooltip,
} from "@mui/material";
import { useStylesCardView } from "./style";
import { UnitDetailsDrawer } from "./unitDetailsDrawer";
import AddUnit from "../createUnit";
import { FontFamilySwitch } from "../../../utils";
import { createState } from "../utils";
import { CreateExternalUnitContext } from "../externalUnitContext";

const CardView = ({
  t,
  unit,
  deleteUnit = () => {},
  viewUnit = () => {},
  unitDetails,
  unitData,
  setUnitData,
  existingPricing,
  isDuplicate,
  getExternalUnits,
  companyId,
  currency,
}) => {
  const classes = useStylesCardView();
  const { formatPrice } = React.useContext(CreateExternalUnitContext);
  const [isEdit, setEdit] = React.useState({
    id: null,
    bool: true,
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dialog, setDialog] = React.useState(false);
  const closeDrawer = () => {
    setDialog(!dialog);
    setUnitData({ ...createState });
    setEdit({
      id: null,
      bool: false,
    });
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
    viewUnit();
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setUnitData({ ...createState });
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  return (
    <Box className={classes.card}>
      <Box sx={{ position: "relative", width: "100%", height: "120px" }}>
        {unit?.assets?.length > 0 ? (
          <img
            src={unit?.assets?.[0]?.url}
            alt={unit?.name}
            className={classes.asset}
            style={{ width: "100%", height: "100%", borderRadius: "4px" }}
          />
        ) : (
          <img
            src="/images/propertyImagePlaceholder.svg"
            alt={unit?.name}
            className={classes.asset}
            style={{ width: "100%", height: "100%", borderRadius: "4px" }}
          />
        )}
        <Stack
          className={classes.assetText}
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <Typography className={classes.text}> {t(unit?.purpose)}</Typography>
          <Divider className={classes.round} />
          <Typography className={classes.text}>
            {unit?.unitType?.name}
          </Typography>
        </Stack>
      </Box>
      <Box className={classes.availability}>{t("Available")}</Box>
      <Stack
        direction={"row"}
        spacing={2}
        mt={2}
        mb={"6px"}
        justifyContent={"space-between"}
      >
        <Typography className={classes.header}>{unit?.name}</Typography>
        <Stack direction={"row"}>
          <Typography
            className={isDuplicate ? classes.header1 : classes.header}
          >
            {currency} {formatPrice(unit?.pricing)}
          </Typography>
          <Tooltip
            title={`${t("Primary Charges")} ${currency} ${formatPrice(
              unit?.pricing
            )} ${t(unit?.period)}`}
            placement="right"
            arrow
          >
            <Box
              sx={{
                position: "relative",
                top: "-2px",
                "&:hover img": {
                  content: 'url("/images/infoblue.svg")',
                },
                marginInlineStart: 1,
              }}
            >
              <img src="/images/info.svg" />
            </Box>
          </Tooltip>
        </Stack>
      </Stack>
      <Box className={classes.propertyName}>{unit.property_name}</Box>
      <Box className={classes.units}>{`${t("Total Units in this Property:")} ${
        unit.numberOfUnits
      } ${t("Units")}`}</Box>
      <Divider className={classes.divider} />
      <Stack
        direction={"row"}
        spacing={1}
        alignItems={"center"}
        sx={{ width: "100%" }}
      >
        <img src="/images/link.svg" />
        <Box
          sx={{
            flexGrow: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {unit?.listings?.portal_url ? (
            <Link
              target="_blank"
              href={unit?.listings?.portal_url}
              className={classes.url}
            >
              {unit?.listings?.portal_url}
            </Link>
          ) : (
            <Typography sx={{ display: "inline-block" }}>-</Typography>
          )}
        </Box>
      </Stack>

      {!isDuplicate && (
        <Stack mt={2} direction="row" alignItems="center">
          <Button
            onClick={() => {
              viewUnit();
              handleDrawerOpen();
            }}
            className={classes.view}
            variant="outlined"
            sx={{ flex: 0.75, marginInlineEnd: 1 }}
          >
            {t("View Details")}
          </Button>
          <Tooltip title={t("Edit")} placement="top" arrow>
            <Box
              onClick={() => {
                viewUnit();
                setDialog(true);
              }}
              className={classes.editBox}
              sx={{ flex: 0.125, marginInlineEnd: 1 }}
            >
              <img src="/images/editblue.svg" alt={unit?.edit} />
            </Box>
          </Tooltip>

          <Tooltip title={t("Delete")} placement="top" arrow>
            <Box
              onClick={handleDialogOpen}
              className={classes.deleteBox}
              sx={{ flex: 0.125 }}
            >
              <img src="/images/delete.svg" alt={unit?.delete} />
            </Box>
          </Tooltip>
        </Stack>
      )}
      {/* Dialog for delete confirmation */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.publishDialog}
      >
        <DialogContent sx={{ padding: 0 }}>
          <DialogContentText sx={{ background: "transparent" }}>
            <div
              style={{
                paddingRight: "60px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                backgroundImage: `url(${"images/announsment.svg"})`,
                backgroundRepeat: "no-repeat",
                height: 250,
                width: 600,
              }}
            >
              <div>
                <Stack spacing={3}>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      fontFamily: FontFamilySwitch().semiBold,
                    }}
                  >
                    {t("Are you sure you want to delete this unit?")}
                  </Typography>
                  <Box variant="contained" display="flex" alignItems="center">
                    <Button
                      className={classes.Yes}
                      onClick={() => {
                        deleteUnit();
                        handleDialogClose();
                      }}
                    >
                      {t("Confirm")}
                    </Button>
                    <Button className={classes.No} onClick={handleDialogClose}>
                      {t("Cancel")}
                    </Button>
                  </Box>
                </Stack>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {/* Drawer for unit details */}
      <React.Fragment key={"right"}>
        <Drawer anchor={"right"} open={drawerOpen} onClose={handleDrawerClose}>
          <UnitDetailsDrawer
            t={t}
            currency={currency}
            unitDetails={unitDetails}
            closeDrawer={handleDrawerClose}
          />
        </Drawer>
      </React.Fragment>
      <React.Fragment key={"right"}>
        <Drawer anchor={"right"} open={dialog} onClose={closeDrawer}>
          <AddUnit
            t={t}
            closeDrawer={closeDrawer}
            unitData={unitData}
            setUnitData={setUnitData}
            isEdit={true}
            pricing={existingPricing}
            getExternalUnits={getExternalUnits}
            companyId={companyId}
            currency={currency}
          />
        </Drawer>
      </React.Fragment>
    </Box>
  );
};

export default CardView;
