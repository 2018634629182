import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    root: {
        margin: "14px",
    },
    property_content: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px 0px",
        height: `calc(100vh - 147px)`,
        overflow: "hidden",
    },
    occupant_content: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        height: `calc(100vh - 147px)`,
        overflow: "hidden",
    },
    selected_property_card: {
        fontSize: "0.875rem",
        color: "white",
        fontFamily: FontFamilySwitch().bold,
        borderRadius: "4px",
        padding: "6px",
        backgroundColor: "#5078E1",
        alignItems: "center",
    },
    unselected_property_card: {
        fontSize: "0.875rem",
        color: "#1C1C1C",
        padding: "6px",
        fontFamily: FontFamilySwitch().semiBold,
        alignItems: "center",
    },
    unselected_property_name: {
        fontSize: "0.875rem",
        color: "#1C1C1C",
        padding: "6px",
        fontFamily: FontFamilySwitch().semiBold,
        alignItems: "center"

    },
    selected_property_name: {
        fontSize: "0.875rem",
        color: "white",
        fontFamily: FontFamilySwitch().bold,
        borderRadius: "4px",
        padding: "6px",
        backgroundColor: "#5078E1",
        alignItems: "center"

    },
    selected_property_no: {
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: "0.75rem",
        color: "white",
        alignItems: "center",
    },
    unselected_property_no: {
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: "0.75rem",
        color: "#7C8594",
        alignItems: "center",
    },
    no_data_text: {
        textAlign: "center",
        fontSize: "0.875rem",
        color: "#4E5A6B",
        fontFamily: FontFamilySwitch().bold,
        fontWeight: "bold",
        marginTop: "22px",
        overflow: "hidden",
    },
    unselected_period_box: {
        display: "flex",
        alignItems: "center",
        padding: "4px 8px",
        borderRadius: theme.palette.borderRadius,
        height: "40px",
        border: "1px solid #E4E8EE",
        backgroundColor: "white",
    },
    unselected_period_text: {
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: "#4E5A6B",
    },
    selected_period_box: {
        display: "flex",
        alignItems: "center",
        padding: "4px 8px",
        borderRadius: theme.palette.borderRadius,
        height: "40px",
        border: "1px solid #5078E1",
        backgroundColor: "#5078E1",
    },
    selected_period_text: {
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: "white",
    },
    occupant_default_image: {
        height: "250px",
        width: "100%",
        objectFit: "cover"
    },
    occupant_details_content: {
        padding: "16px",
        border: "1px solid #E4E8EE",
        borderRadius: "4px",
        backgroundColor: "#fff"
    },
    occupant_details_label: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.tertiary
    },
    occupant_details_value_rtl: {
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.primary,
        marginTop: "6px"
    },
    occupant_details_value_ltr: {
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: "#091B29",
        marginTop: "6px",
        textAlign: '-webkit-match-parent',
        direction: "ltr"
    },
}));