import { Box } from "@mui/material";
import React from "react";
import { CompanySettingsList } from "../../../components";
import { basicSettingState, returnSaveData } from "../../../utils/clientsettings";

import { SettingSave } from "../../companyCreation/components";
import { useStyles } from "../styles";


export const OnboardSettings = ({
    selected = {},

    settings = {},
    isDisableBtn = false,
    onSubmitSettings = () => false,
    t = () => false

}) => {
    const classes = useStyles();

    const [data2, setData2] = React.useState({ ...basicSettingState })


    //update data
    const updateState = (key, value, index) => {
        const updatedOnboardTypes = [...data2?.onboard_type];
        updatedOnboardTypes[index].value = value?.value;
        updatedOnboardTypes[index].app = value?.value?.value;
        setData2({ ...data2, [key]: updatedOnboardTypes })
    }



    //initial load
    React.useLayoutEffect(() => {
        setData2(settings)
        // eslint-disable-next-line
    }, [settings])


    // const validateForm = () => {
    //     let isValid = true;
    //     let error = data.error;

    //     if (data?.default_base_currency?.length === 0) {
    //         isValid = false;

    //         error.default_base_currency = t("Default Base Currency is Required");
    //     }
    //     if (data?.default_time_zone?.length === 0) {
    //         isValid = false;
    //         error.default_time_zone = t("Default TimeZone is Required");
    //     }

    //     setData({ ...data, error });
    //     return isValid;
    // }
    //on submit form
    const onSubmit = () => {

        const payload = returnSaveData(data2)
        onSubmitSettings(payload)

    }
    return (
        <Box>
            {/*setting save */}
            <SettingSave title={selected?.label} subTitle={selected?.subtitle} btn={true} onSave={onSubmit} isDisableBtn={isDisableBtn} t={t} />
            <Box className={classes.settingRoot}>
                {data2?.onboard_type?.map((i, index) => {
                    return (
                        < Box padding="8px 12px" >
                            <CompanySettingsList

                                title={t(i?.type)}
                                onChange={(value) => updateState("onboard_type", value, index)}
                                value={i}
                                bgColor="#F2F4F7"
                                type="onboardselect"
                                border={false}
                                onboardSelectOptions={[{ label: t("Resident"), value: "resident" }, { label: t("Tenant"), value: "tenant" }]}
                                selectBoxWidth={"250px"}

                            />

                        </Box>

                    )
                })}



            </Box>
        </Box >
    )
}