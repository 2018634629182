import { Avatar, Box, Stack, Typography, Divider, IconButton } from "@mui/material";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import { stringAvatar } from "../../../utils";
import { useStyles } from "./styles";

export const InterestedUnitsCard = ({ t = () => false, list = [], removeSpecificUnit }) => {
    const classes = useStyles();

    return (
        <Box className={classes.interestunitcard}>
            <Box className={classes.unitproperty}>
                <Typography className={classes.intTitle}>{t("INTERESTED PROPERTIES AND UNITS")}</Typography>
                {
                    list?.map((data, index) => {
                        return (
                            <React.Fragment key={data.unit_id}>
                                <Stack direction="row" mt={2} alignItems="center">
                                    <Avatar src={data?.logo} {...stringAvatar(data?.name)} />
                                    <Box mt={0.5} marginInlineStart={1}>
                                        <Typography className={classes.unit_name}>{data?.name ?? ""}</Typography>
                                        <Typography className={classes.property_name}>
                                            {data?.unit_no},{data.property_name ? data?.property_name : data?.property?.name}
                                        </Typography>
                                    </Box>
                                    <IconButton onClick={() => removeSpecificUnit(data.unit_id)} style={{ marginLeft: 'auto' }}>
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </Stack>
                                <Box mt={1}>
                                    {index !== list.length - 1 &&
                                        <Divider />
                                    }
                                </Box>
                            </React.Fragment>
                        );
                    })}
            </Box>
        </Box>
    );
};