import {
  Box,
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { SearchFilter, Subheader, TableWithPagination } from "../../components";
import { NewLoader } from "../../components/newLoader";
import { config } from "../../config";
import { AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import {
  NetWorkCallMethods,
  accessCheckRender,
  getCompanyOption,
  getRoutePermissionNew,
  useWindowDimensions,
} from "../../utils";
import {
  Heading,
  Path,
  Type,
} from "../preventiveMaintenancePlanner/utils/tableUtils";
import { useStyles } from "./style";

const DynamicMaintenancePlanner = ({ t = () => false }) => {
  const classes = useStyles();
  const size = useWindowDimensions();
  const matches = useMediaQuery("(min-width:900px)");
  const navigate = useNavigate();
  const { state } = useLocation();
  const auth = React.useContext(AuthContext);
  const backdrop = React.useContext(BackdropContext);
  const [companyList, setCompanyList] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState("");
  const [tabValue, setTabValue] = React.useState("1");
  const [searchText, setSearchText] = React.useState("");
  // const [filterData, setFilterData] = React.useState({})
  // const [filterDrawer, setFilterDrawer] = React.useState(false)
  const [tableData, setTableData] = React.useState([]);
  // const [details, setDetails] = React.useState({ isLoading: true })
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  // const [showMore, setShowMore] = React.useState(false)
  const [loading, setLoading] = React.useState(true);
  // const [infoType, setInfoType] = React.useState("")
  const [permission, setPermission] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          setCompanyList(company?.list);
          setSelectedCompany(company?.selected);
          getPlannerList(
            company?.selected?.value,
            0,
            10,
            state?.tabValue ?? tabValue,
            ""
          );
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);
  React.useEffect(() => {
    if (state?.tabValue) {
      setTabValue(state?.tabValue);
    }
    // setSelectedCompany({ value: 1 })
    // getPlannerList(1, 0, 10, state?.tabValue??tabValue, "")
    //eslint-disable-next-line
  }, []);
  const getPlannerList = (
    company_id,
    offset = 0,
    limit = 10,
    activeTab = "1",
    search = ""
  ) => {
    setLoading(true);
    const payload = {
      company_id: company_id,
      start: offset,
      length: limit,
      search: search,
      is_draft: activeTab === "1" ? false : true,
    };

    NetworkCall(
      `${config.api_url}/preventive_dynamic_plan`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        const result = response?.data?.data?.map((x) => {
          return {
            ...x,
            is_draft: activeTab === "1" ? false : true,
            execution: `${moment(x?.start_date).format(
              "DD MMM YYYY"
            )} - ${moment(x?.end_date).format("DD MMM YYYY")}`,
          };
        });
        setTableData({ list: result, count: response?.data?.count });
        // getMiniDashboardDetails(result?.[0])
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  // const getMiniDashboardDetails = (data) => {
  //     setDetails({ ...details, isLoading: true })
  //     const payload = {
  //         "id": data?.id
  //     }

  //     NetworkCall(
  //         `${config.api_url}/preventive_plan/get_mini_dashboard`,
  //         NetWorkCallMethods.post,
  //         payload,
  //         null,
  //         true,
  //         false
  //     ).then((response) => {
  //         setDetails({ ...response?.data?.data, id: data?.id, isLoading: false })
  //     }).catch((err) => {
  //         setDetails({ ...details, isLoading: false })
  //     })
  // }
  const handleCompanyChange = (value) => {
    setSelectedCompany(value);
    getPlannerList(value?.value, 0, 10, tabValue, "");
  };
  const handleTabChange = (newValue) => {
    setTabValue(newValue);
    getPlannerList(selectedCompany?.value, 0, 10, newValue, "");
  };
  const handleSearch = (value) => {
    setSearchText(value);
    getPlannerList(selectedCompany?.value, 0, 10, tabValue, value);
  };
  const handleTableIcon = (type, data) => {
    if (type === "view") {
      // getMiniDashboardDetails(data)
      // const result = tableData?.list?.map((x) => {
      //     return {
      //         ...x,
      //         selectedRow: x?.id === data?.id ?? false
      //     }
      // })
      // setTableData({ ...tableData, list: result })
    } else if (type === "info") {
      if (data?.is_draft) {
        navigate(Routes?.createMaintenancePlanner, {
          state: {
            company: selectedCompany,
            tableData: data,
            is_draft: true,
            is_edit: true,
            planner_type: "Dynamic",
            tabValue: tabValue,
          },
        });
      } else {
        navigate(Routes?.viewMaintenancePlanner, {
          state: {
            company: selectedCompany,
            tableData: data,
            is_draft: false,
            isView: true,
            planner_type: "Dynamic",
            tabValue: tabValue,
          },
        });
      }
    }
  };
  const handleTablePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getPlannerList(selectedCompany?.value, offset, 10, tabValue, "");
  };
  const handleTablePageLimit = (value) => {
    setLimit(value);
    setPage(1);
    getPlannerList(selectedCompany?.value, 0, limit, tabValue, "");
  };
  const handleAdd = (val) => {
    navigate(Routes?.createMaintenancePlanner, {
      state: {
        company: selectedCompany,
        planner_type: "Dynamic",
        pmp_type: val,
        tabValue: tabValue,
      },
    });
  };
  // const getHeading = (value) => {
  //     switch (value) {
  //         case "Projected BOM": {
  //             return {
  //                 heading: BOMHeading(t),
  //                 path: BOMPath,
  //                 dataType: BOMDataType
  //             }
  //         }
  //         case "Projected BOM Items": {
  //             return {
  //                 heading: BOMHeading(t),
  //                 path: BOMPath,
  //                 dataType: BOMDataType
  //             }
  //         }
  //         case "Executed Jobs": {
  //             return {
  //                 heading: ExecutedJobsHeading(t),
  //                 path: ExecutedJobsPath,
  //                 dataType: ExecutedJobsDataType
  //             }
  //         }
  //         case "Invoices": {
  //             return {
  //                 heading: InvoiceHeading(t),
  //                 path: InvoicePath,
  //                 dataType: InvoiceDataType
  //             }
  //         }
  //         case "Assets Covered": {
  //             return {
  //                 heading: AssetHeading(t),
  //                 path: AssetPath,
  //                 dataType: AssetDataType
  //             }
  //         }
  //         case "Resources": {
  //             return {
  //                 heading: ResourceHeading(t),
  //                 path: ResourcePath,
  //                 dataType: ResourceDataType
  //             }
  //         }
  //         default: {
  //             return {
  //                 heading: ScheduleWorkOrdersHeading(t),
  //                 path: ScheduleWorkOrdersPath,
  //                 dataType: ScheduleWorkOrdersDataType
  //             }
  //         }
  //     }
  // }
  const render = () => {
    return (
      <Box>
        <Subheader
          hideBackButton={true}
          title={t("Dynamic Preventive Maintenance Planner")}
          select
          options={companyList}
          value={selectedCompany}
          onchange={(e) => {
            handleCompanyChange(e);
          }}
          // padding={"12px 4px"}
        />
        {loading ? (
          <NewLoader />
        ) : (
          <Box
            className={classes.root}
            height={matches ? "auto" : size?.height - 150}
            overflow="scroll"
          >
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box sx={{ padding: "16px 16px 0px 16px" }}>
                  <Stack direction="row" spacing={1.5} alignItems="center">
                    <Box onClick={() => handleTabChange("1")}>
                      <Typography
                        className={
                          tabValue === "1"
                            ? classes.selectedPublishText
                            : classes.publishtext
                        }
                        sx={{ marginBottom: "8px" }}
                      >
                        {t("Published")}
                      </Typography>
                      <Box
                        sx={{
                          borderBottom:
                            tabValue === "1"
                              ? "3px solid #5078E1"
                              : "3px solid #FFFFFF",
                        }}
                      />
                    </Box>
                    <Box onClick={() => handleTabChange("2")}>
                      <Typography
                        className={
                          tabValue === "2"
                            ? classes.selectedPublishText
                            : classes.publishtext
                        }
                        sx={{ marginBottom: "8px" }}
                      >
                        {t("Draft")}
                      </Typography>
                      <Box
                        sx={{
                          borderBottom:
                            tabValue === "2"
                              ? "3px solid #5078E1"
                              : "3px solid #FFFFFF",
                        }}
                      />
                    </Box>
                  </Stack>
                </Box>
                <Divider />
                <Box p={2}>
                  <Grid container className={classes.content} spacing={1}>
                    <Grid item xs={4}>
                      <SearchFilter
                        value={searchText}
                        placeholder={t("Search")}
                        handleChange={(value) => handleSearch(value)}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <Box display={"flex"} justifyContent={"end"}>
                        <Stack
                          direction="row"
                          spacing={2}
                          divider={<Divider orientation="vertical" flexItem />}
                        >
                          {/* <IconButton onClick={() => setFilterDrawer(!filterDrawer)}
                                                    className={classes.filterButton}>
                                                    <Badge variant="dot" color="primary"
                                                        invisible={!(filterData.is_active?.length > 0)}>
                                                        <FilterIMG color="#091b29" />
                                                    </Badge>
                                                </IconButton> */}
                          {permission?.create && (
                            <Button
                              variant="contained"
                              className={classes.button}
                              onClick={handleClick}
                            >
                              {t("Create")}
                            </Button>
                          )}
                        </Stack>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                          PaperProps={{
                            sx: {
                              backgroundColor: "white",
                              border: "1px solid #E4E8EE",
                              boxShadow: "0px 0px 16px #00000014",
                              borderRadius: "8px",
                              padding: "0px",
                              marginTop: "8px",
                            },
                          }}
                        >
                          <MenuItem onClick={() => handleAdd("Asset")}>
                            <Stack
                              direction={"row"}
                              spacing={1}
                              alignItems="center"
                            >
                              <img
                                src="/images/selectedAsset.svg"
                                alt="unit icon"
                              />
                              <Box>
                                <Typography className={classes.plantitle}>
                                  {t("Asset")}
                                </Typography>
                                <Typography className={classes.examples}>
                                  {t("Eg:Ac,Lift,Tv etc..")}
                                </Typography>
                              </Box>
                            </Stack>
                          </MenuItem>
                          <Box mt={1} mb={1}>
                            <Divider />
                          </Box>
                          <MenuItem onClick={() => handleAdd("Unit")}>
                            <Stack
                              direction={"row"}
                              spacing={1}
                              alignItems="center"
                            >
                              <img
                                src="/images/propertdashboard/block.svg"
                                alt="unit icon"
                              />
                              <Box>
                                <Typography className={classes.plantitle}>
                                  {t("Unit")}
                                </Typography>
                                <Typography className={classes.examples}>
                                  {t("Eg:2BHK,villa etc..")}
                                </Typography>
                              </Box>
                            </Stack>
                          </MenuItem>
                        </Menu>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box mt={2}>
                    <TableWithPagination
                      heading={Heading(t)}
                      rows={tableData?.list}
                      path={Path}
                      showpagination={true}
                      showpdfbtn={false}
                      showexcelbtn={false}
                      showSearch={false}
                      handleIcon={handleTableIcon}
                      onClick={() => false}
                      tableType="no-side"
                      dataType={Type}
                      handlePagination={handleTablePagination}
                      handleChangeLimit={handleTablePageLimit}
                      totalRowsCount={tableData?.count}
                      page={page}
                      limit={limit}
                      height={"calc(100vh - 360px)"}
                      view={permission?.read}
                      edit={permission?.update}
                      delete={permission?.delete}
                      noDataText={t("No Dynamic Plans created yet")}
                      // enable_double_click={true}
                      enable_single_click={true}
                    />
                  </Box>
                </Box>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={4} lg={4} sx={matches ? { borderLeft: "1px solid #E4E8EE" } : { borderTop: "1px solid #E4E8EE" }}>
                                {details?.isLoading ?
                                    <NewLoader />
                                    :
                                    <Box p={2}>
                                        <Typography className={classes.title}>{t("Overall dashboard")}</Typography>
                                        <Box height={size?.height - 200} overflow={"scroll"}>
                                            <Box mt={2}>
                                                <Grid container spacing={2}>
                                                    {
                                                        cardData(t, details)?.map((val) => {
                                                            return (
                                                                <Grid item xs={12} sm={6} md={6} lg={6} onClick={() => showMoreDetails(val)}>
                                                                    <Box className={classes.card}>
                                                                        {val?.icon}
                                                                        <Box mt={0.5}>
                                                                            <Typography className={classes.countText}>{val?.count}</Typography>
                                                                        </Box>
                                                                        <Box mt={0.5} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                            <Typography className={classes.valueName}>{val?.name}</Typography>
                                                                            <KeyboardArrowRight sx={{ color: "#98A0AC", cursor: "pointer" }} />
                                                                        </Box>
                                                                    </Box>
                                                                </Grid>
                                                            )
                                                        })
                                                    }
                                                </Grid>
                                            </Box>
                                            <Box mt={1} className={classes.card}>
                                                {
                                                    BomDetails(t, details)?.map((val, index) => {
                                                        return (
                                                            <>
                                                                <Box display={"flex"} justifyContent={"space-between"} alignItems="center">
                                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                                        {val?.icon}
                                                                        <Box>
                                                                            <Typography className={classes.countText}>{val?.count}</Typography>
                                                                            <Typography className={classes.valueName}>{val?.name}</Typography>
                                                                        </Box>
                                                                    </Stack>
                                                                    <KeyboardArrowRight sx={{ color: "#98A0AC" }} onClick={() => val?.name === "Projected BOM Items" ? showMoreDetails(val) : false} />
                                                                </Box>
                                                                {BomDetails(t, details)?.length - 1 !== index &&
                                                                    <Box mt={1} mb={1}><Divider /></Box>
                                                                }
                                                            </>
                                                        )
                                                    })
                                                }
                                            </Box>
                                            <Box mt={1} className={classes.card}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                                        <Box sx={{ borderRight: "1px solid #E4E8EE" }} onClick={() => showMoreDetails({ name: "Invoices" })}>
                                                            <InvoiceRaisedIcon />
                                                            <Box mt={0.5}>
                                                                <Typography className={classes.countText}>{details?.invoice_count ?? 0}</Typography>
                                                            </Box>
                                                            <Box mt={0.5} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                <Typography className={classes.valueName}>{t("Invoices Raised")}</Typography>
                                                                <KeyboardArrowRight sx={{ color: "#98A0AC", cursor: "pointer" }} />
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                                        <Box>
                                                            <PaymentCollectedIcon />
                                                            <Box mt={0.5}>
                                                                <Typography className={classes.countText}>{details?.payment_collected ?? 0}</Typography>
                                                            </Box>
                                                            <Box mt={0.5} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                <Typography className={classes.valueName} noWrap>{t("Payment Collected")}</Typography>
                                                                <KeyboardArrowRight sx={{ color: "#98A0AC", cursor: "pointer" }} />
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Box>
                                    </Box>
                                }
                            </Grid> */}
            </Grid>
          </Box>
        )}
        {/* <Drawer
                    anchor="right"
                    open={showMore}
                    PaperProps={{
                        sx: { width: "90%" },
                    }}
                    onClose={() => setShowMore(false)}
                >
                    <Box>
                        <Stack direction="row" spacing={2} alignItems="center" p={1.5}>
                            <Box onClick={() => setShowMore(false)} style={{ cursor: "pointer" }}><CloseIconWithBG /></Box>
                            <Typography className={classes.drawerHeader}>{t(infoType?.name)}</Typography>
                        </Stack>
                        <Divider />
                    </Box>
                    <Box mt={2}>
                        <CommonTable
                            t={t}
                            heading={getHeading(infoType?.name)?.heading}
                            path={getHeading(infoType?.name)?.path}
                            dataType={getHeading(infoType?.name)?.dataType}
                            viewMore={true}
                            infoType={infoType}
                        />
                    </Box>
                </Drawer> */}
      </Box>
    );
  };
  return <>{accessCheckRender(render, permission)}</>;
};
export default withTranslation("maintenancePlanner")(DynamicMaintenancePlanner);
