import React from "react";
import {
  Box,
  Divider,
  IconButton,
  Stack,
  Tabs,
  Tab,
  Button,
  Grid,
  useMediaQuery,
  useTheme,
  Drawer,
} from "@mui/material";
import { useStyles } from "./styles";
import {
  enum_types,
  enumSelect,
  getMobileLimitBasedOnCC,
  NetWorkCallMethods,
} from "../../utils";
import { AlertContext } from "../../contexts";
import { AlertProps } from "../../utils";
import { CustomTypography } from "../companyCreation/components";
import CloseIcon from "../../assets/closeIcon";
import styled from "@mui/material/styles/styled";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import DuplicateUnit from "./duplicateUnit";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import Step1 from "./components/step1";
import Step2 from "./components/step2";
import Step3 from "./components/step3";
import { createState } from "./utils";
import { CreateExternalUnitContext } from "./externalUnitContext";

const AddUnit = ({
  t,
  closeDrawer,
  unitData,
  setUnitData,
  isEdit,
  handleDeleteUnit,
  viewUnitById,
  unitDetails,
  pricing,
  getExternalUnits,
  companyId,
  currency,
}) => {
  const { manualVendorResponse } = React.useContext(CreateExternalUnitContext);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(isEdit ?? false);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [uuid, setUUID] = React.useState(1);
  const alert = React.useContext(AlertContext);
  const [dialog, setDialog] = React.useState(false);
  const [units, setUnits] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [enumValue, setEnumValue] = React.useState({
    purpose: [],
    revenue_type: [],
    pricing_period_type: [],
  });

  const custom_options = [
    { label: "Acre", value: "1" },
    { label: "Sq.Meter", value: "2" },
    { label: "Sq.Feet", value: "3" },
    { label: "Hectare", value: "4" },
    { label: "Cents", value: "5" },
    { label: "Sq.Yard", value: "6" },
    { label: "Sq.Mile", value: "7" },
    { label: "Object", value: "8" },
    { label: "Seat", value: "9" },
  ];

  const chargeOptions = {
    secondary: {
      label: t("Secondary Charges"),
      tooltip: t(
        "Identify and configure pricing for maintenance and other services"
      ),
    },
    refundable: {
      label: t("Refundable Charges"),
      tooltip: t(
        "Configure pricing which has  to be calculated as refundable amount"
      ),
    },
    onetime: {
      label: t("One Time Charges"),
      tooltip: t(
        "Configure pricing which has to be calculated as one time charges"
      ),
    },
    other: { label: t("Other Charges"), tooltip: t("Other Charges") },
  };

  const pricingInfo = t("Rental Price, Sale Price");

  const CustomtoolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
  ))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "black",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "black",
      padding: "10px",
      color: "white",
      border: "1px solid #E4E8EE",
    },
  }));

  // Update state
  const updateState = (key, value) => {
    let error = unitData?.error;
    error[key] = "";
    setUnitData({ ...unitData, [key]: value });
  };

  const updateListingState = (key, value) => {
    let error = unitData?.error;
    error[key] = "";
    setUnitData({
      ...unitData,
      ListingData: { ...unitData?.ListingData, [key]: value },
    });
  };

  const updatePricingState = (key, value) => {
    let updatedUnitData = {
      ...unitData,
      error: {
        ...unitData?.error,
        pricingData: {
          ...unitData?.error?.pricingData,
          [key]: "",
        },
      },
      pricingData: {
        ...unitData?.pricingData,
        [key]: value,
      },
    };

    setUnitData(updatedUnitData);
  };

  const ValidateMobile = (value) => {
    const limit = getMobileLimitBasedOnCC(value?.mobile_code);
    let error = unitData.error;
    if (value?.mobile?.length === limit) {
      setUnitData({
        ...unitData,
        error: {
          ...error,
          ListingData: {
            ...error.ListingData,
            phone: "",
          },
        },
      });
      return true;
    } else return false;
  };

  const ValidURL = (url) => {
    const urlPattern = /^(https?:\/\/)[^\s/$.?#].[^\s]*$/i;
    return urlPattern.test(url);
  };

  const validateForm = () => {
    let isValid = true;
    let isMobileValid = true;
    let isPriceValid = true;
    let isURLValid = true;
    let error = {
      ...unitData?.error,
      pricingData: {
        ...unitData?.error?.pricingData,
      },
    };

    if (value === 0) {
      if (unitData?.purpose?.length === 0) {
        error.purpose = t("Purpose is Required");
        isValid = false;
      }
      if (unitData?.developer?.length === 0) {
        error.developer = t("Vendor / Owner is Required");
        isValid = false;
      }
      if (unitData?.property_name?.length === 0) {
        error.property_name = t("Property Name is Required");
        isValid = false;
      }
      if (unitData?.property_type?.length === 0) {
        error.property_type = t("Property Type is Required");
        isValid = false;
      }
      if (unitData?.category?.length === 0) {
        error.category = t("Category is Required");
        isValid = false;
      }
      if (unitData?.address?.length === 0) {
        error.address = t("Address is Required");
        isValid = false;
      }
      if (unitData?.name?.length === 0) {
        error.name = t("Unit Name is Required");
        isValid = false;
      }
      if (unitData?.revenue_type?.length === 0) {
        error.revenue_type = t("Revenue Type is Required");
        isValid = false;
      }
      if (unitData?.unit_type?.length === 0) {
        error.unit_type = t("Unit Type is Required");
        isValid = false;
      }
      if (
        unitData?.total_area?.length === 0 ||
        unitData?.uom_id?.length === 0
      ) {
        error.total_area = t("Total Area and UOM Required");
        isValid = false;
      }
      if (unitData?.total_area <= 0) {
        error.total_area = t("Total Area must be a Positive Number");
        isValid = false;
      }

      if (unitData?.bed?.length === 0) {
        error.bed = t("Bed is Required");
        isValid = false;
      } else if (unitData?.bed <= 0) {
        error.bed = t("Bed should be a Positive Number");
        isValid = false;
      }
      if (unitData?.bath?.length === 0) {
        error.bath = t("Bath is Required");
        isValid = false;
      } else if (unitData?.bath <= 0) {
        error.bath = t("Bath should be a Positive Number");
        isValid = false;
      }
      if (unitData?.latitude?.length === 0) {
        error.latitude = t("Latitude is Required");
        isValid = false;
      } else if (unitData?.latitude < -90 || unitData?.latitude > 90) {
        error.latitude = t("Latitude should be between -90 and 90");
        isValid = false;
      }
      if (unitData?.longitude?.length === 0) {
        error.longitude = t("Longitude is Required");
        isValid = false;
      } else if (unitData?.longitude < -180 || unitData?.longitude > 180) {
        error.longitude = t("Longitude should be between -180 and 180");
        isValid = false;
      }
    }

    if (value === 1) {
      if (!unitData?.payment_period) {
        error.payment_period = t("Payment Perioid cannot be empty");
        isValid = false;
      }
      if (
        unitData?.pricingData?.primary?.length === 0 ||
        !unitData?.pricingData?.primary
      ) {
        error.pricingData.primary = t("Primary Charge is Required");
        isValid = false;
      } else if (unitData?.pricingData?.primary <= 0) {
        error.pricingData.primary = t(
          "Primary Charge should be a Positive Number"
        );
        isValid = false;
        isPriceValid = false;
      }

      Object.entries(chargeOptions).forEach(([key, label]) => {
        if (unitData?.pricingData[key]?.length > 0) {
          // Only then check if the value is empty
          if (unitData.pricingData[key][0]?.value === "") {
            error.pricingData[key] = t(`${label} cannot be empty`);
            isValid = false;
          } else if (unitData.pricingData[key][0]?.value <= 0) {
            error.pricingData[key] = t(`${label} should be a Positive Number`);
            isValid = false;
            isPriceValid = false;
          }
        }
      });
    }

    if (value === 2) {
      if (
        unitData?.ListingData?.portal_url &&
        (unitData.ListingData.portal_url.length === 0 ||
          !ValidURL(unitData.ListingData.portal_url))
      ) {
        isURLValid = false;
        isValid = false;
      }

      if (
        unitData?.ListingData?.phone &&
        unitData?.ListingData?.phone?.mobile !== ""
      ) {
        if (!ValidateMobile(unitData?.ListingData?.phone)) {
          isMobileValid = false;
          isValid = false;
          error.ListingData.phone = t("Please enter valid number");
        }
      }
    }

    setUnitData({ ...unitData, error });
    if (!isValid) {
      if (!isMobileValid && !isValid) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Please enter valid number"),
        });
      } else if (!isPriceValid && !isValid) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.warning,
          msg: t("Price should be a positive number"),
        });
      } else if (!isURLValid && !isValid) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Please enter valid URL"),
        });
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Please fill all mandatory fields"),
        });
      }
    }
    return isValid;
  };

  // Handle next
  const handleNext = () => {
    if (validateForm() && value < 2) {
      setValue(value + 1);
    }
  };

  // Handle previous
  const handlePrevious = () => {
    if (value > 0) {
      setValue(value - 1);
    }
  };

  const closeDraw = () => {
    setDialog(!dialog);
  };

  // Handle Add (Final Submit)
  const handleAdd = () => {
    if (validateForm()) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.success,
        msg: t("Check for duplicates"),
      });

      const payload = {
        search: unitData?.name,
        company_id: companyId,
      };

      // get API call
      NetworkCall(
        `${config.api_url}/external_units/getAllUnits`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((r) => {
          const units = r?.data?.data?.data;
          // Calculate the number of units for each property_name
          const propertyUnitCount = units.reduce((acc, unit) => {
            acc[unit.property_name] = (acc[unit.property_name] || 0) + 1;
            return acc;
          }, {});

          // Add pricing and numberOfUnits to each unit
          const unitsWithPricingAndCount = units.map((unit) => ({
            ...unit,
            pricing: unit.pricing.reduce((acc, price) => acc + price.value, 0),
            numberOfUnits: propertyUnitCount[unit.property_name],
          }));

          setUnits(unitsWithPricingAndCount);
          setCount(r?.data?.data?.count);
        })
        .catch((e) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Some Thing Went Wrong"),
          });
          setLoading(false);
        });
      setDialog(!dialog);
    }
  };

  const getEnum = async () => {
    const result = await enumSelect([
      enum_types?.purpose_global_type,
      enum_types.revenue_type,
      enum_types?.external_pricing_period,
    ]);

    if (result) {
      setEnumValue({
        purpose: result?.purpose_global_type,
        revenue_type: result?.revenue_type,
        pricing_period_type: result?.external_pricing_period,
      });
    }
    setLoading(false);
  };

  const handleAddClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAddCharge = (key) => {
    setUnitData((prev) => {
      if (prev.pricingData[key]?.length > 0) return prev; // Prevent multiple charges
      return {
        ...prev,
        pricingData: {
          ...prev.pricingData,
          [key]: [{ value: "", error: "" }],
        },
      };
    });
    handleMenuClose();
  };

  const handleRemoveCharge = (key) => {
    setUnitData((prev) => ({
      ...prev,
      pricingData: {
        ...prev.pricingData,
        [key]: [], // Remove charge, ensuring only one existed
      },
    }));
  };

  const handleChange = (key, value) => {
    setUnitData((prev) => ({
      ...prev,
      pricingData: {
        ...prev.pricingData,
        [key]: [
          {
            value,
            error: value.trim() === "" ? "Charge cannot be empty" : "",
          },
        ], // Ensure only one entry exists
      },
    }));
  };

  const updateImage = (val) => {
    setUnitData({
      ...unitData,
      assestData: val,
    });
  };

  const handleAssetFile = (data) => {
    let filteredAssests = unitData?.assestData.filter(
      (val) => val?.url !== data?.url
    );
    if (isEdit && data?.id) {
      let deleteAssets = [...unitData?.deleteAssets, data?.id];
      let assestData = filteredAssests;

      setUnitData({
        ...unitData,
        assestData: assestData,
        deleteAssets: deleteAssets,
      });
    } else {
      setUnitData({
        ...unitData,
        assestData: filteredAssests,
      });
    }
  };

  React.useEffect(() => {
    if (isEdit) {
      // editData();
    }
    getEnum();
    // eslint-disable-next-line
  }, [isEdit]);

  const manualUnitCategoryMasterResponse = (array) => {
    return array?.unit_category_master;
  };

  return (
    <Box
      className={classes.formRoot}
      sx={{ display: "flex", flexDirection: "column", height: "100vh" }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "white",
          zIndex: 10,
        }}
      >
        <Stack p={2} direction={"row"} alignItems={"center"} gap={2}>
          <IconButton
            sx={{ padding: 0 }}
            onClick={() => {
              closeDrawer();
            }}
          >
            <CloseIcon htmlColor="#7C8594" />
          </IconButton>
          <CustomTypography className={classes.drawerTitle}>
            {isEdit
              ? t("Edit Unit For 3rd Party")
              : t("Add A New Unit For 3rd Party")}
          </CustomTypography>
        </Stack>
        <Divider />
        <Tabs
          value={value}
          onChange={(e, newValue) => setValue(newValue)}
          variant="fullWidth"
          selectionFollowsFocus={false}
          className={classes.tab}
        >
          <Tab label={t("Property & Unit Details")} disabled />
          <Tab label={t("Pricing Information")} disabled />
          <Tab label={t("Listings & Additional Information")} disabled />
        </Tabs>
      </Box>

      {/* Tab Content */}
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          padding: "16px",
          backgroundColor: "#F2F4F7",
        }}
      >
        <Box sx={{ paddingBottom: "50px" }}>
          {value === 0 && (
            <Step1
              t={t}
              unitData={unitData}
              updateState={updateState}
              enumValue={enumValue}
              manualUnitCategoryMasterResponse={
                manualUnitCategoryMasterResponse
              }
              handleAssetFile={handleAssetFile}
              updateImage={updateImage}
              custom_options={custom_options}
              uuid={uuid}
              setUUID={setUUID}
              loading={loading}
              companyId={companyId}
              manualVendorResponse={manualVendorResponse}
            />
          )}
          {value === 1 && (
            <Step2
              t={t}
              updatePricingState={updatePricingState}
              loading={loading}
              unitData={unitData}
              enumValue={enumValue}
              updateState={updateState}
              CustomtoolTip={CustomtoolTip}
              pricingInfo={pricingInfo}
              chargeOptions={chargeOptions}
              handleChange={handleChange}
              handleRemoveCharge={handleRemoveCharge}
              handleAddClick={handleAddClick}
              anchorEl={anchorEl}
              handleMenuClose={handleMenuClose}
              handleAddCharge={handleAddCharge}
              companyId={companyId}
              currency={currency}
            />
          )}
          {value === 2 && (
            <Step3
              t={t}
              loading={loading}
              unitData={unitData}
              updateListingState={updateListingState}
            />
          )}
        </Box>
      </Box>
      {/* Navigation Buttons */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          width: "inherit",
          backgroundColor: "white",
          boxShadow: "0px -2px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Grid container className={classes.btnroot} spacing={1}>
          <Grid item xs={4}>
            <Button
              className={classes.previousbtn}
              variant="contained"
              onClick={handlePrevious}
              disabled={value === 0}
            >
              {t("Previous")}
            </Button>
          </Grid>
          <Grid item xs={8} textAlign={"end"}>
            <Button
              className={classes.submitbtn}
              variant="contained"
              onClick={value === 2 ? handleAdd : handleNext}
            >
              {value === 2 ? (isEdit ? t("Update") : t("Add")) : t("Next")}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <React.Fragment key={"right"}>
        <Drawer anchor={"right"} open={dialog} onClose={closeDraw}>
          <DuplicateUnit
            t={t}
            closeDrawer={closeDraw}
            unitData={unitData}
            units={units}
            count={count}
            handleDeleteUnit={handleDeleteUnit}
            viewUnitById={viewUnitById}
            unitDetails={unitDetails}
            close={closeDrawer}
            existingPricing={pricing}
            isEdit={isEdit}
            getExternalUnits={getExternalUnits}
            companyId={companyId}
            currency={currency}
          />
        </Drawer>
      </React.Fragment>
    </Box>
  );
};

export default AddUnit;
