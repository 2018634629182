import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { FontFamilySwitch } from '../../../utils';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#fff',
        padding: "4px",
        marginTop: "-4px",
        borderBottom: "1px solid #00000014",
        minHeight: "54px",
        display: "flex",
        alignItems: "center"
    },
    icon: {
        fontSize:"0.875rem",
        marginLeft: "-2px",
    },
    title: {
        color: '#4E5A6B',
        fontFamily: FontFamilySwitch().bold,
        fontSize:"1rem"
    },
    label: {
        color: '#98A0AC',
        fontFamily: FontFamilySwitch().bold,
        fontSize:"1rem",
        marginLeft: "2px"
    },
    count: {
        color: "#98A0AC",
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
    }
}));
export const Subheader = (props) => {
    const classes = useStyles();

    return (
        <div >
            <Box display="flex" alignItems="center" className={classes.root}>
                {
                    !Boolean(props?.hideBackButton) &&
                    <Box marginLeft="16px">
                        <Box onClick={props?.goBack} className={classes.iconbtn} display="flex" alignItems="center">
                            <ArrowBackIosIcon className={classes.icon} htmlColor="black" />
                        </Box>
                    </Box>
                }
                <Box marginLeft="10px">
                    <Typography className={classes.title}>{props?.title}</Typography>
                </Box>
                {props?.count && <Box marginLeft="10px">
                    <Typography className={classes.count}>{"(" + props?.count + ")"}</Typography>
                </Box>}
                {
                    props?.pageoptions?.map((val) => {
                        return (
                            val?.label && <>
                                <Box marginTop="4px" marginLeft="4px"><ArrowForwardIosIcon className={classes.icon} htmlColor="black" /></Box>
                                <Box><Typography className={val?.type === "name" ? classes.title : classes.label}>{val?.label}</Typography></Box>
                            </>
                        )
                    })
                }
                <div style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>
                    {
                        props?.isDetailsarrow &&

                        props?.pageoptionsoption?.map((val) => {
                            return (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Box><Typography className={classes.label}>{val?.label}</Typography></Box>
                                    {
                                        val?.isArrow && <Box marginTop="4px" marginLeft="4px"><ArrowForwardIosIcon className={classes.icon} htmlColor="#091B29" /></Box>
                                    }
                                </div>
                            )
                        })

                    }
                </div>
                {
                    props?.isEdit &&
                    <div style={{ marginLeft: '14px', textAlign: 'left' }} onClick={props?.handleEdit}>
                        <img src='/images/Outlineselect.svg' alt="" />
                    </div>
                }
                <Box flexGrow={1} />


            </Box>
        </div>
    );
};
