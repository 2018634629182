import { makeStyles } from "@mui/styles"
import { remCalc, FontFamilySwitch } from "../../../utils"

export const MemberSelectBoxStyle = makeStyles((theme) => ({
    box: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: "4px",
        padding: "12px",
        height: "40px"
    },
    menuText: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.primary
    },
    menuSubText: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().regular,
        color: theme.typography.color.secondary
    }
}))

export const SpecificationTableStyle = makeStyles((theme) => ({
    head: {
        background: theme.palette.background.tertiary,
        height: "36px",
        borderRadius: "4px"
    },
    headText: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.secondary,
        padding: "8px",
        border: "none"
    },
    bodyCell: {
        padding: "4px",
        border: "none"
    },
    viewText: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.palette.primary.main,
        cursor: "pointer"
    },
    table: {
        maxHeight: "calc(100vh - 220px)",
        [theme.breakpoints.only("md")]: {
            maxHeight: "calc(100vh - 260px)"
        },
        [theme.breakpoints.only("sm")]: {
            maxHeight: "calc(100vh - 260px)"
        },
        // minHeight: "auto",
        // maxHeight:"200px",
        // overflow: "auto"
    },
    viewTitle: {
        fontSize: remCalc(14),
        color: theme.palette.primary.main,
        fontFamily: FontFamilySwitch().semiBold,
        paddingLeft: "12px",
        cursor: "pointer"
    }
}))

export const ProjectedBOMStyle = makeStyles((theme) => ({
    head: {
        background: theme.palette.background.tertiary,
        height: "36px",
        borderRadius: "4px"
    },
    headText: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.secondary,
        padding: "8px",
        border: "none"
    },
    bodyCell: {
        padding: "4px",
        border: "none"
    },
    viewText: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.palette.primary.main,
        cursor: "pointer"
    },
    table: {
        maxHeight: "calc(100vh - 220px)",
        // [theme.breakpoints.only("md")]: {
        //     height: "calc(100vh - 460px)"
        // },
        // [theme.breakpoints.only("sm")]: {
        //     height: "calc(100vh - 460px)"
        // },
        // minHeight: "auto",
        // maxHeight:"200px",
        // overflow: "auto"
    },
    currencySymText: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().regular,
        color: theme.typography.color.tertiary
    }
}))