import { Box, Button, Divider, Drawer, Grid, Stack, Typography, useMediaQuery } from "@mui/material"
import React from "react"
import { CloseIconWithBG } from "../../../assets"
import { SearchFilter, TableWithPagination } from "../../../components"
import { config } from "../../../config"
import { NetworkCall } from "../../../networkcall"
import { NetWorkCallMethods } from "../../../utils"
import { useStyles } from "../style"
import { AssetDataType, AssetHeading, AssetPath, CheckListDataType, CheckListHeading, CheckListPath, ResourceDataType, ResourceHeading, ResourcePath, UnitDataType, UnitHeading, UnitPath, planPreviewTabOptions } from "../utils"
import { CheckListForm } from "./checkListForm"
import { CommonForm } from "./commonForm"

export const AddItem = ({ state = {}, data = {}, t = () => false, updateState = () => false, form_type = "Unit", isView = false }) => {
  const classes = useStyles()
  const [searchText, setSearchText] = React.useState("")
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const [tabValue, setTabValue] = React.useState("")
  // const [filterData, setFilterData] = React.useState({})
  const [propertyOptions, setPropertyOptions] = React.useState([])
  const [details, setDetails] = React.useState({ row: [], count: 0 })
  // const [filterDrawer, setFilterDrawer] = React.useState({})
  // const [page, setPage] = React.useState(1)
  const [limit, setLimit] = React.useState(10)
  const [offset, setOffset] = React.useState(10)
  const [loading, setLoading] = React.useState(true)
  const [tabOptions, setTabOptions] = React.useState([])
  const matches = useMediaQuery('(max-width:1100px)')
  React.useEffect(() => {
    const dynamic_frequency = data?.dynamic_schedule?.map((x) => { return x?.frequency?.value })
    if (dynamic_frequency?.length > 0) {
      const result = planPreviewTabOptions?.filter((x) => { return dynamic_frequency?.includes(x?.label) })
      setTabOptions(result)
      setTabValue(result?.[0]?.value)
      updateState("selected_period", result?.[0]?.label)
    }
    //eslint-disable-next-line
  }, [])
  const getDetails = (property = null, offset = 0, limit = 10, search = "", is_fetchMore = false) => {
    const payload = {
      company_id: state?.company?.value,
      offset: offset,
      limit: limit,
      search: search,
      category_id: (form_type === "unit" && data?.PMPType === "Asset") ? [data?.category?.value] : undefined,
      subcategory_id: (form_type === "unit" && data?.PMPType === "Asset") ? [data?.sub_category?.value] : undefined,
      profession: [],
      jobs: [],
      group_id: form_type === "resource" ? data.resource_group?.map((x) => { return x?.value }) : undefined,
      unit_type: (form_type === "unit" && data?.PMPType === "Unit") ? [data?.unit_type?.value] : undefined,
      properties: form_type === "unit" ? (property?.label === "All Properties" ? property?.value : [property?.value]) : undefined
    }

    NetworkCall(
      `${config.api_url}${(form_type === "unit" && data?.PMPType === "Unit") ? "/queries/manager_units" : (form_type === "unit" && data?.PMPType === "Asset") ? "/preventive_plan/get_mapping_list" : "/queries/resources_by_group"}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((response) => {
      const result = response?.data?.data?.data?.map((x) => {
        const selectedData = form_type === "unit" ? data?.selected_items?.filter((val) => { return (val?.id === (x?.id || x?.value) || val?.unit_id === (x?.id || x?.value) || val?.mapping_id === (x?.id || x?.value)) }) : data?.selected_resources?.filter((val) => { return (val?.id === (x?.id || x?.value) || val?.resource_id === (x?.id || x?.value)) })
        return {
          ...x,
          id: x?.value ?? x?.id,
          item_mapping_id: (data?.PMPType === "Asset" && form_type === "unit") ? x?.id : undefined,
          unit_id: (form_type === "unit" && data?.PMPType === "Unit") ? x?.id : x?.unit_id,
          item_id: (data?.PMPType === "Asset" && form_type === "unit") ? x?.inspection_item : undefined,
          config_type: data?.PMPType,
          name: x?.name ?? x?.label,
          logo: x?.logo ?? null,
          is_active: true,
          image: x?.image_url !== "" ? x?.image_url : null,
          image_url: x?.image_url !== "" ? x?.image_url : null,
          unit_location: `${x?.city},${x.state}`,
          functional_location: x?.location,
          is_selected: data?.is_selected_allItems ? true : (selectedData?.length > 0 ? selectedData?.[0]?.is_selected : x?.is_selected)
        }
      })
      setLoading(false)
      if (is_fetchMore) {
        const unitDetails = [...details.row, ...result]
        setDetails({ ...details, row: unitDetails })
        // updateState(form_type === "unit" ? "selected_items" : "selected_resources", unitDetails?.filter((x) => { return x?.is_selected }))
      }
      else {
        setDetails({ row: result, count: form_type === "unit" ? response?.data?.data?.count : response?.data?.data?.count?.[0]?.count })
        // updateState(form_type === "unit" ? "selected_items" : "selected_resources", result)
      }

    }).catch((err) => {
      setLoading(false)
      console.log(err)
    })
  }
  const reload = (val) => {
    getDetails(data?.properties, 0, 10, val);
  }
  const fetchMoreData = (searchText) => {
    setOffset(offset + 10)
    getDetails(data?.properties, offset + 10, 10, searchText, true);
  }
  const returnAllPropertyJson = (list) => {
    const data = list?.map((val) => {
      return {
        label: val?.label,
        value: val?.value
      }
    })
    return (
      ([
        {
          label: t("All Properties"),
          value: list?.map(i => { return i?.value })
        },
        ...data]
      ))
  }
  const getPropertyDropdown = (company = []) => {
    let payload = {
      company_ids: [state?.company?.value],
      offset: 0,
      limit: null
    };
    NetworkCall(
      `${config.api_url}/queries/property/list`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then(async (res) => {
      const result = returnAllPropertyJson(res?.data?.data?.property);
      setPropertyOptions(result);
      await updateState("properties", result?.[0]);
      await getDetails(result?.[0], 0, 10, "");
      setOpenDrawer(true);
    });
  };

  const handleTableSearch = (val) => {
    setSearchText(val)
    if (val?.length > 0) {
      const result = data?.selected_resources?.filter((x) => { return (x?.name.toLowerCase().includes(val.toLowerCase()) || x?.reference_id.includes(val)) })
      updateState("selected_resources", result)
    }
    else {
      updateState("selected_resources", form_type === "unit" ? data?.allSelectedItems : data?.allSelectedResources)
    }
  }
  // const handleTablePagination = () => {

  // }
  // const handleTablePageLimit = () => {

  // }
  const handleTableIcon = (type, details) => {
    if (type === "delete") {
      if (form_type === "unit") {
        const result = data?.selected_items?.filter((val) => { return val?.id !== details?.id })
        updateState("selected_items", result)
      }
      else {
        const result = data?.selected_resources?.filter((val) => { return val?.id !== details?.id })
        updateState("selected_resources", result)
      }
    }
  }
  const handleAdd = async () => {
    await setOffset(0)
    await setLimit(10)
    if (data?.contract_details === null || data?.contract_details?.length === 0) {
      // console.log("handleCheck")
      getPropertyDropdown()
    }
    else {
      const allProperties = {
        label: "All Properties",
        value: data?.selected_properties?.map((x) => { return x?.property?.id ?? x?.property_id })
      }
      setPropertyOptions([allProperties, ...data?.selected_properties?.map((x) => { return { label: x?.property?.name ?? x?.property_name, value: x?.property?.id ?? x?.property_id } })])
      updateState("properties", allProperties, "item_row", [])
      if (state.planner_type === "Fixed") {
        getDetails(allProperties, 0, 10, "")
      }
      setOpenDrawer(true)
    }
  }
  const handleUpdate = (key, value, selected_itemData) => {
    setDetails({ ...details, [key]: value })
    const checkData = data?.item_row?.length > 0 ? [...data?.item_row, ...selected_itemData] : selected_itemData
    const result = checkData?.map((x) => { return { ...x, is_selected: x?.id === selected_itemData?.[0]?.id ? selected_itemData?.[0]?.is_selected : x?.is_selected, mapping_id: x?.item_mapping_id, resource_id: x?.id, unit_id: x?.id } })
    updateState("item_row", result, "is_selected_allItems", false)
  }
  const handleClose = () => {
    updateState("is_selected_allItems", false)
    setOpenDrawer(false)
    setOffset(0)
    setLimit(10)
  }
  const handleUpdateProperties = (key, value) => {
    updateState("properties", value)
    getDetails(value, 0, 10, "")
  }
  const handleTabChange = (newValue) => {
    setTabValue(newValue?.value);
    updateState("selected_period", newValue?.label)
    // if(newValue==="1"){
    //     updateState("selected_dynamic_checklist", data?.selected_dynamic_checklist?.filter((val) => { return val?.is_checked && val?.selected_period==="Monthly"}))
    // }
    // else if(newValue==="2"){
    //     updateState("selected_dynamic_checklist", data?.selected_dynamic_checklist?.filter((val) => { return val?.is_checked && val?.selected_period==="Quarterly"}))
    // }
    // else if(newValue==="3"){
    //     updateState("selected_dynamic_checklist", data?.selected_dynamic_checklist?.filter((val) => { return val?.is_checked && val?.selected_period==="Half yearly"}))
    // }
  }
  return (
    <Box>
      {state?.planner_type === "Dynamic" &&
        <>
          <Box sx={{ padding: "16px 16px 0px 16px" }} display={"flex"} justifyContent={"center"}>
            <Stack direction="row" spacing={1.5} alignItems="center">
              {tabOptions?.map((x) => {
                return (

                  <Box onClick={() => handleTabChange(x)}>
                    <Typography className={tabValue === x?.value ? classes.selectedPublishText : classes.publishtext} sx={{ marginBottom: "6px" }}>{`${x?.label} (${data?.selected_dynamic_checklist?.filter((val) => { return val?.selected_period === x?.label })?.length ?? 0})`}</Typography>
                    <Box sx={{ borderBottom: tabValue === x?.value ? "3px solid #5078E1" : "3px solid #FFFFFF" }} />
                  </Box>

                )
              })}
            </Stack>
          </Box>
          <Divider />
        </>
        // <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        //     <Tabs value={tabValue} centered>
        //         {tabOptions?.map((x) => {
        //             return (
        //                 <Tab label={x?.label} value={x?.value} onClick={() => handleTabChange(x)} />
        //             )
        //         })}
        //     </Tabs>
        // </Box>
      }
      <Box p={2}>
        <Grid container spacing={1}>
          {!isView &&
            <Grid item xs={6} sm={6} lg={3}>
              <SearchFilter value={searchText} placeholder={t("Search")}
                handleChange={(value) => handleTableSearch(value)} />
            </Grid>
          }
          <Grid item xs={6} sm={6} lg={9}>
            <Box display={"flex"} justifyContent={"end"}>
              <Stack direction="row" spacing={2}
                divider={<Divider orientation="vertical" flexItem />}>
                {/* <IconButton onClick={() => setFilterDrawer(!filterDrawer)}
                                    className={classes.filterButton}>
                                    <Badge variant="dot" color="primary"
                                        invisible={!(filterData.is_active?.length > 0)}>
                                        <FilterIMG color="#091b29" />
                                    </Badge>
                                </IconButton> */}
                {!isView &&
                  <Button variant="contained" className={classes.button}
                    onClick={handleAdd}>
                    {form_type === "unit" ? (data?.PMPType === "Unit" ? t("Add Unit") : t("Add Item")) : form_type === "checkList" ? t("Add Checklist") : t("Add Resource")}
                  </Button>
                }
              </Stack>
            </Box>
          </Grid>
        </Grid>
        <Box mt={isView ? 0 : 2}>
          <TableWithPagination
            heading={form_type === "unit" ? (data?.PMPType === "Unit" ? UnitHeading(t) : AssetHeading(t)) : form_type === "checkList" ? CheckListHeading(t) : ResourceHeading(t)}
            rows={state?.planner_type === "Fixed" ? (form_type === "unit" ? data?.selected_items : form_type === "resource" ? data?.selected_resources : data?.selected_checklist) : data?.selected_dynamic_checklist?.filter((x) => { return x?.selected_period === data?.selected_period })}
            path={form_type === "unit" ? (data?.PMPType === "Unit" ? UnitPath : AssetPath) : form_type === "checkList" ? CheckListPath : ResourcePath}
            showpagination={false}
            showpdfbtn={false}
            showexcelbtn={false}
            showSearch={false}
            handleIcon={handleTableIcon}
            onClick={() => console.log("")}
            // noDataSvg
            tableType="no-side"
            dataType={form_type === "unit" ? (data?.PMPType === "Unit" ? UnitDataType : AssetDataType) : form_type === "checkList" ? CheckListDataType : ResourceDataType}
            // handlePagination={handleTablePagination}
            // handleChangeLimit={handleTablePageLimit}
            // totalRowsCount={form_type === "unit" ? data?.selected_items?.length : form_type === "resource" ? data?.selected_resources?.length : data?.selected_checklist?.length}
            // page={page}
            limit={limit}
            height={isView ? 'calc(100vh - 330px)' : (state?.planner_type === "Fixed" ? 'calc(100vh - 405px)' : 'calc(100vh - 470px)')}
            view={true}
            edit={isView ? false : true}
            delete={true} />
        </Box>
      </Box>
      <Drawer
        anchor={"right"}
        open={openDrawer}
        PaperProps={{
          sx: { width: matches ? "95%" : (form_type === "checkList" ? "70%" : "60%") },
        }}
        onClose={() => handleClose()}
      >
        <Box>
          <Stack direction="row" spacing={2} alignItems="center" p={1.5}>
            <Box onClick={() => handleClose()} style={{ cursor: "pointer" }}><CloseIconWithBG /></Box>
            <Typography className={classes.drawerHeader}>{form_type === "unit" ? (data?.PMPType === "Unit" ? "Add Unit" : "Add Item") : form_type === "checkList" ? "Add Checklist" : "Add Resource"}</Typography>
          </Stack>
          <Divider />
        </Box>
        <Box>
          {form_type === "checkList" ?
            <Box>
              <CheckListForm data={data} t={t} updateState={updateState} state={state} dynamicTabValue={tabValue} />
            </Box>
            :
            <CommonForm
              t={t}
              data={data}
              company_id={[state?.company?.value]}
              image_url={data?.PMPType === "Asset" ? "/images/selectedAsset.svg" : "/images/propertdashboard/block.svg"}
              title={form_type === "unit" ? (data?.PMPType === "Unit" ? t("Unit") : t("Asset")) : "Internal Resource"}
              subTitle={form_type === "unit" ? (data?.PMPType === "Unit" ? t("Eg: 2BHK, Villa, etc") : t("Eg: AC, Lift, TV etc")) : t("Select below criteria for resource pooling")}
              updateState={updateState}
              form_type={form_type}
              details={details}
              handleUpdate={handleUpdate}
              fetchMoreData={fetchMoreData}
              handleClose={() => setOpenDrawer(false)}
              propertyOptions={propertyOptions}
              handleUpdateProperties={handleUpdateProperties}
              reload={reload}
              loading={loading}
            />
          }
        </Box>
      </Drawer>
    </Box>
  )
}