import React from 'react'
import { PropertyTabs, Subheader } from '../../components'
import { projectCategoryStyle } from './style';
import styled from '@mui/material/styles/styled';
import { Table } from './table';
import { accessCheckRender, getCompanyOption, getRoutePermissionNew } from '../../utils';
import { AuthContext, BackdropContext } from '../../contexts';
import { Box } from "@mui/material"
import { withTranslation } from 'react-i18next';

const CustomPaper = styled('div')(({ theme }) => ({
    borderRadius: "8px",
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    height: `calc(100vh - 165px)`,
    overflow: "hidden",
    margin: "16px"
}))

const ProjectCategory = ({
    t = () => false
}) => {

    const classes = projectCategoryStyle()
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext)
    const [value, setValue] = React.useState(1);
    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [permission, setPermission] = React.useState({})

    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
    }


    React.useEffect(() => {
        const _ = getRoutePermissionNew(auth)
        if (_) {
            setPermission(_)
            if (_?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                }
            }
        }
        // eslint-disable-next-line        
    }, [auth])

    const tabTittle = [
        {
            label: t("Category"),
            value: 1,
            className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
        },
        {
            label: t("Sub Category"),
            value: 2,
            className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
        },
    ]
    const tabChange = (e, newValue) => {
        setValue(newValue)
    }
    const render = () => {
        return (
            <>
                <Subheader
                    title={t("Project Category")}
                    select
                    options={companyList}
                    value={selectedCompany}
                    onchange={(e) => handleCompanyChange(e)}
                    hideBackButton
                />
                <CustomPaper>
                    <PropertyTabs t={t} value={value} padding handleChange={tabChange}
                        tab1={(<>
                            <Table type='category' selectedCompany={selectedCompany} t={t} />
                        </>
                        )}
                        tab2={(
                            <>
                                <Table selectedCompany={selectedCompany} t={t} />
                            </>
                        )}
                        tabTittle={tabTittle}
                        backgroundColor
                    />
                </CustomPaper>
            </>
        )
    }

    return (
        <Box>
            {accessCheckRender(render, permission)}
        </Box>
    )

}

export default withTranslation("projectCategory")(ProjectCategory)