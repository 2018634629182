export const receiptPath = ["receipt_no", "receipt_date", "account_no", "account_name", "receipt_amount", "payment_method", "reference", "unused_balance"]

export const getReceiptHeading = (t) => {
    const receiptheading = [
        { title: t("receiptNumber"), field: "receipt_no", },
        { title: t("receiptDate"), field: "created_at" },
        { title: t("Account"), field: "account_no", },
        { title: t("accountName"), field: "account_name", },
        { title: t("receiptAmount"), field: "receipt_amount", },
        { title: t("paymentMethod"), field: "payment_method" },
        { title: t("paymentDate"), field: "transaction_date" },
        { title: t("unusedBalance"), field: "unused_balance", },
        { title: t("reference"), field: "reference" },
        { title: t("Collector"), field: "collector" },
        // { title: t("Payment Date"), field: "receipt_date", },
        // { title: t("receivedAmount"), field: "receipt_amount", },
        // { title: t("source"), field: "source" },
    ]
    return receiptheading
}

export const receiptdataType = [
    { type: ["text"], name: "receipt_no" },
    { type: ["date"], name: "created_at" },
    { type: ["text"], name: "account_no" },
    { type: ["text"], name: "account_name" },
    { type : ["text"], name: "receipt_amount" },
    { type: ["text"], name: "payment_method" },
    {type : ["date"], name: "transaction_date"},
    { type: ["unused_balance"], name: "unused_amount" },
    { type: ["text"], name: "reference" },
    { type: ["text"], name: "collector" },
    // { type: ["date"], name: "receipt_date" },
    // { type: ["text"], name: "receipt_amount" },
    // { type: ["text"], name: "source" },
]





export const getAccountDetails = (t, details) => {
    return [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
            },
            isActive: true,
            component: "profileWithAccount",
            profileImage: details?.image_url || "",
            profileData: {
                name: details?.name || "-",
                badge: {
                    label: details?.account_payment_classification || "-",
                    color: "#5078E1"
                },
                contactInfo: [
                    { 
                        label: t("Mobile No"), 
                        value: `${details?.mobile_no_country_code || ""} ${details?.mobile_no || ""}`,
                        maxWidth: "220px" 
                    },
                    { 
                        label: t("Email ID"), 
                        value: details?.email_id || "-",
                        maxWidth: "300px" 
                    }
                ]
            },
            accountDetails: [
                { label: t("Account ID"), value: details?.subTitle1 || "-" },
                { label: t("Account Name"), value: details?.name || "-" },
                { label: t("Account Type"), value: details?.account_payment_classification || "-" }
            ]
        }
    ];
};

export const accountFormState = {
    amountTopay: "",
    paymentmethod: "",
    bankName: "",
    branch: "",
    chequeNo: "",
    depoiteDate: "",
    clearanceStatus: "",
    available_balance: 0,
    total: 0,
    paymentDate:new Date(),
    transactionDate:new Date(),
    transactionNumber: "",
    collector: "",
    error: {
        amountTopay: "",
        paymentmethod: "",
        bankName: "",
        branch: "",
        chequeNo: "",
        depoiteDate: "",
        clearanceStatus: "",
        transactionDate: "",
        transactionNumber: "",
        collector: ""
    }
}

export const invoicePath = ["selected", "invoice_id", "agreement_id", "invoice_generate_date", "status", "pending_amount", "total_amount"]

export const getInvoiceHeading = (t, selected = 0, state = false) => {
    const receiptheading = [
        { title: state ? null : `${selected} ${t("selected")}`, field: "invoice_id", },
        { title: t("invoiceId"), field: "invoice_id", },
        { title: t("agreementId"), field: "agreement_id", },
        { title: t("generatedDate"), field: "invoice_generate_date", },
        { title: t("status"), field: "status", },
        { title: t("pendingAmount"), field: "pending_amount", },
        { title: t("totalAmount"), field: "total_amount" },
        { title: "", field: "link" },
    ]
    return receiptheading
}

export const invoicedataType = [
    { type: ["inputBox"], name: "selected" },
    { type: ["text"], name: "invoice_id" },
    { type: ["text"], name: "agreement_id" },
    { type: ["date"], name: "invoice_generate_date" },
    { type: ["status"], name: "status" },
    { type: ["text"], name: "pending_amount" },
    { type: ["text"], name: "total_amount" },
    { type: ["link"], name: "link" },

]



export const getReceiptDetails = (t, details, currency) => {
    const data = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 2.4
            },
            isActive: true,
            component: "text",
            heading: t("customerName"),
            subtitle: details?.account?.name
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 2.4
            },
            isActive: true,
            component: "text",
            heading: t("customerMobile"),
            subtitle: `${details?.account?.mobile_no_country_code} ${details?.account?.mobile_no}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 2.4
            },
            isActive: true,
            component: "text",
            heading: t("paymentMethod"),
            subtitle: details?.payment_mode
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 2.4
            },
            isActive: details?.payment_mode !== "Cash",
            component: "text",
            heading: t("bankName"),
            subtitle: details?.bank_name
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 2.4
            },
            isActive: details?.payment_mode !== "Cash",
            component: "text",
            heading: t("chequeNo"),
            subtitle: details?.cheque_no
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 2.4
            },
            isActive: true,
            component: "text",
            heading: t("paymentStatus"),
            subtitle: details?.payment_status,
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 2.4
            },
            isActive: true,
            component: "text",
            heading: t("paymentAmount"),
            subtitle: `${currency} ${details?.amount_paid}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 2.4
            },
            isActive: true,
            component: "date",
            heading: t("paymentTime"),
            subtitle: details?.created_at
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 2.4
            },
            isActive: true,
            component: "text",
            heading: t("accountNo"),
            subtitle: details?.account?.account_no
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 2.4
            },
            isActive: true,
            component: "text",
            heading: t("receiptGenBy"),
            subtitle: details?.generated_by?.name
        },
    ]
    return data
}

export const historyPath = ["reciepts_no", "settlement_no", "datetime", "account_no", "agreement_id", "invoice_no", "invoice_date", "invoice_due_amount", "amount_settled"]

export const getHistoryHeading = (t) => {
    const historyheading = [
        { title: t("receiptNo"), field: "reciepts_no", },
        { title: t("settlement_no"), field: "settlement_no", },
        { title: t("datetime"), field: "datetime", },
        { title: t("account_no"), field: "account_no", },
        { title: t("agreementNo"), field: "agreement_id", },
        { title: t("invoiceNo"), field: "invoice_no", },
        { title: t("invoice_date"), field: "invoice_date", },
        { title: t("invoiceDue"), field: "invoice_due_amount" },
        { title: t("amount_settled"), field: "amount_settled" },
    ]
    return historyheading
}

export const historydataType = [
    { type: ["text"], name: "reciepts_no" },
    { type: ["text"], name: "settlement_no" },
    { type: ["date"], name: "datetime" },
    { type: ["text"], name: "account_no" },
    { type: ["text"], name: "agreement_id" },
    { type: ["text"], name: "invoice_no" },
    { type: ["date"], name: "invoice_date" },
    { type: ["text"], name: "invoice_due_amount" },
    { type: ["text"], name: "amount_settled" },

]
