import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch, remCalc } from "../../utils";
export const LeadStylesParent = makeStyles((theme) => ({
  root: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
    margin: "14px",
    [theme.breakpoints.only("xs")]: {
      overflow: "auto",
      height: "calc(100vh - 200px)",
    },
  },
  img: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "10px",
    height: "40px",
  },
  outerCircle: {
    padding: "2px",
    border: "1px solid #5078E1",
    borderRadius: "50%",
  },
  next: {
    fontFamily: FontFamilySwitch().semiBold,
    color: "#fff",
    backgroundColor: "#5078E1",
    "&:hover": {
      backgroundColor: "#5078E1",
    },
    height: "40px",
  },
  Cancel: {
    backgroundColor: "#FFFFFF ",
    color: "#091B29",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontFamily: FontFamilySwitch().semiBold,
    border: "1px solid #E4E8EE",
    "&:hover": {
      backgroundColor: "#FFFFFF ",
    },
  },
  bottombtn: {
    padding: "12px 19px",
    backgroundColor: "#fff",
    // boxShadow: "0px 0px 16px #00000014",
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #E4E8EE",
    position: "sticky",
    bottom: "0px",
    width: "100%",
    zIndex: 2,
    alignItems: "center",
  },
  Avatar: {
    backgroundColor: theme.palette.primary.main,
    width: "18px",
    height: "18px",
    fontSize: "0.875rem",
  },
  tittle: {
    color: "#5078E1",
    fontSize: "0.875rem",
  },
  tittle1: {
    color: "#98A0AC",
    fontSize: "0.875rem",
  },
  outerCircle1: {
    padding: "2px",
    border: "1px solid #98A0AC",
    borderRadius: "50%",
  },
  Avatar1: {
    backgroundColor: "#98A0AC",
    width: "18px",
    height: "18px",
    fontSize: "0.875rem",
  },
  stepper: {
    borderBottom: "1px solid #E4E8EE",
    display: "flex",
    alignItems: "center",
    padding: "10px",
  },
  profileTilte: {
    color: "#091B29",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
  },
  leadnumber: {
    color: "#091B29",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    backgroundColor: "#E1E8F8",
    padding: "4px",
    borderRadius: "4px",
    marginInlineStart: "10px",
  },
  leadcontact: {
    color: "#4E5A6B",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().regular,
    display: "flex",
    alignItems: "center",
  },
  leadcontactNo: {
    color: "#4E5A6B",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().regular,
    display: "flex",
    alignItems: "center",
    direction: "ltr",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  profileroot: {
    backgroundColor: "#fff",
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
  },
  profileDescription: {
    color: "#98A0AC",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  profileDescriptioncontant: {
    color: "#4E5A6B",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().regular,
    marginTop: "8px",
    paddingRight: "5px",
    wordBreak: "break-all",
  },
  allocatedroot: {
    // border: "1px solid #E4E8EE",
    marginTop: "4px",
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "#fff",
  },
  timedot: {
    height: "8px",
    width: "8px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
  },
  timedotborder: {
    borderRadius: "50%",
    padding: "3px",
    border: "1px dashed #CED3DD",
  },
  timelinecreated: {
    color: "#4E5A6B",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().regular,
  },
  timelinecreatedat: {
    color: "#98A0AC",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().regular,
  },
  message: {
    color: "#091B29",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    fontWeight: 600,
    textAlign: "-webkit-auto",
  },
  commandbox: {
    width: "100%",
    padding: "12px",
    borderRadius: "10px",
    border: "1px solid #E4E8EE",
    margin: "0 auto",
  },
  profileroot2: {
    backgroundColor: "#F2F4F7",
    borderRadius: "8px",
    width: "100%",
    padding: "24px",
    margin: "0 auto",
  },
  commentsDiv: {
    backgroundColor: "#F5F7FA",
    borderRadius: "4px",
    width: "100%",
    // padding: "24px",
    margin: "0 auto",
  },
  options: {
    color: "#091B29",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
  },
  leadtransfersbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px",
    cursor: "pointer",
    borderBottom: "1px solid #E4E8EE",
    "&:nth-child(3)": {
      borderBottom: "none !important",
    },
  },
  dot1: {
    border: "2px solid #CED3DD",
  },
  doted: {
    color: "#CED3DD",
    marginLeft: 6,
  },
  dot_line: {
    border: "1px dotted #CED3DD",
  },
  userImage: {
    backgroundColor: "#F2F4F7",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dotted_line: {
    border: "1px dashed #CED3DD",
    backgroundColor: "#FFFFFF",
  },
  menuItem: {
    "&.MuiTimeline-root": {
      "& .MuiTimelineItem-root": {
        "&:before": {
          content: "",
          padding: 0,
        },
      },
    },
  },
  activityBorder: {
    padding: "0px",
  },
  activityPadding: {
    paddingLeft: "0 !important",
  },
  detailsub: {
    fontFamily: FontFamilySwitch().regular,
    color: "#4E5A6B",
    fontSize: "0.875rem",
    marginTop: "5px",
  },
  seeMoreLessTextStyle: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  fontArrow: {
    fontSize: "21px !important",
  },
  profilecard: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  },
  profilecardContent: {
    display: "flex",
    alignItems: "center",
  },
  memberboxes: {
    flexFlow: "wrap !important",
    alignItems: "center",
    display: "flex",
    marginTop: "4px",
  },
  deleteIcon: {
    color: "red",
    fontSize: "19px",
    position: "absolute",
  },
  profilePadding: {
    padding: "12px",
  },
  cardBottomLine: {
    borderBottom: "1px solid #E4E8EE",
  },
  unitNo: {
    color: "#091B29",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
  },
  unitName: {
    color: "#4E5A6B",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().regular,
  },
  UserDetails: {
    marginLeft: "10px",
  },
  Cardcontent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "7px 5px",
    margin: "10px 0px",
  },
  unitList: {
    textAlign: "left",
    overflow: "auto",
    padding: "24px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tableMain: {
    paddingTop: "5px !important",
  },
  formRoot: {
    backgroundColor: "white",
    width: "508px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  drawerTitle: {
    color: "#091B29",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().extraBold,
  },
  title: {
    color: "#091B29",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.75rem",
  },
  subtitle: {
    color: "#98A0AC",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.875rem",
    marginTop: "4px",
  },
  borderLeft: {
    borderInlineStart: `1px solid #E4E8EE`,
  },
  bottombtn1: {
    padding: "12px 19px",
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #E4E8EE",
    position: "sticky",
    bottom: "0px",
    width: "100%",
    zIndex: 2,
    alignItems: "center",
    backgroundColor: "#F2F4F7",
  },
  drawerHeader: {
    padding: "12px 19px",
    border: "1px solid #E4E8EE",
    position: "sticky",
    bottom: "0px",
    width: "100%",
    zIndex: 2,
    alignItems: "center",
    // backgroundColor: "#F2F4F7"
  },
  timeLineMain: {
    "& .css-1eppkgy-MuiTypography-root-MuiTimelineContent-root": {
      padding: "6px 6px 24px 12px",
    },
  },
  button: {
    height: "40px",
  },
  quick_view_drawer: {
    "& .MuiDrawer-paper": {
      padding: "0px",
      width: ({ size }) => (size?.width > 899 ? 899 : "-webkit-fill-available"),
      borderRadius: ({ size }) =>
        size?.width >= 600 ? "4px 0px 0px 4px" : "4px 4px 0px 0px",
    },
  },
  quick_view_drawer_header: {
    padding: "16px",
    borderBottom: "1px solid #E4E8EE",
  },
  quick_view_drawer_header_title: {
    fontFamily: FontFamilySwitch().extraBold,
    fontSize: remCalc(16),
    color: theme?.typography?.color?.primary,
  },
  quick_view_drawer_close_button: {
    padding: "0px",
  },
  quick_view_drawer_body_view: {
    padding: "16px 0px",
    overflowY: "overlay",
  },
  tray: {
    zIndex: 1000,
    borderTop: "1px solid #ddd",
    width: "330px",
    minHeight: "100px",
    maxHeight: "350px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  list: {
    maxHeight: "200px",
    overflowY: "auto",
    width: "100%",
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  fileName: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: "300px",
  },
  disabledFileName: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: "300px",
  },
  buttonWrapper: {
    position: "relative",
    width: "60%",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  fileProgress: {
    marginLeft: theme.spacing(1),
  },
  button: {
    width: "100%",
  },
  commentsHeader: {
    fontSize: remCalc(12),
    fontFamily: FontFamilySwitch().extraBold,
    color: theme?.typography?.color?.primary,
    textTransform: "uppercase",
  },
  commentbox: {
    // width: "100%",
    padding: "12px",
    borderRadius: "10px",
    border: "1px solid #E4E8EE",
    margin: "12px 12px 0px 0px",
  },
  commandinput: {
    // width: "100%",
    height: "35px",
    border: "none !important",
    outline: "none !important",
    color: "#091B29",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    fontWeight: 600,
    padding: "0px !important",

    "&:focus": {
      border: "none !important",
      outline: "none !important",
    },
  },
  commandbtn: {
    // width: "100%",
    background: "#5078E1",
    color: "#fff",
    height: "32px",
    marginTop: "10px",
    "&:hover": {
      background: "#5078E1",
      color: "#fff",
    },
  },
  userName: {
    color: theme.typography.color.primary,
    fontSize: remCalc(14),
    fontFamily: FontFamilySwitch().semiBold,
  },
  userSubData: {
    color: theme.typography.color.tertiary,
    fontSize: remCalc(12),
    fontFamily: FontFamilySwitch().semiBold,
  },
  userSubData2: {
    color: theme.typography.color.tertiary,
    fontSize: remCalc(12),
    fontFamily: FontFamilySwitch().italic,
  },
  viewText: {
    color: "#5078E1",
    fontSize: remCalc(14),
    fontFamily: FontFamilySwitch().semiBold,
    cursor: "pointer",
  },
  selectedUser: {
    backgroundColor: "#DBEBFF",
    border: "1px solid #5078E1",
    borderRadius: theme.palette.borderRadius,
    margin: "4px",
  },
  customBadge: {
    top: "2px",
    right: "2px",
  },
}));
