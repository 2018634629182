import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../utils";
export const LeadStylesParent = makeStyles((theme) => ({
  type: {
    color: "#091B29",
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  cardParent: {
    border: "1px solid #E4E8EE",
    padding: "10px",
    borderRadius: "4px",
    textAlign: "center",
    cursor: "pointer",
  },
  cardParent1: {
    border: "1px solid #5078E1",
    padding: "10px",
    borderRadius: "4px",
    textAlign: "center",
    cursor: "pointer",
  },
  next: {
    marginLeft: "10px",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#fff",
    backgroundColor: "#5078E1",
    "&:hover": {
      backgroundColor: "#5078E1",
    },
  },
  cancel: {
    backgroundColor: "#FFFFFF ",
    color: "#091B29",
    fontSize:"0.875rem",
    fontWeight: 600,
    fontFamily: FontFamilySwitch().semiBold,
    border: "1px solid #E4E8EE",
    "&:hover": {
      backgroundColor: "#FFFFFF ",
    },
  },
  bottomButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
    padding: "8px",
  },
  typeCard: {
    borderRadius: "4px",
    padding: "8px",
    display: "flex",
    alignItems: "center",
    marginBottom: "11px",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  typeCard1: {
    borderRadius: "4px",
    padding: "8px",
    display: "flex",
    alignItems: "center",
    marginBottom: "11px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    justifyContent: "space-between",
  },
  title: {
    color: "#98A0AC",
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    marginBottom: "6px",
  },
  sub: {
    color: "#4E5A6B",
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    textTransform: "capitalize"

  },
  seeMoreLessTextStyle: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.palette.primary.main,
  },
  margin: {
    margin: "0px 0px 14px",
  },
  typeName: {
    color: "#fff",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize:"0.875rem",
    marginLeft: "10px",
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
  },
  flexBox1: {
    padding: "15px",
  },
  edit: {
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    backgroundColor: "#fff",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
}));
