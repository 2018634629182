import { CheckCircle, InfoOutlined } from "@mui/icons-material";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React from "react";
import ShowMoreText from "react-show-more-text";
import { CloseIconWithBG } from "../../assets";
import RecordActualExpense from "../../assets/recordActualExpense";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import {
  AlertProps,
  NetWorkCallMethods,
  useWindowDimensions,
} from "../../utils";
import { AlertDialog } from "../dialog";
import { Slider } from "../slider";
import { EditIcon } from "../svg";
import { TextBox } from "../textbox";
import { InfoCard } from "./instructions";
import { AccordianStyle } from "./styles";

export const CheckListDetails = ({
  state = {},
  is_unit = false,
  instructions = {},
  setInstructions = () => false,
  generalInspection = false,
  item = {},
  t = () => false,
  MoveOut = false,
  setNotesData = () => false,
  notesData = "",
  onUpdateNotes = () => false,
  status = "",
  type = "",
  isDisableBtn = false,
  handleDownload = () => false,
  setLoading = () => false,
}) => {
  const size = useWindowDimensions();
  const alert = React.useContext(AlertContext);
  const [data, setData] = React.useState({
    penalty_amount: null,
    move_out_penalty_amount: null,
  });
  const [info, setInfo] = React.useState("");
  const [open, setOpen] = React.useState({
    bool: false,
    data: [],
  });
  const [isShowInfo, setIsShowInfo] = React.useState({
    bool: false,
    data: {},
  });
  const [checkListData, setCheckListData] = React.useState([]);
  const classes = AccordianStyle();
  const colorOptions = [
    { label: "green", value: "#48B76E" },
    { label: "orange", value: "#F68F4E" },
    { label: "yellow", value: "#ECCB3A" },
    { label: "red", value: "#EC5150" },
  ];
  const [tabValue, setTabValue] = React.useState("1");
  const [tabOptions, setTabOptions] = React.useState([]);
  const [isEdit, setIsEdit] = React.useState(false);
  const getColor = (value) => {
    if (value) {
      return colorOptions?.filter((x) => {
        return x.label === value;
      })?.[0].value;
    } else {
      return undefined;
    }
  };
  const getCheckListDetails = (activeTab = 1) => {
    // setLoading(true)
    const payload = {
      id: state?.request_id,
      agreement_inspection_id: state?.id ?? state?.agreement_inspection_id,
      inspection_item_map: is_unit ? null : state?.inspection_item_map ?? null,
      activity_id: is_unit ? null : item?.inspection_item_mapping?.id,
      types:
        activeTab === "1"
          ? "General"
          : activeTab === "2"
            ? "Item Group"
            : "Unit",
    };
    NetworkCall(
      `${config.api_url}/agreement_inspection/get_checklist_inspection`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        // setLoading(false)
        setInstructions(res?.data?.group_details);
        setCheckListData(res?.data?.list);
      })
      .catch((err) => {
        // setLoading(false)
        console.log(err);
      });
  };
  React.useEffect(() => {
    setData({
      ...data,
      penalty_amount: item?.move_in_details?.penalty_amount,
      move_out_penalty_amount: item?.move_out_details?.penalty_amount,
    });
    if (item?.check_list?.length > 0) {
      const result = item?.check_list?.map((x, index) => {
        return {
          label:
            x?.check_list_group_type === "General"
              ? t("Common CheckList")
              : x?.check_list_group_type === "Item Group"
                ? t("Specific CheckList")
                : "Unit Based CheckList",
          value:
            x?.check_list_group_type === "General"
              ? "1"
              : x?.check_list_group_type === "Item Group"
                ? "2"
                : "3",
          type: x?.check_list_group_type,
          is_active: x?.count > 0,
        };
      });
      setTabOptions(result);
      setTabValue(
        result?.filter((x) => {
          return x?.is_active;
        })?.[0]?.value
      );
      getCheckListDetails(
        result?.filter((x) => {
          return x?.is_active;
        })?.[0]?.value
      );
    } else {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, [item]);
  const onUpdatePenaltyAmount = (val) => {
    const payload = {
      id: state?.request_id,
      update: {
        penalty_amount: !MoveOut
          ? data?.penalty_amount
          : data?.move_out_penalty_amount,
      },
    };
    NetworkCall(
      `${config.api_url}/queries/agreement_inspection/update`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Penalty Amount Updated Successfully"),
        });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went wrong"),
        });
      });
  };
  const handlePenaltyAmount = () => {
    if (
      data?.penalty_amount !== null ||
      data?.move_out_penalty_amount !== null
    ) {
      onUpdatePenaltyAmount();
      setIsEdit(false);
    }
  };
  //handleChangeNotes
  const handleChangeNotes = (e) => {
    setInfo(e.target.value);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    getCheckListDetails(newValue);
  };
  //onclickupdate
  const updateNotes = () => {
    if (info?.length > 0) {
      setNotesData([...notesData?.managerNotes, { notes: info }]);
      onUpdateNotes(info);
    }
  };
  const onClickImage = (data) => {
    setOpen({
      bool: !open?.bool,
      data: data ?? [],
    });
    // setExpend(true)
  };
  return (
    <Box sx={{ borderRadius: "4px" }}>
      {!generalInspection ? (
        tabOptions?.filter((val) => {
          return val?.is_active;
        })?.length > 0 && (
          <Box
            display="flex"
            justifyContent={"space-between"}
            style={{ borderBottom: "1px solid #CED3DD" }}
            alignItems={"center"}
          >
            <Tabs value={tabValue} onChange={handleChange}>
              {tabOptions
                ?.filter((val) => {
                  return val?.is_active;
                })
                ?.map((x) => {
                  return (
                    <Tab
                      label={x?.label}
                      value={x?.value}
                      className={classes.customtab}
                    />
                  );
                })}
            </Tabs>
          </Box>
        )
      ) : (tabOptions?.filter((val) => {
        return val?.is_active;
      })?.length > 0 ||
        status === "Completed") &&
        generalInspection ? (
        <Box
          p={
            tabOptions?.filter((val) => {
              return val?.is_active;
            })?.length > 0
              ? 0
              : 1.5
          }
          display="flex"
          justifyContent={
            tabOptions?.filter((val) => {
              return val?.is_active;
            })?.length > 0
              ? "space-between"
              : state?.request_type === "site_visit"
                ? "space-between"
                : "end"
          }
          style={{ borderBottom: "1px solid #CED3DD" }}
          alignItems={"center"}
        >
          {state?.request_type !== "site_visit" ? (
            tabOptions?.filter((val) => {
              return val?.is_active;
            })?.length > 0 && (
              <Tabs value={tabValue} onChange={handleChange}>
                {tabOptions
                  ?.filter((val) => {
                    return val?.is_active;
                  })
                  ?.map((x) => {
                    return (
                      <Tab
                        label={x?.label}
                        value={x?.value}
                        className={classes.customtab}
                      />
                    );
                  })}
              </Tabs>
            )
          ) : (
            <Typography className={classes.customtab}>
              {t("Details")}
            </Typography>
          )}
          {status === "Completed" && (
            <Box
              pr={
                tabOptions?.filter((val) => {
                  return val?.is_active;
                })?.length > 0
                  ? 2
                  : 0
              }
              display={"flex"}
              justifyContent={"end"}
              alignItems={"end"}
            >
              <Button
                variant="outlined"
                className={classes.cancelbtn}
                onClick={() => handleDownload()}
              >
                <Stack direction={"row"} spacing={1} alignItems="center">
                  <img src={"/images/downloadInspection.svg"} alt="download" />
                  <Typography className={classes.viewMore}>
                    {t("Inspection Report")}
                  </Typography>
                </Stack>
              </Button>
            </Box>
          )}
        </Box>
      ) : (
        <Box p={1.5}>
          <Typography className={classes.customtab}>{t("Details")}</Typography>
        </Box>
      )}
      <Box className={classes.detailsRoot} p={2}>
        {!generalInspection && (
          <Grid container spacing={2}>
            <Grid item xs={4} sm={4} md={4} lg={3}>
              <Typography className={classes.itemTitle}>
                {t("ITEM AND ASSETS")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={MoveOut ? 4.5 : 9}
              sm={MoveOut ? 4.5 : 9}
              md={MoveOut ? 4.5 : 9}
              lg={MoveOut ? 4.5 : 9}
            >
              <Typography
                className={classes.itemTitle}
                textAlign={"right"}
                pr={3}
              >
                {state?.request_type === "unit_readiness"
                  ? t("Details")
                  : t("MOVE IN")}
              </Typography>
            </Grid>
            {MoveOut && (
              <Grid item xs={4.5} sm={4.5} md={4.5} lg={4.5}>
                <Typography
                  className={classes.itemTitle}
                  textAlign={"right"}
                  pr={3}
                >
                  {t("MOVE OUT")}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
        <Box
          mt={!generalInspection ? 2 : 0}
          height={
            !generalInspection
              ? size?.height - 179
              : state?.request_type === "site_visit"
                ? size?.height - 240
                : size?.height - 318
          }
          overflow={"overlay"}
        >
          <Box className={classes.notesSection}>
            <Box p={1.5}>
              <Typography className={classes.checkListName}>{`${t(
                "Notes"
              )}`}</Typography>
              <Box mt={1}>
                <Grid container spacing={2}>
                  <Grid item xs={3} sm={3} md={3} lg={3}>
                    <Typography className={classes.subtitle1}>
                      {t("Manager")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={MoveOut ? 4.5 : 9}
                    sm={MoveOut ? 4.5 : 9}
                    md={MoveOut ? 4.5 : 9}
                    lg={MoveOut ? 4.5 : 9}
                    onClick={(e) =>
                      item?.move_in_details?.inspection_manager_notes !==
                      null && e.stopPropagation()
                    }
                  >
                    {item?.move_in_details?.inspection_manager_notes !==
                      null ? (
                      item?.move_in_details?.inspection_manager_notes?.map(
                        (val) => {
                          return (
                            <Typography
                              className={classes.subtitle2}
                              onClick={(e) => e.stopPropagation()}
                            >
                              {val?.notes !== null ? (
                                <ShowMoreText
                                  lines={2}
                                  more="Show More"
                                  less="Show Less"
                                  className={classes.subtitle2}
                                  anchorClass={classes.seeMoreLessTextStyle}
                                  expanded={false}
                                  truncatedEndingComponent={"... "}
                                >
                                  {val?.notes?.match(/\n+/g) ? (
                                    <Typography
                                      className={classes.subtitle2}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          (val?.notes).replace(
                                            /\n+/g,
                                            "<br />"
                                          ) ?? "",
                                      }}
                                    ></Typography>
                                  ) : (
                                    val?.notes
                                  )}
                                  {/* {val?.notes?.split('\n').map((item, i) => <Typography className={classes.subtitle2} key={i}>{item}</Typography>)} */}
                                </ShowMoreText>
                              ) : (
                                "-"
                              )}
                            </Typography>
                          );
                        }
                      )
                    ) : status === "Completed" &&
                      item?.move_in_details?.inspection_manager_notes ===
                      null ? (
                      <Typography className={classes.subtitle2}>
                        {"-"}
                      </Typography>
                    ) : status !== "Completed" && !MoveOut ? (
                      <Box className={classes.textAreaBox}>
                        <TextBox
                          label=""
                          placeholder={t("Type Notes here")}
                          rowheight={6}
                          border={0}
                          color="white"
                          multiline
                          onChange={(e) => handleChangeNotes(e)}
                          value={info}
                        />
                        <Box mt={2}>
                          <Button
                            variant="contained"
                            fullWidth
                            onClick={updateNotes}
                            disabled={isDisableBtn}
                          >
                            {t("Update Note")}
                          </Button>
                        </Box>
                      </Box>
                    ) : (
                      <Typography className={classes.subtitle2}>
                        {"-"}
                      </Typography>
                    )}
                  </Grid>
                  {MoveOut && (
                    <Grid
                      item
                      xs={4.5}
                      sm={4.5}
                      md={4.5}
                      lg={4.5}
                      onClick={(e) =>
                        item?.move_out_details?.inspection_manager_notes !==
                        null && e.stopPropagation()
                      }
                    >
                      {item?.move_out_details?.inspection_manager_notes !==
                        null ? (
                        item?.move_out_details?.inspection_manager_notes?.map(
                          (val) => {
                            return (
                              <Typography
                                className={classes.subtitle2}
                                onClick={(e) => e.stopPropagation()}
                              >
                                {val?.notes !== null ? (
                                  <ShowMoreText
                                    lines={2}
                                    more="Show More"
                                    less="Show Less"
                                    className={classes.subtitle2}
                                    anchorClass={classes.seeMoreLessTextStyle}
                                    expanded={false}
                                    truncatedEndingComponent={"... "}
                                  >
                                    {val?.notes?.match(/\n+/g) ? (
                                      <Typography
                                        className={classes.subtitle2}
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            (val?.notes).replace(
                                              /\n+/g,
                                              "<br />"
                                            ) ?? "",
                                        }}
                                      ></Typography>
                                    ) : (
                                      val?.notes
                                    )}
                                  </ShowMoreText>
                                ) : (
                                  "-"
                                )}
                              </Typography>
                            );
                          }
                        )
                      ) : status === "Completed" &&
                        item?.move_out_details?.inspection_manager_notes ===
                        null ? (
                        <Typography className={classes.subtitle2}>
                          {"-"}
                        </Typography>
                      ) : (
                        status !== "Completed" && (
                          <Box className={classes.textAreaBox}>
                            <TextBox
                              label=""
                              placeholder={t("Type Notes here")}
                              rowheight={6}
                              border={0}
                              color="white"
                              multiline
                              onChange={(e) => handleChangeNotes(e)}
                              value={info}
                            />
                            <Box mt={2}>
                              <Button
                                variant="contained"
                                fullWidth
                                onClick={updateNotes}
                                disabled={isDisableBtn}
                              >
                                {t("Update Note")}
                              </Button>
                            </Box>
                          </Box>
                        )
                      )}
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
            <Box>
              <Divider />
            </Box>
            <Box p={1.5}>
              <Grid container spacing={2}>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  <Typography className={classes.subtitle1}>
                    {t("Inspector")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={MoveOut ? 4.5 : 9}
                  sm={MoveOut ? 4.5 : 9}
                  md={MoveOut ? 4.5 : 9}
                  lg={MoveOut ? 4.5 : 9}
                  onClick={(e) => e.stopPropagation()}
                >
                  {item?.move_in_details?.inspector_notes !== null ? (
                    <ShowMoreText
                      lines={2}
                      more="Show More"
                      less="Show Less"
                      className={classes.subtitle2}
                      anchorClass={classes.seeMoreLessTextStyle}
                      expanded={false}
                      truncatedEndingComponent={"... "}
                    >
                      {item?.move_in_details?.inspector_notes?.match(/\n+/g) ? (
                        <Typography
                          className={classes.subtitle2}
                          dangerouslySetInnerHTML={{
                            __html:
                              (item?.move_in_details?.inspector_notes).replace(
                                /\n+/g,
                                "<br />"
                              ) ?? "",
                          }}
                        ></Typography>
                      ) : (
                        item?.move_in_details?.inspector_notes
                      )}
                      {/* {item?.move_in_details?.inspector_notes?.split('\n').map((item, i) => <Typography className={classes.subtitle2} key={i}>{item}</Typography>)} */}
                    </ShowMoreText>
                  ) : (
                    <Typography className={classes.subtitle2}>{"-"}</Typography>
                  )}
                </Grid>
                {MoveOut && (
                  <Grid
                    item
                    xs={4.5}
                    sm={4.5}
                    md={4.5}
                    lg={4.5}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {item?.move_out_details?.inspector_notes !== null ? (
                      <ShowMoreText
                        lines={2}
                        more="Show More"
                        less="Show Less"
                        className={classes.subtitle2}
                        anchorClass={classes.seeMoreLessTextStyle}
                        expanded={false}
                        truncatedEndingComponent={"... "}
                      >
                        {item?.move_out_details?.inspector_notes?.match(
                          /\n+/g
                        ) ? (
                          <Typography
                            className={classes.subtitle2}
                            dangerouslySetInnerHTML={{
                              __html:
                                (item?.move_out_details?.inspector_notes).replace(
                                  /\n+/g,
                                  "<br />"
                                ) ?? "",
                            }}
                          ></Typography>
                        ) : (
                          item?.move_out_details?.inspector_notes
                        )}
                      </ShowMoreText>
                    ) : (
                      <Typography className={classes.subtitle2}>
                        {"-"}
                      </Typography>
                    )}
                  </Grid>
                )}
              </Grid>
            </Box>
            <Box>
              <Divider />
            </Box>
            {item?.move_in_details?.costing_notes?.length > 0 && (
              <>
                <Box p={1.5}>
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={3} md={3} lg={3}>
                      <Typography className={classes.subtitle1}>
                        {t("Bill of Material")}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={MoveOut ? 4.5 : 9}
                      sm={MoveOut ? 4.5 : 9}
                      md={MoveOut ? 4.5 : 9}
                      lg={MoveOut ? 4.5 : 9}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {item?.move_in_details?.costing_notes !== null ? (
                        <ShowMoreText
                          lines={2}
                          more="Show More"
                          less="Show Less"
                          className={classes.subtitle2}
                          anchorClass={classes.seeMoreLessTextStyle}
                          expanded={false}
                          truncatedEndingComponent={"... "}
                        >
                          {item?.move_in_details?.costing_notes?.match(
                            /\n+/g
                          ) ? (
                            <Typography
                              className={classes.subtitle2}
                              dangerouslySetInnerHTML={{
                                __html:
                                  (item?.move_in_details?.costing_notes).replace(
                                    /\n+/g,
                                    "<br />"
                                  ) ?? "",
                              }}
                            ></Typography>
                          ) : (
                            item?.move_in_details?.costing_notes
                          )}

                          {/* {item?.move_in_details?.costing_notes?.split('\n').map((item, i) => <Typography className={classes.subtitle2} key={i}>{item}</Typography>)} */}
                        </ShowMoreText>
                      ) : (
                        <Typography className={classes.subtitle2}>
                          {"-"}
                        </Typography>
                      )}
                    </Grid>
                    {MoveOut && (
                      <Grid
                        item
                        xs={4.5}
                        sm={4.5}
                        md={4.5}
                        lg={4.5}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {item?.move_out_details?.costing_notes !== null ? (
                          <ShowMoreText
                            lines={2}
                            more="Show More"
                            less="Show Less"
                            className={classes.subtitle2}
                            anchorClass={classes.seeMoreLessTextStyle}
                            expanded={false}
                            truncatedEndingComponent={"... "}
                          >
                            {item?.move_out_details?.costing_notes?.match(
                              /\n+/g
                            ) ? (
                              <Typography
                                className={classes.subtitle2}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    (item?.move_out_details?.costing_notes).replace(
                                      /\n+/g,
                                      "<br />"
                                    ) ?? "",
                                }}
                              ></Typography>
                            ) : (
                              item?.move_out_details?.costing_notes
                            )}
                          </ShowMoreText>
                        ) : (
                          <Typography className={classes.subtitle2}>
                            {"-"}
                          </Typography>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Box>
                <Box>
                  <Divider />
                </Box>
              </>
            )}
            {item?.move_in_details?.discrepancy_note?.length > 0 && (
              <>
                <Box p={1.5}>
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={3} md={3} lg={3}>
                      <Typography className={classes.subtitle1}>
                        {t("Discrepancy")}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={MoveOut ? 4.5 : 9}
                      sm={MoveOut ? 4.5 : 9}
                      md={MoveOut ? 4.5 : 9}
                      lg={MoveOut ? 4.5 : 9}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {item?.move_in_details?.discrepancy_note !== null ? (
                        <ShowMoreText
                          lines={2}
                          more="Show More"
                          less="Show Less"
                          className={classes.subtitle2}
                          anchorClass={classes.seeMoreLessTextStyle}
                          expanded={false}
                          truncatedEndingComponent={"... "}
                        >
                          {item?.move_in_details?.discrepancy_note?.match(
                            /\n+/g
                          ) ? (
                            <Typography
                              className={classes.subtitle2}
                              dangerouslySetInnerHTML={{
                                __html:
                                  (item?.move_in_details?.discrepancy_note).replace(
                                    /\n+/g,
                                    "<br />"
                                  ) ?? "",
                              }}
                            ></Typography>
                          ) : (
                            item?.move_in_details?.discrepancy_note
                          )}

                          {/* {item?.move_in_details?.discrepancy_note?.split('\n').map((item, i) => <Typography className={classes.subtitle2} key={i}>{item}</Typography>)} */}
                        </ShowMoreText>
                      ) : (
                        <Typography className={classes.subtitle2}>
                          {"-"}
                        </Typography>
                      )}
                    </Grid>
                    {MoveOut && (
                      <Grid
                        item
                        xs={4.5}
                        sm={4.5}
                        md={4.5}
                        lg={4.5}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {item?.move_out_details?.discrepancy_note !== null ? (
                          <ShowMoreText
                            lines={2}
                            more="Show More"
                            less="Show Less"
                            className={classes.subtitle2}
                            anchorClass={classes.seeMoreLessTextStyle}
                            expanded={false}
                            truncatedEndingComponent={"... "}
                          >
                            {item?.move_out_details?.discrepancy_note?.match(
                              /\n+/g
                            ) ? (
                              <Typography
                                className={classes.subtitle2}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    (item?.move_out_details?.discrepancy_note).replace(
                                      /\n+/g,
                                      "<br />"
                                    ) ?? "",
                                }}
                              ></Typography>
                            ) : (
                              item?.move_out_details?.discrepancy_note
                            )}
                            {/* {item?.move_out_details?.discrepancy_note?.split('\n').map((item, i) => <Typography className={classes.subtitle2} key={i}>{item}</Typography>)} */}
                          </ShowMoreText>
                        ) : (
                          <Typography className={classes.subtitle2}>
                            {"-"}
                          </Typography>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Box>
                <Box>
                  <Divider />
                </Box>
              </>
            )}

            <Box p={1.5}>
              <Typography className={classes.checkListName}>{`${t(
                "Penalty"
              )}`}</Typography>
              <Box
                mt={1}
                className={classes.penaltySection}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Stack direction="row" spacing={1} alignItems={"center"}>
                  <RecordActualExpense />
                  <Typography className={classes.subtitle1}>
                    {t("Penalty Amount")}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1.5} alignItems={"center"}>
                  {isEdit ? (
                    <Box width={"150px"}>
                      <TextBox
                        label=""
                        value={data?.penalty_amount}
                        color="white"
                        onChange={(e) =>
                          setData({ ...data, penalty_amount: e?.target?.value })
                        }
                        endAdornment={
                          <CheckCircle
                            color="primary"
                            sx={{ cursor: "pointer" }}
                            onClick={() => handlePenaltyAmount()}
                          />
                        }
                        height="48px"
                        textBoxHeight="15px"
                      />
                    </Box>
                  ) : (
                    <>
                      <Typography className={classes.subtitle1}>
                        {data?.penalty_amount ?? 0} {state?.currency_code}
                      </Typography>
                      <Box
                        onClick={() => setIsEdit(true)}
                        sx={{ cursor: "pointer" }}
                      >
                        <EditIcon />
                      </Box>
                    </>
                  )}
                </Stack>
                <Button variant="contained">{t("Generate Invoice")}</Button>
              </Box>
            </Box>
          </Box>
          {checkListData?.length > 0 &&
            checkListData?.map((val, i) => {
              return (
                <Box className={classes.notesSection} mt={2}>
                  <Box p={1.5}>
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Typography className={classes.checkListName}>
                        {i + 1}.
                      </Typography>
                      {val?.is_mandatory && (
                        <span className={classes.astriksymbol}>*</span>
                      )}
                      <Typography className={classes.checkListName} noWrap>
                        {val?.list_name}
                      </Typography>
                      <InfoOutlined
                        style={{ color: "#98A0AC", cursor: "pointer" }}
                        onClick={() => setIsShowInfo({ bool: true, data: val })}
                      />
                    </Stack>
                    <Box mt={1}>
                      <Grid container spacing={0} alignItems={"center"}>
                        <Grid item xs={6} sm={6} md={4} lg={3}>
                          <Typography className={classes.subtitle1}>
                            {t("Condition")}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={!MoveOut ? 9 : 4.5}
                          sm={!MoveOut ? 9 : 4.5}
                          md={!MoveOut ? 9 : 4.5}
                          lg={!MoveOut ? 9 : 4.5}
                        >
                          <Box display={"flex"} justifyContent={"end"}>
                            <Typography
                              className={classes.tag}
                              sx={{
                                backgroundColor:
                                  getColor(val?.color_code) ?? "#5AC782",
                              }}
                              noWrap
                            >
                              {val?.condition_name ?? "-"}
                            </Typography>
                          </Box>
                        </Grid>
                        {MoveOut && (
                          <Grid item xs={4.5} sm={4.5} md={4} lg={4.5}>
                            <Box display={"flex"} justifyContent={"end"}>
                              <Typography
                                className={classes.tag}
                                sx={{
                                  backgroundColor:
                                    getColor(val?.move_out?.color_code) ??
                                    "#5AC782",
                                }}
                                noWrap
                              >
                                {val?.move_out?.condition_name ?? "-"}
                              </Typography>
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                      {val?.is_software_hardware && (
                        <Grid container spacing={0}>
                          <Grid item xs={6} sm={6} md={4} lg={3}>
                            <Typography className={classes.subtitle1} mt={1}>
                              {t("Platform")}
                            </Typography>
                            <Typography className={classes.subtitle1} mt={1}>
                              {t("IMEI")}
                            </Typography>
                            <Typography className={classes.subtitle1} mt={1}>
                              {t("MAC")}
                            </Typography>
                            <Typography className={classes.subtitle1} mt={1}>
                              {t("License")}
                            </Typography>
                            <Typography className={classes.subtitle1} mt={1}>
                              {t("Hardware")}
                            </Typography>
                            <Typography className={classes.subtitle1} mt={1}>
                              {t("Software")}
                            </Typography>
                            <Typography className={classes.subtitle1} mt={1}>
                              {t("Firmware")}
                            </Typography>
                            <Typography className={classes.subtitle1} mt={1}>
                              {t("Build")}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={!MoveOut ? 9 : 4.5}
                            sm={!MoveOut ? 9 : 4.5}
                            md={!MoveOut ? 9 : 4.5}
                            lg={!MoveOut ? 9 : 4.5}
                          >
                            <Typography className={classes.subtitle2} mt={1}>
                              {val?.platform ?? "-"}
                            </Typography>
                            <Typography className={classes.subtitle2} mt={1}>
                              {val?.imei ?? "-"}
                            </Typography>
                            <Typography className={classes.subtitle2} mt={1}>
                              {val?.mac ?? "-"}
                            </Typography>
                            <Typography className={classes.subtitle2} mt={1}>
                              {val?.license ?? "-"}
                            </Typography>
                            <Typography className={classes.subtitle2} mt={1}>
                              {val?.hardware ?? "-"}
                            </Typography>
                            <Typography className={classes.subtitle2} mt={1}>
                              {val?.software ?? "-"}
                            </Typography>
                            <Typography className={classes.subtitle2} mt={1}>
                              {val?.firmware ?? "-"}
                            </Typography>
                          </Grid>
                          {MoveOut && (
                            <Grid item xs={4.5} sm={4.5} md={4} lg={4.5}>
                              <Typography className={classes.subtitle2} mt={1}>
                                {val?.move_out?.platform ?? "-"}
                              </Typography>
                              <Typography className={classes.subtitle2} mt={1}>
                                {val?.move_out?.imei ?? "-"}
                              </Typography>
                              <Typography className={classes.subtitle2} mt={1}>
                                {val?.move_out?.mac ?? "-"}
                              </Typography>
                              <Typography className={classes.subtitle2} mt={1}>
                                {val?.move_out?.license ?? "-"}
                              </Typography>
                              <Typography className={classes.subtitle2} mt={1}>
                                {val?.move_out?.hardware ?? "-"}
                              </Typography>
                              <Typography className={classes.subtitle2} mt={1}>
                                {val?.move_out?.software ?? "-"}
                              </Typography>
                              <Typography className={classes.subtitle2} mt={1}>
                                {val?.move_out?.firmware ?? "-"}
                              </Typography>
                              <Typography className={classes.subtitle2} mt={1}>
                                {val?.move_out?.build ?? "-"}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      )}
                      <Grid container spacing={0} alignItems={"center"}>
                        <Grid item xs={6} sm={6} md={4} lg={3}>
                          <Typography className={classes.subtitle1} mt={1}>
                            {t("Worker Note")}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={!MoveOut ? 9 : 4.5}
                          sm={!MoveOut ? 9 : 4.5}
                          md={!MoveOut ? 9 : 4.5}
                          lg={!MoveOut ? 9 : 4.5}
                        >
                          {val?.worker_note !== null ? (
                            <Box mt={1}>
                              <ShowMoreText
                                lines={2}
                                more="Show More"
                                less="Show Less"
                                className={classes.subtitle2}
                                anchorClass={classes.seeMoreLessTextStyle}
                                expanded={false}
                                truncatedEndingComponent={"... "}
                              >
                                {val?.worker_note?.match(/\n+/g) ? (
                                  <Typography
                                    className={classes.subtitle2}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        (val?.worker_note).replace(
                                          /\n+/g,
                                          "<br />"
                                        ) ?? "",
                                    }}
                                  ></Typography>
                                ) : (
                                  val?.worker_note
                                )}

                                {/* {val?.worker_note?.split('\n').map((item, i) => <Typography className={classes.subtitle2} key={i}>{item}</Typography>)} */}
                              </ShowMoreText>
                            </Box>
                          ) : (
                            <Typography className={classes.subtitle2} mt={2}>
                              {"-"}
                            </Typography>
                          )}
                        </Grid>
                        {MoveOut && (
                          <Grid item xs={4.5} sm={4.5} md={4} lg={4.5}>
                            {val?.move_out?.worker_note?.length > 0 &&
                              val?.move_out?.worker_note !== null ? (
                              <ShowMoreText
                                lines={2}
                                more="Show More"
                                less="Show Less"
                                className={classes.subtitle2}
                                anchorClass={classes.seeMoreLessTextStyle}
                                expanded={false}
                                truncatedEndingComponent={"... "}
                              >
                                <Typography
                                  className={classes.subtitle2}
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      (val?.move_out?.worker_note).replace(
                                        /\n+/g,
                                        "<br />"
                                      ) ?? "",
                                  }}
                                ></Typography>
                                {/* {val?.move_out?.worker_note?.split('\n').map((item, i) => <Typography className={classes.subtitle2} key={i} mt={1}>{item}</Typography>)} */}
                              </ShowMoreText>
                            ) : (
                              <Typography className={classes.subtitle2} mt={1}>
                                {"-"}
                              </Typography>
                            )}
                          </Grid>
                        )}
                      </Grid>
                      <Grid container spacing={0} alignItems={"center"}>
                        <Grid item xs={6} sm={6} md={4} lg={3}>
                          <Typography className={classes.subtitle1} mt={1}>
                            {t("Image")}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={!MoveOut ? 9 : 4.5}
                          sm={!MoveOut ? 9 : 4.5}
                          md={!MoveOut ? 9 : 4.5}
                          lg={!MoveOut ? 9 : 4.5}
                        >
                          {val?.assets?.length > 0 ? (
                            <Box display={"flex"} justifyContent={"end"} mt={1}>
                              <AvatarGroup
                                onClick={() => onClickImage(val?.assets)}
                                className={classes.avatarGroup}
                                max={4}
                                sx={{ float: "left" }}
                              >
                                {val?.assets?.map((src) => {
                                  return (
                                    <Avatar
                                      sx={{ width: 34, height: 34 }}
                                      alt=""
                                      src={src?.url}
                                    />
                                  );
                                })}
                              </AvatarGroup>
                            </Box>
                          ) : (
                            <Box display={"flex"} justifyContent={"end"} mt={1}>
                              {"-"}
                            </Box>
                          )}
                        </Grid>
                        {MoveOut && (
                          <Grid item xs={4.5} sm={4.5} md={4} lg={4.5}>
                            {val?.move_out?.assets?.length > 0 ? (
                              <Box
                                display={"flex"}
                                justifyContent={"end"}
                                mt={1}
                              >
                                <AvatarGroup
                                  onClick={() => onClickImage(val?.assets)}
                                  className={classes.avatarGroup}
                                  max={4}
                                  sx={{ float: "left" }}
                                >
                                  {val?.move_out?.assets?.map((src) => {
                                    return (
                                      <Avatar
                                        sx={{ width: 34, height: 34 }}
                                        alt=""
                                        src={src?.url}
                                      />
                                    );
                                  })}
                                </AvatarGroup>
                              </Box>
                            ) : (
                              <Box
                                display={"flex"}
                                justifyContent={"end"}
                                mt={1}
                              >
                                {"-"}
                              </Box>
                            )}
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              );
            })}
        </Box>
        <Drawer
          anchor={"right"}
          open={isShowInfo?.bool}
          onClose={() => setIsShowInfo({ bool: false, data: {} })}
        >
          <Stack
            direction="row"
            spacing={2}
            p={1.5}
            alignItems="center"
            sx={{ backgroundColor: "#F2F4F7" }}
          >
            <Box
              onClick={() => setIsShowInfo({ bool: false, data: {} })}
              style={{ cursor: "pointer" }}
            >
              <CloseIconWithBG />
            </Box>
            <Typography className={classes.drawerHeader}>
              {t("Instructions & Documents")}
            </Typography>
          </Stack>
          <Divider />
          <Box p={1.5} width="400px">
            <InfoCard
              t={t}
              isCustom={true}
              state={state}
              data={isShowInfo?.data}
            />
          </Box>
        </Drawer>
      </Box>
      <AlertDialog
        open={open?.bool}
        header="Assets"
        md={true}
        onClose={() => onClickImage()}
        component={
          <Box p={1}>
            <Slider backgroundColor="black" assets={open?.data} />
          </Box>
        }
      />
    </Box>
  );
};
