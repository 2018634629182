import { Box, Button, Grid, useMediaQuery } from "@mui/material";

import moment from "moment";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  FormGenerator,
  LoadingSection,
  TextBox,
  UploadComponent,
} from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { BackendRoutes } from "../../../router/routes";
import {
  AlertProps,
  enum_types,
  enumSelect,
  isEmptyObject,
  NetWorkCallMethods,
} from "../../../utils";
import {
  initialState,
  returnEditPayload,
  returnSavePayload,
} from "../../../utils/resourceMaster";
import { loadOptionsApis } from "../../createMaintenancePlanner/utils";
import { constructOptionResponse } from "../utils";
import { ResourceMasterComponentStyles } from "./styles";
import { UserCard } from "./userCard";

const Resources = ({
  type = "",
  setData = () => false,
  data = {},
  company = {},
  reload = () => false,
  onClose = () => false,
  setSelectedType = () => false,
  t,
}) => {
  const classes = ResourceMasterComponentStyles();
  const alert = React.useContext(AlertContext);
  const [list, setList] = React.useState([]);
  const [offset, setOffset] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const [assets, setAssets] = React.useState([]);
  const [module, setModule] = React.useState([]);
  const [period, setPeriod] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const matches = useMediaQuery("(max-width:900px)");
  const [isDisableBtn, setIsDisableBtn] = React.useState(false);
  const resourceTypes = {
    tools: t("Tools"),
    vehicle: t("Vehicles"),
    account: t("Account"),
    users: t("Users"),
    generic: t("Generic"),
  };
  //get Enum
  const getEnum = async () => {
    const result = await enumSelect([enum_types.resource_period_enum]);
    setPeriod(result?.resource_period_enum);
  };
  //get module
  const getModule = () => {
    const payload = {
      offset: 0,
      limit: 25,
    };
    NetworkCall(
      `${config.api_url}/modules`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((res) => {
      const result = res?.data?.data?.map((val) => {
        return {
          value: val?.id,
          label: val?.name,
        };
      });
      setModule(result);
    });
  };
  //get account
  const getList = (types, offset, search, fetch) => {
    const payload = {
      offset: offset,
      limit: 10,
      company_id: company?.value,
      relationship: ["Company Employee"],
      search: search,
    };
    NetworkCall(
      `${config.api_url}/queries/opportunity/contact/list`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        if (fetch) {
          setList(response?.data?.data?.contact);
        } else {
          setList(list.concat(response?.data?.data?.contact));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //get details
  const getDetails = () => {
    const payload = {
      id: data?.id,
    };
    NetworkCall(
      `${config.api_url}/resources/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        let result = returnEditPayload(res?.data);
        setAssets(result?.assets);
        setData(result);
        setSelectedType(result?.type);
        getList(result?.type?.label, 0, "", false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //initial load
  React.useEffect(() => {
    if (data?.isEdit) {
      getDetails();
    } else {
      getList(type?.label, 0, "", false);
      setLoading(false);
    }
    getModule();
    getEnum();
    // eslint-disable-next-line
  }, []);
  //onchange module
  const changeModule = (val) => {
    updateState("module", val);
  };
  //updateState
  const updateState = (key, value) => {
    let error = data.error;
    error[key] = "";
    setData({ ...data, [key]: value, error });
  };
  const manualResponse = (array) => {
    const result = {
      data: array?.working_hours_master?.map((_) => {
        return {
          ..._,
          label: `${_?.shift_name} ( ${moment(_?.from, ["HH:mm"]).format(
            "hh:mm a"
          )} - ${moment(_?.to, ["HH:mm"]).format("hh:mm a")})`,
          value: _?.id,
        };
      }),
      count: array?.count,
    };
    return result;
  };
  const manualResponseProfession = (array) => {
    const details = {
      data: array?.data?.map((i) => {
        return {
          ...i,
          label: i?.label,
          value: i?.value,
        };
      }),
      count: array?.count,
    };
    return details;
  };
  const manualResponseJobs = (array) => {
    const details = {
      data: array?.job_master?.map((i) => {
        return {
          ...i,
          label: i?.label,
          value: i?.value,
        };
      }),
      count: array?.count,
    };
    return details;
  };
  //form json
  const toolForm = [
    {
      size: {
        xs: 4,
        sm: 4,
        md: 4,
        lg: 4,
      },
      isActive: t(type?.label) === `${t(resourceTypes?.tools)}`,
      component: "select",
      label: `${resourceTypes?.tools}`,
      placeholder: t("Select Tools"),
      value: data?.tool,
      onChange: (value) => {
        setData({
          ...data,
          tool: value,
          rate_hour: value?.rate,
          period: {
            label: value?.period,
            value: value?.period,
          },
        });
      },
      error: data?.error?.tool,
      isRequired: true,
      loadOptions: (search, array, handleLoading) =>
        loadOptionsApis(
          "queries/tools_master/get",
          {
            company_id: company?.value,
          },
          search,
          array,
          handleLoading,
          "tools_master",
          {}
        ),
      debounceTimeout: 800,
      isPaginate: true,
      menuPlacement: "bottom",
    },
    {
      size: {
        xs:
          t(type?.label) === `${t(resourceTypes?.users)}` ||
          t(type?.label) === `${t(resourceTypes?.account)}`
            ? 12
            : 4,
        sm:
          t(type?.label) === `${t(resourceTypes?.users)}` ||
          t(type?.label) === `${t(resourceTypes?.account)}`
            ? 12
            : 4,
        md:
          t(type?.label) === `${t(resourceTypes?.users)}` ||
          t(type?.label) === `${t(resourceTypes?.account)}`
            ? 12
            : 4,
        lg:
          t(type?.label) === `${t(resourceTypes?.users)}` ||
          t(type?.label) === `${t(resourceTypes?.account)}`
            ? 12
            : 4,
      },
      isActive:
        t(type?.label) === `${t(resourceTypes?.users)}` ||
        t(type?.label) === `${t(resourceTypes?.account)}`,
      component: "select",
      label: t("Module"),
      placeholder: t("Select Module"),
      value: data?.module,
      onChange: (value) => changeModule(value),
      error: data?.error?.module,
      isRequired: true,
      options: module,
      menuPlacement: "bottom",
    },
    {
      size: {
        xs: t(type?.label) === `${t(resourceTypes?.account)}` ? 12 : 4,
        sm: t(type?.label) === `${t(resourceTypes?.account)}` ? 12 : 4,
        md: t(type?.label) === `${t(resourceTypes?.account)}` ? 12 : 4,
        lg: t(type?.label) === `${t(resourceTypes?.account)}` ? 12 : 4,
      },
      isActive: t(type?.label) !== `${t(resourceTypes?.users)}`,
      component: "select",
      label: t("Department"),
      placeholder: t("Select Department"),
      value: data?.department,
      onChange: (value) => updateState("department", value),
      error: data?.error?.department,
      isRequired: true,
      loadOptions: (search, array, handleLoading) =>
        loadOptionsApis(
          "queries/opportunity/department_master/get",
          {
            company_id: company?.value,
          },
          search,
          array,
          handleLoading,
          "department_master",
          {}
        ),
      debounceTimeout: 800,
      isPaginate: true,
      menuPlacement: "bottom",
    },
    {
      size: {
        xs: t(type?.label) === `${t(resourceTypes?.users)}` ? 12 : 4,
        sm: t(type?.label) === `${t(resourceTypes?.users)}` ? 12 : 4,
        md: t(type?.label) === `${t(resourceTypes?.users)}` ? 12 : 4,
        lg: t(type?.label) === `${t(resourceTypes?.users)}` ? 12 : 4,
      },
      isActive: t(type?.label) === `${t(resourceTypes?.users)}`,
      component: "select",
      label: t("Jobs"),
      placeholder: t("Select Jobs"),
      value: data?.job,
      onChange: (value) => updateState("job", value),
      error: data?.error?.job,
      isRequired: true,
      loadOptions: (search, array, handleLoading) =>
        loadOptionsApis(
          "queries/job_master/list",
          {
            company_id: company?.value,
          },
          search,
          array,
          handleLoading,
          "data",
          {},
          manualResponseJobs
        ),
      debounceTimeout: 800,
      isPaginate: true,
      menuPlacement: "bottom",
    },
    {
      size: {
        xs: t(type?.label) === `${t(resourceTypes?.users)}` ? 12 : 4,
        sm: t(type?.label) === `${t(resourceTypes?.users)}` ? 12 : 4,
        md: t(type?.label) === `${t(resourceTypes?.users)}` ? 12 : 4,
        lg: t(type?.label) === `${t(resourceTypes?.users)}` ? 12 : 4,
      },
      isActive: t(type?.label) === `${t(resourceTypes?.users)}`,
      component: "select",
      label: t("Profession"),
      placeholder: t("Select Profession"),
      value: data?.profession,
      onChange: (value) => updateState("profession", value),
      error: data?.error?.profession,
      isRequired: true,
      loadOptions: (search, array, handleLoading) =>
        loadOptionsApis(
          "queries/profession_master",
          {
            company_id: company?.value,
          },
          search,
          array,
          handleLoading,
          "data",
          {},
          manualResponseProfession
        ),
      debounceTimeout: 800,
      isPaginate: true,
      menuPlacement: "bottom",
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      isActive:
        t(type?.label) === `${t(resourceTypes?.users)}` ||
        t(type?.label) === `${t(resourceTypes?.account)}`,
      component: "select",
      label: t("Working Calender"),
      placeholder: t("Select Working Calender"),
      value: data?.working_hour,
      onChange: (value) => updateState("working_hour", value),
      error: data?.error?.working_hour,
      isRequired: true,
      loadOptions: (search, array, handleLoading) =>
        loadOptionsApis(
          "queries/working_hours_master/get",
          {
            company_id: company?.value,
          },
          search,
          array,
          handleLoading,
          "data",
          {},
          manualResponse
        ),
      debounceTimeout: 800,
      isPaginate: true,
      menuPlacement: "bottom",
    },
    {
      isActive: true,
      isRequired: true,
      size: {
        xs: t(type?.label) === `${t(resourceTypes?.users)}` ? 12 : 4,
        sm: t(type?.label) === `${t(resourceTypes?.users)}` ? 12 : 4,
        md: t(type?.label) === `${t(resourceTypes?.users)}` ? 12 : 4,
        lg: t(type?.label) === `${t(resourceTypes?.users)}` ? 12 : 4,
      },
      component: "select",
      label: t("Functional Location"),
      placeholder: t("Select Functional Location"),
      value: data?.functional_location,
      onChange: (value) => updateState("functional_location", value),
      error: data?.error?.functional_location,
      loadOptions: (search, array, handleLoading) =>
        loadOptionsApis(
          BackendRoutes?.function_location_list?.slice(1),
          { company_id: company?.value },
          search,
          array,
          handleLoading,
          "data",
          {},
          constructOptionResponse
        ),
      debounceTimeout: 800,
      isPaginate: true,
    },
    {
      size: {
        xs: 6,
        sm: 6,
        md: 6,
        lg: 6,
      },
      isActive:
        t(type?.label) === `${t(resourceTypes?.users)}` ||
        t(type?.label) === `${t(resourceTypes?.account)}`,
      component: "text",
      label: t("Hours Rate"),
      placeholder: t("Enter Hours Rate"),
      value: data?.rate_hour,
      onChange: (value) => updateState("rate_hour", value.target.value),
      error: data?.error?.rate_hour,
      isRequired: true,
      type: "number",
      endAdornment: company?.currency_symbol,
    },
    {
      size: {
        xs: 6,
        sm: 6,
        md: 6,
        lg: 6,
      },
      isActive:
        t(type?.label) === `${t(resourceTypes?.users)}` ||
        t(type?.label) === `${t(resourceTypes?.account)}`,
      component: "text",
      label: t("Daily Rate"),
      placeholder: t("Enter Daily Rate"),
      value: data?.daily_rate,
      onChange: (value) => updateState("daily_rate", value.target.value),
      error: data?.error?.daily_rate,
      isRequired: true,
      type: "number",
      endAdornment: company?.currency_symbol,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      isActive:
        t(type?.label) === `${t(resourceTypes?.users)}` ||
        t(type?.label) === `${t(resourceTypes?.account)}`,
      component: "user",
      label: t("Reporting To"),
      placeholder: t("Select Reporting To"),
      value: data?.reporting_to,
      onChange: (value) => updateState("reporting_to", value),
      error: data?.error?.reporting_to,
      isRequired: true,
      company_id: company?.value,
    },
    {
      size: {
        xs: 4,
        sm: 4,
        md: 4,
        lg: 4,
      },
      isActive: t(type?.label) === `${t(resourceTypes?.vehicle)}`,
      component: "select",
      label: t("Vehicle"),
      placeholder: t("Select Vehicle"),
      value: data?.vehicles,
      type: "number",
      onChange: (value) => {
        setData({
          ...data,
          vehicles: value,
          rate_hour: value?.rate,
          period: {
            label: value?.period,
            value: value?.period,
          },
        });
      },
      error: data?.error?.vehicles,
      isRequired: true,
      loadOptions: (search, array, handleLoading) =>
        loadOptionsApis(
          "queries/vehicle_master/get",
          {
            company_id: company?.value,
          },
          search,
          array,
          handleLoading,
          "vehicle_master",
          {}
        ),
      debounceTimeout: 800,
      isPaginate: true,
      menuPlacement: "bottom",
    },
    {
      size: {
        xs: 4,
        sm: 4,
        md: 4,
        lg: 4,
      },
      isActive:
        t(type?.label) === `${t(resourceTypes?.vehicle)}` ||
        t(type?.label) === `${t(resourceTypes?.tools)}`,
      component: "text",
      label:
        type?.label === `${resourceTypes?.vehicle}`
          ? t("Vehicle Number")
          : t("Serial Number"),
      placeholder:
        type?.label === `${resourceTypes?.vehicle}`
          ? t("Enter Vehicle Number")
          : t("Enter Serial Number"),
      value: data?.vehicles_no,
      onChange: (value) => updateState("vehicles_no", value.target.value),
      error: data?.error?.vehicles_no,
      isRequired: true,
    },
    {
      size: {
        xs: 4,
        sm: 4,
        md: 4,
        lg: 4,
      },
      isActive:
        t(type?.label) === `${t(resourceTypes?.vehicle)}` ||
        t(type?.label) === `${t(resourceTypes?.tools)}`,
      component: "text",
      label: t("Rate"),
      placeholder: t("Enter Rate"),
      value: data?.rate_hour,
      onChange: (value) => updateState("rate_hour", value.target.value),
      isRequired: true,
      error: data?.error?.rate_hour,
      endAdornment: company?.currency_symbol,
    },
    {
      size: {
        xs: 4,
        sm: 4,
        md: 4,
        lg: 4,
      },
      isActive: t(type?.label) === `${t(resourceTypes?.generic)}`,
      component: "text",
      label: `${resourceTypes?.generic}`,
      placeholder: t("Enter Generic"),
      value: data?.name,
      onChange: (value) => updateState("name", value.target.value),
      error: data?.error?.name,
      isRequired: true,
    },
    {
      size: {
        xs: 4,
        sm: 4,
        md: 4,
        lg: 4,
      },
      isActive:
        t(type?.label) === `${t(resourceTypes?.vehicle)}` ||
        t(type?.label) === `${t(resourceTypes?.tools)}`,
      component: "select",
      label: t("Period"),
      placeholder: t("Select Period"),
      value: data?.period,
      onChange: (value) => updateState("period", value),
      isRequired: true,
      error: data?.error?.period,
      options: period,
      menuPlacement: "bottom",
    },
  ];
  //more
  const fetchMoreData = () => {
    setOffset(offset + 10, search);
    getList(type?.label, offset + 10, "", false);
  };
  //handleSearch
  const handleSearch = (val) => {
    setSearch(val);
    getList(type?.label, 0, val, true);
  };
  const validateForm = () => {
    let isValid = true;
    let error = data.error;

    if (type?.value?.length === 0) {
      isValid = false;
      error.type = t("Type is Required");
    }
    if (!data?.functional_location || data?.functional_location?.length === 0) {
      isValid = false;
      error.functional_location = t("Functional Location is Required");
    }
    //account and users
    if (
      type?.label === `${resourceTypes?.account}` ||
      type?.label === `${resourceTypes?.users}`
    ) {
      if (data?.working_hour?.length === 0) {
        isValid = false;
        error.working_hour = t("Working Calender is Required");
      }
      if (data?.rate_hour?.length === 0) {
        isValid = false;
        error.rate_hour = t("Hours Rate is Required");
      }
      if (data?.daily_rate?.length === 0) {
        isValid = false;
        error.daily_rate = t("Daily Rate is Required");
      }
      if (data?.reporting_to?.length === 0) {
        isValid = false;
        error.reporting_to = t("Reporting To is Required");
      }
      if (data?.module?.length === 0) {
        isValid = false;
        error.module = t("Module is Required");
      }
    }
    if (
      type?.label === `${resourceTypes?.tools}` ||
      type?.label === `${resourceTypes?.vehicle}`
    ) {
      if (data?.rate_hour?.length === 0) {
        isValid = false;
        error.rate_hour = t("Rate is Required");
      }
      if (data?.period?.length === 0) {
        isValid = false;
        error.period = t("Period is Required");
      }
    }
    if (type?.label === `${resourceTypes?.account}`) {
      if (data?.account?.length === 0) {
        isValid = false;
        error.account = t("Account is Required");
      }
    }
    if (type?.label !== `${resourceTypes?.users}`) {
      if (data?.department?.length === 0) {
        isValid = false;
        error.department = t("Department is Required");
      }
    }
    if (type?.label === `${resourceTypes?.users}`) {
      if (data?.user?.length === 0) {
        isValid = false;
        error.user = t("User is Required");
      }
      if (data?.job?.length === 0) {
        isValid = false;
        error.job = t("Jobs is Required");
      }
      if (data?.profession?.length === 0) {
        isValid = false;
        error.profession = t("Profession is Required");
      }
      if (data?.leave?.length > 0) {
        if (!isEmptyObject(data?.leave)) {
          isValid = false;
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Please Fill All Fields of  Leave"),
          });
        }
      }
    }
    if (type?.label === `${resourceTypes?.tools}`) {
      if (data?.tool?.length === 0) {
        isValid = false;
        error.tool = t("Tools is Required");
      }
      if (data?.vehicles_no?.length === 0) {
        isValid = false;
        error.vehicles_no = t("Serial Number is Required");
      }
    }
    if (type?.label === `${resourceTypes?.vehicle}`) {
      if (data?.vehicles?.length === 0) {
        isValid = false;
        error.vehicles = t("Vehicles is Required");
      }
      if (data?.vehicles_no?.length === 0) {
        isValid = false;
        error.vehicles_no = t("Vehicles Number is Required");
      }
    }
    if (type?.label === `${resourceTypes?.generic}`) {
      if (data?.name?.length === 0) {
        isValid = false;
        error.name = t("Generic Name is Required");
      }
    }
    // if (assets?.length === 0) {
    //     isValid = false;
    //     error.assets = "Assets is Required";
    // }

    setData({ ...data, error });
    return isValid;
  };
  //onsubmit
  const onSubmit = () => {
    // const payload = returnSavePayload(data, type?.label, company, assets, type)

    if (validateForm()) {
      setIsDisableBtn(true);
      const payload = returnSavePayload(
        data,
        type?.label,
        company,
        assets,
        type
      );
      payload["function_location_id"] = data?.functional_location?.value;

      NetworkCall(
        `${config.api_url}/resources/upsert`,
        NetWorkCallMethods.post,
        { ...payload, size_type: "resource_size" },
        null,
        true,
        false
      )
        .then((res) => {
          reload();
          setIsDisableBtn(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `${data?.id ? t("Resource Updated") : t("Resource Created")}`,
          });
          setData({ ...initialState });
          onClose();
        })
        .catch((err) => {
          setIsDisableBtn(false);
          if (err?.response?.status === 406)
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: t(
                "Your  Resource  limit has been hit! Please upgrade your plan!"
              ),
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          else {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg:
                err?.response?.data?.error?.message ??
                t("Some Thing Went Wrong"),
            });
          }
        });
    }
  };

  const selectUser = (key, user_data) => {
    if (
      type?.label === resourceTypes?.users &&
      user_data?.image_url?.length > 0
    ) {
      let ext = user_data?.image_url?.split(".")?.reverse()?.[0];
      setAssets([
        {
          url: user_data?.image_url,
          is_active: true,
          file_meta: {
            name: `${user_data?.name}.${ext}`,
            size: null,
            type: ext,
          },
        },
      ]);
      setData({
        ...data,
        [key]: user_data?.id,
        is_exisit: true,
      });
    } else {
      updateState(key, user_data?.id);
    }
  };

  return (
    <Box className={`${classes.resources}`}>
      {loading ? (
        <LoadingSection message="Fetching Details..." top="10vh" />
      ) : (
        <Box className={classes.formRoot}>
          <Grid container>
            <Grid
              item
              xs={
                t(type?.label) === `${t(resourceTypes?.users)}` ||
                t(type?.label) === `${t(resourceTypes?.account)}`
                  ? 6
                  : 12
              }
              p={2}
              className={
                (t(type?.label) === `${t(resourceTypes?.users)}` ||
                  t(type?.label) === `${t(resourceTypes?.account)}`) &&
                classes.fromSection
              }
            >
              {/* Add tool */}
              <FormGenerator t={t} components={toolForm} />
              {(t(type?.label) === `${t(resourceTypes?.users)}` ||
                t(type?.label) === `${t(resourceTypes?.account)}`) && (
                <Box>
                  {/* {type?.label === t(`${resourceTypes?.users}`) && <LeaveComponent file={data?.leave} update={(val) => updateState("leave", val)} />} */}

                  <UploadComponent
                    readOnly={
                      t(type?.label) === `${t(resourceTypes?.users)}` &&
                      data?.is_exisit
                    }
                    handleChange={(val) => updateState("assets", val)}
                    logo_title={t("IMAGES")}
                    errorMessage={data?.error?.assets}
                    isError={data?.error?.assets?.length > 0}
                    assets={assets}
                    setAssets={setAssets}
                    xs={matches ? 6 : 4}

                    //isrequired
                  />
                </Box>
              )}
            </Grid>
            {(t(type?.label) === `${t(resourceTypes?.users)}` ||
              t(type?.label) === `${t(resourceTypes?.account)}`) && (
              <Grid item xs={6} p={2}>
                {(t(type?.label) === `${t(resourceTypes?.users)}` ||
                  t(type?.label) === `${t(resourceTypes?.account)}`) && (
                  <Box>
                    <Box p={2} marginTop="-12px">
                      <TextBox
                        placeholder={`${t("Search")} ${t(type?.label)} ${t(
                          "Name"
                        )}`}
                        label={`${t("Search")} ${t(type?.label)} ${t("Name")}`}
                        value={search}
                        isrequired
                        isError={
                          data?.error?.account?.length > 0 ||
                          data?.error?.user?.length > 0
                        }
                        errorMessage={data?.error?.account || data?.error?.user}
                        onChange={(val) => handleSearch(val.target.value)}
                      />
                    </Box>
                    <InfiniteScroll
                      dataLength={list?.length ?? ""}
                      next={fetchMoreData}
                      hasMore={true}
                      height={350}
                    >
                      <Box p={1}>
                        {list?.map((val) => {
                          return (
                            <Box p={1}>
                              <UserCard
                                list={val}
                                value={
                                  type?.label === `${resourceTypes?.users}`
                                    ? data?.user
                                    : data?.account
                                }
                                clickCheck={(e) =>
                                  selectUser(
                                    type?.label === `${resourceTypes?.users}`
                                      ? "user"
                                      : "account",
                                    val
                                  )
                                }
                              />
                            </Box>
                          );
                        })}
                      </Box>
                    </InfiniteScroll>
                  </Box>
                )}
              </Grid>
            )}

            {(t(type?.label) === `${t(resourceTypes?.tools)}` ||
              t(type?.label) === `${t(resourceTypes?.generic)}` ||
              t(type?.label) === `${t(resourceTypes?.vehicle)}`) && (
              <Grid item xs={12} p={2} marginTop="-16px">
                <UploadComponent
                  handleChange={(val) => updateState("assets", val)}
                  logo_title={t("IMAGES")}
                  errorMessage={data?.error?.assets}
                  isError={data?.error?.assets?.length > 0}
                  assets={assets}
                  setAssets={setAssets}
                  xs={matches ? 3 : 2}

                  //isrequired
                />
              </Grid>
            )}
          </Grid>
        </Box>
      )}
      {/* buttons */}
      <Box display="flex" className={`${classes.btngrp}`} p={1}>
        <Button
          variant="outlined"
          className={`${classes.btn_outlined}`}
          onClick={onClose}
        >
          {t("Cancel")}
        </Button>
        <Button
          variant="contained"
          className={`${classes.btn_contained}`}
          onClick={onSubmit}
          disabled={isDisableBtn}
        >
          {data?.isEdit === true ? t("Update") : t("Create")}
        </Button>
      </Box>
    </Box>
  );
};
export default Resources;
