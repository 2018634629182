import { Avatar, Box, Grid, Typography } from "@mui/material";
import styled from '@mui/material/styles/styled';
import React from 'react';
import "../../../App.css";
import { AlertDialog, MapWithFields, MobileNumberInputComponent, SelectBox, TextBox } from "../../../components";
import { CustomSelect } from '../../../components/filterGenerator/components/select';
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, FontFamilySwitch, enum_types, enumSelect, NetWorkCallMethods } from "../../../utils";
import { useStylesCreation } from "../style";
const CustomTypography = styled(Typography)(({ theme }) => ({
      fontSize:"0.75rem",
      fontFamily: FontFamilySwitch().bold,
      color: theme.typography.color.secondary,
      marginBottom: theme.spacing(1)
}))

const textBoxStyle = {
      padding: "8px 14px",
      borderRadius: 8,
      height: 47
}

export const GeneralDetails = (props) => {
      const { mapLoad } = props;
      const [fullScreenMap, setFullScreenMap] = React.useState(false)
      const classes = useStylesCreation();
      const alert = React.useContext(AlertContext);
      const [loading, setLoading] = React.useState(false)
      const [imageViwer, setImageViwer] = React.useState(false)
      const [enumValue, setEnumValue] = React.useState({
            replationShip: [],
            method: [],
            emergencyContactRelationship: [],
      })
      const loadOptions = async (search = "", array, type) => {
            setLoading(type);
            let result, query, offset = 0;

            if (search && !Boolean(array?.length)) {
                  offset = 0;
            }
            else {
                  offset = array?.length;
            }

            switch (type) {
                  case 'properties':
                        if (!props?.data?.company?.value > 0) {
                              setLoading(null);
                              return { options: [] }
                        }
                        query = {
                              url: "/queries/property/dropdown",
                              payload: {
                                    offset, limit: 10, search, company_id: props?.data?.company?.value
                              }
                        }
                        result = await networkCallBack(query);
                        return {
                              options: [...result?.property],
                              hasMore: (array?.length + result?.property?.length) < result?.count
                        }
                  case 'job':
                        query = {
                              url: "/queries/roles/get",
                              payload: {
                                    offset, limit: 10, search
                              }
                        }
                        result = await networkCallBack(query);
                        return {
                              options: [...result?.roles],
                              hasMore: (array?.length + result?.roles?.length) < result?.count
                        }
                  default:
                        return { options: [] }
            }
      }
      const networkCallBack = async (query, variable = {}) => {

            let payload = query.payload

            const options = await NetworkCall(
                  `${config.api_url}${query.url}`,
                  NetWorkCallMethods.post,
                  payload,
                  null,
                  true,
                  false
            )
                  .then((response) => {
                        let main = response.data.data;
                        setLoading(null);
                        return main
                  })
                  .catch(() => {
                        setLoading(null);
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: "Some Thing Went Wrong",
                              vertical: AlertProps.vertical.top,
                              horizontal: AlertProps.horizontal.center,
                        });
                        return null
                  });

            return options
      }
      const getEnum = async () => {
            const result = await enumSelect([
                  enum_types.relationship_type, enum_types.preferred_mode_contact_type,
                  enum_types.emergency_relationship_type
            ])
            setEnumValue({
                  replationShip: result?.relationship_type,
                  method: result?.preferred_mode_contact_type,
                  emergencyContactRelationship: result?.emergency_relationship_type
            })
      }
      React.useEffect(() => {
            getEnum()
      }, [])
      React.useEffect(() => {
            document.addEventListener("fullscreenchange", existFullscreen)
            return () => {
                  document.removeEventListener("fullscreenchange", existFullscreen)

            }

      }, [])
      //exist full screen
      const existFullscreen = () => {
            if (document.fullscreenElement === null) {
                  setFullScreenMap(false)
            }

      }
      //open full screen
      const fullScreenControl = () => {
            setFullScreenMap(true)
            document.getElementsByClassName('map-wrapper')[0]?.requestFullscreen()

      }
      return (
            <div>
                  <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={2}>
                              <Box className={classes.imagebox}>
                                    <CustomTypography>
                                          PROFILE PICTURE
                                    </CustomTypography>
                                    <Avatar mb={2} src={props?.data?.profileImage?.length > 0 ? props?.data?.profileImage : "/images/citynew.svg"}
                                          className={props?.data?.profileImage?.length > 0 ? classes.avatar : classes.avatar1} onClick={() => setImageViwer(true)} />
                                    <div>
                                          <label> <Box className={classes.button} variant='contained'>    Upload image</Box> <input type='file' className={classes.inputFileStyle} accept="image/*"
                                                onClick={(event) => {
                                                      event.target.value = null;

                                                }}
                                                onChange={(e) => props?.updateimg(e?.target?.files?.[0])} /> </label>
                                    </div>
                                    {props?.data?.profileImage?.length > 0 ? <Typography className={classes.removeimg} onClick={props?.removeimg}>Remove Image</Typography> : props?.data?.error?.profileImage?.length > 0 ? (
                                          <span className={classes.profileImageErrorTextStyle}>
                                                Profile Image is required
                                          </span>
                                    ) : <Box height={"18px"} />}

                              </Box>
                        </Grid>
                        <Grid item xs={12} md={8} lg={10}>
                              <Box className={classes.profileDetailCardStyle}>
                                    <CustomTypography>
                                          Profile Details
                                    </CustomTypography>
                                    <Grid container spacing={3}>
                                          <Grid item xs={4}>
                                                <TextBox
                                                      {...textBoxStyle}
                                                      isReadonly
                                                      label="Company Name"
                                                      value={props?.data?.company?.label ?? ""} />
                                          </Grid>
                                          <Grid item xs={4}>
                                                <TextBox
                                                      {...textBoxStyle}
                                                      isrequired
                                                      label="Name"
                                                      placeholder="Enter Name"
                                                      value={props?.data?.name ?? ""}
                                                      onChange={(e) => {
                                                            props?.updateState("name", e.target.value);
                                                      }}
                                                      isError={props?.data?.error?.name?.length > 0}
                                                      errorMessage={props?.data?.error?.name} />
                                          </Grid>
                                          <Grid item xs={4}>
                                                <TextBox
                                                      {...textBoxStyle}
                                                      label="Job Title"
                                                      placeholder="Enter Job Title"
                                                      value={props?.data?.jobTitle ?? ""}
                                                      onChange={(e) => {
                                                            props?.updateState("jobTitle", e.target.value);
                                                      }} />
                                          </Grid>
                                          <Grid item xs={4}>
                                                <TextBox
                                                      {...textBoxStyle}
                                                      isReadonly
                                                      label="Company ID"
                                                      value={props?.data?.company?.company_no ?? ""} />
                                          </Grid>
                                          {props?.data?.relationship?.value === "Company Employee" &&
                                                <Grid item xs={8}>
                                                      <CustomSelect
                                                            marginBottom={4}
                                                            color={'#98A0AC'}
                                                            fontSize={12}
                                                            required
                                                            fontWeight={400}
                                                            isMulti
                                                            label=" Properties"
                                                            placeholder="Select Properties"
                                                            loading={loading === "properties"}
                                                            isPaginate
                                                            debounceTimeout={800}
                                                            loadOptions={(search, array) => loadOptions(search, array, 'properties')}
                                                            value={props?.data?.properties ?? ""}
                                                            onChange={(value) => {
                                                                  props?.updateState("properties", value);
                                                            }}
                                                            isDeletedValue
                                                            deletedValue={(value, updateval) => props?.deletedValue(value, updateval)}
                                                      />
                                                      {props?.data?.error?.properties?.length > 0 && (
                                                            <Typography variant={"caption"} color={"error"}>
                                                                  {props?.data?.error?.properties}
                                                            </Typography>
                                                      )}
                                                </Grid>}
                                    </Grid>
                              </Box>
                        </Grid>
                  </Grid>
                  <div className='map-wrapper'>
                        <Box className={classes.addressDetailCardStyle} >
                              <CustomTypography>Address</CustomTypography>
                              {
                                    props?.load && <MapWithFields
                                          fullScreenMap={fullScreenMap}
                                          fullScreenControl={fullScreenControl}
                                          mapOptions={{
                                                isInput: true,
                                                center: {
                                                      lat: props?.data?.latitude,
                                                      lng: props?.data?.longitude
                                                },
                                                lat: props?.data?.latitude,
                                                lng: props?.data?.longitude,
                                                mapLoad
                                          }}
                                          mapResult={props?.mapResult}
                                          autoCompletePlacement={{
                                                top: 14,
                                          }}
                                          fields={[
                                                {
                                                      label: "Door Number",
                                                      component: "TextField",
                                                      value: props?.data?.doorNo,
                                                      state_name: 'doorNo',
                                                      isrequired: false,
                                                      placeholder: "Enter Door Number",
                                                      breakpoints: { sm: 6, md: 2 }
                                                },
                                                {
                                                      label: "Address Line 1",
                                                      component: "TextField",
                                                      value: props?.data?.addressLineOne,
                                                      state_name: 'addressLineOne',
                                                      isrequired: false,
                                                      placeholder: "Enter Address Line 1",
                                                      breakpoints: { sm: 6, md: 5 }
                                                },
                                                {
                                                      label: "Address Line 2",
                                                      component: "TextField",
                                                      value: props?.data?.addressLineTwo,
                                                      state_name: 'addressLineTwo',
                                                      isrequired: false,
                                                      placeholder: "Enter Address Line 2",
                                                      breakpoints: { sm: 6, md: 5 }
                                                },
                                                {
                                                      label: "Landmark",
                                                      component: "TextField",
                                                      value: props?.data?.landmark,
                                                      state_name: 'landmark',
                                                      isrequired: false,
                                                      placeholder: "Enter Landmark",
                                                      breakpoints: { sm: 6, md: 4 }
                                                },
                                                {
                                                      label: "Area",
                                                      component: "TextField",
                                                      value: props?.data?.area,
                                                      state_name: 'area',
                                                      isrequired: false,
                                                      placeholder: "Enter Area",
                                                      breakpoints: { sm: 6, md: 4 }
                                                },
                                                {
                                                      label: "City",
                                                      component: "TextField",
                                                      value: props?.data?.city,
                                                      state_name: 'city',
                                                      isrequired: false,
                                                      placeholder: "Enter City",
                                                      breakpoints: { sm: 6, md: 4 }
                                                },
                                                {
                                                      label: "State",
                                                      component: "TextField",
                                                      state_name: 'state',
                                                      value: props?.data?.state,
                                                      isrequired: false,
                                                      placeholder: "Enter State",
                                                      breakpoints: { sm: 6, md: 4 }
                                                },
                                                {
                                                      label: "Country",
                                                      component: "TextField",
                                                      value: props?.data?.country,
                                                      state_name: 'country',
                                                      isrequired: false,
                                                      placeholder: "Enter Country",
                                                      breakpoints: { sm: 6, md: 4 }
                                                },
                                                {
                                                      label: "Pincode",
                                                      component: "TextField",
                                                      state_name: 'zipcode',
                                                      value: props?.data?.zipcode,
                                                      isrequired: false,
                                                      placeholder: "Enter Pincode",
                                                      breakpoints: { sm: 6, md: 4 }
                                                },
                                          ]}
                                          onChangeFields={(key, value) => props?.updateState(key, value)}
                                    />
                              }

                        </Box>
                  </div>
                  <Box className={classes.contactAndOtherDetailCardStyle} >
                        <CustomTypography>Contact & Other Information</CustomTypography>
                        <Grid container spacing={3}>
                              <Grid item xs={12} md={4} lg={4}>
                                    <SelectBox
                                          label="Preferred Method Of Contact"
                                          placeholder="Select Method"
                                          options={enumValue?.method}
                                          value={props?.data?.preferredModeContact ?? ""}
                                          onChange={(value) => {
                                                props?.updateState("preferredModeContact", value);
                                          }} />
                              </Grid>
                              <Grid item xs={12} md={4} lg={4}>
                                    <MobileNumberInputComponent
                                          isRequired
                                          label={"Primary Telephone"}
                                          placeholder={"Enter Telephone"}
                                          value={props?.data?.primaryTelephone}
                                          handleChange={(value) => {
                                                props?.updateState("primaryTelephone", value);
                                          }}
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                          isError={props?.data?.error?.primaryTelephone?.length > 0}
                                          errorMessage={props?.data?.error?.primaryTelephone} />
                              </Grid>
                              <Grid item xs={12} md={4} lg={4}>
                                    <MobileNumberInputComponent
                                          isRequired
                                          label={"Primary Mobile"}
                                          placeholder={"Enter Mobile"}
                                          value={props?.data?.primaryMobile}
                                          handleChange={(value) => {
                                                props?.updateState("primaryMobile", value);
                                          }}
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }}
                                          isError={props?.data?.error?.primaryMobile?.length > 0}
                                          errorMessage={props?.data?.error?.primaryMobile} />
                              </Grid>
                              <Grid item xs={12} md={4} lg={4}>
                                    <TextBox
                                          {...textBoxStyle}
                                          label="Primary Email Address"
                                          isrequired
                                          placeholder="Enter Email Address"
                                          value={props?.data?.primaryEmail ?? ""}
                                          onChange={(value) => {
                                                props?.updateState("primaryEmail", value?.target?.value);
                                          }}
                                          isError={props?.data?.error?.primaryEmail?.length > 0}
                                          errorMessage={props?.data?.error?.primaryEmail} />
                              </Grid>
                              <Grid item xs={12} md={4} lg={4}>
                                    <TextBox
                                          {...textBoxStyle}
                                          label="Emergency Contact Name"
                                          placeholder="Enter Name"
                                          value={props?.data?.emergencyContactName ?? ""}
                                          onChange={(value) => {
                                                props?.updateState("emergencyContactName", value?.target?.value);
                                          }} />
                              </Grid>
                              <Grid item xs={12} md={4} lg={4}>
                                    {/* <TextBox
                                          {...textBoxStyle}
                                          label="Emergency Contact Relationship"
                                          placeholder="Enter Relationship"
                                          value={props?.data?.emergencyContactRelationship ?? ""}
                                          onChange={(value) => {
                                                props?.updateState("emergencyContactRelationship", value?.target?.value);
                                          }} /> */}
                                    <SelectBox
                                          label="Emergency Contact Relationship"
                                          placeholder="Select Relationship"
                                          options={enumValue?.emergencyContactRelationship}
                                          value={props?.data?.emergencyContactRelationship ?? ""}
                                          onChange={(value) => {
                                                props?.updateState("emergencyContactRelationship", value);
                                          }} />
                              </Grid>
                              <Grid item xs={12} md={4} lg={4}>
                                    <MobileNumberInputComponent
                                          label={"Emergency Contact Number"}
                                          placeholder={"Enter Number"}
                                          value={props?.data?.emergencyContactNumber}
                                          handleChange={(value) => {
                                                props?.updateState("emergencyContactNumber", value);
                                          }}
                                          onKeyPress={(e) => {
                                                if (e.key === 'e') {
                                                      e.preventDefault();
                                                }
                                          }} />
                              </Grid>
                        </Grid>
                  </Box>
                  <Box className={classes.socialProfileDetailCardStyle} >
                        <CustomTypography>Social Profile</CustomTypography>
                        <Grid container spacing={3}>
                              <Grid item xs={12} md={4} lg={4}>
                                    <TextBox
                                          label="Facebook"
                                          placeholder="Enter Facebook"
                                          value={props?.data?.facebook ?? ""}
                                          onChange={(e) => {
                                                props?.updateState("facebook", e.target.value);
                                          }} />
                              </Grid>
                              <Grid item xs={12} md={4} lg={4}>
                                    <TextBox
                                          {...textBoxStyle}
                                          label="Twitter"
                                          placeholder="Enter Twitter"
                                          value={props?.data?.twitter ?? ""}
                                          onChange={(e) => {
                                                props?.updateState("twitter", e.target.value);
                                          }} />
                              </Grid>
                              <Grid item xs={12} md={4} lg={4}>
                                    <TextBox
                                          {...textBoxStyle}
                                          label="Linkedin"
                                          placeholder="Enter Linkedin"
                                          value={props?.data?.linkedin ?? ""}
                                          onChange={(e) => {
                                                props?.updateState("linkedin", e.target.value);
                                          }} />
                              </Grid>
                        </Grid>
                  </Box>
                  <AlertDialog isNormal isnotTitle component={
                        <div className={classes.imageViewerWrapperStyle}>
                              <img src={props?.data?.profileImage} alt='' className={classes.imageViewerImageStyle} />
                        </div>
                  } open={imageViwer} onClose={() => setImageViwer(false)} />
            </div>
      )
}
