
import { Box } from "@mui/material";
import React from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { LoadingSection, Subheader } from "../../components";
import { AlertContext, AuthContext } from "../../contexts";
import { Routes } from "../../router/routes";
import { AlertProps, NetWorkCallMethods, accessCheckRender, getRoutePermissionNew } from "../../utils";
import { Address, BankingDetails, KYCDetails, Profile } from "./sections";
import { useStyles } from "./styles";
import { withTranslation } from "react-i18next";
import { RegisteredDetails } from "./sections/registeredDetails";
import { IdDetails } from "./sections/idDetails";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { useState } from "react";

const AccountDetails = ({ accid = null, is_edit = true, t, reltype = null}) => {
    const classes = useStyles()
    const alert = React.useContext(AlertContext)
    // const searchURL = useLocation().search;
    // const acc_id = new URLSearchParams(searchURL).get("id");
    const [data, setData] = React.useState({})
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate()
    const auth = React.useContext(AuthContext)
    const [permission, setPermission] = React.useState({})
    const { state } = useLocation()
    const [isConfigured, setIsConfigured] = React.useState({})
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getDetails()
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const getDetails = () => {

        const payload = {
            id: accid === null ? state?.id : accid,
            company_id: state?.company_id
        }
        NetworkCall(
            `${config.api_url}/account/get_detail`,
            NetWorkCallMethods.post,
            payload,
            true,
            true,
            false
        ).then((res) => {
            setData(res?.data?.data?.[0])
            setIsConfigured({is_zoho_configured: res?.data?.data?.is_zoho_configured, is_account_synced: res?.data?.data?.is_account_synced, is_resync: res?.data?.data?.[0]?.is_resync}) 
            setLoading(false)
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something Went Wrong"),
            });
            setLoading(false)
        })
    }

    const typeSelect = {
        "Vendor": Routes.vendorAccountCreate,
        "Property Owner": Routes.ownerAccountCreate,
        "Customer": Routes.customerAccountCreate,
        "AllAccount": Routes.createAccount,
        "cashBank": Routes.cashBankAccountCreate
    }



    const handleEdit = () => {
        // state?.type === "Vendor" ?
        // navigate(Routes.createAccount + "?id=" + state?.id)
        const path = state?.type?.length > 0 ? typeSelect[state?.type] : Routes.createAccount
        navigate(path,
            {
                state: { type: state?.type, id: state?.id, viewtype: "edit" }
            }
        )
    }

    const handleSync = () => {
        setIsButtonDisabled(true);
        const payload = {
            id: data?.id,
            company_id: data?.company_id,
            is_resync: isConfigured?.is_resync
          };
          NetworkCall(
            `${config.api_url}/integration_api/sync_account`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
          )
          .then((response) => {
            if (response?.data?.data?.status === 400) {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: response?.data?.data?.data?.error,
                });
                setIsButtonDisabled(false);
            } 
            else if (response?.data?.data?.type === "error") { 
                setIsButtonDisabled(false);
            } else {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Synced Successfully"),
                });
                getDetails();
                setIsButtonDisabled(false);
            }
        })
        .catch((err) => {
            setIsButtonDisabled(false);
            console.error(err);
        });
        
    }
    const render = () => {
        return <>
            <Subheader
                title={data?.company?.company_name}
                goBack={() => navigate(-1)}
                isEdit={is_edit ? permission.update : false}
                isConfigured = {isConfigured}
                handleSync={handleSync}
                isButtonDisabled={isButtonDisabled}
                handleEdit={handleEdit}
                t={t}
            />
            {
                loading ?
                    <LoadingSection bottom={"45vh"} message="Fetching ..." />
                    :
                    <Box className={classes.root}>

                        {/*profile section */}
                        <Profile t={t} data={data} relType={reltype} />
                        {/* Registered Details */}
                        {reltype !== "cashBank" && <RegisteredDetails t={t} data={data} />}
                        {/*address section */}
                        <Address t={t} data={data} />
                        {/*BankingDetails section */}
                        <BankingDetails t={t} data={data} />
                        {/*KYCDetails*/}
                        <KYCDetails t={t} data={data} />
                        {/* ID Details */}
                        {reltype !== "cashBank" && <IdDetails t={t} data={data} />}
                    </Box>
            }
        </>
    }
    return (
        <Box>
            {accessCheckRender(render, permission)}
        </Box>
    )
}
export default withTranslation("accountDetails")(AccountDetails)
