import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material"
import moment from "moment"
import React from "react"
import { TextBox } from "../../../components"
import PrioritySelect from "../../../components/prioritySelect/prioritySelect"
import { config } from "../../../config"
import { AlertContext } from "../../../contexts"
import { NetworkCall } from "../../../networkcall"
import { AlertProps, NetWorkCallMethods, enumSelect, enum_types, useWindowDimensions } from "../../../utils"
import { InventoryDetails, ProcurementDetails, ProgressDetails } from "./progressDetails"
import { useStyles } from "./style"

export const ServiceLevelAgreement = ({ state = {}, val = {}, t = () => false, reload = () => false }) => {
    const classes = useStyles()
    const size = useWindowDimensions()
    const [data, setData] = React.useState({
        actual_date: val?.actual_close_date ? new Date(val?.actual_close_date) : "",
        planned_date: val?.planned_close_date ? new Date(val?.planned_close_date) : ""
    })
    const alert = React.useContext(AlertContext);
    const updateState = (key, value, key1, value1) => {
        setData({ ...data, [key]: value, [key1]: value1 })
    }
    const [options, setOptions] = React.useState({
        urgent_type: []
    })
    const getEnum = async () => {
        const result = await enumSelect([enum_types.work_order_status, enum_types.urgent_type, enum_types.payment_mode, enum_types.addressing_type]);
        setOptions({
            urgent_type: result?.urgent_type,
            addressing_type: result?.addressing_type
        });
    };
    const RequestDetails = [
        {
            label: state?.main?.request === "Maintenance" ? t("Maintenance Request") : t("General Request"),
            value: state?.main?.request === "Maintenance" ? val?.maintenance_request_no : val?.general_request_no
        },
        {
            label: t("Channel Source"),
            value: val?.build_source
        },
        {
            label: t("Status"),
            value: val?.status
        },
        {
            label: t("Property Name"),
            value: val?.property_name ?? "-"
        },
        {
            label: t("Unit Name"),
            value: val?.unit_name ?? "-"
        },
        {
            label: t("Asset ID"),
            value: val?.inspection_mapping?.[0]?.item?.reference_no
        },
        {
            label: t("Ticket Handling"),
            value: val?.type
        },
        {
            label: t("Warranty End Date"),
            value: val?.warranty_date !== null ? moment(val?.warranty_date).format("DD MMM YYYY") : "-"
        },
        {
            label: t("Approval to start on"),
            value: val?.approval_date !== null ? moment(val?.approval_date).format("DD MMM YYYY") : "-"
        },
        {
            label: t("BOM Approval on"),
            value: val?.bom_approved_date !== null ? moment(val?.bom_approved_date).format("DD MMM YYYY") : "-"
        },
    ]
    const ImpactDetails = [
        {
            label: t("Priority"),
            value: { label: data?.priority?.label, value: data?.priority?.value },
            component: state?.main?.request === "Maintenance" ? "select" : null,
            options: options?.urgent_type,
            state_name: "priority",
            // isReadOnly: true
        },
        {
            label: t("Urgency"),
            value: data?.urgency !== null ? { label: data?.urgency?.label ?? data?.urgency, value: data?.urgency?.value ?? data?.urgency } : "Select Urgency",
            component: "select",
            options: options?.urgent_type,
            state_name: "urgency",

        },
        {
            label: t("Impact"),
            value: data?.impact !== null ? { label: data?.impact?.label ?? data?.impact, value: data?.impact?.value ?? data?.impact } : "Select Impact",
            component: "select",
            options: options?.urgent_type,
            state_name: "impact"
        },
        {
            label: t("Impact Notes"),
            value: data?.impact_notes,
            component: "textBox"
        },
    ]
    React.useEffect(() => {
        getEnum()
        setData({
            ...val,
            priority:{label:val?.priority,value:val?.priority},
            impact:{label:val?.impact,value:val?.impact},
            urgency:{label:val?.urgency,value:val?.urgency},
            resource_group: val?.resource_group?.map((x) => {
                return {
                    ...x,
                    label: x?.name,
                    value: x?.resource_group_id,
                    is_active: true
                }
            }),
            project: val?.project !== null ? {
                ...val?.project,
                label: `${val?.project?.title} (${moment(val?.project?.planned_start_date).format("DD MMM YYYY")} - ${moment(val?.project?.planned_end_date).format("DD MMM YYYY")})`,
                value: val?.project?.id
            } : "",
            contract: val?.contract !== null ? {
                ...val?.contract,
                label: `${val?.contract?.contract_no} (${moment(val?.contract?.start_date).format("DD MMM YYYY")} - ${moment(val?.contract?.end_date).format("DD MMM YYYY")})`,
                value: val?.contract?.id
            } : "",
            billing_account: val?.billing_account?.account_no?.length > 0 ? {
                ...val?.billing_account,
                label: `${val?.billing_account?.account_no} - ${val?.billing_account?.name}`,
                value: val?.billing_account?.id
            } : "",
            impact_notes: val?.impact_notes !== null ? JSON.parse(val?.impact_notes) : "",
            addressing_type: val?.addressing_type !== null ? { label: val?.addressing_type, value: val?.addressing_type } : ""
        })
        //eslint-disable-next-line
    }, [])
    const onSubmit = (type, value) => {
        const payload = {
            id: val?.id,
            type: state?.main?.request === "Maintenance" ? "Maintenance" : "General",
            planned_close_date: data?.planned_close_date ? moment(data?.planned_close_date).format("YYYY-MM-DD") : undefined,
            actual_close_date: data?.actual_close_date ? moment(data?.actual_close_date).format("YYYY-MM-DD") : undefined,
            impact: data?.impact?.value,
            impact_notes: data?.impact_notes,
            planned_start_date: data?.planned_start_date ? moment(data?.planned_start_date).format("YYYY-MM-DD") : undefined,
            actual_start_date: data?.actual_start_date ? moment(data?.actual_start_date).format("YYYY-MM-DD") : undefined,
            project_id: data?.project?.id ?? null,
            contract_id: data?.contract?.value ?? null,
            account_id: data?.billing_account?.account_no ?? null,
            account_type: data?.account_type ?? null,
            resource_group: data?.resource_group?.map((x) => {
                return {
                    id: x?.id ?? undefined,
                    resource_group_master_id: x?.value,
                    is_active: x?.is_active
                }
            }),
            urgency: data?.urgency?.value,
            priority:data?.priority?.value,
            addressing_type: data?.addressing_type?.value
        }
        NetworkCall(
            `${config.api_url}/request/update_request_date`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            reload()
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Details Updated Successfully"),
            });
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: AlertProps.message.some_thing_went_wrong,
            });
        })
    }


    return (
        <Box>
            <Box position={"relative"}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4.5} lg={4.5}>
                        <Box p={1} sx={{ height: size?.height - 272, overflow: "scroll" }}>
                            <Box className={classes.serviceLevelCard}>
                                <Box p={1.5} className={classes.hdrBg}>
                                    <Typography className={classes.serviceLevelValues}>{t("Request Details")}</Typography>
                                </Box>
                                <Divider className={classes.dividerBGcolor}/>
                                <Box>
                                    {RequestDetails?.map((x, index) => {
                                        return (
                                            <>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={6}>
                                                        <Box p={1.5}>
                                                            <Typography className={classes.serviceLevelTitle}>{x?.label}</Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                                                        <Box p={1.5}>
                                                            <Typography className={classes.serviceLevelValues}>{x?.value ?? "-"}</Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                {RequestDetails?.length - 1 !== index &&
                                                    <Divider className={classes.dividerBGcolor}/>
                                                }
                                            </>
                                        )
                                    })}
                                </Box>

                            </Box>
                            <Box className={classes.serviceLevelCard} mt={1}>
                                <Box p={1.5} className={classes.hdrBg}>
                                    <Typography className={classes.serviceLevelValues}>{t("Impact Details")}</Typography>
                                </Box>
                                <Divider className={classes.dividerBGcolor}/>
                                <Box>
                                    {ImpactDetails?.map((x, index) => {
                                        return (
                                            <>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={6}>
                                                        <Box p={1.5}>
                                                            <Typography className={classes.serviceLevelTitle}>{x?.label}</Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6} sx={{ borderLeft: "1px solid #9ba9b3" }}>
                                                        <Box p={1.5}>
                                                            {x?.component === "select" ?
                                                                <PrioritySelect
                                                                    value={x?.value?.value}
                                                                    showLabel={false}
                                                                    onChange={(value) => updateState(x?.state_name, value)}
                                                                    placeholder={`${t("Select")} ${x?.label}`}
                                                                    // selectHeight={"40px"}
                                                                    menuPlacement={x?.state_name!=="impact"?"bottom":"top"}
                                                                    options={x?.options}
                                                                    isReadOnly={(val?.status === "Closed" || val?.status === "Cancelled" || val?.status === "Rejected")}
                                                                    menu_text_transfrom={"capitalize"}
                                                                    selectHeight={"auto"}
                                                                />
                                                                : x?.component === "textBox" ?
                                                                    <TextBox
                                                                        value={x?.value}
                                                                        label=""
                                                                        multiline
                                                                        isReadonly={(val?.status === "Closed" || val?.status === "Cancelled" || val?.status === "Rejected")}
                                                                        placeholder={`${t("Enter")} ${x?.label}`}
                                                                        onChange={(e) => updateState("impact_notes", e?.target?.value)}
                                                                    />
                                                                    :
                                                                    <Typography className={classes.serviceLevelValues}>{data?.value ?? "-"}</Typography>
                                                            }
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                {ImpactDetails?.length - 1 !== index &&
                                                    <Divider className={classes.dividerBGcolor}/>
                                                }
                                            </>
                                        )
                                    })}
                                </Box>

                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={7.5} lg={7.5}>
                        <Box p={1} sx={{ height: size?.height - 272, overflow: "scroll" }}>
                            <Box className={classes.serviceLevelCard}>
                                <Box p={1.3} display={"flex"} alignItems="center" className={classes.hdrBg}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography className={classes.title}>{`${t("Progress Details")} - Created On ${moment(val?.created_at).format("DD MMM YYYY")} |`}
                                        </Typography>
                                        <Typography className={classes.openTxt}>{`${(val?.status !== "Closed" && val?.status !== "Completed") ? `Open for ${val?.total_date_diff}` : ""} `}</Typography>
                                    </Stack>
                                </Box>
                                <Divider className={classes.dividerBGcolor}/>
                                <Box>
                                    <ProgressDetails state={state} t={t} val={val} data={data} setData={setData} updateState={updateState} companyId={state?.main?.company} addressing_type_options={options?.addressing_type} />
                                </Box>
                            </Box>
                            <Box mt={1} className={classes.serviceLevelCard}>
                                <Box p={1.5} className={classes.hdrBg} display={"flex"} justifyContent={"space-between"} alignItems="center">
                                    <Typography className={classes.serviceLevelValues}>{`${t("Procurement and Jobs")} `}</Typography>
                                </Box>
                                <Divider className={classes.dividerBGcolor}/>
                                <Box>
                                    <ProcurementDetails state={state} t={t} val={val} data={data} setData={setData} updateState={updateState} companyId={state?.main?.company} addressing_type_options={options?.addressing_type} />
                                </Box>
                            </Box>
                            <Box mt={1} className={classes.serviceLevelCard}>
                                <Box p={1.5} className={classes.hdrBg} display={"flex"} justifyContent={"space-between"} alignItems="center">
                                    <Typography className={classes.serviceLevelValues}>{`${t("Inventory and Billing")} `}</Typography>
                                </Box>
                                <Divider className={classes.dividerBGcolor}/>
                                <Box>
                                    <InventoryDetails state={state} t={t} val={val} data={data} setData={setData} updateState={updateState} companyId={state?.main?.company} addressing_type_options={options?.addressing_type} />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box display="flex" justifyContent={"end"} className={classes.fixedBottom}>
                <Button variant="contained" onClick={() => onSubmit()}>{t("Save")}</Button>
            </Box>
        </Box>
    )
}