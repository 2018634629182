import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
    margin: "16px",
  },
  btn: {
    borderRadius: "8px",
  },
  btn2: {
    borderRadius: "8px",
    color: theme.palette.secondary.main,
  },
  text: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },
  subText: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.secondary,
  },
  img: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: "4px",
    padding: "10px",
    height: "40px",
    width: "40px",
    position: "relative",
  },
  searchdrop: {
    borderRadius: "10px",
    border: "1px solid #E4E8EE",
    cursor: "pointer",
  },
  chip: {
    backgroundColor: "#F2F4F7",
    marginLeft: "6px",
    marginTop: "10px",
    borderRadius: "4px",
    fontSize: "0.875rem",
    cursor: "pointer",
    border: "none",
    boxShadow: "none",
    color: theme.typography.color.secondary,
    "&:hover": {
      border: "none",
      boxShadow: "none",
    },
    display: "flex",
    alignItems: "center",
  },
  Label: {
    color: theme.typography.color.secondary,
    fontSize: "0.875rem",
    marginTop: "6px",
  },
  UnitStatusactive: {
    padding: "10px 16px",
    textAlign: "center",
    borderRadius: "4px",
    color: "white",
    fontSize: "0.875rem",
    backgroundColor: "#5078E1",
    cursor: "pointer",
  },
  UnitStatus: {
    padding: "10px 16px",
    textAlign: "center",
    borderRadius: "4px",
    fontSize: "0.875rem",
    cursor: "pointer",
    backgroundColor: "#F2F4F7",
  },
  previousbtn: {
    backgroundColor: "white",
    color: "#091B29",
    border: "1px solid #CED3DD",
    borderRadius: "10px",
    padding: "12px 8px",
    boxShadow: "none",
  },
  submitbtn: {
    borderRadius: "10px",
    padding: "12px 8px",
  },
  tableMain: {
    padding: "4px 4px 16px 16px !important",
  },
  drawerwidth: {
    width: "380px",
    [theme.breakpoints.down("sm")]: {
      width: "318px",
    },
  },
  Header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "14px",
    borderBottom: "1px solid #00000012",
    position: "absolute",
    width: "100%",
    top: "0",
    height: "60px",
    padding: "16px 16px 16px",
    justifyContent: "left",
    backgroundColor: theme.palette.primary.contrastText,
    zIndex: "1",
    "& .MuiTypography-root": {
      fontSize: "0.875rem",
      fontWeight: "bold",
      fontFamily: FontFamilySwitch().bold,
    },
  },
  closeIcondrawermap: {
    right: "400px",
    position: "fixed",
    display: "flex",
    color: theme.palette.primary.contrastText,
    cursor: "pointer",
    fontSize: "0.875rem",
    marginTop: 8,
    "& .MuiSvgIcon-root": {
      fontSize: "0.875rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.875rem !important",
      },
    },
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      right: "11px",
      color: theme.palette.primary.dark,
      top: "0px",
      fontSize: "0.875rem",
    },
  },
  createnewsection: {
    padding: "10px 15px 65px",
    height: "100vh",
    overflow: "auto",
    paddingTop: "80px",
    zIndex: "-1",
  },
  btnparent: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    padding: "10px 16px",
    backgroundColor: "#fff",
    borderTop: "1px solid #00000012",
    "& .MuiButton-root": {
      width: "100%",
    },
  },
  Apply: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  clearall: {
    backgroundColor: "#fff",
    border: "1px solid #E4E8EE",
    color: "#091B29",
    "&:hover": {
      backgroundColor: "#fff",
      border: "1px solid #E4E8EE",
    },
  },
  content: {
    paddingTop: "4px !important",
  },
  indicator: {
    padding: "5px",
    borderRadius: "50%",
    backgroundColor: "#5078E1",
    position: "absolute",
    top: "-5px",
    right: "-5px",
  },
  customBadge: {
    top: "2px",
    right: "2px",
  },
  // Tray related styles
  tray: {
    zIndex: 1000,
    borderTop: "1px solid #ddd",
    width: "330px",
    minHeight: "100px",
    maxHeight: "350px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  list: {
    maxHeight: "200px",
    overflowY: "auto",
    width: "100%",
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  fileName: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: "300px",
  },
  disabledFileName: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: "300px",
  },
  buttonWrapper: {
    position: "relative",
    width: "60%",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  fileProgress: {
    marginLeft: theme.spacing(1),
  },
  button: {
    width: "100%",
  },
}));
