/* eslint-disable react-hooks/exhaustive-deps */
import { Badge, Box, Button, Dialog, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import FilterIMG from '../../assets/filter';
import { FilterGenerator, SearchFilter, SelectBox, Subheader, TableWithPagination, TextBox, UseDebounce } from '../../components';
import { AlertContext, AuthContext, BackdropContext } from '../../contexts';
import { AlertProps, getCalendarOptions, getCompanyOption, LocalStorageKeys, NetWorkCallMethods } from '../../utils';
import { useStyles } from "./style";
import CloseIcon from '@mui/icons-material/Close';
import { Heading, Path, Type, StatusOptionList } from '../../utils/activityCategory/activityCategoryListUtils';
import { withTranslation } from 'react-i18next';
import { NetworkCall } from '../../networkcall';
import { config } from '../../config';
import { loadOptionsApis } from '../../utils/asyncPaginateLoadOptions';

const ActivityCategory = ({
    t
}) => {
    const defaultFilterState = { status: [true] };

    const defaultAddState = {
        formType: "add",
        id: "",
        activity: "",
        activityCategory: "",
        status: true,
        delete: false,
        error: {
            activity: "",
            activityCategory: "",
        }
    }

    const clientID = localStorage.getItem(LocalStorageKeys.clinetID) ?? undefined
    const classes = useStyles()
    const debounce = UseDebounce()

    // useContext
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)


    // useState
    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [list, setList] = React.useState({})
    const [searchText, setSearchText] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [filterData, setFilterData] = React.useState(defaultFilterState)
    const [filterDrawer, setFilterDrawer] = React.useState(false)
    const [addDialogOpen, setAddDialogOpen] = React.useState(false)
    const [addState, setAddState] = React.useState({ ...defaultAddState })
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)

    // useEffect to get company list for company switcher when loading the screen
    React.useEffect(() => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
        }
        // eslint-disable-next-line
    }, [auth])

    // useEffect to get activity category list using selected company and filter data when loading the screen
    React.useEffect(() => {
        getCalendarOptions()
        setPage(1)
        if (selectedCompany?.value) { getActivityCategory() }
    }, [selectedCompany, filterData])

    // Function to get activity category list based on the input data
    const getActivityCategory = (offset = 0, limit = 10, search = "") => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: t("Loading...") })
        const payload = {
            status: (!filterData?.status || filterData?.status?.length === 0) ?
                [true, false] : filterData?.status,
            company_id: selectedCompany?.value,
            search,
            offset,
            limit,
        }
        NetworkCall(
            `${config.api_url}/queries/activity_category_master/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((r) => {
            setList({
                data: r?.data?.data?.activity_category_master,
                totalRowsCount: r?.data?.count
            })
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
        }).catch((e) => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
            })
        })
    }

    // Set row data for table
    const Rows = React.useCallback(list?.data?.map((_) => {
        let j
        try {
            j = {
                id: _?.id,
                activity: _?.lead_activity_masterByID ?? "-",
                activityCategory: _?.name ?? "-",
                status: _?.is_active ? "Active" : "Inactive",
                data: _
            }
        } catch (err) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
            })
        }
        return j
    }), [list])

    // Function to change the company
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
    }

    // Function for search in search component
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    // Function to search data in activity category list
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }
        getActivityCategory(0, limit, e)
    }

    // Function to open add activity category form
    const handleAddActivityCategory = () => {
        setAddState({ ...defaultAddState })
        setAddDialogOpen(true)
    }

    // Function to handle icon in table row
    const handleTableIcon = (type, data) => {
        const tempData = data?.data
        const tempAddState = {
            formType: type,
            id: data?.id,
            activity: data?.activity,
            activityCategory: data?.activityCategory,
            status: type === "active" ? !tempData?.is_active : tempData?.is_active,
            delete: type === "delete" ?? tempData?.is_delete,
            error: defaultAddState?.error
        }
        setAddState({ ...tempAddState })
        if (type === "edit" || type === "view") { setAddDialogOpen(true) }
        else if (type === "active" || type === "delete") { handleCreateEdit(tempAddState) }
    }

    // Function to handle pagination in table
    const handleTablePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getActivityCategory(offset, limit, searchText)
    }

    // Function to handle page limit in table
    const handleTablePageLimit = (value) => {
        setLimit(value)
        setPage(1)
        getActivityCategory(0, value, searchText)
    }

    // Function to update addState
    const updateAddDialogState = (k, v) => {
        let error = addState?.error
        error[k] = ""
        setAddState({ ...addState, [k]: v, error })
    }

    // Function for updating addNewState
    const validate = () => {
        let isValid = true
        let error = addState.error
        if (addState?.activity?.length === 0) { isValid = false; error.activity = t("Activity is Required") }
        if (addState?.activityCategory?.length === 0) {
            isValid = false; error.activityCategory = t("Activity Category is Required")
        }
        if (!isValid) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill all mandatory field"),
            })
        }
        setAddState({ ...addState, error })
        return isValid
    }

    // Function to create a activity category
    const handleCreateEdit = async (data) => {
        if ((data?.formType === "active" || data?.formType === "delete") ? true : validate()) {
            const currentDate = new Date().toISOString()
            const profileID = localStorage.getItem(LocalStorageKeys.profileID)
            setIsDisableBtn(true)
            const payload = {
                company_id: selectedCompany?.value ?? undefined,
                lead_activity_master_id: data?.activity?.value ?? undefined,
                is_active: data?.status ?? undefined,
                is_delete: data?.delete ?? undefined,
                name: data?.activityCategory ?? undefined,
                updated_at: currentDate ?? undefined,
                updated_by: profileID ?? undefined
            }

            if (data?.formType === "add") {
                payload.client = clientID ?? undefined
                payload.created_at = currentDate ?? undefined
                payload.created_by = profileID ?? undefined
            }

            const upsertPayload = {
                params: [
                    {
                        id: data?.id?.length ? data?.id : undefined,
                        ...data?.id ? payload : payload
                    }
                ]
            }

            NetworkCall(
                `${config.api_url}/queries/activity_category_master/upsert`,
                NetWorkCallMethods.post,
                upsertPayload,
                null,
                true,
                false
            ).then((r) => {
                setAddState({ ...defaultAddState })
                getActivityCategory()
                setAddDialogOpen(false)
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.success,
                    msg: `Activity Category ${data?.id ? `Updated` : `Created`} Successfully.!!!`,
                })
            }).catch((err) => {
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
                })
            })

        } else { return false }
    }

    const manualLeadCategoryResponse = (array) => {
        return array.lead_activity_master
    }

    return <div>
        <Subheader hideBackButton={true} title={t("Activity Category")}
            select options={companyList} value={selectedCompany} onchange={(e) => handleCompanyChange(e)} />
        <div className={classes.root}>
            <Grid container className={classes.content} spacing={1}>
                <Grid item xs={4}>
                    <SearchFilter value={searchText} placeholder={t("Search Activity Category")}
                        handleChange={(value) => handleSearch(value)}
                        customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
                    />
                </Grid >
                <Grid item xs={8}>
                    <Box display={"flex"} justifyContent={"end"}>
                        <Stack direction="row" spacing={2}
                            divider={<Divider orientation="vertical" flexItem sx={{ marginInline: "16px" }} />}>
                            <IconButton onClick={() => setFilterDrawer(!filterDrawer)}
                                className={classes.filterButton}>
                                <Badge variant="dot" color="primary"
                                    invisible={!(filterData.status?.length > 0)}>
                                    <FilterIMG color="#091b29" />
                                </Badge>
                            </IconButton>
                            <Button variant="contained" className={classes.button}
                                onClick={handleAddActivityCategory}>
                                {t("Add Activity Category")}
                            </Button >
                        </Stack >
                    </Box >
                </Grid >

            </Grid >
            <TableWithPagination
                heading={Heading(t)}
                rows={Rows}
                path={Path}
                showpagination={true}
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                handleIcon={handleTableIcon}
                onClick={() => console.log("")}
                tableType="no-side"
                dataType={Type}
                handlePagination={handleTablePagination}
                handleChangeLimit={handleTablePageLimit}
                totalRowsCount={list?.totalRowsCount}
                page={page}
                limit={limit}
                height={'calc(100vh - 290px)'}
                view={true}
                edit={true}
                delete={true} />
            <FilterGenerator open={filterDrawer} onClose={() => setFilterDrawer(false)}
                onApply={(value) => setFilterData(value)}
                defaultState={defaultFilterState}
                components={[
                    {
                        component: "toggleButton",
                        value: filterData?.status,
                        options: StatusOptionList,
                        isMulti: true,
                        state_name: "status",
                        label: t("Status")
                    },
                ]} />
            <Dialog
                className={classes.addDialog}
                open={addDialogOpen}
                onClose={() => setAddDialogOpen(false)}>
                <div className={classes.addDialogHeader}>
                    <Typography className={classes.addDialogHeaderTitle}>
                        {
                            addState?.formType === "add" ? t("Add Activity Category") :
                                addState?.formType === "edit" ? t("Edit Activity Category") :
                                    addState?.formType === "view" ? t("View Activity Category") : t("Add Activity Category")
                        }
                    </Typography >
                    <IconButton onClick={() => setAddDialogOpen(false)}
                        className={classes.addDialogCloseButton}>
                        <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
                    </IconButton>
                </div >
                <div className={classes.addDialogBody}>
                    <SelectBox
                        isRequired
                        isReadOnly={
                            (addState?.formType === "add" ||
                                addState?.formType === "edit") ?
                                false : true}
                        label="Activity"
                        placeholder="Select Activity"
                        value={addState?.activity}
                        menuPlacement={"bottom"}
                        onChange={(value) => updateAddDialogState("activity", value)}
                        isPaginate
                        debounceTimeout={800}
                        loadOptions={(search, array, handleLoading) =>
                            loadOptionsApis(
                                "queries/lead_activity_master/get",
                                {},
                                search,
                                array,
                                handleLoading,
                                "data",
                                {},
                                manualLeadCategoryResponse
                            )}
                        isError={addState?.error?.activity?.length > 0}
                        errorMessage={addState?.error?.activity} />
                    <Box height={16} />
                    <TextBox
                        isrequired
                        isReadonly={addState?.formType === "view"}
                        label={t("Activity Category")}
                        placeholder={t("Enter Activity Category")}
                        value={addState?.activityCategory ?? ""}
                        onChange={(e) => updateAddDialogState("activityCategory", e.target.value)}
                        isError={addState?.error?.activityCategory?.length > 0}
                        errorMessage={addState?.error?.activityCategory} />
                    <Box height={16} />
                    <Typography className={classes.addDialogFieldLabel} noWrap>{t("Status")}</Typography>
                    <div className={classes.addDialogButtonContainer}>
                        {StatusOptionList.map((_) => {
                            return <Button className={_?.value === addState?.status ?
                                classes.addDialogButtonSelected : classes.addDialogButtonUnSelected}
                                onClick={() => (addState?.formType === "add" || addState?.formType === "edit") ?
                                    updateAddDialogState("status", _?.value) : false}>
                                {_?.label}
                            </Button>
                        })}
                    </div>
                </div >
                {(addState?.formType === "add" || addState?.formType === "edit") &&
                    <div className={classes.addDialogFooter}>
                        {addState?.formType === "edit" && <><Button fullWidth
                            className={classes.addDialogFooterCloseButton}
                            onClick={() => setAddDialogOpen(false)}>
                            {"Cancel"}
                        </Button></>}
                        <Button variant="contained" fullWidth
                            className={classes.addDialogFooterButton}
                            onClick={() => handleCreateEdit(addState)} disabled={isDisableBtn}>
                            {addState?.formType === "add" ? t("Create") : t("Save")}
                        </Button >
                    </div >}
            </Dialog >
        </div >
    </div >
}
export default withTranslation("activityCategory")(ActivityCategory);
