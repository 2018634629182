import { Close, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, Menu, MenuItem, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import React, { useContext, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { useNavigate, useLocation } from "react-router-dom"
import { DeleteIcon } from "../../../assets"
import { DeleteSvgIcon } from "../../../assets/deleteSvg"
import { DropDownIcon } from "../../../assets/dropDownIcon"
import { GoBack } from "../../../assets/goBack"
import { InputFieldIcon } from "../../../assets/inputFieldIcon"
import { MultiSelectIcon } from "../../../assets/multiSelectIcon"
import { NoComponentIcon } from "../../../assets/noComponentsIcon"
import { PublishFormIcon } from "../../../assets/publishForm"
import { RatingIcon } from "../../../assets/ratingicon"
import { AlertDialog, SelectBox, Subheader, TextBox } from "../../../components"
import { config } from "../../../config"
import { AlertContext } from "../../../contexts"
import { NetworkCall } from "../../../networkcall"
import { Routes } from "../../../router/routes"
import { AlertProps, enumSelect, enum_types, LocalStorageKeys, NetWorkCallMethods, useWindowDimensions } from "../../../utils"
import { useStyles } from "../style"
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions"

const Form = ({ t }) => {
    const size = useWindowDimensions();
    const classes = useStyles()
    const navigate = useNavigate()
    const { state } = useLocation()
    const Range = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    const [selectedComponent, setSelectedComponent] = useState("")
    const [questions, setQuestions] = useState([])
    const [formComponents, setFormComponents] = useState([])
    const draggingPos = useRef(null);
    const dragOverPos = useRef(null);
    // const [enumValue, setEnumValue] = React.useState({ formComponents: [], requestType: [] })
    const [data, setData] = useState({
        form_title: "",
        form_category: "",
        is_mandatory: false,
        form_type: "draft",
        error: {
            form_title: "",
            form_category: "",
        }
    })
    const client_id = localStorage.getItem(LocalStorageKeys.clinetID)
    const alert = useContext(AlertContext)
    const [goBack, setGoBack] = React.useState(false)
    const [isPublish, setIsPublish] = React.useState(false)
    const [saveDraft, setSaveDraft] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isDisableBtn,setIsDisableBtn] = React.useState(false)
    const open = Boolean(anchorEl);

    // Function to get Enum value
    const getEnum = async () => {
        const result = await enumSelect([enum_types.inspection_request_type_enum, enum_types.feedback_element_type])
        // setEnumValue({ requestType: result?.inspection_request_type_enum })
        const formComponentsList = result?.feedback_element_type?.map((val, i) => {
            return {
                id: i + 1,
                type: val?.value,
                name: val?.label,
                question: "",
                options: (val?.value !== "text" && val?.value !== "rating") ? [{ value: "", label: "" }] : val?.value === "rating" ? [0, ...arrayRange(1, 10, 1)]?.map((x) => { return { label: x, value: x } }) : [],
                is_mandatory: false,
                start_range: 0,
                end_range: 10,
                icon: val?.value === "text" ? <InputFieldIcon /> : val?.value === "rating" ? <RatingIcon /> : val?.value === "check_box" ? <MultiSelectIcon /> : val?.value === "boolean" ? <DropDownIcon /> : ""
            }
        })
        setFormComponents(formComponentsList)
        if (state?.is_edit) {
            GetFeedBackDetailsById(result?.inspection_request_type_enum, result?.feedback_element_type)
        }

    }
    //get edit details
    // get feedBack details by id
    const GetFeedBackDetailsById = (requestType, feedback_element_type) => {
        const payload = {
            form_id: state?.data?.id
        }
        NetworkCall(
            `${config.api_url}/feedback_form/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            const result = res?.data?.data?.data
            setData({
                ...data,
                form_title: result?.title,
                form_category: { value: result?.feedback_master?.[0]?.type_id, label: result?.feedback_master?.[0]?.type?.[0]?.name },


            })
            const question_bank = result?.feedback_elements?.map((x) => {
                return {
                    type: x.type,
                    question: x.title,
                    name: feedback_element_type?.filter((val) => val.value === x.type)?.[0]?.label,
                    options: addId(x.data_type_options, true),
                    is_mandatory: x.is_mandatory,
                    start_range: x.type === "rating" ? x.data_type_options?.[0]?.value : 1,
                    end_range: x.type === "rating" ? x.data_type_options?.[x.data_type_options?.length - 1]?.value : 10,
                }
            })
            setQuestions(addId(question_bank))
        }).catch((err) => {
            console.log(err)
        })
    }

    // useEffect to load the Enum value while initial load
    React.useEffect(() => {
        getEnum()
        //eslint-disable-next-line
    }, [])
    const handleDragStart = (position) => {
        draggingPos.current = position;
    };

    const handleDragEnter = (position) => {
        dragOverPos.current = position;
        const newItems = [...questions];
        const draggingItem = newItems[draggingPos.current];
        if (!draggingItem) return;

        newItems.splice(draggingPos.current, 1);
        newItems.splice(dragOverPos.current, 0, draggingItem);

        const reorderedItems = newItems.map((item, index) => ({
            ...item,
            id: index
        }));

        draggingPos.current = position;
        dragOverPos.current = null;
        setQuestions(reorderedItems);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const validateForm = () => {
        let isValid = true;
        let error = data.error;

        if (data?.form_title?.length === 0) {
            isValid = false;
            error.form_title = t("Form Title is required")
        }
        if (data?.form_category?.length === 0) {
            isValid = false;
            error.form_category = t("Form Category is required");
        }

        setData({ ...data, error });
        return isValid;
    };
    const updateState = (k, v) => {
        let error = data?.error ?? {};


        error[k] = "";
        setData({ ...data, [k]: v, error })
    }
    const addId = (arr, option = false) => {
        return arr.map(function (obj, index) {
            return Object.assign({}, obj, option ? { option_id: index } : { auto_inc_id: index });
        });
    };

    const handleComponents = (val, index) => {
        const result = [...questions, val]
        setQuestions(addId(result))
        setSelectedComponent(val)
    }

    //component type change
    const handleSelect = (val, selected) => {
        const result = questions?.map((data) => {
            if (data.auto_inc_id === selected.auto_inc_id) {
                return val
            }
            else {
                return data
            }
        })
        setQuestions(addId(result))
        setSelectedComponent(val)
        handleClose()
    }
    //handleQuestion
    const handleQuestion = (value, detail) => {
        const result = questions?.map((x) => {
            if (x.auto_inc_id === detail.auto_inc_id) {
                return { ...x, question: value }
            }
            else {
                return x
            }
        })
        setQuestions(result)

    }
    //delete question
    const handleDeleteQuestions = (val) => {
        const result = questions?.filter((x) => x.auto_inc_id !== val?.auto_inc_id)
        setQuestions(result)
    }
    //option store
    const handleOptionChange = (val, detail, option_value) => {
        const result = questions?.map((x) => {
            const options = x.auto_inc_id === detail.auto_inc_id ? x?.options?.map((option) => {
                if (option?.option_id === val?.option_id) {
                    return {
                        ...option,
                        value: option_value,
                        label: option_value
                    }
                }
                else {
                    return option
                }
            }) : x.options
            return { ...x, options }
        })
        setQuestions(result)
    }
    //add more options
    const addMoreOptions = (val) => {
        const result = questions?.map((x) => {
            const addOptions = [...val?.options, { value: "", label: "" }]
            if (val?.auto_inc_id === x.auto_inc_id) {
                return {
                    ...x,
                    options: addId(addOptions, true)
                }
            }
            else {
                return x
            }
        })
        setQuestions(result)
    }
    //deleteOptions
    const deleteOption = (val, detail) => {
        const result = questions?.map((x) => {
            const options = x.auto_inc_id === detail.auto_inc_id ? x?.options?.filter((data) => data?.option_id !== val.option_id) : x.options
            return { ...x, options }
        })
        setQuestions(result)
    }
    //arrayRange
    const arrayRange = (start, stop, step) => {
        return Array.from(
            { length: (stop - start) / step + 1 },
            (value, index) => start + index * step
        );
    }
    //Rating Range selection
    const handleRangeSelection = (key, value, detail) => {
        const result = questions?.map((x) => {
            if (x.auto_inc_id === detail.auto_inc_id) {
                if (key === "start_range") {
                    return { ...x, [key]: value?.value, options: value?.value === 0 ? [{ value: 0, label: 0 }, ...arrayRange(value?.value, detail?.end_range, value?.value)?.map((val) => { return { value: val, label: val } })] : arrayRange(value?.value, detail?.end_range, value?.value)?.map((val) => { return { value: val, label: val } }) }
                }
                else {
                    return { ...x, [key]: value?.value, options: detail?.start_range === 0 ? [{ value: 0, label: 0 }, ...arrayRange(1, value?.value, 1)?.map((val) => { return { value: val, label: val } })] : arrayRange(detail?.start_range, value?.value, detail?.start_range)?.map((val) => { return { value: val, label: val } }) }
                }

            }
            else {
                return x
            }
        })
        setQuestions(result)
    }
    //mandatory check
    const mandatoryCheck = (value, data) => {
        const result = questions?.map((val) => {
            if (val.auto_inc_id === data.auto_inc_id) {
                return { ...val, is_mandatory: value?.target?.checked }
            }
            else {
                return val
            }
        })
        setQuestions(result)
    }
    //go Back
    const goBackToPage = () => {
        setGoBack(true)
    }
    //go back and save as draft
    const goBackAndSave = () => {
        setGoBack(false)
        if (questions?.length > 0) {
            onSubmit("draft")
        }
        else {
            navigate(Routes?.formList)
        }
    }
    //create form
    const onCreateForm = (type) => {
        if (type === "publish") {
            setIsPublish(true)
        }
        else {
            onSubmit(type)
        }
        setData({ ...data, form_type: type })
    }
    //on submit form
    const onSubmit = (type) => {
        if (validateForm()) {
            setIsDisableBtn(true)
            const payload = {
                "upsert_fields": {
                    id: state?.data?.id ?? undefined,
                    title: data?.form_title,
                    is_draft: type === "draft" ? true : false,
                    // inspection_type: data?.form_category?.value,
                    type_id: data?.form_category?.value,
                    feedback_master: {
                        // inspection_type: data?.form_category?.value,
                        type_id: data?.form_category?.value,
                        client: client_id
                    },
                    feedback_elements: questions?.map((val, i) => {
                        return {
                            type: val?.type,
                            order: i + 1,
                            title: val?.question,
                            data_type_options: val?.type !== "text" ? val?.options?.map((x) => { return { label: x.label, value: x.value } }) : undefined,
                            is_mandatory: val?.is_mandatory
                        }
                    })
                }
            }
            NetworkCall(
                `${config.api_url}/feedback_form/upsert`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((res) => {
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: `Form ${type === "draft" ? t("Saved as Draft") : state?.is_edit ? t("Edited") : t("Published")} ${t("Successfully")}`,
                });
                navigate(Routes?.formList)
            }).catch((error) => {
                setIsDisableBtn(false)
                if (error.response) {
                    // Request made and server responded
                    setIsPublish(false)
                    setSaveDraft(true)
                }
                else {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Something went wrong please try again"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });
                }
            })
        }
    }
    const manualResponse = (array) => array?.list?.map(_ => {
        return {
            ..._,
            label: _?.name,
            value: _?.id,
        }
    });
    return (
        <Box>
            <Subheader
                title={state?.is_edit ? t("editFormBuilder") : t("formBuilder")}
                goBack={goBackToPage}
            />
            <Grid container>
                <Grid item xs={12} sm={4} md={3} lg={3} className={classes.formBlock} style={{ height: size?.height - 120 }}>
                    <Box p={2} position={"relative"} className={classes.responsiveBlock}>
                        <Typography className={classes.formTitle}>{t("formInformation")}</Typography>
                        <Box mt={1}>
                            <TextBox
                                label={t("formTitle")}
                                placeholder={t("enterFormTitle")}
                                value={data?.form_title}
                                onChange={(e) => updateState("form_title", e?.target?.value)}


                                isrequired
                                isError={data?.error?.form_title?.length > 0}
                                errorMessage={data?.error?.form_title} />
                        </Box>
                        <Box mt={2}>
                            <SelectBox label={t("formCategory")}
                                value={data?.form_category}
                                // options={enumValue?.requestType}
                                loadOptions={(search, array, handleLoading) =>
                                    loadOptionsApis(
                                        "customer_feedback_type_master",
                                        null,
                                        search,
                                        array,
                                        handleLoading,
                                        "data",
                                        {},
                                        manualResponse
                                    )
                                }
                                isPaginate={true}

                                onChange={(value) => updateState("form_category", value)}
                                placeholder={t("selectCategory")}
                                isRequired
                                errorMessage={data?.error?.form_category}
                                isError={data?.error?.form_category?.length > 0}
                            />
                        </Box>
                        <Box mt={2} mb={2}>
                            <Divider />
                        </Box>
                        <Box mt={1}>
                            <Typography className={classes.formTitle}>{t("formComponents")}</Typography>
                        </Box>
                        <Box mt={1}>
                            <Grid container spacing={2}>
                                {formComponents?.map((x, index) => {
                                    return (
                                        <Grid item xs={12} sm={12} md={6} lg={6} onClick={() => handleComponents(x, index)}>


                                            <Box className={classes.componentBox}>
                                                <Box>
                                                    <Box display="flex" justifyContent={"center"} alignItems="center">{x.icon}</Box>
                                                    <Typography className={classes.formComponentTitle}>{x.name}</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    )
                                })}

                            </Grid>
                        </Box >

                    </Box >
                    <Box className={classes.fixedBottom}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Button variant="outlined" className={classes.draftbtn} onClick={() => onCreateForm("draft")} fullWidth disabled={isDisableBtn}>{t("Save Draft")}</Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Button variant="contained" disabled={questions?.length === 0 ? true : isDisableBtn?true:false} onClick={() => onCreateForm("publish")} fullWidth>{t("Publish Form")}</Button>
                            </Grid>
                        </Grid>
                    </Box >
                </Grid >
                <Grid item xs={12} sm={8} md={9} lg={9} className={classes.rightBlock}>


                    {questions?.length > 0 ? questions?.map((x, i) => {
                        return (
                            <Box className={classes.chooseQuestions}>
                                <Box display="flex" justifyContent={"space-between"} p={1.5}>

                                    <Stack direction="row" alignItems="center" gap={"16px"}>
                                        <Box className={classes.dragIcon} draggable
                                            onDragStart={() => handleDragStart(i)}
                                            onDragEnter={() => handleDragEnter(i)}
                                            onDragOver={(e) => e.preventDefault()}>
                                            <img src="/images/drag-reorder.png" alt="drag-reorder" />
                                        </Box>
                                        <Box display="flex" justifyContent={"space-between"} className={classes.customSelectBox} alignItems="center" onClick={(e) => { return (handleClick(e), setSelectedComponent(x)) }}>
                                            <Typography className={classes.optionsText}>{x?.name}</Typography>
                                            {x.auto_inc_id === selectedComponent?.auto_inc_id && open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                        </Box>
                                        <FormControlLabel
                                            // value="mandatory"
                                            control={<Checkbox onChange={(e) => mandatoryCheck(e, x)} checked={x.is_mandatory} />}
                                            label="Mandatory"
                                            labelPlacement="end"
                                            sx={{ marginRight: '0px' }}
                                        />
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            className={classes.menuList}
                                            PaperProps={{
                                                style: {
                                                    width: 180,
                                                },
                                            }}
                                        >
                                            {formComponents?.map((val) => {
                                                return (
                                                    <MenuItem
                                                        onClick={() => {
                                                            handleSelect(val, selectedComponent)
                                                        }}
                                                        className={classes.menuItem}
                                                    >
                                                        <Typography className={classes.optionsText} style={{ color: val?.id === selectedComponent?.id ? "#5078E1" : "#4E5A6B" }}>{val.name}</Typography>
                                                    </MenuItem>
                                                )
                                            })

                                            }
                                        </Menu>
                                    </Stack>
                                    <Box display={"flex"} justifyContent="center" alignItems={"center"} onClick={() => handleDeleteQuestions(x)} style={{ cursor: "pointer" }}><DeleteSvgIcon /></Box>
                                </Box>
                                <Divider />
                                <Box mt={1} p={1.5}>
                                    <Typography className={classes.questiontxt}>{t("Question Here")}</Typography>
                                    <Box mt={1}>
                                        <TextBox label="" placeholder={t("Type question here")} value={x.question} onChange={(e) => handleQuestion(e.target.value, x)} />
                                    </Box>
                                </Box >
                                {
                                    x.type !== "text" &&
                                    <Box p={1.5}>
                                        <Typography className={classes.optiontitle}>{x.type === "boolean" ? "Single select OPTIONS" : x.type === "rating" ? "Choose Rating range" : x.type === "radio" ? "MULTIPLE SELECT OPTIONS" : ""}</Typography>
                                        {x.type !== "rating" &&
                                            <>

                                                {x.options?.map((val) => {
                                                    return (
                                                        <Box mt={1}>
                                                            <Grid container spacing={2}>
                                                                < Grid item xs={12} sm={12} md={4} lg={3} mt={0.5} >

                                                                    <Stack direction="row" spacing={2} alignItems={"center"}>
                                                                        <TextBox label="" placeholder={`Type Choice `} value={val.value} onChange={(e) => handleOptionChange(val, x, e.target.value)} />
                                                                        <Box display="flex" alignItems={"center"} justifyContent={"center"} onClick={() => deleteOption(val, x)} style={{ cursor: "pointer" }}><DeleteIcon /></Box>
                                                                    </Stack>
                                                                </Grid >
                                                            </Grid >
                                                        </Box >
                                                    )
                                                })}

                                                <Box mt={2} onClick={() => addMoreOptions(x)}>
                                                    <Typography className={classes.addAnother}>{t("Add Another Option")}</Typography>
                                                </Box >
                                            </>
                                        }
                                        {
                                            x.type === "rating" &&
                                            <Box mt={1}>
                                                <Stack direction="row" spacing={2} alignItems="center">
                                                    <Box>
                                                        <SelectBox label="" value={{ value: x.start_range, label: x.start_range }} options={Range?.map((val) => { return { label: val, value: val } })} onChange={(val) => handleRangeSelection("start_range", val, x)} menuPlacement={i !== 0 && i === questions?.length - 1 ? "top" : "bottom"} />
                                                    </Box>
                                                    <Box>-</Box>
                                                    <Box>
                                                        <SelectBox label="" value={{ value: x.end_range, label: x.end_range }} options={Range?.map((val) => { return { label: val, value: val } })} onChange={(val) => handleRangeSelection("end_range", val, x)} menuPlacement={i !== 0 && i === questions?.length - 1 ? "top" : "bottom"} />
                                                    </Box>
                                                </Stack>
                                            </Box>
                                        }
                                    </Box >
                                }
                            </Box >)
                    }) :
                        <Box className={classes.nocomponentBox}>
                            <NoComponentIcon />
                            <Box mt={1}>
                                <Typography className={classes.nocomponentText}>{t("Add Form Component")}</Typography>
                                <Box mt={1}>
                                    <Typography className={classes.choosecomponentText}>{t("Choose component from left side form components")}</Typography>
                                    <Typography className={classes.choosecomponentText}>{t("panel")}</Typography>
                                </Box>
                            </Box >
                        </Box >
                    }
                </Grid >
            </Grid >
            {/* goBack */}
            < AlertDialog
                open={goBack}
                isNormal
                isnotTitle={true}
                onClose={() => setGoBack(false)}
                component={
                    < Box p={1} >
                        <Box display={"flex"} justifyContent="end" style={{ cursor: "pointer" }} onClick={() => setGoBack(false)}><Close /></Box>
                        <Box p={1}>
                            <center>
                                <Box><GoBack /></Box>
                                <Box mt={1}>
                                    <Typography className={classes.goBacktext}>{t("Do you want to go back?")}</Typography>
                                    {
                                        questions?.length > 0 &&
                                        <Box mt={1}>
                                            <Typography className={classes.goBackSubtxt}>{t("If you go back this form will be saved in draft, you")}</Typography>
                                            <Typography className={classes.goBackSubtxt}>{t("can publish it when ever you want")}</Typography>
                                        </Box>
                                    }
                                </Box >

                            </center >

                        </Box >
                        <Box p={2}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={6}>
                                    <Button variant="outlined" className={classes.draftbtn} fullWidth onClick={() => setGoBack(false)}>{t("No, Stay Here")}</Button>
                                </Grid >
                                <Grid item xs={6}>
                                    <Button variant="contained" fullWidth onClick={() => goBackAndSave()} disabled={isDisableBtn}>{t("Ok, Go Back")}</Button>
                                </Grid>
                            </Grid >
                        </Box >
                    </Box >
                }
            />
            {/* Publish form */}
            <AlertDialog
                open={isPublish}
                isNormal
                isnotTitle={true}
                onClose={() => setIsPublish(false)}
                component={
                    <Box p={1}>
                        <Box display={"flex"} justifyContent="end" style={{ cursor: "pointer" }} onClick={() => setIsPublish(false)}><Close /></Box>
                        <Box p={1}>
                            <center>
                                <Box><PublishFormIcon /></Box>
                                <Box mt={1}>
                                    <Typography className={classes.goBacktext}>{t("Do you want to publish this form?")}</Typography>
                                </Box >

                            </center >

                        </Box >
                        <Box p={2}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={6}>
                                    <Button variant="outlined" className={classes.draftbtn} fullWidth onClick={() => setIsPublish(false)}>{t("No")}</Button>
                                </Grid >
                                <Grid item xs={6}>
                                    <Button variant="contained" fullWidth onClick={() => onSubmit("publish")} disabled={isDisableBtn}>{t("Yes, Publish")}</Button>
                                </Grid>
                            </Grid >
                        </Box >
                    </Box >
                }
            />
            {/* save draft*/}
            <AlertDialog
                open={saveDraft}
                isNormal
                isnotTitle={true}
                onClose={() => setSaveDraft(false)}
                component={
                    <Box p={1}>
                        <Box display={"flex"} justifyContent="end" style={{ cursor: "pointer" }} onClick={() => setSaveDraft(false)}><Close /></Box>
                        <Box p={1}>
                            <center>
                                <Box><PublishFormIcon /></Box>
                                <Box mt={1}>
                                    <Typography className={classes.goBacktext}>{t("An existing form is active. Please disable it to activate the new one.")}</Typography>
                                </Box >

                            </center >

                        </Box >
                        <Box p={2}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={6}>
                                    <Button variant="outlined" className={classes.draftbtn} fullWidth onClick={() => setSaveDraft(false)}>{t("Cancel")}</Button>
                                </Grid >
                                <Grid item xs={6}>
                                    <Button variant="contained" fullWidth onClick={() => onSubmit("draft")}>{t("Save Draft")}</Button>
                                </Grid>
                            </Grid >
                        </Box >
                    </Box >
                }
            />
        </Box >
    )
}
export default withTranslation("formBuilder")(Form)