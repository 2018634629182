import { Box } from "@mui/material";
import React from "react";
import { CompanySettingsList } from "../../../components";
import {
  reservation_feature,
  validateCustom,
} from "../../../utils/companySettings";

export const ChartofAccounts = ({
  data = {},
  updateState = () => false,
  t,
  setSettings = () => false,
}) => {
  return (
    <Box>
      <Box padding="8px 12px">
        <CompanySettingsList
          title={t("Account Receivable Process")}
          onChange={(value) => updateState("account_receive", value)}
          value={{
            ...data?.account_receive,
            acc_receiveType: { label: "Assets", value: "Assets" },
            tax_payType: { label: "Liabilities", value: "Liabilities" },
            depositsType: { label: "Liabilities", value: "Liabilities" },
            customerType: { label: "Liabilities", value: "Liabilities" },
            inventoryType: { label: "Assets", value: "Assets" },
            advanceType: { label: "Liabilities", value: "Liabilities" },
            investType: { label: "Liabilities", value: "Liabilities" },
            saleType: { label: "Expenses", value: "Expenses" },
          }}
          toggle={true}
          isError={data?.error?.account_receive?.length > 0}
          errorMessage={data?.error?.account_receive}
          type="AR"
          setSettings={setSettings}
          data={data}
          t={t}
        />
      </Box>
      <Box padding="8px 12px">
        <CompanySettingsList
          title={t("Account Payable Process")}
          onChange={(value) => updateState("account_pay", value)}
          value={{
            ...data?.account_pay,
            acc_payType: { label: "Liabilities", value: "Liabilities" },
            tax_receiveType: { label: "Assets", value: "Assets" },
            accrued_type: { label: "Liabilities", value: "Liabilities" },
            goodsType: { label: "Expenses", value: "Expenses" },
            creditsType: { label: "Assets", value: "Assets" },
          }}
          toggle={true}
          isError={data?.error?.account_pay?.length > 0}
          errorMessage={data?.error?.account_pay}
          setSettings={setSettings}
          data={data}
          type="AP"
          t={t}
        />
      </Box>
      <Box padding="8px 12px">
        <CompanySettingsList
          title={t("Reservation Invoice Item Master")}
          onChange={(value) => updateState("reservation_item", value)}
          value={{
            ...data?.reservation_item,
            itemType: { label: "Services", value: "Services" },
          }}
          toggle={true}
          isError={data?.error?.reservation_item?.length > 0}
          errorMessage={data?.error?.reservation_item}
          onChangeText={(value) => updateState("reservation_item", value)}
          textValue={data?.reservation_item?.reservation_COA}
          setSettings={setSettings}
          data={data}
          type="Reservation"
          t={t}
        />
      </Box>
      <Box padding="8px 12px">
        <CompanySettingsList
          title={t("Penalty Invoices Item Master")}
          onChange={(value) => updateState("penalty_item", value)}
          value={{
            ...data?.penalty_item,
            itemType: { label: "Services", value: "Services" },
          }}
          toggle={true}
          isError={data?.error?.penalty_item?.length > 0}
          errorMessage={data?.error?.penalty_item}
          onChangeText={(value) => updateState("penalty_item", value)}
          textValue={data?.penalty_item?.penalty_COA}
          setSettings={setSettings}
          data={data}
          type="Penalty"
          t={t}
        />
      </Box>
      <Box padding="8px 12px">
        <CompanySettingsList
          title={t("Upfront Payment Charges Item Master")}
          onChange={(value) => updateState("upfront_charge", value)}
          value={{
            ...data?.upfront_charge,
            itemType: { label: "Services", value: "Services" },
          }}
          toggle={true}
          isError={data?.error?.upfront_charge?.length > 0}
          errorMessage={data?.error?.upfront_charge}
          onChangeText={(value) => updateState("upfront_charge", value)}
          type="Upfront"
          textValue={data?.upfront_charge?.upfront_COA}
          setSettings={setSettings}
          data={data}
          t={t}
        />
      </Box>
    </Box>
  );
};
