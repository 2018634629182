import { Box } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  FilterGenerator,
  LoadingSection,
  Subheader,
  UseDebounce,
} from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import {
  accessCheckRender,
  enum_types,
  enumSelect,
  getCompanyOption,
  getRoutePermissionNew,
  NetWorkCallMethods,
} from "../../utils";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { ReceiptTable } from "./components";
import { ReceiptStyles } from "./style";

const Receipt = ({ t }) => {
  const classes = ReceiptStyles();
  const navigate = useNavigate();
  const auth = React.useContext(AuthContext);
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const [companyList, setCompanyList] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState({});
  const [drawer, setDrawer] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const debounce = UseDebounce();
  const [loading, setLoading] = React.useState(true);
  const [filterData, setFilterData] = React.useState({
    payment_method: null,
    account: null,
    date: {
      startDate: null,
      endDate: null,
    },
  });
  const [activeFilters, setActiveFilters] = React.useState({});
  const [filterValue, setFilterValue] = React.useState({
    payment_method: [],
  });
  const [receiptList, setReceiptList] = React.useState({
    list: [],
    count: 0,
  });
  const [permission, setPermission] = React.useState({});

  const initialLoadRef = React.useRef(false);
  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");
  const limitParam = searchParams.get("limit");

  // Default values if not set in URL
  const pageFromParams = pageParam ? parseInt(pageParam, 10) : 1;
  const limitFromParams = limitParam ? parseInt(limitParam, 10) : 10;

  const [page, setPage] = React.useState(pageFromParams);
  const [limit, setLimit] = React.useState(limitFromParams);

  const updateUrlOnly = (newPage, newLimit) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", newPage.toString());
    searchParams.set("limit", newLimit.toString());

    // Update URL without reloading
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  // Function to load data with proper filters and pagination
  const loadData = (newPage, newLimit, companyId, search, filters) => {
    if (!companyId) return;
    
    const offset = (newPage - 1) * newLimit;
    
    // Update state
    setPage(newPage);
    setLimit(newLimit);
    updateUrlOnly(newPage, newLimit);
    getReciptList(companyId, search, offset, newLimit, filters);
  };

  //handle pagination
  const handlePagination = (newPage) => {
    loadData(newPage, limit, selectedCompany?.value, searchText, activeFilters);
  };

  // Handle limit change
  const handleChangeLimit = (newLimit) => {
    loadData(1, newLimit, selectedCompany?.value, searchText, activeFilters);
  };

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm && !initialLoadRef.current) {
      setPermission(perm);
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          setCompanyList(company?.list);
          setSelectedCompany(company?.selected);
          
          if (company?.selected?.value) {
            initialLoadRef.current = true; 
            getEnum();
            const offset = (pageFromParams - 1) * limitFromParams;
            getReciptList(company?.selected?.value, "", offset, limitFromParams, {});
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  React.useEffect(() => {
    if (initialLoadRef.current && (page !== pageFromParams || limit !== limitFromParams)) {
      setPage(pageFromParams);
      setLimit(limitFromParams);
      
      if (selectedCompany?.value) {
        const offset = (pageFromParams - 1) * limitFromParams;
        getReciptList(selectedCompany?.value, searchText, offset, limitFromParams, activeFilters);
      }
    }
    // eslint-disable-next-line
  }, [pageParam, limitParam]);

  //get Enum 
  const getEnum = async () => {
    const result = await enumSelect([enum_types.payment_mode]);
    const filteredPaymentModes = result?.payment_mode?.filter(mode => mode.value !== "Credit Note");
    const translatedPaymentModes = filteredPaymentModes.map(mode => ({
      ...mode,
      label: t(mode.label),
    }));
  
    setFilterValue({
      payment_method: translatedPaymentModes,
    });
  };
  //get list
  const getReciptList = (
    company_id,
    searchText,
    offset,
    limits,
    filterData
  ) => {
    setLoading(true);
    const payload = {
      company_id: company_id,
      search: searchText,
      offset: offset,
      limit: limits,
      account_no: filterData?.account ?? undefined,
      payment_mode: filterData?.payment_method ?? undefined,
      from_date: filterData?.startDate ?? undefined,
      to_date: filterData?.endDate ?? undefined,
    };
    NetworkCall(
      `${config.api_url}/receipt/getAll`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        const result = response?.data?.data?.map((val) => {
          return {
            ...val,
            receipt_amount: `${val?.symbol} ${val?.receipt_amount}`,
            source: val?.source ?? "-",
            reference: val?.cheque_no ?? "-",
          };
        });
        setReceiptList({
          list: result,
          count: response.data.count,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    // eslint-disable-next-line
  };

  //handle icon
  const handleIcon = (type, data) => {
    if (type === "view") {
      navigate(Routes.receiptView, {
        state: {
          id: data?.id,
          company: selectedCompany,
        },
      });
    } else if (type === "edit") {
      navigate(Routes.createReceipt);
    }
  };
  //handle change
  const handleCompanyChange = (value) => {
    if (value?.value === selectedCompany?.value) return;
    
    setSelectedCompany(value);
    setActiveFilters({});
    setFilterData({
      payment_method: null,
      account: null,
      date: {
        startDate: null,
        endDate: null,
      },
    });
    
    loadData(1, 10, value?.value, "", {});
  };

  //apply filter
  const onApplyFilter = (data) => {
    const processedFilters = {
      account: data?.account?.map((val) => val?.account_no),
      payment_method: data?.payment_method?.map((val) => val?.value),
      startDate: data?.date?.startDate,
      endDate: data?.date?.endDate,
    };
    
    setActiveFilters(processedFilters);
    setFilterData(data);
    setDrawer(false);
    loadData(1, limit, selectedCompany?.value, searchText, processedFilters);
  };
  
  // Handle search input
  const handleSearch = (e) => {
    setSearchText(e);
    debounce(() => {
      loadData(1, limit, selectedCompany?.value, e, activeFilters);
    }, 800);
  };
  const manualResponse = (array) =>
    array?.map((_) => {
      return {
        ..._,
        label: _?.name,
        value: _?.id,
      };
    });
  const render = () => {
    return (
      <>
        {/* subheader */}
        <Subheader
          hideBackButton={true}
          select={true}
          options={companyList}
          title={`${t("receipts")} (${receiptList.count})`}
          value={selectedCompany}
          goBack={() => navigate()}
          onchange={(e) => {
            handleCompanyChange(e);
          }}
        />

        {loading ? (
          <LoadingSection top="20vh" message={t("loading")} />
        ) : (
          <Box className={classes.root}>
            <ReceiptTable
              permission={permission}
              openfilter={() => setDrawer(true)}
              handleIcon={handleIcon}
              value={selectedCompany}
              handleChangeLimit={handleChangeLimit}
              handlePagination={handlePagination}
              page={page}
              filterData={filterData}
              list={receiptList}
              limit={limit}
              handleSearch={handleSearch}
              searchText={searchText}
            />
          </Box>
        )}

        {drawer && (
          <FilterGenerator
            open={drawer}
            onClose={() => setDrawer(false)}
            components={[
              {
                component: "select",
                value: filterData?.payment_method,
                options: filterValue?.payment_method,
                state_name: "payment_method",
                label: t("Payment Method"),
                isMulti: true,
                placeholder: t("Select Payment Method"), 
              },
              {
                component: "select",
                value: filterData?.account,
                state_name: "account",
                label: t("Account"),
                isPaginate: true,
                debounceTimeout: 800,
                isMulti: true,
                placeholder: t("Select Account"),
                loadOptions: (search, array, handleLoading) =>
                  loadOptionsApis(
                    "queries/opportunity/contact_account",
                    {
                      company_id: selectedCompany?.value,
                    },
                    search,
                    array,
                    handleLoading,
                    "data",
                    {},
                    (response) => {
                      const filteredData = response?.filter(
                        (account) =>
                          account.account_payment_classification === "AR"
                      );
                      return manualResponse(filteredData);
                    }
                  ),
              },
            ]}
            onApply={(value) => onApplyFilter(value)}
          />
        )}
      </>
    );
  };

  return <Box>{accessCheckRender(render, permission)}</Box>;
};

export default withTranslation("receipt")(Receipt);
