import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStylesCreation = makeStyles((size) => ({
      tabtitle: {
            fontSize:"0.8125rem",
            color: '#4E5A6B',
            fontFamily: FontFamilySwitch().bold
      },
      tabtitle1: {
            fontSize:"0.75rem",
            color: '#5078E1',
            fontFamily: FontFamilySwitch().bold
      },
      tabsub: {
            fontSize:"0.75rem",
            color: '#98A0AC',
            fontFamily: FontFamilySwitch().regular,
      },
      tabs: {
            padding: '0px !important',
            '& .MuiTabs-root': {
                  padding: '0px !important',
                  '& .MuiTabs-scroller': {
                        overflowX: "auto !important",
                        "&::-webkit-scrollbar": {
                              width: "0em",
                              display: "none"
                        },
                  }
            },
            '& .MuiTabs-scroller': {
                  overflowX: "auto !important",
                  "&::-webkit-scrollbar": {
                        width: "0em",
                        display: "none"
                  },
            },
            '& .MuiButtonBase-root-MuiTab-root': {
                  padding: "0px !important"
            }



      },
      after: {
            position: 'absolute',
            right: '0',
            fontSize: 'larger',
            color: '#e3e3e3',
            fontWeight: 100
      },
      tabPanel: {
            padding: (props) => props?.paddingTab ?? "16px",

      },
      tabBox: {
            borderBottom: (props) => props?.backgroundColor ? "1.5px solid #E4E8EE" : 1,

            borderColor: 'divider',
            backgroundColor: (props) => props?.backgroundColor ? "#FFFFFF" : '#F5F7FA',
            display: "flex",
            justifyContent: "space-between",
      },
      Cancel: {
            backgroundColor: "#FFFFFF",
            color: "#091B29",
            fontSize:"0.875rem",
            fontWeight: 600,
            fontFamily: FontFamilySwitch().semiBold,

            border: '1px solid #E4E8EE',
            "&:hover": {
                  backgroundColor: "#FFFFFF ",
            },
      },
      next: {
            marginInlineStart: "10px",
            padding: "6px 10px",
            fontFamily: FontFamilySwitch().semiBold,
            color: "#fff",
            // backgroundColor: '#5078E1',
            "&:hover": {
                  backgroundColor: '#5078E1',
            },
      },
      class: {
            color: '#4E5A6B',
            fontFamily: FontFamilySwitch().semiBold,
            fontSize:"1rem"

      }
}));