import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold, ExtraBold, FontFamilySwitch } from "../../utils";

export const managrmentStyles = makeStyles((theme) => ({
  invoiceRoot: {
    padding: "4px 0px 8px 0px",
  },
  root: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: `0px 0px 16px #00000014`,
    backgroundColor: "white",
    padding: "16px 0px 16px 16px",
    margin: "14px",
    height: `calc(100vh - 147px)`,
  },
  bottombtn: {
    padding: "12px 19px",
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #E4E8EE",
    position: "sticky",
    bottom: "0px",
    width: "100%",
    zIndex: 2,
    alignItems: "center",
  },
  drawerHeader: {
    padding: "12px 19px",
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #E4E8EE",
    position: "sticky",
    bottom: "0px",
    width: "100%",
    zIndex: 2,
    alignItems: "center",
    backgroundColor: "#F2F4F7",
  },
  drawerClearbtn: {
    fontFamily: SemiBold,
    color: "#5078E1",
    fontSize: "0.875rem",
    cursor: "pointer",
  },
  bottombtn2: {
    fontFamily: SemiBold,
    color: "#fff",
    backgroundColor: "#5078E1",
    "&:hover": {
      backgroundColor: "#5078E1",
    },
    height: "40px",
    "&.Mui-disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.12)", 
      color: "rgba(0, 0, 0, 0.26)", 
    },
  },
  drawerTitle: {
      color: "#091B29",
      fontSize: "1rem",
      fontFamily: ExtraBold,
  },
  img: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "16px ",
    // marginTop: "3px",
  },
  title: {
    color: theme?.typography?.status?.primary,
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  totalAmount: {
    color: theme?.typography?.color?.primary,
    fontSize:"1.125rem",
    fontFamily: FontFamilySwitch().bold,
    direction: "ltr",
    textAlign: "-webkit-match-parent"
  },
  btn: {
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    padding: "11px",
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
      color: theme?.palette?.background?.tertiary1,
    },
  },
  outLine: {
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.typography?.color?.primary,
    backgroundColor: "white",
    padding: "11px",
    border: `1px solid ${theme?.palette?.primary?.main}`,
    marginLeft: "10px",
    "&:hover": {
      color: theme?.typography?.color?.primary,
      backgroundColor: "white",
      border: `1px solid ${theme?.palette?.primary?.main}`,
    },
  },
  buttomCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 24px",
    borderRadius: "4px",
    backgroundColor: theme?.palette?.background?.secondary,
    marginRight: "16px",
  },
  amoutTilte: {
    color: theme?.palette?.pricingAppraisal?.tertiary,
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  amount: {
    color: theme?.palette?.pricingAppraisal?.tertiary1,
    fontSize: "1.25rem",
    fontFamily: FontFamilySwitch().bold,
  },
  cardBox: {
    padding: "18px 24px",
    backgroundColor: theme?.palette?.pricingAppraisal?.secondary,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  next: {
    fontFamily: FontFamilySwitch().bold,
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    padding: "11px",
    width: "100%",
    fontSize:"0.875rem",
    marginInlineStart: "10px",
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
  Cancel: {
    backgroundColor: "#FFFFFF ",
    color: "#091B29",
    fontSize:"0.875rem",
    padding: "11px",
    fontWeight: 600,
    width: "100%",
    fontFamily: FontFamilySwitch().bold,
    border: "1px solid #E4E8EE",
    "&:hover": {
      backgroundColor: "#FFFFFF ",
    },
  },
  btnParent: {
    padding: "20px 0px 0px",
    display: "flex",
    alignItems: "center",
  },
  parentDialog: {
    padding: "16px",
  },
  proceedDialog: {
    padding: "54px 50px 51px 228px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundImage: `url(${"images/announsment.svg"})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:theme?.palette?.primary?.main
  },
  proceedDialogArabic: {
    padding: "54px 50px 51px 228px",
    display: "flex",
    alignItems: "center",
    // justifyContent: "flex-end",
    backgroundImage: `url(${"images/announsment.svg"})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:theme?.palette?.primary?.main
  },
  proceedDialog1: {
    padding: "78px 50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundImage: `url(${"images/success.svg"})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:theme?.palette?.primary?.main
  },
  proceedDialog1Arabic: {
    padding: "78px 50px",
    display: "flex",
    alignItems: "center",
    // justifyContent: "flex-end",
    backgroundImage: `url(${"images/success.svg"})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:theme?.palette?.primary?.main
  },
  convert: {
    color: "white",
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().bold,
  },
  convertProcess: {
    color: "white",
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().regular,
    marginTop:'7px'
  },
  yes: {
    color: theme?.palette?.primary?.main,
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().bold,
    border: `1px solid white`,
    backgroundColor: "white",
    padding: "7px 29px",
    width: "100%",
    "&:hover": {
      border: `1px solid white`,
      backgroundColor: "white",
    },
  },
  No: {
    color: "white",
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    border: `1px solid white`,
    padding: "7px 29px",
    backgroundColor: theme?.palette?.primary?.main,
    marginInlineStart: "10px",
    width: "100%",
    "&:hover": {
      border: `1px solid white`,
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
  tableMain: {
    padding: "0px 16px !important",
  },
  select: {
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    padding: "11px",
    fontSize:"0.875rem",
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
  unSelect: {
    backgroundColor: "#F5F7FA ",
    color: "#7C8594",
    fontSize:"0.875rem",
    padding: "11px",
    fontWeight: 600,
    fontFamily: FontFamilySwitch().semiBold,
    border: "1px solid #F5F7FA",
    // borderRadius:"4px 0px 0px 4px",
    "&:hover": {
      backgroundColor: "#F5F7FA ",
    },
  },
  toggle:{
    display:'flex'
  },
  marginTop:{
    marginTop:"24px"
  },
  imgs: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "10px",
  },
}));
