import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Button, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods } from "../../utils";
import { From } from "./section";
import { proofComponentStyles } from "./styles";

const initial = {
    type: "",
    name: "",
    id_no: "",
    valid_from: new Date(),
    valid_to: "",
    issuing_country: "",
    mandatry_verify: "",
    file: [],
    is_active: true
};

export const ProofComponent = ({ update = () => false, file = [], company = false, t = () => false, isNotRequired = false, proofType }) => {
    const classes = proofComponentStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [data, setData] = React.useState(file?.length > 0 ? file : []);
    const [proof, setProof] = React.useState([]);
    const [choosen, setChoosen] = React.useState([]);
    const open = Boolean(anchorEl);

    //handle click
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    //handle add fields
    const handleAddFields = (type) => {
        setData([...data, { ...initial, type }]);
    };

    //update state
    const updateState = (key, value, index) => {
        let allProperty = [...data];
        if (key === "file") {
            allProperty[index] = {
                ...allProperty[index],
                file: [...allProperty[index]?.file, value],
            };
        } else {
            if (key === "valid_from") {
                allProperty[index] = {
                    ...allProperty[index],
                    [key]: value,
                    valid_to: (value > allProperty[index]?.valid_to) ? value : allProperty[index]?.valid_to
                };
            } else {
                allProperty[index] = {
                    ...allProperty[index],
                    [key]: value,
                };
            }
        }
        setData([...allProperty]);
    };

    //handle close menu
    const handleClose = () => {
        setAnchorEl(null);
    };

    //handle choose
    const onChoose = (type) => {
        if (choosen?.some((i) => i?.value === type?.value)) {
            setChoosen(choosen.filter((val) => val?.value !== type?.value));
            setData(data?.filter((i) => i?.type?.value !== type?.value));
        } else {
            setChoosen([...choosen, type]);
            handleAddFields(type);
        }
        handleClose();
    };

    //get identification proof
    const getIdentificationData = () => {
        const payload = {
            api: true,
            proof_type: [proofType],
        };
        NetworkCall(
            `${config.api_url}/queries/identification_master`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setProof(company ? response?.data?.data?.filter((item) => item.proof_type === "Company and Account") : response?.data?.data);
        }).catch((err) => {
            console.log(err);
        });
    };

    //on delete proof
    const onDeleteProof = (val) => {
        let allData = [...data];
        const index = allData.findIndex(item => item.id === val?.id);
        if (index !== -1) {
            // Set is_active to false
            allData[index].is_active = false;
            setData([...allData]);
        }
    };

    //initial load
    React.useEffect(() => {
        getIdentificationData("");
        if (file?.length > 0) {
            setChoosen(file?.map((val) => val?.type));
        }
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        update(data);
        // eslint-disable-next-line
    }, [data]);

    return (
        <Box>
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    <Typography className={classes.title}>{t("PROOF DETAILS")}</Typography>
                </Box>
                <Box>
                    <Button variant="contained" onClick={handleClick} className={classes.addtitle}>{t("Add New Proof Item")}</Button>
                    <Menu
                        className={classes.menuList}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        {proof?.map((val) => (
                            <MenuItem className={classes.menuItem} onClick={() => onChoose(val)}>
                                {choosen?.some((i) => i?.value === val?.value) ? (
                                    <CheckOutlinedIcon sx={{ fontSize: "1rem" }} />
                                ) : (
                                    ""
                                )} &nbsp;&nbsp;{val?.label}
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            </Box>
            {data?.filter((i) => i?.is_active)?.length > 0 &&
                data?.filter((i) => i?.is_active)?.map((val, index) => (
                    <Box key={`proof ${index}`} display="flex" alignItems="center">
                        <From
                            t={t}
                            data={val}
                            id={index}
                            onDeleteProof={onDeleteProof}
                            updateState={updateState}
                            company={company ? true : false}
                            isNotRequired={isNotRequired}
                        />
                        <IconButton onClick={() => onDeleteProof(val)}>
                            <DeleteOutlineIcon />
                        </IconButton>
                    </Box>
                ))}
        </Box>
    );
};