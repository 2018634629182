import React, { useEffect, useState } from "react";
import { useStyles } from "../styles";
import { Box, Button, Typography } from "@mui/material";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import {
  NetWorkCallMethods,
  AlertProps,
  LocalStorageKeys,
} from "../../../utils";
import { AlertContext, BackdropContext } from "../../../contexts";
import { Skeletons } from "../../skeleton";

export const CancelDialog = ({
  t,
  cancel = () => false,
  termination = () => false,
  agreement_id,
}) => {
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    getApprovalWorkflow();
  }, []);

  const alert = React.useContext(AlertContext);
  const [
    agreementEmergencycancellationLevels,
    setagreementEmergencyCancellationLevels,
  ] = useState(0);
  const [workflowId, setWorkflowId] = useState("");
  const [approvalStatus, setApprovalStatus] = useState([]);
  const backdrop = React.useContext(BackdropContext);
  const userProfileID = localStorage.getItem("profileID");

  const getApprovalWorkflow = () => {
    setLoading(true);
    const payload = { offset: 0, limit: 10 };

    NetworkCall(
      `${config.api_url}/approval_workflow/get_approval_workflows`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        if (res?.data?.data) {
          const workflows = res.data.data;

          // Get Quotation Approval workflow
          const agreementEmergencyCancellationApproval = workflows.find(
            (workflow) =>
              workflow.workflow_type ===
              "Agreement Emergency Cancellation Approval"
          );

          // Set states based on the found workflows
          if (agreementEmergencyCancellationApproval) {
            setagreementEmergencyCancellationLevels(
              agreementEmergencyCancellationApproval.no_of_levels
            );
            setWorkflowId(agreementEmergencyCancellationApproval.id);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleButtonClick = () => {
    const sendApproval = {
      agreement_id: agreement_id,
      workflow_id: workflowId,
      company_id: JSON.parse(
        localStorage.getItem(LocalStorageKeys.selectedCompany)
      )?.value,
      created_by: userProfileID,
      updated_by: userProfileID,
    };

    // Show the backdrop before making the API call
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: t("Loading..."),
    });

    NetworkCall(
      `${config.api_url}/approval_workflow/create_workflow_approval`,
      NetWorkCallMethods.post,
      sendApproval,
      null,
      true,
      false
    )
      .then((res) => {
        checkApporvalStatus();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Approval Request Has Been Sent"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        cancel();
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: err?.response?.data?.error?.message?.message,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      })
      .finally(() => {
        // Hide the backdrop after API request completion
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      });
  };

  const checkApporvalStatus = () => {
    setLoading(true);
    const payload = {
      agreement_id: agreement_id,
      workflow_id: workflowId,
    };
    NetworkCall(
      `${config.api_url}/approval_workflow/check_approval_status`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setApprovalStatus(res?.data?.data?.status);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (workflowId) {
      checkApporvalStatus();
    }
    // eslint-disable-next-line
  }, [workflowId]);

  const classes = useStyles();
  return (
    <div style={{ minHeight: "50px" }}>
      {loading ? (
        <Skeletons variant="rectangular" height={200} />
      ) : approvalStatus === "Approved" ||
        agreementEmergencycancellationLevels === "0" ? (
        // If status is "Approved" or level is 0, show the first part
        <>
          <Box className={classes.createnewsection}>
            <Box>
              <img src="\images\deletebig.svg" alt="close" />
            </Box>
            <Box>
              <Typography className={classes.modaltitle}>
                {t(
                  "Are you sure this will revert all the changes made and cancel the agreement?"
                )}
              </Typography>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt={2}
          >
            <Button
              variant="contained"
              onClick={termination}
              className={classes.btnSelction}
              style={{ padding: "16px" }}
            >
              {t("yes")}
            </Button>
            <Box width="16px" />
            <Button
              className={classes.Cancel}
              variant="outlined"
              onClick={cancel}
              style={{ padding: "16px" }}
            >
              {t("no")}
            </Button>
          </Box>
        </>
      ) : approvalStatus === "Rejected" ||
        agreementEmergencycancellationLevels !== "0" ? (
        loading ? (
          <Skeletons variant="rectangular" height={200} />
        ) : (
          <>
            <Box
              className={classes.createnewsection}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Box display="flex" alignItems="center">
                <img
                  src="/images/deletebig.svg"
                  alt="verify"
                  width="70"
                  height="70"
                />
                <Box width={5} />

                <Typography className={classes.modaltitle} mt={2}>
                  {t(
                    "To proceed with the emergency cancellation, kindly send the agreement for approval."
                  )}
                </Typography>
              </Box>

              <Box mt={2} pl={10} width="100%">
                <Typography textAlign="left">
                  {t(
                    "Note: This will revert all changes and cancel the agreement"
                  )}
                </Typography>
              </Box>

              <Button
                disabled={approvalStatus === "Pending"}
                onClick={() => {
                  handleButtonClick();
                }}
                variant="contained"
                className={classes.sendbtn}
                fullWidth
                sx={{ mt: 2 }}
              >
                {t("Send for Approval")}
              </Button>
            </Box>
          </>
        )
      ) : null}
    </div>
  );
};
