import { Box, Button, Grid, Stack } from '@mui/material'
import React from 'react'
import { Loader, SelectBox } from '../../../components'
import { CustomSelect } from '../../../components/filterGenerator/components'
import { config } from '../../../config'
import { NetworkCall } from '../../../networkcall'
import { NetWorkCallMethods } from '../../../utils'

const initialState = {
    company: "",
    property: "",
    error: {
        module: "",
        role: "",
        company: "",
        property: "",
        personname: "",
        email: "",
        phone: "",
        employeeID: "",
    },
}

export const InvitePopup = ({ t, selectedInvite = {}, inviteClick = () => false, contact_id = "", isDisableBtn = false }) => {
    const [companyState, setCompanyState] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [loading2, setLoading2] = React.useState(false);
    const [stateData, setStateData] = React.useState({ ...initialState });
    const loadOption = async (search = "", array, type, type1) => {
        setLoading(type);
        let result,
            query,
            offset = 0;

        if (search && !Boolean(array?.length)) {
            offset = 0;
        } else {
            offset = array?.length;
        }

        switch (type) {
            case "company":
                query = {
                    url: "/queries/company_master/list",
                    payload: {
                        offset, limit: 10, search
                    }
                }
                // GET_COMPANY(offset, 10, search, client).loc.source.body;
                result = await networkCallBack(query);
                setCompanyState(result?.all_company_master);
                return {
                    options: [...result?.company_master],
                    hasMore:
                        array?.length + result?.company_master?.length <
                        result?.count[0]?.count,
                };
            case "property":
                if (stateData?.company?.length === 0) {
                    setLoading(null);
                    return { options: [] };
                }
                query = {
                    url: "/queries/property/dropdown",
                    payload: {
                        company_ids:
                            stateData?.company?.[0]?.value === 0
                                ? companyState?.map((x) => x?.value)
                                : stateData?.company?.map((x) => x?.value),
                        offset,
                        limit: 10,
                        search,
                    }
                }
                result = await networkCallBack(query);
                return {
                    options: [...result],
                    hasMore:
                        array?.length + result?.length < result?.length,
                };
            default:
                return { options: [] };
        }
    };

    const networkCallBack = async (query) => {
        let payload = query.payload

        const options = await NetworkCall(
            `${config.api_url}${query?.url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                let main = response.data.data;
                setLoading(null);
                return main;
            })
            .catch((error) => {
                setLoading(null);
                // alert.setSnack({
                //       ...alert,
                //       open: true,
                //       severity: AlertProps.severity.error,
                //       msg: "Some Thing Went Wrong",
                //       vertical: AlertProps.vertical.top,
                //       horizontal: AlertProps.horizontal.center,
                // });
                return null;
            });

        return options;
    };

    const updateState = (key, value, type) => {
        let error = stateData?.error;
        error[key] = "";
        if (key === "module") {
            setStateData({ ...stateData, role: "", module: value, error });
        }
        else if (key === "company") {
            let Al = [];
            let ALL = [];
            if (value?.length > 0) {
                value.map((val) => {
                    if (val?.value === 0) {
                        setStateData({ ...stateData, company: ALL, property: [], error });
                    } else {
                        Al.push(val);
                        setStateData({ ...stateData, company: Al, property: [], error });
                    }
                    return 0;
                });
            } else {
                setStateData({ ...stateData, [key]: value, property: [], error });

            }
        } else if (key === "property") {
            let Al = [];
            let ALL = [];
            if (value?.length > 0) {
                value.map((val) => {
                    if (val?.value === 0) {
                        setStateData({ ...stateData, property: ALL, error });
                    } else {
                        Al.push(val);
                        setStateData({ ...stateData, property: Al, error });
                    }
                    return 0;
                });
            }
            else {
                setStateData({ ...stateData, [key]: value, error });
            }
        }
        else {
            setStateData({ ...stateData, [key]: value, error });
        }
    };
    const getEditData = (selectedInvite) => {
        setLoading2(true)
        const payload = {
            contact_id: contact_id,
            roles_id: selectedInvite?.id
        };
        NetworkCall(
            `${config.api_url}/invite/get_user_data`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((r) => {
            // let data = r?.data?.data ?? [];
            let company = r?.data?.data?.[0];
            let property = r?.data?.data?.map((val) => {
                return {
                    value: val?.property_id,
                    label: val?.property_name

                }
            })

            setStateData({
                ...stateData,
                company: [{
                    label: company?.company_name,
                    value: company?.company_id
                }],
                property: property
            })
            setLoading2(false)
        }).catch((error) => {
            console.log(error);
            setLoading2(false)

        })
    }


    React.useEffect(() => {
        if (selectedInvite?.is_invite === false) {
            getEditData(selectedInvite)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedInvite])
    return (
        <Box sx={{ height: "400px" }} p={2}>
            {loading2 ?
                <Loader message={"fetching data"} /> :
                <Stack justifyContent={"space-between"} sx={{ height: "100%" }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {/* {
                                selectedInvite?.name === "Security Guard" ? */}

                            <SelectBox
                                loadOptions={(search, array) =>
                                    loadOption(search, array, "company", "custom")
                                }
                                isPaginate={true}
                                borderRadius={false}
                                noBorder={false}
                                placeholder={"Select Company"}
                                label="Company"
                                value={stateData?.company[0] ?? ""}
                                isError={stateData?.error?.company?.length > 0}
                                errorMessage={stateData?.error?.company}
                                onChange={(value) => updateState("company", [value], "custom")}
                                menuPlacement="bottom"
                            />
                        </Grid >
                        <Grid item xs={12}>
                            {
                                selectedInvite?.name === "Security Guard" ?

                                    <SelectBox
                                        loadOptions={(search, array) =>
                                            loadOption(search, array, "property", "custom")
                                        }
                                        isPaginate={true}
                                        borderRadius={false}
                                        noBorder={false}
                                        placeholder={t("Select Property")}
                                        key={JSON.stringify(stateData?.company)}
                                        label={t("Property")}
                                        value={stateData?.property[0] ?? ""}
                                        isError={stateData?.error?.company?.length > 0}
                                        errorMessage={stateData?.error?.company}
                                        onChange={(value) => updateState("property", [value], "custom")}
                                        menuPlacement="bottom"
                                        maxMenuHeight="200px"

                                    />
                                    :
                                    <CustomSelect
                                        loadOptions={(search, array) =>
                                            loadOption(search, array, "property")
                                        }
                                        isMulti
                                        isPaginate={true}
                                        borderRadius={false}
                                        noBorder={false}
                                        placeholder={t("Select Property")}
                                        key={JSON.stringify(stateData?.company)}
                                        label={t("Property")}
                                        value={stateData?.property}
                                        isError={stateData?.error?.property?.length > 0}
                                        errorMessage={stateData?.error?.property}
                                        onChange={(value) => updateState("property", value, "")}
                                        menuPlacement="bottom"
                                        maxMenuHeight="200px"
                                        debounceTimeout={800}
                                        loading={loading === "property"}
                                    />
                            }
                        </Grid>
                    </Grid >
                    <Button variant='contained' onClick={() => inviteClick({ selectedInvite, stateData })} disabled={!isDisableBtn ? ((stateData?.company?.length > 0 && stateData?.property?.length > 0) ? false : true) : isDisableBtn}
                        fullWidth>{t("Invite")}
                    </Button>
                </Stack >
            }
        </Box >
    )
}