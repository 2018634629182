import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import React from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { LoadingSection } from "../../loadingSection";
import { useStyles } from "../styles";
import { SelectedListCard } from "./selectedListCard";
import { UnitCard } from "./unitCard";
import { withTranslation } from 'react-i18next';

const Details = ({ t, list = [], selected = {}, onRemove = () => false, checkUnit = () => false, onSearch = () => false, fetchMoreData = () => false, loading = false, searchText = "", height = null }) => {
    const classes = useStyles({ height });


    return (
        <Box className={classes.detailRoot}>
            <SelectedListCard data={selected} onRemove={onRemove} />
            <Box p={1}>
                <TextField
                    placeholder={t("Search Units")}
                    size="small"
                    fullWidth
                    onChange={(e) => onSearch(e.target.value)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchOutlinedIcon /></InputAdornment>
                    }}
                    value={searchText}
                />
            </Box>
            {
                loading ?
                    <Box height={height ?? "200px"}>
                        <LoadingSection message='Fetching ...' top="10vh" />
                    </Box>
                    :
                    <Box>
                        <Box height="8px" />
                        {
                            list?.length > 0 ?
                                <InfiniteScroll
                                    dataLength={list.length}
                                    next={fetchMoreData}
                                    hasMore={true}
                                    height={height ?? `calc(100vh - 599px)`}
                                >
                                    {
                                        list?.map((val) => {
                                            return (
                                                <UnitCard t={t} data={val} onClick={checkUnit} />
                                            )
                                        })
                                    }
                                </InfiniteScroll>
                                :
                                <Box className={classes.heightBody}>
                                    <Typography textAlign="center" className={classes.name}>{t("No Data Found")}</Typography>
                                </Box>

                        }

                    </Box>
            }

        </Box>
    )
}
export default withTranslation("createOpportunity")(Details)

