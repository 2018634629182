import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ShowMoreText from "react-show-more-text";
import { FormGenerator, Stepper } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, enumSelect, enum_types, LocalStorageKeys, NetWorkCallMethods, useWindowDimensions, concat_string } from "../../../utils";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";
import { MapSource } from "./commom";
import { LeadStylesParent } from "./style";

export const AddActivity = (props) => {
  const { t } = (props)
  // classes
  const classes = LeadStylesParent(props);
  const alert = React.useContext(AlertContext);
  const company = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))

  // state
  const [step, setStep] = React.useState(1);

  const [enumValue, setEnumValue] = useState({
    priority: [],
    // purpose: [],
    followUp: [],
  });
  const [disable, setDisable] = useState(false);

  const size = useWindowDimensions();

  const steps = [
    {
      label: t("Activity Details"),
      step: 1,
      value: step,
    },
    {
      label: t("Preview and Submit"),
      step: 2,
      value: step,
    },
  ];

  //on previous
  const previous = () => {
    setStep(step - 1);
  };
  //on next
  const next = () => {
    if (props?.validate()) {
      setStep(step + 1);
    }
  };

  const getEnum = async () => {
    const result = await enumSelect([
      // enum_types?.purpose_global_type,
      enum_types?.urgent_type,
      enum_types?.follow_up_type,
    ]);
    setEnumValue({
      priority: result?.urgent_type ?? [],
      // purpose: result?.purpose_global_type ?? [],
      followUp: result?.follow_up_type,
    });
  };
  const save = () => {
    setDisable(true)
    const activity = {
      activity_category: props?.state?.Category?.value,
      notes: props?.state?.Notes,
      priority: props?.state?.Priority,
      email_id: props?.state?.email ?? undefined,
      type:
        props?.state?.Followup?.value?.length > 0
          ? props?.state?.Followup?.value
          : undefined,
      lead_id: props?.proxy_lead_id ?? undefined,
      phone_number: props?.state?.mobile?.mobile ?? undefined,
      location: props?.state?.location ?? undefined,
      date_time: moment(props?.state?.visitorDate).format("YYYY-MM-DD HH:mm:ss") ?? undefined,
      phone_code: props?.state?.mobile?.mobile_code ?? undefined,
      // purpose: props?.state?.Purpose?.value,
      lead_activitiy_master: props?.type?.id,
      company_id: company?.value ?? undefined,
      calendar_event_params: {
        subject: "PropGOTO - "
          + concat_string(
            {
              category_name: props?.state?.Category?.label,
              reference_name: props?.opportunity_data?.contact?.first_name,
              reference_no: props?.opportunity_data?.lead_no,
            },
            ["category_name", "reference_name", "reference_no"],
            ", "
          )
          + (props?.state?.Priority?.length > 0 ? ` - (${props?.state?.Priority?.charAt(0).toUpperCase()}${props?.state?.Priority?.slice(1)})` : ``),
        notes: props?.state?.Notes?.length > 0 ? props?.state?.Notes : "",
        start: {
          dateTime: moment(props?.state?.visitorDate).format("YYYY-MM-DDTHH:mm:ss"),
          timeZone: company?.timezone?.length > 0 ? company.timezone : "UTC",
        },
        end: {
          dateTime: moment(props?.state?.visitorDate).add(30, "m").format("YYYY-MM-DDTHH:mm:ss"),
          timeZone: company?.timezone?.length > 0 ? company.timezone : "UTC",
        },
      },
    };

    if (props?.state?.isEdit) {
      activity["id"] = props?.state?.id;
      activity["updated_by"] = localStorage.getItem("profileID");
    } else {
      activity["created_by"] = localStorage.getItem("profileID");
      activity["status"] = "Open";
    }

    NetworkCall(
      `${config.api_url}/lead/activity`,
      NetWorkCallMethods.post,
      {
        activity,
      },
      null,
      true,
      false
    )
      .then((response) => {
        props?.cancel();
        props?.getActivityList(0, 10);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: `Activity ${props?.data?.isEdit === true ? "Updated" : "Created"
            } successfully.`,
        });
      })
      .catch((error) => {
        setDisable(false)

        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
      });
  };

  useEffect(() => {
    getEnum();
  }, []);

  const components = {
    1: (
      <Step1
        state={props?.state}
        enumValue={enumValue}
        updateState={props?.updateState}
        type={props?.type}
        t={t}
      />
    ),
    2: <Step2 t={t} type={props?.type} state={props?.state} />,
  };

  return (
    <div>
      <div
        style={{
          height: size?.height - 370,
          textAlign: "left",
          overflow: "auto",
        }}
      >
        <Stepper t={t} step={step} steps={steps} type={props?.type} />
        {components[step]}
      </div>
      <Box className={classes.bottomButton}>
        <Box>
          <Button
            className={classes.cancel}
            onClick={previous}
            disabled={step <= 1 ? true : false}
          >
            {t("previous")}
          </Button>
          {step === 2 ? (
            <Button className={classes.next} onClick={save} disabled={disable}>
              {props?.state?.isEdit ? t("Update") : t("Submit")}
            </Button>
          ) : (
            <Button className={classes.next} onClick={next}>
              {t("Next")}
            </Button>
          )}
        </Box>
      </Box>
    </div>
  );
};

const Step1 = (props) => {
  const { t } = props
  const company = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))
  return (
    <div style={{ padding: "24px" }}>
      <FormGenerator t={t}
        components={[
          {
            isActive: true,
            component: "select",
            label: t("Category"),
            value: props?.state?.Category,
            placeholder: t("Category"),
            onChange: (value) => props?.updateState("Category", value),
            error: props?.state?.error?.Category,
            isRequired: true,
            size: {
              xs: 12,
              sm: 12,
              md: 6,
              lg: 6,
            },
            isPaginate: true,
            loadOptions: (search, array, handleLoading) =>
              loadOptionsApis(
                "queries/opportunity/activity_category_master",
                {
                  company: company?.value,
                  lead_activity_master_id: props?.type?.id
                },
                search,
                array,
                handleLoading,
                "data",
                {},
              ),
            menuOptionHeight: "150px"
          },
          {
            isActive: props?.type?.name === "Phone call" ? true : false,
            component: "mobile",
            label: t("Phone Number"),
            value: props?.state?.mobile,
            placeholder: t("Phone Number"),
            onChange: (value) => props?.updateState("mobile", value),
            error: props?.state?.error?.mobile,
            isRequired: true,
            size: {
              xs: 12,
              sm: 12,
              md: 6,
              lg: 6,
            },
          },
          {
            isActive:
              props?.type?.name === "Follow up" ||
                props?.type?.name === "Meeting" ||
                props?.type?.name === "Appointment"
                ? true
                : false,
            component: "select",
            label: t("Followup Type"),
            value: props?.state?.Followup,
            placeholder: t("Followup"),
            onChange: (value) => props?.updateState("Followup", value),
            error: props?.state?.error?.Followup,
            isRequired: true,
            size: {
              xs: 12,
              sm: 12,
              md: 6,
              lg: 6,
            },
            options: props?.enumValue?.followUp ?? [],
          },
          {
            isActive:
              props?.state?.Followup?.value === "In-Person" ? true : false,
            component: "text",
            label: t("Location"),
            value: props?.state?.location,
            placeholder: t("Location"),
            onChange: (value) =>
              props?.updateState("location", value?.target?.value),
            error: props?.state?.error?.location,
            isRequired: true,
            size: {
              xs: 12,
              sm: 12,
              md: 6,
              lg: 6,
            },
          },
          {
            isActive:
              props?.type?.name === "Send Email" ||
                props?.type?.name === "Send Quotation"
                ? true
                : false,
            component: "text",
            label: t("Email"),
            value: props?.state?.email,
            placeholder: t("Email"),
            onChange: (value) =>
              props?.updateState("email", value?.target?.value),
            error: props?.state?.error?.email,
            isRequired: true,
            size: {
              xs: 12,
              sm: 12,
              md: 6,
              lg: 6,
            },
          },
          {
            isActive: true,
            component: "newDatePickerAutoTime",
            label: t("Call Date & Time"),
            value: props?.state?.visitorDate,
            placeholder: t("Select Visit Date & Time"),
            onChange: (value) => props?.updateState("visitorDate", value),
            error: props?.state?.error?.visitorDate,
            minDate: new Date(),
            isRequired: true,
            size: {
              xs: 12,
              sm: 12,
              md: 6,
              lg: 6,
            },
          },
          {
            size: {
              xs: 12,
              sm: 6,
              md: 6,
              lg: 6,
            },
            isActive: true,
            component: "toggle",
            label: t("Priority"),
            value: props?.state?.Priority,
            placeholder: t("Priority"),
            onChange: (value) => props?.updateState("Priority", value),
            error: props?.state?.error?.Priority,
            isRequired: true,
            options: props?.enumValue?.priority ?? [],
          },
          {
            isActive: true,
            component: "text",
            label: t("Notes"),
            value: props?.state?.Notes,
            placeholder: t("Notes"),
            onChange: (value) =>
              props?.updateState("Notes", value?.target?.value),
            error: props?.state?.error?.Notes,
            isRequired: true,
            multiline: true,
            size: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
            },
          },
        ]}
      />
    </div>
  );
};

const Step2 = (props) => {
  // classes
  const { t } = props
  const previewType = MapSource[props?.type?.name];
  const classes = LeadStylesParent();

  return (
    <div style={{ padding: "24px" }}>
      <Box
        className={classes.typeCard}
        style={{ backgroundImage: `url(${previewType?.background})` }}
      >
        <img src={previewType?.image} alt={previewType?.text} />
        <Typography className={classes.typeName}>
          {previewType?.text}
        </Typography>
      </Box>
      <Grid container spacing={1} mb={2}>
        <Grid item xs={6} sm={3}>
          <Stack>
            <Typography className={classes.title}>{t("CATEGORY")}</Typography>
            <Typography className={classes.sub}>
              {props?.state?.Category?.label}
            </Typography>
          </Stack>
        </Grid>
        {props?.state?.visitorDate?.length !== 0 &&
          <Grid item xs={6} sm={3}>
            <Stack>
              <Typography className={classes.title}>START DATE</Typography>
              <Typography className={classes.sub}>
                {moment(props?.state?.visitorDate).format("DD MMM YY hh:mm")}
              </Typography>
            </Stack>
          </Grid>}
        {
          props?.state?.mobile?.mobile_code?.length > 0 &&
          <Grid item xs={6} sm={3}>
            <Stack>
              <Typography className={classes.title}>{t("PHONE NUMBER")}</Typography>
              <Typography className={classes.sub}>
                {props?.state?.mobile?.mobile_code} -{" "}
                {props?.state?.mobile?.mobile}
              </Typography>
            </Stack>
          </Grid>
        }


        {props?.state?.email?.length > 0 && (
          <Grid item xs={6} sm={3}>
            <Stack>
              <Typography className={classes.title}>{t("EMAIL ID")}</Typography>
              <Typography className={classes.sub}>
                {props?.state?.email}
              </Typography>
            </Stack>
          </Grid>
        )}
        {props?.state?.location?.length > 0 && (
          <Grid item xs={6} sm={3}>
            <Stack>
              <Typography className={classes.title}>{t("LOCATION")}</Typography>
              <Typography className={classes.sub}>
                {props?.state?.location}
              </Typography>
            </Stack>
          </Grid>
        )}
        {
          props?.state?.Followup?.value && (
            <Grid item xs={6} sm={3}>
              <Stack>
                <Typography className={classes.title}>{t("FOLLOW UP")}</Typography>
                <Typography className={classes.sub}>
                  {props?.state?.Followup?.value}
                </Typography>
              </Stack>
            </Grid>
          )}

        <Grid item xs={6} sm={3}>
          <Stack>
            <Typography className={classes.title}>{t("PRIORITY")}</Typography>
            <Typography className={classes.sub}>
              {props?.state?.Priority}
            </Typography>
          </Stack>
        </Grid>


      </Grid>
      <Box className={classes.margin}>
        <Divider />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.title}>{t("NOTE")}</Typography>
          <ShowMoreText
            lines={2}
            more="Show More"
            less="Show Less"
            className={classes.sub}
            anchorClass={classes.seeMoreLessTextStyle}
            expanded={false}
            truncatedEndingComponent={"... "}
          >
            <Typography className={classes.sub}>
              {props?.state?.Notes ?? ""}
            </Typography>
          </ShowMoreText>
        </Grid>
      </Grid>
    </div >
  );
};
