import { Badge, Button, IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import styled from "@mui/material/styles/styled";
import React from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FilterIMG from "../../assets/filter";
import {
  DialogBox,
  FilterGenerator,
  LoadingSection,
  SearchFilter,
  Subheader,
  TableWithPagination,
  UseDebounce,
} from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import {
  AlertProps,
  InvoiceHeading,
  InvoicePath,
  NetWorkCallMethods,
  accessCheckRender,
  getCompanyOption,
  getRoutePermissionNew,
} from "../../utils";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { InvoiceStyles } from "./style";

const CustomPaper = styled("div")(({ theme }) => ({
  boxShadow: "0px 0px 16px #00000014",
  borderRadius: theme.palette.borderRadius,
  backgroundColor: "#ffffff",
  padding: theme.spacing(2),
  margin: theme.spacing(3),
}));

const Invoice = ({ t }) => {
  const classes = InvoiceStyles();
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const auth = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [sucessDialog, setSucessDialog] = React.useState(false);
  const [sucessDisableBtn, setSucessDisableBtn] = React.useState(false);

  const [companyList, setCompanyList] = React.useState([]);
  const [dialogData, setDialogData] = React.useState({});
  const [selectedCompany, setSelectedCompany] = React.useState({});
  const [InvoiceList, setInvoiceList] = React.useState({
    data: [],
    totalRowsCount: 0,
    is_configured: false,
  });
  const [searchText, setSearchText] = React.useState("");
  const [drawer, setDrawer] = React.useState(null);
  const [filterData, setFilterData] = React.useState({
    status: [true],
    contact: [],
    payment_status: ["no-due", "due"],
    classification: ["AR"],
  });
  const [loading, setLoading] = React.useState(true);
  // const clientId = localStorage.getItem(LocalStorageKeys.clinetID)
  const [permission, setPermission] = React.useState({});
  const [companyPermission, setCompanyPermission] = React.useState(false);

  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");
  const limitParam = searchParams.get("limit");

  // Default values if not set in URL
  const pageFromParams = pageParam ? parseInt(pageParam, 10) : 1;
  const limitFromParams = limitParam ? parseInt(limitParam, 10) : 10;

  const [page, setPage] = React.useState(pageFromParams);
  const [limit, setLimit] = React.useState(limitFromParams);

  const updatePage = (newPage, newLimit = limit) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", newPage.toString());
    searchParams.set("limit", newLimit.toString());

    // Update URL without reloading
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });

    // Update state
    setPage(newPage);
    setLimit(newLimit);
  };

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          setCompanyList(company?.list);
          setSelectedCompany(company?.selected);
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  const draweropen = () => {
    setDrawer(true);
  };
  const drawerclose = () => {
    setDrawer(false);
  };
  const debounce = UseDebounce();

  //handle pagination
  const handlePagination = (newPage) => {
    updatePage(newPage, limit);
  };

  const handleChangeLimit = (newLimit) => {
    updatePage(1, newLimit); // Reset page to 1 when limit changes
  };

  const handleSearch = (e) => {
    setSearchText(e);
    debounce(() => searchTableFunction(e), 800);
  };
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    InvoiceTableData(0, limit, e, filterData);
  };

  //table listing function
  const InvoiceTableData = async (
    offset = 0,
    limit = 10,
    search = "",
    filterValue
  ) => {
    let company_id = [selectedCompany?.value];
    let is_active = (filterValue?.status?.length > 0
      ? filterValue?.status
      : [true, false]) ?? [true];

    const payload = {
      offset: offset,
      limit: limit,
      search: search,
      company_id,
      is_active,
      contact:
        filterValue?.contact?.length > 0
          ? filterValue?.contact?.map((x) => x?.value)
          : undefined,
      payment_status:
        filterValue?.payment_status?.length > 0
          ? filterValue?.payment_status
          : undefined,
      payment_type:
        filterValue?.classification?.length > 0
          ? filterValue?.classification
          : undefined,
    };

    NetworkCall(
      `${config.api_url}/invoice/agreement_invoice`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setInvoiceList({
          data: response?.data?.invoice,
          totalRowsCount: response?.data?.count?.[0]?.count,
          is_configured: response?.data?.is_configured,
        });
        setLoading(false);
        backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
      })
      .catch((err) => {
        console.log(err);
      });

    // const payload = {
    //     query: GET_INVOICE_TABLE_LIST(clientId, offset, limit, search, companyID, is_active).loc.source.body,

    // };
  };
  // is active changing function
  const changeactive = async (data, value) => {
    const payload = {
      id: data,
      update: {
        is_active: value === "Inactive" ? true : false,
      },
    };
    NetworkCall(
      `${config.api_url}/queries/amenities_type_master/update`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((rs) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Status Updated"),
        });
        InvoiceTableData();
        console.log(rs);
      })
      .catch((er) => {
        console.log(er);
      });
  };
  //delete function
  const deleteTableData = async (data) => {
    const payload = {
      id: data,
      update: {
        is_delete: true,
      },
    };
    NetworkCall(
      `${config.api_url}/queries/amenities_type_master/update`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((rs) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Deleted"),
        });
        InvoiceTableData();
        console.log(rs);
      })
      .catch((er) => {
        console.log(er);
      });
  };
  //table row constructions
  const InvoiceRow = InvoiceList?.data?.map((val, index) => {
    let _d;
    try {
      _d = {
        index: (page - 1) * limit + index + 1,
        invoiceNumber: val?.invoice_no ?? " - ",
        erpInvoiceNumber: val?.erp_invoice_no ?? " - ",
        accountID: val?.account_no ?? " - ",
        accountName: val?.contact_account?.name ?? " - ",
        invoiceAmount:
          `${selectedCompany?.currency_symbol} ${val?.invoice_total_amount}` ??
          " - ",
        invoiceType: val?.invoice_type ?? " - ",
        generatedDate: val?.created_at ?? " - ",
        dueDate: val?.payment_due_date ?? " - ",
        balance:
          `${selectedCompany?.currency_symbol} ${val?.invoice_due_amount}` ??
          " - ",
        status: val?.payment_status === "no-due" ? "Paid" : "Unpaid",
        agreementNumber: val?.agreement?.agreement_no ?? "-",
        icon: "more",
        id: val?.id,
        ...val,
      };
    } catch (err) {}
    return _d;
  });

  const currentOffset = (page - 1) * limit;
  React.useEffect(() => {
    if (selectedCompany?.value) {
      InvoiceTableData(currentOffset, limit, "", filterData);
    }
    //eslint-disable-next-line
  }, [selectedCompany]);

  const handleIcon = (type, data, status) => {
    if (type === "view") {
      navigate(Routes.invoiceView, {
        state: data?.id,
      });
    } else if (type === "edit") {
    } else if (type === "active") {
      changeactive(data.id, status);
    } else if (type === "delete") {
      deleteTableData(data.id, data.IsDelete);
    }
  };

  //apply filter
  const onApplyFilter = (value) => {
    setFilterData(value);
    if (value?.status?.length > 0 && value?.payment_status?.length > 0) {
      InvoiceTableData(0, 10, "", value);
    } else {
      setDrawer(true);
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg:
          (value?.status?.length === 0 || value?.status === undefined) &&
          (value?.payment_status?.length === 0 ||
            value?.payment_status === undefined)
            ? t(
                "Please choose atleast one filter for status and payment status"
              )
            : value?.status?.length === 0 || value?.status === undefined
            ? t("Please choose atleast one filter for status")
            : value?.payment_status?.length === 0 ||
              value?.payment_status === undefined
            ? t("Please choose atleast one filter for payment status")
            : t(
                "Please choose atleast one filter for status and payment status"
              ),
      });
    }
  };
  const handleSubmit = (val) => {
    setSucessDisableBtn(true);
    let datas = {
      id: val?.id,
    };
    NetworkCall(
      `${config.api_url}/invoice/convert_invoice`,
      NetWorkCallMethods.post,
      datas,
      null,
      true,
      false
    )
      .then((res) => {
        setSucessDisableBtn(false);
        setSucessDialog(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Converted Successfully"),
        });
        InvoiceTableData();
      })
      .catch((error) => {
        setSucessDisableBtn(false);
        setSucessDialog(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
      });
  };

  const handleOpen = (val) => {
    setDialogData(val);
    setSucessDialog(true);
  };

  //delete popup
  const Proceed = (props) => {
    return (
      <div className={classes.proceedDialog}>
        <Box>
          <Typography className={classes.convert}>{props?.title}</Typography>
          <Typography className={classes.convertProcess}>
            {props?.sub}
          </Typography>
          <Box display="flex" alignItems="center" className={classes.marginTop}>
            <Button
              className={classes.yes}
              disabled={sucessDisableBtn}
              onClick={() => handleSubmit(props?.val)}
            >
              {t("Yes")}
            </Button>
            <Button
              className={classes.No}
              onClick={() => setSucessDialog(false)}
            >
              {t("No")}
            </Button>
          </Box>
        </Box>
      </div>
    );
  };
  const manualResponse = (array) =>
    array?.contact?.map((_) => {
      return {
        ..._,
        label: _?.label,
        value: _?.id,
      };
    });

  // check company permission
  const fetchPermissions = async (companyIds) => {
    try {
      const response = await NetworkCall(
        `${config.api_url}/check-company-permission/check_company_permissions`,
        NetWorkCallMethods.post,
        { company_ids: companyIds }, 
        null,
        true,
        false
      );
      if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
        const data = response.data.data;
        return data.filter(company => company.is_active);
      }
    } catch (error) {
      console.error("Network call error:", error);
    }
  };

  React.useEffect(() => {
    const checkPermissions = async () => {
      if (selectedCompany?.value) {
        const permissions = await fetchPermissions([selectedCompany.value]);
        if (permissions.length > 0) {
          setCompanyPermission(true);
        } else {
          setCompanyPermission(false);
        }
      }
    };
    checkPermissions();
  }, [selectedCompany]);

  const render = () => {
    return (
      <>
        {loading ? (
          <LoadingSection
            bottom={"45vh"}
            message={t("Loading Invoice Listing...")}
          />
        ) : (
          <>
            <div>
              <Subheader
                title={t("Invoice")}
                count={false}
                hideBackButton
                select
                options={companyList}
                value={selectedCompany}
                onchange={
                  (e) => setSelectedCompany(e)
                  // getProperty(e)
                }
              />

              <CustomPaper>
                <Grid container className={classes.content} spacing={2}>
                  <Grid item xs={4}>
                    <SearchFilter
                      placeholder={t("Search by invoice number")}
                      value={searchText}
                      handleChange={(value) => handleSearch(value)}
                      customfieldSx={{
                        "& .MuiOutlinedInput-root": { height: "40px" },
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    textAlign={"right"}
                    justifyContent={"end"}
                    display={"flex"}
                  >
                    <Box display={"flex"} sx={{ float: "right" }}>
                      <Box marginRight={"12px"}>
                        {filterData.status?.length > 0 ||
                        filterData.property_type?.length > 0 ? (
                          <IconButton
                            onClick={draweropen}
                            className={classes.img}
                          >
                            <Badge variant="dot" color="primary">
                              <FilterIMG color="#091b29" />
                            </Badge>
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={draweropen}
                            className={classes.img}
                          >
                            <FilterIMG color="#091b29" />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <TableWithPagination
                      heading={InvoiceHeading(t, InvoiceList?.is_configured, companyPermission)}
                      rows={InvoiceRow?.length ? InvoiceRow : []}
                      path={InvoicePath(companyPermission)}
                      showpagination={true}
                      tableType="no-side"
                      handleIcon={handleIcon}
                      onClick={() => null}
                      showpdfbtn={false}
                      showexcelbtn={false}
                      showSearch={false}
                      dataType={[
                        { type: ["text"], name: "invoiceType" },
                        { type: ["date"], name: "generatedDate" },
                        { type: ["text"], name: "invoiceNumber" },
                        companyPermission && { type: ["text"], name: "erpInvoiceNumber" },
                        { type: ["text"], name: "accountID" },
                        { type: ["text"], name: "accountName" },
                        { type: ["text"], name: "agreementNumber" },
                        { type: ["text"], name: "invoiceAmount" },
                        { type: ["date"], name: "dueDate" },
                        { type: ["status"], name: "status" },
                        { type: ["text"], name: "balance" },
                        InvoiceList?.is_configured && {
                          type: ["submit_button"],
                          name: "submitted_on",
                        },
                      ].filter(Boolean)}
                      handlePagination={handlePagination}
                      handleChangeLimit={handleChangeLimit}
                      handleSubmit={handleOpen}
                      totalRowsCount={InvoiceList?.totalRowsCount}
                      page={page}
                      limit={limit}
                      height={`calc(100vh -  290px)`}
                      view={permission?.read}
                      edit={permission?.update}
                      delete={permission?.delete}
                    />
                  </Grid>
                </Grid>
              </CustomPaper>
            </div>

            {/* filter drawer */}
            {drawer && (
              <FilterGenerator
                open={drawer}
                onClose={drawerclose}
                components={[
                  {
                    component: "toggleButton",
                    value: filterData?.status,
                    options: [
                      { label: t("Active"), value: true },
                      { label: t("Inactive"), value: false },
                    ],
                    isMulti: true,
                    state_name: "status",
                    label: t("Status"),
                    // required:true
                  },
                  {
                    component: "toggleButton",
                    value: filterData?.payment_status,
                    options: [
                      { label: t("Paid"), value: "no-due" },
                      { label: t("UnPaid"), value: "due" },
                    ],
                    isMulti: true,
                    state_name: "payment_status",
                    label: t("Payment Status"),
                    // required:true
                  },
                  {
                    isActive: true,
                    component: "select",
                    label: t("Choose Contact"),
                    value: filterData?.contact,
                    placeholder: t("Select Contact"),
                    isRequired: true,
                    isPaginate: true,
                    isMulti: true,
                    state_name: "contact",
                    loadOptions: (search, array, handleLoading) =>
                      loadOptionsApis(
                        "queries/opportunity/contact/dropdown",
                        {
                          company_id: selectedCompany?.value,
                        },
                        search,
                        array,
                        handleLoading,
                        "data",
                        {},
                        manualResponse
                      ),
                  },
                  {
                    component: "toggleButton",
                    value: filterData?.classification,
                    options: [
                      {
                        value: "AR",
                        label: "AR",
                      },
                      {
                        value: "AP",
                        label: "AP",
                      },
                    ],
                    isMulti: true,
                    state_name: "classification",
                    label: t("Classification"),
                  },
                ]}
                onApply={(value) => onApplyFilter(value)}
              />
            )}
            {/* sucess dialog */}
            <DialogBox
              open={sucessDialog}
              isnotTitle
              handleClose={() => setSucessDialog(false)}
              onClose={() => setSucessDialog(false)}
              maxWidth={"sm"}
              component={
                <Proceed
                  t={t}
                  val={dialogData}
                  title={t("Are you sure want to Submit...?")}
                  noClick={() => setSucessDialog(false)}
                />
              }
            />
          </>
        )}
      </>
    );
  };

  return <>{accessCheckRender(render, permission)}</>;
};

export default withTranslation("invoice")(Invoice);
