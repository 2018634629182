import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../utils";
export const AggrementTabStyles = makeStyles((theme) => ({
  pdfSection: {
    // padding: "12px",
    height: "calc(100vh - 250px)",
    overflow: "auto",
    backgroundColor: "#F5F7FA",
  },
  title: {
    color: "#4E5A6B",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    textTransform: "capitalize",
  },
  summarytitle: {
    color: "#4E5A6B",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    textTransform: "capitalize",
  },
  summarytitle1: {
    color: "#4E5A6B",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
  },
  root: {
    padding: "16px",
    border: "1px solid #E4E8EE",
    borderRadius: "4px",
    backgroundColor: "#fff",
  },
  sub: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.primary,
    marginTop: "6px",
  },
  sub1: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.primary,
  },
  dot: {
    backgroundColor: "#E4E8EE",
    borderRadius: "50%",
    width: "10px",
    height: "10px",
  },
  account_root: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    borderRadius: "4px",
    backgroundColor: "#fff",
    margin: "2px",
  },
  selected_account_root: {
    padding: "12px",
    border: "1px solid #5078E1",
    borderRadius: "4px",
    backgroundColor: "#F1F7FF",
    margin: "2px",

    "& p": {
      color: "#5078E1",
      fontSize: "0.875rem !important",
    pdfSection: {
        height: "calc(100vh - 250px)",
        overflow: "auto",
        backgroundColor: "#F5F7FA",
    },
    title: {
        color: "#4E5A6B",
        fontSize:"1rem",
        fontFamily: FontFamilySwitch().bold,
        textTransform: "capitalize"
    },
    summarytitle:{
        color: "#4E5A6B",
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        textTransform: "capitalize"
    },
    summarytitle1:{
        color: "#4E5A6B",
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
      },
    root:{
        padding: "16px",
        border: "1px solid #E4E8EE",
        borderRadius: "4px",
        backgroundColor: "#fff"
    },
    sub: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.primary,
        marginTop: "6px"
    },
    sub1: {
        fontSize:"1rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.primary,
    },
    dot:{
        backgroundColor:"#E4E8EE",
        borderRadius:"50%",
        width:"10px",
        height:"10px"
    },
    account_root:{
        padding: "12px",
        border: "1px solid #E4E8EE",
        borderRadius: "4px",
        backgroundColor: "#fff",
        margin:"2px",
    },
    selected_account_root:{
        padding: "12px",
        border: "1px solid #5078E1",
        borderRadius: "4px",
        backgroundColor: "#F1F7FF",
        margin:"2px",
       
        "& p":{
            color:"#5078E1",
            fontSize:"0.875rem !important",
        }
    },
    account_sub: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.primary,
    },
  },
  account_sub: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.primary,
  },
},}));

export const TermsAndConditionStyle = makeStyles((theme) => ({
  reactQuil: {
    "& .ql-toolbar.ql-snow": {
      borderRadius: "8px 8px 0px 0px",
    },
    "& .ql-container.ql-snow": {
      borderRadius: "0px 0px 8px 8px",
    },
    "& .ql-editor": {
      padding: "0px 0px 16px 0px",
      textAlign: "start",
      fontSize: "0.875rem",
      fontFamily: FontFamilySwitch().bold,
      color: "#091B29",
      height: "calc(100vh - 400px)",
    },
  },
  createBox: {
    width: "100%",
    // height: "calc(100vh - 350px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  createBoxText: {
    fontSize: "0.875rem",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  createBoxButton: {
    height: "40px",
    width: "74px",
    borderRadius: 4,
  },
  title: {
    fontSize: "1rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
  },
  createSaveBtn: {
    height: "40px",
    borderRadius: "4px",
  },
  editBtn: {
    height: "40px",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  contentBox: {
    // height: "calc(100vh - 235px)",
    overflow: "auto",
  },
}));
