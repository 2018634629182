import React from 'react';
import Grid from '@mui/material/Grid';
import { CustomPaper, CustomTypography, TitleDesc } from '../../companyCreation/components';
import useTheme from '@mui/material/styles/useTheme';
import { returnValue } from '../../companyCreation/utils';
import { FontFamilySwitch } from '../../../utils';
import { Box } from '@mui/material';
export const PaymentAndTaxDetails = (props) => {
    const { t } = (props);

    const theme = useTheme();

    const {
        tax = null,
        bankingBasicDetails = null,
    } = props?.data ?? {};

    return (
        <div>
            <Box mx={2} p={2} sx={{ borderTop: "1px solid #E4E8EE", borderBottom: "1px solid #E4E8EE" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CustomTypography
                            fontSize={12}
                            fontFamily={FontFamilySwitch().bold}
                            // marginBottom={theme?.spacing(2)}
                            color={theme?.typography?.color?.secondary}
                        >
                            {t("TAX")}
                        </CustomTypography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={t("Tax Group")}
                            desc={returnValue(tax?.taxGroup?.label, '-')}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={t("Tax Code")}
                            desc={returnValue(tax?.taxCode?.label, '-')}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TitleDesc
                            title={t("Tax Registration Number")}
                            desc={returnValue(tax?.taxRegistrationNumber, '-')}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ margin: "0px 16px 16px 16px", borderBottom: "1px solid #E4E8EE" }}>
                <CustomPaper >
                <Grid container spacing={2} rowSpacing={3}>
                    <Grid item xs={12}>
                        <CustomTypography
                            fontSize={12}
                            fontFamily={FontFamilySwitch().bold}
                            // marginBottom={theme?.spacing(2)}
                            color={theme?.typography?.color?.secondary}
                        >
                            {t("Banking Basic Details")}
                        </CustomTypography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={t("Bank Name")}
                            desc={returnValue(bankingBasicDetails?.bankName, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={t("Branch Name")}
                            desc={returnValue(bankingBasicDetails?.branchName, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={t("Currency")}
                            desc={returnValue(bankingBasicDetails?.currency?.label, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={t("Bank Account Type")}
                            desc={returnValue(bankingBasicDetails?.bankAccountType, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={t("Account Number")}
                            desc={returnValue(bankingBasicDetails?.accountNumber, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={t("Bank Routing Type")}
                            desc={returnValue(bankingBasicDetails?.bankRoutingType?.label, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={t("Bank Routing Code")}
                            desc={returnValue(bankingBasicDetails?.bankRoutingCode, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={t("Cheque Name")}
                            desc={returnValue(bankingBasicDetails?.chequeName, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={t("Address Line 1")}
                            desc={returnValue(bankingBasicDetails?.addressLineOne, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={t("Address Line 2")}
                            desc={returnValue(bankingBasicDetails?.addressLineTwo, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={t("City")}
                            desc={returnValue(bankingBasicDetails?.city, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={t("Country")}
                            desc={returnValue(bankingBasicDetails?.country, '-')}
                        />
                    </Grid>
                </Grid>
                </CustomPaper>
            </Box>
        </div >
    )
}