import { Box, Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FormGenerator, Subheader } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, NetWorkCallMethods, accessCheckRender, getCompanyOption, getPropertyOptions, getRoutePermissionNew } from "../../utils";
import { CustomTypography, initial } from "./common";
import { useStyles } from "./style";
import { withTranslation } from "react-i18next";
import { loadOptionsApis } from "../maintenancerequest/utils";
import { CustomLoadOptionsApis } from "../requestView/steps/utils";

const Request = ({
  t = () => false
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [data, setData] = useState({ ...initial });
  const { state } = useLocation();
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const auth = React.useContext(AuthContext)
  const [permission, setPermission] = React.useState({})
  const [properties, setProperties] = React.useState([])
  const [isDisableBtn, setIsDisableBtn] = React.useState(false)
  const [defaultValue, setDefaultValue] = React.useState({
    project: "",
    contract: "",
    contact: ""
  })

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        let propertiesArray = getPropertyOptions(company)
        setProperties(propertiesArray ?? [])
        if (state?.main?.isEdit) getDetails();
      }
    }
    // eslint-disable-next-line
  }, [auth, state?.main?.id]);

  // validation
  const validateContact = () => {
    let isValid = true;
    let error = data.error;
    if (data?.Category?.length === 0) {
      isValid = false;
      error.Category = t("Category is Required");
    }
    if (data?.Unit?.length === 0) {
      isValid = false;
      error.Unit = t("Unit is Required");
    }
    if (data?.subCategory?.length === 0) {
      isValid = false;
      error.subCategory = t("Sub-Category is Required");
    }
    if (data?.Title?.length === 0) {
      isValid = false;
      error.Title = t("Problem Title is Required");
    }
    if (data?.Property?.length === 0) {
      isValid = false;
      error.Property = t("Property is Required");
    }
    if (
      data?.Category?.length === 0 ||
      data?.subCategory?.length === 0 ||
      data?.Unit?.length === 0 ||
      data?.Title?.length === 0 ||
      data?.Property?.length === 0
    ) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Please fill all mandatory field"),
      });
    }

    setData({ ...data, error });

    return isValid;
  };

  const manualResponseUnit = (array) => {
    const details = array?.unit?.map((i) => {
      return {
        label: i?.label,
        value: i?.value
      }
    })
    return details
  };

  //   updateData
  const updateData = (key, value) => {
    let error = data?.error;
    error[key] = "";
    if (key === "Category") {
      setData({ ...data, subCategory: "", [key]: value, error });
    } else if (key === "Property") {
      setData({ ...data, Unit: "", [key]: value, error });
    }
    else if (key === "project") {
      setDefaultValue({ ...defaultValue, project: value })
    }
    else if (key === "contract") {
      setDefaultValue({ ...defaultValue, contract: value })
    }
    else {
      setData({ ...data, [key]: value, error });
    }
  };

  // request creation
  const saveMaintenanceRequest = () => {
    if (validateContact()) {
      setIsDisableBtn(true)
      const payload = {
        type: 1,
        subject: data?.Title ?? null,
        category: data?.Category?.value,
        sub_category: data?.subCategory?.value,
        units: [data?.Unit?.value],
        description: data?.Description,
        userProfileId: localStorage.getItem("profileID"),
        propertyId: data?.Property?.value,
        companyId: state?.main?.company?.value ?? null,
        project_id: defaultValue?.project?.value ?? null,
        contract_id: defaultValue?.contract?.value ?? null,
        is_active: data?.status
      };
      if (state?.main?.isEdit) {
        payload["id"] = state?.main?.id;
      }
      NetworkCall(
        `${config.api_url}/request/${state?.main?.isEdit === true ? "update" : "create"}`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          setIsDisableBtn(false)
          navigate(Routes?.generalRequest);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `${state?.main?.isEdit === true ? t("General Request Updated successfully.") : t("General Request Created successfully.")} `,
          });
        })
        .catch((error) => {
          setIsDisableBtn(false)
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `Maintenance Request ${state?.main?.isEdit === true ? "Updated" : "Created"} successfully.`,
          });
        });
    }
  };

  const getDefaultProject = (property_id = "", unit_id = "") => {
    const payload = {
      "property_id": property_id,
      "unit_id": unit_id,
      "agreement_unit_id": ""
    }
    NetworkCall(
      `${config?.api_url}/request/get_agreement_project`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        if (response?.data?.data?.title?.length > 0 || response?.data?.data?.contract_id?.length > 0) {
          const result = {
            label: `${response?.data?.data?.title} - ${response?.data?.data?.project_no}`,
            value: response?.data?.data?.project_id
          }
          const contract = {
            label: response?.data?.data?.contract_no,
            value: response?.data?.data?.contract_id
          }
          setDefaultValue({ ...defaultValue, project: result, contract: contract })
        }
      })
      .catch((error) => {
        setDefaultValue({ project: null, contract: null })
        console.log(error)
      });
  };

  const manualResponse = (array) => {
    const details = {
      data: array?.data?.map((i) => {
        return {
          ...i,
          label: `${i?.title} - ${i?.project_no}`,
          value: i?.id
        }
      }),
      count: array?.count
    }
    return details
  }

  const handlePropertyUnit = (k, v) => {
    getDefaultProject(data?.Property?.value, [v?.value])
    updateData(k, v)
  }

  // get details
  const getDetails = () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: t("Loading"),
    });
    const payload = {
      id: state?.main?.id
    };
    NetworkCall(
      `${config.api_url}/queries/general_request/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let result = response?.data?.data;
        setData({
          Category: {
            value: result?.table?.[0]?.catagory?.id ?? "",
            label: result?.table?.[0]?.catagory?.type ?? "",
          },
          subCategory: {
            value: result?.table?.[0]?.subcatagory?.id ?? "",
            label: result?.table?.[0]?.subcatagory?.type ?? "",
          },
          Unit: {
            value: result?.table?.[0]?.unit?.[0].unit?.id ?? "",
            label: result?.table?.[0]?.unit?.[0].unit?.name ?? "",
          },
          Title: result?.table?.[0]?.subject ?? "",
          Description: result?.table?.[0]?.description ?? "",
          status: result?.table?.[0]?.is_active,
          Property: {
            value: result?.table?.[0]?.unit?.[0].property?.id ?? "",
            label: result?.table?.[0]?.unit?.[0].property?.name ?? "",
          },
          error: {
            Category: "",
            subCategory: "",
            Unit: "",
            Title: "",
            Description: "",
            status: "",
            Property: "",
            Agreement: "",
            member: "",
          },
        });
        setDefaultValue({
          project: result?.table?.[0]?.projectByID !== null ? {
            label: `${result?.table?.[0]?.projectByID?.title} - ${result?.table?.[0]?.projectByID?.project_no}`,
            value: result?.table?.[0]?.projectByID?.id
          } : null,
          contract: result?.table?.[0]?.contractByID !== null ? {
            label: result?.table?.[0]?.contractByID?.contract_no,
            value: result?.table?.[0]?.contractByID?.id
          } : null,
        })
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went wrong please try again"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };

  const render = () => {
    return <>
      <Subheader
        title={`${state?.main?.isEdit === true ? t("Edit General Request") : t("Create General Request")}`}
        goBack={() => {
          navigate(-1);
        }}
      />
      <Box className={classes.root1}>
        <Grid container spacing={3}>
          {/*  SERVICE DETAILS*/}
          <Grid item xs={12}>
            <div className={classes.card}>
              <CustomTypography>{t("SERVICE DETAILS")}</CustomTypography>
              <FormGenerator t={t}
                components={[
                  {
                    isActive: true,
                    component: "select",
                    label: t("Property"),
                    value: data?.Property,
                    placeholder: t("Property"),
                    onChange: (value) => updateData("Property", value),
                    error: data?.error?.Property,
                    isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    options: properties
                  },
                  {
                    isActive: true,
                    component: "select",
                    label: t("Unit"),
                    value: data?.Unit,
                    placeholder: t("Unit"),
                    onChange: (value) => handlePropertyUnit("Unit", value),
                    error: data?.error?.Unit,
                    isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                    loadOptions: (search, array, handleLoading) =>
                      loadOptionsApis(
                        "queries/unit/list",
                        {
                          "property_id": data?.Property?.value ?? [],
                          "company_id": state?.main?.company?.value,
                          "status": [true]
                        },
                        search,
                        array,
                        handleLoading,
                        "data",
                        {},
                        manualResponseUnit
                      ),
                    key: JSON.stringify(data?.Property),
                  },
                  {
                    isActive: true,
                    component: "select",
                    label: t("Contract"),
                    value: defaultValue?.contract,
                    placeholder: t("Contract"),
                    onChange: (value) => updateData("contract", value),
                    error: data?.error?.contract,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                    isClearable: true,
                    loadOptions: (search, array, handleLoading) => loadOptionsApis(
                      "contract/get_all_contract",
                      {
                        company_id: state?.main?.company?.value,
                        contract_type: [3],
                      },
                      search,
                      array,
                      handleLoading,
                      "data",
                      {},
                    ),
                    key: JSON.stringify(data?.unit),
                  },
                  {
                    isActive: true,
                    component: "select",
                    label: t("Project"),
                    value: defaultValue?.project,
                    placeholder: t("Project"),
                    isClearable: true,
                    onChange: (value) => updateData("project", value),
                    error: data?.error?.project,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                    loadOptions: (search, array, handleLoading) => CustomLoadOptionsApis(
                      "project/drop_down",
                      {
                        company_id: state?.main?.company?.value
                      },
                      search,
                      array,
                      handleLoading,
                      "data",
                      {},
                      manualResponse,
                    ),
                    key: JSON.stringify(data?.unit),
                  },
                  {
                    isActive: true,
                    component: "select",
                    label: t("Category"),
                    value: data?.Category,
                    placeholder: t("Category"),
                    onChange: (value) => updateData("Category", value),
                    error: data?.error?.Category,
                    isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                    loadOptions: (search, array, handleLoading) =>
                      loadOptionsApis(
                        "general-master/category_master",
                        {},
                        search,
                        array,
                        handleLoading,
                        "data",
                        {},
                      ),
                  },
                  {
                    isActive: true,
                    component: "select",
                    label: t("Sub-Category"),
                    value: data?.subCategory,
                    placeholder: t("Sub-Category"),
                    onChange: (value) => updateData("subCategory", value),
                    error: data?.error?.subCategory,
                    isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                    loadOptions: (search, array, handleLoading) =>
                      loadOptionsApis(
                        "general-master/sub_category_master",
                        {
                          category_id: data?.Category?.value
                        },
                        search,
                        array,
                        handleLoading,
                        "data",
                        {},
                      ),
                    key: JSON.stringify(data?.Category),
                  },
                  {
                    isActive: true,
                    component: "text",
                    label: t("Problem Title"),
                    value: data?.Title,
                    placeholder: t("Problem Title"),
                    onChange: (e) => updateData("Title", e?.target?.value),
                    error: data?.error?.Title,
                    isRequired: true,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 3,
                    },
                    isPaginate: true,
                  },
                  {
                    isActive: true,
                    component: "text",
                    label: t("Problem Description"),
                    value: data?.Description,
                    placeholder: t("Problem Description"),
                    onChange: (e) =>
                      updateData("Description", e?.target?.value),
                    error: data?.error?.Description,
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 6,
                      lg: 6,
                    },
                    isPaginate: true,
                  },
                  {
                    size: {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 3,
                    },
                    isActive: true,
                    component: "toggle",
                    label: t("Status"),
                    value: data?.status ?? true,
                    placeholder: t("Status"),
                    onChange: (value) => updateData("status", value),
                    error: data?.error?.status,
                    isRequired: true,
                    options: [
                      { label: t("Active"), value: true },
                      { label: t("Inactive"), value: false },
                    ],
                  },
                ]}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
      {/* bottom btn */}
      <Box className={classes.bottombtn}>
        <Box>
          <Button
            className={classes.Cancel}
            onClick={() => {
              navigate(-1);
            }}
          >
            {t("Cancel")}
          </Button>
        </Box>
        {((state?.main?.isEdit && permission?.update) || (!Boolean(state?.main?.isEdit) && permission?.create)) &&
          <Box>
            <Button className={classes.next} onClick={saveMaintenanceRequest} disabled={isDisableBtn}>
              {state?.main?.isEdit ? t("Update") : t("Submit")}
            </Button>
          </Box>}
      </Box>
    </>
  }

  return (
    <>
      {accessCheckRender(render, permission)}
    </>
  );
};

export default withTranslation("maintanceRequest")(Request);