import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch, remCalc } from "../../utils";
export const correspondencesStyles = makeStyles((theme) => ({
    draft_root: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        height: `calc(100vh - 202px)`,
        overflow: "overlay",
        margin: "6px 12px 12px 12px"
    },
    tab_div: {
        borderRadius: theme.palette.borderRadius,
        backgroundColor: theme.palette.background.tab_background_1,
        padding: "4px",
    },
    selected_tab: {
        cursor: "pointer",
        borderRadius: theme.palette.borderRadius,
        backgroundColor: theme.palette.primary.main,
        padding: "7px 10px",
        minWidth: "62px",
    },
    unselected_tab: {
        cursor: "pointer",
        borderRadius: theme.palette.borderRadius,
        padding: "7px 10px",
        minWidth: "62px",
    },
    selected_tab_text: {
        textAlign: "center",
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.white,
    },
    unselected_tab_text: {
        textAlign: "center",
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.secondary,
    },
    button: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "none",
        fontSize:"0.875rem",
        height: "40px",
    },
    preview_root: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        overflow: "overlay",
    },
    preview_div: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        height: `calc(100vh - 202px)`,
        overflow: "overlay",
        margin: "6px 12px 12px 12px",
        width: "600px",
    },
    preview_logo: {
        height: "90px",
        width: "230px",
        objectFit: "contain",
        alignItems: "start",
    },
    preview_company_div: {
        backgroundColor: "#F2F5F7",
    },
    preview_company_name: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().extraBold,
        color: theme.typography.color.primary,
    },
    preview_company_details: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().regular,
        color: theme.typography.color.tertiary,
    },
    preview_detail_label: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.tertiary,
    },
    preview_correspondence_type_value: {
        textAlign: "center",
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().extraBold,
        color: theme.typography.color.primary,
    },
    preview_detail_value: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().extraBold,
        color: theme.typography.color.primary,
    },
    label_div: {
        width: "100px",
    },
    to_address_div: {
        width: "300px",
    },
    step2Title: {
        color: theme?.typography?.color?.tertiary,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
      },
    optionAvatar: {
        height: 32,
        width: 32
    },
    optionHeader: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
    },
    optionSubTitle: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().regular,
        color: theme.typography.color.secondary
    },
    contactInfoAccordian: {
        boxShadow: "none",
        border: "1px solid #e4e8ee"
    },
    contactInfoRoleName: {
        fontSize: remCalc(14),
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
    },
    contactInfoRoleNameDetails: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.primary
    },
    contactInfoAccordianDetailsStack: {
        borderTop: "1px solid #e4e8ee",
        paddingTop: "16px",
    },
}));