import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import {
  DialogBox,
  FormGenerator,
  LoadingSection,
  TableWithPagination,
} from "../../components";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import {
  AlertProps,
  enumSelect,
  enum_types,
  NetWorkCallMethods,
  ValidateEmail,
  LocalStorageKeys,
  wrapLabelsInT,
  useWindowDimensions,
  FontFamilySwitch
} from "../../utils";
import { intitalState, returnEditData } from "../../utils/lead";
import { LeadStylesParent } from "./style";
import CloseIconComponent from "../../assets/closeIcons";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { LeadNewheading } from "./utils/quickViewUtils";
export const AddLead = (props) => {
  const backdrop = React.useContext(BackdropContext);

  const { t, moduleType } = props;
  const classes = LeadStylesParent();
  const alert = React.useContext(AlertContext);
  const size = useWindowDimensions();
  const [data, setData] = React.useState({
    ...intitalState(props?.company, props?.isEdit?.bool),
    type: null,
  });
  const [loading, setLoading] = React.useState(props?.isEdit?.bool ?? false);
  const [disableButton, setDisableButton] = React.useState(false);
  const [enumValue, setEnumValue] = React.useState({
    source: [],
    purpose: [],
    revenue_type: [],
    lease_type: [],
  });
  
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);

  const [open, setOpen] = React.useState({
    open: false,
    data: [],
    count: 0,
  });

  // update state function
  const updateState = (key, value) => {
    let error = data?.error;
    error[key] = "";
    setData({ ...data, [key]: value });
  };
  // validate transfer lead
  const validatemain = () => {
    let isValid = true;
    let error = data.error;

    if (data?.name?.length === 0) {
      isValid = false;
      error.name = t("Prospect Contact Name is Required");
    }
    if (!ValidateEmail(data?.email)) {
      isValid = false;
      error.email = "Enter valid Email";
    }
    if (data?.email?.length === 0) {
      isValid = false;
      error.email = t("Email is Required");
    }
    if (data?.mobile?.length === 0) {
      isValid = false;
      error.mobile = t("Mobile Number is Required");
    }
    if (data?.internal_lead_source?.length === 0) {
      isValid = false;
      error.internal_lead_source = t("Internal Lead Source is Required");
    }
    if (data?.purpose?.length === 0) {
      isValid = false;
      error.purpose = "Purpose is Required";
    }
    if (data?.type?.length === 0) {
      isValid = false;
      error.type = "Type is Required";
    }

    if (data?.type?.value !== "Sale") {
      if (data?.lease_start_date?.length === 0) {
        isValid = false;
        error.type = "Start Date is Required";
      }

      if (data?.duration?.value?.length === 0) {
        isValid = false;
        error.duration = "Duration is Required";
      }
      if (data?.duration?.select?.length === 0) {
        isValid = false;
        error.duration = "Duration is Required";
      }
    }

    if (data?.company?.length === 0) {
      isValid = false;
      error.company = t("Company is Required");
    }
    if (data?.city?.length === 0) {
      isValid = false;
      error.city = t("City Source is Required");
    }
    if (data?.country?.length === 0) {
      isValid = false;
      error.country = "Country is Required";
    }
    if (data?.des?.length === 0) {
      isValid = false;
      error.des = t("Description is Required");
    }
    if (data?.preferred_mode_contact_type?.length === 0) {
      isValid = false;
      error.preferred_mode_contact_type = t("Preferred Mode is Required");
    }
    if (data?.job_title?.length === 0) {
      isValid = false;
      error.job_title = t("Job Title is Required");
    }
    if (data?.property?.length === 0) {
      isValid = false;
      error.property = t("Property is Required");
    }

    setData({ ...data, error });

    return isValid;
  };

  // add and edit function
  const save = async () => {
    if (validatemain()) {
      setDisableButton(true);
      const createdUnit = data?.selectedUnit
        ?.filter((val) => val?.is_active)
        ?.map((i) => {
          return {
            id: i?.id,
            property_id: data?.lead_owner ? null : i?.property_id,
          };
        });

      const createdEditUnit = data?.selectedUnit
        ?.filter(
          (item) => !data?.fixUnit?.map((i) => i?.id)?.includes(item?.id)
        )
        ?.filter((item) => item?.is_active === true)
        ?.map((x) => {
          return {
            id: x?.id,
            property_id: data?.lead_owner ? null : x?.property_id,
          };
        });
      const removeUnits = data?.selectedUnit
        ?.filter((item) => data?.fixUnit?.map((i) => i?.id)?.includes(item?.id))
        ?.filter((item) => item?.is_active === false)
        ?.map((x) => x?.id);
      const removeDoialogUnit = data?.changeUnit
        ?.filter((val) => val?.is_active === false)
        ?.map((i) => i?.id);

      let urgentValue = "medium";
      if (props?.isEdit?.bool === true && data?.id) {
        try {
          const response = await NetworkCall(
            `${config.api_url}/lead_proxy/get_proxy_lead_details`,
            NetWorkCallMethods.post,
            {
              offset: 0,
              limit: 1,
              is_active: true,
              id: data?.id,
            },
            null,
            true,
            false
          );
          urgentValue = response?.data?.data?.proxy_lead?.[0]?.urgent || "medium";
        } catch (error) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Failed to fetch urgent value"),
          });
        }
      }

      const datas = {
        tenantId: `${config.tenantId}`,
        lead_name: data?.name ?? "",
        mobile_no: data?.mobile?.mobile,
        mobile_country_code: data?.mobile?.mobile_code ?? "",
        email: data?.email ?? "",
        company_id: props?.selectedCompany,
        description: data?.des?.length > 0 ? data?.des : undefined,
        purpose: data?.purpose?.value ?? "",
        units: props?.isEdit?.bool ? createdEditUnit : createdUnit,
        unit_owner: data?.lead_owner ?? null,
        id: data?.id ?? null,
        remove_units: props?.isEdit?.bool
          ? removeUnits?.length > 0
            ? removeUnits
            : removeDoialogUnit
          : undefined,
        revenue_type: data?.type?.value,
        unit_usage: data?.purpose?.value,
        value: !!data?.duration?.value ? data?.duration?.value : undefined,
        lease_period: data?.duration?.select,
        lease_start_date: data?.lease_start_date,
        classify_lead_id: 1,
        urgent: urgentValue,
        job_title: data?.job_title ?? "",
        company: data?.company ?? "",
        city: data?.city ?? "",
        country_id: data?.country?.value,
        internal_lead_source_id: data?.internal_lead_source?.value,
        lead_owner: props?.isEdit?.bool
          ? undefined
          : localStorage.getItem(LocalStorageKeys?.userProfileID),
        unit_count: data?.count,
        unit_type: data?.unit_type?.value,
        preferred_mode_contact: data?.preferred_mode_contact_type?.value,
        property: data?.property?.value,
      };
      if (props?.isEdit?.bool === true) {
        datas["id"] = data?.id;
      } else {
        datas["status"] = "In Progress";
      }

      NetworkCall(
        `${config.api_url}/lead_proxy/upsert`,
        NetWorkCallMethods.post,
        datas,
        null,
        true,
        false
      )
        .then((response) => {
          setDisableButton(false);
          props?.closeDrawer();
          props?.getDetails(props?.team?.value);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `${props?.isEdit?.bool === true
              ? t("Lead Updated successfully.")
              : t("Lead Created successfully.")
              }`,
          });
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Internal error. Please try again later."),
          });
          setDisableButton(false);
        });
    } else {
      return false;
    }
  };
  // get enum
  const getEnum = async () => {
    const result = await enumSelect([
      enum_types.lead_source,
      enum_types?.purpose_global_type,
      enum_types.revenue_type,
      enum_types.l_period,
      enum_types?.preferred_mode_contact_type,
    ]);

    const activeRoleModuleId = localStorage.getItem('activeRoleModuleId');

    let purposeOptions = [
      ...result?.purpose_global_type?.filter((x) => x?.value === "Commercial"),
      ...result?.purpose_global_type?.filter((x) => x?.value === "Residential"),
      ...result?.purpose_global_type?.filter((x) => x?.value === "Mixed"),
    ];
    let revenueTypeOptions = result?.revenue_type;

    if (activeRoleModuleId === '49') {
      purposeOptions = purposeOptions.filter(
        (x) => x?.value !== "Residential" && x?.value !== "Mixed"
      );
      revenueTypeOptions = revenueTypeOptions.filter((x) => x?.value === "Lease");
    } else {
      revenueTypeOptions = revenueTypeOptions.filter((x) => x?.value !== "Stay");
    }
    setEnumValue({
      preferred_mode_contact_type: result?.preferred_mode_contact_type,
      source: result?.lead_source,
      revenue_type: revenueTypeOptions,
      purpose: purposeOptions,
      lease_type: result?.l_period
        ?.filter((val) => val?.value !== "Quarterly")
        .filter((val) => val?.value !== "Daily"),
    });
  };
  //get edit details
  const editData = () => {
    setLoading(true);
    NetworkCall(
      `${config.api_url}/lead_proxy/get`,
      NetWorkCallMethods.post,
      { id: props?.isEdit?.id },
      null,
      true,
      false
    )
      .then((res) => {
        const result = returnEditData(res?.data?.data);
        setData(result);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  
  React.useEffect(() => {
    if (props?.isEdit?.bool) {
      editData();
    }
    getEnum();
    // eslint-disable-next-line
  }, [props?.isEdit]);
  
  React.useEffect(() => {
    // Set the state based on moduleType
    if (moduleType === "Lease") {
      setData((prevState) => ({
        ...prevState,
        type: enumValue?.revenue_type?.find(option => option.value === "Lease")
      }));
    } else if (moduleType === "Sale") {
      setData((prevState) => ({
        ...prevState,
        type: enumValue?.revenue_type?.find(option => option.value === "Sale")
      }));
    }
    // eslint-disable-next-line
  }, [moduleType, enumValue]);
    
  const checkValidate = (company, email, is_check) => {
    if (!company) {
      if (is_check) {
        return save();
      }
      return true;
    }
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading ...",
    });

    let datas = {
      company: company ?? "",
      email: email,
      is_check,
    };
    NetworkCall(
      `${config.api_url}/lead/check_email`,
      NetWorkCallMethods.post,
      datas,
      null,
      true,
      false
    )
      .then((response) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "Loading ...",
        });

        if (response?.data?.data?.count > 0) {
          if (is_check) {
            setOpen({
              data: response?.data?.data?.data?.map((v) => {
                return {
                  ...v,
                  name: `${v?.first_name} ${v?.last_name ?? ""}`,
                };
              }),
              open: true,
              count: response?.data?.data?.count,
            });
            return false;
          }
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Company Already Exists"),
          });
          return false;
        } else {
          if (is_check) {
            save();
          }
          return false;
        }
      })
      .catch((error) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "Loading ...",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
      });
  };
  // pagination function
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    checkValidate(
      !!data?.company ? data?.company?.toLocaleLowerCase() : null,
      !!data?.email ? data?.email?.toLocaleLowerCase() : null,
      true,
      offset,
      limit
    );
  };
  // pagination limit change function
  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    checkValidate(
      !!data?.company ? data?.company?.toLocaleLowerCase() : null,
      !!data?.email ? data?.email?.toLocaleLowerCase() : null,
      true,
      0,
      value
    );
  };
  return (
    <Box className={classes.formRoot}>
      {/* lead Details */}

      <Box>
        <Box className={classes.bottombtn1}>
          <Typography className={classes.drawerTitle}>
            {props?.isEdit?.bool === true ? t("Edit") : t("Create")} {t("Lead")}
          </Typography>
          <Box
            display={"flex"}
            alignItems={"center"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setData({ ...intitalState });
              props?.closeDrawer();
            }}
          >
            <CloseIconComponent />
          </Box>
        </Box>
        {loading ? (
          <Box sx={{ height: `calc(100vh - 125px)`, overflow: "auto" }}>
            <LoadingSection message="Fetching Details ..." top="30vh" />
          </Box>
        ) : (
          <Box style={{ height: size.height - 130, overflow: "auto" }} p={2}>
            <Grid container>
              <Grid item xs={12}>
                <FormGenerator
                  t={t}
                  spacing={1.5}
                  components={[
                    {
                      size: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                      },
                      isActive: true,
                      component: "select",
                      label: t("Internal Lead Source"),
                      value: data?.internal_lead_source,
                      placeholder: t("Internal Lead Source"),
                      onChange: (value) =>
                        updateState("internal_lead_source", value),
                      isPaginate: true,
                      error: data?.error?.internal_lead_source,
                      isRequired: true,
                      fontSize: "14px !important",
                      menuPlacement: "bottom",
                      loadOptions: (search, array, handleLoading) =>
                        loadOptionsApis(
                          "queries/opportunity/internal_lead_source",
                          {},
                          search,
                          array,
                          handleLoading,
                          "data",
                          {}
                        ),
                    },
                    {
                      size: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                      },
                      isActive: true,
                      component: "text",
                      label: t("Contact Name"),
                      value: data?.name,
                      placeholder: t("Contact Name"),
                      onChange: (value) =>
                        updateState("name", value.target.value),
                      error: data?.error?.name,
                      isRequired: true,
                      fontSize: "14px !important",
                    },
                    {
                      size: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                      },
                      isActive: true,
                      component: "text",
                      label: t("Job Title"),
                      value: data?.job_title,
                      placeholder: t("Job Title"),
                      onChange: (value) =>
                        updateState("job_title", value.target.value),
                      error: data?.error?.job_title,
                      isRequired: true,
                      fontSize: "14px !important",
                    },
                    {
                      isActive: true,
                      component: "select",
                      menuPlacement: "auto",
                      label: t("Preferred Mode"),
                      value: data?.preferred_mode_contact_type,
                      placeholder: t("Preferred Mode"),
                      onChange: (value) =>
                        updateState("preferred_mode_contact_type", value),
                      error: data?.error?.preferred_mode_contact_type,
                      isRequired: true,
                      size: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                      },
                      options: enumValue?.preferred_mode_contact_type,
                      fontFamily: FontFamilySwitch().semiBold,
                    },
                    {
                      size: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                      },
                      isActive: true,
                      component: "text",
                      label: t("Email"),
                      value: data?.email,
                      placeholder: t("Email"),
                      onChange: (e) => updateState("email", e.target.value),
                      error: data?.error?.email,
                      isRequired: true,
                    },
                    {
                      isActive: true,
                      component: "mobile",
                      label: t("Mobile Number"),
                      value: data?.mobile,
                      placeholder: t("Mobile Number"),
                      onChange: (value) => updateState("mobile", value),
                      error: data?.error?.mobile,
                      isRequired: true,
                      size: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                      },
                      isPaginate: true,
                      xs1: 7,
                      xs: 5,
                    },
                    {
                      size: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                      },
                      isActive: true,
                      component: "text",
                      label: t("City"),
                      value: data?.city,
                      placeholder: t("City"),
                      onChange: (value) =>
                        updateState("city", value.target.value),
                      error: data?.error?.city,
                      isRequired: true,
                      fontSize: "14px !important",
                    },
                    {
                      size: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                      },
                      isActive: true,
                      component: "select",
                      menuPlacement: "auto",
                      label: t("Country"),
                      value: data?.country,
                      placeholder: t("Country"),
                      onChange: (value) => updateState("country", value),
                      isPaginate: true,
                      error: data?.error?.country,
                      isRequired: true,
                      fontSize: "14px !important",
                      loadOptions: (search, array, handleLoading) =>
                        loadOptionsApis(
                          "queries/opportunity/country_master",
                          {},
                          search,
                          array,
                          handleLoading,
                          "data",
                          {}
                        ),
                    },
                    {
                      size: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                      },
                      isActive: true,
                      component: "text",
                      label: t("Company"),
                      value: data?.company,
                      placeholder: t("Company"),
                      onChange: (value) =>
                        updateState("company", value.target.value),
                      error: data?.error?.company,
                      isRequired: true,
                      fontSize: "14px !important",
                      onBlur: () =>
                        checkValidate(
                          !!data?.company
                            ? data?.company?.toLocaleLowerCase()
                            : null
                        ),
                    },
                    {
                      isActive: true,
                      component: "text",
                      label: t("Description/Requirement"),
                      value: data?.des,
                      placeholder: t("Description/Requirement"),
                      onChange: (e) => updateState("des", e?.target?.value),
                      error: data?.error?.des,
                      size: {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                      },
                      isPaginate: true,
                      multiline: true,
                      isRequired: true,
                      height: "60px",
                    },
                    {
                      isActive: true,
                      component: "purpose_choose",
                      label: t("Purpose Type"),
                      value: data?.purpose?.value,
                      placeholder: t("Purpose Type"),
                      onChange: (value) => updateState("purpose", value),
                      error: data?.error?.purpose,
                      isrequired: true,
                      options: wrapLabelsInT(enumValue?.purpose, t),
                      size: {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                      },
                      isPaginate: true,
                    },
                    {
                      isActive: true,
                      component: "select",
                      menuPlacement: "auto",
                      label: t("Type"),
                      value: data?.type,
                      placeholder: t("Type"),
                      onChange: (value) => updateState("type", value),
                      error: data?.error?.type,
                      isRequired: true,
                      size: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                      },
                      options: moduleType === "Lease" 
                        ? enumValue?.revenue_type?.filter(option => option.value === "Lease") 
                        : moduleType === "Sale" 
                          ? enumValue?.revenue_type?.filter(option => option.value === "Sale") 
                          : enumValue?.revenue_type,
                      fontFamily: FontFamilySwitch().semiBold,
                    },
                    {
                      size: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                      },
                      isActive: true,
                      component: "date",
                      label:
                        data?.type?.value === "Sale"
                          ? t("Earliest Occupation Date")
                          : data?.type?.value === "Lease"
                            ? t("Lease Start Date")
                            : t("Manage Start Date"),
                      value: data?.lease_start_date,
                      placeholder:
                        data?.type?.value === "Sale"
                          ? t("Earliest Occupation Date")
                          : data?.type?.value === "Lease"
                            ? t("Lease Start Date")
                            : t("Manage Start Date"),
                      onChange: (value) =>
                        updateState("lease_start_date", value),
                      // isReadonly: true,
                      isRequired: true,
                      error: data?.error?.lease_start_date,
                      minDate: new Date(),
                    },
                    {
                      size: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                      },
                      isActive: data?.type?.value === "Sale" ? false : true,
                      component: "duration",
                      label:
                        data?.type?.value === "Lease"
                          ? t("Lease Duration")
                          : t("Manage Duration"),
                      value: data?.duration,
                      onChange: (value) => updateState("duration", value),
                      placeholder:
                        data?.type === "Lease"
                          ? t("Lease Duration")
                          : t("Manage Duration"),
                      isRequired: true,
                      options: enumValue?.lease_type?.filter(
                        (x) => x?.value !== "Weekly"
                      ),
                      isError: data?.error?.duration?.length > 0,
                      errorMessage: data?.error?.duration,
                      fontFamily: FontFamilySwitch().semiBold,
                      fontSize: "14px !important",
                      item_size: {
                        item: 3,
                        item1: 9,
                      },
                    },
                    {
                      size: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                      },

                      isActive: true,
                      component: "select",
                      menuPlacement: "top",
                      label: t("Property"),
                      value: data?.property,
                      placeholder: t("Select Property"),
                      onChange: (value) => updateState("property", value),
                      isPaginate: true,
                      error: data?.error?.property,
                      isRequired: true,
                      fontSize: "14px !important",
                      loadOptions: (search, array, handleLoading) =>
                        loadOptionsApis(
                          "queries/opportunity/property/get",
                          { company_id: JSON.parse(
                            localStorage.getItem(
                              LocalStorageKeys?.selectedCompany
                            )
                          )?.value,
                        },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {}
                        ),
                    },
                    {
                      size: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                      },
                      isActive: true,
                      component: "select",
                      menuPlacement: "top",
                      label: t("Unit Type"),
                      value: data?.unit_type,
                      placeholder: t("Unit Type"),
                      onChange: (value) => updateState("unit_type", value),
                      isPaginate: true,
                      error: data?.error?.unit_type,
                      isRequired: false,
                      fontSize: "14px !important",
                      loadOptions: (search, array, handleLoading) =>
                        loadOptionsApis(
                          "queries/unit_type",
                          {
                            company_id: JSON.parse(
                              localStorage.getItem(
                                LocalStorageKeys?.selectedCompany
                              )
                            )?.value,
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {}
                        ),
                      debounceTimeout: 800,
                    },
                  ]}
                />
              </Grid>

              {/* <Grid item xs={6}>
                          <Box
                            p={2}
                            sx={{
                              height: size?.height - 300,
                              overflow: "auto",
                            }}>
                            <SelectUnitComponent
                              datas={data}
                              onDeleteUnits={onDeleteUnits}
                              onChange={onChangeUnits}
                              value={data?.selectedUnit}
                              isEdit={props?.isEdit?.bool}
                              company={props?.selectedCompany}
                            />
                          </Box>
                        </Grid> */}
            </Grid>
          </Box>
        )}
      </Box>

      {/* end */}
      {/* button */}
      <Box className={classes.bottombtn}>
        <Button
          className={classes.next}
          onClick={() =>
            checkValidate(
              !!data?.company ? data?.company?.toLocaleLowerCase() : null,
              !!data?.email ? data?.email?.toLocaleLowerCase() : null,
              true
            )
          }
          fullWidth
        >
          {props?.isEdit?.bool === true ? t("Save") : t("Create Lead")}
        </Button>
      </Box>

      <DialogBox
        maxWidth={"sm"}
        open={open?.open}
        isnotTitle
        background
        handleClose={() =>
          setOpen({
            open: false,
            data: [],
            count: 0,
          })
        }
        component={
          <Box p={2}>
            <TableWithPagination
              heading={LeadNewheading(t)}
              rows={open?.data ?? ""}
              showpagination={true}
              showpdfbtn={false}
              showexcelbtn={false}
              showSearch={false}
              handlePagination={handlePagination}
              handleChangeLimit={handleChangeLimit}
              totalRowsCount={open?.count}
              page={page}
              limit={limit}
              tableType="no-side"
              noDataSvg
              dataType={[
                { type: ["text"], name: "reference_no" },
                { type: ["text"], name: "name" },
                { type: ["long_text"], name: "description" },
                { type: ["date"], name: "created_at" },
              ]}
              height={"calc(100vh - 460px)"}
              view={false}
              edit={false}
              delete={false}
            />
            <Stack mt={2} direction={"row"} spacing={2}>
              <Button
                disabled={disableButton}
                className={classes.next}
                style={{ width: "100%" }}
                onClick={save}
              >
                {props?.isEdit?.bool ? t("Save") : t("Create")}
              </Button>
              <Button
                className={classes.Cancel}
                style={{ width: "100%" }}
                onClick={() =>
                  setOpen({
                    open: false,
                    data: [],
                    count: 0,
                  })
                }
              >
                {t("Cancel")}
              </Button>
            </Stack>
          </Box>
        }
      />
    </Box>
  );
};
