import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import styled from "@mui/material/styles/styled";
import moment from "moment";
import React, { useMemo, useState } from "react";
import {
  AlertDialog,
  SearchFilter,
  SelectBox,
  Subheader,
  TableWithPagination,
  TextBox,
  UseDebounce,
} from "../../components";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import {
  AlertProps,
  LocalStorageKeys,
  NetWorkCallMethods,
  useWindowDimensions,
} from "../../utils";
import {
  NumberSequenceHeading,
  NumberSequencePath,
  NumberSequenceType,
} from "../../utils/numberSequence";
import { useStylesManagement } from "./style";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const CustomPaper = styled("div")(({ theme }) => ({
  boxShadow: "0px 0px 16px #00000008",
  borderRadius: theme.palette.borderRadius,
  backgroundColor: "#FFF",
  margin: theme.spacing(3),
  padding: "0px",
  height: `calc(100vh - 147px)`,
}));

const initial = {
  open: false,
  view: false,
  seedvaluepreview: "",
  preference: "",
  fromat: "",
  number: "",
  seed: "",
  String: "",
  list: [],
  listID: {},
  isShow: false,
  isEdit: false,
  error: {
    preference: "",
    fromat: "",
    number: "",
    seed: "",
    String: "",
  },
};
const Configure = ({ t }) => {
  const preference = [
    {
      value: "String preferred number",
      label: t("String preferred number"),
    },
    {
      value: "Date preferred number",
      label: t("Date preferred number"),
      format: [
        {
          value: "YYYY",
          label: "YYYY",
        },
        {
          value: "YYYYMM",
          label: "YYYYMM",
        },
        {
          value: "YYYYMMDD",
          label: "YYYYMMDD",
        },
      ],
    },
    {
      value: "String and date preferred number",
      label: t("String and date preferred number"),
      format: [
        {
          value: "YYYY",
          label: "YYYY",
        },
        {
          value: "YYYYMM",
          label: "YYYYMM",
        },
        {
          value: "YYYYMMDD",
          label: "YYYYMMDD",
        },
      ],
    },
  ];
  const navigate = useNavigate();
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const classes = useStylesManagement();
  const size = useWindowDimensions();
  const [state, setState] = useState({ ...initial });
  const [list, setList] = useState({
    count: null,
    list: [],
  });
  const [view, setView] = useState({});
  // const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [searchText, setSearchText] = React.useState("");
  const debounce = UseDebounce();

  const closeDrawer = () => {
    setState({ ...initial });
  };

  const openDrawer = (v, x, detail) => {
    if (detail === "edit") {
      let maxDigit = Number(x?.starts_with?.toString());
      let addZero = "";
      if (maxDigit > x?.starts_with?.length) {
        let appendDigit = maxDigit - x?.starts_with?.length;
        let i = appendDigit;
        while (i > 0) {
          addZero += "0";
          i--;
        }
      }
      setState({
        open: true,
        view: v,
        seedvaluepreview: addZero + x?.starts_with?.toString(),
        preference: {
          value: x?.preference ?? "",
          label: x?.preference ?? "",
        },
        fromat: {
          value: x?.date_format ?? "",
          label: x?.date_format ?? "",
        },
        number: x?.starts_with?.toString()?.length,
        seed: x?.starts_with ?? "",
        String: x?.prefix ?? "",
        list: [],
        listID: x,
        isShow: true,
        isEdit: true,
        error: {
          preference: "",
          fromat: "",
          number: "",
          seed: "",
          String: "",
        },
      });
    } else if (detail === "view") {
      setView(x);
      setState({ ...state, open: true, view: v, listID: x });
    } else {
      setState({ ...state, open: true, view: v, listID: x });
    }
  };
  // const updateState = (k, v) => {
  //       let error = state?.error;
  //       error[k] = "";
  //       if (k === "seed") {
  //             let maxDigit = Number(state?.number);
  //             let addZero = "";
  //             if (maxDigit > v.length) {
  //                   let appendDigit = maxDigit - v.length;
  //                   let i = appendDigit;
  //                   while (i > 0) {
  //                         addZero += '0';
  //                         i--;
  //                   }
  //             }
  //             setState({ ...state, seedvaluepreview: addZero + v, seed: v, isShow: true, error })
  //       }
  //       else {

  //             if (k === 'preference') {
  //                   setState({ ...state, String: "", seed: "", number: "", fromat: "", isShow: false, [k]: v })
  //             }
  //             else if (k === 'number') {
  //                   setState({ ...state, seed: "", [k]: v })
  //             }
  //             else {
  //                   setState({ ...state, [k]: v })
  //             }

  //       }
  // }
  const updateState = (k, v) => {
    let error = state?.error;
    error[k] = "";
    if (k === "seed") {
      let maxDigit = Number(state?.number);
      let addZero = "";
      if (maxDigit > v.length) {
        let appendDigit = maxDigit - v.length;
        let i = appendDigit;
        while (i > 0) {
          addZero += "0";
          i--;
        }
      }
      setState({
        ...state,
        seedvaluepreview: addZero + v,
        seed: v,
        isShow: true,
        error,
      });
    } else {
      if (k === "preference") {
        setState({
          ...state,
          String: "",
          seed: "",
          number: "",
          fromat: "",
          isShow: false,
          [k]: v,
        });
      } else if (k === "number") {
        setState({ ...state, seed: "", [k]: v });
      } else {
        setState({ ...state, [k]: v });
      }
    }
  };
  const getRoles = (offset, limit, searchText) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    const payload = {
      offset: offset,
      limit: limit,
      search: searchText,
    };
    NetworkCall(
      `${config.api_url}/queries/sequence_config/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        let listData = res?.data?.data?.sequence_config?.map((data) => {
          return {
            configuration_name: data?.table_name ?? "-",
            configuration_id: data?.id ?? "-",
            no_of_preference: data?.preference ?? "-",
            prefix: data?.prefix ?? "-",
            min_num_of_digit: data?.starts_with ?? "-",
            seed_value: data?.seed_value ?? "-",
            date_format: data?.date_format ?? "-",
          };
        });
        setList({
          count: res?.data?.data?.count ?? null,
          list: listData ?? [],
        });
        // setLoading(false);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        // setLoading(false);
      });
  };
  const save = async () => {
    if (validate()) {
      closeDrawer();
      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: "Loading",
      });
      const payload = {
        id: state?.listID?.id ?? undefined,
        update: {
          preference: state?.preference?.value,
          prefix: state?.String ?? null,
          date_format: state?.fromat?.value ?? null,
          starts_with: state?.seedvaluepreview ?? null,
          is_active: true,
          seed_value: state?.number ?? null,
          updated_at: new Date().toISOString(),
          client: localStorage.getItem(LocalStorageKeys.clinetID),
        },
      };
      let result = await NetworkCall(
        `${config.api_url}/queries/sequence_config/update`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      );
      if (result) {
        getRoles(0, limit, "");
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: `${
            state?.isEdit
              ? t("Updated Successfully")
              : t("Created Successfully")
          }`,
        });
        setState({ ...initial });
      } else {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
      }
    }
  };

  const DateToday = useMemo(
    () => [
      {
        date:
          state?.fromat?.label?.length > 0
            ? moment(new Date()).format(state?.fromat?.label)
            : null,
        name: Number(state?.seedvaluepreview) ?? "-",
        value: state?.String ?? null,
      },
      {
        date:
          state?.fromat?.label?.length > 0
            ? moment(new Date()).format(state?.fromat?.label)
            : null,
        name: Number(state?.seedvaluepreview) + 1 ?? "-",
        value: state?.String ?? null,
      },
      {
        date:
          state?.fromat?.label?.length > 0
            ? moment(new Date()).format(state?.fromat?.label)
            : null,
        name: Number(state?.seedvaluepreview) + 2 ?? "-",
        value: state?.String ?? null,
      },
    ],
    // eslint-disable-next-line
    [state?.seed]
  );

  const validate = () => {
    let isValid = true;
    let error = state.error;
    if (state?.preference?.length === 0) {
      isValid = false;
      error.preference = t("Preference is Required");
    }
    if (
      state?.preference?.value === "String and date preferred number" ||
      state?.preference?.value === "Date preferred number" ||
      state?.preference?.length === 0
    ) {
      if (state?.fromat?.length === 0) {
        isValid = false;
        error.fromat = t("Date Format is Required");
      }
    }
    if (state?.seed?.length === 0) {
      isValid = false;
      error.seed = t("Seed Value is Required");
    }
    if (
      state?.preference?.value === "String and date preferred number" ||
      state?.preference?.value === "String preferred number" ||
      state?.preference?.length === 0
    ) {
      if (state?.String?.length === 0) {
        isValid = false;
        error.String = t("Prefix is Required");
      }
    }

    setState({ ...state, error });

    return isValid;
  };

  React.useEffect(() => {
    getRoles(0, limit, "");
    // eslint-disable-next-line
  }, []);

  // As per CR Commented
  // const handleInfiniteScroll = () => {
  //       if (offset + limit <= list?.count[0]?.count) {
  //             setOffset((prevOffset) => prevOffset + limit);
  //             setLoading(true);
  //             getRoles(offset + limit, 'scroll');
  //       }
  // }

  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getRoles(offset, limit, "");
  };

  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getRoles(0, value, "");
  };

  const handleSearch = (e) => {
    setSearchText(e);
    debounce(() => searchTableFunction(e), 800);
  };
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getRoles(0, limit, e);
  };

  //handle icon
  const handleIcon = (type, value) => {
    let viewData = {
      id: value?.configuration_id ? value?.configuration_id : undefined,
      slug: value?.configuration_name,
      table_name: value?.configuration_name,
      date_format: value?.date_format,
      starts_with: value?.min_num_of_digit,
      prefix: value?.prefix,
      preference: value?.no_of_preference,
    };

    if (type === "view") {
      setState({ ...state, view: true, open: true });
      setView(viewData);
      openDrawer(true, viewData, "view");
    } else if (type === "edit") {
      openDrawer(false, view, "edit");
    }
  };

  return (
    <div>
      <Subheader
        title={t("Configuration")}
        goBack={() => {
          navigate(-1);
        }}
      />
      {/* As per CR Commented */}
      {/* <div style={{ padding: "24px", paddingBottom: '150px', margin: "4px", height: '100vh', overflow: 'auto' }}>
                        <AddMemberCard
                              closeDrawer={closeDrawer}
                              data={list?.list}
                              openDrawer={openDrawer}
                              handleInfiniteScroll={handleInfiniteScroll}
                              loading={loading}
                        />
                  </div> */}

      <CustomPaper m={1}>
        <Grid container spacing={2} className={classes.content}>
          <Grid item md={12}>
            <Grid container alignItems={"center"} p={1} pl={2} pt={2}>
              <Grid item xs={4}>
                <SearchFilter
                  placeholder={t("Search Configuration Name")}
                  value={searchText}
                  handleChange={(value) => handleSearch(value)}
                  customfieldSx={{
                    "& .MuiOutlinedInput-root": { height: "40px" },
                  }}
                />
              </Grid>
              <Grid item xs={8}></Grid>
            </Grid>
          </Grid>
          <Grid item md={12} ml={1} className={classes.tablegrid}>
            <TableWithPagination
              heading={NumberSequenceHeading(t)}
              rows={list?.list}
              path={NumberSequencePath}
              showpagination={true}
              onClick={() => false}
              dataType={NumberSequenceType}
              handlePagination={handlePagination}
              handleChangeLimit={handleChangeLimit}
              handleIcon={handleIcon}
              totalRowsCount={list?.count?.[0]?.count}
              page={page}
              limit={limit}
              tableType="no-side"
              height={"calc(100vh - 351px)"}
              view={true}
              edit={true}
              delete={true}
            />
          </Grid>
        </Grid>
      </CustomPaper>
      <AlertDialog
        md
        header={
          state?.view
            ? view?.table_name
            : state?.isEdit
            ? t("Edit Configuration")
            : t("Add Configuration")
        }
        onClose={closeDrawer}
        open={state?.open}
        component={
          <>
            {state?.view ? (
              <div>
                <div style={{ textAlign: "left", padding: "24px" }}>
                  <Box className={classes.detailsBox}>
                    {view?.preference && (
                      <>
                        <Box className={classes.box}>
                          <Typography className={classes.title}>
                            {t("Number Preference")}
                          </Typography>
                          <Typography className={classes.sub1}>
                            {view?.preference ?? "-"}
                          </Typography>
                        </Box>
                      </>
                    )}
                    {view?.prefix && (
                      <>
                        <Box className={classes.box}>
                          <Typography className={classes.title}>
                            {t("Prefix")}
                          </Typography>
                          <Typography className={classes.sub1}>
                            {view?.prefix ?? "-"}
                          </Typography>
                        </Box>
                      </>
                    )}
                    {view?.starts_with && (
                      <>
                        <Box className={classes.box}>
                          <Typography className={classes.title}>
                            {t("Minimum Number Of Digits")}
                          </Typography>
                          <Typography className={classes.sub1}>
                            {view?.starts_with?.toString?.length ?? "-"}
                          </Typography>
                        </Box>
                      </>
                    )}
                    {view?.seed_value && (
                      <Box className={classes.box}>
                        <Typography className={classes.title}>
                          {t("Seed Value")}
                        </Typography>
                        <Typography className={classes.sub1}>
                          {view?.seed_value ?? "-"}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </div>
                <Divider />
                <div style={{ textAlign: "left", padding: "24px" }}>
                  <Box className={classes.detailsBox}>
                    <Box className={classes.box}>
                      <Typography className={classes.title}>
                        {t("Preview")}
                      </Typography>
                      <Typography className={classes.sub1}>
                        {view?.prefix ?? "-"}
                        {view?.prefix && "-"}
                        {view?.starts_with ?? "-"}
                      </Typography>
                    </Box>
                  </Box>
                </div>
                <div style={{ padding: "24px" }}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    onClick={(e) => {
                      e.stopPropagation();
                      openDrawer(false, view, "edit");
                    }}
                  >
                    {t("Edit")}
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <div
                  style={{
                    height: size?.height - 300,
                    textAlign: "left",
                    margin: "9px 0px",
                    overflow: "auto",
                    padding: "24px",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <SelectBox
                        isRequired
                        label={t("Number Preference")}
                        placeholder={t("Select Number Preference")}
                        options={preference ?? []}
                        value={state?.preference ?? ""}
                        onChange={(value) => {
                          updateState("preference", value);
                        }}
                        isError={state?.error?.preference?.length > 0}
                        errorMessage={state?.error?.preference}
                      />
                    </Grid>
                    {state?.preference?.value ===
                      "String and date preferred number" && (
                      <Grid item xs={12}>
                        <TextBox
                          isrequired
                          label={t("Prefix")}
                          placeholder={t("Prefix")}
                          value={state?.String ?? ""}
                          onChange={(e) => {
                            updateState("String", e.target.value);
                          }}
                          isError={state?.error?.String?.length > 0}
                          errorMessage={state?.error?.String}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      {state?.preference?.value ===
                      "String preferred number" ? (
                        <TextBox
                          isrequired
                          label={t("Prefix")}
                          placeholder={t("prefix")}
                          value={state?.String ?? ""}
                          onChange={(e) => {
                            updateState("String", e.target.value);
                          }}
                          isError={state?.error?.String?.length > 0}
                          errorMessage={state?.error?.String}
                        />
                      ) : (
                        <SelectBox
                          isRequired
                          label={t("Fromat")}
                          placeholder={t("Select Fromat")}
                          options={state?.preference?.format ?? []}
                          value={state?.fromat ?? ""}
                          onChange={(value) => {
                            updateState("fromat", value);
                          }}
                          isError={state?.error?.fromat?.length > 0}
                          errorMessage={state?.error?.fromat}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextBox
                        isrequired
                        type="number"
                        label={t("Min Number")}
                        placeholder={t("Min Number")}
                        value={state?.number ?? ""}
                        onChange={(e) => {
                          updateState("number", e.target.value);
                        }}
                        isError={state?.error?.number?.length > 0}
                        errorMessage={state?.error?.number}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextBox
                        isrequired
                        type="number"
                        label={t("Seed Value")}
                        placeholder={t("Seed Value")}
                        value={state?.seed ?? ""}
                        onChange={(e) => {
                          updateState("seed", e.target.value);
                        }}
                        isError={state?.error?.seed?.length > 0}
                        errorMessage={state?.error?.seed}
                      />
                    </Grid>
                    {state?.isShow && state?.seedvaluepreview?.length > 0 && (
                      <Grid item xs={12}>
                        <Typography className={classes.preview}>
                          {t("Preview")}
                        </Typography>
                        <Box style={{ marginTop: "6px" }}>
                          {DateToday?.map((x) => {
                            return (
                              <Typography className={classes.previewdetails}>
                                {x?.value?.length > 0 && x?.value}
                                {x?.value?.length > 0 && "-"}
                                {x?.date !== null && x?.date}
                                {x?.date !== null && "-"}
                                {x?.name}
                              </Typography>
                            );
                          })}
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </div>
                <div style={{ padding: "20px 24px" }}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    onClick={save}
                  >
                    {state?.isEdit
                      ? t("Save Configuration")
                      : t("Add Configuration")}
                  </Button>
                </div>
              </div>
            )}
          </>
        }
      />
    </div>
  );
};
export default withTranslation("configure")(Configure);
