import {
  Badge,
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import FilterIMG from "../../assets/filter";
import {
  FilterGenerator,
  Label,
  SearchFilter,
  SelectBox,
  Subheader,
  TableWithPagination,
  TextBox,
  UseDebounce,
} from "../../components";
import { AlertContext, AuthContext } from "../../contexts";
import {
  accessCheckRender,
  activeOptions,
  activeOptionsList,
  AlertProps,
  enum_types,
  enumSelect,
  getRoutePermissionNew,
  NetWorkCallMethods,
} from "../../utils";
import { LightTooltip, useStyles } from "./style";
import CloseIcon from "@mui/icons-material/Close";
import { NewLoader } from "../../components/newLoader";
import {
  Heading,
  Path,
  Type,
  defaultAddState,
  defaultFilterData,
} from "./utils/tableUtils";
import { NetworkCall } from "../../networkcall";
import { BackendRoutes } from "../../router/routes";
import { withTranslation } from "react-i18next";
import { config } from "../../config";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import InfoIcon from "../../assets/infoIcon";

const ItemSubCategoryMaster = (props) => {
  const { loading, handleLoading, t } = props;

  const classes = useStyles();
  const debounce = UseDebounce();

  // useContext
  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext);

  // useState
  const [data, setData] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const [filterData, setFilterData] = React.useState(defaultFilterData);
  const [fiterDrawer, setFilterDrawer] = React.useState(false);
  const [addDialogOpen, setAddDialogOpen] = React.useState(false);
  const [addState, setAddState] = React.useState(defaultAddState);
  const [permission, setPermission] = React.useState({});
  const [loader, setLoader] = React.useState(true);
  const [buttonDisable, setButtonDisable] = React.useState(false);
  const [options, setOptions] = React.useState({
    item_type: [],
  });
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");
  const limitParam = searchParams.get("limit");

  // Default values if not set in URL
  const pageFromParams = pageParam ? parseInt(pageParam, 10) : 1;
  const limitFromParams = limitParam ? parseInt(limitParam, 10) : 10;

  const [page, setPage] = React.useState(pageFromParams);
  const [limit, setLimit] = React.useState(limitFromParams);

  const updatePage = (newPage, newLimit = limit) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", newPage.toString());
    searchParams.set("limit", newLimit.toString());

    // Update URL without reloading
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
    // Update state
    setPage(newPage);
    setLimit(newLimit);
  };

  const currentOffset = (page - 1) * limit;
  // useEffect to get permission and data when loading the screen
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        getOption();
        getData(currentOffset, limitFromParams, searchText, filterData);
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  // Function to get options
  const getOption = async () => {
    const result = await enumSelect([enum_types?.inspection_item_type]);
    setOptions({ item_type: result?.inspection_item_type });
  };

  // Function to get the list based on the input data
  const getData = (
    offset = 0,
    limit = 10,
    search = "",
    filter = defaultFilterData
  ) => {
    let payload = {
      offset,
      limit,
      search,
      is_active: filter?.is_active,
      item_type: filter?.item_type?.map?.((_) => _?.value),
    };
    NetworkCall(
      `${config.api_url}${BackendRoutes?.inspection_item_subcategory_get}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((r) => {
        let temp_response = r?.data?.data ?? [];
        let temp_row = temp_response?.map?.((_) => {
          return {
            id: _?.id,
            item_type: _?.item_type ?? "-",
            category: _?.category_name ?? "-",
            name: _?.name ?? "-",
            is_active: _?.is_active ? "Active" : "Inactive",
            data: _,
          };
        });
        setData({
          row: temp_row ?? [],
          totalRowsCount: r?.data?.count ?? 0,
        });
        setLoader(false);
        handleLoading(false);
      })
      .catch((e) => {
        alert.setSnack({
          ...alert,
          open: true,
          msg: t("Some Thing Went Wrong"),
          severity: AlertProps?.severity?.error,
        });
      });
  };

  // Function for search in search component
  const handleSearch = (e) => {
    setSearchText(e);
    debounce(() => searchTableFunction(e), 800);
  };

  // Function to search data in the list
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getData(0, limit, e, filterData);
  };

  // Function to open add form
  const handleAdd = () => {
    setButtonDisable(false);
    setAddState({
      ...defaultAddState,
      error: {
        item_type: "",
        category: "",
        name: "",
      },
    });
    setAddDialogOpen(true);
  };

  // Function to handle icon in table row
  const handleTableIcon = (type, data) => {
    const tempData = data?.data;
    const tempAddState = {
      formType: type,
      id: tempData?.id,
      item_type:
        options?.item_type?.find((_) => _?.value === tempData?.item_type) ?? "",
      category: tempData?.category_id
        ? { label: tempData?.category_name, value: tempData?.category_id }
        : "",
      name: tempData?.name,
      is_active: type === "active" ? !tempData?.is_active : tempData?.is_active,
      is_delete: type === "delete",
      specification: JSON.parse(tempData?.specification) ?? "",
      revenue_account: tempData?.revenue_account ?? "",
      expense_number: tempData?.expense_number ?? "",
      assets_number: tempData?.assets_number ?? "",
      error: {
        item_type: "",
        category: "",
        name: "",
      },
    };
    setAddState({ ...tempAddState });
    if (type === "edit" || type === "view") {
      setButtonDisable(false);
      setAddDialogOpen(true);
    } else if (type === "active" || type === "delete") {
      handleCreateEdit(tempAddState);
    }
  };

  //more options
  const handleTablePagination = (newPage) => {
    updatePage(newPage, limit);
  };

  const handleTablePageLimit = (newLimit) => {
    updatePage(1, newLimit); // Reset page to 1 when limit changes
  };

  // Function to update addState
  const updateAddDialogState = (k, v) => {
    let error = addState?.error;
    error[k] = "";
    if (k === "item_type") {
      setAddState({ ...addState, [k]: v, category: "", error });
    } else {
      setAddState({ ...addState, [k]: v, error });
    }
  };

  // Function for updating addNewState
  const validate = () => {
    let isValid = true;
    let error = addState.error;
    if (addState?.item_type?.length === 0) {
      isValid = false;
      error.item_type = t("Item Type is Required");
    }
    if (addState?.category?.length === 0) {
      isValid = false;
      error.category = t("Category is Required");
    }
    if (addState?.name?.length === 0) {
      isValid = false;
      error.name = t("Name is Required");
    }
    if (
      addState?.specification?.length === 0 ||
      addState?.specification === "<p><br></p>"
    ) {
      isValid = false;
      error.specification = t("Specification is Required");
    }
    if (!isValid) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Please fill all mandatory field"),
      });
    }
    setAddState({ ...addState, error });
    return isValid;
  };

  // Function to create a leave master
  const handleCreateEdit = async (data) => {
    if (
      data?.formType === "active" || data?.formType === "delete"
        ? true
        : validate()
    ) {
      setButtonDisable(true);
      const currentDate = new Date().toISOString();

      const payload = {
        category_id: data?.category?.value ?? undefined,
        name: data?.name ?? undefined,
        is_active: data?.is_active ?? undefined,
        is_delete: data?.is_delete ?? undefined,
        updated_at: currentDate ?? undefined,
        revenue_account: data?.revenue_account
          ? parseInt(data?.revenue_account, 10)
          : null,
        expense_number: data?.expense_number
          ? parseInt(data?.expense_number, 10)
          : null,
        assets_number: data?.assets_number
          ? parseInt(data?.assets_number, 10)
          : null,
        specification: data?.specification,
      };

      switch (data?.formType) {
        case "add":
          payload.created_at = currentDate ?? undefined;
          break;
        case "edit":
          payload.id = data?.id ?? undefined;
          break;
        case "active":
          payload.id = data?.id ?? undefined;
          break;
        case "delete":
          payload.id = data?.id ?? undefined;
          break;

        default:
          break;
      }

      NetworkCall(
        `${config.api_url}${BackendRoutes?.masters_upsert_item_sub_category}`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((r) => {
          setLoader(true);
          setAddState({ ...defaultAddState });
          setSearchText("");
          getData(currentOffset, limitFromParams, searchText, filterData);
          setAddDialogOpen(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: data?.id
              ? data?.formType === "delete"
                ? t("Item Sub Category Master Deleted Successfully")
                : t("Item Sub Category Master Updated Successfully")
              : t("Item Sub Category Master Created Successfully"),
          });
        })
        .catch((e) => {
          setButtonDisable(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Some Thing Went Wrong"),
          });
        });
    } else {
      return false;
    }
  };

  // Function to filter
  const handleFilter = (value) => {
    setLoader(true);
    setFilterData(value);
    setPage(1);
    getData(0, limit, searchText, value);
  };

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        [{ color: [] }, { background: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ align: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        ["emoji"],
        ["code-block"],
      ],
    },
    "emoji-toolbar": true,
    "emoji-textarea": true,
    "emoji-shortname": true,
  };

  const render = () => {
    return (
      <div>
        <Subheader
          hideBackButton={true}
          title={t("Item Sub Category Master")}
        />
        {loader ? (
          <NewLoader minusHeight="100px" />
        ) : (
          <div className={classes.root}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <SearchFilter
                  value={searchText}
                  placeholder={t("Search Item Sub Category Master")}
                  handleChange={(value) => handleSearch(value)}
                />
              </Grid>
              <Grid item xs={8}>
                <Box display={"flex"} justifyContent={"end"}>
                  <Stack
                    direction="row"
                    spacing={2}
                    divider={<Divider orientation="vertical" flexItem />}
                  >
                    <IconButton
                      onClick={() => setFilterDrawer(!fiterDrawer)}
                      className={classes.filterButton}
                    >
                      <Badge
                        variant="dot"
                        color="primary"
                        invisible={!(filterData.is_active?.length > 0)}
                      >
                        <FilterIMG color="#091b29" />
                      </Badge>
                    </IconButton>
                    <Button
                      variant="contained"
                      className={classes.button}
                      onClick={handleAdd}
                    >
                      {t("Add")}
                    </Button>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
            <TableWithPagination
              heading={Heading(t)}
              rows={data?.row}
              path={Path}
              showpagination={true}
              showpdfbtn={false}
              showexcelbtn={false}
              showSearch={false}
              handleIcon={handleTableIcon}
              onClick={() => console.log("")}
              tableType="no-side"
              dataType={Type}
              handlePagination={handleTablePagination}
              handleChangeLimit={handleTablePageLimit}
              totalRowsCount={data?.totalRowsCount}
              page={page}
              limit={limit}
              height={"calc(100vh - 290px)"}
              view={true}
              edit={true}
              delete={true}
            />
            <FilterGenerator
              open={fiterDrawer}
              onClose={() => setFilterDrawer(false)}
              onApply={(value) => handleFilter(value)}
              defaultState={defaultFilterData}
              components={[
                {
                  component: "toggleButton",
                  value: filterData?.is_active,
                  options: activeOptions(t),
                  isMulti: true,
                  state_name: "is_active",
                  label: t("Status"),
                },
                {
                  component: "select",
                  value: filterData?.item_type,
                  options: options?.item_type,
                  isMulti: true,
                  state_name: "item_type",
                  label: t("Item Type"),
                  placeholder: t("Item Type"),
                },
              ]}
            />
            <Dialog
              className={classes.addDialog}
              open={addDialogOpen}
              onClose={() => setAddDialogOpen(false)}
            >
              <div className={classes.addDialogHeader}>
                <Typography className={classes.addDialogHeaderTitle}>
                  {addState?.formType === "add"
                    ? t("Add Item Sub Category Master")
                    : addState?.formType === "edit"
                    ? t("Edit Item Sub Category Master")
                    : addState?.formType === "view"
                    ? t("View Item Sub Category Master")
                    : t("Add Item Sub Category Master")}
                </Typography>
                <IconButton
                  onClick={() => setAddDialogOpen(false)}
                  className={classes.addDialogCloseButton}
                >
                  <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
                </IconButton>
              </div>
              <div className={classes.addDialogBody}>
                <SelectBox
                  isRequired
                  isReadOnly={addState?.formType === "view"}
                  menuPlacement={"bottom"}
                  label={t("Item Type")}
                  placeholder={t("Select Item Type")}
                  value={addState?.item_type ?? ""}
                  options={options?.item_type ?? []}
                  onChange={(value) => updateAddDialogState("item_type", value)}
                  isError={addState?.error?.item_type?.length > 0}
                  errorMessage={addState?.error?.item_type}
                />
                <Box height={16} />
                <SelectBox
                  key={addState?.item_type?.value}
                  isRequired
                  isReadOnly={addState?.formType === "view"}
                  menuPlacement={"bottom"}
                  label={t("Category")}
                  placeholder={t("Select Category")}
                  value={addState?.category ?? ""}
                  onChange={(value) => updateAddDialogState("category", value)}
                  isPaginate={true}
                  debounceTimeout={800}
                  loadOptions={(search, array, handleLoading) =>
                    loadOptionsApis(
                      BackendRoutes?.inspection_item_category_get,
                      {
                        is_active: [true],
                        item_type: [addState?.item_type?.value],
                      },
                      search,
                      array,
                      handleLoading,
                      "data",
                      { label: "name", value: "id" }
                    )
                  }
                  isError={addState?.error?.category?.length > 0}
                  errorMessage={addState?.error?.category}
                />
                <Box height={16} />
                <TextBox
                  isrequired
                  isReadonly={addState?.formType === "view"}
                  label={t("Name")}
                  placeholder={t("Enter Name")}
                  value={addState?.name ?? ""}
                  onChange={(e) => updateAddDialogState("name", e.target.value)}
                  isError={addState?.error?.name?.length > 0}
                  errorMessage={addState?.error?.name}
                />
                <Box height={16} />
                {/* <div>
                  <Typography className={classes.addDialogFieldLabel}>
                    <Box display="flex" alignItems="center" gap="2px">
                      {t("COA Main Account - Revenue")}
                      <LightTooltip
                        title={t("This field is mandatory when ERP is enabled")}
                        placement="top"
                      >
                        <span
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          <InfoIcon style={{ width: "16px", height: "16px" }} />
                        </span>
                      </LightTooltip>
                    </Box>
                  </Typography>
                  <Box height={5} />
                  <TextBox
                    type="number"
                    isReadonly={addState?.formType === "view"}
                    placeholder={t("Enter Revenue Account")}
                    value={addState?.revenue_account ?? ""}
                    onChange={(e) =>
                      updateAddDialogState("revenue_account", e.target.value)
                    }
                    isError={addState?.error?.revenue_account?.length > 0}
                    errorMessage={addState?.error?.revenue_account}
                  />
                </div>
                <Box height={16} />
                <div>
                  <Typography className={classes.addDialogFieldLabel}>
                    <Box display="flex" alignItems="center" gap="2px">
                      {t("COA Main Number - Expense")}
                      <LightTooltip
                        title={t("This field is mandatory when ERP is enabled")}
                        placement="top"
                      >
                        <span
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          <InfoIcon style={{ width: "16px", height: "16px" }} />
                        </span>
                      </LightTooltip>
                    </Box>
                  </Typography>
                  <Box height={5} />
                  <TextBox
                    type="number"
                    isReadonly={addState?.formType === "view"}
                    placeholder={t("Enter Expense Number")}
                    value={addState?.expense_number ?? ""}
                    onChange={(e) =>
                      updateAddDialogState("expense_number", e.target.value)
                    }
                    isError={addState?.error?.expense_number?.length > 0}
                    errorMessage={addState?.error?.expense_number}
                  />
                </div>
                <Box height={16} />
                <div>
                  <Typography className={classes.addDialogFieldLabel}>
                    <Box display="flex" alignItems="center" gap="2px">
                      {t("COA Main Number - Assets")}
                      <LightTooltip
                        title={t("This field is mandatory when ERP is enabled")}
                        placement="top"
                      >
                        <span
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          <InfoIcon style={{ width: "16px", height: "16px" }} />
                        </span>
                      </LightTooltip>
                    </Box>
                  </Typography>
                  <Box height={5} />
                  <TextBox
                    type="number"
                    isReadonly={addState?.formType === "view"}
                    placeholder={t("Enter Assets Number")}
                    value={addState?.assets_number ?? ""}
                    onChange={(e) =>
                      updateAddDialogState("assets_number", e.target.value)
                    }
                    isError={addState?.error?.assets_number?.length > 0}
                    errorMessage={addState?.error?.assets_number}
                  />
                </div> */}
                <Box height={16} />
                <Label isRequired label={t("Specifications")} />
                <ReactQuill
                  onChange={(value) =>
                    updateAddDialogState("specification", value)
                  }
                  value={addState?.specification}
                  modules={modules}
                  className={classes.reactQuil}
                  theme="snow"
                  readOnly={addState?.formType === "view"}
                />
                {addState?.error?.specification?.length > 0 && (
                  <Typography variant={"caption"} color={"error"}>
                    {addState?.error?.specification}
                  </Typography>
                )}
                <Box height={16} />
                <Typography className={classes.addDialogFieldLabel} noWrap>
                  {t("Status")}
                </Typography>
                <div className={classes.addDialogButtonContainer}>
                  {activeOptionsList?.map((_) => {
                    return (
                      <Button
                        className={
                          _?.value === addState?.is_active
                            ? classes.addDialogButtonSelected
                            : classes.addDialogButtonUnSelected
                        }
                        onClick={() =>
                          addState?.formType === "add" ||
                          addState?.formType === "edit"
                            ? updateAddDialogState("is_active", _?.value)
                            : false
                        }
                      >
                        {t(_?.label)}
                      </Button>
                    );
                  })}
                </div>
              </div>
              {(addState?.formType === "add" ||
                addState?.formType === "edit") && (
                <div className={classes.addDialogFooter}>
                  {addState?.formType === "edit" && (
                    <>
                      <Button
                        fullWidth
                        className={classes.addDialogFooterCloseButton}
                        onClick={() => setAddDialogOpen(false)}
                      >
                        {t("Cancel")}
                      </Button>
                    </>
                  )}
                  <Button
                    variant="contained"
                    fullWidth
                    className={classes.addDialogFooterButton}
                    disabled={buttonDisable}
                    onClick={() => handleCreateEdit(addState)}
                  >
                    {addState?.formType === "add" ? t("Create") : t("Save")}
                  </Button>
                </div>
              )}
            </Dialog>
          </div>
        )}
      </div>
    );
  };

  return <div>{accessCheckRender(render, permission, "", loading)}</div>;
};
export default withTranslation("itemSubCategoryMaster")(ItemSubCategoryMaster);
