import { Box } from "@mui/material";
import { withTranslation } from "react-i18next";
import { DetailsCardCoponent } from "../../../components";

const ConfirmOrdersInformation = ({ details = {}, t = () => false }) => {
    const card1 = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("Order ID"),
            subtitle: `${details?.order_reference??"-"}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("Order Date"),
            subtitle: `${details?.order_date??"-"}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("Amount"),
            subtitle: `${details?.order_value??"-"}`
        },
    ]



    return (
        <Box p={2}>
            <DetailsCardCoponent components={card1} />
            {/* <Box height="12px" /> */}
        </Box>
    )
}
export default withTranslation("agreement")(ConfirmOrdersInformation); 