import { Box, Grid, Hidden, Skeleton } from "@mui/material";
import React from "react";
import { config } from "../../config";
import { AlertProps, NetWorkCallMethods, useWindowDimensions } from "../../utils";
import { useEffect } from "react";
import { NetworkCall } from "../../networkcall";
import { BackendRoutes } from "../../router/routes";
import { useState } from "react";
import { LoginPage } from "./loginPage";
import { withTranslation } from "react-i18next";
import { useStyles } from "./style";
import { getLeftSectionGrid, getRightSectionGrid, initital_screen_state } from "../../utils/login/utils";
import { useContext } from "react";
import { AlertContext } from "../../contexts";

const Login = ({
  t = () => false,
}) => {
  const size = useWindowDimensions()
  const classes = useStyles({ size });
  const alert = useContext(AlertContext);
  const [is_loading, set_is_loading] = useState(true);
  const [isBackgroundImageLoading, setIsBackgroundImageLoading] = useState(true);
  const [screen_state, set_screen_state] = useState(initital_screen_state);
  const [term_and_condition_state, set_term_and_condition_state] = useState({});
  const [region_list, set_region_list] = useState({});

  useEffect(() => {
    initialLoad();
    getRegionList();
    getTermsAndCondition();
    // eslint-disable-next-line
  }, []);

  const initialLoad = () => {
    getInitialRender();
  }

  const getInitialRender = () => {
    NetworkCall(
      `${config?.authapi}${BackendRoutes?.login_domain_initial_render}`,
      NetWorkCallMethods?.post, {}, null, false, false)
      .then((res) => {
        let is_custom_domain = res?.data?.data?.is_custom_domain;
        let _ = res?.data?.data?.render_data?.[0]
        if (_) {
          let temp_screen_state = {
            ...screen_state,
            [_?.id && "id"]: _?.id,
            [_?.region && "region"]: _?.region,
            [_?.login_logo && "logo"]: _?.login_logo,
            [_?.web_background && "web_background"]: _?.web_background,
            [_?.mobile_background && "mob_background"]: _?.mobile_background,
            [_?.web_welcome && "web_welcome_background"]: _?.web_welcome,
            [_?.mobile_welcome && "mob_welcome_background"]: _?.mobile_welcome,
            [_?.flag && "flag"]: _?.flag,
            [_?.domain && "domain"]: _?.domain,
            [is_custom_domain && "is_custom_domain"]: is_custom_domain,
            [_?.country_code && "country_code"]: _?.country_code,
          }
          set_screen_state(temp_screen_state);
        }
        set_is_loading(false);
      }).catch((error) => {
        alert.setSnack({
          ...alert, open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
        set_is_loading(false);
      });
  }

  const getTermsAndCondition = (token = "") => {
    const payload = { client: token?.client, }
    NetworkCall(
      `${config.authapi}${BackendRoutes?.client_terms_and_conditions}`,
      NetWorkCallMethods.post, payload, false, false
    ).then((response) => {
      set_term_and_condition_state(response?.data?.data);
    }).catch((err) => {
      alert.setSnack({
        ...alert, open: true,
        severity: AlertProps.severity.error,
        msg: t("Some Thing Went Wrong"),
      });
    });
  };

  const getRegionList = () => {
    NetworkCall(
      `${config.authapi}${BackendRoutes?.login_domain_domain_list}`,
      NetWorkCallMethods.post, {}, false, false
    ).then((response) => {
      set_region_list(response?.data?.data?.domainList ?? []);
    }).catch((err) => {
      alert.setSnack({
        ...alert, open: true,
        severity: AlertProps.severity.error,
        msg: t("Some Thing Went Wrong"),
      });
    });
  };

  const handleImageOnLoad = () => {
    setIsBackgroundImageLoading(false);
  }

  const handleImageOnError = () => {
    set_screen_state({ ...screen_state, web_background: initital_screen_state?.web_background });
  }

  return (
    <>
      <Hidden smUp>
        {is_loading
          ? <></>
          : <LoginPage t={t} size={size} region_list={region_list}
            term_and_condition_state={term_and_condition_state}
            screen_state={screen_state} set_screen_state={set_screen_state}
            initital_screen_state={initital_screen_state} />
        }
      </Hidden>
      <Hidden smDown>
        {is_loading
          ? <></>
          : <Box className={classes.root}>
            <Grid container className={classes.grid}>
              <Grid item xs={getLeftSectionGrid(size)} className={classes.left_section}>
                {size?.width > 599 &&
                  <>
                    {isBackgroundImageLoading && <Skeleton variant="rounded" className={classes.web_background} />}
                    <img className={classes.web_background}
                      style={{ display: isBackgroundImageLoading ? "none" : "block" }}
                      src={screen_state?.web_background} alt=""
                      onLoad={handleImageOnLoad}
                      onError={handleImageOnError} />
                  </>}
              </Grid>
              <Grid item
                xs={getRightSectionGrid(size)}
                className={classes.right_section}>
                <LoginPage t={t} size={size} region_list={region_list}
                  term_and_condition_state={term_and_condition_state}
                  screen_state={screen_state} set_screen_state={set_screen_state}
                  initital_screen_state={initital_screen_state} />
              </Grid>
            </Grid>
          </Box>
        }
      </Hidden>
    </>
  );
};
export default withTranslation("login")(Login)
