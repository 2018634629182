import * as React from "react"
import { FontFamilySwitch } from "../../utils"

export const NoFileImage = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={333.063}
    height={294.512}
    {...props}
  >
    <g data-name="Group 107579" transform="translate(-667 -337)">
      <ellipse
        data-name="Ellipse 129256"
        cx={148.725}
        cy={125.536}
        rx={148.725}
        ry={125.536}
        transform="rotate(-9 2770.112 -4045.754)"
        fill="#f1f7ff"
      />
      <text
        data-name="Oops! There are no files to show."
        transform="translate(721.467 557.91)"
        fill="#4e5a6b"
        fontSize={14}
        fontFamily={FontFamilySwitch().semiBold}
        fontWeight={700}
      >
        <tspan x={0} y={0}>
          {"Oops! There are no files to show."}
        </tspan>
      </text>
      <g data-name="Mesa de trabajo 1">
        <g data-name="Group 101475">
          <path
            data-name="Rectangle 53578"
            fill="#98a0ac"
            stroke="#f1f7ff"
            d="m728.451 442.981 41.567-27.803 40.938 61.206-41.567 27.802z"
          />
          <g data-name="Group 101474" fill="#e0e0e0">
            <path
              data-name="Path 96570"
              d="m789.478 473.481-.472 2.92-20.663-2.977.472-2.919Z"
            />
            <path
              data-name="Path 96571"
              d="m779.075 463.067 2.965.428-3.287 20.344-2.965-.427Z"
            />
          </g>
          <path
            data-name="Path 96572"
            d="M760.042 437.209c.03.044-4.136 2.882-9.3 6.338s-9.381 6.223-9.411 6.178 4.135-2.882 9.3-6.339 9.382-6.221 9.411-6.177Z"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 96573"
            d="M775.329 433.372c.03.044-6.9 4.729-15.47 10.463s-15.549 10.348-15.579 10.3 6.9-4.728 15.471-10.464 15.548-10.343 15.578-10.299Z"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 96574"
            d="M778.393 437.953c.03.044-6.9 4.729-15.47 10.463s-15.549 10.348-15.578 10.3 6.9-4.728 15.471-10.464 15.547-10.343 15.577-10.299Z"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 96575"
            d="M781.457 442.534c.03.044-6.9 4.729-15.47 10.463s-15.549 10.348-15.578 10.3 6.9-4.728 15.471-10.464 15.547-10.343 15.577-10.299Z"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 96576"
            d="M784.521 447.115c.03.044-6.9 4.729-15.47 10.463s-15.549 10.348-15.579 10.3 6.9-4.728 15.471-10.464 15.547-10.347 15.577-10.3Z"
            fill="#e0e0e0"
          />
        </g>
        <g data-name="Group 101477">
          <path
            data-name="Rectangle 53579"
            fill="#71798a"
            stroke="#f1f7ff"
            d="m873.533 479.456 34.91-23.35 34.38 51.403-34.91 23.349z"
          />
          <g data-name="Group 101476" fill="#e4e8ee">
            <path
              data-name="Path 96577"
              d="m924.788 505.072-.4 2.452-17.354-2.5.4-2.452Z"
            />
            <path
              data-name="Path 96578"
              d="m916.048 496.325 2.49.359-2.761 17.086-2.49-.359Z"
            />
          </g>
          <path
            data-name="Path 96579"
            d="M900.064 474.609c.03.044-3.465 2.434-7.8 5.336s-7.883 5.22-7.912 5.176 3.464-2.433 7.8-5.336 7.883-5.223 7.912-5.176Z"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 96580"
            d="M912.9 471.386c.03.044-5.784 3.985-12.983 8.8s-13.062 8.685-13.092 8.641 5.783-3.984 12.985-8.8 13.062-8.689 13.09-8.641Z"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 96581"
            d="M915.475 475.233c.03.044-5.784 3.985-12.983 8.8s-13.062 8.685-13.092 8.641 5.783-3.984 12.985-8.8 13.061-8.685 13.09-8.641Z"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 96582"
            d="M918.048 479.08c.03.044-5.784 3.985-12.983 8.8s-13.062 8.685-13.092 8.641 5.783-3.984 12.985-8.8 13.061-8.684 13.091-8.64Z"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 96583"
            d="M920.622 482.928c.03.044-5.784 3.985-12.983 8.8s-13.062 8.685-13.092 8.64 5.783-3.984 12.985-8.8 13.061-8.684 13.09-8.64Z"
            fill="#e0e0e0"
          />
        </g>
        <g data-name="Group 101479">
          <path
            data-name="Rectangle 53580"
            fill="#57698d"
            stroke="#f1f7ff"
            d="m815.471 397.128 38.097 20.388-30.02 56.097-38.097-20.388z"
            strokeWidth={1.00035}
          />
          <g data-name="Group 101478" fill="#e4e8ee">
            <path
              data-name="Path 96584"
              d="m817.045 456.039-2.416.828-6.134-16.957 2.417-.828Z"
            />
            <path
              data-name="Path 96585"
              d="m820.746 443.874.881 2.433-16.841 5.768-.88-2.433Z"
            />
          </g>
          <path
            data-name="Path 96586"
            d="M832.716 418.854c-.03.056-3.893-1.952-8.628-4.484s-8.55-4.631-8.52-4.687 3.892 1.952 8.628 4.485 8.55 4.631 8.52 4.686Z"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 96587"
            d="M841.856 428.944c-.03.056-6.423-3.305-14.279-7.507s-14.2-7.654-14.172-7.709 6.422 3.3 14.28 7.507 14.2 7.653 14.171 7.709Z"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 96588"
            d="M839.611 433.142c-.03.056-6.423-3.305-14.279-7.507s-14.2-7.654-14.172-7.709 6.422 3.3 14.28 7.507 14.2 7.653 14.171 7.709Z"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 96589"
            d="M837.366 437.339c-.03.056-6.423-3.305-14.279-7.507s-14.2-7.654-14.172-7.709 6.422 3.3 14.28 7.507 14.2 7.653 14.171 7.709Z"
            fill="#e0e0e0"
          />
          <path
            data-name="Path 96590"
            d="M835.121 441.537c-.03.056-6.423-3.305-14.279-7.507s-14.2-7.654-14.172-7.709 6.422 3.3 14.28 7.507 14.205 7.653 14.171 7.709Z"
            fill="#e0e0e0"
          />
        </g>
        <g data-name="Group 101483">
          <path
            data-name="Path 96593"
            d="m799.313 456.677-13.1 67.347-1.715 8.816 87.45-.431c3.924-.019 7.4-3.5 8.665-8.681l15.507-63.583c.959-3.93-1.145-8.018-4.124-8.015l-88.509.093c-1.954 0-3.663 1.825-4.174 4.454Z"
            fill="#f1f7ff"
          />
          <g data-name="Group 101481" opacity={0.5}>
            <path
              data-name="Path 96594"
              d="m799.312 456.677-13.1 67.347-1.715 8.816 87.45-.431c3.924-.019 7.4-3.5 8.665-8.681l15.507-63.583c.959-3.93-1.145-8.018-4.124-8.015l-88.509.093c-1.954 0-3.663 1.825-4.174 4.454Z"
              fill="#6982b4"
              stroke="#5078e1"
            />
          </g>
          <path
            data-name="Path 96595"
            d="M863.559 519.461 854.3 444.85a5.872 5.872 0 0 0-5.69-5.328l-20.3.066a5.532 5.532 0 0 0-4.011 1.778l-9.641 10.248-56.466.295a6.007 6.007 0 0 0-5.644 6.952l8.6 68.96a5.872 5.872 0 0 0 5.683 5.325l98.409-.194c10.96.227 12.007-3.01 12.007-3.01-12.561 2.784-13.68-10.472-13.681-10.48Z"
            fill="#5078e1"
          />
          <g data-name="Group 101482" opacity={0.5} fill="#fff">
            <path
              data-name="Path 96596"
              d="m821.126 504.529-3.319 3.564-25.562-23 3.319-3.564Z"
            />
            <path
              data-name="Path 96597"
              d="m816.416 480.743 3.668 3.3-23.129 24.841-3.668-3.3Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
