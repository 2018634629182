import { makeStyles } from "@mui/styles";
import { FontFamilySwitch, remCalc } from "../../../utils";

export const CreateCardStyle = makeStyles((theme) => ({
    title: {
        fontSize: "1rem",
        color: theme.typography.color.primary,
        fontWeight: "bold",
        marginInlineStart: "16px"
    },
    content: {
        fontSize: "0.875rem",
        color: theme.typography.color.tertiary,
        marginInlineStart: "16px"

    }
}))

export const PublishedPopupStyle = makeStyles((theme) => ({
    content: {
        fontSize: "1rem",
        color: "white",
        fontWeight: "bold"
    },
    btn: {
        height: 40,
        width: 150,
        color: theme.palette.primary.main,
        background: "#fff",
        borderRadius: 4,
        fontSize: "0.875rem",
        fontWeight: "bold",
        textAlign: "center",
        '&.hover': {
            color: theme.palette.primary.main,
            background: "#fff",
        }
    },
    nobtn: {
        height: 40,
        width: 150,
        color: "#fff",
        border: "1px solid #fff",
        background: theme.palette.primary.main,
        borderRadius: 4,
        fontSize: "0.875rem",
        fontWeight: "bold",
        textAlign: "center",
        '&.hover': {
            color: "#fff",
            border: "1px solid #fff",
            background: theme.palette.primary.main,
        }
    },
    dialogConten: {
        padding: "0 !important"


    }
}))


export const PropertyUnitSelectStyle = makeStyles((theme) => ({
    header: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().italic,
        color: theme.typography.color.tertiary
    },
    title: {
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.primary
    },
    subtitle: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.secondary
    },
    stackDivider: {
        borderWidth: "1px",
        borderColor: "black",
        height: "14px",
        marginTop: "4px"
    },
    notSelectedBox: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
        cursor: "pointer"
    },
    selectedBox: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 4,
        background: theme.palette.info.light,
        cursor: "pointer"
    }
}))

export const DetailCardStyle = makeStyles((theme) => ({
    box: {
        // height: 94,
        borderRadius: 8,
        background: "#fff",
        boxShadow: "0px 0px 16px #00000014",
    },
    header: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.secondary
    },
    immediate: {
        color: theme.palette.info.main,
        background: theme.palette.info.light,
        borderRadius: 4,
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        padding: "4px",
        border: `1px solid ${theme.palette.info.main}`
    },
    title: {
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.primary
    },
    subtitle: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.secondary,
        // whiteSpace:"nowrap",
        // textOverflow:"ellipsis",
        // overflow:"hidden"
    },
    avatar: {
        height: 38,
        width: 38,
        borderRadius: 6
    },
    editText: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.palette.info.main,
        textTransform: "capitalize",
        cursor: "pointer"
    },
    isMoreText: {
        fontSize: "0.75rem",
        color: theme.palette.primary.main,
        fontFamily: FontFamilySwitch().semiBold,
    }
}))

export const TableBodyCompStyle = makeStyles((theme) => ({
    tableCell: {
        border: "none",
        padding: "12px 8px 8px 0px"
    }
}))

export const BottomTotalCardStyle = makeStyles((theme) => ({
    title: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().extraBold,
        color: theme.typography.color.secondary
    },
    subtitle: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
    }
}))

export const TypeCategoryCompStyle = makeStyles((theme) => ({
    selectBox: {
        height: 32,
        borderRadius: 4,
        border: `1px solid ${theme.palette.border.secondary}`,
        cursor: "pointer"
    },
    disabledBox: {
        height: 32,
        borderRadius: 4,
        border: `1px solid ${theme.palette.border.secondary}`,
        // background: theme.palette.background.secondary,
        cursor: "pointer"
    },
    placeholder: {
        fontSize: remCalc(14),
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.tertiary,
        width: "100%",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        paddingLeft: "8px"
    },
    label: {
        fontSize: remCalc(14),
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary,
        padding: 8,
        cursor: "pointer"
    },
    selectedLabel: {
        fontSize: remCalc(14),
        fontFamily: FontFamilySwitch().bold,
        color: theme.palette.primary.main,
        padding: 8,
        cursor: "pointer"
    },
    box: {
        height: 180,
        overflow: "auto",
    },
    menu: {
        "& .MuiMenu-list": {
            paddingTop: "0px"
        },
        "& .MuiPopover-paper": {
            boxShadow: "0px 0px 6px #0717411F",
            borderRadius: theme.palette.borderRadius,
            backgroundColor: "white",
          },
    },
    
    valueText: {
        fontSize: remCalc(14),
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.primary,
        width: "100%",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        paddingLeft: "8px"
    }
}))