import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch, remCalc } from "../../../utils";
export const useStyles = makeStyles((theme) => ({
    label: {
        color: "#98A0AC",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: "0.875rem",
        marginBottom: "5px"
    },
    title: {
        color: "#091B29",
        fontFamily: FontFamilySwitch().bold,
        fontSize: "0.75rem",
    },
    labellogo: {
        color: "#98A0AC",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: "0.75rem",
        marginBottom: "5px"
    },
    previewRoot: {
        border: "1px solid #E4E8EE",
        borderRadius: "4px",
        padding: "12px"
    },
    gatWayRoot: {
        backgroundColor: "#F5F7FA",
        borderBottom: "1px solid #E4E8EE"
    },
    previewImg: {
        height: "200px",
        objectFit: "cover"
    },
    imagepreviewRoot: {
        position: "relative"
    },
    logo: {
        height: "36px",
        width: "100px",
        position: "absolute",
        top: "8px",
        left: "0px",
        objectFit: "contain"
    },
    logo1: {
        height: "32px",
        width: "100px",
        position: "absolute",
        top: "12px",
        left: "0px",
        objectFit: "contain"
    },
    root: {
        padding: "12px",
        borderRadius: "4px",
        boxShadow: "0px 1px 16px #00000014",
        background: theme.palette.background.tertiary,
        margin: 8
    },
    upfrontTitle: {
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: remCalc(14),
        paddingLeft: 8
    },
    upfrontBox: {
        width: "150px",
        [theme.breakpoints.only("sm")]: {
            width: "100px"
        }
    },
    dynamicTitle: {
        fontSize: "0.875rem",
        color: "#091B29",
        fontFamily: FontFamilySwitch().bold,
    }
}));