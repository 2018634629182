import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const PriorityStyles = makeStyles((theme) => ({
  priorityBox: {
    padding: "10px",
    borderRadius: theme.palette.borderRadius,
    display: "flex",
    justifyContent: "space-between",
    alignItems:"center",
    backgroundColor: "#FFFFFF",
    marginTop:(props)=>props?.marginTop??"6px",
    border: "1px solid #E4E8EE",
    cursor: "pointer",
    height:(props)=> props?.customHeight??"45px"

  },
  priorityLabel: {
    fontSize:"0.75rem",
    color: theme.typography.color.tertiary,
    fontFamily: FontFamilySwitch().semiBold,
    marginBottom: "5px"
  },
  initialText: {
    color: "white",
    textTransform: "capitalize",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize:"0.875rem"

  },
  arrowIcon: {
    color: "white"
  },
  menuOptions: {
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    borderRadius: "4px",
    marginTop: "2px",
    padding: "12px"
  },
  dot: {
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    backgroundColor: "black",
    marginTop: "6px"
  },
  priorityDivider: {
    // marginBottom:"5px"
  },
  priorityName: {
    fontSize:"0.875rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
    textTransform: "capitalize",
  },
  menu: {
    "& .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper":
    {
      borderRadius: theme.palette.borderRadius,
      padding: "0px !important",
      boxShadow: "0px 8px 24px #0717411F",

    },
    "& .MuiMenu-list": {
      padding: "0 !important",
    },
  },
  required: {
    color: "red",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: (props) => props?.labelSize ?? theme.palette.labelFontSize,
    marginBottom: (props) => props?.labelMarginBottom ?? "5px",
  },
  dropDown: {
    boxShadow: "0px 0px 6px #0717411F",
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "white",
    position: "absolute",
    left: 0,
    right: 0,
    top: (props) => props?.menuPlacement === "top" ? "-160px" : "auto",
    border: "1px solid #E4E8EE",
    marginTop: "4px",
    zIndex: 999
  },

  optionParent: {
    padding: '10px 12px'
  }
}))