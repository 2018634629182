import { LocalStorageKeys } from "../../utils";

export const createState = {
  id: "",
  name: "",
  property_name: "",
  purpose: "",
  developer: "",
  property_type: "",
  category: "",
  address: "",
  revenue_type: "",
  unit_type: "",
  total_area: "",
  uom_id: "",
  bed: "",
  bath: "",
  latitude: "",
  longitude: "",
  notes: "",
  created_by: "",
  updated_by: "",
  client: "",
  payment_period: "",
  pricingData: {
    primary: "",
    secondary: [],
    refundable: [],
    onetime: [],
    other: [],
  },
  deletePricing: [],
  assestData: [],
  deleteAssets: [],
  ListingData: {
    portal_url: "",
    portal_provider: "",
    reference_id: "",
    agent: "",
    phone: "",
  },
  error: {
    name: "",
    property_name: "",
    purpose: "",
    developer: "",
    property_type: "",
    category: "",
    address: "",
    revenue_type: "",
    unit_type: "",
    total_area: "",
    uom_id: "",
    bed: "",
    bath: "",
    latitude: "",
    longitude: "",
    notes: "",
    created_by: "",
    updated_by: "",
    client: "",
    payment_period: "",
    pricingData: "",
    deletePricing: "",
    assestData: "",
    deleteAssets: "",
    ListingData: {
      phone: "",
    },
  },
};

export const constructPayload = (unitData, existingPricing) => {
  let pricing_data = [];
  let deleted_pricing = [];

  // Helper function to find deleted items from a category
  const findDeletedPricings = (existingCategory, currentCategory) => {
    if (!Array.isArray(existingCategory)) return [];
    return existingCategory
      .filter(
        (existing) =>
          existing.id &&
          !currentCategory?.some((current) => current.id === existing.id)
      )
      .map((item) => item.id);
  };

  deleted_pricing = [
    ...findDeletedPricings(
      existingPricing?.refundable,
      unitData?.pricingData?.refundable
    ),
    ...findDeletedPricings(
      existingPricing?.onetime,
      unitData?.pricingData?.onetime
    ),
    ...findDeletedPricings(
      existingPricing?.secondary,
      unitData?.pricingData?.secondary
    ),
    ...findDeletedPricings(
      existingPricing?.other,
      unitData?.pricingData?.other
    ),
  ];

  if (unitData?.pricingData?.primary?.length > 0) {
    if (!existingPricing?.primary1) {
      // If no existing pricing, add the new pricing
      pricing_data.push({
        value: unitData?.pricingData?.primary,
        is_primary: true,
        payment_period: unitData?.payment_period,
      });
    } else {
      if (
        existingPricing?.primary1?.toString() ===
        unitData.pricingData.primary.toString()
      ) {
        // If values match, keep the existing pricing with ID
        pricing_data.push({
          ...existingPricing.primary1,
          is_primary: true,
          payment_period: unitData?.payment_period,
        });
      } else {
        // If values don't match, update the new value
        pricing_data.push({
          ...existingPricing.primary1,
          value: unitData.pricingData.primary,
          is_primary: true,
          payment_period: unitData?.payment_period,
        });
      }
    }
  }

  const cleanPrice = (price, extraFields = {}) => {
    const { error, ...cleanedPrice } = price;
    return { ...cleanedPrice, ...extraFields };
  };

  ["secondary", "refundable", "onetime"].forEach((key) => {
    if (unitData?.pricingData[key]?.length > 0) {
      if (key !== "secondary") {
        pricing_data.push(
          cleanPrice(unitData.pricingData[key][0], {
            [`is_${key}`]: true,
            payment_period: "Total",
          })
        );
      } else {
        pricing_data.push(
          cleanPrice(unitData.pricingData[key][0], {
            [`is_${key}`]: true,
            payment_period: unitData?.payment_period,
          })
        );
      }
    }
  });

  if (unitData?.pricingData.other?.length > 0) {
    pricing_data.push(
      cleanPrice(unitData.pricingData.other[0], { payment_period: "Total" })
    );
  }

  let payload = {
    name: unitData?.name,
    property_name: unitData?.property_name,
    purpose: unitData?.purpose?.value,
    developer: unitData?.developer?.id,
    property_type: unitData?.property_type?.value,
    category: unitData?.category?.id,
    address: unitData?.address,
    revenue_type: unitData?.revenue_type?.value,
    unit_type: unitData?.unit_type?.value,
    total_area: unitData?.total_area,
    uom_id: unitData?.uom_id?.value,
    bed: unitData?.bed,
    bath: unitData?.bath,
    latitude: unitData?.latitude,
    longitude: unitData?.longitude,
    notes: unitData?.notes,
    client: localStorage.getItem(LocalStorageKeys.clinetID),
    payment_period: unitData?.payment_period,
    pricingData: pricing_data,
    deletePricing: deleted_pricing,
    assestData: unitData?.assestData?.map(
      ({ asset_type, priority, ...rest }) => rest
    ),
    deleteAssets: unitData?.deleteAssets,
    ListingData: {
      reference_id: unitData?.ListingData?.reference_id,
      portal_url: unitData?.ListingData?.portal_url,
      portal_provider: unitData?.ListingData?.portal_provider,
      agent: unitData?.ListingData?.agent,
      phone: unitData?.ListingData?.phone?.mobile,
      country_code: unitData?.ListingData?.mobile?.mobile_code,
    },
  };

  return payload;
};

export const returnEditData = (data) => {
  const pricingData = {
    primary: null,
    primary1: null,
    secondary: [],
    refundable: [],
    onetime: [],
    other: [],
  };
  data?.pricing.forEach((price) => {
    if (price.is_primary) {
      pricingData.primary = price?.value;
      pricingData.primary1 = price;
    } else if (price.is_secondary) {
      pricingData.secondary = [price];
    } else if (price.is_refundable) {
      pricingData.refundable = [price];
    } else if (price.is_onetime) {
      pricingData.onetime = [price];
    } else {
      pricingData.other = [price];
    }
  });
  let unitData = {
    id: data?.id,
    name: data?.name,
    property_name: data?.property_name,
    purpose: { label: data?.purpose, value: data?.purpose },
    developer: data?.vendor[0]
      ? {
          ...data?.vendor[0],
          label: data?.vendor[0]?.name,
          id: data?.developer,
          value: data?.developer,
        }
      : "",
    property_type: {
      label: data?.propertyType?.group_name,
      value: data?.property_type,
    },
    category: {
      id: data?.category,
      value: data?.category,
      name: data?.unitCategory?.name,
      label: data?.unitCategory?.name,
    },
    address: data?.address,
    revenue_type: {
      label: data?.revenue_type,
      value: data?.revenue_type,
    },
    unit_type: {
      label: data?.unitType?.name,
      value: data?.unit_type,
    },
    total_area: data?.total_area,
    uom_id: {
      value: data?.uom_id,
      label: data?.uom?.name,
    },
    bed: data?.bed,
    bath: data?.bath,
    latitude: data?.latitude,
    longitude: data?.longitude,
    notes: data?.notes,
    created_by: data?.created_by,
    updated_by: "",
    client: data?.client,
    payment_period: data?.pricing?.[0]?.payment_period,
    pricingData: pricingData,
    deletePricing: [],
    assestData: data?.assets.map((asset) => ({
      ...asset,
      asset_type: 2,
    })),
    deleteAssets: [],
    ListingData: {
      portal_url: data?.listings?.portal_url,
      portal_provider: data?.listings?.portal_provider,
      reference_id: data?.listings?.reference_id,
      agent: data?.listings?.agent,
      phone: data?.listings?.phone
        ? {
            mobile: data?.listings?.phone,
            mobile_code: data?.listings?.country_code,
          }
        : "",
    },
    error: {
      name: "",
      property_name: "",
      purpose: "",
      developer: "",
      property_type: "",
      category: "",
      address: "",
      revenue_type: "",
      unit_type: "",
      total_area: "",
      uom_id: "",
      bed: "",
      bath: "",
      latitude: "",
      longitude: "",
      notes: "",
      created_by: "",
      updated_by: "",
      client: "",
      payment_period: "",
      pricingData: "",
      deletePricing: "",
      assestData: "",
      deleteAssets: "",
      ListingData: {
        phone: "",
      },
    },
  };

  return { unitData, pricingData };
};
