import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const useStyle = makeStyles((theme, props) => ({
  root: {
    padding: "24px",
    backgroundColor: theme.palette.background.secondary,
    height: `calc(100vh - 64px)`,
    overflow: "auto",
  },
  header: {
    backgroundColor: "#fff",
    padding: "16px",
  },
  icon: {
    fontSize: "0.875rem",
  },
  iconArabic: {
    fontSize: "0.875rem",
  },
  customTitle: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#091B29",
  },
  customSubTitle: {
    color: "#091B29",
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.75rem",
  },
  customSubTitle1: {
    backgroundColor: "#F6F1FE",
    padding: "6px",
    borderRadius: "8px",
    color: "#896DB3",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.75rem",
  },
  submit: {
    backgroundColor: "#5078E1",
    borderRadius: 4,
    color: "#fff",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.875rem",
    padding: "11px 16px",
    "&:hover": {
      backgroundColor: "#5078E1",
      color: "#fff",
    },
  },
  iconbtn: {
    border: `1px solid ${theme.palette.border.secondary}`,
    backgroundColor: theme.palette.border.secondary,
    borderRadius: "50%",
    cursor: "pointer",
    direction: "ltr",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30px",
    height: "30px",
  },
  cardRoot: {
    boxShadow: "0px 0px 16px #00000014",
    borderRadius: "8px",
    backgroundColor: "#fff",
  },
  cardTitle: {
    color: "#091B29",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.875rem",
  },
  cardSubTitle: {
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.75rem",
  },
  money: {
    color: "#091B29",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.875rem",
  },
  currency: {
    color: "#FF9340",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.75rem",
    backgroundColor: "#FFF4EB",
    borderRadius: 4,
  },
  add: {
    color: "#5078E1",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    cursor: "pointer",
  },
  rootPopUP: {
    position: "relative",
    padding: "24px 25px",
  },
  popUpTitle: {
    color: "#091B29",
    fontFamily: FontFamilySwitch().bold,
    fontSize: "1.125rem",
    marginTop: "19px",
  },
  popUpSubTitle: {
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.875rem",
  },
  cancel: {
    border: "1px solid #CED3DD",
    color: "#091B29",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.875rem",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
      border: "1px solid #CED3DD",
    },
  },
  headTableCell: {
    border: "none !important",
    padding: 0,
  },
  headTitle: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.tertiary,
  },
  table: {
    maxHeight: "calc(100vh - 420px)",
    height: "100%",
    [theme.breakpoints.only("md")]: {
      maxHeight: "calc(100vh - 460px)",
      height: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      maxHeight: "calc(100vh - 460px)",
      height: "100%",
    },
  },
  headCell: {
    padding: "12px 8px 0px 8px",
    border: "none",
  },
  headText: {
    fontSize: "0.75rem",
    color: theme.typography.color.tertiary,
    fontFamily: FontFamilySwitch().semiBold,
    whiteSpace: "nowrap",
  },
  bodyCell: {
    padding: "4px",
    border: "none",
    minWidth: "125px",
  },
  bodyCell2: {
    padding: "4px 4px 4px 8px",
    border: "none",
    minWidth: "160px",
    position: "sticky",
    right: "57px",
    zIndex: 1,
    backgroundColor: "#fff",
    borderLeft: "1px solid #E4E8EE",
  },
  bodyCell1: {
    padding: "4px 4px 4px 8px",
    border: "none",
    position: "sticky",
    right: 0,
    zIndex: 1,
    backgroundColor: "#fff",
    minWidth: "164px",
    borderLeft: "1px solid #E4E8EE",
  },
  bodyText: {
    fontSize: "0.875rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
  },
  headCell2: {
    padding: "16px 12px 0px 16px",
    border: "none",
    minWidth: "125px",
    position: "sticky",
    right: "57px",
    zIndex: 999,
    backgroundColor: "#fff",
    borderLeft: "1px solid #E4E8EE",
  },
  headCell1: {
    padding: "12px 12px 0px 16px",
    border: "none",
    position: "sticky",
    right: 0,
    zIndex: 999,
    backgroundColor: "#fff",
    minWidth: "125px",
    borderLeft: "1px solid #E4E8EE",
  },
  bodyCellAuto: {
    padding: "4px 10px",
    border: "none",
    "& .MuiCheckbox-root": {
      padding: "5px !important",
    },
  },
  headCellIcon: {
    padding: "12px 8px 0px 12px",
    border: "none",
  },
  popover: {
    "& .MuiPopover-paper": {
      boxShadow: "0px 0px 6px #0717411F",
      borderRadius: theme.palette.borderRadius,
      backgroundColor: "white",
    },
  },
  menuItem1: {
    borderBottom: "1px solid #E4E8EE",
    margin: "0px 4px",
    fontSize: "0.875rem",
    color: "#071741",
    fontFamily: FontFamilySwitch().semiBold,
    padding: "4px",
    "&:last-child": {
      border: 0,
    },
  },
  proceedDialog: {
    padding: "54px 50px 51px 228px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundImage: `url(${"images/announsment.svg"})`,
    backgroundRstatusComponentSubtitleepeat: "no-repeat",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme?.palette?.primary?.main,
  },
  convert: {
    color: "white",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
  },
  convertProcess: {
    color: "white",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().regular,
    marginTop: "7px",
  },
  yes: {
    color: theme?.palette?.primary?.main,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    border: `1px solid white`,
    backgroundColor: "white",
    padding: "7px 29px",
    width: "100%",
    "&:hover": {
      border: `1px solid white`,
      backgroundColor: "white",
    },
  },
  No: {
    color: "white",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    border: `1px solid white`,
    padding: "7px 29px",
    backgroundColor: theme?.palette?.primary?.main,
    marginInlineStart: "10px",
    width: "100%",
    "&:hover": {
      border: `1px solid white`,
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
  proceedDialogArabic: {
    padding: "54px 50px 51px 228px",
    display: "flex",
    alignItems: "center",
    // justifyContent: "flex-end",
    backgroundImage: `url(${"images/announsment.svg"})`,
    backgroundRstatusComponentSubtitleepeat: "no-repeat",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme?.palette?.primary?.main,
  },
  marginTop: {
    marginTop: "24px",
  },
}));
