import React from "react"
import { withTranslation } from "react-i18next"
import { TableWithPagination } from "../../../components"
import { SummaryDataType, SummaryPath, WalletDataType, WalletPath, getSummaryHeading, getWalletHeading } from "../../../utils/agreementUtils"
import { Box, Typography } from "@mui/material"
import { AggrementTabStyles } from "./styles"

const WalletCredits = ({ t = () => false, details = {}, type = "" }) => {
    const classes = AggrementTabStyles()
    return (
        <Box p={2}>
            <Box>
                <TableWithPagination
                    heading={getWalletHeading(type, t)}
                    path={WalletPath}
                    rows={type === "wallet" ? details?.wallet_credit : type === "late_fee" ? details?.late_fee : type === "commission_bonus" ? details?.commission_bonus : type === "wallet_item" ? details?.wallet_item : []}
                    dataType={WalletDataType}
                    showpagination={false}
                    count="2"
                    showpdfbtn={false}
                    showexcelbtn={false}
                    showSearch={false}
                    tableType="no-side"
                    view={true}
                    edit={true}
                    delete={true} />
            </Box>
            {(type === "wallet" ? details?.wallet_credit?.length > 0 : type === "late_fee" ? details?.late_fee?.length > 0 : type === "commission_bonus" ? details?.commission_bonus?.length > 0 : details?.wallet_item?.length > 0) &&
                <>
                    <Box mt={1}>
                        <Typography className={classes.summarytitle}>{t("Summary")}</Typography>
                    </Box>
                    <Box mt={1}>
                        <TableWithPagination
                            heading={getSummaryHeading(t)}
                            path={SummaryPath}
                            rows={type === "wallet" ? details?.wallet_credit_summary : type === "late_fee" ? details?.late_fee_summary : type === "commission_bonus" ? details?.commission_and_payments_summary : type === "wallet_item" ? details?.wallet_item_summary : []}
                            dataType={SummaryDataType}
                            showpagination={false}
                            count="2"
                            showpdfbtn={false}
                            showexcelbtn={false}
                            showSearch={false}
                            tableType="no-side"
                            height={`calc(100vh - 450px)`}
                            view={true}
                            edit={true}
                            delete={true} />
                    </Box>
                </>
            }
        </Box>
    )
}
export default withTranslation("agreement")(WalletCredits)