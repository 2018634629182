import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const useStyles = makeStyles((theme) => ({
  folderRoot: {
    cursor: "pointer",
    padding: "45px",
    backgroundColor: theme?.palette?.info?.light,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative"
  },
  folderParent: {
    border: `1px solid ${theme?.palette?.border?.secondary}`,
    borderRadius: "4px",
  },
  folderName: {
    color: theme?.typography?.color?.primary,
    fontFamily: FontFamilySwitch().bold,
    fontSize:"0.75rem",
    overflowWrap: "anywhere"
  },
  folderSub: {
    color: theme?.typography?.color?.tertiary,
    fontFamily: FontFamilySwitch().regular,
    fontSize:"0.75rem",
    marginTop: "4px",
  },
  menuList: {
    "& .MuiPopover-paper": {
      boxShadow: "0px 0px 6px #0717411F",
      borderRadius: theme.palette.borderRadius,
      backgroundColor: "white",
    },
  },
  more: {
    fontSize: "1.25rem",
    color: "gray",
    cursor: "pointer",
  },
  subRoot: {
    borderTop: `1px solid ${theme?.palette?.border?.secondary}`,
    padding: "12px 0px 12px 12px"
  },
  menuItem: {
    borderBottom: `1px solid ${theme?.palette?.border?.secondary}`,
    margin: "0px 4px",
    fontSize:"0.875rem",
    color: theme?.palette?.secondary?.main,
    fontFamily: FontFamilySwitch().regular,
    "&:last-child": {
      border: 0,
    },
  },
  chip: {
    backgroundColor: theme?.palette?.primary?.main,
    padding: "3px 10px",
    borderRadius: "4px",
    color: "white",
    position: "absolute",
    top: "10px",
    right: "10px",
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().bold
  }
}));
