export const Path = [
  "coa_type",
  "ledger_name",
  "sub_ledger_name",
  "name",
  "number",
  "is_active",
];

export const Heading = (t) => [
  { title: t("Type"), field: "coa_type" },
  { title: t("Main Ledger"), field: "ledger_name" },
  { title: t("Sub Ledger"), field: "sub_ledger_name" },
  { title: t("COA Name"), field: "name" },
  { title: t("COA Code"), field: "number" },
  { title: t("Status"), field: "is_active" },
  { title: "", field: "icon" },
];

export const Type = [
  { type: ["text"], name: "coa_type" },
  { type: ["text"], name: "ledger_name" },
  { type: ["long_text"], name: "sub_ledger_name" },
  { type: ["long_text"], name: "name" },
  { type: ["text"], name: "number" },
  { type: ["status"], name: "is_active" },
  { type: ["more"], icon: "icon" },
];

export const defaultAddState = {
  formType: "add",
  name: "",
  reference_number: "",
  ledger: "",
  subLedger: "",
  type: "",
  number: "",
  opening_balance: "",
  credit_balance: "",
  debit_balance: "",
  opening_balance_date: null,
  is_active: true,
  error: {
    name: "",
    type: "",
    reference_number: "",
    ledger: "",
    subLedger: "",
    number: "",
    opening_balance: "",
    credit_balance: "",
    debit_balance: "",
    opening_balance_date: "",
  },
};

export const defaultFilterData = {
  is_active: [true],
  type: [],
  ledger: [],
  subLedger: [],
};
