import React from "react";
import { withNavBars } from "../../HOCs";
import ManageAgreementsList from "./manageAgreement";

class ManageAgreementsListParent extends React.Component {
    render() {
        return <ManageAgreementsList />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ManageAgreementsListParent, props);