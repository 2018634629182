import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch, remCalc } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    box: {
        boxShadow: "0px 0px 16px #00000014",
        borderRadius: "4px",
        height: 'calc(100vh - 159px)',
        overflow: "auto",
        backgroundColor: "white"
    },
    box2: {
        borderRadius: "4px",
        height: 'calc(100vh - 145px)',
        overflow: "auto",
    },


    gatWayRoot: {
        borderBottom: "1px solid #E4E8EE"
    },
    header: {
        fontSize: remCalc(16),
        fontFamily: FontFamilySwitch().extraBold,
        color: "#071741",
        marginTop: "10px"

    },
    subHeader: {
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: remCalc(12),
        color: "#98A0AC",
        margin: "10px 0px"

    },
    verticalLine: {
        borderRight: `1px solid #E4E8EE`,
    },
    buyBox: {
        background: "#F1F7FF",
        padding: "12px",
        borderRadius: theme.palette.borderRadius
    },
    buyBox2: {
        padding: "12px",
        borderRadius: theme.palette.borderRadius,
        border: `1px solid #E4E8EE`,
        marginTop: "12px"

    },
    buyBoxHeader: {
        fontSize: remCalc(24),
        fontFamily: FontFamilySwitch().extraBold,
        color: "#071741",
    },
    buyBoxHeader2: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().bold,
        color: "#98A0AC",
    },
    buyBoxHeader3: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().regular,
        color: "#98A0AC",
    },
    btn: {
        color: "#5078E1",
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().bold,
        "&:hover": {
            backgroundColor: "inherit",
        },
    },
}))