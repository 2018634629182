import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    root: {
        padding: "12px",

    },
    content: {
        backgroundColor: "#F2F4F7",
        padding: "12px",
        borderRadius: "10px"
    },
    heading: {
        color: "#091B29",
        fontFamily: FontFamilySwitch().bold,
        fontSize:"0.875rem"
    },
    heading1: {
        color: "#5078E1",
        fontFamily: FontFamilySwitch().bold,
        fontSize:"0.875rem",
        cursor: "pointer",

    },
    pricing: {
        padding: "12px",
        borderBottom: "1px solid #E4E8EE",
        '&:last-child': {
            border: 0
        },

    },
    priceName: {
        color: "#4E5A6B",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize:"0.875rem"
    },
    discountName: {
        color: "#98A0AC",
        fontFamily: FontFamilySwitch().regular,
        fontSize:"0.875rem"
    },
    priceValue: {
        color: "#4E5A6B",
        fontFamily: FontFamilySwitch().bold,
        fontSize:"0.875rem"
    },
    discountValue: {
        color: "#98A0AC",
        fontSize:"0.75rem",
        textAlign: "right",

    },
    pricingContainer: {
        height: `calc(100vh-200px)`,
        overflow: "scroll"
    },
    final: {
        backgroundColor: "#E4E8EE",
        margin: "4px 4px 8px 4px",
        borderRadius: theme.palette.borderRadius
    },
    btn: {
        padding: "8px 12px",
        boxShadow: "none",
        borderRadius: theme.palette.borderRadius,
        marginTop: "8px"
    }

}));