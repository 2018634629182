import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const paymentDashboardStyle = makeStyles((theme) => ({
  root: {
    // padding: "16px",
    marginTop: "16px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 0px 6px #00000014",
    borderRadius: "4px",
  },
  card: {
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderRadius: theme.palette.borderRadius,
    // border: "3px solid white",
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    height: (props) => (props?.height ? props?.height : "100%"),
    padding: (props) => props?.padding ?? "19px",
    minHeight: (props) => props?.maxHeight ?? "100%",
    width: "100%",
    overflow: "auto",
    display: "flex",
    flexFlow: (props) => props?.flexFlow ?? "column",
    justifyContent: (props) => props?.justifyContent,
  },
  graphMargin: {
    marginTop: (props) => props?.margin ?? "20px",
  },
  graphdatabox: {
    // backgroundColor: "#F5F7FA",
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
    height: (props) => props?.gradiantStyle?.height?.lg ?? "210px",
    [theme.breakpoints.down("sm")]: {
      height: (props) => props?.gradiantStyle?.height?.sm ?? "210px",
    },
    overflow: "overlay",
  },
  dot: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    padding: "5px",
  },
  graphDataCount: {
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "1rem",
    display: "flex",
    alignItems: "center",
    marginInline: "8px",
  },
  graphDataTotal: {
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().extraBold,
    fontSize: "1rem",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  graphDataTitle: {
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.85rem",
    marginBottom: "8px",
  },
  titleFont: {
    color: "#091B29",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().extraBold,
  },
  iconButton: {
    borderRadius: "4px",
    border: `1px solid ${theme.palette.border.primary}`,
    height: "40px",
    width: "40px",
  },
  listBox: {
    width: "100%",
    cursor: "pointer",
    display: "flex",
    overflow: "auto",
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderRadius: "4px",
    backgroundColor: "#fff",
    padding: "16px",
    marginBottom: "16.5px",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      width: "375px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  title: {
    color: theme.typography.color.tertiary,
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.85rem",
  },
  subTitle: {
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.85rem",
  },
  transactionLabel: {
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.85rem",
    display: "flex",
    alignItems: "center",
  },
  sccessBg: {
    backgroundColor: theme.palette.success.main,
    color: theme.typography.color.white,
    // padding: "4px 10px",
    borderRadius: "8px",
  },
  failureBg: {
    backgroundColor: theme.palette.error.main,
    color: theme.typography.color.white,
    // padding: "4px 10px",
    borderRadius: "8px",
  },
}));
