import { getTotalArray } from "./common";
import {
  CompanyDrawer,
  CompanyHeading,
  CompanyPath,
  CompanyType,
  CurrencyDrawer,
  CurrencyHeading,
  CurrencyPath,
  CurrencyType,
  VATGroupDrawer,
  vatGroupheading,
  VATGroupItemDrawer,
  vatGroupItemheading,
  vatGroupItemPath,
  vatGroupItemType,
  vatGroupPath,
  vatGroupType
} from ".";

export const bussinessActions = (
  type,
  offset,
  limit,
  search,
  company,
  status,
  t
) => {
  switch (type) {
    case "Currency":
      return {
        endpoint: "/queries/currency_master/getAll",
        resfunc: (array) => {
          let arr = array?.currency_master?.map((data, index) => {
            let mainData = {};
            mainData.id = data?.id;
            mainData.name = data?.name;
            mainData.code = data?.code;
            mainData.symbol = data?.symbol;
            mainData.country_name = {
              label: data?.country_master?.country_name,
              value: data?.country_master?.id,
            };
            mainData.is_active = data?.is_active;
            mainData.status = data?.is_active ? "Active" : "Inactive";
            mainData.is_external = data?.is_external;
            mainData.index = offset + index + 1;
            mainData.icon = "editviewtoggle";
            return mainData;
          });
          return arr;
        },
        tbdata: {
          heading: CurrencyHeading(t),
          path: CurrencyPath,
          type: CurrencyType,
          drawer: CurrencyDrawer(t),
        },
      };
    case "Tax Group":
      return {
        endpoint: "/queries/vat_group_master/getAll",
        resfunc: (array) => {
          let arr = array?.vat_group_master?.map((data, index) => {
            let mainData = {};
            mainData.id = data?.id;
            mainData.hsn_code = data?.hsn_code;
            mainData.group_name = data?.group_name;
            mainData.tax_group_code = {
              label: data?.vat_group_code,
              value: data?.vat_group_code
            }
            mainData.total_rate = data?.total_rate;
            mainData.country_name = {
              label: data?.country_master?.country_name,
              value: data?.country_master?.id,
            };
            mainData.coa_account_number =
              data?.com_master !== null
                ? {
                    label:
                      data?.com_master?.name +
                      "-" +
                      parseInt(data?.com_master?.number),
                    value: data?.com_master?.id,
                  }
                : "";
            mainData.account_type = data?.com_master?.coa_type;
            mainData.account_number =
              data?.com_master !== null
                ? data?.com_master?.name +
                  "-" +
                  parseInt(data?.com_master?.number)
                : "-";
            mainData.Coa_account_type =
              data?.com_master?.coa_type !== null
                ? {
                    label: data?.com_master?.coa_type,
                    value: data?.com_master?.coa_type,
                  }
                : "";
            mainData.is_active = data?.is_active;
            mainData.status = data?.is_active ? "Active" : "Inactive";
            mainData.is_external = data?.is_external;
            mainData.index = offset + index + 1;
            mainData.icon = "editviewtoggle";
            return mainData;
          });
          return arr;
        },
        tbdata: {
          heading: vatGroupheading(t),
          path: vatGroupPath,
          type: vatGroupType,
          drawer: VATGroupDrawer(t),
        },
      };
    case "Tax Item":
      return {
        endpoint: "/queries/vat_group_item/list",
        resfunc: (array) => {
          let arr = array?.vat_group_item?.map((data, index) => {
            let mainData = {};
            mainData.id = data?.id;
            mainData.group_name = data?.group_name;
            mainData.tax_group_code = {
              label: data?.vat_group_item_code,
              value: data?.vat_group_item_code,
            };
            mainData.Vat_group = {
              label: data?.vat_group_master?.group_name,
              value: data?.vat_group_master?.id,
            };
            mainData.country = {
              label: data?.vat_group_master?.country_masterByID?.country_name,
              value: data?.vat_group_master?.country_masterByID?.id,
            };
            mainData.rate = data?.rate;
            mainData.is_active = data?.is_active;
            mainData.status = data?.is_active ? "Active" : "Inactive";
            mainData.is_external = data?.is_external;
            mainData.index = offset + index + 1;
            mainData.icon = "editviewtoggle";
            return mainData;
          });
          return arr;
        },
        tbdata: {
          heading: vatGroupItemheading(t),
          path: vatGroupItemPath,
          type: vatGroupItemType,
          drawer: VATGroupItemDrawer(t),
        },
      };
      case "Company":
        return {
          resfunc: (array) => {
            let arr = array?.company_master?.map((data, index) => {
              let mainData = {};
              mainData.id = data?.id;
              mainData.name = data?.name;
              mainData.upload_logo = {
                logo: data?.logo,
                name: data?.name
              };
              mainData.company_code_name = data?.company_no ?? "";
              mainData.company_name = data?.name ?? "";
              mainData.city = data?.company_address?.[0]?.city;
              mainData.country_name = data?.company_address?.[0]?.country;
              mainData.company_type = data?.company_type_master?.name;
              mainData.state_name = {
                label: data?.cities_master?.state_master?.state_name,
                value: data?.cities_master?.state_master?.id
              };
              mainData.currency = data?.currency ? {
                label: data?.currency?.symbol,
                value: data?.currency?.id
              } : [];
              mainData.tax_group = data?.vat_group?.group_name ?? data?.vat_group_master?.group_name
              mainData.tax_rate = !!data?.vat_group?.vat_group_item ?
                `${getTotalArray(data?.vat_group?.vat_group_item, 'sum_rate')} %` :
                !!data?.vat_group_master?.vat_group_item ? `${getTotalArray(data?.vat_group_master?.vat_group_item, 'sum_rate')} %` : "";
              
              mainData.company_code = data?.company_no;
              mainData.tax_registration = data?.tax_registration_number;
              mainData.description = data?.description;
              mainData.is_active = data?.is_active;
              mainData.is_external = data?.is_external;
              mainData.cash_collection_office = data?.cash_collection_office;
              mainData.cheque_name = data?.cheque_name;
              mainData.primary_bank_name = data?.primary_bank_name;
              mainData.primary_account_no = data?.primary_account_no;
              mainData.financial_system_code = data?.financial_system_code;
              mainData.account_type = data?.account_type;
              mainData.routing_type = data?.routing_type;
              mainData.bank_routing_code = data?.bank_routing_code;
              mainData.iban = data?.iban;
              mainData.index = offset + index + 1;
              mainData.status = data?.is_active ? "Active" : "Inactive";
              mainData.icon = "editviewtoggle";
              return mainData;
            });
            return arr;
          },
          tbdata: {
            heading: CompanyHeading(t),
            path: CompanyPath,
            type: CompanyType,
            drawer: CompanyDrawer(t),
          },
        };
      default:
      return null;
  }
};

export const bussinessUpdateActions = {
  Currency: {
    endpoint: "/queries/currency_master/upsert",
  },
  "Tax Group": {
    endpoint: "/queries/vat_group_master/upsert",
  },
  "Tax Item": {
    endpoint: "/queries/vat_group_item/upsert",
  },
  Company: {
    endpoint: "/queries/company_master/upsert",
  }
};

export const bussinesssEditActions = {
  Currency: {
    endpoint: "/queries/currency_master/upsert",
  },
  "Tax Group": {
    endpoint: "/queries/vat_group_master/upsert",
  },
  "Tax Item": {
    endpoint: "/queries/vat_group_item/upsert",
  },
  Company: {
    endpoint: "/queries/company_master/upsert",
  }
};

export const bussinessSearch = {};
