import React from "react";
import { AlertContext } from "../../contexts";
import { AlertProps, InAndOutOption, InOutStatusVW, NetWorkCallMethods, StayTypetOption, FontFamilySwitch } from "../../utils";
import { FilterGenerator } from "../filterGenerator";
import { withTranslation } from "react-i18next";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";

const VisitorSecurityFilter = (
    {
        open = "",
        close = () => false,
        apply = () => false,
        filterData = {},
        clientId = "",
        property = {},
        filterKeys = [],
        t
    }
) => {
    const alert = React.useContext(AlertContext)
   

    const manualResponse = (array) => {
        const details = array?.access_gates_master?.map((i) => {
          return {
            label: i?.label,
            value: i?.value,
            ...i,
          };
        });
        return details;
      };
    const object = {
        unitID: {
            component: "select",
            value: filterData?.unit_id,
            options: [],
            isMulti: true,
            label: t("Unit ID"),
            state_name: "unit_id",
            debounceTimeout: 800,
            isPaginate: true,
            labelColor: "#98a0ac",
            labelSize: "12px",
            fontFamily: FontFamilySwitch().semiBold,
        },
        accessGate: {
            component: "select",
            value: filterData?.gate,
            options: [],
            isMulti: true,
            label: t("Gate"),
            state_name: "gate",
            placeholder: t("Select gate"),

            loadOptions: (search, array, handleLoading) =>
                // loadOptions(search, array, handleLoading, "access_gates_master", manualResponse),
             loadOptionsApis(
                              `queries/access_gates_master/list`,
                              { propertyIDS: property.label === "All Properties" ? property?.value : [property?.value] },
                              search,
                              array,
                              handleLoading,
                              "data",
                              {},
                              manualResponse
                            ),
            debounceTimeout: 800,
            isPaginate: true,
            labelColor: "#98a0ac",
            labelSize: "12px",
            fontFamily: FontFamilySwitch().semiBold,
        },
        status: {
            component: "toggleButton",
            value: filterData?.status,
            options: InAndOutOption,
            isMulti: true,
            state_name: "status",
            label: t("Status"),
            labelColor: "#98a0ac",
            labelSize: "12px",
            fontFamily: FontFamilySwitch().semiBold,
        },
        reqDate: {
            component: "minAndmaxDate",
            placeholder: {
                startLabel: t("Start"),
                endLabel: t("End"),
            },
            state_name: "createdDate",
            value: filterData?.createdDate,
            minMaxlabel: t("Created Date"),
            labelColor: "#98a0ac",
            labelSize: "12px",
            fontFamily: FontFamilySwitch().semiBold,
            height: "35%"
        },
        checkInDate: {
            component: "minAndmaxDate",
            placeholder: {
                startLabel: t("Start"),
                endLabel: t("End"),
            },
            state_name: "checkInDate",
            value: filterData?.checkInDate,
            minMaxlabel: t("Check In Date"),
            labelColor: "#98a0ac",
            labelSize: "12px",
            fontFamily: FontFamilySwitch().semiBold,
            height: "35%"
        },
        checkOutDate: {
            component: "minAndmaxDate",
            placeholder: {
                startLabel: t("Start"),
                endLabel: t("End"),
            },
            state_name: "checkOutDate",
            value: filterData?.checkOutDate,
            minMaxlabel: t("Check Out Date"),
            labelColor: "#98a0ac",
            labelSize: "12px",
            fontFamily: FontFamilySwitch().semiBold,
            height: "35%"
        },
        requestedFromDate: {
            component: "minAndmaxDate",
            placeholder: {
                startLabel: t("Start"),
                endLabel: t("End"),
            },
            state_name: "requestedFromDate",
            value: filterData?.requestedFromDate,
            minMaxlabel: t("Requested From"),
            labelColor: "#98a0ac",
            labelSize: "12px",
            fontFamily: FontFamilySwitch().semiBold,
            height: "35%"
        },
        requestedToDate: {
            component: "minAndmaxDate",
            placeholder: {
                startLabel: t("Start"),
                endLabel: t("End"),
            },
            state_name: "requestedToDate",
            value: filterData?.requestedToDate,
            minMaxlabel: t("Requested To"),
            labelColor: "#98a0ac",
            labelSize: "12px",
            fontFamily: FontFamilySwitch().semiBold,
            height: "35%"
        },
        stayType: {
            component: "select",
            value: filterData?.stayType,
            options: StayTypetOption,
            isMulti: true,
            label: t("Stay Type"),
            placeholder: t("Select stay type"),
            state_name: "stayType",
            labelColor: "#98a0ac",
            labelSize: "12px",
            fontFamily: FontFamilySwitch().semiBold,
        },
        inOutStatusVW: {
            component: "select",
            value: filterData?.inOutStatus,
            options: InOutStatusVW,
            isMulti: true,
            label: t("In/Out Status"),
            state_name: "inOutStatus",
            labelColor: "#98a0ac",
            labelSize: "12px",
            fontFamily: FontFamilySwitch().semiBold,
        },
    }
    const components = () => {
        let data = filterKeys?.map(i => object[i])
        return data
    }
    return (
        <FilterGenerator open={open} onClose={close}
            onApply={(value) => apply(value)}
            components={components()} />
    )
}
export default withTranslation("visitorSecurityFilter")(VisitorSecurityFilter)

