import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch, remCalc } from "../../../utils";
export const useStyles = makeStyles((theme) => ({
    box: {
        boxShadow: "0px 0px 16px #00000014",
        borderRadius: "4px",
        height: 'calc(100vh - 160px)',
        overflow: "auto",
        backgroundColor: "white"
    },
    root: {
        backgroundColor: '#FFFFFF',
        borderRadius: 4,
        width: '100%',
        padding: theme.spacing(1),
        height: 'calc(100vh - 186px)',
        overflow: 'auto',
    },
    label: {
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: "0.75rem",
        color: theme.typography.color.tertiary,
        marginBottom: theme.spacing(1)
    },
    title_template: {
        fontSize: "1rem",
        color: "#091B29",
        fontFamily: FontFamilySwitch().bold,
        // padding: "15px"
    },
    tab: {
        fontFamily: FontFamilySwitch().bold,
        fontSize: "0.875rem"
    },
    tab_container: {
        '& .MuiTabs-indicator': {
            height: '4px',
            borderRadius: "4px 4px 0px 0px"
        }
    },
    container: {
        border: `1px solid #E4E8EE`,
        borderRadius: '8px',
        height: '100%',
        position: "relative",
        cursor: "pointer",
        '&:hover': {
            border: `1px solid #5078E1`,

        }
    },
    container1: {
        padding: "12px",
        borderTop: `1px solid #E4E8EE`,
    },
    language: {
        color: '#98A0AC',
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: "0.75rem",
        backgroundColor: '#F2F4F7',
        borderRadius: '4px',
        padding: '5px'
    },
    view: {
        color: '#5078E1',
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: "0.75rem",
        cursor: 'pointer'
    },
    pdfImg: {
        height: '119px',
        overflow: 'auto',
        padding: "14px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: '#F5F7FA',
        borderRadius: "4px 4px 0px 0px",
        position: "relative"
    },
    templateTitle: {
        color: "#4E5A6B",
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold,
        marginTop: "13px"
    },
    selectBoxParent: {
        position: "absolute",
        top: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        zIndex: 999
    },
    selectBox: {
        backgroundColor: "#5078E1",
        borderRadius: '0px 0px 8px 8px',
        display: "flex",
        alignItems: "center",
        padding: "6px"
        // justifyContent:"center"
    },
    DoneParent: {
        backgroundColor: "#fff",
        borderRadius: "50%",
        height: '20px',
        width: '20px',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    doneText: {
        color: '#fff',
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        marginLeft: "10px"
    },

    selectBoxDefault: {
        backgroundColor: "#fff",
        borderRadius: '0px 0px 8px 8px',
        display: "flex",
        alignItems: "center",
        padding: "6px",
        border: `1px solid #5078E1`,
        borderTop: "none",
        cursor: "pointer"

        // justifyContent:"center"
    },
    DoneParentDefault: {
        backgroundColor: "#5078E1",
        borderRadius: "50%",
        height: '20px',
        width: '20px',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    doneTextDefault: {
        color: '#5078E1',
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        marginLeft: "10px"
    },
    dialogTitle: {
        color: '#4E5A6B',
        fontFamily: FontFamilySwitch().bold,
        fontSize: "0.875rem"
    },
    pdfImgs: {
        height: "100%",
        // display:"flex",
        // alignItems:"center",
        // justifyContent:"center",
        width: '100%',
        textAlign: 'center',
        overflow: 'auto'
    },
    tabName: {
        color: "#4E5A6B",
        fontFamily: FontFamilySwitch().bold,
        fontSize: "0.875rem",
        textAlign: "center",
        cursor: "pointer",
        position: "relative",


    },
    tabNameseleted: {
        color: "#5078E1",
        fontFamily: FontFamilySwitch().bold,
        fontSize: "0.875rem",
        position: "relative",
        textAlign: "center",
        paddingBottom: "2px",
        cursor: "pointer"
    },
    absolute: {
        position: "absolute",
        height: "3px",
        backgroundColor: "#5078E1",
        borderRadius: "4px 4px 0px 0px",
        bottom: "-4px",
        width: "100%",
        left: 0,
        right: 0
    },
    save: {
        backgroundColor: "#5078E1",
        color: "#fff",
        fontFamily: FontFamilySwitch().semiBold,
    },
    explore: {
        border: "1px solid #5078E1",
        color: "#5078E1",
        fontFamily: FontFamilySwitch().semiBold,
        backgroundColor: "#fff",
        '&:hover': {
            border: "1px solid #5078E1",
            color: "#5078E1",
            fontFamily: FontFamilySwitch().semiBold,
            backgroundColor: "#fff"
        }
    },
    tabOnly: {
        [theme.breakpoints.down('md')]: {
            marginBottom: "16px"

        },
    },
    buttons: {
        position: "absolute",
        bottom: "-24px",
        width: "97.5%",
        display: "flex",
        justifyContent: "end"
    },
    custom: {
        background: "#5AC782",
        padding: "2px 4px",
        backgroundColor: "4px",
        position: "absolute",
        bottom: "8px",
        left: "8px",
        color: "#fff",
        fontSize: "10px",
        fontFamily: FontFamilySwitch().semiBold,
        borderRadius: "4px",
        cursor: "pointer"
    },
    custom1: {
        background: "#5AC782",
        padding: "2px 4px",
        backgroundColor: "4px",
        // position:"absolute",
        bottom: "8px",
        left: "8px",
        color: "#fff",
        fontSize: "10px",
        fontFamily: FontFamilySwitch().semiBold,
        borderRadius: "4px",
        cursor: "pointer",
        width: "fit-content",
        marginBottom: "6px"
    },
    paginate: {
        direction: "ltr",
        "& .MuiButtonBase-root.MuiPaginationItem-root": {
            borderRadius: theme.palette.borderRadius,
            border: "none",
            color: "#4E5A6B",
            fontSize: "12px",
            fontFamily: FontFamilySwitch().bold
        },
        "& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected": {
            color: "#FFFF",
            borderRadius: theme.palette.borderRadius,
            border: "none",
            fontSize: "12px",
            fontFamily: FontFamilySwitch().bold
            //   backgroundColor: "#5078E1",
        },
    },

    pageCount: {
        color: "#4E5A6B",
        fontSize: "12px",
        fontFamily: FontFamilySwitch().bold,
        padding: "0px 10px",
        '& span': {
            color: "#98A0AC",
            fontSize: "12px",
            fontFamily: FontFamilySwitch().bold,
        }
    },
    root1: {
        backgroundColor: '#FFFFFF',
        borderRadius: 4,
        width: '100%',
        overflow: 'auto',
    }
}))


export const TermsAndConditionStyle = makeStyles((theme) => ({
    reactQuil: {
        "& .ql-toolbar.ql-snow": {
            borderRadius: "8px 8px 0px 0px"
        },
        "& .ql-container.ql-snow": {
            borderRadius: "0px 0px 8px 8px"
        },
        "& .ql-editor": {
            padding: "0px 0px 16px 0px",
            textAlign: "start",
            fontSize: "0.875rem",
            fontFamily: FontFamilySwitch().bold,
            color: "#091B29",
            height: "calc(100vh - 450px)",
        },
    },
    createBox: {
        width: "100%",
        height: "calc(100vh - 270px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    createBoxText: {
        fontSize: "0.875rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    createBoxButton: {
        height: "40px",
        width: "74px",
        borderRadius: 4,
    },
    title: {
        fontSize: "1rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold

    },
    createSaveBtn: {
        height: "40px",
        borderRadius: "4px"
    },
    editBtn: {
        height: "40px",
        borderRadius: "4px",
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    contentBox: {
        height: "calc(100vh - 235px)",
        overflow: "auto"
    },
    documentButton: {
        fontSize: remCalc(12),
        color: theme.typography.color.primary,
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
        padding: 8,
        cursor: "pointer"
    },
    documentButtonSelected: {
        fontSize: remCalc(12),
        color: "#fff",
        background: theme.palette.primary.main,
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
        padding: 8,
        cursor: "pointer"
    },
    documentBtnText:{
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().semiBold,
    },
    documentTitle:{
        fontSize: remCalc(16),
        fontFamily: FontFamilySwitch().bold,
        margin: 8
    },
    imageContainer:{
        height: 150,
        width: "100%",
        borderRadius: 12,
        border: `1px solid ${theme.palette.border.secondary}`,
        margin: 4
    },
    detailsBox: {
        display: "flex",
        alignItems: "center",
        padding: "16px",
        backgroundColor: theme?.palette?.background?.background
    },
    pdfText: {
        padding: 4,
        color: "#fff",
        fontFamily: FontFamilySwitch().bold,
        fontSize: remCalc(12),
        background: theme.palette.primary.main,
        borderRadius: 8
    },
    pdfName: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().bold,
        wordBreak: "break-all",
        whiteSpace: "nowrap",
        width: '95%',
        textOverflow: "ellipsis",
        overflow: "hidden"
    }
}))

export const ImageUploadStyle = makeStyles((theme) => ({
    avatar: {
        height: 100,
        width: "150px",
        borderRadius: 4
    },
    viewImageBox: {
        overflow: "hidden",
        '&:hover': {
            overflow: "auto"
        }
    },
    popupAvatar: {
        height: "450px",
        width: "100%",
        borderRadius: "4px",
        padding: "8px"
    }
}))