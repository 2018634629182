export const initialData = {
  image_url: "",
  logo_name: "",
  company_name: "",
  module: "",
  status: "",
  error: {
    image_url: "",
    logo_name: "",
    company_name: "",
    module: "",
    status: "",
  },
};

export const initialBasicSettings = {
  default_base_currency: "",
  default_uom: "",
  quotation_validity: { value: null, label: "None" },
  billing_payment_peroid: "",
  billing_payment_terms: "",
  billing_cycle_method: "",
  billing_generate_date: "",
  default_payment_gateway: { value: null, label: "None" },
  loginName: "",
  loginPassword: "",
  loginToken: "",
  paymentProviderName: "",
  provider_url: "",
  reservation_feature: { value: null, label: "None" },
  auto_deposite_cal: "",
  auto_reservation_cal: "",
  grace_peroid: { value: null, label: "None" },
  finane_email: { value: null, label: "None" },
  bill_custom_date: 0,
  pricing_override: "",
  short_term_retals: "",
  storage_offering: "",
  index_calculation: "",
  refer_win: "",
  professoinal_banners: "",
  logo: "",
  billing_payment_terms_value: "",
  logo_status: "",
  lease_period: "",
  proof: [],
  default_time_zone: "",
  discussion: {
    is_active: false,
  },
  neigbours: {
    is_active: false,
  },
  contact: {
    is_active: false,
  },
  survey: {
    is_active: false,
  },
  walkin: {
    is_active: false,
  },
  booking: {
    is_active: false,
  },
  polls: {
    is_active: false,
  },
  deleivery: {
    is_active: false,
  },
  parking: {
    is_active: false,
  },
  is_visitor_worker_pass_approval: {
    is_active: false,
  },
  is_unit_request_access_approval: {
    is_active: false,
  },
  itemType: null,
  itemCategory: null,
  itemMaster: null,
  upfront_charge: {
    itemType: { label: "", value: "" },
    itemCategory: { label: "", value: "" },
    itemMaster: { label: "", value: "" },
    itemSubCategory: { label: "", value: "" },
    upfront_COA: null,
    is_active: false,
  },
  penalty_item: {
    itemType: { label: "", value: "" },
    itemCategory: { label: "", value: "" },
    itemMaster: { label: "", value: "" },
    itemSubCategory: { label: "", value: "" },
    penalty_COA: null,
    is_active: false,
  },
  reservation_item: {
    itemType: { label: "", value: "" },
    itemCategory: { label: "", value: "" },
    itemMaster: { label: "", value: "" },
    itemSubCategory: { label: "", value: "" },
    reservation_COA: null,
    is_active: false,
  },
  account_pay: {
    acc_pay: { label: "", value: "" },
    acc_payType: { label: "", value: "" },
    acc_payLedger: { label: "", value: "" },
    tax_receive: { label: "", value: "" },
    tax_receiveType: { label: "", value: "" },
    tax_receiveLedger: { label: "", value: "" },
    accrued_liability: { label: "", value: "" },
    accrued_type: { label: "", value: "" },
    accrued_ledger: { label: "", value: "" },
    goods: { label: "", value: "" },
    goodsType: { label: "", value: "" },
    goodsLedger: { label: "", value: "" },
    credits: { label: "", value: "" },
    creditsType: { label: "", value: "" },
    creditsLedger: { label: "", value: "" },
    is_active: false,
  },
  account_receive: {
    acc_receive: { label: "", value: "" },
    acc_receiveType: { label: "", value: "" },
    acc_receiveLedger: { label: "", value: "" },
    tax_pay: { label: "", value: "" },
    tax_payType: { label: "", value: "" },
    tax_payLedger: { label: "", value: "" },
    deposits: { label: "", value: "" },
    depositsType: { label: "", value: "" },
    depositsLedger: { label: "", value: "" },
    customer_deposit: { label: "", value: "" },
    customerType: { label: "", value: "" },
    customerLedger: { label: "", value: "" },
    inventory: { label: "", value: "" },
    inventoryType: { label: "", value: "" },
    inventoryLedger: { label: "", value: "" },
    advance: { label: "", value: "" },
    advanceType: { label: "", value: "" },
    advanceLedger: { label: "", value: "" },
    invest: { label: "", value: "" },
    investType: { label: "", value: "" },
    investLedger: { label: "", value: "" },
    sale: { label: "", value: "" },
    saleType: { label: "", value: "" },
    saleLedger: { label: "", value: "" },
    is_active: false,
  },
  client_controls: [],
  is_mail_trigger: false,
  error: {
    default_base_currency: "",
    default_uom: "",
    quotation_validity: "",
    billing_payment_peroid: "",
    billing_payment_terms: "",
    billing_cycle_method: "",
    billing_generate_date: "",
    default_payment_gateway: "",
    reservation_feature: "",
    auto_deposite_cal: "",
    auto_reservation_cal: "",
    grace_peroid: "",
    pricing_override: "",
    short_term_retals: "",
    storage_offering: "",
    index_calculation: "",
    refer_win: "",
    professoinal_banners: "",
    logo: "",
    logo_status: "",
    loginName: "",
    loginPassword: "",
    loginToken: "",
    paymentProviderName: "",
    provider_url: "",
    lease_period: "",
    default_time_zone: "",
    discussion: {
        is_active: false
    },
    neigbours: {
        is_active: false
    },
    contact: {
        is_active: false
    },
    survey: {
        is_active: false
    },
    walkin: {
        is_active: false
    },
    booking: {
        is_active: false
    },
    polls: {
        is_active: false
    },
    deleivery: {
        is_active: false
    },
    parking: {
        is_active: false
    },
    is_visitor_worker_pass_approval: {
        is_active: false
    },
    is_unit_request_access_approval: {
        is_active: false
    },
    itemType: null,
    itemCategory: null,
    itemMaster: null,
    client_controls:[],
    is_mail_trigger:false,
    approval_period: "",
    error: {
        default_base_currency: "",
        default_uom: "",
        quotation_validity: "",
        billing_payment_peroid: "",
        billing_payment_terms: "",
        billing_cycle_method: "",
        billing_generate_date: "",
        default_payment_gateway: "",
        reservation_feature: "",
        auto_deposite_cal: "",
        auto_reservation_cal: "",
        grace_peroid: "",
        pricing_override: "",
        short_term_retals: "",
        storage_offering: "",
        index_calculation: "",
        refer_win: "",
        professoinal_banners: "",
        logo: "",
        logo_status: "",
        loginName: "",
        loginPassword: "",
        loginToken: "",
        paymentProviderName: "",
        provider_url: "",
        lease_period: "",
        default_time_zone: '',
        approval_period: "",
        upfront_charge: "",
        penalty_item: "",
        reservation_item: "",
        account_pay: "",
        account_receive: "",
    }
}
}

export const status = [
  {
    label: "Active",
    value: true,
  },
  {
    label: "InActive",
    value: false,
  },
];

export const moduleOption = [
  {
    label: "Resident",
    value: "resident",
  },
  {
    label: "Manager",
    value: "manager",
  },
];

export const quotation_validity = [
  {
    label: "None",
    value: null,
  },
];
export const Billing_cycle_method = [
  {
    label: "Prepaid",
    value: "Start of the Payment Period",
  },
  {
    label: "Post Paid",
    value: "End of the Payment Period",
  },
];
export const Billing_cycle_date = [
  {
    label: "As Agreement",
    value: "As per the Agreement Date",
  },
];

export const yesorno = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];
export const reservation_feature = [
  {
    label: "None",
    value: null,
  },
];

export const validateCustom = (array, value) => {
  let result = array.includes(value);
  return !result;
};

export const validatePaymentperoid = (options, value, type) => {
  switch (type) {
    case "Monthly":
      if (parseInt(value) < 12) {
        return options?.filter((val) => val?.value !== "Yearly");
      } else if (parseInt(value) < 6) {
        return options?.filter((val) => val?.value !== "Half Yearly");
      } else if (parseInt(value) < 3) {
        return options?.filter((val) => val?.value !== "Quarterly");
      } else {
        return options;
      }
    default:
      return options;
  }
};
