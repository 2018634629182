/* eslint-disable react-hooks/exhaustive-deps */
import {
  Badge,
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import FilterIMG from "../../assets/filter";
import {
  FilterGenerator,
  SearchFilter,
  Subheader,
  TableWithPagination,
  UseDebounce,
} from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import {
  AlertProps,
  NetWorkCallMethods,
  accessCheckRender,
  getCompanyOption,
  getRoutePermissionNew,
} from "../../utils";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import {
  PricingTablePropertyheading,
  PricingtablePropertypath,
} from "../../utils/tableData";
import { UtilitiesMappingStyles } from "./style";
import { withTranslation } from "react-i18next";

const UtilitiesMapping = ({ t }) => {
  const navigate = useNavigate();
  const classes = UtilitiesMappingStyles();
  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext);
  const backdrop = React.useContext(BackdropContext);
  const [drawer, setDrawer] = React.useState(null);
  const [propertyList, setPropertyList] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const [companyList, setCompanyList] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState({});
  const [filterData, setFilterData] = React.useState({ status: [true] });
  // const clientId = localStorage.getItem(LocalStorageKeys.clinetID)
  const [permission, setPermission] = React.useState({});

  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");
  const limitParam = searchParams.get("limit");

  // Default values if not set in URL
  const pageFromParams = pageParam ? parseInt(pageParam, 10) : 1;
  const limitFromParams = limitParam ? parseInt(limitParam, 10) : 10;

  const [page, setPage] = React.useState(pageFromParams);
  const [limit, setLimit] = React.useState(limitFromParams);

  const updatePage = (newPage, newLimit = limit) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", newPage.toString());
    searchParams.set("limit", newLimit.toString());

    // Update URL without reloading
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
    // Update state
    setPage(newPage);
    setLimit(newLimit);
  };
  //handle pagination
  const handlePagination = (newPage) => {
    updatePage(newPage, limit);
  };

  const handleChangeLimit = (newLimit) => {
    updatePage(1, newLimit); // Reset page to 1 when limit changes
  };

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          setCompanyList(company?.list);
          setSelectedCompany(company?.selected);
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  //debounce
  const debounce = UseDebounce();
  //more options

  const handleSearch = (e) => {
    setSearchText(e);
    debounce(() => searchTableFunction(e), 800);
  };
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getPropertyByCompanyID(0, limit, e);
  };

  const handleIcon = (type, data, status) => {
    if (type === "view") {
      navigate(Routes.utilitiesMappingPropertyView + "?propertyID=" + data?.id);
    }
  };
  const draweropen = () => {
    setDrawer(true);
  };
  const drawerclose = () => {
    setDrawer(false);
  };
  const propertyRow = React.useCallback(
    propertyList?.data?.map((val, index) => {
      let _d;
      try {
        _d = {
          index: (page - 1) * limit + index + 1,
          propertyNo: val?.property_no,
          propertyName: val?.name,
          companyName: val?.company?.name,
          location: val?.address?.city
            ? val?.address?.city
            : val?.address?.district,
          blocks: val?.block.length,
          floors: val?.floor.length,
          units: val?.unit.length,
          propertyType: val?.property_group_master?.[0]?.group_name,
          status: val?.is_active ? "Active" : "Inactive",
          icon: "editview",
          companyID: val?.company?.id,
          id: val?.id,
        };
      } catch (err) {}
      return _d;
    }),
    [propertyList]
  );

  const getPropertyByCompanyID = (offset = 0, limit = 10, search = "") => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });

    let companyID = [selectedCompany?.value];
    let propertyGroudID = filterData.property_type?.length
      ? filterData?.property_type?.map(({ value }) => value)
      : [];
    let is_active = filterData?.status ?? [];

    const payload = {
      offset: offset,
      limit: limit,
      company_id: companyID,
      is_active: is_active,
      search: search,
      property_group_id: propertyGroudID,
    };
    NetworkCall(
      `${config.api_url}/queries/property/by_company`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setPropertyList({
          data: response?.data?.data?.property,
          totalRowsCount: response?.data?.data?.count?.[0]?.count,
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch(() => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
      });
  };

  const currentOffset = (page - 1) * limit;
  React.useEffect(() => {
    if (selectedCompany?.value) {
      getPropertyByCompanyID(currentOffset, limitFromParams, searchText);
    }
  }, [selectedCompany, filterData]);

  const handleCompanyChange = (value) => {
    setSelectedCompany(value);
  };

  const onApplyFilter = (value) => {
    setFilterData(value);
  };

  const render = () => {
    return (
      <>
        <Subheader
          hideBackButton={true}
          title={t("Utilities Mapping")}
          select
          options={companyList}
          value={selectedCompany}
          goBack={() => {
            navigate(Routes.home);
          }}
          onchange={(e) => {
            handleCompanyChange(e);
          }}
        />
        <div className={classes.root}>
          {propertyRow?.length < 0 ? (
            <>
              <Grid container className={classes.content}>
                <Grid item xs={12}>
                  <img src="/images/propertybg.svg" alt="pp" />
                  <Typography className={classes.text}>
                    {t("No Property Found")}
                  </Typography>
                  <br />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <SearchFilter
                    value={searchText}
                    placeholder={t("Search Properties")}
                    handleChange={(value) => handleSearch(value)}
                    customfieldSx={{
                      "& .MuiOutlinedInput-root": { height: "40px" },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={8}
                  textAlign={"right"}
                  display={"flex"}
                  justifyContent={"end"}
                >
                  <Box display={"flex"} sx={{ float: "right" }}>
                    <Stack
                      direction="row"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={2}
                    >
                      {filterData.status?.length > 0 ||
                      filterData.property_type?.length > 0 ? (
                        <IconButton
                          onClick={draweropen}
                          className={classes.img}
                        >
                          <Badge variant="dot" color="primary">
                            <FilterIMG color="#091b29" />
                          </Badge>
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={draweropen}
                          className={classes.img}
                        >
                          <FilterIMG color="#091b29" />
                        </IconButton>
                      )}
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={12} marginTop="-14px">
                  <TableWithPagination
                    heading={PricingTablePropertyheading(t)}
                    rows={propertyRow}
                    path={PricingtablePropertypath}
                    showpagination={true}
                    count="2"
                    showpdfbtn={false}
                    showexcelbtn={false}
                    showSearch={false}
                    onClick={() => console.log("")}
                    tableType="no-side"
                    handleIcon={handleIcon}
                    dataType={[
                      { type: ["text"], name: "propertyNo", width: "170px" },
                      { type: ["text"], name: "propertyName", width: "170px" },
                      { type: ["text"], name: "companyName", width: "170px" },
                      {
                        type: ["description"],
                        name: "location",
                        width: "300px",
                      },
                      { type: ["text"], name: "units" },
                      { type: ["propertyType"], name: "propertyType" },
                      { type: ["status"], name: "status" },
                    ]}
                    handlePagination={handlePagination}
                    handleChangeLimit={handleChangeLimit}
                    totalRowsCount={propertyList?.totalRowsCount}
                    page={page}
                    limit={limit}
                    height={"calc(100vh - 290px)"}
                    view={permission?.read}
                    edit={permission?.update}
                    delete={permission?.delete}
                  />
                </Grid>
              </Grid>
            </>
          )}
          {/* filter drawer */}
          {drawer && (
            <FilterGenerator
              open={drawer}
              onClose={drawerclose}
              components={[
                {
                  component: "select",
                  value: filterData?.property_type,
                  options: [],
                  isMulti: true,
                  label: t("Property Type"),
                  placeholder: t("Select Property Type"),
                  state_name: "property_type",
                  loadOptions: (search, array, handleLoading) =>
                    loadOptionsApis(
                      "queries/property_type/get",
                      {
                        company_id: selectedCompany?.value,
                        is_active: [true],
                      },
                      search,
                      array,
                      handleLoading,
                      "property_group_master",
                      {}
                    ),
                  debounceTimeout: 800,
                  isPaginate: true,
                },
                {
                  component: "toggleButton",
                  value: filterData?.status,
                  options: [
                    { label: t("Active"), value: true },
                    { label: t("Inactive"), value: false },
                  ],
                  isMulti: true,
                  state_name: "status",
                  label: t("Status"),
                  // required:true
                },
              ]}
              onApply={(value) => onApplyFilter(value)}
            />
          )}
        </div>
      </>
    );
  };

  return <div>{accessCheckRender(render, permission)}</div>;
};
export default withTranslation("utilities")(UtilitiesMapping);
