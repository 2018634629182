import { CssBaseline } from "@mui/material";
import React from "react";
import AppAlert from "./App.alert";
import AppAuth from "./App.auth";
import AppBackdrop from "./App.backdrop";
import './App.css';
import AppDialog from "./App.dialog";
import AppDrawer from "./App.drawer";
import AppErrorBoundary from "./App.errorBoundry";
import AppPopover from "./App.popover";
import AppTheme from "./App.theme";
import RouterApp from "./router";

import { CompanyProvider } from './contexts/CompanyContext';

import AppFirebase from "./App.firebase"; //For Push Notification thing


const App = () => {
  return (
    <AppErrorBoundary>
      <CompanyProvider>
      <AppAuth>
        <AppTheme>
          <CssBaseline />
          <AppAlert>
            <AppDialog>
              <AppDrawer>
                <AppBackdrop>
                  <AppPopover>
                    <AppFirebase>
                      <RouterApp />
                    </AppFirebase>
                  </AppPopover>
                </AppBackdrop>
              </AppDrawer>
            </AppDialog>
          </AppAlert>
        </AppTheme>
      </AppAuth>
      </CompanyProvider>
    </AppErrorBoundary>
  );
};
export default App;
