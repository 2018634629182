import { TextField, Typography, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Label } from "../label";
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";

export const TextBox = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation("residentOnboarding");

  const placeholder = t("Type Here...");
  return (
    <div className={classes.root}>
      <>
        {props.label && (
          <Label
            label={props.label}
            isRequired={props.isrequired}
            labelMarginBottom={props.labelMarginBottom}
            textAlign={props?.textAlign}
            isTooltip={props?.isTooltip}
            tooltip={props?.tooltip}
            tooltipPlacement={props?.tooltipPlacement}
            style={props.labelStyle}
          />
        )}
        <div className={classes.text}>
          <Tooltip
            title={props?.valueTooltip && props.value ? props.value : ""}
            placement="top"
            arrow
            disableHoverListener={!props?.valueTooltip || !props.value}
          >
            <TextField
              className={classes.textbox}
              type={props.type}
              style={{
                backgroundColor: props?.backgroundColor ?? "none",
              }}
              onKeyPress={(e) => {
                if (e?.key === "Enter") {
                  props?.handleEnter && props?.handleEnter(e);
                }
                if (props.type === "number") {
                  if (e.key === "e") {
                    e.preventDefault();
                  }
                  if (props?.keyPress) {
                    props?.keyPress(e);
                  }
                }
              }}
              id={props.id}
              placeholder={props.placeholder ?? placeholder}
              variant={props.variant ?? "outlined"}
              fullWidth={props?.fullWidth ?? true}
              InputLabelProps={{
                shrink: false,
              }}
              inputProps={{
                readOnly: props?.isReadonly ?? false,
                autoComplete: props?.autocomplete,
                step: props?.step,
                pattern: props?.pattern,
                maxLength: props?.maxLength ?? "auto",
              }}
              InputProps={{
                endAdornment: props?.endAdornment,
                startAdornment: props?.startAdornment,
                style: {
                  fontSize: props?.fontSize ?? "0.875rem",
                  padding: props.multiline ? 0 : "none",
                },
              }}
              onInput={props?.onInput}
              disabled={props?.isReadonly ?? false}
              size="small"
              multiline={props.multiline}
              rows={props?.rowheight ?? 3}
              autoFocus={props?.autoFocus}
              rowsMax={props?.maxRowheight ?? 10}
              onChange={props.onChange}
              value={props.value ?? ""}
              error={props.error}
              helperText={props.helperText}
              defaultValue={props?.defaultValue ?? ""}
              onBlur={props?.onBlur}
              onWheel={(e) => e.target.blur()}
              onKeyDown={
                props?.handleKeydown
                  ? (e) => props?.handleKeydown(e)
                  : () => false
              }
              onKeyUp={
                props?.handleKeyUp ? (e) => props?.handleKeyUp(e) : () => false
              }
              step={props?.step}
            />
          </Tooltip>
        </div>
      </>
      {props.isError && (
        <Typography variant={"caption"} color={"error"}>
          {props.errorMessage}
        </Typography>
      )}
    </div>
  );
};

TextBox.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  type: PropTypes.string,
  isReadonly: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

TextBox.defaultProps = {
  // label: "Textbox",
  multiline: false,
  type: "text",
};
