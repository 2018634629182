import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import styled from "@mui/material/styles/styled";
import React from "react";
import "../../App.css";
import {
  Editor,
  MapFieldsOnly,
  MobileNumberInputComponent,
  SelectBox,
  TextBox,
} from "../../components";
import { AuthContext } from "../../contexts";
import { FontFamilySwitch, enum_types, enumSelect } from "../../utils";
import { useStyles } from "./style";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: "0.75rem",
  fontFamily: FontFamilySwitch().bold,
  color: theme.typography.color.secondary,
  marginBottom: theme.spacing(1),
}));
export const ServiceProviderDetails = (props) => {
  const { mapLoad, t } = props;
  const [fullScreenMap, setFullScreenMap] = React.useState(false);
  const classes = useStyles();
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);
  const auth = React.useContext(AuthContext);
  const companyIDs = auth?.auth?.auth?.access?.[
    auth?.auth?.auth?.activeRole
  ]?.company?.map((item) => ({ value: item.value, label: item.label }));
  const [enumValue, setEnum] = React.useState({
    company_registration: [],
    bank_account_type: [],
    bank_routing_type: [],
  });

  const getEnum = async () => {
    const result = await enumSelect([
      enum_types.company_registration,
      enum_types.bank_account_type,
      enum_types.bank_routing_type,
    ]);
    setEnum({
      company_registration: result.company_registration,
      bank_account_type: result.bank_account_type,
      bank_routing_type: result.bank_routing_type,
    });
  };
  React.useEffect(() => {
    getEnum();
  }, []);
  React.useEffect(() => {
    document.addEventListener("fullscreenchange", existFullscreen);
    return () => {
      document.removeEventListener("fullscreenchange", existFullscreen);
    };
  }, []);
  //exist full screen
  const existFullscreen = () => {
    if (document.fullscreenElement === null) {
      setFullScreenMap(false);
    }
  };
  //open full screen
  const fullScreenControl = () => {
    setFullScreenMap(true);
    document.getElementsByClassName("map-wrapper")[0]?.requestFullscreen();
  };

  const manualResponse = (array) => {
    return array?.currency_master?.map((i) => {
      return {
          ...i,
          label: i?.label,
          value: i?.value
      }
  })
  };
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={2}>
          <Box className={classes.imagebox}>
            <Typography className={classes.title}>
              {t("PROFILE PICTURE")}
            </Typography>
            <Avatar
              src={
                props?.data?.image?.length > 0
                  ? props?.data?.image
                  : "/images/citynew.svg"
              }
              style={{ margin: "0 auto" }}
              className={
                props?.data?.image?.length > 0
                  ? classes.avatar
                  : classes.avatar1
              }
            />
            <label>
              {" "}
              <Box
                className={classes.button}
                variant="contained"
                style={{
                  marginTop: props?.data?.image?.length === 0 ? "72px" : "60px",
                }}
              >
                {t("Upload image")}
              </Box>{" "}
              <input
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                onClick={(event) => {
                  event.target.value = null;
                }}
                onChange={(e) => props?.updateimg(e?.target?.files?.[0])}
              />
            </label>
            {props?.data?.image?.length > 0 ? (
              <Typography
                className={classes.removeimg}
                onClick={props?.removeimg}
              >
                {t("Remove Image")}
              </Typography>
            ) : props?.data?.error?.image?.length > 0 ? (
              <span style={{ fontSize: "0.75rem", color: "red" }}>
                {t("Profile Image is required")}
              </span>
            ) : (
              <span style={{ fontSize: "0.75rem" }}></span>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={8} lg={10}>
          <Box className={classes.imagebox1}>
            <Typography className={classes.title}>
              {t("Profile Details")}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={3}>
                <SelectBox
                  isRequired
                  isReadOnly
                  label={t("Company")}
                  placeholder={t("Select Company")}
                  options={companyIDs ?? []}
                  value={props?.data?.company ?? ""}
                  onChange={(value) => {
                    props?.updateState("company", value);
                  }}
                  isError={props?.data?.error?.company?.length > 0}
                  errorMessage={props?.data?.error?.company}
                />
              </Grid>

              <Grid item xs={12} md={4} lg={3}>
                <TextBox
                  label={t("Service Provider Code")}
                  placeholder={t("Enter Code")}
                  value={props?.data?.code ?? ""}
                  onChange={(e) => {
                    props?.updateState("code", e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextBox
                  isrequired
                  label={t("Service Provider Name")}
                  placeholder={t("Enter Name")}
                  value={props?.data?.name ?? ""}
                  onChange={(e) => {
                    props?.updateState("name", e.target.value);
                  }}
                  isError={props?.data?.error?.name?.length > 0}
                  errorMessage={props?.data?.error?.name}
                />
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "10px" }}>
              <Grid item xs={12}>
                <Editor
                  value={props?.data?.description ?? ""}
                  label={t("Description")}
                  handleChange={(e, delta, source, editor) => {
                    props?.updateState(
                      "description",
                      e === "<p><br></p>" ? null : e
                    );
                  }}
                  id={props?.id}
                  height={"80px"}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <div className="map-wrapper">
        <Box
          className={classes.imagebox1}
          style={{ marginTop: "24px", position: "relative" }}
        >
          <CustomTypography>{t("Address")}</CustomTypography>
          {props && (
            <MapFieldsOnly
              fullScreenMap={fullScreenMap}
              fullScreenControl={fullScreenControl}
              mapOptions={{
                isInput: true,
                center: {
                  lat: props?.data?.latitude,
                  lng: props?.data?.longitude,
                },
                lat: props?.data?.latitude,
                lng: props?.data?.longitude,
                mapLoad,
              }}
              mapResult={props?.mapResult}
              autoCompletePlacement={{ top: 14 }}
              fields={[
                {
                  label: t("Door Number"),
                  component: "TextField",
                  value: props?.data?.doorNo,
                  state_name: "doorNo",
                  isrequired: false,
                  placeholder: t("Enter Door Number"),
                  error: props?.data?.error?.doorNo,
                  errorMessage: props?.data?.error?.doorNo?.length > 0,
                  breakpoints: { sm: 6, md: 3 },
                },
                {
                  label: t("Address Line 1"),
                  component: "TextField",
                  value: props?.data?.addressLineOne,
                  state_name: "addressLineOne",
                  isrequired: false,
                  placeholder: t("Enter Address Line 1"),
                  errorMessage: props?.data?.error?.addressLineOne,
                  error: props?.data?.error?.addressLineOne?.length > 0,
                  breakpoints: { sm: 6, md: 3 },
                },
                {
                  label: t("Address Line 2"),
                  component: "TextField",
                  value: props?.data?.addressLineTwo,
                  state_name: "addressLineTwo",
                  isrequired: false,
                  placeholder: t("Enter Address Line 2"),
                  errorMessage: props?.data?.error?.addressLineTwo,
                  error: props?.data?.error?.addressLineTwo?.length > 0,
                  breakpoints: { sm: 6, md: 3 },
                },
                {
                  label: t("Landmark"),
                  component: "TextField",
                  value: props?.data?.landmark,
                  state_name: "landmark",
                  isrequired: false,
                  placeholder: t("Enter Landmark"),
                  errorMessage: props?.data?.error?.landmark,
                  error: props?.data?.error?.landmark?.length > 0,
                  breakpoints: { sm: 6, md: 3 },
                },
                {
                  label: t("Area"),
                  component: "TextField",
                  value: props?.data?.area,
                  state_name: "area",
                  isrequired: false,
                  placeholder: t("Enter Area"),
                  errorMessage: props?.data?.error?.area,
                  error: props?.data?.error?.area?.length > 0,
                  breakpoints: { sm: 6, md: 3 },
                },
                {
                  label: t("City"),
                  component: "TextField",
                  value: props?.data?.city,
                  state_name: "city",
                  isrequired: false,
                  placeholder: t("Enter City"),
                  errorMessage: props?.data?.error?.city,
                  error: props?.data?.error?.city?.length > 0,
                  breakpoints: { sm: 6, md: 3 },
                },
                {
                  label: t("State"),
                  component: "TextField",
                  state_name: "state",
                  value: props?.data?.state,
                  isrequired: false,
                  placeholder: t("Enter State"),
                  errorMessage: props?.data?.error?.state,
                  error: props?.data?.error?.state?.length > 0,
                  breakpoints: { sm: 6, md: 3 },
                },
                {
                  label: t("Country"),
                  component: "TextField",
                  value: props?.data?.country,
                  state_name: "country",
                  isrequired: false,
                  placeholder: t("Enter Country"),
                  errorMessage: props?.data?.error?.country,
                  error: props?.data?.error?.country?.length > 0,
                  breakpoints: { sm: 6, md: 3 },
                },
                {
                  label: t("Pincode"),
                  component: "TextField",
                  state_name: "zipcode",
                  value: props?.data?.zipcode,
                  isrequired: false,
                  placeholder: t("Enter Pincode"),
                  errorMessage: props?.data?.error?.zipcode,
                  error: props?.data?.error?.zipcode?.length > 0,
                  breakpoints: { sm: 6, md: 3 },
                },
              ]}
              onChangeFields={(key, value) => props?.updateState(key, value)}
            />
          )}
        </Box>
      </div>
      <Box className={classes.imagebox1} style={{ marginTop: "24px" }}>
        <Typography className={classes.title}>
          {t("Contact & Other Information")}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={4}>
            <MobileNumberInputComponent
              label={t("Primary Telephone")}
              placeholder={t("Primary Telephone")}
              value={props?.data?.telephone}
              isRequired
              handleChange={(value) => {
                props?.updateState("telephone", value);
              }}
              isError={props?.data?.error?.telephone?.length > 0}
              errorMessage={props?.data?.error?.telephone}
              onKeyPress={(e) => {
                if (e.key === "e") {
                  e.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={4}>
            <MobileNumberInputComponent
              label={t("Primary Mobile")}
              placeholder={t("Primary Mobile")}
              value={props?.data?.mobile}
              isRequired
              handleChange={(value) => {
                props?.updateState("mobile", value);
              }}
              isError={props?.data?.error?.mobile?.length > 0}
              errorMessage={props?.data?.error?.mobile}
              onKeyPress={(e) => {
                if (e.key === "e") {
                  e.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2.5}>
            <TextBox
              label={t("Primary Email Address")}
              placeholder={t("Enter Email Address")}
              value={props?.data?.email ?? ""}
              onChange={(e) => {
                props?.updateState("email", e.target.value);
              }}
              isError={props?.data?.error?.email?.length > 0}
              errorMessage={props?.data?.error?.email}
              isrequired
            />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.imagebox1} style={{ marginTop: "24px" }}>
        <Typography className={classes.title}>
          {t("REGISTRATION DETAILS")}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={3}>
            <SelectBox
              label={t("Company Registration")}
              placeholder={t("Select Registration")}
              options={enumValue.company_registration}
              value={props?.data?.registration ?? ""}
              onChange={(value) => {
                props?.updateState("registration", value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextBox
              label={t("Company Registration Number")}
              placeholder={t("Enter Number")}
              value={props?.data?.registrationNo ?? ""}
              onChange={(value) => {
                props?.updateState("registrationNo", value?.target?.value);
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.imagebox1} style={{ marginTop: "24px" }}>
        <Typography className={classes.title}>
          {t("Banking Basic Details")}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={3}>
            <TextBox
              label={t("Bank Name")}
              placeholder={t("Enter Name")}
              value={props?.data?.bankName ?? ""}
              onChange={(value) => {
                props?.updateState("bankName", value?.target?.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextBox
              label={t("Branch Name")}
              placeholder={t("Enter Name")}
              value={props?.data?.branchName ?? ""}
              onChange={(value) => {
                props?.updateState("branchName", value?.target?.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <SelectBox
              label={t("Currency")}
              placeholder={t("Select Currency")}
              value={props?.data?.currency}
              onChange={(value) => {
                props?.updateState("currency", value);
              }}
              key={JSON.stringify(props?.data?.currency?.value)}
              loading={loading === "currency_master"}
              isPaginate
              debounceTimeout={800}
              loadOptions={(search, array,handleLoading) =>
                loadOptionsApis(
                  "queries/currency_master/list",
                  {},
                  search,
                  array,
                  handleLoading,
                  "data",
                  {},
                  manualResponse
                )
              }
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Typography className={classes.fieldLabel} noWrap>
              {t("Bank Account Type")}
            </Typography>
            <Grid container rowGap={"8px"} columnGap={"8px"}>
              {enumValue?.bank_account_type?.map((value) => {
                return (
                  <Button
                    className={
                      value?.value === props?.data?.accountType?.value
                        ? classes.buttonSelected
                        : classes.buttonUnSelected
                    }
                    onClick={() => props?.updateState("accountType", value)}
                  >
                    <Typography
                      className={
                        value?.value === props?.data?.accountType?.value
                          ? classes.buttonTextSelected
                          : classes.buttonTextUnSelected
                      }
                    >
                      {t(value?.label)}
                    </Typography>
                  </Button>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextBox
              label={t("Account Number")}
              placeholder={t("Enter Number")}
              value={props?.data?.accountNo ?? ""}
              onChange={(value) => {
                props?.updateState("accountNo", value?.target?.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <SelectBox
              label={t("Bank Routing Type")}
              placeholder={t("Select Type")}
              options={enumValue.bank_routing_type}
              value={props?.data?.routingType ?? ""}
              onChange={(value) => {
                props?.updateState("routingType", value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextBox
              label={t("Bank Routing Code")}
              placeholder={t("Enter Code")}
              value={props?.data?.routingCode ?? ""}
              onChange={(value) => {
                props?.updateState("routingCode", value?.target?.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextBox
              label={t("Preferred Cash Collection Office")}
              placeholder={t("Enter Office")}
              value={props?.data?.cashCollection ?? ""}
              onChange={(value) => {
                props?.updateState("cashCollection", value?.target?.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextBox
              label={t("Cheque Name")}
              placeholder={t("Enter Name")}
              value={props?.data?.cheque ?? ""}
              onChange={(value) => {
                props?.updateState("cheque", value?.target?.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextBox
              label={t("Address Line 1")}
              placeholder={t("Enter Line 1")}
              value={props?.data?.bankAddress1 ?? ""}
              onChange={(value) => {
                props?.updateState("bankAddress1", value?.target?.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextBox
              label={t("Address Line 2")}
              placeholder={t("Enter Line 2")}
              value={props?.data?.bankAddress2 ?? ""}
              onChange={(value) => {
                props?.updateState("bankAddress2", value?.target?.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextBox
              label={t("City")}
              placeholder={t("Enter City")}
              value={props?.data?.bankCity ?? ""}
              onChange={(value) => {
                props?.updateState("bankCity", value?.target?.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextBox
              label={t("Country")}
              placeholder={t("Enter Country")}
              value={props?.data?.bankCountry ?? ""}
              onChange={(value) => {
                props?.updateState("bankCountry", value?.target?.value);
              }}
            />
          </Grid>
        </Grid>
      </Box>
      {/* As per CR commented */}
      {/* <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>

                  <Typography className={classes.title}>
                        {t("Social Profile")}
                  </Typography>
                  <Grid container spacing={3}>
                        <Grid item xs={12} md={3} lg={3}>
                              <TextBox
                                    label={t("Facebook")}
                                    placeholder={t("Enter Facebook")}
                                    value={props?.data?.facebook ?? ""}
                                    onChange={(value) => {
                                          props?.updateState("facebook", value?.target?.value);
                                    }} />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                              <TextBox
                                    label={t("Twitter")}
                                    placeholder={t("Enter Twitter")}
                                    value={props?.data?.twitter ?? ""}
                                    onChange={(value) => {
                                          props?.updateState("twitter", value?.target?.value);
                                    }} />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                              <TextBox
                                    label={t("Linkedin")}
                                    placeholder={t("Enter Linkedin")}
                                    value={props?.data?.linkedin ?? ""}
                                    onChange={(value) => {
                                          props?.updateState("linkedin", value?.target?.value);
                                    }} />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                              <TextBox
                                    label={t("Whatsapp")}
                                    placeholder={t("Enter Whatsapp")}
                                    value={props?.data?.whatsapp ?? ""}
                                    onChange={(value) => {
                                          props?.updateState("whatsapp", value?.target?.value);
                                    }} />
                        </Grid>
                  </Grid>
            </Box> */}
    </div>
  );
};
