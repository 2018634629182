import { Box, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { Subheader } from "../../components";
import { AuthContext } from "../../contexts";
import { accessCheckRender, getRoutePermissionNew } from "../../utils";
import { accountFormState } from "../../utils/receipt";
import { AccountList, Details } from "./sections";
import { ReceiptStyles } from "./styles";
import NoDataFound from "../../assets/noData";

const CreateReceipt = ({ t }) => {
  const classes = ReceiptStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [selected, setSelected] = React.useState({});
  const [data, setData] = React.useState({ ...accountFormState });
  const [list, setList] = React.useState();
  const auth = React.useContext(AuthContext);
  const [permission, setPermission] = React.useState({});
  const [count, setCount] = React.useState(0);
  const [noData, setNoData] = React.useState(null);

  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
    }
    // eslint-disable-next-line
  }, [auth]);

  //validate form
  const validateForm = () => {
    let isValid = true;
    let error = { ...data?.error };
    const today = new Date();
    if (!data?.amountTopay || data?.amountTopay?.length === 0) {
      isValid = false;
      error.amountTopay = t("Amount To Pay is Required");
    }
    if (data?.amountTopay <= 0 && data?.amountTopay?.length > 0) {
      isValid = false;
      error.amountTopay = t("Amount To Pay must be greater than 0");
    }
    if (data?.paymentmethod?.length === 0) {
      isValid = false;
      error.paymentmethod = t("Payment Method is Required");
    }
    if (data?.paymentDate?.length === 0) {
      isValid = false;
      error.paymentDate = t("Receipt Date is Required");
    }
    if (data?.bankName?.length === 0) {
      isValid = false;
      error.bankName = t("Cash & Bank Account is Required");
    }
    if (data?.transactionDate?.length === 0) {
      isValid = false;
      error.transactionDate = t("Transaction Date is Required");
    }
      if (new Date(data?.transactionDate) > today) {
        isValid = false;
        error.transactionDate = t("Transaction Date cannot be in the future");
      }
    if (data?.collector?.length === 0) {
      isValid = false;
      error.collector = t("Collector is Required");
    }
    if (new Date(data?.transactionDate) > new Date(data?.paymentDate)) {
      isValid = false;
      error.transactionDate = t("Transaction Date cannot be after the Receipt Date");
    }
    if (data?.paymentmethod?.value !== "Cash") {
      if (data?.transactionNumber?.length === 0) {
        isValid = false;
        error.transactionNumber = t("Transaction Number is Required");
      }
      if (data?.chequeNo?.length === 0) {
        isValid = false;
        error.chequeNo = t("Cheque / Transaction Reference is Required");
      }
     
    }
    setData({ ...data, error });
    return isValid;
  };

  const render = () => {
    return (
      <>
        {/* Subheader */}
        <Subheader
          title={t("createReceipt")}
          chipText={
            state?.type === "account"
              ? t("Pay Against Account")
              : t("Pay Against Invoice")
          }
          goBack={() => navigate()}
        />
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={2.5}>
              {/* Account List */}
              <Box className={classes.root}>
                <AccountList
                  setSelected={setSelected}
                  selected={selected}
                  company={state?.company}
                  setNoData={setNoData}
                />
              </Box>
            </Grid>
            <Grid item xs={9.5}>
              {noData == null ? (
                <></>
              ) : noData ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                  className={classes.noDataDetailRoots}
                >
                  <NoDataFound />
                </Box>
              ) : (
                <Box className={classes.detailRoot}>
                  <Details
                    state={state}
                    permission={permission}
                    validateForm={validateForm}
                    type={state?.type}
                    currency={state?.company?.currency_symbol ?? ""}
                    company={state?.company}
                    selected={selected}
                    data={data}
                    setData={setData}
                    list={list}
                    setList={setList}
                    count={count}
                    setCount={setCount}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };

  return <Box>{accessCheckRender(render, permission)}</Box>;
};

export default withTranslation("receipt")(CreateReceipt);
