import {
  Typography,
  Box,
  Stack,
  Divider,
  Button,
  Drawer,
  Grid,
} from "@mui/material";
import React, { useState, useContext } from "react";
import { managrmentStyles } from "./style";
import {
  DialogBox,
  TableWithPagination,
  RangeDatePicker,
} from "../../components";
import { FormGenerator } from "../../components/formGenerator/index";
import CloseIconComponent from "../../assets/closeIcons";
import {
  manageInvoicePreviewPath,
  manageInvoicePreviewHeading,
  manageInvoicePreviewType,
  getTotalMangeInvoice,
  AlertProps,
  NetWorkCallMethods,
  getToday,
  useWindowDimensions,
} from "../../utils";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";

export const BottomCard = (props) => {
  const language = localStorage.getItem("i18nextLng");
  const { t } = props;

  // classes
  const classes = managrmentStyles();
  // context
  const alert = useContext(AlertContext);
  // ------------------------STATE-------------------------
  const data = [
    {
      lable: t("Total Invoice"),
      value: props?.list?.length ?? 0,
    },
    {
      lable: t("Total Amount"),
      value: `${props?.company?.currency_symbol ?? ""} ${
        props?.list?.length > 0 ? getTotalMangeInvoice(props?.list) : 0
      }`,
    },
  ];
  const [open, setOpen] = useState({
    bool: false,
    type: "",
  });
  const [sucessDialog, setSucessDialog] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [alterDate, setAlterDate] = useState(getToday());
  const [type, setType] = React.useState("");
  const [onInActive, setInActive] = React.useState({
    type: false,
    bool: false,
  });
  const [disable, setDisable] = React.useState({
    in_active: false,
  });
  const size = useWindowDimensions();
  const [isDisableBtn, setIsDisableBtn] = React.useState(false);
  const [drawer, setDrawer] = React.useState(false);
  const [formData, setFormData] = useState({
    alterDate: "",
    invoiceType: { value: "", label: "" },
    paymentTerm: { value: "", label: "" },
    paymentMethod: { value: "", label: "" },
    chequeNo: "",
    issuedBy: "",
    chequeImgLink: [],
  });

  const backdrop = React.useContext(BackdropContext);

  const handleFormChange = (key, value) => {
    const newValue = value && value.target ? value.target.value : value;
    setFormData((prevData) => ({
      ...prevData,
      [key]: newValue,
    }));
  };

  React.useEffect(() => {
    setFormData({
      alterDate: props?.list?.length > 0 ? props?.list[0]?.Invoice_Date : "",
      invoiceType:
        props?.list?.length > 0
          ? props?.enumValue?.type?.find(
              (option) => option.label === props?.list[0]?.Invoice_Type
            )
          : "",
      paymentTerm:
        props?.list?.length > 0
          ? filteredPaymentTermOptions?.find(
              (option) => option.value === props?.list[0]?.Payment_terms
            )
          : "",
      paymentMethod:
        props?.list?.length > 0
          ? props?.enumValue?.paymentMode?.find(
              (option) => option.value === props?.list[0]?.Payment_Method
            )
          : "",
      chequeNo: props?.list?.length > 0 ? props?.list[0]?.Cheque_No : "",
      issuedBy: props?.list?.length > 0 ? props?.list[0]?.Issued_By : "",
      chequeImgLink:
        props?.list?.length > 0 && props?.list[0]?.Cheque_Image_URL
          ? Array.isArray(props?.list[0]?.Cheque_Image_URL)
            ? props.list[0].Cheque_Image_URL.map((url) => ({
                name: "",
                url: url,
                type: "image",
              }))
            : []
          : [],
    });
  }, [props.list, props.enumValue]);

  const clearAll = () => {
    setFormData({
      alterDate: "",
      invoiceType: "",
      paymentTerm: "",
      paymentMethod: "",
      chequeNo: "",
      issuedBy: "",
      chequeImgLink: "",
    });
  };

  // filter payment term options
  const filteredPaymentTermOptions =
    props.enumValue?.paymentTerm?.filter(
      (option) => option.value !== "User Defined"
    ) ?? [];

  // --------------------------FUNCTION-----------------------------
  const openClosePopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const openClose = () => {
    setAnchorEl(null);
  };
  const onSumbit = (data) => {
    setAlterDate(data);
    closePopup("posting");
    setType("alter");
  };

  const closePopup = (type) => {
    if (type === "Proceed") {
      setOpen({
        ...open,
        type: type,
      });
    } else {
      setOpen({
        bool: !open?.bool,
        type: type ?? "",
      });
    }
  };

  const Proceed = (props) => {
    return (
      <div
        className={
          language === "ar"
            ? classes.proceedDialogArabic
            : classes.proceedDialog
        }
      >
        <Box>
          <Typography className={classes.convert}>{props?.title}</Typography>
          <Typography className={classes.convertProcess}>
            {props?.sub}
          </Typography>
          <Box display="flex" alignItems="center" className={classes.marginTop}>
            <Button
              className={classes.yes}
              onClick={props?.yesClick}
              disabled={
                props?.disable ? props?.disable : isDisableBtn ? true : false
              }
            >
              {t("Yes")}
            </Button>
            <Button className={classes.No} onClick={props?.noClick}>
              {t("No")}
            </Button>
          </Box>
        </Box>
      </div>
    );
  };

  const Posting = (props) => {
    return (
      <div
        className={
          language === "ar"
            ? classes.proceedDialog1Arabic
            : classes.proceedDialog1
        }
      >
        <Box>
          <Typography className={classes.convert}>{props?.title}</Typography>
          <Box display="flex" alignItems="center" className={classes.marginTop}>
            <Button className={classes.yes} onClick={props?.onClose}>
              {t("Okay")}
            </Button>
          </Box>
        </Box>
      </div>
    );
  };

  // updateAlterDate(data);
  const ProceedTable = () => {
    return (
      <div className={classes.parentDialog}>
        <TableWithPagination
          heading={manageInvoicePreviewHeading(t)}
          rows={props?.list ?? []}
          path={manageInvoicePreviewPath}
          showpagination={false}
          showpdfbtn={false}
          showexcelbtn={false}
          showSearch={false}
          totalRowsCount={props?.list?.length ?? 0}
          tableType="no-side"
          dataType={manageInvoicePreviewType}
          height={"calc(100vh - 400px)"}
        />

        {/*  */}
        <Box className={classes?.cardBox}>
          <Typography className={classes.amoutTilte}>
            {t("Total_Amount")}
          </Typography>
          <Typography className={classes.amount}>
            {props?.company?.currency_symbol ?? ""}{" "}
            {props?.list?.length > 0 ? getTotalMangeInvoice(props?.list) : 0}
          </Typography>
        </Box>

        <Box className={classes.btnParent}>
          <Button className={classes.Cancel} onClick={closePopup}>
            {t("Cancel")}
          </Button>
          <Button
            className={classes.next}
            onClick={() => closePopup("Proceed")}
          >
            {t("Proceed")}
          </Button>
        </Box>
      </div>
    );
  };

  // components
  const component = {
    Preview: <ProceedTable />,
    posting: (
      <Proceed
        title={t("Alter")}
        type={"alter"}
        yesClick={() => {
          updateAlterInvoice();
        }}
        isDisableBtn={isDisableBtn}
        noClick={() => closePopup("")}
      />
    ),
    Proceed: (
      <Proceed
        title={t("convert")}
        sub={t("process")}
        isDisableBtn={isDisableBtn}
        yesClick={() => {
          updateProceed();
        }}
        noClick={() => closePopup("")}
      />
    ),
  };

  // update alter date
  const updateAlterInvoice = () => {
    if (formData.chequeNo.length > 15) {
      return;
    }
    setType('alter')
    setIsDisableBtn(true)
    const datas = {
      company_id: props?.company?.value,
      managed_invoice_ids: props?.list?.map((x) => x?.id) ?? [],
      payment_due_date: formData.alterDate,
      invoice_type: formData.invoiceType ? formData.invoiceType.value : "",
      payment_terms: formData.paymentTerm ? formData.paymentTerm.value : "",
      payment_method: formData.paymentMethod
        ? formData.paymentMethod.value
        : "",
      cheque_name: formData.chequeNo,
      bank_name: formData.issuedBy,
      cheque_image: Array.isArray(formData.chequeImgLink)
        ? JSON.stringify(formData.chequeImgLink.map((file) => file.url))
        : "[]",
      is_all: false,
      start_date: props?.date?.from ?? undefined,
      end_date: props?.date?.to ?? undefined,
    };

    NetworkCall(
      `${config.api_url}/invoice/alter_managed_invoices`,
      NetWorkCallMethods.post,
      datas,
      null,
      true,
      false
    )
      .then((response) => {
        setSucessDialog(true);
        setIsDisableBtn(false);
        setType("alter");
        closePopup("");
        setDrawer(false);
        setFormData({
          alterDate: "",
          invoiceType: "",
          paymentTerm: "",
          paymentMethod: "",
          chequeNo: "",
          issuedBy: "",
          chequeImgLink: "",
        });
        props?.reloadFunction();
      })
      .catch((error) => {
        setIsDisableBtn(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
      });
  };
  const filteredList = props?.list?.map((item) => ({
    id: item.id,
    Posting_Type: item.Posting_Type,
  }));
  // update Proceed
  const updateProceed = () => {
    closePopup("");
    setType("posting");
    setIsDisableBtn(true);
    backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." });
    const datas = {
      company_id: props?.company?.value,
      managed_invoice_ids:
        props?.allList?.length === props?.list
          ? []
          : props?.list?.map((x) => x?.id) ?? [],
      is_all: props?.allList?.length === props?.list ? true : false,
      start_date: props?.date?.from ?? undefined,
      end_date: props?.date?.to ?? undefined,
      status: filteredList,
    };

    NetworkCall(
      `${config.api_url}/invoice/convert_managed_invoice`,
      NetWorkCallMethods.post,
      datas,
      null,
      true,
      false
    )
      .then((response) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "Loading...",
        });
        if (response?.data?.type == "error") {
          setIsDisableBtn(false);
          closePopup("");
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: response?.data?.message,
          });
          props?.reloadFunction();
          return;
        }

        setSucessDialog(true);
        setType("posting");
        setIsDisableBtn(false);
        closePopup("");
        props?.reloadFunction();
      })
      .catch((error) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "Loading...",
        });
        setIsDisableBtn(false);
        console.error("error", error);
      });
  };
  const InActiveManage = () => {
    setDisable({
      ...disable,
      is_active: true,
    });
    const payload = {
      company_id: props?.company?.value,
      managed_invoice_ids:
        props?.allList?.length === props?.list
          ? []
          : props?.list?.map((x) => x?.id) ?? [],
      is_all: props?.allList?.length === props?.list ? true : false,
      is_active: props?.selectType?.value === "Void Invoices" ? true : false,
    };
    NetworkCall(
      `${config.api_url}/invoice/upsert`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setDisable({
          ...disable,
          is_active: false,
        });
        setInActive({
          type: true,
          bool: true,
        });
        props?.reloadFunction();
      })
      .catch((err) => {
        setDisable({
          ...disable,
          is_active: false,
        });
        setInActive({
          type: false,
          bool: false,
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
      });
  };

  const onCloseAll = () => {
    setOpen({
      type: "",
      open: false,
    });
  };

  const handleUpload = (file) => {
    if (file.type === "image/jpeg" || file.type === "image/png") {
      setFormData((prevData) => ({
        ...prevData,
        chequeImgLink: [
          ...prevData.chequeImgLink,
          { name: file.name, url: file.src, type: file.type },
        ],
      }));
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Only JPG and PNG files are supported."),
      });
    }
  };

  const handleDelete = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      chequeImgLink: prevData.chequeImgLink.filter((_, i) => i !== index),
    }));
  };

  const isFormDataValid = () => {
    return Object.values(formData).some((value) => value !== "");
  };

  return (
    <>
      <Box className={classes.buttomCard}>
        {/*  */}
        <Box>
          <Stack
            direction="row"
            display={"flex"}
            alignItems="center"
            divider={
              <Divider
                sx={{ marginInline: "16px" }}
                orientation="vertical"
                flexItem
              />
            }
          >
            {data?.map((x) => {
              return (
                <Box>
                  <Typography className={classes.title}>{x?.lable}</Typography>
                  <Typography className={classes.totalAmount}>
                    {x?.value}
                  </Typography>
                </Box>
              );
            })}
          </Stack>
        </Box>

        {props?.selectType?.value === "Void Invoices" ? (
          <Box display="flex" alignItems="center">
            <Button
              className={classes.btn}
              onClick={() =>
                setInActive({
                  type: false,
                  bool: true,
                })
              }
              disabled={props?.list?.length > 0 ? false : true}
              variant="contained"
            >
              {t("Unvoid invoice")}
            </Button>
          </Box>
        ) : (
          <Box display="flex" alignItems="center">
            <Button
              className={classes.outLine}
              onClick={() =>
                setInActive({
                  type: false,
                  bool: true,
                })
              }
              variant="outlined"
              disabled={props?.list?.length > 0 ? false : true}
            >
              {t("Void Invoices")}
            </Button>
            <Button
              className={classes.outLine}
              onClick={() => setDrawer(true)}
              variant="outlined"
              disabled={props?.list?.length === 1 ? false : true}
            >
              {t("Alter Invoice")}
            </Button>
            <Button
              className={classes.outLine}
              variant="outlined"
              disabled={true}
            >
              {t("Send Payment")}
            </Button>
            <Button
              className={classes.btn}
              onClick={() => closePopup("Preview")}
              disabled={props?.list?.length > 0 ? false : true}
              variant="contained"
            >
              {t("Post")}
            </Button>
          </Box>
        )}
      </Box>
      {/* date picker */}

      <RangeDatePicker
        outline={t("Cancel")}
        container={t("Change")}
        openClosePopOver={openClosePopOver}
        anchorEl={anchorEl}
        onSumbit={onSumbit}
        openClose={openClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      />
      {/* proceed dialog */}
      <DialogBox
        open={open?.bool}
        isnotTitle={open?.type === "Preview" ? false : true}
        handleClose={() => onCloseAll()}
        onClose={() => onCloseAll()}
        header={t("Preview")}
        maxWidth={"sm"}
        component={component[props?.component ?? open?.type]}
      />

      {/* sucess dialog */}
      <DialogBox
        open={sucessDialog}
        isnotTitle
        handleClose={() => setSucessDialog(false)}
        onClose={() => setSucessDialog(false)}
        maxWidth={"sm"}
        component={
          <Posting
            title={
              type === "alter"
                ? t("Invoice Altered successfully")
                : t("Managed Invoice posted successfully")
            }
            onClose={() => setSucessDialog(false)}
          />
        }
      />

      <DialogBox
        open={onInActive?.bool}
        isnotTitle
        handleClose={() =>
          setInActive({
            type: false,
            bool: false,
          })
        }
        onClose={() =>
          setInActive({
            type: false,
            bool: false,
          })
        }
        maxWidth={"sm"}
        component={
          <>
            {onInActive?.type ? (
              <Posting
                title={t(
                  props?.selectType?.value === "Void Invoices"
                    ? "Unvoided successfully!"
                    : "Voided successfully!"
                )}
                onClose={() =>
                  setInActive({
                    type: false,
                    bool: false,
                  })
                }
              />
            ) : (
              <Proceed
                title={t(
                  `Are you sure you want to  ${
                    props?.selectType?.value === "Void Invoices"
                      ? "Unvoid"
                      : "void"
                  } the invoice?`
                )}
                type={"alter"}
                disable={disable?.is_active}
                yesClick={() => {
                  InActiveManage();
                }}
                noClick={() =>
                  setInActive({
                    type: false,
                    bool: false,
                  })
                }
              />
            )}
          </>
        }
      />

      {/* alter invoice drawer */}
      <Drawer
        anchor="right"
        open={drawer}
        onClose={() => {
          setDrawer(false);
          clearAll();
        }}
      >
        <Box width="550px">
          <Box className={classes.drawerHeader}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Box
                display="flex"
                alignItems="center"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setDrawer(false);
                }}
              >
                <CloseIconComponent />
              </Box>
              <Typography
                className={classes.drawerTitle}
                style={{ marginLeft: 5 }}
              >
                {t("Alter Invoice")}
              </Typography>
            </Box>
            <Typography
              className={classes.drawerClearbtn}
              onClick={clearAll}
              fullWidth
            >
              {t("Clear All")}
            </Typography>
          </Box>
          <Box style={{ height: size.height - 130, overflow: "auto" }} p={2}>
            <Grid container>
              <Grid item xs={12}>
                <FormGenerator
                  t={t}
                  spacing={2.5}
                  components={[
                    {
                      component: "date",
                      label: t("Invoice Date"),
                      placeholder: t("Select Date"),
                      borderColor:"1.5px solid #E4E8EE",
                      value: formData.alterDate,
                      onChange: (value) => handleFormChange("alterDate", value),
                      size: { xs: 12, sm: 6, md: 6, lg: 6 },
                      isActive: true,
                    },
                    {
                      component: "select",
                      label: t("Invoice Type"),
                      placeholder: t("Select Type"),
                      value: formData.invoiceType,
                      onChange: (value) =>
                        handleFormChange("invoiceType", value),
                      options: props.enumValue.type,
                      size: { xs: 12, sm: 6, md: 6, lg: 6 },
                      isActive: true,
                    },
                    {
                      component: "select",
                      label: t("Payment Term"),
                      placeholder: t("Select Term"),
                      value: formData.paymentTerm,
                      onChange: (value) =>
                        handleFormChange("paymentTerm", value),
                      options: filteredPaymentTermOptions,
                      size: { xs: 12, sm: 6, md: 6, lg: 6 },
                      isActive: true,
                    },
                    {
                      component: "select",
                      label: t("Payment Method"),
                      placeholder: t("Select Method"),
                      value: formData.paymentMethod,
                      onChange: (value) =>
                        handleFormChange("paymentMethod", value),
                      options: props.enumValue.paymentMode,
                      size: { xs: 12, sm: 6, md: 6, lg: 6 },
                      isActive: true,
                    },
                    // dynamic form field for cheque details
                    ...(formData.paymentMethod &&
                    formData.paymentMethod.value &&
                    (formData.paymentMethod.value === "Cheque" ||
                      formData.paymentMethod.value === "Post Dated Cheques")
                      ? [
                          {
                            component: "number",
                            label: t("Cheque No"),
                            value: formData.chequeNo,
                            onChange: (value) =>
                              handleFormChange("chequeNo", value),
                            size: { xs: 12, sm: 6, md: 6, lg: 6 },
                            isActive: true,
                            error: formData.chequeNo.length > 15 ? t("Cheque No cannot exceed 15 digits") : "",
                          },
                          {
                            component: "text",
                            label: t("Issued By"),
                            value: formData.issuedBy,
                            onChange: (value) =>
                              handleFormChange("issuedBy", value),
                            size: { xs: 12, sm: 6, md: 6, lg: 6 },
                            isActive: true,
                          },
                          {
                            component: "customFileUpload",
                            label: t("Upload Cheque Image"),
                            value: formData.chequeImgLink,
                            onChange: (value) =>
                              handleFormChange("chequeImgLink", value),
                            handleDelete,
                            handleUpload,
                            size: { xs: 12, sm: 6, md: 6, lg: 6 },
                            isActive: true,
                          },
                        ]
                      : []),
                  ]}
                />
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.bottombtn}>
            <Button
              className={classes.bottombtn2}
              onClick={updateAlterInvoice}
              fullWidth
              disabled={!isFormDataValid()}
            >
              {t("Update")}
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};
