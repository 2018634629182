import { makeStyles } from "@mui/styles";
import { FontFamilySwitch, remCalc } from "../../utils";


export const FreeTextInvoiceStyle = makeStyles((theme) => ({
    createBtn: {
        height: 40,
        width: 78
    },
    publishPopupTitle: {
        fontSize: "1rem",
        color: "#fff",
        fontFamily: FontFamilySwitch().extraBold,
        whiteSpace: "nowrap"
    },
    publishPopupBtn: {
        background: "#fff",
        borderRadius: 4,
        width: 150,
        height: 40,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        cursor: "pointer"
    },
    publishbtnText: {
        color: theme.palette.primary.main,
        fontSize: "0.875rem",
        fontWeight: "bold",
    },
    publishDialog: {
        '& .MuiPaper-root': {
            background: "transparent !important"
        }
    },
    proceedDialog: {
        padding: "54px 50px 51px 228px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        backgroundImage: `url(${"images/announsment.svg"})`,
        backgroundRepeat: "no-repeat",
        backgroundColor: theme?.palette?.primary?.main
    },
    convert: {
        color: "white",
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().bold,
    },
    yes: {
        color: theme?.palette?.primary?.main,
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold,
        border: `1px solid white`,
        backgroundColor: "white",
        padding: "7px 29px",
        width: "100%",
        "&:hover": {
            border: `1px solid white`,
            backgroundColor: "white",
        },
    },
    No: {
        color: "white",
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        border: `1px solid white`,
        padding: "7px 29px",
        backgroundColor: theme?.palette?.primary?.main,
        marginInlineStart: "10px",
        width: "100%",
        "&:hover": {
            border: `1px solid white`,
            backgroundColor: theme?.palette?.primary?.main,
        },
    },
}))


export const CreateEditInvoiceDrawerStyle = makeStyles((theme) => ({
    createBtn: {
        height: 40,
        width: 78
    },
    drawerBox: {
        width: '456px',
        position: "relative"
    },
    header: {
        position: "fixed",
        top: 0,
        width: "100%"
    },
    bottom: {
        position: "fixed",
        bottom: 0,
        padding: '8px',
        width: '456px'
    },
    body: {
        // position: "fixed",
        top: 0,
        marginTop: '64px',
        height: "calc(100vh - 130px)",
        overflow: "auto",
        width: "100%"
    },
    drawerCloseBtn: {
        fontSize: "1rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
        background: theme.palette.background.tertiary,
        borderRadius: 4,
        height: 32,
        width: 32,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    drawerHeading: {
        fontSize: "1rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    previous: {
        border: `1px solid ${theme.palette.border.primary}`,
        background: "transparent",
        color: theme.typography.color.primary,
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold,
        // height: 40,
        padding: '11px 16px',
        lineHeight: '19px !important',
        "&:hover": {
            background: "transparent",
        }
    },
    next: {
        padding: '11px 16px',
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold,
        lineHeight: '19px !important'

    },
}))

export const CreateEditInvoiceStyle = makeStyles((theme) => ({
    headTableCell: {
        border: "none !important",
        padding: 0
    },
    headTitle: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.tertiary
    },
    bottomCard: {
        // position: "absolute",
        // bottom: 0,
        // width: "100%",
        borderTop: `1px solid ${theme.palette.border.primary}`,
        padding: 16
    },
    containBox: {
        border: `1px solid ${theme.palette.border.primary}`,
        borderRadius: 4
    },
    singleBorder: {
        borderRight: `1px solid ${theme.palette.border.primary}`
    },
    generateNotes: {
        height: 40,
        whiteSpace: "nowrap",
        "&:hover": {
            background: "transparent"
        }
    },
    drawerBox: {
        width: '456px',
        position: "relative"
    },
    header: {
        position: "fixed",
        top: 0,
        width: "100%",
        backgroundColor:"#F2F4F7"
    },
    bottom: {
        position: "fixed",
        bottom: 0,
        padding: '8px',
        width: '456px'
    },
    body: {
        // position: "fixed",
        top: 0,
        marginTop: '64px',
        height: "calc(100vh - 130px)",
        overflow: "auto",
        width: "100%"
    },
    drawerCloseBtn: {
        fontSize: "1rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
        background: theme.palette.background.tertiary,
        borderRadius: 4,
        height: 32,
        width: 32,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    drawerHeading: {
        fontSize: "1rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    previous: {
        border: `1px solid ${theme.palette.border.primary}`,
        background: "transparent",
        color: theme.typography.color.primary,
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold,
        // height: 40,
        padding: '11px 16px',
        lineHeight: '19px !important',
        "&:hover": {
            background: "transparent",
        }
    },
    next: {
        padding: '11px 16px',
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold,
        lineHeight: '19px !important'

    },
    addText: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.palette.primary.main,
        cursor: "pointer"
    },

    // extra 

    headCell: {
        padding: "8px",
        border: "none"
    },
    headText: {
        fontSize: "0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    bodyCell: {
        padding: "4px",
        border: "none",
        minWidth: "125px"
    },
    bodyText: {
        fontSize: "0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    addBillLine: {
        fontSize: "0.875rem",
        color: theme.palette.primary.main,
        fontFamily: FontFamilySwitch().bold,
        padding: "4px",
        cursor: "pointer",
        whiteSpace: "noWrap"
    },
    billLineCell: {
        border: `1px solid ${theme.palette.border.primary}`
    },
    customText: {
        '& .MuiFilledInput-input': {
            // height: "14px !important",
            padding: "0px !important",
            fontSize: "0.875rem",
            height: 35,
            fontFamily: FontFamilySwitch().bold
        }
    },
    textBoxTypography: {
        width: "125px",
        margin: "auto",
        fontSize: "0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    table: {
        height: "calc(100vh - 420px)",
        [theme.breakpoints.only("md")]: {
            height: "calc(100vh - 460px)"
        },
        [theme.breakpoints.only("sm")]: {
            height: "calc(100vh - 460px)"
        },
        // minHeight: "auto",
        // maxHeight:"200px",
        // overflow: "auto"
    },
    edit: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.palette.primary.main,
        cursor: "pointer"
    },
    notesHoverBox: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: "4px"
    },
    notesBox: {
        border: `1px solid ${theme.palette.border.primary}`,
        borderRadius: "4px"
    },
    notesTitle: {
        fontSize: "0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    notesSubTitle: {
        fontSize: "0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().bold,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "auto",
    },
    createBtn: {
        height: 40
    },
    endAdornmentText: {
        fontSize: "0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().semiBold,
        width: 35
    },
    optionHeader: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.primary
    },
    optionSubTitle: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().regular,
        color: theme.typography.color.secondary
    },
    showMoreLabel: {
        fontSize: "0.75rem",
        color: theme.palette.primary.main,
        fontFamily: FontFamilySwitch().bold,
        cursor: "pointer",        
        width: "210px"
    },
    menuItem:{
        fontSize: remCalc(14),
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
    }
}))