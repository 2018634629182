export const Path = ["id", "item_type", "category", "name", "is_active"];

export const Heading = (t) => [
  { title: t("ID"), field: "id" },
  { title: t("Item Type"), field: "item_type" },
  { title: t("Category"), field: "category" },
  { title: t("Name"), field: "name" },
  { title: t("Status"), field: "is_active" },
  { title: "", field: "icon" },
];

export const Type = [
  { type: ["text"], name: "id" },
  { type: ["text"], name: "item_type" },
  { type: ["text"], name: "category" },
  { type: ["text"], name: "name" },
  { type: ["status"], name: "is_active" },
  { type: ["more"], icon: "icon" },
];

export const defaultAddState = {
  formType: "add",
  id: "",
  item_type: "",
  category: "",
  name: "",
  revenue_account: "",
  expense_number: "",
  assets_number: "",
  specification: "",
  is_active: true,
  is_delete: false,
  error: {
    item_type: "",
    category: "",
    name: "",
    revenue_account: "",
    expense_number: "",
    assets_number: "",
    specification: "",
  },
};

export const defaultFilterData = { is_active: [true] };
