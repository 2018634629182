import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Typography } from "@mui/material";
import Stack from '@mui/material/Stack';
import React from "react";
import Select, { components } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { DropDownCard } from "../../../components/existingLead/dropDownCard";
import { FontFamilySwitch } from '../../../utils';
import { CustomTypography } from './typography';

const Control = ({ children, ...props }, sendedProps) => {

    const { icon, label } = sendedProps;

    return (
        <components.Control {...props}>
            <Stack
                direction={"row"}
                alignItems={"center"}
                width={1}
            >
                <div style={{ flex: 1, width: '85%' }}>
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        gap={1}
                        sx={{
                            cursor: 'pointer'
                        }}
                    >
                        {icon && icon}
                        {label &&
                            <CustomTypography
                                fontFamily={FontFamilySwitch().semiBold}
                                fontSize={12}
                                color={"#98A0AC"}
                                noWrap
                            >
                                {label}
                            </CustomTypography>
                        }
                    </Stack>
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                    >
                        {children}
                    </Stack>
                </div>
                <ArrowDropDownIcon
                    style={{
                        fontSize: "26px",
                        color: "#98A0AC",
                        transform: props.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)',
                        transition: '0.25s',
                    }} />
            </Stack>
        </components.Control>
    );
};

const isSingleStyle = (prop) => {
    if (prop.isMulti) return {}
    return {
        color: prop?.isSelected ? "#5078E1" : "#091B29",
        backgroundColor: "transparent",
        '&:hover,&:active': {
            backgroundColor: "transparent",
            color: "#5078E1"
        },
    }
}

const Option = ({ children, ...props }) => {
    return (
        <components.Option {...props}>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
            >
                <DropDownCard data={props?.data} />

            </Stack>
        </components.Option>
    );
};


export const LeadSelect = React.memo((props) => {

    const {
        key,
        value,
        // controlComponent,
        icon,
        label,
        placeholder,
        loadOptions,
        onChange,
        options,
        loading,
        isReadOnly,
        isMulti,
        debounceTimeout,
        reduceOptions,
        isClearable = true,
        styles = {},
        isPaginate = false,
        isError,
        errorMessage
    } = props;

    const customStyles = {

        control: (base) => ({
            ...base,
            borderColor: props?.error ? '#FF4B4B' : "#E4E8EE",
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '10px',
            borderRadius: '4px',
            boxShadow: 'none',
            '&:not(:focus)': {
                boxShadow: "none"
            },
            '&:hover': {
                borderColor: props?.error ? '#FF4B4B' : '#5078E1',
            },
            border: '1px solid #E4E8EE',
            ...styles?.control ?? {},

        }),
        placeholder: defaultStyles => {
            return {
                ...defaultStyles,
                fontFamily: FontFamilySwitch().regular,
                fontSize:"0.875rem",
                whiteSpace: "nowrap",
                color: "#98A0AC"

            };
        },
        dropdownIndicator: (base, prop) => {
            return {
                ...base,
                transform: prop.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)',
                transition: '0.25s',
                marginRight: "-4px",
                padding: "0px"
            }
        },
        option: (defaultStyles, prop) => {
            return {
                ...defaultStyles,
                fontFamily: FontFamilySwitch().semiBold,
                fontSize:"0.875rem",
                cursor: "pointer",
                ...isSingleStyle(prop),
            };
        },
        valueContainer: (base) => ({
            ...base,
            padding: 0
        }),
        input: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
            '& input': {
                height: 20,
                transform: 'translateY(3px)',
            }
        }),
        singleValue: (base) => ({
            ...base,
            color: '#091B29',
            fontFamily: FontFamilySwitch().semiBold,
            fontSize:"0.875rem",
        }),
        menu: defaultStyles => {
            return {
                ...defaultStyles,
                zIndex: 2,
                boxShadow: "0px 10px 25px #0000000A",
                border: "1px solid #E4E8EE",
                borderRadius: 8
            };
        },
        indicatorsContainer: defaultStyles => {
            return {
                ...defaultStyles,
                position: 'absolute',
                right: 30,
                top: '50%',
                transform: 'translateY(-50%)'
            };
        },

    };

    return (
        <>
            {
                isPaginate ? (
                    <AsyncPaginate
                        key={key}
                        isClearable={isClearable}
                        isSearchable
                        components={{
                            IndicatorSeparator: () => null,
                            Control: (defaultProps) => Control(defaultProps, { icon, label }),
                            DropdownIndicator: () => null,
                            Option
                        }}
                        value={value}
                        placeholder={placeholder}
                        loadOptions={loadOptions}
                        onChange={onChange}
                        options={options}
                        isLoading={loading}
                        defaultOptions={options}
                        styles={customStyles}
                        isDisabled={isReadOnly}
                        isMulti={isMulti}
                        debounceTimeout={debounceTimeout}
                        reduceOptions={reduceOptions}
                        menuPlacement={"auto"}
                        minMenuHeight={"150px"}
                    />
                ) : (
                    <Select
                        //isClearable={isClearable}
                        isSearchable
                        components={{
                            IndicatorSeparator: () => null,
                            Control: (defaultProps) => Control(defaultProps, { icon, label }),
                            DropdownIndicator: () => null
                        }}
                        value={value}
                        getOptionLabel={option => option.label}
                        getOptionValue={option => option.value}

                        placeholder={placeholder}
                        options={options}
                        isLoading={loading}
                        onChange={onChange}
                        styles={customStyles}
                        isMulti={isMulti}
                        isDisabled={isReadOnly}
                        menuPlacement={"auto"}
                    />


                )
            }
            {isError && (
                <Typography variant={"caption"} color={"error"}>
                    {errorMessage}
                </Typography>
            )}
        </>

    )
})
