import React, { createContext } from 'react'
import { UseDebounce } from '../../components'
import { AlertContext, AuthContext, BackdropContext } from '../../contexts'
import moment from 'moment'
import { addSecondFormat, AlertProps, constructPropertyList, getCompanyOption, LocalStorageKeys, NetWorkCallMethods } from '../../utils'
import { NetworkCall } from '../../networkcall'
import { config } from '../../config'
import { addDays, endOfMonth, endOfWeek, format, startOfMonth, startOfWeek, subDays } from 'date-fns'
import { v4 as uuidv4 } from 'uuid';
import {jwtDecode} from 'jwt-decode'
import { withTranslation } from 'react-i18next'



export const AmenitiesBookingContext = createContext()

const AmenitiesBookingContextProvider = (props) => {

    // const
    
    const { t = () => false, fromType } = props
    const title = props?.title ?? t("Amenity Booking");
    const debounce = UseDebounce()

    // useContext
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)
    const decoded = jwtDecode(localStorage.getItem(LocalStorageKeys.authToken))

    // useState
    const [openCA, setOpenCA] = React.useState(false)
    const [openDecline, setOpenDecline] = React.useState(false)
    // const [permission, setPermission] = React.useState({})
    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [propertyList, setPropertyList] = React.useState([])
    const [selectedProperty, setSelectedProperty] = React.useState({})
    // const [amenityCategoryList, setAmenityCategoryList] = React.useState([])
    // const [selectedAmenityCategory, setSelectedAmenityCategory] = React.useState({})
    const [amenityList, setAmenityList] = React.useState([])
    const [selectedAmenity, setSelectedAmenity] = React.useState({})
    const [selectedDescrption, setSelectedDescrption] = React.useState({})
    const [selectedAmenityView, setSelectedAmenityView] = React.useState({})
    const [searchText, setSearchText] = React.useState("")
    const [amenityBooking, setAmenityBooking] = React.useState({})
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [amenityBookingDetail, setAmenityBookingDetail] = React.useState({})
    const [dateRange, setDateRange] = React.useState([])
    const [calendarData, setCalendarData] = React.useState([])
    const [calendarEvent, setCalendarEvent] = React.useState("")
    const [openDailyCalendar, setOpenDailyCalendar] = React.useState(false)
    const [weekdates, setWeekdates] = React.useState({ startDate: new Date(), endDate: addDays(new Date(), 6) })
    const [selected, setSelected] = React.useState("Weekly")
    const [initialLoad, setInitialLoad] = React.useState(false)
    const [step, setStep] = React.useState("step1")
    const [slotType, setSlotType] = React.useState("Slot basis")
    const [contactDetail, setContactDetail] = React.useState({
        name: "",
        mobileNo: "",
        mailId: "",
        account: "",
        error: {
            name: "",
            mobileNo: "",
            mailId: ""
        }
    })
    const [bookWalkin, setBookWalkin] = React.useState(false)

    const [data, setData] = React.useState({
        venue: [],
        selectedVenue: {},
        selectedDate: new Date(),
        availableSlot: [],
        selected_slot: [],
        slotIndex: null,
        property_id: "",
        is_back: false
    })
    const [finalDays, setFinalDays] = React.useState([])
    const [month, setMonth] = React.useState(new Date())
    const [booked, setBooked] = React.useState(false)
    const [selectedUnit, setSelectedUnit] = React.useState("")
    const [amenities, setAmenities] = React.useState({
        amenityCategory: [],
        amenityMaster: [],
        amenityDescription: [],
        selectedAmenityCategory: {},
        selectedAmenityMaster: {},
        selectedAmenityDescription: {}
    })
    const [selectedExistingContact, setSelectedExistingContact] = React.useState("")
    const [member, setMember] = React.useState({
        bool: false,
        count: ""
    })
    const [amounts, setAmounts] = React.useState({
        totalAmount: 0,
        taxAmount: 0,
        invoiceAmount: 0
    })
    // Check Availability popup function

    const slotToggle = [
        { label: "Slot basis", value: "Slot basis" },
        // { label: "Half day", value: "Half day" },
        // { label: "Full Day", value: "Full Day" }
    ]

    const openPopup = () => {
        // setOpenCA(true)
        setStep("step2")
    }

    // Decline pop up function
    const handleTableIcon = (type, data) => {
        let viewData = data?.data
        if (type === "view") {
            getBookingDetail(viewData)
        }
    }


    const getBookingDetail = (value) => {
        const payload =
        {
            property_facility_id: value?.property_facility_id,
            booking_id: value?.booking_id,
            current_date: format(new Date(), "yyyy-MM-dd HH:mm:ss")
        }
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/get_booked_details`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setCalendarEvent("")
                setAmenityBookingDetail(response?.data?.data?.[0] ?? [])
                setOpenDecline(true)
            })
            .catch((error) => {
                if (error?.response?.data?.error?.code === 406) {
                    // Request made and server responded
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.info,
                        msg: error?.response?.data?.error?.message ?? t("Try Again"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });
                } else {
                    alert.setSnack({
                        ...alert, open: true, severity: AlertProps.severity.error,
                        msg: AlertProps.message.some_thing_went_wrong,
                    })
                }
            });
    };

    // calender event click function

    const handleEvent = (value) => {
        let payload = {
            property_facility_id: value?.data?.facility_id,
            booking_id: value?.data?.booking_id,
            current_date: format(new Date(), "yyyy-MM-dd HH:mm:ss")
        }
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/get_booked_details`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((r) => {
            setCalendarEvent(r?.data?.data?.[0])
            setOpenDecline(true)
        }).catch((error) => {
            if (error?.response?.data?.error?.code === 406) {
                // Request made and server responded
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.info,
                    msg: error?.response?.data?.error?.message ?? t("Try Again"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            } else {
                alert.setSnack({
                    ...alert, open: true, severity: AlertProps.severity.error,
                    msg: AlertProps.message.some_thing_went_wrong,
                })
            }
        })
    }

    const handleMonthEvent = (date) => {

        const customDateRange = [moment(date).format("YYYY-MM-DD 00:00:00"), moment(date).format("YYYY-MM-DD 23:59:59")]
        getAmenityBookingCalandarList(
            amenities?.selectedAmenityDescription, customDateRange,
            selectedCompany, "Daily"
        )
        setOpenDailyCalendar({ status: !openDailyCalendar, selectedDate: date })


    }

    const closeDailyCalendarPopup = () => {
        setOpenDailyCalendar(!openDailyCalendar)

        getAmenityBookingCalandarList(
            amenities?.selectedAmenityDescription, dateRange,
            selectedCompany, selected
        )
    }

    //    // use effect to get permission
    //    React.useEffect(() => {
    //        const perm = getRoutePermissionNew(auth)
    //        if (perm) {
    //            setPermission(perm)
    //            if (perm?.read) {
    //                getCompany()
    //                // const result = eachHourOfInterval({
    //                //     start: new Date(2022, 7, 17, 0),
    //                //     end: new Date(2022, 7, 17, 23)
    //                // })
    //                // setDailyInterval(result)
    //            }
    //        }
    //        // eslint-disable-next-line
    //    }, [auth]);

    const getCompany = () => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
            getPropertyList(company?.selected)
        }
    }

    // Function to change the company
    const handleCompanyChange = (e) => {
        setSelectedCompany(e)
        getPropertyList(e)
    }

    // Function to change property list
    const getPropertyList = (company) => {
        let property = props?.isCommunity ? constructPropertyList(company, true) : constructPropertyList(company, false)
        setPropertyList(property?.list);
        setSelectedProperty(property?.list?.[0])
        getAmenityCategories(company, property?.list?.[0])

    }

    // Function to change property
    const handlePropertyChange = (e) => {
        setSelectedProperty(e)
        getAmenityCategories(selectedCompany, e)
    }

    // Funtion to get amenity categories
    const getAmenityCategories = (company = selectedCompany, property = selectedProperty) => {
        let payload = {}
        if (property?.label === "All Properties") {
            payload["company_id"] = company?.value
        } else {
            payload["property_id"] = property?.value
        }
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/get_amenity_categories`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((r) => {
            const res = r?.data?.data?.amenities_categories
            let tempList = r?.data?.data?.amenities_categories?.map((i) => {
                return {
                    label: i?.amenity_category,
                    value: i?.amenity_category_id,
                    ...i
                }
            })
            const amenityCategory = res?.map((e) => {
                return {
                    label: e?.amenity_category,
                    value: e?.amenity_category_id,
                    ...e
                }
            })

            const amenityMaster = amenityCategory?.[0]?.amenitiesItems?.reduce((acc, current) => {
                const x = acc.find(item => item.amenities_type === current.amenities_type);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, [])?.map((i) => {
                return {
                    label: i?.amenities_name,
                    value: i?.facility_id,
                    ...i
                }
            })
            const amenityDescription = res?.[0]?.amenitiesItems?.filter(v => v?.amenities_name === amenityMaster?.[0]?.amenities_name)?.map(x => {
                return {
                    ...x,
                    label: x?.description,
                    value: x?.facility_id
                }
            })

            setAmenities({
                amenityCategory: amenityCategory ?? [],
                selectedAmenityCategory: amenityCategory?.[0],
                amenityMaster: amenityMaster ?? [],
                selectedAmenityMaster: amenityMaster?.[0],
                amenityDescription: amenityDescription ?? [],
                selectedAmenityDescription: amenityDescription?.[0]
            })

            if (dateRange.length > 0) {
                getAmenityBookingList(
                    amenityDescription?.[0], dateRange,
                    selectedCompany, searchText, 0, limit
                )
                getAmenityBookingCalandarList(
                    amenityDescription?.[0], dateRange,
                    selectedCompany, selected
                )
            }
            getAmenitiesForAmenityCategory(company, property, tempList?.[0])


        }).catch((error) => {
            if (error?.response?.data?.error?.code === 406) {
                // Request made and server responded
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.info,
                    msg: error?.response?.data?.error?.message ?? t("Try Again"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            } else {
                alert.setSnack({
                    ...alert, open: true, severity: AlertProps.severity.error,
                    msg: AlertProps.message.some_thing_went_wrong,
                })
            }
        })
    }

    // Function to change property
    const handleAmenityCategoryChange = (e) => {
        const amenityMaster = e?.amenitiesItems?.reduce((acc, current) => {
            const x = acc.find(item => item?.amenities_type === current?.amenities_type);

            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, [])?.map((i) => {
            return {
                label: i?.amenities_name,
                value: i?.facility_id,
                ...i
            }
        })
        // amenities?.amenityCategory?.filter(v => v?.amenity_category === e?.amenity_category)?.[0]?.amenitiesItems?.map((i) => {
        //     return {
        //         label: i?.amenities_name,
        //         value: i?.facility_id,
        //         ...i
        //     }
        // })
        const amenityDescription = amenities?.amenityCategory?.filter(v => v?.amenity_category === e?.amenity_category)?.[0]?.amenitiesItems?.filter(v => v?.amenities_type === amenityMaster?.[0]?.amenities_type)?.map(x => {
            return {
                ...x,
                label: x?.description,
                value: x?.facility_id
            }
        })
        setAmenities({
            ...amenities,
            amenityMaster: amenityMaster,
            selectedAmenityCategory: e,
            selectedAmenityMaster: amenityMaster?.[0],
            amenityDescription: amenityDescription,
            selectedAmenityDescription: amenityDescription?.[0]
        })
        // setSelectedAmenityCategory(e)
        if (dateRange.length > 0) {
            getAmenityBookingList(
                amenityDescription?.[0], dateRange,
                selectedCompany, searchText, 0, limit
            )
            getAmenityBookingCalandarList(
                amenityDescription?.[0], dateRange,
                selectedCompany, selected
            )
        }
        // getAmenitiesForAmenityCategory(selectedCompany, selectedProperty, e)
    }

    // Funtion to get amenity categories
    const getAmenitiesForAmenityCategory = (
        company = selectedCompany,
        property = selectedProperty,
        amenityCategory = amenities?.selectedAmenityCategory
    ) => {
        let payload = {
            "amenity_category_id": amenityCategory?.value,
            "selected_date": `${moment(new Date()).format("YYYY-MM-DD")} 00:00:00`,
            current_date: format(new Date(), "yyyy-MM-dd HH:mm:ss")
        }
        if (property?.label === "All Properties") {
            payload["company_id"] = company?.value
        } else {
            payload["property_id"] = property?.value
        }
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/get_amenities_for_category`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((r) => {
            let tempList = r?.data?.data?.amenityByDate?.map((i) => {
                return {
                    ...i,
                    label: i?.amenities_name,
                    value: i?.facility_id,
                    assets: i?.assets?.map((j) => {
                        return {
                            url: j
                        }
                    })
                }
            })

            const amenitySecondDropdown = amenityCategory?.amenitiesItems?.map((e) => {
                return {
                    label: e?.amenities_name,
                    value: e?.facility_id,
                    ...e
                }
            })
            setAmenityList(amenitySecondDropdown)
            const data = amenitySecondDropdown?.filter(v => v?.amenities_name === amenitySecondDropdown?.[0]?.amenities_name).map(x => {

                return {
                    ...x,
                    label: x?.description,
                    value: x?.facility_id
                }
            })
            setSelectedAmenity(amenitySecondDropdown?.[0])
            setSelectedDescrption(data)

            if (dateRange.length > 0) {
                getAmenityBookingList(
                    tempList?.[0], dateRange,
                    company, searchText, 0, limit
                )
                getAmenityBookingCalandarList(
                    tempList?.[0], dateRange,
                    company, selected
                )
            }
        }).catch((error) => {
            if (error?.response?.data?.error?.code === 406) {
                // Request made and server responded
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.info,
                    msg: error?.response?.data?.error?.message ?? t("Try Again"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            } else {
                alert.setSnack({
                    ...alert, open: true, severity: AlertProps.severity.error,
                    msg: AlertProps.message.some_thing_went_wrong,
                })
            }
        })
    }

    //    React.useEffect(() => {
    //        if (dateRange?.length > 0 && selectedAmenity?.value && selectedCompany?.value && !initialLoad) {
    //            getAmenityBookingList(
    //                selectedAmenity, dateRange,
    //                selectedCompany, searchText, 0, limit
    //            )
    //            getAmenityBookingCalandarList(
    //                selectedAmenity, dateRange,
    //                selectedCompany, selected
    //            )
    //            setInitialLoad(true)
    //        }
    //        // eslint-disable-next-line
    //    }, [dateRange, selectedAmenity, selectedCompany])

    // Function to change property
    const handleAmenityMasterChange = (e) => {
        const data = amenities?.selectedAmenityCategory?.amenitiesItems?.filter(v => v?.amenities_type === e?.amenities_type).map(x => {

            return {
                ...x,
                label: x?.description,
                value: x?.facility_id
            }
        })
        // setSelectedDescrption(data)
        setAmenities({
            ...amenities,
            amenityDescription: data,
            selectedAmenityMaster: e,
            selectedAmenityDescription: data?.[0]
        })
        if (dateRange.length > 0 && e) {
            getAmenityBookingList(
                data?.[0], dateRange,
                selectedCompany, searchText, 0, limit
            )
            getAmenityBookingCalandarList(
                data?.[0], dateRange,
                selectedCompany, selected
            )
        }
    }

    const handleDescriptionChange = (e) => {
        setAmenities({
            ...amenities,
            selectedAmenityDescription: e
        })
        if (dateRange.length > 0) {
            getAmenityBookingList(
                e, dateRange,
                selectedCompany, searchText, 0, limit
            )
            getAmenityBookingCalandarList(
                e, dateRange,
                selectedCompany, selected
            )
        }
    }

    // Function to change property
    const handleAmenityViewChange = (e) => {
        setSelectedAmenityView(e)

        if (selectedAmenity?.value) {
            getAmenityBookingList(
                amenities?.selectedAmenityDescription, dateRange,
                selectedCompany, searchText, 0, limit
            )
            getAmenityBookingCalandarList(
                amenities?.selectedAmenityDescription, dateRange,
                selectedCompany, selected
            )
        }
    }

    // Function to get date range
    const getDateRange = (date_range = [], type = "") => {
        let tempDateRange = [
            moment(date_range?.[0]).format("YYYY-MM-DD 00:00:00"),
            moment(date_range?.[1]).format("YYYY-MM-DD 23:59:59")
        ]
        setDateRange(tempDateRange)
        if (selectedAmenity?.value) {
            getAmenityBookingList(
                amenities?.selectedAmenityDescription, tempDateRange,
                selectedCompany, searchText, 0, limit
            )
            getAmenityBookingCalandarList(
                amenities?.selectedAmenityDescription, tempDateRange,
                selectedCompany, type
            )
        }
    }

    const updateAmenityBookingList = () => {
        getAmenityBookingList(
            amenities?.selectedAmenityDescription, dateRange,
            selectedCompany, "", 0, limit
        )
    }
    // Funtion to get amenity booking
    const getAmenityBookingList = (
        amenity = amenities?.selectedAmenityDescription,
        date_range = dateRange,
        company = selectedCompany,
        search = searchText,
        offset = 0,
        limit = 10,
    ) => {
        let payload = {
            property_facility_id: amenity?.value,
            date_range: date_range,
            company: company?.value,
            current_date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
            search, offset, limit,
        }

        NetworkCall(
            `${config.api_url}/amenities_booking_v2/get_my_bookings`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((r) => {
            let totalCount = r?.data?.data?.count
            let list = r?.data?.data?.bookings?.map((i) => {
                return {
                    booking_id: i?.booking_id,
                    amenity: i?.amenities_name,
                    booking_from: moment(i?.booking_start_time).format("DD MMM YY"),
                    booking_to: moment(i?.booking_end_time).format("DD MMM YY"),
                    source: i?.source ?? "-",
                    time_range: i?.period === "Daily" ?
                        i?.booking_start_time + " - " + i?.booking_end_time
                        :
                        addSecondFormat(i?.booked_slots?.[0]?.[0], 0, "HH:mm") + " - " +
                        addSecondFormat(i?.booked_slots?.[i?.booked_slots?.length - 1]?.[1], 1, "HH:mm"),
                    booked_by: i?.booked_by,
                    payment_status: i?.payment_status,
                    slot_status: i?.booking_status,
                    data: i
                }
            })
            setAmenityBooking({ list, totalCount })
        }).catch((error) => {
            if (error?.response?.data?.error?.code === 406) {
                // Request made and server responded
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.info,
                    msg: error?.response?.data?.error?.message ?? t("Try Again"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            } else {
                alert.setSnack({
                    ...alert, open: true, severity: AlertProps.severity.error,
                    msg: AlertProps.message.some_thing_went_wrong,
                })
            }
        })
    }

    // calander view data
    const getAmenityBookingCalandarList = (amenity = selectedAmenity, date_range, company, type) => {
        const customRange = [date_range?.[0], `${moment(new Date(date_range?.[1])).format("YYYY-MM-DD")} 00:00:00`]
        let payload = {
            property_facility_id: amenity?.value,
            date_range: customRange,
            company: company?.value,
            current_date: format(new Date(), "yyyy-MM-dd HH:mm:ss")
        }
        let weeklyList = []
        let dailyList = []
        let monthlyList = []
        if (type === "Monthly") {
            NetworkCall(
                `${config.api_url}/amenities_booking_v2/get_monthly_booking`,
                NetWorkCallMethods.post,
                payload, null, true, false
            ).then((res) => {
                monthlyList.push(...res?.data?.data)
                setCalendarData({ weeklyList, dailyList, monthlyList: res?.data?.data })
            }).catch((error) => {
                if (error?.response?.data?.error?.code === 406) {
                    // Request made and server responded
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.info,
                        msg: error?.response?.data?.error?.message ?? t("Try Again"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });
                } else {
                    alert.setSnack({
                        ...alert, open: true, severity: AlertProps.severity.error,
                        msg: AlertProps.message.some_thing_went_wrong,
                    })
                }
            })
        } else if (type === "Daily") {
            NetworkCall(
                `${config.api_url}/amenities_booking_v2/get_daily_booking`,
                NetWorkCallMethods.post,
                payload, null, true, false
            ).then((res) => {
                dailyList.push(...res?.data?.data)
                setCalendarData({ weeklyList, dailyList: res?.data?.data, monthlyList })
            }).catch((error) => {
                if (error?.response?.data?.error?.code === 406) {
                    // Request made and server responded
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.info,
                        msg: error?.response?.data?.error?.message ?? t("Try Again"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });
                } else {
                    alert.setSnack({
                        ...alert, open: true, severity: AlertProps.severity.error,
                        msg: AlertProps.message.some_thing_went_wrong,
                    })
                }
            })
        } else if (type === "Weekly") {
            NetworkCall(
                `${config.api_url}/amenities_booking_v2/get_weekly_booking`,
                NetWorkCallMethods.post,
                payload, null, true, false
            ).then((res) => {

                weeklyList.push(...res?.data?.data)
                setCalendarData({ weeklyList, dailyList, monthlyList: res?.data?.data })
            }).catch((error) => {
                if (error?.response?.data?.error?.code === 406) {
                    // Request made and server responded
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.info,
                        msg: error?.response?.data?.error?.message ?? t("Try Again"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });
                } else {
                    alert.setSnack({
                        ...alert, open: true, severity: AlertProps.severity.error,
                        msg: AlertProps.message.some_thing_went_wrong,
                    })
                }
            })
        }
    }

    // Function to change search text
    const handleSearchTextChange = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    // Function to search data in amenity booking
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }

        if (dateRange.length > 0) {
            getAmenityBookingList(
                amenities?.selectedAmenityDescription, dateRange,
                selectedCompany, e, 0, limit
            )
            getAmenityBookingCalandarList(
                amenities?.selectedAmenityDescription, dateRange,
                selectedCompany, selected
            )
        }
    }

    // Function to handle pagination in table
    const handlePagination = (e) => {
        setPage(e)
        let offset = (e - 1) * limit

        if (dateRange.length > 0) {
            getAmenityBookingList(
                amenities?.selectedAmenityDescription, dateRange,
                selectedCompany, searchText, offset, limit
            )
            getAmenityBookingCalandarList(
                amenities?.selectedAmenityDescription, dateRange,
                selectedCompany, selected
            )
        }
    }

    // Function to handle page limit in table
    const handleChangeLimit = (e) => {
        setLimit(e)
        setPage(1)

        if (dateRange.length > 0) {
            getAmenityBookingList(
                amenities?.selectedAmenityDescription, dateRange,
                selectedCompany, searchText, 0, e
            )
            getAmenityBookingCalandarList(
                amenities?.selectedAmenityDescription, dateRange,
                selectedCompany, selected
            )
        }
    }

    const closeCheckAvailability = () => {
        setOpenCA(!openCA)
    }


    // check availability functions

    const getViewAmenities = (date) => {
        if (!data?.is_back) {
            const data = {
                property_id: selectedProperty?.value,
                amenity_category_id: amenities?.selectedAmenityCategory?.value,
                selected_date: `${moment(date).format("YYYY-MM-DD")} 00:00:00`,
                current_date: format(new Date(), "yyyy-MM-dd HH:mm:ss")
            }
            NetworkCall(
                `${config.api_url}/amenities_booking_v2/get_amenities_for_category`,
                NetWorkCallMethods.post,
                data,
                null,
                true,
                false
            )
                .then((response) => {
                    setData({
                        ...data,
                        venue: response?.data?.data?.amenityByDate,
                        selectedVenue: {},
                        selectedDate: date,
                        selected_slot: []
                    })
                })
                .catch((error) => {
                    if (error?.response?.data?.error?.code === 406) {
                        // Request made and server responded
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.info,
                            msg: error?.response?.data?.error?.message ?? t("Try Again"),
                            vertical: AlertProps.vertical.top,
                            horizontal: AlertProps.horizontal.center,
                        });
                    } else {
                        alert.setSnack({
                            ...alert, open: true, severity: AlertProps.severity.error,
                            msg: AlertProps.message.some_thing_went_wrong,
                        })
                    }
                });
        }
    };

    const getAmenitiesSelectedDate = (date) => {
        if (moment(date).format("YYYY-MM-DD") >= moment(new Date()).format("YYYY-MM-DD")) {
            getViewAmenities(date)
        } else {
            setData({
                ...data,
                selected_slot: []
            })
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("You cannot book for past dates"),
            });
        }
    }

    // Get amenities Booking 
    const getAmenitiesBooking = (value) => {
        const payload = {
            property_id: selectedProperty?.value,
            facility_id: value?.facility_id,
            amenities_type: value?.amenities_type,
            selected_date: moment(data?.selectedDate).format("YYYY-MM-DD"),
            period: value?.period,
            current_date: format(new Date(), "yyyy-MM-dd HH:mm:ss")
        };
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/get_amenities_for_booking`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                let constAvailableDates = response?.data?.data?.available_slots?.map((e) => {
                    // const add = new Date(moment(data?.selected_Date).format("YYYY-MM-DD") + " " + e?.[1])
                    return response?.data?.data?.amenity?.[0]?.period === "Hourly" ?
                        {
                            "check_in_time": e?.[0],
                            "check_out_time": `${parseInt(e?.[1]?.split(":")?.[0]) + 1}:00:00`?.padStart(8, '0'),
                            "check_in_value": e?.[0],
                            "check_out_value": e?.[1],
                            "info": e?.[2],
                            "rowId": uuidv4(),
                            "extraInvoice": false
                        } :
                        {
                            "check_in_date": e?.date,
                            "check_out_date": e?.date,
                            ...e,
                            "rowId": uuidv4(),
                            "extraInvoice": false,
                            "info": {
                                "rate": response?.data?.data?.amenity?.[0].rate
                            }
                        }
                })
                setData({
                    ...data,
                    availableSlot: constAvailableDates ?? [],
                    selectedVenue: value,
                    slotIndex: null,
                    selected_slot: []
                })
            })
            .catch((error) => {
                if (error?.response?.data?.error?.code === 406) {
                    // Request made and server responded
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.info,
                        msg: error?.response?.data?.error?.message ?? t("Try Again"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });
                } else {
                    alert.setSnack({
                        ...alert, open: true, severity: AlertProps.severity.error,
                        msg: AlertProps.message.some_thing_went_wrong,
                    })
                }
            });
    };

    //  select slots
    const selectSlot = (value, index) => {
        let selectedIndex = data?.selected_slot?.map(e => {
            return e.index
        })

        if (selectedIndex?.includes(index)) {
            setData({
                ...data,
                selected_slot: [],
                slotIndex: null
            })
        }
        else {
            if (data?.selected_slot?.length === 0) {
                setData({
                    ...data,
                    selected_slot: [{ value, index }],
                    slotIndex: index
                })
            } else {
                if (value?.check_in_date) {
                    if (moment(subDays(new Date(data?.selected_slot?.[0]?.value?.check_out_date), 1)).format("YYYY-MM-DD") === moment(value?.check_in_date).format("YYYY-MM-DD")) {

                        setData({
                            ...data,
                            selected_slot: [{ value, index }, ...data?.selected_slot],
                            slotIndex: index
                        })
                    }
                    else if (moment(addDays(new Date(data?.selected_slot[data?.selected_slot?.length - 1]?.value?.check_out_date), 1)).format("YYYY-MM-DD") === moment(value?.check_in_date).format("YYYY-MM-DD")) {

                        setData({
                            ...data,
                            selected_slot: [...data?.selected_slot, { value, index }],
                            slotIndex: index
                        })
                    }
                    else {
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: t("Only consecutive slots can be booked"),
                        });
                    }
                }
                else {
                    if ((Number((data?.selected_slot?.[0]?.value?.check_in_time)?.slice(0, 2))) === (Number((value?.check_out_time)?.slice(0, 2)))) {

                        setData({
                            ...data,
                            selected_slot: [{ value, index }, ...data?.selected_slot],
                            slotIndex: index
                        })
                    } else if ((Number((data?.selected_slot[data?.selected_slot?.length - 1]?.value?.check_out_time)?.slice(0, 2))) === Number((value?.check_in_time)?.slice(0, 2))) {

                        setData({
                            ...data,
                            selected_slot: [...data?.selected_slot, { value, index }],
                            slotIndex: index
                        })
                    }
                    else {
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: t("Only consecutive slots can be booked"),
                        });
                    }
                }
            }
        }
    }

    React.useEffect(() => {
        const monthStart = startOfMonth(month);
        const monthEnd = endOfMonth(monthStart);
        const monthstartDate = startOfWeek(monthStart);
        const monthendDate = endOfWeek(monthEnd);
        let finaldays = getdateRange(monthstartDate, monthendDate)
        setFinalDays(finaldays)
        // eslint-disable-next-line
    }, [])

    //dateRange
    const getdateRange = (startDate, endDate) => {
        const date = new Date(startDate.getTime());

        const dates = [];

        while (date <= endDate) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }

        return dates;
    }

    const goBack = (type = "") => {
        setStep("step1")
        setData({
            venue: [],
            selectedVenue: {},
            selectedDate: new Date(),
            availableSlot: [],
            selected_slot: [],
            slotIndex: null,
            property_id: "",
            is_back: false
        })
        setSelectedUnit("")
        setSelectedExistingContact("")
        setBookWalkin(false)
        setContactDetail({
            name: "",
            mobileNo: "",
            mailId: "",
            account: "",
            error: {
                name: "",
                mobileNo: "",
                mailId: ""
            }
        })
        if (type === "success") {
            if (dateRange?.length > 0) {
                getAmenityBookingList(
                    amenities?.selectedAmenityDescription, dateRange,
                    selectedCompany, searchText, 0, limit
                )
                getAmenityBookingCalandarList(
                    amenities?.selectedAmenityDescription, dateRange,
                    selectedCompany, selected
                )
            }
        }
    }

    // validate existing contact details

    const contactValidate = () => {
        let isValid = true
        let error = contactDetail.error
        if (contactDetail?.name?.length === 0) {
            isValid = false
            error.name = t("Name is Required")
        }
        if (contactDetail?.mobileNo?.length === 0) {
            isValid = false
            error.mobileNo = t("Mobile No is Required")
        }
        if (contactDetail?.mailId?.length === 0) {
            isValid = false
            error.mailId = t("Email Id is Required")
        }
        setContactDetail({ ...contactDetail, error: error })
        return isValid
    }

    // const amountCalculationee = (newInvoice) => {
    //     let total = 0
    //     // eslint-disable-next-line
    //     if (data?.selectedVenue?.period === "Hourly" && !newInvoice) {
    //         // eslint-disable-next-line
    //         data?.selected_slot?.map((e) => {
    //             total = total + (Number(e?.value?.info?.rate) * Number(member?.count ?? 1))
    //         })
    //     } else if (newInvoice) {
    //         // eslint-disable-next-line
    //         data?.selected_slot?.map((e) => {
    //             if (e?.value?.extraInvoice) {
    //                 total = total + (Number(e?.value?.info?.rate) * Number(e?.value?.qty))
    //             }
    //             else {
    //                 if (data?.selectedVenue?.period === "Hourly") {
    //                     total = total + (Number(e?.value?.info?.rate) * Number(member?.count ?? 1))
    //                 } else {
    //                     total = total + data?.selectedVenue?.rate
    //                 }
    //             }
    //         })

    //     } else {
    //         total = data?.selectedVenue?.rate * data?.selected_slot?.length
    //     }
    //     let taxAmt = data?.selectedVenue?.is_taxable ? data?.selectedVenue?.vat_group_master?.vat_item?.map(v => v?.rate)?.reduce((a, b) => a + b, 0) : 0
    //     console.log("total", total)
    //     if (data?.selectedVenue?.is_overbooking) {
    //         setAmounts({
    //             ...amounts,
    //             totalAmount: Number(Number((total * (taxAmt / 100)) + total)).toFixed(2),
    //             taxAmount: newInvoice ? amounts?.taxAmount : (Number(Number(total)) * (taxAmt / 100)).toFixed(2),
    //             invoiceAmount: Number(total).toFixed(2)
    //         })
    //     } else {
    //         setAmounts({
    //             ...amounts,
    //             totalAmount: Number((total * (taxAmt / 100)) + total).toFixed(2),
    //             taxAmount: newInvoice ? amounts?.taxAmount : Number(total) * (taxAmt / 100).toFixed(2),
    //             invoiceAmount: Number(total).toFixed(2)
    //         })
    //     }

    // }

    const addInvoiceLine = (newInvoice) => {
        const construtedSlot = [...data?.selected_slot,
        {
            index: data?.selected_slot?.length,
            value: {
                label: "",
                qty: 1,
                rowId: uuidv4(),
                extraInvoice: true,
                info: {
                    rate: 0
                }
            }
        }
        ]
        setData({
            ...data,
            selected_slot: construtedSlot
        })
        amountCalculation(newInvoice)
        // totalAmountCalculation(newInvoice)
    }

    const removeInvoice = (item) => {
        setData({
            ...data,
            selected_slot: data?.selected_slot?.filter((val) => val?.value?.rowId !== item?.value?.rowId)
        })
        const slot = data?.selected_slot?.filter((val) => val?.value?.rowId !== item?.value?.rowId)
        amountCalculation(true, slot, true)
    }

    const setInvoiceRow = (item, type, value) => {
        if (item?.index >= 0 && item?.index < data?.selected_slot?.length) {
            let arritem = data?.selected_slot?.[item?.index];
            arritem.index = item?.index
            if (type === "label") {
                arritem.value.label = value;
            } else if (type === "qty") {
                arritem.value.qty = value
            } else if (type === "rate") {
                arritem.value.info.rate = value
            }

            setData({ ...data, selected_slot: data?.selected_slot })
        }
    }


    const amountCalculation = (newInvoice, slot, remove) => {
        let selectedSlot = remove ? slot : data?.selected_slot
        // let total = 0
        let taxAmt = data?.selectedVenue?.is_taxable ? data?.selectedVenue?.vat_group_master?.vat_item?.map(v => v?.rate)?.reduce((a, b) => a + b, 0) : 0

        const normalAmount = selectedSlot?.filter((item) => item?.value?.extraInvoice === false)?.map((e) => e?.value?.info?.rate)?.reduce((startRate, endRate) => Number(startRate ?? 0) + Number(endRate ?? 0), 0) * Number((data?.selectedVenue?.period === "Hourly" && data?.selectedVenue?.is_overbooking) ? member?.count : 1)

        const invoiceAmount = selectedSlot?.filter((item) => item?.value?.extraInvoice === true)?.map((e) => Number(e?.value?.info?.rate) * Number(e?.value?.qty ?? 1))?.reduce((startRate, endRate) => Number(startRate ?? 0) + Number(endRate ?? 0), 0)

        const total_amount = newInvoice ?
            Number(Number(Number((normalAmount * (taxAmt / 100)) + normalAmount)) + Number(invoiceAmount)).toFixed(2) :
            data?.selectedVenue?.is_taxable ? Number(Number(Number((normalAmount * (taxAmt / 100)) + normalAmount)) + Number(invoiceAmount)).toFixed(2) :
                Number(Number(normalAmount) + Number(invoiceAmount))?.toFixed(2)
        const taxable_amount = data?.selectedVenue?.is_taxable ? Number(normalAmount * (taxAmt / 100)).toFixed(2) : 0.00
        setAmounts({
            ...amounts,
            totalAmount: total_amount,
            taxAmount: taxable_amount,
            invoiceAmount: Number(normalAmount ?? 0 + invoiceAmount ?? 0).toFixed(2)
        })
    }

    return (
        <AmenitiesBookingContext.Provider value={{
            title,
            openPopup,
            handleTableIcon,
            getBookingDetail,
            handleEvent,
            handleMonthEvent,
            closeDailyCalendarPopup,
            getCompany,
            handleCompanyChange,
            getPropertyList,
            handlePropertyChange,
            getAmenityCategories,
            handleAmenityCategoryChange,
            getAmenitiesForAmenityCategory,
            handleAmenityMasterChange,
            handleAmenityViewChange,
            getDateRange,
            updateAmenityBookingList,
            getAmenityBookingList,
            getAmenityBookingCalandarList,
            handleSearchTextChange,
            handlePagination,
            handleChangeLimit,
            closeCheckAvailability,
            getViewAmenities,
            getAmenitiesSelectedDate,
            getAmenitiesBooking,
            selectSlot,
            getdateRange,
            goBack,
            handleDescriptionChange,
            contactValidate,
            addInvoiceLine,
            amountCalculation,
            removeInvoice,
            decoded,
            openCA,
            openDecline,
            companyList,
            selectedCompany,
            propertyList,
            selectedProperty,
            setInvoiceRow,
            // amenityCategoryList,
            // selectedAmenityCategory,
            amenityList,
            selectedAmenity,
            selectedAmenityView,
            searchText,
            amenityBooking,
            page,
            limit,
            amenityBookingDetail,
            dateRange,
            calendarData,
            calendarEvent,
            openDailyCalendar,
            weekdates,
            selected,
            initialLoad,
            step,
            slotToggle,
            slotType,
            data,
            contactDetail,
            bookWalkin,
            finalDays,
            month,
            booked,
            selectedUnit,
            selectedDescrption,
            amenities,
            selectedExistingContact,
            member,
            amounts,
            setAmounts,
            setMember,
            setContactDetail,
            setSlotType,
            setWeekdates,
            setSelected,
            setInitialLoad,
            setOpenDecline,
            setStep,
            setFinalDays,
            setBookWalkin,
            setMonth,
            setData,
            setBooked,
            setSelectedUnit,
            setSelectedDescrption,
            setAmenities,
            setSelectedExistingContact,
            t,
            fromType
        }}>
            {props.children}
        </AmenitiesBookingContext.Provider>
    )
}

export default withTranslation("amenityBooking")(AmenitiesBookingContextProvider)