import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AmenitiesMappingPropertyView from "../screens/amenitiesMapping/amenitiesMappingPropertyView";
import AmenitiesMappingUnitView from "../screens/amenitiesMapping/amenitiesMappingUnitView";
// import CreateAnnounsment from "../screens/announsment/createannounsment";

import {
  Communication,
  CommunityDiscussion,
} from "../screens/communityManager";
import { DbTask } from "../screens/dbTask/dbTask";
import CreateGeneralRequest from "../screens/genralrequest/createrequest";
import InsPropertyDetail from "../screens/inspectionItem/propertyDetails";
import MapPropertyDetails from "../screens/inspectionMapping/propertyDetails";
import MapUnitDetails from "../screens/inspectionMapping/unitDetails";
import InventoryMaster from "../screens/inventoryMaster";
import CreateMaintanceRequest from "../screens/maintenancerequest/createrequest";
import ManagementCommittee from "../screens/managementcommittee";
import ManagementCommitteeView from "../screens/managementcommittee/managementcommitteeview";
import {
  DeliveryOrderCollection,
  DomesticHelpRequest,
  ParkingSlot,
  ParkingSlotMaster,
  ServiceProvider,
  VendorEntry,
  VisitorAndWorkedGatePass,
} from "../screens/securityManager";
import ViewServiceInspection from "../screens/serviceInspection/components/viewInspection";
import ViewSiteVisitDetails from "../screens/siteVisit/components/viewSiteVisitDetails";
import UtilitiesMappingPropertyView from "../screens/utilitiesMapping/utilitiesMappingPropertyView";
import UtilitiesMappingUnitView from "../screens/utilitiesMapping/utilitiesMappingUnitView";
import {
  AccessGates,
  AccessGatesPropertyView,
  AccountDetails,
  Accounts,
  ActivityBoard,
  ActivityCategory,
  ActivitySubCategory,
  AggreementsList,
  AgreementDetails,
  AgreementRenewal,
  AgreementTermination,
  AgreementUnitAccess,
  AlreadyPaid,
  Amenities,
  AmenitiesBooking,
  AmenitiesMapping,
  AmenityBoard,
  AmenityCategory,
  AmenityDashBoard,
  AmenityMappingNew,
  AnnouncementBoard,
  AnnounsmentNew,
  BackupRestore,
  BlockDetails,
  BlockList,
  BlockView,
  COAMaster,
  CheckListGroup,
  CheckListGroupView,
  CheckListItemView,
  ClientManagerDashboard,
  ClientSettings,
  CommunityDashboard,
  Communitybanner,
  Company,
  CompanyCreation,
  ConfigurartionNew,
  ConfigurationMaster,
  Configure,
  ContactView,
  Contacts,
  Contract,
  Contract360,
  ContractManagerDashboard,
  CorrespondenceType,
  Correspondences,
  CostingApproval,
  CreateAccount,
  CreateAnnounsmentNew,
  CreateBlock,
  CreateContactNew,
  CreateDeliveryMilestoneTemplate,
  CreateEditContract,
  CreateFixedMaintenancePlanner,
  CreateFloor,
  CreateInvoiceFreeText,
  CreateOpportunity,
  CreateQuotation,
  CreateReceipt,
  CreateUnit,
  CustomerAccountDetails,
  CustomerAccountList,
  CustomerFeedBack,
  CustomerGroupMaster,
  CustomerViewNew,
  Dashboardbanner,
  DeliveryMilestone,
  DeliveryMilestoneTemplate,
  DeliveryWorkOrder,
  Department,
  DomesticContacts,
  DomesticHelperCreateEdit,
  DomesticHelperList,
  DomesticHelperView,
  DutyRoster,
  EmergencyContact,
  EmployeesBooking,
  ExpenseGroupMaster,
  ExploreTemplate,
  FacilityManagerDashboard,
  FeedBackCategoryMaster,
  FeedBackSubCategoryMaster,
  FileManager,
  FloorDetails,
  FloorList,
  ForgotPassword,
  FormBuilder,
  FreeTextInvoice,
  FunctionalLocation,
  GeneralInspection,
  GeneralRequest,
  Import,
  ImportFileUpload,
  LeadImportFileUploadWithNavBars,
  InspectionItems,
  InspectionList,
  InspectionManagerDashboard,
  InspectionMapping,
  InspectionMaster,
  InspectionReq,
  InternalLeadSource,
  InviteTrigger,
  Invoice,
  InvoiceView,
  ItemCategoryMaster,
  ItemSubCategoryMaster,
  Jobs,
  KycDetails,
  KycDocumentType,
  KycScreen,
  LeadNew,
  LeadView,
  Leads,
  LeaseAndSalesManagerDashboard,
  LeaveMaster,
  LedgerMaster,
  ListingDashBoard,
  LogoApperances,
  MaintanceRequest,
  MaintenancePlanner,
  ManageInvoice,
  ManagementCommitteRoleMaster,
  ManagementRoleMaster,
  ManufacturerMaster,
  MarketingPortalConfiguration,
  Masters,
  MoveoutInspection,
  MyOrg,
  NotFound,
  Occupants,
  OperationsManagerDashboard,
  OppertunityView,
  Otp,
  OwnerAccountCreate,
  OwnerAccountDetails,
  OwnerAccountList,
  OwnerTableView,
  OwnerViewNew,
  ParkingArea,
  ParkingBooking,
  ParkingGroupingMaster,
  PricingAppraisal,
  PricingComponent,
  PricingTable,
  PricingTablePropertyView,
  PricingTableUnitView,
  PricingTableViewAll,
  Profession,
  ProfileEdit,
  ProfileView,
  Properties,
  PropertyBoard,
  PropertyCreation,
  PropertyManagerDashboard,
  PropertyDetails,
  PropertyFinder4,
  PropertyFinderDetails,
  PropertyView,
  Propspect,
  PublicListing,
  QuotationDetails,
  QuotationsList,
  Receipt,
  ReceiptView,
  RentalIndex,
  Report,
  Reports,
  ReqTable,
  Request,
  RequestView,
  Reservation,
  ReservationDetails,
  ResetPassword,
  ResetYourPassword,
  ResidentOnboarding,
  ResidentTableView,
  ResourceBoard,
  ResourceBooking,
  ResourceManagerDashboard,
  ResourceMaster,
  SalesAgentCreateNew,
  SalesAgentViewNew,
  SalesManagerDashboard,
  ScheduleBoard,
  ServiceHubDashboard,
  ServiceInspection,
  ServiceProviderCreateEdit,
  ServiceProviderView,
  ServiceProviderViewNew,
  SetPassword,
  Settlements,
  ShortList,
  ShortTermPricing,
  ShortTermPricingPropertyView,
  ShortTermPricingUnitView,
  SiteVisit,
  StatementOfAccount,
  SubLedgerMaster,
  Subscriptions,
  Teams,
  TimeOffRequest,
  TimeOffResources,
  ToolsMaster,
  UnitBlockingList,
  UnitDetails,
  UnitHandOver,
  UnitItemLocationMaster,
  UnitList,
  UnitReadiness,
  UnitTable,
  UnitTableDetails,
  UnitVacancy,
  UnitVacancyAllList,
  UnitVacancyDetails,
  UnitVacancyPropertyList,
  UnitVacancyPropertyView,
  UploadApi,
  UserManageMent,
  UtilitiesMapping,
  UtilityManagerDashboard,
  UtilityReadings,
  VehicleMaster,
  VendorAccountCreate,
  VendorAccountDetail,
  VendorAccountList,
  VendorCreation,
  VendorGroupMaster,
  VendorViewNew,
  VisitorandSecurityDashboard,
  Welcome,
  WelcomePanner,
  WorkingHoursMaster,
  DynamicMaintenancePlanner,
  UtilitiesCategory,
  UtilityMaster,
  CustomerAccountCreate,
  CashBankAccountList,
  CashBankAccountCreate,
  CashBankAccountDetails,
  MarketPlace,
  SurveyForm,
  SelfCheckInEntries,
  WorkspaceManagerDashboard,
  SchedulePlanView,
  ScheduleWorkOrderView,
  ExecutedJobsView,
  ExecutedWorkOrdersView,
  Project,
  ResourceGroupMaster,
  ProjectCategory,
  PaymentDashboard,
  Login,
  ExternalUnits,
  ApprovalWorkflow,
} from "./../screens";
import AnnounsmentDetails from "./../screens/announsmentNew/announsmentDetails";
import SalesDetails from "./../screens/salesagentview";
// import SalesAgentList from "./../screens/salesagentview/saleagenttabel";
// import SalesCreation from "./../screens/salseagent";
import CheckAvailability from "../screens/amenitiesBooking/checkAvailability";
import CorrespondencesView from "../screens/correspondences/correspondencesView";
import CreateEditCorrespondences from "../screens/createEditCorrespondences";
import ViewDeliveryOrderDetails from "../screens/deliveryWorkOrders/components/viewDeliveryOrderDetails";
import FinalSettlement from "../screens/finalSettlement";
import FinalSettlementView from "../screens/finalSettlement/finalSettlementView";
import CreateFeedBackForm from "../screens/formBuilder/components/createform";
import FunctionalHeirarchy from "../screens/functionalLocation/functionalHeirarchy";
import ViewGeneralInspection from "../screens/generalInspection/components/viewInspection";
import KycDetailView from "../screens/kycDetails/kycDetailView";
import ShareUnit from "../screens/propertyBoard/components/shareUnitCard";
import ResidentOnboardingViewEdit from "../screens/residentOnboarding/residentOnboardingViewEdit";
import RestoreErrorHandling from "../screens/restoreErrorHandling";
import ViewUnitReadinessDetails from "../screens/unitReadiness/components/viewUnitReadiness";
import Shortlistedlead from "./../screens/shortlistlead";
import PrivateRouter from "./privateRouter";
import CustomizeQuote from "../screens/customizeQUote/customizeQuote";
import ViewMaintenancePlanner from "../screens/viewMaintenancePlanner";
import MarketPlaceView from "../screens/marketingPlace/marketPlaceView";
import CreateSurveyForm from "../screens/surveryForm/components/createform";
import VideoRecorder from "../screens/videoRecorder";
import QueuedInvoice from "../screens/queuedInvoice";
import ProxyInvoice from "../screens/proxyInvoice";
import SaleAgreementsList from "../screens/saleAgreement";
import LeaseAgreementsList  from "../screens/leaseAgreement";
import ManageAgreementsList from "../screens/manageAgreement";
import { Routes as AppRoutes } from "./routes";
const RouterApp = (props) => {
  return (
    <Router>
      <Routes>
        {/* property creation */}
        <Route
          path={AppRoutes.propertycreation}
          element={
            <PrivateRouter>
              <PropertyCreation />
            </PrivateRouter>
          }
        />
        {/* Create Block New */}
        <Route
          path={AppRoutes.createBlockNew}
          element={
            <PrivateRouter>
              <CreateBlock />
            </PrivateRouter>
          }
        />
        {/* Create Floor New */}
        <Route
          path={AppRoutes.createFloorNew}
          element={
            <PrivateRouter>
              <CreateFloor />
            </PrivateRouter>
          }
        />
        {/* Create Unit New */}
        <Route
          path={AppRoutes.createUnitNew}
          element={
            <PrivateRouter>
              <CreateUnit />
            </PrivateRouter>
          }
        />
        {/* property creation */}
        <Route
          path={AppRoutes.propertyview}
          element={
            <PrivateRouter>
              <PropertyView />
            </PrivateRouter>
          }
        />
        {/* block view */}
        <Route
          path={AppRoutes.blockView}
          element={
            <PrivateRouter>
              <BlockView />
            </PrivateRouter>
          }
        />
        {/* Login Route */}
        <Route path={AppRoutes.login} element={<Login />} />
        {/* otp */}
        <Route path={AppRoutes.otp} element={<Otp />} />
        {/* Master */}
        {/* <Route path={AppRoutes.propertyMaster} element={<PrivateRouter><PropertyMaster /></PrivateRouter>} />
        <Route path={AppRoutes.businessMaster} element={<PrivateRouter><BusinessMaster /></PrivateRouter>} />
        <Route path={AppRoutes.servicesMaster} element={<PrivateRouter><ServiceMaster /></PrivateRouter>} /> */}
        {/* forgotPassword */}
        <Route path={AppRoutes.forgotPassword} element={<ForgotPassword />} />
        {/* Properties */}
        <Route
          path={AppRoutes.properties}
          element={
            <PrivateRouter>
              <Properties />
            </PrivateRouter>
          }
        />
        {/*  Property Details */}
        <Route
          path={AppRoutes.propertyDetails}
          element={
            <PrivateRouter>
              <PropertyDetails />
            </PrivateRouter>
          }
        />
        {/*  Block Details */}
        <Route
          path={AppRoutes.blockDetails}
          element={
            <PrivateRouter>
              <BlockDetails />
            </PrivateRouter>
          }
        />
        {/*  Floor Details */}
        <Route
          path={AppRoutes.floorDetails}
          element={
            <PrivateRouter>
              <FloorDetails />
            </PrivateRouter>
          }
        />
        {/*  PropertyFinder */}
        <Route
          path={AppRoutes.propertyFinder}
          element={
            <PrivateRouter>
              <PropertyFinder4 />
            </PrivateRouter>
          }
        />
        {/*  PropertyFinder Details */}
        <Route
          path={AppRoutes.propertyFinderDetails}
          element={
            <PrivateRouter>
              <PropertyFinderDetails />
            </PrivateRouter>
          }
        />
        {/*  shortlisted units */}
        <Route
          path={AppRoutes.shortlisted}
          element={
            <PrivateRouter>
              <ShortList />
            </PrivateRouter>
          }
        />
        {/* create CreateQuotation Route */}
        <Route
          path={AppRoutes.createQuotation}
          element={
            <PrivateRouter>
              <CreateQuotation />
            </PrivateRouter>
          }
        />
        {/* Leads Route */}
        <Route
          path={AppRoutes.leads}
          element={
            <PrivateRouter>
              <Leads />
            </PrivateRouter>
          }
        />
        {/* LeadsDetails Route */}
        <Route
          path={AppRoutes.leadsDetails}
          element={
            <PrivateRouter>
              <OppertunityView />
            </PrivateRouter>
          }
        />
        {/* reset password Route */}
        <Route path={AppRoutes.resetPassword} element={<ResetPassword />} />
        {/* Unit Table Route */}
        <Route
          path={AppRoutes.unitTable}
          element={
            <PrivateRouter>
              <UnitTable />
            </PrivateRouter>
          }
        />
        {/* UnitTableDetails Route */}
        <Route
          path={AppRoutes.unitTableDetails}
          element={
            <PrivateRouter>
              <UnitTableDetails />
            </PrivateRouter>
          }
        />
        {/* UploadApi Route */}
        <Route
          path={AppRoutes.upload}
          element={
            <PrivateRouter>
              <UploadApi />
            </PrivateRouter>
          }
        />
        {/* Agreement Unit Access */}
        <Route
          path={AppRoutes.agreementUnitAccess}
          element={
            <PrivateRouter>
              <AgreementUnitAccess />
            </PrivateRouter>
          }
        />
        {/* Resident Table Route */}
        <Route
          path={AppRoutes.residentTable}
          element={
            <PrivateRouter>
              <CustomerViewNew />
            </PrivateRouter>
          }
        />
        {/* Owner Table Route */}
        <Route
          path={AppRoutes.ownerTable}
          element={
            <PrivateRouter>
              <OwnerViewNew />
            </PrivateRouter>
          }
        />
        {/* ResidentTableDetails Route */}
        <Route
          path={AppRoutes.residentTableDetails}
          element={
            <PrivateRouter>
              <ResidentTableView />
            </PrivateRouter>
          }
        />
        {/* OwnerTableDetails Route */}
        <Route
          path={AppRoutes.ownerTableDetails}
          element={
            <PrivateRouter>
              <OwnerTableView />
            </PrivateRouter>
          }
        />
        {/* Pricing Component */}
        <Route
          path={AppRoutes.pricingComponent}
          element={
            <PrivateRouter>
              <PricingComponent />
            </PrivateRouter>
          }
        />
        {/* Pricing Table view */}
        <Route
          path={AppRoutes.pricingTable}
          element={
            <PrivateRouter>
              <PricingTable />
            </PrivateRouter>
          }
        />
        {/* Pricing Table View All */}
        <Route
          path={AppRoutes.pricingTableAll}
          element={
            <PrivateRouter>
              <PricingTableViewAll />
            </PrivateRouter>
          }
        />
        {/* Shortlist Lead */}
        <Route
          path={AppRoutes.shortlistLead}
          element={
            <PrivateRouter>
              <Shortlistedlead />
            </PrivateRouter>
          }
        />
        {/* Pricing Table Property View */}
        <Route
          path={AppRoutes.pricingTablePropertyView}
          element={
            <PrivateRouter>
              <PricingTablePropertyView />
            </PrivateRouter>
          }
        />
        {/* Pricing Table Property View */}
        <Route
          path={AppRoutes.pricingTableUnitView}
          element={
            <PrivateRouter>
              <PricingTableUnitView />
            </PrivateRouter>
          }
        />
        {/* requestAdd Route */}
        <Route
          path={AppRoutes.request}
          element={
            <PrivateRouter>
              <Request />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.quotation}
          element={
            <PrivateRouter>
              <QuotationsList title="Quatation" />
            </PrivateRouter>
          }
        />
        {/* view quotation */}
        <Route
          path={AppRoutes.quotation_view}
          element={
            <PrivateRouter>
              <QuotationDetails title="Quatation" backURL="goBack" />
            </PrivateRouter>
          }
        />
        {/* property search result  */}
        <Route
          path={AppRoutes.propertyResult}
          element={
            <PrivateRouter>
              <PropertyFinder4 />
            </PrivateRouter>
          }
        />
        {/* Utilities  */}
        <Route
          path={AppRoutes.utilities}
          element={
            <PrivateRouter>
              <UtilityMaster />
            </PrivateRouter>
          }
        />
        {/* Utilities Mapping */}
        <Route
          path={AppRoutes.utilitiesMapping}
          element={
            <PrivateRouter>
              <UtilitiesMapping />
            </PrivateRouter>
          }
        />
        {/* Utilities Mapping Property View */}
        <Route
          path={AppRoutes.utilitiesMappingPropertyView}
          element={
            <PrivateRouter>
              <UtilitiesMappingPropertyView />
            </PrivateRouter>
          }
        />
        {/* Utilities Mapping unit View */}
        <Route
          path={AppRoutes.utilitiesMappingUnitView}
          element={
            <PrivateRouter>
              <UtilitiesMappingUnitView />
            </PrivateRouter>
          }
        />
        {/* Amenities  */}
        <Route
          path={AppRoutes.amenities}
          element={
            <PrivateRouter>
              <Amenities />
            </PrivateRouter>
          }
        />
        {/* Amenities Mapping */}
        <Route
          path={AppRoutes.amenitiesMapping}
          element={
            <PrivateRouter>
              <AmenitiesMapping />
            </PrivateRouter>
          }
        />
        {/* Amenities Mapping Property View */}
        <Route
          path={AppRoutes.amenitiesMappingPropertyView}
          element={
            <PrivateRouter>
              <AmenitiesMappingPropertyView />
            </PrivateRouter>
          }
        />
        {/* Amenities Mapping unit View */}
        <Route
          path={AppRoutes.amenitiesMappingUnitView}
          element={
            <PrivateRouter>
              <AmenitiesMappingUnitView />
            </PrivateRouter>
          }
        />
        {/* Property Dashboard */}
        <Route
          path={AppRoutes.dasdboard}
          element={
            <PrivateRouter>
              <PropertyManagerDashboard />
            </PrivateRouter>
          }
        />
        {/* Client Dashboard */}
        <Route
          path={AppRoutes.clientDashboard}
          element={
            <PrivateRouter>
              <ClientManagerDashboard />
            </PrivateRouter>
          }
        />
        {/* Sales Dashboard */}
        <Route
          path={AppRoutes.salesDashboard}
          element={
            <PrivateRouter>
              <SalesManagerDashboard />
            </PrivateRouter>
          }
        />
        {/* Inspection Dashboard */}
        <Route
          path={AppRoutes.inspectionDashboard}
          element={
            <PrivateRouter>
              <InspectionManagerDashboard />
            </PrivateRouter>
          }
        />
        {/* Operations Dashboard */}
        <Route
          path={AppRoutes.operationsDashboard}
          element={
            <PrivateRouter>
              <OperationsManagerDashboard />
            </PrivateRouter>
          }
        />
        {/* Facility Dashboard */}
        <Route
          path={AppRoutes.facilityDashboard}
          element={
            <PrivateRouter>
              <FacilityManagerDashboard />
            </PrivateRouter>
          }
        />
        {/* Finance Dashboard */}
        <Route
          path={AppRoutes.financeDashboard}
          element={
            <PrivateRouter>
              <LeaseAndSalesManagerDashboard />
            </PrivateRouter>
          }
        />
        {/* Contract Dashboard */}
        <Route
          path={AppRoutes.contractDashboard}
          element={
            <PrivateRouter>
              <ContractManagerDashboard />
            </PrivateRouter>
          }
        />
        {/*community manager routes ------------------>*/}
        {/* CommunityCard Route */}
        <Route
          path={AppRoutes.communitycard}
          element={
            <PrivateRouter>
              <CommunityDiscussion />
            </PrivateRouter>
          }
        />
        {/* master Route */}
        {/* <Route path={AppRoutes.master} element={<PrivateRouter><Master /></PrivateRouter>} /> */}
        {/* communication Route */}
        <Route
          path={AppRoutes.communication}
          element={
            <PrivateRouter>
              <Communication />
            </PrivateRouter>
          }
        />
        {/*security manager routes ------------------------> */}
        {/* MasterData Route */}
        {/* <Route path={AppRoutes.masterData} element={<PrivateRouter><MasterData /></PrivateRouter>} /> */}
        {/* Parking Slot Route */}
        <Route
          path={AppRoutes.parkingSlot}
          element={
            <PrivateRouter>
              <ParkingSlot />
            </PrivateRouter>
          }
        />
        {/* Parking Slot master Route */}
        <Route
          path={AppRoutes.parkingSlotMaster}
          element={
            <PrivateRouter>
              <ParkingSlotMaster />
            </PrivateRouter>
          }
        />
        {/* Visitor And Worked Gate Pass Route */}
        <Route
          path={AppRoutes.visitorAndWorkedGatePass}
          element={
            <PrivateRouter>
              <VisitorAndWorkedGatePass />
            </PrivateRouter>
          }
        />
        {/* Delivery Order Collection Route */}
        <Route
          path={AppRoutes.deliveryOrderCollection}
          element={
            <PrivateRouter>
              <DeliveryOrderCollection />
            </PrivateRouter>
          }
        />
        {/* Domestic Help Request Route */}
        <Route
          path={AppRoutes.domesticHelpRequest}
          element={
            <PrivateRouter>
              <DomesticHelpRequest />
            </PrivateRouter>
          }
        />
        {/* Vendor Entry Route */}
        <Route
          path={AppRoutes.vendorEntry}
          element={
            <PrivateRouter>
              <VendorEntry />
            </PrivateRouter>
          }
        />
        {/* Service Provider Route */}
        <Route
          path={AppRoutes.serviceProvider}
          element={
            <PrivateRouter>
              <ServiceProvider />
            </PrivateRouter>
          }
        />
        {/* announsmwnt */}
        <Route
          path={AppRoutes.announcement}
          element={
            <PrivateRouter>
              <AnnounsmentNew />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.createannounsment}
          element={
            <PrivateRouter>
              <CreateAnnounsmentNew />
            </PrivateRouter>
          }
        />
        {/* Configuraiton Listing */}
        <Route
          path={AppRoutes.configuration}
          element={
            <PrivateRouter>
              <ConfigurartionNew title="Configuration" />
            </PrivateRouter>
          }
        />
        {/* Configuraiton details */}
        <Route
          path={AppRoutes.configuration_details}
          element={
            <PrivateRouter>
              <ConfigurationMaster title="Configuration" backURL="goBack" />
            </PrivateRouter>
          }
        />
        {/* Configuraiton details */}
        <Route
          path={AppRoutes.configuration_add}
          element={
            <PrivateRouter>
              <ConfigurartionNew />
            </PrivateRouter>
          }
        />
        {/* Block */}
        <Route
          path={AppRoutes.block}
          element={
            <PrivateRouter>
              <BlockList />
            </PrivateRouter>
          }
        />
        {/* fllor */}
        <Route
          path={AppRoutes.floor}
          element={
            <PrivateRouter>
              <FloorList />
            </PrivateRouter>
          }
        />
        {/* unit */}
        <Route
          path={AppRoutes.unit}
          element={
            <PrivateRouter>
              <UnitList />
            </PrivateRouter>
          }
        />
        {/* unitDetails */}
        <Route
          path={AppRoutes.unitDetails}
          element={
            <PrivateRouter>
              <UnitDetails />
            </PrivateRouter>
          }
        />
        {/* floorDetails */}
        <Route
          path={AppRoutes.floorDetails}
          element={
            <PrivateRouter>
              <FloorDetails />
            </PrivateRouter>
          }
        />
        {/* Company Master */}
        <Route
          path={AppRoutes.companyMaster}
          element={
            <PrivateRouter>
              <Masters />
            </PrivateRouter>
          }
        />
        {/* Property Type */}
        <Route
          path={AppRoutes.propertyType}
          element={
            <PrivateRouter>
              <Masters />
            </PrivateRouter>
          }
        />
        {/* Block Type */}
        <Route
          path={AppRoutes.blockType}
          element={
            <PrivateRouter>
              <Masters />
            </PrivateRouter>
          }
        />
        {/* Unit Type */}
        <Route
          path={AppRoutes.unitType}
          element={
            <PrivateRouter>
              <Masters />
            </PrivateRouter>
          }
        />
        {/* Tax Group */}
        <Route
          path={AppRoutes.taxGroup}
          element={
            <PrivateRouter>
              <Masters />
            </PrivateRouter>
          }
        />
        {/* Tax Item */}
        <Route
          path={AppRoutes.taxItem}
          element={
            <PrivateRouter>
              <Masters />
            </PrivateRouter>
          }
        />
        {/* residnettableDetails */}
        <Route
          path={AppRoutes.residentTableDetails}
          element={
            <PrivateRouter>
              <ResidentTableView />
            </PrivateRouter>
          }
        />
        {/* owner tableDetails */}
        <Route
          path={AppRoutes.ownerTableDetails}
          element={
            <PrivateRouter>
              <OwnerTableView />
            </PrivateRouter>
          }
        />
        {/* ManagementCommittee */}
        <Route
          path={AppRoutes.managementcommittee}
          element={
            <PrivateRouter>
              <ManagementCommittee />
            </PrivateRouter>
          }
        />
        {/* managementcommitteeView */}
        <Route
          path={AppRoutes.managementcommitteeView}
          element={
            <PrivateRouter>
              <ManagementCommitteeView />
            </PrivateRouter>
          }
        />
        {/* companyCreation */}
        <Route
          path={AppRoutes.companyCreation}
          element={
            <PrivateRouter>
              <CompanyCreation />
            </PrivateRouter>
          }
        />
        {/* companyDetails */}
        <Route
          path={AppRoutes.editcompany}
          element={
            <PrivateRouter>
              <CompanyCreation />
            </PrivateRouter>
          }
        />
        {/*vendor Listing */}
        <Route
          path={AppRoutes.vendorListing}
          element={
            <PrivateRouter>
              <VendorViewNew />
            </PrivateRouter>
          }
        />
        {/*vendorCreation */}
        <Route
          path={AppRoutes.vendorCreation}
          element={
            <PrivateRouter>
              <VendorCreation />
            </PrivateRouter>
          }
        />
        {/* vendorDetails */}
        <Route
          path={AppRoutes.editVendor}
          element={
            <PrivateRouter>
              <VendorCreation />
            </PrivateRouter>
          }
        />
        {/* configure */}
        <Route
          path={AppRoutes.configure}
          element={
            <PrivateRouter>
              <Configure />
            </PrivateRouter>
          }
        />
        {/* salesagent */}
        <Route
          path={AppRoutes.salesagent}
          element={
            <PrivateRouter>
              <SalesAgentCreateNew />
            </PrivateRouter>
          }
        />
        {/* salesview */}
        <Route
          path={AppRoutes.salesview}
          element={
            <PrivateRouter>
              <SalesDetails />
            </PrivateRouter>
          }
        />
        {/* salesagentlist */}
        <Route
          path={AppRoutes.salesagentlist}
          element={
            <PrivateRouter>
              <SalesAgentViewNew />
            </PrivateRouter>
          }
        />
        {/* Country Master */}
        <Route
          path={AppRoutes.countryMaster}
          element={
            <PrivateRouter>
              <Masters />
            </PrivateRouter>
          }
        />
        {/* Currency Master */}
        <Route
          path={AppRoutes.currencyMaster}
          element={
            <PrivateRouter>
              <Masters />
            </PrivateRouter>
          }
        />
        {/* General Category Master */}
        <Route
          path={AppRoutes.generalCategory}
          element={
            <PrivateRouter>
              <Masters />
            </PrivateRouter>
          }
        />
        {/* General Sub-Category Master */}
        <Route
          path={AppRoutes.generalSubCategory}
          element={
            <PrivateRouter>
              <Masters />
            </PrivateRouter>
          }
        />
        {/* Maintenance Category Master */}
        <Route
          path={AppRoutes.maintenanceCategory}
          element={
            <PrivateRouter>
              <Masters />
            </PrivateRouter>
          }
        />
        {/* Maintenance Sub-Category Master */}
        <Route
          path={AppRoutes.maintenanceSubCategory}
          element={
            <PrivateRouter>
              <Masters />
            </PrivateRouter>
          }
        />
        {/* LeadNew */}
        <Route
          path={AppRoutes.leadnew}
          element={
            <PrivateRouter>
              <LeadNew />
            </PrivateRouter>
          }
        />
        {/* Contact List */}
        {/* <PrivateRouter exact path={AppRoutes.contactsList}>
          <ContactList  />
        </PrivateRouter> */}
        {/* contactsList */}
        <Route
          path={AppRoutes.contactsList}
          element={
            <PrivateRouter>
              <Contacts />
            </PrivateRouter>
          }
        />
        {/* Create Contact */}
        <Route
          path={AppRoutes.createContact}
          element={
            <PrivateRouter>
              <CreateContactNew />
            </PrivateRouter>
          }
        />
        {/* Aggreements */}
        <Route
          path={AppRoutes.aggreements}
          element={
            <PrivateRouter>
              <AggreementsList />
            </PrivateRouter>
          }
        />
        {/* SaleAgreements */}
        <Route
          path={AppRoutes.saleAgreement}
          element={
            <PrivateRouter>
              <SaleAgreementsList />
            </PrivateRouter>
          }
        />
        {/* LeaseAgreements */}
        <Route
          path={AppRoutes.leaseAgreement}
          element={
            <PrivateRouter>
              <LeaseAgreementsList />
            </PrivateRouter>
          }
        />
        {/* ManageAgreements */}
        <Route
          path={AppRoutes.manageAgreement}
          element={
            <PrivateRouter>
              <ManageAgreementsList />
            </PrivateRouter>
          }
        />
        {/* Aggreement view */}
        <Route
          path={AppRoutes.agreementView}
          element={
            <PrivateRouter>
              <AgreementDetails />
            </PrivateRouter>
          }
        />
        {/* Contact View */}
        <Route
          path={AppRoutes.contactView}
          element={
            <PrivateRouter>
              <ContactView />
            </PrivateRouter>
          }
        />
        {/* Jobs */}
        <Route
          path={AppRoutes.jobs}
          element={
            <PrivateRouter>
              <Jobs />
            </PrivateRouter>
          }
        />
        {/* Department */}
        <Route
          path={AppRoutes.department}
          element={
            <PrivateRouter>
              <Department />
            </PrivateRouter>
          }
        />
        {/* Parking Area */}
        <Route
          path={AppRoutes.parkingArea}
          element={
            <PrivateRouter>
              <ParkingArea />
            </PrivateRouter>
          }
        />
        {/* Access Gates */}
        <Route
          path={AppRoutes.accessGates}
          element={
            <PrivateRouter>
              <AccessGates />
            </PrivateRouter>
          }
        />
        {/* Access Gates Property View */}
        <Route
          path={AppRoutes.accessGatesPropertyView}
          element={
            <PrivateRouter>
              <AccessGatesPropertyView />
            </PrivateRouter>
          }
        />
        {/* serviceProviderListing */}
        <Route
          path={AppRoutes.serviceProviderListing}
          element={
            <PrivateRouter>
              <ServiceProviderViewNew />
            </PrivateRouter>
          }
        />
        {/* Service Provider View */}
        <Route
          path={AppRoutes.serviceProviderView}
          element={
            <PrivateRouter>
              <ServiceProviderView />
            </PrivateRouter>
          }
        />
        {/* Service Provider Create Edit */}
        <Route
          path={AppRoutes.serviceProviderCreateEdit}
          element={
            <PrivateRouter>
              <ServiceProviderCreateEdit />
            </PrivateRouter>
          }
        />
        {/* company select in login screen */}
        <Route path={AppRoutes.companyselect} element={<Company />} />
        {/* Welcome  in login screen */}
        <Route path={AppRoutes.welcome} element={<Welcome />} />
        {/* SetPassword  in login screen */}
        <Route path={AppRoutes.setpassword} element={<SetPassword />} />
        {/*leasemanagerdashboard */}
        <Route
          path={Routes.leasemanagerdashboard}
          element={
            <PrivateRouter>
              <LeaseAndSalesManagerDashboard />
            </PrivateRouter>
          }
        />

        {/* WorkspaceManagerDashboard */}
        <Route
          path={AppRoutes.WorkspaceManagerDashboard}
          element={
            <PrivateRouter>
              <WorkspaceManagerDashboard />
            </PrivateRouter>
          }
        />

        {/* securitydashboard */}
        <Route
          path={AppRoutes.securitydashboard}
          element={
            <PrivateRouter>
              <VisitorandSecurityDashboard />
            </PrivateRouter>
          }
        />
        {/* unit_vacancy*/}
        <Route
          path={AppRoutes.unit_vacancy}
          element={
            <PrivateRouter>
              <UnitVacancy />
            </PrivateRouter>
          }
        />
        {/* vacancy_details */}
        <Route
          path={AppRoutes.vacancy_details}
          element={
            <PrivateRouter>
              <UnitVacancyDetails />
            </PrivateRouter>
          }
        />
        {/*create opportunity*/}
        <Route
          path={AppRoutes.createOpportunity}
          element={
            <PrivateRouter>
              <CreateOpportunity />
            </PrivateRouter>
          }
        />
        {/* Profession */}
        <Route
          path={AppRoutes.profession}
          element={
            <PrivateRouter>
              <Profession />
            </PrivateRouter>
          }
        />
        {/* Emergency Number */}
        <Route
          path={AppRoutes.emergencyContact}
          element={
            <PrivateRouter>
              <EmergencyContact />
            </PrivateRouter>
          }
        />
        {/* Domestic Helper List */}
        <Route
          path={AppRoutes.domesticHelperList}
          element={
            <PrivateRouter>
              <DomesticHelperList />
            </PrivateRouter>
          }
        />
        {/* Domestic Helper View */}
        <Route
          path={AppRoutes.domesticHelperView}
          element={
            <PrivateRouter>
              <DomesticHelperView />
            </PrivateRouter>
          }
        />
        {/* Domestic Helper Create Edit */}
        <Route
          path={AppRoutes.domesticHelperCreateEdit}
          element={
            <PrivateRouter>
              <DomesticHelperCreateEdit />
            </PrivateRouter>
          }
        />
        {/*account*/}
        <Route
          path={AppRoutes.accounts}
          element={
            <PrivateRouter>
              <Accounts />
            </PrivateRouter>
          }
        />
        {/*create account*/}
        <Route
          path={AppRoutes.createAccount}
          element={
            <PrivateRouter>
              <CreateAccount />
            </PrivateRouter>
          }
        />
        {/*account details*/}
        <Route
          path={AppRoutes.accountDetails}
          element={
            <PrivateRouter>
              <AccountDetails />
            </PrivateRouter>
          }
        />
        {/*account details*/}
        {/* <PrivateRouter exact path={AppRoutes.dashboard} >
          <PropertyDashBoard  />
        </PrivateRouter> */}
        {/*ClientSettings*/}
        <Route
          path={AppRoutes.clientSettings}
          element={
            <PrivateRouter>
              <ClientSettings />
            </PrivateRouter>
          }
        />
        {/*userManagement */}
        <Route
          path={AppRoutes.userManagement}
          element={
            <PrivateRouter>
              <UserManageMent />
            </PrivateRouter>
          }
        />
        {/*invoice */}
        <Route
          path={AppRoutes.invoice}
          element={
            <PrivateRouter>
              <Invoice />
            </PrivateRouter>
          }
        />
        {/*invoiceView */}
        <Route
          path={AppRoutes.invoiceView}
          element={
            <PrivateRouter>
              <InvoiceView />
            </PrivateRouter>
          }
        />
        {/* Agreement Termination*/}
        <Route
          path={AppRoutes.agreementTermination}
          element={
            <PrivateRouter>
              <AgreementTermination />
            </PrivateRouter>
          }
        />
        {/* Short Term Pricing view */}
        <Route
          path={AppRoutes.shortTermPricing}
          element={
            <PrivateRouter>
              <ShortTermPricing />
            </PrivateRouter>
          }
        />
        {/* Short Term Pricing Property View */}
        <Route
          path={AppRoutes.shortTermPricingPropertyView}
          element={
            <PrivateRouter>
              <ShortTermPricingPropertyView />
            </PrivateRouter>
          }
        />
        {/* Short Term Pricing Unit View */}
        <Route
          path={AppRoutes.shortTermPricingUnitView}
          element={
            <PrivateRouter>
              <ShortTermPricingUnitView />
            </PrivateRouter>
          }
        />
        {/* DeliveryMilestone*/}
        <Route
          path={AppRoutes.deliveryMilestoneTemplate}
          element={
            <PrivateRouter>
              <DeliveryMilestoneTemplate />
            </PrivateRouter>
          }
        />
        {/* DeliveryMilestone*/}
        <Route
          path={AppRoutes.deliveryMilestone}
          element={
            <PrivateRouter>
              <DeliveryMilestone />
            </PrivateRouter>
          }
        />
        {/* Community Dashboard*/}
        <Route
          path={AppRoutes.communityDashboard}
          element={
            <PrivateRouter>
              <CommunityDashboard />
            </PrivateRouter>
          }
        />
        {/* Activity Category*/}
        <Route
          path={AppRoutes.activityCategory}
          element={
            <PrivateRouter>
              <ActivityCategory />
            </PrivateRouter>
          }
        />
        {/* Activity Sub-Category*/}
        <Route
          path={AppRoutes.activitySubCategory}
          element={
            <PrivateRouter>
              <ActivitySubCategory />
            </PrivateRouter>
          }
        />
        {/* Propspect*/}
        <Route
          path={AppRoutes.propspect}
          element={
            <PrivateRouter>
              <Propspect />
            </PrivateRouter>
          }
        />
        {/* Profile View */}
        <Route
          path={AppRoutes.profileView}
          element={
            <PrivateRouter>
              <ProfileView />
            </PrivateRouter>
          }
        />
        {/* Profile Edit */}
        <Route
          path={AppRoutes.profileEdit}
          element={
            <PrivateRouter>
              <ProfileEdit />
            </PrivateRouter>
          }
        />
        {/* Agreement Renewal */}
        <Route
          path={AppRoutes.agreementRenewal}
          element={
            <PrivateRouter>
              <AgreementRenewal />
            </PrivateRouter>
          }
        />
        {/* Unit Vacancy Property List */}
        <Route
          path={AppRoutes.unitVacancyPropertyList}
          element={
            <PrivateRouter>
              <UnitVacancyPropertyList />
            </PrivateRouter>
          }
        />
        {/* Unit Vacancy All List */}
        <Route
          path={AppRoutes.unitVacancyAll}
          element={
            <PrivateRouter>
              <UnitVacancyAllList />
            </PrivateRouter>
          }
        />
        {/* Unit Vacancy Property View */}
        <Route
          path={AppRoutes.unitVacancyPropertyView}
          element={
            <PrivateRouter>
              <UnitVacancyPropertyView />
            </PrivateRouter>
          }
        />
        {/* DeliveryMilestone Template*/}
        <Route
          path={AppRoutes.createDeliveryMilestoneTemplate}
          element={
            <PrivateRouter>
              <CreateDeliveryMilestoneTemplate />
            </PrivateRouter>
          }
        />
        {/* leadview */}
        <Route
          path={AppRoutes.leadview}
          element={
            <PrivateRouter>
              <LeadView />
            </PrivateRouter>
          }
        />
        {/* "/" Route */}
        <Route path={AppRoutes.home} element={<Login />} />
        {/* DbTask */}
        <Route path={AppRoutes.dbTask} element={<DbTask />} />
        {/* INSPECTION START*/}
        {/* move-in inspection details */}
        <Route
          path={AppRoutes.moveInInspectionOrders}
          element={
            <PrivateRouter>
              <ReqTable />
            </PrivateRouter>
          }
        />
        {/* inspection */}
        <Route
          path={AppRoutes.moveInInspectionDetails}
          element={
            <PrivateRouter>
              <InspectionList />
            </PrivateRouter>
          }
        />
        {/* inspection Request */}
        <Route
          path={AppRoutes.moveInInspectionItemDetails}
          element={
            <PrivateRouter>
              <InspectionReq />
            </PrivateRouter>
          }
        />
        {/* moveoutInspection */}
        <Route
          path={AppRoutes.moveOutInspectionOrders}
          element={
            <PrivateRouter>
              <MoveoutInspection />
            </PrivateRouter>
          }
        />
        {/* move-out inspection details */}
        <Route
          path={AppRoutes.moveOutInspectionDetails}
          element={
            <PrivateRouter>
              <InspectionList />
            </PrivateRouter>
          }
        />
        {/* move-out inspection item details */}
        <Route
          path={AppRoutes.moveOutInspectionItemDetails}
          element={
            <PrivateRouter>
              <InspectionReq />
            </PrivateRouter>
          }
        />
        {/*Unit HandOver Inspection */}
        <Route
          path={AppRoutes.unitHandOverInspectionOrders}
          element={
            <PrivateRouter>
              <UnitHandOver />
            </PrivateRouter>
          }
        />
        {/* unit HandOver inspection details */}
        <Route
          path={AppRoutes.unitHandOverInspectionDetails}
          element={
            <PrivateRouter>
              <InspectionList />
            </PrivateRouter>
          }
        />
        {/* unit HandOver inspection item details */}
        <Route
          path={AppRoutes.unitHandOverInspectionItemDetails}
          element={
            <PrivateRouter>
              <InspectionReq />
            </PrivateRouter>
          }
        />
        {/* InspectionItems */}
        <Route
          path={AppRoutes.inspectionItems}
          element={
            <PrivateRouter>
              <InspectionItems />
            </PrivateRouter>
          }
        />
        {/* InspectionItems property details */}
        <Route
          path={AppRoutes.insItemPropertyDetails}
          element={
            <PrivateRouter>
              <InsPropertyDetail />
            </PrivateRouter>
          }
        />
        {/* inspectionMapping */}
        <Route
          path={AppRoutes.inspectionMapping}
          element={
            <PrivateRouter>
              <InspectionMapping />
            </PrivateRouter>
          }
        />
        {/* inspectionMapping-propertyDetails  */}
        <Route
          path={AppRoutes.mapPropertyDetails}
          element={
            <PrivateRouter>
              <MapPropertyDetails />
            </PrivateRouter>
          }
        />
        {/* inspectionMapping-unitDetails  */}
        <Route
          path={AppRoutes.mapUnitDetails}
          element={
            <PrivateRouter>
              <MapUnitDetails />
            </PrivateRouter>
          }
        />
        {/* inventoryMaster */}
        <Route
          path={AppRoutes.inventoryMaster}
          element={
            <PrivateRouter>
              <InventoryMaster />
            </PrivateRouter>
          }
        />
        {/* resource Master */}
        <Route
          path={AppRoutes.resourceMaster}
          element={
            <PrivateRouter>
              <ResourceMaster />
            </PrivateRouter>
          }
        />
        {/* INSPECTION END*/}
        {/* Resource board  */}
        <Route
          path={AppRoutes.resourceBoard}
          element={
            <PrivateRouter>
              <ResourceBoard />
            </PrivateRouter>
          }
        />
        {/* Schedule Board */}
        <Route
          path={AppRoutes.scheduleBoard}
          element={
            <PrivateRouter>
              <ScheduleBoard />
            </PrivateRouter>
          }
        />
        {/* Employees Booking */}
        <Route
          path={AppRoutes.employeesBooking}
          element={
            <PrivateRouter>
              <EmployeesBooking />
            </PrivateRouter>
          }
        />
        {/* Already paid */}
        <Route
          path={AppRoutes.alreadypaid}
          element={
            <PrivateRouter>
              <AlreadyPaid />
            </PrivateRouter>
          }
        />
        {/* MaintanceRequest */}
        <Route
          path={AppRoutes.maintancerequest}
          element={
            <PrivateRouter>
              <MaintanceRequest />
            </PrivateRouter>
          }
        />
        {/* CreateMaintanceRequest */}
        <Route
          path={AppRoutes.createmaintanceRequest}
          element={
            <PrivateRouter>
              <CreateMaintanceRequest />
            </PrivateRouter>
          }
        />
        {/* generalRequest */}
        <Route
          path={AppRoutes.generalRequest}
          element={
            <PrivateRouter>
              <GeneralRequest />
            </PrivateRouter>
          }
        />
        {/* creategeneralrequest */}
        <Route
          path={AppRoutes.creategeneralrequest}
          element={
            <PrivateRouter>
              <CreateGeneralRequest />
            </PrivateRouter>
          }
        />
        {/* receipt */}
        <Route
          path={AppRoutes.receipt}
          element={
            <PrivateRouter>
              <Receipt />
            </PrivateRouter>
          }
        />
        {/*CreateReceipt */}
        <Route
          path={AppRoutes.createReceipt}
          element={
            <PrivateRouter>
              <CreateReceipt />
            </PrivateRouter>
          }
        />
        {/*ReceiptView */}
        <Route
          path={AppRoutes.receiptView}
          element={
            <PrivateRouter>
              <ReceiptView />
            </PrivateRouter>
          }
        />
        {/* kyclist */}
        <Route
          path={AppRoutes.kyclist}
          element={
            <PrivateRouter>
              <KycScreen />
            </PrivateRouter>
          }
        />
        {/* pricingAppraisal */}
        <Route
          path={AppRoutes.pricingAppraisal}
          element={
            <PrivateRouter>
              <PricingAppraisal />
            </PrivateRouter>
          }
        />
        {/* inspection master */}
        <Route
          path={AppRoutes.inspectionMaster}
          element={
            <PrivateRouter>
              <InspectionMaster />
            </PrivateRouter>
          }
        />
        {/* rental index */}
        <Route
          path={AppRoutes.rentalIndex}
          element={
            <PrivateRouter>
              <RentalIndex />
            </PrivateRouter>
          }
        />
        {/* Working Hours Master */}
        <Route
          path={AppRoutes.workingHoursMaster}
          element={
            <PrivateRouter>
              <WorkingHoursMaster />
            </PrivateRouter>
          }
        />
        {/* vehicle master */}
        <Route
          path={AppRoutes.vehicleMaster}
          element={
            <PrivateRouter>
              <VehicleMaster />
            </PrivateRouter>
          }
        />
        {/* toolMaster */}
        <Route
          path={AppRoutes.toolMaster}
          element={
            <PrivateRouter>
              <ToolsMaster />
            </PrivateRouter>
          }
        />
        {/* ManageInvoice */}
        <Route
          path={AppRoutes.manageInvoice}
          element={
            <PrivateRouter>
              <ManageInvoice />
            </PrivateRouter>
          }
        />
        {/* QueuedInvoice */}
        <Route
          path={AppRoutes.queuedInvoice}
          element={
            <PrivateRouter>
              <QueuedInvoice />
            </PrivateRouter>
          }
        />
        {/* ProxyInvoice */}
        <Route
          path={AppRoutes.proxyInvoice}
          element={
            <PrivateRouter>
              <ProxyInvoice />
            </PrivateRouter>
          }
        />
        {/* statement of account */}
        <Route
          path={AppRoutes.statementOfAccount}
          element={
            <PrivateRouter>
              <StatementOfAccount />
            </PrivateRouter>
          }
        />
        {/* Reservation */}
        <Route
          path={AppRoutes.reservation}
          element={
            <PrivateRouter>
              <Reservation />
            </PrivateRouter>
          }
        />
        {/* Reservation Details */}
        <Route
          path={AppRoutes.reservationDetails}
          element={
            <PrivateRouter>
              <ReservationDetails />
            </PrivateRouter>
          }
        />
        {/* Amenities Booking */}
        <Route
          path={AppRoutes.amenitiesBooking}
          element={
            <PrivateRouter>
              <AmenitiesBooking />
            </PrivateRouter>
          }
        />
        {/* domesticContactsList */}
        <Route
          path={AppRoutes.domesticContactsList}
          element={
            <PrivateRouter>
              <DomesticContacts />
            </PrivateRouter>
          }
        />
        {/* customerAccountList */}
        <Route
          path={AppRoutes.customerAccountList}
          element={
            <PrivateRouter>
              <CustomerAccountList />
            </PrivateRouter>
          }
        />
        {/* vendorAccountList */}
        <Route
          path={AppRoutes.vendorAccountList}
          element={
            <PrivateRouter>
              <VendorAccountList />
            </PrivateRouter>
          }
        />
        {/* vendorAccountCreate */}
        <Route
          path={AppRoutes.vendorAccountCreate}
          element={
            <PrivateRouter>
              <VendorAccountCreate />
            </PrivateRouter>
          }
        />
        {/* Reports */}
        <Route
          path={AppRoutes.reports}
          element={
            <PrivateRouter>
              <Reports />
            </PrivateRouter>
          }
        />
        {/* Report */}
        <Route
          path={AppRoutes.report}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        {/* UnitOccupancyReport */}
        <Route
          path={AppRoutes.unitOccupancyReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.propertyOverviewReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.unitOverviewReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.unitLinkedInventoryReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.unitLinkedUtiltiesReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.unitLinkedPricingComponentReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.loginActivityReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.leadOverviewReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.opportunityOverviewReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.agreementOverviewReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.reservationReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.terminationReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.unitBlockingReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.renewalReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.visitorWorkerRequestsReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.visitorWorkerEntriesReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.onboardRegisteredWorkerReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.registeredWorkerEntriesReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.shortPeriodParkingSlotRequestsReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.parkingSlotUtilizationReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.vendorEntriesReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.serviceProviderEntriesReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.walkInEntriesReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.generalRequestsReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.maintenanceRequestsReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.moveInAndMoveOutReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.allWorkJobsReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.resourceUtilizationSummaryReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.utilityReadingsReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.resourceTimeOffRequestsReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.resourceTimeOffJobsReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.itemMasterReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.singleUnitAgreementsReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.projectedRevenueReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.invoiceSettlementsReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.allAgreementOverviewReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.receiptsAndCollectionsReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.projectedVsPostedComparisonsReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.chequeManagementReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.invoicePaymentStatusReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.postedRevenueReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.unitPricingReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.unitInventoryReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.workOrderReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.resourceUtilizationReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.weekoffAndTimeoffJobs}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.utilityBillingReports}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.unitUtilitiesReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.amenityBookingOverview}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.scheduledChequeReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.actualRevenueReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.projectedVsPostedSummaryReport}
          element={
            <PrivateRouter>
              <Report />
            </PrivateRouter>
          }
        />

        {/* fileManager */}
        <Route
          path={AppRoutes.fileManager}
          element={
            <PrivateRouter>
              <FileManager />
            </PrivateRouter>
          }
        />
        {/* owner account create */}
        <Route
          path={AppRoutes.ownerAccountCreate}
          element={
            <PrivateRouter>
              <OwnerAccountCreate />
            </PrivateRouter>
          }
        />
        {/* owner account list */}
        <Route
          path={AppRoutes.ownerAccountList}
          element={
            <PrivateRouter>
              <OwnerAccountList />
            </PrivateRouter>
          }
        />
        {/* managementCommitteRoleMaster */}
        <Route
          path={AppRoutes.managementCommitteRoleMaster}
          element={
            <PrivateRouter>
              <ManagementCommitteRoleMaster />
            </PrivateRouter>
          }
        />
        {/* vendor account detail */}
        <Route
          path={AppRoutes.vendorAccountDetail}
          element={
            <PrivateRouter>
              <VendorAccountDetail />
            </PrivateRouter>
          }
        />
        {/* ownerAccountDetails */}
        <Route
          path={AppRoutes.ownerAccountDetails}
          element={
            <PrivateRouter>
              <OwnerAccountDetails />
            </PrivateRouter>
          }
        />
        {/* Customer account detail */}
        <Route
          path={AppRoutes.customerAccountDetails}
          element={
            <PrivateRouter>
              <CustomerAccountDetails />
            </PrivateRouter>
          }
        />
        {/* parkingBooking */}
        <Route
          path={AppRoutes.parkingBooking}
          element={
            <PrivateRouter>
              <ParkingBooking />
            </PrivateRouter>
          }
        />
        {/* parkingGroupingMaster */}
        <Route
          path={AppRoutes.parkingGroupingMaster}
          element={
            <PrivateRouter>
              <ParkingGroupingMaster />
            </PrivateRouter>
          }
        />
        {/* announcement_details */}
        <Route
          path={AppRoutes.announcement_details}
          element={
            <PrivateRouter>
              <AnnounsmentDetails />
            </PrivateRouter>
          }
        />
        {/* Invite trigger */}
        <Route
          path={AppRoutes.inviteTrigger}
          element={
            <PrivateRouter>
              <InviteTrigger />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.maintanceRequestView}
          element={
            <PrivateRouter>
              <RequestView />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.generalRequestView}
          element={
            <PrivateRouter>
              <RequestView />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.freeTextInvoice}
          element={
            <PrivateRouter>
              <FreeTextInvoice />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.createInvoiceFreeText}
          element={
            <PrivateRouter>
              <CreateInvoiceFreeText />
            </PrivateRouter>
          }
        />
        {/* TimeOffRequest */}
        <Route
          path={AppRoutes.timeOffRequest}
          element={
            <PrivateRouter>
              <TimeOffRequest />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.teams}
          element={
            <PrivateRouter>
              <Teams />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.settlements}
          element={
            <PrivateRouter>
              <Settlements />
            </PrivateRouter>
          }
        />
        {/* resourceBookingDetails */}
        <Route
          path={AppRoutes.resourceBookingDetails}
          element={
            <PrivateRouter>
              <ResourceBooking />
            </PrivateRouter>
          }
        />
        {/*Service Inspection */}
        <Route
          path={AppRoutes.serviceInspection}
          element={
            <PrivateRouter>
              <ServiceInspection />
            </PrivateRouter>
          }
        />
        {/* viewServiceInspection */}
        <Route
          path={AppRoutes.viewServiceInspection}
          element={
            <PrivateRouter>
              <ViewServiceInspection />
            </PrivateRouter>
          }
        />
        {/*General Inspection */}
        <Route
          path={AppRoutes.generalInspection}
          element={
            <PrivateRouter>
              <GeneralInspection />
            </PrivateRouter>
          }
        />
        {/*view general Inspection */}
        <Route
          path={AppRoutes.viewGeneralInspection}
          element={
            <PrivateRouter>
              <ViewGeneralInspection />
            </PrivateRouter>
          }
        />
        {/*Site Visit */}
        <Route
          path={AppRoutes.siteVisit}
          element={
            <PrivateRouter>
              <SiteVisit />
            </PrivateRouter>
          }
        />
        {/*viewSiteVisitDetails */}
        <Route
          path={AppRoutes.viewSiteVisitDetails}
          element={
            <PrivateRouter>
              <ViewSiteVisitDetails />
            </PrivateRouter>
          }
        />
        {/*Unit Readiness*/}
        <Route
          path={AppRoutes.unitReadiness}
          element={
            <PrivateRouter>
              <UnitReadiness />
            </PrivateRouter>
          }
        />
        {/*viewUnitReadinessDetails */}
        <Route
          path={AppRoutes.viewUnitReadinessDetails}
          element={
            <PrivateRouter>
              <ViewUnitReadinessDetails />
            </PrivateRouter>
          }
        />
        {/* KYC Details */}
        <Route
          path={AppRoutes.kycDetails}
          element={
            <PrivateRouter>
              <KycDetails />
            </PrivateRouter>
          }
        />
        {/* kycDetailView==================================*/}
        <Route
          path={AppRoutes.kycDetailView}
          element={
            <PrivateRouter>
              <KycDetailView />
            </PrivateRouter>
          }
        />
        {/* Time Off resources */}
        <Route
          path={AppRoutes.timeOffResources}
          element={
            <PrivateRouter>
              <TimeOffResources />
            </PrivateRouter>
          }
        />
        {/* inspectionFeedbackCategory */}
        <Route
          path={AppRoutes.inspectionFeedbackCategory}
          element={
            <PrivateRouter>
              <FeedBackCategoryMaster />
            </PrivateRouter>
          }
        />
        {/* inspectionFeedbackSubcategory */}
        <Route
          path={AppRoutes.inspectionFeedbackSubcategory}
          element={
            <PrivateRouter>
              <FeedBackSubCategoryMaster />
            </PrivateRouter>
          }
        />
        {/* Correspondences */}
        <Route
          path={AppRoutes.correspondences}
          element={
            <PrivateRouter>
              <Correspondences />
            </PrivateRouter>
          }
        />
        {/* create_correspondences*/}
        <Route
          path={AppRoutes.create_correspondences}
          element={
            <PrivateRouter>
              <CreateEditCorrespondences />
            </PrivateRouter>
          }
        />
        {/* edit_correspondences*/}
        <Route
          path={AppRoutes.edit_correspondences}
          element={
            <PrivateRouter>
              <CreateEditCorrespondences />
            </PrivateRouter>
          }
        />
        {/* Activity Board */}
        <Route
          path={AppRoutes.activityBoard}
          element={
            <PrivateRouter>
              <ActivityBoard />
            </PrivateRouter>
          }
        />
        {/* Public Listing */}
        <Route
          path={AppRoutes.publicListing}
          element={
            <PrivateRouter>
              <PublicListing />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.deliveryOrders}
          element={
            <PrivateRouter>
              <DeliveryWorkOrder />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.viewDeliveryOrders}
          element={
            <PrivateRouter>
              <ViewDeliveryOrderDetails />
            </PrivateRouter>
          }
        />
        {/* Utility Readings */}
        <Route
          path={AppRoutes.utilityReadings}
          element={
            <PrivateRouter>
              <UtilityReadings />
            </PrivateRouter>
          }
        />
        {/* {customer FeedBack} */}
        <Route
          path={AppRoutes.customerFeedBack}
          element={
            <PrivateRouter>
              <CustomerFeedBack />
            </PrivateRouter>
          }
        />
        {/* Shortlist*/}
        <Route
          path={AppRoutes.shortlistedoppertunityunits}
          element={
            <PrivateRouter>
              <ShortList isOppertunity={true} />
            </PrivateRouter>
          }
        />
        {/* Reset Password*/}
        <Route
          path={AppRoutes.resetyourpassword}
          element={<ResetYourPassword />}
        />
        {/* Contract */}
        <Route
          path={AppRoutes.contract}
          element={
            <PrivateRouter>
              <Contract />
            </PrivateRouter>
          }
        />
        {/* Contract create and edit */}
        <Route
          path={AppRoutes.contractCreateEdit}
          element={
            <PrivateRouter>
              <CreateEditContract />
            </PrivateRouter>
          }
        />
        {/* Create Quotation */}
        <Route
          path={AppRoutes.createOppertunityQuotation}
          element={
            <PrivateRouter>
              <CreateQuotation />
            </PrivateRouter>
          }
        />
        {/* Amenity mapping*/}
        <Route
          path={AppRoutes.amenityMappingNew}
          element={
            <PrivateRouter>
              <AmenityMappingNew />
            </PrivateRouter>
          }
        />
        {/* check availability */}
        <Route
          path={AppRoutes.checkAvailability}
          element={
            <PrivateRouter>
              <CheckAvailability />
            </PrivateRouter>
          }
        />
        {/* Quotation */}
        <Route
          path={AppRoutes.oppertunityQuotation}
          element={
            <PrivateRouter>
              <QuotationsList title="Quatation" />
            </PrivateRouter>
          }
        />
        {/* Property Board */}
        <Route
          path={AppRoutes.propertyBoard}
          element={
            <PrivateRouter>
              <PropertyBoard />
            </PrivateRouter>
          }
        />
        {/* Free Text Invoice */}
        <Route
          path={AppRoutes.creditNote}
          element={
            <PrivateRouter>
              <FreeTextInvoice />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.debitNote}
          element={
            <PrivateRouter>
              <FreeTextInvoice />
            </PrivateRouter>
          }
        />
        {/*form Builder*/}
        <Route
          path={AppRoutes.formList}
          element={
            <PrivateRouter>
              <FormBuilder />
            </PrivateRouter>
          }
        />
        {/* create form */}
        <Route
          path={AppRoutes.formBuilder}
          element={
            <PrivateRouter>
              <CreateFeedBackForm />
            </PrivateRouter>
          }
        />
        {/*unit Blocking */}
        <Route
          path={AppRoutes.unitBlocking}
          element={
            <PrivateRouter>
              <UnitBlockingList />
            </PrivateRouter>
          }
        />
        {/*Inspection management service dashboard */}
        <Route
          path={AppRoutes.serviceHubDashboard}
          element={
            <PrivateRouter>
              <ServiceHubDashboard />
            </PrivateRouter>
          }
        />
        {/*KYC Document Type */}
        <Route
          path={AppRoutes.kyc_document_type}
          element={
            <PrivateRouter>
              <KycDocumentType />
            </PrivateRouter>
          }
        />
        {/*Correspondence Type */}
        <Route
          path={AppRoutes.correspondence_type}
          element={
            <PrivateRouter>
              <CorrespondenceType />
            </PrivateRouter>
          }
        />
        {/*Amenity Category */}
        <Route
          path={AppRoutes.amenity_category}
          element={
            <PrivateRouter>
              <AmenityCategory />
            </PrivateRouter>
          }
        />
        {/*Leave Master */}
        <Route
          path={AppRoutes.leave_master}
          element={
            <PrivateRouter>
              <LeaveMaster />
            </PrivateRouter>
          }
        />
        {/*Share Unit */}
        <Route path={AppRoutes.shareUnit} element={<ShareUnit />} />
        {/*Resident Onboarding View */}
        <Route
          path={AppRoutes.tenantOnboarding}
          element={
            <PrivateRouter>
              <ResidentOnboarding type="tenant" />
            </PrivateRouter>
          }
        />
        {/*Resident Onboarding View */}
        <Route
          path={AppRoutes.residentOnboarding}
          element={
            <PrivateRouter>
              <ResidentOnboarding type="resident" />
            </PrivateRouter>
          }
        />
        {/*Resident Onboarding View */}
        <Route
          path={AppRoutes.quickLeaseOnboarding}
          element={
            <PrivateRouter>
              <ResidentOnboarding type="leaseType" />
            </PrivateRouter>
          }
        />
        {/*Resident Onboarding View Edit */}
        <Route
          path={AppRoutes.residentOnboardingViewEdit}
          element={
            <PrivateRouter>
              <ResidentOnboardingViewEdit />
            </PrivateRouter>
          }
        />
        {/* Marketing Portal*/}
        <Route
          path={AppRoutes.marketingConfiguration}
          element={
            <PrivateRouter>
              <MarketingPortalConfiguration />
            </PrivateRouter>
          }
        />
        {/* Amenity view */}
        <Route
          path={AppRoutes.amenityMappingNew}
          element={
            <PrivateRouter>
              <AmenityMappingNew />
            </PrivateRouter>
          }
        />
        {/*Import */}
        <Route
          path={AppRoutes.import}
          element={
            <PrivateRouter>
              <Import />
            </PrivateRouter>
          }
        />
        {/*Import File Upload */}
        <Route
          path={AppRoutes.import_file_upload}
          element={
            <PrivateRouter>
              <ImportFileUpload />
            </PrivateRouter>
          }
        />
        <Route
          path={AppRoutes.lead_import_file_upload}
          element={
            <PrivateRouter>
              <LeadImportFileUploadWithNavBars />
            </PrivateRouter>
          }
        />
        {/* Listing Dashboard */}
        <Route
          path={AppRoutes.listingDashBoard}
          element={
            <PrivateRouter>
              <ListingDashBoard />
            </PrivateRouter>
          }
        />
        {/*Backup & Restore */}
        <Route
          path={AppRoutes.backup_restore}
          element={
            <PrivateRouter>
              <BackupRestore />
            </PrivateRouter>
          }
        />
        {/* Final Settlement */}
        <Route
          path={AppRoutes.finalSettlement}
          element={
            <PrivateRouter>
              <FinalSettlement />
            </PrivateRouter>
          }
        />
        {/* Final Settlement View */}
        <Route
          path={AppRoutes.finalSettlementView}
          element={
            <PrivateRouter>
              <FinalSettlementView />
            </PrivateRouter>
          }
        />
        {/* welcome banner */}
        <Route
          path={AppRoutes.welcome_banner}
          element={
            <PrivateRouter>
              <WelcomePanner />
            </PrivateRouter>
          }
        />
        {/* dashboard banner */}
        <Route
          path={AppRoutes.dashboard_banner}
          element={
            <PrivateRouter>
              <Dashboardbanner />
            </PrivateRouter>
          }
        />
        {/* community banner */}
        <Route
          path={AppRoutes.community_banner}
          element={
            <PrivateRouter>
              <Communitybanner />
            </PrivateRouter>
          }
        />
        {/* logo appearences */}
        <Route
          path={AppRoutes.logo_apperence}
          element={
            <PrivateRouter>
              <LogoApperances />
            </PrivateRouter>
          }
        />
        {/*Subcriptions */}
        <Route
          path={AppRoutes.subscribe}
          element={
            <PrivateRouter>
              <Subscriptions />
            </PrivateRouter>
          }
        />
        {/* Restore Error handling Image */}
        <Route
          path={Routes?.restoreErrorHandling}
          element={<RestoreErrorHandling />}
        />
        {/*Amenity Board*/}
        <Route
          path={AppRoutes.amenity_board}
          element={
            <PrivateRouter>
              <AmenityBoard />
            </PrivateRouter>
          }
        />
        {/* correspondences view*/}
        <Route
          path={AppRoutes.correspondencesView}
          element={
            <PrivateRouter>
              <CorrespondencesView />
            </PrivateRouter>
          }
        />
        {/* Costing Approval */}
        <Route
          path={AppRoutes.costingApprovalRequests}
          element={
            <PrivateRouter>
              <CostingApproval />
            </PrivateRouter>
          }
        />
        {/* Contract 360 */}
        <Route
          path={AppRoutes.contract360}
          element={
            <PrivateRouter>
              <Contract360 />
            </PrivateRouter>
          }
        />
        {/* Announcement Board */}
        <Route
          path={AppRoutes.announcementBoard}
          element={
            <PrivateRouter>
              <AnnouncementBoard />
            </PrivateRouter>
          }
        />
        {/* Item Category Master */}
        <Route
          path={AppRoutes.item_category_master}
          element={
            <PrivateRouter>
              <ItemCategoryMaster />
            </PrivateRouter>
          }
        />
        {/* Item Sub Category Master */}
        <Route
          path={AppRoutes.item_sub_category_master}
          element={
            <PrivateRouter>
              <ItemSubCategoryMaster />
            </PrivateRouter>
          }
        />
        {/* Manufacturer Master */}
        <Route
          path={AppRoutes.manufacturer_master}
          element={
            <PrivateRouter>
              <ManufacturerMaster />
            </PrivateRouter>
          }
        />
        {/* Unit Item Location Master */}
        <Route
          path={AppRoutes.unit_item_location_master}
          element={
            <PrivateRouter>
              <UnitItemLocationMaster />
            </PrivateRouter>
          }
        />
        {/* Occupants */}
        <Route
          path={AppRoutes.occupants}
          element={
            <PrivateRouter>
              <Occupants />
            </PrivateRouter>
          }
        />
        {/* CheckListGroup */}
        <Route
          path={AppRoutes.checkListGroup}
          element={
            <PrivateRouter>
              <CheckListGroup />
            </PrivateRouter>
          }
        />
        {/* CheckList Gorup view */}
        <Route
          path={AppRoutes.checkListGroupView}
          element={
            <PrivateRouter>
              <CheckListGroupView />
            </PrivateRouter>
          }
        />
        {/* CheckList item */}
        <Route
          path={AppRoutes.checkListItemView}
          element={
            <PrivateRouter>
              <CheckListItemView />
            </PrivateRouter>
          }
        />
        {/* Explore */}
        <Route path={AppRoutes.ExploreTemplate} element={<ExploreTemplate />} />
        {/* My Org */}
        <Route
          path={AppRoutes.myOrg}
          element={
            <PrivateRouter>
              <MyOrg />
            </PrivateRouter>
          }
        />
        {/* Management Role Master */}
        <Route
          path={AppRoutes.managementRoleMaster}
          element={
            <PrivateRouter>
              <ManagementRoleMaster />
            </PrivateRouter>
          }
        />
        {/* Functional Location */}
        <Route
          path={AppRoutes.functionalLocation}
          element={
            <PrivateRouter>
              <FunctionalLocation />
            </PrivateRouter>
          }
        />
        {/* Functional Heirarchy */}
        <Route
          path={AppRoutes.functionalHeirarchy}
          element={
            <PrivateRouter>
              <FunctionalHeirarchy />
            </PrivateRouter>
          }
        />
        {/* Internal Lead Source */}
        <Route
          path={AppRoutes.internal_lead_source}
          element={
            <PrivateRouter>
              <InternalLeadSource />
            </PrivateRouter>
          }
        />
        {/* Duty Roster */}
        <Route
          path={AppRoutes.dutyRoster}
          element={
            <PrivateRouter>
              <DutyRoster />
            </PrivateRouter>
          }
        />
        {/* Self CheckIn Entries */}
        <Route
          path={AppRoutes.selfCheckInEntries}
          element={
            <PrivateRouter>
              <SelfCheckInEntries />
            </PrivateRouter>
          }
        />
        {/* Ledger Master */}
        <Route
          path={AppRoutes.ledgerMaster}
          element={
            <PrivateRouter>
              <LedgerMaster />
            </PrivateRouter>
          }
        />
        {/* Sub Ledger Master */}
        <Route
          path={AppRoutes.subLedgerMaster}
          element={
            <PrivateRouter>
              <SubLedgerMaster />
            </PrivateRouter>
          }
        />
        {/* COA Master*/}
        <Route
          path={AppRoutes.COAMaster}
          element={
            <PrivateRouter>
              <COAMaster />
            </PrivateRouter>
          }
        />
        {/* Utility Manager Dashboard */}
        <Route
          path={AppRoutes.utilityDashboard}
          element={
            <PrivateRouter>
              <UtilityManagerDashboard />
            </PrivateRouter>
          }
        />
        {/* Resource Manager Dashboard */}
        <Route
          path={AppRoutes.resourceManagerDashboard}
          element={
            <PrivateRouter>
              <ResourceManagerDashboard />
            </PrivateRouter>
          }
        />
        {/* Customer Group Master */}
        <Route
          path={AppRoutes.customer_group_master}
          element={
            <PrivateRouter>
              <CustomerGroupMaster />
            </PrivateRouter>
          }
        />
        {/* Vendor Group Master */}
        <Route
          path={AppRoutes.vendor_group_master}
          element={
            <PrivateRouter>
              <VendorGroupMaster />
            </PrivateRouter>
          }
        />
        {/* Expense Group Master */}
        <Route
          path={AppRoutes.expense_group_master}
          element={
            <PrivateRouter>
              <ExpenseGroupMaster />
            </PrivateRouter>
          }
        />
        {/*Utility Category */}
        <Route
          path={AppRoutes.utilities_category}
          element={
            <PrivateRouter>
              <UtilitiesCategory />
            </PrivateRouter>
          }
        />
        {/*Customize quote */}
        <Route path={AppRoutes.customQuote} element={<CustomizeQuote />} />
        {/*Amenity DashBoard */}
        <Route
          path={AppRoutes.amenityDashboard}
          element={
            <PrivateRouter>
              <AmenityDashBoard />
            </PrivateRouter>
          }
        />
        {/*Maintenance Planner*/}
        <Route
          path={AppRoutes.fixedMaintenancePlanner}
          element={
            <PrivateRouter>
              <MaintenancePlanner />
            </PrivateRouter>
          }
        />
        {/* {Dynamic Maintenance planner} */}
        <Route
          path={AppRoutes.dynamicMaintenancePlanner}
          element={
            <PrivateRouter>
              <DynamicMaintenancePlanner />
            </PrivateRouter>
          }
        />
        {/* Create Maintenance Planner */}
        <Route
          path={AppRoutes.createMaintenancePlanner}
          element={
            <PrivateRouter>
              <CreateFixedMaintenancePlanner />
            </PrivateRouter>
          }
        />
        {/* View Maintenance Planner */}
        <Route
          path={AppRoutes.viewMaintenancePlanner}
          element={
            <PrivateRouter>
              <ViewMaintenancePlanner />
            </PrivateRouter>
          }
        />
        {/* CustomerAccountCreate */}
        <Route
          path={AppRoutes.customerAccountCreate}
          element={
            <PrivateRouter>
              <CustomerAccountCreate />
            </PrivateRouter>
          }
        />
        {/* Cash and Bank Account */}
        <Route
          path={AppRoutes.cashBankAccountList}
          element={
            <PrivateRouter>
              <CashBankAccountList />
            </PrivateRouter>
          }
        />
        {/* Cash and Bank Account Create */}
        <Route
          path={AppRoutes.cashBankAccountCreate}
          element={
            <PrivateRouter>
              <CashBankAccountCreate />
            </PrivateRouter>
          }
        />
        {/* Cash and Bank Account Details */}
        <Route
          path={AppRoutes.cashBankAccountDetails}
          element={
            <PrivateRouter>
              <CashBankAccountDetails />
            </PrivateRouter>
          }
        />
        {/* Market Place */}
        <Route
          path={AppRoutes.marketPlace}
          element={
            <PrivateRouter>
              <MarketPlace />
            </PrivateRouter>
          }
        />
        {/* Market Place View */}
        <Route
          path={AppRoutes.marketPlaceView}
          element={
            <PrivateRouter>
              <MarketPlaceView />
            </PrivateRouter>
          }
        />
        {/* Survey Form */}
        <Route
          path={AppRoutes.surveyForm}
          element={
            <PrivateRouter>
              <SurveyForm />
            </PrivateRouter>
          }
        />
        {/* Create Survey Form */}
        <Route
          path={AppRoutes.createSurveyForm}
          element={
            <PrivateRouter>
              <CreateSurveyForm />
            </PrivateRouter>
          }
        />
        {/* Schedule Plan View */}
        <Route
          path={AppRoutes.schedulePlan}
          element={
            <PrivateRouter>
              <SchedulePlanView />
            </PrivateRouter>
          }
        />
        {/* Schedule Work order View */}
        <Route
          path={AppRoutes.scheduledWorkOrder}
          element={
            <PrivateRouter>
              <ScheduleWorkOrderView />
            </PrivateRouter>
          }
        />
        {/* Executed Work Orders View */}
        <Route
          path={AppRoutes.executedWorkOrder}
          element={
            <PrivateRouter>
              <ExecutedWorkOrdersView />
            </PrivateRouter>
          }
        />
        {/* Executed Jobs View */}
        <Route
          path={AppRoutes.executedJobs}
          element={
            <PrivateRouter>
              <ExecutedJobsView />
            </PrivateRouter>
          }
        />
        {/* Project */}
        <Route
          path={AppRoutes.project}
          element={
            <PrivateRouter>
              <Project />
            </PrivateRouter>
          }
        />
        {/* Resource Group Master */}
        <Route
          path={AppRoutes.resourceGroupMaster}
          element={
            <PrivateRouter>
              <ResourceGroupMaster />
            </PrivateRouter>
          }
        />
        {/* Project Category */}
        <Route
          path={AppRoutes.projectCategory}
          element={
            <PrivateRouter>
              <ProjectCategory />
            </PrivateRouter>
          }
        />
        {/* VideoRecorder */}
        <Route path={AppRoutes.videoRecorder} element={<VideoRecorder />} />
        <Route
          path={AppRoutes.paymentDashboard}
          element={
            <PrivateRouter>
              <PaymentDashboard />
            </PrivateRouter>
          }
        />
        {/* ExternalUnits */}
        <Route
          path={AppRoutes.externalUnits}
          element={
            <PrivateRouter>
              <ExternalUnits />
            </PrivateRouter>
          }
        />
        {/* Approval Workflow */}
        <Route
          path={AppRoutes.approvalWorkflow}
          element={
            <PrivateRouter>
              <ApprovalWorkflow />
            </PrivateRouter>
          }
        />
        {/* For unknow/non-defined path */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default RouterApp;
