import { Badge, Box, Button, Divider, Grid, IconButton, Stack } from "@mui/material";
import React from "react";
import { useNavigate } from 'react-router-dom';
// import FilterIcon from "../../../assets/filterIcon";
import { SearchFilter, TableWithPagination } from "../../../components";
import { Routes } from "../../../router/routes";
import { Accountheading, Accountpath, dataType } from "../../../utils/accountUtiles";
import { useStyles } from "../styles";
import FilterIMG from "../../../assets/filter";

export const AccountTable = ({
    handleSearch = () => false,
    searchText = "",
    handleIcon = () => false,
    data = {},
    handlePagination = () => false,
    handleChangeLimit = () => false,
    page = "",
    limit = "",
    selectedCompany = {},
    permission = {},
    setIsFilterData = "",
    type = null,
    enable_double_click = "",
    enable_single_click = "",
    t = () => false,
    filterData = {}
}) => {
    const typeSelect = {
        "Vendor": Routes.vendorAccountCreate,
        "Property Owner": Routes.ownerAccountCreate,
        "AllAccount": Routes.createAccount,
        "Customer": Routes.customerAccountCreate,
        "cashBank": Routes.cashBankAccountCreate
    }

    const createBtn = {
        "Customer": t("New Customer Account"),
        "Vendor": t("New Vendor Account"),
        "cashBank": t("New Cash & Bank Account"),
        "Property Owner": t("New Property Owner"),
        "AllAccount": t("New Account")
    }

    const classes = useStyles()
    const navigate = useNavigate()
    const goCreate = () => {
        navigate(type?.length > 0 ? typeSelect[type] : Routes.createAccount,
        {
            state: selectedCompany
        })
    }
    const openFilter = () => {
        setIsFilterData(true)
    }
    return (
        <Box>
            <Grid container justifyContent="space-between">
                <Grid item xs={4}>
                    {/*search field*/}
                    <SearchFilter value={searchText} placeholder={t("Search Account")} handleChange={(value) => handleSearch(value)} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
                </Grid>
                <Grid item xs={8}>
                    {/*Add Btn*/}
                    <Box display={"flex"} justifyContent={"end"} alignItems={"center"}>
                        <Stack direction="row"
                            divider={<Divider orientation="vertical" flexItem sx={{ marginInline: "16px" }} />}>
                            {type === "AllAccount" &&
                                
                                <Box className={classes.filterBox}>
                                {/* <Box>
                                    <FilterIcon
                                        onClick={() => openFilter()} />
                                </Box> */}
                                    {
                                        (filterData?.account_type?.length > 0 || filterData?.is_active?.length > 0) ?
                                            <IconButton onClick={() => openFilter()} className={classes.img}>
                                                <Badge variant="dot" color="primary">
                                                    <FilterIMG color="#091b29" />
                                                </Badge>
                                            </IconButton> :
                                            <IconButton onClick={() => openFilter()} className={classes.img}>
                                                <FilterIMG color="#091b29" />
                                            </IconButton>
                                    }
                                </Box>
                            }
                            {
                                permission?.create &&
                                // type !== "Customer" &&
                                <Button
                                    onClick={goCreate}
                                    variant="contained" className={classes.btn}>
                                        {createBtn[type]}
                                    {/* {type === "Customer" ? "New Customer" : t("New Account")} */}
                                </Button>
                            }


                        </Stack>
                    </Box>
                    {/* {permission?.create && <Button
                        onClick={goCreate}
                        variant="contained" className={classes.btn}>Add An Account</Button>} */}
                </Grid>
            </Grid>
            <TableWithPagination
                heading={Accountheading(t)}
                rows={data?.list}
                path={Accountpath}
                showpagination={true}
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                tableType="no-side"
                count="2"
                handleIcon={handleIcon}
                dataType={dataType}
                handlePagination={handlePagination}
                handleChangeLimit={handleChangeLimit}
                totalRowsCount={data?.count}
                page={page}
                limit={limit}
                height={`calc(100vh - 300px)`}
                view={permission?.read}
                edit={permission?.update}
                delete={permission?.delete}
                enable_double_click={enable_double_click}
                enable_single_click={enable_single_click}
            />


        </Box>
    )
}