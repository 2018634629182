import { makeStyles } from "@mui/styles";
import { FontFamilySwitch, remCalc } from "../../utils";

export const InspectionMappingStyles = makeStyles((theme) => ({
  root: {
    padding: "20px",
    marginTop: "12px",
    backgroundColor: theme.palette.background.tertiary1,
    border: `1px solid ${theme.palette.border.secondary}`,
    boxShadow: "0px 0px 16px #00000014",
    borderRadius: theme.palette.borderRadius,
  },
  content: {
    height: `calc(100vh - 100px)`,
    overflow: "auto"
  },
  viewCard: {
    // margin: "10px 20px 20px 20px"
  },
  drawerTitle:{
    fontSize:remCalc(16),
    fontFamily: FontFamilySwitch().extraBold,
    color:theme.typography.color.primary
  },
  fixbtn:{
    position:"absolute",
    bottom:0,
    left:0,
    right:0,
    padding:"16px"
  },
}))