import { withTranslation } from "react-i18next";
import { TableWithPagination } from "../../../components";
import { InspectionItemsHeading, InspectionItemsPath, InspectionItemsType } from "../../../utils/insepectionMaster";

const Table = ({
    is_loading = false,
    handleIcon = () => false,
    list = [],
    handlePagination = () => false,
    handleChangeLimit = () => false,
    page = "",
    limit = "",
    t = () => false,
}) => {

    const heading = InspectionItemsHeading({t})

    return (

        <TableWithPagination
            is_loading={is_loading}
            heading={heading}
            rows={list?.list}
            path={InspectionItemsPath}
            dataType={InspectionItemsType}
            showpagination={true}
            count="2"
            showpdfbtn={false}
            showexcelbtn={false}
            showSearch={false}
            tableType="no-side"
            handleIcon={handleIcon}
            view={true}
            handlePagination={handlePagination}
            handleChangeLimit={handleChangeLimit}
            totalRowsCount={list?.count}
            page={page}
            limit={limit}
            height={`calc(100vh - 300px)`}
            noDataText={t("No Items Found")}
        />
    )
}
export default withTranslation("inspectionItemmaster")(Table); 