import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import Exporting from "highcharts/modules/exporting";
import { FontFamilySwitch } from "../../../utils";

Exporting(Highcharts);

export const HighChartsPie = ({
  height = 300,
  data = [],
  graphTitle = "",
  subtitle = "",
  isShowLegend = false,
  isTotal = false,
}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const chart = Highcharts.chart(chartRef.current, {
        colors: [
          "#278ECF",
          "#48D762",
          "#FFCA1F",
          "#FF9416",
          "#D42AE8",
          "#535AD7",
          "#FF402C",
          "#83BFFF",
          "#6ED88F",
          "#FFE366",
        ],
        exporting: {
          enabled: true, // Enable the exporting module
        },
        chart: {
          type: "pie",
          events: {
            render: function () {
              if (isTotal) {
                const total = this.series[0].data.reduce(
                  (total, point) => total + point.y,
                  0
                );
                if (this.totalLabel) {
                  this.totalLabel.destroy();
                }

                const isFullscreen = document.fullscreenElement != null;
                const labelYPosition = isFullscreen
                  ? this.plotHeight / 2
                  : this.plotHeight / 1.4;

                const label = this.renderer
                  .text(total, this.plotWidth / 2, labelYPosition)
                  .css({
                    fontSize: "30px",
                    fontFamily: FontFamilySwitch().bold,
                    textAlign: "center",
                  })
                  .attr({
                    align: "center",
                    verticalAlign: "middle",
                  })
                  .add();

                this.totalLabel = label;
              }
            },
          },
        },
        credits: {
          enabled: false,
        },
        title: {
          text: graphTitle,
          align: "left",
          style: {
            color: "#091B29",
            fontSize: "1rem",
            fontFamily: FontFamilySwitch().extraBold,
            marginInlineStart: "8px",
          },
        },
        subtitle: {
          text: subtitle,
        },
        plotOptions: {
          pie: {
            innerSize: isTotal ? "80%" : "0%",
          },
          series: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: isShowLegend,
            },
          },
        },
      tooltip: {
        useHTML: true,
        formatter: function () {
          let number = this.y;
          let metric = '';
          if (number >= 1.0e+12) {
            number = number / 1.0e+12;
            metric = 'Tn';
          } else if (number >= 1.0e+9) {
            number = number / 1.0e+9;
            metric = 'Bn';
          } else if (number >= 1.0e+6) {
            number = number / 1.0e+6;
            metric = 'Mn';
          } else if (number >= 1.0e+3) {
            number = number / 1.0e+3;
            metric = 'k';
          }
          number = Math.round(number);
          return '<b>' + this.point.name + '</b>: ' + number + metric;
        }
      },
        series: [
          {
            name: "",
            colorByPoint: true,
            data: data.map((item) => ({ name: item.name, y: parseFloat(item.count) })),
          },
        ],
      });

      const handleFullscreenChange = () => {
        chart.reflow();
      };
      document.addEventListener("fullscreenchange", handleFullscreenChange);

      return () => {
        document.removeEventListener(
          "fullscreenchange",
          handleFullscreenChange
        );
      };
    }
  }, [data, graphTitle, subtitle, isShowLegend, isTotal]);

  return <div ref={chartRef} style={{ width: "100%", height: height }} />;
};
