import React from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { Typography, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../utils";

const useStyles = makeStyles((theme, props) => ({
  root: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "-20px",
    "& svg": {
      // borderRadius: "50%",
      width: "100%",
      minWidth: (props) => props?.height ?? "165px !important",
      height: (props) => props?.height ?? "165px !important",
      position: "relative",
      cursor: "pointer",
      [theme.breakpoints.up("sm")]: {
        width: "100% !important",
        margin: "0 auto",
      },
      "& .recharts-wrapper": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
  center: {
    textAlign: "center",
    position: "absolute",
    left: "0px",
    right: "0px",
    top: "36%",
    zIndex: 1,
    padding: "12px",
    [theme.breakpoints.down("sm")]: {
      left: "0px",
      right: "0px",
      top: "36%",
    },
  },
  chart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize:"0.75rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().semiBold,
  },
  count: {
    fontSize:"1.125rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
  },
  tooltip: {
    backgroundColor: "#F2F4F7",
    border: "1px solid #E4E8EE",
    display: "flex",
    alignItems: "center",
    padding: "4px 7px",
    zIndex: 999,
    borderRadius: "4px",
    position: "relative",
  },
  name: {
    color: "#7C8594",
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  value: {
    color: "#4E5A6B",
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().semiBold,
    marginRight: "8px",
  },
  dot: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    marginRight: "8px",
  },
  // arrow: {
  //   width: "0px",
  //   height: "0px",
  //   borderLeft:'10px solid transparent',
  //   borderRight:'10px solid transparent',
  //   borderTop:'15px solid #F2F4F7'
  // },
  // arrowParent: {
  //   position: "absolute",
  //   bottom: "-12px",
  //   textAlign:'center',
  //   left:0,
  //   display:'flex',
  //   justifyContent:'center',
  //   alignItems:'center',
  //   width:'100%'
  // },
}));

// tool tip
const Tip = ({ setShowTooltip, fill, ...rest }) => {
  const classes = useStyles();
  const [payload, setPayload] = React.useState(rest?.payload);

  React.useEffect(() => {
    rest?.payload?.length && setPayload(rest?.payload);
  }, [rest?.payload]);

  return payload?.length ? (
    <div
      onMouseLeave={() => setShowTooltip(false)}
      onMouseMove={(e) => e.stopPropagation()}
      className={classes.tooltip}
    >
      <Box
        className={classes.dot}
        style={{ backgroundColor: fill ?? "#58D0E0" }}
      />
      <Typography className={classes.value}>
        {payload?.[0]?.payload?.bar}
      </Typography>
      <Typography className={classes.name}>
        {payload?.[0]?.payload?.name}
      </Typography>
    </div>
  ) : null;
};
export const BarWithLine = (props) => {
  const [showTooltip, setShowTooltip] = React.useState(false);
  return (
    <ResponsiveContainer width={"100%"} height={"100%"}>
      <ComposedChart
        width={"100%"}
        height={"100%"}
        data={props?.data}
        onMouseLeave={() => setShowTooltip(false)}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey={props?.XAxisKey ?? "name"} />
        <YAxis />
        {showTooltip && (
          <Tooltip
            // Anymation is a bit weird when the tooltip shows up after hidding
            isAnimationActive={false}
            // Send props down to get the payload
            content={
              <Tip
                setShowTooltip={setShowTooltip}
                fill={props?.fill ?? "#58D0E0"}
              />
            }
            // We need this to manage visibility ourselves
            wrapperStyle={{ visibility: "visible", pointerEvents: "auto" }}
          />
        )}
        {props?.Legend && <Legend />}
        <Bar
          dataKey={props?.barKey ?? "bar"}
          barSize={20}
          fill="#58D0E0"
          onMouseEnter={() => setShowTooltip(true)}
          animationDuration={false}
        />
        <Line
          type="monotone"
          dataKey={props?.LineKey ?? "line"}
          stroke="#ff7300"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
