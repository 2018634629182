import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../utils";

export const reservationStyle = makeStyles((theme) => ({
  title: {
    color: theme.typography?.color?.secondary,
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().bold,
  },
  viewHistory: {
    color: theme.typography?.status?.primary,
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().bold,
  },
  quationName: {
    color: theme.typography?.color?.secondary,
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().bold,
  },
  badge: {
    backgroundColor: theme.palette?.success?.main,
    borderRadius: "2px",
    padding: "2px 6px ",
    marginInlineStart: "10px",
    color: "white",
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().bold,
  },
  subject: {
    color: theme.palette?.background?.primary,
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  contant: {
    color: theme.typography?.color?.primary,
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  contant1: {
    color: theme.typography?.color?.tertiary,
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  quatationCard: {
    border: `1px solid ${theme.palette?.border?.secondary}`,
    borderRadius: "4px",
    padding: "10px",
  },
  main: {
    position: "relative",
    height: `calc(100vh - 218px)`,
    overflow:'auto',
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  container: {
    padding: "16px 0px 16px 16px",
  },
  reserve: {
    padding: "8px",
    boxShadow: "none",
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "white",
    fontFamily: FontFamilySwitch().semiBold,
    // position: "absolute",
    // width: "92%",
    margin:"12px auto 0px",
    bottom: "16px",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  unitTitle: {
    color: theme.typography?.color?.secondary,
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().bold,
  },
  unitList: {
      backgroundColor: theme.palette?.background?.unitBackground,
      padding: "16px 18px 0px 18px",
      overflow: "auto",
      height: `calc(100vh - 147px)`,
  },
}));
