import React from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Subheader } from "../../../components";
import { AuthContext, BackdropContext } from "../../../contexts";
import { NetWorkCallMethods, accessCheckRender, getRoutePermissionNew } from "../../../utils";
import { UnitDetails } from "./unitView/unitDetails";
import { withTranslation } from "react-i18next";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
import { BackendRoutes } from "../../../router/routes";

const PricingTableUnitView = ({
    t
}) => {
    const [details, setDetails] = React.useState({
        data: {},
        assets: [],
        asset: []
    })

    const search = useLocation().search;
    const unitID = new URLSearchParams(search).get("unitID");
    const navigate = useNavigate()
    const backdrop = React.useContext(BackdropContext);
    const auth = React.useContext(AuthContext)
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: true,
                    message: "",
                });
                getUnitDetails()
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const getUnitDetails = () => {
        const payload = { unitId: unitID }
        NetworkCall(
            `${config.api_url}${BackendRoutes?.queries_unit_get}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((response) => {
            backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "",
            });
            setDetails({
                data: response.data?.data?.unit[0],
                assets: response.data?.data?.assets,
                asset: response.data?.data?.asset
            })
        }).catch((err) => {
            console.log(err)
        });
    }

    const headerpage = [
        {
            id: "1",
            label: t("Unit")
        }
    ]

    const render = () => {
        return <>
            <Subheader title={details?.data?.property?.name} pageoptions={headerpage} goBack={() => navigate(-1)} select isReadOnly={true} value={details?.data?.property?.company} />
            <UnitDetails t={t} data={details?.data} assets={details?.asset} permission={permission} />
        </>
    }

    return (
        <>
            {accessCheckRender(render, permission)}
        </>
    )
}
export default withTranslation("pricingComponent")(PricingTableUnitView)
