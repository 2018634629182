import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const NoInspectionStyles = makeStyles((theme) => ({
    noInspection: {
        position: "absolute",
        top: "50%",
        left: "65%",
        transform: "translate(-0%,-50%)"
    },
    noInspection2: {
        position: "absolute",
        top: "50%",
        right: "65%",
        transform: "translate(-0%,-50%)"
    },
    message: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        marginTop: "10px",
        textAlign:"center"
    },
    note: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        marginTop: "5px",
        color: theme.typography.color.primary
    }
}))