/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import CloseIcon from "@mui/icons-material/Close";
import PhoneIcon from "@mui/icons-material/Phone";
import {
  Avatar,
  Box, Button, CircularProgress, Dialog, Divider, Grid, IconButton, Menu,
  MenuItem, Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {jwtDecode} from 'jwt-decode';
import moment from "moment-timezone";
import React, { useCallback, useState } from "react";
import { useNavigate } from 'react-router-dom';
import ImageViewer from "react-simple-image-viewer";
import { DialogBox, DrawerComponent, NewDatePicker, SearchFilter, Subheader, TableWithPagination, TextBox } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, getCompanyOption, LocalStorageKeys, NetWorkCallMethods, Requestheading, Requestpath, useWindowDimensions, FontFamilySwitch } from "../../utils";
import FilterComponent from "./filter";
import { General } from "./generalrequest/genearlrequest";
import { MainRequest } from "./maintancerequest/main";

const useStyles = makeStyles((theme) => ({
  dropdown: {
    margin: "10px 0px",
  },
  closeMain: {
    padding: "24px",
  },
  root: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    margin: "16px",
    overflow: "auto",
    padding: '16px'
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    float: "right",
    "&:hover": {
      backgroundColor: "#5078E1",
    },
  },

  btn2: {
    borderRadius: theme.palette.borderRadius,
    color: theme.palette.secondary.main,
  },
  subText: {
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary,
  },
  img: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "10px",
  },
  active: {
    padding: "10px 14px",
    textAlign: "center",
    borderRadius: "10px",
    color: "white",
    fontSize:"0.875rem",
    backgroundColor: "#5078E1",
    cursor: "pointer",
  },
  disactive: {
    padding: "10px 14px",
    textAlign: "center",
    borderRadius: "10px",
    fontSize:"0.875rem",
    cursor: "pointer",
    border: "1px solid #E4E8EE",
  },
  Label: {
    color: theme.typography.color.tertiary,
    fontSize:"0.75rem",
  },
  imgContainer: {
    width: "100%",
    maxWidth: "150px",
    margin: "0px 10px",
    borderRadius: theme.palette.borderRadius,
    display: "flex",
  },
  DialogCloseIcon: {
    color: "#7C8594",
    fontSize: "22px",
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    marginBottom: "12px",
    padding: "14px 18px",
    alignItems: "center",
    width: "100%",
  },
  bottomTitle: {
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: "#091B29",
    fontWeight: "600px",
  },
  quotationsummaryquest: {
    fontSize:"0.75rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().semiBold,
    fontWeight: "600",
  },
  waterLeakageIcons: {
    color: "#071741",
    fontFamily: FontFamilySwitch().semiBold,
    fontWeight: "600",
    marginRight: "4px",
  },

  quotationsummaryans: {
    fontSize:"0.875rem",
    color: "#000",
    fontFamily: FontFamilySwitch().semiBold,
    fontWeight: "600",
    marginBottom: "3px",
  },

  title: {
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: "#091B29",
    fontWeight: "600",
  },
  title2: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: "#98A0AC",
    fontWeight: "600",
  },
  contactDetailsName: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: "#091B29",
    fontWeight: "600",
  },
  menu: {
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: "#071741",
  },
  drawerwidth: {
    width: "380px",
    [theme.breakpoints.down("sm")]: {
      width: "318px",
    },
  },
  Header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "14px",
    borderBottom: "1px solid #00000012",
    position: "absolute",
    width: "100%",
    top: "0",
    height: "60px",
    padding: "16px 16px 16px",
    justifyContent: "left",
    backgroundColor:"#F2F4F7",
    zIndex: "1",
    "& .MuiTypography-root": {
      fontSize:"1rem",
      fontWeight: "bold",
      fontFamily: FontFamilySwitch().bold,
    },
  },
  closeIcondrawermap: {
    right: "400px",
    position: "fixed",
    display: "flex",
    color: theme.palette.primary.contrastText,
    cursor: "pointer",
    fontSize: 28,
    marginTop: 8,
    "& .MuiSvgIcon-root": {
      fontSize: "35px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "28px !important",
      },
    },
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      right: "11px",
      color: theme.palette.primary.dark,
      top: "0px",
      fontSize:"0.875rem",
    },
  },
  createnewsection: {
    padding: "10px 15px 65px",
    height: "100vh",
    overflow: "auto",
    paddingTop: "63px",
  },
  btnparent: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    padding: "10px 16px",
    backgroundColor: "#fff",
    borderTop: "1px solid #00000012",
    "& .MuiButton-root": {
      width: "100%",
    },
  },
  Apply: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  clearall: {
    backgroundColor: "#fff",
    border: "1px solid #E4E8EE",
    color: "#091B29",
    "&:hover": {
      backgroundColor: "#fff",
      border: "1px solid #E4E8EE",
    },
  },
  contactDetails: {
    backgroundColor: "#FFFFFF",
    borderRadius: "5px",
    padding: "5px 0px 15px",
    alignItems: "center",
  },
  contactDetailsNumbers: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: "#7C8594",
    fontWeight: "600",
  },
  phoneIconBtn: {
    backgroundColor: "#E4E8EE",
    padding: "10px",
    color: "#7C8594",
    border: "1px solid #E4E8EE",
    borderRadius: "50px",
    float: "right",
    fontSize:"0.75rem",
    "&:hover": {
      backgroundColor: "#E4E8EE",
    },
  },
  phoneIcon: {
    fontSize:"1.125rem",
    "&:hover": {
      fontSize:"1.125rem",
    },
  },
  homeService: {
    padding: "2px 2px",
    backgroundColor: "#5078E11E",
    color: "#091B29",
    fontSize:"0.75rem",
    fontWeight: "600",
    fontFamily: FontFamilySwitch().regular,
    borderRadius: theme.palette.borderRadius,
    margin: "0px",
    lineheight: "1.5",
    textAlign: "center",
  },
  dialogStyle: {
    "& .MuiPaper-root": {
      minWidth: "700px",
      width: "100% !important",
      borderRadius: theme.palette.borderRadius,
      // minHeight: "487px",
    },
  },
  createnewsectionlog: {
    padding: "20px",
  },
  connecter: {
    marginTop: "-21px",
  },
  top: {
    marginTop: "-45px",
    "& li": {
      "&.css-ha3bif-MuiTimelineItem-root": {
        "&:before": {
          padding: "0px",
          display: "none",
        },
      },
    },
  },
  top1: {
    "& ul": {
      marginTop: "0px",
    },
    "& li": {
      "&.css-ha3bif-MuiTimelineItem-root": {
        "&:before": {
          padding: "0px",
          display: "none",
        },
      },
    },
  },
  labelTittle: {
    color: "#091B29",
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().bold,
  },
  description: {
    color: "#7C8594",
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  BorderLine: {
    borderRight: "1px solid #E4E8EE",
  },
  service: {
    backgroundColor: "#5078E11E",
    borderRadius: "5px",
    padding: "3px 10px",
    cursor: "pointer",
  },
  servicetittle: {
    fontSize:"0.75rem",
    color: "#091B29",
    fontWeight: 600,
  },
  buttonParent: {
    padding: '20px',
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center'
  },
  header: {
    border: "1px solid #E4E8EE",
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().extraBold,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "24px",
  },
  closeDialog: {
    "& .MuiPaper-root": {
      maxWidth: "400px !important",
      width: "100% !important",
      borderRadius: theme.palette.borderRadius,
    },
  },
  headers: {
    marginBottom: '5px',
    marginTop: '20px'
  },
  imagePreview: {
    display: 'flex',
    alignItems: 'center',
    overflowX: 'scroll'
  }
}));
const initial1 = {
  Type: ["General"],
  status: [],
  unit: [],
  propperty: "",
  company: "",
  Maintanance: [],
  error: {
    Type: "",
    status: "",
    unit: "",
    propperty: "",
    company: "",
    Maintanance: [],
  },
};

const initials = {
  name: "",
  mobile: "",
  alternative: "",
  subject: "",
  description: "",
  problemsince: "",
  raisedon: "",
  images: "",
  catagory: "",
  subcatagory: "",
  unit: "",
  status: "",
  prefferedtime: new Date().toISOString(),
};
// company drop down option
export const Request = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const size = useWindowDimensions();
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertOpen1, setAlertOpen1] = useState(false);
  const [filter, setFilter] = useState(false);
  const [filterdata, setFilterdata] = useState(initial1);
  // const [offset, setOffset] = React.useState("0");
  const [list, setList] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [searchdata, setsearchdata] = React.useState("");
  const [total, setTotal] = React.useState("");
  const [property, setproperty] = React.useState([]);
  const [unit, setUnit] = React.useState([]);
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const [contact, setContact] = React.useState([]);
  const [details, setDetails] = React.useState({});
  const [datas, setDatas] = useState(initials);
  const [request, setRequest] = useState("");
  const [isEdit, setisEdit] = useState(false);
  const [requestclose, setRequestclose] = useState(false);
  const [requestID, setrequestID] = useState("");
  const [change, setChange] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  const [closed, setClosed] = React.useState(null);
  const [filterTrue, setfilterTrue] = React.useState(false);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [imagesListForView, setImagesListForView] = React.useState([]);
  const [createdDate, setCreatedDate] = React.useState("");
  const [closedata, setclosedata] = useState({
    date: new Date(),
    remarks: "",
    error: {
      date: "",
      remarks: "",
    },
  });
  const [selectedCompany, setSelectedCompany] = React.useState(null);
  const [companyList, setCompanyList] = React.useState([])
  const updateState = (key, value) => {
    let error = closedata.error;
    error[key] = "";
    setclosedata({ ...closedata, [key]: value, error });
  };

  const closeRequest = () => {
    setclosedata({
      date: "",
      remarks: "",
      error: {
        date: "",
        remarks: "",
      },
    });
    setRequestclose(!requestclose);
  };

  const handleIcon = (type, value) => {
    getDetails(type, value);
  };

  const getDetails = (type, value) => {
    return false
  };

  const CloseDialog = (v) => {
    if (v === "open") {
      setOpen(false);
      closeRequest();
    } else {
      setOpen(false);

    }
  };
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    if (filterTrue === true) {
      getTable(offset, limit, searchdata, "filter", selectedCompany);
    } else {
      getTable(offset, limit, searchdata, "key", selectedCompany);
    }
  };

  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    if (filterTrue === true) {
      getTable(0, value, searchdata, "filter", selectedCompany);
    } else {
      getTable(0, value, searchdata, "key", selectedCompany);
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openOption = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type) => {
    if (type === "General") {
      setAnchorEl(null);
      AlertOpen1();
    }
    if (type === "maintance") {
      setAnchorEl(null);
      AlertOpen();
    }
    if (type === "type") {
      setAnchorEl(null);
    }
  };
  const AlertOpen = () => {
    setAlertOpen(true);
  };
  const AlertOpen1 = () => {
    setAlertOpen1(true);
  };
  const AlertClose = () => {
    setisEdit(false);
    setAlertOpen(false);
  };
  const AlertClose1 = () => {
    setisEdit(false);
    setAlertOpen1(false);
  };
  const OpenDrawer = () => {
    setFilter(!filter);
  };
  const upDateFilter = (key, value) => {
    let error = filterdata.error;
    error[key] = "";


    if (key === "Type") {
      let Type = [];
      Type.push(value);
      setFilterdata({ ...filterdata, [key]: Type });
    } else if (key === "status") {
      let status = [];
      status.push(value);
      setFilterdata({ ...filterdata, [key]: status });
    } else if (key === "unit") {
      let unit = [];
      unit.push(value);
      setFilterdata({ ...filterdata, [key]: unit });
    } else if (key === "Maintanance") {
      let Maintanance = [];
      Maintanance.push(value);
      setFilterdata({ ...filterdata, [key]: Maintanance });
    } else {
      setFilterdata({ ...filterdata, [key]: value, error });
    }
    if (key === "propperty") {
      selectunit(value);
      setFilterdata({ ...filterdata, propperty: value, unit: [] });
    }
  };
  const getProperty = (v) => {
    return false
  };
  const clearAll = () => {
    setFilterdata({
      Type: [],
      status: [],
      unit: [],
      propperty: "",
      company: "",
      Maintanance: [],
      error: {
        Type: "",
        status: "",
        unit: "",
        propperty: "",
        company: "",
        Maintanance: [],
      },
    });
  };
  const selectunit = (v) => {
    return false
  };
  const getTable = (offset, limit, value, key, company) => {
    setloading(true);
    setsearchdata(value);
    if (key === "filter") {

      setFilter(false);
      const requestPayload = {
        request_units:
          filterdata?.unit?.[0]?.value?.length > 0
            ? [filterdata?.unit?.[0]?.value]
            : undefined,
        request_type:
          filterdata.Type?.length > 0 ? filterdata?.Type : undefined,
        request_status:
          filterdata?.status?.length > 0 ? filterdata?.status : undefined,
        search: value?.length > 0 ? value : undefined,
        company_id: filterdata?.company?.label?.length > 0 ? [filterdata?.company?.value] : [company?.value],
        offset: offset,
        limit: limit,
      };
      NetworkCall(
        `${config.api_url}/request`,
        NetWorkCallMethods.post,
        requestPayload,
        null,
        true,
        false
      )
        .then((response) => {
          setfilterTrue(true);
          setloading(false);
          setTotal(response?.data?.totalCount);
          let arry = [];
          if (response?.data?.data && response?.data?.data.length > 0) {
            response?.data?.data?.map((val, index) => {
              let obj = {
                sno: offset + index + 1,
                index: offset + index + 1,
                requestName: val?.subject ?? "-",
                request_no: val?.request_no ?? "-",
                category: val?.request_type ?? "-",
                raisedDate: val?.raised_on ?? "-",
                unit: `${val?.units?.unit_no ?? ""}  ${val?.units?.unit_no?.length > 0 ? "-" : ""} ${val?.units?.name}` ?? "",
                status: val?.request_status ?? "-",
                icon: "more_5",
                ...val,
              };
              arry.push(obj);
            });
          }
          setList(arry);
          setFilter(false);
          backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
        })
        .catch((error) => {
          setloading(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.info,
            msg: "Some Thing Went Wrong",
          });
        });
    } else {
      const requestPayload = {
        tenantId: `${config.tenantId}`,
        company_id: [company?.value],
        request_type: [
          "Maintenance",
          "General"
        ],
        search: value?.length > 0 ? value : undefined,
        offset: offset,
        limit: limit,
      };
      NetworkCall(
        `${config.api_url}/request`,
        NetWorkCallMethods.post,
        requestPayload,
        null,
        true,
        false
      )
        .then((response) => {
          setloading(false);
          setTotal(response?.data?.totalCount);
          let arry = [];
          if (response?.data?.data && response?.data?.data.length > 0) {
            response?.data?.data?.map((val, index) => {
              let obj = {
                sno: offset + index + 1,
                index: offset + index + 1,
                requestName: val?.subject ?? "-",
                request_no: val?.request_no ?? "-",
                category: val?.request_type ?? "-",
                raisedDate: val?.raised_on ?? "-",
                unit: `${val?.units?.unit_no ?? ""} ${val?.units?.unit_no?.length > 0 ? "-" : ""} ${val?.units?.name ?? ""}` ?? "",
                status: val?.request_status ?? "-",
                icon: "more_5",
                ...val,
              };
              arry.push(obj);
            });
          }
          setList(arry);
        })
        .catch((error) => {
          setloading(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something went wrong please try again",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center
          });

        });
    }
  };
  const contactshow = () => {
    return false
  };
  const isEditfalse = () => {
    setisEdit(false);
  };
  const isIamValideTostep = () => {
    let isValid = true;
    let error = closedata.error;
    if (closedata?.date?.length === 0) {
      isValid = false;
      error.date = "Problem Since is Required";
    }
    if (closedata?.remarks?.length === 0) {
      isValid = false;
      error.remarks = "Problem Title is Required";
    }
    if (closedata?.date?.length === 0 || closedata?.remarks?.length === 0
    ) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all mandatory field",
      });
    }
    setclosedata({ ...closedata, error });

    return isValid;
  }
  const cancelPass = (val) => {
    return false
  };
  const changeFunction = () => {
    setChange(true);
  };
  React.useEffect(() => {
    if (localStorage.getItem("authToken")) {
      let company = getCompanyOption(backdrop, auth, alert)
      if (company) {
        setCompanyList(company?.list)
        setSelectedCompany(company?.selected)
        if (company?.list?.length > 0) {
          getProperty(company?.selected);
          getTable(0, 10, searchdata, "key", company?.selected);
        }
        contactshow();
      }
    } else {
      navigate(Routes.login);
    }
    // eslint-disable-next-line
  }, [auth]);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  const track = [
    {
      date: datas?.table?.[0]?.status?.[0]?.created_at,
      label: "Open"
    },
    {
      date: datas?.table?.[0]?.status?.[0]?.closed_on,
      label: "Closed"
    },
  ]
  return (
    <>
      {/* sub nav bar */}
      <Subheader hideBackButton={true} title={`Requests (${total ?? 0
        })`} select options={companyList} value={selectedCompany}
        goBack={() => {
          navigate(Routes.home)
        }}
        onchange={(e) => {
          setSelectedCompany(e)
          getTable(0, 10, searchdata, "key", e);
          setFilterdata({ ...filterdata, propperty: "", company: e?.value, unit: [] });
          getProperty(e);
        }

        } />
      <div className={classes.root}>
        <Grid container className={classes.content} spacing={2}>
          <Grid item xs={4}>
            <SearchFilter
              value={searchdata}
              handleChange={(value) => getTable(0, 10, value, "key", selectedCompany)}
              placeholder="Search by ID"
            />
          </Grid>
          <Grid item xs={8} textAlign={"right"}>
            <Box display={"flex"} sx={{ float: "right" }}>
              <Box>
                <IconButton
                  size="small"
                  className={classes.img}
                  onClick={OpenDrawer}
                >
                  <img src="/images/filter.svg" alt="filter" />
                </IconButton>
              </Box>
              &nbsp; &nbsp; &nbsp;
              <Box>
                <Button variant="contained" onClick={handleClick}>
                  Add Request
                </Button>
              </Box>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openOption}
                onClose={() => handleClose("type")}
                className={classes.menuParent}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => handleClose("General")}
                  className={classes.menu}
                >
                  General
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => handleClose("maintance")}
                  className={classes.menu}
                >
                  Maintenance
                </MenuItem>
              </Menu>
              {/* General request popup */}
              <DialogBox
                maxWidth={'sm'}
                open={alertOpen1}
                onClose={AlertClose1}
                header={"New General Request"}
                component={
                  <>
                    <General

                      getTable={getTable}
                      AlertOpen1={AlertOpen1}
                      details={details}
                      isEdit={isEdit}
                      isEditfalse={isEditfalse}
                      AlertClose={AlertClose1}
                      loading={loading}
                      selectedCompany={selectedCompany}
                    />
                  </>
                }
              />
              {/* maintance request popup */}
              <DialogBox
                maxWidth={'sm'}
                open={alertOpen}
                onClose={AlertClose}
                header={"New Maintenance Request"}
                component={
                  <>
                    <MainRequest
                      onClose={AlertClose}
                      getTable={getTable}
                      contact={contact}
                      AlertOpen={AlertOpen}
                      isEdit={isEdit}
                      details={details}
                      isEditfalse={isEditfalse}
                      alertOpen={alertOpen}
                      AlertClose={AlertClose}
                      change={change}
                      changeFunction={changeFunction}
                      loading={loading}
                      selectedCompany={selectedCompany}
                    />
                  </>
                }
              />
            </Box>
          </Grid>
          <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '24px' }}>
            {
              loading ?
                <div style={{ textAlign: 'center', marginTop: '10%' }}>
                  <CircularProgress color="primary" />
                </div> :
                <>
                  <Grid item xs={12} marginTop="20px" borderTop="2px solid #E4E8EE">
                    <TableWithPagination
                      heading={Requestheading}
                      rows={list}
                      path={Requestpath}
                      showpagination={true}
                      onClick={() => false}
                      handleIcon={handleIcon}
                      handlePagination={handlePagination}
                      handleChangeLimit={handleChangeLimit}
                      showpdfbtn={false}
                      showexcelbtn={false}
                      showSearch={false}
                      page={page}
                      totalRowsCount={total}
                      limit={limit}
                      dataType={[
                        { type: ["text"], name: "sno" },
                        { type: ["text"], name: "requestName" },
                        { type: ["text"], name: "request_no" },
                        { type: ["text"], name: "category" },
                        { type: ["text"], name: "raisedDate" },
                        { type: ["text"], name: "unit" },
                        { type: ["status"], name: "status" },
                        { type: ["more_5"], icon: "icon" },
                      ]}
                      height={'calc(100vh - 350px)'}
                      view={true}
                      edit={true}
                      delete={true} />
                  </Grid></>
            }
          </Grid>
        </Grid>

        {/* View Dialog */}
        <Dialog
          open={open}
          className={classes.dialogStyle}
          maxWidth={'sm'}
        >
          <>
            <Grid container className={classes.titleroot}>
              <Grid item xs={11} sm={11} md={11} lg={11}>
                <Typography className={classes.bottomTitle}>{request}</Typography>
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} textAlign="right">
                <IconButton onClick={() => CloseDialog("close")} size="small">
                  <CloseIcon className={classes.DialogCloseIcon} />
                </IconButton>
              </Grid>
            </Grid>
            <div style={{ height: size?.height - 300, overflow: "auto" }}>
              <Grid container padding="0px 20px">
                <Grid
                  item
                  xs={7}
                  md={7}
                  sm={7}
                  lg={7}
                  borderRight="1px solid #E4E8EE"
                >
                  <div>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <Box className={classes.service}>
                        <Typography className={classes.servicetittle}>
                          {datas?.table?.[0]?.catagory?.type}
                        </Typography>
                      </Box>
                      <Box
                        className={classes.service}
                        style={{ marginLeft: "10px" }}
                      >
                        <Typography className={classes.servicetittle}>
                          {datas?.table?.[0]?.subcatagory?.type}
                        </Typography>
                      </Box>
                    </Box>
                    <Grid marginTop="8px">
                      <Typography className={classes.title}>
                        {datas?.table?.[0]?.subject}
                      </Typography>
                      <Typography className={classes.title2}>
                        {datas?.table?.[0]?.description}
                      </Typography>
                    </Grid>
                    <Grid container textAlign="left" className={classes.headers}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container spacing={3}>
                          {
                            datas?.table?.[0]?.problemsince?.length > 0 &&
                            <Grid item xs={6} sm={6} lg={6} md={6} >
                              <Typography className={classes.quotationsummaryans}>
                                Problem Since
                              </Typography>
                              <Box
                                style={{ display: "flex", alignItems: "center" }}
                              >
                                <Box className={classes.waterLeakageIcons}>
                                  <img
                                    src="images/icons8-calendar (5).svg"
                                    alt="no_img"
                                  ></img>
                                </Box>

                                <Box className={classes.quotationsummaryquest}>
                                  {moment(datas?.table?.[0]?.problemsince)
                                    .tz(moment.tz.guess())
                                    .format("YYYY-MM-DD hh:mm")}
                                </Box>
                              </Box>
                            </Grid>
                          }


                          {
                            datas?.table?.[0]?.unit?.[0]?.unit?.name?.length > 0 &&
                            <Grid item xs={6} sm={6} lg={6} md={6}>
                              <Typography className={classes.quotationsummaryans}>Unit</Typography>
                              <Box
                                style={{ display: "flex", alignItems: "center" }}
                              >
                                <Box className={classes.waterLeakageIcons}>
                                  <img
                                    src="images/Group 619.svg"
                                    alt="no_img"
                                  ></img>
                                </Box>
                                <Box className={classes.quotationsummaryquest}>
                                  {datas?.table?.[0]?.unit?.[0]?.unit?.name}
                                </Box>
                              </Box>
                            </Grid>
                          }

                          {
                            datas?.table?.[0]?.raisedon?.length > 0 &&
                            <Grid item xs={6} sm={6} lg={6} md={6}>
                              <Typography className={classes.quotationsummaryans}>
                                Raised On
                              </Typography>
                              <Box
                                style={{ display: "flex", alignItems: "center" }}
                              >
                                <Box className={classes.waterLeakageIcons}>
                                  <img
                                    src="images/icons8-calendar (5).svg"
                                    alt="no_img"
                                  ></img>
                                </Box>
                                <Box className={classes.quotationsummaryquest}>
                                  {moment(datas?.table?.[0]?.raisedon)
                                    .tz(moment.tz.guess())
                                    .format("YYYY-MM-DD")}
                                </Box>
                              </Box>
                            </Grid>
                          }

                          {datas?.table?.[0]?.prefferedtime?.length > 0 && (
                            <Grid item xs={6} sm={6} lg={6} md={6} >
                              <Typography className={classes.quotationsummaryans}>
                                Preferred Visit Date And Time
                              </Typography>
                              <Box
                                style={{ display: "flex", alignItems: "center" }}
                              >
                                <Box className={classes.waterLeakageIcons}>
                                  <img
                                    src="images/icons8-schedule (1).svg"
                                    alt="no_img"
                                  ></img>
                                </Box>
                                <Box className={classes.quotationsummaryquest}>
                                  {moment(datas?.table?.[0]?.prefferedtime)
                                    .tz(moment.tz.guess())
                                    .format("YYYY-MM-DD hh:mm")}
                                </Box>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    {datas?.images?.length > 0 && (
                      <Grid container width="80%" marginTop="10px">
                        <Typography
                          className={classes.quotationsummaryans}
                          style={{ marginTop: "10px" }}
                        >
                          Images
                        </Typography>

                        <Grid container spacing={2} marginTop="10px">
                          {datas?.images?.map((x, index) => {
                            return (
                              <Grid item xs={4}>
                                <Box className={classes.imgContainer}>
                                  <div style={{ position: "relative" }}>
                                    <img
                                      type="file"
                                      accept="image/*"
                                      multiple
                                      alt="Not Found"
                                      width={"250px"}
                                      onClick={() => openImageViewer(index)}
                                      src={x.url}
                                      style={{
                                        objectFit: "cover",
                                        height: "150px",
                                        width: "100%",
                                        borderRadius: "12px",
                                        cursor: "pointer",
                                      }}
                                    />
                                    {isViewerOpen && (
                                      <ImageViewer
                                        src={imagesListForView}
                                        currentIndex={currentImage}
                                        onClose={closeImageViewer}
                                        disableScroll={false}
                                        backgroundStyle={{
                                          backgroundColor: "rgba(0,0,0,0.9)",
                                        }}
                                        closeOnClickOutside={true}
                                      />
                                    )}
                                  </div>
                                </Box>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                    )}

                    <Grid container marginTop="10px">
                      {datas?.table?.[0]?.name?.length > 0 ||
                        datas?.table?.[0]?.mobile?.length > 0 ||
                        datas?.table?.[0]?.alternative?.length > 0 ? (
                        <>
                          <Typography
                            className={classes.quotationsummaryans}
                            style={{ marginTop: "10px" }}
                          >
                            Contact Details
                          </Typography>
                          <Grid container spacing={3} className={classes.contactDetails}>
                            <Grid item lg={1.5} xs={1.5} md={1.5} sm={1.5} display="flex">
                              <IconButton className={classes.phoneIconBtn}>
                                <PhoneIcon className={classes.phoneIcon} />
                              </IconButton>
                            </Grid>
                            <Grid item lg={10.5} xs={10.5} md={10.5} sm={10.5}>
                              <div style={{ marginLeft: "8px" }}>
                                <Typography
                                  className={classes.contactDetailsName}
                                >
                                  {datas?.table?.[0]?.name}
                                </Typography>
                                <Typography
                                  className={classes.contactDetailsNumbers}
                                >
                                  {datas?.table?.[0]?.mobile}&nbsp;|{" "}
                                  {datas?.table?.[0]?.alternative}
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <>
                          {!datas?.images && (
                            <>
                              <Grid container style={{ marginBottom: "10px" }}>
                                <Grid item xs={12}>
                                  <Typography
                                    className={classes.quotationsummaryans}
                                    style={{ marginTop: "10px" }}
                                  >
                                    Created By
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography
                                    className={classes.contactDetailsName}
                                  >
                                    {datas?.table?.[0]?.profile?.first_name}
                                    {datas?.table?.[0]?.profile?.last_name}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </>
                          )}
                        </>
                      )}
                    </Grid>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={5}
                  md={5}
                  sm={5}
                  lg={5}
                  style={{ padding: "4px", position: "relative" }}
                >
                  <div style={{ height: size?.height - 400 }}>
                    <div className={classes.createnewsectionlog}>
                      <Typography
                        className={classes.title}
                        style={{ marginBottom: "20px" }}
                      >
                        Status
                      </Typography>
                      <Grid container direction={"column"} spacing={1}>
                        {track?.filter((i => i?.date)).map((item, index, array) => {
                          return (
                            <Grid
                              container
                              direction={"row"}
                              justifyContent={"center"}
                              alignItems="center"
                            >
                              <Grid
                                container
                                direction={"column"}
                                xs={2}
                                justifyContent={"start"}
                                alignItems="center"
                              >
                                {index !== 0 && (
                                  <Typography
                                    style={{
                                      color: "#5078E1",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    |
                                  </Typography>
                                )}
                                <Avatar
                                  variant="circular"
                                  style={{
                                    height: "40px",
                                    width: "40px",
                                    border: "8px solid #F2F4F7",
                                  }}
                                >
                                  <Avatar
                                    variant="circular"
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      backgroundColor: "#5078E1",
                                    }}
                                  >
                                    <img
                                      src={
                                        request === "Maintance"
                                          ? item?.label === "Open"
                                            ? "images/icons8-clock.svg"
                                            : "images/Path 953.svg"
                                          : item?.label === "Open"
                                            ? "images/icons8-clock.svg"
                                            : "images/Path 953.svg"
                                      }
                                      alt="document"
                                    />
                                  </Avatar>
                                </Avatar>
                                {index + 1 !== array.length && (
                                  <Typography
                                    style={{
                                      color: "#5078E1",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    |
                                  </Typography>
                                )}
                              </Grid>
                              <Grid
                                container
                                direction={"column"}
                                xs={10}
                                justifyContent={"center"}
                                alignItems="start"
                                style={{ padding: "0px 0px 0px 10px" }}
                              >
                                <div
                                  style={{
                                    marginBottom: index === 0 ? "16px" : "0px",
                                    marginTop:
                                      index + 1 === array.length ? "22px" : "0px",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontSize:"1rem",
                                      fontFamily: FontFamilySwitch().bold
,
                                      color: "#091B29",
                                    }}
                                  >
                                    {item?.label}
                                  </Typography>
                                  {/* <Typography
                                style={{
                                  fontSize:"0.875rem",
                                  fontFamily:  SemiBold,
                                  color: "#7C8594",
                                }}
                              >
                                {request === "General"
                                  ? item?.remarks
                                  : item?.remarks}
                              </Typography> */}
                                  <Typography
                                    style={{
                                      fontSize:"0.75rem",
                                      fontFamily: FontFamilySwitch().semiBold,
                                      color: "#7C8594",
                                    }}
                                  >
                                    {moment(item.date)
                                      .tz(moment.tz.guess())
                                      .format("YYYY-MM-DD hh:mm A")}
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            {datas?.table?.[0]?.status?.some(
              (item) => item?.type === "Open"
            ) ? (
              <div className={classes.buttonParent}>
                <Button
                  className={classes.button}
                  variant="contained"
                  onClick={() => CloseDialog("open")}
                >
                  Close Request
                </Button>
              </div>
            ) : (
              ""
            )}
          </>
        </Dialog>

        {/* Drawer */}
        <DrawerComponent
          open={filter}
          onClose={OpenDrawer}
          toggleDrawer={OpenDrawer}
          component={
            <>
              <div className={classes.drawerwidth}>
                <Box className={classes.Header}>
                  <Box className={classes.closeIcondrawermap}>
                    <Typography variant="h6">
                      <img
                        src="\images\closebutton.svg"
                        alt="close"
                        onClick={OpenDrawer}
                      />
                    </Typography>
                  </Box>
                  <Typography variant="h6" className={classes.title} noWrap>
                    Filter
                  </Typography>
                </Box>
                <div className={classes.createnewsection}>
                  <FilterComponent
                    filterdata={filterdata}
                    upDateFilter={upDateFilter}
                    property={property}
                    units={unit}
                  />
                </div>
                <Box className={classes.btnparent}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={clearAll}
                        className={classes.clearall}
                      >
                        Clear All
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => getTable(0, 10, searchdata, "filter", selectedCompany)}
                        className={classes.Apply}
                      >
                        Apply
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </>
          }
        />

        <Dialog
          open={requestclose}
          className={classes.closeDialog}
        >
          <Box className={classes.header}>
            <Box>Close Request</Box>
            <Box>
              <img
                src="/images/close.svg"
                alt="close"
                style={{ cursor: "pointer" }}
                onClick={closeRequest}
              />
            </Box>
          </Box>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.closeMain}>
                <div className={classes.dropdown}>
                  <NewDatePicker
                    required
                    // maxDate={new Date(createdDate)}
                    maxDate={new Date()}
                    value={closedata?.date ?? ""}
                    label="Problem Since"
                    placeholder="Problem Since"
                    onChange={(value) => {
                      updateState("date", value);
                    }}
                    isError={closedata?.error?.date?.length > 0}
                    errorMessage={closedata?.error?.date}
                  />
                </div>
                <div className={classes.dropdown}>
                  <TextBox
                    isrequired
                    label="Problem Title"
                    placeholder="Select Problem Title"
                    value={closedata?.remarks}
                    multiline={true}
                    onChange={(e) => {
                      updateState("remarks", e.target.value);
                    }}
                    isError={closedata?.error?.remarks?.length > 0}
                    errorMessage={closedata?.error?.remarks}
                  />
                </div>

                <Button
                  variant="contained"
                  style={{ width: "100%", marginTop: "30px" }}
                  onClick={cancelPass}
                >
                  close
                </Button>
              </div>
            </Grid>
          </Grid>
        </Dialog>
      </div>
    </>
  );
};
