import { TabContext, TabList } from "@mui/lab"
import { Box, Tab } from "@mui/material"
import React from "react"

export const DynamicTabs = ({ handleChange = () => false, value = 1, tabList = [], t = () => false }) => {
    return (
        <Box>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        {tabList?.map((x) => {
                            return (
                                <Tab label={t(x.title)} value={x.value} />
                            )
                        })}
                    </TabList>

                </Box>
            </TabContext>
        </Box>
    )
}