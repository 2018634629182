import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: "20px",
        backgroundColor: theme.palette.background.tertiary,
        padding: "4px",
    },
    selected_tab: {
        cursor: "pointer",
        borderRadius: "20px",
        backgroundColor: theme.palette.primary.main,
        padding: "8px",
        minWidth: "150px",
        boxShadow: `0px 10px 25px ${theme.palette.boxShadow_2}`
    },
    unselected_tab: {
        cursor: "pointer",
        borderRadius: "20px",
        padding: "8px",
        minWidth: "150px"
    },
    selected_tab_text: {
        textAlign: "center",
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.white,
    },
    unselected_tab_text: {
        textAlign: "center",
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.primary,
    }
}));