/* eslint-disable react-hooks/exhaustive-deps */
import ClearIcon from "@mui/icons-material/Clear";
import {
  Badge,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import lightFormat from "date-fns/lightFormat";
import React from "react";
import { useNavigate } from 'react-router-dom';
import FilterIMG from "../../../assets/filter";
import {
  LoadingSection,
  SearchFilter,
  Subheader,
  TableWithPagination,
} from "../../../components";
import { ParkingDetails } from "../../../components/parkingDetails";
import VisitorSecurityFilter from "../../../components/visitorSecurityFilter";
import { config } from "../../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { Routes } from "../../../router/routes";
import {
  AlertProps,
  constructPropertyList,
  getCompanyOption,
  LocalStorageKeys,
  NetWorkCallMethods,
  timeZoneConverter
} from "../../../utils";
import {
  ApprovedHeading,
  ApprovedPath,
  ApprovedType,
  DeclinedHeading,
  DeclinedPath,
  DeclinedType,
  EntriesHeading,
  EntriesPath,
  EntriesType,
  WaitingForApprovalHeading,
  WaitingForApprovalPath,
  WaitingForApprovalType,
} from "../../../utils/securityManager/parkingSlotDataPoints";
import { withTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  innerWindow: {
    borderRadius: theme.palette.borderRadius,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    // padding: "16px",
    height: `calc(100vh - 147px)`,
    overflow: "hidden",
    margin: "14px",
  },
  dialogTitle: {
    color: "#091B29",
    fontSize:"1rem",
    fontWeight: "bolder",
  },
  searchFilter: {
    padding: "16px 0px 0px 0px",
    width: "50%",
  },
  table: {
    padding: "0px 16px 0px 16px",
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: theme.palette.borderRadius,
      padding: "0px",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const ParkingSlot = (props) => {
  const { t } = props
  const classes = useStyles();
  const navigate = useNavigate();
  const backdrop = React.useContext(BackdropContext);
  const auth = React.useContext(AuthContext);

  const [value, setValue] = React.useState(0);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [companyList, setCompanyList] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState({});
  const [selectedProperty, setSelectedProperty] = React.useState({})
  const [propertyData, setPropertyData] = React.useState([])
  const [initialLoad, setInitialLoad] = React.useState(false);
  const [filterDrawer, setFilterDrawer] = React.useState(false)
  const [filterData, setFilterData] = React.useState({})
  const clientId = localStorage.getItem(LocalStorageKeys.clinetID)
  const [unitID, setUnitID] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [entries, setEntries] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [load, setLoad] = React.useState(false);

  const [status, setStatus] = React.useState();

  const [searchText, setSearchText] = React.useState("");

  const [waitingForApprovalData, setWaitingForApprovalData] = React.useState({
    heading: WaitingForApprovalHeading(t),
    row: [],
    totalRowsCount: "",
    path: WaitingForApprovalPath,
    type: WaitingForApprovalType,
  });
  const [approvedData, setApprovedData] = React.useState({
    heading: ApprovedHeading(t),
    row: [],
    totalRowsCount: "",
    path: ApprovedPath,
    type: ApprovedType,
  });
  const [declinedData, setDeclinedData] = React.useState({
    heading: DeclinedHeading(t),
    row: [],
    totalRowsCount: "",
    path: DeclinedPath,
    type: DeclinedType,
  });
  const [entriesData, setEntriesData] = React.useState({
    heading: EntriesHeading(t),
    row: [],
    totalRowsCount: "",
    path: EntriesPath,
    type: EntriesType,
  });

  // searchText.length > 0 ? searchText.toUpperCase() : undefined
  const alert = React.useContext(AlertContext);
  const handleTab = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setStatus(["Pending"]);
      setEntries(false);
      setPage(1);
      setOffset(0);
    }
    if (newValue === 1) {
      setStatus(["Approved"]);
      setEntries(false);
      setPage(1);
      setOffset(0);
    }
    if (newValue === 2) {
      setStatus(["Rejected"]);
      setEntries(false);
      setPage(1);
      setOffset(0);
    }
    if (newValue === 3) {
      setStatus(["Approved"]);
      setEntries(true);
      setPage(1);
      setOffset(0);
    }
  };

  const handleChange = (event, newValue) => {
    if (newValue.status === "Waiting for Approval") {
      setOpen(true);
      setUnitID(newValue.id);
      setTitle("Request View");
    }
    if (newValue.status === "Declined") {
      setOpen(true);
      setUnitID(newValue.id);
      setTitle("Declined");
    }
    if (newValue.status === "Approved") {
      setOpen(true);
      setUnitID(newValue.id);
      setTitle("Temporary Visitor Parking Allocation");
    }
  };

  const Load = () => {
    setLoad(true);
  };

  const handlePagination = (value) => {
    setPage(value);
    const offset = (value - 1) * limit;
    setOffset(offset);
  };

  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    setOffset(0);
  };
  React.useEffect(() => {
    if (selectedCompany?.value) {
      setStatus(["Pending"]);
    }
  }, [selectedCompany]);

  React.useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, [
    selectedCompany,
    status,
    entries,
    load,
    searchText,
    limit,
    offset,
    page,
    selectedProperty,
    filterData
  ]);

  const getList = () => {
    if (
      propertyData !== null && propertyData?.length > 0 && selectedProperty?.value &&
      status?.length > 0
    ) {
      setInitialLoad(true);
      const payload = {
        request_type: ["PSR"],
        status: status,
        entries: entries,
        offset: offset,
        limit: limit,
        property_id: selectedProperty?.label === "All Properties" ? selectedProperty?.value : [selectedProperty?.value],
        reference_id:
          searchText.length > 0 ? searchText.toUpperCase() : undefined,
        created_start_date: filterData?.createdDate?.startDate ? lightFormat(filterData?.createdDate?.startDate, 'yyyy-MM-dd') : undefined,
        created_end_date: filterData?.createdDate?.endDate ? lightFormat(filterData?.createdDate?.endDate, 'yyyy-MM-dd') : undefined,
        check_in_start_date: filterData?.checkInDate?.startDate ? lightFormat(filterData?.checkInDate?.startDate, 'yyyy-MM-dd') : undefined,
        check_in_end_date: filterData?.checkInDate?.endDate ? lightFormat(filterData?.checkInDate?.endDate, 'yyyy-MM-dd') : undefined,
        check_out_start_date: filterData?.checkOutDate?.startDate ? lightFormat(filterData?.checkOutDate?.startDate, 'yyyy-MM-dd') : undefined,
        check_out_end_date: filterData?.checkOutDate?.endDate ? lightFormat(filterData?.checkOutDate?.endDate, 'yyyy-MM-dd') : undefined,
        access_gate_ids: filterData?.gate?.length > 0 ? filterData?.gate?.map((i) => i?.value) : undefined,
        request_to_start_date: filterData?.requestedToDate?.startDate ? lightFormat(filterData?.requestedToDate?.startDate, 'yyyy-MM-dd') : undefined,
        request_to_end_date: filterData?.requestedToDate?.endDate ? lightFormat(filterData?.requestedToDate?.endDate, 'yyyy-MM-dd') : undefined,
        request_from_start_date: filterData?.requestedFromDate?.startDate ? lightFormat(filterData?.requestedFromDate?.startDate, 'yyyy-MM-dd') : undefined,
        request_from_end_date: filterData?.requestedFromDate?.endDate ? lightFormat(filterData?.requestedFromDate?.endDate, 'yyyy-MM-dd') : undefined
      };
      NetworkCall(
        `${config.api_url}/security/visitors`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          if (
            status?.[0] === "Pending" &&
            entries === false
          ) {
            let tempPending = response?.data?.data?.map((item, index) => {
              let _item = {
                id: item?.id,
                reference_id: item?.request_no ? item?.request_no : "-",
                vehicle_type: item?.vechicle_type,
                vehicle_number: item?.vechicle_number,
                entry_gate: item?.gate ? item?.gate : "-",
                image: item?.requestor_image ?? null,
                requested_by:
                  item?.requested_by !== null
                    ? item?.requested_by
                      ? item?.requested_by
                      : ""
                    : "-",
                unit_no:
                  (item?.unit?.[0]?.name
                    ? item?.unit?.[0]?.name +
                    (item?.unit?.[0]?.unit_no ? ", " : "")
                    : "") +
                  (item?.unit?.[0]?.unit_no ? item?.unit?.[0]?.unit_no : "-"),
                requested_slot_date: item?.request_on
                  ? timeZoneConverter(item?.request_on, "DD MMM YY, hh:mm")
                  : "-",
                request_from: item?.request_from
                  ? timeZoneConverter(item?.request_from, "DD MMM YY, hh:mm")
                  : "-",
                request_to: item?.request_to
                  ? timeZoneConverter(item?.request_to, "DD MMM YY, hh:mm")

                  : "-",
                status: "Waiting for Approval",
                icon: "",
                index: offset + index + 1,
              };
              return _item;
            });
            setWaitingForApprovalData({
              ...waitingForApprovalData,
              row: tempPending,
              totalRowsCount: response?.data?.totalRecords,
            });
          }
          if (
            status?.[0] === "Approved" &&
            entries === false
          ) {
            let tempApproved = response?.data?.data?.map((item, index) => {
              let _item = {
                id: item?.id,
                reference_id: item?.request_no ? item?.request_no : "-",
                vehicle_type: item?.vechicle_type,
                vehicle_number: item?.vechicle_number,
                entry_gate: item?.gate ? item?.gate : "-",
                image: item?.requestor_image ?? null,
                requested_by:
                  item?.requested_by !== null
                    ? item?.requested_by
                      ? item?.requested_by
                      : ""
                    : "-",
                unit_no:
                  (item?.unit?.[0]?.name
                    ? item?.unit?.[0]?.name +
                    (item?.unit?.[0]?.unit_no ? ", " : "")
                    : "") +
                  (item?.unit?.[0]?.unit_no ? item?.unit?.[0]?.unit_no : "-"),

                requested_slot_date: item?.request_on
                  ? timeZoneConverter(item?.request_on, "DD MMM YY, hh:mm")
                  : "-",
                request_from: item?.request_from
                  ? timeZoneConverter(item?.request_from, "DD MMM YY, hh:mm")
                  : "-",
                request_to: item?.request_to
                  ? timeZoneConverter(item?.request_to, "DD MMM YY, hh:mm")

                  : "-",
                allotted_slot: item?.parking_slot ?? "-",
                status: "Approved",
                icon: "",
                index: offset + index + 1,
              };
              return _item;
            });
            setApprovedData({
              ...approvedData,
              row: tempApproved,
              totalRowsCount: response?.data?.totalRecords,
            });
          }
          if (
            status?.[0] === "Rejected" &&
            entries === false
          ) {
            let tempRejected = response?.data?.data?.map((item, index) => {
              let _item = {
                id: item?.id,
                reference_id: item?.request_no ? item?.request_no : "-",
                vehicle_type: item?.vechicle_type,
                vehicle_number: item?.vechicle_number,
                entry_gate: item?.gate ? item?.gate : "-",
                image: item?.requestor_image ?? null,
                requested_by:
                  item?.requested_by !== null
                    ? item?.requested_by
                      ? item?.requested_by
                      : ""
                    : "-",
                unit_no:
                  (item?.unit?.[0]?.name
                    ? item?.unit?.[0]?.name +
                    (item?.unit?.[0]?.unit_no ? ", " : "")
                    : "") +
                  (item?.unit?.[0]?.unit_no ? item?.unit?.[0]?.unit_no : "-"),
                requested_slot_date: item?.request_on
                  ? timeZoneConverter(item?.request_on, "DD MMM YY, hh:mm")
                  : "-",
                request_from: item?.request_from
                  ? timeZoneConverter(item?.request_from, "DD MMM YY, hh:mm")
                  : "-",
                request_to: item?.request_to
                  ? timeZoneConverter(item?.request_to, "DD MMM YY, hh:mm")

                  : "-",
                status: "Declined",
                icon: "",
                index: offset + index + 1,
              };
              return _item;
            });
            setDeclinedData({
              ...declinedData,
              row: tempRejected,
              totalRowsCount: response?.data?.totalRecords,
            });
          }
          if (entries === true && status?.[0] === "Approved") {
            let tempEntries = response?.data?.data?.map((item, index) => {
              let _item = {
                id: item?.id,
                reference_id: item?.request_no ? item?.request_no : "-",
                vehicle_type: item?.vechicle_type,
                vehicle_number: item?.vechicle_number,
                entry_gate: item?.gate ? item?.gate : "-",
                image: item?.requestor_image ?? null,
                requested_by:
                  item?.requested_by !== null
                    ? item?.requested_by
                      ? item?.requested_by
                      : ""
                    : "-",
                unit_no:
                  (item?.units?.[0]?.name
                    ? item?.units?.[0]?.name +
                    (item?.units?.[0]?.unit_no ? ", " : "")
                    : "") +
                  (item?.units?.[0]?.unit_no ? item?.units?.[0]?.unit_no : "-"),
                payment_charges:
                  item?.payment_charges === null &&
                    item?.currency === null &&
                    item?.currency?.symbol === null
                    ? "-"
                    : (item?.currency &&
                      item?.currency !== null &&
                      item?.currency?.symbol !== null
                      ? item?.currency?.symbol
                      : "") +
                    (item?.payment_charges !== null &&
                      item?.payment_charges !== ""
                      ? item?.payment_charges
                      : "-"),
                checked_in:
                  // (item?.parking_check_in_by
                  //   ? item?.parking_check_in_by +
                  //   (item?.actual_in === null ? "" : ", ")
                  //   : "") +
                  (item?.actual_in === null
                    ? "-"
                    : timeZoneConverter(item?.actual_in, "DD MMM YY, hh:mm")),

                checked_out:
                  // (item?.parking_check_out_by
                  //   ? item?.parking_check_out_by +
                  //   (item?.actual_out === null ? "" : ", ")
                  //   : "") +
                  (item?.actual_out === null
                    ? "-"
                    : timeZoneConverter(item?.actual_out, "DD MMM YY, hh:mm")),
                allotted_slot: item?.parking_slot ?? "-",
                requested_slot_date: item?.request_on
                  ? timeZoneConverter(item?.request_on, "DD MMM YY, hh:mm")

                  : "-",
                index: offset + index + 1,
              };
              return _item;
            });
            setEntriesData({
              ...entriesData,
              row: tempEntries,
              totalRowsCount: response?.data?.totalRecords,
            });
          }
          setInitialLoad(false);

        })
        .catch((error) => {
          setInitialLoad(false);

          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Some Thing Went Wrong"),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        });
    } else if (selectedCompany?.property?.length === 0) {
      if (status?.[0] === "Pending" && entries === false) {
        setWaitingForApprovalData({
          ...waitingForApprovalData,
          row: [],
          totalRowsCount: 0,
        });
      }
      if (status?.[0] === "Approved" && entries === false) {
        setApprovedData({ ...approvedData, row: [], totalRowsCount: 0 });
      }
      if (status?.[0] === "Rejected" && entries === false) {
        setDeclinedData({ ...declinedData, row: [], totalRowsCount: 0 });
      }
      if (entries === true && status?.[0] === "Approved") {
        setEntriesData({ ...entriesData, row: [], totalRowsCount: 0 });
      }
      setInitialLoad(false);
    }
  };

  // useEffect to get company list for company switcher when loading the screen
  React.useEffect(() => {
    let company = getCompanyOption(backdrop, auth, alert)
    if (company) {
      setCompanyList(company?.list)
      setSelectedCompany(company?.selected)
      getPropertyList(company?.selected)
    }
    // eslint-disable-next-line
  }, [auth]);
  // Function to change the company
  const handleCompanyChange = (value) => {
    setSelectedCompany(value);
    getPropertyList(value)
  };

  // Function to change property list
  const getPropertyList = (company) => {
    let property = constructPropertyList(company)
    setPropertyData(property?.list);
    setSelectedProperty(property?.list[0])
  }


  //handlePropertyChange
  const onPropertyChange = (val) => {
    setSelectedProperty(val)
  }
  // Filter Component Functions
  const apply = (value) => {
    setFilterData(value)
  }

  const filterKeys1 = ["reqDate", "requestedFromDate", "requestedToDate"]
  const filterKeys2 = ["reqDate", "checkOutDate", "checkInDate"]

  return (
    <div className={classes.root}>
      <Subheader
        hideBackButton={true}
        title={t("Visitor Parking Slot")}
        select
        options={companyList}
        value={selectedCompany}
        goBack={() => navigate(Routes.home)}
        onchange={(e) => handleCompanyChange(e)}
        onPropertyChange={(e) => {
          onPropertyChange(e)
        }}
        propertyValue={selectedProperty}
        selectProperty
        propertyOptions={propertyData}
      />
      <Grid direction={"column"} className={classes.innerWindow}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleTab} sx={{padding:"0px 8px 0px 16px"}}>
              <Tab label={t("Waiting for Approval")} />
              <Tab label={t("Approved")} />
              <Tab label={t("Declined")} />
              <Tab label={t("Entries")} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {waitingForApprovalData.heading.length > 0 && (
              <>
                <Grid container className={classes.table}>
                  <Grid item xs={8}>
                    <div className={classes.searchFilter}>
                      <SearchFilter
                        placeholder={t("Search Reference ID")}
                        value={searchText}
                        handleChange={(value) => setSearchText(value)}
                        customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" }}}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <Box display={"flex"} justifyContent={"end"} p={2}>
                      <IconButton onClick={() => setFilterDrawer(!filterDrawer)} className={classes.filterBox}>
                        <Badge variant="dot" color="primary"
                          invisible={!(Object.keys(filterData).length > 0)}>
                          <FilterIMG color="#091b29" />
                        </Badge>
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
                {initialLoad ? <LoadingSection message={"feteching data..."} /> :
                  <div className={classes.table}>
                    <TableWithPagination
                      heading={waitingForApprovalData.heading}
                      rows={waitingForApprovalData.row}
                      path={waitingForApprovalData.path}
                      showpagination={
                        waitingForApprovalData.row.length > 0 ? true : false
                      }
                      count="2"
                      handleIcon={handleChange}
                      onClick={() => false}
                      // tableType = "normal"
                      showpdfbtn={false}
                      showexcelbtn={false}
                      showSearch={false}
                      dataType={waitingForApprovalData.type}
                      handlePagination={handlePagination}
                      handleChangeLimit={handleChangeLimit}
                      totalRowsCount={waitingForApprovalData?.totalRowsCount}
                      page={page}
                      limit={limit}
                      height={"calc(100vh - 330px)"}
                      tableType="no-side"
                      view={true}
                      edit={true}
                      delete={true} />
                  </div>
                }
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {approvedData.heading.length > 0 && (
              <>
                <Grid container className={classes.table}>
                  <Grid item xs={8}>
                    <div className={classes.searchFilter}>
                      <SearchFilter
                        placeholder={t("Search Reference ID")}
                        value={searchText}
                        handleChange={(value) => setSearchText(value)}
                        customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" }}}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <Box display={"flex"} justifyContent={"end"} p={2}>
                      <IconButton onClick={() => setFilterDrawer(!filterDrawer)} className={classes.filterBox}>
                        <Badge variant="dot" color="primary"
                          invisible={!(Object.keys(filterData).length > 0)}>
                          <FilterIMG color="#091b29" />
                        </Badge>
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
                {initialLoad ? <LoadingSection message={"feteching data..."} /> :
                  <TableWithPagination
                    heading={approvedData.heading}
                    rows={approvedData.row}
                    path={approvedData.path}
                    showpagination={approvedData.row.length > 0 ? true : false}
                    count="2"
                    handleIcon={handleChange}
                    onClick={() => false}
                    // tableType = "normal"
                    showpdfbtn={false}
                    showexcelbtn={false}
                    showSearch={false}
                    dataType={approvedData.type}
                    handlePagination={handlePagination}
                    handleChangeLimit={handleChangeLimit}
                    totalRowsCount={approvedData?.totalRowsCount}
                    page={page}
                    limit={limit}
                    height={"calc(100vh - 330px)"}
                    tableType="no-side"
                    view={true}
                    edit={true}
                    delete={true} />
                }
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {declinedData.heading.length > 0 && (
              <>
                <Grid container className={classes.table}>
                  <Grid item xs={8}>
                    <div className={classes.searchFilter}>
                      <SearchFilter
                        placeholder={t("Search Reference ID")}
                        value={searchText}
                        handleChange={(value) => setSearchText(value)}
                        customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" }}}
                      />
                    </div>
                  </Grid>
                  {/* <Grid item xs={4}>
                    <Box display={"flex"} justifyContent={"end"} p={2}>
                      <IconButton onClick={() => setFilterDrawer(!filterDrawer)} className={classes.filterBox}>
                        <Badge variant="dot" color="primary"
                          invisible={!(Object.keys(filterData).length > 0)}>
                          <FilterIMG color="#091b29" />
                        </Badge>
                      </IconButton>
                    </Box>
                  </Grid> */}
                </Grid>
                {initialLoad ? <LoadingSection message={"feteching data..."} /> :
                  <TableWithPagination
                    heading={declinedData.heading}
                    rows={declinedData.row}
                    path={declinedData.path}
                    showpagination={declinedData.row.length > 0 ? true : false}
                    count="2"
                    handleIcon={handleChange}
                    onClick={() => false}
                    // tableType = "normal"
                    showpdfbtn={false}
                    showexcelbtn={false}
                    showSearch={false}
                    dataType={declinedData.type}
                    handlePagination={handlePagination}
                    handleChangeLimit={handleChangeLimit}
                    totalRowsCount={declinedData?.totalRowsCount}
                    page={page}
                    limit={limit}
                    height={"calc(100vh - 330px)"}
                    tableType="no-side"
                    view={true}
                    edit={true}
                    delete={true} />
                }
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={3}>
            {entriesData.heading.length > 0 && (
              <>
                <Grid container className={classes.table}>
                  <Grid item xs={8}>
                    <div className={classes.searchFilter}>
                      <SearchFilter
                        placeholder={t("Search Reference ID")}
                        value={searchText}
                        handleChange={(value) => setSearchText(value)}
                        customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" }}}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <Box display={"flex"} justifyContent={"end"} p={2}>
                      <IconButton onClick={() => setFilterDrawer(!filterDrawer)} className={classes.filterBox}>
                        <Badge variant="dot" color="primary"
                          invisible={!(Object.keys(filterData).length > 0)}>
                          <FilterIMG color="#091b29" />
                        </Badge>
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
                {initialLoad ? <LoadingSection message={"feteching data..."} /> :
                  <TableWithPagination
                    heading={entriesData.heading}
                    rows={entriesData.row}
                    path={entriesData.path}
                    showpagination={entriesData.row.length > 0 ? true : false}
                    count="2"
                    handleIcon={handleChange}
                    onClick={() => false}
                    // tableType = "normal"
                    showpdfbtn={false}
                    showexcelbtn={false}
                    showSearch={false}
                    dataType={entriesData.type}
                    handlePagination={handlePagination}
                    handleChangeLimit={handleChangeLimit}
                    totalRowsCount={entriesData?.totalRowsCount}
                    page={page}
                    limit={limit}
                    height={"calc(100vh - 270px)"}
                    view={true}
                    edit={true}
                    delete={true} />
                }
              </>
            )}
          </TabPanel>
        </Box>
      </Grid>

      {open && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          className={classes.dialog}
        >
          <DialogTitle
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography className={classes.dialogTitle}>{title}</Typography>
            <ClearIcon
              onClick={() => setOpen(false)}
              style={{ cursor: "pointer" }}
            />
          </DialogTitle>
          <Divider />
          <DialogContent style={{ padding: 0 }}>
            <ParkingDetails
              t={t}
              passId={unitID}
              onCancel={() => setOpen(false)}
              onLoad={() => Load()}
              getList={getList}
            />
          </DialogContent>
        </Dialog>

      )}

      <VisitorSecurityFilter
        open={filterDrawer}
        close={() => setFilterDrawer(!filterDrawer)}
        apply={apply}
        filterData={filterData}
        filterKeys={value === 3 ? filterKeys2 : filterKeys1}
        clientId={clientId}
        property={selectedProperty}
      />
    </div>
  );
};

export default withTranslation("parkingArea")(ParkingSlot)
