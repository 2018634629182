export const INITIAL_TABLE_OFFSET = 0;

export const INITIAL_TABLE_PAGE = 1;

export const INITIAL_TABLE_LIMIT = 10;

export const TABLE_PATH = [
    "reference_no",
    "utility_category_name",
    "utility_master_name",
    "provider",
    "service_provider_name",
    "price_per_uom",
    "description",
    "box_odu_number",
    "meter_serial_no",
    "subscription_no",
    "mobile_no",
    "inspection_date",
    "inspection_reading",
    "last_reading_date",
    "last_reading",
    "current_reading_date",
    "current_reading",
    "is_active",
]

export const TABLE_HEADING = (t) => [
    { title: "", field: "icon" },
    { title: t("Utility Reference No"), field: "reference_no" },
    { title: t("Utility Category Name"), field: "utility_category_name" },
    { title: t("Utility Master Name"), field: "utility_master_name" },
    { title: t("Provider"), field: "provider" },
    { title: t("Service Provider Name"), field: "service_provider_name" },
    { title: t("Price Per UOM"), field: "price_per_uom" },
    { title: t("Description"), field: "description" },
    { title: t("Box/ODU No"), field: "box_odu_number" },
    { title: t("Meter Serial No"), field: "meter_serial_no" },
    { title: t("Subscription No"), field: "subscription_no" },
    { title: t("Mobile No"), field: "mobile_no" },
    { title: t("Inspection Date"), field: "inspection_date" },
    { title: t("Inspection Reading"), field: "inspection_reading" },
    { title: t("Last Reading Date"), field: "last_reading_date" },
    { title: t("Last Reading"), field: "last_reading" },
    { title: t("Current Reading Date"), field: "current_reading_date" },
    { title: t("Current Reading"), field: "current_reading" },
    { title: t("Status"), field: "is_active" },
]

export const TABLE_TYPE = [
    { type: ["more_4"], icon: "icon" },
    { type: ["text"], name: "reference_no", },
    { type: ["long_text"], name: "utility_category_name", },
    { type: ["long_text"], name: "utility_master_name", },
    { type: ["text"], name: "provider", },
    { type: ["long_text"], name: "service_provider_name", },
    { type: ["text"], name: "price_per_uom", },
    { type: ["long_text"], name: "description", },
    { type: ["long_text"], name: "box_odu_number", },
    { type: ["long_text"], name: "meter_serial_no", },
    { type: ["long_text"], name: "subscription_no", },
    { type: ["text"], name: "mobile_no", },
    { type: ["date_time"], name: "inspection_date", format: "DD MMM yyyy, hh:mm a"},
    { type: ["long_text"], name: "inspection_reading", },
    { type: ["date_time"], name: "last_reading_date", format: "DD MMM yyyy, hh:mm a"},
    { type: ["long_text"], name: "last_reading", },
    { type: ["date_time"], name: "current_reading_date", format: "DD MMM yyyy, hh:mm a"},
    { type: ["long_text"], name: "current_reading", },
    { type: ["status"], name: "is_active", },
]

export const INITIAL_ADD_FORM_STATE = {
    form_type: "add",
    id: "",
    utility_category: "",
    utility_master: "",
    unit_utility_id: "",
    provider: "",
    service_provider_name: "",
    uom_name: "",
    currency_id: null,
    price: "",
    price_per_uom: "",
    description: "",
    box_odu_no: "",
    meter_serial_no: "",
    subscription_no: "",
    mobile: "",
    inspection_date: "",
    inspection_reading: "",
    last_reading_date: "",
    last_reading: "",
    current_reading_date: "",
    current_reading: "",
    is_active: true,
    is_delete: false,
    error: {
        utility_category: "",
        utility_master: "",
        description: "",
        box_odu_no: "",
        meter_serial_no: "",
        subscription_no: "",
        mobile: "",
        inspection_date: "",
        inspection_reading: "",
        last_reading_date: "",
        last_reading: "",
        current_reading_date: "",
        current_reading: "",
    }
}

export const INITIAL_FILTER_STATE = { is_active: [true] }