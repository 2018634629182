import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../../utils";

export const VenueCardStyle = makeStyles((theme) => ({
    root: {
        overflow: "auto",
        height: "430px"
    },
    box: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4
    },
    name: {
        fontSize:"0.875rem",
        fontWeight: "bold",
        color: theme.typography.color.primary
    },
    slot: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary
    },
    selectedbox: {
        border: `1px solid ${theme.palette.info.main}`,
        borderRadius: 4,
        backgroundColor: theme.palette.info.light

    }
}))

export const CalendarStyle = makeStyles((theme) => ({
    iconbutton: {
        border: `1px solid ${theme.palette.border.secondary}`
    },
    day: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        textTransform: "uppercase",
        border: `1px solid ${theme.palette.border.secondary}`,
        padding: 4
    },
    date_curr_month: {
        border: `1px solid ${theme.palette.border.secondary}`,
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontWeight: "bold"
    },
    date_not_curr_month: {
        border: `1px solid ${theme.palette.border.secondary}`,
        color: theme.palette.border.secondary,
        fontSize:"0.875rem",
        fontWeight: "bold"
    },
    today: {
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.info.light,
        color: theme.palette.primary.main,
        borderRadius: 4,
        fontSize:"0.875rem",
        fontWeight: "bold"
    }
}))

export const AvailableSlotsStyle = makeStyles((theme) => ({
    box: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4
    },
    dot: {
        height: 4,
        width: 4,
        borderRadius: 50,
        backgroundColor: theme.palette.border.secondary
    },
    time: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontWeight: "bold"
    },
    content: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontWeight: "bold"
    },
    noneTitle: {
        fontSize:"0.875rem",
        color: theme.typography.color.secondary,
        fontWeight: "bold"
    },
    noneContent: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary
    },
    none: {
        justifyContent: "center",
        alignItems: "center",
        height: "100% !important",
        display: "flex"
    },
    root_none: {
        height: "100%"
    },
    slot_section: {
        height: 350,
        overflow: "auto"
    }
}))

export const ParkingGroupMasterDropdownStyles = makeStyles((theme) => ({
    avatarSelected: {
        height: 30,
        width: 30,
        backgroundColor: "#E6F0FF",
        color: theme.palette.primary.main,
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,

    },
    notAvatarSelected: {
        height: 30,
        width: 30,
        backgroundColor: "#F5F5F5",
        color: theme.typography.color.tertiary,
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
    },
    dropdown: {
        border: `1.5px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        cursor: "pointer",
        zIndex: 1,
        backgroundColor: "#fff",
        boxShadow: "0px 0px 16px #00000014",
        padding: "11px 8px",
        marginTop: "3px"
    },
    amenity_content: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        cursor: "pointer",
        zIndex: 3,
        position: "absolute",
        backgroundColor: "#fff",
        top: "180px",
        padding: 12,
        boxShadow: "0px 0px 16px #00000014"

    },
    name: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontWeight: "bold"
    },
    view: {
        fontSize:"0.75rem",
        color: theme.palette.info.main
    },
    label: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary,

    },
    selectedBorder: {
        border: "1px solid #5078E1",
        backgroundColor: "#F1F7FF",
        padding: "6px",
        borderRadius: theme.palette.borderRadius,
        alignItems: "center"

    },
    notSelectedBorder: {
        padding: "6px",
        alignItems: "center"

    }

}))

export const CourtDropdownStyle = makeStyles((theme) => ({
    avatar: {
        height: 25,
        width: 25
    },
    dropdown: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 6,
        cursor: "pointer",
        zIndex: 3,
        position: "absolute",
        left: "20%",
        backgroundColor: "#fff",
        top: "130px"
    },
    name: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontWeight: "bold"
    },
    view: {
        fontSize:"0.75rem",
        color: theme.palette.info.main
    },
    court_content: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 6,
        cursor: "pointer",
        zIndex: 1,
        position: "absolute",
        left: "20%",
        backgroundColor: "#fff",
        top: "190px",
        width: 850,
        margin: 6,
        padding: 12
    }
}))

export const CalendarHeaderStyle = makeStyles((theme) => ({
    header: {
        height: 50,
        padding: 6,
    }
}))


export const AmenityPaymentDetailsStyle = makeStyles((theme) => ({
    name: {
        fontSize:"0.875rem",
        fontWeight: "bold",
        color: theme.typography.color.primary
    },
    id: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary
    },
    paid: {
        color: theme.palette.success.main,
        fontSize:"0.75rem",
        textAlign: "right"
    },
    avatar: {
        height: 48,
        width: 48,
        borderRadius: 4
    },
    title: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        textAlign: "left",
        fontWeight: "bold"
    },
    content: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        textAlign: "right",
        fontWeight: "bold"
    },
    totaltitle: {
        fontSize:"0.75rem",
        color: theme.typography.color.primary,
        textAlign: "left",
        fontWeight: "bold"
    },
    totalcontent: {
        fontSize:"0.75rem",
        color: theme.typography.color.primary,
        textAlign: "right",
        fontWeight: "bold"
    },
    box: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
    }
}))

export const AmenityDetailsStyle = makeStyles((theme) => ({
    avatar: {
        height: 42,
        width: 42,
        borderRadius: 4
    },
    name: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontWeight: "bold"
    },
    content: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontWeight: "semibold"
    },
    title: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontWeight: "bold",
        textTransform: "uppercase"
    },
    box: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
    }
}))

export const DeclineQRcodeStyle = makeStyles((theme) => ({
    box: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
    },
    declinebtn: {
        border: `1px solid ${theme.palette.error.main}`,
        color: theme.palette.error.main,
        borderRadius: 4,
        '&:hover': {
            border: `1px solid ${theme.palette.error.main}`,
            color: theme.palette.error.main,
            borderRadius: 4,
            backgroundColor: "transparent"
        }
    },
    shareIcon: {
        display: "flex",
        justifyContent: "right"
    },
    qr: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,

    },
    label: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    subLabel: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    status: {
        backgroundColor: "#5AC782",
        color: "white",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize:"0.75rem",
        padding: "4px",
        borderRadius: theme.palette.borderRadius
    },
    circle: {
        marginLeft: "-7px", marginTop: "8px", fontSize:"1rem", color: "white"
    },
    circle2: {
        marginRight: "-7px", marginTop: "8px", fontSize:"1rem", color: "white"
    },
    divRoot: {
        display: "flex", alignItems: "center", justifyContent: "center", marginTop: "14px"
    },
    boxRoot: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#E8F9E8",
        borderRadius: "4px",

    },
    expire2: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: "#43AA69"
    }
}))


export const FabButtonStyle = makeStyles((theme) => ({
    fabbtn: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 50,
        height: 50,
        width: 50,
        zIndex: 1,
        position: "absolute",
        bottom: "3%",
        right: "3%",
        textAlign: "center",
        color: "#fff",
        '&:hover': {
            width: 160,
            cursor: "pointer",
            '& p': {
                visibility: "visible !important",
                fontSize:"0.875rem",
            }
        }
    },
    // content:{
    //     position:"relative",
    //     top:"50%",
    //     left:"50%",
    //     transform:"translate(-50%,-50%)"
    // },
    btntext: {
        visibility: "hidden",
        whiteSpace: "nowrap"
    }
}))

export const ParkingBookingCardStyles = makeStyles((theme) => ({

    root: {
        position: "relative",
        backgroundColor: (props) => props?.val?.is_available ? "#EBFFF2" :
            "#F2F6FF",
        // backgroundColor: (props) => props?.val?.is_available ? "#EBFFF2" : props?.val?.parking_type === "Visitors" ? "#FFFAF0" :
        //     props?.val?.parking_type === "Reserved" ?
        //         "rgb(243 224 254)" :
        //         "#F2F6FF",
        borderRadius: "4px",
        padding: "8px",
        textAlign: "center",
        "&:hover": {
            backgroundColor: (props) => props?.val?.is_available ? "#EBFFF2" : "rgb(165 161 161 / 40%)",
        },
        height: "100%",
        minHeight: "214px"

    },
    subRoot: {
        zIndex: 1,
        display: "flex",
        position: "absolute",
        top: "43%",
        justifyContent: "center"
    },
    singleImgIcon: {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)"
    },
    text: {
        marginTop: (props) => props?.val?.is_available && "60px",
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: (props) => props?.val?.is_available ? theme.palette.success.main : theme.typography.color.tertiary
    },
    text2: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: (props) => props?.val?.is_available ? theme.palette.success.main : theme.palette.primary.main,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        margin: "6px 0px"

    },
    text3: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.tertiary,
        border: "1px solid #E6ECFF",
        padding: "4px",
        backgroundColor: "white",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    spanBox: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    btn: {
        backgroundColor: "white",
        marginLeft: "16px",
        "&:hover": {
            backgroundColor: "white",

        }
    },
    bottomArea: {
        bottom: 0,
        position: "absolute",
        left: 0,
        right: 0,
        backgroundColor: (props) => props?.val?.parking_type === "Visitors" ? "#FFFAF0" :
            props?.val?.parking_type === "Reserved" ?
                "rgb(243 224 254)" :
                "#F2F6FF",
    },
    text4: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
    },
}))
export const MonthlyCalendarStyles = makeStyles((theme) => ({
    // calendar dates styles
    event_label: {
        padding: 2,
        fontSize:"0.75rem",
        fontWeight: "bold",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: "150px"

    },
    calDates: {
        textAlign: "center",
        overflow: "auto",
        height: `calc(100vh - 280px)`,

    },
    date: {
        color: theme.typography.color.secondary,
        fontSize:"1.125rem",
        fontWeight: "bold"
    },
    date_outline: {
        color: "black",
        border: `1px solid ${theme.palette.border.secondary}`,
        height: 90,
    },
    hidden_other_month_dates: {
        visibility: "hidden"
    },
    eventlabel: {
        overflow: "hidden",
        width: "98%",
    },
    eventtxt: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: "#6DAFB3",
        width: "150px"
    },
    moreLabel: {
        float: "left",
        fontSize:"0.75rem",
        fontWeight: "bold",
        color: theme.palette.info.main,
        cursor:"pointer"
    },
    days: {
        fontSize:"0.75rem",
        textTransform: "uppercase",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().semiBold,
        textAlign: "center"
    },
    customTooltip: {
        '& .MuiTooltip-arrow':{
            backgroundColor:"#fff",
            color:"#fff",
            border: '1px solid #dadde9'
        }
    },
}))
export const weeklyCalendarStyle = makeStyles((theme) => ({
    cells: {
        display: "flex",
        justifyContent: "center",
        height: 80,
        border: `1px solid ${theme.palette.border.secondary}`,
        alignItems: "center",
        padding:"2px"
    },
    hovercells: {
        display: "flex",
        justifyContent: "center",
        height: 80,
        border: `1px solid ${theme.palette.border.secondary}`,
    },
    dayLabel: {
        fontSize:"0.875rem",
        textTransform: "uppercase",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().semiBold,

    },
    dateLabel: {
        fontSize:"1rem",
        fontWeight: "bold",
        textTransform: "uppercase",
        color: theme.typography.color.secondary
    },
    listTitle: {
        color: theme.typography.color.tertiary,
        fontSize:"1.625rem",
    },
    calender: {
        zIndex: "0"
    },
    calenderRows: {
        height: `calc(100vh - 320px)`,
        overflow: "auto",
        width: "100%",
    },
    cell_hover: {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.info.main,
        height: 80,
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        cursor: "pointer"
    },
    header_timecell: {
        height: 40,
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        textTransform: "uppercase",
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    timeCell: {
        display: "flex",
        justifyContent: "center",
        height: 100,
        alignItems: 'end',
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
    },
    header_eventCell: {
        height: 40,
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "left",
        alignItems: "center"
    },
    eventCell: {
        height: 100,
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%"
    },
    event_label: {
        padding: 2,
        fontSize:"0.75rem",
        fontWeight: "bold",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: "150px"

    },
    dot: {
        height: 5,
        width: 5,
        borderRadius: "50%",
    },
    calRow: {
        height: "calc(100vh - 250px)",
        overflow: "auto",
        width: "100%",
    },
    moreLabel: {
        fontSize:"0.75rem",
        color: theme.palette.info.main,
        fontWeight: "bold",
        marginLeft: 8,
        cursor: "pointer"
    },
    customTooltip: {
        '& .MuiTooltip-arrow':{
            backgroundColor:"#fff",
            color:"#fff",
            border: '1px solid #dadde9'
        }
    },
    ttavatar: {
        height: 24,
        width: 24,
    },
    ttTitle: {
        fontSize:"1.125rem",
        fontWeight: "bold",
        color: theme.typography.color.primary
    },
    ttsecTitle: {
        fontSize:"0.875rem",
        color: theme.typography.color.secondary,
    },
    ttassigned_by: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary
    },
    ttname: {
        fontSize:"1rem",
        color: theme.typography.color.secondary,
        fontWeight: "bold"
    }
}))

export const eventListStyle = makeStyles((theme) => ({
    header_timecell: {
        height: 40,
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        textTransform: "uppercase",
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    timeCell: {
        display: "flex",
        justifyContent: "center",
        height: 100,
        alignItems: 'end',
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
    },
    header_eventCell: {
        height: 40,
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "left",
        alignItems: "center"
    },
    eventCell: {
        height: 100,
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%"
    },
    event_label: {
        padding: 2,
        fontSize:"0.75rem",
        fontWeight: "bold",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: "150px"
    },
    dot: {
        height: 5,
        width: 5,
        borderRadius: "50%",
    },
    calRow: {
        height: "calc(100vh - 250px)",
        overflow: "auto",
        width: "100%",
    },
    moreLabel: {
        fontSize:"0.75rem",
        color: theme.palette.info.main,
        fontWeight: "bold",
        cursor: "pointer"
    },
    customTooltip: {
        // '& .MuiTooltip-arrow':{
        //     backgroundColor:"#fff",
        //     color:"#fff",
        //     border: '1px solid #dadde9'
        // }
    },
    ttavatar: {
        height: 24,
        width: 24,
    },
    ttTitle: {
        fontSize:"1.125rem",
        fontWeight: "bold",
        color: theme.typography.color.primary
    },
    ttsecTitle: {
        fontSize:"0.875rem",
        color: theme.typography.color.secondary,
    },
    ttassigned_by: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary
    },
    ttname: {
        fontSize:"1rem",
        color: theme.typography.color.secondary,
        fontWeight: "bold"
    }
}))
export const ProfileCardStyle = makeStyles((theme) => ({
    dot: {
        height: 6,
        width: 6,
        background: theme.palette.border.secondary,
        borderRadius: 50
    },
    name: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
    },
    mobno_email: {
        color: theme.typography.color.secondary,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
    },
    titleText: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary
    },
    contentText: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontWeight: "bold"
    },
}))
export const ImageGalleryStyle = makeStyles((theme) => ({
    img: {
        objectFit: "contain",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
        maxWidth:"250px"
    },
    timeCell: {
        display: "flex",
        justifyContent: "center",
        minHeight: 100,
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        borderLeft: `0.5px solid ${theme.palette.border.secondary}`,
        borderBottom: `0.5px solid ${theme.palette.border.secondary}`,
        borderTop:  `0.5px solid ${theme.palette.border.secondary}`

    },
    eventCell: {
        //height: 100,
        borderTop: `1px solid ${theme.palette.border.secondary}`,
        borderLeft: `1px solid ${theme.palette.border.secondary}`,
        borderRight: `1px solid ${theme.palette.border.secondary}`,
        borderBottom: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        //overflow: "auto"
    },
    event_label: {
        padding: 2,
        fontSize:"0.75rem",
        fontWeight: "bold",
        textTransform: "capitalize"
    },
    dot: {
        height: 5,
        width: 5,
        borderRadius: "50%",
    },
}))
