import { Close } from "@mui/icons-material"
import { Box, Button, Divider, Grid, IconButton, Stack, Typography } from "@mui/material"
import React, { useContext, useState } from "react"
import { withTranslation } from "react-i18next"
import { useNavigate } from 'react-router-dom'
import FilterIcon from "../../assets/filterIcon"
import { FormDeleteIcon } from "../../assets/formDeleteIcon"
import { InActiveIcon } from "../../assets/inActivateIcon"
import { PublishFormIcon } from "../../assets/publishForm"
import { AlertDialog, FilterGenerator, SearchFilter, Subheader, TableWithPagination } from "../../components"
import { NewLoader } from "../../components/newLoader"
import { config } from "../../config"
import { AlertContext, AuthContext } from "../../contexts"
import { NetworkCall } from "../../networkcall"
import { Routes } from "../../router/routes"
import { AlertProps, FeedBackDraftDataType, FeedBackPublishDataType, LocalStorageKeys, NetWorkCallMethods, accessCheckRender, feedBackDraftHeading, feedBackFormHeading, feedBackFormPath, getRoutePermissionNew } from "../../utils"
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions"
import { DynamicTabs } from "./components/tab"
import { ViewFeedBackForm } from "./components/viewFeedBackForm"
import { useStyles } from "./style"


const FormBuilder = ({ t }) => {
    const classes = useStyles()
    const navigate = useNavigate()
    const alert = useContext(AlertContext)
    const TabList = [{ value: 1, title: "Publish" }, { value: 2, title: "Draft" }]
    const [loading, setLoading] = React.useState(true)
    const [tabValue, setTabValue] = React.useState(1);
    const [publishFormDetails, setPublishFormDetails] = React.useState([])
    const [viewData, setViewData] = React.useState({
        is_view: false,
        data: {}
    })

    const [showDialog, setShowDialog] = React.useState({
        open: false, type: ""
    })
    const client_id = localStorage.getItem(LocalStorageKeys.clinetID)
    const [searchText, setSearchText] = React.useState("")
    const [feedBackData, setFeedBackData] = React.useState({
        list: [],
        count: 0
    })
    const [filterData, setFilterData] = React.useState({
        inspection_type: [],
        status: [true]
    })
    const [drawer, setDrawer] = useState(false)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [isPublish, setIsPublish] = useState(false)
    const auth = React.useContext(AuthContext)
    const [permission, setPermission] = useState({})
    //get feedbackList
    const GetFeedBackList = (type, offset = 0, limit = 10, search = "", filterData) => {
        setLoading(true)

        const payload = {
            inspection_type: filterData?.inspection_type?.map((x) => { return x.value }),
            type: [type === 2 ? true : false],  //is_draft
            active: filterData?.status?.map((x) => { return x }),
            start: offset,
            length: limit,
            search: search
        }
        NetworkCall(
            `${config.api_url}/feedback_form`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setLoading(false)
            const result = res?.data?.data?.data?.map((val) => {
                return {
                    ...val,
                    form_category: val.inspection_type
                }
            })
            setFeedBackData({ list: result, count: res?.data?.data?.count })
        }).catch((err) => {
            setLoading(false)
            console.log(err)
        })
    }
    // get feedBack details by id
    const GetFeedBackDetailsById = (formId, type) => {
        const payload = {
            form_id: formId
        }
        NetworkCall(
            `${config.api_url}/feedback_form/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            if (type !== "view_feedBack") {
                setShowDialog({ open: true, type: type })
            } else {
                setViewData({ data: res?.data?.data?.data, is_view: true })
            }
            setPublishFormDetails(res?.data?.data?.data)
        }).catch((err) => {
            console.log(err)
        })
    }
    //useEffect-initial load
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            GetFeedBackList(tabValue, 0, 10, "", filterData)
        }
        //eslint-disable-next-line
    }, [auth])


    //tab change
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        setLoading(true)
        GetFeedBackList(newValue, 0, 10, "", filterData)
    };
    //handle Search
    const handleSearch = (value) => {
        setSearchText(value)
        GetFeedBackList(tabValue, 0, 10, value, filterData)
    }
    //handleIcon
    const handleIcon = (type, data) => {
        if (type === "active/inactive" || type === "delete" || type === "publish") {
            GetFeedBackDetailsById(data?.id, type)
        }
        else if (type === "edit") {
            navigate(Routes?.formBuilder, {
                state: {
                    data: data,
                    is_edit: true
                }
            })
        }
        else if (type === "view_feedBack") {
            GetFeedBackDetailsById(data?.id, type)
        }
    }
    //handlePagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        GetFeedBackList(tabValue, offset, 10, "", filterData)
    }
    //handleChangeLimit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        GetFeedBackList(tabValue, 0, value, "", filterData)

    }
    //on create button click
    const onCreate = () => {
        navigate(Routes?.formBuilder)
    }
    // close view dialog
    const closeViewDialog = () => {
        setViewData({ is_view: false, data: {} })
    }
    const onApplyFilter = (val) => {
        setFilterData(val)
        GetFeedBackList(tabValue, 0, 10, "", val)
    }
    //publish form
    const onSubmit = (type) => {
        let payload = {}
        if (type) {
            if (type === "publish") {
                payload = {
                    "upsert_fields": {
                        id: publishFormDetails?.id ?? undefined,
                        title: publishFormDetails?.title,
                        is_draft: type === "delete" ? true : false,
                        type_id: publishFormDetails?.feedback_master?.[0]?.type_id,
                        feedback_master: {
                            type_id: publishFormDetails?.feedback_master?.[0]?.type_id,
                            client: client_id
                        },
                        feedback_elements: publishFormDetails?.feedback_elements
                    }
                }
            }
            else {
                payload = {
                    "upsert_fields": {
                        id: publishFormDetails?.id ?? undefined,
                        is_active: publishFormDetails?.is_active ? false : true,

                        is_delete: type === "delete" ? true : false,
                    }
                }
            }

            NetworkCall(
                `${config.api_url}${type === "publish" ? "/feedback_form/publish" : "/feedback_form/edit_status"}`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((res) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,

                    msg: `${t("Form")} ${type === "publish" ? t("Published") : type === "delete" ? t("Deleted") : t("In-Activated")} ${t("Successfully")}`,
                });
                setIsPublish(false)
                setShowDialog({ ...showDialog, open: false })
                GetFeedBackList(tabValue, 0, 10, "", filterData)
            }).catch((error) => {
                if (error.response.data.error.message?.length>0) {
                    // Request made and server responded
                    setIsPublish(false)
                    setShowDialog({ open: false, type: "" })
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,

                        msg: t("Already Another form is active for this type.Please disable and continue"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });
                }
                else {
                    setIsPublish(false)
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,

                        msg: t("Something went wrong please try again"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });
                }
            })
        }
    }
    const openfilter = () => {
        setDrawer(true)
    }
    const manualResponse = (array) => array?.list?.map(_ => {
        return {
            ..._,
            label: _?.name,
            value: _?.id,
        }
    });
    const render = () => {
        return (
            <Box>
                <Subheader
                    hideBackButton={true}
                    title={t("formBuilder")}
                />
                <Box className={classes.root}>
                    <DynamicTabs tabList={TabList} value={tabValue} handleChange={handleTabChange} t={t}/>
                    <Box p={2}>
                        <Box display="flex" justifyContent={"space-between"}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <SearchFilter
                                        handleChange={handleSearch}
                                        value={searchText}
                                        placeholder={t("search")}
                                        customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
                                    />
                                </Grid>
                                <Grid item xs={9} alignContent="flex-end">
                                    <Stack
                                        divider={<Divider orientation="vertical" flexItem />}
                                        spacing={2}
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        direction="row">
                                        {/*add btn */}
                                        {
                                            (filterData?.status?.length > 0||filterData?.inspection_type?.length>0) ?
                                               <Box onClick={openfilter}><FilterIcon/></Box>
                                                :
                                                <IconButton className={classes.iconButton} onClick={openfilter}>
                                                    <img src="/images/filter.svg" alt="filter" />
                                                </IconButton>
                                        }
                                        {permission?.create &&

                                            <Button variant="contained" onClick={onCreate} sx={{ height: '40px' }}>{t("Create")}</Button>
                                        }

                                    </Stack>
                                </Grid>
                            </Grid>

                        </Box>
                        {
                            loading ? (
                                <NewLoader minusHeight="180px" />
                            ) :
                                <Box mt={2}>
                                    <TableWithPagination
                                        heading={tabValue === 1 ? feedBackFormHeading(t) : feedBackDraftHeading(t)}
                                        rows={feedBackData?.list}
                                        dataType={tabValue === 1 ? FeedBackPublishDataType : FeedBackDraftDataType}
                                        path={feedBackFormPath}
                                        showpagination
                                        tableType="no-side"
                                        handleIcon={handleIcon}
                                        handlePagination={handlePagination}
                                        handleChangeLimit={handleChangeLimit}
                                        totalRowsCount={feedBackData?.count}
                                        page={page}
                                        limit={limit}
                                        className={classes.reqTable}
                                        // view={true}
                                        view={permission?.read}
                                        height={`calc(100vh - 350px)`}
                                        // edit={true}
                                        edit={permission?.update}
                                        // delete={true}
                                        delete={permission?.delete}
                                    />
                                </Box>
                        }
                    </Box>
                </Box>
                {/* dialog */}
                <AlertDialog
                    open={showDialog?.open}
                    isNormal
                    isnotTitle={true}
                    onClose={() => setShowDialog({ open: false, type: "" })}
                    component={
                        <Box p={1}>
                            <Box display={"flex"} justifyContent="end" style={{ cursor: "pointer" }} onClick={() => setShowDialog({ open: false, type: "" })}><Close /></Box>
                            <Box p={1}>
                                <center>

                                    <Box>{showDialog?.type === "active/inactive" ? (publishFormDetails?.is_active ? <InActiveIcon /> : <PublishFormIcon />) : showDialog?.type === "publish" ? <PublishFormIcon /> : showDialog?.type === "delete" ? <FormDeleteIcon /> : ""}</Box>
                                    <Box mt={1}>
                                        <Typography className={classes.goBacktext}>{`Do you want to ${showDialog?.type === "publish" ? "publish" : showDialog?.type === "delete" ? "delete" : showDialog?.type === "active/inactive" ? (publishFormDetails?.is_active ? "inactive" : "active") : ""} this form?`}</Typography>
                                        {showDialog?.type === "active/inactive" && publishFormDetails?.is_active &&
                                            <Box mt={1}>
                                                <Typography className={classes.goBackSubtxt}>{t("After inactive you can't use this form")}</Typography>

                                            </Box>
                                        }
                                    </Box>

                                </center>

                            </Box>
                            <Box p={2}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={6}>
                                        <Button variant="outlined" className={classes.draftbtn} fullWidth onClick={() => setShowDialog({ open: false, type: "" })}>No</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button variant="contained" onClick={() => onSubmit(showDialog.type)} fullWidth>{t("Yes")}, {showDialog?.type === "active/inactive" ? (publishFormDetails?.is_active ? "Inactive" : "Active") : showDialog?.type === "publish" ? "Publish" : showDialog?.type === "delete" ? "Delete" : ""}</Button>

                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    }
                />
                {/* view form */}
                <AlertDialog
                    open={viewData?.is_view}
                    isNormal
                    header={viewData?.data?.title}
                    onClose={() => closeViewDialog()}

                    component={<ViewFeedBackForm t={t} data={viewData?.data} tabValue={tabValue} closeViewDialog={closeViewDialog} setIsPublish={setIsPublish} />}

                />
                {/*filter component */}
                {drawer && (
                    <FilterGenerator
                        open={drawer}
                        onClose={() => setDrawer(false)}
                        components={[
                            {
                                component: "select",
                                value: filterData?.inspection_type,
                                state_name: "inspection_type",

                                label: t("Form Category"),
                                placeholder: t("Form Category"),
                                isMulti: true,
                                loadOptions: (search, array, handleLoading) =>loadOptionsApis(
                                    "customer_feedback_type_master",
                                    null,
                                    search,
                                    array,
                                    handleLoading,
                                    "data",
                                    {},
                                    manualResponse
                                ),
                                debounceTimeout: 800,
                                isPaginate: true,
                                menuPlacement: 'bottom',

                            },
                            {
                                component: "toggleButton",
                                value: filterData?.status,
                                state_name: "status",
                                placeholder: t("Status"),
                                label: t("Status"),
                                options: [{ label: t("Active"), value: true }, { label: t("In-active"), value: false }],
                                isMulti: true
                            },

                        ]}
                        onApply={(value) => onApplyFilter(value)}
                    />
                )}
                {/* {publish dialog} */}
                {/* Publish form  in view*/}
                <AlertDialog
                    open={isPublish}
                    isNormal
                    isnotTitle={true}
                    onClose={() => setIsPublish(false)}
                    component={
                        <Box p={1}>
                            <Box display={"flex"} justifyContent="end" style={{ cursor: "pointer" }} onClick={() => setIsPublish(false)}><Close /></Box>
                            <Box p={1}>
                                <center>
                                    <Box><PublishFormIcon /></Box>
                                    <Box mt={1}>

                                        <Typography className={classes.goBacktext}>{t("Do you want to publish this form?")}</Typography>
                                    </Box>

                                </center>

                            </Box>
                            <Box p={2}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={6}>
                                        <Button variant="outlined" className={classes.draftbtn} fullWidth onClick={() => setIsPublish(false)}>No</Button>
                                    </Grid>
                                    <Grid item xs={6}>

                                        <Button variant="contained" onClick={() => onSubmit("publish")} fullWidth>{t("Yes, Publish")}</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    }
                />
            </Box>
        )
    }
    return (
        <Box>
            {accessCheckRender(render, permission)}
        </Box>

    )

}
export default withTranslation("formBuilder")(FormBuilder)