import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import { Avatar, Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { TypeIcon } from "../../../assets";
import BackgroundType from "../../../assets/leaseBackground";
import BackgroundTypeArabic from "../../../assets/leaseBackgroundArabic";
import MobileIcon from "../../../assets/mobileIcon";
import QuotationLeaseIcon from "../../../assets/quotationLeaseIcon";
import SourceTypeIcon from "../../../assets/sourceIcon";
import { AlertDialog, CustomSelectOptions, IOSSwitch, SourceMenuOption } from "../../../components";
import { CustomSelectBox } from "../../../components/customSelectBox";
import { CreateOppertunityContext } from "../../../contexts/createOppertunityContext";
import { LocalStorageKeys, stringAvatar } from "../../../utils";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";
import { BrokerAccCard } from "../../createquotation/component/brokerAccCard";
import { InterestedUnitsCard } from "./interstedUnitsCard";
import { LeadDetailCard } from "./leadCard";
import { useStyles } from "./styles";
import { UnitSelection } from "./unitSelection";
import { UnitSelectionLead } from "./unitselectlead";
export const LeadDetailsCard = ({
  data = {},
  hideDetails = false,
  source_enum = [],
  is_lead = false,
  is_convert = false,
  contact_id = null,
  opperEdit = false,
  setOpperEdit = () => false
}) => {
  const { dataNew, updateStateNew, setDataNew, setSelectedAccount, selectedAccount: account, t = () => false } = useContext(
    CreateOppertunityContext
  );

  const removeUnits = () => {
    setDataNew({
      ...dataNew,
      units: [],
    });
  };

  const removeSpecificUnit = (unit_id) => {
    setDataNew({
      ...dataNew,
      units: dataNew.units.filter(unit => unit.unit_id !== unit_id),
    });
  };

  const [open, setOpen] = React.useState({
    bool: false,
    type: null
  })
  const [primary, setPrimary] = React.useState(dataNew.primary ? true : false)
  const language = localStorage.getItem("i18nextLng")
  const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId)
  const is_workspace = ['49', '35'].includes(moduleId)

  // const clientID = localStorage.getItem(LocalStorageKeys.clinetID)
  const classes = useStyles();
  // onChange Unit
  const onChangeUnits = (unit, owner, type) => {
    setDataNew({
      ...dataNew,
      selectedUnit: unit ?? [],
      lead_owner: type === 1 ? owner : null,
      property_id: type === 2 ? owner : null,
      edit_owner: null,
      // units: unit?.filter((val) => val?.is_active === true) ?? [],
    });
  };

  const updateAccount = (key, value) => {
    setSelectedAccount({
      ...account,
      [key]: value
    })
  }

  const agentManualResponse = (array) => {
    const data = array?.data?.map((e) => {
      return {
        ...e,
        label: e?.name
      }
    })

    return data
  }

  return (
    <Box>
      <Box className={classes.detailRoot}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Avatar
              src={data?.image}
              className={classes.userImg}
              {...stringAvatar(data?.name, 14, "4px")}
            />
          </Grid>
          <Grid item xs={10}>
            <Typography className={classes.leadName} noWrap style={{ paddingRight: "55px" }}>{data?.name}</Typography>

          </Grid>

        </Grid>
        {/* <Stack direction="row" spacing={1.5}> */}
        {/* </Stack> */}
        <Box className={language === "ar" ? classes.typeBgArabic : classes.typeBg}>
          {language === "ar" ?
            <BackgroundTypeArabic
              color={
                data?.type === "Lease"
                  ? "#6d6db3"
                  : data?.type === "Sale"
                    ? "#f4a261"
                    : "#690375"
              } /> :
            <BackgroundType
              color={
                data?.type === "Lease"
                  ? "#6d6db3"
                  : data?.type === "Sale"
                    ? "#f4a261"
                    : "#690375"
              }
            />
          }
          <Box className={classes.centeredText} display={"flex"}>
            <QuotationLeaseIcon />
            <Typography className={classes.type}>{t(data?.type) ?? "-"}</Typography>
          </Box>
        </Box>
      </Box >
      <Stack direction={"row"} alignItems="center" spacing={1} mt={1.5}>
        <MobileIcon className={classes.mailIcon} />
        <Typography className={classes.mobileNo}>{data?.mobile ?? "-"}</Typography>
      </Stack>
      <Stack direction={"row"} alignItems="center" spacing={1} mt={1.5}>
        <MailOutlinedIcon className={classes.mailIcon} />
        <Typography className={classes.mobileNo}>{data?.email ?? "-"}</Typography>
      </Stack>
      <Grid container mt={2} alignItems="center">
        <Grid item xs={1} className={classes.gridFlex}>
          <TypeIcon className={classes.homeIcon} />
        </Grid>
        <Grid item xs={4} className={classes.gridFlex}>
          <Typography className={classes.typeText}>{t("Type")}</Typography>
        </Grid>
        <Grid item xs={7}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            className={classes.purposebox}
          >
            <Typography className={classes.typeText} noWrap>
              {data?.purpose ?? "-"}
            </Typography>
            <KeyboardArrowDownIcon className={classes.arrowIcons} />
          </Box>
        </Grid>
      </Grid>
      <Grid container mt={2} alignItems="center">
        <Grid item xs={1} className={classes.gridFlex}>
          <SourceTypeIcon />
        </Grid>
        <Grid item xs={4} className={classes.gridFlex}>
          <Typography className={classes.srcText} ml={1}>
            {t("Lead Source")}
          </Typography>
        </Grid>
        <Grid item xs={7}>

          <SourceMenuOption
            value={t(dataNew?.source?.value) ?? ""}
            onChange={(value) => updateStateNew("source", value)}
            option={source_enum ?? []}
          />
        </Grid>
      </Grid>
      <Grid container mt={2} alignItems="center">
        <Grid item xs={1} className={classes.gridFlex}>
          <SourceTypeIcon />
        </Grid>
        <Grid item xs={4} className={classes.gridFlex}>
          <Typography className={classes.srcText} ml={1}>
            {t("Internal Lead Source")}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <CustomSelectBox
            value={dataNew?.internal_lead_source}
            menuOptionPadding={"0px 0px 8px 0px"}
            networkCallData={
              {
                path: "queries/opportunity/internal_lead_source",
                payload: {},
                mappingVariable: "data",
              }
            }
            onChange={(value) => updateStateNew("internal_lead_source", value)}
            placeholder={t("Internal")}
            customStyles={{
              background: "#EDE4FE80",
              height: 40,
              padding: "8px 16px 8px 8px",
              textColor: "#896DB3",
              boldText: true
            }}
          />
        </Grid>
      </Grid>
      {
        account?.normal?.label ?
          <Box marginTop="12px">
            <BrokerAccCard
              bgColor="#FFFCEB"
              textColor="#B3A16D"
              title={t("Customer Account")}
              edit={opperEdit ? true : false}
              acc={account?.normal}
              onEdit={() => setOpen({
                bool: opperEdit ? true : false,
                type: "normal"
              })}
              hideValue1={false}
            />
          </Box>
          :
          <Button className={classes.addBrokerAcc} onClick={() => setOpen({
            bool: opperEdit ? true : false,
            type: "normal"
          })}>{t("Customer Account +")}</Button>

      }

      {
        account?.broker?.label ?
          <Box marginTop="12px">
            <BrokerAccCard
              acc={account?.broker}
              edit={opperEdit ? true : false}
              title={t("Broker Account")}
              onEdit={() => setOpen({
                bool: opperEdit ? true : false,
                type: "broker"
              })}
              hideValue1={false}
            />
          </Box>
          :
          <Button className={classes.addBrokerAcc}
            onClick={() => setOpen({
              bool: opperEdit ? true : false,
              type: "broker"
            })}
          >{t("Broker Account +")}</Button>

      }
      {
        account?.agent?.label ?
          <Box marginTop="12px">
            <BrokerAccCard
              acc={account?.agent}
              edit={opperEdit ? true : false}
              title={t("Broker Agent")}
              onEdit={() => setOpen({
                bool: opperEdit ? true : false,
                type: "Agent"
              })}
            />
          </Box>
          :
          <>
            {
              account?.broker?.label &&
              <Button className={classes.addBrokerAcc}
                onClick={() => setOpen({
                  bool: opperEdit ? true : false,
                  type: "Agent"
                })}
              >{t("Broker Agent +")}</Button>
            }
          </>

      }
      <Box mt={2}>
        <Divider />
      </Box>

      {
        (is_lead && !is_workspace) ?
          <>
            {
              dataNew?.revenue_type?.value === "Sale"
                ? dataNew?.earlist_occupation_date !== "" && (
                  <UnitSelection
                    datas={dataNew}
                    t={t}
                    onChange={onChangeUnits}
                    typeData={data?.type}
                    purpose={data?.purpose}
                    removeUnits={removeUnits} 

                  />
                )
                : dataNew?.lease_start_date !== null &&
                dataNew?.lease_start_date !== "" &&
                dataNew?.duration?.value !== null &&
                dataNew?.duration?.value !== "" && (
                  <UnitSelection
                    t={t}
                    datas={dataNew}
                    onChange={onChangeUnits}
                    typeData={data?.type}
                    purpose={data?.purpose}
                    removeUnits={removeUnits} 
                  />
                )
            }

          </>
          :
          !is_workspace && (
            <>
              {
                dataNew?.revenue_type?.value === "Sale"
                  ? dataNew?.earlist_occupation_date !== "" && (
                    <UnitSelectionLead
                      t={t}
                      datas={dataNew}
                      onChange={onChangeUnits}
                      typeData={data?.type}
                      purpose={data?.purpose}


                    />
                  )
                  : dataNew?.lease_start_date !== null &&
                  dataNew?.lease_start_date !== "" && (
                    <UnitSelectionLead
                      t={t}
                      datas={dataNew}
                      onChange={onChangeUnits}
                      typeData={data?.type}
                      purpose={data?.purpose}

                    />
                  )
              }
            </>
          )
      }


      <Box height={"14px"} />
      {
        dataNew?.proxy_detail?.id?.length > 0 && (
          <LeadDetailCard t={t} data={dataNew?.proxy_detail ?? {}} />
        )
      }
      <Box height={"14px"} />
      {
        dataNew?.units?.length > 0 && (
          <InterestedUnitsCard t={t} list={dataNew?.units ?? []} removeSpecificUnit={removeSpecificUnit} />
        )
      }

      <AlertDialog
        isNormal
        header={open?.type === "normal" ? t("Customer Account") : open?.type === "Agent" ? t("Broker Agent") : t("Broker Account")}
        open={open?.bool}
        onClose={() => setOpen({
          type: null,
          bool: false
        })}
        component={
          <Box height="460px" p={2}>
            {
              (open?.type === "normal") &&
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Typography className={classes.subTitle}>{t("Show only primary account")}</Typography>
                <Box> <IOSSwitch
                  width={36}
                  height={16}
                  checked={primary}
                  tamW={10}
                  tamH={10}
                  tamM={0}
                  onChange={(event) => { setDataNew({ ...dataNew, primary: event.target.checked }); setPrimary(event.target.checked) }}
                  inputProps={{ 'aria-label': 'controlled' }}
                /></Box>
              </Box>
            }
            <CustomSelectOptions
              maxMenuHeight="250px"
              menuPlacement="bottom"
              isPaginate={true}
              value={open?.type === "normal" ? account?.normal : open?.type === "Agent" ? account?.agent : account?.broker}
              onChange={(val) => {
                updateAccount(open?.type === "normal" ? "normal" : open?.type === "Agent" ? "agent" : "broker", val)
                setOpen({
                  type: null,
                  bool: false
                })
              }}
              key={JSON.stringify(primary)}
              loadOptions={(search, array, handleLoading) => {
                if (primary && open?.type === "normal") {
                  return loadOptionsApis(
                    "contact/primary_account",
                    {
                      contact_id: dataNew?.contact?.value ?? null,
                      email_id: dataNew?.contact?.value ? undefined : data?.email ?? ""
                    },
                    search,
                    array,
                    handleLoading,
                    "data"
                  )
                } else if (open?.type === "Agent") {
                  return loadOptionsApis(
                    "queries/get_vendor_account",
                    {
                      vendor_id: account?.broker?.vendor_id,
                    },
                    search,
                    array,
                    handleLoading,
                    "data",
                    {},
                    agentManualResponse
                  )
                }
                return loadOptionsApis(
                  "/queries/opportunity/contact_account",
                  {
                    company_id: dataNew?.company,
                    relationship:open?.type === "normal" ? "Customer" : "Sales Broker"
                  },
                  search,
                  array,
                  handleLoading,
                  "data"
                )
              }}
              hideName
              hideValue1={false}
            />
          </Box>
        }
      />
    </Box >
  );
};
