import {
  Grid,
  Box,
  IconButton,
  Badge,
  Button,
  Drawer,
  Pagination,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import {
  Subheader,
  SearchFilter,
  SelectBox,
  FilterGenerator,
  LoadingSection,
} from "../../components";
import { useStyles } from "./styles";
import React from "react";
import { CreateExternalUnitContext } from "./externalUnitContext";
import CardView from "./components/cardView";
import { ListView } from "./components/listView";
import AddUnit from "./createUnit";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { accessCheckRender } from "../../utils";
import NoDataFound from "../../assets/noData";
import { createState } from "./utils";

const ExternalUnits = () => {
  const {
    t = () => false,
    units,
    handleViewChange,
    handleFilter,
    handleDeleteUnit,
    activeView,
    viewUnitById,
    unitDetails,
    handleSearch,
    searchValue,
    enumValue,
    page,
    handlePagination,
    count,
    limit,
    handleChangeLimit,
    filterData,
    unitData,
    setUnitData,
    permission,
    selectedVendor,
    handleVendorChange,
    existingPricing,
    loading,
    getExternalUnits,
    handleCompanyChange,
    companyList,
    selectedCompany,
    currency,
    manualVendorResponse,
  } = React.useContext(CreateExternalUnitContext);
  const classes = useStyles();
  const [is_filter_open, set_is_filter_open] = React.useState(false);
  const [dialog, setDialog] = React.useState(false);
  const [isEdit, setEdit] = React.useState({
    id: null,
    bool: false,
  });
  const custom_options = [
    { label: "Acre", value: "1" },
    { label: "Sq.Meter", value: "2" },
    { label: "Sq.Feet", value: "3" },
    { label: "Hectare", value: "4" },
    { label: "Cents", value: "5" },
    { label: "Sq.Yard", value: "6" },
    { label: "Sq.Mile", value: "7" },
    { label: "Object", value: "8" },
    { label: "Seat", value: "9" },
  ];
  const manualCategoryMasterResponse = (array) => array?.unit_category_master;
  const totalCount = count > 0 ? count : "";

  const closeDrawer = () => {
    setUnitData({ ...createState });
    setDialog(!dialog);
    setEdit({
      id: null,
      bool: false,
    });
  };

  const addUnit = () => {
    setDialog(!dialog);
    setUnitData({
      ...unitData,
      payment_period: selectedCompany?.payment_period,
      uom_id: selectedCompany?.uom,
    });
  };

  const render = () => {
    return (
      <>
        <Subheader
          hideBackButton={true}
          title={t("External Property Units")}
          select
          options={companyList}
          value={selectedCompany}
          onchange={(e) => handleCompanyChange(e)}
          companyId={selectedCompany?.value}
        />

        <div className={classes.root}>
          <Grid container className={classes.content} spacing={2}>
            <Grid item xs={12} sm={4}>
              <SearchFilter
                value={searchValue}
                handleChange={handleSearch}
                placeholder={t("Search")}
                customfieldSx={{ height: "40px" }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              textAlign={"right"}
              display={"flex"}
              justifyContent={"end"}
            >
              <Box display={"flex"} sx={{ float: "right" }}>
                <IconButton
                  size="small"
                  className={classes.img}
                  onClick={() => set_is_filter_open(!is_filter_open)}
                >
                  <Badge
                    variant="dot"
                    color="primary"
                    classes={{ badge: classes.customBadge }}
                  >
                    <img src="/images/filter.svg" alt="filter" />
                  </Badge>
                </IconButton>
                <Box
                  sx={{ width: "350px", textAlign: "left", marginInline: 2 }}
                >
                  <SelectBox
                    className={classes.select}
                    selectHeight={"40px"}
                    padding={"0px 0px 0px 2px"}
                    placeholder={t("Select All")}
                    value={selectedVendor}
                    isPaginate
                    onChange={handleVendorChange}
                    loadOptions={(search, array, handleLoading) =>
                      loadOptionsApis(
                        "queries/contact/vendor_master/list",
                        {
                          company_id: selectedCompany?.value,
                        },
                        search,
                        array,
                        handleLoading,
                        "data",
                        {},
                        manualVendorResponse,
                        {
                          all: true,
                          all_label: t("Select All"),
                          all_value: 0,
                        }
                      )
                    }
                    key={selectedCompany?.value}
                  />
                </Box>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={addUnit}
                >
                  {t("Add Unit")}
                </Button>

                {/* View toggle using Icons only */}
                <Box display="flex" sx={{ marginLeft: 2 }}>
                  <Tooltip title={t("Card View")} placement="top" arrow>
                    <IconButton
                      onClick={() => handleViewChange("card")}
                      disableRipple
                    >
                      <img
                        src={
                          activeView === "card"
                            ? "/images/cardviewblue.svg"
                            : "/images/cardview.svg"
                        }
                        alt="Card View"
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("List View")} placement="top" arrow>
                    <IconButton
                      onClick={() => handleViewChange("list")}
                      disableRipple
                    >
                      <img
                        src={
                          activeView === "list"
                            ? "/images/listviewblue.svg"
                            : "/images/listview.svg"
                        }
                        alt="List View"
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Grid>

            {/* Conditionally render CardView based on selected view */}
            <Grid
              mt={activeView === "card" ? 4 : 2}
              item
              xs={12}
              sx={{
                height: `calc(100vh - 330px)`,
                overflowY: "auto",
              }}
            >
              {loading ? (
                <LoadingSection message={t("Loading")} />
              ) : (
                <>
                  {units?.length > 0 ? (
                    <>
                      {activeView === "card" && (
                        <Grid container spacing={2}>
                          {units?.map((unit, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                              <CardView
                                t={t}
                                unit={unit}
                                deleteUnit={() => handleDeleteUnit(unit)}
                                viewUnit={() => viewUnitById(unit)}
                                unitDetails={unitDetails}
                                unitData={unitData}
                                setUnitData={setUnitData}
                                existingPricing={existingPricing}
                                getExternalUnits={getExternalUnits}
                                companyId={selectedCompany?.value}
                                currency={currency}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      )}
                      {activeView === "list" && (
                        <Grid xs={12} container>
                          {units?.map((unit, index) => (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              key={index}
                            >
                              <ListView
                                t={t}
                                unit={unit}
                                deleteUnit={() => handleDeleteUnit(unit)}
                                viewUnit={() => viewUnitById(unit)}
                                unitDetails={unitDetails}
                                unitData={unitData}
                                setUnitData={setUnitData}
                                existingPricing={existingPricing}
                                getExternalUnits={getExternalUnits}
                                companyId={selectedCompany?.value}
                                currency={currency}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </>
                  ) : (
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      sx={{ height: "100%" }}
                    >
                      <NoDataFound />
                    </Grid>
                  )}
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"space-between"}
                style={{ padding: "12px" }}
              >
                <div className={classes.flexRow}>
                  <p className={classes.typo}>{t("Rows per page")}</p>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={limit}
                    onChange={(e) => handleChangeLimit(e.target.value)}
                    classes={{
                      root: classes.select1,
                    }}
                    className={classes.select1}
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                  >
                    <MenuItem value={12}>12</MenuItem>
                    <MenuItem value={24}>24</MenuItem>
                    <MenuItem value={36}>36</MenuItem>
                  </Select>
                </div>
                <div className={classes.flexRow}>
                  <Pagination
                    className={classes.paginate}
                    shape="rounded"
                    count={Math.ceil(totalCount / limit)}
                    page={page}
                    onChange={(e, value) => handlePagination(value)}
                    color="primary"
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
        </div>
        <FilterGenerator
          open={is_filter_open}
          onClose={() => set_is_filter_open(false)}
          onApply={(filter) => handleFilter({ filter })}
          components={[
            {
              component: "select",
              value: filterData?.unit_type,
              isMulti: true,
              label: t("Unit Type"),
              state_name: "unit_type",
              placeholder: t("Select Unit Type"),
              loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                  "queries/unit_type",
                  {
                    company_id: selectedCompany?.value,
                  },
                  search,
                  array,
                  handleLoading,
                  "data"
                ),
              debounceTimeout: 800,
              isPaginate: true,
            },
            {
              component: "select",
              value: filterData?.unit_category,
              isMulti: true,
              label: t("Unit Category"),
              state_name: "unit_category",
              placeholder: t("Select Unit Category"),
              loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                  "queries/unit_category/get",
                  {
                    company_id: selectedCompany?.value,
                  },
                  search,
                  array,
                  handleLoading,
                  "data",
                  {},
                  manualCategoryMasterResponse,
                  { manualResponseMethod: "without_extract_label" }
                ),
              debounceTimeout: 800,
              isPaginate: true,
            },
            {
              component: "select",
              value: filterData?.revenue_type,
              options: enumValue?.revenue_type ?? [],
              isMulti: true,
              label: t("Revenue Type"),
              state_name: "revenue_type",
              placeholder: t("Select Revenue Type"),
            },
            {
              component: "Slider",
              label: t("Total Area"),
              options: custom_options ?? [],
              value: filterData?.total_area,
              state_name: "total_area",
            },
          ]}
        />
        <React.Fragment key={"right"}>
          <Drawer anchor={"right"} open={dialog} onClose={closeDrawer}>
            <AddUnit
              t={t}
              closeDrawer={closeDrawer}
              unitData={unitData}
              setUnitData={setUnitData}
              isEdit={false}
              handleDeleteUnit={handleDeleteUnit}
              viewUnitById={viewUnitById}
              unitDetails={unitDetails}
              getExternalUnits={getExternalUnits}
              companyId={selectedCompany?.value}
              currency={currency}
            />
          </Drawer>
        </React.Fragment>
      </>
    );
  };
  return <div>{accessCheckRender(render, permission)}</div>;
};

export default ExternalUnits;
