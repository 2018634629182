import React from "react";
import { componentsStyle } from "./style";
import { Avatar, Box, Button, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export const MarketPlaceCard = (props) => {
  const classes = componentsStyle(props);

  const handleClick = () => {
    if (props?.data?.configuration_type === "client") {
      if (props.data?.isConfigured === true) {
        props.onConfigureClick({
          ...props.data,
          drawer_type: 2,
          configuration_type: props.data.configuration_type,
          isConfigured: props.data?.isConfigured,
          access_token: props.data?.accessToken,
          business_id: props.data?.businessId,
          phonenumber_id: props.data?.phoneNumberId,
          company: props.data?.company,
          emailId: props.data?.emailId,
        });
      } else {
        props.onConfigureClick({
          ...props.data,
          drawer_type: 2,
          configuration_type: props.data.configuration_type,
          isConfigured: props.data?.isConfigured,
          access_token: props.data?.accessToken,
          business_id: props.data?.businessId,
          phonenumber_id: props.data?.phoneNumberId,
          company: props.data?.company,
          emailId: props.data?.emailId,
        });
      }
    } else {
      props.onClick(props.data, props.item);
    }
  };

  return (
    <>
      <Box className={classes.parentBox}>
        {props?.master && (
          <Box className={classes.imgParent}>
            {props?.data?.logo?.length > 0 ? (
              <img src={props?.data?.logo} alt="s" className={classes.img} />
            ) : (
              <Avatar className={classes.img} />
            )}
          </Box>
        )}
        {props?.master === false && (
          <Box className={classes.imgParent2}>
            <Box>
              {props?.data?.logo?.length > 0 ? (
                <img src={props?.data?.logo} alt="s" className={classes.img2} />
              ) : (
                <Avatar className={classes.img2} />
              )}
            </Box>
            <Box>
              <span
                className={props?.data?.purchased ? classes.tag : classes.tag1}
              >
                {props?.data?.purchased ? "Purchased" : "Not Purchased"}
              </span>
            </Box>
          </Box>
        )}
        <Box>
          <Typography className={classes.header}>
            {props?.data?.name ?? ""}
          </Typography>
          <Typography className={classes.subHeader}>
            {props?.data?.description ?? props?.data?.desc ?? ""}
          </Typography>
        </Box>
        {(props?.item === undefined || props?.data?.purchased) && (
          <Box>
            <Button
              className={classes.btn}
              variant="text"
              endIcon={<KeyboardArrowRightIcon />}
              onClick={handleClick}
            >
              {props?.data?.configuration_type === "client"
                ? props?.data?.isConfigured === true
                  ? "Edit (Configured)"
                  : "Configure"
                : "View"}
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};
