import {
  Avatar,
  Box,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
  IconButton,
} from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { SelectBox, Subheader, TableWithPagination } from "../../components";
import styled from "@mui/material/styles/styled";
import { Contract360Style } from "./style";
import { ContractDetailCard } from "./components/contractDetailCard";
import {
  InspectionOrderDatatype,
  InspectionOrderHeading,
  InspectionOrderPath,
  InvoiceDatatype,
  InvoiceHeading,
  InvoicePath,
  MaintenanceRequestDatatype,
  MaintenanceRequestHeading,
  MaintenanceRequestPath,
  PaymentDatatype,
  PaymentHeading,
  PaymentPath,
  ReceiptsDatatype,
  ReceiptsHeading,
  ReceiptsPath,
  TabTitleList,
  UnitsDatatype,
  UnitsHeading,
  UnitsPath,
} from "./utils/contract360";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import moment from "moment";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import {
  AlertProps,
  NetWorkCallMethods,
  accessCheckRender,
  getCompanyOption,
  getRoutePermissionNew,
} from "../../utils";
import ContractIcon from "./utils/contractIcon";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import ContractTypeIcon from "./utils/contractTypeicon";
import AccountIcon from "./utils/accountIcon";
import DateIcon from "./utils/dateIcon";
import { useNavigate } from "react-router-dom";
import NoContractIcon from "./utils/noContractIcon";
import { DownloadImage } from "../../assets";

const CustomPaper = styled("div")(({ theme }) => ({
  boxShadow: "0px 0px 16px #00000014",
  borderRadius: 4,
  backgroundColor: "#ffffff",
  // padding: theme.spacing(2),
  margin: theme.spacing(2),
  // height: 'auto'
}));

const Contract360 = ({ t = () => false }) => {
  const classes = Contract360Style();
  const auth = React.useContext(AuthContext);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const navigate = useNavigate;
  const [contractData, setContractData] = React.useState({
    page: 1,
    limit: 10,
    tabValue: "Invoice",
    selectedContract: "",
    tableData: {},
    show: false,
  });
  const [permission, setPermission] = React.useState({});
  const [companyList, setCompanyList] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState({});

  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        getCompany();
      }
    }
    //eslint-disable-next-line
  }, [auth]);

  const getCompany = () => {
    let company = getCompanyOption(backdrop, auth, alert);
    if (company) {
      setCompanyList(company?.list);
      setSelectedCompany(company?.selected);
      getContractData(company?.selected?.value);
    }
  };

  const updateState = (key, value) => {
    setContractData({ ...contractData, [key]: value });
  };

  const handleCompanyChange = (e) => {
    // resetContract()
    getContractData(e?.value);
    setSelectedCompany(e);
  };

  const manualContractResponse = (array) => {
    const data = array?.map((e) => {
      return {
        ...e,
        label: e?.contract_no,
        value: e?.id,
      };
    });
    return data;
  };

  const handleTabChange = (event, newValue) => {
    // updateState("tabValue", newValue);
    getContractTableData(newValue, 0, 10);
  };

  const handlePagination = (e) => {
    updateState("page", e);
    let offset = (e - 1) * contractData?.limit;
    getContractTableData(contractData?.tabValue, offset, 10);
  };

  const handleChangeLimit = (e) => {
    setContractData({ ...contractData, limit: e, page: e });
    getContractTableData(contractData?.tabValue, 0, e);
  };

  const handleIcon = (type, data) => {
    if (type === "view") {
    }
  };

  const selectContract = (contract_data) => {
    updateState("selectedContract", contract_data);
    getContractTableData("Invoice", 0, 10, contract_data);
  };

  const getContractData = (company_id) => {
    const payload = {
      company_id: company_id,
      offset: 0,
      limit: 10,
      search: "",
    };
    NetworkCall(
      `${config.api_url}/contract/contract_list_360`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        if (res?.data?.data?.length > 0) {
          const resData = res?.data?.data?.[0];
          const constructedData = {
            ...resData,
            label: resData?.contract_no,
            value: resData?.id,
          };
          getContractTableData("Invoice", 0, 10, constructedData, company_id);
        } else {
          setContractData({
            ...contractData,
            selectedContract: "",
            show: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: AlertProps.message.some_thing_went_wrong,
        });
      });
  };

  const getContractTableData = (
    type,
    offset,
    limit,
    contract_data = contractData?.selectedContract,
    company_id = selectedCompany?.value
  ) => {
    const payload = {
      company_id: company_id,
      contract_id: contract_data?.value,
      list_type: type,
      account_no: contract_data?.account?.account_no,
      offset: offset,
      limit: limit,
    };
    NetworkCall(
      `${config.api_url}/contract/contract_360`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const resData = res?.data;
        switch (type) {
          case "Invoice":
            setContractData({
              ...contractData,
              selectedContract: contract_data,
              tabValue: type,
              show: true,
              tableData: {
                heading: InvoiceHeading(t),
                path: InvoicePath,
                datatype: InvoiceDatatype,
                list: resData?.data?.map((i) => {
                  return {
                    invoice_no: i?.invoice_no,
                    invoice_type: i?.invoice_type,
                    invoice_total_amount: `${i?.currency_symbol} ${i?.invoice_total_amount}`,
                    invoice_due_amount: `${i?.currency_symbol} ${i?.invoice_due_amount}`,
                    invoice_date: i?.invoice_date,
                    invoice_tax: `${i?.currency_symbol} ${i?.invoice_total_tax_amount}`,
                    due_date: i?.payment_due_date,
                    status: i?.payment_status?.replace(
                      /\b([a-z])/g,
                      (_, match) => match.toUpperCase()
                    ),
                  };
                }),
                count: resData?.count,
              },
            });
            break;
          case "Payment":
            setContractData({
              ...contractData,
              tabValue: type,
              show: true,
              tableData: {
                heading: PaymentHeading(t),
                path: PaymentPath,
                datatype: PaymentDatatype,
                list: resData?.data?.map((i) => {
                  return {
                    settlement: i?.settlement_no,
                    invoice_no: i?.invoice_no,
                    tagged_date: i?.tagged_date,
                    receipt_no: i?.reciepts_no,
                    source: i?.source,
                    tagged_amount: `${i?.currency_symbol} ${i?.amount_tagged}`,
                  };
                }),
                count: resData?.count,
              },
            });
            break;
          case "Receipt":
            setContractData({
              ...contractData,
              tabValue: type,
              show: true,
              tableData: {
                heading: ReceiptsHeading(t),
                path: ReceiptsPath,
                datatype: ReceiptsDatatype,
                list: resData?.data?.map((i) => {
                  return {
                    receipt_no: i?.reciepts_no,
                    receipt_date: i?.payment_date,
                    receipt_amount: `${i?.currency_symbol} ${i?.amount_paid}`,
                    unused_balance: `${i?.currency_symbol} ${i?.account_balance}`,
                    payment_method: i?.payment_mode,
                  };
                }),
                count: resData?.count,
              },
            });
            break;
          case "Maintenance":
            setContractData({
              ...contractData,
              tabValue: type,
              show: true,
              tableData: {
                heading: MaintenanceRequestHeading(t),
                path: MaintenanceRequestPath,
                datatype: MaintenanceRequestDatatype,
                list: resData?.data?.map((i) => {
                  return {
                    request_id: i?.maintenance_request_no,
                    description: i?.description,
                    property_name: i?.propety_name,
                    unit_name: i?.unit_name,
                    category: i?.category_master?.name,
                    raised_on: i?.raised_on,
                    date_closed: i?.status?.[0]?.closed_on,
                    status: i?.status?.[0]?.type,
                  };
                }),
                count: resData?.count,
              },
            });
            break;
          case "Inspection Jobs":
            setContractData({
              ...contractData,
              tabValue: type,
              show: true,
              tableData: {
                heading: InspectionOrderHeading(t),
                path: InspectionOrderPath,
                datatype: InspectionOrderDatatype,
                list: resData?.data?.map((i) => {
                  return {
                    job_id: i?.reference_id,
                    job_type: i?.job_type,
                    unit_name: i?.unit_name,
                    job_date: i?.job_date,
                    inspector_name: i?.inspector_name,
                    status: i?.status,
                  };
                }),
                count: resData?.count,
              },
            });
            break;
          case "Units":
            setContractData({
              ...contractData,
              selectedContract: contract_data,
              tabValue: type,
              show: true,
              tableData: {
                heading: UnitsHeading(t),
                path: UnitsPath,
                datatype: UnitsDatatype,
                list: resData?.data?.map((i) => {
                  return {
                    unit_no: i?.unit_no,
                    unit_name: i?.unit_name,
                    property_no: i?.property_no,
                    property_name: i?.property_name,
                    location: `${i?.city ?? ""}${i?.city && ","} ${
                      i?.state ?? ""
                    }`,
                    move_in_date: i?.move_in_date,
                    move_out_date: i?.move_out_date,
                    status: i?.status,
                  };
                }),
                count: resData?.count,
              },
            });
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: AlertProps.message.some_thing_went_wrong,
        });
      });
  };

  const handleClick = () => {
    const payload = {
      contract_id: contractData?.selectedContract?.value,
    };
    NetworkCall(
      `${config.api_url}/360_reports/contract_downloads`,
      NetWorkCallMethods.get,
      null,
      payload,
      true,
      false
    )
      .then((res) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "File downloaded successfully",
        });
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: AlertProps.message.some_thing_went_wrong,
        });
      });
  };

  const render = () => {
    return (
      <>
        <Subheader
          title={t("Contract 360")}
          select
          options={companyList}
          value={selectedCompany}
          onchange={(e) => handleCompanyChange(e)}
          goBack={() => navigate()}
        />
        <Box sx={{ overflow: "auto", height: "calc(100vh - 120px)" }}>
          <CustomPaper>
            <Box p={2}>
              <Box>
                <Grid container>
                  <Grid item lg={3} md={4} sm={4} xs={8}>
                    <SelectBox
                      label={""}
                      placeholder={t("Select Contract ID")}
                      borderRadius={"8px"}
                      key={JSON.stringify(selectedCompany)}
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "contract/contract_list_360",
                          {
                            company_id: selectedCompany?.value,
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualContractResponse
                        )
                      }
                      selectHeight="40px"
                      isPaginate
                      value={contractData?.selectedContract}
                      menuPlacement={"bottom"}
                      onChange={(value) => selectContract(value)}
                      customOptionComponent={(props) => {
                        return (
                          <Stack
                            direction={"row"}
                            m={"4px 8px"}
                            p={0.5}
                            alignItems={"center"}
                            className={
                              props?.isSelected
                                ? classes.dropdownSelected
                                : classes.dropdownNotSelected
                            }
                          >
                            <Avatar sx={{ height: 32, width: 32 }}></Avatar>
                            <Stack sx={{ marginInlineStart: "16px" }}>
                              <Typography className={classes.optionHeader}>
                                {props?.data?.label}
                              </Typography>
                              <Stack direction={"row"} alignItems={"center"}>
                                <Typography
                                  className={classes.optionSubTitle}
                                  noWrap
                                >
                                  {props?.data?.type?.name}
                                </Typography>
                                <Box className={classes.dot}></Box>
                                <Typography
                                  className={classes.optionSubTitle}
                                  noWrap
                                >
                                  {props?.data?.account?.account_no}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item lg={9} md={8} sm={8} xs={4}>
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        float: "right",
                      }}
                    >
                      <Stack
                        direction="row"
                        spacing={0.6}
                        display={"flex"}
                        alignItems="center"
                      >
                        {/* <IconButton
                          size="small"
                          className={classes.img}
                          onClick={handleClick}
                        >
                          <DownloadImage />
                        </IconButton> */}
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    lg={9}
                    md={8}
                    sm={8}
                    justifyContent={"end"}
                    display={"flex"}
                  >
                    {/* <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                                            <Button variant='outlined' disableElevation className={classes.resetBtn}
                                                onClick={() => resetContract()}>{t("Reset")}</Button>
                                            <Button variant='contained' disableElevation
                                                onClick={() => searchContract()}
                                                className={classes.searchBtn}>{t("Search")}</Button>
                                        </Stack> */}
                  </Grid>
                </Grid>
              </Box>
              {contractData?.selectedContract !== "" && contractData?.show && (
                <Box mt={1}>
                  <Typography className={classes.contractDetailHeader}>
                    {t("Contract Details")}
                  </Typography>
                  <Grid container columnSpacing={1} mt={"0px"} rowSpacing={1}>
                    <Grid item xl={3} lg={3} md={6} sm={6}>
                      <ContractDetailCard
                        logo={<ContractIcon />}
                        title={contractData?.selectedContract?.contract_no}
                        subtitle={t("Contract ID")}
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={6} sm={6}>
                      <ContractDetailCard
                        logo={<ContractTypeIcon />}
                        title={`${
                          contractData?.selectedContract?.type?.name
                        } ${t("Contract")}`}
                        subtitle={t(
                          contractData?.selectedContract?.account?.relationship
                        )}
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={6} sm={6}>
                      <ContractDetailCard
                        logo={<AccountIcon />}
                        title={t(
                          contractData?.selectedContract?.account?.account_no
                        )}
                        subtitle={t(
                          contractData?.selectedContract?.account?.name
                        )}
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={6} sm={6}>
                      <ContractDetailCard
                        logo={<DateIcon />}
                        title={`${moment(
                          contractData?.selectedContract?.start_date
                        ).format("DD MMM YY")} - ${moment(
                          contractData?.selectedContract?.end_date
                        ).format("DD MMM YY")}`}
                        subtitle={t("Contract Start & End Date")}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
          </CustomPaper>
          {contractData?.selectedContract !== "" && contractData?.show ? (
            <CustomPaper>
              <Box>
                <Tabs
                  value={contractData?.tabValue}
                  onChange={handleTabChange}
                  className={classes.tabs}
                >
                  {TabTitleList(t)?.map((i, index) => {
                    return (
                      <Tab
                        value={i?.value}
                        label={i?.label}
                        className={classes.tabTitle}
                        sx={{ marginLeft: index === 4 && "16px" }}
                      />
                    );
                  })}
                </Tabs>
              </Box>
              <Box p={2}>
                <TableWithPagination
                  heading={contractData?.tableData?.heading}
                  rows={contractData?.tableData?.list ?? []}
                  dataType={contractData?.tableData?.datatype}
                  path={contractData?.tableData?.path}
                  showpagination
                  tableType="no-side"
                  handleIcon={handleIcon}
                  handlePagination={handlePagination}
                  handleChangeLimit={handleChangeLimit}
                  totalRowsCount={contractData?.tableData?.count}
                  page={contractData?.page}
                  limit={contractData?.limit}
                  view={true}
                  height={`calc(100vh - 500px)`}
                  edit={true}
                  delete={true}
                  // extraData={data?.key}
                  noDataSvg={true}
                />
              </Box>
            </CustomPaper>
          ) : (
            <Box className={classes.noContractDisplay}>
              <Stack rowGap={1} alignItems={"center"}>
                <NoContractIcon />
                <Typography className={classes.noContractText}>
                  {t("No Contract selected to display")}
                </Typography>
              </Stack>
            </Box>
          )}
        </Box>
      </>
    );
  };

  return <div>{accessCheckRender(render, permission)}</div>;
};

export default withTranslation("contract360")(Contract360);
