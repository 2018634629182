import React, { useState } from "react";
import { dashboardStyles } from "./style";
import { PropertyManager, Revenue, Sales } from "./assests";
import { Tabs } from "./component";
import {
  AlertContext,
  AuthContext,
  BackdropContext
} from "../../contexts";
import DashBoard from "../propertymanagerdashboard/dashboard";
import LeaseManagerDashboard from "./leasemanerdashboard";
import RevenueDashboard from "./revenuedashboard";
import { getCalendarOptions, getCompanyOption, accessCheckRender, pageName, getRoutePermissionNew, LocalStorageKeys } from "../../utils";
import { Routes } from "../../router/routes";
import { withTranslation } from "react-i18next";

export const LeaseAndSalesManagerDashboard = (props) => {
  const { loading, handleLoading, t } = props;

  // classes
  const pathname = window.location.pathname;
  const classes = dashboardStyles();
  const [value, setValue] = useState(
    pathname === Routes?.salesDashboard ? 2 :
      pathname === Routes?.financeDashboard ? 3 : 1);
  const title = pathname === Routes?.salesDashboard ? t("CRM Dashboard") :
    pathname === Routes?.financeDashboard ? t("Accounts Receivable Dashboard") : t("Property Dashboard");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [calandervalue, setCalanderValue] = useState(getCalendarOptions()[6]);
  // context
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext);
  const [permission, setPermission] = React.useState({})
  const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId)
  const [selectedTeams, setSelectedTeams] = React.useState('')
  const [teams_list, set_teams_list] = React.useState([])
  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        getCompany()
        handleLoading(false)
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  // tab title
  const tabTittle = (t) => [
    {
      label: t("Property Dashboard"),
      imgSelect: (
        <PropertyManager color={value === 1 ? "#5078E1" : "#98A0AC"} />
      ),
      value: 1,
      className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
    },
    {
      label: t("CRM Dashboard"),
      imgSelect: <Sales color={value === 2 ? "#5078E1" : "#98A0AC"} />,
      value: 2,
      className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
    },
    {
      label: t("Revenue Dashboard"),
      imgSelect: <Revenue color={value === 3 ? "#5078E1" : "#98A0AC"} />,
      value: 3,
      className: value === 3 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 3 ? classes.selectBack1 : classes.selectBack,
    },
  ];

  // tab change function
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // company switcher
  const handleCompanyChange = (e) => {
    setSelectedCompany(e);
  };
  // company switcher
  const handleCalander = (e) => {
    setCalanderValue(e);
  };

  const getCompany = () => {
    let company = getCompanyOption(backdrop, auth, alert)
    if (company) {
      setCompanyList(company?.list)
      setSelectedCompany(company?.selected)
    }
  }
  // Function to change team
  const handleTeamsChange = (team) => {
    setSelectedTeams(team)
  }

  // Function to get Team List
  const getTeamsOption = (list) => {
    set_teams_list(list)
  }

  const render = () => {
    return <Tabs
      isTabHide
      title={title}
      value={value}
      isHeigth
      tab1={<DashBoard selectedcompany={selectedCompany} selectedTeams={selectedTeams} t={t} />}
      tab2={<LeaseManagerDashboard selectedcompany={selectedCompany} calandervalue={calandervalue} selectedTeams={selectedTeams} teams_list={teams_list} t={t} />}
      tab3={<RevenueDashboard selectedcompany={selectedCompany} calandervalue={calandervalue} selectedTeams={selectedTeams} t={t} />}
      handleChange={handleChange}
      tabTittle={tabTittle}
      select
      calanderselect={value === 1 ? false : true}
      options={companyList}
      selectvalue={selectedCompany}
      calendarOptions={getCalendarOptions()}
      calanderValue={calandervalue}
      onchange={(e) => {
        handleCompanyChange(e);
      }}
      onChangeCalendar={(e) => {
        handleCalander(e);
      }}
      selectTeamsOptions
      handleTeamsChange={handleTeamsChange}
      companyId={selectedCompany?.value}
      moduleId={moduleId}
      selectedTeams={selectedTeams}
      getTeamsOption={getTeamsOption}
    />

  }

  return (
    <div>
      {accessCheckRender(render, permission, pageName.dashboard, loading)}
    </div>
  );
};

export default withTranslation("leaseAndSalesManagerDashboard")(LeaseAndSalesManagerDashboard);
