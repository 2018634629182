import React from "react";
import { useStyles } from "../styles";
import {
  Box,
  IconButton,
  Typography,
  Grid,
  Menu,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  LoadingSection,
  TextBox,
  ToggleButtonComponent,
} from "../../../components";
import { FontFamilySwitch, wrapLabelsInT } from "../../../utils";
import BackgroundType from "../../../assets/leaseBackground";
import QuotationLeaseIcon from "../../../assets/quotationLeaseIcon";
import { DeleteIcon } from "../../../assets";

const Step2 = ({
  t,
  updatePricingState,
  loading,
  unitData,
  enumValue,
  updateState,
  CustomtoolTip,
  pricingInfo,
  chargeOptions,
  handleChange,
  handleRemoveCharge,
  handleAddClick,
  anchorEl,
  handleMenuClose,
  handleAddCharge,
  currency,
}) => {
  const classes = useStyles();
  const language = localStorage.getItem("i18nextLng");

  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: "white",
        border: "1px solid #E4E8EE",
        borderRadius: "4px",
      }}
    >
      {loading ? (
        <Box sx={{ height: `calc(100vh - 125px)`, overflow: "auto" }}>
          <LoadingSection message="Fetching Details ..." top="30vh" />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: "0.875rem",
                fontfamily: FontFamilySwitch().bold,
                color: "#091B29",
              }}
            >
              {t("Payment Details")}
            </Typography>
            <Box
              className={
                language === "ar" ? classes.typeBgArabic : classes.typeBg
              }
            >
              <BackgroundType
                color={
                  unitData?.revenue_type?.value === "Lease"
                    ? "#6d6db3"
                    : unitData?.revenue_type?.value === "Sale"
                    ? "#f4a261"
                    : "#690375"
                }
              />
              <Box className={classes.centeredText} display={"flex"}>
                <QuotationLeaseIcon />
                <Typography className={classes.type}>
                  {t(unitData?.revenue_type?.label)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ p: 1 }}>
            <Grid container spacing={2}>
              {/* Payment Period Section */}
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <Box>
                    {/* Payment Period */}
                    <ToggleButtonComponent
                      label={t("Payment Period")}
                      options={wrapLabelsInT(enumValue?.pricing_period_type, t)}
                      value={unitData?.payment_period}
                      onChange={(value) => updateState("payment_period", value)}
                      isrequired={true}
                      isError={unitData?.error?.payment_period}
                      errorMessage={unitData?.error?.payment_period}
                      gap={5}
                    />
                  </Box>
                  <Box sx={{ width: { xs: "100%", sm: "49%" } }}>
                    <Box
                      display="flex"
                      alignItems="center"
                      gap={0.5}
                      paddingBottom={"8px"}
                    >
                      <Typography
                        sx={{
                          color: "#98A0AC",
                          fontSize: "0.75rem",
                          fontfamily: FontFamilySwitch().semiBold,
                        }}
                      >
                        {t("Primary Charges")}
                      </Typography>
                      <CustomtoolTip title={pricingInfo}>
                        <InfoOutlinedIcon
                          sx={{
                            fontSize: "1rem",
                            color: "#4E5A6B",
                            marginBottom: "-2px",
                          }}
                        />
                      </CustomtoolTip>
                    </Box>
                    <TextBox
                      label={""}
                      value={unitData?.pricingData?.primary}
                      placeholder={t("Enter Primary Charges")}
                      onChange={(value) =>
                        updatePricingState("primary", value.target.value)
                      }
                      isError={
                        unitData?.error?.pricingData?.primary?.length > 0
                      }
                      errorMessage={unitData?.error?.pricingData?.primary}
                      isRequired={true}
                      fontSize={"0.875rem !important"}
                      type="number"
                      endAdornment={
                        <InputAdornment position="end">
                          {currency + " / " + t(unitData?.payment_period) ?? ""}
                        </InputAdornment>
                      }
                    />
                  </Box>
                </Box>
              </Grid>

              {/* Charges Grid Container */}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {/* Additional Charges Sections */}
                  {Object.entries(chargeOptions).map(
                    ([key, { label, tooltip }]) =>
                      unitData.pricingData[key].length > 0 ? (
                        <Grid item xs={12} sm={6} key={key}>
                          <Box>
                            <Box
                              display="flex"
                              alignItems="center"
                              gap={0.5}
                              sx={{ mb: 1 }}
                            >
                              <Typography
                                sx={{
                                  color: "#98A0AC",
                                  fontSize: "0.75rem",
                                  fontFamily: FontFamilySwitch().semiBold,
                                }}
                              >
                                {t(label)}
                              </Typography>
                              <CustomtoolTip title={tooltip}>
                                <InfoOutlinedIcon
                                  sx={{
                                    fontSize: "1rem",
                                    color: "#4E5A6B",
                                    mb: "-2px",
                                  }}
                                />
                              </CustomtoolTip>
                            </Box>
                            <TextBox
                              label={""}
                              value={unitData.pricingData[key][0]?.value || ""}
                              placeholder={`${t("Enter")} ${t(label)}`}
                              onChange={(e) =>
                                handleChange(key, e.target.value)
                              }
                              type="number"
                              endAdornment={
                                <>
                                  <InputAdornment position="end">
                                    {currency +
                                      " / " +
                                      (label === t("Secondary Charges")
                                        ? t(unitData?.payment_period)
                                        : t("Total") ?? "")}
                                  </InputAdornment>
                                  <IconButton
                                    onClick={() => handleRemoveCharge(key)}
                                  >
                                    <DeleteIcon color="error" />
                                  </IconButton>
                                </>
                              }
                              isError={!!unitData.pricingData[key][0]?.error}
                              errorMessage={unitData.pricingData[key][0]?.error}
                            />
                          </Box>
                        </Grid>
                      ) : null
                  )}
                </Grid>
              </Grid>

              {/* Add More Charges Button */}
              <Grid item xs={12}>
                {Object.entries(chargeOptions).some(
                  ([key]) => unitData.pricingData[key].length === 0
                ) && (
                  <Box
                    sx={{
                      cursor: "pointer",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                    onClick={handleAddClick}
                  >
                    <Typography
                      sx={{
                        fontSize: "0.875rem",
                        color: "#5078E1",
                        fontFamily: FontFamilySwitch().semiBold,
                      }}
                    >
                      {`+ ${t("Add More Charges")}`}
                    </Typography>
                  </Box>
                )}
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  {Object.entries(chargeOptions)
                    .filter(([key]) => unitData.pricingData[key].length === 0)
                    .map(([key, { label }]) => (
                      <MenuItem key={key} onClick={() => handleAddCharge(key)}>
                        {t(label)}
                      </MenuItem>
                    ))}
                </Menu>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Step2;
