import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import Exporting from "highcharts/modules/exporting";
import { FontFamilySwitch } from "../../../utils";

Exporting(Highcharts);

export const HighChartsBar = ({
  data = {},
  options = {},
  isShowLegend = false,
  chartHeight = "",
  graphTitle,
}) => {
  const chartRef = useRef(null);
  const highchartRef = useRef(null);

  useEffect(() => {
    if (!highchartRef.current) {
      highchartRef.current = Highcharts.chart(chartRef.current, {
        colors: [
          "#278ECF",
          "#48D762",
          "#FFCA1F",
          "#FF9416",
          "#D42AE8",
          "#535AD7",
          "#FF402C",
          "#83BFFF",
          "#6ED88F",
          "#FFE366",
        ],
        chart: {
          type: "column", // Change this to "bar" for a horizontal bar chart
          ...options.chart,
        },
        credits: {
          enabled: false
        },
        title: {
          text: graphTitle,
          align: "left",
          style: {
            color: "#091B29",
            fontSize: "1rem",
            fontFamily: FontFamilySwitch().extraBold,
            marginInlineStart: "8px",
          },
        },
        xAxis: {
          categories: data.labels,
          ...options.xAxis,
        },
        yAxis: {
          min: 0,
          ...options.yAxis,
        },
        exporting: {
          enabled: true, // Enable the exporting module
        },
        plotOptions: {
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: true,
              ...options.plotOptions?.column?.dataLabels,
            },
            ...options.plotOptions?.column,
          },
          ...options.plotOptions,
        },
        legend: {
          enabled: isShowLegend,
          align: "center",
          verticalAlign: "bottom",
          layout: "horizontal",
          margin: 15,
          ...options.legend,
        },
        series: data.datasets.map((dataset) => {
          return {
            name: dataset.label,
            // convert string in data array to int
            // data: dataset.data,
            data: dataset.data.map((item) => parseInt(item)),
          };
        }),
        ...options,
      });
    } else {
      highchartRef.current.update({
        xAxis: {
          categories: data.labels,
          ...options.xAxis,
        },
        series: data.datasets.map((dataset) => ({
          name: dataset.label,
          data: dataset.data.map((item) => parseInt(item)),
        })),
      });
    }
    if (!highchartRef.current) {
      highchartRef.current = Highcharts.chart(chartRef.current, options);
    } else {
      highchartRef.current.update(options);
    }

    const handleFullscreenChange = () => {
      if (highchartRef.current) {
        highchartRef.current.reflow();
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      if (highchartRef.current) {
        highchartRef.current = null;
      }
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };

  }, [data, options, isShowLegend, graphTitle]);

  return (
    <div ref={chartRef} style={{ width: "100%", height: chartHeight }}></div>
  );
};
