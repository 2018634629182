import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const VerticalStepperStyles = makeStyles((theme) => ({

    connector: {
        borderColor: theme.palette.border.secondary,
        borderLeftStyle: "dashed",
        borderLeftWidth: "1px",
        height: "30px",
        marginLeft: "7px",
        marginTop: "-5px"
    },
    customconnector: {
        borderColor: "#5078E1",
        borderLeftStyle: "dashed",
        borderLeftWidth: "1px",
        height: "30px",
        marginLeft: "18px",
        marginTop: "0px"
    },
    Name: {
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
    },
    description: {
        color: theme.typography.color.tertiary,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().regular,
    },
    detail: {
        marginInlineStart: "15px",
        // marginTop: "-10px"
    },
    applybackground:{
        backgroundColor:"#F2F4F7",
        width:"40px",
        height:"40px",
        borderRadius:"50%",
        textAlign:"center",
        alignItems:"center",
        justifyContent:"center",
        display:"flex"
    },
    notes:{
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color:theme.typography.color.secondary,
        marginTop:"3px"
    }
}))