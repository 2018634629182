import { Box, Grid } from "@mui/material";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  FilterGenerator,
  LoadingSection,
  Subheader,
  UseDebounce,
} from "../../components";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import {
  accessCheckRender,
  getRoutePermissionNew,
  LocalStorageKeys,
  NetWorkCallMethods,
} from "../../utils";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { unitStyles } from "./style";
import { AccountTable, DetailsContainer } from "./component";
import { AuthContext } from "../../contexts";
import { withTranslation } from "react-i18next";

const UnitList = (props) => {
  const defaultFilterState = { status: [true] };
  const { t } = props;
  const classes = unitStyles();
  const navigate = useNavigate();
  const search = useLocation().search;
  const [unitlist, setUnitList] = React.useState({
    data: [],
    count: 0,
    property: {},
    breadCrumbs: {},
  });

  const [drawer, setDrawer] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const floorID = new URLSearchParams(search).get("floorID");
  const propertyID = new URLSearchParams(search).get("propertyID");
  const blockID = new URLSearchParams(search).get("blockID");
  const [loading, setLoading] = React.useState(true);
  const debounce = UseDebounce();
  const [filterData, setFilterData] = React.useState(defaultFilterState);
  const [stats, setStats] = React.useState(null);
  const auth = React.useContext(AuthContext);
  const [permission, setPermission] = React.useState({});

  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");
  const limitParam = searchParams.get("limit");

  // Default values if not set in URL
  const pageFromParams = pageParam ? parseInt(pageParam, 10) : 1;
  const limitFromParams = limitParam ? parseInt(limitParam, 10) : 10;

  const [page, setPage] = React.useState(pageFromParams);
  const [limit, setLimit] = React.useState(limitFromParams);

  const updatePage = (newPage, newLimit = limit) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", newPage.toString());
    searchParams.set("limit", newLimit.toString());

    // Update URL without reloading
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });

    // Update state
    setPage(newPage);
    setLimit(newLimit);
  };

  //handle pagination
  const handlePagination = (newPage) => {
    updatePage(newPage, limit);
  };

  const handleChangeLimit = (newLimit) => {
    updatePage(1, newLimit); // Reset page to 1 when limit changes
  };

  const currentOffset = (page - 1) * limit;
  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        getunitlist(
          propertyID ? propertyID : blockID ? blockID : floorID,
          filterData,
          "",
          currentOffset,
          limit
        );
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  const headerpage = [
    {
      id: "1",
      label: unitlist?.breadCrumbs?.block_name,
      type: "name",
    },
    {
      id: "2",
      label: unitlist?.breadCrumbs?.floor_name,
      type: "name",
    },
    {
      id: "3",
      label: "Unit",
    },
  ];
  const headerpage1 = [
    {
      id: "1",
      label: unitlist?.breadCrumbs?.block_name,
      type: "name",
    },
    {
      id: "3",
      label: "Unit",
    },
  ];
  const headerpage2 = [
    {
      id: "3",
      label: "Unit",
    },
  ];

  const getunitlist = (id, filterProp, searchText, offSet, limits) => {
    const variables = {
      tenantId: `${config.tenantId}`,
      property_id: propertyID ?? null,
      block_id: blockID ?? null,
      floor_id: floorID ?? null,
      offset: offSet,
      limit: limits,
      unit_type: filterProp?.unit_type?.length
        ? filterProp?.unit_type?.map(({ value }) => value)
        : [],
      status: filterProp?.status?.length ? filterProp?.status : [],
      unit_category: filterProp?.unit_category?.length
        ? filterProp?.unit_category?.map(({ value }) => value)
        : [],
      search: searchText,
    };
    NetworkCall(
      `${config.api_url}/unit/list`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((res) => {
        const list = res.data.list?.map((val) => {
          let _d;
          try {
            _d = {
              unitNo: val?.unit_no,
              unitName: val?.unit_name,
              unitcategory: val?.unit_category,
              revenuetype: val?.revenue_type,
              propertyName: val?.property?.name,
              unitpurpose: val?.unit_purpose,
              // eslint-disable-next-line
              totalarea: `${val?.total_area}  ${val?.area_metric ?? ""}`,
              furnishing: val?.furnishing,
              baths: val?.total_baths ? val?.total_baths : " - ",
              id: val?.unit_id,
              bed: val?.total_bed_rooms ? val?.total_bed_rooms : " - ",
              icon: "editview",
              occupancyStatus: val?.current_status
                ? val?.current_status
                : " - ",
              status: val?.is_active ? "Active" : "Inactive",
              blockName: val?.block_name,
              floorName: val?.floor_name,
              companyName: val?.company_name,
              // eslint-disable-next-line
              location: val?.property?.address?.city
                ? val?.property?.address?.city
                : val?.property?.address?.district,
            };
          } catch (err) {}
          return _d;
        });
        if (propertyID) {
          setUnitList({
            count: res?.data?.count,
            data: list,
            property: {
              name: res.data?.property?.name,
              id: res.data?.property?.id,
              property_hierarchy: res.data?.property?.property_hierarchy,
            },
            breadCrumbs: res?.data?.breadCrumbs,
          });
        } else if (blockID) {
          setUnitList({
            count: res?.data?.count,
            data: list,
            property: {
              name: res.data?.property?.name,
              id: res.data?.property?.id,
              property_hierarchy: res.data?.property?.property_hierarchy,
            },
            breadCrumbs: res?.data?.breadCrumbs,
          });
        } else {
          setUnitList({
            count: res?.data?.count,
            data: list,
            property: {
              name: res.data?.property?.name,
              id: res.data?.property?.id,
              property_hierarchy: res.data?.property?.property_hierarchy,
            },
            breadCrumbs: res?.data?.breadCrumbs,
          });
        }
        getUnitStats(res?.data?.list?.[0]?.unit_id);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleIcon = (type, data) => {
    if (type === "view") {
      getUnitStats(data?.id);
    }
    if (type === "edit") {
      navigate(Routes.createUnitNew, {
        state: {
          main: {
            unitID: data?.id,
            isEdit: true,
          },
        },
      });
    }
    if (type === "info") {
      navigate(`/unitDetails?id=${data?.id}`);
    }
  };
  const handleOnClickAddUnitButton = () => {
    navigate(Routes.createUnitNew, {
      state: {
        main: {
          floorID,
          blockID,
          propertyID,
        },
      },
    });
  };

  const handleSearch = (e) => {
    setSearchText(e);
    debounce(() => searchTableFunction(e), 800);
  };
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getunitlist(
      propertyID ? propertyID : blockID ? blockID : floorID,
      filterData,
      e,
      0,
      limit
    );
  };

  const onApplyFilter = (value) => {
    setFilterData(value);
    getunitlist(
      propertyID ? propertyID : blockID ? blockID : floorID,
      value,
      "",
      0,
      limit
    );
  };

  const getUnitStats = (id) => {
    setLoading(true);
    const payload = {
      unit_id: id,
    };
    NetworkCall(
      `${config.api_url}/unit/stats`,
      NetWorkCallMethods.post,
      payload,
      true,
      true,
      false
    )
      .then((res) => {
        setStats({
          data: res?.data?.data,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const manualCategoryMasterResponse = (array) => array?.unit_category_master;

  const manualUnitTypeResponse = (array) => array?.unit_type_master;

  const render = () => {
    return (
      <>
        <Subheader
          arrow
          pageoptions={
            propertyID ? headerpage2 : blockID ? headerpage1 : headerpage
          }
          title={unitlist?.breadCrumbs?.property_name}
          goBack={() => {
            navigate(-1);
          }}
        />
        {loading ? (
          <LoadingSection bottom={"45vh"} />
        ) : (
          <Box className={classes.root}>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                md={unitlist?.data?.length > 0 ? 7 : 12}
                lg={unitlist?.data?.length > 0 ? 8 : 12}
              >
                {/*table */}
                <Box p={2}>
                  <AccountTable
                    draweropen={() => setDrawer(true)}
                    data={unitlist?.data ?? []}
                    handleChangeLimit={handleChangeLimit}
                    handlePagination={handlePagination}
                    page={page}
                    handleSearch={handleSearch}
                    searchText={searchText}
                    limit={limit}
                    handleIcon={handleIcon}
                    //   onUnitClick={onUnitClick}
                    totalRowsCount={unitlist?.count}
                    handleOnClickAddPropertyButton={handleOnClickAddUnitButton}
                    handleChange={handleSearch}
                    unitlist={unitlist}
                    permission={permission}
                    t={t}
                    filterData={filterData}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                lg={4}
                sx={{ display: unitlist?.data?.length > 0 ? "block" : "none" }}
              >
                {/*details */}
                <Box p={2} className={classes.detailsContainer}>
                  <DetailsContainer t={t} stats={stats} loading={loading} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        {/* filter drawer */}
        {drawer && (
          <FilterGenerator
            open={drawer}
            onClose={() => setDrawer(false)}
            defaultState={defaultFilterState}
            components={[
              {
                component: "select",
                value: filterData?.unit_category,
                options: [],
                isMulti: true,
                label: t("Unit Category"),
                placeholder: t("Select Unit Category"),
                state_name: "unit_category",
                loadOptions: (search, array, handleLoading) =>
                  loadOptionsApis(
                    "queries/unit_category/get",
                    {},
                    search,
                    array,
                    handleLoading,
                    "data",
                    {},
                    manualCategoryMasterResponse,
                    { manualResponseMethod: "without_extract_label" }
                  ),
                debounceTimeout: 800,
                isPaginate: true,
              },
              {
                component: "select",
                value: filterData?.unit_type,
                options: [],
                isMulti: true,
                label: t("Unit Type"),
                placeholder: t("Select Unit Type"),
                state_name: "unit_type",
                loadOptions: (search, array, handleLoading) =>
                  loadOptionsApis(
                    "queries/unit_type/get",
                    {
                      company_id: JSON.parse(
                        localStorage.getItem(LocalStorageKeys?.selectedCompany)
                      )?.value,
                    },
                    search,
                    array,
                    handleLoading,
                    "data",
                    {},
                    manualUnitTypeResponse,
                    { manualResponseMethod: "without_extract_label" }
                  ),
                debounceTimeout: 800,
                isPaginate: true,
              },
              {
                component: "toggleButton",
                value: filterData?.status,
                options: [
                  { label: t("Active"), value: true },
                  { label: t("Inactive"), value: false },
                ],
                isMulti: true,
                state_name: "status",
                label: t("Status"),
                // required:true
              },
            ]}
            onApply={(value) => onApplyFilter(value)}
          />
        )}
      </>
    );
  };

  return <>{accessCheckRender(render, permission)}</>;
};
export default withTranslation("unitList")(UnitList);
