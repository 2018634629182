import { styled } from "@mui/material/styles";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view";
import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch, remCalc } from "../../../utils";

// styled component
export const StyledTreeItem = styled((props) => <TreeItem {...props} />)(
    ({ theme }) => ({
        [`& .${treeItemClasses.iconContainer}`]: {
            "& .close": {
                opacity: 0.3,
            },
        },
        [`& .${treeItemClasses.group}`]: {
            marginLeft: 15,
            paddingLeft: 18,
            borderLeft: `1px solid ${theme?.palette?.border?.third}`,
        },

    })
);

export const useStyles = makeStyles((theme) => ({
    treeContainer: {
        position: "relative",
        "& .MuiTreeItem-content .MuiTreeItem-label": {
            position: "relative",
        },
        "& .MuiTreeItem-content.Mui-selected": {
            color: `${theme?.typography?.status?.primary} !important`,
            fontFamily: FontFamilySwitch().bold,
            fontSize: "0.875rem",
            borderRadius: "4px",
            backgroundColor: theme?.palette?.info?.light,
            "& .fileIcon": {
                color: `${theme?.palette?.border?.primary} !important`,
            },
        },
        "& .MuiTreeItem-content": {
            fontFamily: FontFamilySwitch().bold,
            fontSize: "0.875rem",
            color: "#4E5A6B",
            marginBottom: "3px",
            padding: "0 !important",
        },
        "& .MuiTreeItem-content:hover": {
            color: `${theme?.typography?.status?.primary} !important`,
            fontFamily: FontFamilySwitch().bold,
            fontSize: "0.875rem",
            borderRadius: "4px",
            backgroundColor: theme?.palette?.info?.light,
        },
    },
    Icons: {
        position: "absolute",
        right: "10px",
        top: "11px",
    },
    treeContainerParent: {
        padding: "12px",
        height: `calc(100vh - 205px)`,
        overflow: "auto",
    },
    //   fileIcon: {
    //     color: "#CED3DD",
    //   },
    Divider: {
        mr: "14px",
        ml: '14px'
    },
    root: {
        padding: "16px",
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        display: "flex",
        justifyContent: "space-between"

    },
    titleStack: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        // whiteSpace: "nowrap",
        marginInlineStart: "16px"
    },
    text1: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().semiBold,
        color: "#98A0AC",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
    text2: {
        fontSize: remCalc(14),
        fontFamily: FontFamilySwitch().semiBold,
        color: "#091B29",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    }
}));
