import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
export const useStyles = makeStyles((theme) => ({
  root: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
    height: `calc(100vh - 147px)`,
    overflow: "hidden",
    margin: "14px",
  },
  button: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: "none",
    fontSize: "0.875rem",
  },
  filterButton: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "12px",
  },
  addDialog: {
    "& .MuiDialog-paper": {
      borderRadius: theme.palette.borderRadius,
      padding: "0px",
      width: "520px",
    },
  },
  addDialogHeader: {
    padding: "16px 24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #E4E8EE",
  },
  addDialogHeaderTitle: {
    fontFamily: FontFamilySwitch().extraBold,
    fontSize: "1rem",
    color: "#091B29",
  },
  addDialogCloseButton: {
    padding: "0px",
  },
  addDialogBody: {
    padding: "24px",
    overflowY: "overlay",
  },
  addDialogFooter: {
    display: "flex",
    columnGap: "12px",
    padding: "24px",
  },
  addDialogFooterButton: {
    borderRadius: theme.palette.borderRadius,
    height: "45px",
    border: "1px solid #5078E1",
    boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.08)",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: "white",
  },
  addDialogFooterCloseButton: {
    borderRadius: theme.palette.borderRadius,
    height: "45px",
    border: "1px solid #5078E1",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#5078E1",
    backgroundColor: "white",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#E4E8EE ",
    },
  },
  addDialogFieldLabel: {
    color: "#98A0AC",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.75rem",
    marginBottom: "5px",
  },
  addDialogButtonContainer: {
    display: "flex",
    rowGap: "8px",
    columnGap: "8px",
  },
  addDialogButtonSelected: {
    borderRadius: theme.palette.borderRadius,
    height: "45px",
    border: "1px solid #5078E1",
    backgroundColor: "#5078E1",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#5078E1 ",
    },
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: "white",
  },
  addDialogButtonUnSelected: {
    borderRadius: theme.palette.borderRadius,
    height: "45px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#E4E8EE ",
    },
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#98A0AC",
  },
  reactQuil: {
    "& .ql-toolbar.ql-snow": {
      borderRadius: "8px 8px 0px 0px",
    },
    "& .ql-container.ql-snow": {
      borderRadius: "0px 0px 8px 8px",
    },
    "& .ql-editor": {
      padding: "8px",
      textAlign: "start",
      fontSize: "0.875rem",
      fontFamily: FontFamilySwitch().bold,
      color: "#091B29",
      height: "calc(100vh - 600px)",
    },
  },
}));

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px",
    padding: "12px",
    backgroundColor: "#FFFFFF",
    color: "#4E5A6B",
    border: "1px solid #E4E8EE",
    boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.04)",
    fontSize: "0.8rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
}));
