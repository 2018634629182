import { Badge, Box, Divider, Grid, IconButton, Stack } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { SearchFilter } from "../../../components";
import { paymentDashboardStyle } from "../style";
const SearchAndFilter = ({
  searchText = "",
  handleSearch = () => false,
  selected = {},
  openfilter = () => false,
  filterData = {},
  t,
}) => {
  const classes = paymentDashboardStyle();

  return (
    <Box>
      <Grid container alignItems="center">
        {/* search and filter */}
        <Grid item xs={4}>
          <SearchFilter
            value={searchText}
            handleChange={(e) => handleSearch(e)}
            placeholder={t("searchReceipt")}
            customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
          />
        </Grid>
        <Grid item xs={8}>
          {/* search and filter */}
          <Stack
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{ marginInline: "16px" }}
              />
            }
            justifyContent="flex-end"
            alignItems="center"
            direction="row"
          >
            {filterData?.account?.length > 0 ? (
              <Badge variant="dot" color="primary">
                <IconButton className={classes.iconButton} onClick={openfilter}>
                  <img src="/images/filter.svg" alt="filter" />
                </IconButton>
              </Badge>
            ) : (
              <IconButton className={classes.iconButton} onClick={openfilter}>
                <img src="/images/filter.svg" alt="filter" />
              </IconButton>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withTranslation("paymentDashboard")(SearchAndFilter);
