import { Box } from "@mui/material";
import React from "react";
import { FormGenerator } from "../../../components";
import { AlertContext } from "../../../contexts";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../../utils/constants";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
const initialState = {
    status: "",
    date: "",
    description: "",
    error: {
        status: "",
        date: "",
        description: "",
    }

}
const status = [
    {
        label: "Postpone",
        value: "Postpone",
    },
    {
        label: "Disqualify",
        value: "Disqualify",
    }
]

export const CloseOppertunity = ({ t, oppertunity_id = "", onClose = () => false }) => {
    const [data, setData] = React.useState({ ...initialState })
    const alert = React.useContext(AlertContext);
    //update state
    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        setData({ ...data, [key]: value, error });
    };
    //validate 
    const validateForm = () => {
        let isValid = true;
        let error = data.error;
        if (data?.status?.length === 0 || data?.status === null || data?.status === "") {
            isValid = false;
            error.status = t("Status is Required");
        }
        if (data?.description?.length === 0) {
            isValid = false;
            error.description = t("Description is Required");
        }
        if (data?.date?.length === 0) {
            isValid = false;
            error.date = t("Closing Date is Required");
        }
        setData({ ...data, error });
        return isValid;
    };
    //on submit
    const onSubmit = () => {
        if (validateForm()) {
            let datas = {
                id: oppertunity_id,
                lead_closure_status: data?.status,
                reason: data?.description,
                closed_by: localStorage.getItem(LocalStorageKeys.userProfileID),
                closed_on: data?.date,
                is_active: false,
                status: 1
            }
            NetworkCall(
                `${config.api_url}/lead_proxy/lost_lead`,
                NetWorkCallMethods.post,
                datas,
                null,
                true,
                false
            )
                .then((response) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: t("Close Oppertunity Successfully"),
                    });
                    onClose()

                }).catch((err) => {
                    console.log(err)
                })

        } else {
            return false
        }
    }

    const formData = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "toggle",
            label: t("Lead Closing Status"),
            value: data?.status,
            placeholder: t("Lead Closing Status"),
            onChange: (value) => updateState("status", value),
            error: data?.error?.status,
            isRequired: true,
            options: status
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "date",
            label: t("Closing Date"),
            value: data?.date,
            placeholder: t("Closing Date"),
            onChange: (value) => updateState("date", value),
            error: data?.error?.date,
            isRequired: true,
            minDate: new Date()
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "text",
            label: t("Description"),
            value: data?.description,
            placeholder: t("Enter Description"),
            onChange: (value) => updateState("description", value?.target.value),
            error: data?.error?.description,
            isRequired: true,
            multiline: true
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "button",
            label: t("Submit"),
            onClick: (value) => onSubmit(),

        }
    ]

    return (
        <Box p={3}>
            <FormGenerator t={t} components={formData} />
        </Box>
    )
}