import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Typography, Popover, Stack, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import * as React from "react";
// import { useWindowDimensions } from "../../../utils";
import { dashboardStyles } from "./style";
import { SelectBox } from "../../../components";
import moment from "moment";
import { DateRangePicker } from "materialui-daterange-picker";
import { TeamsSelect } from "../../../components/teamsSelect";
import { CompaniesPrefixIcon, TeamsIcon } from "../../../assets/navBarIconsNew";
import { CalenderIcon } from "../../../assets";
import { FontFamilySwitch } from "../../../utils";

const TabTitle = (props) => {
  const classes = dashboardStyles();
  return (
    <div className={props?.selectBack}>
      <div>{props?.datavalue?.imgSelect}</div>
      <div style={{ marginLeft: "10px", textAlign: "left" }}>
        <Typography className={props?.class}>
          {props?.datavalue?.label}
        </Typography>
        <Typography className={props?.subtabtitle} flexWrap>
          {props?.datavalue?.sub}
        </Typography>
      </div>
      {props?.isEdit && (
        <div
          style={{ marginLeft: "14px", textAlign: "left" }}
          onClick={() =>
            props?.handleedit
              ? props?.handleedit(props?.datavalue?.value)
              : null
          }
        >
          <img src="/images/Outlineselect.svg" alt="" />
        </div>
      )}

      <div className={classes.after}>|</div>
    </div>
  );
};

export function Tabs(props) {
  // const size = useWindowDimensions();
  const classes = dashboardStyles();
  const { style = {} } = props;
  const [calenderOpen, setCalendarOpen] = React.useState(false);
  const openClosePopOver = () => {
    setCalendarOpen(!calenderOpen);
  };

  const onCalendarDropdownChange = (value) => {
    props?.onChangeCalendar(value);
    if (value.label === "Custom") {
      setCalendarOpen(!calenderOpen);
    }
  };

  const onCalendarPickerChange = (range) => {
    let value = {
      label: "Custom",
      value: {
        from_date: moment(range?.startDate).format("YYYY-MM-DD"),
        to_date: moment(range?.endDate).format("YYYY-MM-DD"),
      },
      load: true,
    };
    setCalendarOpen(!calenderOpen);
    props?.onChangeCalendar(value);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={props?.value}>
        <Box className={classes.rootTab}>
          {!props?.isTabHide ?
            <Box>
              <TabList
                onChange={props?.handleChange ?? ""}
                aria-label="lab API tabs example"
                className={classes.tabs}
              >
                {props?.tabTittle?.map((x, index) => {
                  return (
                    <Tab
                      label={
                        <TabTitle
                          handleedit={props?.handleedit ?? ""}
                          datavalue={x}
                          index={index}
                          isEdit={props?.isEdit}
                          class={x?.className}
                          subtabtitle={x?.subtabtitle}
                          selectBack={x?.selectBack}
                        />
                      }
                      value={x?.value}
                      tabvalue={props?.value}
                      className={classes.tabs}
                    />
                  );
                })}
              </TabList>
            </Box> :
            <Box marginInlineStart="10px">
              <Typography className={classes.title}>{props?.title}</Typography>
            </Box>}
          <Box className={classes.flexBox}>
            <Stack className={classes.companyGroupDiv}
              direction="row"
              margin={"0px 8px"}
              divider={<Divider orientation="vertical" flexItem />}>
              {props?.calanderselect && (
                <Box width="205px" marginRight="9px" zIndex={999}>

                  <SelectBox
                    color="#FFFFFF"
                    noBorder
                    left={'2px'}
                    selectHeight={"40px"}
                    padding={"0px 0px 0px 6px"}
                    prefix={<CalenderIcon />}
                    options={props?.calendarOptions}
                    isReadOnly={props?.calendarIsReadOnly}
                    placeholder={props?.calendarPlaceholder}
                    label={props?.calendarLabel}
                    value={props?.calanderValue}
                    onChange={(value) => onCalendarDropdownChange(value)}
                    fontFamily={FontFamilySwitch().semiBold}

                  />
                </Box>
              )}
              {props?.calanderValue?.label === "Custom" && (
                <Box
                  style={{
                    backgroundColor: "#FFFFFF",
                    padding: "0px 6px",
                    alignItems: "center",
                    display: "flex",
                    zIndex: 999,
                    cursor: "pointer",
                  }}
                  onClick={openClosePopOver}>
                  <CalenderIcon />
                  <Typography style={{ marginLeft: "6px", fontSize:"0.875rem", fontFamily: FontFamilySwitch().regular, color: "#333333" }}>
                    {(props?.calanderValue?.value?.from_date ?? "YYYY-MM-DD") +
                      " to " +
                      (props?.calanderValue?.value?.to_date ?? "YYYY-MM-DD")}
                  </Typography>
                </Box>
              )}
              {props?.select && (
                <Box width="250px" marginRight="14px" zIndex={999}>

                  <SelectBox
                    color="#FFFFFF"
                    noBorder
                    selectHeight={"40px"}
                    padding={"0px 0px 0px 2px"}
                    prefix={<CompaniesPrefixIcon />}
                    options={props?.options}
                    isReadOnly={props?.isReadOnly}
                    placeholder={props?.placeholder}
                    label={props?.label}
                    value={props?.selectvalue}
                    onChange={(value) => props?.onchange(value)}
                    fontFamily={FontFamilySwitch().semiBold}

                  />
                </Box>
              )}
              {props?.selectTeamsOptions &&
                <Box className={props?.selectedTeams?.value !== "noteams" ? classes.box : ""} >
                  <TeamsSelect
                    noBorder
                    selectHeight={"40px"}
                    padding={"0px 0px 0px 2px"}
                    color="#FFFFFF"
                    companyId={props?.companyId}
                    prefix={<TeamsIcon />}
                    handleTeamsChange={props?.handleTeamsChange}
                    moduleId={props?.moduleId}
                    otherOptionsIsReadOnly={props?.isReadOnly}
                    getTeamsOption={props?.getTeamsOption}
                  />
                </Box>}
            </Stack>
          </Box>
        </Box>
        <div
          style={{
            // height: props?.isHeigth ? size.height - (64 + 60 + 57 + 45) : "",
            ...style,
          }}
        >
          <TabPanel value={1} sx={{ padding: 0 }}>
            {props?.tab1}
          </TabPanel>
          <TabPanel value={2} sx={{ padding: 0 }}>
            {props?.tab2}
          </TabPanel>
          <TabPanel value={3} sx={{ padding: 0 }}>
            {props?.tab3}
          </TabPanel>
        </div>
      </TabContext>
      <Popover
        open={calenderOpen}
        onClose={openClosePopOver}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 130, left: 1000 }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <DateRangePicker
          open={calenderOpen}
          toggle={openClosePopOver}
          onChange={(range) => onCalendarPickerChange(range)}
          initialDateRange={{
            startDate: props?.calanderValue?.value?.from_date
              ? new Date(props?.calanderValue?.value?.from_date)
              : new Date(),
            endDate: props?.calanderValue?.value?.to_date
              ? new Date(props?.calanderValue?.value?.to_date)
              : new Date(),
          }}
          definedRanges={[]}
        />
      </Popover>
    </Box>
  );
}
