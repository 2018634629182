import { Box } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CityProperty from '../../assets/cityproperty';
import ImagesProperty from '../../assets/imagesproperty';
import { PropertyTabs, Subheader } from "../../components";
import { NewLoader } from '../../components/newLoader';
import { config } from "../../config";
import { AlertContext, AuthContext } from '../../contexts';
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { accessCheckRender, AlertProps, getRoutePermissionNew, NetWorkCallMethods } from '../../utils';
import { KYCDetails } from '../accountDetails/sections/kyc';
import { PropertyStylesParent } from "../propertyviewing/style";
import { constructCompanyDetails } from './utils';
import {
    AssetsView, BankingDetailsView, CompanyConfigurationView, CompanyProfileView, GovernmentalDetailsView
} from './views';
import { TermsAndConditionView } from './views/termsAndCondition';
import { withTranslation } from 'react-i18next';


const CompanyDetails = (props) => {
    const { loading, handleLoading, t } = props;
    
    const classes = PropertyStylesParent();
    const navigate = useNavigate();
    const alert = useContext(AlertContext);
    const auth = React.useContext(AuthContext);
    const [loader, setLoader] = useState(null);
    const [value, setValue] = useState(1);
    const [data, setData] = useState({});
    const [permission, setPermission] = React.useState({});

    useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                if (props?.params?.id) {
                    getCompanyDetails(props?.params?.id)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth])

    const getCompanyDetails = (id) => {
        setLoader('screen')
        NetworkCall(
            `${config.api_url}/queries/get_company_info`,
            NetWorkCallMethods.post,
            { id: id },
            null,
            true,
            false
        ).then(res => {
            onChangeTaxGroup(res?.data?.data?.company_master?.[0])
        }).catch(err => {
            console.log(err);
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something went wrong"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            })
        })
    }
    const onChangeTaxGroup = (value) => {
        let group_id = value?.vat_group_id

        if (group_id) {
            NetworkCall(
                `${config.api_url}/queries/vat_group_item/get_sum_rate`,
                NetWorkCallMethods.post,
                { vat_group_master_id: group_id },
                null,
                true,
                false
            ).then(res => {
                constructCompanyDetailsData(value, res?.data?.data?.vat_group_item?.[0]?.sum_rate)
            }).catch(err => {

                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something went wrong"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                })
            })
        } else {
            constructCompanyDetailsData(value, "")
        }
    }

    const constructCompanyDetailsData = (val, totalTax) => {
        let companyDetails = constructCompanyDetails(val, totalTax);
        setData(companyDetails)
        setLoader(null);
        handleLoading(false);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabTittle = useMemo(() => (
        [
            {
                label: 'Company Profile',
                imgSelect: <CityProperty color={value === 1 ? '#5078E1' : '#98A0AC'} />,
                value: 1,
                className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
                selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
                subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
            },
            {
                label: 'Company Settings',
                imgSelect: <CityProperty color={value === 2 ? '#5078E1' : '#98A0AC'} />,
                value: 2,
                className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
                selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
                subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
            },
            {
                label: 'KYC Details',
                imgSelect: <CityProperty color={value === 3 ? '#5078E1' : '#98A0AC'} />,
                value: 3,
                className: value === 3 ? classes.tabtitle1 : classes.tabtitle,
                selectBack: value === 3 ? classes.selectBack1 : classes.selectBack,
                subtabtitle: value === 3 ? classes.subtabtitle1 : classes.subtabtitle
            },

            {
                label: 'Attachments',
                imgSelect: <ImagesProperty color={value === 4 ? '#5078E1' : '#98A0AC'} />,
                value: 4,
                className: value === 4 ? classes.tabtitle1 : classes.tabtitle,
                selectBack: value === 4 ? classes.selectBack1 : classes.selectBack,
                subtabtitle: value === 4 ? classes.subtabtitle1 : classes.subtabtitle
            },
        ]
        // eslint-disable-next-line
    ), [value])

    const goBack = () => {
        navigate({
            pathname: '/masters/business_master/Company'
        })
    }

    const handleedit = (index) => {
        navigate(`${Routes.editcompany?.split('/:')[0]}/${props?.params?.id}`, 
        {
            state: {
                from: 'edit',
                // tabIndex: index
            },
        });
    }

    const render = () => {
        return (
            <div >
                <Subheader
                    title={(
                        loader === "screen" ? <Skeleton variant="text" width={"100px"} /> : data?.companyProfile?.profileDetails?.companyName
                    )}
                    goBack={goBack}
                />
                {
                    loader === "screen" ? (
                        <NewLoader minusHeight="100px" />
                    ) : (
                        <PropertyTabs
                            showBtns={true}
                            value={value}
                            t={t}
                            handleChange={handleChange}
                            isEdit
                            handleedit={handleedit}
                            style={{
                                height: "calc(100vh - 161px)",
                                overflow: "auto"
                            }}
                            tab1={(
                                <>
                                    <CompanyProfileView t={t} data={data?.companyProfile} />
                                    <Box height="20px" />
                                    <GovernmentalDetailsView t={t} data={data?.governmentalDetails} />
                                    <Box height="20px" />
                                    <BankingDetailsView t={t} data={data?.bankingDetails} />
                                    {data?.companyProfile?.TermsAndConditionDetails &&
                                        <>

                                            <Box height="20px" />
                                            <TermsAndConditionView t={t} data={data?.companyProfile?.TermsAndConditionDetails} />
                                        </>

                                    }
                                </>
                            )}
                            tab2={(

                                <CompanyConfigurationView t={t} data={data?.companyConfiguration} upfrontData={data?.upfront_charges} />
                            )}
                            tab3={(
                                <KYCDetails t={t} data={data?.companyProfile?.proof} />
                            )}
                            tab4={(
                                <AssetsView t={t} data={data?.assets} />
                            )}
                            tabTittle={tabTittle}
                        />
                    )
                }
            </div >
        )
    }
    return <div>{accessCheckRender(render, permission, "", loading)}</div>;
}
export default withTranslation("companyCreation")(CompanyDetails)
