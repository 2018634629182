import {
  Backdrop, Box, Button, CircularProgress, Grid
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { SelectBox, TextBox } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, NetWorkCallMethods, useWindowDimensions, FontFamilySwitch } from "../../../utils";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";

const useStyles = makeStyles((theme) => ({
  main: {
    padding: "0px 24px",
    overflow: "auto",
  },
  dropdown: {
    margin: "10px 0px",
  },
  next: {
    marginLeft: "10px",
    fontFamily: FontFamilySwitch().semiBold,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  Cancel: {
    backgroundColor: "#E4E8EE ",
    color: "#091B29",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontFamily: FontFamilySwitch().semiBold,
    "&:hover": {
      backgroundColor: "#E4E8EE ",
    },
  },
  btnFixed: {
    padding: "10px 0px",
  },
}));

export const General = (props) => {
  const classes = useStyles();
  const alert = React.useContext(AlertContext);
  const size = useWindowDimensions();
  const [data, setData] = React.useState({
    company: props?.selectedCompany,
    propperty: "",
    units: "",
    subcategorys: "",
    description: "",
    agreements: "",
    catagorys: "",
    problem: "",
    error: {
      company: "",
      propperty: "",
      units: "",
      subcategorys: "",
      description: "",
      agreements: "",
      catagorys: "",
      problem: "",
    },
  });
  const [search] = React.useState(undefined);
  const [disable, setDisable] = React.useState(false)
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false)
  const updateState = (key, value) => {
    let error = data?.error;
    error[key] = "";
    if (key === "company") {
      setData({ ...data, propperty: "", company: value, error });
    } else if (key === "catagorys") {
      setData({ ...data, subcategorys: "", catagorys: value, error });
    }
    else if (key === "propperty") {
      setData({ ...data, units: "", propperty: value, error });
    }
    else {
      let error = data.error;
      error[key] = "";
      setData({ ...data, [key]: value, error });

    }

  };

  const validate = () => {
    let isValid = true;
    let error = data.error;
    if (data?.company?.length === 0) {
      isValid = false;
      error.company = "Company is Required";
    }
    if (data?.propperty?.length === 0) {
      isValid = false;
      error.propperty = "Property Name is Required";
    }
    if (data?.catagorys?.length === 0) {
      isValid = false;
      error.catagorys = "Catagory is Required";
    }
    if (data?.units?.length === 0) {
      isValid = false;
      error.units = "units is Required";
    }
    if (data?.subcategorys?.length === 0) {
      isValid = false;
      error.subcategorys = "subcategory is Required";
    }
    if (data?.problem?.length === 0) {
      isValid = false;
      error.problem = "Problem is Required";
    }
    if (data?.description?.length === 0) {
      isValid = false;
      error.description = "Description is Required";
    }
    if (data?.company?.length === 0 || data?.propperty?.length === 0 ||
      data?.catagorys?.length === 0 || data?.units?.length === 0 ||
      data?.subcategorys?.length === 0 || data?.problem?.length === 0 ||
      data?.description?.length === 0 || data?.description?.length === 0
    ) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all mandatory field",
      });
    }
    setData({ ...data, error });

    return isValid;
  };

  React.useEffect(() => {
    if (props?.isEdit === true) {
      setData({
        company: {
          value:
            props?.details?.table?.[0]?.unit?.[0]?.company_master?.id ?? "",
          label:
            props?.details?.table?.[0]?.unit?.[0]?.company_master?.name ?? "",
        },
        propperty: {
          value: props?.details?.table?.[0]?.unit?.[0]?.property?.id ?? "",
          label: props?.details?.table?.[0]?.unit?.[0]?.property?.name ?? "",
        },
        units: {
          value: props?.details?.table?.[0]?.unit?.[0]?.unit?.id ?? "",
          label: props?.details?.table?.[0]?.unit?.[0]?.unit?.name ?? "",
        },
        subcategorys: {
          value: props?.details?.table?.[0]?.subcatagory?.id ?? "",
          label: props?.details?.table?.[0]?.subcatagory?.type ?? "",
        },
        description: props?.details?.table?.[0]?.description ?? "",
        agreements: "",
        catagorys: {
          value: props?.details?.table?.[0]?.catagory?.id ?? "",
          label: props?.details?.table?.[0]?.catagory?.type ?? "",
        },
        problem: props?.details?.table?.[0]?.subject ?? "",
        error: {
          company: "",
          propperty: "",
          units: "",
          subcategorys: {
            value: "",
            label: "",
          },
          description: "",
          agreements: "",
          catagorys: "",
          problem: "",
        },
      });
    }
    // eslint-disable-next-line
  }, [props?.isEdit]);

  const Edit = () => {
    if (validate()) {
      setDisable(true)
      const saveGeneralRequestPayload = {
        tenantId: `${config.tenantId}`,
        type: 1,
        id: props?.details?.table?.[0]?.id,
        subject: data?.problem,
        category: data?.catagorys?.value,
        sub_category: data?.subcategorys?.value,
        units: [data?.units?.value],
        description: data?.description,
        userProfileId: localStorage.getItem("profileID"),
        propertyId: data?.propperty?.value,
        companyId: data?.company?.value,

      };
      NetworkCall(
        `${config.api_url}/request/update`,
        NetWorkCallMethods.post,
        saveGeneralRequestPayload,
        null,
        true,
        false
      )
        .then((response) => {
          setDisable(false)
          props?.AlertClose();
          props?.getTable(0, 10, search, "key", props?.selectedCompany);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Request Update successfully.",
          });
        })
        .catch((error) => {
          setDisable(false)
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Internal error. Please try again later.",
          });

        });
    }
  };
  const saveGeneralRequest = () => {
    if (validate()) {
      setDisable(true)
      const saveGeneralRequestPayload = {
        type: 1,
        subject: data?.problem,
        category: data?.catagorys?.value,
        sub_category: data?.subcategorys?.value,
        units: [data?.units?.value],
        description: data?.description,
        userProfileId: localStorage.getItem("profileID"),
        propertyId: data?.propperty?.value,
        companyId: data?.company?.value,
        created_by: localStorage.getItem("profileID"),
      };
      NetworkCall(
        `${config.api_url}/request/create`,
        NetWorkCallMethods.post,
        saveGeneralRequestPayload,
        null,
        true,
        false
      )
        .then((response) => {
          setDisable(false)
          props?.AlertClose();
          props?.getTable(0, 10, search, "key", props?.selectedCompany);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Request submitted successfully.",
          });
        })
        .catch((error) => {
          setDisable(false)
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Internal error. Please try again later.",
          });

        });
    }
  };
  let option = JSON.parse(localStorage.getItem("role"))
    .filter((item) => item?.name === "Manager")
    .map((v) => v);

  const properyRow = option?.map((val, index) => {
    let _d;
    try {
      _d = {
        value: val.company_id,
        label: val.company_name,
      };
    } catch (err) {

    }
    return _d;
  });


  const manualPropertyResponse = (array) => {
    return array?.property
  }

  const manualCategoryResponse = (array) => {
    return array?.general_category_master
  }

  const manualSubCategoryResponse = (array) => {
    return array?.general_sub_category_master
  }

  return (
    <div className={classes.main}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props?.loading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      {/* <div style={{ height: size?.height - 400 }}> */}
      <Grid container spacing style={{ height: size?.height - 350, overflow: "auto" }}>
        <Grid item xs={6}>
          {/* company */}
          <div className={classes.dropdown}>
            <SelectBox
              isReadOnly
              isRequired
              label="Company"
              options={properyRow}
              placeholder="Select Company"
              value={data?.company}
              onChange={(value) => {
                updateState("company", value);

              }}
              isError={data?.error?.company?.length > 0}
              errorMessage={data?.error?.company}
            />
          </div>
          {/*  */}
          <div className={classes.dropdown}>
            <SelectBox
              isRequired
              label="Property"
              isPaginate
              loadOptions={
                (search, array, handleLoading) =>
                  loadOptionsApis(
                    "queries/property/list",
                    {
                      "orderByKey": "name",
                      "orderByValue": "asc",
                      company_id: [data?.company?.value]
                    },
                    search,
                    array,
                    handleLoading,
                    "data",
                    {},
                    manualPropertyResponse
                  )
              }
              loading={loading === "propertyName"}
              debounceTimeout={800}
              key={JSON.stringify(data?.company)}
              placeholder="Select Property"
              value={data?.propperty}
              onChange={(value) => {
                updateState("propperty", value);
              }}
              isError={data?.error?.propperty?.length > 0}
              errorMessage={data?.error?.propperty}
            />
          </div>
          <div className={classes.dropdown}>
            <SelectBox
              isRequired
              label="Unit"
              isPaginate
              loadOptions={
                (search, array, handleLoading) =>
                  loadOptionsApis(
                    "queries/unit/list",
                    {
                      property_id: data?.propperty?.value
                    },
                    search,
                    array,
                    handleLoading,
                    "data"
                  )
              }
              loading={loading === "unit"}
              debounceTimeout={800}
              key={JSON.stringify(data?.propperty)}
              placeholder="Select Unit"
              value={data?.units}
              onChange={(value) => {
                updateState("units", value);
              }}
              isError={data?.error?.units?.length > 0}
              errorMessage={data?.error?.units}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.dropdown}>
            <SelectBox
              isRequired
              label="catagory"
              isPaginate
              loadOptions={
                (search, array, handleLoading) =>
                  loadOptionsApis(
                    "queries/general_category_master",
                    {},
                    search,
                    array,
                    handleLoading,
                    "data",
                    {},
                    manualCategoryResponse
                  )
              }
              loading={loading === "catagory"}
              debounceTimeout={800}
              placeholder="Select catagory"
              value={data?.catagorys}
              onChange={(value) => {
                updateState("catagorys", value);
              }}
              isError={data?.error?.catagorys?.length > 0}
              errorMessage={data?.error?.catagorys}
            />
          </div>
          <div className={classes.dropdown}>
            <SelectBox
              isRequired
              label="Sub-Category"
              isPaginate
              loadOptions={
                (search, array, handleLoading) =>
                  loadOptionsApis(
                    "queries/general_sub_category_master/list",
                    { id: data?.catagorys?.value },
                    search,
                    array,
                    handleLoading,
                    "data",
                    {},
                    manualSubCategoryResponse
                  )
              }
              loading={loading === "subcatagory"}
              debounceTimeout={800}
              key={JSON.stringify(data?.catagorys)}
              placeholder="Select Sub-Category"
              value={data?.subcategorys}
              onChange={(value) => {
                updateState("subcategorys", value);
              }}
              isError={data?.error?.subcategorys?.length > 0}
              errorMessage={data?.error?.subcategorys}
            />
          </div>
          <div className={classes.dropdown}>
            <TextBox
              isRequired
              label="Problem Tittle"
              placeholder="Problem Tittle"
              value={data?.description}
              onChange={(e) => {
                updateState("description", e.target.value);
              }}
              isError={data?.error?.description?.length > 0}
              errorMessage={data?.error?.description}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.dropdown}>
            <TextBox
              isRequired
              label="Problem Description"
              placeholder="Problem Description"
              value={data?.problem}
              multiline
              onChange={(e) => {
                updateState("problem", e.target.value);
              }}
              isError={data?.error?.problem?.length > 0}
              errorMessage={data?.error?.problem}
            />
          </div>
        </Grid>
      </Grid>
      {/* </div> */}
      {/* button */}
      <Box
        style={{
          display: "flex",
          justifyContent: "right",
          margin: "10px 0px",
        }}
        className={classes.btnFixed}
      >
        <Box>
          <Button variant="contained" className={classes.Cancel}>
            Cancel
          </Button>
        </Box>
        <Box>
          {props?.isEdit ? (
            <Button variant="contained" disabled={disable} className={classes.next} onClick={Edit}>
              Update
            </Button>
          ) : (
            <Button
              variant="contained"
              disabled={disable}
              className={classes.next}
              onClick={saveGeneralRequest}
            >
              Submit
            </Button>
          )}
        </Box>
      </Box>
    </div>
  );
};
