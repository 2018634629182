import {
  Box,
  Grid,
  IconButton,
  Stack,
  Divider,
  Badge,
  Button,
} from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import {
  SearchFilter,
  Subheader,
  TableWithPagination,
  UseDebounce,
  FilterGenerator,
} from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import {
  accessCheckRender,
  AlertProps,
  getCompanyOption,
  getRoutePermissionNew,
  NetWorkCallMethods,
} from "../../utils";
import {
  getSettlementHeading,
  settlementdataType,
  settlementPath,
} from "../../utils/settlement";
import { SettlementStyles } from "./style";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";

const Settlements = ({ t }) => {
  const classes = SettlementStyles();
  const auth = React.useContext(AuthContext);
  const settlementHeading = getSettlementHeading(t);
  const debounce = UseDebounce();
  const [searchText, setSearchText] = React.useState("");
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [list, setList] = React.useState({
    list: [],
    count: 0,
  });
  const [permission, setPermission] = React.useState({});
  const [companyList, setCompanyList] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState({});
  const [drawer, setDrawer] = React.useState(false);
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const [filterData, setFilterData] = React.useState({
    account: null,
    date: {
      startDate: null,
      endDate: null,
    },
  });

  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        getCompany();
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  const getCompany = () => {
    let company = getCompanyOption(backdrop, auth, alert);
    if (company) {
      setCompanyList(company?.list);
      setSelectedCompany(company?.selected);
      getSettlements(0, 10, "", company?.selected?.value);
    }
  };

  const getSettlements = (
    offset = 0,
    limit = 10,
    searchText = "",
    companyId = selectedCompany?.value,
    filters = {}
  ) => {
    let payload = {
      search: searchText,
      offset: offset,
      limit: limit,
      company_id: companyId,
      account_no: filters.account || [],
      startDate: filters.startDate,
      endDate: filters.endDate,
    };

    NetworkCall(
      `${config.api_url}/settlement/history`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setList({
          list: response?.data?.data?.map((val) => {
            return {
              ...val,
              status: val?.status === "no-due" ? "Paid" : "Unpaid",
              datetime: val?.settlement_date,
              settlement_no: val?.settlement_no ?? "-",
            };
          }),
          count: response?.data?.count,
        });
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: AlertProps.message.some_thing_went_wrong,
        });
      });
  };

  //handlesearch
  const handleSearch = (e) => {
    setSearchText(e);
    debounce(() => searchTableFunction(e), 800);
  };
  //search function
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getSettlements(0, 10, e);
  };
  //handle pagination
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getSettlements(
      offset,
      limit,
      searchText,
      selectedCompany?.value,
      filterData
    );
  };
  //on change limit
  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getSettlements(0, value, searchText, selectedCompany?.value, filterData);
  };
  const handleCompanyChange = (value) => {
    setSelectedCompany(value);
    getSettlements(0, 10, searchText, value?.value, filterData);
    setSearchText("");
  };
  const manualResponse = (array) =>
    array?.map((item) => {
      return {
        ...item,
        label: `${item?.account_no} - ${item?.name}`,
        value: item?.account_no,
      };
    });
  const onApplyFilter = (data) => {
    let result = {
      account: data?.account?.map((val) => val?.value),
      startDate: data?.date?.startDate,
      endDate: data?.date?.endDate,
    };
    getSettlements(0, 10, searchText, selectedCompany?.value, result);
    setFilterData(data);
    setDrawer(false);
  };

  const openfilter = () => {
    setDrawer(true);
  };

  const render = () => {
    return (
      <>
        <Subheader
          hideBackButton={true}
          title={t("Settlements")}
          select
          options={companyList}
          value={selectedCompany}
          onchange={(e) => handleCompanyChange(e)}
        />

        <Box className={classes.settlementRoot} p={2} m={2}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={4}>
              <SearchFilter
                handleChange={handleSearch}
                value={searchText}
                placeholder={t("search")}
                customfieldSx={{
                  "& .MuiOutlinedInput-root": { height: "40px" },
                }}
              />
            </Grid>
            {drawer && (
              <FilterGenerator
                open={drawer}
                onClose={() => setDrawer(false)}
                components={[
                  {
                    component: "select",
                    value: filterData?.account,
                    state_name: "account",
                    placeholder: t("Account ID"),
                    label: t("Account ID"),
                    isPaginate: true,
                    debounceTimeout: 800,
                    isMulti: true,
                    loadOptions: (search, array, handleLoading) =>
                      loadOptionsApis(
                        "queries/opportunity/contact_account",
                        {
                          company_id: selectedCompany?.value,
                        },
                        search,
                        array,
                        handleLoading,
                        "data",
                        {},
                        manualResponse
                      ),
                  },
                  {
                    component: "minAndmaxDate",
                    label: {
                      startLabel: t("From Date"),
                      endLabel: t("To Date"),
                    },
                    placeholder: {
                      startLabel: t("From"),
                      endLabel: t("To"),
                    },
                    state_name: "date",
                    value: filterData?.date,
                  },
                ]}
                onApply={(value) => onApplyFilter(value)}
              />
            )}

            <Stack
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ marginInline: "16px" }}
                />
              }
              justifyContent="flex-end"
              alignItems="center"
              direction="row"
            >
              {filterData?.account?.length > 0 ||
              filterData?.date?.startDate ||
              filterData?.date?.endDate ? (
                <Badge variant="dot" color="primary">
                  <IconButton
                    className={classes.iconButton}
                    onClick={openfilter}
                  >
                    <img src="/images/filter.svg" alt="filter" />
                  </IconButton>
                </Badge>
              ) : (
                <IconButton className={classes.iconButton} onClick={openfilter}>
                  <img src="/images/filter.svg" alt="filter" />
                </IconButton>
              )}
            </Stack>
            <Grid item xs={12}>
              <TableWithPagination
                heading={settlementHeading}
                rows={list?.list}
                path={settlementPath}
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                tableType="no-side"
                showpagination={true}
                dataType={settlementdataType}
                handleChangeLimit={handleChangeLimit}
                handlePagination={handlePagination}
                page={page}
                totalRowsCount={list?.count}
                limit={limit}
                height={`calc(100vh - 300px)`}
                view={true}
                edit={true}
                delete={true}
              />
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };

  return <div>{accessCheckRender(render, permission)}</div>;
};

export default withTranslation("settlement")(Settlements);
