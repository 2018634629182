import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CityProperty from "../../assets/cityproperty";
import ImagesProperty from "../../assets/imagesproperty";
import { ProofComponent, PropertyTabs, Subheader } from "../../components";
import { NewLoader } from "../../components/newLoader";
import { config } from "../../config";
import { AlertContext, AuthContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import {
  accessCheckRender,
  AlertProps,
  checkWebsiteUrl,
  getRoutePermissionNew,
  networkCallback,
  NetWorkCallMethods,
  ValidateEmail,
} from "../../utils";
import { initialBasicSettings } from "../../utils/companySettings";
import { useStylesCreation } from "../propertcreation/createproperty/style";
import {
  Assets,
  BankingDetails,
  CompanyProfile,
  GovernmentalDetails,
  Settings,
} from "./tabs";
import { TermsAndCondition } from "./tabs/termsAndCondition";
import {
  constructCompanyDetails,
  constructPayload,
  getDefaultSetting,
  listErrorState,
  returnEditSettings,
} from "./utils";

import { withTranslation } from "react-i18next";

const CompanyCreation = (props) => {
  const params = useParams();

  const { isEdit, loading, handleLoading, t } = props;
  const COMPANY_ID = params?.id;
  const classes = useStylesCreation();
  const navigate = useNavigate();
  const alert = useContext(AlertContext);
  const auth = React.useContext(AuthContext);
  const [data, setData] = useState({
    companyProfile: {
      profileDetails: {
        status: { label: "Active", value: true },
      },
    },
  });
  const [proofType, setProofType] = React.useState("");
  const [settings, setSettings] = React.useState({ ...initialBasicSettings });
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(null);
  const [mapLoad, setMapLoad] = useState(!Boolean(isEdit));
  const [value, setValue] = React.useState(1);
  const [permission, setPermission] = React.useState({});
  const [buttonDisable, setButtonDisable] = React.useState(false);

  const getSettingDetails = () => {
    NetworkCall(
      `${config.api_url}/client/settings/get`,
      NetWorkCallMethods.get,
      null,
      null,
      true,
      false
    )
      .then((response) => {
        let result = getDefaultSetting(response?.data?.settings);
        setSettings(result);
        handleLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const checkCode = async (code) => {
    if (code) {
      const payload = {
        code,
        id: isEdit ? COMPANY_ID : undefined,
      };
      const data = await NetworkCall(
        `${config.api_url}/company/check_code`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      );

      if (!data.data?.data?.allow) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Company Code Already Exists"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }

      return data.data?.data?.allow;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      setProofType("Company and Account");
      if (perm?.read) {
        if (isEdit) {
          getClientControls();
        } else {
          getSettingDetails();
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);
  //getClientControls edit
  const getClientControls = () => {
    NetworkCall(
      `${config.api_url}/company_application_control/get_all`,
      NetWorkCallMethods.post,
      { company_id: COMPANY_ID },
      null,
      true,
      false
    )
      .then((response) => {
        let myObject = response?.data?.data;
        let controls = [];
        for (var key in myObject) {
          if (myObject.hasOwnProperty(key)) {
            controls.push({
              header: key,
              details: myObject[key],
            });
          }
        }
        getCompanyDetails(COMPANY_ID, controls);
        handleLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //company details
  const getCompanyDetails = (id, client_controls) => {
    setLoader("screen");
    NetworkCall(
      `${config.api_url}/queries/get_company_info`,
      NetWorkCallMethods.post,
      { id: id },
      null,
      true,
      false
    )
      .then((res) => {
        const result = {
          ...res?.data?.data?.company_master?.[0],
          finance_email: res?.data?.data?.finance_email,
          client_controls,
        };
        let settingEdit = returnEditSettings(result);
        setSettings(settingEdit);
        onChangeTaxGroup(res?.data?.data?.company_master?.[0]);
        // constructCompanyDetailsData(res?.data?.company_master?.[0])
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  const onChangeTaxGroup = (value) => {
    let group_id = value?.vat_group_id;
    if (group_id) {
      NetworkCall(
        `${config.api_url}/queries/vat_group_item/get_sum_rate`,
        NetWorkCallMethods.post,
        { vat_group_master_id: group_id },
        null,
        true,
        false
      )
        .then((res) => {
          constructCompanyDetailsData(
            value,
            res?.data?.data?.vat_group_item?.[0]?.sum_rate
          );
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Something went wrong"),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        });
    } else {
      constructCompanyDetailsData(value, "");
    }
  };

  const constructCompanyDetailsData = (val, totalTax) => {
    let companyDetails = constructCompanyDetails(val, totalTax);
    setData(companyDetails);
    setLoader(null);
    handleLoading(false);
  };

  const tabTittle = [
    {
      label: t("Company Profile"),
      sub: t("Enter Profile, Address & Contact Details"),
      imgSelect:
        value > 1 ? (
          <img src="/images/correct_icon.svg" alt="icon" />
        ) : (
          <CityProperty color={value === 1 ? "#5078E1" : "#98A0AC"} />
        ),
      value: 1,
      className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle,
    },
    {
      label: t("Company Settings"),
      sub: t("Enter Company Settings"),
      imgSelect:
        value > 2 ? (
          <img src="/images/correct_icon.svg" alt="icon" />
        ) : (
          <CityProperty color={value === 2 ? "#5078E1" : "#98A0AC"} />
        ),
      value: 2,
      className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle,
    },
    {
      label: t("KYC Details"),
      sub: t("Enter KYC Details"),
      imgSelect:
        value > 3 ? (
          <img src="/images/correct_icon.svg" alt="icon" />
        ) : (
          <CityProperty color={value === 2 ? "#5078E1" : "#98A0AC"} />
        ),
      value: 3,
      className: value === 3 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 3 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 3 ? classes.subtabtitle1 : classes.subtabtitle,
    },

    {
      label: t("Attachments"),
      sub: t("Upload assets like Image, Videos"),
      imgSelect: <ImagesProperty color={value === 4 ? "#5078E1" : "#98A0AC"} />,
      value: 4,
      className: value === 4 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 4 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 4 ? classes.subtabtitle1 : classes.subtabtitle,
    },
  ];
  // eslint-disable-next-line

  const updateState = (stateKey, parentKey, key, value) => {
    let updateData;
    if (parentKey) {
      updateData = {
        ...data?.[stateKey],
        [parentKey]: {
          ...data?.[stateKey]?.[parentKey],
          [key]: value,
        },
      };
      if (key === "companyCountry") {
        if (updateData?.tax?.taxGroup) {
          updateData.tax.taxGroup = null;
        }
        if (updateData?.tax?.totalRateTax) {
          updateData.tax.totalRateTax = "";
        }
      }
    } else {
      updateData = {
        ...data?.[stateKey],
        [key]: value,
      };
    }

    setData({
      ...data,
      [stateKey]: updateData,
    });
  };
  const updateState1 = (value, value1) => {
    setSettings({
      ...settings,
      billing_payment_terms: value,
      billing_payment_terms_value: value1,
    });
  };
  //validate settings
  const validateSettings = () => {
    let isValid = true;
    let error = settings.error;

    if (settings?.default_base_currency?.length === 0) {
      isValid = false;
      error.default_base_currency = t("Default Base Currency is Required");
    }
    if (settings?.default_time_zone?.length === 0) {
      isValid = false;
      error.default_time_zone = t("Default Time Zone is Required");
    }
    if (settings?.default_uom?.length === 0) {
      isValid = false;
      error.default_uom = t("Default Unit Measurement is Required");
    }
    if (settings?.quotation_validity?.length === 0) {
      isValid = false;
      error.quotation_validity = t("Default Quotation Validity is Required");
    }
    if (settings?.billing_payment_peroid?.length === 0) {
      isValid = false;
      error.billing_payment_peroid = t(
        "Default Billing Payment Period is Required"
      );
    }
    if (settings?.billing_payment_terms_value?.length === 0) {
      isValid = false;
      error.billing_payment_terms_value = t(
        "Default Billing Payment Terms is Required"
      );
    }
    if (settings?.billing_payment_terms?.value === "custom") {
      if (settings?.billing_payment_terms_value?.length === 0) {
        isValid = false;
        error.billing_payment_terms = t(
          "Default Billing Payment Terms Value is Required"
        );
      }
    }
    if (settings?.billing_cycle_method?.length === 0) {
      isValid = false;
      error.billing_cycle_method = t(
        "Default Billing Cycle Methods is Required"
      );
    }
    if (settings?.lease_period?.length === 0) {
      isValid = false;
      error.lease_period = t("Default Lease Peroid is Required");
    }
    if (settings?.billing_generate_date?.length === 0) {
      isValid = false;
      error.billing_generate_date = t(
        "Default Billing Generate Date is Required"
      );
    }
    if (settings?.default_payment_gateway?.length === 0) {
      isValid = false;
      error.default_payment_gateway = t("Default Payment Gateway is Required");
    }

    if (settings?.default_payment_gateway?.value === "Solution Provider") {
      if (
        settings?.loginName?.length === 0 ||
        settings?.loginPassword?.length === 0 ||
        settings?.loginToken?.length === 0 ||
        settings?.paymentProviderName?.length === 0 ||
        settings?.provider_url?.length === 0
      ) {
        isValid = false;
        error.default_payment_gateway = t("All User Defined Value is Required");
      }
    }
    if (settings?.reservation_feature?.is_active === true) {
      if (settings?.reservation_feature?.value?.length === 0) {
        isValid = false;
        error.reservation_feature = t("Reservation Feature value is Required");
      }
    }
    if (settings?.grace_peroid?.is_active === true) {
      if (settings?.grace_peroid?.value?.length === 0) {
        isValid = false;
        error.grace_peroid = t("Grace Peroid value is Required");
      }
    }
    if (
      settings?.is_mail_trigger === "" ||
      settings?.is_mail_trigger === null
    ) {
      isValid = false;
      error.is_mail_trigger = t("Invite Mail Trigger is Required");
    }
    if (settings?.is_renewal === "" || settings?.is_renewal === null) {
      isValid = false;
      error.is_renewal = t("Renewal Trigger is Required");
    }
    if (settings?.renewal_period === "" || settings?.renewal_period === null) {
      isValid = false;
      error.renewal_period = t("Renewal Period is Required");
    }
    if (settings?.approval_period === "" || settings?.approval_period === null) {
      isValid = false;
      error.approval_period = t("Approval Period is Required");
    }
    if (
      settings?.allow_extension === "" ||
      settings?.allow_extension === null
    ) {
      isValid = false;
      error.allow_extension = t("Allow Extension Trigger is Required");
    }

    if (settings?.upfront_charge?.is_active === true) {
      if (!settings?.upfront_charge?.itemMaster) {
        isValid = false;
        error.upfront_charge = t("Upfront Charge Item is Required");
      }
    }

    if (settings?.penalty_item?.is_active === true) {
      if (!settings?.penalty_item?.itemMaster) {
        isValid = false;
        error.penalty_item = t("Penalty Item is Required");
      }
    }

    if (settings?.reservation_item?.is_active === true) {
      if (!settings?.reservation_item?.itemMaster) {
        isValid = false;
        error.reservation_item = t("Reservation Item is Required");
      }
    }

    if (settings?.account_pay?.is_active === true) {
      if (
        !settings?.account_pay?.acc_pay ||
        !settings?.account_pay?.tax_receive ||
        !settings?.account_pay?.accrued_liability ||
        !settings?.account_pay?.goods ||
        !settings?.account_pay?.credits
      ) {
        isValid = false;
        error.account_pay = t("Account Payable is Required");
      }
    }

    if (settings?.account_receive?.is_active === true) {
      if (
        !settings?.account_receive?.acc_receive ||
        !settings?.account_receive?.tax_pay ||
        !settings?.account_receive?.deposits ||
        !settings?.account_receive?.customer_deposit ||
        !settings?.account_receive?.inventory ||
        !settings?.account_receive?.advance ||
        !settings?.account_receive?.invest ||
        !settings?.account_receive?.sale
      ) {
        isValid = false;
        error.account_receive = t("Account Receivable is Required");
      }
    }
    setSettings({ ...settings, error });
    return isValid;
  };
  //update settings
  const updateStateSettings = (key, value) => {
    let error = settings.error;
    error[key] = "";
    setSettings({ ...settings, [key]: value, error });
  };

  const onChangeParticularState = (key, value) => {
    setData({
      ...data,
      [key]: {
        ...data?.[key],
        ...value,
      },
    });
  };

  const validation = (value) => {
    if (value === 3) return true;
    let listCheck = listErrorState[value - 1];
    let stateKey = ["companyProfile"];
    let returnCheckError = checkError(listCheck, stateKey[value - 1]);
    return !Object.keys(returnCheckError)?.length;
  };

  const checkError = (object, stateKey) => {
    let value,
      component,
      errorState = {};

    Object.keys(object)?.map((parentKey) => {
      object?.[parentKey]?.map((key) => {
        value = data?.[stateKey]?.[parentKey]?.[key?.key];
        component = key?.component;
        let errorMsg = key?.errorMsg ?? t("This field is required");

        if (key?.dependency?.key) {
          if (
            key?.dependency?.component === "select" &&
            key?.dependency?.value
          ) {
            if (
              data?.[stateKey]?.[parentKey]?.[key?.dependency?.key]?.value ===
              key?.dependency?.value
            ) {
              checkComponentError(
                value,
                component,
                errorState,
                stateKey,
                parentKey,
                key?.key,
                errorMsg
              );
            }
          } else if (key?.dependency?.component === "boolean") {
            if (data?.[stateKey]?.[parentKey]?.[key?.dependency?.key]) {
              checkComponentError(
                value,
                component,
                errorState,
                stateKey,
                parentKey,
                key?.key,
                errorMsg
              );
            }
          } else if (!data?.[stateKey]?.[parentKey]?.[key?.key]) {
            checkComponentError(
              value,
              component,
              errorState,
              stateKey,
              parentKey,
              key?.key,
              errorMsg
            );
          }
        } else {
          checkComponentError(
            value,
            component,
            errorState,
            stateKey,
            parentKey,
            key?.key,
            errorMsg
          );
        }

        return key;
      });

      return parentKey;
    });
    //lat lng throws error is null
    if (
      errorState?.companyProfile?.address?.latitude ||
      errorState?.companyProfile?.address?.longitude
    ) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Please choose location in map and proceed"),
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
    setError(errorState);
    return errorState;
  };

  const checkComponentError = (
    value,
    component,
    errorState,
    stateKey,
    parentKey,
    key,
    errorMsg
  ) => {
    // if (component === "mobile_number") {
    //     if (!(!!value?.mobile_code)) {
    //         constructErrorState(errorState, stateKey, parentKey, key, "Please select country code");
    //     }
    //     if (value?.mobile?.length !== 10) {
    //         constructErrorState(errorState, stateKey, parentKey, key, "Please enter 10 digits");
    //     }
    // }
    if (component === "company_code") {
      if (value?.length > 6) {
        constructErrorState(
          errorState,
          stateKey,
          parentKey,
          key,
          "company code  should be 6 digits"
        );
      }
    }
    if (component === "boolean") {
      if (typeof value !== "boolean") {
        constructErrorState(errorState, stateKey, parentKey, key, errorMsg);
      }
    } else if (component === "integer") {
      if (typeof value !== "number") {
        constructErrorState(errorState, stateKey, parentKey, key, errorMsg);
      }
    } else if (component === "website") {
      if (!checkWebsiteUrl(value)) {
        constructErrorState(
          errorState,
          stateKey,
          parentKey,
          key,
          value ? "Please enter valid URL" : errorMsg
        );
      }
    } else if (component === "email") {
      if (!value) {
        constructErrorState(
          errorState,
          stateKey,
          parentKey,
          key,
          t("This field is required")
        );
      } else if (!ValidateEmail(value)) {
        constructErrorState(errorState, stateKey, parentKey, key, errorMsg);
      }
    } else {
      if (
        !value ||
        //to check Object
        (value?.constructor === Object && !Object?.keys(value)?.length) ||
        //to check Date
        (value?.constructor === Date && !value) ||
        //to check Array
        (value?.constructor === Array && !value?.length) ||
        //to check string
        (value?.constructor === String && !value?.trim()?.length)
      ) {
        constructErrorState(errorState, stateKey, parentKey, key, errorMsg);
      }
    }
  };

  const constructErrorState = (
    errorState,
    stateKey,
    parentKey,
    key,
    errorMsg
  ) => {
    if (!errorState[stateKey]) {
      errorState[stateKey] = {};
    }
    if (!errorState[stateKey][parentKey]) {
      errorState[stateKey][parentKey] = {};
    }
    if (!errorState[stateKey][parentKey][key]) {
      errorState[stateKey][parentKey][key] = {};
    }
    errorState[stateKey][parentKey][key]["error"] = true;
    errorState[stateKey][parentKey][key]["errorMsg"] = errorMsg;
  };

  const previous = () => {
    setValue((prevValue) => prevValue - 1);
    if (!isEdit) {
      setMapLoad(false);
    }
  };

  const next = async () => {
    if (value === 2) {
      if (validateSettings()) {
        setValue(3);
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Please fill All fields"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
    } else if (value === 3) {
      if (ValidateProof(settings?.proof)) {
        setValue(4);
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Please fill All mandatory fields"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
    } else {
      let allow = await checkCode(
        data?.companyProfile?.profileDetails?.companyCode
      );

      if (validation(value) && allow) {
        setValue((prevValue) => prevValue + 1);
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Please fill All mandatory fields"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
    }
  };

  const ValidateProof = (object) => {
    let isValid = true;

    let newObj = object
      ?.filter((val) => val?.is_active)
      ?.filter((val) => val?.type !== undefined);

    const result = newObj?.map((data) => {
      let error = data?.error ?? {};
      if (data?.id_no === "") {
        error.id_no = "ID Proof Number is Required";
      }
      if (data?.valid_to === "") {
        error.valid_to = "Valid To is Required";
      }
      if (data?.issuing_country === "") {
        error.issuing_country = "Issuing Country is Required";
      }
      if (data?.mandatry_verify === "" || data?.mandatry_verify === null) {
        error.mandatry_verify = "Mandatory Verify is Required";
      }

      return {
        ...data,
        error: error,
      };
    });
    isValid =
      result?.filter((i) => Object.keys(i?.error)?.length > 0)?.length === 0
        ? true
        : false;
    return isValid;
  };

  //on save
  const save = async () => {
    setButtonDisable(true);
    setLoader("save");
    const {
      companyProfile,
      companyConfiguration,
      governmentalDetails,
      bankingDetails,
      assets,
    } = data;
    let payload = constructPayload(
      companyProfile,
      companyConfiguration,
      governmentalDetails,
      bankingDetails,
      assets,
      settings,
      isEdit
    );

    if (isEdit) {
      payload.id = COMPANY_ID;
    }
    let result = await networkCallback(
      payload,
      "company/upsert",
      setLoader,
      true
    ); 
    if (result?.data?.type === "success") {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.success,
        msg: `${isEdit ? t(result?.data?.data) : t(result?.data?.data?.message)}`,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
      navigate({
        pathname: "/masters/business_master/Company",
      });
    } else {
      if (result?.response?.status === 406) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t(
            "Your  Company  limit has been hit! Please upgrade your plan!"
          ),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Unable to create"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        setButtonDisable(false);
        setLoader(null);
      }
    }
  };

  const render = () => {
    return (
      <div style={{ position: "relative" }}>
        <Subheader
          title={
            isEdit
              ? data?.companyProfile?.profileDetails?.companyName
              : t("Create Companies")
          }
          goBack={() => {
            navigate(-1);
          }}
          previousAndNextBtn={true}
          previousBtn={previous}
          nextBtn={next}
          previousBtnDisabled={value <= 1 && true}
          nextBtnDisabled={value === 4 ? true : false}
        />
        {loader === "screen" ? (
          <NewLoader minusHeight="100px" />
        ) : (
          <>
            <PropertyTabs
              value={value}
              isHeigth
              t={t}
              tab1={
                <>
                  <CompanyProfile
                    id="pushTitle"
                    data={data?.companyProfile}
                    error={error?.companyProfile}
                    updateState={(parentKey, key, value) =>
                      updateState("companyProfile", parentKey, key, value)
                    }
                    mapLoad={mapLoad}
                    isEdit={isEdit}
                    checkCode={checkCode}
                    t={t}
                  />
                  <Box height="24px" />
                  <GovernmentalDetails
                    isEdit={isEdit}
                    data={data?.companyProfile}
                    error={error?.companyProfile}
                    updateState={(parentKey, key, value) =>
                      updateState("companyProfile", parentKey, key, value)
                    }
                    countryID={
                      data?.companyProfile?.profileDetails?.companyCountry
                        ?.value
                    }
                    onChangeParticularState={onChangeParticularState}
                    t={t}
                  />
                  <Box height="24px" />
                  <BankingDetails
                    data={data?.companyProfile}
                    error={error?.companyProfile}
                    updateState={(parentKey, key, value) =>
                      updateState("companyProfile", parentKey, key, value)
                    }
                    t={t}
                  />
                  <Box height="24px" />
                  <TermsAndCondition
                    id="pushTitle"
                    data={data?.companyProfile}
                    updateState={(parentKey, key, value) =>
                      updateState("companyProfile", parentKey, key, value)
                    }
                    t={t}
                  />
                </>
              }
              tab2={
                <Settings
                  data={settings}
                  isEdit={isEdit}
                  countryID={
                    data?.companyProfile?.profileDetails?.companyCountry?.value
                  }
                  updateState={updateStateSettings}
                  dataLogo={data?.companyProfile}
                  updateState1={updateState1}
                  updateStateLogo={(parentKey, key, value) =>
                    updateState("companyProfile", parentKey, key, value)
                  }
                  t={t}
                  COMPANY_ID={COMPANY_ID}
                  setSettings={setSettings}
                />
              }
              tab3={
                <Box className={classes.imagebox1}>
                  <ProofComponent
                    t={t}
                    file={settings?.proof}
                    update={(val) => updateStateSettings("proof", val)}
                    company
                    isNotRequired={false}
                    proofType={proofType}
                  />
                </Box>
              }
              tab4={
                <Assets
                  isEdit={isEdit}
                  data={data?.assets}
                  error={error?.assets}
                  updateState={(parentKey, key, value) =>
                    updateState("assets", parentKey, key, value)
                  }
                  onChangeParticularState={onChangeParticularState}
                  t={t}
                />
              }
              tabTittle={tabTittle}
              showBtns={true}
              isSaveDisable={
                buttonDisable
                  ? buttonDisable
                  : value === 4
                  ? false
                  : true || loader === "save"
                  ? true
                  : false
              }
              saveBtnText={isEdit ? t("Update") : t("Submit")}
              save={save}
            />
          </>
        )}
      </div>
    );
  };

  return <div>{accessCheckRender(render, permission, "", loading)}</div>;
};
export default withTranslation("companyCreation")(CompanyCreation);
