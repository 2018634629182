import React from "react";
import { TreeView } from "@mui/x-tree-view";
import { useStyles, StyledTreeItem } from "./styles";
import { Box } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FolderIcon from "@mui/icons-material/Folder";

export const FileManagerList = (
  {
    data = [],
    handleOnFolderClick = () => false
  }
) => {
  const classes = useStyles();

  // render tree function
  const render = (TreeJson) => {
    let listData =
      Array?.isArray(TreeJson) &&
      TreeJson?.map((currentItem) => {
        return (
          <StyledTreeItem
            onClick={() => handleOnFolderClick(currentItem)}
            nodeId={currentItem?.id}
            itemId={currentItem?.id}
            label={
              <Box display="flex" alignItems="center">
                <FolderIcon className={classes.fileIcon} />
                <Box width={"12px"} />
                {currentItem?.name}
              </Box>
            }
            className={classes.treeContainer}
          >
            {Array.isArray(currentItem?.children)
              ? render(currentItem?.children)
              : null}
          </StyledTreeItem>
        );
      });
    return listData;
  };

  return (
    <div className={classes.treeContainerParent}>
      <TreeView
        className={classes.tree}
        defaultExpanded={["1"]}
        defaultCollapseIcon={<KeyboardArrowUpIcon className={classes.Icons} />}
        defaultExpandIcon={<KeyboardArrowDownIcon className={classes.Icons} />}
      >
        {render(data)}
      </TreeView>
    </div>
  );
};
