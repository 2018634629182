import { Box, Button, Drawer, Stack, Typography } from "@mui/material";
import React from "react";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import {
  AlertProps,
  isEmptyObject,
  NetWorkCallMethods,
  ValidateEmail,
} from "../../../utils";
import { AccountForm } from "../component";
import { useStyles } from "./styles";
import { Steps } from "../../../screens/residentOnboarding/steps";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";
import { IOSSwitch } from "../../formGenerator";

const initialState = {
  name: "",
  url: "",
  accountNo: "",
  mobile: "",
  currency: "",
  tax: "",
  email: "",
  proofFile: [],
  error: {
    name: "",
    email: "",
    accountNo: "",
    mobile: "",
    relationShip: "",
    currency: "",
    tax: "",
  },
};
export const Account = ({
  t = () => false,
  company_id = "",
  setSelectedAcc = () => false,
  selectedAcc = "",
  contactId = "",
  type = "Customer",
  contact,
  is_quote = false,
  checkPrimary = () => false,
  primaryState = false,
  setPrimary = () => false,
  previous = () => false,
}) => {
  const classes = useStyles();
  const [acc, setAcc] = React.useState({ ...initialState });
  const [disableAcc, setDisableAcc] = React.useState(false);
  const [openAcc, setOpenAcc] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const alert = React.useContext(AlertContext);
  const [tempAcc, setTepAcc] = React.useState(selectedAcc ?? "");

  //update state of Account
  const updateStateAcc = (key, value) => {
    let error = acc.error;
    error[key] = "";
    setAcc({ ...acc, [key]: value, error });
  };

  const onSelectAccount = (val) => {
    setTepAcc(val);
  };
  //validate account form
  const validateAccountForm = () => {
    let isValid = true;
    let error = acc.error;
    //validate first name
    if (acc?.name?.length === 0) {
      isValid = false;
      error.name = t("Account Name is Required");
    }

    //validate email
    if (acc?.email?.length === 0) {
      isValid = false;
      error.email = "Email ID is Required";
    }
    //validate email
    if (acc?.email?.length > 0) {
      if (ValidateEmail(acc?.email) === false) {
        isValid = false;
        error.email = "Invalide Email ID";
      }
    }

    //validate Mobile Number
    if (acc?.mobile?.length === 0) {
      isValid = false;
      error.mobile = "Mobile Number is Required";
    }

    setAcc({ ...acc, error });
    return isValid;
  };
  //on submit create account
  const onSubmitAccount = () => {
    if (validateAccountForm() && isEmptyObject(acc?.proofFile)) {
      setDisableAcc(true);
      let proof = acc?.proofFile
        ?.filter((val) => val?.is_active)
        ?.map((val) => {
          return {
            proof_id: val?.type?.value,
            proof_type: val?.type?.proof_type,
            kyc_type: "Both",
            name: val?.name,
            number: val?.id_no,
            valid_from: val?.valid_from,
            valid_to: val?.valid_to,
            url: val?.file,
            is_mandatory: val?.mandatry_verify,
            country_id: val?.issuing_country?.value,
          };
        });

      const formData = new FormData();
      for (let i = 0; i < acc?.url?.length; i++) {
        formData.append("files", acc?.url[i]);
      }
      formData.append("tenantId", `${config.tenantId}`);
      formData.append("name", `${acc?.name}`);
      formData.append("email_id", `${acc?.email}`);
      formData.append("mobile_no", `${acc?.mobile?.mobile}`);
      formData.append("mobile_no_country_code", `${acc?.mobile?.mobile_code}`);
      formData.append("contact_id", `${contactId}`);
      formData.append("company_id", `${company_id}`);
      formData.append("proof", JSON.stringify(proof));
      formData.append("relationship", `Customer`);
      if (!contact?.primary_account_no && !open && contactId) {
        formData.append("contact", contactId);
      }

      NetworkCall(
        `${config.api_url}/account/create`,
        NetWorkCallMethods.post,
        formData,
        null,
        true,
        false
      )
        .then((response) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: t("Account Successfully Created"),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
          setOpen(true);
          setDisableAcc(false);
          setOpenAcc(false);
        })
        .catch((error) => {
          setDisableAcc(false);
          setOpen(false);
        });
    } else {
      setDisableAcc(false);
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Please fill All fields"),
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };
  const sumbit = () => {
    setSelectedAcc(tempAcc);
    previous(4);
  };
  React.useEffect(() => {
    if (contact) {
      setAcc({
        ...acc,
        email: contact?.email,
        name: contact?.name,
        mobile: contact?.mobile,
      });
    }
    // eslint-disable-next-line
  }, [contact]);

  React.useEffect(() => {
    if (contact) {
      setAcc({
        ...acc,
        email: contact?.email,
        name: contact?.name,
        mobile: contact?.mobile,
      });
    }
    // eslint-disable-next-line
  }, [contact]);

  React.useEffect(() => {
    checkPrimary(contactId, selectedAcc?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Box
        style={{
          position: "relative",
          height: "calc(100vh - 126px)",
          overflow: "auto",
        }}
        p={2}
      >
        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Typography className={classes.addresstitle}>
              {t("ACCOUNT DETAILS")}
            </Typography>
            {is_quote && (
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Typography className={classes.subTitle}>
                  {t("Show only primary account")}
                </Typography>
                <IOSSwitch
                  width={36}
                  height={16}
                  tamW={10}
                  tamH={10}
                  checked={primaryState}
                  onChange={(event) => {
                    setPrimary(event.target.checked);
                    onSelectAccount("");
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Box>
            )}
          </Box>
        </Stack>
        <Box height="8px" />
        <Box>
          <Steps
            t={t}
            component={[
              {
                type: "customSelectBox",
                placeHolder: t("Search Existing Account"),
                value: tempAcc,
                handleChange: (value) => onSelectAccount(value),
                options: [],
                sm: 12,
                md: 12,
                lg: 12,
                isActive: true,
                menuOptionHeight: 300,
                color: "#F5F7FA",
                key: JSON.stringify({ openAcc, primaryState }),
                loadOptions: (search, array, handleLoading) => {
                  if (is_quote && primaryState) {
                    return loadOptionsApis(
                      "contact/primary_account",
                      {
                        contact_id: contactId,
                      },
                      search,
                      array,
                      handleLoading,
                      "data"
                    );
                  }
                  return loadOptionsApis(
                    "queries/opportunity/contact_account",
                    {
                      company_id: company_id,
                      relationship:
                        type === "Sale"
                          ? "Customer"
                          : type === "Lease"
                          ? "Customer"
                          : type === "Manage"
                          ? "Customer"
                          : "Property Owner",
                    },
                    search,
                    array,
                    handleLoading,
                    "data"
                  );
                },
              },
            ]}
          />
        </Box>

        {/*account dialog*/}
        <React.Fragment key={"right"}>
          <Drawer
            anchor={"right"}
            open={openAcc}
            onClose={() => setOpenAcc(false)}
          >
            <AccountForm
              t={t}
              disableAcc={disableAcc}
              onSubmitAccount={onSubmitAccount}
              onClose={() => setOpenAcc(false)}
              account={acc}
              updateStateAcc={updateStateAcc}
              open={open}
            />
          </Drawer>
        </React.Fragment>
      </Box>

      <Box p={2}>
        <Stack
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
          direction="row"
        >
          <Button
            className={classes.btn}
            variant="outlined"
            onClick={() => previous(4)}
          >
            {t("Back")}
          </Button>
          <Button
            className={classes.sumbitBtn}
            variant="contained"
            onClick={sumbit}
          >
            {t("Update")}
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};
