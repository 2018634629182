import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const PublicListingStyles = makeStyles((theme) => ({
    body: {
        margin: "24px",
        padding:"16px",
        backgroundColor: theme.palette.background.tertiary1,
        borderRadius: theme.palette.borderRadius,
        boxShadow: `0px 0px 16px ${theme.palette.boxShadow}`
    },
    tab_div: {
        padding: "0px 20px 0px 20px"
    },
    search_filter_add_div: {
        padding: "20px"
    },
    search_div: {
        width: "25%",
    },
    filter_button: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "12px",
        marginInlineEnd: "16px",
        height:'40px',
        width:'40px'
    },
    add_button: {
        padding: "10px",
        border: `1px solid ${theme.palette.primary.main}`,
        "&:hover": {
            backgroundColor: theme.palette.background.tertiary1,
        },
        marginInlineStart: "16px",
        height: '40px'
    },
    add_button_text: {
        textAlign: "center",
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.selected_1
    },
    table_div: {
        padding: "0px 12px 20px 20px",
        marginTop: "-20px"
    }
}));