import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { ConfirmationDialogStyles } from './styles';

const ConfirmationDialog = ({
  open,
  onClose,
  title,
  message,
  yesClick,
  noClick,
  t,
}) => {
  if (!open) return null;

  const classes = ConfirmationDialogStyles();

  return (
    <Box className={classes.modalOverlay} onClick={onClose}>
      <Box className={classes.confirmationBox} onClick={(e) => e.stopPropagation()}>
        <Box className={classes.contentContainer}>
          <Box className={classes.iconContainer}>
            <img src="/images/tick-circle.svg" alt="Tick Circle" />
          </Box>
          <Box>
            <Typography className={classes.title}>
              {title ?? "Confirmation Dialog"}
            </Typography>
            <Typography className={classes.message}>
              {message ?? 'Are you sure you want to proceed?'}
            </Typography>
          </Box>
          <Box className={classes.buttonContainer}>
            <Button className={classes.cancelButton} onClick={noClick}>
              {t("Cancel")}
            </Button>
            <Button className={classes.confirmButton} onClick={yesClick}>
              {t("Confirm")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ConfirmationDialog;
