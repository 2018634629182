/* eslint-disable react-hooks/exhaustive-deps */
import {
  Badge,
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import FilterIMG from "../../assets/filter";
import {
  FilterGenerator,
  SearchFilter,
  Subheader,
  TableWithPagination,
  UseDebounce,
} from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import {
  accessCheckRender,
  AlertProps,
  getCompanyOption,
  getRoutePermissionNew,
  NetWorkCallMethods,
} from "../../utils";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import {
  PricingTablePropertyheading,
  PricingtablePropertypath,
} from "../../utils/tableData";
import { AmenitiesMappingStyles } from "./style";
import { withTranslation } from "react-i18next";

const AmenitiesMapping = ({ t }) => {
  const defaultFilterState = { status: [true] };
  const navigate = useNavigate();
  const classes = AmenitiesMappingStyles();
  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext);
  const backdrop = React.useContext(BackdropContext);
  const [drawer, setDrawer] = React.useState(null);
  const [propertyList, setPropertyList] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const [companyList, setCompanyList] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState({});
  const [filterData, setFilterData] = React.useState(defaultFilterState);
  // const clientId = localStorage.getItem(LocalStorageKeys.clinetID)
  const [permission, setPermission] = React.useState({});

  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");
  const limitParam = searchParams.get("limit");

  // Default values if not set in URL
  const pageFromParams = pageParam ? parseInt(pageParam, 10) : 1;
  const limitFromParams = limitParam ? parseInt(limitParam, 10) : 10;

  const [page, setPage] = React.useState(pageFromParams);
  const [limit, setLimit] = React.useState(limitFromParams);

  const updatePage = (newPage, newLimit = limit) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", newPage.toString());
    searchParams.set("limit", newLimit.toString());

    // Update URL without reloading
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
    // Update state
    setPage(newPage);
    setLimit(newLimit);
  };
  //handle pagination
  const handlePagination = (newPage) => {
    updatePage(newPage, limit);
  };

  const handleChangeLimit = (newLimit) => {
    updatePage(1, newLimit); // Reset page to 1 when limit changes
  };

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          setCompanyList(company?.list);
          setSelectedCompany(company?.selected);
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  //debounce
  const debounce = UseDebounce();
  //search in table
  const handleSearch = (e) => {
    setSearchText(e);
    debounce(() => searchTableFunction(e), 800);
  };
  //search function
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getPropertyByCompanyID(0, limit, e);
  };
  //icon click function
  const handleIcon = (type, data, status) => {
    if (type === "view") {
      navigate(Routes.amenitiesMappingPropertyView + "?propertyID=" + data?.id);
    }
  };
  //drawer open
  const draweropen = () => {
    setDrawer(true);
  };
  //drawer close
  const drawerclose = () => {
    setDrawer(false);
  };
  //table row
  const propertyRow = React.useCallback(
    propertyList?.data?.map((val, index) => {
      let _d;
      try {
        _d = {
          index: (page - 1) * limit + index + 1,
          propertyNo: val?.property_no,
          propertyName: val?.name,
          companyName: val?.company?.name,
          location: val?.addressByID?.city
            ? val?.addressByID?.city
            : val?.addressByID?.district,
          blocks: val?.block.length,
          floors: val?.floor.length,
          units: val?.unit.length,
          propertyType: val?.property_groupByID?.group_name,
          status: val?.is_active ? "Active" : "Inactive",
          icon: "editview",
          companyID: val?.company?.id,
          id: val?.id,
        };
      } catch (err) {}
      return _d;
    }),
    [propertyList]
  );
  //property lisiting
  const getPropertyByCompanyID = (offset = 0, limit = 10, search = "") => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });

    let companyID = [selectedCompany?.value];
    let propertyGroudID = filterData.property_type?.length
      ? filterData?.property_type?.map(({ value }) => value)
      : [];
    let is_active = filterData?.status ?? [];

    const payload = {
      offset,
      limit,
      search,
      company_id: companyID,
      property_group_id: propertyGroudID,
      status: is_active,
    };
    NetworkCall(
      `${config.api_url}/queries/amenity_mapping/list`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setPropertyList({
          data: response?.data?.data?.property,
          totalRowsCount: response?.data?.data?.count?.[0]?.count,
        });
        backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
      })
      .catch(() => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
      });
  };

  const currentOffset = (page - 1) * limit;
  React.useEffect(() => {
    if (selectedCompany?.value) {
      getPropertyByCompanyID(currentOffset, limitFromParams, searchText);
    }
  }, [selectedCompany, filterData]);

  //company change
  const handleCompanyChange = (value) => {
    setSelectedCompany(value);
  };
  //filter apply
  const onApplyFilter = (value) => {
    setFilterData(value);
  };

  const render = () => {
    return (
      <>
        <Subheader
          hideBackButton={true}
          title={t("Amenities Mapping")}
          select
          options={companyList}
          value={selectedCompany}
          goBack={() => {
            navigate(Routes.home);
          }}
          onchange={(e) => {
            handleCompanyChange(e);
          }}
        />
        <div className={classes.root}>
          {propertyRow?.length < 0 ? (
            <>
              <Grid container className={classes.content}>
                <Grid item xs={12}>
                  <img src="/images/propertybg.svg" alt="pp" />
                  <Typography className={classes.text}>
                    {t("No Property Found")}
                  </Typography>
                  <br />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid container className={classes.content} spacing={2}>
                <Grid item xs={4}>
                  <SearchFilter
                    value={searchText}
                    placeholder={t("Search Properties")}
                    handleChange={(value) => handleSearch(value)}
                  />
                </Grid>
                <Grid item xs={8} textAlign={"right"}>
                  <Box display={"flex"} sx={{ justifyContent: "end" }}>
                    <Stack
                      direction="row"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={2}
                    >
                      {filterData.status?.length > 0 ||
                      filterData.property_type?.length > 0 ? (
                        <IconButton
                          onClick={draweropen}
                          className={classes.img}
                        >
                          <Badge variant="dot" color="primary">
                            <FilterIMG color="#091b29" />
                          </Badge>
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={draweropen}
                          className={classes.img}
                        >
                          <FilterIMG color="#091b29" />
                        </IconButton>
                      )}
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
              <TableWithPagination
                heading={PricingTablePropertyheading(t)}
                rows={propertyRow}
                path={PricingtablePropertypath}
                showpagination={true}
                count="2"
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                onClick={() => console.log("")}
                tableType="no-side"
                handleIcon={handleIcon}
                dataType={[
                  { type: ["text"], name: "propertyNo" },
                  { type: ["text"], name: "propertyName" },
                  { type: ["text"], name: "companyName" },
                  { type: ["description"], name: "location" },
                  { type: ["text"], name: "units" },
                  { type: ["propertyType"], name: "propertyType" },
                  { type: ["status"], name: "status" },
                ]}
                handlePagination={handlePagination}
                handleChangeLimit={handleChangeLimit}
                totalRowsCount={propertyList?.totalRowsCount}
                page={page}
                limit={limit}
                height={"calc(100vh - 290px)"}
                view={permission?.read}
                edit={permission?.update}
                delete={permission?.delete}
              />
            </>
          )}
          {/* filter drawer */}
          {drawer && (
            <FilterGenerator
              open={drawer}
              onClose={drawerclose}
              defaultState={defaultFilterState}
              components={[
                {
                  component: "select",
                  value: filterData?.property_type,
                  options: [],
                  isMulti: true,
                  label: t("Property Type"),
                  state_name: "property_type",
                  placeholder: t("Select Property Type"),
                  loadOptions: (search, array, handleLoading) =>
                    loadOptionsApis(
                      "queries/property_type/get",
                      {},
                      search,
                      array,
                      handleLoading,
                      "property_group_master"
                    ),
                  debounceTimeout: 800,
                  isPaginate: true,
                },
                {
                  component: "toggleButton",
                  value: filterData?.status,
                  options: [
                    { label: t("Active"), value: true },
                    { label: t("Inactive"), value: false },
                  ],
                  isMulti: true,
                  state_name: "status",
                  label: t("Status"),
                  // required:true
                },
              ]}
              onApply={(value) => onApplyFilter(value)}
            />
          )}
        </div>
      </>
    );
  };

  return <div>{accessCheckRender(render, permission)}</div>;
};
export default withTranslation("amenities")(AmenitiesMapping);
