import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { FontFamilySwitch } from "../../utils";
const useStyles = makeStyles((theme) => ({
    title: {
        fontSize:"1rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary,
        marginInlineStart: "8px"
    },
    closeIcon: {
        backgroundColor: "#F5F7FA",
        borderRadius: "4px",
        height: "32px",
        width: "32px"
    },
    root:{
        border:(props)=>props?.border ?? "",
        backgroundColor:"#F2F4F7"
    }
}));
export const DialogHeader = ({ title = "", onClose = () => false, isLeft = false, p = 0.5 , border }) => {
    const classes = useStyles({border});
    return (
        <Box display="flex" p={p} alignItems="center" className={classes.root}>
            {
                isLeft &&
                <Box >
                    <IconButton className={classes.closeIcon} onClick={onClose}>
                        <CloseIcon style={{ color: "#98A0AC" }} />
                    </IconButton>
                </Box>
            }
            <Box flexGrow={1}>
                <Typography className={classes.title}>{title}</Typography>
            </Box>
            {
                !isLeft &&
                <Box>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            }


        </Box>
    );
}
