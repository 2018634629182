import { Box, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from 'react-router-dom';
import { Subheader } from "../../components";
import { useStyles } from "./style";
import { PortalCard, UploadCard } from "./components";
import UploadAsset from "./assets/uploadAsset";
import UnitListingIcon from "./assets/unitListingIcon";
import StayListingIcon from "./assets/stayLisingIcon";
import FacilityListingIcon from "./assets/facilityListingIcon";
import { NetworkCall } from "../../networkcall";
import { AlertContext, BackdropContext } from "../../contexts";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { config } from "../../config";
import { NewLoader } from "../../components/newLoader";
import { withTranslation } from "react-i18next";

const initialState = {
    client_logo: {
        id: "",
        url: ""
    },
    resident_listing_cover: {
        id: "",
        url: ""
    },
    commercial_listing_cover: {
        id: "",
        url: ""
    },
    stay_listing_cover: {
        id: "",
        url: ""
    },
    facilities_cover: {
        id: "",
        url: ""
    },
    "terms_&_condition_link": {
        id: "",
        url: ""
    },
    about_us_link: {
        id: "",
        url: ""
    },
    privacy_policy_link: {
        id: "",
        url: ""
    },
    contact_us_link: {
        id: "",
        url: ""
    },
}
const MarketingPortalConfigurartion = ({
    t
}) => {
    const classes = useStyles()
    const alert = React.useContext(AlertContext)
    const backdrop = React.useContext(BackdropContext)
    const [data, setData] = React.useState({ ...initialState });
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate()
    const updateState = (key, value, id) => {
        setData({ ...data, [key]: { url: value, id: id } })
    }
    // console.log(data, "data");
    //get initial data
    const getAssetsData = () => {
        setLoading(true)
        NetworkCall(
            `${config.api_url}/client_marketing_assets/get`,
            NetWorkCallMethods.get,
            null,
            null,
            true,
            false
        )
            .then((response) => {
                let results = response?.data?.data
                let finalData = {};
                for (let result of results) {
                    finalData[result?.type_name?.replace(/\s+/g, '_').replace(/([A-Z])/g, (match) => match.toLowerCase())] = {
                        id: result?.id,
                        url: result.url
                    }

                }
                setData({ ...data, ...finalData })
                setLoading(false)

            })
            .catch((error) => {
                console.log(error)

                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something went wrong please try again"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                });
                setLoading(false)

            });

    }

    //Initial load
    React.useEffect(() => {
        getAssetsData()
        // eslint-disable-next-line
    }, [])

    //card data
    // const cardData = [
    //     {
    //         id: 0,
    //         icon: <UnitListingIcon />,
    //         name: t("Unit listing"),
    //         is_purchased: true,
    //         total: 150,
    //         current: 108,
    //         is_active: true

    //     },
    //     {
    //         id: 1,
    //         icon: <StayListingIcon />,
    //         name: t("stay listing"),
    //         is_purchased: true,
    //         total: 150,
    //         current: 108,
    //         is_active: true

    //     },
    //     {
    //         id: 2,
    //         icon: <FacilityListingIcon />,
    //         name: t("facilities listing"),
    //         is_purchased: false,
    //         total: 150,
    //         current: 108,
    //         is_active: true

    //     },
    // ]

    //uploadData list
    const uploadData = [
        {
            type: 1,
            id: data?.client_logo?.id,
            name: t("company logo"),
            link: false,
            state: "client_logo",
            value: data?.client_logo?.url,
            icon: <UploadAsset />,
            tooltip: true

        },
        {
            type: 6,
            id: data["terms_&_condition_link"]?.id,
            name: t("Terms & Condition Link"),
            link: true,
            state: "terms_&_condition_link",
            value: data["terms_&_condition_link"]?.url,
            icon: <UploadAsset />,
            tooltip: false

        },
        {
            type: 2,
            id: data?.resident_listing_cover?.id,
            name: t("Resident Listing Cover"),
            link: false,
            state: "resident_listing_cover",
            value: data?.resident_listing_cover?.url,
            icon: <UploadAsset />,
            tooltip: true

        },
        {
            type: 7,
            id: data?.about_us_link?.id,
            name: t("About Us Link"),
            link: true,
            state: "about_us_link",
            value: data?.about_us_link?.url,
            icon: <UploadAsset />,
            tooltip: false

        },
        {
            type: 3,
            id: data?.commercial_listing_cover?.id,
            name: t("Commercial Listing Cover"),
            link: false,
            state: "commercial_listing_cover",
            value: data?.commercial_listing_cover?.url,
            icon: <UploadAsset />,
            tooltip: true

        },
        {
            type: 8,
            id: data?.privacy_policy_link?.id,
            name: t("Privacy Policy Link"),
            link: true,
            state: "privacy_policy_link",
            value: data?.privacy_policy_link?.url,
            icon: <UploadAsset />,
            tooltip: false

        },
        {
            type: 4,
            id: data?.stay_listing_cover?.id,
            name: t("Stay Listing Cover"),
            link: false,
            state: "stay_listing_cover",
            value: data?.stay_listing_cover?.url,
            icon: <UploadAsset />,
            tooltip: true

        },
        {
            type: 9,
            id: data?.contact_us_link?.id,
            name: t("Contact Us Link"),
            link: true,
            state: "contact_us_link",
            value: data?.contact_us_link?.url,
            icon: <UploadAsset />,
            tooltip: false

        },

        {
            type: 5,
            id: data?.facilities_cover?.id,
            name: t("Facilities Cover"),
            link: false,
            state: "facilities_cover",
            value: data?.facilities_cover?.url,
            icon: <UploadAsset />,
            tooltip: true

        }
    ]

    //update link and photo
    const updateLinkAndPhoto = (data) => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "updating....",
        });
        let payload;
        if (data?.id) {
            payload = {
                id: data?.id,
                url: data?.value,
                file_meta: {},
                client_marketing_type: data?.type,
                is_active: true
            }
        } else {
            payload = {
                url: data?.value,
                file_meta: {},
                client_marketing_type: data?.type,
                is_active: true
            }
        }
        NetworkCall(
            `${config.api_url}/client_marketing_assets/upsert`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                getAssetsData()
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
            })
            .catch((error) => {
                console.log(error)
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something went wrong please try again"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                });
            });

    }

    return (
        <Box>
            <Subheader title={t("Marketing Portal Configuration")} goBack={() => navigate()} />
            {loading ? <NewLoader /> :
                <Box className={classes.box} m={2}>
                    {/* <Grid container spacing={2} p={2} className={classes.gatWayRoot}>
                        {cardData?.map((item) => {
                            return (
                                <Grid item xs={12} md={4}>

                                    <PortalCard t={t} data={item} />
                                </Grid>
                            )
                        })}

                    </Grid> */}
                    <Grid container spacing={2} p={2}>
                        {uploadData?.map((item => {
                            return (
                                <Grid item xs={12} md={6}>

                                    <UploadCard t={t} data={item} updateState={updateState} updateLinkAndPhoto={updateLinkAndPhoto} />
                                </Grid>
                            )
                        }))}



                    </Grid>
                </Box>
            }
        </Box >
    )
}
export default withTranslation("marketingPortalConfigurartion")(MarketingPortalConfigurartion)
