import {
  Blockdrawer,
  BlockHeading,
  BlockPath,
  BlockType,
  Propertydrawer,
  PropertyHeading,
  PropertyPath,
  PropertyType,
  UnitTypedrawer,
  UnitTypeHeading,
  UnitTypePath,
  UnitTypetype
} from '.';
export const propertyActions = (type, offset, limit, search, company, status, t=()=>false) => {
  switch (type) {
    case "Property Type":
      return {
        endpoint: "/queries/property_group_master/list",
        resfunc: (array) => {
          let arr = array?.map((data, index) => {
            let mainData = {}
            mainData.id = data?.id;
            // mainData.company_name = {
            //   label: data?.company_master?.name,
            //   value: data?.company_master?.id
            // };
            mainData.purpose = {
              label: data?.purpose,
              value: data?.purpose,
            };
            mainData.status = data?.is_active ? "Active" : "Inactive";
            mainData.is_active = data?.is_active;
            mainData.is_external = data?.is_external;
            mainData.description = data?.description;
            mainData.group_name = data?.group_name;
            mainData.icon = "editviewtoggle";
            mainData.index = offset + index + 1;
            return mainData;
          });
          return arr;
        },
        tbdata: {
          heading: PropertyHeading(t),
          path: PropertyPath,
          type: PropertyType,
          drawer: Propertydrawer(company , t),
        }
      }
    case 'Block Type':
      return {
        endpoint: "/queries/block_type_master/list",
        resfunc: (array) => {
          let arr = array?.block_type_master.map((data, index) => {
            const bObj = {};
            bObj.id = data?.id;
            // bObj.company_name = {
            //   label: data?.company_master?.name,
            //   value: data?.company_master?.id
            // };
            bObj.name = data?.name;
            bObj.icon = "editviewtoggle";

            bObj.is_active = data.is_active;
            bObj.status = data?.is_active ? "Active" : "Inactive";
            bObj.is_external = data.is_external;
            bObj.index = offset + index + 1;
            return bObj;
          });
          return arr;
        },
        tbdata: {
          heading: BlockHeading(t),
          path: BlockPath,
          type: BlockType,
          drawer: Blockdrawer(company , t),
        }
      }
    case 'Unit Type':
      return {
        endpoint: "/queries/unit_type_master/list",
        resfunc: (array) => {
          let arr = array?.unit_type_master.map((data, index) => {
            const utObj = {};
            utObj.id = data?.id;
            utObj.name = data?.name;
            // utObj.company_name = {
            //   label: data?.company_master?.name,
            //   value: data?.company_master?.id
            // };
            utObj.purpose = {
              label: data?.purpose,
              value: data?.purpose,
            };

            utObj.icon = "editviewtoggle";

            utObj.is_active = data.is_active;
            utObj.status = data?.is_active ? "Active" : "Inactive";
            utObj.is_external = data.is_external;
            utObj.index = offset + index + 1;
            return utObj;
          });
          return arr;
        },
        tbdata: {
          heading: UnitTypeHeading(t),
          path: UnitTypePath,
          type: UnitTypetype,
          drawer: UnitTypedrawer(company,t),
        }
      }
    default:
      return null;
  }
};

export const getPropertyUpdateActions = (t) => ({
  [t('Property Type')]: {
    endpoint: "/queries/property_group_master/upsert",
  },
  [t('Block Type')]: {
    endpoint: "/queries/block_type_master/upsert",
  },
  [t('Unit Type')]: {
    endpoint: "/queries/unit_type_master/upsert",
  },
});

export const getPropertyEditActions = (t) => ({
  [t('Property Type')]: {
    endpoint: "/queries/property_group_master/upsert",
    key: 'propertyId'
  },
  [t('Block Type')]: {
    endpoint: "/queries/block_type_master/upsert",
    key: 'blockId'
  },
  [t('Unit Type')]: {
    endpoint: "/queries/unit_type_master/upsert",
    key: 'unit_typeId'
  }
});

export const propertySearch = {}
