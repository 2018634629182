import React from 'react';
import styled from "@mui/material/styles/styled";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { CustomTypography } from './customTypography';
import { FontFamilySwitch } from '../../../../utils';
const StickyHeader = styled('div')({
    position: 'sticky',
    top: 0,
    backgroundColor: "#fff",
    zIndex: 1
});

export const Header = (props) => {

    const {
        onClear = null
    } = props;

    return (
        <StickyHeader>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={2}
                padding={2}
            >
                <CustomTypography fontSize={16} fontFamily={FontFamilySwitch().bold}>Filter</CustomTypography>
                <CustomTypography sx={{ cursor: "pointer" }} color={"#5078E1"} fontFamily={FontFamilySwitch().semiBold} onClick={onClear}>Clear</CustomTypography>
            </Stack>
            <Divider />
        </StickyHeader>
    )
}