import { Box } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { LoadingSection, UseDebounce } from "../../../components";
import { InvoiceDetails, InvoiceTable } from "../components";
import { NetWorkCallMethods, timeZoneConverter } from "../../../utils";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";

const Invoicesection = ({ id = "", details = {}, t = () => false }) => {
    const [open, setOpen] = React.useState({ id: null, bool: false })
    const [loading, setLoading] = React.useState(true)
    const [tabledetails, setTableDetails] = React.useState()
    const [data, setData] = React.useState({
        list: [],
        count: 0
    })
    //get  invoices
    const getInvoice = (offset = 0, limit = 10, search = "") => {


        const payload = {
            id: id,
            offset: offset,
            limit: limit,
            search: search
        }

        NetworkCall(
            `${config.api_url}/invoice/agreement_invoice`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setData({
                list: response?.data?.invoice?.map((val) => {
                    return {
                        ...val,
                        tax_group: val?.vat_group?.group_name,
                        payment_status: val?.payment_status === "no-due" ? "Paid" : "Unpaid",
                    }
                }),
                count: response?.data?.count?.[0]?.count
            })
            setLoading(false)
        }).catch((err) => {
            console.log(err)
        })

    }
    //initial load
    React.useEffect(() => {
        getInvoice()
        // eslint-disable-next-line
    }, [])

    // get Receipt data

    const getInvoiceDropdown = (e, invoice_id) => {
        const payload = {
            invoice_id: invoice_id,
            search: e ?? ""
        }
        NetworkCall(
            `${config.api_url}/settlement/get_invoice_receipt_settlement`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            let agreementDataArray = []
            response?.data?.data?.invoice_receipt_settlement?.map((i) => {
                agreementDataArray.push(
                    {
                        amount_tagged: i.amount_tagged,
                        created_at: timeZoneConverter(i.created_at),
                        tagged_by: i.tagged_by.first_name,
                        reciepts_no: i.reciepts.reciepts_no,
                        source: "-",
                        url: i.reciepts.url,
                        settlement_no: i.settlement_no,
                        symbol: i?.invoice.company.currency.symbol
                    }
                )
                return 0
            }
            )
            setTableDetails(agreementDataArray)
        })
            .catch((err) => {
                console.log(err);
            });
    }

    // Search function

    const [searchText, setSearchText] = React.useState("");
    const debounce = UseDebounce();

    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    const searchTableFunction = (e) => {
        getInvoiceDropdown(e)
    }

    const handleClick = (val) => {
        setOpen({
            bool: !open?.bool,
            id: val
        })
        // console.log(val)
        getInvoiceDropdown("", val?.id)
    }


    return (
        <Box >
            {
                loading ?
                    <LoadingSection message={t("loading")} top="20vh" />
                    :
                    <>
                        {
                            open?.bool ?
                                <InvoiceDetails setLoading={setLoading} goBack={handleClick} details={details} inVoice_id={open?.id} tabledetails={tabledetails}
                                    searchText={searchText} handleSearch={handleSearch} />
                                :
                                <InvoiceTable handleClick={handleClick} getInvoice={getInvoice} id={id} setData={setData} data={data} />

                        }
                    </>
            }


        </Box>
    )
}
export default withTranslation("agreement")(Invoicesection); 