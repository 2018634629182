
import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    otp: {
        boxShadow: "none",
        "& input": {
            boxShadow: "none",
            height: "48px",
            width: "50px !important",
            background: theme.palette.common.white,
            borderRadius: theme.palette.borderRadius,
            fontSize:"1.125rem",
            fontFamily: FontFamilySwitch().bold,
            border: `1.5px solid ${theme.palette.border.secondary} !important`,
            "&:focus": {
                border: `1.5px solid ${theme.palette.primary.main} !important`,
                outline: "none",
            },
        },
    },
    resendOtp: {
        fontSize:"0.875rem",
        color: theme.typography.color.Tertiary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    resendOtps: {
        fontSize:"0.875rem",
        color: theme.palette.primary.main,
        fontFamily: FontFamilySwitch().semiBold,
    },
}));