import React from "react";
import { withNavBars } from "../../HOCs";
import QueuedInvoice from "./queuedInvoice";
class QueuedInvoiceParent extends React.Component {
  render() {
    return <QueuedInvoice />;
  }
}
const props = {
  boxShadow: false,
};
export default withNavBars(QueuedInvoiceParent, props);
