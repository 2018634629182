export const filterOptions= (t) => ({
    requestType:[
        {
            label:t("All"),
            value:t("All")
        },
        {
            label:t("Maintenance"),
            value:"Maintenance"
        },
        {
            label:t("General"),
            value:"General"
        },
        {
            label:t("Final Settlement"),
            value:"Final Settlement"
        },
        {
            label: "Quotation Approval",
            value: "Quotation Approval"
        },
        {
            label: "Agreement Order Confirmation Approval",
            value: "Agreement Order Confirmation Approval"
        },
        {
            label: "Agreement Confirmation Approval",
            value: "Agreement Confirmation Approval"
        },
        {
            label: "Agreement Emergency Cancellation Approval",
            value: "Agreement Emergency Cancellation Approval"
        },
        {
            label: "Reservation Request Approval",
            value: "Reservation Request Approval"
        }
    ]
})
export const notes = [
    {
        notes: "Request Approved"
    },
    {
        notes: "Request Rejected",
        status: "Rejected",
        comments:"test",
        created_at:new Date()
    }
]