/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from '@mui/icons-material/Close';
import { Badge, Box, Button, Dialog, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import FilterIMG from "../../assets/filter";
import { FilterGenerator, SearchFilter, SelectBox, Subheader, TableWithPagination, UseDebounce } from '../../components';
import { config } from '../../config';
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from '../../networkcall';
import { Routes } from '../../router/routes';
import { AlertProps, clearAccess, enum_types, enumSelect, getAccessRoutes, getCompanyOption, getPageLevelPermission, NetWorkCallMethods } from '../../utils';
import { ContactHeading, ContactPath, ContactType } from "../../utils/contact/contactListUtil";
import { contactListStyles } from "./style";

export const ContactList = () => {
    const initialAddContactDialogState = {
        relationship: "",
        role: "",
        vendor: "",
        error: {
            relationship: "",
            role: "",
            vendor: ""
        }
    }
    const classes = contactListStyles();
    const location = useLocation();
    const pathName = location?.pathname
    const [pageLevelPermissionData, setPageLevelPermissionData] = React.useState()
    const navigate = useNavigate();
    const debounce = UseDebounce();
    const alert = React.useContext(AlertContext);
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext);
    const [loading, setLoading] = React.useState(false)
    const [fiterDrawer, setFilterDrawer] = React.useState(null);
    const [addContactDialogOpen, setAddContactDialogOpen] = React.useState(null);
    const [contactList, setContactList] = React.useState({});
    const [searchText, setSearchText] = React.useState("");
    const [companyList, setCompanyList] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const [filterData, setFilterData] = React.useState({ status: [true] });
    const [enumValue, setEnum] = React.useState({
        relationship: [],
    })
    const [addContactDialogState, setAddContactDialogState] = React.useState({ ...initialAddContactDialogState });
    const updateAddContactDialogState = (k, v) => {
        let error = addContactDialogState?.error;
        error[k] = "";
        if (k === "relationship") {
            setAddContactDialogState({ ...addContactDialogState, [k]: v, role: "", vendor: "", error });
        } else {
            setAddContactDialogState({ ...addContactDialogState, [k]: v, error });
        }
    }
    const validateAddContactDialogState = () => {
        let isValid = true;
        let error = addContactDialogState.error;
        if (addContactDialogState?.relationship?.length === 0) { isValid = false; error.relationship = "Relationship is Required"; }
        if (addContactDialogState?.relationship?.value === "Company Employee" &&
            addContactDialogState?.role?.length === 0) { isValid = false; error.role = "Role is Required"; }
        if (addContactDialogState?.relationship?.value === "External Vendor Employee" &&
            addContactDialogState?.vendor?.length === 0) { isValid = false; error.vendor = "Vendor is Required"; }
        if (!isValid) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: "Please fill all mandatory field",
            });
        }
        setAddContactDialogState({ ...addContactDialogState, error });
        return isValid;
    };
    const relationshipOptionList = [
        { label: 'Company Employee', value: "Company Employee" },
        { label: 'Contract Employee', value: "Contract Employee" },
        { label: 'Investor', value: "Investor" },
        { label: 'Board Member', value: "Board Member" },
        { label: 'External Sales Broker', value: "External Sales Broker" },
        { label: 'External Vendor Employee', value: "External Vendor Employee" },
        { label: 'External Contact', value: "External Contact" },
        { label: 'External Property Owner', value: "External Property Owner" },
        { label: 'Other', value: "Other" },
    ];
    const statusOptionList = [
        { label: 'Active', value: true },
        { label: 'Inactive', value: false },
    ];
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getContactByCompanyID(offset, limit, searchText)
    }
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getContactByCompanyID(0, value, searchText)
    }
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getContactByCompanyID(0, limit, e)
    }
    const handleIcon = (type, data) => {
        if (type === "edit") {
            navigate(Routes.createContact, {
                state: {
                    main: {
                        id: data?.id,
                        isEdit: true
                    },
                },
            });
        }
        else if (type === "view") { navigate(Routes.contactView + "?contactID=" + data?.id); }
        else if (type === "active") { changeContactStatus(data?.id, data?.status) }
    }
    const changeContactStatus = async (data, value) => {
        const variables = { id: data, update: { is_active: value === "Inactive" ? true : false } }
        NetworkCall(
            `${config.api_url}/queries/contact/update`,
            NetWorkCallMethods.post,
            variables,
            null,
            true,
            false
        ).then((response) => {
            getContactByCompanyID();
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.success,
                msg: "Contact Updated Successfully.!!!",
            });
        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: "Something Went Wrong. Internal Error.!!!",
            });
        });
    }
    const draweropen = () => {
        setFilterDrawer(true);
    };
    const drawerclose = () => {
        setFilterDrawer(false);
    };
    const handleAddContactDialogOpen = () => {
        setAddContactDialogState({ ...initialAddContactDialogState });
        setAddContactDialogOpen(true);
    }
    const handleAddContactDialogClose = () => {
        setAddContactDialogOpen(false);
    }
    const handleOnClickAddContactButton = () => {
        handleAddContactDialogOpen();
    }

    const handleCreate = () => {
        if (validateAddContactDialogState()) {
            navigate(Routes.createContact, {
                state: {
                    main: {
                        company: selectedCompany,
                        relationship: addContactDialogState?.relationship,
                        role: addContactDialogState?.role ?? undefined,
                        vendor: addContactDialogState?.vendor ?? undefined,
                    },
                },
            });
        } else { return false; }
    }
    const ContactRow = React.useCallback(contactList?.data?.map((val, index) => {
        let _d;
        try {
            _d = {
                id: val?.id,
                image: val?.image_url ?? "",
                name: val?.first_name ?? "-",
                ucid: val?.customer_id ?? "-",
                department: val?.department ?? "-",
                jobTitle: val?.job_title ?? "-",
                mobileNumber: (val?.mobile_no_country_code ?? "") + (val?.mobile_no ?? "-"),
                email: val?.email_id ?? "-",
                relationship: val?.relationship ?? "-",
                lastLogin: val?.last_logged_in ?? "-",
                status: val?.is_active ? "Active" : "Inactive",
            };
        } catch (err) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: "Some Thing Went Wrong",
            });
        }
        return _d;
    }), [contactList]);
    const getContactByCompanyID = (offset = 0, limit = 10, search = "") => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "", });
        const payload = {
            "tenantId": `${config.tenantId}`,
            "company_id": selectedCompany?.value,
            "search": search,
            "filter": {
                relationship: filterData?.relationship ? filterData?.relationship?.map((val) => val?.value) : undefined,
                status: (!filterData?.status || filterData?.status?.length === 0) ? [true, false] : filterData?.status
            },
            "offset": offset,
            "limit": limit
        }
        NetworkCall(
            `${config.api_url}/contact/list`,
            NetWorkCallMethods.post,
            payload, null, true, false).then((response) => {
                setContactList({ data: response?.data?.data, totalRowsCount: response?.data?.count, });
                backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
            }).catch(() => {
                backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.error, msg: "Some Thing Went Wrong",
                });
            });
    };
    React.useEffect(() => {

        let access = auth?.auth?.auth?.access

        let permissionData = Object.keys(access)

        let permissionJson = access?.[permissionData?.[0]]?.role?.permissions?.[0]?.structure
        clearAccess()
        let permisionlist = getAccessRoutes(permissionJson)
        let permissionValue = getPageLevelPermission(Routes, pathName, permisionlist)
        setPageLevelPermissionData(permissionValue)

        setPage(1)
        if (selectedCompany?.value) {
            getContactByCompanyID();
        }
    }, [selectedCompany, filterData]);
    React.useEffect(() => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
        }
        // eslint-disable-next-line
    }, [auth]);
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
    }
    const onApplyFilter = (value) => {
        setFilterData(value)
    }
    const loadOptions = async (search = "", array, type) => {
        setLoading(type);
        let result, query, offset = 0;
        let companyID = [selectedCompany?.value];
        if (search && !Boolean(array?.length)) {
            offset = 0;
        }
        else {
            offset = array?.length;
        }
        switch (type) {
            case 'roles':
                query = {
                    url: "/queries/roles/get",
                    payload: {
                        offset, limit: 10, search
                    }
                }
                result = await networkCallBack(query);
                return {
                    options: [...result?.roles],
                    hasMore: (array?.length + result?.roles?.length) < result?.count
                }
            case 'vendor_master':
                query = {
                    url: "/queries/vendor_master/list",
                    payload: {
                        offset, limit: 10, search, company_id: companyID
                    }
                }
                result = await networkCallBack(query);
                return {
                    options: [...result?.vendor_master],
                    hasMore: (array?.length + result?.vendor_master?.length) < result?.count
                }
            default:
                return { options: [] }
        }
    }
    const networkCallBack = async (query, variable = {}) => {
        let payload = query.payload

        const options = await NetworkCall(
            `${config.api_url}${query.url}`,
            NetWorkCallMethods.post,
            payload, null, true, false).then((response) => {
                let main = response.data.data;
                setLoading(null);
                return main
            }).catch(() => {
                setLoading(null);
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some Thing Went Wrong",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                return null
            });
        return options
    }
    const getEnum = async () => {
        const result = await enumSelect([enum_types.relationship_type])
        setEnum({
            relationship: result?.relationship_type,
        })
    }
    React.useEffect(() => {
        getEnum()
    }, [])
    return <div >
        <Subheader hideBackButton={true} title="Contacts"
            select options={companyList} value={selectedCompany} onchange={(e) => handleCompanyChange(e)} />
        <div className={classes.root}>
            <Grid container className={classes.content} spacing={1}>
                <Grid item xs={4}>
                    <SearchFilter value={searchText} placeholder="Search Contacts" handleChange={(value) => handleSearch(value)} />
                </Grid>
                <Grid item xs={8} textAlign={"right"}>
                    <Box display={"flex"} sx={{ float: "right" }}>
                        <Stack direction="row" spacing={2}
                            divider={<Divider orientation="vertical" flexItem />}>
                            {filterData.relationship?.length > 0 || filterData.status?.length > 0 ?
                                (<IconButton onClick={draweropen} className={classes.img}>
                                    <Badge variant="dot" color="primary">
                                        <FilterIMG color="#091b29" />
                                    </Badge>
                                </IconButton>) :
                                (<IconButton onClick={draweropen} className={classes.img}>
                                    <FilterIMG color="#091b29" />
                                </IconButton>)}
                            {pageLevelPermissionData?.create &&
                                <Button variant="contained" className={classes.btn}
                                    onClick={handleOnClickAddContactButton}>
                                    Add A Contact
                                </Button>
                            }
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <TableWithPagination
                        heading={ContactHeading}
                        rows={ContactRow}
                        path={ContactPath}
                        showpagination={true}
                        count="2"
                        showpdfbtn={false}
                        showexcelbtn={false}
                        showSearch={false}
                        handleIcon={handleIcon}
                        onClick={() => console.log("")}
                        tableType="no-side"
                        dataType={ContactType}
                        handlePagination={handlePagination}
                        handleChangeLimit={handleChangeLimit}
                        totalRowsCount={contactList?.totalRowsCount}
                        page={page}
                        limit={limit}
                        height={'calc(100vh - 290px)'}
                        view={true}
                        edit={true}
                        delete={true} />
                </Grid>
            </Grid>
            <FilterGenerator open={fiterDrawer} onClose={drawerclose} onApply={(value) => onApplyFilter(value)}
                components={[
                    {
                        component: "select",
                        value: filterData?.relationship,
                        options: relationshipOptionList,
                        isMulti: true,
                        state_name: "relationship",
                        label: "Relationship",
                    },
                    {
                        component: "toggleButton",
                        value: filterData?.status,
                        options: statusOptionList,
                        isMulti: true,
                        state_name: "status",
                        label: "Status",
                    },
                ]} />
            <Dialog
                className={classes.addContactDialogStyle}
                open={addContactDialogOpen}
                onClose={handleAddContactDialogClose}>
                <div className={classes.dialogHeaderStyle}>
                    <Typography className={classes.dialogHeaderTitleStyle}>
                        {"Add Contact"}
                    </Typography>
                    <IconButton onClick={handleAddContactDialogClose} className={classes.dialogCloseIconButtonStyle}>
                        <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
                    </IconButton>
                </div>
                <div className={classes.dialogBodyStyle}>
                    <SelectBox
                        isRequired
                        label="Relationship"
                        options={enumValue?.relationship}
                        placeholder={"Select Relationship"}
                        value={addContactDialogState?.relationship}
                        onChange={(value) => {
                            updateAddContactDialogState("relationship", value);
                        }}
                        isError={addContactDialogState?.error?.relationship?.length > 0}
                        errorMessage={addContactDialogState?.error?.relationship} />
                    <Box height={16} />
                    {(addContactDialogState?.relationship && addContactDialogState?.relationship?.value === "Company Employee") &&
                        <SelectBox
                            key={addContactDialogState?.relationship?.value}
                            isRequired
                            label="Role"
                            placeholder="Select Role"
                            value={addContactDialogState?.role}
                            onChange={(value) => {
                                updateAddContactDialogState("role", value);
                            }}
                            loading={loading === "roles"}
                            isPaginate
                            debounceTimeout={800}
                            loadOptions={(search, array) => loadOptions(search, array, 'roles')}
                            isError={addContactDialogState?.error?.role?.length > 0}
                            errorMessage={addContactDialogState?.error?.role} />}
                    {(addContactDialogState?.relationship && addContactDialogState?.relationship?.value === "External Vendor Employee") &&
                        <SelectBox
                            key={addContactDialogState?.relationship?.value}
                            isRequired
                            label="Vendor"
                            placeholder="Select Vendor"
                            value={addContactDialogState?.vendor}
                            onChange={(value) => {
                                updateAddContactDialogState("vendor", value);
                            }}
                            loading={loading === "vendor_master"}
                            isPaginate
                            debounceTimeout={800}
                            loadOptions={(search, array) => loadOptions(search, array, 'vendor_master')}
                            isError={addContactDialogState?.error?.vendor?.length > 0}
                            errorMessage={addContactDialogState?.error?.vendor} />}
                </div>
                <div className={classes.dialogFooterStyle}>
                    <Button variant="contained" fullWidth
                        className={classes.dialogFooterButtonStyle}
                        onClick={handleCreate}>
                        {"Create"}
                    </Button>
                </div>
            </Dialog>
        </div >
    </div >
}