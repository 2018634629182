import { Badge, Box, Button, Divider, Grid, IconButton, Stack } from "@mui/material";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import FilterIMG from "../../assets/filter";
import { FilterGenerator, LoadingSection, SearchFilter, Subheader, TableWithPagination, UseDebounce } from "../../components";
import { Routes } from "../../router/routes";
import { FloorHeadingNew, FloorPath } from "../../utils/block";
import { floorStyles } from "./style";
import { accessCheckRender, AlertProps, getCustomFloorName, getRoutePermissionNew, NetWorkCallMethods } from '../../utils'
import { AuthContext } from "../../contexts";
import { AlertContext } from "../../contexts";


import { withTranslation } from "react-i18next";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";

const FloorList = (props) => {
    const defaultFilterState = { status: [true] };
    const { t } = (props);
    const classes = floorStyles();
    const navigate = useNavigate()
    const search = useLocation().search;
    const [floorlist, setFloorList] = React.useState({
        data: [],
        count: 0,
        property: {},
        block: {}
    })
    const alert = React.useContext(AlertContext);
    const [drawer, setDrawer] = React.useState(false);
    const [loading, setLoading] = React.useState(true)
    const [searchText, setSearchText] = React.useState("");
    const [property, setProperty] = React.useState(null);
    const blockID = new URLSearchParams(search).get("blockID");
    const propertyID = new URLSearchParams(search).get("propertyID");
    const [filterData, setFilterData] = React.useState(defaultFilterState);
    const debounce = UseDebounce();
    const auth = React.useContext(AuthContext)
    const [permission, setPermission] = React.useState({})

    const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");
  const limitParam = searchParams.get("limit");

  // Default values if not set in URL
  const pageFromParams = pageParam ? parseInt(pageParam, 10) : 1;
  const limitFromParams = limitParam ? parseInt(limitParam, 10) : 10;

  const [page, setPage] = React.useState(pageFromParams);
  const [limit, setLimit] = React.useState(limitFromParams);

  const updatePage = (newPage, newLimit = limit) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", newPage.toString());
    searchParams.set("limit", newLimit.toString());

    // Update URL without reloading
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });

    // Update state
    setPage(newPage);
    setLimit(newLimit);
  };

  //handle pagination
  const handlePagination = (newPage) => {
    updatePage(newPage, limit);
  };

  const handleChangeLimit = (newLimit) => {
    updatePage(1, newLimit); // Reset page to 1 when limit changes
  };

  const currentOffset = (page - 1) * limit;
    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getfloorlist(propertyID ? propertyID : blockID, filterData, "", currentOffset, limit)
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    // eslint-disable-next-line
    const headerpage = [
        {
            id: "1",
            label: floorlist?.block?.name,
            type: "name"
        },
        {
            id: "2",
            label: getCustomFloorName(floorlist?.property?.property_hierarchyByID)
        }
    ]
    const headerpage1 = [
        {
            id: "2",
            label: getCustomFloorName(floorlist?.property?.property_hierarchyByID)
        }
    ]

    const getfloorlist = async (id, filterProp, searchText, offSet, limits) => {
        const payload = {
            id, searchText, active: filterProp?.status,
            offset: offSet, limit: limits,
        };

        NetworkCall(
            `${config?.api_url}/queries/floor/${propertyID ? `by_property` : `by_block`}`,
            NetWorkCallMethods?.post, payload, null, true, false
        ).then((res) => {
            setProperty(res?.data?.data)
            const list = res?.data?.data?.floor?.map((val) => {
                let _d;
                try {
                    _d = {
                        main: val,
                        image: val?.logo,
                        floorNo: val?.floor_no,
                        propertyName: val?.property?.name,
                        floorName: val?.name,
                        companyname: val?.property?.company?.name,
                        location: val?.property?.address?.city ? val?.property?.address?.city : val?.property?.address?.district,
                        blockname: val?.block?.name,
                        unit: val?.unit?.length,
                        id: val?.id,
                        propertytype: val?.property?.property_group_master?.group_name,
                        revenuetype: val?.property?.revenue_type,
                        status: val?.is_active ? "Active" : "Inactive",
                        icon: "editview",
                    };
                } catch (err) {

                }
                return _d;
            })
            if (propertyID) {
                setFloorList({
                    count: res?.data?.data.count[0]?.count,
                    data: list,
                    property: res?.data?.data.property[0]
                })
            } else {
                setFloorList({
                    count: res?.data?.data.count[0]?.count,
                    data: list,
                    property: res?.data?.data.block[0]?.property,
                    block: res?.data?.data?.block?.[0]
                })
            }
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }

    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)

    }
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getfloorlist(propertyID ? propertyID : blockID, filterData, e, 0, limit)

    }
    const handleOnClickAddPropertyButton = () => {

        navigate(Routes.createFloorNew, {
            state: {
                main: {
                    blockID: blockID,
                    propertyID: floorlist?.property?.id ? floorlist?.property?.id : propertyID,
                }
            }
        })
    }
    const changeactive = async (id, value) => {
        const payload = {
            ID: id,
            updatePayload: { is_active: value === "Inactive" }
        };

        NetworkCall(
            `${config?.api_url}/queries/floor/update`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then(() => {
            getfloorlist(propertyID ? propertyID : blockID, filterData, "", 0, limit)
        }).catch((err) => console.log(err));
    }
    const handleIcon = (type, data) => {
        if (type === "active") {
            changeactive(data?.id, data?.status)
        }

        if (type === "edit") {
            navigate(Routes.createFloorNew, {
                state: {
                    edit: {
                        blockID: property?.floor?.block_id,
                        floorID: data?.id,
                        propertyID: floorlist?.property?.id,
                        isEdit: true
                    }
                }
            })
        }

        if (type === "view") {
            navigate(Routes.floorDetails + "?companyID=" + data?.companyID + "&floorID=" + data?.id + "&name=" + data?.floorName)
        }
    }
    const onUnitClick = (value, type) => {
        navigate(Routes.unit + "?floorID=" + value?.id)
    }
    const goBack = () => {
        navigate(-1);
    }

    const onApplyFilter = (value) => {
        setFilterData(value)
        getfloorlist(propertyID ? propertyID : blockID, value, "", 0, limit)
    }
    const render = () => {
        return <>
            {

                loading ? <LoadingSection bottom={"45vh"} message={t("Loading Floor Listing...")} />
                    :
                    <div className={classes.root}>
                        <Subheader arrow pageoptions={propertyID ? headerpage1 : headerpage} title={floorlist?.property?.name} goBack={goBack} />
                        <Box p={2} m={2} className={classes.table}>
                            <Grid container >
                                <Grid item xs={4}>

                                    <SearchFilter value={searchText} placeholder={t("Search Floor")} handleChange={(value) => handleSearch(value)} />
                                </Grid >
                                <Grid item xs={8}>
                                    <Box
                                        display={"flex"}

                                        sx={{ justifyContent: "end" }}
                                    >
                                        <Stack
                                            direction="row"
                                            divider={<Divider orientation="vertical" flexItem />}
                                            spacing={2}
                                        >
                                            {filterData?.status?.length > 0 ? (
                                                <IconButton onClick={() => setDrawer(true)} className={classes.img}>
                                                    <Badge variant="dot" color="primary">
                                                        <FilterIMG color="#091b29" />
                                                    </Badge>
                                                </IconButton>
                                            ) : (
                                                <IconButton onClick={() => setDrawer(true)} className={classes.img}>
                                                    <FilterIMG color="#091b29" />
                                                </IconButton>)}
                                            {(permission?.create &&
                                                floorlist?.property?.property_hierarchyByID.is_floor) &&
                                                <Button variant="contained" className={classes.btn} onClick={handleOnClickAddPropertyButton}>

                                                    {t("Add ") + getCustomFloorName(floorlist?.property?.property_hierarchyByID)}
                                                </Button >
                                            }

                                        </Stack >
                                    </Box >
                                </Grid >

                            </Grid >
                            <TableWithPagination
                                heading={FloorHeadingNew(floorlist?.property?.property_hierarchyByID, t)}
                                rows={floorlist?.data}
                                path={FloorPath}
                                showpagination={true}
                                count="2"
                                showpdfbtn={false}
                                showexcelbtn={false}
                                showSearch={false}
                                handleIcon={handleIcon}
                                tableType="no-side"
                                onClick={() => console.log("")}
                                onUnitClick={permission?.read ? onUnitClick : false}
                                dataType={[
                                    { type: ["avatarmanagement"], name: "image" },
                                    { type: ["text"], name: "floorNo" },
                                    { type: ["text"], name: "floorName" },
                                    { type: ["text"], name: "companyname" },
                                    { type: ["description"], name: "location" },
                                    { type: ["test"], name: "blockname" },
                                    { type: ["unit"], name: "unit" },
                                    { type: ["text"], name: "revenuetype" },
                                    // { type: ["propertyType"], name: "propertytype" },
                                    { type: ["status"], name: "status" },
                                    { type: ["more"], icon: "icon" },
                                ]}
                                handlePagination={handlePagination}
                                handleChangeLimit={handleChangeLimit}
                                totalRowsCount={floorlist?.count}
                                page={page}
                                limit={limit}
                                height={'calc(100vh - 300px)'}
                                view={permission?.read}
                                edit={permission?.update}
                                delete={false} />


                        </Box >
                    </div >
            }
            {/* filter drawer */}
            {
                drawer &&
                <FilterGenerator
                open={drawer}
                onClose={() => setDrawer(false)}
                defaultState={defaultFilterState}
                components={[
                    {
                        component: "toggleButton",
                        value: filterData?.status,
                        options: [
                            { label: t("Active"), value: true },
                            { label: t("Inactive"), value: false },
                        ],
                        isMulti: true,
                        state_name: "status",
                        label: t("Status"),
                        onChange: (newValue) => {
                            if (newValue.includes(false) && !filterData?.status.includes(false)) {
                                alert.setSnack({
                                    ...alert,
                                    open: true,
                                    severity: AlertProps.severity.warning,
                                    msg: t("Status changed to include Inactive")
                                });
                            }
                        }
                    },
                ]}
                onApply={(value) => onApplyFilter(value)}
            />
            }
        </>
    }

    return (
        <>
            {accessCheckRender(render, permission)}
        </>
    );
};

export default withTranslation("floorList")(FloorList)
