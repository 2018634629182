import React, { createContext, useState, useEffect } from 'react';
import { LocalStorageKeys } from '../utils/constants';

export const CompanyContext = createContext();

export const CompanyProvider = ({ children }) => {
  const [selectedCompany, setSelectedCompany] = useState(localStorage.getItem(LocalStorageKeys.selectedCompany) || '');

  useEffect(() => {
    const handleStorageChange = () => {
      setSelectedCompany(localStorage.getItem(LocalStorageKeys.selectedCompany) || '');
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentCompany = localStorage.getItem(LocalStorageKeys.selectedCompany) || '';
      if (currentCompany !== selectedCompany) {
        setSelectedCompany(currentCompany);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [selectedCompany]);

  return (
    <CompanyContext.Provider value={{ selectedCompany, setSelectedCompany }}>
      {children}
    </CompanyContext.Provider>
  );
};
