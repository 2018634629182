import { Box, Button, Grid, Typography, Stack, Divider } from '@mui/material'
import React from 'react'
import { AlertDialog, MenuList, Subheader, UseDebounce } from '../../components'
import { withTranslation } from "react-i18next";
import { InviteTriggerStyle } from './style'
import { ProfileCard, InviteCard, InvitePopup } from './component';
import { accessCheckRender, AlertProps, getCompanyOption, getRoutePermissionNew, LocalStorageKeys, NetWorkCallMethods } from '../../utils';
import { AlertContext, AuthContext, BackdropContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { config } from '../../config';
import { CustomSelect } from '../../components/filterGenerator/components';

const InviteTriggerComp = ({ t }) => {

    const classes = InviteTriggerStyle()
    const debounce = UseDebounce()
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext)
    const [selected, setSelected] = React.useState("")
    const [search, setSearch] = React.useState("");
    const [offset, setOffset] = React.useState(0);
    const [menuList, setMenuList] = React.useState({
        type: { label: t("Customer"), value: "Customer" },
        list: null
    })
    const [companyList, setCompanyList] = React.useState([]);
    const [permission, setPermission] = React.useState({})
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const [invitePopup, setInvitePopup] = React.useState(false)
    const [access, setAccess] = React.useState([])
    const [selectedInvite, setSelectedInvite] = React.useState("")
    const [show, setShow] = React.useState(true)
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)

    const selectList = (e) => {
        // setSelected(e)
        getUserAccess(e, menuList?.type)
    }

    React.useEffect(() => {
        const _ = getRoutePermissionNew(auth)
        if (_) {
            setPermission(_)
            if (_?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                // const moduleList = JSON.parse(localStorage.getItem(LocalStorageKeys.modules))
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                    getMenuList(0, "", true, company?.selected, { label: "Customer", value: "Customer" })
                    // setModuleData({
                    //     modules: moduleList
                    // })
                }
            }
        }


        // eslint-disable-next-line        
    }, [auth])

    //   company switcher
    const handleCompanyChange = (e) => {
        setSelectedCompany(e);
        getMenuList(0, "", true, e , menuList?.type)
    };

    // infinity scroll
    const fetchMoreData = () => {
        setOffset(offset + 20);
        getMenuList(offset + 20, search, false, selectedCompany, menuList?.type);
    };
    //handle search
    const handleSearch = (value) => {
        setSearch(value);
        getMenuList(0, value, true, selectedCompany, menuList?.type)
    }
    //Get MenuList
    const getMenuList = (offset, search, reload, company, type) => {

        const payload = {
            start: offset ?? 0,
            length: 20,
            search: search ?? "",
            client: localStorage.getItem(LocalStorageKeys.clinetID),
            company_id: company?.value,
            type: type?.value
        }


        NetworkCall(
            `${config.api_url}/invite/get_agreement_user `,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            if (reload) {

                setMenuList({
                    ...menuList,
                    list: response?.data?.list,
                    type
                })
                setSelected(response?.data?.list?.[0])
                if (response?.data?.list?.[0]) {
                    getUserAccess(response?.data?.list?.[0], type)
                    setShow(true)
                }
                if (!response?.data?.list?.[0]) {
                    setShow(false)
                }

                setOffset(0)
            } else {
                setMenuList({
                    ...menuList,
                    list: [...menuList?.list, ...response?.data?.list]
                })
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    //Get access Information
    const getUserAccess = (e, type) => {
        let payload = {
            contact_id: e?.id,
            type: type?.value === "Customer" ? ["Tenant" , "Workspace" , "Tenant2" , "Owner"] : undefined
        }
        NetworkCall(
            `${config.api_url}/invite/get_user_access`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((r) => {
            setAccess(r?.data?.data)
            setSelected({
                ...r?.data?.data?.profile,
                id: e?.id,
                user_profile_id: e?.user_profile_id
            })
        }).catch((error) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: AlertProps.message.some_thing_went_wrong,
            })
        })
    }

    const invite = (data , app_name) => {
        setSelectedInvite(data)
        if (menuList?.type?.value === "Customer") {

        setIsDisableBtn(true)
        let payload = {
                contact_id: selected?.id,
                type:app_name
            }
            NetworkCall(
                `${config.api_url}/invite/invite_tenant`,
                NetWorkCallMethods.post,
                payload, null, true, false
            ).then((r) => {
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Invite Send Successfully"),
                })
            }).catch((error) => {
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: AlertProps.message.some_thing_went_wrong,
                })
            })

        } else {
            setInvitePopup(!invitePopup)
        }
    }

    const clickResentInvite = (value) => {
        debounce(() => resendInvite(value?.id), 400)
    }

    const resendInvite = (id) => {
        setIsDisableBtn(true)
        let payload = {
            contact_id: id
        }
        NetworkCall(
            `${config.api_url}/invite/resend`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((r) => {
            setIsDisableBtn(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: r?.data?.data,
            })
        }).catch((error) => {
            setIsDisableBtn(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: AlertProps.message.some_thing_went_wrong,
            })
        })
    }

    const inviteClick = (inviteData) => {

        let selectedCompany = inviteData?.stateData?.company?.map((c) => {
            return c?.value
        })

        let selectedProperty = inviteData?.stateData?.property?.map((p) => {
            return p?.value
        })
        let payload = {
            contact_id: selected?.id,
            role_id: inviteData?.selectedInvite?.id,
            access: {
                company_ids: selectedCompany,
                properties: selectedProperty
            }
        }
        setIsDisableBtn(true)
        NetworkCall(
            selectedInvite?.is_invite ? `${config.api_url}/invite/app` : `${config.api_url}/invite/app_access_update`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((r) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t(r?.data?.data),
            })
            setIsDisableBtn(false)
            setInvitePopup(!invitePopup)
            getUserAccess(selected)
        }).catch((error) => {
            setIsDisableBtn(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: AlertProps.message.some_thing_went_wrong,
            })
        })
    }

    
    const render = () => {
        return (
            <Box>
                <Subheader
                    title={t("User Invitations")}
                    hideBackButton
                    select={true}
                    options={companyList}
                    value={selectedCompany}
                    onchange={(e) => {
                        handleCompanyChange(e);
                    }}
                />
                <Box p={2}>
                    <Grid container>

                        <Grid item lg={2.5} md={4} sm={5} className={classes.listGrid}>

                            <Box p={2}>

                                <CustomSelect
                                    options={[{ label: ("Customer"), value: t("Customer") }, { label: t("Employee"), value: t("Employee") }]}
                                    value={menuList?.type}
                                    onChange={(value) => getMenuList(0, "", true, selectedCompany, value)}
                                    selectHeight='40px'
                                />

                            </Box>
                            <Divider></Divider>
                            <Box p={2}>
                                <MenuList
                                    list={menuList?.list}
                                    selected={selected?.id}
                                    setSelected={selectList}
                                    searchLabel={t("search")}
                                    fetchMoreData={fetchMoreData}
                                    handleSearch={handleSearch}
                                    searchValue={search}
                                    boxPadding={4}
                                    listHeight={320}
                                    t={t}
                                />
                            </Box>
                        </Grid>
                        <Grid item lg={9.5} md={8} sm={7} pl={2}>


                            <Box className={classes.contentBox}>
                                {
                                    show ?
                                        <>
                                            <Box p={2}>
                                                <Typography className={classes.nameTitle}> {selected?.name} </Typography>
                                            </Box>
                                            <ProfileCard t={t} reload={() => getMenuList(0, "", true, selectedCompany, menuList?.type)} data={selected} setSelected={setSelected} />
                                            <Box className={classes.inviteBox}>
                                                {
                                                    menuList?.type?.value === "Employee" &&

                                                    <>
                                                        {access?.modules?.length > 0 &&
                                                            <>
                                                                <Stack direction={"row"} justifyContent={"space-between"} p={2}>

                                                                    <Stack>
                                                                        <Typography className={classes.title}>{t("Invite to modules")}</Typography>
                                                                        <Typography className={classes.subtitle}>{t("Send an invite to the member for the module and role.")}</Typography>
                                                                    </Stack>
                                                                    <Button variant='contained' className={classes.inviteBtn} onClick={() => clickResentInvite(selected)} disabled={isDisableBtn}>{t("Resend Invite")}</Button>

                                                                </Stack >
                                                                <Divider></Divider>
                                                            </>
                                                        }


                                                        <Grid container spacing={2} p={2}>
                                                            {
                                                                access?.modules?.map(e => {
                                                                    return (

                                                                        <Grid item md={6}>
                                                                            <InviteCard t={t} data={e} click={invite} isDisableBtn={isDisableBtn} />
                                                                        </Grid>
                                                                    )
                                                                })
                                                            }
                                                        </Grid>

                                                        <Divider></Divider>
                                                    </>
                                                }
                                                <Stack p={2}>
                                                    <Typography className={classes.title}>{t("Invite to Apps")}</Typography>
                                                    <Typography className={classes.subtitle}>{t("Send an invite to the member for the app.")}</Typography>
                                                </Stack>
                                                <Divider></Divider>
                                                <Grid container spacing={2} p={2}>
                                                    {
                                                        access?.apps?.map(e => {
                                                            return (

                                                                <Grid item sm={12} md={12} lg={6}>

                                                                    <InviteCard t={t} data={e} isCustomer={menuList?.type?.value === "Customer"} click={(data)=>invite(data , e?.name)} type="app" isDisableBtn={isDisableBtn}/>
                                                                </Grid>
                                                            )
                                                        })
                                                    }
                                                </Grid>
                                            </Box></> : ""
                                }

                            </Box >
                        </Grid >
                    </Grid >
                </Box >
                <AlertDialog open={invitePopup}
                    onClose={() => setInvitePopup(!invitePopup)}
                    header={t("INVITE")}
                    component={<InvitePopup t={t} selectedInvite={selectedInvite} inviteClick={inviteClick} contact_id={selected?.id} isDisableBtn={isDisableBtn}/>}
                    isNormal
                />
            </Box >
        )
    }

    return (
        <Box>
            {accessCheckRender(render, permission)}
        </Box>
    )
}
export default withTranslation("inviteTrigger")(InviteTriggerComp); 