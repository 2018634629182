import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const PublicListingStyles = makeStyles((theme) => ({
    body: {
        margin: "16px",
        backgroundColor: theme.palette.background.tertiary1,
        borderRadius: theme.palette.borderRadius,
        boxShadow: `0px 0px 16px ${theme.palette.boxShadow}`
    },
    tab_div: {
        padding: "0px 16px 0px 16px"
    },
    search_filter_add_div: {
        padding: "16px"
    },
    search_div: {
        width: "25%",
    },
    add_button_div: {
        width: "25%",
        display: "flex",
        justifyContent: "end",
    },
    filter_button: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "12px"
    },
    add_button: {
        padding: "0px 16px",
        height: "40px",
        backgroundColor: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    add_button_text: {
        textAlign: "center",
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: "white"
    },
    table_div: {
        padding: "0px 16px 16px 16px",
        marginTop: "-16px"
    }
}));