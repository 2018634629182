import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlertContext } from "../contexts";
import { Routes } from "../router/routes";
import { AlertProps, NetWorkCallMethods } from "../utils";
import {
  createState,
  returnContactCreatePayload,
  returnLeadCreatePayload,
} from "../utils/createQuotationUtils";
import { withTranslation } from "react-i18next";
import { NetworkCall } from "../networkcall";
import { config } from "../config";

export const CreateShortListContext = createContext();

const CreateShortlistContextProvider = (props) => {
  const { t } = props;
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [dataNew, setDataNew] = React.useState({ ...createState });
  const [disable, setDisable] = React.useState(false);
  const [selectedAccount, setSelectedAccount] = React.useState({
    broker: null,
    normal: null,
  });
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  //validate sale oppertunity
  const validateSaleOppertunity = () => {
    let isValid = true;
    let error = dataNew.error;
    if (dataNew?.contract_start_date?.length === 0) {
      isValid = false;

      error.contract_start_date = t("Contract Start Date is Required");
    }
    if (dataNew?.payment_period?.value === "Milestone Based") {
      if (dataNew?.delivery_timestone_template?.length === 0) {
        isValid = false;
        error.delivery_timestone_template = t("Delivery Milstone is Required");
      }
    }

    if (dataNew?.payment_period?.length === 0) {
      isValid = false;
      error.payment_period = t("Payment Period is Required");
    }
    if (dataNew?.payment_option?.length === 0) {
      isValid = false;
      error.payment_option = t("Payment Options is Required");
    }
    if (dataNew?.oppertunity_subject?.length === 0) {
      isValid = false;
      error.oppertunity_subject = t("Subject is Required");
    }
    if (dataNew?.source?.length === 0) {
      isValid = false;
      error.source = t("Source is Required");
    }
    if (dataNew?.priority?.length === 0) {
      isValid = false;
      error.priority = t("Priority is Required");
    }

    setDataNew({ ...dataNew, error });
    return isValid;
  };
  //update state
  const updateStateNew = (key, value) => {
    let error = dataNew.error;
    error[key] = "";
    setDataNew({ ...dataNew, [key]: value, error });
  };
  const alert = React.useContext(AlertContext);

  //validate lead
  const validateLeadDetails = () => {
    let isValid = true;
    let error = dataNew.error;
    if (dataNew?.duration?.length === 0) {
      isValid = false;
      error.duration = t("Duration is Required");
    }
    if (dataNew?.lease_start_date?.length === 0) {
      isValid = false;
      error.lease_start_date = t("Start Date & End Date is Required");
    }

    if (dataNew?.billing_start_date?.length === 0) {
      isValid = false;
      error.billing_start_date = t("Billing Start Date is Required");
    }
    if (dataNew?.payment_period?.length === 0) {
      isValid = false;
      error.payment_period = t("Payment Period is Required");
    }
    if (dataNew?.payment_option?.length === 0) {
      isValid = false;
      error.payment_option = t("Payment Options is Required");
    }
    if (dataNew?.payment_period?.value !== "Prepaid") {
      if (dataNew?.bill_cycle_date?.length === 0) {
        isValid = false;
        error.bill_cycle_date = t("Billing Cycle Date is Required");
      }
      if (dataNew?.bill_cycle_method?.length === 0) {
        isValid = false;
        error.bill_cycle_method = t("Billing Cycle Method is Required");
      }
    }
    if (dataNew?.oppertunity_subject?.length === 0) {
      isValid = false;
      error.oppertunity_subject = t("Subject is Required");
    }
    if (dataNew?.source?.length === 0) {
      isValid = false;
      error.source = t("Source is Required");
    }
    if (dataNew?.priority?.length === 0) {
      isValid = false;
      error.priority = t("Priority is Required");
    }
    if (dataNew?.renewal_based_on?.length === 0) {
      isValid = false;
      error.renewal_based_on = t("Renewal Based on is Required");
    }
    if (
      dataNew?.renewal &&
      (dataNew?.renewal_based_on?.value === "Escalation %" ||
        dataNew?.renewal_based_on?.value === "Increment %")
    ) {
      if (dataNew?.esclation_percentage?.length === 0) {
        isValid = false;
        error.esclation_percentage = t(
          "Increment/Escalation Percentage is Required"
        );
      }
    }
    if (
      dataNew?.bill_cycle_date?.value === "Custom Date" &&
      dataNew?.payment_period?.value !== "Prepaid"
    ) {
      if (dataNew?.custom_date?.length === 0) {
        isValid = false;
        error.custom_date = t("Custom Date is Required");
      }
    }

    setDataNew({ ...dataNew, error });
    return isValid;
  };
  //previous btn function
  const previous = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };
  //create shortlist
  const createShortlist = (id, unitIds) => {
    const units = unitIds?.map((val) => {
      return {
        unit_id: val?.id,
        lead_id: id,
        is_active: true,
      };
    });
    NetworkCall(
      `${config?.api_url}/queries/opportunity/create/shortlisted`,
      NetWorkCallMethods.post,
      {
        data: units,
      },
      null,
      true,
      false
    )
      .then((rs) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Shortlist Successfully Created"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        navigate(Routes.propertyFinder);
        setDisable(false);
      })
      .catch((er) => {
        console.log(er);
      });
  };
  //next function if newlead
  const goNew = () => {
    if (step === 0) {
      setStep(1);
    } else if (step === 1) {
      if (
        dataNew?.revenue_type?.value === "Lease" ||
        dataNew?.revenue_type?.value === "Manage"
      ) {
        if (validateLeadDetails()) {
          setStep(2);
        }
      } else if (dataNew?.revenue_type?.value === "Sale") {
        if (validateSaleOppertunity()) {
          setStep(2);
        }
      }
    } else if (step === 2) {
      upsertLead();
    }
  };
  //next function if existinglead
  const goExisting = () => {
    if (step === 1) {
      setDisable(true);
    }
    createShortlist(dataNew?.lead_details?.id, dataNew?.units);
  };
  //next btn function
  const next = () => {
    if (dataNew.type === "new lead") {
      goNew();
    } else if (dataNew.type === "existing lead") {
      goExisting();
    }
  };
  const upsertLead = () => {
    let unit_id = [];
    const prospect = returnContactCreatePayload(dataNew);
    let lead = returnLeadCreatePayload(dataNew, selectedAccount);
    if (dataNew?.units?.length > 0) {
      unit_id = dataNew?.units?.map((val) => {
        return {
          unit_id: val?.id,
          company_id: dataNew?.company,
          is_active: true,
          property_id: val?.property_id ?? null,
        };
      });
    }
    const payload = {
      prospective: prospect,
      opportunity: lead,
      units: unit_id,
      is_response: true,
    };
    NetworkCall(
      `${config?.api_url}/queries/opportunity/upsert`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((rs) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Shortlist Successfully Created"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        navigate(Routes.propertyFinder);
        setDisable(false);
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing went wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };

  return (
    <CreateShortListContext.Provider
      value={{
        step,
        previous,
        next,
        updateStateNew,
        dataNew,
        setDataNew,
        disable,
        setShow,
        show,
        setShow1,
        show1,
        setSelectedAccount,
        selectedAccount,
        t,
      }}
    >
      {props.children}
    </CreateShortListContext.Provider>
  );
};

export default withTranslation("createShortlist")(
  CreateShortlistContextProvider
);
