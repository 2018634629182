import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { DeclineQRcodeStyle } from './style'
import ShareSvg from '../../../assets/shareSvg'
import QRCode from "react-qr-code";
import moment from 'moment';
import { addSecondFormat } from '../../../utils';
import CircleIcon from '@mui/icons-material/Circle';
import { withTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';

const DeclineQRcode = (
    {
        amenityBookingDetail = {},
        type = "",
        cancelClick = () => false,
        t,
        status = false,
    }
) => {
    const classes = DeclineQRcodeStyle()

    const contentRef = React.useRef(null);

    const convertToImage = async (fileName) => {
        const contentElement = contentRef.current;
        if (contentElement) {
            await html2canvas(contentElement, { useCORS: true }).then(async canvas => {
                const img = new Image();
                img.src = canvas.toDataURL("image/png");

                let file2 = await urltoFile(img.src, `${fileName}.png`, "image/png")
                let fileArray = [file2];
                if (navigator.canShare && navigator.canShare({ files: fileArray })) {
                    try {
                        await navigator
                            .share({
                                files: fileArray
                            })
                        console.log(`File Shared...`);
                    } catch (err) {
                        console.log(`Couldn't share ${err}`);
                    }


                }

            });
        }
    };

    function urltoFile(url, filename, mimeType) {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const QrContent = () => {
        return (
            <Stack
                ref={contentRef}
                alignItems={"center"} spacing={3} sx={{ height: "350px", width: "100%", padding: "24px" }}
            >
                {/* <Stack spacing={2} alignItems={"center"}> */}
                <img className={classes.company_logo} src={amenityBookingDetail?.company_logo} alt='logo' />
                <Box sx={{
                    height: "200px",
                    width: "200px",
                    marginTop: "-20px",
                    background: "yellow"
                }}>
                    <QRCode value={amenityBookingDetail?.booking_id} size={200} />
                </Box>
                {/* </Stack> */}

                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "14px" }} >
                    <Box display="flex" alignItems="center" bgcolor={amenityBookingDetail?.booking_status === "Booked" ? "#E8F9E8" : "#E4E8EE"} style={{ borderRadius: "4px" }}>
                        <Box style={{ overflow: "hidden" }}>
                            <CircleIcon
                                style={{ marginLeft: "-7px", marginTop: "8px", fontSize: "1rem", color: "white" }}
                            />
                        </Box>
                        <Box flexGrow={1} padding={"8px 36px"}>
                            <Stack alignItems={"center"}>
                                <Typography className={amenityBookingDetail?.booking_status === "Booked" ? classes.qr : classes.cancelled_qr}>
                                    {

                                        // amenityBookingDetail?.period === "Daily" ? 
                                        // `${moment(amenityBookingDetail?.booking_start_time).format("DD MMM") - moment(amenityBookingDetail?.booking_end_time).format("DD MMM") - moment(amenityBookingDetail?.booking_start_time).format("DD MMM YYYY")}`
                                        ((amenityBookingDetail?.booked_slots?.[0]?.[0]) === "00:00:00" ? "12:00" : addSecondFormat(amenityBookingDetail?.booked_slots?.[0]?.[0], 0, "HH:mm")) + " - " +//"08:59:59"
                                        (amenityBookingDetail?.booked_slots?.[amenityBookingDetail?.booked_slots?.length - 1]?.[1] === "23:59:59" ? "24:00" : addSecondFormat(amenityBookingDetail?.booked_slots?.[amenityBookingDetail?.booked_slots?.length - 1]?.[1], 1, "HH:mm")) + " - " +
                                        moment(amenityBookingDetail?.booking_start_time).format("DD MMM YYYY")
                                    }
                                </Typography>

                                <Typography
                                    variant="subtitle2"
                                    className={classes.expire}
                                >
                                    {t("SlotDetails")}
                                </Typography>
                            </Stack>
                        </Box>
                        <Box style={{ overflow: "hidden" }}>
                            <CircleIcon
                                style={{ marginRight: "-7px", marginTop: "8px", fontSize: "1rem", color: "white" }}
                            />
                        </Box>
                    </Box>
                </div>

            </Stack>
        )
    }

    return (
        <Stack p={2} className={classes.box} spacing={3}>
            <Box className={classes.shareIcon} onClick={() => convertToImage(amenityBookingDetail?.booking_id)}>
                <ShareSvg />
            </Box>
            <Stack alignItems={"center"} spacing={3} sx={{ height: amenityBookingDetail?.check_logs?.length > 0 ? 340 : status ? 310 : amenityBookingDetail?.is_expired ? 340 : amenityBookingDetail?.booking_status === "Booked" ? 250 : 355 }}>
                {/* <Stack spacing={2} alignItems={"center"}> */}
                <img className={classes.company_logo} src={amenityBookingDetail?.company_logo} alt='logo' />
                <QRCode value={amenityBookingDetail?.booking_id} size={200} />
                {/* </Stack> */}

                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "14px" }} >
                    <Box display="flex" alignItems="center" bgcolor={amenityBookingDetail?.booking_status === "Booked" ? "#E8F9E8" : "#E4E8EE"} style={{ borderRadius: "4px" }}>
                        <Box style={{ overflow: "hidden" }}>
                            <CircleIcon
                                style={{ marginLeft: "-7px", marginTop: "8px", fontSize: "1rem", color: "white" }}
                            />
                        </Box>
                        <Box flexGrow={1} padding={"8px 36px"}>
                            <Stack alignItems={"center"}>
                                <Typography className={amenityBookingDetail?.booking_status === "Booked" ? classes.qr : classes.cancelled_qr}>
                                    {

                                        // amenityBookingDetail?.period === "Daily" ? 
                                        // `${moment(amenityBookingDetail?.booking_start_time).format("DD MMM") - moment(amenityBookingDetail?.booking_end_time).format("DD MMM") - moment(amenityBookingDetail?.booking_start_time).format("DD MMM YYYY")}`
                                        ((amenityBookingDetail?.booked_slots?.[0]?.[0]) === "00:00:00" ? "12:00" : addSecondFormat(amenityBookingDetail?.booked_slots?.[0]?.[0], 0, "HH:mm")) + " - " +//"08:59:59"
                                        (amenityBookingDetail?.booked_slots?.[amenityBookingDetail?.booked_slots?.length - 1]?.[1] === "23:59:59" ? "24:00" : addSecondFormat(amenityBookingDetail?.booked_slots?.[amenityBookingDetail?.booked_slots?.length - 1]?.[1], 1, "HH:mm")) + " - " +
                                        moment(amenityBookingDetail?.booking_start_time).format("DD MMM YYYY")
                                    }
                                </Typography>

                                <Typography
                                    variant="subtitle2"
                                    className={classes.expire}
                                >
                                    {t("SlotDetails")}
                                </Typography>
                            </Stack>
                        </Box>
                        <Box style={{ overflow: "hidden" }}>
                            <CircleIcon
                                style={{ marginRight: "-7px", marginTop: "8px", fontSize: "1rem", color: "white" }}
                            />
                        </Box>
                    </Box>
                </div>
            </Stack>
            <Box sx={{ height: 0, width: "100%", overflow: "hidden" }}>
                {
                    <QrContent />
                }
            </Box>
            {/* {
                type !== "decline" &&
                <Button variant='outlined' className={classes.declinebtn} fullWidth>Decline Request</Button>
            } */}
            {
                (amenityBookingDetail?.booking_status === "Booked" && !amenityBookingDetail?.is_expired && amenityBookingDetail?.check_logs?.length === 0) &&
                <Button variant='outlined' fullWidth disabledElevation
                    className={classes.cancelBtn}
                    onClick={cancelClick}>{t("CancelRequest")}</Button>
            }
        </Stack>
    )
}



export default withTranslation("amenityBooking")(DeclineQRcode)
