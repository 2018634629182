import { Box, Popover, Typography, Slider } from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import styled from "@mui/material/styles/styled";
import makeStyles from "@mui/styles/makeStyles";
import { DateRangePicker } from "materialui-daterange-picker";
import moment from "moment";
import React from "react";
import { CalenderIcon } from "../../assets";
import {
  DatePickerNew,
  DatePickerTimeNew,
  Label,
  NewDatePicker,
  TextBox,
} from "../../components";
import {
  timeZoneConverter,
  useWindowDimensions,
  FontFamilySwitch,
} from "../../utils";
import {
  CustomSelect,
  MinMaxTextFieldWithSelect,
  ToggleButton,
} from "./components";
import SelectBox from "../selectBox";

const Div = styled("div")(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
  overflow: "auto",
}));

const CustomDivider = styled(Divider)(({ theme }) => ({
  marginBlock: theme.spacing(1),

  borderColor: "#E4E8EE",
  width: "100%",
}));
const useStyles = makeStyles((theme) => ({
  menu: {
    "& .MuiMenu-paper": {
      borderRadius: theme.palette.borderRadius,
      padding: "0px !important",
      boxShadow: "0px 8px 24px #0717411F",
    },
    "& .MuiMenu-list": {
      padding: "0 !important",
    },
    "& .MuiPopover-paper": {
      borderRadius: theme.palette.borderRadius,
      boxShadow: "0px 8px 24px #0717411F",
      padding: "0px !important",
    },
  },
}));

export const Body = (props) => {
  const {
    data = {},
    error = {},
    components = [],
    onChangeState = null,
    t = () => false,
  } = props;

  const size = useWindowDimensions();
  const classes = useStyles();

  const [calenderOpen, setCalendarOpen] = React.useState(false);
  const openClosePopOver = () => {
    setCalendarOpen(!calenderOpen);
  };
  const [calenderOpen1, setCalendarOpen1] = React.useState(false);
  const openClosePopOver1 = () => {
    setCalendarOpen1(!calenderOpen1);
  };

  const onCalendarPickerChange = (val, range) => {
    let value = {
      label: "Custom",
      value: {
        from_date: range?.startDate,
        to_date: range?.endDate,
      },
      load: true,
    };
    setCalendarOpen1(!calenderOpen1);
    onChangeState(val?.state_name, value, val);
  };

  const switchComponents = (val) => {
    switch (val.component) {
      case "select":
        return (
          <>
            <CustomSelect
              label={val?.label ?? ""}
              options={val?.options?.length ? val?.options : []}
              isMulti={val?.isMulti}
              value={data?.[val?.state_name] ?? null}
              onChange={(value) => {
                onChangeState(val?.state_name, value, val);
              }}
              error={error?.[val?.state_name] ?? false}
              errorText={t("This field is required")}
              required={val?.required ?? false}
              isPaginate={val?.isPaginate}
              debounceTimeout={val?.debounceTimeout}
              loadOptions={val?.loadOptions}
              loading={val?.loading ? val?.loading : false}
              labelSize={val?.labelSize ?? ""}
              labelColor={val?.labelColor ?? ""}
              fontFamily={val?.fontFamily}
              placeholder={val?.placeholder ?? ""}
              key={val?.key}
            />
            {data?.[val?.state_name]?.label === "Custom" && (
              <Box
                style={{
                  backgroundColor: "#FFFFFF",
                  alignItems: "center",
                  display: "flex",
                  zIndex: 999,
                  cursor: "pointer",
                  padding: "10px",
                  marginTop: "5px",
                  border: "1px solid #E4E8EE",
                  borderRadius: "4px",
                }}
                onClick={openClosePopOver1}
              >
                <CalenderIcon />
                <Typography
                  style={{
                    marginLeft: "6px",
                    fontSize: "0.875rem",
                    fontFamily: FontFamilySwitch().semiBold,
                    color: "#333333",
                  }}
                >
                  {`${
                    data?.[val?.state_name]?.value?.from_date
                      ? moment(
                          data?.[val?.state_name]?.value?.from_date
                        ).format("YYYY-MM-DD")
                      : ""
                  } 
                    ${
                      data?.[val?.state_name]?.value?.from_date &&
                      data?.[val?.state_name]?.value?.to_date
                        ? "to"
                        : "Select Date"
                    }
                     ${
                       data?.[val?.state_name]?.value?.to_date
                         ? moment(
                             data?.[val?.state_name]?.value?.to_date
                           ).format("YYYY-MM-DD")
                         : ""
                     }`}
                </Typography>
              </Box>
            )}

            <Popover
              className={classes.menu}
              open={calenderOpen1}
              onClose={openClosePopOver1}
              anchorReference="anchorPosition"
              anchorPosition={{ top: 130, left: 1000 }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <DateRangePicker
                open={calenderOpen1}
                toggle={openClosePopOver1}
                onChange={(value) => onCalendarPickerChange(val, value)}
                initialDateRange={{
                  startDate: data?.[val?.state_name]?.value?.from_date
                    ? new Date(data?.[val?.state_name]?.value?.from_date)
                    : new Date(),
                  endDate: data?.[val?.state_name]?.value?.to_date
                    ? new Date(data?.[val?.state_name]?.value?.to_date)
                    : new Date(),
                }}
                definedRanges={[]}
              />
            </Popover>
          </>
        );
      case "toggleButton":
        return (
          <ToggleButton
            options={val?.options?.length ? val?.options : []}
            value={data?.[val?.state_name]}
            onChange={(value) => onChangeState(val?.state_name, value, val)}
            isMulti={val?.isMulti}
            fullWidth={val?.fullWidth}
            buttonStyle={{
              borderColor: "#E4E8EE",
              borderRadius: 1,
              backgroundColor: "#fff",
              ...val?.buttonStyle,
            }}
            buttonGroupStyle={{ ...val?.buttonGroupStyle }}
            label={val?.label ?? ""}
            error={error?.[val?.state_name] ?? false}
            errorText={t("This field is required")}
            required={val?.required ?? false}
            labelColor={val?.labelColor ?? ""}
            labelSize={val?.labelSize ?? ""}
            fontFamily={val?.fontFamily ?? ""}
          />
        );
      case "minMaxTextFieldWithSelect":
        return (
          <MinMaxTextFieldWithSelect
            value={data?.[val?.state_name] ?? null}
            onChange={(value) => onChangeState(val?.state_name, value, val)}
            label={val?.label ?? ""}
            error={error?.[val?.state_name] ?? false}
            errorText={t("This field is required")}
            required={val?.required ?? false}
            placeholders={val?.placeholders ?? null}
            endAdornmentLabels={val?.endAdornmentLabels ?? null}
            options={val?.options}
            select={val?.select}
          />
        );
      case "minAndmaxDate":
        return (
          <>
            <Label label={val?.minMaxlabel} isRequired={val?.required} />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <NewDatePicker
                  isRequired={val?.isRequired}
                  disableFuture={val?.disableFuture}
                  disablePast={val?.disablePast}
                  minDate={val?.minDate}
                  maxDate={val?.maxDate}
                  label={val?.label?.startLabel}
                  placeholder={val?.placeholder?.startLabel}
                  value={data?.[val?.state_name]?.["startDate"]}
                  onChange={(value) =>
                    onChangeState(
                      val?.state_name,
                      {
                        ...data?.[val?.state_name],
                        startDate: value,
                      },
                      val
                    )
                  }
                  labelColor={val?.labelColor ?? ""}
                  labelSize={val?.labelSize ?? ""}
                  fontFamily={val?.fontFamily ?? ""}
                  height={val?.height}
                  newDate={val?.newDate}
                />
              </Grid>
              <Grid item xs={6}>
                <NewDatePicker
                  isRequired={val?.isRequired}
                  disableFuture={val?.disableFuture}
                  disablePast={val?.disablePast}
                  minDate={val?.minDate}
                  maxDate={val?.maxDate}
                  label={val?.label?.endLabel}
                  placeholder={val?.placeholder?.endLabel}
                  value={data?.[val?.state_name]?.["endDate"]}
                  onChange={(value) =>
                    onChangeState(
                      val?.state_name,
                      {
                        ...data?.[val?.state_name],
                        endDate: value,
                      },
                      val
                    )
                  }
                  labelColor={val?.labelColor ?? ""}
                  labelSize={val?.labelSize ?? ""}
                  fontFamily={val?.fontFamily ?? ""}
                  height={val?.height}
                  newDate={val?.newDate}
                />
              </Grid>
            </Grid>
          </>
        );
      case "date":
        return (
          <>
            <Label
              style={{
                fontFamily: FontFamilySwitch().semiBold,
                fontSize: "1rem",
                marginBottom: "6px",
              }}
              label={val?.label}
              isRequired={val?.required}
            />
            <DatePickerNew
              isRequired={val?.isRequired}
              disableFuture={val?.disableFuture}
              disablePast={val?.disablePast}
              minDate={val?.minDate}
              maxDate={val?.maxDate}
              label={val?.label?.startLabel}
              placeholder={val?.placeholder}
              value={data?.[val?.state_name] ?? null}
              handleChange={(value) =>
                onChangeState(val?.state_name, value, val)
              }
            />
          </>
        );
      case "date_range":
        return (
          <Grid container>
            <Label
              label={val?.label}
              isRequired={val?.required}
              style={{
                fontFamily: FontFamilySwitch().semiBold,
                fontSize: "1rem",
                marginBottom: "6px",
              }}
            />
            <Grid container spacing={"16px"}>
              <Grid item xs={6}>
                <Label label={t("From")} textAlign={"left"} />
                <Box
                  style={{
                    border: "1px solid #e4e8ee",
                    borderRadius: "4px",
                    height: "45px",
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    zIndex: 999,
                    cursor: "pointer",
                  }}
                  onClick={openClosePopOver}
                >
                  <Typography
                    style={{
                      fontSize: !data?.[val?.state_name]?.startDate
                        ? "0.875rem"
                        : "1rem",
                      color: !data?.[val?.state_name]?.startDate
                        ? "#98A0AC"
                        : "#333333",
                      fontFamily: !data?.[val?.state_name]?.startDate
                        ? FontFamilySwitch().regular
                        : FontFamilySwitch().semiBold,
                    }}
                  >
                    {data?.[val?.state_name]?.startDate
                      ? timeZoneConverter(data?.[val?.state_name]?.startDate)
                      : val?.startPlaceholder ?? t("From")}
                  </Typography>
                  <img src={"/images/icons8-calendar (5).svg"} alt="" />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Label
                  label={val?.toLabel ? val?.toLabel : t("Till")}
                  textAlign={"left"}
                />
                <Box
                  style={{
                    border: "1px solid #e4e8ee",
                    borderRadius: "4px",
                    height: "45px",
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    zIndex: 999,
                    cursor: "pointer",
                  }}
                  onClick={openClosePopOver}
                >
                  <Typography
                    style={{
                      fontSize: !data?.[val?.state_name]?.endDate
                        ? "0.875rem"
                        : "1rem",
                      color: !data?.[val?.state_name]?.endDate
                        ? "#98A0AC"
                        : "#333333",
                      fontFamily: !data?.[val?.state_name]?.endDate
                        ? FontFamilySwitch().regular
                        : FontFamilySwitch().semiBold,
                    }}
                  >
                    {data?.[val?.state_name]?.endDate
                      ? timeZoneConverter(data?.[val?.state_name]?.endDate)
                      : val?.endPlaceholder ??  t("Till")}
                  </Typography>
                  <img src={"/images/icons8-calendar (5).svg"} alt="" />
                </Box>
              </Grid>
            </Grid>
            <Popover
              open={calenderOpen}
              onClose={openClosePopOver}
              anchorReference="anchorPosition"
              anchorPosition={{ top: 250, left: size?.width }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <DateRangePicker
                open={calenderOpen}
                toggle={openClosePopOver}
                onChange={(range) => {
                  onChangeState(val?.state_name, range, val);
                  openClosePopOver();
                }}
                initialDateRange={data?.[val?.state_name]}
                definedRanges={[]}
              />
            </Popover>
          </Grid>
        );
      case "amount_minAndmax":
        return (
          <Grid container>
            <Label label={val?.label} isRequired={val?.required} />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextBox
                  label={t("From")}
                  type={t("number")}
                  value={data?.[val?.state_name]?.["fromAmount"]}
                  endAdornment={val?.amount_symbol}
                  onChange={(value) =>
                    onChangeState(
                      val?.state_name,
                      {
                        ...data?.[val?.state_name],
                        fromAmount: value.target.value,
                      },
                      val
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextBox
                  label={t("To")}
                  type={"number"}
                  value={data?.[val?.state_name]?.["toAmount"]}
                  endAdornment={val?.amount_symbol}
                  onChange={(value) =>
                    onChangeState(
                      val?.state_name,
                      {
                        ...data?.[val?.state_name],
                        toAmount: value.target.value,
                      },
                      val
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        );
      case "select_box":
        return (
          <>
            {val?.is_active && (
              <CustomSelect
                label={val?.label ?? ""}
                options={val?.options?.length ? val?.options : []}
                isMulti={val?.isMulti}
                value={val.value}
                onChange={val?.onChange}
                error={val?.error?.length > 0}
                errorText={val?.error}
                required={val?.isReadonly ?? false}
                isPaginate={val?.isPaginate}
                loadOptions={val?.loadOptions}
                loading={val?.loading}
                debounceTimeout={800}
                key={val?.key}
                labelSize={val?.labelSize ?? ""}
                labelColor={val?.labelColor ?? ""}
                fontFamily={val?.fontFamily}
              />
            )}
          </>
        );
      case "dateTimePickerWithRange":
        return (
          <>
            <Label
              label={val?.title}
              isRequired={val?.required}
              style={{
                fontFamily: FontFamilySwitch().bold,
                fontSize: "0.875rem",
                color: "#091B29",
              }}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <DatePickerTimeNew
                  isRequired={val?.isRequired}
                  isTime={val?.isNot ? false : true}
                  minDate={val?.minDate}
                  maxDate={val?.maxDate}
                  label={val?.label?.startLabel}
                  placeholder={val?.placeholder?.startLabel}
                  value={data?.[val?.state_name]?.["startDate"]}
                  onChange={(value) =>
                    onChangeState(
                      val?.state_name,
                      {
                        ...data?.[val?.state_name],
                        startDate: value,
                      },
                      val
                    )
                  }
                  border={val?.border}
                  is_popover={val?.is_popover}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePickerTimeNew
                  isRequired={val?.isRequired}
                  isTime={val?.isNot ? false : true}
                  minDate={data?.[val?.state_name]?.["startDate"]}
                  maxDate={val?.maxDate}
                  label={val?.label?.endLabel}
                  placeholder={val?.placeholder?.endLabel}
                  value={data?.[val?.state_name]?.["endDate"]}
                  onChange={(value) =>
                    onChangeState(
                      val?.state_name,
                      {
                        ...data?.[val?.state_name],
                        endDate: value,
                      },
                      val
                    )
                  }
                  border={val?.border}
                  is_popover={val?.is_popover}
                />
              </Grid>
            </Grid>
          </>
        );
        case "Slider":
          return (
            <>
              <Typography
                variant="body1"
                sx={{ marginBottom: "8px", fontWeight: "500" }}
              >
                {val?.label}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 7,
                }}
              >
                {/* Range Slider Component with Range 0 - 50,000 */}
                <Slider
                  size="large"
                  value={data?.[val?.state_name]?.[1] || [0, 10000]} // Default range [1000, 10000]
                  onChange={(event, newValue) => {
                    onChangeState(
                      val?.state_name,
                      [0, newValue, data?.[val?.state_name]?.[2] || {label: "Sq.Feet", value: "3"}],
                      val
                    );
                  }}
                  valueLabelDisplay="auto"
                  step={500} // Step of 500 for better control
                  min={0}
                  max={50000} // Maximum value 50,000
                  sx={{ width: "65%" }}
                  disableSwap // Prevents crossing of values
                  marks={[
                    { value: 0, label: '0' },
                    { value: 25000, label: '25000' },
                    { value: 50000, label: '50000+' },
                  ]}
                />
        
                {/* Dropdown for selecting Unit */}
                <CustomSelect
                  options={val?.options}
                  value={data?.[val?.state_name]?.[2] || {label: "Sq.Feet", value: "3"}} // Default to first option
                  onChange={(newUnit) => {
                    onChangeState(
                      val?.state_name,
                      [0, data?.[val?.state_name]?.[1] || [0, 10000], newUnit],
                      val
                    );
                  }}
                />
              </Box>
              {/* Display the selected range values */}
              <Box sx={{ display: "flex", justifyContent: "space-between", width: "70%", mt: 1 }}>
                <Typography variant="caption">
                  {`Min: ${data?.[val?.state_name]?.[1]?.[0] || 0} `}
                </Typography>
                <Typography variant="caption">
                  {`Max: ${data?.[val?.state_name]?.[1]?.[1] || 10000} `}
                </Typography>
              </Box>
            </>
          );

      default:
        return null;
    }
  };

  return (
    <Div>
      {
        <Grid container>
          {components?.length &&
            components.map((_) => {
              return (
                <>
                  <Grid item xs={12}>
                    {switchComponents(_)}
                  </Grid>
                  {_?.is_divider ? "" : <CustomDivider />}
                </>
              );
            })}
        </Grid>
      }
    </Div>
  );
};
