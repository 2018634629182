import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    templateTitle: {
        color: '#091B29',
        fontFamily: FontFamilySwitch().bold,
        fontSize:"0.875rem"
    },
    templateVariable: {
        color: `#CED3DD !important`,
        fontFamily: FontFamilySwitch().bold,
        fontSize:"0.875rem"
    },
    root:{
        backgroundColor:'#FFF4EB',
        padding:'15px',
        borderRadius:'4px',
        display:'flex',
        alignItems:"center",
        justifyContent:"space-between",
        cursor:"pointer"
    },
    select:{
        padding:'15px',
        borderBottom:`1px solid #CED3DD`,
        display:'flex',
        alignItems:"center",
        justifyContent:"space-between",
        cursor:"pointer"
    },
    select1:{
        padding:'15px',
        display:'flex',
        alignItems:"center",
        justifyContent:"space-between",
        cursor:"pointer"
    },
    selectRoot:{
        border:`1px solid #CED3DD`,
        borderRadius:'4px',
        boxShadow:'0px 0px 16px #00000014' ,
        position:"absolute",
        width:"100%", 
        zIndex:999,
        backgroundColor:"white"
    },
    selectRoot1:{
        border:`1px solid #CED3DD`,
        borderRadius:'4px',
        boxShadow:'0px 0px 16px #00000014',
        backgroundColor:"white" 
    },
    

}));