import { Box, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { SearchFilter, TableWithPagination, UseDebounce } from "../../../components";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods } from "../../../utils";
import { getHistoryHeading, historydataType, historyPath } from "../../../utils/receipt";
import { ReceiptStyles } from "./styles";

const HistoryTag = ({ t, id = "" }) => {
    const classes = ReceiptStyles();
    const historyHeading = getHistoryHeading(t);
    const debounce = UseDebounce();
    const [searchText, setSearchText] = React.useState("");
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [list, setList] = React.useState({
        list: [],
        count: 0
    })

    //get quoation list
    const getHistoryList = (offset = 0, limit = 10, searchText = "") => {

        const payload = {
            receipt_id: id,
            search: searchText,
            offset: offset,
            limit: limit,

        }
        NetworkCall(
            `${config.api_url}/settlement/history`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setList({
                    list: response?.data?.data?.map((val) => {
                        return {
                            ...val,
                            status: val?.status === "no-due" ? "Paid" : "Unpaid",
                            datetime: val?.settlement_date,
                            settlement_no: val?.settlement_no ?? "-"
                        }
                    }),
                    count: response?.data?.count
                })
            }).catch((err) => {
                console.log(err)
            })
    }
    //initial commit
    React.useEffect(() => {
        getHistoryList(0, 10, "")
        // eslint-disable-next-line 
    }, [])
    //handlesearch
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getHistoryList(0, 10, e)
    }
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getHistoryList(offset, limit, "")
    }
    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getHistoryList(0, value, "")
    }
    return (
        <Box className={classes.historyRoot} p={2}>
            <Grid container>
                <Grid item xs={4}>
                    <SearchFilter handleChange={handleSearch} value={searchText} placeholder={t("search")} />
                </Grid>
                <Grid item xs={12}>
                    <TableWithPagination
                        heading={historyHeading}
                        rows={list?.list}
                        path={historyPath}
                        showpdfbtn={false}
                        showexcelbtn={false}
                        showSearch={false}
                        tableType="no-side"
                        showpagination={true}
                        dataType={historydataType}
                        handleChangeLimit={handleChangeLimit}
                        handlePagination={handlePagination}
                        page={page}
                        totalRowsCount={list?.count}
                        limit={limit}
                        height={`calc(100vh - 300px)`}
                        view={true}
                        edit={true}
                        delete={true} />
                </Grid>
            </Grid>

        </Box>
    )
}
export default withTranslation("receipt")(HistoryTag); 