import dotenv from "dotenv";
dotenv.config();

let config = {};
config.api_url = `${process.env.REACT_APP_BACKEND_API}`;
config.public_listing_api_url = `${process.env.REACT_APP_PUBLIC_LISTING_API}`;
config.public_listing_api_key = `${process.env.REACT_APP_PUBLIC_LISTING_API_KEY}`;
config.socket = `${process.env.REACT_APP_SOCKET}`;
config.slug = `${process.env.REACT_APP_SLUG}`;
config.tenantId = `${process.env.REACT_APP_TENANT_ID}`;
config.alertKey = `${process.env.REACT_APP_ALERT_KEY}`;
config.googleMapApiKey = `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`;
config.weather = `${process.env.REACT_APP_WEATHER_API}`;
config.weatherId = `${process.env.REACT_APP_WEATHER_API_KEY}`;
config.authapi = `${process.env.REACT_APP_AUTH_API}`;
config.ksa_url = `${process.env.REACT_APP_KSA_API}`;
config.integration_uri = `${process.env.REACT_APP_INTEGRATION_REDIRECT_URI}`;
// config.websocket = ``;
config.app_x_build = `${process.env.REACT_APP_X_BUILD}`;
config.app_env = `${process.env.REACT_APP_ENV}`;

// app intall urls
config.playstore = `${process.env.REACT_APP_PLAYSTORE}`;
config.appstore = `${process.env.REACT_APP_APPSTORE}`;

// mobile apps urls
config.resident_url = `${process.env.REACT_APP_RESIDENT}`;
config.tenant_url = `${process.env.REACT_APP_TENANT}`;
config.member_url = `${process.env.REACT_APP_MEMBER}`;
config.workspace_url = `${process.env.REACT_APP_WORKSPACE}`;
config.employee_url = `${process.env.REACT_APP_EMPLOYEE}`;
config.owner_url = `${process.env.REACT_APP_OWNER}`;
config.security_guard_url = `${process.env.REACT_APP_SECURITY_GUARD}`;
config.inspector_url = `${process.env.REACT_APP_INSPECTOR}`;
config.worker_url = `${process.env.REACT_APP_WORKER}`;
config.guest_url = `${process.env.REACT_APP_GUEST}`;
config.wallet_url = `${process.env.REACT_APP_WALLET}`;
config.agent_url = `${process.env.REACT_APP_AGENT}`;

// web portal urls
config.customer_portal = `${process.env.REACT_APP_CUSTOMER_PORTAL}`;
config.vendor_portal = `${process.env.REACT_APP_VENDOR_PORTAL}`;
config.agent_portal = `${process.env.REACT_APP_AGENT_PORTAL}`;
config.resource_portal = `${process.env.REACT_APP_RESOURCE_PORTAL}`;
config.realty_goto = `${process.env.REACT_APP_REALTY_GOTO}`;
config.amenities_goto = `${process.env.REACT_APP_AMENITIES_GOTO}`;
config.stay_goto = `${process.env.REACT_APP_STAY_GOTO}`;
config.listing_goto = `${process.env.REACT_APP_LISTING_GOTO}`;

config.support_team_email = `${process.env.REACT_APP_SUPPORT_SUPPORT_TEAM_EMAIL}`;
config.support_live_support = `${process.env.REACT_APP_SUPPORT_LIVE_SUPPORT}`;
config.support_view_help_faq = `${process.env.REACT_APP_SUPPORT_VIEW_HELP_FAQ}`;
config.support_blogs = `${process.env.REACT_APP_SUPPORT_BLOGS}`;
config.support_site_terms = `${process.env.REACT_APP_SUPPORT_SITE_TERMS}`;
config.support_privacy_policy = `${process.env.REACT_APP_SUPPORT_PRIVACY_POLICY}`;
config.support_about_propgoto = `${process.env.REACT_APP_SUPPORT_ABOUT_PROPGOTO}`;

config.microsoft_account_auth_url = `${process.env.REACT_APP_MICROSOFT_ACCOUNT_AUTH_URL}`;
config.microsoft_account_client_id = `${process.env.REACT_APP_MICROSOFT_ACCOUNT_CLIENT_ID}`;
config.microsoft_account_redirect_uri = `${process.env.REACT_APP_MICROSOFT_ACCOUNT_REDIRECT_URI}`;

config.google_account_auth_url = `${process.env.REACT_APP_GOOGLE_ACCOUNT_AUTH_URL}`;
config.google_account_client_id = `${process.env.REACT_APP_GOOGLE_ACCOUNT_CLIENT_ID}`;
config.google_account_redirect_uri = `${process.env.REACT_APP_GOOGLE_ACCOUNT_REDIRECT_URI}`;

// payment gateway
config.payment_gateway_url = `${process.env.REACT_APP_PAYMENT_GATEWAY}`;
config.payment_api_key = `${process.env.REACT_APP_PAYMENT_GATEWAY_API_KEY}`;

export { config };
