/* eslint-disable react/jsx-no-target-blank */
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  Avatar,
  Checkbox,
  IconButton,
  MenuItem,
  Select,
  TableRow,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { convertTimeUtcToZoneCalander, FontFamilySwitch } from "../../../utils";
import EditIMG from "../assests/edit";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.typography.color.secondary,
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold, 
    border: 0,
    backgroundColor: "#F2F4F7",
    padding: "8px",
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.typography.color.primary,
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().semiBold, 
    // border: 0,
    whiteSpace: "normal",
    wordWrap: "break-word",
    padding: "8px",
  },
}));

const useStyles = makeStyles((theme) => ({
  arrow: {
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    fontSize:"1rem",
    color: "black",
  },
  button: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    margin: 10,
  },
  paginate: {
    "& .MuiButtonBase-root.MuiPaginationItem-root": {
      borderRadius: "4px",
      border: "none",
      color: "#606060",
    },
    "& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected": {
      color: "white",
      borderRadius: "4px",
      border: "none",
      backgroundColor: "#5078E1",
    },
  },
  pagination: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  typo: {
    fontSize:"0.75rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().bold, 
    fontWeight: "bold",
    margin: 0,
    overflow: "hidden",
  },
  tabletopbtn: {
    fontWeight: "lighter",
    boxShadow: "none",
    marginLeft: "6px",
    backgroundColor: "#F8F8F8",
    border: "1px solid #E9E9E9",
    borderRadius: "6px",
    color: "black",

    "&:nth-child(1)": {
      textTransform: "capitalize",
    },
    "&:hover": {
      backgroundColor: "#F8F8F8",
      border: "1px solid #E9E9E9",
      borderRadius: "6px",
      boxShadow: "none",
    },
  },
  root: {
    whiteSpace: "noWrap",
    "& .MuiTableContainer-root": {
      "&::-webkit-scrollbar": {
        height: 6,
      },
      "& table": {
        borderCollapse: "separate",
      },
    },
  },
  flexRow: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  change: {
    textDecoration: "underline",
    color: "#4D7EFF",
    cursor: "pointer",
    marginLeft: 70,
  },
  thead: {
    backgroundColor: "#F2F4F7",
    "& th:first-child": {
      borderRadius: (props) =>
        props.tableType === "normal" ? "4px 0px 0px 4px" : "4px 0 0 0",
    },
    "& th:last-child": {
      borderRadius: (props) =>
        props.tableType === "normal" ? "0 4px 4px 0" : "0 4px 0 0",
    },
  },
  tbody: (props) => ({
    height: "48px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F1F7FF",
    }, // border: (props.tableType === "normal" ? 0 : "1px solid #E4E8EE"),
    backgroundColor: props.tableType === "normal" ? "#F2F4F7" : "white",
    "& th": {
      borderBlock: props.tableType === "normal" ? 0 : "1px solid #E4E8EE",
    },
    "& th:first-child": {
      borderRadius: props.tableType === "normal" ? "4px 0px 0px 4px" : 0,
      borderLeft:
        props.tableType === "normal"
          ? 0
          : props?.tableType === "no-side"
            ? 0
            : "1px solid #E4E8EE",
    },
    "& th:last-child": {
      borderRadius: props.tableType === "normal" ? "0 4px 4px 0" : 0,
      borderRight:
        props.tableType === "normal"
          ? 0
          : props?.tableType === "no-side"
            ? 0
            : "1px solid #E4E8EE",
    },
  }),
  select: {
    color: "red",
    "& .MuiSelect-select": {
      paddingBlock: 0,
      fontSize:"0.75rem",
      color: "#4E5A6B",
      fontFamily: FontFamilySwitch().bold, 
      fontWeight: "bold",
      height: 20,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
  },
  noRecordDiv: {
    textAlign: "center",
    marginTop: 30,
  },
  menu: {
    "& .MuiPopover-paper": {
      boxShadow: "0 0px 8px -4px #f2f3f5",
      border: "1px solid #f2f3f5",
      borderRadius: "5px",
    },
  },
  active: {
    fontFamily: FontFamilySwitch().semiBold, 
    fontSize:"0.875rem",
    color: "#5AC782",
  },
  inprogress: {
    padding: "4px 6px",
    backgroundColor: "#78B1FE",
    borderRadius: "4px",
    display: "inline",
    color: "white",
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().bold, 
  },

  activego: {
    padding: "4px 6px",
    backgroundColor: "#5AC782",
    borderRadius: "4px",
    display: "inline",
    color: "white",
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().bold, 
  },
  inactivego: {
    padding: "4px 6px",
    backgroundColor: "#CED3DD",
    borderRadius: "4px",
    display: "inline",
    color: "white",
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().bold, 
  },
  inactive: {
    fontFamily: FontFamilySwitch().semiBold, 
    fontSize:"0.875rem",
    color: "red",
  },
  more: {
    fontSize: "1.25rem",
    color: "gray",
    cursor: "pointer",
  },
  menuList: {
    "& .MuiPopover-paper": {
      boxShadow: "0px 0px 6px #0717411F",
      borderRadius: "4px",
      backgroundColor: "white",
    },
  },
  menuItem: {
    borderBottom: "1px solid #E4E8EE",
    margin: "0px 4px",
    fontSize:"0.875rem",
    color: "#071741",
    fontFamily: FontFamilySwitch().semiBold, 
    "&:last-child": {
      border: 0,
    },
  },
  text: {
    fontFamily: FontFamilySwitch().semiBold, 
    fontSize:"0.875rem",
    color: "#091B29",
    "& .ql-editor": {
      padding: "0px 0px 16px 0px",
      color: theme.typography.color.primary,
      fontSize:"0.875rem",
      fontFamily: FontFamilySwitch().semiBold, 
      textTransform: "capitalize",
    },
  },
  tooltip: {
    "&:hover": {
      backgroundColor: theme.typography.color.white,
    },
  },
  qstatus: {
    fontFamily: FontFamilySwitch().semiBold, 
    fontSize:"0.875rem",
    color: "#5AC782",
    textTransform: "capitalize",
  },
  infoIcon: {
    color: "#4E5A6B",
    fontSize: "1.25rem",
  },
  tabelHeadButton: {
    color: "#5078E1",
    cursor: "pointer",
    fontWeight: 600,
    fontSize:"0.75rem",
  },
  green: {
    width: "110px",
    borderRadius: theme.palette.borderRadius,
    color: "white",
    backgroundColor: "#5AC782",
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().regular,
    textAlign: "center",
    padding: "4px",
  },
  resend_button: {
    width: "110px",
    borderRadius: theme.palette.borderRadius,
    color: "white",
    backgroundColor: "#5078E1",
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().regular,
    textAlign: "center",
    padding: "4px",
  }
}));

export const TableWithPagination = (props) => {
  const classes = useStyles(props);
  const totalCount = props?.totalRowsCount > -1 ? props?.totalRowsCount : "";
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [index, setIndex] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const getComponentType = (val, data) => {
    const main = data.main;

    switch (data.type) {
      case "status": {
        if (data.value === "Approved") {
          return <p style={{ color: "#267C24" }}>{data.value}</p>;
        } else if (data.value === "Rejected") {
          return <p style={{ color: "red" }}>{data.value}</p>;
        } else if (data.value === "Pending") {
          return <p style={{ color: "#78B1FE" }}>{data.value}</p>;
        } else if (data.value === "Lost Lead") {
          return <p style={{ color: "red" }}>{data.value}</p>;
        } else if (data.value === "Lost Lead") {
          return <p style={{ color: "red" }}>{data.value}</p>;
        } else if (data.value === "In Progress") {
          return <p style={{ color: "#5AC782" }}>{data.value}</p>;
        } else if (data.value === "Converted") {
          return <p style={{ color: "#78B1FE" }}>{data.value}</p>;
        } else if (data.value === "Cancelled") {
          return <p style={{ color: "#98A0AC" }}>{data.value}</p>;
        } else if (data.value === "Quote Accepted") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#5AC782",
                fontSize:"0.75rem",
                fontFamily: FontFamilySwitch().bold,
                textAlign: "center",
                borderRadius: "4px",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "open") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#5AC782",
                fontSize:"0.75rem",
                fontFamily: FontFamilySwitch().bold,
                textAlign: "center",
                borderRadius: "4px",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "close" || data?.value === "closed") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#CED3DD",
                fontSize:"0.75rem",
                fontFamily: FontFamilySwitch().bold,
                textAlign: "center",
                borderRadius: "4px",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Closed") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "red",
                fontSize:"0.75rem",
                fontFamily: FontFamilySwitch().bold,
                textAlign: "center",
                borderRadius: "4px",
                padding: "4px",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data?.value === "Open") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#5AC782",
                fontSize:"0.75rem",
                fontFamily: FontFamilySwitch().bold,
                textAlign: "center",
                borderRadius: "4px",
                padding: "4px",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "requested for visit") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#FF9340",
                fontSize:"0.75rem",
                fontFamily: FontFamilySwitch().bold,
                textAlign: "center",
                borderRadius: "4px",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "requested for re-quote accepted") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#FF9340",
                fontSize:"0.75rem",
                fontFamily: FontFamilySwitch().bold,
                textAlign: "center",
                borderRadius: "4px",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "inProgress") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#78B1FE",
                fontSize:"0.75rem",
                fontFamily: FontFamilySwitch().bold,
                textAlign: "center",
                borderRadius: "4px",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "In Progress") {
          return (
            <Typography
              sx={{
                color: "white",
                backgroundColor: "#78B1FE",
                fontSize:"0.75rem",
                fontFamily: FontFamilySwitch().bold,
                textAlign: "center",
                borderRadius: "4px",
              }}
            >
              {data.value}
            </Typography>
          );
        } else if (data.value === "Active") {
          return (
            <Typography className={classes.active}>{data.value}</Typography>
          );
        } else if (data.value === "Vacant") {
          return (
            <Typography className={classes.active}>{data.value}</Typography>
          );
        } else if (data.value === "Occupied") {
          return (
            <Typography className={classes.inactive}>{data.value}</Typography>
          );
        } else if (data.value === "Inactive") {
          return (
            <Typography className={classes.inactive}>{data.value}</Typography>
          );
        } else if (data.value === "delete") {
          return (
            <div>
              <IconButton onClick={() => props.handleIcon("delete", main)}>
                <DeleteForeverIcon color="primary" />
              </IconButton>
            </div>
          );
        } else if (data.value === true) {
          return <Typography className={classes.active}>Active</Typography>;
        } else if (data.value === false) {
          return <Typography className={classes.inactive}>Inactive</Typography>;
        } else {
          return data.value;
        }
      }
      case "date": {
        return (
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
            className={classes.text}
          >
            {data?.value
              ? convertTimeUtcToZoneCalander(data?.value)
              : "-"}
          </Typography>
        );
      }
      case "date_time": {
        return (
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
            className={classes.text}
          >
            &#x202a;{data.value
              ? convertTimeUtcToZoneCalander(data.value, 'YYYY-MM-DD hh:mm a')
              : "-"}&#x202c;
          </Typography>
        );
      }
      case "icon": {
        let editviewtoggle = (
          <div style={{ display: "flex" }}>
            <IconButton onClick={() => props.handleIcon("edit", main)}>
              <EditIMG color="#98a0ac" />
            </IconButton>
            <IconButton onClick={() => props.handleIcon("view", main)}>
              <RemoveRedEyeOutlinedIcon
                sx={{ fontSize:"1rem", color: "#98A0AC" }}
              />
            </IconButton>

            <Switch
              checked={data.is_active}
              onChange={(e) =>
                props.handleIcon("toggle", main, e.target.checked)
              }
            />
          </div>
        );
        let editview = (
          <div style={{ display: "flex" }}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                props.handleIcon("edit", main);
              }}
            >
              <EditIMG color="#98a0ac" />
            </IconButton>
            <IconButton onClick={() => props.handleIcon("view", main)}>
              <RemoveRedEyeOutlinedIcon
                sx={{ fontSize:"1rem", color: "#98A0AC" }}
              />
            </IconButton>
          </div>
        );
        let deleteIcon = (
          <div style={{ display: "flex" }}>
            <IconButton onClick={() => props.handleIcon("delete", main)}>
              <DeleteOutlineIcon sx={{ fontSize:"1rem", color: "#98A0AC" }} />
            </IconButton>
          </div>
        );
        let viewdelete = (
          <div style={{ display: "flex" }}>
            <IconButton onClick={() => props.handleIcon("edit", main)}>
              <EditIMG color="#98a0ac" />
            </IconButton>
            <IconButton onClick={() => props.handleIcon("delete", main)}>
              <DeleteOutlineIcon sx={{ fontSize:"1rem", color: "#98A0AC" }} />
            </IconButton>
          </div>
        );
        let edittoggle = (
          <div style={{ display: "flex" }}>
            <IconButton
              onClick={() => props.handleIcon("edit", main, props?.edit)}
            >
              <EditIMG color="#98a0ac" />
            </IconButton>
            <Switch
              checked={main.is_active}
              onChange={(e) => props.handleIcon("toggle", main, props?.edit)}
            />
          </div>
        );
        let viewedittoggle = (
          <div style={{ display: "flex" }}>
            <IconButton
              onClick={() => props.handleIcon("edit", main, props?.edit)}
            >
              <EditIMG color="#98a0ac" />
            </IconButton>
            <IconButton
              onClick={() => props.handleIcon("view", main, props?.view)}
            >
              <RemoveRedEyeOutlinedIcon
                sx={{ fontSize:"1rem", color: "#98A0AC" }}
              />
            </IconButton>
            <Switch
              checked={main.is_active}
              onChange={(e) => props.handleIcon("toggle", main, props?.edit)}
            />
          </div>
        );
        if (data.icon === "editviewtoggle") {
          return editviewtoggle;
        }
        if (data.icon === "deleteIcon") {
          return deleteIcon;
        }
        if (data.icon === "viewedittoggle") {
          return viewedittoggle;
        }
        if (data.icon === "edittoggle") {
          return edittoggle;
        }
        if (data.icon === "viewdelete") {
          return viewdelete;
        }
        if (data.icon === "editview") {
          return editview;
        } else if (data.icon === "edit") {
          return (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                props.handleIcon("edit", main, props?.edit);
              }}
            >
              <EditIMG color="#98a0ac" />
            </IconButton>
          );
        } else if (data.icon === "view") {
          return (
            <IconButton onClick={() => props.handleIcon("view", main)}>
              <RemoveRedEyeOutlinedIcon
                sx={{ fontSize:"1rem", color: "#98A0AC" }}
              />
            </IconButton>
          );
        } else if (data.icon === "arrow") {
          return (
            <div style={{ display: "flex" }}>
              <IconButton size="small" className={classes.arrow}>
                <ArrowForwardIosIcon
                  size="small"
                  style={{ fontSize:"1rem" }}
                />
              </IconButton>
            </div>
          );
        }

        break;
      }
      case "checkBox": {
        return (
          <Checkbox
            onChange={() => props.handleIcon("checkBox", main)}
            color="success"
            checked={data.value}
            checkedIcon={<CheckCircleIcon />}
            icon={<CircleOutlinedIcon style={{ color: "#c1c1c1" }} />}
          />
        );
      }
      case "avatar": {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={data.image} sx={{ width: 34, height: 34 }} />
            <Typography style={{ marginLeft: 10 }}>{data.value}</Typography>
          </div>
        );
      }
      case "avatarmanagement": {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={data.value} sx={{ width: 34, height: 34 }} />
          </div>
        );
      }
      case "text": {
        return (
          <Typography
            style={{
              whiteSpace: "nowrap",
              direction: 'ltr'
            }}
            className={classes.text}
          >
            {data.value}
          </Typography>
        );
      }
      case "textLink": {
        return (
          <div
            // onClick={() => props?.onUnitClick(main, data)}
            style={{ textDecorationLine: "underline", color: "blue" }}
          >
            {data.value}
          </div>
        );
      }
      case "increment": {
        return data.index + 1;
      }
      case "image": {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <img src={data.image} alt={data.name}></img>
            <Typography style={{ marginLeft: 10 }}>{data.value}</Typography>
            {/* <Typography className={classes.change}>Change</Typography> */}
          </div>
        );
      }
      case "logo": {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src={data?.value?.logo}
              sx={{ width: 34, height: 34, lineHeight: 0, fontSize:"0.875rem" }}
            >
              {data?.value?.name ? data?.value?.name?.charAt(0) : ""}
            </Avatar>
          </div>
        );
      }
      case "link": {
        return (
          <Typography
            style={{ textDecoration: "underline", color: "#5078E1" }}
            onClick={() => props.handleLink(data)}
          >
            {main.url}
          </Typography>
        );
      }

      case "description": {
        return (
          <div>
            <Typography
              style={{
                wordBreak: "break-all",
                overflowWrap: "anywhere",
              }}
              className={classes.text}
            >
              {data?.value}
            </Typography>
          </div>
        );
      }
      case "redirect": {
        return (
          <a target="_blank" href={data.value}>
            {data.value}
          </a>
        );
      }
      case "object": {
        return data.value[data?.objectOption] ?? "-";
      }
      case "phone": {
        return (
          <>
            {main.code} {main.mobile}
          </>
        );
      }
      case "more": {
        return (
          <div>
            <IconButton
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    props.handleIcon("edit", main);
                  }}
                  className={classes.menuItem}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    props.handleIcon("active", main, main.is_active);
                  }}
                  className={classes.menuItem}
                >
                  {main.is_active ? "In-active" : "Active"}
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleClose();
                    props.handleIcon("delete", main, main.delete);
                  }}
                  className={classes.menuItem}
                >
                  Delete
                </MenuItem>
              </Menu>
            )}
          </div>
        );
      }

      case "more1": {
        return (
          <div>
            <IconButton
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    props.handleIcon("edit", main);
                  }}
                  className={classes.menuItem}
                >
                  Edit
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleClose();
                    props.handleIcon("active", main, main.is_active);
                  }}
                  className={classes.menuItem}
                >
                  {main.is_active ? "In-active" : "Active"}
                </MenuItem>
              </Menu>
            )}
          </div>
        );
      }
      case "more_2": {
        return (
          <div>
            <IconButton
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setIndex(data.index);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>

            {index === data?.index && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menuList}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    props.handleIcon("edit", main);
                  }}
                  className={classes.menuItem}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    props.handleIcon("delete", main);
                  }}
                  className={classes.menuItem}
                >
                  Delete
                </MenuItem>
              </Menu>
            )}
          </div>
        );
      }
      case "resend_button": {
        if (data.value === "Active") {
          return <Typography className={classes.green}>{data.value}</Typography>
        } else {
          return <Typography className={classes.resend_button}
            onClick={() => props?.handleIcon("resend_button", main)}>
            {data.value}
          </Typography>
          // <Button variant="contained" className={classes.resend_button}
          //   onClick={() => props?.handleIcon("resend_button", main)}>
          //   {data.value}
          // </Button>
        }
      }

      default: {
        return data.value;
      }
    }
  };

  const returnPageNumber = () => {
    //check if props.rows have a index key
    const checkIndexKeyInType = props?.rows?.some((_) => _?.index);

    if (props?.rows?.length && checkIndexKeyInType) {
      if (props?.rows?.length === 1) {
        return `${props?.rows?.[0]?.index} of ${totalCount}`;
      } else {
        let lastCountIndex = props?.rows?.length - 1;
        return `${props?.rows?.[0]?.index} - ${props?.rows?.[lastCountIndex]?.index} of ${totalCount}`;
      }
    }
    return "";
  };

  const addRoleNew = (e) => {
    props?.addrolePopFunction(e);
  };

  return (
    <div className={classes.root}>
      <TableContainer
        sx={{
          //zIndex: -1,
          minHeight: props?.height && props?.height,
          maxHeight: props?.height && props?.height,
          mt: 2,
        }}
      >
        {/* <br /> */}
        {/* Table Component */}
        <Table stickyHeader aria-label="customized table">
          {/* heading */}
          <TableHead className={classes.thead}>
            {props?.heading?.length > 0 && (
              <TableRow>
                {props?.heading?.map((data, index) => {
                  return (
                    <StyledTableCell size="small" key={index}>
                      {data.title}{" "}
                      {data?.button && (
                        <div
                          className={classes.tabelHeadButton}
                          onClick={(e) => {
                            e.stopPropagation();
                            addRoleNew(e);
                          }}
                        >
                          {data?.button}
                        </div>
                      )}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            )}
          </TableHead>
          {/* rows */}
          {props?.tableType === "normal" && <Box height={"8px"} />}
          <TableBody>
            {props?.rows?.length > 0 && (
              <>
                {props?.rows?.map((row, index) => {
                  return (
                    <>
                      <TableRow
                        key={index + 1}
                        className={classes.tbody}
                        onClick={() => props?.onClick && props?.onClick(row)}
                      >
                        {props?.dataType.map((val) => {
                          return (
                            <StyledTableCell
                              component="th"
                              scope="row"
                              onClick={() => {
                                if (
                                  val.type[0] === "more" ||
                                  val.type[0] === "more_2" ||
                                  val.type[0] === "unit" ||
                                  val.type[0] === "block" ||
                                  val.type[0] === "textLink" ||
                                  val.type[0] === "floor" ||
                                  val.type[0] === "more_3" ||
                                  val?.type[0] === "info" ||
                                  val.type[0] === "more_4" ||
                                  val.type[0] === "resend_button"
                                ) {
                                } else {
                                  props.handleIcon &&
                                    props.handleIcon("view", row);
                                }
                              }}
                            >
                              {val.type.map((type) => {
                                return getComponentType(row, {
                                  index: index,
                                  type: type,
                                  main: row,
                                  value: row[val.name],
                                  image: row[val.imagekey],
                                  icon: row[val.icon],
                                  is_active: row[val.is_active],
                                  objectOption: val?.label ?? "label",
                                });
                              })}
                            </StyledTableCell>
                          );
                        })}
                      </TableRow>
                      {props.tableType === "normal" && <Box height={"8px"} />}
                    </>
                  );
                })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination Section */}
      {props?.showpagination && (
        <>
          <div className={classes.pagination}>
            {/* <p className={classes.typo}>
              pages {1}/{Math.ceil(props.rows.length / pageSize)}
            </p> */}
            <div className={classes.flexRow}>
              <p className={classes.typo}>Rows per page</p>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props?.limit}
                onChange={(e) => props?.handleChangeLimit(e.target.value)}
                // classes={{
                //   root:classes.select
                // }}
                className={classes.select}
                MenuProps={{
                  disableScrollLock: true,
                }}
              >
                {/* <MenuItem value={2}>2</MenuItem> */}
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </div>
            <div className={classes.flexRow}>
              {props?.rows?.length > 0 && (
                <p className={classes.typo}>{returnPageNumber()}</p>
              )}
              <Pagination
                className={classes.paginate}
                shape="rounded"
                count={Math.ceil(totalCount / props?.limit)}
                page={props?.page}
                // onChange={handleChange}
                onChange={(e, value) => props?.handlePagination(value)}
                color="primary"
              // showFirstButton
              // showLastButton
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

TableWithPagination.defaultProps = {
  handleIcon: () => { },
};
