import { Box, Button } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { FormGenerator } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../../utils";

const Form = ({ company_id = "", data = {}, setData = () => false, reload = () => false, onClose = () => false, t, requestTypeOptions = [], StatusOptionList = [] }) => {

    const alert = React.useContext(AlertContext);
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)

    //update state of master
    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        setData({ ...data, [key]: value, error });
    };
    //validate form
    const validateForm = () => {
        let isValid = true;
        let error = data.error;
        if (data?.type?.length === 0) {
            isValid = false;
            error.type = `${t("inspectionType")} ${t("isRequired")}`;
        }
        if (data?.title?.length === 0) {
            isValid = false;
            error.title = `${t("categoryName")} ${t("isRequired")}`;
        }
        setData({ ...data, error });
        return isValid;
    };
    //on submit
    const onSubmit = () => {
        if (validateForm()) {
            setIsDisableBtn(true)
            const payload = {
                "data": {
                    "name": data?.title,
                    "is_active": data?.status,
                    "inspection_item_type": data?.type?.value,
                    "id": data?.id ?? undefined

                }
            }
            NetworkCall(
                `${config.api_url}/inspection_feedback_category/upsert`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((res) => {
                reload()
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: `${t("CheckList Master Successfully")} ${data?.isEdit ? t("Updated") : t("Created")}`,
                });
                onClose()
            }).catch((error) => {
                setIsDisableBtn(false)
                if (error.response) {
                    // Request made and server responded
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: Object.keys(error.response.data.error.message).length !== 0 ? error.response.data.error.message : "Something went wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });

                }
                else {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: `${t("Cannot Be ")}${data?.isEdit ? t("Edited") : t("Added")}`,
                    });
                }
            })
        } else {
            return false
        }
    }
    //form data
    const changeInput = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "select",
            label: t("inspectionItemType"),
            placeholder: t("selectInspectionItemType"),
            value: data?.type,
            onChange: (val) => updateState("type", val),
            error: data?.error?.type,
            isRequired: true,
            options: requestTypeOptions,
            debounceTimeout: 800,
            isPaginate: true,
            menuPlacement: "bottom"
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "text",
            label: t("categoryName"),
            placeholder: t("enterCategoryName"),
            value: data?.title,
            onChange: (value) => updateState("title", value.target.value),
            error: data?.error?.title,
            isRequired: true,
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "toggle",
            label: t("status"),
            placeholder: t("status"),
            value: data?.status,
            onChange: (value) => updateState("status", value),
            error: data?.error?.status,
            isRequired: true,
            options: StatusOptionList
        },
    ]


    return (

        <Box p={2} >
            {/*form generator */}
            <Box>
                <FormGenerator t={t} components={changeInput} />
            </Box>
            <Box mt={2}>
                <Button sx={{ height: "45px" }} variant="contained" fullWidth onClick={onSubmit} disabled={isDisableBtn}>{data?.isEdit ? t("Update") : t("create")}</Button>

            </Box>
        </Box >
    )
}
export default withTranslation("inspectionItemmaster")(Form); 