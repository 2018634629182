import { withTranslation } from "react-i18next";
import { TableWithPagination } from "../../../components";
import { getItemConditionMasterHeading, ItemConditionDataType, ItemConditionPath } from "../../../utils";


const Table = ({
    handleIcon = () => false,
    list = [],
    handlePagination = () => false,
    handleChangeLimit = () => false,
    page = "",
    limit = "",
    t
}) => {

    const heading = getItemConditionMasterHeading(t)

    return (

        <TableWithPagination
            heading={heading}
            rows={list?.list}
            path={ItemConditionPath}
            dataType={ItemConditionDataType}
            showpagination={true}
            count="2"
            showpdfbtn={false}
            showexcelbtn={false}
            showSearch={false}
            tableType="no-side"
            handleIcon={handleIcon}
            view={true}
            edit={true}
            delete={false}
            handlePagination={handlePagination}
            handleChangeLimit={handleChangeLimit}
            totalRowsCount={list?.count}
            page={page}
            limit={limit}
            height={`calc(100vh - 300px)`}
        />
    )
}
export default withTranslation("inspectionItemmaster")(Table); 