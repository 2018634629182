import makeStyles from "@mui/styles/makeStyles";
import { remCalc, FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px 16px 80px",
    backgroundColor: theme?.palette?.background?.secondary,
    height: `calc(100vh - 64px)`,
    overflow: "auto",
    [theme?.breakpoints.down("md")]: {
      padding: "10px 10px 80px",
    },
  },
  widget_section: {
    padding: "16px",
    backgroundColor: theme?.palette?.background?.tertiary1,
  },
  widget_header: {
    color: theme?.typography?.color?.primary,
    fontSize: remCalc(16),
    fontFamily: FontFamilySwitch().extraBold,
  },
  widget_internal_card_1: {
    backgroundColor: "#f5f5db",
    display: "flex",
    justifyContent: "center",
    border: "1px solid #E4E8EE",
    borderRadius: theme.palette.borderRadius,
    padding: "12px",
    alignItems: "center"
  },
  widget_internal_card_1_text_1: {
    fontSize: remCalc(18),
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary
  },
  widget_internal_card_1_text_2: {
    fontSize: remCalc(14),
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.primary
  },
}));
