import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { LoadingSection, Subheader, UseDebounce } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { InspectionMappingDataType, InspectionMappingHeading, InspectionMappingPath, NetWorkCallMethods, accessCheckRender, getCompanyOption, getRoutePermissionNew } from "../../utils";
import InspectionMappingTable from "./components/table";
import { InspectionMappingStyles } from "./styles";
import { withTranslation } from "react-i18next";

const InspectionMapping = ({
    t
}) => {
    const classes = InspectionMappingStyles()
    const navigate = useNavigate();
    const debounce = UseDebounce();
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext);
    const [searchText, setSearchText] = React.useState("");
    const [compenyList, setCompanyList] = React.useState([]);
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const [loading, setLoading] = React.useState(true)
    const [permissions, setPermission] = React.useState({})
    const [itemList, setItemList] = React.useState({
        list: [],
        count: 0,
    })

    const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");
  const limitParam = searchParams.get("limit");

  // Default values if not set in URL
  const pageFromParams = pageParam ? parseInt(pageParam, 10) : 1;
  const limitFromParams = limitParam ? parseInt(limitParam, 10) : 10;

  const [page, setPage] = React.useState(pageFromParams);
  const [limit, setLimit] = React.useState(limitFromParams);

  const updatePage = (newPage, newLimit = limit) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", newPage.toString());
    searchParams.set("limit", newLimit.toString());

    // Update URL without reloading
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
    // Update state
    setPage(newPage);
    setLimit(newLimit);
  };
  //handle pagination
  const handlePagination = (newPage) => {
    updatePage(newPage, limit);
  };

  const handleChangeLimit = (newLimit) => {
    updatePage(1, newLimit); // Reset page to 1 when limit changes
  };

    //get list
    const getPropertyList = (company_id, searchText, offset, limits) => {
        setLoading(true)     
        const payload={
            company_id: company_id,
                search: searchText,
                offset: offset,
                limit: limits, 
        }
        NetworkCall(
            `${config.api_url}/queries/property/list`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
        .then((response) => {
            setItemList({
                list: response?.data?.data?.property?.map((val) => {
                    return { ...val, total_units: val?.unit?.[0]?.count_id }
                }),
                count: response.data.count
            })
            setLoading(false)

        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })


    }

    const currentOffset = (page - 1) * limit;
    //initial load
    React.useEffect(() => {
        let company = getCompanyOption(backdrop, auth, alert);
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (company && perm?.read) {
                setCompanyList(company?.list)
                setSelectedCompany(company?.selected)
                getPropertyList(company?.selected?.value, "", currentOffset, limitFromParams, {})
            }
        }
        // eslint-disable-next-line
    }, [auth])

    //on search
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getPropertyList(selectedCompany?.value, e, 0, limit)
    }

    //handle change
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        getPropertyList(value?.value, "", 0, 10, {})
    }


    //switch to next page
    const handleIcon = (data) => {
        navigate(Routes.mapPropertyDetails, {
            state: {
                company: selectedCompany,
                id: data?.id
            }
        })
    }


    const render = () => {
        return (
            <Box>
                <Subheader
                    hideBackButton={true}
                    title={t("Inspection Mapping")}
                    select
                    options={compenyList}
                    value={selectedCompany}
                    onchange={(e) => {
                        handleCompanyChange(e)
                    }}
                />
                {
                    loading ?
                        <LoadingSection top="20vh" message={"Fetching Details"} />
                        :
                        <Box className={`${classes.root}`}>
                            <InspectionMappingTable
                                t={t}
                                searchText={searchText}
                                placeholder={t("searchProperty")}
                                onClick={handleIcon}
                                heading={InspectionMappingHeading(t)}
                                path={InspectionMappingPath}
                                dataType={InspectionMappingDataType}
                                handleChangeLimit={handleChangeLimit}
                                handlePagination={handlePagination}
                                page={page}
                                list={itemList}
                                limit={limit}
                                handleSearch={handleSearch}
                                height={`calc(100vh - 300px)`}
                            />
                        </Box>
                }

            </Box>
        )
    }

    return (
        <Box>
            {accessCheckRender(render, permissions)}

        </Box>
    )
}

export default withTranslation("inspection")(InspectionMapping)
