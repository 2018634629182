import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup, Stack, Typography
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import React from "react";
import { components } from "react-select";

import { FontFamilySwitch } from "../../utils";
import { CustomTypography } from '../filterGenerator/customTypography';
import { Label } from '../label';
import { useStyles } from "./styles";
import { AsyncPaginate } from "react-select-async-paginate";


const Details = ({ mileStoneOptions = {}, props = "" , is_checked=false }) => {
    const classes = useStyles();
    return (
        <FormControl>
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={mileStoneOptions?.value ?? ""}
            >
                <FormControlLabel
                    value={
                        props?.selectProps?.mileStoneId?.payment_period_value ?? ""
                    }
                    control={<Radio size={"small"} checked={is_checked} />}
                    label={
                        mileStoneOptions?.label?.length > 0
                            ? <span className={classes.name}>{mileStoneOptions?.label?.substring(0, 25)}{
                                mileStoneOptions?.label?.length > 25 ? "..." : ""
                            } </span>
                            : "-"
                    }
                    className={classes.name}
                    size="small"
                />
            </RadioGroup>
        </FormControl>
    )
}

const Option = ({ children, innerRef, ...props }) => {
    return (

        <components.Option {...props} ref={innerRef}>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
            >
                <Details mileStoneOptions={props?.data} props={props} is_checked={(props?.selectProps?.value?.mile_stone_id === props?.data?.value)}/>

            </Stack>

            {
                (props?.label === "On Completion" && props?.options?.length > 2) &&
                <Typography
                    sx={{ fontFamily: FontFamilySwitch().regular, color: "#98A0AC", fontSize:"0.75rem", marginTop: "4px" }}
                >IF MILESTONE BASE</Typography>
            }

        </components.Option>

    );
};

export const CustomPaymentSelectBox = React.memo((props) => {
    const {
        value = "",

        onChange,
        options,
        loading,
        isReadOnly,
        isMulti,
        styles = {},
        label = "",
        error = false,
        required = false,
        mileStoneId = "",
        loadOptions

    } = props;
    const theme = useTheme();
    const Control = ({ children, ...props }) => {
        return (
            <components.Control {...props}>
                {children}
            </components.Control>
        );
    };


    const customStyles = (props) => {

        return {
            control: (base) => ({
                ...base,
                borderColor:
                    props?.noBorder ? "white" : "#E4E8EE",

                borderRadius: 4,
                height: "45px",
                paddingLeft: props?.noBorder ? 0 : 8,
                '&:not(:focus)': {
                    boxShadow: "none"
                },
                '&:hover': {
                    borderColor:
                        props?.noBorder ? "white" : theme.palette.primary.main,
                },
                ...styles?.control ?? {},
            }),
            valueContainer: (base) => ({
                ...base,
                paddingBlock: 3
            }),
            placeholder: defaultStyles => {
                return {
                    ...defaultStyles,
                    fontFamily: FontFamilySwitch().regular,
                    fontSize:"0.875rem",
                    whiteSpace: "nowrap",
                    color: "#98A0AC"
                };
            },
            menu: defaultStyles => {
                return {
                    ...defaultStyles,
                    zIndex: 2,
                    boxShadow: "0px 10px 25px #0000000A",
                    border: "1px solid #E4E8EE",
                    borderRadius: 4,
                    marginTop: "10px",
                    width: "100%",
                    ".css-m7ygez-MenuList": {
                        minHeight: "255px !important"
                    }


                };
            },
            option: (defaultStyles, prop) => {
                return {
                    ...defaultStyles,
                    fontFamily: FontFamilySwitch().semiBold,
                    fontSize:"0.875rem",
                    cursor: "pointer",
                    padding: "0px 12px",
                    ...isSingleStyle(prop),


                };
            },
        }
    };

    const isSingleStyle = (prop) => {
        if (prop.isMulti) return {}
        return {
            color: prop?.isSelected ? "#5078E1" : "#091B29",
            backgroundColor: "transparent",
            '&:hover,&:active': {
                backgroundColor: "transparent",
                color: "#5078E1"
            },
        }
    }

    return (
        <>
            {label && <Label label={label} isRequired={required} />}


            <AsyncPaginate
                maxMenuHeight='255px'
                components={{
                    IndicatorSeparator: () => null,
                    Control,
                    Option,
                    MultiValueContainer: () => null,

                }}
                value={{...value , mile_stone_id:mileStoneId?.payment_period_value}}
                placeholder={"Select Payment period"}
                options={options}
                loadOptions={loadOptions}
                onChange={onChange}
                isLoading={loading}
                styles={customStyles(props)}
                isMulti={isMulti}
                isDisabled={isReadOnly}
                menuPlacement="auto"
                minMenuHeight={"150px"}

            />

            {error &&
                <CustomTypography fontFamily={FontFamilySwitch().semiBold} marginTop={theme.spacing(1)} color={theme?.palette?.error?.main} fontSize={12}>{error}</CustomTypography>
            }

        </>

    )
})