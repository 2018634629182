import React from "react";
import { useStyles } from "./styles";
import {
  Box,
  IconButton,
  Stack,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import CloseIcon from "../../assets/closeIcon";
import CardView from "./components/cardView";
import {
  FontFamilySwitch,
  LocalStorageKeys,
  NetWorkCallMethods,
} from "../../utils";
import { constructPayload } from "./utils";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { AlertProps } from "../../utils";

const DuplicateUnit = ({
  t,
  closeDrawer,
  unitData,
  count,
  units,
  handleDeleteUnit,
  viewUnitById,
  unitDetails,
  isEdit,
  close,
  existingPricing,
  getExternalUnits,
  companyId,
  currency,
}) => {
  const classes = useStyles();
  const alert = React.useContext(AlertContext);

  const unitUpsert = () => {
    let payload = constructPayload(unitData, existingPricing);

    if (isEdit) {
      payload.id = unitData?.id;
      payload.updated_by = localStorage.getItem(LocalStorageKeys.profileID);
      payload.company_id = companyId;
    } else {
      payload.created_by = localStorage.getItem(LocalStorageKeys.profileID);
      payload.company_id = companyId;
    }

    NetworkCall(
      `${config.api_url}/external_units/upsert`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then(() => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: `${
            isEdit === true
              ? t("Unit Updated Successfully")
              : t("Unit Created Successfully")
          }`,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        closeDrawer();
        close();
        getExternalUnits("", 0, 12, "", "", companyId);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
      });
  };
  return (
    <>
      <Box
        className={classes.formRoot}
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Box p={2}>
          <Stack direction={"column"} alignItems={"center"} gap={2} padding={2}>
            <img src="/images/duplicate-unit.svg" alt="duplicate" />
            <Stack direction={"column"} alignItems={"center"} gap={1}>
              <Typography
                sx={{
                  fontSize: "1.125rem",
                  fontfamily: FontFamilySwitch().bold,
                }}
              >
                {t("Similar/Duplicate Units Warning")}
              </Typography>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Typography
                  sx={{
                    fontSize: "0.875rem",
                    fontfamily: FontFamilySwitch().bold,
                  }}
                >
                  {t("Unit found with similar details,")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.875rem",
                    fontfamily: FontFamilySwitch().regular,
                    color: "#717B85",
                  }}
                >
                  {t("Do you want to create this unit?")}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Typography
            sx={{
              fontSize: "0.875rem",
              fontfamily: FontFamilySwitch().bold,
              paddingTop: "10px",
              marginBottom: "-16px",
            }}
          >
            {`${count} ${t("Units Found with similar details")}`}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1, overflowY: "auto", padding: "12px 16px" }}>
          <Grid container spacing={2}>
            {units?.map((unit, index) => (
              <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                <CardView
                  t={t}
                  unit={unit}
                  deleteUnit={() => handleDeleteUnit(unit)}
                  viewUnit={() => viewUnitById(unit)}
                  unitDetails={unitDetails}
                  isDuplicate={true}
                  currency={currency}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          width: "100%",
          backgroundColor: "white",
          padding: "5px",
        }}
      >
        <Grid container className={classes.btnroot} spacing={1}>
          <Grid item xs={4}>
            <Button
              className={classes.previousbtn}
              variant="contained"
              onClick={closeDrawer}
            >
              {t("Back")}
            </Button>
          </Grid>
          <Grid item xs={8} textAlign={"end"}>
            <Button
              className={classes.submitbtn}
              variant="contained"
              onClick={unitUpsert}
            >
              {isEdit ? t("Update Unit") : t("Add New Unit")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DuplicateUnit;
