import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { DialogDrawer, SelectBox, TableWithPagination } from "../../components";
import Subheader from "../../components/subheader/subheader";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { BackendRoutes } from "../../router/routes";
import {
  AlertProps,
  NetWorkCallMethods,
  accessCheckRender,
  getCalendarOptions,
  getCompanyOption,
  getRoutePermissionNew,
  manualResponse,
  pageName,
  stringAvatar,
} from "../../utils";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { CardDetails } from "../propertymanagerdashboard/components";
import Graphcomponent from "../propertymanagerdashboard/components/graphcomponent";
import { useStyles } from "./style";
import {
  CompletedJobsListDataType,
  CompletedJobsListHeading,
  CompletedJobsListPath,
  OngoingJobsListDataType,
  OngoingJobsListHeading,
  OngoingJobsListPath,
  ResourceJobsListDataType,
  ResourceJobsListHeading,
  ResourceJobsListPath,
  ResourceListDataType,
  ResourceListHeading,
  ResourceListPath,
} from "./utils/constants";
import {
  constructResponseGetCompletedJobsListData,
  constructResponseGetLeaveTimeOffData,
  constructResponseGetOngoingJobsListData,
  constructResponseGetResourceJobsListData,
  constructResponseGetResourceListData,
  constructResponseGetStatData,
} from "./utils/helperFunctions";

const ResourceManagerDashboard = ({
  loading = true,
  handleLoading = () => false,
  t = () => false,
}) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const backdrop = useContext(BackdropContext);
  const alert = useContext(AlertContext);

  const [permission_state, set_permission_state] = useState("");
  const [calendar_options, set_calendar_options] = useState([]);
  const [selected_calendar, set_selected_calendar] = useState("");
  const [selected_company, set_selected_company] = useState("");
  const [company_list, set_company_list] = useState([]);
  const [stat_data, set_stat_data] = useState("");
  const [resource_list_data, set_resource_list_data] = useState({
    rows: [],
    count: 0,
  });
  const [selected_resource, set_selected_resource] = useState("");
  const [resource_list_page, set_resource_list_page] = useState(1);
  const [resource_list_limit, set_resource_list_limit] = useState(10);
  const [is_resource_list_loading, set_is_resource_list_loading] =
    useState(true);
  const [resource_list_department, set_resource_list_department] = useState({
    label: t("All Departments"),
  });
  const [is_zoom, set_is_zoom] = useState(false);
  const [zoom_graph_title, set_zoom_graph_title] = useState("");
  const [ongoing_jobs_list_data, set_ongoing_jobs_list_data] = useState({
    rows: [],
    count: 0,
  });
  const [ongoing_jobs_list_page, set_ongoing_jobs_list_page] = useState(1);
  const [ongoing_jobs_list_limit, set_ongoing_jobs_list_limit] = useState(10);
  const [is_ongoing_jobs_list_loading, set_is_ongoing_jobs_list_loading] =
    useState(true);
  const [leave_time_off_state, set_leave_time_off_state] = useState({
    data: [],
    count: 0,
  });
  const [resource_jobs_list_data, set_resource_jobs_list_data] = useState({
    rows: [],
    count: 0,
  });
  const [resource_jobs_list_page, set_resource_jobs_list_page] = useState(1);
  const [resource_jobs_list_limit, set_resource_jobs_list_limit] = useState(10);
  const [is_resource_jobs_list_loading, set_is_resource_jobs_list_loading] =
    useState(true);
  const [completed_jobs_list_data, set_completed_jobs_list_data] = useState({
    rows: [],
    count: 0,
  });
  const [completed_jobs_list_page, set_completed_jobs_list_page] = useState(1);
  const [completed_jobs_list_limit, set_completed_jobs_list_limit] =
    useState(10);
  const [is_completed_jobs_list_loading, set_is_completed_jobs_list_loading] =
    useState(true);

  useEffect(() => {
    const permission = getRoutePermissionNew(auth);
    if (permission) {
      set_permission_state(permission);
      if (permission?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          let tempCalendarOptions = getCalendarOptions();
          set_calendar_options(tempCalendarOptions);
          set_selected_calendar(tempCalendarOptions?.[6]);
          set_company_list(company?.list);
          set_selected_company(company?.selected);
          getInitialData({
            company: company?.selected,
            calendar: tempCalendarOptions?.[6]?.value,
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  const handleCalendarChange = (_) => {
    if (_?.load) {
      set_selected_calendar(_);
      getInitialData({ calendar: _?.value });
    }
  };

  const handleCompanyChange = (_) => {
    set_selected_company(_);
    getInitialData({ company: _ });
  };

  const getInitialData = ({
    company = selected_company,
    calendar = selected_calendar?.value,
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        await Promise.all([
          getStatData({ company }),
          getResourceListData({ company, calendar }),
          getResourceJobsListData({ company, calendar }),
          getCompletedJobsListData({ company, calendar }),
        ]);
        handleLoading(false);
      } catch (error) {
        reject(error);
      }
    });
  };

  const getStatData = ({ company = selected_company }) => {
    let payload = { company_id: company?.value };

    NetworkCall(
      `${config?.api_url}${BackendRoutes.resource_dashboard_getCount}`,
      NetWorkCallMethods?.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const temp_stat_data = constructResponseGetStatData({
          data: res?.data?.data,
          t,
        });
        set_stat_data(temp_stat_data);
      })
      .catch(() => {
        alert.setSnack({
          ...alert,
          open: true,
          msg: t("Some Thing Went Wrong"),
          severity: AlertProps?.severity?.error,
        });
      });
  };

  const getResourceListData = ({
    offset = 0,
    limit = resource_list_limit,
    company = selected_company,
    department = resource_list_department,
    calendar = selected_calendar?.value,
  }) => {
    set_is_resource_list_loading(true);
    let payload = {
      offset,
      limit,
      company_id: company?.value,
      department_ids: department?.value ? [department?.value] : null,
    };

    NetworkCall(
      `${config?.api_url}${BackendRoutes.resource_dashboard_get_resource}`,
      NetWorkCallMethods?.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const temp_resource_list = constructResponseGetResourceListData({
          data: res?.data?.data,
          t,
        });
        set_resource_list_data(temp_resource_list);
        set_selected_resource(temp_resource_list?.rows?.[0]);
        getOngoingJobsListData({
          company,
          resource: temp_resource_list?.rows?.[0],
          calendar,
        });
        getLeaveTimeOffData({
          company,
          resource: temp_resource_list?.rows?.[0],
          calendar,
        });
        set_is_resource_list_loading(false);
      })
      .catch(() => {
        alert.setSnack({
          ...alert,
          open: true,
          msg: t("Some Thing Went Wrong"),
          severity: AlertProps?.severity?.error,
        });
      });
  };

  const resourceListHandleIcon = (type, data) => {
    switch (type) {
      case "double_click":
        set_selected_resource(data);
        getOngoingJobsListData({ resource: data });
        getLeaveTimeOffData({ resource: data });
        break;

      default:
        break;
    }
  };

  const resourceListHandlePagination = (_) => {
    set_resource_list_page(_);
    let offset = (_ - 1) * resource_list_limit;
    getResourceListData({ offset });
  };

  const resourceListHandleChangeLimit = (_) => {
    set_resource_list_limit(_);
    set_resource_list_page(1);
    getResourceListData({ limit: _ });
  };

  const handleResourceListDepartment = (_) => {
    set_resource_list_department(_);
    getResourceListData({ department: _ });
  };

  const getOngoingJobsListData = ({
    offset = 0,
    limit = ongoing_jobs_list_limit,
    company = selected_company,
    resource = selected_resource,
    calendar = selected_calendar?.value,
  }) => {
    set_is_ongoing_jobs_list_loading(true);
    let payload = {
      start: offset,
      length: limit,
      property_id: company?.property?.map?.((_) => _?.id),
      start_date: calendar?.from_date,
      end_date: calendar?.to_date,
      resource_id: resource?.id,
    };

    NetworkCall(
      `${config?.api_url}${BackendRoutes.inspection_manager_get_resource_jobs}`,
      NetWorkCallMethods?.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const temp_ongoing_jobs_list = constructResponseGetOngoingJobsListData({
          data: res?.data?.data,
          t,
        });
        set_ongoing_jobs_list_data(temp_ongoing_jobs_list);
        set_is_ongoing_jobs_list_loading(false);
      })
      .catch(() => {
        alert.setSnack({
          ...alert,
          open: true,
          msg: t("Some Thing Went Wrong"),
          severity: AlertProps?.severity?.error,
        });
      });
  };

  const ongoingJobsListHandlePagination = (_) => {
    set_ongoing_jobs_list_page(_);
    let offset = (_ - 1) * ongoing_jobs_list_limit;
    getOngoingJobsListData({ offset });
  };

  const ongoingJobsListHandleChangeLimit = (_) => {
    set_ongoing_jobs_list_limit(_);
    set_ongoing_jobs_list_page(1);
    getOngoingJobsListData({ limit: _ });
  };

  const getLeaveTimeOffData = ({
    company = selected_company,
    resource = selected_resource,
    calendar = selected_calendar?.value,
  }) => {
    set_is_ongoing_jobs_list_loading(true);
    let payload = {
      property_id: company?.property?.map?.((_) => _?.id),
      start_date: calendar?.from_date,
      end_date: calendar?.to_date,
      resource_id: resource?.id,
      type: "Paid",
    };

    NetworkCall(
      `${config?.api_url}${BackendRoutes.inspection_manager_time_off_data}`,
      NetWorkCallMethods?.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const temp_leave_time_off_state = constructResponseGetLeaveTimeOffData({
          data: res?.data?.data,
          t,
        });
        set_leave_time_off_state(temp_leave_time_off_state);
      })
      .catch(() => {
        alert.setSnack({
          ...alert,
          open: true,
          msg: t("Some Thing Went Wrong"),
          severity: AlertProps?.severity?.error,
        });
      });
  };

  const getResourceJobsListData = ({
    offset = 0,
    limit = resource_jobs_list_limit,
    company = selected_company,
    calendar = selected_calendar?.value,
  }) => {
    set_is_resource_jobs_list_loading(true);
    let payload = {
      start: offset,
      length: limit,
      property_id: company?.property?.map?.((_) => _?.id),
      start_date: calendar?.from_date,
      end_date: calendar?.to_date,
    };

    NetworkCall(
      `${config?.api_url}${BackendRoutes.inspection_manager_get_resource_vs_jobs}`,
      NetWorkCallMethods?.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const temp_resource_jobs_list =
          constructResponseGetResourceJobsListData({ data: res?.data?.data });
        set_resource_jobs_list_data(temp_resource_jobs_list);
        set_is_resource_jobs_list_loading(false);
      })
      .catch(() => {
        alert.setSnack({
          ...alert,
          open: true,
          msg: t("Some Thing Went Wrong"),
          severity: AlertProps?.severity?.error,
        });
      });
  };

  const resourceJobsListHandlePagination = (_) => {
    set_resource_jobs_list_page(_);
    let offset = (_ - 1) * resource_jobs_list_limit;
    getResourceJobsListData({ offset });
  };

  const resourceJobsListHandleChangeLimit = (_) => {
    set_resource_jobs_list_limit(_);
    set_resource_jobs_list_page(1);
    getResourceJobsListData({ limit: _ });
  };

  const getCompletedJobsListData = ({
    offset = 0,
    limit = completed_jobs_list_limit,
    company = selected_company,
    calendar = selected_calendar?.value,
  }) => {
    set_is_completed_jobs_list_loading(true);
    let payload = {
      start: offset,
      length: limit,
      property_id: company?.property?.map?.((_) => _?.id),
      start_date: calendar?.from_date,
      end_date: calendar?.to_date,
    };

    NetworkCall(
      `${config?.api_url}${BackendRoutes.inspection_manager_actual_vs_other_vs_projected}`,
      NetWorkCallMethods?.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const temp_completed_jobs_list =
          constructResponseGetCompletedJobsListData({ data: res?.data?.data });
        set_completed_jobs_list_data(temp_completed_jobs_list);
        set_is_completed_jobs_list_loading(false);
      })
      .catch(() => {
        alert.setSnack({
          ...alert,
          open: true,
          msg: t("Some Thing Went Wrong"),
          severity: AlertProps?.severity?.error,
        });
      });
  };

  const completedJobsListHandlePagination = (_) => {
    set_completed_jobs_list_page(_);
    let offset = (_ - 1) * completed_jobs_list_limit;
    getCompletedJobsListData({ offset });
  };

  const completedJobsListHandleChangeLimit = (_) => {
    set_completed_jobs_list_limit(_);
    set_completed_jobs_list_page(1);
    getCompletedJobsListData({ limit: _ });
  };

  const zoomGraphFunction = (e) => {
    set_zoom_graph_title(e);
    set_is_zoom(true);
  };

  const handleCloseModule = () => {
    set_is_zoom(false);
  };

  const manualResponseDepartment = (array) => {
    const details = array?.department_master?.map((i) => {
      return {
        ...i,
        label: i?.name,
        value: i?.id,
      };
    });
    return details;
  };

  const render = () => {
    return (
      <>
        {/* sub header */}
        <Subheader
          title={t("Resource Manager Dashboard")}
          hideBackButton
          calanderselect
          calendarOptions={calendar_options}
          calanderValue={selected_calendar}
          onChangeCalendar={(e) => handleCalendarChange(e)}
          select
          options={company_list}
          value={selected_company}
          onchange={(e) => handleCompanyChange(e)}
        />
        <Box className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
              <Grid container spacing={2}>
                {stat_data?.resource_count?.map((_) => {
                  return (
                    <Grid item xs={12} sm={4}>
                      <CardDetails data={_} padding={16} />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack
                className={classes?.table_section}
                direction={"column"}
                height="350px"
              >
                <Stack
                  direction={"row"}
                  columnGap={"16px"}
                  justifyContent={"space-between"}
                >
                  <Typography className={classes.table_header}>
                    {t("Resource List")}
                  </Typography>
                  <Box minWidth={"200px"}>
                    <SelectBox
                      placeholder={t("Select Department")}
                      selectHeight={"35px"}
                      value={resource_list_department}
                      onChange={(_) => handleResourceListDepartment(_)}
                      isPaginate
                      debounceTimeout={800}
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "queries/opportunity/department_master/get",
                          {
                            company_id: selected_company?.value,
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualResponseDepartment
                        )
                      }
                    />
                  </Box>
                </Stack>
                <TableWithPagination
                  t={t}
                  is_loading={is_resource_list_loading}
                  heading={ResourceListHeading({ t })}
                  rows={resource_list_data?.rows}
                  path={ResourceListPath}
                  dataType={ResourceListDataType}
                  showpagination
                  handleIcon={resourceListHandleIcon}
                  handlePagination={resourceListHandlePagination}
                  handleChangeLimit={resourceListHandleChangeLimit}
                  totalRowsCount={resource_list_data?.count}
                  page={resource_list_page}
                  limit={resource_list_limit}
                  height={213}
                  tableType="no-side"
                  enable_double_click
                  data={selected_resource?.id}
                  selectedBackgroundColor={"#F1F7FF"}
                  view={permission_state?.read}
                  edit={permission_state?.update}
                  delete={permission_state?.delete}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack
                className={classes?.table_section}
                direction={"column"}
                height="350px"
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <Avatar
                    className={classes.avatar}
                    src={selected_resource?.image?.image_src}
                    {...stringAvatar(selected_resource?.name)}
                    variant="rounded"
                  />
                  <Typography className={classes.table_header}>
                    {`${selected_resource?.name} - ${t("Ongoing Jobs")} (${
                      ongoing_jobs_list_data?.count
                    })`}
                  </Typography>
                </Stack>
                <TableWithPagination
                  t={t}
                  is_loading={is_ongoing_jobs_list_loading}
                  heading={OngoingJobsListHeading({ t })}
                  rows={ongoing_jobs_list_data?.rows}
                  path={OngoingJobsListPath}
                  dataType={OngoingJobsListDataType}
                  showpagination
                  enable_single_click
                  handleIcon={() => {}}
                  handlePagination={ongoingJobsListHandlePagination}
                  handleChangeLimit={ongoingJobsListHandleChangeLimit}
                  totalRowsCount={ongoing_jobs_list_data?.count}
                  page={ongoing_jobs_list_page}
                  limit={ongoing_jobs_list_limit}
                  height={226}
                  tableType="no-side"
                  view={permission_state?.read}
                  edit={permission_state?.update}
                  noDataSvg
                  delete={permission_state?.delete}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box height="340px" display={"contents"}>
                <Graphcomponent
                  t={t}
                  title={`${selected_resource?.name} - ${t(
                    "Job Completion KPI"
                  )}`}
                  graphTitle={"Job Completion KPI"}
                  chartHeader={t("Job Completion KPI")}
                  graphData={
                    ongoing_jobs_list_data?.job_completion_kpi?.data?.some(
                      (x) => {
                        return x?.count > 0;
                      }
                    )
                      ? ongoing_jobs_list_data?.job_completion_kpi?.data
                      : []
                  }
                  innerRadius={50}
                  isTotal={true}
                  total={ongoing_jobs_list_data?.job_completion_kpi?.total}
                  is_pie_new
                  margin={"20px"}
                  onZoomClick={zoomGraphFunction}
                  textMargin={"0px 0px 16px"}
                  divider1={{
                    xs: 12,
                    sm: 7.4,
                    md: 12,
                    lg: 12,
                  }}
                  divider={{
                    xs: 12,
                    sm: 4.6,
                    md: 12,
                    lg: 12,
                  }}
                  gradiantStyle={{
                    margin: "0px 0px 0px",
                    display: "auto",
                    alignItems: "center",
                    top: {
                      lg: "auto",
                      md: "16px",
                      sm: "3px",
                    },
                    height: {
                      md: "400px",
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box height="350px" display={"contents"}>
                <Graphcomponent
                  t={t}
                  title={`${selected_resource?.name} - ${t(
                    "Leave & Time Off"
                  )}`}
                  graphTitle={"Leave & Time Off"}
                  chartHeader={t("Leave & Time Off")}
                  graphData={leave_time_off_state?.data}
                  innerRadius={50}
                  isTotal={true}
                  total={leave_time_off_state?.total}
                  is_pie_new
                  margin={"20px"}
                  onZoomClick={zoomGraphFunction}
                  textMargin={"0px 0px 16px"}
                  divider1={{
                    xs: 12,
                    sm: 7.4,
                    md: 12,
                    lg: 12,
                  }}
                  divider={{
                    xs: 12,
                    sm: 4.6,
                    md: 12,
                    lg: 12,
                  }}
                  gradiantStyle={{
                    margin: "0px 0px 0px",
                    display: "auto",
                    alignItems: "center",
                    top: {
                      lg: "auto",
                      md: "16px",
                      sm: "3px",
                    },
                    height: {
                      md: "400px",
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Stack
                className={classes?.table_section}
                direction={"column"}
                height="350px"
              >
                <Typography className={classes.table_header}>
                  {t("Resource Vs Jobs")}
                </Typography>
                <TableWithPagination
                  t={t}
                  is_loading={is_resource_jobs_list_loading}
                  heading={ResourceJobsListHeading({ t })}
                  rows={resource_jobs_list_data?.rows}
                  path={ResourceJobsListPath}
                  dataType={ResourceJobsListDataType}
                  showpagination
                  handleIcon={() => {}}
                  handlePagination={resourceJobsListHandlePagination}
                  handleChangeLimit={resourceJobsListHandleChangeLimit}
                  totalRowsCount={resource_jobs_list_data?.count}
                  page={resource_jobs_list_page}
                  limit={resource_jobs_list_limit}
                  height={226}
                  tableType="no-side"
                  view={permission_state?.read}
                  edit={permission_state?.update}
                  delete={permission_state?.delete}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box height="350px" display={"contents"}>
                <Graphcomponent
                  t={t}
                  title={t("Jobs & Roles")}
                  graphTitle={"Jobs & Roles"}
                  chartHeader={t("Jobs & Roles")}
                  graphData={stat_data?.job_roles?.data}
                  innerRadius={50}
                  total={stat_data?.job_roles?.total}
                  is_pie_new
                  isShowLegend={true}
                  margin={"20px"}
                  onZoomClick={zoomGraphFunction}
                  textMargin={"0px 0px 16px"}
                  divider1={{
                    xs: 12,
                    sm: 7.4,
                    md: 12,
                    lg: 12,
                  }}
                  divider={{
                    xs: 12,
                    sm: 4.6,
                    md: 12,
                    lg: 12,
                  }}
                  gradiantStyle={{
                    margin: "0px 0px 0px",
                    display: "auto",
                    alignItems: "center",
                    top: {
                      lg: "auto",
                      md: "16px",
                      sm: "3px",
                    },
                    height: {
                      md: "400px",
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Stack
                className={classes?.table_section}
                direction={"column"}
                height="350px"
              >
                <Typography className={classes.table_header}>
                  {t("Completed Jobs")}
                </Typography>
                <TableWithPagination
                  t={t}
                  is_loading={is_completed_jobs_list_loading}
                  heading={CompletedJobsListHeading({ t })}
                  rows={completed_jobs_list_data?.rows}
                  path={CompletedJobsListPath}
                  dataType={CompletedJobsListDataType}
                  showpagination
                  handleIcon={() => {}}
                  handlePagination={completedJobsListHandlePagination}
                  handleChangeLimit={completedJobsListHandleChangeLimit}
                  totalRowsCount={completed_jobs_list_data?.count}
                  page={completed_jobs_list_page}
                  limit={completed_jobs_list_limit}
                  height={226}
                  tableType="no-side"
                  view={permission_state?.read}
                  edit={permission_state?.update}
                  delete={permission_state?.delete}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box height="350px" display={"contents"}>
                <Graphcomponent
                  t={t}
                  title={t("Profession & Skills")}
                  graphTitle={"Profession & Skills"}
                  chartHeader={t("Profession & Skills")}
                  isShowLegend={true}
                  graphData={stat_data?.profession_skills?.data}
                  innerRadius={50}
                  total={stat_data?.profession_skills?.total}
                  is_variable_pie_new
                  margin={"20px"}
                  onZoomClick={zoomGraphFunction}
                  textMargin={"0px 0px 16px"}
                  divider1={{
                    xs: 12,
                    sm: 7.4,
                    md: 12,
                    lg: 12,
                  }}
                  divider={{
                    xs: 12,
                    sm: 4.6,
                    md: 12,
                    lg: 12,
                  }}
                  gradiantStyle={{
                    margin: "0px 0px 0px",
                    display: "auto",
                    alignItems: "center",
                    top: {
                      lg: "auto",
                      md: "16px",
                      sm: "3px",
                    },
                    height: {
                      md: "400px",
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <DialogDrawer
          header={t(zoom_graph_title)}
          maxWidth={"md"}
          handleClose={handleCloseModule}
          open={is_zoom}
          height={"auto"}
          borderRadius={"12px"}
          padding={"0px"}
          onClose={handleCloseModule}
          component={
            <div>
              {zoom_graph_title ===
                `${selected_resource?.name} - ${t("Job Completion KPI")}` && (
                <Graphcomponent
                  graphData={ongoing_jobs_list_data?.job_completion_kpi?.data}
                  innerRadius={100}
                  t={t}
                  centerTop={"44%"}
                  isTotal={true}
                  total={ongoing_jobs_list_data?.job_completion_kpi?.total}
                  isPie
                  margin={"20px"}
                  padding={"16px"}
                  maxHeightGraph={"250px"}
                  minWidth={false}
                  height={"auto"}
                  is_popUp
                  justifyContent={"start"}
                  divider1={{
                    xs: 12,
                    sm: 5,
                    md: 5,
                    lg: 5,
                  }}
                  divider={{
                    xs: 12,
                    sm: 7,
                    md: 7,
                    lg: 7,
                  }}
                  gradiantStyle={{
                    margin: "0px 0px 0px",
                    display: "flex",
                    alignItems: "center",
                    grid_template: "100%",
                    maxHeight: "auto",
                    top: {
                      lg: "auto",
                      md: "-8px",
                      sm: "auto",
                    },
                    height: {
                      md: "auto",
                      sm: "auto",
                      lg: "100%",
                    },
                  }}
                />
              )}
              {zoom_graph_title ===
                `${selected_resource?.name} - ${t("Leave & Time Off")}` && (
                <Graphcomponent
                  graphData={leave_time_off_state?.data}
                  innerRadius={100}
                  t={t}
                  centerTop={"44%"}
                  isTotal={true}
                  total={leave_time_off_state?.total}
                  isPie
                  margin={"20px"}
                  padding={"16px"}
                  maxHeightGraph={"250px"}
                  minWidth={false}
                  height={"auto"}
                  is_popUp
                  justifyContent={"start"}
                  divider1={{
                    xs: 12,
                    sm: 5,
                    md: 5,
                    lg: 5,
                  }}
                  divider={{
                    xs: 12,
                    sm: 7,
                    md: 7,
                    lg: 7,
                  }}
                  gradiantStyle={{
                    margin: "0px 0px 0px",
                    display: "flex",
                    alignItems: "center",
                    grid_template: "100%",
                    maxHeight: "auto",
                    top: {
                      lg: "auto",
                      md: "-8px",
                      sm: "auto",
                    },
                    height: {
                      md: "auto",
                      sm: "auto",
                      lg: "100%",
                    },
                  }}
                />
              )}
              {zoom_graph_title === t("Jobs & Roles") && (
                <Graphcomponent
                  graphData={stat_data?.job_roles?.data}
                  innerRadius={100}
                  t={t}
                  centerTop={"44%"}
                  isTotal={true}
                  total={stat_data?.job_roles?.total}
                  isPie
                  margin={"20px"}
                  padding={"16px"}
                  maxHeightGraph={"250px"}
                  minWidth={false}
                  height={"auto"}
                  is_popUp
                  justifyContent={"start"}
                  divider1={{
                    xs: 12,
                    sm: 5,
                    md: 5,
                    lg: 5,
                  }}
                  divider={{
                    xs: 12,
                    sm: 7,
                    md: 7,
                    lg: 7,
                  }}
                  gradiantStyle={{
                    margin: "0px 0px 0px",
                    display: "flex",
                    alignItems: "center",
                    grid_template: "100%",
                    maxHeight: "auto",
                    top: {
                      lg: "auto",
                      md: "-8px",
                      sm: "auto",
                    },
                    height: {
                      md: "auto",
                      sm: "auto",
                      lg: "100%",
                    },
                  }}
                />
              )}
              {zoom_graph_title === t("Profession & Skills") && (
                <Graphcomponent
                  graphData={stat_data?.profession_skills?.data}
                  innerRadius={100}
                  t={t}
                  centerTop={"44%"}
                  isTotal={true}
                  total={stat_data?.profession_skills?.total}
                  isPie
                  margin={"20px"}
                  padding={"16px"}
                  maxHeightGraph={"250px"}
                  minWidth={false}
                  height={"auto"}
                  is_popUp
                  justifyContent={"start"}
                  divider1={{
                    xs: 12,
                    sm: 5,
                    md: 5,
                    lg: 5,
                  }}
                  divider={{
                    xs: 12,
                    sm: 7,
                    md: 7,
                    lg: 7,
                  }}
                  gradiantStyle={{
                    margin: "0px 0px 0px",
                    display: "flex",
                    alignItems: "center",
                    grid_template: "100%",
                    maxHeight: "auto",
                    top: {
                      lg: "auto",
                      md: "-8px",
                      sm: "auto",
                    },
                    height: {
                      md: "auto",
                      sm: "auto",
                      lg: "100%",
                    },
                  }}
                />
              )}
            </div>
          }
        />
      </>
    );
  };

  return (
    <div>
      {accessCheckRender(render, permission_state, pageName.dashboard, loading)}
    </div>
  );
};
export default withTranslation("resourceManagerDashboard")(
  ResourceManagerDashboard
);
