import { Avatar, Box, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { AmenityPaymentDetailsStyle } from './style'
import { stringAvatar, timeZoneConverter } from "../../../utils"
import moment from 'moment'

const AmenityPaymentDetails = (
    {
        amenityBookingDetail = {},
        t = () => false
    }
) => {
    const classes = AmenityPaymentDetailsStyle()
    return (
        <Box className={classes.box} p={"16px 16px 8px"} ml={2} mt={1}>
            <Stack spacing={1}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ width: "100%" }}>
                        <Avatar className={classes.avatar} src={amenityBookingDetail?.amenity_assets}
                            {...stringAvatar(amenityBookingDetail?.amenities_name)}></Avatar>
                        <Stack sx={{ width: "100%" }}>
                            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                <Typography className={classes.name} noWrap>{amenityBookingDetail?.amenities_name}</Typography>
                                <Typography className={classes.rightname}>{amenityBookingDetail?.currency + " " + Number(amenityBookingDetail?.paid_amount).toFixed(2)}</Typography>
                            </Stack>
                            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                <Typography className={classes.id}>{amenityBookingDetail?.booking_id}</Typography>
                                <Typography className={amenityBookingDetail?.payment_status === "Paid" ? classes.paid : classes.unpaid} noWrap>{amenityBookingDetail?.payment_status}</Typography>
                            </Stack>
                        </Stack>
                    </Stack>

                </Stack>
                <Divider></Divider>
                <Stack spacing={1}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography className={classes.regularTitle}>{t("Invoice Amount")}</Typography>
                        <Typography className={classes.semiBoldTitle}>{amenityBookingDetail?.currency} {(amenityBookingDetail?.tax_amount && amenityBookingDetail?.paid_amount) ? Number(Number(amenityBookingDetail?.paid_amount) - Number(amenityBookingDetail?.tax_amount)).toFixed(2) : '-'}</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography className={classes.regularTitle}>{t("VAT Amount")}</Typography>
                        <Typography className={classes.semiBoldTitle}>{amenityBookingDetail?.currency} {amenityBookingDetail?.tax_amount ? Number(amenityBookingDetail?.tax_amount).toFixed(2) : '-'}</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography className={classes.totalTitle}>{t("Total")}</Typography>
                        <Typography className={classes.totalTitle}>{amenityBookingDetail?.currency} {amenityBookingDetail?.paid_amount ? Number(amenityBookingDetail?.paid_amount).toFixed(2) : '-'}</Typography>
                    </Stack>
                </Stack>
                <Divider></Divider>
                <Stack spacing={1}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography className={classes.regularTitle}>{t("Payment Receipt No")}</Typography>
                        <Typography className={classes.semiBoldTitle}>{amenityBookingDetail?.receipt_no ?? "-"}</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography className={classes.regularTitle}>{t("Paidon")}</Typography>
                        <Typography className={classes.semiBoldTitle}>{amenityBookingDetail?.paid_on ? moment(amenityBookingDetail?.paid_on).format("MM-DD-YYYY") : "-"}</Typography>
                    </Stack>
                </Stack>
                {amenityBookingDetail?.paid_on && <Divider />}
                <Stack spacing={1}>
                    {amenityBookingDetail?.paid_on &&
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Typography className={classes.title}>{t("Paidon")}</Typography>
                            <Typography className={classes.content}>{timeZoneConverter(amenityBookingDetail?.paid_on)}</Typography>
                        </Stack>}
                </Stack>
            </Stack>
        </Box>
    )
}

export default withTranslation("amenityBooking")(AmenityPaymentDetails)
