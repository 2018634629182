import makeStyles from "@mui/styles/makeStyles";
import { remCalc, FontFamilySwitch } from "../../../../../utils";
export const useStyles = makeStyles((theme) => ({
    root: {},
    body: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        height: `calc(100vh - 296px)`,
        overflow: "hidden",
        margin: "14px 0px"
    },
    button: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "none",
        fontSize: "0.875rem"
    },
    filter_button: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "12px"
    },
    add_drawer: {
        "& .MuiDrawer-paper": {
            padding: "0px",
            width: ({ size }) => size?.width >= 600 ? "600px" : "-webkit-fill-available",
        },
    },
    add_drawer_header: {
        padding: "16px 24px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #E4E8EE"
    },
    add_drawer_header_title: {
        fontFamily: FontFamilySwitch().extraBold, 
        fontSize: "1rem",
        color: "#091B29"
    },
    add_drawer_close_button: {
        padding: "0px"
    },
    add_drawer_body: {
        height: ({ size }) => size?.height ? (size?.height - 130) : "auto",
        padding: "16px",
        overflowY: "overlay",
    },
    add_drawer_body_view: {
        padding: "16px",
        overflowY: "overlay"
    },
    add_drawer_footer: {
        display: "flex",
        columnGap: "12px",
        padding: "16px",
    },
    add_drawer_footer_button: {
        borderRadius: theme.palette.borderRadius,
        height: "45px",
        border: "1px solid #5078E1",
        boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.08)",
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold, 
        color: "white"
    },
    add_drawer_footer_close_button: {
        borderRadius: theme.palette.borderRadius,
        height: "45px",
        border: "1px solid #5078E1",
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold, 
        color: "#5078E1",
        backgroundColor: "white",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#E4E8EE ",
        },
    },
    add_drawer_button_container: {
        display: "flex",
        rowGap: "8px",
        columnGap: "8px",
    },
    add_drawer_button_selected: {
        borderRadius: theme.palette.borderRadius,
        height: "45px",
        border: "1px solid #5078E1",
        backgroundColor: "#5078E1",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#5078E1 ",
        },
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold, 
        color: "white",
    },
    add_drawer_button_unselected: {
        borderRadius: theme.palette.borderRadius,
        height: "45px",
        border: "1px solid #E4E8EE",
        backgroundColor: "white",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#E4E8EE ",
        },
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold, 
        color: "#98A0AC",
    },
    switch_container: {
        display: "flex",
        alignItems: "center",
        direction: "ltr",
    },
    switch_container_value: {
        fontSize: remCalc(14),
        color: "#091b29",
        fontFamily: FontFamilySwitch().semiBold, 
    },
    switch_container_on: {
        position: "relative",
        height: "24px",
        width: "46px",
        borderRadius: "24px",
        backgroundColor: "#5078E1"
    },
    switch_toggle_on: {
        position: "absolute",
        right: "0px",
        height: "24px",
        width: "24px",
        borderRadius: "24px",
        border: "3px solid #5078E1",
        backgroundColor: "#FFFFFF"
    },
    switch_container_off: {
        position: "relative",
        height: "24px",
        width: "46px",
        borderRadius: "24px",
        backgroundColor: "#B6B6B6"
    },
    switch_toggle_off: {
        position: "absolute",
        left: "0px",
        height: "24px",
        width: "24px",
        borderRadius: "24px",
        border: "3px solid #B6B6B6",
        backgroundColor: "#FFFFFF",
    },
    add_drawer_property_unit_card: {
        borderRadius: theme?.palette?.borderRadius_2,
        border: "1px solid #E4E8EE",
        backgroundColor: "#F5F7FA",
    },
    add_drawer_property_unit_card_logo: {
        height: "32px",
        width: "32px",
        borderRadius: "20px",
    },
    add_drawer_property_unit_card_unit_name: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().bold, 
        color: theme?.typography?.color?.primary,
    },
    add_drawer_property_unit_card_unit_no: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().bold, 
        color: theme?.typography?.color?.primary,
    },
    add_drawer_property_unit_card_property_location: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().regular, 
        color: theme?.typography?.color?.secondary,
    },
    add_drawer_utility_detail_card: {
        borderRadius: theme?.palette?.borderRadius_3,
        border: "1px solid #E4E8EE",
        backgroundColor: "#FFFFFF",
    },
    add_drawer_utility_icon: {
        height: "40px",
        width: "40px",
        borderRadius: theme?.palette?.borderRadius,
        backgroundColor: "#F2F4F7",
    },
    add_drawer_unit_utility_id: {
        fontSize: remCalc(14),
        fontFamily: FontFamilySwitch().bold, 
        color: theme?.typography?.color?.primary,
    },
    add_drawer_period: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        backgroundColor: "#E4E8EE",
    },
    add_drawer_provider: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().semiBold, 
        fontStyle: "italic",
        color: theme?.typography?.color?.tertiary,
    },
    add_drawer_service_provider_name: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().semiBold, 
        fontStyle: "italic",
        color: theme?.typography?.color?.tertiary,
    },
    add_drawer_uom_label: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().semiBold, 
        color: theme?.typography?.color?.secondary,
    },
    add_drawer_uom_value: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().regular, 
        color: theme?.typography?.color?.secondary,
        display: "inline",
    },
    add_drawer_price_per_uom_label: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().semiBold, 
        color: theme?.typography?.color?.secondary,
    },
    add_drawer_price_per_uom_value: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().regular, 
        color: theme?.typography?.color?.secondary,
        display: "inline",
    },
}));