import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { config } from '../../../config';
import { AlertContext } from '../../../contexts';
import { AlertProps, allowed_file_size, singleFileUpload } from '../../../utils';
import { withTranslation } from 'react-i18next';
import { proofComponentStyles } from "../styles";
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';

const NewUploadFile = (props) => {
    const fileInputRef = React.useRef(null);
    const {
        label = "",
        handleChange = null,
        id = "",
        t,
        acceptedFileTypes = ["application/pdf", "image/png", "image/jpg", "image/jpeg"]
    } = props;
    
    const classes = proofComponentStyles()
    const alert = React.useContext(AlertContext);
    const [loading, setLoading] = React.useState(false);
    
    const handleUpload = async (data, e) => {
        if (data?.length) {
            if (acceptedFileTypes.includes(data?.[0]?.type)) {
                executeImageData(data);
            } else {
                alert.setSnack({
                    open: true,
                    horizontal: AlertProps.horizontal.center,
                    vertical: AlertProps.vertical.top,
                    msg: t("Invalid file"),
                    severity: AlertProps.severity.error,
                });
            }
        }
    }
    
    const executeImageData = async (data) => {
        setLoading(true);
        let uploaded_file = await singleFileUpload(data?.[0], { tenantId: `${config.tenantId}` }, alert, allowed_file_size)
        if (uploaded_file?.[0]?.url) {
            handleChange && handleChange(
                {
                    src: uploaded_file?.[0]?.url,
                    name: data[0]?.name,
                    type: data[0]?.type
                },
                id
            );
            setLoading(false);
            
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        } else {
            setLoading(false);
        }
    }
    
    return (
        <Box className={classes.wrapper}>
            <label htmlFor={`uplodebtn${id}`}>
                <Box className={classes.fileContainer}>
                    <CloudUploadRoundedIcon className={classes.uploadIcon} />
                    <Typography className={classes.uploadLabel}>
                        {loading ? t("Uploading...") : label || t("Upload File")}
                    </Typography>
                    <Typography className={classes.helperText}>
                        {t("Only support")} {acceptedFileTypes.map(type => type.split('/')[1]).join(', ')}
                    </Typography>
                </Box>
            </label>
            <input
                ref={fileInputRef}
                type="file"
                name="img"
                style={{ display: "none" }}
                id={`uplodebtn${id}`}
                onChange={(value) => handleUpload(value.target.files, value)}
                accept={acceptedFileTypes.join(',')}
            />
        </Box>
    );
};

export default withTranslation("uploadFile")(NewUploadFile);