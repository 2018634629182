import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import styled from "@mui/material/styles/styled";
import useTheme from "@mui/material/styles/useTheme";
import React from "react";
import Select, { components } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import SerachIMG from "../../../assets/search";
import { FontFamilySwitch } from '../../../utils';
import { CustomTypography } from '../../filterGenerator/customTypography';
import { Label } from '../../label';
import { Card } from "./card";
const Wrapper = styled(Stack)(({ theme }) => ({
    padding: 3,
    paddingLeft: theme.spacing(1),
    backgroundColor: "#F5F7FA",
    borderRadius: 4,
    minWidth: 100,
}));




const Option = ({ children, ...props }) => {

    return (
        <components.Option {...props}>
            <Card selected={props?.isSelected} data={props?.data} />
        </components.Option>
    );
};

export const SelectUnits = React.memo((props) => {

    const {
        key,
        value,
        placeholder,
        loadOptions,
        onChange,
        options,
        loading,
        isReadOnly,
        isMulti,
        debounceTimeout,
        reduceOptions,
        isClearable = true,
        styles = {},
        isPaginate = false,
        label = "",
        error = false,
        errorText = "",
        required = false,
        menuPlacement = "auto"

    } = props;

    const theme = useTheme();

    const Control = ({ children, ...props }) => {
        return (
            <components.Control {...props}>
                <SerachIMG color={"#98A0AC"} />

                {children}
            </components.Control>
        );
    };


    const customStyles = (props) => {
        return {
            control: (base) => ({
                ...base,
                borderColor:
                    props?.noBorder ? "white" : "#E4E8EE",

                borderRadius: 4,
                paddingLeft: props?.noBorder ? 0 : 8,
                '&:not(:focus)': {
                    boxShadow: "none"
                },
                '&:hover': {
                    borderColor:
                        props?.noBorder ? "white" : theme.palette.primary.main,
                },
                ...styles?.control ?? {},
            }),
            valueContainer: (base) => ({
                ...base,
                paddingBlock: 3
            }),
            placeholder: defaultStyles => {
                return {
                    ...defaultStyles,
                    fontFamily: FontFamilySwitch().regular,
                    fontSize:"0.875rem",
                    whiteSpace: "nowrap",
                    color: "#98A0AC"
                };
            },
            menu: defaultStyles => {
                return {
                    ...defaultStyles,
                    zIndex: 2,
                    boxShadow: "0px 10px 25px #0000000A",
                    border: "1px solid #E4E8EE",
                    borderRadius: 4,
                    marginTop: "10px",
                    width: "100%"
                };
            },
            option: (defaultStyles, prop) => {
                return {
                    ...defaultStyles,
                    fontFamily: FontFamilySwitch().semiBold,
                    fontSize:"0.875rem",
                    cursor: "pointer",
                    ...isSingleStyle(prop),
                };
            },
        }
    };

    const isSingleStyle = (prop) => {
        if (prop.isMulti) return {}
        return {
            color: prop?.isSelected ? "#5078E1" : "#091B29",
            backgroundColor: "transparent",
            '&:hover,&:active': {
                backgroundColor: "transparent",
                color: "#5078E1"
            },
        }
    }

    const onClear = (val) => {
        let updateValue = value?.filter(({ value }) => value !== val.value);
        if (props?.isDeletedValue) {
            props?.deletedValue(val, updateValue)
        }
        else {
            props?.onChange(updateValue)
        }
    }


    return (
        <>
            {label && <Label label={label} isRequired={required} />}
            {
                isPaginate ? (
                    <AsyncPaginate
                        key={key}
                        isClearable={isClearable}
                        isSearchable
                        components={{
                            IndicatorSeparator: () => null,
                            Control,
                            Option,
                            MultiValueContainer: () => null,
                        }}
                        value={value}
                        placeholder={placeholder}
                        loadOptions={loadOptions}
                        onChange={onChange}
                        options={options}
                        isLoading={loading}
                        defaultOptions={options}
                        styles={customStyles(props)}
                        isDisabled={isReadOnly}
                        isMulti={isMulti}
                        debounceTimeout={debounceTimeout}
                        reduceOptions={reduceOptions}
                        menuPlacement={menuPlacement ?? "auto"}
                        minMenuHeight={"150px"}

                    />
                ) : (
                    <Select
                        //isClearable={isClearable}
                        isSearchable
                        components={{
                            IndicatorSeparator: () => null,
                            Control,
                            Option,
                            MultiValueContainer: () => null,
                        }}
                        value={value}
                        placeholder={placeholder}
                        comp
                        options={options}
                        isLoading={loading}
                        onChange={onChange}
                        styles={customStyles(props)}
                        isMulti={isMulti}
                        isDisabled={isReadOnly}
                        menuPlacement={menuPlacement ?? "auto"}

                    />
                )
            }
            {error &&
                <CustomTypography fontFamily={FontFamilySwitch().bold} marginTop={theme.spacing(1)} color={theme?.palette?.error?.main} fontSize={12}>{errorText}</CustomTypography>
            }
            {
                (isMulti && value?.length > 0) &&
                <Stack
                    direction="row"
                    alignItems={"center"}
                    gap={1}
                    flexWrap={"wrap"}
                    marginTop={2}
                >
                    {
                        value?.map((_) => (
                            <Wrapper
                                direction={"row"}
                                alignItems={"center"}
                                key={_?.value}
                                gap={1}
                            >
                                <CustomTypography
                                    color={"#4E5A6B"}
                                    fontSize={12}
                                    fontFamily={FontFamilySwitch().semiBold}
                                    sx={{ flex: 1 }}
                                >
                                    {_?.label}
                                </CustomTypography>
                                <div
                                    onClick={() => onClear(_)}
                                    style={{ display: 'flex', cursor: "pointer" }}
                                >
                                    <IconButton size="small">
                                        <CloseIcon htmlColor="#7C8594" fontSize={"12px"} />
                                    </IconButton>
                                </div>
                            </Wrapper>
                        ))
                    }
                </Stack>
            }
        </>

    )
})
