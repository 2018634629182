import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import styled from "@mui/material/styles/styled";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ShortListIMG from "../../assets/shortlistImg";
import {
  CustomMap,
  ExistingLead,
  PropertyCard,
  Subheader,
} from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import {
  accessCheckRender,
  AlertProps,
  getRoutePermissionNew,
  LocalStorageKeys,
  NetWorkCallMethods,
} from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { Filter as FilterComponent } from "../propertyFinder3/component/filter";
import { Details } from "../propertyFinder3/result/detailpopover";
import { resultStyles } from "../propertyFinder3/result/style";
import {
  FilterIcon,
  FilterList,
  GenerateSearch,
  SearchIcon,
} from "./components";
import {
  commercialValidation,
  filterType,
  isHas,
  leaseValidation,
  propertyResultComponentGenerator,
  residentialValidation,
  returnPropertyPayload,
} from "./utils";
import { withTranslation } from "react-i18next";

const Wrapper = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  width: 60,
  height: 62,
  borderRadius: 4,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "1px solid #E4E8EE",
  "&:hover": {
    backgroundColor: theme.palette.primary.contrastText,
  },
}));

const Dot = styled("div")({
  width: 10,
  height: 10,
  borderRadius: "50%",
  backgroundColor: "#5078E1",
  position: "absolute",
  right: -3,
  top: -3,
});

const PropertyResult = (props) => {
  const language = localStorage.getItem("i18nextLng");
  const { loading, handleLoading, t } = props;
  const classes = resultStyles();
  const size = useWindowDimensions();
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext);
  const history = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedProperty, setSelectedProperty] = React.useState({});
  const [shortlistid, setShortlistId] = React.useState([]);
  const { state } = useLocation();

  const [list, setList] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [drawer, setDrawer] = useState(false);

  const [data, setData] = React.useState({});

  const [stateFilter, setStateFilter] = useState({});
  const [filterData, setFilterData] = React.useState({});

  const [location, setLocation] = React.useState({});
  const [mapPosition, setMapPosition] = React.useState({});
  const propetryPopover = React.useRef(null);
  const [radius, setRadius] = React.useState(10);
  const [permission, setPermission] = React.useState({});
  const [buttonDisable, setButtonDisable] = React.useState(false);
  const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId);
  const is_workspace = ["49", "35"].includes(moduleId);

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        const { data = null, location = {}, filterData = {} } = state ?? {};
        if (data) {
          let payload = returnPropertyPayload(
            data,
            location,
            filterData,
            state
          );
          getproperties(payload);
          setData(data);
          setStateFilter(filterData);
          setFilterData(filterData);
          setLocation(location);
          setMapPosition(location);
          handleLoading(false);
        }
      }
    }
    // eslint-disable-next-line
  }, [auth, state]);

  const getproperties = (data) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Searching ...",
    });

    data["company_id"] = state?.companyID?.value;
    if (state?.teamNumber?.value !== "noteams") {
      data["team"] = state?.teamNumber?.value;
      data["team_all_property"] = true;
    }

    NetworkCall(
      `${config.api_url}/search-property-new`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        setList(response.data.data);
        setLoader(false);
        setButtonDisable(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
        setButtonDisable(false);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      });
  };

  const search = () => {
    if (validate()) {
      setButtonDisable(true);
      let payload = returnPropertyPayload(
        { ...data, distance: radius },
        location,
        filterData,
        state
      );
      getproperties(payload);
      setMapPosition(location);
    }
  };

  const validate = () => {
    let result;
    const { type } = state;
    if (type === "residential") {
      result = residentialValidation({ ...data, location });
    } else if (type === "commercial" || type === "storage") {
      result = commercialValidation({ ...data, location });
    } else if (type === "lease") {
      result = leaseValidation({ ...data, location });
    }

    if (!result) return true;
    showAlert("Please fill all the fields");
    return false;
  };

  const showAlert = (msg = "") => {
    alert.setSnack({
      ...alert,
      open: true,
      severity: AlertProps.severity.error,
      msg,
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.center,
    });
  };

  const onChangeState = (key, value, val) => {
    if (key === "location") {
      setLocation(value);
    } else {
      setData(() => {
        if (value?.value === "Lease") {
          data["duration"] = null;
        } else if (value?.value === "Sale") {
          data["handOverDate"] = null;
        }
        return {
          ...data,
          [key]: value,
        };
      });
    }
  };

  const handleDetails = (event, value) => {
    //scroll
    if (list?.length > 2) {
      const element = document.getElementById(value?.id);
      element.scrollIntoView();
    }
    setAnchorEl(propetryPopover.current);
    setSelectedProperty(value);
  };
  const changeKms = (kms) => {
    setRadius(kms);
    setLoader(true);
    let payload = returnPropertyPayload(
      { ...data, distance: kms },
      location,
      filterData,
      state
    );
    getproperties(payload);
  };

  const goShortlist = () => {
    setButtonDisable(true);
    let response = returnPropertyPayload(data, location, filterData, state);
    history(Routes.shortlisted, {
      state: {
        unitId: shortlistid,
        date: {
          startDate: data?.duration?.fromDate,
          endDate: data?.duration?.toDate,
        },
        data: { ...response, stateFilter },
        duration: { ...data },
        companyID: state?.companyID,
      },
    });
  };

  const updateStateFilter = (key, value) => {
    setStateFilter({ ...stateFilter, [key]: value });
  };

  const onClear = () => {
    setStateFilter({});
    setFilterData({});

    if (validate()) {
      let payload = returnPropertyPayload(data, location, {}, state);
      getproperties(payload);
      setMapPosition(location);
    }
    setDrawer(false);
  };

  const applyfilter = () => {
    setDrawer(false);
    setFilterData(stateFilter);
    if (validate()) {
      let payload = returnPropertyPayload(data, location, stateFilter, state);
      getproperties(payload);
      setMapPosition(location);
    }
  };

  const onClearChips = (key, value) => {
    let copyFilterData = { ...filterData };
    if (key === "range") {
      delete copyFilterData["range1"];
      delete copyFilterData["range2"];
      delete copyFilterData["rangetype"];
    } else if (key === "budget") {
      delete copyFilterData["budget1"];
      delete copyFilterData["budget2"];
      delete copyFilterData["budgettype"];
    } else {
      delete copyFilterData[key];
    }

    if (validate()) {
      let payload = returnPropertyPayload(
        data,
        location,
        copyFilterData,
        state
      );
      getproperties(payload);
      setMapPosition(location);
    }
    setFilterData(copyFilterData);
  };

  const openFilter = () => {
    setStateFilter(filterData);
    setDrawer(true);
  };
  let date = returnPropertyPayload(data, location, filterData, state);

  const ShortlistedBtn = () => {
    return (
      <Button
        disabled={buttonDisable}
        onClick={goShortlist}
        variant="contained"
        className={
          language === "ar"
            ? classes.shortlistbtnlengthArabic
            : classes.shortlistbtnlength
        }
        startIcon={
          <ShortListIMG style={{ marginInlineEnd: "8px" }} color="white" />
        }
      >
        {t("Shortlisted")} ({shortlistid?.length})
      </Button>
    );
  };

  const render = () => {
    return (
      <>
        <Subheader
          title={t("Property Search Result")}
          isReadOnly
          select
          value={state?.companyID}
          goBack={() => {
            history(Routes.propertyFinder, {
              state: {
                company: state?.companyID,
              },
            });
          }}
          readOnlyTeam
          selectedTeams={state?.teamNumber}
        />
        <div className={classes.root}>
          <Stack direction={"row"} gap={1}>
            <div style={{ flex: 1, width: "100%" }}>
              <GenerateSearch
                components={propertyResultComponentGenerator(
                  state?.type,
                  { ...data, location },
                  filterData,
                  state?.companyID,
                  t,
                  is_workspace ? true : false
                )}
                onChangeState={onChangeState}
                t={t}
              />
            </div>
            {permission?.read && (
              <Wrapper disabled={buttonDisable} onClick={search}>
                <SearchIcon fill={"#091b29"} />
              </Wrapper>
            )}
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={1}
              sx={{
                height: "62px",
                border: "1px solid #E4E8EE",
                padding: "8px 20px",
                position: "relative",
                borderRadius: "6px",
                cursor: "pointer",
              }}
              onClick={openFilter}
            >
              {Object.keys(stateFilter).filter(
                (key) => key !== "budgettype" && key !== "rangetype"
              ).length > 0 ? (
                <Dot />
              ) : (
                ""
              )}
              <FilterIcon />
              {isHas(stateFilter) ? <Dot /> : ""}
            </Stack>
          </Stack>
          {isHas(stateFilter) ? (
            <div style={{ marginTop: 16 }}>
              <FilterList
                filterData={filterData}
                onClearChips={onClearChips}
                backgroundColor={"#F1F7FF"}
              />
            </div>
          ) : (
            ""
          )}

          <>
            {
              <Grid
                container
                marginTop={2}
                sx={{ borderTop: "1px solid #E4E8EE" }}
              >
                <Grid
                  item
                  xs={3.5}
                  className={classes.cardRoot}
                  style={{ height: `calc(100vh - 280px)`, overflow: 'scroll' }}
                >
                  {list?.length > 0 ? (
                    <>
                      <Typography className={classes.total}>
                        {list?.length ?? 0}&nbsp;{t("Properties")}
                      </Typography>
                      {list?.map((val) => {
                        return (
                          <div id={val?.id}>
                            <PropertyCard
                              data={{ ...val, type: val?.type?.group_name }}
                              onClick={handleDetails}
                            />
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <Typography className={classes.noData}>
                      {t("No Data Found")}{" "}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={8.5} ref={propetryPopover}>
                  {!loader && (
                    <CustomMap
                      handleDetails={handleDetails}
                      changeKms={changeKms}
                      lat={mapPosition?.latitude}
                      lang={mapPosition?.longitude}
                      radius={radius}
                      list={list}
                    />
                  )}
                  {data?.lead && (
                    <Box className={classes.existingLeadRoot}>
                      <ExistingLead disable={true} value={data?.lead} />
                    </Box>
                  )}
                </Grid>
              </Grid>
            }
          </>
          {permission?.create && shortlistid?.length > 0 && (
            <Button
              disabled={buttonDisable}
              onClick={goShortlist}
              variant="contained"
              className={
                language === "ar"
                  ? classes.shortlistbtnlengthArabic
                  : classes.shortlistbtnlength
              }
              startIcon={
                <ShortListIMG
                  style={{ marginInlineEnd: "8px" }}
                  color="white"
                />
              }
            >
              {t("Shortlisted")} ({shortlistid?.length})
            </Button>
          )}
          <Details
            revenue_type={data?.propertyPurpose?.value}
            date={date?.duration}
            grace={parseInt(filterData?.facilitesbool)}
            setShortlistId={setShortlistId}
            shortlistid={shortlistid}
            selectedProperty={selectedProperty}
            anchorEl={anchorEl}
            t={t}
            btn={shortlistid?.length && <ShortlistedBtn />}
            setAnchorEl={setAnchorEl}
          />
          <Drawer
            anchor="right"
            open={drawer}
            onClose={() => setDrawer(false)}
            sx={{
              "& .MuiDrawer-paper": {
                boxShadow: "none",
                border: 0,
                backgroundColor: "transparent",
              },
            }}
          >
            <FilterComponent
              t={t}
              filter={state?.filterType}
              type={filterType(state?.type)}
              applyfilter={applyfilter}
              onClear={onClear}
              state={stateFilter}
              updateState={updateStateFilter}
              company={state?.companyID?.value}
              onClose={() => {
                setDrawer(false);
              }}
            />
          </Drawer>
        </div>
      </>
    );
  };

  return <div>{accessCheckRender(render, permission, "", loading)}</div>;
};
export default withTranslation("propertyFinder")(PropertyResult);
