import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
    cells: {
        display: "flex",
        justifyContent: "center",
        padding: "20px",
        minHeight: 80,
        alignItems: "center",
    },
    cellsRoot: {
        border: `0.5px solid ${theme.palette.border.secondary}`,
    },
    userListCard: {
        display: "flex",
        justifyContent: "center",
        padding: "12px",
        alignItems: "center",
        border: `0.5px solid ${theme.palette.border.secondary}`,
    },
    userListCards: {
        display: "flex",
        //justifyContent: "center",
        padding: "12px",
        alignItems: "center",
        border: `0.5px solid ${theme.palette.border.secondary}`,
    },
    hovercells: {
        display: "flex",
        justifyContent: "center",
        padding: "20px",
        border: `0.5px solid ${theme.palette.border.secondary}`,
    },
    dayLabel: {
        fontSize:"0.75rem",
        textTransform: "uppercase",
        color: theme.typography.color.tertiary
    },
    dateLabel: {
        fontSize:"1rem",
        fontWeight: "bold",
        textTransform: "uppercase",
        color: theme.typography.color.secondary
    },
    listTitle: {
        color: theme.typography.color.tertiary,
        fontSize:"1rem",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "0px",
        border: "none",

        height: "30px",

        [`& fieldset`]: {
            borderRadius: "0px",
            border: "0px",
        },
        "& .MuiOutlinedInput-input": {
            padding: "4px 8px 0px 8px"
        }
    },
    calender: {
        zIndex: "0"
    },
    calenderRows: {
        // height: "calc(100vh - 320px)",
        // overflow: "auto",
        width: "100%",

    },
    cell_hover: {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.primary.main,
        minHeight: 80,
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        cursor: "pointer",
        padding: "20px",
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
    },
    leaveRoot: {
        display: "flex",
        justifyContent: "center",
        padding: "20px",
        minHeight: 80,
        alignItems: "center",
        backgroundImage: `url("/images/leace.svg")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "100%",
        cursor: "pointer"

    },
    leaveRootHover: {
        display: "flex",
        justifyContent: "center",
        padding: "20px",
        minHeight: 80,
        alignItems: "center",
        backgroundImage: `url("/images/hover_leave.svg")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "100%",
        cursor: "pointer"
    },
    leave_name: {
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.secondary,
        fontSize:"0.75rem"
    },
    dateRoot: {
        backgroundColor: "#F5F7FA",
        borderRadius: "4px",
        padding: "8px",
        width: "200px",
        marginTop: "4px"
    },
    viewScheduleBtn: {
        backgroundColor: "white",
        fontSize:"0.75rem",
        padding: "0px",
        '&:hover': {
            backgroundColor: "white",
        }

    },
    weekOff: {
        backgroundColor: "#F5F7FA",
        minHeight: 80

    },
    desc: {
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
        fontSize:"0.75rem",
        marginInlineStart: "8px !important"
    },
    box: {
        backgroundColor: "#F5F7FA",
        height: "34px",
        width: "34px",
        borderRadius: "4px",
        border: "1px solid #CED3DD"
    },
    filterbox:{
        height: "34px",
        width: "34px",
        borderRadius: "4px",
        border: "1px solid #CED3DD",
        alignItems:"center",
        cursor:"pointer"
    },
    box1: {
        backgroundImage: `url("/images/hover_leave.svg")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "100%",
        height: "34px",
        width: "34px",
        borderRadius: "4px",
        border: "1px solid #CED3DD"
    },
    noData: {
        textAlign: "center",
        marginTop: "10%",
        [theme.breakpoints.down("md")]: {
            marginTop: "10%",
        },
        height: "24px",
        width: "24px",
        borderRadius: "4px",
        border: "1px solid #CED3DD"
    },
    cal: {
        direction: "ltr !important"
    }
}))

export const HeaderStyles = makeStyles((theme) => ({
    dateLabel: {
        fontSize:"0.875rem",
        fontWeight: "bold",
        color: theme.typography.color.primary
    },
    button: {
        border: `1px solid ${theme.palette.border.secondary}`,
        height: 35,
        width: 35,
        textAlign: "center"
    },
    button_icon: {
        fontSize:"1rem",
        color: theme.typography.color.tertiary
    },
    dropdown: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 6,
        cursor: "pointer",
        zIndex: 1,
        position: "relative",
        backgroundColor: "#fff",

    },
    list: {
        position: "absolute",
        zIndex: 1,
        backgroundColor: "#fff",
        width: "100%",
        left: 0,
        padding: "12px",
        borderRight: `1px solid ${theme.palette.border.secondary}`,
        borderLeft: `1px solid ${theme.palette.border.secondary}`,
        borderBottom: `1px solid ${theme.palette.border.secondary}`,
    },
    dropdown_name: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontWeight: "bold"

    },
    dropdown_user_type: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary
    },
    dropdown_icon: {
        fontSize: 20,
        color: theme.typography.color.tertiary
    },

}))

export const ProgressBarStyles = makeStyles((theme) => ({
    outerline: {
        border: `1px solid ${theme.palette.border.secondary}`,
        padding: 4,
        borderRadius: 10,
        width: 50,
        [theme.breakpoints.down('md')]: {
            width: 40,
        }

    },
    progress_level_label: {
        fontSize:"0.75rem",
        fontWeight: "bold",
        color: theme.typography.color.tertiary,
        marginInlineStart: "8px"
    }
}))

export const userListStyles = makeStyles((theme) => ({
    avatar: {
        height: 50,
        width: 50,
        borderRadius: 4
    },
    user_avatar: {
        height: 40,
        width: 40,
        borderRadius: "50%"
    },
    name: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontWeight: "bold"
    },
    role: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        marginTop: "4px"
    }
}))

export const DayCalendarStyles = makeStyles((theme) => ({
    header_timecell: {
        height: 40,
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        textTransform: "uppercase",
        borderLeft: `0.5px solid ${theme.palette.border.secondary}`,
        borderBottom: `0.5px solid ${theme.palette.border.secondary}`,
        borderTop: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    timeCell: {
        display: "flex",
        justifyContent: "center",
        minHeight: 100,
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        borderLeft: `0.5px solid ${theme.palette.border.secondary}`,
        borderBottom: `0.5px solid ${theme.palette.border.secondary}`,

    },
    header_eventCell: {
        height: 40,
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "left",
        alignItems: "center"
    },
    eventCell: {
        //height: 100,
        borderLeft: `1px solid ${theme.palette.border.secondary}`,
        borderRight: `1px solid ${theme.palette.border.secondary}`,
        borderBottom: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        //overflow: "auto"
    },
    event_label: {
        padding: 2,
        fontSize:"0.75rem",
        fontWeight: "bold",
        textTransform: "capitalize"
    },
    dot: {
        height: 5,
        width: 5,
        borderRadius: "50%",
    },
    calRow: {
        height: "calc(100vh - 300px)",
        overflow: "auto",
        width: "100%",

    },
    moreLabel: {
        fontSize:"0.75rem",
        color: theme.palette.info.main,
        fontWeight: "bold",
        marginLeft: 8,
        cursor: "pointer"
    },
    customTooltip: {
        // '& .MuiTooltip-arrow':{
        //     backgroundColor:"#fff",
        //     color:"#fff",
        //     border: '1px solid #dadde9'
        // }
        '& .MuiTooltip-arrow': {
            background: 'red',
        },
        "&:before": {
            border: "1px solid #E6E8ED"
        },

    },
    ttavatar: {
        height: 24,
        width: 24,
        marginInlineEnd: "8px"
    },
    ttTitle: {
        fontSize:"0.875rem",
        fontWeight: "bold",
        color: theme.typography.color.primary,
        textTransform: "capitalize"
    },
    ttsecTitle: {
        fontSize:"0.75rem",
        textTransform: "capitalize",
        color: theme.typography.color.secondary,
    },
    ttassigned_by: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary
    },
    ttname: {
        fontSize:"1rem",
        color: theme.typography.color.secondary,
        fontWeight: "bold"
    },
    arrow: {
        "&:before": {
            border: "1px solid #E6E8ED"
        },
        color: "white",
        position: "absolute",
        top: "0px",
        left: "0px",
        fontSize: "40px"
    },
    toolRoot: {
        border: '1px solid #E4E8EE',
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        borderRadius: "4px",

    }
}))


export const CustomDropDownStyles = makeStyles((theme) => ({
    dropdown: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 6,
        cursor: "pointer",
        zIndex: 1,
        position: "absolute",
        right: "4%",
        backgroundColor: "#fff",
        top: "16%"
    },
    selected: {
        '&:hover': {
            color: theme.palette.info.main
        }
    }
}))