import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import React from "react";
import { SelectBox, TextBox } from "../../../components/";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, FontFamilySwitch, LocalStorageKeys, NetWorkCallMethods } from "../../../utils/constants";

const useStyles = makeStyles((theme, props) => ({
  bottomTitle: {
    fontSize:"1.125rem",
    fontFamily: FontFamilySwitch().extraBold,
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "8px",
  },
  submitbtn: {
    padding: "12px",
    borderRadius: "12px",
  },
}));
const Initialstate = {
  property: "",
  roles: "",
  company: "",
  user: [],
  title: "",
  url: "",
  description: "",
  error: {
    property: "",
    roles: "",
    company: "",
    user: "",

    title: "",

    description: "",
  },
};
export const FormCommmunication = (props) => {
  const classes = useStyles();
  moment.defaultFormat = "DD MMM YY";
  const [data, setdata] = React.useState({ ...Initialstate });
  const [property, setProperty] = React.useState([]);
  const [company, setCompany] = React.useState([]);
  const [user, setuser] = React.useState([]);
  const alert = React.useContext(AlertContext);
  const roleOption = [
    {
      value: "Resident",
      label: "Resident",
    },
    {
      value: "Owner",
      label: "Owner",
    },
  ];
  const getProperty = (val) => {
    const payload = {
        user_profile_id: localStorage.getItem(LocalStorageKeys.userProfileID),
        offset: 0,
        limit: null
    };
    NetworkCall(
      `${config.api_url}/queries/access_control/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let tempProperty = response.data.data.access_control.map(
          (val) => val.property
        );
        const key = "value";
        const u_arr = [
          ...new Map(tempProperty.map((item) => [item[key], item])).values(),
        ];
        let tempPropertys = response.data.data.access_control.map(
          (val) => val.property?.company
        );

        const u_arr2 = [
          ...new Map(tempPropertys.map((item) => [item[key], item])).values(),
        ];

        setCompany(u_arr2);
        setProperty(u_arr);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
        });
      });
  };
  React.useEffect(() => {
    getProperty();
    // eslint-disable-next-line
  }, []);

  const isIamValide = () => {
    let isValid = true;
    let error = data.error;
    //Checking Property
    if (data.property.length === 0) {
      isValid = false;
      error.property = "Property Name is Required";
    }
    //Checking roles
    if (data.roles.length === 0) {
      isValid = false;
      error.roles = "Roles is Required";
    }
    //Checking Company
    if (data.company.length === 0) {
      isValid = false;
      error.company = "Company is Required";
    }
    //Checking Audience
    if (data.user.length === 0) {
      isValid = false;
      error.user = "Audience is Required";
    }
    //Checking title
    if (data.title.length === 0) {
      isValid = false;
      error.title = "Title is Required";
    }
    //Checking description
    if (data.description.length === 0) {
      isValid = false;
      error.description = "Descriptionis Required";
    }

    setdata({ ...data, error });
    return isValid;
  };

  const submit = () => {

    if (isIamValide()) {
      const payload = {
        reference_id: "notifier",
        alert_key: `${config.alertKey}`,
        push_receivers: data?.user.map((val) => val.token).flat(),
        push_title: [`${data?.title}`],
        push_body: [`${data?.description}`],
        push_data: {},
        push_click_action: "",
      };
      NetworkCall(
        `https://alertshub-api.crayond.com/api/v1/sendmessage`,
        NetWorkCallMethods.post,
        payload,
        null,
        false,
        false
      )
        .then((response) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Send Announcement Successfully",
          });
          props?.setDrawer(false);
          const payloads = {
            tenantId: `${config.tenantId}`,
            company_id: data?.company?.value,
            title: data?.title,
            description: data?.description,
            triggered_by: localStorage.getItem("profileID"),
            url: data?.url,
            users: data?.user.map((val) => val.value),
            triggered_at: new Date().toISOString(),
            property_id: data?.property?.value,
          };
          NetworkCall(
            `${config.api_url}/notifications/create`,
            NetWorkCallMethods.post,
            payloads,
            true,
            true
          )
            .then((response) => {

              props?.reload();
            })
            .catch((error) => {
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Some Thing Went Wrong",
              });
            });
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some Thing Went Wrong",
          });
        });
    } else {
      return false;
    }
  };
  const updateState = (key, value) => {
    let error = data.error;
    error[key] = "";
    setdata({ ...data, [key]: value, error });
  };
  const getuser = (id, type) => {
    const datas = {
      tenantId: `${config.tenantId}`,
      property_id: id,
      offset: 0,
      limit: 1000,
      type: type,
    };
    NetworkCall(
      `${config.api_url}/notifications/get-residents`,
      NetWorkCallMethods.post,
      datas,
      null,
      true,
      false
    )
      .then((response) => {


        const tempData = response.data.data.map((val, index) => {

          let _d;
          try {
            _d = {
              value: val.id,
              label: `${val.first_name} ${val.last_name ? val.last_name : ""}`,
              token: val.token,
            };
          } catch (err) {

          }
          return _d;
        });
        setuser(tempData);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
        });
      });
  };
  return (
    <div className={classes.root}>
      <Box display="flex" alignItems="center" className={classes.titleroot}>
        <Box flexGrow={1}>
          <Typography className={classes.bottomTitle}>
            Add Communication
          </Typography>
        </Box>
        <Box>
          <IconButton size="small" onClick={() => props?.setDrawer(false)}>
            <CloseIcon color="secondary" />
          </IconButton>
        </Box>
      </Box>
      <Grid container spacing={1} p={2}>
        <Grid item lg={4} sm={6} xs={12}>
          <SelectBox
            placeholder="Select Property Name"
            label="Property Name"
            options={property}
            isError={data?.error?.property?.length > 0}
            errorMessage={data?.error?.property}
            onChange={(value) => {
              updateState("property", value);
              getuser(value.value, "Resident");
            }}
            value={data?.property}
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <SelectBox
            placeholder="Roles"
            label="Roles"
            isError={data?.error?.roles?.length > 0}
            errorMessage={data?.error?.roles}
            options={roleOption}
            onChange={(value) => {
              updateState("roles", value);
              getuser(data?.property.value, value.value);
            }}
            value={data?.roles}
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <SelectBox
            placeholder="Select Company"
            label="Company"
            isError={data?.error?.company?.length > 0}
            errorMessage={data?.error?.company}
            options={company}
            onChange={(value) => updateState("company", value)}
            value={data?.company}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectBox
            placeholder="Audience"
            label="Audience"
            isError={data?.error?.user?.length > 0}
            errorMessage={data?.error?.user}
            onChange={(value) => updateState("user", value)}
            options={user}
            value={data?.user}
            isMulti
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextBox
            placeholder="Title"
            label="Title"
            isError={data?.error?.title?.length > 0}
            errorMessage={data?.error?.title}
            onChange={(value) => updateState("title", value.target.value)}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextBox
            placeholder="URL"
            label="URL"
            onChange={(value) => updateState("url", value.target.value)}
            value={data?.url}
          />
        </Grid>
        <Grid item xs={12}>
          <TextBox
            placeholder="Description"
            label="Description"
            multiline
            isError={data?.error?.description?.length > 0}
            errorMessage={data?.error?.description}
            onChange={(value) => updateState("description", value.target.value)}
            value={data?.description}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={submit}
            className={classes.submitbtn}
            fullWidth
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
