/* eslint-disable react-hooks/exhaustive-deps */
import { Badge, Box, Divider, Grid, IconButton, Stack } from '@mui/material';
import React from 'react';
import FilterIMG from '../../assets/filter';
import { FilterGenerator, SearchFilter, Subheader, TableWithPagination, UseDebounce } from '../../components';
import { AlertContext, AuthContext, BackdropContext } from '../../contexts';
import { accessCheckRender, AlertProps, enumName, enumSelect, enum_types, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods, useWindowDimensions, convertTimeUtcToZoneCalander, agreement_status_object } from '../../utils';
import { useStyles } from "./style";
import { Heading, Path, Type, StatusOptionList } from '../../utils/agreementRenewal/agreementRenewalUtils';
import moment from "moment";
import { config } from '../../config';
import { NetworkCall } from '../../networkcall';
import { RequestView } from './components/requestView';
import { Remarks } from './components/remarks';
import { AcceptConfirm } from './components/acceptConfirm';
import { UnitView } from './components/unitView';
import { withTranslation } from 'react-i18next';

const AgreementRenewal = ({
  t = () => false
}) => {

  const defaultRequestState = {
    id: "",
    formType: "",
    status: "",
    requestNo: "",
    agreementNo: "",
    requestedBy: "",
    requestedOn: "",
    remarks: "",
    acceptDeclineRemarks: "",
    leaseStart: "",
    leaseEnd: "",
    contactImg: "",
    contactName: "",
    contactNo: "",
    contactEmail: "",
    units: [],
    autoRenewalEscalation: "",
    renewal_type: "",
    currency: "",
    unitView: "",
    revised_auto_renewal_escalation: "",
    increment_escalation: "",
    revised_increment_escalation: "",
    lock_in_period: "",
    revised_lock_in_period: "",
    error: {
      acceptDeclineRemarks: "",
      revised_auto_renewal_escalation: "",
      revised_increment_escalation: "",
      revised_lock_in_period: "",
    }
  }

  const classes = useStyles()
  const debounce = UseDebounce()
  const size = useWindowDimensions()

  // useContext
  const backdrop = React.useContext(BackdropContext)
  const alert = React.useContext(AlertContext)
  const auth = React.useContext(AuthContext)

  // useState
  const [companyList, setCompanyList] = React.useState([])
  const [selectedCompany, setSelectedCompany] = React.useState({})
  const [list, setList] = React.useState({})
  const [searchText, setSearchText] = React.useState("")
  const [page, setPage] = React.useState(1)
  const [limit, setLimit] = React.useState(10)
  const [filterData, setFilterData] = React.useState({
    status: [true],
    agreementStatus: [agreement_status_object(t, "renewal")?.[enumName.pending]],
    dateRange: ""
  })
  const [filterDrawer, setFilterDrawer] = React.useState(false)
  const [requestDialogOpen, setRequestDialogOpen] = React.useState(false)
  const [requestState, setRequestState] = React.useState({ ...defaultRequestState })
  const [acceptDeclineDialogOpen, setAcceptDeclineDialogOpen] = React.useState(false)
  const [acceptDialogOpen, setAcceptDialogOpen] = React.useState(false)
  const [unitViewDialogOpen, setUnitViewDialogOpen] = React.useState(false)
  const [enumValue, setEnumValue] = React.useState({
    agreement_request_action: [],
    auto_renewal_escalation: []
  })
  const [permission, setPermission] = React.useState({})
  const [isDisableBtn, setIsDisableBtn] = React.useState(false)

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        getEnum()
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
          setCompanyList(company?.list)
          setSelectedCompany(company?.selected)
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  // useEffect to get agreement termination list using selected company and filter data when loading the screen
  React.useEffect(() => {
    setPage(1)
    if (selectedCompany?.value) { getList() }
  }, [selectedCompany, filterData])

  // Function to get agreement renewal list based on the input data
  const getList = (offset = 0, limit = 10, search = "", agreementStatus = [enumName.pending]) => {
    backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." })
    let startDate = filterData?.dateRange?.startDate
    let endDate = filterData?.dateRange?.endDate
    let payload = {
      company_id: selectedCompany?.value, search, offset, limit,
      status: filterData?.agreementStatus?.map((_) => _?.value) ?? agreementStatus,
      request_purpose: enumName.renewal,
      from_date: startDate && moment(startDate).format("YYYY-MM-DD"),
      to_date: endDate && moment(endDate).format("YYYY-MM-DD"),
      active: (!filterData?.status || filterData?.status?.length === 0) ?
        [true, false] : filterData?.status,
    }
    NetworkCall(
      `${config.api_url}/agreement_request/list`,
      NetWorkCallMethods.post,
      payload, null, true, false
    ).then((r) => {
      setList({
        data: r?.data?.data ?? [],
        totalRowsCount: r?.data?.count
      })
      backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
    }).catch((error) => {
      backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
      alert.setSnack({
        ...alert, open: true, msg: t("Some Thing Went Wrong"),
        severity: AlertProps.severity.error
      })
    })
  }

  // Function to get Enum value
  const getEnum = async () => {
    const result = await enumSelect([enum_types.agreement_request_action, enum_types?.auto_renewal_escalation]);

    let agreement_request_action = result?.agreement_request_action?.map((_) => {
      return agreement_status_object(t, "renewal")?.[_?.value ?? "default"]
    });

    let auto_renewal_escalation = result?.auto_renewal_escalation?.filter?.((_) =>
      !["RERA Index Chart", "Escalation %"]?.includes(_?.value));

    setEnumValue({ agreement_request_action, auto_renewal_escalation });
  }

  // Set row data for table
  const Rows = React.useCallback(list?.data?.map((_) => {
    let j
    try {
      j = {
        id: _?.id,
        requestNo: _?.reference_id ?? "-",
        agreementNo: _?.agreement_no ?? "-",
        requestedBy: _?.created_by ?? "-",
        requestedOn: convertTimeUtcToZoneCalander(_?.created_at),
        noOfUnits: _?.no_of_units ?? "-",
        period: _?.period ?? "-",
        renewal_period: (_?.renewal_period_from_date || _?.renewal_period_to_date)
          ? `${convertTimeUtcToZoneCalander(_?.renewal_period_from_date)}${(_?.renewal_period_from_date && _?.renewal_period_to_date) && ` - `}${convertTimeUtcToZoneCalander(_?.renewal_period_to_date)}`
          : "-",
        renewalType: _?.renewal_type ?? "-",
        incrementPercentage: _?.custom_increment_escalation ?? _?.increment_escalation ?? "-",
        status: agreement_status_object(t, "renewal")?.[_?.status ?? "default"],
        data: _
      }
    } catch (err) {
      alert.setSnack({
        ...alert, open: true,
        severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
      })
    }
    return j
  }), [list])

  // Function to change the company
  const handleCompanyChange = (value) => {
    setSelectedCompany(value)
  }

  // Function for search in search component
  const handleSearch = (e) => {
    setSearchText(e)
    debounce(() => searchTableFunction(e), 800)
  }

  // Function to search data in agreement request list
  const searchTableFunction = (e) => {
    if (page > 1) { setPage(1) }
    getList(0, limit, e)
  }

  // Function to handle pagination in table
  const handleTablePagination = (value) => {
    setPage(value)
    let offset = (value - 1) * limit
    getList(offset, limit, searchText)
  }

  // Function to handle page limit in table
  const handleTablePageLimit = (value) => {
    setLimit(value)
    setPage(1)
    getList(0, value, searchText)
  }

  // Function to update agreementRequestState
  const updateRequestDialogState = (k, v) => {
    let error = requestState?.error
    error[k] = ""
    setRequestState({ ...requestState, [k]: v, error })
  }

  // Function for updating addNewState
  const validate = () => {
    let isValid = true
    let error = requestState.error
    if (requestState?.acceptDeclineRemarks?.length === 0) { isValid = false; error.acceptDeclineRemarks = t("Remarks is Required") }
    if (!isValid) {
      alert.setSnack({
        ...alert, open: true,
        severity: AlertProps.severity.error,
        msg: t("Please fill all mandatory field"),
      })
    }
    setRequestState({ ...requestState, error })
    return isValid
  }


  // Function to accept/decline a agreement request
  const handleAcceptDecline = () => {
    if (validate()) {
      setIsDisableBtn(true)
      backdrop.setBackDrop({
        ...backdrop, open: true,
        message: `${requestState?.formType === "Accept" ? "Accepting" : "Declining"} the request`,
      });
      let data = requestState?.data
      let unitPrice = requestState?.unitPrice
      let agreementUnits = unitPrice?.units?.map((_) => {
        let agreementRentBreakup = _?.pricing?.filter((val) => {
          if (requestState?.revised_auto_renewal_escalation?.value === "Latest Price")
            return !val?.pricing_component?.[0]?.is_late_fee && !val?.is_wallet_item && !val?.pricing_component?.[0]?.is_commission_payment && !val?.isQuantity;
          else
            return !val?.pricing_component?.[0]?.is_wallet && !val?.pricing_component?.[0]?.is_late_fee && !val?.is_wallet_item && !val?.pricing_component?.[0]?.is_commission_payment && !val?.isQuantity;
        })?.map((i) => {
          return {
            unit_id: i?.unitid,
            rent_breakup_id: i?.PCid,
            // period_type: null,
            // lease_period:null,
            rent_amount: i?.componentvalue,
            primary: i?.primary,
            refundable: i?.refundable,
            taxable: i?.taxable,
            is_active: true,
            created_by: data?.agreement?.created_by,
            updated_by: data?.agreement?.updated_by,
            vat_group_id: i?.vatGroupId,
            currency_id: i?.currencyId,
            property_id: i?.propertyId,
            company_id: i?.companyId,
            client: data?.agreement?.client,
            applied_value: i?.componentvalue + i?.appliedTaxAmount,
            tax: i?.appliedTaxAmount,
            tax_percentage: i?.value,
            before_rent_amount: i?.componentvalue,
            before_tax: i?.appliedTaxAmount,
            component_value: i?.value,
            is_one_time: i?.isOnetime,
            is_quantity: i?.isQuantity,
            quantity: i?.quantity,
            payment_period: i?.paymentPeriod,
            value_basis_type: i?.valueBasisType,
            uom_type: i?.uom_type,
            is_wallet: i?.is_wallet,
            discount_percentage: i?.displayPercentage ?? 0,
            is_wallet_item: i?.is_wallet_item,
            inspection_item: i?.inspection_id
          }
        })
        return {
          unit_id: _?.id,
          unit_total_primary: _?.totalPricing,
          unit_total_refundable: _?.unitTotalRefundable,
          // unit_total_others: null,
          is_active: true,
          created_by: data?.agreement?.created_by,
          updated_by: data?.agreement?.updated_by,
          is_external: false,
          contact_id: data?.agreement?.contact_id,
          client: data?.agreement?.client,
          agreement_unit_users: [
            {
              contact_id: data?.agreement?.contact_id,
              is_active: true,
              created_by: data?.agreement?.created_by,
              updated_by: data?.agreement?.updated_by,
              client: data?.agreement?.client,
            }
          ],
          agreement_rent_breakup: agreementRentBreakup
        }
      })
      let agreement = {
        ...data?.agreement,
        agreement_no: undefined,
        created_at: undefined,
        updated_at: undefined,
        lease_start_date: data?.renewal_period?.from_date,
        lease_end_date: data?.renewal_period?.to_date,
        id: undefined,
        parent_agreement_id: data?.agreement?.id,
        account_name: undefined,
        agreement_units: agreementUnits,
      }
      const payload = {
        request_id: requestState?.id,
        status: requestState?.formType === "Accept" ? "Approved" : "Rejected",
        manager_remarks: requestState?.acceptDeclineRemarks,
        auto_renewal_escalation_type: requestState?.revised_auto_renewal_escalation?.value,
        is_agreement_lock: requestState?.revised_lock_in_period
      }

      if (requestState?.revised_auto_renewal_escalation?.value === "Latest Price") {
        payload["latest_price"] = agreement
      }

      if (requestState?.revised_auto_renewal_escalation?.value === "Increment %"
        && requestState?.revised_increment_escalation?.length > 0) {
        payload["increment_escalation"] = requestState?.revised_increment_escalation
      }

      NetworkCall(
        `${config.api_url}/agreement/renewal`,
        NetWorkCallMethods.post,
        payload, null, true, false
      ).then((_) => {
        alert.setSnack({
          ...alert, open: true, severity: AlertProps.severity.success,
          msg: `${t("Agreement Renewal Request")} ${requestState?.formType === "Accept" ? t("Accepted") : t("Declined")}`,
        });
        getList()
        setAcceptDeclineDialogOpen(false)
        setRequestDialogOpen(false)
        setAcceptDialogOpen(false)
        setRequestState({ ...defaultRequestState })
        setIsDisableBtn(false)
        backdrop.setBackDrop({ ...backdrop, open: false, message: "", })
      }).catch(() => {
        backdrop.setBackDrop({ ...backdrop, open: false, message: "", })
        alert.setSnack({
          ...alert, open: true, msg: t("Some Thing Went Wrong"),
          severity: AlertProps.severity.error,
        })
        setIsDisableBtn(false)
      })
    } else { return false }
  }

  // Function to handle icon in table row
  const handleTableIcon = (type, data) => {
    backdrop.setBackDrop({ ...backdrop, open: true, message: t("Loading Request"), });
    let payload = { request_id: data?.id, is_renewal: true }
    NetworkCall(
      `${config.api_url}/agreement_request`,
      NetWorkCallMethods.post,
      payload, null, true, false
    ).then((res) => {
      let _ = res?.data?.data
      let tempStatus = agreement_status_object(t, "renewal")?.[_?.agreement_request?.status ?? "default"]
      setRequestState({
        id: _?.agreement_request?.id ?? "",
        agreement_id: _?.agreement?.id,
        status: tempStatus ?? "",
        requestNo: _?.agreement_request?.reference_id ?? "-",
        agreementNo: _?.agreement?.agreement_no ?? "-",
        requestedBy: _?.agreement_request?.requested_by?.name ?? "-",
        requestedOn: _?.agreement_request?.created_at ?
          convertTimeUtcToZoneCalander(_?.agreement_request?.created_at) : "-",
        acceptDeclineRemarks: _?.agreement_request?.remarks ?? "-",
        leaseStart: _?.agreement?.lease_start_date ?
          convertTimeUtcToZoneCalander(_?.agreement?.lease_start_date) : "-",
        leaseEnd: _?.agreement?.lease_end_date ?
          convertTimeUtcToZoneCalander(_?.agreement?.lease_end_date) : "-",
        contactImg: _?.contact?.image_url ?? "",
        contactName: _?.contact?.name ?? "-",
        contactNo: _?.contact?.mobile_no ?
          (_?.contact?.mobile_no_country_code + " " + _?.contact?.mobile_no) : "-",
        contactEmail: _?.contact?.email_id ?? "",
        units: _?.agreement_units?.map((i) => {
          return {
            ...i,
            iconToolTip: (tempStatus.value !== enumName.approved &&
              i?.is_occupied) && "This Unit Is Occupied."
          }
        }) ?? [],
        occupiedUnits: _?.agreement_units?.filter((i) => i?.is_occupied === true) ?? [],
        autoRenewalEscalation: enumValue?.auto_renewal_escalation?.find(x => x?.value === _?.agreement?.auto_renewal_escalation) ?? "",
        currency: _?.currency?.symbol ?? "",
        accountNo: _?.agreement?.account_id ?? "-",
        accountName: _?.agreement?.account_name ?? "-",
        approvedBY: _?.agreement_request?.approved?.manager_name ?? "-",
        managerRemarks: _?.agreement_request?.manager_remarks ?? "-",
        updatedOn: _?.agreement_request?.updated_at ?
          moment(_?.agreement_request?.updated_at).format("DD MMM YYYY") : "-",
        data: _,
        error: defaultRequestState?.error,
        renewal_type: _?.renewal_type,
        revised_auto_renewal_escalation: _?.renewed_agreement?.auto_renewal_escalation
          ? (enumValue?.auto_renewal_escalation?.find(x => x?.value === _?.renewed_agreement?.auto_renewal_escalation) ?? "")
          : _?.renewal_type === "Custom"
            ? (enumValue?.auto_renewal_escalation?.find(x => x?.value === "Latest Price") ?? "")
            : (enumValue?.auto_renewal_escalation?.find(x => x?.value === _?.agreement?.auto_renewal_escalation) ?? ""),
        increment_escalation: _?.agreement?.increment_escalation ?? "",
        revised_increment_escalation: _?.renewed_agreement?.increment_escalation ?? _?.agreement?.increment_escalation ?? "",
        custom_increment_escalation: _?.agreement_request?.custom_increment_escalation ?? "",
        lock_in_period: _?.agreement?.is_agreement_lock,
        revised_lock_in_period: _?.renewed_agreement?.is_agreement_lock ?? _?.agreement?.is_agreement_lock,
        renewed_agreement_no: _?.renewed_agreement?.agreement_no ?? t("yet to be generated"),
      })
      setRequestDialogOpen(true)
      backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
    }).catch((error) => {
      backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
      alert.setSnack({
        ...alert, open: true,
        severity: AlertProps.severity.error,
        msg: t("Internal error. Please try again later."),
      });
    });
  }

  const priorValidate = () => {
    let isValid = true
    let error = requestState.error
    if (requestState?.revised_auto_renewal_escalation?.value?.length === 0) {
      isValid = false;
      error.revised_auto_renewal_escalation = "Required";
    }
    if (requestState?.revised_auto_renewal_escalation?.value === "Increment %" &&
      requestState?.revised_increment_escalation?.length === 0) {
      isValid = false;
      error.revised_increment_escalation = "Required";
    }
    if (!isValid) {
      alert.setSnack({
        ...alert, open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all mandatory field",
      })
    }
    setRequestState({ ...requestState, error })
    return isValid
  }

  const getUnitPrice = () => {
    if (priorValidate()) {
      backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading", })
      setRequestDialogOpen(false)
      setAcceptDeclineDialogOpen(false)
      let payload = {}
      if (requestState?.revised_auto_renewal_escalation?.value === "Latest Price") {
        payload = {
          unitId: requestState?.units?.map?.((_) => _?.id),
          startDate: requestState?.data?.renewal_period?.from_date,
          endDate: requestState?.data?.renewal_period?.to_date,
          gracePeriod: requestState?.data?.agreement?.grace_period ?? "",
          revenue_type: requestState?.data?.agreement?.revenue_type ?? "",
          noRefundable: true,
          noOneTime: true
        }
      } else {

        payload = {
          agreement_id: requestState?.agreement_id,
          auto_renewal_escalation_type: requestState?.revised_auto_renewal_escalation?.value,
          increment_escalation: requestState?.revised_increment_escalation,
        }
      }

      NetworkCall(
        `${config.api_url}${requestState?.revised_auto_renewal_escalation?.value === "Latest Price" ? "/quotation/unitprice" : "/agreement/agreement_renewal_pricing"}`,
        NetWorkCallMethods.post,
        payload, null, true, false
      ).then((res) => {
        let _ = res?.data?.data
        setRequestState({
          ...requestState,
          formType: "Accept",
          acceptDeclineRemarks: "Agreement Renewed",
          unitPrice: _,
          error: defaultRequestState?.error
        })
        setAcceptDialogOpen(true)
        backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
      }).catch((error) => {
        backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
        alert.setSnack({
          ...alert, open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
      });
    }
  }

  const render = () => {
    return <>
      <Subheader hideBackButton={true} title={t("Agreement Renewal")}
        select options={companyList} value={selectedCompany} onchange={(e) => handleCompanyChange(e)} />
      <div className={classes.root}>
        <Grid container className={classes.content}>
          <Grid item xs={4}>
            <SearchFilter value={searchText} placeholder={t("Search Agreement")}
              handleChange={(value) => handleSearch(value)}
              customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
            />
          </Grid >
          <Grid item xs={8}>
            <Box display={"flex"} justifyContent={"end"}>
              <Stack direction="row" spacing={2}
                divider={<Divider orientation="vertical" flexItem />}>
                <IconButton onClick={() => setFilterDrawer(!filterDrawer)}
                  className={classes.filterButton}>
                  <Badge variant="dot" color="primary"
                    invisible={!(filterData.status?.length > 0 || filterData?.dateRange)}>
                    <FilterIMG color="#091b29" />
                  </Badge>
                </IconButton>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TableWithPagination
              heading={Heading(t)}
              rows={Rows}
              path={Path}
              requestState showpagination={true}
              showpdfbtn={false}
              showexcelbtn={false}
              showSearch={false}
              onClick={() => console.log("")}
              tableType="no-side"
              dataType={Type}
              handleIcon={handleTableIcon}
              handlePagination={handleTablePagination}
              handleChangeLimit={handleTablePageLimit}
              totalRowsCount={list?.totalRowsCount}
              page={page}
              limit={limit}
              height={'calc(100vh - 290px)'}
              view={permission?.read}
              edit={permission?.update}
              delete={permission?.delete} />
          </Grid>
        </Grid >
        <FilterGenerator open={filterDrawer} onClose={() => setFilterDrawer(false)}
          onApply={(value) => setFilterData(value)}
          components={[
            {
              component: "toggleButton",
              value: filterData?.status,
              options: StatusOptionList(t),
              isMulti: true,
              state_name: "status",
              label: t("Status")
            },
            {
              component: "select",
              value: filterData?.agreementStatus,
              options: enumValue?.agreement_request_action,
              isMulti: true,
              state_name: "agreementStatus",
              label: t("Agreement Status"),
              placeholder: t("Select Agreement Status")
            },
            {
              component: "date_range",
              value: filterData?.dateRange,
              state_name: "dateRange",
              label: t("Requested On"),
              startPlaceholder: t("Select Start Date"),
              endPlaceholder: t("Select End Date")
            },
          ]} />
        <RequestView
          t={t}
          permission={permission}
          requestDialogOpen={requestDialogOpen}
          setRequestDialogOpen={setRequestDialogOpen}
          setAcceptDeclineDialogOpen={setAcceptDeclineDialogOpen}
          requestState={requestState}
          setRequestState={setRequestState}
          getUnitPrice={getUnitPrice}
          updateRequestDialogState={updateRequestDialogState}
          options={enumValue} />
        <Remarks
          t={t}
          acceptDeclineDialogOpen={acceptDeclineDialogOpen}
          setAcceptDeclineDialogOpen={setAcceptDeclineDialogOpen}
          setRequestDialogOpen={setRequestDialogOpen}
          requestState={requestState}
          setRequestState={setRequestState}
          updateRequestDialogState={updateRequestDialogState}
          handleAcceptDecline={handleAcceptDecline}
          isDisableBtn={isDisableBtn} />
        <AcceptConfirm
          t={t}
          acceptDialogOpen={acceptDialogOpen}
          setAcceptDialogOpen={setAcceptDialogOpen}
          setRequestDialogOpen={setRequestDialogOpen}
          setAcceptDeclineDialogOpen={setAcceptDeclineDialogOpen}
          setUnitViewDialogOpen={setUnitViewDialogOpen}
          requestState={requestState}
          setRequestState={setRequestState}
          handleAcceptDecline={handleAcceptDecline}
          windowHeight={size?.height}
          isDisableBtn={isDisableBtn} />
        <UnitView
          t={t}
          unitViewDialogOpen={unitViewDialogOpen}
          setUnitViewDialogOpen={setUnitViewDialogOpen}
          setAcceptDialogOpen={setAcceptDialogOpen}
          setRequestDialogOpen={setRequestDialogOpen}
          setAcceptDeclineDialogOpen={setAcceptDeclineDialogOpen}
          requestState={requestState}
          setRequestState={setRequestState}
          updateRequestDialogState={updateRequestDialogState}
          handleAcceptDecline={handleAcceptDecline}
          windowHeight={size?.height} />
      </div >
    </>
  }

  return <div>
    {accessCheckRender(render, permission)}
  </div>
}
export default withTranslation("agreement")(AgreementRenewal)
