import { Close } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { DeleteIcon } from "../../../assets/deleteIconResident";
import { SelectBox } from "../../../components";
import { stringAvatar, useWindowDimensions } from "../../../utils";
import { ChooseCardStyle } from "./style";


export const ChooseCard = ({
    header = "",
    options = [],
    showRemove = false,
    visible = true,
    handleRemove = () => false,
    title = "",
    subTitle = "",
    noSelectedTitle = "",
    icon = {},
    topHeader = "",
    onchange = () => false,
    value = [],
    onDelete = () => false,
    loadOptions = () => false,
    error = "",
    errorMsg = "",
    isRequired = true,
    isMulti = false,
    key,
    onDeleteMuti = () => false,
    onDeleteMutiCheck = () => false,
    menuPlacement = 'auto',
    border = "",
    isReadOnly = false,
    showCard = true,
    placeholder = "",
    boldHeader = false,
    isBorder = false,
    is_toggle = false,
    state1,
    setState1 = () => false,
    t = () => false,
    tabValue = 1,
    setTabValue = () => false,
    handleTabChange = () => false
}) => {
    const classes = ChooseCardStyle({ border })
    const language = localStorage.getItem("i18nextLng");
    const size = useWindowDimensions()

    const customStyles = {
        control: base => ({
            ...base,
            height: 200,
            minHeight: 200
        })
    };

    const multiFunction = (data) => {
        // console.log(value,data,"how")
        // if (!value?.map((x) => x?.value)?.includes(data?.value)) {
        //     onchange(data)
        // }
        onchange(data)
    }
    return (
        <>
            {
                visible &&
                <Stack spacing={1}>
                    {
                        (boldHeader || header) &&

                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>

                            {boldHeader ?
                                <Typography className={classes.boldHeader}>{header}{isRequired && <Typography variant="caption" style={{ color: "red", marginLeft: 4 }}>
                                    *
                                </Typography>}</Typography>
                                :
                                <Typography className={showRemove ? classes.boldHeader : classes.header}>{header}{isRequired && <Typography variant="caption" style={{ color: "red", marginLeft: 4 }}>
                                    *
                                </Typography>}</Typography>
                            }

                            {showRemove && <Typography className={classes.remove} onClick={handleRemove}>{t("Remove")}</Typography>}
                        </Stack>


                    }


                    <Stack spacing={1} className={classes.box} border={isBorder ? "1px solid #CED3DD" : "none"} p={isBorder ? "14px" : "none"}>
                        <Box className={classes.tabSwitchBlock}>
                            <Grid container spacing={2} alignItems={"center"} sx={{ cursor: "pointer" }}>
                                <Grid item xs={12} sm={6} md={6} lg={6} onClick={() => handleTabChange(1)}>
                                    <Box className={tabValue === 1 ? classes.selectedTabBox : classes.unSelectedTabBox}>
                                        <Typography className={classes.tabSelecttxt} sx={{ color: tabValue === 1 ? "#5078E1" : "#091B29" }}>{t("Profession Based Search")}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} onClick={() => handleTabChange(2)} sx={{ cursor: "pointer" }}>
                                    <Box className={tabValue === 2 ? classes.selectedTabBox : classes.unSelectedTabBox}>
                                        <Typography className={classes.tabSelecttxt} sx={{ color: tabValue === 2 ? "#5078E1" : "#091B29" }}>{t("Role Based Search")}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>


                        <SelectBox
                            // isRequired={true}
                            key={key}
                            label={''}
                            menuOptionHeight={'290px'}
                            menuPlacement={menuPlacement}
                            placeholder={placeholder}
                            borderRadius={"8px"}
                            // options={options}
                            loadOptions={(search, array, handleLoading) => loadOptions(search, array, handleLoading)}
                            isPaginate={true}
                            debounceTimeout={800}
                            styles={customStyles}
                            isReadOnly={isReadOnly}
                            noSearch={false}
                            color={"#FFFFFF"}

                            //             left={'10px'}
                            //             prefix={
                            //                 <SearchIMG
                            //   color="#a4b2c1"
                            //   style={{ color: "#999999" }}
                            // />
                            //             }
                            onChange={(value) => {

                                isMulti ? multiFunction(value) : onchange(value)
                            }}
                            // loading={true}
                            value={isMulti ? null : value}
                            selectHeight="38px"
                            zeroMargin={true}
                            customOptionComponent={(props) => {
                                return (
                                    <Box marginTop="8px" className={isMulti ? classes.selectMultiParent : classes.selectParent} style={{ border: (props?.isSelected && !isMulti) ? "1px solid #5078E1" : "1px solid #E4E8EE", backgroundColor: (props?.isSelected && !isMulti) ? "#F1F7FF" : "#fff" }}>
                                        <Stack direction={"row"} spacing={2} alignItems={"center"} justifyContent={'space-between'}>
                                            <Stack direction={"row"} p={0.5} alignItems={"center"}>
                                                <Avatar className={classes.optionAvatar} src={props?.data?.assets?.[0]?.url ?? null} {...stringAvatar(props?.data?.resource_name)}></Avatar>
                                                <Stack marginInlineStart={'8px'}>
                                                    <Typography className={classes.resourceName}>{props?.data?.resource_name ?? ""}</Typography>
                                                    <Stack direction="row" spacing={props?.data?.profession_name !== null?1:0} alignItems="center">
                                                        <Typography className={classes.jobName}>t({props?.data?.profession_name})</Typography>
                                                        {props?.data?.profession_name !== null &&
                                                            <Box className={classes.dot}></Box>
                                                        }
                                                        <Typography className={classes.jobName}>t({props?.data?.job_name})</Typography>

                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                            <Box>
                                                {
                                                    isMulti && (
                                                        (value?.length > 0 && value?.filter((z)=>{return z?.is_active})?.map((x) => x?.value).includes(props?.data?.value)) ? <CheckCircleIcon sx={{ color: "#5078E1" }} /> : false
                                                    )

                                                    // <Checkbox
                                                    //     onChange={(event) => {
                                                    //         onDeleteMutiCheck(event?.target?.checked ? [...value , props?.data] : value?.filter((x)=> x?.value !== props?.data?.value))
                                                    //         event.stopPropagation()
                                                    //     }}
                                                    //     checked={value?.length > 0 ? value?.map((x) => x?.value).includes(props?.data?.value) ? true : false : false}
                                                    //     icon={<RadioButtonUncheckedIcon sx={{ color: "#E4E8EE" }} />}
                                                    //     checkedIcon={<CheckCircleIcon sx={{ color: "#5078E1" }} />} />
                                                }
                                            </Box>
                                        </Stack>
                                    </Box>
                                )
                                // <div style={{ padding: "8px", backgroundColor: props?.isSelected ? "red" : "white" }}>{`${props?.data?.label} - ${props?.isSelected ? "Selected" : "Unselected"}`}</div>
                            }}
                        />


                    </Stack>
                    <Box mt={1} height={size?.height-255} overflow="scroll">
                        {
                            isMulti ? (
                                value?.length > 0 ?
                                    value?.filter((x)=>{return x?.is_active})?.map((element, i) => {
                                        return (
                                            <Stack mt={1} direction={"row"} p={0.5} alignItems={"center"} className={classes.choosecardsMuti}>
                                                <Avatar src={element?.assets?.[0]?.url} className={classes.optionAvatar} {...stringAvatar(element?.label)} ></Avatar>
                                                <Stack marginInlineStart={'8px'}>
                                                    <Typography className={classes.resourceName}>{element?.resource_name ?? ""}</Typography>
                                                    <Stack direction="row" spacing={element?.profession_name !== null?1:0} alignItems="center">
                                                        <Typography className={classes.jobName}>{element?.profession_name}</Typography>
                                                        {element?.profession_name !== null &&
                                                            <Box className={classes.dot}></Box>
                                                        }
                                                        <Typography className={classes.jobName}>{element?.job_name}</Typography>

                                                    </Stack>
                                                </Stack>
                                                {
                                                    !isReadOnly &&
                                                    <Close style={{ cursor: "pointer", color: "#7C8594", position: "absolute", left: language === "ar" && 3, right: language !== "ar" && 3 }} onClick={() => onDeleteMuti(element)} />
                                                }
                                            </Stack>
                                        )
                                    })
                                    :
                                    <center>
                                        <Stack alignItems={"center"} justifyContent={"center"}>
                                            {/* <NoSelectionIcon /> */}
                                            <Typography className={classes.subTitle}>{t(noSelectedTitle)}</Typography>
                                        </Stack>
                                    </center>
                            )
                                :
                                (
                                    value ? <Stack direction={"row"} p={0.5} alignItems={"center"} className={classes.choosecards}>
                                        <Avatar className={classes.optionAvatar} {...stringAvatar(value?.label)} src={value?.data?.assets?.[0]?.url}></Avatar>
                                        <Stack sx={{ marginInlineStart: "8px" }}>
                                            <Typography className={classes.optionHeader}>{t(value?.label ?? "")}</Typography>
                                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                                <Typography className={classes.optionSubTitle} noWrap>{t(value?.mobileNo || value?.label1)} {value?.uom_master?.name || value?.uom?.name}</Typography>
                                                {/* {getTrueCheck((value?.mobileNo || value?.label1), (value?.email)) && <Box className={classes.dot}></Box>} */}
                                                <Typography className={classes.optionSubTitle} noWrap>{t(value?.email)}</Typography>
                                            </Stack>
                                        </Stack>
                                        {
                                            !isReadOnly &&
                                            <DeleteIcon style={{ cursor: "pointer", position: "absolute", left: language === "ar" && 3, right: language !== "ar" && 3 }} onClick={onDelete} />
                                        }
                                    </Stack> : <center>
                                        <Stack alignItems={"center"} justifyContent={"center"}>
                                            {/* <NoSelectionIcon /> */}
                                            <Typography className={classes.subTitle}>t({noSelectedTitle})</Typography>
                                        </Stack>
                                    </center>
                                )

                        }
                    </Box>
                </Stack >
            }
            {
                error && (
                    <Typography variant={"caption"} color={"error"}>
                        t({errorMsg})
                    </Typography>
                )
            }
        </>
    )
}