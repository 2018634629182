import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Typography } from "@mui/material";
import React from "react";
import Select, { components } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { FontFamilySwitch } from '../../utils';
import { EditIcon } from '../svg';

export const CustomAsyncWithIcons = React.memo((props) => {

    const {
        key,
        value,
        placeholder,
        loadOptions,
        onChange,
        options,
        loading,
        isReadOnly,
        isMulti,
        debounceTimeout,
        reduceOptions,
        isClearable = true,
        styles = {},
        isPaginate = false,
        icon = "",
        isError,
        errorMessage
    } = props;

    const Control = ({ children, ...props }) => {

        return (
            <components.Control {...props}>
                <img src={icon} alt="" />
                {children}
            </components.Control>
        );
    };

    const DropdownIndicator = (props, icon) => {
        return (
            <components.DropdownIndicator {...props}>
                <ArrowDropDownIcon style={{ fontSize: "2.6rem", color: "#98A0AC" }} />
                {/* {icon && icon} */}
            </components.DropdownIndicator>
        );
    };


    const customStyles = {

        control: (base) => ({
            ...base,
            ...styles?.control ?? {},
            height: "45px",
            border: 0,
            boxShadow: 'none',

        }),
        placeholder: defaultStyles => {
            return {
                ...defaultStyles,
                fontFamily: FontFamilySwitch().regular,
                fontSize:"0.875rem",
                whiteSpace: "nowrap",
                color: "#98A0AC"

            };
        },
        menu: defaultStyles => {
            return {
                ...defaultStyles,
                //zIndex: 2,
            };
        },
        dropdownIndicator: (base, prop) => {
            return {
                ...base,
                transform: prop.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)',
                transition: '0.25s',
                marginRight: "-4px",
                padding: "0px"
            }
        },
        option: (base, state) => ({
            ...base,
            color: "#1e2022",
            backgroundColor: state.isSelected ? "rgba(189,197,209,.3)" : "white",
            padding: ".5rem 3rem .5rem .5rem",
            cursor: "pointer",
        }),

    };

    return (
        <>
            {
                isPaginate ? (
                    <AsyncPaginate
                        key={key}
                        isClearable={isClearable}
                        isSearchable
                        components={{
                            IndicatorSeparator: () => null,
                            Control,
                            DropdownIndicator
                        }}
                        value={value}
                        placeholder={placeholder}
                        loadOptions={loadOptions}
                        onChange={onChange}
                        options={options}
                        isLoading={loading}
                        defaultOptions={options}
                        styles={customStyles}
                        isDisabled={isReadOnly}
                        isMulti={isMulti}
                        debounceTimeout={debounceTimeout}
                        reduceOptions={reduceOptions}
                        menuPlacement={"auto"}
                        minMenuHeight={"150px"}

                    />
                ) : (
                    <Select
                        //isClearable={isClearable}
                        isSearchable
                        components={{
                            IndicatorSeparator: () => null,
                            Control,
                            DropdownIndicator: (val) => DropdownIndicator(val, <EditIcon />)
                        }}
                        value={value}
                        getOptionLabel={option => option.label}
                        getOptionValue={option => option.value}

                        placeholder={placeholder}
                        options={options}
                        isLoading={loading}
                        onChange={onChange}
                        styles={customStyles}
                        isMulti={isMulti}
                        isDisabled={isReadOnly}
                        menuPlacement={"auto"}

                    />


                )
            }
            {isError && (
                <Typography variant={"caption"} color={"error"}>
                    {errorMessage}
                </Typography>
            )}
        </>

    )
})
