import { Box, Grid, List, ListItem, Typography } from "@mui/material";
import React from "react";
import { DocumentViewer } from "../../../components/fileViewer/index";
import { unitStyles } from "../style";
import { assestType } from "../../../utils/common";
import NoDataFound from "../../../assets/noData";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
import { NetWorkCallMethods } from "../../../utils";
export const Assets = (props) => {
  const classes = unitStyles();
  const [value, setValue] = React.useState(1);
  const [listing, setlisting] = React.useState([]);
  React.useEffect(() => {
    NetworkCall(
      `${config.api_url}/queries/asset_master/get`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    ).then((res) => {
      let tempAssetsMaster = res?.data?.data?.asset_master;
      setlisting(tempAssetsMaster);
    });
    // eslint-disable-next-line
  }, []);

  const handleList = (val) => {
    setValue(val);
  };
  const getVideoId = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url?.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  };
  return (
    <Box className={classes.imagebox1}>
      <>
        <List style={{ display: "flex" }} className={classes.list}>
          {listing
            .filter((x) => x?.id !== assestType?.Thumbnails)
            .map((val) => {
              return (
                <ListItem
                  className={val.id === value ? classes.listText : classes.list}
                  onClick={() => handleList(val.id)}
                >
                  <Typography className={classes.tabname}>
                    {val.type}
                  </Typography>
                </ListItem>
              );
            })}
        </List>
        <div style={{ marginTop: "8px" }}>
          {props?.asset?.filter((i) => i?.asset_type === value)?.length ? (
            <Grid container>
              {props?.asset
                ?.filter((x) => x?.id !== assestType?.Thumbnails)
                .map((val) => {
                  const url = getVideoId(val.url);

                  return (
                    <>
                      {val?.asset_type === value && (
                        <Grid
                          item
                          xs={2}
                          style={{
                            margin: "4px",
                            borderRadius: "12px",
                            overflow: "hidden",
                          }}
                        >
                          {val?.asset_type === assestType?.Documents ? (
                            <div style={{ height: "150px", overflow: "auto" }}>
                              <DocumentViewer url={val?.url} />
                            </div>
                          ) : (
                            <>
                              {val?.asset_type === assestType?.Videos ? (
                                <iframe
                                  title={val?.file_meta?.name}
                                  className={classes.video}
                                  src={"https://www.youtube.com/embed/" + url}
                                ></iframe>
                              ) : (
                                // <video
                                //     style={{
                                //         objectFit: "cover",
                                //         height: "150px",
                                //         width: "100%",
                                //         borderRadius: "12px",
                                //     }}
                                //     controls
                                // >
                                //     <source src={val.url} type="video/mp4" />
                                // </video>
                                <img
                                  src={val.url}
                                  alt=""
                                  style={{
                                    objectFit: "cover",
                                    height: "150px",
                                    width: "100%",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </Grid>
                      )}
                    </>
                  );
                })}
            </Grid>
          ) : (
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <NoDataFound />
            </Box>
          )}
        </div>
      </>
    </Box>
  );
};
