import { Box, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { PaymentCardStyle } from './style'
import SettleOfflineSvg from '../../../assets/setttleOffline';
import OnlinePaymentSvg from '../../../assets/onlinePayment';
import { withTranslation } from 'react-i18next';

const PaymentCard = ({ type = "", click = () => false,t }) => {
    const classes = PaymentCardStyle()
    return (
        <Box m={2} className={classes.box} p={2} onClick={click}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    {type === "offline" ? <SettleOfflineSvg /> : <OnlinePaymentSvg />}
                    <Stack>
                        <Typography className={classes.title}>{type === "offline" ? t("Settleoffline") : t("BillagainstTenantORResidentaccount")}</Typography>
                        <Typography className={classes.content}>{type === "offline" ? t("Paymentcollectedfromofflinemode") : t("OnlinepaymentlinkAndwilladdagaintunitinvoice")}</Typography>
                    </Stack>
                </Stack>
                <IconButton className={classes.iconbtn}>
                    <KeyboardArrowRightIcon />
                </IconButton>
            </Stack>
        </Box>
    )
}

export default withTranslation("amenityBooking")(PaymentCard)