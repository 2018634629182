import { Close } from '@mui/icons-material';
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { withNavBars } from '../../HOCs';
import { AlertDialog, Subheader } from '../../components';
import { NewLoader } from '../../components/newLoader';
import { config } from '../../config';
import { AlertContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { AlertProps, NetWorkCallMethods, FontFamilySwitch } from '../../utils';
import { CorrespondanceDatatype, CorrespondanceHeading, CorrespondancePath, CreditNoteDatatype, CreditNoteHeading, CreditNotePath, DebitNoteDatatype, DebitNoteHeading, DebitNotePath, PostedInvoiceDatatype, PostedInvoiceHeading, PostedInvoicePath, ProertyUnitHeading, ProertyUnitPath, PropertyUnitDatatype, ReceiptSettlementDatatype, ReceiptSettlementHeading, ReceiptSettlementPath, RefundableDatatype, RefundableHeading, RefundablePath, ScheduledDatatype, ScheduledHeading, ScheduledPath, TerminationRequestDatatype, TerminationRequestHeading, TerminationRequestPath } from '../../utils/finalSettlement';
import { AgreementDetailCard } from './components/agreementDetailCard';
import { Collapes } from './components/collapse';
import { CustomDatePicker } from './components/customDatePicker';
import { DetailCard, SettlementCard } from './components/detailCard';
import { SettlementAmountCard } from './components/settlementAmountCard';
import { FinalSettlementViewStyle } from './style';
import AgreementInformationIcon from './utils/agreementInformationIcon';
import EditIcon from './utils/editIcon';
import FinalSettleIcon from './utils/finalSettlementIcon';


const FinalSettlementView = ({ t = () => false }) => {
    const classes = FinalSettlementViewStyle()
    const navigate = useNavigate()
    const state = useLocation()?.state
    const alert = React.useContext(AlertContext);
    const [type, setType] = React.useState("Invoice")



    const goBack = () => {
        navigate(-1);
    }

    const [data, setData] = React.useState({
        finalSettlement: {},
        agreement_info: {},
        totalCountJson: []
    })
    const [tableData, setTableData] = React.useState([])

    // Table functions


    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [loader, setLoader] = React.useState(true)
    const [tabState, setTabState] = React.useState("Property & Unit Details")
    const [open, setOpen] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [agreementData, setAgreementData] = React.useState(false)
    const [isPopup, setIsPopup] = React.useState({
        bool: false,
        actual_date: null
    })



    const onTabChange = (v) => {
        setTabState(v)
    }
    const handlePagination = (e, key) => {
        setPage(e)
        let offset = (e - 1) * limit
        getKeyData({}, key, offset, limit)
    }

    const handleChangeLimit = (e, key) => {
        setLimit(e)
        setPage(1)
        getKeyData({}, key, 0, e)
    }

    const handleIcon = (type, data) => {
        if (type === "view") {
        }
    }

    const getSettlementInformation = () => {
        const payload = {
            "agreement_id": state?.data?.agreement_id,
            "offset": 0,
            "limit": 10,
            "type": "AgreementInfo"
        }
        NetworkCall(
            `${config.api_url}/final_settlement/agreement_info`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setAgreementData({
                finalSettlement: res?.data?.data?.finalSettlement,
                agreement_info: res?.data?.data?.agreementDetails?.[0]
            })
            getKeyData({
                finalSettlement: res?.data?.data?.finalSettlement,
                agreement_info: res?.data?.data?.agreementDetails?.[0]
            }, "TotalCount")
        }).catch(() => {
        });
    }

    const getKeyData = (obj, key, offset, limit) => {
        const payload = {
            agreement_id: state?.data?.agreement_id,
            offset: offset,
            limit: limit,
            type: key
        }
        NetworkCall(
            `${config.api_url}/final_settlement/agreement_info`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setLoader(false)
            const resData = res?.data?.data
            if (key === "TotalCount") {
                const jsondata = [
                    {
                        title: t("Property & Unit Details"),
                        status: resData?.property_unit?.status ? "cleared" : "uncleared" ?? "uncleared",
                        key: "PropertyUnitDetails",
                        label:t("Property & Units"),
                        show: Object.keys(resData?.property_unit)?.length,
                        cardData: [
                            {
                                title: resData?.property_unit?.unit_count ?? "-",
                                content: t("Unit Count"),
                                status: "normal"
                            },
                            {
                                title: resData?.property_unit?.status ? t("Cleared") : t("Uncleared") ?? t("Uncleared"),
                                content: t("Status"),
                                status: resData?.property_unit?.status ? "cleared" : "uncleared" ?? "uncleared"
                            }
                        ]
                    },
                    {
                        title: t("Termination Request"),
                        status: resData?.termination_request?.status ? "cleared" : "uncleared" ?? "uncleared",
                        key: "TerminationRequest",
                        label:t("Requests"),
                        show: Object.keys(resData?.termination_request)?.length,
                        cardData: [
                            {
                                title: resData?.termination_request?.status ? t("Cleared") : t("Uncleared") ?? t("Uncleared"),
                                content: t("Status"),
                                status: resData?.termination_request?.status ? "cleared" : "uncleared" ?? "uncleared"
                            }
                        ]
                    },
                    // {
                    //     title: "Closure Clearance",
                    //     status: "uncleared",
                    //     show: Object.keys(resData?.closure_clearance)?.length,
                    //     cardData: []
                    // },
                    {
                        title: t("Scheduled Invoices"),
                        status: resData?.scheduled_invoice?.status ? "cleared" : "uncleared" ?? "uncleared",
                        key: "ScheduledInvoice",
                        label:t("Scheduled"),
                        show: Object.keys(resData?.scheduled_invoice)?.length,
                        cardData: [
                            {
                                title: resData?.scheduled_invoice?.posted_count ?? "-",
                                content: t("Posted Count"),
                                status: "normal"
                            },
                            {
                                title: resData?.scheduled_invoice?.total_count ?? "-",
                                content: t("Total Count"),
                                status: "normal"
                            },
                            {
                                title: resData?.scheduled_invoice?.unposted_count ?? "-",
                                content: t("Unposted Count"),
                                status: "normal"
                            },
                            {
                                title: resData?.scheduled_invoice?.total_void ?? "-",
                                content: t("Total Void"),
                                status: "normal"
                            },
                            {
                                title: resData?.scheduled_invoice?.status ? t("Cleared") : t("Uncleared") ?? t("Uncleared"),
                                content: t("Status"),
                                status: resData?.scheduled_invoice?.status ? "cleared" : "uncleared" ?? "uncleared"
                            }
                        ]
                    },
                    {
                        title: t("Posted Invoices"),
                        status: resData?.posted_invoice?.status ? "cleared" : "uncleared" ?? "uncleared",
                        key: "PostedInvoice",
                        label:t("Posted"),
                        show: Object.keys(resData?.posted_invoice)?.length,
                        cardData: [
                            {
                                title: `${resData?.symbol ?? ""} ${Number(resData?.posted_invoice?.total_amount ?? 0).toFixed(0)}` ?? "-",
                                content: ("Total Amount"),
                                status: "normal"
                            },
                            {
                                title: Number(resData?.posted_invoice?.total_invoice ?? 0).toFixed(0) ?? "-",
                                content: t("Total Invoice"),
                                status: "normal"
                            },
                            {
                                title: `${resData?.symbol ?? ""} ${Number(resData?.posted_invoice?.total_paid ?? 0).toFixed(0)}` ?? "-",
                                content: t("Total Paid"),
                                status: "normal"
                            },
                            {
                                title: resData?.posted_invoice?.status ? t("Cleared") : t("Uncleared") ?? t("Uncleared"),
                                content: t("Status"),
                                status: resData?.posted_invoice?.status ? "cleared" : "uncleared" ?? "uncleared"
                            }
                        ]
                    },
                    {
                        title: t("Receipts & Settlements"),
                        status: resData?.receipt_settelment?.status ? "cleared" : "uncleared" ?? "uncleared",
                        key: "ReciptsSettlements",
                        label:t("Receipts"),
                        show: Object.keys(resData?.receipt_settelment)?.length,
                        statusHide: true,
                        cardData: [
                            {
                                title: `${resData?.symbol ?? ""} ${resData?.receipt_settelment?.amount_collected ?? "0"}` ?? "-",
                                content: t("Amount Collected"),
                                status: "normal"
                            },
                            {
                                title: resData?.receipt_settelment?.receipt_count ?? "-",
                                content: t("Receipt Count"),
                                status: "normal"
                            },
                            {
                                title: `${resData?.symbol ?? ""} ${resData?.receipt_settelment?.unused_amount ?? "0"}` ?? "-",
                                content: t("Unused Amount"),
                                status: "normal"
                            },
                            {
                                title: `${resData?.symbol ?? ""} ${resData?.receipt_settelment?.used_amount ?? "0"}` ?? "-",
                                content: t(t("Used Amount")),
                                status: "normal"
                            },
                            // {
                            //     title: resData?.receipt_settelment?.status ? "Cleared" : "Uncleared" ?? "uncleared",
                            //     content: "Status",
                            //     status: resData?.receipt_settelment?.status ? "cleared" : "uncleared" ?? "uncleared"
                            // }
                        ]
                    },
                    {
                        title: t("Credits Note Details"),
                        status: resData?.credit_note?.status ? "cleared" : "uncleared" ?? "uncleared",
                        key: "CreditNote",
                        label:t("Credit Notes"),
                        show: Object.keys(resData?.credit_note)?.length,
                        statusHide: true,
                        cardData: [
                            {
                                title: resData?.credit_note?.total_count ?? "-",
                                content: t("Total Count"),
                                status: "normal"
                            },
                            {
                                title: `${resData?.symbol ?? ""} ${resData?.credit_note?.total_amount ?? "0"}` ?? "-",
                                content: t("Total Amount"),
                                status: "normal"
                            },
                            {
                                title: `${resData?.symbol ?? ""} ${resData?.credit_note?.used_amount ?? "0"}` ?? "-",
                                content: t("Used Amount"),
                                status: "normal"
                            },
                            // {
                            //     title: resData?.credit_note?.status ? "Cleared" : "Uncleared" ?? "uncleared",
                            //     content: "Status",
                            //     status: resData?.credit_note?.status ? "cleared" : "uncleared" ?? "uncleared"
                            // }
                        ]
                    },
                    {
                        title: t("Debit Note Details"),
                        status: resData?.debit_note?.status ? "cleared" : "uncleared" ?? "uncleared",
                        key: "DebitNote",
                        label:t("Debit Notes"),
                        show: Object.keys(resData?.debit_note)?.length,
                        statusHide: true,
                        cardData: [
                            {
                                title: `${resData?.symbol ?? ""} ${resData?.debit_note?.posted_amount ?? "0"}` ?? "-",
                                content: t("Posted Amount"),
                                status: "normal"
                            },
                            {
                                title: `${resData?.symbol ?? ""} ${resData?.debit_note?.total_amount ?? "0"}` ?? "-",
                                content: t("Total Amount"),
                                status: "normal"
                            },
                            {
                                title: resData?.debit_note?.total_count ?? "-",
                                content: t("Total Count"),
                                status: "normal"
                            },
                            // {
                            //     title: resData?.debit_note?.status ? "Cleared" : "Uncleared" ?? "uncleared",
                            //     content: "Status",
                            //     status: resData?.debit_note?.status ? "cleared" : "uncleared" ?? "uncleared"
                            // }
                        ]
                    },
                    {
                        title: t("Refundable"),
                        status: resData?.refundable?.status ? "cleared" : "uncleared" ?? "uncleared",
                        statusHide: true,
                        key: "Refundable",
                        label:t("Refundable"),
                        show: Object.keys(resData?.refundable)?.length,
                        cardData: [
                            {
                                title: `${resData?.symbol ?? ""} ${resData?.refundable?.total_amount}`,
                                content: t("Total Amount"),
                            }
                        ]
                    },
                    {
                        title: t("Correspondences"),
                        status: resData?.correspondence?.status ? "cleared" : "uncleared" ?? "uncleared",
                        statusHide: true,
                        key: "Correspondence",
                        label:t("Letters"),
                        show: Object.keys(resData?.correspondence)?.length,
                        cardData: [
                            {
                                title: resData?.correspondence?.open_letter_count,
                                content: t("Open letter Count"),
                                status: "normal"
                            },
                            {
                                title: resData?.correspondence?.total_count,
                                content: t("Total Count"),
                                status: "normal"
                            }
                        ]
                    }
                ]
                setData({
                    ...data,
                    ...obj,
                    totalCountJson: jsondata
                })
            } else {
                switch (key) {
                    case "PropertyUnitDetails":
                        setTableData({
                            ...tableData,
                            PropertyUnitDetails: {
                                heading: ProertyUnitHeading(t),
                                path: ProertyUnitPath,
                                datatype: PropertyUnitDatatype,
                                list: resData?.unit_details?.map((e) => {
                                    return {
                                        property_id: e?.property_no,
                                        property_name: e?.property_name,
                                        unit_id: e?.unit_no,
                                        unit_name: e?.unit_name,
                                        location: `${e?.area} ${e?.city}`,
                                        move_in_date: e?.move_in_date,
                                        move_out_date: e?.move_out_completed_date
                                    }
                                }),
                                count: resData?.unit_count
                            }
                        })
                        break;
                    case "TerminationRequest":
                        setTableData({
                            ...tableData,
                            TerminationRequest: {
                                heading: TerminationRequestHeading(t),
                                path: TerminationRequestPath,
                                datatype: TerminationRequestDatatype,
                                list: resData?.data?.map((e) => {
                                    return {
                                        request_id: e?.request_id,
                                        request_date: e?.requested_date,
                                        accepted_by: e?.approved_by_first_name,
                                        accepted_date: e?.accepted_date,
                                        contract_exit_date: e?.contract_exits_date,
                                        move_out_completed: e?.move_out_completed_date,
                                        actual_exit_date: e?.actual_exit_date ?? "-"
                                    }
                                }),
                                count: resData?.count
                            }
                        })
                        break;
                    case "ScheduledInvoice":
                        setTableData({
                            ...tableData,
                            ScheduledInvoice: {
                                heading: ScheduledHeading(t),
                                path: ScheduledPath,
                                datatype: ScheduledDatatype,
                                list: resData?.schedule_detials?.map((e) => {
                                    return {
                                        schedule_no: e?.schedule_no,
                                        billing_date: e?.billing_date,
                                        billing_period: `${moment(e?.from_date).format("DD MMM YY")} - ${moment(e?.to_date).format("DD MMM YY")}`,
                                        total_amount: e?.total_amount,
                                        payment_method: e?.payment_method,
                                        posting_date: e?.posting_date,
                                        posting_status: e?.status,
                                        invoice_no: e?.invoice_no,
                                        invoice_status: e?.invoice_status
                                    }
                                }),
                                count: resData?.count
                            }
                        })
                        break;
                    case "PostedInvoice":
                        setTableData({
                            ...tableData,
                            PostedInvoice: {
                                heading: PostedInvoiceHeading(t),
                                path: PostedInvoicePath,
                                datatype: PostedInvoiceDatatype,
                                list: resData?.data?.map((e) => {
                                    return {
                                        invoice_id: e?.invoice_no,
                                        invoice_date: e?.invoice_date,
                                        invoice_type: e?.invoice_type,
                                        total_amount: e?.invoice_total_amount,
                                        payment_method: e?.payment_method,
                                        settlement_date: e?.settlement_date,
                                        settlement_amount: e?.settle_amount,
                                        due_amount: e?.due_amount,
                                        invoice_status: e?.invoice_status
                                    }
                                }),
                                count: resData?.count
                            }
                        })
                        break;
                    case "ReciptsSettlements":
                        setTableData({
                            ...tableData,
                            ReciptsSettlements: {
                                heading: ReceiptSettlementHeading(t),
                                path: ReceiptSettlementPath,
                                datatype: ReceiptSettlementDatatype,
                                list: resData?.receipt_details?.map((e) => {
                                    return {
                                        receipt_no: e?.reciepts_id,
                                        receipt_date: e?.reciepts_date,
                                        payment_method: e?.payment_method,
                                        cheque_no: e?.cheque_no,
                                        paid_amount: e?.amount_paid,
                                        used_amount: e?.used_amount,
                                        unused_amount: e?.unused_amount,
                                        settle_invoice: e?.invoice_no,
                                        status: e?.payment_status
                                    }
                                }),
                                count: resData?.receipt_count
                            }
                        })
                        break;
                    case "CreditNote":
                        setTableData({
                            ...tableData,
                            CreditNote: {
                                heading: CreditNoteHeading(t),
                                path: CreditNotePath,
                                datatype: CreditNoteDatatype,
                                list: resData?.data?.map((e) => {
                                    return {
                                        credit_note_id: e?.credit_note_id,
                                        date: e?.date,
                                        credit_amount: e?.amount_paid,
                                        used_amount: e?.used_amount,
                                        unused_amount: e?.unused_amount,
                                        settle_invoice: e?.invoice_no,
                                        status: (Number(e?.unused_amount) === 0 && e?.unused_amount !== null) ? "Settled" : "Unsettled"
                                    }
                                }),
                                count: resData?.count
                            }
                        })
                        break;
                    case "DebitNote":
                        setTableData({
                            ...tableData,
                            DebitNote: {
                                heading: DebitNoteHeading(t),
                                path: DebitNotePath,
                                datatype: DebitNoteDatatype,
                                list: resData?.data?.map((e) => {
                                    return {
                                        debit_note_id: e?.debit_note_id,
                                        date: e?.date,
                                        scheduled_invoice_no: e?.schedule_invoice_no,
                                        debit_invoice_no: e?.debit_invoice_no,
                                        posted_date: e?.posted_date,
                                        debit_amount: e?.debit_amount,
                                        status: e?.status
                                    }
                                }),
                                count: resData?.count
                            }
                        })
                        break;
                    case "Refundable":
                        setTableData({
                            ...tableData,
                            Refundable: {
                                heading: RefundableHeading(t),
                                path: RefundablePath,
                                datatype: RefundableDatatype,
                                list: resData?.response?.map((e) => {
                                    return {
                                        rent_component: e?.rent_component,
                                        schedule_invoice_id: e?.settlement_no,
                                        invoice_no: e?.invoice_no,
                                        refundable_amount: e?.refundable_amount,
                                        receipt_no: e?.reciepts_no,
                                        receipt_date: e?.receipt_date,
                                        settlement_id: e?.schedule_invoice_id,
                                        settlement_amount: e?.amount_tagged,
                                        status: e?.status
                                    }
                                }),
                                count: resData?.response?.length
                            }
                        })
                        break;
                    case "Correspondence":
                        setTableData({
                            ...tableData,
                            Correspondence: {
                                heading: CorrespondanceHeading(t),
                                path: CorrespondancePath,
                                datatype: CorrespondanceDatatype,
                                list: resData?.data?.map((e) => {
                                    return {
                                        credit_note_id: e?.credit_note_id,
                                        date: e?.date,
                                        property: e?.property_name,
                                        unit_no: e?.unit_no,
                                        correspondance_type: e?.correspondence_type,
                                        acknowledged_no: e?.is_read ? e?.acknowledged_on : null,
                                        status: "-"
                                    }
                                }),
                                count: resData?.count
                            }
                        })
                        break;
                    default:
                        break;
                }
            }

        }).catch(() => {
        });
    }
    React.useState(() => {
        getSettlementInformation()
        //eslint-disable-next-line
    }, [])
    React.useEffect(() => {
        if (tabState === "Property & Unit Details") {
            getKeyData({}, "PropertyUnitDetails")
        }
        //eslint-disable-next-line
    }, [])

    const getTableData = (key) => {
        if (key) {
            getKeyData({}, key)
        }
    }
    const chipColor = {
        "Ontime": {
            backgroundColor: "#FF9340",
            color: "#fff"
        },
        "Early Termination": {
            backgroundColor: "#5AC782",
            color: "#fff"
        },
        "Over Stay": {
            backgroundColor: "#FF4B4B",
            color: "#fff"
        },
    }
    const openClosePopOver = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const openClose = () => {
        setAnchorEl(null);
    };


    const sendRequest = () => {
        const payload = {
            "request_id": state?.data?.agreement_id,
            "type": "Final Settlement"
        }
        NetworkCall(
            `${config.api_url}/request_costing/send_for_approval`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setLoader(false)
            getSettlementInformation()
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t('Request Sent Successfully'),
            });

        }).catch(() => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t('Something went wrong'),
            });
        });
    }
    const createInvoice = () => {
        const payload = {
            "agreement_id": state?.data?.agreement_id,
        }
        NetworkCall(
            `${config.api_url}/final_settlement/createInvoice`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setLoader(false)
            getSettlementInformation()
            setOpen(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: res?.data?.data,
            });

        }).catch(() => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t('Something went wrong'),
            });
        });
    }
    const changeActualdate = (e) => {
        setIsPopup({ bool: true, actual_date: e })
        setAnchorEl(null);
    }
    const alterActualDate = (date) => {
        const payload = {
            id: state?.data?.agreement_id,
            agreement_exit_date: date
        }
        NetworkCall(
            `${config.api_url}/agreement/upsert`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setLoader(false)
            setIsPopup({ bool: false, actual_date: null })
            getSettlementInformation()
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Agreement Exit Date Updated Successfully"),
            });

        }).catch(() => {
            setIsPopup({ bool: false, actual_date: null })
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t('Something went wrong'),
            });
        });
    }
    const handleClose = () => {
        setIsPopup({ bool: false, actual_date: null })
    }
    const handlePopUp = (value) => {
        setType(value)
        setOpen(true)
    }

    return (
        <>
            <Subheader title={state?.data?.agreement_no} goBack={goBack} />
            {
                loader ?
                    <NewLoader /> :
                    <Box p={"16px 24px"} className={classes.root}>
                        {/* contact && account details */}
                        <Grid container spacing={'15.5px'}>
                            <Grid item lg={3} md={3} sm={6} xs={12}>
                                <DetailCard
                                    title={t("AGREEMENT CONTACT DETAILS")}
                                    name={agreementData?.agreement_info?.contact_first_name ?? "-"}
                                    mobile_no={`${agreementData?.agreement_info?.contact_mobile_no_country_code ?? ""} ${agreementData?.agreement_info?.contact_mobile_no ?? "-"}`}
                                    email={agreementData?.agreement_info?.contact_email_id ?? "-"}
                                    image_url={agreementData?.agreement_info?.contact_image_url ?? "-"}
                                />
                            </Grid>
                            <Grid item lg={3} md={3} sm={6} xs={12}>
                                <DetailCard
                                    title={t("BROKER ACCOUNT DETAILS")}
                                    name={agreementData?.agreement_info?.broker_first_name ?? "-"}
                                    mobile_no={`${agreementData?.agreement_info?.broker_mobile_no_country_code ?? ""} ${agreementData?.agreement_info?.broker_mobile_no ?? "-"}`}
                                    email={agreementData?.agreement_info?.broker_email_id ?? "-"}
                                    url={agreementData?.agreement_info?.broker_image_url ?? "-"} />
                            </Grid>
                            <Grid item lg={3} md={3} sm={6} xs={12}>
                                <DetailCard
                                    image={"/images/Group 111998.svg"}
                                    title={t("SALES AGENT DETAILS")}
                                    name={agreementData?.agreement_info?.sales_agent_first_name ?? "-"}
                                    mobile_no={`${agreementData?.agreement_info?.sales_agent_mobile_no_country_code ?? "-"} ${agreementData?.agreement_info?.sales_agent_mobile_no ?? "-"}`}
                                    email={agreementData?.agreement_info?.sales_agent_email_id ?? "-"}
                                    image_url={agreementData?.agreement_info?.sales_agent_image_url ?? "-"}

                                />
                            </Grid>
                            <Grid item lg={3} md={3} sm={6} xs={12}>
                                <SettlementCard
                                    t={t}
                                    title={t("APPROVAL STATUS DETAILS")}
                                    sendRequest={sendRequest}
                                    costing_request={agreementData?.agreement_info?.costing_request}
                                />
                            </Grid>
                        </Grid>


                        {/* agreement information */}
                        <Box mt={'16.67px'} ml={'-15.5px'} mr={'15.67px'}>
                            <Grid container spacing={'15.5px'} justifyContent="flex-start">
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box className={classes.gridBox} padding={2}>
                                        <Stack direction={"row"} alignItems={"center"} pb={"16px"}>
                                            <AgreementInformationIcon style={{ marginInlineEnd: "16px" }} />
                                            <Typography className={classes.title}>{t("Agreement Information")}</Typography>
                                        </Stack>
                                        <Box style={{ backgroundColor: "#E4E8EE", height: "1.1px" }} />

                                        <Stack direction={{
                                            xs: "column",
                                            sm: "row",
                                            md: "row",
                                            lg: "row",
                                        }} pt={"12.5px"} pb={'12.5px'}
                                            spacing={2}
                                            divider={<Divider orientation="vertical" flexItem sx={{ backgroundColor: "#E4E8EE" }} />}>
                                            <Box sx={{ width: "50%" }}>
                                                <AgreementDetailCard
                                                    title={t("ACCOUNT DETAILS")}
                                                    header={agreementData?.agreement_info?.account_name ?? "-"}
                                                    subheader={agreementData?.agreement_info?.account_no ?? "-"} />
                                            </Box>
                                            <Box sx={{ width: "50%" }}>
                                                <AgreementDetailCard
                                                    title={t("AGREEMENT DETAILS")}
                                                    header={agreementData?.agreement_info?.agreement_no ?? "-"}
                                                    subheader={agreementData?.agreement_info?.company_name ?? "-"}
                                                    type="agreement"
                                                    is_chip
                                                />
                                            </Box>
                                        </Stack>
                                        <Box style={{ backgroundColor: "#E4E8EE", height: "1.1px" }} />
                                        <Stack direction={"row"} pt={"12px"}
                                            spacing={2}
                                            divider={<Divider variant='middle' orientation='vertical' sx={{ height: "110px", marginTop: "20px !important" }} />}>
                                            <Box sx={{ width: "50%" }}>
                                                <Stack spacing={'16.5px'}>
                                                    <Stack>
                                                        <Typography className={classes.detailSubtitle}>{t("Termination Request ID")}</Typography>
                                                        <Typography className={classes.detailTitle}>{agreementData?.agreement_info?.request_id}</Typography>
                                                    </Stack>
                                                    <Stack>
                                                        <Typography className={classes.detailSubtitle}>{t("Termination Approved Date")}</Typography>
                                                        <Typography className={classes.detailTitle}>&#x202a;{moment(agreementData?.agreement_info?.agreement_request?.[0]?.termination_closed_date).format("DD-MM-YYYY") ?? "-"}&#x202c;</Typography>
                                                    </Stack>
                                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                                        <Box>
                                                            <Typography className={classes.detailSubtitle}>{t("Actual End Date")}</Typography>
                                                            <Typography className={classes.detailTitle}>{agreementData?.agreement_info?.agreement_exit_date ? moment(agreementData?.agreement_info?.agreement_exit_date).format("DD-MM-YYYY") : "-"}</Typography>
                                                        </Box>
                                                        {(agreementData?.agreement_info?.reciepts_no === null && agreementData?.agreement_info?.schedule_no === null) &&
                                                            <Box onClick={openClosePopOver}>
                                                                <EditIcon />
                                                            </Box>
                                                        }
                                                    </Stack>
                                                    <Stack>
                                                        <Box border="1px solid #E4E8EE" borderRadius={'4px'} padding={'13.5px 12.5px'} display="flex" alignItems={'center'} justifyContent={'space-between'}>
                                                            <Box>
                                                                <Typography className={classes.detailSubtitle}>{t("Days Difference")}</Typography>
                                                                <Typography className={classes.detailTitle}>{agreementData?.agreement_info?.day_diff}</Typography>
                                                            </Box>
                                                            <span style={{ ...chipColor[agreementData?.agreement_info?.day_diff_status ?? "Ontime"], borderRadius: "4px", fontSize:"0.75rem", fontFamily: FontFamilySwitch().bold, padding: "3px 6px" }}>
                                                                {agreementData?.agreement_info?.day_diff_status}
                                                            </span>
                                                        </Box>
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                            <Box sx={{ width: "50%" }}>
                                                <Stack spacing={'16.5px'}>
                                                    <Stack>
                                                        <Typography className={classes.detailSubtitle}>{t("Termination Request Date")}</Typography>
                                                        <Typography className={classes.detailTitle}>&#x202a;{moment(agreementData?.agreement_info?.agreement_request?.[0]?.termination_created_date).format("DD-MM-YYYY") ?? "-"}&#x202c;</Typography>
                                                    </Stack>
                                                    <Stack>
                                                        <Typography className={classes.detailSubtitle}>{t("Planned End Date")}</Typography>
                                                        <Typography className={classes.detailTitle}>&#x202a;{moment(agreementData?.agreement_info?.lease_end_date).format("DD-MM-YYYY") ?? "-"}&#x202c;</Typography>
                                                    </Stack>
                                                    <Stack >

                                                        <Typography className={classes.detailSubtitle}>{t("Amount Difference")}</Typography>
                                                        <Typography className={classes.detailTitle}>{agreementData?.agreement_info?.amount_diff ?? "-"}</Typography>

                                                    </Stack>
                                                    {agreementData?.agreement_info?.day_diff_status !== "Early Termination" && agreementData?.agreement_info?.day_diff_status !== "Ontime" &&
                                                        <Stack>
                                                            <Box border="1px solid #E4E8EE" borderRadius={'4px'} padding={'13.5px 12.5px'} display="flex" alignItems={'center'} justifyContent={'space-between'} boxShadow={'0px 3px 6px #00000029'}>
                                                                <Box>
                                                                    <Typography className={classes.detailTitle}>{t("Invoice")}</Typography>
                                                                    {agreementData?.agreement_info?.schedule_no !== null &&
                                                                        <Typography className={classes.detailSubtitle}>{agreementData?.agreement_info?.schedule_no ?? "-"}</Typography>
                                                                    }
                                                                </Box>
                                                                {agreementData?.agreement_info?.schedule_no === null &&
                                                                    <Box>
                                                                        <Button className={classes.create} onClick={() => handlePopUp("Invoice")}>{t("Create")}</Button>
                                                                    </Box>
                                                                }
                                                            </Box>
                                                        </Stack>
                                                    }
                                                    {agreementData?.agreement_info?.day_diff_status === "Early Termination" &&
                                                        <Stack>
                                                            <Box border="1px solid #E4E8EE" borderRadius={'4px'} padding={'13.5px 12.5px'} display="flex" alignItems={'center'} justifyContent={'space-between'} boxShadow={'0px 3px 6px #00000029'}>
                                                                <Box>
                                                                    <Typography className={classes.detailTitle}>{t("Credit Notes")}</Typography>
                                                                    {agreementData?.agreement_info?.reciepts_no !== null &&
                                                                        <Typography className={classes.detailSubtitle}>{agreementData?.agreement_info?.reciepts_no ?? "-"}</Typography>
                                                                    }
                                                                </Box>
                                                                {agreementData?.agreement_info?.reciepts_no === null &&
                                                                    <Box >
                                                                        <Button className={classes.create} onClick={() => handlePopUp("Credit")}>{t("Create")}</Button>
                                                                    </Box>
                                                                }
                                                            </Box>
                                                        </Stack>
                                                    }
                                                </Stack>
                                            </Box>
                                        </Stack>
                                    </Box>
                                </Grid>
                                {/* final settlement */}
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box className={classes.gridBox} padding={2} position={"relative"}>
                                        <Stack direction={"row"} alignItems={"center"} pb={"16px"}>
                                            <FinalSettleIcon style={{ marginInlineEnd: "16px" }} />
                                            <Typography className={classes.title}>{t("Final Settlement")}</Typography>
                                        </Stack>
                                        <Box style={{ backgroundColor: "#E4E8EE", height: "1.1px" }} />
                                        <Stack spacing={'10px'} padding={'12px 0px'} maxHeight={agreementData?.agreement_info?.costing_request?.length > 0 ? '165px' : '200px'} overflow={'auto'}>
                                            <Stack direction={"row"} justifyContent={"space-between"} spacing={'10px'}>
                                                <Typography className={classes.breakupTitle}>{t("Billed Invoices")}</Typography>
                                                <Typography className={classes.breakupContent}>{`${Number(agreementData?.finalSettlement?.billed_invoice ?? 0).toFixed(2)} ${agreementData?.finalSettlement?.symbol ?? ""}`}</Typography>
                                            </Stack>
                                            <Stack direction={"row"} justifyContent={"space-between"}>
                                                <Typography className={classes.breakupTitle}>{t("Receipts & Credit Notes")}</Typography>
                                                <Typography className={classes.breakupContent}>{`${Number(agreementData?.finalSettlement?.reciept_amount ?? 0).toFixed(2)} ${agreementData?.finalSettlement?.symbol ?? ""}`}</Typography>
                                            </Stack>
                                            <Stack direction={"row"} justifyContent={"space-between"}>
                                                <Typography className={classes.breakupTitle}>{t("Amount Due")}</Typography>
                                                <Typography className={classes.breakupContent}>{`${Number(agreementData?.finalSettlement?.due_amount ?? 0).toFixed(2)} ${agreementData?.finalSettlement?.symbol ?? ""}`}</Typography>
                                            </Stack>
                                            <Stack direction={"row"} justifyContent={"space-between"}>
                                                <Typography className={classes.breakupTitle}>{t("Refundables")}</Typography>
                                                <Typography className={classes.breakupContent}>{`${Number(agreementData?.finalSettlement?.refundable_total ?? 0).toFixed(2)} ${agreementData?.finalSettlement?.symbol ?? ""}`}</Typography>
                                            </Stack>
                                            <Stack direction={"row"} justifyContent={"space-between"}>
                                                <Typography className={classes.breakupTitle}>{t("Void invoices")}</Typography>
                                                <Typography className={classes.breakupContent}>{`${Number(agreementData?.finalSettlement?.void_invoice ?? 0).toFixed(2)} ${agreementData?.finalSettlement?.symbol ?? ""}`}</Typography>
                                            </Stack>
                                            <Stack direction={"row"} justifyContent={"space-between"}>
                                                <Typography className={classes.breakupTitle}>{t("Unbilled Invoices")}</Typography>
                                                <Typography className={classes.breakupContent}>{`${Number(agreementData?.finalSettlement?.unbilled_invoice ?? 0).toFixed(2)} ${agreementData?.finalSettlement?.symbol ?? ""}`}</Typography>
                                            </Stack>
                                        </Stack>

                                        <Box style={{ backgroundColor: "#E4E8EE", height: "1.1px" }} mt={1}/>
                                        <Box className={classes.approvalbox}>
                                            <Stack direction={"row"}>
                                                <Box sx={{ width: "50%" }}>
                                                    <SettlementAmountCard
                                                        backgroundColor="#5AC782"
                                                        title={t("AMOUNT TO BE PAID TO CUSTOMER")}
                                                        amount={`${Number(agreementData?.finalSettlement?.amount_to_be_paid ?? 0).toFixed(2)} ${agreementData?.finalSettlement?.symbol ?? ""}`}
                                                        type='paid' />
                                                </Box>
                                                <Box sx={{ width: "50%", marginInlineStart: "16px" }}>
                                                    <SettlementAmountCard
                                                        backgroundColor="#FF4B4B"
                                                        title={t('AMOUNT TO BE RECOVERED FROM CUSTOMER')}
                                                        amount={`${Number(agreementData?.finalSettlement?.amount_recovered ?? 0).toFixed(2)} ${agreementData?.finalSettlement?.symbol ?? ""}`}
                                                    />
                                                </Box>
                                            </Stack>

                                            {agreementData?.agreement_info?.costing_request?.length > 0 &&
                                                <>
                                                    <Box style={{ backgroundColor: "#E4E8EE", height: "1.1px" }}  mt={1.5}/>
                                                    <Stack direction={"row"} pt={"12px"}
                                                        spacing={2}
                                                        divider={<Divider orientation="vertical" flexItem />}>
                                                        <Box sx={{ width: "50%" }}>

                                                            <Typography className={classes.detailSubtitle}>{t("APPROVAL REQUEST BY")}</Typography>
                                                            <Typography className={classes.detailTitle}>{agreementData?.agreement_info?.costing_request?.[0]?.requested_by !== "" ? agreementData?.agreement_info?.costing_request?.[0]?.requested_by : "-"}</Typography>
                                                        </Box>
                                                        <Box sx={{ width: "50%" }}>
                                                            <Typography className={classes.detailSubtitle}>{t("APPROVAL SENT TO")}</Typography>
                                                            <Typography className={classes.detailTitle}>{agreementData?.agreement_info?.costing_request?.[0]?.approved_by !== "" ? agreementData?.agreement_info?.costing_request?.[0]?.approved_by : "-"}</Typography>

                                                        </Box>
                                                    </Stack>
                                                </>
                                            }
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        {/*  */}
                        <Grid container pt={2} spacing={2}>

                            <Grid item md={12} lg={12} sm={12}>
                                <Collapes
                                    data={data?.totalCountJson?.find((x) => x?.title === tabState)}
                                    page={page}
                                    limit={limit}
                                    handlePagination={handlePagination}
                                    handleChangeLimit={handleChangeLimit}
                                    handleIcon={handleIcon}
                                    getTableData={getTableData}
                                    tableData={tableData}
                                    t={t}
                                    tab={data?.totalCountJson}
                                    tabState={tabState}
                                    onTabChange={onTabChange}
                                />
                            </Grid>

                        </Grid>




                        {/* old */}

                        {/* <Grid container pt={2} spacing={2}>
                            {
                                data?.totalCountJson?.map((e) => {
                                    return (
                                        <Grid item md={12} lg={12} sm={12}>
                                            <Collapes
                                                data={e}
                                                page={page}
                                                limit={limit}
                                                handlePagination={handlePagination}
                                                handleChangeLimit={handleChangeLimit}
                                                handleIcon={handleIcon}
                                                getTableData={getTableData}
                                                tableData={data}
                                                t={t}
                                            />
                                        </Grid>
                                    )
                                })
                            }
                        </Grid> */}


                    </Box >
            }
            <AlertDialog open={open}
                onClose={() => setOpen(false)}
                isnotTitle={true}
                medium
                component={
                    <Box className={classes.reqblock} p={1} alignItems="center">
                        <Box display={"flex"} justifyContent="end" onClick={() => setOpen(false)}>
                            <Close className={classes.closeicon} />
                        </Box>
                        <Grid container mt={5}>
                            <Grid item xs={4}></Grid>

                            <Grid item xs={8}>
                                <Typography className={classes.q1hdr}>
                                    {type === "Credit" ? t("Are you sure , You want to create credit Note?") : t("Are you sure , You want to create Invoice?")}
                                </Typography>
                                <Stack mt={5} ml={2} direction="row" >
                                    <Button variant="contained" fullWidth className={classes.yesbtn} onClick={() => createInvoice()}>{t("Yes")}</Button>
                                    <Button variant="outlined" fullWidth className={classes.nobtn} onClick={() => setOpen(false)}>{t("No")}</Button>

                                </Stack>
                            </Grid>
                        </Grid >
                    </Box >
                }
            />
            {/* date picker */}
            <CustomDatePicker
                outline="Cancel"
                container="Change"
                openClosePopOver={openClosePopOver}
                anchorEl={anchorEl}
                value={agreementData?.agreement_info?.agreement_exit_date !== null ? new Date(agreementData?.agreement_info?.agreement_exit_date) : new Date()}
                handleSubmit={(e) => changeActualdate(e)}
                minDate={new Date()}
                openClose={openClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                t={t}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "left",
                }} />
            <AlertDialog open={isPopup?.bool}
                onClose={() => handleClose()}
                isnotTitle={true}
                medium
                component={
                    <Box className={classes.reqblock} p={1} alignItems="center">
                        <Box display={"flex"} justifyContent="end" onClick={() => handleClose()}>
                            <Close className={classes.closeicon} />
                        </Box>
                        <Grid container mt={5}>
                            <Grid item xs={4} />
                            <Grid item xs={8}>
                                <Typography className={classes.q1hdr}>
                                    {t("Do you want to alter Actual End Date?")}
                                </Typography>
                                <Stack mt={5} ml={2} direction="row" >
                                    <Button variant="contained" fullWidth className={classes.yesbtn} onClick={() => alterActualDate(isPopup?.actual_date)}>{t("Yes")}</Button>
                                    <Button variant="outlined" fullWidth className={classes.nobtn} onClick={() => handleClose()}>{t("No")}</Button>

                                </Stack>
                            </Grid>
                        </Grid >
                    </Box >
                }
            />
        </>
    )
}

const props = {
    boxShadow: false
}

export default withNavBars(withTranslation("finalSettlement")(FinalSettlementView), props);