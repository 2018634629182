import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../../utils";

export const ProfileCardStyle = makeStyles((theme) => ({
  avatar: {
    height: 75,
    width: 75
  },
  box: {
    background: theme.palette.background.secondary
  },
  title: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.secondary
  },
  subtitle: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary
  },
  content: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
    direction: "ltr",
    textAlign: "-webkit-match-parent"
  },
  stack: {

  },
}))

export const InviteCardStyle = makeStyles((theme) => ({
  box: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: 4
  },
  inviteBtn: {
    height: 42,
    width: 95,
  },
  title: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.primary
  },
  subtitle: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.primary
  },
  subtitlespan: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: `${theme.typography.color.tertiary} !important`
  },
  gotAccess: {
    fontSize: "0.75rem",
    fontWeight: "bold",
    color: theme.palette.success.main,
    background: "#5AC78214",
    borderRadius: 4,
    height: 20,
    width: 63,
    padding: "2px 8px 0px 8px",
    whiteSpace: 'nowrap'
  },
  avatar: {
    height: 50,
    width: 50,
    borderRadius: 4
  },
  pending: {
    fontSize: "0.75rem",
    fontWeight: "bold",
    color: theme.palette.warning.main,
    background: theme.palette.warning.light,
    borderRadius: 4,
    height: 20,
    width: 50,
    padding: "2px 12px 0px 6px",
    whiteSpace: 'nowrap'
  },
  revokeBtn: {
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: 4,
    color: theme.palette.error.main,
    fontSize: "0.875rem",
    fontWeight: "bold",
    background: "#fff",
    height: 42,
    width: 95,
    '&:hover': {
      background: "#fff"
    }
  }
}))