import { Box, Container } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DialogBox } from "../../components";
import { DocumentViewer } from "../../components/fileViewer";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { useStyles } from "./style";
import { EmailPreview } from "./viewAnnounsment";

import { withTranslation } from "react-i18next";

const AnnounsmentDetails = ({
  t
}) => {
  //   context
  const alert = useContext(AlertContext);
  const searchURL = useLocation().search;
  const Id = new URLSearchParams(searchURL).get("Id");
  const [details, setDetails] = useState({});
  const [pdf, setPdf] = React.useState({
    bool: false,
    data: "",
  });
  // classes
  const classes = useStyles();
  // get builds list
  const getBuilds = (offsets, filter) => {
    const requestPayload = {
      offset: offsets ?? 0,
      limit: 10,
      company_id: JSON.parse(
        localStorage.getItem(LocalStorageKeys.selectedCompany)
      )?.value,
      id: Id ?? "",
    };
    NetworkCall(
      `${config.api_url}/notifications/view`,
      NetWorkCallMethods.post,
      requestPayload,
      null,
      true,
      false
    )
      .then((res) => {
        const result = {
          details: res?.data?.data?.notification_details,
          created_by: res?.data?.data?.created_user
        }
        setDetails(result);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong. Please try again"),
        });
      });
  };

  const openPdf = (value) => {
    setPdf({
      ...pdf,
      data: value,
      bool: !pdf?.bool,
    });
  };

  useEffect(() => {
    if (Id) {
      getBuilds(0, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Id]);

  return (
    <Box className={classes.rootDetails}>
      <Container maxWidth="sm">
        <EmailPreview
          details={details?.details}
          user={details?.created_by}
          classes={classes}
          is_show
          openPdf={openPdf}
        />
        <DialogBox
          id={"id_123"}
          open={pdf?.bool}
          onClose={() => openPdf(false)}
          handleClose={() => openPdf(false)}
          header={pdf?.data?.name ?? ""}
          isNormal
          // width={"710px !important"}
          maxWidth={"sm"}
          component={
            <Box>
              <DocumentViewer url={pdf?.data?.url ?? ""} />
            </Box>
          }
        />
      </Container>
    </Box>
  );
};


export default withTranslation("announsment")(AnnounsmentDetails);

