import useTheme from '@mui/styles/useTheme';
import React from 'react';
import { FontFamilySwitch } from '../../../utils';
import { ToggleButtonComponent } from '../../toggleButton';
import { CustomTypography } from '../customTypography';

export const ToggleButton = (props) => {

    const {
        label = "",
        options = [],
        value,
        onChange = null,
        isMulti,
        fullWidth = false,
        buttonStyle = {},
        buttonGroupStyle = {},
        error = false,
        errorText = "",
        required = false,
        labelColor = "",
        labelSize = "",
        fontFamily = FontFamilySwitch().bold,
        height = "48px !important"
    } = props;

    const theme = useTheme();
    // const alert = React.useContext(AlertContext)
    const handleChange = (e) => {
        if (isMulti && e.length > 0) {
            onChange(e)
        }
        else if(!isMulti){
            onChange(e)
        }
        // else{
        //     alert.setSnack({
        //         ...alert,
        //         open: true,
        //         severity: AlertProps.severity.error,
        //         msg: `Atleast one filter must be selected for ${label}`,
        //     });
        // }
    }
    return (
        <div>
            <ToggleButtonComponent
                label={label}
                isrequired={required}
                options={options}
                value={value}
                onChange={handleChange}
                isMulti={isMulti}
                fullWidth={fullWidth}
                buttonStyle={{ ...buttonStyle }}
                buttonGroupStyle={{ ...buttonGroupStyle }}
                labelColor={labelColor}
                labelSize={labelSize}
                fontFamily={fontFamily}
                height={height}
            />
            {error &&
                <CustomTypography fontFamily={FontFamilySwitch().bold} marginTop={theme.spacing(1)} color={theme?.palette?.error?.main} fontSize={12}>{errorText}</CustomTypography>
            }
        </div>
    )
}