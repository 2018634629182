import { Box } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { AlertDialog, DetailsCardCoponent } from "../../../components";
import { NetWorkCallMethods, enumSelect, enum_types } from "../../../utils";
import { getPaymentScheduleHeading, paymentmethoddataType, paymentmethodPath } from "../../../utils/agreementUtils";
import { PaymentMethodCard, PaymentScheduleAccordion } from "../components";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
const BillingInformation = ({ details = {}, id = "", reload = () => false, agree = {}, t = () => false }) => {
    const paymentHeading = getPaymentScheduleHeading(t)
    const [open, setOpen] = React.useState(false)
    const [enumValue, setEnumValue] = React.useState({
        payment_method: [],
        auto_renewal_escalation: [],
    })
    //get Enum
    const getEnum = async () => {
        const result = await enumSelect([enum_types.payment_mode, enum_types.auto_renewal_escalation])
        setEnumValue({
            payment_method: result?.payment_mode,
            auto_renewal_escalation: result?.auto_renewal_escalation
        })
    }
    React.useEffect(() => {
        getEnum()
    }, [])
    const card = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("billingCycle"),
            subtitle: `${details?.bill_generation ?? "-"}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("billingCycleDate"),
            subtitle: `${details?.billing_cycle_date ?? "-"}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("billingStartDate"),
            subtitle: `${details?.billing_start_date ?? "-"}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("agreementPaymentTerms"),
            subtitle: `${details?.company?.payment_terms ?? "-"}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "button",
            heading: `${t("methodOfPayment")}  (${t(agree?.payment_mode)})`,
            subtitle: `+ ${t("paymentMethod")}`,
            onClick: () => setOpen(true)
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("Agreement Renewal Type"),
            subtitle: details?.auto_renewal_escalation
                ? enumValue?.auto_renewal_escalation?.find?.(_ => _?.value === details?.auto_renewal_escalation)?.label
                : "-",
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: details?.auto_renewal_escalation === "Increment %",
            component: "text",
            heading: t("Increment Percentage"),
            subtitle: details?.increment_escalation && `${details?.increment_escalation} %`,
        },

    ]
    //on select payment method
    const onSelectPayment = (value) => {
        NetworkCall(
            `${config.api_url}/agreement/upsert_agreement_details`,
            NetWorkCallMethods.post,
            {
                payment_mode: value,
                agreement_id: agree?.id,
            },
            null,
            true,
            false
        )
        .then((response) => {
            reload()
            setOpen(false)
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <Box p={2}>
            <DetailsCardCoponent components={card} />
            <Box height="12px" />
            {
                details?.quotation_payment_schedule?.length > 0 && 
                <PaymentScheduleAccordion
                type={paymentmethoddataType}
                heading={paymentHeading}
                row={details?.quotation_payment_schedule}
                path={paymentmethodPath}
                title={`${t("paymentSchedule")} (${details?.quotation_payment_schedule?.length})`} />
            }
        
            <AlertDialog
                isNormal={true}
                open={open}
                onClose={() => setOpen(false)}
                header={t("paymentMethod")}
                component={
                    <Box p={2}>
                        {
                            enumValue?.payment_method?.map((val) => {
                                return (
                                    <PaymentMethodCard t={t} onClick={onSelectPayment} value={agree?.payment_mode} paymentMethod={val} />
                                )
                            })
                        }
                    </Box>
                }
            />

        </Box>
    )
}
export default withTranslation("agreement")(BillingInformation); 