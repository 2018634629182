import React from "react";
import { withNavBars } from "../../HOCs";
import PropertyFinder from "./propertyFinder";
import PropertyResult from "./propertyResult";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Routes } from "../../router/routes";
class PropertyFinder4Parent extends React.Component {
  render() {
    const { location } = this.props;
    return (
      <>
        {location.pathname === Routes.propertyFinder && (
          <PropertyFinder {...this.props} />
        )}
        {location.pathname === Routes.propertyResult && (
          <PropertyResult {...this.props} />
        )}
      </>
    );
  }
}
const props = {
  boxShadow: false,
};

const PropertyFinder4 = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  return <PropertyFinder4Parent {...props} navigate={navigate} location={location} params={params}/>;
};
export default withNavBars(PropertyFinder4, props);
