import moment from "moment";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import {
  AlertProps,
  LocalStorageKeys,
  NetWorkCallMethods,
  ValidateEmail,
  makeArrayUniqueByProperty,
  uniqueArr,
} from "../../utils";
import { Routes } from "../../router/routes";
import TenentActiveIcon from "./utils/tenentActiveIcon";
import TenentInActiveIcon from "./utils/tenentInActiveIcon";
import OwnerActiveIcon from "./utils/ownerActiveIcon";
import OwnerInActiveIcon from "./utils/ownerInActiveIcon";

export const initialCreateStateResident = {
  auto_renewal: false,
  priority: "high",
  is_exist: true,
  apply_grace_peroid: "In the Beginning",
  grace_peroid: null,
  billingAccount: false,
  propertyType: null,
  activeOccupents: null,
  subject: "",
  duration: {
    select: "Monthly",
    value: null,
  },
  durationRange: null,
  startDate: null,
  endDate: null,
  billStartDate: null,
  paymentOption: {
    value: "Online Payment",
    label: "Online Payment",
  },
  paymentPeriod: null,
  billingCycleMethod: null,
  billingCycleDate: null,
  contact: null,
  billing: null,
  owner: null,
  property: [],
  custom_date: null,
  bill_cycle_date: null,
  initial_bill_cycle_date: null,
  show_property: false,
  quote_id: null,
  deleted_property: [],
  unit_type: 1,
  property_against: null,
  properties: [],
  account: null,
  duration1: {
    select: "Monthly",
    value: null,
  },
  contract: null,
  startDate1: null,
  endDate1: null,
  unit_category: null,
  error: {
    contact: "",
    propertyType: "",
    subject: "",
    duration: "",
    durationRange: "",
    startDate: "",
    paymentPeriod: "",
    property: "",
    owner: "",
    activeOccupents: "",
    properties: "",
    property_against: "",
    account: "",
    contract: "",
    unit_category: "",
  },
};

export const initialCreateState = {
  auto_renewal: false,
  priority: "high",
  is_exist: true,
  apply_grace_peroid: "In the Beginning",
  grace_peroid: null,
  billingAccount: false,
  propertyType: null,
  activeOccupents: null,
  subject: "",
  duration: {
    select: "Monthly",
    value: null,
  },
  durationRange: null,
  startDate: null,
  endDate: null,
  billStartDate: null,
  paymentOption: {
    value: "Online Payment",
    label: "Online Payment",
  },
  paymentPeriod: null,
  billingCycleMethod: null,
  billingCycleDate: null,
  contact: null,
  billing: null,
  owner: null,
  property: [],
  custom_date: null,
  bill_cycle_date: null,
  initial_bill_cycle_date: null,
  show_property: false,
  quote_id: null,
  deleted_property: [],
  unit_type: 1,
  property_against: [],
  properties: [],
  account: null,
  duration1: {
    select: "Monthly",
    value: null,
  },
  contract: null,
  startDate1: null,
  endDate1: null,
  error: {
    contact: "",
    propertyType: "",
    subject: "",
    duration: "",
    durationRange: "",
    startDate: "",
    paymentPeriod: "",
    property: "",
    owner: "",
    activeOccupents: "",
    properties: "",
    property_against: "",
    account: "",
    contract: "",
  },
};

// validate 1 step
export const validateContact = ({
  createState,
  setCreateState = () => false,
  is_tenant = false,

}) => {
  let isValid = true;
  let error = createState.error;

  if (!is_tenant) {
    if (!createState?.propertyType) {
      isValid = false;
      error.propertyType = "Onboarding Property Type is Required";
    }
  }

  if (createState?.is_exist) {
    if (!createState?.contact) {
      isValid = false;
      error.contact = "Contact is Required";
    }
  } else {
    if (createState?.name?.length === 0) {
      isValid = false;
      error.name = "Name is Required";
    }
    if (!createState?.email_id?.length === 0) {
      isValid = false;
      error.email_id = "Email ID is Required";
    }
    if (
      createState?.email_id?.length > 0 &&
      !ValidateEmail(createState?.email_id)
    ) {
      isValid = false;
      error.email_id = "Invalid Email ID";
    }
    if (!createState?.mobile) {
      isValid = false;
      error.mobile = "Mobile No is Required";
    }
  }

  setCreateState({ ...createState, error });
  return isValid;
};
export const validateTenant = ({
  createState,
  setCreateState = () => false,
  isEdit = false,
}) => {
  let isValid = true;
  let error = createState.error;

  if (!isEdit) {
    if (!createState?.propertyType) {
      isValid = false;
      error.propertyType = "Onboarding property Type is Required";
    }
  }

  if (!createState?.account) {
    isValid = false;
    error.account = "Account is Required";
  }
  if (!createState?.contract) {
    isValid = false;
    error.contract = "Contract is Required";
  }

  setCreateState({ ...createState, error });
  return isValid;
};
// validate 2 step
export const validateOppertunity = ({
  createState,
  setCreateState = () => false,
  is_tenant = false,
  t = () => false
}) => {
  let isValid = true;
  let error = createState.error;

  if (!createState?.subject || createState?.subject?.length === 0) {
    isValid = false;
    error.subject = t("Subject is Required");
  }

  if (!is_tenant) {
    if (!createState?.activeOccupents) {
      isValid = false;
      error.activeOccupents = t("Active Occupants is Required");
    }
  }

  if (!createState?.duration?.value) {
    isValid = false;
    error.duration = t("Manage Duration is Required");
  }

  if (!createState?.startDate) {
    isValid = false;
    error.startDate = t("Start Date is Required");
  }

  if (!createState?.paymentOption) {
    isValid = false;
    error.paymentOption = t("Payment Options is Required");
  }

  if (!createState?.paymentPeriod) {
    isValid = false;
    error.paymentPeriod = t("Payment Period is Required");
  }

  if (createState?.paymentPeriod?.value !== "Prepaid") {
    if (!createState?.billingCycleDate) {
      isValid = false;
      error.billingCycleDate = t("Billing Cycle Date is Required");
    }

    if (!createState?.billingCycleMethod) {
      isValid = false;
      error.billingCycleMethod = t("Billing Cycle Method is Required");
    }
    if (new Date(createState?.startDate).getDate() > 28) {
      if (createState?.billingCycleDate > 28) {
        isValid = false;
        error.billingCycleDate = t("Billing Cycle Date must be smaller than 28");
      } else {
        isValid = true;
        error.billingCycleDate = "";
      }
    }
  }

  setCreateState({ ...createState, error });
  return isValid;
};
// validate 1 step
export const validateUnits = ({
  createState,
  setCreateState = () => false,
}) => {
  let isValid = true;
  let error = createState.error;

  if (createState?.unit_type === 2) {
    if (!createState?.owner) {
      isValid = false;
      error.owner = "Owner is Required";
    }
    // if (!createState?.unit_category) {
    //   isValid = false;
    //   error.owner = "Unit Category is Required";
    // }
    if (!createState?.property?.length > 0) {
      isValid = false;
      error.property = "Unit is Required";
    }
  }

  if (createState?.unit_type === 1) {
    if (!createState?.property_against) {
      isValid = false;
      error.property_against = "Property is Required";
    }
    if (!createState?.properties?.length > 0) {
      isValid = false;
      error.properties = "Unit is Required";
    }
    if (!createState?.unit_category) {
      isValid = false;
      error.owner = "Unit Category is Required";
    }
  }

  setCreateState({ ...createState, error });
  return isValid;
};
export const validateUnitsTenant = ({
  createState,
  setCreateState = () => false,
}) => {
  let isValid = true;
  let error = createState.error;
  if (!createState?.property_against?.length > 0) {
    isValid = false;
    error.property_against = "Property is Required";
  }
  if (!createState?.properties?.length > 0) {
    isValid = false;
    error.properties = "Unit is Required";
  }
  if (!createState?.unit_category?.label?.length > 0) {
    isValid = false;
    error.unit_category = "Unit Category is Required";
  }
  setCreateState({ ...createState, error });
  return isValid;
};

// next
export const next = ({
  step,
  setStep = () => false,
  setCreateState = () => false,
  createState,
  navigate,
  alert,
  backdrop,
  sumbit,
  company_id,
  reload,
  oppertunity_only,
  type,
  t = () => false,
}) => {
  if (step === 1)
    if (validateContact({ setCreateState, createState }))
      return setStep(step + 1);
  if (step === 2)
    if (validateOppertunity({ setCreateState, createState, oppertunity_only, t }))
      return setStep(step + 1);
  if (step === 3)
    if (validateUnits({ setCreateState, createState, oppertunity_only })) {
      sumbit();
      return getUnitPricing({
        ...createState,
        navigate,
        alert,
        backdrop,
        sumbit,
        company_id,
        reload,
        oppertunity_only,
        type, t
      });
    }

  alert.setSnack({
    ...alert,
    open: true,
    severity: AlertProps.severity.error,
    msg: t(`Please Fill All Mandatory Fields`),
  });
};

export const getTrueCheck = (data, data1) => {
  return data?.length > 0 && data1?.length > 0 ? true : false;
};

const create = ({
  contact,
  company,
  navigate,
  property,
  units,
  totalAmount,
  totalDiscount,
  totalrefundableTax,
  totalTax,
  alert,
  backdrop,
  company_id,
  result,
  oppertunity_only,
  type, t,
  revenue_type,
  ...state
}) => {
  if (totalAmount === 0) {
    backdrop.setBackDrop({
      ...backdrop,
      open: false,
      message: "",
    });
    return alert.setSnack({
      ...alert,
      open: true,
      severity: AlertProps.severity.error,
      msg: `Units Doesn't Have any pricing.`,
    });
  }
  let payload = {};
  let propspective = {
    first_name: `${contact?.first_name} ${contact?.last_name ?? ""}`,
    mobile_no_country_code: contact?.mobile_no_country_code,
    mobile_no: contact?.mobile_no,
    alternative_mobile: contact?.alternative_mobile,
    alternative_mobile_country_code: contact?.alternative_mobile_country_code,
    email_id: contact?.email_id,
    fax_no: contact?.fax_no,
    door_no: contact?.door_no,
    street_1: contact?.street_1,
    street_2: contact?.street_2,
    landmark: contact?.street_3,
    country: contact?.country,
    longitude: contact?.longitude,
    latitude: contact?.latitude,
    area: contact?.district,
    state: contact?.state,
    zipcode: contact?.pincode,
    city: contact?.city,
    facebook: contact?.facebook,
    linkedin: contact?.linkedin,
    twitter: contact?.twitter,
    image_url: contact?.image_url,
    client: localStorage.getItem(LocalStorageKeys.clinetID),
    phone: contact?.telephone?.mobile,
    phone_code: contact?.phone_code,
    company_id: contact?.company_id,
    is_active: true,
  };

  let lead = {
    billing_cycle_date:
      state?.paymentPeriod?.value === "Prepaid"
        ? null
        : state?.billingCycleDate === "As per the Agreement Date"
          ? state?.billingCycleDate
          : "Custom Date",
    billing_day:
      state?.paymentPeriod?.value === "Prepaid"
        ? null
        : state?.billingCycleDate === "As per the Agreement Date"
          ? null
          : state?.billingCycleDate,
    billing_start_date: state?.billStartDate,
    client: localStorage.getItem(LocalStorageKeys.clinetID),
    created_at: new Date(),
    created_by: localStorage.getItem(LocalStorageKeys.profileID),
    currency_id: company?.currency_id,
    grace_period_type: "Days",
    include_market: false,
    is_active: true,
    lease_end_date: state?.endDate,
    lease_period: state?.duration?.select,
    lease_start_date: state?.startDate,
    payment_mode: state?.paymentOption?.value,
    payment_period: state?.paymentPeriod?.value,
    revenue_based_lease: false,
    revenue_type: revenue_type,
    status: 2,
    subject: state?.subject,
    unit_usage: state?.propertyType?.title,
    updated_at: null,
    value: state?.duration?.value,
    contact_account: state?.billing?.value,
    contact_id: contact?.id,
    company_id: company_id,
    lead_owner: localStorage.getItem(LocalStorageKeys.profileID),
    bill_generation: state?.billingCycleMethod,
    id: state?.lead_id?.length > 0 ? state?.lead_id : undefined,
    occupants_type: state?.activeOccupents?.title ?? null,
    onboarding_type: type === "tenant" ? "Tenant" : null,
  };

  if (oppertunity_only) {
    lead["auto_renewal"] = state?.auto_renewal;
    lead["urgent"] = state?.priority;
    if (state?.grace_peroid > 0) {
      lead["grace_period_type"] = "Days";
      lead["grace_period_value"] = state?.grace_peroid;
      lead["grace_period_value"] = state?.grace_peroid;
    }
  }

  let discoutValue = result
    ?.map((i) => i?.pricing)
    .flat()
    .map((val) => parseInt(val?.discountValue))
    .reduce((a, b) => a + b, 0);
  let units_details = result?.map((val) => {
    return {
      unit_id: val?.id,
      unit_total_primary: val?.totalAmount,
      unit_total_refundable: val?.unitTotalRefundable,
      unit_total_others: null,
      total_rent_breakup: null,
      total_rent_discounts: null,
      total_rent_security_deposit: null,
      total_rent_tax: null,
      total_rent_extended: null,
      area_metric: val?.areaMetric,
      property_id: val?.propertyid,
      block_id: val?.blockid,
      floor_id: val?.floorid,
    };
  });
  let refundable = result
    ?.map((i) => i?.pricing)
    .flat()
    .map((val) => {
      let applied_component_value =
        (val?.discountPrice ? val?.discountPrice : val?.componentvalue) +
        (val?.appliedTaxAmount ? val?.appliedTaxAmount : val?.fixTax);
      return {
        unit_id: val?.unitid,
        rent_breakup_id: val?.PCid,
        rent_amount: val?.discountPrice ?? val?.componentvalue,
        primary: val?.primary,
        refundable: val?.refundable,
        taxable: val?.taxable,
        is_one_time: val?.isOnetime,
        display_percentage: val?.discountValue,
        payment_period: val?.paymentPeriod,
        company_id: company?.value,
        tax: val?.taxPercentage
          ? val?.appliedTaxAmount === 0
            ? val?.fixTax
            : val?.appliedTaxAmount
          : 0,
        tax_percentage: val?.taxPercentage,
        before_rent_amount: val?.componentvalue,
        before_tax: val?.fixTax,
        component_value: val?.value,
        applied_value: applied_component_value,
        vat_group_id: val?.vat_id,
        is_quantity: val?.isQuantity,
        is_wallet_item: val?.is_wallet_item,
        quantity: val?.quantity,
        value_basis_type: val?.valueBasisType,
      };
    });

  let quotation_details = {
    total_amount: totalAmount,
    description: state?.subject,
    total_refundable: totalrefundableTax,
    total_tax: totalTax,
    total_discount: 0,
    lease_period: state?.duration?.select ?? null,
    lease_duration: state?.duration?.value ?? null,
    lease_start_date: state?.startDate
      ? moment(state?.startDate).format("YYYY-MM-DD")
      : null,
    lease_end_date: state?.endDate
      ? moment(state?.endDate).format("YYYY-MM-DD")
      : null,
    quote_start_date: new Date(),
    tax_percentage: discoutValue,
    unit_usage: state?.propertyType?.title,
    company_id: company_id,
    grace_period_type: "Days",
    revenue_type: revenue_type,
    units: units_details,
    rental_breakup: refundable,
    billing_start_date: state?.billStartDate
      ? moment(state?.billStartDate).format("YYYY-MM-DD")
      : null,
    lease_payment_period: state?.paymentPeriod?.value,
    total_quote_amount: totalTax + totalAmount,
    revenue_based_lease: false,
    payment_mode: state?.paymentOption?.value,
    bill_generation:state?.billingCycleMethod,
    include_market: false,
    open_agreement: true,
    occupants_type: state?.activeOccupents?.title,
    contract_id: state?.contract?.value,
    billing_cycle_date:
      state?.paymentPeriod?.value === "Prepaid"
        ? null
        : state?.billingCycleDate === "As per the Agreement Date"
          ? state?.billingCycleDate
          : "Custom Date",
    billing_day:
      state?.paymentPeriod?.value === "Prepaid"
        ? null
        : state?.billingCycleDate === "As per the Agreement Date"
          ? null
          : state?.billingCycleDate,
    is_resident: type === "resident" ? true : false,
    is_tenant: type === "tenant" ? true : false,
  };
  if (!state?.lead_id) payload["prospective"] = propspective;
  if (state?.quote_id?.length > 0) payload["quotation_id"] = state?.quote_id;
  payload["lead"] = lead;
  payload["lead_units"] = state?.lead_id
    ? property?.filter((x) => !x?.lead_unit_id)
    : property;
  payload["quotation"] = quotation_details;
  payload["company_id"] = company_id ?? 1;
  if (state?.deleted_property?.length > 0) {
    payload["deleted_units"] =
      state?.deleted_property?.filter((x) => x?.lead_unit_id)?.length > 0
        ? state?.deleted_property
          ?.filter((x) => x?.lead_unit_id)
          ?.map((x) => x?.lead_unit_id)
        : [];
  }

  NetworkCall(
    `${config?.api_url}/resident_onboarding/create`,
    NetWorkCallMethods.post,
    payload,
    null,
    true,
    false
  )
    .then((response) => {
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });

      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.success,
        msg: t('onboarding') + ' ' + (lead?.id ? t('updated') : t('created')) + ' ' + t('successfully')
      });
      navigate(Routes.residentOnboardingViewEdit, {
        state: {
          main: response?.data?.data?.quote_id,
          lead_id: response?.data?.data?.lead_id,
          type: type,
        },
      });
    })
    .catch((error) => {
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });

      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: `Some thing went wrong.`,
      });
    });
};

const getUnitPricing = ({
  property,
  navigate,
  backdrop,
  alert, t,
  company_id,
  reload,
  properties,
  type,
  is_tenant = false,
  property_against,
  ...state
}) => {
  return new Promise(async () => {
    try {
      let total_properties = uniqueArr([...property, ...properties]);
      let revenue_type = type === "leaseType" ? "Lease" : "Manage";

      if (is_tenant) {
        total_properties = uniqueArr([...properties]);
      }

      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: "...OnBoarding",
      });

      let quotation;
      let result;
      if (total_properties?.length > 0) {
        const datas = {
          unitId: total_properties?.map((x) => x?.value),
          startDate: state?.startDate,
          endDate: state?.endDate,
          gracePeriod: null,
          revenue_type: type === "leaseType" ? "Lease" : "Manage",
        };

        quotation = await NetworkCall(
          `${config.api_url}/quotation/unitprice`,
          NetWorkCallMethods.post,
          datas,
          null,
          true,
          false
        );

        result = quotation?.data?.data?.units?.map((val) => {
          return {
            id: val?.id,
            name: val?.name,
            unit_no: val?.unitNo,
            total_area: val?.totalarea,
            area_metric: val?.areaMetric,
            total_bed_rooms: val?.totalbedroom,
            total_baths: val?.baths,
            unit_type: val?.unitType,
            discount: 0,
            discountValue: 0,
            room_rent: val?.totalPricing,
            url: val?.logo,
            pricing: val?.pricing?.map((val) => {
              return {
                ...val,
                discountedPrice: 0,
                discountValue: 0,
                fixTax: val?.appliedTaxAmount,
                refundableAmount: 0,
                vat_id: val?.vatGroup?.VGMid,
              };
            }),
            total: val?.total,
            totalDiscount: 0,
            totalAmount: val?.totalPricing,
            fixTotal: val?.totalPricing,
            totalrefundableTax: val?.totalrefundableTax,
            totalDiscountValue: null,
            discountPrice: 0,
            discountedPrice: 0,
            appliedTaxAmount: 0,
            blockid: val?.blockid,
            propertyid: val?.propertyid,
            floorid: val?.floorid,
          };
        });
      }
      create({
        ...state,
        ...quotation?.data?.data,
        result,
        property: total_properties,
        navigate,
        backdrop,
        alert,
        company_id,
        type,
        revenue_type, t
      });
    } catch (err) { }
  });
};

// validate 1 step
export const validateAgreementStep1 = ({
  state,
  setState = () => false,
  is_account = false,
}) => {
  let isValid = true;
  let error = state.error;
  if (!state?.name) {
    isValid = false;
    error.name = `${is_account ? "Account Name" : "Name"} is Required`;
  }
  if (!state?.email) {

    isValid = false;
    error.email = "Email Id is Required";
  }
  if (!state?.mobile?.mobile_code) {

    isValid = false;
    error.mobile = "Mobile Number is Required";
  }
  setState({ ...state, error });
  return isValid;
};

export const editConstractData = (res, quote_id) => {
  let units = [];
  let properties = [];
  if (
    res?.unit?.length > 0 &&
    res?.unit?.filter((val) => val?.unit?.owner_id)?.length > 0
  ) {
    units = res?.unit
      ?.filter((val) => val?.unit?.owner_id)
      ?.map((x) => {
        const unit = x;
        return {
          label: unit?.unitByID?.unit_name,
          value: unit?.unitByID?.id,
          url: unit?.unitByID?.logo,
          label1: unit?.unitByID?.unit_no,
          unit_id: unit?.unitByID?.id,
          id: unit?.unitByID?.id,
          unit_no: unit?.unitByID?.unit_no,
          unit_name: unit?.unitByID?.unit_name,
          logo: unit?.unitByID?.logo,
          property_id: unit?.unitByID?.property?.property_id,
          property_name: unit?.unitByID?.property?.property_name,
          status: "Vacant",
          is_active: true,
          lead_unit_id: x?.id,
          contact_account: unit?.unitByID?.contact_account,
        };
      });
  }
  if (
    res?.unit?.length > 0 &&
    res?.unit?.filter((val) => !val?.unit?.owner_id)?.length > 0
  ) {
    properties = res?.unit
      ?.filter((val) => !val?.unit?.owner_id)
      ?.map((x) => {
        const unit = x;
        return {
          label: unit?.unitByID?.unit_name,
          value: unit?.unitByID?.id,
          url: unit?.unitByID?.logo,
          label1: unit?.unitByID?.unit_no,
          unit_id: unit?.unitByID?.id,
          id: unit?.unitByID?.id,
          unit_no: unit?.unitByID?.unit_no,
          unit_name: unit?.unitByID?.unit_name,
          logo: unit?.unitByID?.logo,
          property_id: unit?.unitByID?.property?.property_id,
          property_name: unit?.unitByID?.property?.property_name,
          status: "Vacant",
          is_active: true,
          lead_unit_id: x?.id,
          property: unit?.unitByID?.property,
        };
      });
  }

  const payload = {
    billingAccount: false,
    propertyType: null,
    is_exist: true,
    contract: res?.contract,
    account: res?.contract?.accountByID,
    activeOccupents: {
      title: res?.occupants_type,
      selectedIcon:
        res?.occupants_type === "Owner" ? (
          <OwnerActiveIcon />
        ) : (
          <TenentActiveIcon />
        ),
      notSelectedIcon:
        res?.occupants_type === "Owner" ? (
          <OwnerInActiveIcon />
        ) : (
          <TenentInActiveIcon />
        ),
    },
    subject: res?.subject ?? null,
    duration: {
      value: res?.value,
      select: res?.lease_period,
    },
    durationRange: null,
    startDate: res?.lease_start_date ? new Date(res?.lease_start_date) : null,
    endDate: res?.lease_end_date ? new Date(res?.lease_end_date) : null,
    billStartDate: res?.billing_start_date
      ? new Date(res?.billing_start_date)
      : null,
    paymentOption: {
      value: res?.payment_mode,
      label: res?.payment_mode,
    },
    paymentPeriod: {
      value: res?.payment_period,
      label: res?.payment_period,
    },
    billingCycleMethod: res?.bill_generation ?? null,
    billingCycleDate:
      res?.billing_cycle_date === "As per the Agreement Date"
        ? res?.billing_cycle_date
        : res?.billing_day,
    contact: null,
    billing: null,
    owner: units?.length > 0 ? units?.[0]?.contact_account : null,
    property: units,
    bill_cycle_date: null,
    initial_bill_cycle_date:
      res?.billing_cycle_date === "As per the Agreement Date"
        ? res?.billing_cycle_date
        : res?.billing_day,
    show_property: res?.unit?.length > 0 ? true : false,
    quote_id: quote_id,
    lead_id: res?.id,
    initial_units: units,
    deleted_property: [],
    unit_type: units?.length > 0 ? 2 : 1,
    property_against: properties?.length > 0 ? properties?.[0]?.property : null,
    properties: properties,
    unit_category:
      res?.unit?.length > 0
        ? { label: res?.unit_usage, value: res?.value }
        : null,
    error: {
      contact: "",
      propertyType: "",
      subject: "",
      duration: "",
      durationRange: "",
      startDate: "",
      paymentPeriod: "",
      property: "",
      owner: "",
      property_against: "",
      properties: "",
    },
  };

  return payload;
};

export const nextTenant = ({
  step,
  setStep = () => false,
  setCreateState = () => false,
  createState,
  isEdit = false,
  navigate,
  alert,
  backdrop,
  sumbit,
  company_id,
  reload,
  oppertunity_only,
  type,
}) => {
  if (isEdit) {
    if (step === 1)
      if (validateTenant({ setCreateState, createState, isEdit }))
        return setStep(step + 1);
    if (step === 2)
      if (
        validateOppertunity({
          setCreateState,
          createState,
          oppertunity_only,
          is_tenant: true,
        })
      )
        return setStep(step + 1);
    if (step === 3)
      if (
        validateUnitsTenant({ setCreateState, createState, oppertunity_only })
      ) {
        sumbit();
        return getUnitPricing({
          ...createState,
          navigate,
          alert,
          backdrop,
          sumbit,
          company_id,
          reload,
          oppertunity_only,
          type,
          is_tenant: true,
        });
      }
    alert.setSnack({
      ...alert,
      open: true,
      severity: AlertProps.severity.error,
      msg: `Please Fill All Mandatory Fields.`,
    });
  } else {
    if (step === 1)
      if (validateTenant({ setCreateState, createState }))
        return setStep(step + 1);
    if (step === 2)
      if (
        validateContact({
          setCreateState,
          createState,
          oppertunity_only,
          is_tenant: true,
        })
      )
        return setStep(step + 1);
    if (step === 3)
      if (
        validateOppertunity({
          setCreateState,
          createState,
          oppertunity_only,
          is_tenant: true,
        })
      )
        return setStep(step + 1);
    if (step === 4)
      if (
        validateUnitsTenant({ setCreateState, createState, oppertunity_only })
      ) {
        sumbit();
        return getUnitPricing({
          ...createState,
          navigate,
          alert,
          backdrop,
          sumbit,
          company_id,
          reload,
          oppertunity_only,
          type,
          is_tenant: true,
        });
      }
    alert.setSnack({
      ...alert,
      open: true,
      severity: AlertProps.severity.error,
      msg: `Please Fill All Mandatory Fields.`,
    });
  }
};

export const editConstractDataTenant = (res, quote_id) => {
  let units = res?.lead_units?.map((x) => {
    const unit = x?.unit;
    return {
      label: unit?.unit_name,
      value: unit?.id,
      url: unit?.logo,
      label1: unit?.unit_no,
      unit_id: unit?.id,
      id: unit?.id,
      unit_no: unit?.unit_no,
      unit_name: unit?.unit_name,
      logo: unit?.logo,
      property_id: unit?.property?.property_id,
      property_name: unit?.property?.property_name,
      status: "Vacant",
      is_active: true,
      lead_unit_id: x?.id,
      contact_account: unit?.contact_account,
    };
  });
  let properties = res?.lead_units?.map((x) => {
    const unit = x?.unit;
    return {
      label: unit?.property?.property_name,
      value: unit?.property?.property_id,
      url: unit?.property?.url,
      label1: unit?.property?.label1,
      property_no: unit?.property?.property_no,
      uom_master: unit?.property?.uom_master,
    };
  });

  const payload = {
    billingAccount: false,
    propertyType: null,
    is_exist: true,
    contract: res?.contract,
    account: res?.contract?.accountByID,
    activeOccupents: {
      title: res?.occupants_type,
      selectedIcon:
        res?.occupants_type === "Owner" ? (
          <OwnerActiveIcon />
        ) : (
          <TenentActiveIcon />
        ),
      notSelectedIcon:
        res?.occupants_type === "Owner" ? (
          <OwnerInActiveIcon />
        ) : (
          <TenentInActiveIcon />
        ),
    },
    subject: res?.subject,
    duration: {
      value: res?.value,
      select: res?.lease_period,
    },
    durationRange: null,
    startDate: res?.lease_start_date ? new Date(res?.lease_start_date) : null,
    endDate: res?.lease_end_date ? new Date(res?.lease_end_date) : null,
    billStartDate: res?.billing_start_date
      ? new Date(res?.billing_start_date)
      : null,
    paymentOption: {
      value: res?.payment_mode,
      label: res?.payment_mode,
    },
    paymentPeriod: {
      value: res?.payment_period,
      label: res?.payment_period,
    },
    billingCycleMethod: res?.bill_generation ?? null,
    billingCycleDate:
      res?.billing_cycle_date === "As per the Agreement Date"
        ? res?.billing_cycle_date
        : res?.billing_day,
    contact: null,
    billing: null,
    owner: units?.length > 0 ? units?.[0]?.contact_account : null,
    property: units,
    // custom_date: res?.billing_cycle_date !== "As per the Agreement Date" ? res?.billing_day : null,
    bill_cycle_date: null,
    initial_bill_cycle_date:
      res?.billing_cycle_date === "As per the Agreement Date"
        ? res?.billing_cycle_date
        : res?.billing_day,
    show_property: res?.lead_units?.length > 0 ? true : false,
    quote_id: quote_id,
    lead_id: res?.id,
    initial_units: units,
    deleted_property: [],
    unit_type: units?.length > 0 ? 2 : 1,
    property_against:
      properties?.length > 0
        ? makeArrayUniqueByProperty(properties, "value")
        : null,
    properties: units,
    error: {
      contact: "",
      propertyType: "",
      subject: "",
      duration: "",
      durationRange: "",
      startDate: "",
      paymentPeriod: "",
      property: "",
      owner: "",
      property_against: "",
      properties: "",
    },
  };

  return payload;
};
