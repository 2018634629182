import { Badge, Box, IconButton, Stack } from '@mui/material'
import React from 'react'
import { EmployeeCard } from './component/employeeCard'
import { styled } from '@mui/material/styles';
import { CalendarChangeBtn } from './component/calendarChangeBtn';
import { Calendar } from './component/calender';
import { addMonths, endOfMonth, endOfWeek, format, startOfMonth, startOfWeek, subMonths } from 'date-fns'
import { ChangeEmployee } from './component/changeEmployee';
import { AlertDialog, FilterGenerator, SelectBox, Subheader, TableWithPagination, UseDebounce } from '../../components';
import { AlertContext, AuthContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { config } from '../../config';
import { AlertProps, NetWorkCallMethods, accessCheckRender, getRoutePermissionNew } from '../../utils';
import { TableList } from './component/tableList';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { CheckInOutHeading, CheckInOutPath, CheckInOutType, TYPE_LIST, VIEW_TYPE_LIST } from './utils/utils';
import { ParkingCalenderIcon } from '../../assets';
import MenuIcon from '@mui/icons-material/Menu'
import { useStyles } from './style';

const CustomPaper = styled('div')(() => ({
    borderRadius: "4px",
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
    height: `calc(100vh - 100px)`,
    overflow: "hidden",
    margin: "16px"
}))

const SelfCheckInEntries = ({
    loading,
    t = () => false
}) => {
    const defaultFilterState = {
        resource_name: [],
        job: [],
        check_in_date: null,
        check_out_date: null,
        is_checkout: false,
    };
    const viewTypeList = VIEW_TYPE_LIST;
    const classes = useStyles();
    const alert = React.useContext(AlertContext)
    const debounce = UseDebounce()
    const auth = React.useContext(AuthContext);
    const [typeList , setTypeList] = React.useState([]);
    const [permission, setPermission] = React.useState({});
    const [selectedMonth, setSelectedMonth] = React.useState(new Date())
    const [dates, setDates] = React.useState([])
    const [resourceCalendarState, setResourceCalendarState] = React.useState({
        openEmployee: false,
        employeeList: [],
        employeeListCount: 0,
        selectedEmploee: "",
        calendarData: [],
        openTable: false,
        viewDate: new Date()
    })
    const [contactCalendarState, setContactCalendarState] = React.useState({
        openEmployee: false,
        employeeList: [],
        employeeListCount: 0,
        selectedEmploee: "",
        calendarData: [],
        openTable: false,
        viewDate: new Date()
    })
    const [resourceOffset, setResourceOffset] = React.useState(0)
    const [resourceSearch, setResourceSearch] = React.useState("")
    const [contactOffset, setContactOffset] = React.useState(0)
    const [contactSearch, setContactSearch] = React.useState("")
    const [type, setType] = React.useState(typeList?.[0]);
    const [viewType, setViewType] = React.useState(viewTypeList?.[0]);  
    const [drawer, setDrawer] = React.useState(false)
    const [filterData, setFilterData] = React.useState(defaultFilterState)
    const [resourceTableData, setResourceTableData] = React.useState([])
    const [resourcePage, setResourcePage] = React.useState(1)
    const [resourceLimit, setResourceLimit] = React.useState(10)
    const [contactTableData, setContactTableData] = React.useState([])
    const [contactPage, setContactPage] = React.useState(1)
    const [contactLimit, setContactLimit] = React.useState(10)

    const updateResourceCalendarState = (key, value) => {
        setResourceCalendarState({ ...resourceCalendarState, [key]: value })
    }

    const updateContactCalendarState = (key, value) => {
        setContactCalendarState({ ...contactCalendarState, [key]: value })
    }

    const monthStart = startOfMonth(selectedMonth);
    const monthEnd = endOfMonth(monthStart);
    const monthstartDate = startOfWeek(monthStart);
    const monthendDate = endOfWeek(monthEnd);
    const day = [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
    ]

    React.useEffect(() => {
        const typeList = TYPE_LIST(t); 
        setTypeList(typeList);
        setType(typeList?.[0]);
        const perm = getRoutePermissionNew(auth);
        if (perm) {
            setPermission(perm);
            if (perm?.read) {
                let finaldates = dateRange(monthstartDate, monthendDate)
                setDates(finaldates)
                getResource(0, "", false, true)
                getContact(0, "", false, true)
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const dateRange = (startDate, endDate) => {
        const date = new Date(startDate.getTime());
        const dates = [];
        while (date <= endDate) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
        return dates;
    }

    const monthnext = () => {
        let tempMonth = addMonths(selectedMonth, 1)
        let monthStart = startOfMonth(tempMonth);
        let monthEnd = endOfMonth(monthStart);
        setSelectedMonth(tempMonth)
        let finaldays = dateRange(startOfWeek(monthStart), endOfWeek(monthEnd))
        setDates(finaldays)
        getResourceCalendarData(monthStart, monthEnd, resourceCalendarState?.selectedEmploee)
        getContactCalendarData(monthStart, monthEnd, contactCalendarState?.selectedEmploee)
        // sendDateRange(tempDateRange, selected)
    }

    const monthprev = () => {
        let tempMonth = subMonths(selectedMonth, 1)
        let monthStart = startOfMonth(tempMonth);
        let monthEnd = endOfMonth(monthStart);
        setSelectedMonth(tempMonth)
        let finaldays = dateRange(startOfWeek(monthStart), endOfWeek(monthEnd))
        setDates(finaldays)
        getResourceCalendarData(monthStart, monthEnd, resourceCalendarState?.selectedEmploee)
        getContactCalendarData(monthStart, monthEnd, contactCalendarState?.selectedEmploee)
        // sendDateRange(tempDateRange, selected)
    }

    const getResource = (offset = 0, search = "", bool = false, initial = false) => {
        const payload = {
            offset: offset,
            limit: 10,
            active: [true],
            search: search
        }
        NetworkCall(
            `${config.api_url}/resources/getAll`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            if (initial) {
                getResourceCalendarData(monthStart, monthEnd, res?.data?.result, res?.data?.count, initial, false)
                getTableData(0, 10, filterData, "resource", res?.data?.result?.[0]?.id)
            } else {
                if (bool) {
                    setResourceCalendarState({
                        ...resourceCalendarState,
                        employeeList: [...resourceCalendarState?.employeeList, ...res?.data?.result],
                        employeeListCount: res?.data?.count
                    })
                } else {
                    if (initial) {
                        setResourceCalendarState({
                            ...resourceCalendarState,
                            employeeList: res?.data?.result,
                            employeeListCount: res?.data?.count,
                            selectedEmploee: res?.data?.result?.[0]

                        })
                    }
                    else {
                        setResourceCalendarState({
                            ...resourceCalendarState,
                            employeeList: res?.data?.result,
                            employeeListCount: res?.data?.count,
                        })
                    }

                }
            }
        }).catch((err) => {
            console.log(err)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: AlertProps.message.some_thing_went_wrong
            });
        })
    }

    const getContact = (offset = 0, search = "", bool = false, initial = false) => {
        const payload = {
            offset, limit: 10, search,
            filter: { relationship: ["Company Employee"] },
        }
        NetworkCall(
            `${config.api_url}/contact/list`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            const tempResult = res?.data?.data?.map?.(_ => {
                return {
                    ..._,
                    name: _?.first_name ?? "",
                    job_name: _?.job_title ?? "",
                }
            })
            if (initial) {
                getContactCalendarData(monthStart, monthEnd, tempResult, res?.data?.count, initial, false)
                getTableData(0, 10, filterData, "contact", tempResult?.[0]?.id)
            } else {
                if (bool) {
                    setContactCalendarState({
                        ...contactCalendarState,
                        employeeList: [...contactCalendarState?.employeeList, ...tempResult],
                        employeeListCount: res?.data?.count
                    })
                } else {
                    if (initial) {
                        setContactCalendarState({
                            ...contactCalendarState,
                            employeeList: tempResult,
                            employeeListCount: res?.data?.count,
                            selectedEmploee: tempResult?.[0]

                        })
                    }
                    else {
                        setContactCalendarState({
                            ...contactCalendarState,
                            employeeList: tempResult,
                            employeeListCount: res?.data?.count,
                        })
                    }

                }
            }
        }).catch((err) => {
            console.log(err)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: AlertProps.message.some_thing_went_wrong
            });
        })
    }

    const getResourceCalendarData = (startDate = monthStart, endDate = monthEnd, empData = [], empDataCount = 0, initial = false, calendar = false) => {
        const payload = {
            resource_id: initial ? empData?.[0]?.id : empData?.id,
            date_range: [
                format(startDate, "yyyy-MM-dd"),
                format(endDate, "yyyy-MM-dd")
            ]
        }
        NetworkCall(
            `${config.api_url}/duty_roaster/calendar_view`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            if (initial) {
                setResourceCalendarState({
                    ...resourceCalendarState,
                    employeeList: empData,
                    employeeListCount: empDataCount,
                    selectedEmploee: empData?.[0],
                    calendarData: res?.data?.data
                })
            } else if (calendar) {
                setResourceCalendarState({
                    ...resourceCalendarState,
                    calendarData: res?.data?.data,
                    openEmployee: false,
                    selectedEmploee: empData
                })

            } else {
                setResourceCalendarState({
                    ...resourceCalendarState,
                    calendarData: res?.data?.data
                })
            }
        }).catch((err) => {
            console.log(err)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: AlertProps.message.some_thing_went_wrong
            });
        })
    }

    const getContactCalendarData = (startDate = monthStart, endDate = monthEnd, empData = [], empDataCount = 0, initial = false, calendar = false) => {
        const payload = {
            contact_id: initial ? empData?.[0]?.id : empData?.id,
            date_range: [
                format(startDate, "yyyy-MM-dd"),
                format(endDate, "yyyy-MM-dd")
            ]
        }
        NetworkCall(
            `${config.api_url}/contact_duty_roaster/calendar_view`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            if (initial) {
                setContactCalendarState({
                    ...contactCalendarState,
                    employeeList: empData,
                    employeeListCount: empDataCount,
                    selectedEmploee: empData?.[0],
                    calendarData: res?.data?.data
                })
            } else if (calendar) {
                setContactCalendarState({
                    ...contactCalendarState,
                    calendarData: res?.data?.data,
                    openEmployee: false,
                    selectedEmploee: empData
                })

            } else {
                setContactCalendarState({
                    ...contactCalendarState,
                    calendarData: res?.data?.data
                })
            }
        }).catch((err) => {
            console.log(err)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: AlertProps.message.some_thing_went_wrong
            });
        })
    }

    const fetchMoreResource = () => {
        const off = resourceOffset + 10
        setResourceOffset(off)
        getResource(off, "", true)
    }

    const fetchMoreContact = () => {
        const off = contactOffset + 10
        setContactOffset(off)
        getContact(off, "", true)
    }

    const handleSelectResource = (data) => {
        getResourceCalendarData(startOfMonth(selectedMonth), endOfMonth(selectedMonth), data, 0, false, true)
        getTableData(0, 10, filterData, "resource", data?.id)
    }

    const handleSelectContact = (data) => {
        getContactCalendarData(startOfMonth(selectedMonth), endOfMonth(selectedMonth), data, 0, false, true)
        getTableData(0, 10, filterData, "contact", data?.id)
    }

    const handleSearchResource = (search = "") => {
        setResourceSearch(search)
        setResourceOffset(0)
        debounce(() => getResource(0, search), 800)
    }

    const handleSearchContact = (search = "") => {
        setContactSearch(search)
        setContactOffset(0)
        debounce(() => getContact(0, search), 800)
    }

    const openResourceTableView = (date) => {
        setResourceCalendarState({
            ...resourceCalendarState,
            openTable: true,
            viewDate: date
        })
    }

    const openContactTableView = (date) => {
        setContactCalendarState({
            ...contactCalendarState,
            openTable: true,
            viewDate: date
        })
    }

    const getTableData = (offset = 0, limit = 10, filterData, type, emp_id) => {
        const payload = {
            offset, limit,
            checkin_from_date: filterData?.check_in_date?.startDate ? moment(filterData?.check_in_date?.startDate).format("YYYY-MM-DD HH:mm:ss") : null,
            checkin_to_date: filterData?.check_in_date?.endDate ? moment(filterData?.check_in_date?.endDate).format("YYYY-MM-DD HH:mm:ss") : null,
            checkout_from_date: filterData?.check_out_date?.startDate ? moment(filterData?.check_out_date?.startDate).format("YYYY-MM-DD HH:mm:ss") : null,
            checkout_to_date: filterData?.check_out_date?.endDate ? moment(filterData?.check_out_date?.endDate).format("YYYY-MM-DD HH:mm:ss") : null,
            is_checkout: filterData?.is_checkout ?? undefined,
        }

        if (type === "resource") {
            payload.resource_ids = [emp_id];
        } else {
            payload.contact_ids = [emp_id];
        }
        NetworkCall(
            `${config.api_url}${type === "resource" ? `/duty_roaster/calendar_log` : `/contact_duty_roaster/calendar_log`}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            const constructedData = res?.data?.data?.map((e) => {
                return {
                    resource_name: type === "resource" ? e?.resource_name : e?.contact_name,
                    location: e?.location_name,
                    checkin: format(new Date(`${e?.checkin_date} ${e?.checkin_time}`), "dd MMM yy p") + (e?.in_timezone?.length ? ` (${e?.in_timezone ?? ""})` : "") ?? "-",
                    checkout: e?.checkout_date !== null ? format(new Date(`${e?.checkout_date} ${e?.checkout_time}`), "dd MMM yy p") + (e?.out_timezone?.length ? ` (${e?.out_timezone ?? ""})` : "") : "-",
                    check_in_distance: e?.in_distance ? `${e?.in_distance.toFixed(2)} ${t("Kms")}` : "-",
                    check_out_distance: e?.out_distance ? `${e?.out_distance.toFixed(2)} ${t("Kms")}` : "-",
                    status: e?.status,
                    time_diff: e?.time_diff ?? "-",
                    checkin_by: (e?.checkin_time?.length && e?.checkin_by?.length) && t("User"),
                    checkout_by: e?.checkin_time?.length === 0 ? t("Yet to checkout") : (e?.checkout_time?.length && e?.checkout_by?.length) ? t("User") : t("System")
                }
            })

            if (type === "resource") {
                setResourceTableData({
                    list: constructedData,
                    count: res?.data?.count,
                })
            } else {
                setContactTableData({
                    list: constructedData,
                    count: res?.data?.count,
                })
            }
        }).catch((err) => {
            console.log(err)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: AlertProps.message.some_thing_went_wrong
            });
        })
    }

    const handleResourcePagination = (value) => {
        setResourcePage(value);
        let offset = (value - 1) * resourceLimit;
        getTableData(offset, resourceLimit, filterData, "resource", resourceCalendarState?.selectedEmploee?.id)
    }

    const handleContactPagination = (value) => {
        setContactPage(value);
        let offset = (value - 1) * contactLimit;
        getTableData(offset, contactLimit, filterData, "contact", contactCalendarState?.selectedEmploee?.id)
    }

    const handleResourceChangeLimit = (value) => {
        setResourceLimit(value);
        setResourcePage(1);
        getTableData(0, value, filterData, "resource", resourceCalendarState?.selectedEmploee?.id)
    }

    const handleContactChangeLimit = (value) => {
        setContactLimit(value);
        setContactPage(1);
        getTableData(0, value, filterData, "contact", contactCalendarState?.selectedEmploee?.id)
    }

    const openDrawer = () => {
        setDrawer(!drawer)
    }

    const onApplyFilter = (value) => {
        if (value?.check_in_date !== null && (!value?.check_in_date?.startDate || !value?.check_in_date?.endDate)) {
            setDrawer(true);
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.info,
                msg: !value?.check_in_date?.startDate ? t("Please choose Check In from date") : t("Please choose Check In to date"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
            setFilterData(value)
        }
        else if (value?.check_out_date !== null && (!value?.check_out_date?.startDate || !value?.check_out_date?.endDate)) {
            setDrawer(true);
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.info,
                msg: !value?.check_out_date?.startDate ? t("Please choose Check out from date") : t("Please choose Check out to date"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        }
        else {
            getTableData(0, 10, value, "resource", resourceCalendarState?.selectedEmploee?.id)
            getTableData(0, 10, value, "contact", contactCalendarState?.selectedEmploee?.id)
            setFilterData(value)
            console.log("value: ", value)
        }
    }

    const render = () => {
        return (
            <>
                <Subheader hideBackButton title={t("Self Check-In Entries")} />
                <CustomPaper>
                    <Stack direction={"row"} columnGap={"16px"} justifyContent={"space-between"} alignItems={"center"}>
                        <Stack direction={"row"} columnGap={"16px"} justifyContent={"space-between"} alignItems={"center"}>
                            <SelectBox
                                value={type}
                                onChange={(value) => setType(value)}
                                options={typeList}
                                selectHeight={"61px"} />
                            {type?.value === "resource"
                                ? <EmployeeCard
                                    openPopup={() => updateResourceCalendarState("openEmployee", true)}
                                    data={resourceCalendarState?.selectedEmploee} t={t} />
                                : <EmployeeCard
                                    openPopup={() => updateContactCalendarState("openEmployee", true)}
                                    data={contactCalendarState?.selectedEmploee} t={t} />}
                        </Stack>
                        {viewType?.value === "calendar" &&
                            <CalendarChangeBtn monthnext={monthnext} monthprev={monthprev}
                                selectedMonth={selectedMonth} t={t} />}
                        <Stack direction={"row"} columnGap={"16px"} justifyContent={"space-between"} alignItems={"center"}>
                            {viewType?.value === "table" &&
                                <IconButton className={classes.img}
                                    size="small" onClick={openDrawer}>
                                    <Badge variant="dot" color="primary">
                                        <img src="/images/filter.svg" alt="filter" />
                                    </Badge>
                                </IconButton>}
                            <Box className={classes.layoutSwitch}>
                                <Stack direction={"row"} spacing={1} alignItems="center">
                                    <Box className={viewType?.value === "calendar" ? classes.selectedBtn : classes.notSelectedBtn}>
                                        <ParkingCalenderIcon onClick={() => setViewType(viewTypeList?.[0])}
                                            fill={viewType?.value === "calendar" ? "#FFFFFF" : "#98A0AC"} />
                                    </Box>
                                    <Box className={viewType?.value === "table" ? classes.selectedBtn : classes.notSelectedBtn}>
                                        <MenuIcon onClick={() => setViewType(viewTypeList?.[1])} />
                                    </Box>
                                </Stack>
                            </Box>
                        </Stack>
                    </Stack>
                    <Box mt={2}>
                        {viewType?.value === "calendar"
                            ? (type?.value === "resource"
                                ? <Calendar
                                    selectedMonth={selectedMonth}
                                    dates={dates}
                                    day={day}
                                    calendarData={resourceCalendarState?.calendarData}
                                    openTableView={openResourceTableView} t={t} />
                                : <Calendar
                                    selectedMonth={selectedMonth}
                                    dates={dates}
                                    day={day}
                                    calendarData={contactCalendarState?.calendarData}
                                    openTableView={openContactTableView} t={t} />)
                            : (type?.value === "resource"
                                ? <TableWithPagination
                                    heading={CheckInOutHeading(t)}
                                    rows={resourceTableData?.list}
                                    path={CheckInOutPath}
                                    showpagination={true}
                                    showpdfbtn={false}
                                    showexcelbtn={false}
                                    showSearch={false}
                                    onClick={() => false}
                                    tableType="no-side"
                                    dataType={CheckInOutType}
                                    handlePagination={handleResourcePagination}
                                    handleChangeLimit={handleResourceChangeLimit}
                                    totalRowsCount={resourceTableData?.count}
                                    page={resourcePage}
                                    limit={resourceLimit}
                                    height={'calc(100vh - 290px)'} />
                                : <TableWithPagination
                                    heading={CheckInOutHeading(t)}
                                    rows={contactTableData?.list}
                                    path={CheckInOutPath}
                                    showpagination={true}
                                    showpdfbtn={false}
                                    showexcelbtn={false}
                                    showSearch={false}
                                    onClick={() => false}
                                    tableType="no-side"
                                    dataType={CheckInOutType}
                                    handlePagination={handleContactPagination}
                                    handleChangeLimit={handleContactChangeLimit}
                                    totalRowsCount={contactTableData?.count}
                                    page={contactPage}
                                    limit={contactLimit}
                                    height={'calc(100vh - 290px)'} />)
                        }
                    </Box>
                </CustomPaper>

                <AlertDialog
                    open={resourceCalendarState?.openEmployee}
                    onClose={() => {
                        updateResourceCalendarState("openEmployee", false)
                        setResourceSearch("")
                    }}
                    header={t("Change Employee")}
                    isNormal={true}
                    component={
                        <Box p={2}>
                            <ChangeEmployee
                                dutyData={resourceCalendarState}
                                fetchMoreData={fetchMoreResource}
                                handleSelect={handleSelectResource}
                                search={resourceSearch}
                                handleSearchEmploee={handleSearchResource}
                                t={t} />
                        </Box>}
                />

                <AlertDialog
                    open={contactCalendarState?.openEmployee}
                    onClose={() => {
                        updateContactCalendarState("openEmployee", false)
                        setContactSearch("")
                    }}
                    header={t("Change Employee")}
                    isNormal={true}
                    component={
                        <Box p={2}>
                            <ChangeEmployee
                                dutyData={contactCalendarState}
                                fetchMoreData={fetchMoreContact}
                                handleSelect={handleSelectContact}
                                search={contactSearch}
                                handleSearchEmploee={handleSearchContact}
                                t={t} />
                        </Box>}
                />

                <AlertDialog
                    open={resourceCalendarState?.openTable}
                    onClose={() => updateResourceCalendarState("openTable", false)}
                    header={moment(resourceCalendarState?.viewDate).format("DD-MM-YYYY")}
                    lg={true}
                    component={
                        <Box p={2}>
                            <TableList type={"resource"} dutyData={resourceCalendarState} t={t} />
                        </Box>}
                />

                <AlertDialog
                    open={contactCalendarState?.openTable}
                    onClose={() => updateContactCalendarState("openTable", false)}
                    header={moment(contactCalendarState?.viewDate).format("DD-MM-YYYY")}
                    lg={true}
                    component={
                        <Box p={2}>
                            <TableList type={"contact"} dutyData={contactCalendarState} t={t} />
                        </Box>}
                />
                {drawer && (
                    <FilterGenerator
                        open={drawer}
                        width={450}
                        onClose={() => setDrawer(false)}
                        defaultState={defaultFilterState}
                        components={[
                            {
                                component: "dateTimePickerWithRange",
                                label: {
                                    startLabel: t("From"),
                                    endLabel: t("To"),
                                },
                                title: t("Check In"),
                                placeholder: {
                                    startLabel: t("From"),
                                    endLabel: t("To"),
                                },
                                state_name: "check_in_date",
                                // newDate:true,
                                value: filterData?.check_in_date,
                                is_popover: true
                            },
                            {
                                component: "dateTimePickerWithRange",
                                label: {
                                    startLabel: t("From"),
                                    endLabel: t("To"),
                                },
                                title: t("Check Out"),
                                placeholder: {
                                    startLabel: t("From"),
                                    endLabel: t("To"),
                                },
                                state_name: "check_out_date",
                                // newDate:true,
                                value: filterData?.check_out_date,
                                is_popover: true
                            },
                            {
                                component: "toggleButton",
                                value: filterData?.is_checkout,
                                options: [
                                    { label: t("Yes"), value: true },
                                    { label: t("No"), value: false },
                                ],
                                isMulti: false,
                                state_name: "is_checkout",
                                label: t("Is Checkout"),
                            },
                        ]}
                        onApply={(value) => onApplyFilter(value)}
                    />
                )}
            </>
        )
    }

    return <>{accessCheckRender(render, permission, "", loading)}</>

}

export default withTranslation("dutyRoster")(SelfCheckInEntries)