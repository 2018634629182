import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const AggreementCardBottomStyles = makeStyles((theme) => ({
    cardRoot: {
        padding: '12px',
        borderRadius: theme.palette.borderRadius,

        border: "1px solid #E4E8EE"

    },
    title: {
        fontSize:"1.125rem",
        color: "#091B29",
        fontFamily: FontFamilySwitch().bold

    },
    subtitle: {
        fontSize:"0.75rem",
        color: "#4E5A6B",
        fontFamily: FontFamilySwitch().semiBold,
    }
}));