import { Box } from '@mui/material'
import { addDays, addMonths, endOfMonth, endOfWeek, startOfMonth, startOfWeek, subDays, subMonths } from 'date-fns'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { AlertDialog, Subheader, UseDebounce } from '../../components'
import { config } from '../../config'
import { AlertContext, AuthContext, BackdropContext } from '../../contexts'
import { NetworkCall } from '../../networkcall'
import { AlertProps, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods } from '../../utils'
import { Calendars } from './calendars'
import { ImageGallery } from './components/imageGallery'
import { DeclinePopup } from './declinePopup'

const ParkingBooking = ({t}) => {
    const [companyList, setCompanyList] = React.useState([]);
    const [selectedCompany, setSelectedCompany] = React.useState({})
    // eslint-disable-next-line
    const [permission, setPermission] = React.useState({})
    const [propertyOption, setPropertyOption] = React.useState([])
    const [propertyValue, setPropertyValue] = React.useState({})
    const alert = React.useContext(AlertContext);
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext);
    const [parkingGroupData, setParkingGroupData] = React.useState([])
    const [parkingSlotData, setParkingSlotData] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [selectParking, setSelectParking] = React.useState("")
    const [groupingMasterId, setGroupingMasterId] = React.useState(null)
    const [calendarData, setCalendarData] = React.useState([])
    const [searchValue, setSearchValue] = React.useState("")

    const debounce = UseDebounce()

    // Daily Calendar Functions

    const [daily, setDaily] = React.useState({ date: new Date() })
    //nextdate
    const nextdate = () => {
        setDaily({ ...daily, date: addDays(daily, 1) })
    }
    //prevdate
    const prevdate = () => {
        setDaily({ ...daily, date: subDays(daily, 1) })
    }
    // weekly calendar functions
    const [weekdates, setWeekdates] = React.useState({ startDate: new Date(), endDate: addDays(new Date(), 6) })

    let weekstartDate = weekdates.startDate
    const weekendDate = weekdates.endDate

    var dates = []
    while (weekstartDate <= weekendDate) {
        dates.push(weekstartDate)
        weekstartDate = addDays(weekstartDate, 1)
    }
    //nextweek
    const weeknext = () => {
        setWeekdates({
            startDate: addDays(weekdates.endDate, 1), endDate: addDays(weekdates.endDate, 7)
        })
        calendarDataApiCall(groupingMasterId, addDays(weekdates.endDate, 1), addDays(weekdates.endDate, 7), 1)

    }
    //prevweek
    const weekprev = () => {
        setWeekdates({
            startDate: subDays(weekdates.startDate, 7), endDate: subDays(weekdates.startDate, 1)
        })
        calendarDataApiCall(groupingMasterId, subDays(weekdates.startDate, 7), subDays(weekdates.startDate, 1), 1)
    }

    // Month rendar funtion and state
    const [month, setMonth] = React.useState(new Date())
    const monthStart = startOfMonth(month);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);

    let day = startDate;

    let monthdays = []
    while (day <= endDate) {
        for (let i = 0; i < 7; i++) {
            day = addDays(day, 1);
            monthdays.push(day)
        }
    }
    //next month
    const monthnext = () => {
        setMonth(addMonths(month, 1))
        calendarDataApiCall(groupingMasterId, startOfMonth(addMonths(month, 1)), endOfMonth(addMonths(month, 1)), 24)

    }
    //prevmonth
    const monthprev = () => {
        setMonth(subMonths(month, 1))
        calendarDataApiCall(groupingMasterId, startOfMonth(subMonths(month, 1)), endOfMonth(subMonths(month, 1)), 24)
    }


    // Check Availability popup function
    const [openCA, setOpenCA] = React.useState(false)
    const [openCAUrl, setOpenCAUrl] = React.useState("")
    const openPopup = (data) => {
        setOpenCAUrl(data?.vehicle_image ? data?.vehicle_image : data?.visitor_vehicle_image ?? null)
        setOpenCA(true)
    }

    // Decline pop up function
    const [openDecline, setOpenDecline] = React.useState(false)
    const [openDeclineData, setOpenDeclineData] = React.useState({})
    const openDeclinePopup = (data) => {
        setOpenDeclineData(data)
        setOpenDecline(true)

    }
    //select parking data
    const selectedParking = (val) => {
        setSelectParking(val)
    }

    //get parking group master
    const getParkingGroupingMaster = (search="") => {
       const payload={
        property_id: propertyValue?.value,
        is_active:[true],
        offset:0,
        limit:null,
        search:search
    }
        NetworkCall(
            `${config.api_url}/queries/parking_grouping_master/list`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
        .then((response) => {
            setParkingGroupData(response?.data?.data?.parking_grouping_master)
            if (response?.data?.data?.parking_grouping_master.length > 0) {
                setGroupingMasterId(response?.data?.data?.parking_grouping_master?.[0]?.id)
            }
            else {
                setGroupingMasterId(null)
                setParkingSlotData([])
                setCalendarData([])
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    React.useEffect(() => {
        if (selectedCompany?.value) {
            let properties = selectedCompany?.property?.map((val) => {
                return {
                    value: val.id,
                    label: val.name
                }
            })
            setPropertyOption(properties)
            setPropertyValue(properties?.[0])
        }
        //eslint-disable-next-line
    }, [selectedCompany]);

    React.useEffect(() => {
        if (propertyValue?.value) {
            getParkingGroupingMaster()
        }
        // eslint-disable-next-line
    }, [propertyValue]);
    React.useEffect(() => {
        if (groupingMasterId) {
            if (selectParking?.select === "P") {
                selectedParkingDropdown(groupingMasterId, searchValue)
            }
            if (selectParking?.select === "nonlist") {
                selectParking?.selected === "Weekly" && calendarDataApiCall(groupingMasterId, weekdates?.startDate, weekdates?.endDate, 1)
                selectParking?.selected === "Monthly" && calendarDataApiCall(groupingMasterId, monthStart, monthEnd, 24)
            }
        }
        // eslint-disable-next-line
    }, [groupingMasterId, selectParking]);

    //propertychange
    const onPropertyChange = (val) => {
        setPropertyValue(val)
    }
    //handle search
    const setSearchValueFunc = (val) => {
        setSearchValue(val)
        debounce(() => searchTableFunction(val), 800);

    }
    const searchTableFunction = (val) => {
        selectedParkingDropdown(groupingMasterId, val)
    }
    //selectedParkingDropdown
    const selectedParkingDropdown = (id, search) => {
        setGroupingMasterId(id)
        setLoading(true)
        const payload = {
            grouping_master_id: id,
            search: search ?? ""
        }
        NetworkCall(
            `${config.api_url}/parking/bookingSlots`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setParkingSlotData({
                    data: response?.data?.data,
                    visitorCount: response?.data?.data?.filter((val) => val.parking_type === "Visitors").length,
                    reservedCount: response?.data?.data?.filter((val) => val.parking_type === "Reserved").length,
                    availableCount: response?.data?.data?.filter((val) => val.is_available === true).length,
                    bookedCount: response?.data?.data?.filter((val) => val.is_available !== true).length
                }

                )
                setLoading(false)

            })
            .catch((error) => {
                setLoading(false)

                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Internal error. Please try again later."),
                });

            });

    }
    //calendar data
    const calendarDataApiCall = (id, startDate, endDate, duration) => {
        setLoading(true)
        if (id?.length > 0) {
            const payload = {
                grouping_master_id: id,
                from: startDate,
                to: endDate,
                batchDuration: duration
            }
            NetworkCall(
                `${config.api_url}/parking/getAllSlots`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            )
                .then((response) => {
                    let myObject = response?.data?.data
                    let result = []
                    for (var key in myObject) {
                        if (myObject.hasOwnProperty(key)) {
                            result.push({
                                date: key,
                                calendarData: myObject[key]
                            })
                        }
                    }

                    setCalendarData(result)
                    setLoading(false)

                })
                .catch((error) => {
                    setLoading(false)
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Internal error. Please try again later."),
                    });

                });
        }
    }
    return (
        <Box>
            <Subheader
                hideBackButton
                title={t("Parking Booking")}
                select
                options={companyList}
                value={selectedCompany}
                onchange={(e) =>
                    setSelectedCompany(e)
                }
                onPropertyChange={(e) => {
                    onPropertyChange(e)
                }}
                selectProperty
                propertyOptions={propertyOption}
                propertyValue={propertyValue}
            />
            <Calendars
                openPopup={openPopup}
                openDeclinePopup={openDeclinePopup}
                parkingGroupData={parkingGroupData}
                selectedParkingDropdown={selectedParkingDropdown}
                parkingSlotData={parkingSlotData}
                loading={loading}
                selectedParking={selectedParking}
                setDaily={setDaily}
                daily={daily}
                prevdate={prevdate}
                nextdate={nextdate}
                weekprev={weekprev}
                weeknext={weeknext}
                monthnext={monthnext}
                monthprev={monthprev}
                month={month}
                weekdates={weekdates}
                dates={dates}
                monthdays={monthdays}
                calendarData={calendarData}
                searchValue={searchValue}
                setSearchValueFunc={setSearchValueFunc}
                t={t}
            />

            {/* Check Availability */}
            <AlertDialog open={openCA}
                onClose={() => setOpenCA(!openCA)}
                header={t("Vehicle Image")}
                component={<ImageGallery t={t} url={openCAUrl} />}
                isNormal
            />

            {/* Decline popup */}
            <AlertDialog open={openDecline}
                onClose={() => setOpenDecline(!openDecline)}
                header={openDeclineData?.reference_id}
                component={<DeclinePopup t={t} data={openDeclineData} selectedCompany={selectedCompany}/>}
                md
            />

        </Box>
    )
}
export default withTranslation("parkingArea")(ParkingBooking)
