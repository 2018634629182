import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { FormGenerator, LoadingSection } from "../../../components";
import ImgUpload from "../../../components/imgUpload";
import { useStyles } from "../styles";
import { FontFamilySwitch, LocalStorageKeys } from "../../../utils";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";

const Step1 = ({
  t,
  unitData,
  updateState,
  enumValue,
  manualUnitCategoryMasterResponse,
  handleAssetFile,
  updateImage,
  custom_options,
  uuid,
  setUUID,
  loading,
  companyId,
  manualVendorResponse,
}) => {
  const classes = useStyles();

  return (
    <Box>
      <Box
        sx={{
          mb: 2,
          p: 2,
          backgroundColor: "white",
          border: "1px solid #E4E8EE",
          borderRadius: "4px",
        }}
      >
        <ImgUpload
          t={t}
          srcType="external_unit"
          accept={"image/*"}
          setUUID={setUUID ?? null}
          uuid={uuid ?? null}
          updateImageURL={(val) => {
            updateImage(val);
          }}
          handleFile={handleAssetFile}
          selectedImageURL={unitData?.assestData ?? []}
          link
          id={2}
          imgHeight="125px"
          imgWidth="125px"
        />
      </Box>
      <Box height="10px"></Box>
      <Box
        sx={{
          p: 2,
          backgroundColor: "white",
          border: "1px solid #E4E8EE",
          mb: 2,
          borderRadius: "4px",
        }}
      >
        {loading ? (
          <Box sx={{ height: `calc(100vh - 125px)`, overflow: "auto" }}>
            <LoadingSection message="Fetching Details ..." top="30vh" />
          </Box>
        ) : (
          <>
            <Typography
              sx={{
                fontSize: "0.875rem",
                fontfamily: FontFamilySwitch().semiBold,
                color: "#091B29",
              }}
            >
              {t("Property & Unit Details")}
            </Typography>
            <Box height="10px" />
            <Box>
              <Grid container>
                <Grid item xs={12}>
                  <FormGenerator
                    t={t}
                    spacing={1.5}
                    components={[
                      {
                        size: {
                          xs: 12,
                          sm: 6,
                          md: 6,
                          lg: 6,
                        },
                        isActive: true,
                        component: "select",
                        label: t("Purpose"),
                        value: unitData?.purpose,
                        placeholder: t("Select Purpose"),
                        onChange: (value) => updateState("purpose", value),
                        error: unitData?.error?.purpose,
                        isRequired: true,
                        fontSize: "0.875rem !important",
                        fontFamily: FontFamilySwitch().semiBold,
                        options: enumValue.purpose,
                        menuPlacement: "auto",
                      },
                      {
                        size: {
                          xs: 12,
                          sm: 6,
                          md: 6,
                          lg: 6,
                        },
                        isActive: true,
                        component: "select",
                        label: t("Vendor / Owner"),
                        value: unitData?.developer,
                        placeholder: t("Select Vendor / Owner"),
                        onChange: (value) => updateState("developer", value),
                        error: unitData?.error?.developer,
                        isRequired: true,
                        fontSize: "0.875rem !important",
                        fontFamily: FontFamilySwitch().semiBold,
                        isPaginate: true,
                        menuPlacement: "auto",
                        loadOptions: (search, array, handleLoading) =>
                          loadOptionsApis(
                            "queries/contact/vendor_master/list",
                            {
                              company_id: companyId,
                            },
                            search,
                            array,
                            handleLoading,
                            "data",
                            {},
                            manualVendorResponse
                          ),
                        debounceTimeout: 800,
                      },
                      {
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 12,
                        },
                        isActive: true,
                        component: "text",
                        label: t("Property / Project Name"),
                        value: unitData?.property_name,
                        placeholder: t("Enter Property/Project Name"),
                        onChange: (value) =>
                          updateState("property_name", value.target.value),
                        error: unitData?.error?.property_name,
                        isRequired: true,
                        fontSize: "0.875rem !important",
                      },
                      {
                        size: {
                          xs: 12,
                          sm: 6,
                          md: 6,
                          lg: 6,
                        },
                        isActive: true,
                        component: "select",
                        label: t("Property Type"),
                        value: unitData?.property_type,
                        placeholder: t("Select Property Type"),
                        onChange: (value) =>
                          updateState("property_type", value),
                        error: unitData?.error?.property_type,
                        isRequired: true,
                        fontSize: "0.875rem !important",
                        fontFamily: FontFamilySwitch().semiBold,
                        isPaginate: true,
                        menuPlacement: "top",
                        loadOptions: (search, array, handleLoading) =>
                          loadOptionsApis(
                            "queries/property_group_master",
                            {
                              company_id: companyId,
                            },
                            search,
                            array,
                            handleLoading,
                            "property_group_master",
                            {},
                            null
                          ),
                        debounceTimeout: 800,
                      },
                      {
                        size: {
                          xs: 12,
                          sm: 6,
                          md: 6,
                          lg: 6,
                        },
                        isActive: true,
                        component: "select",
                        label: t("Unit Category"),
                        value: unitData?.category,
                        placeholder: t("Select Unit Category"),
                        onChange: (value) => updateState("category", value),
                        error: unitData?.error?.category,
                        isRequired: true,
                        fontSize: "0.875rem !important",
                        fontFamily: FontFamilySwitch().semiBold,
                        isPaginate: true,
                        menuPlacement: "top",
                        loadOptions: (search, array, handleLoading) =>
                          loadOptionsApis(
                            "queries/unit_category_master/list",
                            {
                              company_id: companyId,
                              orderByKey: "name",
                              orderByValue: "asc",
                            },
                            search,
                            array,
                            handleLoading,
                            "data",
                            {},
                            manualUnitCategoryMasterResponse
                          ),
                        debounceTimeout: 800,
                      },
                      {
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 12,
                        },
                        isActive: true,
                        component: "text",
                        label: t("Address"),
                        value: unitData?.address,
                        placeholder: t("Enter Address"),
                        onChange: (value) =>
                          updateState("address", value.target.value),
                        error: unitData?.error?.address,
                        isRequired: true,
                        fontSize: "0.875rem !important",
                        multiline: true,
                      },
                      {
                        size: {
                          xs: 12,
                          sm: 6,
                          md: 6,
                          lg: 6,
                        },
                        isActive: true,
                        component: "text",
                        label: t("Unit Name"),
                        value: unitData?.name,
                        placeholder: t("Enter Unit Name"),
                        onChange: (value) =>
                          updateState("name", value.target.value),
                        error: unitData?.error?.name,
                        isRequired: true,
                        fontSize: "0.875rem !important",
                      },
                      {
                        size: {
                          xs: 12,
                          sm: 6,
                          md: 6,
                          lg: 6,
                        },
                        isActive: true,
                        component: "select",
                        label: t("Revenue Type"),
                        value: unitData?.revenue_type,
                        placeholder: t("Select Revenue Type"),
                        onChange: (value) => updateState("revenue_type", value),
                        error: unitData?.error?.revenue_type,
                        isRequired: true,
                        fontSize: "0.875rem !important",
                        fontFamily: FontFamilySwitch().semiBold,
                        options: enumValue.revenue_type,
                        menuPlacement: "auto",
                      },
                      {
                        size: {
                          xs: 12,
                          sm: 6,
                          md: 6,
                          lg: 6,
                        },
                        isActive: true,
                        component: "select",
                        menuPlacement: "top",
                        label: t("Unit Type"),
                        value: unitData?.unit_type,
                        placeholder: t("Select Unit Type"),
                        onChange: (value) => updateState("unit_type", value),
                        isPaginate: true,
                        error: unitData?.error?.unit_type,
                        isRequired: true,
                        fontSize: "0.875rem !important",
                        loadOptions: (search, array, handleLoading) =>
                          loadOptionsApis(
                            "queries/unit_type",
                            {
                              company_id: companyId,
                            },
                            search,
                            array,
                            handleLoading,
                            "data",
                            {}
                          ),
                        debounceTimeout: 800,
                      },
                      {
                        size: {
                          xs: 12,
                          sm: 6,
                          md: 6,
                          lg: 6,
                        },
                        isActive: true,
                        component: "textWithSelect",
                        label: t("Total Area"),
                        value: unitData?.total_area,
                        placeholder: t("Enter Total Area"),
                        onChange: (value) =>
                          updateState("total_area", value.target.value),
                        error: unitData?.error?.total_area,
                        isRequired: true,
                        fontSize: "0.875rem !important",
                        type: "number",
                        className: classes.endAdornment,
                        customOption: custom_options,
                        classSelect: classes.selectParent,
                        selectChange: (value) => updateState("uom_id", value),
                        selectedValue: unitData?.uom_id ?? "",
                      },
                      {
                        size: {
                          xs: 12,
                          sm: 6,
                          md: 6,
                          lg: 6,
                        },
                        isActive: true,
                        component: "text",
                        label: t("Bed"),
                        value: unitData?.bed,
                        placeholder: t("Enter Bed"),
                        onChange: (value) =>
                          updateState("bed", value.target.value),
                        error: unitData?.error?.bed,
                        isRequired: true,
                        fontSize: "0.875rem !important",
                        type: "number",
                      },
                      {
                        size: {
                          xs: 12,
                          sm: 6,
                          md: 6,
                          lg: 6,
                        },
                        isActive: true,
                        component: "text",
                        label: t("Bath"),
                        value: unitData?.bath,
                        placeholder: t("Enter Bath"),
                        onChange: (value) =>
                          updateState("bath", value.target.value),
                        error: unitData?.error?.bath,
                        isRequired: true,
                        fontSize: "0.875rem !important",
                        type: "number",
                      },
                      {
                        size: {
                          xs: 12,
                          sm: 6,
                          md: 6,
                          lg: 6,
                        },
                        isActive: true,
                        component: "text",
                        label: t("Gps Latitude"),
                        value: unitData?.latitude,
                        placeholder: t("Enter Latitude"),
                        onChange: (value) =>
                          updateState("latitude", value.target.value),
                        error: unitData?.error?.latitude,
                        isRequired: true,
                        fontSize: "0.875rem !important",
                        type: "number",
                      },
                      {
                        size: {
                          xs: 12,
                          sm: 6,
                          md: 6,
                          lg: 6,
                        },
                        isActive: true,
                        component: "text",
                        label: t("Gps Longitude"),
                        value: unitData?.longitude,
                        placeholder: t("Enter Longitude"),
                        onChange: (value) =>
                          updateState("longitude", value.target.value),
                        error: unitData?.error?.longitude,
                        isRequired: true,
                        fontSize: "0.875rem !important",
                        type: "number",
                      },
                      {
                        size: {
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 12,
                        },
                        isActive: true,
                        component: "text",
                        label: t("Notes"),
                        value: unitData?.notes,
                        placeholder: t("Enter Notes"),
                        onChange: (value) =>
                          updateState("notes", value.target.value),
                        error: unitData?.error?.notes,
                        isRequired: false,
                        fontSize: "0.875rem !important",
                        multiline: true,
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Step1;
