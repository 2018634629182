import { useContext, useEffect, useState } from "react";
import { Subheader } from "../../components";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { LocalStorageKeys, accessCheckRender, getCompanyOption, getRoutePermissionNew, pageName } from "../../utils";
import { DashBoard } from "./dashboard"
import { withTranslation } from "react-i18next";

const ContractManagerDashboard = (props) => {
  const { loading, handleLoading, t } = props;
  const module_id = localStorage.getItem(LocalStorageKeys?.activeRoleModuleId);

  // useContext
  const auth = useContext(AuthContext);
  const backdrop = useContext(BackdropContext);
  const alert = useContext(AlertContext);

  // useState
  const [company_list, set_company_list] = useState([]);
  const [selected_company, set_selected_company] = useState({});
  const [selected_teams, set_selected_teams] = useState({})
  const [permission, set_permission] = useState({});

  // useEffect to get permission
  useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      set_permission(perm);
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          set_company_list(company?.list);
          set_selected_company(company?.selected);
          handleLoading(false);
        }
      }
    }
    // eslint-disable-next-line
  }, [auth])

  // Function to handle company change
  const handleCompanyChange = (company) => {
    set_selected_company(company);
  };

  // Function to handle teams change
  const handleTeamsChange = (teams) => {
    set_selected_teams(teams);
  }

  const render = () => {
    return (
      <>
        <Subheader
          hideBackButton={true}
          title={t("Contract Manager Dashboard")}
          select
          options={company_list ?? []}
          value={selected_company}
          onchange={(company) => handleCompanyChange(company)}
          companyId={selected_company?.value}
          moduleId={module_id}
          selectTeamsOptions
          selectedTeams={selected_teams}
          handleTeamsChange={handleTeamsChange} />
        <DashBoard
          selected_company={selected_company}
          selected_teams={selected_teams} t={t} />
      </>
    );
  };

  return (
    <div>
      {accessCheckRender(render, permission, pageName.dashboard, loading)}
    </div>
  );
};
export default withTranslation("contractManagerDashboard")(ContractManagerDashboard)
