import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  Label: {
    color: theme.typography.color.tertiary,
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: (props) => props?.labelSize ?? "0.875rem",
    marginBottom: (props) => props?.labelMarginBottom ?? "5px",
  },
  text: {},

  textbox: {
    backgroundColor: (props) => props?.color ?? "auto",
    borderRadius: theme.palette.borderRadius,
    border: "none",
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
      backgroundColor: "#fff",
    },
    [`& fieldset`]: {
      borderRadius: theme.palette.borderRadius,
      height: (props) => (props.multiline ? "unset" : props?.height ?? 50),
      border: (props) => props?.border ?? "1px solid #CED3DD",
      "&:hover": {
        border: "1px solid red",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: (props) => props?.padding ?? "11px 14px",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: theme?.palette?.primary?.main,
      },
    },
  },
  Label1: {
    color: theme.typography.color.tertiary,
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: (props) => props?.labelSize ?? "0.75rem",
    marginBottom: (props) => props?.labelMarginBottom ?? "5px",
  },
  progressBar: {
    borderRadius: '100px'
  }
}));
