import { Box, Button, Container, Grid, Hidden, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropGoToComponent from '../../assets/propgoto';
import { TextBox } from '../../components';
import { config } from '../../config';
import { AlertContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { Routes } from '../../router/routes';
import { AlertProps, NetWorkCallMethods, RetainLocalStorage, useWindowDimensions, FontFamilySwitch } from "../../utils";
import { PasswordInfo } from '../../components/passwordInfo';

const useStyles = makeStyles((theme) => ({
    signupform: {
        overflow: "overlay",
        padding: "0px 70px",
        alignContent: "start",
        height: (size) => size?.height
    },
    image: {
        marginTop: "70px",
        marginBottom: (size) => size?.height > 412.16 ? ((size?.height - 372.16) / 2) : "20px"
    },
    text: {
        fontSize:"1.5rem",
        fontFamily: FontFamilySwitch().extraBold,
        color: theme.typography.color.primary
    },
    btn: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 6px 10px #00000014",
        opacity: 1,
        padding: "8px",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    verificationText: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().regular,
        textAlign: "center",
        color: theme.typography.color.primary,
        backgroundColor: theme.palette.background.secondary,
        borderRadius: "15px",
        padding: "20px"

    },
    poweredby: {
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
    },
    pa: {
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
        fontSize:"0.75rem"
    },
    title: {
        fontFamily: FontFamilySwitch().extraBold,
        fontSize:"1.5rem",
        color: "#091B29",
    },
    content: {
        width: "inherit",
    },
    backgroundImage: {
        backgroundColor: "#7EA1FF",
        height: "213px",
        backgroundImage: `url("/images/login_background_1-min.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "100%"
    },
    img: {
        height: "8%",
        width: "28%",
    },

}))
const InitialState = {
    password: "",
    conform_password:"",
    error: {
        password: "",
        conform_password:"",
    }
}

export const ResetPasswordPage = () => {
    const size = useWindowDimensions()
    const classes = useStyles(size);
    const navigate = useNavigate();

    const alert = React.useContext(AlertContext);
    const [value, setValue] = React.useState({ ...InitialState });

    const updateState = (key, email) => {
        let error = value.error;
        error[key] = "";
        setValue({ ...value, [key]: email, error });
    };

    const isIamValideToLogin = () => {
        let isValid = true;
        let error = value.error;
        if (value.email.length === 0) {
            isValid = false;
            error.email = "Email is Required";
        }
        setValue({ ...value, error });
        return isValid;
    };
    const submit = () => {
        if (isIamValideToLogin()) {
            const payload = {
                email_id: value?.email
            };
            NetworkCall(
                `${config.authapi}/auth/forgotpassword`,
                NetWorkCallMethods.post,
                payload,
                null,
                false,
                false
            )
                .then((response) => {
                    if (response.status === 200) {
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.success,
                            msg: "Email send Successfully",
                        });
                        navigate(-1)

                    } else {
                        RetainLocalStorage();
                        navigate(Routes.login);
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.success,
                            msg: "Invalid Email",
                        });
                    }
                })
                .catch((err) => {

                    console.log(err)
                    RetainLocalStorage();
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "User Not Found",
                    });
                });
        } else {
            return false;
        }
    };
    return <>
        <Hidden smDown>
            <div className={classes.root}>
                <Grid container className={classes.signupform}>
                    <Grid item xs={12} className={classes.image}>
                        <PropGoToComponent />
                    </Grid>
                    <Grid item xs={12} marginBottom={"16px"}>
                        <Typography className={classes.title}>Reset your password</Typography>
                    </Grid>
                    <Grid item xs={12} marginBottom={"16px"}>
                        <TextBox
                        isrequired
                            label='Enter New Password'
                            placeholder='Enter New Password'
                            value={value?.password}
                            isError={value?.error?.password.length > 0}
                            errorMessage={value?.error?.password}
                            onChange={(e) => updateState('password', e.target.value)} />
                    </Grid>
                    <Grid item xs={12} marginBottom={"16px"}>
                        <TextBox
                        isrequired
                            label='Confirm New Password'
                            placeholder='Confirm New Password'
                            value={value?.conform_password}
                            isError={value?.error.conform_password?.length > 0}
                            errorMessage={value?.error?.conform_password}
                            onChange={(e) => updateState('conform_password', e.target.value)} />
                    </Grid>
                    <Grid item xs={12}  marginBottom={"10px"}>
                    <PasswordInfo/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            className={classes.btn}
                            onClick={submit}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Hidden>
        <Hidden smUp>
            <div>
                <Container maxWidth="sm" style={{ padding: 0 }}>
                    <Grid container style={{ height: "100vh", backgroundColor: "white" }}>
                        <Grid
                            item
                            className={classes.content}
                            style={{ height: size.height > 580 ? size.height - 82 : 498 }}
                        >
                            <Grid item xs={12} className={classes.backgroundImage} />
                            <Grid style={{ padding: "0px 16px 0px 16px" }}>
                                <Box height={"24px"} />
                                <Grid
                                    container
                                    xs={12}
                                    direction="row"
                                    justifyContent={"space-between"}
                                    alignContent={"center"}
                                >
                                    <img
                                        src="/images/PA Logo_1.svg"
                                        alt="logo"
                                        className={classes.img}
                                    />
                                </Grid>
                                <Box height={"16px"} />
                                <Grid item xs={12}>
                                    <Typography className={classes.title}>Reset your password</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                <TextBox
                            label='Enter New Password'
                            placeholder='Enter New Password'
                            value={value?.password}
                            isError={value?.error?.password.length > 0}
                            errorMessage={value?.error?.password}
                            onChange={(e) => updateState('password', e.target.value)} />
                                </Grid>
                                <Grid item xs={12}>
                                <TextBox
                            label='Enter New Password'
                            placeholder='Enter New Password'
                            value={value?.password}
                            isError={value?.error?.password.length > 0}
                            errorMessage={value?.error?.password}
                            onChange={(e) => updateState('password', e.target.value)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} alignSelf={"self-end"} style={{ padding: "16px 16px 25px 16px" }}>
                            <Button
                                fullWidth
                                variant="contained"
                                className={classes.btn}
                                onClick={submit}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </Hidden>
    </>
}
