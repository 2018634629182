import React, { useState, useEffect } from "react";
import { Box, Stack, IconButton, Divider, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { useStyles } from "./style";
import CloseIcon from "../../assets/closeIcon";
import { CustomTypography } from "../companyCreation/components";
import { FormGenerator, LoadingSection } from "../../components";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { AlertContext } from "../../contexts";
// import ConfirmationDialog from "../../components/confirmationDialog";

const AddApproval = ({
  t,
  closeDrawer,
  getApprovalWorkflowById,
  approvalWorkflowData,
  loading,
  companyValue,
  getApprovalWorkflow,
}) => {
  const classes = useStyles();
  const [selectedLevel, setSelectedLevel] = useState(null);
  const alert = React.useContext(AlertContext);
  const [levelSelections, setLevelSelections] = useState({});
  const [managementSelections, setManagementSelections] = useState({});
  const [employeeSelections, setEmployeeSelections] = useState({});
  const userProfileID = localStorage.getItem("profileID");
  const [selectionOrder, setSelectionOrder] = useState({});
  const [errors, setErrors] = useState({});
  const [button, setButton] = useState(true);
  const [reportingToCount, setReportingToCount] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogCallback, setDialogCallback] = useState(null);

  const levelOptions = [
    { label: t("None"), value: "0" },
    ...Array.from({ length: 5 }, (_, index) => ({
      label: t(`Level ${index + 1}`),
      value: String(index + 1),
    })),
  ];

  useEffect(() => {
    getApprovalWorkflowById();
  }, []);

  useEffect(() => {
    if (approvalWorkflowData?.levels) {
      const levels = approvalWorkflowData.levels || [];

      const maxLevel = levels.reduce(
        (max, level) => Math.max(max, parseInt(level.value, 10)),
        0
      );

      const initialLevel = levelOptions.find(
        (option) => option.value === String(maxLevel)
      );

      setSelectedLevel(initialLevel || levelOptions[0]);

      const newLevelSelections = {};
      const newManagementSelections = {};
      const newEmployeeSelections = {};
      const newSelectionOrder = {};

      levels.forEach((level) => {
        const levelNo = `level_${level.value}`;

        if (level.approvalLevel) {
          newLevelSelections[levelNo] = {
            label: t(level.approvalLevel.label),
            value: level.approvalLevel.value,
          };
          newSelectionOrder[levelNo] = level.approvalLevel.label;
        }

        if (level.approvalLevel?.label === "Management Role") {
          newManagementSelections[levelNo] = {
            label: level.assignedTo.label,
            value: level.assignedTo.value,
            contact_id: level.assignedTo.value,
          };
        } else if (level.approvalLevel?.label === "Employee") {
          newEmployeeSelections[levelNo] = {
            label: level.assignedTo.label,
            value: level.assignedTo.value,
          };
        }
      });

      setLevelSelections(newLevelSelections);
      setManagementSelections(newManagementSelections);
      setEmployeeSelections(newEmployeeSelections);
      setSelectionOrder(newSelectionOrder);
    }
  }, [approvalWorkflowData]);

  const handleLevelChange = (value) => {
    setSelectedLevel(value);
    setLevelSelections({});
    setManagementSelections({});
    setEmployeeSelections({});
    setSelectionOrder({});
    setErrors({});
    setReportingToCount(0);
  };
  const [departmentSelected, setDepartmentSelected] = useState(false);

  const updateLevelSelection = (level, value) => {
    const isReportingTo = value?.label === "Reporting To";
    const isDepartment = value?.label === "Department";

    setLevelSelections((prev) => {
      const prevWasReportingTo =
        prev[`level_${level}`]?.label === "Reporting To";
        const prevWasDepartment = prev[`level_${level}`]?.label === "Department";

        if (isDepartment && departmentSelected) {
          alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Department can only be selected once"),
          });
          return prev; 
        }

        setDepartmentSelected((prevDept) =>
          isDepartment ? true : prevWasDepartment ? false : prevDept
        );
    

      setReportingToCount((prevCount) =>
        isReportingTo && !prevWasReportingTo
          ? prevCount + 1
          : !isReportingTo && prevWasReportingTo
          ? prevCount - 1
          : prevCount
      );

      return {
        ...prev,
        [`level_${level}`]: value,
      };
    });

    setSelectionOrder((prev) => ({
      ...prev,
      [`level_${level}`]: value.label,
    }));

    setErrors((prev) => ({
      ...prev,
      [`level_${level}`]: "",
    }));
  };

  const updateManagementSelection = (level, value) => {
    setManagementSelections((prev) => ({
      ...prev,
      [`level_${level}`]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [`management_${level}`]: "", 
    }));
  };

  const updateEmployeeSelection = (level, value) => {
    setEmployeeSelections((prev) => ({
      ...prev,
      [`level_${level}`]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [`employee_${level}`]: "", 
    }));
  };

  const getLevelComponents = () => {
    const levelCount = selectedLevel ? Number(selectedLevel.value) : 0;
    if (!levelCount) return [];

    let components = [];
    let reportingToCounter = 0; 
    let lastWasReportingTo = false; 

    for (let i = 1; i <= levelCount; i++) {
      let label = t(`Level ${i}`); 
      let notation = ""; 

      if (levelSelections[`level_${i}`]?.label === "Reporting To") {
        if (lastWasReportingTo) {
          reportingToCounter += 1; 
        } else {
          reportingToCounter = 1; 
        }

        notation = ` (N+${reportingToCounter})`;
        lastWasReportingTo = true; 
      } else {
        lastWasReportingTo = false; 
        reportingToCounter = 0; 
      }

      components.push({
        size: { xs: 12, sm: 12, md: 12, lg: 12 },
        component: "select",
        placeholder: t("Select Approver"),
        isActive: true,
        label: `${label}${notation}`, 
        isRequired: true,
        isPaginate: true,
        fontSize: "0.875rem !important",
        value: levelSelections[`level_${i}`] || "",
        onChange: (value) => updateLevelSelection(i, value),
        error: errors[`level_${i}`],
        loadOptions: (search, array, handleLoading) => {
          return loadOptionsApis(
            "approval_workflow/get_approval_levels",
            {},
            search,
            array,
            handleLoading,
            "data",
            {}
          );
        },
        debounceTimeout: 800,
      });

      if (levelSelections[`level_${i}`]?.label === "Management Role") {
        components.push({
          size: { xs: 12, sm: 12, md: 12, lg: 12 },
          component: "select",
          isActive: true,
          label: t("Select Management"),
          placeholder: t("Select Management"),
          isRequired: true,
          isPaginate: true,
          fontSize: "0.875rem !important",
          value: managementSelections[`level_${i}`] || "",
          onChange: (value) => updateManagementSelection(i, value),
          error: errors[`management_${i}`],
          loadOptions: (search, array, handleLoading) => {
            return loadOptionsApis(
              "my_organisation/get_list",
              {},
              search,
              array,
              handleLoading,
              "data",
              {},
              (response) =>
                response.map((item) => ({
                  label: item.role_name,
                  value: item.id,
                  user_profile: item.user_profile_id,
                }))
            );
          },
          debounceTimeout: 800,
        });
      }

      if (levelSelections[`level_${i}`]?.label === "Employee") {
        components.push({
          size: { xs: 12, sm: 12, md: 12, lg: 12 },
          component: "select",
          isActive: true,
          label: t("Select Employee"),
          placeholder: t("Select Employee"),
          isRequired: true,
          isPaginate: true,
          fontSize: "0.875rem !important",
          value: employeeSelections[`level_${i}`] || "",
          onChange: (value) => updateEmployeeSelection(i, value),
          error: errors[`employee_${i}`],
          loadOptions: (search, array, handleLoading) => {
            return loadOptionsApis(
              "queries/employee_list",
              {},
              search,
              array,
              handleLoading,
              "data",
              {},
            );
          },
          debounceTimeout: 800,
        });
      }
    }

    return components;
  };

  const validateFields = () => {
    let newErrors = {};

    const levelCount = selectedLevel ? Number(selectedLevel.value) : 0;

    if (levelCount !== 0) {
      for (let i = 1; i <= levelCount; i++) {
        if (!levelSelections[`level_${i}`]?.value) {
          newErrors[`level_${i}`] = t(
            `Please select an approver for Level ${i}`
          );
        }

        if (
          levelSelections[`level_${i}`]?.label === "Management Role" &&
          !managementSelections[`level_${i}`]?.value
        ) {
          newErrors[`management_${i}`] = t(
            `Please select a management for Level ${i}`
          );
        }

        if (
          levelSelections[`level_${i}`]?.label === "Employee" &&
          !employeeSelections[`level_${i}`]?.value
        ) {
          newErrors[`employee_${i}`] = t(
            `Please select an employee for Level ${i}`
          );
        }
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const checkConsecutiveSelections = () => {
    const levelCount = selectedLevel ? Number(selectedLevel.value) : 0;
    for (let i = 1; i < levelCount; i++) {
      if (
        employeeSelections[`level_${i}`]?.value &&
        employeeSelections[`level_${i + 1}`]?.value &&
        employeeSelections[`level_${i}`]?.value === employeeSelections[`level_${i + 1}`]?.value
      ) {
        return true;
      }
    }
    return false;
  };

  const handleDialogClose = (confirm) => {
    setDialogOpen(false);
    if (confirm && dialogCallback) {
      dialogCallback();
    } else {
      setButton(true); // Reset the button state when "No" is clicked
    }
  };

  const createApproval = () => {
    setButton(false);
    if (!validateFields()) {
      setButton(true);
      return;
    }

    if (checkConsecutiveSelections()) {
      setDialogCallback(() => createApprovalConfirmed);
      setDialogOpen(true);
      return;
    }

    createApprovalConfirmed();
  };

  const createApprovalConfirmed = () => {
    if (selectedLevel?.value === "0") {
      const payload = {
        workflow_id: approvalWorkflowData?.id,
        level: 0,
      };
      NetworkCall(
        `${config.api_url}/approval_workflow/update_approval_levels`,
        NetWorkCallMethods.post,
        { payloads: [payload] },
        null,
        true,
        false
      )
        .then((res) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: t("Approval Workflow Deactivated Successfully"),
          });
          closeDrawer();
          getApprovalWorkflow(0, 10);
          setButton(true);
        })
        .catch((err) => {
          console.log(err);
          setButton(true);
        });

      return;
    }

    const payloads = [];

    for (let i = 1; i <= Number(selectedLevel.value); i++) {
      if (i > 0) {
        const selectionType = selectionOrder[`level_${i}`] || "";
        const payload = {
          company: companyValue,
          workflow_id: approvalWorkflowData?.id,
          level: i,
          approval_level_id: levelSelections[`level_${i}`]?.value,
          created_by: userProfileID,
          updated_by: userProfileID,
          assigned_to: "",
          is_active: true,
        };

        if (selectionType === "Employee") {
          payload.assigned_to = employeeSelections[`level_${i}`]?.value;
        } else if (selectionType === "Management Role") {
          payload.assigned_to =
            managementSelections[`level_${i}`]?.user_profile;
        } else if (selectionType === "Reporting To") {
          payload.assigned_to = null; 
        } else if (selectionType === "Department") {
          payload.assigned_to = null; 
        }

        payloads.push(payload);
      }
    }
    NetworkCall(
      `${config.api_url}/approval_workflow/update_approval_levels`,
      NetWorkCallMethods.post,
      { payloads },
      null,
      true,
      false
    )
      .then((res) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Approval Workflow Updated Successfully"),
        });
        closeDrawer();
        getApprovalWorkflow(0, 10);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box className={classes.formRoot}>
      <Box sx={{ height: "calc(100vh - 64px)", overflowY: "auto" }}>
        <Stack p={2} direction={"row"} gap={2}>
          <IconButton sx={{ padding: 0 }} onClick={closeDrawer}>
            <CloseIcon htmlColor="#7C8594" />
          </IconButton>
          <CustomTypography className={classes.drawerTitle}>
            {t("Create Workflow Approvals")}
          </CustomTypography>
        </Stack>
        <Divider />
        <Box height="10px" />
        <Box p={3}>
          {loading ? (
            <LoadingSection />
          ) : (
            <FormGenerator
              spacing={1.5}
              components={[
                {
                  size: { xs: 12, sm: 12, md: 12, lg: 12 },
                  component: "text",
                  isActive: true,
                  label: t("Trigger"),
                  isRequired: true,
                  fontSize: "0.875rem !important",
                  value: t(approvalWorkflowData?.workflow_type) || "",
                  isReadonly: true,
                },
                {
                  size: { xs: 12, sm: 12, md: 12, lg: 12 },
                  component: t("select"),
                  isActive: true,
                  label: t("Number of Levels"),
                  isRequired: true,
                  fontSize: "0.875rem !important",
                  options: levelOptions,
                  value: selectedLevel,
                  onChange: handleLevelChange,
                  error: errors.selectedLevel,
                },
                ...getLevelComponents(),
              ]}
            />
          )}
        </Box>
      </Box>
      <Box className={classes.bottombtn}>
        <Button
          disabled={!button}
          onClick={createApproval}
          variant="contained"
          className={classes.createbtn}
          fullWidth
        >
          {t("Update")}
        </Button>
      </Box>
      {/* <ConfirmationDialog
        title={t("Confirmation")}
        message={t(
          "Are you sure you want to select the same person for consecutive levels?"
        )}
        open={dialogOpen}
        onClose={() => handleDialogClose(false)}
        type="confirmation"
        yesClick={() => handleDialogClose(true)}
        noClick={() => handleDialogClose(false)}
        t={t}
      /> */}
    </Box>
  );
};

export default AddApproval;