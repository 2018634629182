import { Box, Divider, Stack, Typography } from "@mui/material"
import { useStyles } from "./styles";
import { Money } from "../../assets";
import React from "react";
import { FontFamilySwitch } from "../../utils";

export const HourBillInput = ({ value = 0, onChange = () => false, status = "", t = () => false }) => {
    const classes = useStyles();

    const [data, setData] = React.useState({
        isEnter: false
    });



    const updateState = (key, value) => {
        setData({
            ...data,
            [key]: value
        })
    }

    return (
        <>
            {
                data?.isEnter ?
                    <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.enterRoot}>

                        <Box marginLeft="4px">
                            <Typography className={classes.billing_hour}>{t("Enter billing hour")}</Typography>
                        </Box>
                        <Box>
                            <Stack
                                direction="row"
                                className={classes.inputRoot}
                                divider={<Divider orientation="vertical" flexItem />}
                                spacing={1}
                            >
                                <input value={data?.value} onChange={(e) => onChange(e.target.value)} style={{ width: "40px", padding: "0px", fontFamily: FontFamilySwitch().bold, fontSize: "0.875rem" }} disabled={status === "Completed" ? true : false} />
                                <Typography className={classes.hr}>Hr</Typography>


                            </Stack>
                        </Box>
                        {status !== "Completed" &&
                            <Box>
                                <Typography className={classes.editBtn} onClick={() => updateState("isEnter", false)}>{t("Update")}</Typography>
                            </Box>
                        }
                    </Box>

                    :
                    <Box display="flex" alignItems="center" className={classes.moneyRoot}>
                        <Box marginTop="4px">
                            <Money />
                        </Box>
                        <Box flexGrow={1} marginInlineStart="8px">
                            <Typography className={classes.billing_hour}>{t("Billing Hour")}</Typography>
                        </Box>
                        <Box>
                            <Typography className={classes.billing_hourValue}>{`${value} Hrs`}</Typography>
                        </Box>
                        {status !== "Completed" &&
                            <Box marginInlineStart="14px">
                                <Typography className={classes.editBtn} onClick={() => updateState("isEnter", true)}>{t("Edit")}</Typography>
                            </Box>
                        }
                    </Box>

            }

        </>
    )
}