import { Box, Drawer } from "@mui/material"
import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import { useNavigate, useLocation } from "react-router-dom"
import { FilterGenerator, LoadingSection, Subheader, UseDebounce } from "../../components"
import AssignModal from "../../components/assignModal/reAssignModal"
import { config } from "../../config"
import { AlertContext, AuthContext, BackdropContext } from "../../contexts"
import { NetworkCall } from "../../networkcall"
import { Routes } from "../../router/routes"
import { accessCheckRender, AlertProps, enum_types, enumSelect, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods } from "../../utils"
import InspectionDetails from "./components/cards"
import InspectionTable from "./components/table"
import { InspectionStyles } from "./styles"
import moment from "moment"
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions"
const InspectionList = ({ t }) => {
    const { state } = useLocation();
    const classes = InspectionStyles()
    const navigate = useNavigate()
    const [assignInspector, setAssignInspector] = useState({
        bool: false,
        data: null
    });
    const [page, setPage] = useState(1)
    const [permissions, setPermission] = React.useState({})
    const [limit, setLimit] = useState(10)
    const [tableData, setTableData] = useState([])
    const [totalRows, setTotalRows] = useState([])
    const [searchText, setSearchText] = React.useState("")
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const [details, setDetails] = useState([])
    const debounce = UseDebounce()
    const alert = React.useContext(AlertContext);
    const backdrop = React.useContext(BackdropContext)
    const auth = React.useContext(AuthContext)
    const [loading, setLoading] = React.useState(true)
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)
    const [drawer, setDrawer] = React.useState(false)
    const [statusOptionList, setStatusOptionsList] = React.useState([])
    const [filterData, setFilterData] = React.useState({
        status: null,
        dateRange: {
            startDate: null,
            endDate: null
        },
        resource: null
    });
    // handleAdd function
    const AssignInspector = async (type, data) => {
        const routeType = state.type === "move-in" ? Routes?.moveInInspectionItemDetails : state.type === "move-out" ? Routes?.moveOutInspectionItemDetails : Routes?.unitHandOverInspectionItemDetails
        if (type === "view") {
            // if(data.status !== "Yet to assign"){
            navigate(routeType, {
                state: {
                    id: state.id,
                    agreement_inspection_id: data.id,
                    title: data.reference_id,
                    status: data.status,
                    type: state.type,
                    agreement_id: state?.agreement_id,
                    data: {...data,request_id:state?.id,currency_code:selectedCompany?.code},
                }
            })
            // }

        } else {
            setAssignInspector({
                bool: true,
                data: data?.id
            })
        }
    }
    //goback
    const goBack = () => {
        navigate(-1)
    }
    const getEnum = async () => {
        const result = await enumSelect([
            enum_types?.agreement_inspection_status_type_new
        ]);
        setStatusOptionsList(result?.agreement_inspection_status_type_new)
    };
    //get tableData 
    const getAllInspectionList = (offset = 0, limit = 10, searchText, filterData) => {
        // setLoading(true)
        const payload = {
            id: state?.id,
            offset: offset,
            limit: limit,
            search: searchText,
            type: state?.type,
            "resources": filterData?.resource?.map((x) => { return x?.value }) ?? [],
            "start_date": filterData?.dateRange?.startDate ? moment(filterData?.dateRange?.startDate).format("YYYY-MM-DD") : null,
            "end_date": filterData?.dateRange?.endDate ? moment(filterData?.dateRange?.endDate).format("YYYY-MM-DD") : null,
            "status": filterData?.status?.map((x) => { return x?.value }) ?? null
        }

        NetworkCall(
            `${config.api_url}/agreement_inspection/get_all_inspection`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setDetails(response?.data?.data?.detail)
                const result = response?.data?.data?.list?.data?.map((val) => {
                    return {
                        ...val,
                        unit_reference_id: `${val?.unit_reference_id}`,
                        other_hours: val?.other_hours > 0 ? `${val?.other_hours} ${val?.other_hours > 1 ? "hrs" : "hr"}` : 0,
                        KPI: val?.overbooking_count > 0 ? "Delayed" : val?.ontime_count > 0 ? "Ontime" : "-",
                        type: val?.unit_category === "Workspace Unit" ? "Workspace Unit" : val?.is_parking_component ? "Parking Component" : val?.is_parking_unit ? "Parking Unit" : "Residential",
                        parking: (val?.is_parking_unit || val?.is_parking_component) ? true : false,
                        actual_hours: (val?.status === "Completed" || val?.status === "Yet To Verify") ? (val?.actual_hours > 0 ? `${val?.actual_hours} ${val?.actual_hours > 1 ? "hrs" : "hr"}` : "<1 hr") : "-"

                    }
                })
                setTableData(result)
                setTotalRows(response?.data?.data?.list?.count)
                setLoading(false)

            }).catch((err) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("data not found"),
                });
                setLoading(false)

            })
    }

    //initial load
    React.useEffect(() => {
        if (auth) {
            let company = getCompanyOption(backdrop, auth, alert)
            const perm = getRoutePermissionNew(auth)
            if (perm) {
                setPermission(perm)
                if (company && perm?.read) {
                    setSelectedCompany(company?.selected)
                    getEnum()
                    getAllInspectionList(0, limit, "", filterData)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth])
    //on search
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getAllInspectionList(0, 10, e, filterData)
    }

    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getAllInspectionList(offset, limit, "", filterData)
    }
    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getAllInspectionList(0, limit, "", filterData)
    }
    //for assigning
    const handleAssign = (payload) => {
        setIsDisableBtn(true)
        NetworkCall(
            `${config.api_url}/agreement_inspection/assign_resource`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Assigned Successfully"),
                });
                getAllInspectionList(0, limit, "", filterData)
                setAssignInspector({
                    bool: false,
                    data: null
                })
                handleClose()
            }).catch((err) => {
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("data not found"),
                });
            })
    }
    //handleClose Assign Modal
    const handleClose = () => {
        setAssignInspector({
            bool: !assignInspector?.bool,
            data: null
        })
    }

    // const dialogHeader = () => {
    //     return (
    //         <Stack direction="row" spacing={1}>
    //             {/* <Box onClick={handleClose} className={classes.iconbtn} display="flex" alignItems="center">
    //                 <ArrowBackIosIcon className={classes.icon} htmlColor="black" />
    //             </Box> */}
    //             <Typography className={classes.dialoghdrtext}>{t("assignInspector")}</Typography>
    //         </Stack>
    //     )
    // }
    const openFilter = () => {
        setDrawer(true)
    }
    const handleCloseFilter = () => {
        setDrawer(false)
    }
    //To filter data based on status
    const handleFilter = (data) => {
        getAllInspectionList(0, 10, "", data)
        setFilterData(data)
    }
    const manualResponse = (array) => {
        const details = array?.map((i) => {
            return {
                ...i,
                label: i?.name,
                value: i?.id
            }
        })
        return details
    };
    const render = () => {
        return (
            <Box>
                <Subheader goBack={goBack}
                    title={state?.title}
                />
                <Box className={classes.content}>

                    {/* cards */}
                    {
                        loading ?
                            <LoadingSection top="20vh" message={"Fetching Details"} />
                            :
                            <>
                                <Box className={`${classes.inspection}`}>
                                    <InspectionDetails details={details} />


                                    <Box className={`${classes.inspectionTable}`}>
                                        <InspectionTable
                                            handleIcon={AssignInspector}
                                            handlePagination={handlePagination}
                                            handleChangeLimit={handleChangeLimit}
                                            tableData={tableData}
                                            totalRows={totalRows}
                                            page={page}
                                            limit={limit}
                                            searchText={searchText}
                                            dot={filterData?.status?.length>0||filterData?.resource?.length>0||filterData?.dateRange?.startDate!==null}
                                            openFilter={openFilter}
                                            handleSearch={handleSearch}
                                            height={`calc(100vh - 330px)`}
                                        />
                                    </Box>
                                </Box>
                            </>
                    }
                    {drawer && (
                        <FilterGenerator
                            open={drawer}
                            onClose={() => handleCloseFilter()}
                            components={[
                                {
                                    component: "select",
                                    value: filterData?.resource,
                                    options: [],
                                    isMulti: true,
                                    label: t("Resource"),
                                    placeholder:t("Resource"),
                                    state_name: "resource",
                                    loadOptions: (search, array, handleLoading) =>
                                        loadOptionsApis(
                                            "/resources/getAll",
                                            {
                                                company_id: selectedCompany?.value,
                                                active:[true]

                                            },
                                            search,
                                            array,
                                            handleLoading,
                                            "result",
                                            {},
                                            manualResponse
                                            )
                                    ,
                                    debounceTimeout: 800,
                                    isPaginate: true,
                                },
                                {
                                    component: "date_range",
                                    value: filterData?.dateRange,
                                    state_name: "dateRange",
                                    label: t("Requested On"),
                                    toLabel: t("To"),
                                    startPlaceholder: t("Select Start Date"),
                                    endPlaceholder: t("Select End Date")
                                },
                                {

                                    component: "select",
                                    value: filterData?.status,
                                    options: statusOptionList,
                                    isMulti: true,
                                    state_name: "status",
                                    label: t("Status"),
                                    placeholder: t("Status"),
                                },


                            ]}
                            onApply={(value) => handleFilter(value)}
                        />
                    )}
                    <Drawer
                        anchor={"right"}
                        open={assignInspector?.bool}
                        onClose={handleClose}
                    >
                        <AssignModal
                            agreement_inspection_id={assignInspector?.data}
                            moduleName={t("moveInInspectionRequest")}
                            handleClose={handleClose}
                            onSubmit={handleAssign}
                            header={t("Assign Resource")}
                            buttonName={t("assign")}
                            isDisableBtn={isDisableBtn}
                        />
                    </Drawer>

                </Box>
            </Box>
        )
    }
    return (
        <Box>
            {accessCheckRender(render, permissions)}
        </Box>

    )
}
export default withTranslation("inspection")(InspectionList);
