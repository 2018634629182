import GroupIcon from "@mui/icons-material/Group";
import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useStyles } from "./style";
import { UserList } from "./userList";
import { ReturnDetail } from "./returnDetails";
import { getDay } from "date-fns";


export const Calendar = ({
  dates = {},
  weekdatas = {},
  onChange = () => false,
  value = "",
  onView = () => false,
  selectedType = {},
  fetchMoreData = () => false,
  t
}) => {
  const classes = useStyles();
  //   state
  const [hidden, setHidden] = useState({
    bool: false,
    id: null,
  });

  // onHover
  const onHover = (index) => {
    setHidden({
      bool: true,
      id: index,
    });
  };

  // onLeave
  const onLeave = (index) => {
    setHidden({
      bool: false,
      id: null,
    });
  };


  return (
    <>
      <Grid container justifyContent="center">
        <Grid item md={3} xs={3} className={classes.userListCards}>
          <Stack direction={"row"} marginLeft="4px" spacing={1} alignItems={"center"}>
            <GroupIcon className={classes.listTitle} />
            <TextField
              value={value}
              onChange={(val) => onChange(val.target.value)}
              placeholder={`${t(selectedType?.label)} ${t("Lists")}`}
              className={classes.listTitle}
              size="small"
            />
          </Stack>
        </Grid>
        {dates?.map((e) => {
          return (
            <Grid item md={1.28} xs={1.28} className={classes.userListCard}>
              <Stack alignItems={"center"} >
                <Typography className={classes.dayLabel}>
                  {moment(e).format("ddd")}
                </Typography>
                <Typography className={classes.dateLabel}>
                  {moment(e).format("DD")}
                </Typography>
              </Stack>
            </Grid>
          );
        })}
      </Grid>
      <Box className={classes.calenderRows}>
        <InfiniteScroll
          dataLength={weekdatas.length}
          next={fetchMoreData}
          hasMore={true}
          style={{ overflow: "overlay", }}
          height={`calc(100vh - 320px)`}
        >
          <Grid container justifyContent="center">

            {
              weekdatas?.length > 0 ? weekdatas?.map((val) => {


                return (

                  <>
                    <Grid item md={3} xs={3} className={classes.userListCards}>
                      <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <UserList userData={val} t={t} />
                      </Stack>
                    </Grid>

                    {dates?.map((e) => {

                      return (
                        <>
                          <Grid item md={1.28} xs={1.28} className={classes.cellsRoot}>
                            <Box className={val?.week_off_days?.includes(getDay(e)) ? classes.weekOff : null}>
                              {val?.progress?.map((wd, index) => {

                                return (
                                  <Box>

                                    {moment(e).format("YYYY-MM-DD") === moment(wd?.execution).format("YYYY-MM-DD") &&
                                      <div
                                        id={wd.id}
                                        onMouseOver={() => onHover(wd?.id)}
                                        onMouseLeave={() => onLeave(wd?.id)}
                                      >

                                        <ReturnDetail t={t} onClick={() => onView(val, wd)} open_id={hidden?.id} val={wd} />
                                      </div>

                                    }

                                  </Box>
                                );
                              })}
                            </Box>
                          </Grid>
                        </>
                      );
                    })}
                  </>

                );
              })

                :
                <Grid item xs={12} className={classes.noData}>
                  <Typography className={classes.leave_name}>{"No Data Found"}</Typography>
                </Grid>
            }
          </Grid >
        </InfiniteScroll >
      </Box >
    </>
  );
};
