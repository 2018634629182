import { Box, Grid, IconButton, Stack } from "@mui/material";
import moment from "moment-timezone";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FilterIcon from "../../assets/filterIcon";
import {
  FilterGenerator,
  LoadingSection,
  SearchFilter,
  Subheader,
  TableWithPagination,
  UseDebounce,
} from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import {
  AlertProps,
  LocalStorageKeys,
  NetWorkCallMethods,
  accessCheckRender,
  enumSelect,
  enum_types,
  getCompanyOption,
  getRoutePermissionNew,
  reservationHeading,
  reservationPath,
  reservationType,
  wrapLabelsInT,
} from "../../utils";
import { useStyles } from "./style";

const Reservation = ({ t }) => {
  const navigate = useNavigate();
  // context
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext);
  // useRef
  const permission = React.useRef([]);
  // apolo clint
  //debounce hook
  const debounce = UseDebounce();
  // styles
  const classes = useStyles();
  // ----------------------------------------STATES---------------------------------------------------
  const [company, setCompany] = useState({
    companyList: [],
    SelectedCompany: {},
    searchText: "",
    drawer: false,
  });
  const [selectedTeams, setSelectedTeams] = React.useState("");
  const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId);
  const [filterData, setFilterDate] = useState({
    Status: "",
    date: "",
    StatusType: "",
  });
  const [list, setList] = useState({
    data: [],
    count: 0,
  });
  const [loading, setLoading] = useState(true);
  const [enumValue, setEnumValue] = useState({
    reservation_status_type: [],
    reservation_status_type_new: [],
  });
  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");
  const limitParam = searchParams.get("limit");

  // Default values if not set in URL
  const pageFromParams = pageParam ? parseInt(pageParam, 10) : 1;
  const limitFromParams = limitParam ? parseInt(limitParam, 10) : 10;

  const [page, setPage] = React.useState(pageFromParams);
  const [limit, setLimit] = React.useState(limitFromParams);

  const updatePage = (newPage, newLimit = limit) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", newPage.toString());
    searchParams.set("limit", newLimit.toString());

    // Update URL without reloading
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });

    // Calculate offset
    const newOffset = (newPage - 1) * newLimit;

    // Update state
    setPage(newPage);
    setLimit(newLimit);

    // Fetch the updated data
    GetList(
      company?.SelectedCompany,
      selectedTeams,
      company?.searchText,
      newOffset,
      newLimit,
      filterData?.Status,
      filterData?.date,
      filterData?.StatusType
    );
  };

  // pagination
  const handlePagination = (newPage) => {
    updatePage(newPage, limit);
  };

  const handleChangeLimit = (newLimit) => {
    updatePage(1, newLimit); // Reset page to 1 when limit changes
  };

  // --------------------------------FUNCTIONS--------------------------------------------------------
  //   initial company update
  const updateState = (k, v) => {
    setCompany({ ...company, [k]: v });
  };
  //   company selection
  const companySelect = (e) => {
    updateState("SelectedCompany", e);
  };
  // onApplyFilter
  const onApplyFilter = (value) => {
    setFilterDate(value);
    GetList(
      company?.SelectedCompany,
      selectedTeams,
      company?.searchText,
      0,
      limit,
      value?.Status,
      value?.date,
      value?.StatusType
    );
  };
  // reservation get
  const GetList = (
    company,
    team,
    searchText,
    offSet,
    limit,
    Status,
    date,
    StatusType
  ) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    let clientID = localStorage.getItem(LocalStorageKeys.clinetID);
    const payload = {
      client: clientID,
      company_id: company?.value,
      search: searchText,
      reservation_status: Status ?? null,
      status: StatusType?.length > 0 ? StatusType : null,
      reservation_expiry_date:
        JSON.stringify(date)?.length > 2
          ? [
              `${moment(date).format("YYYY-MM-DD")} 00:00:00`,
              `${moment(date).format("YYYY-MM-DD")} 23:59:59`,
            ]
          : [],
      offset: offSet,
      limit: limit,
    };
    if (team?.value !== "noteams") {
      payload["team"] = team?.value;
    }
    NetworkCall(
      `${config.api_url}/quotation/get_reservation_list`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setLoading(false);
        let result = res?.data?.reservation?.map((x) => {
          return {
            Reservation_ID: x?.reservation_no ?? "",
            Reservation_Type: x?.reservation_status ?? "",
            Quotation_ID: x?.quotation_no ?? "",
            Purpose: x?.unit_usage ?? "",
            "No.Of_Units": x?.quotation_units ?? 0,
            Revenue: `${company?.currency_symbol ?? ""} ${
              x?.total_amount ?? ""
            }`,
            Reservation_Valid_Till: x?.reservation_expiry_date ?? "",
            Status: x?.status ?? "",
            id: x?.id ?? "",
            qutation_id: x?.quotation_id ?? "",
          };
        });
        setList({
          data: result ?? [],
          count: res?.data?.count ?? 0,
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        setLoading(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Internal error. Please try again later.",
        });
      });
  };
  //   get permission function
  // const getPermission = async () => {
  //   const tempPermission = await getRoutePermission();
  //   permission.current = tempPermission;
  //   if (tempPermission?.read) {
  //     let company = getCompanyOption(backdrop, auth, alert);
  //     if (company) {
  //       getEnum();
  //       setCompany({
  //         ...company,
  //         companyList: company?.list,
  //         SelectedCompany: company?.selected,
  //       });
  //     }
  //   }
  // };
  // drawer open
  const openDrawer = () => {
    updateState("drawer", true);
  };
  // get enum
  const getEnum = async () => {
    const result = await enumSelect([
      enum_types?.reservation_status_type,
      enum_types?.reservation_status_type_new,
    ]);
    setEnumValue({
      reservation_status_type: result?.reservation_status_type,
      reservation_status_type_new: result?.reservation_status_type_new,
    });
  };
  const handleIcon = (type, data) => {
    if (data?.Status !== "Cancelled")
      navigate(`${Routes.reservationDetails}?id=${data?.qutation_id}`);
  };
  
  // searchFunction
  const searchFunction = (value) => {
    updateState("searchText", value);
    debounce(
      () =>
        GetList(
          company?.selected,
          selectedTeams,
          value,
          0,
          limit,
          filterData?.Status,
          filterData?.date,
          filterData?.StatusType
        ),
      800
    );
  };
  // use effect to get permission
  React.useEffect(() => {
    const tempPermission = getRoutePermissionNew(auth);
    // permission.current = tempPermission;
    if (tempPermission) {
      permission.current = tempPermission;
      let company = getCompanyOption(backdrop, auth, alert);
      if (company) {
        getEnum();
        setCompany({
          ...company,
          companyList: company?.list,
          SelectedCompany: company?.selected,
        });
      }
    }
    // eslint-disable-next-line
  }, [auth]);
  // Function to change property
  const handleTeamsChange = (team) => {
    const currentOffset = (page - 1) * limit;
    setSelectedTeams(team);
    GetList(
      company?.SelectedCompany,
      team,
      "",
      currentOffset,
      limit,
      filterData?.Status,
      [],
      ""
    );
  };
  const render = () => {
    return (
      <div>
        {/* sub Navbar */}
        <Subheader
          title={`${t("Reservations")} (${list?.count ?? 0})`}
          select
          hideBackButton={true}
          options={company?.companyList}
          value={company?.SelectedCompany}
          placeholder={t("Search")}
          onchange={(e) => {
            companySelect(e);
          }}
          selectTeamsOptions
          handleTeamsChange={handleTeamsChange}
          companyId={company?.SelectedCompany?.value}
          moduleId={moduleId}
          selectedTeams={selectedTeams}
        />
        {/* body */}
        {loading ? (
          <LoadingSection />
        ) : (
          <div className={classes.root}>
            <Grid container className={classes.content} spacing={2}>
              <Grid item xs={4}>
                <Box>
                  {/* search box */}
                  <SearchFilter
                    value={company?.searchText}
                    placeholder={t("Search")}
                    handleChange={(value) => searchFunction(value)}
                    marginBottom="0px !important"
                    customfieldSx={{
                      "& .MuiOutlinedInput-root": { height: "40px" },
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={8}>
                <Box
                  display={"flex"}
                  alignItems="center"
                  justifyContent={"end"}
                >
                  <Stack
                    direction="row"
                    spacing={0.6}
                    display={"flex"}
                    alignItems="center"
                  >
                    <Box onClick={openDrawer}>
                      {filterData?.Status?.length > 0 ||
                      filterData?.StatusType?.length > 0 ||
                      (filterData?.date !== undefined &&
                        filterData?.date !== "") ? (
                        <FilterIcon />
                      ) : (
                        <IconButton size="small" className={classes.img}>
                          <img src="/images/filter.svg" alt="filter" />
                        </IconButton>
                      )}
                    </Box>
                  </Stack>
                  <Box width={"15px"} />
                </Box>
              </Grid>

              {/* <Grid item xs={12} className={classes.noPadding}>
                <Divider />
              </Grid> */}

              {/* Main tableData */}
              <Grid item xs={12} className={classes.tableMain}>
                <TableWithPagination
                  heading={reservationHeading(t)}
                  rows={list?.data ?? []}
                  path={reservationPath}
                  showpagination={true}
                  showpdfbtn={false}
                  showexcelbtn={false}
                  totalRowsCount={list?.count ?? 0}
                  page={page}
                  limit={limit}
                  tableType="no-side"
                  dataType={reservationType}
                  height={"calc(100vh - 315px)"}
                  handleIcon={handleIcon}
                  handlePagination={handlePagination}
                  handleChangeLimit={handleChangeLimit}
                />
              </Grid>
              {/* end Main tableData */}
            </Grid>
          </div>
        )}
        {/* filter */}
        {company?.drawer && (
          <FilterGenerator
            open={company?.drawer}
            onClose={() => updateState("drawer", false)}
            components={[
              {
                component: "toggleButton",
                value: filterData?.Status,
                options: wrapLabelsInT(enumValue?.reservation_status_type, t),
                state_name: "Status",
                label: t("Reservation Status"),
              },
              {
                component: "toggleButton",
                value: filterData?.StatusType,
                options: wrapLabelsInT(enumValue?.reservation_status_type_new, t),
                state_name: "StatusType",
                label: t("Status"),
              },
              {
                component: "date",
                value: filterData?.date,
                state_name: "date",
                label: t("Expiry date"),
                placeholder: t("Select Expiry Date"),
              },
            ]}
            onApply={(value) => onApplyFilter(value)}
          />
        )}
      </div>
    );
  };
  return <div> {accessCheckRender(render, permission?.current)}</div>;
};
export default withTranslation("reservation")(Reservation);
