import { Badge, Box, Button, Grid, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import FilterIMG from "../../assets/filter";
import {
  FilterGenerator,
  SearchFilter,
  SelectBox,
  TableWithPagination,
} from "../../components";
import {
  getThisWeek,
  getToday,
  queuedInvoiceHeading,
  queuedInvoicePath,
  queuedInvoiceType,
} from "../../utils";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { DatePicker } from "./datePicker";
import { managrmentStyles } from "./style";
import ChequeImageViewer from "../manageInvoice/ChequeImageViewer";

export const TableComponent = (props) => {
  const { t, date, dateChange, enumValue, setSelectType, onChange = () => false, filterData, reloadFunction = () => false, clearFilter = () => props, handleIcon = () => false, handleAccountTypeToggle } = props;
  const dateOption = [
    {
      label: t("Past"),
      key: "past",
      value: {
        from: getToday(),
        to: getToday(),
      },
    },
    {
      label: t("Future"),
      key: "future",
      value: getThisWeek(),
    },
  ];

  const classes = managrmentStyles();
  const [type, setType] = React.useState({
    value: "All",
    label: "Select Invoice Type",
  }, { value: "Void Invoices", label: "Void Invoices" })
  const [drawer, setDrawer] = React.useState(false)
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogImage, setDialogImage] = useState(null);
  const [dialogChequeNo, setDialogChequeNo] = useState(null);
  const [dialogIssuedBy, setDialogIssuedBy] = useState(null);

  const onSumbit = (data) => {
    let result = {
      from: data?.startDate,
      to: data?.endDate ?? data?.startDate,
    };
    dateChange(result);
  };

  const allhandleCheck = (e) => {
    props?.allSelectBox(e);
  };

  const typeChange = (e) => {
    setType(e);
    setSelectType(e)
    props?.typeChage(e)
  }
  const manualResponse = (array) => array?.map(_ => {
    return {
        ..._,
        label: _?.name,
        value: _?.account_no,
    }
});
const manualResponseAgreement = (array) => array?.agreement?.map(_ => {
  return {
      ..._,
      label: _?.label,
      value: _?.value,
  }
});

const handleChipClick = (image, chequeNo, issuedBy) => {
  setDialogImage(image);
  setDialogChequeNo(chequeNo);
  setDialogIssuedBy(issuedBy);
  setDialogOpen(true);
};

const handleCloseDialog = () => {
  setDialogOpen(false); 
};

// payment method chip props
const paymentMethodProps = {
  text: "View",
  color: "1px solid #5078E1",
  textColor: "#5078E1",
  bgColor: "rgba(217, 223, 236, 0.5)",
  for: ["Cheque", "Post Dated Cheques"],
  handleClick: row => handleChipClick(row.Cheque_Image_URL, row.Cheque_No, row.Issued_By)
};

// posting type chip props
const postingTypeProps = {
  textColor: "white",
  textWeight: "bold",
  bgColor: (() => {
    const colorCode = {
      "ERP": "#e69a2d",
      "PG2": "#33ace4",
      "Proxy": "#9987c2"
    };
    const item = props?.list?.data.find((x) => colorCode[x?.Posting_Type]);
    return item ? colorCode[item.Posting_Type] : "none";
  })()
};

// posting status props
const postingStatusProps = props?.list?.data.map((item) => {
  const colorCode = {
    "Submitted": "#008000",
    "Rejected": "#800000",
  };

  return {
    ...item,
    color: colorCode[item?.Posting_Status] || "none",
  };
});
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={4} sx={{ paddinInlineStart: "16px" }}>
          <Box>
            <SearchFilter
              value={props?.company?.searchText}
              handleChange={(value) => props?.searchFunction(value)}
              placeholder={t("search")}
              customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box />
        </Grid>
        <Grid item xs={4}>
          <Box
            display={"flex"}
            pr={2}
            alignItems="center"
          >
            {/* select type */}
            <Grid container spacing={1}>
              <Grid item xs={6} display={"flex"} justifyContent={"end"}>
                <Box display="flex" alignItems="center" sx={{ float: "right" }}>
                  {/* account type toggle */}
                  <Box marginInlineStart={1}>
                    <Box className={classes.toggle}>
                      <Button
                        className={
                          filterData?.classification?.includes("AR")
                            ? classes.select
                            : classes.unSelect
                        }
                        onClick={() => handleAccountTypeToggle("AR")}
                      >
                        AR
                      </Button>
                      <Button
                        disabled
                        className={
                          filterData?.classification?.includes("AP")
                            ? classes.select
                            : classes.unSelect
                        }
                        onClick={() => handleAccountTypeToggle("AP")}
                      >
                        AP
                      </Button>
                    </Box>
                  </Box>
                  {/* date toggle */}
                  <Box marginInlineStart={1}>
                    <Box className={classes.toggle}>
                      {dateOption?.map((x) => {
                        return (
                          <Button
                            className={
                              x?.value?.from === date?.from && x?.value?.to === date?.to
                                ? classes.select
                                : classes.unSelect
                            }
                            onClick={() => dateChange(x?.value, x?.key)}
                          >
                            <Typography noWrap>{x?.label}</Typography>
                          </Button>
                        );
                      })}
                    </Box>
                  </Box>
                  {/* date picker */}
                  <DatePicker onSumbit={onSumbit} />
                  <Box marginInlineStart={1}>
                    {filterData?.classification?.length > 0 ||
                    filterData?.contact?.length > 0 ? (
                      <IconButton
                        onClick={() => setDrawer(true)}
                        className={classes.img}
                      >
                        <Badge variant="dot" color="primary">
                          <FilterIMG color="#091b29" />
                        </Badge>
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => setDrawer(true)}
                        className={classes.img}
                      >
                        <FilterIMG color="#091b29" />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <SelectBox
                    isRequired
                    placeholder="Select Status"
                    options={[
                      {
                        value: "All",
                        label: "All",
                      },
                      ...enumValue?.type,
                    ]}
                    value={type}
                    onChange={(value) => {
                      typeChange(value);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            {/* </Stack> */}
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.tableMain}>
          <TableWithPagination
            heading={queuedInvoiceHeading(t)}
            rows={props?.list?.data ?? []}
            path={queuedInvoicePath}
            showpdfbtn={false}
            showexcelbtn={false}
            showSearch={false}
            tableType="no-side"
            handleCheck={props?.onCheckBox}
            page={props?.page ?? 1}
            limit={props?.limit ?? 10}
            handlePagination={props?.handlePagination}
            handleChangeLimit={props?.handleChangeLimit}
            totalRowsCount={props?.list?.count}
            dataType={queuedInvoiceType ?? []}
            height={
              props?.list?.data?.length > 0
                ? "calc(100vh - 395px)"
                : "calc(100vh - 356px)"
            }
            showpagination={true}
            onCheckBox={props?.onCheckBox}
            checkboxKey="is_active"
            allSelect={props?.selected ?? ""}
            allhandleCheck={allhandleCheck}
            handleIcon={handleIcon}
            paymentMethodProps={paymentMethodProps}
            postingTypeProps={postingTypeProps}
            postingStatusProps={postingStatusProps}
          />
        </Grid>
      </Grid>

      {drawer && (
        <FilterGenerator
          open={drawer}
          onClose={() => setDrawer(false)}
          components={[
            {
              component: "select_box",
              value: filterData?.contact,
              options: [],
              isMulti: false,
              label: t("Account"),
              placeholder: t("Account"),
              state_name: "unit",
              onChange: (value) => onChange("contact", value),
              loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                  "queries/opportunity/contact_account",
                  {
                    company_id: props?.company?.SelectedCompany?.value,
                  },
                  search,
                  array,
                  handleLoading,
                  "data",
                  {},
                  manualResponse
                ),
              debounceTimeout: 800,
              isPaginate: true,
              is_active: true,
            },
            {
              component: "select_box",
              value: filterData?.agreement,
              options: [],
              isMulti: true,
              label: t("Agreement"),
              placeholder: t("Agreement"),
              state_name: "unit",
              key: JSON.stringify(filterData?.contact),
              onChange: (value) => onChange("agreement", value),
              loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                  "queries/agreement/by_account",
                  {
                    account_id: filterData?.contact?.value,
                  },
                  search,
                  array,
                  handleLoading,
                  "data",
                  {},
                  manualResponseAgreement
                ),
              debounceTimeout: 800,
              isPaginate: true,
              is_active: filterData?.contact?.value ? true : false,
              is_divider: filterData?.contact?.value ? false : true,
            },
            {
              component: "toggleButton",
              value: filterData?.classification,
              options: [
                {
                  value: "AR",
                  label: "AR",
                },
                {
                  value: "AP",
                  label: "AP",
                },
              ],
              isMulti: true,
              state_name: "classification",
              label: t("Classification"),
            },
          ]}
          clearFilter={(value) => {
            clearFilter();
          }}
          is_customFilter
          onApply={(value) => {
            setDrawer(false);
            onChange("classification", value?.classification);
            reloadFunction(value?.classification);
          }}
        />
      )}
      <ChequeImageViewer
        open={dialogOpen}
        onClose={handleCloseDialog}
        image={dialogImage}
        chequeNo={dialogChequeNo}
        issuedBy={dialogIssuedBy}
        onCloseButtonClick={handleCloseDialog}
      />
    </div>
  );
};
