import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { AlertDialog } from "../../../components";
import { DocumentViewer } from "../../../components/fileViewer";
import { ProofView } from "../../../components/proofComponent/section/proofView";
import { useStyles } from "../styles";
import { convertTimeUtcToZoneCalander } from "../../../utils";
export const KYCDetails = ({ data = {}, t }) => {
  const classes = useStyles();
  const [openPdf, setOpenPdf] = React.useState(false);
  const [currentImage, setCurrentImage] = React.useState("");
  const [currentImageType, setCurrentImageType] = React.useState("");
  const setOpenPdfBtn = (data) => {
    setOpenPdf(true);
    setCurrentImage(data?.[0]);
    setCurrentImageType(data?.[1]);
  };
  return (
    <Box className={classes.box} marginTop="16px">
      <Typography className={classes.title} marginBottom={"14px"}>
        {t("KYC Details")}
      </Typography>
      {data?.know_your_client_account?.length > 0 ? (
        data?.know_your_client_account?.map((val) => {
          return (
            <>
              <Grid container rowSpacing={1} columnSpacing={3}>
                <Grid item xs={12}>
                  <Typography className={classes.title2}>
                    {val?.identification_master?.label}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Box marginTop="16px">
                    <Typography className={classes.heading}>
                      {t("Name As In Proof")}
                    </Typography>
                    <Typography className={classes.sub}>{val?.name}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box marginTop="16px">
                    <Typography className={classes.heading}>
                      {t("ID Proof Number")}
                    </Typography>
                    <Typography className={classes.sub}>
                      {val?.number}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={2}>
                  <Box marginTop="16px">
                    <Typography className={classes.heading}>
                      {t("Valid From")}
                    </Typography>
                    <Typography className={classes.sub}>
                      &#x202a;
                      {val?.valid_from && val?.valid_from !== ""
                        ? convertTimeUtcToZoneCalander(val?.valid_from)
                        : "-"}
                      &#x202C;
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box marginTop="16px">
                    <Typography className={classes.heading}>
                      {t("Valid To")}
                    </Typography>
                    <Typography className={classes.sub}>
                      &#x202a;
                      {val?.valid_to && val?.valid_to !== ""
                        ? convertTimeUtcToZoneCalander(val?.valid_to)
                        : "-"}
                      &#x202c;
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box marginTop="16px">
                    <Typography className={classes.heading}>
                      {t("Issuing Country")}
                    </Typography>
                    <Typography className={classes.sub}>
                      {val?.country_master?.label}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box marginTop="16px">
                    <Typography className={classes.heading}>
                      {t("Mandatory Verify")}
                    </Typography>
                    <Typography className={classes.sub}>
                      {val?.is_mandatory ? "Yes" : "No"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box marginTop="16px">
                    <Typography className={classes.heading}>
                      {t("Document")}
                    </Typography>
                    <Grid container spacing={2}>
                      {val?.url?.length > 0 ? (
                        val?.url?.map((file, index) => {
                          return (
                            <Grid item xs={2} key={index}>
                              <ProofView
                                name={file?.name}
                                src={file?.src}
                                fileType={file?.type}
                                setOpenPdfBtn={setOpenPdfBtn}
                                disable={true}
                                t={t}
                              />
                            </Grid>
                          );
                        })
                      ) : (
                        <Grid item xs={2}>
                          -
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Grid>
                <AlertDialog
                  isNormal
                  isnotTitle
                  component={
                    currentImageType === "img" ? (
                      <div style={{ display: "flex" }}>
                        <img
                          src={currentImage}
                          alt=""
                          style={{ objectFit: "cover", width: "444px" }}
                        />
                      </div>
                    ) : (
                      <div>
                        <DocumentViewer url={currentImage} />
                      </div>
                    )
                  }
                  open={openPdf}
                  onClose={() => setOpenPdf(false)}
                />
              </Grid>
            </>
          );
        })
      ) : (
        <Typography className={classes.nodataText}>
          {t("No data Found")}
        </Typography>
      )}
    </Box>
  );
};
