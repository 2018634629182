import React from "react";
import { withNavBars } from "../../HOCs";
import SaleAgreementsList from "./saleAgreement";

class SaleAgreementsListParent extends React.Component {
    render() {
        return <SaleAgreementsList />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(SaleAgreementsListParent, props);
