import { Avatar, Box, Button, Divider, Grid, Menu, MenuItem, Stack, Typography } from "@mui/material"
import React from "react"
import { withTranslation } from "react-i18next"
import { CloseIconWithBG } from "../../assets"
import { useStyles } from "./style"
import InfoIcon from "../../assets/infoIcon"
import { NetWorkCallMethods, stringAvatar, useWindowDimensions } from "../../utils"
import { NetworkCall } from "../../networkcall"
import { config } from "../../config"
import { LoadingSection } from "../../components"


const ViewForm = ({ t = () => false, onClose = () => false, data = {
    jobs: ["Inspector", "Designer", "Developer", "Inspector", "Designer", "Developer"]
}, handleEditInView = () => false, permission = {} }) => {
    const classes = useStyles()
    const size = useWindowDimensions()
    const [loading, setLoading] = React.useState(false)
    const [viewData, setViewData] = React.useState({})
    const [type, setType] = React.useState("")
    const [anchorEl, setAnchorEl] = React.useState(false);
    const open = Boolean(anchorEl);

    React.useEffect(() => {
        getViewDetails()
        //eslint-disable-next-line
    }, [])

    const getViewDetails = () => {
        const requestPayload = {
            id: data?.id
        };
        NetworkCall(
            `${config.api_url}/resource_group_master/get`,
            NetWorkCallMethods.post,
            requestPayload,
            null,
            true,
            false
        )
            .then((response) => {
                setLoading(false)
                setViewData(response?.data?.data)
            }).catch((err) => {
                setLoading(false)
            })
    }
    const handleInfo = (event, val) => {
        setType(val)
        handleClick(event)
    }
    //handle clik
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null)
    };
    return (
        <Box sx={{ width: "500px" }}>

            <Box p={2}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Box sx={{ cursor: "pointer" }} onClick={() => onClose()}><CloseIconWithBG backgroundColor={"#f2f4f7"} /></Box>
                    <Typography className={classes.createTitle}>{t("View")}</Typography>
                </Stack>
            </Box>
            <Divider />
            {loading ?
                <LoadingSection /> :
                <Box>
                    <Box p={2}>
                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                            <Typography className={classes.resourceGrpName}>{viewData?.name}</Typography>
                            <Box className={classes.statusBox} sx={{ backgroundColor: viewData?.is_active ? "#5AC782" : "#FF4B4B" }}>
                                <Typography className={classes.statusText}>{viewData?.is_active ? t("Active") : t("In-active")}</Typography>
                            </Box>
                        </Box>
                        <Box mt={2} className={classes.detailsBox}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Box display="flex" justifyContent={"space-between"} alignItems={"center"} p={1} sx={{ borderRight: "1px solid #E4E8EE" }}>
                                        <Stack direction={"row"} alignItems="center" spacing={0.5}>
                                            <Typography className={classes.job_count}>{viewData?.job_count ?? 0}</Typography>
                                            <Typography className={classes.job_hdr}>{t("Jobs / Roles")}</Typography>
                                        </Stack>
                                        <Box sx={{ cursor: "pointer" }} onMouseOver={(e) => handleInfo(e, "job")}>
                                            <InfoIcon />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Box display="flex" justifyContent={"space-between"} alignItems={"center"} p={1}>
                                        <Stack direction={"row"} alignItems="center" spacing={0.5}>
                                            <Typography className={classes.job_count}>{viewData?.profession_count ?? 0}</Typography>
                                            <Typography className={classes.job_hdr}>{t("Profession / Skills")}</Typography>
                                        </Stack>
                                        <Box sx={{ cursor: "pointer" }} onMouseOver={(e) => handleInfo(e, "profession")}>
                                            <InfoIcon />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mt={2}>
                            <Typography className={classes.memberhdr}>{`${t("Members")} (${viewData?.resource_count})`}</Typography>
                            <Box sx={{ height: size?.height - 270 }} overflow={"scroll"}>
                                {viewData?.resource?.map((x) => {
                                    return (
                                        <Box mt={1} className={classes.memberBox}>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Avatar src={x?.resource?.assets?.[0]?.url} variant="square" className={classes.resourceImg} {...stringAvatar(x?.resource_name)} />
                                                <Box>
                                                    <Typography className={classes.resourceName}>{x?.resource_name}</Typography>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Typography className={classes.jobName}>{x?.profession_name}</Typography>
                                                        {x?.profession_name !== null &&
                                                            <Box className={classes.dot}></Box>
                                                        }
                                                        <Typography className={classes.jobName}>{x?.job_name}</Typography>
                                                    </Stack>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    )
                                })}

                            </Box>
                            {permission?.update &&
                                <Box mt={1}>
                                    <Button variant="contained" fullWidth onClick={() => handleEditInView(viewData)}>{t("Edit")}</Button>
                                </Box>
                            }
                        </Box>
                    </Box>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        className={classes.menuList}
                        MenuListProps={{ onMouseLeave: handleClose }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        PaperProps={{ sx: { width: "350px" } }}
                    >
                        <MenuItem
                            className={classes.menuItem}
                            sx={{ maxHeight: "64px", overflow: "scroll" }}
                        >
                            <Stack direction="row" gap={1}
                                flexWrap={"wrap"}>
                                {type === "profession" ? viewData?.profession?.map((x => {
                                    return (
                                        <Box className={classes.rolebox}>
                                            <Typography className={classes.roleText}>{x?.name}</Typography>
                                        </Box>
                                    )
                                })) : viewData?.job?.map((x => {
                                    return (
                                        <Box className={classes.rolebox}>
                                            <Typography className={classes.roleText}>{x?.name}</Typography>
                                        </Box>
                                    )
                                }))}
                            </Stack>
                        </MenuItem>
                    </Menu>
                </Box>
            }
        </Box>
    )
}
export default withTranslation("resourceMaster")(ViewForm)