import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        border: "2px solid white",
        padding: "16px",
        backgroundColor: "white",
        margin: "14px",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
    },
    hdrtitle: {
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.primary
    },
    rootblock: {
        backgroundColor: "#FFFFFF",
        borderRadius: theme.palette.borderRadius,
        padding: "10px",
        position: "relative"
        // height:"560px"
        // margin: "15px"
    },
    rootblock1: {
        backgroundColor: "#FFFFFF",
        borderRadius: theme.palette.borderRadius,
        padding: "16px",
        position: "relative"
        // height:"560px"
        // margin: "15px"
    },

    cmpbtn: {
        position: "absolute",
        bottom: "0%",
        left: "0%",
        width: "100%",
        padding: "10px"
    },
    completeInsp: {
        padding: "20px"
    },
    nobtn: {
        '&:hover': {
            background: "#fff"
        }
    },
    msg: {
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().semiBold,
    },
    successbtn: {
        backgroundColor: "#5078E1",
        '&:hover': {
            backgroundColor: "#5078E1",
        }
    },
    cancelbtn: {
        backgroundColor: "white",
        '&:hover': {
            backgroundColor: "white",
        }
    },
    accoCardButton: {
        fontSize: "0.875rem",
        fontWeight: "normal",
        '&:hover': {
            color: "#fff"
        },
        // marginInlineStart: "16px"
    },
    cancelButton: {
        fontSize: "0.875rem",
        backgroundColor: theme.typography.color.white,
        color: theme.palette.primary.main,
        // marginInlineStart: "10px",
        border: `1px solid ${theme.palette.border.secondary}`,
        '&:hover': {
            backgroundColor: theme.typography.color.white,

        }
    },

    viewTrack: {
        color: "#5078E1",
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold,
        cursor: "pointer"
    },
    checkIconcircle: {
        backgroundColor: "#5078E1",
        borderRadius: "50%",
        padding: "3px",
        width: "25px",
        height: "25px",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center"
    },
    addIcon: {
        height: "40px"
    },
    filterIconbtn: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: "4px",
        padding: "12px"
    },
    producttitle: {
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
    },
    productValue: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.secondary
    },
    avatar: {
        borderRadius: "4px",
        backgroundColor: "#F2F4F7",
        padding: "4px",
        width:"40px",
        height:"40px"
    },
    assetSection: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "#FFFFFF",
        padding: "16px"
    },
    btnfix: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        padding: "12px 8px"
    },
    reqblock: {
        backgroundColor: "#5078E1",
        backgroundImage: `url(${"../images/requestView.svg"})`,
        height: "250px"
      },
      closeicon: {
        color: "white",
        cursor: "pointer"
      },
      q1hdr: {
        color: "white",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize:"0.875rem",
        marginLeft: "15px"
      },
      q1subhdr: {
        color: "white",
        fontFamily: FontFamilySwitch().regular,
        fontSize:"0.75rem",
        marginLeft: "15px"
      },
      yesbtn: {
        backgroundColor: "white",
        color: "#5078E1",
        "&:hover": {
          backgroundColor: "white",
          color: "#5078E1",
        }
      },
      notxtbtn: {
        color: "white",
        border: "1px solid #E4E8EE",
        "&:hover": {
          color: "white",
          border: "1px solid white",
    
        },
        marginInlineStart:"8px"
      },
}))