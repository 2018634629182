import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material"
import React, { useContext } from "react"
import { withTranslation } from "react-i18next"
import ChooseResourceIcon from "../../assets/chooseResource"
import OtherResourcesIcon from "../../assets/otherResourcesIcon"
import { AssignModalContext } from '../../contexts/assignModalContext'
import { CustomSelect } from "../filterGenerator/components"
import { PopupCard } from "./card"
import { ResourceDropDown } from "./resourceDropdown"
import { addOtherResourcesStyles } from "./styles"

const AddOtherResources = (props) => {
    const { t } = props
    const classes = addOtherResourcesStyles()
    const { selectedOtherResources, setSelectedOtherResources } = useContext(AssignModalContext)
    //const [isSelect, setIsSelect] = useState(false)

    const selectedResourceList = (data) => {
        //setIsSelect(true)
        //setSelectedOtherResources([...selectedOtherResources, data])
        setSelectedOtherResources(data)
        props?.otherResourcesList(data)
    }
    return (
        <Box>
            {props?.assignData?.otherResources === true ?
                <Box m={2}>
                    <Box display={"flex"} onClick={() => props?.handleChange("otherResources", false)} className={classes.otherres}>
                        <ChooseResourceIcon className={classes.icon} />
                        <Box ml={2}>
                            <Typography className={classes.otherTitle}>{t("chooseOptionalResource")}</Typography>
                            <Typography className={classes.otherSubText}>{t("otherResources")}</Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Box className={classes.searchBlock}>
                            <Stack
                                direction="row"
                                divider={<Divider orientation="vertical" flexItem />}
                                spacing={1}
                            >
                                <Grid item xs={12} md={props?.assignData?.resource_type?.label === "Users" ? 4 : 12}>

                                    <Typography className={classes.selecttitle}>{t("resourceType")}</Typography>
                                    <CustomSelect
                                        options={props?.assignData?.resourceTypeOptions}
                                        borderRadius={false}
                                        noBorder={true}
                                        label={""}
                                        value={props?.assignData?.resource_type}
                                        onChange={(value) => {
                                            props?.handleChange("resource_type", value, false)
                                        }} />
                                </Grid>
                                {props?.assignData?.resource_type?.label === "Users" &&
                                    <>

                                        <Grid item xs={4} borderRight="1px solid #E4E8EE">
                                            <Typography className={classes.selecttitle}>{t("department")}</Typography>
                                            <CustomSelect options={props?.assignData?.departmentOptions}
                                                borderRadius={false}
                                                noBorder={true}
                                                isReadOnly={props?.isReadOnly}
                                                placeholder={"All"}
                                                label={""}
                                                value={props?.assignData?.department}
                                                onChange={(value) => {
                                                    props?.handleChange("department", value, false)
                                                }} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography className={classes.selecttitle}>{t("role")}</Typography>
                                            <CustomSelect
                                                options={props?.assignData?.roleOptions}
                                                borderRadius={false}
                                                noBorder={true}
                                                isReadOnly={props?.isReadOnly}
                                                placeholder={"All"}
                                                label={""}
                                                value={props?.assignData?.role}
                                                onChange={(value) => {
                                                    props?.handleChange("role", value, true)
                                                }} />
                                        </Grid>

                                    </>
                                }
                            </Stack>
                            <Divider className={classes.dividerstyle1} />
                            <Box padding="8px 0px" >
                                <ResourceDropDown
                                    placeholder={"Search"}
                                    onChange={(value) => selectedResourceList(value, "otherResourceSearch")}
                                    value={selectedOtherResources}
                                    color={true}
                                    borderRadius={false}
                                    noBorder={true}
                                    isMulti={true}
                                    resource={true}
                                    options={props?.otherResourcesList}

                                />
                            </Box>
                        </Box>
                    </Box>
                    {
                        selectedOtherResources?.length > 0 &&


                        selectedOtherResources?.map((item) => (
                            <PopupCard
                                Resources={true}
                                list={item}
                                hours={props?.assignData?.hours}
                                deleteSvg={true}
                                removeData={props?.removeData}
                                selected={true}
                            />
                        ))

                    }

                    {/* <Box className={classes.popupCards}>
                        {isSelect === true && selectedOtherResources.length > 0 && selectedOtherResources?.map((item) => {
                            return (
                                <PopupCard
                                    Resources={true}
                                    list={item}
                                    hours={props?.assignData?.hours}
                                    deleteSvg={true}
                                    removeData={props?.removeData}
                                    selected={true}
                                />
                            )
                        })}
                    </Box> */}
                </Box>
                :
                <Box className={classes.otherresources}>
                    <Box  justifyContent="center" display={"flex"} alignItems="center">
                        <center>
                            <OtherResourcesIcon />
                            <Typography className={classes.otherTitle}>{t("chooseOptionalResource")}</Typography>
                            <Typography className={classes.otherSubText}>{t("addOptionalResource")}</Typography>
                            <Button variant="outlined" onClick={() => props?.handleChange("otherResources", true, false)} className={classes.addresourcebtn}>{t("addResource")}</Button>
                        </center>
                    </Box>
                </Box>
            }

        </Box>
    )
}
export default withTranslation("assignModal")(AddOtherResources)