import { Box, Table, TableBody, TableCell, TableRow, Typography, Stack } from '@mui/material'
import React from 'react'
import { FontFamilySwitch } from '../../utils'
import { DatePickerNew } from '../datepickernew'
import { CustomSelect } from '../filterGenerator/components'
import { EditIcon } from '../svg'
import { ListTableStyle } from './style'

export const ListTable = (
    {
        components = []
    }) => {

    const classes = ListTableStyle()

    return (
        <Box>
            <Table>
                <TableBody>
                    {
                        components?.length && components?.map((e) => {
                            return (
                                !e?.dontShow &&
                                <TableRow>
                                    <TableCell className={classes.tableCell}>
                                        <Typography className={classes.title}> {e?.tableLabel} </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {
                                            list_Component(e)
                                        }
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </Box>
    )
}


const list_Component = (val) => {

    const classes = ListTableStyle()

    switch (val.component) {
        case "label":
            return (
                <Stack direction="row" justifyContent={"space-between"}>
                    <Typography className={val?.content === "Autogenerate" ? classes.autogenerate : classes.content}> {val?.content} </Typography>
                    {val?.editIcon && <div className={classes.edit} onClick={val?.editDetail}> <EditIcon /> </div>}
                </Stack>
            )
        case "dropdown":
            return (
                <CustomSelect
                    loadOptions={val?.loadOptions}
                    isPaginate={val?.isPaginate}
                    borderRadius={val?.borderRadius}
                    noBorder={val?.noBorder}
                    isReadOnly={val?.isReadOnly}
                    key={val?.invoiceData?.selectedAccountDetails?.account_no}
                    placeholder={val?.placeholder}
                    label={val?.label}
                    value={val?.value}
                    onChange={val?.onChange}
                    fontFamily={FontFamilySwitch().bold}
                    padding={0}
                />
            )
        case "datepicker":
            return (
                <Box sx={{ height: 20, marginLeft: "4px" }}>
                    <DatePickerNew
                        label=""
                        height='16px'
                        placeholder="DD MM YY"
                        value={val?.value && new Date(val?.value)}
                        handleChange={val?.selectedDate} //(value) => setSelectedDate(value)
                        margintop="-12px"
                        noBorder
                        customValue
                        isReadonly={val?.isReadOnly}
                        changePlaceHolder
                    />
                </Box>
            )
        case "selectBox":
            return (
                <CustomSelect
                    options={val?.options ?? []}
                    borderRadius={val?.borderRadius}
                    noBorder={val?.noBorder}
                    isReadOnly={val?.isReadOnly}
                    placeholder={val?.placeholder}
                    label={val?.label}
                    value={val?.value}
                    onChange={val?.onChange}
                    fontFamily={FontFamilySwitch().bold}
                    padding={0}
                />
            )
        default:
            return false
    }
}