import makeStyles from "@mui/styles/makeStyles";
import { remCalc, FontFamilySwitch } from "../../../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    height: ({ root_height }) => root_height,
    display: "flex",
    padding: ({ root_padding }) => root_padding,
    borderRadius: theme.palette.borderRadius,
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.background.primary_2,
  },
  root_label: {
    fontSize: remCalc(12),
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.primary_3,
    textTransform: "capitalize",
  },
  arrow_icon: {
    padding: "0px",
    color: "white"
  },
  menu: {
    "& .MuiMenu-paper": {
      borderRadius: theme.palette.borderRadius,
      padding: "0px !important",
      boxShadow: "0px 8px 24px #0717411F",
    },
    "& .MuiMenu-list": {
      padding: "0 !important",
    },
  },
  menu_item: {
    height: ({ menu_item_height }) => menu_item_height,
    width: ({ menu_item_width }) => menu_item_width,
  },
  menu_item_label: {
    fontSize: remCalc(12),
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
    textTransform: "capitalize",
  },
}))