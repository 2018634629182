import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, IconButton } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import React from 'react';
import { config } from '../../config';
import { AlertContext } from '../../contexts';
import { AlertProps, allowed_file_size, singleFileUpload } from '../../utils';
import { useStyles } from "./styles";
import { withTranslation } from 'react-i18next';


const CompanyLogoUpload = (props) => {

    const {
        label = "",
        accept = "image/*",
        fileUploadUrl = true,
        handleChange = null,
        data = {},
        noValidate = false,
        t,
        size
    } = props;


    const alert = React.useContext(AlertContext);

    const [loading, setLoading] = React.useState(false);
    const classes = useStyles({
        size
    })

    const handleUpload = async (data) => {
        if (!noValidate) {
            //only allow if file selected
            if (data[0]?.type === "image/png") {
                if (data?.length) {
                    if (fileUploadUrl) {
                        executeImageData(data)
                    }
                    else {
                        handleChange && handleChange(data)
                    }

                }
            }
            else {
                alert.setSnack({
                    open: true,
                    horizontal: AlertProps.horizontal.center,
                    vertical: AlertProps.vertical.top,
                    msg: t("Please type must be png"),
                    severity: AlertProps.severity.error,
                });
            }
        } else {
            if (data?.length) {
                if (fileUploadUrl) {
                    executeImageData(data)
                }
                else {
                    handleChange && handleChange(data)
                }

            }
        }
        // let { width = null, height = null } = await findImageSize(data[0]);
        // if (width === 115 && height === 22) {
        //     if (data?.length) {
        //         if (fileUploadUrl) {
        //             executeImageData(data)
        //         }
        //         else {
        //             handleChange && handleChange(data)
        //         }

        //     }
        // }
        // else {
        //     alert.setSnack({
        //         open: true,
        //         horizontal: AlertProps.horizontal.center,
        //         vertical: AlertProps.vertical.top,
        //         msg: `Please select the image size in ("115px x22px")`,
        //         severity: AlertProps.severity.error,
        //     });
        // }

    }

    const executeImageData = async (data) => {

        setLoading(true);

        let uploaded_file = await singleFileUpload(data?.[0], { tenantId: `${config.tenantId}` }, alert, allowed_file_size)
        if (uploaded_file?.[0]?.url) {
            handleChange && handleChange({ src: uploaded_file?.[0]?.url });
            setLoading(false);
        } else {
            handleChange(null);
            setLoading(false);
            alert.setSnack({
                open: true,
                horizontal: AlertProps.horizontal.center,
                vertical: AlertProps.vertical.top,
                msg: t("Unable to upload"),
                severity: AlertProps.severity.error,
            });
        }
    }

    const removeImage = () => {
        handleChange && handleChange(null)
    }

    return (
        <Box>


            {
                loading ? (
                    <Skeleton height={300} variant={"rectangle"} />
                ) : (

                    data?.src ?

                        <Box className={classes.root} display="flex" alignItems="center" justifyContent="center">
                            <img className={classes.uploadedimg} src={data?.src} alt="" />
                            <IconButton onClick={removeImage} size='small' className={classes.deleteBtn}>
                                <img src="/images/delete.svg" alt="" />
                            </IconButton>
                        </Box>
                        :
                        <label htmlFor="uplodebtn">
                            <Box className={classes.root} display="flex" alignItems="center" justifyContent="center">
                                <center>
                                    <FileUploadOutlinedIcon className={classes.uploadIcon} />
                                    <Typography className={classes.label}>{label}</Typography>
                                </center>
                                <input
                                    type="file"
                                    name="img"
                                    accept={accept}
                                    style={{ display: "none" }}
                                    id="uplodebtn"
                                    onChange={(value) => handleUpload(value.target.files)}
                                />
                            </Box>
                        </label>

                )
            }

            {/* <Typography className={classes.note}>
                Note: Upload in jpg, png format.In size 115px X 22px
            </Typography> */}
        </Box>
    )
}
export default withTranslation("companyLogoUpload")(CompanyLogoUpload)
