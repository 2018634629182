import * as React from "react"
const PlaceHolder = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={196} height={107} {...props}>
    <g data-name="Group 115688">
      <g data-name="Group 115687" transform="translate(59.59 1.999)">
        <path
          fill="#6c757d"
          d="m17.517 11.04-8.4 10.282V5.079a1.011 1.011 0 0 1 1.725-.713Z"
          data-name="Path 100647"
        />
        <circle
          cx={6.81}
          cy={6.81}
          r={6.81}
          fill="#6c757d"
          data-name="Ellipse 130010"
          transform="translate(29.548 31.241)"
        />
        <path
          fill="#ced4da"
          d="M70.362 36.22A34.292 34.292 0 0 0 36.392 4a33.91 33.91 0 0 0-23.618 9.524l.075.007a34.2 34.2 0 0 0-3.732 4.176v3.673a1.349 1.349 0 0 0 1.348 1.348h16.3a1.01 1.01 0 0 0 .715-1.723l-4.588-4.588c.221-.14.453-.262.679-.395l-.019-.017a25.293 25.293 0 0 1 13.176-3.49A25.539 25.539 0 1 1 13.871 50.1a.844.844 0 0 0-1.178-.339l-5.859 3.49a.863.863 0 0 0-.32 1.149 34.039 34.039 0 0 0 63.848-18.18Z"
          data-name="Path 100648"
        />
      </g>
      <text
        fill="#98a0ac"
        data-name="No restoration is done Till now"
        fontFamily="NunitoSans-Bold, Nunito Sans"
        fontSize={14}
        fontWeight={700}
        transform="translate(98 102)"
      >
        <tspan x={-97} y={0}>
          {"No restoration is done Till now"}
        </tspan>
      </text>
    </g>
  </svg>
)
export default PlaceHolder
