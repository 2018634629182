import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { SelectedResourceIcon } from "../../../assets/selectedResource";
import {
  FormGenerator,
  LoadingSection,
  SearchFilter,
} from "../../../components";
import { stringAvatar, useWindowDimensions } from "../../../utils";
import { ItemAsset } from "../assets/itemAsset";
import { SelectedAsset } from "../assets/selectedAsset";
import { SelectedUnit } from "../assets/selectedUnit";
import { UnitAssetIcon } from "../assets/unitAsset";
import { CommonFormStyles } from "../style";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";

export const CommonForm = ({
  t = () => false,
  data = {},
  company_id = [],
  image_url = "",
  title = "",
  subTitle = "",
  updateState = () => false,
  form_type = "unit",
  details = {},
  handleUpdate = () => false,
  fetchMoreData = () => false,
  handleClose = () => false,
  propertyOptions = [],
  handleUpdateProperties = () => false,
  reload = () => false,
  loading = true,
}) => {
  const classes = CommonFormStyles();
  const size = useWindowDimensions();
  const [searchText, setSearchText] = React.useState("");
  const [selectAll, setSelectAll] = React.useState(false);
  // const alert = React.useContext(AlertContext)
  const handleSearch = (val) => {
    setSearchText(val);
    reload(val);
  };

  const manualPropertyResponse = (array) => {
    return array?.property;
  };

  const BasicForm = [
    {
      size: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      isActive: form_type === "unit" ? true : false,
      component: "custom_select",
      label: t("Properties"),
      placeholder: t("Select Properties"),
      value: data?.properties,
      onChange: (val) => handleUpdateProperties("properties", val),
      error: data?.error?.properties,
      options: propertyOptions,
      debounceTimeout: 800,
      isPaginate: true,
      selectHeight: "35px",
      isMulti: true,
      color: "white",
      menuPlacement: "bottom",
      loadOptions: (search, array, handleLoading) =>
        loadOptionsApis(
          "queries/property/list",
          {
            company_ids: company_id,
            orderByKey: "name",
            orderByValue: "asc",
          },
          search,
          array,
          handleLoading,
          "data",
          {},
          manualPropertyResponse
        ),
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      isActive: data?.PMPType === "Unit" && form_type === "unit" ? true : false,
      component: "select",
      label: t("Unit Type"),
      placeholder: t("Select Unit Type"),
      value: data?.unit_type,
      onChange: (val) => updateState("unit_type", val),
      error: data?.error?.unit_type,
      options: [],
      isReadonly: true,
      debounceTimeout: 800,
      isPaginate: true,
      selectHeight: "35px",
      // isMulti: true,
      color: "#F2F4F7",
      menuPlacement: "bottom",
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      isActive:
        data?.PMPType === "Asset" && form_type === "unit" ? true : false,
      component: "select",
      label: t("Category"),
      placeholder: t("Select category"),
      value: data?.category,
      onChange: (val) => updateState("category", val),
      error: data?.error?.category,
      isReadonly: true,
      options: [],
      debounceTimeout: 800,
      isPaginate: true,
      selectHeight: "35px",
      // isMulti: true,
      color: "#F2F4F7",
      menuPlacement: "bottom",
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
      },
      isActive:
        data?.PMPType === "Asset" && form_type === "unit" ? true : false,
      component: "select",
      label: t("Subcategory"),
      placeholder: t("Select Subcategory"),
      value: data?.sub_category,
      onChange: (val) => updateState("sub_category", val),
      error: data?.error?.sub_category,
      isReadonly: true,
      options: [],
      debounceTimeout: 800,
      isPaginate: true,
      selectHeight: "35px",
      color: "#F2F4F7",
      // isMulti: true,
      menuPlacement: "bottom",
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      isActive: form_type === "resource" ? true : false,
      component: "select",
      label: t("Resource Group"),
      placeholder: t("Select Resource Group"),
      value: data?.resource_group,
      onChange: (val) => updateState("resource_group", val),
      error: data?.error?.resource_group,
      isReadonly: true,
      options: [],
      debounceTimeout: 800,
      isPaginate: true,
      selectHeight: "auto",
      isMulti: true,
      color: "#F2F4F7",
      menuPlacement: "bottom",
    },
  ];
  const handleSelect = (k, v, is_checked) => {
    const result = details?.row?.map((val) => {
      return {
        ...val,
        is_selected: v?.id === val.id ? is_checked : val?.is_selected,
      };
    });
    handleUpdate("row", result, [{ ...v, is_selected: is_checked }]);
  };
  const handleAddItems = () => {
    // const selectedData = data?.selected_items?.filter((x) => { return x?.is_selected })?.map((val) => { return val?.id })
    // const addedData = form_type === "unit" ? data?.selected_items?.filter((x) => { return x?.is_selected })?.map((val) => { return (data?.PMPType === "Asset" ? val?.mapping_id : val?.unit_id) }) ?? [] : data?.selected_resources?.filter((x) => { return x?.is_selected })?.map((val) => { return val?.resource_id }) ?? []
    const result = details?.row
      ?.filter((x) => {
        return x?.is_selected;
      })
      ?.map((z) => {
        return {
          ...z,
          mapping_id: z?.item_mapping_id,
          resource_id: form_type === "resource" ? z?.id : undefined,
          unit_id: data?.PMPType === "Unit" ? z?.id : z?.unit_id,
        };
      });
    updateState(
      form_type === "unit" ? "selected_items" : "selected_resources",
      result,
      "item_row",
      [],
      form_type === "unit" ? "allSelectedItems" : "allSelectedResources",
      form_type === "unit"
        ? [...data?.selected_items, ...result]
        : [...data?.selected_resources, ...result]
    );
    handleClose();
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    const updatedDetails = details?.row?.map((val) => ({
      ...val,
      is_selected: newSelectAll,
    }));
    handleUpdate("row", updatedDetails);
  };

  return (
    <Box>
      <Grid container spacing={0}>
        <Grid item xs={6} sm={6} lg={6}>
          <Box p={2} height={size?.height - 70} position={"relative"}>
            <Box
              maxHeight={form_type === "unit" ? "auto" : "480px"}
              overflow={form_type === "unit" ? "inherit" : "scroll"}
            >
              <Box className={classes.unitAssetCard}>
                <Stack direction="row" spacing={1} alignItems="center">
                  {form_type === "unit" ? (
                    <img src={image_url} alt="unit icon" />
                  ) : (
                    <SelectedResourceIcon />
                  )}
                  <Box>
                    <Typography className={classes?.unitTitle}>
                      {title}
                    </Typography>
                    <Typography className={classes?.unitSubTitle}>
                      {subTitle}
                    </Typography>
                  </Box>
                </Stack>
                <Box mt={2}>
                  <FormGenerator t={t} components={BasicForm} />
                </Box>
              </Box>
            </Box>
            <Box mt={2} className={classes.itemSelectedCard}>
              <Stack direction={"row"} spacing={1} alignItems="center">
                <Box>
                  {form_type === "unit" ? (
                    data?.PMPType === "Unit" ? (
                      <UnitAssetIcon />
                    ) : (
                      <ItemAsset />
                    )
                  ) : (
                    <UnitAssetIcon />
                  )}
                </Box>
                <Typography className={classes.selectedCount}>{`${
                  details?.row?.filter((x) => {
                    return x?.is_selected;
                  })?.length ?? 0
                } ${
                  form_type === "unit"
                    ? data?.PMPType === "Unit"
                      ? "Units"
                      : "Items"
                    : "Resources"
                } Selected`}</Typography>
              </Stack>
            </Box>
            <Box className={classes.fixedbtn} onClick={() => handleAddItems()}>
              <Button variant="contained" fullWidth>
                {t("Add")}
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <Box sx={{ borderLeft: "1px solid #CED3DD" }} p={2}>
            <Box>
              <Typography className={classes.recommendedUnitTitle}>
                {t(
                  `Recommended ${
                    form_type === "unit"
                      ? data?.PMPType === "Unit"
                        ? "Units"
                        : "Items/Asset"
                      : "Resources"
                  }`
                )}
              </Typography>
              <>
                <Box mt={2}>
                  <SearchFilter
                    value={searchText}
                    placeholder={
                      form_type === "unit"
                        ? data?.PMPType === "Unit"
                          ? t("Search Unit")
                          : t("Search Item/Asset")
                        : t("Search Resource")
                    }
                    handleChange={(value) => handleSearch(value)}
                  />
                </Box>
                {details?.row?.length > 0 && (
                  <Box
                    mt={2}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems="center"
                  >
                    <Typography className={classes.unitSubTitle}>
                      {`${details?.count} ${
                        form_type === "unit"
                          ? data?.PMPType === "Unit"
                            ? "Units"
                            : "Items"
                          : "Resources"
                      }`}
                    </Typography>
                    <Box display="flex" alignItems="center">
                      <Typography
                        className={classes.unitSubTitle}
                        onClick={handleSelectAll}
                        style={{ cursor: "pointer", color: "#e74c3c" }}
                      >
                        Select All
                      </Typography>
                    </Box>
                  </Box>
                )}
              </>
              {/* <Box height={size?.height - 250} overflow="scroll"> */}
              {loading ? (
                <LoadingSection />
              ) : (
                <InfiniteScroll
                  dataLength={details?.row?.length ?? ""}
                  next={fetchMoreData}
                  hasMore={true}
                  height={size?.height - 250}
                >
                  {details?.row?.length > 0 ? (
                    details?.row?.map((val, i) => {
                      return (
                        <Box mt={2} className={classes.unitItemCard}>
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems="center"
                          >
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Box>
                                {val?.logo !== null ||
                                val?.item_assets !== null ||
                                (val?.image_url !== "" &&
                                  val?.image_url !== null) ? (
                                  <img
                                    src={
                                      data?.PMPType === "Asset" &&
                                      form_type === "unit"
                                        ? val?.item_assets?.[0]?.url ??
                                          "../../images/selectedAsset.svg"
                                        : form_type === "resource"
                                        ? val?.image_url ??
                                          "../../images/resourceImg.svg"
                                        : val?.logo ??
                                          "../../images/propertdashboard/block.svg"
                                    }
                                    width="40px"
                                    height="40px"
                                    style={{
                                      borderRadius: "4px",
                                      border: "1px solid #E4E8EE",
                                      padding: "4px",
                                    }}
                                    alt="image_url"
                                  />
                                ) : form_type === "unit" ? (
                                  data?.PMPType === "Unit" ? (
                                    <SelectedUnit />
                                  ) : (
                                    <SelectedAsset />
                                  )
                                ) : (
                                  <Avatar
                                    variant={"square"}
                                    sx={{ borderRadius: "4px" }}
                                    {...stringAvatar(val?.name)}
                                  />
                                )}
                              </Box>
                              <Box>
                                <Typography className={classes.selectedName}>
                                  {val?.name}
                                </Typography>
                                {form_type === "unit" &&
                                data?.PMPType === "Unit" ? (
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    divider={
                                      <Divider
                                        orientation="vertical"
                                        flexItem
                                        sx={{ borderRadius: "50%" }}
                                      />
                                    }
                                  >
                                    <Typography
                                      className={classes.unitSubTitle}
                                    >
                                      {val?.unit_no}
                                    </Typography>
                                    <Typography
                                      className={classes.unitSubTitle}
                                    >
                                      {val?.unit_type}
                                    </Typography>
                                    <Typography
                                      className={classes.unitSubTitle}
                                      noWrap
                                      sx={{ maxWidth: "100px" }}
                                    >
                                      {val?.property}, {val?.block ?? ""},{" "}
                                      {val?.floor ?? ""}
                                    </Typography>
                                  </Stack>
                                ) : form_type === "resource" ? (
                                  <>
                                    <Typography
                                      className={classes.unitSubTitle}
                                    >
                                      {val?.reference_id} |{" "}
                                      {val?.resource_group_name}
                                    </Typography>
                                    <Typography
                                      className={classes.unitSubTitle}
                                      noWrap
                                    >
                                      {val?.department}, {val?.job}
                                    </Typography>
                                  </>
                                ) : (
                                  <>
                                    <Typography
                                      className={classes.unitSubTitle}
                                    >
                                      {val?.serial_number !== null &&
                                      val?.serial_number !== ""
                                        ? `${val?.serial_number},`
                                        : ""}
                                      {val?.manufacturer !== null
                                        ? val?.manufacturer
                                        : ""}
                                      {val?.last_service !== null
                                        ? `${
                                            val?.manufacturer !== null
                                              ? ","
                                              : ""
                                          } ${moment(val?.last_service).format(
                                            "DD MMM YYYY"
                                          )}`
                                        : ""}
                                      {val?.last_service !== null
                                        ? `${
                                            val?.last_service !== null
                                              ? ","
                                              : ""
                                          } ${moment(val?.last_service).format(
                                            "DD MMM YYYY"
                                          )}`
                                        : ""}
                                    </Typography>
                                    <Typography
                                      className={classes.unitSubTitle}
                                    >
                                      {val?.unit_name}, {val?.property_name}
                                    </Typography>
                                  </>
                                )}
                              </Box>
                            </Stack>
                            <Checkbox
                              onChange={(e) =>
                                handleSelect(
                                  "multi_select",
                                  val,
                                  e?.target.checked
                                )
                              }
                              color="primary"
                              checked={val?.is_selected ? true : false}
                            />
                          </Box>
                        </Box>
                      );
                    })
                  ) : (
                    <Box display="flex" justifyContent={"center"} mt={2}>
                      <Typography className={classes.title}>
                        {form_type === "unit"
                          ? data?.PMPType === "Unit"
                            ? t("No Units Found")
                            : t("No Assets Found")
                          : t("No Resources Found")}
                      </Typography>
                    </Box>
                  )}
                </InfiniteScroll>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
