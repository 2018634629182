import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CityProperty from "../../assets/cityproperty";
import { PropertyTabs, Subheader } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import {
  AlertProps,
  allowed_file_size,
  NetWorkCallMethods,
  useWindowDimensions,
  ValidateEmail,
} from "../../utils";
import {
  accessCheckRender,
  assestType,
  getRoutePermissionNew,
  mapResult,
  singleFileUpload,
} from "../../utils/common";
import DomesticHelperDetails from "./domesticHelperDetails";
import { KycDetails } from "./kycDetails";
import { useStyles } from "./style";
import { withTranslation } from "react-i18next";

const DomesticHelperCreateEdit = ({ t }) => {
  const size = useWindowDimensions();
  const classes = useStyles(size);
  const { state } = useLocation();
  // const mapLoad = !Boolean(state?.main?.domesticHelperID)
  const initial = {
    image: "",
    company: state?.main?.company,
    property: state?.main?.selectedProperty,
    name: "",
    gender: "",
    profession: "",
    idType: "",
    idNumber: "",
    description: "",
    doorNo: "",
    addressLineOne: "",
    addressLineTwo: "",
    landmark: "",
    area: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    latitude: "",
    longitude: "",
    telephone: "",
    mobile: "",
    email: "",
    bankName: "",
    branchName: "",
    currency: "",
    accountType: "",
    accountNo: "",
    routingType: "",
    routingCode: "",
    cashCollection: "",
    cheque: "",
    bankAddress1: "",
    bankAddress2: "",
    bankCity: "",
    bankCountry: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    whatsapp: "",
    barred: false,
    nationality: "",
    error: {
      image: "",
      company: "",
      property: "",
      name: "",
      telephone: "",
      mobile: "",
      email: "",
      nationality: "",
    },
  };

  const backdrop = React.useContext(BackdropContext);
  const [data, setData] = React.useState({ ...initial });
  const [disable, setDisable] = React.useState(true);
  const [load, setLoad] = React.useState(false);
  const [value, setValue] = React.useState(1);
  const [mapLoad, setMapLoad] = React.useState(
    !Boolean(state?.main?.domesticHelperID)
  );
  const [permission, setPermission] = React.useState({});
  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext);
  const navigate = useNavigate();

  const tabTittle = [
    {
      label: t("ContactDetails"),
      // sub: t('ContactDetails'),

      imgSelect:
        value > 1 ? (
          <img src="/images/correct_icon.svg" alt="icon" />
        ) : (
          <CityProperty color={value === 1 ? "#5078E1" : "#98A0AC"} />
        ),
      value: 1,
      className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle,
    },
    {
      label: t("KYCDetails"),
      // sub: t('KYCDetails'),
      imgSelect:
        value > 2 ? (
          <img src="/images/correct_icon.svg" alt="icon" />
        ) : (
          <CityProperty color={value === 2 ? "#5078E1" : "#98A0AC"} />
        ),
      value: 2,
      className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle,
    },
  ];

  const updateState = (k, v) => {
    let error = data?.error;
    error[k] = "";
    setData({ ...data, [k]: v, error });
  };

  const removeimg = () => {
    setData({ ...data, image: "" });
  };

  const getDetails = () => {
    backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." });
    const payload = {
      id: state?.main?.domesticHelperID,
    };
    NetworkCall(
      `${config.api_url}/queries/domestic_helper_master/get_by_id`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((r) => {
      const tempData = r?.data?.data?.domestic_helper_master?.[0];
      setData({
        image: tempData?.helper_image ?? "",
        company: state?.main?.company ?? "",
        property: tempData?.propertyByID ?? "",
        name: tempData?.name ?? "",
        gender: tempData?.gender
          ? { label: tempData?.gender, value: tempData?.gender }
          : "",
        profession: tempData?.profession_masterByID ?? "",
        idType: tempData?.identification_masterByID ?? "",
        idNumber: tempData?.identification_no ?? "",
        description: tempData?.description ?? "",
        doorNo: tempData?.domestic_helper_addressByID?.[0]?.door_no ?? "",
        addressLineOne:
          tempData?.domestic_helper_addressByID?.[0]?.street_1 ?? "",
        addressLineTwo:
          tempData?.domestic_helper_addressByID?.[0]?.street_2 ?? "",
        landmark: tempData?.domestic_helper_addressByID?.[0]?.landmark ?? "",
        area: tempData?.domestic_helper_addressByID?.[0]?.area ?? "",
        city: tempData?.domestic_helper_addressByID?.[0]?.city ?? "",
        state: tempData?.domestic_helper_addressByID?.[0]?.state ?? "",
        country: tempData?.domestic_helper_addressByID?.[0]?.country ?? "",
        zipcode: tempData?.domestic_helper_addressByID?.[0]?.pincode ?? "",
        latitude: tempData?.domestic_helper_addressByID?.[0]?.latitude ?? "",
        longitude: tempData?.domestic_helper_addressByID?.[0]?.longitude ?? "",
        telephone: tempData?.domestic_helper_addressByID?.[0]?.telephone_no
          ? {
              mobile: tempData?.domestic_helper_addressByID?.[0]?.telephone_no,
              mobile_code:
                tempData?.domestic_helper_addressByID?.[0]?.telephone_code,
            }
          : "",
        mobile: tempData?.domestic_helper_addressByID?.[0]?.mobile_no
          ? {
              mobile: tempData?.domestic_helper_addressByID?.[0]?.mobile_no,
              mobile_code:
                tempData?.domestic_helper_addressByID?.[0]?.mobile_code,
            }
          : "",
        email: tempData?.domestic_helper_addressByID?.[0]?.email_id ?? "",
        bankName: tempData?.bank_name ?? "",
        branchName: tempData?.branch_name ?? "",
        currency: tempData?.currency_masterByID ?? "",
        accountType: tempData?.account_type
          ? {
              value: tempData?.account_type,
              label: tempData?.account_type,
            }
          : "",
        accountNo: tempData?.account_no ?? "",
        routingType: tempData?.routing_type
          ? {
              value: tempData?.routing_type,
              label: tempData?.routing_type,
            }
          : "",
        routingCode: tempData?.routing_code ?? "",
        cashCollection: tempData?.cash_collection_office ?? "",
        cheque: tempData?.cheque_name ?? "",
        bankAddress1: tempData?.address_1 ?? "",
        bankAddress2: tempData?.address_2 ?? "",
        bankCity: tempData?.bank_city ?? "",
        bankCountry: tempData?.bank_country ?? "",
        facebook: tempData?.facebook ?? "",
        twitter: tempData?.twitter ?? "",
        linkedin: tempData?.linkedin ?? "",
        whatsapp: tempData?.whatsapp ?? "",
        error: initial?.error,
        barred: tempData?.is_barred,
        nationality: {
          value: tempData?.nationality,
          label: tempData?.nationality,
        },
        proof: tempData?.proof?.map((e) => {
          return {
            id: e?.id,
            type: {
              value: e?.identification_master?.id,
              label: e?.identification_master?.type,
              proof_type: e?.identification_master?.proof_type,
            },
            name: e?.name,
            id_no: e?.id_no,
            valid_from: e?.valid_from,
            valid_to: e?.valid_to,
            issuing_country: {
              label: e?.country_master?.label,
              value: e?.country_master?.value,
            },
            mandatry_verify: e?.is_mandatory,
            file: e?.url,
            is_active: e?.is_active,
          };
        }),
      });
      setLoad(true);
      backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
    });
  };

  const save = () => {
    if (validate()) {
      const datas = {
        is_active: true ?? null,
        helper_image: data?.image ?? null,
        company_id: state?.main?.companyID ?? null,
        property_id: data?.property?.value ?? null,
        name: data?.name ?? null,
        gender: data?.gender?.value ?? null,
        profession_id: data?.profession?.value ?? null,
        id_type: data?.idType?.value ?? null,
        identification_no: data?.idNumber ?? null,
        description: data?.description ?? null,
        door_no: data?.doorNo ?? null,
        street_1: data?.addressLineOne ?? null,
        street_2: data?.addressLineTwo ?? null,
        landmark: data?.landmark ?? null,
        area: data?.area ?? null,
        city: data?.city ?? null,
        state: data?.state ?? null,
        zipcode: data?.zipcode ?? null,
        country: data?.country ?? null,
        latitude: parseFloat(data?.latitude) ?? null,
        longitude: parseFloat(data?.longitude) ?? null,
        telephone_code: data?.telephone?.mobile_code ?? null,
        telephone_no: data?.telephone?.mobile ?? null,
        mobile_code: data?.mobile?.mobile_code ?? null,
        mobile_no: data?.mobile?.mobile ?? null,
        email_id: data?.email ?? null,
        bank_name: data?.bankName ?? null,
        branch_name: data?.branchName ?? null,
        currency_id: data?.currency?.value ?? null,
        account_type: data?.accountType?.value ?? null,
        account_no: data?.accountNo ?? null,
        routing_type: data?.routingType?.value ?? null,
        routing_code: data?.routingCode ?? null,
        cash_collection_office: data?.cashCollection ?? null,
        cheque_name: data?.cheque ?? null,
        address_1: data?.bankAddress1 ?? null,
        address_2: data?.bankAddress2 ?? null,
        bank_city: data?.city ?? null,
        bank_country: data?.country ?? null,
        facebook: data?.facebook ?? null,
        twitter: data?.twitter ?? null,
        linkedin: data?.linkedin ?? null,
        whatsapp: data?.whatsapp ?? null,
        is_barred: data?.barred ?? null,
        nationality: data?.nationality?.label ?? null,
        proof: data?.proof
          ?.filter((val) => val?.is_active)
          .map((val) => {
            return {
              id: val?.id ?? undefined,
              proof_id: val?.type?.value,
              proof_type: val?.type?.proof_type,
              kyc_type: "Both",
              name: val?.name,
              number: val?.id_no,
              valid_from: val?.valid_from,
              valid_to: val?.valid_to,
              url: val?.file,
              is_mandatory: val?.mandatry_verify,
              country_id: val?.issuing_country?.value,
              is_active: val?.is_active,
            };
          }),
      };
      if (state?.main?.domesticHelperID) {
        datas["id"] = state?.main?.domesticHelperID;
      }
      NetworkCall(
        `${config.api_url}/domestic_helper/upsert`,
        NetWorkCallMethods.post,
        datas,
        null,
        true,
        false
      )
        .then((response) => {
          navigate(-1);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `${t("Registered worker ")}${
              state?.main?.domesticHelperID ? `${t("updated")}` : `${t("created")}`
            } ${t("successfully.!!!")}`,
          });
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("InternalerrorPleasetryagainlater"),
          });
          setDisable(false);
        });
    } else {
      setDisable(false);
      return false;
    }
  };

  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        if (state?.main?.domesticHelperID) {
          getDetails();
        } else {
          setLoad(true);
        }
      }
    }
    // eslint-disable-next-line
  }, [state?.main?.domesticHelperID, auth]);

  const validate = () => {
    let isValid = true;
    let error = data.error;
    if (data?.company?.length === 0) {
      isValid = false;
      error.company = t("CompanyisRequired");
    }
    if (data?.name?.length === 0) {
      isValid = false;
      error.name = t("NameisRequired");
    }
    if (data?.property?.length === 0) {
      isValid = false;
      error.property = t("PropertyisRequired");
    }
    if (data?.nationality?.length === 0) {
      isValid = false;
      error.nationality = t("nationalityisRequired");
    }
    if (data?.gender?.length === 0) {
      isValid = false;
      error.gender = t("GenderisRequired");
    }
    if (data?.profession?.length === 0) {
      isValid = false;
      error.profession = t("professionIsRequired");
    }
    if (data?.barred?.length === 0) {
      isValid = false;
      error.barred = t("barredisRequired");
    }
    if (state?.main?.domesticHelperID) {
      if (data?.telephone?.mobile?.length === 0) {
        isValid = false;
        error.telephone = t("TelephoneisRequired");
      }
      if (data?.mobile?.mobile?.length === 0) {
        isValid = false;
        error.mobile = t("MobilePhoneisRequired");
      }
      if (data?.telephone?.length === 0) {
        isValid = false;
        error.telephone = t("TelephoneisRequired");
      }
      if (data?.mobile?.length === 0) {
        isValid = false;
        error.mobile = t("MobilePhoneisRequired");
      }
    } else {
      if (data?.telephone?.mobile?.length === 0) {
        isValid = false;
        error.telephone = t("TelephoneisRequired");
      }
      if (data?.mobile?.mobile?.length === 0) {
        isValid = false;
        error.mobile = t("MobilePhoneisRequired");
      }
      if (data?.telephone?.length === 0) {
        isValid = false;
        error.telephone = t("TelephoneisRequired");
      }
      if (data?.mobile?.length === 0) {
        isValid = false;
        error.mobile = t("MobilePhoneisRequired");
      }
    }
    // eslint-disable-next-line
    var email = /^\w+([\.-]?\ w+)*@\w+([\.-]?\ w+)*(\.\w{2,3})+$/.test(
      data?.email
    );
    if (data?.email?.length === 0 || !ValidateEmail(data?.email)) {
      isValid = false;
      error.email = t("EntervalidEmail");
    }

    if (!isValid) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Pleasefillallmandatoryfield"),
      });
    }
    setData({ ...data, error });
    return isValid;
  };

  const mapResultData = (map) => {
    let result = mapResult(map);
    setData({ ...data, ...result });
  };
  const updateimg = async (data) => {
    backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." });
    try {
      const uploaded_file = await singleFileUpload(
        data,
        {
          companyId: state?.main?.company?.value,
          type: assestType?.Thumbnails,
        },
        alert,
        allowed_file_size
      );
      if (uploaded_file?.[0]?.url) {
        updateState("image", uploaded_file?.[0]?.url);
        backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
      } else {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      }
    } catch (err) {
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: t("SomeThingWentWrong"),
      });
    }
  };

  // tab functions

  //goNext
  const next = () => {
    if (value === 1) {
      if (validate()) {
        setDisable(false);
        setValue(2);
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Pleasefillallmandatoryfield"),
        });
        return false;
      }
    }
  };
  //goPreviuos
  const previous = () => {
    setValue((prevValue) => prevValue - 1);
    if (!state?.main?.domesticHelperID) {
      setMapLoad(false);
    }
  };

  const checkSave = (type) => {
    setDisable(true);
    if (data?.id) {
      if (permission.update) {
        type === "previous" && previous();
        type === "next" && next();
        type === "save" && save();
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          msg: t("AccessdeniedContactyouradministrator."),
          severity: AlertProps.severity.error,
        });
      }
    } else if (permission.create) {
      type === "previous" && previous();
      type === "next" && next();
      type === "save" && save();
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        msg: t("AccessdeniedContactyouradministrator."),
        severity: AlertProps.severity.error,
      });
    }
  };
  const render = () => {
    return (
      <div className={classes.root}>
        <Subheader
          title={t("RegisteredWorkersADDOREDIT")}
          goBack={() => {
            navigate(-1);
          }}
          previousAndNextBtn={true}
          previousBtn={() => checkSave("previous")}
          nextBtn={() => checkSave("next")}
          previousBtnDisabled={value <= 1 && true}
          nextBtnDisabled={value === 2 ? true : false}
        />
        <div className={classes.content}>
          <PropertyTabs
            padding={"6px 8px 5px 8px"}
            backgroundColor={"white"}
            value={value}
            tab1={
              <DomesticHelperDetails
                t={t}
                id="pushTitle"
                data={data}
                updateState={updateState}
                mapResult={mapResultData}
                removeimg={removeimg}
                updateimg={updateimg}
                mapLoad={mapLoad}
                load={load}
              />
            }
            tab2={
              <KycDetails
                t={t}
                data={data?.proof?.filter((val) => val?.is_active)}
                update={(data) => updateState("proof", data)}
              />
              // <ContactProof data={data?.proof?.filter((val) => val?.is_active)} update={(data) => updateState("proof", data)} />
            }
            t={t}
            isHeigth
            tabTittle={tabTittle}
            showBtns={true}
            isSaveDisable={disable}
            saveBtnText={state?.main?.propertyID ? t("Update") : t("Submit")}
            goBack={() => navigate(-1)}
            save={() => checkSave("save")}
          />
        </div>
        {/* <Box className={classes.bottombtn}>
                  <Button className={classes.Cancel}
                        onClick={() => { navigate(-1) }}>
                        {t("Cancel")}
                  </Button>
                  <Button className={classes.next} onClick={save}
                        disabled={disable === true ? true : false}>
                        {state?.main?.propertyID ? t("Update") : t("Submit")}
                  </Button>
            </Box> */}
      </div>
    );
  };
  return <>{accessCheckRender(render, permission)}</>;
};

export default withTranslation("domesticHelper")(DomesticHelperCreateEdit);
