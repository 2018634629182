import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: `0px 0px 16px #00000014`,
    backgroundColor: "white",
    //     padding: "16px 0px 16px 16px",
    margin: "14px",
    height: `calc(100vh - 147px)`,
  },

  img: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "10px",
    marginInlineEnd: "10px",
    height: "40px",
    width: '40px'
  },
  draft: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFFFFF",
    backgroundColor: "#4E5A6B",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    marginInlineStart: "10px",
    fontSize:"0.75rem",
  },
  draft1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFFFFF",
    backgroundColor: theme?.palette?.primary?.main,
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    marginInlineStart: "10px",
    fontSize:"0.75rem",
  },
  tab: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #E4E8EE;",
    position: "relative"
  },
  tabContant: {
    color: "#4E5A6B",
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().semiBold,
    padding: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "relative"
  },
  activetabContant: {
    color: theme?.palette?.primary?.main,
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().semiBold,
    padding: "16px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  borderAbsolute: {
    position: "absolute",
    backgroundColor: theme?.palette?.primary?.main,
    height: "3px",
    width: "100%",
    bottom: 0,
    borderRadius: "16px 16px 0px 0px",
    left: 0,
    right: 0,
  },
  next: {
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    //     padding: "14px",
    width: "100%",
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
    },
    height: '40px'
  },
  title: {
    color: "#98A0AC",
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  selectBox: {
    border: "1px solid #E4E8EE",
    borderRadius: "4px",
    padding: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  images: {
    width: "42px",
    height: "42px",
    borderRadius: "4px",
  },
  optionTitle: {
    color: "#091B29",
    fontFamily: FontFamilySwitch().regular,
    fontSize:"0.875rem",
  },
  optionSub: {
    color: "#98A0AC",
    fontFamily: FontFamilySwitch().regular,
    fontSize:"0.75rem",
    marginTop: "6px",
  },
  detailsPadding: {
    paddingTop: "1px",
  },
  announsmentTitle: {
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().bold,
    color: "white",
  },
  titleBar: {
    backgroundColor: theme?.typography?.status?.primary ?? "",
    padding: "16px 37px",
    position: "relative",
    borderRadius: "8px 8px 0px 0px",
  },
  arrowIcon: {
    color: "white",
    fontSize:"1rem",
    position: "absolute",
    top: "20px",
    bottom: "0",
    left: "14px",
  },
  chip: {
    borderRadius: "4px",
    padding: "4px",
    color: theme?.typography?.color?.tertiary,
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().bold,
    backgroundColor: theme?.palette?.border?.secondary ?? "",
  },
  dateAndTime: {
    borderRadius: "4px",
    padding: "4px",
    color: theme?.typography?.color?.tertiary,
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().regular,
    textAlign: 'right'
  },
  reactQuil: {
    "& .ql-editor": {
      padding: "0px 0px 16px 0px",
      textAlign: "start"

    },
  },
  detailscontent: {
    height: `calc(100vh - 435px)`,
    overflow: "auto",
    backgroundColor: theme?.palette?.background?.annoumsmentTeritary ?? "",
    padding: "16px",
  },
  detailsBox: {
    backgroundColor: "white",
    padding: "16px",
  },
  addbutton: {
    color: theme.palette.primary.main,
    borderRadius: "22px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
  menu: {
    "& .MuiPopover-paper": {
      boxShadow: `0 0px 8px -4px ${theme?.palette?.border?.tertiary}`,
      border: `1px solid ${theme?.palette?.border?.tertiary}`,
      borderRadius: "5px",
    },
  },
  menuItems: {
    display: "flex",
    alignItems: "center",
  },
  menuContant: {
    color: theme?.typography?.color?.primary ?? "",
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    fontWeight: 600,
  },
  flexBox: {
    padding: "10px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  fileroot: {
    width: "100%",
    fontSize:"0.75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "4px",
    padding: "9px",
    backgroundColor: theme?.palette?.info?.primary ?? "",
    height: "188px",
  },
  imgContainer: {
    width: "100%",
    maxWidth: "188px",
    margin: "0px 10px",
    borderRadius: "12px",
    display: "flex",
    height: "188px",
  },
  select: {
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    padding: "8px",
    fontSize:"0.75rem",
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
  unSelect: {
    backgroundColor: "white",
    color: theme?.palette?.background?.announsment ?? "",
    fontSize:"0.75rem",
    padding: "8px",
    fontWeight: 600,
    fontFamily: FontFamilySwitch().semiBold,
    border: `1px solid ${theme?.palette?.background?.secondary}`,
    // borderRadius:"4px 0px 0px 4px",
    "&:hover": {
      backgroundColor: "white ",
    },
  },
  typeName: {
    color: theme?.typography?.color?.primary ?? "",
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  emailPreview: {
    background: "white",
    "&:hover": {
      background: "white",
    },
  },
  detailsBox1: {
    padding: "6px",
    backgroundColor: theme?.palette?.info?.light ?? "",
    borderRadius: "4px",
    maxHeight: "57px",
    height: "100%",
    cursor: "pointer"
  },
  fileName: {
    color: theme?.typography?.color?.secondary ?? "",
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  type: {
    color: theme?.typography?.color?.tertiary,
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().regular,
  },
  dot: {
    backgroundColor: theme?.palette?.border?.primary ?? "",
    height: "6px",
    width: "6px",
    // margin: "0 auto",
    borderRadius: "50%",
  },
  viewRoot: {
    backgroundColor: "white",
    padding: "16px",
  },
  headerBox1: {
    backgroundColor: theme?.palette?.background?.annoumsmentTeritary ?? "",
    padding: "9px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  border: {
    borderLeft: "1px solid #E4E8EE",
    paddingInlineStart: "8px",
    [theme.breakpoints.down("md")]: {
      borderLeft: "none",
      paddingInlineStart: "0px",
    },
  },
  contant: {
    height: `calc(100vh - 324px)`,
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      height: 200,
    },
  },
  noPadding: {
    paddingTop: "0px !important"
  },
  PaddingTop: {
    paddingTop: "7px !important"
  },
  rootDetails: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: `0px 0px 16px #00000014`,
    backgroundColor: "#F5F7FA",
    //     padding: "16px 0px 16px 16px",
    margin: "14px",
    height: `calc(100vh - 0px)`,
  }
}));
