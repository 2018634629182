import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStylesManagement = makeStyles((theme) => ({
      root: {
            border: "2px solid white",
            borderRadius: theme.palette.borderRadius,
            boxShadow: "0px 0px 16px #00000014",
            backgroundColor: "white",
            padding: "16px",
            height: `calc(100vh - 147px)`,
            overflow: "hidden",
            margin: "14px"
      },

      card: {
            border: "2px solid white",
            borderRadius: theme.palette.borderRadius,
            boxShadow: "0px 0px 16px #00000014",
            backgroundColor: "white",
            padding: "8px",
      },
      unitImg: {
            height: "75px",
            width: "75px",
            borderRadius: "50%",
            margin: "0px",
            objectFit: "fill"
      },
      imgTag: {
            margin: "0px 8px",
            padding: "1px 4px",
            borderRadius: "4px",
            backgroundColor: "#071741",
            color: "white",
            fontSize:"0.75rem",
            fontFamily: FontFamilySwitch().semiBold,
            display: "inline",
      },
      imgDiv: {
            display: "flex",
            justifyContent: "center",
            position: "relative",
            borderRight: "1px solid #00000014",
            padding: "0px",
      },
      title: {
            color: theme.typography.color.secondary,
            fontSize:"0.75rem",
            fontFamily: FontFamilySwitch().bold,
      },
      imgDiv2: {
            padding: "0px 12px"
      },
      heading: {
            color: theme.typography.color.tertiary,
            fontSize:"0.875rem",
            fontFamily: FontFamilySwitch().semiBold,
            textTransform: "capitalize"
      },
      sub: {
            color: theme.typography.color.primary,
            fontSize:"0.875rem",
            fontFamily: FontFamilySwitch().semiBold,
            textTransform: "capitalize",
            '& .ql-editor': {
                  padding: '0px 0px 16px 0px',
                  color: theme.typography.color.primary,
                  fontSize:"0.875rem",
                  fontFamily: FontFamilySwitch().semiBold,
                  textTransform: "capitalize",
            }

      },
      roles: {
            fontSize:"1rem",
            color: '#091B29',
            fontFamily: FontFamilySwitch().semiBold,
            marginBottom: '0 !important',
            marginLeft: '10px'

      },
      addmember: {
            width: '100%',
            '&:hover': {
                  backgroundColor: '#fff',

            }
      },
      managericon: {
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#E29336',
            justifyContent: 'center',
            padding: '10px 15px'

      },
      // card
      cards: {
            cursor: "pointer",

            backgroundColor: theme.palette.background.paper,
            margin: "auto 4px",
            overflow: "hidden",
      },
      Cardcontent: {
            [theme.breakpoints.up("sm")]: {
                  padding: "8px 12px",
            },

            [theme.breakpoints.down("sm")]: {
                  padding: "8px 0px",
            },
      },
      title1: {
            fontSize:"1rem",
            color: theme.typography.color.secondary,
            fontFamily: FontFamilySwitch().bold,
      },
      progress: {
            fontSize:"0.75rem",
            color: "#FFFFFF",
            fontFamily: FontFamilySwitch().bold,
            background: "#78B1FE",
            padding: "1px 8px",
            borderRadius: theme.palette.borderRadius,
      },
      approved: {
            fontSize:"0.75rem",
            color: "#FFFFFF",
            fontFamily: FontFamilySwitch().bold,
            background: "#5AC782",
            padding: "1px 8px",
            borderRadius: theme.palette.borderRadius,
      },
      delete: {
            fontSize:"0.75rem",
            color: "#FFFFFF",
            fontFamily: FontFamilySwitch().bold,
            background: "red",
            padding: "1px 8px",
            borderRadius: theme.palette.borderRadius,
      },
      cancel: {
            fontSize:"0.75rem",
            color: "#FFFFFF",
            fontFamily: FontFamilySwitch().bold,
            background: "#CED3DD",
            padding: "1px 8px",
            borderRadius: theme.palette.borderRadius,
      },
      dot: {
            height: "6px",
            width: "6px",
            borderRadius: "50%",
            backgroundColor: "#CED3DD",
            margin: "6px",
      },
      sub1: {
            fontSize:"0.75rem",
            color: theme.typography.color.secondary,
            fontFamily: FontFamilySwitch().regular,
      },
      img: {
            borderRadius: theme.palette.borderRadius,
            border: "1px solid #E4E8EE",
            padding: "2px 8px",
      },
      rejected: {
            fontSize:"0.75rem",
            color: "#FFFFFF",
            fontFamily: FontFamilySwitch().bold,
            background: "#EC903F",
            padding: "1px 8px",
            borderRadius: theme.palette.borderRadius,
            display: "inline",
      },
      boxes: {
            flexFlow: 'wrap !important'
      },
      // dialog
      dialog: {
            width: "100%",
            maxWidth: "auto",
            padding: "0 !important",
            "& .MuiPaper-root": {
                  width: "100% !important",
                  borderRadius: theme.palette.borderRadius,
            },
            "& .MuiDialogContent-root": {
                  padding: "0px !important",
                  position: 'relative'
            },
      },
      header: {
            border: "1px solid #E4E8EE",
            fontSize:"1rem",
            fontFamily: FontFamilySwitch().extraBold,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
      },
      select: {
            color: '#091B29',
            fontSize:"0.75rem",
            fontFamily: FontFamilySwitch().bold,
            marginLeft: '10px'
      },
      selectBox: {
            display: 'flex',
            alignItems: 'center',
            padding: '5px',
            backgroundColor: '#F5F7FA',
            borderRadius: '20px',
            marginTop: '12px',
            height: '40px',
            cursor: 'pointer'
      },
      avatar: {
            height: '30px',
            width: '30px'
      },
      button: {
            width: '100%',
            color: '#fff',
            fontSize:"0.875rem",
            fontFamily: FontFamilySwitch().bold,
            '&:hover': {
                  backgroundColor: '#5078E1',
            }
      },
      tableSection: {
            backgroundColor: "white",
            borderRadius: theme.palette.borderRadius,
            padding: "20px",
      },
      addbtn: {
            marginTop: "10px"
      },
      historyCard: {
            padding: "15px"
      },
      dashedborder: {
            borderColor: theme.palette.border.secondary,
            borderLeftStyle: "dashed",
            borderLeftWidth: "2px",
            height: "80px",
            marginLeft: "9px",
            marginTop: "-2px"
      },
      noborder: {
            height: "80px",
            marginLeft: "9px",
      },
      historydot: {
            height: "8px",
            width: "8px",
            borderRadius: "50%",
            backgroundColor: "#CED3DD",
            margin: "6px",
      },
      // title: {
      //       fontSize:"0.75rem",
      //       color: theme.typography.color.tertiary,
      //       fontFamily: FontFamilySwitch().semiBold,
      // },
      titlecontent: {
            fontSize:"0.75rem",
            color: theme.typography.color.primary,
            fontFamily: FontFamilySwitch().bold,
            marginLeft: "5px"
      },
      memberName: {
            fontSize:"0.875rem",
            color: theme.typography.color.primary,
            fontFamily: FontFamilySwitch().semiBold,
      },
      subdetails: {
            fontSize:"0.75rem",
            color: theme.typography.color.secondary,
            fontFamily: FontFamilySwitch().regular,            
      },
      role: {
            fontSize:"0.875rem",
            marginBottom: "10px",
            // backgroundColor: "#EBFBFF",
            // color: "#2CA2BF",
            borderRadius: theme.palette.borderRadius,
            padding: "5px",
            width: "fit-content",
            textTransform: "capitalize"
      },
      dividerStyle: {
            height: "40px"
      },
      cancelbtn: {
            '&:hover': {
                  backgroundColor: "white"
            }
      },
      propertyName: {
            fontSize:"0.75rem",
            fontFamily: FontFamilySwitch().semiBold,
            color: theme.typography.color.tertiary,
            margin: "10px 0px"
      },
      delTxt: {
            color: theme.typography.color.primary,
            fontSize:"1rem",
            fontWeight: "bold",
      },
      delIcon: {
            width: "35px",
            height: "35px",
            margin: "auto",
            borderRadius: "50%",
            padding: "10px",
            backgroundColor: theme.palette.error.light
      },
      delBox: {
            backgroundColor: "#fff",
            width: "400px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
      },
      delIconSvg: {
            display: "flex",
            margin: "auto"
      },
      delBtn: {
            fontSize: 13,
            fontWeight: "500"
      },
      delBtn_outlined: {
            fontSize: 13,
            fontWeight: "500",
            '&:hover': {
                  background: "#fff"
            }
      },
      avatarStyle: {
            width: "56px",
            height: "56px",
            margin: "5px 0px 5px 16px !important"
      },
      historyTitle: {
            fontSize:"0.75rem",
            color: theme.typography.color.tertiary,
            fontFamily: FontFamilySwitch().semiBold,
      },
      detailsBlock: {
            margin: "5px 0px 5px 16px !important"
      },
      viewcontent: {
            padding: "12px"
      },

}));