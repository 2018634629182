import { Box, Button, Divider, Drawer, Grid, Typography } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { useNavigate, useLocation } from 'react-router-dom';
import { DialogBox, LoadingSection, SearchFilter, Subheader, TableWithPagination } from "../../components";
import { DocumentViewer } from "../../components/fileViewer";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, NetWorkCallMethods, checkListGroupDataType, checkListGroupHeading, checkListGroupPath, useWindowDimensions } from "../../utils";
import { CheckListCreateForm } from "./components/checkListCreateForm";
import { useStyles } from "./style";
import ShowMoreText from "react-show-more-text";

const CheckListGroupView = ({ t }) => {
    const classes = useStyles()
    const search = useLocation().search
    const id = new URLSearchParams(search).get("id");
    const groupType = new URLSearchParams(search).get("type");
    const alert = React.useContext(AlertContext);
    const navigate = useNavigate()
    const size = useWindowDimensions()
    const [openDrawer, setOpenDrawer] = React.useState(false)
    const [searchText, setSearchText] = React.useState("")
    const [loading, setLoading] = React.useState(true)
    const [formData, setFormData] = React.useState({
        check_list_name: "",
        status: true,
        item_type: "",
        is_mandatory: true,
        is_hardware: false,
        url: "",
        instructions: null,
        is_Edit: false,
        error: {
            check_list_name: "",
            is_mandatory: "",
            is_hardware: "",
            url: "",
            item_type: "",
        }
    })
    const [details, setDetails] = React.useState({})
    const [tableData, setTableData] = React.useState({
        list: [], count: 0
    })
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [isView, setIsView] = React.useState({ bool: false, data: {} })

    React.useEffect(() => {
        getCheckListGroupViewDetails()
        getCheckList()
        //eslint-disable-next-line
    }, [])
    //check list group view details
    const getCheckListGroupViewDetails = () => {

        const payload = {
            id: id
        }

        NetworkCall(
            `${config.api_url}/check_list_group/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setLoading(false)
            setDetails(response?.data)
            setFormData({ ...formData, item_type: { value: response?.data?.inspection_type, label: response?.data?.inspection_type }})
        }).catch((err) => {
            setLoading(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something Went Wrong",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        })
    }
    //get check list 
    const getCheckList = (offset = 0, limit = 10, search = "") => {

        const payload = {
            "group_id": id,
            "search": search,
            "start": offset,
            "length": limit
        }

        NetworkCall(
            `${config.api_url}/check_list`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            const result = response?.data?.data?.map((x) => {
                return {
                    ...x,
                    is_mandatory: x?.is_mandatory ? "Yes" : "No",
                    is_software_hardware: x?.is_software_hardware ? "Yes" : "No"
                }
            })
            setTableData({ list: result, count: response?.data?.count })
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something Went Wrong",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        })
    }
    const reload = () => {
        getCheckList(0, 10, "")
    }
    const handleSearch = (val) => {
        setSearchText(val)
        getCheckList(0, 10, val)
    }
    //handle Pagination
    const handlePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getCheckList(offset, limit, "")
    }

    const handleChangeLimit = (value) => {
        setLimit(value)
        setPage(1)
        getCheckList(0, value, "")
    }
    const handleIcon = (type, value) => {
        if (type === "view") {
            navigate(Routes?.checkListItemView + "?id=" + value.id + "&type=" + groupType)
        }
        else if (type === "edit") {
            setOpenDrawer(true)
            setFormData({ ...formData, is_Edit: true, id: value?.id })
        }
        else {
            onIsActive(value)
        }
    }
    //on isActive
    const onIsActive = (value) => {

        const payload = {
            "upsert_fields": {
                id: value?.id,
                group_id: id,
                is_active: !value?.is_active,
            }
        }
        NetworkCall(
            `${config.api_url}/check_list/upsert`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then(rs => {
            reload();
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Updated Successfully"),
            });
        }).catch(er => {
            console.log(er)
        })


    }
    const closeDrawer = () => {
        setOpenDrawer(false)
        setFormData({
            check_list_name: "",
            status: true,
            item_type: { value: details?.inspection_type, label: details?.inspection_type },
            is_mandatory: true,
            is_hardware: false,
            url: "",
            instructions: null,
            is_Edit: false,
            error: {
                item_type: "",
                check_list_name: "",
                is_mandatory: "",
                is_hardware: "",
                url: "",
            }
        })
    }
    return (
        <Box>
            <Subheader
                title={details?.name}
                goBack={() => navigate(-1)}
            />
            {
                loading ?
                    <LoadingSection top="20vh" message={t("loading")} /> :
                    <>
                        <Box className={classes.root}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={6} lg={3} className={classes.applyBorder} style={{ height: size?.height - 150 }}>
                                    <Box p={1}>
                                        <Typography className={classes.detailhdr}>{t("Details")}</Typography>
                                        <Box height={size?.height - 200} overflow="scroll">
                                            <Box mt={1}>
                                                <Typography className={classes.subhdrs}>{t("Check List Group Name")}</Typography>
                                                <Typography className={classes.subhdrvalues}>{details?.name ?? "-"}</Typography>
                                            </Box>
                                            {details?.inspection_type !== null &&
                                                <Box mt={1}>
                                                    <Typography className={classes.subhdrs}>{t("Item Type")}</Typography>
                                                    <Typography className={classes.subhdrvalues}>{details?.inspection_type ?? "-"}</Typography>
                                                </Box>
                                            }
                                            {details?.item_category !== null &&
                                                <Box mt={1}>
                                                    <Typography className={classes.subhdrs}>{t("Item Category")}</Typography>
                                                    <Typography className={classes.subhdrvalues}>{details?.item_category ?? "-"}</Typography>
                                                </Box>
                                            }
                                            {details?.inspection_item !== null &&
                                                <Box mt={1}>
                                                    <Typography className={classes.subhdrs}>{t("Item Name")}</Typography>
                                                    <Typography className={classes.subhdrvalues}>{details?.inspection_item ?? "-"}</Typography>
                                                </Box>
                                            }
                                            <Box mt={1}>
                                                <Typography className={classes.subhdrs}>{t("Status")}</Typography>
                                                <Typography className={classes.subhdrvalues} style={{ color: details?.is_active ? "#5AC782" : "#FF4B4B" }}>{details?.is_active ? "Active" : "In-active"}</Typography>
                                            </Box>
                                            {details?.instructions !== null &&
                                                <Box mt={1}>
                                                    <Typography className={classes.subhdrs}>{t("Instructions")}</Typography>
                                                    <ShowMoreText
                                                        lines={5}
                                                        more="Show More"
                                                        less="Show Less"
                                                        className={classes.subhdrvalues}
                                                        anchorClass={classes.seeMoreLessTextStyle}
                                                        expanded={false}
                                                        truncatedEndingComponent={"... "}
                                                    >
                                                        <Typography className={classes.subhdrvalues}>{details?.instructions?.split("\n")?.map((item, i) => <div key={i}>{item}</div>)}</Typography>
                                                    </ShowMoreText>
                                                </Box>
                                            }
                                            {Array?.isArray(details?.assets) && details?.assets?.length > 0 &&
                                                <>
                                                    <Box mb={1.5} mt={1.5}>
                                                        <Divider />
                                                    </Box>
                                                    <Typography className={classes.detailhdr}>{t("Documents")}</Typography>
                                                    <Box mt={2}>
                                                        <Grid container spacing={1}>
                                                            {
                                                                details?.assets?.map((x) => {
                                                                    return x?.file_meta?.type === "pdf" ?
                                                                        <Grid item xs={12} sm={6} md={4} lg={4} onClick={() => setIsView({ bool: true, data: x })}>
                                                                            <Box className={classes.documentImg}>
                                                                                <DocumentViewer url={x?.url} />
                                                                            </Box>
                                                                        </Grid>
                                                                        :
                                                                        <Grid item xs={12} sm={6} md={4} lg={4} onClick={() => setIsView({ bool: true, data: x })}>
                                                                            <img src={x?.url} alt="documents" className={classes.documentImg} />
                                                                        </Grid>
                                                                })
                                                            }
                                                        </Grid>
                                                    </Box>
                                                </>
                                            }
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={9} lg={9}>
                                    <Box p={1}>
                                        <Box display="flex" justifyContent={"space-between"} mt={1}>
                                            <Box width="350px">
                                                <SearchFilter value={searchText} placeholder={t("Search")}
                                                    handleChange={(value) => handleSearch(value)} />

                                            </Box>
                                            <Button variant="contained" className={classes.approvebtn} onClick={() => setOpenDrawer(true)}>{t("Create Check List")}</Button>
                                        </Box>
                                        <Box mt={2}>
                                            <TableWithPagination
                                                heading={checkListGroupHeading(t)}
                                                rows={tableData?.list}
                                                path={checkListGroupPath}
                                                handlePagination={handlePagination}
                                                handleChangeLimit={handleChangeLimit}
                                                handleIcon={handleIcon}
                                                totalRowsCount={tableData?.count}
                                                dataType={checkListGroupDataType}
                                                page={page}
                                                limit={limit}
                                                height={'calc(100vh - 250px)'}
                                                view={true}
                                                edit={true}
                                                delete={true} />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>

                        </Box>
                    </>
            }
            <Drawer
                anchor={"right"}
                open={openDrawer}
                onClose={() => closeDrawer()}
            >
                <CheckListCreateForm t={t} group_id={id} onClose={closeDrawer} data={formData} setData={setFormData} reload={reload} groupType={groupType} />
            </Drawer>
            <DialogBox
                maxWidth={"sm"}
                open={isView?.bool}
                onClose={() => setIsView({ ...isView, bool: false })}
                header={t("View")}
                component={
                    <Box p={2}>
                        {isView?.data?.file_meta?.type === "pdf" ?
                            <DocumentViewer url={isView?.data?.url} />
                            :
                            <img
                                src={isView?.data?.url}
                                alt="asset"
                                height="500px"
                                width="100%"
                                style={{
                                    objectFit: "contain",
                                    borderRadius: "8px"
                                }}
                            />
                        }
                    </Box>
                }
            />
        </Box>
    )
}
export default withTranslation("checkListGroup")(CheckListGroupView)