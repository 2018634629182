import React from "react";
import { LoginSuccess } from "../../router/access";
import { LocalStorageKeys } from "../../utils";
import Login from "./login";
import { useNavigate } from "react-router-dom";

class LoginParentOne extends React.Component {
  componentDidMount() {
    if (localStorage.getItem(LocalStorageKeys.authToken)) {
      if (localStorage.getItem(LocalStorageKeys.permission)) {
        this?.props?.history?.push(
          LoginSuccess(
            JSON.parse(localStorage.getItem(LocalStorageKeys.permission))
          )
        );
      }
    }
  }

  render() {
    return <Login />;
  }
}

export default function LoginParent(props) {
  const navigate = useNavigate();
  return <LoginParentOne {...props} navigate={navigate} />;
}
