import { Box, Grid } from "@mui/material";
import React from "react";
import { LoadingSection, SearchFilter, Subheader, TableWithPagination, UseDebounce } from "../../../components";
import { AuthContext } from "../../../contexts";
import { NetWorkCallMethods, accessCheckRender, getRoutePermissionNew } from "../../../utils";
import { logodataType, logoheading, logopath } from "../../../utils/clientsettings";
import Banners from "../components/banners";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
import { withTranslation } from "react-i18next";

export const DashboardBanner = ({ selected = {}, type = "", loading,
    handleLoading = () => false, t = () => false }) => {
    const [open, setOpen] = React.useState(false);
    const [selectedBanner, setSelectedBanner] = React.useState({})
    const [limits, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [bannerListList, setBannerList] = React.useState({
        list: [],
        count: 0
    })
    const [searchText, setSearchText] = React.useState("");
    const auth = React.useContext(AuthContext);
    const [permission, setPermission] = React.useState({})

    //onCLick row
    const onClickRow = (data) => {
        setOpen(true)
        setSelectedBanner({
            id: data?.id,
            name: data?.name,
            bid: data?.company_banner?.[0]?.id
        })
    }
    //get banner list
    const getBannerList = (limit, offset, search) => {
        handleLoading(true)
        const payload = {
            limit: limit,
            offset: offset,
            search: search ?? "",
            "type": "Dashboard"
        }
        NetworkCall(
            `${config.api_url}/queries/company_master/list`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setBannerList({
                list: response.data.data.company_master?.map((val) => {
                    return ({ ...val, count: val?.company_banner?.[0]?.count?.[0]?.count ?? 0 })
                }),
                count: response.data.count
            })
            handleLoading(false)

        }).catch((err) => {
            console.log(err)
            handleLoading(false)

        })
    }
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limits;
        getBannerList(limits, offset, "")
    }
    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getBannerList(value, 0, "")
    }
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getBannerList(limits, 0, "")
            }
        }
        // eslint-disable-next-line
    }, [auth])
    //debounce
    const debounce = UseDebounce();
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getBannerList(limits, 0, e)
    }
    //search in table
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    const render = () => {
        return (
            <Box>
                {/*banner section*/}
                {
                    open ? <Banners
                        type={type}
                        title={`${t("Create New")} ${t(type)} ${t("Banner")}`}
                        goBack={() => setOpen(false)}
                        company={selectedBanner}
                        reload={() => getBannerList(10, 0, "")}

                    />
                        :
                        <Box>
                            {/*Save section*/}
                            <Subheader hideBackButton title={t("Dashboard Banners")} />

                            <Box p={2}>
                                <Box backgroundColor={'#fff'} p={2}>
                                    <Grid container>
                                        <Grid item xs={4} pt={'0px !importantx'}>
                                            <Box>
                                                {/*setting list */}
                                                <SearchFilter value={searchText} placeholder={t("Search Company")} handleChange={(value) => handleSearch(value)} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box pt={2}>
                                                {
                                                    loading ? <LoadingSection bottom={"45vh"} message="Fetching ..." /> :
                                                        <TableWithPagination
                                                            heading={logoheading(t)}
                                                            rows={bannerListList?.list}
                                                            path={logopath}
                                                            showpagination={true}
                                                            onClick={onClickRow}
                                                            dataType={logodataType}
                                                            handlePagination={handlePagination}
                                                            handleChangeLimit={handleChangeLimit}
                                                            totalRowsCount={bannerListList?.count}
                                                            page={page}
                                                            limit={limits}
                                                            height={'calc(100vh - 301px)'}
                                                            view={true}
                                                            edit={true}
                                                            delete={true}
                                                            marginTop={'0px !important'}
                                                        />
                                                }

                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Box>
                }



            </Box>
        )
    }

    return (
        <div>
            {accessCheckRender(render, permission, "", loading)}
        </div>
    );
}
export default withTranslation("banners")(DashboardBanner)