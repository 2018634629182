import DeleteIcon from "@mui/icons-material/Delete";
import DriveFolderUploadOutlined from "@mui/icons-material/DriveFolderUploadOutlined";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { DocumentViewer } from "../../components/fileViewer/index";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { AlertProps, allowed_file_size, singleFileUpload , FontFamilySwitch} from "../../utils";
const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        height: `calc(100vh - 88px)`,
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "0px",
        overflow: "auto"
    },
    fileroot: {
        backgroundColor: "#DEEAFC",
        height: "150px",
        fontSize:"0.75rem",
        color: "#5078E1",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: theme.palette.borderRadius,
        cursor: "pointer"
    },
    imgContainer: {
        borderRadius: theme.palette.borderRadius,
        padding: "16px",
    },
    delete: {
        color: "red",
        position: "absolute",
        top: "4px",
        right: "8px",
        backgroundColor: "white",
    },
}));
export const UploadApi = (props) => {
    const [selectedImage, updateImage] = React.useState([]);
    const [selectedImageURL, updateImageURL] = React.useState([]);

    const [uuid, setUUID] = React.useState(1);
    const [id] = React.useState(1)
    const alert = React.useContext(AlertContext);


    const updateState = async (data) => {
        setUUID(uuid + 1);
        updateImage([...selectedImage, { id: uuid, img: data }]);

        selectedImage.push(data);

        let uploaded_file = await singleFileUpload(data, { tenantId: `${config.tenantId}` }, alert, allowed_file_size)

        if (uploaded_file?.[0]?.url) {
            updateImageURL([
                ...selectedImageURL,
                {
                    url: uploaded_file?.[0]?.url,
                    priority: uuid,
                    fileName: uploaded_file?.[0]?.file_name,
                    assets_master_type: id,
                },
            ]);
        } else {
              alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some Thing Went Wrong",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
              });
        }
    };
    const deleteImg = (data) => {
        updateImageURL(selectedImageURL.filter((val) => val.url !== data.url));
    };

    const canICopied = (val) => {
        navigator.clipboard.writeText(val)
        alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Url Copied Successfully",
        });

    }

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className={classes.fileroot}>
                        <label className="btn label">
                            <input
                                type="file"
                                name="myImage"
                                style={{ display: "none" }}
                                onChange={(e) => {
                                    updateState(e.target.files[0]);
                                }}
                            />
                            <center>
                                <DriveFolderUploadOutlined
                                    style={{ fontSize: "58px", color: "#5078E1", cursor: "pointer" }}
                                />
                                <Typography style={{ cursor: "pointer" }}>Upload File</Typography>
                            </center>
                        </label>
                    </div>
                    <br />
                </Grid>

                {id === 1 && (
                    <>
                        {selectedImageURL.map((val, index) => {
                            return (
                                <>
                                    {val.assets_master_type === 1 && (
                                        <Grid item xs={3} className={classes.imgContainer}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <div style={{ position: "relative" }}>
                                                        <img
                                                            type="file"
                                                            accept="image/*"
                                                            multiple
                                                            alt="Not Found"
                                                            width={"250px"}
                                                            src={val.url}
                                                            style={{
                                                                objectFit: "cover",
                                                                height: "150px",
                                                                width: "100%",
                                                                borderRadius: "12px",
                                                                border: "1px solid grey"
                                                            }}
                                                        />
                                                        <br />
                                                        <IconButton
                                                            className={classes.delete}
                                                            onClick={() => deleteImg(val)}
                                                            size="small"
                                                        >
                                                            <DeleteIcon style={{ fontSize:"0.875rem" }} />
                                                        </IconButton>

                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography fontSize={"10px"} fontFamily={FontFamilySwitch().bold}>File Name:
                                                        &nbsp; <span style={{ fontSize:"0.75rem", fontFamily: FontFamilySwitch().semiBold }}>{val.fileName} </span>
                                                    </Typography>
                                                    <Button size="small" variant="contained" onClick={() => canICopied(val.url)} >Copy url</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </>
                            );
                        })}
                    </>
                )}
                {id === 2 && (
                    <>
                        {selectedImageURL.map((val, index) => {
                            return (
                                <>
                                    {val.assets_master_type === 2 && (
                                        <Grid item xs={3} className={classes.imgContainer}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <div style={{ position: "relative" }}>
                                                        <img
                                                            type="file"
                                                            accept="image/*"
                                                            multiple
                                                            alt="Not Found"
                                                            width={"250px"}
                                                            src={val.url}
                                                            style={{
                                                                objectFit: "cover",
                                                                height: "150px",
                                                                width: "100%",
                                                                borderRadius: "12px",
                                                                border: "1px solid grey"
                                                            }}
                                                        />
                                                        <br />
                                                        <IconButton
                                                            className={classes.delete}
                                                            onClick={() => deleteImg(val)}
                                                            size="small"
                                                        >
                                                            <DeleteIcon style={{ fontSize:"0.875rem" }} />
                                                        </IconButton>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography fontSize={"10px"} fontFamily={FontFamilySwitch().bold}>File Name:
                                                        &nbsp; <span style={{ fontSize:"0.75rem",fontFamily: FontFamilySwitch().semiBold }}>{val.fileName} </span>
                                                    </Typography>
                                                    <Button size="small" variant="contained" onClick={() => canICopied(val.url)} >Copy url</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </>
                            );
                        })}
                    </>
                )}

                {id === 3 && (
                    <>
                        {selectedImageURL.map((val, index) => {
                            return (
                                <>
                                    {val.assets_master_type === 3 && (
                                        <Grid item xs={3} className={classes.imgContainer}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <div style={{ position: "relative" }}>
                                                        <video
                                                            style={{
                                                                objectFit: "cover",
                                                                height: "150px",
                                                                width: "100%",
                                                                borderRadius: "12px",
                                                                border: "1px solid grey"
                                                            }}
                                                            controls
                                                        >
                                                            <source src={val.url} type="video/mp4" />
                                                        </video>
                                                        <br />
                                                        <IconButton
                                                            className={classes.delete}
                                                            onClick={() => deleteImg(val)}
                                                            size="small"
                                                        >
                                                            <DeleteIcon style={{ fontSize:"0.875rem" }} />
                                                        </IconButton>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography fontSize={"10px"} fontFamily={FontFamilySwitch().bold}>File Name:
                                                        &nbsp; <span style={{ fontSize:"0.75rem", fontFamily: FontFamilySwitch().semiBold }}>{val.fileName} </span>
                                                    </Typography>
                                                    <Button size="small" variant="contained" onClick={() => canICopied(val.url)} >Copy url</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </>
                            );
                        })}
                    </>
                )}

                {id === 4 && (
                    <>
                        {selectedImageURL.map((val, index) => {
                            return (
                                <>
                                    {val.assets_master_type === 4 && (
                                        <Grid item xs={3} className={classes.imgContainer}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <div
                                                        style={{
                                                            position: "relative",
                                                            overflow: "hidden",
                                                            height: "150px",
                                                        }}
                                                    >
                                                        <DocumentViewer url={val.url} />
                                                        <br />
                                                        <IconButton
                                                            className={classes.delete}
                                                            onClick={() => deleteImg(val)}
                                                            size="small"
                                                        >
                                                            <DeleteIcon style={{ fontSize:"0.875rem" }} />
                                                        </IconButton>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography fontSize={"10px"} fontFamily={FontFamilySwitch().bold}>File Name:
                                                        &nbsp; <span style={{ fontSize:"0.75rem", fontFamily: FontFamilySwitch().semiBold }}>{val.fileName} </span>
                                                    </Typography>
                                                    <Button size="small" variant="contained" onClick={() => canICopied(val.url)} >Copy url</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </>
                            );
                        })}
                    </>
                )}
                {id === 5 && (
                    <>
                        {selectedImageURL.map((val, index) => {
                            return (
                                <>
                                    {val.assets_master_type === 5 && (
                                        <Grid item xs={3} className={classes.imgContainer}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <div style={{ position: "relative" }}>
                                                        <img
                                                            type="file"
                                                            accept="image/*"
                                                            multiple
                                                            alt="Not Found"
                                                            width={"250px"}
                                                            src={val.url}
                                                            style={{
                                                                objectFit: "cover",
                                                                height: "150px",
                                                                width: "100%",
                                                                borderRadius: "12px",
                                                                border: "1px solid grey"
                                                            }}
                                                        />

                                                        <br />
                                                        <IconButton
                                                            className={classes.delete}
                                                            onClick={() => deleteImg(val)}
                                                            size="small"
                                                        >
                                                            <DeleteIcon style={{ fontSize:"0.875rem" }} />
                                                        </IconButton>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography fontSize={"12px"}>File Name:
                                                        &nbsp; <span style={{ fontSize:"0.75rem" }}>{val.fileName} </span>
                                                    </Typography>
                                                    <Button size="small" variant="contained" onClick={() => canICopied(val.url)} >Copy url</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </>
                            );
                        })}
                    </>
                )}
            </Grid>
        </div>
    );
};
