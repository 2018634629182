import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { FontFamilySwitch } from '../../../utils';

export const CustomTypography = styled(Typography, {
    shouldForwardProp: prop => prop !== "color" && prop !== "direction" && prop !== "fontSize" && prop !== "fontFamily" && prop !== "marginBottom" && prop !== "marginTop" && prop !== "marginLeft"
})(({ color = "#091B29", fontFamily = FontFamilySwitch().regular,  fontSize = 14, marginBottom, marginTop, marginLeft, direction, textAlign}) => ({
    color,
    fontSize,
    fontFamily,
    marginBottom,
    marginTop,
    marginLeft,
    direction,
    textAlign
}))