import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material"
import React from "react"
import { withTranslation } from "react-i18next"
import { CloseIconWithBG } from "../../assets"
import { ResourceGroupAsset } from "../../assets/resourceGroupAsset"
import { FormGenerator } from "../../components"
import { config } from "../../config"
import { AlertContext } from "../../contexts"
import { NetworkCall } from "../../networkcall"
import { AlertProps, NetWorkCallMethods, useWindowDimensions } from "../../utils"
import { loadOptionsApis } from "../createMaintenancePlanner/utils"
import { ChooseCard } from "./components/chooseCard"
import { CustomSelectBox } from "./components/selectBox"
import { useStyles } from "./style"

export const StatusOptionList = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
]

const CreateForm = ({ t = () => false, onClose = () => false, selectedCompany = "", state = {}, reload = () => false, is_edit = false }) => {
    const classes = useStyles()
    const size = useWindowDimensions()
    const [tabValue, setTabValue] = React.useState(1)
    const alert = React.useContext(AlertContext)
    const [data, setData] = React.useState({
        resource_group_name: "",
        status: [true],
        selected_resources: [],
        jobs: [],
        profession: [],
        error: {
            resource_group_name: "",
            profession: [],
            jobs: [],
            selected_resources: []
        }
    })
    const [selectedItems, setSelectedItems] = React.useState([])
    const [selectedRoles, setSelectedRoles] = React.useState([])
    React.useEffect(() => {
        if (state?.id) {
            const Profession = state?.profession?.map((x) => {
                return {
                    ...x,
                    label: x?.name,
                    value: x?.profession_id,
                    edit_id: x?.id,
                    checked: true,
                    is_active: true
                }
            })
            const Jobs = state?.job?.map((x) => {
                return {
                    ...x,
                    label: x?.name,
                    value: x?.job_id,
                    edit_id: x?.id,
                    checked: true,
                    is_active: true
                }
            })
            const result = {
                id: state?.id,
                resource_group_name: state?.name,
                status: state?.is_active,
                profession: Profession ?? [],
                jobs: Jobs ?? [],
                selected_resources: state?.resource?.map((x) => {
                    return {
                        ...x,
                        label: x?.resource_name,
                        value: x?.resource?.id,
                        assets: x?.resource?.assets,
                        edit_id: x?.id,
                        is_active: true
                    }
                }),
                error: {
                    resource_group_name: "",
                    profession: [],
                    jobs: [],
                    selected_resources: []
                }
            }
            setData(result)
            setSelectedItems(Profession)
            setSelectedRoles(Jobs)
        }
        //eslint-disable-next-line
    }, [])
    const manualResponse = (array) => {
        const details = {
            data: array?.data?.map((i) => {
                return {
                    ...i,
                    label: i?.resource_name,
                    value: i?.id,
                    is_active: true
                }
            }),
            count: array?.count
        }
        return details
    };
    const updateState = (k, v) => {
        let error = data.error
        error[k] = ""
        setData({ ...data, [k]: v, error })
    }
    const changeInput = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "text",
            label: t("Resource Group Name"),
            placeholder: t("Enter Group Name"),
            value: data?.resource_group_name,
            height: "48px",
            isRequired: true,
            onChange: (value) => updateState("resource_group_name", value.target.value),
            error: data?.error?.resource_group_name,
            // isError: data?.error?.resource_group_name?.length > 0
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "toggle",
            label: t("Status"),
            placeholder: t("Status"),
            value: data?.status,
            onChange: (value) => updateState("status", value),
            error: data?.error?.status,
            isRequired: true,
            options: [
                { label: t('Active'), value: true },
                { label: t('Inactive'), value: false },
            ],
            isReadonly: data?.isView
        },
    ]
    const validate = () => {
        let isValid = true;
        let error = data.error
        //validate name
        if (data?.selected_resources?.length === 0) {
            isValid = false;
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Please Select Resource"),
            });

        }
        if (data?.resource_group_name?.length === 0) {
            isValid = false;
            error.resource_group_name = t("Resource Group Name is required");
        }
        setData({ ...data, error });
        return isValid
    }
    const handleSelect = (details, value, key) => {
        const result = selectedItems?.map((x) => {
            return {
                ...x,
                edit_id: state?.profession?.map((x) => { return x?.value === details?.value })?.[0]?.id ?? undefined,
                checked: details?.value !== x?.value ? x?.checked : false,
                is_active: details?.value !== x?.value ? x?.is_active : false,
            }
        })
        const result1 = selectedRoles?.map((x) => {
            return {
                ...x,
                checked: details?.value !== x?.value ? x?.checked : false,
                edit_id: state?.profession?.map((x) => { return x?.value === details?.value })?.[0]?.id ?? undefined,
                is_active: details?.value !== x?.value ? x?.is_active : false,
            }
        })
        if (value) {
            if (key === "jobs") {
                setSelectedRoles([...selectedRoles, { ...details, checked: value }])
            }
            else {
                setSelectedItems([...selectedItems, { ...details, checked: value }])
            }
        }
        else {
            if (key === "jobs") {
                setSelectedRoles(result1)
            }
            else {
                setSelectedItems(result)
            }
            // updateState(key,result)
        }
    }
    const onClear = (type, val, key) => {
        if (type === "clearAll") {
            setSelectedItems([])
            setSelectedRoles([])
            updateState(key, [])
        }
        else {
            if (key === "jobs") {
                let updateRoles = selectedRoles?.map((value) => {
                    return {
                        ...value,
                        checked: value?.value === val?.value ? false : value?.checked,
                        is_active: value?.value === val?.value ? false : value?.is_active
                    }
                }) ?? []
                setSelectedRoles(updateRoles)
                updateState("jobs", updateRoles?.filter((x) => { return x?.is_active }))
            }
            else {
                let updateValue = selectedItems?.map((value) => {
                    return {
                        ...value,
                        checked: value?.value === val?.value ? false : value?.checked,
                        is_active: value?.value === val?.value ? false : value?.is_active
                    }
                }) ?? []
                setSelectedItems(updateValue)
                updateState(key, updateValue?.filter((x) => { return x?.is_active }))
            }

        }
    }
    const handleResource = (value) => {
        setData({ ...data, selected_resources: [...data?.selected_resources, { ...value, edit_id: data?.selected_resources?.filter((x) => { return x?.value === value?.value })?.[0]?.edit_id ?? undefined }] })
    }
    const onDeleteMuti = (val) => {
        // const result = data?.selected_resources?.filter((x, i) => { return i !== val })
        const result = data?.selected_resources?.map((x, i) => {
            return {
                ...x,
                is_active: x?.value !== val?.value ? x?.is_active : false
            }
        })
        updateState("selected_resources", result)
    }
    const onSubmit = () => {
        if (validate()) {
            const payload = {
                "id": data?.id ?? undefined,
                "company_id": selectedCompany?.value,
                "name": data?.resource_group_name,
                "is_active": true,
                "profession": data?.profession?.map((x) => { return { profession_id: x?.value, id: x?.edit_id ?? undefined, is_active: x?.is_active } }),
                "job": data?.jobs?.map((x) => { return { job_id: x?.value, id: x?.edit_id ?? undefined, is_active: x?.is_active } }),
                "resources": data?.selected_resources?.map((x) => { return { resource_id: x?.value, id: x?.edit_id ?? undefined, is_active: x?.is_active } })
            }
            NetworkCall(
                `${config.api_url}/resource_group_master/upsert`,
                NetWorkCallMethods.post, payload, null, true, false
            ).then((response) => {
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.success,
                    msg: t(response?.data?.data),
                })
                onClose()
                reload()
            }).catch((e) => {
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.error, msg: t("Some thing went Wrong")
                })
            });
        }
    }
    const handleTabChange = (val) => {
        setTabValue(val)
    }
    return (
        <Box sx={{ width: "800px" }}>
            <Box p={2}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Box sx={{ cursor: "pointer" }} onClick={() => onClose()}><CloseIconWithBG backgroundColor={"#f2f4f7"} /></Box>
                    <Typography className={classes.createTitle}>{is_edit ? t("Edit Resource Group master") : t("Create Resource Group Master")}</Typography>
                </Stack>
            </Box>
            <Divider />
            <Box>
                <Grid container>
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{ borderRight: "1px solid #E4E8EE" }}>
                        <Box height={size?.height - 140}>
                            <Box p={2}>
                                <Box className={classes.resourceCountBox}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <ResourceGroupAsset />
                                        <Typography className={classes.resourceCount}>{data?.selected_resources?.length > 0 ? data?.selected_resources?.filter((x) => { return x?.is_active })?.length : 0}</Typography>
                                        <Typography className={classes.resourcetext}>{t("Resource Selected")}</Typography>
                                    </Stack>
                                </Box>
                                <Box mt={1.5}>
                                    <FormGenerator components={changeInput} />
                                </Box>
                            </Box>
                            <Divider />
                            <Box p={2}>
                                <CustomSelectBox
                                    networkCallData={
                                        {
                                            path: "queries/profession_master",
                                            payload: {
                                                "company_id": selectedCompany?.value,
                                            },
                                            mappingVariable: "data",
                                            // manualResponse: manualResponseProfession
                                        }
                                    }
                                    t={t}
                                    label={t("Choose Profession")}
                                    isPaginate={true}
                                    noBorder={false}
                                    placeholder={t("Search Profession")}
                                    value={data?.profession?.filter((x) => { return x?.is_active })}
                                    noSearch={true}
                                    onChange={() => false}
                                    isMulti={true}
                                    key_name="profession"
                                    menuOptionsHeight={"150px"}
                                    onClear={onClear}
                                    onApply={() => updateState("profession", selectedItems?.filter((x) => { return x?.checked === true }))}
                                    customOptionComponent={(props) => {
                                        return (
                                            <Stack direction={"row"} spacing={0.2} alignItems={"center"}>
                                                {selectedItems?.filter((x) => { return x?.value === props?.data?.value && x?.is_active })?.[0]?.checked ?
                                                    <Box sx={{ padding: "5px 5px 0px 5px" }} onClick={(e) =>
                                                        handleSelect(props?.data, false, props?.key)
                                                    }>
                                                        <CheckBoxIcon color="primary" />
                                                    </Box>
                                                    :
                                                    <Box sx={{ padding: "5px 5px 0px 5px" }} onClick={(e) =>
                                                        handleSelect(props?.data, true, props?.key)
                                                    }>
                                                        <CheckBoxOutlineBlankIcon sx={{ color: "#0009" }} />
                                                    </Box>
                                                }
                                                {/* <Checkbox
                                                    checked={selectedItems?.filter((x) => { return x?.value === props?.data?.value && x?.is_active })?.[0]?.checked}
                                                    sx={{ padding: "5px !important",backgroundColor:selectedItems?.filter((x) => { return x?.value === props?.data?.value && x?.is_active })?.[0]?.checked?"green":"white" }}
                                                    onClick={(e) =>
                                                        handleSelect(props?.data, e?.target?.checked, props?.key)
                                                    }
                                                /> */}
                                                <Typography className={classes.optionHeader}>{t(props?.data?.label)}</Typography>
                                            </Stack>
                                        )
                                    }}
                                />
                            </Box>
                            <Divider />
                            <Box p={2}>
                                <CustomSelectBox
                                    networkCallData={
                                        {
                                            path: "queries/job_master/list",
                                            payload: {
                                                "company_id": selectedCompany?.value,
                                            },
                                            mappingVariable: "job_master",
                                        }
                                    }
                                    label={t("Choose Role")}
                                    isPaginate={true}
                                    noBorder={false}
                                    placeholder={t("Search Role")}
                                    value={data?.jobs?.filter((x) => { return x?.is_active })}
                                    noSearch={true}
                                    onChange={() => false}
                                    isMulti={true}
                                    t={t}
                                    key_name="jobs"
                                    menuOptionsHeight={"150px"}
                                    onClear={onClear}
                                    onApply={() => updateState("jobs", selectedRoles?.filter((x) => { return x?.checked === true }))}
                                    customOptionComponent={(props) => {
                                        return (
                                            <Stack direction={"row"} spacing={0.2} alignItems={"center"}>
                                                {/* <Checkbox
                                                    checked={selectedRoles?.filter((x) => { return x?.value === props?.data?.value && x?.is_active })?.[0]?.checked}
                                                    sx={{ padding: "5px !important" }}
                                                    onClick={(e) =>
                                                        handleSelect(props?.data, e?.target?.checked, props?.key)
                                                    }
                                                /> */}
                                                {selectedRoles?.filter((x) => { return x?.value === props?.data?.value && x?.is_active })?.[0]?.checked ?
                                                    <Box sx={{ padding: "5px 5px 0px 5px" }} onClick={(e) =>
                                                        handleSelect(props?.data, false, props?.key)
                                                    }>
                                                        <CheckBoxIcon color="primary" />
                                                    </Box>
                                                    :
                                                    <Box sx={{ padding: "5px 5px 0px 5px" }} onClick={(e) =>
                                                        handleSelect(props?.data, true, props?.key)
                                                    }>
                                                        <CheckBoxOutlineBlankIcon sx={{ color: "#0009" }} />
                                                    </Box>
                                                }
                                                <Typography className={classes.optionHeader}>{t(props?.data?.label)}</Typography>
                                            </Stack>
                                        )
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box p={2}>
                            <Button variant="contained" fullWidth onClick={() => onSubmit()}>{is_edit ? t("Update") : t("Create")}</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Box p={2}>
                            <Typography className={classes.selectResourcetxt}>{t("Select Resources")}</Typography>
                            <Box mt={1}>
                                <ChooseCard
                                    noSelectedTitle={t("No members selected to display")}
                                    icon={false}
                                    loadOptions={(search, array, handleLoading) =>
                                        loadOptionsApis(
                                            `resource_group_master/resource_list`,
                                            {
                                                "company_id": selectedCompany?.value,
                                                "job_ids": tabValue === 2 ? (data?.jobs?.length > 0 ? data?.jobs?.map((x) => { return x?.value }) : []) : [],
                                                "profession_ids": tabValue === 1 ? (data?.profession?.length > 0 ? data?.profession?.map((x) => { return x?.value }) : []) : [],
                                                "excluded_resource": data?.selected_resources?.filter((x) => { return x?.is_active })?.map((x) => { return x?.value }) ?? []
                                            },
                                            search,
                                            array,
                                            handleLoading,
                                            "data",
                                            {},
                                            manualResponse

                                        )}
                                    onchange={(value) => handleResource(value)}
                                    handleTabChange={handleTabChange}
                                    key={JSON.stringify({ tabValue: tabValue, selected_resources: data?.selected_resources, profession: data?.profession, jobs: data?.jobs })}
                                    // topHeader={t("Choose The Contract Account")}
                                    isActive={true}
                                    isMulti={true}
                                    isBorder={true}
                                    t={t}
                                    menuPlacement={"auto"}
                                    placeholder={t("Search Member")}
                                    onDeleteMuti={(val) => onDeleteMuti(val)}
                                    // onDelete={() => updateState("contract_details", [], "contract_account", null, "selected_properties", [])}
                                    value={data?.selected_resources}
                                    data={data}
                                    tabValue={tabValue}
                                    setTabValue={setTabValue}
                                // updateState={updateState}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
export default withTranslation("resourceMaster")(CreateForm)