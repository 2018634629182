import React from 'react'
import { Grid, Box, Stack, IconButton, Badge, Button, Drawer, Typography, Divider } from "@mui/material"
import FilterIMG from '../../assets/filter'
import { FilterGenerator, FormGenerator, SearchFilter, TableWithPagination, UseDebounce } from '../../components'
import { projectCategoryStyle } from './style'
import { ProjectCategoryHeading, ProjectCategoryPath, ProjectCategoryType, ProjectSubCategoryHeading, ProjectSubCategoryPath, ProjectSubCategoryType } from './utils'
import { CloseIconWithBG } from '../../assets'
import { config } from '../../config'
import { NetworkCall } from '../../networkcall'
import { AlertProps, NetWorkCallMethods } from '../../utils'
import { AlertContext } from '../../contexts'
import { loadOptionsApis } from '../../utils/asyncPaginateLoadOptions'
import { NewLoader } from '../../components/newLoader'

export const Table = ({
    t = () => false,
    type = "",
    selectedCompany = {}
}) => {
    const classes = projectCategoryStyle()
    const alert = React.useContext(AlertContext)
    const debounce = UseDebounce()
    const [filterDrawer, setFilterDrawer] = React.useState(false)
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [createOpen, setCreateOpen] = React.useState(false)
    const [data, setData] = React.useState({
        category_name: "",
        category: "",
        sub_category_name: "",
        status: true,
        isEdit: false,
        error: {
            category_name: "",
            category: "",
            sub_category_name: "",
            status: ""
        }
    })
    const [list, setList] = React.useState({
        data: "",
        count: 0
    })
    const [searchText, setSearchText] = React.useState("")
    const [filterData, setFilterData] = React.useState({ status: [true] })
    const [loading, setLoading] = React.useState(false)

    const status = [
        {
            label: t("Active"),
            value: true,
        },
        {
            label: t("Inactive"),
            value: false,
        },
    ];

    const categoryForm = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
            },
            isActive: type === "category" ? true : false,
            component: "text",
            label: t("Category Name"),
            value: data?.category_name,
            placeholder: t("Enter Category Name"),
            onChange: (value) =>
                updateState("category_name", value.target.value),
            error: data?.error?.category_name,
            isRequired: true,
            isError: data?.error?.category_name?.length > 0,
            errorMessage: data?.error?.category_name
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
            },
            isActive: type !== "category" ? true : false,
            component: "select",
            label: t("Category"),
            value: data?.category,
            placeholder: t("Select Category"),
            onChange: (value) => updateState("category", value),
            error: data?.error?.category,
            isRequired: true,
            isPaginate: true,
            options: [],
            loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                    "project_category/list",
                    {
                        company_id: selectedCompany?.value,
                        status: [true]
                    },
                    search,
                    array,
                    handleLoading,
                    "data",
                    {},
                    manualResponse
                ),
            isError: data?.error?.category?.length > 0,
            errorMessage: data?.error?.category
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
            },
            isActive: type !== "category" ? true : false,
            component: "text",
            label: t("Sub Category Name"),
            value: data?.sub_category_name,
            placeholder: t("Enter Sub Category Name"),
            onChange: (value) =>
                updateState("sub_category_name", value.target.value),
            error: data?.error?.sub_category_name,
            isRequired: true,
            isError: data?.error?.sub_category_name?.length > 0,
            errorMessage: data?.error?.sub_category_name
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12,
            },
            isActive: true,
            component: "toggle",
            label: t("Status"),
            value: data?.status,
            placeholder: t("Select Status"),
            onChange: (value) => updateState("status", value),
            error: data?.error?.status,
            isRequired: true,
            options: status,
            isError: data?.error?.status?.length > 0,
            errorMessage: data?.error?.status
        },
    ]


    const updateState = (key, value) => {
        setData({
            ...data,
            [key]: value,
            error: {
                ...data?.error,
                [key]: ""
            }
        })
    }

    const onClose = () => {
        setData({
            category_name: "",
            category: "",
            sub_category_name: "",
            status: true,
            isEdit: false,
            error: {
                category_name: "",
                category: "",
                sub_category_name: "",
                status: ""
            }
        })
        setCreateOpen(false)
    }

    React.useEffect(() => {
        getTableList(0, 10, "")
        setSearchText("")
        // eslint-disable-next-line
    }, [selectedCompany])

    const manualResponse = (array) => {
        const result = array?.data?.map((e) => {
            return {
                label: e?.name,
                value: e?.id
            }
        })

        return result
    }


    // Function to handle pagination in table
    const handleTablePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getTableList(offset, limit, searchText)
    }

    // Function to handle page limit in table
    const handleTablePageLimit = (value) => {
        setLimit(value)
        setPage(1)
        getTableList(0, value, searchText)
    }

    const handleTableIcon = (type, data) => {
        if (type === "edit") {
            setData({
                category_name: data?.category_name,
                category: data?.category,
                sub_category_name: data?.sub_category_name,
                status: data?.is_active,
                id: data?.id,
                isEdit: true,
                error: {
                    category_name: "",
                    category: "",
                    sub_category_name: "",
                }
            })
            setCreateOpen(true)
        } else if (type === "active") {
            createCategory(true, data, true)
        }
    }

    const searchTable = (search) => {
        setSearchText(search)
        debounce(() => getTableList(0, 10, search), 800)
    }

    const getTableList = (offset = 0, limit = 10, search = "", status = filterData?.status) => {
        setLoading(true)
        const payload = {
            offset: offset,
            limit: limit,
            search: search,
            company_id: selectedCompany?.value,
            status: status
        };
        NetworkCall(
            `${config.api_url}${type === "category" ? "/project_category/list" : "/project_subcategory/list"}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                setLoading(false)
                const constructedCategory = res?.data?.data?.data?.map((e) => {
                    return {
                        category_name: e?.name,
                        ref_id: e?.reference_no,
                        status: e?.is_active ? "Active" : "InActive",
                        ...e
                    }
                })

                const constructedSubCategory = res?.data?.data?.data?.map((e) => {
                    return {
                        ref_id: e?.reference_no,
                        sub_category_name: e?.name,
                        category: {
                            label: e?.category_name,
                            value: e?.category_id
                        },
                        status: e?.is_active ? "Active" : "InActive",
                        ...e
                    }
                })
                setList({
                    data: type === "category" ? constructedCategory : constructedSubCategory,
                    count: res?.data?.count
                })
            })
            .catch((err) => {
                console.log("err", err)
                setLoading(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something went wrong"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                });
            });
    };

    const validate = (bool) => {
        let isValid = true
        let error = data.error
        if (!bool) {
            if (type === "category") {
                if (data?.category_name === "") {
                    isValid = false
                    error.category_name = t("Category Name is Required")
                }

                if (data?.status === null) {
                    isValid = false
                    error.status = t("Status is Required")
                }
            } else {
                if (data?.category === "") {
                    isValid = false
                    error.category = t("Category is Required")
                }

                if (data?.sub_category_name === "") {
                    isValid = false
                    error.sub_category_name = t("Subcategory Name is Required")
                }

                if (data?.status === null) {
                    isValid = false
                    error.status = t("Status is Required")
                }
            }
            setData({
                ...data,
                error: {
                    ...data?.error,
                    ...error
                }
            })
        }
        return isValid
    }

    const createCategory = (activeStatus = false, value = {}) => {
        if (validate(activeStatus)) {
            const categoryPayload = {
                id: activeStatus ? value?.id : data?.id ?? undefined,
                name: activeStatus ? value?.category_name : data?.category_name,
                is_active: activeStatus ? !value?.is_active : data?.status,
                company_id: selectedCompany?.value
            };

            const subCategoryPayload = {
                id: activeStatus ? value?.id : data?.id ?? undefined,
                name: activeStatus ? value?.sub_category_name : data?.sub_category_name,
                is_active: activeStatus ? !value?.is_active : data?.status,
                company_id: selectedCompany?.value,
                project_category_id: activeStatus ? value?.category?.value : data?.category?.value
            }

            NetworkCall(
                `${config.api_url}${type === "category" ? "/project_category/upsert" : "/project_subcategory/upsert"}`,
                NetWorkCallMethods.post,
                type === "category" ? categoryPayload : subCategoryPayload,
                null,
                true,
                false
            ).then((res) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: activeStatus ? value?.is_active ?
                        type === "category" ? t("Project Category InActivated Successfully") : t("Project Subcategory InActivated Successfully") :
                        type === "category" ? t("Project Category Activated Successfully") : t("Project SubCategory Activated Successfully") : t(res?.data?.data),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                });
                onClose()
                getTableList(0, 10, "")
            }).catch((err) => {
                console.log("err", err)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something went wrong"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                });
            })
        } else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill all mandatory fields"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            });
        }
    }

    const onApplyFilter = (value) => {
        setFilterData(value)
        getTableList(0, 10, "", value?.status)
    }

    return (
        <>
            <Grid container alignItems={"center"}>
                <Grid item xs={4}>
                    <SearchFilter
                        placeholder={t("Search")}
                        value={searchText}
                        handleChange={(value) => searchTable(value)}
                        customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
                    />
                </Grid >
                <Grid item xs={8}>
                    <Box display={"flex"} justifyContent={"end"}>
                        <Stack direction="row" spacing={2} className={classes.filter}>
                            <IconButton onClick={() => setFilterDrawer(true)}
                                className={classes.filterButton}>
                                {
                                    filterData?.status?.length > 0 ?
                                        <Badge variant="dot" color="primary"
                                            invisible={false}>
                                            <FilterIMG color="#091b29" />
                                        </Badge>
                                        : <FilterIMG color="#091b29" />
                                }
                            </IconButton>

                            <Button variant='contained' className={classes.createBtn}
                                onClick={() => setCreateOpen(true)}>{t("Create")}</Button>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>

            <Box>
                {
                    loading ?
                        <NewLoader minusHeight="150px" /> :
                        <TableWithPagination
                            heading={type === "category" ? ProjectCategoryHeading(t) : ProjectSubCategoryHeading(t)}
                            rows={list?.data}
                            path={type === "category" ? ProjectCategoryPath : ProjectSubCategoryPath}
                            showpagination={true}
                            showpdfbtn={false}
                            showexcelbtn={false}
                            showSearch={false}
                            handleIcon={handleTableIcon}
                            onClick={() => console.log("")}
                            tableType="no-side"
                            dataType={type === "category" ? ProjectCategoryType : ProjectSubCategoryType}
                            handlePagination={handleTablePagination}
                            handleChangeLimit={handleTablePageLimit}
                            totalRowsCount={list?.count}
                            page={page}
                            limit={limit}
                            height={'calc(100vh - 370px)'}
                            view={true}
                            edit={true}
                            delete={true}
                        />}
            </Box>

            <Drawer
                anchor={"right"}
                open={createOpen}
                onClose={() => onClose()}
                className={classes.drawer}
            >
                <Box p={2}>
                    <Stack direction={"row"} spacing={"12px"} alignItems={"center"} mb={2}>
                        <Box onClick={() => onClose()}><CloseIconWithBG /></Box>
                        <Typography className={classes.drawerTitle}>{t("Create Category")}</Typography>
                    </Stack>
                    <Divider></Divider>
                    <Box mt={2} sx={{ height: "calc(100vh - 130px)" }}>
                        <FormGenerator components={categoryForm} />
                    </Box>
                    <Button variant='contained' fullWidth className={classes.drawerCreateBtn}
                        onClick={() => createCategory()}>{data?.isEdit ? t("Update") : t("Create")}</Button>
                </Box>
            </Drawer>

            <FilterGenerator
                open={filterDrawer}
                onClose={() => setFilterDrawer(false)}
                onApply={(value) => onApplyFilter(value)}
                components={[
                    {
                        component: "toggleButton",
                        value: filterData?.status,
                        options: status,
                        isMulti: true,
                        state_name: "status",
                        label: t("Status")
                    },
                ]} />
        </>
    )
}