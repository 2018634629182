import { Avatar, Box, Button, Chip, Divider, Grid, Stack, Typography } from "@mui/material";
import { PaymentInput } from "../../screens/companyCreation/components";
import { EditIcon, SelectBox, TextBox } from "../index";
import { useStyles } from "./styles";
import { remCalc, stringAvatar, timeZoneConverter, FontFamilySwitch } from '../../utils'
import ShowMoreText from "react-show-more-text";
import { AuthContext } from "../../contexts";
import React from "react";
import { DeleteIcon } from "../../assets";
import { LongTextTooltip } from "../../../src/components/longTextToolTip/index";

export const DetailsCardCoponent = ({ render = () => false, components = [], title = null, border = true, justify = false }) => {
    const classes = useStyles({ border })
    const auth = React.useContext(AuthContext)

    const switchComponents = (val) => {

        if (val?.isActive) {

            switch (val.component) {
                case "text":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <Typography className={classes.heading} noWrap>{val?.heading}</Typography>
                            <Typography className={auth?.auth?.auth?.language === 'ar' ? classes.subNo : classes.sub} noWrap>{val?.subtitle}</Typography>

                        </Grid >
                    );
                case "date":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <Typography className={classes.heading}>{val?.heading}</Typography>
                            <Typography className={classes.sub}>&#x202a;{timeZoneConverter(val?.subtitle)}&#x202c;</Typography>

                        </Grid>
                    );
                case "inputCopy":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <Typography className={classes.heading}>{val?.heading}</Typography>
                            <PaymentInput
                                title={null}
                                diable={true}
                                subtitle={null}
                                btnname="Copy"
                                value={val?.value}
                                onChange={val?.onChange}
                            />
                        </Grid>
                    );
                case "status":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <Typography className={classes.heading}>{val?.heading}</Typography>
                            <Typography sx={{ color: val?.color, backgroundColor: val?.bgColor }} className={classes.status}>{val?.status}</Typography>

                        </Grid>
                    );
                case "button":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            {val?.heading && <Typography className={classes.heading}>{val?.heading}</Typography>}

                            <Button variant={val?.variant} fullWidth={val?.fullWidth} className={classes.btn} onClick={val?.onClick}>
                                {val?.subtitle}
                            </Button>
                        </Grid>
                    );
                case "tag":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <Typography className={classes.heading}>{val?.heading}</Typography>
                            <Typography sx={{ color: val?.color }} className={classes.status}>{val?.status}</Typography>

                        </Grid>
                    );
                case "select":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <Typography className={classes.heading}>{val?.heading}</Typography>
                            <Box height="4px" />
                            <TextBox
                                isRequired={val?.isRequired}
                                label={val?.label}
                                placeholder={val?.placeholder}
                                value={val.value}
                                onChange={val?.onChange}
                                isError={val?.error?.length > 0}
                                errorMessage={val?.error}
                                isReadOnly={val?.isReadonly}
                                isPaginate={val?.isPaginate}
                                loadOptions={val?.loadOptions}
                                loading={val?.loading}
                                debounceTimeout={800}
                                options={val?.options ?? []}
                            />
                        </Grid>
                    );
                case "description":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <Typography className={classes.heading}>{val?.heading}</Typography>
                            {/* <Typography className={classes.sub}>{val?.subtitle}</Typography> */}
                            <ShowMoreText
                                lines={2}
                                more="Show More"
                                less="Show Less"
                                className={classes.remark}
                                anchorClass={classes.seeMoreLessTextStyle}
                                expanded={false}
                                truncatedEndingComponent={"... "}
                            >
                                <span className={classes.sub} dangerouslySetInnerHTML={{ __html: val?.subtitle }} />
                            </ShowMoreText>

                        </Grid>
                    );
                case "divider":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <Divider />
                        </Grid>
                    );
                case "statusTypo":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <Typography sx={{ color: val?.color, backgroundColor: val?.bgColor, marginLeft: `${val?.marginLeft} !important` ?? "0" }} className={classes.status}>{val?.status}</Typography>
                            <Typography className={classes.sub} sx={{ marginLeft: "4px" }}>{val?.value}</Typography>

                        </Grid>
                    );
                case "textBox":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            <Typography className={classes.heading}>{val?.heading}</Typography>
                            <Box height="4px" />
                            <SelectBox
                                isRequired={val?.isRequired}
                                label={val?.label}
                                placeholder={val?.placeholder}
                                value={val.value}
                                onChange={val?.onChange}
                                isError={val?.error?.length > 0}
                                errorMessage={val?.error}
                                isReadOnly={val?.isReadonly}
                                isPaginate={val?.isPaginate}
                                loadOptions={val?.loadOptions}
                                loading={val?.loading}
                                debounceTimeout={800}
                                options={val?.options ?? []}
                            />
                        </Grid>
                    );

                case "primary_button":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            {val?.heading && <Typography className={classes.heading}>{val?.heading}</Typography>}

                            <Button variant={val?.variant} fullWidth={val?.fullWidth} className={classes.primary_button} onClick={val?.onClick}>
                                {val?.subtitle}
                            </Button>
                        </Grid>
                    );
                    case "input_text":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            {val?.heading && <Typography className={classes.heading}>{val?.heading}</Typography>}

                           <Stack direction={"row"} spacing={2}>
                           <Typography className={auth?.auth?.auth?.language === 'ar' ? classes.subNo : classes.sub} noWrap>{val?.subtitle}</Typography>
                           <Box onClick={val?.onClick} sx={{cursor:"pointer"}}>  <EditIcon/></Box>
                           </Stack>
                        </Grid>
                    );
                case "input":
                    return (
                        <Grid
                            item
                            xs={val?.size?.xs}
                            sm={val?.size?.sm}
                            md={val?.size?.md}
                            lg={val?.size?.lg}
                        >
                            {val?.heading && <Typography className={classes.heading}>{val?.heading}</Typography>}

                           <Stack direction={"row"} spacing={2}>
                            <input value={val?.subtitle} onChange={(e) => val?.onChange(e?.target?.value)} style={{border: "none", padding: 0, fontFamily: FontFamilySwitch().semiBold, fontSize: remCalc(14)}} autoFocus={true}/>
                           </Stack>
                        </Grid>
                    );
                    case "owner":
                        return (
                            <Grid
                                item
                                xs={val?.size?.xs}
                                sm={val?.size?.sm}
                                md={val?.size?.md}
                                lg={val?.size?.lg}
                            >
                                {val?.heading && <Typography className={classes.heading}>{val?.heading}</Typography>}
    
                                {
                                    val?.value?.id ?
                                        <Stack direction={"row"} spacing={2} mt={0.5} alignItems={'center'}>
                                            <Stack direction={"row"} spacing={2} alignItems={'center'}>
                                                <Avatar src={val?.value?.image_url} {...stringAvatar(val?.value?.first_name)}>{val?.value?.image_url ?? ""}</Avatar>
                                             <Box>
                                             <Typography className={classes.sub}>{val?.value?.first_name ?? ""}{val?.value?.last_name ?? ""}</Typography>
                                                <Stack direction={"row"} spacing={2} alignItems={'center'} mt={0.5}>
                                                    <Typography className={classes.summarytitle}>{val?.value?.email_id ?? ""}</Typography>
                                                    <Box className={classes.dot} />
                                                    <Typography className={classes.summarytitle}>{val?.value?.mobile_no_country_code ?? ""}{val?.value?.mobile_no ?? ""}</Typography>
                                                </Stack>
                                             </Box>
                                            </Stack>
                                            {
                                                !val?.is_disable && 
                                                <Box onClick={val?.onDelete} sx={{cursor:"pointer"}}>
                                                <DeleteIcon />
                                            </Box>
                                            }
                                        
                                        </Stack>
                                        :
                                        <Stack direction={"row"} spacing={2} mt={0.5} alignItems={'center'}>
                                            <Button className={classes.btn1} variant="outlined" onClick={val?.onClick}>{val.btnText}</Button>
                                        </Stack>
                                }
                            </Grid>
                        );
                        case "profileWithAccount":
                            return (
                                <Grid
                                    item
                                    xs={val?.size?.xs}
                                    sm={val?.size?.sm}
                                    md={val?.size?.md}
                                    lg={val?.size?.lg}
                                >                                    
                                    <Box sx={{position: 'relative', display: 'flex', flexDirection: 'column', gap: '30px'}} >
                                        {/* Profile section */}
                                        <Box sx={{ 
                                            display: 'flex',
                                            flexDirection: { xs: 'column', sm: 'row' },
                                            alignItems: { xs: 'flex-start', sm: 'center' },
                                            gap: 2
                                        }}>
                                            <Avatar 
                                                src={val?.profileImage} 
                                                {...stringAvatar(val?.profileData?.name || "")}
                                                sx={{ width: 60, height: 60 }}
                                            />
                                            
                                            <Box sx={{ flexGrow: 1 }}>
                                                <Stack direction="row" alignItems="center">
                                                    <Typography 
                                                        sx={{ 
                                                            fontFamily: FontFamilySwitch().bold,
                                                            fontSize: remCalc(18)
                                                        }}
                                                    >
                                                        {val?.profileData?.name || ""}
                                                    </Typography>
                                                    
                                                    {val?.profileData?.badge && (
                                                        <Chip
                                                        variant="outlined"
                                                        size="small"
                                                        label={val?.profileData?.badge?.label || ""}
                                                        sx={{
                                                            display: "flex",
                                                            height: "25px",
                                                            padding: "6px 8px",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            gap: "12px",
                                                            border: `0.5px solid ${val?.profileData?.badge?.color || "#5078E1"}`,
                                                            borderRadius: "5px",
                                                            color: val?.profileData?.badge?.color || "#5078E1",
                                                            marginRight: auth?.auth?.auth?.language === 'ar' ? "16px" : 0,
                                                            marginLeft: auth?.auth?.auth?.language === 'ar' ? 0 : "16px"
                                                        }}
                                                        />
                                                    )}
                                                </Stack>
                                                
                                                <Box sx={{ 
                                                    display: 'flex', 
                                                    flexDirection: { xs: 'column', sm: 'row' },
                                                    gap: { xs: 1, sm: 2 },
                                                    mt: 2
                                                }}>
                                                    {val?.profileData?.contactInfo && val?.profileData?.contactInfo.map((item, index) => (
                                                        <Box key={index} sx={{ 
                                                            backgroundColor: '#f9fafb',
                                                            borderRadius: '8px',
                                                            py: 1,
                                                            px: 1,
                                                            maxWidth: item.maxWidth || '300px'
                                                        }}>
                                                            <Stack direction="row" spacing={1}>
                                                            <Typography 
                                                                sx={{ 
                                                                    fontSize: remCalc(14),
                                                                    display: 'flex',
                                                                    flexWrap: 'nowrap',
                                                                    overflow: 'hidden'
                                                                }}
                                                            >
                                                                <span style={{ 
                                                                    color: '#232323',
                                                                    flexShrink: 0 
                                                                }}>
                                                                    {item.label}:&nbsp;
                                                                </span>
                                                                <LongTextTooltip title={item.value || ""} placement="top" arrow>
                                                                    <span style={{ 
                                                                        fontFamily: FontFamilySwitch().semiBold,
                                                                        whiteSpace: 'nowrap',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        fontWeight: 600
                                                                    }}>
                                                                        {item.value || ""}
                                                                    </span>
                                                                </LongTextTooltip>
                                                            </Typography>
                                                            </Stack>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Box>
                                        </Box>
                                        
                                        {/* Account details section */}
                                        {val?.accountDetails && (
                                            <Box sx={{ 
                                                display: 'grid',
                                                gridTemplateColumns: { 
                                                    xs: '1fr', 
                                                    sm: `repeat(${Math.min(val?.accountDetails?.length || 3, 3)}, 1fr)` 
                                                },
                                                gap: 4
                                            }}>
                                                {val?.accountDetails.map((detail, index) => (
                                                    <Box key={index}>
                                                        <Typography
                                                            sx={{
                                                                color: '#707070',
                                                                fontSize: remCalc(14),
                                                                mb: 1
                                                            }}
                                                        >
                                                            {detail.label}
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontFamily: FontFamilySwitch().semiBold,
                                                                fontSize: remCalc(14),
                                                            }}
                                                        >
                                                            {detail.value || ""}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        )}
                                    </Box>
                                </Grid>
                            );
                   
                    default:
                    return null;
            }
        }
    }
    return (
        <Box className={auth?.auth?.auth?.language === 'ar' ? classes.rootAr : classes.root} >
            <Grid container spacing={2} justifyContent={justify ? "space-between" : "auto"}>
                {
                    title && <Grid item xs={12}>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography className={classes.title}>{title}</Typography>
                            {render}
                        </Stack>

                    </Grid>
                }

                {
                    components?.length && components?.map(_ => (
                        <>
                            {switchComponents(_)}

                        </>
                    ))
                }
            </Grid>
        </Box>
    )
}