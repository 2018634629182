import React from "react";
import { withNavBars } from "../../HOCs";
import CreateExternalUnitContext from "./externalUnitContext";
import ExternalUnits from "./externalUnits";


class ExternalUnitsParent extends React.Component {
    render() {
        return(
        <CreateExternalUnitContext {...this.props}>
            <ExternalUnits />
        </CreateExternalUnitContext>
        );

    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ExternalUnitsParent, props);