import { Box, Button, Typography, styled } from '@mui/material'
import React from 'react'
import { componentsStyle } from './style'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TextBox } from '../../../components';
import { MarketingUpload } from './marketingUpload';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { FontFamilySwitch } from '../../../utils';


const LongTextTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: "400px",
        borderRadius: "4px",
        padding: "8px 12px",
        backgroundColor: "#4E5A6B",
        color: "#FFFFFF",
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
    },
    [`& .${tooltipClasses.arrow}`]: {
        backgroundColor: "transparent",

        color: "#4E5A6B"
    },
}));

export const UploadCard = (props) => {
    const { t } = props
    const classes = componentsStyle(props)
    const [open, setOpen] = React.useState(false)
    const openDown = () => {
        setOpen(true)
    }
    const closeDown = () => {
        setOpen(false)
    }


    return (
        <>
            <Box className={classes.uploadBox}>
                <Box className={classes.boxSection}>
                    <Box className={classes.boxSection2}>
                        {props?.data?.icon}
                        <Typography className={classes.uploadHeader}>{props?.data?.name}</Typography>
                        {props?.data?.tooltip &&
                            <LongTextTooltip title={props?.data?.value ?? "lkjkdjkdjdkslkdjdk"} placement="right" arrow>

                                <InfoOutlinedIcon className={classes.icon} />
                            </LongTextTooltip>
                        }

                    </Box>
                    <Box>
                        <Typography className={classes.uploadText}>{props?.data?.value}</Typography>
                    </Box>
                    {open ?
                        <Box className={classes.boxSection2}>
                            <Button variant="outlined" className={classes.cancelBtn} onClick={closeDown}>{t("Cancel")}</Button>
                            <Button variant="contained" className={classes.saveBtn} onClick={() => {
                                props?.updateLinkAndPhoto(props?.data)
                                setOpen(false)
                            }}>Save</Button>
                        </Box>
                        :
                        <Box>
                            <Button variant="text" className={classes.btn} onClick={openDown}>{props?.data?.value ? t("Edit") : t("Update")}</Button>

                        </Box>
                    }
                </Box>
                {open &&
                    <Box>
                        <Box className={classes.textAndUpload}>
                            {props?.data?.link ?
                                <TextBox
                                    label={""}
                                    value={props?.data?.value}
                                    onChange={(e) => props?.updateState(props?.data?.state, e.target.value, props?.data?.id)}
                                />
                                :
                                <MarketingUpload t={t} value={props?.data?.value} updateState={props?.updateState} state={props?.data?.state} id={props?.data?.id} />
                            }
                        </Box>
                    </Box>
                }


            </Box>
        </>
    )
}