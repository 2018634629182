import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import Exporting from "highcharts/modules/exporting";
import { FontFamilySwitch } from "../../../utils";

Exporting(Highcharts);

export const LineBarChart = ({
  data = {},
  options = {},
  isShowLegend = false,
  chartHeight = "",
  graphTitle = "",
  colors = ["#FF8064", "#F29000", "#7CB10D", "#83C19F"],
  graphColor = ["#FF8064", "#F29000", "#7CB10D", "#83C19F"],
}) => {
  const chartRef = useRef(null);
  const highchartRef = useRef(null);

  useEffect(() => {
    if (!highchartRef.current) {
      highchartRef.current = Highcharts.chart(chartRef.current, {
        colors,
        chart: {
          type: "column",
          ...options.chart,
        },
        title: {
          text: graphTitle,
          align: "left",
          style: {
            color: "#091B29",
            fontSize: "1rem",
            fontFamily: FontFamilySwitch().extraBold,
            marginInlineStart: "8px",
          },
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: data.labels,
          ...options.xAxis,
        },
        yAxis: {
          min: 0,
          ...options.yAxis,
        },
        exporting: {
          enabled: true,
        },
        plotOptions: {
          series: {
            enableMouseTracking: false,
          },
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: false,
              ...options.plotOptions?.column?.dataLabels,
            },
            pointPadding: 0.1,
            opacity: 1, // Set opacity to 1 for the column series
            enableMouseTracking: true,
            ...options.plotOptions?.column,
          },
          line: {
            marker: {
              enabled: false,
            },
          },
          ...options.plotOptions,
        },
        legend: {
          enabled: isShowLegend,
          align: "center",
          verticalAlign: "bottom",
          layout: "horizontal",
          margin: 15,
          ...options.legend,
        },
        tooltip: {
          useHTML: true,
          formatter: function () {
            let number = this.y;
            let metric = "";
            if (number >= 1.0e+12) {
              number = number / 1.0e+12;
              metric = "Tn";
            } else if (number >= 1.0e+9) {
              number = number / 1.0e+9;
              metric = "Bn";
            } else if (number >= 1.0e+6) {
              number = number / 1.0e+6;
              metric = "Mn";
            } else if (number >= 1.0e+3) {
              number = number / 1.0e+3;
              metric = "k";
            }
            return "<b>" + this.x + "</b>: " + Highcharts.numberFormat(number, 0) + metric;
          },
        },
        series: [
          {
            type: "areaspline",
            name: "Line Series",
            data: data.datasets[0].data.map((item) => parseInt(item)),
            color: graphColor[0],
            lineWidth: 0,
            fillColor: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: "90%" },
              stops: [
                [0, Highcharts.color(graphColor[0]).brighten(0).get()],
                [1, Highcharts.color(graphColor[0]).setOpacity(0.1).get("rgba")],
              ],
            },
            marker: {
              enabled: false,
            },
          },
          {
            type: "column",
            name: "Column Series",
            data: data.datasets[0].data.map((item) => parseInt(item)),
            pointWidth: 25,
          },
        ],
        ...options,
      });
    } else {
      highchartRef.current.update({
        xAxis: {
          categories: data.labels,
          ...options.xAxis,
        },
        series: [
          {
            data: data.datasets[0].data,
          },
          {
            data: data.datasets[0].data,
          },
        ],
      });
    }
    if (!highchartRef.current) {
      highchartRef.current = Highcharts.chart(chartRef.current, options);
    } else {
      highchartRef.current.update(options);
    }

    const handleFullscreenChange = () => {
      if (highchartRef.current) {
        highchartRef.current.reflow();
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      if (highchartRef.current) {
        highchartRef.current = null;
      }
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, [data, options, isShowLegend, graphTitle, colors, graphColor]);

  return (
    <div ref={chartRef} style={{ width: "100%", height: chartHeight, paddingTop: '0', paddingBottom: '0' }}></div>
  );
};
