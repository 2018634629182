import makeStyles from "@mui/styles/makeStyles/makeStyles";
import { FontFamilySwitch } from "../../../utils";

export const TableStyles = makeStyles((theme) => ({
    content: {
        display: "flex",
        flexDirection: "column"
    },
    filter: {
        border: `1px solid ${theme.palette.border.secondary}`,
        padding: 2,
        borderRadius: 4
    },
    tablegrid: {
        paddingTop: "0px !important"
    },
    filterButton: {
        height: '40px',
        width: '40px'
    }
}))

export const RequestPopupStyle = makeStyles((theme) => ({
    titleText: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary
    },
    contentText: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontWeight: "bold"
    },
    profileSection: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 6
    },
    declineBtn: {
        background: theme.palette.error.main,
        color: "#fff",
        fontSize:"0.875rem",
        borderRadius: 4,
        width: "99%",
        '&:hover': {
            background: theme.palette.error.main,
            color: "#fff",
            fontSize:"0.875rem",
            borderRadius: 4,
            width: "99%",
        }
    },
    reconcile: {
        background: theme.palette.success.main,
        color: "#fff",
        fontSize:"0.875rem",
        borderRadius: 4,
        width: "99%",
        '&:hover': {
            background: theme.palette.success.main,
            color: "#fff",
            fontSize:"0.875rem",
            borderRadius: 4,
            width: "99%",
        }
    },
    content_box: {
        overflowY: "auto",
        // height: "350px",
    }
}))

export const ProfileCardStyle = makeStyles((theme) => ({
    dot: {
        height: 6,
        width: 6,
        background: theme.palette.border.secondary,
        borderRadius: 50,
        marginInline: "16px"
    },
    name: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    mobno_email: {
        color: theme.typography.color.secondary,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
    },
    mobno: {
        color: theme.typography.color.secondary,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        direction: "ltr"
    },
    titleText: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary
    },
    contentText: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
}))


export const UpdatePopupStyle = makeStyles((theme) => ({
    decline_alert: {
        borderRadius: 4,
        backgroundColor: theme.palette.error.light
    },
    cancel_alert: {
        borderRadius: 4,
        backgroundColor: theme.palette.error.light
    },
    reconcile_alert: {
        borderRadius: 4,
        backgroundColor: theme.palette.success.light
    },
    expired: {
        borderRadius: 4,
        backgroundColor: theme.palette.background.tertiary
    },
    decline_icon: {
        height: 52,
        width: 52,
    },
    alert_title: {
        fontSize:"1rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().bold
    },
    alert_content: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        wordBreak: "break-all"
    },
    label: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.tertiary
    },
    remarks: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        whiteSpace: "nowrap",
        marginTop: "10px",
        marginLeft: "40px"
    }
}))