import { Box, Typography, Button, Collapse } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { DetailsCardCoponent, FormGenerator } from "../../../components";
import ConfirmationDialog from "../../../components/confirmationDialog";
import FinancialCards from "./financeCard";
import { config } from "../../../config";
import { AlertContext, AuthContext, BackdropContext } from '../../../contexts';
import { NetworkCall } from "../../../networkcall";
import { Routes } from "../../../router/routes";
import { AlertProps, enum_types, enumSelect, NetWorkCallMethods, FontFamilySwitch } from "../../../utils";
import { getAccountDetails } from "../../../utils/receipt";
import { SettingSave } from "../../companyCreation/components/settingSaveSections";
import { InvoiceTable } from "../components";
import { ReceiptStyles } from "../styles";
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Details = ({
    t,
    selected = {},
    data = {},
    list = [],
    setData = () => false,
    setList = () => false,
    currency = "",
    type = "",
    company = {},
    permission = {},
    validateForm = () => false,
    count=0,
    setCount=()=>false,
    state
}) => {

    const classes = ReceiptStyles();
    const alert = React.useContext(AlertContext);
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext)
    const navigate = useNavigate()
    const detailsData = getAccountDetails(t, selected);
    const [isDisableBtn,setIsDisableBtn]=React.useState(false)
    const [formData, setFormData] = React.useState({
        chequeImgLink: [],
      });
      const handleFormChange = (key, value) => {
        const newValue = value && value.target ? value.target.value : value;
        setFormData((prevData) => ({
          ...prevData,
          [key]: newValue,
        }));
      };
    const [enumValue, setEnum] = React.useState({
        payment_mode: [],
        cheque_status: []
    })
    const [showInvoiceTable, setShowInvoiceTable] = React.useState(type === "invoice");
    const [outstandingBalance, setOutstandingBalance] = React.useState("");
    const [unusedBalance, setUnusedBalance] = React.useState("");  
    const [bankAccountList, setBankAccountList] = React.useState([]);
    const [collectorList, setCollectorList] = React.useState([]);    
    const getEnum = async () => {
        const result = await enumSelect([enum_types.payment_mode, enum_types?.cheque_status])
        const filteredPaymentModes = result?.payment_mode?.filter(mode => mode.value !== "Credit Note"); 
        setEnum({
            payment_mode: filteredPaymentModes,
            cheque_status: result?.cheque_status
        })
    }
    const getCollectorList = async () => {
        const payload = {
            company_id: company?.value
        };
    
        try {
            const response = await NetworkCall(
                `${config.api_url}/contact/get_collector`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            );    
            const collectors = response?.data?.list?.map((collector) => ({
                label: collector?.name,
                value: collector?.id
            })) || [];
            setCollectorList(collectors);
        } catch (error) {
            console.log("Error fetching collectors:", error);
            setCollectorList([]);
        }
    };
    const handleUpload = (file) => {
        if (file.type === "image/jpeg" || file.type === "image/png") {
            setFormData((prevData) => ({
                ...prevData,
                chequeImgLink: [...prevData.chequeImgLink, { name: file.name, url: file.src, type: file.type }],
            }));
        } else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Only JPG and PNG files are supported."),
            });
        }
    };
    
    const handleDelete = (index) => {
        setFormData((prevData) => ({
            ...prevData,
            chequeImgLink: prevData.chequeImgLink.filter((_, i) => i !== index),
        }));
    };

    const getBankAccountList = async () => {
        const payload = {
            offset: 0,
            limit: 10,
            search: "",
            company: company?.value,
            account_type: ["Cash", "Credit Card", "Bank Account"]
        };
    
        try {
            const response = await NetworkCall(
                `${config.api_url}/account/get_account_list`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            );
    
            
            const accounts = response?.data?.data?.contact_account?.map((account) => ({
                label: account?.coa_number ? `${account?.name}, (${account?.coa_number})` : account?.name,
                value: account?.id
            })) || [];
            setBankAccountList(accounts);
        } catch (error) {
            console.log("Error fetching bank accounts:", error);
        }
    };
    
    
    //get invoice against account
    const getInvoice = (offset, searchText, reload) => {


        const payload = {
            offset: offset,
            limit: 10,
            id: selected?.subTitle1,
            search: searchText,
        }

        NetworkCall(
            `${config.api_url}/invoice/account_invoice`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            let data = response?.data?.invoice?.map((val) => {
                return (
                    {
                        id: val?.id,
                        invoice_id: val?.invoice_no,
                        agreement_id: val?.agreement?.agreement_no,
                        invoice_generate_date: val?.created_at,
                        status: val?.payment_status === "no-due" ? "Paid" : "Unpaid",
                        pending_amount: `${currency ?? val?.currency_master?.symbol} ${val?.invoice_due_amount ?? 0} `,
                        total_amount: `${currency ?? val?.currency_master?.symbol} ${val?.invoice_total_amount ?? 0} `,
                        total_amount_value: val?.invoice_due_amount,
                        url: "View PDF",
                        is_active: false,
                        asset: val?.asset_url,
                        symbol: currency ?? val?.currency_master?.symbol,
                        amount_tagged: val?.invoice_due_amount,

                    }
                )
            })
            let balance = `${currency ?? response?.data?.invoice?.map((val) => val?.currency_master?.symbol)} ${Number(response?.data?.totalPendingAmount ?? 0).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
            setOutstandingBalance(balance);
            let unusedBalance = `${currency ?? response?.data?.invoice?.map((val) => val?.currency_master?.symbol)} ${Number(response?.data?.unused_balance ?? 0).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
            setUnusedBalance(unusedBalance);
            if (reload) {
                setList(data);
            } else {
                setList([...list, ...data]);
            }
            setCount(response?.data?.count?.[0]?.count);

        }).catch((err) => {
            console.log(err)
        })


    }
    //initial load
    React.useEffect(() => {
        if (selected?.subTitle1) {
            getInvoice(0, "", true);
            getBankAccountList();
            getCollectorList();
        }
        setData((prevData) => ({
            ...prevData,
            paymentmethod: "",
            amountTopay: ""
        }));
    }, [selected, company]);

    React.useEffect(() => {
        getEnum();
    }, []);

    //update state 
    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        setData({ ...data, [key]: value, error });
    };
    //form data
    const controlErrorMsg = (msgtext) => {
        if(data?.paymentmethod?.value === "Cash"){
            return null
        }
        else{
            return msgtext
        }
    }
    const changeInput = [
                {
                    size: {
                        xs: 12,
                        sm: 3,
                        md: 3,
                        lg: 4
                    },
                    isActive: true,
                    component: "select",
                    label: t("paymentMethod"),
                    labelStyle: { 
                        color: "#232323", 
                        fontSize: "0.875rem", 
                        fontFamily: FontFamilySwitch().semiBold,
                        marginBottom: "8px",
                    },
                    border: "1px solid #C1C5CB",
                    placeholder: t("paymentMethod"),
                    value: data?.paymentmethod,
                    onChange: (value) => updateState("paymentmethod", value),
                    error: data?.error?.paymentmethod,
                    isRequired: true,
                    options: enumValue?.payment_mode?.map((mode) => ({
                        label: t(mode.label),
                        value: mode.value,
                    })),
                },
                {
                    size: {
                        xs: 12,
                        sm: 3,
                        md: 3,
                        lg: 4
                    },
                    isActive: true,
                    component: "date",
                    label: t("Transaction Date"),
                    labelStyle: { 
                        color: "#232323", 
                        fontSize: "0.875rem", 
                        fontFamily: FontFamilySwitch().semiBold,
                        marginBottom: "8px",
                    },
                    border: "1px solid #C1C5CB",
                    placeholder: t("Transaction Date"),
                    value: data?.transactionDate ?? new Date(),
                    onChange: (value) => updateState("transactionDate", value),
                    error: data?.error?.transactionDate,
                    isRequired: true,
                    maxDate: new Date(), 
                },
                {
                    size: {
                        xs: 12,
                        sm: 3,
                        md: 3,
                        lg: 4
                    },
                    isActive:true,
                    component: "date",
                    label: t("Receipt Date"),
                    labelStyle: { 
                        color: "#232323", 
                        fontSize: "0.875rem", 
                        fontFamily: FontFamilySwitch().semiBold,
                        marginBottom: "8px",
                    },
                    border: "1px solid #C1C5CB",
                    placeholder: t("Receipt Date"),
                    value: data?.paymentDate,
                    onChange: (value) => updateState("paymentDate", value),
                    isRequired: true,
                    maxDate: new Date(), 
                },
               
                {
                    size: { xs: 12, sm: 3, md: 3, lg: 8 },
                    isActive: true,
                    component: "select",
                    label: t("Cash & Bank Account"),
                    labelStyle: { 
                        color: "#232323", 
                        fontSize: "0.875rem", 
                        fontFamily: FontFamilySwitch().semiBold,
                        marginBottom: "8px",
                    },
                    border: "1px solid #C1C5CB",
                    placeholder: t("Cash & Bank Account, COA"),
                    value: data?.bankName,
                    onChange: (value) => updateState("bankName", value),
                    error: data?.error?.bankName,
                    isRequired: true,
                    options: bankAccountList.length > 0 ? bankAccountList : []
                },
                {
                    size: {
                        xs: 12,
                        sm: 3,
                        md: 3,
                        lg: 4
                    },
                    isActive: true,
                    component: "text",
                    label: t("receiptAmount"),
                    labelStyle: { 
                        color: "#232323", 
                        fontSize: "0.875rem", 
                        fontFamily: FontFamilySwitch().semiBold,
                        marginBottom: "8px",
                    },
                    border: "1px solid #C1C5CB",
                    padding: "12px ",
                    height: "51px",
                    type: "number",
                    placeholder: t("amountToPay"),
                    value: data?.amountTopay,
                    onChange: (e) => {
                        let value = e.target.value;
                        if (value.length > 15) return;
                        updateState("amountTopay", value);
                    },
                    error: data?.error?.amountTopay,
                    isRequired: true,
                    isReadonly: type === "invoice",
                },
                {
                    size: {
                        xs: 12,
                        sm: 3,
                        md: 3,
                        lg: 4
                    },
                    isActive: true,
                    component: "text",
                    label: (
                        <Box component="span" display="inline-flex" alignItems="center" gap="4px">
                            {t("Transaction Number")}
                            <Tooltip title={t("Optional for Cash")} placement="top" arrow>
                                <InfoOutlinedIcon fontSize="small" style={{ cursor: "pointer", color: "#666" }} />
                            </Tooltip>
                        </Box>
                    ),
                    labelStyle: { 
                        color: "#232323", 
                        fontSize: "0.875rem", 
                        fontFamily: FontFamilySwitch().semiBold,
                        marginBottom: "8px",
                    },
                    border: "1px solid #C1C5CB",
                    padding: "12px",
                    height: "51px",
                    placeholder: t("Transaction Number"),
                    value: data?.transactionNumber,
                    onChange: (value) => updateState("transactionNumber", value.target.value),
                    error: controlErrorMsg(data?.error?.transactionNumber),
                    isRequired: data?.paymentmethod?.value !== "Cash",
                    maxLength: 15,
                },
                {
                    size: {
                        xs: 12,
                        sm: 3,
                        md: 3,
                        lg: 4
                    },
                    isActive: true,
                    component: "text",
                    label: (
                        <Box component="span" display="inline-flex" alignItems="center" gap="4px">
                            {t("Cheque / Transaction Reference")}
                            <Tooltip title={t("Optional for Cash")} placement="top" arrow>
                                <InfoOutlinedIcon fontSize="small" style={{ cursor: "pointer", color: "#666" }} />
                            </Tooltip>
                        </Box>
                    ),
                    labelStyle: { 
                        color: "#232323", 
                        fontSize: "0.875rem", 
                        fontFamily: FontFamilySwitch().semiBold,
                        marginBottom: "8px",
                    },
                    border: "1px solid #C1C5CB",
                    padding: "12px",
                    height: "51px",
                    placeholder: t("Cheque/Transaction Number"),
                    value: data?.chequeNo,
                    onChange: (value) => updateState("chequeNo", value.target.value),
                    error: controlErrorMsg(data?.error?.chequeNo),
                    isRequired: data?.paymentmethod?.value !== "Cash",
                    maxLength: 15,
                },                
                
                {
                    size: {
                        xs: 12,
                        sm: 3,
                        md: 3,
                        lg: 4
                    },
                    isActive: true,
                    component: "select",
                    label: t("Collector"),
                    labelStyle: { 
                        color: "#232323", 
                        fontSize: "0.875rem", 
                        fontFamily: FontFamilySwitch().semiBold,
                        marginBottom: "8px",
                    },
                    border: "1px solid #C1C5CB",
                    placeholder: t("Collector"),
                    value: data?.collector,
                    onChange: (value) => updateState("collector", value),
                    error: data?.error?.collector,
                    isRequired: true,
                    options: collectorList.length > 0 ? collectorList : []
                },                
                {
                    size: {
                        xs: 3,
                        sm: 3,
                        md: 3,
                        lg: 4
                    },
                    isActive:true,
                    component: "customNotes",
                    label: t("Notes"),
                    labelStyle: { 
                        color: "#232323", 
                        fontSize: "0.875rem", 
                        fontFamily: FontFamilySwitch().semiBold,
                        marginBottom: "5px",
                    },
                    border: "1px solid #C1C5CB",
                    placeholder: t("Type Notes here"),
                    value: data?.notes,
                    onChange: (value) => updateState("notes",value),
                    height : "180px"
                },
                { 
                    size: {
                        xs: 3,
                        sm: 3,
                        md: 3,
                        lg: 4
                    },
                    isActive: true,
                    component: "customFileUpload",
                    label: t("Upload Cheque Image"),
                    labelStyle: { 
                        color: "#232323", 
                        fontSize: "0.875rem", 
                        fontFamily: FontFamilySwitch().semiBold,
                        marginBottom: "5px",
                    },
                    value: formData.chequeImgLink,
                    onChange: (value) => handleFormChange("chequeImgLink", value),
                    handleDelete,
                    handleUpload,
                },
            ]
    //on save
    const [openDialog, setOpenDialog] = React.useState({
        bool: false,
        type: "",
    });

    const handleDialogOpen = (type) => {
        setOpenDialog({
            bool: true,
            type: type,
        });
    };

    const handleDialogClose = () => {
        setOpenDialog({
            bool: false,
            type: "",
        });
    };

    const onSave = () => {
        if (validateForm()) {
            handleDialogOpen("create");
        }
    };

    const onCancel = () => {
        handleDialogOpen("cancel");
    };

    const confirmSave = () => {
        if (validateForm()) {
            setIsDisableBtn(true);
            backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." });
            handleDialogClose(); 
            const payload = {
                account_no: selected?.subTitle1,
                invoices: list?.filter((val) => val?.is_active).map((val) => {
                    return ({
                        id: val?.id,
                        amount_tagged: val?.amount_tagged
                    })
                }),
                type: type,
                payment_amount: data?.amountTopay,
                payment_mode: data?.paymentmethod?.value,
                bank_name: (data?.paymentmethod?.value !== "Cash" && data?.bankName?.label?.length>0 ) ? data?.bankName?.label : undefined,
                bank_branch: (data?.paymentmethod?.value !== "Cash" && data?.branch?.length>0) ? data?.branch : undefined,
                cheque_no: (data?.paymentmethod?.value !== "Cash"  && data?.chequeNo?.length>0) ? data?.chequeNo : undefined,
                cheque_status: (data?.paymentmethod?.value !== "Cash" && data?.clearanceStatus?.value?.length>0) ? data?.clearanceStatus?.value : undefined,
                company_id: company?.value,
                currency_id: company?.currency_id,
                payment_date:data?.paymentDate,
                transaction_date: data?.paymentmethod?.value === "Cash" ? null : data?.transactionDate,
                transaction_number: data?.transactionNumber,
                collector: data?.collector?.label,
                notes: data?.notes,
                cheque_image: Array.isArray(formData.chequeImgLink)
                ? JSON.stringify(formData.chequeImgLink.map(file => file.url))
                : "[]",
            }
            NetworkCall(
                `${config.api_url}/settlement`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            )
                .then((response) => {
                    backdrop.setBackDrop({ ...backdrop, open: false, message: "Loading..." })
                    setIsDisableBtn(false)
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg:t("Receipt Created Successfully"),
                    });
                    getInvoice(0, "", true);
                    navigate(Routes.receipt);
                }).catch((err) => {
                    backdrop.setBackDrop({ ...backdrop, open: false, message: "Loading..." });
                    setIsDisableBtn(false);
                    console.log(err)
                })
        }

    }

    const confirmCancel = () => {
        navigate(Routes.receipt);
    };

    const toggleInvoiceTable = () => {
        setShowInvoiceTable(prev => !prev);
      };

    return (
        <Box>
            <Box className={classes.detailRoots}>
                <Box  
                    className={auth?.auth?.auth?.language === "ar" ? classes.profileContainerArabic : classes.profileContainer} 
                    alignItems={{ xs: 'stretch', md: 'center' }} 
                    flexDirection={{ xs: 'column', md: 'row' }} >
                    <DetailsCardCoponent components={detailsData} />
                    <FinancialCards t={t} toggleInvoiceTable={toggleInvoiceTable} type={type} outstandingBalance={outstandingBalance} count={count} unusedBalance={unusedBalance} />
                </Box>
                <Collapse in={showInvoiceTable} timeout="auto" unmountOnExit>
                    <InvoiceTable
                        state={state}
                        currency={currency}
                        id={selected?.subTitle1}
                        updateState={updateState}
                        list={list}
                        getInvoice={getInvoice}
                        total={data?.amountTopay}
                        setList={setList}
                        datas={data}
                        setData={setData}
                        count={count}
                        handleCloseTable={() => setShowInvoiceTable(false)} 
                    />
                </Collapse>

                <Box p={2} mt={2} className={classes.root}>
                <Typography mb={3} className={classes.title}>
                    {t("Payment Details")}
                </Typography>
                    <FormGenerator t={t} components={changeInput} spacing={3}/>
                </Box>

            </Box>
            <SettingSave onSave={onSave} cancel={true} btn={permission?.create} btnName={t("Create")} cancelName={t("cancel")} isDisableBtn={isDisableBtn} onCancel={onCancel}/>
            <ConfirmationDialog
                title={openDialog.type === "create" ? t("Create Receipt") : t("Cancel Receipt")}
                message={openDialog.type === "create" ? t("Are you sure you want to create the receipt?") : t("Are you sure you want to cancel the receipt?")}
                open={openDialog.bool}
                onClose={handleDialogClose}
                type={openDialog.type}
                yesClick={openDialog.type === "create" ? confirmSave : confirmCancel}
                noClick={handleDialogClose}
                t={t}
            />
                        
        </Box>
    )
}
export default withTranslation("receipt")(Details);