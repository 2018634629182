import { bussinessActions, bussinesssEditActions, bussinessUpdateActions, getPropertyEditActions, getPropertyUpdateActions, propertyActions, serviceActions, serviceAddActions, serviceEditActions } from "../../../utils";

export const getTableActions = (type, name, offset, limit, search, company, status , t=()=>false) => {
    switch (type) {
        case 'Property Master':
            return propertyActions(name, offset, limit, search, company, status , t);
        case 'Business Master':
            return bussinessActions(name, offset, limit, search, company, status , t);
        case 'Service Master':
            return serviceActions(name, offset, limit, search, status , t);

        default:
            return null
    }
}

export const addActions = (type, name, t) => {
    const propertyUpdateActions = getPropertyUpdateActions(t);
    switch (type) {
        case 'Property Master':
            return propertyUpdateActions[name];
        case 'Business Master':
            return bussinessUpdateActions[name];
        case 'Service Master':
            return serviceAddActions[name];
        default:
            return null;
    }
}

export const editActions = (type, name, t) => {
    const propertyEditActions = getPropertyEditActions(t)
    switch (type) {
        case 'Property Master':
            return propertyEditActions[name];
        case 'Business Master':
            return bussinesssEditActions[name];
        case 'Service Master':
            return serviceEditActions[name];
        default:
            return null
    }
}