import { Box, Grid } from "@mui/material";
import moment from "moment";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CityProperty from "../../assets/cityproperty";
import { PropertyTabs, Subheader } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import {
  accessCheckRender,
  AlertProps,
  allowed_file_size,
  getMobileLimitBasedOnCC,
  LocalStorageKeys,
  NetWorkCallMethods,
  singleFileUpload,
  ValidateEmail,
} from "../../utils";
import {
  concat_string,
  getRoutePermissionNew,
  mapResult,
} from "../../utils/common";
import { useStylesCreation } from "./style";
import { GeneralDetails } from "./tabs/generalDetails";
import { NationalIDDetails } from "./tabs/nationalIDDetails";
import { ContactProof } from "./tabs/proof";
import { withTranslation } from "react-i18next";

const CreateContactNew = ({ type = "", t = () => false }) => {
  const { state } = useLocation();
  const initial = {
    id: state?.main?.id,
    isActive: true,
    company:
      type === "Agent"
        ? JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))
        : state?.main?.company?.value
          ? state?.main?.company
          : "",
    company_no: JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))?.company_no,
    companyList: state?.main?.companyList ?? [],
    isAllCompany: state?.main?.isAllCompany,
    relationship:
      type === "Agent"
        ? {
          label: "Sales Broker",
          value: "External Sales Broker",
        }
        : state?.main?.relationship ?? [],
    role: state?.main?.role,
    vendor: state?.main?.vendor,
    userID: "",
    userProfileID: "",
    profileImage: "",
    name: "",
    jobTitle: "",
    properties: "",
    initialProperties: [],
    initialEmail: null,
    initialMobile: null,
    deletedProperties: [],
    doorNo: "",
    addressLineOne: "",
    addressLineTwo: "",
    landmark: "",
    area: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    latitude: "",
    longitude: "",
    preferredModeContact: "",
    preferred_mode_contact: "",
    primaryTelephone: "",
    primaryMobile: "",
    primaryEmail: "",
    emergencyContactName: "",
    emergencyContactRelationship: "",
    emergencyContactNumber: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    nationality: "",
    nationalID: "",
    nationalIDExpiry: "",
    passportID: "",
    passportIDExpiry: "",
    residentID: "",
    residentIDExpiry: "",
    visaNumber: "",
    visaNumberExpiry: "",
    bankName: "",
    bankBranchName: "",
    bankCurrency: "",
    bankAccountType: "",
    bankAccountNumber: "",
    bankRoutingType: "",
    bankRoutingCode: "",
    bankAddressLineOne: "",
    bankAddressLineTwo: "",
    bankCity: "",
    bankCountry: "",
    chequeName: null,
    preferredCashCollectionOffice: "",
    otherRelationship: "",
    otherDepartment: [],
    Department: [],
    member: {},
    proofFile: [],
    sex: [],
    whatsApp: "",
    employee_id: "",
    vendorlist: "",
    serviceProvider: "",
    primary_account: "",
    login_email: "",
    ap_primary_account: "",
    last_revenue_target: "",
    current_revenue_target: "",
    error: {
      company: "",
      profileImage: "",
      name: "",
      jobTitle: "",
      properties: "",
      primaryTelephone: "",
      primaryMobile: "",
      validMobile: "",
      validTele: "",
      primaryEmail: "",
      relationship: "",
      otherRelationship: "",
      Department: "",
      member: "",
      nationalID: "",
      nationalIDExpiry: "",
      passportID: "",
      passportIDExpiry: "",
      residentID: "",
      residentIDExpiry: "",
      visaNumber: "",
      visaNumberExpiry: "",
      vendorlist: "",
      serviceProvider: "",
      nationality: "",
    },
  };
  const navigate = useNavigate();
  const classes = useStylesCreation();
  const [data, setData] = React.useState({ ...initial });
  const [proofType, setProofType] = React.useState("");
  const [mapLoad, setMapLoad] = React.useState(!Boolean(state?.main?.isEdit));
  const [disable, setDisable] = React.useState(false);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext);
  const [load, setLoad] = React.useState(false);
  const [value, setValue] = React.useState(1);
  const [permission, setPermission] = React.useState({});
  const [already_exist, set_already_exist] = React.useState({
    is_exist: {
      primaryEmail: false,
      primaryMobile: false,
    },
    error: {
      primaryEmail: "",
      primaryMobile: "",
    },
  });

  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      setProofType("Contact");
      if (perm?.read) {
        if (state?.main?.isEdit) {
          getContact();
        }
      }
    }
    // eslint-disable-next-line
  }, [auth, state?.main?.isEdit]);

  const tabTittle = [
    {
      label: t("Contact Details"),
      imgSelect:
        value > 1 ? (
          <img src="/images/correct_icon.svg" alt="icon" />
        ) : (
          <CityProperty color={value === 1 ? "#5078E1" : "#98A0AC"} />
        ),
      value: 1,
      className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle,
    },
    {
      label: t("KYC Details"),
      imgSelect:
        value > 2 ? (
          <img src="/images/correct_icon.svg" alt="icon" />
        ) : (
          <CityProperty color={value === 2 ? "#5078E1" : "#98A0AC"} />
        ),
      value: 2,
      className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle,
    },
  ];

  const updateimg = async (data) => {
    backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading" });

    let uploaded_file = await singleFileUpload(
      data,
      { tenantId: `${config.tenantId}` },
      alert,
      allowed_file_size
    );

    if (uploaded_file?.[0]?.url) {
      updateState("profileImage", uploaded_file?.[0]?.url);
      backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
    } else {
      backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Some Thing Went Wrong"),
      });
    }
  };

  const mapResultData = (map) => {
    let result = mapResult(map);
    setData({ ...data, ...result });
  };

  const relationshipHidden = [
    "Service Provider",
    "External Vendor Employee",
    "Vendor",
  ];
  const memberList = ["Company Employee", "External Vendor Employee"];

  const vendorCondition = ["External Sales Broker", "External Vendor Employee"];
  const companyShow = ["External Vendor Employee", "Company Employee"];
  const exceptNationallity = [
    "External Vendor Employee",
    "Service Provider",
    "Vendor",
  ];

  const ValidateMobile = (value, key) => {
    const limit = getMobileLimitBasedOnCC(value?.mobile_code);
    if (value?.mobile?.length === limit) {
      let error = data?.error;
      error[key] = "";
      setData({ ...data, error });
      return true;
    } else {
      return false;
    }
  }

  const validate = () => {
    let isValid = true;
    let error = data.error;

    if (data?.isAllCompany && data?.company?.length === 0) {
      isValid = false;
      error.company = t("Company is Required");
    }
    if (data?.name?.length === 0) {
      isValid = false;
      error.name = t("Name is Required");
    }
    if (
      data?.relationship?.value === "Other" &&
      data?.otherRelationship?.length === 0
    ) {
      isValid = false;
      error.otherRelationship = t("Relationship is Required");
    }
    if (!exceptNationallity.includes(data?.relationship?.value)) {
      if (data?.nationality === "") {
        isValid = false;
        error.nationality = t("Nationality is Required");
      }
    }
    if (data?.primaryEmail?.length === 0) {
      isValid = false;
      error.primaryEmail = t("Email ID is Required");
    } else if (!Boolean(ValidateEmail(data?.primaryEmail))) {
      isValid = false;
      error.primaryEmail = t("Invalid Email ID");
    }
    if (data?.primaryMobile?.length === 0 || data?.primaryMobile?.mobile === "") {
      isValid = false;
      error.primaryMobile = t("Mobile Number is Required");
      error.validMobile = "";
    } else if (!ValidateMobile(data?.primaryMobile, "validMobile")) {
      isValid = false;
      error.primaryMobile = "";
      error.validMobile = t("Please enter valid number");
    }
    if (data?.primaryTelephone?.length === 0 || data?.primaryTelephone?.mobile === "") {
      isValid = false;
      error.primaryTelephone = t("Telephone Number is Required");
      error.validTele = "";
    } else if (!ValidateMobile(data?.primaryTelephone, "validTele")) {
      isValid = false;
      error.primaryTelephone = "";
      error.validTele = t("Please enter valid number");
    }
    if (data?.relationship?.length === 0) {
      isValid = false;
      error.relationship = t("Relationship is Required");
    }
    if (type !== "Agent") {
      if (memberList.includes(data?.relationship?.value)) {
        if (data?.Department?.length === 0) {
          isValid = false;
          error.Department = t("OtherDepartment is Required");
        }
      }
    }
    if (
      data?.relationship?.value === "Company Employee" ||
      data?.relationship?.value === "External Vendor Employee"
    ) {
      if (data?.role === undefined) {
        isValid = false;
        error.role = t("Role is Required");
      }
    }

    if (!relationshipHidden.includes(data?.relationship?.value)) {
      if (data?.sex?.length === 0) {
        isValid = false;
        error.sex = t("Gender is Required");
      }
    }

    if (companyShow.includes(data?.relationship?.value)) {
      if (data?.employee_id?.length === 0) {
        isValid = false;
        error.employee_id = t("Company ID is Required");
      }
    }

    if (data?.relationship?.value === "Service Provider") {
      if (data?.serviceProvider?.length === 0) {
        isValid = false;
        error.serviceProvider = t("Service Provider is Required");
      }
    }

    if (
      type === "Agent" ||
      vendorCondition.includes(data?.relationship?.value)
    ) {
      if (data?.vendorlist?.length === 0) {
        isValid = false;
        error.vendorlist = t("Vendor is Required");
      }
    }
    if (!isValid) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Please fill all mandatory field"),
      });
    }

    setData({ ...data, error });
    return isValid;
  };


  const save = () => {
    let currentDate = new Date().toISOString();
    if (ValidateProof(data?.proofFile)) {
      setDisable(true);
      let tempProperties = [];
      let tempDeletedProperties = [];
      let datas = {
        tenantId: `${config.tenantId}`,
        id: data?.id ?? null,
        company_id: data?.company?.value ?? null,
        relationship: data?.relationship?.value ?? null,
        role: data?.role?.value ?? null,
        user_id: data?.userID ?? null,
        user_profile_id: data?.userProfileID ?? null,
        properties: tempProperties ?? null,
        deleteProperties: tempDeletedProperties ?? null,
        image_url: data?.profileImage ?? null,
        first_name: data?.name ?? null,
        job_title: data?.jobTitle ?? null,
        door_no: data?.doorNo ?? null,
        street_1: data?.addressLineOne ?? null,
        street_2: data?.addressLineTwo ?? null,
        landmark: data?.landmark ?? null,
        area: data?.area ?? null,
        city: data?.city ?? null,
        state: data?.state ?? null,
        zipcode: data?.zipcode ?? null,
        latitude: data?.latitude ? parseFloat(data?.latitude) : null,
        longitude: data?.longitude ? parseFloat(data?.longitude) : null,
        preferred_mode_contact: data?.preferred_mode_contact?.value ?? null,
        phone_code: data?.primaryTelephone?.mobile_code ?? null,
        phone: data?.primaryTelephone?.mobile ?? null,
        mobile_no_country_code: data?.primaryMobile?.mobile_code ?? null,
        mobile_no: data?.primaryMobile?.mobile ?? null,
        email_id: data?.primaryEmail
          ? data?.primaryEmail?.trim()?.toLowerCase()
          : null,
        emergency_contact_name: data?.emergencyContactName ?? null,
        emergency_contact_relationship:
          data?.emergencyContactRelationship?.value ?? null,
        emergency_contact_code:
          data?.emergencyContactNumber?.mobile_code ?? null,
        emergency_contact_number: data?.emergencyContactNumber?.mobile ?? null,
        facebook: data?.facebook ?? null,
        twitter: data?.twitter ?? null,
        linkedin: data?.linkedin ?? null,
        nationality: data?.nationality?.label ?? null,
        national_id: data?.nationalID ?? null,
        national_id_expiry: data?.nationalIDExpiry
          ? moment(data?.nationalIDExpiry).format("yyyy-MM-DD")
          : null,
        passport_id: data?.passportID ?? null,
        passport_expiry: data?.nationalIDExpiry
          ? moment(data?.nationalIDExpiry).format("yyyy-MM-DD")
          : null,
        visa_no: data?.visaNumber ?? null,
        visa_expiry: data?.nationalIDExpiry
          ? moment(data?.nationalIDExpiry).format("yyyy-MM-DD")
          : null,
        resident_id: data?.residentID ?? null,
        resident_id_expiry: data?.nationalIDExpiry
          ? moment(data?.nationalIDExpiry).format("yyyy-MM-DD")
          : null,
        bank_name: data?.bankName ?? null,
        branch_name: data?.bankBranchName ?? null,
        bank_currency_id: data?.bankCurrency?.value
          ? data?.bankCurrency?.value
          : null,
        account_type:
          data?.bankAccountType || data?.bankAccountType !== ""
            ? data?.bankAccountType
            : null,
        account_no: data?.bankAccountNumber ?? null,
        routing_type: data?.bankRoutingType?.value
          ? data?.bankRoutingType?.value
          : null,
        routing_code: data?.bankRoutingCode ?? null,
        address_1: data?.bankAddressLineOne ?? null,
        address_2: data?.bankAddressLineTwo ?? null,
        bank_city: data?.bankCity ?? null,
        bank_country: data?.bankCountry?.value ?? null,
        cheque_name: data?.chequeName ?? null,
        cash_collection_office: data?.preferredCashCollectionOffice ?? null,
        is_active: data?.isActive,
        created_by: data?.id
          ? localStorage.getItem(LocalStorageKeys.profileID)
          : null,
        updated_by: localStorage.getItem(LocalStorageKeys.profileID),
        created_at: data?.id ? currentDate : null,
        updated_at: currentDate,
        department_id: data?.Department?.value ?? null,
        department: data?.Department?.label ?? null,
        other_relationship: data?.otherRelationship ?? null,
        reporting_to: data?.member?.id ?? null,
        vendor_id: data?.vendorlist?.value ?? null,
        service_provider_id: data?.serviceProvider?.value ?? null,
        proof: data?.proofFile
          ?.filter((val) => val?.is_active)
          .map((val) => {
            return {
              id: val?.id ?? undefined,
              proof_id: val?.proof_id ?? val?.type?.value,
              proof_type: val?.proof_type ?? val?.type?.proof_type,
              kyc_type: "Both",
              name: val?.name,
              number: val?.id_no,
              valid_from: val?.valid_from,
              valid_to: val?.valid_to,
              url: val?.file,
              is_mandatory: val?.mandatry_verify,
              country_id: val?.issuing_country?.value,
              is_active: val?.is_active,
            };
          }),
        whatsapp: data?.whatsApp ?? null,
        sex_type: data?.sex?.value ?? null,
        employee_id: data?.employee_id ?? null,
        primary_account: data?.primary_account?.value ?? null,
        login_email: data?.login_email?.trim()?.toLowerCase(),
        country_id: data?.country?.value,
        job_role_id: data?.role?.value,
        ap_primary_account_no: data?.ap_primary_account?.value ?? null,
        last_revenue_target: data?.last_revenue_target
          ? parseFloat(data?.last_revenue_target)
          : null,
        current_revenue_target: data?.current_revenue_target
          ? parseFloat(data?.current_revenue_target)
          : null,
        is_resync: state?.main?.isEdit ? true : false,
        company_no: data?.company_no,
      };

      NetworkCall(
        `${config.api_url}/contact/upsert`,
        NetWorkCallMethods.post,
        datas,
        null,
        true,
        false
      )
        .then((response) => {
          navigate(-1);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `${type === "Agent" ? t("Sales Agent") : t("Contact")} ${state?.main?.isEdit
              ? t("Updated successfully.")
              : t("Created successfully.")
              }`,
          });
        })
        .catch((error) => {
          console.error("error", error);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Internal error. Please try again later."),
          });
          setDisable(false);
        });
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Please fill all mandatory field"),
      });
    }
  };

  const checkSave = (type) => {
    if (data?.id) {
      if (permission.update) {
        type === "previous" && previous();
        type === "next" && next();
        type === "save" && save();
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          msg: t("Access denied. Contact your administrator."),
          severity: AlertProps.severity.error,
        });
      }
    } else if (permission.create) {
      type === "previous" && previous();
      type === "next" && next();
      type === "save" && save();
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        msg: t("Access denied. Contact your administrator."),
        severity: AlertProps.severity.error,
      });
    }
  };

  const checkExsits = (email_id, mobile_no, step) => {
    let datas = {
      tenantId: `${config.tenantId}`,
      email_id: email_id,
      mobile_no: mobile_no,
    };
    NetworkCall(
      `${config.api_url}/contact/findExisting`,
      NetWorkCallMethods.post,
      datas,
      null,
      true,
      false
    )
      .then((response) => {
        let temp_already_exist, message = response?.data?.is_Exist?.message;
        if (response?.data?.is_Exist?.isExist) {
          if (message === "Both") {
            temp_already_exist = {
              ...already_exist,
              is_exist: {
                primaryEmail: true,
                primaryMobile: true,
              },
              error: {
                ...already_exist?.error,
                primaryEmail: t("Email ID already exists"),
                primaryMobile: t("Mobile Number already exists"),
              },
            };
            set_already_exist(temp_already_exist);
            setData({
              ...data, error: {
                ...data?.error,
                validMobile: t("Mobile Number already exists"),
              }
            })
            alert.setSnack({
              ...alert,
              open: true,
              msg: t("Email and Mobile Number already exist"),
              severity: AlertProps.severity.warning,
            });
          } else if (message === "Email") {
            temp_already_exist = {
              ...already_exist,
              is_exist: {
                primaryEmail: true,
                primaryMobile: false,
              },
              error: {
                ...already_exist?.error,
                primaryEmail: t("Email ID already exists"),
                primaryMobile: "",
              },
            };
            set_already_exist(temp_already_exist);
            alert.setSnack({
              ...alert,
              open: true,
              msg: t("Email ID already exists"),
              severity: AlertProps.severity.warning,
            });
          } else if (message === "Mobile") {
            temp_already_exist = {
              ...already_exist,
              is_exist: {
                primaryEmail: false,
                primaryMobile: true,
              },
              error: {
                ...already_exist?.error,
                primaryEmail: "",
                primaryMobile: t("Mobile Number already exists"),
              },
            };
            set_already_exist(temp_already_exist);
            setData({
              ...data,
              error: {
                ...data?.error,
                validMobile: t("Mobile Number already exists"),
              }
            })
            alert.setSnack({
              ...alert,
              open: true,
              msg: t("Mobile Number already exist"),
              severity: AlertProps.severity.warning,
            });
          } else {

            temp_already_exist = {
              ...already_exist,
              is_exist: {
                primaryEmail: false,
                primaryMobile: false,
              },
              error: {
                ...already_exist?.error,
                primaryEmail: "",
                primaryMobile: "",
              },
            };
            set_already_exist(temp_already_exist);
            setData({
              ...data,
              error: {
                ...data?.error,
                validMobile: "",
              }
            })
            if (step) {
              setValue(2);
            }
          }


        } else {

          temp_already_exist = {
            ...already_exist,
            is_exist: {
              primaryEmail: false,
              primaryMobile: false,
            },
            error: {
              ...already_exist?.error,
              primaryEmail: "",
              primaryMobile: "",
            },
          };
          setData({
            ...data,
            error: {
              ...data?.error,
              validMobile: "",
            }
          })
          set_already_exist(temp_already_exist);
          if (step) {
            setValue(2);
          }
        }
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
      });
  };

  const updateState = (key, value) => {
    let error = data?.error;

    if (key === "primaryEmail") {
      set_already_exist({
        is_exist: {
          primaryEmail: false,
        },
        error: {
          primaryEmail: "",
        },
      });
    }

    if (key === "primaryTelephone" || key === "primaryMobile") {
      set_already_exist({
        is_exist: {
          primaryMobile: false,
        },
        error: {
          primaryMobile: "",
        },
      })

      const limit = getMobileLimitBasedOnCC(value?.mobile_code);

      if (value?.mobile?.length === limit) {
        setData({ ...data, [key]: value, error });
      } else {

        setData({
          ...data, error: {
            [key]: "Please enter valid number",
          }
        });
      }
    }

    error[key] = "";
    if (key === "relationship") {
      setData({
        ...data,
        [key]: value,
        vendorlist: "",
        nationality: "",
        sex: "",
        Department: "",
        member: "",
        employee_id: "",
        primary_account: "",
        ap_primary_account: "",
        error: initial?.error,
      });
    } else {
      setData({ ...data, [key]: value, error });
    }
  };

  const getContactDetailsByID = () => {
    backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading" });
    let datas = { tenantId: `${config.tenantId}`, id: state?.main?.id };
    NetworkCall(
      `${config.api_url}/contact/get`,
      NetWorkCallMethods.post,
      datas,
      null,
      true,
      false
    )
      .then((res) => {
        let tempContactDetails = res?.data?.data;
        let properties = tempContactDetails?.properties?.map((val) => {
          return { value: val?.id ?? "", label: val?.name ?? "" };
        });
        let initialProperties = properties?.map((x) => x?.value);
        setData({
          id: tempContactDetails?.id,
          isActive: tempContactDetails?.is_active ?? false,
          initialEmail: tempContactDetails?.email_id,
          initialMobile: tempContactDetails?.mobile_no,
          company: tempContactDetails?.company_id
            ? {
              value: tempContactDetails?.company_id,
              label: tempContactDetails?.company_name,
              company_no: tempContactDetails?.company_no,
            }
            : null,
          relationship: tempContactDetails?.relationship
            ? {
              value: tempContactDetails?.relationship,
              label: tempContactDetails?.relationship,
            }
            : null,
          vendor: tempContactDetails?.vendor_id
            ? {
              value: tempContactDetails?.vendor_id,
              label: tempContactDetails?.vendor_name,
            }
            : null,
          userID: tempContactDetails?.user_id ?? null,
          userProfileID: tempContactDetails?.user_profile_id ?? null,
          profileImage: tempContactDetails?.image_url ?? null,
          name: tempContactDetails?.first_name ?? null,
          jobTitle: tempContactDetails?.job_title ?? null,
          properties: properties ?? null,
          initialProperties: initialProperties ?? null,
          deletedProperties: [],
          doorNo: tempContactDetails?.door_no ?? null,
          addressLineOne: tempContactDetails?.street_1 ?? null,
          addressLineTwo: tempContactDetails?.street_2 ?? null,
          landmark: tempContactDetails?.landmark ?? null,
          area: tempContactDetails?.area ?? null,
          city: tempContactDetails?.city ?? null,
          state: tempContactDetails?.state ?? null,
          zipcode: tempContactDetails?.zipcode ?? null,
          latitude: tempContactDetails?.latitude ?? null,
          longitude: tempContactDetails?.longitude ?? null,
          preferred_mode_contact: tempContactDetails?.preferred_mode_contact
            ? {
              value: tempContactDetails?.preferred_mode_contact,
              label: tempContactDetails?.preferred_mode_contact,
            }
            : null,
          primaryTelephone: tempContactDetails?.phone
            ? {
              mobile: tempContactDetails?.phone,
              mobile_code: tempContactDetails?.phone_code,
            }
            : null,
          primaryMobile: tempContactDetails?.mobile_no
            ? {
              mobile: tempContactDetails?.mobile_no,
              mobile_code: tempContactDetails?.mobile_no_country_code,
            }
            : null,
          primaryEmail: tempContactDetails?.email_id ?? null,
          emergencyContactName:
            tempContactDetails?.emergency_contact_name ?? null,
          emergencyContactRelationship:
            tempContactDetails?.emergency_contact_relationship
              ? {
                value: tempContactDetails?.emergency_contact_relationship,
                label: tempContactDetails?.emergency_contact_relationship,
              }
              : null,
          emergencyContactNumber: tempContactDetails?.emergency_contact_number
            ? {
              mobile: tempContactDetails?.emergency_contact_number,
              mobile_code: tempContactDetails?.emergency_contact_code,
            }
            : null,
          facebook: tempContactDetails?.facebook ?? null,
          twitter: tempContactDetails?.twitter ?? null,
          linkedin: tempContactDetails?.linkedin ?? null,
          nationality: tempContactDetails?.nationality
            ? {
              label: tempContactDetails?.nationality,
              value: tempContactDetails?.nationality,
            }
            : "",
          nationalID: tempContactDetails?.national_id ?? null,
          nationalIDExpiry: tempContactDetails?.national_id_expiry
            ? new Date(tempContactDetails?.national_id_expiry)
            : null,
          passportID: tempContactDetails?.passport_id ?? null,
          passportIDExpiry: tempContactDetails?.passport_expiry
            ? new Date(tempContactDetails?.passport_expiry)
            : null,
          residentID: tempContactDetails?.resident_id ?? null,
          residentIDExpiry: tempContactDetails?.resident_id_expiry
            ? new Date(tempContactDetails?.resident_id_expiry)
            : null,
          visaNumber: tempContactDetails?.visa_no ?? null,
          visaNumberExpiry: tempContactDetails?.visa_expiry
            ? new Date(tempContactDetails?.visa_expiry)
            : null,
          bankName: tempContactDetails?.bank_name ?? null,
          bankBranchName: tempContactDetails?.branch_name ?? null,
          bankCurrency: tempContactDetails?.bank_currency_id
            ? {
              value: tempContactDetails?.bank_currency_id,
              label: tempContactDetails?.bank_currency_code,
            }
            : null,
          bankAccountType: tempContactDetails?.account_type ?? null,
          bankAccountNumber: tempContactDetails?.account_no ?? null,
          bankRoutingType: tempContactDetails?.routing_type
            ? {
              value: tempContactDetails?.routing_type,
              label: tempContactDetails?.routing_type,
            }
            : null,
          bankRoutingCode: tempContactDetails?.routing_code ?? null,
          bankAddressLineOne: tempContactDetails?.address_1 ?? null,
          bankAddressLineTwo: tempContactDetails?.address_2 ?? null,
          bankCity: tempContactDetails?.bank_city ?? null,
          bankCountry: tempContactDetails?.bank_country
            ? {
              value: tempContactDetails?.bank_country,
              label: tempContactDetails?.bank_country_name,
            }
            : null,
          chequeName: tempContactDetails?.cheque_name ?? null,
          preferredCashCollectionOffice:
            tempContactDetails?.cash_collection_office ?? null,
          reporting_id: tempContactDetails?.reporting_to ?? null,
          reporting: tempContactDetails?.reporting ?? null,
          Department: tempContactDetails?.department
            ? {
              label: tempContactDetails?.department,
              value: tempContactDetails?.department_id,
            }
            : null,
          otherRelationship: tempContactDetails?.other_relationship ?? null,
          member: {
            id: tempContactDetails?.reporting_to,
            first_name: tempContactDetails?.reporting?.first_name,
            last_name: tempContactDetails?.reporting?.last_name,
            email_id: tempContactDetails?.reporting?.email_id,
            mobile_no: tempContactDetails?.reporting?.mobile_no,
            mobile_no_country_code: tempContactDetails?.reporting?.mobile_no_country_code,
            image_url: tempContactDetails?.reporting?.image_url,
          },
          proofFile: tempContactDetails?.proof?.map((val) => {
            return {
              id: val?.id,
              type: val?.proof_type_2,
              proof_id: val?.proof_id,
              proof_type: val?.proof_type,
              kyc_type: "Both",
              name: val?.name,
              id_no: val?.number,
              valid_from: new Date(val?.valid_from),
              valid_to: new Date(val?.valid_to),
              file: val?.url,
              mandatry_verify: val?.is_mandatory,
              issuing_country: val?.country,
              is_active: val?.is_active,
            };
          }),
          error: initial?.error,
          sex: tempContactDetails?.sex_type
            ? {
              value: tempContactDetails?.sex_type,
              label: tempContactDetails?.sex_type,
            }
            : null,
          whatsApp: tempContactDetails?.whatsapp ?? null,
          employee_id: tempContactDetails?.employee_id ?? "",
          vendorlist: {
            label: tempContactDetails?.vendor_name,
            value: tempContactDetails?.vendor_id,
          },
          serviceProvider: {
            label: tempContactDetails?.service_provider?.name,
            value: tempContactDetails?.service_provider?.id,
          },
          primary_account: tempContactDetails?.primary_account_no
            ? {
              label: concat_string(
                {
                  account_no: tempContactDetails?.ar_account_no,
                  name: tempContactDetails?.account_name,
                },
                ["account_no", "name"],
                " - ",
                "-"
              ),
              value: tempContactDetails?.account_id,
            }
            : "",
          country: {
            value: tempContactDetails?.country_id,
            label: tempContactDetails?.country_name,
          },
          login_email: tempContactDetails?.login_email,
          role: {
            value: tempContactDetails?.job_id,
            label: tempContactDetails?.job_name,
          },
          ap_primary_account: tempContactDetails?.ap_primary_account_no
            ? {
              label: concat_string(
                {
                  account_no: tempContactDetails?.ap_account_no,
                  name: tempContactDetails?.ap_account_name,
                },
                ["account_no", "name"],
                " - ",
                "-"
              ),
              value: tempContactDetails?.ap_account_id,
            }
            : "",
          last_revenue_target: tempContactDetails?.last_revenue_target ?? null,
          current_revenue_target:
            tempContactDetails?.current_revenue_target ?? null,
        });
        setLoad(true);
        backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
      })
      .catch((error) => {
        backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
      });
  };

  const getContact = () => {
    if (state?.main?.isEdit) {
      getContactDetailsByID();
    } else {
      setLoad(true);
    }
  };

  const deletedValue = (value, updatedvalue) => {
    setData({
      ...data,
      deletedProperties: [...data?.deletedProperties, value],
      properties: updatedvalue,
    });
  };

  const removeimg = () => {
    setData({ ...data, profileImage: "" });
  };
  const ValidateProof = (object) => {
    let isValid = true;

    let newObj = object
      ?.filter((val) => val?.is_active)
      ?.filter((val) => val?.type !== undefined);

    const result = newObj?.map((data) => {
      let error = data?.error ?? {};
      if (data?.id_no === "") {
        error.id_no = "ID Proof Number is Required";
      }
      if (data?.valid_to === "") {
        error.valid_to = "Valid To is Required";
      }
      if (data?.issuing_country === "") {
        error.issuing_country = "Issuing Country is Required";
      }
      if (data?.mandatry_verify === "" || data?.mandatry_verify === null) {
        error.mandatry_verify = "Mandatory Verify is Required";
      }

      return {
        ...data,
        error: error,
      };
    });

    isValid =
      result?.filter((i) => Object.keys(i?.error)?.length > 0)?.length === 0
        ? true
        : false;

    return isValid;
  };

  const next = () => {
    if (value === 1) {
      if (validate() && !already_exist?.is_exist?.primaryEmail && !already_exist?.is_exist?.primaryMobile) {

        if (data?.primaryEmail?.trim() === data?.initialEmail?.trim()
          && data?.primaryMobile?.mobile === data?.initialMobile) {

          setValue(2);
        } else {
          checkExsits(
            (data?.primaryEmail?.trim() === data?.initialEmail?.trim()) ? "" : data?.primaryEmail?.trim(),
            (data?.primaryMobile?.mobile === data?.initialMobile) ? "" : data?.primaryMobile?.mobile,
            2
          );
        }
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Please fill all mandatory field"),
        });
        return false;
      }
    }
  };

  const previous = () => {
    setValue((prevValue) => prevValue - 1);
    if (!state?.main?.isEdit) {
      setMapLoad(false);
    }
  };
  const editTitle = {
    Agent: t("Edit Sales Agent"),
    Contact: t("Edit Contact"),
  };
  const createTitle = {
    Agent: t("Create New Sales Agent"),
    Contact: t("Create New Contact"),
  };
  const render = () => {
    return (
      <>
        <Subheader
          title={state?.main?.isEdit ? editTitle?.[type] : createTitle?.[type]}
          goBack={() => navigate(-1)}
          previousAndNextBtn={true}
          previousBtn={() => checkSave("previous")}
          nextBtn={() => checkSave("next")}
          previousBtnDisabled={value <= 1 && true}
          nextBtnDisabled={value === 2 ? true : false}
        />
        <PropertyTabs
          value={value}
          backgroundColor
          t={t}
          tab1={
            <Box className={classes.root2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <GeneralDetails
                    t={t}
                    id="pushTitle"
                    data={data}
                    updateState={updateState}
                    updateimg={updateimg}
                    mapResult={mapResultData}
                    mapLoad={mapLoad}
                    load={load}
                    deletedValue={deletedValue}
                    removeimg={removeimg}
                    type={type}
                    already_exist={already_exist}
                  />
                </Grid>
                <Grid item xs={12}>
                  <NationalIDDetails
                    t={t}
                    data={data}
                    updateState={updateState}
                  />
                </Grid>
              </Grid>
            </Box>
          }
          tab2={
            <Box className={classes.root2}>
              <ContactProof
                proofType={proofType}
                t={t}
                data={data?.proofFile?.filter((val) => val?.is_active)}
                update={(data) => updateState("proofFile", data)}
              />
            </Box>
          }
          isHeigth
          tabTittle={tabTittle}
          showBtns={true}
          isSaveDisable={disable === true ? true : value === 2 ? false : true}
          saveBtnText={state?.main?.isEdit ? t("Update") : t("Submit")}
          goBack={() => navigate(-1)}
          save={() => checkSave("save")}
        />
      </>
    );
  };
  return <>{accessCheckRender(render, permission)}</>;
};
export default withTranslation("contacts")(CreateContactNew);
