import { makeStyles } from '@mui/styles';
import { FontFamilySwitch } from '../../utils';

export const ConfirmationDialogStyles = makeStyles({
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1300,
  },
  confirmationBox: {
    width: '456px',
    height: '342px',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  },
  iconContainer: {
    backgroundColor: '#5078E126',
    borderRadius: '50%',
    width: '64px',
    height: '64px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: '20px',
    fontWeight: 600,
    fontFamily: FontFamilySwitch().extraBold,
    marginBottom: '16px',
    color: '#333',
    textAlign: 'center',
  },
  message: {
    fontSize: '16px',
    fontFamily: FontFamilySwitch().regular,
    color: '#666',
    marginBottom: '32px',
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    gap: '51px',
    justifyContent: 'center',
    width: '100%',
  },
  confirmButton: {
    width: '168px',
    backgroundColor: '#5078E1',
    color: 'white',
    fontSize: '1rem',
    fontFamily: FontFamilySwitch().regular,
    padding: '12px 27px',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#3d5cb8',
    },
  },
  cancelButton: {
    width: '168px',
    backgroundColor: '#f5f5f5',
    border: '1px solid #98A0AC',
    color: '#98A0AC',
    fontSize: '1rem',
    fontFamily: FontFamilySwitch().regular,
    padding: '12px 27px',
    borderRadius: '8px',
    cursor: 'pointer',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
  },
});
