import { Box, Grid, Stack, Typography } from "@mui/material";
import { withTranslation } from "react-i18next";
import {
  DetailsCardCoponent,
  DialogBox,
  FormGenerator,
} from "../../../components";
import React from "react";
import { AggrementTabStyles } from "./styles";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";
import {
  AlertProps,
  LocalStorageKeys,
  ValidateEmail,
  timeZoneConverter,
} from "../../../utils";
import { AlertContext } from "../../../contexts";
import { OwnerDetails } from "./owner";
const AgreementInformation = ({
  details = {},
  t,
  reload = () => false,
  data,
  updateState = () => false,
  updateEmails = () => false,
  setIsValid = () => false,
  id,
}) => {
  const classes = AggrementTabStyles();
  const alert = React.useContext(AlertContext);
  const [open, setOpen] = React.useState(false);

  const checkEmail = (value) => {
    if (value?.length) {
      if (!ValidateEmail(value)) {
        setIsValid(true);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Enter Valid Email"),
        });
      } else {
        setIsValid(false);
      }
    }
  };
  const card = [
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 3,
      },
      isActive: true,
      component: data?.is_edit ? "input" : "input_text",
      heading: t("agreementNumber"),
      subtitle: `${data?.agreement_no}`,
      onChange: (e) => updateState("agreement_no", e),
      is_edit: data?.is_edit,
      onClick: (e) => updateState("is_edit", true),
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 3,
      },
      isActive: true,
      component: "date",
      heading: t("agreementStartDate"),
      subtitle: `${details?.lease_start_date}`,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 3,
      },
      isActive: true,
      component: "date",
      heading: t("agreementEndDate"),
      subtitle: `${details?.lease_end_date}`,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 3,
      },
      isActive: true,
      component: "text",
      heading: t("agreementType"),
      subtitle: `${details?.agreement_type ?? "-"}`,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 3,
      },
      isActive: true,
      component: "text",
      heading: t("agreementRevenueType"),
      subtitle: `${details?.quotation?.revenue_type ?? "-"}`,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 3,
      },
      isActive: true,
      component: "owner",
      heading: data?.owner?.id ? t("Agreement Owner") : "",
      btnText: t("Add Owner"),
      subtitle: `${details?.quotation?.revenue_type ?? "-"}`,
      onDelete: (e) => updateState("owner", null),
      onClick: () => setOpen(true),
      value: data?.owner,
      is_disable: details?.agreement_trackers?.length,
    },
  ];

  const manualResponse = (array) => {
    const details = array?.map((i) => {
      return {
        ...i,
        label: `${i?.title} - ${i?.project_no}`,
        value: i?.id,
      };
    });
    return details;
  };
  const manualResponseContract = (array) => {
    const details = array?.map((i) => {
      return {
        label: `${i?.label} - ${i?.account?.name}`,
        value: i?.value,
      };
    });
    return details;
  };
  const manualResponseAccount = (array) => {
    const details = array?.map((i) => {
      return {
        ...i,
        label: `${i?.label} - ${i?.account_no}`,
        value: i?.value,
      };
    });
    return details;
  };
  return (
    <Box p={2}>
      <DetailsCardCoponent components={card} />
      <Box mt={2} className={classes.root}>
        <FormGenerator
          components={[
            {
              isActive: true,
              component: "date",
              label: t("Agreement Sign Date"),
              value: data?.agreement_sign_date,
              placeholder: t("Agreement Sign Date"),
              onChange: (e) => updateState("agreement_sign_date", e),

              size: {
                xs: 12,
                sm: 12,
                md: 4,
                lg: 3,
              },
            },
            {
              isActive: true,
              component: "text",
              label: t("Legacy Contract ID"),
              value: data?.legacy_contract_id,
              placeholder: t("Legacy Contract ID"),
              onChange: (e) =>
                updateState("legacy_contract_id", e?.target?.value),

              size: {
                xs: 12,
                sm: 12,
                md: 4,
                lg: 3,
              },
            },
            {
              isActive: true,
              component: "date",
              label: t("Standard Warranty Date"),
              value: new Date(data?.warranty_date),
              placeholder: t("Standard Warranty Date"),
              onChange: (e) => updateState("warranty_date", e),

              size: {
                xs: 12,
                sm: 12,
                md: 4,
                lg: 3,
              },
              minDate: details?.occpation_date
                ? new Date(details?.occpation_date)
                : false,
              is_full_year:
                details?.agreement_type === "Sale Agreement" ? true : false,
            },
            {
              isActive: true,
              component: "select",
              label: t("SLA Contract Account"),
              value: data?.sla_account_id,
              placeholder: t("SLA Contract Account"),
              onChange: (e) => updateState("sla_account_id", e),
              size: {
                xs: 12,
                sm: 12,
                md: 4,
                lg: 3,
              },
              isClearable: true,
              customComponent: (props) => {
                return (
                  <Stack
                    direction={"column"}
                    spacing={1}
                    className={
                      props?.isSelected
                        ? classes.selected_account_root
                        : classes.account_root
                    }
                  >
                    <Typography className={classes.account_sub}>
                      {props?.label}
                    </Typography>
                    {(props?.data?.address?.state ||
                      props?.data?.address?.country) && (
                      <Stack
                        direction={"row"}
                        spacing={1}
                        alignItems={"center"}
                      >
                        <Typography className={classes.account_sub}>
                          {props?.data?.address?.state}
                        </Typography>
                        {props?.data?.address?.state &&
                          props?.data?.address?.country && (
                            <Box className={classes.dot} />
                          )}

                        <Typography className={classes.account_sub}>
                          {props?.data?.address?.country}
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                );
              },
              isPaginate: true,
              error: data?.error?.sla_account_id,
              fontSize: "14px !important",
              loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                  "queries/account",
                  {
                    company_id: JSON.parse(
                      localStorage.getItem(LocalStorageKeys?.selectedCompany)
                    )?.value,
                    account_type: ["AR"],
                    is_account: true,
                  },
                  search,
                  array,
                  handleLoading,
                  "data",
                  {},
                  manualResponseAccount
                ),
              debounceTimeout: 800,
            },
            {
              isActive: true,
              component: "select",
              label: t("SLA Contract ID"),
              value: data?.sla_contract_id,
              placeholder: t("SLA Contract ID"),
              onChange: (e) => updateState("sla_contract_id", e),
              isClearable: true,
              size: {
                xs: 12,
                sm: 12,
                md: 4,
                lg: 3,
              },
              isPaginate: true,
              error: data?.error?.unit_type,
              fontSize: "14px !important",
              loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                  "contract/get_all_contract",
                  {
                    company_id: JSON.parse(
                      localStorage.getItem(LocalStorageKeys?.selectedCompany)
                    )?.value,
                    contract_type: [3],
                    account_id: data?.sla_account_id?.value,
                    start_date:
                      details?.agreement_type === "Sale Agreement"
                        ? timeZoneConverter(
                            details?.lease_start_date,
                            "YYYY-MM-DD"
                          )
                        : "",
                    end_date:
                      details?.agreement_type === "Sale Agreement"
                        ? timeZoneConverter(
                            details?.lease_end_date,
                            "YYYY-MM-DD"
                          )
                        : "",
                  },
                  search,
                  array,
                  handleLoading,
                  "data",
                  {},
                  manualResponseContract
                ),
              debounceTimeout: 800,
              key: JSON.stringify(data?.sla_account_id),
            },
            {
              isActive: true,
              component: "select",
              label: t("SLA Project ID"),
              value: data?.project_id,
              placeholder: t("SLA Project ID"),
              onChange: (e) => updateState("project_id", e),
              isPaginate: true,
              isClearable: true,
              size: {
                xs: 12,
                sm: 12,
                md: 4,
                lg: 3,
              },
              loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                  "project/drop_down",
                  {
                    company_id: JSON.parse(
                      localStorage.getItem(LocalStorageKeys?.selectedCompany)
                    )?.value,
                    is_agreement: true,
                    contract_id: data?.sla_contract_id?.value,
                  },
                  search,
                  array,
                  handleLoading,
                  "data",
                  {},
                  manualResponse
                ),
              debounceTimeout: 800,
              key: JSON.stringify(data?.sla_contract_id),
            },
            {
              isActive: true,
              component: "text",
              label: t("SLA hours"),
              value: data?.sla_hours,
              placeholder: t("SLA hours"),
              onChange: (e) => updateState("sla_hours", e?.target?.value),
              type: "number",
              size: {
                xs: 12,
                sm: 12,
                md: 4,
                lg: 3,
              },
              endAdornment: (
                <Typography className={classes.sub1}>Hrs</Typography>
              ),
            },
            {
              size: {
                xs: 3,
                sm: 3,
                md: 3,
                lg: 3,
              },
              isActive: true,
              component: "toggle",
              label: "Invite Mail Trigger",
              value: data?.is_mail_trigger ?? true,
              placeholder: "Email Trigger",
              onChange: (value) => updateState("is_mail_trigger", value),
              error: data?.error?.is_mail_trigger,
              isRequired: true,
              options: [
                {
                  label: t("Yes"),
                  value: true,
                },
                {
                  label: t("No"),
                  value: false,
                },
              ],
              marginBottom: "11px !important",
            },
            {
              size: {
                xs: 3,
                sm: 3,
                md: 3,
                lg: 3,
              },
              isActive: true,
              component: "toggle",
              label: t("Renewal"),
              value: data?.renewal ?? true,
              placeholder: "Renewal",
              onChange: (value) => updateState("renewal", value),
              error: data?.error?.renewal,
              marginBottom: "11px !important",
              isRequired: true,
              options: [
                {
                  label: t("Yes"),
                  value: true,
                },
                {
                  label: t("No"),
                  value: false,
                },
              ],
            },
            {
              size: {
                xs: 3,
                sm: 3,
                md: 3,
                lg: 3,
              },
              isActive: true,
              component: "toggle",
              label: t("Allow Extension"),
              value: data?.allow_extension ?? true,
              placeholder: "Allow Extension",
              onChange: (value) => updateState("allow_extension", value),
              error: data?.error?.allow_extension,
              marginBottom: "11px !important",
              isRequired: true,
              options: [
                {
                  label: t("Yes"),
                  value: true,
                },
                {
                  label: t("No"),
                  value: false,
                },
              ],
            },
          ]}
        />
      </Box>
      <Box mt={2} className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography className={classes.title}>{t("Email Copies")}</Typography>
          </Grid>
          {data?.agreement_email?.map((c, i) => {
            return (
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <FormGenerator
                  components={[
                    {
                      isActive: true,
                      component: "text",
                      label: t(c?.label),
                      value: c?.value,
                      placeholder: t(c?.label),
                      onChange: (e) => updateEmails(i, e?.target?.value),
                      onBlur: () => checkEmail(c?.value),
                      size: {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                      },
                    },
                  ]}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <DialogBox
        background
        header={t("Add Agreement Owner")}
        open={open}
        onClose={() => setOpen(false)}
        component={
          <OwnerDetails
            agreement_id={id}
            onChange={(e) => updateState("owner", e)}
            value={data?.owner}
            onClose={() => setOpen(false)}
            t={t}
          />
        }
        maxWidth="sm"
      />
    </Box>
  );
};
export default withTranslation("agreement")(AgreementInformation);
