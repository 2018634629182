import React, { useState, useEffect, useLayoutEffect } from "react";
import { MapComponent } from "./components";
import { AlertContext } from "../../contexts";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import Dialog from '@mui/material/Dialog';

import { UseDebounce } from "../../components";

const initial = {
  location: "",
  status: "Vacant",
  area: "Total Area",
  selectedProperty: { label: "All", value: "All" }
};
export const Map = (props) => {
  const { t } = props
  const [mapLoading, setMapLoading] = useState(true);
  const [locationDetails, setLocationDetails] = useState("");
  const [state, setState] = useState({ ...initial });
  const [zoom, setZoom] = useState(false);

  //debounce hook
  const debounce = UseDebounce();

  // context
  const alert = React.useContext(AlertContext);
  //   update state
  const updateState = (key, value) => {
    if (key === "status") {
      getLocation(value, state?.location?.latitude, state?.location?.longitude, state?.selectedProperty);
      setState({ ...state, [key]: value });
    }
    else if (key === "selectedProperty") {
      getLocation(state?.status, state?.location?.latitude, state?.location?.longitude, value);
      setState({ ...state, [key]: value });
    }
    else {
      getLocation(state?.status, value?.latitude, value?.longitude, state?.selectedProperty);
      setState({ ...state, [key]: value });
    }
  };
  // get location
  const getLocation = (e, lat, long, selected_property) => {
    // if (!zoom) {
    //   backdrop.setBackDrop({
    //     ...backdrop,
    //     open: true,
    //     message: "Getting Map View ...",
    //   });
    // }
    let teamsId = props?.selectedTeams?.value;

    setMapLoading(true);
    const payload = {
      company_id: [props?.selectedcompany?.value],
      status: e ?? undefined,
      lat: lat ?? undefined,
      long: long ?? undefined,
      selected_property: selected_property?.value === "All" ? undefined : selected_property?.value
    };
    if (teamsId !== "noteams") {
      payload["team"] = teamsId
      payload["team_all_property"] = true

    }
    NetworkCall(
      `${config.api_url}/dashboard/property/location`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setLocationDetails({
          data: res?.data?.data,
          total: selected_property?.value === "All" ? res?.data?.data?.map((item) => parseInt(item?.total_units))
            .reduce((a, b) => a + b, 0) : res?.data?.total
          ,
        });
        // backdrop.setBackDrop({
        //   ...backdrop,
        //   open: false,
        //   message: "",
        // });
        setMapLoading(false);
      })
      .catch((error) => {
        // backdrop.setBackDrop({
        //   ...backdrop,
        //   open: false,
        //   message: "",
        // });
        setMapLoading(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  //
  const sumbit = () => {
    getLocation(
      state?.status,
      state?.location?.latitude,
      state?.location?.longitude
    );
  };
  // zoom function
  const zoomGraphFunction = (e) => {
    setZoom(true);
    setMapLoading(false);
  };
  const handleCloseModule = () => {
    setZoom(false);
    setState({ ...initial })
    getLocation(state?.status)
  };

  //   useEffect
  useLayoutEffect(() => {
    if (props?.selectedcompany?.label?.length > 0) {
      getLocation(state?.status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.selectedTeams]);

  useEffect(() => {
    debounce(() => getLocation(state?.status), 800);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      <MapComponent
        title={""}
        mapData={locationDetails?.data ?? []}
        total={locationDetails?.total}
        updateState={updateState}
        data={state}
        tabNameState={state?.status}
        tabList={[t("Vacant"), t("Occupied")]}
        keyType={"status"}
        mapLoading={mapLoading}
        sumbit={sumbit}
        height={"100%"}
        onZoomClick={zoomGraphFunction}
        isZoom
        flexFlow={"column"}
        t={t}
      />
      {/* zoom dialog for each graph */}
      <Dialog
        fullScreen
        open={zoom}
      >
        {
          zoom &&
          <MapComponent
            is_close
            title={t("Map View")}
            mapData={locationDetails?.data ?? []}
            total={locationDetails?.total ?? 0}
            updateState={updateState}
            data={state}
            tabNameState={state?.status}
            tabList={["Vacant", "Occupied"]}
            keyType={"status"}
            zoom={zoom}
            mapLoading={!zoom}
            sumbit={sumbit}
            height={"calc(100vh - 10px)"}
            onZoomClick={handleCloseModule}
            t={t}
            company_id={props?.selectedcompany?.value}
          />
        }

      </Dialog>
    </>
  );
};
