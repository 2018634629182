import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, Divider, Grid, InputAdornment, Menu, MenuItem, OutlinedInput, Stack, Typography } from '@mui/material';
import Tooltip from "@mui/material/Tooltip";
import moment from 'moment';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import CalendarIcon from '../../assets/calendar';
import ClockArrow from '../../assets/clockArrow';
import ClockIcon from '../../assets/clockIcon';
import CloseIcon from '../../assets/closeIcon';
import { CurrencyIcon } from '../../assets/currencyIcon';
import { Male } from "../../assets/male";
import StarIcon from '../../assets/starIcon';
import { config } from '../../config';
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from '../../networkcall';
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, getCompanyOption } from '../../utils';
import { loadOptionsApis } from '../../utils/asyncPaginateLoadOptions';

import { initialState } from "../../utils/moveoutInspection";
import CalendarWithTime from '../calendarWithTime';
import { CustomSelectOptions } from "../customOptions";
import { CustomSelect } from '../filterGenerator/components';
import { TextBox } from '../textbox';
import { PopupCard } from './card';
import { AssignCompenentStyles } from './styles';
import { CustomLoadOptionsApis } from './utils';

const ReAssignModal = (props) => {
  const { t, disable } = props
  const classes = AssignCompenentStyles();
  const [company, setCompany] = React.useState([]);
  const auth = React.useContext(AuthContext);
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const [editHours, setEditHours] = useState(false)
  const [edit, setEdit] = useState(false)
  const [anchorEl, setAnchorEl] = useState(false)
  const datePickerOpen = Boolean(anchorEl)
  const [assignData, setAssignData] = useState({ ...initialState })
  const clientId = localStorage.getItem(LocalStorageKeys.clinetID);


  // const removeData = (id) => {
  //     const filteredData = assignData?.otherResources?.filter((item) => { return item.resource_id !== id })
  //     const otherResourceEstimatedCost = filteredData?.length > 0 && filteredData.reduce((accumulator, object) => {
  //         return accumulator + object.total_rate;
  //     }, 0);
  //     assignData["totalHours"] = assignData?.hours * (filteredData?.length + (assignData?.resource?.total_rate ? 1 : 0))
  //     assignData["estimatedAmount"] = Number(assignData?.resource?.total_rate ? assignData?.resource?.total_rate : 0) + Number(otherResourceEstimatedCost)
  //     setAssignData({ ...assignData, otherResources: filteredData })
  // }
  //To edit hours
  const onEditHours = (type) => {
    if (type === "edit") {
      setEditHours(false)
      setEdit(true)
    }
    else if (type === "check") {
      setEditHours(true)
      setEdit(false)
    }

  }

  //get assign details 
  const getAssignDetails = (company_id, module_id) => {

    const payload = {
      agreement_inspection_id: props?.agreement_inspection_id
    }
    NetworkCall(
      `${config.api_url}/agreement_inspection/get_assign_resource`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((res) => {
      const data = res?.data?.data
      let otherResources = data?.child_resources?.map((x) => {
        return {
          ...x,
          total_rate: x.hourly_charge * data?.data?.total_hours
        }
      })
      const otherResourceEstimatedCost = otherResources?.length > 0 && otherResources.reduce((accumulator, object) => {
        return accumulator + object.total_rate;
      }, 0);
      assignData["totalHours"] = data?.data?.total_hours * (otherResources?.length + (data?.parent_resources?.[0]?.hourly_charge ? 1 : 0))
      assignData["estimatedAmount"] = Number(data?.parent_resources?.[0]?.hourly_charge * data?.data?.total_hours ? data?.parent_resources?.[0]?.hourly_charge * data?.data?.total_hours : 0) + Number(otherResourceEstimatedCost)
      const assigndateTime = new Date(data?.data?.start_date_time)
      const minutes = 30;
      const ms = 1000 * 60 * minutes;
      const RoundOffTime = new Date(Math.ceil(assigndateTime.getTime() / ms) * ms);
      setAssignData({
        ...assignData,
        dateTime: RoundOffTime,
        hours: data?.data?.total_hours,
        agreement_inspection_id: props?.agreement_inspection_id,
        disable: (new Date() > new Date(data?.execution)) ? true : false,
        moduleId: module_id,
        company: company_id,
        resource_group: { label: data?.parent_resources?.[0]?.resource_group_master_name, value: data?.parent_resources?.[0]?.resource_group_master_id },
        resource: { ...data?.parent_resources?.[0], total_rate: (data?.parent_resources?.[0]?.hourly_charge * data?.data?.total_hours) },
        otherResources: otherResources
      })
    }).catch((err) => {
      console.log(err)
    })
  }

  React.useEffect(() => {
    let company = getCompanyOption(backdrop, auth, alert);
    const authData = auth.auth.auth.access[localStorage.getItem(LocalStorageKeys.activeRole)]
    if (company) {
      setCompany(company?.list);
      if (props?.reassign) {
        getAssignDetails(company?.selected, authData?.module_id)
      } else {
        //roundoff to nearest 30 mins
        setAssignData({
          ...assignData,
          company: company?.selected,
          moduleId: authData?.module_id,
          agreement_inspection_id: props?.agreement_inspection_id,
          dateTime: new Date(),
        })
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  //handleChange
  const updateState = (key, value, isPrimary) => {
    assignData[key] = value
    let error = assignData.error;
    error[key] = "";
    if (key === "company") {
      assignData[key] = value
      setAssignData({ ...assignData, isPrimary: isPrimary, department: "", role: "", resource_group: { label: "", value: null }, error })
    }
    else {
      assignData.isPrimary = isPrimary

      if (key === "resource" || key === "otherResources" || key === "hours") {
        let totalhr = key === "hours" ? value : assignData?.hours

        if (props?.reassign) {
          assignData["resource"] = { ...assignData.resource, total_rate: (assignData.resource?.hourly_charge * totalhr) }

        }
        const otherResourceEstimatedCost = assignData?.otherResources?.length > 0 && assignData?.otherResources.reduce((accumulator, object) => {
          return accumulator + object.total_rate;
        }, 0);
        assignData["totalHours"] = totalhr * (assignData.otherResources?.length + (assignData?.resource?.total_rate ? 1 : 0))
        assignData["estimatedAmount"] = Number(assignData?.resource?.total_rate ? assignData?.resource?.total_rate : 0) + Number(otherResourceEstimatedCost)
      }
      setAssignData({ ...assignData, error })
    }
  }
  //validate 
  const validate = () => {
    let isValid = true;
    //validate name
    if (!assignData?.resource?.name) {
      isValid = false;
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Please Select Resource"),
      });

    }
    return isValid
  }

  const getHour = (date) => {
    const dates = new Date(date);
    const hour = dates.getHours();
    const mins = dates.getMinutes();
    const result = `${hour < 10 ? `0${hour}` : hour}:${mins < 10 ? `0${mins}` : mins}`
    return result
  }


  //on submit 
  const onSubmit = () => {
    if (validate()) {
      let otherResource_id = assignData?.otherResources?.filter(i => i?.resource_id !== assignData?.resource?.resource_id).map((x) => x?.resource_id)
      const payload = {
        client: clientId,
        agreement_inspection_id: assignData?.agreement_inspection_id,
        parent_resource_id: assignData?.resource?.resource_id,
        resource_ids: otherResource_id,
        start_date_time: moment(assignData?.dateTime).format("YYYY-MM-DDTHH:mm:ssZ"),
        hours: parseFloat(assignData?.hours),

        flexible: assignData?.schedulingType === "flexible" ? true : false,

      }
      props?.onSubmit(payload)
    }
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <Box className={classes.main}>
      <Box p={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"} className={classes.topNav}>
        <Typography className={classes.tophdrTitle}>{props.header}</Typography>
        <Box style={{ cursor: "pointer" }} onClick={() => props.handleClose()}><CloseIcon /></Box>
      </Box>
      <Grid container className={classes.formRoot}>
        <Grid item xs={12} className={classes.section1} >
          <Box className={classes.section1header}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Typography className={classes.modalName}>{props?.moduleName}</Typography>
              </Grid>
              <Grid item xs={8}>
                <CustomSelect
                  noSearch
                  options={company}
                  isToolTip={true}
                  isReadOnly={props?.isReadOnly}
                  // color="white"
                  placeholder={t("companyName")}
                  value={assignData?.company}
                  onChange={(value) => {
                    updateState("company", value, true)
                  }} />
              </Grid>
            </Grid>



          </Box>
          <Box className={classes.steps}>
            <Typography className={classes.stepTitle}>{t("step1")}</Typography>
          </Box>
          <Box>
            <Stack direction="row" spacing={2} alignItems="center">
              <Grid item xs={6} className={classes.subHeader}  >
                <Box className={classes.datePicker}>
                  <Box><CalendarIcon className={classes.icon} /></Box>
                  <Box flexGrow={1} marginLeft={"8px"}>
                    <Typography className={classes.datePickerText}>{t("startDateandTime")}</Typography>
                  </Box>
                  <Box>
                    <Tooltip title={t("Choose start date & time")} placement="top" arrow>
                      <Box>
                        <InfoOutlinedIcon className={classes.icon} />
                      </Box>
                    </Tooltip>
                  </Box>
                </Box>

                <Box>
                  {/* <DateTimePicker
                                    isReadOnly={disable}
                                    handleDateChange={(value) => updateState("dateTime", value)}
                                    dateTime={assignData?.dateTime}
                                /> */}
                  <Box onClick={(event) => setAnchorEl(event.currentTarget)}>
                    {/* <TextBox label={null}
                                            placeholder=''
                                            border={0}
                                            isReadonly={disable}
                                            // endAdornment={<InputAdornment position="end">{t("hr")}</InputAdornment>}
                                            color="white"
                                            value={moment(assignData?.dateTime).format("DD MMM YY HH:mm a")}
                                            isError={assignData?.error?.dateTime?.length > 0}
                                            errorMessage={assignData?.error?.dateTime}
                                        /> */}
                    <Typography className={classes.dateTimePicker}
                    >&#x202a;{moment(assignData?.dateTime ?? new Date()).local().format("DD MMM YY HH:mm a")}&#x202c;</Typography>
                  </Box>

                  <Menu
                    anchorEl={anchorEl}
                    open={datePickerOpen}
                    className={classes.menublock}
                    onClose={handleClose}
                    PaperProps={{ sx: { width: "460px", backgroundColor: "white", border: "1px solid #E4E8EE", boxShadow: "0px 0px 16px #00000014" } }}
                  >
                    <MenuItem className={classes.menuList} disableRipple>
                      <CalendarWithTime
                        handleDateChange={(value) => updateState("dateTime", value)}
                        dateTime={assignData?.dateTime}
                        setAnchorEl={setAnchorEl}
                        inspection={true}

                      />
                    </MenuItem>
                  </Menu>
                </Box>
              </Grid>
              <Grid item xs={6} className={classes.subHeader} >
                <Box className={classes.datePicker}>
                  <Box><ClockIcon className={classes.icon} /></Box>
                  <Box flexGrow={1} marginLeft={"8px"}>
                    <Typography className={classes.datePickerText}>{t("projectedHours")}</Typography>
                  </Box>
                  <Box>
                    <Tooltip title={t("toolTipTextForHours")} placement="top" arrow>
                      <Box>
                        <InfoOutlinedIcon className={classes.icon} />
                      </Box>
                    </Tooltip>
                  </Box>
                </Box>
                <Box>
                  <TextBox label={null}
                    placeholder=''
                    border={0}
                    type='number'
                    isReadonly={disable}
                    endAdornment={<InputAdornment position="end">{t("hr")}</InputAdornment>}
                    color="white"
                    onChange={(e) => updateState("hours", e.target.value, true)}
                    value={assignData?.hours}
                    isError={assignData?.error?.hours?.length > 0}
                    errorMessage={assignData?.error?.hours}
                  />
                </Box>
              </Grid>

            </Stack>
            <Box mt={2} display="flex" justifyContent={"space-between"} className={classes.subHeader}>
              <Stack direction={"row"} spacing={1} alignItems="center">
                <ClockIcon className={classes.icon} />
                <Box>
                  <Typography className={classes.datePickerText}>{t("scheduling")}</Typography>
                </Box>
                {/* <Box> */}
                <Tooltip title={t("chooseSchedulingType")} placement="top" arrow>

                  <InfoOutlinedIcon className={classes.icon} />

                </Tooltip>
                {/* </Box> */}
              </Stack>
              <Stack direction="row" spacing={2} className={classes.schedule}>
                <Grid item xs={6}
                  textAlign={"center"}
                  className={assignData?.schedulingType === "flexible" ? classes.schedulefix : classes.schedulestrict}
                  onClick={() => updateState("schedulingType", "flexible")}
                >
                  {t("Flexible")}
                </Grid>
                <Grid item xs={6}
                  textAlign={"center"}
                  className={assignData?.schedulingType === "strict" ? classes.schedulefix : classes.schedulestrict}
                  onClick={() => updateState("schedulingType", "strict")}
                >
                  {t("Strict")}
                </Grid>
              </Stack>
            </Box>
          </Box>
          <Divider className={classes.dividerstyle} />
          {assignData?.hours?.length !== 0 &&
            <>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box className={classes.steps}>
                  <Typography className={classes.stepTitle}>{t("step2")}</Typography>
                </Box>
                <Box className={classes.step2}>
                  <Stack direction="row" spacing={1} alignItems="center">

                    <StarIcon className={classes.icon} />
                    <Typography className={classes.PrimaryTitle}>{t("primaryResource")}</Typography>
                  </Stack>
                </Box>

              </Box >
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box display={"flex"} alignItems="center">

                  <Male />

                  <Box ml={1.5} marginInlineStart={'12px'}>
                    <Typography className={classes.resourceTitle}>{t("selectResource")}</Typography>
                    {/* <Typography className={classes.resourceSubTitle}>{t("selectSpecificResource")}</Typography> */}
                  </Box>
                </Box>
                {editHours === true ?
                  <Box display={"flex"} className={classes.editAssignedhrs}>

                    <ClockArrow className={classes.arrowicon} color="#5AC782" />
                    <Typography className={classes.edithoursText}>Assigned for {assignData?.hours} Hr</Typography>
                    <EditOutlinedIcon className={classes.editIcon} onClick={() => onEditHours("edit")} />
                  </Box>
                  : edit === true ?
                    <Box>
                      <Box display={"flex"} className={classes.editAssignedhrs}>

                        <ClockArrow className={classes.arrowicon} color="#5AC782" />
                        <Typography className={classes.edithoursText}>Edit Hour</Typography>
                        <OutlinedInput
                          size="small"
                          className={classes.smalltextfield}
                          endAdornment={<InputAdornment position="end">{t("hr")}</InputAdornment>}
                          onChange={(e) => updateState("hours", e.target.value, true)}
                          value={assignData?.hours}
                        />
                        <CheckCircleIcon className={classes.checkIcon} onClick={() => onEditHours("check")} />
                      </Box>
                    </Box>
                    :
                    <Box className={classes.assignedhrs}>
                      <Stack direction={"row"} spacing={1} alignItems="center">

                        <ClockArrow className={classes.icon} />
                        <Typography className={classes.assignText} onClick={() => setEditHours(true)}>{t("Assigned for")} {assignData?.hours} {t("Hr")}</Typography>
                      </Stack>
                    </Box>
                }
              </Box >
              <Box className={classes.searchBlock}>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <Grid item xs={12}>
                    <Typography className={classes.selecttitle}>{t("Resource Group")}</Typography>
                    <CustomSelect
                      loadOptions={(search, array, handleLoading) => CustomLoadOptionsApis(
                        "resource_group_master/drop_down",
                        {
                          company_id: assignData?.company?.value,
                          "maintenance_request_id": assignData?.company?.value === props?.maintenance_company_id ? props?.maintenance_request_id : null
                        },
                        search,
                        array,
                        handleLoading,
                        "data",
                        {},
                        false,
                        // manualResponse,
                        { all: true }
                      )}
                      isPaginate={true}
                      borderRadius={false}
                      noBorder={true}
                      isReadOnly={props?.isReadOnly}
                      placeholder={t("Choose Resource Group")}
                      label={false}
                      isClearable={false}
                      maxMenuHeight={"150px"}
                      value={assignData?.resource_group}
                      key={JSON.stringify(assignData)}
                      onChange={(value) => {
                        updateState("resource_group", value, true)
                      }}
                    />
                  </Grid>
                </Stack>
                <Divider className={classes.dividerstyle1} />
                <Box padding="8px 0px 8px 8px">

                  <CustomSelectOptions
                    menuPlacement="top"
                    placeholder={t("search_employee")}
                    padding="12px"

                    loadOptions={(search, array, handleLoading) =>
                      loadOptionsApis(
                        "agreement_inspection/get_resources",
                        {
                          "module_id": parseInt(assignData?.moduleId),
                          "company_id": assignData?.company?.value,
                          start_date_time: moment(assignData?.dateTime).format("YYYY-MM-DDTHH:mm:ss"),
                          "hours": parseFloat(assignData?.hours),
                          "resource_type_name": assignData?.resource_type?.label,
                          "department_id": assignData?.department?.value,
                          "job_id": assignData?.role?.value,
                          "group_id": assignData?.resource_group?.value !== null ? (assignData?.resource_group?.label === "All" ? [] : [assignData?.resource_group?.value]) : [],
                          "flexible": assignData?.schedulingType === "flexible" ? true : false,
                          "search": search,
                          "maintenance_request_id": assignData?.company?.value === props?.maintenance_company_id ? props?.maintenance_request_id : null,
                          hours_time: getHour(assignData?.dateTime, "YYYY-MM-DDTHH:mm:ss")

                        },
                        search,
                        array,
                        handleLoading,
                        "data"
                      )
                    }
                    value={assignData?.resource}
                    isPaginate={true}
                    key={JSON.stringify(assignData)}
                    onChange={(val) => updateState("resource", val)}
                    // options={props?.resourceList}
                    maxMenuHeight={"250px"}

                    noBorder={true} />

                </Box>
              </Box>

              <Box>
                {assignData?.resource?.name &&
                  <PopupCard
                    list={assignData?.resource}
                    removeData={(val) => updateState("resource", "")}
                    deleteIcon={true}
                    selected={true}
                    hours={assignData?.hours}
                  />
                }
              </Box>
            </>
          }

        </Grid >
        {assignData?.hours?.length !== 0 &&
          <Grid container>
            <Grid item xs={12} p={1.5}>
              <Box>
                <Stack
                  direction="row"
                  className={classes?.estimatedhr}
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <Grid item xs={6}>
                    <Box p={1} display="flex" justifyContent={"space-between"}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <ClockIcon className={classes.icon} />
                        <Box className={classes.totalhr}>{t("totalhour")}</Box>
                      </Stack>
                      <Typography textAlign={"center"} className={classes.counthr}>{`${assignData?.totalHours} ${assignData?.totalHours > 1 ? "Hrs" : "Hr"}`}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box p={1} textAlign="center" display="flex" justifyContent={"space-between"}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <CurrencyIcon className={classes.icon} />
                        <Box className={classes.totalhr}>{t("Est Cost")}</Box>
                      </Stack>
                      <Typography textAlign={"center"} className={classes.counthr}>{`${assignData?.estimatedAmount} ${assignData?.resource?.currency_symbol ?? ""}`}</Typography>
                    </Box>
                  </Grid>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        }
        {/* <Grid item xs={6} >
                    <AddOtherResources
                        handleSearchResource={props?.handleSearchResource}
                        assignData={assignData}
                        handleChange={updateState}
                        otherResourcesList={props?.otherResourcesList}
                        removeData={removeData}

                    />
                </Grid> */}
      </Grid >

      <Box className={classes.footer} display={"flex"} justifyContent={"space-between"} p={2}>
        <Button variant="outlined" className={classes.cancelBtn} onClick={() => props?.handleClose()}>{t("close")}</Button>

        <Button variant="contained" onClick={onSubmit} className={classes.submit} disabled={props?.isDisableBtn}>{t(`${props?.buttonName}`)}</Button>
      </Box>
    </Box >
  )
}
export default withTranslation("assignModal")(ReAssignModal)
