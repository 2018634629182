import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../utils";

export const useStylesCardView = makeStyles((theme) => ({
  card: {
    border: "2px solid #d4dbe4",
    borderRadius: "6px",
    padding: "12px",
  },
  asset: {
    width: "100%",
    height: "120px",
    borderRadius: "4px",
    objectFit: "cover",
  },
  assetText: {
    position: "absolute",
    bottom: "8px",
    left: "8px",
    color: "white",
    backgroundColor: "#091B29",
    padding: "4px 8px",
    borderRadius: "4px",
  },
  text: {
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.875rem",
  },
  round: {
    backgroundColor: "#CED3DD",
    width: "7px",
    height: "7px",
    borderRadius: "50%",
  },
  availability: {
    marginTop: "8px",
    backgroundColor: "#D3F0D3",
    textAlign: "center",
    padding: "4px",
    borderRadius: "4px",
    color: "#208044",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.875rem",
  },
  header: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "1rem",
    color: "#091B29",
  },
  header1: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "1rem",
    color: "#091B29",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "80px",
  },
  propertyName: {
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.875rem",
    color: "#4E5A6B",
    marginBottom: "6px",
  },
  units: {
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.875rem",
    color: "#091B29",
  },
  divider: {
    backgroundColor: "#d4dbe4",
    marginTop: "8px",
    marginBottom: "8px",
  },
  url: {
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.875rem",
    color: "#51585E",
    textDecoration: "underline",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    cursor: "pointer",
  },
  editBox: {
    padding: "6px 8px",
    borderRadius: "4px",
    border: "2px solid #d4dbe4",
    textAlign: "center",
    cursor: "pointer",
  },
  deleteBox: {
    padding: "6px 8px",
    borderRadius: "4px",
    backgroundColor: "#FFECEC",
    textAlign: "center",
    cursor: "pointer",
  },
  view: {
    border: "2px solid #d4dbe4",
    color: "#091B29",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.875rem",
    padding: "7px 8px",
  },
  Yes: {
    color: theme?.palette?.primary?.main,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    border: `1px solid white`,
    backgroundColor: "#ffffff",
    padding: "7px 29px",
    width: "100%",
    "&:hover": {
      border: `1px solid white`,
      backgroundColor: "white",
    },
  },
  No: {
    color: "#ffffff",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    border: `1px solid white`,
    padding: "7px 29px",
    backgroundColor: theme?.palette?.primary?.main,
    marginInlineStart: "10px",
    width: "100%",
    "&:hover": {
      border: `1px solid white`,
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
}));

export const useStylesListView = makeStyles((theme) => ({
  card: {
    border: "2px solid #d4dbe4",
    borderRadius: "6px",
    padding: "12px",
  },
  asset: {
    width: "230px",
    height: "100%",
    borderRadius: "4px",
    objectFit: "cover",
  },
  assetText: {
    position: "absolute",
    bottom: "8px",
    left: "8px",
    color: "white",
    backgroundColor: "#091B29",
    padding: "4px 8px",
    borderRadius: "4px",
  },
  text: {
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.875rem",
  },
  header: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "1rem",
    color: "#091B29",
  },
  round: {
    backgroundColor: "#CED3DD",
    width: "7px",
    height: "7px",
    borderRadius: "50%",
  },
  units: {
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.875rem",
    color: "#091B29",
  },
  editBox: {
    padding: "6px 8px",
    borderRadius: "4px",
    border: "2px solid #d4dbe4",
    textAlign: "center",
    cursor: "pointer",
    marginInline: "10px",
  },
  deleteBox: {
    padding: "6px 8px",
    borderRadius: "4px",
    backgroundColor: "#FFECEC",
    textAlign: "center",
    cursor: "pointer",
  },
  view: {
    border: "2px solid #d4dbe4",
    color: "#091B29",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.875rem",
    padding: "7px 24px",
    width: "max-content",
  },
  url: {
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.875rem",
    color: "#51585E",
    textDecoration: "underline",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    cursor: "pointer",
  },
  availability: {
    marginTop: "8px",
    backgroundColor: "#D3F0D3",
    textAlign: "center",
    padding: "4px",
    borderRadius: "4px",
    color: "#208044",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.875rem",
  },
  Yes: {
    color: theme?.palette?.primary?.main,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    border: `1px solid white`,
    backgroundColor: "white",
    padding: "7px 29px",
    width: "100%",
    "&:hover": {
      border: `1px solid white`,
      backgroundColor: "white",
    },
  },
  No: {
    color: "white",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    border: `1px solid white`,
    padding: "7px 29px",
    backgroundColor: theme?.palette?.primary?.main,
    marginInlineStart: "10px",
    width: "100%",
    "&:hover": {
      border: `1px solid white`,
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
  propertyName: {
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.875rem",
    color: "#4E5A6B",
    marginBottom: "6px",
  },
}));

export const useStylesDrawerView = makeStyles((theme) => ({
  formRoot: {
    backgroundColor: "#F2F4F7",
    width: "66vw",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  header: {
    fontFamily: FontFamilySwitch().bold,
    fontSize: "1rem",
    color: "#091B29",
    paddingLeft: "16px",
  },
  whiteBox: {
    backgroundColor: "white",
    padding: "16px",
    borderRadius: "8px",
  },
  asset: {
    width: "100%",
    height: "10rem",
  },
  assets1: {
    width: "2.5rem",
    height: "auto", // Allow natural height
    objectFit: "contain", // Preserve entire image
    borderRadius: "2px",
  },
  salePerson: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.875rem",
    color: "#98A0AC",
  },
  name: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.875rem",
    color: "#091B29",
    wordWrap: "break-word",
    whiteSpace: "normal",
  },
  designation: {
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.875rem",
    color: "#98A0AC",
  },
  salesText: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.875rem",
    color: "#091B29",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  unitName: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "1.125rem",
    color: "#091B29",
  },
  availability: {
    marginTop: "8px",
    backgroundColor: "#D3F0D3",
    textAlign: "center",
    padding: "4px 8px",
    borderRadius: "4px",
    color: "#208044",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.875rem",
  },
  propertyName: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.875rem",
    color: "#4E5A6B",
  },
  detailsCardContainer: {
    display: "flex",
    overflowX: "auto",
    whiteSpace: "nowrap",
    scrollbarWidth: "auto",
  },
  detailsCard: {
    border: "2px solid #d4dbe4",
    borderRadius: "6px",
    display: "inline-block",
    padding: "12px",
    width: "fit-content",
    marginRight: "12px",
    minWidth: "10rem",
  },
  companyCard: {
    border: "2px solid #d4dbe4",
    borderRadius: "4px",
    display: "inline-block",
    padding: "6px 16px 6px 16px",
  },
  pricingBox: {
    border: "2px solid #d4dbe4",
    borderRadius: "6px",
    display: "inline-block",
    padding: "16px",
    width: "fit-content",
    minWidth: "7rem",
  },
  divider: {
    backgroundColor: "#d4dbe4",
    marginTop: "12px",
    marginBottom: "12px",
  },
  divider2: {
    backgroundColor: "#d4dbe4",
    marginTop: "12px",
  },
  button: {
    padding: 0,
    minWidth: "auto",
    textTransform: "none",
    color: "primary",
    background: "none",
    "&:hover": {
      background: "none",
    },
  },
  notes: {
    wordBreak: "break-word",
    overflowWrap: "break-word",
    whiteSpace: "normal",
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.75rem",
    color: "#091B29",
  },

  addressDetails: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.875rem",
    color: "#091B29",
    wordWrap: "break-word",
    whiteSpace: "normal",
    width: "21vw",
  },
}));
export const filterStyles = makeStyles((theme) => ({
  detailImg: {
    width: "100%",
    height: "192px",
    aspectRatio: "1 / 1", // Ensures a perfect square
    borderRadius: "8px 0px 0px 8px",
    objectFit: "cover",
    cursor: "pointer",
  },
  detailImg2: {
    width: "100%",
    height: "92px", // Adjusted for grid alignment
    borderRadius: "8px",
    objectFit: "cover",
    cursor: "pointer",
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "12px",
    },
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)", // Consistent two-column grid
    gap: "8px",
  },
  viewAllText: {
    position: "absolute",
    bottom: "8px",
    right: "8px",
    color: "#fff",
    background: "#000000",
    padding: "6px 8px",
    borderRadius: "4px",
    fontSize: "0.875rem",
    fontWeight: FontFamilySwitch().semiBold,
    zIndex: 2,
  },
  addImg: {
    position: "relative", // Ensures text is positioned properly
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  plus: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "rgba(0, 0, 0, 0.7)",
    color: "#fff",
    padding: "4px 8px",
    borderRadius: "4px",
    fontSize: "1rem",
    fontWeight: "bold",
    zIndex: 2,
  },
}));
