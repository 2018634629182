/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate, useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Box, Button, Checkbox, Divider, Grid, InputAdornment, Stack, Tooltip, tooltipClasses, Typography } from '@mui/material';
import styled from '@mui/material/styles/styled';
import { eachHourOfInterval, endOfDay, setHours, setMinutes, startOfDay } from 'date-fns';
import moment from 'moment';
import React from 'react';
import AmenityMappingNoSlot from '../../assets/amenityMappingNoSlot';
import { SelectBox, Subheader, TextBox, ToggleButtonComponent } from '../../components';
import { config } from '../../config';
import { AlertContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { AlertProps, enum_types, enumSelect, LocalStorageKeys, NetWorkCallMethods, FontFamilySwitch } from '../../utils';
import { loadOptionsApis } from '../../utils/asyncPaginateLoadOptions';
import { ImageUpload } from './component/imageUpload';
import { AmenityMappingNewStyle } from './style';


const CustomPaper = styled('div')(({ theme }) => ({
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    height: `calc(100vh - 150px)`,
    overflow: "hidden",
    margin: "12px",
    position: "relative"
}))

const initialState = {
    id: null,
    amenity_category: "",
    amenity_reference: "",
    slot_type: "Hourly",
    status: "Active",
    description: "",
    slot: "",
    participant: "",
    chargeable: false,
    chargeable_value: "",
    half_day_value: "",
    full_day_value: "",
    // edit: false,
    // view: false,
    days: [],
    slot_time: [],
    overbooking: false,
    // amenityListingOn: "Public Listing",
    peakHour: [],
    selectedPeakHour: [],
    peakHourAmount: "",
    taxable: false,
    tax: "",
    amenity_description: "",
    facility_unit: "",
    operational_status: "",
    isShowFacilitySearch: false,
    error: {
        amenity_category: "",
        amenity_reference: "",
        slot_type: "",
        slot: "",
        description: "",
        chargeable: "",
        amount: "",
        period: "",
        status: "",
        participant: "",
        chargeable_value: "",
        slot_time: "",
        days: "",
        half_day_value: "",
        full_day_value: "",
        tax: "",
        amenity_description: "",
        facility_unit: "",
        operational_status: "",
        isShowFacilitySearch: ""
    }
}

const AmenityMappingNew = ({
    t
}) => {

    const classes = AmenityMappingNewStyle()
    const alert = React.useContext(AlertContext)

    const [AmenitiesMapping, setAmenitiesMapping] = React.useState({ ...initialState })

    const selectedCompany = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))
    const state = useLocation()?.state
    const navigate = useNavigate()

    const [enumValue, setEnumValue] = React.useState({ amenity_period: [] });
    const [hours, setHour] = React.useState([])
    const [selectedSession, setSelectedSession] = React.useState("Slot Sessions")
    const [addPeakHour, setAddPeakHour] = React.useState(false)
    const [images, setImages] = React.useState([]);
    const [deletedAssetURL, setDeletedAssetURL] = React.useState([]);
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)
    React.useEffect(() => {
        gethours()
        getEnum()
        if (state?.view || state?.edit) {
            getPropertyAmenitiesDetails(state?.id)
        }
        // eslint-disable-next-line 
    }, [])

    // goBack

    const goBack = () => {
        navigate(-1)
    }

    // get enum
    const getEnum = async () => {
        const result = await enumSelect([enum_types?.amenity_period]);
        setEnumValue({ amenity_period: result?.amenity_period });
    };

    // state updation
    const updateState = (key, value) => {
        let error = AmenitiesMapping?.error;
        error[key] = "";
        if (key === "amenity_category") {
            setAmenitiesMapping({ ...AmenitiesMapping, [key]: value, amenity_reference: "", error })
        } else {
            setAmenitiesMapping({ ...AmenitiesMapping, [key]: value, error })
        }
    }

    // session Buttons

    const sessionBtns = [
        "Slot Sessions",
        "Peak Hour Slot"
    ]

    const STATUS_OPTIONS = [
        { label: "Active", value: "Active" },
        { label: "In Active", value: "InActive" }
    ]

    // const Amenity_listing = [
    //     { label: "Public Listing", value: "Public Listing" },
    //     { label: "Private Listing", value: "Private Listing" },
    //     { label: "Both", value: "Both" }
    // ]

    const chargeable = [
        { label: "Yes", value: true },
        { label: "No", value: false }
    ]

    // Const Days
    const days = [
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat",
        "Sun"
    ]
    // Hours
    const gethours = () => setHour(eachHourOfInterval({
        start: new Date(startOfDay(new Date())),
        end: new Date(endOfDay(new Date()))
    }))
    // Change slot type 
    const changeSlotType = (value) => {
        if (value === "Daily") {
            setAmenitiesMapping({
                ...AmenitiesMapping,
                slot_time: [],
                slot_type: value,
                slot: 1
            })
        }
        else {
            updateState('slot_type', value)
        }
    }
    // change slot
    const changeSlot = (value) => {
        setAmenitiesMapping({
            ...AmenitiesMapping,
            slot_time: [],
            slot: value,
            selectedPeakHour: [],
            peakHour: [],
            error: {
                ...AmenitiesMapping.error, slot: ""
            }
        })
    }

    const selectAmenitesDay = (e) => {
        if (AmenitiesMapping?.days?.includes(e)) {
            const result = AmenitiesMapping?.days?.filter((x) => x !== e)
            updateState("days", result ?? [])
        } else {
            updateState("days", [...AmenitiesMapping?.days, e])
        }

    }

    const selectSlotDate = async (e, index) => {
        if (AmenitiesMapping?.slot_time?.flatMap((x) => x).includes(moment(e).format("YYYY-MM-DDTHH:mm:ss"))) {
            await AmenitiesMapping?.slot_time?.map((x, index) => {
                if (x.includes(moment(e).format("YYYY-MM-DDTHH:mm:ss"))) {
                    const data = [...AmenitiesMapping?.slot_time]
                    data[index] = []
                    updateState("slot_time", data)
                }
                return 0
            })

            if (AmenitiesMapping?.selectedPeakHour?.length) {
                AmenitiesMapping?.selectedPeakHour?.map((x, index) => {
                    if (x.includes(moment(e).format("YYYY-MM-DDTHH:mm:ss"))) {
                        const data = [...AmenitiesMapping?.selectedPeakHour]
                        data[index] = []
                        updateState("selectedPeakHour", data)
                    }
                    return 0
                })
            }
        }
        else {
            let timearr = []
            let validate = false
            for (let i = index; i <= index + parseInt(AmenitiesMapping?.slot) - 1; i++) {
                if (i < 24 && AmenitiesMapping?.slot < 24) {
                    if (!AmenitiesMapping?.slot_time?.flatMap((x) => x).includes(moment(hours?.[i])?.format("YYYY-MM-DDTHH:mm:ss"))) {
                        timearr.push(moment(hours?.[i]).format("YYYY-MM-DDTHH:mm:ss"))
                    }
                    else {
                        validate = true
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: t("No ") + AmenitiesMapping?.slot + t(" consecutive hours available to select"),
                        })
                    }
                }
                else {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Please Select Valid slot"),
                    })
                }
            }

            if (!validate && timearr.length > 0) {
                if (timearr.length < AmenitiesMapping?.slot) {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("No ") + AmenitiesMapping?.slot + t(" consecutive hours available to select"),
                    })
                }
                else {
                    updateState("slot_time", [...AmenitiesMapping?.slot_time, timearr])
                }
            }

            // For Already booked slot check condition

            // let status = already_booked.map(e => {
            //     if (timearr.includes(e)) {
            //         return false
            //     }
            //     return 0
            // })
            // status.includes(false) &&
            // alert.setSnack({
            //     ...alert,
            //     open: true,
            //     severity: AlertProps.severity.error,
            //     msg: "Please Select another slot",
            // })
        }


    }

    const handleShowPeakHour = () => {
        let peakhour = []
        AmenitiesMapping?.slot_time?.map((e) => {
            peakhour.push(...e)
        })
        let data = peakhour.sort(function (a, b) {
            return a.slice(10, 13) - b.slice(10, 13)

        })
        updateState("peakHour", data)
    }

    const selectPeakHour = (data) => {
        AmenitiesMapping?.slot_time?.map((d) => {
            if (d?.includes(data)) {
                if (Object.freeze(AmenitiesMapping?.selectedPeakHour)?.flatMap((x) => x)?.includes(data)) {
                    let updated = AmenitiesMapping?.selectedPeakHour?.filter((i) => !i?.includes(data))
                    updateState("selectedPeakHour", updated)
                }
                else {
                    updateState("selectedPeakHour", [...AmenitiesMapping?.selectedPeakHour, d])
                }
            }
        })

    }

    // validation

    const validate = () => {
        let isValid = true
        let error = AmenitiesMapping.error
        if (AmenitiesMapping?.amenity_category?.length === 0) {
            isValid = false
            error.amenity_category = t("Amenity Category is Required")
        }
        if (AmenitiesMapping?.amenity_reference?.length === 0) {
            isValid = false
            error.amenity_reference = t("Amenity Master is Required")
        }
        if (AmenitiesMapping?.status?.length === 0) {
            isValid = false
            error.status = t("Status is Required")
        }

        if (AmenitiesMapping?.description?.length === 0) {
            isValid = false
            error.description = t("Amenity Name is Required")
        }

        if (AmenitiesMapping?.amenity_description?.length === 0) {
            isValid = false
            error.amenity_description = t("Description is Required")
        }

        if (AmenitiesMapping?.operational_status?.length === 0) {
            isValid = false
            error.operational_status = t("Operational Status is Required")
        }

        if (AmenitiesMapping?.amenity_reference?.is_booking) {

            if (AmenitiesMapping?.slot?.toString()?.length === 0) {
                isValid = false
                error.slot = "Slot is Required"
            } else if (AmenitiesMapping?.slot === "0") {
                isValid = false
                error.slot = "Slot should be greater than 0"
            }
            // else {
            //     isValid = true
            //     error.slot = ""
            // }


            if (AmenitiesMapping?.days?.length === 0) {
                isValid = false
                error.days = "Applicable days is Required"
            }


            if (AmenitiesMapping?.participant?.toString()?.length === 0) {
                isValid = false
                error.participant = "Participant is Required"
            } else if (AmenitiesMapping?.participant === "0") {
                isValid = false
                error.participant = "Participant should be greater than 0"
            }
            // else {
            //     isValid = true
            //     error.participant = ""
            // }

            // if (AmenitiesMapping?.chargeable === false) {
            //     isValid = false
            //     error.chargeable = "Chargable is required"
            // } else {
            //     isValid = true
            //     error.chargeable = ""
            // }

            if (AmenitiesMapping?.chargeable) {
                if (AmenitiesMapping?.chargeable_value?.length === 0) {
                    isValid = false
                    error.chargeable_value = "Rate is Required"
                }
                if (addPeakHour) {
                    if (AmenitiesMapping?.peakHourAmount?.length === 0) {
                        isValid = false
                        error.peakHourAmount = "Peak Hour Rate is Required"
                    }
                }
            }

            if (AmenitiesMapping?.taxable) {
                if (AmenitiesMapping?.tax?.length === 0) {
                    isValid = false
                    error.tax = "Tax is Required"
                }
            }

            if (AmenitiesMapping?.slot_type === "Hourly") {
                if (AmenitiesMapping?.slot_time?.length === 0) {
                    isValid = false
                    error.slot_time = "Slot Session is Required"
                }
            }

            if (AmenitiesMapping?.isShowFacilitySearch === null) {
                isValid = false
                error.isShowFacilitySearch = "Show in Facility Search is Required"
            }
        }


        setAmenitiesMapping({ ...AmenitiesMapping, error })
        return isValid
    }

    // slots organitation
    const slotOrganize = (data) => {
        const result = data?.map(e => {
            return eachHourOfInterval({
                start: setHours(setMinutes(new Date(), e[0].substring(3, 5)), e[0].substring(0, 2)),
                end: setHours(setMinutes(new Date(), e[1].substring(3, 5)), parseInt(e[1].substring(0, 2)))
            })
        })
        const final_slot_time = result?.map((e, i) => {
            return result?.[i].map(data => {
                return moment(data).format("YYYY-MM-DDTHH:mm:ss")
            })
        })

        return final_slot_time
    }


    // edit and view data

    const getPropertyAmenitiesDetails = (id = state?.id) => {
        const payload = {
            facility_id: id
        };
        NetworkCall(
            `${config.api_url}/property_facilities/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                let final_selected_slot = slotOrganize(res?.data?.data?.selected_slots)
                let final_peakhour_slot = slotOrganize(res?.data?.data?.period_type === "Hourly" ? res?.data?.data?.peak_slots?.[0]?.slots : [])
                setAddPeakHour(res?.data?.data?.period_type === "Hourly" ? res?.data?.data?.peak_slots?.[0]?.rate?.length > 0 ? true : false : false)
                setImages(res?.data?.data?.facility_assets)
                setAmenitiesMapping({
                    // data: res?.data?.data,
                    id: res?.data?.data?.id,
                    amenity_category: { label: res?.data?.data?.amenities_category?.name, value: res?.data?.data?.amenities_category?.id },
                    amenity_reference: {
                        label: res?.data?.data?.amenity_type?.amenities_name,
                        value: res?.data?.data?.amenity_type?.id,
                        is_booking: res?.data?.data?.amenity_type?.is_booking
                    },
                    slot_type: res?.data?.data?.period_type,
                    status: res?.data?.data?.is_active ? "Active" : "Inactive",
                    slot: res?.data?.data?.slot_partition,
                    participant: res?.data?.data?.participants_count,
                    chargeable: res?.data?.data?.is_chargeable,
                    chargeable_value: res?.data?.data?.normal_slots?.[0]?.rate,
                    // edit: data?.type === "edit" ? true : false,
                    // view: data?.view ? true : false,
                    days: res?.data?.data?.applicable_days,
                    slot_time: final_selected_slot,
                    overbooking: res?.data?.data?.is_overbooking,
                    selectedPeakHour: final_peakhour_slot,
                    taxable: res?.data?.data?.is_taxable,
                    tax: { label: res?.data?.data?.vat_group_master?.group_name, valueb: res?.data?.data?.vat_group_master?.id },
                    // amenityListingOn: "Public Listing",
                    peakHour: res?.data?.data?.peak_slots?.[0]?.rate?.length > 0 ? final_selected_slot : [], /// need to check this
                    peakHourAmount: res?.data?.data?.period_type === "Hourly" ? res?.data?.data?.peak_slots?.[0]?.rate : "",
                    description: res?.data?.data?.description,
                    amenity_description: res?.data?.data?.amenity_description,
                    operational_status: res?.data?.data?.operational_enum_value,
                    facility_unit: { label: res?.data?.data?.unit?.name, value: res?.data?.data?.unit?.id },
                    isShowFacilitySearch: res?.data?.data?.is_workspace_shared,
                    error: { ...initialState.error }
                })

                // }

            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something went wrong"),
                })
            });
    }
    // upsert data

    const upsertPropertyAmenities = () => {
        if (validate()) {
            setIsDisableBtn(true)
            // remove peak hour duplicate from selected slot ---- start

            ////// first method
            // const flatPeakhour = AmenitiesMapping?.selectedPeakHour.flat();

            // const unique = [];

            // for (let i = 0; i < AmenitiesMapping?.slot_time?.length; i++) {
            //     const subArray = AmenitiesMapping?.slot_time?.[i];

            //     for (let j = 0; j < subArray.length; j++) {
            //         const element = subArray[j];
            //         if (!flatPeakhour.includes(element) && !unique.includes(element)) {
            //             unique.push(AmenitiesMapping?.slot_time?.[i]);
            //         }
            //     }
            // }


            // let uniqueSlotTimeArr = Array.from(new Set(unique.map(JSON.stringify)), JSON.parse);

            /// second method

            let peak_slot_index = []
            let temp_slot = []

            for (let i = 0; i < AmenitiesMapping?.slot_time?.length; i++) {
                for (let j = 0; j < AmenitiesMapping?.selectedPeakHour?.length; j++) {
                    if (JSON.stringify(AmenitiesMapping?.slot_time?.[i]) === JSON.stringify(AmenitiesMapping?.selectedPeakHour?.[j])) {
                        peak_slot_index = [...peak_slot_index, i]
                    }
                }
            }


            for (let i = 0; i < AmenitiesMapping?.slot_time?.length; i++) {
                let temp = peak_slot_index.find((_) => _ === i)
                if (!Boolean(temp || temp === 0)) {
                    temp_slot = [...temp_slot, AmenitiesMapping?.slot_time?.[i]]
                }
            }
            // --------------- function end
            // eslint-disable-next-line

            let const_arr = temp_slot?.filter((i) => i?.length !== 0)?.map((arr) => {
                if (arr.length !== 0)
                return [
                    moment(arr[0]).format("HH:mm:ss"),
                    moment(arr?.[arr?.length - 1]).add(3599, 'seconds').format("HH:mm:ss")
                ]
            })

            const result = const_arr?.filter(element => {
                return element !== undefined
            })

            let peakHourConstruct = AmenitiesMapping?.selectedPeakHour?.filter((i) => i?.length !== 0)?.map((arr) => {
                if (arr.length !== 0)
                    return [
                        moment(arr[0]).format("HH:mm:ss"),
                        moment(arr?.[arr?.length - 1]).add(3599, 'seconds').format("HH:mm:ss")

                    ]
            })

            const peakHourResult = peakHourConstruct?.filter(element => {
                return element !== undefined
            })



            /// only for selected slot -- (update state)

            let const_arr_slot = AmenitiesMapping?.slot_time?.map((arr) => {
                if (arr.length !== 0)
                    return [
                        moment(arr[0]).format("HH:mm:ss"),
                        moment(arr?.[arr?.length - 1]).add(3599, 'seconds').format("HH:mm:ss")
                    ]
            })

            const result_slot = const_arr_slot?.filter(element => {
                return element !== undefined
            })

            // reconstrucst images

            let constructedImages = images?.map((i) => {
                return ({
                    id: i?.id ?? undefined,
                    asset_type: i?.asset_type,
                    file_meta: i?.file_meta,
                    url: i?.url
                })
            })

            const variables = {
                "id": AmenitiesMapping?.id ?? undefined,
                "is_active": AmenitiesMapping?.status === "Active" ? true : false ?? undefined,
                "property_id": state?.propertyId ?? undefined,
                "amenities_type": AmenitiesMapping?.amenity_reference?.value ?? undefined,
                "is_chargeable": AmenitiesMapping?.amenity_reference?.is_booking ? AmenitiesMapping?.chargeable : false ?? undefined,
                "total_slots": AmenitiesMapping?.amenity_reference?.is_booking ? AmenitiesMapping?.slot_time.length === 0 ? 1 : AmenitiesMapping?.slot_time.length : undefined ?? undefined,
                "applicable_days": AmenitiesMapping?.amenity_reference?.is_booking ? AmenitiesMapping?.days?.length > 0 ? AmenitiesMapping?.days : [] : undefined,
                "slot_partition": AmenitiesMapping?.amenity_reference?.is_booking ? AmenitiesMapping?.slot : undefined,
                "selected_slots": AmenitiesMapping?.amenity_reference?.is_booking ? result_slot ?? undefined : undefined,
                "currency_id": AmenitiesMapping?.amenity_reference?.is_booking ? state?.currencyId ?? undefined : undefined,
                "rate": AmenitiesMapping?.amenity_reference?.is_booking ? AmenitiesMapping?.chargeable_value?.length === 0 ? 0 : AmenitiesMapping?.chargeable_value : 0 ?? undefined,
                "period": AmenitiesMapping?.amenity_reference?.is_booking ? AmenitiesMapping?.slot_type : undefined,
                "amenity_category_id": AmenitiesMapping?.amenity_category?.value ?? undefined,
                "participants_count": AmenitiesMapping?.participant !== "" ? AmenitiesMapping?.participant : undefined,
                "slots_info": [
                    {
                        rate: AmenitiesMapping?.amenity_reference?.is_booking ? AmenitiesMapping?.chargeable_value?.length === 0 ? 0 : AmenitiesMapping?.chargeable_value : 0,
                        slots: result,
                        is_peak: false
                    },
                    {
                        rate: AmenitiesMapping?.chargeable_value?.length === 0 ? 0 : AmenitiesMapping?.peakHourAmount,
                        slots: peakHourResult,
                        is_peak: true
                    }
                ],
                "is_overbooking": AmenitiesMapping?.overbooking,
                "is_taxable": AmenitiesMapping?.taxable,
                "vat_group_id": AmenitiesMapping?.tax?.value,
                "description": AmenitiesMapping?.description,
                "amenity_description": AmenitiesMapping?.amenity_description,
                "facility_unit": AmenitiesMapping?.facility_unit?.value,
                "operational_status": AmenitiesMapping?.operational_status?.value,
                "asset_items": constructedImages,
                "deleted_assets": deletedAssetURL,
                "is_workspace_shared": AmenitiesMapping?.isShowFacilitySearch ?? false
            }
            NetworkCall(
                `${config.api_url}/property_facilities/upsert`,
                NetWorkCallMethods.post,
                variables,
                null,
                true,
                false
            )
                .then((response) => {
                    goBack()
                    setIsDisableBtn(false)
                })
                .catch((error) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Something went wrong"),
                    })
                    setIsDisableBtn(false)
                });
        } else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Please Fill all mandatory fields"),
            })
        }
    }

    const maualResponseTFacilityUnit = (val) => {
        let result = val?.units?.map((val) => {
            return {
                value: val?.id,
                label: val?.name
            }
        })

        return result;
    }

    const manualMasterResponse = (array) =>{
        return array?.amenities_type_master
    }

    const manualCategoryResponse = (array) =>{
        return array?.amenity_category
    }

    const manualTaxResponse = (array) =>{
        return array?.vat_group_master
    }

    return (
        <>
            <Subheader title={state?.edit ? t("Update Amenity") : state?.view ? t("View Amenity") : t("Add Amenity")} goBack={goBack} />

            <CustomPaper>
                <Stack justifyContent={"space-between"}>
                    <Grid container>
                        <Grid item md={7} lg={7} sm={7} sx={{ borderInlineEnd: "1px solid #E4E8EE", height: "100vh" }}>
                            <Box p={"8px 8px 0px 8px"} className={classes.topBox}>
                                <Grid container spacing={1}>
                                    <Grid item md={4} xs={12} sm={6}>
                                        <SelectBox
                                            label={t("Amenity Category")}
                                            placeholder={t("Select Amenity Category")}
                                            value={AmenitiesMapping?.amenity_category}
                                            onChange={(value) => {
                                                updateState("amenity_category", value)
                                            }}
                                            isError={AmenitiesMapping?.error?.amenity_category?.length > 0}
                                            errorMessage={AmenitiesMapping?.error?.amenity_category}
                                            isReadOnly={state?.view}
                                            isPaginate
                                            loadOptions={(search, array, handleLoading) => loadOptionsApis(
                                                "queries/amenity_category",
                                                {},
                                                search,
                                                array,
                                                handleLoading,
                                                "data",
                                                {},
                                                manualCategoryResponse
                                            )}
                                            debounceTimeout={800}
                                            isRequired
                                            selectHeight={"40px"}
                                        />
                                    </Grid>
                                    <Grid item md={4} xs={12} sm={6}>
                                        <Stack>
                                            <SelectBox
                                                label={t("Amenity Master")}
                                                placeholder={t("Select Amenity Master")}
                                                value={AmenitiesMapping?.amenity_reference}
                                                onChange={(value) => {
                                                    updateState("amenity_reference", value);
                                                }}
                                                isError={AmenitiesMapping?.error?.amenity_reference?.length > 0}
                                                errorMessage={AmenitiesMapping?.error?.amenity_reference}
                                                isReadOnly={state?.view}
                                                isPaginate
                                                loadOptions={(search, array, handleLoading) => loadOptionsApis(
                                                    "queries/amenities_type_master",
                                                    {amenity_category_id: AmenitiesMapping?.amenity_category?.value},
                                                    search,
                                                    array,
                                                    handleLoading,
                                                    "data",
                                                    {},
                                                    manualMasterResponse
                                                )}
                                                debounceTimeout={800}
                                                isRequired
                                                key={JSON.stringify(AmenitiesMapping?.amenity_category)}
                                                selectHeight={"40px"}
                                            />
                                        </Stack>
                                    </Grid>

                                    <Grid item md={4} xs={12} sm={6}>
                                        <Typography className={classes.statusLabel} pb={"2px"}>
                                            {t("Status")}<span style={{ color: "red", marginLeft: 4 }}>*</span>
                                        </Typography>
                                        <ToggleButtonComponent
                                            options={STATUS_OPTIONS}
                                            value={AmenitiesMapping?.status}
                                            onChange={(value) => updateState('status', value)}
                                            isMulti={false}
                                            fullWidth={false}
                                            isError={AmenitiesMapping?.error?.status?.length > 0}
                                            errorMessage={AmenitiesMapping?.error?.status}
                                            isReadOnly={state?.view}
                                            labelFontSize="12px"
                                            height="40px"
                                            buttonGroupStyle={{
                                                gap: "6px"
                                            }}
                                            t={t}
                                        />
                                    </Grid>

                                    <Grid item md={4} xs={12} sm={6}>
                                        <TextBox
                                            value={AmenitiesMapping?.description}
                                            label={t("Amenity Name")}
                                            placeholder={t("Enter Amenity Name")}
                                            onChange={(e) => updateState("description", e.target.value)}
                                            isError={AmenitiesMapping?.error?.description?.length > 0}
                                            errorMessage={AmenitiesMapping?.error?.description}
                                            isReadonly={state?.view}
                                            isrequired
                                            height="48px"
                                        />
                                    </Grid>
                                    <Grid item md={4} xs={12} sm={6}>
                                        <Stack>
                                            <SelectBox
                                                label={t("Operational Status")}
                                                placeholder={t("Select Operational Status")}
                                                value={AmenitiesMapping?.operational_status}
                                                onChange={(value) => {
                                                    updateState("operational_status", value);
                                                }}
                                                isError={AmenitiesMapping?.error?.operational_status?.length > 0}
                                                errorMessage={AmenitiesMapping?.error?.operational_status}
                                                isReadOnly={state?.view}
                                                isPaginate
                                                loadOptions={(search, array, handleLoading) =>
                                                    loadOptionsApis(
                                                        "/enum",
                                                        { "enumName": [enum_types.operational_status] },
                                                        search,
                                                        array,
                                                        handleLoading,
                                                        "operational_status",
                                                    )
                                                }
                                                debounceTimeout={800}
                                                isRequired
                                                key={AmenitiesMapping?.amenity_category?.value}
                                                selectHeight={"40px"}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item md={4} xs={12} sm={6}>
                                        <Stack>
                                            <SelectBox
                                                label={t("Facility Unit")}
                                                placeholder={t("Select Facility Unit")}
                                                value={AmenitiesMapping?.facility_unit}
                                                onChange={(value) => {
                                                    updateState("facility_unit", value);
                                                }}
                                                isError={AmenitiesMapping?.error?.facility_unit?.length > 0}
                                                errorMessage={AmenitiesMapping?.error?.facility_unit}
                                                isReadOnly={state?.view}
                                                isPaginate
                                                loadOptions={(search, array, handleLoading) =>
                                                    loadOptionsApis(
                                                        "amenities_booking_v2/get_facility_units",
                                                        {
                                                            property_id: state?.propertyId,
                                                            company_id: state?.companyId,
                                                            // unit_purpose: ["Facilities"]
                                                            unit_category_id: [11]
                                                        },
                                                        search,
                                                        array,
                                                        handleLoading,
                                                        "data",
                                                        {},
                                                        maualResponseTFacilityUnit
                                                    )
                                                }
                                                debounceTimeout={800}
                                                key={AmenitiesMapping?.amenity_category?.value}
                                                selectHeight={"40px"}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextBox
                                            value={AmenitiesMapping?.amenity_description}
                                            label={t("Description")}
                                            placeholder={t("Enter Description")}
                                            onChange={(e) => updateState("amenity_description", e.target.value)}
                                            isError={AmenitiesMapping?.error?.amenity_description?.length > 0}
                                            errorMessage={AmenitiesMapping?.error?.amenity_description}
                                            isReadonly={state?.view}
                                            isrequired
                                            height="48px"
                                            multiline
                                        />
                                    </Grid>
                                </Grid >
                            </Box >
                            <Box pt={1}>
                                <Divider></Divider>
                            </Box>
                            <Box sx={{ height: state?.view ? "calc(100vh - 450px)" : "calc(100vh - 520px)", overflow: "auto" }}>

                                <Box px={1}>
                                    <ImageUpload
                                        t={t}
                                        images={images}
                                        setImages={setImages}
                                        deletedAssetURL={deletedAssetURL}
                                        setDeletedAssetURL={setDeletedAssetURL}
                                        isView={state?.view}
                                        xpadding={"8px"} />
                                </Box>
                                <Box pb={1}>
                                    <Divider></Divider>
                                </Box>
                                {AmenitiesMapping?.amenity_reference?.is_booking &&
                                    <Box px={1}>
                                        {/* <Grid item xs={12} md={12} mt={2}> */}
                                        <Grid container spacing={1}
                                        // sx={{ height: state?.view ? "calc(100vh - 330px)" : "calc(100vh - 520px)", overflow: "overlay" }}
                                        >
                                            <Grid item md={12} xs={12}>
                                                {
                                                    AmenitiesMapping?.amenity_reference &&
                                                    <Grid container spacing={2}>
                                                        <Grid item md={12} xs={12}>
                                                            <Grid container alignItems={"center"}>
                                                                <Grid item md={3} xs={3} lg={2} xl={2}>
                                                                    <Typography className={classes.header}>{t("Slot Configuration")}
                                                                        <span style={{ color: "red" }}>*</span></Typography>
                                                                </Grid>
                                                                <Grid item md={9} xs={9} lg={10} xl={10}>

                                                                    <Stack direction={"row"}>
                                                                        <Box sx={{ width: "150px" }}>
                                                                            <ToggleButtonComponent
                                                                                options={enumValue?.amenity_period}
                                                                                value={AmenitiesMapping?.slot_type}
                                                                                onChange={(value) => changeSlotType(value)}
                                                                                isMulti={false}
                                                                                fullWidth={false}
                                                                                isError={AmenitiesMapping?.error?.slot_type?.length > 0}
                                                                                errorMessage={AmenitiesMapping?.error?.slot_type}
                                                                                isReadOnly={state?.view}
                                                                                isrequired
                                                                                labelFontSize="12px"
                                                                                height="40px"
                                                                                width="auto"
                                                                                buttonGroupStyle={{
                                                                                    gap: "6px"
                                                                                }}

                                                                            />
                                                                        </Box>
                                                                        <Stack>
                                                                            <Box ml={"12px"} sx={{ width: "40%", marginTop: "2px" }}>
                                                                                <TextBox
                                                                                    label=""
                                                                                    placeholder="Hrs"
                                                                                    type={"number"}
                                                                                    height="44px"
                                                                                    padding="11px 8px 4px 8px"
                                                                                    fontSize="12px"
                                                                                    value={AmenitiesMapping.slot}
                                                                                    isrequired
                                                                                    onChange={(value) => {
                                                                                        changeSlot(value.target.value)
                                                                                    }}
                                                                                    // isError={AmenitiesMapping?.error?.slot?.length > 0}
                                                                                    // errorMessage={AmenitiesMapping?.error?.slot}
                                                                                    isReadonly={AmenitiesMapping?.slot_type !== "Hourly" || state?.view}

                                                                                />
                                                                            </Box>
                                                                            {AmenitiesMapping?.error?.slot?.length > 0 && <Typography className={classes.error} ml={"12px"} pt={2}>
                                                                                {AmenitiesMapping?.error?.slot}
                                                                            </Typography>}
                                                                        </Stack>
                                                                    </Stack>
                                                                </Grid>
                                                            </Grid >
                                                            <Box mt={2}>
                                                                <Divider></Divider>
                                                            </Box>
                                                        </Grid >
                                                        <Grid item md={12} xs={12}>
                                                            <Grid container alignItems={"center"}>
                                                                <Grid item md={3} xs={3} lg={2} xl={2}>
                                                                    <Typography className={classes.header}>{t("Select Days")}
                                                                        <span style={{ color: "red" }}>*</span></Typography>
                                                                </Grid>
                                                                <Grid item md={9} xs={9} lg={10} xl={10} alignItems={"center"}>

                                                                    <Grid container rowGap={"8px"} columnGap={"8px"}>
                                                                        {
                                                                            days?.map((e) => {
                                                                                return (
                                                                                    <Box className={state?.view ? 'Mui-disabled' : ""}
                                                                                        onClick={() => !state?.view && selectAmenitesDay(e)}>
                                                                                        <Box className={AmenitiesMapping?.days?.includes(e) ? classes.highlight_day : classes.day}>
                                                                                            <Typography className={classes.day_text}>{e}</Typography>
                                                                                        </Box>
                                                                                    </Box>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Grid>
                                                                    {
                                                                        AmenitiesMapping?.error?.days?.length > 0 &&
                                                                        <Typography className={classes.error} mt={1}>{AmenitiesMapping?.error?.days}</Typography>
                                                                    }
                                                                </Grid>
                                                            </Grid >

                                                            <Box mt={2}>
                                                                <Divider></Divider>
                                                            </Box>
                                                        </Grid >
                                                        <Grid item md={12} xs={12}>
                                                            <Grid container alignItems={"center"}>
                                                                <Grid item md={3} xs={3} lg={2} xl={2}>
                                                                    <Typography className={classes.header}>{t("Capacity")}
                                                                        <span style={{ color: "red" }}>*</span></Typography>
                                                                </Grid>
                                                                <Grid item md={9} xs={9} lg={10} xl={10}>
                                                                    <Stack direction={"row"} alignItems={"center"}>
                                                                        <Stack>
                                                                            <Box sx={{ width: "150px" }}>
                                                                                <TextBox
                                                                                    label=""
                                                                                    placeholder={""}
                                                                                    type={"number"}
                                                                                    height="42px"
                                                                                    padding="8px"
                                                                                    fontSize="12px"
                                                                                    endAdornment={<InputAdornment position="end"><Typography sx={{ fontSize: "0.75rem", color: "#98A0AC", fontFamily: FontFamilySwitch().semiBold, }}>{t("Member / slot")}</Typography></InputAdornment>}
                                                                                    value={AmenitiesMapping?.participant}
                                                                                    onChange={(e) => {
                                                                                        updateState("participant", e.target.value);
                                                                                    }}
                                                                                    isrequired
                                                                                    // isError={AmenitiesMapping?.error?.participant?.length > 0}
                                                                                    // errorMessage={AmenitiesMapping?.error?.participant}
                                                                                    isReadonly={state?.view}
                                                                                    textBoxHeight={"20px"}
                                                                                    textBoxColor={"#F2F4F7"}
                                                                                />
                                                                            </Box>
                                                                            {
                                                                                AmenitiesMapping?.error?.participant?.length > 0 &&
                                                                                <Typography className={classes.error} mt={1}>{AmenitiesMapping?.error?.participant}</Typography>
                                                                            }
                                                                        </Stack>
                                                                        {
                                                                            AmenitiesMapping?.slot_type === "Hourly" &&
                                                                            <Stack direction={"row"} spacing={0.5} alignItems={"center"} sx={{ marginTop: AmenitiesMapping?.error?.participant?.length > 0 && "-20px", marginLeft: "8px" }}>

                                                                                <Checkbox
                                                                                    checked={AmenitiesMapping?.overbooking}
                                                                                    value={AmenitiesMapping?.overbooking}
                                                                                    onChange={(e) => updateState("overbooking", e.target.checked)}
                                                                                    disabled={state?.view}
                                                                                    sx={{ width: 20 }} />
                                                                                <Typography className={classes.overBookingLabel}>{t("Overbooking Applicable")}</Typography>
                                                                                <LightTooltip title={
                                                                                    <React.Fragment>
                                                                                        <Stack spacing={1}>
                                                                                            <Typography className={classes.tooltipHeader}>{t("Overbooking Applicable?")}</Typography>
                                                                                            <Typography className={classes.tooltipContent}>{t("In overbooking, the slot remains available Till the entered number of participants is booked in a specific time slot")}.</Typography>
                                                                                        </Stack>
                                                                                    </React.Fragment>
                                                                                } placement="top">
                                                                                    <InfoOutlinedIcon sx={{ color: "#4E5A6B", fontSize: "1rem" }} />
                                                                                </LightTooltip>
                                                                            </Stack>
                                                                        }
                                                                    </Stack>
                                                                </Grid>
                                                            </Grid>
                                                            <Box mt={2}>
                                                                <Divider></Divider>
                                                            </Box>
                                                        </Grid>

                                                        {/* <Grid item md={12} xs={12}>
                                                        <Grid container alignItems={"center"}>
                                                            <Grid item md={4} xs={4}>
                                                                <Typography className={classes.header}>Amenity Listing on</Typography>
                                                            </Grid>
                                                            <Grid item md={8} xs={8} alignItems={"center"}>
                                                                <ToggleButtonComponent
                                                                    options={Amenity_listing}
                                                                    value={AmenitiesMapping?.amenityListingOn}
                                                                    onChange={(value) => updateState('amenityListingOn', value)}
                                                                    isMulti={false}
                                                                    fullWidth={false}
                                                                    isReadOnly={state?.view}
                                                                    height="38px"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Box mt={2}>
                                                            <Divider></Divider>
                                                        </Box>
                                                    </Grid> */}

                                                        <Grid item md={12} sm={12} lg={12}>
                                                            <Grid container spacing={2} alignItems={"center"}>
                                                                <Grid item md={12} xs={12}>
                                                                    <Grid container>
                                                                        <Grid item md={3} xs={3} lg={2} xl={2}>
                                                                            <Typography className={classes.header}>{t("Chargeable?")}<span style={{ color: "red" }}>*</span></Typography>
                                                                        </Grid>
                                                                        <Grid item md={9} xs={9} lg={10} xl={10}>
                                                                            {/* <Box className={state?.view ? 'Mui-disabled' : ""}>
                                                                                <CustomSwitch defaultChecked onChange={(e) => updateState('chargeable', e.target.checked)} checked={AmenitiesMapping.chargeable} disabled={state?.view ? true : false} />
                                                                            </Box> */}
                                                                            <ToggleButtonComponent
                                                                                options={chargeable}
                                                                                value={AmenitiesMapping?.chargeable}
                                                                                onChange={(value) => {
                                                                                    // updateState('chargeable', value)
                                                                                    setAmenitiesMapping({ ...AmenitiesMapping, chargeable: value, chargeable_value: "", peakHourAmount: "", taxable: false, tax: "" })
                                                                                }}
                                                                                isMulti={false}
                                                                                fullWidth={false}
                                                                                isReadOnly={state?.view}
                                                                                isError={AmenitiesMapping?.error?.chargeable?.length > 0}
                                                                                errorMessage={AmenitiesMapping?.error?.chargeable}
                                                                                labelFontSize="12px"
                                                                                height="40px"
                                                                                buttonGroupStyle={{
                                                                                    gap: "6px"
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid >
                                                                {
                                                                    AmenitiesMapping?.chargeable &&
                                                                    <Grid item md={12} xs={12} sm={12}>
                                                                        <Grid container alignItems={"center"}>
                                                                            <Grid item md={3} xs={3} lg={2} xl={2}>
                                                                                <Typography className={classes.header}>{t("Rate")}<span style={{ color: "red" }}>*</span></Typography>
                                                                            </Grid>
                                                                            <Grid item md={9} sm={9} lg={10} xl={10}>
                                                                                <Stack direction={"row"} spacing={1} overflow={"hidden"}>
                                                                                    <Box className={classes.amountTextBox}>
                                                                                        <TextBox
                                                                                            label=""
                                                                                            placeholder={"Rate"}
                                                                                            type={"number"}
                                                                                            height="42px"
                                                                                            padding="8px"
                                                                                            fontSize="12px"
                                                                                            startAdornment={<InputAdornment position="start"><Typography sx={{ fontSize: "0.75rem", color: "#98A0AC", fontFamily: FontFamilySwitch().semiBold, }}>{selectedCompany?.currency_symbol}</Typography></InputAdornment>}
                                                                                            // endAdornment={<InputAdornment position="end"><Typography sx={{ fontSize:"0.75rem", color: "#98A0AC", fontFamily: FontFamilySwitch().semiBold, }}>{t("Per Member")}</Typography></InputAdornment>}
                                                                                            value={AmenitiesMapping?.chargeable_value}
                                                                                            onChange={(e) => {
                                                                                                updateState("chargeable_value", e.target.value);
                                                                                            }}
                                                                                            isrequired
                                                                                            isError={AmenitiesMapping?.error?.chargeable_value?.length > 0}
                                                                                            errorMessage={AmenitiesMapping?.error?.chargeable_value}
                                                                                            isReadonly={state?.view}
                                                                                            textBoxHeight={"20px"}
                                                                                            textBoxColor={"#F2F4F7"}
                                                                                        />
                                                                                    </Box>
                                                                                    {
                                                                                        (!addPeakHour && !state?.view && AmenitiesMapping?.slot_type === "Hourly") &&

                                                                                        <Typography onClick={() => setAddPeakHour(!addPeakHour)} className={classes.addPeakHourLabel}>{t("Peak Hours Rate")}</Typography>

                                                                                    }

                                                                                    {
                                                                                        addPeakHour &&
                                                                                        <Stack direction={"row"} spacing={1}>
                                                                                            <Typography className={classes.header} sx={{ float: "right", marginTop: "8px" }} noWrap>
                                                                                                {t("Peak Rate")}<span style={{ color: "red" }}>*</span>
                                                                                            </Typography >
                                                                                            {/* </Grid>}
                                                                                    {addPeakHour && <Grid item md={3} sm={3}> */}
                                                                                            < Stack >
                                                                                                <Box className={classes.amountTextBox}>
                                                                                                    <TextBox
                                                                                                        label=""
                                                                                                        placeholder={"Peak Amount"}
                                                                                                        type={"number"}
                                                                                                        height="42px"
                                                                                                        padding="8px"
                                                                                                        fontSize="12px"
                                                                                                        startAdornment={<InputAdornment position="start"><Typography sx={{ fontSize: "0.75rem", color: "#98A0AC", fontFamily: FontFamilySwitch().semiBold, }}>{selectedCompany?.currency_symbol}</Typography></InputAdornment>}
                                                                                                        // endAdornment={<InputAdornment position="end"><Typography sx={{ fontSize:"0.75rem", color: "#98A0AC", fontFamily: FontFamilySwitch().semiBold, }}>{t("Per Member")}</Typography></InputAdornment>}
                                                                                                        value={AmenitiesMapping?.peakHourAmount}
                                                                                                        onChange={(e) => {
                                                                                                            updateState("peakHourAmount", e.target.value);
                                                                                                        }}
                                                                                                        isrequired
                                                                                                        isReadonly={state?.view}
                                                                                                        textBoxHeight={"20px"}
                                                                                                        textBoxColor={"#F2F4F7"}
                                                                                                    />
                                                                                                </Box>
                                                                                                {
                                                                                                    AmenitiesMapping?.error?.peakHourAmount?.length > 0 &&
                                                                                                    <Typography className={classes.error} mt={1}>{AmenitiesMapping?.error?.peakHourAmount}</Typography>
                                                                                                }
                                                                                            </Stack >
                                                                                            {
                                                                                                !state?.view &&
                                                                                                <Typography
                                                                                                    className={classes.removeLabel}
                                                                                                    sx={{ marginTop: "8px !important" }}
                                                                                                    onClick={() => {
                                                                                                        setAddPeakHour(!addPeakHour)
                                                                                                        setAmenitiesMapping({
                                                                                                            ...AmenitiesMapping,
                                                                                                            "peakHourAmount": "",
                                                                                                            selectedPeakHour: []
                                                                                                        })
                                                                                                        setSelectedSession("Slot Sessions")
                                                                                                    }}>{t("Remove")}</Typography>
                                                                                            }
                                                                                        </Stack >
                                                                                    }
                                                                                    {/* <TextBox
                                                                                        sx={{ width: "150px" }}
                                                                                        label=""
                                                                                        placeholder={""}
                                                                                        type={"number"}
                                                                                        height="42px"
                                                                                        padding="8px"
                                                                                        fontSize="12px"
                                                                                        endAdornment={
                                                                                            <InputAdornment position="end">
                                                                                                <Typography sx={{ fontSize:"0.75rem", color: "#98A0AC", fontFamily: FontFamilySwitch().semiBold, }}>Half Day</Typography>
                                                                                            </InputAdornment>
                                                                                        }
                                                                                        value={AmenitiesMapping?.half_day_value}
                                                                                        onChange={(e) => {
                                                                                            updateState("half_day_value", e.target.value);
                                                                                        }}
                                                                                        isrequired
                                                                                        isError={AmenitiesMapping?.error?.half_day_value?.length > 0}
                                                                                        errorMessage={AmenitiesMapping?.error?.half_day_value}
                                                                                        isReadonly={state?.view}

                                                                                    />
                                                                                    <TextBox
                                                                                        sx={{ width: "150px" }}
                                                                                        label=""
                                                                                        placeholder={""}
                                                                                        type={"number"}
                                                                                        height="42px"
                                                                                        padding="8px"
                                                                                        fontSize="12px"
                                                                                        endAdornment={
                                                                                            <InputAdornment position="end">
                                                                                                <Typography sx={{ fontSize:"0.75rem", color: "#98A0AC", fontFamily: FontFamilySwitch().semiBold, }}>Full Day</Typography>
                                                                                            </InputAdornment>
                                                                                        }
                                                                                        value={AmenitiesMapping?.full_day_value}
                                                                                        onChange={(e) => {
                                                                                            updateState("full_day_value", e.target.value);
                                                                                        }}
                                                                                        isrequired
                                                                                        isError={AmenitiesMapping?.error?.full_day_value?.length > 0}
                                                                                        errorMessage={AmenitiesMapping?.error?.full_day_value}
                                                                                        isReadonly={state?.view}

                                                                                    /> */}
                                                                                    {/* {
                                                                                        (addPeakHour && !state?.view && AmenitiesMapping?.slot_type === "Hourly") &&
                                                                                        <Typography onClick={() => setAddPeakHour(!addPeakHour)} className={classes.addPeakHourLabel}>{t("Peak Hours Rate")}</Typography>
                                                                                    } */}
                                                                                </Stack >

                                                                            </Grid >
                                                                        </Grid >
                                                                    </Grid >
                                                                }

                                                            </Grid >
                                                            <Box mt={2}>
                                                                <Divider></Divider>
                                                            </Box>
                                                        </Grid >
                                                        <Box mt={2}>
                                                            <Divider></Divider>
                                                        </Box>
                                                        <Grid item md={12} sm={12} lg={12}>
                                                            <Stack direction={'row'} spacing={1} alignItems={"center"}>
                                                                <Grid container alignItems={"center"}>
                                                                    <Grid item md={3} xs={3} lg={2} xl={2}>
                                                                        <Typography className={classes.header}>{t("Taxable?")}</Typography>
                                                                    </Grid>
                                                                    <Grid item md={2.5} lg={2} xs={3} sm={3}>
                                                                        <ToggleButtonComponent
                                                                            options={chargeable}
                                                                            value={AmenitiesMapping?.taxable}
                                                                            onChange={(value) => {
                                                                                setAmenitiesMapping({ ...AmenitiesMapping, taxable: value, tax: "" })
                                                                                // updateState('taxable', value)
                                                                            }}
                                                                            isMulti={false}
                                                                            fullWidth={false}
                                                                            isReadOnly={!AmenitiesMapping?.chargeable || state?.view}
                                                                            labelFontSize="12px"
                                                                            height="40px"
                                                                            buttonGroupStyle={{
                                                                                gap: "6px"
                                                                            }}
                                                                        />

                                                                    </Grid>
                                                                    <Grid item md={4} lg={4} xs={4} sm={4} mt={AmenitiesMapping?.error?.tax?.length > 0 ? "20px !important" : "0px"}>
                                                                        {
                                                                            AmenitiesMapping?.taxable &&
                                                                            <SelectBox
                                                                                label=""
                                                                                placeholder="Choose Tax"
                                                                                value={AmenitiesMapping?.tax}
                                                                                onChange={(value) => {
                                                                                    updateState("tax", value)
                                                                                }}
                                                                                isReadOnly={state?.view}
                                                                                isPaginate
                                                                                loadOptions={(search, array, handleLoading) => loadOptionsApis(
                                                                                    "queries/vat_group_master/list",
                                                                                    {company_id:state?.companyId},
                                                                                    search,
                                                                                    array,
                                                                                    handleLoading,
                                                                                    "data",
                                                                                    {},
                                                                                    manualTaxResponse
                                                                                )}
                                                                                debounceTimeout={800}
                                                                                isRequired
                                                                                selectHeight={"40px"}
                                                                                menuPlacement='top'
                                                                                isError={AmenitiesMapping?.error?.tax?.length > 0}
                                                                                errorMessage={AmenitiesMapping?.error?.tax}
                                                                            />
                                                                        }
                                                                    </Grid>
                                                                </Grid >

                                                            </Stack >
                                                        </Grid >

                                                        <Grid item md={12} sm={12} lg={12}>
                                                            <Box mb={2}>
                                                                <Divider></Divider>
                                                            </Box>
                                                            <Stack direction={'row'} spacing={1} alignItems={"center"}>
                                                                <Grid container alignItems={"center"}>
                                                                    <Grid item md={3} xs={3} lg={2} xl={2}>
                                                                        <Typography className={classes.header}>{t("Show in Facility Search?")}<span style={{ color: "red" }}>*</span></Typography>
                                                                    </Grid>
                                                                    <Grid item md={4} lg={4} xs={4} sm={4}>
                                                                        <ToggleButtonComponent
                                                                            options={chargeable}
                                                                            value={AmenitiesMapping?.isShowFacilitySearch}
                                                                            onChange={(value) => {
                                                                                setAmenitiesMapping({ ...AmenitiesMapping, isShowFacilitySearch: value })
                                                                                // updateState('taxable', value)
                                                                            }}
                                                                            isMulti={false}
                                                                            fullWidth={false}
                                                                            isReadOnly={state?.view}
                                                                            labelFontSize="12px"
                                                                            height="40px"
                                                                            buttonGroupStyle={{
                                                                                gap: "6px"
                                                                            }}
                                                                            isError={AmenitiesMapping?.error?.isShowFacilitySearch?.length > 0}
                                                                            errorMessage={AmenitiesMapping?.error?.isShowFacilitySearch}
                                                                        />

                                                                    </Grid>
                                                                </Grid >

                                                            </Stack >
                                                        </Grid >
                                                    </Grid >
                                                }
                                            </Grid >
                                        </Grid >
                                        {/* </Grid> */}

                                    </Box >
                                }
                            </Box >
                        </Grid >
                        <Grid item md={5} lg={5} sm={5}>
                            {
                                (AmenitiesMapping?.amenity_reference && AmenitiesMapping?.slot_type === "Hourly" && AmenitiesMapping?.amenity_reference?.is_booking) ?
                                    <Box>
                                        {
                                            addPeakHour &&
                                            <Box p={2}>
                                                <Stack direction={"row"} alignItems={"center"} className={classes.customToggle} p={0.5}>
                                                    {
                                                        sessionBtns?.map((e) => {
                                                            return (
                                                                <Button
                                                                    fullWidth
                                                                    disableRipple
                                                                    className={selectedSession === e ? classes.selectedtoggleBtn : classes.toggleBtn}
                                                                    onClick={() => {
                                                                        setSelectedSession(e)
                                                                        handleShowPeakHour()
                                                                    }}>
                                                                    {e}
                                                                </Button>
                                                            )
                                                        })
                                                    }
                                                </Stack>
                                            </Box>
                                        }
                                        <Box p={2}>
                                            <Stack>
                                                <Stack direction={"row"} justifyContent={"space-between"}>
                                                    <Typography className={classes.header}>{selectedSession !== "Slot Sessions" ? t("Peak Hour Slot Configuration") : t("Slot Configuration")}</Typography>
                                                    <Stack direction={"row"} spacing={2}>
                                                        {
                                                            selectedSession === "Slot Sessions" &&
                                                            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                                                <Box className={classes.ex_selected}></Box>
                                                                <Typography className={classes.slotLabel}>{t("Selected Slot")}</Typography>
                                                            </Stack>
                                                        }
                                                        {/* <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                                            <Box className={classes.ex_auto_select}></Box>
                                                            <Typography className={classes.slotLabel}>Auto Selected Slot</Typography>
                                                        </Stack> */}
                                                    </Stack>
                                                </Stack>
                                                <Typography className={classes.desclimer}>
                                                    {
                                                        selectedSession !== "Slot Sessions" ? "Choose applicable peak hour slots" :
                                                            "If slot hour is more than 1hr, Remaining hours get auto selected"
                                                    }
                                                </Typography>
                                            </Stack>
                                            {
                                                (AmenitiesMapping?.error?.slot_time?.length > 0 && selectedSession !== "Slot Sessions") &&
                                                <Typography className={classes.error} mt={1}>{AmenitiesMapping?.error?.slot_time}</Typography>
                                            }
                                            {
                                                selectedSession === "Slot Sessions" ?
                                                    <Grid container pt={1.5}>
                                                        {
                                                            hours.map((e, i) => {
                                                                return (
                                                                    <Grid item md={3} lg={1.71} sm={4}>
                                                                        <Box className={state?.view ? 'Mui-disabled' : ""}
                                                                            onClick={() => !state?.view && selectSlotDate(e, i)}>
                                                                            <Box
                                                                                className={Object?.freeze(AmenitiesMapping?.slot_time)?.flatMap((x) => x).includes(moment(e).format("YYYY-MM-DDTHH:mm:ss")) ? classes.selected : classes.not_selected}
                                                                            >
                                                                                {moment(e).format("hh:ss A")}</Box>
                                                                        </Box>
                                                                    </Grid>
                                                                )
                                                            })
                                                        }
                                                    </Grid>
                                                    :
                                                    <Grid container pt={1.5}>
                                                        {
                                                            AmenitiesMapping?.peakHour?.map((e, i) => {
                                                                return (
                                                                    <Grid item md={3} lg={1.71} sm={4}>
                                                                        <Box className={state?.view ? 'Mui-disabled' : ""}
                                                                            onClick={() => !state?.view && selectPeakHour(e)}>
                                                                            <Box
                                                                                className={Object?.freeze(AmenitiesMapping?.selectedPeakHour)?.flatMap((x) => x).includes(moment(e).format("YYYY-MM-DDTHH:mm:ss")) ? classes.selected : classes.not_selected}
                                                                            >
                                                                                {moment(e).format("hh:ss A")}</Box>
                                                                        </Box>
                                                                    </Grid>
                                                                )
                                                            })
                                                        }
                                                    </Grid>
                                            }
                                        </Box>
                                    </Box>
                                    :
                                    <Box display={"flex"} height={"80%"} alignItems={"center"} justifyContent={"center"} >
                                        <AmenityMappingNoSlot />
                                    </Box>
                            }



                        </Grid>
                    </Grid >
                    {
                        !state?.view &&
                        <Stack direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            pr={2}
                            pl={2}
                            className={classes.bottomStack}>
                            <Button className={classes.closeBtn} onClick={() => goBack()}>{t("Close")}</Button>
                            <Button className={classes.createBtn} variant={"contained"} onClick={() => upsertPropertyAmenities()} disabled={isDisableBtn}>{state?.edit ? t("Update") : t("Create")}</Button>
                        </Stack>
                    }
                </Stack >
            </CustomPaper >
        </>
    )
}


const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />

))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: "0.75rem",
    },
}));
export default withTranslation("amenities")(AmenityMappingNew);
