import { Box } from "@mui/material";
import { withTranslation } from "react-i18next";
import { DetailsCardCoponent } from "../../../components";
const ReservationInfo = ({ t, details = {} }) => {
    const card1 = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("reservationNumber"),
            subtitle: details?.reservation_no ?? "-"
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("paymentScheduleExecutionStatus"),
            subtitle: details?.reservation_no ? "Done" : "-"
        },

        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("reservationAdvanceAmount"),
            subtitle: details?.reserved_amount ?? "-"
        },

        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: details?.reservation_start_date ? "date" : "text",
            heading: t("reservationStartDate"),
            subtitle: details?.reservation_start_date ?? "-"
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: details?.reservation_expiry_date ? "date" : "text",
            heading: t("reservationEndDate"),
            subtitle: details?.reservation_expiry_date ?? "-"
        },


    ]



    return (
        <Box p={2}>
            <DetailsCardCoponent components={card1} />
        </Box>
    )
}
export default withTranslation("agreement")(ReservationInfo); 