import React from "react";
import { useStyles } from "../styles";
import { Box, Typography, Grid } from "@mui/material";
import { FormGenerator, LoadingSection } from "../../../components";
import { FontFamilySwitch } from "../../../utils";

const Step3 = ({ t, loading, unitData, updateListingState }) => {
  const classes = useStyles();

  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: "white",
        border: "1px solid #E4E8EE",
        borderRadius: "4px"
      }}
    >
      {loading ? (
        <Box sx={{ height: `calc(100vh - 125px)`, overflow: "auto" }}>
          <LoadingSection message="Fetching Details ..." top="30vh" />
        </Box>
      ) : (
        <>
          <Typography
            sx={{
              fontSize: "0.875rem",
              fontfamily: FontFamilySwitch().semiBold,
              color: "#091B29",
            }}
          >
            {t("Additional Information")}
          </Typography>
          <Box height="10px" />
          <Box>
            <Grid container>
              <Grid item xs={12}>
                <FormGenerator
                  t={t}
                  spacing={1.5}
                  components={[
                    {
                      size: {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                      },
                      isActive: true,
                      component: "text",
                      label: t("MLS/Listing Portal URL"),
                      value: unitData?.ListingData?.portal_url,
                      placeholder: t("Enter URL"),
                      onChange: (value) =>
                        updateListingState("portal_url", value.target.value),
                      error: unitData?.error?.ListingData?.portal_url,
                      fontSize: "0.875rem !important",
                    },
                    {
                      size: {
                        xs: 12,
                        sm: 6,  
                        md: 6,
                        lg: 6,
                      },
                      isActive: true,
                      component: "text",
                      label: t("MLS/Listing Portal Provider"),
                      value: unitData?.ListingData?.portal_provider,
                      placeholder: t("Enter Portal Provider"),
                      onChange: (value) =>
                        updateListingState(
                          "portal_provider",
                          value.target.value
                        ),
                      error: unitData?.error?.ListingData?.portal_provider,
                      fontSize: "0.875rem !important",
                    },
                    {
                      size: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                      },
                      isActive: true,
                      component: "text",
                      label: t("MLS/Listing Portal Reference ID"),
                      value: unitData?.ListingData?.reference_id,
                      placeholder: t("Enter Portal Reference ID"),
                      onChange: (value) =>
                        updateListingState("reference_id", value.target.value),
                      error: unitData?.error?.ListingData?.reference_id,
                      fontSize: "0.875rem !important",
                    },
                    {
                      size: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                      },
                      isActive: true,
                      component: "text",
                      label: t("Agent Contact"),
                      value: unitData?.ListingData?.agent,
                      placeholder: t("Enter Agent Contact"),
                      onChange: (value) =>
                        updateListingState("agent", value.target.value),
                      error: unitData?.error?.ListingData?.agent,
                      fontSize: "0.875rem !important",
                    },
                    {
                      size: {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                      },
                      isActive: true,
                      component: "mobile",
                      label: t("Phone Number"),
                      value: unitData?.ListingData?.phone,
                      placeholder: t("Enter Phone Number"),
                      onChange: (value) => updateListingState("phone", value),
                      fontSize: "0.875rem !important",
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Step3;
