import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const useStyles = makeStyles((theme) => ({
  dropdown: {
    margin: "10px 0px",
  },
  closeMain: {
    padding: "24px",
  },
  root: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    margin: "16px",
    overflow: "auto",
    padding: "16px",
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    float: "right",
    "&:hover": {
      backgroundColor: "#5078E1",
    },
  },

  btn2: {
    borderRadius: theme.palette.borderRadius,
    color: theme.palette.secondary.main,
  },
  subText: {
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary,
  },
  img: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "10px",
    height: '40px',
    width: '40px'
  },
  active: {
    padding: "10px 14px",
    textAlign: "center",
    borderRadius: "10px",
    color: "white",
    fontSize:"0.875rem",
    backgroundColor: "#5078E1",
    cursor: "pointer",
  },
  disactive: {
    padding: "10px 14px",
    textAlign: "center",
    borderRadius: "10px",
    fontSize:"0.875rem",
    cursor: "pointer",
    border: "1px solid #E4E8EE",
  },
  Label: {
    color: theme.typography.color.tertiary,
    fontSize:"0.75rem",
  },
  imgContainer: {
    width: "100%",
    maxWidth: "150px",
    margin: "0px 10px",
    borderRadius: theme.palette.borderRadius,
    display: "flex",
  },
  DialogCloseIcon: {
    color: "#7C8594",
    fontSize: "22px",
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    marginBottom: "12px",
    padding: "14px 18px",
    alignItems: "center",
    width: "100%",
  },
  bottomTitle: {
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: "#091B29",
    fontWeight: "600px",
  },
  quotationsummaryquest: {
    fontSize:"0.75rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().semiBold,
    fontWeight: "600",
  },
  waterLeakageIcons: {
    color: "#071741",
    fontFamily: FontFamilySwitch().semiBold,
    fontWeight: "600",
    marginRight: "4px",
  },

  quotationsummaryans: {
    fontSize:"0.875rem",
    color: "#000",
    fontFamily: FontFamilySwitch().semiBold,
    fontWeight: "600",
    marginBottom: "3px",
  },

  title: {
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: "#091B29",
    fontWeight: "600",
  },
  title2: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: "#98A0AC",
    fontWeight: "600",
  },
  contactDetailsName: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: "#091B29",
    fontWeight: "600",
  },
  menu: {
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: "#071741",
  },
  drawerwidth: {
    width: "380px",
    [theme.breakpoints.down("sm")]: {
      width: "318px",
    },
  },
  Header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "14px",
    borderBottom: "1px solid #00000012",
    position: "absolute",
    width: "100%",
    top: "0",
    height: "60px",
    padding: "16px 16px 16px",
    justifyContent: "left",
    backgroundColor: theme.palette.primary.contrastText,
    zIndex: "1",
    "& .MuiTypography-root": {
      fontSize:"1rem",
      fontWeight: "bold",
      fontFamily: FontFamilySwitch().bold,
    },
  },
  closeIcondrawermap: {
    right: "400px",
    position: "fixed",
    display: "flex",
    color: theme.palette.primary.contrastText,
    cursor: "pointer",
    fontSize: 28,
    marginTop: 8,
    "& .MuiSvgIcon-root": {
      fontSize: "35px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "28px !important",
      },
    },
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      right: "11px",
      color: theme.palette.primary.dark,
      top: "0px",
      fontSize:"0.875rem",
    },
  },
  createnewsection: {
    padding: "10px 15px 65px",
    height: "100vh",
    overflow: "auto",
    paddingTop: "63px",
  },
  btnparent: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    padding: "10px 16px",
    backgroundColor: "#fff",
    borderTop: "1px solid #00000012",
    "& .MuiButton-root": {
      width: "100%",
    },
  },
  Apply: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  clearall: {
    backgroundColor: "#fff",
    border: "1px solid #E4E8EE",
    color: "#091B29",
    "&:hover": {
      backgroundColor: "#fff",
      border: "1px solid #E4E8EE",
    },
  },
  contactDetails: {
    backgroundColor: "#FFFFFF",
    borderRadius: "5px",
    padding: "5px 0px 15px",
    alignItems: "center",
  },
  contactDetailsNumbers: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: "#7C8594",
    fontWeight: "600",
  },
  phoneIconBtn: {
    backgroundColor: "#E4E8EE",
    padding: "10px",
    color: "#7C8594",
    border: "1px solid #E4E8EE",
    borderRadius: "50px",
    float: "right",
    fontSize:"0.75rem",
    "&:hover": {
      backgroundColor: "#E4E8EE",
    },
  },
  phoneIcon: {
    fontSize:"1.125rem",
    "&:hover": {
      fontSize:"1.125rem",
    },
  },
  homeService: {
    padding: "2px 2px",
    backgroundColor: "#5078E11E",
    color: "#091B29",
    fontSize:"0.75rem",
    fontWeight: "600",
    fontFamily: FontFamilySwitch().regular,
    borderRadius: theme.palette.borderRadius,
    margin: "0px",
    lineheight: "1.5",
    textAlign: "center",
  },
  dialogStyle: {
    "& .MuiPaper-root": {
      minWidth: "700px",
      width: "100% !important",
      borderRadius: theme.palette.borderRadius,
      // minHeight: "487px",
    },
  },
  createnewsectionlog: {
    padding: "20px",
  },
  connecter: {
    marginTop: "-21px",
  },
  top: {
    marginTop: "-45px",
    "& li": {
      "&.css-ha3bif-MuiTimelineItem-root": {
        "&:before": {
          padding: "0px",
          display: "none",
        },
      },
    },
  },
  top1: {
    "& ul": {
      marginTop: "0px",
    },
    "& li": {
      "&.css-ha3bif-MuiTimelineItem-root": {
        "&:before": {
          padding: "0px",
          display: "none",
        },
      },
    },
  },
  labelTittle: {
    color: "#091B29",
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().bold,
  },
  description: {
    color: "#7C8594",
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  BorderLine: {
    borderRight: "1px solid #E4E8EE",
  },
  service: {
    backgroundColor: "#5078E11E",
    borderRadius: "5px",
    padding: "3px 10px",
    cursor: "pointer",
  },
  servicetittle: {
    fontSize:"0.75rem",
    color: "#091B29",
    fontWeight: 600,
  },
  buttonParent: {
    padding: "20px",
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
  },
  header: {
    border: "1px solid #E4E8EE",
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().extraBold,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "24px",
  },
  closeDialog: {
    "& .MuiPaper-root": {
      maxWidth: "400px !important",
      width: "100% !important",
      borderRadius: theme.palette.borderRadius,
    },
  },
  headers: {
    marginBottom: "5px",
    marginTop: "20px",
  },
  imagePreview: {
    display: "flex",
    alignItems: "center",
    overflowX: "scroll",
  },
  card: {
    boxShadow: "0px 10px 25px #0000000A",
    borderRadius: theme.palette.borderRadius,
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    padding: (props) => props?.padding ?? "19px",
    width: "100%",
    // overflow: "auto",
  },
  root1: {
    margin: "16px",
    overflow: "auto",
    height: "100%",
    paddingBottom: "145px",
    position: 'relative'
  },
  next: {
    marginLeft: "10px",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#fff",
    backgroundColor: "#5078E1",
    "&:hover": {
      backgroundColor: "#5078E1",
    },
  },
  Cancel: {
    backgroundColor: "#FFFFFF ",
    color: "#091B29",
    fontSize:"0.875rem",
    fontWeight: 600,
    fontFamily: FontFamilySwitch().semiBold,
    border: "1px solid #E4E8EE",
    "&:hover": {
      backgroundColor: "#FFFFFF ",
    },
  },
  bottombtn: {
    padding: "11px 16px",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 16px #00000014",
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #E4E8EE",
    position: "sticky",
    bottom: "0px",
    width: "100%",
    zIndex: 2,
  },
}));
