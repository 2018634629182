import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Avatar, Backdrop, Box, Button, CircularProgress, Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { SingleImgUpload } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, NetWorkCallMethods, useWindowDimensions,timeZoneConverter, FontFamilySwitch } from "../../../utils";
import { ContactDetails } from "./contactdetails";
import { RequestSummary } from "./request";
import { Service } from "./service";
const useStyles = makeStyles((theme) => ({
  image: {
    maxWidth: "75.6px",
    width: "100%",
    borderRadius: "10px",
    boxShadow: "0px 3px 6px #00000014",
  },
  Avatar: {
    backgroundColor: theme.palette.primary.main,
    width: "18px",
    height: "18px",
    fontSize:"0.75rem",
  },
  tittle: {
    color: theme.palette.primary.main,
    fontSize:"0.875rem"
  },
  tittle1: {
    color: "#98A0AC",
    fontSize:"0.875rem"
  },
  next: {
    marginLeft: "10px",
    fontFamily: FontFamilySwitch().semiBold,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  Cancel: {
    backgroundColor: "#E4E8EE ",
    color: "#091B29",
    fontSize:"0.875rem",
    fontWeight: 600,
    fontFamily: FontFamilySwitch().semiBold,
    "&:hover": {
      backgroundColor: "#E4E8EE ",
    },
  },
  outerCircle: {
    padding: "3px",
    border: "1px solid #5078E1",
    borderRadius: "50%",
  },
  btnFixed: {
    padding: "10px 24px",
  },
  main: {
    padding: "0px 24px",
    overflow: "auto",
  },
}));

export const MainRequest = (props) => {
  const initial = {
    category: "",
    unit: "",
    description: "",
    preferreddate: new Date(),
    subcategory: "",
    problem: "",
    since: "",
    altermobile: {
      mobile: props?.contact?.alternative_mobile_no,
      mobile_code: props?.contact?.mobile_no_country_code,
    },
    image: [],
    propperty: "",
    company: props?.selectedCompany,
    name: props?.contact?.first_name,
    mobile: {
      mobile: props?.contact?.mobile_no,
      mobile_code: props?.contact?.mobile_no_country_code,
    },
    error: {
      category: "",
      unit: "",
      description: "",
      preferreddate: "",
      subcategory: "",
      problem: "",
      since: "",
      altermobile: {
        mobile: "",
        mobile_code: "",
      },
      image: [],
      propperty: "",
      company: "",
      name: "",
      mobile: {
        mobile: "",
        mobile_code: "",
      },
    },
  };
  const alert = React.useContext(AlertContext);
  const [step, setStep] = React.useState(0);
  const classes = useStyles();
  const size = useWindowDimensions();
  const [data, setData] = React.useState(initial);
  const [change, setChange] = React.useState(false);
  const [disable, setDisable] = React.useState(false);
  const [images, setImages] = React.useState([]);
  const [uuid, setUUID] = React.useState(1);
  const [deletedAssetURL, setDeletedAssetURL] = React.useState([]);
  //const [subcatagory, setSubcatagory] = React.useState([]);
  //const [property, setProperty] = React.useState([]);
  //const [unit, setUnit] = React.useState([]);

  const previous = () => {
    setStep(step - 1);
  };
  const next = () => {
    if (step === 0) {
      if (validateData()) {
        setStep(1);
      } else {
        return false;
      }
    }
    if (step === 1) {
      if (validateContact()) {
        if (change) {
        }
        setStep(2);
      } else {
        return false;
      }
    }
    if (step === 2) {
      setStep(2);
    }
    setStep(step + 1);
  };

  const handleAssetFile = (data) => {

    setDeletedAssetURL([...deletedAssetURL, data.id]);
    setImages(images.filter((val) => val.url !== data.url));
  };

  const validateData = () => {
    let isValid = true;
    let error = data.error;
    if (data?.category?.length === 0) {
      isValid = false;
      error.category = "Category is Required";
    }
    if (data?.unit?.length === 0) {
      isValid = false;
      error.unit = "Unit is Required";
    }
    // if (data?.description?.length === 0) {
    //   isValid = false;
    //   error.description = "Description is Required";
    // }
    if (data?.preferreddate?.length === 0) {
      isValid = false;
      error.preferreddate = "Prefered date is Required";
    }
    if (data?.subcategory?.length === 0) {
      isValid = false;
      error.subcategory = "subcategory is Required";
    }
    if (data?.problem?.length === 0) {
      isValid = false;
      error.problem = "Problem is Required";
    }
    if (data?.since?.length === 0) {
      isValid = false;
      error.since = "Problem Since is Required";
    }

    if (data?.propperty?.length === 0) {
      isValid = false;
      error.propperty = "property is Required";
    }
    if (data?.company?.length === 0) {
      isValid = false;
      error.company = "Company is Required";
    }
    if (data?.category?.length === 0 || data?.unit?.length === 0 ||
      data?.preferreddate?.length === 0 || data?.subcategory?.length === 0 ||
      data?.problem?.length === 0 || data?.since?.length === 0 ||
      data?.propperty?.length === 0 || data?.company?.length === 0
    ) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all mandatory field",
      });
    }


    setData({ ...data, error });

    return isValid;
  };

  const validateContact = () => {
    let isValid = true;
    let error = data.error;
    if (data?.name?.length === 0) {
      isValid = false;
      error.name = "Name is Required";
    }
    if (data?.mobile?.mobile?.length === 0) {
      isValid = false;
      error.mobile = "Mobile is Required";
    }
    if (data?.altermobile?.mobile?.length === 0) {
      isValid = false;
      error.altermobile = "AlterNative Mobile is Required";
    }
    if (data?.name?.length === 0 || data?.mobile?.mobile?.length === 0
      || data?.altermobile?.mobile?.length === 0
    ) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all mandatory field",
      });
    }


    setData({ ...data, error });

    return isValid;
  }

  const updateState = (key, value) => {
    let error = data?.error;
    error[key] = "";
    if (key === "company") {
      setData({ ...data, propperty: "", company: value, unit: "", error });
    }
    else if (key === "propperty") {
      setData({ ...data, unit: "", propperty: value, error });
    } else {
      let error = data.error;
      error[key] = "";
      setData({ ...data, [key]: value, error });
    }
    if (key === "category") {
      setData({ ...data, category: value, subcategory: "", error });
    }
  };
  const changeFunction = () => {
    setChange(true);
  };

  const saveFunction = () => {
    if (validateContact()) {
      setChange(false);
    }
  };

  const saveMaintenanceRequest = () => {
    setDisable(true)
    const payload = {
      tenantId: `${config.tenantId}`,
      type: 2,
      subject: data?.problem,
      description: data?.description,
      category: data?.category?.value,
      sub_category: data?.subcategory?.value,
      problem_since: timeZoneConverter(data?.since , "YYYY-MM-DD"),
      preferred_time: timeZoneConverter(data?.preferreddate , "YYYY-MM-DD"),
      contact_name: data?.name,
      contact_mobile: data?.mobile?.mobile ?? undefined,
      contact_alternative_mobile: data?.altermobile?.mobile ?? undefined,
      image_urls: images?.map((x) => x.url),
      units: [data?.unit?.value],
      userProfileId: localStorage.getItem("profileID"),
      propertyId: data?.propperty?.value,
      companyId: data?.company?.value,
      mobile_country_code: data?.mobile?.mobile_code ?? undefined,
    };
    NetworkCall(
      `${config.api_url}/request/create`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setDisable(false)
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Request submitted successfully.",
        });
        props?.getTable(0, 10, "", "key", props?.selectedCompany);
        props?.AlertClose();
      })
      .catch((error) => {
        setDisable(false)

        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something went wrong please try again",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        });
      });
  };

  const Edit = () => {
    setDisable(true)
    const payload = {
      tenantId: `${config.tenantId}`,
      type: 2,
      id: props?.details?.table?.[0]?.id,
      subject: data?.problem,
      description: data?.description,
      category: data?.category?.value,
      sub_category: data?.subcategory?.value,
      problem_since: timeZoneConverter(data?.since ,"YYYY-MM-DD"),
      preferred_time: timeZoneConverter(data?.preferreddate , "YYYY-MM-DD"),
      contact_name: data?.name,
      contact_mobile: data?.mobile?.mobile ?? undefined,
      contact_alternative_mobile: data?.altermobile?.mobile?.length > 0 ? data?.altermobile?.mobile?.length : undefined,
      image_urls: images?.map((x) => x.url),
      units: [data?.unit?.value],
      userProfileId: localStorage.getItem("profileID"),
      propertyId: data?.propperty?.value,
      companyId: data?.company?.value,
      mobile_country_code: data?.mobile?.mobile_code ?? undefined,
      updated_at: new Date().toISOString(),
      updated_by: localStorage.getItem("profileID"),
    };
    NetworkCall(
      `${config.api_url}/request/update`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setDisable(false)
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Request Update successfully.",
        });
        props?.getTable(0, 10, "", "key", props?.selectedCompany);
        props?.AlertClose();
      })
      .catch((error) => {
        setDisable(false)
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong.",
        });
      });
  };

  React.useEffect(() => {
    if (props?.isEdit === true && props?.alertOpen) {
      setImages(props?.details?.images);
      setData({
        category: {
          value: props?.details?.table?.[0]?.catagory?.id ?? "",
          label: props?.details?.table?.[0]?.catagory?.type ?? "",
        },
        unit: {
          value: props?.details?.table?.[0]?.unit?.[0]?.unit?.id ?? "",
          label: props?.details?.table?.[0]?.unit?.[0]?.unit?.name ?? "",
        },
        description: props?.details?.table?.[0]?.description,
        preferreddate: new Date(props?.details?.table?.[0]?.prefferedtime),
        subcategory: {
          value: props?.details?.table?.[0]?.subcatagory?.id ?? "",
          label: props?.details?.table?.[0]?.subcatagory?.type ?? "",
        },
        problem: props?.details?.table?.[0]?.subject ?? "",
        since: new Date(props?.details?.table?.[0]?.problemsince) ?? "",
        altermobile: {
          mobile: props?.details?.table?.[0]?.alternative,
          mobile_code: "+93",
        },
        image: [],
        propperty: {
          value: props?.details?.table?.[0]?.unit?.[0]?.property?.id ?? "",
          label: props?.details?.table?.[0]?.unit?.[0]?.property?.name ?? "",
        },
        company: {
          value:
            props?.details?.table?.[0]?.unit?.[0]?.company_master?.id ?? "",
          label:
            props?.details?.table?.[0]?.unit?.[0]?.company_master?.name ?? "",
        },
        name: props?.details?.table?.[0]?.name,
        mobile: {
          mobile: props?.details?.table?.[0]?.mobile,
          mobile_code: props?.details?.table?.[0]?.mobile_country_code,
        },
        error: {
          category: "",
          unit: {
            value: "",
            label: "",
          },
          description: "",
          preferreddate: "",
          subcategory: "",
          problem: "",
          since: "",
          altermobile: {
            mobile: props?.contact?.alternative_mobile_no,
            mobile_code: props?.contact?.mobile_no_country_code,
          },
          image: [],
          propperty: "",
          company: "",
          name: props?.contact?.first_name,
          mobile: {
            mobile: props?.contact?.mobile_no,
            mobile_code: props?.contact?.mobile_no_country_code,
          },
        },
      });
    }
    // eslint-disable-next-line
  }, [props?.isEdit]);

  return (
    <div style={{ position: "relative" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props?.loading}
      >
        <CircularProgress color="primary" />
      </Backdrop>

      {/* steppers */}
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          margin: "9px 0px",
          padding: "0px 14px 7px",
          borderBottom: "1px solid #E4E8EE",
        }}
      >
        <Box style={{ display: "flex", alignItems: "center" }}>
          {step === 0 ? (
            <div className={classes.outerCircle}>
              <Avatar className={classes.Avatar}>1</Avatar>
            </div>
          ) : (
            ""
          )}

          <Typography
            className={step === 0 ? classes.tittle : classes.tittle1}
            style={{ marginLeft: "10px" }}
          >
            Service Details
          </Typography>
          <ChevronRightIcon
            className={step === 0 ? classes.tittle : classes.tittle1}
          />
        </Box>
        <Box style={{ display: "flex", alignItems: "center" }}>
          {step === 1 && (
            <div className={classes.outerCircle}>
              {" "}
              <Avatar className={classes.Avatar}>2</Avatar>
            </div>
          )}
          <Typography
            className={step === 1 ? classes.tittle : classes.tittle1}
            style={{ marginLeft: "10px" }}
          >
            Contact Details
          </Typography>
          <ChevronRightIcon
            className={step === 1 ? classes.tittle : classes.tittle1}
          />
        </Box>
        {/* step 3 */}
        <Box style={{ display: "flex", alignItems: "center" }}>
          {step === 2 && (
            <div className={classes.outerCircle}>
              {" "}
              <Avatar className={classes.Avatar}>3</Avatar>{" "}
            </div>
          )}
          <Typography
            className={step === 2 ? classes.tittle : classes.tittle1}
            style={{ marginLeft: "10px" }}
          >
            Upload Images
          </Typography>
          <ChevronRightIcon
            className={step === 1 ? classes.tittle : classes.tittle1}
          />
        </Box>
        {/* step 4 */}
        <Box style={{ display: "flex", alignItems: "center" }}>
          {step === 3 && (
            <div className={classes.outerCircle}>
              {" "}
              <Avatar className={classes.Avatar}>4</Avatar>{" "}
            </div>
          )}
          <Typography
            className={step === 3 ? classes.tittle : classes.tittle1}
            style={{ marginLeft: "10px" }}
          >
            Request Summary
          </Typography>
        </Box>
      </Box>
      <div className={classes.main} style={{ height: size?.height - 350, overflow: "auto" }}>
        {/* components  */}
        {step === 0 && (
          <Service
            data={data}
            updateState={updateState}
          //subcatagory={subcatagory}
          //property={property}
          //unit={unit}
          />
        )}
        {step === 1 && (
          <ContactDetails
            data={data}
            updateState={updateState}
            change={change}
            changeFunction={changeFunction}
            saveFunction={saveFunction}
          />
        )}
        {step === 2 && (
          <SingleImgUpload
            setUUID={setUUID}
            uuid={uuid}
            updateImageURL={setImages}
            handleFile={handleAssetFile}
            selectedImageURL={images}
            aaccept="image/*"
            companyId={data?.company?.value}
          />
        )}
        {step === 3 && <RequestSummary data={data} images={images} />}
      </div>

      {/* btn */}
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 0px",
        }}
        className={classes.btnFixed}
      >
        <Box>
          <Button
            variant="contained"
            disabled={step <= 0 && true}
            onClick={previous}
            className={classes.Cancel}
          >
            previous
          </Button>
        </Box>
        <Box>
          <Button
            variant="contained"
            // onClick={close}
            className={classes.Cancel}
          >
            Cancel
          </Button>

          {step === 3 ? (
            <>
              {props?.isEdit ? (
                <>
                  <Button
                    variant="contained"
                    onClick={Edit}
                    className={classes.next}
                    disabled={disable}
                  >
                    Update
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    disabled={disable}
                    variant="contained"
                    onClick={saveMaintenanceRequest}
                    className={classes.next}
                  >
                    Submit
                  </Button>
                </>
              )}
            </>
          ) : (
            <Button
              variant="contained"
              disabled={step >= 3 && true}
              onClick={next}
              className={classes.next}
            >
              Next
            </Button>
          )}
        </Box>
      </Box>
    </div>
  );
};
