import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch, remCalc } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px 16px 80px",
    backgroundColor: theme?.palette?.background?.secondary,
    height: `calc(100vh - 64px)`,
    overflow: "auto",
    [theme?.breakpoints.down("md")]: {
      padding: "10px 10px 80px",
    },
  },
  table_section: {
    padding: "16px",
    backgroundColor: theme?.palette?.background?.tertiary1,
  },
  table_header: {
    color: theme?.typography?.color?.primary,
    fontSize: remCalc(16),
    fontFamily: FontFamilySwitch().extraBold,
  },
  avatar: {
    marginInlineEnd: "8px"
  }
}));
