import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Avatar, Box, Hidden, List, Popover } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Collapse from "@mui/material/Collapse";
import MuiDrawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext } from "react";
import { useNavigate } from 'react-router-dom';
import Menu from '../../../assets/navBarIcons/menu';
import * as All from "../../../assets/navBarIconsNew";
import { AuthContext } from '../../../contexts';
import { CompanyContext } from '../../../contexts/CompanyContext';
import { Routes } from '../../../router/routes';
import { getMenuList } from '../../../utils';
import { LocalStorageKeys, useWindowDimensions } from "../../../utils/constants";
import { FontFamilySwitch } from '../../../utils/constants';
import { withTranslation } from 'react-i18next';
import { NetworkCall } from '../../../networkcall';
import { NetWorkCallMethods } from '../../../utils/constants';
import { config } from '../../../config';
const drawerWidth = 350;

const useStyles = makeStyles((theme) => ({
  root: {
    width: (props) => (props?.drawer ? drawerWidth : 300),
    position: "absolute",
    "& .MuiDrawer-paper": {
      marginLeft: (props) => (props?.size?.width > 1920 && ((props?.size?.width - 1920) * 0.5)),
      backgroundColor: "#333333",
      "&::-webkit-scrollbar": {
        width: "0em",
      },

      "& .MuiListItem-root": {
        padding: "6px 21px",
        [theme.breakpoints.down("md")]: {
          width: 275
        },
      },
    },
  },
  popover: {
    "& .MuiPaper-root": {
      marginLeft: (props) => (props?.size?.width > 1920 && (((props?.size?.width - 1920) * 0.5))),
      borderRadius: "0px",
      width: 350,
      backgroundColor: "white",
      height: `calc(100vh - 64px)`,
      [theme.breakpoints.down("md")]: {
        width: 250
      },
    }
  },
  popoverActiveSub: { color: "#1C1C1C", fontSize: "1rem", fontFamily: FontFamilySwitch().bold, textAlign: "start", cursor: "pointer" },
  popoverInActiveSub: { color: "#98A0AC", fontSize: "1rem", fontFamily: FontFamilySwitch().bold, textAlign: "start", cursor: "pointer" },
  popoverActiveSub1: { color: "#1C1C1C", fontSize: "1rem", fontFamily: FontFamilySwitch().regular, textAlign: "start", cursor: "pointer" },
  popoverInActiveSub1: { color: "#98A0AC", fontSize: "1rem", fontFamily: FontFamilySwitch().regular, textAlign: "start", cursor: "pointer" },
  drawerContainer: {
    overflow: "hidden",
  },
  text: {
    color: theme.palette.sidebar.primary,
    fontSize: "0.875rem",
  },
  text1: {
    color: theme.palette.primary.contrastText,
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
  },
  navBarTitle: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#FFFFFF",
    textAlign: "start"
  },
  btn: {
    padding: "6px 0px 0px 14px",

    "&.Mui-selected": {
      backgroundColor: "#5078E1",
      padding: "6px 0px 0px 14px",
      "& .MuiTypography-root": {
        color: "#fff !important",
      },
      "&:hover": {
        backgroundColor: "#5078E1",
      },
    },
  },
  menuList1: {
    background: "#333333",//1E1E1E
    "&:hover": {
      backgroundColor: "#333333"//1E1E1E
    },
  },
  menuList2: {
    background: "#1E1E1E",//4D4D4D
    "&:hover": {
      backgroundColor: "#1E1E1E"//4D4D4D
    },
  },

  menuList3: {

    background: "#4D4D4D",//4D4D4D
    "&:hover": {
      backgroundColor: "#4D4D4D"//4D4D4D
    },
  },

  menuList4: {
    padding: "6px 16px",
    marginRight: "8px",
    background: "white",//1E1E1E
    "&:hover": {
      backgroundColor: "white"//1E1E1E
    },
  },
  menuList5: {
    padding: "6px 16px",
    marginRight: "8px",
    borderRadius: theme.palette.borderRadius,
    background: "#F2F3F5",//1E1E1E
    "&:hover": {
      backgroundColor: "#F2F3F5"//1E1E1E
    },
  },
  menuList6: {
    padding: "6px 16px",
    background: "white",//1E1E1E
    "&:hover": {
      backgroundColor: "white"//1E1E1E
    },
  },
  selectedSubMenuList: {
    background: "#5078E1",
    "&:hover": {
      backgroundColor: "#5078E1"
    },
  },
  activeIcon: {
    backgroundColor: "#5078E1",
  },
  inActiveIcon: {
    backgroundColor: "transparent",
  },
  activeMainMenu: {
    color: "#FFFFFF",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    textAlign: "start",
    cursor: "pointer"

  },
  inActiveMainMenu: {
    color: "#C1C5CB",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().regular,
    textAlign: "start",
    cursor: "pointer"
  },
  activeSubMenu: {},
  inActiveSubMenu: {}
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  [theme.breakpoints.down("md")]: {
    width: 275
  },
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
});
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: 10,
  width: drawerWidth,
  [theme.breakpoints.down("md")]: {
    width: 275
  },
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px",
    padding: "8px 12px",
    backgroundColor: "#FFFFFF",
    color: "#1C1C1C",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#FFFFFF"
  },
}));

const SideNavBar = (props) => {

  const { t } = props;
  let private_route = JSON.parse(localStorage.getItem("private_route"));
  let size = useWindowDimensions()
  const classes = useStyles({ ...props, size });
  const navigate = useNavigate();
  const authContext = useContext(AuthContext)
  const { selectedCompany } = useContext(CompanyContext);
  const [open, setOpen] = React.useState(props?.isMobile || false);
  const [secondMenu, setSecondMenu] = React.useState(false);
  const [secondMenuItem, setSecondMenuItem] = React.useState({});
  const [selected, setSelected] = React.useState();

  const [mouseX, setMouseX] = React.useState();
  const [permissions, setPermissions] = React.useState([]);
  const handleDrawerOpen = () => {
    setMouseX(document?.documentElement?.clientWidth);
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const openExternalLink = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const onListClick = (data) => {
    setSelected(data?.id);
    if (data?.link !== null && data?.link?.length > 0) {
      setOpen(true);
      if (data?.link?.[0]) {
        if ([window.pathname, private_route?.match?.path].includes(data?.link?.[0])) {
          navigate(data?.router, {
            replace: data?.is_report ? false : true,
            state: { reportId: data?.id, end_point: data?.end_point }
          });
        } else {
          navigate(data?.router, {
            state: { reportId: data?.id, end_point: data?.end_point }
          });
        }
      } else {
        openExternalLink(data?.router);
      }
    }
  };
  
  const AccessRoute = (userRole) => {
    let activeRole = authContext?.auth?.auth?.activeRole ?? ""
    let module = authContext?.auth?.auth?.access[activeRole] ?? {}
    let navBarMenuList = module?.role?.permissions?.[0]?.structure ?? []
    if (navBarMenuList) {
      return getMenuList(navBarMenuList)
    } else {
      return -1
    }
  };

  React.useEffect(() => {
    if (authContext?.auth?.auth?.access) {
      let _ = authContext?.auth?.auth
      if (_?.prevHistoryKey === window?.history?.state?.key) {
        menuSelection();
      }
    }
    // eslint-disable-next-line
  }, [authContext]);

  React.useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const selectedCompany = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany));
        const companyId = selectedCompany?.value;

        if (!companyId) {
          console.error("No company ID found");
          return;
        }

        const response = await NetworkCall(
          `${config.api_url}/check-company-permission/check_company_permissions`,
          NetWorkCallMethods.post,
          { company_ids: [companyId] }, 
          null,
          true,
          false
        );
        if (response.status === 200 && response.data) {
          setPermissions(response.data.data);
        }
      } catch (error) {
        console.error("Network call error:", error);
      }
    };
  
    fetchPermissions();
  }, [selectedCompany]);

  const menuSelection = () => {
    let tempMenuItem = null;
    AccessRoute(localStorage.getItem(LocalStorageKeys.activeRole))?.length > 0 &&
      AccessRoute(localStorage.getItem(LocalStorageKeys.activeRole))?.map(
        // eslint-disable-next-line
        (navBar, index) => {
          if (navBar?.link?.includes(window.location.pathname) ||
            navBar?.link?.includes(private_route?.match?.path)) {
            tempMenuItem = navBar.id;
            props?.setMenuItem(navBar.id);
            // eslint-disable-next-line
            navBar?.children?.map((child1, index) => {
              if (child1?.link?.includes(window.location.pathname) ||
                child1?.link?.includes(private_route?.match?.path)) {
                props.setSubMenuItem(child1.id);
              }
            });
          }
        });
    if (!Boolean(tempMenuItem)) {
      let route_values = authContext?.auth?.auth?.routes?.map((route) => Routes?.[route])
      if (route_values?.includes(window.location.pathname) ||
        route_values?.includes(private_route?.match?.path)) {
        props.handleLoading(false)
      }
    }
  }

  const closePopOver = () => {
    setSecondMenu(false);
    props?.setMenuItem(null);
    setSecondMenuItem(null);
  }

  const getNavIcon = (icon, color) => {
    return All[icon] ? All[icon]?.({ fill: color }) : All["Dashboard"]?.({ fill: color })
  }

  return (
    <ClickAwayListener onClickAway={handleDrawerClose}>
      <Box
        sx={{ display: "flex" }}
        className={classes.root}>
        <Drawer className={classes.drawer} variant="permanent" open={open} anchor={authContext?.auth?.auth?.language === "ar" ? 'right' : 'left'}>
          <List style={{ paddingTop: "0px" }}>
            <Box height="64px" />
            <Hidden mdDown>
              <ListItem style={{ height: "55.5px" }}>
                <ListItemIcon
                  onClick={!open ? handleDrawerOpen : handleDrawerClose}
                  style={{ cursor: "pointer" }}>
                  <Avatar
                    variant={"circular"}
                    className={classes.inActiveIcon}
                    style={{ height: "35px", width: "35px" }}>
                    <Menu fill="white" />
                  </Avatar>
                </ListItemIcon>
                <ListItemText
                  primary={authContext?.auth?.auth?.activeRole ?? ""}
                  classes={{ primary: classes.navBarTitle }} />
              </ListItem>
            </Hidden>

            {/* <Box style={{ padding: open ? "10px 21px" : "10px 16px 10px 21px" }}>
              <Box style={{ width: "inherit" }} />
            </Box> */}
            {
              AccessRoute(localStorage.getItem(LocalStorageKeys.activeRole))?.length > 0 &&
              AccessRoute(localStorage.getItem(LocalStorageKeys.activeRole))?.map(
                (navBar, index) => {
                  const activeMain = navBar?.is_report ? (navBar?.id === selected ? true : false) : (navBar?.link?.includes(window.location.pathname) || navBar?.link?.includes(private_route?.match?.path));

                  return (
                    <React.Fragment>
                      <ListItem
                        key={index}
                        button
                        className={(open && navBar?.children && (props?.menuItem === navBar.id)) ? classes.menuList2 : classes.menuList1}
                        onClick={() => {

                          handleDrawerOpen();
                          if (open && navBar?.router) {
                            onListClick(navBar);
                          } else if (navBar?.children?.length > 0) {
                            setSecondMenuItem(navBar)
                            props?.setMenuItem(
                              props?.menuItem === navBar?.id ? null : navBar?.id)

                            setSecondMenu(!secondMenu)
                          }
                        }}>
                        <ListItemIcon>

                          <LightTooltip title={!open ? t(navBar?.name) : ""} placement="right"
                            arrow>
                            <Avatar
                              variant={"circular"}
                              className={activeMain ? classes.activeIcon : classes.inActiveIcon}
                              style={{ height: "35px", width: "35px" }}>
                              {activeMain ? getNavIcon(navBar?.nav_icon, "white") : getNavIcon(navBar?.nav_icon, "#c1c5cb")}
                            </Avatar>
                          </LightTooltip>
                        </ListItemIcon >
                        <ListItemText primary={t(navBar?.name)} classes={{ primary: activeMain ? classes.activeMainMenu : classes.inActiveMainMenu }} />
                        {navBar?.children?.length > 0 && <ListItemIcon style={{ justifyContent: authContext?.auth?.auth?.language === "ar" ? '' : 'end', transform: authContext?.auth?.auth?.language === "ar" ? `rotate(180deg)` : '' }}><ChevronRightIcon htmlColor="#C1C5CB" /></ListItemIcon>}
                      </ListItem >
                    </React.Fragment >
                  )
                }
              )}
          </List >
        </Drawer >
        <Popover
          className={classes.popover}
          marginThreshold={64}
          open={secondMenu}
          onClose={closePopOver}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 0, left: authContext?.auth?.auth?.language === "ar" ? mouseX - 350 + "" : props?.isMobile ? 260 : 350 }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: authContext?.auth?.auth?.language === "ar" ? 'right' : 'left',
          }}>
          <div>
            <ListItem style={{ height: "55.5px", backgroundColor: "#eef5fb" }}>
              <ListItemIcon>
                <Avatar
                  variant={"circular"}
                  className={classes.inActiveIcon}
                  style={{ height: "35px", width: "35px" }}>
                  {secondMenu && getNavIcon(secondMenuItem?.nav_icon, "#1C1C1C")}
                </Avatar>
              </ListItemIcon>

              <ListItemText
                primary={t(secondMenuItem?.name)}
                classes={{ primary: classes.popoverActiveSub }} />
            </ListItem >
            {/* <Box style={{ padding: "10px 21px" }}>
              <Box style={{ width: "inherit" }} />
            </Box> */}
            < div style={{ overflow: "overlay", height: `calc(100vh - 160px)` }}>
              <List component="div" disablePadding>
                {secondMenuItem?.children?.map((child1, index) => {
                  if (permissions.every(item => item.is_active) === false && child1.company_permission_meta_data === true) {
                    return null;
                  }
                  const activeSub1 = child1?.is_report ? (child1?.id === selected ? true : false) : (child1?.link?.includes(window.location.pathname) || child1?.link?.includes(private_route?.match?.path));
                  return (
                    <React.Fragment>
                      <ListItem
                        key={index}
                        button
                        className={classes.menuList6}
                        onClick={() => {
                          if (open) {
                            if (child1?.router) {
                              setSecondMenu(!secondMenu);
                              onListClick(child1);
                            } else if (child1?.children?.length > 0) {
                              props.setSubMenuItem(
                                props.subMenuItem === child1.id ? null : child1.id);
                            }
                          } else {
                            handleDrawerOpen();
                          }
                        }}>
                        <ListItemIcon>
                          <Avatar
                            variant={"circular"}
                            className={classes.inActiveIcon}
                            style={{ height: "35px", width: "35px" }}>
                            {activeSub1 ? getNavIcon(child1?.nav_icon, "#1C1C1C") :
                              getNavIcon(child1?.nav_icon, "#c1c5cb")}
                          </Avatar>
                        </ListItemIcon>

                        <ListItemText primary={t(child1?.name)} classes={{
                          primary: activeSub1 ?
                            classes.popoverActiveSub :
                            classes.popoverInActiveSub
                        }} />
                        {
                          child1?.children?.length > 0 &&
                          ((props?.subMenuItem === child1.id) ?
                            <ListItemIcon style={{ justifyContent: "end" }}><ExpandLessIcon htmlColor={activeSub1 ? "#1C1C1C" : "#98A0AC"} /></ListItemIcon> :
                            <ListItemIcon style={{ justifyContent: authContext?.auth?.auth?.language === "ar" ? '' : 'end', transform: authContext?.auth?.auth?.language === "ar" ? `rotate(180deg)` : '' }}><ChevronRightIcon htmlColor={activeSub1 ? "#1C1C1C" : "#98A0AC"} /></ListItemIcon>)
                        }
                      </ListItem >
                      <Collapse
                        in={(open && (props?.subMenuItem === child1.id))}
                        timeout="auto"
                        unmountOnExit>
                        <List component="div" disablePadding>
                          {child1?.children?.map((child2, index) => {
                            const activeSub2 = child2?.is_report ? (child2?.id === selected ? true : false) : (child2?.link?.includes(window.location.pathname) || child2?.link?.includes(private_route?.match?.path));
                            return (
                              <div style={{ display: "flex" }}>
                                <Box width={"45px"} />
                                <Box style={{ backgroundColor: "#DBDBDB", width: "1px", height: "48px" }} />
                                <Box width={"50px"} />
                                <ListItem
                                  key={index}
                                  button
                                  className={activeSub2 ? classes.menuList5 : classes.menuList4}
                                  onClick={() => {
                                    if (child2?.router) {
                                      setSecondMenu(!secondMenu);
                                      onListClick(child2);
                                    }
                                  }}>

                                  <ListItemText primary={t(child2?.name)} classes={{
                                    primary: activeSub2 ?
                                      classes.popoverActiveSub1 :
                                      classes.popoverInActiveSub1
                                  }} />
                                </ListItem >
                              </div >
                            )
                          })}
                        </List >
                      </Collapse >
                    </React.Fragment >
                  )
                })}
              </List >
            </div ></div >
        </Popover >
      </Box >
    </ClickAwayListener >
  );
};

export default withTranslation("sideNavbar")(SideNavBar)

