import makeStyles from "@mui/styles/makeStyles";
import { Typography } from "@mui/material";
import styled from '@mui/material/styles/styled';
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  content: {
    // height: (size) => (size?.height - 180),
    height: "calc(100vh - 81px)",
    overflow: "auto",
    // padding: "16px",
  },
  imagebox: {
    textAlign: 'center',
    padding: '85px 16px',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: '4px',
    height: "382px"
  },
  secondbox: {
    padding: '16px',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: '4px',
    height: "385px",
    overflow: "auto"
  },
  imagebox1: {
    padding: '16px',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: '4px',

  },
  button: {
    padding: '8px 16px',
    borderRadius: '4px',
    cursor: 'pointer',
    border: '1px solid #E4E8EE',
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    marginTop: '8px'
  },
  Requirements: {
    padding: "10px",
    textAlign: "center",
    borderRadius: theme.palette.borderRadius,
    color: "white",
    fontSize:"0.875rem",
    backgroundColor: "#5078E1",
    cursor: "pointer",
  },
  Requirementsqfts: {
    padding: "10px",
    textAlign: "center",
    borderRadius: theme.palette.borderRadius,
    fontSize:"0.875rem",
    cursor: "pointer",
    border: "1px solid #E4E8EE",
  },
  completedCheckboxStyle: {
    height: "20px",
    width: "20px",
    backgroundColor: "#5078E1",
    border: "1px solid #5078E1",
  },
  checkboxIconStyle: {
    fontSize: "small",
    color: "white",
  },
  checkboxStyle: {
    height: "20px",
    width: "20px",
    backgroundColor: "white",
    border: "1px solid #98A0AC",
  },
  avatar: {
    height: '120px',
    width: '120px'
  },
  title: {
    fontSize:"0.75rem",
    color: '#4E5A6B',
    fontFamily: FontFamilySwitch().bold,
    marginBottom: '12px'
  },
  label: {
    color: theme.typography.color.tertiary,
    fontFamily: FontFamilySwitch().semiBold,
    fontSize:"0.875rem",
    marginBottom: "5px",
  },
  tabListAssests: {
    display: 'flex'
  },
  listText: {
    backgroundColor: '#5078E1',
    width: '120px !important',
    color: '#fff',
    borderRadius: '4px',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  listText1: {
    width: '120px !important',
    color: '#98A0AC',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  tabname: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().bold,
    cursor: 'pointer'
  },
  list: {
    '&.MuiListItem-root': {
      width: '135px !important',
      padding: '0px!important',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      border: '1px solid #E4E8EE',
      cursor: 'pointer'
    }
  },
  next: {
    marginLeft: "10px",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#fff",
    backgroundColor: '#5078E1',
    "&:hover": {
      backgroundColor: '#5078E1',
    },
  },
  Cancel: {
    backgroundColor: "#FFFFFF ",
    color: "#091B29",
    fontSize:"0.875rem",
    fontWeight: 600,
    fontFamily: FontFamilySwitch().semiBold,
    border: '1px solid #E4E8EE',
    "&:hover": {
      backgroundColor: "#FFFFFF ",
    },
  },
  bottombtn: {
    padding: '11px 16px',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 16px #00000014',
    display: 'flex',
    justifyContent: 'end',
    border: '1px solid #E4E8EE',
    position: 'sticky',
    bottom: '0px',
    width: '100%',
    zIndex: 2,
  },
  removeimg: {
    textDecoration: 'underline',
    color: '#5078E1',
    fontFamily: FontFamilySwitch().semiBold,
    fontSize:"0.75rem",
    marginTop: '6px',
    cursor: 'pointer'
  },
  avatar1: {
    padding: '35px',
    background: '#98A0AC',
    height: '120px',
    width: '120px'
  },
  tabtitle: {
    fontSize:"0.75rem",
    color: '#4E5A6B',
    fontFamily: FontFamilySwitch().bold,
  },
  tabtitle1: {
    fontSize:"0.75rem",
    color: '#5078E1',
    fontFamily: FontFamilySwitch().bold,
  },
  subtabtitle: {
    fontSize:"0.75rem",
    color: '#4E5A6B',
    fontFamily: FontFamilySwitch().regular,
  },
  subtabtitle1: {
    fontSize:"0.75rem",
    color: '#5078E1',
    fontFamily: FontFamilySwitch().regular,
  },
  selectBack1: {
    // backgroundColor: '#F1F7FF',
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    borderTopLeftRadius: "12px",
    // borderTopRightRadius: "12px",
    borderRight:"1px solid #E4E8EE"
  },
  selectBack: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    borderRight:"1px solid #E4E8EE"
  },
  fieldLabel: {
    color: theme.typography.color.tertiary,
    fontFamily: FontFamilySwitch().semiBold,
    fontSize:"0.875rem",
    marginBottom: "5px",
  },
  buttonSelected: {
    borderRadius: theme.palette.borderRadius,
    height: "45px",
    border: "1px solid #5078E1",
    backgroundColor: "#5078E1",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#5078E1 ",
    },
  },
  buttonUnSelected: {
    borderRadius: theme.palette.borderRadius,
    height: "45px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#E4E8EE ",
    },
  },
  buttonTextSelected: {
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "white",
  },
  buttonTextUnSelected: {
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#4E5A6B",
  },
  kycContent: {
    height: "calc(100vh - 230px)",
    background: "#fff",
    overflow: "auto",
    padding: "16px",
    boxShadow: "0px 0px 16px #00000014"
  },
  box: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "12px"
  },
  required: {
    color: "red",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: (props) => props?.labelSize ?? theme.palette.labelFontSize,
    marginBottom: (props) => props?.labelMarginBottom ?? "5px",
  }
}));

export const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize:"0.75rem",
  fontFamily: FontFamilySwitch().bold,
  color: theme.typography.color.secondary,
  marginBottom: theme.spacing(1)
}))