import Close from '@mui/icons-material/Close'
import { Box, Button, Divider, Drawer, Grid, Stack, Typography } from "@mui/material"
import moment from "moment"
import React, { useContext, useState } from "react"
import { withTranslation } from 'react-i18next'
import { useNavigate, useLocation } from "react-router-dom"
import { withNavBars } from "../../../HOCs"
import { CircleIcon, CloseIconWithBG, InspectionIcon } from "../../../assets"
import { AssignIcon } from "../../../assets/assignIcon"
import { CheckCircleIcon } from '../../../assets/checkCircleIcon'
import { ServiceInspectionIcon } from '../../../assets/serviceInspection'
import { TrackClockIcon } from '../../../assets/trackClockIcon'
import { AlertDialog, EditInspectionHour, IframeViwer, LoadingSection, Subheader, TemplateGenerator, TextBox, VerticalLinearStepper } from "../../../components"
import { CheckListDetails } from '../../../components/accordianWithDetails/CheckListDetails'
import { ItemDetailsCard } from '../../../components/accordianWithDetails/itemDetails'
import AssignModal from "../../../components/assignModal/reAssignModal"
import { config } from "../../../config"
import { AlertContext, AuthContext, BackdropContext } from "../../../contexts"
import { NetworkCall } from "../../../networkcall"
import { AlertProps, JobType, NetWorkCallMethods, RemoveZUTCDateTime, useWindowDimensions } from "../../../utils"
import { useStyles } from "../style"
import { RequestCard } from "./requestCard"



const ViewDeliveryOrderDetails = ({
    t
}) => {
    const size = useWindowDimensions()
    const { state } = useLocation()
    const navigate = useNavigate()
    const alert = useContext(AlertContext)
    const classes = useStyles()
    const [assignModal, setAssignModal] = useState(false)
    const [details, setDetails] = useState([])
    const [isComplete, setIsComplete] = useState(false)
    const [loading, setLoading] = useState(false)
    const [reAssignModal, setReAssignModal] = useState(false)
    const [cancelModal, setCancelModal] = useState(false)
    const [description, setDescription] = useState("")
    const [showTrack, setShowTrack] = useState(false)
    const [itemMappingDetails, setItemMappingdetails] = React.useState({})
    const [isBilledHour, setIsBilledHour] = React.useState(false)
    const auth = React.useContext(AuthContext);
    const [notesData, setNotesData] = useState({
        managerNotes: [],
        inspectorNotes: [],
        costing_materials_notes: ""
    })
    const [template, setTemplate] = React.useState({
        bool: false,
        type: ""
    });
    const [pdf, setPdf] = React.useState({
        bool: false,
        data: ""
    })
    const [isView, setIsView] = React.useState({
        bool: false,
        is_unit: false,
        is_product: false
    })
    const [openpdf, setOpenpdf] = React.useState(false);
    const backdrop = React.useContext(BackdropContext)
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)

    const getInspectionDetails = () => {
        setLoading(true)
        const payload = {
            "request_id": state?.request_id
        }
        NetworkCall(
            `${config.api_url}/general_inspection/details`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setLoading(false)
                setDetails({
                    data: response?.data?.data?.details,
                    list: response?.data?.data?.list,
                    cardDetails: response?.data?.data?.card_detail,
                    maintenance_job_visit: response?.data?.data?.maintenance_job_visit,
                    itemDetails: {
                        move_in_details: {
                            penalty_amount: response?.data?.data?.details?.agreement_inspection?.penalty_amount,
                            inspection_manager_notes: response?.data?.data?.details?.agreement_inspection?.inspection_manager_notes ?? null,
                            inspector_notes: response?.data?.data?.details?.agreement_inspection?.inspector_notes ?? null,
                            costing_notes: response?.data?.data?.details?.agreement_inspection?.costing_materials_notes ?? null,
                            discrepancy_note: response?.data?.data?.details?.agreement_inspection?.discrepancy_note ?? null,
                        },
                        check_list: response?.data?.data?.check_list
                    }
                })
                if (response?.data?.data?.card_detail?.item_details?.id) {
                    getItemDetails(response?.data?.data?.card_detail?.item_details?.id)
                }
                setNotesData({ managerNotes: response?.data?.data?.details?.agreement_inspection?.inspection_manager_notes ?? [], inspectorNotes: response?.data?.data?.details?.agreement_inspection?.inspector_notes ?? null, costing_materials_notes: response?.data?.data?.details?.agreement_inspection?.costing_materials_notes ?? null })
            })
            .catch((error) => {
                setLoading(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),
                });
            });
    }
    React.useEffect(() => {
        getInspectionDetails()
        //eslint-disable-next-line
    }, [])
    const goBack = () => {
        navigate(-1)
    }
    const openAssignModal = () => {
        setAssignModal(true)
    }

    //for assigning
    const handleAssign = (payload) => {
        setIsDisableBtn(true)
        NetworkCall(
            `${config.api_url}/agreement_inspection/assign_resource`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Assigned Successfully"),
                });
                getInspectionDetails()
                setAssignModal(false)
            }).catch((err) => {
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("data not found"),
                });
            })
    }
    //reassign submit
    const reAssign = (payload) => {
        setIsDisableBtn(true)

        NetworkCall(
            `${config.api_url}/agreement_inspection/re_assign_resource`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Re Assigned Successfully"),
                });
                getInspectionDetails()
                setReAssignModal(false)
            }).catch((err) => {
                setIsDisableBtn(false)
                if(err?.response?.data?.error?.message?.msg) {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: err?.response?.data?.error?.message?.msg,
                    });
                }
                else {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Something went wrong"),
                    });
                }
            })
    }
    const completeInspection = () => {
        setIsDisableBtn(true)
        const payload = {
            agreement_inspection_id: state?.agreement_inspection_id,
            description: description
        }
        NetworkCall(
            `${config.api_url}/general_inspection/complete_inspection`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setIsDisableBtn(false)

            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Inspection Completed Successfully"),
            });
            setIsComplete(false)
            getInspectionDetails()
        }).catch((err) => {
            setIsDisableBtn(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something went wrong"),
            });
        })
    }


    const SwitchButtons = (val) => {
        switch (val) {
            case "Yet to assign":
                return (
                    <Grid container spacing={1} alignItems={"center"}>
                        {/* <Box onClick={() => setShowTrack(true)}><Typography className={classes.viewTrack}>{t("View Tracker")}</Typography></Box> */}
                        <Grid item xs={12}>
                            <Button
                                variant="outlined"
                                fullWidth
                                className={classes.cancelButton}
                                onClick={() => setCancelModal(true)}
                            >{t("Cancel")}
                            </Button>
                        </Grid>
                    </Grid>
                )
            case "Assigned":
                return (
                    <Grid container spacing={1} alignItems={"center"}>
                        {/* <Box onClick={() => setShowTrack(true)}><Typography className={classes.viewTrack}>{t("View Tracker")}</Typography></Box> */}
                        <Grid item xs={12} lg={12}>
                            <Button
                                variant="outlined"
                                fullWidth
                                className={classes.cancelButton}
                                onClick={() => setCancelModal(true)}
                            >{t("Cancel")}
                            </Button>
                        </Grid>
                    </Grid>
                )
            case "Yet To Verify":
                return (
                    <Grid container spacing={1} alignItems={"center"}>
                        {/* <Box onClick={() => setShowTrack(true)}><Typography className={classes.viewTrack}>{t("View Tracker")}</Typography></Box> */}
                        <Grid item xs={12} lg={4}>
                            <Button
                                variant="outlined"
                                fullWidth
                                className={classes.cancelButton}
                                onClick={() => setCancelModal(true)}
                            >{t("Cancel")}
                            </Button>
                        </Grid>
                        <Grid item xs={12} lg={8}>
                            <Button
                                variant="contained"
                                fullWidth
                                className={classes.accoCardButton}
                                onClick={() => details?.data?.inspection_detail?.billable_hour === null ? setIsBilledHour(true) : setIsComplete(true)}>{t("Complete Inspection")}
                            </Button>
                        </Grid>
                    </Grid>)
            default:
                return <Box></Box>
            // return <Box onClick={() => setShowTrack(true)}><Typography className={classes.viewTrack}>{t("View Tracker")}</Typography></Box>;
        }

    }
    const getItemDetails = (inspection_item_map) => {
        const payload = {
            "mapping_id": inspection_item_map ?? null,
        }
        NetworkCall(
            `${config.api_url}/inspection_jobs/asset_details`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setItemMappingdetails({ item_details: { ...res?.data?.data?.item_details, item_name: res?.data?.data?.item_details?.name, location_name: res?.data?.data?.item_details?.location, assets: res?.data?.data?.item_details?.item_asset, manufacturer_name: res?.data?.data?.item_details?.manufacturer }, groupDetails: res?.data?.data?.check_list_group?.[0] })
        }).catch((err) => {
            console.log(err)
        })
    }
    const cancelInspection = () => {
        setIsDisableBtn(true)
        const payload = {
            description: description,
            "agreement_inspection_id": state?.agreement_inspection_id,
        }
        NetworkCall(
            `${config.api_url}/agreement_inspection/cancel_inspection`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setIsDisableBtn(false)

                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: `${JobType(state?.request_type)} ${t("Inspection Cancelled Successfully")}`,
                });
                setCancelModal(false)
                getInspectionDetails()
            }).catch((err) => {
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something Went Wrong"),
                });
                setLoading(false)
            })

    }
    //on download pdf
    // const downloadPdf = () => {
    //     if (details?.data?.agreement_inspection?.asset_url) {
    //         saveAs(details?.data?.agreement_inspection?.asset_url, `${details?.data?.agreement_inspection?.reference_id}.pdf`)
    //     }
    // }
    const generate = async (value) => {
        setOpenpdf(true)
        await backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "...Loading",
        });
        const payload = {
            agreement_inspection_id: state?.agreement_inspection_id,
            type_master_id: value?.value

        };
        await NetworkCall(
            `${config.api_url}/general_inspection/template`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                setPdf({
                    bool: true,
                    data: res?.data
                })
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
            })
            .catch((err) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some thing went wrong"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            });
    }
    const onUpdateNotes = (val) => {
        setIsDisableBtn(true)
        const payload = {
            id: state?.agreement_inspection_id,
            update: {
                inspection_manager_notes: [...notesData?.managerNotes, { notes: val }],
            }
        }
        NetworkCall(
            `${config.api_url}/queries/agreement_inspection/update`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setIsDisableBtn(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Notes Added Successfully"),
            });
            setCancelModal(false)
            getInspectionDetails()
        }).catch((err) => {
            setIsDisableBtn(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something went wrong"),
            });
        })
    }

    const handleClose = () => {
        setIsView({ ...isView, bool: false })
    }
    const handleDownload = () => {
        setTemplate({
            bool: true,
            type: 'view'
        })
    }
    const reload = () => {
        getInspectionDetails()
    }
    return (
        <Box>
            <Subheader
                title={state?.reference_id}
                goBack={goBack}
            />
            {
                loading ?
                    <LoadingSection top="20vh" message={"Fetching Details"} />
                    :
                    <Box p={2}>
                        <Grid container spacing={2}>
                            <Grid item lg={3} sm={5} md={3} xs={6}>
                                <Box className={classes.rootblock} style={{ height: size?.height - 150 }}>
                                    <Box style={{ height: details?.data?.agreement_inspection?.status === "Yet To Verify" ? size?.height - 230 : (details?.rescheduleDetails?.[0]?.status === "Rescheduled" ? size?.height - 210 : size?.height - 150), overflow: "scroll" }}>

                                        {(details?.data?.agreement_inspection?.status === "Yet To Verify" || details?.data?.agreement_inspection?.status === "Completed") &&

                                            <EditInspectionHour
                                                t={t}
                                                reload={reload}
                                                agreement_inspection_id={state?.agreement_inspection_id}
                                                resource_id={details?.data?.resource_details?.find(i => i?.is_primary)?.id}
                                                status={details?.data?.agreement_inspection?.status}
                                            />

                                        }
                                        <Box mt={1}>
                                            <RequestCard
                                                t={t}
                                                title={details?.data?.agreement_inspection?.maintenance_id !== null ? t("Maintenance Request Details") : t("General Request Details")}
                                                subTitle={details?.data?.maintenance?.maintenance_request_no}
                                                // subTitle1={details?.data?.maintenance?.subject}
                                                backgroundColor="#EEF9EE"
                                                icon={<ServiceInspectionIcon />}
                                                maintenanceRequestType={details?.data?.agreement_inspection?.maintenance_id !== null ? "Maintenance" : "General"}
                                                maintenanceData={details?.data?.maintenance}
                                            />
                                        </Box>

                                        {details?.data?.inspection_detail &&
                                            <Box mt={1.5}>
                                                <RequestCard
                                                    t={t}
                                                    title={t("Inspection Detail")}
                                                    subTitle={details?.data?.inspection_detail?.reference_id}
                                                    subTitle1={`Inspection on ${moment(details?.data.inspection_detail?.created_at).format("DD MMM YY")}`}
                                                    icon={<InspectionIcon />}
                                                    status={details?.data?.agreement_inspection?.status}
                                                    unit_details={details?.data?.unit_details}
                                                />
                                            </Box>
                                        }
                                        {details?.data?.resource_details?.length > 0 ?
                                            <Box mt={1.5}>
                                                <RequestCard
                                                    t={t}
                                                    title={t("Resource Details")}
                                                    subTitle={details?.data?.resource_details?.[details?.data?.resource_details?.length - 1]?.name}
                                                    subTitle1={`${details?.data?.resource_details?.[details?.data?.resource_details?.length - 1]?.mobile_no_country_code} ${details?.data?.resource_details?.[details?.data?.resource_details?.length - 1]?.mobile_no}`}
                                                    image={true}
                                                    date={details?.data?.agreement_inspection?.execution}
                                                    AllResources={details?.data?.resource_details}
                                                    agreement_inspection_id={details?.data?.agreement_inspection?.id}
                                                    reAssignModal={reAssignModal}
                                                    setReAssignModal={setReAssignModal}
                                                    reAssign={reAssign}
                                                    status={details?.data?.agreement_inspection?.status}
                                                    rescheduleDetails={details?.rescheduleDetails}
                                                />
                                            </Box>
                                            :
                                            <Box mt={1.5}>
                                                <RequestCard
                                                    t={t}
                                                    assign={true}
                                                    icon={<AssignIcon />}
                                                    openAssignModal={openAssignModal}
                                                />
                                            </Box>
                                        }
                                        {details?.data?.inspection_tracker?.length > 0 &&
                                            <Box mt={2}>
                                                <RequestCard
                                                    t={t}
                                                    title={t("Track Update")}
                                                    details={details?.data?.inspection_tracker}
                                                    subTitle={details?.data?.inspection_tracker?.[0]?.title}
                                                    subTitle1={`By ${details?.data?.inspection_tracker?.[0]?.created_by}, ${moment(details?.data?.inspection_tracker?.[0]?.created_at).format("DD MMM YY HH:mm a")}`}
                                                    icon={<CircleIcon />}
                                                />
                                            </Box>
                                        }
                                    </Box>
                                    <Box className={classes.btnfix}>{SwitchButtons(details?.data?.agreement_inspection?.status)}</Box>

                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={7} md={9} lg={9}>
                                <Box>
                                    {details?.cardDetails?.item_details ?
                                        <Box className={classes.assetSection}>
                                            <Grid container spacing={1} alignItems={"center"}>
                                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                                        <img src={details?.cardDetails?.item_details?.assets !== null ?  (typeof details?.cardDetails?.item_details?.assets==="string" ? JSON.parse(details?.cardDetails?.item_details?.assets)?.[0]?.url : details?.cardDetails?.item_details?.assets?.[0]?.url)  : "/images/Group 98362.svg"} alt="product_image" className={classes.avatar} />
                                                        <Box>
                                                            <Typography className={classes.producttitle}>{details?.cardDetails?.item_details?.item_name}</Typography>
                                                            <Typography className={classes.productValue}>{details?.cardDetails?.item_details?.manufacturer_name}</Typography>
                                                        </Box>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={2} lg={2}>
                                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                                        <img src={"/images/assetLocation.svg"} alt="product_image" />
                                                        <Box>
                                                            <Typography className={classes.producttitle}>{details?.cardDetails?.item_details?.location_name}</Typography>
                                                            <Typography className={classes.productValue}>{t("Location")}</Typography>
                                                        </Box>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={2} lg={2}>
                                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                                        <img src={"/images/serialno.svg"} alt="product_image" />
                                                        <Box>
                                                            <Typography className={classes.producttitle}>{details?.cardDetails?.item_details?.serial_number}</Typography>
                                                            <Typography className={classes.productValue}>{t("Serial Number")}</Typography>
                                                        </Box>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={2} lg={2}>
                                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                                        <img src={"/images/modelNo.svg"} alt="product_image" />
                                                        <Box>
                                                            <Typography className={classes.producttitle}>{details?.cardDetails?.item_details?.model_number}</Typography>
                                                            <Typography className={classes.productValue}>{t("Model Number")}</Typography>
                                                        </Box>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={2} lg={2} justifyContent={"end"} onClick={() => setIsView({ ...isView, bool: true, is_product: true, is_unit: false })}>
                                                    <Typography className={classes.viewTrack} textAlign={"right"}>{t("View More")}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        :
                                        <Box className={classes.assetSection}>
                                            <Grid container spacing={1} alignItems={"center"}>
                                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                                        <img src={details?.cardDetails?.unit_details?.logo ?? "/images/unitIcon.svg"} alt="product_image" className={classes.avatar} />
                                                        <Box>
                                                            <Typography className={classes.producttitle}>{details?.cardDetails?.unit_details?.name}</Typography>
                                                            <Typography className={classes.productValue}>{details?.cardDetails?.unit_details?.property_name}</Typography>
                                                        </Box>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                                        <img src={"/images/assetLocation.svg"} alt="product_image" />
                                                        <Box>
                                                            <Typography className={classes.producttitle}>{details?.cardDetails?.unit_details?.city}</Typography>
                                                            <Typography className={classes.productValue}>{t("Unit Location")}</Typography>
                                                        </Box>
                                                    </Stack>
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                                        <img src={"/images/unitType.svg"} alt="product_image" />
                                                        <Box>
                                                            <Typography className={classes.producttitle}>{details?.cardDetails?.unit_details?.unit_type}</Typography>
                                                            <Typography className={classes.productValue}>{t("Unit Type")}</Typography>
                                                        </Box>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={2} lg={2} justifyContent={"end"} onClick={() => setIsView({ ...isView, bool: true, is_product: false, is_unit: true })}>
                                                    <Typography className={classes.viewTrack} textAlign={"right"}>{t("View More")}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    }
                                    <Box sx={{ boxShadow: "0px 0px 16px #00000014", backgroundColor: "#FFFFFF" }} mt={2}>
                                        <CheckListDetails state={{ ...state, inspection_item_map: details?.cardDetails?.item_details?.id }} item={details?.itemDetails} t={t} generalInspection={true} data={details?.list} notesData={notesData} setNotesData={setNotesData} onUpdateNotes={onUpdateNotes} status={details?.data?.agreement_inspection?.status} type="site_visit" isDisableBtn={isDisableBtn} handleDownload={handleDownload} is_unit={details?.cardDetails?.item_details ? false : true} />
                                    </Box>
                                    {/* <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Typography className={classes.hdrtitle}>{t("Inspection Details")}</Typography>
                                        
                                    </Stack>
                                    <Box>
                                        <AccordianWithDetails generalInspection={true} data={details?.list} notesData={notesData} setNotesData={setNotesData} onUpdateNotes={onUpdateNotes} status={details?.data?.agreement_inspection?.status} type="general" isDisableBtn={isDisableBtn} />
                                    </Box > */}
                                </Box >

                            </Grid >
                        </Grid>
                    </Box >
            }
            <Drawer
                anchor={"right"}
                open={assignModal}
                onClose={() => setAssignModal(false)}
            >
                <AssignModal
                    agreement_inspection_id={details?.data?.agreement_inspection?.id}
                    maintenance_request_id={details?.data?.agreement_inspection?.maintenance_id}
                    maintenance_company_id={state?.maintenance_company_id}
                    // reassign={true}
                    header={t("Assign Resource")}
                    moduleName={"Delivery Work Order"}
                    handleClose={() => setAssignModal(false)}
                    buttonName={t("Assign")}
                    onSubmit={handleAssign}
                    isDisableBtn={isDisableBtn}
                />
            </Drawer>
            <Drawer
                anchor={"right"}
                open={isView?.bool}
                onClose={() => handleClose()}
            >
                <Stack direction="row" spacing={2} p={1.5} alignItems="center" sx={{ backgroundColor: "#F2F4F7" }}>
                    <Box onClick={() => handleClose()} style={{ cursor: "pointer" }}><CloseIconWithBG /></Box>
                    <Typography className={classes.drawerHeader}>{isView?.is_product ? details?.cardDetails?.item_details?.item_name : details?.cardDetails?.unit_details?.unit_no}</Typography>
                </Stack>
                <Divider />
                <Box p={1.5} width="400px">
                    <ItemDetailsCard item={itemMappingDetails?.item_details} checkListGroupDetails={itemMappingDetails?.groupDetails} is_unit={isView?.is_unit} unitDetails={details?.cardDetails?.unit_details} t={t} isCustom={true} />
                </Box>
            </Drawer>
            <AlertDialog open={isComplete}
                onClose={() => { return (setIsComplete(false), setDescription("")) }}
                header={t("Complete Inspection")}
                isNormal
                component={
                    // <CompleteInspection />
                    <Box>
                        <center>
                            <Box className={classes.completeInsp}>
                                <Box mb={10}>
                                    <TextBox label={t("Description")} placeholder={t("Enter Description")} value={description} onChange={(e) => setDescription(e.target.value)} height={100} />
                                </Box>
                                {/* <Typography className={classes.msg}>Are you Sure ,You want to Complete ?</Typography> */}
                                <Grid container mt={4} spacing={1}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className={classes.cancelbtn} onClick={() => { return (setIsComplete(false), setDescription("")) }} variant="outlined">{t("No")}</Button>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth sx={{ color: "white" }} className={classes.successbtn} variant="contained" onClick={completeInspection} disabled={isDisableBtn}>{t("Yes")}</Button>

                                    </Grid>
                                </Grid>


                            </Box>
                        </center>
                    </Box>
                }

            />

            <AlertDialog open={cancelModal}
                onClose={() => { return (setCancelModal(false), setDescription("")) }}
                header={t("Cancel Inspection")}
                isNormal
                component={
                    // <CompleteInspection />
                    <Box>
                        <center>
                            <Box className={classes.completeInsp}>
                                <Box mb={10}>
                                    <TextBox label={t("Description")} placeholder={t("Enter Description")} value={description} onChange={(e) => setDescription(e.target.value)} height={100} />
                                </Box>
                                {/* <Typography className={classes.msg}>Are you Sure ,You want to Cancel ?</Typography> */}
                                <Grid container mt={4} spacing={1}>
                                    <Grid item xs={6}>
                                        <Button fullWidth className={classes.cancelbtn} onClick={() => { return (setCancelModal(false), setDescription("")) }} variant="outlined">{t("No")}</Button>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth sx={{ color: "white" }} className={classes.successbtn} variant="contained" onClick={cancelInspection} disabled={isDisableBtn}>{t("Yes")}</Button>

                                    </Grid>
                                </Grid>


                            </Box>
                        </center>
                    </Box>
                }

            />
            <AlertDialog
                open={showTrack}
                onClose={() => {
                    setShowTrack(false)
                }
                }
                header={t("Track Update")}
                isNormal
                component={<Box p={2} mt={2}>
                    <VerticalLinearStepper data={details?.data?.inspection_tracker?.map((val) => { return { name: val?.title, created_by: val?.created_by, date: RemoveZUTCDateTime(val?.created_at, "DD-MM-YYYY hh:mm A"), icon: val.title === "Inspection Completed" ? <Box className={classes.checkIconcircle}><CheckCircleIcon /></Box> : <TrackClockIcon />, notes: val?.description } })} customTrack />
                </Box>}
            />
            <AlertDialog open={isBilledHour}
                onClose={() => setIsBilledHour(false)}
                isnotTitle
                medium
                component={
                    // <CompleteInspection />
                    <Box className={classes.reqblock} p={1} alignItems="center">
                        <Box display={"flex"} justifyContent="end" onClick={() => setIsBilledHour(false)}>
                            <Close className={classes.closeicon} />
                        </Box>
                        <Grid container mt={5}>

                            {
                                auth?.auth?.auth?.language === "ar" ? " " : <Grid item xs={4}></Grid>
                            }
                            <Grid item xs={8}>
                                <Typography className={classes.q1hdr}>
                                    {t("Billing Hours not Updated,Do you want to Close?")}
                                </Typography>
                                <Box mt={1}>
                                    <Typography className={classes.q1subhdr}>
                                        {t("The Process Cannot be reverted once done")}
                                    </Typography>
                                </Box>
                                <Stack mt={5} ml={2} direction="row" >
                                    <Button variant="contained" fullWidth className={classes.yesbtn} onClick={() => { return (setIsBilledHour(false), setIsComplete(true)) }} disabled={isDisableBtn}>{t("Yes")}</Button>
                                    <Button variant="outlined" fullWidth className={classes.notxtbtn} onClick={() => setIsBilledHour(false)}>{t("No")}</Button>

                                </Stack>
                            </Grid>
                        </Grid >
                    </Box >
                }

            />
            {/* Inspection pdf */}
            <AlertDialog
                header={state?.reference_id}
                onClose={() => {
                    setOpenpdf(false)
                    setPdf({
                        bool: false,
                        data: ""
                    })
                }}
                open={openpdf}
                // render={renderButton()}
                component={
                    <Box p={1} bgcolor={"#F5F7FA"}>
                        <IframeViwer pdf={pdf} height={`250px`} />
                    </Box>

                }
            />
            <TemplateGenerator t={t} name={t("Inspection Template")} btnText={template?.type === "view" ? t("Generate") : t("Send")} open={template?.bool} onClose={() => setTemplate({
                bool: false,
                type: "view"
            })} type={7} generate={generate} />
        </Box >
    )
}
const props = {
    boxShadow: false,
};

export default withTranslation("inspection")(withNavBars(ViewDeliveryOrderDetails, props))
