import { Box } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { AlertDialog, FilterGenerator, LoadingSection, Subheader, UseDebounce } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { accessCheckRender, AlertProps, enumSelect, enum_types, getRoutePermissionNew, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { ItemConditionInitialState } from "../../utils/insepectionMaster";
import { Form, SearchFilters, Table } from "./components";
import { useStyles } from "./style";

const FeedBackSubCategoryMaster = ({ t }) => {
    const classes = useStyles();
    const debounce = UseDebounce();
    const client_id = localStorage.getItem(LocalStorageKeys.clinetID)
    const auth = React.useContext(AuthContext);
    const alert = React.useContext(AlertContext);
    const [data, setData] = React.useState(ItemConditionInitialState);
    const [searchText, setSearchText] = React.useState("");
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [loading, setLoading] = React.useState(false)
    const [list, setList] = React.useState([])
    const [open, setOpen] = React.useState(false);
    const [requestTypeOptions, setRequestTypeOptions] = React.useState([])
    const [categoryOptions, setCategoryOptions] = React.useState([])
    const [drawer, setDrawer] = React.useState(false);
    const [permissions, setPermission] = React.useState({})
    const [filterData, setFilterData] = React.useState({
        inspection_item_type: null,
        category: null
    });
    const colorOptions = [{ label: "Green", value: "#5AC782" }, { label: "Orange", value: "#FF9340" }, { label: "Yellow", value: "#FFFF00" }, { label: "Red", value: "#B20606" }]

    //get list
    const getFeedBackSubCategoryList = (offset = 0, limit = 10, searchText = "", filterData = []) => {
        setLoading(true)
        const payload = {
            start: offset,
            length: limit,
            search: searchText,
            type: filterData?.inspection_item_type?.map((x) => { return x.value }),
            category: filterData?.category?.map((x) => { return x.value })

        }
        NetworkCall(
            `${config.api_url}/inspection_feedback_sub_category`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setList({
                    list: response?.data?.data?.map((val) => {
                        return { ...val, status: val?.is_active, type: val?.inspection_item_type, colorLabel: colorOptions.filter((y) => y.value === val?.color)?.[0]?.label }
                    }),
                    count: response.data.count
                })
                setLoading(false)
            }).catch((err) => {
                console.log(err)
                setLoading(false)

            })
    }
    //getCategoryList
    const getFeedBackCategoryList = (searchText = "", type = null) => {

        const payload = {
            start: 0,
            length: 500,
            is_active: [true],
            type: type,
            "search": searchText,
        }
        NetworkCall(
            `${config.api_url}/inspection_feedback_category`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                const result = response?.data?.data?.map((val) => {
                    return {
                        label: val?.name,
                        value: val?.id
                    }
                })
                setCategoryOptions(result)
            }).catch((err) => {
                console.log(err)

            })
    }
    //initial load
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getFeedBackCategoryList()
                getFeedBackSubCategoryList(0, 10, "")
                getEnum()
            }
        }
        // eslint-disable-next-line
    }, [auth])

    //reload
    const reload = () => {
        getFeedBackSubCategoryList(0, 10, "")
    }
    // Function to get Enum value
    const getEnum = async () => {
        const result = await enumSelect([enum_types.inspection_item_type])
        setRequestTypeOptions(result?.inspection_item_type)
    }
    //on isActive
    const onIsActive = (val) => {
        const payload = {
            "data": {
                "id": val?.id,
                "category_id": val?.category_id,
                "name": val?.name,
                "color": val.color,
                "client": client_id,
                "is_active": !val?.status,
            }
        }
        NetworkCall(
            `${config.api_url}/inspection_feedback_sub_category/upsert`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            reload()
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Status Updated Successfully"),
            });
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something Went Wrong"),
            });

        })

    }

    //handle icon
    const handleClickHandler = (type, val) => {
        if (type === "edit") {
            setOpen(true);
            setData({
                ...data,
                isEdit: true,
                id: val?.id,
                title: { value: val?.category_id, label: val?.category_name },
                inspectionType: { value: val?.inspection_item_type, label: val?.inspection_item_type },
                name: val?.name,
                status: val?.is_active,
                color: colorOptions.filter((y) => y.value === val?.color)?.[0],
            });
            getFeedBackCategoryList("", [val?.inspection_item_type])

        } else if (type === "active") {
            onIsActive(val);
        }

    }


    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getFeedBackSubCategoryList(offset, limit, "", filterData)

    }

    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getFeedBackSubCategoryList(0, value, "", filterData)

    }

    //on search
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getFeedBackSubCategoryList(0, limit, e, filterData)

    }
    //update state of master
    const updateState = (key, value) => {
        if (key === "title") {
            getFeedBackCategoryList(value?.label ? value.label : value, [data?.inspectionType?.value])
        }
        if (key === "inspectionType") {
            getFeedBackCategoryList("", [value?.value])
        }
        let error = data.error;
        error[key] = "";
        setData({ ...data, [key]: value, error });
    };

    //onClose form
    const closeForm = () => {
        setOpen(false)
        setData({
            title: "",
            status: true,
            name: "",
            color: "",
            type: "",
            error: {
                title: "",
                status: "",
                name: "",
                color: "",
                type: "",
            }

        })
    }
    const openFilter = () => {
        setDrawer(true)
        getFeedBackCategoryList("", [])

    }
    //apply filter
    const onApplyFilter = (data) => {
        getFeedBackSubCategoryList(0, limit, "", data)
        setFilterData(data)
    }
    const render = () => {
        return (
            <Box>
                {/*sub header */}
                <Subheader
                    hideBackButton={true}
                    title={t("Inspection FeedBack SubCategory Master")}
                />
                {
                    loading ?
                        <LoadingSection top="20vh" message={t("loading")} /> :

                        <Box container className={classes.root}>
                            {/*search btn component */}
                            <SearchFilters
                                onClick={() => { return (setOpen(true), setCategoryOptions([])) }}
                                searchText={searchText}
                                handleSearch={handleSearch}
                                permissions={permissions}
                                openfilter={() => openFilter()}
                            />
                            {/*table component */}
                            <Table
                                handleIcon={handleClickHandler}
                                handleChangeLimit={handleChangeLimit}
                                handlePagination={handlePagination}
                                page={page}
                                list={list}
                                limit={limit}
                                handleSearch={handleSearch}
                                searchText={searchText}
                                view={permissions?.read}
                                edit={permissions?.update}
                            />
                        </Box>
                }

                {/*filter component */}
                {drawer && (
                    <FilterGenerator
                        open={drawer}
                        onClose={() => setDrawer(false)}
                        components={[
                            {
                                component: "select",
                                value: filterData?.inspection_item_type,
                                state_name: "inspection_item_type",
                                label: t("Inspection Item Type"),
                                options: requestTypeOptions,
                                isMulti: true
                            },
                            {
                                component: "select",
                                value: filterData?.category,
                                state_name: "category",
                                label: t("Category"),
                                options: categoryOptions,
                                isMulti: true
                            },

                        ]}
                        onApply={(value) => onApplyFilter(value)}
                    />
                )}
                {/*form component */}
                <AlertDialog
                    open={open}
                    onClose={closeForm}
                    header={data?.isEdit ? `${t("edit - Inspection Subcategory")}` : `${t("add - Inspection Subcategory")}`}
                    component={<Form
                        data={data}
                        list={list?.list}
                        colorOptions={colorOptions}
                        inspectionItemTypeOptions={requestTypeOptions}
                        categoryOptions={categoryOptions}
                        setData={setData}
                        reload={reload}
                        onClose={closeForm}
                        updateState={updateState}
                    />}
                    isNormal
                />


            </Box>

        )
    }
    return (
        <>
            {accessCheckRender(render, permissions)}
        </>

    )
}
export default withTranslation("inspectionItemmaster")(FeedBackSubCategoryMaster); 