import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    cursor: "pointer"
  },

  srctypebox: {
    backgroundColor: theme?.palette?.background?.light,
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center'
  },
  arrowIcon: {
    color: theme?.typography?.status?.tertiary ?? "",
  },
  menu: {
    "& .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper":
    {
      borderRadius: theme.palette.borderRadius,
      padding: "0px !important",
      boxShadow: "0px 8px 24px #0717411F",
      color: theme?.typography?.status?.tertiary ?? "",
      height: '147px',
      overFlow: "auto"
    },
    "& .MuiMenu-list": {
      padding: "0 !important",
      color: theme?.typography?.status?.tertiary ?? "",
      display: "block !important"
    },
  },
  srcText: {
    color: theme?.typography?.status?.tertiary,
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().bold,
  },
  flexBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center'
  },
  flexBox1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center'
  },
  dropDown: {
    boxShadow: "0px 0px 6px #0717411F",
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "white",
    position: "absolute",
    left: 0,
    right: 0,
    border: "1px solid #E4E8EE",
    marginTop: "4px",
    zIndex: 999,
    height: "200px",
    overflow: "auto"

  }
}));
