import { Button, Grid } from "@mui/material"
import React from "react"
import { useNavigate } from 'react-router-dom'
import { AccessDeniedSVG } from "../../assets"
import { pageName } from "../../utils"
import { useStyles } from "./style"

export const AccessDenied = (props) => {

    const {
        screen = ""
    } = props

    const classes = useStyles()
    const navigate = useNavigate()

    return <div className={classes.root}>
        <Grid container direction={"column"}
            justifyContent={"center"} alignItems={"center"}>
            <AccessDeniedSVG />
            {screen !== pageName.dashboard && <>
                <br />
                <Button className={classes.button}
                    onClick={() => {
                        navigate(-1)
                    }}>
                    {"Go Back"}
                </Button>
            </>}
        </Grid>
    </div>
}
