import { makeStyles } from "@mui/styles"
import { FontFamilySwitch } from "../../../utils"

export const useStyles = makeStyles((theme) => ({
    pdImage: {
        height: 140,
        width: 140,
        borderRadius: "50%"
    },
    companyListTitle: {
        color: theme.typography.color.tertiary,
        fontSize:"0.75rem",
        fontWeight: "bold"
    },
    companyListContent: {
        fontSize:"0.875rem",
        fontWeight: "bold",
        color: theme.typography.color.primary,
    },
    tableAddBtn: {
        fontWeight: "normal",
        // padding:"2px 16px 2px 16px",
        fontSize:"0.875rem",
        height: "40px"
    },

    // Add Bulk item component

    abGriditem: {
        border: `1px solid ${theme.palette.border.secondary}`,
    },
    abTempTitle: {
        color: theme.typography.color.tertiary,
        fontSize:"0.75rem",
        fontWeight: "bold"
    },
    abTempName: {
        border: `1px solid ${theme.palette.border.secondary}`,
        padding: 8,
        fontSize:"0.875rem",
        fontWeight: "bold",
        width: "85%",
        borderRadius: 4
    },
    abAddCompList: {
        // overflow: "auto",
        // height: "300px"
    },
    abPreviewTitle: {
        fontSize:"1rem",
        fontWeight: "bold"
    },
    abDeleteAll: {
        color: theme.palette.error.main,
        fontSize:"0.75rem",
        fontWeight: "bold",
        cursor: "pointer",
        marginRight: "12px"
    },
    createBtn: {
        width: "100%",
    },
    cartList: {
        height: `calc(100vh - 280px)`,
        overflow: "auto"
    },
    imageBadge: {
        '& .MuiBadge-badge': {
            marginLeft: "68px",
            backgroundColor: theme.palette.secondary.main,
            color: theme.typography.color.white,
            whiteSpace: "nowrap",
            borderRadius: "4px"
        }
    },
    contentTitle: {
        color: theme.typography.color.secondary,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        textTransform: "upperCase"
    },
    propertyTitle: {
        color: theme.typography.color.tertiary,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        marginBottom: "5px",
        marginLeft: "12px"
    },
    propertyTitleCard: {
        color: theme.typography.color.tertiary,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        marginTop: "12px",
        marginBottom: "4px"
    },
    propertyContent: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
    },

}))