import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        padding: "16px",
        alignItems: "center",
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "white",
        boxShadow: "0px 3px 30px #5C86CB2E",
        width: "100%",
        height: "100%",
    },
    title: {
        color: "#091B29",
        fontSize:"1rem",
        fontFamily: FontFamilySwitch().extraBold,
    },
}));