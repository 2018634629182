import makeStyles from "@mui/styles/makeStyles";
import { remCalc, FontFamilySwitch } from "../../utils";
export const AccordianStyle = makeStyles((theme) => ({
  cardRoot: {
    padding: "12px",
    border: `1px solid ${theme.palette.border.secondary}`,
    marginTop: "10px",
    borderRadius: "4px",
    cursor: "pointer",
  },
  title: {
    color: theme.typography.color.primary,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
  },
  title1: {
    color: theme.typography.color.primary,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
  },
  noof: {
    color: theme.typography.color.secondary,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
  },
  avatarGroup: {
    "& .MuiAvatar-root": {
      height: "34px",
      width: "34px",
      fontSize: "0.75rem"
    }
  },
  subtitle1: {
    color: theme.typography.color.secondary,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  subtitle2: {
    color: theme.typography.color.primary,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    textAlign: "right"
  },
  subtitle3: {
    color: theme.typography.color.tertiary,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    marginTop: "22px"
  },
  heading: {
    color: theme.typography.color.tertiary,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    marginTop: "12px",
    textTransform: "upperCase"
  },
  tag: {
    padding: "4px 4px",
    borderRadius: "25px",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    minWidth: "100px",
    color: "white",
    display: "inline-block",
    textAlign: "center"
  },
  emptytag: {
    padding: "2px 4px",
    borderRadius: "4px",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    marginTop: "12px",
    display: "inline-block"
  },
  subtitle4: {
    color: theme.typography.color.primary,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    marginTop: "22px",
  },
  tenant_name: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    marginTop: "22px",
    cursor: "pointer"
  },
  image: {
    height: "240px",
    objectFit: "cover",
    width: "100%",
    borderRadius: "4px"
  },
  borderNone: {
    border: "none"
  },
  textAreaBox: {
    border: "1px solid #E4E8EE",
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
  },
  seeMoreLessTextStyle: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.palette.primary.main,
    cursor: "pointer"
  },
  avatar: {
    borderRadius: "4px",
    marginInlineEnd: "12px",
    backgroundColor: "#F2F4F7",
    padding: "3px"
  },
  drawerHeader: {
    fontSize: remCalc(16),
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary
  },
  productTitle: {
    fontSize: remCalc(14),
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary,
    textTransform: "capitalize"
  },
  productValue: {
    fontSize: remCalc(14),
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary
  },
  inspectionItemIcon: {
    backgroundColor: "#F5F7FA",
    border: "1px solid #E4E8EE",
    padding: "4px",
    borderRadius: "6px",
    width: "40px",
    height: "40px",
    objectFit: "contain"
  },
  assetSection: {
    borderRadius: theme.palette.borderRadius,
    border: "1px solid #CED3DD",
    backgroundColor: "#FFFFFF",
    padding: "16px"
  },
  instructionSection: {
    borderRadius: theme.palette.borderRadius,
    border: "1px solid #CED3DD",
    backgroundColor: "#FFFFFF",
    // padding: "16px"
  },
  notesSection: {
    borderRadius: "8px",
    border: "1px solid #CED3DD",
    backgroundColor: "#FFFFFF",
  },
  instructionsTitle: {
    fontSize: remCalc(12),
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary
  },
  documentViewTitle: {
    fontSize: remCalc(12),
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.palette.primary.main
  },
  unitNoTitle: {
    fontSize: remCalc(12),
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary
  },
  itemName: {
    fontSize: remCalc(16),
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
    textAlign: "center"
  },
  itemCondition: {
    fontSize: remCalc(12),
    color: "#5AC782",
    fontFamily: FontFamilySwitch().semiBold,
  },
  itemBox: {
    backgroundColor: "#EEF9EE",
    borderRadius: theme.palette.borderRadius,
    display: "flex",
    justifyContent: "center",
    width: "100px",
    padding: "5px"
  },
  productImage: {
    width: "127px",
    height: "127px",
    borderRadius: "12px",
    border: "1px solid #E4E8EE"
  },
  customtab: {
    "&.MuiTab-root": { padding: "12px 16px !important" }
  },
  applyborder: {
    borderRight: "1px solid #CED3DD"
  },
  detailsRoot: {
    backgroundColor: "#F2F5FA",
    borderRadius:theme.palette.borderRadius
  },
  itemTitle: {
    fontSize: remCalc(12),
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().semiBold,
    textTransform: "uppercase",
  },
  penaltySection: {
    borderRadius: "8px",
    backgroundColor: "#F1F7FF",
    padding: "12px"
  },
  checkListName: {
    fontSize: remCalc(14),
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary,
  },
  astriksymbol: {
    color: "#FF4B4B",
    fontSize: remCalc(14),
    fontFamily: FontFamilySwitch().extraBold,
  },
  cancelbtn: {
    backgroundColor: "white",
    '&:hover': {
      backgroundColor: "white",
    }
  },
  viewMore: {
    color: "#5078E1",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer"
  }
}));