import { Dialog, Grid, Stack } from "@mui/material";
import React, { useState } from "react";
import { Slider } from "../../../components";
import { filterStyles } from "./style";
import { Assets } from "../../propertyFinder3/component/assets";

export const AssetView = ({
  asset,
  assetAll,
  title,
  isNormal = false,
  maxWidth = "md",
}) => {
  const classes = filterStyles();
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0); // Store selected index

  // Open popup for the selected image index
  const openPopup = (index) => {
    setSelectedIndex(index);
    setOpen(true);
  };

  return (
    <>
      <Grid container spacing={"4px"}>
        {/* First Image (Left Side) */}
        <Grid item lg={4} md={4} xs={4} onClick={() => openPopup(0)}>
          <img
            src={asset[0]?.url ?? "/images/imagesproperty.svg"}
            alt=""
            className={classes.detailImg}
          />
        </Grid>

        {/* Right Side - Next 4 Images or Placeholders */}
        <Grid item lg={8} md={8} xs={8}>
          <Grid container spacing={"4px"}>
            {Array.from({ length: 4 }).map((_, index) => {
              const imageIndex = index + 1;
              const isLastImage = imageIndex === 4; // Check if this is the last image slot

              return (
                <Grid
                  item
                  xs={6}
                  key={imageIndex}
                  onClick={() =>
                    asset[imageIndex] ? openPopup(imageIndex) : null
                  }
                  style={{ position: "relative", cursor: "pointer" }}
                >
                  <div className={classes.addImg}>
                    <img
                      src={asset[imageIndex]?.url ?? "/images/imagesproperty.svg"}
                      alt=""
                      className={classes.detailImg2}
                    />

                    {/* Show View All Images + +X only on the last image slot */}
                    {isLastImage && asset.length > 5 && (
                      <span className={classes.plus}>+{asset.length - 5}</span>
                    )}

                    {isLastImage && (
                      <Stack
                        className={classes.viewAllText}
                        direction={"row"}
                        spacing={1}
                        alignItems={"center"}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevents triggering the image click
                          openPopup(0); // Open first image only
                        }}
                      >
                        <img src="/images/unitDrawerImage.svg" alt="" />
                        <span>View All Images</span>
                      </Stack>
                    )}
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>

      {/* Dialog for Viewing Images */}
      <Dialog
        open={open}
        fullWidth
        maxWidth={maxWidth ?? "md"}
        onClose={() => setOpen(false)}
        className={classes.dialog}
      >
        {isNormal ? (
          <Slider assets={assetAll ?? []} selectedAssetIndex={selectedIndex} />
        ) : (
          <Assets
            assets={assetAll}
            title={title}
            onClose={() => setOpen(false)}
          />
        )}
      </Dialog>
    </>
  );
};
