import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";
export const CalendarStyle = makeStyles((theme) => ({
    iconbutton: {
        // border: `1px solid ${theme.palette.border.secondary}`
    },
    day: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        textTransform: "uppercase",
        border: `1px solid ${theme.palette.border.secondary}`,
        padding: 4
    },
    date_curr_month: {
        border: `1px solid ${theme.palette.border.secondary}`,
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontWeight: "bold",
        cursor: "pointer",
        // height:"45px"
    },
    date_not_curr_month: {
        border: `1px solid ${theme.palette.border.secondary}`,
        color: theme.palette.border.secondary,
        fontSize:"0.875rem",
        fontWeight: "bold",
        cursor: "not-allowed",
        // height:"45px"
    },
    today: {
        // border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.primary.main,
        color: "white",
        borderRadius: 4,
        fontSize:"0.875rem",
        fontWeight: "bold",
        // height:"45px"
    },
    timeBox: {
        height: "calc(100vh - 506px)",
        overflow: "auto",
        marginTop: "18px",
        border: "1px solid #E4E8EE",
        padding: "3px",
        scrollBehavior: "smooth"
    },
    selectedTime: {
        backgroundColor: "#5078E1",
        color: "white",
        borderRadius: theme.palette.borderRadius,
        padding: "3px",
    },
    date_hidden: {
        display: "none"
    },
    headerMonth: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,

        fontFamily: FontFamilySwitch().semiBold,
    }
}))