import {
  Grid,
  Box,
  IconButton,
  Badge,
  Divider,
  Popover,
  Button,
  CircularProgress,
  List,
  Tooltip,
  ListItem,
  Link,
  Typography,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import {
  SearchFilter,
  TableWithPagination,
  FilterGenerator,
} from "../../components";
import { Aggreementheading, Aggreementpath } from "../../utils/quotations";
import { quotationsStyles } from "./styles";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import {
  enumSelect,
  enum_types,
  AlertProps,
  NetWorkCallMethods,
  LocalStorageKeys,
} from "../../utils";
import { AlertContext } from "../../contexts";
import { DownloadImage } from "../../assets";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

const AggreementsTable = ({
  handleSearch = () => false,
  searchText = "",
  revenue = {},
  handleIcon = () => false,
  list = [],
  handlePagination = () => false,
  handleChangeLimit = () => false,
  page = "",
  limit = "",
  t = () => false,
  permission = {},
  selectedCompany,
  filterData = {},
  getAgreement = () => false,
  enable_double_click = true,
  enable_single_click = true,
}) => {
  const classes = quotationsStyles();
  const [drawer, setDrawer] = useState(false);

  const [enumValue, setEnumValue] = useState({
    type: [],
  });
  const drawerclose = () => {
    setDrawer(false);
  };
  const onApplyFilter = (value) => {
    getAgreement(value);
  };
  // enum
  const getEnum = async () => {
    const result = await enumSelect([enum_types.agreement_status]);
    setEnumValue({
      type: result?.agreement_status,
    });
  };

  React.useEffect(() => {
    getEnum();
  }, []);
  const [downloadedFiles, setDownloadedFiles] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = React.useState(false);
  const [isReportLoading, setIsReportLoading] = React.useState(true);
  const [scrollOffset, setScrollOffset] = useState(0);
  const clientId = localStorage.getItem(LocalStorageKeys.clinetID);
  const alert = React.useContext(AlertContext);
  const [pendingFiles, setPendingFiles] = useState([]);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const performNetworkCall = async (url, method, payload, onSuccess) => {
    try {
      const response = await NetworkCall(
        url,
        method,
        payload,
        null,
        true,
        false
      );
      if (response.status === 200) {
        onSuccess(response);
      } else {
        console.error("response no success");
      }
    } catch (error) {
      console.log("Error in performNetworkCall:", error);
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Some Thing Went Wrong"),
      });
    }
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (pendingFiles.length > 0 && open) {
        // get ids of pending files
        const pendingFileIds = Array.isArray(pendingFiles)
          ? pendingFiles
              .map((file) => file?.id)
              .filter((id) => id !== undefined)
          : [];

        performNetworkCall(
          `${config.api_url}/360_reports/get_report_status`,
          NetWorkCallMethods.post,
          { report_ids: pendingFileIds },
          (response) => {
            // filter undefined data in the array and remove them
            const filteredPendingFiles = pendingFiles.filter(
              (file) => file !== undefined
            );

            // get response files with is_completed = true or is_failed = true
            const updatedFiles = response.data.data;
            const completedOrFailedFiles = updatedFiles.filter(
              (file) => file.is_completed || file.is_failed
            );

            // remove completed files from pending files
            const newPendingFiles = filteredPendingFiles.filter(
              (file) =>
                !completedOrFailedFiles.some(
                  (completedOrFailedFile) =>
                    completedOrFailedFile.id === file.id
                )
            );

            setPendingFiles(newPendingFiles);

            // update downloaded files with the new data
            const newDownloadedFiles = downloadedFiles.map((file) => {
              const updatedFile = updatedFiles.find(
                (updatedFile) => updatedFile.id === file.id
              );
              if (updatedFile) {
                return updatedFile;
              }
              return file;
            });

            setDownloadedFiles(newDownloadedFiles);
          }
        );
      }
    }, 5000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingFiles, open]);

  const handleDownload = async (event) => {
    setAnchorEl(event.currentTarget);
    setIsReportLoading(true);
    const newScrollOffset = 0;
    setScrollOffset(newScrollOffset);

    performNetworkCall(
      `${config.api_url}/360_reports/get_reports`,
      NetWorkCallMethods.post,
      { report_type: "agreement", offset: newScrollOffset, limit: 5 },
      (response) => {
        const getData = response.data.data;
        setDownloadedFiles(getData);

        // eslint-disable-next-line array-callback-return
        const queuedReports = getData.map((file) => {
          if (!file.is_completed) {
            return file;
          }
        });
        setPendingFiles(queuedReports);

        console.log("report data stored", getData);
        setIsReportLoading(false);
        setIsLoading(false);
        setIsDownloadLoading(false);
      }
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDownloadedFiles([]);
  };

  const generateRandomAlphanumeric = (length) => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };

  const handleDownloadReport = async () => {
    setIsLoading(true);
    setIsDownloadLoading(true);
    const date = new Date();
    const yy = String(date.getFullYear());
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    const hh = String(date.getHours()).padStart(2, "0");
    const min = String(date.getMinutes()).padStart(2, "0");
    const randomAlphanumeric = generateRandomAlphanumeric(6);
    let fileName = `agreement_report_${yy}_${mm}_${dd}_${hh}_${min}_${randomAlphanumeric}.xlsx`;
    try {
      await performNetworkCall(
        `${config.api_url}/360_reports/report_downloads`,
        NetWorkCallMethods.post,
        {
          file_name: fileName,
          report_type: "agreement",
          is_completed: false,
          is_failed: false,
          file_url: null,
          company_id: selectedCompany?.value,
          client_id: clientId,
          status: filterData?.status,
          agreement_id: filterData?.account,
        },
        async () => {
          const newScrollOffset = 0;
          setScrollOffset(newScrollOffset);

          await performNetworkCall(
            `${config.api_url}/360_reports/get_reports`,
            NetWorkCallMethods.post,
            { report_type: "agreement", offset: newScrollOffset, limit: 5 },
            (response) => {
              const getData = response.data.data;
              setDownloadedFiles(getData);

              // eslint-disable-next-line array-callback-return
              const queuedReports = getData.map((file) => {
                if (!file.is_completed) {
                  return file;
                }
              });
              setPendingFiles(queuedReports);

              console.log("report data stored", getData);
              setIsLoading(false);
              setIsDownloadLoading(false);
            }
          );
        }
      );
    } catch (error) {
      console.error("Error in handleDownloadReport:", error);
      setIsLoading(false);
      setIsDownloadLoading(false);
    }
  };
  const handleOpenFile = (fileName) => {
    const fileUrl = fileName;
    window.open(fileUrl, "_blank");
  };

  const fetchMoreReport = () => {
    const newScrollOffset = scrollOffset + 5;
    setScrollOffset(newScrollOffset);

    performNetworkCall(
      `${config.api_url}/360_reports/get_reports`,
      NetWorkCallMethods.post,
      { report_type: "agreement", offset: newScrollOffset, limit: 5 },
      (response) => {
        const getData = response.data.data;
        setDownloadedFiles([...downloadedFiles, ...getData]);

        // eslint-disable-next-line array-callback-return
        const queuedReports = getData.map((file) => {
          if (!file.is_completed) {
            return file;
          }
        });
        setPendingFiles(queuedReports);

        console.log("REPORT data stored", getData);
        setIsReportLoading(false);
        setIsLoading(false);
        setIsDownloadLoading(false);
      }
    );
  };

  function truncateFileName(fileName) {
    const maxLength = 15;
    if (fileName.length <= maxLength * 2) {
      return fileName;
    }
    const start = fileName.slice(0, maxLength);
    const end = fileName.slice(-maxLength);
    return `${start}...${end}`;
  }

  return (
    <div>
      <Grid container justifyContent="space-between">
        <Grid item xs={4}>
          {/*search field*/}
          <SearchFilter
            value={searchText}
            placeholder={t("seacrhAgreement")}
            handleChange={(value) => handleSearch(value)}
            height={"40px"}
            customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
          />
        </Grid>
        <Grid item xs={8} justifyContent={"end"} display={"flex"}>
          <Box style={{ float: "right" }}>
            <IconButton
              size="small"
              className={classes.img}
              onClick={handleDownload}
            >
              <DownloadImage />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <div className={classes.tray}>
                <div className={classes.buttonWrapper}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDownloadReport}
                    disabled={isLoading || isDownloadLoading}
                    className={classes.button}
                  >
                    {isDownloadLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Download Report"
                    )}
                  </Button>
                </div>
                <Divider />

                <List className={classes.list}>
                  {isReportLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress size={34} />
                    </div>
                  ) : downloadedFiles.length === 0 ? (
                    <Typography style={{ textAlign: "center" }}>
                      No export history
                    </Typography>
                  ) : (
                    <InfiniteScroll
                      dataLength={downloadedFiles.length}
                      next={() => fetchMoreReport()}
                      hasMore={true}
                      height={150}
                    >
                      <Grid>
                        {downloadedFiles.map((file, index) => (
                          <ListItem key={index} className={classes.listItem}>
                            <Tooltip title={file.file_name} arrow>
                              {file.is_completed ? (
                                <Link
                                  className={classes.fileName}
                                  onClick={() => handleOpenFile(file.file_url)}
                                  component="button"
                                  variant="body2"
                                >
                                  {truncateFileName(file.file_name)}
                                </Link>
                              ) : (
                                <Typography
                                  variant="body2"
                                  className={classes.disabledFileName}
                                  style={{ color: "gray" }}
                                >
                                  {truncateFileName(file.file_name)}
                                </Typography>
                              )}
                            </Tooltip>
                            {file.is_completed ? null : file.is_failed ? (
                              <WarningAmberRoundedIcon
                                className={classes.errorIcon}
                                style={{ color: "red", fontSize: "18px" }}
                              />
                            ) : (
                              <CircularProgress
                                size={24}
                                className={classes.loader}
                              />
                            )}
                          </ListItem>
                        ))}
                      </Grid>
                    </InfiniteScroll>
                  )}
                </List>
              </div>
            </Popover>
          </Box>
          <Divider
            orientation="vertical"
            p={1}
            height={2}
            sx={{ marginInline: "16px" }}
          />
          <Box style={{ float: "right" }}>
            <IconButton
              size="small"
              className={classes.img}
              onClick={() => setDrawer(true)}
            >
              <Badge
                variant="dot"
                color="primary"
                invisible={
                  !Boolean(
                    Boolean(filterData?.account?.length > 0) ||
                      Boolean(filterData.status?.length > 0)
                  )
                }
              >
                <img src="/images/filter.svg" alt="filter" />
              </Badge>
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      {/*table container*/}
      <TableWithPagination
        heading={Aggreementheading(t)}
        rows={list?.list}
        path={Aggreementpath}
        showpagination={true}
        count="2"
        showpdfbtn={false}
        showexcelbtn={false}
        showSearch={false}
        tableType="no-side"
        handleIcon={handleIcon}
        onClick={() => console.log("")}
        dataType={[
          { type: ["info"], icon: "icon" },
          { type: ["text"], name: "agreement_no" },
          { type: ["date"], name: "generate_date" },
          { type: ["text"], name: "account_no" },
          { type: ["long_text"], name: "account_name" },
          { type: ["text"], name: "agreement_type" },
          { type: ["date"], name: "start_date" },
          { type: ["date"], name: "end_date" },
          { type: ["q_status"], name: "status" },
        ]}
        handlePagination={handlePagination}
        handleChangeLimit={handleChangeLimit}
        totalRowsCount={list?.count}
        page={page}
        limit={limit}
        height={`calc(100vh - 300px)`}
        view={permission?.read}
        edit={permission?.update}
        delete={permission?.delete}
        enable_double_click={enable_double_click}
        enable_single_click={enable_single_click}
      />

      {drawer && (
        <FilterGenerator
          open={drawer}
          onClose={drawerclose}
          components={[
            {
              component: "select",
              value: filterData?.account,
              options: [],
              isMulti: true,
              label: t("Customer-AccountID"),
              state_name: "account",
              loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                  "agreement/get_agreement_account",
                  {
                    company_id: selectedCompany?.value,
                  },
                  search,
                  array,
                  handleLoading,
                  "data",
                  { label: t("label"), value: "value", agreement: true }
                ),
              debounceTimeout: 800,
              isPaginate: true,
              placeholder: t("Select Account ID"),
            },
            {
              component: "select",
              value: filterData?.status,
              options: enumValue?.type?.filter(
                (x) => x?.value !== "Offboarding (In Moveout Stage)"
              ),
              isMulti: true,
              label: t("Status"),
              state_name: "status",
              placeholder: t("Select Status"),
            },
          ]}
          onApply={(value) => onApplyFilter(value)}
        />
      )}
    </div>
  );
};
export default withTranslation("agreement")(AggreementsTable);
