import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const ReceiptStyles = makeStyles((theme) => ({
    root: {
        padding: "12px",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "10px"
    },
    detailRoot: {
        backgroundColor: theme.palette.background,
        borderRadius: "10px"
    },
    detailRoots: {
        height: `calc(100vh - 220px)`,
        overflow: "auto"
    },
    noDataDetailRoots: {
        height: 'calc(100vh - 160px)',
        overflow: "auto",
        backgroundColor: theme.palette.background.paper,
        borderRadius: '10px',
    },
    invoiceRoot: {
        padding: "4px 12px 8px 12px"
    },
    title: {
        fontFamily: FontFamilySwitch().bold,
        fontSize:"1rem",
        color: theme?.typography?.color?.primary
    },
    totalRoot: {
        border: `1px solid ${theme.palette.border.third}`,
        padding: "8px",
        margin: "12px",
        borderRadius: "4px"
    },
    totalAmount: {
        fontFamily: FontFamilySwitch().semiBold,
        fontSize:"0.75rem",
        color: theme?.typography?.color?.secondary,
        textAlign: "right"
    },
    totalAmountValue: {
        fontFamily: FontFamilySwitch().extraBold,
        fontSize:"1.375rem",
        color: theme?.typography?.color?.primary,
        direction: "ltr",
        textAlign: "-webkit-match-parent"
    },
    available: {
        fontFamily: FontFamilySwitch().semiBold,
        fontSize:"0.75rem",
        color: theme?.palette?.success?.main,
    },
    downBtn: {
        border: `1px solid ${theme?.typography?.color?.primary}`,
        color: theme?.typography?.color?.primary,
        backgroundColor: "white",
        "&:hover": {
            backgroundColor: "white",
            border: `1px solid ${theme?.typography?.color?.primary}`,
        }
    },
    convert: {
        color: "white",
        fontSize:"1rem",
        fontFamily: FontFamilySwitch().bold,
    },
      marginTop:{
        marginTop:"24px"
      },
      profileContainer: {
        display: "flex",
        gap: "16px",
      },
      profileContainerArabic: {
        display: "flex",
        gap: "6px",
      },
    profileCardArabic: {
        position: "relative",
        gap: "10px",
      },
    profileCard: {
        position: "relative",
    },
}));