import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { TemplateIcon } from "../../../assets/templateIcon";
import { TemplateListIcon } from "../../../assets/templateListIcon";
import { SearchFilter } from "../../../components";
import { useStyles } from "./style";
export const InspectionItems = ({ t, data = {}, selectedUnit = {}, selectedInspectionItem = [], updateState = () => false, list = [], fetchMoreData = () => false, handleSearch = () => false, searchText = "" }) => {
    const classes = useStyles()
    const onSelectInspectionItem = (val) => {
        updateState("selectedInspectionItem", [val])
        // if (!data?.selectedInspectionItem?.includes(val)) {
        //     updateState("selectedInspectionItem", [...data?.selectedInspectionItem, val])
        // }
        // else {
        //     const filtereddata = data?.selectedInspectionItem?.filter((x) => { return x !== val })
        //     updateState("selectedInspectionItem", filtereddata)
        // }
    }
    // eslint-disable-next-line
    const SelectAllItems = () => {
        if (data?.selectedInspectionItem?.length === 0) {
            const selectAll = list.map((x) => { return x.id })
            updateState("selectedInspectionItem", selectAll)
        }
        else {
            updateState("selectedInspectionItem", [])
        }
    }
    return (
        <Box p={2}>
            <Box className={classes.unitblock} style={{ backgroundColor: "#FEEAEA80" }}>
                <Stack direction="row" flexWrap={"wrap"}>
                    <Box className={classes.templateIcon}><TemplateIcon /></Box>
                    <Box marginInlineStart={'8px'}>
                        <Typography className={classes.name}>{selectedUnit?.name}</Typography>
                        {/* <Typography className={classes.location}>{"Last Inspection Conducted on 01 dec 22"}</Typography> */}
                        <Box display="flex">
                            {selectedUnit?.floor?.name?.length > 0 &&
                                <Typography className={classes.location}>{selectedUnit?.floor?.name} ,</Typography>
                            }
                            {
                                selectedUnit?.block?.name?.length > 0 &&
                                <Typography className={classes.location}>{selectedUnit?.floor?.name} ,</Typography>

                            }

                            <Typography className={classes.location}>{`${selectedUnit?.property?.name}`}</Typography>
                        </Box>
                    </Box>
                </Stack>
            </Box>
            <Box mt={2}>
                <SearchFilter placeholder={t("Search Inspection Items")} value={searchText} handleChange={(value) => handleSearch(value)} />
            </Box>
            <Grid container mt={2}>
                <Grid item xs={5}>
                    <Typography className={classes.listText}>{t("Item lists")}</Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography className={classes.listText}>{t("Serial Number")}</Typography>
                </Grid>
                <Grid item xs={2} textAlign="end">
                    {/* <Box mt={-1.5}>
                        <Checkbox
                            onChange={() => SelectAllItems()}
                            checked={data?.selectedInspectionItem?.length > 0}
                            icon={<RadioButtonUncheckedIcon sx={{ color: "#E4E8EE" }} />}
                            checkedIcon={<CheckCircleIcon sx={{ color: "#5078E1" }} />} />
                    </Box> */}
                </Grid>
            </Grid>
            <InfiniteScroll
                dataLength={list?.length ?? ""}
                next={fetchMoreData}
                hasMore={true}
                style={{ overflow: "overlay", }}
                height={250}
            >
                {
                    list?.length > 0 ? list?.map((x, i) => {
                        return (
                            <>
                                <Grid container mt={1}>
                                    <Grid item xs={5}>
                                        <Stack direction="row" spacing={1} flexWrap="wrap">
                                            <Box><TemplateListIcon /></Box>
                                            <Box><Typography className={classes.inspectionName}>{x.name}</Typography></Box>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography className={classes.listsubText}>{x.serial_number}</Typography>
                                    </Grid>
                                    <Grid item xs={2} textAlign="end">
                                        <Box mt={-1.5}>
                                            <Checkbox
                                                onChange={() => onSelectInspectionItem(x.id)}
                                                checked={selectedInspectionItem.includes(x.id) ? true : false}
                                                icon={<RadioButtonUncheckedIcon sx={{ color: "#E4E8EE" }} />}
                                                checkedIcon={<CheckCircleIcon sx={{ color: "#5078E1" }} />} />
                                        </Box>
                                    </Grid>
                                </Grid>
                                {(list?.length - 1) !== i &&
                                    <Box mt={0.5}>
                                        <Divider className={classes.divStyle} />
                                    </Box>
                                }
                            </>
                        )
                    })
                        :
                        <Box mt={2}>
                            <Typography textAlign={"center"}>{t("No Data Found")}</Typography>
                        </Box>
                }

            </InfiniteScroll>

        </Box>
    )
}