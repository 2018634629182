import { Avatar, Box, Divider, Grid, Stack } from "@mui/material"
import React from "react"
import { withTranslation } from "react-i18next"
import { CircleIcon, GatepassIcon, InspectionIcon, KeyIcon } from "../../../assets"
import { DetailsCard, EditInspectionHour } from "../../../components"
import { Cards } from "../../../components/detailsCard/card"
import { convertTimeUtcToZoneCalander, RemoveZUTCDateTime } from '../../../utils'

const InspectionRequestCard = (props) => {
    const { t } = props;
    // const [data, setData] = React.useState({
    //     inspector: []
    // });


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                {/* <DetailsCard
                    title={t("inspectionDetail")}
                    data={props?.details?.inspection_detail}
                    icon={<InspectionIcon />}
                /> */}
                <Cards
                    t={t}
                    title={t("inspectionOrder")}
                    subTitle={props?.details?.inspection_detail?.reference_id}
                    subTitle1={<Stack direction="row" divider={<Divider orientation="vertical" flexItem sx={{marginInline:'12px'}}/>}>
                        <Box>{props?.details?.inspection_detail?.first_name}</Box>
                        <Box>&#x202a;{convertTimeUtcToZoneCalander(props?.details?.inspection_detail?.created_at)}&#x202c;</Box>
                    </Stack>}
                    icon={<InspectionIcon />}
                />
            </Grid>
            {
                props?.details?.responsible_person_detail?.name &&
                <Grid item xs={12} md={3}>

                    {/* <Cards
                        title={t("responsiblePersonDetail")}
                        subTitle={props?.details?.responsible_person_detail?.name}
                        subTitle1={`${props?.details?.responsible_person_detail?.mobile_no_country_code} ${props?.details?.responsible_person_detail?.mobile_no}`}
                        icon={<Avatar sx={{ height: 40, width: 40, marginBottom: "6px" }} variant="rounded" src={props?.details?.responsible_person_detail?.image_url} />}
                    /> */}
                    <Cards
                        t={t}
                        title={t("propertyAndUnitDetails")}
                        subTitle={props?.details?.inspection?.property_name}
                        subTitle1={`${props?.details?.inspection?.unit_no ?? ""} , ${props?.details?.inspection?.unit_name ?? ""}`}
                        icon={<Avatar sx={{ height: 40, width: 40, marginBottom: "6px" }} variant="rounded" src={props?.details?.inspection?.property_logo} />}
                    />
                </Grid>
            }

            {
                props?.details?.resource_details?.length > 0 &&
                <Grid item xs={12} md={3}>
                    <DetailsCard
                        t={t}
                        reassign={!["Completed", "Rejected","Yet To Verify"].includes(props?.inspectionStatus)}
                        title={t("resourceDetails")}
                        data={props?.details?.resource_details?.length > 0 ? props?.details?.resource_details?.[0] : []}
                        AllData={props?.details?.resource_details?.length > 1 ? props?.details?.resource_details : []}
                        viewAll={props?.details?.resource_details?.length > 0 ? true : false}
                        icon={<InspectionIcon />}
                        handleStatusClick={props?.handleStatusClick}
                        onClick={() => props?.setAssignModal(true)}
                    />
                </Grid>
            }

            {
                props?.inspectionStatus === "Completed" ?

                    <Grid item xs={12} md={3}>
                        <Cards
                            t={t}
                            title={t("completionDetails")}
                            subTitle=""
                            subTitle1={<><Box>{`Start Date : ${RemoveZUTCDateTime(props?.details?.track?.start, 'DD-MM-yyyy hh:mm A')}`}</Box><Box>{`End Date : ${RemoveZUTCDateTime(props?.details?.track?.end, 'DD-MM-yyyy hh:mm A')}`}</Box></>}
                            icon={<InspectionIcon />}
                        />
                    </Grid> :
                    props?.inspectionStatus !== "Yet to assign" &&
                    props?.details?.inspection?.execution &&
                    <Grid item xs={12} md={3}>
                        <Cards
                            t={t}
                            title={t("assigned_date")}
                            subTitle={` `}
                            subTitle1={`Start Date : ${convertTimeUtcToZoneCalander(RemoveZUTCDateTime(props?.details?.inspection?.execution), 'DD-MM-yyyy')} `}
                            icon={<InspectionIcon />}
                        />
                    </Grid>

            }


            {
                props?.details?.track_update?.length > 0 &&
                <Grid item xs={12} md={3}>
                    <DetailsCard
                        t={t}
                        title={t("trackUpdate")}
                        data={props?.details?.track_update?.length > 0 ? props?.details?.track_update?.[0] : []}
                        AllData={props?.details?.track_update?.length > 1 ? props?.details?.track_update : []}
                        viewAll={props?.details?.track_update?.length > 0 ? true : false}
                        icon={<CircleIcon />}
                    />
                </Grid>
            }
            {props?.details?.security_request?.length > 0 &&
                <Grid item xs={12} md={4}>
                    <DetailsCard
                        t={t}
                        title={t("Gate Pass")}
                        data={{ "created_at": props?.details?.security_request[0]?.created_at }}
                        onButtonClick={props?.onButtonClick}
                        icon={<GatepassIcon />}
                        buttonName="Send Link"
                    />
                </Grid>
            }
            {(props?.inspectionStatus === "Yet To Verify"||props?.inspectionStatus==="Completed") &&
                <Grid item xs={12} md={4}>
                    <EditInspectionHour
                        t={t}
                        noBg
                        agreement_inspection_id={props?.details?.inspection?.id}
                        status={props?.inspectionStatus}
                        reload={props?.reload}
                        resource_id={props?.details?.resource_details?.find(i => i?.is_primary)?.id}
                    />
                </Grid>
            }



            {props?.inspectionStatus === "Completed" &&
                <Grid item xs={12} md={4}>
                    <DetailsCard
                        t={t}
                        title={t("Tenant Login")}
                        is_active={props?.details?.user?.is_active}
                        icon={<KeyIcon />}
                        buttonName={props?.details?.user?.is_active ? "" : t("Send Link")}
                        onButtonClick={props?.onButtonClick}
                    />
                </Grid>
            }
        </Grid>

    )
}
export default withTranslation("inspection")(InspectionRequestCard)