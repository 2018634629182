import { Box, Button, Popover } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontFamilySwitch } from "../../../utils";
const styles = makeStyles((theme) => ({
    img: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "13px",
      },
      btnParent: {
        display: "flex",
        alignItems: "center",
        background: "white",
        position: "absolute",
        bottom: "0",
        width: "100%",
        padding: "7px 11px",
        borderTop: "1px solid #E4E8EE",
        justifyContent: "flex-end",
      },
      btn: {
        fontFamily: FontFamilySwitch().semiBold,
        color: theme?.palette?.background?.tertiary1,
        backgroundColor: theme?.palette?.primary?.main,
        padding: "5px 11px",
        marginInlineStart: "10px",
        fontSize:"0.75rem",
        "&:hover": {
          backgroundColor: theme?.palette?.primary?.main,
          color: theme?.palette?.background?.tertiary1,
        },
      },
      outLine: {
        fontFamily: FontFamilySwitch().semiBold,
        color: theme?.typography?.color?.primary,
        backgroundColor: "white",
        padding: "5px 11px",
        border: `1px solid ${theme?.palette?.primary?.main}`,
        fontSize:"0.75rem",
        "&:hover": {
          color: theme?.typography?.color?.primary,
          backgroundColor: "white",
          border: `1px solid ${theme?.palette?.primary?.main}`,
        },
      },
      datePicker: {
        "& div": {
          "&  .react-datepicker__month": {
            padding: "1rem 0 33px !important",
          },
        },
      },
}));
export const CustomDatePicker = ({
    startIcon = "",
    placeholder = "",
    handleChange = () => false,
    value =null,
    endDate = "",
    minDate = null,
    maxDate = null,
    margintop = "0px",
    fontFamily = FontFamilySwitch().regular, 
    openClosePopOver=()=>false,
    handleSubmit=()=>false,
    t=()=>false,
    anchorEl,
    openClose=()=>false,
    anchorOrigin,
    transformOrigin,
}) => {
    const classes = styles({ fontFamily })
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const [state , setState]=React.useState(null)
    return (

        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={openClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        className={classes.Popover}
      >
            <DatePicker
                selected={state??value}
                onChange={(value) => {
                    setState(value)
                }}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                disabled
                dropdownMode="select"
                selectsDisabledDaysInRange
                inline
                minDate={minDate ?? false}
                maxDate={maxDate ?? false}
            />
            <Box className={classes.btnParent}>
            <Button className={classes.outLine} onClick={openClose}>
              {t('Cancel')}
            </Button>
            <Button className={classes.btn} onClick={()=>handleSubmit(state)} disabled={!state ? true : false}>
              {t('Change')}
            </Button>
        </Box>
        </Popover>
    )
}