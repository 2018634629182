import { Badge, Box, Divider, Grid, IconButton, Stack } from "@mui/material";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import FilterIMG from "../../../../../assets/filter";
import {
  FilterGenerator,
  LoadingSection,
  SearchFilter,
  TableWithPagination,
  UseDebounce,
} from "../../../../../components";
import { config } from "../../../../../config";
import { NetworkCall } from "../../../../../networkcall/index";
import { Routes } from "../../../../../router/routes";
import { NetWorkCallMethods } from "../../../../../utils";
import { loadOptionsApis } from "../../../../../utils/asyncPaginateLoadOptions";
import {
  PricingTableUnitheading,
  PricingTableUnitpath,
} from "../../../../../utils/block";
import { loadOptionsApis as CustomLoadOptionsAPI } from "../../../../createMaintenancePlanner/utils";
import { unitStyles } from "./style";
import { BackdropContext } from "../../../../../contexts";

export const UnitList = ({ t = () => false, companyId = "" }) => {
  const classes = unitStyles();
  const navigate = useNavigate();
  const search = useLocation().search;
  const [unitlist, setUnitList] = React.useState({
    data: [],
    count: 0,
    property: {},
  });

  const [drawer, setDrawer] = React.useState(false);
  const backdrop = React.useContext(BackdropContext);
  const [searchText, setSearchText] = React.useState("");
  const propertyID = new URLSearchParams(search).get("propertyID");
  const [loading, setLoading] = React.useState(true);
  const debounce = UseDebounce();
  const [filterData, setFilterData] = React.useState({ status: [true] });

  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");
  const limitParam = searchParams.get("limit");

  // Default values if not set in URL
  const pageFromParams = pageParam ? parseInt(pageParam, 10) : 1;
  const limitFromParams = limitParam ? parseInt(limitParam, 10) : 10;

  const [page, setPage] = React.useState(pageFromParams);
  const [limit, setLimit] = React.useState(limitFromParams);

  const updatePage = (newPage, newLimit = limit) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", newPage.toString());
    searchParams.set("limit", newLimit.toString());

    // Update URL without reloading
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
    const newOffset = (newPage - 1) * newLimit;
    // Update state
    setPage(newPage);
    setLimit(newLimit);

    getunitlist(propertyID, filterData, "", newOffset, newLimit);
  };

  const getunitlist = (id, filterProp, searchText, offSet, limits) => {
    const variables = {
      tenantId: `${config.tenantId}`,
      property_id: id ?? null,
      offset: offSet,
      limit: limits,
      unit_type: filterProp?.unit_type?.length
        ? filterProp?.unit_type?.map(({ value }) => value)
        : [],
      status: filterProp?.status?.length ? filterProp?.status : [],
      unit_category: filterProp?.unit_category?.length
        ? filterProp?.unit_category?.map(({ value }) => value)
        : [],
      search: searchText,
    };
    NetworkCall(
      `${config.api_url}/unit/list`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((res) => {
        const list = res.data.list?.map((val) => {
          let _d;
          try {
            _d = {
              unitNo: val?.unit_no,
              unitName: val?.unit_name,
              unitcategory: val?.unit_category,
              revenuetype: val?.revenue_type,
              propertyName: val?.property?.name,
              unitpurpose: val?.unit_purpose,
              // eslint-disable-next-line
              totalarea: `${val?.total_area}  ${val?.area_metric ?? ""}`,
              furnishing: val?.furnishing,
              baths: val?.total_baths,
              id: val?.unit_id,
              bed: val?.total_bed_rooms,
              icon: "editview",
              status: val?.active_status ? "Active" : "Inactive",
              blockName: val?.block_name,
              floorName: val?.floor_name,
              companyName: val?.company_name,
              // eslint-disable-next-line
              location: val?.property?.address?.city
                ? val?.property?.address?.city
                : val?.property?.address?.district,
            };
          } catch (err) {}
          return _d;
        });
        setUnitList({
          count: res?.data?.count,
          data: list,
          property: {
            name: res.data?.property?.name,
            id: res.data?.property?.id,
          },
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const currentOffset = (page - 1) * limit;
  React.useEffect(() => {
    getunitlist(propertyID, filterData, "", currentOffset, limitFromParams);
    // eslint-disable-next-line
  }, []);
  const handleIcon = (type, data) => {
    if (type === "view") {
      navigate(`${Routes.utilitiesMappingUnitView}?unitID=${data?.id}`);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e);
    debounce(() => searchTableFunction(e), 800);
  };
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getunitlist(propertyID, filterData, e, 0, limit);
  };
  //handle pagination
  const handlePagination = (newPage) => {
    updatePage(newPage, limit);
  };

  const handleChangeLimit = (newLimit) => {
    updatePage(1, newLimit); // Reset page to 1 when limit changes
  };

  const onApplyFilter = (value) => {
    setFilterData(value);
    getunitlist(propertyID, value, "", 0, limit);
  };
  const manualResponse = (array) => {
    const details = {
      data: array?.data?.map((i) => {
        return {
          ...i,
        };
      }),
      count: array?.count,
    };
    return details;
  };
  return (
    <>
      {loading ? (
        <LoadingSection bottom={"45vh"} message="Loading Unit Listing..." />
      ) : (
        <div>
          <Box p={2} className={classes.root} marginTop={2}>
            <Grid container>
              <Grid item xs={4}>
                <SearchFilter
                  value={searchText}
                  placeholder={t("Search Units")}
                  handleChange={(value) => handleSearch(value)}
                />
              </Grid>
              <Grid item xs={8} display={"flex"} justifyContent={"end"}>
                <Box display={"flex"} sx={{ float: "right" }}>
                  <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                  >
                    {filterData.status?.length > 0 ||
                    filterData.property_type?.length > 0 ? (
                      <IconButton
                        onClick={() => setDrawer(true)}
                        className={classes.img}
                      >
                        <Badge variant="dot" color="primary">
                          <FilterIMG color="#091b29" />
                        </Badge>
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => setDrawer(true)}
                        className={classes.img}
                      >
                        <FilterIMG color="#091b29" />
                      </IconButton>
                    )}
                  </Stack>
                </Box>
              </Grid>
            </Grid>
            <TableWithPagination
              heading={PricingTableUnitheading(t)}
              rows={unitlist?.data}
              path={PricingTableUnitpath}
              showpagination={true}
              count="2"
              showpdfbtn={false}
              showexcelbtn={false}
              showSearch={false}
              handleIcon={handleIcon}
              tableType="no-side"
              onClick={() => console.log("")}
              dataType={[
                { type: ["text"], name: "unitNo" },
                { type: ["text"], name: "unitName" },
                { type: ["text"], name: "unitcategory" },
                { type: ["text"], name: "revenuetype" },
                { type: ["text"], name: "unitpurpose" },
                { type: ["status"], name: "status" },
              ]}
              handlePagination={handlePagination}
              handleChangeLimit={handleChangeLimit}
              totalRowsCount={unitlist?.count}
              page={page}
              limit={limit}
              height={`calc(100vh - 432px)`}
              view={true}
              edit={true}
              delete={true}
            />
          </Box>
        </div>
      )}
      {/* filter drawer */}
      {drawer && (
        <FilterGenerator
          open={drawer}
          onClose={() => setDrawer(false)}
          components={[
            {
              component: "select",
              value: filterData?.unit_category,
              options: [],
              isMulti: true,
              label: t("Unit Category"),
              state_name: "unit_category",
              loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                  "queries/unit_category/get",
                  {},
                  search,
                  array,
                  handleLoading,
                  "unit_category_master",
                  {}
                ),
              debounceTimeout: 800,
              isPaginate: true,
            },
            {
              component: "select",
              value: filterData?.unit_type,
              options: [],
              isMulti: true,
              label: t("Unit Type"),
              state_name: "unit_type",
              loadOptions: (search, array, handleLoading) =>
                CustomLoadOptionsAPI(
                  "queries/unit_type_master",
                  {
                    company_id: companyId?.id,
                  },
                  search,
                  array,
                  handleLoading,
                  "data",
                  {},
                  manualResponse
                ),
              debounceTimeout: 800,
              isPaginate: true,
            },
            {
              component: "toggleButton",
              value: filterData?.status,
              options: [
                { label: t("Active"), value: true },
                { label: t("Inactive"), value: false },
              ],
              isMulti: true,
              state_name: "status",
              label: t("Status"),
              // required:true
            },
          ]}
          onApply={(value) => onApplyFilter(value)}
        />
      )}
    </>
  );
};
