
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { FontFamilySwitch } from "../../utils";

export const LongTextTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: "400px",
      borderRadius: "4px",
      padding: "8px 12px",
      backgroundColor: "#FFFFFF",
      color: "#1C1C1C",
      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
      fontSize:"0.875rem",
      fontFamily: FontFamilySwitch().semiBold,
    },
    [`& .${tooltipClasses.arrow}`]: {
      backgroundColor: "transparent",
      color: "#FFFFFF"
    },
  }));