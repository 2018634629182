import { Typography, Box, Stack, Divider, Button } from "@mui/material";
import React, { useState, useContext } from "react";
import { managrmentStyles } from "./style";
import {
  DialogBox,
  TableWithPagination,
  RangeDatePicker,
} from "../../components";
import {
  manageInvoicePreviewPath,
  manageInvoicePreviewHeading,
  manageInvoicePreviewType,
  getTotalMangeInvoice,
  AlertProps,
  NetWorkCallMethods,
  getToday,
} from "../../utils";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";

export const BottomCard = (props) => {
  const language = localStorage.getItem("i18nextLng")
  const { t } = props;
  
  // classes
  const classes = managrmentStyles();
  // context
  const alert = useContext(AlertContext);
  // ------------------------STATE-------------------------
  const data = [
    {
      lable: t("Total Invoice"),
      value: props?.list?.length ?? 0,
    },
    {
      lable: t("Total Amount"),
      value: `${props?.company?.currency_symbol ?? ""} ${props?.list?.length > 0 ? getTotalMangeInvoice(props?.list) : 0
        }`,
    },
  ];
  const [open, setOpen] = useState({
    bool: false,
    type: "",
  });
  const [sucessDialog, setSucessDialog] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [alterDate, setAlterDate] = useState(getToday());
  const [type, setType] = React.useState("")
  const [onInActive, setInActive] = React.useState({
    type: false,
    bool: false
  })
  const [disable, setDisable] = React.useState({
    in_active: false
  })
  const [isDisableBtn, setIsDisableBtn] = React.useState(false)

  // --------------------------FUNCTION-----------------------------
  const openClosePopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const openClose = () => {
    setAnchorEl(null);
  };
  const onSumbit = (data) => {
    setAlterDate(data);
    closePopup("posting");
    setType('alter')
  };

  const closePopup = (type) => {
    if (type === "Proceed") {
      setOpen({
        ...open,
        type: type,
      });
    } else {
      setOpen({
        bool: !open?.bool,
        type: type ?? "",
      });
    }
  };
  const backdrop = React.useContext(BackdropContext)


  const Proceed = (props) => {
    return (
      <div className={language === "ar" ? classes.proceedDialogArabic : classes.proceedDialog}>
        <Box>
          <Typography className={classes.convert}>{props?.title}</Typography>
          <Typography className={classes.convertProcess}>
            {props?.sub}
          </Typography>
          <Box display="flex" alignItems="center" className={classes.marginTop}>
            <Button className={classes.yes} onClick={props?.yesClick} disabled={props?.disable ? props?.disable : isDisableBtn ? true : false}>
              {t("Yes")}
            </Button>
            <Button className={classes.No} onClick={props?.noClick}>
              {t("No")}
            </Button>
          </Box>
        </Box>
      </div>
    );
  };

  const Posting = (props) => {
    return (
      <div className={language === "ar" ? classes.proceedDialog1Arabic : classes.proceedDialog1}>
        <Box>
          <Typography className={classes.convert}>{props?.title}</Typography>
          <Box display="flex" alignItems="center" className={classes.marginTop}>
            <Button className={classes.yes} onClick={props?.onClose}>
              {t("Okay")}
            </Button>
          </Box>
        </Box>
      </div>
    );
  };

  const ProceedTable = () => {
    return (
      <div className={classes.parentDialog}>
        <TableWithPagination
          heading={manageInvoicePreviewHeading(t)}
          rows={props?.list ?? []}
          path={manageInvoicePreviewPath}
          showpagination={false}
          showpdfbtn={false}
          showexcelbtn={false}
          showSearch={false}
          totalRowsCount={props?.list?.length ?? 0}
          tableType="no-side"
          dataType={manageInvoicePreviewType}
          height={"calc(100vh - 400px)"}
        />

        {/*  */}
        <Box className={classes?.cardBox}>
          <Typography className={classes.amoutTilte}>
            {t("Total_Amount")}
          </Typography>
          <Typography className={classes.amount}>
            {props?.company?.currency_symbol ?? ""}{" "}
            {props?.list?.length > 0 ? getTotalMangeInvoice(props?.list) : 0}
          </Typography>
        </Box>

        <Box className={classes.btnParent}>
          <Button className={classes.Cancel} onClick={closePopup}>
            {t("Cancel")}
          </Button>
          <Button
            className={classes.next}
            onClick={() => closePopup("Proceed")}
          >
            {t("Proceed")}
          </Button>
        </Box>
      </div>
    );
  };

  // components
  const component = {
    Preview: <ProceedTable />,
    posting: (
      <Proceed
        title={t("Alter")}
        type={"alter"}
        yesClick={() => {
          updateAlterDate();
        }}
        isDisableBtn={isDisableBtn}
        noClick={() => closePopup("")}
      />
    ),
    Proceed: (
      <Proceed
        title={t("convert")}
        sub={t("process")}
        isDisableBtn={isDisableBtn}
        yesClick={() => {
          updateProceed();
        }}
        noClick={() => closePopup("")}
      />
    ),
  };

  // update alter date
  const updateAlterDate = () => {
    setType('alter')
    setIsDisableBtn(true)
    const datas = {
      company_id: props?.company?.value,
      managed_invoice_ids: props?.list?.map((x) => x?.id) ?? [],
      payment_due_date: alterDate ?? undefined,
      is_all:false,
      start_date: props?.date?.from ?? undefined,
      end_date: props?.date?.to ?? undefined,
    };

    NetworkCall(
      `${config.api_url}/invoice/alter_managed_invoices`,
      NetWorkCallMethods.post,
      datas,
      null,
      true,
      false
    )
      .then((response) => {
        setSucessDialog(true);
        setIsDisableBtn(false)
        setType('alter')
        closePopup("");
        props?.reloadFunction();
      })
      .catch((error) => {
        setIsDisableBtn(false)
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
      });
  };

  // update Proceed
  const updateProceed = () => {
    closePopup("");
    setType('posting');
    setIsDisableBtn(true)
    backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." }); 
    const datas = {
      company_id: props?.company?.value,
      managed_invoice_ids:
        props?.allList?.length === props?.list
          ? []
          : props?.list?.map((x) => x?.id) ?? [],
      is_all: props?.allList?.length === props?.list ? true : false,
      start_date: props?.date?.from ?? undefined,
      end_date: props?.date?.to ?? undefined,
      is_resubmit: true, // let the integration know that this is a resubmit
    };

    NetworkCall(
      `${config.api_url}/invoice/convert_managed_invoice`,
      NetWorkCallMethods.post,
      datas,
      null,
      true,
      false
    )
      .then((response) => {
        backdrop.setBackDrop({ ...backdrop, open: false, message: "Loading..." })
        if (response?.data?.type == "error") {
          setIsDisableBtn(false);
          closePopup("");
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: response?.data?.message,
          });
          props?.reloadFunction();
          return;
        }

        setSucessDialog(true);
        setType("posting");
        setIsDisableBtn(false);
        closePopup("");
        props?.reloadFunction();
      })
      .catch((error) => {
        backdrop.setBackDrop({ ...backdrop, open: false, message: "Loading..." })
        setIsDisableBtn(false)
        console.error("error", error);
      });
  };
  const InActiveManage = () => {
    backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." }); 
    const payload = {
      company_id: props?.company?.value,
      managed_invoice_ids:
        props?.allList?.length === props?.list
          ? []
          : props?.list?.map((x) => x?.id) ?? [],
      is_all: props?.allList?.length === props?.list ? true : false,
      is_active: props?.selectType?.value==="Void Invoices" ?true:false,
      is_queued: true
    }
    NetworkCall(
      `${config.api_url}/invoice/upsert`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        backdrop.setBackDrop({ ...backdrop, open: false, message: "Loading..." })
        setDisable({
          ...disable,
          is_active: false
        })
        setInActive({
          type: true,
          bool: true
        })
        props?.reloadFunction();
      })
      .catch((err) => {
        backdrop.setBackDrop({ ...backdrop, open: false, message: "Loading..." })
        setDisable({
          ...disable,
          is_active: false
        })
        setInActive({
          type: false,
          bool: false
        })
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
      });
  };

const onCloseAll=()=>{
  setOpen({
    type:"",
    open:false
  })
}

  return (
    <>
      <Box className={classes.buttomCard}>
        {/*  */}
        <Box>
          <Stack
            direction="row"
            display={"flex"}
            alignItems="center"
            divider={<Divider sx={{ marginInline: "16px" }} orientation="vertical" flexItem />}
          >
            {data?.map((x) => {
              return (
                <Box>
                  <Typography className={classes.title}>{x?.lable}</Typography>
                  <Typography className={classes.totalAmount}>
                    {x?.value}
                  </Typography>
                </Box>
              );
            })}
          </Stack>
        </Box>

        {props?.selectType?.value === "Void Invoices" ? (
          <Box display="flex" alignItems="center">
            <Button
              className={classes.btn}
              onClick={() =>
                setInActive({
                  type: false,
                  bool: true,
                })
              }
              disabled={true}
              variant="contained"
            >
              {t("Unvoid invoice")}
            </Button>
          </Box>
        ) : (
          <Box display="flex" alignItems="center">
            <Button
            className={classes.outLine}
            onClick={() => {
              setInActive({
                type: false,
                bool: true,
              })
            }}
            disabled={props?.list?.length > 0 ? false : true}
            variant="outlined">
              {t("Cancel")}
            </Button>
            <Button
            className={classes.btn}
            onClick={() => closePopup("Preview")}
            disabled={props?.list?.length > 0 ? false : true}
            variant="contained">
              {t("Resubmit")}
            </Button>
          </Box>
        )}
      </Box>
      {/* date picker */}

      <RangeDatePicker
        outline={t("Cancel")}
        container={t("Change")}
        openClosePopOver={openClosePopOver}
        anchorEl={anchorEl}
        onSumbit={onSumbit}
        openClose={openClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      />
      {/* proceed dialog */}
      <DialogBox
        open={open?.bool}
        isnotTitle={open?.type === "Preview" ? false : true}
        handleClose={() => onCloseAll()}
        onClose={() => onCloseAll()}
        header={t("Preview")}
        maxWidth={"sm"}
        component={component[props?.component ?? open?.type]}
      />

      {/* sucess dialog */}
      <DialogBox
        open={sucessDialog}
        isnotTitle
        handleClose={() => setSucessDialog(false)}
        onClose={() => setSucessDialog(false)}
        maxWidth={"sm"}
        component={
          <Posting
            title={type === "alter" ? t("Posting date changed successfully") : t("Managed Invoice posted successfully")}
            onClose={() => setSucessDialog(false)}
          />
        }
      />

      <DialogBox
        open={onInActive?.bool}
        isnotTitle
        handleClose={() => setInActive({
          type: false,
          bool: false
        })}
        onClose={() => setInActive({
          type: false,
          bool: false
        })}
        maxWidth={"sm"}
        component={
          <>
          {
           onInActive?.type ? 
           <Posting
           title={t( props?.selectType?.value==="Void Invoices" ? "Unvoided successfully!": "Voided successfully!")}
           onClose={() => setInActive({
            type:false,
            bool:false
          })}
         />:
           <Proceed
          title={ t(`Are you sure you want to cancel the invoice?`)}
          type={"alter"}
          disable={disable?.is_active}
          yesClick={() => {
            InActiveManage();
          }}
          noClick={() => setInActive({
            type:false,
            bool:false
          })}
        />
          }
          </>

        }
      />
    </>
  );
};
