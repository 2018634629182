import { Box } from "@mui/material";
import moment from "moment";
import React from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { TableWithPagination } from "../../../components";
import { NewLoader } from "../../../components/newLoader";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods, SwitchInspectionRoutes } from '../../../utils';
import { ScheduleDataType, ScheduleHeading, SchedulePath } from "../../../utils/tableData";
const Table = ({
    data = [],
    companyId,
    propertyId,
    resourceType,
    scheduleData,
    type,
    t
}) => {
    const [tableData, setTableData] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const navigate = useNavigate()

    const getTableData = (offset = 0, limit) => {

        if (data?.resources) {
            const RequestId = data?.resources?.[type]?.map((x) => { return x.request_id })
            const payload = {
                company_id: companyId,
                property_id: propertyId,
                request_type: type ?? undefined,
                resource_type: resourceType,
                from: moment(data.start).format("YYYY-MM-DD"),
                to: moment(data.end).format("YYYY-MM-DD"),
                batchDuration: 4,
                request_id: RequestId
            }

            NetworkCall(
                `${config.api_url}/schedule_board/get_all_inspection_resource`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((res) => {
                setLoading(false)
                const list = res?.data?.data?.map((val) => {
                    return {
                        ...val,
                        agreement_inspection_status: val?.agreement_inpection_status,
                        eta_minutes: val?.eta_minutes ? val?.eta_minutes < 60 ? `${val?.eta_minutes} Mins` : `${(val?.eta_minutes / 60).toFixed(2)} Hrs` : val?.eta
                    }
                })
                setTableData(list)
            }).catch((err) => {
                setLoading(false)
                console.log(err)
            })
        }
    }
    React.useEffect(() => {
        getTableData()
        // eslint-disable-next-line
    }, [])
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getTableData(offset, limit, "")
    }
    const handleIcon = (val, data) => {
        const RoutePath = SwitchInspectionRoutes(type)
        navigate(RoutePath, {
            state: {
                id: data.agreement_request_id,
                agreement_inspection_id: data?.agreement_inspection_id,
                agreement_id: data?.agreement_id,
                title: data?.inspection_id,
                type: type,
                data: data,
                request_id: data.agreement_request_id,
                reference_id: data.reference_id
            }
        })
    }

    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getTableData(0, limit)

    }
    return (
        <Box p={1}>
            {
                loading ?
                    <NewLoader minusHeight="158px" />
                    :
                    <TableWithPagination
                        heading={ScheduleHeading(t)}
                        rows={tableData}
                        path={SchedulePath}
                        dataType={ScheduleDataType}
                        showpagination={true}
                        count="2"
                        showpdfbtn={false}
                        showexcelbtn={false}
                        showSearch={false}
                        tableType="no-side"
                        handleIcon={handleIcon}
                        view={true}
                        handlePagination={handlePagination}
                        handleChangeLimit={handleChangeLimit}
                        totalRowsCount={tableData?.length}
                        page={page}
                        limit={limit}
                        height={`calc(100vh - 300px)`}
                    />
            }
        </Box>
    )
}
export default withTranslation("inspectionItemmaster")(Table); 