/* eslint-disable use-isnan */
import { Avatar, Box, Button, Dialog, Divider, Grid, IconButton, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { convertTimeUtcToZoneCalander, enumName, stringAvatar } from "../../../utils"
import { UnitHeading, UnitPath, UnitType } from "../../../utils/agreementRenewal/agreementRenewalUtils"
import { useStyles } from "../style"
import { RenewalSelect, TableWithPagination, TextBox } from "../../../components";
import InfoIcon from "../../../assets/infoIcon";
import DeclineIcon from "../../../assets/agreementDetails/declineIcon";
import RequestDeatilsIcon from "../../../assets/agreementDetails/requestDetailsIcon";
import AGRIcon from "../../../assets/agreementDetails/agrIcon";
import AcceptIcon from "../../../assets/agreementDetails/acceptIcon";
import { Stack } from "@mui/system";

export const RequestView = (props) => {
    const { t = () => false } = (props)

    const classes = useStyles()

    return <Dialog
        className={classes.viewDialog}
        open={props?.requestDialogOpen}
        onClose={() => props?.setRequestDialogOpen(false)}>
        <div className={classes.viewDialogHeader}>
            <div className={classes.viewDialogHeaderContainer}>
                <Typography className={classes.viewDialogHeaderTitle}>
                    {t("Request View")}
                </Typography >
            </div >
            <IconButton onClick={() => props?.setRequestDialogOpen(false)}
                className={classes.viewDialogCloseButton}>
                <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
            </IconButton>
        </div >
        <div className={classes.viewDialogBody}>
            <Grid container>
                <Grid item xs={6} paddingInlineEnd={"8px"}>
                    <Box height={'calc(100vh - 207px)'} overflow={"overlay"}>
                        {(props?.requestState?.status?.value !== enumName?.approved &&
                            props?.requestState?.occupiedUnits?.length > 0) &&
                            <Grid container direction={"row"} justifyContent={"space-between"}>
                                <Grid item xs={12} paddingBottom={"12px"}>

                                    <div className={classes.occupiedErrorCard}>
                                        <div className={classes.occupiedErrortextDiv}>
                                            <Typography className={classes.occupiedErrorText}>
                                                {props?.requestState?.occupiedUnits?.length > 1 ?
                                                    t("Few Units Are Occupied") :
                                                    (props?.requestState?.occupiedUnits?.[0]?.unit_name + " - " +
                                                        props?.requestState?.occupiedUnits?.[0]?.unit_no + " Is Occupied")}
                                            </Typography>
                                        </div>
                                        <div className={classes.occupiedErrorIconDiv}>
                                            <InfoIcon fill="#FF4B4B" hoverFill="#FF4B4B" />
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>}
                        {(props?.requestState?.status?.value !== enumName?.pending) &&
                            <Box className={classes.approved_box}>
                                <Box>
                                    {props?.requestState?.status?.value === enumName?.rejected ? <DeclineIcon /> : <AcceptIcon />}
                                </Box>
                                <Box flexGrow={1} marginLeft={"10px"} >
                                    <Typography className={classes.viewDialogText4}>
                                        {props?.requestState?.status?.label}
                                    </Typography>
                                    <Typography className={classes.italicText} pb={"12px"}>
                                        {t("By")} {props?.requestState?.approvedBY} on {props?.requestState?.updatedOn}
                                    </Typography>
                                    <Typography className={classes.semiBoldText}>
                                        {t("Remarks")}  : <span className={classes.regularText}>{props?.requestState?.managerRemarks}</span>
                                    </Typography>
                                </Box >
                            </Box >
                        }
                        <Box className={classes.boxWithOutFlex}>
                            <Box marginBottom={"12px"}>
                                <Typography className={classes.heading2} textTransform={"uppercase"}>
                                    {t("ACCOUNT INFORMATION")}
                                </Typography>
                            </Box>
                            <Box className={classes.boxWithOutBorder}>
                                <Box>
                                    <RequestDeatilsIcon />
                                </Box>
                                <Box flexGrow={1} marginInlineStart={"10px"} >
                                    <Typography className={classes.viewDialogText8}>
                                        {props?.requestState?.accountNo}
                                    </Typography>
                                    <Typography className={classes.italicText}>
                                        {props?.requestState?.accountName}
                                    </Typography>
                                </Box >
                            </Box >
                            <Box margin={"8px 0px"}>
                                <Divider />
                            </Box>
                            <Box margin={"12px 0px"}>
                                <Typography className={classes.heading2} textTransform={"uppercase"}>
                                    {t("REQUEST DETAILS")}
                                </Typography>
                            </Box>
                            <Box className={classes.boxWithOutBorder}>
                                <Box>
                                    <RequestDeatilsIcon />

                                </Box>
                                <Box flexGrow={1} marginInlineStart={"10px"} >
                                    <Box className={classes.boxFlex}>
                                        <Box>
                                            <Typography className={classes.viewDialogText8}>
                                                {props?.requestState?.requestedBy}
                                            </Typography>
                                        </Box>
                                        {props?.requestState?.status?.value === enumName?.pending &&
                                            <Box className={classes.inProgress}>
                                                {props?.requestState?.status?.label}
                                            </Box>
                                        }
                                    </Box>

                                    <Typography className={classes.italicText}>
                                        {props?.requestState?.requestNo} on {props?.requestState?.requestedOn}
                                    </Typography>
                                    <Typography mt={"12px"} className={classes.semiBoldText}>
                                        {t("Remarks")} : <span className={classes.regularText}>{props?.requestState?.acceptDeclineRemarks}</span>
                                    </Typography >

                                </Box >
                            </Box >
                            <Box margin={"8px 0px"}>
                                <Divider />
                            </Box>
                            <Box margin={"12px 0px"}>
                                <Typography className={classes.heading2} textTransform={"uppercase"}>
                                    {t("Current Agreement")}
                                </Typography>
                            </Box>
                            <Box className={classes.boxWithOutBorder} marginBottom={"8px"}>
                                <Box>
                                    <AGRIcon />
                                </Box>
                                <Box flexGrow={1} marginInlineStart={"10px"} >
                                    <Box className={classes.boxFlex}>
                                        <Box>
                                            <Typography className={classes.viewDialogText4}>
                                                {props?.requestState?.agreementNo}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.renewal_type}>
                                            {props?.requestState?.renewal_type}
                                        </Box >
                                    </Box >
                                    <Typography className={classes.semiBoldText}>
                                        &#x202a;{props?.requestState?.leaseStart}&#x202c; - &#x202a;{props?.requestState?.leaseEnd}&#x202c;
                                    </Typography>
                                </Box >
                            </Box >
                            {props?.requestState?.autoRenewalEscalation?.value &&
                                <Stack direction={"row"} justifyContent={"space-between"}>
                                    <Typography className={classes.viewDialogText9} textTransform={"capitalize"}>
                                        {t("Renewal Based On")}
                                    </Typography>
                                    <Typography className={classes.viewDialogText10}>
                                        {props?.requestState?.autoRenewalEscalation?.label}{(props?.requestState?.autoRenewalEscalation?.value === "Increment %" && props?.requestState?.increment_escalation) && (" - " + props?.requestState?.increment_escalation + "%")}
                                    </Typography>
                                </Stack>}
                            <Stack direction={"row"} justifyContent={"space-between"} marginTop={"8px"}>
                                <Typography className={classes.viewDialogText9} textTransform={"capitalize"}>
                                    {t("Lock-In Period")}
                                </Typography>
                                <Typography className={classes.viewDialogText10} textTransform={"capitalize"}>
                                    {props?.requestState?.lock_in_period ? t("yes") : t("no")}
                                </Typography>
                            </Stack>
                        </Box >
                        <Box margin={"12px 0px"}>
                            <Typography className={classes.heading}>
                                {t("NEW AGREEMENT DETAILS")}
                            </Typography >
                        </Box >
                        <Box className={classes.boxWithOutFlex}>
                            <Box className={classes.boxWithOutBorder} marginBottom={"8px"}>
                                <Box>
                                    <AGRIcon />
                                </Box>
                                <Box flexGrow={1} marginInlineStart={"10px"} >
                                    <Box className={classes.boxFlex}>
                                        <Box>
                                            <Typography className={classes.viewDialogText4}
                                                textTransform={props?.requestState?.status?.value === enumName?.pending && "capitalize"}>
                                                {props?.requestState?.renewed_agreement_no}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.renewal_type}>
                                            {props?.requestState?.renewal_type}
                                        </Box >
                                    </Box >
                                    <Typography className={classes.semiBoldText}>
                                        &#x202a;{convertTimeUtcToZoneCalander(props?.requestState?.data?.renewal_period?.from_date)}&#x202c; - &#x202a;{convertTimeUtcToZoneCalander(props?.requestState?.data?.renewal_period?.to_date)}&#x202c;
                                    </Typography>
                                </Box >
                            </Box>
                            {(props?.requestState?.occupiedUnits?.length > 0 ||
                                props?.requestState?.status?.value !== enumName?.pending ||
                                props?.requestState?.renewal_type === "Custom")
                                ? <>
                                    {props?.requestState?.revised_auto_renewal_escalation?.value &&
                                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography className={classes.viewDialogText9} textTransform={"capitalize"}>
                                                {t("Revised Renewal Based On")}
                                            </Typography>
                                            <Typography className={classes.viewDialogText10}>
                                                {props?.requestState?.revised_auto_renewal_escalation?.label}{(props?.requestState?.revised_auto_renewal_escalation?.value === "Increment %" && props?.requestState?.revised_increment_escalation) && (" - " + props?.requestState?.revised_increment_escalation + "%")}
                                            </Typography>
                                        </Stack>}
                                    <Stack direction={"row"} justifyContent={"space-between"} marginTop={"8px"} alignItems={"center"}>
                                        <Typography className={classes.viewDialogText9} textTransform={"capitalize"}>
                                            {t("Renewal Based On")}
                                        </Typography>
                                        <Typography className={classes.viewDialogText10} textTransform={"capitalize"}>
                                            {props?.requestState?.revised_lock_in_period ? t("yes") : t("no")}
                                        </Typography>
                                    </Stack>
                                    {(((props?.requestState?.custom_increment_escalation &&
                                        props?.requestState?.custom_increment_escalation != null) ||
                                        props?.requestState?.custom_increment_escalation === 0) &&
                                        props?.requestState?.custom_increment_escalation !== props?.requestState?.revised_increment_escalation) &&
                                        <Stack direction={"row"} justifyContent={"space-between"} marginTop={"8px"} alignItems={"center"}>
                                            <Typography className={classes.viewDialogText9} textTransform={"capitalize"}>
                                                {t("Price Increment %")}
                                            </Typography>
                                            <Typography className={classes.viewDialogText10}>
                                                {props?.requestState?.custom_increment_escalation + "%"}
                                            </Typography>
                                        </Stack>}
                                </>
                                : <>
                                    <Stack direction={"column"} justifyContent={"space-between"}>
                                        <Typography className={classes.viewDialogText9} textTransform={"capitalize"}>
                                            {t("Revised Renewal Based On")}
                                        </Typography >
                                        <Stack direction={"row"} justifyContent={"space-between"} marginTop={"8px"} divider={<Box width={"16px"} />}>
                                            <Box width={props?.requestState?.revised_auto_renewal_escalation?.value === "Increment %" ? "80%" : "100%"}>
                                                <RenewalSelect
                                                    val={{
                                                        placeholder: "Select",
                                                        value: props?.requestState?.revised_auto_renewal_escalation ?? "",
                                                        options: props?.options?.auto_renewal_escalation ?? [],
                                                        onChange: (value) =>
                                                            props?.updateRequestDialogState("revised_auto_renewal_escalation", value),
                                                        isError: props?.requestState?.error?.revised_auto_renewal_escalation?.length > 0,
                                                        errorMessage: props?.requestState?.error?.revised_auto_renewal_escalation,
                                                    }}
                                                />
                                            </Box>
                                            {props?.requestState?.revised_auto_renewal_escalation?.value === "Increment %" &&
                                                <Box width={"20%"}>
                                                    <TextBox
                                                        label={""}
                                                        placeholder={"--"}
                                                        type='number'
                                                        endAdornment={"%"}
                                                        value={props?.requestState?.revised_increment_escalation}
                                                        onChange={(e) =>
                                                            props?.updateRequestDialogState("revised_increment_escalation", e.target.value)}
                                                        isError={props?.requestState?.error?.revised_increment_escalation?.length > 0}
                                                        errorMessage={props?.requestState?.error?.revised_increment_escalation} />
                                                </Box>}
                                        </Stack>
                                    </Stack>
                                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} marginTop={"16px"}>
                                        <Typography className={classes.viewDialogText9} textTransform={"capitalize"}>
                                            {t("Lock-In Period")}
                                        </Typography >
                                        <Stack direction={"row"} justifyContent={"end"} alignItems={"center"}
                                            divider={<Box width={"8px"} />}>
                                            <Typography className={classes.viewDialogText10} textTransform={"capitalize"}>
                                                {props?.requestState?.revised_lock_in_period ? t("yes") : t("no")}
                                            </Typography >
                                            <Box className={classes.addNewDialogCustomSwitchContent2}
                                                onClick={() =>
                                                    props?.updateRequestDialogState("revised_lock_in_period", !props?.requestState?.revised_lock_in_period)}>
                                                {props?.requestState?.revised_lock_in_period ?
                                                    <div className={classes.switchContainerOn}>
                                                        <div className={classes.switchToggleOn} />
                                                    </div> :
                                                    <div className={classes.switchContainerOff}>
                                                        <div className={classes.switchToggleOff} />
                                                    </div>}
                                            </Box>
                                        </Stack>
                                    </Stack>
                                </>}
                        </Box>
                        <Box margin={"12px 0px"}>
                            <Typography className={classes.heading}>
                                {t("PRIMARY CONTACT")}
                            </Typography >
                        </Box >
                        <Box display="flex" alignItems="center" className={classes.viewDialogContactCard}>
                            <Avatar src={props?.requestState?.contactImg} variant={"rounded"}
                                className={classes.contactAvatar} {...stringAvatar(props?.requestState?.contactName)} />
                            <Box flexGrow={1}>
                                <Grid container justifyContent={"start"} alignItems={"center"}
                                    direction={"row"} paddingBottom={"6px"}>
                                    <Typography className={classes.viewDialogText4}>
                                        {props?.requestState?.contactName}
                                    </Typography>
                                </Grid>
                                <Grid container justifyContent={"start"} alignItems={"center"} direction={"row"}>
                                    <Typography className={classes.viewDialogText5}>
                                        {props?.requestState?.contactNo}
                                    </Typography>
                                    <Box className={classes.period} />
                                    <Typography className={classes.viewDialogText5}>
                                        {props?.requestState?.contactEmail}
                                    </Typography>
                                </Grid >
                            </Box >
                        </Box >
                    </Box>
                </Grid >
                <Grid item xs={6} paddingInlineStart={"8px"}>
                    <Grid container className={
                        props?.requestState?.status?.value === enumName?.pending
                            ? classes.viewDialogTableContainer_1
                            : classes.viewDialogTableContainer_2}>
                        <Grid container paddingBottom={"12px"}>
                            <Typography className={classes.viewDialogText4}>
                                {t("NUMBER OF UNITS")} {"(" + props?.requestState?.units?.length + ")"}
                            </Typography >
                            <Grid className={classes.viewDialogTable}>
                                <TableWithPagination
                                    heading={UnitHeading(t)}
                                    rows={props?.requestState?.units}
                                    path={UnitPath}
                                    showpagination={false}
                                    showpdfbtn={false}
                                    showexcelbtn={false}
                                    showSearch={false}
                                    onClick={() => false}
                                    cellBackground={"transparent"}
                                    tableType="no-side"
                                    dataType={UnitType}
                                    totalRowsCount={props?.requestState?.units?.length}
                                    height={
                                        props?.requestState?.is_external ?
                                        'calc(100vh - 268px)' :
                                        props?.requestState?.status?.value === enumName.pending
                                            ? 'calc(100vh - 320px)'
                                            : 'calc(100vh - 268px)'
                                    }
                                    view={props?.permission?.read}
                                    edit={props?.permission?.update}
                                    delete={props?.permission?.delete} />
                            </Grid>
                        </Grid >
                    </Grid >
                    {(props?.permission?.update &&
                        props?.requestState?.status?.value === enumName.pending) &&
                        <>
                        {!props?.requestState?.is_external &&
                            <div className={classes.viewDialogButtonContainer}>
                            <Button fullWidth className={classes.declineButton}
                                onClick={() => {
                                    props?.setRequestState({
                                        ...props?.requestState,
                                        formType: t("Decline"),
                                        acceptDeclineRemarks: props?.requestState?.occupiedUnits?.length > 0 ?
                                            (props?.requestState?.occupiedUnits?.length > 1 ?
                                                t("Few Units Are Occupied") :
                                                (props?.requestState?.occupiedUnits?.[0]?.unit_name + " - " +
                                                    props?.requestState?.occupiedUnits?.[0]?.unit_no + " Is Occupied")) : ""
                                    })
                                    props?.setRequestDialogOpen(false)
                                    props?.setAcceptDeclineDialogOpen(true)
                                }}>
                                {t("Decline")}
                            </Button >
                            {props?.requestState?.occupiedUnits?.length === 0 &&
                                <Button fullWidth className={classes.acceptButton}
                                    onClick={props?.getUnitPrice}>
                                    {t("Accept")}
                                </Button >}
                        </div >}</>}
                </Grid >
            </Grid >
        </div >
    </Dialog >
}