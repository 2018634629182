import { ChooseCard } from "./chooseCard";
import { Steps } from "../steps";
import { ChooseCardStyle } from "./style";
import React from "react";
import { Typography, Box } from "@mui/material";
import { useState } from "react";
import { AlertContext } from "../../../contexts";

export const ContactSelect = ({ val = {}, t = () => false }) => {
  const classes = ChooseCardStyle();
  const alert = React.useContext(AlertContext);
  const [alreadyExist, setAlreadyExist] = useState({
    is_exist: {
      primaryEmail: false,
      primaryMobile: false,
    },
    error: {
      primaryEmail: "",
      primaryMobile: "",
    },
  });

  return (
    <Box>
      {val?.is_exist && (
        <Box>
          <ChooseCard
            header={t("Existing Contact")}
            title={val?.title}
            subTitle={val?.subTitle}
            noSelectedTitle={val?.noSelectedTitle}
            icon={val?.icon}
            options={val?.options}
            showRemove={val?.showRemove}
            visible={val?.visible}
            topHeader={val?.topHeader}
            onchange={val?.chooseContact}
            value={val?.value}
            onDelete={val?.onDelete}
            loadOptions={val?.loadOptions}
            error={val?.error}
            errorMsg={val?.errorMsg}
            isMulti={val?.isMulti}
            onDeleteMuti={val?.onDeleteMuti}
            onDeleteMutiCheck={val?.onDeleteMutiCheck}
            key={val?.key}
            menuPlacement={val?.menuPlacement}
            border={val?.border}
            isReadOnly={val?.isReadOnly}
            isBorder={true}
            boldHeader={true}
          />
        </Box>
      )}

      {!val?.is_exist && (
        <Box>
          <Typography className={classes.labelBold}>
            {t("New Prospect Details")}
          </Typography>
          <Box>
            <Steps
              t={t}
              component={[
                {
                  type: "upload",
                  label: false,
                  onChange: (e) => val?.handleChange("image_url", e),
                  value: val?.state?.image_url,
                  isActive: true,
                  float: "right",
                },
                {
                  type: "textBox",
                  header: (
                    <>
                      {t("Name")}
                      <Typography
                        variant="caption"
                        style={{ color: "red", marginLeft: 4 }}
                      >
                        *
                      </Typography>
                    </>
                  ),
                  handleChange: (e) => val?.handleChange("name", e),
                  value: val?.state?.name,
                  error: val?.state?.error?.name?.length > 0,
                  errMsg: val?.state?.error?.name,
                  isActive: !val?.state?.is_exist,
                  placeHolder: t("Enter Name"),
                  isReadOnly: val?.isReadOnly,
                  md: 12,
                  lg: 12,
                  sm: 12,
                },
                {
                  type: "mobileNo",
                  header: (
                    <>
                      {t("Mobile")}
                      <Typography
                        variant="caption"
                        style={{ color: "red", marginLeft: 4 }}
                      >
                        *
                      </Typography>
                    </>
                  ),
                  handleChange: (e) => val?.handleChange("mobile", e),
                  value: val?.state?.mobile,
                  placeHolder: t("Enter Mobile"),
                  error:
                    val?.state?.error?.mobile?.length > 0 ||
                    alreadyExist.error.primaryMobile,
                  errMsg:
                    alreadyExist.error.primaryMobile ||
                    val?.state?.error?.mobile,
                  isActive: !val?.state?.is_exist,
                  isReadOnly: val?.isReadOnly,
                  md: 12,
                  lg: 12,
                  sm: 12,
                },
                {
                  type: "textBox",
                  header: (
                    <>
                      {t("Email Address")}
                      <Typography
                        variant="caption"
                        style={{ color: "red", marginLeft: 4 }}
                      >
                        *
                      </Typography>
                    </>
                  ),
                  handleChange: (e) => val?.handleChange("email_id", e),
                  value: val?.state?.email_id,
                  placeHolder: t("Enter Email Address"),
                  error:
                    val?.state?.error?.email_id?.length > 0 ||
                    alreadyExist.error.primaryEmail,
                  errMsg:
                    alreadyExist.error.primaryEmail ||
                    val?.state?.error?.email_id,
                  isActive: !val?.state?.is_exist,
                  isReadOnly: val?.isReadOnly,
                  md: 12,
                  lg: 12,
                  sm: 12,
                },
              ]}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};