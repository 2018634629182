import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../../utils";

export const ContractCreateEditStyle = makeStyles((theme) => ({
    selectedContractLabel: {
        fontSize:"1rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    createBtn: {
        height: 42
    },
    dot: {
        height: "6px",
        width: 6,
        borderRadius: "50%",
        background: theme.typography.color.tertiary,
        marginInline: "8px"
    },
    viewSecondary: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.tertiary
    },
    viewHeaderText:{
        fontSize:"1rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
    }
}))
