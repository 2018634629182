import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
    Avatar,
    Box,
    InputAdornment,
    Menu,
    MenuItem,
    Stack,
    Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from 'react-router-dom';
import { MinusIcon, PlusIcon } from '../../../assets';
import { CustomMap, SearchFilter, TextBox } from "../../../components";
import { NewLoader } from "../../../components/newLoader";
import { SwitchInspectionRoutes, useWindowDimensions } from "../../../utils";
import { SearchIcon } from "../../propertyFinder4/components";
import { useStylesCreation } from "./style";
import { AuthContext } from "../../../contexts";

export const MapComponent = (props) => {
    const { t } = (props)
    const classes = useStylesCreation(props);
    const navigate = useNavigate()
    const size = useWindowDimensions();
    const [location, setLocation] = useState({});
    const [state, setState] = useState({
        value: 0,
        zoom: null
    })
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const auth = React.useContext(AuthContext)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (key, value) => {
        setAnchorEl(null);
    };

    const getLocation = () => {
        navigator.geolocation.getCurrentPosition(getCoordinates);
    };
    const getCoordinates = (position) => {
        setLocation({
            latitude: position?.coords?.latitude,
            longitude: position?.coords?.longitude,
        });
    };
    useEffect(() => {
        getLocation();
        // eslint-disable-next-line
    }, []);

    const zoomFunction = (value, bool) => {
        setState({
            zoom: bool,
            value
        })
    }


    const viewRequest = (val, onClose = () => false) => {
        onClose()
        navigate(SwitchInspectionRoutes(val.request_type), {
            state: {
                request_id: val?.requset_id,
                reference_id: val?.reference_id,
                unit_id: val?.unit_id,
                agreement_inspection_id: val?.id,
                status: val?.status,
                request_type: val?.request_type,
                agreement_id: val?.agreement_id
            }
        })
    }
    return (
        <Box>
            {
                !props?.mapLoading ?
                    <div className={classes.card}>
                        {
                            (location?.latitude && location?.longitude) ?
                                <>
                                    <div
                                        className={classes.map}
                                        style={{ height: (props?.isZoom || props?.is_close) ? null : size?.height - 200 }}
                                    >
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            MenuListProps={{
                                                "aria-labelledby": "basic-button",
                                            }}
                                            PaperProps={{
                                                style: {
                                                    width: 250,
                                                },
                                            }}
                                            style={{ marginTop: "4px" }}
                                        >
                                            <Box style={{ padding: "8px 16px 8px 16px" }}><SearchFilter value={props?.searchResourceTxt} handleChange={(value) => props?.handleSearchResource(value)} placeholder="Search Resource" /></Box>
                                            <InfiniteScroll
                                                dataLength={props?.inspectorData?.length ?? ""}
                                                next={props?.fetchMoreData}
                                                hasMore={true}
                                                height={size?.height - 450}
                                            >
                                                {props?.inspectorData.map((x, index) => {
                                                    return (

                                                        <MenuItem onClick={() => props?.handleResource(x, setAnchorEl)}>

                                                            <Stack direction={"row"} spacing={1} className={props?.selectedInspector?.id === x.id ? classes.selectedResource : classes.resource} alignItems={"center"} >
                                                                {x.isIcon ? <Avatar style={{ backgroundColor: "white" }}>{x.icon}</Avatar> : <Avatar src={x.image_url} />}
                                                                <Box>
                                                                    <Typography className={classes.resourceName} noWrap>{x.first_name}</Typography>
                                                                    <Typography className={classes.resourceSubName} noWrap>{x.job_name}</Typography>

                                                                </Box>
                                                            </Stack>


                                                        </MenuItem>
                                                    );
                                                })}
                                            </InfiniteScroll>

                                        </Menu>
                                        {/* map */}


                                        <CustomMap
                                            map_id="dashboad_map"
                                            lat={
                                                props?.data?.location?.latitude ??
                                                Number(
                                                    props?.mapData?.[0]?.address?.latitude ?? props?.data?.company_address?.[0]?.latitude
                                                )
                                            }
                                            lang={
                                                props?.data?.location?.longitude ??
                                                Number(
                                                    props?.mapData?.[0]?.address?.longitude ?? props?.data?.company_address?.[0]?.longitude
                                                )
                                            }
                                            radius={0}
                                            list={props?.mapData ?? []}
                                            zoom={10}
                                            hideControl={true}
                                            viewRequest={viewRequest}
                                            // status={
                                            //     props?.tabNameState === "Occupied"
                                            //         ? "Occupied Units"
                                            //         : "Vacant Units"
                                            // }
                                            // isDashBoard
                                            // mapIcon={
                                            //     props?.tabNameState === "Occupied"
                                            //         ? "images/occupiedmap.svg"
                                            //         : "images/vacantmap.svg"
                                            // }
                                            state={state}
                                            isInspection={true}
                                            is_zoom={true}
                                        />

                                        {/*  */}

                                        <span className={classes.zoomInOut}>
                                            <div className={classes.searchBox}>
                                                {/* search field */}
                                                <Box m={2} display="flex" justifyContent={"center"} className={classes.cutomselectdrp}>
                                                    <Box className={classes.resourceBox} onClick={handleClick}>
                                                        <Stack direction="row" alignItems="center" spacing={1} >
                                                            {props?.selectedInspector?.isIcon ? <Avatar className={classes.resourceImg} style={{ backgroundColor: "white" }}>{props?.selectedInspector.icon}</Avatar>
                                                                :
                                                                <Avatar className={classes.resourceImg} src={props?.selectedInspector?.image_url} />
                                                            }
                                                            <Typography className={classes.sourceName} no noWrap>{props?.selectedInspector?.first_name}</Typography>
                                                        </Stack>
                                                        {open ? <KeyboardArrowUp style={{ color: "#98A0AC" }} /> : <KeyboardArrowDown style={{ color: "#98A0AC" }} />}
                                                    </Box>
                                                    <Box style={{ width: "350px" }}>
                                                        <TextBox label={null}
                                                            placeholder={t("Search By Request Name")}
                                                            border={0}
                                                            startAdornment={<InputAdornment position="start">{<SearchIcon />}</InputAdornment>}
                                                            color="white"
                                                            value={props?.mapSearchData}
                                                            onChange={(e) => props?.handleMapSearchData(e.target.value)}
                                                        />
                                                    </Box>
                                                </Box>

                                            </div>
                                            <Box mt={-9}>
                                            <div className= {auth?.auth?.auth?.language === 'ar' ? "zoom-control-rtl" : "zoom-control" }>
                                                    <div className="plus" onClick={() => {
                                                        zoomFunction(state + 5, true)
                                                    }}>
                                                        <PlusIcon />
                                                    </div>
                                                    <div className="minus" onClick={() => {
                                                        zoomFunction(state - 5, false)
                                                    }}>
                                                        <MinusIcon />
                                                    </div>
                                                </div>
                                            </Box>
                                        </span>
                                    </div></>
                                :
                                <Box className={classes.mapRoot}>
                                    <Typography className={classes.titleFont}>{t("Please Check Your Location")}</Typography>
                                </Box>
                        }


                    </div >
                    : <NewLoader minusHeight="100px" />}
        </Box>
    );
};

