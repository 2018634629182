/* eslint-disable array-callback-return */
import {
    Grid
} from '@mui/material';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Subheader } from '../../components';
import { config } from '../../config';
import { AlertContext, AuthContext } from "../../contexts";
import { NetworkCall } from '../../networkcall';
import { accessCheckRender, AlertProps, getRoutePermissionNew, NetWorkCallMethods } from '../../utils';
import useWindowDimensions from "../../utils/useWindowDimensions";
import { OtherInfoCard } from './otherInfo';
import { withTranslation } from 'react-i18next';
const getAllids = (data) => {
    let list = [];
    if (data?.length > 0) {
        data?.map(id => {
            list = [...list, id?.unitId]
        })
    }
    return list;
}

const OwnerTableView = (props) => {
    const { loading, handleLoading = () => false, t } = props;
    const navigate = useNavigate()
    const size = useWindowDimensions();
    const alert = React.useContext(AlertContext);
    const search = useLocation().search;
    const auth = React.useContext(AuthContext)
    const [ownerDetailsData, setOwnerDetailsData] = React.useState([])
    const userId = props?.userId ? props?.userId : new URLSearchParams(search).get("userId");
    const companyId = props?.companyId ? props?.companyId : new URLSearchParams(search).get("companyId");
    const [permission, setPermission] = React.useState({})
    // const [loader, setLoader] = React.useState(true)

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getOwnerDetailsId()
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const getOwnerDetailsId = () => {
        // setLoader(true)
        const payload = {
            tenantId: `${config.tenantId}`,
            companyId: Number(companyId),
            userId: userId
        };
        NetworkCall(
            `${config.api_url}/owner-resident/owner`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setOwnerDetailsData(response?.data?.data);
                // setLoader(false)
                handleLoading(false);
            })
            .catch((error) => {
                console.log(error)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something went wrong please try again"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                });
            });

    };

    const allUnitId = getAllids(ownerDetailsData);

    const render = () => {
        return <>
            {props?.hideSubheader && <Subheader title={ownerDetailsData?.[0]?.firstName} goBack={() => navigate()} />}
            {
                // loader ? (
                //     <NewLoader minusHeight="100px" />
                // ) : (
                <div style={{ height: size?.height - 200, overflow: "auto" }} >
                    <Grid container spacing={2}>
                        {/* <Grid item xs={12} >
                                <OwnerDetail data={ownerDetailsData?.[0]} />
                            </Grid> */}

                        <Grid item xs={12}>
                            <OtherInfoCard t={t} allUnitId={allUnitId} />
                        </Grid>
                    </Grid>
                </div>
                // )
            }
        </>
    }

    return <>
        {accessCheckRender(render, permission, "", loading)}
    </>

}
export default withTranslation("ownerTableView")(OwnerTableView)
