import { Box, Grid, Typography } from "@mui/material";
import { useStyles } from "../styles";
import { withTranslation } from "react-i18next";
const CompanyDetails = ({ data = {}, t }) => {
    const classes = useStyles()
    return (
        <Box className={classes.box} marginTop="16px">
            <Typography className={classes.title} marginBottom={"14px"}>{t("Company & Real Estate Registration")}</Typography>
            <Grid container justifyContent="space-between">
                <Grid item xs={2}>
                    <Box marginTop="16px">
                        <Typography className={classes.heading}>{t("Company Registration Type")}</Typography>
                        <Typography className={classes.sub}>{data?.company_registration}</Typography>
                    </Box>


                </Grid>
                <Grid item xs={2}>
                    <Box marginTop="16px">
                        <Typography className={classes.heading}>{t("Company Registration Number")}</Typography>
                        <Typography className={classes.sub}>{data?.company_registration_no}</Typography>
                    </Box>

                </Grid>

                <Grid item xs={2}>
                    <Box marginTop="16px">
                        <Typography className={classes.heading}>{t("Real Estate Regulatory Authority")}</Typography>
                        <Typography className={classes.sub}>{data?.rera_type}</Typography>
                    </Box>


                </Grid>
                <Grid item xs={2}>
                    <Box marginTop="16px">
                        <Typography className={classes.heading}>{t("RERA Registration Number")}</Typography>
                        <Typography className={classes.sub}>{data?.rera_registration_no}</Typography>
                    </Box>

                </Grid>
                <Grid item xs={12}>
                    <Box marginTop="16px">
                        <Typography className={classes.heading}>{t("RERA Broker Registration Number")}</Typography>
                        <Typography className={classes.sub}>{data?.rera_broker_registration_no}</Typography>
                    </Box>
                </Grid>

            </Grid>
        </Box>
    )
}
export default withTranslation("accountDetails")(CompanyDetails)
