import {
  Badge,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import styled from "@mui/material/styles/styled";
import React from "react";
import FilterIMG from "../../../assets/filter";
import {
  AlertDialog,
  FilterGenerator,
  SearchFilter,
  Subheader,
  TableWithPagination,
  TextBox,
  ToggleButtonComponent,
  UseDebounce,
} from "../../../components";
import { SelectBox } from "../../../components/select";
import { config } from "../../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import {
  accessCheckRender,
  AlertProps,
  enum_types,
  enumSelect,
  getCompanyOption,
  getRoutePermissionNew,
  NetWorkCallMethods,
  useWindowDimensions,
} from "../../../utils";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";
import {
  LocalStorageKeys,
  ParkingSlotMasterHeading,
  ParkingSlotMasterPath,
} from "../../../utils/securityManager";
import { ParkingSlotMasterStyles } from "./style";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.palette.borderRadius,
  height: "40px",
}));

const CustomPaper = styled("div")(({ theme }) => ({
  boxShadow: "0px 0px 16px #00000014",
  borderRadius: theme.palette.borderRadius,
  backgroundColor: "#ffffff",
  padding: theme.spacing(2),
  margin: theme.spacing(2),
}));

const initialState = () => {
  return {
    id: null,
    parkingType: "Reserved",
    parkingNumber: "",
    property: "",
    unit: "",
    location: "",
    description: "",
    groupingMaster: "",
    ratePerHour: null,
    edit: false,
    ratePerDay: null,
    error: {
      parkingType: "",
      parkingNumber: "",
      property: "",
      groupingMaster: "",
      unit: "",
      location: "",
      description: "",
      ratePerHour: "",
      ratePerDay: "",
    },
  };
};

const ParkingSlotMaster = ({ t }) => {
  const defaultFilterState = { status: [true] };
  const classes = ParkingSlotMasterStyles();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogView, setOpenDialogView] = React.useState(false);
  const size = useWindowDimensions();
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const auth = React.useContext(AuthContext);
  const [parkingSlotMasterData, setParkingSlotMasterData] = React.useState({
    ...initialState(),
  });
  const [selectedCompany, setSelectedCompany] = React.useState({});
  const [companyList, setCompanyList] = React.useState([]);
  const [drawer, setDrawer] = React.useState(null);
  const [filterData, setFilterData] = React.useState(defaultFilterState);
  const [parkingSlotMasterList, setParkingSlotMasterList] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const [details, setviewDeatils] = React.useState({});
  const [enumValue, setEnumValue] = React.useState({
    parking_type: [],
  });
  const debounce = UseDebounce();
  const [permission, setPermission] = React.useState({});
  const [isDisableBtn, setIsDisableBtn] = React.useState(false);
  const [viewInfo, setViewInfo] = React.useState({});
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");
  const limitParam = searchParams.get("limit");

  // Default values if not set in URL
  const pageFromParams = pageParam ? parseInt(pageParam, 10) : 1;
  const limitFromParams = limitParam ? parseInt(limitParam, 10) : 10;

  const [page, setPage] = React.useState(pageFromParams);
  const [limit, setLimit] = React.useState(limitFromParams);

  const updatePage = (newPage, newLimit = limit) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", newPage.toString());
    searchParams.set("limit", newLimit.toString());

    // Update URL without reloading
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
    // Update state
    setPage(newPage);
    setLimit(newLimit);
  };

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        getEnum();
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          setCompanyList(company?.list);
          setSelectedCompany(company?.selected);
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);
  //more options
  const handlePagination = (newPage) => {
    updatePage(newPage, limit);
  };

  const handleChangeLimit = (newLimit) => {
    updatePage(1, newLimit); // Reset page to 1 when limit changes
  };

  const handleSearch = (e) => {
    setSearchText(e);
    debounce(() => searchTableFunction(e), 800);
  };
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    parkingSlotMasterTableData(0, limit, e);
  };

  const draweropen = () => {
    setDrawer(true);
  };
  const drawerclose = () => {
    setDrawer(false);
  };

  //update state
  const updateState = (key, value) => {
    if (key === "property") {
      let error = parkingSlotMasterData.error;
      error[key] = "";
      setParkingSlotMasterData({
        ...parkingSlotMasterData,
        [key]: value,
        error,
        unit: "",
        groupingMaster: "",
      });
    } else {
      let error = parkingSlotMasterData?.error;
      error[key] = "";
      setParkingSlotMasterData({ ...parkingSlotMasterData, [key]: value });
    }
  };

  const validate = () => {
    let isValid = true;
    let error = parkingSlotMasterData.error;
    if (parkingSlotMasterData?.parkingType?.length === 0) {
      isValid = false;
      error.parkingType = t("Parking Type  is Required");
    }
    if (parkingSlotMasterData?.parkingNumber?.length === 0) {
      isValid = false;
      error.parkingNumber = t("Parking Number is Required");
    }
    if (parkingSlotMasterData?.property?.length === 0) {
      isValid = false;
      error.property = t("Property is Required");
    }
    if (parkingSlotMasterData?.groupingMaster?.length === 0) {
      isValid = false;
      error.groupingMaster = t("Parking Grouping Master is Required");
    }

    // if (parkingSlotMasterData?.unit?.length === 0) {
    //     isValid = false;
    //     error.unit = "Unit is Required";
    // }
    // if (parkingSlotMasterData?.location?.length === 0) {
    //     isValid = false;
    //     error.location = " location is Required";
    // }
    // if (parkingSlotMasterData?.description?.length === 0) {
    //     isValid = false;
    //     error.description = "description is Required";
    // }

    if (parkingSlotMasterData?.parkingType === "Visitors") {
      if (parkingSlotMasterData?.ratePerHour?.length === 0) {
        isValid = false;
        error.ratePerHour = t("Rate Per Hour is Required");
      }
    }

    if (
      parkingSlotMasterData?.parkingType?.length === 0 ||
      parkingSlotMasterData?.parkingNumber?.length === 0 ||
      parkingSlotMasterData?.property?.length === 0 ||
      (parkingSlotMasterData?.parkingType === "Visitors" &&
        parkingSlotMasterData?.ratePerHour?.length === 0)
    ) {
      isValid = false;
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Please fill all mandatory field"),
      });
    }
    setParkingSlotMasterData({ ...parkingSlotMasterData, error });

    return isValid;
  };
  const userProfileId = localStorage.getItem(LocalStorageKeys.userProfileID);

  //Upsert API FUNCTION
  const UpsertParkingSlotMaster = () => {
    if (validate()) {
      setIsDisableBtn(true);
      let payload;
      if (parkingSlotMasterData?.id) {
        payload = {
          tenantId: `${config.tenantId}`,
          parking_type: parkingSlotMasterData?.parkingType,
          parking_no: parkingSlotMasterData?.parkingNumber,
          property_id: parkingSlotMasterData?.property?.value,
          unit_id: parkingSlotMasterData?.unit?.value ?? null,
          location: parkingSlotMasterData?.location ?? null,
          description: parkingSlotMasterData?.description ?? null,
          per_day_rate:
            parkingSlotMasterData?.parkingType === "Reserved"
              ? null
              : Number(parkingSlotMasterData?.ratePerHour) * 24 ?? null,
          rate:
            parkingSlotMasterData?.parkingType === "Reserved"
              ? null
              : Number(parkingSlotMasterData?.ratePerHour) ?? null,
          company_id: selectedCompany?.value,
          currency_id: selectedCompany?.currency_id,
          created_by: userProfileId,
          id: parkingSlotMasterData?.id,
          grouping_master_id: parkingSlotMasterData?.groupingMaster?.value,
        };
      } else {
        payload = {
          tenantId: `${config.tenantId}`,
          parking_type: parkingSlotMasterData?.parkingType,
          parking_no: parkingSlotMasterData?.parkingNumber,
          property_id: parkingSlotMasterData?.property?.value,
          unit_id: parkingSlotMasterData?.unit?.value ?? null,
          location: parkingSlotMasterData?.location ?? null,
          description: parkingSlotMasterData?.description ?? null,
          per_day_rate: parkingSlotMasterData?.ratePerHour * 24 ?? null,
          rate: parkingSlotMasterData?.ratePerHour ?? null,
          company_id: selectedCompany?.value,
          currency_id: selectedCompany?.currency_id,
          created_by: userProfileId,
          grouping_master_id: parkingSlotMasterData?.groupingMaster?.value,
        };
      }
      NetworkCall(
        `${config.api_url}/parking/slot/upsert`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `${t("Parking Slot Master")} ${
              parkingSlotMasterData?.id ? "Updated" : "Created"
            } successfully.`,
          });
          setParkingSlotMasterData({ ...initialState() });
          setOpenDialog(false);
          parkingSlotMasterTableData(
            currentOffset,
            limitFromParams,
            searchText
          );
          setIsDisableBtn(false);
        })
        .catch((error) => {
          setIsDisableBtn(false);
          console.log(
            error?.errors?.errors?.map((x) => {
              return x?.msg;
            })
          );
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Internal error. Please try again later."),
          });
        });
    }
  };

  //table listing function
  const parkingSlotMasterTableData = async (
    offset = 0,
    limit = 10,
    search = ""
  ) => {
    let companyID = [selectedCompany?.value];
    let propertyGroudID = filterData.property_type?.length
      ? filterData?.property_type?.map(({ value }) => value)
      : [];
    let is_active = filterData?.status ?? [true];
    const payload = {
      company_id: companyID,
      offset: offset,
      limit: limit,
      search: search,
      status: is_active,
      property_group_id: propertyGroudID,
    };
    NetworkCall(
      `${config.api_url}/queries/parking_master/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setParkingSlotMasterList({
          data: response?.data?.data?.parking_master,
          totalRowsCount: response?.data?.data?.count,
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((error) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
      });
  };

  // is active changing function
  const changeactive = async (data, value) => {
    const payload = {
      id: data,
      payload: {
        is_active: value === "Inactive" ? true : false,
      },
    };
    NetworkCall(
      `${config.api_url}/queries/parking_master/update`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((rs) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Status Updated"),
        });
        parkingSlotMasterTableData();
      })
      .catch((er) => {
        console.log(er);
      });
  };
  // is delte changing function
  const changeDelete = async (data) => {
    const payload = {
      id: data,
      payload: {
        is_delete: true,
      },
    };
    NetworkCall(
      `${config.api_url}/queries/parking_master/update`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((rs) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Deleted Successfully"),
        });
        parkingSlotMasterTableData();
      })
      .catch((er) => {
        console.log(er);
      });
  };
  //table row constructions
  const parkingSlotMasterRow = parkingSlotMasterList?.data?.map(
    (val, index) => {
      let _d;
      try {
        _d = {
          index: (page - 1) * limit + index + 1,
          parkingSlot: `PS20-${index + 1}`,
          parkingUnitId: val?.unit?.unit_no ?? " - ",
          parkingNumber: val?.parking_no ?? " - ",
          location: val?.location ?? " - ",
          rate: val?.rate ?? " - ",
          perHourRate: val?.rate
            ? `${val?.rate} ${val?.currencyByID?.symbol}`
            : " - ",
          description: val?.description ?? " - ",
          status: val?.is_active ? "Active" : "Inactive",
          icon: "more",
          id: val?.id,
          property: val?.property,
          propertyName: val?.property?.label,
          unit: val?.unit,
          groupingMaster: val?.parking_grouping_master,
          groupMasterName: val?.parking_grouping_master?.[0]?.label,
          parkingType: val?.parking_type,
          ratePerDay: val?.per_day_rate,
        };
      } catch (err) {}
      return _d;
    }
  );
  //get Enum
  const getEnum = async () => {
    const result = await enumSelect([enum_types.parking_type]);
    setEnumValue({
      parking_type: result?.parking_type,
    });
  };

  const currentOffset = (page - 1) * limit;
  React.useEffect(() => {
    if (selectedCompany?.value) {
      parkingSlotMasterTableData(currentOffset, limitFromParams, searchText);
    }
    //eslint-disable-next-line
  }, [selectedCompany, filterData]);

  const handleIcon = (type, data, status) => {
    if (type === "view") {
      setOpenDialogView(true);
      setviewDeatils(data);
    }
    if (type === "edit") {
      setOpenDialog(true);
      setParkingSlotMasterData({
        ...parkingSlotMasterData,
        parkingType: data?.parkingType,
        parkingNumber: data?.parkingNumber,
        property: data?.property,
        unit: data?.unit,
        groupingMaster: data?.groupingMaster,
        location: data?.location,
        description: data?.description,
        ratePerHour: data?.rate ?? null,
        ratePerDay: data?.ratePerDay ?? null,
        edit: true,
        id: data?.id,
      });
    } else if (type === "active") {
      changeactive(data.id, status);
    } else if (type === "delete") {
      changeDelete(data.id);
    }
  };
  const [loading, setLoading] = React.useState(null);
  // drop down option
  const loadOptionsDropDown = async (search = "", array, type) => {
    setLoading(type);
    let result,
      payload,
      offset = 0;

    if (search && !Boolean(array?.length)) {
      offset = 0;
    } else {
      offset = array?.length;
    }

    switch (type) {
      case "properties":
        if (!selectedCompany?.value > 0) {
          setLoading(null);
          return { options: [] };
        }
        payload = {
          offset: offset,
          limit: 10,
          search: search,
          company_ids: [selectedCompany?.value],
        };
        result = await networkCallBack(
          payload,
          `${config.api_url}/queries/property/list`
        );
        return {
          options: [...result?.property],
          hasMore: array?.length + result?.property?.length < result?.count,
        };
      case "parking_grouping_master":
        if (!parkingSlotMasterData?.property?.value > 0) {
          setLoading(null);
          return { options: [] };
        }
        payload = {
          search: search,
          offset: offset,
          limit: limit,
          property_id: parkingSlotMasterData?.property?.value,
          is_active: [true],
        };
        result = await networkCallBack(
          payload,
          `${config.api_url}/queries/parking_grouping_master/list`
        );
        return {
          options: [...result?.parking_grouping_master],
          hasMore:
            array?.length + result?.parking_grouping_master?.length <
            result?.count,
        };

      case "units":
        if (!parkingSlotMasterData?.property?.value > 0) {
          setLoading(null);
          return { options: [] };
        }
        payload = {
          tenantId: config.tenantId,
          property_id: parkingSlotMasterData?.property?.value,
        };
        result = await networkCallBack(
          payload,
          `${config.api_url}/parking/slot/getUnits`
        );
        return {
          options: result?.units?.length
            ? result?.units?.map((item) => {
                return {
                  label: item?.name,
                  value: item?.id,
                };
              })
            : [],
          hasMore: array?.length + result?.units?.length < result?.count,
        };
      default:
        return { options: [] };
    }
  };
  const networkCallBack = async (payload, url) => {
    const options = await NetworkCall(
      `${url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let main = response.data.data;
        setLoading(null);
        return main;
      })
      .catch((error) => {
        setLoading(null);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        return null;
      });

    return options;
  };

  //apply filter
  const onApplyFilter = (value) => {
    setFilterData(value);
  };
  // add and edit modal
  const addAndEdit = () => {
    return (
      <>
        <div
          style={{
            height: size.height - 250,
            overflow: "scroll",
            padding: "24px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                gutterBottom
                style={{
                  color: "#98A0AC",
                  fontSize: "0.75rem",
                  marginBottom: "4px",
                }}
              >
                {t("Parking Type")}
              </Typography>
              <ToggleButtonComponent
                options={enumValue?.parking_type?.map((x) => {
                  return {
                    ...x,
                    label: t(x?.label) ?? x?.label,
                  };
                })}
                value={parkingSlotMasterData?.parkingType}
                onChange={(value) => updateState("parkingType", value)}
                isMulti={false}
                fullWidth={false}
                isError={parkingSlotMasterData?.error?.parkingType?.length > 0}
                errorMessage={parkingSlotMasterData?.error?.parkingType}
              />
            </Grid>
            <Grid item xs={6}>
              <TextBox
                value={parkingSlotMasterData?.parkingNumber}
                isrequired
                label={t("Parking Number")}
                placeholder={t("Enter Parking Number")}
                onChange={(e) => {
                  updateState("parkingNumber", e.target.value);
                }}
                isError={
                  parkingSlotMasterData?.error?.parkingNumber?.length > 0
                }
                errorMessage={parkingSlotMasterData?.error?.parkingNumber}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectBox
                value={parkingSlotMasterData?.property}
                isRequired
                label={t("Property")}
                placeholder={t("Select Property")}
                onChange={(value) => {
                  updateState("property", value);
                }}
                isError={parkingSlotMasterData?.error?.property?.length > 0}
                errorMessage={parkingSlotMasterData?.error?.property}
                loading={loading === "properties"}
                isPaginate
                debounceTimeout={800}
                loadOptions={(search, array) =>
                  loadOptionsDropDown(search, array, "properties")
                }
              />
            </Grid>
            <Grid item xs={12}>
              <SelectBox
                value={parkingSlotMasterData?.unit}
                //isRequired
                label={t("Unit")}
                placeholder={t("Select Unit")}
                onChange={(value) => {
                  updateState("unit", value);
                }}
                //isError={parkingSlotMasterData?.error?.unit?.length > 0}
                //errorMessage={parkingSlotMasterData?.error?.unit}
                key={parkingSlotMasterData?.property?.value}
                loading={loading === "units"}
                isPaginate
                debounceTimeout={800}
                loadOptions={(search, array) =>
                  loadOptionsDropDown(search, array, "units")
                }
              />
            </Grid>
            <Grid item xs={12}>
              <SelectBox
                value={parkingSlotMasterData?.groupingMaster}
                isRequired
                label={t("Parking Grouping Master")}
                placeholder={t("Select Parking Grouping Master")}
                onChange={(value) => {
                  updateState("groupingMaster", value);
                }}
                isError={
                  parkingSlotMasterData?.error?.groupingMaster?.length > 0
                }
                errorMessage={parkingSlotMasterData?.error?.groupingMaster}
                loading={loading === "parking_grouping_master"}
                isPaginate
                key={parkingSlotMasterData?.property?.value}
                debounceTimeout={800}
                loadOptions={(search, array) =>
                  loadOptionsDropDown(search, array, "parking_grouping_master")
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextBox
                value={parkingSlotMasterData?.location ?? ""}
                label={t("Location")}
                placeholder={t("Enter Location")}
                onChange={(e) => {
                  updateState("location", e.target.value);
                }}
                isError={parkingSlotMasterData?.error?.location?.length > 0}
                errorMessage={parkingSlotMasterData?.error?.location}
              />
            </Grid>
            {/* Visitors */}
            {parkingSlotMasterData?.parkingType === "Visitors" && (
              <>
                <Grid item xs={9}>
                  <Box
                    display="flex"
                    alignItems={"center"}
                    style={{
                      border: "2px solid #E4E8EE",
                      borderRadius: "4px",
                      height: 45,
                    }}
                  >
                    <TextField
                      placeholder={t("Enter rate per hour")}
                      variant="standard"
                      value={parkingSlotMasterData?.ratePerHour ?? ""}
                      onChange={(e) => {
                        updateState("ratePerHour", e.target.value);
                      }}
                      type="number"
                      className={classes.TextField}
                      InputProps={{
                        endAdornment: <span>{"/Hr"}</span>,
                        disableUnderline: true,
                      }}
                      style={{
                        borderRight: "1px solid #E4E8EE",
                        paddingRight: "4px",
                      }}
                    />
                    <FormControl
                      variant="standard"
                      sx={{ minWidth: 30, border: "none" }}
                    >
                      <Select disableUnderline value={1} disabled>
                        <MenuItem value={1}>
                          <em>{selectedCompany?.currency_symbol}</em>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.hour}>
                    {parkingSlotMasterData?.ratePerHour * 24}{" "}
                    <span style={{ color: "#98A0AC" }}> / Day </span>
                  </Typography>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <TextBox
                multiline
                value={parkingSlotMasterData?.description ?? ""}
                label={t("Description")}
                placeholder={t("Enter Description")}
                onChange={(e) => {
                  updateState("description", e.target.value);
                }}
                isError={parkingSlotMasterData?.error?.description?.length > 0}
                errorMessage={parkingSlotMasterData?.error?.description}
              />
            </Grid>
          </Grid>
        </div>
        <Grid container padding={"24px"}>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              className={classes.btn}
              onClick={UpsertParkingSlotMaster}
              disabled={isDisableBtn}
            >
              {parkingSlotMasterData?.edit ? t("Update") : t("Create")}
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };

  //details for view api call
  const detailsBox = (id) => {
    let parking_id = id;
    const payload = {
      parking_id: parking_id,
    };
    NetworkCall(
      `${config.api_url}/queries/get_parking_slot_info`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setViewInfo(response?.data?.data?.parking_slot[0]);
        view(response?.data?.data?.parking_slot[0]);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((error) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
        });
      });
  };
  React.useEffect(() => {
    if (details?.id) {
      detailsBox(details?.id);
    }
    //eslint-disable-next-line
  }, [details?.id]);
  const manualResponse = (array) => {
    return array.property?.map((x) => {
      return {
        value: x?.id,
        label: x?.propertyName,
      };
    });
  };
  //view modal
  const view = (value) => {
    return (
      <>
        <div className={classes.card}>
          <Grid container>
            <Grid item xs={3}>
              <Box className={classes.recBox}>
                <Typography className={classes.textSmall}>
                  {t("PARKING SLOT")}
                </Typography>
                <Typography
                  className={classes.textSmall}
                  fontSize={"18px !important"}
                >
                  {details?.parkingSlot ?? "-"}
                </Typography>
                <Typography className={classes.textSmall}>
                  {details?.location ?? "-"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Typography className={classes.text1}>
                {details?.parkingType ?? "-"}
                {details?.parkingType === "Visitors" && (
                  <span className={classes.span}>
                    {`${selectedCompany?.currency_symbol} ${details?.perHourRate} / Day (${selectedCompany?.currency_symbol} ${details?.ratePerDay} / Hr)`}
                  </span>
                )}
              </Typography>
              <Typography className={classes.text2} display="flex">
                {details?.parkingNumber}
                <Box className={classes.periodStyle} />
                {details?.parkingUnitId}
              </Typography>

              <Typography className={classes.text3} fontSize="12px !important">
                {details?.description ?? "-"}
              </Typography>
            </Grid>
          </Grid>
        </div>
        {details?.parkingType === "Reserved" && (
          <Grid
            container
            border="1px solid #E4E8EE"
            borderRadius={"8px"}
            marginTop={2}
          >
            <Grid
              item
              xs={12}
              justifyContent={"space-between"}
              display={"flex"}
              borderBottom="1px solid #E4E8EE"
            >
              <Typography className={classes.textLeft}>
                {t("Agreement Id")}
              </Typography>
              <Typography
                className={classes.textRight}
                textAlign="left"
                sx={{ marginInlineEnd: "80px" }}
              >
                {value?.agreementById?.agreement_no ?? " - "}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent={"space-between"}
              display={"flex"}
              borderBottom="1px solid #E4E8EE"
            >
              <Typography className={classes.textLeft}>
                {t("Unit Id")}
              </Typography>
              <Typography
                className={classes.textRight}
                textAlign="left"
                sx={{ marginInlineEnd: "80px" }}
              >
                {value?.unit?.unit_no ?? " - "}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent={"space-between"}
              display={"flex"}
              borderBottom="1px solid #E4E8EE"
            >
              <Typography className={classes.textLeft}>
                {t("Allocated")}
              </Typography>
              <Typography
                className={classes.textRight}
                textAlign="left"
                sx={{ marginInlineEnd: "80px" }}
              >
                {value?.allocated ?? " - "}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent={"space-between"}
              display={"flex"}
              borderBottom="1px solid #E4E8EE"
            >
              <Typography className={classes.textLeft}>
                {t("Number Plate")}
              </Typography>
              <Typography
                className={classes.textRight}
                textAlign="left"
                sx={{ marginInlineEnd: "80px" }}
              >
                {value?.number_plate ?? " - "}
              </Typography>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  const render = () => {
    return (
      <>
        <div>
          <Subheader
            title={t("Parking Slot Master")}
            hideBackButton
            select
            options={companyList}
            value={selectedCompany}
            onchange={(e) => setSelectedCompany(e)}
          />
          <CustomPaper>
            <Grid container className={classes.content} spacing={2}>
              <Grid item xs={4}>
                <SearchFilter
                  placeholder={t("Search by parking number")}
                  value={searchText}
                  handleChange={(value) => handleSearch(value)}
                  customfieldSx={{
                    "& .MuiOutlinedInput-root": { height: "40px" },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={8}
                textAlign={"right"}
                display={"flex"}
                justifyContent={"end"}
              >
                <Box display={"flex"} sx={{ float: "right" }}>
                  <Box marginInlineEnd={"12px"}>
                    {filterData.status?.length > 0 ||
                    filterData.property_type?.length > 0 ? (
                      <IconButton onClick={draweropen} className={classes.img}>
                        <Badge variant="dot" color="primary">
                          <FilterIMG color="#091b29" />
                        </Badge>
                      </IconButton>
                    ) : (
                      <IconButton onClick={draweropen} className={classes.img}>
                        <FilterIMG color="#091b29" />
                      </IconButton>
                    )}
                  </Box>

                  {permission?.create && (
                    <Box>
                      <CustomButton
                        color="primary"
                        variant="contained"
                        onClick={() => setOpenDialog(true)}
                      >
                        {t("Add new")}
                      </CustomButton>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <TableWithPagination
                  heading={ParkingSlotMasterHeading(t)}
                  rows={
                    parkingSlotMasterRow?.length ? parkingSlotMasterRow : []
                  }
                  path={ParkingSlotMasterPath}
                  showpagination={true}
                  tableType="no-side"
                  handleIcon={handleIcon}
                  onClick={() => null}
                  showpdfbtn={false}
                  showexcelbtn={false}
                  showSearch={false}
                  dataType={[
                    { type: ["text"], name: "parkingSlot" },
                    { type: ["text"], name: "parkingUnitId" },
                    { type: ["text"], name: "parkingNumber" },
                    { type: ["text"], name: "location" },
                    { type: ["text"], name: "perHourRate" },
                    { type: ["text"], name: "propertyName" },
                    { type: ["text"], name: "groupMasterName" },
                    { type: ["status"], name: "status" },
                    { type: ["more"], icon: "icon" },
                  ]}
                  handlePagination={handlePagination}
                  handleChangeLimit={handleChangeLimit}
                  totalRowsCount={parkingSlotMasterList?.totalRowsCount}
                  page={page}
                  limit={limit}
                  height={"calc(100vh - 290px)"}
                  view={permission?.read}
                  edit={permission?.update}
                  delete={permission?.delete}
                />
              </Grid>
            </Grid>
          </CustomPaper>
        </div>
        {/* add and edit */}
        <AlertDialog
          isNormal
          header={
            parkingSlotMasterData?.edit
              ? t("Edit Parking Slot Master")
              : t("Add Parking Slot Master")
          }
          onClose={() => {
            setOpenDialog(false);
            setParkingSlotMasterData({ ...initialState() });
          }}
          open={openDialog}
          component={
            <>
              <div>{addAndEdit()}</div>
            </>
          }
        />
        {/* view */}
        <AlertDialog
          isNormal
          header={t("View Parking Slot Master")}
          onClose={() => setOpenDialogView(false)}
          open={openDialogView}
          component={
            <>
              <div style={{ padding: "24px" }}>{view(viewInfo)}</div>
            </>
          }
        />
        {/* filter drawer */}
        {drawer && (
          <FilterGenerator
            open={drawer}
            onClose={drawerclose}
            defaultState={defaultFilterState}
            components={[
              {
                component: "select",
                value: filterData?.property_type,
                options: [],
                isMulti: true,
                label: t("Property"),
                state_name: "property_type",
                placeholder: t("Select Property"),
                loadOptions: (search, array, handleLoading) =>
                  loadOptionsApis(
                    `/queries/property/list`,
                    {
                      company_ids: [selectedCompany?.value],
                    },
                    search,
                    array,
                    handleLoading,
                    "data",
                    {},
                    manualResponse
                  ),
                debounceTimeout: 800,
                isPaginate: true,
              },
              {
                component: "toggleButton",
                value: filterData?.status,
                options: [
                  { label: t("Active"), value: true },
                  { label: t("Inactive"), value: false },
                ],
                isMulti: true,
                state_name: "status",
                label: t("Status"),
                // required:true
              },
            ]}
            onApply={(value) => onApplyFilter(value)}
          />
        )}
      </>
    );
  };
  return <>{accessCheckRender(render, permission)}</>;
};
export default withTranslation("parkingArea")(ParkingSlotMaster);
