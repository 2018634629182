import { Box, Typography, Stack } from '@mui/material'
import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { FabButtonStyle } from './style'
import { withTranslation } from 'react-i18next';

const FabButton = ({openPopup=() => false, t = () => false, select="" }) => {
    const [show, setShow] = React.useState(false)
    const classes = FabButtonStyle({select})
    const language = localStorage.getItem("i18nextLng")
    return (
        <Box sx={{ left: language === "ar" && "3%", right: language !== "ar" && "3%" }} className={classes.fabbtn} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}
            onClick={openPopup}>
            <Stack direction={"row"} alignItems={"center"} mt={1.5} ml={1.5} mr={1.5}>
                <SearchIcon />
                {
                    show && <Typography className={classes.btntext}>{t("CheckAvailability")}</Typography>
                }
            </Stack>
        </Box>
    )
}

export default withTranslation("amenityBooking")(FabButton)
