import { Box, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { CreateEditContext } from '../create/createEditContext'
import { IncrementDecrement } from '../../../components/IncrementDecrement'
import { AddMenu } from './addMenu'
import { CreateStripStyle } from './style'
import DeleteIcon from '../../../assets/delete'
import { PropertyUnitAdd } from './propertyUnitAdd'
import { RateConfiguration } from './rateConfiguration'
import { withTranslation } from 'react-i18next'

const CreateStrip = (
    {
        data = {},
        click = () => false,
        selected = {},
        setSelected = () => false,
        index = {},
        t = () => false
    }
) => {
    const classes = CreateStripStyle()
    const [show, setShow] = React.useState(false)

    const {
        scopeCount,
        handleDecreaseScope = () => false,
        todoList,
        updateState,
        contractData,
        state,
        handleIncreaseScope = () => false,
        removeAllScope } = React.useContext(CreateEditContext)


    React.useEffect(() => {
        if (state?.type === "view" || state?.type === "edit") {
            setShow(true)
        }
        // eslint-disable-next-line
    }, [state])


    return (
        <>
            <Box>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} p={1} className={classes.strip}>
                    <Stack direction={"row"} spacing={2}>
                        <Box className={classes.countBox}>
                            <Typography className={classes.count}> {data?.count} </Typography>
                        </Box>
                        <Typography className={classes.title} onClick={() => data?.expand && setShow(!show)}> {data?.title} </Typography>
                    </Stack>
                    <Stack>
                        <AddMenu t={t} data={data} click={click} setSelected={setSelected} selected={selected} setShow={setShow} index={index} />
                    </Stack>
                </Stack>

                {
                    show &&
                    <Box sx={{ height: "100%", background: "#fff" }} pb={[1, 2].includes(index) ? 0 : 4}>
                        {
                            (selected?.type3 && index === 3) &&
                            <Box className={classes.propertyBox} ml={3}>
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} p={2}>
                                    <Typography>{t("Property")}</Typography>
                                    <IncrementDecrement count={scopeCount}
                                        decrease={handleDecreaseScope} increase={handleIncreaseScope} view={state?.type === "view"} />
                                    {
                                        state?.type !== "view" ?
                                            <Box onClick={() => removeAllScope()}>
                                                <DeleteIcon />
                                            </Box>
                                            :
                                            <Box></Box>
                                    }
                                </Stack>
                                <Box p={2}>
                                    {
                                        todoList?.map((d) => {
                                            return (
                                                <PropertyUnitAdd data={d} t={t} />
                                            )
                                        })
                                    }
                                </Box>
                            </Box>
                        }
                        {
                            (selected?.type4 && index === 4) &&
                            <RateConfiguration t={t} />
                        }
                        {
                            (selected?.type5 && index === 5) &&
                            <Box p={2}>
                                <TextField
                                    sx={{ width: "100%" }}
                                    multiline
                                    rows={4}
                                    value={contractData?.contractDetail}
                                    onChange={(e) => updateState("contractDetail", e?.target?.value)}
                                    placeholder={t("Contract Details")}
                                    disabled={state?.type === "view"}
                                />
                            </Box>
                        }
                        {
                            (selected?.type6 && index === 6) &&
                            <Box p={2}>
                                <TextField
                                    sx={{ width: "100%" }}
                                    multiline
                                    rows={4}
                                    value={contractData?.termsAndCondition}
                                    onChange={(e) => updateState("termsAndCondition", e?.target?.value)}
                                    placeholder={t("Terms and Condition")}
                                    disabled={state?.type === "view"}
                                />
                            </Box>
                        }
                    </Box>

                }

            </Box>


        </>
    )
}
export default withTranslation("contract")(CreateStrip)
