import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
import { CloseIcon } from "../customizeQUote/components/assets";
export const useStyles = makeStyles((theme) => ({
  root: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    margin: "16px 16px 0px 16px",
    padding: "16px 16px 0px 16px",
  },
  content: {
    paddingTop: "4px !important",
  },
  img: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: "4px",
    padding: "15px",
    height: "42px",
    width: "40px",
    position: "relative",
  },
  customBadge: {
    top: "2px",
    right: "2px",
  },
  icon: {
    filter: "none",
  },
  button: {
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  formRoot: {
    backgroundColor: "white",
    width: "45vw",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  drawerTitle: {
    color: "#091B29",
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().extraBold,
  },
  closeIcon: {
    fill: "none",
  },
  paginate: {
    "& .MuiButtonBase-root.MuiPaginationItem-root": {
      borderRadius: theme.palette.borderRadius,
      border: "none",
      color: "#606060",
    },
    "& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected": {
      color: "white",
      borderRadius: theme.palette.borderRadius,
      border: "none",
      backgroundColor: "#5078E1",
    },
  },
  select: {
    color: "red",
    "& .MuiSelect-select": {
      paddingBlock: 0,
      fontSize: "1rem",
      color: "#4E5A6B",
      fontFamily: FontFamilySwitch().bold,
      fontWeight: "bold",
      height: 20,
      width: 100,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
  },
  select1: {
    color: "red",
    "& .MuiSelect-select": {
      paddingBlock: 0,
      fontSize: "1rem",
      color: "#4E5A6B",
      fontFamily: FontFamilySwitch().bold,
      fontWeight: "bold",
      height: 20,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
  },
  flexRow: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  typo: {
    fontSize: "1rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().bold,
    fontWeight: "bold",
    margin: 0,
    overflow: "hidden",
  },
  tab: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#5078E1",
      height: "1.5px",
    },
    "& .MuiTab-root": {
      textTransform: "none",
      fontSize: "0.875rem",
      color: "#091B29",
    },
    "& .Mui-selected": {
      color: "#5078E1",
    },
    fontfamily: FontFamilySwitch().regular,
    borderBottom: "1px solid #E5E5E5",
    color: "#091B29",
  },
  endAdornment: {
    display: "flex",
    borderLeft: "1px solid #E4E8EE",
    width: "100px",
    height: "42.13px",
    alignItems: "center",
    cursor: "pointer",
    paddingLeft: 8,
  },
  btnroot: {
    backgroundColor: "transparent",
    padding: "14px", // Consistent padding
    display: "flex",
    alignItems: "center",
  },
  previousbtn: {
    backgroundColor: "white",
    color: theme.typography.color.primary,
    border: "1px solid #CED3DD",
    borderRadius: theme.palette.borderRadius,
    padding: "8px 12px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "white",
    },
    fontFamily: FontFamilySwitch().bold,
  },
  submitbtn: {
    borderRadius: theme.palette.borderRadius,
    marginInlineStart: "8px",
    padding: "8px 12px",
    boxShadow: "none",
    fontFamily: FontFamilySwitch().bold,
  },
  bottom: {
    bottom: 0,
    padding: "8px",
  },
  typeBg: {
    position: "absolute",
    textAlign: "center",
    color: "white",
    right: "0px",
    padding: "0px 4px",
  },
  typeBgArabic: {
    position: "absolute",
    textAlign: "center",
    color: "white",
    left: "0px",
    padding: "0px 4px",
  },
  centeredText: {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  type: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    marginInlineStart: "3px",
    marginTop: "1px",
  },

  popover: {
    "& .MuiPopover-paper": {
      boxShadow: "0px 0px 6px #0717411F",
      borderRadius: theme.palette.borderRadius,
      backgroundColor: "white",
    },
  },
  menuItem1: {
    borderBottom: "1px solid #E4E8EE",
    margin: "0px 4px",
    fontSize: "1rem",
    color: "#071741",
    fontFamily: FontFamilySwitch().semiBold,
    padding: "4px",
    "&:last-child": {
      border: 0,
    },
  },
  add: {
    color: "#5078E1",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    cursor: "pointer",
  },
}));
