import { Typography } from "@mui/material";
import styled from "@mui/material/styles/styled";
import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch, remCalc } from "../../../utils";
import { PaidStatus } from "./utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: `0px 0px 16px #00000014`,
    borderRadius: '4px',
    backgroundColor: "white"
  },
  showborder: {
    border: "1px solid #5078E1",
    padding: "8px",
    borderRadius: theme.palette.borderRadius
  },
  statusText: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    borderRadius: theme.palette.borderRadius,
    padding: "5px",
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    height: "20px",
    textTransform: "capitalize"
  },
  noborder: {
    padding: "8px"
  },
  paddingLeft: {
    paddingLeft: (props) => props?.type ? '4px !important' : '41px !important',
    paddingTop: "19px"
  },
  detailsBox: {
    borderTop: `1px solid ${theme?.palette?.border?.secondary}`,
    borderBottom: `1px solid ${theme?.palette?.border?.secondary}`,
    padding: '12px'
  },
  boxHeight: {
    height: 'calc(100vh - 230px)',
    // height: "500px",

    overflow: "auto",
    borderRight: `1px solid ${theme?.palette?.border?.secondary}`,
    boxShadow: `0px 0px 16px #00000014`,
    borderRadius: '4px',
    backgroundColor: "white"
  },
  notrackfound: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)"
  },

  notes: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.typography?.color?.secondary,
  },
  title: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold
,
    color: theme?.typography?.color?.primary,
  },
  des: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.palette?.background?.announsment,
  },
  btn: {
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    width: '100%',
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
      color: theme?.palette?.background?.tertiary1,
    },
  },
  btnParent: {
    padding: "20px 20px 20px 20px",
  },
  convayTitle: {
    color: theme?.palette?.border?.status,
    fontSize: "0.9375rem",
    fontFamily: FontFamilySwitch().bold
,
  },
  status: {
    color: theme?.typography?.color?.tertiary,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  subStatus: {
    color: theme?.palette?.background?.reservation,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  statusBox: {
    borderRadius: "4px",
    backgroundColor: theme?.palette?.info?.light,
    padding: "16px"
  },
  card: {
    padding: "10px",
    borderRadius: "4px",
    boxShadow: `0px 0px 16px #00000014`,
    backgroundColor: "white"
  },
  cardDetails: {
    color: theme?.palette?.background?.primary,
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold

  },
  cardTitle: {
    color: theme?.typography?.color?.primary,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  cardSubTitle: {
    color: theme?.typography?.color?.tertiary,
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular,
  },
  dot: {
    backgroundColor: theme?.typography?.color?.tertiary,
    borderRadius: "50%",
    height: "7px",
    width: "7px",
    margin: "0 7px"
  },
  timeLineBox: {
    boxShadow: `0px 0px 16px #00000014`,
    borderRadius: "4px",
    marginTop: "5px"
  },
  profilePic: {
    borderRadius: theme.palette.borderRadius,
  },
  createnewsectionlog: {
    // padding: "30px 20px 30px 41px",
  },

  notesTimeLine: {
    color: theme?.palette?.background?.primary,
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  noteTimeLine: {
    color: theme?.typography?.color?.secondary,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    maxWidth: "624px",
    width: '100%',
    textAlign: "left"
  },
  border: {
    borderLeft: `2px dashed ${theme?.palette?.primary?.main}`,
    padding: "0px 20px",
    position: "relative",
    // "&:last-child": {
    //     borderLeft: "none",
    // },
  },
  showNotesText: {
    color: "#98a0ac",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.75rem"
  },
  applynoborder: {
    padding: "0px 20px",
    position: "relative",
  },
  avatars1: {
    position: "absolute",
    top: 0,
    left: '-22px',
    height: "45px",
    width: "45px",
    backgroundColor: "#F2F4F7",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
  },
  image: {
    backgroundColor: theme?.palette?.primary?.main,
    borderRadius: "50%",
    padding: "10px 9px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  createnewsectionlog1: {
    overflow: "auto",
    padding: "24px 20px 0px 40px"

  },
  trackboxmod: {
    boxShadow: `0px 0px 16px #00000014`,
    borderRadius: '4px',
    backgroundColor: "white",
  },
  dateTime: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#7C8594",
  },
  innerImage: {
    height: "100%",
    width: "100%",
    backgroundColor: theme?.typography?.status?.primary,
  },
  checkIconcircle: {
    backgroundColor: "#5078E1",
    borderRadius: "50%",
    padding: "3px",
    width: "25px",
    height: "25px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center"
  },
  acknowledgeTxt: {
    fontSize: "0.875rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold
  },
  acknowledgeSent: {
    color: "#5AC782",
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.875rem",
  },
  acknowledgeSentBox: {
    backgroundColor: '#EEF9EE',
    borderRadius: theme.palette.borderRadius,
    padding: "5px"
  },
  chargingReqTxt: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#4E5A6B"
  },
  chargingSubReqTxt: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#4E5A6B"
  },
  statusTxt: {
    color: "#FF9340",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    marginLeft: "5px"
  },
  approvestatusTxt: {
    color: "#5AC782",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    marginLeft: "5px"
  },
  RejectedstatusTxt: {
    color: "#FF4B4B",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    marginLeft: "5px"
  },
  costingTitle: {
    color: "#4E5A6B",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  costingAmount: {
    color: "#4E5A6B",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold
  },
  visitingCharges: {
    backgroundColor: "#F2F4F7",
    borderRadius: theme.palette.borderRadius,
    padding: "8px"
  },
  costingCharges: {
    backgroundColor: "#F2F4F7",
    borderRadius: theme.palette.borderRadius,
    padding: "16px"
  },
  applytxt: {
    color: '#5078E1',
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer"
  },
  canceltxt: {
    color: '#FF4B4B',
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    marginInlineStart: "16px"
  },
  fixedBtn: {
    position: "absolute",
    right: 0,
    bottom: 0,
    // top: 0,
    left: 0,
    padding: "16px"
  },
  fixedBottom:{
    position: "absolute",
    right: 0,
    bottom: 0,
    // top: 0,
    left: 0,
    padding: "16px",
    backgroundColor:"#FFFFFF",
    borderTop:"1px solid #E4E8EE"
  },
  totalbox: {
    backgroundColor: "#E4E8EE",
    borderRadius: "6px",
    padding: "8px",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    margin: "16px"
  },
  finalBox: {
    backgroundColor: "#E4E8EE",
    borderRadius: "6px",
    padding: "8px",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0
  },
  costingBox: {
    border: "1px solid #5078E1",
    borderRadius: theme.palette.borderRadius,
    padding: "5px",
    width: "365px",
    curssor: "pointer"
  },
  costingTxt: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#5078E1"
  },
  showComplete: {
    backgroundColor: "#5078E1",
    backgroundImage: `url(${"/images/requestComplete.svg"})`,
    height: "250px"
  },
  closeicon: {
    color: "white",
    cursor: "pointer"
  },
  q1hdr: {
    color: "white",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.875rem",
    marginLeft: "15px"
  },
  q1subhdr: {
    color: "white",
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.75rem",
    marginLeft: "15px"
  },
  yesbtn: {
    backgroundColor: "white",
    color: "#5078E1",
    "&:hover": {
      backgroundColor: "white",
      color: "#5078E1",
    }
  },
  outlinebtn: {
    "&:hover": {
      backgroundColor: "white"

    }
  },
  costinghdr: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary
  },
  more: {
    fontSize: "1.25rem",
    color: "gray",
    // cursor: "pointer"
  },
  service: {
    backgroundColor: "#F5F7FA",
    borderRadius: "4px",
    padding: "4px 10px",
    cursor: "pointer",
    textAlign: "center"
  },
  slotTime: {
    backgroundColor: "#E4E8EE",
    borderRadius: "4px",
    padding: "6px 4px",
    cursor: "pointer",
    textAlign: "center"
  },
  seeMoreLessTextStyle: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  description: {
    fontFamily: FontFamilySwitch().regular,
    color: "#4E5A6B",
    fontSize: "0.75rem"
  },
  detailsReqBox: {
    borderRadius: theme.palette.borderRadius,
    border: "1px solid #E4E8EE",
    padding: "12px"
  },
  applyBorderLeft: {
    borderLeft: "1px solid #E4E8EE",
  },
  dotService: {
    backgroundColor: theme.typography.color.tertiary,
    width: "5px",
    height: "5px",
    borderRadius: "50%",
    marginInline: "4px"
  },
  serviceLevelCard: {
    borderRadius: "4px",
    // boxShadow: `0px 0px 16px #00000014`,
    backgroundColor: "white"
  },
  serviceLevelBox: {
    border: "1px solid #E4E8EE",
    borderRadius: theme.palette.borderRadius,
    padding: "12px"
  },
  serviceLevelTitle: {
    fontSize: "0.875rem",
    color: theme.typography.color.tertiary,
    fontFamily: FontFamilySwitch().bold,
  },
  serviceLevelValues: {
    fontSize: "0.875rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
  },
  serviceLevelsubValue: {
    fontSize: "0.75rem",
    color: theme.typography.color.tertiary,
    fontFamily: FontFamilySwitch().bold,
  },
  visitingChargesText: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.secondary,
    marginInline: "8px !important"
  },
  visitingChargeBox: {
    border: "1px solid #E4E8EE",
    borderRadius: theme.palette.borderRadius,
    display: "flex",
    justifyContent: "space-between"
  },
  unitNametxt: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary
  },
  unitIdtxt: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular,
    color: theme.typography.color.secondary
  },
  vistingdetailsBox: {
    backgroundColor: "#F1F7FF",
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center"
  },
  Amounttxt: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary
  },
  chargesstatusBox: {
    borderRadius: theme.palette.borderRadius,
    fontSize: '0.75rem',
    fontFamily: FontFamilySwitch().semiBold,
    color: "#FFFFFF",
    padding: "5px"
  },
  invoiceStatus: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#FFFFFF",
    textTransform: "capitalize"
  },
  invoiceIcon: {
    border: "1px solid #E4E8EE",
    borderRadius: theme.palette.borderRadius,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "6px",
    cursor: "pointer",
    marginInline: "8px !important"
  },
  BOMText: {
    color: "#5078E1",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    cursor: "pointer",
    marginInline: "8px !important"
  },
  deductionBox: {
    border: "1px solid #E4E8EE",
    borderRadius: "16px",
    padding: "6px",
    cursor: "pointer"
  },
  deductiontxt: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary,
    marginInline: "8px !important"
  },
  table: {
    // [theme.breakpoints.only("md")]: {
    //   maxHeight: "calc(100vh - 460px)"
    // },
    // [theme.breakpoints.only("sm")]: {
    //   maxHeight: "calc(100vh - 460px)"
    // },
    overflow: "auto",
    // height:"calc(100vh - 580px)"
  },
  headCell: {
    padding: "8px",
    border: "none"
  },
  headText: {
    fontSize: "0.75rem",
    color: theme.typography.color.tertiary,
    fontFamily: FontFamilySwitch().semiBold,
    textTransform: "uppercase"
  },
  addBillLine: {
    fontSize: "0.875rem",
    color: theme.palette.primary.main,
    fontFamily: FontFamilySwitch().bold,
    padding: "4px",
    cursor: "pointer",
    whiteSpace: "noWrap",
    width: "150px"
  },
  bodyCell: {
    padding: "4px",
    border: "none",
    minWidth: "105px"
  },
  bodyCell2: {
    padding: "4px",
    border: "none",
  },
  bottomCard: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: `1px solid ${theme.palette.border.primary}`,
    padding: 16,
    backgroundColor: "#FFFFFF"
  },
  singleBorder: {
    borderRight: `1px solid ${theme.palette.border.primary}`
  },
  containBox: {
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: 4
  },
  costingStatusTxt: {
    padding: "5px",
    borderRadius: theme.palette.borderRadius,
    color: "#FFFFFF",
    fontSize: "0.875rem",
    textAlign: "center"
  },
  deductionSection: {
    overflow: "auto",
  },
  addNewDeduction: {
    // position:"absolute",
    // bottom:0,
    // right:0,
    // left:0,
    // padding:"0px 16px"
  },
  costingStatusBox: {
    backgroundColor: "#F1F7FF",
    borderRadius: theme.palette.borderRadius,
    padding: "4px",
    textWrap: "nowrap",
  },
  reqblock: {
    backgroundColor: "#5078E1",
    backgroundImage: `url(${"/images/requestView.svg"})`,
    height: "250px"
  },
  nobtn: {
    color: "white",
    border: "1px solid #E4E8EE",
    "&:hover": {
      color: "white",
      border: "1px solid white",

    },
    marginInlineStart: "8px"
  },
  costingBoxAll: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    overflow: "auto",
  },
  costingDeleteIcon: {
    cursor: "pointer",
    marginInline: "8px !important"
  },
  requestImage: {
    width: "110px",
    height: "110px",
    borderRadius: theme.palette.borderRadius,
    '&:hover': {
      opacity: "80%"
    }
  },
  viewimg: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  viewTxt: {
    fontSize: remCalc(14),
    fontFamily: FontFamilySwitch().semiBold,
    color: "#FFFFFF"
  },
  requesTitle: {
    fontSize: remCalc(16),
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary
  },
  contactName: {
    fontSize: remCalc(14),
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.primary
  },
  leadcontact: {
    color: "#4E5A6B",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular,
    display: "flex",
    alignItems: "center",
  },
  leadcontactNo: {
    color: "#4E5A6B",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular,
    display: "flex",
    alignItems: "center",
    direction: "ltr"
  },
  contactbox: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    borderRadius: theme.palette.borderRadius
  },
  avatar: {
    borderRadius: "4px",
    marginInlineEnd: "12px",
    backgroundColor: "#F2F4F7",
    border: "1px solid #E4E8EE",
  },
  viewMoreTxt: {
    fontSize: remCalc(12),
    fontFamily: FontFamilySwitch().semiBold,
    color: "#5078E1",
    cursor: "pointer"
  },
  drawerHeader: {
    fontSize: remCalc(16),
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary
  },
  openTxt: {
    fontSize: remCalc(14),
    fontFamily: FontFamilySwitch().bold,
    color: "#FFFFFF",
    backgroundColor:"#bcbc37",
    borderRadius:"4px",
    padding:"2px 8px"
  },
  hdrBg:{
    backgroundColor:"#E4E8EE",
    borderRadius:"4px 4px 0px 0px"
  },
  dividerBGcolor:{
    backgroundColor:"#b6c6d1"
  },
  productTitle: {
    fontSize: remCalc(14),
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary
  },
  productValue: {
    fontSize: remCalc(14),
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.primary
  },
  itemName: {
    fontSize: remCalc(16),
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
    textAlign: "center"
  },
  itemCondition: {
    fontSize: remCalc(12),
    color: "#5AC782",
    fontFamily: FontFamilySwitch().semiBold,
  },
  itemBox: {
    backgroundColor: "#EEF9EE",
    borderRadius: theme.palette.borderRadius,
    display: "flex",
    justifyContent: "center",
    width: "100px",
    padding: "5px"
  },
  verticalDivider: {
    height: "30px",
    margin: "5px 0px"
  },
  productImage: {
    width: "127px",
    height: "127px",
    borderRadius: "12px",
    border: "1px solid #E4E8EE",
  },
  requestheading: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#4E5A6B",
    marginBottom: theme.spacing(1),
    textTransform: "capitalize"
  },
  generateNotes: {
    height: 40,
    whiteSpace: "nowrap",
    "&:hover": {
      background: "transparent"
    }
  },
  notesHoverBox: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "4px"
  },
  notesBox: {
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: "4px"
  },
  notesTitle: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  notesSubTitle: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().bold,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  createBtn: {
    height: 40,
    width: 78
  },
  drawerBox: {
    width: '456px',
    position: "relative"
  },
  header: {
    position: "fixed",
    top: 0,
    width: "100%"
  },
  bottom: {
    position: "fixed",
    bottom: 0,
    padding: '8px',
    width: '456px'
  },
  body: {
    // position: "fixed",
    top: 0,
    marginTop: '64px',
    height: "calc(100vh - 130px)",
    overflow: "auto",
    width: "100%"
  },
  drawerCloseBtn: {
    fontSize: "1rem",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().semiBold,
    background: theme.palette.background.tertiary,
    borderRadius: 4,
    height: 32,
    width: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },
  drawerHeading: {
    fontSize: "1rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold
  },
  previous: {
    border: `1px solid ${theme.palette.border.primary}`,
    background: "transparent",
    color: theme.typography.color.primary,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    // height: 40,
    padding: '11px 16px',
    lineHeight: '19px !important',
    "&:hover": {
      background: "transparent",
    }
  },
  next: {
    padding: '11px 16px',
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    lineHeight: '19px !important'

  },
  showMoreLabel: {
    fontSize: "0.75rem",
    color: theme.palette.primary.main,
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
  },
  edit: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.palette.primary.main,
    cursor: "pointer"
  },
  priorityBox: {
    borderRadius: "4px",
    padding: "4px 10px",
    textAlign: "center"
  },
  prioritytitle: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().semiBold,
    textTransform: "capitalize"
  },
  showOnly: {
    fontSize: remCalc(12),
    color: theme.typography.color.tertiary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  view_more_button: {
    borderRadius: theme.palette.borderRadius,
    border: `1px solid ${theme.palette.border.white}`,
    backgroundColor: theme.palette.background.tertiary1,
    "&:hover": {
      backgroundColor: theme.palette.background.tertiary1,
    },
  },
  view_more_text: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: remCalc(12),
    color: theme.typography.color.primary_2,
  },
  tag_1: {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: theme.palette.borderRadius,
    padding: "4px 10px",
  },
  tag_text_1: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: remCalc(12),
    color: theme.typography.color.primary,
  },
  worker_image: {
    cursor: 'pointer',
    height: "40px",
    width: "40px",
    objectFit: "contain",
    borderRadius: theme.palette.borderRadius,
  },
  worker_period: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: theme.palette.background.primary_3,
  },
  worker_name: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: remCalc(14),
    color: theme.typography.color.primary,
  },
  vendor_name: {
    fontFamily: FontFamilySwitch().regular,
    fontSize: remCalc(12),
    color: theme.typography.color.primary,
  },
  worker_details: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: remCalc(12),
    color: theme.typography.color.secondary,
  },
  total_amount_tag: {
    backgroundColor: ({ is_worker_paid }) => PaidStatus?.[is_worker_paid]?.total_amount_tag_color,
    borderRadius: "21px",
    padding: "8px",
  },
  total_amount_label: {
    fontFamily: FontFamilySwitch().regular,
    fontSize: remCalc(12),
    color: ({ is_worker_paid }) => PaidStatus?.[is_worker_paid]?.total_amount_label_color,
  },
  total_amount_value: {
    display: "inline",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: remCalc(12),
    color: ({ is_worker_paid }) => PaidStatus?.[is_worker_paid]?.total_amount_value_color,
  },
  paid_tag: {
    backgroundColor: ({ is_worker_paid }) => PaidStatus?.[is_worker_paid]?.paid_tag_color,
    padding: "4px 10px",
    borderRadius: "13px",
  },
  paid_text: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: remCalc(12),
    color: theme.typography.color.white,
  },
  worker_action_button: {
    display: 'flex',
    alignItems: 'center',
    cursor: "pointer",
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
    border: `1px solid ${theme.palette.border.secondary}`,
  },
  cancel_button: {
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "12px",
    backgroundColor: theme.palette.background.tertiary1,
    "&:hover": {
      backgroundColor: theme.palette.background.tertiary1,
    }
  },
  cancel_button_text: {
    fontSize: remCalc(14),
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary
  },
  update_button: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.palette.borderRadius,
    padding: "12px",
    backgroundColor: theme.palette.background.button_background_1,
    "&:hover": {
      backgroundColor: theme.palette.background.button_background_1,
    }
  },
  update_button_text: {
    fontSize: remCalc(14),
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.white
  },
  view_grey_card: {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: theme.palette.borderRadius_2,
    padding: "16px",
  },
  view_work_order_ref_no: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: remCalc(14),
    color: theme.typography.color.primary,
  },
  view_work_order_date: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: remCalc(12),
    color: theme.typography.color.secondary,
  },
  work_order_status_tag: {
    backgroundColor: theme.palette.background.primary_2,
    borderRadius: theme.palette.borderRadius,
    padding: "4px 10px",
  },
  work_order_status_text: {
    textTransform: "capitalize",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: remCalc(12),
    color: theme.typography.color.white,
  },
  view_section_details_card: {
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: theme.palette.borderRadius_2,
    padding: "16px",
  },
  view_worker_image: {
    cursor: 'pointer',
    height: "40px",
    width: "40px",
    objectFit: "contain",
    borderRadius: theme.palette.borderRadius,
  },
  view_worker_name: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: remCalc(14),
    color: theme.typography.color.primary,
  },
  view_worker_details: {
    fontFamily: FontFamilySwitch().regular,
    fontSize: remCalc(12),
    color: theme.typography.color.secondary,
  },
  view_paid_tag: {
    backgroundColor: ({ is_worker_paid }) => PaidStatus?.[is_worker_paid]?.view_paid_tag_color,
    padding: "4px 10px",
    borderRadius: theme.palette.borderRadius,
  },
  view_paid_text: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: remCalc(12),
    color: theme.typography.color.white,
  },
}));
export const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: "0.75rem",
  fontFamily: FontFamilySwitch().bold,
  color: "#4E5A6B",
  marginBottom: theme.spacing(1),
}));

export const CustomTypography2 = styled(Typography)(({ theme }) => ({
  fontSize: remCalc(12),
  fontFamily: FontFamilySwitch().semiBold,
  color: "#4E5A6B",
}));

export const CustomTypography3 = styled(Typography)(({ theme }) => ({
  fontSize: remCalc(14),
  fontFamily: FontFamilySwitch().semiBold,
  color: "#4E5A6B",
}));

export const CustomTypography4 = styled(Typography)(({ theme }) => ({
  fontSize: remCalc(14),
  fontFamily: FontFamilySwitch().semiBold,
  color: "#091B29",
}));

export const CustomTypography5 = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontSize: remCalc(12),
  fontFamily: FontFamilySwitch().semiBold,
  fontStyle: "italic",
  color: "#091B29",
}));