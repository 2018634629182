import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../../utils";
export const useStyles = makeStyles((theme) => ({
    root: {
        padding: "16px",
        // alignItems: "center",
        borderRadius:theme.palette.borderRadius,
        backgroundColor: "white",
        boxShadow: "0px 3px 30px #5C86CB2E",
        height: "116px",
        display:"flex",
        justifyContent:"space-between",
        flexDirection:"column",
    },
    row: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    count: {
        color: "#091B29",
        fontSize:"1.5rem",
        fontFamily: FontFamilySwitch().bold
    },
    label: {
        color: "#091B29",
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold
    },
    stackTitle: {
        color: "#091B29",
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        // width: "80%",
    },
}));