import React from "react";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods, FontFamilySwitch } from "../../utils";
import { CustomSelect } from "../filterGenerator/components";
import { SelectBox } from "../select";



export const TeamsSelect = (props) => {

    const [selectedTeams, setSelectedTeams] = React.useState({})
    const [teamsList, setTeamsList] = React.useState([])
    //get teams
    const getTeams = (companyId, moduleId) => {
        const payload = {
            company_id: companyId,
            module: Number(moduleId)
        };
        NetworkCall(
            `${config.api_url}/hierarchy-team/get_user_access_teams`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setSelectedTeams(response?.data?.data?.teams?.[0] ?? { label: "noteams", value: "noteams" })
                props?.handleTeamsChange && props?.handleTeamsChange(response?.data?.data?.teams?.[0] ?? { label: "noteams", value: "noteams" })
                setTeamsList(response?.data?.data?.teams)
                props?.getTeamsOption && props?.getTeamsOption(response?.data?.data?.teams)
            })
            .catch((error) => {
                console.log(error)
            });
    }
    //onChange
    const onChange = (value) => {
        setSelectedTeams(value)
        props?.handleTeamsChange(value)
    }

    React.useEffect(() => {

        if (props?.companyId && props?.companyId.toString()?.length > 0 && props?.moduleId) {
            getTeams(props?.companyId, props?.moduleId)
        }
        //eslint-disable-next-line
    }, [props?.companyId, props?.moduleId])
    return (
        <>
        {
            teamsList?.length ?
             <>
             {
                  props?.customSelect ?
                  <CustomSelect
                      noBorder={props?.noBorder}
                      selectHeight={props?.selectHeight}
                      padding={props?.padding}
                      color={props?.color}
                      prefix={props?.prefix}
                      options={teamsList}
                      value={selectedTeams ?? { label: "No Teams Available" }}
                      noSearch={true}
                      onChange={(value) => {
                          onChange(value)
                      }}
                  />
                  :
                  selectedTeams?.value !== "noteams" &&
                  <SelectBox
                      noBorder={props?.noBorder}
                      selectHeight={props?.selectHeight}
                      padding={props?.padding}
                      color={props?.color}
                      prefix={props?.prefix}
                      isReadOnly={props?.otherOptionsIsReadOnly}
                      placeholder={props?.otherOptionsPlaceholder}
                      label={props?.otherOptionslabel}
                      fontFamily={FontFamilySwitch().semiBold}
                      value={selectedTeams ?? { label: "No Teams Available" }}
                      options={teamsList}
                      onChange={(value) => {
                          onChange(value)
                      }} />
             }
             
             </>:""
        }
        </>
    );
};


