export const TYPE_LIST =  (t = () => false) => [
    { label: t("Resource"), value: "resource" },
    { label: t("Contact"), value: "contact" },
]

export const VIEW_TYPE_LIST = [
    { label: "Calendar", value: "calendar" },
    { label: "Table", value: "table" },
]

export const CheckInOutHeading = (t = () => false) => [
    { title: t("Resource Name"), field: "resource_name" },
    { title: t("Location"), field: "location" },
    { title: t("Check In"), field: "checkin" },
    { title: t("Check In Distance"), field: "check_in_distance" },
    { title: t("Check In By"), field: "checkin_by" },
    { title: t("Check Out"), field: "checkout" },
    { title: t("Check Out Distance"), field: "check_out_distance" },
    { title: t("Check Out By"), field: "checkout_by" },
    { title: t("Time Difference"), field: "time_diff" },
    { title: t("Status"), field: "status" },
]

export const CheckInOutPath = ["resource_name", "location", "checkin", "check_in_distance","checkin_by", "checkout", "check_out_distance","checkout_by", "time_diff", "status"]

export const CheckInOutType = [
    { type: ["text"], name: "resource_name" },
    { type: ["text"], name: "location" },
    { type: ["text"], name: "checkin" },
    { type: ["text"], name: "check_in_distance" },
    { type: ["text"], name: "checkin_by" },
    { type: ["text"], name: "checkout" },
    { type: ["text"], name: "check_out_distance" },
    { type: ["text"], name: "checkout_by" },
    { type: ["text"], name: "time_diff" },
    { type: ["text"], name: "status" },
]
