import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { config } from '../../config';
import { NetworkCall } from '../../networkcall';
import { NetWorkCallMethods } from '../../utils';
import { Card } from "./card";
import { NotesCard } from "./notes";
import ParkingCard from "./parkingCard";
import { AccordianStyle } from "./styles";

const AccordianWithDetails = ({ data = [],unitDetails={}, t, MoveIn = true, MoveOut = false, state = {}, utility = false, generalInspection = false, notesData = "", setNotesData = () => false, onUpdateNotes = () => false, status = "", siteVisit = false, type = "", isDisableBtn = false,unitReadiness=false }) => {
    const classes = AccordianStyle();
    const [details, setDetails] = React.useState({})
    const getParkingDetails = () => {
        const payload = {
            agreement_id: state?.agreement_id,
            unit_id: state?.unit_id,
            is_parking_component: state?.is_parking_component,
            is_parking_unit: state?.is_parking_unit
        }
        NetworkCall(
            `${config.api_url}/parking_unit/get_parking_unit_details`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setDetails(res?.data?.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    React.useEffect(() => {
        if (state?.is_parking_component || state?.is_parking_unit) {
            getParkingDetails()
        }
        // eslint-disable-next-line
    }, [])


    return (
        <Box>
            {!utility && !unitReadiness&&
                <Grid container>
                    <Grid item xs={4} sm={(!state?.is_parking_component && !state?.is_parking_unit && !MoveOut) ? 6 : 4} md={4} lg={4}>
                        <Typography className={classes.heading}>{t("inspection_item")}</Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>

                        {
                            !state?.is_parking_component && !state?.is_parking_unit &&
                            <Typography className={classes.heading}>{t("move_in")}</Typography>
                        }

                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} sx={{ display: "flex", justifyContent: "center" }}>

                        {
                            (MoveOut && !state?.is_parking_component && !state?.is_parking_unit) && <Typography className={classes.heading}>{t("MOVE OUT")}</Typography>
                        }

                    </Grid>
                    <Grid item sm={2} md={2} lg={2}></Grid>

                </Grid >
            }
            {
                utility &&
                <Grid container>
                    <Grid item xs={4}>
                        <Typography className={classes.heading}>{t("utility_item")}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {
                            !state?.is_parking_component && !state?.is_parking_unit &&
                            <Typography className={classes.heading}>{t("details")}</Typography>
                        }

                    </Grid>
                    <Grid item xs={4}>
                        {
                            (MoveOut && !state?.is_parking_component && !state?.is_parking_unit) && <Typography className={classes.heading}>{t("MOVE OUT")}</Typography>
                        }

                    </Grid>
                </Grid>
            }
            {
                (generalInspection || siteVisit || unitReadiness) &&
                <Grid container>
                    <Grid item xs={4} sm={6} md={4}>
                        <Typography className={classes.heading}>{(type === "unit_readiness" || type === "site_visit" || type === "delivery-order") ? t("inspection_item") : t("inventory_inspection_item")}</Typography>

                    </Grid>
                    <Grid item xs={4}>
                        <Typography className={classes.heading}>{t("details")}</Typography>
                    </Grid>
                </Grid>
            }
            {
                state?.is_parking_component || state?.is_parking_unit ?
                    <Box>
                        <ParkingCard item={details} />
                        <NotesCard setNotesData={setNotesData} t={t} notesData={notesData} onUpdateNotes={onUpdateNotes} status={status} isDisableBtn={isDisableBtn} />

                    </Box>
                    :
                    <Box>
                        <Box>
                            
                            {/* {data?.length > 0 && data?.map((val) => {
                                return ( */}
                                    <Card t={t} state={state} unitDetails={unitDetails} item={data} MoveIn={MoveIn} MoveOut={MoveOut} utility={utility} generalInspection={generalInspection} setNotesData={setNotesData} notesData={notesData} onUpdateNotes={onUpdateNotes} status={status} type={type} isDisableBtn={isDisableBtn}/>
                                {/* )
                            })} */}

                            {/* <NotesCard t={t} setNotesData={setNotesData} notesData={notesData} onUpdateNotes={onUpdateNotes} status={status} type={type} isDisableBtn={isDisableBtn} /> */}
                        </Box>


                    </Box>
            }



        </Box >
    )
}
export default withTranslation("agreement")(AccordianWithDetails);