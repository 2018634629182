import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch, remCalc } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px 16px 80px",
    backgroundColor: theme?.palette?.background?.secondary,
    height: `calc(100vh - 64px)`,
    overflow: "auto",
    [theme?.breakpoints.down("md")]: {
      padding: "10px 10px 80px",
    },
  },
  widget_section: {
    padding: "16px",
    backgroundColor: theme?.palette?.background?.tertiary1,
  },
  widget_header: {
    color: theme?.typography?.color?.primary,
    fontSize: remCalc(16),
    fontFamily: FontFamilySwitch().extraBold,
  },
  widget_internal_card_1: {
    backgroundColor: "#F5F7FA",
    display: "flex",
    justifyContent: "center",
    border: "1px solid #E4E8EE",
    borderRadius: theme.palette.borderRadius,
    padding: "12px",
    alignItems: "center"
  },
  widget_internal_card_1_text_1: {
    fontSize: remCalc(18),
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary
  },
  widget_internal_card_1_text_2: {
    fontSize: remCalc(14),
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.primary
  },
  loader: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  summarytxt: {
    fontSize: "0.875rem",
    color: theme.palette.secondary,
    fontFamily: FontFamilySwitch().semiBold,
    marginBottom: "8px"
  },
  summaryCard: {
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "#FFFFFF",
    padding: "12px"
  },
  subscribeCard: {
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
    backgroundColor: "#F5F7FA"
  },
  title: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary
  },
  subTitle: {
    fontSize: "0.75rem",
    color: theme.palette.secondary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  detailsCard: {
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
    height: "90px"
    // opacity:"20%"
  },
  clienttitle: {
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary
  },
  clientsubTitle: {
    fontSize: "0.75rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
    // marginTop:"8px"
  },
}));
