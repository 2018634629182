import { Avatar, Box, Typography } from "@mui/material"
import { FunctionalDetailsStyle } from "./style"
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { FontFamilySwitch } from "../../utils";


export const FunctionalLocationDetails = ({
    active = () => true, t = () => false, data = {}
}) => {
    const LongTextTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: "400px",
            borderRadius: "4px",
            padding: "8px 12px",
            backgroundColor: "#FFFFFF",
            color: "#1C1C1C",
            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
            fontSize: "0.875rem",
            fontFamily: FontFamilySwitch().semiBold,
        },
        [`& .${tooltipClasses.arrow}`]: {
            backgroundColor: "transparent",
            color: "#FFFFFF"
        },
    }));
    const classes = FunctionalDetailsStyle()
    return (
        <Box className={classes.mainbox}>
            <Box>
                <Box className={classes.imgBox}>
                    <Avatar className={classes.avatar} src={data?.image_url}></Avatar>
                    <Typography className={data?.is_active ? classes.activeText : classes.inactiveText}>{data?.status}</Typography>
                </Box>
                <Box className={classes.textBox}>
                    <div style={{ maxWidth: "400px", display: "flex" }}>
                        <LongTextTooltip title={data?.name ?? ""} placement="top" arrow>
                            <Typography
                                noWrap
                                readOnly
                                className={classes.locationName}
                                style={{
                                    wordBreak: "break-all",
                                    overflowWrap: "anywhere",
                                }}
                            >
                                {data?.name?.length > 0
                                    ? `${data?.name?.substring(0, 70)}${data?.name?.length > 70 ? "..." : ""
                                    } `
                                    : "-"}

                            </Typography>
                        </LongTextTooltip>

                    </div>
                    <div style={{ maxWidth: "400px", display: "flex" }}>
                        <LongTextTooltip title={data?.description ?? ""} placement="top" arrow>
                            <Typography
                                noWrap
                                readOnly
                                className={classes.desc}
                                style={{
                                    wordBreak: "break-all",
                                    overflowWrap: "anywhere",
                                }}
                            >
                                {data?.description?.length > 0
                                    ? `${data?.description?.substring(0, 70)}${data?.description?.length > 70 ? "..." : ""
                                    } `
                                    : "-"}

                            </Typography>
                        </LongTextTooltip>
                    </div>
                    <Typography align="center" className={classes.location}>{`Location: ${data?.property_name ? data?.property_name : ""}  ${data?.block_name ? "," + data?.block_name : ""} ${data?.floor_name ? "," + data?.floor_name : ""} ${data?.unit_name ? "," + data?.unit_name : ""}`}</Typography>
                    {data?.is_parent === "Yes" &&
                        <Box className={classes.parentBox}>
                            {/* <Typography align="center" className={classes.parent}>Parent Location: Location place here</Typography> */}
                        </Box>
                    }
                </Box>
            </Box>
        </Box>
    )
}

