import makeStyles from "@mui/styles/makeStyles";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { useEffect } from "react";
import { Bar, Line } from "react-chartjs-2";
import { defaults } from 'chart.js';
import { FontFamilySwitch } from "../../utils/constants"
// import ChartAnnotation from 'chartjs-plugin-annotation';

const useStyles = makeStyles((theme, props) => ({
  root: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // "& svg": {
    //   // borderRadius: "50%",
    //   width: "100%",
    //   minWidth: "138px !important",
    //   height: "138px !important",
    //   position: "relative",
    //   [theme.breakpoints.up("sm")]: {
    //     width: "100% !important",
    //     margin: "0 auto",
    //   },
    //   "& .recharts-wrapper": {
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   },
    // },
  },
  total: {
    color: "#637381",
    fontSize:"0.9375rem",
  },
  des: {
    fontWeight: 600,
    color: "#252F3A",
    fontSize: "2.5rem",
  },
  chart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  lineChart: {
    minHeight: (props) => props?.height ?? "320px",
    width: (props) => props?.width ?? "600px",
  },
}));
export const GroupPieCharts = (props) => {
  defaults.font.family = FontFamilySwitch().extraBold;
  const options = {
    indexAxis: props?.indexAxis ?? false,
    animation: false,
    // responsive: false,

    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        // mode: 'index',
        // intersect: false,
        // position: 'nearest',
        // enabled:false,
        // external: externalTooltipHandler,
        backgroundColor: '#FFFFFF',
        borderColor: "#E4E8EE",
        // backgroundColor: 'rgba(0, 0, 0, 0.8)',
        titleColor: 'black',
        bodyColor: 'black',
        usePointStyle: true,
        caretSize: 4,
        caretPadding: 8,
        displayColors: true,
        fontFamily: FontFamilySwitch().semiBold,
        padding: 10,
        borderWidth: 1,
        bodySpacing: 12,
        cornerRadius: 8,
        boxWidth: 40,
        yAlign: 'center',
        callbacks: {
          title: function (tooltipItem, data) {
            // Customize the title text
            return null
          },
          label: function (tooltipItem, data) {
            // Customize the label text
            return ((tooltipItem?.dataset?.tooltip_prefix ? (tooltipItem?.dataset?.tooltip_prefix + " ") : "") + tooltipItem.formattedValue)
          },
          afterLabel: function (tooltipItem, data) {
            // Customize additional label text after the main label
            return tooltipItem?.dataset?.label;
          },
          labelPointStyle: function (context) {
            return {
              pointStyle: 'circle',
              rotation: 0,
            };
          },
          // afterBody: function(tooltipItems, d) {
          //    let sum = 0;

          //   tooltipItems.forEach(function(tooltipItem) {
          //     sum += tooltipItem.parsed.y;
          //   });
          //   return `Total ${sum}`;
          // }  // return a string that you wish to append

          // footer:footer

        },

      },
      datalabels: {
        display: props?.datalabels ?? false,
        color: "#0F0C1D",
        formatter: Math.round,
        anchor: "end",
        offset: -25,
        align: "start",
        font: {
          weight: "bold",
          size: 14,
        },
      },
      annotation: {
        annotations: [
          {
            type: "line",
            mode: "vertical",
            scaleID: "y-axis-0",
            value: 10,
            borderColor: "red",
            borderWidth: 4,
            label: {
              enabled: false,
              content: "Test label",
            },
          },
        ],
      },
    },
    scale: {
      ticks: {
        font: {
          family: FontFamilySwitch().extraBold,
          size: 12,
        },
        precision: 0
      },
    },
    scales: props?.scale,
    type: "bar",
    position: "bottom",
    align: "center",
  };

  useEffect(() => {
    if (props?.datalabels) Chart.register([ChartDataLabels]);
    // eslint-disable-next-line
  }, []);


  const classes = useStyles(props);


  const constractData = {
    ...props?.data, datasets: props?.data?.datasets.map((x) => {
      return {
        ...x,
        barThickness: x?.barThickness ?? 40
      }
    })
  }

  return (
    <div style={{ position: "relative" }} className={classes.root}>
      <>
        {props?.line ? (
          // <div className={classes.lineChart}>
          <Line
            options={options}
            data={props?.data}
            width={100}
            height={props?.height ?? 220}
          />
          // </div>
        ) : (
          <Bar data={constractData}
            width={props?.width ?? 100}
            height={props?.height ?? 220}
            options={options}
            plugins={[ChartDataLabels]} />
        )}
      </>
    </div>
  );
};
