/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import {
  AlertProps,
  LocalStorageKeys,
  NetWorkCallMethods,
  accessCheckRender,
  getRoutePermissionNew,
  useWindowDimensions,
} from "../../utils";

import { withTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router";
import { withNavBars } from "../../HOCs";
import { CloseIconWithBG } from "../../assets";
import {
  AlertDialog,
  FormGenerator,
  SearchFilter,
  Subheader,
  TableWithPagination,
  TextBox,
  UseDebounce,
} from "../../components";
import { NewLoader } from "../../components/newLoader";
import { config } from "../../config";
import { AlertContext, AuthContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { MarketPlaceCard } from "./components";
import { Scroller } from "./components/scroller";
import { useStyles } from "./style";
import { Heading, Path, Type } from "./utils";
import { type } from "@testing-library/user-event/dist/cjs/utility/type.js";
import { isCancel } from "axios";
import { set } from "date-fns";

const MarketPlaceView = ({ t = () => false }) => {
  const initialStateKSA = {
    company: "",
    taxpayerProvidedId: "",
    model: "PA",
    crnNumber: "",
    emailId: "",
    vatName: "",
    vatNumber: "",
    branchName: "",
    branchIndustry: "",
    city: "",
    citySubDivision: "",
    street: "",
    plotIdentification: "",
    building: "",
    postalZone: "",
    otp: "",
    isDisable: true,
    payment_id: "",
  };
  const paymentInitialState = {
    is_common: false,
    is_custom: false,
    custom_key_id: "",
    custom_secret: "",
    isDisable: false,
  };
  const initialStateZoho = {
    company: "",
    emailId: "",
    organizationId: "",
    clientId: "",
    clientSecret: "",
    isDisable: false,
  };
  const initialStateWhatsApp = {
    company: "",
    emailId: "",
    accessToken: "",
    businessId: "",
    phoneNumberId: "",
    isDisable: false,
  };

  const classes = useStyles();
  const navigate = useNavigate();
  const size = useWindowDimensions();
  const { state } = useLocation();
  const debounce = UseDebounce();

  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [permission, setPermission] = React.useState({});
  const [tableData, setTableData] = React.useState({
    isDisable: false,
    row: [],
    totalRowsCount: 0,
  });
  const [detailsData, setDetailsData] = React.useState([]);
  const [popupData, setPopupData] = React.useState([]);
  const [data, setData] = React.useState({ ...initialStateKSA });
  const [drawerOpen, setDrawerOpen] = React.useState({
    table: false,
    form: false,
    open: false,
  });
  const [loading, setLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [configurationType, setConfigurationType] = React.useState("");
  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        getDetailsByID(state?.id);
        getConfigureDetails(state?.data);
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  const getInitialState = {
    1: initialStateZoho,
    4: initialStateKSA,
    3: paymentInitialState,
    2: initialStateWhatsApp,
  };

  // use effect to get permission
  React.useEffect(() => {
    if (popupData?.id && popupData?.drawer_type) {
      getData();
      setData({ ...getInitialState[popupData?.drawer_type] });
    }
    // eslint-disable-next-line
  }, [popupData]);

  //update data
  const updateState = (key, value) => {
    const newData = { ...data, [key]: value };
    let error = { ...data.error };
    if (error[key]) {
      delete error[key];
    }
    if (popupData?.drawer_type === 3) {
      // Check if key is 'is_custom'
      if (newData?.is_custom) {
        const isValidate = ["custom_key_id", "custom_secret"].every(
          (val) => newData[val]?.length > 0
        );
        setData({ ...newData, isDisable: !isValidate });
      } else {
        setData({ ...newData, isDisable: false });
      }
    }
    if (popupData?.drawer_type === 1) {
      if (newData?.organizationId?.length >= 0) {
        setData({ ...newData, isDisable: false });
      }
    }
    if (popupData?.drawer_type === 4) {
      // For other keys, check if all values have length greater than 0
      const allValuesFilled = [
        "taxpayerProvidedId",
        "model",
        "crnNumber",
        "emailId",
        "vatName",
        "vatNumber",
        "branchName",
        "branchIndustry",
        "city",
        "citySubDivision",
        "street",
        "plotIdentification",
        "building",
        "postalZone",
        "otp",
      ].every((val) => newData[val]?.length > 0);
      setData({ ...newData, isDisable: !allValuesFilled });
    }
    if (popupData?.drawer_type === 2) {
      if (newData?.accessToken?.length >= 0) {
        setData({ ...newData, isDisable: false, error });
      }
      if (newData?.businessId?.length >= 0) {
        setData({ ...newData, isDisable: false, error });
      }
      if (newData?.phoneNumberId?.length >= 0) {
        setData({ ...newData, isDisable: false, error });
      } else {
        setData({ ...newData, error });
      }
    }
  };

  const getConfigureDetails = (data) => {
    let payload = {
      client_id: localStorage.getItem(LocalStorageKeys.clinetID),
      company_id: data?.company?.id,
    };
    NetworkCall(
      `${config.api_url}/whatsapp_integration/get_configure_details`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setData({
          isConfigured: res?.data?.data?.is_configured,
          accessToken: res?.data?.data?.access_token,
          businessId: res?.data?.data?.business_id,
          phoneNumberId: res?.data?.data?.phonenumber_id,
          company: res?.data?.data?.company,
          emailId: res?.data?.data?.email_id,
        });
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
      });
  };

  const getDetailsByID = (id) => {
    if (id) {
      setLoading(true);
      let datas = { addon_category_id: id };
      NetworkCall(
        `${config.api_url}/subscription/get_addon_category`,
        NetWorkCallMethods.post,
        datas,
        null,
        true,
        false
      )
        .then((res) => {
          setDetailsData(res?.data?.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Internal error. Please try again later."),
          });
        });
    }
  };
  const getData = (offset = 0, limit = 10, search = "") => {
    setTableData({
      ...tableData,
      isDisable: true,
    });
    let datas = {
      offset: offset,
      limit: limit,
      search: search,
      addon_id: popupData?.id,
      drawer_type: popupData?.drawer_type,
      client: localStorage.getItem(LocalStorageKeys.clinetID),
    };
    let url;
    if (popupData?.drawer_type === 3) {
      url = `${config.payment_gateway_url}/payment_gateway_master/get_payment_config`;
    } else if (popupData?.drawer_type === 2) {
      url = `${config.api_url}/whatsapp_integration/get_configure_details`;
    } else {
      url = `${config.api_url}/company/get_all_company`;
    }
    NetworkCall(url, NetWorkCallMethods.post, datas, null, true, false)
      .then((res) => {
        setTableData({
          row: res?.data?.data?.data,
          totalRowsCount: res?.data?.data?.count,
          isDisable: false,
        });
        if (popupData?.drawer_type === 2 && res?.data?.data?.is_configured) {
          setData({
            isConfigured: res?.data?.data?.is_configured,
            accessToken: res?.data?.data?.access_token,
            businessId: res?.data?.data?.business_id,
            phoneNumberId: res?.data?.data?.phonenumber_id,
            company: res?.data?.data?.company_name,
            emailId: res?.data?.data?.email_id,
          });
        }
      })
      .catch((error) => {
        setTableData({
          ...tableData,
          row: [],
          totalRowsCount: 0,
          isDisable: false,
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
      });
  };

  const handleClick = (data, item) => {
    if (data?.configuration_type !== "client") {
      setDrawerOpen({ table: true, form: false, open: true });
      setPopupData({ ...data, drawer_type: item?.id });
      setConfigurationType(data?.configuration_type);
    }
  };

  const handleConfigureClick = (data) => {
    setDrawerOpen({ table: false, form: true, open: true });
    setPopupData({ ...data, drawer_type: 2 });
    setData(data);
    setConfigurationType("client");
  };

  const validate = () => {
    let isValid = true;
    let error = data.error || {};
    if (data?.accessToken?.length === 0) {
      isValid = false;
      error.accessToken = t("Access Token is Required");
    }
    if (data?.businessId?.length === 0) {
      isValid = false;
      error.businessId = t("Business Id is Required");
    }
    if (data?.phoneNumberId?.length === 0) {
      isValid = false;
      error.phoneNumberId = t("Phone Number Id is Required");
    }
    setData({ ...data, error });
    return isValid;
  };

  //on submit form
  const validateZohoForm = () => {
    const requiredFields = ["organizationId", "clientId", "clientSecret"];
    let isValid = true;
    let newData = { ...data };

    requiredFields.forEach((field) => {
      if (!newData[field]) {
        newData = {
          ...newData,
          error: { ...newData.error, [field]: t("This field is required") },
        };
        isValid = false;
      } else {
        newData = { ...newData, error: { ...newData.error, [field]: "" } };
      }
    });

    setData(newData);
    return isValid;
  };
  const onSubmit = () => {
    if (popupData?.drawer_type === 4) {
      setData({ ...data, isDisable: true });
      let datas = {
        ...data,
        invoiceType: "Both",
        location: {
          city: data?.city,
          citySubDivision: data?.citySubDivision,
          street: data?.street,
          plotIdentification: data?.plotIdentification,
          building: data?.building,
          postalZone: data?.postalZone,
          countryCode: "SA",
        },
        otp: data?.otp,
      };
      NetworkCall(
        `https://dev-einvoicer-api.propertyautomate.com/api/v1/egs_unit/`,
        NetWorkCallMethods.put,
        datas,
        null,
        true,
        false
      )
        .then((res) => {
          setLimit(0);
          updateDetailsInCompany();
        })
        .catch((error) => {
          setData({ ...data, isDisable: false });
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Internal error. Please try again later."),
          });
        });
    } else if (popupData?.drawer_type === 3) {
      setData({ ...data, isDisable: true });
      let datas = {
        addon_id: popupData?.id,
        name: popupData?.name,
        custom_key_id: data?.custom_key_id ?? undefined,
        custom_secret: data?.custom_secret ?? undefined,
        is_custom: data?.is_custom,
        is_common: data?.is_common,
        company_id: data?.company?.id,
        client: localStorage.getItem(LocalStorageKeys.clinetID),
        config: popupData?.configure,
        payment_id: data?.payment_id,
      };
      NetworkCall(
        `${config.payment_gateway_url}/payment_gateway_master/create_payment_config`,
        NetWorkCallMethods.post,
        datas,
        null,
        true,
        false
      )
        .then((res) => {
          setDrawerOpen({ ...drawerOpen, table: true, form: false });
          setLimit(0);
          getData(0, 10, "");
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: t("Configured Successfully"),
          });
          setData({ ...initialStateKSA, isDisable: false });
        })
        .catch((error) => {
          setData({ ...data, isDisable: false });
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Internal error. Please try again later."),
          });
        });
    } else if (popupData?.drawer_type === 1) {
      if (!validateZohoForm()) return;
      setData({ ...data, isDisable: true });
      let datas = {
        company_id: data?.company?.id,
        email_id: data?.emailId,
        organization_id: data?.organizationId,
      };
      if (!data?.company?.is_configured) {
        let client = data?.clientId;
        let clientSecret = data?.clientSecret;
        let clientId = localStorage.getItem(LocalStorageKeys.clinetID);
        let companyId = data?.company?.id;
        let slugId = localStorage.getItem(LocalStorageKeys.slug);
        let orgId = data?.organizationId;
        let emailId = data?.emailId;

        let url = `https://accounts.zoho.in/oauth/v2/auth?scope=ZohoBooks.fullaccess.all&client_id=${client}&state=${clientId}*${companyId}*${slugId}*${orgId}*${emailId}*${client}*${clientSecret}&response_type=code&redirect_uri=${config.integration_uri}/external/zoho/sync&access_type=offline&prompt=consent` ;
        window.location.href = url;
      } else {
        let datas = {
          slug_id: localStorage.getItem(LocalStorageKeys.slug),
          client: localStorage.getItem(LocalStorageKeys.clinetID),
          company_id: String(data?.company?.id),
          email_id: data?.emailId,
          org_id: data?.organizationId,
          client_id: data?.clientId,
          client_secret: data?.clientSecret,
        };
        NetworkCall(
          `${config.api_url}/company/update_config`,
          NetWorkCallMethods.post,
          datas,
          null,
          true,
          false
        )
          .then((res) => {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.success,
              msg: t("Configured updated Successfully"),
            });
            setData({ ...data, isDisable: false });
            setDrawerOpen({ table: false, form: false, open: false });
          })
          .catch((error) => {
            setData({ ...data, isDisable: false });
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: t("Internal error. Please try again later."),
            });
          });
      }
    } else if (popupData?.drawer_type === 2) {
      setData({ ...data, isDisable: true });
      if (!validate()) {
        alert.setSnack({
          ...alert,
          open: false,
          severity: AlertProps.severity.error,
          msg: t("Please fill in all required fields"),
        });
        return;
      }

      const payload = {
        client_id: localStorage.getItem(LocalStorageKeys.clinetID),
        company_id: data.company?.id,
        company_name: data.company?.name,
        email_id: data.emailId,
        access_token: data.accessToken,
        business_id: data.businessId,
        phonenumber_id: data.phoneNumberId,
      };

      if (data?.isConfigured) {
        getConfigureDetails(data);
        NetworkCall(
          `${config.api_url}/whatsapp_integration/configure`,
          NetWorkCallMethods.post,
          payload,
          null,
          true,
          false
        )
          .then((res) => {
            setDrawerOpen({
              ...drawerOpen,
              table: false,
              form: false,
              open: false,
            });
            setLimit(0);
            getData(0, 10, "");
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.success,
              msg: t("WhatsApp configuration updated successfully"),
            });
            setData({ ...initialStateWhatsApp, isDisable: false });
          })
          .catch((error) => {
            console.error("WhatsApp Configuration Update Error:", error);
            setData({ ...data, isDisable: false });
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: t(
                error?.message ||
                  "Failed to update WhatsApp configuration. Please try again."
              ),
            });
          });
      } else {
        NetworkCall(
          `${config.api_url}/whatsapp_integration/configure`,
          NetWorkCallMethods.post,
          payload,
          null,
          true,
          false
        )
          .then((res) => {
            setDrawerOpen({
              ...drawerOpen,
              table: false,
              form: false,
              open: false,
            });
            setLimit(0);
            getData(0, 10, "");
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.success,
              msg: t("WhatsApp configured successfully"),
            });
            setData({ ...initialStateWhatsApp, isDisable: false });
          })
          .catch((error) => {
            console.error("WhatsApp Configuration Error:", error);
            setData({ ...data, isDisable: false });
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: t(
                error?.message ||
                  "Failed to configure WhatsApp. Please try again."
              ),
            });
          });
      }
    } else {
      return false;
    }
  };
  const updateDetailsInCompany = () => {
    let datas = {
      company_id: data?.company?.id,
      configuration_value:
        data?.company?.configuration_value?.length > 0
          ? JSON.stringify([
              ...data?.company?.configuration_value,
              {
                taxpayerProvidedId: data?.taxpayerProvidedId,
                model: data?.model,
                crnNumber: data?.crnNumber,
                vatName: data?.vatName,
                vatNumber: data?.vatNumber,
                branchName: data?.branchName,
                branchIndustry: data?.branchIndustry,
                emailId: data?.emailId,
                invoiceType: "Both",
                add_on: {
                  name: popupData?.name,
                  id: popupData?.id,
                  is_system_addon: popupData?.is_system_addon,
                  is_integration: popupData?.is_integration,
                },
                is_configured: true,
                location: {
                  city: data?.city,
                  citySubDivision: data?.citySubDivision,
                  street: data?.street,
                  plotIdentification: data?.plotIdentification,
                  building: data?.building,
                  postalZone: data?.postalZone,
                  countryCode: "SA",
                },
                otp: data?.otp,
              },
            ])
          : JSON.stringify([
              {
                taxpayerProvidedId: data?.taxpayerProvidedId,
                model: data?.model,
                crnNumber: data?.crnNumber,
                vatName: data?.vatName,
                vatNumber: data?.vatNumber,
                branchName: data?.branchName,
                branchIndustry: data?.branchIndustry,
                emailId: data?.emailId,
                invoiceType: "Both",
                add_on: {
                  name: popupData?.name,
                  id: popupData?.id,
                  is_system_addon: popupData?.is_system_addon,
                  is_integration: popupData?.is_integration,
                },
                is_configured: true,
                location: {
                  city: data?.city,
                  citySubDivision: data?.citySubDivision,
                  street: data?.street,
                  plotIdentification: data?.plotIdentification,
                  building: data?.building,
                  postalZone: data?.postalZone,
                  countryCode: "SA",
                },
                otp: data?.otp,
              },
            ]),
    };
    NetworkCall(
      `${config.api_url}/company/update_company`,
      NetWorkCallMethods.post,
      datas,
      null,
      true,
      false
    )
      .then((res) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Configured Successfully"),
        });
        setData({ ...data, isDisable: false });
        setDrawerOpen({ table: false, form: false, open: false });
      })
      .catch((error) => {
        setData({ ...data, isDisable: false });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
      });
  };
  const handleOccupantSearch = (e) => {
    setSearchText(e);
    debounce(() => searchTableFunction(e), 800);
  };

  const searchTableFunction = (e) => {
    getData(0, limit, e);
  };

  const handleTablePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getData(offset, limit, searchText);
  };

  const handleTablePageLimit = (value) => {
    setLimit(value);
    getData(0, value, searchText);
  };
  const handleConfiguration = (value) => {
    setData({
      ...data,
      company: value,
      emailId: value?.company_address[0]?.email_id,
      crnNumber: value?.cr_no,
      vatNumber: value?.tax_registration_number,
      branchName: value?.branch_name,
      street: value?.company_address?.[0]?.street_1,
      city: value?.company_address?.[0]?.city,
      citySubDivision: value?.company_address?.[0]?.state,
      plotIdentification: value?.company_address?.[0]?.door_no,
      building: value?.company_address?.[0]?.street_2,
      postalZone: value?.company_address?.[0]?.zipcode,
      organizationId: value?.config_data?.org_id ?? "",
      accessToken: value?.config_data?.access_token ?? "",
      businessId: value?.config_data?.business_id ?? "",
      phoneNumberId: value?.config_data?.phone_number_id ?? "",
    });
    setDrawerOpen({ table: false, form: true, open: true });
  };

  //form data
  const formData = [
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      label: t("Company"),
      value: data?.company?.name,
      placeholder: t("Company"),
      onChange: (value) => updateState("company", value),
      error: data?.error?.company,
      isReadonly: true,
    },
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      label: t("Taxpayer Provided Id"),
      value: data?.taxpayerProvidedId,
      placeholder: t("Taxpayer Provided Id"),
      onChange: (value) =>
        updateState("taxpayerProvidedId", value?.target.value),
      error: data?.error?.taxpayerProvidedId,
    },
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      label: t("Email Id"),
      value: data?.emailId,
      placeholder: t("Email Id"),
      onChange: (value) => updateState("emailId", value?.target.value),
      error: data?.error?.emailId,
    },
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      label: t("Model"),
      value: data?.model,
      placeholder: t("Model"),
      onChange: (value) => updateState("model", value?.target.value),
      error: data?.error?.model,
    },
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      label: t("Crn Number"),
      value: data?.crnNumber,
      placeholder: t("Crn Number"),
      onChange: (value) => updateState("crnNumber", value?.target.value),
      error: data?.error?.crnNumber,
    },
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      label: t("Vat Name"),
      value: data?.vatName,
      placeholder: t("Vat Name"),
      onChange: (value) => updateState("vatName", value?.target.value),
      error: data?.error?.vatName,
    },
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      label: t("Vat Number"),
      value: data?.vatNumber,
      placeholder: t("Vat Number"),
      onChange: (value) => updateState("vatNumber", value?.target.value),
      error: data?.error?.vatNumber,
    },
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      label: t("Branch Name"),
      value: data?.branchName,
      placeholder: t("Branch Name"),
      onChange: (value) => updateState("branchName", value?.target.value),
      error: data?.error?.branchName,
    },
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      label: t("Branch Industry"),
      value: data?.branchIndustry,
      placeholder: t("Branch Industry"),
      onChange: (value) => updateState("branchIndustry", value?.target.value),
      error: data?.error?.branchIndustry,
    },
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      label: t("City"),
      value: data?.city,
      placeholder: t("City"),
      onChange: (value) => updateState("city", value?.target.value),
      error: data?.error?.city,
    },
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      label: t("City Sub Division"),
      value: data?.citySubDivision,
      placeholder: t("City Sub Division"),
      onChange: (value) => updateState("citySubDivision", value?.target.value),
      error: data?.error?.citySubDivision,
    },
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      label: t("Street"),
      value: data?.street,
      placeholder: t("Street"),
      onChange: (value) => updateState("street", value?.target.value),
      error: data?.error?.street,
    },
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      label: t("Plot Identification"),
      value: data?.plotIdentification,
      placeholder: t("Plot Identification"),
      onChange: (value) =>
        updateState("plotIdentification", value?.target.value),
      error: data?.error?.plotIdentification,
    },
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      label: t("Building"),
      value: data?.building,
      placeholder: t("Building"),
      onChange: (value) => updateState("building", value?.target.value),
      error: data?.error?.building,
    },
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      label: t("Postal Zone"),
      value: data?.postalZone,
      placeholder: t("Postal Zone"),
      onChange: (value) => updateState("postalZone", value?.target.value),
      error: data?.error?.postalZone,
    },
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      label: t("Otp"),
      value: data?.otp,
      placeholder: t("Otp"),
      onChange: (value) => updateState("otp", value?.target.value),
      error: data?.error?.otp,
    },
  ];
  const zohoFormData = [
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      label: t("Company Name"),
      value: data?.company?.name,
      placeholder: t("Company Name"),
      onChange: (value) => updateState("company", value),
      error: data?.error?.company,
      isReadonly: true,
      isRequired: true,
    },
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      label: t("Email Id"),
      value: data?.emailId,
      placeholder: t("Email Id"),
      onChange: (value) => updateState("emailId", value),
      error: data?.error?.emailId,
      isReadonly: true,
      isRequired: true,
    },
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      value: data?.organizationId,
      label: t("Organization ID"),
      onChange: (value) => updateState("organizationId", value?.target.value),
      error: data?.error?.organizationId,
      isRequired: true,
    },
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      value: data?.clientId,
      label: t("Client ID"),
      onChange: (value) => updateState("clientId", value?.target.value),
      error: data?.error?.clientId,
      isRequired: true,
    },
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      value: data?.clientSecret,
      label: t("Client Secret"),
      onChange: (value) => updateState("clientSecret", value?.target.value),
      error: data?.error?.clientSecret,
      isRequired: true,
    },
  ];
  const paymentGatewayformData = [
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "toggleButton",
      label: t("Custom"),
      value: data?.is_custom,
      placeholder: t("Custom"),
      onChange: (value) => {
        updateState("is_custom", value);
      },
      error: data?.error?.is_custom,
      disabled: popupData?.is_custom ? (data?.is_common ? true : false) : true,
    },
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "toggleButton",
      label: t("Common"),
      value: data?.is_common,
      placeholder: t("Common"),
      onChange: (value) => updateState("is_common", value),
      error: data?.error?.is_common,
      disabled: popupData?.is_common ? (data?.is_custom ? true : false) : true,
    },
    {
      size: {
        xs: 12,
      },
      isActive: true,
      component: "text",
      label: t("Name"),
      value: popupData?.name,
      placeholder: t("Name"),
      onChange: (value) => updateState("company", value),
      isReadonly: true,
    },
    {
      size: {
        xs: 6,
      },
      isActive: data?.is_custom ? true : false,
      component: "text",
      label: t("Custom Key Id"),
      value: data?.custom_key_id,
      placeholder: t("Custom Key Id"),
      onChange: (value) => updateState("custom_key_id", value?.target.value),
      error: data?.error?.custom_key_id,
    },
    {
      size: {
        xs: 6,
      },
      isActive: data?.is_custom ? true : false,
      component: "text",
      label: t("Custom Secret"),
      value: data?.custom_secret,
      placeholder: t("Custom Secret"),
      onChange: (value) => updateState("custom_secret", value?.target.value),
      error: data?.error?.custom_secret,
    },
  ];
  const companyWhatsappFormData = [
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      label: t("Company Name"),
      value: data?.company?.name,
      placeholder: t("Company Name"),
      onChange: (value) => updateState("company", value),
      error: data?.error?.company,
      isReadonly: true,
      isRequired: true,
    },
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      label: t("Email Id"),
      value: data?.emailId,
      placeholder: t("Email Id"),
      onChange: (value) => updateState("emailId", value),
      error: data?.error?.emailId,
      isReadonly: true,
      isRequired: true,
    },
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      value: data?.accessToken,
      label: t("Access Token"),
      onChange: (value) => updateState("accessToken", value?.target.value),
      error: data?.error?.accessToken,
      isRequired: true,
    },
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      value: data?.businessId,
      label: t("Business Id"),
      onChange: (value) => updateState("businessId", value?.target.value),
      error: data?.error?.businessId,
      isRequired: true,
      type: "number",
    },
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      value: data?.phoneNumberId,
      label: t("Phone Number Id"),
      onChange: (value) => updateState("phoneNumberId", value?.target.value),
      error: data?.error?.phoneNumberId,
      isRequired: true,
      type: "number",
    },
  ];
  const clientWhatsappFormData = [
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      value: data?.accessToken,
      label: t("Access Token"),
      onChange: (value) => updateState("accessToken", value?.target.value),
      error: data?.error?.accessToken,
      isRequired: true,
    },
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      value: data?.businessId,
      label: t("Business Id"),
      onChange: (value) => updateState("businessId", value?.target.value),
      error: data?.error?.businessId,
      isRequired: true,
      type: "number",
    },
    {
      size: {
        xs: 6,
      },
      isActive: true,
      component: "text",
      value: data?.phoneNumberId,
      label: t("Phone Number Id"),
      onChange: (value) => updateState("phoneNumberId", value?.target.value),
      error: data?.error?.phoneNumberId,
      isRequired: true,
      type: "number",
    },
  ];

  const getFormData = {
    1: zohoFormData,
    4: formData,
    3: paymentGatewayformData,
    2:
      configurationType === "client"
        ? clientWhatsappFormData
        : companyWhatsappFormData,
  };
  const handleIcon = (type, value) => {
    if (type === "edit") {
      setData({
        ...data,
        company: value,
        emailId: value?.company_address?.[0]?.email_id,
        crnNumber: value?.cr_no,
        vatNumber: value?.tax_registration_number,
        branchName: value?.branch_name,
        street: value?.company_address?.[0]?.street_1,
        city: value?.company_address?.[0]?.city,
        citySubDivision: value?.company_address?.[0]?.state,
        plotIdentification: value?.company_address?.[0]?.door_no,
        building: value?.company_address?.[0]?.street_2,
        postalZone: value?.company_address?.[0]?.zipcode,
        name: value?.payment_config?.name,
        custom_key_id: value?.payment_config?.is_custom
          ? value?.payment_config?.key_id
          : null,
        custom_secret: value?.payment_config?.is_custom
          ? value?.payment_config?.secret
          : null,
        is_custom: value?.payment_config?.is_custom,
        is_common: value?.payment_config?.is_common,
        company_id: value?.payment_config?.company_id,
        client: value?.payment_config?.client_id,
        payment_id: value?.payment_config?.id,
        organizationId: value?.config_data?.[0]?.org_id ?? "",
        clientId: value?.client_id,
        clientSecret: value?.client_secret,
        accessToken: value?.accessToken ?? "",
        businessId: value?.businessId ?? "",
        phoneNumberId: value?.phoneNumberId ?? "",
      });
      setDrawerOpen({ table: false, form: true, open: true });
    }
  };
  //dialog content
  // const dialogContent = () => {
  //render
  const render = () => {
    return (
      <>
        <Subheader
          title={detailsData?.name ?? ""}
          isReadOnly
          goBack={() => navigate(-1)}
          value={{}}
          onchange={() => false}
        />
        <Box className={classes.box2}>
          {loading ? (
            <NewLoader />
          ) : (
            <Grid container spacing={2} p={2}>
              {detailsData?.addon_sub_categories?.map((item) => {
                return (
                  <>
                    <Grid item xs={12}>
                      <Box>
                        <Typography className={classes.header}>
                          {item?.name}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className={classes.subHeader}>
                          {item?.description}
                        </Typography>
                      </Box>
                      <Box position={"relative"}>
                        <Scroller isOther={3}>
                          <Grid container spacing={2}>
                            {item?.add_ons?.map((i) => {
                              return (
                                <>
                                  <Grid item xs={3}>
                                    <MarketPlaceCard
                                      data={{
                                        ...i,
                                        isConfigured: data?.isConfigured,
                                      }}
                                      item={item}
                                      master={false}
                                      onClick={(data, item) => {
                                        handleClick(data, item);
                                      }}
                                      onConfigureClick={handleConfigureClick}
                                      handleIcon={handleIcon}
                                    />
                                  </Grid>
                                </>
                              );
                            })}
                          </Grid>
                        </Scroller>
                      </Box>
                      <Grid
                        item
                        xs={12}
                        height={"6px"}
                        bgcolor={"#E4E8EE"}
                        marginTop={"16px"}
                      ></Grid>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          )}
          <Drawer
            anchor={"right"}
            open={drawerOpen?.open}
            onClose={() => {
              setTableData({
                isDisable: false,
                row: [],
                totalRowsCount: 0,
              });
              setDrawerOpen({ table: false, form: false, open: false });
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              p={1.5}
              alignItems="center"
              sx={{ backgroundColor: "#F2F4F7" }}
            >
              <Box
                onClick={() =>
                  setDrawerOpen({ table: false, form: false, open: false })
                }
                style={{ cursor: "pointer" }}
              >
                <CloseIconWithBG />
              </Box>
              <Typography className={classes.header}>
                {"Configuration Details"}
              </Typography>
            </Stack>
            <Divider />
            <Box sx={{ width: "550px" }} p={2}>
              {drawerOpen?.table && (
                <>
                  <Box sx={{ width: 340 }}>
                    {/*search */}
                    <SearchFilter
                      label={false}
                      placeholder={t("Search")}
                      handleChange={(value) => handleOccupantSearch(value)}
                      value={searchText}
                      customfieldSx={{
                        "& .MuiOutlinedInput-root": { height: "40px" },
                      }}
                    />
                  </Box>
                  <TableWithPagination
                    is_loading={tableData?.isDisable}
                    heading={Heading(t)}
                    rows={tableData?.row}
                    path={Path}
                    showpagination={true}
                    showpdfbtn={false}
                    showexcelbtn={false}
                    showSearch={false}
                    handleConfiguration={handleConfiguration}
                    tableType="no-side"
                    dataType={Type}
                    handlePagination={handleTablePagination}
                    handleChangeLimit={handleTablePageLimit}
                    totalRowsCount={tableData?.totalRowsCount}
                    page={page}
                    limit={limit}
                    height={"calc(100vh - 205px)"}
                    view={true}
                    edit={true}
                    delete={true}
                    noDataText={"No Company Found"}
                    handleIcon={handleIcon}
                  />
                </>
              )}

              {drawerOpen?.form && (
                <>
                  <Box style={{ height: size?.height - 140 }}>
                    <FormGenerator
                      components={getFormData[popupData?.drawer_type]}
                    />
                    <br />
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={onSubmit}
                      disabled={
                        popupData?.drawer_type === 3
                          ? data?.isDisable
                            ? true
                            : data?.is_common || data?.is_custom
                            ? false
                            : true
                          : data?.isDisable
                      }
                    >
                      {popupData?.drawer_type === 2
                        ? data?.isConfigured
                          ? t("Update")
                          : t("Configure")
                        : data?.company?.is_configured
                        ? t("Update")
                        : t("Configure")}
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Drawer>
        </Box>
      </>
    );
  };

  return <div>{accessCheckRender(render, permission)}</div>;
};
const props = {
  boxShadow: false,
};
export default withNavBars(
  withTranslation("functionalLocation")(MarketPlaceView),
  props
);
