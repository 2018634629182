import { Badge, Box, Divider, Grid, IconButton, Paper, Stack } from "@mui/material"
import React, { useState, useContext } from "react"
import { withTranslation } from "react-i18next"
import { AlertDialog, FilterGenerator, SearchFilter, Subheader, TableWithPagination } from "../../components"
import { NewLoader } from "../../components/newLoader"
import { config } from "../../config"
import { AlertContext, AuthContext, BackdropContext } from "../../contexts"
import { NetworkCall } from "../../networkcall"
import { AlertProps, FeedBackDataType, FeedBackHeading, FeedBackPath, NetWorkCallMethods, accessCheckRender, getCompanyOption, getRoutePermissionNew } from "../../utils"
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions"
import { useStyles } from "./style"
import { ViewFeedBack } from "./viewFeedBack"

const CustomerFeedBack = ({ t }) => {
    const [loading, setLoading] = React.useState(false)
    const [searchText, setSearchText] = React.useState("")
    const [viewDetails, setViewDetails] = useState([])
    const [tableData, setTableData] = React.useState({
        list: [],
        count: 0,
        isView: false,
        data: {}
    })
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const alert = useContext(AlertContext)
    const backdrop = React.useContext(BackdropContext)
    const auth = React.useContext(AuthContext)
    const [permission, setPermission] = useState({})
    const [companyList, setCompanyList] = useState([])
    const [selectedCompany, setSelectedCompany] = useState({})
    const [drawer, setDrawer] = useState(false)
    const [filterData, setFilterData] = useState({
        contact: null
    })
    const classes = useStyles()
    const getFeedBackList = (offset = 0, limit = 10, companyID, contact, search = "") => {
        const payload = {
            "company_id": companyID,
            "contact": contact,
            "start": offset,
            "length": limit,
            "search": search
        };
        NetworkCall(
            `${config.api_url}/customer_feedback_replies`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
                const result = res?.data?.data?.map((val) => {
                    return {
                        ...val,
                        job: val?.inspection_type
                    }
                })
                setTableData({ list: result, count: res?.data?.count })
                setLoading(false)
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                setLoading(false)
            })
    }
    const getViewDetails = (reply_id) => {
        const payload = {
            "replay_id": reply_id
        }
        NetworkCall(
            `${config.api_url}/customer_feedback_values/get_values`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                setViewDetails(res?.data?.data)
            })
    }
    React.useEffect(() => {
        let company = getCompanyOption(backdrop, auth, alert)
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (company && perm?.read) {
                setCompanyList(company?.list)
                setSelectedCompany(company?.selected)
                getFeedBackList(0, 10, company?.selected?.value, [], "")
            }
        }
        // eslint-disable-next-line
    }, [auth])

    //handleCompanyChange
    const handleCompanyChange = (val) => {
        setSelectedCompany(val)
        getFeedBackList(0, 10, val?.value, [], "")

    }
    //handleSearch
    const handleSearch = (value) => {
        setSearchText(value)
        getFeedBackList(0, 10, selectedCompany?.value, [], value)

    }
    //handle Icon
    const handleIcon = (type, data) => {
        setTableData({ ...tableData, isView: true, data: data })
        getViewDetails(data?.id)
    }
    //handlePagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getFeedBackList(offset, 10, selectedCompany?.value, [], "")
    }
    //handleChangeLimit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getFeedBackList(0, value, selectedCompany?.value, [], "")

    }
    const openfilter = () => {
        setDrawer(true)
    }
    const onApplyFilter = (val) => {
        setFilterData(val)
        getFeedBackList(0, 10, selectedCompany?.value, val?.contact?.map((x) => { return x.value }), "")
    }
    const onCloseModal = () => {
        setTableData({ ...tableData, isView: false })
    }
    const manualResponse = (array) => {
        const details = array?.contact?.map((i) => {
            return {
                label: i?.label,
                value: i?.id
            }
        })
        return details
    };
    const render = () => {
        return (
            <Box>
                <Subheader
                    hideBackButton={true}
                    title={t("feedBack")}
                    select
                    options={companyList}
                    value={selectedCompany}
                    onchange={(e) => {
                        handleCompanyChange(e)
                    }}
                />
                {
                    loading ?
                        <NewLoader minusHeight="158px" />
                        :
                        <Paper className={classes.root}>
                            <Grid container justifyContent="space-between">
                                <Grid item xs={4}>
                                    {/*search */}
                                    <SearchFilter
                                        handleChange={handleSearch}
                                        value={searchText}
                                        placeholder={t("search")}
                                        customfieldSx={{height:'40px'}}
                                    />
                                </Grid>
                                <Grid item xs={8} alignContent="flex-end">
                                    <Stack
                                        divider={<Divider orientation="vertical" flexItem />}
                                        spacing={2}
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        direction="row">
                                        {/*add btn */}
                                        {
                                            filterData?.contact?.length > 0 ?
                                                <Badge variant="dot" color="primary">
                                                    <IconButton className={classes.iconButton} onClick={openfilter}>
                                                        <img src="/images/filter.svg" alt="filter" />
                                                    </IconButton>
                                                </Badge>
                                                :
                                                <IconButton className={classes.iconButton} onClick={openfilter}>
                                                    <img src="/images/filter.svg" alt="filter" />
                                                </IconButton>
                                        }


                                    </Stack>
                                </Grid>
                            </Grid>
                            <TableWithPagination
                                heading={FeedBackHeading(t)}
                                rows={tableData?.list}
                                dataType={FeedBackDataType}
                                path={FeedBackPath}
                                showpagination
                                tableType="no-side"
                                handleIcon={handleIcon}
                                handlePagination={handlePagination}
                                handleChangeLimit={handleChangeLimit}
                                totalRowsCount={tableData?.count}
                                page={page}
                                limit={limit}
                                view={permission?.read}
                                height={`calc(100vh - 300px)`}
                                edit={true}
                                delete={true} />
                        </Paper>
                }
                {/*filter component */}
                {drawer && (
                    <FilterGenerator
                        open={drawer}
                        onClose={() => setDrawer(false)}
                        components={[
                            {
                                component: "select",
                                value: filterData?.contact,
                                state_name: "contact",
                                label: t("Contact"),
                                loadOptions: (search, array, handleLoading) =>  loadOptionsApis(
                                    "queries/opportunity/contact/dropdown",
                                    {
                                        "company_id":selectedCompany?.value,
                                        "is_active":[true]
                                    },
                                    search,
                                    array,
                                    handleLoading,
                                    "data",
                                    { "custom_data": true },
                                    manualResponse
                                ),
                                isPaginate:true,
                                isMulti: true,
                                placeholder: t("Select Contact")
                            },

                        ]}
                        onApply={(value) => onApplyFilter(value)}
                    />
                )}
                <AlertDialog
                    width={"42%"}
                    header={`${tableData.data?.first_name} (${tableData.data?.email_id})`}
                    open={tableData?.isView}
                    onClose={onCloseModal}
                    component={<ViewFeedBack t={t} data={tableData?.data} details={viewDetails} onCloseModal={onCloseModal} />}
                />
            </Box>
        )
    }
    return (
        <>
            {accessCheckRender(render, permission)}
        </>

    )
}
export default withTranslation("feedBack")(CustomerFeedBack)