import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const blockStylesParent = makeStyles((theme) => ({
    tabtitle: {
        fontSize:"0.75rem",
        color: '#4E5A6B',
        fontFamily: FontFamilySwitch().bold,
    },
    tabtitle1: {
        fontSize:"0.75rem",
        color: '#5078E1',
        fontFamily: FontFamilySwitch().bold,
    },
    subtabtitle: {
        fontSize:"0.75rem",
        color: '#4E5A6B',
        fontFamily: FontFamilySwitch().regular,
    },
    subtabtitle1: {
        fontSize:"0.75rem",
        color: '#5078E1',
        fontFamily: FontFamilySwitch().regular,
    },
    selectBack1: {
        backgroundColor: '#F1F7FF',
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px"
    },
    selectBack: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
    },
}))