import React from "react";
import { withNavBars } from "../../HOCs";
import CompanyCreation from "./companyCreation";
import CompanyDetails from "./companyDetails";
import { useNavigate, useLocation, useParams, matchPath } from "react-router-dom";
import { Routes } from "../../router/routes";
class CompanyCreationParentOne extends React.Component {
  render() {
    const { location } = this.props;
    const path = location.pathname;
    return (
      <>
        {path === Routes.companyCreation && <CompanyCreation {...this.props} />}
        {matchPath(Routes.editcompany, path) &&
        location?.state?.from === "edit" ? (
          <CompanyCreation isEdit={true} {...this.props} />
        ) : (
          <>
            {matchPath(Routes.editcompany, path) && <CompanyDetails {...this.props} />}
          </>
        )}
      </>
    );
  }
}
const props = {
  boxShadow: false,
};

const CompanyCreationParent = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  return (
    <CompanyCreationParentOne
      {...props}
      navigate={navigate}
      location={location}
      params={params}
    />
  );
};

export default withNavBars(CompanyCreationParent, props);
