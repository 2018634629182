import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../../utils";

export const VenueCardStyle = makeStyles((theme) => ({
    root: {
        overflow: "auto",
        height: "430px"
    },
    box: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
        cursor: "pointer"
    },
    name: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
    },
    slot: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary
    },
    selectedbox: {
        border: `1px solid ${theme.palette.info.main}`,
        borderRadius: 4,
        backgroundColor: theme.palette.info.light,
        cursor: "pointer"
    }
}))

export const CalendarStyle = makeStyles((theme) => ({
    iconbutton: {
        border: `1px solid ${theme.palette.border.secondary}`,
        height: 34,
        width: 34,
        fontSize:"0.875rem"
    },
    day: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        textTransform: "uppercase",
        border: `1px solid ${theme.palette.border.secondary}`,
        padding: 4,
    },
    date_curr_month: {
        border: `1px solid ${theme.palette.border.secondary}`,
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        cursor: "pointer",
        height: 40,
    },
    date_not_curr_month: {
        border: `1px solid ${theme.palette.border.secondary}`,
        color: theme.palette.border.secondary,
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        cursor: "not-allowed",
        height: 40,
    },
    today: {
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.info.light,
        color: theme.palette.primary.main,
        // borderRadius: 4,
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        height: 39
    },
    dateTitle: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
    }
}))

export const AvailableSlotsStyle = makeStyles((theme) => ({
    box: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
        cursor: "pointer",
        height: 58
    },
    booked_box: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
        cursor: "pointer",
        background: theme.palette.background.tertiary,
        height: 58
    },
    selectedBox: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 4,
        cursor: "pointer",
        backgroundColor: "#5078E10D",
        height: 58
    },
    dot: {
        height: 4,
        width: 4,
        borderRadius: 50,
        backgroundColor: theme.palette.border.secondary
    },
    time: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().bold,
        marginTop: (props) => !props?.selectedVenue?.is_overbooking ? "8px" : "0px",
        direction: "ltr"
    },
    selectedTime: {
        fontSize:"0.75rem",
        color: theme.palette.primary.main,
        fontFamily: FontFamilySwitch().bold,
        marginTop: (props) => !props?.selectedVenue?.is_overbooking ? "8px" : "0px",
        direction: "ltr"
    },
    content: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().bold
    },
    selectedContent: {
        fontSize:"0.75rem",
        color: theme.palette.primary.main,
        fontFamily: FontFamilySwitch().bold
    },
    noneTitle: {
        fontSize:"0.875rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().bold
    },
    noneContent: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary
    },
    none: {
        justifyContent: "center",
        alignItems: "center",
        // height: "400px !important",
        height: "calc(100vh - 320px)",
        display: "flex"
        // position: "absolute",
        // top: "50%",
        // left: "50%",
        // transform: "translate(-50%,-50%)"
    },
    root_none: {
        // height: "100%"
        // position: "relative"
    },
    slot_not_select: {
        height: 400,
        overflow: "auto",
        padding: 4
    },
    slot_section: {
        height: 370,
        overflow: "auto",
        padding: 4
    },
    walkinBtn: {
        height: 48,
        '&:hover': {
            backgroundColor: "#fff"
        }
    },
    bookBtn: {
        height: 48
    },
    btnBox: {
        padding: 4,
        borderRadius: 4,
        border: `1px solid ${theme.palette.border.secondary}`,
        boxShadow: "0px 0px 16px #00000014"
    },
    notesBox: {
        background: theme.palette.info.light,
        borderRadius: 4
    },
    notesText: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().bold,
        whiteSpace: "noWrap"
    },
    overBookingLabel: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().semiBold,
        position: "absolute",
        top: "18px",
        right: "5px",
        zIndex: 100
    },
    peakBox: {
        borderRadius: 9,
        background: theme.palette.warning.light
    },
    peakBoxText: {
        color: theme.palette.warning.main,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        whiteSpace: "noWrap"
    },
    slotPeakBox: {
        position: "absolute",
        top: "2px",
        left: "50%",
        zIndex: 100,
        background: theme.palette.warning.light,
        height: 16,
        width: 24,
        display: "flex",
        justifyContent: "center",
        borderRadius: "9px",
        alignItems: "center"
    },
    slotTimeBooked: {
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().bold,
        fontSize:"0.75rem",
    },
    maintanceBox: {
        border: `1px solid ${theme.palette.warning.main}`,
        borderRadius: 4,
        cursor: "not-selected",
        backgroundColor: theme.palette.warning.light,
        height: 58
    },
    maintanceText: {
        color: theme.palette.warning.main,
        fontFamily: FontFamilySwitch().bold,
        fontSize:"0.75rem",
    },
    maintanceContent: {
        fontSize:"0.75rem",
        color: theme.palette.warning.main,
        fontFamily: FontFamilySwitch().bold
    },
}))

export const AmenityDropdownStyle = makeStyles((theme) => ({
    avatar: {
        height: 25,
        width: 25
    },
    dropdown: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 6,
        cursor: "pointer",
        // zIndex: 1,
        // position: "absolute",
        // left: "77px",
        backgroundColor: props => props?.listLength > 0 ? "#fff" : "#f1f1f1",
        // top: "130px",
        width: 200
    },
    amenity_content: {
        // border: `1px solid ${theme.palette.border.secondary}`,
        // borderRadius: 6,
        // cursor: "pointer",
        // zIndex: 3,
        // position: "absolute",
        // left: "7%",
        // backgroundColor: "#fff",
        // top: "190px",
        width: 850,
        margin: 6,
        padding: "12px 12px 0px 12px"
    },
    name: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
        width: 108
    },
    view: {
        fontSize:"0.75rem",
        color: theme.palette.info.main
    },
    card: {
        borderRadius: theme.palette.borderRadius,
        border: "1px solid transparent",
        backgroundColor: "transparent",
        padding: 4,
        width: "100%"
    },
    selected_card: {
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #5078E1",
        backgroundColor: "#F1F7FF",
        padding: 4,
        width: "100%"
    },
    customDialog: {
        // '& .MuiBackdrop-root': {
        //     backgroundColor: "transparent"
        // },
        // '& .MuiDialog-container': {
        //     position: "absolute",
        //     height: 300,
        //     top: 80,
        //     left: 55,
        //     width:"700px"
        // },
        // '& .MuiDialogContent-root': {
        //     position: "relative",
        //     width: "100%",
        //     padding: "8px 8px 4px"
        // }

        '& .MuiBackdrop-root': {
            backgroundColor: "transparent"
        },
        '& .MuiDialog-container': {
            position: "absolute",
            height: 300,
            top: 100,
            left: 55,
            width: "700px",
        },
        '& .MuiDialogContent-root': {
            position: "relative",
            width: "100%",
            padding: "8px",
            height: 130,
        }
    },
    value: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        width: 110
    },
}))

export const CourtDropdownStyle = makeStyles((theme) => ({
    avatar: {
        height: 25,
        width: 25
    },
    dropdown: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 6,
        cursor: "pointer",
        // zIndex: 3,
        // position: "absolute",
        // left: "285px",
        backgroundColor: props => props?.listLength > 0 ? "#fff" : "#f1f1f1",
        // top: "130px",
        width: 200
    },
    name: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    view: {
        fontSize:"0.75rem",
        color: theme.palette.info.main
    },
    court_content: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 6,
        cursor: "pointer",
        zIndex: 1,
        position: "absolute",
        left: "20%",
        backgroundColor: "#fff",
        top: "190px",
        width: 850,
        margin: 6,
        padding: 12
    },
    card: {
        padding: "4px",
        cursor: "pointer",
        border: "1px solid #fff",
    },
    selected_card: {
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #5078E1",
        backgroundColor: "#F1F7FF",
        padding: "4px",
        marginRight: "4px",
        cursor: "pointer"
    },
    customDialog: {
        '& .MuiBackdrop-root': {
            backgroundColor: "transparent"
        },
        '& .MuiDialog-container': {
            position: "absolute",
            height: 300,
            top: 100,
            left: 280,
            width: "700px",
        },
        '& .MuiDialogContent-root': {
            position: "relative",
            width: "100%",
            padding: "8px",
            height: 130,
        }
    },
    value: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        width: 150
    },
    gridContainer: {
        height: 200,
        overflow: "overlay"
    }
}))

export const DescriptionDropdownStyle = makeStyles((theme) => ({
    avatar: {
        height: 25,
        width: 25
    },
    dropdown: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 6,
        cursor: "pointer",
        // zIndex: 3,
        // position: "absolute",
        // left: "485px",
        backgroundColor: props => props?.listLength > 0 ? "#fff" : "#f1f1f1",
        // top: "130px",
        width: 200
    },
    name: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    view: {
        fontSize:"0.75rem",
        color: theme.palette.info.main
    },
    court_content: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 6,
        cursor: "pointer",
        zIndex: 1,
        position: "absolute",
        left: "20%",
        backgroundColor: "#fff",
        top: "190px",
        width: 850,
        margin: 6,
        padding: 12
    },
    card: {
        padding: "4px",
        cursor: "pointer",
        border: "1px solid #fff",
    },
    selected_card: {
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #5078E1",
        backgroundColor: "#F1F7FF",
        padding: "4px",
        marginRight: "4px",
        cursor: "pointer"
    },
    customDialog: {
        '& .MuiBackdrop-root': {
            backgroundColor: "transparent"
        },
        '& .MuiDialog-container': {
            position: "absolute",
            height: 300,
            top: 100,
            left: 500,
            width: "700px",
        },
        '& .MuiDialogContent-root': {
            position: "relative",
            width: "100%",
            padding: "8px",
            height: 130,
        }
    },
    value: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        width: 150
    },
    gridContainer: {
        height: 200,
        overflow: "overlay"
    }
}))

export const CalendarHeaderStyle = makeStyles((theme) => ({
    header: {
        height: 50,
        padding: 6,
    },
    dateChange: {
        display: "flex",
        justifyContent: "start",
        [theme.breakpoints.only('lg')]: {
            justifyContent: "end",
        },
        [theme.breakpoints.only('xl')]: {
            justifyContent: "end",
        },
    }
}))


export const AmenityPaymentDetailsStyle = makeStyles((theme) => ({
    name: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary,
        width: "150px"
    },
    rightname: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary,
        textAlign: "right"
    },
    id: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary
    },
    paid: {
        color: theme.palette.success.main,
        fontSize:"0.75rem",
        textAlign: "right"
    },
    unpaid: {
        color: theme.palette.warning.main,
        fontSize:"0.75rem",
        textAlign: "right",
        width: "150"
    },
    avatar: {
        height: 48,
        width: 48,
        borderRadius: 4
    },
    title: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        textAlign: "left",
        fontFamily: FontFamilySwitch().bold
    },
    content: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        textAlign: "right",
        fontFamily: FontFamilySwitch().bold
    },
    totaltitle: {
        fontSize:"0.75rem",
        color: theme.typography.color.primary,
        textAlign: "left",
        fontFamily: FontFamilySwitch().bold
    },
    totalcontent: {
        fontSize:"0.75rem",
        color: theme.typography.color.primary,
        textAlign: "right",
        fontFamily: FontFamilySwitch().bold
    },
    box: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
    },
    regularTitle: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().regular,
    },
    semiBoldTitle: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    totalTitle: {
        fontSize:"0.75rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    }
}))

export const AmenityDetailsStyle = makeStyles((theme) => ({
    avatar: {
        height: 42,
        width: 42,
        borderRadius: 4
    },
    name: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    content: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    title: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().bold,
        textTransform: "uppercase"
    },
    box: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
    }
}))

export const DeclineQRcodeStyle = makeStyles((theme) => ({
    box: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
        width: "96.7%",
        marginLeft: 8
    },
    declinebtn: {
        border: `1px solid ${theme.palette.error.main}`,
        color: theme.palette.error.main,
        borderRadius: 4,
        '&:hover': {
            border: `1px solid ${theme.palette.error.main}`,
            color: theme.palette.error.main,
            borderRadius: 4,
            backgroundColor: "transparent"
        }
    },
    shareIcon: {
        display: "flex",
        justifyContent: "right"
    },
    qr: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    cancelled_qr: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    expire: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().regular,
    },
    qrTime: {
        color: theme.palette.success.main,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold
    },
    qrDetail: {
        color: theme.palette.success.main,
        fontSize:"0.75rem",
    },
    company_logo: {
        height: 20,
        width: 105
    },
    cancelBtn: {
        height: 48,
        '&:hover': {
            backgroundColor: "#fff"
        }
    }
}))


export const FabButtonStyle = makeStyles((theme) => ({
    fabbtn: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 50,
        height: 50,
        width: 50,
        zIndex: 1,
        position: "absolute",
        bottom: "24px",
        textAlign: "center",
        color: "#fff",
        '&:hover': {
            width: 160,
            cursor: "pointer",
            '& p': {
                visibility: "visible !important",
                fontSize:"0.875rem",
            }
        },
        [theme.breakpoints.only('lg')]: {
            bottom: (props) => props?.select === "nonlist" ? "30px" : "60px",
        },
        [theme.breakpoints.only('xl')]: {
            bottom: (props) => props?.select === "nonlist" ? "30px" : "60px",
        },
    },
    // content:{
    //     position:"relative",
    //     top:"50%",
    //     left:"50%",
    //     transform:"translate(-50%,-50%)"
    // },
    btntext: {
        visibility: "hidden",
        whiteSpace: "nowrap",
        marginInlineStart: "8px"
    }
}))


export const InvoiceDetailStyle = makeStyles((theme) => ({
    box: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
        height: (props) => props?.customHeight ? "calc(100vh - 430px)" : "calc(100vh - 380px)",
        width: (props) => props?.booked ? 500 : "100%",
        [theme.breakpoints.only('md')]: {
            height: (props) => (props?.customHeight && props?.booked) && "230px",
        },
        [theme.breakpoints.only('sm')]: {
            height: (props) => (props?.customHeight && props?.booked) && "230px",
        },
        [theme.breakpoints.only('lg')]: {
            height: (props) => (props?.booked) ? "290px" : props?.customHeight ? "calc(100vh - 430px)" : "calc(100vh - 380px)",
        },
        [theme.breakpoints.only('xl')]: {
            height: (props) => (props?.booked) ? "290px" : props?.customHeight ? "calc(100vh - 430px)" : "calc(100vh - 380px)",
        },
    },
    title: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    content: {
        fontSize:"0.75rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().semiBold,
        float: "right"
    },
    total: {
        fontSize:"0.75rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    invoiceBox: {
        height: (props) => props?.customHeight ? "calc(100vh - 588px)" : "calc(100vh - 550px)",
        overflow: "scroll"
    },
    tableHeadText: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.secondary
    },
    tableBodyText: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.primary
    },
    addText: {
        fontSize:"0.875rem",
        color: theme.palette.primary.main,
        fontFamily: FontFamilySwitch().semiBold,
        cursor: "pointer"
    },
    tableTotalAmount: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.primary,
        marginTop: "6px",
        cursor: "pointer",
    },
    deleteIcon: {
        display: "none",
        "&:hover": {
            display: "block"
        }
    },
    tableContainer: {
        height: (props) => props?.customHeight ? "calc(100vh - 500px)" : "calc(100vh - 450px)",
        overflow: "auto",
        [theme.breakpoints.only('md')]: {
            height: (props) => (props?.customHeight && props?.booked) && "155px",
        },
        [theme.breakpoints.only('sm')]: {
            height: (props) => (props?.customHeight && props?.booked) && "155px",
        },
        [theme.breakpoints.only('lg')]: {
            height: (props) => (props?.booked) && "215px",
        },
        [theme.breakpoints.only('xl')]: {
            height: (props) => (props?.booked) && "215px",
        },
    }
}))

export const PaymentCardStyle = makeStyles((theme) => ({
    box: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
        cursor: "pointer"
    },
    iconbtn: {
        border: `1px solid ${theme.palette.border.secondary}`,
        height: 24,
        width: 24
    },
    title: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
    },
    content: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary
    }
}))

export const UnitListCardStyle = makeStyles((theme) => ({
    box: {
        background: theme.palette.background.secondary,
        borderRadius: 4
    },
    avatar: {
        height: 32,
        width: 32
    },
    title: {
        fontSize:"0.75rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    content: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        direction: "ltr"
    },
    selectedUnit: {
        background: theme.palette.background.secondary,
        borderRadius: 4,
        padding: 8,
        marginTop: 4
    }
}))

export const CancelBookingStyle = makeStyles((theme) => ({
    header: {
        fontSize:"0.75rem",
        color: theme.typography.color.Tertiary,
        fontWeight: "semibold"
    },
    submitBtn: {
        textTransform: "capitalize",
        height: 42,
        borderRadius: 4
    },
    declineBox: {
        borderRadius: 4,
        background: theme.palette.error.light,
    },
    declineTitle: {
        fontSize:"1rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.secondary
    },
    declineContent: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().regular,
        color: theme.typography.color.tertiary
    }
}))

export const calendarDropDownStyle = makeStyles((theme) => ({

    dropdown2: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        cursor: "pointer",
        zIndex: 1,
        position: "relative",
        backgroundColor: "#fff",
        boxShadow: "0px 0px 16px  #00000014",
        padding: 8,
        width: 120
    },
    dropdown: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        cursor: "pointer",
        zIndex: 3,
        position: "relative",
        backgroundColor: "#fff",
        // top: "130px",
        // boxShadow: "0px 0px 16px  #00000014",
        float: "right",
        // right: "130px",
        padding: 8,
        width: 120,
        height: 40
    },
    customDialogForParking: {
        '& .MuiBackdrop-root': {
            backgroundColor: "transparent"
        },
        '& .MuiDialog-container': {
            position: "absolute",
            height: 300,
            top: 74,
            right: 71,
            width: "200px",

        },
        '& .MuiDialogContent-root': {
            position: "relative",
            width: "120px",
            padding: "8px",
            // boxShadow: "0px 0px 16px  #00000014",
        }
    },
    customDialog: {
        '& .MuiBackdrop-root': {
            backgroundColor: "transparent"
        },
        '& .MuiDialog-container': {
            position: "absolute",
            height: 300,
            top: (props) => props?.right ? 74 : 90,
            right: (props) => props?.right ? 71 : 90,
            width: "200px",

        },
        '& .MuiDialogContent-root': {
            position: "relative",
            width: "120px",
            padding: "8px",
            // boxShadow: "0px 0px 16px  #00000014",
        }
    },
    selected: {
        color: theme.palette.primary.main,
        fontFamily: FontFamilySwitch().semiBold,
        fontSize:"0.875rem",
        cursor: "pointer"
    },
    value: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold
    },
    listText: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        cursor: "pointer"
    }
}))


export const CustomDialogStyle = makeStyles((theme) => ({
    customDialog: {
        '& .MuiBackdrop-root': {
            backgroundColor: "transparent"
        },
        '& .MuiDialog-container': {
            position: "absolute",
            height: 300,
            top: 80,
            left: 55
        },
        '& .MuiDialogContent-root': {
            position: "relative",
            width: "100%",
            padding: "8px 8px 4px"
        }
    }
}))

export const ExistingContactCardStyle = makeStyles((theme) => ({
    box: {
        background: theme.palette.background.secondary,
        borderRadius: 4
    },
    avatar: {
        height: 32,
        width: 32
    },
    title: {
        fontSize:"0.75rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    content: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        whiteSpace: "noWrap",
        width: 100
    },
}))

export const AccountCardStyle = makeStyles((theme) => ({
    card: {
        background: "#EBF6EB",
        borderRadius: 4
    },
    heading: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    dollar: {
        position: "absolute",
        bottom: "-6px",
        right: 0
    },
    dollarRtl: {
        position: "absolute",
        bottom: "-3px",
        left: "-2px",
        transform: "rotate(90deg)"
    }
}))

export const CustomDropdownStyle = makeStyles((theme) => ({
    avatar: {
        height: 20,
        width: 20,
        borderRadius: 50
    },
    label: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary,
        // width: 100,
        whiteSpace: "nowrap",
        textOverflow:"ellipsis",
        overflow:"hidden",
        marginInlineStart: '4px'
    },
    dropDownBox: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
        background: "#fff",
        cursor: "pointer"
    },
    disabledDropdownBox: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
        background: theme.palette.background.tertiary,
        cursor: "pointer"
    },
    selectedBox: {
        // width: 200,
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 4,
        background: theme.palette.info.light,
        cursor: "pointer"
    },
    viewLabel: {
        fontSize:"0.75rem",
        color: theme.palette.primary.main,
        fontFamily: FontFamilySwitch().semiBold,
        cursor: "pointer",
        marginInlineStart: '16px'
    },
    listAvatar: {
        height: 40,
        width: 40,
        borderRadius: 50,
        cursor: "pointer"
    }
}))


export const UnitAndContactStyle = makeStyles((theme) => ({
    box: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
    },
    title: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    subtitle: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().regular,
    },
    optionAvatar: {
        height: 32,
        width: 32
    },
    optionHeader: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
    },
    optionSubTitle: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().regular,
        color: theme.typography.color.secondary
    }

}))

export const MemberCountStyle = makeStyles((theme) => ({
    pcTitle: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.secondary,
        width: 300
    }
}))