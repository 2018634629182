import React from "react";
import { TreeView } from "@mui/x-tree-view";
import { useStyles, StyledTreeItem } from "./styles";
import { Box, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { LocationIcon, LocationIcon2 } from "./locationIcon";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { FontFamilySwitch } from "../../../utils";



export const FunctionTree = (
    {
        data = [],
        handleOnFolderClick = () => false
    }
) => {
    const classes = useStyles();

    const LongTextTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: "400px",
            borderRadius: "4px",
            padding: "8px 12px",
            backgroundColor: "#FFFFFF",
            color: "#1C1C1C",
            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
            fontSize: "0.875rem",
            fontFamily: FontFamilySwitch().semiBold,
        },
        [`& .${tooltipClasses.arrow}`]: {
            backgroundColor: "transparent",
            color: "#FFFFFF"
        },
    }));

    // render tree function
    const render = (TreeJson) => {
        let listData =
            Array?.isArray(TreeJson) &&
            TreeJson?.map((currentItem) => {
                return (
                    <StyledTreeItem
                        onClick={() => handleOnFolderClick(currentItem)}
                        nodeId={currentItem?.id}
                        itemId={currentItem?.id}
                        label={
                            <Box display="flex" alignItems="center">
                                {currentItem?.level === 0 ? <LocationIcon
                                /> : <LocationIcon2 />}
                                <Box width={"12px"} />
                                <LongTextTooltip title={currentItem?.name ?? ""} placement="top" arrow>
                                    <Typography
                                        noWrap
                                        readOnly
                                        style={{
                                            wordBreak: "break-all",
                                            overflowWrap: "anywhere",
                                        }}
                                    >
                                        {currentItem?.name?.length > 0
                                            ? `${currentItem?.name?.substring(0, 20)}${currentItem?.name?.length > 20 ? "..." : ""
                                            } `
                                            : "-"}

                                    </Typography>
                                </LongTextTooltip>
                                {/* {currentItem?.name} */}
                            </Box>
                        }
                        className={classes.treeContainer}
                    >
                        {Array.isArray(currentItem?.children)
                            ? render(currentItem?.children)
                            : null}
                    </StyledTreeItem>
                );
            });
        return listData;
    };
    return (
        <div className={classes.treeContainerParent}>
            <TreeView
                className={classes.tree}
                defaultExpanded={[data?.[0]?.id]}
                defaultCollapseIcon={<KeyboardArrowUpIcon className={classes.Icons} />}
                defaultExpandIcon={<KeyboardArrowDownIcon className={classes.Icons} />}
            >
                {render(data)}
            </TreeView>
        </div>
    );
};
