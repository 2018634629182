import React, { useState } from "react";
import { Subheader, DialogBox, DetailsCardCoponent } from "../../components";
import { Slider } from "../agreementDetails/components";
import { Box } from "@mui/material";
import { DocumentViewer } from "../../components/fileViewer";
import { useStyles } from "./style";
import { withTranslation } from "react-i18next";
import {convertTimeUtcToZoneCalander} from '../../utils'


const ViewCard = (props) => {
  const { details } = props;
  const { t = () => false } = props
  // class
  const classes = useStyles();
  //   state
  const [openKyc, setOpenKyc] = useState({ bool: false, type: null });

  //   function
  const onClickKyc = (val) => {
    setOpenKyc({
      bool: !openKyc?.bool,
      data: val,
    });
  };

  return (
    <div>
      <Subheader
        title={t(`Assets`)}
        hideBackButton={true}
        borderBottom={"1px solid #E4E8EE"}
      />
      <div className={classes.padding}>
        {details?.url?.length > 0 && (
          <Slider
            onClick={onClickKyc}
            assets={details?.url ?? []}
            backgroundColor={"#000"}
            pageNumber
            padding={"0px 21px 0px 23px"}
          />
        )}

        <Box mt={1}>
          <DetailsCardCoponent
            components={[
              {
                size: {
                  xs: 12,
                  sm: 12,
                  md: 6,
                  lg: 4,
                },
                isActive: true,
                component: "text",
                heading: t("Proof"),
                subtitle: details?.identification_masterByID?.type ?? "-",
              },
              {
                size: {
                  xs: 12,
                  sm: 12,
                  md: 6,
                  lg: 4,
                },
                isActive: true,
                component: "text",
                heading: t("Name_As_In_Proof"),
                subtitle: details?.name ?? "-",
              },
              {
                size: {
                  xs: 12,
                  sm: 12,
                  md: 6,
                  lg: 4,
                },
                isActive: true,
                component: "text",
                heading: t("ID_Proof_Number"),
                subtitle: details?.number ?? "-",
              },
              {
                size: {
                  xs: 12,
                  sm: 12,
                  md: 6,
                  lg: 4,
                },
                isActive: true,
                component: "text",
                heading: t("Valid_From"),
                subtitle:
                  details?.valid_from
                    ? convertTimeUtcToZoneCalander(new Date(details?.valid_from))
                        
                    : "-",
              },
              {
                size: {
                  xs: 12,
                  sm: 12,
                  md: 6,
                  lg: 4,
                },
                isActive: true,
                component: "text",
                heading: t("Valid_To"),
                subtitle:
                  details?.valid_to
                    ? convertTimeUtcToZoneCalander(new Date(details?.valid_to))
                    : "-",
              },
              {
                size: {
                  xs: 12,
                  sm: 12,
                  md: 6,
                  lg: 4,
                },
                isActive: true,
                component: "text",
                heading: t("Issuing_Country"),
                subtitle: details?.countryByID?.country_name ?? "-",
              },
            ]}
          />
        </Box>
      </div>
      <DialogBox
        open={openKyc?.bool}
        handleClose={onClickKyc}
        isnotTitle
        maxWidth={"md"}
        heigth={"362px !important"}
        component={
          <Box p={2}>
            {openKyc?.data?.type === "application/pdf" ? (
              <center>
                <DocumentViewer url={openKyc?.data?.src} />
              </center>
            ) : (
              <center>
                <img src={openKyc?.data?.src} alt="" />
              </center>
            )}
          </Box>
        }
      />
    </div>
  );
};
export default withTranslation("kyclistAndview")(ViewCard);
