import React from 'react';
import { CustomTypography } from '.';
import useTheme from '@mui/material/styles/useTheme';
import Stack from '@mui/material/Stack';
import { FontFamilySwitch } from '../../../utils';
export const IconWithText = (props) => {

    const {
        icon = "",
        text = ""
    } = props;

    const theme = useTheme();

    return (
        <Stack
            direction={"row"}
            alignItems={"center"}
            gap={1}
        >
            {icon && icon}
            {
                text &&
                <CustomTypography
                    fontFamily={FontFamilySwitch().bold}
                    color={theme?.typography?.color?.primary}
                    fontSize={12}
                >
                    {text}
                </CustomTypography>
            }
        </Stack>
    )
}