import { Button, Divider, Drawer, Stack, Typography } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { DialogBox, LineIndicatorTab, SearchFilter, Subheader, TableWithPagination, UseDebounce } from "../../components";
import { AlertContext, AuthContext } from "../../contexts";
import { AlertProps, NetWorkCallMethods, accessCheckRender, getRoutePermissionNew } from "../../utils";
import { PublicListingStyles } from "./style";
import { initial_create_backup_form_state, tab_data, tab_paths } from "./utils/constant";
import { manualBackupHeading, manual_backup_table_path, manual_backup_table_type, systemBackupHeading, system_backup_table_path, system_backup_table_type } from "./utils/tableUtils";
import { constructAdditionalPayload, constructResponseGetData } from "./utils/common";
import { BackendRoutes } from "../../router/routes";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { ConfirmDialog, CreateManualBackupForm, DeleteBackupIcon, RestoreBackupIcon, RestoreLog } from "./components";
import { format } from "date-fns";

const BackupRestore = (props) => {
    const { t, loading, handleLoading } = props;
    const initialMainTabValue = tab_data(t)?.manual_backup
    const classes = PublicListingStyles()

    //Hooks
    const navigate = useNavigate()
    const debounce = UseDebounce()

    //Context
    const auth = React.useContext(AuthContext)
    const alert = React.useContext(AlertContext)

    //useState
    const [permission, setPermission] = React.useState({})
    const [mainTabValue, setMainTabValue] = React.useState(initialMainTabValue)
    const [searchText, setSearchText] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [tableData, setTableData] = React.useState({
        manual_backup: { row: [], row_count: 0 },
        system_backup: { row: [], row_count: 0 },
    })
    const [isButtonDisable, setIsButtonDisable] = React.useState(false)
    const [isAddFormOpen, setIsAddFormOpen] = React.useState(false)
    const [create_backup_form_state, setCreateBackupFormState] = React.useState(initial_create_backup_form_state)
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false)
    const [confirmDialogState, setConfirmDialogState] = React.useState({})
    const [isRestorelog, setIsRestoreLog] = React.useState({
        data: null,
        bool: false
    })

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getData(0, limit, searchText, mainTabValue)
            }
        }
        // eslint-disable-next-line
    }, [auth])

    //Main Tab handle change
    const handleMainTabChange = (v) => {
        if (mainTabValue?.value !== v?.value) {
            setMainTabValue(v)
            setSearchText("")
            getData(0, limit, "", v)
        }
    }

    // Function for search in search component
    const handleSearch = (v) => {
        setSearchText(v)
        debounce(() => searchTableFunction(v), 800)
    }

    // Function to search data in list
    const searchTableFunction = (v) => {
        getData(0, limit, v, mainTabValue)
    }

    // Function to handle heading for table
    const handleTableHeading = (selected_main_tab) => {
        let temp_heading = []
        switch (selected_main_tab?.value) {
            case tab_data(t).manual_backup.value:
                temp_heading = manualBackupHeading(t)
                break;
            case tab_data(t).system_backup.value:
                temp_heading = systemBackupHeading(t)
                break;
            default:
                break;
        }
        return temp_heading
    }

    // Function to handle heading for table
    const handleTablePath = (selected_main_tab) => {
        let temp_path = []
        switch (selected_main_tab?.value) {
            case tab_data(t).manual_backup.value:
                temp_path = manual_backup_table_path
                break;
            case tab_data(t).system_backup.value:
                temp_path = system_backup_table_path
                break;
            default:
                break;
        }
        return temp_path
    }

    // Function to handle heading for table
    const handleTableType = (selected_main_tab) => {
        let temp_type = []
        switch (selected_main_tab?.value) {
            case tab_data(t).manual_backup.value:
                temp_type = manual_backup_table_type
                break;
            case tab_data(t).system_backup.value:
                temp_type = system_backup_table_type
                break;
            default:
                break;
        }
        return temp_type
    }

    //Funtion to get table data
    const getData = (offset = 0, limit = 10, search = "", selected_main_tab) => {
        setIsButtonDisable(true)
        let temp_payload = { offset, limit, search }

        let payload = constructAdditionalPayload(selected_main_tab, temp_payload , t)
        NetworkCall(
            `${config?.api_url}${BackendRoutes.system_backup_list}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((res) => {
            const temp_table_data = constructResponseGetData(res?.data, tableData, selected_main_tab , t)
            setTableData(temp_table_data)
            handleLoading(false)
            setIsButtonDisable(false)
        }).catch((error) => {
            console.log(error)
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
            setIsButtonDisable(false)
        });
    }

    //Function to handle click on table
    const handleIcon = (type, data) => {
        let temp_data = data?.data
        switch (type) {
            case "restore":
                setConfirmDialogState(confirmDialogData(temp_data).restore)
                setIsConfirmDialogOpen(true)
                break;
            case "delete":
                handleDeleteBackup(temp_data?.backup_id)
                break;

            case "log":
                setIsRestoreLog({
                    data: temp_data,
                    bool: true
                })
                break;
            default:
                break;
        }
    }

    // Function to handle pagination in table
    const handleTablePagination = (v) => {
        setPage(v)
        let offset = (v - 1) * limit
        getData(offset, limit, searchText, mainTabValue)
    }

    // Function to handle page limit in table
    const handleTablePageLimit = (v) => {
        setLimit(v)
        setPage(1)
        getData(0, v, searchText, mainTabValue)
    }

    //Function to handle Create Backup Button
    const handleCreateBackupButton = (selected_main_tab) => {
        updateCreateBackupFormState("clear")
        setIsAddFormOpen(true)
    }

    //Function to update createBackupFormstate
    const updateCreateBackupFormState = (key, value) => {
        let error = create_backup_form_state?.error;
        error[key] = "";
        if (key === "clear") {
            setCreateBackupFormState({
                ...initial_create_backup_form_state,
                error: {
                    title: "",
                    schedule_date: new Date(),
                }
            })
        } else {
            setCreateBackupFormState({ ...create_backup_form_state, [key]: value, error })
        }
    }

    //Function to validate create backup form
    const validateCreateBackupForm = () => {
        let isValid = true;
        let error = create_backup_form_state.error;

        if (create_backup_form_state?.title?.length === 0) {
            isValid = false;
            error.title = t("Backup Title is Required");
        }

        if (create_backup_form_state?.schedule_date?.length === 0) {
            isValid = false;
            error.schedule_date = t("Schedule Date is Required");
        }

        setCreateBackupFormState({ ...create_backup_form_state, error });
        return isValid;
    }

    //Function to update createBackupFormstate
    const handleCreateBackup = () => {
        if (validateCreateBackupForm()) {
            setIsButtonDisable(true)
            let payload = {
                scheduled_date: create_backup_form_state?.type === "Schedule" ? format(new Date(create_backup_form_state?.schedule_date), 'yyyy-MM-dd HH:mm:ss') : undefined,
                title: create_backup_form_state?.title,
                is_cloud: true,
                "is_scheduled": create_backup_form_state?.type === "Schedule" ? true : false,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
            setIsButtonDisable(false)
            NetworkCall(
                `${config?.api_url}${BackendRoutes.system_upsert}`,
                NetWorkCallMethods.post, payload, null, true, false
            ).then((res) => {
                getData(0, limit, "", mainTabValue)
                setSearchText("")
                handleCreateBackupFormClose()
                setIsButtonDisable(false)
                alert.setSnack({
                    ...alert, open: true, msg: t("Backup Created Successfully"),
                    severity: AlertProps.severity.success
                })
            }).catch((error) => {
                console.log(error)
                alert.setSnack({
                    ...alert, open: true, msg: t("Some Thing Went Wrong"),
                    severity: AlertProps.severity.error
                })
            });
        } else {
            return false
        }
    }

    //Function to update createBackupFormstate
    const handleRestoreBackup = (data) => {
        setIsButtonDisable(true)
        let payload = { backup_id: data?.backup_id, }
        setIsButtonDisable(false)
        NetworkCall(
            `${config?.api_url}${BackendRoutes.system_restore_backup}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((res) => {
            getData(0, limit, "", mainTabValue)
            setSearchText("")
            handleCreateBackupFormClose()
            setIsButtonDisable(false)
            alert.setSnack({
                ...alert, open: true, msg: t("Backup Restored Successfully"),
                severity: AlertProps.severity.success
            })
        }).catch((error) => {
            console.log(error)
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
        });
    }

    // delete backup
    const handleDeleteBackup = (id) => {
        let payload = {
            backup_id: id
        }
        setIsButtonDisable(false)
        NetworkCall(
            `${config?.api_url}/system/delete_backup`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((res) => {
            getData(0, limit, "", mainTabValue)
            setSearchText("")
            setIsButtonDisable(false)
            alert.setSnack({
                ...alert, open: true, msg: t("Backup Deleted Successfully"),
                severity: AlertProps.severity.success
            })
        }).catch((error) => {
            console.log(error)
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
        });
    }
    const confirmDialogData = (data) => {
        return (
            {
                restore: {
                    icon: <RestoreBackupIcon />,
                    question: t("Do your want to restore this backup?"),
                    cancel: {
                        label: t("Cancel"),
                        action: () => handleConfirmDialogClose()
                    },
                    yes: {
                        label: t("Yes, Restore"),
                        action: () => {
                            setIsConfirmDialogOpen(false)
                            handleRestoreBackup(data)
                        }
                    },
                },
                delete: {
                    icon: <DeleteBackupIcon />,
                    question: t("Do you want to delete this backup?"),
                    cancel: {
                        label: t("Cancel"),
                        action: () => handleConfirmDialogClose()
                    },
                    yes: {
                        label: t("Yes, Delete"),
                        action: () => handleConfirmDialogClose()
                    },
                }
            }
        )
    }

    const handleConfirmDialogClose = () => {
        setIsConfirmDialogOpen(false)
        setConfirmDialogState({})
    }

    //Function to handle close form
    const handleCreateBackupFormClose = () => {
        setIsAddFormOpen(false)
        updateCreateBackupFormState("clear")
    }

    const onRestoreClose = () => {
        setIsRestoreLog({
            ...isRestorelog,
            bool: false
        })
    }
    const render = () => {
        return <>
            <Subheader
                hideBackButton
                title={t("Back Up & Restore")}
                goBack={() => navigate()} />
            <Stack className={classes.body} direction={"column"}>
                <Stack direction={"column"} divider={<Divider />}>
                    <div className={classes.tab_div}>
                        <LineIndicatorTab
                            t={t}
                            path={tab_paths(t)}
                            data={tab_data(t)}
                            selected={mainTabValue}
                            onClick={handleMainTabChange} />
                    </div>
                    <div className={classes.search_filter_add_div}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <div className={classes.search_div}>
                                <SearchFilter placeholder={t("Search")} value={searchText} handleChange={(v) => handleSearch(v)} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
                            </div>
                            <div className={classes.add_button_div}>
                                {mainTabValue?.add_button_text &&
                                    <Button className={classes.add_button} disabled={isButtonDisable}
                                        onClick={() => handleCreateBackupButton(mainTabValue)}>
                                        <Typography className={classes.add_button_text}>{t(mainTabValue?.add_button_text)}</Typography>
                                    </Button>}
                            </div>
                        </Stack>
                    </div>
                </Stack>
                <div className={classes.table_div}>
                    <TableWithPagination
                        heading={handleTableHeading(mainTabValue , t)}
                        rows={tableData?.[mainTabValue?.value]?.row}
                        path={handleTablePath(mainTabValue)}
                        showpagination={true}
                        showpdfbtn={false}
                        showexcelbtn={false}
                        showSearch={false}
                        handleIcon={handleIcon}
                        onClick={() => { }}
                        tableType="no-side"
                        dataType={handleTableType(mainTabValue)}
                        handlePagination={handleTablePagination}
                        handleChangeLimit={handleTablePageLimit}
                        totalRowsCount={tableData?.[mainTabValue?.value]?.row_count}
                        page={page}
                        limit={limit}
                        height={'calc(100vh - 345px)'}
                        view={permission.read}
                        edit={permission.update}
                        delete={permission.delete}
                        isButtonDisable={isButtonDisable}
                        noDataSvg />
                </div>
            </Stack >
            <Drawer
                anchor={"right"}
                open={isAddFormOpen}
                onClose={handleCreateBackupFormClose}>
                <CreateManualBackupForm
                    t={t}
                    handleClose={handleCreateBackupFormClose}
                    state={create_backup_form_state}
                    updateState={updateCreateBackupFormState}
                    isButtonDisable={isButtonDisable}
                    handleCreateBackup={handleCreateBackup} />
            </Drawer>
            <DialogBox
                isnotTitle
                maxWidth={"xs"}
                handleClose={() => setIsConfirmDialogOpen(false)}
                open={isConfirmDialogOpen}
                component={
                    <ConfirmDialog
                        t={t}
                        onClose={handleConfirmDialogClose}
                        state={confirmDialogState} />
                } />


            {/* restore logs */}
            <Drawer
                anchor={"right"}
                open={isRestorelog?.bool}
                handleClose={() => {
                    onRestoreClose()
                }}
            >
                <RestoreLog data={isRestorelog?.data} handleClose={() => {
                    onRestoreClose()
                }} />
            </Drawer>
        </>
    }

    return (<>{accessCheckRender(render, permission, "", loading)}</>)
}

export default withTranslation("backupRestore")(BackupRestore);
