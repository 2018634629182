import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../utils";
export const tabStyles = makeStyles((theme) => ({
    title: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: "#4E5A6B",
        marginBottom: "12px"
    },
    card: {
        backgroundColor: "white",
        boxShadow: "0px 0px 16px #00000014",
        borderRadius: "12px"
    },
    heading: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: " #98A0AC"
    },
    sub: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: "#091B29",
        marginTop: "4px"
    }
}));