import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { Avatar, Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import BackgroundType from "../../../assets/leaseBackground";
import MobileIcon from "../../../assets/mobileIcon";
import QuotationLeaseIcon from "../../../assets/quotationLeaseIcon";
import SourceTypeIcon from "../../../assets/sourceIcon";
import { AlertDialog, CustomSelectOptions, IOSSwitch, SourceMenuOption } from "../../../components";
import { useStyles } from "./styles";
import { loadOptionsApis } from '../../../utils/asyncPaginateLoadOptions';
import { stringAvatar } from '../../../utils';
import { BrokerAccCard } from "./brokerAccCard"
import { TypeIcon } from '../../../assets';
import BackgroundTypeArabic from '../../../assets/leaseBackgroundArabic';
import { CustomSelectBox } from '../../../components/customSelectBox';

export const LeadDetailsCard = ({ data = {}, account = null, source_enum = [], dataNew = [], setDataNew = () => false, isReadOnly = false, setSelectedAccount = () => false, t, contact_id, opperEdit = false, setOpperEdit = () => false }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const language = localStorage.getItem("i18nextLng")
  const [primary, setPrimary] = React.useState(dataNew.primary ? true : false)
  const updateAccount = (key, value) => {
    setSelectedAccount({
      ...account,
      [key]: value
    })
  }

  const agentManualResponse = (array) => {
    const data = array?.data?.map((e) => {
      return {
        ...e,
        label: e?.name
      }
    })

    return data
  }
  return (
    <Box >
      <Box className={classes.detailRoot} marginBottom="4px">
        <Grid container>
          <Grid item xs={1.5}>
            <Avatar
              src={(data?.image || data?.get_assets_url_id)}
              className={classes.userImg}
              {...stringAvatar(data?.name, 14, "4px")}
            />
          </Grid>
          <Grid item xs={10.5}>
            <Typography className={classes.leadName} noWrap style={{ paddingRight: "55px" }}>{data?.name}</Typography>
          </Grid>

        </Grid>

        <Box className={language === "ar" ? classes.typeBgArabic : classes.typeBg}>
          {language === "ar" ?
            <BackgroundTypeArabic color={data?.type === "Lease" ? "#6d6db3" : data?.type === "Sale" ? "#f4a261" : "#690375"} /> :
            <BackgroundType color={data?.type === "Lease" ? "#6d6db3" : data?.type === "Sale" ? "#f4a261" : "#690375"} />
          }
          <Box className={classes.centeredText} display={"flex"}>
            <QuotationLeaseIcon />
            <Typography className={classes.type}>{data?.type}</Typography>

          </Box>
        </Box>
      </Box>
      <Stack direction={"row"} mt={1.5} spacing={1}>
        <MobileIcon className={classes.mailIcon} />
        <Typography className={classes.mobileNo}>{data?.mobile}</Typography>
      </Stack>
      <Stack direction={"row"} spacing={1} mt={1.5}>
        <MailOutlinedIcon className={classes.mailIcon} />
        <Typography className={classes.mobileNo}>{data?.email}</Typography>
      </Stack>

      <Grid container mt={2} alignItems="center">
        <Grid item xs={1}>
          <TypeIcon style={{ marginTop: "4px" }} className={classes.homeIcon} />
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.typeText}>{t("Type")}</Typography>
        </Grid>
        <Grid item xs={7}>
          <Box display={"flex"} justifyContent={"space-between"} className={classes.purposebox}>
            <Typography className={classes.typeText}>{data.purpose}</Typography>
            <KeyboardArrowDownIcon className={classes.homeIcon} />
          </Box>
        </Grid>
      </Grid>

      <Grid container mt={2.5} alignItems="center">
        <Grid item xs={1}>
          <Box className={classes.sourceTypeIcon} style={{ marginTop: "4px" }}>
            <SourceTypeIcon />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.srcText}>{t("Lead Source")}</Typography>
        </Grid >
        <Grid item xs={7}>
          <SourceMenuOption
            value={dataNew?.source?.value ?? ""}
            onChange={(value) => setDataNew({ ...dataNew, source: value })}
            option={source_enum ?? []}
            isReadOnly={isReadOnly}
          />
        </Grid>
      </Grid >

      <Grid container mt={2} alignItems="center">
        <Grid item xs={1} className={classes.gridFlex}>
          <SourceTypeIcon />
        </Grid>
        <Grid item xs={4} className={classes.gridFlex}>
          <Typography className={classes.srcText} ml={1}>
            {t("Internal Lead Source")}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <CustomSelectBox
            value={dataNew?.internal_lead_source}
            menuOptionPadding={"0px 0px 8px 0px"}
            networkCallData={
              {
                path: "queries/opportunity/internal_lead_source",
                payload: {},
                mappingVariable: "data",
              }
            }
            onChange={(value) => setDataNew({ ...dataNew, internal_lead_source: value })}
            placeholder={t("Internal")}
            customStyles={{
              background: "#EDE4FE80",
              height: 40,
              padding: "8px 16px 8px 8px",
              textColor: "#896DB3",
              boldText: true
            }}
            isReadOnly={isReadOnly}
          />
        </Grid>
      </Grid>
      {
        account?.normal?.label ?
          <Box marginTop="12px">
            <BrokerAccCard
              bgColor="#FFFCEB"
              textColor="#B3A16D"
              title={t("Customer Account")}
              acc={account?.normal}
              edit={opperEdit ? true : false}
              onEdit={() => setOpen({
                bool: opperEdit ? true : false,
                type: "normal"
              })}
              hideValue1={false}
            />
          </Box>
          :
          opperEdit ? 
          <Button className={classes.addBrokerAcc} onClick={() => setOpen({
            bool: opperEdit ? true : false,
            type: "normal"
          })}>+ {t("Customer Account")}</Button> : ""

      }

      {
        account?.broker?.label ?
          <Box marginTop="12px">
            <BrokerAccCard
              title={t('Broker Account')}
              acc={account?.broker}
              edit={opperEdit ? true : false}
              onEdit={() => setOpen({
                bool: opperEdit ? true : false,
                type: "broker"
              })}
              hideValue1={false}
            />
          </Box>
          :
          opperEdit ? 
          <Button className={classes.addBrokerAcc}
            onClick={() => setOpen({
              bool: opperEdit ? true : false,
              type: "broker"
            })}
          >+ {t("Broker Account")}</Button>: ""

      }

      {
        account?.agent?.label ?
          <Box marginTop="12px">
            <BrokerAccCard
              acc={account?.agent}
              title={t('Broker Agent')}
              edit={opperEdit ? true : false}
              onEdit={() => setOpen({
                bool: opperEdit ? true : false,
                type: "Agent"
              })}
              hideValue1={false}
            />
          </Box>
          :
          <>
            {
                opperEdit ? 
              (account?.broker?.label &&
              <Button className={classes.addBrokerAcc}
                onClick={() => setOpen({
                  bool: opperEdit ? true : false,
                  type: "Agent"
                })}
              >+ {"Broker Agent"}</Button>) : ""
            }
          </>

      }
      <Divider sx={{ margin: "4px 0pc" }} />

      <AlertDialog
        isNormal
        header={open?.type === "normal" ? "Account" : open?.type === "broker" ? "Broker Account" : "Broker Agent"}
        open={open?.bool}
        onClose={() => setOpen({
          type: null,
          bool: false
        })}
        component={
          <Box height="460px" p={2}>
            {
              (open?.type === "normal") &&
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Typography className={classes.subTitle}>Show only primary account</Typography>
                <Box> <IOSSwitch
                  width={36}
                  height={16}
                  checked={primary}
                  tamW={10}
                  tamH={10}
                  tamM={0}
                  onChange={(event) => { setDataNew({ ...dataNew, primary: event.target.checked }); setPrimary(event.target.checked) }}
                  inputProps={{ 'aria-label': 'controlled' }}
                /></Box>
              </Box>
            }
            <CustomSelectOptions
              maxMenuHeight="250px"
              menuPlacement="bottom"
              key={JSON.stringify(primary)}
              isPaginate={true}
              value={open?.type === "normal" ? account?.normal : open?.type === "Agent" ? account?.agent : account?.broker}
              onChange={(val) => {
                updateAccount(open?.type === "normal" ? "normal" : open?.type === "Agent" ? "agent" : "broker", val)
                setOpen({
                  type: null,
                  bool: false
                })
              }}
              loadOptions={(search, array, handleLoading) => {
                if (primary && open?.type === "normal") {
                  return loadOptionsApis(
                    "contact/primary_account",
                    {
                      contact_id: dataNew?.contact_id ?? undefined,
                      email_id: dataNew?.contact_id ? undefined : data?.email ?? undefined
                    },
                    search,
                    array,
                    handleLoading,
                    "data"
                  )
                } else if (open?.type === "Agent") {
                  return loadOptionsApis(
                    "queries/get_vendor_account",
                    {
                      vendor_id: account?.broker?.vendor_id,
                    },
                    search,
                    array,
                    handleLoading,
                    "data",
                    {},
                    agentManualResponse
                  )
                }
                return loadOptionsApis(
                  "/queries/opportunity/contact_account",
                  {
                    company_id: dataNew?.company,
                    relationship:open?.type === "normal" ? "Customer" : "Sales Broker"
                  },
                  search,
                  array,
                  handleLoading,
                  "data"
                )
              }
              }
              hideName
              hideValue1={false}
            />


          </Box>
        }
      />
    </Box >
  )
}