import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const quotationsStyles = makeStyles((theme) => ({
    container: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        //overflow: "hidden",
        margin: "14px"
    },
    title: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: "#4E5A6B",
        marginBottom: "10px"
    },
    unitList: {
        backgroundColor: "#F5F7FAE6",
        padding: "16px 18px 0px 18px",
        overflow: "auto",
        height: `calc(100vh - 147px)`,
    },
    detailRoot: {
        overflow: "auto",
        height: `calc(100vh - 223px)`,
    },
    summeryRoot: {
        backgroundColor: "#F5F7FA",
        borderRadius: theme.palette.borderRadius,
    },
    btn: {
        margin: "12px 0px",
        padding: "8px",
        boxShadow: "none",
        borderRadius: theme.palette.borderRadius
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: "4px",
            padding: "0px",
        },
    },
    reserve: {
        marginTop: "12px",
        padding: "8px",
        boxShadow: "none",
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "white",
        fontFamily: FontFamilySwitch().semiBold,
        "&:hover": {
            backgroundColor: "white",
        },
    },
    snackBarRoot: {
        backgroundColor: theme.palette.success.main,
        width: "350px",
        borderRadius: "4px",
        marginTop: "-20px"
    },
    alertTitle: {
        color: theme.palette.background.paper,
        fontFamily: FontFamilySwitch().bold,
        fontSize:"0.875rem"

    },
    alertSub: {
        color: theme.palette.background.paper,
        fontSize:"0.75rem",
        marginTop: "6px"
    },
    card: {
        padding: "24px",
        backgroundColor: theme.palette.background.paper,
        cursor: "pointer",
        borderRadius: "4px",

    },
    titleReservation: {
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary,
        textAlign: "center",
        marginTop: "6px",
        fontSize: "1.25rem"
    },
    iconBtn: {
        backgroundColor: theme.palette.primary.main,
        fontSize:"0.75rem",
        marginTop: "6px",
        color: theme.palette.background.paper,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        }
    },
    reservationType: {
        minHeight: "240px"
    },
    downBtn: {
        border: `1px solid ${theme?.typography?.color?.primary}`,
        color: theme?.typography?.color?.primary,
        backgroundColor: "white",
        "&:hover": {
            backgroundColor: "white",
            border: `1px solid ${theme?.typography?.color?.primary}`,
        }
    },
    Customize:{
        color:"#5078E1",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize:"0.875rem",
        cursor:"pointer",
        border:"1px solid #5078E1",
        borderRadius:"4px",
        padding:'4px',
        "&:hover": {
            backgroundColor: "white",
        },
        "&.Mui-disabled": {
            border: "1px solid #D3D3D3",
        },
    },

    proceedDialog: {
        padding: "54px 50px 51px 228px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        backgroundImage: `url(${"images/announsment.svg"})`,
        backgroundRepeat: "no-repeat",
        backgroundColor:theme?.palette?.primary?.main
      },
      proceedDialogArabic: {
        padding: "54px 50px 51px 228px",
        display: "flex",
        alignItems: "center",
        // justifyContent: "flex-end",
        backgroundImage: `url(${"images/announsment.svg"})`,
        backgroundRepeat: "no-repeat",
        backgroundColor:theme?.palette?.primary?.main
      },
        convert: {
        color: "white",
        fontSize:"1rem",
        fontFamily: FontFamilySwitch().bold,
      },
      convertProcess: {
        color: "white",
        fontSize:"1rem",
        fontFamily: FontFamilySwitch().regular,
        marginTop:'7px'
      },
        marginTop:{
        marginTop:"24px"
      },
      yes: {
        color: theme?.palette?.primary?.main,
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        border: `1px solid white`,
        backgroundColor: "white",
        padding: "7px 29px",
        width: "100%",
        "&:hover": {
          border: `1px solid white`,
          backgroundColor: "white",
        },
      },
      No: {
        color: "white",
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        border: `1px solid white`,
        padding: "7px 29px",
        backgroundColor: theme?.palette?.primary?.main,
        marginInlineStart: "10px",
        width: "100%",
        "&:hover": {
          border: `1px solid white`,
          backgroundColor: theme?.palette?.primary?.main,
        },
      },
}));