import { Avatar, Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  AggreementCardInfo,
  LoadingSection,
  // GroupPieCharts,
} from "../../../components";
import moment from "moment";
import { useWindowDimensions, assestType } from "../../../utils";
import { LightTooltip, useStyles } from "../style";
import { AssetView } from "../../propertyFinder3/component/assetView";
import CityProperty from "../../../assets/cityproperty";
import Location from "../../../assets/locationsymbol";
import PhoneImg from "../../../assets/phone";
import EmailIMG from "../../../assets/mailImg";
import TelephoneImg from "../../../assets/telephone";
import WebSiteImg from "../../../assets/website";
import ApartmentImg from "../../../assets/apartment";
import { useNavigate } from 'react-router-dom';
import { Routes } from "../../../router/routes";
import GraphComponent from "../../propertymanagerdashboard/components/graphcomponent";
// import LeaseImage from "../../../assets/leaseimagecomponent";

export const DetailsContainer = (props) => {
  const { t } = props;
  const data = props?.stats?.data ?? {};
  const propertyHierarchy = data?.property_details?.property_hierarchy ?? {};
  const classes = useStyles();
  const navigate = useNavigate();
  // const [loading, setLoading] = React.useState(true);
  const size = useWindowDimensions();
  const listData = [
    {
      image_url: "/images/totalproperty.svg",
      count:
        `${props?.stats?.data?.statistics?.toala_property_area} ${
          props?.stats?.data?.property_details?.uom_name ?? ""
        }` ?? 0,
      name: "Total Property Area",
    },
    {
      image_url: "/images/parkingslotarea.svg",
      count: props?.stats?.data?.statistics?.parking_units ?? 0,
      name: "No. of Parking slot",
    },
    {
      image_url: "/images/occupiedunits.svg",
      count: props?.stats?.data?.statistics?.swimming_pools ?? 0,
      name: "No. of Swimming Pools",
    },
    {
      image_url: "/images/elevators.svg",
      count: props?.stats?.data?.statistics?.elevators ?? 0,
      name: "No. of Elevators",
    },
  ];
  const cardData = [
    {
      url: "/images/activeblock.svg",
      title: props?.stats?.data?.statistics?.active_blocks ?? 0,
      subtitle: `Active ${
        propertyHierarchy?.is_customisable && propertyHierarchy?.block_label
          ? propertyHierarchy?.block_label
          : "Blocks"
      }`,
      color: "#F5F7FA",
      view: propertyHierarchy?.is_block,
      onClick: () =>
        navigate(Routes.block + "?propertyID=" + data?.statistics?.id),
    },
    {
      url: "/images/activefloor.svg",
      title: props?.stats?.data?.statistics?.active_floors ?? 0,
      subtitle: `Active ${
        propertyHierarchy?.is_customisable && propertyHierarchy?.floor_label
          ? propertyHierarchy?.floor_label
          : "Floors"
      }`,
      color: "#F5F7FA",
      view: propertyHierarchy?.is_floor,
      onClick: () =>
        navigate(Routes.floor + "?propertyID=" + data?.statistics?.id),
    },
    {
      url: "/images/activeunit.svg",
      title: props?.stats?.data?.statistics?.active_units ?? 0,
      subtitle: "Active Units",
      color: "#F5F7FA",
      view: true,
      onClick: () =>
        navigate(Routes.unit + "?propertyID=" + data?.statistics?.id),
    },
    {
      url: "/images/vacantunits.svg",
      title: props?.stats?.data?.statistics?.vacant_units ?? 0,
      subtitle: "Vacant Units",
      color: "#F5F7FA",
      view: true,
      onClick: () => {},
    },
    {
      url: "/images/listedunitImage.svg",
      title: props?.stats?.data?.statistics?.listing_units ?? 0,
      subtitle: "Listed Units",
      color: "#F5F7FA",
      view: true,
      onClick: () => {},
    },
    {
      url: "/images/listedunits.svg",
      title: props?.stats?.data?.statistics?.occupied_units ?? 0,
      subtitle: "Occupied Units",
      color: "#F5F7FA",
      view: true,
      onClick: () => {},
    },
  ];
  return (
    <>
      {props?.loading ? (
        <LoadingSection message={t("Loading")} />
      ) : (
        <Box
          style={{
            height: size?.height - 200,
            overflow: "auto",
            paddingRight: "16px",
          }}
        >
          <Box>
            <AssetView
              asset={props?.stats?.assets
                ?.filter((x) => x?.asset_type !== assestType?.Videos)
                ?.filter((x) => x?.asset_type !== assestType?.Documents)
                ?.map((x) => x)}
              assetAll={props?.stats?.data?.assets}
            />
          </Box>

          <Box>
            <Box display={"flex"}>
              <Avatar
                src={props?.stats?.data?.property_details?.logo}
                alt={""}
              />
              <Typography className={classes.propertyDeatils}>
                {props?.stats?.data?.property_details?.name}
              </Typography>
            </Box>

            <Box className={classes.propertyDeatilsBox}>
              <Box className={classes.flexBox}>
                <CityProperty color={"#98A0AC"} />
                <Typography className={classes.yearStyle}>
                  &#x202a;
                  {moment(
                    props?.stats?.data?.property_details?.year_built
                  ).format("DD MMM YY")}
                  &#x202c;
                  <Typography className={classes.yearFontStyle}>
                    {" "}
                    {t("Build")}
                  </Typography>
                </Typography>
              </Box>
              <Box className={classes.marginLeft}>
                <Box
                  className={classes.dot}
                  style={{
                    backgroundColor: "#E4E8EE",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                />
                <ApartmentImg color={"#98A0AC"} />
                <Typography className={classes.yearStyle}>
                  {props?.stats?.data?.property_details?.group_name}
                </Typography>
              </Box>
              <Box className={classes.marginLeft}>
                <Box
                  className={classes.dot}
                  style={{
                    backgroundColor: "#E4E8EE",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                />
                {/* <LeaseImage color={"#98A0AC"} /> */}
                <img src="/images/leasetypeimage.svg" alt="lease" />
                <Typography className={classes.yearStyle}>
                  {props?.stats?.data?.property_details?.revenue_type}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box className={classes.addressDetailsBox}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box className={classes.addressflexBox}>
                  <Box>
                    <Location />
                  </Box>

                  <Box>
                    <Typography className={classes.addressDeatils}>
                      {props?.stats?.data?.property_details?.door_no?.length >
                        0 &&
                        props?.stats?.data?.property_details?.door_no +
                          ","}{" "}
                      {props?.stats?.data?.property_details?.street_1?.length >
                        0 &&
                        props?.stats?.data?.property_details?.street_1 +
                          ","}{" "}
                      {props?.stats?.data?.property_details?.street_2?.length >
                        0 &&
                        props?.stats?.data?.property_details?.street_2 + ","}
                      {props?.stats?.data?.property_details?.landmark?.length >
                        0 &&
                        props?.stats?.data?.property_details?.landmark +
                          ","}{" "}
                      {props?.stats?.data?.property_details?.district?.length >
                        0 &&
                        props?.stats?.data?.property_details?.district + ","}
                      {props?.stats?.data?.property_details?.country?.length >
                        0 &&
                        props?.stats?.data?.property_details?.country + ","}
                      -{props?.stats?.data?.property_details?.zipcode + "."}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <Box className={classes.addressflexBox}>
                  <PhoneImg />
                  <Box>
                    <Typography className={classes.addressDeatils}>
                      {
                        props?.stats?.data?.property_details
                          ?.mobile_country_code
                      }
                      - {props?.stats?.data?.property_details?.mobile_phone}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className={classes.addressflexBox}>
                  <TelephoneImg />
                  <Box>
                    <Typography className={classes.addressDeatils}>
                      {
                        props?.stats?.data?.property_details
                          ?.business_phone_country_code
                      }
                      - {props?.stats?.data?.property_details?.business_phone}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <LightTooltip
                  title={props?.stats?.data?.property_details?.email}
                  placement="top"
                >
                  <Box className={classes.addressflexBox}>
                    <EmailIMG />
                    <Box>
                      <Typography
                        noWrap
                        sx={{ width: "135px" }}
                        className={classes.addressDeatils}
                      >
                        {props?.stats?.data?.property_details?.email}
                      </Typography>
                    </Box>
                  </Box>
                </LightTooltip>
              </Grid>
              <Grid item xs={6}>
                <Box className={classes.addressflexBox}>
                  <WebSiteImg />
                  <Box>
                    <Typography className={classes.addressDeatils}>
                      {props?.stats?.data?.property_details?.website}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={2} className={classes.paddingTop}>
            {cardData?.map((val) => {
              return (
                val?.view && (
                  <Grid
                    item
                    xs={4}
                    onClick={
                      props?.permission?.read ? val?.onClick ?? false : false
                    }
                  >
                    <AggreementCardInfo t={t} data={val} />
                  </Grid>
                )
              );
            })}
          </Grid>

          <Box className={classes.parentDetailsFlexBox}>
            {listData?.map((x) => {
              return (
                <Box className={classes.detailsFlexBox}>
                  <Box className={classes.flexBox}>
                    <img src={x?.image_url} alt={x?.name} />
                    <Typography className={classes.detailsText}>
                      {t(x?.name)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className={classes.count}>
                      {x?.count}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Grid container spacing={2} className={classes.marginTop}>
            <Grid item xs={12}>
              <GraphComponent
                padding={"8px 16px"}
                t={t}
                graphTitle={t("Unit Type")}
                chartHeader={t("Unit Type")}
                graphData={props?.stats?.unit_type}
                is_pie_new
                minWidth={true}
                textMargin={"0px 0px 16px"}
                divider1={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                divider={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                gradiantStyle={{
                  margin: "15px 0px 0px",
                  top: { lg: "auto", md: "16px", sm: "3px" },
                  height: { md: "400px" },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <GraphComponent
                padding={"8px 16px"}
                t={t}
                graphTitle={t("Unit Category")}
                chartHeader={t("Unit Category")}
                graphData={props?.stats?.unit_catagory}
                is_pie_new
                minWidth={true}
                textMargin={"0px 0px 16px"}
                divider1={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                divider={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                gradiantStyle={{
                  margin: "15px 0px 0px",
                  top: { lg: "auto", md: "16px", sm: "3px" },
                  height: { md: "400px" },
                }}
              />
            </Grid>
          </Grid>
          {props?.stats?.data?.amenities?.length > 0 && (
            <Box className={classes.aminitiesParent}>
              <Typography className={classes.aminitiesDeatils}>
                {t("Properties Amenities")}
              </Typography>
              <Grid container spacing={2}>
                {props?.stats?.data?.amenities?.map((x) => {
                  return (
                    <Grid item xs={6}>
                      <Typography className={classes.amenitieslist}>
                        {x}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          )}

          {/* <Box className={classes.aminitiesParent}>
        <Typography className={classes.aminitiesDeatils}>
          Location NearBy
        </Typography>
        <Grid container spacing={3}>
          {location?.map((x) => {
            return (
              <Grid item xs={6}>
                <Box className={classes.flexBox}>
                  <Location />
                  <Typography className={classes.detailsText}>
                    {x?.name}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box> */}

          {/* <Box className={classes.revenueBorder}>
            <Box className={classes.revenueParent}>
              <Typography className={classes.aminitiesDeatils}>
                Revenue
              </Typography>
              <div className={classes.graphPadding}>
                <GroupPieCharts data={barData} />
              </div>
              <Box className={classes.textCenter}>
                <Typography className={classes.detailsText}>
                  This Year Renvenue
                </Typography>
                <Typography className={classes.totalCount}>$ 20000</Typography>
              </Box>
            </Box>
          </Box> */}
        </Box>
      )}
    </>
  );
};
