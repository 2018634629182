import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: `0px 0px 16px #00000014`,
    backgroundColor: "white",
    padding: "16px",
    margin: "14px",
    height: `calc(100vh - 147px)`,
  },
  formRoot: {
    backgroundColor: "white",
    width: "408px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  img: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "13px",
    height: "40px",
  },
  tableMain: {
    padding: "0px 16px !important",
  },
  drawerTitle: {
    color: "#091B29",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().extraBold,
  },
  bottombtn: {
    padding: "12px 19px",
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #E4E8EE",
    position: "fixed",
    bottom: "0px",
    width: "inherit",
    maxWidth:"408px",
    zIndex: 2,
    alignItems: "center",
  },
  createbtn: {
    fontFamily: FontFamilySwitch().semiBold,
    color: "#fff",
    width:"100%",
    backgroundColor: "#5078E1",
    height: "40px",
  },
}));
