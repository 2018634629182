import React from 'react';
import styled from "@mui/material/styles/styled";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { CustomTypography } from './customTypography';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FontFamilySwitch } from '../../utils';
import { withTranslation } from 'react-i18next';
const StickyHeader = styled('div')({
    position: 'sticky',
    top: 0,
    backgroundColor: "#fff",
    zIndex: 1
});

const Header = (props) => {

    const {
        onCloseDrawer = null,
        onClear = null,
        t
    } = props;

    return (
        <StickyHeader>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={2}
                padding={2}
            >
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    gap={1}>
                    <IconButton sx={{ padding: 0 }}
                        onClick={onCloseDrawer}>
                        <CloseIcon fontSize={"16px"} htmlColor="#7C8594" />
                    </IconButton>
                    <CustomTypography fontSize={16} fontFamily={FontFamilySwitch().bold}>{t("Filter")}</CustomTypography>
                </Stack>
                <CustomTypography sx={{ cursor: "pointer" }} color={"#5078E1"} fontFamily={FontFamilySwitch().semiBold} onClick={onClear}>{t("Clear")}</CustomTypography>
            </Stack>
            <Divider />
        </StickyHeader>
    )
}
export default withTranslation("filterComponent")(Header)