import { Box, Grid, IconButton, Stack, Button, Drawer } from "@mui/material";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  FilterGenerator,
  LoadingSection,
  SearchFilter,
  Subheader,
  TableWithPagination,
  UseDebounce,
} from "../../components";
import AddApproval from "./createApproval";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import {
  LocalStorageKeys,
  accessCheckRender,
  getCompanyOption,
  getRoutePermissionNew,
  approvalWorkflowHeading,
  NetWorkCallMethods,
  approvalWorkflowType,
} from "../../utils";
import { useStyles } from "./style";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { NewLoader } from "../../components/newLoader";

const ApprovalWorkflow = ({ t }) => {
  const levelOptions = [
    { label: t("None"), value: "0" },
    { label: t("Level 1"), value: "1" },
    { label: t("Level 2"), value: "2" },
    { label: t("Level 3"), value: "3" },
    { label: t("Level 4"), value: "4" },
    { label: t("Level 5"), value: "5" },
  ];
  const navigate = useNavigate();
  // context
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext);
  const permission = React.useRef([]);
  const debounce = UseDebounce();
  const classes = useStyles();
  // ----------------------------------------STATES---------------------------------------------------
  const [company, setCompany] = useState({
    companyList: [],
    SelectedCompany: {},
    searchText: "",
    drawer: false,
  });
  const [approvalWorkflow, setApprovalWorkflow] = useState([]);
  const [selectedApprovalId, setSelectedApprovalId] = useState(null);
  const [approvalWorkflowData, setApprovalWorkflowData] = useState({
    no_of_levels: "",
    error: {
      no_of_levels: "",
    },
  });
  const [searchText, setSearchText] = React.useState("");
  const closeDrawer = () => {
    setDialog(!dialog);
  };
  const [totalCount, setTotalCount] = useState(0);
  const [filterDrawer, setFilterDrawer] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  const [dialog, setDialog] = React.useState(false);
  const [filterData, setFilterData] = useState({
    levels: [],
  });
  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");
  const limitParam = searchParams.get("limit");

  // Default values if not set in URL
  const pageFromParams = pageParam ? parseInt(pageParam, 10) : 1;
  const limitFromParams = limitParam ? parseInt(limitParam, 10) : 10;

  const [page, setPage] = React.useState(pageFromParams);
  const [limit, setLimit] = React.useState(limitFromParams);

  const updatePage = (newPage, newLimit = limit) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", newPage.toString());
    searchParams.set("limit", newLimit.toString());

    // Update URL without reloading
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });

    // Calculate offset
    const newOffset = (newPage - 1) * newLimit;

    // Update state
    setPage(newPage);
    setLimit(newLimit);

    // Fetch the updated data
    getApprovalWorkflow(newOffset, newLimit);
  };

  // pagination
  const handlePagination = (newPage) => {
    updatePage(newPage, limit);
  };

  const handleChangeLimit = (newLimit) => {
    updatePage(1, newLimit); // Reset page to 1 when limit changes
  };
  //   initial company update
  const updateState = (k, v) => {
    setCompany({ ...company, [k]: v });
  };
  //   company selection
  const companySelect = (e) => {
    updateState("SelectedCompany", e);
  };

  const getApprovalWorkflow = (
    offset,
    limit,
    company_id,
    filterData,
    search = ""
  ) => {
    setLoader(true);
    const payload = {
      offset: 0,
      limit: 10,
      company_id: company_id ?? company?.SelectedCompany?.value,
      filterData: filterData,
      search,
    };
    NetworkCall(
      `${config.api_url}/approval_workflow/get_approval_workflows`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        if (res?.data?.data) {
          setApprovalWorkflow(res?.data?.data);
          setTotalCount(res?.data?.count);
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const approvalWorkflowRows = approvalWorkflow.map((item) => {
    return {
      id: item.id,
      name: t(item?.workflow_type),
      level: item?.no_of_levels === "0" ? "None" : item?.no_of_levels,
    };
  });

  // use effect to get permission
  React.useEffect(() => {
    const tempPermission = getRoutePermissionNew(auth);
    // permission.current = tempPermission;
    if (tempPermission) {
      permission.current = tempPermission;
      let company = getCompanyOption(backdrop, auth, alert);
      if (company) {
        setCompany({
          ...company,
          companyList: company?.list,
          SelectedCompany: company?.selected,
        });
        getApprovalWorkflow(0, 10, company?.selected?.value);
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  const getApprovalWorkflowById = () => {
    setLoading(true);
    const payload = {
      workflow_id: selectedApprovalId,
      company_id: company?.SelectedCompany?.value,
    };
    NetworkCall(
      `${config.api_url}/approval_workflow/get_approval_workflow_by_id`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        if (res?.data?.data) {
          const fetchedData = res?.data?.data?.levels;
          const workflowType = res?.data?.data?.workflow_type;
          const workflowId = res?.data?.data?.workflow_id;

          // Dynamically set None if no_of_levels === 0
          const modifiedData = fetchedData.map((item) => ({
            ...item,
            no_of_levels: item.value === 0 ? "None" : String(item.value),
          }));

          setApprovalWorkflowData({
            id: workflowId,
            workflow_type: workflowType,
            levels: modifiedData,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const handleIcon = (id, type) => {
    setDialog(true);
    setSelectedApprovalId(type?.id);
  };

  const onApplyFilter = (data) => {
    getApprovalWorkflow(0, 10, company?.SelectedCompany?.value, data);
    setFilterData(data);
  };

  const handleSearch = (e) => {
    setSearchText(e);
    debounce(
      () => getApprovalWorkflow(0, 10, company?.SelectedCompany?.value, [], e),
      800
    );
  };

  const render = () => {
    return (
      <div>
        {/* sub Navbar */}
        <Subheader
          title={t("Approval Workflow")}
          select
          hideBackButton={true}
          options={company?.companyList}
          value={company?.SelectedCompany}
          placeholder={t("Search")}
          onchange={(e) => {
            companySelect(e);
            getApprovalWorkflow(0, 10, e.value);
          }}
          companyId={company?.SelectedCompany?.value}
        />
        {loader ? (
          <NewLoader />
        ) : (
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box>
                  <SearchFilter
                    handleChange={(value) => handleSearch(value)}
                    value={searchText}
                    placeholder={t("Search")}
                  />
                </Box>
              </Grid>

              <Grid item xs={8}>
                <Box display="flex" alignItems="center" justifyContent="end">
                  <Stack
                    direction="row"
                    spacing="0.6"
                    display="flex"
                    alignItems="center"
                  >
                    <Box onClick={() => setFilterDrawer(true)}>
                      <IconButton size="small" className={classes.img}>
                        <img src="/images/filter.svg" alt="filter" />
                      </IconButton>
                    </Box>
                  </Stack>

                  <Box width={"15px"} />
                </Box>
              </Grid>

              <Grid item xs={12} className={classes.tableMain}>
                <TableWithPagination
                  heading={approvalWorkflowHeading(t)}
                  rows={approvalWorkflowRows}
                  dataType={approvalWorkflowType}
                  showpagination={true}
                  height={"calc(100vh - 300px)"}
                  view={true}
                  edit={true}
                  delete={false}
                  handleIcon={handleIcon}
                  limit={limit}
                  page={page}
                  tableType="no-side"
                  handlePagination={handlePagination}
                  handleChangeLimit={handleChangeLimit}
                  totalRowsCount={totalCount}
                />
              </Grid>

              {/* end Main tableData */}

              <FilterGenerator
                open={filterDrawer}
                onClose={() => setFilterDrawer(false)}
                onApply={(value) => onApplyFilter(value)}
                components={[
                  {
                    component: "select",
                    value: filterData?.levels,
                    isMulti: true,
                    label: t("Level"),
                    placeholder: t("Select Level"),
                    options: levelOptions,
                    state_name: "levels",
                  },
                ]}
              />
              <Drawer
                anchor={"right"}
                open={dialog}
                onClose={closeDrawer}
                sx={{ width: "300px" }}
              >
                <AddApproval
                  t={t}
                  companyValue={company?.SelectedCompany?.value}
                  approvalWorkflowData={approvalWorkflowData}
                  getApprovalWorkflowById={getApprovalWorkflowById}
                  closeDrawer={closeDrawer}
                  loading={loading}
                  setLoading={setLoading}
                  getApprovalWorkflow={getApprovalWorkflow}
                />
              </Drawer>
            </Grid>
          </div>
        )}
      </div>
    );
  };
  return <div> {accessCheckRender(render, permission?.current)}</div>;
};
export default withTranslation("approvalWorkflow")(ApprovalWorkflow);
