import { Avatar, Box, Grid, Typography } from "@mui/material";
import styled from "@mui/material/styles/styled";
import React from "react";
import { withTranslation } from "react-i18next";
import "../../App.css";
import {
  Editor,
  MapFieldsOnly,
  MobileNumberInputComponent,
  SelectBox,
  TextBox,
  ToggleButtonComponent,
} from "../../components";
import { FontFamilySwitch } from "../../utils";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { GenderOptionList } from "../../utils/domesticHelper/domesticHelperUtils";
import { CustomTypography, useStyles } from "./style";
import { ValidateEmail } from "../../utils/validations";

const Label = styled(Typography)(({ theme }) => ({
  color: theme.typography.color.tertiary,
  fontSize: "0.75rem",
  fontFamily: FontFamilySwitch().semiBold,
}));

const DomesticHelperDetails = (props) => {
  const { t } = props;
  // const [fullScreenMap, setFullScreenMap] = React.useState(false)
  const classes = useStyles();
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);
  const [emailError, setEmailError] = React.useState("");

  // const [enumValue, setEnum] = React.useState({
  //       company_registration: [],
  //       bank_account_type: [],
  //       bank_routing_type: []
  // })
  const buttonStyle = {
    height: "45px",
    minWidth: "45px",
    borderColor: "#E4E8EE",
    borderRadius: 1,
    backgroundColor: "#fff",
  };
  const YesNOOptions = [
    { label: t("Yes"), value: true },
    { label: t("No"), value: false },
  ];

  // Function for network callback for Async Select Options
  // const getEnum = async () => {
  //       const result = await enumSelect([enum_types.company_registration, enum_types.bank_account_type, enum_types.bank_routing_type])
  //       setEnum({
  //             company_registration: result.company_registration,
  //             bank_account_type: result.bank_account_type,
  //             bank_routing_type: result.bank_routing_type
  //       })
  // }
  // React.useEffect(() => {
  //       getEnum()
  // }, [])
  // React.useEffect(() => {
  //       document.addEventListener("fullscreenchange", existFullscreen)
  //       return () => {
  //             document.removeEventListener("fullscreenchange", existFullscreen)

  //       }

  // }, [])
  // //exist full screen
  // const existFullscreen = () => {
  //       if (document.fullscreenElement === null) {
  //             setFullScreenMap(false)
  //       }

  // }
  // //open full screen
  // const fullScreenControl = () => {
  //       setFullScreenMap(true)
  //       document.getElementsByClassName('map-wrapper')[0]?.requestFullscreen()

  // }
  const handleEmailChange = (e) => {
    const email = e.target.value;
    props.updateState("email", email);
    if (email && !ValidateEmail(email)) {
      setEmailError(t("Please enter a valid email address"));
    } else {
      setEmailError("");
    }
  };

  const manualProfessionResponse = (array) => {
    return array?.profession_master;
  };
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={2}>
          <Box className={classes.imagebox}>
            <Typography className={classes.title}>
              {t("PROFILEPICTURE")}
            </Typography>
            <Avatar
              src={
                props?.data?.image?.length > 0
                  ? props?.data?.image
                  : "/images/citynew.svg"
              }
              style={{ margin: "0 auto" }}
              className={
                props?.data?.image?.length > 0
                  ? classes.avatar
                  : classes.avatar1
              }
            />
            <label>
              {" "}
              <Box
                className={classes.button}
                variant="contained"
                style={{ marginTop: "10px" }}
              >
                {" "}
                {t("Uploadimage")}
              </Box>{" "}
              <input
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                onClick={(event) => {
                  event.target.value = null;
                }}
                onChange={(e) => props?.updateimg(e?.target?.files?.[0])}
              />
            </label>
            {props?.data?.image?.length > 0 ? (
              <Typography
                className={classes.removeimg}
                onClick={props?.removeimg}
              >
                {t("RemoveImage")}
              </Typography>
            ) : props?.data?.error?.image?.length > 0 ? (
              <span style={{ fontSize: "0.75rem", color: "red" }}>
                {/* {t("ProfileImageisrequired")} */}
              </span>
            ) : (
              <span style={{ fontSize: "0.75rem" }}>
                <Box height={"18px"} />
              </span>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={8} lg={10}>
          <Box className={classes.secondbox}>
            <Typography className={classes.title}>
              {t("PROFILEDETAILS")}
            </Typography>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12} md={4} lg={3}>
                <TextBox
                  isrequired
                  isReadonly
                  label={t("Company")}
                  placeholder={t("EnterCompany")}
                  value={props?.data?.company?.label ?? ""}
                  onChange={(value) => props?.updateState("company", value)}
                  isError={props?.data?.error?.company?.length > 0}
                  errorMessage={props?.data?.error?.company}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <SelectBox
                  isReadOnly
                  isRequired
                  label={t("Property")}
                  placeholder={t("SelectProperty")}
                  value={props?.data?.property}
                  onChange={(value) => props?.updateState("property", value)}
                  // loading={loading === "property"}
                  // isPaginate
                  // debounceTimeout={800}
                  // loadOptions={(search, array) => loadOptionss(search, array, 'property')}
                  isError={props?.data?.error?.property?.length > 0}
                  errorMessage={props?.data?.error?.property}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextBox
                  isrequired
                  label={t("Name")}
                  placeholder={t("EnterName")}
                  value={props?.data?.name ?? ""}
                  onChange={(e) => props?.updateState("name", e.target.value)}
                  isError={props?.data?.error?.name?.length > 0}
                  errorMessage={props?.data?.error?.name}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <SelectBox
                  isRequired
                  label={t("Gender")}
                  placeholder={t("SelectGender")}
                  options={GenderOptionList ?? []}
                  value={props?.data?.gender ?? ""}
                  onChange={(value) => props?.updateState("gender", value)}
                  isError={props?.data?.error?.gender?.length > 0}
                  errorMessage={props?.data?.error?.gender}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <SelectBox
                  isRequired
                  label={t("Nationality")}
                  placeholder={t("Enter Nationality")}
                  value={props?.data?.nationality ?? null}
                  isPaginate
                  debounceTimeout={800}
                  loadOptions={(search, array, handleLoading) =>
                    loadOptionsApis(
                      "queries/opportunity/country_master",
                      {},
                      search,
                      array,
                      handleLoading,
                      "data"
                    )
                  }
                  onChange={(value) => props?.updateState("nationality", value)}
                  isError={props?.data?.error?.nationality?.length > 0}
                  errorMessage={props?.data?.error?.nationality}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <SelectBox
                  isRequired
                  label={t("Profession")}
                  placeholder={t("SelectProfession")}
                  value={props?.data?.profession}
                  onChange={(value) => props?.updateState("profession", value)}
                  key={JSON.stringify(props?.data?.profession?.value)}
                  loading={loading === "profession_master"}
                  isPaginate
                  debounceTimeout={800}
                  loadOptions={(search, array, handleLoading) =>
                    loadOptionsApis(
                      "queries/profession_master/list",
                      {
                        company_id: props?.data?.company?.value,
                      },
                      search,
                      array,
                      handleLoading,
                      "data",
                      {},
                      manualProfessionResponse
                    )
                  }
                  isError={props?.data?.error?.profession?.length > 0}
                  errorMessage={props?.data?.error?.profession}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Label
                  variant="body1"
                  color="textsecondary"
                  align="left"
                  gutterBottom
                >
                  {t("Barred")}
                  <Typography variant="caption" className={classes.required}>
                    *
                  </Typography>
                </Label>
                <ToggleButtonComponent
                  options={YesNOOptions}
                  value={props?.data?.barred ?? []}
                  onChange={(value) => props?.updateState("barred", value)}
                  isMulti={false}
                  fullWidth={false}
                  buttonStyle={buttonStyle}
                  isError={props?.data?.error?.barred?.length > 0 ?? false}
                  errorMessage={props?.data?.error?.barred ?? ""}
                />
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "10px" }}>
              <Grid item xs={12}>
                <Editor
                  value={props?.data?.description ?? ""}
                  label={t("Description")}
                  handleChange={(e, delta, source, editor) => {
                    props?.updateState("description", e);
                  }}
                  id={props?.id}
                  height={"80px"}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <div className="map-wrapper">
        <Box
          className={classes.imagebox1}
          style={{ marginTop: "24px", position: "relative" }}
        >
          <CustomTypography>{t("Address")}</CustomTypography>
          {props?.load && (
            <MapFieldsOnly
              fields={[
                {
                  label: t("DoorNumber"),
                  component: "TextField",
                  value: props?.data?.doorNo,
                  state_name: "doorNo",
                  isrequired: false,
                  placeholder: t("EnterDoorNumber"),
                  error: props?.data?.error?.doorNo,
                  errorMessage: props?.data?.error?.doorNo?.length > 0,
                  breakpoints: { sm: 6, md: 3 },
                },
                {
                  label: t("AddressLine1"),
                  component: "TextField",
                  value: props?.data?.addressLineOne,
                  state_name: "addressLineOne",
                  isrequired: false,
                  placeholder: t("EnterAddressLine1"),
                  errorMessage: props?.data?.error?.addressLineOne,
                  error: props?.data?.error?.addressLineOne?.length > 0,
                  breakpoints: { sm: 6, md: 3 },
                },
                {
                  label: t("AddressLine2"),
                  component: "TextField",
                  value: props?.data?.addressLineTwo,
                  state_name: "addressLineTwo",
                  isrequired: false,
                  placeholder: t("EnterAddressLine2"),
                  errorMessage: props?.data?.error?.addressLineTwo,
                  error: props?.data?.error?.addressLineTwo?.length > 0,
                  breakpoints: { sm: 6, md: 3 },
                },
                {
                  label: t("Landmark"),
                  component: "TextField",
                  value: props?.data?.landmark,
                  state_name: "landmark",
                  isrequired: false,
                  placeholder: t("EnterLandmark"),
                  errorMessage: props?.data?.error?.landmark,
                  error: props?.data?.error?.landmark?.length > 0,
                  breakpoints: { sm: 6, md: 3 },
                },
                {
                  label: t("Area"),
                  component: "TextField",
                  value: props?.data?.area,
                  state_name: "area",
                  isrequired: false,
                  placeholder: t("EnterArea"),
                  errorMessage: props?.data?.error?.area,
                  error: props?.data?.error?.area?.length > 0,
                  breakpoints: { sm: 6, md: 3 },
                },
                {
                  label: t("City"),
                  component: "TextField",
                  value: props?.data?.city,
                  state_name: "city",
                  isrequired: false,
                  placeholder: t("EnterCity"),
                  errorMessage: props?.data?.error?.city,
                  error: props?.data?.error?.city?.length > 0,
                  breakpoints: { sm: 6, md: 3 },
                },
                {
                  label: t("State"),
                  component: "TextField",
                  state_name: "state",
                  value: props?.data?.state,
                  isrequired: false,
                  placeholder: t("EnterState"),
                  errorMessage: props?.data?.error?.state,
                  error: props?.data?.error?.state?.length > 0,
                  breakpoints: { sm: 6, md: 3 },
                },
                {
                  label: t("Country"),
                  component: "TextField",
                  value: props?.data?.country,
                  state_name: "country",
                  isrequired: false,
                  placeholder: t("EnterCountry"),
                  errorMessage: props?.data?.error?.country,
                  error: props?.data?.error?.country?.length > 0,
                  breakpoints: { sm: 6, md: 3 },
                },
                {
                  label: t("Pincode"),
                  component: "TextField",
                  state_name: "zipcode",
                  value: props?.data?.zipcode,
                  isrequired: false,
                  placeholder: t("EnterPincode"),
                  errorMessage: props?.data?.error?.zipcode,
                  error: props?.data?.error?.zipcode?.length > 0,
                  breakpoints: { sm: 6, md: 3 },
                },
              ]}
              onChangeFields={(key, value) => props?.updateState(key, value)}
            />
          )}
        </Box>
      </div>
      <Box className={classes.imagebox1} style={{ marginTop: "24px" }}>
        <Typography className={classes.title}>
          {t("CONTACTandOTHERINFORMATION")}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={3}>
            <MobileNumberInputComponent
              label={t("PrimaryTelephone")}
              placeholder={t("PrimaryTelephone")}
              value={props?.data?.telephone}
              isRequired
              handleChange={(value) => {
                props?.updateState("telephone", value);
              }}
              isError={props?.data?.error?.telephone?.length > 0}
              errorMessage={props?.data?.error?.telephone}
              onKeyPress={(e) => {
                if (e.key === "e") {
                  e.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <MobileNumberInputComponent
              label={t("PrimaryMobile")}
              placeholder={t("PrimaryMobile")}
              value={props?.data?.mobile}
              isRequired
              handleChange={(value) => {
                props?.updateState("mobile", value);
              }}
              isError={props?.data?.error?.mobile?.length > 0}
              errorMessage={props?.data?.error?.mobile}
              onKeyPress={(e) => {
                if (e.key === "e") {
                  e.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2.5}>
            <TextBox
              isrequired
              label={t("PrimaryEmailAddress")}
              placeholder={t("EnterEmailAddress")}
              value={props?.data?.email ?? ""}
              onChange={handleEmailChange}
              isError={!!emailError || props?.data?.error?.email?.length > 0}
              errorMessage={emailError || props?.data?.error?.email}
            />
          </Grid>
        </Grid>
      </Box>
      {/* <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                  <Typography className={classes.title}>
                        {t("BANKINGBASICDETAILS")}
                  </Typography>
                  <Grid container spacing={3}>
                        <Grid item xs={12} md={3} lg={3}>
                              <TextBox
                                    label={t("BankName")}
                                    placeholder={t("EnterName")}
                                    value={props?.data?.bankName ?? ""}
                                    onChange={(value) => {
                                          props?.updateState("bankName", value?.target?.value);
                                    }} />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                              <TextBox
                                    label={t("BranchName")}
                                    placeholder={t("EnterName")}
                                    value={props?.data?.branchName ?? ""}
                                    onChange={(value) => {
                                          props?.updateState("branchName", value?.target?.value);
                                    }} />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                              <Typography className={classes.fieldLabel} noWrap>{t("BankAccountType")}</Typography>
                              <Grid container rowGap={"8px"} columnGap={"8px"}>
                                    {enumValue?.bank_account_type?.map((value) => {
                                          return <Button className={value?.value === props?.data?.accountType?.value ?
                                                classes.buttonSelected : classes.buttonUnSelected}
                                                onClick={() => props?.updateState("accountType", value)}>
                                                <Typography className={value?.value === props?.data?.accountType?.value ?
                                                      classes.buttonTextSelected : classes.buttonTextUnSelected}>
                                                      {value?.label}</Typography>
                                          </Button>
                                    })}
                              </Grid>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                              <TextBox
                                    label={t("AccountNumber")}
                                    placeholder={t("EnterNumber")}
                                    value={props?.data?.accountNo ?? ""}
                                    onChange={(value) => {
                                          props?.updateState("accountNo", value?.target?.value);
                                    }} />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                              <SelectBox
                                    label={t("BankRoutingType")}
                                    placeholder={t("SelectType")}
                                    options={enumValue.bank_routing_type}
                                    value={props?.data?.routingType ?? ""}
                                    onChange={(value) => {
                                          props?.updateState("routingType", value);
                                    }} />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                              <TextBox
                                    label={t("BankRoutingCode")}
                                    placeholder={t("EnterCode")}
                                    value={props?.data?.routingCode ?? ""}
                                    onChange={(value) => {
                                          props?.updateState("routingCode", value?.target?.value);
                                    }} />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                              <TextBox
                                    label={t("PreferredCashCollectionOffice")}
                                    placeholder={t("EnterOffice")}
                                    value={props?.data?.cashCollection ?? ""}
                                    onChange={(value) => {
                                          props?.updateState("cashCollection", value?.target?.value);
                                    }} />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                              <TextBox
                                    label={t("ChequeName")}
                                    placeholder={t("EnterName")}
                                    value={props?.data?.cheque ?? ""}
                                    onChange={(value) => {
                                          props?.updateState("cheque", value?.target?.value);
                                    }} />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                              <TextBox
                                    label={t("AddressLine1")}
                                    placeholder={t("EnterLine1")}
                                    value={props?.data?.bankAddress1 ?? ""}
                                    onChange={(value) => {
                                          props?.updateState("bankAddress1", value?.target?.value);
                                    }} />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                              <TextBox
                                    label={t("AddressLine2")}
                                    placeholder={t("EnterLine2")}
                                    value={props?.data?.bankAddress2 ?? ""}
                                    onChange={(value) => {
                                          props?.updateState("bankAddress2", value?.target?.value);
                                    }} />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                              <TextBox
                                    label={t("City")}
                                    placeholder={t("EnterCity")}
                                    value={props?.data?.bankCity ?? ""}
                                    onChange={(value) => {
                                          props?.updateState("bankCity", value?.target?.value);
                                    }} />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                              <TextBox
                                    label={t("Country")}
                                    placeholder={t("EnterCountry")}
                                    value={props?.data?.bankCountry ?? ""}
                                    onChange={(value) => {
                                          props?.updateState("bankCountry", value?.target?.value);
                                    }} />
                        </Grid>
                  </Grid>
            </Box> */}
      {/* <Box className={classes.imagebox1} style={{ marginTop: '24px' }}>
                  <Typography className={classes.title}>
                        {t("SOCIALPROFILE")}
                  </Typography>
                  <Grid container spacing={3}>
                        <Grid item xs={12} md={3} lg={3}>
                              <TextBox
                                    label={t("Facebook")}
                                    placeholder={t("EnterFacebook")}
                                    value={props?.data?.facebook ?? ""}
                                    onChange={(value) => {
                                          props?.updateState("facebook", value?.target?.value);
                                    }} />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                              <TextBox
                                    label={t("Twitter")}
                                    placeholder={t("EnterTwitter")}
                                    value={props?.data?.twitter ?? ""}
                                    onChange={(value) => {
                                          props?.updateState("twitter", value?.target?.value);
                                    }} />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                              <TextBox
                                    label={t("Linkedin")}
                                    placeholder={t("EnterLinkedin")}
                                    value={props?.data?.linkedin ?? ""}
                                    onChange={(value) => {
                                          props?.updateState("linkedin", value?.target?.value);
                                    }} />
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                              <TextBox
                                    label={t("Whatsapp")}
                                    placeholder={t("EnterWhatsapp")}
                                    value={props?.data?.whatsapp ?? ""}
                                    onChange={(value) => {
                                          props?.updateState("whatsapp", value?.target?.value);
                                    }} />
                        </Grid>
                  </Grid>
            </Box> */}
    </div>
  );
};

export default withTranslation("domesticHelper")(DomesticHelperDetails);
