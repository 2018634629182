export const JobsPath = ["name", "description", "status"]

export const JobsHeading = (t) => [
    { title: t("Name"), field: "name" },
    { title: t("Notes"), field: "description" },
    { title: t("Status"), field: "status", },
    { title: "", field: "icon" },
]

export const JobsType = [
    { type: ["text"], name: "name" },
    { type: ["text"], name: "description" },
    { type: ["status"], name: "status" },
    { type: ["more"], icon: "icon" },
]

export const StatusOptionListMap = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
]