import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Popover from '@mui/material/Popover';
import Stack from "@mui/material/Stack";
import styled from "@mui/material/styles/styled";
import React, { useContext, useEffect, useMemo, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalenderIcon, CustomTypography } from '.';
import { AlertContext } from '../../../contexts';
import { AlertProps } from '../../../utils';
import { FontFamilySwitch } from "../../../utils";
const Wrapper = styled(Stack)({
    border: '1px solid #E4E8EE',
    borderRadius: '4px',
    backgroundColor: '#fff',
    position: 'relative',
    // overflow: 'hidden',
    cursor: 'pointer',
    padding: '9px',
})

const Container = styled('div')({
    padding: 16,
    boxShadow: '0px 8px 24px #0717411F',
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    '& .react-datepicker': {
        border: 0,
        '& .react-datepicker__header': {
            backgroundColor: '#F2F4F7',
            borderBottom: '2px solid #2579ba'
        },
    }
})

const CustomDivider = styled(Divider)({
    marginBlock: '16px'
})

export const CustomRangePicker = (props) => {

    const {
        label,
        placeholder = "Choose Date",
        value = null,
        onChange = null,
        data = "",
        startDateTitle = "",
        endDateTitle = "",
        flex = false
    } = props;

    const alert = useContext(AlertContext);
    const [anchorEl, setAnchorE1] = useState(null)
    const [state, setState] = useState({});

    useEffect(() => {
        if (anchorEl) {
            setState(value)
        }
    }, [value, anchorEl])

    const onChangeValue = (key, val) => {
        let updateValue = {
            ...state,
            [key]: val
        }
        setState(updateValue)
    }


    const onClear = () => {
        setState({})
    }

    const onApply = () => {
        if (state?.startDate && state?.endDate) {
            onChange && onChange(state);
            setAnchorE1(null)
        }
        else {
            showAlert('Please select start and end date')
        }
    }


    const showAlert = (msg = "") => {
        alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center
        })
    }

    const onClose = () => {
        onChange && onChange(value);
        setAnchorE1(null)
    }

    const returnValue = useMemo(() => Boolean(data?.replace('-', '')?.trim()?.length), [data]);


    return (
        <div>

            <Wrapper onClick={e => setAnchorE1(e.target)}>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    gap={1}
                >


                    <div style={{ flex: 1, width: '80%' }}>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                        >
                            {
                                !flex && <CalenderIcon />
                            }
                            {label &&
                                <CustomTypography
                                    fontFamily={FontFamilySwitch().semiBold}
                                    fontSize={12}
                                    color={"#98A0AC"}
                                >
                                    {label}
                                </CustomTypography>
                            }
                        </Stack>
                        <CustomTypography
                            fontFamily={returnValue ? FontFamilySwitch().semiBold : FontFamilySwitch().regular}
                            fontSize={14}
                            color={returnValue ? '#091B29' : '#98A0AC'}
                            noWrap={!returnValue}
                            marginTop={'3px'}
                        >
                            {returnValue ? data : placeholder}
                        </CustomTypography>
                    </div>

                    {
                        flex && <CalenderIcon />
                    }
                </Stack>
            </Wrapper>
            {props.isError && (
                <Typography marginTop="4px" variant={"caption"} color={"error"}>
                    {props.errorMessage}
                </Typography>
            )}
            {
                anchorEl &&
                <Popover
                    open={true}
                    anchorEl={anchorEl}
                    onClose={onClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Container>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={2}
                        >
                            <div>
                                {
                                    startDateTitle &&
                                    <CustomTypography
                                        color={"#4E5A6B"}
                                        fontSize={12}
                                        fontFamily={FontFamilySwitch().bold}
                                        marginBottom={8}
                                    >
                                        {startDateTitle}
                                    </CustomTypography>
                                }

                                <DatePicker
                                    minDate={new Date()}
                                    selected={state?.startDate ?? ''}
                                    onChange={(value) => onChangeValue('startDate', value)}
                                    inline
                                    peekNextMonth
                                    dropdownMode="select"
                                    showMonthDropdown
                                    showYearDropdown
                                />

                            </div>
                            <div>
                                {
                                    endDateTitle &&
                                    <CustomTypography
                                        color={"#4E5A6B"}
                                        fontSize={12}
                                        fontFamily={FontFamilySwitch().bold}
                                        marginBottom={8}
                                    >
                                        {endDateTitle}
                                    </CustomTypography>
                                }


                                <DatePicker
                                    minDate={state?.startDate ? state?.startDate : new Date()}
                                    maxDate={state?.startDate ? null : new Date()}
                                    excludeDates={state?.startDate ? null : [new Date()]}
                                    selected={state?.endDate ?? ''}
                                    onChange={(value) => onChangeValue('endDate', value)}
                                    inline
                                    peekNextMonth
                                    dropdownMode="select"
                                    showMonthDropdown
                                    showYearDropdown
                                    disabled={!state?.startDate}
                                />
                            </div>
                        </Stack>
                        <CustomDivider sx={{ marginTop: 0, marginBottom: '12px' }} />
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                        >
                            <CustomTypography
                                color={"#071741"}
                                fontSize={12}
                                fontFamily={FontFamilySwitch().bold}
                                sx={{ cursor: 'pointer', userSelect: 'none' }}
                                onClick={onClear}
                            >
                                Clear
                            </CustomTypography>
                            <CustomTypography
                                color={"#5078E1"}
                                fontSize={12}
                                fontFamily={FontFamilySwitch().semiBold}
                                sx={{ cursor: 'pointer', userSelect: 'none' }}
                                onClick={onApply}
                            >
                                Apply
                            </CustomTypography>
                        </Stack>
                    </Container>
                </Popover>
            }
        </div>
    )
}

