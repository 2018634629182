import { getCustomBlockName, getCustomFloorName } from "./common";

export const BlockPath = [
    "blockNo",
    "blockname",
    "companyname",
    "location",
    "blocks",
    "floors",
    "revenuetype",
    "blocktype",
    "status"
];
export const BlockPathView = [
    "image",
    "blockNo",
    "blockname",
    "description",
    "blocks",
    "floors",
];

export const BlockHeadingView = [
    { title: "Image", field: "image" },
    { title: "Block Number", field: "blockNo", type: "numeric" },
    { title: "Block Name", field: "blockname" },
    { title: "Description", field: "description" },
    { title: "Floors", field: "floors" },
    { title: "Units", field: "units" },
];
export const BlockHeadingViewNew = (property_hierarchy) => {
    let block_name = getCustomBlockName(property_hierarchy)
    let floor_name = getCustomFloorName(property_hierarchy, "Floors")
    return [
        { title: "Image", field: "image" },
        { title: block_name + " Number", field: "blockNo", type: "numeric" },
        { title: block_name + " Name", field: "blockname" },
        { title: "Description", field: "description" },
        { title: floor_name, field: "floors" },
        { title: "Units", field: "units" },
    ]
};
export const BlockHeading = [
    { title: "Block Number", field: "blockNo", type: "numeric" },
    { title: "Block Name", field: "blockname" },
    { title: "Company Name", field: "companyname" },
    { title: "Location", field: "location" },
    { title: "Floors", field: "floors" },
    { title: "Units", field: "units" },
    { title: "Revenue type", field: "revenuetype" },
    { title: "Block Type", field: "blocktype" },
    { title: "Status", field: "status" },
    { title: "", field: "icon" },
];

export const BlockHeadingNew = (property_hierarchy) => {
    let block_name = getCustomBlockName(property_hierarchy)
    let floor_name = getCustomFloorName(property_hierarchy, "Floors")
    return [
        { title: block_name + " Image", field: "image" },
        { title: block_name + " Number", field: "blockNo", type: "numeric" },
        { title: block_name + " Name", field: "blockname" },
        { title: "Company Name", field: "companyname" },
        { title: "Location", field: "location" },
        { title: floor_name, field: "floors" },
        { title: "Units", field: "units" },
        { title: "Revenue type", field: "revenuetype" },
        { title: block_name + " Type", field: "blocktype" },
        { title: "Status", field: "status" },
        { title: "", field: "icon" },
    ]
};

export const FloorPath = ["floorNo", "floorname", "companyname", 'location', 'blockname', "unit", "revenutype", "propertytype", "status"];
export const FloorHeading = [
    { title: "Floor Number", field: "floorNo", },
    { title: "Floor Name", field: "floorname" },
    { title: "Company Name", field: "companyname" },
    { title: "Location", field: "location" },
    { title: "Block Name", field: "floorname" },
    { title: "Unit", field: "unit" },
    { title: "Revenue Type", field: "revenutype" },
    { title: "Property Type", field: "propertytype" },
    { title: "Status", field: "status" },
    { title: "", field: "icon" },
];
export const FloorHeadingNew = (property_hierarchy, t) => {
    let block_name = getCustomBlockName(property_hierarchy)
    let floor_name = getCustomFloorName(property_hierarchy)
    return [
        { title: t("Image"), field: "image", },
        { title: floor_name + " Number", field: "floorNo", },
        { title: floor_name + " Name", field: "floorname" },
        { title: "Company Name", field: "companyname" },
        { title: "Location", field: "location" },
        { title: block_name + " Name", field: "floorname" },
        { title: "Unit", field: "unit" },
        { title: "Revenue Type", field: "revenutype" },
        // { title: "Property Type", field: "propertytype" },
        { title: "Status", field: "status" },
        { title: "", field: "icon" },
    ]
};
export const FloorPathView = ["image", "floorNo", "floorname", 'description', 'blockname', "unit"];
export const FloorHeadingView = [
    { title: "Image", field: "image", },
    { title: "Floor Name", field: "floorname" },
    { title: "Block Name", field: "Blockname" },
    { title: "Description", field: "description" },
    { title: "Floor Number", field: "floorNo", },
    { title: "Unit", field: "unit" },
];

export const FloorHeadingViewNew = (property_hierarchy, t) => {
    let block_name = getCustomBlockName(property_hierarchy)
    let floor_name = getCustomFloorName(property_hierarchy)
    return [
        { title: t("Image"), field: "image", },
        { title: floor_name + " " + t("Name"), field: "floorname" },
        { title: block_name + " " + t("Name"), field: "Blockname" },
        { title: t("Description"), field: "description" },
        { title: floor_name + " " + t("Number"), field: "floorNo", },
        { title: t("Unit"), field: "unit" },
    ]
};

export const Unitpath = [
    "unitNo", "unitName", "unitcategory", "revenuetype",
    "totalarea", "occupancyStatus"
]


export const Unitheading = (t) => [
    { title: "", field: 'icon' },
    { title: t("Unit Number"), field: "unitNo", },
    { title: t("Unit Name"), field: "unitName", },
    { title: t("Category"), field: 'unitcategory', },
    { title: t("Revenue Type"), field: "revenuetype", },
    { title: t("Saleable Area"), field: "totalarea", },
    { title: t("Occupancy"), field: "occupancyStatus", },
]

export const UnitpathView = ["image", "unitNo", "unitName", "floorName", "blockName", "description", "category", "totalarea", "status"]


export const UnitheadingView = [
    { title: "Image", field: "image", },
    { title: "Unit Name", field: "unitName", },
    { title: "Description", field: "description", },
    { title: "Block Name", field: "blockName", },
    { title: "Floor Name", field: "floorName", },
    { title: "Unit Number", field: "unitNo", },
    { title: "Unit Status", field: "status", },
    { title: "", field: 'icon' },
]

export const UnitheadingViewNew = (property_hierarchy, t) => {
    let block_name = getCustomBlockName(property_hierarchy)
    let floor_name = getCustomFloorName(property_hierarchy)
    return [
        { title: t("Image"), field: "image", },
        { title: t("Unit Name"), field: "unitName", },
        { title: t("Description"), field: "description", },
        { title: block_name + " " + t("Name"), field: "blockName", },
        { title: floor_name + " " + t("Name"), field: "floorName", },
        { title: t("Unit Number"), field: "unitNo", },
        { title: t("Category"), field: "unitpurpose", },
        { title: t("Saleable Area") + " ", field: "totalarea", },
        { title: t("Unit Status"), field: "status", },
    ]
}

export const PricingTableUnitpath = ["unitNo", "unitName", "unitcategory", "revenuetype", "unitpurpose", "status"]


export const PricingTableUnitheading = (t) => [
    { title: t("Unit Number"), field: "unitNo", },
    { title: t("Unit Name"), field: "unitName", },
    { title: t("Unit Category"), field: 'unitcategory', },
    { title: t("Revenue Type"), field: "revenuetype", },
    { title: t("Unit Purpose"), field: "unitpurpose", },
    { title: t("Status"), field: "status", },
]

export const UnitChidpath = ["available_from", "available_to", "status"]


export const UnitChidheading = (t) => [
    { title: t("Start Date"), field: "available_from", },
    { title: t("End Date"), field: "available_to", },
    { title: t("Occupancy"), field: 'status', },
]