/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { AlertDialog, PropertyTabs, SelectBox, Subheader, TextBox, UseDebounce } from '../../components';
import { config } from '../../config';
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from '../../networkcall';
import { BackendRoutes } from '../../router/routes';
import { accessCheckRender, AlertProps, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods } from '../../utils';
import { TeamList } from './components';
import { teamsStyles } from "./style";
import { PropertyTable } from './tabs/property';
import { UserTable } from './tabs/user';
import { withTranslation } from 'react-i18next';

const teamsInitialData = () => {
    return {
        id: "",
        teamsName: "",
        module: "",
        edit: false,
        error: {
            teamsName: "",
            module: "",
        }
    }

}

const Teams = (props) => {
    const { handleLoading, t } = props;

    const classes = teamsStyles();
    const debounce = UseDebounce();
    const alert = React.useContext(AlertContext);
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext);
    const [teamsList, setTeamsList] = React.useState([]);
    const [teamsData, setTeamsData] = React.useState({ ...teamsInitialData() });
    const [searchText, setSearchText] = React.useState("");
    const [companyList, setCompanyList] = React.useState([]);
    const [offset, setOffset] = React.useState(0)
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const [selected, setSelected] = React.useState();
    const [value, setValue] = React.useState(1);
    const [permission, setPermission] = React.useState({})
    const [open, setOpen] = React.useState(false)
    const [module, setModule] = React.useState([]);
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)
    //updateState
    const updateState = (key, value) => {
        let error = teamsData?.error
        error[key] = ""
        setTeamsData({ ...teamsData, [key]: value, error })
    }
    //validateTeams
    const validateTeams = () => {
        let isValid = true;
        let error = teamsData.error;
        //validate teamname
        if (teamsData?.teamsName?.length === 0) {
            isValid = false;
            error.teamsName = t("Team Name is Required");
        }
        //validate module
        if (teamsData?.module?.length === 0) {
            isValid = false;
            error.module = t("Module is Required");
        }

        setTeamsData({ ...teamsData, error });
        return isValid;
    }
    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getCompany()
            }
        }
        // eslint-disable-next-line
    }, [auth]);
    //tab
    const tabTittle = [
        {
            label: t('Properties'),
            value: 1,
            className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
        },
        {
            label: t('User'),
            value: 2,
            className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
        },
    ]



    //fetch more
    const fetchMoreData = () => {
        setOffset(offset + 10)
        getTeamsByCompanyID(searchText, offset + 10, true)
    }
    //search
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    const searchTableFunction = (e) => {
        getTeamsByCompanyID(e, 0, false)
    }

    //open
    const drawerOpen = (key) => {
        if (key === "add") {
            setOpen(true)
        }
        if (key === "edit") {
            setOpen(true)
            setTeamsData({
                ...teamsData,
                teamsName: selected?.name,
                module: {
                    value: selected?.module_id, label: selected?.module_name
                },
                id: selected?.id,
                edit: true
            })

        }
    };
    //close
    const drawerClose = () => {
        setOpen(false);
        setTeamsData({ ...teamsInitialData() })
    };

    //createteams
    const createTeams = () => {
        if (validateTeams()) {
            let payload;
            setIsDisableBtn(true)
            if (teamsData?.edit) {
                payload = {
                    team_name: teamsData?.teamsName,
                    module: teamsData?.module?.value,
                    company_id: selectedCompany?.value,
                    id: teamsData?.id

                }
            } else {
                payload = {
                    team_name: teamsData?.teamsName,
                    module: teamsData?.module?.value,
                    company_id: selectedCompany?.value,
                }
            }
            NetworkCall(
                `${config.api_url}/hierarchy-team/create_team`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((res) => {
                getTeamsByCompanyID(searchText, 0, false);
                drawerClose()
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: `${teamsData?.id ? t("Teams  Successfully Updated") : t("Teams  Successfully Created")}`,
                });

            }).catch((err) => {
                drawerClose()
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something went wrong"),
                });
            })
        }

    }




    //change status
    const changeStatus = async (data) => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "", });
        const payload = {
            id: data?.id,
            update: {
                is_active: data?.is_active === false ? true : false
            },
        }
        NetworkCall(
            `${config.api_url}/queries/teams/update`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((response) => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.success,
                msg: t("Teams status changed successfully.!!!"),
            });
            getTeamsByCompanyID(searchText, 0, false);
        }).catch((err) => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "", });

            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Something Went Wrong. Internal Error.!!!"),
            });
        });
    }


    //get teams
    const getTeamsByCompanyID = (search, offset, searchBool) => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "", });
        const payload = {
            "company_id": selectedCompany?.value,
            "search": search,
            "start": offset,
            "length": 10
        }
        NetworkCall(
            `${config.api_url}/hierarchy-team/get_team`,
            NetWorkCallMethods.post,
            payload, null, true, false)
            .then((response) => {
                if (searchBool) {
                    setTeamsList(
                        teamsList.concat(response?.data?.data?.teamList)
                    );


                } else {
                    setTeamsList(response?.data?.data?.teamList);
                    setSelected(response?.data?.data?.teamList?.[0])
                }
                backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
                handleLoading(false);
            }).catch(() => {
                backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
                })
            });
    };

    //first load
    React.useEffect(() => {
        if (selectedCompany?.value) {
            getModule()
            getTeamsByCompanyID(searchText, 0, false);
            setOffset(0)

        }
    }, [selectedCompany]);
    //get company
    const getCompany = () => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
        }
    }
    //company change
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
    }

    //tab cbange
    const tabChange = (e, newValue) => {
        setValue(newValue)
    }

    //get module
    const getModule = () => {
        NetworkCall(
            `${config?.api_url}${BackendRoutes?.subscription_subscribe_modules}`,
            NetWorkCallMethods?.post, {}, null, true, false
        ).then((res) => {
            const result = res?.data?.data ?? [];
            setModule(result)
        })
    }






    //add and edit team section
    const TeamsDialog = () => {
        return (
            <>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextBox
                            label={t("Team Name")}
                            placeholder={t("Enter Team Name")}
                            value={teamsData?.teamsName}
                            isrequired
                            onChange={(e) => updateState("teamsName", e.target.value)}
                            isError={teamsData?.error?.teamsName?.length > 0}
                            errorMessage={teamsData?.error?.teamsName}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectBox
                            options={module}
                            label={t("Module")}
                            placeholder={t("Select Modules")}
                            value={teamsData?.module ?? null}
                            onChange={(value) => {
                                updateState("module", value);
                            }}
                            isError={teamsData?.error?.module?.length > 0}
                            errorMessage={teamsData?.error?.module}
                            isRequired
                            menuPlacement='bottom'
                        />
                    </Grid>
                </Grid>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />

                {/* add member button */}
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {
                            <Button variant='outlined'
                                fullWidth
                                className={classes.btn3}
                                onClick={drawerClose}
                            > {t("Cancel")}</Button>
                        }
                    </Grid>
                    <Grid item xs={6}>
                        {
                            <Button variant='contained'
                                fullWidth
                                className={classes.btn2} onClick={createTeams}
                                disabled={isDisableBtn}
                            >{teamsData?.edit ? t("Update Team") : t("Add Team")} </Button>
                        }
                    </Grid>
                </Grid>
            </>
        )
    }
    const render = () => {
        return <>
            {/*sub header */}
            <Subheader hideBackButton={true} title={t("Teams")}
                select options={companyList} value={selectedCompany} onchange={(e) => handleCompanyChange(e)} />
            {/* body */}
            <Grid container spacing={2} p={2}>
                {/* left side list */}
                <Grid item sm={5} md={5} lg={3}>
                    <Box className={teamsList?.length > 0 ? classes.box2 : classes.box}>

                        <TeamList
                            list={teamsList}
                            selected={selected?.id}
                            setSelected={setSelected}
                            fetchMoreData={fetchMoreData}
                            contactSetting
                            isSearch
                            addTeam={drawerOpen}
                            handleSearch={handleSearch}
                            changeStatus={changeStatus}
                            searchText={searchText}
                            permission={permission}
                            t={t}
                            name={t("Search")}
                        />
                    </Box>
                </Grid>
                {/* right side tables */}
                <Grid item sm={7} md={7} lg={9}>
                    <Box className={teamsList?.length > 0 ? classes.box2 : classes.box}>
                        {teamsList?.length > 0 ?
                            <>
                                {selected?.name &&
                                    <>
                                        <Grid container spacing={1}
                                            className={classes.gridRoot}
                                        >
                                            <Grid item xs={4}>
                                                <Typography className={classes.title}>{selected?.name}</Typography>
                                            </Grid>

                                            <Grid item xs={8} display={'flex'} justifyContent={'end'}>
                                                {permission.update && <Button variant="contained" className={classes.btn}
                                                    onClick={() => drawerOpen("edit")}
                                                >
                                                    {t("Edit")}
                                                </Button>}
                                            </Grid>

                                        </Grid>
                                        <PropertyTabs t={t} value={value} padding handleChange={tabChange}
                                            tab1={(
                                                <PropertyTable
                                                    companyId={selectedCompany?.value}
                                                    teamId={selected.id}
                                                    permission={permission}
                                                    t={t}

                                                />
                                            )}
                                            tab2={(
                                                <UserTable
                                                    companyId={selectedCompany?.value}
                                                    teamId={selected.id}
                                                    permission={permission}
                                                    t={t}
                                                />
                                            )}
                                            tabTittle={tabTittle}
                                            backgroundColor
                                        />
                                    </>
                                }
                            </>
                            :
                            <Typography className={classes.noData} >{t("No data found")}</Typography>

                        }
                    </Box>
                </Grid>
            </Grid>



            <AlertDialog
                open={open}
                isNormal
                header={teamsData?.edit ? t("Edit Team") : t("Add Team")}
                onClose={drawerClose}
                component={
                    <Box p={2}>
                        {TeamsDialog()}
                    </Box>
                }
            />
        </>
    }
    return <div >
        {accessCheckRender(render, permission)}
    </div >
}
export default withTranslation("teams")(Teams)

