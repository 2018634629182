import { Box, Button } from "@mui/material";
import moment from "moment";
import React from "react";
import { CustomAmount, DatePickerNew } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import {
  AlertProps,
  LocalStorageKeys,
  NetWorkCallMethods,
} from "../../../utils";
// import { SendPaymentLink } from "./sendPaymentLink";

const intialState = {
  startDate: new Date(),
  endDate: moment(new Date()).add(6, "days"),
  status: true,
  amount: "",
  error: {
    date: "",
    status: "",
    amount: "",
  },
};

export const Booking = ({
  t = () => false,
  type = {},
  onClose = () => false,
  currency = {},
  quotation_id = "",
  company_id = "",
  reload = () => false,
  is_reservation = false,
  reservationPrice = "",
  reservationWorkflowId="",
  checkApporvalStatus = () => false,
}) => {
  const [data, setData] = React.useState({ ...intialState });
  const userProfileID = localStorage.getItem("profileID");
  // const [link, sendLink] = React.useState(false);
  const alert = React.useContext(AlertContext);
  //update state
  const updateState = (key, value) => {
    let error = data.error;
    error[key] = "";
    setData({ ...data, [key]: value, error });
  };
  //validate form
  const validateForm = () => {
    let isValid = true;
    let error = data.error;
    if (data?.date?.length === 0) {
      isValid = false;
      error.date = "Booking Holding Date is Required";
    }
    if (data?.status?.length === 0) {
      isValid = false;
      error.status = "Reservation is Required";
    }
    if (
      data?.amount?.amount?.length === 0 ||
      data?.amount?.currency?.length === 0
    ) {
      isValid = false;
      error.amount = "Reservation Amount is Required";
    }
    setData({ ...data, error });
    return isValid;
  };
  //initial call
  React.useEffect(() => {
    setData({
      ...data,
      amount: {
        amount: reservationPrice ?? "",
        currency: currency,
      },
    });
    // eslint-disable-next-line
  }, []);

  //on submit
  const onSubmit = () => {
    if (validateForm()) {
      if (type?.types === "Approval") {
        const handleSendForApproval = () => {
          const sendApproval = {
            quotation_id: quotation_id,
            workflow_id: reservationWorkflowId,
            company_id: JSON.parse(
              localStorage.getItem(LocalStorageKeys.selectedCompany)
            )?.value,
            created_by: userProfileID,
            updated_by: userProfileID,
          };

          NetworkCall(
            `${config.api_url}/approval_workflow/create_workflow_approval`,
            NetWorkCallMethods.post,
            sendApproval,
            null,
            true,
            false
          )
            .then((res) => {
              checkApporvalStatus();
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Approval Request Has Been Sent"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
              });
            })
            .catch((err) => {
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: err?.response?.data?.error?.message?.message,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
              });
            });
        };
        const payload = {
          quotation_id: quotation_id,
          start_date: data?.startDate,
          end_date: data?.endDate,
          amount: data?.amount?.amount,
          currency_id: data?.amount?.currency?.value,
          company_id: company_id,
          type: "Reserved",
          is_reservation: is_reservation,
        };

        NetworkCall(
          `${config.api_url}/quotation/reserve`,
          NetWorkCallMethods.post,
          payload,
          null,
          true,
          false
        )
          .then((res) => {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.success,
              msg: `${res?.data?.message}`,
            });
            handleSendForApproval();
            onClose();
            reload();
          })
          .catch((err) => {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: `${err?.response?.data?.message}`,
            });
          });
      } else {
        const payload = {
          quotation_id: quotation_id,
          start_date: data?.startDate,
          end_date: data?.endDate,
          amount: data?.amount?.amount,
          currency_id: data?.amount?.currency?.value,
          company_id: company_id,
          type: type?.types,
          is_reservation: is_reservation,
        };

        NetworkCall(
          `${config.api_url}/quotation/reserve`,
          NetWorkCallMethods.post,
          payload,
          null,
          true,
          false
        )
          .then((res) => {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.success,
              msg: `${res?.data?.message}`,
            });

            onClose();
            reload();
          })
          .catch((err) => {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: `${err?.response?.data?.message}`,
            });
          });
    }
  }
  };

  //on dateChange
  const changeDate = (val) => {
    setData({
      ...data,
      startDate: val,
      endDate: moment(val).add(7, "days"),
    });
  };
  return (
    <Box p={3}>
      <Box>
        <DatePickerNew
          label={t("Booking Holding Date")}
          placeholder={t("Booking Holding Date")}
          handleChange={(value) => changeDate(value)}
          isError={data?.error?.date?.length > 0}
          errorMessage={data?.error?.date}
          value={data?.startDate}
          range={true}
          endDate={data?.endDate}
        />
        <Box height="18px" />
        <Box marginBottom="18px">
          <CustomAmount
            //options={dumyOptions}
            value={data?.amount}
            onChange={(value) => updateState("amount", value)}
            isError={data?.error?.amount?.length > 0}
            errorMessage={data?.error?.amount}
            disable={{
              text: false,
              select: true,
            }}
            t={t}
          />
        </Box>
        <Button onClick={onSubmit} variant="contained" fullWidth>
          {type?.btn}
        </Button>
      </Box>
      {/* } */}
      {/*form */}
    </Box>
  );
};
