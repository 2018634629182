import { Box } from "@mui/material";
import React from "react";
import {
  AlertDialog,
  FilterGenerator,
  LoadingSection,
  Subheader,
  UseDebounce,
} from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import {
  accessCheckRender,
  AlertProps,
  getCompanyOption,
  getRoutePermissionNew,
  NetWorkCallMethods,
} from "../../utils";
import VehicleMasterTable from "./components/table";
import Vehicles from "./components/vehicles";
import { ViewVehicles } from "./components/ViewVehicles";
import { VechicleMasterStyles } from "./styles";
import { initialState, StatusOptionList } from "./utils";
import { withTranslation } from "react-i18next";
const VehicleMaster = ({ t }) => {
  const classes = VechicleMasterStyles();
  const debounce = UseDebounce();
  const auth = React.useContext(AuthContext);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const [searchText, setSearchText] = React.useState("");
  const [compenyList, setCompanyList] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState({});
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [drawer, setDrawer] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [permissions, setPermission] = React.useState({});
  const [itemList, setItemList] = React.useState({
    list: [],
    count: 0,
  });
  const [form, setForm] = React.useState(false);
  const [filterData, setFilterData] = React.useState({
    type: [],
    is_active: null,
  });
  const [data, setData] = React.useState({ ...initialState });
  const [selected, setSelected] = React.useState({
    bool: false,
    id: null,
  });

  // use effect to get permission
  React.useEffect(() => {
    let company = getCompanyOption(backdrop, auth, alert);
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (company && perm?.read) {
        setCompanyList(company?.list);
        setSelectedCompany(company?.selected);
        getVehicleList(0, 10, "", company?.selected?.value, true);
      }
    }

    // eslint-disable-next-line
  }, [filterData, auth]);

  //handle change
  const handleCompanyChange = (value) => {
    setSelectedCompany(value);
    getVehicleList(0, 10, "", value?.value, true);
  };

  //apply filter
  const onApplyFilter = (data) => {
    setFilterData(data);
  };

  //handle pagination
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getVehicleList(offset, limit, "", selectedCompany?.value, false);
  };

  //on change limit
  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getVehicleList(0, value, "", selectedCompany?.value, false);
  };

  //on search
  const handleSearch = (e) => {
    setSearchText(e);
    debounce(() => searchTableFunction(e), 800);
  };

  //search function
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getVehicleList(0, limit, e, selectedCompany?.value, false);
  };
  //onadd
  const onAdd = () => {
    setForm(!form);
  };
  //on close
  const onClose = () => {
    setForm(!form);
    setData(initialState);
  };
  //onview
  const onView = (val) => {
    setSelected({
      bool: !selected?.bool,
      id: val,
    });
  };
  //onEdit
  const onEdit = (id) => {
    setForm(true);
    setData({ data: id, isEdit: true });
    onView();
  };
  //handleIcon
  const handleIcon = (type, data) => {
    if (type === "view") {
      onView(data);
    }
    if (type === "edit") {
      setForm(true);
      setData({ data: data, isEdit: true });
    }
    if (type === "active") {
      changeActive(data);
    }
  };
  //reload
  const reload = () => {
    getVehicleList(0, 10, "", selectedCompany?.value, true);
  };
  //table list
  const getVehicleList = (
    offset = 0,
    limit = 10,
    searchText = "",
    companyId,
    loading = true
  ) => {
    let is_active = filterData?.status ?? [true];
    setLoading(loading);
    const payload = {
      offset: offset,
      limit: limit,
      status: is_active,
      company_id: companyId,
      search: searchText,
    };
    NetworkCall(
      `${config.api_url}/queries/vehicle_master/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        //table row constructions
        const List = res?.data?.data?.vehicle_master?.map((val, index) => {
          let _d;
          try {
            _d = {
              index: (page - 1) * limit + index + 1,
              name: val?.name ?? " - ",
              description: val?.description ?? " - ",
              number: val?.number ?? " - ",
              vehicle_type: val?.vehicle_type ?? " - ",
              vehicle_id: val?.vehicle_id ?? " - ",
              cost: val?.rate
                ? `${val?.company?.currency?.symbol} ${val?.rate} / ${val?.period}`
                : " - ",
              status: val?.is_active ? "Active" : "Inactive",
              icon: "more_2",
              id: val?.id,
              period: val?.period,
              ...val,
            };
          } catch (err) {}
          return _d;
        });
        setItemList({
          list: List,
          count: res?.data?.data?.count?.[0]?.count ?? res?.data?.data?.count,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  // is active changing function
  const changeActive = async (data) => {
    const payload = {
      id: data?.id,
      update: {
        is_active: data?.is_active === false ? true : false,
      },
    };
    NetworkCall(
      `${config.api_url}/queries/vehicle_master/update`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((rs) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity:
            data?.is_active === false
              ? AlertProps.severity.success
              : AlertProps.severity.error,
          msg: data?.is_active === false ? t("Activated") : t("Deleted"),
        });
        getVehicleList(0, 10, "", selectedCompany?.value, true);
        setSelected({
          bool: false,
        });
      })
      .catch((er) => {
        console.log(er);
      });
  };

  const render = () => {
    return (
      <Box>
        {/*sub header */}
        <Subheader
          hideBackButton={true}
          title={t("Vehicle Master")}
          select
          options={compenyList}
          value={selectedCompany}
          onchange={(e) => {
            handleCompanyChange(e);
          }}
        />
        {loading ? (
          <LoadingSection top="20vh" message="Fetching ..." />
        ) : (
          <Box className={`${classes.root}`}>
            <VehicleMasterTable
              onFilter={() => setDrawer(true)}
              placeholder={t("Search Vehicle")}
              searchText={searchText}
              handleSearch={handleSearch}
              handleChangeLimit={handleChangeLimit}
              handlePagination={handlePagination}
              page={page}
              list={itemList}
              limit={limit}
              permissions={permissions}
              onAdd={onAdd}
              handleIcon={handleIcon}
              height={`calc(100vh - 320px)`}
              t={t}
            />

            {drawer && (
              <FilterGenerator
                open={drawer}
                onClose={() => setDrawer(false)}
                components={[
                  {
                    component: "toggleButton",
                    value: filterData?.status,
                    state_name: "status",
                    label: t("Active"),
                    options: StatusOptionList(t),
                    isMulti: true,
                  },
                  // {
                  //     component: "select",
                  //     value: filterData?.type,
                  //     state_name: "type",
                  //     label: "Type",
                  //     isMulti: true
                  // },
                ]}
                onApply={(value) => onApplyFilter(value)}
              />
            )}

            {/*add form */}
            <AlertDialog
              open={form}
              md={true}
              component={
                <Vehicles
                  data={data}
                  setData={setData}
                  company={selectedCompany}
                  reload={reload}
                  onClose={onClose}
                  t={t}
                  btnName={t("Upload Image")}
                />
              }
              header={`${data?.isEdit ? t("Edit") : t("Add")} ${t("Vehicle")}`}
              onClose={onClose}
            />

            {/*view form */}
            <AlertDialog
              open={selected?.bool}
              medium={true}
              component={
                <ViewVehicles
                  selected={selected?.id}
                  name={selected?.id?.name}
                  onEdit={onEdit}
                  permissions={permissions}
                  changeActive={changeActive}
                  t={t}
                />
              }
              header={selected?.id?.name}
              onClose={onView}
            />
          </Box>
        )}
      </Box>
    );
  };
  return <Box>{accessCheckRender(render, permissions)}</Box>;
};
export default withTranslation("vehicleMaster")(VehicleMaster);
