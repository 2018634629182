import { Box } from "@mui/material";
import { withTranslation } from "react-i18next";
import { DetailsCardCoponent } from "../../../components";
import {timeZoneConverter} from '../../../utils';

const OppertunityInformation = ({ details = {}, t }) => {
    const card = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("opportunityNumber"),
            subtitle: `${details?.lead_no}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: details?.revenue_type === "Sale" ? false : true,
            component: "text",
            heading: t("leaseDuration"),
            subtitle: `${details?.lease_period} ${details?.value}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: details?.revenue_type === "Sale" ? false : true,
            component: "text",
            heading: t("leasePeriod"),
            subtitle: `${details?.lease_start_date ? timeZoneConverter(details?.lease_start_date) : "-"} to ${details?.lease_end_date ? timeZoneConverter(details?.lease_end_date) : "-"}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: details?.revenue_type === "Sale" ? false : true,
            component: "text",
            heading: t("gracePeriod"),
            subtitle: `${details?.grace_period_value ?? 0} Days`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: details?.revenue_type === "Sale" ? false : true,
            component: "text",
            heading: t("applyGracePeriod"),
            subtitle: `${details?.apply_grace_period ?? "-"}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: details?.revenue_type === "Sale" ? true : false,
            component: "text",
            heading: t("contractStartDate"),
            subtitle: `${details?.contract_start_date ? timeZoneConverter(details?.contract_start_date): '-'}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: details?.revenue_type === "Sale" ? true : false,
            component: "text",
            heading: t("occpationDate"),
            subtitle: `${details?.occpation_date ? timeZoneConverter(details?.occpation_date) : '-'}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("paymentPeriod"),
            subtitle: `${details?.payment_period ?? "-"}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "description",
            heading: t("opportunityDescription"),
            subtitle: `${details?.subject ?? "-"}`
        }
    ]

    return (
        <Box p={2}>
            <DetailsCardCoponent components={card} />
        </Box>
    )
}
export default withTranslation("agreement")(OppertunityInformation); 