import React from 'react';
import Grid from '@mui/material/Grid';
import {  CustomTypography, TitleDesc } from '../../companyCreation/components';
import useTheme from '@mui/material/styles/useTheme';
import { returnValue } from '../../companyCreation/utils';
import { FontFamilySwitch } from '../../../utils';
import { Box } from '@mui/material';
export const RegistrationDetails = (props) => {
    const { t } = (props);

    const theme = useTheme();

    const {
        companyEstateRegistration = null,
    } = props?.data ?? {};

    return (
        <div>
            <Box mx={2} p={2} sx={{ borderBottom: "1px solid #E4E8EE" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CustomTypography
                            fontSize={12}
                            fontFamily={FontFamilySwitch().bold}
                            // marginBottom={theme?.spacing(2)}
                            color={theme?.typography?.color?.secondary}
                        >
                            {t("Company & Real Estate Registration")}
                        </CustomTypography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={t("Company Registration Type")}
                            desc={returnValue(companyEstateRegistration?.companyRegistrationType?.label, '-')}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TitleDesc
                            title={t("Company Registration Number")}
                            desc={returnValue(companyEstateRegistration?.companyRegistrationNumber, '-')}
                        />
                    </Grid>
                </Grid>
            </Box>
        </div >
    )
}