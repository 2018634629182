import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React from 'react'
import DeleteIcon from '../../../assets/delete'
import { PropertyUnitDetailsStyle } from './style'
import { EditIcon } from '../../../components';
import { withTranslation } from 'react-i18next';
import { checkNumber } from '../../../utils';
import InfoIcon from '@mui/icons-material/Info';
import { CustomTextBox } from './customTextBox';

const PropertyUnitDetails = (
    {
        t = () => false,
        data = [],
        type = "",
        edit = () => false,
        addClick = () => false,
        setRowData = () => false,
        removeObjectFromArray = () => false,
        tableRowData = [],
        selectedCompany = {}
    }) => {

    // const [rowCount, setRowCount] = React.useState(0)

    const classes = PropertyUnitDetailsStyle()

    // const fillValue = (type, item) =>{
    //     if (e === "discount" || e === "amount") {
    //         //     val.target.value?.length === 0 ?
    //         //         setRowData(0, item, e === "discount" ? "discount" : "amount")
    //         //         : setRowData(val.target.value, item, e)
    //         // }
    // }   

    return (
        <Box>
            <TableContainer className={classes.table}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {
                                data?.map((e) => {
                                    return (
                                        <TableCell className={classes.headCell}>
                                            <Typography className={classes.headText}> {e?.title} </Typography>
                                        </TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    {
                        type.length === 0 ?
                            <TableBody>
                                {
                                    data?.map((e) => {
                                        return (
                                            <TableCell className={classes.bodyCell}>
                                                <Stack direction={"row"} justifyContent={"space-between"} alignItems="center">
                                                    <Typography className={classes.bodyText}> {e?.content} </Typography>
                                                    {e?.editIcon && <Typography sx={{ cursor: "pointer" }} onClick={edit} ><EditIcon /></Typography>}
                                                </Stack>
                                            </TableCell>
                                        )
                                    })
                                }
                            </TableBody>
                            :
                            <TableBody>
                                {
                                    tableRowData?.map((item) => {
                                        return (
                                            <TableRow>
                                                <TableCell className={classes.bodyCell}>
                                                    <Typography className={classes.headText}> {item?.component} </Typography>
                                                </TableCell>
                                                {
                                                    item?.path?.map((e) => {
                                                        return (
                                                            <>
                                                                <TableCell className={classes.bodyCell}>
                                                                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}
                                                                        sx={{ height: "35px" }}>
                                                                        {/* <TextField
                                                                        value={item[e] ?? ""}
                                                                        variant="filled"
                                                                        color="primary" size="small"
                                                                        focused
                                                                        disabled={type === "view"}
                                                                        className={classes.customText}
                                                                        type={item?.field[e]}
                                                                        onBlur={(val) => {
                                                                            if (e === "discount" || e === "amount") {
                                                                                val.target.value?.length === 0 ?
                                                                                    setRowData(0, item, e === "discount" ? "discount" : "amount")
                                                                                    : setRowData(val.target.value, item, e)
                                                                            }
                                                                            else if (e === "qty") {
                                                                                val.target.value?.length === 0 ?
                                                                                    setRowData(1, item, "qty")
                                                                                    : setRowData(val.target.value, item, e)
                                                                            }
                                                                            else {
                                                                                setRowData(val.target.value, item, e)
                                                                            }
                                                                        }}
                                                                        autoComplete={false}
                                                                        onChange={(val) => {
                                                                            setRowData(val.target.value, item, e)
                                                                        }} /> */}

                                                                        <CustomTextBox
                                                                            value={item?.[e] ?? ""}
                                                                            type={item?.field?.[e]}
                                                                            setRowData={setRowData}
                                                                            disable={type === "view"}
                                                                            e={e}
                                                                            item={item}
                                                                            selectedCompany={selectedCompany} />

                                                                        {
                                                                            (item?.discountError?.length > 0 && checkNumber(item?.discount) !== 0 && e === "discount") &&
                                                                            <Tooltip arrow title={item?.discountError ?? ""} placement="top">
                                                                                <InfoIcon sx={{ color: "#FF9340" }} />
                                                                            </Tooltip>
                                                                        }
                                                                    </Stack>
                                                                </TableCell>
                                                                {/* {ind === arr.length - 1 && <DeleteIcon onClick={() => removeObjectFromArray(item.id)} />} */}
                                                            </>
                                                        )
                                                    })
                                                }
                                                <TableCell className={classes.bodyCell}>
                                                    <Typography className={classes.headText}> {Number(item?.taxableAmount ?? 0).toLocaleString('en-IN')} </Typography>
                                                </TableCell>
                                                <TableCell className={classes.bodyCell}>
                                                    <Typography className={classes.headText}> {item?.taxGroup ? item?.taxGroup : "-"} </Typography>
                                                </TableCell>
                                                <TableCell className={classes.bodyCell}>
                                                    <Typography className={classes.headText}> {Number(item?.taxes ?? 0).toLocaleString('en-IN')} </Typography>
                                                </TableCell>
                                                <TableCell className={classes.bodyCell}>
                                                    <Typography className={classes.headText}> {Number(item?.subtotal ?? 0).toLocaleString('en-IN')} </Typography>
                                                </TableCell>
                                                {type !== "view" && <TableCell className={classes.bodyCell} sx={{ textAlign: "center" }}>
                                                    <Box sx={{ cursor: "pointer" }}>
                                                        <DeleteIcon onClick={() => removeObjectFromArray(item)} />
                                                    </Box>
                                                </TableCell>}
                                            </TableRow>
                                        )
                                    })
                                }
                                {
                                    type !== "view" &&
                                    <TableRow className={classes.billLineCell}>
                                        <Typography className={classes.addBillLine} onClick={addClick}>+ {t("AddNewBillLine")}</Typography>
                                    </TableRow>
                                }
                            </TableBody>
                    }
                </Table>
            </TableContainer>
        </Box>
    )
}

export default withTranslation("freeTextInvoice")(PropertyUnitDetails)