import React, { useContext, useEffect } from "react";
import { Box, Typography, Stack, Button, Divider } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { AddUnit, OwnerUnit, PropertyUnit } from "../../../assets";
import { AlertDialog } from "../../../components";
import { Owner } from "./ownerSelect";
import { CreateOppertunityContext } from "../../../contexts/createOppertunityContext";
import { useStyles } from "./styles";

export const UnitSelection = ({
  onChange = () => false,
  value = [],
  company = null,
  datas = {},
  isEdit = false,
  onDeleteUnits = () => false,
  typeData = "",
  purpose = "",
  removeUnits 
}) => {
  const { dataNew, setDataNew, t = () => false } = useContext(CreateOppertunityContext);
  const classes = useStyles();
  const [showDialog, setShowDialog] = React.useState(false);
  const [chooseOwner, setChooseOwner] = React.useState(false);
  const language = localStorage.getItem("i18nextLng");

  const types = [
    {
      value: 1,
      label: t("Select against owner units"),
      description: "",
      icon: <OwnerUnit />,
      search: "Search Owner",
      is_diver: true,
    },
    {
      value: 2,
      label: t("Select against property units"),
      description: "",
      icon: <PropertyUnit />,
      search: "Search Property",
    },
  ];

  const [data, setData] = React.useState({
    type: null,
  });
  const [unitData, setUnitData] = React.useState({
    data: [],
    id: "",
  });

  const chooseOwnerforUnitSelection = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
    setChooseOwner(true);
    setShowDialog(false);
  };

  React.useEffect(() => {
    setData({
      type: types[1],
    });
    // eslint-disable-next-line
  }, []);

  const addUnits = () => {
    if (unitData?.id?.length > 0) {
      setDataNew({
        ...dataNew,
        units: unitData?.data ?? [],
      });
      setChooseOwner(false);
    }
    else {
      setDataNew({
        ...dataNew,
        units: [...new Set([...unitData?.data])] ?? [],
      });
      setChooseOwner(false);
    }
  };

  useEffect(() => {
    removeUnits();
  }, [dataNew?.grace_peroid]);

  const onChangeState = (unit, owner, type, is_delete) => {
    setUnitData({
      data: unit?.filter((val) => val?.is_active === true) ?? [],
      id: is_delete ?? "",
    });
    onChange(unit, owner, type);
  };

  return (
    <>
      <Box className={classes.unitCard} onClick={() => setChooseOwner(true)}>
        <Stack direction="row">
          <AddUnit className={classes.selectionIcon} />
          <Typography className={classes.selectionText}>
            {t("Quick Unit Selection")}
          </Typography>
        </Stack >
        <Stack>
          {language === "ar" ?
            <ChevronLeftIcon className={classes.arrowRightIcon} style={{ color: "#6DAFB3" }} /> :
            <ChevronRightIcon className={classes.arrowRightIcon} style={{ color: "#6DAFB3" }} />
          }
        </Stack>
      </Box >
      <AlertDialog
        isNormal
        isnotTitle={true}
        onClose={() => {
          setShowDialog(false);
        }}
        open={showDialog}
        component={
          <>
            <Box>
              {types?.map((val) => {
                return (
                  <>
                    <Box
                      className={classes.selectCard}
                      onClick={() => chooseOwnerforUnitSelection("type", val)}
                    >
                      <Box display="flex" alignItems="center">
                        <Box>{val?.icon}</Box>
                        <Box>
                          <Typography className={classes.ownerText}>
                            {val?.label}
                          </Typography>
                          <Typography className={classes.ownerSubText}>
                            {val?.description}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        {language === "ar" ?
                          <ChevronLeftIcon
                            className={classes.selectarrowRightIcon}
                            color="6DAFB3" /> :
                          <ChevronRightIcon
                            className={classes.selectarrowRightIcon}
                            color="6DAFB3"

                          />
                        }
                        
                      </Box>
                    </Box>
                    {val?.is_diver && <Divider />}
                  </>
                );
              })}
            </Box>
          </>
        }
      />
      <AlertDialog
        isNormal
        onClose={() => {
          setChooseOwner(false);
        }}
        header={
          data?.type?.value === 1
            ? t("Select against Owner Units")
            : t("Select against Property Units")
        }
        open={chooseOwner}
        component={
          <>
            <Box p={2}>
              <Owner
                datas={datas}
                isEdit={isEdit}
                company={datas?.company}
                onChange={onChangeState}
                type={data?.type}
                onDeleteUnits={onDeleteUnits}
                value={datas?.selectedUnit ?? []}
                onClose={() => {
                  chooseOwnerforUnitSelection("type", null);
                  onDeleteUnits([], null, true);
                }}
                is_show={true}
                typeData={typeData}
                purpose={purpose}
              />
            </Box>
            <Box style={{ padding: '0px 16px 16px 16px' }}>
              <Button
                variant="contained"
                className={classes.btnAdd}
                onClick={() => addUnits()}
              >
                {t("Add Units")}
              </Button>
            </Box>
          </>
        }
      ></AlertDialog >
    </>
  );
};