import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  ApplyDiscount,
  ContactList,
  LoadingSection,
  QuotationSummery,
} from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { CreateQuotationListContext } from "../../../contexts/createQuotationContext";
import { NetworkCall } from "../../../networkcall";
import {
  AlertProps,
  LocalStorageKeys,
  NetWorkCallMethods,
  checkNumber,
  getTotalArray,
  timeZoneConverter,
} from "../../../utils";
import useDimensions from "../../../utils/useWindowDimensions";
import { UnitSingleDetails } from "../../propertyFinder3/component/singleUnitDetail";
import UnitCard from "../../propertyFinder3/component/unitCard";
import { QuotationPreviewStyles as useStyles } from "./styles";
import { TemplateComponent } from "../component";
import { getComponentValue } from "../../freeTextInvoice/utlis/freeTextInvoice";
import CustomizeQuote from "../../customizeQUote/customizeQuote";
import { HoleNumber, filterValues } from "../../customizeQUote/function";

export const Preview = (props) => {
  const classes = useStyles();
  const {
    dataNew,
    next,
    previous,
    setDataNew,
    createShortlist,
    selectedAccount,
    t,
  } = React.useContext(CreateQuotationListContext);
  const [openUnit, setOpenUnit] = React.useState(false);
  const alert = React.useContext(AlertContext);
  const [selectedUnit, setSelectedUnit] = React.useState("");
  const [selectedUnitDetails, setSelectedUnitDetails] = React.useState({});
  const [unitDetails, setUnitDetails] = React.useState([]);
  const [disable, setDisable] = React.useState(true);
  const [totalValue, setTotalValue] = React.useState({
    totalAmount: 0,
    totalrefundableTax: 0,
    totalTax: 0,
    totalDiscount: null,
    totalWithoutOne: 0,
  });
  const [custom, setCustom] = React.useState({
    type: false,
    data: null,
  });
  // states
  const navigate = useNavigate();
  const size = useDimensions();
  const [loading, setLoading] = React.useState(true);
  const [customize, setCustomize] = React.useState(false);
  //get unit pricing
  const getUnitPricing = (unit_id) => {
    if (dataNew?.units?.length > 0) {
      const datas = {
        tenantId: `${config.tenantId}`,
        unitId: unit_id,
        startDate: dataNew?.lease_start_date,
        endDate: dataNew?.lease_end_date,
        gracePeriod: dataNew?.quotation_grace_period,
        revenue_type: dataNew?.revenue_type?.value,
      };

      NetworkCall(
        `${config.api_url}/quotation/unitprice`,
        NetWorkCallMethods.post,
        datas,
        null,
        true,
        false
      )
        .then((response) => {
          let result = response?.data?.data?.units?.map((val) => {
            return {
              ...val,
              id: val?.id,
              is_open: false,
              name: val?.name,
              unit_no: val?.unitNo,
              total_area: val?.totalarea,
              area_metric: val?.areaMetric,
              total_bed_rooms: val?.totalbedroom,
              total_baths: val?.baths,
              unit_type: val?.unitType,
              discount: 0,
              discountValue: 0,
              room_rent: val?.totalPricing,
              url: val?.logo,
              is_active_unit: true,
              pricing: val?.pricing?.map((c) => {
                return {
                  ...c,
                  beforeTax: c?.componentvalue,
                  pricing_components: !!c?.inspection
                    ? c?.inspection
                    : c?.pricing_component[0],
                  discountType: { label: "%", value: "%" },
                  inspection: !!c?.inspection
                    ? {
                        category: c?.inspection?.category,
                        type: {
                          value: c?.inspection?.item,
                          label: c?.inspection?.item,
                        },
                      }
                    : null,
                  discountedPrice: 0,
                  discountValue: 0,
                  discountPercentage: 0,
                  fixTax: c?.appliedTaxAmount,
                  refundableAmount: 0,
                  vat_id: c?.vatGroup?.VGMid,
                  componentType: getComponentValue(
                    c?.pricing_component[0],
                    c?.is_wallet_item
                  ),
                  subtotal:
                    Number(c?.componentvalue ?? 0) +
                    Number(c?.appliedTaxAmount ?? 0),
                  componentvalues: c?.value ?? 0,
                  quantity: c?.quantity ? c?.quantity : 1,
                  tax: c?.appliedTaxAmount ?? 0,
                  beforeTaxAmount: c?.componentvalue,
                  taxAmountValue: c?.appliedTaxAmount ?? 0,
                  amountWithoutDiscount: c?.componentvalue,
                  is_active: true,
                  isQuantity: !!c?.quantity ? true : false,
                  uom_type: c?.uom_type,
                  is_wallet_item: c?.is_wallet_item,
                };
              }),
              total: val?.total,
              totalDiscount: 0,
              totalAmount: val?.totalPricing,
              fixTotal: val?.totalPricing,
              totalrefundableTax: val?.totalrefundableTax,
              totalDiscountValue: null,
              discountPrice: 0,
              discountedPrice: 0,
              appliedTaxAmount: 0,
              blockid: val?.blockid,
              propertyid: val?.propertyid,
              floorid: val?.floorid,
            };
          });

          const total_one = result
            ?.flatMap((i) => i?.pricing)
            ?.filter((j) => j?.refundable === false)
            ?.filter((k) => k?.isOnetime === false)
            ?.map((i) => {
              return (
                (i?.discountedPrice > 0
                  ? i?.discountedPrice
                  : i?.componentvalue) + i?.appliedTaxAmount
              );
            })
            ?.reduce((a, b) => a + b, 0);

          setUnitDetails(result);
          setTotalValue({
            totalWithoutOne: total_one,
            totalAmount: response?.data?.data?.totalAmount,
            totalrefundableTax: response?.data?.data?.totalrefundableTax,
            totalTax: response?.data?.data?.totalTax,
            totalDiscount: 0,
            total_quote_amount: response?.data?.data?.unit,
            totalAmountWithDiscount: response?.data?.data?.totalAmount,
            totalTaxWithDiscount: response?.data?.data?.totalTax,
          });

          setLoading(false);
          if (response?.data?.data?.totalAmount) {
            setDisable(false);
          } else {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: "There is No Unit Pricing For Selected Units",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setDisable(false);
        });
    }
  };
  const getTotal = (data) => {
    // get total count
    let totalCount = data.reduce(function (prev, current) {
      return prev + +current.percentage_value;
    }, 0);

    return totalCount;
  };
  const upDateCustom = (unitDetails) => {
    if (unitDetails) {
      props?.setCustom1(!custom?.type);
      return setCustom({
        ...custom,
        type: !custom?.type,
        data: unitDetails,
      });
    }
    props?.setCustom1(!custom?.type);
    setCustom({
      ...custom,
      type: !custom?.type,
    });
  };
  React.useEffect(() => {
    //initial load
    getUnitPricing(dataNew?.units?.map((val) => val?.id));
    // eslint-disable-next-line
  }, []);
  //on delete unit
  const onDelete = (id) => {
    if (unitDetails?.length > 1) {
      const deleted = unitDetails.filter((ids, index) => index !== id);
      getUnitPricing(deleted?.map((val) => val.id));
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.info,
        msg: t("Cannot Delete"),
      });
    }
  };
  //on open unit Details
  const openUnitDetails = (data) => {
    setSelectedUnitDetails(data);
  };
  //on apply btn clicked
  const applyDiscount = (data, total, unitId, discountValue, totalTax) => {
    const edited = unitDetails.map((item) =>
      item.id === unitId
        ? {
            ...item,
            totalDiscount: total,
            totalDiscountValue: discountValue,
            totalAmount: total,
            discountedPrice: item?.fixTotal - total,
          }
        : item
    );
    const discountTotal = edited
      ?.map((i) => i?.discountedPrice)
      .reduce((a, b) => a + b, 0);
    setTotalValue({ ...totalValue, totalDiscount: discountTotal });
    setUnitDetails(edited);
    setOpenUnit(false);
  };

  //on submit
  const onSubmit = () => {
    if (customize) {
      return onSumbitCustomize();
    }
    onSumbitNormal();
  };
  const onSumbit = (e) => {
    setCustomize(true);
    const units = e?.map((x) => {
      return {
        ...x,
        totalDiscount: getTotalArray(
          x?.pricing
            ?.filter(
              (item) => !filterValues.includes(item?.componentType?.value)
            )
            ?.filter((x) => x?.is_active === true),
          "subtotal"
        ),
        is_active_unit:
          x?.pricing?.filter((c) => c?.is_active)?.length > 0 ? true : false,
        room_rent: getTotalArray(
          x?.pricing
            ?.filter(
              (item) => !filterValues.includes(item?.componentType?.value)
            )
            ?.filter((x) => x?.is_active === true),
          "subtotal"
        ),
        pricing: x?.pricing?.map((v) => {
          return {
            ...v,
            PCid: v?.pricing_components?.PCid || v?.pricing_components?.value,
            PCname:
              v?.pricing_components?.PCname || v?.pricing_components?.label,
            rent_breakup_id:
              v?.pricing_components?.PCid || v?.pricing_components?.value,
            unit_id: x?.id ?? "",
            component_value:
              Number(v?.quantity > 1 ? v?.quantity : 1) *
              Number(v?.componentvalues ?? 0),
            amount: Number(!!v?.componentvalues ? v?.componentvalues : 0),
            beforeTax: !!v?.beforeTax ? Number(v?.beforeTax) : 0,
            companyId: !!v?.companyId
              ? v?.companyId
              : JSON.parse(
                  localStorage.getItem(LocalStorageKeys.selectedCompany)
                )?.value ?? dataNew?.company,
            currencyId: !!v?.currencyId
              ? v?.currencyId
              : JSON.parse(
                  localStorage.getItem(LocalStorageKeys.selectedCompany)
                )?.currency_id,
            currencyName: !!v?.currencyName
              ? v?.currencyName
              : JSON.parse(
                  localStorage.getItem(LocalStorageKeys.selectedCompany)
                )?.code,
            currencySymbol: !!v?.currencySymbol
              ? v?.currencySymbol
              : JSON.parse(
                  localStorage.getItem(LocalStorageKeys.selectedCompany)
                )?.currency_symbol,
            subtotal: !!v?.subtotal ? Number(v?.subtotal) : 0,
            tax: !!v?.tax ? Number(v?.tax) : 0,
            value: !!v?.componentvalues ? Number(v?.componentvalues) : 0,
            vatGroupId: !!v?.vatGroup?.value ? v?.vatGroup?.value : null,
            unitid: !!v?.unit_id ? v?.unit_id : x?.id,
            vat_id: !!v?.vat_id ? v?.vat_id : v?.vatGroup?.value ?? null,
            fixTax: Number(v?.fixTax),
            beforeTaxAmount: v?.beforeTaxAmount,
            taxAmountValue: v?.taxAmountValue ?? 0,
            discountValue: !!v?.discountValue ? Number(v?.discountValue) : 0,
          };
        }),
      };
    });
    const totalAmount = units
      ?.map((i) => i?.pricing)
      ?.flat()
      ?.filter((item) => !filterValues.includes(item?.componentType?.value))
      ?.filter((x) => x?.is_active)
      .map((item) => Number(item?.amountWithoutDiscount))
      .reduce((a, b) => a + b, 0);

    const totalAmountWithDiscount = units
      ?.map((i) => i?.pricing)
      ?.flat()
      ?.filter((item) => !filterValues.includes(item?.componentType?.value))
      ?.filter((x) => x?.is_active)
      .map((item) => Number(item?.beforeTax))
      .reduce((a, b) => a + b, 0);

    const totalrefundableTax = units
      ?.map((i) => i?.pricing)
      .flat()
      ?.filter((item) => !filterValues.includes(item?.componentType?.value))
      ?.filter((x) => x?.is_active)
      .filter((i) => i?.refundable === true)
      .map((item) => Number(item?.beforeTax))
      .reduce((a, b) => a + b, 0);

    const total_one = units
      ?.flatMap((i) => i?.pricing)
      ?.filter((j) => j?.refundable === false)
      ?.filter((item) => !filterValues.includes(item?.componentType?.value))
      ?.filter((x) => x?.is_active)
      ?.filter((k) => k?.isOnetime === false)
      ?.map((i) => {
        return Number(i?.subtotal);
      })
      ?.reduce((a, b) => a + b, 0);

    const totalTax = units
      ?.map((i) => i?.pricing)
      ?.flat()
      ?.filter((item) => !filterValues.includes(item?.componentType?.value))
      ?.filter((x) => x?.is_active)
      .map((item) => Number(item?.fixTax))
      .reduce((a, b) => a + b, 0);

    const totalTaxWithDiscount = units
      ?.map((i) => i?.pricing)
      ?.flat()
      ?.filter((item) => !filterValues.includes(item?.componentType?.value))
      ?.filter((x) => x?.is_active)
      .map((item) => Number(item?.tax))
      .reduce((a, b) => a + b, 0);

    let total_discount_amount =
      totalAmount + totalTax - (totalAmountWithDiscount + totalTaxWithDiscount);
    setUnitDetails(units);
    setTotalValue({
      totalAmount: HoleNumber(totalAmount),
      totalrefundableTax: HoleNumber(totalrefundableTax),
      totalTax: HoleNumber(totalTax),
      total_quote_amount: HoleNumber(totalAmount),
      totalDiscount: HoleNumber(total_discount_amount),
      totalWithoutOne: HoleNumber(total_one),
      totalAmountWithDiscount: HoleNumber(totalAmountWithDiscount),
      totalTaxWithDiscount: HoleNumber(totalTaxWithDiscount),
    });

    props?.setCustom1(!custom?.type);
    setCustom({
      ...custom,
      type: !custom?.type,
    });
  };
  const onSumbitNormal = () => {
    if (
      dataNew?.revenue_type?.value === "Sale" &&
      dataNew?.payment_period?.value === "Milestone Based"
    ) {
      if (!dataNew?.typeValue.length > 0) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Please Fill Milestone Configuration"),
        });
        return false;
      }
      if (
        dataNew?.typeValue === "amount" &&
        (dataNew?.mailstoneLists?.[0]?.percentage_value?.length === 0 ||
          dataNew?.mailstoneLists?.[0]?.percentage_value === "0" ||
          dataNew?.mailstoneLists?.[0]?.percentage_value === 0 ||
          !dataNew?.mailstoneLists?.[0]?.percentage_value.length > 0)
      ) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Please Enter Valid Milestone Configuration"),
        });
        return false;
      }
      if (dataNew?.typeValue === "variable") {
        const totalCount = getTotal(dataNew?.mailstoneLists);
        const totalAmount =
          totalValue?.totalTax +
          totalValue?.totalAmount -
          totalValue?.totalDiscount;
        if (totalAmount !== totalCount) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Please Enter Valid Milestone Configuration"),
          });
          setDataNew({
            ...dataNew,
            isValid: false,
          });
          return false;
        }
      }
    }

    setDisable(true);
    let discoutValue = unitDetails
      ?.map((i) => i?.pricing)
      .flat()
      .map((val) => Number(val?.discountValue))
      .reduce((a, b) => a + b, 0);
    let units = unitDetails?.map((val) => {
      return {
        unit_id: val?.id,
        unit_total_primary: val?.totalAmount,
        unit_total_refundable: val?.unitTotalRefundable,
        unit_total_others: null,
        total_rent_breakup: null,
        total_rent_discounts: null,
        total_rent_security_deposit: null,
        total_rent_tax: null,
        total_rent_extended: null,
        area_metric: val?.areaMetric,
        property_id: val?.propertyid,
        block_id: val?.blockid,
        floor_id: val?.floorid,
      };
    });
    let refundable = unitDetails
      ?.map((i) => i?.pricing)
      .flat()
      .map((val) => {
        let applied_component_value =
          Number(val?.beforeTax ?? 0) + Number(val?.tax ?? 0);
        return {
          unit_id: val?.unitid,
          rent_breakup_id:
            val?.componenttype === "Component"
              ? val?.is_wallet_item === "is_wallet_item"
                ? null
                : val?.PCid
              : null,
          rent_amount: val?.discountPrice ?? val?.componentvalue,
          primary: val?.primary,
          refundable: val?.refundable,
          taxable: val?.taxable,
          is_one_time: val?.isOnetime,
          display_percentage: val?.discountValue,
          payment_period: val?.paymentPeriod,
          company_id: dataNew?.company,
          tax: val?.tax ?? 0,
          tax_percentage: val?.taxPercentage,
          before_rent_amount: val?.amountWithoutDiscount,
          before_tax: val?.fixTax,
          component_value: val?.value,
          applied_value: applied_component_value,
          vat_group_id: val?.vat_id,
          is_quantity: val?.isQuantity,
          quantity: val?.quantity,
          value_basis_type: val?.valueBasisType,
          inspection_item: val?.inspection_id,
          uom_type: val?.pricing_components?.uom_type
            ? val?.pricing_components?.uom_type
            : val?.paymentPeriod,
          is_wallet_item: val?.is_wallet_item ? true : false,
        };
      });
    let milestone_item = [];

    if (
      dataNew?.revenue_type?.value === "Sale" &&
      dataNew?.quotation_payment_peroid?.value === "Milestone Based"
    ) {
      if (dataNew?.typeValue === "amount") {
        milestone_item = dataNew?.mailstoneLists?.map((x, i) => {
          return {
            milestone_item_id: x?.id,
            value:
              i === 0
                ? x.percentage_value
                  ? Number(x?.percentage_value)
                  : 0
                : x.percentage
                ? Number(x?.percentage)
                : 0,
            value_type: i === 0 ? "amount" : "percentage",
            due_date: x?.payment_date,
          };
        });
      }
      if (dataNew?.typeValue === "variable") {
        milestone_item = dataNew?.mailstoneLists.map((x) => {
          return {
            milestone_item_id: x?.id,
            value: x?.percentage_value ? Number(x?.percentage_value) : 0,
            value_type: x?.value_type,
            due_date: x?.payment_date,
          };
        });
      }
      if (dataNew?.typeValue === "percentage") {
        milestone_item = dataNew?.mailstoneLists?.map((x, i) => {
          return {
            milestone_item_id: x?.id,
            value: x.percentage ? Number(x?.percentage) : 0,
            value_type: "percentage",
            due_date: x?.payment_date,
          };
        });
      }
    }
    const processBillCycleMethod = (billCycleMethod) => {
      if (Array.isArray(billCycleMethod)) {
        return billCycleMethod.join(", ");
      }
      return billCycleMethod;
    };
    let payload = {
      tenantId: `${config.tenantId}`,
      lead_id:
        dataNew.type === "existing lead"
          ? dataNew?.lead_details?.id
          : dataNew?.lead_id,
      grace_period:
        dataNew?.quotation_grace_period?.length > 0
          ? dataNew?.quotation_grace_period
          : dataNew?.grace_peroid ?? null,
      total_amount: totalValue?.totalAmount,
      description: dataNew?.quotation_description,
      total_discount: totalValue?.totalDiscount,
      total_refundable: totalValue?.totalrefundableTax,
      total_tax: totalValue?.totalTax,
      lease_period: dataNew?.quotation_lease_duration?.select ?? null,
      lease_duration: dataNew?.quotation_lease_duration?.value ?? null,
      lease_start_date:
        dataNew?.searchdata?.revenue_type === "Sale"
          ? dataNew?.quotation_handover_date
          : moment(dataNew?.lease_start_date).format("YYYY-MM-DD"),
      lease_end_date:
        dataNew?.searchdata?.revenue_type === "Sale"
          ? "2999-12-31"
          : moment(dataNew?.lease_end_date).format("YYYY-MM-DD"),
      quote_start_date: new Date(),
      discount_percentage: discoutValue,
      tax_percentage: discoutValue,
      unit_usage: dataNew?.searchdata?.property_purpose,
      company_id: dataNew?.company,
      grace_period_type: "Days",
      apply_grace_period:
        dataNew?.apply_grace_peroid?.length > 0
          ? dataNew?.apply_grace_peroid
          : null,
      revenue_type: dataNew?.searchdata?.revenue_type,
      units: units,
      rental_breakup: refundable,
      billing_start_date:
        dataNew?.searchdata?.revenue_type === "Sale"
          ? moment(dataNew?.quotation_contract_start_date).format("YYYY-MM-DD")
          : dataNew?.quotation_billing_start_date
          ? moment(dataNew?.quotation_billing_start_date).format("YYYY-MM-DD")
          : null,
      bill_generation:
        processBillCycleMethod(dataNew?.quotation_billing_cycle) ?? null,
      billing_day:
        dataNew?.custom_date?.length !== 0 ? dataNew?.custom_date : null,
      billing_cycle_date:
        dataNew?.bill_cycle_date === "As per the Agreement Date"
          ? dataNew?.bill_cycle_date
          : "Custom Date",
      lease_payment_period: dataNew?.quotation_payment_peroid?.value,
      total_quote_amount:
        totalValue?.totalTax + totalValue?.totalAmount === 0
          ? 0
          : totalValue?.totalTax +
            totalValue?.totalAmount -
            totalValue?.totalDiscount,
      revenue_based_lease: dataNew?.quotation_revenue_based_lease,
      payment_mode: dataNew?.payment_option?.value,
      auto_renewal_escalation: dataNew?.quotation_renewal_based_on?.value,
      auto_renewal: dataNew?.quotation_auto_renewal,
      increment_escalation:
        dataNew?.quotation_escalculation_percent !== null &&
        dataNew?.quotation_escalculation_percent?.length > 0
          ? dataNew?.quotation_escalculation_percent
          : null,
      include_market: dataNew?.quotation_include_market_place,
      open_agreement: dataNew?.convert_to_open_agreement?.is_active,
      milestone_template: dataNew?.delivery_timestone_template?.value ?? null,
      contract_start_date:
        dataNew?.quotation_contract_start_date?.length !== 0 &&
        dataNew?.revenue_type?.value === "Sale"
          ? moment(dataNew?.quotation_contract_start_date).format("YYYY-MM-DD")
          : undefined,
      occpation_date:
        dataNew?.quotation_handover_date?.length !== 0 &&
        dataNew?.revenue_type?.value === "Sale"
          ? dataNew?.quotation_handover_date
          : undefined,
      milestone_item: milestone_item ?? [],
      broker_account: selectedAccount?.broker?.id,
      is_agreement_lock: dataNew?.agreement_lock,
      account_no: selectedAccount?.normal?.account_no,
      broker_agent_account:
        selectedAccount?.agent?.value || selectedAccount?.agent?.id,
      renewal: dataNew?.renewal ?? false,
      allow_extension: dataNew?.allow_extension ?? false,
    };

    NetworkCall(
      `${config.api_url}/quotation/create`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setDataNew({
          ...dataNew,
          pdf: response?.data?.data?.data,
          type_master_id: response?.data?.data?.type_master_id,
        });
        createShortlist(
          dataNew.type === "existing lead"
            ? dataNew?.lead_details?.id
            : dataNew?.lead_id,
          unitDetails,
          true
        );
        setDisable(false);

        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Quotation Create Successfully"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });

        next();
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        setDisable(true);
      });
  };
  const onSumbitCustomize = () => {
    const unit_details = unitDetails;
    if (
      dataNew?.revenue_type?.value === "Sale" &&
      dataNew?.payment_period?.value === "Milestone Based"
    ) {
      if (!dataNew?.typeValue.length > 0) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Please Fill Milestone Configuration"),
        });
        return false;
      }
      if (
        dataNew?.typeValue === "amount" &&
        (dataNew?.mailstoneLists?.[0]?.percentage_value?.length === 0 ||
          dataNew?.mailstoneLists?.[0]?.percentage_value === "0" ||
          dataNew?.mailstoneLists?.[0]?.percentage_value === 0 ||
          !dataNew?.mailstoneLists?.[0]?.percentage_value.length > 0)
      ) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Please Enter Valid Milestone Configuration"),
        });
        return false;
      }
      if (dataNew?.typeValue === "variable") {
        const totalCount = getTotal(dataNew?.mailstoneLists);
        const totalAmount =
          totalValue?.totalTax +
          totalValue?.totalAmount -
          totalValue?.totalDiscount;
        if (totalAmount !== totalCount) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Please Enter Valid Milestone Configuration"),
          });
          setDataNew({
            ...dataNew,
            isValid: false,
          });
          return false;
        }
      }
    }
    let milestone_item = [];

    if (
      dataNew?.revenue_type?.value === "Sale" &&
      dataNew?.quotation_payment_peroid?.value === "Milestone Based"
    ) {
      if (dataNew?.typeValue === "amount") {
        milestone_item = dataNew?.mailstoneLists?.map((x, i) => {
          return {
            milestone_item_id: x?.id,
            value:
              i === 0
                ? x.percentage_value
                  ? Number(x?.percentage_value)
                  : 0
                : x.percentage
                ? Number(x?.percentage)
                : 0,
            value_type: i === 0 ? "amount" : "percentage",
            due_date: x?.payment_date,
          };
        });
      }
      if (dataNew?.typeValue === "variable") {
        milestone_item = dataNew?.mailstoneLists.map((x) => {
          return {
            milestone_item_id: x?.id,
            value: x?.percentage_value ? Number(x?.percentage_value) : 0,
            value_type: x?.value_type,
            due_date: x?.payment_date,
          };
        });
      }
      if (dataNew?.typeValue === "percentage") {
        milestone_item = dataNew?.mailstoneLists?.map((x, i) => {
          return {
            milestone_item_id: x?.id,
            value: x.percentage ? Number(x?.percentage) : 0,
            value_type: "percentage",
            due_date: x?.payment_date,
          };
        });
      }
    }

    let units = unit_details
      ?.filter((c) => c?.is_active_unit)
      ?.map((val) => {
        return {
          unit_id: val?.id,
          unit_total_primary: val?.totalDiscount,
          unit_total_refundable: val?.unitTotalRefundable,
          unit_total_others: null,
          total_rent_breakup: null,
          total_rent_discounts: null,
          total_rent_security_deposit: null,
          total_rent_tax: null,
          total_rent_extended: null,
          area_metric: val?.areaMetric,
          property_id: val?.propertyid,
          block_id: val?.blockid,
          floor_id: val?.floorid,
        };
      });

    let refundable = unit_details
      ?.map((i) => i?.pricing)
      .flat()
      .map((val) => {
        return {
          unit_id: val?.unitid,
          rent_breakup_id:
            val?.componenttype === "Component"
              ? val?.componentType?.value === "is_wallet_item"
                ? null
                : val?.pricing_components?.id || val?.PCid
              : null,
          rent_amount: val?.beforeTax ?? 0,
          primary: val?.primary,
          refundable: val?.refundable,
          taxable: val?.taxable,
          is_one_time: val?.isOnetime,
          display_percentage: HoleNumber(val?.discountPercentage),
          payment_period: val?.paymentPeriod,
          company_id: dataNew?.company,
          tax: !!val?.tax ? val?.tax : 0,
          tax_percentage: val?.taxPercentage,
          before_rent_amount: val?.amountWithoutDiscount,
          before_tax: Number(val?.fixTax),
          component_value: !!val?.value ? val?.value : val?.component_value,
          vat_group_id: val?.vat_id,
          is_quantity: val?.quantity > 1 ? true : val?.isQuantity,
          quantity: val?.quantity,
          value_basis_type: val?.valueBasisType,
          inspection_item:
            val?.componenttype === "Component"
              ? val?.componentType?.value === "is_wallet_item"
                ? val?.pricing_components?.id
                : null
              : val?.pricing_components?.id,
          discount_type: val?.discount_type,
          applied_value: val?.subtotal,
          is_wallet_item:
            val?.componentType?.value === "is_wallet_item" ? true : false,
          uom_type: val?.pricing_components?.uom_type
            ? val?.pricing_components?.uom_type
            : val?.paymentPeriod,
          is_proxy: val?.isProxy,
        };
      });

    const processBillCycleMethod = (billCycleMethod) => {
      if (Array.isArray(billCycleMethod)) {
        return billCycleMethod.join(", ");
      }
      return billCycleMethod;
    };
    let payload = {
      tenantId: `${config.tenantId}`,
      lead_id:
        dataNew.type === "existing lead"
          ? dataNew?.lead_details?.id
          : dataNew?.lead_id,
      grace_period:
        dataNew?.quotation_grace_period?.length > 0
          ? dataNew?.quotation_grace_period
          : dataNew?.grace_peroid ?? null,
      total_amount: totalValue?.totalAmountWithDiscount,
      description: dataNew?.quotation_description,
      total_discount: totalValue?.totalDiscount,
      total_refundable: totalValue?.totalrefundableTax,
      total_tax: totalValue?.totalTaxWithDiscount,
      lease_period: dataNew?.quotation_lease_duration?.select ?? null,
      lease_duration: dataNew?.quotation_lease_duration?.value ?? null,
      lease_start_date:
        dataNew?.searchdata?.revenue_type === "Sale"
          ? dataNew?.quotation_handover_date
          : moment(dataNew?.lease_start_date).format("YYYY-MM-DD"),
      lease_end_date:
        dataNew?.searchdata?.revenue_type === "Sale"
          ? "2999-12-31"
          : moment(dataNew?.lease_end_date).format("YYYY-MM-DD"),
      quote_start_date: new Date(),
      discount_percentage: totalValue?.totalDiscount,
      tax_percentage: totalValue?.totalDiscount,
      unit_usage: dataNew?.searchdata?.property_purpose,
      company_id: dataNew?.company,
      grace_period_type: "Days",
      apply_grace_period:
        dataNew?.apply_grace_peroid?.length > 0
          ? dataNew?.apply_grace_peroid
          : null,
      revenue_type: dataNew?.searchdata?.revenue_type,
      units: units,
      rental_breakup: refundable,
      billing_start_date:
        dataNew?.searchdata?.revenue_type === "Sale"
          ? moment(dataNew?.quotation_contract_start_date).format("YYYY-MM-DD")
          : dataNew?.quotation_billing_start_date
          ? moment(dataNew?.quotation_billing_start_date).format("YYYY-MM-DD")
          : null,
      bill_generation:
        processBillCycleMethod(dataNew?.quotation_billing_cycle) ?? null,
      billing_day:
        dataNew?.custom_date?.length !== 0 ? dataNew?.custom_date : null,
      billing_cycle_date:
        dataNew?.bill_cycle_date === "As per the Agreement Date"
          ? dataNew?.bill_cycle_date
          : "Custom Date",
      lease_payment_period: dataNew?.quotation_payment_peroid?.value,
      // "total_quote_amount": (totalValue?.totalTax + totalValue?.totalAmount) === 0? 0:(totalValue?.totalTax + totalValue?.totalAmount) - totalValue?.totalDiscount,
      total_quote_amount:
        HoleNumber(
          checkNumber(totalValue?.totalTax) +
            checkNumber(totalValue?.totalAmount)
        ) === 0
          ? 0
          : checkNumber(totalValue?.totalTax) +
            checkNumber(totalValue?.totalAmount) -
            checkNumber(totalValue?.totalDiscount),

      revenue_based_lease: dataNew?.quotation_revenue_based_lease,
      payment_mode: dataNew?.payment_option?.value,
      auto_renewal_escalation: dataNew?.quotation_renewal_based_on?.value,
      auto_renewal: dataNew?.quotation_auto_renewal,
      increment_escalation:
        dataNew?.quotation_escalculation_percent !== null &&
        dataNew?.quotation_escalculation_percent?.length > 0
          ? dataNew?.quotation_escalculation_percent
          : null,
      include_market: dataNew?.quotation_include_market_place,
      open_agreement: dataNew?.convert_to_open_agreement?.is_active,
      milestone_template: dataNew?.delivery_timestone_template?.value ?? null,
      contract_start_date:
        dataNew?.quotation_contract_start_date?.length !== 0 &&
        dataNew?.revenue_type?.value === "Sale"
          ? moment(dataNew?.quotation_contract_start_date).format("YYYY-MM-DD")
          : undefined,
      occpation_date:
        dataNew?.quotation_handover_date?.length !== 0 &&
        dataNew?.revenue_type?.value === "Sale"
          ? dataNew?.quotation_handover_date
          : undefined,
      milestone_item: milestone_item ?? [],
      broker_account: selectedAccount?.broker?.id,
      is_agreement_lock: dataNew?.agreement_lock,
      account_no: selectedAccount?.normal?.account_no,
      broker_agent_account:
        selectedAccount?.agent?.value || selectedAccount?.agent?.id,
      renewal: dataNew?.renewal,
      allow_extension: dataNew?.allow_extension ?? false,
    };

    NetworkCall(
      `${config.api_url}/quotation/create`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setDataNew({
          ...dataNew,
          pdf: response?.data?.data?.data,
          type_master_id: response?.data?.data?.type_master_id,
        });
        createShortlist(
          dataNew.type === "existing lead"
            ? dataNew?.lead_details?.id
            : dataNew?.lead_id,
          unitDetails,
          true
        );
        setDisable(false);

        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Quotation Create Successfully"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });

        next();
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        setDisable(true);
      });
  };

  return (
    <>
      {custom?.type ? (
        <CustomizeQuote
          company_id={dataNew?.company}
          details={{
            quott_no: "",
            lease_start_date: !!dataNew?.lease_start_date
              ? timeZoneConverter(dataNew?.lease_start_date)
              : timeZoneConverter(dataNew?.contract_start_date),
            lease_end_date: !!dataNew?.lease_end_date
              ? timeZoneConverter(dataNew?.lease_end_date)
              : "",
            grace_period_value: dataNew?.grace_peroid ?? "",
            revenue_type: dataNew?.revenue_type ?? "",
          }}
          is_search={true}
          goBack={upDateCustom}
          data={unitDetails}
          onSumbit={onSumbit}
        />
      ) : (
        <>
          <Box padding="16px 8px 16px 16px">
            <Grid container spacing={1} className={classes.root}>
              {/*lead details*/}
              <Grid item xs={3.5} p={1}>
                <Typography className={classes.title}>
                  {t("Opportunity Details")}
                </Typography>

                {dataNew.type === "new lead" && (
                  <ContactList
                    data={{
                      name: dataNew?.name,
                      img: dataNew?.image_url?.src,
                      phone: dataNew?.mobile?.mobile,
                      mail: dataNew?.email_id,
                    }}
                  />
                )}

                {dataNew.type === "existing lead" && (
                  <ContactList
                    data={{
                      name: dataNew?.lead_details?.contact?.first_name,
                      img: dataNew?.lead_details?.contact?.get_assets_url_id,
                      arg: dataNew?.lead_details?.lead_no,
                      phone: dataNew?.lead_details?.contact?.mobile_no,
                      mail: dataNew?.lead_details?.contact?.email,
                    }}
                  />
                )}

                <Box height="18px" />
                <Typography className={classes.title}>
                  {t("Quotation Details")}
                </Typography>
                <Box height="8px" />
                {dataNew?.revenue_type?.value === "Sale" ? (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography className={classes.head}>
                        {t("HANDOVER DATE")}
                      </Typography>
                      <Typography className={classes.sub}>
                        &#x202a;
                        {moment(dataNew?.quotation_handover_date)
                          .tz(moment.tz.guess())
                          .format("DD MMM YYYY")}
                        &#x202c;
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.head}>
                        {t("CONTRACT START DATE")}
                      </Typography>
                      <Typography className={classes.sub}>
                        &#x202a;
                        {moment(dataNew?.quotation_contract_start_date)
                          .tz(moment.tz.guess())
                          .format("DD MMM YYYY")}
                        &#x202c;
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Typography className={classes.head}>
                        {dataNew?.revenue_type?.value} START DATE
                      </Typography>
                      <Typography className={classes.sub}>
                        &#x202a;
                        {moment(dataNew?.lease_start_date)
                          .tz(moment.tz.guess())
                          .format("DD MMM YYYY")}
                        &#x202c;
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={classes.head}>
                        {dataNew?.revenue_type?.value} END DATE
                      </Typography>
                      <Typography className={classes.sub}>
                        &#x202a;
                        {moment(dataNew?.lease_end_date)
                          .tz(moment.tz.guess())
                          .format("DD MMM YYYY")}
                        &#x202c;
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={classes.head}>
                        {t("Billing Start Date")}
                      </Typography>
                      <Typography className={classes.sub}>
                        &#x202a;
                        {moment(dataNew?.quotation_billing_start_date)
                          .tz(moment.tz.guess())
                          .format("DD MMMM YYYY")}
                        &#x202c;
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box height="6px" />
                      <Typography className={classes.head}>
                        {t("GRACE PERIOD")}
                      </Typography>
                      <Typography className={classes.sub}>
                        {dataNew?.quotation_grace_period}
                        {t("Days")}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {/*unit list*/}
              <Grid item xs={4.5}>
                {loading ? (
                  <LoadingSection top="30vh" />
                ) : (
                  <div
                    style={{ height: size?.height - 300, overflow: "scroll" }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent={"space-between"}
                    >
                      <Typography className={classes.title}>
                        {t("Unit Details")}
                      </Typography>
                      <Typography
                        className={classes.Customize}
                        style={{ cursor: "pointer" }}
                        onClick={() => upDateCustom(unitDetails)}
                      >
                        {t("Customize")}
                      </Typography>
                    </Stack>
                    <Grid container spacing={1}>
                      {unitDetails?.map((val, index) => {
                        return (
                          <Grid item xs={6}>
                            <UnitCard
                              data={val}
                              currency={val?.pricing?.[0]?.currencySymbol}
                              delete={true}
                              quotation={true}
                              onDelete={() => onDelete(index)}
                              onClick={(e) => {
                                setOpenUnit(true);
                                setSelectedUnit(e);
                              }}
                              onSelectedUnit={openUnitDetails}
                              is_unit={true}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                )}
              </Grid>
              {/*quotation summery*/}
              <Grid item xs={4}>
                <Box
                  sx={{
                    backgroundColor: "#F5F7FAE6",
                    overflow: "auto",
                    height:
                      dataNew?.revenue_type?.value === "Sale" &&
                      dataNew?.quotation_payment_peroid?.value ===
                        "Milestone Based"
                        ? `calc(100vh-465px)`
                        : `calc(100vh-387px)`,
                  }}
                >
                  <Typography className={classes.title}>
                    {t("Quotation Summary")}
                  </Typography>
                  <QuotationSummery
                    symbol={unitDetails[0]?.pricing?.[0]?.currencySymbol}
                    datas={totalValue}
                    unit={unitDetails}
                    total={
                      totalValue?.totalTax + totalValue?.totalAmount === 0
                        ? 0
                        : totalValue?.totalTax +
                          totalValue?.totalAmount -
                          totalValue?.totalDiscount
                    }
                    t={t}
                    height={
                      dataNew?.revenue_type?.value === "Sale" &&
                      dataNew?.quotation_payment_peroid?.value ===
                        "Milestone Based"
                        ? "465px"
                        : "400px"
                    }
                  />
                </Box>
                {dataNew?.revenue_type?.value === "Sale" &&
                  dataNew?.quotation_payment_peroid?.value ===
                    "Milestone Based" && (
                    <TemplateComponent
                      t={t}
                      total_quote_amount={
                        totalValue?.totalTax +
                        totalValue?.totalAmount -
                        totalValue?.totalDiscount
                      }
                      // total={totalValue?.total_quote_amount}
                      total={totalValue?.totalWithoutOne}
                      contract_start_date={
                        dataNew?.quotation_contract_start_date
                      }
                      symbol={unitDetails[0]?.pricing?.[0]?.currencySymbol}
                    />
                  )}
              </Grid>
            </Grid>
          </Box>
          {/*unit Details*/}
          <Dialog
            className={classes.dialog}
            open={openUnit}
            onClose={() => {
              setOpenUnit(false);
            }}
            fullWidth
            maxWidth="md"
          >
            <Box
              display="flex"
              alignItems="center"
              p={1}
              className={classes.titleBar}
            >
              <Box flexGrow={1}>
                <Typography className={classes.detailTitle}>
                  {t("Unit View")}
                </Typography>
              </Box>
              <Box>
                <IconButton size="small" onClick={() => setOpenUnit(false)}>
                  <CloseIcon style={{ fontSize: "1.25rem" }} />
                </IconButton>
              </Box>
            </Box>
            <Grid container>
              <Grid item xs={6}>
                <UnitSingleDetails
                  revenue_type={dataNew?.revenue_type?.value}
                  startDate={dataNew?.lease_start_date}
                  endDate={dataNew?.lease_end_date}
                  t={t}
                  grace={dataNew?.quotation_grace_period}
                  setUnitDetails={setUnitDetails}
                  dis={true}
                  selectedUnits={selectedUnit}
                />
              </Grid>
              <Grid item xs={6}>
                <ApplyDiscount
                  t={t}
                  applyDiscountFun={applyDiscount}
                  data={selectedUnitDetails?.pricing}
                  onClose={() => setOpenUnit(false)}
                  unit={selectedUnitDetails}
                  totalValues={totalValue}
                />
              </Grid>
            </Grid>
          </Dialog>
          {/*btn container*/}
          <Grid container className={classes.btnroot} spacing={1}>
            <Grid item xs={6}>
              <Button
                className={classes.previousbtn}
                variant="contained"
                onClick={previous}
              >
                {t("Previous")}
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              textAlign={"right"}
              display={"flex"}
              justifyContent={"end"}
            >
              <Button
                className={classes.previousbtn}
                variant="contained"
                onClick={() => navigate()}
              >
                {t("Cancel")}
              </Button>
              <Button
                disabled={disable}
                className={classes.submitbtn}
                variant="contained"
                onClick={onSubmit}
              >
                {t("Submit")}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
