import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import { Box, Checkbox, Divider, FormControlLabel, Grid, Menu, MenuItem, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import React, { useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { DeleteIcon } from "../../../assets"
import { DeleteSvgIcon } from "../../../assets/deleteSvg"
import { DropDownIcon } from "../../../assets/dropDownIcon"
import { InputFieldIcon } from "../../../assets/inputFieldIcon"
import { MultiSelectIcon } from "../../../assets/multiSelectIcon"
import { NoComponentIcon } from "../../../assets/noComponentsIcon"
import { RatingIcon } from "../../../assets/ratingicon"
import { SelectBox, TextBox } from "../../../components"
import { enumSelect, enum_types, useWindowDimensions } from "../../../utils"
import { useStyles } from "../style"

const Form = ({ t = () => false, data = {}, setData = () => false, questions = [], setQuestions = () => false, state = {} }) => {
    const size = useWindowDimensions();
    const classes = useStyles()
    const Range = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    const [selectedComponent, setSelectedComponent] = useState("")
    const [formComponents, setFormComponents] = useState([])
    const draggingPos = useRef(null);
    const dragOverPos = useRef(null);
    // const [enumValue, setEnumValue] = React.useState({ formComponents: [], requestType: [] })
    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const open = Boolean(anchorEl);

    // Function to get Enum value
    const getEnum = async () => {
        const result = await enumSelect([enum_types.inspection_request_type_enum, enum_types.feedback_element_type])
        // setEnumValue({ requestType: result?.inspection_request_type_enum })
        const formComponentsList = result?.feedback_element_type?.map((val, i) => {
            return {
                id: i + 1,
                type: val?.value,
                name: val?.label,
                question: "",
                options: (val?.value !== "text" && val?.value !== "rating") ? [{ value: "", label: "" }] : val?.value === "rating" ? [0, ...arrayRange(1, 10, 1)]?.map((x) => { return { label: x, value: x } }) : [],
                is_mandatory: false,
                start_range: 0,
                end_range: 10,
                icon: val?.value === "text" ? <InputFieldIcon /> : val?.value === "rating" ? <RatingIcon /> : val?.value === "check_box" ? <MultiSelectIcon /> : val?.value === "boolean" ? <DropDownIcon /> : ""
            }
        })
        setFormComponents(formComponentsList)

    }


    // useEffect to load the Enum value while initial load
    React.useEffect(() => {
        getEnum()
        //eslint-disable-next-line
    }, [])
    const handleDragStart = (position) => {
        draggingPos.current = position;
    };

    const handleDragEnter = (position) => {
        dragOverPos.current = position;
        const newItems = [...questions];
        const draggingItem = newItems[draggingPos.current];
        if (!draggingItem) return;

        newItems.splice(draggingPos.current, 1);
        newItems.splice(dragOverPos.current, 0, draggingItem);

        const reorderedItems = newItems.map((item, index) => ({
            ...item,
            id: index
        }));

        draggingPos.current = position;
        dragOverPos.current = null;
        setQuestions(reorderedItems);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const updateState = (k, v) => {
        let error = data?.error ?? {};
        error[k] = "";
        setData({ ...data, [k]: v, error })
    }
    const addId = (arr, option = false) => {
        return arr.map(function (obj, index) {
            return Object.assign({}, obj, option ? { option_id: index } : { auto_inc_id: index });
        });
    };

    const handleComponents = (val, index) => {
        const result = [...questions, val]
        setQuestions(addId(result))
        setSelectedComponent(val)
    }

    //component type change
    const handleSelect = (val, selected) => {
        const result = questions?.map((data) => {
            if (data.auto_inc_id === selected.auto_inc_id) {
                return val
            }
            else {
                return data
            }
        })
        setQuestions(addId(result))
        setSelectedComponent(val)
        handleClose()
    }
    //handleQuestion
    const handleQuestion = (value, detail) => {
        const result = questions?.map((x) => {
            if (x.auto_inc_id === detail.auto_inc_id) {
                return { ...x, question: value }
            }
            else {
                return x
            }
        })
        setQuestions(result)

    }
    //delete question
    const handleDeleteQuestions = (val) => {
        const result = questions?.filter((x) => x.auto_inc_id !== val?.auto_inc_id)
        setQuestions(result)
    }
    //option store
    const handleOptionChange = (val, detail, option_value) => {
        const result = questions?.map((x) => {
            const options = x.auto_inc_id === detail.auto_inc_id ? x?.options?.map((option) => {
                if (option?.option_id === val?.option_id) {
                    return {
                        ...option,
                        value: option_value,
                        label: option_value
                    }
                }
                else {
                    return option
                }
            }) : x.options
            return { ...x, options }
        })
        setQuestions(result)
    }
    //add more options
    const addMoreOptions = (val) => {
        const result = questions?.map((x) => {
            const addOptions = [...val?.options, { value: "", label: "" }]
            if (val?.auto_inc_id === x.auto_inc_id) {
                return {
                    ...x,
                    options: addId(addOptions, true)
                }
            }
            else {
                return x
            }
        })
        setQuestions(result)
    }
    //deleteOptions
    const deleteOption = (val, detail) => {
        const result = questions?.map((x) => {
            const options = x.auto_inc_id === detail.auto_inc_id ? x?.options?.filter((data) => data?.option_id !== val.option_id) : x.options
            return { ...x, options }
        })
        setQuestions(result)
    }
    //arrayRange
    const arrayRange = (start, stop, step) => {
        return Array.from(
            { length: (stop - start) / step + 1 },
            (value, index) => start + index * step
        );
    }
    //Rating Range selection
    const handleRangeSelection = (key, value, detail) => {
        const result = questions?.map((x) => {
            if (x.auto_inc_id === detail.auto_inc_id) {
                if (key === "start_range") {
                    return { ...x, [key]: value?.value, options: value?.value === 0 ? [{ value: 0, label: 0 }, ...arrayRange(value?.value, detail?.end_range, value?.value)?.map((val) => { return { value: val, label: val } })] : arrayRange(value?.value, detail?.end_range, value?.value)?.map((val) => { return { value: val, label: val } }) }
                }
                else {
                    return { ...x, [key]: value?.value, options: detail?.start_range === 0 ? [{ value: 0, label: 0 }, ...arrayRange(1, value?.value, 1)?.map((val) => { return { value: val, label: val } })] : arrayRange(detail?.start_range, value?.value, detail?.start_range)?.map((val) => { return { value: val, label: val } }) }
                }

            }
            else {
                return x
            }
        })
        setQuestions(result)
    }
    //mandatory check
    const mandatoryCheck = (value, data) => {
        const result = questions?.map((val) => {
            if (val.auto_inc_id === data.auto_inc_id) {
                return { ...val, is_mandatory: value?.target?.checked }
            }
            else {
                return val
            }
        })
        setQuestions(result)
    }

  


    return (
        <Box>
            <Grid container>
                <Grid item xs={12} sm={4} md={3} lg={3} className={classes.formBlock} style={{ height: size?.height - 150 }}>
                    <Box p={2} position={"relative"} className={classes.responsiveBlock}>
                        <Typography className={classes.formTitle}>{t("formInformation")}</Typography>
                        <Box mt={1}>
                            <TextBox
                                label={t("formTitle")}
                                placeholder={t("enterFormTitle")}
                                value={data?.form_title}
                                onChange={(e) => updateState("form_title", e?.target?.value)}
                                isReadonly={state?.main?.is_view}
                                color={state?.main?.is_view ? "#F2F4F7" : "#FFFFFF"}
                                isrequired
                                isError={data?.error?.form_title?.length > 0}
                                errorMessage={data?.error?.form_title} />
                        </Box>
                        {/* <Box mt={2} mb={2}>
                            <Divider />
                        </Box> */}
                        <Box mt={2}>
                            <Typography className={classes.formTitle}>{t("formComponents")}</Typography>
                        </Box>
                        <Box mt={1}>
                            <Grid container spacing={2}>
                                {formComponents?.map((x, index) => {
                                    return (
                                        <Grid item xs={12} sm={12} md={6} lg={6} onClick={() => handleComponents(x, index)}>


                                            <Box className={state?.main?.is_view?classes.viewcomponentBox:classes.componentBox}>
                                                <Box>
                                                    <Box display="flex" justifyContent={"center"} alignItems="center">{x.icon}</Box>
                                                    <Typography className={classes.formComponentTitle}>{x.name}</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    )
                                })}

                            </Grid>
                        </Box >

                    </Box >
                </Grid >
                <Grid item xs={12} sm={8} md={9} lg={9} className={classes.rightBlock} sx={{height:state?.main?.is_view? `calc(100vh - 200px)`: `calc(100vh - 250px)`}}>


                    {questions?.length > 0 ? questions?.map((x, i) => {
                        return (
                            <Box className={classes.chooseQuestions}>
                                <Box display="flex" justifyContent={"space-between"} p={1.5}>

                                    <Stack direction="row" alignItems="center" gap={"16px"}>
                                        {!state?.main?.is_view &&
                                            <Box className={classes.dragIcon} draggable
                                                onDragStart={() => handleDragStart(i)}
                                                onDragEnter={() => handleDragEnter(i)}
                                                onDragOver={(e) => e.preventDefault()}>
                                                <img src="/images/drag-reorder.png" alt="drag-reorder" />
                                            </Box>
                                        }
                                        <Box display="flex" justifyContent={"space-between"} className={classes.customSelectBox} alignItems="center" onClick={(e) => { return state?.main?.is_view?false:(handleClick(e), setSelectedComponent(x)) }}>
                                            <Typography className={classes.optionsText}>{x?.name}</Typography>
                                            {x.auto_inc_id === selectedComponent?.auto_inc_id && open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                        </Box>
                                        <FormControlLabel
                                            // value="mandatory"
                                            control={<Checkbox onChange={(e) => state?.main?.is_view?false:mandatoryCheck(e, x)} checked={x.is_mandatory} />}
                                            label="Mandatory"
                                            labelPlacement="end"
                                            sx={{ marginRight: '0px' }}
                                        />
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            className={classes.menuList}
                                            PaperProps={{
                                                style: {
                                                    width: 180,
                                                },
                                            }}
                                        >
                                            {formComponents?.map((val) => {
                                                return (
                                                    <MenuItem
                                                        onClick={() => {
                                                            handleSelect(val, selectedComponent)
                                                        }}
                                                        className={classes.menuItem}
                                                    >
                                                        <Typography className={classes.optionsText} style={{ color: val?.id === selectedComponent?.id ? "#5078E1" : "#4E5A6B" }}>{val.name}</Typography>
                                                    </MenuItem>
                                                )
                                            })

                                            }
                                        </Menu>
                                    </Stack>
                                    {!state?.main?.is_view &&
                                        <Box display={"flex"} justifyContent="center" alignItems={"center"} onClick={() => handleDeleteQuestions(x)} style={{ cursor: "pointer" }}><DeleteSvgIcon /></Box>
                                    }
                                </Box>
                                <Divider />
                                <Box mt={1} p={1.5}>
                                    <Typography className={classes.questiontxt}>{t("Question Here")}</Typography>
                                    <Box mt={1}>
                                        <TextBox label="" placeholder={t("Type question here")} value={x.question} onChange={(e) => handleQuestion(e.target.value, x)} isReadonly={state?.main?.is_view}
                                            color={state?.main?.is_view ? "#F2F4F7" : "#FFFFFF"} />
                                    </Box>
                                </Box >
                                {
                                    x.type !== "text" &&
                                    <Box p={1.5}>
                                        <Typography className={classes.optiontitle}>{x.type === "boolean" ? "Single select OPTIONS" : x.type === "rating" ? (state?.main?.is_view?"Rating range":"Choose Rating range") : x.type === "radio" ? "MULTIPLE SELECT OPTIONS" : ""}</Typography>
                                        {x.type !== "rating" &&
                                            <>

                                                {x.options?.map((val) => {
                                                    return (
                                                        <Box mt={1}>
                                                            <Grid container spacing={2}>
                                                                < Grid item xs={12} sm={12} md={4} lg={3} mt={0.5} >

                                                                    <Stack direction="row" spacing={2} alignItems={"center"}>
                                                                        <TextBox label="" placeholder={`Type Choice `} value={val.value} onChange={(e) => handleOptionChange(val, x, e.target.value)} isReadonly={state?.main?.is_view} />
                                                                        {!state?.main?.is_view &&
                                                                            <Box display="flex" alignItems={"center"} justifyContent={"center"} onClick={() => deleteOption(val, x)} style={{ cursor: "pointer" }}><DeleteIcon /></Box>
                                                                        }
                                                                    </Stack>
                                                                </Grid >
                                                            </Grid >
                                                        </Box >
                                                    )
                                                })}
                                                {!state?.main?.is_view &&
                                                    <Box mt={2} onClick={() => addMoreOptions(x)}>
                                                        <Typography className={classes.addAnother}>{t("Add Another Option")}</Typography>
                                                    </Box >
                                                }
                                            </>
                                        }
                                        {
                                            x.type === "rating" &&
                                            <Box mt={1}>
                                                <Stack direction="row" spacing={2} alignItems="center">
                                                    <Box>
                                                        <SelectBox label="" value={{ value: x.start_range, label: x.start_range }} options={Range?.map((val) => { return { label: val, value: val } })} onChange={(val) => handleRangeSelection("start_range", val, x)} menuPlacement={i !== 0 && i === questions?.length - 1 ? "top" : "bottom"} isReadOnly={state?.main?.is_view} />
                                                    </Box>
                                                    <Box>-</Box>
                                                    <Box>
                                                        <SelectBox label="" value={{ value: x.end_range, label: x.end_range }} options={Range?.map((val) => { return { label: val, value: val } })} onChange={(val) => handleRangeSelection("end_range", val, x)} menuPlacement={i !== 0 && i === questions?.length - 1 ? "top" : "bottom"} isReadOnly={state?.main?.is_view} />
                                                    </Box>
                                                </Stack>
                                            </Box>
                                        }
                                    </Box >
                                }
                            </Box >)
                    }) :
                        <Box className={classes.nocomponentBox}>
                            <NoComponentIcon />
                            {state?.main?.is_view ?
                                <Box mt={1}>
                                    <Typography className={classes.nocomponentText}>{t("No Questions added")}</Typography>
                                </Box> :
                                <Box mt={1}>
                                    <Typography className={classes.nocomponentText}>{t("Add Form Component")}</Typography>
                                    <Box mt={1}>
                                        <Typography className={classes.choosecomponentText}>{t("Choose component from left side form components")}</Typography>
                                        <Typography className={classes.choosecomponentText}>{t("panel")}</Typography>
                                    </Box>
                                </Box >
                            }
                        </Box >
                    }
                </Grid >
            </Grid >
        </Box >
    )
}
export default withTranslation("formBuilder")(Form)