import { makeStyles } from "@mui/styles";
import { FontFamilySwitch, remCalc } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    title:{
        fontSize:remCalc(12),
        fontFamily: FontFamilySwitch().semiBold,
        color:"#98a0ac"
    },
    slotInputBox:{
        border:"1px solid #E4E8EE",
        borderRadius:theme.palette.borderRadius,
        height:"46px",
        alignItems:"center",
        padding:"16px"
    },
    menuList: {
        backgroundColor:"#FFFFFF !important",
        '&:hover': {
          backgroundColor: "transparent"
        }
      },
      slotTime: {
        fontSize: remCalc(12),
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
      },
      slotBox:{
        borderRadius:"8px",
        border:"1px solid #CED3DD",
        display:"flex",
        justifyContent:"center",
        padding:"12px 0px"
      },
      selectedSlotBox:{
        borderRadius:"8px",
        border:"1px solid #5078E1",
        display:"flex",
        justifyContent:"center",
        backgroundColor:"#F1F7FF",
        padding:"12px 0px"
      },
      slotValue:{
        fontSize: remCalc(14),
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.primary
      },
      errorTitle:{
        color:"#ff4b4b",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize:remCalc(12)
      }
}))