import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const AmenityMappingNewStyle = makeStyles((theme) => ({
    statusLabel: {
        color: theme.typography.color.tertiary,
        fontSize:"0.75rem",
        marginBottom: "4px",
        fontFamily: FontFamilySwitch().semiBold,
    },
    header: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    peakRate: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
        marginLeft: "16px",
        marginRight: "16px"
    },
    slot_textbox: {
        height: 22,
        width: 112,
        marginTop: 5
    },
    day: {
        color: theme.typography.color.primary,
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 16,
        height: 32,
        width: 60,
        fontSize:"0.75rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        fontFamily: FontFamilySwitch().bold
    },
    highlight_day: {
        color: theme.palette.primary.main,
        background: theme.palette.info.light,
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 16,
        height: 32,
        width: 60,
        fontSize:"0.75rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        fontFamily: FontFamilySwitch().bold
    },
    day_text: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold
    },
    ex_selected: {
        height: 15,
        width: 15,
        borderRadius: 4,
        border: `1px solid ${theme.palette.primary.main}`,
        background: theme.palette.primary.main,
    },
    ex_auto_select: {
        height: 15,
        width: 15,
        borderRadius: 4,
        background: theme.palette.info.light,
        border: `1px solid ${theme.palette.primary.main}`,
    },
    slotLabel: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().regular,
        marginInlineStart: "8px"
    },
    desclimer: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().regular,
    },
    selected: {
        borderRadius: 4,
        border: `1px solid ${theme.palette.primary.main}`,
        background: theme.palette.info.light,
        textAlign: "center",
        margin: 4,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        padding: "10px 6px",
        color: theme.palette.primary.main,
        cursor: "pointer",
        direction: "ltr"
    },
    auto_selected: {
        borderRadius: 4,
        background: theme.palette.info.light,
        textAlign: "center",
        margin: 4,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        padding: "10px 6px",
        color: theme.typography.color.Tertiary
    },
    body: {
        height: "380px",
        overflow: "auto"
    },
    not_selected: {
        borderRadius: 4,
        border: `1px solid ${theme.palette.border.secondary}`,
        backgroundColor: "transparent",
        textAlign: "center",
        margin: 4,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        padding: "10px 6px",
        color: theme.typography.color.primary,
        cursor: "pointer",
        direction: "ltr"
    },
    tertiaryHeader: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().bold
    },
    customToggle: {
        background: theme.palette.background.secondary,
        height: 40,
        borderRadius: 8
    },
    selectedtoggleBtn: {
        color: "#000",
        fontFamily: FontFamilySwitch().bold,
        background: "#fff",
        borderRadius: "8px",
        height: 32,
        "&:hover": {
            background: "#fff",
        }
    },
    toggleBtn: {
        color: theme.typography.color.secondary,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        height: 32,
        "&:hover": {
            background: "transparent",
        }
    },
    bottomStack: {
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        height: 60,
        background: "#fff",
        borderTop: `1px solid ${theme.palette.border.secondary}`
    },
    createBtn: {
        height: 40,
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        width: 122
    },
    closeBtn: {
        height: 40,
        fontSize:"0.875rem",
        width: 122,
        fontFamily: FontFamilySwitch().bold,
        color: "#000",
        border: `1px solid ${theme.palette.border.secondary}`,
        "&:hover": {
            background: "transparent"
        }
    },
    overBookingLabel: {
        fontSize:"0.875rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
        whiteSpace: "noWrap",
        [theme.breakpoints.only("sm")]: {
            whiteSpace: "normal",
            paddingInlineStart: "8px"
        }
    },
    addPeakHourLabel: {
        paddingTop: 8,
        fontSize:"0.875rem",
        color: theme.palette.primary.main,
        fontFamily: FontFamilySwitch().semiBold,
        cursor: "pointer",
        marginInlineStart: "16px"
    },
    tooltipHeader: {
        fontSize:"0.75rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    tooltipContent: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    removeLabel: {
        fontSize:"0.875rem",
        color: theme.palette.error.main,
        fontFamily: FontFamilySwitch().semiBold,
        cursor: "pointer",
        marginInlineStart: "16px"
    },
    error: {
        fontSize:"0.75rem",
        color: theme.palette.error.main,
        fontFamily: FontFamilySwitch().semiBold,
    },
    topBox: {
        [theme.breakpoints.only("sm")]: {
            height: "calc(100vh - 465px)",
            overflow: "auto"
        }
    },
    amountTextBox: {
        width: 130,
        [theme.breakpoints.only("sm")]: {
            width: 80
        }
    }
}))
