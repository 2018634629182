import DeleteIcon from "@mui/icons-material/Delete";
import DriveFolderUploadOutlined from "@mui/icons-material/DriveFolderUploadOutlined";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { DocumentViewer } from "../../components/fileViewer/index";
import { AlertContext, BackdropContext } from "../../contexts";
import { multiFileUpload, assestType } from "../../utils/common";
import { AlertProps, allowed_file_size } from "../../utils";
import LinkIcon from "../../assets/linkIcon";
import { AlertDialog } from "../dialog";
import { FormGenerator } from "../formGenerator";
import { v4 as uuidv4 } from "uuid";
import { Slider } from "../slider";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  fileroot: {
    backgroundColor: "#DEEAFC",
    height: (props) => props?.imgHeight ?? "150px",
    fontSize: "0.75rem",
    color: "#5078E1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.palette.borderRadius,
    cursor: "pointer",
  },
  imgContainer: {
    width: "100%",
    borderRadius: theme.palette.borderRadius,
  },
  hoverCard: {
    opacity: "12",
    backgroundColor: "#0000009c",
    position: "absolute",
    width: "100%",
    height: "150px",
    top: 0,
    bottom: 0,
    borderRadius: 4,
  },
  delete: {
    color: "red",
    position: "absolute",
    top: "4px",
    right: "8px",
    backgroundColor: "white",
  },
  img: {
    objectFit: "cover",
    height: (props) => props?.imgHeight ?? "150px",
    width: "100%",
    borderRadius: theme.palette.borderRadius,
  },
  video: {
    height: "150px",
    width: "100%",
  },
}));
const ImgUpload = (props) => {
  const {
    onClickImage = null,
    t = () => false,
    updateImageURL = () => false,
    selectedImageURL = [],
    handleFile = () => false,
  } = props;
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const [hover, setHover] = React.useState(false);
  const [dialog, setDialog] = React.useState(false);
  const [data, setData] = React.useState({
    linkName: "",
    link: "",
    error: {
      linkName: "",
      link: "",
    },
  });
  const [open, setOpen] = React.useState({
    bool: false,
    data: [],
  });
  const [isDisabled, setIsDisabled] = React.useState(false);

  //onclick images
  const onViewImage = (data) => {
    setOpen({
      bool: !open?.bool,
      data: data ?? [],
    });
  };

  const checkDocumentType = (data, id) => {
    let isvalid = true;
    for (let i = 0; i < data.length; i++) {
      if (id === 4) {
        if (data[i].type === "application/pdf") {
          isvalid = true;
        } else {
          isvalid = false;
        }
      } else if (id === 2) {
        let imageTypes = ["image/png", "image/jpeg", "image/jpg"];
        if (imageTypes.includes(data[i].type)) {
          isvalid = true;
        } else {
          isvalid = false;
        }
      }
    }
    return isvalid;
  };

  const updateState = async (value) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: t("Loading"),
    });
    try {
      // comman function
      let uploaded_files;
      if (props?.id === 3) {
        setIsDisabled(true);
        if (validateForm()) {
          uploaded_files = [
            {
              url: data?.link,
              asset_type: 3,
              file_meta: {
                name: data?.linkName,
                id: uuidv4(),
              },
            },
          ];
          updateImageUrlFunc(uploaded_files);
        } else {
          setIsDisabled(false);
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "",
          });
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Please fill mandatory fields."),
          });
        }
      } else {
        const propsData = { companyId: props?.companyID, type: props?.id };
        if (props?.termsCondtion) {
          if (props?.id === 2) {
            if (checkDocumentType(value, props?.id)) {
              uploaded_files = await multiFileUpload(
                value,
                propsData,
                alert,
                allowed_file_size
              );
              updateImageUrlFunc(uploaded_files);
            } else {
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Please choose Image only"),
              });
              backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "",
              });
            }
          } else if (props?.id === 4) {
            if (checkDocumentType(value, props?.id)) {
              uploaded_files = await multiFileUpload(
                value,
                propsData,
                alert,
                allowed_file_size
              );
              updateImageUrlFunc(uploaded_files);
            } else {
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Please choose PDF only"),
              });
              backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "",
              });
            }
          } else {
            uploaded_files = await multiFileUpload(
              value,
              propsData,
              alert,
              allowed_file_size
            );
            updateImageUrlFunc(uploaded_files);
          }
        } else {
          uploaded_files = await multiFileUpload(
            value,
            propsData,
            alert,
            allowed_file_size
          );
          updateImageUrlFunc(uploaded_files);
        }
      }
    } catch (err) {
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Internal error. Please try again later."),
      });
    }
  };

  const updateImageUrlFunc = (uploaded_file) => {
    let dataImage =
      uploaded_file?.length > 0
        ? [...selectedImageURL, ...uploaded_file]
        : [...selectedImageURL];
    updateImageURL(dataImage);
    setData({
      linkName: "",
      link: "",
      error: {
        linkName: "",
        link: "",
      },
    });
    setDialog(false);
    backdrop.setBackDrop({
      ...backdrop,
      open: false,
      message: "",
    });
  };

  //update data
  const updateStateDialog = (key, value) => {
    let error = data.error;
    error[key] = "";
    setData({ ...data, [key]: value, error });
  };
  //validate
  const validateForm = () => {
    let isValid = true;
    let error = data.error;

    if (data?.linkName?.length === 0) {
      isValid = false;
      error.linkName = t("Link name is Required");
    }
    if (data?.link?.length === 0) {
      isValid = false;
      error.link = t("Link is Required");
    }
    setData({ ...data, error });
    return isValid;
  };
  const formData = [
    {
      size: {
        xs: 12,
      },
      isActive: true,
      component: "text",
      label: t("Link Name"),
      value: data?.linkName,
      placeholder: t("Enter Link Name"),
      onChange: (value) => updateStateDialog("linkName", value.target.value),
      error: data?.error?.linkName,
      isRequired: true,
      options: [],
      // multiline: true
    },
    {
      size: {
        xs: 12,
      },
      isActive: true,
      component: "text",
      label: t("Link"),
      value: data?.link,
      placeholder: t("Enter Link"),
      onChange: (value) => updateStateDialog("link", value.target.value),
      error: data?.error?.link,
      isRequired: true,
      options: [],
      // multiline: true
    },
    {
      size: {
        xs: 12,
      },
      isActive: true,
      component: "button",
      label: t("Save"),
      value: data?.link,
      disable: isDisabled,
      onClick: () => updateState(),
    },
  ];

  const deleteImg = (e) => {
    handleFile(e);
    if (props?.id === 3) {
      const updatedImages = selectedImageURL.filter(
        (image) => image.id !== e.id
      );
      updateImageURL(updatedImages);
    }
  };
  const getVideoId = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url?.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  };
  const classes = useStyles(props);
  return (
    <div>
      <Grid container spacing={2}>
        {!props?.readOnly && (
          <Grid item xs={12} md={props?.srcType === "external_unit" ? 3 : 2}>
            <label className="btn label">
              <div
                className={classes.fileroot}
                onClick={() => {
                  if (props?.accept === "video/*") {
                    setIsDisabled(false);
                    setDialog(true);
                  }
                }}
              >
                {props?.accept !== "video/*" && (
                  <input
                    accept={props?.accept}
                    type="file"
                    name="myImage"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      updateState(e.target.files);
                    }}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    multiple
                  />
                )}
                <center>
                  {props?.accept === "video/*" ? (
                    <>
                      <LinkIcon />
                      <Typography>{t("Add New Link")}</Typography>
                    </>
                  ) : (
                    <>
                      {props?.srcType === "external_unit" ? (
                        <img src="/images/icons8-upload.svg" alt="upload_img" />
                      ) : (
                        <DriveFolderUploadOutlined
                          style={{ fontSize: "58px", color: "#5078E1" }}
                        />
                      )}
                      <Typography classNa>{t("Upload File")}</Typography>
                    </>
                  )}
                </center>
              </div>
            </label>
          </Grid>
        )}
        {props?.id === 1 && (
          <>
            {selectedImageURL?.map((val, index) => {
              return (
                <>
                  {val?.asset_type === assestType?.Facility_Images && (
                    <Grid item xs={6} md={2} className={classes.imgContainer}>
                      <div
                        style={{
                          position: "relative",
                          cursor: onClickImage ? "pointer" : "auto",
                        }}
                        onClick={() =>
                          onClickImage
                            ? onClickImage(val, index)
                            : onViewImage(val, index)
                        }
                      >
                        <img
                          type="file"
                          accept="image/*"
                          multiple
                          alt="Not Found"
                          width={"250px"}
                          src={val.url}
                          className={classes.img}
                        />

                        <br />
                        {!props?.readOnly && (
                          <IconButton
                            className={classes.delete}
                            onClick={(e) => {
                              deleteImg(val);
                              e.stopPropagation();
                            }}
                            size="small"
                          >
                            <DeleteIcon style={{ fontSize: "0.875rem" }} />
                          </IconButton>
                        )}
                      </div>
                    </Grid>
                  )}
                </>
              );
            })}
          </>
        )}
        {props?.id === 2 && (
          <>
            {selectedImageURL?.map((val, index) => {
              return (
                <>
                  {val.asset_type === assestType?.General_Images && (
                    <Grid
                      item
                      xs={6}
                      md={props?.srcType === "external_unit" ? 3 : 2}
                      className={classes.imgContainer}
                    >
                      <div
                        style={{
                          position: "relative",
                          cursor: onClickImage ? "pointer" : "auto",
                        }}
                        onClick={() =>
                          onClickImage
                            ? onClickImage(val, index)
                            : onViewImage(val, index)
                        }
                      >
                        <img
                          type="file"
                          accept="image/*"
                          multiple
                          alt="Not Found"
                          width={"250px"}
                          src={val.url}
                          className={classes.img}
                        />

                        <br />
                        {!props?.readOnly && (
                          <IconButton
                            className={classes.delete}
                            onClick={(e) => {
                              deleteImg(val);
                              e.stopPropagation();
                            }}
                            size="small"
                          >
                            <DeleteIcon style={{ fontSize: "0.875rem" }} />
                          </IconButton>
                        )}
                      </div>
                    </Grid>
                  )}
                </>
              );
            })}
          </>
        )}

        {props?.id === 3 && (
          <>
            {selectedImageURL?.map((val, index) => {
              const url = getVideoId(val?.url);
              return (
                <>
                  {val?.asset_type === assestType?.Videos && (
                    <Grid item xs={6} md={2} className={classes.imgContainer}>
                      <div
                        style={{ position: "relative" }}
                        onMouseEnter={() =>
                          props?.link ? setHover(true) : null
                        }
                        onMouseLeave={() =>
                          props?.link ? setHover(false) : null
                        }
                      >
                        <iframe
                          title={val?.file_meta?.name}
                          className={classes.video}
                          src={"https://www.youtube.com/embed/" + url}
                        ></iframe>
                        {/* <video
                          className={classes.img}

                          controls
                        >
                          <source src={val.url} type="video/mp4" />
                        </video> */}
                        <Typography>{val?.file_meta?.name}</Typography>
                        {!props?.readOnly && hover && (
                          <IconButton
                            className={classes.delete}
                            onClick={(e) => {
                              deleteImg(val);
                              e.stopPropagation();
                            }}
                            size="small"
                          >
                            <DeleteIcon style={{ fontSize: "0.875rem" }} />
                          </IconButton>
                        )}
                      </div>
                    </Grid>
                  )}
                </>
              );
            })}
          </>
        )}

        {props?.id === 4 && (
          <>
            {selectedImageURL?.map((val, index) => {
              return (
                <>
                  {val.asset_type === assestType?.Documents && (
                    <Grid item xs={6} md={2} className={classes.imgContainer}>
                      <div
                        style={{
                          position: "relative",
                          overflow: "hidden",
                          height: "150px",
                        }}
                        onClick={() =>
                          onClickImage
                            ? onClickImage(val, index)
                            : onViewImage(val, index)
                        }
                      >
                        <DocumentViewer url={val.url} />
                        <br />
                        {!props?.readOnly && (
                          <IconButton
                            className={classes.delete}
                            onClick={(e) => {
                              deleteImg(val);
                              e.stopPropagation();
                            }}
                            size="small"
                          >
                            <DeleteIcon style={{ fontSize: "0.875rem" }} />
                          </IconButton>
                        )}
                      </div>
                    </Grid>
                  )}
                </>
              );
            })}
          </>
        )}
        {props?.id === 5 && (
          <>
            {selectedImageURL?.map((val, index) => {
              return (
                <>
                  {val?.asset_type === assestType?.Three_sixty_Images && (
                    <Grid item xs={6} md={2} className={classes.imgContainer}>
                      <div
                        style={{
                          position: "relative",
                          cursor: onClickImage ? "pointer" : "auto",
                        }}
                        onClick={() =>
                          onClickImage
                            ? onClickImage(val, index)
                            : onViewImage(val, index)
                        }
                      >
                        <img
                          type="file"
                          accept="image/*"
                          multiple
                          alt="Not Found"
                          width={"250px"}
                          src={val.url}
                          className={classes.img}
                        />

                        <br />
                        {!props?.readOnly && (
                          <IconButton
                            className={classes.delete}
                            onClick={(e) => {
                              deleteImg(val);
                              e.stopPropagation();
                            }}
                            size="small"
                          >
                            <DeleteIcon style={{ fontSize: "0.875rem" }} />
                          </IconButton>
                        )}
                      </div>
                    </Grid>
                  )}
                </>
              );
            })}
          </>
        )}
        {props?.id === 7 && (
          <>
            {selectedImageURL?.map((val, index) => {
              return (
                <>
                  {val?.asset_type === assestType?.Floor_Plan && (
                    <Grid item xs={6} md={2} className={classes.imgContainer}>
                      <div
                        style={{
                          position: "relative",
                          cursor: onClickImage ? "pointer" : "auto",
                        }}
                        onClick={() =>
                          onClickImage
                            ? onClickImage(val, index)
                            : onViewImage(val, index)
                        }
                      >
                        <img
                          type="file"
                          accept="image/*"
                          multiple
                          alt={`Floor_Plan_${index + 1}`}
                          width={"250px"}
                          src={val.url}
                          className={classes.img}
                        />

                        <br />
                        {!props?.readOnly && (
                          <IconButton
                            className={classes.delete}
                            onClick={(e) => {
                              deleteImg(val);
                              e.stopPropagation();
                            }}
                            size="small"
                          >
                            <DeleteIcon style={{ fontSize: "0.875rem" }} />
                          </IconButton>
                        )}
                      </div>
                    </Grid>
                  )}
                </>
              );
            })}
          </>
        )}
      </Grid>
      <AlertDialog
        medium={true}
        open={dialog}
        onClose={() => setDialog(false)}
        header={t("Add New Link")}
        component={
          <Box p={2}>
            <FormGenerator t={t} components={formData} />
          </Box>
        }
      />
      <AlertDialog
        header={t("Asset View")}
        open={open?.bool}
        onClose={onViewImage}
        component={
          <Box height="450px" alignItems="center">
            <Slider assets={open?.data} uploadImage />
          </Box>
        }
        medium={open?.data?.asset_type === 4 ? false : true}
      />
    </div>
  );
};
export default withTranslation("imgUpload")(ImgUpload);
