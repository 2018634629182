import { makeStyles } from "@mui/styles";

export const SettlementStyles = makeStyles((theme) => ({
  settlementRoot: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 0px 16px #00000014",
    borderRadius: "4px",
    height: `calc(100vh - 150px)`,
    overflow: "auto",
  },
  iconButton: {
    borderRadius: "4px",
    border: `1px solid ${theme.palette.border.primary}`,
    height: "40px",
    width: "40px",
  },
}));
