import React from 'react'
import SuccessIcon from './utils/successIcon'
import { Box, Button, Stack, Typography } from '@mui/material'
import { BookingSuccessStyle } from './style'
import { AmenitiesBookingContext } from './amenitiesBookingContext'
import InvoiceDetail from './components/invoiceDetail'
import { withTranslation } from 'react-i18next'

const BookingSuccess = ({ slots = {}, amenityData = {}, t = () => false, amounts = {} }) => {
    const classes = BookingSuccessStyle()
    const { goBack, setBooked, member, setMember } = React.useContext(AmenitiesBookingContext)
    return (
        <Stack spacing={2} alignItems={"center"} justifyContent={"center"} sx={{ width: "100%" }} mt={3}>
            <SuccessIcon />
            <Typography className={classes.title}>{amenityData?.description} {t("booked successfully")}</Typography>


            <Box className={classes.msgBox} p={2}>
                <Typography className={classes.msgBoxText}>{t("Managed invoice generated please verify and post as invoice")}</Typography>
            </Box>
            {/* <Typography className={classes.invoiceText}>{t("Invoice No")}: INV-1234515354, 5 Aug 2022</Typography> */}

            <InvoiceDetail slots={slots} amenityData={amenityData} customHeight amounts={amounts} member={member} booked />
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
                {/* <Button variant='outlined'><InvoiceDownloadIcon /> {t("Invoice")}</Button>
                <Button variant='outlined'> <SendPaymentLinkIcon /> {t("Send payment link")}</Button> */}
                <Button variant='contained'
                    sx={{ width: 200 }}
                    onClick={() => {
                        setBooked(false)
                        setMember({ ...member, count: "", bool: false })
                        goBack("success")
                    }}>{t("Done")}</Button>

            </Stack>
        </Stack >
    )
}

export default withTranslation("amenityBooking")(BookingSuccess)