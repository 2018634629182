
import { Box, Button, Grid, Typography } from "@mui/material";
import { saveAs } from "file-saver";
import React from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { Download, NoInvoice, ReceiptTotal, UnusedAmount, UsedAmount } from "../../../assets";
import { AggreementCardInfo, AlertDialog, DetailsCardCoponent, Subheader } from "../../../components";
import { DocumentViewer } from "../../../components/fileViewer";
import { config } from "../../../config";
import { AlertContext, BackdropContext } from '../../../contexts';
import { NetworkCall } from "../../../networkcall";
import { Routes } from "../../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../../utils";
import { accountFormState, getReceiptDetails } from "../../../utils/receipt";
import { InvoiceTable } from "../../receiptCreate/components";
import { ReceiptStyles } from "./styles";

const Receipt = ({ t, details = {}, id, currency, }) => {
    const classes = ReceiptStyles();
    const navigate = useNavigate();
    const alert = React.useContext(AlertContext);
    const cardDetails = getReceiptDetails(t, details, details?.currency?.symbol);
    const [list, setList] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState({ ...accountFormState });
    const [count,setCount]=React.useState(0)
    const [disabled , setDisabled] = React.useState(false)
    const backdrop = React.useContext(BackdropContext);

    //update state 
    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        setData({ ...data, [key]: value, error });
    };
    //initial load
    React.useEffect(() => {
        setData({
            ...data,
            available_balance: details?.available_balance,
            amountTopay: 0
        })
        // eslint-disable-next-line
    }, [details])
    //get invoice against account
    const getInvoice = (offset, searchText, limit=10) => {


        const payload = {
            offset: offset,
            limit,
            id: details?.account_no,
            search: searchText,
            client: localStorage.getItem(LocalStorageKeys.clinetID)
        }

        NetworkCall(
            `${config.api_url}/invoice/account_invoice`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
 
            let data = response?.data?.invoice?.map((val) => {
                return (
                    {
                        id: val?.id,
                        invoice_id: val?.invoice_no,
                        agreement_id: val?.agreement?.agreement_no,
                        invoice_generate_date: val?.created_at,
                        status: val?.payment_status === "no-due" ? "Paid" : "Unpaid",
                        pending_amount: `${val?.currency_master?.symbol ?? currency} ${val?.invoice_due_amount ?? 0} `,
                        total_amount: `${val?.currency_master?.symbol ?? currency} ${val?.invoice_total_amount ?? 0} `,
                        total_amount_value: val?.invoice_due_amount,
                        url: "View PDF",
                        is_active: false,
                        asset: val?.asset_url,
                        symbol: val?.currency_master?.symbol,
                        amount_tagged: val?.invoice_due_amount,

                    }
                )
            })

                setList(data)
                setCount(response?.data?.count?.[0]?.count)
            

        }).catch((err) => {
            console.log(err)
        })

  
    }
    //download pdf
    const downloadPdf = () => {
        saveAs(details?.url, `${details?.reciepts_no}.pdf`)
    }
    //render link
    const renderLink = () => {
        return (
            <Typography onClick={() => setOpen(true)} className={classes.view}>{t("viewPdf")}</Typography>
        )
    }
    //render btn
    const renderButton = () => {
        return (
            <Button onClick={downloadPdf} className={classes.downBtn} variant="outlined" startIcon={<Download />}>
                {t("download")}
            </Button>
        )
    }

    const infoData = [
        {
            id: "1",
            title: `${details?.currency?.symbol} ${details?.amount_paid ?? 0}`,
            subtitle: t("receiptTotal"),
            image: <ReceiptTotal />,
            color: "#E0FFED"
        },
        {
            id: "2",
            title: `${details?.currency?.symbol} ${details?.used_amount ?? 0}`,
            subtitle: t("usedAmount"),
            image: <UsedAmount />,
            color: "#E0E9FF"
        },
        {
            id: "3",
            title: `${details?.currency?.symbol} ${details?.unused_amount ?? 0}`,
            subtitle: t("unUsedAmount"),
            image: <UnusedAmount />,
            color: "#FFF1E0"
        },
        {
            id: "4",
            title: `${details?.invoices_tagged ?? 0}`,
            subtitle: t("noInvoice"),
            image: <NoInvoice />,
            color: "#E0F3FF"
        },

    ]
    //calculateData
    const calculation = (list) => {

        let ourBalance = data?.available_balance ? data?.available_balance : 0;

        let activeAmt = list?.reduce((total, item) => {
            if (item.is_active) {
                return total = total + (item?.total_amount_value ? item?.total_amount_value : 0)
            }
            return total
        }, 0)


        let avalBalance = ourBalance - activeAmt; //-200
        let totalAmt = activeAmt > ourBalance ? ourBalance : activeAmt

        return {
            avalBalance: avalBalance < 0 ? 0 : avalBalance,
            totalAmt,
            as: avalBalance
        }
    }
    //onsubmit
    const onSubmit = () => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "",
        })
        setDisabled(true)
        const dataList = list?.filter(val => val?.is_active)?.map((val) => {
            return {
                invoice_due_amount: val?.invoice_due_amount,
                id: val?.id,
                amount_tagged: val?.result,
                receipt_id: id
            }
        })
        const payload = {
            invoices: dataList,
            receipt_id: id,
            amount_paid: calculation(list)?.avalBalance ?? 0,
        }

        NetworkCall(
            `${config.api_url}/settlement/payment`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                })
                setDisabled(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Payment Settled"),
                });
                navigate(Routes.receipt)
            }).catch((err) => {
                setDisabled(false)
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                })
                console.log(err)
            })
    }

    // filter components to set the grid size dynamic
    const hasInactiveComponents = cardDetails.some(item => !item.isActive);

    const filteredComponents = cardDetails.filter(item => item.isActive)
    .map(item => ({
      ...item,
      size: {
        ...item.size,
        lg: hasInactiveComponents ? 3 : 2.4
      }
    }));

    return (
        <Box className={classes.root}>
            <Grid container>
                <Grid item xs={9}>
                    <Subheader hideBackButton={true} title={details?.reciepts_no} />
                    <Box className={classes.detailRoot}>
                        <Box marginTop="12px" padding="12px">
                            {/* card details */}
                            <DetailsCardCoponent render={renderLink()} justify={true} title={t("receiptDetails")} components={filteredComponents} />
                        </Box>
                        {/* invoice table */}
                        <InvoiceTable
                            total={data?.amountTopay}
                            id={details?.account_no}
                            list={list}
                            count={count}
                            setList={setList}
                            view={true}
                            currency={details?.currency?.symbol}
                            availableTotal={`${details?.currency?.symbol} ${data?.available_balance ?? 0}`}
                            updateState={updateState}
                            getInvoice={getInvoice}
                            datas={data}
                            setData={setData}
                        />
                    </Box>
                </Grid>
                <Grid item xs={3} className={classes.left}>
                    <Subheader hideBackButton={true} title={t("receiptInfo")} />
                    <Box className={classes.detailRoot2} p={2}>
                        <Grid container spacing={1}>

                            {
                                infoData?.map((val) => {
                                    return (
                                        <Grid item xs={6}>
                                            <AggreementCardInfo data={val} />
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>

                    </Box>
                    <Box p={1}>
                        <Button
                            onClick={onSubmit}
                            disabled={list?.filter((val) => val?.is_active).length > 0 ? (disabled ? true : false) : true}
                            variant="contained" fullWidth>{t("settlePayment")}</Button>

                    </Box>
                </Grid>
            </Grid>


            <AlertDialog
                header={details?.reciepts_no}
                onClose={() => setOpen(false)}
                open={open}
                render={renderButton()}
                component={
                    <Box p={1} bgcolor={"#F5F7FA"}>
                        <center>
                            {
                                details?.url ?
                                    <DocumentViewer url={details?.url} />
                                    :
                                    t("No Data Found")
                            }

                        </center>
                    </Box>
                }
            />
        </Box >
    )
}
export default withTranslation("receipt")(Receipt); 