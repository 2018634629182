import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Stepper, Subheader } from "../../components";
import { AuthContext ,BackdropContext} from "../../contexts";
import { CreateQuotationListContext } from "../../contexts/createQuotationContext";
import { Routes } from "../../router/routes";
import { accessCheckRender, getRoutePermissionNew, NetWorkCallMethods } from "../../utils";
import { LeadDetails, LeadPreview, Pdfpreview, Preview, QuotationDetailesNew } from "./steps";
import { useStyles } from "./styles";
import {returnSteps,retutnMappedValues} from "./utils"
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
export const CreateQuotation = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const auth = React.useContext(AuthContext)
  const backdrop = React.useContext(BackdropContext)
  // const { t = () => false } = props; 
  const [permission, setPermission] = React.useState({})
  const [custom1 , setCustom1]=React.useState(false)

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        backdrop.setBackDrop({
          ...backdrop,
          open: true,
          message: "Loading...",
        });
        if (state) {
          getCompanyDetails()
        }
        else {
          navigate(Routes.propertyFinder)
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  //context state
  const { step, previous, next, hideBtn,setSelectedAccount, setDataNew, dataNew , hidenBtn1, t } = React.useContext(CreateQuotationListContext); 

  //get company details
  const getCompanyDetails = () => {
    NetworkCall(
      `${config?.api_url}/queries/opportunity/get_company_details`,
      NetWorkCallMethods.post,
      {
        company_id: state?.companyID?.value || state?.companyID,
      },
      null,
      true,
      false
    )
    .then((res) => {
      let result=retutnMappedValues({
        is_lead:state?.duration?.lead,
        state:state,
        company:res?.data?.company_master[0]
      })
      
      if(state?.duration?.lead){
        setSelectedAccount({
          normal:state?.duration?.lead?.contact_account,
          broker:state?.duration?.lead?.broker,
          agent:{
            ...state?.duration?.lead?.broker_agent,
            label:state?.duration?.lead?.broker_agent?.name,
            id:state?.duration?.lead?.broker_agent?.value
          },
        })
      }
      else{
        setSelectedAccount({
          normal:state?.account ?? state?.data?.lead_details?.contact_account,
          broker:state?.data?.lead_details?.broker,
          agent:{
            ...state?.data?.lead_details?.broker_agent,
            label:state?.data?.lead_details?.broker_agent?.name,
            id:state?.data?.lead_details?.broker_agent?.value
          },
        })
      }
      setDataNew({
        ...dataNew,
        ...result
      })
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });
      if (state?.duration?.lead) {
        next()
      } 

    }).catch((err) => console.log(err))
  }

  const newLeadComponent = {
    // 1: <AddQutation />,
    1: <Box className={classes.detailNew}><LeadDetails/></Box>,
    2: <Box className={classes.contentNew}><LeadPreview /></Box>,
    3:<Box className={classes.detailNew}><QuotationDetailesNew/></Box>,
    4: <Box className={custom1 ? classes.preview1 : classes.preview}><Preview setCustom1={setCustom1} /></Box>,
    5: <Box className={classes.content}><Pdfpreview url={dataNew?.pdf} type_master_id={dataNew?.type_master_id} /></Box>
  }
  const existComponent = {
    1: <Box className={classes.detailNew}><QuotationDetailesNew opperEdit={false}/></Box>,
    2: <Box className={custom1 ? classes.preview1 : classes.preview}><Preview setCustom1={setCustom1} /></Box>,
    3: <Box className={classes.content}><Pdfpreview url={dataNew?.pdf} type_master_id={dataNew?.type_master_id}/></Box>
  }

  const render = () => {
    return <>
    
      <Box>
        {
         !custom1 && 
         <Box>
                <Subheader goBack={() => navigate()} title={dataNew.type === "existing lead" ? t("Create Quotation To Existing Opportunity") : t("Create Quotation To New Opportunity")} />
      <Stepper t={t} step={step} steps={returnSteps(step,dataNew.type)} />

         </Box>
        }
         {/*sub header*/}
      {/*conditional component*/}
      {dataNew.type === "existing lead" ?
        existComponent[step]
        :
        newLeadComponent[step]
      }
      {/*btn container */}

      {
        (dataNew.type === "existing lead" ? step !== 2 : step !== 4 ) && (
          <>
           {
                ((!hideBtn && !custom1)) &&
                <Grid container className={classes.btnroot} spacing={1}>
                  <Grid item xs={6}>
                    <Button className={classes.previousbtn} variant="contained" onClick={previous}
                      disabled={dataNew.type === "existing lead" ? 
                      step === 1 : 
                      (step === 1 || step === 3 || step === 4|| step === 5)}
                      >
                      {t("Previous")}
                    </Button>
                  </Grid>
                  <Grid item xs={6} textAlign={"end"} >
                    <Button className={classes.previousbtn} variant="contained" onClick={() => navigate()}>
                      {t("Cancel")}
                    </Button>
        
                    {permission?.create &&
                        <Button className={classes.submitbtn} variant="contained" onClick={next}
                          disabled={dataNew.type === "existing lead" ?
                            false
                            : step === 2 ? hidenBtn1 : false}
                      >
                        
                        {dataNew.type === "existing lead" ?
                          step === 3 ? "Done" : t("Next")
                          : step === 5 ? "Done" : t("Next")}
        
                      </Button>}
                  </Grid>
                </Grid>
           }
          </>
        )
      
      }
      </Box>
    </>
  }

  return (
    <>
      {accessCheckRender(render, permission)}
    </>
  );
};
