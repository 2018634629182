import { KeyboardArrowRight } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CloseIconWithBG } from "../../assets";
import { InvoiceRaisedIcon } from "../../assets/invoiceRaisedIcon";
import { PaymentCollectedIcon } from "../../assets/paymentCollectedIcon";
import {
  LoadingSection,
  SearchFilter,
  Subheader,
  TableWithPagination,
} from "../../components";
import { NewLoader } from "../../components/newLoader";
import { config } from "../../config";
import { AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import {
  NetWorkCallMethods,
  accessCheckRender,
  getCompanyOption,
  getRoutePermissionNew,
  useWindowDimensions,
} from "../../utils";
import {
  AssetDataType,
  AssetHeading,
  AssetPath,
  ResourceDataType,
  ResourceHeading,
  ResourcePath,
} from "../createMaintenancePlanner/utils";
import { CommonTable } from "../viewMaintenancePlanner/table";
import {
  BOMDataType,
  BOMHeading,
  BOMPath,
  ExecutedJobsDataType,
  ExecutedJobsHeading,
  ExecutedJobsPath,
  InvoiceDataType,
  InvoiceHeading,
  InvoicePath,
  ScheduleWorkOrdersDataType,
  ScheduleWorkOrdersHeading,
  ScheduleWorkOrdersPath,
} from "../viewMaintenancePlanner/utils";
import { useStyles } from "./style";
import { BomDetails, Heading, Path, Type, cardData } from "./utils/tableUtils";

const MaintenancePlanner = ({ t = () => false }) => {
  const classes = useStyles();
  const size = useWindowDimensions();
  const matches = useMediaQuery("(min-width:900px)");
  const isLargeScreen = useMediaQuery("(min-width:1700px)");
  const navigate = useNavigate();
  const { state } = useLocation();
  const auth = React.useContext(AuthContext);
  const backdrop = React.useContext(BackdropContext);
  const [companyList, setCompanyList] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState("");
  const [tabValue, setTabValue] = React.useState("1");
  const [searchText, setSearchText] = React.useState("");
  // const [filterData, setFilterData] = React.useState({})
  // const [filterDrawer, setFilterDrawer] = React.useState(false)
  const [tableData, setTableData] = React.useState([]);
  const [details, setDetails] = React.useState({ isLoading: true });
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [showMore, setShowMore] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [infoType, setInfoType] = React.useState("");
  const [permission, setPermission] = React.useState({});
  const [viewData, setViewData] = React.useState([]);
  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          setCompanyList(company?.list);
          setSelectedCompany(company?.selected);
          getPlannerList(
            company?.selected?.value,
            0,
            10,
            state?.tabValue ?? tabValue,
            ""
          );
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);
  React.useEffect(() => {
    if (state?.tabValue) {
      setTabValue(state?.tabValue);
    }
    // setSelectedCompany({ value: 1 })
    // getPlannerList(1, 0, 10, state?.tabValue??tabValue, "")
    //eslint-disable-next-line
  }, [state?.tabValue]);
  const getPlannerList = (
    company_id,
    offset = 0,
    limit = 10,
    activeTab = "1",
    search = ""
  ) => {
    setLoading(true);
    const payload = {
      company_id: company_id,
      start: offset,
      length: limit,
      search: search,
      is_draft: activeTab === "1" ? false : true,
    };

    NetworkCall(
      `${config.api_url}/preventive_plan`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        const result = response?.data?.data?.map((x) => {
          return {
            ...x,
            is_draft: activeTab === "1" ? false : true,
            execution: `${moment(x?.start_date).format(
              "DD MMM YYYY"
            )} - ${moment(x?.end_date).format("DD MMM YYYY")}`,
          };
        });
        setTableData({ list: result, count: response?.data?.count });
        if (result?.length > 0) {
          getMiniDashboardDetails(result?.[0]);
        } else {
          setDetails({ isLoading: false });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const getMiniDashboardDetails = (data) => {
    setDetails({ ...details, isLoading: true });
    const payload = {
      id: data?.id,
    };

    NetworkCall(
      `${config.api_url}/preventive_plan/get_mini_dashboard`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setDetails({ ...response?.data?.data, id: data?.id, isLoading: false });
      })
      .catch((err) => {
        setDetails({ ...details, isLoading: false });
      });
  };
  const handleCompanyChange = (value) => {
    setSelectedCompany(value);
    getPlannerList(value?.value, 0, 10, tabValue, "");
  };
  const handleTabChange = (newValue) => {
    setTabValue(newValue);
    getPlannerList(selectedCompany?.value, 0, 10, newValue, "");
  };
  const handleSearch = (value) => {
    setSearchText(value);
    getPlannerList(selectedCompany?.value, 0, 10, tabValue, value);
  };
  const handleTableIcon = (type, data) => {
    if (type === "view") {
      getMiniDashboardDetails(data);
      const result = tableData?.list?.map((x) => {
        return {
          ...x,
          selectedRow: x?.id === data?.id ?? false,
        };
      });
      setTableData({ ...tableData, list: result });
    } else if (type === "info") {
      if (data?.is_draft) {
        navigate(Routes?.createMaintenancePlanner, {
          state: {
            company: selectedCompany,
            tableData: data,
            is_draft: true,
            is_edit: true,
            planner_type: "Fixed",
            tabValue: tabValue,
          },
        });
      } else {
        navigate(Routes?.viewMaintenancePlanner, {
          state: {
            company: selectedCompany,
            tableData: data,
            is_draft: false,
            isView: true,
            planner_type: "Fixed",
            tabValue: tabValue,
          },
        });
      }
    }
  };
  const handleTablePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getPlannerList(selectedCompany?.value, offset, 10, tabValue, "");
  };
  const handleTablePageLimit = (value) => {
    setLimit(value);
    setPage(1);
    getPlannerList(selectedCompany?.value, 0, limit, tabValue, "");
  };
  const handleAdd = () => {
    navigate(Routes?.createMaintenancePlanner, {
      state: {
        company: selectedCompany,
        planner_type: "Fixed",
        tabValue: tabValue,
      },
    });
  };
  const showMoreDetails = (val) => {
    setInfoType({ ...val, id: details?.id });
    setShowMore(true);
  };
  const getHeading = (value) => {
    switch (value) {
      case "Projected BOM": {
        return {
          heading: BOMHeading(t),
          path: BOMPath,
          dataType: BOMDataType,
        };
      }
      case "Projected BOM Items": {
        return {
          heading: BOMHeading(t),
          path: BOMPath,
          dataType: BOMDataType,
        };
      }
      case "Executed Jobs": {
        return {
          heading: ExecutedJobsHeading(t),
          path: ExecutedJobsPath,
          dataType: ExecutedJobsDataType,
        };
      }
      case "Invoices": {
        return {
          heading: InvoiceHeading(t),
          path: InvoicePath,
          dataType: InvoiceDataType,
        };
      }
      case "Assets Covered": {
        return {
          heading: AssetHeading(t),
          path: AssetPath,
          dataType: AssetDataType,
        };
      }
      case "Resources": {
        return {
          heading: ResourceHeading(t),
          path: ResourcePath,
          dataType: ResourceDataType,
        };
      }
      default: {
        return {
          heading: ScheduleWorkOrdersHeading(t),
          path: ScheduleWorkOrdersPath,
          dataType: ScheduleWorkOrdersDataType,
        };
      }
    }
  };
  const render = () => {
    return (
      <Box>
        <Subheader
          // padding={"12px 4px"}
          hideBackButton={true}
          title={t("Fixed Preventive Maintenance Planner")}
          select
          options={companyList}
          value={selectedCompany}
          onchange={(e) => {
            handleCompanyChange(e);
          }}
        />
        {loading ? (
          <NewLoader />
        ) : (
          <Box
            className={classes.root}
            height={matches ? size?.height - 165 : size?.height - 150}
            overflow="scroll"
          >
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={isLargeScreen ? 10 : 9}
                lg={isLargeScreen ? 10 : 9.2}
              >
                {/* <TabContext value={tabValue} >
                                    <TabList onChange={handleTabChange}>
                                        <Tab label={t("Published")} value="1" className={classes.tabBox} />
                                        <Tab label={t("Draft")} value="2" className={classes.tabBox} />
                                    </TabList>
                                    <Divider />
                                </TabContext> */}
                <Box sx={{ padding: "16px 16px 0px 16px" }}>
                  <Stack direction="row" spacing={1.5} alignItems="center">
                    <Box onClick={() => handleTabChange("1")}>
                      <Typography
                        className={
                          tabValue === "1"
                            ? classes.selectedPublishText
                            : classes.publishtext
                        }
                        sx={{ marginBottom: "8px" }}
                      >
                        {t("Published")}
                      </Typography>
                      <Box
                        sx={{
                          borderBottom:
                            tabValue === "1"
                              ? "3px solid #5078E1"
                              : "3px solid #FFFFFF",
                        }}
                      />
                    </Box>
                    <Box onClick={() => handleTabChange("2")}>
                      <Typography
                        className={
                          tabValue === "2"
                            ? classes.selectedPublishText
                            : classes.publishtext
                        }
                        sx={{ marginBottom: "8px" }}
                      >
                        {t("Draft")}
                      </Typography>
                      <Box
                        sx={{
                          borderBottom:
                            tabValue === "2"
                              ? "3px solid #5078E1"
                              : "3px solid #FFFFFF",
                        }}
                      />
                    </Box>
                  </Stack>
                </Box>
                <Divider />
                <Box p={2}>
                  <Grid container className={classes.content} spacing={1}>
                    <Grid item xs={4}>
                      <SearchFilter
                        value={searchText}
                        placeholder={t("Search")}
                        handleChange={(value) => handleSearch(value)}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <Box display={"flex"} justifyContent={"end"}>
                        <Stack
                          direction="row"
                          spacing={2}
                          divider={<Divider orientation="vertical" flexItem />}
                        >
                          {/* <IconButton onClick={() => setFilterDrawer(!filterDrawer)}
                                                    className={classes.filterButton}>
                                                    <Badge variant="dot" color="primary"
                                                        invisible={!(filterData.is_active?.length > 0)}>
                                                        <FilterIMG color="#091b29" />
                                                    </Badge>
                                                </IconButton> */}
                          {permission?.create && (
                            <Button
                              variant="contained"
                              className={classes.button}
                              onClick={handleAdd}
                            >
                              {t("Create")}
                            </Button>
                          )}
                        </Stack>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box mt={2}>
                    <TableWithPagination
                      heading={Heading(t)}
                      rows={tableData?.list}
                      path={Path}
                      showpagination={true}
                      showpdfbtn={false}
                      showexcelbtn={false}
                      showSearch={false}
                      handleIcon={handleTableIcon}
                      onClick={() => false}
                      tableType="no-side"
                      dataType={Type}
                      handlePagination={handleTablePagination}
                      handleChangeLimit={handleTablePageLimit}
                      totalRowsCount={tableData?.count}
                      page={page}
                      limit={limit}
                      height={"calc(100vh - 370px)"}
                      view={permission?.read}
                      edit={permission?.update}
                      delete={permission?.delete}
                      noDataText={t("No Fixed Plans created yet")}
                      // enable_double_click={true}
                      enable_single_click={true}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={isLargeScreen ? 2 : 3}
                lg={isLargeScreen ? 2 : 2.8}
                sx={
                  matches
                    ? { borderLeft: "1px solid #E4E8EE" }
                    : { borderTop: "1px solid #E4E8EE" }
                }
              >
                {details?.isLoading ? (
                  <LoadingSection />
                ) : (
                  <Box sx={{ padding: "12px 12px 0px 12px" }}>
                    <Typography className={classes.title}>
                      {t("Overall dashboard")}
                    </Typography>
                    <Box height={size?.height - 201} overflow={"scroll"}>
                      <Box mt={2}>
                        <Grid container spacing={1}>
                          {cardData(t, details)?.map((val) => {
                            return (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                lg={6}
                                onClick={() => showMoreDetails(val)}
                              >
                                <Box className={classes.card}>
                                  {val?.icon}
                                  <Box mt={0.5}>
                                    <Typography className={classes.countText}>
                                      {val?.count}
                                    </Typography>
                                  </Box>
                                  <Box
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                  >
                                    <Typography className={classes.valueName}>
                                      {val?.name}
                                    </Typography>
                                    <KeyboardArrowRight
                                      sx={{
                                        color: "#98A0AC",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Box>
                                </Box>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Box>
                      <Box mt={1} className={classes.card}>
                        {BomDetails(t, details, selectedCompany)?.map(
                          (val, index) => {
                            return (
                              <>
                                <Box
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  alignItems="center"
                                >
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                  >
                                    {val?.icon}
                                    <Box>
                                      <Typography className={classes.countText}>
                                        {val?.count}
                                      </Typography>
                                      <Typography className={classes.valueName}>
                                        {val?.name}
                                      </Typography>
                                    </Box>
                                  </Stack>
                                  <KeyboardArrowRight
                                    sx={{ color: "#98A0AC" }}
                                    onClick={() =>
                                      val?.name === "Projected BOM Items"
                                        ? showMoreDetails(val)
                                        : false
                                    }
                                  />
                                </Box>
                                {BomDetails(t, details, selectedCompany)
                                  ?.length -
                                  1 !==
                                  index && (
                                  <Box mt={1} mb={1}>
                                    <Divider />
                                  </Box>
                                )}
                              </>
                            );
                          }
                        )}
                      </Box>
                      <Box mt={1} className={classes.card} mb={1}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Box
                              sx={{ borderRight: "1px solid #E4E8EE" }}
                              onClick={() =>
                                showMoreDetails({ name: "Invoices" })
                              }
                            >
                              <InvoiceRaisedIcon />
                              <Box mt={0.5}>
                                <Typography className={classes.countText}>
                                  {details?.invoice_count ?? 0}
                                </Typography>
                              </Box>
                              <Box
                                mt={0.5}
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <Typography className={classes.valueName}>
                                  {t("Invoices Raised")}
                                </Typography>
                                <KeyboardArrowRight
                                  sx={{ color: "#98A0AC", cursor: "pointer" }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Box>
                              <PaymentCollectedIcon />
                              <Box mt={0.5}>
                                <Typography className={classes.countText}>
                                  {`${details?.payment_collected ?? 0} ${
                                    selectedCompany?.code
                                  }` ?? `0 ${selectedCompany?.code}`}
                                </Typography>
                              </Box>
                              <Box
                                mt={0.5}
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <Typography
                                  className={classes.valueName}
                                  noWrap
                                >
                                  {t("Payment Collected")}
                                </Typography>
                                <KeyboardArrowRight
                                  sx={{ color: "#98A0AC", cursor: "pointer" }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        )}
        <Drawer
          anchor="right"
          open={showMore}
          PaperProps={{
            sx: { width: "90%" },
          }}
          onClose={() => setShowMore(false)}
        >
          <Box>
            <Stack direction="row" spacing={2} alignItems="center" p={1.5}>
              <Box
                onClick={() => setShowMore(false)}
                style={{ cursor: "pointer" }}
              >
                <CloseIconWithBG />
              </Box>
              <Typography className={classes.drawerHeader}>
                {t(infoType?.name)}
              </Typography>
            </Stack>
            <Divider />
          </Box>
          <Box mt={2}>
            <CommonTable
              t={t}
              heading={getHeading(infoType?.name)?.heading}
              path={getHeading(infoType?.name)?.path}
              dataType={getHeading(infoType?.name)?.dataType}
              viewMore={true}
              infoType={infoType}
              tableData={viewData}
              setTableData={setViewData}
              currency_symbol={selectedCompany?.currency_symbol}
            />
          </Box>
        </Drawer>
      </Box>
    );
  };
  return <>{accessCheckRender(render, permission)}</>;
};
export default withTranslation("maintenancePlanner")(MaintenancePlanner);
