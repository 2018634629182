import React from "react";
import { CreateNewContractEditStyle } from "./style";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { CreateNewEditContext } from "./createNewEditContext";
import { NewFormStep } from "../../components/newFormSteps";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import ContactIcon from "../residentOnboarding/utils/contactIcon";
import { GetContactType } from "./utils/contract";
import ChooseProperty from "./utils/chooseProperty";
import ChooseUnit from "./utils/chooseUnit";
import { format } from "date-fns";
import SelectedProperty from "./utils/selectedProperty";
import NonSelectedProperty from "./utils/nonSelectedProperty";
import SelectedUnit from "./utils/selectedUnit";
import NonSelectedUnit from "./utils/nonSelectedUnit";
import { LocalStorageKeys, enumSelect, enum_types } from "../../utils";

export const CreateNewContractEdit = ({
    title = "",
    onClose = () => false
}) => {
    const classes = CreateNewContractEditStyle()
    const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId)

    const {
        t,
        contractData,
        updateState,
        stateData,
        enumValue,
        updateStateData,
        selectedCompany,
        handleTypeCard,
        manualResponse,
        manualPropertyResponse,
        manualUnitResponse,
        handlePrevious,
        handleNext,
        handleChangeProperty,
        handleChangeUnitProperty,
        handleChangeUnit,
        handleStartDate,
        handlePeriod,
        handleDeleteUnit,
        manualContractTypeResponse,
        handleDeleteProperty,
        handleDeleteUnitProperty,
        handleContractType,
        setEnumValue,
        handleChangeCategory
    } = React.useContext(CreateNewEditContext)

    React.useEffect(() => {
        getEnum()
        // eslint-disable-next-line
    }, [])

    const manualAccountResponse = (array) => {
        if (array.length) {
            let result = array.map((i) => {
                return {
                    ...i,
                    label: i?.name,
                    value: i?.id,
                };
            });
            return result;
        } else if (array?.list?.length) {
            let result = array.list.map((i) => {
                return {
                    ...i,
                    label: i?.name,
                    value: i?.id,
                };
            });
            return result;
        }
        return [];
    };
    
        

    const getEnum = async () => {
        const result = await enumSelect([
            enum_types.l_period,
            enum_types?.contract_commission_rate_type
        ]);
        setEnumValue({
            lease_type: result?.l_period,
            rateConfiguration: result?.contract_commission_rate_type
        });
        if(stateData?.type === "create"){
            updateState("selectedRateConfiguration", result?.contract_commission_rate_type?.[2]?.value)
        }
    }
    return (
        <Box className={classes.drawerBox}>
            <Box className={classes.header}>
                <Stack direction={"row"} alignItems={"center"} p={2}>
                    <Typography className={classes.drawerCloseBtn} onClick={onClose}>X</Typography>
                    <Typography className={classes.drawerHeading} sx={{ marginInlineStart: 2 }}>{title}</Typography>
                </Stack>
                <Divider></Divider>
            </Box>
            <Box className={classes.body}>
                <Box p={2}>
                    {stateData?.step === 1 &&
                        <NewFormStep component={[
                            {
                                type: "stepsCountWithLabel",
                                count: "01",
                                label: t("Contract Details"),
                                sm: 12,
                                md: 12,
                                lg: 12,
                                isActive: true
                            },
                            {
                                type: "customSelectBox",
                                header: t("Contract Type"),
                                placeholder: t("Select Contract Type"),
                                handleChange: (value) => handleContractType(value),
                                value: contractData?.selectedContractType,
                                options: [],
                                isActive: true,
                                menuOptionHeight: 200,
                                sm: 12,
                                md: 12,
                                lg: 12,
                                roundedAvatar: true,
                                withoutSelectedBox: true,
                                isRequired: true,
                                isError: contractData?.error?.selectedContractType?.length > 0 && contractData?.error?.selectedContractType !== "",
                                errorMessage: contractData?.error?.selectedContractType,
                                isReadOnly: stateData?.type === "view",
                                loadOptions: (search, array, handleLoading) =>
                                    loadOptionsApis(
                                        "contract/contract_type",
                                        {module_id:moduleId},
                                        search,
                                        array,
                                        handleLoading,
                                        "data",
                                        {},
                                        manualContractTypeResponse
                                    ),
                                hideLabel: true
                            },
                            {
                                type: "chooseComponent",
                                header: contractData?.selectedContractType?.value === "Employee" ? t("Choose the Contact") : t("Choose the Account"),
                                title: contractData?.selectedContractType?.value === "Employee" ? t("Choose Contact") : t("Choose Account"),
                                placeholder: contractData?.selectedContractType?.value === "Employee" ? t("Search Contact") : t("Search Account"),
                                noSelectedTitle: contractData?.selectedContractType?.value === "Employee" ? t("No contact selected to display") : t("No account selected to display"),
                                icon: <ContactIcon />,
                                options: [],
                                topHeader: t("Choose Vendor Contact"),
                                onchange: (value) => updateState("vendorContact", value),
                                value: contractData?.vendorContact,
                                onDelete: () => updateState("vendorContact", ""),
                                key: JSON.stringify(contractData?.selectedContractType),
                                loadOptions: (search, array, handleLoading) =>
                                    loadOptionsApis(
                                        contractData?.selectedContractType?.value === "Employee" ? "contact/get_employee_contacts" : "account/get_all",
                                        {
                                            company_id: selectedCompany?.value,
                                            type: GetContactType[contractData?.selectedContractType?.value] ?? undefined,
                                            owner:  contractData?.selectedContractType?.value === "Employee" ? false : true
                                        },
                                        search,
                                        array,
                                        handleLoading,
                                        contractData?.selectedContractType?.value === "Employee" ? "contacts" : "data",
                                        {},
                                        contractData?.selectedContractType?.value === "Employee" ? manualResponse : manualAccountResponse
                                    ),
                                isActive: true,
                                isBorder: true,
                                sm: 12,
                                md: 12,
                                lg: 12,
                                error: contractData?.error?.vendorContact?.length > 0,
                                errorMsg: contractData?.error?.vendorContact,
                                isReadOnly: stateData?.type !== "view" ? contractData?.selectedContractType === "" : true
                            },
                            {
                                type: "datePicker",
                                header: t("Start Date"),
                                placeholder: t("Select Start Date"),
                                value: contractData?.startDate,
                                handleChange: (value) => handleStartDate(value),
                                isrequired: true,
                                // isReadonly={val?.isReadonly}
                                isError: contractData?.error?.startDate?.length > 0,
                                errorMessage: contractData?.error?.startDate,
                                isActive: true,
                                sm: 6,
                                md: 6,
                                lg: 6,
                                isReadOnly: stateData?.type === "view"
                            },
                            {
                                sm: 6,
                                md: 6,
                                lg: 6,
                                isActive: true,
                                type: "duration",
                                label: t("Period"),
                                value: (contractData?.period),
                                onChange: (value) => handlePeriod(value),
                                placeholder: t("Select Period"),
                                options: enumValue?.lease_type?.filter((x) => x?.value !== "Daily"),
                                isRequired: true,
                                isError: contractData?.error?.period?.length > 0,
                                errorMessage: contractData?.error?.period,
                                isReadOnly: stateData?.type !== "view" ? contractData?.startDate === "" : true,
                                item_size: {
                                    item: 4.5,
                                    item1: 7.5
                                }
                            }

                        ]} />
                    }
                    {(stateData?.step === 2 && !stateData?.unit) &&
                        <NewFormStep component={[
                            {
                                type: "stepsCountWithLabel",
                                count: "02",
                                label: t("Scope Of Work"),
                                sm: 12,
                                md: 12,
                                lg: 12,
                                isActive: true
                            },
                            {
                                type: "typeCard",
                                data: [
                                    {
                                        title: t("Properties"),
                                        value: "Property",
                                        selectedIcon: <SelectedProperty />,
                                        notSelectedIcon: <NonSelectedProperty />,
                                        disable: Object.keys(contractData?.selectedUnitProperty)?.length === 0 && contractData?.selectedUnit?.length === 0,
                                    },
                                    {
                                        title: t("Units"),
                                        value: "Unit",
                                        selectedIcon: <SelectedUnit />,
                                        notSelectedIcon: <NonSelectedUnit />,
                                        disable: (contractData?.selectedProperty?.length === 0),
                                    }
                                ],
                                selected: contractData?.selectScopeType,
                                handleClick: (value) => handleTypeCard(value),
                                header: t("Select the type"),
                                error: contractData?.error?.selectScopeType?.length > 0,
                                errorMsg: contractData?.error?.selectScopeType,
                                isActive: true,
                                isRequired: true,
                                isReadOnly: stateData?.type === "view"
                            },
                            {
                                type: "chooseComponent",
                                title: t("Choose Property"),
                                placeholder: t("Search Property"),
                                noSelectedTitle: t("No Property selected to display"),
                                icon: <ChooseProperty />,
                                options: [],
                                topHeader: t("Choose Vendor Contact"),
                                onchange: (value) => handleChangeProperty(value),
                                value: contractData?.selectedProperty,
                                onDeleteMuti: (value) => handleDeleteProperty(value),
                                isMulti: true,
                                menuOptionHeight: "200px",
                                loadOptions: (search, array, handleLoading) =>
                                    loadOptionsApis(
                                        "contract/property_scope",
                                        {
                                            company_id: selectedCompany?.value,
                                            contract_type: contractData?.selectedContractType?.id,
                                            current_date: format(contractData?.startDate, "yyyy-MM-dd"),
                                        },
                                        search,
                                        array,
                                        handleLoading,
                                        "data",
                                        {},
                                        manualPropertyResponse
                                    ),
                                error: contractData?.error?.selectedProperty?.length > 0,
                                errorMsg: contractData?.error?.selectedProperty,
                                isActive: contractData?.selectScopeType?.value === "Unit" ? false : true,
                                isBorder: false,
                                sm: 12,
                                md: 12,
                                lg: 12,
                                isReadOnly: stateData?.type === "view"
                            },
                            {
                                type: "addButton",
                                text: `+ ${t("Add Units")}`,
                                isActive: contractData?.selectScopeType?.value === "Unit" ? true : false,
                                click: () => stateData?.type !== "view" && updateStateData("unit", true),
                                error: contractData?.error?.selectedUnit?.length > 0,
                                errorMsg: contractData?.error?.selectedUnit
                            },
                            {
                                type: "showSelectedList",
                                header: t("Selected units"),
                                count: `(${contractData?.selectedUnit?.length})`,
                                value: contractData?.selectedUnit ?? [],
                                onDelete: (value) => handleDeleteUnit(value),
                                isActive: contractData?.selectedUnit?.length > 0 && !stateData?.unit && contractData?.selectScopeType?.value !== "Property",
                                sm: 12,
                                md: 12,
                                lg: 12,
                                isReadOnly: stateData?.type === "view"
                            }

                        ]} />
                    }
                    {
                        (stateData?.step === 2 && stateData?.unit) &&
                        <NewFormStep component={[
                            {
                                type: "chooseComponent",
                                title: t("Choose Property"),
                                placeholder: t("Search Property"),
                                noSelectedTitle: t("No Property selected to display"),
                                icon: <ChooseProperty />,
                                options: [],
                                topHeader: t("Choose Vendor Contact"),
                                onchange: (value) => handleChangeUnitProperty(value),
                                value: contractData?.selectedUnitProperty,
                                onDelete: () => handleDeleteUnitProperty(),
                                loadOptions: (search, array, handleLoading) =>
                                    loadOptionsApis(
                                        "contract/property_scope",
                                        {
                                            company_id: selectedCompany?.value,
                                            contract_type: contractData?.selectedContractType?.id,
                                            current_date: format(contractData?.startDate, "yyyy-MM-dd"),
                                        },
                                        search,
                                        array,
                                        handleLoading,
                                        "data",
                                        {},
                                        manualPropertyResponse
                                    ),
                                error: contractData?.error?.selectedUnitProperty?.length > 0,
                                errorMsg: contractData?.error?.selectedUnitProperty,
                                isActive: true,
                                isBorder: false,
                                sm: 12,
                                md: 12,
                                lg: 12,
                                isReadOnly: stateData?.type === "view"
                            },
                            {
                                sm: 12,
                                md: 12,
                                lg: 12,
                                type: "selectBox",
                                header: t("Unit Category"),
                                placeholder: t("Choose Unit Category"),
                                handleChange: (value) => handleChangeCategory(value),
                                value: contractData?.unit_category,
                                isActive:  (contractData?.selectedUnitProperty?.value?.length > 0) ? true : false,
                                loadOptions: (search, array, handleLoading) =>
                                    loadOptionsApis(
                                        "queries/unit_category/get",
                                        { type:(moduleId === "27" ?  "contract" : "contract"), purpose:contractData?.propertyType?.value },
                                        search,
                                        array,
                                        handleLoading,
                                        "unit_category_master"
                                    ),
                                    isError: contractData?.error?.unit_category?.length > 0,
                                    errorMessage: contractData?.error?.unit_category,
                                isrequired: true,
                                isPaginate:true,
                                key: JSON.stringify(contractData?.selectedUnitProperty),


                            },
                            {
                                type: "chooseComponent",
                                title: t("Choose Units"),
                                placeholder: t("Search units"),
                                noSelectedTitle: t("No Unit selected"),
                                icon: <ChooseUnit />,
                                options: [],
                                isMulti: true,
                                onchange: (value) => handleChangeUnit(value),
                                value: contractData?.selectedUnit,
                                key: JSON.stringify(contractData?.unit_category),
                                onDeleteMuti: (value) => handleDeleteUnit(value),
                                loadOptions: (search, array, handleLoading) =>
                                    loadOptionsApis(
                                        "contract/unit_scope",
                                        {
                                            company_id: selectedCompany?.value,
                                            contract_type: contractData?.selectedContractType?.id,
                                            current_date: format(contractData?.startDate, "yyyy-MM-dd"),
                                            property_id: [contractData?.selectedUnitProperty?.value],
                                            unit_category_id:contractData?.unit_category?.value
                                        },
                                        search,
                                        array,
                                        handleLoading,
                                        "data",
                                        {},
                                        manualUnitResponse
                                    ),
                                error: contractData?.error?.selectedUnit?.length > 0,
                                errorMsg: contractData?.error?.selectedUnit,
                                isActive: contractData?.unit_category?.value ? true : false,
                                isBorder: true,
                                sm: 12,
                                md: 12,
                                lg: 12,
                                isReadOnly: stateData?.type === "view"
                            },
                        ]} />
                    }
                    {/* {
                        stateData?.step === 3 &&
                        <NewFormStep component={[
                            {
                                type: "stepsCountWithLabel",
                                count: "03",
                                label: t("Commission Rate Configuration"),
                                sm: 12,
                                md: 12,
                                lg: 12,
                                isActive: true
                            },
                            {
                                type: "rateConfiguration",
                                sm: 12,
                                md: 12,
                                lg: 12,
                                isActive: true,
                                t: t
                            }
                        ]} />
                    } */}
                    {
                        stateData?.step === 3 &&
                        <NewFormStep component={[
                            {
                                type: "stepsCountWithLabel",
                                count: "03",
                                label: t("Contract Details"),
                                sm: 12,
                                md: 12,
                                lg: 12,
                                isActive: true,
                                isRequired: true
                            },
                            {
                                type: "description",
                                isActive: true,
                                sm: 12,
                                md: 12,
                                lg: 12,
                                value: contractData?.contractDetail,
                                onChange: (value) => updateState("contractDetail", value),
                                isReadOnly: stateData?.type === "view",
                                error: contractData?.error.contractDetail?.length > 0,
                                errorMsg: contractData?.error?.contractDetail
                            },
                            {
                                type: "stepsCountWithLabel",
                                count: "04",
                                label: t("Terms and Condition"),
                                sm: 12,
                                md: 12,
                                lg: 12,
                                isActive: true,
                                isRequired: true
                            },
                            {
                                type: "description",
                                isActive: true,
                                sm: 12,
                                md: 12,
                                lg: 12,
                                value: contractData?.termsAndCondition,
                                onChange: (value) => updateState("termsAndCondition", value),
                                isReadOnly: stateData?.type === "view",
                                error: contractData?.error.termsAndCondition?.length > 0,
                                errorMsg: contractData?.error?.termsAndCondition
                            }
                        ]} />
                    }
                </Box>
            </Box>

            <Box className={classes.bottom} sx={{ width: "100%" }}>
                <Stack direction={"row"} justifyContent={"space-between"}
                    alignItems={"center"} p={1}>

                    <Button
                        disabled={stateData?.step === 1}
                        onClick={handlePrevious}
                        variant='contained'
                        className={classes.previous}
                        disableElevation
                    >
                        {stateData?.unit ? t("Cancel") : t("Previous")}
                    </Button>

                    <Button
                        onClick={handleNext}
                        className={classes.next}
                        disabled={ (stateData?.step === 3 && stateData?.type === "view") ? stateData?.isPublished : stateData?.disableBtn}
                        variant='contained'>
                        {
                            (stateData?.type === "edit" && stateData?.step === 3) ? t("Save") :
                                (stateData?.type === "view" && stateData?.step === 3) ? t("Edit") :
                                    stateData?.step === 3 ? t("Create Contract") :
                                        stateData?.unit ? t("Add") : t("Next")
                        }
                    </Button>
                </Stack>
            </Box>
        </Box>
    )
}