import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import CreatableSelect from "react-select/creatable";
import { FontFamilySwitch } from "../../../utils";
const useStyles = makeStyles((theme) => ({
  createBtn: {
    display: "inline-flex",
    cursor: "pointer",
  },
  selects: {
    "& .css-1rhbuit-multiValue": {
      backgroundColor: window.location.pathname === "/properties" ? "#E4E8EE" : "#DBEDFE",
    },
  },
  label: {
    color: theme.typography.color.tertiary,
    fontFamily: FontFamilySwitch().semiBold,
    fontSize:"0.875rem",
    marginBottom: "5px",
  },
  labelb: {
    color: theme.typography.color.primary,
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().bold,
    marginBottom: "5px",
  }
}));


export const SelectBox = ({
  label = "",
  isRequired = false,
  isPaginate = false,
  isReadOnly = false,
  createSelect = false,
  value = "",
  placeholder = "",
  options = [],
  loading = false,
  loadOptions = () => null,
  onChange = () => null,
  isError = false,
  errorMessage = "",
  isMulti = false,
  reduceOptions = undefined,
  debounceTimeout = 0,
  key,
  bold = false,
  isSelectAll,
  color,
  padding,
  isSearchable = true,
  width = "auto",
  style = false,
  isClearable = false,
  menuOptionHeight = null,
  menuPlacement = "auto",
}) => {
  const classes = useStyles();
  const customStyles = {

    control: (base) => ({
      ...base,
      borderRadius: 4,
      backgroundColor: color ?? "none",
      zIndex: 1,
      fontSize:"1rem",
      padding: padding ? padding : "auto",
      minWidth: width,
      border: style ? "none" : "1.5px solid #E2E2E2",
      boxShadow: "none",
      outline: "none",
      "&:hover": {
        border: "1.5px solid #E2E2E2",
      },
    }),
    placeholder: defaultStyles => {
      return {
        ...defaultStyles,
        fontFamily: FontFamilySwitch().regular,
        fontSize:"0.875rem",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: "100%",
        overflow: "hidden"

      };
    },
    menu: defaultStyles => {
      return {
        ...defaultStyles,
        zIndex: 3,
        borderRadius: "4px",
      };
    },
  };

  return (

    <div className={classes.root}>
      {label && <div style={{ display: "flex" }}>
        {
          <Typography
            variant="body1"
            color="textsecondary"
            className={bold ? classes.labelb : classes.label}
            align="left"
            noWrap
          >
            {label}
          </Typography>
        }
        {isRequired && (
          <Typography color="error" variant="caption">
            &nbsp;*
          </Typography>
        )}
      </div>}

      {isPaginate ? (
        <AsyncPaginate
          key={key}
          isClearable={isClearable}
          isSearchable={isSearchable}
          components={{
            IndicatorSeparator: () => null,
          }}
          value={value}
          placeholder={placeholder}
          loadOptions={loadOptions}
          onChange={onChange}
          options={options}
          isLoading={loading}
          defaultOptions={options}
          styles={customStyles}
          isDisabled={isReadOnly}
          isMulti={isMulti}
          debounceTimeout={debounceTimeout}
          reduceOptions={reduceOptions}
          menuPlacement={menuPlacement}
          minMenuHeight={"150px"}
          maxMenuHeight={menuOptionHeight ? menuOptionHeight : '150px'}
        />
      ) : createSelect ? (
        <CreatableSelect
          isClearable={false}
          options={options}
          isLoading={loading}
          placeholder={placeholder}
          components={{
            IndicatorSeparator: () => null,
          }}
          value={value}
          onChange={onChange}
          styles={customStyles}
          isDisabled={isReadOnly}
          menuPlacement={menuPlacement}
          maxMenuHeight={menuOptionHeight ? menuOptionHeight : '150px'}
        />
      ) : (
        <Select
          isClearable={false}
          isSearchable
          components={{
            IndicatorSeparator: () => null,
          }}
          value={value}
          autoComplete="off"
          placeholder={placeholder}
          options={options}
          isLoading={loading}
          onChange={onChange}
          styles={customStyles}
          className={classes.selects}
          isMulti={isMulti}
          isDisabled={isReadOnly}
          isSelectAll
          menuPlacement={menuPlacement}
          maxMenuHeight={menuOptionHeight ? menuOptionHeight : '150px'}
        />
      )}
      {isError && (
        <Typography variant={"caption"} color={"error"}>
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};
