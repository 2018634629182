import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { AlertDialog, ContactList, TemplateGenerator } from "../../../components";
import { NewLoader } from "../../../components/newLoader";
import { config } from "../../../config";
import { AlertContext, BackdropContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, NetWorkCallMethods, timeZoneConverter } from "../../../utils";
import { CancelBooking } from "../../quotationDetails/components";
import { ReservationCard } from "../component";
import { reservationStyle } from "./style";

export const DetailsSection = ({
  t,
  details = {},
  id = "",
  permission = {},
}) => {
  const classes = reservationStyle();
  const alert = React.useContext(AlertContext)
  const backdrop = React.useContext(BackdropContext)
  // const [open, setOpen] = React.useState(false);
  const [openpdf, setOpenpdf] = React.useState(false);
  const [template, setTemplate] = React.useState({
    bool: false,
    type: ""
  });
  const [pdf, setPdf] = React.useState({
    bool: false,
    data: ""
  })
  const [cancelBooking, setCancel] = React.useState({
    bool: false,
    data: "",
  });

  //download pdf
  // const downloadPdf = () => {
  //   saveAs(details?.url, `${details?.quotation_no}.pdf`);
  // };
  //render btn
  // const renderButton = () => {
  //   return (
  //     <Button
  //       onClick={downloadPdf}
  //       className={classes.downBtn}
  //       variant="outlined"
  //       startIcon={<Download />}
  //     >
  //       {t("Download")}
  //     </Button>
  //   );
  // };
  const detailsCard = [
    {
      title: t("GENERATED_ON"),
      sub: timeZoneConverter(details?.quott?.created_at),
    },
    {
      title: t("LEASE_START_DATE"),
      sub: timeZoneConverter(details?.quott?.lease_start_date),
    },
    {
      title: t("LEASE_END_DATE"),
      sub: timeZoneConverter(details?.quott?.lease_end_date),
    },
    {
      title: t("RENT_START_DATE"),
      sub: timeZoneConverter(details?.quott?.billing_start_date),
    },
    {
      title: t("GRACE_PERIOD"),
      sub: "Days(In the Beginning)",
    },
  ];

  //on cancel Booking
  const onCancelBooking = (datas) => {
    setCancel({
      bool: !cancelBooking?.bool,
      data: datas,
    });
  };
  //send quotation
  const sendQuotation = (value) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: t("...Sending"),
    });
    setTemplate({
      bool: false,
      type: "view"
    })
    const payload = value
    NetworkCall(
      `${config.api_url}/quotation/send_invite`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        // getQuotDetail()
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      }).catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        console.log(err)
      })
  }
  const generate = async (value) => {
    if (template?.type === "send") return sendQuotation({
      quotation_id: id,
      type_master_id: value?.value
    })
    setOpenpdf(true)
    await backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: t("...Loading"),
    });
    const payload = {
      id: id,
      type_master_id: value?.value

    };
    await NetworkCall(
      `${config.api_url}/quotation/get_quotation_pdf`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setPdf({
          bool: true,
          data: res?.data
        })
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("some thing went wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  }

  return (
    <Box className={classes.container}>
      {/*  */}
      <Box className={classes.main}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography className={classes.title}>
            {" "}
            {t("Reservation_Details")}
          </Typography>
          {/* <Typography className={classes.viewHistory}>
          {t("View_History")}
        </Typography> */}
        </Box>
        <Box height={"14px"} />
        {/*ReservationCard  */}
        <ReservationCard
          onCancel={onCancelBooking}
          details={{
            reservation_no: details?.reservation?.reservation_no,
            symbol: details?.reservation?.currency?.symbol,
            reserved_amount: details?.reservation?.reserved_amount,
            date: `${timeZoneConverter(details?.reservation?.start_date)} - ${timeZoneConverter(details?.reservation?.end_date)}`,
            is_active: details?.reservation?.is_active,
            paid_on_date:
              details?.reservation?.invoice?.paid_on?.length > 0
                ? timeZoneConverter(details?.reservation?.invoice?.paid_on)
                : "",
          }}
          permission={permission}
          t={t}
        />
        <Box height={"14px"} />
        {/* Quotation Details */}
        <Box display="flex" alignItems="center">
          <Typography className={classes.quationName}>
            {t("Quotation_Details")} ({details?.quott?.quotation_no})
          </Typography>
          <Box className={classes.badge}>
            {details?.quott?.is_active ? "Active" : "In Active"}
          </Box>
        </Box>
        <Box height={"14px"} />
        <Box className={classes.quatationCard}>
          <Grid container spacing={3}>
            {detailsCard?.map((x) => {
              return (
                <Grid item xs={4}>
                  <Typography className={classes.subject}>{x?.title}</Typography>
                  <Typography className={classes.contant}>&#x202a;{x?.sub}&#x202c;</Typography>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Box height={"14px"} />
        <Typography className={classes.quationName}>
          {t("Lead_Details")}
        </Typography>
        <Box height={"14px"} />
        <ContactList
          data={{
            name: `${details?.quott?.first_name ?? ""} ${details?.quott?.last_name ?? ""
              }`,
            img:
              details?.quott?.image_url?.length > 0
                ? details?.quott?.image_url
                : null,
            arg: false,
            phone: `${details?.quott?.mobile_no_country_code ?? ""} ${details?.quott?.mobile_no ?? ""
              }`,
            mail: details?.quott?.email_id,
          }}
          leadtype={true}
        />
      </Box>
      {permission?.read && (
        <Button
          startIcon={<FileDownloadOutlinedIcon />}
          onClick={() => setTemplate({
            bool: true,
            type: 'view'
          })}
          variant="outlined"
          className={classes.reserve}
          fullWidth
        >
          {t("View_Quotation")}
        </Button>
      )}

      {/* quotation pdf */}
      {/* <AlertDialog
        header={details?.quott?.quotation_no}
        onClose={() => setOpenpdf(false)}
        open={openpdf}
        render={renderButton()}
        component={
          <Box p={1} bgcolor={"#F5F7FA"}>
            <center>
              {details?.quott?.url ? (
                <DocumentViewer url={details?.quott?.url} />
              ) : (
                "No Data Found"
              )}
            </center>
          </Box>
        }
      /> */}
      <AlertDialog
        header={details?.quott?.quotation_no}
        onClose={() => {
          setOpenpdf(false)
          setPdf({
            bool: false,
            data: ""
          })
        }}
        open={openpdf}
        // render={renderButton()}
        component={
          <Box p={1} bgcolor={"#F5F7FA"}>

            <Box style={{ height: `calc(100vh - 100px)` }}>
              {
                pdf?.bool ?
                  <iframe
                    src={`data:application/pdf;base64,${pdf?.data
                      }`}
                    alt="pdf"
                    width="100%"
                    style={{ height: `calc(100vh - 152px)` }}
                    title="quotation"

                  />

                  :
                  <NewLoader />
              }
            </Box>
          </Box>
        }
      />
      <TemplateGenerator name={t("Quotation Template")} btnText={template?.type === "view" ? t("Generate") : t("Send")} open={template?.bool} onClose={() => setTemplate({
        bool: false,
        type: "view"
      })} type={1} generate={generate} />
      {/*cancel booking*/}
      <AlertDialog
        component={
          <CancelBooking
            t={t}

            company_id={details?.quott?.company_id}
            quotation_id={id}
            onClose={onCancelBooking}
            // reload={getQuotDetail}
            type={
              details?.reservation?.reservation_status === "Booked"
                ? "Booking"
                : "Reserved"
            }
            data={cancelBooking?.data}
          />
        }
        header={t("Cancel Booking")}
        open={cancelBooking?.bool}
        onClose={onCancelBooking}
        isNormal={true}
      />
    </Box>
  );
};
