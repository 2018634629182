import { Box, Button, Divider, Grid, Stack } from "@mui/material";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { LoadingSection, SearchFilter, TableWithPagination } from "../../../components";
import { Routes } from "../../../router/routes";
import { FloorHeadingViewNew, FloorPathView } from "../../../utils/block";
import makeStyles from '@mui/styles/makeStyles';
import { getCustomFloorName, NetWorkCallMethods } from '../../../utils'
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
const useStyles = makeStyles((theme) => ({
    table: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        // height: `calc(100vh - 147px)`,
        overflow: "hidden",


    },
    img: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "12px"
    },
    btn: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "none",
        fontSize:"0.875rem"
    },
    loading: {
        marginTop: "20%"
    }
}))
export const Floors = (props) => {
    const { t } = (props);
    const classes = useStyles();
    const navigate = useNavigate()
    const search = useLocation().search;
    const [floorlist, setFloorList] = React.useState({
        data: [],
        count: 0,
        property: {},
    })
    const [loading, setLoading] = React.useState(true)
    const [searchText, setSearchText] = React.useState("");
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const blockID = new URLSearchParams(search).get("blockID");
    const propertyID = new URLSearchParams(search).get("propertyID");
    // eslint-disable-next-line
    const getfloorlist = async (id, active, searchText, offSet, limits) => {
        const payload = {
            id, searchText, active,
            offset: offSet, limit: limits,
        };

        NetworkCall(
            `${config?.api_url}/queries/floor/${propertyID ? `by_property` : `by_block`}`,
            NetWorkCallMethods?.post, payload, null, true, false
        ).then((res) => {
            const list = res?.data?.data?.floor?.map((val) => {

                let _d;
                try {
                    _d = {
                        floorNo: val?.floor_no,
                        propertyName: val?.property?.name,
                        floorName: val?.name,
                        description: val?.description,
                        companyname: val?.property?.company?.name,
                        location: val?.property?.city_area_master?.area_name,
                        blockname: val?.block?.name,
                        unit: val?.unit?.length,
                        id: val?.id,
                        image: val?.logo,
                    };
                } catch (err) {

                }
                return _d;
            })
            if (propertyID) {
                setFloorList({
                    count: res?.data?.data?.count[0]?.count,
                    data: list,
                    property: res?.data.data?.property[0]
                })
            } else {
                setFloorList({
                    count: res?.data?.data?.count[0]?.count,
                    data: list,
                    property: res?.data?.data?.block[0]?.property,
                })
            }
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
        })
    }
    React.useEffect(() => {
        getfloorlist(propertyID ? propertyID : blockID, [true], "", 0, limit)
        // eslint-disable-next-line
    }, [])

    const handleSearch = (e) => {
        setSearchText(e)
        getfloorlist(propertyID ? propertyID : blockID, [true], e, 0, limit)
    }
    const handleOnClickAddFloorButton = () => {
        navigate(Routes.createFloorNew, {
            state: {
                main: {
                    blockID: blockID,
                    propertyID: floorlist?.property?.id ? floorlist?.property?.id : propertyID,
                }
            }
        })
    }
    const handleIcon = (type, data) => {
        if (type === "view") {
            navigate(Routes.floorDetails + "?companyID=" + data?.companyID + "&floorID=" + data?.id + "&name=" + data?.floorName)
        }
    }
    const onUnitClick = (value, type) => {
        navigate(Routes.unit + "?floorID=" + value?.id)
    }
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getfloorlist(propertyID ? propertyID : blockID, [true], searchText, offset, limit)
    }
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getfloorlist(propertyID ? propertyID : blockID, [true], searchText, 0, value)
    }

    return (
        <>
            {
                loading ? <LoadingSection bottom={"45vh"}
                    message={"Loading " + getCustomFloorName(floorlist?.property?.property_hierarchyByID) + " Listing..."} />
                    :
                    <div className={classes.root}>
                        <Box p={2} m={2} className={classes.table}>
                            <Grid container >
                                <Grid item xs={4}>
                                    <SearchFilter value={searchText}
                                        placeholder={t("Search ") + getCustomFloorName(floorlist?.property?.property_hierarchyByID)}
                                        handleChange={(value) => handleSearch(value)} />
                                </Grid>
                                <Grid item xs={8}>
                                    <Box
                                        display={"flex"}
                                        sx={{ float: "right" }}
                                    >
                                        <Stack
                                            direction="row"
                                            divider={<Divider orientation="vertical" flexItem />}
                                            spacing={2}
                                        >

                                            {floorlist?.property?.property_hierarchyByID?.is_floor &&
                                                <Button variant="contained" className={classes.btn} onClick={handleOnClickAddFloorButton}>
                                                    {t("Add ") + " " + getCustomFloorName(floorlist?.property?.property_hierarchyByID)}
                                                </Button >}
                                        </Stack >
                                    </Box >
                                </Grid >

                            </Grid >
                            <TableWithPagination
                                heading={FloorHeadingViewNew(floorlist?.property?.property_hierarchyByID, t)}
                                rows={floorlist?.data}
                                path={FloorPathView}
                                showpagination={true}
                                count="2"
                                showpdfbtn={false}
                                showexcelbtn={false}
                                showSearch={false}
                                handleIcon={handleIcon}
                                tableType="no-side"
                                onClick={() => console.log("")}
                                onUnitClick={onUnitClick}
                                dataType={[
                                    { type: ["avatarmanagement"], name: "image" },
                                    { type: ["text"], name: "floorName" },
                                    { type: ["text"], name: "blockname" },
                                    { type: ["quill"], name: "description" },
                                    { type: ["text"], name: "floorNo" },
                                    { type: ["unit"], name: "unit" },

                                ]}
                                handlePagination={handlePagination}
                                handleChangeLimit={handleChangeLimit}
                                totalRowsCount={floorlist?.count}
                                page={page}
                                limit={limit}
                                height={'calc(100vh - 300px)'}
                                view={true}
                                edit={true}
                                delete={true} />
                        </Box >
                    </div >
            }
        </>
    );
};
