import React from "react";
import { IconButton, Grid, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import { FontFamilySwitch} from "../../utils";
const useStyles = makeStyles((theme) => ({
    gridContainer: {
        marginBottom: theme.spacing(2),
    },
    root: {
        width: "100%",
        height: "100%",
        "& input": {
            position: "absolute",
            width: "100%",
            height: "100%",
            opacity: 0,
            left: 0,
            cursor: "pointer",
            zIndex: 10000,
        },
        "& .MuiButton-root": {
            top: "80px",
        },
        "& .MuiTypography-colorPrimary": {
            marginTop: theme.spacing(11),
        }
    },
    drap: {
        position: "relative",
        height: "205px",
        borderRadius: 8,
        textAlign: "center",
        background: "#F1F7FF",
        border: "1px dashed #D6D6D6"
    },
    drap2: {
        position: "relative",
        height: "59px",
        borderRadius: 6,
        textAlign: "center",
        background: "#FFFFFF",
        border: "1px dashed #D6D6D6"
    },
    text: {
        color: " #3461FF",
        textDecoration: "underline",
        fontSize:"0.75rem",
        fontWeight: 600,
    },
    text2: {

        fontSize:"0.875rem",
        fontWeight: 500,
        padding: 10,
    }
}));

export const Upload = (props) => {
    const classes = useStyles(props);
    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.gridContainer}
        >
            <Grid item xs={12}>


                <div className={classes.drap}>
                    <div className={classes.root}>
                        <div>
                            {props?.single && <input
                                type="file"
                                onChange={(e) => props.onChange(e)}
                                disabled={props.disabled}
                            />}

                            {!props?.single && <input
                                accept=".xlsx"
                                type="file"
                                single
                                onChange={(e) => props.onChange(e)}
                                disabled={props.disabled}
                            />}
                            <IconButton style={{ marginTop: "-1px", textTransform: "capitalize", color: "#4E5A6B" }}
                            >
                                <DriveFolderUploadOutlinedIcon sx={{ fontSize: "88px" }} />
                            </IconButton>
                            <Typography color={"#4E5A6B"} fontSize={"16px"} fontFamily={FontFamilySwitch().bold} >Upload Your Excel File Here </Typography>
                            <br />
                            <Typography color={"#98A0AC"} fontSize={"12px"} ffontFamily={FontFamilySwitch().semiBold} >Drag & Drop /&nbsp;<span className={classes.text}>Browse</span> </Typography>
                        </div>
                    </div>
                </div>





            </Grid >
        </Grid >
    );
};
