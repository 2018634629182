import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Typography } from '@mui/material';
import Divider from "@mui/material/Divider";
import Popover from '@mui/material/Popover';
import Stack from "@mui/material/Stack";
import styled from "@mui/material/styles/styled";
import React, { useContext, useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AlertContext } from '../../contexts';
import { CalenderIcon } from '../../screens/propertyFinder4/components/svg/calender';
import { CustomTypography } from '../../screens/propertyFinder4/components/typography';
import { AlertProps, FontFamilySwitch } from '../../utils';
import { ToggleButtonComponent } from '../index';
import { withTranslation } from 'react-i18next';
const Wrapper = styled(Stack)({
    border: '1px solid #E4E8EE',
    borderRadius: '4px',
    backgroundColor: '#fff',
    position: 'relative',
    // overflow: 'hidden',
    cursor: 'pointer',
    padding: '9px',
})

const Container = styled('div')({
    padding: 16,
    boxShadow: '0px 8px 24px #0717411F',
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    '& .react-datepicker': {
        border: 0,
        '& .react-datepicker__header': {
            backgroundColor: '#F2F4F7',
            borderBottom: '2px solid #2579ba'
        },
    }
})

const Div = styled('div')({
    display: 'flex'
})

const Input = styled('input')({
    maxWidth: 50,
    minHeight: 38,
    paddingInline: 11,
    color: '#091B29',
    fontFamily: FontFamilySwitch().semiBold,
    fontSize:"0.75rem",
    border: '1px solid #E4E8EE',
    borderRadius: 4,
    '&:focus-visible': {
        outline: '1px solid #E4E8EE'
    }
})

const CustomDivider = styled(Divider)({
    marginBlock: '16px'
})

const DURATION_PERIOD = [
    //{ label: "Day", value: "Day", },
    { label: "Month", value: "Month", },
    { label: "Year", value: "Year", }
]

const StartEndDate = (props) => {

    const {
        label,
        placeholder = "Choose Date",
        value = null,
        onChange = null,
        data = "",
        title = "",
        showEndDate = false,
        disabled = false,
        isrequired = false,
        errMsg = "",
        isError = false,
        option = [],
        isReadonly = false,
        t
    } = props;

    const alert = useContext(AlertContext);
    const [anchorEl, setAnchorE1] = useState(null)
    const [state, setState] = useState({});
    useEffect(() => {
        if (anchorEl) {
            setState(value)
        }
    }, [value, anchorEl])

    const onChangeValue = (key, val) => {
        let updateValue = {
            ...state,
            [key]: val
        }
        setState(updateValue)
    }


    const onClear = () => {
        setState({})
    }

    const onApply = () => {
        if (showEndDate) {
            if (state?.date && state?.input && state?.period) {
                onChange && onChange({ ...state, input: state?.input === "0" ? 1 : state?.input });
                setAnchorE1(null)
            }
            else {
                showAlert(t("Please fill all the fields"))
            }
        }
        else {
            if (state?.date) {
                onChange && onChange(state);
                setAnchorE1(null)
            }
            else {
                showAlert(t("Please choose date"))
            }
        }
    }


    const showAlert = (msg = "") => {
        alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center
        })
    }

    const onClose = () => {
        onChange && onChange(value);
        setAnchorE1(null)
    }


    return (
        <div>
            <CustomTypography
                fontFamily={FontFamilySwitch().semiBold}
                fontSize={14}
                color={"#98A0AC"}
                noWrap
                marginBottom="4px"
            >
                {label}
                {isrequired && (
                    <Typography variant="caption" style={{ color: "red", marginLeft: 4 }}>
                        *
                    </Typography>
                )}
            </CustomTypography>

            <Wrapper onClick={e => {
                if (!disabled) {
                    setAnchorE1(e.target)
                }
            }}>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                >
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        gap={1}
                    >
                        <CalenderIcon />

                    </Stack>

                    <CustomTypography
                        flexGrow={1}
                        fontFamily={data ? FontFamilySwitch().semiBold : FontFamilySwitch().regular}
                        fontSize={14}
                        color={data ? '#091B29' : '#98A0AC'}
                        noWrap={!data}
                        marginTop={'3px'}
                        marginLeft={"10px"}
                    >
                        {
                            data ? data : placeholder

                        }


                    </CustomTypography>
                    <Div>
                        <ArrowDropDownIcon
                            style={{
                                fontSize: "26px",
                                color: "#98A0AC",
                                transform: anchorEl ? 'rotate(180deg)' : 'rotate(0)',
                                transition: '0.25s',
                            }} />
                    </Div>
                </Stack>

            </Wrapper>
            {isError && (
                <Typography variant="caption" style={{ color: "red", marginLeft: 4 }}>
                    {errMsg}
                </Typography>
            )}

            {
                anchorEl &&
                <Popover
                    open={true}
                    anchorEl={anchorEl}
                    onClose={onClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Container>
                        {
                            title &&
                            <CustomTypography
                                color={"#4E5A6B"}
                                fontSize={12}
                                fontFamily={FontFamilySwitch().semiBold}
                                marginBottom={8}
                            >
                                {title}
                            </CustomTypography>
                        }

                        <>
                            <Stack
                                direction={"row"}
                                alignItems={"center"}
                                gap={'6px'}
                            >
                                <Input
                                    min="1"
                                    InputProps={{ inputProps: { min: 1, max: 10 } }}
                                    type="number"
                                    value={state?.input ?? ''}
                                    disabled={isReadonly}
                                    onChange={e => onChangeValue('input', e.target.value)}
                                />
                                <Div style={{ flex: 1 }}>
                                    <ToggleButtonComponent
                                        options={option?.length > 0 ? option : DURATION_PERIOD}
                                        value={state?.period}
                                        isReadOnly={isReadonly}
                                        onChange={(value) => onChangeValue('period', value)}
                                        // buttonHeight
                                        buttonGroupStyle={{
                                            gap: '6px'
                                        }}
                                        buttonStyle={{
                                            // height: '32px',
                                            borderColor: '#E4E8EE',
                                            borderRadius: '4px !important',
                                            paddingBlock: 0,
                                            flex: 1,
                                            backgroundColor: '#F2F4F7'
                                        }}
                                    />
                                </Div>
                            </Stack>
                            <CustomDivider />
                        </>

                        <DatePicker
                            minDate={new Date()}
                            selected={state?.date ?? ''}
                            onChange={(value) => onChangeValue('date', value)}
                            placeholderText={placeholder}
                            inline
                            peekNextMonth
                            dropdownMode="select"
                            showMonthDropdown
                            showYearDropdown
                        />
                        <CustomDivider sx={{ marginTop: 0, marginBottom: '12px' }} />
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                        >
                            <CustomTypography
                                color={"#071741"}
                                fontSize={12}
                                fontFamily={FontFamilySwitch().bold}
                                sx={{ cursor: 'pointer', userSelect: 'none' }}
                                onClick={onClear}
                            >
                                {t("Clear")}
                            </CustomTypography>
                            <Button variant='contained' onClick={onApply}>
                                {t("Apply")}
                            </Button>

                        </Stack>
                    </Container>
                </Popover>
            }
        </div>
    )
}
export default withTranslation("startEndDate")(StartEndDate)

