import { Box, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import { allowed_file_size, assestType, singleFileUpload, FontFamilySwitch } from '../../../utils'
import { AlertContext, BackdropContext } from '../../../contexts';
import { componentsStyle } from './style';
import UploadIcon from '../assets/uploadIcon';
import DeleteIcon from '../assets/deleteIcon';


export const MarketingUpload = (props) => {
    const { t } = props
    const alert = React.useContext(AlertContext);
    const backdrop = React.useContext(BackdropContext);
    const classes = componentsStyle()
    const inputFile = React.useRef(null);
    const [data, setData] = React.useState({ img: props?.value ?? "", imgname: "" })


    const onButtonClick = () => {
        inputFile.current.click();
    };

    const updateimg = async (image) => {

        try {
            backdrop.setBackDrop({
                ...backdrop,
                open: true,
                message: "uploading....",
            });
            const uploaded_file = await singleFileUpload(image, { type: assestType?.General_Images }, alert, allowed_file_size)
            if (uploaded_file?.[0]?.url) {
                setData({
                    img: uploaded_file?.[0]?.url,
                    imgname: uploaded_file?.[0]?.file_name
                })
                props?.updateState(props?.state, uploaded_file?.[0]?.url, props?.id)
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });

            } else {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
            }
        }
        catch (err) {
            backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "updating....",
            });
        }

    }
    const removeimg = () => {
        setData({ ...data, img: "" })
        props?.updateState(props?.state, "", props?.id)
    }
    return (
        <>
            <div className={classes.input}>
                {data?.img ?
                    <div style={{ backgroundColor: "#FFFFFF", borderRadius: "8px", display: "flex", padding: "12px" }}>
                        <img src={data?.img} alt=""
                            style={{
                                objectFit: "contain", height: "46px", width: "46px",
                                borderRadius: "8px", boxShadow: "0px 3px 6px #00000014"
                            }} />
                        <Box width={"16px"} />
                        <Box flexGrow={1} alignSelf={"center"}>
                            <Typography style={{ color: "#091B29", fontSize:"0.875rem", fontFamily: FontFamilySwitch().semiBold, }}>
                                {data?.imgname}
                            </Typography>
                        </Box>
                        <IconButton onClick={removeimg}>
                            <DeleteIcon />
                        </IconButton>
                    </div> :
                    <div onClick={onButtonClick} className={classes.drap2}>
                        <input type='file' accept="image/*" id='file'
                            ref={inputFile} style={{ display: 'none' }}
                            value={data?.img}
                            onChange={(e) => { updateimg(e?.target?.files?.[0]) }}
                            onClick={(event) => { event.target.value = null }} />
                        <Grid container direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}>
                            <IconButton>
                                <UploadIcon />
                            </IconButton>
                            <Typography className={classes.text3}>
                                {t("Upload Images")}
                            </Typography>
                        </Grid>
                    </div>}
                {data?.error?.img?.length > 0 && (
                    <Typography variant={"caption"} color={"error"}>
                        {data?.error?.img}
                    </Typography>
                )}
            </div>
        </>
    )
}