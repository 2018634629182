import { Grid, Typography } from '@mui/material';
import styled from '@mui/material/styles/styled';
import React, { useContext, useState } from 'react';
import { SelectBox, TextBox } from '../../../components';
import { AlertContext } from '../../../contexts';
import { AlertProps, enum_types } from '../../../utils';
import { CustomPaper } from '../../companyCreation/components';
import { networkCallback } from '../../companyCreation/utils';
import { FontFamilySwitch } from '../../../utils';
const CustomTypography = styled(Typography)(({ theme }) => ({
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.secondary,
    marginBottom: theme.spacing(1)
}));

// const textBoxStyle = {
//     padding: "8px 14px",
//     borderRadius: 8,
//     height: 44
// }

export const RegistrationDetails = (props) => {

    const {
        data = {},
        updateState = null,
        error = {},
        t
    } = props;

    const alert = useContext(AlertContext);

    const [loading, setLoading] = useState(false);

    const loadOptionData = async (search, array, type) => {
        setLoading(type);
        let result;
        let enumTypes = [enum_types.company_registration]
        if (enumTypes?.includes(type)) {
            result = await networkCallback({ enumName: [type] }, "enum", setLoading);
            if (!result) {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something went wrong"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                return {
                    options: [],
                    hasMore: false
                }
            }
            return {
                options: result?.[type] ?? [],
                hasMore: false
            }
        }
        else {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const {
        companyEstateRegistration = null,
    } = data;

    return (
        <div>
            <CustomPaper>
                <CustomTypography>{t("Company & Real Estate Registration")}</CustomTypography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4} md={3}>
                        <SelectBox
                            // isRequired={true}
                            label={t("Company Registration Type")}
                            placeholder={t("Select Company Registration Type")}
                            // options={[]}
                            loadOptions={(search, array) => loadOptionData(search, array, enum_types.company_registration)}
                            isPaginate
                            debounceTimeout={800}
                            loading={loading === enum_types.company_registration}
                            isSearchable={false}
                            value={companyEstateRegistration?.companyRegistrationType ?? null}
                            onChange={(value) => updateState('companyEstateRegistration', 'companyRegistrationType', value)}
                            isError={error?.companyEstateRegistration?.companyRegistrationType?.error ?? false}
                            errorMessage={error?.companyEstateRegistration?.companyRegistrationType?.errorMsg ?? ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <TextBox
                            // {...textBoxStyle}
                            // isrequired
                            label={t("Company Registration Number")}
                            placeholder={t("Enter Company Registration Number")}
                            value={companyEstateRegistration?.companyRegistrationNumber ?? ''}
                            onChange={(e) => updateState('companyEstateRegistration', 'companyRegistrationNumber', e.target.value)}
                            isError={error?.companyEstateRegistration?.companyRegistrationNumber?.error ?? false}
                            errorMessage={error?.companyEstateRegistration?.companyRegistrationNumber?.errorMsg ?? ''}
                        />
                    </Grid>
                </Grid>
            </CustomPaper>
        </div>
    )
}