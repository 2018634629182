import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  LoadingSection,
  SearchFilter,
  Skeletons,
  TextBox,
} from "../../../components";
import { config } from "../../../config";
import { AlertContext, BackdropContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import {
  AlertProps,
  LocalStorageKeys,
  NetWorkCallMethods,
  stringAvatar,
} from "../../../utils";
import { getNextstatus } from "../../../utils/agreementUtils";
import { useStyles, useStyleTimeLine } from "../styles";
import { ContactList } from "./contactList";
import { Complete, DeclineForm } from "../../../assets";

const TimelineForm = ({
  onClose = () => false,
  company = "",
  data = {},
  type = "",
  details = {},
  reload = () => false,
  t,
  isType = false,
  isUnderOnboard = false,
  is_show = false,
  agreement_id,
}) => {
  const classes = useStyles();
  const classes1 = useStyleTimeLine();
  const alert = React.useContext(AlertContext);
  const [search, setSearch] = React.useState("");
  const [open, setOpen] = React.useState(null);
  const [datas, setData] = React.useState(null);
  const [list, setList] = React.useState([]);
  const [remarks, setRemarks] = React.useState("");
  const [disable, setDisable] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const backdrop = React.useContext(BackdropContext);
  const userProfileID = localStorage.getItem("profileID");

  const handleClick = (event) => {
    setOpen(!open);
  };
  //on change
  const onChange = (val) => {
    setData(val);
    handleClick();
  };
  //get user list
  const getUserList = (search) => {
    setSearch(search);
    const variables = {
      company_id: [company],
      search: search,
      client: localStorage.getItem(LocalStorageKeys.clinetID),
    };
    NetworkCall(
      `${config.api_url}/client_user_roles/get_users`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((res) => {
        setList(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //initial load
  React.useEffect(() => {
    getUserList("");
    // eslint-disable-next-line
  }, []);
  //update status
  const updateStatus = () => {
    setDisable(true);
    const variables = {
      agreement_id: details?.id,
      remarks: remarks,
      assigned_id: datas?.id ?? data?.assignee?.id,
      status: isType ? data?.status : getNextstatus(data?.status),
    };

    if (
      isType &&
      isUnderOnboard &&
      data?.data_type !== "agreement_approval_send"
    ) {
      variables["type"] = type === "Incomplete" ? "Declined" : "Approved";
    }
    if (data?.data_type === "agreement_approval_send") {
      variables["data_type"] = "approval_completed";
    }
    // if(data?.status === "Finance Review"){
    //   variables["type"] = "Approved"
    // }

    NetworkCall(
      `${config.api_url}/agreement_tracker`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((res) => {
        setOpen(false);
        onClose();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Log Created Successfully"),
        });
        reload();
        setDisable(false);
      })
      .catch((err) => {
        setDisable(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some thing went wrong"),
        });
      });
  };
  //verify kyc
  const verifyKyc = () => {
    const variables = {
      contact_id: details?.contact?.id,
    };
    NetworkCall(
      `${config.api_url}/kyc/check_kyc`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((res) => {
        if (res?.data?.check) {
          updateStatus();
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Please Verify All Mandatory KYC Items"),
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //on submit
  const onSubmit = () => {
    if (
      isType &&
      isUnderOnboard &&
      data?.status === "KYC Screening" &&
      type === "Complete"
    ) {
      verifyKyc();
    } else {
      updateStatus();
    }
  };

  useEffect(() => {
    getApprovalWorkflow();
  }, []);

  const [agreementConfirmationLevels, setagreementConfirmationLevels] =
    useState();
  const [workflowId, setWorkflowId] = useState("");
  const [approvalStatus, setApprovalStatus] = useState([]);

  const getApprovalWorkflow = () => {
    setLoading(true);
    const payload = { offset: 0, limit: 10, company_id: company };

    NetworkCall(
      `${config.api_url}/approval_workflow/get_approval_workflows`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        if (res?.data?.data) {
          const workflows = res.data.data;

          // Get Quotation Approval workflow
          const agreementConfirmationApproval = workflows.find(
            (workflow) =>
              workflow.workflow_type === "Agreement Confirmation Approval"
          );

          // Set states based on the found workflows
          if (agreementConfirmationApproval) {
            setagreementConfirmationLevels(
              agreementConfirmationApproval.no_of_levels
            );
            setWorkflowId(agreementConfirmationApproval.id);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleButtonClick = () => {
    const sendApproval = {
      agreement_id: agreement_id,
      workflow_id: workflowId,
      company_id: JSON.parse(
        localStorage.getItem(LocalStorageKeys.selectedCompany)
      )?.value,
      created_by: userProfileID,
      updated_by: userProfileID,
    };

    // Show the backdrop before making the API call
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: t("Loading..."),
    });

    NetworkCall(
      `${config.api_url}/approval_workflow/create_workflow_approval`,
      NetWorkCallMethods.post,
      sendApproval,
      null,
      true,
      false
    )
      .then((res) => {
        checkApporvalStatus();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Approval Request Has Been Sent"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: err?.response?.data?.error?.message?.message,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      })
      .finally(() => {
        // Hide the backdrop after API request completion
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        onClose(); 
      });
  };

  const checkApporvalStatus = () => {
    setLoading(true);
    const payload = {
      agreement_id: agreement_id,
      workflow_id: workflowId,
    };
    NetworkCall(
      `${config.api_url}/approval_workflow/check_approval_status`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setApprovalStatus(res?.data?.data?.status);
        setLoading(false);
      })

      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  React.useEffect(() => {
    if (workflowId) {
      checkApporvalStatus();
    }
    // eslint-disable-next-line
  }, [workflowId]);

  return (
    <Box p={2}>
      {type === "assign" && (
        <Box onClick={handleClick}>
          <SearchFilter
            label={t("assignRepresentative")}
            value={search}
            handleChange={(value) => getUserList(value)}
            placeholder={t("searchMember")}
          />
        </Box>
      )}

      <Box position="relative">
        {open && (
          <Box className={classes.contactList}>
            {list?.map((val) => {
              return (
                <ContactList value={datas} onClick={onChange} data={val} />
              );
            })}
          </Box>
        )}
        {datas ? (
          <Box
            display="flex"
            alignItems="center"
            className={classes.selectedList}
          >
            <Box padding="4px">
              <Avatar
                className={classes.selectedAvatar}
                src={datas?.image_url ?? null}
                {...stringAvatar(datas?.name, 14, "50%")}
              />
            </Box>
            <Box flexGrow={1} marginInlineStart="8px">
              <Typography className={classes.name}>{datas?.name}</Typography>
            </Box>
            <Box marginInlineEnd="8px">
              <IconButton size="small" onClick={() => setData(null)}>
                <img src="/images/delete.svg" alt="" />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <>{type === "assign" && <Box height="12px" />}</>
        )}
        <>{type === "assign" && <Box height="8px" />}</>
        {loading ? (
          <Skeletons />
        ) : agreementConfirmationLevels === "0" ||
          approvalStatus === "Approved" ? (
          <>
            {is_show && (
              <>
                <Box display="flex" alignItems="center">
                  <Box>
                    {type === "Incomplete" ? <DeclineForm /> : <Complete />}
                  </Box>
                  <Box width="16px" />
                  <Box>
                    <Typography className={classes1.skipFormTitle}>
                      {type === "Incomplete" ? t("Decline") : t("Complete")}
                    </Typography>
                    <Box height="4px" />
                    <Typography className={classes1.skipFormsubTitle}>
                      {type === "Incomplete"
                        ? t("Declined_des")
                        : t("Complete_des")}
                    </Typography>
                  </Box>
                </Box>
                <Box height="12px" />
              </>
            )}

            <TextBox
              isrequired
              label={t("remarks")}
              multiline
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              placeholder={t("Type_here")}
            />
            <Box height="24px" />

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  className={classes.btn}
                  variant="contained"
                  onClick={onClose}
                  fullWidth
                >
                  {t("close")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  className={classes.btn1}
                  disabled={remarks?.length > 0 && !disable ? false : true}
                  variant="outlined"
                  onClick={onSubmit}
                  fullWidth
                >
                  {isType
                    ? type === "Incomplete"
                      ? t("Yes")
                      : t("Complete")
                    : t("Assign")}
                </Button>
              </Grid>
            </Grid>
          </>
        ) : approvalStatus === "Rejected" ||
          agreementConfirmationLevels !== "0" ? (
          loading ? (
            <Skeletons />
          ) : (
            <div>
              <Box className={classes.createnewsection}>
                <Box>
                  <img
                    src="\images\approvalApproved.svg"
                    alt="verify"
                    width="60"
                    height="60"
                  />
                </Box>
                <Box width={5} />
                <Box>
                  <Typography className={classes1.modaltitle}>
                    {t(
                      "To proceed further, kindly send the Agreement for Approval process."
                    )}
                  </Typography>
                </Box>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                mt={2}
              >
                <Button
                  disabled={approvalStatus === "Pending"}
                  onClick={() => {
                    handleButtonClick();
                  }}
                  variant="contained"
                  className={classes.sendbtn}
                  fullWidth
                >
                  {t("Send")}
                </Button>
              </Box>
            </div>
          )
        ) : null}
      </Box>
    </Box>
  );
};
export default withTranslation("agreement")(TimelineForm);
