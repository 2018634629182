import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "8px",
  },
  subTitle: {
    fontSize:"0.75rem",
    color: theme.typography.color.tertiary,
    fontFamily: FontFamilySwitch().semiBold,
},
  leadDetailsRoot: {
  },
  avatarGroup: {
    justifyContent: "start",
    "& .MuiAvatar-root": {
      backgroundColor: "#5078E1",
      fontFamily: FontFamilySwitch().bold,
      fontSize:"0.875rem"
    }
  },
  typeBg: {
    position: "absolute",
    textAlign: "center",
    color: "white",
    right: "0px"
  },
  typeBgArabic: {
    position: "absolute",
    textAlign: "center",
    color: "white",
    left: "0px",
    direction: "ltr"
  },
  centeredText: {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  type: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().bold,
    marginLeft: "3px",
    marginTop: "1px"
  },
  leadDetailsRootDivider: {
    borderRadius: "4px",
    backgroundColor: "white",
    height: `calc(100vh - 274px)`,
    overflow: 'auto',
    boxShadow: "0px 0px 16px #00000014",
    [theme.breakpoints.down('lg')]: {
      height: "auto",
    },
  },
  srcText: {
    color: "#896DB3",
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().bold,
  },
  accountText: {
    color: "#B3A16D",
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().bold,
  },
  arrowIcon: {
    color: "#896DB3",
  },
  typeText: {
    color: "#6D80B3",
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().bold,
    // marginLeft: "3px"
  },
  accText: {
    color: "#B3A16D",
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().bold,
  },
  sourceTypeIcon: {
    marginLeft: "3px",
    marginTop: "2px"
  },
  homeIcon: {
    color: "#6D80B3",
    marginTop: "2px"

  },
  purposebox: {
    backgroundColor: "#E4EDFF80",
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
    marginTop: "-5px"
  },

  accountbox: {
    backgroundColor: "#FFFCEB",
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
    marginTop: "-5px"
  },
  srctypebox: {
    backgroundColor: "#EDE4FE80",
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
    marginTop: "-12px"
  },
  title: {
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  userImg: {
    height: "48px",
    width: "48px",
    borderRadius: "4px",
    objectFit: "fill"
  },
  detailRoot: {
    // backgroundColor: "#F5F7FA",
    // padding: "6px 6px 6px 6px"
    display: "flex",
    justifyContent: "space-between"
  },
  leadName: {
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().bold,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    marginInlineStart: '14px'
  },
  leadNo: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().bold,
    backgroundColor: theme.palette.background.secondary,
    color: theme.typography.color.tertiary,
    borderRadius: "4px",
    padding: "2px 4px",
    marginTop: "4px"
  },
  mobileNo: {
    fontSize:"0.75rem",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().bold,
    marginInlineStart: "8px"
  },
  mailIcon: {
    fontSize: "19px",
    color: "#98a0ac",
    marginLeft: "3px"
  },
  divider: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  dataBold: {
    fontSize:"0.75rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().bold
  },
  tabContant: {
    padding: "12px",
  },
  center: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
    marginTop: "53px",
  },
  tabTitle: {
    color: "#4E5A6B",
    fontSize:"1rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  image: {
    borderRight: "1px solid #E4E8EE",
  },
  viewCard: {
    border: "1px solid #E4E8EE",
    borderRadius: "4px",
    padding: "16px",
    marginTop: '16px'
  },
  customTypography: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: '#4E5A6B',
    marginBottom: 10
  },
  //unitCard Styles
  unitCard: {
    padding: "15px",
    backgroundColor: "#DBF0F180",
    display: "flex",
    justifyContent: "space-between",
    borderRadius: theme.palette.borderRadius,
    marginTop: "15px"
  },
  selectionIcon: {
    color: "#6DAFB3"
  },
  selectionText: {
    color: "#6DAFB3"
  },
  selectarrowRightIcon: {
    color: "#6DAFB3",
    marginTop: "8px"
  },
  ownerSelection: {
    padding: "25px",
  },
  selectCard: {
    display: "flex",
    justifyContent: "space-between",
  },
  ownerText: {
    fontSize:"1rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold
  },
  ownerSubText: {
    fontSize:"0.875rem",
    color: theme.typography.color.tertiary,
    fontFamily: FontFamilySwitch().regular,
  },
  ownerTitle: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.tertiary,
    marginBottom: "5px"
  },
  ownerAgainstUnits: {
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    marginTop: "5px"
  },
  individualownerSelection: {
    padding: "25px",
    height: "400px"
  },
  unitName: {
    fontSize:"0.875rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  unitLocation: {
    fontSize:"0.75rem",
    color: theme.typography.color.tertiary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  vacant: {
    fontSize:"0.75rem",
    color: "#5AC782",
    fontFamily: FontFamilySwitch().semiBold,
    marginTop: "10px"
  },
  mileStoneBg: {
    backgroundColor: "#F2F4F7",
    border: "1px solid #E4E8EE",
    padding: "15px"
  },
  mileStoneName: {
    fontSize:"0.875rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
  },
  interestunitcard: {
    backgroundColor: "#F5F7FA",
    cursor: "pointer"
  },
  unitproperty: {
    padding: "12px",
    justifyContent: "start"
  },
  unit_name: {
    fontSize:"0.875rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  property_name: {
    fontSize:"0.75rem",
    color: theme.typography.color.tertiary,
    fontFamily: FontFamilySwitch().italic,
  },
  intTitle: {
    fontSize: "0.8125rem",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().bold
  },
  unitselectivemain: {
    border: "1px solid #E4E8EE",
    borderRadius: theme.palette.borderRadius
  },
  unitselective: {
    padding: "12px"
  },
  remove: {
    fontSize:"0.75rem",
    color: "#FF4B4B",
    fontFamily: FontFamilySwitch().semiBold,
    marginTop: "10px"
  },
  addBrokerAcc: {
    marginTop: "4px",
    fontSize:"0.75rem",
    "&:hover": {
      backgroundColor: "white",
    },
    fontFamily: FontFamilySwitch().bold
  },
  accTitle: {
    fontFamily: FontFamilySwitch().bold,
    color: (props) =>props?.textColor?? "#B3776D",
    fontSize:"0.75rem"
  },
  accLabel: {
    fontFamily: FontFamilySwitch().bold,
    fontSize:"0.875rem"
  },
  accNo: {
    fontSize:"0.75rem",
    color: (props) =>props?.textColor?? "#98A0AC"
  },
  brokerRoot: {
    padding: "12px",
    backgroundColor: (props) =>props?.bgColor?? "#FEF4F4",
    borderRadius: "4px",
    marginBottom: "17px"
  }
}));
export const useStylesDetailsCard = makeStyles((theme) => ({
  root: {
    padding: "12px",
    borderRadius: "8px",
    backgroundColor: "#fff",
  },
  heading: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: " #98A0AC",
  },
  sub: {
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#091B29",
    marginTop: "6px",
  },
  status: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().bold,
    padding: "2px 10px",
    borderRadius: "2px",
    display: "inline-block",
    marginTop: "6px",
  },
  btn: {
    padding: "0px",
    boxShadow: "none",
    marginTop: "6px",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  title: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#4E5A6B",
  },
  imageView: {
    height: "100px",
    width: "100px",
    margin: '0 auto'
  },
  templateTitle: {
    color: '#091B29',
    fontFamily: FontFamilySwitch().bold,
    fontSize:"0.875rem",
    marginBottom: "2px"
  },
  templateDesc: {
    color: '#98A0AC',
    fontFamily: FontFamilySwitch().regular,
    fontSize:"0.75rem"
  },
  templateUpdate: {
    color: '#5078E1',
    fontFamily: FontFamilySwitch().bold
,
    fontSize:"0.75rem",
    cursor: 'pointer'
  },
  templateRoot: {
    padding: "12px",
    backgroundColor: 'white',
    marginTop: "12px",
    border: `1px solid #E4E8EE`
  },
  templateAmountTilte: {
    color: '#578C8F',
    fontFamily: FontFamilySwitch().bold,
    fontSize:"0.75rem",
    marginLeft: "12px"
  },
  amountRoot: {
    padding: "14px",
    backgroundColor: '#EEF9EE',
    borderRadius: "4px",
    marginTop: "12px"
  },
  downBtn: {
    border: `1px solid ${theme?.typography?.status?.primary}`,
    color: theme?.typography?.color?.white,
    backgroundColor: theme?.typography?.status?.primary,
    width: "100%",
    "&:hover": {
      border: `1px solid ${theme?.typography?.status?.primary}`,
      color: theme?.typography?.color?.white,
      backgroundColor: theme?.typography?.status?.primary,
    }
  },
  subTitle: {
    fontSize:"0.75rem",
    color: theme.typography.color.tertiary,
    fontFamily: FontFamilySwitch().semiBold,
},

}));