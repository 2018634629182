import { makeStyles } from "@mui/styles";
import { FontFamilySwitch, remCalc } from "../../../utils";


export const InspectEditStyles = makeStyles((theme) => ({
    inspection: {
        padding: "20px",
    },
    inspectionTable: {
        padding: "20px",
        margin: "20px",
        backgroundColor: theme.palette.background.tertiary1,
        border: `1px solid ${theme.palette.border.secondary}`,
        boxShadow: "0px 0px 16px #00000014",
        borderRadius: theme.palette.borderRadius,
    },

    search: {
        padding: "24px 24px 10px 24px",
    },

    innerCard: {
        margin: "8px",
    },

    cardSection: {
        height: "468px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        border: "1px solid" + theme.palette.border.secondary,
        padding: "22px",

    },
    inputTxt: {
        color: theme.palette.background.primary,
        fontSize:"0.875rem"
    },
    itemInput: {
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        height: 35,
        borderRadius: 4

    },
    assignBtn: {
        width: "100%",
        boxShadow: "unset"
    },
    addIcon: {
        marginInlineStart: "12px !important"
    },
    fixedBottom:{
        position:"absolute",
        left:0,
        right:0,
        bottom:0,
        padding:"16px"
    },
    drawerHeader:{
        fontSize:remCalc(16),
        fontFamily: FontFamilySwitch().extraBold,
        color:theme.typography.color.primary
    }
}));