import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const AggreementCardInfoStyles = makeStyles((theme) => ({
    cardRoot: {
        padding: '12px',
        borderRadius: theme.palette.borderRadius,
        backgroundColor: (props) => props?.color,
        minHeight: "150px",

        cursor: "pointer",
        position:"relative"
    },
    title: {
        fontSize:"1.125rem",
        color: "#091B29",
        fontFamily: FontFamilySwitch().bold,
        // width: "95%",
        direction: "ltr",
        textAlign: "-webkit-match-parent"
    },
    subtitle: {
        fontSize:"0.75rem",
        color: "#4E5A6B",
        fontFamily: FontFamilySwitch().semiBold,
        marginTop: "4px"
    },
    currency: {
        marginRight: "10px"
    },
    bottomPlace:{
        position:"absolute",
        bottom:"12px",
        width:"-webkit-fill-available"
    }
}));