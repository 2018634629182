import React, { useState, useContext } from "react";
import {
  Subheader,
  TableWithPagination,
  FilterGenerator,
  AlertDialog,
} from "../../components";
import { SelectBox } from "../../components/select";
import { useStyles } from "./style";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Popover,
  Divider,
  CircularProgress,
  Link,
  Menu,
  MenuItem,
  List,
  Tooltip,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import { withTranslation } from "react-i18next";
import {
  NetWorkCallMethods,
  AlertProps,
  getCompanyOption,
  getRoutePermissionNew,
  accessCheckRender,
  LocalStorageKeys,
} from "../../utils";
import { DownloadImage } from "../../assets";
import { ExcelImage } from "../../assets/excelImage";
import { PdfImage } from "../../assets/pdfImage";
import { useNavigate, useLocation } from "react-router-dom";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { BackdropContext, AlertContext, AuthContext } from "../../contexts";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { NewLoader } from "../../components/newLoader";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ReportCheckBox } from "./components/checkBox";
import { BackendRoutes } from "../../router/routes";
import InfiniteScroll from "react-infinite-scroll-component";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

const Report = (props) => {
  const clientId = localStorage.getItem(LocalStorageKeys.clinetID);
  const { loading, handleLoading, t } = props;
  const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId);
  const optionsMenu = [
    {
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <ExcelImage
            style={{ width: "20px", height: "20px", marginRight: "8px" }}
          />
          Excel
        </div>
      ),
      value: "xlsx",
    },
    {
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <PdfImage
            style={{ width: "20px", height: "20px", marginRight: "8px" }}
          />
          PDF
        </div>
      ),
      value: "pdf",
    },
  ];

  const optionMenu = [
    {
      label: "Excel",
      value: "excel",
    },
    {
      label: "Pdf",
      value: "pdf",
    },
  ];

  const [selectedOption, setSelectedOption] = useState(optionsMenu[0]);

  // histroy
  const navigate = useNavigate();
  // location
  const { state } = useLocation();
  // styles
  const classes = useStyles();
  // -----------------------------------STATES------------------------------------------------------------
  const [company, setCompany] = useState({
    searchText: "",
    drawer: false,
    property_management: "",
    SelectedCompany: {},
  });
  const [filterData, setFilterData] = useState({});
  const [filterComponentsValue, setFilterComponentsValue] = useState({});
  const [list, setList] = useState({
    data: [],
    count: 0,
  });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const auth = useContext(AuthContext);
  const backdrop = useContext(BackdropContext);
  const alert = useContext(AlertContext);
  const [permission, setPermission] = React.useState({});
  const [loader, setLoader] = React.useState(true);
  const [selectedColumns, setSelectedColumns] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [openColumnsDialog, setOpenColumnsDialog] = React.useState(false);
  const [showColumns, setShowColumns] = React.useState(false);
  const [newDataType, setNewDataType] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [offset, setOffset] = useState(0);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isReportLoading, setIsReportLoading] = React.useState(true);
  const [downloadedFiles, setDownloadedFiles] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [scrollOffset, setScrollOffset] = useState(0);
  const [pendingFiles, setPendingFiles] = useState([]);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (value) => {
    setSelectedOption(value);
  };

  //--------------------------------FUNCTION-------------------------------------------
  const updateState = (k, v) => {
    setCompany({ ...company, [k]: v });
  };

  //   company selection
  const companySelect = (e) => {
    updateState("SelectedCompany", e);
  };
  const updateState1 = (value) => {
    setFilterData(value);
  };

  const getData = (offset = 0, limit = 40, search = "") => {
    if (state?.reportId?.length > 0) {
      let payload = {
        offset,
        limit,
        search,
        report_id: state?.reportId,
      };
      NetworkCall(
        `${config?.api_url}${BackendRoutes.reports_get_report}`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((res) => {
          let temp_res = res?.data?.data?.[0] ?? [];
          setTableData(temp_res);
        })
        .catch((error) => {
          console.log(error);
          alert.setSnack({
            ...alert,
            open: true,
            msg: t("Some Thing Went Wrong"),
            severity: AlertProps.severity.error,
          });
        });
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        msg: t("Please select the report"),
        severity: AlertProps.severity.error,
      });
      navigate("/");
    }
  };
  const dateFilter = (value) => {
    if (value?.date?.startDate > value?.date?.endDate) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.warning,
        msg: t("End Date is Must Be Greater than Start Date"),
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
      return false;
    }
    if (value?.date?.startDate && value?.date?.endDate) {
      const filters = {};
      for (const key in value) {
        filters[key] =
          value[key] && Array.isArray(value[key])
            ? value[key]?.map((item) => item.value ?? item)
            : value[key];
      }
      getList(0, 10, filters);
      setPage(1);
      setLimit(10);
      setFilterData(filters);
    } else {
      if (value?.date?.startDate && !value?.date?.endDate) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("End Date is required"),
        });
      }
      if (!value?.date?.startDate && value?.date?.endDate) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Start Date is required"),
        });
      }
    }
  };
  const onApplyFilter = (value) => {
    setFilterComponentsValue(value);
    if (value?.date) {
      dateFilter(value);
    } else {
      const filters = {};
      for (const key in value) {
        filters[key] =
          value[key] && Array.isArray(value[key])
            ? value[key]?.map((item) => item.value ?? item)
            : value[key];
      }
      getList(0, 10, filters);
      setPage(1);
      setLimit(10);
      setFilterData(filters);
    }
  };
  //   company selection
  //reports get
  const getList = (offset, limit, filters = {}) => {
    setLoader(true);
    const requestPayload = {
      filters: filters,
      offset: offset,
      limit: limit,
      company_id: company?.SelectedCompany?.value,
    };
    NetworkCall(
      `${config.api_url}${state?.end_point}`,
      NetWorkCallMethods.post,
      requestPayload,
      null,
      true,
      false
    )
      .then((res) => {
        let data = res?.data?.data?.map((item, index) => {
          return {
            index: offset + index + 1,
            ...item,
          };
        });
        setList({
          data: data ?? [],
          count: res?.data?.count ?? 0,
        });
        handleLoading(false);
        setLoader(false);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong. Please try again"),
        });
      });
  };
  // pagination
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getList(offset, limit, filterData);
  };
  // pagination limit change function
  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getList(0, value, filterData);
  };

  const clearFilter = () => {
    setFilterData({});
    setFilterComponentsValue({});
    getList(0, 10, {});
    updateState("drawer", false);
  };

  // useEffect to get company list for company switcher when loading the screen
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          setCompany({
            ...company,
            companyList: company?.list,
            SelectedCompany: company?.selected,
          });
        }
        getData();
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  React.useEffect(() => {
    if (
      company?.SelectedCompany?.label?.length > 0 &&
      tableData?.name?.length > 0
    ) {
      getList(0, 10, {});
    }
    // eslint-disable-next-line
  }, [company?.SelectedCompany, tableData]);
  const filterComponents =
    tableData?.schema?.filters?.length > 0
      ? tableData?.schema?.filters?.map((item) => {
          return {
            component: item?.component,
            value: item?.value ?? filterComponentsValue[item?.key],
            options: item?.options,
            isMulti: item?.is_multi,
            label: item?.label,
            placeholder: item?.placeholder,
            state_name: item?.key,
            loadOptions: (search, array, handleLoading) =>
              loadOptionsApis(
                item?.load_options_end_point,
                item?.load_options_end_point === "/enum"
                  ? item?.payload
                  : { company_id: company?.SelectedCompany?.value },
                search,
                array,
                handleLoading,
                item?.extract_label ?? "data"
              ),
            debounceTimeout: item?.debounceTimeout,
            isPaginate: item?.isPaginate,
            onChange: (value) => updateState1(item?.state_name, value),
            // minMaxlabel: item?.minMaxlabel ?? false
          };
        })
      : [];
  //column options(
  const columnOptions = tableData?.schema?.table_headers?.map((item) => {
    return {
      value: item?.field,
      label: item?.title,
    };
  });
  const onSelectedColumns = () => {
    setShowColumns(true);
    const ResultArrayObjtwo = tableData?.schema?.table_data_type?.filter(
      ({ name }) => selectedColumns?.some(({ value }) => value === name)
    );
    setNewDataType(ResultArrayObjtwo);
    setOpenColumnsDialog(false);
  };
  const onClearColumns = () => {
    setShowColumns(false);
    setSelectedColumns([]);
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (pendingFiles.length > 0 && open) {
        // get ids of pending files
        const pendingFileIds = Array.isArray(pendingFiles)
          ? pendingFiles
              .map((file) => file?.id)
              .filter((id) => id !== undefined)
          : [];

        performNetworkCall(
          `${config.api_url}/360_reports/get_report_status`,
          NetWorkCallMethods.post,
          { report_ids: pendingFileIds },
          (response) => {
            // filter undefined data in the array and remove them
            const filteredPendingFiles = pendingFiles.filter(
              (file) => file !== undefined
            );

            // get response files with is_completed = true or is_failed = true
            const updatedFiles = response.data.data;
            const completedOrFailedFiles = updatedFiles.filter(
              (file) => file.is_completed || file.is_failed
            );

            // remove completed files from pending files
            const newPendingFiles = filteredPendingFiles.filter(
              (file) =>
                !completedOrFailedFiles.some(
                  (completedOrFailedFile) =>
                    completedOrFailedFile.id === file.id
                )
            );

            setPendingFiles(newPendingFiles);

            // update downloaded files with the new data
            const newDownloadedFiles = downloadedFiles.map((file) => {
              const updatedFile = updatedFiles.find(
                (updatedFile) => updatedFile.id === file.id
              );
              if (updatedFile) {
                return updatedFile;
              }
              return file;
            });

            setDownloadedFiles(newDownloadedFiles);
          }
        );
      }
    }, 5000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingFiles, open]);

  const performNetworkCall = async (url, method, payload, onSuccess) => {
    try {
      const response = await NetworkCall(
        url,
        method,
        payload,
        null,
        true,
        false
      );
      if (response.status === 200) {
        onSuccess(response);
      } else {
        console.error("Failed to store report details");
      }
    } catch (error) {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Some Thing Went Wrong"),
      });
    }
  };

  const fetchMoreReport = () => {
    setIsLoadingMore(true);
    const newScrollOffset = scrollOffset + 5;
    setScrollOffset(newScrollOffset);
    performNetworkCall(
      `${config.api_url}/360_reports/get_reports`,
      NetWorkCallMethods.post,
      { report_type: getReportType(), offset: newScrollOffset, limit: 5 },
      (response) => {
        const getData = response.data.data;
        setDownloadedFiles([...downloadedFiles, ...getData]);
        const queuedReports = [...downloadedFiles, ...getData].map((file) => {
          if (!file.is_completed) {
            return file;
          }
        });
        setPendingFiles(queuedReports);

        console.log("Report details stored successfully");
        setIsReportLoading(false);
        setIsLoading(false);
        setIsDownloadLoading(false);
        setIsLoadingMore(false);
      }
    );
  };

  const handleDownload = async (event) => {
    setAnchorEl(event.currentTarget);
    setIsReportLoading(true);
    const newScrollOffset = 0;
    setScrollOffset(newScrollOffset);
    performNetworkCall(
      `${config.api_url}/360_reports/get_reports`,
      NetWorkCallMethods.post,
      { report_type: getReportType(), offset: newScrollOffset, limit: 5 },
      (response) => {
        const getData = response.data.data;
        setDownloadedFiles(getData);

        // eslint-disable-next-line array-callback-return
        const queuedReports = getData.map((file) => {
          if (!file.is_completed) {
            return file;
          }
        });
        setPendingFiles(queuedReports);
        console.log("Report details stored successfully");
        setIsReportLoading(false);
        setIsLoading(false);
        setIsDownloadLoading(false);
      }
    );
  };

  const downloadExcelAndPdf = (type) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "downloading...",
    });
    const keyMapper =
      selectedColumns?.length > 0 &&
      Object.fromEntries(
        selectedColumns?.map((item, index) => [
          item.value,
          { header: item.label, index: index },
        ])
      );
    const requestPayload = {
      report_id: state?.reportId,
      filters: filterData,
      offset: offset,
      limit: limit,
      key_mapper: keyMapper,
      format_type: type,
      company_id: company?.SelectedCompany?.value,
    };
    NetworkCall(
      `${config.api_url}/reports/exports`,
      NetWorkCallMethods.post,
      requestPayload,
      null,
      true,
      false,
      { responseType: "blob" }
    )
      .then((res) => {
        const { data, headers } = res;
        setAnchorEl(null);
        var blob = new Blob([data], { type: data?.type });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var fileName = JSON.parse(
          headers["content-disposition"].split("=")[
            headers["content-disposition"].split("=").length - 1
          ]
        );
        link.download = fileName;
        link.click();
        backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
      })
      .catch((error) => {
        setAnchorEl(null);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong. Please try again"),
        });
      });
  };

  const generateRandomAlphanumeric = (length) => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };

  const handleDownloadReport = async () => {
    setIsLoading(true);
    setIsDownloadLoading(true);
    const date = new Date();
    const yy = String(date.getFullYear());
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    const hh = String(date.getHours()).padStart(2, "0");
    const min = String(date.getMinutes()).padStart(2, "0");
    const randomAlphanumeric = generateRandomAlphanumeric(6);
    const file_name = `${getReportType()}_report_${yy}_${mm}_${dd}_${hh}_${min}_${randomAlphanumeric}.${
      selectedOption?.value
    }`;
    performNetworkCall(
      `${config.api_url}/reports/export`,
      NetWorkCallMethods.post,
      {
        file_name: file_name,
        report_type: getReportType(),
        is_completed: false,
        is_failed: false,
        file_url: null,
        file_type: selectedOption?.value,
        company_id: company?.SelectedCompany?.value,
        client_id: clientId,
        filters: {
          opportunity_status: filterData?.opportunity_status,
          unit_usage: filterData?.unit_usage,
          contact_account: filterData?.contact_account,
          broker_account: filterData?.broker_account,
          lead_source: filterData?.lead_source,
          revenue_type: filterData?.revenue_type,
          usage_type: filterData?.usage_type,
          account_id: filterData?.account_id,
          agreement_type: filterData?.agreement_type,
          agreement_status: filterData?.agreement_status,
          broker_agent_account: filterData?.broker_agent_account,
          auto_renewal: filterData?.auto_renewal,
          is_mail_trigger: filterData?.is_mail_trigger,
          sla_account_id: filterData?.sla_account_id,
          project_id: filterData?.project_id,
          unit_type: filterData?.unit_type,
          purpose_global_type: filterData?.purpose_global_type,
          internal_lead_source_id: filterData?.internal_lead_source_id,
          urgent: filterData?.urgent,
          lead_owner: filterData?.lead_owner,
          campaign_source: filterData?.campaign_source,
          status: filterData?.status,
          classify_lead_id: filterData?.classify_lead_id,
          is_confirm_interest: filterData?.is_confirm_interest,
          property_id: filterData?.property_id,
        },
      },
      async () => {
        console.log("Report details stored successfully");
        const newScrollOffset = 0;
        setScrollOffset(newScrollOffset);
        performNetworkCall(
          `${config.api_url}/360_reports/get_reports`,
          NetWorkCallMethods.post,
          { report_type: getReportType(), offset: newScrollOffset, limit: 5 },
          (response) => {
            const getData = response.data.data;
            setDownloadedFiles(getData);
            // eslint-disable-next-line array-callback-return
            const queuedReports = getData.map((file) => {
              if (!file.is_completed) {
                return file;
              }
            });
            setPendingFiles(queuedReports);
            console.log("Report details stored successfully");
            setIsLoading(false);
            setIsDownloadLoading(false);
          }
        );
      }
    );
  };

  const handleOpenFile = (fileName) => {
    const fileUrl = fileName;
    window.open(fileUrl, "_blank");
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDownloadedFiles([]);
  };

  const openAnchor = Boolean(anchorEl);
  const id = openAnchor ? "simple-popover" : undefined;
  function truncateFileName(fileName) {
    const maxLength = 15;
    if (fileName.length <= maxLength * 2) {
      return fileName;
    }
    const start = fileName.slice(0, maxLength);
    const end = fileName.slice(-maxLength);
    return `${start}...${end}`;
  }

  const getReportType = () => {
    switch (state?.reportId) {
      case "ca7cb665-3380-4e88-9672-8087fe433604":
        return "lead_overview";
      case "72cf839d-a86b-43bd-bbf9-bb258484bfca":
        return "opportunity_overview";
      case "94ff56e1-7bc0-472f-b173-b0925a2ff899":
        return "agreement_overview";
      default:
        return "";
    }
  };

  const render = () => {
    const report_id = state?.reportId;
    let reportType = "";
    if (state?.reportId === "ca7cb665-3380-4e88-9672-8087fe433604") {
      reportType = "lead_overview";
    }
    if (state?.reportId === "72cf839d-a86b-43bd-bbf9-bb258484bfca") {
      reportType = "opportunity_overview";
    }
    if (state?.reportId === "94ff56e1-7bc0-472f-b173-b0925a2ff899") {
      reportType = "agreement_overview";
    }
    reportType = reportType
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "_")
      .replace(/(^_|_$)/g, "")
      .replace(/_report$/, "");

    return (
      <div>
        {" "}
        {/* sub Navbar */}
        <Subheader
          title={tableData?.name ?? ""}
          goBack={() => {
            navigate(-1);
          }}
          select={moduleId === "5" ? false : true}
          options={company?.companyList}
          value={company?.SelectedCompany}
          placeholder={t("Search")}
          onchange={(e) => {
            companySelect(e);
          }}
        />
        <div className={classes.root}>
          <Grid container className={classes.content} spacing={2}>
            <Grid item xs={3} className={classes.PaddingTop}>
              <Box>
                <IconButton
                  size="small"
                  className={classes.img3}
                  onClick={() => setOpenColumnsDialog(true)}
                >
                  {t("Filter columns")} &nbsp;
                  <VisibilityIcon />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={9} className={classes.PaddingTop}>
              <Box display={"flex"} alignItems="center" sx={{ float: "right" }}>
                {report_id === "ca7cb665-3380-4e88-9672-8087fe433604" ||
                report_id === "72cf839d-a86b-43bd-bbf9-bb258484bfca" ||
                report_id === "94ff56e1-7bc0-472f-b173-b0925a2ff899" ? (
                  //   reportType === "lead_overview" ||
                  // reportType === "opportunity_overview" ||
                  //     reportType === "agreement_overview"
                  <Stack
                    direction="row"
                    spacing={0.6}
                    display="flex"
                    alignItems="center"
                  >
                    <Grid container>
                      <Grid item xs={12} width="200px">
                        <SelectBox
                          value={selectedOption}
                          onChange={handleChange}
                          options={optionsMenu}
                        />
                      </Grid>
                    </Grid>

                    <IconButton
                      size="small"
                      className={classes.img}
                      onClick={handleDownload}
                    >
                      <DownloadImage />
                    </IconButton>
                    <Popover
                      id={id}
                      open={openAnchor}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <div className={classes.tray}>
                        <div className={classes.buttonWrapper}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleDownloadReport}
                            disabled={isLoading || isDownloadLoading}
                            className={classes.button}
                          >
                            {isDownloadLoading ? (
                              <CircularProgress size={24} />
                            ) : (
                              "Download Report"
                            )}
                          </Button>
                        </div>
                        <Divider />
                        <List className={classes.list}>
                          {isReportLoading ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <CircularProgress size={34} />
                            </div>
                          ) : downloadedFiles.length === 0 ? (
                            <Typography style={{ textAlign: "center" }}>
                              No export history
                            </Typography>
                          ) : (
                            <InfiniteScroll
                              dataLength={downloadedFiles.length}
                              next={fetchMoreReport}
                              hasMore={true}
                              height={150}
                            >
                              <Grid>
                                {downloadedFiles.map((file, index) => (
                                  <ListItem
                                    key={index}
                                    className={classes.listItem}
                                  >
                                    <Tooltip title={file.file_name} arrow>
                                      {file.is_completed ? (
                                        <Link
                                          className={classes.fileName}
                                          onClick={() =>
                                            handleOpenFile(file.file_url)
                                          }
                                          component="button"
                                          variant="body2"
                                        >
                                          {truncateFileName(file.file_name)}
                                        </Link>
                                      ) : (
                                        <Typography
                                          variant="body2"
                                          className={classes.disabledFileName}
                                          style={{ color: "gray" }}
                                        >
                                          {truncateFileName(file.file_name)}
                                        </Typography>
                                      )}
                                    </Tooltip>
                                    {file.is_completed ? null : file.is_failed ? (
                                      <WarningAmberRoundedIcon
                                        className={classes.errorIcon}
                                        style={{
                                          color: "red",
                                          fontSize: "18px",
                                        }}
                                      />
                                    ) : (
                                      <CircularProgress size={24} />
                                    )}
                                  </ListItem>
                                ))}
                                {isLoadingMore && (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <CircularProgress size={24} />
                                  </div>
                                )}
                              </Grid>
                            </InfiniteScroll>
                          )}
                        </List>
                      </div>
                    </Popover>

                    <IconButton
                      size="small"
                      className={classes.img}
                      onClick={() => updateState("drawer", true)}
                    >
                      <img src="/images/filter.svg" alt="filter" />
                    </IconButton>
                  </Stack>
                ) : (
                  // Code block for other report types
                  <>
                    {/* <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openAnchor}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      className={classes.menuparent}
                    >
                      {optionMenu.map((x, index) => (
                        <Box className={classes.menuparent} key={index}>
                          <MenuItem
                            onClick={() => downloadExcelAndPdf(x?.value)}
                          >
                            <Typography className={classes.menutext}>
                              {x?.label}
                            </Typography>
                          </MenuItem>
                          <hr
                            className={
                              index === optionMenu.length - 1
                                ? classes.alt_divider
                                : classes.divider_menu
                            }
                          />
                        </Box>
                      ))}
                    </Menu> */}
                    <IconButton
                      size="small"
                      className={classes.img}
                      onClick={() => downloadExcelAndPdf("excel")}
                      disabled={list?.data === 0}
                    >
                      <DownloadImage />
                    </IconButton>
                    <Box mx={1} />
                    <IconButton
                      size="small"
                      className={classes.img}
                      onClick={() => updateState("drawer", true)}
                    >
                      <img src="/images/filter.svg" alt="filter" />
                    </IconButton>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} className={classes.noPadding}>
              {loader ? (
                <NewLoader minusHeight="300px" />
              ) : (
                <TableWithPagination
                  heading={
                    showColumns
                      ? selectedColumns?.length > 0 &&
                        selectedColumns?.map(({ value, label }) => ({
                          title: label,
                          field: value,
                        }))
                      : tableData?.schema?.table_headers
                  }
                  rows={list?.data ?? []}
                  path={tableData?.schema?.table_path ?? []}
                  showpagination={true}
                  showpdfbtn={false}
                  showexcelbtn={false}
                  totalRowsCount={list?.count}
                  page={page ?? 1}
                  limit={limit ?? 10}
                  tableType="no-side"
                  dataType={
                    showColumns
                      ? newDataType?.length > 0 && newDataType
                      : tableData?.schema?.table_data_type
                  }
                  height={"calc(100vh - 301px)"}
                  handlePagination={handlePagination}
                  handleChangeLimit={handleChangeLimit}
                />
              )}
            </Grid>
          </Grid>
        </div>
        {company?.drawer && (
          <FilterGenerator
            open={company?.drawer}
            onClose={() => updateState("drawer", false)}
            components={filterComponents}
            onApply={(value) => onApplyFilter(value)}
            clearFilter={clearFilter}
            is_customFilter
          />
        )}
        {/* column select */}
        <AlertDialog
          open={openColumnsDialog}
          onClose={() => setOpenColumnsDialog(false)}
          header={t("Choose Columns To Display")}
          isNormal
          component={
            <Box p={2}>
              <Box height="400px">
                <ReportCheckBox
                  t={t}
                  options={columnOptions}
                  value={selectedColumns}
                  onChange={(value) => setSelectedColumns(value)}
                  placeholder={t("Search Columns")}
                />
              </Box>
              {/* buttons */}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    className={classes.previousbtn}
                    disabled={selectedColumns?.length === 0}
                    fullWidth
                    onClick={onClearColumns}
                  >
                    {t("Clear All")}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    className={classes.submitbtn}
                    disabled={selectedColumns?.length === 0}
                    variant="contained"
                    fullWidth
                    onClick={onSelectedColumns}
                  >
                    {t("Apply")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          }
        />
      </div>
    );
  };
  return <div>{accessCheckRender(render, permission, "", loading)}</div>;
};

export default withTranslation("reports")(Report);
