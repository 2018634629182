import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: `0px 0px 16px #00000014`,
    backgroundColor: "white",
    padding: "24px 16px",
    margin: "14px",
    height: `calc(100vh - 147px)`,
  },
  img: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "13px",
  },
  img3: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
  },
  outerCircle: {
    padding: "2px",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "50%",
  },
  noPadding: {
    paddingTop: "0px !important",
  },
  PaddingTop: {
    paddingTop: "7px !important",
  },
  previousbtn: {
    backgroundColor: "white",
    color: theme.typography.color.primary,
    border: "1px solid #CED3DD",
    borderRadius: theme.palette.borderRadius,
    padding: "8px 12px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  submitbtn: {
    borderRadius: theme.palette.borderRadius,
    marginLeft: "8px",
    padding: "8px 12px",
    boxShadow: "none",
  },
  alt_divider: {
    display: "none",
  },
  divider_menu: {
    border: "1px solid #EBEEF3",
    margin: "3px 0px",
  },
  menuparent: {
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingTop: "0px",
    paddingBottom: "0px",
    display: "flex",
    alignItems: "center",
  },
  menutext: {
    color: "#000000",
  },
  customSelect: {
    fontSize: "20px",
    width: "200px",
    color: "#000000",
    borderRadius: "2px",
    fontFamily:"system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'NotoSans-SemiBold'",
    padding: "8px",
    minWidth: "120px",
    border: "1px solid #CED3DD",
    // eslint-disable-next-line no-dupe-keys
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    "&::placeholder": {
      color: "#000000",
      opacity: 1, // Ensure the placeholder text is fully opaque
    },

    "& option": {
      color: "#000000", // Ensure selected option text is black
    },
    customSelect: {
      fontSize: "16px", // Ensure the font size is consistent
      width: "200px", // Set a fixed width to prevent size reduction
      color: "#000000",
      borderRadius: "4px",
      padding: "8px",
      border: "1px solid #CED3DD",
      backgroundColor: "#FFFFFF",
      "&::placeholder": {
        color: "#000000", // Dark placeholder text
        opacity: 1, // Ensure the placeholder text is fully opaque
      },
      "& option": {
        color: "#000000", // Ensure option text is black
      },
      "& .selectedOption": {
        color: "#000000", // Ensure selected option text is black
      },
    },
    menuparent: {
      paddingLeft: "8px",
      paddingRight: "8px",
      paddingTop: "0px",
      paddingBottom: "0px",
      display: "flex",
      alignItems: "center",
    },
    menutext: {
      color: "#000000", // Ensure menu text color is black
    },
    alt_divider: {
      display: "none",
    },
    divider_menu: {
      border: "1px solid #EBEEF3",
      margin: "3px 0px",
    },
    img: {
      border: `1px solid ${theme.palette.border.secondary}`,
      borderRadius: theme.palette.borderRadius,
      padding: "8px",
    },
  },
  tray: {
    zIndex: 1000,
    borderTop: "1px solid #ddd",
    width: "330px",
    minHeight: "100px",
    maxHeight: "350px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  buttonWrapper: {
    position: "relative",
    width: "60%",
  },
  button: {
    width: "100%",
  },
  list: {
    maxHeight: "200px",
    overflowY: "auto",
    width: "100%",
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  fileName: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: "300px",
  },
  disabledFileName: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: "300px",
  },
}));
