import { Avatar, Box, Typography, Stack } from "@mui/material";
import React from "react";
import { stringAvatar } from "../../../utils";
import { OutstandingCardStyles } from "./outstandingCardStyle";
import { useNavigate } from "react-router-dom";



export const OutStandingCard = (props) => {
    const classes = OutstandingCardStyles(props);
    const navigate = useNavigate();
    const handleClick = (props) => {
        navigate(props?.data);
    }

    return (
        <>
            <div className={classes.card} style={{ backgroundColor: props?.data?.color ?? "" }} onClick={handleClick}>
                <div className={classes.Cardcontent}>
                    {props?.type === 5 ?
                        <Stack direction="row" spacing={1}>
                            <Box style={{ backgroundColor: props?.data?.borderColor ?? "" }} className={classes.borderHandover}></Box>
                            <Box sx={{ marginInlineStart: "8px!important" }}>
                                <Typography style={{ color: props?.data?.borderColor }} className={classes.handoverTitle} noWrap>{`${props?.data?.totalUnits} Units`}</Typography>
                                <Stack direction="row" spacing={1} alignItems={"center"}>
                                    <Typography style={{ color: props?.data?.borderColor }} className={classes.handoverproperty} noWrap>{props.data.title}</Typography>
                                    <Box className={classes.handoverdot} style={{ backgroundColor: props?.data?.borderColor ?? "" }}></Box>
                                    <Typography style={{ color: props?.data?.borderColor }} className={classes.handoverproperty} noWrap>{props?.data?.location ?? "-"}</Typography>
                                </Stack>
                            </Box>
                        </Stack>
                        :
                        <Box alignItems="center" display="flex">
                            {props?.type === 1 &&
                                <>
                                    {/* {props?.data?.logo && props?.data?.logo ? */}
                                    <Box >
                                        <Avatar src={props?.data?.logo}  {...stringAvatar(props?.data?.title)}></Avatar>
                                    </Box>
                                    {/* <Box >
                                        <Avatar ></Avatar>
                                    </Box>
                                } */}
                                </>
                            }
                            <Box flexGrow={1} marginLeft="10px">
                                <Box alignItems="center" display="flex">
                                    <Box>
                                        <Typography
                                            variant="subtitle2"
                                            className={classes.title}
                                            noWrap
                                        >
                                            {props?.data?.title}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    marginTop="4px"
                                    style={{ flexFlow: "wrap !important" }}
                                    className={classes.boxes}
                                >
                                    <div className={classes.div}>
                                        <Box>
                                            <Typography
                                                variant="subtitle2"
                                                className={classes.sub}
                                                noWrap
                                            >
                                                {props?.data?.location ? props?.data?.location : props?.data?.type}
                                            </Typography>
                                        </Box>
                                    </div>
                                    {props?.type === 2 &&
                                        <>
                                            <div className={classes.div}>
                                                <Box className={classes.dot} />

                                                <Box>
                                                    <Typography
                                                        variant="subtitle2"
                                                        className={classes.sub}
                                                        noWrap
                                                    >
                                                        {props?.data?.totalUnits}
                                                    </Typography>
                                                </Box>
                                            </div>
                                        </>
                                    }
                                    {props?.type === 3 &&
                                        <>
                                            <div className={classes.div}>
                                                <Box className={classes.dot} />

                                                <Box>
                                                    <Typography
                                                        variant="subtitle2"
                                                        className={classes.sub}
                                                        noWrap
                                                    >
                                                        {props?.data?.date}
                                                    </Typography>
                                                </Box>
                                            </div>
                                            <div className={classes.div}>
                                                <Box className={classes.dot} />

                                                <Box>
                                                    <Typography
                                                        variant="subtitle2"
                                                        className={classes.sub}
                                                        noWrap
                                                    >
                                                        {props?.data?.unitNo}
                                                    </Typography>
                                                </Box>
                                            </div>
                                        </>
                                    }
                                </Box>
                            </Box>
                            <Box>
                                {props?.type === 1 &&
                                    <Typography className={classes.amount}>
                                        {props?.data?.sum}
                                    </Typography>
                                }
                                {/* {props?.type === 3 &&
                                <>
                                    <IconButton>
                                        <EditIMG color="#98a0ac" />
                                    </IconButton>
                                    <IconButton>
                                        <RemoveRedEyeOutlinedIcon
                                            sx={{ fontSize:"1rem", color: "#98A0AC" }}
                                        />
                                    </IconButton>
                                </>
                            } */}
                            </Box>
                        </Box>
                    }
                </div>
            </div>
        </>
    );
};
