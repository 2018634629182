import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { useStyles } from './style'
import { TermsAndConditionIcon } from './assets/terms_and_condition_icon'
import { Document } from './assets/document'
import { DialogDrawer } from '../dialogDrawer'
import { Slider } from '../slider'

export const TermsAndCondition = ({ termsCondition = {}, t = () => false }) => {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const [selected, setSelected] = React.useState("")

    const handleClick = (val) => {
        setOpen(!open)
        setSelected(val)
    }

    return (
        <Box>
            <Stack alignItems={"center"} justifyContent={"center"} spacing={2}>
                <TermsAndConditionIcon />
                <Typography className={classes.tcText}>{t("Terms & Conditions")}</Typography>
            </Stack>
            <div dangerouslySetInnerHTML={{
                __html: termsCondition?.terms_conditions,
            }} ></div>
            <Box mt={2}>
                <Grid container>
                    {termsCondition?.docs?.map((val) => {
                        return (
                            <Grid item xs={3.7} className={classes.imgContainer}>
                                {
                                    val?.asset_type === 4 || val?.file_meta?.type === "pdf" ?
                                        <Stack>
                                            <Stack rowGap={1} onClick={() => handleClick(val)} className={classes.detailsBox}>
                                                <Document />
                                                <Typography className={classes.pdfText}>PDF</Typography>
                                            </Stack>
                                            <Box sx={{ width: "100%", display: 'flex', justifyContent: "center", marginTop: "6px" }}>
                                                <Typography className={classes.pdfName}>{val?.file_meta?.name ?? "-"}</Typography>
                                            </Box>
                                        </Stack>
                                        :
                                        <div
                                            style={{ position: "relative", cursor: "pointer" }}
                                            onClick={() => handleClick(val)}
                                        >
                                            <img
                                                type="file"
                                                accept="image/*"
                                                multiple
                                                alt="Not Found"
                                                width={"100%"}
                                                src={val?.url}
                                                className={classes.img}

                                            />
                                        </div>
                                }


                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

            <DialogDrawer
                open={open}
                onClose={() => setOpen(!open)}
                header={t("View Document")}
                maxWidth="sm"
                height="530px"
                component={
                    <Box>
                        <Slider assets={selected} uploadImage />
                    </Box>
                }
            />
        </Box>
    )
}