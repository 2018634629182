import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// import { Assests } from './tabs/assests'
import CityProperty from '../../assets/cityproperty';
import ImagesProperty from '../../assets/imagesproperty';
import { PropertyTabs, Subheader } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from '../../networkcall';
import { accessCheckRender, AlertProps, allowed_file_size, deleteS3File, NetWorkCallMethods } from "../../utils";
import { Assests } from '../propertcreation/createproperty/assests';
import { BlockDetails } from './tabs/blockDetails';
import { useStylesCreation } from "./tabs/style";
import { mapResult, singleFileUpload, assestType, getRoutePermissionNew, getCustomBlockName } from '../../utils/common';
import moment from "moment";
import { withTranslation } from "react-i18next";

const CreateBlock = (props) => {
      const { t } = (props)
      const initial = {
            propertyID: "",
            blockID: "",
            image: "",
            company: "",
            blockName: "",
            blockType: "",
            status: { value: true, label: "Active" },
            description: "",
            propertyType: "",
            blockPurpose: "",
            revenueType: "",
            measurementUnit: "",
            isFloor: false,
            doorNo: "",
            addressLineOne: "",
            addressLineTwo: "",
            landmark: "",
            area: "",
            city: "",
            state: "",
            country: "",
            zipcode: "",
            latitude: "",
            longitude: "",
            businessPhone: "",
            mobilePhone: "",
            website: "",
            emailAddress: "",
            built: '',
            handover: '',
            property_hierarchy: "",
            error: {
                  image: "",
                  company: "",
                  blockName: "",
                  blockType: "",
                  propertyType: "",
                  blockPurpose: "",
                  revenueType: "",
                  measurementUnit: "",
                  businessPhone: "",
                  mobilePhone: "",
                  website: "",
                  emailAddress: "",
                  built: '',
                  handover: '',
            }
      }
      const location = useLocation();
      const [data, setData] = React.useState({ ...initial })
      const backdrop = React.useContext(BackdropContext);
      const [value, setValue] = React.useState(1);
      const [images, setImages] = React.useState([]);
      const alert = React.useContext(AlertContext);
      const auth = React.useContext(AuthContext)
      const [disable, setDisable] = React.useState(true);
      const navigate = useNavigate();
      const classes = useStylesCreation();
      const [deletedAssetURL, setDeletedAssetURL] = React.useState([]);
      const main = location?.state?.main ?? null;
      const [mapLoad, setMapLoad] = React.useState(false)
      const [load, setLoad] = React.useState(false)
      const [permission, setPermission] = React.useState({})
      const [deleted_s3_urls, set_deleted_s3_urls] = React.useState([]);

      // use effect to get permission
      React.useEffect(() => {
            const perm = getRoutePermissionNew(auth)
            if (perm) {
                  setPermission(perm)
                  if (perm?.read) {
                        if (main?.blockID) {
                              getBlockDataEdit();
                        } else if (main?.propertyID) {
                              getPropertyDetailsByPropertyID()
                        }
                  }
            }
            // eslint-disable-next-line
      }, [auth]);

      const getBlockDataEdit = () => {
            backdrop.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: "Loading",
            });
            const payload = { blockId: main?.blockID };

            NetworkCall(
                  `${config?.api_url}/queries/block/get`,
                  NetWorkCallMethods.post, payload, null, true, false
            ).then((response) => {
                  let editDetails = response?.data?.data?.block?.[0] ?? null;
                  setImages(response?.data?.data?.assets);
                  setData({
                        ...data,
                        propertyID: editDetails?.property_id,
                        blockID: editDetails?.id,
                        image: editDetails?.logo,
                        property_hierarchy: editDetails?.propertyByID?.property_hierarchyByID,
                        company: editDetails?.propertyByID?.companyByID ?? "",
                        blockName: editDetails?.name ?? "",
                        blockType: editDetails?.block_type_masterByID ?? "",
                        status: { value: editDetails?.is_active ? true : false, label: editDetails?.is_active ? "Active" : "In-Active" },
                        description: editDetails?.description ?? "",
                        propertyType: editDetails?.propertyByID?.property_groupByID ?? "",
                        blockPurpose: editDetails?.block_purpose ?
                              { value: editDetails?.block_purpose, label: editDetails?.block_purpose } : "",
                        revenueType: editDetails?.revenue_type ?
                              { value: editDetails?.revenue_type, label: editDetails?.revenue_type } : "",
                        measurementUnit: editDetails?.uom_masterByID ?? "",
                        isFloor: editDetails?.use_floor_hierarchy ? true : false,
                        doorNo: editDetails?.addressByID?.door_no ?? "",
                        addressLineOne: editDetails?.addressByID?.street_1 ?? "",
                        addressLineTwo: editDetails?.addressByID?.street_2 ?? "",
                        landmark: editDetails?.addressByID?.landmark ?? "",
                        area: editDetails?.addressByID?.area ?? "",
                        city: editDetails?.addressByID?.city ?? "",
                        state: editDetails?.addressByID?.state ?? "",
                        country: editDetails?.addressByID?.country ?? "",
                        zipcode: editDetails?.addressByID?.zipcode ?? "",
                        latitude: parseFloat(editDetails?.addressByID?.latitude) ?? "",
                        longitude: parseFloat(editDetails?.addressByID?.longitude) ?? "",
                        businessPhone: {
                              mobile: editDetails?.addressByID?.mobile_no ?? "",
                              mobile_code: editDetails?.addressByID?.mobile_no_country_code ?? "",
                        },
                        mobilePhone: {
                              mobile: editDetails?.addressByID?.alternative_mobile_no ?? "",
                              mobile_code: editDetails?.addressByID?.alternative_mobile_no_country_code ?? "+91",
                        },
                        website: editDetails?.propertyByID?.website ?? "",
                        emailAddress: editDetails?.addressByID?.email_id ?? "",
                        built: new Date(editDetails?.year_built) ?? "",
                        handover: new Date(editDetails?.planned_hand_over_date) ?? "",
                        error: initial?.error,
                  })
                  setLoad(true)
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            }).catch((err) => {

                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Something went wrong please try again"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center
                  });

                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            });
      };

      const getPropertyDetailsByPropertyID = () => {
            backdrop.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: t("Loading"),
            });

            const payload = { propertyID: main?.propertyID };

            NetworkCall(
                  `${config?.api_url}/queries/property/get`,
                  NetWorkCallMethods?.post, payload, null, true, false
            ).then((response) => {
                  const tempProperty = response?.data?.data?.property?.[0] ?? null;

                  if (tempProperty?.property_hierarchyByID?.is_block) {
                        setData({
                              ...data,
                              company: tempProperty?.companyByID ?? "",
                              propertyID: tempProperty?.id,
                              image: tempProperty?.logo,
                              property_hierarchy: tempProperty?.property_hierarchyByID,
                              blockID: null,
                              propertyType: tempProperty?.property_groupByID ?? "",
                              blockPurpose: tempProperty?.property_purpose ?
                                    { value: tempProperty?.property_purpose ?? "", label: tempProperty?.property_purpose ?? "" } : "",
                              revenueType: tempProperty?.revenue_type ?
                                    { value: tempProperty?.revenue_type ?? "", label: tempProperty?.revenue_type ?? "" } : "",
                              measurementUnit: tempProperty?.uom_masterByID ?? "",
                              doorNo: tempProperty?.addressByID?.door_no ?? "",
                              addressLineOne: tempProperty?.addressByID?.street_1 ?? "",
                              addressLineTwo: tempProperty?.addressByID?.street_2 ?? "",
                              landmark: tempProperty?.addressByID?.landmark ?? "",
                              area: tempProperty?.addressByID?.area ?? "",
                              city: tempProperty?.addressByID?.city ?? "",
                              state: tempProperty?.addressByID?.state ?? "",
                              country: tempProperty?.addressByID?.country ?? "",
                              zipcode: tempProperty?.addressByID?.zipcode ?? "",
                              latitude: parseFloat(tempProperty?.addressByID?.latitude) ?? "",
                              longitude: parseFloat(tempProperty?.addressByID?.longitude) ?? "",
                              businessPhone: {
                                    mobile: tempProperty?.business_phone ?? "",
                                    mobile_code: tempProperty?.business_phone_country_code ?? "",
                              },
                              mobilePhone: {
                                    mobile: tempProperty?.mobile_phone ?? "",
                                    mobile_code: tempProperty?.mobile_country_code ?? "",
                              },
                              website: tempProperty?.website ?? "",
                              emailAddress: tempProperty?.email ?? "",
                              built: new Date(tempProperty?.year_built) ?? "",
                              handover: new Date(tempProperty?.planned_hand_over_date) ?? "",
                              error: initial?.error,
                        })
                        setLoad(true)
                  } else {
                        alert.setSnack({
                              ...alert, open: true, severity: AlertProps.severity.info,
                              msg: getCustomBlockName(tempProperty?.property_hierarchyByID) + t(" creation is restricted."),
                        });
                        navigate(-1)
                  }
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            }).catch((err) => {

                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Something went wrong please try again"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center
                  });

                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            });
      }

      // const handleChange = (event, newValue) => {
      //       setValue(newValue);
      // };

      const updateState = (k, v) => {
            let error = data?.error;
            error[k] = "";
            if (k === "revenueType") {
                  setData({ ...data, [k]: v, handover: "", error });
            } else { setData({ ...data, [k]: v, error }); }
      }

      const validate = () => {
            let isValid = true;
            let error = data.error;

            if (data?.company?.length === 0) { isValid = false; error.company = t("Company Name is Required"); }
            if (data?.blockName?.length === 0) {
                  isValid = false;
                  error.blockName = getCustomBlockName(data?.property_hierarchy) + " " + t("Name is Required");
            }
            if (data?.propertyType?.length === 0) { isValid = false; error.propertyType = t("Property Type is Required"); }
            if (data?.blockType?.length === 0) { isValid = false; error.blockType = t("Block Type is Required"); }
            if (data?.blockPurpose?.length === 0) {
                  isValid = false;
                  error.blockPurpose = getCustomBlockName(data?.property_hierarchy) + t(" Purpose is Required");
            }
            if (data?.revenueType?.length === 0) { isValid = false; error.revenueType = t("Revenue Type is Required"); }
            if (data?.measurementUnit?.length === 0) { isValid = false; error.measurementUnit = t("Measurement Unit is Required"); }
            if (data?.built?.length === 0) {
                  isValid = false;
                  error.built = t("Year Built is Required");
            }
            if (data?.revenueType === "Sale" && data?.handover?.length === 0) {
                  isValid = false;
                  error.handover = t("Handover Date is Required");
            }
            if (data?.businessPhone?.length === 0) { isValid = false; error.businessPhone = t("Business Phone is Required"); }
            if (data?.mobilePhone?.length === 0) { isValid = false; error.mobilePhone = t("Mobile Phone is Required"); }
            if (data?.website?.length === 0) { isValid = false; error.website = t("Website is Required"); }
            if (data?.emailAddress?.length === 0) { isValid = false; error.emailAddress = t("Email Address is Required"); }
            if (data?.mobile?.length === 0) { isValid = false; error.mobile = t("Mobile Phone is Required"); }
            if (data?.image?.length === 0) {
                  isValid = false;
                  error.image = getCustomBlockName(data?.property_hierarchy) + t(" Image is Required");
            }

            if (!isValid) {
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Please fill all mandatory field"),
                  });
            }
            setData({ ...data, error });
            return isValid;
      };


      const mapResultData = (map) => {
            let result = mapResult(map);
            setData({ ...data, ...result })
      }

      const updateimg = async (image) => {
            backdrop.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: t("Loading"),
            });
            try {
                  const uploaded_file = await singleFileUpload(image, { companyId: data?.company?.value, type: assestType?.Thumbnails }, alert, allowed_file_size)
                  if (uploaded_file?.[0]?.url) {
                        updateState("image", uploaded_file?.[0]?.url)
                        backdrop.setBackDrop({
                              ...backdrop,
                              open: false,
                              message: "",
                        });
                  } else {
                        backdrop.setBackDrop({
                              ...backdrop,
                              open: false,
                              message: "",
                        });
                  }
            }
            catch (err) {
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: t("Some Thing Went Wrong"),
                  });
            }

      }


      const next = () => {
            if (value === 1) {
                  if (validate()) {
                        setDisable(false);
                        setValue(2)
                  } else {
                        return false;
                  }
            }
      };
      const previous = () => {
            setValue(prevValue => prevValue - 1);
            if (!main?.blockID) {
                  setMapLoad(false)
            }
      };
      const removeimg = () => {
            set_deleted_s3_urls([...deleted_s3_urls, data?.image]);
            setData({ ...data, image: "" })
      }
      const handleAssetFile = (data) => {
            if (data.asset_type === 3) {
                  setDeletedAssetURL([...deletedAssetURL, data.id].filter(Boolean));
                  setImages(images?.filter((val) => val?.file_meta?.id !== data?.file_meta?.id));
            } else {
                  set_deleted_s3_urls([...deleted_s3_urls, data?.url]);
                  setDeletedAssetURL([...deletedAssetURL, data.id].filter(Boolean));
                  setImages(images?.filter((val) => val?.url !== data?.url));
            }
      };
      const tabTittle = [
            {
                  label: getCustomBlockName(data?.property_hierarchy) + " " + t(" Details"),
                  sub: t("Enter ") + " " + getCustomBlockName(data?.property_hierarchy) + " " + t(", Address & Contact Details"),
                  imgSelect: value === 2 ? <img src="/images/correct_icon.svg" alt='icon' /> : <CityProperty color={value === 1 ? '#5078E1' : '#98A0AC'} />,
                  value: 1,
                  className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
                  selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
                  subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
            },
            {
                  label: t("Attachments"),
                  sub: t("Upload assets like Image, 360 Image, Vid.."),
                  imgSelect: <ImagesProperty color={value === 2 ? '#5078E1' : '#98A0AC'} />,
                  value: 2,
                  className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
                  selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
                  subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
            },
      ]

      const save = () => {    
            deleteS3File({ urls: deleted_s3_urls });

            if (validate()) {
                  const payload = {
                        tenantId: `${config.tenantId}`,
                        name: data.blockName ?? null,
                        logo: data?.image ?? null,
                        description: data.description ?? null,
                        block_type: data.blockType.value ?? null,
                        block_purpose: data.blockPurpose.value ?? null,
                        company_id: data.company.value ?? null,
                        revenue_type: data.revenueType.value ?? null,
                        uom_id: data.measurementUnit.value ?? null,
                        use_floor_hierarchy: data.isFloor ? true : false,
                        door_no: data.doorNo,
                        street_1: data.addressLineOne,
                        street_2: data.addressLineTwo,
                        landmark: data.landmark,
                        area: data.area,
                        locality: data?.area,
                        district: data?.city,
                        city: data.city,
                        state: data.state,
                        country: data.country,
                        zipcode: data.zipcode,
                        business_phone: data?.businessPhone?.mobile,
                        mobile_phone: data?.mobilePhone?.mobile,
                        business_phone_country_code: data?.businessPhone?.mobile_code ?? "+91",
                        mobile_country_code: data?.mobilePhone?.mobile_code,
                        website: data?.website,
                        email: data?.emailAddress,
                        latitude: data.latitude ?? null,
                        longitude: data.longitude ?? null,
                        assetsData: data?.blockID
                              ? images.filter((val) => !val?.id)
                              : images,
                        block_id: data?.blockID ?? null,
                        is_active: data?.status?.value ? true : false,
                        property_id: data?.propertyID ?? null,
                        year_built: data?.built ? moment(data?.built).format("yyyy-MM-DD") : null,
                        planned_hand_over_date: (data?.revenueType?.value === "Sale" && data?.handover) ? moment(data?.handover).format("yyyy-MM-DD") : null,
                        deleteAssets: deletedAssetURL,
                  };
                  NetworkCall(
                        `${config.api_url}/block/${data?.blockID ? `update` : `create`}`,
                        NetWorkCallMethods.post,
                        payload,
                        null,
                        true,
                        false
                  )
                        .then((response) => {
                              alert.setSnack({
                                    ...alert,
                                    open: true,
                                    severity: AlertProps.severity.success,
                                    msg: `${main?.blockID ? (getCustomBlockName(data?.property_hierarchy) + " " + t(" Updated Successfully!!!")) : (getCustomBlockName(data?.property_hierarchy) +" " + t(" Added Successfully!!!"))}`,
                                    vertical: AlertProps.vertical.top,
                                    horizontal: AlertProps.horizontal.center,
                              });
                              navigate(-1);
                        })
                        .catch((error) => {
                              alert.setSnack({
                                    ...alert,
                                    open: true,
                                    severity: AlertProps.severity.error,
                                    msg: t("Some Thing Went Wrong"),
                                    vertical: AlertProps.vertical.top,
                                    horizontal: AlertProps.horizontal.center,
                              });
                              setDisable(false);
                        });
            }
      };

      const checkSave = (type) => {
            setDisable(true);
            if (main?.blockID) {
                  if (permission?.update) {
                        type === "previous" && previous()
                        type === "next" && next()
                        type === "save" && save()
                  } else {
                        alert.setSnack({
                              ...alert, open: true, msg: t("Access denied. Contact your administrator."),
                              severity: AlertProps.severity.error,
                        })
                  }
            } else if (permission?.create) {
                  type === "previous" && previous()
                  type === "next" && next()
                  type === "save" && save()
            } else {
                  alert.setSnack({
                        ...alert, open: true, msg: t("Access denied. Contact your administrator."),
                        severity: AlertProps.severity.error,
                  })
            }
      }

      const render = () => {
            return <>
                  <Subheader
                        title={getCustomBlockName(data?.property_hierarchy) + t(" Add/Edit")}
                        goBack={() => { navigate(-1) }}
                        previousAndNextBtn={true}
                        previousBtn={() => checkSave("previous")}
                        nextBtn={() => checkSave("next")}
                        previousBtnDisabled={value <= 1 && true}
                        nextBtnDisabled={value === 2 ? true : false}
                  />
                  <PropertyTabs value={value} isHeigth
                        t={t}
                        tab1={<BlockDetails t={t} id='pushTitle' load={load} data={data} updateState={updateState} updateimg={updateimg} mapResult={mapResultData} removeimg={removeimg} mapLoad={mapLoad} />}
                        tab2={<Assests imageList={images} updateImg={setImages} handleAssetFile={handleAssetFile} companyId={data?.company?.value ?? ""} t={t} />}
                        tabTittle={tabTittle}
                        showBtns={true}
                        isSaveDisable={disable}
                        saveBtnText={main?.blockID ? t("Update") : t("Submit")}
                        save={() => checkSave("save")}
                  />
            </>
      }

      return (
            <div style={{ position: 'relative' }}>
                  {accessCheckRender(render, permission)}
            </div>
      )
}
export default withTranslation("createBlock")(CreateBlock)
