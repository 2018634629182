import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  tabtitle: {
    fontSize:"0.75rem",
    color: theme?.typography?.color?.secondary,
    fontFamily: FontFamilySwitch().bold,
  },
  tabtitle1: {
    fontSize:"0.75rem",
    color: theme?.typography?.status?.primary,
    fontFamily: FontFamilySwitch().bold,
  },
  selectBack1: {
    backgroundColor: theme?.palette?.info?.light,
    display: 'flex',
    alignItems: 'center',
    padding: '19px 16px',
    position: 'relative'
  },
  selectBack: {
    display: 'flex',
    alignItems: 'center',
    padding: '19px 16px',
    position: 'relative'
  },
  root: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    margin: "16px",
    overflow: "auto",
  },
  closeDialog: {
    "& .MuiPaper-root": {
      maxWidth: "400px !important",
      width: "100% !important",
      borderRadius: theme.palette.borderRadius,
    },
  },
  root1: {
    borderRadius: theme.palette.borderRadius,
    // boxShadow: "0px 0px 16px #00000014",
    // backgroundColor: "white",
    height: `calc(100vh - 180px)`,
    overflow: "auto",
    padding: '16px'
  },
  avatar: {
    position: "absolute",
    top: 20,
    left: -16,
  },
  avatar0: {
    position: "absolute",
    top: 20,
    left: -18,
    backgroundColor: "#FFF4EB",
  },
  avatars: {
    position: "absolute",
    top: 20,
    left: -18
  },
  avatar1: {
    position: "absolute",
    top: 20,
    left: -18,
    height: "34px",
    width: "34px",
    backgroundColor: "#FFECEC",
  },
  assigned: {
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize:"0.75rem",
    marginTop: "6px",
  },
  reqblock: {
    backgroundColor: "#5078E1",
    backgroundImage: `url(${"/images/requestView.svg"})`,
    height: "250px"
  },
  showComplete: {
    backgroundColor: "#5078E1",
    backgroundImage: `url(${"/images/requestComplete.svg"})`,
    height: "250px"
  },
  closeicon: {
    color: "white",
    cursor: "pointer"
  },
  q1hdr: {
    color: "white",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize:"0.875rem",
    marginLeft: "15px"
  },
  q1subhdr: {
    color: "white",
    fontFamily: FontFamilySwitch().regular,
    fontSize:"0.75rem",
    marginLeft: "15px"
  },
  yesbtn: {
    backgroundColor: "white",
    color: "#5078E1",
    "&:hover": {
      backgroundColor: "white",
      color: "#5078E1",
    }
  },
  nobtn: {
    color: "white",
    border: "1px solid #E4E8EE",
    "&:hover": {
      color: "white",
      border: "1px solid white",

    },
    marginInlineStart:"8px"
  },
  cancelbtn: {
    "&:hover": {
      backgroundColor: "white"

    }
  }
}));
