import React from 'react'
import { withNavBars } from '../../HOCs';
import styled from '@mui/material/styles/styled';
import { AlertDialog, ContactList, DialogBox, IframeViwer, QuotationContactCreate, QuotationSummery, Subheader, TemplateGenerator } from '../../components';
import { Box, Button, Divider, Drawer, Grid, IconButton, Snackbar, Stack, Typography } from '@mui/material';
import { ResidentOnboardViewEditStyle } from './style';
import SendMailIcon from './utils/sendMailIcon';
import DownloadIcon from './utils/downloadIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import { NetworkCall } from "../../networkcall";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, accessCheckRender, getRoutePermissionNew, getTotalArray, timeZoneConverter } from "../../utils";
import { config } from '../../config';
import { QuotationDetail, QuotationVaersionCard, UnitSingleDetails } from '../quotationDetails/components';
import { ApprovedWhite } from '../../assets';
import CloseIcon from '@mui/icons-material/Close';
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NewLoader } from '../../components/newLoader';
import { UnitList } from '../quotationDetails/components'
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import CustomizeQuote from '../customizeQUote/customizeQuote';
import { getComponentValue } from '../freeTextInvoice/utlis/freeTextInvoice';
import { Routes } from '../../router/routes';
import { HoleNumber, filterValues, percentageOfAmount } from '../customizeQUote/function';


const CustomPaper = styled('div')(({ theme }) => ({
  borderRadius: "12px",
  boxShadow: "0px 0px 16px #00000014",
  backgroundColor: "white",
  height: `calc(100vh - 140px)`,
  overflow: "auto",
  margin: "10px"
}))

const ResidentOnboardViewEdit = ({
  t
}) => {
  const { state } = useLocation();
  const navigate = useNavigate()
  const auth = React.useContext(AuthContext);
  const classes = ResidentOnboardViewEditStyle()
  const [open, setOpen] = React.useState(false);
  const [template, setTemplate] = React.useState({
    bool: false,
    type: ""
  });
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const [permission, setPermission] = React.useState({})
  const [selectedAcc, setSelectedAcc] = React.useState(null);
  const [details, setDetails] = React.useState({
    details: null,
    unit: [],
    totalAmount: 0,
    totalrefundableTax: 0,
    totalTax: 0,
    totalDiscount: 0,
    unit_details: {},
    unitDetails: [],
    quott: null
  })

  const [openpdf, setOpenpdf] = React.useState(false);
  const [show, setShow] = React.useState(true)
  const [openUnitDetails, setOpenUnitDetails] = React.useState(false);
  const [selectedUnit, setSelectedUnit] = React.useState("")
  const [pdf, setPdf] = React.useState({
    bool: false,
    data: ""
  })
  const [primary, setPrimary] = React.useState(true)
  const [custom, setCustom] = React.useState({
    type: false,
    already: false
  })

  // drawer function
  const [states, setStates] = React.useState({
    right: false,
  });


  // --------------------FUNCTIONS-----------------------
  const toggleDrawer = () => {
    setStates({ ...states, right: !states?.right });
  };




  const getAgreementDetails = async () => {

    const payload = {
      quotation_id: state?.main,
      is_quote: true,
      is_primary: true,
    }

    let unit_details = await NetworkCall(
      `${config.api_url}/quotation/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
    let result = unit_details?.data?.quotation?.map((val) => {
      return {
        ...val,
        id: val?.id,
        is_open: false,
        name: val?.name,
        unit_no: val?.unitNo,
        total_area: val?.totalarea,
        area_metric: val?.areaMetric,
        total_bed_rooms: val?.totalbedroom,
        total_baths: val?.baths,
        unit_type: val?.unitType,
        discount: 0,
        discountValue: 0,
        room_rent: val?.room_rent ?? 0,
        url: val?.logo,
        pricing: val?.pricing?.map((c) => {
          return {
            ...c,
            "unitid": val?.id,
            "primary": c?.primary ?? false,
            "refundable": c?.refundable ?? false,
            "isActive": true,
            "taxable": c?.taxable ?? false,
            "isChargeable": true,
            "isSecurity": true,
            "propertyId": val?.property?.property_id,
            "companyId": c?.company_id,
            "isOnetime": c?.isOnetime ?? false,
            beforeTax: c?.rent_amount,
            pricing_components: !!c?.inspection ? c?.inspection : c?.component,
            discountType: { label: "%", value: "%" },
            inspection: !!c?.inspection ? { category: c?.inspection?.category, type: { value: c?.inspection?.item, label: c?.inspection?.item } } : null,
            discountedPrice: c?.displayPercentage ?? 0,
            discountValue: c?.displayPercentage ?? 0,
            fixTax: c?.before_tax,
            refundableAmount: 0,
            vat_id: c?.vatGroup?.VGMid,
            componentType: getComponentValue(c?.component, c?.is_wallet_item),
            subtotal: Number(c?.rent_amount ?? 0) + Number(c?.tax ?? 0),
            componentvalues: c?.value,
            componentvalue: c?.value,
            quantity: !!c?.quantity ? c?.quantity : 1,
            tax: c?.tax ?? 0,
            taxAmountValue: Number(c?.before_tax) ?? 0,
            amountWithoutDiscount: c?.amount_without_discount,
            is_active: true,
            isQuantity: !!c?.quantity ? true : false,
            uom_type: c?.uom_type
          }
        }),
        total: val?.total,
        totalDiscount: 0,
        totalAmount: val?.totalPricing,
        fixTotal: val?.totalPricing,
        totalrefundableTax: val?.totalrefundableTax,
        totalDiscountValue: null,
        discountPrice: 0,
        discountedPrice: 0,
        tax: 0,
        blockid: val?.blockid,
        propertyid: val?.propertyid,
        floorid: val?.floorid,

      }

    })
    setDetails({
      details: unit_details?.data?.quotationDetails[0],
      unit: unit_details?.data?.units,
      unitDetails: result,
      quott: unit_details.data.quotationDetails[0],
      is_primary: unit_details?.data?.is_primary,
      totalAmount: unit_details?.data?.quotationDetails[0]?.total_amount,
      totalDiscount: unit_details?.data?.quotationDetails[0]?.total_discount,
      totalrefundableTax: unit_details?.data?.quotationDetails[0]?.total_refundable,
      totalTax: unit_details?.data?.quotationDetails[0]?.total_tax,
    })
    setSelectedAcc(unit_details?.data?.quotationDetails[0]?.account_id ? {
      "id": unit_details?.data?.quotationDetails[0]?.account_id,
      "url": unit_details?.data?.quotationDetails[0]?.image_url,
      "name": unit_details?.data?.quotationDetails[0]?.account_name,
      "label": unit_details?.data?.quotationDetails[0]?.account_name,
      "value": unit_details?.data?.quotationDetails[0]?.account_id,
      "value1": unit_details?.data?.quotationDetails[0]?.account_mobile_no_country_code,
      "account_no": unit_details?.data?.quotationDetails[0]?.account_mobile_no_country_code,
      "mobile_no_country_code": unit_details?.data?.quotationDetails[0]?.account_mobile_no_country_code
    } : null)




    await setShow(false)


  }
  //send quotation
  const sendQuotation = (value) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "...Sending",
    });
    setTemplate({
      bool: false,
      type: "view"
    })
    const payload = value

    NetworkCall(
      `${config.api_url}/quotation/send_invite`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setOpen(true);
        getAgreementDetails()
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      }).catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        console.log(err)
      })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  // create agreement 
  const createAgreementFunction = () => {
    checkDetails()

  }



  const checkDetails = () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const payload = {
      vacant: ["Vacant", "Reserved"],
      offset: 0,
      limit: 10,
      revenue_type: details?.details?.revenue_type,
      lease_start_date:
        details?.details?.revenue_type !== "Sale"
          ? `${moment(details?.details?.lease_start_date).format("YYYY-MM-DD")} 00:00:00` ?? undefined
          : undefined,
      lease_end_date:
        details?.details?.revenue_type !== "Sale"
          ? `${moment(details?.details?.lease_end_date).format("YYYY-MM-DD")} 23:59:59` ?? undefined
          : undefined,
      units: details?.unit?.map((x) => x?.unit_id)
    };
    NetworkCall(
      `${config.api_url}/opportunity/units`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        if (res?.data?.count > 0) {
          setStates({ ...states, right: true });
        }
        else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("The unit(s) are already occupied"),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        }


      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      });
  };

  //on click unit card
  const onClickUnit = (unit_id) => {
    setSelectedUnit(unit_id)
    setOpenUnitDetails(true)
  }

  const generate = async (value) => {
    if (template?.type === "send") return sendQuotation({
      quotation_id: state?.main,
      type_master_id: value?.value
    })
    setOpenpdf(true)
    await backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "...Loading",
    });
    const payload = {
      id: state?.main,
      type_master_id: value?.value

    };
    await NetworkCall(
      `${config.api_url}/quotation/get_quotation_pdf`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setPdf({
          bool: true,
          data: res?.data
        })
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "some thing went wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  }

  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        if (!!state) {
          getAgreementDetails()

        }

      }
    }
    // eslint-disable-next-line 
  }, [auth])

  const checkPrimary = async (id, account_id) => {
    if (!account_id) {
      return setPrimary(true)
    }
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const payload = {
      contact_id: id,
      account_id: account_id
    };
    await NetworkCall(
      `${config.api_url}/contact/check_primary`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setPrimary(res?.data?.data)
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("some thing went wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  }
  const onSumbit = (e, dataNew) => {
    // setCustom({
    //     ...custom,
    //     type: !custom?.type
    // })
    const units = e?.map((x) => {
      return {
        ...x,
        totalDiscount: getTotalArray(x?.pricing?.filter(item => !filterValues.includes(item?.componentType?.value))
          ?.filter((x) => x?.is_active === true), 'subtotal'),
        room_rent: getTotalArray(x?.pricing?.filter(item => !filterValues.includes(item?.componentType?.value))
          ?.filter((x) => x?.is_active === true), 'subtotal'),
        pricing: x?.pricing?.map((v) => {
          return {
            ...v,
            PCid: v?.pricing_components?.PCid || v?.pricing_components?.value,
            PCname: v?.pricing_components?.PCname || v?.pricing_components?.label,
            rent_breakup_id: v?.pricing_components?.PCid || v?.pricing_components?.value,
            unit_id: x?.id ?? "",
            component_value: Number(v?.quantity ?? 0) * Number(v?.componentvalues ?? 0),
            amount: Number(!!v?.componentvalues ? v?.componentvalues : 0),
            beforeTax: !!v?.beforeTax ? Number(v?.beforeTax) : 0,
            companyId: !!v?.companyId ? v?.companyId : JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))?.value,
            currencyId: !!v?.currencyId ? v?.currencyId : JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))?.currency_id,
            currencyName: !!v?.currencyName ? v?.currencyName : JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))?.code,
            currencySymbol: !!v?.currencySymbol ? v?.currencySymbol : JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))?.currency_symbol,
            subtotal: !!v?.subtotal ? Number(v?.subtotal) : 0,
            tax: !!v?.tax ? Number(v?.tax) : 0,
            value: !!v?.componentvalues ? Number(v?.componentvalues) : 0,
            vatGroupId: !!v?.vatGroup?.value ? v?.vatGroup?.value : null,
            unitid: !!v?.unit_id ? v?.unit_id : x?.id,
            vat_id: !!v?.vat_id ? v?.vat_id : v?.vatGroup?.value ?? null,
            fixTax: Number(v?.fixTax)
          }
        })
      }
    })

    let totalAmount = units
      ?.map((i) => i?.pricing)
      .flat()
      ?.filter(item => !filterValues.includes(item?.componentType?.value))
      ?.filter((x) => x?.is_active === true)
      .map((item) => Number(item?.amountWithoutDiscount))
      .reduce((a, b) => a + b, 0);

    let totalrefundableTax = units
      ?.map((i) => i?.pricing)
      .flat()
      ?.filter(item => !filterValues.includes(item?.componentType?.value))
      ?.filter((x) => x?.is_active === true)
      .filter((i) => i?.refundable === true)
      .map((item) => Number(item?.beforeTax))
      .reduce((a, b) => a + b, 0);

    let totalTax = units
      ?.map((i) => i?.pricing)
      .flat()
      ?.filter(item => !filterValues.includes(item?.componentType?.value))
      ?.filter((x) => x?.is_active === true)
      .map((item) => Number(item?.taxAmountValue))
      .reduce((a, b) => a + b, 0);

    let totalTaxwithDiscount = units
      ?.map((i) => i?.pricing)
      .flat()
      ?.filter(item => !filterValues.includes(item?.componentType?.value))
      ?.filter((x) => x?.is_active === true)
      .map((item) => Number(item?.tax))
      .reduce((a, b) => a + b, 0);

    let totalAmountwithDiscount = units
      ?.map((i) => i?.pricing)
      .flat()
      ?.filter(item => !filterValues.includes(item?.componentType?.value))
      ?.filter((x) => x?.is_active === true)
      .map((item) => Number(item?.beforeTax))
      .reduce((a, b) => a + b, 0);

    let totalDiscount = (totalAmount + totalTax) - (totalAmountwithDiscount + totalTaxwithDiscount)

    let milestone_item = []

    if (details?.quott?.revenue_type === "Sale" && !!details?.quott?.milestone_template_master_id) {
      if (details?.quott?.template_type === "amount") {
        milestone_item = dataNew?.map((x, i) => {
          return {
            milestone_item_id: x?.id,
            value: i === 0 ? x.percentage_value ? Number(x?.percentage_value) : 0 : x.percentage ? Number(x?.percentage) : 0,
            value_type: i === 0 ? "amount" : "percentage",
            due_date: x?.payment_date
          }
        })
      }
      if (details?.quott?.template_type === "variable") {
        milestone_item = dataNew.map((x) => {
          return {
            milestone_item_id: x?.id,
            value: x?.percentage_value ? Number(x?.percentage_value) : 0,
            value_type: x?.value_type,
            due_date: x?.payment_date
          }
        })
      }
      if (details?.quott?.template_type === "percentage") {
        milestone_item = dataNew?.map((x, i) => {
          return {
            milestone_item_id: x?.id,
            value: x.percentage ? Number(x?.percentage) : 0,
            value_type: "percentage",
            due_date: x?.payment_date
          }
        })
      }
    }

    // console.log(units, 'units')

    totalAmount = HoleNumber(totalAmount)
    totalrefundableTax = HoleNumber(totalrefundableTax)
    totalTax = HoleNumber(totalTax)
    totalDiscount = HoleNumber(totalDiscount)
    totalTaxwithDiscount = HoleNumber(totalTaxwithDiscount)
    totalAmountwithDiscount = HoleNumber(totalAmountwithDiscount)

    setDetails({
      ...details,
      unitDetails: units,
      totalAmount: totalAmount,
      totalDiscount: totalDiscount,
      totalrefundableTax: totalrefundableTax,
      totalTax: totalTax,
    })

    onSumbitCustomize(units, { totalAmount, totalrefundableTax, totalTax, milestone_item, totalDiscount, totalTaxwithDiscount, totalAmountwithDiscount })
  }

  const onSumbitCustomize = (unit_details, value) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const e = unit_details?.map((x) => {
      return {
        ...x,
        pricing: x?.pricing?.map((v) => {
          return {
            ...v,
            discountValue:
              v?.discountType?.value === "%" ?
                Number(v?.discountValue ?? 0) :
                percentageOfAmount(v?.discountValue, v?.amountWithoutDiscount) + percentageOfAmount(v?.discountValue, v?.taxAmountValue)
          }
        })

      }
    })

    let discoutValue = e?.map(i => i?.pricing).flat().map(val => Number(val?.discountValue)).reduce((a, b) => a + b, 0)

    let units = e?.map((val) => {
      return {
        "unit_id": val?.id ?? val?.unitid,
        "unit_total_primary": val?.totalDiscount,
        "unit_total_refundable": val?.unit_total_refundable,
        "unit_total_others": null,
        "total_rent_breakup": null,
        "total_rent_discounts": null,
        "total_rent_security_deposit": null,
        "total_rent_tax": null,
        "total_rent_extended": null,
        "area_metric": val?.areaMetric,
        "property_id": val?.propertyid,
        "block_id": val?.blockid,
        "floor_id": val?.floorid
      }
    })

    let refundable = e?.map(i => i?.pricing).flat().map(val => {
      let applied_component_value = Number(val?.beforeTax ?? 0) + Number(val?.tax ?? 0);

      return {
        "unit_id": val?.unitid,
        "rent_breakup_id": (val?.componenttype === "Component") ? val?.componentType?.value === "is_wallet_item" ? null : val?.pricing_components?.id || val?.PCid : null,
        "rent_amount": val?.beforeTax,
        "primary": val?.primary,
        "refundable": val?.refundable,
        "taxable": val?.taxable,
        "is_one_time": val?.isOnetime,
        "display_percentage": HoleNumber(val?.discountPercentage),
        "payment_period": val?.paymentPeriod,
        "company_id": JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))?.value,
        "tax": val?.tax ?? 0,
        "tax_percentage": val?.taxPercentage ?? 0,
        "before_rent_amount": val?.amountWithoutDiscount,
        "before_tax": val?.fixTax,
        "component_value": val?.value,
        "vat_group_id": val?.vat_id,
        "is_quantity": val?.isQuantity,
        "quantity": val?.quantity,
        "value_basis_type": val?.valueBasisType,
        "inspection_item": (val?.componenttype === "Component") ? val?.componentType?.value === "is_wallet_item" ? val?.pricing_components?.id : null : val?.pricing_components?.id,
        "discount_type": val?.discount_type,
        "applied_value": applied_component_value,
        "is_wallet_item": val?.componentType?.value === "is_wallet_item" ? true : false,
        // "uom_type":val?.uom_type,
        "uom_type": val?.pricing_components?.uom_type ? val?.pricing_components?.uom_type : val?.paymentPeriod

      }
    })

    let payload = {
      "tenantId": `${config.tenantId}`,
      "lead_id": details?.quott?.lead_id,
      "total_amount": value?.totalAmountwithDiscount,
      "total_discount": value?.totalDiscount,
      "total_refundable": value?.totalrefundableTax,
      "total_tax": value?.totalTaxwithDiscount,
      "discount_percentage": value?.totalDiscount,
      "tax_percentage": discoutValue,
      "grace_period_type": "Days",
      "units": units,
      "rental_breakup": refundable,
      "total_quote_amount": Number(((value?.totalTax + value?.totalAmount) - value?.totalDiscount)?.toFixed(2)),
      "quotation_id": details?.quott?.id,
      "milestone_item": value?.milestone_item ?? [],
    }



    NetworkCall(
      `${config.api_url}/quotation/create_customize`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Quotation Updated Successfully"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }).catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      })
  }
  const upDateCustom = () => {
    setCustom({
      ...custom,
      already: custom?.already ? custom?.already : !custom?.already,
      type: !custom?.type
    })
  }
  const render = () => {
    return (
      <>
        {
          custom?.type ? <>
            <CustomizeQuote
              details={{
                quott_no: details?.quott?.quotation_no,
                lease_start_date: timeZoneConverter(details?.quott?.lease_start_date),
                lease_end_date: timeZoneConverter(details?.quott?.lease_end_date),
                revenue_type: { value: details?.quott?.revenue_type, label: details?.quott?.revenue_type }
              }} goBack={upDateCustom} data={details?.unitDetails} onSumbit={onSumbit} quote_details={details} is_resident={true} />
          </> : <>
            {
              show ? <NewLoader /> : <>
                <Subheader title={state?.data?.leadNo ?? ""} goBack={() => navigate(-1)} />
                <CustomPaper>
                  <Grid container>
                    <Grid item md={4} sm={12} lg={3.5}>
                      <Stack className={classes.leftCard} p={2} justifyContent={"space-between"}>
                        <Stack spacing={2}>
                          <Typography className={classes.headerTitle}>{state?.type ? t("Tenant Onboarding") : (state?.revenue_type === "leaseType" ? t("Quick Lease") : t("Resident Onboarding"))}</Typography>
                          <QuotationVaersionCard data={{
                            quott_no: details?.details?.quotation_no ?? "",
                            active: details?.details?.status ?? "",
                            startDate: details?.details?.created_at ?? ""
                          }}
                            t={t}
                            is_arrow={false}
                          />
                          {/*Quotation Detail*/}
                          <QuotationDetail
                            t={t}
                            data={{
                              startDate: details?.details?.lease_start_date,
                              endDate: details?.details?.lease_end_date,
                              billstartDate: details?.details?.billing_start_date,
                              gradeperiod: null,
                              graceApplicable: null,
                              revenue_type: details?.details?.revenue_type,
                              occpation_date: details?.details?.occpation_date,
                              contract_start_date: details?.details?.contract_start_date,
                              payment_mode: details?.details?.payment_mode,
                              lease_payment_period: details?.details?.lease_payment_period,
                              bill_generation: details?.details?.bill_generation,
                              billing_cycle_date: details?.details?.billing_cycle_date,
                              billing_day: details?.details?.billing_day


                            }} />
                          <Divider></Divider>
                          <Stack spacing={2}>
                            <Typography className={classes.detailHeader}>{t("Resident Details")}</Typography>
                            <ContactList
                              t={t}
                              marginTop={'13px !important'}
                              data={{
                                name: `${details?.details?.first_name ?? ""} ${details?.details?.last_name ?? ""}`,
                                img: details?.details?.image_url?.length > 0 ? details?.details?.image_url : null,
                                arg: false,
                                phone: `${details?.details?.mobile_no_country_code ?? ""} ${details?.details?.mobile_no ?? ""}`,
                                mail: details?.details?.email_id
                              }}

                              leadtype={true} />
                          </Stack>
                        </Stack>
                        <Stack direction={"row"} spacing={2}>
                          <Button variant='outlined' fullWidth className={classes.leftBtn} disableElevation onClick={() => setTemplate({
                            bool: true,
                            type: 'send'
                          })}>
                            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                              <SendMailIcon />
                              <Typography className={classes.leftBtnText}>{t("Send Mail")}</Typography>
                            </Stack>
                          </Button>
                          <Button variant='outlined' fullWidth className={classes.leftBtn}
                            onClick={() => setTemplate({
                              bool: true,
                              type: 'view'
                            })}>
                            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                              <DownloadIcon />
                              <Typography className={classes.leftBtnText}>{t("Download")}</Typography>
                            </Stack>
                          </Button>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item md={4} sm={12} lg={4.5}>
                      <Box className={classes.centerCard} p={2}>

                        <Stack direction="row" alignItems="center" justifyContent={'space-between'} style={{ marginBottom: "16px" }}>
                          <Typography className={classes.headerTitle} >{t("Unit Details")}</Typography>
                          {
                            details?.details?.status !== "Won" && <Typography className={classes.Customize} style={{ cursor: "pointer" }} onClick={() => upDateCustom(details?.unitDetails)}>{t("Customize")}</Typography>
                          }


                        </Stack>
                        <UnitList onOpen={onClickUnit} list={details?.unit} noBtn />

                      </Box>
                    </Grid>
                    <Grid item md={4} sm={12} lg={4} padding="16px 16px 0px 16px">
                      <Typography className={classes.title}>{t("Billing Summary")}</Typography>

                      <Box className={classes.summeryRoot}>
                        <QuotationSummery
                          symbol={details?.details?.symbol}
                          datas={{
                            totalAmount: details?.totalAmount,
                            totalrefundableTax: details?.totalrefundableTax,
                            totalTax: details?.totalTax,
                            total: details?.totalAmount,
                            totalDiscount: details?.totalDiscount,
                          }}
                          total={details?.total_quote_amount ?? (details?.totalAmount + details?.totalTax) - details?.totalDiscount}
                          height="310px"
                          t={t}
                          unit={details?.unitDetails}

                        />
                      </Box>
                      <Box mt={2}>
                        <Button fullWidth variant='contained' disableElevation sx={{ height: 40 }} disabled={details?.details?.status === "Won" ? true : false}
                          onClick={createAgreementFunction}>
                          {t("Confirm Order")}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </CustomPaper>



                {/* snack bar */}

                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                  <Box display="flex" className={classes.snackBarRoot} p={2}>
                    <Box>
                      <ApprovedWhite />
                    </Box>
                    <Box marginLeft="10px" flexGrow={1}>
                      <Typography className={classes.alertTitle}>
                        {t("Quotation Link Sent Successfully")}
                      </Typography>
                      <Typography className={classes.alertSub}>
                        {details?.details?.is_resend ? "Resend" : "Send"} to {details?.details?.email_id}
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton onClick={handleClose}>
                        <CloseIcon fontSize='small' sx={{ color: "white" }} />
                      </IconButton>

                    </Box>
                  </Box>
                </Snackbar>

                <AlertDialog
                  header={details?.details?.quotation_no}
                  onClose={() => {
                    setOpenpdf(false)
                    setPdf({
                      bool: false,
                      data: ""
                    })
                  }}
                  open={openpdf}
                  component={
                    <Box >
                      <IframeViwer pdf={pdf} height='124px' />

                    </Box>
                  }
                />

                <DialogBox
                  isnotTitle
                  background
                  maxWidth="sm"
                  open={openUnitDetails}
                  handleClose={() => setOpenUnitDetails(false)}
                  component={
                    <UnitSingleDetails shortlist t={t} selectedUnits={selectedUnit} onClose={() => setOpenUnitDetails(false)} />
                  } />
                <div>
                  <React.Fragment key={'right'}>
                    <Drawer
                      anchor={'right'}
                      open={states?.right}
                      onClose={toggleDrawer}
                    >
                      <QuotationContactCreate
                        onClose={toggleDrawer}
                        quotation_id={details?.details?.id}
                        t={t}
                        data={{
                          quott: details?.details,
                          units: details?.unit,
                          ...details
                        }}
                        email_id={details?.details?.email_id}
                        contactId={details?.details?.contact_id}
                        company_id={details?.details?.company_id}
                        setSelectedAcc={setSelectedAcc}
                        selectedAcc={selectedAcc}
                        reload={getAgreementDetails}
                        type={details?.details?.revenue_type}
                        total={(details?.details?.total_amount + details?.details?.total_tax) - details?.details?.total_discount}
                        is_prospect={true}
                        is_quote={true}
                        checkPrimary={checkPrimary}
                        primary={primary}
                        setPrimary={setPrimary}
                        is_conform={true}
                        is_tenant={state?.type}
                        lead_id={state?.lead_id}
                      />
                    </Drawer>
                  </React.Fragment>

                </div>
              </>
            }


            <TemplateGenerator t={t} name={"Quotation Template"} btnText={template?.type === "view" ? "Generate" : "Send"} open={template?.bool} onClose={() => setTemplate({
              bool: false,
              type: "view"
            })} type={1} generate={generate} />
          </>
        }
      </>
    )
  }

  return <div>
    {accessCheckRender(render, permission)}
  </div>
}

const props = {
  boxShadow: false
}


export default withTranslation("quotationsList")(withNavBars(ResidentOnboardViewEdit, props))