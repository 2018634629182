import React from "react";
import { FunctionalStyle } from "./style";
import Subheader from "../../components/subheader/subheader";
import {
  Badge,
  Box,
  Button,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import {
  FilterGenerator,
  SearchFilter,
  TableWithPagination,
  TextBox,
  ToggleButtonComponent,
  UseDebounce,
} from "../../components";
import { withTranslation } from "react-i18next";
import FilterIMG from "../../assets/filter";
import CloseIcon from "../../assets/closeIcon";
import { styled } from "@mui/material/styles";
import { CustomAsyncSelect } from "./components/customSelect";
import {
  AlertProps,
  NetWorkCallMethods,
  accessCheckRender,
  enumSelect,
  enum_types,
  getCompanyOption,
  getRoutePermissionNew,
  remCalc,
  wrapLabelsInT,
  FontFamilySwitch
} from "../../utils";
import LogoUpload from "../../components/LogoUpload";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import {
  functionalLocationDataType,
  functionalLocationPath,
  functionalLocationTable,
} from "./utils";
import { NewLoader } from "../../components/newLoader";
import { FunctionalLocationDetails } from "./functionalLocationDetails";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { Routes } from "../../router/routes";
import { useNavigate } from "react-router";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 22,
  padding: 0,
  marginInlineEnd: "-8px",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: "2px 6px",
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 17,
    height: 17,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const FunctionalLocation = ({ t }) => {
  const classes = FunctionalStyle();
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext);
  const navigate = useNavigate();

  const initialState = () => {
    return {
      id: "",
      type: "Property",
      property: "",
      block: "",
      floor: "",
      unit: "",
      amenities: "",
      gate: "",
      generic: "",
      logo: "",
      name: "",
      description: "",
      status: true,
      associateParent: "",
      data: "",
      error: {
        type: "",
        property: "",
        block: "",
        floor: "",
        unit: "",
        amenities: "",
        gate: "",
        generic: "",
        logo: "",
        name: "",
        description: "",
        status: "",
        associateParent: "",
      },
    };
  };
  const [functionalData, setFunctionalData] = React.useState({
    ...initialState(),
  });
  const [companyList, setCompanyList] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState({});
  const [functionalLocationalList, setFunctionalLocationalList] =
    React.useState({
      data: [],
      count: 0,
    });
  const [searchText, setSearchText] = React.useState("");
  const [filterDrawer, setFilterDrawer] = React.useState(null);
  const [addNewDialog, setAddNewDialog] = React.useState({
    addEdit: false,
    view: false,
  });
  const [isComponentVisible, setComponentVisibility] = React.useState(false);
  const [filterData, setFilterData] = React.useState({ status: [true] });
  const [loading, setLoading] = React.useState(false);
  const [permission, setPermission] = React.useState({});
  const [isDisableBtn, setIsDisableBtn] = React.useState(false);
  const [enumData, setEnumData] = React.useState([]);

  const debounce = UseDebounce();

  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");
  const limitParam = searchParams.get("limit");

  // Default values if not set in URL
  const pageFromParams = pageParam ? parseInt(pageParam, 10) : 1;
  const limitFromParams = limitParam ? parseInt(limitParam, 10) : 10;

  const [page, setPage] = React.useState(pageFromParams);
  const [limit, setLimit] = React.useState(limitFromParams);

  const updatePage = (newPage, newLimit = limit) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", newPage.toString());
    searchParams.set("limit", newLimit.toString());

    // Update URL without reloading
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
    // Update state
    setPage(newPage);
    setLimit(newLimit);
  };

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        getEnum();
        if (company) {
          setCompanyList(company?.list);
          setSelectedCompany(company?.selected);
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  //handle pagination
  const handlePagination = (newPage) => {
    updatePage(newPage, limit);
  };

  const handleChangeLimit = (newLimit) => {
    updatePage(1, newLimit); // Reset page to 1 when limit changes
  };

  const handleSearch = (e) => {
    setSearchText(e);
    debounce(() => searchTableFunction(e), 800);
  };
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    functionalLocationTableData(0, limit, e);
  };

  //update state
  const updateState = (key, value) => {
    let error = functionalData?.error;
    error[key] = "";
    if (key === "property") {
      setFunctionalData({
        ...functionalData,
        [key]: value,
        block: "",
        floor: "",
        unit: "",
        gate: "",
        amenities: "",
      });
    } else {
      setFunctionalData({ ...functionalData, [key]: value });
    }
  };
  //validation
  const validate = () => {
    let isValid = true;
    let error = functionalData.error;
    if (functionalData?.name?.length === 0) {
      isValid = false;
      error.name = t("Name is Required");
    }

    if (functionalData?.description?.length === 0) {
      isValid = false;
      error.description = t("Description is Required");
    }
    if (functionalData?.status?.length === 0) {
      isValid = false;
      error.status = t("Status is Required");
    }
    if (functionalData?.logo?.src?.length === 0) {
      isValid = false;
      error.logo.src = t("logo is Required");
    }

    setFunctionalData({ ...functionalData, error });

    return isValid;
  };

  //Upsert API FUNCTION
  const UpsertFunctionalLocation = (data, key) => {
    if (key === "active" ? true : validate()) {
      setIsDisableBtn(true);
      let payload;
      if (functionalData?.id) {
        payload = {
          name: functionalData?.name,
          description: functionalData?.description,
          company_id: selectedCompany?.value,
          id: functionalData?.id,
          is_active: functionalData?.status ?? undefined,
          location_type: functionalData?.type ?? undefined,
          property_id: functionalData?.property?.value ?? undefined,
          unit_id: functionalData?.unit?.value ?? undefined,
          block_id: functionalData?.block?.value ?? undefined,
          floor_id: functionalData?.floor?.value ?? undefined,
          gate_id: functionalData?.gate?.value ?? undefined,
          amenity_id: functionalData?.amenities?.value ?? undefined,
          image_url: functionalData?.logo?.src ?? undefined,
          parent_id: functionalData?.associateParent?.value ?? undefined,
        };
      } else if (key === "active") {
        payload = {
          id: data?.id,
          is_active: data?.status === "Active" ? false : true,
        };
      } else {
        payload = {
          name: functionalData?.name,
          description: functionalData?.description,
          company_id: selectedCompany?.value,
          is_active: functionalData?.status ?? undefined,
          location_type: functionalData?.type ?? undefined,
          property_id: functionalData?.property?.value ?? undefined,
          unit_id: functionalData?.unit?.value ?? undefined,
          block_id: functionalData?.block?.value ?? undefined,
          floor_id: functionalData?.floor?.value ?? undefined,
          gate_id: functionalData?.gate?.value ?? undefined,
          amenity_id: functionalData?.amenities?.value ?? undefined,
          image_url: functionalData?.logo?.src ?? undefined,
          parent_id: functionalData?.associateParent?.value ?? undefined,
        };
      }
      NetworkCall(
        `${config.api_url}/function_location/upsert`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          let successMessage;
          if (key === "active") {
            successMessage =
              data?.status === "Active"
                ? t("Functional Location Inactive successful")
                : t("Functional Location Active successful");
          } else {
            successMessage = functionalData?.id
              ? t("Functional Location Updated successfully")
              : t("Functional Location Added successfully");
          }
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: successMessage,
          });
          setFunctionalData({ ...initialState() });
          setAddNewDialog({
            addEdit: false,
            view: false,
          });
          functionalLocationTableData(currentOffset, limitFromParams, searchText);
          setIsDisableBtn(false);
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Internal error. Please try again later."),
          });
          setIsDisableBtn(false);
        });
    }
  };
  //table data API FUNCTION
  const functionalLocationTableData = (offset = 0, limit = 10, search = "") => {
    setLoading(true);

    const payload = {
      offset: offset,
      limit: limit,
      search: search,
      company_id: selectedCompany?.value,
      type: filterData?.type ?? [],
      is_active: filterData?.status ?? [],
    };

    NetworkCall(
      `${config.api_url}/function_location/list`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setFunctionalLocationalList({
          data: response?.data?.data?.data?.map((i) => {
            return {
              ...i,
              latitude_data:
                i?.latitude && i?.latitude
                  ? `${i?.latitude ?? ""} ${i?.latitude && "&"} ${
                      i?.longitude ?? ""
                    }`
                  : "-",
              is_parent: i?.is_parent ? "Yes" : "No",
              status: i?.is_active ? "Active" : "In-Active",
            };
          }),
          count: response?.data?.data?.count,
        });
        setLoading(false);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
        setLoading(false);
      });
  };

  // Function to open filter drawer
  const filterDraweropen = () => {
    setFilterDrawer(true);
  };

  // Function to close filter drawer
  const filterDrawerclose = () => {
    setFilterDrawer(false);
  };
  // Function for Add New Button
  const handleAddNewButton = () => {
    setAddNewDialog({
      addEdit: true,
      view: false,
    });
  };
  // Function for view Button
  const handleViewButton = () => {
    setAddNewDialog({
      addEdit: false,
      view: true,
    });
  };

  // Function to close filter drawer
  const addNewDialogclose = () => {
    setFunctionalData({ ...initialState() });
    setAddNewDialog({
      addEdit: false,
      view: false,
    });
  };

  const handleClick = () => {
    setComponentVisibility((prevVisibility) => !prevVisibility);
  };

  const SearchIcon = (props) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} {...props}>
        <path
          d="M5.9 0a5.9 5.9 0 1 0 3.548 10.6l3.163 3.162a.814.814 0 1 0 1.151-1.151L10.6 9.448A5.886 5.886 0 0 0 5.9 0Zm0 1.627a4.273 4.273 0 0 1 3.112 7.2.814.814 0 0 0-.182.182A4.272 4.272 0 1 1 5.9 1.627Z"
          fill={props?.fill ?? "#98a0ac"}
        />
      </svg>
    );
  };
  const getEnum = async () => {
    const result = await enumSelect([enum_types?.location_type]);
    setEnumData(result?.location_type);
  };
  const currentOffset = (page - 1) * limit;
  React.useEffect(() => {
    if (selectedCompany?.value) {
      functionalLocationTableData(currentOffset, limitFromParams, searchText);
    }
    //eslint-disable-next-line
  }, [selectedCompany, filterData]);

  const handleIcon = (type, data, status) => {
    if (type === "view") {
      setFunctionalData({
        ...functionalData,
        data: data,
      });
      handleViewButton();
    } else if (type === "edit") {
      setFunctionalData({
        ...functionalData,
        id: data?.id,
        type: data?.location_type,
        property: {
          label: data?.property_name,
          value: data?.property_id,
        },
        block: {
          label: data?.block_name,
          value: data?.block_id,
        },
        floor: {
          label: data?.floor_name,
          value: data?.floor_id,
        },
        unit: {
          label: data?.unit_name,
          value: data?.unit_id,
        },
        amenities: {
          label: data?.facility_name,
          value: data?.facility_id,
        },
        gate: {
          label: data?.gate_name,
          value: data?.gate_id,
        },
        logo: { src: data?.image_url },
        name: data?.name,
        description: data?.description,
        status: data?.is_active,
        associateParent: {
          label: data?.parent_name,
          value: data?.parent_id,
        },
      });
      handleAddNewButton();
    } else if (type === "active") {
      UpsertFunctionalLocation(data, "active");
    } else if (type === "view_heirarchy") {
      navigate(Routes?.functionalHeirarchy, {
        state: { id: data?.id, name: data?.name, company: selectedCompany },
      });
    }
  };

  //apply filter
  const onApplyFilter = (value) => {
    setFilterData(value);
  };

  const manualResponse = (array) => {
    const result = array?.data?.map((e) => {
      return {
        label: e?.name,
        value: e?.id,
        ...e,
      };
    });
    return result;
  };

  const manualAmenityResponse = (array) => {
    const result = array?.data?.map((e) => {
      return {
        label: e?.facility_name,
        value: e?.facility_id,
        ...e,
      };
    });
    return result;
  };

  const render = () => {
    return (
      <>
        <Subheader
          hideBackButton={true}
          title={t("Functional Location")}
          options={companyList}
          value={selectedCompany}
          select
          // goBack={() => {
          //     navigate(-1);
          // }}
          onchange={(e) => setSelectedCompany(e)}
        />

        {/* Content */}
        <div className={classes.root}>
          <Grid container className={classes.content} spacing={2}>
            {/* Search TextBox */}
            <Grid item xs={4}>
              <SearchFilter
                value={searchText}
                placeholder={t("Search")}
                handleChange={(value) => handleSearch(value)}
                customfieldSx={{
                  "& .MuiOutlinedInput-root": { height: "40px" },
                }}
              />
            </Grid>

            {/* Filter and Add New Button */}
            <Grid item xs={8} textAlign={"right"}>
              <Box display={"flex"} sx={{ justifyContent: "end" }}>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={2}
                >
                  {filterData.status?.length > 0 ? (
                    <IconButton
                      onClick={filterDraweropen}
                      className={classes.filterButton}
                    >
                      <Badge variant="dot" color="primary">
                        <FilterIMG color="#091b29" />
                      </Badge>
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={filterDraweropen}
                      className={classes.filterButton}
                    >
                      <FilterIMG color="#091b29" />
                    </IconButton>
                  )}
                  {permission?.create && (
                    <Button
                      variant="contained"
                      className={classes.addNewButton}
                      onClick={handleAddNewButton}
                      disabled={isDisableBtn}
                    >
                      {t("Create New")}
                    </Button>
                  )}
                </Stack>
              </Box>
            </Grid>

            {/* function location Component Table */}
            {loading ? (
              <NewLoader minusHeight="200px" />
            ) : (
              <Grid item xs={12} marginTop="-14px">
                <TableWithPagination
                  heading={functionalLocationTable(t)}
                  rows={functionalLocationalList?.data}
                  path={functionalLocationPath}
                  showpagination={true}
                  showpdfbtn={false}
                  showexcelbtn={false}
                  showSearch={false}
                  handleIcon={handleIcon}
                  onClick={() => console.log("")}
                  tableType="no-side"
                  dataType={functionalLocationDataType}
                  handlePagination={handlePagination}
                  handleChangeLimit={handleChangeLimit}
                  totalRowsCount={functionalLocationalList?.count}
                  page={page}
                  limit={limit}
                  height={"calc(100vh - 290px)"}
                  view={permission?.read}
                  edit={permission?.update}
                  delete={permission?.delete}
                />
              </Grid>
            )}
          </Grid>

          {/* Filter Drawer */}
          <FilterGenerator
            open={filterDrawer}
            onClose={filterDrawerclose}
            components={[
              {
                component: "toggleButton",
                value: filterData?.status,
                options: [
                  { label: t("Active"), value: true },
                  { label: t("Inactive"), value: false },
                ],
                isMulti: true,
                state_name: "status",
                label: t("Status"),
              },
              {
                component: "toggleButton",
                value: filterData?.type,
                options: wrapLabelsInT(enumData, t),
                isMulti: true,
                state_name: "type",
                label: t("Type"),
              },
            ]}
            onApply={(value) => onApplyFilter(value)}
          />

          {/* Add Edit View Drawer */}
          <Dialog
            open={addNewDialog?.addEdit}
            onClose={addNewDialogclose}
            className={classes.addNewDialog}
          >
            <div className={classes.addNewDialogHeader}>
              <Typography className={classes.addNewDialogHeaderTitle}>
                {t("Property Functional Location")}
              </Typography>
              <IconButton
                className={classes.addNewDialogHeaderCloseButton}
                onClick={addNewDialogclose}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div className={classes.addNewDialogFormBody}>
              <ToggleButtonComponent
                options={wrapLabelsInT(enumData, t)}
                isReadOnly={functionalData?.id?.length > 0 ? true : false}
                // isrequired={required}
                value={functionalData?.type}
                onChange={(value) => updateState("type", value)}
                // isMulti={isMulti}
                // fullWidth={fullWidth}
                buttonStyle={{
                  borderRadius: "20px !important",
                  border: "1px solid #E4E8EE",
                  padding: "10px 12px 11px 12px !important",
                  fontSize: remCalc(14),
                  color: "#091B29",
                  fontFamily: FontFamilySwitch().semiBold,
                  height: "40px",
                }}
                buttonGroupStyle={{ gap: "8px" }}
                height={"40px !important"}
              />
              {functionalData?.type !== "Generic" && (
                <Grid container marginTop={"12px"}>
                  <Grid item xs={functionalData?.type === "Property" ? 12 : 6}>
                    <CustomAsyncSelect
                      isReadOnly={functionalData?.id?.length > 0 ? true : false}
                      borderTopLeftRadius
                      placeholder={t("Select Property")}
                      value={functionalData?.property ?? null}
                      onChange={(value) => updateState("property", value)}
                      label={t("Property")}
                      icon={<SearchIcon width={13} height={13} />}
                      isPaginate={true}
                      debounceTimeout={800}
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "function_location/get_dropdown",
                          {
                            company_id: selectedCompany?.value,
                            type: "Property",
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          false
                        )
                      }
                    />
                  </Grid>
                  {(functionalData?.type === "Block" ||
                    functionalData?.type === "Floor" ||
                    functionalData?.type === "Unit") && (
                    <Grid item xs={6}>
                      <CustomAsyncSelect
                        isReadOnly={
                          functionalData?.id?.length > 0 ? true : false
                        }
                        // borderTopLeftRadius
                        borderTopRightRadius
                        // borderRadius
                        placeholder={t("Select Block")}
                        value={functionalData?.block ?? null}
                        onChange={(value) => updateState("block", value)}
                        label={t("Block")}
                        icon={<SearchIcon width={13} height={13} />}
                        isPaginate={true}
                        key={JSON.stringify(functionalData)}
                        debounceTimeout={800}
                        loadOptions={(search, array, handleLoading) =>
                          loadOptionsApis(
                            "function_location/get_dropdown",
                            {
                              company_id: selectedCompany?.value,
                              type: "Block",
                              property_id: functionalData?.property?.value,
                            },
                            search,
                            array,
                            handleLoading,
                            "data",
                            {},
                            false
                          )
                        }
                      />
                    </Grid>
                  )}
                  {(functionalData?.type === "Floor" ||
                    functionalData?.type === "Unit") && (
                    <Grid item xs={functionalData?.type === "Floor" ? 12 : 6}>
                      <CustomAsyncSelect
                        isReadOnly={
                          functionalData?.id?.length > 0 ? true : false
                        }
                        borderBottomLeftRadius
                        // borderTopRightRadius
                        // borderRadius
                        placeholder={t("Select Floor")}
                        value={functionalData?.floor ?? null}
                        onChange={(value) => updateState("floor", value)}
                        label={t("Floor")}
                        icon={<SearchIcon width={13} height={13} />}
                        isPaginate={true}
                        key={JSON.stringify(functionalData?.block)}
                        debounceTimeout={800}
                        loadOptions={(search, array, handleLoading) =>
                          loadOptionsApis(
                            "function_location/get_dropdown",
                            {
                              company_id: selectedCompany?.value,
                              type: "Floor",
                              block_id: functionalData?.block?.value,
                              property_id: functionalData?.property?.value,
                            },
                            search,
                            array,
                            handleLoading,
                            "data",
                            {},
                            false
                          )
                        }
                      />
                    </Grid>
                  )}
                  {functionalData?.type === "Unit" && (
                    <Grid item xs={6}>
                      <CustomAsyncSelect
                        isReadOnly={
                          functionalData?.id?.length > 0 ? true : false
                        }
                        borderBottomRightRadius
                        placeholder={t("Select Unit")}
                        value={functionalData?.unit ?? null}
                        onChange={(value) => updateState("unit", value)}
                        label={t("Unit")}
                        icon={<SearchIcon width={13} height={13} />}
                        isPaginate={true}
                        debounceTimeout={800}
                        loadOptions={(search, array, handleLoading) =>
                          loadOptionsApis(
                            "function_location/get_dropdown",
                            {
                              company_id: selectedCompany?.value,
                              type: "Unit",
                              block_id: functionalData?.block?.value,
                              property_id: functionalData?.property?.value,
                              floor_id: functionalData?.floor?.value,
                            },
                            search,
                            array,
                            handleLoading,
                            "data",
                            {},
                            false
                          )
                        }
                      />
                    </Grid>
                  )}
                  {functionalData?.type === "Gate" && (
                    <Grid item xs={6}>
                      <CustomAsyncSelect
                        isReadOnly={
                          functionalData?.id?.length > 0 ? true : false
                        }
                        borderBottomRightRadius
                        placeholder={t("Select Gate")}
                        value={functionalData?.gate ?? null}
                        onChange={(value) => updateState("gate", value)}
                        label={t("Gate")}
                        icon={<SearchIcon width={13} height={13} />}
                        isPaginate={true}
                        key={JSON.stringify(functionalData?.property)}
                        debounceTimeout={800}
                        loadOptions={(search, array, handleLoading) =>
                          loadOptionsApis(
                            "function_location/get_dropdown",
                            {
                              company_id: selectedCompany?.value,
                              type: "Gate",
                              property_id: functionalData?.property?.value,
                            },
                            search,
                            array,
                            handleLoading,
                            "data",
                            {},
                            false
                          )
                        }
                      />
                    </Grid>
                  )}
                  {functionalData?.type === "Amenity" && (
                    <Grid item xs={6}>
                      <CustomAsyncSelect
                        isReadOnly={
                          functionalData?.id?.length > 0 ? true : false
                        }
                        borderBottomRightRadius
                        placeholder={t("Select Amenity")}
                        value={functionalData?.amenities ?? null}
                        onChange={(value) => updateState("amenities", value)}
                        label={t("Amenity")}
                        icon={<SearchIcon width={13} height={13} />}
                        isPaginate={true}
                        key={JSON.stringify(functionalData?.property)}
                        debounceTimeout={800}
                        loadOptions={(search, array, handleLoading) =>
                          loadOptionsApis(
                            "function_location/get_dropdown",
                            {
                              company_id: selectedCompany?.value,
                              type: "Amenity",
                              property_id: functionalData?.property?.value,
                            },
                            search,
                            array,
                            handleLoading,
                            "data",
                            {},
                            false,
                            manualAmenityResponse
                          )
                        }
                      />
                    </Grid>
                  )}
                </Grid>
              )}
              <div className={classes.switchBox}>
                <Box className={classes.toggleBox}>
                  <Typography className={classes.AssociateText}>
                    {t("Associate Parent Location")}
                  </Typography>
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} />}
                    checked={isComponentVisible}
                    onChange={handleClick}
                  />
                </Box>
                {isComponentVisible && (
                  <Box>
                    <CustomAsyncSelect
                      // isReadOnly={functionalData?.id?.length > 0 ? true : false}
                      borderRadius
                      placeholder={t("Select Parent")}
                      value={functionalData?.associateParent ?? null}
                      onChange={(value) =>
                        updateState("associateParent", value)
                      }
                      icon={<SearchIcon width={13} height={13} />}
                      debounceTimeout={800}
                      isPaginate={true}
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "function_location/get",
                          {
                            company_id: selectedCompany?.value,
                          },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualResponse
                        )
                      }
                    />
                  </Box>
                )}
              </div>
              <div style={{ margin: "10px 0px" }}>
                <Divider />
              </div>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className={classes.AssociateText}>
                    {t("Functional Location Picture & Details")}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <LogoUpload
                    logo_title={false}
                    isrequired
                    handleChange={(value) => updateState("logo", value)}
                    data={functionalData?.logo}
                    isError={
                      functionalData?.error?.logo?.src?.length > 0
                        ? true
                        : false
                    }
                    errorMessage={functionalData?.error?.logo?.src}
                  />
                </Grid>
                <Grid item xs={8} marginTop={"10px"}>
                  <Grid container rowSpacing={2}>
                    <Grid item xs={12}>
                      <TextBox
                        label={t("Name")}
                        placeholder={t("Enter Name")}
                        onChange={(val) =>
                          updateState("name", val.target.value)
                        }
                        value={functionalData?.name ?? ""}
                        isrequired={true}
                        isError={
                          functionalData?.error?.name?.length < 0 ? false : true
                        }
                        errorMessage={functionalData?.error?.name}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextBox
                        label={t("Description")}
                        placeholder={t("Enter Description")}
                        onChange={(val) =>
                          updateState("description", val.target.value)
                        }
                        value={functionalData?.description ?? ""}
                        isrequired={true}
                        isError={
                          functionalData?.error?.description?.length < 0
                            ? false
                            : true
                        }
                        errorMessage={functionalData?.error?.description}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ToggleButtonComponent
                        label={t("Status")}
                        options={[
                          { label: t("Active"), value: true },
                          { label: t("Inactive"), value: false },
                        ]}
                        isrequired
                        value={functionalData?.status}
                        onChange={(value) => updateState("status", value)}
                        // isMulti={isMulti}
                        // fullWidth={fullWidth}
                        buttonStyle={{
                          // borderRadius: '20px !important',
                          // border: '1px solid #E4E8EE',
                          // padding: "10px 12px 11px 12px !important",
                          fontSize: remCalc(14),
                          color: "#091B29",
                          fontFamily: FontFamilySwitch().semiBold,
                          height: "40px",
                        }}
                        buttonGroupStyle={{ gap: "8px" }}
                        isError={functionalData?.error?.status?.length > 0}
                        errorMessage={functionalData?.error?.status}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <div style={{ margin: "10px 0px" }}>
                <Divider />
              </div>
              <Grid container>
                <Grid item xs={6} textAlign={"left"}>
                  <Button
                    className={classes.cancelButton}
                    variant="outlined"
                    onClick={addNewDialogclose}
                  >
                    {t("Cancel")}
                  </Button>
                </Grid>
                <Grid item xs={6} textAlign={"right"}>
                  <Button
                    variant="contained"
                    onClick={UpsertFunctionalLocation}
                    disabled={isDisableBtn}
                  >
                    {t("Create Functional Location")}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Dialog>
          {/*  View Drawer */}
          <Dialog
            open={addNewDialog?.view}
            onClose={addNewDialogclose}
            className={classes.addNewDialog}
          >
            <div className={classes.addNewDialogHeader}>
              <Typography className={classes.addNewDialogHeaderTitle}>
                {t("Functional Location Picture & Details")}
              </Typography>
              <IconButton
                className={classes.addNewDialogHeaderCloseButton}
                onClick={addNewDialogclose}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div className={classes.addNewDialogFormBody}>
              <FunctionalLocationDetails data={functionalData?.data} t={t} />
            </div>
          </Dialog>
        </div>
      </>
    );
  };

  return <>{accessCheckRender(render, permission)}</>;
};

export default withTranslation("functionalLocation")(FunctionalLocation);
