import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  Editor,
  SelectBox,
  TextBox,
  ToggleButtonComponent,
} from "../../../components";
import { LocalStorageKeys } from "../../../utils";
import { useStyles } from "../styles";
import LogoUpload from "../../../components/LogoUpload";
import { loadOptionsApis } from "../../maintenancerequest/utils";

export const Profile = ({
  data = {},
  updateState = {},
  enumValue = {},
  setData = () => false,
  reltype = null,
  acc_id = null,
  t = () => false,
  state = "",
}) => {
  const classes = useStyles();
  const selectedCompany = JSON.parse(
    localStorage.getItem(LocalStorageKeys.selectedCompany)
  );
  const language = localStorage.getItem("i18nextLng");

  //load option api

  const manualResponseBussinesType = (array) => {
    const result = array?.data?.map((e) => {
      return e;
    });
    return result;
  };
  return (
    <Box>
      <Grid container spacing={2} width={`calc(100% + 12px)`}>
        <Grid item xs={12} md={12} lg={2}>
          <Box className={classes.profile_box}>
            <LogoUpload
              logo_title={t("PROFILE IMAGE")}
              handleChange={(value) => updateState("image_url", value)}
              data={data?.image_url}
              buttonName={t("Upload Image")}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={10}>
          <Box
            sx={{ overflow: language !== "ar" && "auto" }}
            className={classes.secondBox}
          >
            <Typography className={classes.title}>
              {t("Profile Details")}
            </Typography>
            <Grid container rowSpacing={1} width={"100%"}>
              <Grid item xs={12} md={12} lg={12}>
                <Grid container spacing={3}>
                  {reltype !== "cashBank" && (
                    <Grid item xs={12} md={1.5} lg={1.5}>
                      <ToggleButtonComponent
                        options={enumValue?.payment_classification}
                        value={data?.account_type_ap_ar}
                        onChange={(value) =>
                          updateState("account_type_ap_ar", value)
                        }
                        label={t("Account Type")}
                        isrequired
                        errorMessage={data?.error?.account_type_ap_ar}
                        isError={data?.error?.account_type_ap_ar?.length > 0}
                        isReadOnly={
                          state?.viewtype === "edit" ?? reltype !== "AllAccount"
                        }
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={4.5} lg={4.5}>
                    <SelectBox
                      isReadOnly
                      label={t("Company Name")}
                      placeholder={t("Enter Company Name")}
                      onChange={(value) => updateState("company", value)}
                      value={data?.company}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} lg={3}>
                    <SelectBox
                      label={t("Business Type")}
                      placeholder={t("Enter Business Type")}
                      onChange={(value) => updateState("bussiness_type", value)}
                      value={data?.bussiness_type}
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "queries/get_comapany_type",
                          { company_id: selectedCompany?.value },
                          search,
                          array,
                          handleLoading,
                          "data",
                          {},
                          manualResponseBussinesType
                        )
                      }
                      isPaginate={true}
                      isRequired
                      errorMessage={data?.error?.bussiness_type}
                      isError={data?.error?.bussiness_type?.length > 0}
                    />
                  </Grid>
                  {reltype !== "cashBank" && (
                    <Grid item xs={12} md={3} lg={3}>
                      <SelectBox
                        label={
                          data?.account_type_ap_ar === "AP"
                            ? t("Vendor Group")
                            : t("Customer Group")
                        }
                        placeholder={
                          data?.account_type_ap_ar === "AP"
                            ? t("Select Vendor Group")
                            : t("Select Customer Group")
                        }
                        onChange={(value) =>
                          updateState("account_group", value)
                        }
                        value={data?.account_group}
                        loadOptions={(search, array, handleLoading) =>
                          loadOptionsApis(
                            data?.account_type_ap_ar === "AP"
                              ? "queries/get_vendor_group"
                              : "queries/get_customer_group",
                            { company_id: selectedCompany?.value },
                            search,
                            array,
                            handleLoading,
                            "data",
                            {},
                            manualResponseBussinesType
                          )
                        }
                        isPaginate={true}
                        key={data?.account_type_ap_ar}
                        isRequired
                        errorMessage={data?.error?.account_group}
                        isError={data?.error?.account_group?.length > 0}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={3} lg={3}>
                    <SelectBox
                      label={t("Relationship")}
                      placeholder={t("Select Relationship")}
                      options={enumValue?.account_relationship}
                      onChange={(value) => updateState("relation_ship", value)}
                      value={data?.relation_ship}
                      isReadOnly={acc_id ? true : false}
                      isRequired
                      menuOptionHeight="150px"
                      key={JSON.stringify(data?.account_type_ap_ar)}
                    />
                  </Grid>

                  <Grid item xs={12} md={3} lg={3}>
                    <TextBox
                      label={t("Account Name")}
                      placeholder={t("Enter Account Name")}
                      onChange={(value) =>
                        updateState("acc_name", value.target.value)
                      }
                      value={data?.acc_name}
                      isrequired
                      errorMessage={data?.error?.acc_name}
                      isError={data?.error?.acc_name?.length > 0}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} lg={3}>                                        
                    {reltype === "cashBank" ? (
                      <TextBox
                        label={t("COA Number")}
                        placeholder={t("Enter COA Number")}
                        onChange={(value) => {
                          const newValue = value.target.value.replace(/\D/g, "");
                          updateState("coa_number", newValue);
                        }}
                        value={data?.coa_number}
                        isrequired
                        errorMessage={data?.error?.coa_number}
                        isError={data?.error?.coa_number?.length > 0}
                      />
                    ) : (
                      <TextBox
                      label={t("Alternative Account Number")}
                      placeholder={t("Enter Alternative Account Number")}
                      onChange={(value) =>
                        updateState("alt_acc_no", value.target.value)
                      }
                      value={data?.alt_acc_no}
                      isrequired
                      errorMessage={data?.error?.alt_acc_no}
                      isError={data?.error?.alt_acc_no?.length > 0}
                    />
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Editor
                  label={t("Business Description")}
                  handleChange={(e) => {
                    updateState("description", e);
                  }}
                  value={data?.description}
                  height={"93px"}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
