import { Button, IconButton, Typography } from '@mui/material';
import Divider from "@mui/material/Divider";
import Popover from '@mui/material/Popover';
import Stack from "@mui/material/Stack";
import styled from "@mui/material/styles/styled";
import React, { useContext, useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalenderIcon } from '../../../assets';
import { ToggleButtonComponent } from '../../../components';
import { AlertContext } from '../../../contexts';
import { AlertProps, DURATION_PERIOD, FontFamilySwitch } from '../../../utils';
import moment from 'moment';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { DateStyles } from './dateStyle';




const Container = styled('div')({
    padding: 16,
    boxShadow: '0px 8px 24px #0717411F',
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    '& .react-datepicker': {
        border: 0,
        '& .react-datepicker__header': {
            backgroundColor: '#F2F4F7',
            borderBottom: '2px solid #2579ba'
        },
    }
})

const Div = styled('div')({
    display: 'flex',
    alignItems: "center",
    marginLeft: "4px"
})

const Input = styled('input')({
    maxWidth: 50,
    minHeight: 38,
    paddingInline: 11,
    color: '#091B29',
    fontFamily: FontFamilySwitch().semiBold,
    fontSize:"0.75rem",
    border: '1px solid #E4E8EE',
    borderRadius: 4,
    '&:focus-visible': {
        outline: '1px solid #E4E8EE'
    }
})

const CustomDivider = styled(Divider)({
    marginBlock: '16px'
})



export const CustomDatePicker = (props) => {

    const {
        placeholder = "Choose Date",
        value = null,
        onChange = null,
        title = "",
        showEndDate = false
    } = props;

    const alert = useContext(AlertContext);
    const [anchorEl, setAnchorE1] = useState(null)
    const [state, setState] = useState(value ?? {});

    useEffect(() => {
        if (anchorEl) {
            setState(value)
        }
    }, [value, anchorEl])

    const onChangeValue = (key, val) => {
        let updateValue = {
            ...state,
            [key]: val
        }
        setState(updateValue)
    }


    const onClear = () => {
        setState({})
    }

    const onApply = () => {
        if (showEndDate) {
            if (state?.date && state?.input && state?.period) {
                props?.getDate(moment(state?.date))
                onChange && onChange(state);
                setAnchorE1(null)
            }
            else {
                showAlert('Please fill all the fields')
            }
        }
        else {
            if (state?.date) {
                props?.getDate(moment(state?.date))
                onChange && onChange(state);
                setAnchorE1(null)
            }
            else {
                showAlert('Please choose date')
            }
        }
    }


    const showAlert = (msg = "") => {
        alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center
        })
    }

    const onClose = () => {
        onChange && onChange(value);
        setAnchorE1(null)
    }

    const classes = DateStyles(props)
    //previousDate
    const previousDate = () => {
        let previous_Date = moment(state?.date).subtract(1, 'days');
        setState({
            ...state,
            date: previous_Date
        })
        props?.getDate(previous_Date)

    }
    //nextDate
    const nextDate = () => {

        let next_Date = moment(state?.date).add(1, 'days');
        setState({
            ...state,
            date: next_Date
        })
        props?.getDate(next_Date)

    }
    return (
        <div>

            {props?.onlyCalendarIcon ?
                <Div>
                    {/* <IconButton onClick={previousDate}>
                        <ArrowBackIos className={classes.text} />
                    </IconButton>
                    <Typography className={classes.text}>
                        {moment(state?.date).format("DD MMM")}
                    </Typography>&nbsp;
                    <IconButton onClick={nextDate}>
                        <ArrowForwardIos className={classes.text} />
                    </IconButton> */}
                    <IconButton onClick={e => setAnchorE1(e.target)} className={classes.btn}>
                        <CalenderIcon width={10} height={10} />
                    </IconButton>
                </Div>
                :
                <Div style={{direction:"ltr"}}>
                    <IconButton onClick={previousDate}>
                        <ArrowBackIos className={classes.arrow} />
                    </IconButton>
                    <Typography className={classes.text}>
                        {moment(state?.date).format("DD MMM")}
                    </Typography>&nbsp;
                    <IconButton onClick={nextDate}>
                        <ArrowForwardIos className={classes.arrow} />
                    </IconButton>
                    <IconButton onClick={e => setAnchorE1(e.target)} className={classes.btn}>
                        <CalenderIcon width={10} height={10} />
                    </IconButton>
                </Div>
            }

            {
                anchorEl &&
                <Popover
                    open={true}
                    anchorEl={anchorEl}
                    onClose={onClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Container>
                        {
                            title &&
                            <Typography
                                className={classes.title}
                            >
                                {title}
                            </Typography>
                        }
                        {
                            showEndDate &&
                            <>
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    gap={'6px'}
                                >
                                    <Input
                                        value={state?.input ?? ''}
                                        onChange={e => onChangeValue('input', e.target.value)}
                                    />
                                    <Div style={{ flex: 1 }}>
                                        <ToggleButtonComponent
                                            options={DURATION_PERIOD}
                                            value={state?.period}
                                            onChange={(value) => onChangeValue('period', value)}
                                            // buttonHeight
                                            buttonGroupStyle={{
                                                gap: '6px'
                                            }}
                                            buttonStyle={{
                                                // height: '32px',
                                                borderColor: '#E4E8EE',
                                                borderRadius: '4px !important',
                                                paddingBlock: 0,
                                                flex: 1,
                                                backgroundColor: '#F2F4F7'
                                            }}
                                        />
                                    </Div>
                                </Stack>
                                <CustomDivider />
                            </>
                        }
                        <DatePicker
                            // minDate={new Date()}
                            selected={state?.date ?? ''}
                            onChange={(value) => onChangeValue('date', value)}
                            placeholderText={placeholder}
                            inline
                            peekNextMonth

                            dropdownMode="select"
                            showMonthDropdown
                            showYearDropdown
                        />
                        <CustomDivider sx={{ marginTop: 0, marginBottom: '12px' }} />
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                        >
                            <Typography
                                className={classes.clear}
                                onClick={onClear}
                            >
                                Clear
                            </Typography>
                            <Button variant='contained' onClick={onApply}>
                                Apply
                            </Button>

                        </Stack>
                    </Container>
                </Popover>
            }
        </div>
    )
}
