import {
  Badge,
  Box,
  Grid,
  IconButton,
  Popover,
  List,
  ListItem,
  Link,
  Typography,
  Button,
  CircularProgress,
  Divider,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  FilterGenerator,
  SearchFilter,
  Subheader,
  TableWithPagination,
  UseDebounce,
} from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import {
  accessCheckRender,
  enumSelect,
  enum_types,
  getCompanyOption,
  getRoutePermissionNew,
  LocalStorageKeys,
  NetWorkCallMethods,
  wrapLabelsInT,
} from "../../utils";
import { Quotationheading, Quotationpath } from "../../utils/quotations";
import { quotationsStyles } from "./styles";
import { Routes } from "../../router/routes";
import FilterIMG from "../../assets/filter";
import { DownloadImage } from "../../assets";
import InfiniteScroll from "react-infinite-scroll-component";
import { AlertProps } from "../../utils";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { withTranslation } from "react-i18next";

const QuotationsList = (props) => {
  const search = useLocation().search;
  const filter = new URLSearchParams(search).get("filter");
  const initial_filter = filter ? [filter] : ["Active", "Interested"];
  const { t } = props;
  const classes = quotationsStyles();
  const navigate = useNavigate();
  const debounce = UseDebounce();
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext);
  const [searchText, setSearchText] = React.useState("");
  const [companyList, setCompanyList] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState({});
  const [permission, setPermission] = React.useState({});
  const [selectedTeams, setSelectedTeams] = React.useState("");
  const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId);
  const [fiterDrawer, setFilterDrawer] = React.useState(false);
  const [filterData, setFilterData] = React.useState({ quote_status: [] });
  const [enumValue, setEnumValue] = React.useState({ quote_status: [] });
  const [downloadedFiles, setDownloadedFiles] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [scrollOffset, setScrollOffset] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isReportLoadind, setIsReportLoading] = React.useState(true);
  const clientId = localStorage.getItem(LocalStorageKeys.clinetID);
  const [pendingFiles, setPendingFiles] = useState([]);
  const open = Boolean(anchorEl);

  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");
  const limitParam = searchParams.get("limit");

  // Default values if not set in URL
  const pageFromParams = pageParam ? parseInt(pageParam, 10) : 1;
  const limitFromParams = limitParam ? parseInt(limitParam, 10) : 10;

  const [page, setPage] = React.useState(pageFromParams);
  const [limit, setLimit] = React.useState(limitFromParams);

  const updatePage = (newPage, newLimit = limit) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", newPage.toString());
    searchParams.set("limit", newLimit.toString());

    // Update URL without reloading
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });

    // Calculate offset
    const newOffset = (newPage - 1) * newLimit;

    // Update state
    setPage(newPage);
    setLimit(newLimit);

    getQuotationTableListData(
      selectedCompany?.value,
      selectedTeams,
      newOffset,
      newLimit,
      "",
      filterData
    );
  };

  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        getEnum();
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          setCompanyList(company?.list);
          setSelectedCompany(company?.selected);
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (pendingFiles.length > 0 && open) {
        // get ids of pending files
        const pendingFileIds = Array.isArray(pendingFiles)
          ? pendingFiles
              .map((file) => file?.id)
              .filter((id) => id !== undefined)
          : [];

        performNetworkCall(
          `${config.api_url}/360_reports/get_report_status`,
          NetWorkCallMethods.post,
          { report_ids: pendingFileIds },
          (response) => {
            // filter undefined data in the array and remove them
            const filteredPendingFiles = pendingFiles.filter(
              (file) => file !== undefined
            );

            // get response files with is_completed = true or is_failed = true
            const updatedFiles = response.data.data;
            const completedOrFailedFiles = updatedFiles.filter(
              (file) => file.is_completed || file.is_failed
            );

            // remove completed or failed files from pending files
            const newPendingFiles = filteredPendingFiles.filter(
              (file) =>
                !completedOrFailedFiles.some(
                  (completedOrFailedFile) =>
                    completedOrFailedFile.id === file.id
                )
            );

            setPendingFiles(newPendingFiles);

            // update downloaded files with the new data
            const newDownloadedFiles = downloadedFiles.map((file) => {
              const updatedFile = updatedFiles.find(
                (updatedFile) => updatedFile.id === file.id
              );
              if (updatedFile) {
                return updatedFile;
              }
              return file;
            });

            setDownloadedFiles(newDownloadedFiles);
          }
        );
      }
    }, 5000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingFiles, open]);

  // Function to get Enum value
  const getEnum = async () => {
    const result = await enumSelect([enum_types.quote_status]);
    setFilterData({
      quote_status: result?.quote_status?.filter((i) =>
        Boolean(initial_filter.includes(i?.value))
      ),
    });
    setEnumValue({ quote_status: result?.quote_status });
  };

  // eslint-disable-next-line
  const [quotationList, setQuotationList] = React.useState({
    list: [],
    count: 0,
  });

  React.useEffect(() => {
    if (selectedTeams?.value) {
      const currentOffset = (page - 1) * limit;
      getQuotationTableListData(
        selectedCompany?.value,
        selectedTeams,
        currentOffset,
        limit,
        "",
        filterData
      );
    }
    // eslint-disable-next-line
  }, [selectedTeams]);
  //get quoation list
  const getQuotationTableListData = (
    company_id,
    team,
    offset = 0,
    limit = 10,
    searchText,
    filter
  ) => {
    backdrop.setBackDrop({ ...backdrop, open: true, message: "" });

    const payload = {
      tenantId: `${config.tenantId}`,
      company_id: company_id,
      searchText: searchText,
      offset: offset,
      limit: limit,
      quote_status: filter?.quote_status?.map((i) => i.value),
    };
    if (team?.value !== "noteams") {
      payload["team"] = team?.value;
    }
    NetworkCall(
      `${config.api_url}/quotation/getAll`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let temp_list = response?.data?.list?.map((i) => {
          return {
            ...i,
            value: `${i?.symbol} ${i?.total_amount?.toLocaleString()}`,
            tax: `${i?.symbol} ${i?.total_tax?.toLocaleString()}`,
          };
        });
        setQuotationList({
          list: Array.isArray(temp_list) ? temp_list : [],
          count: response?.data?.count,
        });
        backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //on click rows
  const handleIcon = (type, data) => {
    if (type === "view") {
      navigate(`${Routes?.quotation_view}?id=${data?.id}`);
    }
  };
  //on search
  const handleSearch = (e) => {
    setSearchText(e);
    debounce(() => searchTableFunction(e), 800);
  };
  //search function
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getQuotationTableListData(
      selectedCompany?.value,
      selectedTeams,
      0,
      10,
      e,
      filterData
    );
  };
  //handle pagination
  const handlePagination = (newPage) => {
    updatePage(newPage, limit);
  };

  const handleChangeLimit = (newLimit) => {
    updatePage(1, newLimit); // Reset page to 1 when limit changes
  };
  //on switch company
  const handleCompanyChange = (company) => {
    setSelectedCompany(company);
  };
  // Function to change property
  const handleTeamsChange = (team) => {
    setSelectedTeams(team);
  };

  const handleFilterChange = (filter) => {
    setFilterData(filter);
    getQuotationTableListData(
      selectedCompany?.value,
      selectedTeams,
      0,
      limit,
      "",
      filter
    );
  };

  const performNetworkCall = async (url, method, payload, onSuccess) => {
    try {
      const response = await NetworkCall(
        url,
        method,
        payload,
        null,
        true,
        false
      );
      if (response.status === 200) {
        onSuccess(response);
      } else {
        console.error("Failed to store report details");
      }
    } catch (error) {
      alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Some Thing Went Wrong"),
      });
    }
  };

  const handleDownload = async (event) => {
    setAnchorEl(event.currentTarget);
    setIsReportLoading(true);
    const newScrollOffset = 0;
    setScrollOffset(newScrollOffset);
    performNetworkCall(
      `${config.api_url}/360_reports/get_reports`,
      NetWorkCallMethods.post,
      { report_type: "quotation", offset: newScrollOffset, limit: 5 },
      (response) => {
        const getData = response.data.data;
        setDownloadedFiles(getData);

        // eslint-disable-next-line array-callback-return
        const queuedReports = getData.map((file) => {
          if (!file.is_completed) {
            return file;
          }
        });
        setPendingFiles(queuedReports);

        console.log("Report details stored successfully");
        setIsReportLoading(false);
        setIsLoading(false);
        setIsDownloadLoading(false);
      }
    );
  };

  const generateRandomAlphanumeric = (length) => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };

  const handleDownloadReport = async () => {
    setIsLoading(true);
    setIsDownloadLoading(true);
    let fileName = "";
    const date = new Date();
    const yy = String(date.getFullYear());
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    const hh = String(date.getHours()).padStart(2, "0");
    const min = String(date.getMinutes()).padStart(2, "0");
    const randomAlphanumeric = generateRandomAlphanumeric(6);
    fileName = `quotation_report_${yy}_${mm}_${dd}_${hh}_${min}_${randomAlphanumeric}.xlsx`;
    performNetworkCall(
      `${config.api_url}/360_reports/report_downloads`,
      NetWorkCallMethods.post,
      {
        file_name: fileName,
        report_type: "quotation",
        is_completed: false,
        is_failed: false,
        file_url: null,
        company_id: selectedCompany?.value,
        client_id: clientId,
        status: filterData?.status,
        quote_status: filterData?.quote_status?.map((status) => status.value),
      },
      async () => {
        console.log("Report details stored successfully");
        const newScrollOffset = 0;
        setScrollOffset(newScrollOffset);
        performNetworkCall(
          `${config.api_url}/360_reports/get_reports`,
          NetWorkCallMethods.post,
          { report_type: "quotation", offset: newScrollOffset, limit: 5 },
          (response) => {
            const getData = response.data.data;

            setDownloadedFiles(getData);
            // eslint-disable-next-line array-callback-return
            const queuedReports = getData.map((file) => {
              if (!file.is_completed) {
                return file;
              }
            });
            setPendingFiles(queuedReports);

            console.log("Report details stored successfully");
            setIsLoading(false);
            setIsDownloadLoading(false);
          }
        );
      }
    );
  };

  const fetchMoreReport = () => {
    setIsLoadingMore(true);
    const newScrollOffset = scrollOffset + 5;
    setScrollOffset(newScrollOffset);
    performNetworkCall(
      `${config.api_url}/360_reports/get_reports`,
      NetWorkCallMethods.post,
      { report_type: "quotation", offset: newScrollOffset, limit: 5 },
      (response) => {
        const getData = response.data.data;
        setDownloadedFiles([...downloadedFiles, ...getData]);

        // get files with is_completed = false
        // eslint-disable-next-line array-callback-return
        const queuedReports = [...downloadedFiles, ...getData].map((file) => {
          if (!file.is_completed) {
            return file;
          }
        });
        setPendingFiles(queuedReports);

        console.log("Report details stored successfully");
        setIsReportLoading(false);
        setIsLoading(false);
        setIsDownloadLoading(false);
        setIsLoadingMore(false);
      }
    );
  };

  const handleOpenFile = (fileName) => {
    const fileUrl = fileName;
    window.open(fileUrl, "_blank");
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDownloadedFiles([]);
  };

  const id = open ? "simple-popover" : undefined;
  function truncateFileName(fileName) {
    const maxLength = 15;
    if (fileName.length <= maxLength * 2) {
      return fileName;
    }
    const start = fileName.slice(0, maxLength);
    const end = fileName.slice(-maxLength);
    return `${start}...${end}`;
  }
  const render = () => {
    return (
      <>
        {
          //loading section
          <div className={classes.root}>
            {/*sub header*/}
            <Subheader
              title={t("Quotations")}
              hideBackButton
              select
              options={companyList}
              value={selectedCompany}
              onchange={(value) => {
                handleCompanyChange(value);
              }}
              selectTeamsOptions
              handleTeamsChange={handleTeamsChange}
              companyId={selectedCompany?.value}
              moduleId={moduleId}
              selectedTeams={selectedTeams}
            />
            <Box p={2} className={classes.table} m={2}>
              <Grid container>
                <Grid item xs={4}>
                  {/*search field*/}
                  <SearchFilter
                    value={searchText}
                    placeholder={t("Search Quotations")}
                    handleChange={(value) => handleSearch(value)}
                    customfieldSx={{
                      "& .MuiOutlinedInput-root": { height: "40px" },
                    }}
                  />
                </Grid>
                <Grid item xs={8} display={"flex"} justifyContent={"end"}>
                  <Box display={"flex"} justifyContent={"end"}>
                    <IconButton
                      size="small"
                      className={classes.img}
                      onClick={handleDownload}
                      // disabled={list?.data === 0}
                    >
                      <DownloadImage />
                    </IconButton>

                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <div className={classes.tray}>
                        <div className={classes.buttonWrapper}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleDownloadReport}
                            disabled={isLoading || isDownloadLoading} // Disable button when loading or download is in progress
                            className={classes.button}
                          >
                            {isDownloadLoading ? (
                              <CircularProgress size={24} />
                            ) : (
                              "Download Report"
                            )}
                          </Button>
                        </div>
                        <Divider />

                        <List className={classes.list}>
                          {isReportLoadind ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <CircularProgress size={34} />
                            </div>
                          ) : downloadedFiles.length === 0 ? (
                            <Typography style={{ textAlign: "center" }}>
                              No export history
                            </Typography>
                          ) : (
                            <InfiniteScroll
                              dataLength={downloadedFiles.length}
                              next={() => fetchMoreReport()}
                              hasMore={true}
                              height={150}
                            >
                              <Grid>
                                {downloadedFiles.map((file, index) => (
                                  <ListItem
                                    key={index}
                                    className={classes.listItem}
                                  >
                                    <Tooltip title={file.file_name} arrow>
                                      {file.is_completed ? (
                                        <Link
                                          className={classes.fileName}
                                          onClick={() =>
                                            handleOpenFile(file.file_url)
                                          }
                                          component="button"
                                          variant="body2"
                                        >
                                          {truncateFileName(file.file_name)}
                                        </Link>
                                      ) : (
                                        <Typography
                                          variant="body2"
                                          className={classes.disabledFileName}
                                          style={{ color: "gray" }}
                                        >
                                          {truncateFileName(file.file_name)}
                                        </Typography>
                                      )}
                                    </Tooltip>
                                    {file.is_completed ? null : file.is_failed ? (
                                      <WarningAmberRoundedIcon
                                        className={classes.errorIcon}
                                        style={{
                                          color: "red",
                                          fontSize: "18px",
                                        }}
                                      />
                                    ) : (
                                      <CircularProgress size={24} />
                                    )}
                                  </ListItem>
                                ))}
                                {isLoadingMore && (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <CircularProgress size={24} />
                                  </div>
                                )}
                              </Grid>
                            </InfiniteScroll>
                          )}
                        </List>
                      </div>
                    </Popover>

                    <Divider
                      orientation="vertical"
                      p={1}
                      height={2}
                      sx={{ marginInline: "16px" }}
                    />
                    <IconButton
                      onClick={() => setFilterDrawer(!fiterDrawer)}
                      className={classes.filterButton}
                    >
                      <Badge
                        variant="dot"
                        color="primary"
                        invisible={!(filterData?.quote_status?.length > 0)}
                      >
                        <FilterIMG color="#091b29" />
                      </Badge>
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
              {/*table container*/}
              <TableWithPagination
                heading={Quotationheading(t)}
                rows={quotationList?.list}
                path={Quotationpath}
                showpagination={true}
                count="2"
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                tableType="no-side"
                handleIcon={handleIcon}
                onClick={() => console.log("")}
                dataType={[
                  { type: ["text"], name: "quotation_no" },
                  { type: ["date"], name: "created_at" },
                  { type: ["long_text"], name: "description" },
                  { type: ["text"], name: "purpose" },
                  { type: ["text"], name: "quotation_type" },
                  { type: ["text"], name: "units" },
                  { type: ["text"], name: "value" },
                  { type: ["text"], name: "tax" },
                  { type: ["date"], name: "quotation_expiry_date" },
                  { type: ["text"], name: "owner" },
                  { type: ["q_status"], name: "status" },
                  // { type: ["text"], name: "lead_no" },
                ]}
                handlePagination={handlePagination}
                handleChangeLimit={handleChangeLimit}
                totalRowsCount={quotationList?.count}
                page={page}
                limit={limit}
                height={`calc(100vh - 300px)`}
                view={permission?.read}
                edit={permission?.update}
                delete={permission?.delete}
              />
            </Box>
            <FilterGenerator
              open={fiterDrawer}
              onClose={() => setFilterDrawer(false)}
              onApply={(value) => handleFilterChange(value)}
              components={[
                {
                  component: "select",
                  value: wrapLabelsInT(filterData?.quote_status, t),
                  options: wrapLabelsInT(enumValue?.quote_status, t),
                  isMulti: true,
                  state_name: "quote_status",
                  label: t("Quotation Status"),
                  placeholder: t("Select Quotation Status"),
                },
              ]}
            />
          </div>
        }
      </>
    );
  };

  return <>{accessCheckRender(render, permission)}</>;
};
export default withTranslation("quotationsList")(QuotationsList);
