import { Box } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { InspectionAssginee, LoadingSection, UseDebounce } from "../../../components";
import { InspectionDetails, InspectionTable } from "../components";
import { AggrementTabStyles } from "./styles";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods } from "../../../utils";
import { config } from "../../../config";

const Insepection = ({ id = "", t, details }) => {
    const classes = AggrementTabStyles();
    const debounce = UseDebounce();
    const [searchText, setSearchText] = React.useState("");
    const [loading, setLoading] = React.useState(true)
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);

    const [list, setList] = React.useState({
        list: [],
        count: 0
    })
    const [selectedInspection, setSelectedInspection] = React.useState({
        bool: false,
        data: {}
    })
    const [view, setView] = React.useState({
        data: {},
        bool: false
    })

    //get inspection list
    const getInspectionList = (offset = 0, limit = 10, search = "") => {
        setLoading(true)



        const payload={
            agreement_id: id,
                offset: offset,
                limit: limit,
                search: search
        }
        NetworkCall(
            `${config.api_url}/agreement_inspection/get_inspection`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {

            setList({
                list: response?.data?.agreement_inspection?.map((val) => {
                    return (
                        {
                            id: val?.id,
                            inspection_id: val?.reference_id,
                            unit_id: val?.units?.id,
                            unit_no: val?.units?.name,
                            property_name: val?.units?.property?.name,
                            tenant_name: val?.agreement_units?.agreement_unit_users?.[0]?.contact?.first_name,
                            assigned_on: val?.created_at,
                            status: val?.status,
                            agreement_units_id: val?.agreement_units?.id,
                            requset_id: val?.request?.id,
                            agreement_id: val?.request?.agreement_id,
                            is_parking_component: val?.is_parking_component,
                            is_parking_unit: val?.is_parking_unit,
                            request_type: val?.request?.request_type,
                            requested_date: val?.execution,
                            contact_number: `${val?.agreement_units?.agreement_unit_users?.[0]?.contact?.mobile_no}`
                        }
                    )
                }),
                count: response?.data?.count?.[0]?.count
            })
            setLoading(false)

        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }
    //initial load
    React.useEffect(() => {
        getInspectionList()
        // eslint-disable-next-line
    }, [])
    //handle view
    const handleView = (data = {}) => {
        setView({
            bool: !selectedInspection?.bool,
            data: data
        })
    }
    //handle click table
    const handleClick = (type, data = {}) => {
        if (type === "assign") {
            setSelectedInspection({
                bool: !selectedInspection?.bool,
                data: data
            })
        } else if (type === "view") {
            handleView(data)
            setSelectedInspection({
                bool: false,
                data: data
            })
        }
    }
    //close view
    const closeView = (data = {}) => {
        setView({
            bool: false,
            data: view?.data
        })
    }
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getInspectionList(offset, limit, "")
    }
    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getInspectionList(0, value, "")
    }
    //on search
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getInspectionList(0, 10, e)
    }

    return (
        <Box >
            {
                loading ?
                    <LoadingSection top="20vh" message={t("loading")} />
                    :
                    <Box>
                        {
                            view?.bool ?
                                <InspectionDetails agreement_id={id} selected={selectedInspection?.data} onClose={closeView} />
                                :
                                <Box className={classes.kycbox} p={2}>
                                    <InspectionTable
                                        handleChangeLimit={handleChangeLimit}
                                        handlePagination={handlePagination}
                                        page={page}
                                        list={list}
                                        limit={limit}
                                        handleSearch={handleSearch}
                                        searchText={searchText}
                                        handleIcon={handleClick} />
                                    <InspectionAssginee
                                        t={t}
                                        reload={() => getInspectionList()}
                                        selected={selectedInspection?.data}
                                        lead_id={details?.quotation?.lead?.id}
                                        company_id={details?.company?.id}
                                        revenue_type={details?.quotation?.revenue_type}
                                        open={selectedInspection?.bool}
                                        onClose={() => handleClick("assign")} />
                                </Box>

                        }
                    </Box>

            }


        </Box>
    )
}
export default withTranslation("agreement")(Insepection); 