import { Checkbox, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import * as React from "react";
import { FontFamilySwitch } from "../../utils";

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  checkbox: {
    color: theme.palette.border.primary,
    "&.Mui-checked": {
      color: theme.palette.primary,
    },
  },
  Label: {
    color: theme.typography.color.tertiary,
    fontSize:"0.75rem",
  },
}));
const CheckBox = (props) => {
  const classes = useStyles();
  const getLabel = (props) => {
    return (
      <Typography variant="body1" className={classes.Label} gutterBottom>
        {props.heading}{props.isrequired && <Typography variant="caption" style={{ color: "red" }}>*</Typography>}
      </Typography>
    );
  };
  return (
    <>
      {getLabel(props)}
      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            value={props.value}
            control={<Checkbox className={classes.checkbox} />}
            label={props.label}
            labelPlacement={props.labelPlacement}
            className={classes.text}
            onChange={(e, checked) => props.onChange(checked)}
          />
        </FormGroup>
      </FormControl>
    </>
  );
};
CheckBox.propTypes = {
  heading: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  labelPlacement: "end" | "start",
};
CheckBox.defaultProps = {
  heading: "",
  label: "",
  value: "",
  labelPlacement: "end",
  onChange: () => { },
};

export default CheckBox;
