import {
  Grid,
  Stack,
  Typography,
  Box,
  Divider,
  Button,
  Tabs,
  Tab,
  Drawer,
} from "@mui/material";
import React from "react";
import { ProjectStyle } from "./style";
import styled from "@mui/material/styles/styled";
import ShowMoreText from "react-show-more-text";
import { tabsClasses } from "@mui/material/Tabs";
import {
  ActualBOMTableDataType,
  ActualBOMTableHeading,
  ActualBOMTablePath,
  BlockTableDataType,
  BlockTableHeader,
  BlockTablePath,
  InvoiceTableDataType,
  InvoiceTableHeader,
  InvoiceTablePath,
  MaintanceReqTableDataType,
  MaintanceReqTableHeading,
  MaintanceReqTablepath,
  ProgressFormTableDataType,
  ProgressFormTableHeader,
  ProgressFormTablePath,
  ProjectedBOMTableDataType,
  ProjectedBOMTableHeader,
  ProjectedBOMTablePath,
  PropertyTableDataType,
  PropertyTableHeader,
  PropertyTablePath,
  ServiceJobTableDataType,
  ServiceJobTableHeading,
  ServiceJobTablePath,
  SpecificationTableDataType,
  SpecificationTableHeader,
  SpecificationTablePath,
  UnitTableDataType,
  UnitTableHeader,
  UnitTablePath,
  addFormButtom,
  buttonName,
  viewTabList,
} from "./utils";
import { MemberSelectBox } from "./components/memberSelectBox";
import {
  DialogDrawer,
  FormGenerator,
  SearchFilter,
  UseDebounce,
} from "../../components";
import { MemberTable } from "./components/memberTable";
import { SpecificationTable } from "./components/specificationTable";
import { CloseIconWithBG } from "../../assets";
import { MemberForm } from "./components/memberForm";
import { PropertyBlockUnitForm } from "./components/propertyBlockUnitForm";
import { AddProjectedBOM } from "./components/addProjectedBOM";
import { ProgressForm } from "./components/progressForm";
import { ViewDetail } from "./components/viewDetail";
import { ProjectContext } from "./projectContext";
import moment from "moment";
import { EditForm } from "./editForm";
import { ActualBOMPreview } from "./actualBOMPreview";
import ProjectedHourIcon from "./utils/projectedHourIcon";
import { BackdropContext } from "../../contexts";
import ReactQuill from "react-quill";
import CloseIcon from "@mui/icons-material/Close";

const CustomPaper = styled("div")(({ theme }) => ({
  border: "2px solid white",
  borderRadius: theme.palette.borderRadius,
  boxShadow: "0px 0px 16px #00000014",
  backgroundColor: "white",
  // padding: "16px",
  height: `calc(100vh - 152px)`,
  overflow: "hidden",
  margin: "16px",
}));

export const ViewForm = ({ t = () => false }) => {
  const {
    viewData,
    upsertProjectMemeber,
    getProjectMember,
    upsertProjectProperty,
    tableData,
    handleTabSwitch,
    handleTableIconfunc,
    upsertProjectSpecification,
    data,
    setData,
    specificationEdit,
    upsertProjectedBOM,
    upsertProjectBlock,
    addMember,
    setAddMember,
    upsertProjectUnit,
    upsertProgressForm,
    openCreateForm,
    setOpenCreateForm,
    handleCloseForm,
    progressForm,
    itemDetails,
    openActualDrawer,
    setOpenActualDrawer,
    projectedBOMEdit,
    selectedCompany,
    searchText,
    setSearchText,
    handleTable,
    publishProject,
    loading,
    editFormDisable,
    setEditFormDisable,
    addTableData,
    setAddTableData,
  } = React.useContext(ProjectContext);
  const backdrop = React.useContext(BackdropContext);
  const language = localStorage.getItem("i18nextLng");
  const [value, setValue] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [viewDetail, setViewDetail] = React.useState(false);
  const [addHour, setAddHour] = React.useState(false);

  const classes = ProjectStyle({
    value: viewData?.type === "Property" && value > 1 ? value + 1 : value,
  });
  const debounce = UseDebounce();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setData({
      ...data,
      selectedValue: newValue,
    });
    handleTabSwitch(
      viewData?.type === "Property" && newValue > 1 ? newValue + 1 : newValue
    );
    setLimit(10);
    setPage(1);
  };

  const addFormButtonDisable = {
    0: addMember?.projectMember?.length === 0 ? true : false,
    1: addTableData?.property?.length === 0 ? true : false,
    2: addTableData?.block?.length === 0 ? true : false,
    3: addTableData?.unit?.length === 0 ? true : false,
    4: loading,
    5: loading,
    6: progressForm?.isView ? true : false,
    7: loading,
  };

  const hourDetails = [
    {
      icon: <ProjectedHourIcon />,
      label: t("Projected Hours"),
      value: `${viewData?.planned_hours} Hrs`,
    },
    // {
    //     icon: <ActualHour />,
    //     label: "Actual Hours",
    //     value: "2600 Hrs"
    // },
    // {
    //     icon: <ChangeReqHourIcon />,
    //     label: "Change Request Hours",
    //     isLink: true
    // },
    // {
    //     icon: <ActualHour />,
    //     label: "Billable Hour",
    //     value: "1400 Hrs"
    // },
    // {
    //     icon: <ActualHour />,
    //     label: "Non Billable Hour",
    //     value: "800 Hrs"
    // }
  ];

  const tableUtils = {
    1: {
      heading: PropertyTableHeader(t),
      path: PropertyTablePath,
      dataType: PropertyTableDataType,
      row: tableData?.property?.data,
      count: tableData?.property?.count,
    },
    2: {
      heading: BlockTableHeader(t),
      path: BlockTablePath,
      dataType: BlockTableDataType,
      row: tableData?.block?.data,
      count: tableData?.block?.count,
    },
    3: {
      heading: UnitTableHeader(t),
      path: UnitTablePath,
      dataType: UnitTableDataType,
      row: tableData?.unit?.data,
      count: tableData?.unit?.count,
    },
    4: {
      heading: SpecificationTableHeader(t),
      path: SpecificationTablePath,
      dataType: SpecificationTableDataType,
      row: tableData?.specification?.data,
      count: tableData?.specification?.count,
    },
    5: {
      heading: ProjectedBOMTableHeader(t),
      path: ProjectedBOMTablePath,
      dataType: ProjectedBOMTableDataType,
      row: tableData?.projectedBOM?.data,
      count: tableData?.projectedBOM?.count,
    },
    6: {
      heading: ProgressFormTableHeader(t),
      path: ProgressFormTablePath,
      dataType: ProgressFormTableDataType,
      row: tableData?.progressForm?.data,
      count: tableData?.progressForm?.count,
    },
    7: {
      heading: MaintanceReqTableHeading(t),
      path: MaintanceReqTablepath,
      dataType: MaintanceReqTableDataType,
      row: tableData?.maintanceReq?.data,
      count: tableData?.maintanceReq?.count,
    },
    8: {
      heading: ServiceJobTableHeading(t),
      path: ServiceJobTablePath,
      dataType: ServiceJobTableDataType,
      row: tableData?.serviceJob?.data,
      count: tableData?.serviceJob?.count,
    },
    9: {
      heading: ActualBOMTableHeading(t),
      path: ActualBOMTablePath,
      dataType: ActualBOMTableDataType,
      row: tableData?.actualBOM?.data,
      count: tableData?.actualBOM?.count,
    },
    10: {
      heading: InvoiceTableHeader(t),
      path: InvoiceTablePath,
      dataType: InvoiceTableDataType,
      row: tableData?.invoice?.data,
      count: tableData?.invoice?.count,
    },
  };

  React.useEffect(() => {
    // getProjectMember(viewData?.id, 0, 10, "")
    setValue(data?.selectedValue ?? 0);
    handleTabSwitch(data?.selectedValue);
    // eslint-disable-next-line
  }, []);

  const handleTableIcon = (type, data, boolCheckBox, checkBoxId, value) => {
    handleTableIconfunc(type, data, boolCheckBox, checkBoxId, value);
  };

  const handlePagination = (val) => {
    setPage(val);
    let offset = (val - 1) * limit;
    handleTable(offset, limit, "", value);
    // getCustomerList(offset, limit, "", [], [], null)
  };

  const handleChangeLimit = (val) => {
    setLimit(val);
    setPage(1);
    handleTable(0, val, "", value);
    // getCustomerList(0, value, "", [], [], null)
  };

  const handleSearch = (text) => {
    setSearchText(text);
    debounce(() => handleTable(0, 10, text, value), 800);
  };

  const allhandleCheck = (is_checked, data) => {
    console.log("is_checked", is_checked, data);
  };
  const handleAddForm = (value) => {
    // setLoading(true)
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    switch (value) {
      case 0:
        upsertProjectMemeber(viewData?.id);
        break;
      case 1:
        upsertProjectProperty(viewData?.id);
        break;
      case 2:
        upsertProjectBlock(viewData?.id);
        break;
      case 3:
        upsertProjectUnit(viewData?.id);
        break;
      case 4:
        upsertProjectSpecification(viewData?.id);
        break;
      case 5:
        upsertProjectedBOM(viewData?.id);
        break;
      case 6:
        upsertProgressForm(viewData?.id);
        break;
      default:
        break;
    }
  };

  const updateForm = () => {
    setEditFormDisable(true);
    if (data?.type === "bom") {
      const constructedRow = [
        {
          inspection_items_id: projectedBOMEdit?.item?.value,
          value: projectedBOMEdit?.value,
          qty: projectedBOMEdit?.qty,
          total_value: projectedBOMEdit?.totalAmount,
          currency_id: selectedCompany?.currency?.value,
          project_id: viewData?.id,
          id: projectedBOMEdit?.id,
        },
      ];
      upsertProjectedBOM(viewData?.id, constructedRow, true);
    } else {
      const constructedRow = [
        {
          inspection_items_id: specificationEdit?.itemMaster?.value,
          project_id: viewData?.id,
          id: specificationEdit?.id,
        },
      ];
      upsertProjectSpecification(viewData?.id, constructedRow, true);
    }
  };
  const handleMemberSelect = (data) => {
    const deletedData = addTableData?.member?.filter((i) => i?.id !== data?.id);
    if (deletedData?.length === 0 || data?.id === null) {
      setAddTableData({
        ...addTableData,
        member: [
          {
            name: t("All Resource Group"),
            id: null,
          },
        ],
      });
      getProjectMember(viewData?.id, 0, 10, "", [], true);
    } else if (
      addTableData?.member
        ?.map((e) => {
          return e?.id;
        })
        ?.includes(data?.id)
    ) {
      setAddTableData({
        ...addTableData,
        member: deletedData,
      });
      const ids = deletedData
        ?.filter((i) => i?.id !== data?.id)
        ?.map((e) => {
          return e?.id;
        });
      getProjectMember(viewData?.id, 0, 10, "", ids, true);
    } else if (
      addTableData?.member?.length === 1 &&
      addTableData?.member?.[0]?.id === null
    ) {
      setAddTableData({
        ...addTableData,
        member: [data],
      });
      getProjectMember(viewData?.id, 0, 10, "", [data?.id], true);
    } else {
      if (
        addTableData?.member
          ?.map((e) => {
            return e?.id;
          })
          ?.includes(data?.id)
      ) {
        setAddTableData({
          ...addTableData,
          member: addTableData?.member?.filter((i) => i?.id !== data?.id),
        });
        const ids = addTableData?.member
          ?.filter((i) => i?.id !== data?.id)
          ?.map((e) => {
            return e?.id;
          });
        getProjectMember(viewData?.id, 0, 10, "", ids, true);
      } else {
        setAddTableData({
          ...addTableData,
          member: [...addTableData?.member, data],
        });

        const ids = [...addTableData?.member, data]?.map((e) => {
          return e?.id;
        });
        getProjectMember(viewData?.id, 0, 10, "", ids, true);
      }
    }
  };

  const handleMemberFormSelect = (data) => {
    const deletedData = addMember?.selectedMember?.filter(
      (i) => i?.id !== data?.id
    );
    if (deletedData?.length === 0 || data?.id === null) {
      setAddMember({
        ...addMember,
        selectedMember: [
          {
            name: t("All Resource Group"),
            id: null,
          },
        ],
      });
    } else if (
      addMember?.selectedMember
        ?.map((e) => {
          return e?.id;
        })
        ?.includes(data?.id)
    ) {
      setAddMember({
        ...addMember,
        selectedMember: deletedData,
      });
    } else if (
      addMember?.selectedMember?.length === 1 &&
      addMember?.selectedMember?.[0]?.id === null
    ) {
      setAddMember({
        ...addMember,
        selectedMember: [data],
      });
    } else {
      if (
        addMember?.selectedMember
          ?.map((e) => {
            return e?.id;
          })
          ?.includes(data?.id)
      ) {
        setAddMember({
          ...addMember,
          selectedMember: addMember?.selectedMember?.filter(
            (i) => i?.id !== data?.id
          ),
        });
      } else {
        setAddMember({
          ...addMember,
          selectedMember: [...addMember?.selectedMember, data],
        });
      }
    }
  };
  return (
    <>
      <CustomPaper>
        <Grid container spacing={2}>
          <Grid
            item
            xs={4}
            className={
              language === "ar" ? classes.leftBorder : classes.rightBorder
            }
          >
            <Box p={2} sx={{ height: "calc(100vh - 220px)", overflow: "auto" }}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography className={classes.basedText}>
                  {viewData?.type}
                </Typography>
                <Typography
                  className={classes.createdByText}
                  sx={{ textAlign: language === "ar" && "left" }}
                >{`${
                  viewData?.is_draft ? t("Published on") : t("Created on")
                } ${moment(viewData?.created_at).format("DD MMM YY")}, ${t("by")} ${
                  viewData?.user_profile?.first_name
                }`}</Typography>
              </Stack>

              <Typography
                className={classes.overViewTitle}
                mb={"12px"}
                mt={"14px"}
              >
                {viewData?.title}
              </Typography>
              <ShowMoreText
                lines={2}
                more={t("Show More")}
                less={t("Show Less")}
                className={classes.optionSubTitle}
                anchorClass={classes.seeMoreLessTextStyle}
                expanded={false}
                truncatedEndingComponent={"... "}
              >
                <Typography
                  className={classes.optionSubTitle}
                  dangerouslySetInnerHTML={{
                    __html:
                      (viewData?.description).replace(/\n+/g, "<br />") ?? "",
                  }}
                ></Typography>
              </ShowMoreText>
              <Stack
                direction={"row"}
                alignItems={"center"}
                mt={"12px"}
                columnGap={1}
              >
                <Typography className={classes.createdByText}>
                  {viewData?.category?.name}
                </Typography>
                <Box className={classes.dot}></Box>
                <Typography className={classes.createdByText}>
                  {viewData?.sub_category?.name}
                </Typography>
                <Box className={classes.dot}></Box>
                <Typography className={classes.createdByText}>
                  {`${moment(viewData?.planned_start_date)
                    .add(1, "days")
                    .format("DD MMM YY")} - ${moment(viewData?.planned_end_date)
                    .add(1, "days")
                    .format("DD MMM YY")}`}
                </Typography>
              </Stack>

              <Typography
                className={classes.viewText}
                mt={"12px"}
                mb={2}
                onClick={() => setViewDetail(true)}
              >
                {t("View Detail")}
              </Typography>

              <Divider></Divider>

              {/* <Typography className={classes.secText} sx={{ textTransform: "uppercase" }} mt={2}>Progress</Typography>

                            <Box className={classes.progressBox}>
                                <ProgressBar value={10} />
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} mt={"10px"}>
                                    <Stack>
                                        <Typography className={classes.viewValue}>10 % Completed</Typography>
                                        <Typography className={classes.optionSubTitle}>Last updated on 22 Jan 23</Typography>
                                    </Stack>
                                    <KeyboardArrowRightIcon />
                                </Stack>
                            </Box> */}

              <Typography
                className={classes.secText}
                sx={{ textTransform: "uppercase" }}
                mt={2}
              >
                {t("Hours Details")}
              </Typography>
              <Box
                className={classes.progressBox}
                sx={{ padding: "0px 16px !important" }}
              >
                {hourDetails?.map((e, i) => {
                  return (
                    <>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        py={"14px"}
                      >
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          columnGap={1}
                        >
                          {e?.icon}
                          <Typography className={classes.viewLabel}>
                            {e?.label}
                          </Typography>
                        </Stack>
                        <Typography
                          className={
                            e?.isLink ? classes.viewTextBig : classes.viewValue
                          }
                          onClick={() => e?.isLink && setAddHour(true)}
                        >
                          {e?.isLink ? t("Add Hours") : e?.value}
                        </Typography>
                      </Stack>
                      {hourDetails?.length - 1 !== i && (
                        <Box>
                          <Divider></Divider>
                        </Box>
                      )}
                    </>
                  );
                })}
              </Box>
            </Box>
            {!viewData?.is_draft && (
              <Box p={2}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() =>
                    setData({
                      publishBool: true,
                    })
                  }
                >
                  {t("Publish Project")}
                </Button>
              </Box>
            )}
          </Grid>
          <Grid item xs={8} sx={{ paddingLeft: "0px !important" }}>
            <Box
              sx={{
                // flexGrow: 1,
                borderBottom: "1px solid #CED3DD",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                aria-label="visible arrows tabs example"
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    "&.Mui-disabled": { opacity: 0.3 },
                  },
                }}
                className={classes.tabList}
              >
                {viewTabList(t, viewData?.type)?.map((d) => {
                  return (
                    d?.isActive && (
                      <Tab
                        label={d?.label}
                        sx={{ padding: "0px 16px !important" }}
                      />
                    )
                  );
                })}
              </Tabs>
            </Box>

            <Grid container p={language === "ar" ? "12px 12px 12px 30px" : 2}>
              <Grid item xs={4}>
                <SearchFilter
                  value={searchText}
                  label=""
                  placeholder={t("Search")}
                  handleChange={(e) => handleSearch(e)}
                />
              </Grid>
              <Grid item xs={8} sx={{ display: "flex", justifyContent: "end" }}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  columnGap={"12px"}
                >
                  {value === 0 && (
                    <MemberSelectBox
                      data={viewData}
                      selected={addTableData?.member}
                      onChange={(value) => {
                        handleMemberSelect(value);
                      }}
                      initial
                      t={t}
                      customSelectedWidth="150px"
                    />
                  )}
                  {[0, 1, 2, 3, 4, 5, 6]?.includes(
                    viewData?.type === "Property" && value > 1
                      ? value + 1
                      : value
                  ) && (
                    <Button
                      variant="contained"
                      disableElevation
                      sx={{ height: "40px" }}
                      onClick={() => setOpenCreateForm(true)}
                    >
                      {
                        buttonName(t)?.[
                          viewData?.type === "Property" && value > 1
                            ? value + 1
                            : value
                        ]
                      }
                    </Button>
                  )}
                </Stack>
              </Grid>
            </Grid>

            <Box p={language === "ar" ? "12px 12px 12px 30px" : "0px 12px"}>
              {value === 0 && (
                <MemberTable
                  handleTableIcon={handleTableIcon}
                  handlePagination={handlePagination}
                  handleChangeLimit={handleChangeLimit}
                  allhandleCheck={allhandleCheck}
                  type="member"
                  page={page}
                  limit={limit}
                  t={t}
                  value={value}
                />
              )}
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.includes(value) && (
                <MemberTable
                  handleTableIcon={handleTableIcon}
                  handlePagination={handlePagination}
                  handleChangeLimit={handleChangeLimit}
                  allhandleCheck={allhandleCheck}
                  type="nonMember"
                  page={page}
                  limit={limit}
                  heading={
                    tableUtils[
                      viewData?.type === "Property" && value > 1
                        ? value + 1
                        : value
                    ]?.heading
                  }
                  path={
                    tableUtils[
                      viewData?.type === "Property" && value > 1
                        ? value + 1
                        : value
                    ]?.path
                  }
                  dataType={
                    tableUtils[
                      viewData?.type === "Property" && value > 1
                        ? value + 1
                        : value
                    ]?.dataType
                  }
                  row={
                    tableUtils[
                      viewData?.type === "Property" && value > 1
                        ? value + 1
                        : value
                    ]?.row
                  }
                  count={
                    tableUtils[
                      viewData?.type === "Property" && value > 1
                        ? value + 1
                        : value
                    ]?.count
                  }
                  value={
                    viewData?.type === "Property" && value > 1
                      ? value + 1
                      : value
                  }
                  t={t}
                  handleViewQuill={(val) =>
                    value === 4 &&
                    setData({
                      ...data,
                      viewTitle: true,
                      selectedSpecTitle: val,
                    })
                  }
                />
              )}
            </Box>
          </Grid>
        </Grid>
        {/* Add Member Form */}
        <Drawer
          anchor={"right"}
          open={openCreateForm}
          onClose={() => {
            handleCloseForm(value);
            setValue(value);
          }}
          className={classes.drawer}
        >
          <Box className={classes.header}>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Box
                onClick={() => {
                  if (value === 6) {
                    handleCloseForm(6);
                    setOpenCreateForm(false);
                  } else {
                    setOpenCreateForm(false);
                  }
                }}
              >
                <CloseIconWithBG />
              </Box>
              <Typography className={classes.overViewTitle}>
                {t("Add")}
              </Typography>
            </Stack>
          </Box>
          <Box className={classes.body}>
            {value === 0 && (
              <MemberForm
                data={viewData}
                t={t}
                handleMemberFormSelect={handleMemberFormSelect}
              />
            )}
            {[1, 2, 3].includes(
              viewData?.type === "Property" && value > 1 ? value + 1 : value
            ) && (
              <PropertyBlockUnitForm
                type={
                  value === 1
                    ? "property"
                    : value === 2 && viewData?.type !== "Property"
                    ? "block"
                    : "unit"
                }
                data={viewData}
                t={t}
              />
            )}
            {(viewData?.type === "Property" && value > 1
              ? value + 1
              : value) === 4 && <SpecificationTable t={t} />}
            {(viewData?.type === "Property" && value > 1
              ? value + 1
              : value) === 5 && <AddProjectedBOM t={t} />}
            {(viewData?.type === "Property" && value > 1
              ? value + 1
              : value) === 6 && <ProgressForm projectData={viewData} t={t} />}
          </Box>
          <Box className={classes.bottom}>
            {value === 5 || value === 6 ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "end",
                  justiftContent: "end",
                }}
              >
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.createBtn}
                  disabled={
                    addFormButtonDisable[
                      viewData?.type === "Property" && value > 1
                        ? value + 1
                        : value
                    ]
                  }
                  onClick={() =>
                    handleAddForm(
                      viewData?.type === "Property" && value > 1
                        ? value + 1
                        : value
                    )
                  }
                >
                  {
                    addFormButtom(t)[
                      viewData?.type === "Property" && value > 1
                        ? value + 1
                        : value
                    ]
                  }
                </Button>
              </Box>
            ) : (
              <Button
                variant="contained"
                fullWidth
                className={classes.createBtn}
                disabled={
                  addFormButtonDisable[
                    viewData?.type === "Property" && value > 1
                      ? value + 1
                      : value
                  ]
                }
                onClick={() =>
                  handleAddForm(
                    viewData?.type === "Property" && value > 1
                      ? value + 1
                      : value
                  )
                }
              >
                {
                  addFormButtom(t)[
                    viewData?.type === "Property" && value > 1
                      ? value + 1
                      : value
                  ]
                }
              </Button>
            )}
          </Box>
        </Drawer>
        {/* view Detail Drawer */}

        <Drawer
          anchor={"right"}
          open={viewDetail}
          onClose={() => setViewDetail(false)}
          className={classes.editdrawer}
        >
          <Box className={classes.header}>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Box onClick={() => setViewDetail(false)}>
                <CloseIconWithBG />
              </Box>
              <Typography className={classes.overViewTitle}>
                {t("View More")}
              </Typography>
            </Stack>
          </Box>
          <Box className={classes.body}>
            <ViewDetail data={viewData} t={t} />
          </Box>
          {/* <Box className={classes.bottom}>
                        <Button variant='contained' fullWidth className={classes.createBtn}>{addFormButtom[value]}</Button>
                    </Box> */}
        </Drawer>

        {/* Add Hour */}

        <Drawer
          anchor={"right"}
          open={addHour}
          onClose={() => setAddHour(false)}
          className={classes.drawer}
        >
          <Box className={classes.header}>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Box onClick={() => setAddHour(false)}>
                <CloseIconWithBG />
              </Box>
              <Typography className={classes.overViewTitle}>
                {t("Change Request Hour")}
              </Typography>
            </Stack>
          </Box>
          <Box className={classes.body}>
            <Box p={2}>
              <FormGenerator
                spacing={2}
                components={[
                  {
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 12,
                      lg: 12,
                    },
                    isActive: true,
                    component: "text",
                    label: "Title",
                    value: "",
                    placeholder: "Enter Title",
                    onChange: (value) =>
                      console.log("title", value.target.value),
                    // error: data?.error?.oppertunity_subject,
                    isRequired: true,
                    options: [],
                    // multiline: true
                  },
                  {
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 12,
                      lg: 12,
                    },
                    isActive: true,
                    component: "text",
                    label: "Reason",
                    value: "",
                    placeholder: "Enter Reason",
                    onChange: (value) =>
                      console.log("reason", value.target.value),
                    // error: data?.error?.oppertunity_subject,
                    isRequired: true,
                    options: [],
                    multiline: true,
                  },
                  {
                    size: {
                      xs: 12,
                      sm: 12,
                      md: 12,
                      lg: 12,
                    },
                    isActive: true,
                    component: "text",
                    label: "Additional Allotted Hours",
                    value: "",
                    placeholder: "Enter Additional Allotted Hours",
                    onChange: (value) =>
                      console.log("allocated_hrs", value.target.value),
                    // error: data?.error?.oppertunity_subject,
                    isRequired: true,
                    options: [],
                    endAdornment: (
                      <Typography className={classes.hrsLabel}>Hrs</Typography>
                    ),
                  },
                ]}
              />
            </Box>
          </Box>
          <Box className={classes.bottom}>
            <Button variant="contained" fullWidth className={classes.createBtn}>
              Add
            </Button>
          </Box>
        </Drawer>
        {/* Edit form */}
        <Drawer
          anchor={"right"}
          open={data?.editSpec}
          onClose={() => setData({ ...data, editSpec: false })}
          className={classes.editdrawer}
        >
          <Box className={classes.header}>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Box onClick={() => setData({ ...data, editSpec: false })}>
                <CloseIconWithBG />
              </Box>
              <Typography className={classes.overViewTitle}>
                {data?.type === "bom"
                  ? t("Edit Actual BOM")
                  : t("Edit Specification")}
              </Typography>
            </Stack>
          </Box>
          <Box className={classes.body}>
            <Box p={2}>
              <EditForm item={data?.selectedSpecification} t={t} />
            </Box>
          </Box>
          <Box className={classes.bottom}>
            <Button
              variant="contained"
              fullWidth
              className={classes.createBtn}
              disabled={editFormDisable}
              onClick={() => updateForm()}
            >
              {t("Update")}
            </Button>
          </Box>
        </Drawer>

        <Drawer
          anchor={"right"}
          open={openActualDrawer}
          onClose={() => setOpenActualDrawer(false)}
          className={classes.drawer}
        >
          <Box className={classes.header}>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Box onClick={() => setOpenActualDrawer(false)}>
                <CloseIconWithBG />
              </Box>
              <Typography className={classes.overViewTitle}>
                {t("Actual BOM Detail")}
              </Typography>
            </Stack>
          </Box>
          <ActualBOMPreview
            data={data?.selectedActualBOM}
            itemDetails={itemDetails}
            t={t}
          />
        </Drawer>

        <DialogDrawer
          isnotTitle
          maxWidth={"xs"}
          handleClose={() =>
            setData({
              publishBool: false,
            })
          }
          open={data?.publishBool}
          height={"auto"}
          borderRadius={"12px"}
          padding={"0px"}
          onClose={() =>
            setData({
              publishBool: false,
            })
          }
          component={
            <>
              <Stack justifyContent={"center"} alignItems={"center"} p={2}>
                <Typography className={classes.overViewTitle}>
                  {t("Are you want to publish this project")}
                </Typography>
                <Stack
                  direction="row"
                  columnGap={2}
                  alignItems={"center"}
                  mt={3}
                >
                  <Button
                    variant="outlined"
                    onClick={() =>
                      setData({
                        publishBool: false,
                      })
                    }
                  >
                    {t("No")}
                  </Button>
                  <Button variant="contained" onClick={() => publishProject()}>
                    {t("Yes")}
                  </Button>
                </Stack>
              </Stack>
            </>
          }
        />

        <DialogDrawer
          header={"Specification Title"}
          isnotTitle
          maxWidth={"xs"}
          open={data?.viewTitle}
          onClose={() =>
            setData({
              ...data,
              viewTitle: false,
              selectedSpecTitle: "",
            })
          }
          padding={"0px !impotant"}
          component={
            <>
              <Box p={2}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  mb={1}
                >
                  <Box></Box>
                  <Box
                    onClick={() =>
                      setData({
                        ...data,
                        viewTitle: false,
                        selectedSpecTitle: "",
                      })
                    }
                    sx={{ cursor: "pointer" }}
                  >
                    <CloseIcon />
                  </Box>
                </Stack>
                <ReactQuill
                  readOnly
                  theme="bubble"
                  value={data?.selectedSpecTitle}
                  modules={{ toolbar: false }}
                  className={classes.sub}
                />
              </Box>
            </>
          }
        />
      </CustomPaper>
    </>
  );
};
