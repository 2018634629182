import { Grid } from "@mui/material"
import { Box } from "@mui/system"
import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import { SearchFilter, TableWithPagination } from "../../../components"
import { NewLoader } from "../../../components/newLoader"
import { config } from "../../../config"
import { NetworkCall } from "../../../networkcall"
import { getSummaryTableHeading, JobType, NetWorkCallMethods, SummaryTableDataType, SummaryTablePath, SwitchInspectionRoutes } from "../../../utils"
import { useNavigate } from "react-router"


const SummaryTable = ({ t, companyData = "", companyId = "", maintenanceRequestId = undefined, generalRequestId = undefined }) => {
    const [searchText, setSearchText] = React.useState("")
    const navigate = useNavigate()

    const [tableData, setTableData] = React.useState([])
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = useState(10)
    const [loading, setLoading] = useState(true)
    const getSummary = (offset = 0, limit = 10, company_id, search = "") => {
        const payload = {
            company_id: company_id,
            offset: offset,
            limit: limit,
            request_type: maintenanceRequestId !== undefined ? ["service", "delivery-order", "general"] : ["delivery-order"],
            maintenance_id: maintenanceRequestId,
            general_request_id: generalRequestId,
            search: search
        }

        NetworkCall(
            `${config.api_url}/general_inspection/`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setLoading(false)
            const data = response?.data?.data?.data?.map((val) => {
                return ({
                    ...val,
                    hours: val?.eta > 0 ? `${val?.eta} ${val?.eta > 1 ? "hrs" : "hr"}` : 0,
                    other_hours: val?.other_hours > 0 ? `${val?.other_hours} ${val?.other_hours > 1 ? "hrs" : "hr"}` : 0,
                    request_type: JobType(val?.request_type),
                    KPI: val?.overbooking_count > 0 ? "Delayed" : val?.ontime_count > 0 ? "Ontime" : "-",
                    actual_hours: val?.actual_hours > 0 ? `${val?.actual_hours} ${val?.actual_hours > 1 ? "hrs" : "hr"}` : val?.actual_hours === 0 ? "<1 hr" : "-",
                    per_hour_rate: val?.per_hour_rate?`${val?.per_hour_rate} ${companyData?.currency_symbol}`:"-",
                    billing_amount: val?.billing_amount?`${val?.billing_amount} ${companyData?.currency_symbol}`:"-",

                })
            })
            setTableData({ list: data, count: response?.data?.data?.count })

        }).catch((err) => {
            setLoading(false)
            console.log(err)
        })
    }
    React.useEffect(() => {
        getSummary(0, 10, companyId, "")
        //eslint-disable-next-line
    }, [])
    const handleSearch = (e) => {
        setSearchText(e)
        getSummary(0, 10, companyId, e)

    }
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getSummary(offset, 10, companyId, "")
    }
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getSummary(0, limit, companyId, "")
    }
    const handleIcon = (type, data) => {
        const request_type = data?.request_type === "Service" ? "service" : data?.request_type === "General" ? "general" : "delivery-order"
        const RoutePath = SwitchInspectionRoutes(request_type)
        navigate(RoutePath, {
            state: {
                request_id: data?.request_id,
                reference_id: data?.job_no,
                agreement_inspection_id:data?.id,
            }
        })
    }

    return (
        <Box p={2} backgroundColor={"white"}>
            {
                loading ?
                    <NewLoader minusHeight="158px" />
                    :
                    <>
                        <Grid container justifyContent="space-between">
                            <Grid item xs={4}>
                                {/*search */}
                                <SearchFilter
                                    handleChange={(e) => handleSearch(e)}
                                    value={searchText}
                                    placeholder={t("searchInspection")}
                                />
                            </Grid>
                        </Grid>
                        <TableWithPagination
                            heading={getSummaryTableHeading(t)}
                            rows={tableData?.list}
                            dataType={SummaryTableDataType}
                            path={SummaryTablePath}
                            showpagination
                            tableType="no-side"
                            handlePagination={handlePagination}
                            handleChangeLimit={handleChangeLimit}
                            handleIcon={handleIcon}

                            totalRowsCount={tableData?.count}
                            page={page}
                            limit={limit}
                            view={true}
                            height={`calc(100vh - 380px)`}
                            edit={true}
                            delete={true} />
                    </>
            }
        </Box>
    )
}
export default withTranslation("inspection")(SummaryTable)