import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  DialogDrawer,
  LoadingSection,
  NewDatePicker,
  SelectBox,
  Subheader,
  TableWithPagination,
  TextBox,
  UploadComponent,
  UseDebounce,
} from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { BackendRoutes } from "../../router/routes";
import {
  AlertProps,
  concat_string,
  constructPropertyList,
  enum_types,
  enumSelect,
  getCompanyOption,
  getRoutePermissionNew,
  NetWorkCallMethods,
  stringAvatar,
  timeZoneConverter,
} from "../../utils";
import { PrioritySelect } from "./components";
import { correspondencesStyles } from "./style";
import {
  AgreementDataType,
  AgreementHeading,
  AgreementPath,
  ContractDataType,
  ContractHeading,
  ContractPath,
  initial_data,
  ModuleDataType,
  ModuleHeading,
  ModulePath,
  PreviewTabData,
  RoleDataType,
  RoleHeading,
  RolePath,
} from "./utils/utils";
import { withTranslation } from "react-i18next";
import { accessCheckRender } from "../../utils";

const CreateEditCorrespondences = (props) => {
  const { t = () => false, loading, handleLoading } = props;

  const classes = correspondencesStyles();
  const navigate = useNavigate();
  const { state } = useLocation();

  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext);
  const debounce = UseDebounce();

  const [data, setData] = React.useState({
    ...initial_data,
    id: state?.id,
    title: state?.title ?? t("Contact Level Letter Management"),
    level: state?.level ?? 2,
  });
  const [selectedCompany, setSelectedCompany] = React.useState({});
  const [selectedPreviewTab, setSelectedPreviewTab] = React.useState(
    PreviewTabData[0]
  );
  const [options, setOptions] = React.useState({
    property: [],
    correspondences_type_master: [],
    urgent_type: [],
  });
  const [disableButton, setDisableButton] = React.useState(false);
  const [permission, setPermission] = React.useState({});
  const [optionLoading, setOptionLoading] = React.useState(false);
  const [assets, setAssets] = React.useState([]);
  const [signAsset, setSignAsset] = React.useState([]);
  const [isContactInfoOpen, setIsContactInfoOpen] = React.useState(false);
  const [isContactInfoLoading, setIsContactInfoLoading] = React.useState(true);
  const [contactInfoState, setContactInfoState] = React.useState([]);

  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        getCompany();
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  const getCompany = () => {
    let company = getCompanyOption(backdrop, auth, alert);
    if (company) {
      setSelectedCompany(company?.selected);
      getPropertyList(company?.selected);
    }
  };

  const getPropertyList = (company) => {
    let property = constructPropertyList(company);
    getOptions(property?.list);
  };

  const getList = (correspondence_id = "") => {
    let payload = { correspondence_id };
    NetworkCall(
      `${config.api_url}${BackendRoutes?.correspondences_list}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        let temp_res = res?.data?.data?.list?.[0];
        setAssets(temp_res?.assets);
        setSignAsset([
          { asset_type: 2, url: temp_res?.signature, is_active: true },
        ]);
        let temp_data = {
          ...data,

          level: temp_res?.level_id,
          property: temp_res?.property?.id
            ? { value: temp_res?.property?.id, label: temp_res?.property?.name }
            : "",
          agreement_unit: temp_res?.agreement_unit_id
            ? {
                value: temp_res?.agreement_unit_id,
                label: temp_res?.unit_name,
                first_name: temp_res.contact_name,
                address: { ...temp_res?.unit_address },
              }
            : "",
          contact: temp_res?.contact_id
            ? {
                label: (
                  <Stack direction={"row"} columnGap={2} alignItems={"center"}>
                    <Avatar
                      className={classes.optionAvatar}
                      src={temp_res?.contact_address?.image_url}
                      {...stringAvatar(temp_res?.contact_address?.first_name)}
                    />
                    <Typography className={classes.optionHeader}>
                      {temp_res?.contact_address?.first_name}
                    </Typography>
                  </Stack>
                ),
                value: temp_res.contact_id,
                address: { ...temp_res?.contact_address },
              }
            : "",
          correspondences_type: temp_res?.type_id
            ? { value: temp_res?.type_id, label: temp_res?.type_name }
            : "",
          date: temp_res?.incident_date
            ? new Date(temp_res?.incident_date)
            : new Date(),
          acknowledge_before: temp_res?.dead_line_date
            ? new Date(temp_res?.dead_line_date)
            : new Date(),
          reference_letter: temp_res?.previous_case_id
            ? {
                value: temp_res?.previous_case_id,
                label: temp_res?.previous_case_no,
              }
            : "",
          subject: temp_res?.subject,
          priority: temp_res?.priority,
          notes: temp_res?.notes,
          error: {
            property: "",
            agreement_unit: "",
            contact: "",
            correspondences_type: "",
            date: "",
            acknowledge_before: "",
            subject: "",
            priority: "",
            notes: "",
          },
        };
        setData(temp_data);
        handleLoading(false);
      })
      .catch(() => {
        alert.setSnack({
          ...alert,
          open: true,
          msg: t("Some Thing Went Wrong"),
          severity: AlertProps.severity.error,
        });
      });
  };

  const getOptions = async (property_list = []) => {
    const enum_result = await enumSelect([enum_types?.urgent_type]);
    const master_result = await NetworkCall(
      `${config.api_url}/queries/correspondences_masters/get`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    ).catch((err) => console.log(err));
    setOptions({
      property: property_list,
      correspondences_type_master:
        master_result?.data?.data?.correspondences_type_master,
      urgent_type: enum_result?.urgent_type,
    });
    if (data?.id) {
      getList(data?.id);
    } else {
      handleLoading(false);
    }
  };

  const updateData = (k, v) => {
    let error = data?.error;
    error[k] = "";
    if (k === "date") {
      let temp_date = new Date(v.toDateString());
      setData({
        ...data,
        [k]: temp_date,
        acknowledge_before: Boolean(temp_date > data?.acknowledge_before)
          ? ""
          : data?.acknowledge_before,
        error,
      });
    } else if (k === "acknowledge_before") {
      let temp_acknowledge_before = new Date(v.toDateString());
      setData({ ...data, [k]: temp_acknowledge_before, error });
    } else {
      setData({ ...data, [k]: v, error });
    }
  };

  const loadOptions = async (search = "", array, type) => {
    setOptionLoading(type);
    let result,
      payload,
      offset = 0;
    let limit = 10;
    if (search && !Boolean(array?.length)) {
      offset = 0;
    } else {
      offset = array?.length;
    }

    switch (type) {
      case "agreement_unit":
        payload = { property_id: data?.property?.value, offset, limit, search };
        result = await NetworkCall(
          `${config.api_url}${BackendRoutes?.agreement_unit_info_agreement_unit_for_property}`,
          NetWorkCallMethods.post,
          payload,
          null,
          true,
          false
        ).catch(() => {
          alert.setSnack({
            ...alert,
            open: true,
            msg: t("Some Thing Went Wrong"),
            severity: AlertProps.severity.error,
          });
        });
        setOptionLoading(null);
        return {
          options: [
            ...result?.data?.data?.map((i) => {
              return {
                label: i?.unit_name,
                value: i?.agreement_unit_id,
                first_name: i?.first_name,
                address: { ...i },
              };
            }),
          ],
          hasMore:
            array?.length + result?.data?.data?.length < result?.data?.count,
        };
      case "reference_letter_by_agreement_unit":
        payload = {
          offset: offset,
          limit: limit,
          is_active: [true],
          agreement_unit_id: data?.agreement_unit?.value,
        };
        result = await NetworkCall(
          `${config.api_url}/queries/correspondence_units/list`,
          NetWorkCallMethods.post,
          payload,
          null,
          true,
          false
        ).catch(() => {
          alert.setSnack({
            ...alert,
            open: true,
            msg: t("Some Thing Went Wrong"),
            severity: AlertProps.severity.error,
          });
        });
        setOptionLoading(null);
        return {
          options: [
            ...result?.data?.data?.correspondence_units?.map((i) => {
              return {
                label: i?.correspondenceByID?.reference_no,
                value: i?.correspondenceByID?.id,
              };
            }),
          ],
          hasMore:
            array?.length + result?.data?.data?.correspondence_units?.length <
            (result?.data?.data?.count?.[0]?.count ??
              result?.data?.data?.count),
        };
      case "reference_letter_by_contact":
        const payload2 = {
          offset: offset,
          limit: limit,
          is_active: [true],
          contact_id: data?.contact?.value,
        };
        result = await NetworkCall(
          `${config.api_url}/queries/correspondence_units/list`,
          NetWorkCallMethods.post,
          payload2,
          null,
          true,
          false
        ).catch(() => {
          alert.setSnack({
            ...alert,
            open: true,
            msg: t("Some Thing Went Wrong"),
            severity: AlertProps.severity.error,
          });
        });
        setOptionLoading(null);
        return {
          options: [
            ...(result?.data?.data?.correspondence_units?.map((i) => {
              return {
                label: i?.correspondenceByID?.reference_no ?? null,
                value: i?.correspondenceByID?.id ?? null,
              };
            }) ?? []),
          ],
          hasMore:
            (array?.length ?? 0) + (result?.data?.data?.correspondence_units?.length ?? 0) <
            (result?.data?.data?.count?.[0]?.count ??
               result?.data?.data?.count ?? 0),
        };
      case "contact_by_company":
        payload = { company_id: selectedCompany?.value, offset, limit, search };
        result = await NetworkCall(
          `${config?.api_url}${BackendRoutes?.contact_agreement_contact}`,
          NetWorkCallMethods.post,
          payload,
          null,
          true,
          false
        ).catch(() => {
          alert.setSnack({
            ...alert,
            open: true,
            msg: t("Some Thing Went Wrong"),
            severity: AlertProps.severity.error,
          });
        });
        setOptionLoading(null);
        return {
          options: [
            ...result?.data?.data?.data?.map((i) => {
              return {
                label: (
                  <Stack direction={"row"} columnGap={2} alignItems={"center"}>
                    <Avatar
                      className={classes.optionAvatar}
                      src={i?.image_url}
                      {...stringAvatar(i?.first_name)}
                    />
                    <Typography className={classes.optionHeader}>
                      {i?.first_name}
                    </Typography>
                  </Stack>
                ),
                value: i?.id,
                address: { ...i },
              };
            }),
          ],
          hasMore:
            array?.length + result?.data?.data?.data?.length <
            result?.data?.data?.count,
        };
      default:
        return { options: [] };
    }
  };

  const validate = () => {
    let isValid = true;
    let error = data.error;
    if (data?.level === 1 && data?.property?.length === 0) {
      isValid = false;
      error.property = t("Property is Required");
    }
    if (data?.level === 1 && data?.agreement_unit?.length === 0) {
      isValid = false;
      error.agreement_unit = t("Unit is Required");
    }
    if (data?.level === 2 && data?.contact?.length === 0) {
      isValid = false;
      error.contact = t("Contact is Required");
    }
    if (data?.correspondences_type?.length === 0) {
      isValid = false;
      error.correspondences_type = t("Correspondence Type is Required");
    }
    if (data?.date?.length === 0) {
      isValid = false;
      error.date = t("Date is Required");
    }
    if (data?.acknowledge_before?.length === 0) {
      isValid = false;
      error.acknowledge_before = t("Acknowledge Before is Required");
    }
    if (data?.subject?.length === 0) {
      isValid = false;
      error.subject = t("Subject is Required");
    }
    if (data?.priority?.length === 0) {
      isValid = false;
      error.priority = t("Priority is Required");
    }
    if (data?.notes?.length === 0) {
      isValid = false;
      error.notes = t("Notes is Required");
    }
    if (!isValid) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Please fill all mandatory field"),
      });
    }
    setData({ ...data, error });

    return isValid;
  };
  const handleCreateEditButton = () => {
    if (validate()) {
      setDisableButton(true);
      let payload = {
        subject: data?.subject,
        notes: data?.notes,
        type_id: data?.correspondences_type?.value,
        dead_line_date: data?.acknowledge_before
          ? new Date(data?.acknowledge_before)
          : null,
        incident_date: data?.date ? new Date(data?.date) : null,
        priority: data?.priority,
        company_id: selectedCompany?.value,
        status: 1,
        level: data?.level,
        previous_case: data?.reference_letter?.value,
        assets: assets,
        signature: signAsset?.[0]?.url,
      };
      if (data?.level === 1) {
        payload["agreement_unit_id"] = data?.agreement_unit?.value;
      } else if (data?.level === 2) {
        payload["contact_id"] = data?.contact?.value;
      }
      if (data?.id) {
        payload["id"] = data?.id;
      }
      NetworkCall(
        `${config.api_url}${
          data?.id
            ? BackendRoutes?.correspondences_update
            : BackendRoutes?.correspondences_create
        }`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((res) => {
          alert.setSnack({
            ...alert,
            open: true,
            msg: data?.id
              ? t("Correspondence updated successfully!")
              : t("Correspondence created successfully!"),
            severity: AlertProps.severity.success,
          });
          setDisableButton(false);
          navigate(-1);
        })
        .catch(() => {
          alert.setSnack({
            ...alert,
            open: true,
            msg: t("Some Thing Went Wrong"),
            severity: AlertProps.severity.error,
          });
          setDisableButton(false);
        });
    }
  };

  const handleContactInfoClick = () => {
    setIsContactInfoLoading(true);
    setIsContactInfoOpen(true);

    let payload = {
      id: data?.contact?.value,
      company_id: selectedCompany?.value,
      user_profile_id: data?.contact?.address?.user_profile_id,
    };

    NetworkCall(
      `${config.api_url}${BackendRoutes?.contact_agreement_contact_info}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const tempContactInfoState = res?.data?.data?.map?.((_) => {
          let data = {
            agreement: [],
            contract: [],
            role: [],
            module: [],
          };

          if (_?.agreement && _?.agreement?.length > 0) {
            data = {
              ...data,
              agreement: _?.agreement?.map((ag) => {
                return {
                  agreement_no: ag?.agreement_no,
                  unit:
                    (ag?.unit_name ?? "") + (ag?.unit_no && `(${ag?.unit_no})`),
                  revenue_type: ag?.revenue_type,
                  start_date: ag?.lease_start_date
                    ? timeZoneConverter(
                        new Date(ag?.lease_start_date),
                        "DD-MMM-yyyy"
                      )
                    : "-",
                  end_date: ag?.lease_end_date
                    ? timeZoneConverter(
                        new Date(ag?.lease_end_date),
                        "DD-MMM-yyyy"
                      )
                    : "-",
                };
              }),
            };
          }

          if (_?.contract && _?.contract?.length > 0) {
            data = {
              ...data,
              contract: _?.contract?.map((co) => {
                return {
                  contract_no: co?.contract_no ?? "-",
                  account_no: co?.account_no ?? "-",
                  start_date: co?.start_date
                    ? timeZoneConverter(new Date(co?.start_date), "DD-MMM-yyyy")
                    : "-",
                  end_date: co?.end_date
                    ? timeZoneConverter(new Date(co?.end_date), "DD-MMM-yyyy")
                    : "-",
                };
              }),
            };
          }

          if (_?.role && _?.role?.length > 0) {
            data = {
              ...data,
              role: _?.role?.map((ro) => {
                return {
                  role_name: ro?.name ?? "-",
                };
              }),
            };
          }

          if (_?.module && _?.module?.length > 0) {
            data = {
              ...data,
              module: _?.module?.map((mo) => {
                return {
                  module_name: mo?.name ?? "-",
                };
              }),
            };
          }

          return { ..._, data };
        });

        setContactInfoState(tempContactInfoState ?? []);
        setIsContactInfoLoading(false);
      })
      .catch(() => {
        alert.setSnack({
          ...alert,
          open: true,
          msg: t("Some Thing Went Wrong"),
          severity: AlertProps.severity.error,
        });
      });
  };

  const handleContactInfoOnClose = () => {
    setIsContactInfoOpen(false);
    debounce(() => setContactInfoState([]), 1000);
  };

  const render = () => {
    return (
      <>
        <Subheader
          goBack={() => navigate(-1)}
          isReadOnly
          select
          value={selectedCompany}
          title={data?.title ?? t("Create Unit Level Correspondence")}
        />
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          spacing={"16px"}
          padding={"12px"}
        >
          <Stack direction={"row"} className={classes.tab_div}>
            {PreviewTabData?.map?.((i) => {
              return (
                <div
                  className={
                    selectedPreviewTab === i
                      ? classes.selected_tab
                      : classes.unselected_tab
                  }
                  onClick={() => setSelectedPreviewTab(i)}
                >
                  <Typography
                    className={
                      selectedPreviewTab === i
                        ? classes.selected_tab_text
                        : classes.unselected_tab_text
                    }
                  >
                    {t(i)}
                  </Typography>
                </div>
              );
            })}
          </Stack>
          <Button
            variant="contained"
            className={classes.button}
            disabled={disableButton}
            onClick={handleCreateEditButton}
          >
            {data?.id ? t("Update & Save") : t("Create Correspondence")}
          </Button>
        </Stack>
        {selectedPreviewTab === "Drafting" && (
          <div className={classes.draft_root}>
            <Grid container spacing={"16px"}>
              {data?.level === 1 && (
                <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                  <SelectBox
                    key={selectedCompany?.value}
                    isRequired
                    label={t("Choose Property")}
                    placeholder={t("Search Property")}
                    value={data?.property}
                    options={options?.property}
                    onChange={(value) => updateData("property", value)}
                    isError={data?.error?.property?.length > 0}
                    errorMessage={data?.error?.property}
                  />
                </Grid>
              )}
              {data?.level === 1 && (
                <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                  <SelectBox
                    key={data?.property?.value}
                    isReadOnly={!Boolean(data?.property?.value)}
                    isRequired
                    label={t("Choose Unit")}
                    placeholder={t("Search Unit")}
                    value={data?.agreement_unit}
                    onChange={(value) => updateData("agreement_unit", value)}
                    loading={optionLoading === "agreement_unit"}
                    isPaginate
                    debounceTimeout={800}
                    loadOptions={(search, array) =>
                      loadOptions(search, array, "agreement_unit")
                    }
                    isError={data?.error?.agreement_unit?.length > 0}
                    errorMessage={data?.error?.agreement_unit}
                  />
                </Grid>
              )}
              {data?.level === 2 && (
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <SelectBox
                    key={selectedCompany?.value}
                    info={
                      data?.contact?.value &&
                      t("For more details click here!!!")
                    }
                    handleInfoClick={handleContactInfoClick}
                    isReadOnly={!Boolean(selectedCompany?.value)}
                    isRequired
                    label={t("Choose Contact")}
                    placeholder={t("Search Contact")}
                    value={data?.contact}
                    onChange={(value) => updateData("contact", value)}
                    loading={optionLoading === "contact_by_company"}
                    isPaginate
                    debounceTimeout={800}
                    loadOptions={(search, array) =>
                      loadOptions(search, array, "contact_by_company")
                    }
                    customOptionComponent={(option) => {
                      return (
                        <Stack
                          direction={"row"}
                          spacing={2}
                          p={1}
                          alignItems={"center"}
                          style={{
                            border: option?.isSelected
                              ? "1px solid #5078E1"
                              : "1px solid #E4E8EE",
                            backgroundColor: option?.isSelected
                              ? "#F1F7FF"
                              : "#ffffff",
                          }}
                        >
                          <Avatar
                            className={classes.optionAvatar}
                            src={option?.data?.address?.image_url}
                            {...stringAvatar(option?.data?.address?.first_name)}
                          ></Avatar>
                          <Stack>
                            <Typography className={classes.optionHeader}>
                              {option?.data?.address?.first_name}
                            </Typography>
                            <Stack
                              direction={"row"}
                              spacing={2}
                              alignItems={"center"}
                            >
                              <Typography className={classes.optionSubTitle}>
                                {concat_string(
                                  {
                                    mobile: concat_string(
                                      {
                                        mobile_code:
                                          option?.data?.address
                                            ?.mobile_no_country_code,
                                        mobile_no:
                                          option?.data?.address?.mobile_no,
                                      },
                                      ["mobile_code", "mobile_no"],
                                      " - "
                                    ),
                                    email_id: option?.data?.address?.email_id,
                                  },
                                  ["mobile", "email_id"],
                                  ", "
                                )}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      );
                    }}
                    isError={data?.error?.contact?.length > 0}
                    errorMessage={data?.error?.contact}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                <SelectBox
                  isRequired
                  label={t("Correspondence Type")}
                  placeholder={t("Correspondence Type")}
                  value={data?.correspondences_type}
                  options={options?.correspondences_type_master}
                  onChange={(value) =>
                    updateData("correspondences_type", value)
                  }
                  isError={data?.error?.correspondences_type?.length > 0}
                  errorMessage={data?.error?.correspondences_type}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                <NewDatePicker
                  left="25px"
                  label={t("Date")}
                  placeholder={"Select Date"}
                  value={data?.date}
                  isRequired
                  onChange={(value) => updateData("date", value)}
                  isError={data?.error?.date?.length > 0}
                  errorMessage={data?.error?.date}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                <NewDatePicker
                  left="25px"
                  label={t("Acknowledge Before")}
                  placeholder={"Select Date"}
                  value={data?.acknowledge_before}
                  isRequired
                  minDate={data?.date}
                  onChange={(value) => updateData("acknowledge_before", value)}
                  isError={data?.error?.acknowledge_before?.length > 0}
                  errorMessage={data?.error?.acknowledge_before}
                />
              </Grid>
              {data?.level === 1 && (
                <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                  <SelectBox
                    key={data?.agreement_unit?.value}
                    isReadOnly={!Boolean(data?.agreement_unit?.value)}
                    label={t("Previous Letter Reference (If Any)")}
                    placeholder={t("Search Letter")}
                    value={data?.reference_letter}
                    onChange={(value) => updateData("reference_letter", value)}
                    loading={
                      optionLoading === "reference_letter_by_agreement_unit"
                    }
                    isPaginate
                    debounceTimeout={800}
                    loadOptions={(search, array) =>
                      loadOptions(
                        search,
                        array,
                        "reference_letter_by_agreement_unit"
                      )
                    }
                    isError={data?.error?.reference_letter?.length > 0}
                    errorMessage={data?.error?.reference_letter}
                  />
                </Grid>
              )}
              {data?.level === 2 && (
                <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                  <SelectBox
                    key={data?.contact?.value}
                    isReadOnly={!Boolean(data?.contact?.value)}
                    label={t("Previous Letter Reference (If Any)")}
                    placeholder={t("Search Letter")}
                    value={data?.reference_letter}
                    onChange={(value) => updateData("reference_letter", value)}
                    loading={optionLoading === "reference_letter_by_contact"}
                    isPaginate
                    debounceTimeout={800}
                    loadOptions={(search, array) =>
                      loadOptions(search, array, "reference_letter_by_contact")
                    }
                    isError={data?.error?.reference_letter?.length > 0}
                    errorMessage={data?.error?.reference_letter}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container spacing={"8px"}>
                  <Grid item xs={12} sm={6} md={8} lg={10} xl={10}>
                    <TextBox
                      isrequired
                      type={"text"}
                      label={t("Subject")}
                      placeholder={t("Enter Subject")}
                      value={data?.subject}
                      onChange={(e) => updateData("subject", e.target.value)}
                      isError={data?.error?.subject?.length > 0}
                      errorMessage={data?.error?.subject}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                    <PrioritySelect
                      options={options?.urgent_type}
                      value={data?.priority}
                      t={t}
                      onChange={(value) => updateData("priority", value)}
                      isRequired
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextBox
                  isrequired
                  multiline
                  rowheight={10}
                  type={"text"}
                  label={t("Notes")}
                  placeholder={t("Enter Notes")}
                  value={data?.notes}
                  onChange={(e) => updateData("notes", e.target.value)}
                  isError={data?.error?.notes?.length > 0}
                  errorMessage={data?.error?.notes}
                />
              </Grid>
              <Grid item xs={12}>
                <UploadComponent
                  handleChange={(val) => updateData("attachment", val)}
                  logo_title={t("Attachment")}
                  errorMessage={data?.error?.attachment}
                  isError={data?.error?.attachment?.length > 0}
                  xs={2}
                  assets={assets}
                  setAssets={setAssets}
                  is_pdf={true}
                  accept={"image/*,.pdf"}
                />
              </Grid>
              <Grid item xs={12}>
                <UploadComponent
                  handleChange={(val) => updateData("signature", val)}
                  logo_title={t("Signature")}
                  errorMessage={data?.error?.signature}
                  isError={data?.error?.signature?.length > 0}
                  xs={2}
                  assets={signAsset}
                  setAssets={setSignAsset}
                  accept={"image/*"}
                  isOnlyOne
                />
              </Grid>
            </Grid>
            <DialogDrawer
              open={isContactInfoOpen}
              onClose={handleContactInfoOnClose}
              header={t("Contact Information")}
              maxWidth="md"
              height="530px"
              component={
                isContactInfoLoading ? (
                  <LoadingSection screen top={"15vh"} />
                ) : (
                  <Box>
                    {contactInfoState?.map?.((_) => {
                      return (
                        <Accordion
                          className={classes.contactInfoAccordian}
                          defaultExpanded={false}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className={classes.contactInfoRoleName}>
                              {(_?.role_name ?? "") +
                                " " +
                                ("(" +
                                  ((_?.data?.agreement?.length > 0
                                    ? _?.data?.agreement?.length
                                    : 0) +
                                    (_?.data?.contract?.length > 0
                                      ? _?.data?.contract?.length
                                      : 0) +
                                    (_?.data?.role?.length > 0
                                      ? _?.data?.role?.length
                                      : 0) +
                                    (_?.data?.module?.length > 0
                                      ? _?.data?.module?.length
                                      : 0)) +
                                  ")")}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {_?.data?.agreement?.length > 0 && (
                              <TableWithPagination
                                tableType="no-side"
                                heading={AgreementHeading(t)}
                                rows={_?.data?.agreement}
                                dataType={AgreementDataType}
                                path={AgreementPath}
                                totalRowsCount={0}
                                height={"200px"}
                              />
                            )}
                            {_?.data?.contract?.length > 0 && (
                              <TableWithPagination
                                tableType="no-side"
                                heading={ContractHeading(t)}
                                rows={_?.data?.contract}
                                dataType={ContractDataType}
                                path={ContractPath}
                                totalRowsCount={0}
                                height={"200px"}
                              />
                            )}
                            {_?.data?.role?.length > 0 && (
                              <TableWithPagination
                                tableType="no-side"
                                heading={RoleHeading(t)}
                                rows={_?.data?.role}
                                dataType={RoleDataType}
                                path={RolePath}
                                totalRowsCount={0}
                                height={"200px"}
                              />
                            )}
                            {_?.data?.module?.length > 0 && (
                              <TableWithPagination
                                tableType="no-side"
                                heading={ModuleHeading(t)}
                                rows={_?.data?.module}
                                dataType={ModuleDataType}
                                path={ModulePath}
                                totalRowsCount={0}
                                height={"200px"}
                              />
                            )}
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </Box>
                )
              }
            />
          </div>
        )}
        {selectedPreviewTab === "Preview" && (
          <div className={classes.preview_root}>
            <div className={classes.preview_div}>
              <Stack>
                <Stack
                  className={classes.preview_company_div}
                  direction={"row"}
                  spacing={"8px"}
                  justifyContent={"space-between"}
                  padding={"20px"}
                >
                  <img
                    className={classes.preview_logo}
                    src={selectedCompany?.logo}
                    alt="company_logo"
                  />
                  <Stack spacing={"8px"} alignItems={"end"}>
                    {selectedCompany?.label && (
                      <Typography className={classes.preview_company_name}>
                        {selectedCompany?.label}
                      </Typography>
                    )}
                    {selectedCompany?.address?.city &&
                      selectedCompany?.address?.state &&
                      selectedCompany?.address?.country && (
                        <Stack
                          direction={"row"}
                          spacing={"4px"}
                          divider={<Divider orientation="vertical" flexItem />}
                        >
                          {selectedCompany?.address?.city && (
                            <Typography
                              className={classes.preview_company_details}
                            >
                              {selectedCompany?.address?.city}
                            </Typography>
                          )}
                          {selectedCompany?.address?.state && (
                            <Typography
                              className={classes.preview_company_details}
                            >
                              {selectedCompany?.address?.state}
                            </Typography>
                          )}
                          {selectedCompany?.address?.country && (
                            <Typography
                              className={classes.preview_company_details}
                            >
                              {selectedCompany?.address?.country}
                            </Typography>
                          )}
                        </Stack>
                      )}
                    {selectedCompany?.email_id &&
                      selectedCompany?.mobile_number && (
                        <Stack
                          direction={"row"}
                          spacing={"4px"}
                          divider={<Divider orientation="vertical" flexItem />}
                        >
                          {selectedCompany?.email_id && (
                            <Typography
                              className={classes.preview_company_details}
                            >
                              {selectedCompany?.email_id}
                            </Typography>
                          )}
                          {selectedCompany?.mobile_number && (
                            <Typography
                              className={classes.preview_company_details}
                            >
                              {(selectedCompany?.mobile_code &&
                                selectedCompany?.mobile_code + "-") +
                                selectedCompany?.mobile_number}
                            </Typography>
                          )}
                        </Stack>
                      )}
                    {selectedCompany?.tax_registration_number && (
                      <Typography className={classes.preview_company_details}>
                        {selectedCompany?.tax_registration_number}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
                <Box height={"16px"} />
                <Typography
                  className={classes.preview_correspondence_type_value}
                >
                  {data?.correspondences_type?.label ??
                    t("Select Correspondence Type")}
                </Typography>
                <Box height={"18px"} />
                <Stack
                  direction={"row"}
                  spacing={"8px"}
                  justifyContent={"space-between"}
                >
                  <Stack spacing={"10px"}>
                    <Stack
                      direction={"row"}
                      divider={
                        <Typography className={classes.preview_detail_value}>
                          :
                        </Typography>
                      }
                      spacing={"4px"}
                    >
                      <div className={classes.label_div}>
                        <Typography className={classes.preview_detail_label}>
                          {t("Previous letter")}
                        </Typography>
                      </div>
                      <Typography className={classes.preview_detail_value}>
                        {data?.reference_letter?.label ??
                          t("Select Reference Letter")}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={"row"}
                      divider={
                        <Typography className={classes.preview_detail_value}>
                          :
                        </Typography>
                      }
                      spacing={"4px"}
                    >
                      <div className={classes.label_div}>
                        <Typography className={classes.preview_detail_label}>
                          {t("Incident date")}
                        </Typography>
                      </div>
                      <Typography className={classes.preview_detail_value}>
                        &#x202a;
                        {data?.date
                          ? timeZoneConverter(data?.date, "DD MMM YY")
                          : t("Select Incident Date")}
                        &#x202c;
                      </Typography>
                    </Stack>
                    <Stack
                      direction={"row"}
                      divider={
                        <Typography className={classes.preview_detail_value}>
                          :
                        </Typography>
                      }
                      spacing={"4px"}
                    >
                      <div className={classes.label_div}>
                        <Typography className={classes.preview_detail_label}>
                          {t("Deadline to reply")}
                        </Typography>
                      </div>
                      <Typography className={classes.preview_detail_value}>
                        &#x202a;
                        {data?.acknowledge_before
                          ? timeZoneConverter(
                              data?.acknowledge_before,
                              "DD MMM YY"
                            )
                          : t("Select Deadline Date")}
                        &#x202c;
                      </Typography>
                    </Stack>
                  </Stack>
                  <Typography className={classes.preview_detail_value}>
                    &#x202a;{timeZoneConverter(new Date(), "DD MMM YY")}&#x202c;
                  </Typography>
                </Stack>
                <Box height={"25px"} />
                <div className={classes.to_address_div}>
                  <Typography className={classes.preview_detail_label}>
                    {t("To") + ":"}
                  </Typography>
                  <Typography className={classes.preview_detail_value}>
                    {(data?.level === 1
                      ? data?.agreement_unit?.first_name
                      : data?.contact?.address?.first_name ??
                        t("Select Contact")) + ","}
                  </Typography>
                  <Typography className={classes.preview_detail_value}>
                    {concat_string(
                      {
                        unit_name:
                          data?.level === 1 ? data?.agreement_unit?.label : "",
                        door_no:
                          data?.level === 1
                            ? data?.agreement_unit?.address?.door_no
                            : data?.contact?.address?.door_no,
                        street_1:
                          data?.level === 1
                            ? data?.agreement_unit?.address?.street_1
                            : data?.contact?.address?.street_1,
                        street_2:
                          data?.level === 1
                            ? data?.agreement_unit?.address?.street_2
                            : data?.contact?.address?.street_2,
                        landmark:
                          data?.level === 1
                            ? data?.agreement_unit?.address?.landmark
                            : data?.contact?.address?.landmark,
                        area:
                          data?.level === 1
                            ? data?.agreement_unit?.address?.area
                            : data?.contact?.address?.area,
                        city:
                          data?.level === 1
                            ? data?.agreement_unit?.address?.city
                            : data?.contact?.address?.city,
                        district:
                          data?.level === 1
                            ? data?.agreement_unit?.address?.district
                            : data?.contact?.address?.district,
                        state:
                          data?.level === 1
                            ? data?.agreement_unit?.address?.state
                            : data?.contact?.address?.state,
                        country:
                          data?.level === 1
                            ? data?.agreement_unit?.address?.country
                            : data?.contact?.address?.country,
                        zipcode:
                          data?.level === 1
                            ? data?.agreement_unit?.address?.zipcode
                            : data?.contact?.address?.zipcode,
                      },
                      [
                        "unit_name",
                        "door_no",
                        "street_1",
                        "street_2",
                        "street_3",
                        "landmark",
                        "area",
                        "city",
                        "district",
                        "state",
                        "country",
                        "zipcode",
                      ],
                      ", ",
                      ""
                    )}
                  </Typography>
                </div>
                <Box height={"30px"} />
                <Stack
                  direction={"row"}
                  divider={
                    <Typography className={classes.preview_detail_value}>
                      :
                    </Typography>
                  }
                  spacing={"4px"}
                >
                  <Typography className={classes.preview_detail_label}>
                    {t("Subject")}
                  </Typography>
                  <Typography className={classes.preview_detail_value}>
                    {data?.subject?.length > 0
                      ? data?.subject
                      : t("Enter Subject")}
                  </Typography>
                </Stack>
                <Box height={"16px"} />
                {data?.notes ? (
                  <Typography className={classes.preview_detail_value}>
                    {data?.notes &&
                      data?.notes?.length > 0 &&
                      data?.notes
                        ?.split("\n")
                        .map((item, i) => <p key={i}>{item}</p>)}
                  </Typography>
                ) : (
                  <Typography className={classes.preview_detail_value}>
                    {t("Enter Notes")}
                  </Typography>
                )}
                {signAsset?.length && (
                  <Stack mt={1}>
                    <Typography className={classes.preview_detail_label}>
                      {t("Signature")}
                    </Typography>
                    <img
                      style={{ height: 60, width: 100, objectFit: "contain" }}
                      src={signAsset?.[0]?.url}
                      alt="sign"
                    />
                  </Stack>
                )}
                <Box height={"30px"} />
                <Typography className={classes.preview_detail_label}>
                  {t("*System generated letter signature not required")}
                </Typography>
              </Stack>
            </div>
          </div>
        )}
      </>
    );
  };

  return <div>{accessCheckRender(render, permission, "", loading)}</div>;
};
export default withTranslation("correspondences")(CreateEditCorrespondences);
