import { Box } from "@mui/material";
import moment from "moment-timezone";
import React, { useContext, useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  DialogBox,
  Subheader,
  TemplateGenerator,
  UseDebounce,
} from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import {
  AlertProps,
  getCompanyOption,
  getToday,
  enumSelect,
  enum_types,
  getRoutePermissionNew,
  NetWorkCallMethods,
  accessCheckRender,
} from "../../utils";
import { BottomCard } from "./bottomCard";
import { managrmentStyles } from "./style";
import { TableComponent } from "./tableComponent";
import { NewLoader } from "../../components/newLoader";

const ManageInvoice = ({ t, loading, handleLoading }) => {
  // useRef
  const permission = useRef([]);
  const classes = managrmentStyles();
  //   context
  const auth = useContext(AuthContext);
  const alert = useContext(AlertContext);
  const backdrop = useContext(BackdropContext);
  // apolo clint
  //debounce hook
  const debounce = UseDebounce();
  // ----------------------------------------STATES----------------------------------------
  const [company, setCompany] = useState({
    companyList: [],
    SelectedCompany: {},
    searchText: "",
    drawer: false,
    typeSelect: {
      value: "All",
      labe: "All",
    },
  });
  const [list, setList] = useState({
    data: [],
    count: 0,
  });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [date, setDate] = useState({
    from: getToday(),
    to: getToday(),
    key: "past",
  });
  const [selected, setSelected] = useState(false);
  const [enumValue, setValue] = useState({
    type: [],
  });
  const [selectType, setSelectType] = useState({
    value: "All",
    label: "All",
  });
  const [filterData, setFilterData] = React.useState({
    contact: "",
    agreement: [],
    unit: [],
    classification: ["AR"], // enable AR by default
  });
  const [pdf, setPdf] = useState({
    pdf: "",
    bool: false,
  });
  const [template, setTemplate] = React.useState(false);
  const [manageInvoice, setManageInvoice] = React.useState(false);

  const handleAccountTypeToggle = (type) => {
    setFilterData({ ...filterData, classification: [type] });
    reloadFunction([type]);
  };

  const onChange = (key, value) => {
    if (key === "contact") {
      setFilterData({ ...filterData, contact: value, agreement: [] });
    } else {
      setFilterData({ ...filterData, [key]: value });
    }
  };
  const clearFilter = (key, value) => {
    setFilterData({
      contact: "",
      agreement: [],
      unit: [],
      classification: [],
    });
  };
  //   --------------------------------------FUNCTIONS--------------------------------------
  //   initial company update
  const updateState = (k, v) => {
    setCompany({ ...company, [k]: v });
  };
  // date change
  const dateChange = (value, key) => {
    setDate({ ...value, key: key });
    setPage(1);
    GetList(
      company?.SelectedCompany,
      company?.searchText,
      0,
      limit,
      value,
      selectType,
      key
    );
  };
  //   company selection
  const companySelect = (e) => {
    updateState("SelectedCompany", e);
    GetList(e, company?.searchText, 0, limit, date, selectType, date?.key);
  };
  // search
  const searchFunction = (value) => {
    updateState("searchText", value);
    debounce(
      () =>
        GetList(
          company?.SelectedCompany,
          value,
          0,
          limit,
          date,
          selectType,
          date?.key
        ),
      800
    );
  };
  // pagination
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    GetList(
      company?.SelectedCompany,
      company?.searchText,
      offset,
      limit,
      date,
      selectType,
      date?.key
    );
  };
  // pagination limit change function
  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    GetList(
      company?.SelectedCompany,
      company?.searchText,
      0,
      value,
      date,
      selectType,
      date?.key
    );
  };
  // reservation get
  const GetList = async (
    comp,
    searchText,
    offSet,
    limit,
    date,
    type,
    key,
    classify
  ) => {
    const typeSelet = type?.value === "All" ? null : type?.value;

    const dateRange = [
      JSON.stringify(date?.from)?.length > 0
        ? `${moment(date?.from).format("YYYY-MM-DD")} 00:00:00`
        : "",
      JSON.stringify(date?.to)?.length > 0
        ? `${moment(date?.to).format("YYYY-MM-DD")} 23:59:59`
        : "",
    ];

    const payload = {
      company_id: comp?.value,
      status: "Unposted",
      payment_due_date: dateRange,
      filter: {
        type: typeSelet,
        is_void: typeSelet === "Void Invoices" ? true : false,
      },
      search: searchText,
      limit: limit,
      offset: offSet,
      agreement_id:
        filterData?.agreement?.length > 0
          ? filterData?.agreement?.map((x) => x?.value)
          : undefined,
      account_no: filterData?.contact?.value
        ? filterData?.contact?.value
        : undefined,
      is_date_type: key ?? undefined,
      payment_type: classify?.length
        ? classify
        : filterData?.classification?.length > 0
        ? filterData?.classification
        : undefined,
    };

    let Posting_Type = "-";

    try {
      const response = await NetworkCall(
        `${config.api_url}/check-company-permission/check_company_permissions`,
        NetWorkCallMethods.post,
        { company_ids: [comp?.value] },
        null,
        true,
        false
      );

      const responseData = response.data;
      if (responseData.type === "success" && Array.isArray(responseData.data)) {
        responseData.data.forEach((item) => {
          if (item.is_active === true) {
            Posting_Type = "ERP";
          } else if (item.is_active === false) {
            Posting_Type = "PG2";
          } else {
            Posting_Type = "-";
          }
        });
      }
    } catch (error) {
      console.log(error);
    }

    NetworkCall(
      `${config.api_url}/quotation_payment_schedule/manage_invoice_list`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setSelected(false);
        let result = res?.data?.quotation_payment_schedule?.map((x) => {
          let tax = "-";
          if (x?.invoice_type === "Amenity") {
            tax = `${x?.currency_symbol ?? ""} ${
              x?.total_amenity_taxs?.total_amenity_taxs ?? "-"
            }`;
          } else if (x?.invoice_type === "Services") {
            tax = `${x?.currency_symbol ?? ""} ${
              x?.total_service_taxs?.content?.total_taxs ?? "-"
            }`;
          } else if (x?.invoice_type === "Final Settlement") {
            tax = `${x?.currency_symbol ?? ""} ${
              x?.total_freetext_taxs?.total_tax ?? "-"
            }`;
          } else if (x?.invoice_type === "Rental") {
            tax = `${x?.currency_symbol ?? ""} ${
              x?.total_rental_taxs?.content?.total_taxs ?? "-"
            }`;
          }
          return {
            id: x?.id,
            Invoice_Date: x?.payment_due_date ?? "-",
            Agreement_Number: x?.agreement_no ?? "-",
            Account_Number: x?.account_no ?? "-",
            Account_Name: x?.account_name ?? "-",
            Managed_Invoice_Number: x?.schedule_no ?? "-",
            Description: x?.description ?? "-",
            Posting_Date: x?.payment_due_date ?? "-",
            Invoice_Type: x?.invoice_type ?? "-",
            Total_Amount: `${x?.currency_symbol ?? ""} ${
              x?.total_amount ?? "-"
            }`,
            total_amount: x?.total_amount ?? "-",
            Payment_Method: x?.payment_mode_method ?? "-",
            Payment_terms: x?.payment_terms ?? "-",
            Salesperson: x?.sales_person ?? "-",
            Free_Text: x?.free_text_invoice_no ?? "-",
            Cheque_Image_URL: x?.cheque_image ?? "",
            Cheque_No: x?.cheque_name ?? "",
            Issued_By: x?.bank_name ?? "",
            is_active: false,
            icon: "view_pdf",
            Due_Date: x?.due_date ?? "-",
            Posting_Type: x?.free_text_is_proxy ? "Proxy" : Posting_Type ?? "-",
            Taxes: tax,
          };
        });
        setList({
          data: result ?? [],
          count: res?.data?.count,
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
      });
  };
  //   get permission function
  const getPermission = () => {
    const tempPermission = getRoutePermissionNew(auth);
    permission.current = tempPermission;
    if (tempPermission?.read) {
      let company = getCompanyOption(backdrop, auth, alert);
      if (company) {
        GetList(company?.selected, "", 0, limit, date, selectType, "past");
        setCompany({
          ...company,
          companyList: company?.list,
          SelectedCompany: company?.selected,
        });
        getEnum();
      }
    }
  };
  //select checkbox
  const onCheckBox = (datas, value, index, checkboxKey) => {
    if (
      value !== undefined &&
      list?.data?.filter((x) => x?.is_active === true)?.length !== 10
    ) {
      let result = [...list?.data];
      result[index][checkboxKey] = value;
      setList({
        ...list,
        data: result,
      });
    }
  };
  // select all checkbox
  const allSelectBox = (e) => {
    setSelected(e);
    const result = list?.data?.map((x) => {
      return {
        ...x,
        id: x?.id,
        Agreement_Number: x?.Agreement_Number ?? "",
        Account_Number: x?.Account_Number ?? "",
        Account_Name: x?.Account_Name ?? "",
        Managed_Invoice_Number: x?.Managed_Invoice_Number ?? "",
        Description: x?.Description ?? "",
        Posting_Date: x?.Posting_Date ?? "",
        Invoice_Type: x?.Invoice_Type ?? "",
        Total_Amount: x?.Total_Amount ?? "",
        Payment_Method: x?.Payment_Method ?? "",
        is_active: e,
      };
    });
    setList({
      ...list,
      data: result ?? [],
    });
  };
  // reload
  const reloadFunction = (classify) => {
    GetList(
      company?.SelectedCompany,
      company?.searchText,
      0,
      limit,
      date,
      selectType,
      date?.key,
      classify
    );
  };
  // enum get
  const getEnum = async () => {
    const result = await enumSelect([
      enum_types.invoice_charge_type,
      enum_types.payment_mode,
      enum_types.payment_terms,
    ]);
    setValue({
      type: [
        ...result?.invoice_charge_type,
        { value: "Void Invoices", label: "Void Invoices" },
      ],
      paymentMode: result?.payment_mode,
      paymentTerm: result?.payment_terms,
    });
  };
  // get list by type
  const typeChage = (value) => {
    GetList(
      company?.SelectedCompany,
      company?.searchText,
      0,
      limit,
      date,
      value,
      date?.key
    );
  };
  const updatePopup = (type, data) => {
    if (type) {
      return setTemplate({
        pdf: "",
        open: false,
      });
    }

    setTemplate({
      pdf: data,
      open: true,
    });
  };
  const getManageInvoice = (v) => {
    setManageInvoice(!manageInvoice);
    setPdf({
      bool: false,
      data: "",
    });
    const variables = {
      id: template?.pdf?.id,
      type_master_id: v?.value,
    };
    // console.log(variables)
    NetworkCall(
      `${config.api_url}/invoice/manage_invoice`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((res) => {
        setPdf({
          pdf: res?.data?.data,
          bool: true,
        });
      })
      .catch((err) => {
        setPdf({
          pdf: "",
          bool: false,
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some thing went wrong"),
        });
      });
  };
  useEffect(() => {
    getPermission();
    // eslint-disable-next-line
  }, [auth]);
  const render = () => {
    return (
      <div>
        {/* sub nav bar */}
        <Subheader
          title={`${t("Scheduled Invoices")} (${list?.count ?? 0})`}
          select
          hideBackButton={true}
          options={company?.companyList}
          value={company?.SelectedCompany}
          placeholder={t("Search")}
          onchange={(e) => {
            companySelect(e);
          }}
        />

        {/* table contant */}
        <Box className={classes.root}>
          {/* <RangeDatePicker /> */}
          <TableComponent
            t={t}
            list={list}
            page={page}
            limit={limit}
            handlePagination={handlePagination}
            handleChangeLimit={handleChangeLimit}
            searchFunction={searchFunction}
            company={company}
            date={date}
            dateChange={dateChange}
            onCheckBox={onCheckBox}
            allSelectBox={allSelectBox}
            selected={selected}
            enumValue={enumValue}
            typeChage={typeChage}
            setSelectType={setSelectType}
            onChange={onChange}
            filterData={filterData}
            reloadFunction={reloadFunction}
            clearFilter={clearFilter}
            handleIcon={(type, data) => {
              if (type === "view_pdf") {
                updatePopup(false, data);
              }
            }}
            handleAccountTypeToggle={handleAccountTypeToggle}
          />
          <Box pt={2}>
            <BottomCard
              t={t}
              company={company?.SelectedCompany}
              list={list?.data?.filter((x) => x?.is_active === true)}
              date={date}
              enumValue={enumValue}
              allList={list?.data}
              reloadFunction={reloadFunction}
              selectType={selectType}
            />
          </Box>
          <TemplateGenerator
            t={t}
            name={t("Proforma Invoice Template")}
            open={template?.open}
            onClose={() =>
              setTemplate({
                open: false,
                data: "",
              })
            }
            type={4}
            generate={getManageInvoice}
          />
          <DialogBox
            onClose={() => setManageInvoice(!manageInvoice)}
            handleClose={() => setManageInvoice(!manageInvoice)}
            open={manageInvoice}
            component={
              <div style={{ height: "100vh" }}>
                {pdf?.bool ? (
                  <iframe
                    src={`data:application/pdf;base64,${pdf?.pdf}`}
                    alt="pdf"
                    width="100%"
                    style={{ height: `calc(100vh - 2px)` }}
                    title="quotation"
                  />
                ) : (
                  <NewLoader minusHeight={"2px"} />
                )}
              </div>
            }
          />
        </Box>
      </div>
    );
  };
  return (
    <div>{accessCheckRender(render, permission.current, "", loading)}</div>
  );
};
export default withTranslation("manageinvoice")(ManageInvoice);
