import { Box, Button, Fade, Grid, Typography } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { AlertDialog, DetailsCardCoponent, Subheader } from "../../../components";
import { DocumentViewer } from "../../../components/fileViewer";
import { useWindowDimensions } from "../../../utils";
import { KycCard, KYCForm, Slider } from "../components";
import { AggrementTabStyles } from "./styles";

const KYC = ({ details = {}, reload = () => false, t = () => false }) => {
  // classes
  const classes = AggrementTabStyles();
  const [open, setOpen] = React.useState({ bool: false, type: null });
  const [openKyc, setOpenKyc] = React.useState({ bool: false, data: null })
  const [selectedKyc, setSelectedKyc] = React.useState({})
  React.useEffect(() => {
    setSelectedKyc(details?.contact?.know_your_client?.[0])
    // eslint-disable-next-line
  }, [details])
  // size
  const size = useWindowDimensions();
  //onopen drawer
  const onOpen = (val) => {
    setOpen({
      bool: true,
      type: val
    })
  }
  //onclose drawer
  const onClose = () => {
    setOpen({
      bool: false,
      type: null
    })
  }
  const card = [
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      isActive: true,
      component: "text",
      heading: t("proof"),
      subtitle: `${selectedKyc?.proofByID?.type}`
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      isActive: true,
      component: "text",
      heading: t("nameAsInProof"),
      subtitle: `${selectedKyc?.name}`,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      isActive: true,
      component: "text",
      heading: t("idProofNumber"),
      subtitle: `${selectedKyc?.number}`,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      isActive: true,
      component: "date",
      heading: t("validFrom"),
      subtitle: `${selectedKyc?.valid_from}`,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      isActive: true,
      component: "date",
      heading: t("validTo"),
      subtitle: `${selectedKyc?.valid_to}`,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
      },
      isActive: true,
      component: "text",
      heading: t("issuingCountry"),
      subtitle: `${selectedKyc?.countryByID?.country_name}`,
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      isActive: selectedKyc?.status !== "Yet to be Verified",
      component: "divider",
    },
    {
      size: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
      isActive: selectedKyc?.status !== "Yet to be Verified",
      component: "statusTypo",
      status: selectedKyc?.status,
      bgColor: selectedKyc?.status === "Verified" ? "#EEF9EE" : "#FFECEC",
      color: selectedKyc?.status === "Verified" ? "#5AC782" : "#FF4B4B",
      value: `${t("remarks")} : ${selectedKyc?.remarks}`,
      marginLeft:"4px"
    },
  ];
  //on click details
  const handleDetails = (val) => {
    setSelectedKyc(val)
  }
  //onClickKyc
  const onClickKyc = (val = null) => {
    setOpenKyc({
      bool: !openKyc?.bool,
      data: val
    })
  }

  return (
    <Box className={classes.kycbox}>
      {
        details?.contact?.know_your_client?.length > 0 ?

          <Grid container>
            <Grid item xs={7} style={{ borderRight: "1px solid #CED3DD" }} onClick={onClose}>
              <Box p={2}>
                {details?.contact?.know_your_client?.map((val) => {

                  return (
                    <KycCard
                      onClick={handleDetails}
                      selected={selectedKyc}
                      value={{ ...val, backgroundColor: "#FEF4F4", color: "black" }} />
                  )
                })}
              </Box>
            </Grid>
            {/* person details */}
            <Grid item xs={5}>
              <Subheader
                title={`${selectedKyc?.name} | ${selectedKyc?.proofByID?.type}`}
                hideBackButton={true}
                borderBottom={"1px solid #E4E8EE"}
              />
              {/* status form */}
              <Box sx={{ position: "relative" }}>
                {
                  open?.bool &&
                  <Fade in={open?.bool}>
                    <Box className={classes.kycForm}>
                      <KYCForm reload={reload} selected={selectedKyc} onClose={onClose} type={open?.type} />
                    </Box>
                  </Fade>
                }

                <Box
                  onClick={onClose}
                  className={classes.detailsKYC}
                  style={{ height: selectedKyc?.status === "Yet to be Verified" ? size?.height - 340 : size?.height - 270, overflow: "auto" }}
                >
                  {/* slider */}
                  {selectedKyc?.url?.length > 0 &&
                    <>
                      <Slider onClick={onClickKyc} assets={selectedKyc?.url ?? []} backgroundColor={"#E4E8EE"} />
                      <Box height="12px" />
                    </>
                  }
                  {/* details card */}
                  <DetailsCardCoponent components={card} backgroundColor={"#fff"} />
                </Box>

                {/* bottom button */}
                {
                  selectedKyc?.status === "Yet to be Verified" &&

                  <Box className={classes.buttomBtn}>
                    <Button className={classes.decline} onClick={() => onOpen("Decline")} variant="contained">
                      {t("Decline")}
                    </Button >
                    <Button className={classes.approve} onClick={() => onOpen("Approve")} variant="contained">
                      {t("Approve")}
                    </Button>
                  </Box >
                }
              </Box >
            </Grid >
          </Grid >
          :
          <Typography sx={{ marginTop: "12px" }} textAlign="center" className={classes.title}>{t("Proof Not Found")}</Typography>
      }

      <AlertDialog
        open={openKyc?.bool}
        header={t("Kyc View")}
        onClose={onClickKyc}
        // isNormal={true}
        component={
          <Box p={2}>

            {openKyc?.data?.type === "application/pdf" ?
              <center>
                <DocumentViewer url={openKyc?.data?.src} />
              </center>
              :
              <center>
                <img
                  src={openKyc?.data?.src}
                  alt=""
                />
              </center>
            }

          </Box>
        }
      />
    </Box >
  );
};
export default withTranslation("agreement")(KYC); 