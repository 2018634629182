import { Box, Button } from "@mui/material";
import moment from "moment";
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import CityProperty from '../../assets/cityproperty';
import ContractProperty from '../../assets/contractproperty';
import ImagesProperty from '../../assets/imagesproperty';
import { PropertyTabs, Subheader, UseDebounce } from "../../components";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { AlertProps, allowed_file_size, LocalStorageKeys, NetWorkCallMethods, singleFileUpload } from "../../utils";
import { mapResult } from '../../utils/common';
import { useStylesCreation } from "./style";
import { BankingDetails } from "./tabs/bankingDetails";
import { GeneralDetails } from './tabs/generalDetails';
import { NationalIDDetails } from './tabs/nationalIDDetails';
import { withTranslation } from "react-i18next";

const CreateContact = ({ t = () => false }) => {
      const { state } = useLocation();
      const initial = {
            id: state?.main?.id,
            isActive: true,
            company: state?.main?.company,
            relationship: state?.main?.relationship,
            role: state?.main?.role,
            vendor: state?.main?.vendor,
            userID: "",
            userProfileID: "",
            profileImage: "",
            name: "",
            jobTitle: "",
            properties: "",
            initialProperties: [],
            deletedProperties: [],
            doorNo: "",
            addressLineOne: "",
            addressLineTwo: "",
            landmark: "",
            area: "",
            city: "",
            state: "",
            country: "",
            zipcode: "",
            latitude: "",
            longitude: "",
            preferredModeContact: "",
            primaryTelephone: "",
            primaryMobile: "",
            primaryEmail: "",
            emergencyContactName: "",
            emergencyContactRelationship: "",
            emergencyContactNumber: "",
            facebook: "",
            twitter: "",
            linkedin: "",
            nationality: "",
            nationalID: "",
            nationalIDExpiry: "",
            passportID: "",
            passportIDExpiry: "",
            residentID: "",
            residentIDExpiry: "",
            visaNumber: "",
            visaNumberExpiry: "",
            bankName: "",
            bankBranchName: "",
            bankCurrency: "",
            bankAccountType: "",
            bankAccountNumber: "",
            bankRoutingType: "",
            bankRoutingCode: "",
            bankAddressLineOne: "",
            bankAddressLineTwo: "",
            bankCity: "",
            bankCountry: "",
            chequeName: "",
            preferredCashCollectionOffice: "",
            error: {
                  profileImage: "",
                  name: "",
                  jobTitle: "",
                  properties: "",
                  primaryTelephone: "",
                  primaryMobile: "",
                  primaryEmail: "",
            }
      }
      const navigate = useNavigate();
      const [value, setValue] = React.useState(1);
      const classes = useStylesCreation();
      const [data, setData] = React.useState({ ...initial })
      const [mapLoad, setMapLoad] = React.useState(!Boolean(state?.main?.isEdit))
      const [disable, setDisable] = React.useState(false)
      const backdrop = React.useContext(BackdropContext);
      const alert = React.useContext(AlertContext);
      const debounce = UseDebounce();
      const [load, setLoad] = React.useState(false)
      const tabTittle = [
            {
                  label: 'General Details',
                  sub: 'Enter Profile, Address & Contact Details',
                  imgSelect: value === 2 || value === 3 ? <img src="/images/correct_icon.svg" alt='icon' /> : <CityProperty color={value === 1 ? '#5078E1' : '#98A0AC'} />,
                  value: 1,
                  className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
                  selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
                  subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
            },
            {
                  label: 'National ID Details',
                  sub: 'Enter ID Details',
                  imgSelect: value === 3 ? <img src="/images/correct_icon.svg" alt='icon' /> : <ImagesProperty color={value === 2 ? '#5078E1' : '#98A0AC'} />,
                  value: 2,
                  className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
                  selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
                  subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
            },
            {
                  label: 'Banking Details',
                  sub: 'Enter Authority, Utilities Details',
                  imgSelect: <ContractProperty color={value === 3 ? '#5078E1' : '#98A0AC'} />,
                  value: 3,

                  className: value === 3 ? classes.tabtitle1 : classes.tabtitle,
                  selectBack: value === 3 ? classes.selectBack1 : classes.selectBack,
                  subtabtitle: value === 3 ? classes.subtabtitle1 : classes.subtabtitle
            },
      ]
      const updateimg = async (data) => {
            backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading", });

            let uploaded_file = await singleFileUpload(data, { tenantId: `${config.tenantId}` }, alert, allowed_file_size)

            if (uploaded_file?.[0]?.url) {
                  updateState("profileImage", uploaded_file?.[0]?.url)
                  backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
            } else {
                  backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
                  alert.setSnack({
                        ...alert, open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some Thing Went Wrong",
                  });
            }
      };
      const next = () => {
            if (value === 1) {
                  if (validate()) { setValue(2) } else { return false }
            }
            if (value === 2) { setValue(3); }
            if (value === 3) { setValue(3); }
      };
      const previous = () => {
            setValue(prevValue => prevValue - 1);
            if (!state?.main?.isEdit) {
                  setMapLoad(false)
            }
      };
      const mapResultData = (map) => {
            let result = mapResult(map);
            setData({ ...data, ...result })
      }
      const validate = () => {
            let isValid = true;
            let error = data.error;
            if (data?.profileImage?.length === 0) { isValid = false; error.profileImage = "Profile Image is Required"; }
            if (data?.name?.length === 0) { isValid = false; error.name = "Name is Required"; }
            if (data?.relationship?.value === "Company Employee" && data?.properties?.length === 0) {
                  isValid = false; error.properties = "Properties is Required";
            }
            if (data?.primaryEmail?.length === 0) { isValid = false; error.primaryEmail = "Email ID is Required"; }
            if (data?.primaryMobile?.length === 0) { isValid = false; error.primaryMobile = "Primary Mobile ID is Required"; }
            if (data?.primaryTelephone?.length === 0) { isValid = false; error.primaryTelephone = "Primary Telephone ID is Required"; }
            if (!isValid) {
                  alert.setSnack({
                        ...alert, open: true,
                        severity: AlertProps.severity.error,
                        msg: "Please fill all mandatory field",
                  });
            }
            setData({ ...data, error });
            return isValid;
      };
      const save = () => {
            let currentDate = new Date().toISOString();
            if (validate()) {
                  setDisable(true)
                  let tempProperties = [];
                  let tempDeletedProperties = [];
                  if (data?.id) {
                        for (let i = 0; i < data?.properties?.length; i++) {
                              if (!data?.initialProperties?.includes(data?.properties?.[i]?.value)) {
                                    tempProperties = [...tempProperties, data?.properties?.[i]?.value];
                              }
                        }
                        for (let i = 0; i < data?.deletedProperties?.length; i++) {
                              if (data?.initialProperties?.includes(data?.deletedProperties?.[i]?.value)) {
                                    tempDeletedProperties = [...tempDeletedProperties, data?.deletedProperties?.[i]?.value];
                              }
                        }
                  } else {
                        tempProperties = data?.relationship?.value === "Company Employee" ?
                              (data?.properties?.map((x) => x?.value) ?? undefined) : undefined;
                  }
                  let datas = {
                        tenantId: `${config.tenantId}`,
                        id: data?.id ?? undefined,
                        company_id: data?.company?.value ?? undefined,
                        relationship: data?.relationship?.value ?? undefined,
                        role: data?.role?.value ?? undefined,
                        vendor_id: data?.vendor?.value ?? undefined,
                        user_id: data?.userID ?? "",
                        user_profile_id: data?.userProfileID ?? "",
                        properties: tempProperties ?? undefined,
                        deleteProperties: tempDeletedProperties ?? undefined,
                        image_url: data?.profileImage ?? undefined,
                        first_name: data?.name ?? undefined,
                        job_title: data?.jobTitle ?? undefined,
                        door_no: data?.doorNo ?? undefined,
                        street_1: data?.addressLineOne ?? undefined,
                        street_2: data?.addressLineTwo ?? undefined,
                        landmark: data?.landmark ?? undefined,
                        area: data?.area ?? undefined,
                        city: data?.city ?? undefined,
                        state: data?.state ?? undefined,
                        zipcode: data?.zipcode ?? undefined,
                        country: data?.country ?? undefined,
                        latitude: data?.latitude ? parseFloat(data?.latitude) : undefined,
                        longitude: data?.longitude ? parseFloat(data?.longitude) : undefined,
                        preferred_mode_contact: data?.preferredModeContact?.value ?? undefined,
                        phone_code: data?.primaryTelephone?.mobile_code ?? undefined,
                        phone: data?.primaryTelephone?.mobile ?? undefined,
                        mobile_no_country_code: data?.primaryMobile?.mobile_code ?? undefined,
                        mobile_no: data?.primaryMobile?.mobile ?? undefined,
                        email_id: data?.primaryEmail ?? undefined,
                        emergency_contact_name: data?.emergencyContactName ?? undefined,
                        emergency_contact_relationship: data?.emergencyContactRelationship?.value ?? undefined,
                        emergency_contact_code: data?.emergencyContactNumber?.mobile_code ?? undefined,
                        emergency_contact_number: data?.emergencyContactNumber?.mobile ?? undefined,
                        facebook: data?.facebook ?? undefined,
                        twitter: data?.twitter ?? undefined,
                        linkedin: data?.linkedin ?? undefined,
                        nationality: data?.nationality ?? undefined,
                        national_id: data?.nationalID ?? undefined,
                        national_id_expiry: data?.nationalIDExpiry ? moment(data?.nationalIDExpiry).format("yyyy-MM-DD") : undefined,
                        passport_id: data?.passportID ?? undefined,
                        passport_expiry: data?.passportIDExpiry ? moment(data?.passportIDExpiry).format("yyyy-MM-DD") : undefined,
                        visa_no: data?.visaNumber ?? undefined,
                        visa_expiry: data?.visaNumberExpiry ? moment(data?.visaNumberExpiry).format("yyyy-MM-DD") : undefined,
                        resident_id: data?.residentID ?? undefined,
                        resident_id_expiry: data?.residentIDExpiry ? moment(data?.residentIDExpiry).format("yyyy-MM-DD") : undefined,
                        bank_name: data?.bankName ?? undefined,
                        branch_name: data?.bankBranchName ?? undefined,
                        bank_currency_id: data?.bankCurrency?.value ? data?.bankCurrency?.value : undefined,
                        account_type: (data?.bankAccountType || data?.bankAccountType !== "") ? data?.bankAccountType : undefined,
                        account_no: data?.bankAccountNumber ?? undefined,
                        routing_type: data?.bankRoutingType?.value ? data?.bankRoutingType?.value : undefined,
                        routing_code: data?.bankRoutingCode ?? undefined,
                        address_1: data?.bankAddressLineOne ?? undefined,
                        address_2: data?.bankAddressLineTwo ?? undefined,
                        bank_city: data?.bankCity ?? undefined,
                        bank_country: data?.bankCountry ?? undefined,
                        cheque_name: data?.chequeName ?? undefined,
                        cash_collection_office: data?.preferredCashCollectionOffice ?? undefined,
                        is_active: data?.isActive,
                        created_by: data?.id ? localStorage.getItem(LocalStorageKeys.profileID) : undefined,
                        updated_by: localStorage.getItem(LocalStorageKeys.profileID),
                        created_at: data?.id ? currentDate : undefined,
                        updated_at: currentDate
                  }
                  NetworkCall(
                        `${config.api_url}/contact/upsert`,
                        NetWorkCallMethods.post,
                        datas, null, true, false).then((response) => {
                              navigate(-1)
                              alert.setSnack({
                                    ...alert, open: true,
                                    severity: AlertProps.severity.success,
                                    msg: `Contact ${state?.main?.isEdit ? "Updated" : "Created"}  successfully.`,
                              });
                              setDisable(false)
                        }).catch((error) => {
                              alert.setSnack({
                                    ...alert, open: true,
                                    severity: AlertProps.severity.error,
                                    msg: "Internal error. Please try again later.",
                              });
                              setDisable(false)
                        });
            }
      }
      const checkExsits = (k, v, m) => {
            let datas = {
                  tenantId: `${config.tenantId}`,
                  [k]: v
            }
            NetworkCall(
                  `${config.api_url}/contact/${k === "user_name" ? `findUserExisting` : `findContactExisting`}`,
                  NetWorkCallMethods.post,
                  datas, null, true, false).then((response) => {
                        if (response?.data?.is_Exist && v?.length > 0) {
                              alert.setSnack({
                                    ...alert, open: true,
                                    severity: AlertProps.severity.warning,
                                    msg: `${m} is already exists`,
                              });
                        }
                  }).catch((error) => {
                        alert.setSnack({
                              ...alert, open: true,
                              severity: AlertProps.severity.error,
                              msg: "Internal error. Please try again later.",
                        });
                  });
      }
      const updateState = (k, v) => {
            if (data?.relationship?.value === "Company Employee" && k === 'name') {
                  debounce(() => checkExsits("user_name", v, 'Name'), 1700);
            }
            if (k === "primaryEmail") {
                  debounce(() => checkExsits("email_id", v, 'Email Id'), 1700);
            }
            if (k === 'primaryMobile') {
                  debounce(() => checkExsits("mobile_no", v?.mobile, 'Primary Mobile Number'), 1700);
            }
            let error = data?.error;
            error[k] = "";
            setData({ ...data, [k]: v, error })
      }
      const getContactDetailsByID = () => {
            backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading", });
            let datas = { tenantId: `${config.tenantId}`, id: state?.main?.id }
            NetworkCall(
                  `${config.api_url}/contact/get`,
                  NetWorkCallMethods.post,
                  datas, null, true, false
            ).then((res) => {
                  let tempContactDetails = res?.data?.data
                  let properties = tempContactDetails?.properties?.map((val) => {
                        return { value: val?.id ?? "", label: val?.name ?? "" }
                  })
                  let initialProperties = properties?.map((x) => x?.value)
                  setData({
                        id: tempContactDetails?.id,
                        isActive: tempContactDetails?.is_active ?? false,
                        company: tempContactDetails?.company_id ?
                              {
                                    value: tempContactDetails?.company_id,
                                    label: tempContactDetails?.company_name,
                                    company_no: tempContactDetails?.company_no,
                              } : "",
                        relationship: tempContactDetails?.relationship ?
                              {
                                    value: tempContactDetails?.relationship,
                                    label: tempContactDetails?.relationship,
                              } : "",
                        role: tempContactDetails?.role_id ?
                              {
                                    value: tempContactDetails?.role_id,
                                    label: tempContactDetails?.role_name,
                              } : "",
                        vendor: tempContactDetails?.vendor_id ?
                              {
                                    value: tempContactDetails?.vendor_id,
                                    label: tempContactDetails?.vendor_name,
                              } : "",
                        userID: tempContactDetails?.user_id ?? "",
                        userProfileID: tempContactDetails?.user_profile_id ?? "",
                        profileImage: tempContactDetails?.image_url ?? "",
                        name: tempContactDetails?.first_name ?? "",
                        jobTitle: tempContactDetails?.job_title ?? "",
                        properties: properties ?? "",
                        initialProperties: initialProperties ?? "",
                        deletedProperties: [],
                        doorNo: tempContactDetails?.door_no ?? "",
                        addressLineOne: tempContactDetails?.street_1 ?? "",
                        addressLineTwo: tempContactDetails?.street_2 ?? "",
                        landmark: tempContactDetails?.landmark ?? "",
                        area: tempContactDetails?.area ?? "",
                        city: tempContactDetails?.city ?? "",
                        state: tempContactDetails?.state ?? "",
                        country: tempContactDetails?.country ?? "",
                        zipcode: tempContactDetails?.zipcode ?? "",
                        latitude: tempContactDetails?.latitude ?? "",
                        longitude: tempContactDetails?.longitude ?? "",
                        preferredModeContact: tempContactDetails?.preferred_mode_contact ?
                              {
                                    value: tempContactDetails?.preferred_mode_contact,
                                    label: tempContactDetails?.preferred_mode_contact,
                              } : "",
                        primaryTelephone: tempContactDetails?.phone ? {
                              mobile: tempContactDetails?.phone,
                              mobile_code: tempContactDetails?.phone_code
                        } : "",
                        primaryMobile: tempContactDetails?.mobile_no ? {
                              mobile: tempContactDetails?.mobile_no,
                              mobile_code: tempContactDetails?.mobile_no_country_code
                        } : "",
                        primaryEmail: tempContactDetails?.email_id ?? "",
                        emergencyContactName: tempContactDetails?.emergency_contact_name ?? "",
                        emergencyContactRelationship: tempContactDetails?.emergency_contact_relationship ?
                              {
                                    value: tempContactDetails?.emergency_contact_relationship,
                                    label: tempContactDetails?.emergency_contact_relationship,
                              } : "",
                        emergencyContactNumber: tempContactDetails?.emergency_contact_number ? {
                              mobile: tempContactDetails?.emergency_contact_number,
                              mobile_code: tempContactDetails?.emergency_contact_code
                        } : "",
                        facebook: tempContactDetails?.facebook ?? "",
                        twitter: tempContactDetails?.twitter ?? "",
                        linkedin: tempContactDetails?.linkedin ?? "",
                        nationality: tempContactDetails?.nationality ?? "",
                        nationalID: tempContactDetails?.national_id ?? "",
                        nationalIDExpiry: tempContactDetails?.national_id_expiry ? new Date(tempContactDetails?.national_id_expiry) : "",
                        passportID: tempContactDetails?.passport_id ?? "",
                        passportIDExpiry: tempContactDetails?.passport_expiry ? new Date(tempContactDetails?.passport_expiry) : "",
                        residentID: tempContactDetails?.resident_id ?? "",
                        residentIDExpiry: tempContactDetails?.resident_id_expiry ? new Date(tempContactDetails?.resident_id_expiry) : "",
                        visaNumber: tempContactDetails?.visa_no ?? "",
                        visaNumberExpiry: tempContactDetails?.visa_expiry ? new Date(tempContactDetails?.visa_expiry) : "",
                        bankName: tempContactDetails?.bank_name ?? "",
                        bankBranchName: tempContactDetails?.branch_name ?? "",
                        bankCurrency: tempContactDetails?.bank_currency_id ? {
                              value: tempContactDetails?.bank_currency_id,
                              label: tempContactDetails?.bank_currency_code
                        } : "",
                        bankAccountType: tempContactDetails?.account_type ?? "",
                        bankAccountNumber: tempContactDetails?.account_no ?? "",
                        bankRoutingType: tempContactDetails?.routing_type ?
                              {
                                    value: tempContactDetails?.routing_type,
                                    label: tempContactDetails?.routing_type,
                              } : "",
                        bankRoutingCode: tempContactDetails?.routing_code ?? "",
                        bankAddressLineOne: tempContactDetails?.address_1 ?? "",
                        bankAddressLineTwo: tempContactDetails?.address_2 ?? "",
                        bankCity: tempContactDetails?.bank_city ?? "",
                        bankCountry: tempContactDetails?.bank_country ?? "",
                        chequeName: tempContactDetails?.cheque_name ?? "",
                        preferredCashCollectionOffice: tempContactDetails?.cash_collection_office ?? "",
                        error: initial?.error
                  })
                  setLoad(true)
                  backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
            }).catch((error) => {
                  backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
                  alert.setSnack({
                        ...alert, open: true,
                        severity: AlertProps.severity.error,
                        msg: "Internal error. Please try again later.",
                  });
            });
      }
      React.useEffect(() => {
            if (state?.main?.isEdit) { getContactDetailsByID() } else { setLoad(true) }
            // eslint-disable-next-line
      }, [state?.main?.isEdit])
      const deletedValue = (value, updatedvalue) => {
            setData({ ...data, deletedProperties: [...data?.deletedProperties, value], properties: updatedvalue })
      }
      const removeimg = () => {
            setData({ ...data, profileImage: "" })
      }
      return (
            <div className={classes.createContactRoot}>
                  <Subheader title={state?.main?.isEdit ? "Edit Contact" : "Create New Contact"} goBack={() => { navigate(-1) }} />
                  <PropertyTabs value={value} isHeigth backgroundColor t={t}
                        tab1={<GeneralDetails      t={t} id='pushTitle' data={data} updateState={updateState} updateimg={updateimg} mapResult={mapResultData} mapLoad={mapLoad} load={load} deletedValue={deletedValue} removeimg={removeimg} />}
                        tab2={<NationalIDDetails      t={t} data={data} updateState={updateState} />}
                        tab3={<BankingDetails data={data}      t={t} updateState={updateState} />}
                        tabTittle={tabTittle} />
                  <Box className={classes.bottombtn}>
                        <Box>
                              <Button className={classes.Cancel} disabled={value <= 1 && true} onClick={previous}>Previous</Button>
                        </Box>
                        <Box>
                              <Button className={classes.Cancel} onClick={() => { navigate(-1) }}>Cancel</Button>
                              {value === 3 ?
                                    <Button className={classes.next}
                                          disabled={disable === true ? true : false}
                                          onClick={save}>{state?.main?.isEdit ? "update" : "save"}</Button> :
                                    <Button className={classes.next} onClick={next}>Next</Button>}
                        </Box>
                  </Box>
            </div >
      )
}

export default withTranslation("contacts")(CreateContact)
