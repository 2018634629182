import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import bullet from "highcharts/modules/bullet.js";

bullet(Highcharts);

export const GaugeBar = ({ stat_data }) => {
  const chartRef = useRef(null);
  useEffect(() => {
    Highcharts.setOptions({
      chart: {
        type: "bullet",
      },
      title: {
        text: null,
      },
      legend: {
        enabled: false,
      },
      xAxis: {},
      yAxis: {
        gridLineWidth: 0,
      },
      plotOptions: {
        series: {
          pointPadding: 0.25,
          borderWidth: 0,
          targetOptions: {
            width: "200%",
          },
        },
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
    });
    Highcharts.chart(chartRef.current, {
      chart: {
        inverted: true,
        marginLeft: 20,
        marginTop: 0,
        height: 70,
      },
      xAxis: {
        labels: {
          enabled: false,
        },
      },
      yAxis: {
        max: 100,
        labels: {
          formatter: function () {
            return this.value + "%";
          },
        },
        plotBands: [
          {
            from: 0,
            to: 33.3,
            color: "#caf0f8",
          },
          {
            from: 33.3,
            to: 66.6,
            color: "#90e0ef",
          },
          {
            from: 66.6,
            to: 100,
            color: '#00b4d8'
        }],
        title: null
    },
    series: [{
        color: '#003459',
        data: [{
          y: stat_data?.progress_bar_value,
          target: stat_data?.progress_bar_value,
          details: stat_data?.details
        }]
      }],
    tooltip: {
      useHTML: true, // Enable HTML in the tooltip
      headerFormat: "<table>", // Add opening <table> tag
      pointFormat:
        '<td><span style="color:{point.color};"></span> </td>' +
        '<td style="padding:0"><b>{point.details}</b></td></tr>',
      footerFormat: "</table>", // Add closing </table> tag
    },
});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div ref={chartRef}></div>;
};
