import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const InspectionCardStyles = makeStyles((theme) => ({
    box: {
        padding: "8px 16px 16px 16px",
        backgroundColor: theme.palette.background.paper,
        cursor: "pointer",
        borderRadius: "4px",
        border: `1px solid ${theme.palette.border.secondary}`
    },
    title: {
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary,
        fontSize:"0.875rem"
    },
    heading: {
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.tertiary,
        fontSize:"0.75rem"
    },
    subTitle: {
        color: theme.typography.color.tertiary,
        fontSize:"0.75rem"
    },
    status: {
        fontFamily: FontFamilySwitch().bold,
        fontSize:"0.75rem",
        padding: "2px 8px",
        borderRadius: "4px"
    },
    avatar: {
        borderRadius: "4px"
    },
    resendHeading: {
        color: theme.typography.color.secondary,
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
    },
    resend: {
        color: theme.palette.primary.main,
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
    },
    sendLink: {
        fontFamily: FontFamilySwitch().bold,
        fontSize:"0.75rem",
        padding: "2px 8px",
        borderRadius: "4px",
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.info.light
    },
    trackerRoot: {
        borderLeft: `1px dashed ${theme.palette.border.secondary}`,
        position: "relative"
    },
    dotRoot: {
        position: "absolute",
        top: "10px",
        left: "-10px",
        padding: "2px",
        backgroundColor: "white",
        border: `1px dashed ${theme.palette.border.secondary}`,
        borderRadius: "50%",
        height: "20px",
        width: "20px"
    },
    dotIcon: {
        backgroundColor: theme.palette.border.secondary,
        borderRadius: "50%",
        height: "14px",
        width: "14px"
    }

}));