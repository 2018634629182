/* eslint-disable no-useless-concat */
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, Divider, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import AgreementAccessIMG from "../../assets/agreementAccess";
import { SearchFilter, Subheader, TableWithPagination, TextBox } from '../../components';
import { config } from '../../config';
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from '../../networkcall';
import { AlertProps, getCompanyOption, LocalStorageKeys, NetWorkCallMethods, timeZoneConverter } from '../../utils';
import {
    ApprovedHeading, ApprovedPath,
    ApprovedType,
    DeclinedHeading, DeclinedPath,
    DeclinedType, WaitingForApprovalHeading, WaitingForApprovalPath,
    WaitingForApprovalType
} from '../../utils/agreementUnitAccessDataPoints';
import ShowMoreText from "react-show-more-text";
import { FontFamilySwitch } from "../../utils";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        // height: "100%",
    },
    innerWindow: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        // padding: "16px",
        height: `calc(100vh - 147px)`,
        overflow: "hidden",
        margin: "14px"
    },
    searchFilter: {
        padding: "16px 0px 0px 0px",
        width: "50%"
    },
    table: {
        padding: "0px 16px 0px 16px"
    },
    rootCard: {
        backgroundColor: "white",
        padding: "24px"
        // borderRadius: "16px",
        // boxShadow: "0px 3px 16px #00000014",
    },
    img: {
        minHeight: "42px",
        minWidth: "42px",
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #CED3DD",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    bottomBarTitle: {
        fontSize: "1.125rem",
        fontFamily: FontFamilySwitch().extraBold,
        color: theme.typography.color.primary,
    },
    title: {
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().bold,
        color: "#4E5A6B",
        marginRight: "8px"
    },
    valueText: {
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: "#091B29",
        marginBottom: "8px",
        wordBreak: "break-word",
        direction: "ltr",
        textAlign: "-webkit-match-parent"
    },
    valueTextNo: {
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: "#091B29",
        marginBottom: "8px",
        marginRight: "16px",
        wordBreak: "break-word",
        direction: 'ltr',
        textAlign: 'end'
    },
    sub: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: "#98A0AC",
    },
    approved: {
        fontSize: "0.75rem",
        color: "#FFFFFF",
        fontFamily: FontFamilySwitch().bold,
        background: "#5AC782",
        padding: "2px 8px",
        borderRadius: theme.palette.borderRadius,
        display: "inline",
    },
    progress: {
        fontSize: "0.75rem",
        color: "#FFFFFF",
        fontFamily: FontFamilySwitch().bold,
        background: "#78B1FE",
        padding: "2px 8px",
        borderRadius: theme.palette.borderRadius,
        display: "inline",
    },
    rejected: {
        fontSize: "0.75rem",
        color: "#FFFFFF",
        fontFamily: FontFamilySwitch().bold,
        background: "#FF4B4B",
        padding: "2px 8px",
        borderRadius: theme.palette.borderRadius,
        display: "inline",
    },
    round: {
        color: "#00000014",
        fontSize: "40px",
    },
    border: {
        // margin: "16px 0px",
        border: "1px solid #E4E8EE",
    },
    heading: {
        fontSize: "0.75rem",
        color: "#98A0AC",
        fontFamily: FontFamilySwitch().semiBold,
    },
    name: {
        fontSize: "1rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    drawer: {
        "& .MuiDrawer-paper": {
            borderTopRightRadius: "16px",
            borderTopLeftRadius: "16px",
        },
        "& .MuiContainer-root": {
            padding: "0px 8px",
        },
    },
    dialog1: {
        "& .MuiDialog-paper": {
            borderRadius: "4px",
            padding: "0px",
            height: "600px",
            width: "900px",
            minWidth: 900
        },
    },
    dialog2: {
        "& .MuiDialog-paper": {
            borderRadius: "4px",
            padding: "0px",
            width: "900px",
            minWidth: 900
        },
    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
        padding: "4px 8px 8px 8px",
    },
    qr: {
        fontSize: "1rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    expire: {
        fontSize: "0.75rem",
        color: theme.typography.color.Tertiary,
        fontFamily: FontFamilySwitch().regular,
    },
    cancelbtn: {
        padding: "12px",
        border: "1px solid #5078E1",
        backgroundColor: "white",
        borderRadius: "10px",
    },
    submitbtn: {
        borderRadius: "10px",
        padding: "12px 8px",
    },
    drawerHeaderStyle: {
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
    },
    drawerHeaderTextStyle: {
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().extraBold,
        color: "#091B29",
    },
    drawerFooterStyle: {
        justifyContent: "space-between",
        alignItems: "center",
        padding: "24px",
    },
    drawerButtonStyle: {
        "&:hover": "none",
        borderRadius: theme.palette.borderRadius,
        padding: "14px",
        background: theme.palette.primary.main,
        fontSize: "0.875rem",
        fontWeight: "normal",
        color: "#FFF",
    },
    seeMoreLessTextStyle: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.palette.primary.main,
    },
}))


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (children)}
        </div>
    );
}

const AgreementUnitAccess = props => {
    const { t = () => false } = props;

    const classes = useStyles();

    const [value, setValue] = React.useState(0);
    const backdrop = React.useContext(BackdropContext);
    const alert = React.useContext(AlertContext);
    const auth = React.useContext(AuthContext)

    const [companyList, setCompanyList] = React.useState([]);
    const [selectedCompany, setSelectedCompany] = React.useState({});

    const exportRef = React.useRef();

    const initialDeclineFormData = {
        remarkForDecline: "",
        error: {
            remarkForDecline: "",
        }
    }

    const [declineFormData, setDeclineFormData] = React.useState({ ...initialDeclineFormData });

    const [status, setStatus] = React.useState(["Pending"]);

    const [searchText, setSearchText] = React.useState("");

    const [isAccessDetailsDialogOpen, setIsAccessDetailsDialogOpen] = React.useState(false);

    const [isDialogActionButton, setIsDialogActionButton] = React.useState(true);

    const [isAcceptActionButton, setIsAcceptActionButton] = React.useState(true);
    const [disable, setDisable] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState("");

    const [accessDetails, setAccessDetails] = React.useState([]);

    const [waitingForApprovalData, setWaitingForApprovalData] = React.useState({
        heading: WaitingForApprovalHeading(t),
        row: [],
        path: WaitingForApprovalPath,
        type: WaitingForApprovalType,
    });
    const [approvedData, setApprovedData] = React.useState({
        heading: ApprovedHeading(t),
        row: [],
        path: ApprovedPath,
        type: ApprovedType,
    });
    const [declinedData, setDeclinedData] = React.useState({
        heading: DeclinedHeading(t),
        row: [],
        path: DeclinedPath,
        type: DeclinedType,
    });
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
    }
    React.useEffect(() => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
        }
        // eslint-disable-next-line
    }, [auth]);

    const updateDeclineFormData = (key, value) => {
        let error = declineFormData?.error;
        error[key] = "";
        setDeclineFormData({ ...declineFormData, [key]: value, error });
    };

    const isFormValid = () => {
        let isValid = true;
        let error = declineFormData.error;
        if (declineFormData?.remarkForDecline?.length === 0) {
            isValid = false;
            error.remarkForDecline = t("Please Enter Remarks");
        } else {
            error.remarkForDecline = "";
        }
        setDeclineFormData({ ...declineFormData, error });
        return isValid;
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0) {
            setIsDialogActionButton(true);
            setStatus(["Pending"])
        }
        if (newValue === 1) {
            setIsDialogActionButton(false);
            setStatus(["Approved"])
        }
        if (newValue === 2) {
            setIsDialogActionButton(false);
            setStatus(["Rejected"])
        }
    };

    const RequestAgainstDetail = (item) => {
        let list = {
            propertyName: item?.unit?.property?.name,
            propertyNo: item?.unit?.block?.property?.property_no,
            blockName: item?.unit?.block?.name,
            blockNo: item?.unit?.block?.block_no,
            unitName: item?.unit?.name,
            unitNo: item?.unit?.unit_no
        }
        let list_key = [
            "propertyName", "propertyNo", "blockName", "blockNo",
            "unitName", "unitNo"
        ]
        let list_array = []
        for (let i = 0; i < list_key.length; i++) {
            if (list[list_key[i]]?.length > 0) {
                list_array.push(list[list_key[i]])
            }
        }
        return list_array.join(' - ')
    }

    React.useEffect(() => {

        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "",
        });
        const payload = {
            company_id: [selectedCompany?.value],
            request_status: status,
            limit: null
        };
        NetworkCall(
            `${config.api_url}/queries/agreement_unit_access/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((response) => {
                if (status?.[0] === "Pending") {
                    let tempPending = response?.data?.data?.agreement_access?.map((item) => {
                        let _item = {
                            id: item?.id ? item?.id : "",
                            request_type: item?.request_type ? item?.request_type : "-",
                            requested_date: ((item?.created_at !== null && item?.created_at !== "") ? timeZoneConverter(item?.created_at) : "-"),
                            request_against_detail: item?.request_type === "Agreement Access" ?
                                ((item?.account && item?.account?.agreement?.length > 0) ?
                                    item?.account?.agreement?.map((item, index) => (index !== 0 ? " " : "") + item?.agreement_no).join()
                                    : "-") :
                                (item?.unit ?
                                    RequestAgainstDetail(item) : "-"),
                            existing_access: "-",
                            requested_by: item?.created_by?.first_name ? item?.created_by?.first_name : "-",
                            name: item?.name ? item?.name : "-",
                            mobile_no: item?.mobile_no ? item?.mobile_no : "-",
                            email_id: item?.email_id ? item?.email_id : "-",
                            icon: "view"
                        };
                        return _item;
                    });
                    setWaitingForApprovalData({ ...waitingForApprovalData, row: tempPending });
                }
                if (status?.[0] === "Approved") {
                    let tempData = response?.data?.data?.agreement_access?.map((item) => {
                        let _item = {
                            id: item?.id ? item?.id : "",
                            request_type: item?.request_type ? item?.request_type : "-",
                            requested_date: ((item?.created_at !== null && item?.created_at !== "") ? timeZoneConverter(item?.created_at) : "-"),
                            request_against_detail: item?.request_type === "Agreement Access" ?
                                ((item?.account && item?.account?.agreement?.length > 0) ?
                                    item?.account?.agreement?.map((item, index) => (index !== 0 ? " " : "") + item?.agreement_no).join()
                                    : "-") :
                                (item?.unit ?
                                    RequestAgainstDetail(item) : "-"),
                            existing_access: "-",
                            requested_by: item?.created_by?.first_name ? item?.created_by?.first_name : "-",
                            name: item?.name ? item?.name : "-",
                            mobile_no: item?.mobile_no ? item?.mobile_no : "-",
                            email_id: item?.email_id ? item?.email_id : "-",
                            icon: "view"
                        };
                        return _item;
                    });
                    setApprovedData({ ...approvedData, row: tempData });
                }
                if (status?.[0] === "Rejected") {
                    let tempRejected = response?.data?.data?.agreement_access?.map((item) => {
                        let _item = {
                            id: item?.id ? item?.id : "",
                            request_type: item?.request_type ? item?.request_type : "-",
                            requested_date: ((item?.created_at !== null && item?.created_at !== "") ? timeZoneConverter(item?.created_at) : "-"),
                            request_against_detail: item?.request_type === "Agreement Access" ?
                                ((item?.account && item?.account?.agreement?.length > 0) ?
                                    item?.account?.agreement?.map((item, index) => (index !== 0 ? " " : "") + item?.agreement_no).join()
                                    : "-") :
                                (item?.unit ?
                                    RequestAgainstDetail(item) : "-"),
                            existing_access: "-",
                            requested_by: item?.created_by?.first_name ? item?.created_by?.first_name : "-",
                            name: item?.name ? item?.name : "-",
                            mobile_no: item?.mobile_no ? item?.mobile_no : "-",
                            email_id: item?.email_id ? item?.email_id : "-",
                            icon: "view"
                        };
                        return _item;
                    });
                    setDeclinedData({ ...declinedData, row: tempRejected });
                }
                backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            }).catch((error) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                })
            });
        // eslint-disable-next-line
    }, [selectedCompany, status, searchText]);

    const getAgreementUnitAccessDetails = (data) => {

        const payload = {
            id: data?.id
        };
        NetworkCall(
            `${config.api_url}/queries/agreement_unit_access/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((response) => {

                setAccessDetails(response?.data?.data?.agreement_access?.[0])
                setIsAccessDetailsDialogOpen(true);
            }).catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),
                });
            });
    };

    const handleWaitingForApprovalTableRowView = (type, data) => {
        setIsDialogActionButton(true);
        setDialogTitle(t("Request View"));
        getAgreementUnitAccessDetails(data);
    };

    const handleApprovedTableRowView = (type, data) => {
        setIsDialogActionButton(false);
        setDialogTitle(t("Approved"));
        getAgreementUnitAccessDetails(data);
    };

    const handleDeclinedTableRowView = (type, data) => {
        setIsDialogActionButton(false);
        setDialogTitle(t("Declined"));
        getAgreementUnitAccessDetails(data);
    };

    const closeAgreementUnitAccessDialog = () => {
        setIsAccessDetailsDialogOpen(false);
    }

    const agreementUnitAccessView = () => {
        return (<Grid
            container
            style={{
                // overflow: "hidden",
                height: "600px",
                overflowX: "hidden",
                overflowY: "scroll",
                scrollbarWidth: "none",
                "&::-webkit-scrollbar": {
                    display: "none",
                },
            }}
        >
            <Grid item xs={12} ref={exportRef} id="ticket">
                <div className={classes.rootCard}  >
                    <Box display="flex" alignItems="start" >
                        <Box className={classes.img}>
                            <AgreementAccessIMG />
                        </Box>
                        <Box flexGrow={1} marginInlineStart="14px">
                            <Typography className={classes.title}>
                                {accessDetails?.request_type === "Unit Access" && t("Unit Level Access")}
                                {accessDetails?.request_type === "Agreement Access" && t("Account Level Access")}
                            </Typography >
                            <Typography className={classes.sub}>
                                {accessDetails?.created_at ? ("Requested on " + timeZoneConverter(accessDetails?.created_at, "DD MMM yyyy hh:mm:ss A")) : ""}
                                {accessDetails?.approved_on ? (", " + "Approved on " + timeZoneConverter(accessDetails?.approved_on, "DD MMM yyyy hh:mm:ss A")) : ""}
                            </Typography>
                            {
                                accessDetails?.request_status === "Pending" && (
                                    <Typography className={classes.progress}>
                                        {t("In Progress")}

                                    </Typography >
                                )
                            }
                            {
                                accessDetails?.request_status === "Approved" && (
                                    <Typography className={classes.approved}>
                                        {t("Approved")}
                                    </Typography >
                                )
                            }
                            {
                                accessDetails?.request_status === "Rejected" && (
                                    <Typography className={classes.rejected}>
                                        {t("Declined")}
                                    </Typography >
                                )
                            }
                        </Box >
                    </Box ></div >
                {(accessDetails?.request_status !== "Pending" && accessDetails?.remarks !== null) &&
                    (<Box display="flex" justifyContent={"center"} alignItems="center" textAlign={"center"}
                        style={{
                            backgroundColor: accessDetails?.request_status === "Approved" ? "#EEF9EE" : "#FFECEC",
                            padding: "8px 16px"
                        }}>
                        <Typography
                            style={{
                                textAlign: "center",
                                color: accessDetails?.request_status === "Approved" ? "#5AC782" : "#FF4B4B",
                                fontFamily: FontFamilySwitch().semiBold,
                                wordWrap: "break-word",
                                wordBreak: "break-word",
                                fontSize: "0.75rem"
                            }}>
                            {accessDetails?.remarks !== "" ? t(accessDetails?.remarks) : t("Reason Not Mentioned")}
                        </Typography >
                    </Box >)}
                <div className={classes.rootCard} style={{ padding: (accessDetails?.request_status !== "Pending" && accessDetails?.remarks !== null) ? "24px" : "0px 24px 24px 24px" }} >
                    {(accessDetails?.request_status !== "Pending" && accessDetails?.remarks !== null) ? <></> : <Box display="flex" alignItems="center" style={{ padding: "0px 0px 24px 0px" }}>
                        <Box flexGrow={1}>
                            <Box className={classes.border} />
                        </Box>
                    </Box>}
                    {accessDetails?.request_type === "Unit Access" && <Box style={{ padding: "0px 0px 24px 0px" }}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography className={classes.heading}>
                                    {t("REQUESTED BY")}
                                </Typography >
                                <Typography className={classes.valueText}>
                                    {accessDetails?.created_by?.first_name ? accessDetails?.created_by?.first_name : "-"}
                                </Typography>
                            </Grid >
                            <Grid item xs={6}>
                                <Typography className={classes.heading}>
                                    {t("PROPERTY")}
                                </Typography >
                                <Typography className={classes.valueText}>
                                    {(accessDetails?.unit?.property?.name ? (accessDetails?.unit?.property?.name + " - ") : "") +
                                        (accessDetails?.unit?.property?.property_no ? (accessDetails?.unit?.property?.property_no) : "-")}
                                </Typography>
                            </Grid >
                        </Grid >
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography className={classes.heading}>
                                    {t("BLOCK")}
                                </Typography >
                                <Typography className={classes.valueText}>
                                    {(accessDetails?.unit?.block?.name ? (accessDetails?.unit?.block?.name + " - ") : "") +
                                        (accessDetails?.unit?.block?.block_no ? (accessDetails?.unit?.block?.block_no) : "-")}
                                </Typography>
                            </Grid >
                            <Grid item xs={6}>
                                <Typography className={classes.heading}>
                                    {t("UNIT")}
                                </Typography >
                                <Typography className={classes.valueText}>
                                    {(accessDetails?.unit?.name ? (accessDetails?.unit?.name + " - ") : "") +
                                        (accessDetails?.unit?.unit_no ? (accessDetails?.unit?.unit_no) : "-")}
                                </Typography>
                            </Grid >
                        </Grid >
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography className={classes.heading}>
                                    {t("Existing Access")}
                                </Typography >
                                <Typography className={classes.valueText}>
                                    -
                                </Typography>
                            </Grid >
                            <Grid item xs={6} />
                        </Grid >
                    </Box >}
                    {
                        accessDetails?.request_type === "Agreement Access" && <Box style={{ padding: "0px 0px 24px 0px" }}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography className={classes.heading}>
                                        {t("REQUESTED BY")}
                                    </Typography >
                                    <Typography className={classes.valueText}>
                                        {accessDetails?.created_by?.first_name ? accessDetails?.created_by?.first_name : "-"}
                                    </Typography>
                                </Grid >
                                <Grid item xs={6}>
                                    <Typography className={classes.heading}>
                                        {t("AGREEMENT")}
                                    </Typography >
                                    <ShowMoreText
                                        lines={2}
                                        more={t("Show More")}
                                        less={t("Show Less")}
                                        className={classes.valueText}
                                        anchorClass={classes.seeMoreLessTextStyle}
                                        expanded={false}
                                        truncatedEndingComponent={"... "}
                                    >
                                        <Typography className={classes.valueText}>

                                            {((accessDetails?.account && accessDetails?.account?.agreement?.length > 0) ?
                                                accessDetails?.account?.agreement?.map((item, index) => (index !== 0 ? " " : "") + item?.agreement_no).join()
                                                : "-")}
                                        </Typography>
                                    </ShowMoreText>
                                </Grid >
                            </Grid >
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography className={classes.heading}>
                                        {t("Existing Access")}
                                    </Typography >
                                    <Typography className={classes.valueText}>
                                        -
                                    </Typography>
                                </Grid >
                                <Grid item xs={6} />
                            </Grid >
                        </Box >}
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1}>
                            <Box className={classes.border} />
                        </Box>
                    </Box>
                    <Box style={{ padding: "24px 0px 0px 0px" }} >
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography className={classes.heading}>
                                    {t("REQUESTED FOR")}
                                </Typography >
                                <Typography className={classes.valueText} >
                                    {accessDetails?.name ? accessDetails?.name : "-"}
                                </Typography>
                            </Grid >
                            <Grid item xs={6}>
                                <Typography className={classes.heading}>
                                    {t("MOBILE NUMBER")}
                                </Typography>
                                <Typography className={auth?.auth?.auth?.language === 'ar' ? classes.valueTextNo : classes.valueText} >
                                    {accessDetails?.mobile_country_code ? (accessDetails?.mobile_country_code + " ") : ""}
                                    {accessDetails?.mobile_no ? accessDetails?.mobile_no : ""}
                                </Typography>
                            </Grid >
                        </Grid >
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography className={classes.heading}>
                                    {t("MAIL ID")}
                                </Typography >
                                <Typography className={classes.valueText}>
                                    {accessDetails?.email_id ? accessDetails?.email_id : "-"}
                                </Typography>
                            </Grid >
                            <Grid item xs={6} />
                        </Grid >
                    </Box >
                </div >
            </Grid >
        </Grid >);
    }

    const acceptAgreementUnitAccess = () => {
        setDisable(true)
        const payload = {
            tenantId: `${config.tenantId}`,
            user_profile_id: localStorage.getItem(LocalStorageKeys.profileID),
            user_name: accessDetails?.name,
            mobile_no: accessDetails?.mobile_no,
            mobile_no_country_code: accessDetails?.mobile_country_code,
            email_id: accessDetails?.email_id,
            is_unit: accessDetails?.request_type === "Unit Access" ? true : false,
            agreement_id: accessDetails?.request_type === "Unit Access" ? accessDetails?.agreement_id : undefined,
            unit_id: accessDetails?.request_type === "Unit Access" ? accessDetails?.unit?.id : undefined,
            account_no: accessDetails?.request_type === "Unit Access" ? undefined : accessDetails?.account_id
        };
        NetworkCall(
            `${config.api_url}/access-control/agreement-unit`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((response) => {
                const payload = {
                    data: [
                        {
                            id: accessDetails?.id,
                            updated_at: new Date().toISOString(),
                            updated_by: localStorage.getItem(LocalStorageKeys.profileID),
                            approved_on: new Date().toISOString(),
                            approved_by: localStorage.getItem(LocalStorageKeys.profileID),
                            remarks: "Access mail sent",
                            request_status: "Approved"
                        }
                    ]
                };
                NetworkCall(
                    `${config.api_url}/queries/agreement_access/upsert`,
                    NetWorkCallMethods.post,
                    payload,
                    null,
                    true,
                    false).then((response) => {
                        setDisable(false)
                        setStatus(["Pending"])
                        setIsDialogActionButton(false);
                        getAgreementUnitAccessDetails(accessDetails);
                    }).catch((error) => {
                        setDisable(false)
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: t("Some Thing Went Wrong"),
                            vertical: AlertProps.vertical.top,
                            horizontal: AlertProps.horizontal.center
                        })
                    });
            }).catch((error) => {
                setDisable(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                })
            });
    }

    const declineAgreementUnitAccessForm = () => {
        return (<Grid p={2}>
            <TextBox
                multiline
                label={t("Remark For Decline")}
                placeholder={t("Enter Remarks")}
                value={declineFormData?.remarkForDecline}
                onChange={(e) => {
                    updateDeclineFormData("remarkForDecline", e.target.value);
                }}
                isError={declineFormData?.error?.remarkForDecline?.length > 0}
                errorMessage={declineFormData?.error?.remarkForDecline} />
        </Grid>);
    }

    const declineAgreementUnitAccess = () => {
        if (isFormValid()) {
            setDisable(true)
            const payload = {
                data: [
                    {
                        id: accessDetails?.id,
                        updated_at: new Date().toISOString(),
                        updated_by: localStorage.getItem(LocalStorageKeys.profileID),
                        remarks: declineFormData.remarkForDecline,
                        request_status: "Rejected"
                    }
                ]
            };
            NetworkCall(
                `${config.api_url}/queries/agreement_access/upsert`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false).then((response) => {
                    setDisable(false)
                    setStatus(["Pending"])
                    setIsDialogActionButton(false);
                    setIsAcceptActionButton(true);
                    getAgreementUnitAccessDetails(accessDetails);
                    setDeclineFormData({ ...initialDeclineFormData });
                }).catch((error) => {
                    setDisable(false)
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some Thing Went Wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center
                    })
                });
        } else { return false; }
    }

    return <div className={classes.root}>
        <Subheader title={t("Access Request")} hideBackButton
            select options={companyList} value={selectedCompany} onchange={(e) => handleCompanyChange(e)} />
        <Grid direction={"column"} className={classes.innerWindow}>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs style={{ marginInlineStart: "12px" }} value={value} onChange={handleChange}>
                        <Tab style={{ padding: "0px 6px" }} label={t("Waiting for Approval")} />
                        <Tab label={t("Approved")} />
                        <Tab label={t("Declined")} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    {waitingForApprovalData.heading.length > 0 && (
                        <Grid item xs={12} className={classes.table}>
                            <div className={classes.searchFilter}>
                                <SearchFilter placeholder={t("Search Agreement/Unit")} value={searchText} handleChange={(value) => setSearchText(value)} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
                            </div >
                            <TableWithPagination
                                heading={waitingForApprovalData.heading}
                                rows={waitingForApprovalData.row}
                                path={waitingForApprovalData.path}
                                showpagination={waitingForApprovalData.row.length > 10 ? true : false}
                                onClick={() => false}
                                handleIcon={handleWaitingForApprovalTableRowView}
                                tableType="no-side"
                                showpdfbtn={false}
                                showexcelbtn={false}
                                showSearch={false}
                                dataType={waitingForApprovalData.type}
                                height={'calc(100vh - 270px)'}
                                view={true}
                                edit={true}
                                delete={true} />
                            <br />
                        </Grid >
                    )}
                </TabPanel >
                <TabPanel value={value} index={1}>
                    {approvedData.heading.length > 0 && (
                        <Grid item xs={12} className={classes.table}>
                            <div className={classes.searchFilter}>
                                <SearchFilter placeholder={t("Search Agreement/Unit")} value={searchText} handleChange={(value) => setSearchText(value)} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
                            </div >
                            <TableWithPagination
                                heading={approvedData.heading}
                                rows={approvedData.row}
                                path={approvedData.path}
                                showpagination={approvedData.row.length > 10 ? true : false}
                                onClick={() => false}
                                handleIcon={handleApprovedTableRowView}
                                tableType="no-side"
                                showpdfbtn={false}
                                showexcelbtn={false}
                                showSearch={false}
                                dataType={approvedData.type}
                                height={'calc(100vh - 270px)'}
                                view={true}
                                edit={true}
                                delete={true} />
                            <br />
                        </Grid >
                    )}
                </TabPanel >
                <TabPanel value={value} index={2}>
                    {declinedData.heading.length > 0 && (
                        <Grid item xs={12} className={classes.table}>
                            <div className={classes.searchFilter}>
                                <SearchFilter placeholder={t("Search Agreement/Unit")} value={searchText} handleChange={(value) => setSearchText(value)} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
                            </div >
                            <TableWithPagination
                                heading={declinedData.heading}
                                rows={declinedData.row}
                                path={declinedData.path}
                                showpagination={declinedData.row.length > 10 ? true : false}
                                onClick={() => false}
                                handleIcon={handleDeclinedTableRowView}
                                tableType="no-side"
                                showpdfbtn={false}
                                showexcelbtn={false}
                                showSearch={false}
                                dataType={declinedData.type}
                                height={'calc(100vh - 270px)'}
                                view={true}
                                edit={true}
                                delete={true} />
                            <br />
                        </Grid >
                    )}
                </TabPanel >
            </Box >
        </Grid >
        <Dialog
            className={isAcceptActionButton ? classes.dialog1 : classes.dialog2}
            fullWidth
            open={isAccessDetailsDialogOpen}
            onClose={closeAgreementUnitAccessDialog}>
            <Grid
                container
                direction="row"
                className={classes.drawerHeaderStyle}>
                <Grid>
                    <Typography className={classes.drawerHeaderTextStyle}>
                        {dialogTitle}
                    </Typography>
                </Grid>
                <Grid>
                    <IconButton
                        style={{
                            padding: "0px",
                        }}
                        onClick={closeAgreementUnitAccessDialog}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Divider />
            {isAcceptActionButton ? agreementUnitAccessView() : declineAgreementUnitAccessForm()}
            {isDialogActionButton && (<>
                {isAcceptActionButton ? (<Grid className={classes.drawerFooterStyle}>
                    <Box height={"6px"} />
                    <Grid container direction={"row"} >
                        <Grid container xs={6}>
                            <Button
                                fullWidth
                                className={classes.drawerButtonStyle}
                                style={{ marginRight: "8px", backgroundColor: "#FF4B4B", height: "40px" }}
                                variant="contained"
                                disabled={disable}
                                onClick={() => setIsAcceptActionButton(false)}>
                                {t("Decline")}
                            </Button >
                        </Grid >
                        <Grid container xs={6}>
                            <Button
                                fullWidth
                                className={classes.drawerButtonStyle}
                                style={{ marginLeft: "8px", backgroundColor: "#5AC782", height: "40px" }}
                                variant="contained"
                                disabled={disable}
                                onClick={acceptAgreementUnitAccess}>

                                {t("Accept")}

                            </Button>
                        </Grid>
                    </Grid >
                </Grid >) : (<Grid className={classes.drawerFooterStyle}>
                    <Box height={"6px"} />
                    <Grid container direction={"row"} >
                        <Grid container xs={6}>
                            <Button
                                fullWidth
                                className={classes.drawerButtonStyle}
                                style={{ marginRight: "8px", backgroundColor: "#FFFFFF", color: "#091B29", height: "40px" }}
                                variant="contained"
                                onClick={() => setIsAcceptActionButton(true)}>
                                {t("Back")}
                            </Button >
                        </Grid >
                        <Grid container xs={6}>
                            <Button
                                fullWidth
                                className={classes.drawerButtonStyle}
                                style={{ marginLeft: "8px", backgroundColor: "#FF4B4B", height: "40px" }}
                                variant="contained"
                                disabled={disable}
                                onClick={declineAgreementUnitAccess}>

                                {t("Decline")}
                            </Button>
                        </Grid>
                    </Grid >
                </Grid >)}</>)}
        </Dialog >
    </div >

}
export default withTranslation("agreement")(AgreementUnitAccess)

