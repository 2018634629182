import { Badge, Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import FilterIMG from '../../../assets/filter';
import { FilterGenerator } from '../../../components';
import { CalendarDateChange } from '../../../components/calendarDateChange';
import { loadOptionsApis } from '../../../utils/asyncPaginateLoadOptions';
import { DropdownCard } from './dropdownCard';
import { useStyles } from "./style";

export const CalHeader = ({ state = {}, prev = () => false, next = () => false, resource = [], setSeletedType = () => false, selectedType = {}, t = () => false, onApplyFilter = () => false, filterData = {}, companyId = "" }) => {

    const classes = useStyles()
    const [drawer, setDrawer] = React.useState(false)
    const manualResponseProfession = (array) => {
        const details = array?.data?.map((i) => {
            return {
                label: i?.label,
                value: i?.value
            }
        })
        return details
    };
    const manualResponseDepartment = (array) => {
        const details = array?.department_master?.map((i) => {
            return {
                label: i?.label,
                value: i?.value
            }
        })
        return details
    };
    return (
        <Grid container alignItems={"center"} justifyContent="space-between">
            <Grid item >
                <Box width="200px">
                    <DropdownCard t={t} setSeletedType={setSeletedType} selectedType={selectedType} options={resource} />
                </Box >
            </Grid >
            <Grid item>

                <CalendarDateChange state={state} prev={prev} next={next} />
            </Grid>
            <Grid item >
                <Stack direction="row" spacing={2} alignItems="center" marginRight="12px">
                    <Box className={classes.filterbox} display={'flex'} justifyContent={"center"} onClick={() => setDrawer(true)}>
                        {(filterData?.job?.length > 0 || filterData?.department?.length > 0 || filterData?.profession?.length > 0) ? <Badge variant="dot" color="primary"><FilterIMG /></Badge> : <FilterIMG />}
                    </Box>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Box className={classes.box} />
                        <Typography className={classes.desc}>{t("Week Off Days")}</Typography>
                    </Stack >

                    <Stack direction="row" spacing={1} alignItems="center">
                        <Box className={classes.box1} />
                        <Typography className={classes.desc}>{t("Time Off Day")}</Typography>
                    </Stack>

                </Stack>
            </Grid>

            {drawer && (
                <FilterGenerator
                    open={drawer}
                    onClose={() => setDrawer(false)}

                    components={[
                        {
                            component: "select",
                            value: filterData?.department,
                            state_name: "department",
                            label: t("Department"),
                            placeholder: t("Select Department"),
                            labelSize:"0.875rem",
                            // options: [],
                            loadOptions: (search, array, handleLoading) =>
                                loadOptionsApis(
                                    "queries/opportunity/department_master/get",
                                    {
                                        "company_id": companyId,
                                        status:[true]
                                    },
                                    search,
                                    array,
                                    handleLoading,
                                    "data",
                                    { "custom_data": true },
                                    manualResponseDepartment
                                ),
                            isMulti: true,
                            isPaginate: true,
                        },
                        {
                            component: "select",
                            value: filterData?.job,
                            state_name: "job",
                            label: t("Job"),
                            placeholder: t("Select Job"),
                            labelSize:"0.875rem",
                            // options: [],
                            loadOptions: (search, array, handleLoading) =>
                                loadOptionsApis(
                                    "queries/job_master",
                                    {
                                        "company_id": companyId,
                                        status:[true]
                                    },
                                    search,
                                    array,
                                    handleLoading,
                                    "data",
                                    { "custom_data": true },
                                    manualResponseProfession
                                ),
                            isMulti: true,
                            isPaginate: true,
                        },
                        {
                            component: "select",
                            value: filterData?.profession,
                            state_name: "profession",
                            label: t("Profession"),
                            placeholder: t("Select Profession"),
                            labelSize:"0.875rem",
                            // options: [],
                            loadOptions: (search, array, handleLoading) =>
                                loadOptionsApis(
                                    "queries/profession_master",
                                    {
                                        "company_id": companyId,
                                    },
                                    search,
                                    array,
                                    handleLoading,
                                    "data",
                                    { "custom_data": true },
                                    manualResponseProfession
                                ),
                            isMulti: true,
                            isPaginate: true,
                        },
                    ]}
                    onApply={(value) => onApplyFilter(value)}
                />
            )}
        </Grid >
    )
}