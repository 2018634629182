import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Subheader } from "../../../components";
import { getSettingList, getSettingList1 } from "../../../utils/agreementUtils";
import { SettingList } from "../../companyCreation/components";
import { AgreementDocument, AgreementInformation, BillingInformation, CustomerInformation, OppertunityInformation, QuotationInformation, QuotationPreview, RenewalInformation, ReservationInfo, RevenueSummery, ConfirmOrdersInformation, TermsAndCondition, WalletCredits, FinanceInformation } from "../agreementPreviewTabs";
import { AggrementTabStyles } from "./styles";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../../utils";
import { AlertContext } from "../../../contexts";



const AgreementPreview = ({ details = {}, id = "", reload = () => false, t = () => false, onSubmit = () => false }) => {
    const classes = AggrementTabStyles();
    const alert = React.useContext(AlertContext);

    //get preview list
    const list = details?.is_renewal ?
        getSettingList1(t, details?.quotation?.lead, details?.quotation?.id, details?.quotation?.reservation?.[0]?.id, details?.quotation?.id)
        : getSettingList(t, details?.quotation?.lead, details?.quotation?.id, details?.quotation?.reservation?.[0]?.id, details?.quotation?.id);

    const [selected, setSelected] = React.useState(list[0]);
    const [data, setData] = React.useState({
        agreement_sign_date: null,
        legacy_contract_id: "",
        warranty_date: null,
        sla_contract_id: "",
        agreement_email: [
            {
                label: "Email 1",
                value: ""
            },
            {
                label: "Email 2",
                value: ""
            },
            {
                label: "Email 3",
                value: ""
            },
            {
                label: "Email 4",
                value: ""
            }
        ],
        application_number: null,
        application_date: "",
        financing_bank: null,
        upfront_amount: "",
        approval_reference: "",
        approved_amount: "",
        emi_amount: "",
        installments: "",
        starting_from: "",
        signed_date: "",
        period: "",
        agreement_no:"",
        is_edit:false,
        owner:null,
        sla_hours:"",
        is_mail_trigger:false,
        renewal:false,
        project_id:"",
        allow_extension:false

    })
    const [isValid , setIsValid] = React.useState(false)

    const updateState = (k, v) => {
        if(k === "sla_contract_id"){
            return setData({ ...data, [k]: v  , project_id:""})
        }
        if(k === "sla_account_id"){
            return setData({ ...data, [k]: v  , project_id:"" , sla_contract_id:""})
        }
        setData({ ...data, [k]: v })
    }
    const updateEmails = (i, v) => {
        const values = [...data?.agreement_email]
        values[i] = {
            ...values[i],
            value: v
        }
        updateState("agreement_email", [...values])

    }
    const onCreate = () => {
        if(data?.is_mail_trigger === "" || data?.is_mail_trigger === null || data?.renewal === "" || data?.renewal === null || data?.allow_extension === "" || data?.allow_extension === null){
            return alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill mandatory fields"),
            });
        }
        const payload = {
            agreement_sign_date: data?.agreement_sign_date ?? undefined,
            legacy_contract_id: data?.legacy_contract_id ?? undefined,
            warranty_date: data?.warranty_date ?? undefined,
            sla_contract_id: data?.sla_contract_id?.value ?? null,
            agreement_email: data?.agreement_email ?? undefined,
            application_number: data?.application_number ?? undefined,
            application_date: data?.application_date ?? undefined,
            approval_date: data?.approval_date ?? undefined,
            financing_bank: data?.financing_bank?.value ?? undefined,
            upfront_amount: !!data?.upfront_amount ? Number(data?.upfront_amount) :undefined,
            approval_reference: data?.approval_reference ?? undefined,
            approved_amount: !!data?.approved_amount ? Number(data?.approved_amount) : undefined,
            emi_amount: !!data?.emi_amount ? Number(data?.emi_amount) : undefined,
            installments: data?.installments ?? undefined,
            starting_from: data?.starting_from ?? undefined,
            signed_date: data?.signed_date ?? undefined,
            period: !!data?.period ? data?.period : undefined,
            agreement_id:id,
            agreement_no:data?.agreement_no ? data?.agreement_no?.trim() : undefined,
            owner_id:data?.owner?.id ?? null,
            sla_hours:data?.sla_hours ? Number(data?.sla_hours) : undefined,
            is_mail_trigger:data?.is_mail_trigger,
            renewal:data?.renewal ?? false,
            project_id:data?.project_id?.value ?? null,
            sla_account_id:data?.sla_account_id?.value ?? null,
            allow_extension:data?.allow_extension ?? false
        }
        NetworkCall(
            `${config.api_url}/agreement/upsert_agreement_details`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            reload();
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Agreement Details updated Successfully"),
            });
        }).catch((err) => {
            if(err?.response?.status === 406){
              return alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Agreement No Already Exists"),
            });
            }
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something went wrong"),
            });
        })
    }
    useEffect(() => {
        setData({
            agreement_sign_date: details?.agreement_sign_date ? new Date(details?.agreement_sign_date) : null,
            legacy_contract_id: details?.legacy_contract_id,
            warranty_date: details?.warranty_date ?  new Date(details?.warranty_date) : null,
            sla_contract_id: details?.contracts?.value ? {
                label: `${details?.contracts?.label} - ${details?.contracts?.account?.name}`,
                value:details?.contracts?.value
            } : "",
            agreement_email: details?.agreement_email?.length > 0 ? details?.agreement_email : [
                {
                    label: "Email 1",
                    value: ""
                },
                {
                    label: "Email 2",
                    value: ""
                },
                {
                    label: "Email 3",
                    value: ""
                },
                {
                    label: "Email 4",
                    value: ""
                }
            ],
            application_number: details?.application_number,
            application_date: details?.application_date ? new Date(details?.application_date) : null,
            financing_bank: details?.financing,
            upfront_amount: details?.upfront_amount,
            approval_reference: details?.approval_reference,
            approved_amount: details?.approved_amount,
            emi_amount: details?.emi_amount,
            installments: details?.installments,
            starting_from: details?.starting_from ? new Date(details?.starting_from) : null,
            signed_date: details?.signed_date ? new Date(details?.signed_date) : null,
            period: details?.period,
            agreement_no:details?.agreement_no ?? null,
            is_edit:false,
            approval_date:details?.approval_date ? new Date(details?.approval_date) : null,
            owner:details?.owner,
            sla_hours:details?.sla_hours,
            is_mail_trigger:details?.is_mail_trigger ? true : false,
            renewal:details?.renewal ?? false,
            allow_extension:details?.allow_extension ?? false,
            project_id: details?.project?.id ? {
                ...details?.project,
                value: details?.project?.id,
                label: `${details?.project?.title} - ${details?.project?.project_no}`
            } : "",
            sla_account_id:details?.sla_account?.id ? {
                label:`${details?.sla_account?.name} - ${details?.sla_account?.account_no}`,
                value:details?.sla_account?.id,
                address:details?.sla_account?.address,
            } :""
        })
    }, [details])
    const sections = {
        1: <CustomerInformation t={t} details={details} />,
        2: <OppertunityInformation t={t} details={details?.quotation?.lead} />,
        3: <QuotationInformation t={t} details={details?.quotation} />,
        4: <ReservationInfo t={t} details={details?.quotation?.reservation?.[0]} />,
        5: <AgreementInformation t={t} details={details} reload={reload} updateEmails={updateEmails} data={data} updateState={updateState} setIsValid={setIsValid} isValid={isValid} id={id}/>,
        6: <BillingInformation t={t} reload={reload} id={id} details={details} agree={details} />,
        7: <RenewalInformation t={t} reload={reload} details={details?.quotation} />,
        8: <RevenueSummery t={t} details={details} />,
        9: <QuotationPreview t={t} details={details?.quotation} />,
        10: <AgreementDocument t={t} details={details} />,
        11: <ConfirmOrdersInformation t={t} details={details} />,
        12: <TermsAndCondition t={t} settings={details} onSubmit={onSubmit} />,
        13: <WalletCredits t={t} details={details} type={"wallet"} />,
        14: <WalletCredits t={t} details={details} type={"wallet_item"} />,
        15: <WalletCredits t={t} details={details} type={"late_fee"} />,
        16: <WalletCredits t={t} details={details} type={"commission_bonus"} />,
        17: <FinanceInformation t={t} details={details} reload={reload} updateEmails={updateEmails} data={data} updateState={updateState} />
    }

    return (
        <Box className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={2.5}>
                    <Box className={classes.box}>
                        {/*tab list */}
                        <Box pt={"4px"} >
                            <SettingList searchBool={false} list={list?.filter((x) => x?.is_active === true)} selected={selected?.value} setSelected={setSelected} />
                        </Box>
                    </Box >

                </Grid >
                <Grid item xs={9.5}>
                    <Box className={classes.box}>
                        {/*sub header */}
                        <Subheader title={selected?.label} hideBackButton={true} isEdit={selected?.isEdit} handleEdit={onCreate} text={t("Save")} disabled={isValid}/>
                        <Box className={classes.box1}>
                            {sections[selected?.value]}
                        </Box>
                    </Box>
                </Grid>

            </Grid >

        </Box >
    )
}
export default withTranslation("agreement")(AgreementPreview); 