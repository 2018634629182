
import { Routes } from "./routes";
import { matchPath } from 'react-router-dom';

/**
 * The below function define redirection for a particular route based on the role
 */
export const Redirections = (userRole) => {
  switch (userRole) {
    default:
      return "";
  }
};

export const LoginSuccess = (routesList) => {
  if (routesList?.length > 0) {
    let path = Routes?.[routesList?.[0]]
    return path
  }
};

export const Access = (userRole, path, routes) => {
  let routeList = Routes;
  // Loop through routes array once and check for match directly
  for (let i = 0; i < routes?.length; i++) {
    const routePath = routeList?.[routes[i]];
    if (routePath && matchPath(routePath, path)) {
      return i;
    }
  }
  return -1; // Return -1 if no match is found
};
