import { Box, Grid } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FilterGenerator, Subheader, UseDebounce } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import {
  accessCheckRender,
  AlertProps,
  getCompanyOption,
  getRoutePermission,
  NetWorkCallMethods,
} from "../../utils";
import {
  AddQuestions,
  PaymentPeriodList,
  RevenueTypeList,
  ValueBasisList1,
} from "../../utils/pricingComponent";
import { Status, TabelComponents } from "./components";
import { useStyles } from "./style";
import { withTranslation } from "react-i18next";

const RentalIndex = ({ t }) => {
  // useRef
  const permission = useRef([]);
  //   context
  const auth = useContext(AuthContext);
  const alert = useContext(AlertContext);
  const backdrop = useContext(BackdropContext);
  //debounce hook
  const debounce = UseDebounce();
  // styles
  const classes = useStyles();
  // apolo clint
  // ----------------------------------------STATES---------------------------------------------------
  const [company, setCompany] = useState({
    companyList: [],
    SelectedCompany: {},
    searchText: "",
    drawer: false,
  });
  const [filterData, setFilterDate] = useState({
    Status: [true],
    unit: [],
  });
  const [list, setList] = useState({
    data: [],
    count: 0,
    unitList: [],
  });
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
    page: 1,
    id: "",
  });
  const [details, setDetails] = useState({
    open: false,
    data: {},
  });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [reload, setReload] = useState(false);
  // --------------------------------FUNCTIONS--------------------------------------------------------
  //   initial company update
  const updateState = (k, v) => {
    setCompany({ ...company, [k]: v });
  };
  // update pagination
  const updatePagination = (k, v) => {
    setPagination({ ...pagination, [k]: v });
  };
  //   get permission function
  const getPermission = async () => {
    const tempPermission = await getRoutePermission();
    permission.current = tempPermission;
    if (tempPermission?.read) {
      let company = getCompanyOption(backdrop, auth, alert);
      if (company) {
        getList(
          0,
          10,
          "",
          company?.selected,
          "desc",
          filterData?.Status,
          filterData?.unit
        );
        setCompany({
          ...company,
          companyList: company?.list,
          SelectedCompany: company?.selected,
        });
      }
    }
  };
  //   company selection
  const companySelect = (e) => {
    getList(
      0,
      10,
      company?.searchText ?? "",
      e,
      "desc",
      filterData?.Status,
      []
    );
    updateState("SelectedCompany", e);
    setFilterDate({
      ...filterData,
      unit: [],
    });
  };
  //   Search function
  const searchFunction = (e) => {
    updateState("searchText", e);
    debounce(
      () =>
        getList(
          pagination?.offset,
          pagination?.limit,
          e,
          company?.SelectedCompany,
          "desc",
          filterData?.Status,
          filterData?.unit
        ),
      800
    );
  };
  // onApplyFilter
  const onApplyFilter = (value) => {
    setFilterDate(value);
    getList(
      pagination?.offset,
      pagination?.limit,
      "",
      company?.SelectedCompany,
      "desc",
      value?.Status,
      value?.unit
    );
  };
  // get appraisal list
  const getList = (
    offset,
    limit,
    search,
    company,
    order_by,
    is_active,
    unit_id
  ) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading...",
    });
    const payload = {
      offset: offset,
      limit: limit,
      search: search,
      company_id: company?.value,
      order_by: order_by,
      is_active: is_active ?? true,
      unit_id: unit_id?.length > 0 ? unit_id?.map((x) => x?.value) : undefined,
    };
    NetworkCall(
      `${config.api_url}/rental_index/get_all`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let result = response?.data?.data?.map((x) => {
          return {
            PropertyName: x?.property_name,
            Location: `${x?.area} , ${x?.city}`,
            UnitNumber: x?.unit_no,
            UnitType: x?.unit_type,
            UnitPurpose: x?.unit_purpose,
            Revenuetype: x?.revenue_type,
            Bedrooms: x?.total_bed_rooms,
            Status: "Status",
            id: x?.id,
            pricing_table_id: x?.pricing_table_id,
          };
        });
        let unitlist = response?.data?.unitData?.map((x) => {
          return {
            value: x?.unit_id ?? "",
            label: x?.unit_name ?? "",
          };
        });
        setList({
          data: result,
          count: response?.data?.count,
          unitList: unitlist ?? [],
        });
        updatePagination("id", result?.[0]?.pricing_table_id);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "Loading...",
        });
      })
      .catch((error) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "Loading...",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Internal error. Please try again later.",
        });
      });
  };
  // pagination
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getList(
      offset,
      limit,
      "",
      company?.SelectedCompany,
      "desc",
      filterData?.Status,
      filterData?.unit
    );
  };
  // pagination limit change function
  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getList(
      0,
      value,
      "",
      company?.SelectedCompany,
      "desc",
      filterData?.Status,
      filterData?.unit
    );
  };
  const handleIcon = (type, data) => {
    if (type === "info") {
      getData(data?.pricing_table_id);
    } else {
      updatePagination("id", data?.pricing_table_id);
    }
  };
  // get details
  const getData = (v) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
      const payload={
        rera_id: v ?? "",
      }
      NetworkCall(
        `${config.api_url}/queries/pricing_table/by_id`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
      .then((res) => {
        const data = {
          data: res?.data?.data?.pricing_table?.[0],
          reraValue: res?.data?.data?.pricing_table?.[0]?.rera_pricing?.[0],
        };
        let category = data?.data?.is_onetime
          ? AddQuestions?.[2]?.category
          : data?.data?.refundable
            ? AddQuestions?.[3]?.category
            : data?.data?.is_quantity
              ? AddQuestions?.[4]?.category
              : data?.data?.is_parking
                ? AddQuestions?.[5]?.category
                : data?.data?.primary
                  ? AddQuestions?.[0]?.category
                  : AddQuestions?.[1]?.category;

        let info = data?.data?.is_onetime
          ? AddQuestions?.[2]?.info
          : data?.data?.refundable
            ? AddQuestions?.[3]?.info
            : data?.data?.is_quantity
              ? AddQuestions?.[4]?.info
              : data?.data?.is_parking
                ? AddQuestions?.[5]?.info
                : data?.data?.primary
                  ? AddQuestions?.[0]?.info
                  : AddQuestions?.[1]?.info;

        let primaryColor = data?.data?.is_onetime
          ? AddQuestions?.[2]?.primaryColor
          : data?.data?.refundable
            ? AddQuestions?.[3]?.primaryColor
            : data?.data?.is_quantity
              ? AddQuestions?.[4]?.primaryColor
              : data?.data?.is_parking
                ? AddQuestions?.[5]?.primaryColor
                : data?.data?.primary
                  ? AddQuestions?.[0]?.primaryColor
                  : AddQuestions?.[1]?.primaryColor;

        let secondaryColor = data?.data?.is_onetime
          ? AddQuestions?.[2]?.secondaryColor
          : data?.data?.refundable
            ? AddQuestions?.[3]?.secondaryColor
            : data?.data?.is_quantity
              ? AddQuestions?.[4]?.secondaryColor
              : data?.data?.is_parking
                ? AddQuestions?.[5]?.secondaryColor
                : data?.data?.primary
                  ? AddQuestions?.[0]?.secondaryColor
                  : AddQuestions?.[1]?.secondaryColor;

        let isChargeable = data?.data?.is_onetime
          ? true
          : data?.data?.is_parking
            ? true
            : data?.data?.primary &&
              data?.data?.is_onetime &&
              data?.data?.refundable &&
              data?.data?.is_quantity &&
              data?.data?.is_parking
              ? true
              : false;
        const tempAddNewState = {
          category: category,
          info: info,
          primaryColor: primaryColor,
          secondaryColor: secondaryColor,
          id: data?.data?.id ?? "",
          revenueType: data?.data?.revenue_type
            ? {
              value: data?.data?.revenue_type,
              label: data?.data?.revenue_type,
            }
            : RevenueTypeList[0],
          pricingComponent: data?.data?.rental_breakupByID ?? "",
          taxGroup: data?.data?.vat_groupByID ?? "",
          chargeable: {
            value: data?.data?.is_chargeable,
            label: data?.data?.is_chargeable ? "Yes" : "No",
          },
          valueBasis: data?.data?.value_basis_type
            ? {
              value: data?.data?.value_basis_type,
              label:
                data?.data?.value_basis_type === "Amount"
                  ? "Fixed Amount"
                  : data?.data?.value_basis_type === "Percentage"
                    ? "Percentage"
                    : data?.data?.value_basis_type === "Per UOM"
                      ? "UOM"
                      : data?.data?.value_basis_type === "Rental Value"
                        ? "Rental Value"
                        : data?.data?.value_basis_type,
            }
            : ValueBasisList1[0],
          value: data?.data?.value ?? "",
          currency: data?.data?.currencyByID ?? "",
          currencyCode: data?.data?.currencyByID?.code ?? "",
          uom: data?.data?.uom ?? "",
          paymentPeriod: data?.data?.payment_period
            ? {
              value: data?.data?.payment_period,
              label: data?.data?.payment_period,
            }
            : PaymentPeriodList[0],
          quantity: data?.data?.quantity ?? "",
          companyID: data?.data?.company_id ?? "",
          propertyID: data?.data?.property_id ?? "",
          unitID: data?.data?.unit_id ?? "",
          countryID: data?.data?.companyByID?.country_id ?? "",
          categoryQuestionSet: {
            isPrimary: data?.data?.primary ?? false,
            isOneTime: data?.data?.is_onetime ?? false,
            isRefundable: data?.data?.refundable ?? false,
            isQuantity: data?.data?.quantity ?? false,
            isParking: data?.data?.is_parking ?? false,
          },
          isChargeable: isChargeable,
          maxValue: data?.reraValue?.max ?? "",
          minValue: data?.reraValue?.min ?? "",
          recommanded: data?.reraValue?.recommended_value ?? "",
          reraID: data?.reraValue?.id ?? "",
          total_area: data?.data?.unit?.total_area ?? "",
          is_read: data?.reraValue?.id?.length > 0 ? true : false,
          error: {
            pricingComponent: "",
            taxGroup: "",
            value: "",
            quantity: "",
            maxValue: "",
            minValue: "",
            recommanded: "",
          },
        };
        setDetails({
          data: tempAddNewState,
          open: true,
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "Loading",
        });
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "Loading",
        });
      });
  };
  // closemodule
  const closeModule = () => {
    setDetails({
      ...details,
      open: false,
    });
    setReload(true);
  };
  // ascending order
  const ascendingOrder = (e) => {
    getList(
      0,
      10,
      "",
      company?.selected,
      e,
      filterData?.Status,
      filterData?.unit
    );
  };
  const getListFunction = () => {
    getList(0, 10, "", company?.selected, "desc", filterData?.Status, []);
  };
  useEffect(() => {
    getPermission();
    // eslint-disable-next-line
  }, [auth]);
  // render screen
  const render = () => {
    return (
      <>
        {/* sub Navbar */}
        <Subheader
          title={`${t("rentalIndex")} (${list?.count ?? 0})`}
          select
          hideBackButton={true}
          options={company?.companyList}
          value={company?.SelectedCompany}
          placeholder={t("Search")}
          onchange={(e) => {
            companySelect(e);
          }}
        />
        {/* body */}
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={8.5}>
              <TabelComponents
                company={company}
                searchFunction={searchFunction}
                permission={permission?.current}
                t={t}
                openDrawer={() => updateState("drawer", true)}
                list={list}
                handlePagination={handlePagination}
                handleChangeLimit={handleChangeLimit}
                pagination={pagination}
                handleIcon={handleIcon}
                open={details?.open}
                details={details?.data}
                closePopup={closeModule}
                ascendingOrder={ascendingOrder}
                page={page}
                limit={limit}
                reload={reload}
                getListFunction={getListFunction}

              />
            </Grid>
            <Grid item xs={3.5} className={classes.paddingLeft}>
              <Box className={classes.borderLeft}>
                <Status
                  t={t}
                  id={pagination?.id}
                  company={company}
                  reload={reload}
                />
              </Box>
            </Grid>
          </Grid>
        </div>

        {/* filter */}
        {company?.drawer && (
          <FilterGenerator
            open={company?.drawer}
            onClose={() => updateState("drawer", false)}
            components={[
              {
                component: "toggleButton",
                value: filterData?.Status,
                options: [
                  { label: t("Active"), value: true },
                  { label: t("Inactive"), value: false },
                ],
                isMulti: true,
                state_name: "Status",
                label: t("Status"),
              },
              {
                component: "select",
                value: filterData?.unit,
                options: list?.unitList ?? [],
                isMulti: true,
                label: t("Units"),
                state_name: "unit",
              },
            ]}
            onApply={(value) => onApplyFilter(value)}
          />
        )}
      </>
    );
  };

  return <>{accessCheckRender(render, permission?.current)}</>;
};
export default withTranslation("rentalIndex")(RentalIndex);
