import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../utils";
export const useStyles = makeStyles((theme) => ({
    viewbox: {
        // backgroundColor: "#27AFCF",
        padding: "10px 10px 6px 10px",
        borderRadius: theme.palette.borderRadius
    },
    viewTitle: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
        marginInlineStart: "8px"
    },
    borderBox: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius

    },
    headingact: {
        fontSize:"0.625rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.tertiary,
        textTransform: "uppercase"
    },
    dot: {
        width: "5px",
        height: "5px",
        backgroundColor: "#E4E8EE",
        borderRadius: "50%",
        marginInline: "4px"
    },
    closebtn: {
        "&:hover": {
            backgroundColor: "white"
        },
    },
    name: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary,
    },
    mobileNo: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.secondary,
       
    },
    mobileNo1: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.secondary,
        direction: 'ltr'
    },
    mobileNortl: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.secondary,
        direction: 'ltr',
        textAlign: "end"
    },
    layoutSwitch: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px"
    },
    img: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "13px",
    },
    cardblock: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #E4E8EE",
        padding: "10px"
    },
    selectedBtn: {
        width: "32px",
        background: theme.palette.primary.main,
        borderRadius: theme.palette.borderRadius,
        color: "#fff",
        height: "32px",
        margin: "2px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    notSelectedBtn: {
        width: "32px",
        borderRadius: theme.palette.borderRadius,
        color: theme.typography.color.tertiary,
        height: "32px",
        margin: "2px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    categoryText: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    togglebtntype: {
        border: "1px solid #E4E8EE",
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().semiBold,
        borderRadius: "20px",
        display: "flex",
        alignItems: "center",
        padding: "10px",
        cursor: "pointer",
        marginInlineStart: "8px"
    },
    selectedtoggle: {
        fontSize:"0.75rem",
        color: "#FFFFFF",
        fontFamily: FontFamilySwitch().semiBold,
        borderRadius: "20px",
        display: "flex",
        alignItems: "center",
        padding: "10px",
        backgroundColor: "#5078E1",
        cursor: "pointer",
        marginInlineStart: "8px"
    },
    content: {
        height: 'calc(100vh - 370px)',
        overflow: "auto"
    },
    cancelBtn: {
        "&:hover": {
            background: "transparent"
        }
    },
    iconButton: {
        borderRadius: "4px",
        border: `1px solid ${theme.palette.border.primary}`,
        padding: "12px"
    },
    verticalborder:{
        borderRight:"1px solid #CED3DD",
        height:"24px",
        width:"1px"
    }
}))
export const MonthlyCalendarStyles = makeStyles((theme) => ({
    // calendar dates styles
    event_label: {
        padding: 2,
        fontSize:"0.75rem",
        fontWeight: "bold",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
    calDates: {
        textAlign: "center",
        overflow: "overlay",
        height: `calc(100vh - 280px)`,

    },
    date: {
        color: theme.typography.color.secondary,
        fontSize:"1.125rem",
        fontWeight: "bold"
    },
    date_outline: {
        color: "black",
        border: `1px solid ${theme.palette.border.secondary}`,
        height: "145px"
    },
    hidden_other_month_dates: {
        visibility: "hidden"
    },
    eventlabel: {
        overflow: "hidden",
        width: "98%",
    },
    eventtxt: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        fontSize:"0.75rem",
        // fontFamily: `${SemiBold}`,
        color: "#6DAFB3",
        width: "150px"
    },
    moreLabel: {
        float: "left",
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.palette.info.main,
        cursor: "pointer"
    },
    days: {
        fontSize:"0.75rem",
        textTransform: "uppercase",
        color: theme.typography.color.tertiary,
        // fontFamily: `${SemiBold}`,
        textAlign: "center"
    },
    iconButton: {
        borderRadius: "4px",
        border: `1px solid ${theme.palette.border.primary}`,
        padding: "12px"
    },
}))
export const weeklyCalendarStyle = makeStyles((theme) => ({
    table_container: {
        maxHeight: `calc(100vh - 237px)`,
    },
    cells: {
        // display: "flex",
        textAlign: "center",
        height: "auto",
        border: `1px solid ${theme.palette.border.secondary}`,
        alignItems: "center",
        padding: "34px",
        maxWidth: "100px"
    },
    content_cells: {
        // display: "flex",
        // height: "auto",
        border: `1px solid ${theme.palette.border.secondary}`,
        padding: 6,
        minWidth: "100px",
        maxWidth: "100px"
    },
    hovercells: {
        display: "flex",
        justifyContent: "center",
        height: 100,
        border: `1px solid ${theme.palette.border.secondary}`,
    },
    dayLabel: {
        fontSize:"0.875rem",
        textTransform: "uppercase",
        color: theme.typography.color.tertiary,
        // fontFamily: `${SemiBold}`,

    },
    dateLabel: {
        fontSize:"1rem",
        fontWeight: "bold",
        textTransform: "uppercase",
        color: theme.typography.color.secondary
    },
    listTitle: {
        color: theme.typography.color.tertiary,
        fontSize: 26,
    },
    calender: {
        zIndex: "0"
    },
    calenderRows: {
        height: `calc(100vh - 320px)`,
        overflow: "auto",
        width: "100%",
    },
    cell_hover: {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.info.main,
        height: 80,
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        cursor: "pointer"
    },
    header_timecell: {
        height: 40,
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        textTransform: "uppercase",
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    timeCell: {
        display: "flex",
        justifyContent: "center",
        height: 100,
        alignItems: 'end',
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
    },
    header_eventCell: {
        height: 40,
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "left",
        alignItems: "center"
    },
    eventCell: {
        height: 100,
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%"
    },
    event_title: {
        padding: 2,
        fontSize:"0.75rem",
        fontWeight: "bold",
    },
    event_label: {
        padding: 2,
        fontSize:"0.75rem",
        fontWeight: "bold",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        // width: "120px"

    },
    dot: {
        height: 5,
        width: 5,
        borderRadius: "50%",
        color: "#E4E8EE"
    },
    calRow: {
        height: "calc(100vh - 250px)",
        overflow: "auto",
        width: "100%",
    },
    moreLabel: {
        fontSize:"0.75rem",
        color: theme.palette.info.main,
        fontWeight: "bold",
        marginLeft: 8,
        cursor: "pointer"
    },
    customTooltip: {
        // '& .MuiTooltip-arrow':{
        //     backgroundColor:"#fff",
        //     color:"#fff",
        //     border: '1px solid #dadde9'
        // }
    },
    ttavatar: {
        height: 24,
        width: 24,
    },
    ttTitle: {
        fontSize:"1.125rem",
        fontWeight: "bold",
        color: theme.typography.color.primary,
        width:"200px",
        textOverflow:"ellipsis",
        whiteSpace:"nowrap",
        overflow:"hidden"
    },
    ttsecTitle: {
        fontSize:"0.875rem",
        color: theme.typography.color.secondary,
    },
    ttassigned_by: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary
    },
    ttname: {
        fontSize:"1rem",
        color: theme.typography.color.secondary,
        fontWeight: "bold"
    },
    iconButton: {
        borderRadius: "4px",
        border: `1px solid ${theme.palette.border.primary}`,
        padding: "12px"
    },
}))
export const eventListStyle = makeStyles((theme) => ({
    header_timecell: {
        height: 40,
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        textTransform: "uppercase",
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    timeCell: {
        display: "flex",
        justifyContent: "center",
        height: 100,
        alignItems: 'end',
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
    },
    header_eventCell: {
        height: 40,
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "left",
        alignItems: "center"
    },
    eventCell: {
        height: 100,
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%"
    },
    event_title: {
        padding: 2,
        fontSize:"0.75rem",
        fontWeight: "bold",
    },
    event_label: {
        padding: 2,
        fontSize:"0.75rem",
        fontWeight: "bold",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        // width: "150px",
        marginInlineStart: "8px"
    },
    dot: {
        height: 5,
        width: 5,
        borderRadius: "50%",
    },
    calRow: {
        height: "calc(100vh - 250px)",
        overflow: "auto",
        width: "100%",
    },
    moreLabel: {
        fontSize:"0.75rem",
        color: theme.palette.info.main,
        fontWeight: "bold",
        cursor: "pointer",
        marginTop:"5px"

    },
    customTooltip: {
        // '& .MuiTooltip-arrow':{
        //     backgroundColor:"#fff",
        //     color:"#fff",
        //     border: '1px solid #dadde9'
        // }
    },
    ttavatar: {
        height: 24,
        width: 24,
    },
    ttTitle: {
        fontSize:"1rem",
        fontWeight: "bold",
        color: theme.typography.color.primary,
        marginInlineStart: "8px",
        width:"200px",
        textOverflow:"ellipsis",
        whiteSpace:"nowrap",
        overflow:"hidden"
    },
    ttsecTitle: {
        fontSize:"0.875rem",
        color: theme.typography.color.secondary,
    },
    ttassigned_by: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        marginTop:"5px"
    },
    ttname: {
        fontSize:"1rem",
        color: theme.typography.color.secondary,
        fontWeight: "bold"
    },
    announceImg:{
        backgroundColor:"#DBF3FF",
        padding:"5px",
        borderRadius:"4px"
    }
}))
export const DailyCalendarStyle = makeStyles((theme) => ({
    header_timecell: {
        height: 40,
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        textTransform: "uppercase",
        borderLeft: `0.5px solid ${theme.palette.border.secondary}`,
        borderBottom: `0.5px solid ${theme.palette.border.secondary}`,
        borderTop: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    timeCell: {
        display: "flex",
        justifyContent: "center",
        height: 130,
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        borderLeft: `0.5px solid ${theme.palette.border.secondary}`,
        borderBottom: `0.5px solid ${theme.palette.border.secondary}`,

    },
    header_eventCell: {
        height: 40,
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "left",
        alignItems: "center"
    },
    eventCell: {
        height: 130,
        borderLeft: `1px solid ${theme.palette.border.secondary}`,
        borderRight: `1px solid ${theme.palette.border.secondary}`,
        borderBottom: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        //overflow: "auto"
    },
    event_label: {
        padding: 2,
        fontSize:"0.75rem",
        fontWeight: "bold",
        textTransform: "capitalize"
    },
    dot: {
        height: 5,
        width: 5,
        borderRadius: "50%",
    },
    calRow: {
        height: "calc(100vh - 200px)",
        overflow: "auto",
        width: "100%",

    },
    moreLabel: {
        fontSize:"0.75rem",
        color: theme.palette.info.main,
        fontWeight: "bold",
        marginLeft: 8,
        cursor: "pointer"
    },
    customTooltip: {
        // '& .MuiTooltip-arrow':{
        //     backgroundColor:"#fff",
        //     color:"#fff",
        //     border: '1px solid #dadde9'
        // }
        '& .MuiTooltip-arrow': {
            background: 'transparent',
        },
        "&:before": {
            border: "1px solid #E6E8ED"
        },

    },
    ttavatar: {
        height: 24,
        width: 24,
    },
    ttTitle: {
        fontSize:"0.875rem",
        fontWeight: "bold",
        color: theme.typography.color.primary,
        textTransform: "capitalize",
        width:"200px",
        textOverflow:"ellipsis",
        whiteSpace:"nowrap",
        overflow:"hidden"
    },
    ttsecTitle: {
        fontSize:"0.75rem",
        textTransform: "capitalize",
        color: theme.typography.color.secondary,
    },
    ttassigned_by: {
        fontSize:"0.625rem",
        color: theme.typography.color.tertiary
    },
    ttname: {
        fontSize:"1rem",
        color: theme.typography.color.secondary,
        fontWeight: "bold"
    },
    arrow: {
        "&:before": {
            border: "1px solid #E6E8ED"
        },
        color: "white",
        position: "absolute",
        top: "0px",
        left: "0px",
        fontSize: "40px"
    },
    toolRoot: {
        border: '1px solid #E4E8EE',
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        borderRadius: "4px",

    },
    contentbox: {
        position: "relative",
        borderRadius: theme.palette.borderRadius
    },
    rowOverflow: {
        height: "85px",
        width: "100%",
        // position:"relative",
        overflow: "auto",
        top: "0",
        left: "0",
        right: "0"
    },
    closebtn: {
        "&:hover": {
            backgroundColor: "white"
        },
    },
    name: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary,
    },
    mobileNo: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.secondary,
        direction: 'ltr',
        maxWidth:"200px",
        textOverflow:"ellipsis",
        overflow:"hidden",
        whiteSpace:"nowrap"
    },
    statushdr: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: "white",
    },
    statusbox: {
        backgroundColor: "#5AC782",
        padding: "5px",
        width: "50px",
        borderRadius: theme.palette.borderRadius,
        textAlign: "center"
    },
    overflow: {
        height: "100%",
        overflow: "auto"
    }
}))

export const LeadCardStyle = makeStyles((theme) => ({
    title: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().regular,
    },
    subTitle: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().regular,
        color: theme.typography.color.tertiary
    },
    avatar: {
        height: 35,
        width: 35,
        borderRadius: 4
    },
    heading: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.tertiary
    },
    borderBox:{
        border:"1px solid #E4E8EE",
        borderRadius:4,
        padding:"8px",
    }
}))