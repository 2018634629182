import BlockBasedIcon from "./utils/blockBased"
import ActiveProjectIcon from "./utils/activeProjectIcon"
import PropertyBasedIcon from "./utils/propertyBased"
import TaskBasedIcon from "./utils/taskBased"
import InactiveContractIcon from "./utils/inactiveContractIcon"
import InactiveInternalProjectIcon from "./utils/inactiveInternalProjectIcon"
import ActiveContractIcon from "./utils/activeContractIcon"
import InactiveProjectIcon from "./utils/inactiveProjectIcon"
import ActiveInternalProjectIcon from "./utils/activeInternalProjectIcon"
import { t } from "i18next"

export const ProjectTableHeading = (t) => {
    const Heading = [

        { title: t("Project ID"), field: "project_id" },
        { title: t("Project Type"), field: "project_type" },
        { title: t("Project Title"), field: "project_title" },
        { title: t("Parent relation"), field: "project_relation" },
        { title: t("Start Date"), field: "start_date" },
        { title: t("End Date"), field: "end_date" },
        { title: t("Planned Hrs"), field: "planned_hrs" },
        { title: t("Actual Hrs"), field: "actual_hrs" },
        { title: t("Project Owner"), field: "project_owner" },
        { title: t("Status"), field: "status" },
        { title: "", field: "icon" },
    ]
    return Heading
}


export const ProjectTablePath = [
    'project_id', 'project_type', 'project_title', 'project_relation', 'start_date',
    'end_date', 'planned_hrs', 'actual_hrs', 'project_owner', 'status'
]



export const ProjectTableRow = [
    {
        account_ID: "CST123521",
        type: "Invoice type here",
        amount: "2,000 USD",
        created_by: "Bala Ganesh, 01 Jan 2022",
        reason: "Reason will be displayed here",
    },
    {
        account_ID: "CST123521",
        type: "Invoice type here",
        amount: "2,000 USD",
        created_by: "Bala Ganesh, 01 Jan 2022",
        reason: "Reason will be displayed here",
    },
]

export const CreateMenuList = (data) => {
    const menuData = {
        0: {
            icon: <PropertyBasedIcon />,
            subTitle: "Create project, task and track against property"
        },
        1: {
            icon: <BlockBasedIcon />,
            subTitle: "Create block and track against project"
        },
        2: {
            icon: <TaskBasedIcon />,
            subTitle: "Create task and track against project"
        }
    }
    const result = data?.map((e, i) => {
        return {
            ...menuData?.[i],
            title: e?.label,
            value: e?.value
        }
    })
    return result
}


export const Step = (t = () => false) => {
    return [
        {
            step: 1,
            title: t("Project Details")
        },
        {
            step: 2,
            title: t("Project Owner, Resource Group")
        },
        {
            step: 3,
            title: t("Overview")
        }
    ]
}

export const TypeSelectList = (data) => {
    const listData = {
        0: {
            activeIcon: <ActiveProjectIcon />,
            inactiveIcon: <InactiveProjectIcon />,
            subTitle: t("Associate With Parent Project"),
            id: 1,
            value: "project"
        },
        1: {
            activeIcon: <ActiveContractIcon />,
            inactiveIcon: <InactiveContractIcon />,
            subTitle: t("Associate With Contract Account"),
            id: 2,
            value: "contract"
        },
        2: {
            activeIcon: <ActiveInternalProjectIcon />,
            inactiveIcon: <InactiveInternalProjectIcon />,
            subTitle: t("For Internal standalone projects"),
            id: 3,
            value: "internal"
        }
    }
    const result = data?.map((e, i) => {
        return {
            title: e?.label,
            ...listData[i],
            value: e?.value
        }
    })
    return result
}


export const viewTabList = (t = () => false, type = "") => {
    return (
        [
            {
                label: t("Members"),
                id: 1,
                isActive: true
            },
            {
                label: t("Property"),
                id: 2,
                isActive: true
            },
            {
                label: t("Block"),
                id: 3,
                isActive: type !== "Property"
            },
            {
                label: t("Unit"),
                id: 4,
                isActive: true
            },
            {
                label: t("Specification"),
                id: 5,
                isActive: true
            },
            {
                label: t("Projected BOM"),
                id: 6,
                isActive: true
            },
            {
                label: t("Project Progress"),
                id: 7,
                isActive: true
            },
            {
                label: t("Service Request"),
                id: 8,
                isActive: true
            },
            {
                label: t("Job"),
                id: 9,
                isActive: true
            },
            {
                label: t("Actual BOM"),
                id: 10,
                isActive: true
            },
            // {
            //     label: "Item Request",
            //     id: 11
            // },
            {
                label: t("Invoice"),
                id: 11,
                isActive: true
            },
            // {
            //     label: "Purchase Order",
            //     id: 13
            // },
        ]
    )
}

export const buttonName = (t = () => false) => {
    return {
        0: t("Add Member"),
        1: t("Add Property"),
        2: t("Add Block"),
        3: t("Add Unit"),
        4: t("Add Specification"),
        5: t("Add BOM"),
        6: t("Update Progress"),
    }
}
export const addFormButtom = (t = () => false) => {
    return {
        0: t("Add"),
        1: t("Add Property"),
        2: t("Add Block"),
        3: t("Add Unit"),
        4: t("Add Specification"),
        5: t("Add BOM"),
        6: t("Update Progress"),
    }
}


export const MemberTableHeading = (t = () => false) => {
    const Heading = [
        { title: "", field: "card" },
        { title: t("Follower"), field: "follower", isBackCheckbox: false, type: "follower" },
        { title: t("Member"), field: "member", isBackCheckbox: false, type: "member" },
        { title: "", field: "icon" }
    ]
    return Heading
}

// start balaji
export const PropertyTableHeader = (t = () => false) => {
    const heading = [
        { title: t("Property Name"), field: "property_name" },
        { title: t("Location"), field: "location" },
        { title: "", field: "icon" }
    ]
    return heading
}

export const PropertyTablePath = ["property_name", "location"]

export const PropertyTableDataType = [
    { type: ["avatarText"], name: "property_name" },
    { type: ["text"], name: "location" },
    { type: ["deleteIcon"], name: "icon" }
]


export const BlockTableHeader = (t = () => false) => {
    const heading = [
        { title: t("Block Name"), field: "block_name" },
        { title: t("Property Name"), field: "property_name" },
        { title: t("Location"), field: "location" },
        { title: "", field: "icon" }
    ]
    return heading
}

export const BlockTablePath = ["block_name", "property_name", "location"]

export const BlockTableDataType = [
    { type: ["avatarText"], name: "block_name" },
    { type: ["text"], name: "property_name" },
    { type: ["text"], name: "location" },
    { type: ["deleteIcon"], name: "icon" }
]


export const UnitTableHeader = (t = () => false) => {
    const heading = [
        { title: t("Unit Name"), field: "unit_name" },
        { title: t("Property Name"), field: "property_name" },
        { title: t("Location"), field: "location" },
        { title: "", field: "icon" }
    ]
    return heading
}

export const UnitTablePath = ["unit_name", "property_name", "location"]

export const UnitTableDataType = [
    { type: ["avatarText"], name: "unit_name" },
    { type: ["text"], name: "property_name" },
    { type: ["text"], name: "location" },
    { type: ["deleteIcon"], name: "icon" }
]

export const SpecificationTableHeader = (t = () => false) => {
    const heading = [
        { title: t("Item Type"), field: "itemType" },
        { title: t("Item Category"), field: "itemCategory" },
        { title: t("Sub Category"), field: "subCategory" },
        // { title: t("Item"), field: "itemMaster" },
        { title: t("Specification Title"), field: "specificationTitle" },
        { title: "", field: "icon" }
    ]
    return heading
}

export const SpecificationTablePath = ["itemType", "itemCategory", "subCategory", "specificationTitle"]

export const SpecificationTableDataType = [
    { type: ["text"], name: "itemType" },
    { type: ["text"], name: "itemCategory" },
    { type: ["text"], name: "subCategory" },
    // { type: ["text"], name: "itemMaster" },
    { type: ["quillView"], name: "specificationTitle" },
    { type: ["more_contract"], name: "icon" }
]


export const ProjectedBOMTableHeader = (t = () => false) => {
    const heading = [
        { title: t("Reference No"), field: "ref_no" },
        { title: t("Item Type"), field: "item" },
        { title: t("Item Category"), field: "itemCategory" },
        { title: t("Sub Category"), field: "subCategory" },
        { title: t("Item"), field: "itemMaster" },
        { title: t("value"), field: "value_text" },
        { title: t("Qty"), field: "qty" },
        { title: t("Total Amount"), field: "total_amount" },
        { title: "", field: "icon" }
    ]
    return heading
}

export const ProjectedBOMTablePath = ["ref_no", "item", "itemCategory", "subCategory", "itemMaster", "value_text", "qty", "total_amount"]

export const ProjectedBOMTableDataType = [
    { type: ["text"], name: "ref_no" },
    { type: ["text"], name: "item" },
    { type: ["text"], name: "itemCategory" },
    { type: ["text"], name: "subCategory" },
    { type: ["text"], name: "itemMaster" },
    { type: ["text"], name: "value_text" },
    { type: ["text"], name: "qty" },
    { type: ["text"], name: "total_amount" },
    { type: ["more_contract"], name: "icon" }
]


export const ProgressFormTableHeader = (t = () => false) => {
    const heading = [
        { title: t("Progress Title"), field: "progress_title" },
        { title: t("Progress Date"), field: "progress_date" },
        { title: t("Updated On"), field: "updated_on" },
        { title: t("Attachment"), field: "attachment" },
        { title: "", field: "icon" }
    ]
    return heading
}

export const ProgressFormTablePath = ["progress_title", "progress_date", "updated_on", "attachment"]

export const ProgressFormTableDataType = [
    { type: ["text"], name: "progress_title" },
    { type: ["date"], name: "progress_date" },
    { type: ["date"], name: "updated_on" },
    { type: ["text"], name: "attachment" },
    { type: ["more_all"], name: "icon" }
]





export const MaintanceReqTableHeading = (t = () => false) => [
    { title: t("Status"), field: 'status' },
    { title: t("Request ID"), field: "request_no" },
    { title: t("Description"), field: "requestName" },
    { title: t("Property Name"), field: "property_name" },
    { title: t("Unit Name"), field: "unit" },
    { title: t("Asset Name"), field: "asset" },
    { title: t("Category"), field: 'category' },
    { title: t("Subcategory"), field: 'sub_category' },
    { title: t("Contact Name"), field: 'contact_name' },
    { title: t("Contact Number"), field: 'contact_number' },
    { title: t("Priority"), field: 'priority' },
    { title: t("Location"), field: 'location' },
    { title: t("Source"), field: 'build_source' },
    { title: t("Jobs"), field: 'job_count' },
    { title: t("Raised On"), field: "raisedDate" },
    { title: t("Date Closed"), field: 'closed_on' },
]

export const MaintanceReqTablepath = ["status", "request_no", "requestName", "property_name", "unit", "asset", "category", "sub_category", 'contact_name', 'contact_number', "priority", "location", "build_source", "job_count", "raisedDate", "closed_on"]

export const MaintanceReqTableDataType = [
    { type: ["maintenance_status"], name: "status" },
    { type: ["text"], name: "request_no" },
    { type: ["long_text"], name: "requestName" },
    { type: ["long_text"], name: "property_name" },
    { type: ["text"], name: "unit" },
    { type: ["assetName"], name: "asset" },
    { type: ["text"], name: "category" },
    { type: ["text"], name: "sub_category" },
    { type: ["text"], name: "contact_name" },
    { type: ["text"], name: "contact_number" },
    { type: ["text"], name: "priority" },
    { type: ["text"], name: "location" },
    { type: ["text"], name: "build_source" },
    { type: ["text"], name: "job_count" },
    { type: ["text"], name: "raisedDate" },
    { type: ["date"], name: "closed_on" },
]


export const ServiceJobTableHeading = (t = () => false) => [
    { title: t("job ID"), field: "job_no" },
    { title: t("property Name"), field: "property_name" },
    { title: t("Unit Name"), field: "unit_name" },
    { title: t("Requested On"), field: "created_at" },
    { title: t("Job Date"), field: "execution" },
    { title: t("Inspector Name"), field: "assigned_to" },
    { title: t("Projected"), field: "hours" },
    { title: t("Actual"), field: "actual_hours" },
    { title: t("Others"), field: "other_hours" },
    { title: t("Completed_on"), field: "completed_on" },
    { title: t("KPI"), field: "KPI" },
    { title: t("Job Status"), field: "status" },
]
export const ServiceJobTableDataType = [
    { type: ["text"], name: "job_no" },
    { type: ["text"], name: "property_name" },
    { type: ["text"], name: "unit_name" },
    { type: ["date"], name: "created_at" },
    { type: ["date"], name: "execution" },
    { type: ["text"], name: "assigned_to" },
    { type: ["text"], name: "hours" },
    { type: ["text"], name: "actual_hours" },
    { type: ["text"], name: "other_hours" },
    { type: ["date"], name: "completed_on" },
    { type: ["text"], name: "KPI" },
    { type: ["agreement_inspection_status"], name: "status" },

]
export const ServiceJobTablePath = [
    "job_no",
    "property_name",
    "unit_name",
    "created_at",
    "assigned_to",
    "hours",
    "actual_hours",
    "other_hours",
    "completed_on",
    "KPI",
    "status",
];


export const ActualBOMTableHeading = (t = () => false) => [
    { title: t("Request Id"), field: "request_id" },
    { title: t("Total Amount"), field: "total_amount" },
    { title: t("Status"), field: "status" },
]

export const ActualBOMTablePath = [
    "request_id",
    "total_amount",
    "costing_status",
]
export const ActualBOMTableDataType = [
    { type: ["text"], name: "request_id" },
    { type: ["text"], name: "total_amount" },
    { type: ["status"], name: "status" },
]


export const InvoiceTableHeader = (t = () => false) => {
    const heading = [
        { title: t("Invoice Number"), field: "invoice_no" },
        { title: t("Invoice Type"), field: "invoice_type" },
        { title: t("Agreement Number"), field: "agreement_no" },
        { title: t("Account ID"), field: "account_id" },
        { title: t("Invoice Amount"), field: "invoice_amount" },
        { title: t("Date Created"), field: "date_created" },
        { title: t("Due Date"), field: "due_date" },
        { title: t("Status"), field: "status" },
        { title: t("Balance"), field: "balance" }
    ]
    return heading
}

export const InvoiceTablePath = [
    "invoice_no", "invoice_type", "agreement_no",
    "account_id", "invoice_amount", "date_created",
    "due_date", "status", "balance"
]

export const InvoiceTableDataType = [
    { type: ["text"], name: "invoice_no" },
    { type: ["text"], name: "invoice_type" },
    { type: ["text"], name: "agreement_no" },
    { type: ["text"], name: "account_id" },
    { type: ["text"], name: "invoice_amount" },
    { type: ["date"], name: "date_created" },
    { type: ["date"], name: "due_date" },
    { type: ["status"], name: "status" },
    { type: ["text"], name: "balance" },
]


export const NonMemberTableHeading = () => {
    const Heading = [
        { title: "Property Name", field: "name" },
        { title: "Location", field: "location" },
        { title: "", field: "icon" }
    ]
    return Heading
}


export const NonMemberRow = [
    {
        name: "Property 1213",
        location: "Location w3234",
        image: "https://media.wired.com/photos/652db3f0b44e9598aea19183/master/pass/Best-Pixel-Phones-Gear.jpg"
    },
    {
        name: "Property 1213",
        location: "Location w3234",
        image: "https://media.wired.com/photos/652db3f0b44e9598aea19183/master/pass/Best-Pixel-Phones-Gear.jpg"
    },
    {
        name: "Property 1213",
        location: "Location w3234",
        image: "https://media.wired.com/photos/652db3f0b44e9598aea19183/master/pass/Best-Pixel-Phones-Gear.jpg"
    },
    {
        name: "Property 1213",
        location: "Location w3234",
        image: "https://media.wired.com/photos/652db3f0b44e9598aea19183/master/pass/Best-Pixel-Phones-Gear.jpg"
    }
]


export const ProjectedBOMHeading = () => {
    const Heading = [
        { title: "Type", field: "type" },
        { title: "Category", field: "category" },
        { title: "Sub-Category", field: "subcategory" },
        { title: "Item", field: "item" },
        { title: "", field: "icon" }
    ]
    return Heading
}

export const ProjectedBOMPath = [
    "type",
    "category",
    "subcategory",
    "item"
]

export const ProjectedBOMDataType = [
    { type: ["text"], name: "type" },
    { type: ["text"], name: "category" },
    { type: ["text"], name: "subcategory" },
    { type: ["text"], name: "item" },
    { type: ["more_2"], name: "icon" },
]


export const ProjectedBOMRow = [
    {
        "type": "Type 123",
        "category": "Category 123",
        "subcategory": "Sub Category 123",
        "item": "item 123"
    },
    {
        "type": "Type 123",
        "category": "Category 123",
        "subcategory": "Sub Category 123",
        "item": "item 123"
    },
    {
        "type": "Type 123",
        "category": "Category 123",
        "subcategory": "Sub Category 123",
        "item": "item 123"
    },
    {
        "type": "Type 123",
        "category": "Category 123",
        "subcategory": "Sub Category 123",
        "item": "item 123"
    },
    {
        "type": "Type 123",
        "category": "Category 123",
        "subcategory": "Sub Category 123",
        "item": "item 123"
    }
]


// export const statusOption=(t => false)=> {
//     return 
// }

export const statusOption = (t = () => false) => {
    return [
        {
            label: t("Active"),
            value: true
        },
        {
            label: t("In-Active"),
            value: false
        }
    ]
}
