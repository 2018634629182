import { Box, Button, Divider, Drawer, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { withNavBars } from "../../HOCs";
import { AlertDialog, FilterGenerator, GeneralCard, LoadingSection, MiniPropertyDetail, ProductUpdate, Subheader, UploadComponent, UseDebounce } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { AlertProps, NetWorkCallMethods, accessCheckRender, enumSelect, enum_types, getRoutePermissionNew, useWindowDimensions } from "../../utils";
import { StatusOptionList, inspectionMappingState } from "../../utils/insepectionMaster";
import AddInspectionItem from "./components/addInspectionItem";
// import PropertyDetailsCard from "./components/propertyDetailsCard";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { CloseIconWithBG } from "../../assets";
import { BackendRoutes } from "../../router/routes";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { InspectionItemMappingDataType, InspectionItemMappingHeading, InspectionItemMappingPath, constructListData, constructOptionResponse } from "../../utils/inspectionItemMapping";
import InspectionMappingTable from "./components/table";
import { InspectionMappingStyles } from "./styles";

const MapUnitDetails = (props) => {
    const { t } = props

    const classes = InspectionMappingStyles()
    const size = useWindowDimensions()
    const navigate = useNavigate();
    const { state } = useLocation();
    const debounce = UseDebounce();
    const [searchText, setSearchText] = React.useState("");
    const [loading, setLoading] = React.useState(true)
    const [is_table_loading, set_is_table_loading] = React.useState(true)
    const [page, setPage] = React.useState(1);
    const auth = React.useContext(AuthContext);
    const [limit, setLimit] = React.useState(10);
    const [permissions, setPermission] = React.useState({})
    const [itemList, setItemList] = React.useState({
        details: {},
        list: [],
        count: 0,
    })
    const [addInspection, setAddInspection] = useState(false);
    const [drawer, setDrawer] = React.useState(false);
    const [filterData, setFilterData] = React.useState({
        is_active: [true]
    });
    const [isShowMore, setIsShowMore] = React.useState(false)
    const [options, setOptions] = React.useState({
        item_types: [],
        item_conditions: [],
    });
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)
    const [details, setDetails] = React.useState(inspectionMappingState);
    const [assets, setAssets] = React.useState([])
    const alert = React.useContext(AlertContext)

    //get list
    const getUnitDetails = (searchText, offset, limits, filterData) => {
        set_is_table_loading(true);

        const payload = {
            unit_id: state?.id,
            search: searchText,
            offset: offset,
            limit: limits,
            active: filterData?.is_active ?? [],
            item_type: filterData?.item_types?.map((x) => { return x?.value }),
            category_ids: filterData?.category_ids?.map((x) => { return x?.value }),
            manufacturer_ids: filterData?.manufacturer_ids?.map((x) => { return x?.value }),
        }

        NetworkCall(
            `${config.api_url}${BackendRoutes?.inspection_item_mapping_getAll}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((response) => {
            const data = response?.data;
            setItemList({
                details: data?.unit,
                list: constructListData(data?.data),
                count: data?.count
            })
            setLoading(false);
            set_is_table_loading(false);
        }).catch((err) => {
            console.log(err)
            setLoading(false)
            set_is_table_loading(false)
        })
    }

    const validateForm = () => {
        let isValid = true;
        let error = details.error;
        if (details?.item === "Inventory") {
            if (details?.modalNumber?.length === 0 || details?.modalNumber === null) {
                isValid = false;
                error.modalNumber = t("Model Number is required")
            }
            if (details?.serialNumber?.length === 0 || details?.serialNumber === null) {
                isValid = false;
                error.serialNumber = t("Serial Number is required");
            }
            if (details?.itemCondition?.length === 0 || details?.itemCondition === null) {
                isValid = false;
                error.itemCondition = t("Item Condition is required");
            }
            setDetails({ ...details, error });
            return isValid;
        }
        else {
            return isValid
        }
    };
    const onSubmit = () => {
        const Images = assets?.filter((x) => { return x?.is_active })
        if (validateForm()) {
            let payload = {
                id: details?.id,
                model_number: details?.modalNumber ?? null,
                serial_number: details?.serialNumber ?? null,
                item_condition: details?.itemCondition?.value ?? null,
                purchase_price: details?.purchasePrice ?? null,
                cost_price: details?.costPrice ?? null,
                location_id: details?.location?.value ?? null,
                assets: Images?.length > 0 ? JSON.stringify(Images) : null
            }
            updateMapping("update", payload)
        }
    }
    //update
    const updateMapping = (type, data, key, value, id) => {
        setIsDisableBtn(true)
        const payload = {
            id: data?.id ?? id,
            payload: type === "status" ? { [key]: value } : data
        }
        NetworkCall(
            `${config.api_url}/queries/inspection_item_mapping/update`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then(rs => {
            setIsDisableBtn(false)
            setSearchText("")
            setPage(0)
            setFilterData({
                is_active: []
            })
            getUnitDetails("", 0, limit, { is_active: [] });
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: `${t("Updated Successfully")}`,
            });
            setIsShowMore(false);
        }).catch(er => {
            setIsDisableBtn(false)
            console.log(er)
        })
    }
    // Function to get Enum value
    const getEnum = async () => {
        const result = await enumSelect([enum_types?.inspection_item_type, enum_types?.inspection_item_condition]);
        const temp_item_types = result?.inspection_item_type?.filter((val) => { return val?.value !== "Product" });
        setOptions({
            item_types: temp_item_types,
            item_conditions: result?.inspection_item_condition,
        })
    }
    //initial load
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)

            getUnitDetails(searchText, 0, limit, filterData)
            getEnum()
        }
        // eslint-disable-next-line
    }, [auth])
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getUnitDetails(searchText, offset, limit, filterData)
    }
    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getUnitDetails(searchText, 0, value, filterData)
    }
    //on search
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getUnitDetails(e, 0, limit, filterData)
    }
    //Add inspection Item
    const handleAddInspection = () => {
        setAddInspection(true)
    }
    const handleIcon = (type, _) => {
        let data = _?.data;
        if (type === "view") {
            getViewDetails(data)
            setIsShowMore(true)
        } else if (type === "active") {
            updateMapping("status", [], "is_active", data?.is_active ? false : true, data?.id)
        }
    }
    const getViewDetails = (data) => {
        const payload = {
            id: data?.id
        }

        NetworkCall(
            `${config.api_url}/inspection_item_mapping/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setAssets(response?.data?.data?.assets?.length>0?(typeof response?.data?.data?.assets ==="string"?JSON.parse(response?.data?.data?.assets):response?.data?.data?.assets):[])
            const values = response?.data?.data
            let result = {
                ...values,
                ...inspectionMappingState,
                isView: true,
                modalNumber: values?.model_no ?? null,
                serialNumber: values?.serial_number ?? null,
                purchasePrice: values?.purchase_price ?? null,
                costPrice: values?.cost_price ?? null,
                itemCondition: values?.item_condition !== null ? { value: values?.item_condition, label: values?.item_condition } : "",
                location: values?.location_id !== null ? { value: values?.location_id, label: values?.location_name } : "",
                last_service_date: values?.last_service_date !== null ? moment(values?.last_service_date).format("DD-MM-YYYY") : null,
                last_service_reference: values?.last_service_ref,
                item: data?.item,
                error: {
                    modalNumber: "",
                    serialNumber: "",
                    itemCondition: ""
                }
            }
            setDetails({ ...result, item: data?.item })
        }).catch((err) => {
            console.log(err)
            set_is_table_loading(false)
            setLoading(false)
        })
    }
    //apply filter
    const onApplyFilter = (data) => {
        getUnitDetails(searchText, 0, limit, data)
        setFilterData(data)
        setDrawer(false)
    }
    //reload
    const reload = () => {
        setSearchText("")
        setFilterData({
            is_active: []
        })
        getUnitDetails("", 0, limit, { is_active: [] })
    }
    const handleSubmit = () => {
        if (details?.isView) {
            setDetails({ ...details, isView: false, isEdit: true })
        }
        else {
            onSubmit()
        }
    }
    const render = () => {
        return (
            <Box>
                {/* subheader */}
                <Subheader goBack={() => navigate()} title={state?.data?.unitName} />
                {/* unitdetails card */}
                {
                    loading ?
                        <LoadingSection top="20vh" message={"Fetching Details"} />
                        :
                        <div style={{ height: size?.height - (64 + 49), overflow: "auto", padding: "16px" }}>
                            <MiniPropertyDetail
                                logo={itemList?.details?.logo}
                                unit_no={state?.data?.unitNumber}
                                property_name={state?.data?.propertyName}
                                unit_name={state?.data?.unitName}
                                t={t}
                                address={state?.data?.address} />
                            {/* items table */}
                            <Box className={`${classes.root}`}>

                                <InspectionMappingTable
                                    permissions={permissions}
                                    dot={filterData?.is_active?.length > 0}
                                    onFilter={() => setDrawer(true)}
                                    placeholder={"searchInspectionItem"}
                                    handleAddInspection={() => handleAddInspection()}
                                    handleIcon={handleIcon}
                                    heading={InspectionItemMappingHeading(t)}
                                    path={InspectionItemMappingPath}
                                    dataType={InspectionItemMappingDataType}
                                    add={t("Map New Item")}
                                    searchText={searchText}
                                    handleSearch={handleSearch}
                                    handleChangeLimit={handleChangeLimit}
                                    handlePagination={handlePagination}
                                    page={page}
                                    list={itemList}
                                    limit={limit}
                                    height={`calc(100vh - 390px)`}
                                    is_loading={is_table_loading}
                                />
                            </Box>
                        </div>
                }

                {/*Add Inspection Item */}
                <AlertDialog open={addInspection}
                    onClose={() => setAddInspection(!addInspection)}
                    header={t("Add New Item")}
                    component={<AddInspectionItem details={itemList?.details} property_id={state?.property_id}
                        onClose={() => setAddInspection(false)}
                        reload={reload}
                    />}
                    md
                />
                {/*Product Update */}
                <Drawer
                    anchor={"right"}
                    open={isShowMore}
                    onClose={() => setIsShowMore(false)}
                >
                    <Box width="500px">
                        <Box display={"flex"} justifyContent={"space-between"} p={1.5} alignItems={"center"} sx={{ backgroundColor: "#F2F4F7" }}>
                            <Typography className={classes.drawerTitle}>{t("Unit Item/Asset Information")}</Typography>
                            <Box onClick={() => setIsShowMore(false)} style={{ cursor: "pointer" }}><CloseIconWithBG /></Box>
                        </Box>
                        <Divider />
                        <Box>
                            <Box height={size?.height - 150} overflow="scroll" position={"relative"} p={2}>
                                <GeneralCard
                                    list={details}
                                    t={t}
                                    component={"toggleButton"}
                                    imageAvatar
                                    isViewProduct={true}
                                    handleCheck={(val) => updateMapping("status", [], "is_active", val)}
                                />
                                <Box mt={2}>
                                    <UploadComponent
                                        logo_title={t("Upload Images")}
                                        assets={assets}
                                        setAssets={setAssets}
                                        xs={3}
                                        height={"100px"}
                                        imageHeight={"98px"}
                                        readOnly={details?.isView}
                                    />
                                </Box>
                                {details?.item === "Inventory" &&
                                    <Box mt={1}>
                                        <ProductUpdate t={t} onUpdate={updateMapping} itemConditionOptions={options?.item_conditions} isDisableBtn={isDisableBtn} details={details} setDetails={setDetails} company={state?.company} />
                                    </Box>
                                }
                            </Box>
                            <Box className={classes.fixbtn}>
                                <Button fullWidth onClick={handleSubmit} disabled={details?.length === 0 || isDisableBtn} variant="contained">{details?.isView ? t("Edit") : t("Update")}</Button>
                            </Box>
                        </Box>
                    </Box>
                </Drawer>



                {/*filter component */}
                {
                    drawer && (
                        <FilterGenerator
                            open={drawer}
                            onClose={() => setDrawer(false)}

                            components={[
                                {
                                    component: "toggleButton",
                                    value: filterData?.is_active,
                                    state_name: "is_active",
                                    label: "Active",
                                    options: StatusOptionList(t),
                                    isMulti: true
                                },
                                {
                                    component: "select",
                                    value: filterData?.item_types,
                                    options: options?.item_types,
                                    isMulti: true,
                                    state_name: "item_types",
                                    label: t("Item Type"),
                                    placeholder: t("Item Type"),
                                },
                                {
                                    component: "select",
                                    value: filterData?.manufacturer_ids,
                                    options: [],
                                    isMulti: true,
                                    label: t("Manufacturer"),
                                    placeholder: t("Manufacturer"),
                                    state_name: "manufacturer_ids",
                                    loadOptions: (search, array, handleLoading) =>
                                        loadOptionsApis(
                                            BackendRoutes?.manufacturer_master_get?.slice(1),
                                            {}, search, array, handleLoading,
                                            "data", {}, constructOptionResponse,
                                            { type: "manufacturer" }
                                        ),
                                    debounceTimeout: 800,
                                    isPaginate: true,
                                },
                                {
                                    component: "select",
                                    value: filterData?.category_ids,
                                    options: [],
                                    isMulti: true,
                                    label: t("Category"),
                                    placeholder: t("Category"),
                                    state_name: "category_ids",
                                    loadOptions: (search, array, handleLoading) =>
                                        loadOptionsApis(
                                            BackendRoutes?.inspection_item_category_get?.slice(1),
                                            {}, search, array, handleLoading,
                                            "data", {}, constructOptionResponse,
                                            { type: "category" }
                                        ),
                                    debounceTimeout: 800,
                                    isPaginate: true,
                                },
                            ]}
                            onApply={(value) => onApplyFilter(value)}
                        />
                    )
                }

            </Box >
        )
    }
    return (
        <>

            {accessCheckRender(render, permissions)}

        </>
    )
}
const props = {
    boxShadow: false
}
export default withTranslation("inspection")(withNavBars(MapUnitDetails, props));
