import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStylesCreation = makeStyles((theme) => ({
  listText: {
    backgroundColor: "#5078E1",
    width: "135px !important",
    color: "#fff",
    borderRadius: "4px",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontFamily: FontFamilySwitch().bold,
  },
  listText1: {
    width: "120px !important",
    color: "#98A0AC",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  tabname: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
  },
  list: {
    display: "flex",
    borderRadius: "4px",
    "&.MuiListItem-root": {
      width: "135px !important",
      padding: "0px!important",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      border: "1px solid #E4E8EE",
      cursor: "pointer",
    },
  },
}));
