import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Grid, Popover, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { FontFamilySwitch } from '../../utils';

const styles = makeStyles((theme) => ({
    placeholder: {
        fontFamily: FontFamilySwitch().regular,
        fontSize:"0.875rem",
        whiteSpace: "nowrap",
        color: "#98A0AC"
    },
    div: {
        width: "305px",
        padding: "6px"
    },
    title: {
        fontFamily: FontFamilySwitch().semiBold,
        fontSize:"0.75rem",
        color: "#4E5A6B",
        marginBottom: "8px"
    },
    value: {
        fontFamily: FontFamilySwitch().semiBold,
        fontSize:"0.875rem",
        color: "#091B29",
    },
    type: {
        fontFamily: FontFamilySwitch().semiBold,
        fontSize:"0.75rem",
        color: "#4E5A6B",
        textAlign: "center",
        cursor: "pointer",
        //marginTop: "-16px",
        margin: "0px 8px"

    },
    input: {
        width: "80px",
        height: "30px",
        border: "1px solid #c1c1c1",
        borderRadius: theme.palette.borderRadius
    },
    select: {
        width: "80px",
        height: "30px",
        border: "1px solid #F5F7FA",
        borderRadius: theme.palette.borderRadius,
        marginLeft: "12px",
        backgroundColor: "#F5F7FA",
        color: "#091B29",
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        outline: "none",
        padding: "2px"
    },
    dialog: {
        "& .MuiPopover-paper": {
            borderRadius: "10px",
            boxShadow: "0px 8px 24px #0717411F"
        }
    },


}));
export const PopoverRanger = ({ startIcon = "", placeholder = "", updateState = () => false,
    range2 = "",
    range1 = "",
    rangetype = ""
}) => {
    const classes = styles()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const measurementUnits = [
        {
            label: "Acres",
            value: "Acres",
        },
        {
            label: "sq.meter",
            value: "sq.meter",
        },
        {
            label: "sq.feet",
            value: "sq.feet",
        },
        {
            label: "Hectare",
            value: "Hectare",
        },
        {
            label: "Cents",
            value: "Cents",
        },
        {
            label: "Sq.Yards",
            value: "Sq.Yards",
        },
    ];
    return (
        <div>
            <Box sx={{ cursor: "pointer" }} display="flex" alignItems="center" height="45px" onClick={handleClick}>
                <Box marginLeft="8px" marginTop="6px">
                    <img src={startIcon} alt="" />
                </Box>
                <Box flexGrow={1} marginLeft="4px">
                    {
                        range1 && range2 && rangetype ?
                            <>
                                <Typography className={classes.value}>{range1}-{range2}&nbsp;&nbsp;{rangetype}</Typography>
                            </>
                            : <Typography className={classes.placeholder}>{placeholder}</Typography>
                    }

                </Box>
                <Box marginTop="4px" marginRight="8px">
                    <ArrowDropDownIcon style={{ fontSize: "26px", color: "#98A0AC" }} />
                </Box>

            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className={classes.dialog}
            >
                <div className={classes.div}>

                    <Grid container display="flex" alignItems="center" p={1}>
                        <Grid item >
                            <input
                                onKeyPress={(e) => {
                                    if (['e', 'E']?.includes(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                type="number"
                                value={range1}
                                onChange={(e) => updateState("range1", e.target.value)}
                                className={classes.input} />
                        </Grid>
                        <Grid item ><Typography className={classes.type}>-</Typography></Grid>
                        <Grid item >
                            <input
                                onKeyPress={(e) => {
                                    if (['e', 'E']?.includes(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                type="number"
                                value={range2}
                                onChange={(e) => updateState("range2", e.target.value)}
                                className={classes.input} />
                        </Grid>
                        <Grid item >
                            <select value={rangetype} className={classes.select} onChange={(value) => {
                                updateState("rangetype", value.target.selectedOptions[0]?.value)
                                handleClose()
                            }} name="cars" id="cars">
                                {
                                    measurementUnits?.map((val) => {
                                        return <option
                                            style={{ margin: "2px", padding: "4px" }}
                                            value={val?.value}>{val?.label}</option>
                                    })
                                }



                            </select>

                        </Grid>
                    </Grid>
                </div>
            </Popover>
        </div>
    )
}