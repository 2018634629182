import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const residentOnboardingStyle = makeStyles((theme) => ({
    createBtn: {
        height: 40,
        width: 78
    },
    drawerBox: {
        width: '456px',
        position: "relative"
    },
    header: {
        position: "fixed",
        top: 0,
        width: "100%",
        backgroundColor:"#F2F4F7"
    },
    bottom: {
        position: "fixed",
        bottom: 0,
        padding: '8px',
        width: '456px'
    },
    body: {
        // position: "fixed",
        top: 0,
        marginTop: '64px',
        height: "calc(100vh - 130px)",
        overflow: "auto",
        width: "100%"
    },
    drawerCloseBtn: {
        fontSize:"1rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
        background: theme.palette.background.tertiary,
        borderRadius: 4,
        height: 32,
        width: 32,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    drawerHeading: {
        fontSize:"1rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    previous: {
        border: `1px solid ${theme.palette.border.primary}`,
        background: "transparent",
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        // height: 40,
        padding: '11px 16px',
        lineHeight: '19px !important',
        "&:hover": {
            background: "transparent",
        }
    },
    next: {
        padding: '11px 16px',
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        lineHeight:'19px !important'
  
    },
    publishDialog:{
        '& .MuiPaper-root':{
            background:"transparent !important"
        }
    },
    publishPopupTitle:{
        fontSize:"1rem",
        color:"#fff",
        fontWeight:"bold",
        whiteSpace:"nowrap"
    },
    deletePopupTitle:{
        fontSize:"1rem",
        color:"#fff",
        fontWeight:"bold",
        width:300
    },
    publishPopupBtn:{
        background:"#fff",
        borderRadius:4,
        width:150,
        height:40,
        justifyContent:"center",
        alignItems:"center",
        display:"flex",
        cursor:"pointer"
    },
    publishbtnText:{
        color:theme.palette.primary.main,
        fontSize:"0.875rem",
        fontWeight:"bold",
    },
    Yes: {
        color: theme?.palette?.primary?.main,
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        border: `1px solid white`,
        backgroundColor: "white",
        padding: "7px 29px",
        width: "100%",
        "&:hover": {
          border: `1px solid white`,
          backgroundColor: "white",
        },
      },
      No: {
        color: "white",
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        border: `1px solid white`,
        padding: "7px 29px",
        backgroundColor: theme?.palette?.primary?.main,
        marginInlineStart: "10px",
        width: "100%",
        "&:hover": {
          border: `1px solid white`,
          backgroundColor: theme?.palette?.primary?.main,
        },
      },
      Customize:{
        color:"#5078E1",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize:"0.875rem",
        cursor:"pointer",
        border:"1px solid #5078E1",
        borderRadius:"4px",
        padding:'4px'
    }
}))

export const StepsStyle = makeStyles((theme) => ({
    Label: {
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: "0.75rem",
        marginBottom: "5px",
    
      },
    addBtn: {
        color: theme.palette.info.main,
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        background: theme.palette.info.light,
        padding: 8,
        borderRadius: 6,
        width: 200,
        cursor: "pointer"
    },
    boldHeader: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
        marginLeft: "12px",
        marginTop: "4px"
    },

    header: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    selectedValue: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
    },
    endAdornment: {
        display: "flex",
        borderLeft: "1px solid #E4E8EE",
        width: "100px",
        height: "42.13px",
        alignItems: "center",
        cursor: "pointer",
        paddingLeft: 8
    },
    durationLabel: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().semiBold,
        marginBottom: "5px",
    },
    optionAvatar: {
        height: 35,
        width: 35,
        borderRadius: 4,
        marginInlineEnd: "8px"

    },
    optionHeader: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: "#091B29"
    },
    optionSubTitle: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().regular,
        whiteSpace: "noWrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginLeft: "0px !important"
    },
    selectParent: {
        padding: "5px",
        borderRadius: 4,
        border: "1px solid #E4E8EE",
        margin: "5px"
    },
    AccountImg1: {
        borderRadius: theme.palette.borderRadius,
        // padding: "12px 14px 10px 14px",
        // backgroundColor: "#F2F4F7",
    },
    Customize:{
        color:"#5078E1",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize:"0.875rem",
        cursor:"poineter"
    }
}))

export const ResidentOnboardViewEditStyle = makeStyles((theme) => ({
    headerTitle: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.secondary,
        // marginBottom:"16px"
    },
    leftCard: {
        background: "#fff",
        height: `calc(100vh - 140px)`,
    },
    centerCard: {
        background: theme.palette.background.unitBackground,
        height: `calc(100vh - 140px)`,
        overflow: "auto"
    },
    rightCard: {
        background: "#fff",
        height: `calc(100vh - 140px)`,
    },
    infoTitle: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    infoContent: {
        fontSize:"0.75rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    detailHeader: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.secondary
    },
    leftBtn: {
        height: 40,
        border: `1px solid ${theme.palette.border.secondary}`,
        "&:hover": {
            background: "transparent"
        }
    },
    leftBtnText: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.palette.primary.main
    },
    headTitle: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.tertiary
    },
    bodySemibold: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.secondary
    },
    bodyRegular: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().regular,
        color: theme.typography.color.secondary
    },
    bodyBold: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary,
        textAlign: "right"
    },
    totalLabel: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary,
    },
    table: {
        background: theme.palette.background.secondary,
        borderRadius: 8
    },
    createBtn: {
        height: 40,
        width: 78
    },
    drawerBox: {
        width: "428px",
        position: "relative"
    },
    header: {
        position: "fixed",
        top: 0,
        width: "100%"
    },
    bottom: {
        position: "fixed",
        bottom: 0,
    },
    body: {
        position: "fixed",
        top: 0,
        marginTop: 120,
        height: "calc(100vh - 175px)",
        overflow: "auto",
        width: "428px",
    },
    drawerCloseBtn: {
        fontSize:"1rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
        background: theme.palette.background.tertiary,
        borderRadius: 4,
        height: 32,
        width: 32,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    drawerHeading: {
        fontSize:"1rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    previous: {
        border: `1px solid ${theme.palette.border.primary}`,
        background: "transparent",
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        height: 40,
        "&:hover": {
            background: "transparent",
        }
    },
    bodyHeadTitle: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold
    },
    quickAccount: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.palette.primary.main,
        cursor: "pointer"
    },
    step3Header: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.secondary
    },
    summeryRoot: {
        backgroundColor: "#F5F7FA",
        borderRadius: theme.palette.borderRadius,
    },
    title: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: "#4E5A6B",
        marginBottom: "10px"
    },
    snackBarRoot: {
        backgroundColor: theme.palette.success.main,
        width: "350px",
        borderRadius: "4px",
        marginTop: "-20px"
    },
    alertTitle: {
        color: theme.palette.background.paper,
        fontFamily: FontFamilySwitch().bold,
        fontSize:"0.875rem"

    },
    alertSub: {
        color: theme.palette.background.paper,
        fontSize:"0.75rem",
        marginTop: "6px"
    },
    downBtn: {
        border: `1px solid ${theme?.typography?.color?.primary}`,
        color: theme?.typography?.color?.primary,
        backgroundColor: "white",
        "&:hover": {
            backgroundColor: "white",
            border: `1px solid ${theme?.typography?.color?.primary}`,
        }
    },
    Customize:{
        color:"#5078E1",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize:"0.875rem",
        cursor:"poineter"
    }
}))