import { Box, Grid } from "@mui/material";
import React from "react";
import { Subheader } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { basicSettingState, returnSeetingData, settinglist } from "../../utils/clientsettings";
import { SettingList } from "../companyCreation/components";
import { useStyles } from "./styles";
import { AdvancedSettings, BasicSettings, Country, Currency, Tax, TaxGroupItem, ResidentSetting, GlobalControls } from "./tabs";
import { TemplateChoose, TermsAndCondition } from "./components";
import { withTranslation } from "react-i18next";
import { OnboardSettings } from "./tabs/onBoardSettings";

const ClientSettings = ({
    t = () => false
}) => {
    const [selected, setSelected] = React.useState(1);
    const alert = React.useContext(AlertContext);
    const [loading, setLoading] = React.useState(false);
    const [settingDetails, setDetails] = React.useState({ ...basicSettingState });
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)
    const [tcLoading, setTCLoading] = React.useState(false)

    const classes = useStyles()
    //get settingDetails
    const getSettingDetails = () => {
        setTCLoading(true)
        NetworkCall(
            `${config.api_url}/client/settings/get`,
            NetWorkCallMethods.get,
            null,
            null,
            true,
            false
        )
            .then((response) => {
                let result = returnSeetingData(response?.data?.settings)
                setDetails(result)
                setTCLoading(false)
            }).catch((err) => {
                console.log(err)
            })
    }
    //Initial load
    React.useEffect(() => {
        setSelected(settinglist(t)[0])
        getSettingDetails()
        // eslint-disable-next-line
    }, [])
    //load option
    const manualCurrencyMasterResponse = (array) =>{
        return array?.currency_master
    }
    const loadOptionData = async (search, array, type) => {

        setLoading(type);
        if (type === "currency") {
            return (search, array, handleLoading) =>
                loadOptionsApis(
                    "queries/currency_master/getAll",
                    {
                        "orderByKey": "name",
                        "orderByValue": "asc"
                    },
                    search,
                    array,
                    handleLoading,
                    "data",
                    {},
                    manualCurrencyMasterResponse
                )
        }
        else {
            return {
                options: [],
                hasMore: false
            }
        }
    }
    //on submit SeetingForm
    const onSubmit = (data) => {
        setIsDisableBtn(true)
        NetworkCall(
            `${config.api_url}/client/settings/upsert`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Setting Successfully Saved"),
                });
                getSettingDetails()
            }).catch((err) => {
                setIsDisableBtn(false)
                console.log(err)
            })
    }
    const alertFun = () => {

        alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: t("Default Selected"),
        });
    }
    //component list
    const components = {
        1: <BasicSettings
            selected={selected}
            loadOptionData={loadOptionData}
            loading={loading}
            settings={settingDetails}
            onSubmitSettings={onSubmit}
            isDisableBtn={isDisableBtn}
            t={t}
        />,
        2: <AdvancedSettings t={t} settings={settingDetails} selected={selected} data={settingDetails} onSubmitSettings={onSubmit} isDisableBtn={isDisableBtn} />,
        // 3: <LogoApperance t={t} selected={selected} />,
        // 4: <Integration selected={selected} />,
        // 5: <WelcomeScreen selected={selected} />,
        // 6: <DashboardBanner selected={selected} type="Dashboard" />,
        // 7: <ServiceBanner t={t} selected={selected} type="Services" />,
        // 8: <CommunityBanner selected={selected} type="Community" />,
        9: <Country t={t} selected={selected} />,
        10: <Currency t={t} selected={selected} />,
        11: <Tax selected={selected} t={t} />,
        12: <TaxGroupItem t={t} selected={selected} />,
        13: <ResidentSetting t={t} settings={settingDetails} selected={selected} details={settingDetails} onSubmitSettings={onSubmit} isDisableBtn={isDisableBtn} />,
        14: <TemplateChoose t={t} settings={settingDetails} selected={selected} data={settingDetails} onSubmitSettings={onSubmit} alert={alertFun} isDisableBtn={isDisableBtn} />,
        15: <TermsAndCondition t={t} onSubmitSettings={onSubmit} settings={settingDetails} tcLoading={tcLoading} />,
        16: <GlobalControls t={t} settings={settingDetails} selected={selected} data={settingDetails} onSubmitSettings={onSubmit} isDisableBtn={isDisableBtn} />,
        17: <OnboardSettings
            selected={selected}
            loadOptionData={loadOptionData}
            loading={loading}
            settings={settingDetails}
            onSubmitSettings={onSubmit}
            isDisableBtn={isDisableBtn}
            t={t}
        />,
    }
    return (
        <Box>
            <Subheader hideBackButton title={t("Client settings")} />
            <Grid container spacing={2} p={2}>
                <Grid item xs={3}>
                    <Box className={classes.box}>
                        {/*setting list */}

                        <SettingList t={t} list={settinglist(t)} selected={selected?.value} setSelected={setSelected} />
                    </Box >
                </Grid >
                <Grid item xs={9}>
                    <Box className={classes.box}>
                        {/*component render*/}
                        {components?.[selected?.value]}


                    </Box >
                </Grid >
            </Grid >
        </Box >
    )

}
export default withTranslation("clientSettings")(ClientSettings);
