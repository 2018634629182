import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import moment from 'moment';
import { MonthlyCalendarStyles, eventListStyle } from './style';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { AlertDialog, LoadingSection } from '../../../components';
import { ViewMore } from './showMore';


const MonthlyCalendar = ({ t, dates = {}, month = {}, data = [], openDeclinePopup = () => false, loading = false }) => {
    const classes = MonthlyCalendarStyles()
    const [showMoreDetails, setShowMoreDetails] = React.useState({
        isShow: false,
        data: []
    })
    const days = [
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat",
        "Sun"
    ]
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#fff',
            maxWidth: "100%",
            border: '1px solid #dadde9',
        },
    }));
    const showMoreData = (data) => {
        setShowMoreDetails({
            isShow: true,
            data: data
        })
    }
    return (
        <Box>
            <Grid container >
                {
                    days.map(e => {
                        return (
                            <Grid item xs={1.7} textAlign={"center"} p={1}>
                                <span className={classes.days}> {e} </span>
                            </Grid>
                        )
                    })
                }
            </Grid>
            <Grid container mt={0} className={classes.calDates}>

                {
                    loading ?
                        <Grid item xs={12}>
                            <LoadingSection />
                        </Grid>
                        :
                        dates.map(e => {
                            return (
                                <Grid item xs={1.7} className={classes.date_outline}>
                                    <Stack mt={1} justifyContent={"space-between"} height="100%" pb={2}>
                                        <Box className={classes.date}><span className={moment(e).format("M") === moment(month).format("M")
                                            ? " " : classes.hidden_other_month_dates}>{moment(e).format("DD")}</span></Box>
                                        <Box sx={{ textAlign: "right" }}>

                                            <Box m={1}>
                                                {
                                                    data?.map(evnt => {
                                                        return (
                                                            evnt?.calendarData?.map(x => {
                                                                return (
                                                                    x?.booking?.length === 1 &&
                                                                        moment(e).format("DD") === moment(evnt.date).format("DD")
                                                                        && moment(evnt.date).format("MM") === moment(e).format("MM") ?
                                                                        x?.booking?.map(val => {
                                                                            return (
                                                                                <Box>
                                                                                    <HtmlTooltip
                                                                                        title={
                                                                                            <React.Fragment>
                                                                                                <CustomToolTip t={t} data={val} openDeclinePopup={openDeclinePopup} />
                                                                                            </React.Fragment>
                                                                                        }
                                                                                        arrow
                                                                                        className={classes.customTooltip}
                                                                                    >

                                                                                        <Stack direction={"column"} spacing={1} alignItems={"center"}
                                                                                            sx={{
                                                                                                backgroundColor: "#FEEAEA80",
                                                                                                color: "#B3776D"
                                                                                            }} p={0.7}>
                                                                                            <Typography className={classes.event_label}> {val?.parking_no} , {val?.reference_id} </Typography>
                                                                                        </Stack>

                                                                                    </HtmlTooltip>
                                                                                </Box>

                                                                            )
                                                                        })
                                                                        : ""
                                                                )
                                                            })

                                                        )
                                                    })
                                                }
                                                {

                                                    <Box>
                                                        {data.filter(i => i.date === moment(e).format("YYYY-MM-DD")).map(evnt => {
                                                            return (
                                                                evnt?.calendarData?.map(x => {
                                                                    return (
                                                                        x?.booking?.length > 1 &&
                                                                            moment(e).format("DD") === moment(evnt.date).format("DD")
                                                                            && moment(evnt.date).format("MM") === moment(e).format("MM") ?
                                                                            <div>
                                                                                <HtmlTooltip
                                                                                    title={
                                                                                        <React.Fragment>
                                                                                            <CustomToolTip t={t} data={x?.booking?.[0]} openDeclinePopup={openDeclinePopup} />
                                                                                        </React.Fragment>
                                                                                    }
                                                                                    arrow
                                                                                    className={classes.customTooltip}

                                                                                >

                                                                                    <Grid container>
                                                                                        <Grid item xs={12} className={classes.eventlabel} sx={{
                                                                                            bgcolor: "#FEEAEA80",
                                                                                            color: "#B3776D",
                                                                                            textAlign: "center",
                                                                                            display: "flex",
                                                                                            justifyContent: "center"
                                                                                        }}>
                                                                                            <Typography className={classes.event_label}> {x?.booking?.[0]?.parking_no} , {x?.booking?.[0]?.reference_id} </Typography>
                                                                                        </Grid>
                                                                                        <Typography className={classes.moreLabel} onClick={() => showMoreData(x)}> +{x?.booking?.length - 1} {t("more")} </Typography>
                                                                                    </Grid>


                                                                                </HtmlTooltip>
                                                                            </div>
                                                                            : ""
                                                                    )
                                                                })
                                                            )


                                                        })}

                                                    </Box>
                                                }
                                            </Box>

                                        </Box>
                                    </Stack>
                                </Grid>
                            )
                        })
                }
            </Grid>
            <AlertDialog open={showMoreDetails?.isShow}
                onClose={() => setShowMoreDetails({ ...showMoreDetails, isShow: false })}
                header={t("Parking Booking")}
                component={<ViewMore t={t} details={showMoreDetails?.data} openDeclinePopup={openDeclinePopup} type="Monthly" />}
                md
            />
        </Box>
    )
}
const CustomToolTip = ({ t, data = {}, startTime = null, endTime = null, openDeclinePopup = () => false }) => {
    const classes = eventListStyle()
    return (
        <Stack>
            <Stack direction={"column"} spacing={1}>
                <Stack>
                    <Typography className={classes.ttTitle}> {data?.parking_no} ,{data?.reference_id}  </Typography>
                </Stack>
                <Stack>
                    <Typography className={classes.ttsecTitle}> {data?.parking_master_parking_type} </Typography>
                </Stack>
                <Stack>
                    <Typography className={classes.ttassigned_by}>{t("booked by :")} {data?.first_name}, {`${data?.mobile_no_country_code ?? ""} ${data?.mobile_no ?? ""}`} </Typography>
                </Stack>
                <Stack>
                    <Typography className={classes.moreLabel} onClick={() => openDeclinePopup(data)}> {t("view more")}</Typography>
                </Stack>
            </Stack>

        </Stack>
    )
}

export  default MonthlyCalendar