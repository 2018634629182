import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import PropertyLeaseAndSaleIcon from "../../../assets/switcherCardIcons/propertyLeaseIcon";
import ClientmanagementIcon from "../../../assets/switcherCardIcons/clientManagerIcon";
import CommunityManagementIcon from "../../../assets/switcherCardIcons/communityManagementIcon";
import FacilityManagementIcon from "../../../assets/switcherCardIcons/facilityManagementIcon";
import InspectionIcon from "../../../assets/switcherCardIcons/inspectionIcon";
import VisitorAndSecurityIcon from "../../../assets/switcherCardIcons/visitorAndSecurityIcon";
import { InviteCardStyle } from "./style";

const InviteCard = ({ t, data = {}, click = () => false, type = "", isCustomer,isDisableBtn=false}) => {
    const classes = InviteCardStyle()
    const avatarSvg = {
        2: <Avatar variant="rounded" src="images/switch/PropertyMangement.svg" />,
        1: <PropertyLeaseAndSaleIcon />,
        5: <ClientmanagementIcon />,
        4: <CommunityManagementIcon />,
        3: <VisitorAndSecurityIcon />,
        6: <InspectionIcon />,
        7: <FacilityManagementIcon />,
        8: <Avatar variant="rounded" src="images/switch/FinanceAndAccoutingModule.svg" />,
        9: <Avatar variant="rounded" src="images/switch/VendorPortal.svg" />,
        11: <Avatar variant="rounded" src="images/switch/tenantPortal.svg" />,
        12: <Avatar variant="rounded" src="images/switch/OwnerPortal.svg" />,
        10: <Avatar variant="rounded" src="images/switch/BrokerPortal.svg" />
    }
    return (
        <Box p={2} className={classes.box}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>

                <Stack direction={"row"} alignItems={"center"}>
                    {avatarSvg[data?.id] ?? <Avatar variant="rounded" src="images/switch/BrokerPortal.svg" />}
                    {!data?.app &&
                        <Stack sx={{ marginInlineStart: "16px" }}>
                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                <Typography className={classes.title}>{data?.name === "Tenant" ? "Resident" : (data?.name === "Tenant2" ? "Tenant" : data?.name)}</Typography>
                                {
                                    data?.status &&
                                    <Typography className={data?.status === "Got Access" ? classes.gotAccess : classes.pending}>
                                        {data?.status === "Got Access" ? t("Got Access") : t("Pending")}
                                    </Typography>
                                }
                            </Stack>
                            <Typography className={classes.subtitle}><span className={classes.subtitlespan}>{t("RoleName")}: </span>{data?.role?.name ?? (data?.name === "Tenant" ? "Resident" : (data?.name === "Tenant2" ? "Tenant" : data?.name))}</Typography>


                        </Stack>
                    }
                    {
                        data?.app &&
                        <Stack spacing={1}>
                            <Typography className={classes.title}>{data?.management}</Typography>
                            <Typography className={data?.status === "Got Access" ? classes.gotAccess : classes.pending}>

                                {data?.status === "Got Access" ? t("Got Access") : t("Pending")}
                            </Typography>
                        </Stack>
                    }
                </Stack>
                {type === "app" &&
                    isCustomer ?
                    <Button
                        variant="contained"
                        onClick={() => click(data)}
                        disableElevation
                        disabled={isDisableBtn}
                        className={classes.inviteBtn}>
                        {t("Resend")}
                    </Button>
                    :
                    <Button
                        variant="contained"
                        disableElevation
                        disabled={isDisableBtn}
                        onClick={() => click(data)}
                        className={data?.is_invite === false ? classes.revokeBtn : data?.is_invite === true ? classes.inviteBtn : 'Mui-disabled'}>
                        {data?.is_invite === false ? t("Edit") : data?.is_invite === true && t("Invite")}
                    </Button>
                }
            </Stack>
        </Box>
    )
}

export default withTranslation("inviteTrigger")(InviteCard)