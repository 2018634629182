import React, { createContext } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  AlertProps,
  NetWorkCallMethods,
  checkNumber,
  enumSelect,
  enum_types,
} from "../../utils";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { addDays, parseISO, format } from "date-fns";

export const ProjectContext = createContext();

const initialSpecTable = {
  itemType: "",
  itemCategory: "",
  subCategory: "",
  specificationTitle: "",
  itemMaster: "",
  subCategoryError: "",
};

const initialAddUOM = (id) => {
  return {
    rowId: id,
    itemType: "",
    itemCategory: "",
    subCategory: "",
    item: "",
    value: 0,
    uom: "",
    qty: 0,
    totalAmount: 0,
    itemError: "",
    delete: true,
  };
};

const initialState = {
  step: 1,
  menuType: "Property",
  type: {
    selected: "project",
    project: "",
    contractAccount: "",
    contract: "",
    error: {
      project: "",
      contractAccount: "",
      contract: "",
    },
  },
  display_type: "",
  projectTitle: "",
  projectDecs: "",
  category: "",
  subCategory: "",
  plannedStartDate: "",
  plannedEndDate: "",
  plannedHour: "",
  projectOwner: [],
  deletedOwner: [],
  resourceGroup: [],
  deletedResource: [],
  error: {
    projectTitle: "",
    projectDecs: "",
    category: "",
    subCategory: "",
    plannedStartDate: "",
    plannedEndDate: "",
    plannedHour: "",
    projectOwner: "",
    resourceGroup: "",
  },
  step1: false,
  step2: false,
  step3: false,
};

const ProjectContextProvider = (props) => {
  const { t = () => false } = props;
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const [viewPage, setViewPage] = React.useState(false);
  const [createData, setCreateData] = React.useState({ ...initialState });
  const [specificationTable, setSpecificationTable] = React.useState([
    { ...initialSpecTable, rowId: uuidv4() },
  ]);
  const [loading, setLoading] = React.useState(false);
  const [enumValue, setEnumValue] = React.useState({
    inspection_item_type: [],
  });
  const [addUOM, setAddUOM] = React.useState([initialAddUOM(uuidv4())]);
  const [selectedCompany, setSelectedCompany] = React.useState({});
  const [data, setData] = React.useState({
    editSpec: false,
    selectedActualBOM: "",
    selectedValue: 0,
    publishBool: false,
  });
  const [createForm, setCreateForm] = React.useState(false);
  const [openCreateForm, setOpenCreateForm] = React.useState(false);
  const [list, setList] = React.useState({
    data: [],
    count: 0,
  });
  const [viewData, setViewData] = React.useState("");
  const [filterData, setFilterData] = React.useState({ status: [true] });

  const [addTableData, setAddTableData] = React.useState({
    member: [
      {
        name: t("All Resource Group"),
        id: null,
      },
    ],
    property: [],
    block: [],
    unit: [],
  });

  const [addMember, setAddMember] = React.useState({
    selectedMember: [
      {
        name: t("All Resource Group"),
        id: null,
      },
    ],
    projectMember: [],
  });

  const [tableData, setTableData] = React.useState({
    member: {
      data: [],
      count: 0,
    },
    property: {
      data: [],
      count: 0,
    },
  });

  const [specificationEdit, setSpecificationEdit] =
    React.useState(initialSpecTable);
  const [projectedBOMEdit, setProjectBOMEdit] = React.useState(
    initialAddUOM(uuidv4())
  );
  const [progressForm, setProgressForm] = React.useState({
    progressTitle: "",
    progressDate: "",
    images: [],
    property: "",
    block: "",
    delectedAssets: [],
    isView: false,
    desc: "",
    error: {
      progressTitle: "",
      progressDate: "",
      images: [],
      property: "",
      block: "",
      desc: "",
    },
  });
  const [openActualDrawer, setOpenActualDrawer] = React.useState(false);
  const [itemDetails, setItemDetails] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [editFormDisable, setEditFormDisable] = React.useState(false);
  const [disableBtn, setDisableBtn] = React.useState(false);
  const [progressDesc, setProgressDesc] = React.useState({
    data: "",
  });

  const validateProgressForm = (customInsert = false) => {
    let isValid = true;
    let error = progressForm?.error;
    if (!customInsert) {
      if (progressForm?.progressTitle === "") {
        isValid = false;
        error.progressTitle = "Progress Title is Required";
      }
      if (progressForm?.progressDate === "") {
        isValid = false;
        error.progressDate = "Progress Date is Required";
      }
      if (progressForm?.images?.length === 0) {
        isValid = false;
        error.images = "Images is Required";
      }
      if (
        viewData?.type === "Property" ||
        viewData?.type === "Property & Block"
      ) {
        if (progressForm?.property === "") {
          isValid = false;
          error.property = "Property is Required";
        }
        if (viewData?.type === "Property & Block") {
          if (progressForm?.block === "") {
            isValid = false;
            error.block = "Block is Required";
          }
        }
      }

      setProgressForm({
        ...progressForm,
        error: error,
      });
    }

    return isValid;
  };

  const updateProgressForm = (key, value) => {
    setProgressForm({
      ...progressForm,
      [key]: value,
      error: {
        ...progressForm?.error,
        [key]: "",
      },
    });
  };

  const updateAddMember = (key, value) => {
    setAddMember({
      ...addMember,
      [key]: value,
    });
  };

  // React.useEffect(() => {
  //     getProjectList(0, 10, "")
  //     // eslint-disable-next-line
  // }, [])

  const getEnum = async () => {
    const result = await enumSelect([
      enum_types.inspection_item_type,
      enum_types.project_type,
      enum_types?.project_ref_type,
      enum_types?.display_type,
    ]);
    setEnumValue({
      inspection_item_type: result?.inspection_item_type,
      project_type: result?.project_type,
      project_ref_type: result?.project_ref_type,
      display_type: result?.display_type,
    });
    setCreateData({ 
      ...createData,
      display_type: result?.display_type?.[0]?.value,
    });
  };

  const addSpecificationTable = () => {
    setSpecificationTable([
      ...specificationTable,
      { ...initialSpecTable, rowId: uuidv4() },
    ]);
  };

  const updateEditSpec = (key, value) => {
    setSpecificationEdit({
      ...specificationEdit,
      [key]: value,
    });
  };

  const updateEditBOM = (key, value) => {
    if (key === "value") {
      setProjectBOMEdit({
        ...projectedBOMEdit,
        [key]: value,
        totalAmount: checkNumber(value) * checkNumber(projectedBOMEdit?.qty),
      });
    } else if (key === "qty") {
      setProjectBOMEdit({
        ...projectedBOMEdit,
        [key]: value,
        totalAmount: checkNumber(value) * checkNumber(projectedBOMEdit?.value),
      });
    } else {
      setProjectBOMEdit({
        ...projectedBOMEdit,
        [key]: value,
      });
    }
  };

  const updateSpecificationTable = (key, value, item) => {
    let tableRow = specificationTable?.map((obj) => {
      if (obj?.rowId === item?.rowId) {
        if (key === "subCategory") {
          return {
            ...obj,
            [key]: value,
            subCategoryError: "",
          };
        } else if (key === "itemType") {
          return {
            ...obj,
            [key]: value,
            itemCategory: "",
            subCategory: "",
            specificationTitle: "",
            itemMaster: "",
            subCategoryError: "",
          };
        } else if (key === "itemCategory") {
          return {
            ...obj,
            [key]: value,
            subCategory: "",
            specificationTitle: "",
            itemMaster: "",
            subCategoryError: "",
          };
        } else {
          return {
            ...obj,
            [key]: value,
          };
        }
      }
      return obj;
    });
    setSpecificationTable(tableRow);
  };

  const updateProjectedBOM = (key, value, item) => {
    let tableRow = addUOM?.map((obj) => {
      if (obj?.rowId === item?.rowId) {
        if (key === "itemType") {
          return {
            ...obj,
            [key]: value,
            itemCategory: "",
            subCategory: "",
            item: "",
            value: 0,
            uom: "",
            qty: 0,
            totalAmount: 0,
            itemError: "",
          };
        } else if (key === "itemCategory") {
          return {
            ...obj,
            [key]: value,
            subCategory: "",
            item: "",
          };
        } else if (key === "subCategory") {
          return {
            ...obj,
            [key]: value,
            item: "",
          };
        } else if (key === "value") {
          return {
            ...obj,
            [key]: value,
            totalAmount: checkNumber(value) * checkNumber(obj.qty),
          };
        } else if (key === "qty") {
          return {
            ...obj,
            [key]: value,
            totalAmount: checkNumber(value) * checkNumber(obj.value),
          };
        } else if (key === "item") {
          return {
            ...obj,
            [key]: value,
            itemError: "",
          };
        } else {
          return {
            ...obj,
            [key]: value,
          };
        }
      }
      return obj;
    });
    setAddUOM(tableRow);
  };

  const deleteSpecificationTableRow = (data) => {
    setSpecificationTable(
      specificationTable?.filter((i) => i?.rowId !== data?.rowId)
    );
  };

  const addUOMTable = () => {
    setAddUOM([...addUOM, initialAddUOM(uuidv4())]);
  };

  const deleteUOMTableRow = (data) => {
    setAddUOM(addUOM?.filter((i) => i?.rowId !== data?.rowId));
  };

  const updateCreateData = (key, value, typeBool) => {
    if (typeBool) {
      setCreateData({
        ...createData,
        type: {
          ...createData?.type,
          [key]: value,
          error: {
            ...createData?.type?.error,
            [key]: "",
          },
        },
      });
    } else {
      setCreateData({
        ...createData,
        [key]: value,
        error: {
          ...createData?.error,
          [key]: "",
        },
      });
    }
  };

  const validateCreateForm = (data) => {
    let isValid = true;
    let error = createData?.error;
    let typeError = createData?.type?.error;

    if (createData?.step === 1) {
      if (createData?.type?.selected === "project") {
        if (createData?.type?.project === "") {
          typeError.project = t("Project is Required");
          isValid = false;
        }
      } else if (createData?.type?.selected === "contract") {
        if (createData?.type?.contractAccount === "") {
          typeError.contractAccount = t("Contract Account is Required");
          isValid = false;
        }

        if (createData?.type?.contract === "") {
          typeError.contract = t("Contract is Required");
          isValid = false;
        }
      }
      if (createData?.projectTitle === "") {
        isValid = false;
        error.projectTitle = t("Project Title is Required");
      }

      if (createData?.projectDecs === "") {
        isValid = false;
        error.projectDecs = t("Project Description is Required");
      }
      if (createData?.category === "") {
        isValid = false;
        error.category = t("Category is Required");
      }
      if (createData?.subCategory === "") {
        isValid = false;
        error.subCategory = t("Subcategory is Required");
      }
      if (createData?.plannedStartDate === "") {
        isValid = false;
        error.plannedStartDate = t("Planned Start Date is Required");
      }
      if (createData?.plannedEndDate === "") {
        isValid = false;
        error.plannedEndDate = t("Planned End Date is Required");
      }
      if (createData?.plannedHour === "") {
        isValid = false;
        error.plannedHour = t("Planned Hour is Required");
      }
    }

    if (createData?.step === 2) {
      if (createData?.projectOwner === "") {
        isValid = false;
        error.projectOwner = t("Project Owner is Required");
      }

      if (createData?.resourceGroup === "") {
        isValid = false;
        error.resourceGroup = t("Resource Group is Required");
      }
    }

    setCreateData({
      ...createData,
      type: {
        ...createData?.type,
        error: {
          ...createData?.type?.error,
          ...typeError,
        },
      },
      error: {
        ...createData?.error,
        ...error,
      },
    });

    return isValid;
  };

  const getProjectList = (
    offset = 0,
    limit = 10,
    search,
    filter = filterData,
    companyId = selectedCompany?.value
  ) => {
    const payload = {
      company_id: companyId,
      search: search,
      offset: offset,
      limit: limit,
      status: filter?.status,
    };
    NetworkCall(
      `${config.api_url}/project/list`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const constructedData = res?.data?.data?.data?.map((data) => {
          const startDate = data?.planned_start_date
            ? format(
              addDays(parseISO(data?.planned_start_date), 1),
              "yyyy-MM-dd"
            )
            : "-";
          const endDate = data?.planned_end_date
            ? format(addDays(parseISO(data?.planned_end_date), 1), "yyyy-MM-dd")
            : "-";
          return {
            project_id: data?.project_no ?? "-",
            project_type: data?.type ?? "-",
            project_title: data?.title ?? "-",
            project_relation: data?.parent_project?.title,
            start_date: startDate,
            end_date: endDate,
            planned_hrs: data?.planned_hours ?? "-",
            actual_hrs: "",
            project_owner:
              data?.project_members?.length > 0
                ? data?.project_members
                  ?.map((e) => {
                    return e?.first_name;
                  })
                  .join(", ")
                : "-",
            status: data?.is_active ? "Active" : "Inactive",
            ...data,
          };
        });
        setList({
          data: constructedData,
          count: res?.data?.data?.count,
        });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went Wrong"),
        });
        console.log("err", err);
      });
  };

  const upsertProject = (customPayload = {}, customDataBool) => {
    setDisableBtn(true);
    const payload = {
      company_id: selectedCompany?.value,
      display_type: createData?.display_type,
      title: createData?.projectTitle,
      type: createData?.menuType,
      ref_type: createData?.type?.selected,
      contract_id: createData?.type?.contract?.value,
      project_category_id: createData?.category?.value,
      project_subcategory_id: createData?.subCategory?.value,
      description: createData?.projectDecs,
      planned_start_date: createData?.plannedStartDate,
      planned_end_date: createData?.plannedEndDate,
      planned_hours: createData?.plannedHour,
      is_active: true,
      parent_project_id: createData?.type?.project?.value,
      contact_details: [
        ...createData?.projectOwner?.map((e) => {
          return {
            contact_id: e?.value,
            id: e?.id,
            is_active: e?.is_active,
          };
        }),
        ...createData?.deletedOwner?.map((e) => {
          return {
            contact_id: e?.value,
            id: e?.id,
            is_active: e?.is_active,
          };
        }),
      ],
      resource_group_details: [
        ...createData?.resourceGroup?.map((e) => {
          return {
            resource_group_master_id: e?.value,
            id: e?.id,
            is_active: e?.is_active,
          };
        }),
        ...createData?.deletedResource?.map((e) => {
          return {
            resource_group_master_id: e?.value,
            id: e?.id,
            is_active: e?.is_active,
          };
        }),
      ],
      id: createData?.id,
    };

    NetworkCall(
      `${config.api_url}/project/upsert`,
      NetWorkCallMethods.post,
      customDataBool ? customPayload : payload,
      null,
      true,
      false
    )
      .then((res) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t(res?.data?.data),
        });
        setCreateForm(false);
        setCreateData({ ...initialState });
        setViewPage(false);
        getProjectList(0, 10, "", filterData, selectedCompany?.value);
        handleCloseForm(0);
        setDisableBtn(false);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went Wrong"),
        });
        console.log("err", err);
        setDisableBtn(false);
      });
  };

  const publishProject = () => {
    const payload = {
      id: viewData?.id,
      is_draft: false,
    };
    upsertProject(payload, true);
  };

  const getProjectDetail = (id, view = false) => {
    // project/get
    const payload = {
      project_id: id,
    };
    NetworkCall(
      `${config.api_url}/project/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then(async (res) => {
        if (view) {
          await setViewData(res?.data?.data);
          await setViewPage(true);
        } else {
          const resData = res?.data?.data;
          setCreateData({
            step: 1,
            id: resData?.id,
            menuType: resData?.type,
            type: {
              selected: resData?.ref_type,
              project: {
                label: resData?.parent_project?.title,
                value: resData?.parent_project?.id,
                ...resData?.parent_project,
              },
              contractAccount: {
                ...resData?.contract?.account,
                subTitle1: resData?.contract?.account?.account_no,
              },
              contract: {
                ...resData?.contract,
                label: resData?.contract?.contract_no,
                value: resData?.contract?.id,
                subTitle1: resData?.contract?.type_name,
              },
              error: {
                project: "",
                contractAccount: "",
                contract: "",
              },
            },
            display_type: resData?.display_type,
            projectTitle: resData?.title,
            projectDecs: resData?.description,
            category: {
              label: resData?.category?.name,
              value: resData?.category?.id,
              subTitle1: resData?.category?.reference_no,
            },
            subCategory: {
              label: resData?.sub_category?.name,
              value: resData?.sub_category?.id,
              subTitle1: resData?.sub_category?.reference_no,
            },
            plannedStartDate: new Date(resData?.planned_start_date),
            plannedEndDate: new Date(resData?.planned_end_date),
            plannedHour: resData?.planned_hours,
            projectOwner: resData?.project_members?.map((e) => {
              return {
                label: e?.first_name,
                value: e?.contact_id,
                id: e?.id,
                url: e?.image_url,
                subTitle1: e?.relationship ?? "-",
                subTitle2: e?.job_title ?? "-",
                ...e,
              };
            }),
            deletedOwner: [],
            resourceGroup: resData?.project_resource_group?.map((e) => {
              return {
                label: e?.name,
                value: e?.resource_grp_id,
                id: e?.id,
                url: e?.image_url,
                subTitle1: `${e?.resource_count ?? 0} Members`,
                ...e,
              };
            }),
            deletedResource: [],
            error: {
              projectTitle: "",
              projectDecs: "",
              category: "",
              subCategory: "",
              plannedStartDate: "",
              plannedEndDate: "",
              plannedHour: "",
              projectOwner: "",
              resourceGroup: "",
            },
            step1: false,
            step2: false,
            step3: false,
            edit: true,
          });
          setCreateForm(true);
        }
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went Wrong"),
        });
        console.log("err", err);
      });
  };

  const upsertProjectMemeber = (project_id, customData = []) => {
    // setLoading(true)
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: t("Loading"),
    });
    const constructedPayload = addMember?.projectMember?.map((e) => {
      return {
        id: e?.id ?? undefined,
        project_id: project_id,
        resource_id: e?.resource_id,
        contact_id: e?.contact_id,
        is_follower: e?.follower,
        is_member: e?.member,
      };
    });
    const payload = {
      insert_params: customData?.length > 0 ? customData : constructedPayload,
    };
    NetworkCall(
      `${config.api_url}/project_member/upsert`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        // setLoading(false)
        getProjectMember(project_id, 0, 10, "", [], false);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went Wrong"),
        });
        console.log("err", err);
        // setLoading(false)
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading"),
        });
      });
  };

  const getProjectMember = (
    id,
    offset,
    limit,
    search,
    grpId = [],
    initial = false
  ) => {
    // setLoading(true)
    const payload = {
      offset: offset,
      limit: limit,
      search: search,
      project_id: id,
      group_id:
        grpId?.length > 0
          ? grpId
          : addTableData?.member
            ?.map((e) => {
              return e?.id;
            })
            ?.filter((i) => i !== null),
    };
    NetworkCall(
      `${config.api_url}/project_member/list`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const constructedRow = res?.data?.data?.data?.map((e) => {
          return {
            ...e,
            card: {
              title: e?.resource_name ?? "-",
              subTitle1: e?.reference_id ?? "-",
              subTitle2: e?.profession_name ?? "-",
            },
            follower: e?.is_follower ?? false,
            member: e?.is_member ?? false,
          };
        });
        setTableData({
          ...tableData,
          member: {
            data: constructedRow,
            count: res?.data?.data?.count,
          },
        });
        handleCloseForm(0);
        // if (!initial) {
        //     handleCloseForm(0)
        // }
        // setLoading(false)
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading"),
        });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went Wrong"),
        });
        // setLoading(false)
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading"),
        });
        console.log("err", err);
      });
  };

  const upsertProjectProperty = (project_id, data = [], update = false) => {
    const constructedPayload = (update ? data : addTableData?.property)?.map(
      (e) => {
        return {
          id: e?.id ?? undefined,
          project_id: project_id ?? undefined,
          property_id: e?.value,
          is_active: update ? false : true,
        };
      }
    );
    const payload = {
      insert_params: constructedPayload,
    };
    NetworkCall(
      `${config.api_url}/project_properties/upsert`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        getProjectProperty(project_id, 0, 10, "");
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went Wrong"),
        });
        console.log("err", err);
      });
  };

  const getProjectProperty = (id, offset, limit, search) => {
    const payload = {
      offset: offset,
      limit: limit,
      search: search,
      project_id: id,
    };
    NetworkCall(
      `${config.api_url}/project_properties/list`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const constructedRow = res?.data?.data?.map((e) => {
          return {
            ...e,
            property_name: e?.name,
            location: `${e?.area ?? ""} ${e?.area && ","} ${e?.city ?? ""}${e?.city && ","
              } ${e?.state ?? ""}`,
            image: e?.logo,
          };
        });
        setTableData({
          ...tableData,
          property: {
            data: constructedRow,
            count: res?.data?.count,
          },
        });
        handleCloseForm(1);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went Wrong"),
        });
        // setLoading(false)
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading"),
        });
        console.log("err", err);
      });
  };

  const upsertProjectBlock = (projectId, customData = []) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: t("Loading"),
    });
    let constructedPayload = [];
    if (customData?.length === 0) {
      constructedPayload = addTableData?.block?.map((data) => {
        return {
          id: data?.id,
          project_id: projectId,
          block_id: data?.value,
        };
      });
    }

    const payload = {
      insert_params: customData?.length > 0 ? customData : constructedPayload,
    };

    NetworkCall(
      `${config.api_url}/project_block/upsert`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        getProjectBlock(projectId, 0, 10, "");
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went Wrong"),
        });
        console.log("err", err);
      });
  };

  const getProjectBlock = (id, offset, limit, search) => {
    const payload = {
      offset: offset,
      limit: limit,
      search: search,
      project_id: id,
    };
    NetworkCall(
      `${config.api_url}/project_block/list`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const constructedRow = res?.data?.data?.data?.map((e) => {
          return {
            ...e,
            block_name: e?.name,
            property_name: e?.property_name,
            location: `${e?.area ?? ""} ${e?.area && ","} ${e?.city ?? ""}${e?.city && ","
              } ${e?.state ?? ""}`,
            image: e?.logo,
          };
        });

        setTableData({
          ...tableData,
          block: {
            data: constructedRow,
            count: res?.data?.data?.count,
          },
        });
        // setData({
        //     ...data,
        //     editSpec: false
        // })
        handleCloseForm(2);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went Wrong"),
        });
        // setLoading(false)
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading"),
        });
        console.log("err", err);
      });
  };

  const upsertProjectUnit = (projectId, customData = []) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: t("Loading"),
    });
    let constructedPayload = [];
    if (customData?.length === 0) {
      constructedPayload = addTableData?.unit?.map((data) => {
        return {
          id: data?.id,
          project_id: projectId,
          unit_id: data?.value,
        };
      });
    }

    const payload = {
      insert_params: customData?.length > 0 ? customData : constructedPayload,
    };

    NetworkCall(
      `${config.api_url}/project_unit/upsert`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        getProjectUnit(projectId, 0, 10, "");
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went Wrong"),
        });
        console.log("err", err);
      });
  };

  const getProjectUnit = (id, offset, limit, search) => {
    const payload = {
      offset: offset,
      limit: limit,
      search: search,
      project_id: id,
    };
    NetworkCall(
      `${config.api_url}/project_unit/list`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const constructedRow = res?.data?.data?.data?.map((e) => {
          return {
            ...e,
            unit_name: e?.name,
            property_name: e?.property_name,
            location: `${e?.area ?? ""} ${e?.area && ","} ${e?.city ?? ""}${e?.city && ","
              } ${e?.state ?? ""}`,
            image: e?.logo,
          };
        });

        setTableData({
          ...tableData,
          unit: {
            data: constructedRow,
            count: res?.data?.data?.count,
          },
        });
        // setData({
        //     ...data,
        //     editSpec: false
        // })
        handleCloseForm(3);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went Wrong"),
        });
        // setLoading(false)
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading"),
        });
        console.log("err", err);
      });
  };

  const validateSpecificationTable = (valid) => {
    let isValid = true;
    if (!valid) {
      specificationTable?.forEach((item) => {
        if (item?.subCategory === "") {
          item.subCategoryError = "Item Master is Required";
          isValid = false;
        } else {
          item.subCategoryError = "";
          isValid = true;
        }
      });
      setSpecificationTable(specificationTable);
    }
    return isValid;
  };
  const upsertProjectSpecification = (
    projectId,
    customData = [],
    isValid = false
  ) => {
    if (validateSpecificationTable(isValid)) {
      setLoading(true);
      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: t("Loading"),
      });
      const constructedPayload = specificationTable?.map((data) => {
        return {
          // inspection_items_id: data?.itemMaster?.value,
          id: data?.id,
          project_id: projectId,
          title: data?.specificationTitle,
          inspection_item_subcategory_id: data?.subCategory?.value,
        };
      });

      const payload = {
        insert_params: customData?.length > 0 ? customData : constructedPayload,
      };

      NetworkCall(
        `${config.api_url}/project_specification/upsert`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((res) => {
          getProjectSpecification(projectId, 0, 10, "");
          setEditFormDisable(false);
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Something went Wrong"),
          });
          console.log("err", err);
          setLoading(false);
        });
    }
  };

  const getProjectSpecification = (id, offset, limit, search) => {
    const payload = {
      offset: offset,
      limit: limit,
      search: search,
      project_id: id,
    };
    NetworkCall(
      `${config.api_url}/project_specification/list`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const constructedRow = res?.data?.data?.data?.map((e) => {
          return {
            itemType: e?.category?.item_type,
            itemCategory: e?.category?.name,
            subCategory: e?.subCategory_name,
            itemMaster: e?.name,
            id: e?.specification_id,
            specificationTitle: e?.specification
              ? JSON.parse(e?.specification)
              : "-",
            ...e,
          };
        });

        setTableData({
          ...tableData,
          specification: {
            data: constructedRow,
            count: res?.data?.data?.count,
          },
        });
        handleCloseForm(4);
        setLoading(false);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went Wrong"),
        });
        // setLoading(false)
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading"),
        });
        console.log("err", err);
        setLoading(false);
      });
  };

  const validateProjectedBOM = (valid) => {
    let isValid = true;
    if (!valid) {
      addUOM?.forEach((item) => {
        if (item?.item === "") {
          item.itemError = "Item is Required";
          isValid = false;
        } else {
          item.itemError = "";
          isValid = true;
        }
      });
      setAddUOM(addUOM);
    }
    return isValid;
  };

  const upsertProjectedBOM = (projectId, customData = [], isValid = false) => {
    if (validateProjectedBOM(isValid)) {
      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: t("Loading"),
      });
      const constructedPayload = addUOM?.map((data) => {
        return {
          inspection_items_id: data?.item?.value,
          value: data?.value,
          qty: data?.qty,
          total_value: data?.totalAmount,
          currency_id: selectedCompany?.currency?.value,
          project_id: projectId,
        };
      });

      const payload = {
        insert_params: customData?.length > 0 ? customData : constructedPayload,
      };

      NetworkCall(
        `${config.api_url}/projected_bom/upsert`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((res) => {
          getProjectedBOM(projectId, 0, 10, "");
          setEditFormDisable(false);
          setData({
            ...data,
            editSpec: false,
          });
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Something went Wrong"),
          });
          console.log("err", err);
          setEditFormDisable(false);
        });
    } else {
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "Loading",
      });
    }
  };

  const getProjectedBOM = (id, offset, limit, search) => {
    const payload = {
      offset: offset,
      limit: limit,
      search: search,
      project_id: id,
    };
    NetworkCall(
      `${config.api_url}/projected_bom/list`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const constructedRow = res?.data?.data?.data?.map((e) => {
          return {
            ref_no: e?.reference_no,
            value_text: `${e?.value} ${e?.symbol}`,
            total_amount: `${e?.total_value} ${e?.symbol}`,
            itemCategory: e?.category?.name,
            subCategory: e?.subcategory?.name,
            specificationTitle: "",
            itemMaster: e?.name,
            id: e?.id,
            qty: e?.qty,
            ...e,
          };
        });

        setTableData({
          ...tableData,
          projectedBOM: {
            data: constructedRow,
            count: res?.data?.data?.count,
          },
        });
        // setData({
        //     ...data,
        //     editSpec: false
        // })
        handleCloseForm(5);
        setEditFormDisable(false);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading"),
        });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went Wrong"),
        });
        // setLoading(false)
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading"),
        });
        setEditFormDisable(false);
        console.log("err", err);
      });
  };

  const upsertProgressForm = (projectId, customPayload, customInsert) => {
    if (validateProgressForm(customInsert)) {
      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: t("Loading"),
      });
      const delectedImages = progressForm?.delectedAssets
        ?.filter((i) => i?.id?.length > 0)
        ?.map((e) => {
          return {
            id: e?.id,
            is_active: false,
          };
        });

      const constructedImage = progressForm?.images?.map((e) => {
        return {
          id: e?.id,
          asset_url: e?.url,
          meta_data: e?.file_meta,
        };
      });

      const payload = {
        id: progressForm?.id,
        project_id: projectId,
        property_id: progressForm?.property?.value,
        progress_title: progressForm?.progressTitle,
        progress_date: moment(progressForm?.progressDate).format("YYYY-MM-DD"),
        block_id: progressForm?.block?.value,
        description: progressDesc?.data,
        assets: [...constructedImage, ...delectedImages],
      };
      NetworkCall(
        `${config.api_url}/project_progress/upsert`,
        NetWorkCallMethods.post,
        customInsert ? customPayload : payload,
        null,
        true,
        false
      )
        .then((res) => {
          getProgressForm(projectId, 0, 10, "");
          handleCloseForm(6);
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Something went Wrong"),
          });
          console.log("err", err);
        });
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Please fill all mandatory fields"),
      });
    }
  };

  const getProgressForm = (id, offset, limit, search) => {
    const payload = {
      offset: offset,
      limit: limit,
      search: search,
      project_id: id,
    };
    NetworkCall(
      `${config.api_url}/project_progress/list`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const constructedRow = res?.data?.data?.data?.map((e) => {
          return {
            progress_title: e?.progress_title ?? "-",
            updated_on: new Date(e?.created_at),
            attachment: e?.images?.[0]?.count ?? 0,
            is_active: true,
            ...e,
          };
        });

        setTableData({
          ...tableData,
          progressForm: {
            data: constructedRow,
            count: res?.data?.data?.count,
          },
        });
        handleCloseForm(6);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went Wrong"),
        });
        setLoading(false);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading"),
        });
        console.log("err", err);
      });
  };

  const getProgressFormRow = (id, view = false) => {
    const payload = {
      id: id,
    };
    NetworkCall(
      `${config.api_url}/project_progress/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setOpenCreateForm(true);
        const resData = res?.data?.data;
        setProgressForm({
          ...progressForm,
          id: resData?.id,
          progressTitle: resData?.progress_title,
          progressDate: new Date(resData?.progress_date),
          // desc: desc?.length > 0 ? JSON.parse(desc) : "",
          images: resData?.images?.map((e) => {
            return {
              ...e,
              url: e?.asset_url,
              file_meta: e?.meta_data,
              rowId: uuidv4(),
            };
          }),
          property: {
            ...resData?.property,
            label: resData?.property?.name,
            value: resData?.property?.id,
          },
          block: {
            ...resData?.block,
            label: resData?.block?.name,
            value: resData?.block?.id,
          },
          isView: view,
        });
        setProgressDesc({
          data: resData?.description ? JSON.parse(resData?.description) : "",
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading"),
        });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went Wrong"),
        });
        // setLoading(false)
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading"),
        });
        console.log("err", err);
      });
  };

  const getProjectMaintanceReq = (projectId, offset, limit, search) => {
    // setLoading(true)
    const payload = {
      project_id: projectId,
      company_id: [selectedCompany?.value],
      offset,
      limit,
      search,
    };
    NetworkCall(
      `${config.api_url}/project/maintenance_request/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const constructedRow = res?.data?.data?.data?.map((val) => {
          return {
            id: val?.id,
            requestName: val?.subject ?? "-",
            request_no: val?.request_no ?? "-",
            category: val?.category ?? "-",
            raisedDate: val?.raised_on ?? "-",
            closed_on: val?.closed_on ?? "-",
            property_name: val?.property_name ?? "-",
            unit: val?.units?.name ?? "",
            status:
              val?.request_status === "Closed"
                ? "Resolved"
                : val?.request_status ?? "-",
            contact_number:
              val?.mobile_country_code !== null
                ? val?.mobile_country_code + val?.contact_mobile
                : val?.contact_mobile ?? "-",
            icon:
              val?.request_status === "Closed" ||
                val?.request_status === "Cancelled"
                ? ""
                : "edit",
            assignedTo: "-",
            ...val,
          };
        });
        setTableData({
          ...tableData,
          maintanceReq: {
            data: constructedRow,
            count: res?.data?.data?.totalCount,
          },
        });
        // setLoading(false)
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading"),
        });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went Wrong"),
        });
        console.log("err", err);
        // setLoading(false)
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading"),
        });
      });
  };

  const getProjectServiceJob = (projectId, offset, limit, search) => {
    // setLoading(true)
    const payload = {
      project_id: projectId,
      company_id: [selectedCompany?.value],
      offset,
      limit,
      search,
    };
    NetworkCall(
      `${config.api_url}/project/service_job/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const constructedRow = res?.data?.data?.data?.map((val) => {
          return {
            hours:
              val?.eta > 0 ? `${val?.eta} ${val?.eta > 1 ? "hrs" : "hr"}` : 0,
            other_hours:
              val?.other_hours > 0
                ? `${val?.other_hours} ${val?.other_hours > 1 ? "hrs" : "hr"}`
                : 0,
            KPI:
              val?.overbooking_count > 0
                ? "Delayed"
                : val?.ontime_count > 0
                  ? "Ontime"
                  : "-",
            actual_hours:
              val?.actual_hours !== null
                ? val?.actual_hours > 0
                  ? `${val?.actual_hours} ${val?.actual_hours > 1 ? "hrs" : "hr"
                  }`
                  : "<1 hr"
                : "-",
            ...val,
          };
        });
        setTableData({
          ...tableData,
          serviceJob: {
            data: constructedRow,
            count: res?.data?.data?.count,
          },
        });
        // setLoading(false)
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading"),
        });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went Wrong"),
        });
        console.log("err", err);
        // setLoading(false)
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading"),
        });
      });
  };

  const getProjectActualBOM = (
    projectId,
    offset = 0,
    limit = 10,
    search = ""
  ) => {
    // setLoading(true)
    const payload = {
      company_id: [selectedCompany?.value],
      offset: offset,
      limit: limit,
      search: search,
      bom_type: "Actual",
      project_id: projectId,
    };
    NetworkCall(
      `${config.api_url}/project/bom/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const constructedRow = res?.data?.data?.data?.filter((val) => {
          if (val?.costing_status_request.length > 0)
            return val?.costing_status_request[0]?.status === "Approved";
        })?.map((val) => {
          return {
            ...val,
            total_amount: `${val?.total_amount_after_tax ?? 0} ${val?.symbol}`,
            request_id:
              val?.maintenance_request_no ??
              val?.general_request_no ??
              val?.agreement_no ??
              "-",
            status: (val?.schedule?.length > 0) ? (val?.schedule?.[0]?.status === "Unposted" || val?.schedule?.[0]?.payment_status !== "no-due") ? "Unpaid" : "Paid" : "Unpaid",
            source: "Maintenance",
          };
        });
        setTableData({
          ...tableData,
          actualBOM: {
            data: constructedRow,
            count: res?.data?.data?.count,
          },
        });
        // setLoading(false)
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading"),
        });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went Wrong"),
        });
        console.log("err", err);
        // setLoading(false)
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading"),
        });
      });
  };

  const getCostingDetails = (value) => {
    setData({
      ...data,
      selectedActualBOM: value,
    });
    const payload = {
      request_id: value?.id,
      type: value?.source,
      bom_type: "Actual",
    };

    NetworkCall(
      `${config.api_url}/request_costing/get_costing`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then(async (response) => {
        setOpenActualDrawer(true);
        await setItemDetails(response?.data?.data?.[0]?.costing?.[0]);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went Wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };

  const getProjectInvoice = (
    projectId,
    offset = 0,
    limit = 10,
    search = ""
  ) => {
    // setLoading(true)
    const payload = {
      company_id: [selectedCompany?.value],
      offset: offset,
      limit: limit,
      search: search,
      project_id: projectId,
    };
    NetworkCall(
      `${config.api_url}/project/invoice/get`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const constructedRow = res?.data?.data?.data?.map((val) => {
          return {
            ...val,
            invoice_no: val?.invoice_no,
            invoice_type: val?.invoice_type,
            agreement_no: val?.agreement?.agreement_no,
            account_id: val?.account_no,
            invoice_amount: `${val?.invoice_total_amount} ${selectedCompany?.currency_symbol}`,
            date_created: new Date(val?.created_at),
            due_date: new Date(val?.payment_due_date),
            status: val?.payment_status,
            balance: `${val?.invoice_due_amount} ${selectedCompany?.currency_symbol}`,
          };
        });
        setTableData({
          ...tableData,
          invoice: {
            data: constructedRow,
            count: res?.data?.data?.count,
          },
        });
        // setLoading(false)
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading"),
        });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went Wrong"),
        });
        console.log("err", err);
        // setLoading(false)
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading"),
        });
      });
  };

  const handleTabSwitch = (value) => {
    setSearchText("");
    switch (value) {
      case 0:
        getProjectMember(viewData?.id, 0, 10, "");
        break;
      case 1:
        getProjectProperty(viewData?.id, 0, 10, "");
        break;
      case 2:
        getProjectBlock(viewData?.id, 0, 10, "");
        break;
      case 3:
        getProjectUnit(viewData?.id, 0, 10, "");
        break;
      case 4:
        getProjectSpecification(viewData?.id, 0, 10, "");
        break;
      case 5:
        getProjectedBOM(viewData?.id, 0, 10, "");
        break;
      case 6:
        getProgressForm(viewData?.id, 0, 10, "");
        break;
      case 7:
        getProjectMaintanceReq(viewData?.id, 0, 10, "");
        break;
      case 8:
        getProjectServiceJob(viewData?.id, 0, 10, "");
        break;
      case 9:
        getProjectActualBOM(viewData?.id, 0, 10, "");
        break;
      case 10:
        getProjectInvoice(viewData?.id, 0, 10, "");
        break;
      default:
        break;
    }
  };

  const handleTableIconfunc = async (
    type,
    data,
    boolCheckBox,
    checkBoxId,
    value
  ) => {
    if (value === 0) {
      if (type === "customClose") {
        const constructedPayload = [
          {
            id: data?.id,
            is_active: false,
            project_id: viewData?.id,
          },
        ];
        upsertProjectMemeber(viewData?.id, constructedPayload);
      } else if (type === "customCheckBox") {
        if (checkBoxId === "follower") {
          const constructedPayload = [
            {
              id: data?.id,
              project_id: viewData?.id,
              is_follower: boolCheckBox,
            },
          ];
          upsertProjectMemeber(viewData?.id, constructedPayload);
        } else if (checkBoxId === "member") {
          const constructedPayload = [
            {
              id: data?.id,
              project_id: viewData?.id,
              is_member: boolCheckBox,
            },
          ];
          upsertProjectMemeber(viewData?.id, constructedPayload);
        }
      }
    } else if (value === 1) {
      if (type === "delete") {
        const payload = [
          {
            id: data?.id,
            is_active: false,
          },
        ];
        upsertProjectProperty(viewData?.id, payload, true);
      }
    } else if (value === 2) {
      if (type === "delete") {
        const payload = [
          {
            id: data?.id,
            is_active: false,
          },
        ];
        upsertProjectBlock(viewData?.id, payload);
      }
    } else if (value === 3) {
      if (type === "delete") {
        const payload = [
          {
            id: data?.id,
            is_active: false,
          },
        ];
        upsertProjectUnit(viewData?.id, payload);
      }
    } else if (value === 4) {
      if (type === "edit") {
        setSpecificationEdit({
          itemType: {
            label: data?.category?.item_type,
            value: data?.category?.item_type,
          },
          itemCategory: {
            label: data?.category?.name,
            value: data?.category?.id,
          },
          subCategory: {
            label: data?.subCategory_name,
            value: data?.subCategory_id,
          },
          specificationTitle: data?.specification
            ? JSON.parse(data?.specification)
            : "-",
          // itemMaster: { label: data?.name, value: data?.inspection_id },
          id: data?.specification_id,
          // ...data
        });
        await setData({
          editSpec: true,
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading"),
        });
      }
    } else if (value === 5) {
      if (type === "edit") {
        setProjectBOMEdit({
          ...projectedBOMEdit,
          itemType: { label: data?.item, value: data?.item },
          itemCategory: {
            label: data?.category?.name,
            value: data?.category?.id,
          },
          subCategory: {
            label: data?.subcategory?.name,
            value: data?.subcategory?.id,
          },
          item: { label: data?.name, value: data?.inspection_id },
          value_text: data?.value ?? 0,
          value: data?.value ?? 0,
          uom: "",
          qty: data?.qty ?? 0,
          totalAmount: data?.total_value,
          id: data?.uom_id,
        });
        await setData({
          editSpec: true,
          type: "bom",
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: t("Loading"),
        });
      }
    } else if (value === 6) {
      if (type === "view_more" || type === "double_click") {
        getProgressFormRow(data?.id, true, data?.description);
      } else if (type === "edit") {
        getProgressFormRow(data?.id, false, data?.description);
      } else if (type === "active") {
        const payload = {
          id: data?.id,
          is_active: false,
        };
        upsertProgressForm(viewData?.id, payload, true);
      }
    } else if (value === 9) {
      if (type === "double_click") {
        getCostingDetails(data);
      }
    }
  };

  const handleCloseForm = (value) => {
    setOpenCreateForm(false);
    // setLoading(false)
    backdrop.setBackDrop({
      ...backdrop,
      open: false,
      message: t("Loading"),
    });
    if (value === 4 || value === 5) {
      setData({
        ...data,
        editSpec: false,
        selectedValue: value,
      });
    } else {
      setData({
        ...data,
        selectedValue: value,
      });
    }
    switch (value) {
      case 0:
        setAddMember({
          ...addMember,
          selectedMember: [
            {
              name: t("All Resource Group"),
              id: null,
            },
          ],
          projectMember: [],
        });
        setAddTableData({
          ...addTableData,
          member: [
            {
              name: t("All Resource Group"),
              id: null,
            },
          ],
        });
        break;
      case 1:
        setAddTableData({
          ...addTableData,
          property: [],
          block: [],
          unit: [],
        });
        break;
      case 2:
        setAddTableData({
          ...addTableData,
          property: [],
          block: [],
          unit: [],
        });
        break;
      case 3:
        setAddTableData({
          ...addTableData,
          property: [],
          block: [],
          unit: [],
        });
        break;
      case 4:
        setSpecificationTable([{ ...initialSpecTable, rowId: uuidv4() }]);
        break;
      case 5:
        setData({
          editSpec: false,
          type: "",
        });
        setAddUOM([initialAddUOM(uuidv4())]);
        break;
      case 6:
        setProgressForm({
          progressTitle: "",
          progressDate: "",
          images: [],
          property: "",
          block: "",
          delectedAssets: [],
          isView: false,
          error: {
            progressTitle: "",
            progressDate: "",
            images: [],
            property: "",
            block: "",
          },
        });
        setProgressDesc({
          data: "",
        });
        break;
      default:
        break;
    }
  };

  const handleTable = (offset = 0, limit = 10, text = "", value) => {
    switch (value) {
      case 0:
        getProjectMember(viewData?.id, offset, limit, text);
        break;
      case 1:
        getProjectProperty(viewData?.id, offset, limit, text);
        break;
      case 2:
        getProjectBlock(viewData?.id, offset, limit, text);
        break;
      case 3:
        getProjectUnit(viewData?.id, offset, limit, text);
        break;
      case 4:
        getProjectSpecification(viewData?.id, offset, limit, text);
        break;
      case 5:
        getProjectedBOM(viewData?.id, offset, limit, text);
        break;
      case 6:
        getProgressForm(viewData?.id, offset, limit, text);
        break;
      case 7:
        getProjectMaintanceReq(viewData?.id, offset, limit, text);
        break;
      case 8:
        getProjectServiceJob(viewData?.id, offset, limit, text);
        break;
      case 9:
        getProjectActualBOM(viewData?.id, offset, limit, text);
        break;
      case 10:
        getProjectInvoice(viewData?.id, offset, limit, text);
        break;
      default:
        break;
    }
  };

  return (
    <ProjectContext.Provider
      value={{
        createData,
        setCreateData,
        viewPage,
        setViewPage,
        specificationTable,
        setSpecificationTable,
        enumValue,
        getEnum,
        addSpecificationTable,
        deleteSpecificationTableRow,
        addUOM,
        setAddUOM,
        addUOMTable,
        deleteUOMTableRow,
        updateCreateData,
        selectedCompany,
        setSelectedCompany,
        validateCreateForm,
        initialState,
        upsertProject,
        createForm,
        setCreateForm,
        getProjectList,
        list,
        setList,
        getProjectDetail,
        filterData,
        setFilterData,
        viewData,
        addMember,
        setAddMember,
        updateAddMember,
        upsertProjectMemeber,
        getProjectMember,
        tableData,
        addTableData,
        setAddTableData,
        upsertProjectProperty,
        handleTabSwitch,
        handleTableIconfunc,
        updateSpecificationTable,
        upsertProjectSpecification,
        data,
        setData,
        specificationEdit,
        setSpecificationEdit,
        updateEditSpec,
        updateProjectedBOM,
        upsertProjectedBOM,
        upsertProjectBlock,
        upsertProjectUnit,
        updateProgressForm,
        progressForm,
        setProgressForm,
        upsertProgressForm,
        openCreateForm,
        setOpenCreateForm,
        handleCloseForm,
        itemDetails,
        openActualDrawer,
        setOpenActualDrawer,
        projectedBOMEdit,
        updateEditBOM,
        searchText,
        setSearchText,
        handleTable,
        loading,
        setLoading,
        publishProject,
        editFormDisable,
        setEditFormDisable,
        disableBtn,
        setDisableBtn,
        progressDesc,
        setProgressDesc,
      }}
    >
      {props.children}
    </ProjectContext.Provider>
  );
};

export default withTranslation("project")(ProjectContextProvider);
