import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../utils";

export const reservationStyle = makeStyles((theme) => ({
  reservationNo: {
    color: theme.palette?.background?.reservation,
    fontSize:"0.875rem",
    fontFamily: FontFamilySwitch().bold,
  },
  badge: {
    backgroundColor: theme.palette?.success?.main,
    borderRadius: "2px",
    padding: "2px 6px ",
    marginInlineStart: "10px",
    color: "white",
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().bold,
  },
  date: {
    color: theme.typography?.color?.secondary,
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  cancelBtn: {
    border: `1px solid ${theme.palette?.error?.main}`,
    color: theme.palette?.error?.main,
    fontFamily: FontFamilySwitch().semiBold,
    fontSize:"0.75rem",
    backgroundColor: theme.palette?.primary?.contrastText,
    "&:hover": {
      backgroundColor: theme.palette?.primary?.contrastText,
      border: `1px solid ${theme.palette?.error?.main}`,
      color: theme.palette?.error?.main,
    },
  },
  saveBtn: {
    border: `1px solid ${theme.typography?.status?.primary}`,
    color:theme.typography?.status?.primary,
    fontFamily: FontFamilySwitch().semiBold,
    fontSize:"0.75rem",
    backgroundColor: theme.palette?.primary?.contrastText,
    "&:hover": {
      backgroundColor: theme.palette?.primary?.contrastText,
      border: `1px solid ${theme.typography?.status?.primary}`,
      color: theme.typography?.status?.primary,
    },
    marginInlineStart:"8px"
  },
  btnParent: {
    backgroundColor: theme.palette?.primary?.contrastText,
    padding: "12px",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
  },
  detailsBox: {
    backgroundColor: theme.palette?.background?.secondary,
    padding: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  parentBox: {
    border: `1px solid ${theme.palette?.border?.secondary}`,
    borderRadius: "4px",
  },
}));
