import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import ShowMoreText from "react-show-more-text";
import {
  CommontsCard,
  CommunityCardComponent,
  DialogBox,
  FilterGenerator,
  Skeletons,
  Subheader
} from "../../../components";
import { config } from "../../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { getCompanyOption, stringAvatar } from "../../../utils";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";
import { AlertProps, NetWorkCallMethods } from "../../../utils/constants";
import { FontFamilySwitch } from "../../../utils/constants";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme, props) => ({
  root: {
    borderRadius: "4px",
    background: "#F5F7FA",
    boxShadow: "0px 0px 16px #00000014",
    margin: '18px 10px 16px 10px',
    // height: `calc(100vh - 147px)`,
  },
  drawerwidth: {
    width: "444px",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "318px",
    },
  },
  popupbtn: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: "35px",
    justifyContent: "space-between",
    "& .MuiButton-root": {
      width: "95%",
      margin: "0 auto",
    },
  },
  modaltitle: {
    fontSize:"1rem",
    color: "#091B29",
    fontFamily: FontFamilySwitch().semiBold,
    fontWeight: 600,
  },
  createnewsection: {
    padding: "30px 17px",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      textAlign: "center",
      // padding:'80px 17px 30px 0px',
      overflow: "hidden",
    },
  },
  modalcontant: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      textAlign: "center",
    },
  },
  grid: {
    overflow: "hidden",
    height: `calc(100vh - 141px)`,
    margin: "0px 10px",
  },
  grid1: {
    overflow: "auto",
    height: `calc(100vh - 141px)`,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.palette.borderRadius
  },
  card: {
    margin: "10px 5px",
  },
  tittle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 10px 10px",
    position: "sticky",
    "& .MuiTypography-root": {
      color: theme.typography.color.primary,
      fontFamily: FontFamilySwitch().bold,
      fontSize:"0.875rem",
    },
  },
  tittle1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 20px 10px",
    position: "sticky",
    borderBottom: "1px solid #CED3DD",
    "& .MuiTypography-root": {
      color: theme.typography.color.primary,
      fontFamily: FontFamilySwitch().bold,
      fontSize:"0.875rem",
    },
  },
  Header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "14px",
    borderBottom: "1px solid #00000012",
    position: "absolute",
    width: "100%",
    top: "0",
    height: "60px",
    padding: "16px 16px 16px",
    justifyContent: "space-between",
    backgroundColor: theme.palette.primary.contrastText,
    zIndex: "1",
    "& .MuiTypography-root": {
      fontSize:"1rem",
      fontWeight: "bold",
      fontFamily: FontFamilySwitch().bold,
    },
  },
  section: {
    height: "100%",
    overflow: "auto",
    padding: "0px 0px 63px",
    "&::-webkit-scrollbar": {
      width: "0 !important",
    },
  },
  section1: {
    height: "100%",
    overflow: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 0px 89px",
    "&::-webkit-scrollbar": {
      width: "0 !important",
    },
  },
  Cardcontent: {
    padding: "12px 12px 10px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px 6px 6px 0px",
  },
  title: {
    fontSize:"0.875rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
    // marginTop: "8px",
  },
  name: {
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
    fontSize:"0.875rem",
  },
  unit: {
    fontSize:"0.75rem",
    color: theme.palette.background.primary,
    fontFamily: FontFamilySwitch().regular,
  },
  time: {
    fontSize:"0.75rem",
    color: theme.palette.background.primary,
    fontFamily: FontFamilySwitch().regular,
    marginTop: "-12px"
  },
  dis: {
    color: "#5D427F",
    backgroundColor: "#F1E6FE",
    padding: "4px",
    borderRadius: "4px",
    display: "inline-block",
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    marginTop: "4px",
  },
  titlecontainer: {
    paddingBottom: "12px",
  },
  like: {
    fontSize:"0.75rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
  },
  property: {
    fontSize:"0.75rem",
    color: "#5D427F",
    backgroundColor: "#F1E6FE",
    padding: "2px",
    borderRadius: theme.palette.borderRadius,
    fontFamily: FontFamilySwitch().bold,
  },
  new: {
    fontSize:"0.75rem",
    color: "#FFFFFF",
    backgroundColor: "#FF4B4B",
    padding: "2px",
    borderRadius: theme.palette.borderRadius,
    fontFamily: FontFamilySwitch().bold,
  },
  commentSection: {
    borderTop: "1px solid #E4E8EE",
    borderBottom: "1px solid #E4E8EE",
    padding: "12px 18px",
  },
  comments: {
    padding: "10px 20px",
    height: "calc(100vh - 8px)",
    overflow: "auto"
  },
  commentsSection: {
    height: "100vh",
    overflow: "scroll",
    padding: "0px 0px 370px",
    "&::-webkit-scrollbar": {
      width: "0 !important",
    },
  },
  commentsSection1: {
    height: `calc(100vh - 86px)`,
    overflow: "scroll",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 0px 421px",
    textAlign: "center",
    marginTop: "10%",
    "&::-webkit-scrollbar": {
      width: "0 !important",
    },
  },
  maincomment: {
    position: "relative",
    height: "100%",
  },
  filter: {
    padding: "10px 13px 5px",
    borderRadius: theme.palette.borderRadius,
    border: "1px solid #E4E8EE",
    backgroundColor: "#fff",
    cursor: "pointer",
  },
  mainimg: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  parent: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  main: {
    paddingTop: '0px !important'
  },
  main2: {
    paddingTop: '0px !important',
    paddingLeft: '0px !important'
  },
  addImg: {
    position: "relative",
    backgroundColor: "#504e4e3d",
    borderRadius: "10px",
    height: "80px",
    cursor: "pointer"
  },
  plus: {
    position: "absolute",
    textAlign: "center",
    left: "0px",
    right: "0px",
    top: "30%",
    color: "white",
    fontFamily: FontFamilySwitch().semiBold,

  },
  detailImg2: {
    borderRadius: theme.palette.borderRadius,
    height: "80px",
    width: "100%",
    objectFit: "contain"
  },
  btn: {
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  hasProperty: {
    background: "#F1E6FE",
    color: "#5D427F",
    padding: "0px 4px",
    borderRadius: "4px",
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().bold,
    display: "flex",
    alignItems: "center"
  },
  sub: {
    color: theme.typography.color.secondary,
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  seeMoreLessTextStyle: {
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.palette.primary.main,
  },
  slideAvatar: {
    height: 165,
    width: 400,
    borderRadius: 4
  }
}));



const CommunityDiscussion = ({
  t
}) => {
  const classes = useStyles();
  moment.defaultFormat = "DD MMM YY";
  const [show, setShow] = React.useState(false);
  const [userProfile, setUserProfile] = React.useState({
    data: [],
  });
  const backdrop = React.useContext(BackdropContext)
  const alert = React.useContext(AlertContext);
  const [comment, setComment] = React.useState({});
  const [commontstate, setCommontstate] = React.useState([]);
  const [treaId, setthreaId] = React.useState("");
  const [state, setState] = React.useState([]);
  const [deleteopen, setDeleteopen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [deleteopencomment, setdeleteopencomment] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState([]);
  const [offset, setOffset] = React.useState(0);
  const observer = React.useRef();
  const auth = React.useContext(AuthContext)
  const [companyList, setCompanyList] = React.useState([])
  const [selectedCompany, setSelectedCompany] = React.useState({})
  const [filterData] = React.useState()
  const [fiterDrawer, setFilterDrawer] = React.useState(false)
  const [selectedProperty, setSelectedProperty] = React.useState({})

  // const filterData = [
  //   {
  //     id: "0",
  //     title: "Property Name",
  //     key: "property_name",
  //     showSearch: false,
  //     filterType: "CHECKBOX",
  //     values: filter,
  //   },
  // ];


  const handleClickOpendelete = () => {
    setDeleteopen(!deleteopen);
  };
  const handleClickOpendeletecomment = (data) => {
    setdeleteopencomment(!deleteopencomment);
    setDeleteId(data);
  };
  const getAll = (val, filter, offset = 0, limit = 1000) => {
    setShow(false)
    if (val?.length > 0) {
      const data = {
        tenantId: `${config.tenantId}`,
        property_id: val,
        offset,
        limit,
      };
      NetworkCall(
        `${config.api_url}/community/manager/threads`,
        NetWorkCallMethods.post,
        data,
        null,
        true,
        false
      )
        .then((res) => {
          if (filter === true) {
            setUserProfile({
              totalCount: res?.data?.data?.totalCount,
              data: res?.data?.data?.threads,
            });
          } else {
            setUserProfile({
              totalCount: res?.data?.data?.totalCount,
              data: [...userProfile?.data, ...res?.data?.data?.threads],
            });
          }
          setLoading(false);
          backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
        })
        .catch((error) => {
          setLoading(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Some Thing Went Wrong"),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center
          })
        });
    }
    else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Don't Have Any Property"),
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center
      })
    }
  };
  const commentShow = (v, key) => {
    setCommontstate([]);
    setShow(true);
    setthreaId(v);

    const data = {
      tenantId: `${config.tenantId}`,
      discussion_id: v,
      offset: 0,
      limit: 1000,
    };
    NetworkCall(
      `${config.api_url}/community/manager/get-thread`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((res) => {
        setComment(res?.data?.data?.[0]);
        setCommontstate(res?.data?.data?.[0]?.comments);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Some Thing Went Wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
      });
  };
  const deleteTred = (v) => {
    const payload = {
        id: v,
        update: {
          is_active: false,
        }
    };
    NetworkCall(
      `${config.api_url}/queries/community_discussion/update`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setShow(false);
        const data = {
          tenantId: `${config.tenantId}`,
          property_id: selectedProperty?.label === "All Properties" ? selectedProperty?.value : [selectedProperty?.value],
          offset: 0,
          limit: offset + 10,
        };
        NetworkCall(
          `${config.api_url}/community/manager/threads`,
          NetWorkCallMethods.post,
          data,
          null,
          true,
          false
        )
          .then((res) => {
            setUserProfile({
              totalCount: res?.data?.data?.totalCount,
              data: res?.data?.data?.threads,
            });
          })
          .catch((error) => {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.success,
              msg: t("Some Thing Went Wrong"),
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center
            })
          });
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Some Thing Went Wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
      });
  };
  const deleteComment = (val) => {
    const payload = {
        id: val,
        update: {
          is_active: false,
        }
    }
    NetworkCall(
      `${config.api_url}/queries/community_discussion_comments/update`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        commentShow(treaId);
        getAll(selectedProperty?.label === "All Properties" ? selectedProperty?.value : [selectedProperty?.value], true, 0, offset + 10);
        handleClickOpendeletecomment(deleteId);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Some Thing Went Wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })
      });
  };
  React.useEffect(() => {
    let company = getCompanyOption(backdrop, auth, alert)
    if (company) {
      setCompanyList(company?.list)
      setSelectedCompany(company?.selected)
      let properties =
        company?.selected?.property?.map((x) => {
          return {
            value: x.id,
            label: x.name
          }


        })
      setState(properties);
      setSelectedProperty(properties?.[0])

    }
    // eslint-disable-next-line
  }, [auth]);


  const applyFilter = (result) => {
    getAll(result?.property?.map((val) => val?.value), true, 0, offset + 10);

  };

  const lastListItemRef = React.useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (offset + 10 <= userProfile?.totalCount) {
            setOffset((prevOffset) => prevOffset + 10);
            setLoading(true);
            getAll(selectedProperty?.label === "All Properties" ? selectedProperty?.value : [selectedProperty?.value], false, offset + 10);
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line
    [loading]
  );
  // Function to change the company
  const handleCompanyChange = (value) => {
    setSelectedCompany(value)
    let properties =
      value?.property?.map((x) => {
        return {
          value: x.id,
          label: x.name
        }


      })
    setState(properties);
    setSelectedProperty(properties?.[0])
  }
  //handlePropertyChange
  const onPropertyChange = (val) => {
    setSelectedProperty(val)
  }



  React.useEffect(() => {
    if (selectedProperty?.value) {
      getAll(selectedProperty?.label === "All Properties" ? selectedProperty?.value : [selectedProperty?.value] ?? [], true);
    }
    // eslint-disable-next-line
  }, [selectedProperty])

  const manualPropertyResponse = (array) =>{
    return array?.property
  }

  return (
    <>

      <Subheader hideBackButton title={t("Discussion Forum")} select options={companyList} value={selectedCompany}
        onchange={(e) => handleCompanyChange(e)}
        onPropertyChange={(e) => {
          onPropertyChange(e)
        }}
        propertyValue={selectedProperty}
        selectProperty
        propertyOptions={state}

      />
      <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid item sm={5} md={5} lg={3} className={classes.main}>
            <div className={classes.grid}>
              <Box className={classes.tittle}>
                <Box>

                  <Typography>{t("Posts")}</Typography>
                </Box >
                {/* <Box className={classes.filter} onClick={() => setFilterDrawer(!fiterDrawer)}>
                  <img src="/images/filter.svg" alt="filter" />
                </Box> */}
              </Box >

              <div
                className={
                  userProfile?.data?.length > 0 ? classes.section : classes.section
                }
              >
                {/* community Post card  */}

                {loading && userProfile?.data?.length === 0 ? (
                  <Skeletons count={6} />
                ) : (
                  <>
                    {userProfile?.data?.length === 0 && (
                      <div style={{ textAlign: "center" }}>
                        <Typography className={classes.unit}>
                          {t("No Data Found")}
                        </Typography>
                      </div>
                    )}
                  </>
                )}

                {userProfile?.data?.length > 0 && (
                  <>
                    {loading && userProfile?.data?.length > 0 ? (
                      <>
                        {userProfile?.data?.map((val, index) => (
                          <div className={classes.card}>
                            <CommunityCardComponent
                              t={t}
                              data={val}
                              commentShow={commentShow}
                              setCommontstate={setCommontstate}
                              ref={index === userProfile?.data?.length - 1 ? lastListItemRef : null}
                            />
                          </div>
                        ))}
                        <Skeletons count={2} />
                      </>
                    ) : (
                      <>
                        {loading ? (
                          <Skeletons count={2} />
                        ) : (
                          <>
                            {userProfile?.data?.map((val, index) => (
                              <div className={classes.card}>
                                <CommunityCardComponent
                                  t={t}
                                  data={val}
                                  commentShow={commentShow}
                                  setCommontstate={setCommontstate}
                                  ref={index === userProfile?.data?.length - 1 ? lastListItemRef : null}
                                />
                              </div>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div >
          </Grid >
          <Grid item sm={7} md={7} lg={9} className={classes.main2}>
            <div className={classes.grid1}
              justifyContent="space-between">


              {/* commont show  section*/}
              {!show && (
                <div className={classes.mainimg}>
                  <img
                    src="\images\main.svg"
                    alt="main"
                    className={classes.image}
                  />
                </div>
              )}
              <div>
                {" "}
                {show && (
                  <div>
                    {/* comment hide section*/}
                    <Box className={classes.tittle1}>
                      <Box>
                        <Typography>{t("View Posts")}</Typography>
                      </Box>
                    </Box>

                    {/* delete popup */}

                    {/* commnet  section */}
                    <div style={{ padding: "15px 15px" }}>
                      <Box alignItems="center" display="flex">
                        <Box>
                          <Avatar src={comment?.image_url} {...stringAvatar(comment?.first_name)} />
                        </Box>
                        <Box flexGrow={1} marginInlineStart="10px">
                          <Box alignItems="center" display="flex">
                            <Box>
                              <Typography
                                variant="subtitle2"
                                className={classes.name}
                              >
                                {comment?.first_name}&nbsp;{comment?.last_name}
                              </Typography>
                            </Box>
                            <Box className={classes.dot} />
                            <Box flexGrow={1}>
                              <Typography
                                variant="subtitle2"
                                className={classes.unit}
                              >
                                {comment?.unit_name}-{comment?.unit_no}
                              </Typography>
                            </Box>

                            <Box marginLeft="4px">
                              <Box
                                className={classes.filter}
                                // onClick={() => deleteTred(comment?.id)}
                                onClick={handleClickOpendelete}
                              >
                                <img src="/images/delete.svg" alt="filter" />
                              </Box>
                              <DialogBox
                                open={deleteopen}
                                handleClickOpen={handleClickOpendelete}
                                onClose={handleClickOpendelete}
                                isnotTitle
                                maxWidth="xs"
                                component={
                                  <>
                                    <div className={classes.drawerwidth}>
                                      <Box className={classes.Header}>
                                        <Box>
                                          <Typography
                                            variant="h6"
                                            className={classes.title}
                                            noWrap
                                          >
                                            {t("Delete")}
                                          </Typography>
                                        </Box>
                                        <Box className={classes.closeIcondrawermap}>
                                          <Typography
                                            variant="h6"
                                            onClick={handleClickOpendelete}
                                          >
                                            <img
                                              src="\images\closepopup.svg"
                                              alt="close"
                                              style={{ cursor: "pointer" }}
                                            />
                                          </Typography>
                                        </Box>
                                      </Box>
                                      <br />
                                      <br />

                                      <div className={classes.createnewsection}>
                                        <Box className={classes.modalcontant}>
                                          <Box>
                                            {" "}
                                            <img
                                              src="\images\deletebig.svg"
                                              alt="close"
                                            />
                                          </Box>
                                          <Box>
                                            {" "}
                                            <Typography
                                              variant="h3"
                                              className={classes.modaltitle}
                                              noWrap
                                            >
                                              {" "}
                                              {t("Are you sure you want to delete this thread ?")} {" "}{" "}

                                            </Typography >
                                          </Box >
                                        </Box >
                                        <div Box className={classes.modalcontant}>
                                          <div className={classes.popupbtn}>
                                            <Button
                                              variant="contained"
                                              onClick={() =>
                                                deleteTred(comment?.id)
                                              }
                                            >
                                              {t("Yes")}
                                            </Button >
                                          </div >
                                          <div className={classes.popupbtn}>
                                            <Button
                                              variant="outlined"
                                              onClick={handleClickOpendelete}
                                              className={classes.btn}
                                            >
                                              {t("No")}
                                            </Button>
                                          </div>
                                        </div >
                                      </div >
                                    </div >
                                  </>
                                }
                              />
                            </Box >
                          </Box >
                          <Typography variant="subtitle2" className={classes.time}>
                            {t("Posted at")} &nbsp;
                            &#x202a;{moment(new Date(comment?.created_at)).fromNow()}&#x202c;
                          </Typography>
                        </Box >
                      </Box >
                      <Stack direction={"row"} spacing={1} mt={2}>
                        <Typography className={classes.title}>{comment?.title}</Typography>
                        <Typography className={classes.hasProperty}>{comment?.property_name}</Typography>
                      </Stack>
                      {comment?.description &&
                        <ShowMoreText
                          lines={2}
                          more={t("Show More")}
                          less={t("Show Less")}
                          className={classes.sub}
                          anchorClass={classes.seeMoreLessTextStyle}
                          expanded={false}
                          truncatedEndingComponent={"... "}
                        >
                          <Typography className={classes.sub}
                            dangerouslySetInnerHTML={{ __html: (comment?.description).replace(/\n+/g, "<br />") ?? "" }}>
                          </Typography>
                        </ShowMoreText>}
                      <ScrollContainer className="scroll-container">
                        <Stack direction={"row"} spacing={1} mt={"12px"}>
                          {
                            comment?.assets?.map((e) => {
                              return (
                                <Avatar src={e?.url} className={classes.slideAvatar}></Avatar>
                              )
                            }
                            )
                          }
                        </Stack>
                      </ScrollContainer>
                    </div >
                    <div className={classes.commentSection}>
                      <Stack
                        direction="row"
                        divider={<Divider sx={{ marginInline: "16px" }} orientation="vertical" flexItem />}
                        spacing={2}
                      >
                        <Box>
                          <Typography variant="subtitle2" className={classes.like}>
                            <img src="\images\like.svg" alt="like" />
                            &nbsp;&nbsp;
                            {comment?.reactions}&nbsp;{t("Likes")}
                          </Typography>
                        </Box>
                        <Box>
                          {" "}
                          <Typography variant="subtitle2" className={classes.like}>
                            <img src="/images/chat.svg" alt="chat" />
                            &nbsp;&nbsp;
                            {comment?.commentsCount}&nbsp;{t("Comments")} 
                          </Typography>
                        </Box>
                      </Stack>
                    </div>

                    {/* comment all */}
                    <div className={classes.maincomment}>
                      <div className={classes.comments}>
                        <Typography
                          className={classes.unit}
                          style={{ marginBottom: "10px" }}>
                          {t("Comments")}
                        </Typography>
                        <div
                          className={
                            commontstate?.length > 0
                              ? classes.commentsSection
                              : classes.commentsSection1
                          }
                        >
                          {commontstate?.length > 0 ? (
                            <>
                              {commontstate?.map((val) => (
                                <CommontsCard
                                  data={val}
                                  comment={comment}
                                  deleteComment={handleClickOpendeletecomment}
                                />
                              ))}
                            </>
                          ) : (
                            <>
                              <Typography className={classes.unit}>
                                {t("No Comments")}
                              </Typography>
                            </>
                          )}
                          {/* comment delete popup */}
                          <DialogBox
                            open={deleteopencomment}
                            handleClickOpen={handleClickOpendeletecomment}
                            handleClose={handleClickOpendeletecomment}
                            header={t("Delete")}
                            maxWidth={'xs'}
                            component={
                              <>
                                <div className={classes.drawerwidth}>
                                  <div className={classes.createnewsection}>
                                    <Box className={classes.modalcontant}>
                                      <Box>
                                        {" "}
                                        <img
                                          src="\images\deletebig.svg"
                                          alt="close"
                                        />
                                      </Box>
                                      <Box>
                                        {" "}
                                        <Typography
                                          variant="h3"
                                          className={classes.modaltitle}
                                          noWrap
                                        >
                                          {" "}
                                          {t("Do you want to delete this comment ?")}  {" "}
                                        </Typography >
                                      </Box >
                                    </Box >
                                    <div Box className={classes.modalcontant}>
                                      <div className={classes.popupbtn}>
                                        <Button
                                          variant="contained"
                                          onClick={() => deleteComment(deleteId)}
                                        >
                                          {t("Yes")}
                                        </Button >
                                      </div >
                                      <div className={classes.popupbtn}>
                                        <Button
                                          variant="outlined"
                                          onClick={handleClickOpendeletecomment}

                                          className={classes.btn}

                                        >
                                          {t("No")}
                                        </Button>
                                      </div>
                                    </div >
                                  </div >
                                </div >
                              </>
                            }
                          />
                        </div >
                      </div >
                    </div >
                  </div >
                )}
              </div >
            </div >
          </Grid >
        </Grid >
        <FilterGenerator
          open={fiterDrawer} onClose={() => setFilterDrawer(false)}
          onApply={applyFilter}
          components={[
            {
              component: "select",
              value: filterData?.property,
              options: [],
              isMulti: true,
              label: t("Properties"),
              state_name: "property",
              loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                    "queries/property/list",
                    {
                        "orderByKey": "name",
                        "orderByValue": "asc",
                        company_id: selectedCompany?.value
                    },
                    search,
                    array,
                    handleLoading,
                    "data",
                    {},
                    manualPropertyResponse
                ),
              debounceTimeout: 800,
              isPaginate: true,
            },

          ]} />
      </div >
    </>
  );
};
export default withTranslation("communityDiscussion")(CommunityDiscussion)
