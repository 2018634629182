import {
  Box,
  Divider,
  Drawer,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { CloseIconWithBG } from "../../../assets";
import { SearchFilter, TableWithPagination } from "../../../components";
import { NewLoader } from "../../../components/newLoader";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods } from "../../../utils";
import { useStyles } from "../style";
import {
  CheckListFormDataType,
  CheckListFormHeading,
  CheckListFormPath,
  ListDataType,
  ListHeading,
  ListPath,
  planPreviewTabOptions,
} from "../utils";

export const CheckListForm = ({
  t = () => false,
  dynamicTabValue = {},
  state = {},
  updateState = () => false,
  data = {},
}) => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:1100px)");
  const [searchText, setSearchText] = React.useState("");
  const [tableData, setTableData] = React.useState({
    row: [
      {
        group_name: "CheckList",
      },
    ],
  });
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(true);
  const [limit, setLimit] = React.useState(10);
  const [listPage, setListPage] = React.useState(1);
  const [listLimit, setListLimit] = React.useState(10);
  const [showCheckListDetails, setShowCheckListDetails] = React.useState({
    bool: false,
    data: [],
  });
  const [allSelect, setAllSelect] = React.useState(false);
  React.useEffect(() => {
    getCheckListGroupDetails();
    //eslint-disable-next-line
  }, []);

  const getCheckListGroupDetails = (
    offset = 0,
    limit = 10,
    search,
    groupType = "1"
  ) => {
    const payload = {
      company_id: state?.company?.value,
      search: search,
      start: offset,
      length: limit,
      check_list_group_type:
        data?.PMPType === "Unit" ? ["Unit"] : ["General"],
      unit_type: data?.PMPType === "Unit" ? [data?.unit_type?.value] : [],
      item_category: data?.PMPType === "Asset" ? [data?.category?.value] : [],
      is_active: [true],
    };

    NetworkCall(
      `${config.api_url}/check_list_group`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setLoading(false);
        const result = response?.data?.data?.map((x) => {
          return {
            ...x,
            checklist_group_id: x?.id,
            id: state?.is_edit ? undefined : x?.id,
            is_checked:
              state?.planner_type === "Fixed"
                ? data?.selected_checklist?.length > 0
                  ? data?.selected_checklist?.filter((z) => {
                    return z?.checklist_group_id === x?.id;
                  })?.length > 0
                    ? data?.selected_checklist?.filter((z) => {
                      return z?.checklist_group_id === x?.id;
                    })?.[0]?.is_checked
                    : false
                  : false
                : data?.selected_dynamic_checklist?.length > 0
                  ? data?.selected_dynamic_checklist?.filter((z) => {
                    return z?.checklist_group_id === x?.id;
                  })?.length > 0
                    ? data?.selected_dynamic_checklist?.filter((z) => {
                      return z?.checklist_group_id === x?.id;
                    })?.[0]?.is_checked
                    : false
                  : false,
          };
        });
        if (result?.length > 0) {
          let selected = result?.filter((val) => {
            return val?.is_checked;
          });
          setAllSelect(selected?.length === result?.length);
        }
        setTableData({ list: result, count: response?.data?.count });
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const getCheckListDetails = (
    data,
    listOffset = 0,
    listLimit = 10,
    search = ""
  ) => {
    const payload = {
      group_id: data?.checklist_group_id,
      search: search,
      start: listOffset,
      length: listLimit,
    };

    NetworkCall(
      `${config.api_url}/check_list`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        const result = response?.data?.data?.map((val) => {
          return {
            ...val,
            condition: val?.condition_count,
            is_mandatory: val?.is_mandatory ? "Yes" : "No",
            group_name: data?.name,
          };
        });
        setShowCheckListDetails({
          bool: true,
          data: result,
          count: response?.data?.count,
          groupDetails: data,
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleSearch = (val) => {
    setSearchText(val);
    getCheckListGroupDetails(0, 10, val);
  };
  const handleTablePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getCheckListGroupDetails(offset, 10, "");
  };
  const handleTablePageLimit = (value) => {
    setLimit(value);
    setPage(1);
    getCheckListGroupDetails(0, limit, "");
  };
  const handleListTablePagination = (value) => {
    setListPage(value);
    let offset = (value - 1) * listLimit;
    getCheckListDetails(showCheckListDetails?.groupDetails, offset, 10, "");
  };
  const handleListTablePageLimit = (value) => {
    setListLimit(value);
    setListPage(1);
    getCheckListDetails(showCheckListDetails?.groupDetails, 0, listLimit, "");
  };
  const handleTableIcon = (type, detail, is_checked) => {

    if (type === "customCheckBox") {
      if (state?.planner_type === "Fixed") {
        const result = tableData?.list?.map((x) => {
          return {
            ...x,
            is_checked:
              x?.checklist_group_id === detail?.checklist_group_id
                ? is_checked
                : x?.is_checked,
            selected_period:
              dynamicTabValue === "1"
                ? "Monthly"
                : dynamicTabValue === "2"
                  ? "Quarterly"
                  : dynamicTabValue === "3"
                    ? "Half yearly"
                    : "",
          };
        });
        updateState(
          "selected_checklist",
          result?.filter((val) => {
            return val?.is_checked;
          })
        );
        setTableData({ ...tableData, list: result });

        if (result?.length > 0) {
          let selected = result?.filter((val) => {
            return val?.is_checked;
          });
          setAllSelect(selected?.length === result?.length);
        }
      } else {
        const selected_period = planPreviewTabOptions?.filter((val) => {
          return val?.value === dynamicTabValue;
        })?.[0]?.label;
        const result = tableData?.list?.map((x) => {
          return {
            ...x,
            is_checked:
              x?.checklist_group_id === detail?.checklist_group_id
                ? is_checked
                : x?.is_checked,
            selected_period: selected_period ?? "",
          };
        });
        if (data?.selected_dynamic_checklist?.length > 0) {
          const dynamic_data = data?.selected_dynamic_checklist?.filter((x) => {
            return x?.selected_period !== selected_period;
          });
          updateState(
            "selected_dynamic_checklist",
            dynamic_data?.length > 0
              ? [
                ...dynamic_data,
                {
                  ...detail,
                  is_checked: is_checked,
                  selected_period: selected_period ?? "",
                },
              ]
              : result?.filter((val) => {
                return val?.is_checked;
              })
          );
          if (result?.length > 0) {
            let selected = result?.filter((val) => {
              return val?.is_checked;
            });
            setAllSelect(selected?.length === result?.length);
          }
        } else {
          updateState(
            "selected_dynamic_checklist",
            data?.selected_dynamic_checklist?.length > 0
              ? [
                ...data?.selected_dynamic_checklist,
                {
                  ...detail,
                  is_checked: is_checked,
                  selected_period: selected_period ?? "",
                },
              ]
              : result?.filter((val) => {
                return val?.is_checked;
              })
          );
          if (result?.length > 0) {
            let selected = result?.filter((val) => {
              return val?.is_checked;
            });
            setAllSelect(selected?.length === result?.length);
          }
        }
        // if (data?.selected_period === "Monthly" && data?.selected_dynamic_checklist?.filter((x) => { return x?.selected_period === "Monthly" })?.length > 0) {
        //     const dynamic_data = data?.selected_dynamic_checklist?.filter((x) => { return x?.selected_period !== "Monthly" })
        //     updateState("selected_dynamic_checklist", dynamic_data?.length > 0 ? [...dynamic_data, { ...detail, is_checked: is_checked, selected_period: dynamicTabValue === "1" ? "Monthly" : dynamicTabValue === "2" ? "Quarterly" : dynamicTabValue === "3" ? "Half yearly" : "" }] : result?.filter((val) => { return val?.is_checked }))
        // }
        // else if (data?.selected_period === "Quarterly" && data?.selected_dynamic_checklist?.filter((x) => { return x?.selected_period === "Quarterly" })?.length > 0) {
        //     const dynamic_data = data?.selected_dynamic_checklist?.filter((x) => { return x?.selected_period !== "Quarterly" })
        //     updateState("selected_dynamic_checklist", dynamic_data?.length > 0 ? [...dynamic_data, { ...detail, is_checked: is_checked, selected_period: dynamicTabValue === "1" ? "Monthly" : dynamicTabValue === "2" ? "Quarterly" : dynamicTabValue === "3" ? "Half yearly" : "" }] : result?.filter((val) => { return val?.is_checked }))
        // }
        // else if (data?.selected_period === "Half yearly" && data?.selected_dynamic_checklist?.filter((x) => { return x?.selected_period === "Half yearly" })?.length > 0) {
        //     const dynamic_data = data?.selected_dynamic_checklist?.filter((x) => { return x?.selected_period !== "Half yearly" })
        //     updateState("selected_dynamic_checklist", dynamic_data?.length > 0 ? [...dynamic_data, { ...detail, is_checked: is_checked, selected_period: dynamicTabValue === "1" ? "Monthly" : dynamicTabValue === "2" ? "Quarterly" : dynamicTabValue === "3" ? "Half yearly" : "" }] : result?.filter((val) => { return val?.is_checked }))
        // }

        setTableData({ ...tableData, list: result });
      }
    } else {
      getCheckListDetails(detail, 0, 10, "");
    }
    // setShowCheckListDetails(true)
  };
  const onCheckBox = (data, is_checked) => {
    setShowCheckListDetails(true)
  };
  const allhandleCheck = (is_checked) => {

    if (state?.planner_type === "Fixed") {
      // Update all rows with the same checked state
      const result = tableData?.list?.map((x) => {
        return {
          ...x,
          is_checked: is_checked,
          selected_period:
            dynamicTabValue === "1"
              ? "Monthly"
              : dynamicTabValue === "2"
                ? "Quarterly"
                : dynamicTabValue === "3"
                  ? "Half yearly"
                  : "",
        };
      });

      if (result?.length > 0) {
        let selected = result?.filter((val) => {
          return val?.is_checked;
        });
        setAllSelect(selected?.length === result?.length);
      }

      updateState(
        "selected_checklist",
        result?.filter((val) => val?.is_checked)
      );

      setTableData({ ...tableData, list: result });
    } else {
      // For dynamic planner type
      const selected_period = planPreviewTabOptions?.filter(
        (val) => val?.value === dynamicTabValue
      )?.[0]?.label;

      // Update all rows with the same checked state
      const result = tableData?.list?.map((x) => {
        return {
          ...x,
          is_checked: is_checked,
          selected_period: selected_period ?? "",
        };
      });
      if (result?.length > 0) {
        let selected = result?.filter((val) => {
          return val?.is_checked;
        });
        setAllSelect(selected?.length === result?.length);
      }
      if (data?.selected_dynamic_checklist?.length > 0) {
        // Remove existing items for the current period
        const dynamic_data = data?.selected_dynamic_checklist?.filter(
          (x) => x?.selected_period !== selected_period
        );

        // Add all newly checked items for the current period
        updateState(
          "selected_dynamic_checklist",
          dynamic_data?.length > 0
            ? [
              ...dynamic_data,
              ...result
                .filter((val) => val?.is_checked)
                .map((item) => ({
                  ...item,
                  selected_period: selected_period ?? "",
                })),
            ]
            : result?.filter((val) => val?.is_checked)
        );
      } else {
        // If no existing selections, simply add all checked items
        updateState(
          "selected_dynamic_checklist",
          result?.filter((val) => val?.is_checked)
        );
        if (result?.length > 0) {
          let selected = result?.filter((val) => {
            return val?.is_checked;
          });
          setAllSelect(selected?.length === result?.length);
        }
      }

      setTableData({ ...tableData, list: result });
    }
  };

  return (
    <Box p={2}>
      {loading ? (
        <NewLoader />
      ) : (
        <>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <SearchFilter
                value={searchText}
                placeholder={t("Search")}
                handleChange={(value) => handleSearch(value)}
              />
            </Grid>
          </Grid>
          <Box mt={2}>
            <TableWithPagination
              heading={CheckListFormHeading(t)}
              rows={tableData?.list}
              path={CheckListFormPath}
              showpagination={true}
              showpdfbtn={false}
              showexcelbtn={false}
              showSearch={false}
              handleIcon={handleTableIcon}
              handleCheck={onCheckBox}
              onClick={() => console.log("")}
              tableType="no-side"
              dataType={CheckListFormDataType}
              handlePagination={handleTablePagination}
              handleChangeLimit={handleTablePageLimit}
              totalRowsCount={tableData?.count}
              // onCheckBox={onCheckBox}
              checkboxKey="is_checked"
              allSelect={allSelect}
              allhandleCheck={allhandleCheck}
              page={page}
              limit={limit}
              height={"calc(100vh - 200px)"}
              view={true}
              edit={true}
              delete={true}
            />
          </Box>
        </>
      )}
      <Drawer
        anchor={"right"}
        open={showCheckListDetails?.bool}
        PaperProps={{
          sx: { width: matches ? "95%" : "60%" },
        }}
        onClose={() => setShowCheckListDetails({ bool: false, data: {} })}
      >
        <Box>
          <Stack direction="row" spacing={2} alignItems="center" p={1.5}>
            <Box
              onClick={() => setShowCheckListDetails({ bool: false, data: {} })}
              style={{ cursor: "pointer" }}
            >
              <CloseIconWithBG />
            </Box>
            <Typography className={classes.drawerHeader}>
              {showCheckListDetails?.data?.[0]?.group_name}
            </Typography>
          </Stack>
          <Divider />
        </Box>
        <Box p={2}>
          <TableWithPagination
            heading={ListHeading(t)}
            rows={showCheckListDetails?.data}
            path={ListPath}
            showpagination={true}
            showpdfbtn={false}
            showexcelbtn={false}
            showSearch={false}
            onClick={() => console.log("")}
            tableType="no-side"
            dataType={ListDataType}
            handlePagination={handleListTablePagination}
            handleChangeLimit={handleListTablePageLimit}
            totalRowsCount={showCheckListDetails?.count}
            page={listPage}
            limit={listLimit}
            height={"calc(100vh - 200px)"}
            view={true}
            edit={true}
            delete={true}
          />
        </Box>
      </Drawer>
    </Box>
  );
};
