import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import { FontFamilySwitch } from "../../../utils";

export const StackedSmoothLineChartHighcharts = ({
  data = [],
  isShowLegend = false,
  graphTitle,
  chartHeight,
}) => {
  const chartRef = useRef(null);
  const highchartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const colors = [
        "#278ECF",
        "#48D762",
        "#FFCA1F",
        "#FF9416",
        "#D42AE8",
        "#535AD7",
        "#FF402C",
        "#83BFFF",
        "#6ED88F",
        "#FFE366",
      ];

      const options = {
        chart: {
          type: "areaspline",
          renderTo: chartRef.current,
          marginTop: 50,
        },
        credits: {
          enabled: false
        },
        colors: colors,
        title: {
          text: graphTitle,
          align: "left",
          style: {
            color: "#091B29",
            fontSize: "1rem",
            fontFamily: FontFamilySwitch().extraBold,
            marginInlineStart: "8px",
          },
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: data.labels,
          tickmarkPlacement: "on",
          title: {
            enabled: false,
          },
        },
        yAxis: {
          title: {
            text: "",
          },
          labels: {
            formatter: function () {
              var number = this.value;
              if (number >= 1e12) {
                return (number / 1e12).toFixed(0) + " Tn";
              } else if (number >= 1e9) {
                return (number / 1e9).toFixed(0) + " Bn";
              } else if (number >= 1e6) {
                return (number / 1e6).toFixed(0) + " Mn";
              } else if (number >= 1e3) {
                return (number / 1e3).toFixed(0) + " k";
              } else {
                return number;
              }
            },
          },
        },
        tooltip: {
          shared: true,
          formatter: function () {
            var s = "<b>" + Highcharts.dateFormat("%B %Y", this.x) + "</b>";

            this.points.forEach(function (point) {
              var number = point.y;
              var formattedNumber;
              if (number >= 1e12) {
                formattedNumber = (number / 1e12).toFixed(0) + " Tn";
              } else if (number >= 1e9) {
                formattedNumber = (number / 1e9).toFixed(0) + " Bn";
              } else if (number >= 1e6) {
                formattedNumber = (number / 1e6).toFixed(0) + " Mn";
              } else if (number >= 1e3) {
                formattedNumber = (number / 1e3).toFixed(0) + " k";
              } else {
                formattedNumber = number;
              }
              s +=
                '<br/><span style="color:' +
                point.series.color +
                '">\u25CF</span> ' +
                point.series.name +
                ": <b>" +
                formattedNumber +
                "</b>";
            });

            return s;
          },
        },
        plotOptions: {
          series: {
            fillOpacity: 0.1,
            fillColor: null,
            marker: {
              enabled: false,
            },
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: data.datasets.map((dataset, index) => ({
          name: dataset.label,
          data: dataset.data,
          fillColor: {
            linearGradient: [0, 0, 0, "90%"],
            stops: [
              [0, colors[index % colors.length]],
              [
                1,
                new Highcharts.Color(colors[index % colors.length])
                  .setOpacity(0.1)
                  .get("rgba"),
              ],
            ],
          },
        })),
      };

      if (!highchartRef.current) {
        highchartRef.current = new Highcharts.Chart(options);
      } else {
        highchartRef.current.update(options);
      }

      const handleFullscreenChange = () => {
        if (highchartRef.current) {
          highchartRef.current.reflow();
        }
      };

      document.addEventListener("fullscreenchange", handleFullscreenChange);

      return () => {
        if (highchartRef.current) {
          highchartRef.current = null;
        }
        document.removeEventListener("fullscreenchange", handleFullscreenChange);
      };
    }
  }, [data, graphTitle, chartHeight]);

  return (
    <div ref={chartRef} style={{ width: "100%", height: chartHeight }}></div>
  );
};