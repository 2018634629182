import { Avatar, Box, Button, Dialog, Divider, Grid, IconButton, Stack, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { convertTimeUtcToZoneCalander, enumName, stringAvatar } from "../../../utils"
import { useStyles } from "../style"
import { Bed } from "./bed"
import { Bath } from "./bath"
import { UnitType } from "./unitType"
import AcceptIcon from "../../../assets/agreementDetails/acceptIcon";
import AGRIcon from "../../../assets/agreementDetails/agrIcon";
import RequestDeatilsIcon from "../../../assets/agreementDetails/requestDetailsIcon";
import { useState } from "react";

export const AcceptConfirm = (props) => {
    const { t = () => false } = (props);

    const classes = useStyles(props);
    const [is_view_more, set_is_view_more] = useState(false);

    return <Dialog
        className={classes.acceptConfirmDialog}
        open={props?.acceptDialogOpen}
        onClose={() => props?.setAcceptDialogOpen(false)}>
        <div className={classes.viewDialogHeader}>
            <Typography className={classes.viewDialogHeaderTitle}>
                {t("Accept Confirmation")}
            </Typography >
            <IconButton onClick={() => props?.setAcceptDialogOpen(false)}
                className={classes.viewDialogCloseButton}>
                <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
            </IconButton>
        </div >
        <div className={classes.acceptDialogBody}>
            <Grid container>
                <Grid item xs={4} padding={"16px"}>
                    <Box height={'calc(100vh - 154px)'} overflow={"overlay"}>
                        <Box className={classes.approved_box}>
                            <Box>
                                <AcceptIcon />
                            </Box>
                            <Box flexGrow={1} marginInlineStart={"10px"} >
                                <Typography className={classes.viewDialogText4}>
                                    {props?.requestState?.status?.label}
                                </Typography>
                                <Typography className={classes.italicText}>
                                    {t("By")} {props?.requestState?.approvedBY} on {props?.requestState?.updatedOn}
                                </Typography>
                                <Typography className={classes.semiBoldText}>
                                    {t("Remarks")} : <span className={classes.regularText}>{props?.requestState?.managerRemarks}</span>
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.boxWithOutFlex} borderRadius={"4px 4px  0px 0px !important"}>
                            <Box marginBottom={"12px"}>
                                <Typography className={classes.heading2} textTransform={"uppercase"}>
                                    {t("account information")}
                                </Typography>
                            </Box>
                            <Box className={classes.boxWithOutBorder}>
                                <Box>
                                    <RequestDeatilsIcon />
                                </Box>
                                <Box flexGrow={1} marginInlineStart={"10px"} >
                                    <Typography className={classes.viewDialogText8}>
                                        {props?.requestState?.accountNo}
                                    </Typography>
                                    <Typography className={classes.italicText}>
                                        {props?.requestState?.accountName}
                                    </Typography>
                                </Box >
                            </Box>
                            <Box margin={"8px 0px"}>
                                <Divider />
                            </Box>
                            <Box margin={"12px 0px"}>
                                <Typography className={classes.heading2} textTransform={"uppercase"}>
                                    {t("renewal agreement details")}
                                </Typography>
                            </Box>
                            <Box className={classes.boxWithOutBorder} marginBottom={"8px"}>
                                <Box>
                                    <AGRIcon />
                                </Box>
                                <Box flexGrow={1} marginInlineStart={"10px"} >
                                    <Box className={classes.boxFlex}>
                                        <Box>
                                            <Typography className={classes.viewDialogText4} textTransform={"capitalize"}>
                                                {props?.requestState?.renewed_agreement_no}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.renewal_type}>
                                            {props?.requestState?.renewal_type}
                                        </Box >
                                    </Box >
                                    <Typography className={classes.semiBoldText}>
                                        &#x202a;{convertTimeUtcToZoneCalander(props?.requestState?.data?.renewal_period?.from_date)}&#x202c; - &#x202a;{convertTimeUtcToZoneCalander(props?.requestState?.data?.renewal_period?.to_date)}&#x202c;
                                    </Typography>
                                </Box>
                            </Box>
                            {props?.requestState?.autoRenewalEscalation?.value &&
                                <Stack direction={"row"} justifyContent={"space-between"}>
                                    <Typography className={classes.viewDialogText9} textTransform={"capitalize"}>
                                        {t("renewal based on")}
                                    </Typography>
                                    <Typography className={classes.viewDialogText10}>
                                        {props?.requestState?.revised_auto_renewal_escalation?.label}{(props?.requestState?.revised_auto_renewal_escalation?.value === "Increment %" && props?.requestState?.revised_increment_escalation) && (" - " + props?.requestState?.revised_increment_escalation + "%")}
                                    </Typography>
                                </Stack>}
                            <Stack direction={"row"} justifyContent={"space-between"} marginTop={"8px"}>
                                <Typography className={classes.viewDialogText9} textTransform={"capitalize"}>
                                    {t("lock-in period")}
                                </Typography>
                                <Typography className={classes.viewDialogText10} textTransform={"capitalize"}>
                                    {props?.requestState?.revised_lock_in_period ? t("yes") : t("no")}
                                </Typography>
                            </Stack>
                            {
                                is_view_more &&
                                <>
                                    <Box margin={"8px 0px"}>
                                        <Divider />
                                    </Box>
                                    <Box margin={"12px 0px"}>
                                        <Typography className={classes.heading2} textTransform={"uppercase"}>
                                            {t("previous agreement details")}
                                        </Typography>
                                    </Box>
                                    <Box className={classes.boxWithOutBorder} marginBottom={"8px"}>
                                        <Box>
                                            <AGRIcon />
                                        </Box>
                                        <Box flexGrow={1} marginInlineStart={"10px"} >
                                            <Box className={classes.boxFlex}>
                                                <Box>
                                                    <Typography className={classes.viewDialogText4}>
                                                        {props?.requestState?.agreementNo}
                                                    </Typography>
                                                </Box>
                                                <Box className={classes.renewal_type}>
                                                    {props?.requestState?.renewal_type}
                                                </Box >
                                            </Box >
                                            <Typography className={classes.semiBoldText}>
                                                &#x202a;{props?.requestState?.leaseStart}&#x202c; - &#x202a;{props?.requestState?.leaseEnd}&#x202c;
                                            </Typography>
                                        </Box >
                                    </Box >
                                    {props?.requestState?.autoRenewalEscalation?.value &&
                                        <Stack direction={"row"} justifyContent={"space-between"}>
                                            <Typography className={classes.viewDialogText9} textTransform={"capitalize"}>
                                                {t("renewal based on")}
                                            </Typography>
                                            <Typography className={classes.viewDialogText10}>
                                                {props?.requestState?.autoRenewalEscalation?.label}{(props?.requestState?.autoRenewalEscalation?.value === "Increment %" && props?.requestState?.increment_escalation) && (" - " + props?.requestState?.increment_escalation + "%")}
                                            </Typography>
                                        </Stack>}
                                    <Stack direction={"row"} justifyContent={"space-between"} marginTop={"8px"}>
                                        <Typography className={classes.viewDialogText9} textTransform={"capitalize"}>
                                            {t("lock-in period")}
                                        </Typography>
                                        <Typography className={classes.viewDialogText10} textTransform={"capitalize"}>
                                            {props?.requestState?.lock_in_period ? t("yes") : t("no")}
                                        </Typography>
                                    </Stack>
                                </>
                            }
                        </Box>
                        <Box className={classes.view_more_less_div}
                            onClick={() => set_is_view_more(!is_view_more)}>
                            <Typography className={classes.view_more_less}>
                                {is_view_more ? t("view less") : t("view more")}
                            </Typography>
                        </Box>
                        <Grid container padding={"12px 0px"}>
                            <Typography className={classes.heading}>
                                {t("Primary Contact")}
                            </Typography >
                        </Grid >
                        <Box display="flex" alignItems="center" className={classes.viewDialogContactCard}>
                            <Avatar src={props?.requestState?.contactImg} variant={"rounded"}
                                className={classes.contactAvatar} {...stringAvatar(props?.requestState?.contactName)} />
                            <Box flexGrow={1}>
                                <Grid container justifyContent={"start"} alignItems={"center"}
                                    direction={"row"} paddingBottom={"6px"}>
                                    <Typography className={classes.viewDialogText4}>
                                        {props?.requestState?.contactName}
                                    </Typography>
                                </Grid>
                                <Grid container justifyContent={"start"} alignItems={"center"} direction={"row"}>
                                    <Typography className={classes.viewDialogText5}>
                                        {props?.requestState?.contactNo}
                                    </Typography>
                                    <Box className={classes.period} />
                                    <Typography className={classes.viewDialogText5}>
                                        {props?.requestState?.contactEmail}
                                    </Typography>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={4} padding={"16px"} className={classes.unitListContainer}>
                    <Grid container paddingBottom={"12px"}>
                        <Typography className={classes.viewDialogText6}>
                            {t("Unit Details")}
                        </Typography >
                    </Grid >
                    <Grid container alignContent={"start"} className={classes.unitList}>
                        <Grid container spacing={"16px"}>
                            {props?.requestState?.unitPrice?.units?.map((_) => {
                                return <Grid item xs={6}>
                                    <div style={{
                                        border: "1px solid #E4E8EE",
                                        borderRadius: "4px",
                                        padding: "16px",
                                        backgroundColor: "white",
                                    }}>
                                        <img className={classes.unitImage}
                                            src={_?.logo ?? ""} alt={_?.name ?? "Unit_Image"} />
                                        <Stack direction={"row"} justifyContent={"space-between"}
                                            alignItems={"center"} padding={"4px 0px"}>
                                            <Typography className={classes.viewDialogText4}>
                                                {_?.name ?? ""}
                                            </Typography>
                                            <Typography className={classes.viewDialogText4}>
                                                {_?.total ?? ""}
                                            </Typography>
                                        </Stack>
                                        <Stack direction={"row"} justifyContent={"start"}
                                            alignItems={"center"} padding={"4px 0px"}
                                            divider={<div style={{
                                                backgroundColor: "#CED3DD", margin: "0px 8px",
                                                borderRadius: "50%", height: "6px", width: "6px"
                                            }} />}>
                                            <Typography className={classes.viewDialogText7}>
                                                {_?.property?.property_name ?? ""}
                                            </Typography>
                                            <Typography className={classes.viewDialogText7}>
                                                {_?.totalarea ? (_?.totalarea + (_?.areaMetric ?? "")) : ""}
                                            </Typography>
                                        </Stack>
                                        <Stack direction={"row"} justifyContent={"space-between"}
                                            alignItems={"center"} padding={"4px 0px"}
                                            divider={<div style={{
                                                backgroundColor: "#CED3DD", margin: "0px",
                                                borderRadius: "50%", height: "6px", width: "6px"
                                            }} />}>
                                            <Stack direction={"row"} justifyContent={"center"}
                                                alignItems={"center"}>
                                                <Bed />
                                                <Typography className={classes.viewDialogText7}>
                                                    {_?.totalbedroom}
                                                </Typography>
                                            </Stack>
                                            <Stack direction={"row"} justifyContent={"center"}
                                                alignItems={"center"}>
                                                <Bath />
                                                <Typography className={classes.viewDialogText7}>
                                                    {_?.baths}
                                                </Typography>
                                            </Stack>
                                            <Stack direction={"row"} justifyContent={"center"}
                                                alignItems={"center"}>
                                                <UnitType />
                                                <Typography className={classes.viewDialogText7}>
                                                    {_?.unitType}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <Typography textAlign={"center"} className={classes.clickableText}
                                            onClick={() => {
                                                props?.setRequestState({
                                                    ...props?.requestState,
                                                    unitView: _
                                                })
                                                props?.setUnitViewDialogOpen(true)
                                                props?.setAcceptDialogOpen(false)
                                                props?.setRequestDialogOpen(false)
                                                props?.setAcceptDeclineDialogOpen(false)
                                            }}>
                                            {"View Details"}
                                        </Typography>
                                    </div>
                                </Grid>
                            })}
                        </Grid>
                    </Grid>
                </Grid >
                <Grid item xs={4} padding={"16px"}>
                    <Grid container paddingBottom={"12px"}>
                        <Typography className={classes.viewDialogText6}>
                            {t("Agreement Summary")}
                        </Typography >
                    </Grid >
                    <Grid container className={classes.agreementSummary}>
                        <Grid item xs={5}>
                            <Typography className={classes.tableHeaderText}>
                                {t("DESCRIPTION")}
                            </Typography >
                        </Grid >
                        <Grid item xs={2}>
                            <Typography className={classes.tableHeaderText}>
                                {t("QTY")}
                            </Typography >
                        </Grid >
                        <Grid item xs={5}>
                            <Typography textAlign={"right"} className={classes.tableHeaderText}>
                                {t("AMOUNT")}
                            </Typography >
                        </Grid >
                        <Grid item xs={12} className={classes.tableDivider} />
                        <Grid container alignContent={"start"} className={classes.tableBody}>
                            <Grid item xs={5}>
                                <Typography className={classes.tableDescriptionText}>
                                    {t("Total Amount")}
                                </Typography >
                            </Grid >
                            <Grid item xs={2}>
                                <Typography className={classes.tableQtyText}>
                                    {"-"}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography textAlign={"right"} className={classes.tableTotalText}>
                                    {(props?.requestState?.currency ? (props?.requestState?.currency + " ") : "") +
                                        (props?.requestState?.unitPrice?.totalAmount?.toFixed(2) ?? "0.00")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} margin={"0px 12px"} className={classes.tableDivider} />
                            {/* <Grid item xs={5}>
                                <Typography className={classes.tableDescriptionText}>
                                    {t("Total Refundable")}
                                </Typography >
                            </Grid >
                            <Grid item xs={2}>
                                <Typography className={classes.tableQtyText}>
                                    {"-"}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography textAlign={"right"} className={classes.tableTotalText}>
                                    {(props?.requestState?.currency ? (props?.requestState?.currency + " ") : "") +
                                        (props?.requestState?.unitPrice?.totalrefundableTax?.toFixed(2) ?? "0.00")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} margin={"0px 12px"} className={classes.tableDivider} /> */}
                            <Grid item xs={5}>
                                <Typography className={classes.tableDescriptionText}>
                                    {t("Total Tax")}
                                </Typography >
                            </Grid >
                            <Grid item xs={2}>
                                <Typography className={classes.tableQtyText}>
                                    {"-"}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography textAlign={"right"} className={classes.tableTotalText}>
                                    {(props?.requestState?.currency ? (props?.requestState?.currency + " ") : "") +
                                        (props?.requestState?.unitPrice?.totalTax?.toFixed(2) ?? "0.00")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} margin={"0px 12px"} className={classes.tableDivider} />
                            <Grid item xs={5}>
                                <Typography className={classes.tableDescriptionText}>
                                    {t("Discount")}
                                </Typography >
                            </Grid >
                            <Grid item xs={2}>
                                <Typography className={classes.tableQtyText}>
                                    {"-"}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography textAlign={"right"} className={classes.tableTotalText}>
                                    {(props?.requestState?.currency ? (props?.requestState?.currency + " ") : "") +
                                        "0.00"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} margin={"0px 12px"} className={classes.tableDivider} />
                            <Grid item xs={5}>
                                <Typography className={classes.tableDescriptionText}>
                                    {t("Reservation Amount")}
                                </Typography >
                            </Grid >
                            <Grid item xs={2}>
                                <Typography className={classes.tableQtyText}>
                                    {"-"}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography textAlign={"right"} className={classes.tableTotalText}>
                                    {(props?.requestState?.currency ? (props?.requestState?.currency + " ") : "") +
                                        "0.00"}
                                </Typography>
                            </Grid>
                        </Grid >
                        <Grid item xs={12} className={classes.tableDivider} />
                        <Grid item xs={5}>
                            <Typography className={classes.tableTotalText}>
                                {t("Total")}
                            </Typography >
                        </Grid >
                        <Grid item xs={2} />
                        <Grid item xs={5}>
                            <Typography textAlign={"right"} className={classes.tableTotalText}>
                                {(props?.requestState?.currency ? (props?.requestState?.currency + " ") : "") +
                                    (((props?.requestState?.unitPrice?.totalAmount ?? 0.00) -
                                        (props?.requestState?.unitPrice?.totalrefundableTax ?? 0.00) +
                                        (props?.requestState?.unitPrice?.totalTax ?? 0.00))?.toFixed(2) ?? "0.00")}
                            </Typography>
                        </Grid>
                    </Grid >
                    {props?.requestState?.status?.value === enumName.pending &&
                        <div className={classes.viewDialogButtonContainer}>
                            <Button fullWidth className={classes.cancelButton}
                                onClick={() => {
                                    props?.setRequestState({
                                        ...props?.requestState,
                                        formType: "",
                                        acceptDeclineRemarks: ""
                                    })
                                    props?.setAcceptDeclineDialogOpen(false)
                                    props?.setAcceptDialogOpen(false)
                                    props?.setRequestDialogOpen(true)
                                }}>
                                {t("Cancel")}
                            </Button >
                            {props?.requestState?.occupiedUnits?.length === 0 &&
                                <Button fullWidth className={classes.acceptDeclineButton}
                                    onClick={props?.handleAcceptDecline}
                                    disabled={props?.isDisableBtn}>
                                    {t("Create")}
                                </Button >}
                        </div >}
                </Grid >
            </Grid >
        </div >
    </Dialog >
}