import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import React from 'react';
import { FontFamilySwitch } from '../../../utils';
import { CustomPaper, CustomTypography, TitleDesc } from '../components';
import { returnValue } from '../utils';

export const BankingDetailsView = (props) => {
    const { t } = props

    const theme = useTheme();

    const {
        bankingBasicDetails = null,
    } = props?.data ?? {};

    return (
        <div>
            <CustomPaper>
                <Grid container spacing={2} rowSpacing={3}>

                    <Grid item xs={12}>
                        <CustomTypography
                            fontSize={12}
                            fontFamily={FontFamilySwitch().bold}
                            // marginBottom={theme?.spacing(2)}
                            color={theme?.typography?.color?.secondary}
                        >
                            {t("Banking Basic Details")}
                        </CustomTypography>
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                        <TitleDesc
                            title={t("Account Type")}
                            desc={returnValue(bankingBasicDetails?.bankAccountType, '-')}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                        <TitleDesc
                            title={t("Account Number")}
                            desc={returnValue(bankingBasicDetails?.accountNumber, '-')}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                        <TitleDesc
                            title={t("Bank Name")}
                            desc={returnValue(bankingBasicDetails?.bankName, '-')}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                        <TitleDesc
                            title={t("Branch Name")}
                            desc={returnValue(bankingBasicDetails?.branchName, '-')}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                        <TitleDesc
                            title={t("Address 1")}
                            desc={returnValue(bankingBasicDetails?.addressLineOne, '-')}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                        <TitleDesc
                            title={t("Address 2")}
                            desc={returnValue(bankingBasicDetails?.addressLineTwo, '-')}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                        <TitleDesc
                            title={t("City")}
                            desc={returnValue(bankingBasicDetails?.city, '-')}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                        <TitleDesc
                            title={t("Country")}
                            desc={returnValue(bankingBasicDetails?.country?.label, '-')}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                        <TitleDesc
                            title={t("Bank Routing Type")}
                            desc={returnValue(bankingBasicDetails?.bankRoutingType?.label, '-')}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                        <TitleDesc
                            title={t("Bank Routing Code")}
                            desc={returnValue(bankingBasicDetails?.bankRoutingCode, '-')}
                        />
                    </Grid>

                    <Grid item xs={6} sm={4} md={3}>
                        <TitleDesc
                            title={t("Account Currency")}
                            desc={returnValue(bankingBasicDetails?.currency?.label, '-')}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                        <TitleDesc
                            title={t("Preferred Cash Collection Office")}
                            desc={returnValue(bankingBasicDetails?.preferredCashCollectionOffice, '-')}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                        <TitleDesc
                            title={t("Cheque Name")}
                            desc={returnValue(bankingBasicDetails?.chequeName, '-')}
                        />
                    </Grid>


                </Grid>
            </CustomPaper>
        </div >
    )
}