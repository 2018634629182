import React from 'react';
import CityProperty from '../../assets/cityproperty';
import ContractProperty from '../../assets/contractproperty';
import ImagesProperty from '../../assets/imagesproperty';
import { PropertyTabs, Subheader } from "../../components";
import { useNavigate, useLocation } from 'react-router-dom';
import { Routes } from "../../router/routes";
import { contactViewStyles } from "./style";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { accessCheckRender, AlertProps, getRoutePermissionNew, NetWorkCallMethods } from "../../utils";
import { NationalIDDetails } from './tabs/nationalIDDetails';
import { GeneralDetails } from './tabs/generalDetails';
import { BankDetails } from './tabs/bankDetails';
import { withTranslation } from 'react-i18next';

const ContactView = ({
    t = () => false
}) => {
    const contactID = new URLSearchParams(useLocation().search).get("contactID");
    const [value, setValue] = React.useState(1);
    const [contactDetails, setContactDetails] = React.useState(null)
    const classes = contactViewStyles()
    const navigate = useNavigate()
    const backdrop = React.useContext(BackdropContext);
    const alert = React.useContext(AlertContext);
    const auth = React.useContext(AuthContext)
    const [isViewerOpen, setIsViewerOpen] = React.useState(false);
    const [currentImage, setCurrentImage] = React.useState("");
    const handleChange = (event, newValue) => { setValue(newValue); };
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                if (contactID) { getContactDetailsByID() }
            }
        }
        // eslint-disable-next-line
    }, [auth, contactID]);

    const openImageViewer = React.useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    const tabTittle = [
        {
            label: 'General Details',
            imgSelect: <CityProperty color={value === 1 ? '#5078E1' : '#98A0AC'} />,
            value: 1,
            className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
        },
        {
            label: 'National ID Details',
            imgSelect: <ImagesProperty color={value === 2 ? '#5078E1' : '#98A0AC'} />,
            value: 2,
            className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
        },
        {
            label: 'Banking Details',
            imgSelect: <ContractProperty color={value === 3 ? '#5078E1' : '#98A0AC'} />,
            value: 3,
            className: value === 3 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 3 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 3 ? classes.subtabtitle1 : classes.subtabtitle
        },
    ]
    const handleedit = () => {
        navigate(Routes.createContact, {
            state: {
                main: {
                    id: contactDetails?.id,
                    isEdit: true
                },
            },
        });
    }
    const getContactDetailsByID = () => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading", });
        let datas = { tenantId: `${config.tenantId}`, id: contactID }
        NetworkCall(
            `${config.api_url}/contact/get`,
            NetWorkCallMethods.post,
            datas, null, true, false
        ).then((res) => {
            let tempContactDetails = res?.data?.data
            setContactDetails(tempContactDetails)
            backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
        }).catch((error) => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Internal error. Please try again later."),
            });
        });
    }

    const render = () => {
        return <>
            <Subheader title={contactDetails?.first_name} goBack={() => navigate(-1)} />
            <PropertyTabs isEdit={permission?.update} value={value} handleChange={handleChange} t={t}
                handleedit={handleedit}
                tabTittle={tabTittle}
                tab1={<GeneralDetails data={contactDetails} closeImageViewer={closeImageViewer}
                    openImageViewer={openImageViewer}
                    isViewerOpen={isViewerOpen}
                    currentImage={currentImage}
                    t={t}
                />}
                tab2={<NationalIDDetails data={contactDetails} t={t} />}
                tab3={<BankDetails data={contactDetails} t={t} />}
            />
        </>
    }
    return (
        <>
            {accessCheckRender(render, permission)}
        </>
    )
}
export default withTranslation("contacts")(ContactView)
