import { Box, Typography,Grid,Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { remCalc, FontFamilySwitch } from "../../../utils";

const useStyles = makeStyles((theme) => ({
    root:{
       backgroundColor:"white",
    },
    yes:{
        backgroundColor:"#5078E1",
        borderRadius:"4px",
        fontSize:remCalc(14),
        fontFamily: FontFamilySwitch().semiBold,
        boxShadow:"none"
    },
    no:{
        border:"1px solid #D3D9E6",
        borderRadius:"4px",
        fontSize:remCalc(14),
        fontFamily: FontFamilySwitch().semiBold,
        boxShadow:"none",
        color:"#091B29",
        backgroundColor:"white",
       "&:hover":{
        color:"#091B29",
        backgroundColor:"white"
       }
    },
    title:{
        fontSize:remCalc(14),
        fontFamily: FontFamilySwitch().semiBold,
        color:"#091B29",

    }
  }));

export const StatusComponent=({data={},status=null,t=()=>false,onClose=()=>false,onChangeStatus=()=>false,isDisableBtn=false})=>{

    const classes=useStyles();
    

    return(
     
        <Box p={2} className={classes.root}>
            <Typography className={classes.title}>{t("Are You Sure Want To")} {t(status==="Approved"?"Approve":"Reject")} ?</Typography>
            <Grid container spacing={1} mt={1}>
                <Grid item xs={6}>
                    <Button variant="contained" 
                    onClick={()=>{
                        onChangeStatus({id:data?.id,status})
                    }}
                    
                    fullWidth className={classes.yes} disabled={isDisableBtn}>{t("Yes")}</Button>
                </Grid>
                <Grid item xs={6}>
                <Button fullWidth className={classes.no}  disabled={isDisableBtn} onClick={onClose}>{t("No")}</Button>
                </Grid>
            </Grid>

        </Box>

    )
}