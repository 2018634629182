import React from "react"
import { Steps } from "../../../screens/residentOnboarding/steps"
import ContactIcon from "../../../screens/residentOnboarding/utils/contactIcon"
import { boardingPropertyType, boardingPropertyTypeArabic } from "../../../screens/residentOnboarding/utils/residentOnboard"
import { enumSelect, enum_types, wrapLabelsInT } from "../../../utils"
import { returnremoveExisitContact } from "../utils"
import { AuthContext } from "../../../contexts"

export const Contact = ({ dataNew, updateStateNew, company_id, setDataNew, is_read_only, is_quotation = false, is_convert = false, t = () => false, moduleId = "" }) => {

    const [enumValue, setEnumValue] = React.useState({
        revenue_type: []
    })
    const auth = React.useContext(AuthContext);
    const accessData = auth?.auth?.auth?.access;
    const moduleName = Object.keys(accessData || {})[0];
    const structureArray = accessData?.[moduleName]?.role?.permissions?.[0]?.structure || [];
    
    let moduleType = ""; 
    structureArray.forEach((item) => {
      if (!moduleType && item?.children?.length) {
        item.children.forEach((child) => {
          if (child?.module_type_meta_data?.includes("Lease")) {
            moduleType = "Lease";
          } else if (child?.module_type_meta_data?.includes("Sale")) {
            moduleType = "Sale";
          }
        });
      }
    });

    const is_workspace = ['49', '35'].includes(moduleId)

    //get Enum
    const getEnum = async () => {
        const result = await enumSelect([enum_types.revenue_type, enum_types.purpose_global_type])
        if (moduleType === "Lease") {
            setEnumValue({
                revenue_type: result?.revenue_type?.filter((x) => x?.value === "Lease"),
            })
        } else if (moduleType === "Sale") {
            setEnumValue({
                revenue_type: result?.revenue_type?.filter((x) => x?.value === "Sale"),
            })
        } else if (is_workspace) {
            setEnumValue({
                revenue_type: result?.revenue_type?.filter((x) => x?.value === "Lease"),
            })
        } else {
            setEnumValue({
                revenue_type: result?.revenue_type?.filter((x) => x?.value !== "Stay"),
            })
        }
    }

    const handleContact = (val) => {
        let data = returnremoveExisitContact(val);

        setDataNew({
            ...dataNew,
            ...data,
            is_exist: val
        })

    }

    React.useEffect(() => {
        getEnum()
        if(is_workspace){
            updateStateNew("purpose",{title:"Commercial",label:"Commercial",value:"Commercial"},"revenue_type",{label:"Lease",value:"Lease"})
        }
        //eslint-disable-next-line
    }, [])

    React.useEffect(() => {
        if (moduleType === "Lease") {
            setDataNew((prevState) => ({
                ...prevState,
                revenue_type: { value: "Lease", label: "Lease" }
            }));
        } else if (moduleType === "Sale") {
            setDataNew((prevState) => ({
                ...prevState,
                revenue_type: { value: "Sale", label: "Sale" }
            }));
        }
    }, [moduleType]);

    const contactOprions = [
        {
            label: t("Existing Contact"),
            icon: <ContactIcon />,
            value: true
        },
        {
            label: t("Create New Prospect"),
            icon: <ContactIcon />,
            value: false
        },
    ]

    return (
        <>
            <Steps
                t={t}
                component={[
                    {
                        type: "propertyType",
                        data: is_workspace ? boardingPropertyType?.filter((x) => { return x?.value !== "Residential" }) : boardingPropertyTypeArabic(t),
                        selected: dataNew?.purpose,
                        handleClick: (value) => updateStateNew("purpose", value),
                        header: t("Purpose"),
                        error: dataNew?.error?.purpose?.length > 0,
                        errorMsg: dataNew?.error?.purpose,
                        isActive: !is_quotation,
                        isRequired: true,
                        isReadOnly: is_read_only,
                    },
                    {
                        type: "toggleButton",
                        header: t("Revenue Type"),
                        value: dataNew?.revenue_type?.value,
                        handleChange: (value) => updateStateNew("revenue_type", { value: value, label: value }),
                        option: wrapLabelsInT(enumValue?.revenue_type, t),
                        isActive: !is_quotation,
                        isrequired: true,
                        isError: dataNew?.error?.revenue_type?.length > 0,
                        errorMessage: dataNew?.error?.revenue_type,
                        isReadOnly: is_read_only,
                        sm: 12,
                        md: 12,
                        lg: 12
                    },
                    {
                        type: "contactTypeSelect",
                        onChange: (value) => handleContact(value),
                        value: dataNew?.is_exist,
                        isActive: !is_convert,
                        options: contactOprions
                    }
                ]}
            />
        </>
    )
}