import React from "react";
import { useNavigate } from "react-router-dom";
import { withNavBars } from "../../HOCs";
import { Routes } from "../../router/routes";
import { ConfigurationMaster } from "./configurationMaster";
import { ConfigurationMasterViewEdit } from "./ConfigurationMasterViewEdit";

class ConfigurationMasterParentOne extends React.Component {
  render() {
    const { match } = this.props;
    return (
      <>
        {match.path === Routes.configuration && (
          <ConfigurationMaster {...this.props} />
        )}
        {match.path === Routes.configuration_details && (
          <ConfigurationMasterViewEdit {...this.props} />
        )}
        {match.path === Routes.configuration_add && (
          <ConfigurationMasterViewEdit {...this.props} />
        )}
      </>
    );
  }
}
const props = {
  boxShadow: false,
};

const WrappedComponent = withNavBars(ConfigurationMasterParentOne, props);
const ConfigurationMasterParent = (props) => {
  const navigate = useNavigate();
  return <WrappedComponent {...props} navigate={navigate} />;
};

export default ConfigurationMasterParent;
