import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Box,
    Checkbox,
    Divider,
    Grid,
    IconButton,
    Stack,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { FontFamilySwitch } from "../../utils";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "12px",
        border: "1.2px solid #E4E8EE",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        padding: "10px"
    },
    root2: {
        marginTop: "12px",
        border: "1.2px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        padding: "10px"
    },
    title: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary,

    },
    address: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().semiBold,

    },
    sqft: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,

    },
    amount: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
    },
    img: {
        height: "68px",
        width: "68px",
        objectFit: "contain",
    },
    delbtn: {
        border: "1px solid #E4E8EE",
        backgroundColor: "white",
    },
    breakup: {
        color: "#5078E1",
        textDecoration: "underline",
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        cursor: "pointer",
    },
    shortlist: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        marginLeft: "6px",
        padding: "4px 4px",
        backgroundColor: "#FFE6C9",
        color: "#E29336",
        borderRadius: theme.palette.borderRadius,
    },
    data: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.tertiary,
        marginTop: "6px",
    },
    datavalue: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.secondary,
        marginTop: "6px",
    },
}));

export const UnitList = (props) => {
    const classes = useStyles(props);

    return (
        <>
            <div className={props.breakup ? classes.root : classes.root2}>
                <Grid container spacing={3} alignItems="center" onClick={props.onClick}>
                    <Grid item xs={3} md={3} lg={2}>
                        <img
                            src={props.data.image}
                            alt={props.data.title}
                            className={classes.img}
                        />
                    </Grid>
                    <Grid item xs={9} md={9} lg={10}>
                        <Box display="flex" alignItems={props.Pending ? null : "center"}>
                            <Box flexGrow={1}>
                                <Typography className={classes.title}>
                                    {props.data.title}
                                    {props.data.shortlist && (
                                        <span className={classes.shortlist}>
                                            {props.data.shortlist}&nbsp;Shortlisted
                                        </span>
                                    )}
                                </Typography>
                                <Typography className={classes.address}>
                                    {props.data.address}
                                </Typography>
                            </Box>
                            <Box>
                                {props.Pending &&
                                    <>
                                        {props.index === 0 &&
                                            <div className={classes.shortlist}>
                                                Move In Update Pending
                                            </div>}
                                    </>}
                                {props.data.amount &&
                                    <Typography className={classes.amount}>
                                        $&nbsp;{props.data.amount}
                                    </Typography>}

                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Box flexGrow={1}>
                                <Stack
                                    direction="row"
                                    divider={<Divider orientation="vertical" flexItem />}
                                    spacing={1}
                                >
                                    <Box display="flex" alignItems="center">
                                        <img src="/images/SQ FT.svg" alt="sqft" />
                                        <Typography className={classes.sqft} noWrap>
                                            &nbsp;&nbsp;{props.data.sqft} &nbsp;Sqft
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <img src="/images/Bedroom.svg" alt="bed" />
                                        <Typography className={classes.sqft} noWrap>
                                            &nbsp;&nbsp;{props.data.sqft} &nbsp;Bedroom
                                        </Typography>
                                    </Box>
                                    {/* {props.breakup && (
                                    <Box display="flex" alignItems="center">
                                        <Typography className={classes.breakup} noWrap>
                                            Break Up
                                        </Typography>
                                    </Box>
                                )} */}
                                </Stack>
                            </Box>
                            <Box>
                                {props.checked && (
                                    <Checkbox
                                        checkedIcon={<CheckCircleIcon />}
                                        icon={<CheckCircleOutlineIcon style={{ color: "#c1c1c1" }} />}
                                    />
                                )}
                                {props.delete && (
                                    <IconButton size="small" className={classes.delbtn}>
                                        <DeleteForeverOutlinedIcon
                                            style={{ color: "red", fontSize:"1rem" }}
                                        />
                                    </IconButton>
                                )}
                            </Box>
                        </Box>
                    </Grid>

                </Grid>

            </div>
            {props.breakup &&
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: "#071741" }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.title}>Rental Break Up </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <Box display="flex" alignItems="center">
                                <Box flexGrow={1}>
                                    {" "}
                                    <Typography className={classes.data}>
                                        Base Rent (Monthly)
                                    </Typography>
                                </Box>
                                <Box>
                                    {" "}
                                    <Typography className={classes.datavalue}>$1,000</Typography>
                                </Box>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Box flexGrow={1}>
                                    {" "}
                                    <Typography className={classes.data}>
                                        Maintanence (Monthly - 10%)
                                    </Typography>
                                </Box>
                                <Box>
                                    {" "}
                                    <Typography className={classes.datavalue}>$100</Typography>
                                </Box>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Box flexGrow={1}>
                                    {" "}
                                    <Typography className={classes.data}>
                                        Deposit (Onetime - Refundable)
                                    </Typography>
                                </Box>
                                <Box>
                                    {" "}
                                    <Typography className={classes.datavalue}>$1000</Typography>
                                </Box>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Box flexGrow={1}>
                                    {" "}
                                    <Typography className={classes.data}></Typography>
                                </Box>
                                <Box>
                                    {" "}
                                    <Typography className={classes.totalcostpreview}>
                                        Total&nbsp;$1200
                                    </Typography>
                                </Box>
                            </Box>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            }
        </>
    );
};
