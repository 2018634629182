import React from "react";
import { Box, Typography, Button, Stack } from "@mui/material";
import FinancialCardSVG from "../components/FinancialCardSVG";
import { AuthContext} from '../../../contexts';
import { ReceiptStyles } from "../styles";


const FinancialCards = ({ t, toggleInvoiceTable, type, outstandingBalance, count, unusedBalance }) => { 
  const classes = ReceiptStyles();
  const auth = React.useContext(AuthContext);
  

  const handleToggleInvoiceTable = () => {
    toggleInvoiceTable();
  };

  return (
    <Stack direction="row" spacing={2} pr={1} className={auth?.auth?.auth?.language === "ar" ? classes.profileCardArabic : classes.profileCard}>
      {/* Outstanding Balance Card */}
      <Box sx={{ width: "12.5rem", height: "8.75rem", position: "relative" }}>
        <FinancialCardSVG fillColor="#FFE6E6" strokeColor="#E83232" />
        <Box
          sx={{
            position: "relative",
            zIndex: 1,
            height: "100%",
            p: "1rem",
            boxSizing: "border-box",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: "0.5rem" }}>
            <Typography color="#E83232" fontWeight="bold" fontSize="0.875rem">
              {outstandingBalance}
            </Typography>
            <img src="/images/outStanding_balance.svg" alt="Outstanding Balance" />
          </Box>

          <Typography color="text.primary" fontWeight="medium" fontSize="0.875rem" sx={{ mb: "1rem" }}>
            {t("Outstanding Balance")}
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            {type !== "invoice" && (
              <Button
                variant="contained"
                sx={{
                  width: "3.375rem",
                  height: "1.5rem",
                  backgroundColor: "#D6664B",
                  color: "white",
                  textTransform: "none",
                  borderRadius: "0.625rem",
                  boxShadow: "none",
                  padding: "0.3125rem 0.625rem",
                  "&:hover": {
                    backgroundColor: "#C06A5A",
                    boxShadow: "none",
                  },
                }}
                onClick={handleToggleInvoiceTable}
              >
                {t("View")}
              </Button>
            )}
            <Typography color="text.secondary" fontSize="0.75rem">
               {`${count} ${count > 1 ? t("Invoices") : t("Invoice")}`}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Unsettled Amount Card */}
      <Box sx={{ width: "12.5rem", height: "8.75rem", position: "relative" }}>
        <FinancialCardSVG fillColor="#FFF3DF" strokeColor="#FF9D00" />
        <Box
          sx={{
            position: "relative",
            zIndex: 1,
            height: "100%",
            p: "1rem",
            boxSizing: "border-box",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: "0.5rem" }}>
            <Typography color="#FF9D00" fontWeight="bold" fontSize="0.875rem">
              {unusedBalance}
            </Typography>
            <img src="/images/unsettled_amount.svg" alt="Unsettled Amount" />
          </Box>

          <Typography color="text.primary" fontWeight="medium" fontSize="0.875rem">
            {t("Unsettled Amount")}
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};

export default FinancialCards;
