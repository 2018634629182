import { TabContext, TabList } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Tab,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import BillingAmountIcon from "../../../assets/billingamtIcon";
import CloseIcon from "../../../assets/closeIcon";
import NoDataFound from "../../../assets/noData";
import { TextBox, QuotationSummery, LoadingSection } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, NetWorkCallMethods, stringAvatar, timeZoneConverter} from "../../../utils";
import { SettlementAmountCard } from "../../finalSettlement/components/settlementAmountCard";
import FinalSettleIcon from "../../finalSettlement/utils/finalSettlementIcon";
import { useStyles as useStyles1 } from "../../requestView/steps/style";
import { CostingApprovalStyles } from "../style";
export const PreviewScreen = ({
  approvalStatus = "Pending",
  t,
  data = {},
  timelineData = [],
  setCloseDrawer = () => false,
  reload = () => false,
  itemDetails = {},
  handleIcon = () => false,
  quotationDetails,
  agreementDetails,
  loader,
  approvalTimeline
}) => {
  const classes = CostingApprovalStyles();
  const classes1 = useStyles1();
  const [tabValue, setTabValue] = React.useState("1");
  const [showNote, setShowNote] = React.useState(false);
  const [notesData, setNotesData] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const alert = React.useContext(AlertContext);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleChangeNotes = (val) => {
    setNotesData(val);
  };
  const updateRequest = (costing_status) => {
    const payload = {
      request_id:
        data?.source === "Maintenance"
          ? data?.maintenance_request_id
          : data?.source === "General"
          ? data?.general_request_id
          : data?.agreement_id,
      type: data?.source,
      comments: notesData,
      costing_status: costing_status,
      bom_type: "Actual",
    };
    const handleChangeNotes = (val) => {
        setNotesData(val)
    }
    const updateRequest = (costing_status) => {
        const payload = {
            "request_id": data?.source === "Maintenance" ? data?.maintenance_request_id : data?.source === "General" ? data?.general_request_id : data?.agreement_id,
            "type": data?.source,
            "comments": notesData,
            "costing_status": costing_status,
            "bom_type":"Actual"
        }
        NetworkCall(
            `${config.api_url}/request_costing/update_costing_request`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                setCloseDrawer(false)
                reload()
                alert.setSnack({
                    ...alert, open: true, msg: `Costing Request is ${costing_status} Successfully`,
                    severity: AlertProps.severity.success
                })
            })
            .catch((error) => {
                setCloseDrawer(false)
                if (error.response) {

                    // Request made and server responded
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: Object.keys(error.response.data.error.message).length !== 0 ? error.response.data.error.message : "Something went wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });

                }
                else {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Something went wrong please try again"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });
                }

            });
    }
    return (
        <Box width="500px">
            <Box position={"relative"}>
                <Box display={"flex"} justifyContent={"space-between"} p={1} sx={{backgroundColor:"#F2F4F7"}}>
                    <Typography>{t("Preview")}</Typography>
                    <Box onClick={() => setCloseDrawer(false)} style={{ cursor: "pointer" }}><CloseIcon /></Box>
                </Box>
                <Divider />
                <Box>
                    <TabContext value={tabValue}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange}>
                                <Tab label={t("Details")} value={"1"} />
                                <Tab label={t("Requestor")} value={"2"} />
                                {
                                    data?.status !== "Pending" &&
                                    <Tab label={t("Approver")} value={"3"} />
                                }

                                <Tab label={t("Timeline")} value={"4"} />
                            </TabList>
                        </Box>
                    </TabContext>
                </Box>

                {tabValue === "1" && data?.source !== "Final Settlement" &&
                    <Box p={2}>
                        <Box className={classes.requestDetailsBox} alignItems={"center"}>
                            <Box display="flex" justifyContent={"space-between"} alignItems="center">
                                <Typography className={classes.requestTitle}>{t(data?.source) ?? "-"}</Typography>
                                <Typography className={classes.statusBox} style={{ backgroundColor: data?.status === t("Pending") ? "#78B1FE" : data?.status === t("Approved") ? "#5AC782" : "#FF4B4B" }}>{t(data?.status)}</Typography>
                            </Box>
                            <Stack direction="row" spacing={1} alignItems={"center"}>
                                <Typography className={classes.requestNo}>{data?.request_no}</Typography>
                                <Box className={classes.requestdot}></Box>
                                <Typography className={classes.requestNo}>{data?.maintenance_request_no ?? data?.general_request_no ?? data?.agreement_no ?? "-"}</Typography>
                            </Stack>
                        </Box>
                        {itemDetails?.items?.length > 0 &&
                            <>
                                <Box mt={1} mb={1}>
                                    <Typography className={classes.BillingAmountTxt}>{t("Billing Amount")}</Typography>
                                </Box>
                                <Box mt={1} className={classes.requestDetailsBox}>
                                    <Box display="flex" justifyContent="space-between" alignItems={"center"}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <BillingAmountIcon />
                                            <Box>
                                                <Typography className={classes.requestTitle}>{itemDetails?.symbol} {itemDetails?.total_amount_after_tax ?? 0} </Typography>
                                                <Typography className={classes.requestNo}>{t("Final Amount")}</Typography>
                                            </Box>
                                        </Stack>
                                        <Box onClick={() => handleIcon("info", data)} style={{ cursor: "pointer" }}>
                                            <Typography className={classes.viewDetailsTxt}>{t("View Details")}</Typography>
                                        </Box>
                                    </Box>
                                    <Box mt={1} mb={1.5}>
                                        <Divider />
                                    </Box>
                                    <Box position={"relative"}>
                                        <Box mt={1} maxHeight={"180px"} overflow={"scroll"}>
                                            {itemDetails?.items?.map((val) => {
                                                return (
                                                    <Grid container spacing={2} alignItems="center" mb={1}>
                                                        <Grid item xs={6}>
                                                            <Typography className={classes.itemName}>{val?.inspection_item_name}</Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography className={classes.requestNo}>{val?.quatity} {t("QTY")}</Typography>
                                                        </Grid>
                                                        <Grid item xs={4} justifyContent={"end"} display={"flex"}>
                                                            <Typography className={classes.itemName}>{itemDetails?.symbol} {val?.line_amount}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}

                                            {itemDetails?.deduction?.length > 0 &&

                                                <>
                                                    <Box mt={1} mb={1}>
                                                        <Divider />
                                                    </Box>
                                                    <Box mb={1}>
                                                        <Typography className={classes.BillingAmountTxt}>{t("Deduction")}</Typography>
                                                    </Box>
                                                    {itemDetails?.deduction?.map((x) => {
                                                        return (
                                                            <Grid container spacing={2} alignItems="center" mb={1}>
                                                                <Grid item xs={6}>
                                                                    <Typography className={classes.itemName}>{x?.description}</Typography>
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <Typography className={classes.requestNo}>-</Typography>
                                                                </Grid>
                                                                <Grid item xs={4} justifyContent={"end"} display={"flex"}>
                                                                    <Typography className={classes.itemName}>{" - "} {itemDetails?.symbol} {x?.amount}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    })}
                                                    <Box mt={1} mb={1}>
                                                        <Divider />
                                                    </Box>
                                                </>
                                            }
                                        </Box>

                                        <Box display={"flex"} justifyContent={"space-between"} mt={1}>
                                            <Typography className={classes.finalamttext}>{t("Final Amount")}</Typography>
                                            <Typography className={classes.finalamttext}>{itemDetails?.symbol} {itemDetails?.total_amount_after_tax ?? 0}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </>
                        }
                    </Box>
                }
                {tabValue === "4" &&
                    <Box p={2}>

                        {timelineData?.length > 0 ? timelineData?.map((x, index, array) => {
                            return (
                                <Box className={classes1.treeBox} pl={2} pr={2}>
                                    <Box className={timelineData?.length - 1 !== index ? classes.border : classes.applynoborder}>
                                        <Avatar
                                            variant="circular"
                                            className={classes1.avatars1}
                                        >
                                            <Avatar
                                                variant="circular"
                                                style={{
                                                    height: "100%",
                                                    width: "100%",
                                                    backgroundColor: "#5078E1",
                                                }}
                                            >
                                                <img
                                                    src={
                                                        x?.status === "Rejected"
                                                            ? "images/rejected.svg"
                                                            : "images/Path 953.svg"
                                                    }
                                                    alt="document"
                                                />
                                            </Avatar>
                                        </Avatar>
                                        <Box height={'4px'} />

                                        <Typography
                                            className={classes1.title}
                                        >
                                            {t(x?.status)}
                                        </Typography>

                                        <Box height={'2px'} />
                                        <Typography
                                            className={classes1.dateTime}
                                        >
                                            {x?.comments}

                                        </Typography>
                                        <Stack direction="row" spacing={1} alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
                                            <Typography
                                                className={classes1.dateTime}
                                            >
                                                {x?.created_at ? moment(x?.created_at).format("DD MMM YY hh:mm a") : "-"
                                                }
                                            </Typography>
                                            {x.created_by?.length > 0 &&
                                                <Typography className={classes1.dateTime}>{x?.created_by}</Typography>
                                            }
                                            {x.updated_by?.length > 0 &&
                                                <Typography className={classes1.dateTime}>{x?.updated_by}</Typography>
                                            }
                                        </Stack>
                                        <Box height={'15px'} />



                                        {
                                            timelineData?.length !== index + 1 && <Box height={'12px'} />
                                        }
                                    </Box>
                                </Box>
                            )
                        })
                            :
                            <Box mt={4} display={"flex"} justifyContent={"center"}>
                                <NoDataFound />
                            </Box>

                        }
                    </Box>
                }
                {(tabValue === "2" || tabValue === "3") &&
                    <Box p={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography className={classes.requestedHdrLabels}>{t("Image")}</Typography>
                            </Grid>
                            <Grid item xs={6} alignItems={"end"} display="flex" justifyContent={"end"}>
                                <Avatar variant="circle" src={tabValue === "2" ? data?.requestedBy?.image_url : data?.updatedBy?.image_url} className={classes.requestorImage} {...stringAvatar(tabValue === "2" ? data?.requestedBy?.requested_by_first_name : data?.updatedBy?.approved_by_first_name)} />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.requestedHdrLabels}>{t("Name")}</Typography>
                            </Grid>
                            <Grid item xs={6} alignItems={"end"}>
                                <Typography className={classes.requestedValues}>{tabValue === "2" ? data?.requestedBy?.requested_by_first_name : data?.updatedBy?.approved_by_first_name}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.requestedHdrLabels}>{t("Job")}</Typography>
                            </Grid>
                            <Grid item xs={6} alignItems={"end"}>
                                <Typography className={classes.requestedValues}>{tabValue === "2" ? data?.requestedBy?.job_title : data?.updatedBy?.job_title}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.requestedHdrLabels}>{t("Department")}</Typography>
                            </Grid>
                            <Grid item xs={6} alignItems={"end"}>
                                <Typography className={classes.requestedValues}>{t(tabValue === "2" ? data?.requestedBy?.department : data?.updatedBy?.department)}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent={"space-between"} alignItems="center">
                                    <Box>
                                        <Typography className={classes.requestedHdrLabels}>{t("Reporting To")}</Typography>
                                    </Box>
                                    <Box className={classes.reportingBox}>
                                        <Avatar variant="circle" src={data?.requestedBy?.reporting_to_image_url} className={classes.reportingPersonImg} {...stringAvatar(data?.requestedBy?.reporting_to_first_name)} />
                                        <Typography className={classes.requestedValues}>{data?.requestedBy?.reporting_to_first_name}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                }
                {tabValue === "1" && data?.source === "Final Settlement" &&
                    <Box p={2}>
                        <Box className={classes.gridBox} p={2}>
                            <Stack direction={"row"} alignItems={"center"} pb={"16px"}>
                                <FinalSettleIcon style={{ marginInlineEnd: "16px" }} />
                                <Typography className={classes.title}>{t("Final Settlement")}</Typography>
                            </Stack>
                            <Box style={{ backgroundColor: "#E4E8EE", height: "1.1px" }} />
                            <Stack spacing={'10px'} padding={'12px 0px'} maxHeight={'250px'} overflow={'auto'}>
                                <Stack direction={"row"} justifyContent={"space-between"} spacing={'10px'}>
                                    <Typography className={classes.breakupTitle}>{t("Billed Invoices")}</Typography>
                                    <Typography className={classes.breakupContent}>{`${Number(data?.finalSettlement?.billed_invoice ?? 0).toFixed(2)} ${data?.finalSettlement?.symbol ?? ""}`}</Typography>
                                </Stack>
                                <Stack direction={"row"} justifyContent={"space-between"}>
                                    <Typography className={classes.breakupTitle}>{t("Receipts & Credit Notes")}</Typography>
                                    <Typography className={classes.breakupContent}>{`${Number(data?.finalSettlement?.amount_paid ?? 0).toFixed(2)} ${data?.finalSettlement?.symbol ?? ""}`}</Typography>
                                </Stack>
                                <Stack direction={"row"} justifyContent={"space-between"}>
                                    <Typography className={classes.breakupTitle}>{t("Refundables")}</Typography>
                                    <Typography className={classes.breakupContent}>{`${Number(data?.finalSettlement?.refundable_total ?? 0).toFixed(2)} ${data?.finalSettlement?.symbol ?? ""}`}</Typography>
                                </Stack>
                                <Stack direction={"row"} justifyContent={"space-between"}>
                                    <Typography className={classes.breakupTitle}>{t("Amount Due")}</Typography>
                                    <Typography className={classes.breakupContent}>{`${Number(data?.finalSettlement?.due_amount ?? 0).toFixed(2)} ${data?.finalSettlement?.symbol ?? ""}`}</Typography>
                                </Stack>
                                <Stack direction={"row"} justifyContent={"space-between"}>
                                    <Typography className={classes.breakupTitle}>{t("Void invoices")}</Typography>
                                    <Typography className={classes.breakupContent}>{`${Number(data?.finalSettlement?.void_invoice ?? 0).toFixed(2)} ${data?.finalSettlement?.symbol ?? ""}`}</Typography>
                                </Stack>
                                <Stack direction={"row"} justifyContent={"space-between"}>
                                    <Typography className={classes.breakupTitle}>{t("Unbilled Invoices")}</Typography>
                                    <Typography className={classes.breakupContent}>{`${Number(data?.finalSettlement?.unbilled_invoice ?? 0).toFixed(2)} ${data?.finalSettlement?.symbol ?? ""}`}</Typography>
                                </Stack>
                            </Stack>

                            <Box style={{ backgroundColor: "#E4E8EE", height: "1.1px" }} />

                            <Stack direction={"row"} padding={"12px 0px"}>
                                <Box sx={{ width: "50%" }}>
                                    <SettlementAmountCard
                                        backgroundColor="#5AC782"
                                        title={t("AMOUNT TO BE PAID TO CUSTOMER")}
                                        amount={`${Number(data?.finalSettlement?.amount_to_be_paid ?? 0).toFixed(2)} ${data?.finalSettlement?.symbol ?? ""}`}
                                        type='paid' />
                                </Box>
                                <Box sx={{ width: "50%", marginInlineStart: "16px" }}>
                                    <SettlementAmountCard
                                        backgroundColor="#FF4B4B"
                                        title={t('AMOUNT TO BE RECOVERED FROM CUSTOMER')}
                                        amount={`${Number(data?.finalSettlement?.amount_recovered ?? 0).toFixed(2)} ${data?.finalSettlement?.symbol ?? ""}`}
                                    />
                                </Box>
                            </Stack>
                        </Box>
                    </Box>
                }
                {tabValue === "1" && showNote && approvalStatus === "Pending" ?
                    <Box p={2}>
                        <Box mt={2} className={classes.notesBox}>
                            <Box p={2} display={"flex"} justifyContent={"space-between"}>
                                <Typography className={classes.notesLabel}>{t("Notes")}</Typography>
                                <Box onClick={() => setShowNote(false)}><Typography className={classes.removeTxt}>{t("Remove")}</Typography></Box>
                            </Box>
                            <Divider />
                            <Box p={1}>
                                <TextBox
                                    label=""
                                    placeholder={t("Type Notes here")}
                                    value={notesData}
                                    onChange={(e) => handleChangeNotes(e?.target?.value)}
                                    color={"white"}
                                    border={0}
                                    multiline
                                />
                            </Box>
                        </Box>
                    </Box>
                    : approvalStatus === "Pending" && tabValue === "1" &&
                    <Box mt={2} onClick={() => setShowNote(true)} p={2}>
                        <Typography className={classes.addNote}>+ {t("Add Note")}</Typography>
                    </Box>
                }

            </Box >
            {tabValue === "1" && approvalStatus === "Pending" &&
                <Box className={classes.fixBottom}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button variant="contained" fullWidth className={classes.rejectbtn} onClick={() => updateRequest("Rejected")}>{t("Reject")}</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" fullWidth className={classes.approvebtn} onClick={() => updateRequest("Approved")}>{t("Approve")}</Button>
                        </Grid>
                    </Grid>
                </Box>
            }
        </Box >
    )
      .then((res) => {
        setLoading(false);
        setCloseDrawer(false);
        reload();
        alert.setSnack({
          ...alert,
          open: true,
          msg: `Costing Request is ${costing_status} Successfully`,
          severity: AlertProps.severity.success,
        });
      })
      .catch((error) => {
        setCloseDrawer(false);
        if (error.response) {
          // Request made and server responded
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg:
              Object.keys(error.response.data.error.message).length !== 0
                ? error.response.data.error.message
                : "Something went wrong",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Something went wrong please try again"),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        }
        setLoading(false);
      });
  };

  const updateRequestStatus = (status) => {
    const type = data?.quotation_id ? "Quotation" : "Agreement";
    const payload = {
      id: data?.awd_id,
      status: status,
      updated_by: localStorage.getItem("profileID"),
      comments: notesData,
    };
    NetworkCall(
      `${config.api_url}/approval_workflow/update_approval_request_status`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setLoading(false);
        setCloseDrawer(false);
        reload();
        alert.setSnack({
          ...alert,
          open: true,
          msg: `${type} Request is ${status} Successfully`,
          severity: AlertProps.severity.success,
        });
      })
      .catch((error) => {
        setCloseDrawer(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went wrong please try again"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        setLoading(false);
      });
  };

  return (
    
    <Box width="500px">
      {loader ? (
        <LoadingSection/>
      ):(
        <>
      <Box position={"relative"}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          p={1}
          sx={{ backgroundColor: "#F2F4F7" }}
        >
          <Typography>{t("Approval Details")}</Typography>
          <Box
            onClick={() => setCloseDrawer(false)}
            style={{ cursor: "pointer" }}
          >
            <CloseIcon />
          </Box>
        </Box>
        <Divider />
        <Box>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleTabChange}>
                <Tab label={t("Details")} value={"1"} />
                <Tab label={t("Requestor")} value={"2"} />
                {data?.status !== "Pending" && (
                  <Tab label={t("Approver")} value={"3"} />
                )}

                <Tab label={t("Timeline")} value={"4"} />
              </TabList>
            </Box>
          </TabContext>
        </Box>
       
        {tabValue === "1" &&
          (data?.source === "Quotation Approval" ||
            data?.source === "Agreement Order Confirmation Approval") && (
            <Box p={2}>
              <Box className={classes.requestDetailsBox} alignItems={"center"}>
                <Box
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems="center"
                >
                  <Typography className={classes.requestTitle}>
                    {t(data?.source ?? "-")}
                  </Typography>
                  <Typography
                    className={classes.statusBox}
                    style={{
                      backgroundColor:
                        data?.status === "Pending"
                          ? "#78B1FE"
                          : data?.status === "Approved"
                          ? "#5AC782"
                          : "#FF4B4B",
                    }}
                  >
                    {t(data?.status)}
                  </Typography>
                </Box>
                <Stack direction="row" spacing={1} alignItems={"center"}>
                  <Typography className={classes.requestNo}>
                    {data?.request_no}
                  </Typography>
                  <Box className={classes.requestdot}></Box>
                  <Typography className={classes.requestNo}>
                    {data?.maintenance_request_no ??
                      data?.general_request_no ??
                      data?.agreement_no ??
                      data?.request_id ??
                      "-"}
                  </Typography>
                </Stack>
              </Box>

              <Box
                className={classes.requestDetailsBox}
                display="flex"
                justifyContent="space-between"
                alignItems={"center"}
                marginTop="16px"
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <BillingAmountIcon />
                  <Box>
                    <Typography className={classes.requestTitle}>
                      {quotationDetails?.quott?.symbol}{" "}
                      {quotationDetails?.quott?.total_amount ?? 0}{" "}
                    </Typography>
                    <Typography className={classes.requestNo}>
                      {t("Final Amount")}
                    </Typography>
                  </Box>
                </Stack>
                <Box
                  onClick={() => handleIcon("info", data)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography className={classes.viewDetailsTxt}>
                    {t("View Details")}
                  </Typography>
                </Box>
              </Box>
              {data?.source === "Agreement Order Confirmation Approval" && (
                <Box className={classes.requestDetailsBox} marginTop="8px">
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography className={classes.itemName}>
                      {t("Quotation Period")}
                    </Typography>
                    <Box flexGrow={1} textAlign="right">
                      <Typography className={classes.requestNo}>
                        {quotationDetails?.quott?.lease_start_date} -{" "}
                        {quotationDetails?.quott?.lease_end_date}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    marginTop="10px"
                  >
                    <Typography className={classes.itemName}>
                      {t("Quotation Type")}
                    </Typography>
                    <Box flexGrow={1} textAlign="right">
                      <Typography className={classes.requestNo}>
                        {quotationDetails?.quott?.revenue_type}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              )}
              <Box height={10} />
              <Box>
                <QuotationSummery
                  symbol={quotationDetails?.quott?.symbol}
                  datas={{
                    totalAmount: quotationDetails?.quott?.total_amount ?? 0,
                    totalrefundableTax:
                      quotationDetails?.quott?.total_refundable ?? 0,
                    totalTax: quotationDetails?.quott?.total_tax ?? 0,
                    total: quotationDetails?.quott?.total_amount ?? 0,
                    totalDiscount: quotationDetails?.quott?.total_discount ?? 0,
                  }}
                  total={
                    quotationDetails?.quott?.total_quote_amount ??
                    quotationDetails?.quott?.total_amount +
                      quotationDetails?.quott?.total_tax
                  }
                  height="75vh"
                  t={t}
                  unit={quotationDetails?.unitDetails}
                />
              </Box>
            </Box>
          )}

          {tabValue === "1" && data?.source === "Reservation Request Approval" && (
            <Box p={2}>
              <Box className={classes.requestDetailsBox} alignItems={"center"}>
                <Box
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems="center"
                >
                  <Typography className={classes.requestTitle}>
                    {t(data?.source ?? "-")}
                  </Typography>
                  <Typography
                    className={classes.statusBox}
                    style={{
                      backgroundColor:
                        data?.status === "Pending"
                          ? "#78B1FE"
                          : data?.status === "Approved"
                          ? "#5AC782"
                          : "#FF4B4B",
                    }}
                  >
                    {t(data?.status)}
                  </Typography>
                </Box>
                <Stack direction="row" spacing={1} alignItems={"center"}>
                  <Typography className={classes.requestNo}>
                    {data?.request_no}
                  </Typography>
                  <Box className={classes.requestdot}></Box>
                  <Typography className={classes.requestNo}>
                    {data?.maintenance_request_no ??
                      data?.general_request_no ??
                      data?.agreement_no ??
                      data?.request_id ??
                      "-"}
                  </Typography>
                </Stack>
              </Box>
             <Box className={classes.requestDetailsBox} marginTop="16px">
                <Box display="flex"
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <BillingAmountIcon />
                  <Box>
                    <Typography className={classes.requestTitle}>
                      {quotationDetails?.reservation?.currency?.symbol}
                      {quotationDetails?.reservation?.reserved_amount}
                    </Typography>
                    <Typography className={classes.requestNo}>
                      {t("Reservation Amount")}
                    </Typography>
                  </Box>
                </Stack>
                <Box
                  onClick={() => handleIcon("info", data)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography className={classes.viewDetailsTxt}>
                    {t("View Details")}
                  </Typography>
                </Box>
               </Box>
               <Box mt={1} mb={1.5}>
                      <Divider />
                    </Box>
                    <Box position={"relative"}>
               <Stack
                 direction="row"
                  spacing={1}
                  alignItems="center"
                  marginTop="10px"
                >
                  <Typography className={classes.finalamttext}>
                    {t("Quotation Value")}
                  </Typography>
                  <Box flexGrow={1} textAlign="right">
                    <Typography className={classes.requestNo}>
                       {quotationDetails?.quott?.total_amount}
                    </Typography>
                  </Box>
                </Stack>
                </Box>
                <Box mt={2}>
                <QuotationSummery
                  symbol={quotationDetails?.quott?.symbol}
                  datas={{
                    totalAmount: quotationDetails?.quott?.total_amount ?? 0,
                    totalrefundableTax:
                      quotationDetails?.quott?.total_refundable ?? 0,
                    totalTax: quotationDetails?.quott?.total_tax ?? 0,
                    total: quotationDetails?.quott?.total_amount ?? 0,
                    totalDiscount: quotationDetails?.quott?.total_discount ?? 0,
                  }}
                  total={
                    quotationDetails?.quott?.total_quote_amount ??
                    quotationDetails?.quott?.total_amount +
                      quotationDetails?.quott?.total_tax
                  }
                  height="75vh"
                  t={t}
                  unit={quotationDetails?.unitDetails}
                />
              </Box>
                </Box>
             </Box>
          )}

        {tabValue === "1" &&
          (data?.source === "Agreement Confirmation Approval" ||
            data?.source === "Agreement Emergency Cancellation Approval") && (
            <Box p={2}>
              <Box className={classes.requestDetailsBox} alignItems={"center"}>
                <Box
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems="center"
                >
                  <Typography className={classes.requestTitle}>
                    {t(data?.source ?? "-")}
                  </Typography>
                  <Typography
                    className={classes.statusBox}
                    style={{
                      backgroundColor:
                        data?.status === "Pending"
                          ? "#78B1FE"
                          : data?.status === "Approved"
                          ? "#5AC782"
                          : "#FF4B4B",
                    }}
                  >
                    {t(data?.status)}
                  </Typography>
                </Box>
                <Stack direction="row" spacing={1} alignItems={"center"}>
                  <Typography className={classes.requestNo}>
                    {data?.request_no}
                  </Typography>
                  <Box className={classes.requestdot}></Box>
                  <Typography className={classes.requestNo}>
                    {data?.maintenance_request_no ??
                      data?.general_request_no ??
                      data?.agreement_no ??
                      data?.request_id ??
                      "-"}
                  </Typography>
                </Stack>
              </Box>
              <Box className={classes.requestDetailsBox} marginTop="16px">
                <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems={"center"}
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <BillingAmountIcon />
                        <Box>
                          <Typography className={classes.requestTitle}>
                            {agreementDetails?.total_amount + agreementDetails?.total_tax}
                          </Typography>
                          <Typography className={classes.requestNo}>
                            {t("Final Amount")}
                          </Typography>
                        </Box>
                      </Stack>
                      <Box
                        onClick={() => handleIcon("info", data)}
                        style={{ cursor: "pointer" }}
                      >
                        <Typography className={classes.viewDetailsTxt}>
                          {t("View Details")}
                        </Typography>
                      </Box>
                    </Box>
                <Box mt={1} mb={1.5}>
                      <Divider />
                    </Box>
                    <Box position={"relative"}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography className={classes.itemName}>
                    {t("Agreement Number")}
                  </Typography>
                  <Box flexGrow={1} textAlign="right">
                    <Typography className={classes.requestNo}>
                      {agreementDetails?.agreement_no}
                    </Typography>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  marginTop="10px"
                >
                  <Typography className={classes.itemName}>
                    {t("Agreement Period")}
                  </Typography>
                  <Box flexGrow={1} textAlign="right">
                  <Typography className={classes.requestNo}>
                    {timeZoneConverter(agreementDetails?.lease_start_date)} -{" "}
                    {timeZoneConverter(agreementDetails?.lease_end_date)}
                   </Typography>

                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  marginTop="10px"
                >
                  <Typography className={classes.itemName}>
                    {t("Agreement Type")}
                  </Typography>
                  <Box flexGrow={1} textAlign="right">
                    <Typography className={classes.requestNo}>
                       {agreementDetails?.quotation?.revenue_type}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              </Box>
              </Box>
          )}

        {tabValue === "1" &&
          data?.source !== "Final Settlement" &&
          data?.source !== "Quotation Approval" &&
          data?.source !== "Agreement Order Confirmation Approval" &&
          data?.source !== "Agreement Confirmation Approval" &&
          data?.source !== "Agreement Emergency Cancellation Approval" && 
          data?.source !== "Reservation Request Approval" && (
            <Box p={2}>
              <Box className={classes.requestDetailsBox} alignItems={"center"}>
                <Box
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems="center"
                >
                  <Typography className={classes.requestTitle}>
                    {t(data?.source ?? "-")}
                  </Typography>
                  <Typography
                    className={classes.statusBox}
                    style={{
                      backgroundColor:
                        data?.status === "Pending"
                          ? "#78B1FE"
                          : data?.status === "Approved"
                          ? "#5AC782"
                          : "#FF4B4B",
                    }}
                  >
                    {t(data?.status)}
                  </Typography>
                </Box>
                <Stack direction="row" spacing={1} alignItems={"center"}>
                  <Typography className={classes.requestNo}>
                    {data?.request_no}
                  </Typography>
                  <Box className={classes.requestdot}></Box>
                  <Typography className={classes.requestNo}>
                    {data?.maintenance_request_no ??
                      data?.general_request_no ??
                      data?.agreement_no ??
                      data?.request_id ??
                      "-"}
                  </Typography>
                </Stack>
              </Box>

              {itemDetails?.items?.length > 0 && (
                <>
                  <Box mt={1} mb={1}>
                    <Typography className={classes.BillingAmountTxt}>
                      {t("Billing Amount")}
                    </Typography>
                  </Box>
                  <Box mt={1} className={classes.requestDetailsBox}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems={"center"}
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <BillingAmountIcon />
                        <Box>
                          <Typography className={classes.requestTitle}>
                            {itemDetails?.symbol}{" "}
                            {itemDetails?.total_amount_after_tax ?? 0}{" "}
                          </Typography>
                          <Typography className={classes.requestNo}>
                            {t("Final Amount")}
                          </Typography>
                        </Box>
                      </Stack>
                      <Box
                        onClick={() => handleIcon("info", data)}
                        style={{ cursor: "pointer" }}
                      >
                        <Typography className={classes.viewDetailsTxt}>
                          {t("View Details")}
                        </Typography>
                      </Box>
                    </Box>
                    <Box mt={1} mb={1.5}>
                      <Divider />
                    </Box>
                    <Box position={"relative"}>
                      <Box mt={1} maxHeight={"180px"} overflow={"scroll"}>
                        {itemDetails?.items?.map((val) => {
                          return (
                            <Grid
                              container
                              spacing={2}
                              alignItems="center"
                              mb={1}
                            >
                              <Grid item xs={6}>
                                <Typography className={classes.itemName}>
                                  {val?.inspection_item_name}
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Typography className={classes.requestNo}>
                                  {val?.quatity} {t("QTY")}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                justifyContent={"end"}
                                display={"flex"}
                              >
                                <Typography className={classes.itemName}>
                                  {itemDetails?.symbol} {val?.line_amount}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        })}

                        {itemDetails?.deduction?.length > 0 && (
                          <>
                            <Box mt={1} mb={1}>
                              <Divider />
                            </Box>
                            <Box mb={1}>
                              <Typography className={classes.BillingAmountTxt}>
                                {t("Deduction")}
                              </Typography>
                            </Box>
                            {itemDetails?.deduction?.map((x) => {
                              return (
                                <Grid
                                  container
                                  spacing={2}
                                  alignItems="center"
                                  mb={1}
                                >
                                  <Grid item xs={6}>
                                    <Typography className={classes.itemName}>
                                      {x?.description}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Typography className={classes.requestNo}>
                                      -
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    justifyContent={"end"}
                                    display={"flex"}
                                  >
                                    <Typography className={classes.itemName}>
                                      {" - "} {itemDetails?.symbol} {x?.amount}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              );
                            })}
                            <Box mt={1} mb={1}>
                              <Divider />
                            </Box>
                          </>
                        )}
                      </Box>

                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        mt={1}
                      >
                        <Typography className={classes.finalamttext}>
                          {t("Final Amount")}
                        </Typography>
                        <Typography className={classes.finalamttext}>
                          {itemDetails?.symbol}{" "}
                          {itemDetails?.total_amount_after_tax ?? 0}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          )}
        {tabValue === "4" && data?.source !== "Final Settlement" &&
          data?.source !== "Quotation Approval" &&
          data?.source !== "Agreement Order Confirmation Approval" &&
          data?.source !== "Agreement Confirmation Approval" &&
          data?.source !== "Agreement Emergency Cancellation Approval" && 
          data?.source !== "Reservation Request Approval" && (
          <Box p={2}>
            {timelineData?.length > 0 ? (
              timelineData?.map((x, index, array) => {
                return (
                  <Box className={classes1.treeBox} pl={2} pr={2}>
                    <Box
                      className={
                        timelineData?.length - 1 !== index
                          ? classes.border
                          : classes.applynoborder
                      }
                    >
                      <Avatar variant="circular" className={classes1.avatars1}>
                        <Avatar
                          variant="circular"
                          style={{
                            height: "100%",
                            width: "100%",
                            backgroundColor: "#5078E1",
                          }}
                        >
                          <img
                            src={
                              x?.status === "Rejected"
                                ? "images/rejected.svg"
                                : "images/Path 953.svg"
                            }
                            alt="document"
                          />
                        </Avatar>
                      </Avatar>
                      <Box height={"4px"} />

                      <Typography className={classes1.title}>
                        {x?.status}
                      </Typography>

                      <Box height={"2px"} />
                      <Typography className={classes1.dateTime}>
                        {x?.comments}
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        divider={<Divider orientation="vertical" flexItem />}
                      >
                        <Typography className={classes1.dateTime}>
                          {x?.created_at
                            ? moment(x?.created_at).format("DD MMM YY hh:mm a")
                            : "-"}
                        </Typography>
                        {x.created_by?.length > 0 && (
                          <Typography className={classes1.dateTime}>
                            {x?.created_by}
                          </Typography>
                        )}
                        {x.updated_by?.length > 0 && (
                          <Typography className={classes1.dateTime}>
                            {x?.updated_by}
                          </Typography>
                        )}
                      </Stack>
                      <Box height={"15px"} />

                      {timelineData?.length !== index + 1 && (
                        <Box height={"12px"} />
                      )}
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Box mt={4} display={"flex"} justifyContent={"center"}>
                <NoDataFound />
              </Box>
            )}
          </Box>
        )}

       {tabValue === "4" &&
           (data?.source === "Quotation Approval" ||
            data?.source === "Agreement Order Confirmation Approval" ||
            data?.source === "Agreement Confirmation Approval" ||
            data?.source === "Agreement Emergency Cancellation Approval" || 
            data?.source === "Reservation Request Approval") && (
          <Box p={2}>
            {approvalTimeline?.length > 0 ? (
              approvalTimeline?.map((x, index, array) => {
                return (
                  <Box className={classes1.treeBox} pl={2} pr={2}>
                    <Box
                      className={
                        approvalTimeline?.length - 1 !== index
                          ? classes.border
                          : classes.applynoborder
                      }
                    >
                      <Avatar variant="circular" className={classes1.avatars1}>
                        <Avatar
                          variant="circular"
                          style={{
                            height: "100%",
                            width: "100%",
                            backgroundColor: "#5078E1",
                          }}
                        >
                          <img
                            src={
                              x?.status === "Rejected"
                                ? "images/rejected.svg"
                                : "images/Path 953.svg"
                            }
                            alt="document"
                          />
                        </Avatar>
                      </Avatar>
                      <Box height={"4px"} />

                      <Typography className={classes1.title}>
                        {t(x?.level_name)} - {t(x?.status)}
                      </Typography>

                      <Box height={"2px"} />
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        divider={<Divider orientation="vertical" flexItem />}
                      >
                        <Typography className={classes1.dateTime}>
                          {x?.created_at
                            ? moment(x?.created_at).format("DD MMM YY hh:mm a")
                            : "-"}
                        </Typography>
                        {x.created_by?.length > 0 && (
                          <Typography className={classes1.dateTime}>
                            {x?.created_by}
                          </Typography>
                        )}
                        {x.updated_by?.length > 0 && (
                          <Typography className={classes1.dateTime}>
                            {x?.updated_by}
                          </Typography>
                        )}
                      </Stack>
                      <Box height={"15px"} />

                      {approvalTimeline?.length !== index + 1 && (
                        <Box height={"12px"} />
                      )}
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Box mt={4} display={"flex"} justifyContent={"center"}>
                <NoDataFound />
              </Box>
            )}
          </Box>
        )} 
        {(tabValue === "2" || tabValue === "3") && (
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className={classes.requestedHdrLabels}>
                  {t("Image")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                alignItems={"end"}
                display="flex"
                justifyContent={"end"}
              >
                <Avatar
                  variant="circle"
                  src={
                    tabValue === "2"
                      ? data?.requestedBy?.image_url
                      : data?.updatedBy?.image_url
                  }
                  className={classes.requestorImage}
                  {...stringAvatar(
                    tabValue === "2"
                      ? data?.requestedBy?.requested_by_first_name || approvalTimeline?.[0]?.createdBy?.first_name
                      : data?.updatedBy?.approved_by_first_name || approvalTimeline?.[0]?.updatedBy?.first_name
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.requestedHdrLabels}>
                  {t("Name")}
                </Typography>
              </Grid>
              <Grid item xs={6} alignItems={"end"}>
                <Typography className={classes.requestedValues}>
                  {tabValue === "2"
                    ? data?.requestedBy?.requested_by_first_name || approvalTimeline?.[0]?.createdBy?.first_name
                    : data?.updatedBy?.approved_by_first_name || approvalTimeline?.[0]?.updatedBy?.first_name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.requestedHdrLabels}>
                  {t("Job")}
                </Typography>
              </Grid>
              <Grid item xs={6} alignItems={"end"}>
                <Typography className={classes.requestedValues}>
                  {tabValue === "2"
                    ? data?.requestedBy?.job_title || approvalTimeline?.[0]?.createdBy?.job
                    : data?.updatedBy?.job_title || approvalTimeline?.[0]?.updatedBy?.job}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.requestedHdrLabels}>
                  {t("Department")}
                </Typography>
              </Grid>
              <Grid item xs={6} alignItems={"end"}>
                <Typography className={classes.requestedValues}>
                  {tabValue === "2"
                    ? data?.requestedBy?.department || approvalTimeline?.[0]?.createdBy?.department
                    : data?.updatedBy?.department || approvalTimeline?.[0]?.updatedBy?.department}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems="center"
                >
                  <Box>
                    <Typography className={classes.requestedHdrLabels}>
                      {t("Reporting To")}
                    </Typography>
                  </Box>
                  <Box className={classes.reportingBox}>
                    <Avatar
                      variant="circle"
                      src={data?.requestedBy?.reporting_to_image_url}
                      className={classes.reportingPersonImg}
                      {...stringAvatar(
                        data?.requestedBy?.reporting_to_first_name || approvalTimeline?.[0]?.createdBy?.reporting_to
                      )}
                    />
                    <Typography className={classes.requestedValues}>
                      {data?.requestedBy?.reporting_to_first_name || approvalTimeline?.[0]?.createdBy?.reporting_to}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        {tabValue === "1" && data?.source === "Final Settlement" && (
          <Box p={2}>
            <Box className={classes.gridBox} p={2}>
              <Stack direction={"row"} alignItems={"center"} pb={"16px"}>
                <FinalSettleIcon style={{ marginInlineEnd: "16px" }} />
                <Typography className={classes.title}>
                  {t("Final Settlement")}
                </Typography>
              </Stack>
              <Box style={{ backgroundColor: "#E4E8EE", height: "1.1px" }} />
              <Stack
                spacing={"10px"}
                padding={"12px 0px"}
                maxHeight={"250px"}
                overflow={"auto"}
              >
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  spacing={"10px"}
                >
                  <Typography className={classes.breakupTitle}>
                    {t("Billed Invoices")}
                  </Typography>
                  <Typography className={classes.breakupContent}>{`${Number(
                    data?.finalSettlement?.billed_invoice ?? 0
                  ).toFixed(2)} ${
                    data?.finalSettlement?.symbol ?? ""
                  }`}</Typography>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography className={classes.breakupTitle}>
                    {t("Receipts & Credit Notes")}
                  </Typography>
                  <Typography className={classes.breakupContent}>{`${Number(
                    data?.finalSettlement?.amount_paid ?? 0
                  ).toFixed(2)} ${
                    data?.finalSettlement?.symbol ?? ""
                  }`}</Typography>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography className={classes.breakupTitle}>
                    {t("Refundables")}
                  </Typography>
                  <Typography className={classes.breakupContent}>{`${Number(
                    data?.finalSettlement?.refundable_total ?? 0
                  ).toFixed(2)} ${
                    data?.finalSettlement?.symbol ?? ""
                  }`}</Typography>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography className={classes.breakupTitle}>
                    {t("Amount Due")}
                  </Typography>
                  <Typography className={classes.breakupContent}>{`${Number(
                    data?.finalSettlement?.due_amount ?? 0
                  ).toFixed(2)} ${
                    data?.finalSettlement?.symbol ?? ""
                  }`}</Typography>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography className={classes.breakupTitle}>
                    {t("Void invoices")}
                  </Typography>
                  <Typography className={classes.breakupContent}>{`${Number(
                    data?.finalSettlement?.void_invoice ?? 0
                  ).toFixed(2)} ${
                    data?.finalSettlement?.symbol ?? ""
                  }`}</Typography>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography className={classes.breakupTitle}>
                    {t("Unbilled Invoices")}
                  </Typography>
                  <Typography className={classes.breakupContent}>{`${Number(
                    data?.finalSettlement?.unbilled_invoice ?? 0
                  ).toFixed(2)} ${
                    data?.finalSettlement?.symbol ?? ""
                  }`}</Typography>
                </Stack>
              </Stack>

              <Box style={{ backgroundColor: "#E4E8EE", height: "1.1px" }} />

              <Stack direction={"row"} padding={"12px 0px"}>
                <Box sx={{ width: "50%" }}>
                  <SettlementAmountCard
                    backgroundColor="#5AC782"
                    title={t("AMOUNT TO BE PAID TO CUSTOMER")}
                    amount={`${Number(
                      data?.finalSettlement?.amount_to_be_paid ?? 0
                    ).toFixed(2)} ${data?.finalSettlement?.symbol ?? ""}`}
                    type="paid"
                  />
                </Box>
                <Box sx={{ width: "50%", marginInlineStart: "16px" }}>
                  <SettlementAmountCard
                    backgroundColor="#FF4B4B"
                    title={t("AMOUNT TO BE RECOVERED FROM CUSTOMER")}
                    amount={`${Number(
                      data?.finalSettlement?.amount_recovered ?? 0
                    ).toFixed(2)} ${data?.finalSettlement?.symbol ?? ""}`}
                  />
                </Box>
              </Stack>
            </Box>
          </Box>
        )}
        {tabValue === "1" &&
        showNote &&
        approvalStatus === "Pending" &&
        (data?.source === "Quotation Approval" ||
          data?.source === "Agreement Order Confirmation Approval" ||
        data?.source === "Agreement Emergency Cancellation Approval" ||
      data?.source === "Agreement Confirmation Approval" ||
      data?.source === "Reservation Request Approval") ? (
          <Box p={2}>
            <Box mt={2} className={classes.notesBox}>
              <Box p={2} display={"flex"} justifyContent={"space-between"}>
                <Typography className={classes.notesLabel}>
                  {t("Notes")}
                </Typography>
                <Box onClick={() => setShowNote(false)}>
                  <Typography className={classes.removeTxt}>
                    {t("Remove")}
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <Box p={1}>
                <TextBox
                  label=""
                  placeholder={t("Type Notes here")}
                  value={notesData}
                  onChange={(e) => handleChangeNotes(e?.target?.value)}
                  color={"white"}
                  border={0}
                  multiline
                />
              </Box>
            </Box>
          </Box>
        ) : (
          approvalStatus === "Pending" &&
          tabValue === "1" &&
          (data?.source === "Quotation Approval" ||
            data?.source === "Agreement Order Confirmation Approval" ||
           data?.source === "Agreement Confirmation Approval" ||
          data?.source === "Agreement Emergency Cancellation Approval" ||
        data?.source === "Reservation Request Approval") && (
            <Box mt={2} onClick={() => setShowNote(true)} p={2}>
              <Typography className={classes.addNote}>
                + {t("Add Note")}
              </Typography>
            </Box>
          )
        )}
      </Box>
      {tabValue === "1" && approvalStatus === "Pending" && (
        <Box className={classes.fixBottom}>
          {data?.source === "Quotation Approval" ||
          data?.source === "Agreement Order Confirmation Approval" || 
          data?.source === "Agreement Emergency Cancellation Approval" || 
          data?.source === "Reservation Request Approval" || 
          data?.source === "Agreement Confirmation Approval" ? (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.rejectbtn}
                  onClick={() => updateRequestStatus("Rejected")}
                >
                  {t("Reject")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.approvebtn}
                  onClick={() => updateRequestStatus("Approved")}
                >
                  {t("Approve")}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.rejectbtn}
                  onClick={() => updateRequest("Rejected")}
                >
                  {t("Reject")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.approvebtn}
                  onClick={() => updateRequest("Approved")}
                >
                  {t("Approve")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      )}
      </>
      )}
    </Box>
  );
};