import { Box, Button, Dialog, Grid, IconButton, Stack, Typography } from "@mui/material"
import { useStyles } from "../style"
import CloseIcon from '@mui/icons-material/Close';
import { TitleDesc } from "../../companyCreation/components";
import { returnValue } from "../../companyCreation/utils";
import { TextBox, TimePickerNew } from "../../../components";
import { activeOptionsList } from "../../../utils";


export const DialogForm = (props) => {

    const {
        t = {},
        dialogState = {},
        dialogOpen = false,
        setDialogOpen = () => false,
        disable = false,
        updateDialogState = () => false,
        handleTableIcon = () => false,
        handleCreateEdit = () => false,
    } = props
    const days = [
        {
            id: 0,
            value: t("Sun")
        },
        {
            id: 1,
            value: t("Mon")
        },
        {
            id: 2,
            value: t("Tue")
        },
        {
            id: 3,
            value: t("Wed")
        },
        {
            id: 4,
            value: t("Thu")
        },
        {
            id: 5,
            value: t("Fri")
        },
        {
            id: 6,
            value: t("Sat")
        },
    ]
    const classes = useStyles()
    const selectWeekOff = (val) => {
        if (!dialogState?.week_off_days?.includes(val)) {
            updateDialogState("week_off_days", [...dialogState?.week_off_days, val])
        }
        else {
            const data = dialogState?.week_off_days?.filter((x) => { return x !== val })
            updateDialogState("week_off_days", data)
        }
    }
    return <Dialog
        className={dialogState?.formType === "view" ?
            classes.viewDialog :
            dialogState?.formType === "delete" ?
                classes.deleteDialog : classes.addDialog}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}>
        <div className={classes.addDialogHeader}>
            <Typography className={classes.addDialogHeaderTitle}>
                {dialogState?.formType === "add" ? t("create_workschedule") :
                    dialogState?.formType === "edit" ? t("edit_workschedule") :
                        dialogState?.formType === "view" ? t("view_workschedule") :
                            dialogState?.formType === "delete" ? t("delete_workschedule") :
                                t("create_workschedule")}
            </Typography>
            <IconButton onClick={() => setDialogOpen(false)}
                className={classes.addDialogCloseButton}>
                <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
            </IconButton>
        </div>
        <div className={classes.addDialogBody}>
            {dialogState?.formType === "view" ?
                <Grid container spacing={"16px"}>
                    <Grid item xs={4}>
                        <TitleDesc
                            title={t("shift_name")}
                            desc={returnValue(dialogState?.shift_name, "-")} />
                    </Grid>
                    <Grid item xs={4}>
                        <TitleDesc
                            title={t("starting_time")}
                            desc={returnValue(dialogState?.starting_time, "-")} />
                    </Grid>
                    <Grid item xs={4}>
                        <TitleDesc
                            title={t("ending_time")}
                            desc={returnValue(dialogState?.ending_time, '-')} />
                    </Grid>
                    <Grid item xs={5}>
                        <TitleDesc
                            title={t("break_period")}
                            desc={returnValue(dialogState?.break_period, '-')} />
                    </Grid>
                    <Grid item xs={5}>
                        <TitleDesc
                            title={t("total_working_hours")}
                            desc={returnValue(dialogState?.total_working_hours, '-')} />
                    </Grid>
                    {dialogState?.week_off_days?.length > 0 &&
                        <Grid item xs={12}>
                            <Typography className={classes.weekoff}>{t("week_off_days")}</Typography>
                            <Box mt={0.5} display={"flex"}>
                                {days?.map((day, i) => {
                                    return (
                                        <Typography className={classes.weekoffedit}>{dialogState?.week_off_days.includes(day?.id) && (day?.value).concat(i === days.length - 1 ? " " : ",")}</Typography>
                                    )
                                })}
                            </Box>
                        </Grid>
                    }
                    <Grid item xs={12} height={"87px"}>
                        <TitleDesc
                            title={t("description")}
                            dangerouslySetInnerHTML={returnValue(dialogState?.description, "-")} />
                    </Grid>
                </Grid> :
                dialogState?.formType === "delete" ?
                    <Typography className={classes.confirmDeleteText}>
                        {`${t("Are you sure want to delete")} ${dialogState?.shift_name} ${t("Workschedule ?")}`}
                    </Typography> :
                    <>
                        <TextBox
                            isrequired
                            isReadonly={disable}
                            label={t("shift_name")}
                            placeholder={t("Enter Shift Name")}
                            value={dialogState?.shift_name ?? ""}
                            onChange={(e) => updateDialogState("shift_name", e.target.value)}
                            isError={dialogState?.error?.shift_name?.length > 0}
                            errorMessage={dialogState?.error?.shift_name} />
                        <Box height={16} />
                        <Stack spacing={"16px"} direction={"row"} style={{ display: "flex" }}>
                            <TimePickerNew isRequired label={t("starting_time")}
                                isReadonly={disable} value={dialogState?.shift_starting_time}
                                onChange={(value) => updateDialogState("shift_starting_time", value)}
                                isError={dialogState?.error?.shift_starting_time?.length > 0}
                                errorMessage={dialogState?.error?.shift_starting_time} />
                            <TimePickerNew isRequired label={t("ending_time")}
                                isReadonly={disable} value={dialogState?.shift_ending_time}
                                onChange={(value) => updateDialogState("shift_ending_time", value)}
                                isError={dialogState?.error?.shift_ending_time?.length > 0}
                                errorMessage={dialogState?.error?.shift_ending_time} />
                        </Stack>
                        <Box height={16} />
                        <Stack spacing={"16px"} direction={"row"} style={{ display: "flex" }}>
                            <TimePickerNew isRequired label={t("break_period_starting_time")}
                                isReadonly={disable} value={dialogState?.break_starting_time}
                                onChange={(value) => updateDialogState("break_starting_time", value)}
                            isError={dialogState?.error?.break_starting_time?.length > 0}
                            errorMessage={dialogState?.error?.break_starting_time}
                            />
                            <TimePickerNew isRequired label={t("break_period_ending_time")}
                                isReadonly={disable} value={dialogState?.break_ending_time}
                                onChange={(value) => updateDialogState("break_ending_time", value)}
                            isError={dialogState?.error?.break_ending_time?.length > 0}
                            errorMessage={dialogState?.error?.break_ending_time}
                            />
                        </Stack>
                        <Box height={16} />
                        <Box>
                            <Typography className={classes.weekoff}>{t("Week Off Days")}</Typography>
                            <Stack direction={"row"} spacing={2} mt={1}>
                                {days.map((x) => {
                                    return (
                                        <Box className={dialogState?.week_off_days?.includes(x.id) ? classes.selectedDays : classes.unselectedDays} onClick={() => selectWeekOff(x.id)}><Box mt={0.7}>{x.value}</Box></Box>
                                    )
                                })}
                            </Stack>
                        </Box>
                        <Box height={16} />
                        <TextBox isReadonly={disable} label={t("description")}
                            placeholder={t("Enter Description")} multiline
                            value={dialogState?.description ?? ""}
                            onChange={(e) => updateDialogState("description", e.target.value)} />
                        <Box height={16} />
                        <Typography className={classes.addDialogFieldLabel} noWrap>{t("status")}</Typography>
                        <div className={classes.addDialogButtonContainer}>
                            {activeOptionsList.map((_) => {
                                return <Button className={_?.value === dialogState?.is_active ?
                                    classes.addDialogButtonSelected : classes.addDialogButtonUnSelected}
                                    onClick={() => !disable ?
                                        updateDialogState("is_active", _?.value) : false}>
                                    {t(_?.label)}
                                </Button>
                            })}
                        </div>
                    </>}
        </div >
        {< div className={classes.addDialogFooter} >
            {(dialogState?.formType === "view" ||
                dialogState?.formType === "edit" ||
                dialogState?.formType === "delete") &&
                <Button fullWidth
                    className={classes.addDialogFooterCloseButton}
                    onClick={() => dialogState?.formType === "view" ?
                        handleTableIcon("delete", dialogState) :
                        setDialogOpen(false)}>
                    {dialogState?.formType === "view" ? t("delete") : t("cancel")}
                </Button>}
            <Button variant="contained" fullWidth disabled={disable}
                className={classes.addDialogFooterButton}
                onClick={() => dialogState?.formType === "view" ?
                    handleTableIcon("edit", dialogState) :
                    handleCreateEdit(dialogState)}>
                {dialogState?.formType === "view" ? t("edit") :
                    dialogState?.formType === "add" ? t("create") :
                        dialogState?.formType === "edit" ? t("save") : t("delete")}
            </Button>
        </div >}
    </Dialog >
}