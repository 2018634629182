import { Grid, Typography } from "@mui/material";
import ReactQuill from "react-quill";
import { useStyles } from "../styles";
export const Profile = ({ data = {}, t, relType=null }) => {
    const classes = useStyles()
    const modules = { toolbar: false };

    const key = {
        "Vendor": data?.vendor?.label,
        "Customer": data?.contact?.label,
        "Property Owner": data?.contact?.label,
        "Sales Broker": data?.vendor?.label,
        "Service Provider": data?.provider?.label,
        "Walk In Account": data?.contact?.label,
        "Cash": data?.com_master?.label
    }
    return (

        <Grid container className={classes.box}>
            <Grid item xs={12} sm={12} md={2} lg={1.5} >
                <div className={classes.imgDiv}>
                    <img src={data?.logo ?? "/images/imagesproperty.svg"} alt="" className={classes.unitImg} />
                    <Typography className={classes.imgTag}>{data?.account_no}</Typography>
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10.5} className={classes.imgDiv2} >
                <Typography className={classes.title} marginBottom={"14px"}>{t("Profile Details")}</Typography>

                <Grid container >
                    <Grid item md={12} lg={12}>
                        <Grid container rowSpacing={1} columnSpacing={3}>
                            <Grid item xs={3}>
                                <Typography className={classes.heading}>{t("Account Type")}</Typography>
                                <Typography className={classes.sub}>
                                    {data?.account_payment_classification ?? "-"}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography className={classes.heading}>{t("Company Name")}</Typography>
                                <Typography className={classes.sub}>{data?.company?.company_name}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography className={classes.heading}>{t("Business Type")}</Typography>
                                <Typography className={classes.sub}>
                                    {data?.company_type?.label ?? "-"}
                                </Typography>
                            </Grid>                            
                            <Grid item xs={3}>
                                <Typography className={classes.heading}>{data?.account_payment_classification === "AR" ? t("Customer Group") : t("Vendor Group")}</Typography>
                                <Typography className={classes.sub}>
                                    {data?.account_payment_classification === "AR" ? data?.customer_group_master?.label : data?.vendor_group_master?.label ?? "-"}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography className={classes.heading}>{t("Relationship")}</Typography>
                                <Typography className={classes.sub}>
                                    {data?.relationship}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography className={classes.heading}>{t(data?.relationship)}</Typography>
                                <Typography className={classes.sub}>
                                    {key[data?.relationship]}
                                </Typography>
                            </Grid>                        
                            <Grid item xs={3}>
                                <Typography className={classes.heading}>{t("Account Name")}</Typography>
                                <Typography className={classes.sub}>{data?.name}</Typography>
                            </Grid>
                            {relType === "cashBank" ? (
                            <Grid item xs={3}>
                                <Typography className={classes.heading}>{t("COA Number")}</Typography>
                                <Typography className={classes.sub}>{data?.coa_number}</Typography>
                            </Grid> 
                            ) : (                            
                            <Grid item xs={3}>
                                <Typography className={classes.heading}>{t("Alternative Account Number")}</Typography>
                                <Typography className={classes.sub}>{data?.alternative_account_number}</Typography>
                            </Grid> )}                                                       
                            <Grid item xs={12}>
                                <Typography className={classes.heading}> {t("Business Description")}</Typography>
                                <ReactQuill
                                    readOnly
                                    theme="bubble"
                                    value={data?.description?.length ? data?.description : "-"}
                                    modules={modules}
                                    className={classes.sub}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    )
}