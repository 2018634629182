import React from "react";
import { withNavBars } from "../../HOCs";
import VendorCreation from "./vendorCreation";
import VendorListing from "./vendorListing";
import VendorDetails from "./vendorDetails";
import { useNavigate, useLocation, matchPath, useParams } from "react-router-dom";
import { Routes } from "../../router/routes";

class VendorMasterParentOne extends React.Component {
  render() {
    const { location } = this.props;
    return (
      <>
        {location.pathname === Routes.vendorListing && (
          <VendorListing {...this.props} />
        )}
        {location.pathname === Routes.vendorCreation && (
          <VendorCreation {...this.props} />
        )}
        {matchPath(Routes.editVendor, location.pathname) && location.state?.from === "edit" ? (
          <VendorCreation isEdit={true} {...this.props} />
        ) : (
          <>
            {matchPath(Routes.editVendor, location.pathname) && (
              <VendorDetails {...this.props} />
            )}
          </>
        )}
      </>
    );
  }
}
const props = {
  boxShadow: false,
};
const VendorMasterParent = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  
  return <VendorMasterParentOne {...props} navigate={navigate} location={location} params={params}/>;
};

export default withNavBars(VendorMasterParent, props);
