import { Typography } from "@mui/material";
import { useStyles, LightTooltip } from "./style";
import InfoIcon from "../../assets/infoIcon";

export const Label = (props) => {
  const classes = useStyles(props);
  return (
    <Typography
      className={props?.style ? null : classes.label}
      sx={props?.style}
      noWrap
    >
      {props.label}
      {props.isRequired ? (
        <Typography variant="caption" className={classes.required}>
          *
        </Typography>
      ) : (
        <Typography variant="caption" className={classes.required}>
          {" "}
        </Typography>
      )}
      {props.isTooltip && (
        <LightTooltip
          title={props?.tooltip}
          placement={props?.tooltipPlacement}
        >
          <span
            style={{
              display: "inline-flex",
              alignItems: "center",
              verticalAlign: "middle",
            }}
          >
            <InfoIcon style={{ width: "16px", height: "16px" }} />
          </span>
        </LightTooltip>
      )}
    </Typography>
  );
};
