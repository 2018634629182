import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../utils";
export const ReceiptStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 0px 16px #00000014",
        borderRadius: "4px"
    },
    historyRoot: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 0px 16px #00000014",
        borderRadius: "4px",
        height: `calc(100vh - 210px)`,
        overflow: "auto"
    },
    detailRoot: {
        height: `calc(100vh - 270px)`,
        overflow: "auto"
    },
    detailRoot2: {
        height: `calc(100vh - 310px)`,
        overflow: "auto"
    },
    left: {
        borderLeft: `1px solid ${theme.palette.border.third}`
    },
    view: {
        color: theme.palette.primary?.main,
        textDecoration: "underline",
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().bold,
        cursor: "pointer"
    },
    downBtn: {
        border: `1px solid ${theme?.typography?.color?.primary}`,
        color: theme?.typography?.color?.primary,
        backgroundColor: "white",
        "&:hover": {
            backgroundColor: "white",
            border: `1px solid ${theme?.typography?.color?.primary}`,
        }
    }


}));