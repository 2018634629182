import { Box, Divider, Menu, Stack, Typography } from '@mui/material'
import React from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { TypeCategoryCompStyle } from './style';
import { CustomTabs, TextBox, UseDebounce } from '../../../components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AlertProps, NetWorkCallMethods, enumSelect, enum_types, remCalc, FontFamilySwitch } from '../../../utils';
import { NetworkCall } from '../../../networkcall';
import { config } from '../../../config';
import { AlertContext } from '../../../contexts';
import useTheme from '@mui/material/styles/useTheme';

export const TypeCategoryComp = ({
    onChange = () => false,
    value = {},
    isReadOnly = false,
    t = () => false
}) => {
    const classes = TypeCategoryCompStyle()
    const alert = React.useContext(AlertContext)
    const debounce = UseDebounce()

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [val, setVal] = React.useState(0);
    const [enumValue, setEnumValue] = React.useState({
        inspection_item_type: []
    })
    const [catData, setCatData] = React.useState({
        list: [],
        count: 0
    })
    const [offset, setOffset] = React.useState(0)
    const [searchText, setSearchText] = React.useState("")

    const theme = useTheme();
    const tabStyles = {
        tabs: {
            minHeight: 32,
            '& .MuiTabs-flexContainer': {
                gap: '4px',
                padding: "4px 0px 0px",
                '& .MuiButtonBase-root': {
                    borderBottom: `1.5px solid ${theme.palette.background.tertiary}`,
                    padding: '12px',
                    minHeight: '32px',
                    '& p': {
                        fontFamily: FontFamilySwitch().regular,
                        fontSize: "0.75rem",
                        color: theme.typography.color.tertiary,
                    }
                },
                '& .Mui-selected': {
                    '& p': {
                        color: `${theme.palette.primary.main} !important`,
                        fontFamily: FontFamilySwitch().bold,
                        fontSize: remCalc(12)
                    },
                    borderBottom: `1.5px solid ${theme.palette.primary.main}`,
                },
            },
            '& .MuiTabs-indicator': {
                height: 0
            }
        }
    }

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (newValue) => {
        if (newValue === 1) {
            if (value?.type && Object.keys(value?.type)?.length) {
                getCategory(0 , "")
                setVal(newValue);
            } else {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.warning,
                    msg: "Please select type",
                });
            }
        } else {
            setVal(newValue);
        }

    };

    React.useEffect(() => {
        if(!isReadOnly){
            getEnum()
        }
        // eslint-disable-next-line
    }, [])

    //get Enum
    const getEnum = async () => {
        const result = await enumSelect([enum_types.inspection_item_type])
        setEnumValue({
            inspection_item_type: result?.inspection_item_type?.filter((i) => i?.value !== "Product"),
            temp_inspection_item_type: result?.inspection_item_type?.filter((i) => i?.value !== "Product")
        })
    }

    const getCategory = (offset, search = "") => {
      const payload = {
        item_type: [value?.type?.value],
        search: search,
        offset: offset,
        limit: 10,
      };

      // Reset the state if the offset is 0 to ensure the old list is not included
      if (offset === 0) {
        setCatData({
          list: [],
          count: 0,
        });
      }

      NetworkCall(
        `${config.api_url}/inspection_item_category`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((res) => {
          setCatData({
            // If offset is 0, use new list, otherwise append to existing list
            list:
              offset === 0
                ? res?.data?.list
                : [...catData.list, ...res?.data?.list],
            count: res?.data?.count,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const handleSearch = (searchText = "") => {
        setSearchText(searchText)
        if (val === 0) {
            debounce(() => {
                if (searchText?.length > 0) {
                    setEnumValue({
                        ...enumValue,
                        inspection_item_type: enumValue?.inspection_item_type?.filter(item => item.label.toLowerCase().includes(searchText.toLowerCase()))
                    })
                } else {
                    setEnumValue({
                        ...enumValue,
                        inspection_item_type: enumValue?.temp_inspection_item_type
                    })
                }
            }, 800)
        } else {
            debounce(() => getCategory(0, searchText), 800)
        }
    }

    const fetchMoreData = () => {
        setOffset(offset + 10);
        getCategory(offset + 10, searchText);
    };

    const onChangeClick=(e)=>{
        if(!isReadOnly ){
            handleClick(e)
        }
    }

    return (
        <>
            <Stack className={isReadOnly ? classes.disabledBox : classes.selectBox}
                alignItems={"center"}
                justifyContent={"space-between"} p={"8px 0px"} direction={"row"}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={onChangeClick}
            >
                <Typography className={(!isReadOnly && value?.type && (Object.keys(value?.type)?.length > 0 || Object.keys(value?.type)?.length > 0)) ? classes.valueText : classes.placeholder}>{(value?.type && (Object.keys(value?.type)?.length > 0 || Object.keys(value?.type)?.length > 0)) ? `${value?.type?.label}, ${value?.category?.label ?? ""} ` : t("Choose Type & Category")}</Typography>
                {open ? <KeyboardArrowUpIcon sx={{ color: "#ccc" }} /> : <KeyboardArrowDownIcon sx={{ color: "#ccc" }} />}
            </Stack>


            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                className={classes.menu}
            >
                <CustomTabs
                    tabList={[
                        { title: 'Type' },
                        { title: 'Category' },
                    ]}
                    active={val}
                    onChangeTab={(value) => {
                        handleChange(value)
                    }}
                    sx={tabStyles}
                />

                <Box p={1}>
                    <Box sx={{ width: 175 }}>
                        <TextBox
                            label=""
                            placeholder="Search"
                            value={searchText}
                            height={"32px"}
                            padding={"2px 4px 4px 8px"}
                            onChange={(e) => {
                                handleSearch(e.target.value);
                            }}
                            color={"#F5F7FA"}
                            border={"1px solid #E4E8EE"}
                        />
                    </Box>

                    {
                        val === 0 ?
                            <Box className={classes.box} mt={1}>
                                {/* <Stack rowGap={"4px"}> */}
                                {
                                    enumValue?.inspection_item_type?.map((e, i) => {
                                        return (
                                            <>
                                                <Typography className={value?.type?.value === e?.value ? classes.selectedLabel : classes.label}
                                                    onClick={() => {
                                                        onChange({
                                                            type: e,
                                                            category: null
                                                        });
                                                    }}
                                                >{e?.label}</Typography>
                                                {enumValue?.inspection_item_type?.length - 1 !== i && <Divider></Divider>}
                                            </>
                                        )
                                    })
                                }
                                {/* </Stack> */}
                            </Box> :
                            <InfiniteScroll
                                dataLength={catData?.count}
                                next={fetchMoreData}
                                hasMore={true}
                                height={"180px"}
                            >
                                {
                                    catData?.list?.map((e, i) => {
                                        return (
                                            <>
                                                <Typography className={value?.category?.value === e?.value ? classes.selectedLabel : classes.label}
                                                    onClick={() => {
                                                        onChange({
                                                            type: value?.type,
                                                            category: e
                                                        })
                                                        handleClose()
                                                    }}
                                                >{e?.label}</Typography>
                                                {catData?.list?.length - 1 !== i && <Divider></Divider>}
                                            </>
                                        )
                                    })
                                }
                            </InfiniteScroll>
                    }
                </Box>

            </Menu >
        </>
    )
}