import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const dashboardStyles = makeStyles((theme) => ({
      totalBox: {
            position: 'absolute',
            top: '40%',
            width: '100%',
            textAlign: 'center'

      },
      root: {
            position: 'relative'
      },
      title: {
            fontSize:"0.75rem",
            color: theme.typography.color.primary,
            fontFamily: FontFamilySwitch().bold,
      },
      count: {
            fontSize:"1.5rem",
            color: theme.typography.color.primary,
            fontFamily: FontFamilySwitch().extraBold,
      },
}))