import { Box, Button, Divider, Stack, Typography } from "@mui/material"
import React from "react"
import { CloseIconWithBG } from "../../../assets"
import { FormGenerator, UploadComponent } from "../../../components"
import { config } from "../../../config"
import { AlertContext } from "../../../contexts"
import { NetworkCall } from "../../../networkcall"
import { AlertProps, NetWorkCallMethods, enumSelect, enum_types, useWindowDimensions } from "../../../utils"
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions"
import { StatusOptionList } from "../../../utils/insepectionMaster"
import { useStyles } from "../style"
export const CreateForm = ({ t, tabValue = "1", onClose = () => false, reload = () => false, company = "", data = [], setData = () => false }) => {
    const classes = useStyles()
    const size = useWindowDimensions()
    const alert = React.useContext(AlertContext);
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)
    const [assets, setAssets] = React.useState([])
    const [itemTypeOptions, setItemTypeOptions] = React.useState([])

    React.useEffect(() => {
        getEnum()
        if (data?.is_Edit) {
            getCheckListGroupViewDetails()
        }
        //eslint-disable-next-line
    }, [])
    // Function to get Enum value
    const getEnum = async () => {
        const result = await enumSelect([enum_types.inspection_item_type])
        setItemTypeOptions(result?.inspection_item_type?.filter((val) => { return val?.value !== "Product" }))
    }
    //get check list group  details
    const getCheckListGroupViewDetails = () => {

        const payload = {
            id: data?.id
        }

        NetworkCall(
            `${config.api_url}/check_list_group/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            const value = response?.data
            let editData = {
                id: value?.id,
                group_name: value?.name,
                unit_type: { label: value?.unit_type, value: value?.unit_type_id },
                item_type: { label: value?.inspection_type, value: value?.inspection_type },
                item_category: { label: value?.item_category, value: value?.item_category_id },
                item_name: { label: value?.inspection_item, value: value?.inspection_item_id },
                instructions: value?.instructions,
                status: value?.is_active,
                is_default_template:value?.is_default
            }
            setData({ ...data, ...editData })
            setAssets(value?.assets?.length > 0 ? value?.assets : [])
        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something Went Wrong"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        })
    }
    //update state of master
    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        if (key === "item_type") {
            setData({ ...data, [key]: value, error, "item_category": "", "item_subcategory": "", item_name: "" });
        }
        else if (key === "item_category") {
            setData({ ...data, [key]: value, error, "item_subcategory": "", item_name: "" });
        }
        else {
            setData({ ...data, [key]: value, error });
        }


    };
    const validateForm = () => {
        let isValid = true;
        let error = data.error;
        if (data?.group_name?.length === 0) {
            isValid = false;
            error.group_name = `${t("Check List Group Name is required")}`;
        }
        if (tabValue === "1" && data?.unit_type?.length === 0) {
            isValid = false;
            error.unit_type = `${t("Unit type is required")}`;
        }
        if (tabValue !== "1" && data?.item_type?.length === 0) {
            isValid = false;
            error.item_type = `${t("Item type is required")}`;
        }
        if (tabValue !== "1" && data?.item_category?.length === 0) {
            isValid = false;
            error.item_category = `${t("Item Category is required")}`;
        }
        if (tabValue === "3" && data?.item_name?.length === 0) {
            isValid = false;
            error.item_name = `${t("Item Name is required")}`;
        }
        setData({ ...data, error });
        return isValid;
    }
    const handleSubmit = () => {
        if (validateForm()) {
            setIsDisableBtn(true)
            const payload = {
                "upsert_fields": {
                    "id": data?.is_Edit ? data?.id : undefined,
                    "company_id": company?.value,
                    "name": data?.group_name,
                    "unit_type": data?.unit_type?.value ?? undefined,
                    "inspection_type": tabValue === "1" ? "Inspection" : data?.item_type?.value ?? undefined,
                    "inspection_item_category": data?.item_category?.value ?? undefined,
                    "inspection_item": data?.item_name?.value ?? undefined,
                    "assets": assets?.length > 0 ? JSON.stringify(assets) : [],
                    "instructions": data?.instructions,
                    "is_active": data?.status,
                    "is_default":data?.is_default_template,
                    "check_list_group_type": tabValue === "1" ? "Unit" : tabValue === "2" ? "General" : "Item Group"
                }
            }
            NetworkCall(
                `${config.api_url}/check_list_group/upsert`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            )
                .then((res) => {
                    onClose()
                    setIsDisableBtn(false)
                    reload()
                    // if(data?.is_Edit){
                    //     const group_type = tabValue === "1" ? "Unit" : tabValue === "2" ? "General" : "Item Group"
                    //     navigate(Routes?.checkListGroupView + "?id=" + data?.id + "&type=" + group_type)
                    // }
                    // else{
                    //     reload()
                    // }               
                    alert.setSnack({
                        ...alert, open: true, msg: data?.is_Edit ? t("Check List Group Updated Successfully") : t("Check List Group Created Successfully"),
                        severity: AlertProps.severity.success
                    })
                })
                .catch((error) => {
                    onClose()
                    setIsDisableBtn(false)
                    if (error.response) {

                        // Request made and server responded
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: Object.keys(error.response.data.error.message?.msg).length !== 0 ? error?.response?.data?.error?.message?.msg : "Something went wrong",
                            vertical: AlertProps.vertical.top,
                            horizontal: AlertProps.horizontal.center,
                        });

                    }
                    else {
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: t("Something went wrong please try again"),
                            vertical: AlertProps.vertical.top,
                            horizontal: AlertProps.horizontal.center,
                        });
                    }

                });
        }
    }
    const maualResponse = (val) => {
        let result = val?.map((val) => {
            return {
                value: val?.id,
                label: val?.name
            }
        })
        return result;
    }
    const manualResponseUnitType = (array) => {
        const details = {
            data: array?.data?.map((i) => {
                return {
                    ...i,
                    label: i?.label,
                    value: i?.value
                }
            }),
            count: array?.count
        }
        return details
    };
    const changeInput = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "text",
            label: t("Check List Group Name"),
            placeholder: t("Enter Check List Group Name"),
            value: data?.group_name,
            onChange: (value) => updateState("group_name", value.target.value),
            error: data?.error?.group_name,
            isRequired: true,
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6
            },
            isActive: tabValue === "1" ? true : false,
            component: "select",
            label: t("Unit Type"),
            placeholder: t("Select Unit type"),
            value: data?.unit_type,
            onChange: (value) => updateState("unit_type", value),
            error: data?.error?.unit_type,
            isRequired: true,
            loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                    "queries/unit_type_master",
                    {
                        company_id: company?.value,
                    },
                    search,
                    array,
                    handleLoading,
                    "data",
                    manualResponseUnitType
                ),
            debounceTimeout: 800,
            isPaginate: true,
            isReadonly: data?.isView,
            menuOptionHeight: "150px",
            menuPlacement: "bottom"

        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "select",
            label: t("Item Type"),
            placeholder: t("Select Item type"),
            value: tabValue === "1" ? { value: "Inspection", label: "Inspection" } : data?.item_type,
            onChange: (value) => updateState("item_type", value),
            error: data?.error?.item_type,
            isRequired: true,
            isReadonly: tabValue === "1" ? true : false,
            color: tabValue === "1" ? "#F6F6F6" : "white",
            options: itemTypeOptions,
            menuOptionHeight: "150px",
            menuPlacement: "bottom"

        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6
            },
            isActive: tabValue !== "1" ? true : false,
            component: "select",
            label: t("Item Category"),
            placeholder: t("Select Item Category"),
            value: data?.item_category,
            onChange: (value) => updateState("item_category", value),
            error: data?.error?.item_category,
            isRequired: true,
            loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                    "inspection_item_category",
                    {
                        "item_type": [data?.item_type?.value],
                    },
                    search,
                    array,
                    handleLoading,
                    "list",
                ),
            key: JSON.stringify(data?.item_type),
            debounceTimeout: 800,
            isPaginate: true,
            isReadonly: data?.isView,
            menuOptionHeight: "150px",
            menuPlacement: "bottom"

        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: tabValue === "3" ? true : false,
            component: "custom_select",
            label: t("Item Name"),
            placeholder: t("Enter Item Name"),
            value: data?.item_name,
            onChange: (value) => updateState("item_name", value),
            loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                    "inspection_items/getAll",
                    {
                        "company_id": company?.value,
                        is_active: [true],
                        "items": [data?.item_type?.value],
                        "category_ids": [data?.item_category?.value]
                    },
                    search,
                    array,
                    handleLoading,
                    "data",
                    {},
                    maualResponse

                ),
            key: JSON.stringify({ item_type: data?.item_type, item_category: data?.item_category }),
            debounceTimeout: 800,
            isPaginate: true,
            error: data?.error?.item_name,
            isReadonly: data?.isView,
            isRequired: true,
            menuOptionHeight: "150px",
            menuPlacement: "bottom"
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "toggle",
            label: t("Status"),
            placeholder: t("status"),
            value: data?.status,
            onChange: (value) => updateState("status", value),
            error: data?.error?.status,
            isRequired: true,
            options: StatusOptionList(t),
            isReadonly: data?.isView
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "toggleButton",
            label: t("Make this the default template?"),
            value: data?.is_default_template,
            // isReadonly: data?.is_default_template,
            isRequired:true,
            onChange:  (value) => updateState("is_default_template", value),
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "text",
            label: t("Instructions"),
            placeholder: t("Enter Instructions"),
            multiline: true,
            value: data?.instructions,
            onChange: (value) => updateState("instructions", value.target.value),
            isReadonly: data?.isView,
        },
    ]
    return (
        <Box>
            <Stack direction="row" spacing={2} alignItems="center" p={1.5} sx={{backgroundColor:"#F2F4F7"}}>
                <Box onClick={onClose} style={{ cursor: "pointer" }}><CloseIconWithBG /></Box>
                {data?.is_Edit ?
                    <Typography className={classes.drawerHeader}>{tabValue === "1" ? t("Edit Check List Group (Unit)") : tabValue === "2" ? t("Edit Check List Group (General & Item)") : t("Edit Item Specific Group Check List")}</Typography>
                    :
                    <Typography className={classes.drawerHeader}>{tabValue === "1" ? t("Create New Check List Group (Unit)") : tabValue === "2" ? t("Create New Check List Group (General & Item)") : t("Create Item Specific Group Check List")}</Typography>
                }
            </Stack>
            <Divider />
            <Box p={2} width="500px" height={size?.height - 120} overflow="scroll" position={"relative"}>
                <FormGenerator t={t} components={changeInput} />
                <Box mt={1}>
                    <UploadComponent
                        // readOnly={type?.label === resourceTypes?.users && data?.is_exisit}
                        handleChange={(val) => updateState("assets", val)}
                        logo_title={t("Documents")}
                        errorMessage={data?.error?.assets}
                        isError={data?.error?.assets?.length > 0}
                        assets={assets}
                        setAssets={setAssets}
                        xs={6}
                        height={"190px"}
                        accept={"image/*,.pdf"}
                        imageHeight={"188px"}
                        readOnly={data?.isView}
                        border={true}
                    />
                </Box>
            </Box>
            <Box className={classes.fixedBottom}>
                <Button sx={{ height: "40px" }} variant="contained" fullWidth onClick={handleSubmit} disabled={isDisableBtn}>{data?.is_Edit ? t("Update") : t("Create")}</Button>
            </Box>
        </Box>
    )
}