import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../../utils";

export const CreatePopupCardstyle = makeStyles((theme) => ({
    card: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
        cursor: "pointer"
    },
    avatar: {
        height: 48,
        width: 48
    },
    title: {
        fontSize:"1rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
    },
    subtitle: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.tertiary
    }
}))

export const CreateStripStyle = makeStyles((theme) => ({
    countBox: {
        display: "flex",
        alignItems: "center",
        borderRadius: "50%",
        background: theme.palette.background.tertiary,
        height: 22,
        width: 22,
        padding: 4
    },
    count: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().bold
    },
    strip: {
        background: "#fff",
        boxShadow: "0px 0px 16px #00000014",
        height: 52,
        borderRadius: 4
    },
    title: {
        fontSize:"1rem",
        fontFamily: FontFamilySwitch().bold,
        cursor: "pointer",
        marginInlineStart: "16px"
    },
    propertyBox: {
        background: theme.palette.background.secondary,
        width: 600,
        borderRadius: 4
    },
    sub: {
        height: 80,
        borderRadius: 4
    }
}))

export const AddMenuStyle = makeStyles((theme) => ({
    addBtn: {
        fontSize:"0.875rem",
        color: theme.palette.primary.main,
        fontFamily: FontFamilySwitch().bold,
        '&:hover': {
            background: theme.palette.info.light
        }
    },
    editBtn: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
        '&:hover': {
            background: theme.palette.info.light
        }
    },
    menuItem: {
        width: 370,
        height: "100%",
        background: "transparent",
        '&:hover': {
            background: "transparent"
        }
    },
    scopeMenuItem: {
        width: 150,
        background: "transparent",
        '&:hover': {
            background: "transparent"
        }
    },
    extraLabel: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    menuLabel: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().semiBold,
    }
}))

export const PopupCardStyle = makeStyles((theme) => ({
    card: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
    },
    selectedCard: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 4,
        background: theme.palette.background.selected
    },
    avatar: {
        height: 40,
        width: 40
    },
    title: {
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
    },
    subtitle: {
        fontSize:"0.75rem",
        fontFamily: FontFamilySwitch().regular,
        color: theme.typography.color.secondary
    }
}))

export const PropertyUnitAddStyle = makeStyles((theme) => ({
    grid: {
        border: `1px solid ${theme.palette.border.primary}`,
        cursor: "pointer"
    },
    container: {
        width: 550
    },
    text: {
        color: theme.palette.primary.main,
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
    },
    selectedTitle: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    selectedSubTitle: {
        fontSize:"0.875rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    deleteBtn: {
        cursor: "pointer",
        marginInlineStart: "16px"
    }
}))


export const RateConfigComponentStyle = makeStyles((theme) => ({
    btn: {
        height: 40,
        '&:hover': {
            background: "transparent"
        }
    },
    title: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    subtitle: {
        fontSize:"0.875rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().regular
    }
}))


export const NewRateConfigurationStyle = makeStyles((theme) => ({
    addBtn: {
        height: 40,
        "&:hover": {
            background: "transparent"
        }
    },
    title: {
        fontSize: "0.875rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().bold
    }
}))