import { Stack, Step, StepLabel, Stepper } from "@mui/material";
import React from "react";
import { DynamicPlanSteps, steps } from "../utils";
import { FontFamilySwitch } from "../../../utils";
export const PlanStepper = ({ activeStep = 0, state = {} }) => {
    return (
        // <Box display={"flex"} justifyContent={"space-between"}>
        //     {steps?.map((val, i) => {
        //         return (
        //             // <>
        //             //     <Box  position={"relative"}>
        //             //         <center>
        //             //             <Box className={classes.stepperValue}>
        //             //                 <Typography>{val?.value}</Typography>
        //             //             </Box>
        //             //             <Box mt={1}>
        //             //                 <Typography className={classes.customlabel}>{val?.label}</Typography>
        //             //             </Box>
        //             //         </center>
        //             //         {steps?.length - 1 !== i &&
        //             //         <Box className={classes.horizontalBorder}></Box>
        //             //     }
        //             //     </Box>

        //             // </>
        //         )
        //     })}
        // </Box>
        <Stack sx={{ width: '70%' }} spacing={1}>
            <Stepper alternativeLabel activeStep={activeStep} sx={{
                "& .MuiStepConnector-line": {
                    borderTopWidth: "4.5px",
                },
                "& .MuiStepConnector-root.Mui-disabled .MuiStepConnector-line": {
                    borderColor: "#F2F4F7",
                },
                "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": {
                    borderColor: "#5AC782",
                },
                "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line": {
                    borderColor: "#5AC782",
                },
                '& .MuiStepLabel-root .Mui-completed': {
                    color: '#5AC782', // circle color (ACTIVE)
                },
                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                {
                    color: '#4E5A6B', // Just text label (ACTIVE)
                    fontSize: "0.75rem",
                    fontFamily: FontFamilySwitch().semiBold,
                },
                '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                {
                    color: '#5078E1', // Just text label (ACTIVE)
                    fontSize: "0.75rem",
                    fontFamily: FontFamilySwitch().semiBold,
                },
                '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
                    marginTop: "6px !important"
                },
                '& .MuiStepLabel-label':{
                    fontSize: "0.75rem",
                    fontFamily: FontFamilySwitch().semiBold,
                    color:"#4E5A6B"
                },
                '& .MuiStepLabel-root .Mui-alternativeLabel':
                {
                    color: 'white', // Just text label (ACTIVE)
                    fontSize: "0.75rem",
                    fontFamily: FontFamilySwitch().semiBold,
                },

                "& .Mui-disabled .MuiStepIcon-root": { color: "#FFFFFF", border: "1px solid #CED3DD", borderRadius: "50%" },
                "& .Mui-disabled .MuiStepIcon-text": { fill: "#98A0AC", fontFamily: FontFamilySwitch().bold, fontSize: "0.75rem" }

            }}>
                {state?.planner_type === "Dynamic" ?
                    DynamicPlanSteps.map((val) => (
                        <Step key={val?.value}>
                            <StepLabel>{val?.label}</StepLabel>
                        </Step>
                    ))
                    : steps.map((val) => (
                        <Step key={val?.value}>
                            <StepLabel>{val?.label}</StepLabel>
                        </Step>
                    ))}
            </Stepper>
        </Stack>
    )
}
