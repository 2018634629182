import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    Label: {
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: (props) => props?.labelSize ??"0.75rem",
        marginBottom: (props) => props?.labelMarginBottom ?? "5px",
    },
    root: {
        position: "relative",
        cursor: "pointer"
    },
    list: {
        //position: "absolute",
        zIndex: 1,
        background: "white",
        border: "1px solid #CED3DD",
        width: "100%",
        padding: "12px",
        borderRadius: "4px"
    },
    field: {
        height: "45px",
        border: "1px solid #CED3DD",
        width: "100%",
        padding: "12px",
        borderRadius: "4px",
        color: theme.typography.color.tertiary,
        fontSize:"0.875rem",
        marginBottom: "12px"
    }
}));