import { Box, Button, Divider, Drawer, Grid, Menu, MenuItem, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { DetailCard } from './components/detailCard'
import AgreementLogo from './utlis/agreementLogo'
import TransactionTypeLogo from './utlis/transactionTypeLogo'
import styled from '@mui/material/styles/styled';
import { CreateEditInvoiceStyle } from './style'
import { BottomTotalCard } from './components/bottomTotalCard'
import TotalDiscount from './utlis/totalDiscount'
import { FreeTextInvoiceContext } from './freeTextInvoiceContext'
import { PropertyUnitSelect } from './components/propertyUnitSelect'
import TotalBeforeAmount from './utlis/totalBeforeAmount'
import TotalTaxes from './utlis/totalTaxes'
import TotalAfterTaxes from './utlis/totalAfterTaxed'
import { propertyUnitListDetail, propertyUnitListDetailView } from '../../utils/freeTextInvoice'
import { DeleteIcon } from '../../assets'
import { LocalStorageKeys, checkNumber } from '../../utils'
import { TextBox } from '../../components'
import { NewFormStep } from '../../components/newFormSteps'
import NotesIcon from './utlis/notesIcon'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CustomSelectBox } from '../../components/customSelectBox'
import { TypeCategoryComp } from './components/typeCategoryComp'
import { ComponentTypes, ItemMasterTypes } from './utlis/freeTextInvoice'

const CustomPaper = styled('div')(({ theme }) => ({
    // border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    height: `calc(100vh - 270px)`,
    overflow: "auto",
    marginTop: "16px",
    // position: "relative",
    // [theme.breakpoints.only('sm')]:{
    //     height: `calc(100vh - 400px)`
    // }
}))

export const CreateEditInvoice = () => {

    const classes = CreateEditInvoiceStyle()

    const language = localStorage.getItem("i18nextLng");

    const {
        stateData,
        updateStateData,
        freeTextData,
        handleLoadMorePropertyUnit,
        handlePropertyUnitSearch,
        selectedCompany,
        setRowData,
        removeObjectFromArray,
        addTableRow,
        handleCreateinvoice,
        updateState,
        setFreeTextData,
        handleEditAgreement,
        handleRemoveUnit,
        t,
        handleEdit,
        isDisabled,
        handleShowMore,
        setStateData,
        handleCloseNewLine,
        anchorEl,
        enumValue,
        handleAddMenuClose,
        is_taxable
    } = React.useContext(FreeTextInvoiceContext)

    const tableHeader = stateData?.type === "view" ? propertyUnitListDetailView(t) : propertyUnitListDetail(t)

    const [selectedProperty, setSelectedProperty] = React.useState([])

    const pricingFactormanualResponse = (array) => {
        const details = array?.map((i) => {
            return {
                label: i?.name,
                value: i?.id,
                ...i
            }
        })
        return details
    };

    const unitManualResponse = (array) => {
        const details = array?.map((i) => {
            return {
                label: i?.unit_name,
                value: i?.unit_id,
                ...i
            }
        })
        return details
    }

    const handleUpdateProperty = () => {
        setSelectedProperty(freeTextData?.selectedPropertyUnit)
        updateStateData("editPropertyUnit", true)
    }

    const updateProperty = () => {
        if (selectedProperty?.length >= freeTextData?.selectedPropertyUnit?.length) {
            updateState("selectedPropertyUnit", selectedProperty)
            updateStateData("editPropertyUnit", false)
        } else {
            if (selectedProperty?.length === 0) {
                setFreeTextData({ ...freeTextData, error: { ...freeTextData?.error, selectedPropertyUnit: "Property / Unit is Required" } })
            } else {
                const original = freeTextData?.selectedPropertyUnit // [{1},{2},{3}]
                const duplicate = selectedProperty // [{2}] -- retain array
                const removedProperty = original?.filter((i) => !duplicate?.some((j) => j?.property_id === i?.property_id))?.map((e) => e?.property_id)
                handleRemoveUnit(removedProperty, true, selectedProperty)
                updateStateData("editPropertyUnit", false)
            }
        }
    }

    const handleSelectProperty = (value) => {
        if (stateData?.value !== "view") {
            if (selectedProperty?.filter((i) => i?.property_id === value?.property_id)?.length) {
                const data = selectedProperty?.filter((i) => i?.property_id !== value?.property_id)
                setSelectedProperty(data)
                setFreeTextData({ ...freeTextData, error: { ...freeTextData?.error, selectedPropertyUnit: "" } })
            } else {
                setSelectedProperty([...selectedProperty, value])
                setFreeTextData({ ...freeTextData, error: { ...freeTextData?.error, selectedPropertyUnit: "" } })
            }
        }

    }
    const hasValidAmount = React.useMemo(() => {
        return freeTextData?.tableRowData?.length > 0 && freeTextData.tableRowData.every(row => {
            const amount = parseFloat(row.amount) || 0;
            return amount > 0;
        });
    }, [freeTextData?.tableRowData]);

    return (
        <Box p={2} sx={{ overflow: "auto", height: "calc(100vh - 100px)" }}>
            <Grid container spacing={2}>
                <Grid item md={freeTextData?.selectedAgreement?.label?.length ? 6 : 4} sm={freeTextData?.selectedAgreement?.label?.length ? 6 : 4} lg={freeTextData?.selectedAgreement?.label?.length ? 3 : 4} xl={freeTextData?.selectedAgreement?.label?.length ? 3 : 4} xs={12}>
                    <DetailCard
                        header={t("CUSTOMER ACCOUNT")}
                        title={freeTextData?.selectedAccount?.name}
                        subtitle={freeTextData?.selectedAccount?.account_no}
                        url={freeTextData?.selectedAccount?.logo}
                        isTooltip
                        tooltipMsg={freeTextData?.description}
                        isReadOnly={stateData?.type === "view"}
                        t={t}
                        payment_term={freeTextData?.selectedPaymentTerms?.label} />
                </Grid>
                {
                    freeTextData?.selectedAgreement?.label?.length &&
                    <Grid item md={6} sm={6} lg={3} xl={3} xs={12}>
                        <DetailCard
                            logo={<AgreementLogo />}
                            isEdit
                            editText={t("Edit")}
                            handleEdit={() => handleEditAgreement()}
                            header={t("AGREEMENT")}
                            title={freeTextData?.selectedAgreement?.label}
                            subtitle={freeTextData?.selectedAgreement?.agreement_type}
                            isReadOnly={stateData?.type === "view"}
                            t={t}
                        />
                    </Grid>
                }
                <Grid item md={freeTextData?.selectedAgreement?.label?.length ? 6 : 4} sm={freeTextData?.selectedAgreement?.label?.length ? 6 : 4} lg={freeTextData?.selectedAgreement?.label?.length ? 3 : 4} xl={freeTextData?.selectedAgreement?.label?.length ? 3 : 4} xs={12}>
                    <DetailCard
                        isEdit
                        editText={t("Edit")}
                        handleEdit={() => handleUpdateProperty()}
                        header={t("SELECTED PROPERTY")}
                        title={`${freeTextData?.selectedPropertyUnit?.[0]?.property_name} | ${freeTextData?.selectedPropertyUnit?.[0]?.property_no}`}
                        subtitle={freeTextData?.selectedPropertyUnit?.length - 1 === 0 ? "" : `+${freeTextData?.selectedPropertyUnit?.length - 1} More`}
                        isMore
                        url={freeTextData?.selectedPropertyUnit?.[0]?.asset_url}
                        isReadOnly={stateData?.type === "view"}
                        t={t} />
                </Grid>
                <Grid item md={freeTextData?.selectedAgreement?.label?.length ? 6 : 4} sm={freeTextData?.selectedAgreement?.label?.length ? 6 : 4} lg={freeTextData?.selectedAgreement?.label?.length ? 3 : 4} xl={freeTextData?.selectedAgreement?.label?.length ? 3 : 4} xs={12}>
                    <DetailCard
                        logo={<TransactionTypeLogo />}
                        header={t("TRANSACTION TYPE")}
                        title={freeTextData?.selectedTransactionType?.title}
                        isHide={true}
                        isEdit
                        t={t} />
                </Grid>
            </Grid>

            <CustomPaper>
                <Box p={2}>
                    <TableContainer className={classes.table}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {
                                        tableHeader?.map((e) => {
                                            return (
                                                <TableCell className={classes.headCell} sx={{ width: e?.delete === "delete" ? 30 : "auto" }}>
                                                    <Typography className={classes.headText}> {e?.title} </Typography>
                                                </TableCell>
                                            )
                                        })
                                    }
                                </TableRow>
                            </TableHead>
                            {
                                <TableBody>
                                    {
                                        freeTextData?.tableRowData?.map((item) => {
                                            return (
                                                <TableRow>
                                                    <TableCell className={classes.bodyCell}>
                                                        <Stack direction={"row"} columnGap={"4px"} alignItems={"center"}>
                                                            <Box sx={{ width: 160 }}>
                                                                <CustomSelectBox
                                                                    value={item?.componentType}
                                                                    // menuOptionWidth={250}
                                                                    menuOptionPadding={"0px 0px 8px 0px"}
                                                                    isReadOnly={stateData?.type === "view"}
                                                                    options={item?.rowType?.value === "Component" ? ComponentTypes : ItemMasterTypes}
                                                                    onChange={(val) => {
                                                                        setRowData(val, item, "componentType")
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Stack>
                                                    </TableCell>

                                                    <TableCell className={classes.bodyCell}>
                                                        <Stack direction={"row"} columnGap={"4px"} alignItems={"center"}>
                                                            <Box sx={{ width: 220 }}>
                                                                <CustomSelectBox
                                                                    value={item?.unit}
                                                                    menuOptionWidth={250}
                                                                    menuOptionPadding={"0px 0px 8px 0px"}
                                                                    networkCallData={
                                                                        {
                                                                            path: freeTextData?.selectedAccountType?.value === "Vendor" ? "free_text_invoice/contract_units" : "/agreement-unit-info/fetch_agreement_units",
                                                                            payload: {
                                                                                property_array: freeTextData?.selectedPropertyUnit?.filter((i) => i?.property_id)?.map((e) => e?.property_id),
                                                                                // selected_units: [],
                                                                                // freeTextData?.tableRowData?.filter((i) => i?.unit?.value)?.map((e) => e?.unit?.value),
                                                                                agreement_id: freeTextData?.selectedAccountType?.value === "Vendor" ? undefined : freeTextData?.selectedAgreement?.value,
                                                                                contract_id: freeTextData?.selectedAccountType?.value === "Vendor" ? freeTextData?.selectedAgreement?.value : undefined
                                                                            },
                                                                            mappingVariable: "data",
                                                                            manualResponse: unitManualResponse
                                                                        }
                                                                    }
                                                                    onChange={(val) => {
                                                                        setRowData(val, item, "unit")
                                                                    }}
                                                                    placeholder={t("Select Unit")}
                                                                    isReadOnly={stateData?.type === "view"}
                                                                />
                                                            </Box>
                                                            {
                                                                item?.error?.unit?.length > 0 && (
                                                                <Tooltip arrow title={item?.error?.unit ?? ""} placement="top">
                                                                    <Box m="4px 0px 0px 4px">
                                                                        <InfoOutlinedIcon style={{ color: "#FF4B4B" }} />
                                                                    </Box>
                                                                </Tooltip>
                                                            )}
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell className={classes.bodyCell}>
                                                        <Box sx={{ width: 250 }}>
                                                            <TypeCategoryComp
                                                                t={t}
                                                                onChange={(val) => {
                                                                    setRowData(val, item, "itemTypeCategory")
                                                                }}
                                                                value={item?.itemTypeCategory}
                                                                isReadOnly={stateData?.type === "view" ? true : item?.rowType?.value === "Component"} />
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell className={classes.bodyCell}>
                                                        <Stack direction={"row"} columnGap={"4px"} alignItems={"center"}>
                                                            <Box sx={{ width: 220 }}>
                                                                <CustomSelectBox
                                                                    value={item?.pricingComponent}
                                                                    menuOptionWidth={250}
                                                                    menuOptionPadding={"0px 0px 8px 0px"}
                                                                    networkCallData={
                                                                        {
                                                                            path: "free_text_invoice/get_free_text_item_types",
                                                                            payload: {
                                                                                company_id: selectedCompany?.value,
                                                                                item_type: item?.rowType?.value,
                                                                                item_master_type: item?.itemTypeCategory?.type?.value,
                                                                                item_master_category: item?.itemTypeCategory?.category?.value,
                                                                                component_type: item?.componentType?.value
                                                                            },
                                                                            mappingVariable: "items",
                                                                            manualResponse: pricingFactormanualResponse
                                                                        }
                                                                    }
                                                                    key={JSON.stringify(item?.itemTypeCategory)}
                                                                    onChange={(val) => {
                                                                        setRowData(val, item, "pricingComponent")
                                                                    }}
                                                                    placeholder={t("Select Component")}
                                                                    isReadOnly={
                                                                        stateData?.type === "view" ||
                                                                        (item?.rowType?.value === "Component"
                                                                            ?!item?.unit?.value: !(
                                                                                item?.unit?.value &&
                                                                                item?.itemTypeCategory?.type?.value &&
                                                                                item?.itemTypeCategory?.category?.value
                                                                            )
                                                                            )
                                                                    }
                                                                />
                                                            </Box>
                                                            {
                                                                (item?.error?.category?.length > 0) &&
                                                                <Tooltip arrow title={item?.error?.category ?? ""} placement="top">
                                                                    <Box m="4px 0px 0px 4px">
                                                                        <InfoOutlinedIcon style={{ color: "#FF4B4B" }} />
                                                                    </Box>
                                                                </Tooltip>
                                                            }
                                                        </Stack>
                                                    </TableCell>
                                                    {
                                                        item?.path?.map((e) => {
                                                            return (
                                                                <>
                                                                    <TableCell className={classes.bodyCell}>
                                                                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}
                                                                            sx={{ height: "35px" }}>
                                                                            {
                                                                                e === "discount" ?
                                                                                    <Stack sx={{ width: "170px" }} direction={"row"} columnGap={"4px"} alignItems={"center"}>

                                                                                        <NewFormStep component={[
                                                                                            {
                                                                                                sm: 12,
                                                                                                md: 12,
                                                                                                lg: 12,
                                                                                                isActive: true,
                                                                                                type: "textWithSelect",
                                                                                                label: "",
                                                                                                value: item?.discountAmount,
                                                                                                handleChange: (val) => {
                                                                                                    setRowData(val, item, "discountAmount")
                                                                                                },
                                                                                                placeholder: t("Select Discount"),
                                                                                                customOption: [
                                                                                                    { label: "%", value: "%" },
                                                                                                    { label: selectedCompany?.currency_symbol, value: selectedCompany?.currency_symbol },
                                                                                                ],
                                                                                                selectChange: (value) => setRowData(value, item, "discountType"),
                                                                                                selectedValue: item?.discountType,
                                                                                                customHeight: 38,
                                                                                                customPadding: "6px 8px",
                                                                                                header: "",
                                                                                                isReadOnly: stateData?.type === "view",
                                                                                                textAlign: "right"
                                                                                            }
                                                                                        ]} />
                                                                                        {
                                                                                            (item?.discountError?.length > 0 && checkNumber(item?.discountAmount) !== 0 && e === "discount") &&
                                                                                            <Tooltip arrow title={item?.discountError ?? ""} placement="top">
                                                                                                <Box m="4px 0px 0px 4px">
                                                                                                    <InfoOutlinedIcon style={{ color: "#FF4B4B" }} />
                                                                                                </Box>
                                                                                            </Tooltip>
                                                                                        }
                                                                                    </Stack>
                                                                                    :
                                                                                    <TextBox
                                                                                        label={""}
                                                                                        height={38}
                                                                                        value={item?.[e] ?? ""}
                                                                                        padding={"6px 8px"}
                                                                                        type={"number"}
                                                                                        onBlur={(val) => {
                                                                                            if (e === "amount") {
                                                                                                val?.target?.value?.length === 0 ?
                                                                                                    setRowData(0, item, "amount")
                                                                                                    : setRowData(val?.target?.value, item, e)
                                                                                            }
                                                                                            else if (e === "qty") {
                                                                                                const qtyValue = val?.target?.value;
                                                                                                if (qtyValue?.length === 0 || qtyValue <= 0) {
                                                                                                    setRowData(1, item, "qty");
                                                                                                } else {
                                                                                                    setRowData(qtyValue, item, e);
                                                                                                }
                                                                                            }
                                                                                            else {
                                                                                                setRowData(val?.target?.value, item, e)
                                                                                            }
                                                                                        }}
                                                                                        onChange={(val) => {
                                                                                            setRowData(val?.target?.value, item, e)
                                                                                        }}
                                                                                        keyPress={(x) => {
                                                                                            if (e === "qty") {
                                                                                                if (x.key === ".") {
                                                                                                    x.preventDefault();
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                        isReadonly={stateData?.type === "view" || (e === "qty" && item?.rowType?.value === "Component")}
                                                                                        textAlign={"right"}
                                                                                    />
                                                                            }
                                                                        </Stack>
                                                                    </TableCell>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                    <TableCell className={classes.bodyCell}>
                                                        <TextBox
                                                            label={""}
                                                            height={38}
                                                            isReadonly
                                                            value={Number(item?.taxableAmount ?? 0)?.toFixed(2)?.toLocaleString('en-IN')}
                                                            padding={"6px 8px"}
                                                            textAlign={"right"}
                                                            endAdornment={
                                                                selectedCompany?.currency_symbol
                                                            } />
                                                    </TableCell>
                                                    <TableCell className={classes.bodyCell}>
                                                        <Stack direction={"row"} columnGap={"4px"} alignItems={"center"}>
                                                            <Box sx={{ width: 160 }}>
                                                                <CustomSelectBox
                                                                    value={item?.taxGroup}
                                                                    menuOptionPadding={"0px 8px"}
                                                                    networkCallData={
                                                                        {
                                                                            path: "vat-group/getAll",
                                                                            payload: {
                                                                                country_id: JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))?.data?.country?.id
                                                                            },
                                                                            mappingVariable: "vat_group_master",
                                                                        }
                                                                    }
                                                                    onChange={(val) => {
                                                                        setRowData(val, item, "taxGroup")
                                                                    }}
                                                                    isReadOnly={is_taxable === false ? true : stateData?.type === "view" ? true : !item?.taxable}
                                                                    placeholder={t("Select Tax Group")}
                                                                    type="tax"
                                                                />
                                                            </Box>
                                                            {
                                                                (item?.error?.taxGroup?.length > 0) &&
                                                                <Tooltip arrow title={item?.error?.taxGroup ?? ""} placement="top">
                                                                    <Box m="4px 0px 0px 4px">
                                                                        <InfoOutlinedIcon style={{ color: "#FF4B4B" }} />
                                                                    </Box>
                                                                </Tooltip>
                                                            }
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell className={classes.bodyCell}>
                                                        <TextBox
                                                            label={""}
                                                            height={38}
                                                            isReadonly
                                                            value={Number(item?.taxes ?? 0)?.toFixed(2)?.toLocaleString('en-IN')}
                                                            padding={"6px 8px"}
                                                            textAlign={"right"}
                                                            endAdornment={
                                                                selectedCompany?.currency_symbol
                                                            } />
                                                    </TableCell>
                                                    <TableCell className={classes.bodyCell}>
                                                        <Box sx={{ width: 120 }}>
                                                            <TextBox
                                                                label={""}
                                                                height={38}
                                                                isReadonly
                                                                value={Number(item?.subtotal ?? 0)?.toFixed(2)?.toLocaleString('en-IN')}
                                                                padding={"6px 8px"}
                                                                textAlign={"right"}
                                                                endAdornment={
                                                                    selectedCompany?.currency_symbol
                                                                } />
                                                        </Box>
                                                    </TableCell>
                                                    {stateData?.type !== "view" && <TableCell className={classes.bodyCell}>
                                                        <Box sx={{ cursor: "pointer" }}>
                                                            <DeleteIcon onClick={() => removeObjectFromArray(item)} />
                                                        </Box>
                                                    </TableCell>}
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            }
                        </Table>
                    </TableContainer>
                    {stateData?.type !== "view" && <Typography className={classes.addBillLine} onClick={addTableRow}
                        id="add_new_row"
                        aria-controls={stateData?.addTableRow ? 'add-new-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={stateData?.addTableRow ? 'true' : undefined}
                    >{t("Add New Bill Item +")}</Typography>}
                    <Menu
                        id="add-new-menu"
                        anchorEl={anchorEl}
                        open={stateData?.addTableRow}
                        onClose={handleAddMenuClose}
                        MenuListProps={{
                            'aria-labelledby': 'add_new_row',
                        }}
                    >
                        {
                            enumValue?.role_type?.map((e) => {
                                return (
                                    <MenuItem onClick={() => handleCloseNewLine(e)} className={classes.menuItem}>{t(e?.label)}</MenuItem>
                                )
                            })
                        }
                    </Menu>
                </Box>




                <Box className={classes.bottomCard}>
                    <Grid container alignItems={"center"} spacing={2}>
                        <Grid item sm={12} md={12} lg={6}>
                            <Stack direction={"row"} columnGap={2} divider={<Divider orientation="vertical" flexItem />} alignItems={"center"}>
                                <Grid container className={classes.containBox} alignItems={"center"}>
                                    <Grid item sm={3} md={3} lg={3} xl={3} className={classes.singleBorder}>
                                        <BottomTotalCard
                                            logo={<TotalDiscount />}
                                            title={t("Total Discount")}
                                            subtitle={freeTextData?.totalDiscount?.toLocaleString('en-IN')} />
                                    </Grid>
                                    <Grid item sm={3} md={3} lg={3} xl={3} className={classes.singleBorder}>
                                        <BottomTotalCard
                                            logo={<TotalBeforeAmount />}
                                            title={t("Total Before Taxes")}
                                            subtitle={freeTextData?.totalTaxableAmount?.toLocaleString('en-IN')} />
                                    </Grid>
                                    <Grid item sm={3} md={3} lg={3} xl={3} className={classes.singleBorder}>
                                        <BottomTotalCard
                                            logo={<TotalTaxes />}
                                            title={t("Total Taxes")}
                                            subtitle={freeTextData?.totalTaxes?.toLocaleString('en-IN')} />
                                    </Grid>
                                    <Grid item sm={3} md={3} lg={3} xl={3} className={language === "ar" && classes.singleBorder}>
                                        <BottomTotalCard
                                            logo={<TotalAfterTaxes />}
                                            title={t("Total After Taxes")}
                                            subtitle={freeTextData?.totalAmount?.toLocaleString('en-IN')} />
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Grid>
                        <Grid item sm={12} md={12} lg={6} display={"flex"} justifyContent={'end'}>
                            <Grid container alignItems={"center"}>
                                <Grid item sm={6} md={6} lg={6}>
                                    {
                                        freeTextData?.notes?.length === 0 ?
                                            <Button variant='outlined' className={classes.generateNotes} disabled={stateData?.type === "view"}
                                                disableElevation onClick={() => updateStateData("openNotes", true)}>{t("Generate Notes")}</Button>
                                            :
                                            <Box onMouseEnter={() => stateData?.type !== "view" && updateStateData("hoverNotes", true)}
                                                onMouseLeave={() => stateData?.type !== "view" && updateStateData("hoverNotes", false)}
                                                className={(stateData?.hoverNotes && stateData?.type !== "view") ? classes.notesHoverBox : classes.notesBox} p={1}>
                                                <Stack direction={"row"} alignItems={"center"} columnGap={"8px"}>
                                                    <NotesIcon />
                                                    <Stack width={"90%"}>
                                                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                                            <Typography className={classes.notesTitle}>{t("Notes")}</Typography>
                                                            {(stateData?.hoverNotes && stateData?.type !== "view") && <Typography className={classes.edit}
                                                                onClick={() => setStateData({
                                                                    ...stateData,
                                                                    openNotes: true,
                                                                    isDisableNotes: false
                                                                })}>{t("Edit")}</Typography>}
                                                        </Stack>
                                                        <Stack sx={{ width: "100%" }} direction={"row"} columnGap={1}>
                                                            <Typography className={classes.notesSubTitle}>
                                                                {freeTextData?.notes?.split("\n")?.[0]}
                                                            </Typography>
                                                            {freeTextData?.notes?.split("\n")?.length > 0 && <Typography noWrap onClick={() => handleShowMore()} className={classes.showMoreLabel}>Show More</Typography>}
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                    }
                                </Grid>
                                <Grid item sm={6} md={6} lg={6} display="flex" justifyContent={"end"}>
                                    <Button variant='contained' disableElevation onClick={() =>
                                        stateData?.type === "view" ?
                                            handleEdit() :
                                            handleCreateinvoice()
                                    } className={classes.createBtn} disabled={isDisabled ? isDisabled : freeTextData?.published || !hasValidAmount}>
                                        {stateData?.type === "view" ? t("Edit") : stateData?.type === "edit" ? t("Save") : t("Create")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </CustomPaper >

            <React.Fragment key={'right'}>
                <Drawer
                    anchor={'right'}
                    open={stateData?.editPropertyUnit}
                    onClose={() => updateStateData("editPropertyUnit", false)}
                >
                    <Box className={classes.drawerBox}>
                        <Box className={classes.header}>
                            <Stack direction={"row"} alignItems={"center"} p={1.5}>
                                <Typography className={classes.drawerCloseBtn}
                                    onClick={() => updateStateData("editPropertyUnit", false)}>X</Typography>
                                <Typography className={classes.drawerHeading} sx={{ marginInlineStart: 2 }}>{t("Edit Property")}</Typography>
                            </Stack>
                            <Divider></Divider>
                        </Box>
                        <Box className={classes.body}>
                            <Box p={2}>
                                <PropertyUnitSelect
                                    list={stateData?.propertyUnitList}
                                    handleLoadMorePropertyUnit={handleLoadMorePropertyUnit}
                                    onselect={(value) => handleSelectProperty(value)}
                                    value={selectedProperty}
                                    search={(value) => handlePropertyUnitSearch(value)}
                                    searchText={stateData?.propertyUnitSearchText}
                                    error={freeTextData?.error?.selectedPropertyUnit?.length > 0}
                                    errorMsg={freeTextData?.error?.selectedPropertyUnit}
                                    t={t} />
                            </Box>
                        </Box>

                        <Box className={classes.bottom} sx={{ width: "100%" }}>
                            <Stack direction={"row"} justifyContent={"space-between"}
                                alignItems={"center"} p={1}>
                                <Button
                                    className={classes.next}
                                    // disabled={disable}
                                    fullWidth
                                    onClick={() => updateProperty()}
                                    variant='contained'>
                                    {t("Update")}
                                </Button>
                            </Stack>
                        </Box>

                    </Box>
                </Drawer>
            </React.Fragment>


            <React.Fragment key={'right'}>
                <Drawer
                    anchor={'right'}
                    open={stateData?.openNotes}
                    onClose={() => updateStateData("openNotes", false)}
                >
                    <Box className={classes.drawerBox}>
                        <Box className={classes.header}>
                            <Stack direction={"row"} alignItems={"center"} p={1.5}>
                                <Typography className={classes.drawerCloseBtn}
                                    onClick={() => updateStateData("openNotes", false)}>X</Typography>
                                <Typography className={classes.drawerHeading} sx={{ marginInlineStart: 2 }}>{t("Notes")}</Typography>
                            </Stack>
                            <Divider></Divider>
                        </Box>
                        <Box className={classes.body} p={2}>
                            <TextBox
                                multiline
                                value={stateData?.notes}
                                onChange={(e) => updateStateData("notes", e.target.value)}
                                label={t("Notes")}
                                placeholder={t("Type Notes here")}
                                rowheight={10}
                                isReadonly={stateData?.isDisableNotes}
                            />
                        </Box>

                        <Box className={classes.bottom} sx={{ width: "100%" }}>
                            <Stack direction={"row"} justifyContent={"space-between"}
                                alignItems={"center"} p={1}>
                                <Button
                                    className={classes.next}
                                    disabled={stateData?.isDisableNotes}
                                    fullWidth
                                    onClick={() => {
                                        updateState("notes", stateData?.notes)
                                        updateStateData("openNotes", false)
                                    }}
                                    variant='contained'>
                                    {t("Update")}
                                </Button>
                            </Stack>
                        </Box>

                    </Box>
                </Drawer>
            </React.Fragment>

        </Box >
    )
}