import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FilterGenerator, Subheader, UseDebounce } from "../../components";
import { NewLoader } from "../../components/newLoader";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import {
  accessCheckRender,
  enumSelect,
  enum_types,
  getCompanyOption,
  getRoutePermissionNew,
  LocalStorageKeys,
  NetWorkCallMethods,
} from "../../utils";
import { AccountTable, DetailsContainer } from "./component";
import { useStyles } from "./styles";
import { withTranslation } from "react-i18next";

const Accounts = (props) => {
  const { type = null, subtitle = null, loading, handleLoading, t } = props;
  const classes = useStyles();
  const auth = React.useContext(AuthContext);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const debounce = UseDebounce();
  const [accountList, setAccountList] = React.useState({
    list: [],
    count: 0,
  });
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [search, setSearch] = React.useState("");
  const [loader, setLoader] = React.useState(true);
  const [selectedAccount, setSelectedAccount] = React.useState("");
  const [permission, setPermission] = React.useState({});
  const [isFilterData, setIsFilterData] = useState(false);
  const [filterData, setFilterData] = React.useState({
    account_type: [],
    is_active: [],
  });
  const [enumValue, setEnumValue] = React.useState({
    account_relationship: [],
  });
  const typeSelect = {
    Vendor: Routes.vendorAccountDetail,
    "Property Owner": Routes.ownerAccountDetails,
    Customer: Routes.customerAccountDetails,
    AllAccount: Routes.accountDetails,
    cashBank: Routes.cashBankAccountDetails,
  };

  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");
  const limitParam = searchParams.get("limit");

  // Default values if not set in URL
  const pageFromParams = pageParam ? parseInt(pageParam, 10) : 1;
  const limitFromParams = limitParam ? parseInt(limitParam, 10) : 10;

  const [page, setPage] = React.useState(pageFromParams);
  const [limit, setLimit] = React.useState(limitFromParams);

  const updatePage = (newPage, newLimit = limit) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", newPage.toString());
    searchParams.set("limit", newLimit.toString());

    // Update URL without reloading
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });

    // Update state
    setPage(newPage);
    setLimit(newLimit);
  };

  //handle pagination
  const handlePagination = (newPage) => {
    updatePage(newPage, limit);
  };

  const handleChangeLimit = (newLimit) => {
    updatePage(1, newLimit); // Reset page to 1 when limit changes
  };
  // get enum
  const getEnum = async () => {
    const result = await enumSelect([enum_types?.account_relationship]);
    setEnumValue({ account_relationship: result?.account_relationship });
  };
  // use effect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        getEnum();
        getCompanyList();
      }
    }
    // eslint-disable-next-line
  }, [auth]);
  const getAccounts = (company_id, limit, offset, searchText, filteredData) => {
    const payload = {
      company: company_id,
      limit: limit,
      offset: offset,
      search: searchText,
      client: localStorage.getItem(LocalStorageKeys.clinetID),
      // is_active: filteredData?.is_active,
      account_type:
        filteredData?.account_type?.length > 0
          ? filteredData?.account_type?.map((_) => _?.value)
          : type === "AllAccount"
          ? [
              "Property Owner",
              "Sales Broker",
              "Walk In Account",
              "Customer",
              "Vendor",
              "Service Provider",
            ]
          : type === "cashBank"
          ? ["Cash", "Credit Card", "Bank Account"]
          : [type],
    };
    NetworkCall(
      `${config.api_url}/account/get_account_list`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setAccountList({
          list: response?.data?.data?.contact_account?.map((val) => {
            // let city = val?.contact_account_address?.[0]?.city ?? ""
            // let country = val?.contact_account_address?.[0]?.country ?? ""
            return {
              ...val,
              // location: val?.contact_account_address?.length > 0 ? (city.concat(" ", country)) : "-",
              status: val?.is_active ? "Active" : "Inactive",
              mobile: `${val?.mobile_no_country_code ?? ""} ${
                val?.mobile ?? "-"
              }`,
            };
          }),
          count: response?.data?.data?.count[0]?.count,
        });
        setSelectedAccount(
          response?.data?.data?.contact_account?.[0]?.account_id
        );
        setLoader(false);
        handleLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  const currentOffset = (page - 1) * limit;
  //get company list
  const getCompanyList = () => {
    let company = getCompanyOption(backdrop, auth, alert);
    if (company) {
      setCompanyList(company?.list);
      setSelectedCompany(company?.selected);
      getAccounts(
        state?.value ?? company?.selected?.value,
        limit,
        currentOffset,
        "",
        filterData
      );
    }
  };
  //change company
  const handleCompanyChange = (value) => {
    setSelectedCompany(value);
    getAccounts(value?.value, limit, 0, search, filterData);
  };
  //on search
  const handleSearch = (e) => {
    setSearch(e);
    debounce(() => searchTableFunction(e), 800);
  };
  //search function
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getAccounts(selectedCompany?.value, 10, 0, e, filterData);
  };
  //handle icon
  const handleIcon = (types, data) => {
    if (types === "view") {
      setSelectedAccount(data?.account_id);
    } else if (["info", "double_click"].includes(types)) {
      navigate(type?.length > 0 ? typeSelect[type] : Routes.accountDetails, {
        state: { types: types, id: data?.id, type: type, company_id: data?.company_id },
      });
    }
  };
  //to filter data based on status and account_type
  const handleFilter = (value) => {
    setFilterData(value);
    getAccounts(selectedCompany?.value, 10, 0, "", value);
  };
  const render = () => {
    return (
      <>
        {/*sub header */}
        <Subheader
          title={subtitle === null ? t("Accounts") : t(subtitle)}
          select
          hideBackButton
          options={companyList}
          value={selectedCompany}
          onchange={(e) => {
            handleCompanyChange(e);
          }}
        />
        {loader ? (
          <NewLoader minusHeight="100px" />
        ) : (
          <Box className={classes.root}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={9}>
                {/*table */}
                <Box p={"8px 16px"}>
                  <AccountTable
                    type={type}
                    data={accountList}
                    selectedCompany={selectedCompany}
                    handleChangeLimit={handleChangeLimit}
                    handlePagination={handlePagination}
                    page={page}
                    handleSearch={handleSearch}
                    searchText={search}
                    limit={limit}
                    handleIcon={handleIcon}
                    permission={permission}
                    setIsFilterData={setIsFilterData}
                    enable_double_click={true}
                    enable_single_click={true}
                    t={t}
                    filterData={filterData}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={3}
                className={classes.detailsContainer}
              >
                {/*details */}
                <Box p={2}>
                  <DetailsContainer
                    selectedAccount={selectedAccount}
                    symbol={selectedCompany?.currency_symbol ?? "-"}
                    code={selectedCompany?.code ?? "-"}
                    t={t}
                  />
                </Box>
              </Grid>
            </Grid>
            {isFilterData && (
              <FilterGenerator
                open={isFilterData}
                onClose={() => setIsFilterData(false)}
                components={[
                  // {
                  //     component: "toggleButton",
                  //     value: filterData?.is_active,
                  //     state_name: "is_active",
                  //     label: "Status",
                  //     options: StatusOptionList,
                  //     isMulti: true
                  // },
                  {
                    component: "select",
                    value: filterData?.account_type,
                    options: enumValue?.account_relationship?.filter(
                      (x) =>
                        x?.value !== "Cash" &&
                        x?.value !== "Credit Card" &&
                        x?.value !== "Bank Account"
                    ),
                    isMulti: true,
                    state_name: "account_type",
                    label: t("Account Type"),
                    placeholder: t("Select Account Type"),
                  },
                ]}
                onApply={(value) => handleFilter(value)}
              />
            )}
          </Box>
        )}
      </>
    );
  };
  return <Box>{accessCheckRender(render, permission, "", loading)}</Box>;
};
export default withTranslation("accounts")(Accounts);
