import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const ContractStyle = makeStyles((theme)=>({
    root:{

    },
    filterIcon:{
        border:`1px solid ${theme.palette.border.secondary}`,
        borderRadius:4,
        padding:4,
        height:40,
        width:40,
        display:"flex",
        justifyContent:"center"
    },
    publishDialog:{
        '& .MuiPaper-root':{
            background:"transparent !important"
        }
    },
    publishPopupTitle:{
        fontSize:"1rem",
        color:"#fff",
        fontWeight:"bold",
        whiteSpace:"nowrap"
    },
    deletePopupTitle:{
        fontSize:"1rem",
        color:"#fff",
        fontWeight:"bold",
        width:300
    },
    publishPopupBtn:{
        background:"#fff",
        borderRadius:4,
        width:150,
        height:40,
        justifyContent:"center",
        alignItems:"center",
        display:"flex",
        cursor:"pointer"
    },
    publishbtnText:{
        color:theme.palette.primary.main,
        fontSize:"0.875rem",
        fontWeight:"bold",
    },
    Yes: {
        color: theme?.palette?.primary?.main,
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().bold,
        border: `1px solid white`,
        backgroundColor: "white",
        padding: "7px 29px",
        width: "100%",
        "&:hover": {
          border: `1px solid white`,
          backgroundColor: "white",
        },
      },
      No: {
        color: "white",
        fontSize:"0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        border: `1px solid white`,
        padding: "7px 29px",
        backgroundColor: theme?.palette?.primary?.main,
        marginInlineStart: "10px",
        width: "100%",
        "&:hover": {
          border: `1px solid white`,
          backgroundColor: theme?.palette?.primary?.main,
        },
      },
}))


export const PopupStyle = makeStyles((theme)=>({
    noData:{
        height:500,
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    },
    noDataText:{
        fontSize:"0.875rem",
        color:theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().bold,
    },
    addbtn:{
        height:40
    }
}))



export const CreateNewContractEditStyle = makeStyles((theme) => ({
    createBtn: {
        height: 40,
        width: 78
    },
    drawerBox: {
        width: '456px',
        position: "relative"
    },
    header: {
        position: "fixed",
        top: 0,
        width: "100%",
        backgroundColor:"#F2F4F7"
    },
    bottom: {
        position: "fixed",
        bottom: 0,
        padding: '8px',
        width: '456px'
    },
    body: {
        // position: "fixed",
        top: 0,
        marginTop: '64px',
        height: "calc(100vh - 130px)",
        overflow: "auto",
        width: "100%"
    },
    drawerCloseBtn: {
        fontSize: "1rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
        background: theme.palette.background.tertiary,
        borderRadius: 4,
        height: 32,
        width: 32,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    drawerHeading: {
        fontSize:"1rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    previous: {
        border: `1px solid ${theme.palette.border.primary}`,
        background: "transparent",
        color: theme.typography.color.primary,
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold,
        // height: 40,
        padding: '11px 16px',
        lineHeight: '19px !important',
        "&:hover": {
            background: "transparent",
        }
    },
    next: {
        padding: '11px 16px',
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold,
        lineHeight:'19px !important'
  
    },
}))