import { Avatar, Box, Stack, Typography, Divider, Grid, IconButton, Button } from '@mui/material'
import React from 'react'
import { ProfileCardStyle } from './style'
import { withTranslation } from "react-i18next";
import { AlertProps, NetWorkCallMethods, ValidateEmail, getMobileLimitBasedOnCC, stringAvatar } from '../../../utils';
import EditIMG from '../../../assets/editimage';
import { TextBox } from '../../../components';
import { NetworkCall } from '../../../networkcall';
import { config } from '../../../config';
import { AlertContext } from '../../../contexts';
import moment from 'moment';
import { SimpleSelect } from '../../usermanagement/components/mobile/select';
import { Sample } from '../../usermanagement/validation';

const ProfileCard = ({ t, data, setSelected, reload = () => false }) => {
  const classes = ProfileCardStyle()
  const alert = React.useContext(AlertContext)

  const [isEdit, setIsEdit] = React.useState({
    bool: false,
    value: data?.email_id,
    error: false,
    errMSg: "",
    disable: false,
    type: "email"
  })
  const [isMobileEdit, setIsMobileEdit] = React.useState({
    bool: false,
    value: data?.mobile,
    no: data?.mobile_no,
    code: data?.mobile_no_country_code,
    error: false,
    errMSg: "",
    disable: false,
    type: "mobile"
  })
  const updateState = (key, value, type) => {
    if (type === "email") {
      setIsEdit({
        ...isEdit,
        [key]: value
      })


    }
    else {
      setIsMobileEdit({
        ...isMobileEdit,
        [key]: value
      })


    }
  }

  const onSubmit = (type) => {

    if (type === "email") {

      if (ValidateEmail(isEdit?.value)) {
        updateState("disable", true, "email")
        const payload = {
          email_id: isEdit?.value,
          user_profile_id: data?.user_profile_id,
          contact_id: data?.id,
          xEmail: data?.email_id
        }


        NetworkCall(
          `${config.api_url}/invite/update_email`,
          NetWorkCallMethods.post,
          payload, null, true, false
        ).then((res) => {
          if (res?.data?.data?.type === "success") {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.success,
              msg: t("Email Successfully Updated"),
            })
            setIsEdit({
              ...isEdit,
              disable: false,
              bool: false,
              errMSg: "",
            })
            setSelected({
              ...data,
              email_id: isEdit?.value
            })

            reload()
          } else {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: res?.data?.data?.msg,
            })
            setIsEdit({
              ...isEdit,
              disable: false,
              bool: false,
              value: data?.email_id
            })
          }

        }).catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: AlertProps.message.some_thing_went_wrong,
          })
        })

      } else {
        setIsEdit({
          ...isEdit,
          errMSg: t("InValid Email Id"),
          error: true
        })
      }
    }
    else {
      const limit = getMobileLimitBasedOnCC(isMobileEdit?.code)
      if (isMobileEdit?.no?.length === limit) {
        updateState("disable", true, "mobile")

        const payload = {
          mobile_no: isMobileEdit?.no,
          mobile_no_country_code: isMobileEdit?.code,
          user_profile_id: data?.user_profile_id,
          contact_id: data?.id,
          xMobile: data?.mobile
        }
        NetworkCall(
          `${config.api_url}/invite/update_mobile`,
          NetWorkCallMethods.post,
          payload, null, true, false
        ).then((res) => {
          if (res?.data?.data?.type === "success") {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.success,
              msg: t("Mobile Number Successfully Updated"),
            })
            setIsMobileEdit({
              ...isMobileEdit,
              disable: false,
              bool: false,
              errMSg: "",
            })
            setSelected({
              ...data,
              mobile_no: isMobileEdit?.no,
              mobile_no_country_code: isMobileEdit?.code
            })
            reload()
          } else {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: res?.data?.data?.msg,
            })
            setIsMobileEdit({
              ...isMobileEdit,
              disable: false,
              bool: false,
              no: data?.mobile_no,
              code: data?.mobile_no_country_code,
              value: data?.mobile
            })
          }
        }).catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: AlertProps.message.some_thing_went_wrong,
          })
        }
        )
      } else {
        setIsMobileEdit({
          ...isMobileEdit,
          errMSg: t("Please enter valid number"),
          error: true
        })
      }
    }
  }
  const handleChangeSelect = (mobile_code = {}) => {
    updateState("code", mobile_code, "mobile")
  };

  React.useEffect(() => {
    setIsEdit({
      ...isEdit,
      value: data?.email_id,
      bool: false,
      errMSg: ""
    })
    setIsMobileEdit({
      ...isMobileEdit,
      value: data?.mobile,
      no: data?.mobile_no,
      code: data?.mobile_no_country_code,
      bool: false,
      errMSg: ""
    })
    // eslint-disable-next-line
  }, [data?.email_id, data?.mobile])

  return (
    <Box className={classes.box} p={2} >
      <Stack direction={"row"} spacing={2}
        divider={<Divider orientation="vertical" flexItem />}
        className={classes.stack}>
        <Avatar className={classes.avatar} alt='profileImg' src={data?.image_url}  {...stringAvatar(data?.name)}></Avatar>
        <Stack spacing={1} sx={{ width: "100%" }}>
          <Typography className={classes.title}>{t("profiledetail")}</Typography>
          <Grid container spacing={1}>
            <Grid item md={6} sm={12} lg={3}>


              <Box display={"flex"} flexDirection={"column"}>
                <Typography className={classes.subtitle}>{t("Name")}</Typography>
                <Typography className={classes.content}>{data?.name}</Typography>
              </Box>
            </Grid>
            <Grid item md={6} sm={12} lg={4}>
              <Box display={"flex"} flexDirection={"column"} marginTop="2px">


                <Typography className={classes.subtitle}>{t("EmailId")}</Typography>

                {
                  isEdit?.bool ?
                    <Stack display={"flex"} direction={"row"} spacing={1}>
                      <TextBox
                        label={null}


                        placeholder={t("Enter Email")}
                        value={isEdit?.value}
                        isError={isEdit?.error}
                        onChange={(e) => updateState("value", e.target.value.trim(' '), "email")}
                        errorMessage={isEdit?.errMSg}
                      />
                      <Button sx={{ height: "40px", marginInlineStart: "8px" }} variant='contained' disabled={isEdit?.value === 0 || isEdit?.disable} onClick={() => onSubmit(isEdit?.type)}>{t("Save")}</Button>
                    </Stack >
                    :
                    <Stack display={"flex"} direction={"row"} spacing={1}>
                      <Typography className={classes.content}>{isEdit?.value}</Typography>
                      <IconButton onClick={() => updateState("bool", true, "email")}>
                        <EditIMG />
                      </IconButton >
                    </Stack >

                }




              </Box >
            </Grid >
            <Grid item md={6} sm={12} lg={4} style={{ marginRight: "5px" }}>
              <Box display={"flex"} flexDirection={"column"} marginTop="2px">
                <Typography className={classes.subtitle}>{t("MobileNumber")}</Typography>
                {
                  isMobileEdit?.bool ?
                    <Stack display={"flex"} direction={"row"} spacing={1} alignItems={"flex-start"}>

                      <div style={{ maxWidth: "15%", marginRight: "28px", marginLeft: "-10px" }}>
                        <SimpleSelect
                          className={classes.select}
                          options={Sample}
                          handleChangeSelect={handleChangeSelect}
                          value={isMobileEdit?.code ?? ""}
                          disabled={false}
                          errorValidation={isMobileEdit?.error}
                        />
                      </div>

                      <TextBox
                        label={null}
                        placeholder={t("Enter Mobile Number")}
                        value={isMobileEdit?.no}
                        isError={isMobileEdit?.error}
                        onChange={(e) => updateState("no", e.target.value.trim(' '), "mobile")}
                        errorMessage={isMobileEdit?.errMSg}
                        style={{ flex: 1, minHeight: "70px" }}
                      />
                      <Button sx={{ height: "40px", marginInlineStart: "8px" }} variant='contained' disabled={isMobileEdit?.no === 0 || isMobileEdit?.disable} onClick={() => onSubmit(isMobileEdit?.type)}>{t("Save")}</Button>
                    </Stack>
                    :
                    <Stack display={"flex"} direction={"row"} spacing={1}>

                      <Typography className={classes.content}>{isMobileEdit?.value}</Typography>
                      <IconButton onClick={() => updateState("bool", true, "mobile")}>
                        <EditIMG />
                      </IconButton >
                    </Stack>
                }

              </Box>
            </Grid>
            <Grid item md={6} sm={12} lg={4}>
              <Box display={"flex"} flexDirection={"column"}>
                <Typography className={classes.subtitle}>{t("LastLogin")}</Typography>
                <Typography className={classes.content}> {data?.last_logged_in ? moment(data?.last_logged_in).format("DD MMM YYYY") : "-"}</Typography>


              </Box>
            </Grid>
          </Grid>
        </Stack >
      </Stack >
    </Box >
  )
}

export default withTranslation("inviteTrigger")(ProfileCard) 