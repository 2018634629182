import { Box } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import {
  AlertDialog,
  LoadingSection,
  Subheader,
  UseDebounce,
} from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import {
  accessCheckRender,
  AlertProps,
  enumSelect,
  enum_types,
  getRoutePermissionNew,
  NetWorkCallMethods,
} from "../../utils";
import { initialState, StatusOptionList } from "../../utils/insepectionMaster";
import { Form, SearchFilters, Table } from "./components";
import { useStyles } from "./style";
import { useNavigate } from "react-router-dom";

const FeedBackCategoryMaster = ({ t }) => {
  const classes = useStyles();
  const debounce = UseDebounce();
  const auth = React.useContext(AuthContext);
  const alert = React.useContext(AlertContext);
  const [data, setData] = React.useState({
    title: "",
    type: "",
    status: true,
    isEdit: false,
    error: {
      title: "",
      status: "",
      type: "",
    },
  });
  const [searchText, setSearchText] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [list, setList] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [requestTypeOptions, setRequestTypeOptions] = React.useState([]);
  const [permissions, setPermission] = React.useState({});
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get("page");
  const limitParam = searchParams.get("limit");

  // Default values if not set in URL
  const pageFromParams = pageParam ? parseInt(pageParam, 10) : 1;
  const limitFromParams = limitParam ? parseInt(limitParam, 10) : 10;

  const [page, setPage] = React.useState(pageFromParams);
  const [limit, setLimit] = React.useState(limitFromParams);

  const updatePage = (newPage, newLimit = limit) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", newPage.toString());
    searchParams.set("limit", newLimit.toString());

    // Update URL without reloading
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
    // Update state
    setPage(newPage);
    setLimit(newLimit);
  };

  //get list
  const getFeedbackCategoryList = (offset = 0, limit = 10, searchText = "") => {
    setLoading(true);
    const payload = {
      start: offset,
      length: limit,
      search: searchText,
    };
    NetworkCall(
      `${config.api_url}/inspection_feedback_category`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setList({
          list: response?.data?.data?.map((val) => {
            return {
              ...val,
              status: val?.is_active,
              title: val?.name,
              type: val?.inspection_item_type,
            };
          }),
          count: response.data.count,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const currentOffset = (page - 1) * limit;
  //initial load
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        getFeedbackCategoryList(currentOffset, limitFromParams, "");
        getEnum();
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  //reload
  const reload = () => {
    getFeedbackCategoryList(currentOffset, limitFromParams, "");
  };
  // Function to get Enum value
  // Function to get Enum value
  const getEnum = async () => {
    const result = await enumSelect([enum_types.inspection_item_type]);
    setRequestTypeOptions(result?.inspection_item_type);
  };
  //on isActive
  const onIsActive = (val) => {
    const payload = {
      data: {
        name: val?.name,
        inspection_type: val?.inspection_type,
        is_active: !val?.is_active,
        id: val?.id ?? undefined,
      },
    };
    NetworkCall(
      `${config.api_url}/inspection_feedback_category/upsert`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        reload();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Status Updated Successfully"),
        });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };

  //handle icon
  const handleClickHandler = (type, val) => {
    if (type === "edit") {
      setOpen(true);
      setData({
        ...data,
        isEdit: true,
        id: val?.id,
        title: val?.name,
        type: {
          label: val?.inspection_item_type,
          value: val?.inspection_item_type,
        },
        status: val?.is_active,
      });
    } else if (type === "active") {
      onIsActive(val);
    }
  };

  //handle pagination
  const handlePagination = (newPage) => {
    updatePage(newPage, limit);
  };

  const handleChangeLimit = (newLimit) => {
    updatePage(1, newLimit); // Reset page to 1 when limit changes
  };

  //on search
  const handleSearch = (e) => {
    setSearchText(e);
    debounce(() => searchTableFunction(e), 800);
  };

  //search function
  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    getFeedbackCategoryList(0, limit, e);
  };

  //onClose form
  const closeForm = () => {
    setOpen(false);
    setData({ ...initialState });
  };
  const render = () => {
    return (
      <Box>
        {/*sub header */}
        <Subheader hideBackButton={true} title={t("CheckList Master")} />
        {loading ? (
          <LoadingSection top="20vh" message={t("loading")} />
        ) : (
          <Box container className={classes.root}>
            {/*search btn component */}
            <SearchFilters
              onClick={() => setOpen(true)}
              searchText={searchText}
              handleSearch={handleSearch}
              permissions={permissions}
              t={t}
            />
            {/*table component */}
            <Table
              handleIcon={handleClickHandler}
              handleChangeLimit={handleChangeLimit}
              handlePagination={handlePagination}
              page={page}
              list={list}
              limit={limit}
              handleSearch={handleSearch}
              searchText={searchText}
              view={permissions?.read}
              edit={permissions?.update}
            />
          </Box>
        )}
        {/*form component */}
        <AlertDialog
          open={open}
          onClose={closeForm}
          header={
            data?.isEdit
              ? `${t("Edit Checklist Master")}`
              : `${t("Add Checklist Master")}`
          }
          header_padding={"16px"}
          close_button_padding={"0px"}
          component={
            <Form
              data={data}
              requestTypeOptions={requestTypeOptions}
              setData={setData}
              reload={reload}
              onClose={closeForm}
              StatusOptionList={StatusOptionList(t)}
            />
          }
          isNormal
        />
      </Box>
    );
  };
  return <>{accessCheckRender(render, permissions)}</>;
};
export default withTranslation("inspectionItemmaster")(FeedBackCategoryMaster);
